import jwt from 'jwt-decode';
import firebase from "../servicos/FirebaseConnection";

export const getToken = async () => {
    const token = localStorage.getItem('token');
    if (token && token !== 'undefined' && token !== 'null') {
        const payload = jwt(token);
        console.log(isExpired(payload.exp));
        if (isExpired(payload.exp)) {
            const newToken = await renewToken();
            return Promise.resolve(newToken ?? '');
        } else {
            return Promise.resolve(token);
        }
    } else {
        const renewedToken = await renewToken();
        return Promise.resolve(renewedToken);
    }
}

 const isExpired = (exp) => {
    const now = new Date().getTime() / 1000;
    return exp - now < 15 * 60;
} 


const renewToken = async () => {
    return new Promise((resolve, reject) => {
        const observer = firebase.auth().onAuthStateChanged(async function (user) {
            if (user) {
                const newToken = await user.getIdToken(true);
                if (newToken) {
                    localStorage.setItem('token', newToken);
                    observer();
                    return resolve(newToken);
                }
                observer();
                return resolve(null);
            } else {
                observer();
                localStorage.removeItem('token');
                return resolve(null);
            }
        });

    });
}
