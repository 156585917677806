
import moment from 'moment';
import firebase from '../servicos/FirebaseConnection';
import { toastr } from 'react-redux-toastr';
import api from '../http_client/http.client';
import {
    MODIFICADATAFINALAVALIACAOGERAL, MODIFICADATAINICIALAVALIACAOGERAL, ENVIAPERIODOPERSONALIZADOAVALIACAOGERAL,
    ATIVADESATIVALOADINGTELARELATORIOAVALIACAOGERAL, MENSAGEMERRORAPLICAPERIODOPERSONALIZADO, ENVIAAVALIACOESGERALDASHBOARD,
    ENVIATOTALAVALIACOESGERAL, ENVIAAVALIACAO1ESTRELA, ENVIAAVALIACAO2ESTRELA, ENVIAAVALIACAO3ESTRELA, ENVIAAVALIACAO4ESTRELA, ENVIAAVALIACAO5ESTRELA,
    ENVIATOTALAVALIACOES, ENVIAPORCENTAGEM1ESTRELA, ENVIAPORCENTAGEM2ESTRELA, ENVIAPORCENTAGEM3ESTRELA, ENVIAPORCENTAGEM4ESTRELA, ENVIAPORCENTAGEM5ESTRELA,
    ENVIANSOGERAL, ENVIANOTAMEDIAGERAL, ATIVADESATIVALOADINGSECTIONEDATENDENTERELATORIOATENDENTE, ENVIATENDENTEPARASECTIONEDRELATORIOATENDENTE,
    MODIFICAATENDENTESELECIONADOSECTIONEDRELATORIOAVALIACAO, ATIVADESATIVALOADINGTELARELATORIOAVALIACAOATENDENTE, ENVIAPERIODOPERSONALIZADOAVALIACAOATENDENTE,
    ENVIAAVALIACAO1ESTRELAATENDENTE, ENVIAAVALIACAO2ESTRELAATENDENTE, ENVIAAVALIACAO3ESTRELAATENDENTE, ENVIAAVALIACAO4ESTRELAATENDENTE, ENVIAAVALIACAO5ESTRELAATENDENTE,
    ENVIATOTALAVALIACOESATENDENTE, ENVIAPORCENTAGEM1ESTRELAATENDENTE, ENVIAPORCENTAGEM2ESTRELAATENDENTE, ENVIAPORCENTAGEM3ESTRELAATENDENTE, ENVIAPORCENTAGEM4ESTRELAATENDENTE,
    ENVIAPORCENTAGEM5ESTRELAATENDENTE, ENVIANSOGERALATENDENTE, ENVIANOTAMEDIAGERALATENDENTE, MODIFICADATAINICIALAVALIACAOATENDENTE,
    MODIFICADATAFINALAVALIACAOATENDENTE, MENSAGEMERRORAPLICAPERIODOPERSONALIZADOATENDENTE, ATIVADESATIVALOADINGTELATODASAVALIACOES,
    ATIVADESATIVALOADINGREFRESHCONTROLTODASAVALIACOES, ENVIAAVALIACOESTELATODASAVALIACOS, ENVIAULTIMAREFERENCIAKEYAVALIACAO,
    ATIVADESATIVALOADINGFINALPAGINATODASAVALIACOES, VERIFICACONEXAOINTERNETELATODASAVALIACOES, ENVIADETALHESAVALIACAOTELADETALHESAVALIACAO,
    ENVIAATENDENTEPARAREDUCERTODASAVALIACOES, ENVIACLIENTEPARAREDUCERTODASAVALIACOES, ATIVADESATIVALOADINGENVIAATENDENTETELAAVALIACAOTODAS,
    ATIVADESATIVALOADINGENVIACLIENTETELAAVALIACAOTODAS, MODIFICACLIENTESELECIONADOTELAVALIACAOTODAS, MODIFICAATENDENTESELECIONADOTELAVALIACAOTODAS,
    MODIFICAVALORAVALIACAOSELECIONADOTELAVALIACAOTODAS, ENVIAULTIMAKEYREFERENCIAKEYCLIENTETODASAVALIACOES, ENVIAULTIMAKEYREFERENCIAKEYATENDENTETODASAVALIACOES,
    ENVIAULTIMAKEYREFERENCIAKEYVALORAVALIACAOTODASAVALIACOES, MODIFICAKEYAVALIACAO, MODIFICAFILTROAVALIACAO,
    MODIFICAFILTRORELATORIOAVALIACAOGERAL, ENVIAIMAGEMPERFILCLIENTETELADETALHESAVALIACAO, MODIFICAFILTROAVALIACAOWEB
} from './types';




/* export const listaAvaliacoesGeralEstatico = (periodoEstatico, valorReferencia) => {
    
    //nome dos meses pt br
    moment.locale('pt-br', {
        months: 'Janeiro_Fevereiro_Março_Abril_Maio_Junho_Julho_Agosto_Setembro_Outubro_Novembro_Dezembro'.split('_')
    })
    //HOJE
    let dataHoje = moment().toDate();
    dataHoje = moment(dataHoje).format('DD/MM/YYYY');

    //ONTEM
    let datadeOntem = moment().subtract(1, 'days').toDate(); // pego data atual
    datadeOntem = moment(datadeOntem).format('DD/MM/YYYY');

    //SEMANA ATUAL
    const comecoDaSemana = moment().startOf('week').toDate();
    const fimDaSemana = moment().endOf('week').toDate();
    let dataComecoSemana = moment(comecoDaSemana).format('DD/MM/YYYY');
    let dataFimSemana = moment(fimDaSemana).format('DD/MM/YYYY');
    // dataComecoSemana = dataComecoSemana + ' 00:00:01';
    // dataFimSemana = dataFimSemana + ' 23:59:59';

    //SEMANA PASSADA
    let comecoSemanaPassada = moment().weekday(-7).toDate();
    let fimDaSemanaPassada = moment().weekday(-7).add(6, 'day').toDate()
    let dataComecoSemanaPassada = moment(comecoSemanaPassada).format('DD/MM/YYYY');
    let dataFimDaSemanaPassada = moment(fimDaSemanaPassada).format('DD/MM/YYYY');

    //MES ATUAL
    const nomeMesAtual = moment().startOf('month').format('MMMM');
    const comecoDoMesAtual = moment().startOf('month').format('DD/MM/YYYY');
    const fimDoMesAtual = moment().endOf('month').format('DD/MM/YYYY');

    //MES PASSADO
    const nomeMesPassado = moment().subtract(1, 'months').startOf('month').format('MMMM');
    const comecoDoMesPassado = moment().subtract(1, 'months').startOf('month').format('DD/MM/YYYY');
    const fimDoMesPassado = moment().subtract(1, 'months').endOf('month').format('DD/MM/YYYY');

   
   
   
   
   
   


    let atendimento1estrela = 0;
    let atendimento2estrela = 0;
    let atendimento3estrela = 0;
    let atendimento4estrela = 0;
    let atendimento5estrela = 0;
    let totalAvaliacoes = 0;
    let porcentagem1estrela = 0.00;
    let porcentagem2estrela = 0.00;
    let porcentagem3estrela = 0.00;
    let porcentagem4estrela = 0.00;
    let porcentagem5estrela = 0.00;
    let notaMedia = 0.00;
    let notaNSO = 0.00;
    // dataComecoSemana = dataComecoSemana + ' 00:00:01';
    //dataFimSemana = dataFimSemana + ' 23:59:59';
    //  let dataComecoSemanaTS = retornaDataTimeStamp(dataComecoSemana);
    // let dataFimSemanaTS = retornaDataTimeStamp(dataFimSemana);

    //como converter uma data de timestamp em data normal
    // let date2 = new Date(1562016662685).toUTCString();
    // let dataAtualTimeStamp = moment(date2).format('DD/MM/YYYY');
    return dispatch => {
        checkInternetConnection().then(isConnected => {
            if (isConnected) {
                AsyncStorage.getItem('chaveUnica').then((chaveUnica2) => {
                    const chaveUnica = chaveUnica2.replace(/"/g, "");
                   
                    //enviaSemConexaoInternetDashBoard(dispatch, false);
                    ativaDesativaLoadingTelaRelatorioAvaliacaoGeral(dispatch, true);
                    let dataAtual = retornaDataAtual();
                    let dataInicioConsulta, dataFinalConsulta;

                    //verificacao para saber qual periodo é e passar o periodo correto para a query do firebase
                    if (periodoEstatico == 'Hoje') {
                        dataInicioConsulta = dataHoje + ' 00:00:01';
                        dataFinalConsulta = dataHoje + ' 23:59:59';
                        enviaPeriodoPersonalizadoAvaliacaoGeral(dispatch, periodoEstatico);
                    }
                    else if (periodoEstatico == 'Ontem') {
                        dataInicioConsulta = datadeOntem + ' 00:00:01';
                        dataFinalConsulta = datadeOntem + ' 23:59:59';
                        enviaPeriodoPersonalizadoAvaliacaoGeral(dispatch, periodoEstatico);
                    }
                    else if (periodoEstatico == 'Semana Atual') {
                        dataInicioConsulta = dataComecoSemana + ' 00:00:01';
                        dataFinalConsulta = dataFimSemana + ' 23:59:59';
                        enviaPeriodoPersonalizadoAvaliacaoGeral(dispatch, periodoEstatico);
                    }
                    else if (periodoEstatico == 'Semana Passada') {
                        dataInicioConsulta = dataComecoSemanaPassada + ' 00:00:01';
                        dataFinalConsulta = dataFimDaSemanaPassada + ' 23:59:59';
                        enviaPeriodoPersonalizadoAvaliacaoGeral(dispatch, periodoEstatico);
                    }
                    else if (periodoEstatico.includes('Mês Atual') && valorReferencia == 0) {
                        dataInicioConsulta = comecoDoMesAtual + ' 00:00:01';
                        dataFinalConsulta = fimDoMesAtual + ' 23:59:59';
                        //   enviaPeriodoPersonalizadoAvaliacaoGeral(dispatch, `${periodoEstatico}, ${nomeMesAtual}.`);
                    }
                    else if (periodoEstatico.includes('Mês Passado') && valorReferencia == 0) {
                        dataInicioConsulta = comecoDoMesPassado + ' 00:00:01';
                        dataFinalConsulta = fimDoMesPassado + ' 23:59:59';
                        //enviaPeriodoPersonalizadoAvaliacaoGeral(dispatch, `${periodoEstatico}, ${nomeMesPassado}.`);
                    }
                    else if (periodoEstatico.includes('Mês Atual') && valorReferencia == 1) {
                        dataInicioConsulta = comecoDoMesAtual + ' 00:00:01';
                        dataFinalConsulta = fimDoMesAtual + ' 23:59:59';
                        enviaPeriodoPersonalizadoAvaliacaoGeral(dispatch, `${periodoEstatico}, ${nomeMesAtual}.`);
                    }
                    else if (periodoEstatico.includes('Mês Passado') && valorReferencia == 1) {
                        dataInicioConsulta = comecoDoMesPassado + ' 00:00:01';
                        dataFinalConsulta = fimDoMesPassado + ' 23:59:59';
                        enviaPeriodoPersonalizadoAvaliacaoGeral(dispatch, `${periodoEstatico}, ${nomeMesPassado}.`);
                    }
                    else {
                        
                        //nunca vai chegar aqui, mas pra previnir vou retornar como se fosse semana atual
                        dataInicioConsulta = dataComecoSemana + ' 00:00:01';
                        dataFinalConsulta = dataFimSemana + ' 23:59:59';
                        enviaPeriodoPersonalizadoAvaliacaoGeral(dispatch, periodoEstatico);

                    }
                    
                    dataInicioConsulta = retornaDataTimeStamp(dataInicioConsulta);
                    dataFinalConsulta = retornaDataTimeStamp(dataFinalConsulta);
                   

                    zeraDataInicialAvaliacaoGeral(dispatch);
                    zeraDataFinalAvaliacaoGeral(dispatch);
                   
                    firebase.database().ref(`${chaveUnica}/avaliacoes`).orderByChild('dataHoraAvaliacaoTimeStamp')
                        .startAt(dataInicioConsulta).endAt(dataFinalConsulta).once("value").then((snapshot) => {
                            
                            
                            if (snapshot.val()) {
                                //envia os valores para o grafico
                                let arrayOfKeys = Object.keys(snapshot.val()).sort().reverse();
                                let results = arrayOfKeys.map((key) => snapshot.val()[key]);
                                for (let i = 0; i < results.length; i++) {
                                   
                                    totalAvaliacoes++;
                                    if (results[i].valorAvaliacao == 1) {
                                        atendimento1estrela++;
                                    }
                                    if (results[i].valorAvaliacao == 2) {
                                        atendimento2estrela++;
                                    }
                                    if (results[i].valorAvaliacao == 3) {
                                        atendimento3estrela++;
                                    }
                                    if (results[i].valorAvaliacao == 4) {
                                        atendimento4estrela++;
                                    }
                                    if (results[i].valorAvaliacao == 5) {
                                        atendimento5estrela++;
                                    }
                                }
                                enviaAvaliacao1estrela(dispatch, atendimento1estrela);
                                enviaAvaliacao2estrela(dispatch, atendimento2estrela);
                                enviaAvaliacao3estrela(dispatch, atendimento3estrela);
                                enviaAvaliacao4estrela(dispatch, atendimento4estrela);
                                enviaAvaliacao5estrela(dispatch, atendimento5estrela);
                                enviaTotalAvaliacoes(dispatch, totalAvaliacoes);

                               
                               
                               
                               
                               
                               
                                porcentagem1estrela = (atendimento1estrela / totalAvaliacoes) * 100;
                                porcentagem2estrela = (atendimento2estrela / totalAvaliacoes) * 100;
                                porcentagem3estrela = (atendimento3estrela / totalAvaliacoes) * 100;
                                porcentagem4estrela = (atendimento4estrela / totalAvaliacoes) * 100;
                                porcentagem5estrela = (atendimento5estrela / totalAvaliacoes) * 100;
                                console.log('porcentagem 1 estrela: ' + porcentagem1estrela.toFixed(2).replace('.', ','));
                                console.log('porcentagem 2 estrela: ' + porcentagem2estrela.toFixed(2).replace('.', ','));
                                console.log('porcentagem 3 estrela: ' + porcentagem3estrela.toFixed(2).replace('.', ','));
                                console.log('porcentagem 4 estrela: ' + porcentagem4estrela.toFixed(2).replace('.', ','));
                                console.log('porcentagem 5 estrela: ' + porcentagem5estrela.toFixed(2).replace('.', ','));
                                enviaPorcentagem1estrela(dispatch, porcentagem1estrela.toFixed(2).replace('.', ','));
                                enviaPorcentagem2estrela(dispatch, porcentagem2estrela.toFixed(2).replace('.', ','));
                                enviaPorcentagem3estrela(dispatch, porcentagem3estrela.toFixed(2).replace('.', ','));
                                enviaPorcentagem4estrela(dispatch, porcentagem4estrela.toFixed(2).replace('.', ','));
                                enviaPorcentagem5estrela(dispatch, porcentagem5estrela.toFixed(2).replace('.', ','));
                                let valor1estrelaParaPorcentagem = atendimento1estrela * 1;
                                let valor2estrelaParaPorcentagem = atendimento2estrela * 2;
                                let valor3estrelaParaPorcentagem = atendimento3estrela * 3;
                                let valor4estrelaParaPorcentagem = atendimento4estrela * 4;
                                let valor5estrelaParaPorcentagem = atendimento5estrela * 5;
                                
                                
                                
                                
                               
                                notaMedia = (valor1estrelaParaPorcentagem + valor2estrelaParaPorcentagem + valor3estrelaParaPorcentagem + valor4estrelaParaPorcentagem + valor5estrelaParaPorcentagem) / totalAvaliacoes;
                                console.log('notaMedia: ' + notaMedia.toFixed(2).replace('.', ','));
                                enviaNotaMediaGeral(dispatch, notaMedia.toFixed(2).replace('.', ','));
                                let valor1estrelaParaNSO = atendimento1estrela * -2;
                                let valor2estrelaParaNSO = atendimento2estrela * -1;
                                let valor3estrelaParaNSO = atendimento3estrela * 0;
                                let valor4estrelaParaNSO = atendimento4estrela * 1;
                                let valor5estrelaParaNSO = atendimento5estrela * 2;
                                notaNSO = (valor1estrelaParaNSO + valor2estrelaParaNSO + valor3estrelaParaNSO + valor4estrelaParaNSO + valor5estrelaParaNSO) / totalAvaliacoes;
                                console.log('NSO: ' + notaNSO.toFixed(2).replace('.', ','));
                                enviaNSOGeral(dispatch, notaNSO.toFixed(2).replace('.', ','));
                                ativaDesativaLoadingTelaRelatorioAvaliacaoGeral(dispatch, false);

                            } else {
                                enviaAvaliacao1estrela(dispatch, atendimento1estrela);
                                enviaAvaliacao2estrela(dispatch, atendimento2estrela);
                                enviaAvaliacao3estrela(dispatch, atendimento3estrela);
                                enviaAvaliacao4estrela(dispatch, atendimento4estrela);
                                enviaAvaliacao5estrela(dispatch, atendimento5estrela);
                                enviaTotalAvaliacoes(dispatch, totalAvaliacoes);
                                enviaPorcentagem1estrela(dispatch, porcentagem1estrela.toFixed(2).replace('.', ','));
                                enviaPorcentagem2estrela(dispatch, porcentagem2estrela.toFixed(2).replace('.', ','));
                                enviaPorcentagem3estrela(dispatch, porcentagem3estrela.toFixed(2).replace('.', ','));
                                enviaPorcentagem4estrela(dispatch, porcentagem4estrela.toFixed(2).replace('.', ','));
                                enviaPorcentagem5estrela(dispatch, porcentagem5estrela.toFixed(2).replace('.', ','));
                                enviaNotaMediaGeral(dispatch, notaMedia.toFixed(2).replace('.', ','));
                                enviaNSOGeral(dispatch, notaNSO.toFixed(2).replace('.', ','));

                                ativaDesativaLoadingTelaRelatorioAvaliacaoGeral(dispatch, false);
                            }
                        })

                })
            } else {
                //sem internet
                 toastr.error('Error', 'Sem Conexão com a Internet');
                ativaDesativaLoadingTelaRelatorioAvaliacaoGeral(dispatch, false);
            }
        })
    }
} */

export const listaAvaliacoesGeralPeriodoPersonalizado = (dataInicial2, dataFinal2, valorReferencia) => {
    let dataInicial, dataFinal;
    // 
    return dispatch => {
        if (valorReferencia == 0) {
            //valor Personalizado
            dataInicial = moment(dataInicial2).format('DD/MM/YYYY')
            dataFinal = moment(dataFinal2).format('DD/MM/YYYY')
        } else {
            //valor Inicial
            let dataHojeWeb = moment().toDate();
            let dataHojeEnviaDatePicker = moment().toDate();
            dataHojeEnviaDatePicker = moment(dataHojeEnviaDatePicker);
            dataHojeWeb = moment(dataHojeWeb).format('DD/MM/YYYY');

            dataInicial = dataHojeWeb;
            dataFinal = dataHojeWeb;
            dispatch({ type: MODIFICADATAINICIALAVALIACAOGERAL, payload: dataHojeEnviaDatePicker });
            dispatch({ type: MODIFICADATAFINALAVALIACAOGERAL, payload: dataHojeEnviaDatePicker });
        }

        let atendimento1estrela = 0;
        let atendimento2estrela = 0;
        let atendimento3estrela = 0;
        let atendimento4estrela = 0;
        let atendimento5estrela = 0;
        let totalAvaliacoes = 0;
        let porcentagem1estrela = 0.00;
        let porcentagem2estrela = 0.00;
        let porcentagem3estrela = 0.00;
        let porcentagem4estrela = 0.00;
        let porcentagem5estrela = 0.00;
        let notaMedia = 0.00;
        let notaNSO = 0.00;

        let dataInicialVerificacao = moment(dataInicial, 'DD/MM/YYYY').format('YYYY-MM-DD');
        let dataFinalVerificacao = moment(dataFinal, 'DD/MM/YYYY').format('YYYY-MM-DD');
        let verificaSeDataInicialMaiorQueDataFinal = moment(dataFinalVerificacao).isBefore(dataInicialVerificacao);


        AsyncStorage.getItem('chaveUnica').then((chaveUnica2) => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            //
            if (dataInicial == '' && dataFinal == '' || dataInicial == null && dataFinal == null) {
                toastr.error('Error', 'Informe a DATA INICIAL e DATA FINAL do período de pesquisa.');
            }
            else if (dataInicial == '' || dataInicial == null) {
                toastr.error('Error', 'Informe a DATA INICIAL do período de pesquisa.');
            }
            else if (dataFinal == '' || dataFinal == null) {
                toastr.error('Error', 'Informe a DATA FINAL do período de pesquisa.');
            }
            else if (verificaSeDataInicialMaiorQueDataFinal == true) {
                toastr.error('Error', 'DATA INICIAL maior que DATA FINAL, por favor verifique.');
            }
            else {
                // enviaSemConexaoInternetDashBoard(dispatch, false);
                ativaDesativaLoadingTelaRelatorioAvaliacaoGeral(dispatch, true);
                enviaPeriodoPersonalizadoAvaliacaoGeral(dispatch, `${dataInicial} - ${dataFinal}`);

                dataInicial = dataInicial + ' 00:00:01';
                dataFinal = dataFinal + ' 23:59:59';
                let dataAtual = retornaDataAtual();
                let dataInicioConsulta, dataFinalConsulta;

                //verificacao para saber qual periodo é e passar o periodo correto para a query do firebase
                // 
                //
                // 
                dataInicioConsulta = retornaDataTimeStamp(dataInicial);
                dataFinalConsulta = retornaDataTimeStamp(dataFinal);
                // 
                //
                /*  firebase.database().ref(`${chaveUnica}/avaliacoes/`).orderByChild('dataHoraAvaliacaoTimeStamp')
                     .startAt(dataInicioConsulta).endAt(dataFinalConsulta).once("value").then((snapshot) => {
                         if (snapshot.val()) { */
                const options = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                        'Accept': 'application/json',
                    },

                    data: { chaveUnica: chaveUnica, dataInicioConsulta: dataInicioConsulta, dataFinalConsulta: dataFinalConsulta, rota: 'avaliacao' },
                    url: 'gerarRelatoriosAtendimentos',
                };
                api(options).then((snapshot) => {
                    
                    
                    snapshot = snapshot.data;
                    
                    if (snapshot !== null) {
                        // 
                        //envia os valores para o grafico
                        let arrayOfKeys = Object.keys(snapshot).sort().reverse();
                        let results = arrayOfKeys.map((key) => snapshot[key]);
                        for (let i = 0; i < results.length; i++) {
                            //
                            totalAvaliacoes++;
                            if (results[i].valorAvaliacao == 1) {
                                atendimento1estrela++;
                            }
                            if (results[i].valorAvaliacao == 2) {
                                atendimento2estrela++;
                            }
                            if (results[i].valorAvaliacao == 3) {
                                atendimento3estrela++;
                            }
                            if (results[i].valorAvaliacao == 4) {
                                atendimento4estrela++;
                            }
                            if (results[i].valorAvaliacao == 5) {
                                atendimento5estrela++;
                            }
                        }
                        enviaAvaliacao1estrela(dispatch, atendimento1estrela);
                        enviaAvaliacao2estrela(dispatch, atendimento2estrela);
                        enviaAvaliacao3estrela(dispatch, atendimento3estrela);
                        enviaAvaliacao4estrela(dispatch, atendimento4estrela);
                        enviaAvaliacao5estrela(dispatch, atendimento5estrela);
                        enviaTotalAvaliacoes(dispatch, totalAvaliacoes);

                        //   
                        // 
                        //  
                        // 
                        // 
                        // 
                        porcentagem1estrela = (atendimento1estrela / totalAvaliacoes) * 100;
                        porcentagem2estrela = (atendimento2estrela / totalAvaliacoes) * 100;
                        porcentagem3estrela = (atendimento3estrela / totalAvaliacoes) * 100;
                        porcentagem4estrela = (atendimento4estrela / totalAvaliacoes) * 100;
                        porcentagem5estrela = (atendimento5estrela / totalAvaliacoes) * 100;
                        /*      console.log('porcentagem 1 estrela: ' + porcentagem1estrela.toFixed(2).replace('.', ','));
                             console.log('porcentagem 2 estrela: ' + porcentagem2estrela.toFixed(2).replace('.', ','));
                             console.log('porcentagem 3 estrela: ' + porcentagem3estrela.toFixed(2).replace('.', ','));
                             console.log('porcentagem 4 estrela: ' + porcentagem4estrela.toFixed(2).replace('.', ','));
                             console.log('porcentagem 5 estrela: ' + porcentagem5estrela.toFixed(2).replace('.', ',')); */
                        enviaPorcentagem1estrela(dispatch, porcentagem1estrela.toFixed(2).replace('.', ','));
                        enviaPorcentagem2estrela(dispatch, porcentagem2estrela.toFixed(2).replace('.', ','));
                        enviaPorcentagem3estrela(dispatch, porcentagem3estrela.toFixed(2).replace('.', ','));
                        enviaPorcentagem4estrela(dispatch, porcentagem4estrela.toFixed(2).replace('.', ','));
                        enviaPorcentagem5estrela(dispatch, porcentagem5estrela.toFixed(2).replace('.', ','));
                        let valor1estrelaParaPorcentagem = atendimento1estrela * 1;
                        let valor2estrelaParaPorcentagem = atendimento2estrela * 2;
                        let valor3estrelaParaPorcentagem = atendimento3estrela * 3;
                        let valor4estrelaParaPorcentagem = atendimento4estrela * 4;
                        let valor5estrelaParaPorcentagem = atendimento5estrela * 5;
                        /*    
                           
                           
                           
                           */
                        notaMedia = (valor1estrelaParaPorcentagem + valor2estrelaParaPorcentagem + valor3estrelaParaPorcentagem + valor4estrelaParaPorcentagem + valor5estrelaParaPorcentagem) / totalAvaliacoes;
                        // console.log('notaMedia: ' + notaMedia.toFixed(2).replace('.', ','));
                        enviaNotaMediaGeral(dispatch, notaMedia.toFixed(2).replace('.', ','));
                        let valor1estrelaParaNSO = atendimento1estrela * -2;
                        let valor2estrelaParaNSO = atendimento2estrela * -1;
                        let valor3estrelaParaNSO = atendimento3estrela * 0;
                        let valor4estrelaParaNSO = atendimento4estrela * 1;
                        let valor5estrelaParaNSO = atendimento5estrela * 2;
                        notaNSO = (valor1estrelaParaNSO + valor2estrelaParaNSO + valor3estrelaParaNSO + valor4estrelaParaNSO + valor5estrelaParaNSO) / totalAvaliacoes;
                        //  console.log('NSO: ' + notaNSO.toFixed(2).replace('.', ','));
                        enviaNSOGeral(dispatch, notaNSO.toFixed(2).replace('.', ','));
                        ativaDesativaLoadingTelaRelatorioAvaliacaoGeral(dispatch, false);
                        // ativaDesativaLoadingTelaDashBoard(dispatch, false);
                        //  ativaDesativaLoadingRefreshControol(dispatch, false);
                    } else {
                        enviaAvaliacao1estrela(dispatch, atendimento1estrela);
                        enviaAvaliacao2estrela(dispatch, atendimento2estrela);
                        enviaAvaliacao3estrela(dispatch, atendimento3estrela);
                        enviaAvaliacao4estrela(dispatch, atendimento4estrela);
                        enviaAvaliacao5estrela(dispatch, atendimento5estrela);
                        enviaTotalAvaliacoes(dispatch, totalAvaliacoes);
                        enviaPorcentagem1estrela(dispatch, porcentagem1estrela.toFixed(2).replace('.', ','));
                        enviaPorcentagem2estrela(dispatch, porcentagem2estrela.toFixed(2).replace('.', ','));
                        enviaPorcentagem3estrela(dispatch, porcentagem3estrela.toFixed(2).replace('.', ','));
                        enviaPorcentagem4estrela(dispatch, porcentagem4estrela.toFixed(2).replace('.', ','));
                        enviaPorcentagem5estrela(dispatch, porcentagem5estrela.toFixed(2).replace('.', ','));
                        enviaNotaMediaGeral(dispatch, notaMedia.toFixed(2).replace('.', ','));
                        enviaNSOGeral(dispatch, notaNSO.toFixed(2).replace('.', ','));

                        ativaDesativaLoadingTelaRelatorioAvaliacaoGeral(dispatch, false);
                    }
                })
            }

        })

    }
}

/* export const listaAvaliacoesEstaticoRelatorioAtendente = (periodoEstatico, keyAtendente, valorReferencia) => {
    
    
    
    //nome dos meses pt br
    moment.locale('pt-br', {
        months: 'Janeiro_Fevereiro_Março_Abril_Maio_Junho_Julho_Agosto_Setembro_Outubro_Novembro_Dezembro'.split('_')
    })
    //HOJE
    let dataHoje = moment().toDate();
    dataHoje = moment(dataHoje).format('DD/MM/YYYY');

    //ONTEM
    let datadeOntem = moment().subtract(1, 'days').toDate(); // pego data atual
    datadeOntem = moment(datadeOntem).format('DD/MM/YYYY');

    //SEMANA ATUAL
    const comecoDaSemana = moment().startOf('week').toDate();
    const fimDaSemana = moment().endOf('week').toDate();
    let dataComecoSemana = moment(comecoDaSemana).format('DD/MM/YYYY');
    let dataFimSemana = moment(fimDaSemana).format('DD/MM/YYYY');
    // dataComecoSemana = dataComecoSemana + ' 00:00:01';
    // dataFimSemana = dataFimSemana + ' 23:59:59';

    //SEMANA PASSADA
    let comecoSemanaPassada = moment().weekday(-7).toDate();
    let fimDaSemanaPassada = moment().weekday(-7).add(6, 'day').toDate()
    let dataComecoSemanaPassada = moment(comecoSemanaPassada).format('DD/MM/YYYY');
    let dataFimDaSemanaPassada = moment(fimDaSemanaPassada).format('DD/MM/YYYY');

    //MES ATUAL
    const nomeMesAtual = moment().startOf('month').format('MMMM');
    const comecoDoMesAtual = moment().startOf('month').format('DD/MM/YYYY');
    const fimDoMesAtual = moment().endOf('month').format('DD/MM/YYYY');

    //MES PASSADO
    const nomeMesPassado = moment().subtract(1, 'months').startOf('month').format('MMMM');
    const comecoDoMesPassado = moment().subtract(1, 'months').startOf('month').format('DD/MM/YYYY');
    const fimDoMesPassado = moment().subtract(1, 'months').endOf('month').format('DD/MM/YYYY');

   
   
   
   
   
   


    let atendimento1estrela = 0;
    let atendimento2estrela = 0;
    let atendimento3estrela = 0;
    let atendimento4estrela = 0;
    let atendimento5estrela = 0;
    let totalAvaliacoes = 0;
    let porcentagem1estrela = 0.00;
    let porcentagem2estrela = 0.00;
    let porcentagem3estrela = 0.00;
    let porcentagem4estrela = 0.00;
    let porcentagem5estrela = 0.00;
    let notaMedia = 0.00;
    let notaNSO = 0.00;
    let verificaSeAtendenteTemAvaliacao = false;
    // dataComecoSemana = dataComecoSemana + ' 00:00:01';
    //dataFimSemana = dataFimSemana + ' 23:59:59';
    //  let dataComecoSemanaTS = retornaDataTimeStamp(dataComecoSemana);
    // let dataFimSemanaTS = retornaDataTimeStamp(dataFimSemana);

    //como converter uma data de timestamp em data normal
    // let date2 = new Date(1562016662685).toUTCString();
    // let dataAtualTimeStamp = moment(date2).format('DD/MM/YYYY');
    return dispatch => {
        checkInternetConnection().then(isConnected => {
            if (isConnected) {
                AsyncStorage.getItem('chaveUnica').then((chaveUnica2) => {
                    const chaveUnica = chaveUnica2.replace(/"/g, "");
                   
                    //enviaSemConexaoInternetDashBoard(dispatch, false);
                    ativaDesativaLoadingTelaRelatorioAvaliacaoAtendente(dispatch, true);
                    let dataAtual = retornaDataAtual();
                    let dataInicioConsulta, dataFinalConsulta;

                    //verificacao para saber qual periodo é e passar o periodo correto para a query do firebase
                    if (periodoEstatico == 'Hoje') {
                        dataInicioConsulta = dataHoje + ' 00:00:01';
                        dataFinalConsulta = dataHoje + ' 23:59:59';
                        enviaPeriodoPersonalizadoAvaliacaoAtendente(dispatch, periodoEstatico);
                    }
                    else if (periodoEstatico == 'Ontem') {
                        dataInicioConsulta = datadeOntem + ' 00:00:01';
                        dataFinalConsulta = datadeOntem + ' 23:59:59';
                        enviaPeriodoPersonalizadoAvaliacaoAtendente(dispatch, periodoEstatico);
                    }
                    else if (periodoEstatico == 'Semana Atual') {
                        dataInicioConsulta = dataComecoSemana + ' 00:00:01';
                        dataFinalConsulta = dataFimSemana + ' 23:59:59';
                        enviaPeriodoPersonalizadoAvaliacaoAtendente(dispatch, periodoEstatico);
                    }
                    else if (periodoEstatico == 'Semana Passada') {
                        dataInicioConsulta = dataComecoSemanaPassada + ' 00:00:01';
                        dataFinalConsulta = dataFimDaSemanaPassada + ' 23:59:59';
                        enviaPeriodoPersonalizadoAvaliacaoAtendente(dispatch, periodoEstatico);
                    }
                    else if (periodoEstatico.includes('Mês Atual') && valorReferencia == 0) {
                        dataInicioConsulta = comecoDoMesAtual + ' 00:00:01';
                        dataFinalConsulta = fimDoMesAtual + ' 23:59:59';
                        // enviaPeriodoPersonalizadoAvaliacaoAtendente(dispatch, `${periodoEstatico}, ${nomeMesAtual}.`);
                    }
                    else if (periodoEstatico.includes('Mês Passado') && valorReferencia == 0) {
                        dataInicioConsulta = comecoDoMesPassado + ' 00:00:01';
                        dataFinalConsulta = fimDoMesPassado + ' 23:59:59';
                        //  enviaPeriodoPersonalizadoAvaliacaoAtendente(dispatch, `${periodoEstatico}, ${nomeMesPassado}.`);
                    }
                    else if (periodoEstatico.includes('Mês Atual') && valorReferencia == 1) {
                        dataInicioConsulta = comecoDoMesAtual + ' 00:00:01';
                        dataFinalConsulta = fimDoMesAtual + ' 23:59:59';
                        enviaPeriodoPersonalizadoAvaliacaoAtendente(dispatch, `${periodoEstatico}, ${nomeMesAtual}.`);
                    }
                    else if (periodoEstatico.includes('Mês Passado') && valorReferencia == 1) {
                        dataInicioConsulta = comecoDoMesPassado + ' 00:00:01';
                        dataFinalConsulta = fimDoMesPassado + ' 23:59:59';
                        enviaPeriodoPersonalizadoAvaliacaoAtendente(dispatch, `${periodoEstatico}, ${nomeMesPassado}.`);
                    }
                    else {
                        
                        //nunca vai chegar aqui, mas pra previnir vou retornar como se fosse semana atual
                        dataInicioConsulta = dataComecoSemana + ' 00:00:01';
                        dataFinalConsulta = dataFimSemana + ' 23:59:59';
                        enviaPeriodoPersonalizadoAvaliacaoAtendente(dispatch, periodoEstatico);

                    }
                    
                    dataInicioConsulta = retornaDataTimeStamp(dataInicioConsulta);
                    dataFinalConsulta = retornaDataTimeStamp(dataFinalConsulta);
                   

                    zeraDataInicialAvaliacaoAtendente(dispatch);
                    zeraDataFinalAvaliacaoAtendente(dispatch);
                   
                    firebase.database().ref(`${chaveUnica}/avaliacoes`).orderByChild('dataHoraAvaliacaoTimeStamp')
                        .startAt(dataInicioConsulta).endAt(dataFinalConsulta).once("value").then((snapshot) => {
                            
                            
                            if (snapshot.val()) {
                                //envia os valores para o grafico
                                let arrayOfKeys = Object.keys(snapshot.val()).sort().reverse();
                                let results = arrayOfKeys.map((key) => snapshot.val()[key]);
                                for (let i = 0; i < results.length; i++) {
                                    if (keyAtendente == results[i].keyAtendente) {
                                        verificaSeAtendenteTemAvaliacao = true;
                                       
                                        totalAvaliacoes++;
                                        if (results[i].valorAvaliacao == 1) {
                                            atendimento1estrela++;
                                        }
                                        if (results[i].valorAvaliacao == 2) {
                                            atendimento2estrela++;
                                        }
                                        if (results[i].valorAvaliacao == 3) {
                                            atendimento3estrela++;
                                        }
                                        if (results[i].valorAvaliacao == 4) {
                                            atendimento4estrela++;
                                        }
                                        if (results[i].valorAvaliacao == 5) {
                                            atendimento5estrela++;
                                        }
                                    }
                                }
                                if (verificaSeAtendenteTemAvaliacao == true) {
                                    enviaAvaliacao1estrelaAtendente(dispatch, atendimento1estrela);
                                    enviaAvaliacao2estrelaAtendente(dispatch, atendimento2estrela);
                                    enviaAvaliacao3estrelaAtendente(dispatch, atendimento3estrela);
                                    enviaAvaliacao4estrelaAtendente(dispatch, atendimento4estrela);
                                    enviaAvaliacao5estrelaAtendente(dispatch, atendimento5estrela);
                                    enviaTotalAvaliacoesAtendente(dispatch, totalAvaliacoes);

                                   
                                   
                                   
                                   
                                   
                                   
                                    porcentagem1estrela = (atendimento1estrela / totalAvaliacoes) * 100;
                                    porcentagem2estrela = (atendimento2estrela / totalAvaliacoes) * 100;
                                    porcentagem3estrela = (atendimento3estrela / totalAvaliacoes) * 100;
                                    porcentagem4estrela = (atendimento4estrela / totalAvaliacoes) * 100;
                                    porcentagem5estrela = (atendimento5estrela / totalAvaliacoes) * 100;
                                    console.log('porcentagem 1 estrela: ' + porcentagem1estrela.toFixed(2).replace('.', ','));
                                    console.log('porcentagem 2 estrela: ' + porcentagem2estrela.toFixed(2).replace('.', ','));
                                    console.log('porcentagem 3 estrela: ' + porcentagem3estrela.toFixed(2).replace('.', ','));
                                    console.log('porcentagem 4 estrela: ' + porcentagem4estrela.toFixed(2).replace('.', ','));
                                    console.log('porcentagem 5 estrela: ' + porcentagem5estrela.toFixed(2).replace('.', ','));
                                    enviaPorcentagem1estrelaAtendente(dispatch, porcentagem1estrela.toFixed(2).replace('.', ','));
                                    enviaPorcentagem2estrelaAtendente(dispatch, porcentagem2estrela.toFixed(2).replace('.', ','));
                                    enviaPorcentagem3estrelaAtendente(dispatch, porcentagem3estrela.toFixed(2).replace('.', ','));
                                    enviaPorcentagem4estrelaAtendente(dispatch, porcentagem4estrela.toFixed(2).replace('.', ','));
                                    enviaPorcentagem5estrelaAtendente(dispatch, porcentagem5estrela.toFixed(2).replace('.', ','));
                                    let valor1estrelaParaPorcentagem = atendimento1estrela * 1;
                                    let valor2estrelaParaPorcentagem = atendimento2estrela * 2;
                                    let valor3estrelaParaPorcentagem = atendimento3estrela * 3;
                                    let valor4estrelaParaPorcentagem = atendimento4estrela * 4;
                                    let valor5estrelaParaPorcentagem = atendimento5estrela * 5;
                                    
                                    
                                    
                                    
                                   
                                    notaMedia = (valor1estrelaParaPorcentagem + valor2estrelaParaPorcentagem + valor3estrelaParaPorcentagem + valor4estrelaParaPorcentagem + valor5estrelaParaPorcentagem) / totalAvaliacoes;
                                    console.log('notaMedia: ' + notaMedia.toFixed(2).replace('.', ','));
                                    enviaNotaMediaGeralAtendente(dispatch, notaMedia.toFixed(2).replace('.', ','));
                                    let valor1estrelaParaNSO = atendimento1estrela * -2;
                                    let valor2estrelaParaNSO = atendimento2estrela * -1;
                                    let valor3estrelaParaNSO = atendimento3estrela * 0;
                                    let valor4estrelaParaNSO = atendimento4estrela * 1;
                                    let valor5estrelaParaNSO = atendimento5estrela * 2;
                                    notaNSO = (valor1estrelaParaNSO + valor2estrelaParaNSO + valor3estrelaParaNSO + valor4estrelaParaNSO + valor5estrelaParaNSO) / totalAvaliacoes;
                                    console.log('NSO: ' + notaNSO.toFixed(2).replace('.', ','));
                                    enviaNSOGeralAtendente(dispatch, notaNSO.toFixed(2).replace('.', ','));
                                    ativaDesativaLoadingTelaRelatorioAvaliacaoAtendente(dispatch, false);
                                } else {
                                    
                                    enviaAvaliacao1estrelaAtendente(dispatch, atendimento1estrela);
                                    enviaAvaliacao2estrelaAtendente(dispatch, atendimento2estrela);
                                    enviaAvaliacao3estrelaAtendente(dispatch, atendimento3estrela);
                                    enviaAvaliacao4estrelaAtendente(dispatch, atendimento4estrela);
                                    enviaAvaliacao5estrelaAtendente(dispatch, atendimento5estrela);
                                    enviaTotalAvaliacoesAtendente(dispatch, totalAvaliacoes);

                                    enviaPorcentagem1estrelaAtendente(dispatch, porcentagem1estrela.toFixed(2).replace('.', ','));
                                    enviaPorcentagem2estrelaAtendente(dispatch, porcentagem2estrela.toFixed(2).replace('.', ','));
                                    enviaPorcentagem3estrelaAtendente(dispatch, porcentagem3estrela.toFixed(2).replace('.', ','));
                                    enviaPorcentagem4estrelaAtendente(dispatch, porcentagem4estrela.toFixed(2).replace('.', ','));
                                    enviaPorcentagem5estrelaAtendente(dispatch, porcentagem5estrela.toFixed(2).replace('.', ','));
                                    enviaNotaMediaGeralAtendente(dispatch, notaMedia.toFixed(2).replace('.', ','));
                                    enviaNSOGeralAtendente(dispatch, notaNSO.toFixed(2).replace('.', ','));

                                    ativaDesativaLoadingTelaRelatorioAvaliacaoAtendente(dispatch, false);
                                }

                            } else {
                                
                                enviaAvaliacao1estrelaAtendente(dispatch, atendimento1estrela);
                                enviaAvaliacao2estrelaAtendente(dispatch, atendimento2estrela);
                                enviaAvaliacao3estrelaAtendente(dispatch, atendimento3estrela);
                                enviaAvaliacao4estrelaAtendente(dispatch, atendimento4estrela);
                                enviaAvaliacao5estrelaAtendente(dispatch, atendimento5estrela);
                                enviaTotalAvaliacoesAtendente(dispatch, totalAvaliacoes);

                                enviaPorcentagem1estrelaAtendente(dispatch, porcentagem1estrela.toFixed(2).replace('.', ','));
                                enviaPorcentagem2estrelaAtendente(dispatch, porcentagem2estrela.toFixed(2).replace('.', ','));
                                enviaPorcentagem3estrelaAtendente(dispatch, porcentagem3estrela.toFixed(2).replace('.', ','));
                                enviaPorcentagem4estrelaAtendente(dispatch, porcentagem4estrela.toFixed(2).replace('.', ','));
                                enviaPorcentagem5estrelaAtendente(dispatch, porcentagem5estrela.toFixed(2).replace('.', ','));
                                enviaNotaMediaGeralAtendente(dispatch, notaMedia.toFixed(2).replace('.', ','));
                                enviaNSOGeralAtendente(dispatch, notaNSO.toFixed(2).replace('.', ','));

                                ativaDesativaLoadingTelaRelatorioAvaliacaoAtendente(dispatch, false);
                            }
                        })

                })
            } else {
                //sem internet
                toastr.error('Error', 'Sem Conexão com a Internet');
                ativaDesativaLoadingTelaRelatorioAvaliacaoAtendente(dispatch, false);
            }
        })
    }
} */
export const listaAvaliacoesAtendentePeriodoPersonalizado = (dataInicial2, dataFinal2, valorReferencia, keyAtendente) => {
    let dataInicial, dataFinal;
    // 
    return dispatch => {
        if (valorReferencia == 0) {
            //valor Personalizado
            dataInicial = moment(dataInicial2).format('DD/MM/YYYY')
            dataFinal = moment(dataFinal2).format('DD/MM/YYYY')
        } else {
            //valor Inicial
            let dataHojeWeb = moment().toDate();
            let dataHojeEnviaDatePicker = moment().toDate();
            dataHojeEnviaDatePicker = moment(dataHojeEnviaDatePicker);
            dataHojeWeb = moment(dataHojeWeb).format('DD/MM/YYYY');

            dataInicial = dataHojeWeb;
            dataFinal = dataHojeWeb;
            dispatch({ type: MODIFICADATAINICIALAVALIACAOGERAL, payload: dataHojeEnviaDatePicker });
            dispatch({ type: MODIFICADATAFINALAVALIACAOGERAL, payload: dataHojeEnviaDatePicker });
        }
        // 
        // 
        // 
        let atendimento1estrela = 0;
        let atendimento2estrela = 0;
        let atendimento3estrela = 0;
        let atendimento4estrela = 0;
        let atendimento5estrela = 0;
        let totalAvaliacoes = 0;
        let porcentagem1estrela = 0.00;
        let porcentagem2estrela = 0.00;
        let porcentagem3estrela = 0.00;
        let porcentagem4estrela = 0.00;
        let porcentagem5estrela = 0.00;
        let notaMedia = 0.00;
        let notaNSO = 0.00;
        let verificaSeAtendenteTemAvaliacao = false;

        let dataInicialVerificacao = moment(dataInicial, 'DD/MM/YYYY').format('YYYY-MM-DD');
        let dataFinalVerificacao = moment(dataFinal, 'DD/MM/YYYY').format('YYYY-MM-DD');
        let verificaSeDataInicialMaiorQueDataFinal = moment(dataFinalVerificacao).isBefore(dataInicialVerificacao);

        AsyncStorage.getItem('chaveUnica').then((chaveUnica2) => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            // 
            if (dataInicial == '' && dataFinal == '' || dataInicial == null && dataFinal == null) {
                toastr.error('Error', 'Informe a DATA INICIAL e DATA FINAL do período de pesquisa.');
            }
            else if (dataInicial == '' || dataInicial == null) {
                toastr.error('Error', 'Informe a DATA INICIAL do período de pesquisa.');
            }
            else if (dataFinal == '' || dataFinal == null) {
                toastr.error('Error', 'Informe a DATA FINAL do período de pesquisa.');
            }
            else if (verificaSeDataInicialMaiorQueDataFinal == true) {
                toastr.error('Error', 'DATA INICIAL maior que DATA FINAL, por favor verifique.');
            }
            else {
                // enviaSemConexaoInternetDashBoard(dispatch, false);
                ///   ativaDesativaLoadingTelaRelatorioAvaliacaoAtendente(dispatch, true);
                ativaDesativaLoadingTelaRelatorioAvaliacaoGeral(dispatch, true);
                enviaPeriodoPersonalizadoAvaliacaoAtendente(dispatch, `${dataInicial} - ${dataFinal}`);

                dataInicial = dataInicial + ' 00:00:01';
                dataFinal = dataFinal + ' 23:59:59';
                let dataAtual = retornaDataAtual();
                let dataInicioConsulta, dataFinalConsulta;

                //verificacao para saber qual periodo é e passar o periodo correto para a query do firebase
                //   
                dataInicioConsulta = retornaDataTimeStamp(dataInicial);
                dataFinalConsulta = retornaDataTimeStamp(dataFinal);
                // 
                // 
                /* firebase.database().ref(`${chaveUnica}/avaliacoes/`).orderByChild('dataHoraAvaliacaoTimeStamp')
                    .startAt(dataInicioConsulta).endAt(dataFinalConsulta).once("value").then((snapshot) => {
                        //    
                        //   
                        if (snapshot.val()) { */
                const options = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                        'Accept': 'application/json',
                    },

                    data: { chaveUnica: chaveUnica, dataInicioConsulta: dataInicioConsulta, dataFinalConsulta: dataFinalConsulta, rota: 'avaliacao' },
                    url: 'gerarRelatoriosAtendimentos',
                };
                api(options).then((snapshot) => {
                    
                    
                    snapshot = snapshot.data;
                    
                    if (snapshot !== null) {
                        //envia os valores para o grafico
                        let arrayOfKeys = Object.keys(snapshot).sort().reverse();
                        let results = arrayOfKeys.map((key) => snapshot[key]);
                        for (let i = 0; i < results.length; i++) {
                            if (keyAtendente == results[i].keyAtendente) {
                                verificaSeAtendenteTemAvaliacao = true;
                                //
                                totalAvaliacoes++;
                                if (results[i].valorAvaliacao == 1) {
                                    atendimento1estrela++;
                                }
                                if (results[i].valorAvaliacao == 2) {
                                    atendimento2estrela++;
                                }
                                if (results[i].valorAvaliacao == 3) {
                                    atendimento3estrela++;
                                }
                                if (results[i].valorAvaliacao == 4) {
                                    atendimento4estrela++;
                                }
                                if (results[i].valorAvaliacao == 5) {
                                    atendimento5estrela++;
                                }
                            }
                        }
                        if (verificaSeAtendenteTemAvaliacao == true) {
                            enviaAvaliacao1estrela(dispatch, atendimento1estrela);
                            enviaAvaliacao2estrela(dispatch, atendimento2estrela);
                            enviaAvaliacao3estrela(dispatch, atendimento3estrela);
                            enviaAvaliacao4estrela(dispatch, atendimento4estrela);
                            enviaAvaliacao5estrela(dispatch, atendimento5estrela);
                            enviaTotalAvaliacoes(dispatch, totalAvaliacoes);

                            /*   
                              
                              
                              
                              
                               */
                            porcentagem1estrela = (atendimento1estrela / totalAvaliacoes) * 100;
                            porcentagem2estrela = (atendimento2estrela / totalAvaliacoes) * 100;
                            porcentagem3estrela = (atendimento3estrela / totalAvaliacoes) * 100;
                            porcentagem4estrela = (atendimento4estrela / totalAvaliacoes) * 100;
                            porcentagem5estrela = (atendimento5estrela / totalAvaliacoes) * 100;
                            /*     console.log('porcentagem 1 estrela: ' + porcentagem1estrela.toFixed(2).replace('.', ','));
                                console.log('porcentagem 2 estrela: ' + porcentagem2estrela.toFixed(2).replace('.', ','));
                                console.log('porcentagem 3 estrela: ' + porcentagem3estrela.toFixed(2).replace('.', ','));
                                console.log('porcentagem 4 estrela: ' + porcentagem4estrela.toFixed(2).replace('.', ','));
                                console.log('porcentagem 5 estrela: ' + porcentagem5estrela.toFixed(2).replace('.', ',')); */
                            enviaPorcentagem1estrela(dispatch, porcentagem1estrela.toFixed(2).replace('.', ','));
                            enviaPorcentagem2estrela(dispatch, porcentagem2estrela.toFixed(2).replace('.', ','));
                            enviaPorcentagem3estrela(dispatch, porcentagem3estrela.toFixed(2).replace('.', ','));
                            enviaPorcentagem4estrela(dispatch, porcentagem4estrela.toFixed(2).replace('.', ','));
                            enviaPorcentagem5estrela(dispatch, porcentagem5estrela.toFixed(2).replace('.', ','));
                            let valor1estrelaParaPorcentagem = atendimento1estrela * 1;
                            let valor2estrelaParaPorcentagem = atendimento2estrela * 2;
                            let valor3estrelaParaPorcentagem = atendimento3estrela * 3;
                            let valor4estrelaParaPorcentagem = atendimento4estrela * 4;
                            let valor5estrelaParaPorcentagem = atendimento5estrela * 5;
                            /*   
                              
                              
                              
                              */
                            notaMedia = (valor1estrelaParaPorcentagem + valor2estrelaParaPorcentagem + valor3estrelaParaPorcentagem + valor4estrelaParaPorcentagem + valor5estrelaParaPorcentagem) / totalAvaliacoes;
                            //   console.log('notaMedia: ' + notaMedia.toFixed(2).replace('.', ','));
                            enviaNotaMediaGeral(dispatch, notaMedia.toFixed(2).replace('.', ','));
                            let valor1estrelaParaNSO = atendimento1estrela * -2;
                            let valor2estrelaParaNSO = atendimento2estrela * -1;
                            let valor3estrelaParaNSO = atendimento3estrela * 0;
                            let valor4estrelaParaNSO = atendimento4estrela * 1;
                            let valor5estrelaParaNSO = atendimento5estrela * 2;
                            notaNSO = (valor1estrelaParaNSO + valor2estrelaParaNSO + valor3estrelaParaNSO + valor4estrelaParaNSO + valor5estrelaParaNSO) / totalAvaliacoes;
                            //  console.log('NSO: ' + notaNSO.toFixed(2).replace('.', ','));
                            enviaNSOGeral(dispatch, notaNSO.toFixed(2).replace('.', ','));
                            ativaDesativaLoadingTelaRelatorioAvaliacaoGeral(dispatch, false);
                        } else {
                            // 
                            enviaAvaliacao1estrela(dispatch, atendimento1estrela);
                            enviaAvaliacao2estrela(dispatch, atendimento2estrela);
                            enviaAvaliacao3estrela(dispatch, atendimento3estrela);
                            enviaAvaliacao4estrela(dispatch, atendimento4estrela);
                            enviaAvaliacao5estrela(dispatch, atendimento5estrela);
                            enviaTotalAvaliacoes(dispatch, totalAvaliacoes);

                            enviaPorcentagem1estrela(dispatch, porcentagem1estrela.toFixed(2).replace('.', ','));
                            enviaPorcentagem2estrela(dispatch, porcentagem2estrela.toFixed(2).replace('.', ','));
                            enviaPorcentagem3estrela(dispatch, porcentagem3estrela.toFixed(2).replace('.', ','));
                            enviaPorcentagem4estrela(dispatch, porcentagem4estrela.toFixed(2).replace('.', ','));
                            enviaPorcentagem5estrela(dispatch, porcentagem5estrela.toFixed(2).replace('.', ','));
                            enviaNotaMediaGeral(dispatch, notaMedia.toFixed(2).replace('.', ','));
                            enviaNSOGeral(dispatch, notaNSO.toFixed(2).replace('.', ','));

                            ativaDesativaLoadingTelaRelatorioAvaliacaoGeral(dispatch, false);
                        }

                    } else {
                        // 
                        enviaAvaliacao1estrela(dispatch, atendimento1estrela);
                        enviaAvaliacao2estrela(dispatch, atendimento2estrela);
                        enviaAvaliacao3estrela(dispatch, atendimento3estrela);
                        enviaAvaliacao4estrela(dispatch, atendimento4estrela);
                        enviaAvaliacao5estrela(dispatch, atendimento5estrela);
                        enviaTotalAvaliacoes(dispatch, totalAvaliacoes);

                        enviaPorcentagem1estrela(dispatch, porcentagem1estrela.toFixed(2).replace('.', ','));
                        enviaPorcentagem2estrela(dispatch, porcentagem2estrela.toFixed(2).replace('.', ','));
                        enviaPorcentagem3estrela(dispatch, porcentagem3estrela.toFixed(2).replace('.', ','));
                        enviaPorcentagem4estrela(dispatch, porcentagem4estrela.toFixed(2).replace('.', ','));
                        enviaPorcentagem5estrela(dispatch, porcentagem5estrela.toFixed(2).replace('.', ','));
                        enviaNotaMediaGeral(dispatch, notaMedia.toFixed(2).replace('.', ','));
                        enviaNSOGeral(dispatch, notaNSO.toFixed(2).replace('.', ','));

                        ativaDesativaLoadingTelaRelatorioAvaliacaoGeral(dispatch, false);
                    }
                })
            }

        })

    }
}

/* export const listaAtendenteTelaRelatorioAvaliacaoAtendente = () => {
    return dispatch => {
        ativaDesativaLoadingSectionedAtendenteRelatorioAtendente(dispatch, true);
        AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild("nome").on("value", snapshot => {
                if (snapshot.val() == null) {
                    const arrayUser = [];
                    enviaAtendentesParaSectionedRelatorioAtendente(dispatch, arrayUser);
                    ativaDesativaLoadingSectionedAtendenteRelatorioAtendente(dispatch, false);
                } else {
                    const children2 = [];
                    snapshot.forEach(childItem => {
                        if (childItem.val().excluido == 1 || childItem.val().clienteBoqueadoDevidoLimiteExcedido == 1) {
                            
                        } else {
                            children2.push({
                                id: childItem.key,
                                name: childItem.val().nome
                            });
                        }
                    });
                    const items = [{ children: children2 }];
                    enviaAtendentesParaSectionedRelatorioAtendente(dispatch, items);
                    ativaDesativaLoadingSectionedAtendenteRelatorioAtendente(dispatch, false);
                }
            });
        });
    };
}; */

export const listaAsUltimasAvaliacoes = (valor, referenciaUltimaKey, avaliacoesAnteriores, destino) => {
    return dispatch => {
        let referenceToOldestKey;
        // 
        AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            if (valor == 0) { // if initial fetch
                ativaDesativaLoadingTelaTodasAvaliacoes(dispatch, true)
                ativaDesativaLoadingRefreshControlTodasAvaliacoes(dispatch, true);
                firebase.database().ref(`${chaveUnica}/avaliacoes/`).orderByKey().limitToLast(9).on('value', snapshot => {
                    if (snapshot.val() == null) {
                        enviaAvaliacoesTelaTodasAvaliacoes(dispatch, []);
                        ativaDesativaLoadingTelaTodasAvaliacoes(dispatch, false)
                        ativaDesativaLoadingRefreshControlTodasAvaliacoes(dispatch, false);
                    } else {

                        // changing to reverse chronological order (latest first)
                        let arrayOfKeys = Object.keys(snapshot.val()).sort().reverse();
                        //.reverse();
                        // transforming to array
                        let results = arrayOfKeys
                            .map((key) => snapshot.val()[key]);
                        // storing reference
                        referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
                        let chamados = [];
                        let arrayChamado = [];
                        let promisseCliente;
                        let promisseAtendente;
                        let promisseSetor;
                        // 
                        //  
                        //  
                        for (let i = 0; i < results.length; i++) {
                            let cliente = results[i].keyCliente;
                            let atendente = results[i].keyAtendente;
                            let setor = results[i].keySetor;
                            promisseCliente = firebase.database().ref(`${chaveUnica}/clientes/${cliente}/`).once('value')
                            promisseAtendente = firebase.database().ref(`${chaveUnica}/usuarios/${atendente}/nome`).once('value')
                            promisseSetor = firebase.database().ref(`${chaveUnica}/setores/${setor}/nome`).once('value')
                            arrayChamado.push(promisseAtendente, promisseCliente, promisseSetor);

                        }
                        let aux = 0;
                        Promise.all(arrayChamado).then(responses => {
                            for (let i = 0; i < responses.length; i += 3) {

                                chamados.push({
                                    key: arrayOfKeys[aux],
                                    cliente: responses[i + 1].val() == null ? "Não informado" : responses[i + 1].val().nome,
                                    empresa: responses[i + 1].val() == null ? "Não informado" : responses[i + 1].val().empresa,
                                    setor: results[aux].keySetor !== undefined ? responses[i + 2].val() == null ? "Não informado" : responses[i + 2].val() : results[aux].setor,
                                    // cliente: (responses[i + 1].val() == null) ? 'Não informado' : responses[i + 1].val(),
                                    atendente: (responses[i].val() == null) ? 'Não informado' : responses[i].val(),
                                    dataHoraAbertura: results[aux].dataHoraAvaliacao,
                                    status: results[aux].status,
                                    prioridade: (results[aux].prioridade === '') ? 'Não informado' : results[aux].prioridade,
                                    descricaoProblema: results[aux].descricaoProblema,
                                    solucaoAtendimento: results[aux].solucaoAtendimento == undefined ? '' : results[aux].solucaoAtendimento,
                                    alterado: results[aux].alterado,
                                    chaveUnica: chaveUnica,
                                    keyCliente: results[aux].keyCliente,
                                    keyAtendente: results[aux].keyAtendente,
                                    valorAvaliacao: results[aux].valorAvaliacao,
                                    comentarioAvaliacao: results[aux].comentarioAvaliacao,
                                    numeroChamado: results[aux].numeroChamado,
                                    dataVencimento: results[aux].dataVencimento,
                                    keySetor: results[aux].keySetor,

                                    local: results[aux].local,
                                });
                                aux++

                            }
                            enviaAvaliacoesTelaTodasAvaliacoes(dispatch, chamados);
                            ativaDesativaLoadingTelaTodasAvaliacoes(dispatch, false)
                            ativaDesativaLoadingRefreshControlTodasAvaliacoes(dispatch, false);
                            enviaUltimaReferenciaKeyAvaliacao(dispatch, referenceToOldestKey);
                            ativaDesativaLoadingFinalPaginaTodasAvaliacoes(dispatch, true);
                        });


                    }
                })

                /*     .catch((error) => {  }); */


            } else {
                if (referenciaUltimaKey != undefined && referenciaUltimaKey != '') {
                    firebase.database().ref(`${chaveUnica}/avaliacoes/`).orderByKey().endAt(referenciaUltimaKey).limitToLast(10).once('value').then((snapshot) => {
                        if (snapshot.val() == null) {
                            ativaDesativaLoadingFinalPaginaTodasAvaliacoes(dispatch, false);
                        } else {
                            // 
                            //   ativaDesativaLoadingFinalPaginaTodasAvaliacoes(dispatch, true);
                            // changing to reverse chronological order (latest first)
                            // & removing duplicate
                            let arrayOfKeys = Object.keys(snapshot.val())
                                .sort()
                                .reverse()
                                .slice(1);
                            // transforming to array
                            let results = arrayOfKeys
                                .map((key) => snapshot.val()[key]);
                            // updating reference
                            let referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];

                            let chamados = [];
                            let arrayChamado = [];
                            let promisseCliente;
                            let promisseAtendente
                            for (let i = 0; i < results.length; i++) {
                                let cliente = results[i].keyCliente;
                                let atendente = results[i].keyAtendente;
                                promisseCliente = firebase.database().ref(`${chaveUnica}/clientes/${cliente}/`).once('value')
                                promisseAtendente = firebase.database().ref(`${chaveUnica}/usuarios/${atendente}/nome`).once('value')
                                arrayChamado.push(promisseAtendente, promisseCliente);

                            }
                            let aux = 0;
                            Promise.all(arrayChamado).then(responses => {
                                for (let i = 0; i < responses.length; i += 2) {

                                    chamados.push({
                                        key: arrayOfKeys[aux],
                                        cliente: responses[i + 1].val() == null ? "Não informado" : responses[i + 1].val().nome,
                                        empresa: responses[i + 1].val() == null ? "Não informado" : responses[i + 1].val().empresa,
                                        //  cliente: (responses[i + 1].val() == null) ? 'Não informado' : responses[i + 1].val(),
                                        atendente: (responses[i].val() == null) ? 'Não informado' : responses[i].val(),
                                        dataHoraAbertura: results[aux].dataHoraAvaliacao,
                                        status: results[aux].status,
                                        prioridade: (results[aux].prioridade === '') ? 'Não informado' : results[aux].prioridade,
                                        descricaoProblema: results[aux].descricaoProblema,
                                        solucaoAtendimento: results[aux].solucaoAtendimento == undefined ? '' : results[aux].solucaoAtendimento,
                                        alterado: results[aux].alterado,
                                        chaveUnica: chaveUnica,
                                        keyCliente: results[aux].keyCliente,
                                        valorAvaliacao: results[aux].valorAvaliacao,
                                        comentarioAvaliacao: results[aux].comentarioAvaliacao,
                                        numeroChamado: results[aux].numeroChamado,
                                        dataVencimento: results[aux].dataVencimento,
                                        keySetor: results[aux].keySetor,
                                        setor: results[aux].setor,
                                        local: results[aux].local,
                                    });
                                    aux++

                                }
                                //  ativaDesativaLoadingFinalPaginaTodasAvaliacoes(dispatch, false)
                                enviaAvaliacoesTelaTodasAvaliacoes(dispatch, avaliacoesAnteriores.concat(chamados));
                                enviaUltimaReferenciaKeyAvaliacao(dispatch, referenceToOldestKey)

                            });
                        }
                    })

                    /*  .catch((error) => {  }); */
                } else {
                    ativaDesativaLoadingFinalPaginaTodasAvaliacoes(dispatch, false);
                }
            }
        })


    }
}
export const listaUltimasAvaliacoesComCliente = (valor, avaliacoesAnteriores, ultimaKey, cliente) => {
    return dispatch => {
        // 
        AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");

            if (valor == 0) { // if initial fetch

                firebase.database().ref(`${chaveUnica}/avaliacoes/`).orderByChild("keyCliente").equalTo(cliente).limitToLast(7)
                    .once('value').then((snapshot) => {
                        ativaDesativaLoadingTelaTodasAvaliacoes(dispatch, true);
                        if (snapshot.val() == null) {
                            enviaAvaliacoesTelaTodasAvaliacoes(dispatch, []);
                            ativaDesativaLoadingTelaTodasAvaliacoes(dispatch, false);
                        } else {

                            // changing to reverse chronological order (latest first)
                            let arrayOfKeys = Object.keys(snapshot.val())
                                .sort()
                                .reverse();
                            // transforming to array
                            let results = arrayOfKeys
                                .map((key) => snapshot.val()[key]);
                            // storing reference
                            let referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
                            let chamados = [];
                            let arrayChamado = [];
                            let promisseCliente;
                            let promisseAtendente;
                            for (let i = 0; i < results.length; i++) {
                                let cliente = results[i].keyCliente;
                                let atendente = results[i].keyAtendente;
                                promisseCliente = firebase.database().ref(`${chaveUnica}/clientes/${cliente}/`).once('value')
                                promisseAtendente = firebase.database().ref(`${chaveUnica}/usuarios/${atendente}/nome`).once('value')
                                arrayChamado.push(promisseAtendente, promisseCliente);

                            }
                            let aux = 0;
                            Promise.all(arrayChamado).then(responses => {
                                for (let i = 0; i < responses.length; i += 2) {

                                    chamados.push({
                                        key: arrayOfKeys[aux],
                                        cliente: responses[i + 1].val() == null ? "Não informado" : responses[i + 1].val().nome,
                                        empresa: responses[i + 1].val() == null ? "Não informado" : responses[i + 1].val().empresa,
                                        //  cliente: (responses[i + 1].val() == null) ? 'Não informado' : responses[i + 1].val(),
                                        atendente: (responses[i].val() == null) ? 'Não informado' : responses[i].val(),
                                        dataHoraAbertura: results[aux].dataHoraAvaliacao,
                                        status: results[aux].status,
                                        prioridade: (results[aux].prioridade === '') ? 'Não informado' : results[aux].prioridade,
                                        descricaoProblema: results[aux].descricaoProblema,
                                        solucaoAtendimento: results[aux].solucaoAtendimento == undefined ? '' : results[aux].solucaoAtendimento,
                                        alterado: results[aux].alterado,
                                        chaveUnica: chaveUnica,
                                        keyCliente: results[aux].keyCliente,
                                        keyAtendente: results[aux].keyAtendente,
                                        valorAvaliacao: results[aux].valorAvaliacao,
                                        comentarioAvaliacao: results[aux].comentarioAvaliacao,
                                        numeroChamado: results[aux].numeroChamado,
                                        dataVencimento: results[aux].dataVencimento,
                                        keySetor: results[aux].keySetor,
                                        setor: results[aux].setor,
                                        local: results[aux].local,
                                    });
                                    aux++

                                }
                                enviaUltimaReferenciaKeyClienteTodasAvaliacoes(dispatch, referenceToOldestKey);
                                enviaAvaliacoesTelaTodasAvaliacoes(dispatch, chamados);
                                ativaDesativaLoadingTelaTodasAvaliacoes(dispatch, false);
                                ativaDesativaLoadingFinalPaginaTodasAvaliacoes(dispatch, true);
                            });

                        }
                    })

                /* .catch((error) => {  }); */


            } else {
                if (ultimaKey != undefined && ultimaKey != '') {
                    firebase.database().ref(`${chaveUnica}/avaliacoes/`).orderByChild("keyCliente").startAt(cliente).endAt(cliente, ultimaKey)
                        .limitToLast(8).once('value').then((snapshot) => {
                            if (snapshot.val() == null) {
                                ativaDesativaLoadingFinalPaginaTodasAvaliacoes(dispatch, false);
                            } else {

                                // changing to reverse chronological order (latest first)
                                // & removing duplicate
                                let arrayOfKeys = Object.keys(snapshot.val())
                                    .sort()
                                    .reverse()
                                    .slice(1);
                                // transforming to array
                                let results = arrayOfKeys
                                    .map((key) => snapshot.val()[key]);
                                // updating reference
                                let referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
                                let chamados = [];
                                let arrayChamado = [];
                                let promisseCliente;
                                let promisseAtendente
                                for (let i = 0; i < results.length; i++) {
                                    let cliente = results[i].keyCliente;
                                    let atendente = results[i].keyAtendente;
                                    promisseCliente = firebase.database().ref(`${chaveUnica}/clientes/${cliente}/`).once('value')
                                    promisseAtendente = firebase.database().ref(`${chaveUnica}/usuarios/${atendente}/nome`).once('value')
                                    arrayChamado.push(promisseAtendente, promisseCliente);

                                }
                                let aux = 0;
                                Promise.all(arrayChamado).then(responses => {
                                    for (let i = 0; i < responses.length; i += 2) {

                                        chamados.push({
                                            key: arrayOfKeys[aux],
                                            cliente: responses[i + 1].val() == null ? "Não informado" : responses[i + 1].val().nome,
                                            empresa: responses[i + 1].val() == null ? "Não informado" : responses[i + 1].val().empresa,
                                            // cliente: (responses[i + 1].val() == null) ? 'Não informado' : responses[i + 1].val(),
                                            atendente: (responses[i].val() == null) ? 'Não informado' : responses[i].val(),
                                            dataHoraAbertura: results[aux].dataHoraAvaliacao,
                                            status: results[aux].status,
                                            prioridade: (results[aux].prioridade === '') ? 'Não informado' : results[aux].prioridade,
                                            descricaoProblema: results[aux].descricaoProblema,
                                            solucaoAtendimento: results[aux].solucaoAtendimento == undefined ? '' : results[aux].solucaoAtendimento,
                                            alterado: results[aux].alterado,
                                            chaveUnica: chaveUnica,
                                            keyCliente: results[aux].keyCliente,
                                            keyAtendente: results[aux].keyAtendente,
                                            valorAvaliacao: results[aux].valorAvaliacao,
                                            comentarioAvaliacao: results[aux].comentarioAvaliacao,
                                            numeroChamado: results[aux].numeroChamado,
                                            dataVencimento: results[aux].dataVencimento,
                                            keySetor: results[aux].keySetor,
                                            setor: results[aux].setor,
                                            local: results[aux].local,
                                        });
                                        aux++

                                    }

                                    enviaAvaliacoesTelaTodasAvaliacoes(dispatch, avaliacoesAnteriores.concat(chamados));
                                    enviaUltimaReferenciaKeyClienteTodasAvaliacoes(dispatch, referenceToOldestKey)
                                    //  ativaDesativaLoadingFinalPaginaTodasAvaliacoes(dispatch, false);
                                });
                            }
                        })

                    /*   .catch((error) => {  }); */

                } else {
                    ativaDesativaLoadingFinalPaginaTodasAvaliacoes(dispatch, false);
                }
            }
        })

    }
}
export const listaUltimasAvaliacoesComAtendente = (valor, avaliacoesAnteriores, ultimaKey, atendente) => {
    return dispatch => {
        //  
        AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");

            if (valor == 0) { // if initial fetch

                firebase.database().ref(`${chaveUnica}/avaliacoes/`).orderByChild("keyAtendente").equalTo(atendente).limitToLast(7)
                    .once('value').then((snapshot) => {
                        ativaDesativaLoadingTelaTodasAvaliacoes(dispatch, true);
                        if (snapshot.val() == null) {
                            enviaAvaliacoesTelaTodasAvaliacoes(dispatch, []);
                            ativaDesativaLoadingTelaTodasAvaliacoes(dispatch, false);
                        } else {

                            // changing to reverse chronological order (latest first)
                            let arrayOfKeys = Object.keys(snapshot.val())
                                .sort()
                                .reverse();
                            // transforming to array
                            let results = arrayOfKeys
                                .map((key) => snapshot.val()[key]);
                            // storing reference
                            let referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
                            let chamados = [];
                            let arrayChamado = [];
                            let promisseCliente;
                            let promisseAtendente;
                            for (let i = 0; i < results.length; i++) {
                                let cliente = results[i].keyCliente;
                                let atendente = results[i].keyAtendente;
                                promisseCliente = firebase.database().ref(`${chaveUnica}/clientes/${cliente}/`).once('value')
                                promisseAtendente = firebase.database().ref(`${chaveUnica}/usuarios/${atendente}/nome`).once('value')
                                arrayChamado.push(promisseAtendente, promisseCliente);

                            }
                            let aux = 0;
                            Promise.all(arrayChamado).then(responses => {
                                for (let i = 0; i < responses.length; i += 2) {

                                    chamados.push({
                                        key: arrayOfKeys[aux],
                                        cliente: responses[i + 1].val() == null ? "Não informado" : responses[i + 1].val().nome,
                                        empresa: responses[i + 1].val() == null ? "Não informado" : responses[i + 1].val().empresa,
                                        // cliente: (responses[i + 1].val() == null) ? 'Não informado' : responses[i + 1].val(),
                                        atendente: (responses[i].val() == null) ? 'Não informado' : responses[i].val(),
                                        dataHoraAbertura: results[aux].dataHoraAvaliacao,
                                        status: results[aux].status,
                                        prioridade: (results[aux].prioridade === '') ? 'Não informado' : results[aux].prioridade,
                                        descricaoProblema: results[aux].descricaoProblema,
                                        solucaoAtendimento: results[aux].solucaoAtendimento == undefined ? '' : results[aux].solucaoAtendimento,
                                        alterado: results[aux].alterado,
                                        chaveUnica: chaveUnica,
                                        keyCliente: results[aux].keyCliente,
                                        keyAtendente: results[aux].keyAtendente,
                                        valorAvaliacao: results[aux].valorAvaliacao,
                                        comentarioAvaliacao: results[aux].comentarioAvaliacao,
                                        numeroChamado: results[aux].numeroChamado,
                                        dataVencimento: results[aux].dataVencimento,
                                        keySetor: results[aux].keySetor,
                                        setor: results[aux].setor,
                                        local: results[aux].local,
                                    });
                                    aux++

                                }
                                enviaUltimaReferenciaKeyAtendenteTodasAvaliacoes(dispatch, referenceToOldestKey);
                                enviaAvaliacoesTelaTodasAvaliacoes(dispatch, chamados);
                                ativaDesativaLoadingTelaTodasAvaliacoes(dispatch, false);
                                ativaDesativaLoadingFinalPaginaTodasAvaliacoes(dispatch, true);
                            });

                        }
                    })

                /* .catch((error) => {  }); */


            } else {
                if (ultimaKey != undefined && ultimaKey != '') {
                    firebase.database().ref(`${chaveUnica}/avaliacoes/`).orderByChild("keyAtendente").startAt(atendente).endAt(atendente, ultimaKey)
                        .limitToLast(8).once('value').then((snapshot) => {
                            if (snapshot.val() == null) {
                                ativaDesativaLoadingFinalPaginaTodasAvaliacoes(dispatch, false);
                            } else {

                                // changing to reverse chronological order (latest first)
                                // & removing duplicate
                                let arrayOfKeys = Object.keys(snapshot.val())
                                    .sort()
                                    .reverse()
                                    .slice(1);
                                // transforming to array
                                let results = arrayOfKeys
                                    .map((key) => snapshot.val()[key]);
                                // updating reference
                                let referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
                                let chamados = [];
                                let arrayChamado = [];
                                let promisseCliente;
                                let promisseAtendente
                                for (let i = 0; i < results.length; i++) {
                                    let cliente = results[i].keyCliente;
                                    let atendente = results[i].keyAtendente;
                                    promisseCliente = firebase.database().ref(`${chaveUnica}/clientes/${cliente}/`).once('value')
                                    promisseAtendente = firebase.database().ref(`${chaveUnica}/usuarios/${atendente}/nome`).once('value')
                                    arrayChamado.push(promisseAtendente, promisseCliente);

                                }
                                let aux = 0;
                                Promise.all(arrayChamado).then(responses => {
                                    for (let i = 0; i < responses.length; i += 2) {

                                        chamados.push({
                                            key: arrayOfKeys[aux],
                                            cliente: responses[i + 1].val() == null ? "Não informado" : responses[i + 1].val().nome,
                                            empresa: responses[i + 1].val() == null ? "Não informado" : responses[i + 1].val().empresa,
                                            //  cliente: (responses[i + 1].val() == null) ? 'Não informado' : responses[i + 1].val(),
                                            atendente: (responses[i].val() == null) ? 'Não informado' : responses[i].val(),
                                            dataHoraAbertura: results[aux].dataHoraAvaliacao,
                                            status: results[aux].status,
                                            prioridade: (results[aux].prioridade === '') ? 'Não informado' : results[aux].prioridade,
                                            descricaoProblema: results[aux].descricaoProblema,
                                            solucaoAtendimento: results[aux].solucaoAtendimento == undefined ? '' : results[aux].solucaoAtendimento,
                                            alterado: results[aux].alterado,
                                            chaveUnica: chaveUnica,
                                            keyCliente: results[aux].keyCliente,
                                            keyAtendente: results[aux].keyAtendente,
                                            valorAvaliacao: results[aux].valorAvaliacao,
                                            comentarioAvaliacao: results[aux].comentarioAvaliacao,
                                            numeroChamado: results[aux].numeroChamado,
                                            dataVencimento: results[aux].dataVencimento,
                                            keySetor: results[aux].keySetor,
                                            setor: results[aux].setor,
                                            local: results[aux].local,
                                        });
                                        aux++

                                    }

                                    enviaAvaliacoesTelaTodasAvaliacoes(dispatch, avaliacoesAnteriores.concat(chamados));
                                    enviaUltimaReferenciaKeyAtendenteTodasAvaliacoes(dispatch, referenceToOldestKey)
                                    //  ativaDesativaLoadingFinalPaginaTodasAvaliacoes(dispatch, false);
                                });
                            }
                        })

                    /*  .catch((error) => {  }); */

                } else {
                    ativaDesativaLoadingFinalPaginaTodasAvaliacoes(dispatch, false);
                }
            }
        })

    }
}
export const listaUltimasAvaliacoesComValorAvaliacao = (valor, avaliacoesAnteriores, ultimaKey, valorAvaliacao) => {
    return dispatch => {
       /*    */
        valorAvaliacao = parseInt(valorAvaliacao);
        // 

        AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");

            if (valor == 0) { // if initial fetch

                firebase.database().ref(`${chaveUnica}/avaliacoes/`).orderByChild("valorAvaliacao").equalTo(valorAvaliacao).limitToLast(7)
                    .once('value').then((snapshot) => {
                        ativaDesativaLoadingTelaTodasAvaliacoes(dispatch, true);
                        if (snapshot.val() == null) {
                            enviaAvaliacoesTelaTodasAvaliacoes(dispatch, []);
                            ativaDesativaLoadingTelaTodasAvaliacoes(dispatch, false);
                        } else {

                            // changing to reverse chronological order (latest first)
                            let arrayOfKeys = Object.keys(snapshot.val())
                                .sort()
                                .reverse();
                            // transforming to array
                            let results = arrayOfKeys
                                .map((key) => snapshot.val()[key]);
                            // storing reference
                            let referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
                            let chamados = [];
                            let arrayChamado = [];
                            let promisseCliente;
                            let promisseAtendente;
                            for (let i = 0; i < results.length; i++) {
                                let cliente = results[i].keyCliente;
                                let atendente = results[i].keyAtendente;
                                promisseCliente = firebase.database().ref(`${chaveUnica}/clientes/${cliente}/`).once('value')
                                promisseAtendente = firebase.database().ref(`${chaveUnica}/usuarios/${atendente}/nome`).once('value')
                                arrayChamado.push(promisseAtendente, promisseCliente);

                            }
                            let aux = 0;
                            Promise.all(arrayChamado).then(responses => {
                                for (let i = 0; i < responses.length; i += 2) {

                                    chamados.push({
                                        key: arrayOfKeys[aux],
                                        cliente: responses[i + 1].val() == null ? "Não informado" : responses[i + 1].val().nome,
                                        empresa: responses[i + 1].val() == null ? "Não informado" : responses[i + 1].val().empresa,
                                        //cliente: (responses[i + 1].val() == null) ? 'Não informado' : responses[i + 1].val(),
                                        atendente: (responses[i].val() == null) ? 'Não informado' : responses[i].val(),
                                        dataHoraAbertura: results[aux].dataHoraAvaliacao,
                                        status: results[aux].status,
                                        prioridade: (results[aux].prioridade === '') ? 'Não informado' : results[aux].prioridade,
                                        descricaoProblema: results[aux].descricaoProblema,
                                        solucaoAtendimento: results[aux].solucaoAtendimento == undefined ? '' : results[aux].solucaoAtendimento,
                                        alterado: results[aux].alterado,
                                        chaveUnica: chaveUnica,
                                        keyCliente: results[aux].keyCliente,
                                        keyAtendente: results[aux].keyAtendente,
                                        valorAvaliacao: results[aux].valorAvaliacao,
                                        comentarioAvaliacao: results[aux].comentarioAvaliacao,
                                        numeroChamado: results[aux].numeroChamado,
                                        dataVencimento: results[aux].dataVencimento,
                                        keySetor: results[aux].keySetor,
                                        setor: results[aux].setor,
                                        local: results[aux].local,
                                    });
                                    aux++

                                }
                                enviaUltimaReferenciaKeyValorAvaliacaoTodasAvaliacoes(dispatch, referenceToOldestKey);
                                enviaAvaliacoesTelaTodasAvaliacoes(dispatch, chamados);
                                ativaDesativaLoadingTelaTodasAvaliacoes(dispatch, false);
                                ativaDesativaLoadingFinalPaginaTodasAvaliacoes(dispatch, true);

                            });

                        }
                    })

                /*      .catch((error) => {  }); */


            } else {
                if (ultimaKey != undefined && ultimaKey != '') {
                    firebase.database().ref(`${chaveUnica}/avaliacoes/`).orderByChild("valorAvaliacao").startAt(valorAvaliacao).endAt(valorAvaliacao, ultimaKey)
                        .limitToLast(8).once('value').then((snapshot) => {
                            if (snapshot.val() == null) {
                                ativaDesativaLoadingFinalPaginaTodasAvaliacoes(dispatch, false);
                            } else {

                                // changing to reverse chronological order (latest first)
                                // & removing duplicate
                                let arrayOfKeys = Object.keys(snapshot.val())
                                    .sort()
                                    .reverse()
                                    .slice(1);
                                // transforming to array
                                let results = arrayOfKeys
                                    .map((key) => snapshot.val()[key]);
                                // updating reference
                                let referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
                                let chamados = [];
                                let arrayChamado = [];
                                let promisseCliente;
                                let promisseAtendente
                                for (let i = 0; i < results.length; i++) {
                                    let cliente = results[i].keyCliente;
                                    let atendente = results[i].keyAtendente;
                                    promisseCliente = firebase.database().ref(`${chaveUnica}/clientes/${cliente}/`).once('value')
                                    promisseAtendente = firebase.database().ref(`${chaveUnica}/usuarios/${atendente}/nome`).once('value')
                                    arrayChamado.push(promisseAtendente, promisseCliente);

                                }
                                let aux = 0;
                                Promise.all(arrayChamado).then(responses => {
                                    for (let i = 0; i < responses.length; i += 2) {

                                        chamados.push({
                                            key: arrayOfKeys[aux],
                                            cliente: responses[i + 1].val() == null ? "Não informado" : responses[i + 1].val().nome,
                                            empresa: responses[i + 1].val() == null ? "Não informado" : responses[i + 1].val().empresa,
                                            //cliente: (responses[i + 1].val() == null) ? 'Não informado' : responses[i + 1].val(),
                                            atendente: (responses[i].val() == null) ? 'Não informado' : responses[i].val(),
                                            dataHoraAbertura: results[aux].dataHoraAvaliacao,
                                            status: results[aux].status,
                                            prioridade: (results[aux].prioridade === '') ? 'Não informado' : results[aux].prioridade,
                                            descricaoProblema: results[aux].descricaoProblema,
                                            solucaoAtendimento: results[aux].solucaoAtendimento == undefined ? '' : results[aux].solucaoAtendimento,
                                            alterado: results[aux].alterado,
                                            chaveUnica: chaveUnica,
                                            keyCliente: results[aux].keyCliente,
                                            keyAtendente: results[aux].keyAtendente,
                                            valorAvaliacao: results[aux].valorAvaliacao,
                                            comentarioAvaliacao: results[aux].comentarioAvaliacao,
                                            numeroChamado: results[aux].numeroChamado,
                                            dataVencimento: results[aux].dataVencimento,
                                            keySetor: results[aux].keySetor,
                                            setor: results[aux].setor,
                                            local: results[aux].local,
                                        });
                                        aux++

                                    }

                                    enviaAvaliacoesTelaTodasAvaliacoes(dispatch, avaliacoesAnteriores.concat(chamados));
                                    enviaUltimaReferenciaKeyValorAvaliacaoTodasAvaliacoes(dispatch, referenceToOldestKey)
                                    //   ativaDesativaLoadingFinalPaginaTodasAvaliacoes(dispatch, false);
                                });
                            }
                        })

                    /*  .catch((error) => {  }); */

                } else {
                    ativaDesativaLoadingFinalPaginaTodasAvaliacoes(dispatch, false);
                }
            }
        })

    }
}

export const removeKeyTodosOsFiltros = () => {
    return dispatch => {
        enviaUltimaReferenciaKeyClienteTodasAvaliacoes(dispatch, '');
        enviaUltimaReferenciaKeyAtendenteTodasAvaliacoes(dispatch, '');
        enviaUltimaReferenciaKeyValorAvaliacaoTodasAvaliacoes(dispatch, '');
        enviaUltimaReferenciaKeyAvaliacao(dispatch, '')

    }
}


export const modificaDataInicialFinalRelatorioAvaliacaoGeral = (dataInicial, dataFinal) => {
    return dispatch => {
        // 
        // 

        dispatch({ type: MODIFICADATAINICIALAVALIACAOGERAL, payload: dataInicial });
        dispatch({ type: MODIFICADATAFINALAVALIACAOGERAL, payload: dataFinal });
    }
};
export const modificaClienteSelecionadoTelaAvaliacaoTodas = cliente => {
    return {
        type: MODIFICACLIENTESELECIONADOTELAVALIACAOTODAS,
        payload: cliente
    };
};
export const modificaFiltroRelatorioAvaliacaoGeral = filtro => {
    return {
        type: MODIFICAFILTRORELATORIOAVALIACAOGERAL,
        payload: filtro
    };
};
export const modificaAtendenteSelecionadoTelaAvaliacaoTodas = atendente => {
    return {
        type: MODIFICAATENDENTESELECIONADOTELAVALIACAOTODAS,
        payload: atendente
    };
};
export const modificaValorAvaliacaoSelecionadoTelaAvaliacaoTodas = valor => {
    return {
        type: MODIFICAVALORAVALIACAOSELECIONADOTELAVALIACAOTODAS,
        payload: valor
    };
};


const enviaUltimaReferenciaKeyClienteTodasAvaliacoes = (dispatch, value) => {
    dispatch({
        type: ENVIAULTIMAKEYREFERENCIAKEYCLIENTETODASAVALIACOES,
        payload: value
    });
};
const enviaUltimaReferenciaKeyAtendenteTodasAvaliacoes = (dispatch, value) => {
    dispatch({
        type: ENVIAULTIMAKEYREFERENCIAKEYATENDENTETODASAVALIACOES,
        payload: value
    });
};
const enviaUltimaReferenciaKeyValorAvaliacaoTodasAvaliacoes = (dispatch, value) => {
    dispatch({
        type: ENVIAULTIMAKEYREFERENCIAKEYVALORAVALIACAOTODASAVALIACOES,
        payload: value
    });
};
const ativaDesativaLoadingEnviaClienteTelaAvaliacaoTodas = (dispatch, value) => {
    dispatch({
        type: ATIVADESATIVALOADINGENVIACLIENTETELAAVALIACAOTODAS,
        payload: value
    });
};
const ativaDesativaLoadingEnviaAtendenteTelaAvaliacaoTodas = (dispatch, value) => {
    dispatch({
        type: ATIVADESATIVALOADINGENVIAATENDENTETELAAVALIACAOTODAS,
        payload: value
    });
};


const enviaClienteParaReducerTodasAvaliacoes = (dispatch, value) => {
    dispatch({
        type: ENVIACLIENTEPARAREDUCERTODASAVALIACOES,
        payload: value
    });
};
const enviaAtendenteParaReducerTodasAvaliacoes = (dispatch, value) => {
    dispatch({
        type: ENVIAATENDENTEPARAREDUCERTODASAVALIACOES,
        payload: value
    });
};
const enviaUltimaReferenciaKeyAvaliacao = (dispatch, value) => {
    dispatch({
        type: ENVIAULTIMAREFERENCIAKEYAVALIACAO,
        payload: value
    });
};

const enviaAvaliacoesTelaTodasAvaliacoes = (dispatch, value) => {
    dispatch({
        type: ENVIAAVALIACOESTELATODASAVALIACOS,
        payload: value
    });
};

const ativaDesativaLoadingTelaTodasAvaliacoes = (dispatch, value) => {
    dispatch({
        type: ATIVADESATIVALOADINGTELATODASAVALIACOES,
        payload: value
    });
};
const ativaDesativaLoadingRefreshControlTodasAvaliacoes = (dispatch, value) => {
    dispatch({
        type: ATIVADESATIVALOADINGREFRESHCONTROLTODASAVALIACOES,
        payload: value
    });
};

const ativaDesativaLoadingFinalPaginaTodasAvaliacoes = (dispatch, value) => {
    dispatch({
        type: ATIVADESATIVALOADINGFINALPAGINATODASAVALIACOES,
        payload: value
    });
};

const verificaConexaoInternetTelaTOdasAvaliacoes = (dispatch, value) => {
    dispatch({
        type: VERIFICACONEXAOINTERNETELATODASAVALIACOES,
        payload: value
    });
};

export const navegaTelaDetalhesAvaliacao = (dadosAvaliacao, uriImagem) => {
    return dispatch => {
       
        dispatch({ type: ENVIADETALHESAVALIACAOTELADETALHESAVALIACAO, payload: dadosAvaliacao });
        dispatch({ type: MODIFICAKEYAVALIACAO, payload: dadosAvaliacao.key });
        enviaImagemPerfilClienteTelaDetalhesAvaliacao(dispatch, uriImagem);
        //   dispatch(NavigationActions.navigate({ routeName: "DetalhesAvaliacao" }));
    }
}
export const zeraConexaoInternetTodasAvaliacoes = (atendente) => {
    return {
        type: VERIFICACONEXAOINTERNETELATODASAVALIACOES,
        payload: ''
    }
}
export const modificaFiltroAvaliacao = (filtro) => {
    return {
        type: MODIFICAFILTROAVALIACAO,
        payload: filtro
    }
}


export const modificaAtendenteSelecionadoSectionedRelatorioAvaliacao = (atendente) => {
    return {
        type: MODIFICAATENDENTESELECIONADOSECTIONEDRELATORIOAVALIACAO,
        payload: atendente
    }
}
export const modificaFiltroAvaliacaoWeb = (value) => {
    return {
        type: MODIFICAFILTROAVALIACAOWEB,
        payload: value
    }
}
const ativaDesativaLoadingSectionedAtendenteRelatorioAtendente = (dispatch, value) => {
    dispatch({
        type: ATIVADESATIVALOADINGSECTIONEDATENDENTERELATORIOATENDENTE,
        payload: value
    });
};
const enviaImagemPerfilClienteTelaDetalhesAvaliacao = (dispatch, value) => {
    dispatch({
        type: ENVIAIMAGEMPERFILCLIENTETELADETALHESAVALIACAO,
        payload: value
    });
};
const enviaAtendentesParaSectionedRelatorioAtendente = (dispatch, value) => {
    dispatch({
        type: ENVIATENDENTEPARASECTIONEDRELATORIOATENDENTE,
        payload: value
    });
};
const modificaKeyAvaliacao = (dispatch, value) => {
    dispatch({
        type: MODIFICAKEYAVALIACAO,
        payload: value
    });
};


const enviaPorcentagem1estrelaAtendente = (dispatch, value) => {
    dispatch({
        type: ENVIAPORCENTAGEM1ESTRELAATENDENTE,
        payload: value
    });
};
const enviaPorcentagem2estrelaAtendente = (dispatch, value) => {
    dispatch({
        type: ENVIAPORCENTAGEM2ESTRELAATENDENTE,
        payload: value
    });
};
const enviaPorcentagem3estrelaAtendente = (dispatch, value) => {
    dispatch({
        type: ENVIAPORCENTAGEM3ESTRELAATENDENTE,
        payload: value
    });
};
const enviaPorcentagem4estrelaAtendente = (dispatch, value) => {
    dispatch({
        type: ENVIAPORCENTAGEM4ESTRELAATENDENTE,
        payload: value
    });
};
const enviaPorcentagem5estrelaAtendente = (dispatch, value) => {
    dispatch({
        type: ENVIAPORCENTAGEM5ESTRELAATENDENTE,
        payload: value
    });
};

const enviaNotaMediaGeralAtendente = (dispatch, value) => {
    dispatch({
        type: ENVIANOTAMEDIAGERALATENDENTE,
        payload: value
    });
};
const enviaNSOGeralAtendente = (dispatch, value) => {
    dispatch({
        type: ENVIANSOGERALATENDENTE,
        payload: value
    });
};




const enviaAvaliacao1estrelaAtendente = (dispatch, value) => {
    dispatch({
        type: ENVIAAVALIACAO1ESTRELAATENDENTE,
        payload: value
    });
};
const enviaAvaliacao2estrelaAtendente = (dispatch, value) => {
    dispatch({
        type: ENVIAAVALIACAO2ESTRELAATENDENTE,
        payload: value
    });
};
const enviaAvaliacao3estrelaAtendente = (dispatch, value) => {
    dispatch({
        type: ENVIAAVALIACAO3ESTRELAATENDENTE,
        payload: value
    });
};
const enviaAvaliacao4estrelaAtendente = (dispatch, value) => {
    dispatch({
        type: ENVIAAVALIACAO4ESTRELAATENDENTE,
        payload: value
    });
};
const enviaAvaliacao5estrelaAtendente = (dispatch, value) => {
    dispatch({
        type: ENVIAAVALIACAO5ESTRELAATENDENTE,
        payload: value
    });
};
const enviaTotalAvaliacoesAtendente = (dispatch, value) => {
    dispatch({
        type: ENVIATOTALAVALIACOESATENDENTE,
        payload: value
    });
};





const enviaAvaliacao1estrela = (dispatch, value) => {
    dispatch({
        type: ENVIAAVALIACAO1ESTRELA,
        payload: value
    });
};
const enviaAvaliacao2estrela = (dispatch, value) => {
    dispatch({
        type: ENVIAAVALIACAO2ESTRELA,
        payload: value
    });
};
const enviaAvaliacao3estrela = (dispatch, value) => {
    dispatch({
        type: ENVIAAVALIACAO3ESTRELA,
        payload: value
    });
};
const enviaAvaliacao4estrela = (dispatch, value) => {
    dispatch({
        type: ENVIAAVALIACAO4ESTRELA,
        payload: value
    });
};
const enviaAvaliacao5estrela = (dispatch, value) => {
    dispatch({
        type: ENVIAAVALIACAO5ESTRELA,
        payload: value
    });
};
const enviaTotalAvaliacoes = (dispatch, value) => {
    dispatch({
        type: ENVIATOTALAVALIACOES,
        payload: value
    });
};


const enviaPorcentagem1estrela = (dispatch, value) => {
    dispatch({
        type: ENVIAPORCENTAGEM1ESTRELA,
        payload: value
    });
};
const enviaPorcentagem2estrela = (dispatch, value) => {
    dispatch({
        type: ENVIAPORCENTAGEM2ESTRELA,
        payload: value
    });
};
const enviaPorcentagem3estrela = (dispatch, value) => {
    dispatch({
        type: ENVIAPORCENTAGEM3ESTRELA,
        payload: value
    });
};
const enviaPorcentagem4estrela = (dispatch, value) => {
    dispatch({
        type: ENVIAPORCENTAGEM4ESTRELA,
        payload: value
    });
};
const enviaPorcentagem5estrela = (dispatch, value) => {
    dispatch({
        type: ENVIAPORCENTAGEM5ESTRELA,
        payload: value
    });
};

const enviaNotaMediaGeral = (dispatch, value) => {
    dispatch({
        type: ENVIANOTAMEDIAGERAL,
        payload: value
    });
};
const enviaNSOGeral = (dispatch, value) => {
    dispatch({
        type: ENVIANSOGERAL,
        payload: value
    });
};



const enviaAvaliacoesGeralDashBoard = (dispatch, value) => {
    dispatch({
        type: ENVIAAVALIACOESGERALDASHBOARD,
        payload: value
    });
};
const enviaTotalAvaliacoesGeral = (dispatch, value) => {
    dispatch({
        type: ENVIATOTALAVALIACOESGERAL,
        payload: value
    });
};


const ativaDesativaLoadingTelaRelatorioAvaliacaoGeral = (dispatch, value) => {
    dispatch({
        type: ATIVADESATIVALOADINGTELARELATORIOAVALIACAOGERAL,
        payload: value
    });
};
const ativaDesativaLoadingTelaRelatorioAvaliacaoAtendente = (dispatch, value) => {
    dispatch({
        type: ATIVADESATIVALOADINGTELARELATORIOAVALIACAOATENDENTE,
        payload: value
    });
};

const enviaPeriodoPersonalizadoAvaliacaoGeral = (dispatch, value) => {
    dispatch({
        type: ENVIAPERIODOPERSONALIZADOAVALIACAOGERAL,
        payload: value
    });
};
const enviaPeriodoPersonalizadoAvaliacaoAtendente = (dispatch, value) => {
    dispatch({
        type: ENVIAPERIODOPERSONALIZADOAVALIACAOATENDENTE,
        payload: value
    });
};
const zeraDataInicialAvaliacaoGeral = (dispatch) => {
    dispatch({
        type: MODIFICADATAINICIALAVALIACAOGERAL,
        payload: null
    });
};
const zeraDataFinalAvaliacaoGeral = (dispatch) => {
    dispatch({
        type: MODIFICADATAFINALAVALIACAOGERAL,
        payload: null
    });
};

const zeraDataInicialAvaliacaoAtendente = (dispatch) => {
    dispatch({
        type: MODIFICADATAINICIALAVALIACAOATENDENTE,
        payload: null
    });
};
const zeraDataFinalAvaliacaoAtendente = (dispatch) => {
    dispatch({
        type: MODIFICADATAFINALAVALIACAOATENDENTE,
        payload: null
    });
};
const mensagemErrorAplicaPeriodoPersonalizado = (dispatch, mensagem) => {
    dispatch({
        type: MENSAGEMERRORAPLICAPERIODOPERSONALIZADO,
        payload: mensagem
    });
};
const mensagemErrorAplicaPeriodoPersonalizadoAtendente = (dispatch, mensagem) => {
    dispatch({
        type: MENSAGEMERRORAPLICAPERIODOPERSONALIZADOATENDENTE,
        payload: mensagem
    });
};

export const zeraMensagemErrorAplicacaoPeriodoPersonalizadoAtendente = () => {
    return {
        type: MENSAGEMERRORAPLICAPERIODOPERSONALIZADOATENDENTE,
        payload: ''
    }
};
export const zeraMensagemErrorAplicacaoPeriodoPersonalizado = () => {
    return {
        type: MENSAGEMERRORAPLICAPERIODOPERSONALIZADO,
        payload: ''
    }
};
export const modificaDataInicialAvaliacaoGeral = data => {
    return {
        type: MODIFICADATAINICIALAVALIACAOGERAL,
        payload: data
    };
};
export const modificaDataInicialAvaliacaoAtendente = data => {
    return {
        type: MODIFICADATAINICIALAVALIACAOATENDENTE,
        payload: data
    };
};
export const modificaDataFinalAvaliacaoAtendente = data => {
    return {
        type: MODIFICADATAFINALAVALIACAOATENDENTE,
        payload: data
    };
};
export const modificaDataFinalAvaliacaoGeral = data => {
    return {
        type: MODIFICADATAFINALAVALIACAOGERAL,
        payload: data
    };
};
function ordenaObjeto(obj) {
    var arr = [];
    for (var prop in obj) {
        if (obj.hasOwnProperty(prop)) {
            arr.push({
                'key': prop,
                'value': obj[prop]
            });
        }
    }
    arr.sort(function (a, b) { return a.value - b.value; });
    //arr.sort(function(a, b) { a.value.toLowerCase().localeCompare(b.value.toLowerCase()); }); //use this to sort as strings
    return arr.reverse(); // returns array
}

function retornaDataTimeStamp(data) {
    var myDate = data;
    myDate = myDate.split("/");
    var newDate = myDate[1] + "/" + myDate[0] + "/" + myDate[2];
    return new Date(newDate).getTime();
}
function retornaDataAtual() {
    let dataAtual = moment().toDate();
    dataAtual = moment(dataAtual).format('DD/MM/YYYY');
    return dataAtual;
}
const AsyncStorage = {
    setItem: function (key, value) {
        return Promise.resolve().then(function () {
            localStorage.setItem(key, value);
        });
    },
    getItem: function (key) {
        return Promise.resolve().then(function () {
            return localStorage.getItem(key);
        });
    }
};
