import moment from 'moment';
import firebase from '../servicos/FirebaseConnection';
import { toastr } from 'react-redux-toastr';
import {
    MENSAGEMSEMCONEXAOINTERNETCONFIGEMPRESA, ATIVADESATIVALOADINGTELACONFIGEMPRESA,
    ENVIANOMEEMPRESAPARAREDUCER, MODIFICANOMEEMPRESACONFIGURAEMPRESA, ATIVADESATIVALOADINGBOTAOALTERARNOMEEMPRESACONFIGEMPRESA,
    MENSAGEMSEMCONEXAOINTERNETCONFIGAVALIACAO, ATIVADESATIVALOADINGTELACONFIGAVALIACAO, ENVIASEDESEJAMANDARMENSAGEMAVALIACAOAPOSFINALIZARATENDIMENTO,
    ENVIAMENSAGEMAVALIACAOPARAREDUCER, ATIVADESATIVALOADINGBOTAOALTERARCONFIGAVALIACAO, ALTERAVALORSWITCHCONFIGATENDIMENTO,
    ENVIADADOSCONFIGURACAOATENDIMENTO, ATIVADESATIVALOADINGCONFIGURACAOATENDIMENTO, MENSAGEMSEMCONEXAOINTERNETCONFIGURACAOATENDIMENTO,
    ATIVADESATIVALOADINGBOTAOALTERARCONFIGURACAOATENDIMENTO, ALTERAAGRUPAATENDENTESETOR, ALTERAATENDENTEVEAPENASPROPRIOCHAMADO,
    ALTERAVALORSWITCHCONFIGCHAMADOESTATICO, MODIFICADESCRICAOPROBLEMAESTATICO, ATIVADESATIVALOADINGBOTAONOVOPROBLEMA,
    ENVIAPROBLEMAPARAPROBLEMASELECIONADO, ENVIAPROBLEMAPARAREDUCER, ATIVADESATIVALOADINGCARREGANDOPROBLEMAS,

    MODIFICACORFUNDOPRINCIPAL, MODIFICACORFUNDOSECUNDARIO, MODIFICACORFUNDOITENSSELECIONAVEIS, MODIFICACORFUNDOBOTAOPRINCIPAL,
    MODIFICACORFUNDOBOTAOSECUNDARIO, MODIFICACORLETRAPADRAO, MODIFICACORLETRAPRINCIPAL, MODIFICACORLETRASECUNDARIA,
    MODIFICACORLETRADESCRICAODOSCAMPOS, MODIFICACORFUNDOMENU, MODIFICACORLETRAMENU,
    ALTERASWITCHNOTIFRESPATENDQUEESTOUATENDENDO, ALTERASWITCHNOTIFRESPATENDABERTOPORMIM,
    ALTERASWITCHNOTIFATENDESTOUATENDENDO, ALTERASWITCHNOTIFATENDABERTOPORMIM, VERIFICACRIARCHAMADOPROBLEMAESTATICO,
    MODIFICALOGOTIPOTELACONFIGURACAO, MODIFICAIMAGEMCOMPLETALOGOTIPOCONFIGURACAO, ALTERAVALORSWITCHUTILIZARCHAT,
    MODIFICAHORARIOOPERACAOSEGUNDAFEIRA, ALTERAVALORSWITCHUTILIZAR2HORARIOSCHAT, ALTERAVALORCHECKSEGUNDAEIRA,
    MODIFICAHORARIOOPERACAOSEGUNDAFEIRA2,

    MODIFICAHORARIOOPERACAOTERCAFEIRA, MODIFICAHORARIOOPERACAOQUARTAFEIRA, MODIFICAHORARIOOPERACAOQUINTAFEIRA,
    MODIFICAHORARIOOPERACAOSEXTAFEIRA, MODIFICAHORARIOOPERACAOSABADOFEIRA, MODIFICAHORARIOOPERACAODOMINGOFEIRA,
    MODIFICAHORARIOOPERACAOTERCAFEIRA2, MODIFICAHORARIOOPERACAOQUARTAFEIRA2, MODIFICAHORARIOOPERACAOQUINTAFEIRA2,
    MODIFICAHORARIOOPERACAOSEXTAFEIRA2, MODIFICAHORARIOOPERACAOSABADOFEIRA2, MODIFICAHORARIOOPERACAODOMINGOFEIRA2,
    ALTERAVALORCHECKDOMINGOEIRA, ALTERAVALORCHECKSABADOEIRA, ALTERAVALORCHECKSEXTAEIRA, ALTERAVALORCHECKQUINTAEIRA,
    ALTERAVALORCHECKQUARTAEIRA, ALTERAVALORCHECKTERCAEIRA, ATIVADESATIVALOADINGBOTAOALTERARCONIGCHATONLINE,
    MODIFICAFUSOHORARIOSELECIONADO, MODIFICACORPRINCIPALCHAT,
    ENVIARESPOSTASSELECIONADASPARAREDUCER, ENVIARESPOSTAPADROESPARAREDUCER, MODIFICADESCROCAORESPOSTAPADRAO, MODIFICAMENSAGEMRESPOSTAPADRAO,
    ATIVADESATIVALOADINGBOTAONVOARESPOSTA, MODIFICARESPOSTASELECIONADA, ENVIARESPOSTAPARAACOESCHAMADO, ENVIASEUTILIZACHATATENDIMENTOONLINE,
    MODIFICACLICOUCFGCHAT, ALTERASWITCHNOTIFCOMENTARIOINTERNO, MODIFICASWITCHADMVISUALIZAAPENASCHAMADOSETOR,
    ENVIASESSIONCLIENT, ENVIASTATUSCONNECT, ENVIAQRCODE,
    MODIFICAMENSAGEMFORAHORARIOOPERACAO, MODIFICAMENSAGEMESCOLHADEPARTAMENTO, MODIFICAMENSAGEMFILAATENDIMENTO,
    MODIFICAMENSAGEMFINALIZACAOCHAMADO, MODIFICAMENSAGEMBOASVINDASWPP, ATIVADESATIVALOADINGSALVARCONFIGWHATSAPP,
    MODIFICALIMITEDEESPERAFILACHAT, ALTERAVALORSWITCHENVIATENDIMENTOPARASETORAPOSLIMITE,
    ENVIASETORPARACONFIGURACAOLIMITE, MODIFICAKEYSETORSELECIONADOPARAENVIARATENDIMENTOAPOSLIMITE,
    MODIFICAOBRIGATORIORESOLUCAOCHAMADO, MODIFICADESATIVARASSISTENTEVIRTUALWPP, MODIFICAMOSTRARNOMEATENDENTEMENSAGEM,
    MODIFICASOLICITARNOMECLIENTEPRIMEIROCONTATO,
    MODIFICADESCROCAORESPOSTAPADRAOALTERAR, MODIFICAMENSAGEMRESPOSTAPADRAOALTERAR,
    MODIFICAMOTIVOCANCELAMENTOCONFIG, MODIFICAATENDENTEVEAPENASPROPRIOHISTORICO,ALTERAVALORSWITCHUTILIZAVERSAOBETA,
    MODIFICAENVIARMENSAGEMTRANSSETORWPPCONFIG
} from './types';


export const alterarRespostaPadrao = (descricaoRespostaPadrao, mensagemRespostaPadrao, key) => {
    return dispatch => {
        
        AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");

            firebase.database().ref(`${chaveUnica}/respostapadrao/${key}`).update({ resposta: mensagemRespostaPadrao, tituloresposta: descricaoRespostaPadrao }).then(() => {
                toastr.success("Sucesso", 'Resposta Padrão Alterado com sucesso.');
            });


        })
    }
}

export const excluirRespostaPadrao = (value, respostaPadroesSelecionadas) => {
    return dispatch => {
        AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            /*    
              
              let result = respostaPadroes.filter(e => e.value !== value)
              let result = respostaPadroes.some(o2 => value === o2.value);
                */
            /*  
             
             let result = respostaPadroesSelecionadas.some(o2 => value === o2);
             
             enviaRespostasSelecionadasParaReducer(dispatch, result) */
            firebase.database().ref(`${chaveUnica}/respostapadrao/${value}`).remove();
            /*     
                let children2 = []
                for (let i = 0; i < respostaPadroes.length; i++) {
                    if (respostaPadroes[i].items[0].value !== value) {
                        children2.push({
                            value: respostaPadroes[i].items[0].value,
                            name: respostaPadroes[i].items[0].name,
                            subTitle: respostaPadroes[i].name,
                        })
                    }
                }
                
                const empresas = children2.reduce((obj, { subTitle, name, value }) => {
                    if (!obj[subTitle]) obj[subTitle] = [];
                    obj[subTitle].push({ value, name });
                    return obj;
                }, {});
                const valoresConvertidos = Object.keys(empresas).map((subTitle, value) => {
                    return {
                        name: subTitle,
                        type: 'group',
                        items: empresas[subTitle]
                    };
                });
                
                enviaRespostaPadroesParaReducer(dispatch, valoresConvertidos); */

        })
    }
}
export const alteraConfiguracaoWhatsApp = (mensagemBemVindo2, mensagemEscolhaDepartamento2, mensagemFilaAtendimento2, mensagemFinalizacaoAtendimento2, mensagemForaHorarioOperacao2, limiteDeEspera,
    enviaAtendimentoParaSetorAposLimite, keySetorSelecionadoParaEnviarAtendimentoAposLimite, mostrarNomeAtendenteMensagem, desativarAssistenteVirtualWpp, solicitarNomeClientePrimeirocontato,
    atendenteVeApenasProprioHistorico,enviarMensagemTransfSetorWpp) => {
    return dispatch => {
        
        AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            let mensagemBemVindo = mensagemBemVindo2.trim();
            let mensagemEscolhaDepartamento = mensagemEscolhaDepartamento2.trim();
            let mensagemFilaAtendimento = mensagemFilaAtendimento2.trim();
            let mensagemFinalizacaoAtendimento = mensagemFinalizacaoAtendimento2.trim();
            let mensagemForaHorarioOperacao = mensagemForaHorarioOperacao2.trim()

            if (mensagemBemVindo === '') {
                toastr.error('ERROR', 'Insira uma mensagem de Boas Vindas');
            } else if (mensagemEscolhaDepartamento === '') {
                toastr.error('ERROR', 'Insira uma mensagem de Escolha de Departamento')
            } else if (mensagemFilaAtendimento === '') {
                toastr.error('ERROR', 'Insira uma mensagem de Fila de Atendimento')
            } else if (mensagemFinalizacaoAtendimento === '') {
                toastr.error('ERROR', 'Insira uma mensagem de Finalização do Atendimento')
            } else if (mensagemForaHorarioOperacao === '') {
                toastr.error('ERROR', 'Insira uma mensagem de Fora de Horario de Operacao')
            } else if (enviaAtendimentoParaSetorAposLimite === true && keySetorSelecionadoParaEnviarAtendimentoAposLimite.trim() === '') {
                toastr.error('ERROR', 'Selecione o SETOR pra onde será redirecionado os chamados')
            }

            else {

                
                ativaDesativaLoadingSalvarConfigWhatsApp(dispatch, true);
                firebase.database().ref(`${chaveUnica}/configuracao/atendimento`).once('value').then((snapshot) => {

                    const dadosConfiguracaoAtendimento = [];
                    snapshot.forEach(childItem => {
                        dadosConfiguracaoAtendimento.push({
                            key: childItem.key,
                        })
                    })
                    //  
                    //  
                    firebase.database().ref(`${chaveUnica}/configuracao/atendimento/${dadosConfiguracaoAtendimento[0].key}`).update({
                        mensagemBoasVindasWpp: mensagemBemVindo, mensagemEscolhaDepartamentoWpp: mensagemEscolhaDepartamento, mensagemFilaAtendimentoWpp: mensagemFilaAtendimento, mensagemFinalizacaoChamado: mensagemFinalizacaoAtendimento, mensagemForaHorarioOperacao,
                        limiteMinutosAtingidos: limiteDeEspera,
                        enviaAtendimentoParaSetorAposLimite, keySetorSelecionadoParaEnviarAtendimentoAposLimite,
                        mostrarNomeAtendenteMensagem: mostrarNomeAtendenteMensagem,
                        desativarAssistenteVirtualWpp: desativarAssistenteVirtualWpp,
                        solicitarNomeClientePrimeirocontato: solicitarNomeClientePrimeirocontato,
                        atendenteVeApenasProprioHistorico: atendenteVeApenasProprioHistorico,
                        enviarMensagemTransfSetorWpp:enviarMensagemTransfSetorWpp
                    }).then(() => {
                        ativaDesativaLoadingSalvarConfigWhatsApp(dispatch, false);
                        toastr.success('Sucesso', 'Configuração do WhatsApp alterado com SUCESSO!');
                    })
                })
            }
        })
    }
}

export const alteraNomeEmpresa = (nome, backgroundPrimeiro, backgroundSegundo, backgroundMaisEscuro, coresLetraPadrao, coresLetraSecundario,
    corBotaoPadrao, letraBotaoPadrao, corSegundoBotaoPadrao, corLetraDescricaoDosCampos, corFundoMenu, corLetraMenu, logoTipo, arquivoCompletoLogoTipo, corPrincipalChat) => {
    /*  console.log(backgroundPrimeiro, backgroundSegundo, backgroundMaisEscuro, coresLetraPadrao, coresLetraSecundario,
         corBotaoPadrao, letraBotaoPadrao, corSegundoBotaoPadrao, corLetraDescricaoDosCampos, corFundoMenu, corLetraMenu, logoTipo, arquivoCompletoLogoTipo, corPrincipalChat) */
    return dispatch => {
        let uriHTTP = 0;
        if (logoTipo === null) {
            //  
            //é uma imagem local nao precisa upar
            uriHTTP = 1;
        } else if (logoTipo === 'semURI') {
            // 
            uriHTTP = 1;
        } else if (logoTipo.includes('/static/media/userPadrao') || logoTipo.includes('data:image/png;base64')) {
            uriHTTP = 1;
        } else if (logoTipo.includes("http") && !logoTipo.includes("blob")) {

            // 
            uriHTTP = 1;
        } else if (arquivoCompletoLogoTipo === '') {
            // 
            uriHTTP = 1;
        } else {
            // 

        }
        ativaDesativaLoadingBotaoAlterarNomeEmpresaConfigEmpresa(dispatch, true);
        AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            if (nome.trim() === '') {
                toastr.error('Error', 'Digite o nome da sua Empresa.');
                ativaDesativaLoadingBotaoAlterarNomeEmpresaConfigEmpresa(dispatch, false);
            }
            else if (nome.trim().length < 2) {
                toastr.error('Error', 'O Nome da empresa deve possuir mais de 1 letra.');
                ativaDesativaLoadingBotaoAlterarNomeEmpresaConfigEmpresa(dispatch, false);
            } else {
                firebase.database().ref(`${chaveUnica}/empresa/`).once('value').then((snapshot) => {
                    let keyEmpresa = [];
                    const dadosEmpresa = [];
                    snapshot.forEach(childItem => {
                        dadosEmpresa.push({
                            key: childItem.key,
                            empresa: childItem.val().empresa
                        })
                        keyEmpresa.push({
                            key: childItem.key,
                        })
                    })
                    //
                    // 
                    firebase.database().ref(`${chaveUnica}/empresa/${dadosEmpresa[0].key}`).update({
                        empresa: nome
                    }).then(() => {
                        firebase.database().ref(`${chaveUnica}/configuracao/cores`).once('value').then((snapshot) => {
                            if (snapshot.val()) {
                                const dadosEmpresa = [];
                                snapshot.forEach(childItem => {
                                    dadosEmpresa.push({
                                        key: childItem.key,
                                    })
                                })
                                //  
                                //  
                                firebase.database().ref(`${chaveUnica}/configuracao/cores/${dadosEmpresa[0].key}`).update({
                                    backgroundPrimeiro, backgroundSegundo, backgroundMaisEscuro, coresLetraPadrao, coresLetraSecundario,
                                    corBotaoPadrao, letraBotaoPadrao, corSegundoBotaoPadrao, corLetraDescricaoDosCampos, corFundoMenu, corLetraMenu,
                                    corPrincipalChat
                                }).then(() => {

                                    //logotipo metodo 1
                                    if (uriHTTP == 1) {
                                        toastr.success('Sucesso', 'Configuração da empresa alterado com SUCESSO!');
                                        ativaDesativaLoadingBotaoAlterarNomeEmpresaConfigEmpresa(dispatch, false);
                                    } else {
                                        //  
                                        // let uri = uriImagemAtendente.replace('file://', '');
                                        //  
                                        // 
                                        let caminhoPadraoImagem = firebase.storage().ref(`${chaveUnica}/${keyEmpresa[0].key}/logotipo.jpg`);
                                        let mime = 'image/jpeg';

                                        caminhoPadraoImagem.put(arquivoCompletoLogoTipo).then(
                                            (url) => {
                                                url.ref.getDownloadURL().then(function (downloadURL) {
                                                    firebase.database().ref(`${chaveUnica}/configuracao/logotipo`).once('value').then((snapshot) => {
                                                        if (snapshot.val()) {
                                                            //  
                                                            const dadosConfiguracaoAtendimento = [];
                                                            snapshot.forEach(childItem => {
                                                                dadosConfiguracaoAtendimento.push({
                                                                    key: childItem.key,
                                                                })
                                                            })
                                                            firebase.database().ref(`${chaveUnica}/configuracao/logotipo/${dadosConfiguracaoAtendimento[0].key}`).update({
                                                                uri: downloadURL
                                                            }).then(() => {
                                                                toastr.success('Sucesso', 'Configuração da empresa alterado com SUCESSO!');
                                                                dispatch({
                                                                    type: MODIFICAIMAGEMCOMPLETALOGOTIPOCONFIGURACAO,
                                                                    payload: ''
                                                                })
                                                                ativaDesativaLoadingBotaoAlterarNomeEmpresaConfigEmpresa(dispatch, false);
                                                            })

                                                        } else {
                                                            // 
                                                            //  
                                                            firebase.database().ref(`${chaveUnica}/configuracao/logotipo`).push({
                                                                uri: downloadURL
                                                            }).then(() => {
                                                                dispatch({
                                                                    type: MODIFICAIMAGEMCOMPLETALOGOTIPOCONFIGURACAO,
                                                                    payload: ''
                                                                })
                                                                toastr.success('Sucesso', 'Configuração da empresa alterado com SUCESSO!');
                                                                ativaDesativaLoadingBotaoAlterarNomeEmpresaConfigEmpresa(dispatch, false);
                                                            })
                                                        }
                                                    })
                                                })
                                            }).catch((error) => {
                                                ativaDesativaLoadingBotaoAlterarNomeEmpresaConfigEmpresa(dispatch, false);
                                                //  
                                                //error.code
                                                //error.message
                                                switch (error.code) {
                                                    case 'auth/invalid-email':
                                                        toastr.error('Error', 'Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br');
                                                        break;
                                                    case 'auth/email-already-in-use':
                                                        toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                                                        break;
                                                    case 'auth/network-request-failed':
                                                        toastr.error('Error', 'Sem conexão com a internet');
                                                        break;
                                                    default:
                                                        toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                                                        break;
                                                }
                                            })
                                    }

                                })
                            } else {
                                firebase.database().ref(`${chaveUnica}/configuracao/cores`).push({
                                    backgroundPrimeiro, backgroundSegundo, backgroundMaisEscuro, coresLetraPadrao, coresLetraSecundario,
                                    corBotaoPadrao, letraBotaoPadrao, corSegundoBotaoPadrao, corLetraDescricaoDosCampos, corFundoMenu, corLetraMenu, corPrincipalChat
                                }).then(() => {
                                    //logotipo metodo 2

                                    if (uriHTTP == 1) {
                                        toastr.success('Sucesso', 'Configuração da empresa alterado com SUCESSO!');
                                        ativaDesativaLoadingBotaoAlterarNomeEmpresaConfigEmpresa(dispatch, false);
                                    } else {
                                        // 
                                        // let uri = uriImagemAtendente.replace('file://', '');
                                        let caminhoPadraoImagem = firebase.storage().ref(`${chaveUnica}/${keyEmpresa[0].key}/logotipo.jpg`);
                                        let mime = 'image/jpeg';

                                        caminhoPadraoImagem.put(arquivoCompletoLogoTipo).then(
                                            (url) => {
                                                url.ref.getDownloadURL().then(function (downloadURL) {
                                                    firebase.database().ref(`${chaveUnica}/configuracao/logotipo`).once('value').then((snapshot) => {
                                                        if (snapshot.val()) {

                                                            const dadosConfiguracaoAtendimento = [];
                                                            snapshot.forEach(childItem => {
                                                                dadosConfiguracaoAtendimento.push({
                                                                    key: childItem.key,
                                                                })
                                                            })
                                                            firebase.database().ref(`${chaveUnica}/configuracao/logotipo/${dadosConfiguracaoAtendimento[0].key}`).update({
                                                                uri: downloadURL
                                                            }).then(() => {
                                                                toastr.success('Sucesso', 'Configuração da empresa alterado com SUCESSO!');
                                                                dispatch({
                                                                    type: MODIFICAIMAGEMCOMPLETALOGOTIPOCONFIGURACAO,
                                                                    payload: ''
                                                                })
                                                                ativaDesativaLoadingBotaoAlterarNomeEmpresaConfigEmpresa(dispatch, false);

                                                            })

                                                        } else {
                                                            firebase.database().ref(`${chaveUnica}/configuracao/logotipo`).push({
                                                                uri: downloadURL
                                                            }).then(() => {
                                                                toastr.success('Sucesso', 'Configuração da empresa alterado com SUCESSO!');
                                                                dispatch({
                                                                    type: MODIFICAIMAGEMCOMPLETALOGOTIPOCONFIGURACAO,
                                                                    payload: ''
                                                                })
                                                                ativaDesativaLoadingBotaoAlterarNomeEmpresaConfigEmpresa(dispatch, false);
                                                            })
                                                        }
                                                    })
                                                })
                                            }).catch((error) => {
                                                ativaDesativaLoadingBotaoAlterarNomeEmpresaConfigEmpresa(dispatch, false);
                                                // 
                                                //error.code
                                                //error.message
                                                switch (error.code) {
                                                    case 'auth/invalid-email':
                                                        toastr.error('Error', 'Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br');
                                                        break;
                                                    case 'auth/email-already-in-use':
                                                        toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                                                        break;
                                                    case 'auth/network-request-failed':
                                                        toastr.error('Error', 'Sem conexão com a internet');
                                                        break;
                                                    default:
                                                        toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                                                        break;
                                                }
                                            })
                                    }

                                })
                            }
                        })
                    })
                })
            }
        })

    }
}
export const alteraConfiguracaoAtendimento = (switchObrigatorioAtribuirAtendente, agruparAtendenteSetor, atendenteVeApenasProprioChamado, respostasPadroes,
    respostasSelecionadas, usuarioAdmVisualizaApenasChamadosDoSetorVinculado, keyUsuarioLogado, obrigatorioInformarResolucaoChamado, enviaMensagemAposFinalizarChamado, obrigatorioInformarMotivoCancelamento) => {
    return dispatch => {

        //
        ativaDesativaLoadingBotaoAlterarConfiguracaoAtendimento(dispatch, true);
        AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            firebase.database().ref(`${chaveUnica}/setores`).once("value").then((snapshotEmp) => {
                let existeSetorCadastro;
                if (snapshotEmp.val()) {
                    existeSetorCadastro = true;
                } else {
                    existeSetorCadastro = false;
                }
                if (agruparAtendenteSetor == true && existeSetorCadastro == false) {
                    toastr.error('Error', 'Para utilizar a opção "Agrupar atendente á setores" é preciso ter no minimo 1 setor cadastrado, cadastre um setor e tente novamente.');
                    ativaDesativaLoadingBotaoAlterarConfiguracaoAtendimento(dispatch, false);
                }
                else if (respostasSelecionadas.length <= 0) {
                    toastr.error('Error', 'Selecione pelo menos 1 resposta padrão.');
                    ativaDesativaLoadingBotaoAlterarConfiguracaoAtendimento(dispatch, false);
                }
                else {
                    firebase.database().ref(`${chaveUnica}/configuracao/avaliacao`).once('value').then((snapshot) => {

                        const dadosEmpresa = [];
                        snapshot.forEach(childItem => {
                            dadosEmpresa.push({
                                key: childItem.key,
                            })
                        })
                        /*  
                          */
                        firebase.database().ref(`${chaveUnica}/configuracao/avaliacao/${dadosEmpresa[0].key}`).update({
                            utilizaAvaliacao: enviaMensagemAposFinalizarChamado
                        })
                    })
                    firebase.database().ref(`${chaveUnica}/configuracao/atendimento`).once('value').then((snapshot) => {

                        const dadosConfiguracaoAtendimento = [];
                        snapshot.forEach(childItem => {
                            dadosConfiguracaoAtendimento.push({
                                key: childItem.key,
                            })
                        })
                        //  
                        //  
                        firebase.database().ref(`${chaveUnica}/configuracao/atendimento/${dadosConfiguracaoAtendimento[0].key}`).update({
                            atribuirAtendenteObrigatorio: switchObrigatorioAtribuirAtendente,
                            agruparAtendenteSetor: agruparAtendenteSetor,
                            atendenteVeApenasProprioChamado: atendenteVeApenasProprioChamado,
                            //criarChamadoProblemaEstatico: verificaUtilizaProblemaEstatico,
                            obrigatorioInformarResolucaoChamado: obrigatorioInformarResolucaoChamado,
                            obrigatorioInformarMotivoCancelamento: obrigatorioInformarMotivoCancelamento,
                        }).then(() => {
                            firebase.database().ref(`${chaveUnica}/usuarios/${keyUsuarioLogado}`).update({ usuarioAdmVisualizaApenasChamadosDoSetorVinculado: usuarioAdmVisualizaApenasChamadosDoSetorVinculado }).then(() => {
                                firebase.database().ref(`${chaveUnica}/respostapadrao`).orderByKey().once('value').then((snapshot) => {
                                    /* 
                                     */
                                    if (snapshot.val() == null) {
                                        /*  */
                                    } else {
                                        let arrayProblemas = [];
                                        let problemasSelecionadosAlterado = [];
                                        let arrayDeProblemasQueNaoEstaoSelecionado = [];

                                        snapshot.forEach(childItem => {

                                            arrayProblemas.push({
                                                value: childItem.key,
                                            });

                                        });

                                        for (let i = 0; i < respostasSelecionadas.length; i++) {
                                            problemasSelecionadosAlterado.push({
                                                value: respostasSelecionadas[i]
                                            })
                                        }
                                        /*   
                                           */

                                        //toastr.success('Sucesso', 'Configuração de Atendimento alterado com SUCESSO!!');
                                        // ativaDesativaLoadingBotaoAlterarConfiguracaoAtendimento(dispatch, false);
                                        let aux = 0;
                                        for (let i = 0; i < arrayProblemas.length; i++) {
                                            aux++;
                                            let valorExistente = 0;
                                            for (let o = 0; o < problemasSelecionadosAlterado.length; o++) {
                                                if (arrayProblemas[i].value == problemasSelecionadosAlterado[o].value) {
                                                    valorExistente = 1;
                                                    break;
                                                }
                                            }
                                            if (valorExistente == 1) {
                                                /*    */
                                                firebase.database().ref(`${chaveUnica}/respostapadrao/${arrayProblemas[i].value}`).update({
                                                    selecionado: 1
                                                }).then(() => {
                                                    if (aux == arrayProblemas.length) {
                                                        /*    */
                                                        // toastr.success('Sucesso', 'Configuração de Atendimento alterado com SUCESSO!!');
                                                        //ativaDesativaLoadingBotaoAlterarConfiguracaoAtendimento(dispatch, false);
                                                    }
                                                })
                                            } else {
                                                /*    */
                                                firebase.database().ref(`${chaveUnica}/respostapadrao/${arrayProblemas[i].value}`).update({
                                                    selecionado: 0
                                                }).then(() => {
                                                    if (aux == arrayProblemas.length) {
                                                        /*   */
                                                        //   toastr.success('Sucesso', 'Configuração de Atendimento alterado com SUCESSO!!');
                                                        // ativaDesativaLoadingBotaoAlterarConfiguracaoAtendimento(dispatch, false);
                                                    }
                                                })
                                            }
                                        }

                                    }
                                })
                                /*     if (verificaUtilizaProblemaEstatico == true) {
                                        // let result = problemasEstaticos.filter(o1 => problemasSelecionados.some(o2 => o1.id !== o2.id));
                                        //
    
                                        firebase.database().ref(`${chaveUnica}/problemaEstatico`).orderByKey().once('value').then((snapshot) => {
                                            // 
                                            //  
                                            if (snapshot.val() == null) {
                                                //    
                                            } else {
                                                let arrayProblemas = [];
                                                let problemasSelecionadosAlterado = [];
                                                let arrayDeProblemasQueNaoEstaoSelecionado = [];
    
                                                snapshot.forEach(childItem => {
    
                                                    arrayProblemas.push({
                                                        value: childItem.key,
                                                    });
    
                                                });
    
                                                for (let i = 0; i < problemasSelecionados.length; i++) {
                                                    problemasSelecionadosAlterado.push({
                                                        value: problemasSelecionados[i]
                                                    })
                                                }
                                                // 
                                                // 
                                                // let result = arrayProblemas.filter(item => !problemasSelecionados.includes(item.id));
                                                //   let result = arrayProblemas.filter(o1 => problemasSelecionadosAlterado.some(o2 => o1.id != o2.id));
                                                //  let result = arrayProblemas.filter(o1 => problemasSelecionadosAlterado.some(o2 => o1.id !== o2.id));   
                                                //    
                                                toastr.success('Sucesso', 'Configuração de Atendimento alterado com SUCESSO!!');
                                                ativaDesativaLoadingBotaoAlterarConfiguracaoAtendimento(dispatch, false);
                                                let aux = 0;
                                                for (let i = 0; i < arrayProblemas.length; i++) {
                                                    aux++;
                                                    let valorExistente = 0;
                                                    for (let o = 0; o < problemasSelecionadosAlterado.length; o++) {
                                                        if (arrayProblemas[i].value == problemasSelecionadosAlterado[o].value) {
                                                            valorExistente = 1;
                                                            break;
                                                        }
                                                    }
                                                    if (valorExistente == 1) {
                                                        // 
                                                        firebase.database().ref(`${chaveUnica}/problemaEstatico/${arrayProblemas[i].value}`).update({
                                                            selecionado: 1
                                                        }).then(() => {
                                                            if (aux == arrayProblemas.length) {
                                                                // 
                                                                toastr.success('Sucesso', 'Configuração de Atendimento alterado com SUCESSO!!');
                                                                ativaDesativaLoadingBotaoAlterarConfiguracaoAtendimento(dispatch, false);
                                                            }
                                                        })
                                                    } else {
                                                        // 
                                                        firebase.database().ref(`${chaveUnica}/problemaEstatico/${arrayProblemas[i].value}`).update({
                                                            selecionado: 0
                                                        }).then(() => {
                                                            if (aux == arrayProblemas.length) {
                                                                // 
                                                                toastr.success('Sucesso', 'Configuração de Atendimento alterado com SUCESSO!!');
                                                                ativaDesativaLoadingBotaoAlterarConfiguracaoAtendimento(dispatch, false);
                                                            }
                                                        })
                                                    }
                                                }
    
                                            }
                                        });
    
                                    } else { */
                                toastr.success('Sucesso', 'Configuração de Atendimento alterado com SUCESSO!!');
                                ativaDesativaLoadingBotaoAlterarConfiguracaoAtendimento(dispatch, false);
                                /* } */
                            })
                        })
                    })
                }
            })



        }
        )
    }
}
export const cadastraProblemaEstatico = (descricaoProblema2, problemasAnteriores) => {
    const descricaoProblema = descricaoProblema2.trim();
    // 
    return dispatch => {
        // 
        if (descricaoProblema.trim() === '') {
            toastr.error('Error', 'Digite a Descrição Do Problema Estático.');
        }
        else {
            ativaDesativaLoadingBotaoNovoProblema(dispatch, true);
            AsyncStorage.getItem('chaveUnica').then((chaveUnica2) => {
                const chaveUnica = chaveUnica2.replace(/"/g, "");
                firebase.database().ref(`${chaveUnica}/problemaEstatico`).push({ descricaoProblema: descricaoProblema, selecionado: 1 }).then(
                    (dadosProblema) => {
                        //  
                        const children2 = [];
                        children2.push({
                            value: dadosProblema.key,
                            name: descricaoProblema
                        });
                        //  
                        // 
                        const items = [{ children: children2 }];
                        // enviaProblemasParaReducer(dispatch,items)
                        //    enviaProblemaParaProblemaSelecionado(dispatch,items)
                        //
                        toastr.success('Sucesso', 'Problema Cadastrado com SUCESSO!');
                        zeraDescricaoProblemaCadastroSucesso(dispatch, '')
                        ativaDesativaLoadingBotaoNovoProblema(dispatch, false);
                    }
                ).catch((error) => {
                    //error.code
                    //error.message
                    ativaDesativaLoadingBotaoNovoProblema(dispatch, false);
                    switch (error.code) {
                        case 'auth/network-request-failed':
                            toastr.error('Error', 'Sem conexão com a internet');
                            break;
                        default:
                            toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                            break;
                    }
                })
            })
        }


    }
}
export const cadastraRespostaPadrao = (descricaoResposta2, mensagemResposta2, respostaAnteriores) => {
    const descricaoResposta = descricaoResposta2.trim();
    const mensagemResposta = mensagemResposta2.trim();
    // 
    return dispatch => {
        // 
        if (descricaoResposta.trim() === '') {
            toastr.error('Error', 'Digite a Descricão da Resposta.');
        } else if (mensagemResposta.trim() === '') {
            toastr.error('Error', 'Digite a Mensagem da Resposta.');
        }
        else {
            ativaDesativaLoadingBOtaoNovaResposta(dispatch, true);
            AsyncStorage.getItem('chaveUnica').then((chaveUnica2) => {
                const chaveUnica = chaveUnica2.replace(/"/g, "");
                firebase.database().ref(`${chaveUnica}/respostapadrao`).push({ resposta: mensagemResposta2, tituloresposta: descricaoResposta2, selecionado: 1 }).then(
                    (dadosProblema) => {

                        //;
                        toastr.success('Sucesso', 'Resposta Cadastrado com SUCESSO!');
                        dispatch({ type: MODIFICADESCROCAORESPOSTAPADRAO, payload: '' })
                        dispatch({ type: MODIFICAMENSAGEMRESPOSTAPADRAO, payload: '' })
                        ativaDesativaLoadingBOtaoNovaResposta(dispatch, false);
                    }
                ).catch((error) => {
                    //error.code
                    //error.message
                    ativaDesativaLoadingBOtaoNovaResposta(dispatch, false);
                    switch (error.code) {
                        case 'auth/network-request-failed':
                            toastr.error('Error', 'Sem conexão com a internet');
                            break;
                        default:
                            toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                            break;
                    }
                })
            })
        }


    }
}
export const resetarCores = () => {
    return dispatch => {
        ativaDesativaLoadingConfiguracaoAtendimento(dispatch, true);
        AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            firebase.database().ref(`${chaveUnica}/configuracao/cores`).once('value').then((snapshot) => {

                let backgroundPrimeiro = '#29454D';
                let backgroundSegundo = '#3F535A';
                let backgroundMaisEscuro = '#223033';
                let coresLetraPadrao = '#FFF';
                let coresLetraSecundario = '#9fadb7';
                let corBotaoPadrao = '#00FF7F';
                let letraBotaoPadrao = '#000';
                let corSegundoBotaoPadrao = '#F0f0f7';
                let corLetraDescricaoDosCampos = '#F0FFF0';
                let corFundoMenu = '#1d1d1d';
                let corLetraMenu = '#FFF';
                let corPrincipalChat = '#39a3f4';
                if (snapshot.val()) {
                    let key = Object.keys(snapshot.val()).toString()
                    /*   
                      console.log(backgroundPrimeiro, backgroundSegundo, backgroundMaisEscuro, coresLetraPadrao, coresLetraSecundario,
                          corBotaoPadrao, letraBotaoPadrao, corSegundoBotaoPadrao, corLetraDescricaoDosCampos, corFundoMenu, corLetraMenu); */
                    firebase.database().ref(`${chaveUnica}/configuracao/cores/${key}`).update({
                        backgroundPrimeiro, backgroundSegundo, backgroundMaisEscuro, coresLetraPadrao, coresLetraSecundario,
                        corBotaoPadrao, letraBotaoPadrao, corSegundoBotaoPadrao, corLetraDescricaoDosCampos, corFundoMenu, corLetraMenu,
                        corPrincipalChat
                    }).then(() => {

                        dispatch({
                            type: MODIFICACORFUNDOPRINCIPAL,
                            payload: backgroundPrimeiro
                        })
                        dispatch({
                            type: MODIFICACORFUNDOSECUNDARIO,
                            payload: backgroundSegundo
                        })
                        dispatch({
                            type: MODIFICACORFUNDOITENSSELECIONAVEIS,
                            payload: backgroundMaisEscuro
                        })
                        dispatch({
                            type: MODIFICACORFUNDOBOTAOPRINCIPAL,
                            payload: corBotaoPadrao
                        })
                        dispatch({
                            type: MODIFICACORFUNDOBOTAOSECUNDARIO,
                            payload: corSegundoBotaoPadrao
                        })
                        dispatch({
                            type: MODIFICACORLETRAPADRAO,
                            payload: letraBotaoPadrao
                        })
                        dispatch({
                            type: MODIFICACORLETRAPRINCIPAL,
                            payload: coresLetraPadrao
                        })

                        dispatch({
                            type: MODIFICACORLETRASECUNDARIA,
                            payload: coresLetraSecundario
                        })
                        dispatch({
                            type: MODIFICACORLETRADESCRICAODOSCAMPOS,
                            payload: corLetraDescricaoDosCampos
                        })
                        dispatch({
                            type: MODIFICACORFUNDOMENU,
                            payload: corFundoMenu
                        })
                        dispatch({
                            type: MODIFICACORLETRAMENU,
                            payload: corLetraMenu
                        })
                        dispatch({
                            type: MODIFICACORPRINCIPALCHAT,
                            payload: corPrincipalChat
                        })
                        toastr.success('Sucesso', 'Cores resetadas com SUCESSO!');
                        ativaDesativaLoadingBotaoAlterarNomeEmpresaConfigEmpresa(dispatch, false);
                        ativaDesativaLoadingConfiguracaoAtendimento(dispatch, false);
                    })
                    //ativaDesativaLoadingTelaNotificacao(dispatch, false);
                } else {
                    toastr.success('Sucesso', 'Cores resetadas com SUCESSO!');
                    ativaDesativaLoadingConfiguracaoAtendimento(dispatch, false);
                }
            })
        })
    }
}
export const listaConfiguracoes = () => {
    return dispatch => {
        ativaDesativaLoadingConfiguracaoAtendimento(dispatch, true);
        AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            firebase.database().ref(`${chaveUnica}/configuracao/atendimento`).on("value", snapshot => {
                /*   */
                const dadosConfiguracaoAtendimento = [];
                snapshot.forEach(childItem => {
                    dadosConfiguracaoAtendimento.push({
                        key: childItem.key,
                        atribuirAtendenteObrigatorio: childItem.val().atribuirAtendenteObrigatorio,
                        atendenteVeApenasProprioChamado: childItem.val().atendenteVeApenasProprioChamado,
                        agruparAtendenteSetor: childItem.val().agruparAtendenteSetor,
                        criarChamadoProblemaEstatico: childItem.val().criarChamadoProblemaEstatico == undefined ? false : childItem.val().criarChamadoProblemaEstatico,
                        obrigatorioInformarResolucaoChamado: childItem.val().obrigatorioInformarResolucaoChamado == undefined ? false : childItem.val().obrigatorioInformarResolucaoChamado,
                        mensagemBoasVindasWpp: childItem.val().mensagemBoasVindasWpp === undefined ? `Olá, {{nome_cliente}}, Seja Bem-Vindo(a) a {{nome_empresa}}.\n✅protocolo de atendimento: *#{{protocolo}}*\nAbertura: *{{data_abertura}}*` : childItem.val().mensagemBoasVindasWpp,
                        mensagemEscolhaDepartamentoWpp: childItem.val().mensagemEscolhaDepartamentoWpp === undefined ? `Eu sou a 🙋🏻‍♀️ *JÚLIA*, assistente virtual da {{nome_empresa}}.\n\nPara iniciar seu atendimento *digite o número* da opção desejada:` : childItem.val().mensagemEscolhaDepartamentoWpp,
                        //mensagemEscolhaDepartamentoWppAposSelectNome: childItem.val().mensagemEscolhaDepartamentoWppAposSelectNome === undefined ? `Oi, {{nome_cliente}}!\n\nEu sou a 🙋🏻‍♀️ *JÚLIA*, assistente virtual da {{nome_empresa}}.\n\nPor favor *digite o número* do setor que deseja atendimento:` : childItem.val().mensagemEscolhaDepartamentoWppAposSelectNome,
                        mensagemFilaAtendimentoWpp: childItem.val().mensagemFilaAtendimentoWpp === undefined ? `Eu sou a 🙋🏻‍♀️ *JÚLIA*, assistente virtual da {{nome_empresa}}.\n\nJá estou lhe encaminhando para um dos nossos atendentes.\n\nPara agilizar seu atendimento por favor, digite sua dúvida.` : childItem.val().mensagemFilaAtendimentoWpp,
                        mensagemFinalizacaoChamado: childItem.val().mensagemFinalizacaoChamado === undefined ? `Agradecemos o seu contato!\n\n*Pedimos por gentileza que não responda essa mensagem.*\n\nPois esse atendimento foi concluido e qualquer nova mensagem abrirá um novo atendimento.\n\nMas lembramos que qualquer dúvida estamos sempre á disposição. Conte com a gente.\n\nAtendimento Finalizado!\nProtocolo de atendimento *#{{protocolo}}*` : childItem.val().mensagemFinalizacaoChamado,
                        mensagemForaHorarioOperacao: childItem.val().mensagemForaHorarioOperacao === undefined ? `Infelizmente, não estamos online no momento.\nEntraremos em contato o mais rápido possível.` : childItem.val().mensagemForaHorarioOperacao,
                        limiteMinutosAtingidos: childItem.val().limiteMinutosAtingidos == undefined ? "5" : childItem.val().limiteMinutosAtingidos,

                        enviaAtendimentoParaSetorAposLimite: childItem.val().enviaAtendimentoParaSetorAposLimite == undefined ? false : childItem.val().enviaAtendimentoParaSetorAposLimite,
                        keySetorSelecionadoParaEnviarAtendimentoAposLimite: childItem.val().keySetorSelecionadoParaEnviarAtendimentoAposLimite == undefined ? '' : childItem.val().keySetorSelecionadoParaEnviarAtendimentoAposLimite,

                        mostrarNomeAtendenteMensagem: childItem.val().mostrarNomeAtendenteMensagem == undefined ? true : childItem.val().mostrarNomeAtendenteMensagem,
                        desativarAssistenteVirtualWpp: childItem.val().desativarAssistenteVirtualWpp == undefined ? false : childItem.val().desativarAssistenteVirtualWpp,
                        enviarMensagemTransfSetorWpp: childItem.val().enviarMensagemTransfSetorWpp == undefined ? true : childItem.val().enviarMensagemTransfSetorWpp,
                        obrigatorioInformarMotivoCancelamento: childItem.val().obrigatorioInformarMotivoCancelamento == undefined ? true : childItem.val().obrigatorioInformarMotivoCancelamento,
                        atendenteVeApenasProprioHistorico: childItem.val().atendenteVeApenasProprioHistorico == undefined ? false : childItem.val().atendenteVeApenasProprioHistorico,

                    })
                })


                /*  */
                enviaDadosConfiguracaoAtendimento(dispatch, dadosConfiguracaoAtendimento[0].atribuirAtendenteObrigatorio);
                alteraAgrupaAtendenteSetorLOCAL(dispatch, dadosConfiguracaoAtendimento[0].agruparAtendenteSetor);
                alteraAtendenteVeApenasProprioChamadoLOCAL(dispatch, dadosConfiguracaoAtendimento[0].atendenteVeApenasProprioChamado);
                verificaCriarChamadoProblemaEstatico(dispatch, dadosConfiguracaoAtendimento[0].criarChamadoProblemaEstatico);
                modificaObrigatorioResolucaoChamadoLocal(dispatch, dadosConfiguracaoAtendimento[0].obrigatorioInformarResolucaoChamado)
                modificaMotivoCancelamentoConfigLocal(dispatch, dadosConfiguracaoAtendimento[0].obrigatorioInformarMotivoCancelamento)
                dispatch({ type: MODIFICAMENSAGEMBOASVINDASWPP, payload: dadosConfiguracaoAtendimento[0].mensagemBoasVindasWpp })
                dispatch({ type: MODIFICAMENSAGEMESCOLHADEPARTAMENTO, payload: dadosConfiguracaoAtendimento[0].mensagemEscolhaDepartamentoWpp })
                dispatch({ type: MODIFICAMENSAGEMFILAATENDIMENTO, payload: dadosConfiguracaoAtendimento[0].mensagemFilaAtendimentoWpp })
                dispatch({ type: MODIFICAMENSAGEMFINALIZACAOCHAMADO, payload: dadosConfiguracaoAtendimento[0].mensagemFinalizacaoChamado })
                dispatch({ type: MODIFICAMENSAGEMFORAHORARIOOPERACAO, payload: dadosConfiguracaoAtendimento[0].mensagemForaHorarioOperacao })
                dispatch({ type: MODIFICALIMITEDEESPERAFILACHAT, payload: dadosConfiguracaoAtendimento[0].limiteMinutosAtingidos })
                dispatch({ type: ALTERAVALORSWITCHENVIATENDIMENTOPARASETORAPOSLIMITE, payload: dadosConfiguracaoAtendimento[0].enviaAtendimentoParaSetorAposLimite })
                dispatch({ type: MODIFICADESATIVARASSISTENTEVIRTUALWPP, payload: dadosConfiguracaoAtendimento[0].desativarAssistenteVirtualWpp })
                dispatch({ type: MODIFICAMOSTRARNOMEATENDENTEMENSAGEM, payload: dadosConfiguracaoAtendimento[0].mostrarNomeAtendenteMensagem })
                dispatch({ type: MODIFICAATENDENTEVEAPENASPROPRIOHISTORICO, payload: dadosConfiguracaoAtendimento[0].atendenteVeApenasProprioHistorico })
                dispatch({ type: MODIFICAENVIARMENSAGEMTRANSSETORWPPCONFIG, payload: dadosConfiguracaoAtendimento[0].enviarMensagemTransfSetorWpp })
                
                modificaKeySetorSelecionadoParaEnviarAtendimentoAposLimite(dispatch, dadosConfiguracaoAtendimento[0].keySetorSelecionadoParaEnviarAtendimentoAposLimite)
            })
            firebase.database().ref(`${chaveUnica}/problemaEstatico`).orderByKey().on("value", snapshot => {
                /*   
                   */
                if (snapshot.val() == null) {
                    const arrayUser = [];
                    enviaProblemasParaReducer(dispatch, arrayUser)
                    // ativaDesativaLoadingCarregandoProblemas(dispatch, false);
                    // ativaDesativaLoadingConfiguracaoAtendimento(dispatch, false);
                } else {
                    let children2 = [];
                    let childrenSelecionado = [];
                    let arrayProblemasSelecionado = [];
                    snapshot.forEach(childItem => {
                        if (childItem.val().selecionado == 1) {
                            /*   */
                            childrenSelecionado.push({
                                value: childItem.key,
                            });
                            children2.push({
                                value: childItem.key,
                                name: childItem.val().descricaoProblema
                            });
                        } else {
                            children2.push({
                                value: childItem.key,
                                name: childItem.val().descricaoProblema
                            });
                        }
                    });
                    /*      
                          */
                    let result = children2.filter(o1 => childrenSelecionado.some(o2 => o1.value === o2.value));
                    /*  */
                    for (let i = 0; i < result.length; i++) {
                        arrayProblemasSelecionado.push(result[i].value)
                    }
                    /*    */
                    const items = [{
                        name: 'PROBLEMAS',
                        type: 'group', items: children2
                    }];
                    //const itemsSelecionado = [{ children: arrayProblemasSelecionado }];
                    enviaProblemasParaReducer(dispatch, items);
                    enviaProblemaParaProblemaSelecionado(dispatch, arrayProblemasSelecionado)

                    ativaDesativaLoadingCarregandoProblemas(dispatch, false);
                    //  ativaDesativaLoadingConfiguracaoAtendimento(dispatch, false);
                }
            });
            //aqui
            firebase.database().ref(`${chaveUnica}/configuracao/avaliacao`).on("value", snapshot => {

                const dadosEmpresa = [];
                snapshot.forEach(childItem => {
                    dadosEmpresa.push({
                        key: childItem.key,
                        mensagemPadrao: childItem.val().mensagemPadrao,
                        utilizaAvaliacao: childItem.val().utilizaAvaliacao
                    })
                })
                /*    */

                /*  */
                //  enviaNomeEmpresReducer(dispatch, dadosEmpresa[0].empresa);
                enviaMensagemAvaliacaoParaReducer(dispatch, dadosEmpresa[0].mensagemPadrao);
                enviaSeDesejaMandarMensagemAvaliacaoAposFInalizarAtendimento(dispatch, dadosEmpresa[0].utilizaAvaliacao);
                // ativaDesativaLoadingTelaConfigAvaliacao(dispatch, false);
            })
            firebase.database().ref(`${chaveUnica}/setores/`).orderByChild('excluido').equalTo(null).once('value').then((snapshot) => {


                if (snapshot.val() == null) {
                    const arrayUser = [];
                    enviaSetorParaConfiguracaoLimite(dispatch, arrayUser);
                } else {
                    const children2 = [];
                    snapshot.forEach(childItem => {
                        if (childItem.val().excluido == 1 || childItem.val().nome == undefined) {
                            /*  */
                        } else {
                            children2.push({
                                value: childItem.key,
                                name: childItem.val().nome
                            });
                        }
                    });
                    const items = [{
                        name: 'SETORES',
                        type: 'group', items: children2
                    }];
                    enviaSetorParaConfiguracaoLimite(dispatch, items);
                }
            })
            /* firebase.database().ref(`${chaveUnica}/configuracao/whatsapp`).on("value", snapshot => {
                if (snapshot.val() == null) {
                    enviaSessionClient(dispatch, '')
                    enviaQrCode(dispatch, '')
                    enviaStatusConnect(dispatch, '')
                } else {

                    const dadosEmpresa = [];
                    snapshot.forEach(childItem => {
                        let sessionclient = childItem.val().sessionclient;
                        let qrCode = childItem.val().qrCode;
                        let statusConnect = childItem.val().statusConnect;
                        enviaSessionClient(dispatch, sessionclient)
                        enviaQrCode(dispatch, qrCode)
                        enviaStatusConnect(dispatch, statusConnect)
                    })

                }
            }) */
            //aqui
            firebase.database().ref(`${chaveUnica}/empresa/`).on("value", snapshot => {
                let keyEmpresa = [];
                const dadosEmpresa = [];
                snapshot.forEach(childItem => {
                    dadosEmpresa.push({
                        key: childItem.key,
                        empresa: childItem.val().empresa
                    })
                    keyEmpresa.push({
                        key: childItem.key,
                    })
                })
                /*    
 
                    */
                enviaNomeEmpresReducer(dispatch, dadosEmpresa[0].empresa);
                //ativaDesativaLoadingTelaConfigEmpresa(dispatch, false);
            })
            firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild('uid').equalTo(firebase.auth().currentUser.uid).limitToFirst(1).on("value", snapshot => {
                let key = Object.keys(snapshot.val()).toString()
                let notifAtendAberto;
                let notifAtendEstouAtendendo;
                let notifRespAtendAbertoPorMim;
                let notifRespAtendQueEstouAtendendo;
                let notifComentarioInterno;
                let usuarioAdmVisualizaApenasChamadosDoSetorVinculado;
                if (snapshot.val()) {
                    snapshot.forEach((childItem) => {
                        notifAtendAberto = (childItem.val().notifNovoAtend == 1) ? true : false;
                        notifAtendEstouAtendendo = (childItem.val().notifAtendimentoAtribuidoAmim == 1) ? true : false;
                        notifRespAtendAbertoPorMim = (childItem.val().notifRespAtendAbertosPorMim == 1) ? true : false;
                        notifRespAtendQueEstouAtendendo = (childItem.val().notifRespAtendEmQueEstouAtend == 1) ? true : false;
                        notifComentarioInterno = (childItem.val().notifComentarioInterno == undefined) ? false : childItem.val().notifComentarioInterno;
                        usuarioAdmVisualizaApenasChamadosDoSetorVinculado = (childItem.val().usuarioAdmVisualizaApenasChamadosDoSetorVinculado == undefined) ? false : childItem.val().usuarioAdmVisualizaApenasChamadosDoSetorVinculado;
                    })
                    alteraSwitchNotifAtendABertoPorMimLocal(dispatch, notifAtendAberto);
                    alteraSwitchNotifAtendEstouAtendendoLocal(dispatch, notifAtendEstouAtendendo);
                    alteraSwitchNotifRespAtendAbertoPorMimLocal(dispatch, notifRespAtendAbertoPorMim);
                    alteraSwitchNotifRespAtendQueEstouAtendendoLocal(dispatch, notifRespAtendQueEstouAtendendo);
                    alteraSwitchNotifComentarioInternoLocal(dispatch, notifComentarioInterno);
                    dispatch({ type: MODIFICASWITCHADMVISUALIZAAPENASCHAMADOSETOR, payload: usuarioAdmVisualizaApenasChamadosDoSetorVinculado })
                    //ativaDesativaLoadingTelaNotificacao(dispatch, false);
                } else {

                }
            })
            firebase.database().ref(`${chaveUnica}/configuracao/logotipo`).on("value", snapshot2 => {
                let uriLogoTipo = [];
                if (snapshot2.val()) {
                    snapshot2.forEach(childItem => {
                        uriLogoTipo.push({
                            uri: childItem.val().uri,
                        })
                    })
                    dispatch({
                        type: MODIFICALOGOTIPOTELACONFIGURACAO,
                        payload: uriLogoTipo[0].uri
                    })
                    // ativaDesativaLoadingConfiguracaoAtendimento(dispatch, false);
                    //ativaDesativaLoadingTelaNotificacao(dispatch, false);
                } else {
                    dispatch({
                        type: MODIFICALOGOTIPOTELACONFIGURACAO,
                        payload: 'semURI'
                    })
                    //ativaDesativaLoadingConfiguracaoAtendimento(dispatch, false);
                }

            })
            firebase.database().ref(`${chaveUnica}/configuracao/chatOnline`).on("value", snapshot2 => {
                let dadosConfigChat = [];
                if (snapshot2.val()) {
                    snapshot2.forEach(childItem => {
                        dadosConfigChat.push({
                            vigienciaSegundaFeira: childItem.val().vigienciaSegundaFeira,
                            vigienciaTercaFeira: childItem.val().vigienciaTercaFeira,
                            vigienciaQuartaFeira: childItem.val().vigienciaQuartaFeira,
                            vigienciaQuintaFeira: childItem.val().vigienciaQuintaFeira,
                            vigienciaSextaFeira: childItem.val().vigienciaSextaFeira,
                            vigienciaSabadoFeira: childItem.val().vigienciaSabadoFeira,
                            vigienciaDomingoFeira: childItem.val().vigienciaDomingoFeira,
                            horarioOperacaoSegundaFeira: childItem.val().horarioOperacaoSegundaFeira,
                            horarioOperacaoSegundaFeira2: childItem.val().horarioOperacaoSegundaFeira2,
                            horarioOperacaoTercaFeira: childItem.val().horarioOperacaoTercaFeira,
                            horarioOperacaoTercaFeira2: childItem.val().horarioOperacaoTercaFeira2,
                            horarioOperacaoQuartaFeira: childItem.val().horarioOperacaoQuartaFeira,
                            horarioOperacaoQuartaFeira2: childItem.val().horarioOperacaoQuartaFeira2,
                            horarioOperacaoQuintaFeira: childItem.val().horarioOperacaoQuintaFeira,
                            horarioOperacaoQuintaFeira2: childItem.val().horarioOperacaoQuintaFeira2,
                            horarioOperacaoSextaFeira: childItem.val().horarioOperacaoSextaFeira,
                            horarioOperacaoSextaFeira2: childItem.val().horarioOperacaoSextaFeira2,
                            horarioOperacaoSabadoFeira: childItem.val().horarioOperacaoSabadoFeira,
                            horarioOperacaoSabadoFeira2: childItem.val().horarioOperacaoSabadoFeira2,
                            horarioOperacaoDomingoFeira: childItem.val().horarioOperacaoDomingoFeira,
                            horarioOperacaoDomingoFeira2: childItem.val().horarioOperacaoDomingoFeira2,
                            utilizarChat: childItem.val().utilizarChat,
                            utilizar2Horarios: childItem.val().utilizar2Horarios,
                            fusoHorario: childItem.val().fusoHorario === undefined ? "America/Sao_Paulo" : childItem.val().fusoHorario,
                        })
                    })
                    /*    
                        */
                    dispatch({ type: ALTERAVALORCHECKSEGUNDAEIRA, payload: dadosConfigChat[0].vigienciaSegundaFeira })
                    dispatch({ type: ALTERAVALORCHECKTERCAEIRA, payload: dadosConfigChat[0].vigienciaTercaFeira })
                    dispatch({ type: ALTERAVALORCHECKQUARTAEIRA, payload: dadosConfigChat[0].vigienciaQuartaFeira })
                    dispatch({ type: ALTERAVALORCHECKQUINTAEIRA, payload: dadosConfigChat[0].vigienciaQuintaFeira })
                    dispatch({ type: ALTERAVALORCHECKSEXTAEIRA, payload: dadosConfigChat[0].vigienciaSextaFeira })
                    dispatch({ type: ALTERAVALORCHECKSABADOEIRA, payload: dadosConfigChat[0].vigienciaSabadoFeira })
                    dispatch({ type: ALTERAVALORCHECKDOMINGOEIRA, payload: dadosConfigChat[0].vigienciaDomingoFeira })
                    dispatch({ type: ALTERAVALORSWITCHUTILIZARCHAT, payload: dadosConfigChat[0].utilizarChat })
                    dispatch({ type: ALTERAVALORSWITCHUTILIZAR2HORARIOSCHAT, payload: dadosConfigChat[0].utilizar2Horarios })

                    dispatch({ type: MODIFICAHORARIOOPERACAOSEGUNDAFEIRA, payload: dadosConfigChat[0].horarioOperacaoSegundaFeira })
                    dispatch({ type: MODIFICAHORARIOOPERACAOSEGUNDAFEIRA2, payload: dadosConfigChat[0].horarioOperacaoSegundaFeira2 })

                    dispatch({ type: MODIFICAHORARIOOPERACAOTERCAFEIRA, payload: dadosConfigChat[0].horarioOperacaoTercaFeira })
                    dispatch({ type: MODIFICAHORARIOOPERACAOTERCAFEIRA2, payload: dadosConfigChat[0].horarioOperacaoTercaFeira2 })

                    dispatch({ type: MODIFICAHORARIOOPERACAOQUARTAFEIRA, payload: dadosConfigChat[0].horarioOperacaoQuartaFeira })
                    dispatch({ type: MODIFICAHORARIOOPERACAOQUARTAFEIRA2, payload: dadosConfigChat[0].horarioOperacaoQuartaFeira2 })

                    dispatch({ type: MODIFICAHORARIOOPERACAOQUINTAFEIRA, payload: dadosConfigChat[0].horarioOperacaoQuintaFeira })
                    dispatch({ type: MODIFICAHORARIOOPERACAOQUINTAFEIRA2, payload: dadosConfigChat[0].horarioOperacaoQuintaFeira2 })

                    dispatch({ type: MODIFICAHORARIOOPERACAOSEXTAFEIRA, payload: dadosConfigChat[0].horarioOperacaoSextaFeira })
                    dispatch({ type: MODIFICAHORARIOOPERACAOSEXTAFEIRA2, payload: dadosConfigChat[0].horarioOperacaoSextaFeira2 })

                    dispatch({ type: MODIFICAHORARIOOPERACAOSABADOFEIRA, payload: dadosConfigChat[0].horarioOperacaoSabadoFeira })
                    dispatch({ type: MODIFICAHORARIOOPERACAOSABADOFEIRA2, payload: dadosConfigChat[0].horarioOperacaoSabadoFeira2 })

                    dispatch({ type: MODIFICAHORARIOOPERACAODOMINGOFEIRA, payload: dadosConfigChat[0].horarioOperacaoDomingoFeira })
                    dispatch({ type: MODIFICAHORARIOOPERACAODOMINGOFEIRA2, payload: dadosConfigChat[0].horarioOperacaoDomingoFeira2 })

                    dispatch({ type: MODIFICAFUSOHORARIOSELECIONADO, payload: dadosConfigChat[0].fusoHorario })

                } else {
                    /*     */
                }

            })
            firebase.database().ref(`${chaveUnica}/respostapadrao`).orderByKey().on("value", snapshot => {
                /*    
                    */
                if (snapshot.val() == null) {
                    const arrayUser = [];
                    enviaRespostaPadroesParaReducer(dispatch, []);
                    enviaRespostasSelecionadasParaReducer(dispatch, [])
                } else {
                    let children2 = [];
                    let childrenSelecionado = [];
                    let arrayProblemasSelecionado = [];
                    snapshot.forEach(childItem => {
                        if (childItem.val().selecionado == 1) {
                            /*  */
                            childrenSelecionado.push({
                                value: childItem.key,
                            });
                            children2.push({
                                value: childItem.key,
                                name: childItem.val().resposta,
                                subTitle: childItem.val().tituloresposta,
                            });
                        } else {
                            firebase.database().ref(`${chaveUnica}/respostapadrao/${childItem.key}`).remove();
                            /* children2.push({
                                value: childItem.key,
                                name: childItem.val().resposta,
                                subTitle: childItem.val().tituloresposta,
                            }); */
                        }
                    });
                    /*   
                       */
                    let result = children2.filter(o1 => childrenSelecionado.some(o2 => o1.value === o2.value));
                    /*    */
                    for (let i = 0; i < result.length; i++) {
                        arrayProblemasSelecionado.push(result[i].value)
                    }
                    /*  const items = [{
                         name: 'RESPOSTAS PADRÕES',
                         type: 'group',
                         // items: children2
                         items: children2[subTitle]
                     }]; */
                    /*    */
                    const empresas = children2.reduce((obj, { subTitle, name, value }) => {
                        if (!obj[subTitle]) obj[subTitle] = [];
                        obj[subTitle].push({ value, name });
                        return obj;
                    }, {});
                    /*    
                        */
                    const valoresConvertidos = Object.keys(empresas).map((subTitle, value) => {
                        return {
                            name: subTitle,
                            type: 'group',
                            items: empresas[subTitle]
                        };
                    });

                    
                    enviaRespostasSelecionadasParaReducer(dispatch, arrayProblemasSelecionado)
                    enviaRespostaPadroesParaReducer(dispatch, valoresConvertidos);


                }
            });

            firebase.database().ref(`${chaveUnica}/configuracao/cores`).on("value", snapshot => {

                let backgroundPrimeiro;
                let backgroundSegundo;
                let backgroundMaisEscuro;
                let coresLetraPadrao;
                let coresLetraSecundario;
                let corBotaoPadrao;
                let letraBotaoPadrao;
                let corSegundoBotaoPadrao;
                let corLetraDescricaoDosCampos;
                let corFundoMenu;
                let corLetraMenu;
                let corPrincipalChat;
                if (snapshot.val()) {
                    let key = Object.keys(snapshot.val()).toString()
                    snapshot.forEach((childItem) => {
                        backgroundPrimeiro = childItem.val().backgroundPrimeiro;
                        backgroundSegundo = childItem.val().backgroundSegundo;
                        backgroundMaisEscuro = childItem.val().backgroundMaisEscuro;
                        coresLetraPadrao = childItem.val().coresLetraPadrao;
                        coresLetraSecundario = childItem.val().coresLetraSecundario;
                        corBotaoPadrao = childItem.val().corBotaoPadrao;
                        letraBotaoPadrao = childItem.val().letraBotaoPadrao;
                        corSegundoBotaoPadrao = childItem.val().corSegundoBotaoPadrao;
                        corLetraDescricaoDosCampos = childItem.val().corLetraDescricaoDosCampos;
                        corFundoMenu = childItem.val().corFundoMenu;
                        corLetraMenu = childItem.val().corLetraMenu;
                        corPrincipalChat = childItem.val().corPrincipalChat;
                    })
                    /*  console.log(backgroundPrimeiro, backgroundSegundo, backgroundMaisEscuro, coresLetraPadrao, coresLetraSecundario,
                         corBotaoPadrao, letraBotaoPadrao, corSegundoBotaoPadrao, corLetraDescricaoDosCampos, corFundoMenu, corLetraMenu); */


                    dispatch({
                        type: MODIFICACORFUNDOPRINCIPAL,
                        payload: backgroundPrimeiro
                    })
                    dispatch({
                        type: MODIFICACORFUNDOSECUNDARIO,
                        payload: backgroundSegundo
                    })
                    dispatch({
                        type: MODIFICACORFUNDOITENSSELECIONAVEIS,
                        payload: backgroundMaisEscuro
                    })
                    dispatch({
                        type: MODIFICACORFUNDOBOTAOPRINCIPAL,
                        payload: corBotaoPadrao
                    })
                    dispatch({
                        type: MODIFICACORFUNDOBOTAOSECUNDARIO,
                        payload: corSegundoBotaoPadrao
                    })
                    dispatch({
                        type: MODIFICACORLETRAPADRAO,
                        payload: letraBotaoPadrao
                    })
                    dispatch({
                        type: MODIFICACORLETRAPRINCIPAL,
                        payload: coresLetraPadrao
                    })

                    dispatch({
                        type: MODIFICACORLETRASECUNDARIA,
                        payload: coresLetraSecundario
                    })
                    dispatch({
                        type: MODIFICACORLETRADESCRICAODOSCAMPOS,
                        payload: corLetraDescricaoDosCampos
                    })
                    dispatch({
                        type: MODIFICACORFUNDOMENU,
                        payload: corFundoMenu
                    })
                    dispatch({
                        type: MODIFICACORLETRAMENU,
                        payload: corLetraMenu
                    })
                    dispatch({
                        type: MODIFICACORPRINCIPALCHAT,
                        payload: corPrincipalChat
                    })
                    ativaDesativaLoadingConfiguracaoAtendimento(dispatch, false);
                    //ativaDesativaLoadingTelaNotificacao(dispatch, false);
                } else {
                    ativaDesativaLoadingConfiguracaoAtendimento(dispatch, false);
                }

            })


        })
    }

}
export const alteraConfiguracaoAvaliacao = (mensagem, verificaUtilizaProblemaEstatico, problemasSelecionados, problemasEstaticos) => {
    return dispatch => {
        ativaDesativaLoadingBotaoAlterarConfigAvaliacao(dispatch, true);
        AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            if (mensagem.trim() === '') {
                toastr.error('Error', 'Digite a mensagem de avaliação padrão, para ser enviada após finalizar o ATENDIMENTO .');
                ativaDesativaLoadingBotaoAlterarConfigAvaliacao(dispatch, false);
            }
            else if (verificaUtilizaProblemaEstatico == true && problemasEstaticos.length <= 0) {
                toastr.error('Error', 'Para utilizar a opção "Utilizar Problema Pré Definido" é preciso ter no minimo 1 problema cadastrado, clique em "Novo Problema" e cadastre um problema.');
                ativaDesativaLoadingBotaoAlterarConfigAvaliacao(dispatch, false);
            }
            else if (verificaUtilizaProblemaEstatico == true && problemasSelecionados.length <= 0) {
                toastr.error('Error', 'Para utilizar a opção "Utilizar Problema Pré Definido" é preciso ter no minimo 1 problema selecionado, selecione um problema.');
                ativaDesativaLoadingBotaoAlterarConfigAvaliacao(dispatch, false);
            }
            else if (mensagem.trim().length < 2) {
                toastr.error('Error', 'A Mensagem de Avaliação Padrão deve possuir mais de 1 letra.');
                ativaDesativaLoadingBotaoAlterarConfigAvaliacao(dispatch, false);
            } else {
                firebase.database().ref(`${chaveUnica}/configuracao/atendimento`).once('value').then((snapshot) => {

                    const dadosConfiguracaoAtendimento = [];
                    snapshot.forEach(childItem => {
                        dadosConfiguracaoAtendimento.push({
                            key: childItem.key,
                        })
                    })
                    //  
                    //  
                    firebase.database().ref(`${chaveUnica}/configuracao/atendimento/${dadosConfiguracaoAtendimento[0].key}`).update({
                        //atribuirAtendenteObrigatorio: switchObrigatorioAtribuirAtendente,
                        // agruparAtendenteSetor: agruparAtendenteSetor,
                        // atendenteVeApenasProprioChamado: atendenteVeApenasProprioChamado,
                        criarChamadoProblemaEstatico: verificaUtilizaProblemaEstatico,
                        // obrigatorioInformarResolucaoChamado: obrigatorioInformarResolucaoChamado,
                    }).then(() => {
                        if (verificaUtilizaProblemaEstatico == true) {
                            // let result = problemasEstaticos.filter(o1 => problemasSelecionados.some(o2 => o1.id !== o2.id));
                            //

                            firebase.database().ref(`${chaveUnica}/problemaEstatico`).orderByKey().once('value').then((snapshot) => {
                                // 
                                //  
                                if (snapshot.val() == null) {
                                    //    
                                } else {
                                    let arrayProblemas = [];
                                    let problemasSelecionadosAlterado = [];
                                    let arrayDeProblemasQueNaoEstaoSelecionado = [];

                                    snapshot.forEach(childItem => {

                                        arrayProblemas.push({
                                            value: childItem.key,
                                        });

                                    });

                                    for (let i = 0; i < problemasSelecionados.length; i++) {
                                        problemasSelecionadosAlterado.push({
                                            value: problemasSelecionados[i]
                                        })
                                    }
                                    // 
                                    // 
                                    // let result = arrayProblemas.filter(item => !problemasSelecionados.includes(item.id));
                                    //   let result = arrayProblemas.filter(o1 => problemasSelecionadosAlterado.some(o2 => o1.id != o2.id));
                                    //  let result = arrayProblemas.filter(o1 => problemasSelecionadosAlterado.some(o2 => o1.id !== o2.id));   
                                    //    
                                    let aux = 0;
                                    for (let i = 0; i < arrayProblemas.length; i++) {
                                        aux++;
                                        let valorExistente = 0;
                                        for (let o = 0; o < problemasSelecionadosAlterado.length; o++) {
                                            if (arrayProblemas[i].value == problemasSelecionadosAlterado[o].value) {
                                                valorExistente = 1;
                                                break;
                                            }
                                        }
                                        if (valorExistente == 1) {
                                            // 
                                            firebase.database().ref(`${chaveUnica}/problemaEstatico/${arrayProblemas[i].value}`).update({
                                                selecionado: 1
                                            }).then(() => {
                                                if (aux == arrayProblemas.length) {
                                                    // 
                                                    //toastr.success('Sucesso', 'Configuração de Atendimento alterado com SUCESSO!!');
                                                    // ativaDesativaLoadingBotaoAlterarConfiguracaoAtendimento(dispatch, false);
                                                }
                                            })
                                        } else {
                                            // 
                                            firebase.database().ref(`${chaveUnica}/problemaEstatico/${arrayProblemas[i].value}`).update({
                                                selecionado: 0
                                            }).then(() => {
                                                if (aux == arrayProblemas.length) {
                                                    // 
                                                    // toastr.success('Sucesso', 'Configuração de Atendimento alterado com SUCESSO!!');
                                                    // ativaDesativaLoadingBotaoAlterarConfiguracaoAtendimento(dispatch, false);
                                                }
                                            })
                                        }
                                    }

                                }
                            });

                        } else {

                        }
                    })
                })
                firebase.database().ref(`${chaveUnica}/configuracao/avaliacao`).once('value').then((snapshot) => {

                    const dadosEmpresa = [];
                    snapshot.forEach(childItem => {
                        dadosEmpresa.push({
                            key: childItem.key,
                        })
                    })
                    /*  
                      */
                    firebase.database().ref(`${chaveUnica}/configuracao/avaliacao/${dadosEmpresa[0].key}`).update({
                        mensagemPadrao: mensagem,
                        //  utilizaAvaliacao: enviaMensagemAposFinalizarChamado
                    }).then(() => {
                        toastr.success('Sucesso', 'Dados de avaliação alterados com SUCESSO!!');
                        ativaDesativaLoadingBotaoAlterarConfigAvaliacao(dispatch, false);
                    })
                })
            }
        })

    }
}
export const verificaSeChatEstaHabilitadoTelaAtendimentoOnline = () => {
    return dispatch => {
        AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            firebase.database().ref(`${chaveUnica}/configuracao/chatOnline`).on("value", snapshot2 => {
                let dadosConfigChat = [];
                if (snapshot2.val()) {
                    snapshot2.forEach(childItem => {
                        dadosConfigChat.push({
                            utilizarChat: childItem.val().utilizarChat,
                        })
                    })
                    /*  
                      */
                    enviaSeUtilizaChatAtendimentoOnline(dispatch, dadosConfigChat[0].utilizarChat);

                } else {
                    /*  */
                    enviaSeUtilizaChatAtendimentoOnline(dispatch, false);
                }

            })
        })
    }
}
export const alteraConfiguracaoChatOnline = (vigienciaSegundaFeira, vigienciaTercaFeira, vigienciaQuartaFeira, vigienciaQuintaFeira, vigienciaSextaFeira,
    vigienciaSabadoFeira, vigienciaDomingoFeira, horarioOperacaoSegundaFeira, horarioOperacaoSegundaFeira2,
    horarioOperacaoTercaFeira, horarioOperacaoTercaFeira2, horarioOperacaoQuartaFeira, horarioOperacaoQuartaFeira2,
    horarioOperacaoQuintaFeira, horarioOperacaoQuintaFeira2, horarioOperacaoSextaFeira, horarioOperacaoSextaFeira2,
    horarioOperacaoSabadoFeira, horarioOperacaoSabadoFeira2, horarioOperacaoDomingoFeira, horarioOperacaoDomingoFeira2,
    utilizarChat, utilizar2Horarios, fusoHorarioSelecionado) => {
    /*  
     console.log(vigienciaSegundaFeira, vigienciaTercaFeira, vigienciaQuartaFeira, vigienciaQuintaFeira, vigienciaSextaFeira,
         vigienciaSabadoFeira, vigienciaDomingoFeira)
     
     console.log(horarioOperacaoSegundaFeira, horarioOperacaoSegundaFeira2,
         horarioOperacaoTercaFeira, horarioOperacaoTercaFeira2, horarioOperacaoQuartaFeira, horarioOperacaoQuartaFeira2,
         horarioOperacaoQuintaFeira, horarioOperacaoQuintaFeira2, horarioOperacaoSextaFeira, horarioOperacaoSextaFeira2,
         horarioOperacaoSabadoFeira, horarioOperacaoSabadoFeira2, horarioOperacaoDomingoFeira, horarioOperacaoDomingoFeira2)
     
     
     
     
     
      */
    return dispatch => {

        AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");

            if (utilizarChat == true && fusoHorarioSelecionado == '') {
                toastr.error('Error', 'Selecione o fuso horário de funcionamento do chat online.');
                // ativaDesativaLoadingBotaoAlterarConfigChatOnline(dispatch, false);
            } else if (utilizarChat == true && vigienciaSegundaFeira == false && vigienciaTercaFeira == false &&
                vigienciaQuartaFeira == false && vigienciaQuintaFeira == false && vigienciaSextaFeira == false &&
                vigienciaSabadoFeira == false && vigienciaDomingoFeira == false) {
                toastr.error('Error', 'Marque pelo menos um dia de operação do chat online.');
                // ativaDesativaLoadingBotaoAlterarConfigChatOnline(dispatch, false);
            }
            else {

                ativaDesativaLoadingBotaoAlterarConfigChatOnline(dispatch, true);
                firebase.database().ref(`${chaveUnica}/configuracao/chatOnline`).once('value').then((snapshot) => {
                    const dadosEmpresa = [];
                    if (!snapshot.val()) {
                        firebase.database().ref(`${chaveUnica}/configuracao/chatOnline/`).push({
                            vigienciaSegundaFeira,
                            vigienciaTercaFeira,
                            vigienciaQuartaFeira,
                            vigienciaQuintaFeira,
                            vigienciaSextaFeira,
                            vigienciaSabadoFeira,
                            vigienciaDomingoFeira,
                            horarioOperacaoSegundaFeira,
                            horarioOperacaoSegundaFeira2,
                            horarioOperacaoTercaFeira,
                            horarioOperacaoTercaFeira2,
                            horarioOperacaoQuartaFeira,
                            horarioOperacaoQuartaFeira2,
                            horarioOperacaoQuintaFeira,
                            horarioOperacaoQuintaFeira2,
                            horarioOperacaoSextaFeira,
                            horarioOperacaoSextaFeira2,
                            horarioOperacaoSabadoFeira,
                            horarioOperacaoSabadoFeira2,
                            horarioOperacaoDomingoFeira,
                            horarioOperacaoDomingoFeira2,
                            utilizarChat,
                            utilizar2Horarios,
                            fusoHorario: fusoHorarioSelecionado
                        }).then(() => {
                            toastr.success('Sucesso', 'Configuração do Chat foi alterada com SUCESSO!!');
                            ativaDesativaLoadingBotaoAlterarConfigChatOnline(dispatch, false);
                        })
                    } else {
                        snapshot.forEach(childItem => {
                            dadosEmpresa.push({
                                key: childItem.key,
                            })
                        })
                        /*   
                           */
                        firebase.database().ref(`${chaveUnica}/configuracao/chatOnline/${dadosEmpresa[0].key}`).update({
                            vigienciaSegundaFeira,
                            vigienciaTercaFeira,
                            vigienciaQuartaFeira,
                            vigienciaQuintaFeira,
                            vigienciaSextaFeira,
                            vigienciaSabadoFeira,
                            vigienciaDomingoFeira,
                            horarioOperacaoSegundaFeira,
                            horarioOperacaoSegundaFeira2,
                            horarioOperacaoTercaFeira,
                            horarioOperacaoTercaFeira2,
                            horarioOperacaoQuartaFeira,
                            horarioOperacaoQuartaFeira2,
                            horarioOperacaoQuintaFeira,
                            horarioOperacaoQuintaFeira2,
                            horarioOperacaoSextaFeira,
                            horarioOperacaoSextaFeira2,
                            horarioOperacaoSabadoFeira,
                            horarioOperacaoSabadoFeira2,
                            horarioOperacaoDomingoFeira,
                            horarioOperacaoDomingoFeira2,
                            utilizarChat,
                            utilizar2Horarios,
                            fusoHorario: fusoHorarioSelecionado
                        }).then(() => {
                            toastr.success('Sucesso', 'Configuração do Chat foi alterada com SUCESSO!!');
                            ativaDesativaLoadingBotaoAlterarConfigChatOnline(dispatch, false);
                        })
                    }

                })
            }
        })

    }
}
export const listaRespostasPadroes = () => {
    return dispatch => {

        AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            firebase.database().ref(`${chaveUnica}/respostapadrao`).orderByKey().on("value", snapshot => {
                /*   
                   */
                if (snapshot.val() == null) {
                    const arrayUser = [];
                    /*   */
                    enviaRespostaParaAcoesChamado(dispatch, [])
                } else {
                    let children2 = [];
                    let childrenSelecionado = [];
                    let arrayProblemasSelecionado = [];
                    snapshot.forEach(childItem => {
                        if (childItem.val().selecionado == 1) {
                            /*   */
                            childrenSelecionado.push({
                                value: childItem.key,
                            });
                            children2.push({
                                value: childItem.key,
                                name: childItem.val().resposta,
                                subTitle: childItem.val().tituloresposta,
                            });
                        } else {
                            firebase.database().ref(`${chaveUnica}/respostapadrao/${childItem.key}`).remove();
                        }
                    });
                    
                    
                    enviaRespostaParaAcoesChamado(dispatch, children2)

                }
            });
        })
    }
}
export const listaRespostasPadroesAntesRemoved = () => {
    return dispatch => {
        AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            firebase.database().ref(`${chaveUnica}/respostapadrao`).orderByKey().on("value", snapshot => {
                /*   
                   */
                if (snapshot.val() == null) {
                    const arrayUser = [];
                    /*   */
                    enviaRespostaParaAcoesChamado(dispatch, [])
                } else {
                    let children2 = [];
                    let childrenSelecionado = [];
                    let arrayProblemasSelecionado = [];
                    snapshot.forEach(childItem => {
                        if (childItem.val().selecionado == 1) {
                            /*   */
                            childrenSelecionado.push({
                                value: childItem.key,
                            });
                            children2.push({
                                value: childItem.key,
                                name: childItem.val().resposta,
                                subTitle: childItem.val().tituloresposta,
                            });
                        } else {
                            firebase.database().ref(`${chaveUnica}/respostapadrao/${childItem.key}`).remove();
                        }
                    });
                    
                    
                    enviaRespostaParaAcoesChamado(dispatch, children2)

                }
            });
        })
    }
}


export const modificaEnviarMensagemTransSetorWppConfig = (valor) => {
    return {
        type: MODIFICAENVIARMENSAGEMTRANSSETORWPPCONFIG,
        payload: valor
    };
};



const enviaSeUtilizaChatAtendimentoOnline = (dispatch, valor) => {
    dispatch({
        type: ENVIASEUTILIZACHATATENDIMENTOONLINE,
        payload: valor
    });
};
const enviaProblemasParaReducer = (dispatch, valor) => {
    dispatch({
        type: ENVIAPROBLEMAPARAREDUCER,
        payload: valor
    });
};
const enviaRespostaParaAcoesChamado = (dispatch, valor) => {
    dispatch({
        type: ENVIARESPOSTAPARAACOESCHAMADO,
        payload: valor
    });
};
const enviaRespostaPadroesParaReducer = (dispatch, valor) => {
    dispatch({
        type: ENVIARESPOSTAPADROESPARAREDUCER,
        payload: valor
    });
};
const enviaRespostasSelecionadasParaReducer = (dispatch, valor) => {
    dispatch({
        type: ENVIARESPOSTASSELECIONADASPARAREDUCER,
        payload: valor
    });
};
const enviaProblemaParaProblemaSelecionado = (dispatch, valor) => {
    dispatch({
        type: ENVIAPROBLEMAPARAPROBLEMASELECIONADO,
        payload: valor
    });
};
const ativaDesativaLoadingCarregandoProblemas = (dispatch, valor) => {
    dispatch({
        type: ATIVADESATIVALOADINGCARREGANDOPROBLEMAS,
        payload: valor
    });
};
const ativaDesativaLoadingBotaoAlterarConfiguracaoAtendimento = (dispatch, valor) => {
    dispatch({
        type: ATIVADESATIVALOADINGBOTAOALTERARCONFIGURACAOATENDIMENTO,
        payload: valor
    });
};
const mensagemSemConexaoInternetConfiguracaoAtendimento = (dispatch, valor) => {
    dispatch({
        type: MENSAGEMSEMCONEXAOINTERNETCONFIGURACAOATENDIMENTO,
        payload: valor
    });
};
export const modificaMotivoCancelamentoConfig = (valor) => {
    return {
        type: MODIFICAMOTIVOCANCELAMENTOCONFIG,
        payload: valor
    };
};
const modificaMotivoCancelamentoConfigLocal = (dispatch, valor) => {
    dispatch({
        type: MODIFICAMOTIVOCANCELAMENTOCONFIG,
        payload: valor
    });
};
export const modificaAtendenteVeApenasProprioHistorico = (valor) => {
    return {
        type: MODIFICAATENDENTEVEAPENASPROPRIOHISTORICO,
        payload: valor
    };
};
//daqui desativa assitente
export const modificaMostrarNomeAtendenteMensagem = (valor) => {
    return {
        type: MODIFICAMOSTRARNOMEATENDENTEMENSAGEM,
        payload: valor
    };
};
export const modificaDesativarAssistenteVirtualWpp = (valor) => {
    return {
        type: MODIFICADESATIVARASSISTENTEVIRTUALWPP,
        payload: valor
    };
};
export const modificaSolicitarNomeClientePrimeirocontato = (valor) => {
    return {
        type: MODIFICASOLICITARNOMECLIENTEPRIMEIROCONTATO,
        payload: valor
    };
};

const enviaDadosConfiguracaoAtendimento = (dispatch, valor) => {
    dispatch({
        type: ENVIADADOSCONFIGURACAOATENDIMENTO,
        payload: valor
    });
};
const ativaDesativaLoadingConfiguracaoAtendimento = (dispatch, valor) => {
    dispatch({
        type: ATIVADESATIVALOADINGCONFIGURACAOATENDIMENTO,
        payload: valor
    });
};


const ativaDesativaLoadingBotaoAlterarConfigAvaliacao = (dispatch, valor) => {
    dispatch({
        type: ATIVADESATIVALOADINGBOTAOALTERARCONFIGAVALIACAO,
        payload: valor
    });
};
const enviaSetorParaConfiguracaoLimite = (dispatch, valor) => {
    dispatch({
        type: ENVIASETORPARACONFIGURACAOLIMITE,
        payload: valor
    });
};

const ativaDesativaLoadingBotaoAlterarConfigChatOnline = (dispatch, valor) => {
    dispatch({
        type: ATIVADESATIVALOADINGBOTAOALTERARCONIGCHATONLINE,
        payload: valor
    });
};
const ativaDesativaLoadingBotaoAlterarNomeEmpresaConfigEmpresa = (dispatch, valor) => {
    dispatch({
        type: ATIVADESATIVALOADINGBOTAOALTERARNOMEEMPRESACONFIGEMPRESA,
        payload: valor
    });
};
const ativaDesativaLoadingBotaoNovoProblema = (dispatch, valor) => {
    dispatch({
        type: ATIVADESATIVALOADINGBOTAONOVOPROBLEMA,
        payload: valor
    });
};
const ativaDesativaLoadingBOtaoNovaResposta = (dispatch, valor) => {
    dispatch({
        type: ATIVADESATIVALOADINGBOTAONVOARESPOSTA,
        payload: valor
    });
};
const ativaDesativaLoadingTelaConfigEmpresa = (dispatch, valor) => {
    dispatch({
        type: ATIVADESATIVALOADINGTELACONFIGEMPRESA,
        payload: valor
    });
};
const ativaDesativaLoadingTelaConfigAvaliacao = (dispatch, valor) => {
    dispatch({
        type: ATIVADESATIVALOADINGTELACONFIGAVALIACAO,
        payload: valor
    });
};
const enviaNomeEmpresReducer = (dispatch, nome) => {
    dispatch({
        type: ENVIANOMEEMPRESAPARAREDUCER,
        payload: nome
    });
};
const enviaSessionClient = (dispatch, session) => {
    dispatch({
        type: ENVIASESSIONCLIENT,
        payload: session
    });
};
const enviaQrCode = (dispatch, qr) => {
    dispatch({
        type: ENVIAQRCODE,
        payload: qr
    });
};
const enviaStatusConnect = (dispatch, status) => {
    dispatch({
        type: ENVIASTATUSCONNECT,
        payload: status
    });
};

const enviaMensagemAvaliacaoParaReducer = (dispatch, nome) => {
    dispatch({
        type: ENVIAMENSAGEMAVALIACAOPARAREDUCER,
        payload: nome
    });
};
const enviaSeDesejaMandarMensagemAvaliacaoAposFInalizarAtendimento = (dispatch, value) => {
    dispatch({
        type: ENVIASEDESEJAMANDARMENSAGEMAVALIACAOAPOSFINALIZARATENDIMENTO,
        payload: value
    });
};

const mensagemSemConexaoInternetConfigEmpresa = (dispatch, valor) => {
    dispatch({
        type: MENSAGEMSEMCONEXAOINTERNETCONFIGEMPRESA,
        payload: valor
    });
};
const mensagemSemConexaoInternetConfigAvaliacao = (dispatch, valor) => {
    dispatch({
        type: MENSAGEMSEMCONEXAOINTERNETCONFIGAVALIACAO,
        payload: valor
    });
};


export const modificaProblemaSelecionadoSectioned = (value) => {
    return {
        type: ENVIAPROBLEMAPARAPROBLEMASELECIONADO,
        payload: value
    }
};
export const modificaRespostaSelecionada = (value) => {
    return {
        type: MODIFICARESPOSTASELECIONADA,
        payload: value
    }
};
export const modificaMensagemRespostaPadraoAlterar = (time) => {
    return {
        type: MODIFICAMENSAGEMRESPOSTAPADRAOALTERAR,
        payload: time
    }
};
export const modificaDescricaoRespostaPadraoAlterar = (time) => {
    return {
        type: MODIFICADESCROCAORESPOSTAPADRAOALTERAR,
        payload: time
    }
};
export const alteraValorSwitchConfigChamadoEstatico = (value) => {
    return {
        type: ALTERAVALORSWITCHCONFIGCHAMADOESTATICO,
        payload: value
    }
};
export const modificaClicouCFGchat = (value) => {
    return {
        type: MODIFICACLICOUCFGCHAT,
        payload: value
    }
};
export const alteraValorSwitchConfigAtendimento = (value) => {
    return {
        type: ALTERAVALORSWITCHCONFIGATENDIMENTO,
        payload: value
    }
};
export const modificaDescricaoProblemaEstatico = (value) => {
    return {
        type: MODIFICADESCRICAOPROBLEMAESTATICO,
        payload: value
    }
};
export const modificaLimiteDeEsperaFilaChat = (value) => {
    return {
        type: MODIFICALIMITEDEESPERAFILACHAT,
        payload: value
    }
};

const zeraDescricaoProblemaCadastroSucesso = (dispatch, value) => {
    dispatch({
        type: MODIFICADESCRICAOPROBLEMAESTATICO,
        payload: value
    })
};
const alteraAtendenteVeApenasProprioChamadoLOCAL = (dispatch, value) => {
    dispatch({
        type: ALTERAATENDENTEVEAPENASPROPRIOCHAMADO,
        payload: value
    })
};

const alteraAgrupaAtendenteSetorLOCAL = (dispatch, value) => {
    dispatch({
        type: ALTERAAGRUPAATENDENTESETOR,
        payload: value
    })
};
const modificaObrigatorioResolucaoChamadoLocal = (dispatch, value) => {
    dispatch({
        type: MODIFICAOBRIGATORIORESOLUCAOCHAMADO,
        payload: value
    })
};
export const modificaObrigatorioResolucaoChamado = (value) => {
    return {
        type: MODIFICAOBRIGATORIORESOLUCAOCHAMADO,
        payload: value
    }
};
export const modificaLogoTipoTelaConfiguracao = (value) => {
    return {
        type: MODIFICALOGOTIPOTELACONFIGURACAO,
        payload: value
    }
};

export const modificaImagemCompletaLogoTipoConfiguracao = (value) => {
    return {
        type: MODIFICAIMAGEMCOMPLETALOGOTIPOCONFIGURACAO,
        payload: value
    }
};

export const alteraAtendenteVeApenasProprioChamado = (value) => {
    return {
        type: ALTERAATENDENTEVEAPENASPROPRIOCHAMADO,
        payload: value
    }
};

export const alteraAgrupaAtendenteSetor = (value) => {
    return {
        type: ALTERAAGRUPAATENDENTESETOR,
        payload: value
    }
};
export const alteraValorSwitchConfigAvaliacao = (value) => {
    return {
        type: ENVIASEDESEJAMANDARMENSAGEMAVALIACAOAPOSFINALIZARATENDIMENTO,
        payload: value
    }
};
export const modificaSwitchAdmVisualizaApenasChamadoSetor = (value) => {
    return {
        type: MODIFICASWITCHADMVISUALIZAAPENASCHAMADOSETOR,
        payload: value
    }
};
export const modificaFusoHorarioSelecionado = (value) => {
    return {
        type: MODIFICAFUSOHORARIOSELECIONADO,
        payload: value
    }
};
export const alteraValorSwitchUtilizaVersaoBETA = (value) => {
    return {
        type: ALTERAVALORSWITCHUTILIZAVERSAOBETA,
        payload: value
    }
};
export const alteraValorCheckSegundaFeira = (value) => {
    return {
        type: ALTERAVALORCHECKSEGUNDAEIRA,
        payload: value
    }
};
export const alteraValorCheckTercaFeira = (value) => {
    return {
        type: ALTERAVALORCHECKTERCAEIRA,
        payload: value
    }
};
export const alteraValorCheckQuartaFeira = (value) => {
    return {
        type: ALTERAVALORCHECKQUARTAEIRA,
        payload: value
    }
};
export const alteraValorCheckQuintaFeira = (value) => {
    return {
        type: ALTERAVALORCHECKQUINTAEIRA,
        payload: value
    }
};
export const alteraValorCheckSextaFeira = (value) => {
    return {
        type: ALTERAVALORCHECKSEXTAEIRA,
        payload: value
    }
};
export const alteraValorCheckSabadoFeira = (value) => {
    return {
        type: ALTERAVALORCHECKSABADOEIRA,
        payload: value
    }
};
export const alteraValorCheckDomingoFeira = (value) => {
    return {
        type: ALTERAVALORCHECKDOMINGOEIRA,
        payload: value
    }
};


export const alteraValorSwitchUtilizarChat = (value) => {
    return {
        type: ALTERAVALORSWITCHUTILIZARCHAT,
        payload: value
    }
};

export const alteraMensagemAvaliacao = (value) => {
    return {
        type: ENVIAMENSAGEMAVALIACAOPARAREDUCER,
        payload: value
    }
};
export const modificaNomeEmpresa = (value) => {
    return {
        type: MODIFICANOMEEMPRESACONFIGURAEMPRESA,
        payload: value
    }
};
export const zeraMensagemSemInternetConfigEmpresa = () => {
    return {
        type: MENSAGEMSEMCONEXAOINTERNETCONFIGEMPRESA,
        payload: ''
    }
};
export const zeraMensagemSemInternetConfigAtendimento = () => {
    return {
        type: MENSAGEMSEMCONEXAOINTERNETCONFIGURACAOATENDIMENTO,
        payload: ''
    }
};

export const zeraMensagemSemInternetConfigAvaliacao = () => {
    return {
        type: MENSAGEMSEMCONEXAOINTERNETCONFIGAVALIACAO,
        payload: ''
    }
};
//aqui new

export const modificaMensagemBoasVindasWpp = (value) => {
    return {
        type: MODIFICAMENSAGEMBOASVINDASWPP,
        payload: value
    };
};
export const modificaMensagemEscolhaDepartamento = (value) => {
    return {
        type: MODIFICAMENSAGEMESCOLHADEPARTAMENTO,
        payload: value
    };
};
export const modificaMensagemFilaAtendimento = (value) => {
    return {
        type: MODIFICAMENSAGEMFILAATENDIMENTO,
        payload: value
    };
};
export const modificaMensagemFinalizacaoChamado = (value) => {
    return {
        type: MODIFICAMENSAGEMFINALIZACAOCHAMADO,
        payload: value
    };
};
export const modificaMensagemForaHorarioOperacao = (value) => {
    return {
        type: MODIFICAMENSAGEMFORAHORARIOOPERACAO,
        payload: value
    };
};
const ativaDesativaLoadingSalvarConfigWhatsApp = (dispatch, value) => {
    dispatch({
        type: ATIVADESATIVALOADINGSALVARCONFIGWHATSAPP,
        payload: value
    });
};

export const modificaCorFundoPrincipal = (cor) => {
    return {
        type: MODIFICACORFUNDOPRINCIPAL,
        payload: cor
    }
};
export const modificaCorFundoSecundario = (cor) => {
    return {
        type: MODIFICACORFUNDOSECUNDARIO,
        payload: cor
    }
};
export const modificaCorFundoItensSelecionaveis = (cor) => {
    return {
        type: MODIFICACORFUNDOITENSSELECIONAVEIS,
        payload: cor
    }
};
export const modificaCorFundoBotaoPrincipal = (cor) => {
    return {
        type: MODIFICACORFUNDOBOTAOPRINCIPAL,
        payload: cor
    }
};
export const modificaCorPrincipalChat = (cor) => {
    return {
        type: MODIFICACORPRINCIPALCHAT,
        payload: cor
    }
};

export const modificaCorFundoBotaoSecundario = (cor) => {
    return {
        type: MODIFICACORFUNDOBOTAOSECUNDARIO,
        payload: cor
    }
};
export const modificaCorLetraBotao = (cor) => {
    return {
        type: MODIFICACORLETRAPADRAO,
        payload: cor
    }
};
export const modificaCorLetraPrincipal = (cor) => {
    return {
        type: MODIFICACORLETRAPRINCIPAL,
        payload: cor
    }
};
const verificaCriarChamadoProblemaEstatico = (dispatch, valor) => {
    dispatch({
        type: VERIFICACRIARCHAMADOPROBLEMAESTATICO,
        payload: valor
    });
};
const modificaKeySetorSelecionadoParaEnviarAtendimentoAposLimite = (dispatch, valor) => {
    dispatch({
        type: MODIFICAKEYSETORSELECIONADOPARAENVIARATENDIMENTOAPOSLIMITE,
        payload: valor
    });
};
export const modificaKeySetorSelecionadoParaEnviarAtendimentoAposLimiteExport = (valor) => {
    return {
        type: MODIFICAKEYSETORSELECIONADOPARAENVIARATENDIMENTOAPOSLIMITE,
        payload: valor
    }
};
export const modificaCorLetraSecundaria = (cor) => {
    return {
        type: MODIFICACORLETRASECUNDARIA,
        payload: cor
    }
};
export const modificaCorDescricaoDosCampos = (cor) => {
    return {
        type: MODIFICACORLETRADESCRICAODOSCAMPOS,
        payload: cor
    }
};
export const modificaCorFundoMenu = (cor) => {
    return {
        type: MODIFICACORFUNDOMENU,
        payload: cor
    }
};
export const modificaHorarioOperacaoSegundaFeira = (time) => {
    return {
        type: MODIFICAHORARIOOPERACAOSEGUNDAFEIRA,
        payload: time
    }
};
export const modificaHorarioOperacaoSegundaFeira2 = (time) => {
    return {
        type: MODIFICAHORARIOOPERACAOSEGUNDAFEIRA2,
        payload: time
    }
};


/* ------------------aqui-------------------- */
export const modificaHorarioOperacaoTercaFeira = (time) => {
    return {
        type: MODIFICAHORARIOOPERACAOTERCAFEIRA,
        payload: time
    }
};
export const modificaHorarioOperacaoTercaFeira2 = (time) => {
    return {
        type: MODIFICAHORARIOOPERACAOTERCAFEIRA2,
        payload: time
    }
};
export const modificaHorarioOperacaoQuartaFeira = (time) => {
    return {
        type: MODIFICAHORARIOOPERACAOQUARTAFEIRA,
        payload: time
    }
};
export const modificaHorarioOperacaoQuartaFeira2 = (time) => {
    return {
        type: MODIFICAHORARIOOPERACAOQUARTAFEIRA2,
        payload: time
    }
};
export const modificaHorarioOperacaoQuintaFeira = (time) => {
    return {
        type: MODIFICAHORARIOOPERACAOQUINTAFEIRA,
        payload: time
    }
};
export const modificaHorarioOperacaoQuintaFeira2 = (time) => {
    return {
        type: MODIFICAHORARIOOPERACAOQUINTAFEIRA2,
        payload: time
    }
};
export const modificaHorarioOperacaoSextaFeira = (time) => {
    return {
        type: MODIFICAHORARIOOPERACAOSEXTAFEIRA,
        payload: time
    }
};
export const modificaHorarioOperacaoSextaFeira2 = (time) => {
    return {
        type: MODIFICAHORARIOOPERACAOSEXTAFEIRA2,
        payload: time
    }
};
export const modificaMensagemRespostaPadrao = (time) => {
    return {
        type: MODIFICAMENSAGEMRESPOSTAPADRAO,
        payload: time
    }
}; export const modificaDescricaoRespostaPadrao = (time) => {
    return {
        type: MODIFICADESCROCAORESPOSTAPADRAO,
        payload: time
    }
};

export const modificaHorarioOperacaoSabadoFeira = (time) => {
    return {
        type: MODIFICAHORARIOOPERACAOSABADOFEIRA,
        payload: time
    }
};
export const modificaHorarioOperacaoSabadoFeira2 = (time) => {
    return {
        type: MODIFICAHORARIOOPERACAOSABADOFEIRA2,
        payload: time
    }
};
export const modificaHorarioOperacaoDomingoFeira = (time) => {
    return {
        type: MODIFICAHORARIOOPERACAODOMINGOFEIRA,
        payload: time
    }
};
export const modificaHorarioOperacaoDomingoFeira2 = (time) => {
    return {
        type: MODIFICAHORARIOOPERACAODOMINGOFEIRA2,
        payload: time
    }
};


export const alteraValorSwitcUtilizar2HorariosChat = (value) => {
    return {
        type: ALTERAVALORSWITCHUTILIZAR2HORARIOSCHAT,
        payload: value
    }
};

export const modificaCorLetraMenu = (cor) => {
    return {
        type: MODIFICACORLETRAMENU,
        payload: cor
    }
};

export const alteraSwitchNotifAtendABertoPorMimLocal = (dispatch, valor) => {
    dispatch({
        type: ALTERASWITCHNOTIFATENDABERTOPORMIM,
        payload: valor
    })
}
export const alteraSwitchNotifAtendEstouAtendendoLocal = (dispatch, valor) => {
    dispatch({
        type: ALTERASWITCHNOTIFATENDESTOUATENDENDO,
        payload: valor
    })
}
export const alteraSwitchNotifRespAtendAbertoPorMimLocal = (dispatch, valor) => {
    dispatch({
        type: ALTERASWITCHNOTIFRESPATENDABERTOPORMIM,
        payload: valor
    })
}
export const alteraSwitchNotifRespAtendQueEstouAtendendoLocal = (dispatch, valor) => {
    dispatch({
        type: ALTERASWITCHNOTIFRESPATENDQUEESTOUATENDENDO,
        payload: valor
    })
}
export const alteraSwitchNotifComentarioInternoLocal = (dispatch, valor) => {
    dispatch({
        type: ALTERASWITCHNOTIFCOMENTARIOINTERNO,
        payload: valor
    })
}
export const alteraValorSwitchEnviaAtendimentoParaSetorAposLimite = (valor) => {
    return {
        type: ALTERAVALORSWITCHENVIATENDIMENTOPARASETORAPOSLIMITE,
        payload: valor
    }
}


const AsyncStorage = {
    setItem: function (key, value) {
        return Promise.resolve().then(function () {
            localStorage.setItem(key, value);
        });
    },
    getItem: function (key) {
        return Promise.resolve().then(function () {
            return localStorage.getItem(key);
        });
    }
};