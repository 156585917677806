import React from 'react';
import { connect } from 'react-redux';
import { ResponsivePie } from '@nivo/pie';
import moment from 'moment';
import { toastr } from 'react-redux-toastr';
import { ResponsiveLine } from '@nivo/line'
import { DateRangePicker, SingleDatePicker, DayPickerRangeController, isSameDay } from 'react-dates';
import SelectSearch from 'react-select-search';
import { Modal } from 'react-responsive-modal';
import { withRouter } from "react-router-dom";
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import {
    modificaCorBordaListaTarefas, insereDataFiltroDashboard, modificaDataInicialFinalRelatorioAtendimentoDetalhado,
    modificaFiltroSelecionadoRelatorioDetalhado,

    modificaClienteTelaRelatorioDetalhado,
    modificaAtendenteRelatorioDetalhado, modificaSetorRelatorioDetalhado,
    modificaFiltroTelaRelatorioDetalhado, modificaFiltroClienteSectionedTelaRelatorioDetalhado, modificaFiltroAtendenteSectionedTelaRelatorioDetalhado,
    modificaFiltroSetorSectionedTelaRelatorioDetalhado, modificaVisibilidadeModalInformacaoRelatorioDetalhado,
    insereTextosInformacionaisModalRelatorioDetalhado, zeraMensagemGerandoRelatorio, novalistaAtendimentosZeradoParaRelatorioDetalhado,
    listaAtendimentosPeriodoPersonalizado, exportaAtendimentosParaExcelPdf, desligaOuvintesChamadosFirebase,
    listaRelatoriosCRM, modificaDataInicialFinalRelatorioCRM, exportaCrmParaExcel
} from '../../actions/DashBoardAction';
import { modificaNomeRotaNoHeader } from '../../actions/HeaderAction';
import { listaAtendentesTelaConsultaAtendente } from '../../actions/AtendenteAction';
import { listaSetorSectionedTelaConsultaSetor } from '../../actions/SetorAction';

import {
    Title, Container, ContainerBox, Box, TextStatus, TextResultado, ContainerGrafico, SubContainerGrafico,
    ContainerTextGrafico, TextGrafico, ContainerAviso, TextAviso, SubContainerListaDeTarefas, TextTarefas,
    SubContainerInputEmail, InputEmail, DivContainerSelectDate, BotaoPesquisarChamadoPorNumero,
    ContainerGraficoTempoMedio, SubContainerGraficoTempoMedio, SubContainerGraficoTempoMedioSemSombra,
    ContainerTextTempoMedio, TextTempoMedio, ContainerIconesExport, IconeExport,
    ContainerTextTituloTempoMedio, DivTextPeriodo, TextPeriodo, ContainerInputSearch, IconeSair, DivPeriodoBotao,
    ContainerIconesExportTempoMedio, ContainerModal, ContainerIconeFecharModal, TextNovoAtendimento, ContainerExplicacaoVincular, TextVincular,
    IconeFechar,
    InputSearch, DivInputSearch, IconeSetaPraBaixo,
    ContainerAtendentes, ContainerInputEBotaoComent, InputDescricaoChamado,
    ContainerTextGraficoLoading, TextGraficoLoading, ContainerGraficoLoading, GraficoLoading,
    TextGraficoLoadingTempoMedio, ContainerModalPrioridadeAtendimento, DivInputComentarChamado, DivBotaoSalvarComentario,
    ButtonSalvarComentario, TextBotaoSalvarComentar

} from './styled';

class RelatorioCRM extends React.Component {
    state = {
        startDate: null,
        endDate: null,
        focusedInput: null,
        acessoPermitido: false,
        modalSenhaAcesso: true, //reability true
        senhaAcesso: '',
        numeroTentativa: 0
    }


    componentWillUnmount() {

    }
    componentDidMount() {


        /*   if (this.props.premium == 0) {
             toastr.error('Recurso Premium', 'Este é um recurso PREMIUM, Assine a versão PREMIUM e libere esse e mais recursos!');
             this.props.history.push('/dashboard')
 
         } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
             toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
             this.props.history.push('/dashboard')
 
         } else { */

        moment.locale('pt-br', {
            months: 'Janeiro_Fevereiro_Março_Abril_Maio_Junho_Julho_Agosto_Setembro_Outubro_Novembro_Dezembro'.split('_')
        })
      
        /*   if (this.state.acessoPermitido) {
              this.props.listaRelatoriosCRM('', '');
              this.props.modificaNomeRotaNoHeader('Relatório CRM DropDesk')
          } else {
  
          } */


        /* else {
           toastr.error('Acesso Negado', 'Você não tem permissão para acessar esta tela');
           this.props.history.push('/dashboard')
       } 
   } */
    }
    /*   removeFiltrosAll() {
          this.props.modificaFiltroSelecionadoRelatorioDetalhado('');
          this.props.modificaAtendenteRelatorioDetalhado('');
          this.props.modificaClienteTelaRelatorioDetalhado('');
          this.props.modificaSetorRelatorioDetalhado('');
          this.props.listaAtendimentosPeriodoPersonalizado(this.props.dataInicialConsultaFiltroRelatorioDetalhado, this.props.dataFinalConsultaFiltroRelatorioDetalhado, 1, 'padrao', '')
      } */
    handleInputEmailBlur() {
        this.props.modificaCorBordaListaTarefas(this.props.corBordaDinamica);

    }
    handleInputEmailFocus() {
        this.props.modificaCorBordaListaTarefas(this.props.corBotaoPadrao);
    }
    handleWeekDays = (day) => {
        //Change week day with custom day array
        day._locale._weekdaysMin = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'];
        // return the actual dates value(like 1,2,3 ...) from the moment object.
        return (day.format('D'));
    }
    insereDataInicialFinal = ({ dataInicial, dataFinal }) => {
        // 
        // 
        if (dataInicial !== null) {
            let dataInicio = moment(dataInicial, 'MM/DD/YYYY').format('DD/MM/YYYY');
            let state = this.state;
            state.startDate = dataInicio;
            this.setState({ state })
        }
        if (dataFinal !== null) {
            let dataFim = moment(dataFinal, 'MM/DD/YYYY').format('DD/MM/YYYY');
            let state = this.state;
            state.endDate = dataFim;
            this.setState({ state })
        }

    }
    modificaSenhAcesso = (senha) => {
        this.setState({ senhaAcesso: senha })
    }
    renderLoadingRelatorioDetalhado() {
        if (this.props.loadingRelatorioCRM) {
            return (
                <Container backgroundDinamico={this.props.backgroundPrimeiro}>
                    <ContainerGrafico>
                        <SubContainerGrafico backgroundDinamico={this.props.backgroundSegundo}>
                            <ContainerTextGraficoLoading>
                                <TextGraficoLoading backgroundinamico={this.props.backgroundSegundo}></TextGraficoLoading>
                            </ContainerTextGraficoLoading>
                            <ContainerGraficoLoading>
                                <GraficoLoading backgroundinamico={this.props.backgroundSegundo}></GraficoLoading>
                            </ContainerGraficoLoading>
                        </SubContainerGrafico>
                        <SubContainerGrafico backgroundDinamico={this.props.backgroundSegundo}>
                            <ContainerTextGraficoLoading>
                                <TextGraficoLoading backgroundinamico={this.props.backgroundSegundo}></TextGraficoLoading>
                            </ContainerTextGraficoLoading>
                            <ContainerGraficoLoading>
                                <GraficoLoading backgroundinamico={this.props.backgroundSegundo}></GraficoLoading>
                            </ContainerGraficoLoading>
                        </SubContainerGrafico>

                    </ContainerGrafico>
                    <ContainerGrafico >
                        <SubContainerGrafico backgroundDinamico={this.props.backgroundSegundo}>
                            <ContainerTextGraficoLoading>
                                <TextGraficoLoading backgroundinamico={this.props.backgroundSegundo}></TextGraficoLoading>
                            </ContainerTextGraficoLoading>
                            <ContainerGraficoLoading>
                                <GraficoLoading backgroundinamico={this.props.backgroundSegundo}></GraficoLoading>
                            </ContainerGraficoLoading>
                        </SubContainerGrafico >
                        <SubContainerGrafico backgroundDinamico={this.props.backgroundSegundo}>
                            <ContainerTextGraficoLoading>
                                <TextGraficoLoading backgroundinamico={this.props.backgroundSegundo}></TextGraficoLoading>
                            </ContainerTextGraficoLoading>
                            <ContainerGraficoLoading>
                                <GraficoLoading backgroundinamico={this.props.backgroundSegundo}></GraficoLoading>
                            </ContainerGraficoLoading>

                        </SubContainerGrafico>
                    </ContainerGrafico>
                    <ContainerGraficoTempoMedio>
                        <SubContainerGraficoTempoMedio backgroundDinamico={this.props.backgroundSegundo}>
                            <ContainerTextTituloTempoMedio>
                                <TextGraficoLoadingTempoMedio backgroundinamico={this.props.backgroundSegundo}></TextGraficoLoadingTempoMedio>
                            </ContainerTextTituloTempoMedio>
                            <ContainerTextTempoMedio>

                                <TextGraficoLoadingTempoMedio backgroundinamico={this.props.backgroundSegundo}></TextGraficoLoadingTempoMedio>
                            </ContainerTextTempoMedio>
                        </SubContainerGraficoTempoMedio>
                        <SubContainerGraficoTempoMedioSemSombra backgroundDinamico={this.props.backgroundPrimeiro}>

                        </SubContainerGraficoTempoMedioSemSombra>
                    </ContainerGraficoTempoMedio>

                </Container >
            )
        } else {
            return (
                <Container backgroundDinamico={this.props.backgroundPrimeiro}>

                    <DivContainerSelectDate>
                        <div style={{}}>
                            <DivTextPeriodo>
                                <i className="icon-calendarweb" style={{ color: this.props.coresLetraPadrao, fontSize: 15, marginRight: 3 }} />
                                <TextPeriodo corLetraDinamica={this.props.coresLetraPadrao}>Período:</TextPeriodo>
                            </DivTextPeriodo>
                            <DivPeriodoBotao >
                                <DateRangePicker
                                    // customInputIcon={<i className="icon-calendar" style={{ color: '#000', marginRight: 5 }} />}
                                    //   customArrowIcon={<Icon icon="next" size="xxs" />}
                                    // customCloseIcon={<i className="icon-close" style={{ color: '#000', marginRight: 5 }} />}
                                    displayFormat={() => "DD/MM/YYYY"}
                                    small
                                    isOutsideRange={() => false}
                                    minimumNights={0}
                                    firstDayOfWeek={1}
                                    renderDayContents={this.handleWeekDays}
                                    isDayHighlighted={day => isSameDay(moment(new Date()), moment(day))}
                                    hideKeyboardShortcutsPanel
                                    startDatePlaceholderText="Data Inicial"
                                    endDatePlaceholderText="Data Final"
                                    startDate={this.props.dataInicialConsultaRelatorioCRM} // momentPropTypes.momentObj or null,
                                    startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                                    endDate={this.props.dataFinalConsultaRelatorioCRM} // momentPropTypes.momentObj or null,
                                    endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                                    //onDatesChange={this.insereDataInicialFinal} // PropTypes.func.isRequired,
                                    onDatesChange={({ startDate, endDate }) => this.props.modificaDataInicialFinalRelatorioCRM(startDate, endDate)}
                                    focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                    onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                                />
                                {this.props.dataInicialConsultaRelatorioCRM !== null && this.props.dataFinalConsultaRelatorioCRM !== null && <BotaoPesquisarChamadoPorNumero onClick={() => {
                                    this.props.listaRelatoriosCRM(this.props.dataInicialConsultaRelatorioCRM, this.props.dataFinalConsultaRelatorioCRM)
                                }} backGroundDinamico={this.props.corBotaoPadrao} type="button">
                                    <i className="icon-magnifier" style={{ color: this.props.letraBotaoPadrao, fontSize: 15 }} />
                                </BotaoPesquisarChamadoPorNumero>}
                            </DivPeriodoBotao>
                        </div>
                        {/* {this.props.filtroSelecionadoRelatorioDetalhado == 'cliente' && <div style={{ marginTop: 18 }}>
                            <TextPeriodo style={{ marginLeft: 40 }} corLetraDinamica={this.props.coresLetraPadrao}>Cliente:</TextPeriodo>
                            <ContainerInputSearch backGroundDinamico={this.props.backgroundMaisEscuro}>
                                <IconeSair className="icon-close" onClick={() => { this.removeFiltrosAll(); }} />
                                <div style={{ background: this.props.corSegundoBotaoPadrao, borderRadius: 3, height: 30 }}>
                                    <SelectSearch
                                        renderValue={(valueProps) =>
                                            <DivInputSearch>
                                                <InputSearch
                                                    corLetraDinamico={this.props.coresLetraPadrao}
                                                    backgroundDinamico={this.props.backgroundMaisEscuro}
                                                    placeholderColor={this.props.corLetraSecundaria}
                                                    corBordaDinamica={this.props.corBotaoPadrao}
                                                    {...valueProps} ></InputSearch>
                                                <IconeSetaPraBaixo className="icon-angle-arrow-down" colorDinamico={this.props.coresLetraPadrao} />
                                            </DivInputSearch>
                                        }

                                        onChange={(value) => {
                                            this.props.modificaClienteTelaRelatorioDetalhado(value);
                                            this.props.listaAtendimentosPeriodoPersonalizado(this.props.dataInicialConsultaFiltroRelatorioDetalhado, this.props.dataFinalConsultaFiltroRelatorioDetalhado, 1, 'cliente', value);
                                        }}
                                        value={this.props.clienteSelecionadoRelatorioAtendimentoDetalhado}
                                        options={this.props.clientesRelatorioAtendimentoDetalhado}
                                        placeholder={this.props.clientesRelatorioAtendimentoDetalhado.length == 0 ? `Nenhum Cliente cadastrado, Por favor verifique!` : 'Selecione o Cliente'}
                                        search={this.props.clientesRelatorioAtendimentoDetalhado.length == 0 ? false : true}
                                    />
                                </div>
                            </ContainerInputSearch>
                        </div>}

                        {this.props.filtroSelecionadoRelatorioDetalhado == 'atendente' && <div style={{ marginTop: 18 }}>
                            <TextPeriodo style={{ marginLeft: 40 }} corLetraDinamica={this.props.coresLetraPadrao}>Atendente:</TextPeriodo>
                            <ContainerInputSearch backGroundDinamico={this.props.backgroundMaisEscuro}>
                                <IconeSair className="icon-close" onClick={() => { this.removeFiltrosAll(); }} />
                                <div style={{ background: this.props.corSegundoBotaoPadrao, borderRadius: 3, height: 30 }}>
                                    <SelectSearch
                                        renderValue={(valueProps) =>
                                            <DivInputSearch>
                                                <InputSearch
                                                    corLetraDinamico={this.props.coresLetraPadrao}
                                                    backgroundDinamico={this.props.backgroundMaisEscuro}
                                                    placeholderColor={this.props.corLetraSecundaria}
                                                    corBordaDinamica={this.props.corBotaoPadrao}
                                                    {...valueProps} ></InputSearch>
                                                <IconeSetaPraBaixo className="icon-angle-arrow-down" colorDinamico={this.props.coresLetraPadrao} />
                                            </DivInputSearch>
                                        }
                                        onChange={(value) => {
                                            this.props.modificaAtendenteRelatorioDetalhado(value);
                                            this.props.listaAtendimentosPeriodoPersonalizado(this.props.dataInicialConsultaFiltroRelatorioDetalhado, this.props.dataFinalConsultaFiltroRelatorioDetalhado, 1, 'atendente', value);
                                        }}
                                        value={this.props.atendentesSelecionadoRelatorioAtendimentoDetalhado}
                                        options={this.props.atendentesRelatorioAtendimentoDetalhado}
                                        placeholder={this.props.atendentesRelatorioAtendimentoDetalhado.length == 0 ? `Nenhum Atendente cadastrado, Por favor verifique!` : 'Selecione o Atendente'}
                                        search={this.props.atendentesRelatorioAtendimentoDetalhado.length == 0 ? false : true}
                                    />
                                </div>
                            </ContainerInputSearch>
                        </div>}
                        {this.props.filtroSelecionadoRelatorioDetalhado == 'setor' && <div style={{ marginTop: 18 }}>
                            <TextPeriodo style={{ marginLeft: 40 }} corLetraDinamica={this.props.coresLetraPadrao}>Setor:</TextPeriodo>
                            <ContainerInputSearch backGroundDinamico={this.props.backgroundMaisEscuro}>
                                <IconeSair className="icon-close" onClick={() => { this.removeFiltrosAll(); }} />
                                <div style={{ background: this.props.corSegundoBotaoPadrao, borderRadius: 3, height: 30 }}>
                                    <SelectSearch
                                        renderValue={(valueProps) =>
                                            <DivInputSearch>
                                                <InputSearch
                                                    corLetraDinamico={this.props.coresLetraPadrao}
                                                    backgroundDinamico={this.props.backgroundMaisEscuro}
                                                    placeholderColor={this.props.corLetraSecundaria}
                                                    corBordaDinamica={this.props.corBotaoPadrao}
                                                    {...valueProps} ></InputSearch>
                                                <IconeSetaPraBaixo className="icon-angle-arrow-down" colorDinamico={this.props.coresLetraPadrao} />
                                            </DivInputSearch>
                                        }
                                        onChange={(value) => {
                                            this.props.modificaSetorRelatorioDetalhado(value);
                                            this.props.listaAtendimentosPeriodoPersonalizado(this.props.dataInicialConsultaFiltroRelatorioDetalhado, this.props.dataFinalConsultaFiltroRelatorioDetalhado, 1, 'setor', value);
                                        }}
                                        value={this.props.SetoresSelecionadoRelatorioAtendimentoDetalhado}
                                        options={this.props.SetoresRelatorioAtendimentoDetalhado}
                                        placeholder={this.props.SetoresRelatorioAtendimentoDetalhado.length == 0 ? `Nenhum Setor cadastrado, Por favor verifique!` : 'Selecione o Setor'}
                                        search={this.props.SetoresRelatorioAtendimentoDetalhado.length == 0 ? false : true} />
                                </div>
                            </ContainerInputSearch>
                                    </div>} */}
                    </DivContainerSelectDate>

                    <ContainerGrafico>
                        <SubContainerGrafico backgroundDinamico={this.props.backgroundSegundo}>
                            <ContainerTextGrafico>
                                <div style={{ width: 80, height: 30 }}></div>
                                <TextGrafico colorDinamico={this.props.coresLetraPadrao}>Clientes Ativos/Cancelados</TextGrafico>
                                <ContainerIconesExport>

                                    <IconeExport onClick={() => {
                                        this.props.exportaCrmParaExcel(this.props.planosComMaisAssinadosParaBaixar, 'clientes')
                                    }} colorDinamico="#32CD32" className="icon-spreadsheet" />

                                </ContainerIconesExport>
                            </ContainerTextGrafico>

                            <ResponsivePie
                                data={this.props.graficoClienteAtivosCancelados}
                                margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                                innerRadius={0.5}
                                padAngle={0.7}
                                cornerRadius={3}
                                colors={d => d.color}
                                borderWidth={1}
                                borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                                radialLabelsSkipAngle={10}
                                radialLabelsTextXOffset={6}
                                radialLabelsTextColor={this.props.coresLetraPadrao}
                                radialLabelsLinkOffset={0}
                                radialLabelsLinkDiagonalLength={16}
                                radialLabelsLinkHorizontalLength={24}
                                radialLabelsLinkStrokeWidth={1}
                                radialLabelsLinkColor={{ from: 'color' }}
                                slicesLabelsSkipAngle={10}
                                slicesLabelsTextColor="#000"
                                animate={true}
                                motionStiffness={90}
                                motionDamping={15}
                                tooltip={(e) => {
                                    if (e.value == 0.5) {
                                        return (
                                            <div style={{ height: 30, background: '#FFF', padding: 5, borderRadius: 5, flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center' }} key={e.id}>

                                                <div style={{ width: 13, height: 13, backgroundColor: e.color, marginRight: 5, marginLeft: 5 }}></div>
                                                <div>

                                                    <strong style={{ color: '#000' }}>
                                                        {`${e.id}: ${e.valorZerado}`}
                                                    </strong>
                                                </div>
                                            </div>

                                        );
                                    } else {
                                        return (
                                            <div style={{ height: 30, background: '#FFF', padding: 5, borderRadius: 5, flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center' }} key={e.id}>

                                                <div style={{ width: 13, height: 13, backgroundColor: e.color, marginRight: 5, marginLeft: 5 }}></div>
                                                <div>

                                                    <strong style={{ color: '#000' }}>
                                                        {`${e.id}: ${e.value}`}
                                                    </strong>
                                                </div>
                                            </div>

                                        );
                                    }
                                }}
                                sliceLabel={(d) => {
                                    if (d.value == 0.5) {
                                        return d.valorZerado
                                    } else {
                                        return d.value
                                    }
                                }}

                            /*   legends={[
                                  {
                                      anchor: 'bottom',
                                      direction: 'row',
                                      translateY: 56,
                                      itemWidth: 100,
                                      itemHeight: 18,
                                      itemTextColor: this.props.coresLetraPadrao,
                                      symbolSize: 18,
                                      symbolShape: 'circle',
                                      effects: [
                                          {
                                              on: 'hover',
                                              style: {
                                                  itemTextColor: this.props.coresLetraSecundario
                                              }
                                          }
                                      ]
                                  }
                              ]} */
                            />

                        </SubContainerGrafico>
                        <SubContainerGrafico backgroundDinamico={this.props.backgroundSegundo}>
                            <ContainerTextGrafico>
                                <div style={{ width: 80, height: 30 }}></div>
                                <TextGrafico colorDinamico={this.props.coresLetraPadrao}>Novas Ativações/Cancelamentos</TextGrafico>
                                <ContainerIconesExport>

                                    <IconeExport onClick={() => {
                                        this.props.exportaCrmParaExcel(this.props.planosComMaisAssinadosParaBaixar, 'ativacoes')
                                    }} colorDinamico="#32CD32" className="icon-spreadsheet" />

                                </ContainerIconesExport>
                            </ContainerTextGrafico>


                            <ResponsivePie
                                data={this.props.graficoNovasAtivacoes}
                                margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                                innerRadius={0.5}
                                padAngle={0.7}
                                cornerRadius={3}
                                colors={d => d.color}
                                borderWidth={1}
                                borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                                radialLabelsSkipAngle={10}
                                radialLabelsTextXOffset={6}
                                radialLabelsTextColor={this.props.coresLetraPadrao}
                                radialLabelsLinkOffset={0}
                                radialLabelsLinkDiagonalLength={16}
                                radialLabelsLinkHorizontalLength={24}
                                radialLabelsLinkStrokeWidth={1}
                                radialLabelsLinkColor={{ from: 'color' }}
                                slicesLabelsSkipAngle={10}
                                slicesLabelsTextColor="#000"
                                animate={true}
                                motionStiffness={90}
                                motionDamping={15}
                                tooltip={(e) => {
                                    if (e.value == 0.5) {
                                        return (
                                            <div style={{ height: 30, background: '#FFF', padding: 5, borderRadius: 5, flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center' }} key={e.id}>

                                                <div style={{ width: 13, height: 13, backgroundColor: e.color, marginRight: 5, marginLeft: 5 }}></div>
                                                <div>

                                                    <strong style={{ color: '#000' }}>
                                                        {`${e.id}: ${e.valorZerado}`}
                                                    </strong>
                                                </div>
                                            </div>

                                        );
                                    } else {
                                        return (
                                            <div style={{ height: 30, background: '#FFF', padding: 5, borderRadius: 5, flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center' }} key={e.id}>

                                                <div style={{ width: 13, height: 13, backgroundColor: e.color, marginRight: 5, marginLeft: 5 }}></div>
                                                <div>

                                                    <strong style={{ color: '#000' }}>
                                                        {`${e.id}: ${e.value}`}
                                                    </strong>
                                                </div>
                                            </div>

                                        );
                                    }
                                }}
                                sliceLabel={(d) => {
                                    if (d.value == 0.5) {
                                        return d.valorZerado
                                    } else {
                                        return d.value
                                    }
                                }}

                            /*   legends={[
                                  {
                                      anchor: 'bottom',
                                      direction: 'row',
                                      translateY: 56,
                                      itemWidth: 100,
                                      itemHeight: 18,
                                      itemTextColor: this.props.coresLetraPadrao,
                                      symbolSize: 18,
                                      symbolShape: 'circle',
                                      effects: [
                                          {
                                              on: 'hover',
                                              style: {
                                                  itemTextColor: this.props.coresLetraSecundario
                                              }
                                          }
                                      ]
                                  }
                              ]} */
                            />

                        </SubContainerGrafico>

                    </ContainerGrafico>
                    <ContainerGrafico >
                        <SubContainerGrafico backgroundDinamico={this.props.backgroundSegundo}>
                            <ContainerTextGrafico>
                                <div style={{ width: 80, height: 30 }}></div>
                                <TextGrafico colorDinamico={this.props.coresLetraPadrao}>Plano Mensal/Anual</TextGrafico>
                                <ContainerIconesExport>

                                    {/*   <IconeExport onClick={() => {
                                        this.props.exportaAtendimentosParaExcelPdf(this.props.arrayChamadosDetalhadosParaDownload, 'local', 'EXCEL')
                                    }} colorDinamico="#32CD32" className="icon-spreadsheet" /> */}

                                </ContainerIconesExport>
                            </ContainerTextGrafico>


                            <ResponsivePie
                                data={this.props.graficoTipoPlanoMensal}
                                margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                                innerRadius={0.5}
                                padAngle={0.7}
                                cornerRadius={3}
                                colors={d => d.color}
                                borderWidth={1}
                                borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                                radialLabelsSkipAngle={10}
                                radialLabelsTextXOffset={6}
                                radialLabelsTextColor={this.props.coresLetraPadrao}
                                radialLabelsLinkOffset={0}
                                radialLabelsLinkDiagonalLength={16}
                                radialLabelsLinkHorizontalLength={24}
                                radialLabelsLinkStrokeWidth={1}
                                radialLabelsLinkColor={{ from: 'color' }}
                                slicesLabelsSkipAngle={10}
                                slicesLabelsTextColor="#000"
                                animate={true}
                                motionStiffness={90}
                                motionDamping={15}
                                tooltip={(e) => {
                                    if (e.value == 0.5) {
                                        return (
                                            <div style={{ height: 30, background: '#FFF', padding: 5, borderRadius: 5, flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center' }} key={e.id}>

                                                <div style={{ width: 13, height: 13, backgroundColor: e.color, marginRight: 5, marginLeft: 5 }}></div>
                                                <div>

                                                    <strong style={{ color: '#000' }}>
                                                        {`${e.id}: ${e.valorZerado}`}
                                                    </strong>
                                                </div>
                                            </div>

                                        );
                                    } else {
                                        return (
                                            <div style={{ height: 30, background: '#FFF', padding: 5, borderRadius: 5, flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center' }} key={e.id}>

                                                <div style={{ width: 13, height: 13, backgroundColor: e.color, marginRight: 5, marginLeft: 5 }}></div>
                                                <div>

                                                    <strong style={{ color: '#000' }}>
                                                        {`${e.id}: ${e.value}`}
                                                    </strong>
                                                </div>
                                            </div>

                                        );
                                    }
                                }}
                                sliceLabel={(d) => {
                                    if (d.value == 0.5) {
                                        return d.valorZerado
                                    } else {
                                        return d.value
                                    }
                                }}

                            /* legends={[
                                {
                                    anchor: 'bottom',
                                    direction: 'row',
                                    translateY: 56,
                                    itemWidth: 100,
                                    itemHeight: 18,
                                    itemTextColor: this.props.coresLetraPadrao,
                                    symbolSize: 18,
                                    symbolShape: 'circle',
                                    effects: [
                                        {
                                            on: 'hover',
                                            style: {
                                                itemTextColor: this.props.coresLetraSecundario
                                            }
                                        }
                                    ]
                                }
                            ]} */
                            />

                        </SubContainerGrafico >
                        <SubContainerGrafico backgroundDinamico={this.props.backgroundSegundo}>
                            <ContainerTextGrafico>
                                <div style={{ width: 80, height: 30 }}></div>
                                <TextGrafico colorDinamico={this.props.coresLetraPadrao}>Forma de Pagamento</TextGrafico>
                                <ContainerIconesExport>

                                    {/*  <IconeExport onClick={() => {
                                        this.props.exportaAtendimentosParaExcelPdf(this.props.arrayChamadosDetalhadosParaDownload, 'solucao', 'EXCEL')
                                    }} colorDinamico="#32CD32" className="icon-spreadsheet" /> */}

                                </ContainerIconesExport>
                            </ContainerTextGrafico>

                            <ResponsivePie
                                data={this.props.graficoFormaPagamento}
                                margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                                innerRadius={0.5}
                                padAngle={0.7}
                                cornerRadius={3}
                                colors={d => d.color}
                                borderWidth={1}
                                borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                                radialLabelsSkipAngle={10}
                                radialLabelsTextXOffset={6}
                                radialLabelsTextColor={this.props.coresLetraPadrao}
                                radialLabelsLinkOffset={0}
                                radialLabelsLinkDiagonalLength={16}
                                radialLabelsLinkHorizontalLength={24}
                                radialLabelsLinkStrokeWidth={1}
                                radialLabelsLinkColor={{ from: 'color' }}
                                slicesLabelsSkipAngle={10}
                                slicesLabelsTextColor="#000"
                                animate={true}
                                motionStiffness={90}
                                motionDamping={15}
                                tooltip={(e) => {
                                    if (e.value == 0.5) {
                                        return (
                                            <div style={{ height: 30, background: '#FFF', padding: 5, borderRadius: 5, flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center' }} key={e.id}>

                                                <div style={{ width: 13, height: 13, backgroundColor: e.color, marginRight: 5, marginLeft: 5 }}></div>
                                                <div>

                                                    <strong style={{ color: '#000' }}>
                                                        {`${e.id}: ${e.valorZerado}`}
                                                    </strong>
                                                </div>
                                            </div>

                                        );
                                    } else {
                                        return (
                                            <div style={{ height: 30, background: '#FFF', padding: 5, borderRadius: 5, flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center' }} key={e.id}>

                                                <div style={{ width: 13, height: 13, backgroundColor: e.color, marginRight: 5, marginLeft: 5 }}></div>
                                                <div>

                                                    <strong style={{ color: '#000' }}>
                                                        {`${e.id}: ${e.value}`}
                                                    </strong>
                                                </div>
                                            </div>

                                        );
                                    }
                                }}
                                sliceLabel={(d) => {
                                    if (d.value == 0.5) {
                                        return d.valorZerado
                                    } else {
                                        return d.value
                                    }
                                }}
                            /*   legends={[
                                  {
                                      anchor: 'bottom',
                                      direction: 'row',
                                      translateY: 56,
                                      itemWidth: 100,
                                      itemHeight: 18,
                                      itemTextColor: this.props.coresLetraPadrao,
                                      symbolSize: 18,
                                      symbolShape: 'circle',
                                      effects: [
                                          {
                                              on: 'hover',
                                              style: {
                                                  itemTextColor: this.props.coresLetraSecundario
                                              }
                                          }
                                      ]
                                  }
                              ]} */
                            />

                        </SubContainerGrafico>
                    </ContainerGrafico>



                    <ContainerGrafico >
                        <SubContainerGrafico backgroundDinamico={this.props.backgroundSegundo}>
                            <ContainerTextGrafico>
                                <div style={{ width: 80, height: 30 }}></div>
                                <TextGrafico colorDinamico={this.props.coresLetraPadrao}>Assinado por Web/App</TextGrafico>
                                <ContainerIconesExport>

                                    {/*  <IconeExport onClick={() => {
                                        this.props.exportaAtendimentosParaExcelPdf(this.props.arrayChamadosDetalhadosParaDownload, 'local', 'EXCEL')
                                    }} colorDinamico="#32CD32" className="icon-spreadsheet" /> */}

                                </ContainerIconesExport>
                            </ContainerTextGrafico>


                            <ResponsivePie
                                data={this.props.graficoAssinadoViaWebApp}
                                margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                                innerRadius={0.5}
                                padAngle={0.7}
                                cornerRadius={3}
                                colors={d => d.color}
                                borderWidth={1}
                                borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                                radialLabelsSkipAngle={10}
                                radialLabelsTextXOffset={6}
                                radialLabelsTextColor={this.props.coresLetraPadrao}
                                radialLabelsLinkOffset={0}
                                radialLabelsLinkDiagonalLength={16}
                                radialLabelsLinkHorizontalLength={24}
                                radialLabelsLinkStrokeWidth={1}
                                radialLabelsLinkColor={{ from: 'color' }}
                                slicesLabelsSkipAngle={10}
                                slicesLabelsTextColor="#000"
                                animate={true}
                                motionStiffness={90}
                                motionDamping={15}
                                tooltip={(e) => {
                                    if (e.value == 0.5) {
                                        return (
                                            <div style={{ height: 30, background: '#FFF', padding: 5, borderRadius: 5, flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center' }} key={e.id}>

                                                <div style={{ width: 13, height: 13, backgroundColor: e.color, marginRight: 5, marginLeft: 5 }}></div>
                                                <div>

                                                    <strong style={{ color: '#000' }}>
                                                        {`${e.id}: ${e.valorZerado}`}
                                                    </strong>
                                                </div>
                                            </div>

                                        );
                                    } else {
                                        return (
                                            <div style={{ height: 30, background: '#FFF', padding: 5, borderRadius: 5, flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center' }} key={e.id}>

                                                <div style={{ width: 13, height: 13, backgroundColor: e.color, marginRight: 5, marginLeft: 5 }}></div>
                                                <div>

                                                    <strong style={{ color: '#000' }}>
                                                        {`${e.id}: ${e.value}`}
                                                    </strong>
                                                </div>
                                            </div>

                                        );
                                    }
                                }}
                                sliceLabel={(d) => {
                                    if (d.value == 0.5) {
                                        return d.valorZerado
                                    } else {
                                        return d.value
                                    }
                                }}

                            /* legends={[
                                {
                                    anchor: 'bottom',
                                    direction: 'row',
                                    translateY: 56,
                                    itemWidth: 100,
                                    itemHeight: 18,
                                    itemTextColor: this.props.coresLetraPadrao,
                                    symbolSize: 18,
                                    symbolShape: 'circle',
                                    effects: [
                                        {
                                            on: 'hover',
                                            style: {
                                                itemTextColor: this.props.coresLetraSecundario
                                            }
                                        }
                                    ]
                                }
                            ]} */
                            />

                        </SubContainerGrafico >
                        <SubContainerGrafico backgroundDinamico={this.props.backgroundSegundo}>
                            <ContainerTextGrafico>
                                <div style={{ width: 80, height: 30 }}></div>
                                <TextGrafico colorDinamico={this.props.coresLetraPadrao}>Planos com mais assinantes</TextGrafico>
                                <ContainerIconesExport>

                                    <IconeExport onClick={() => {
                                        this.props.exportaCrmParaExcel(this.props.planosComMaisAssinadosParaBaixar, 'planosmaisassinados')
                                    }} colorDinamico="#32CD32" className="icon-spreadsheet" />

                                </ContainerIconesExport>
                            </ContainerTextGrafico>

                            <ResponsiveLine
                                data={this.props.planosMaisAssinados}
                                margin={{ top: 10, right: 110, bottom: 70, left: 60 }}
                                xScale={{ type: 'point' }}
                                yScale={{ type: 'linear', stacked: true, reverse: false }}
                                axisTop={null}
                                axisRight={null}

                                // enableArea
                                curve="catmullRom"
                                colors={this.props.corBotaoPadrao}
                                pointSize={9}
                                pointColor={{ from: 'color', modifiers: [] }}
                                pointBorderWidth={2}
                                pointBorderColor={{ from: 'serieColor' }}
                                pointLabel="y"
                                pointLabelYOffset={-12}
                                sliceTooltip={({ slice }) => {
                                    return (
                                        <div>
                                            {slice.points.map(point => (

                                                <div style={{ background: this.props.corBotaoPadrao, padding: 5, borderRadius: 5 }} key={point.id}>
                                                    <strong style={{ color: this.props.letraBotaoPadrao }}>
                                                        {`${point.data.xFormatted} - ${point.data.yFormatted}`}
                                                    </strong>
                                                </div>
                                            ))}
                                        </div>
                                    );
                                }}
                                enableSlices="x"
                                theme={{
                                    axis: {
                                        ticks: {
                                            line: {
                                                stroke: this.props.coresLetraPadrao
                                            },
                                            text: {
                                                fill: this.props.coresLetraPadrao
                                            }
                                        }
                                    },
                                    grid: {
                                        line: {
                                            stroke: this.props.coresLetraSecundario,
                                            strokeWidth: 2,
                                            strokeDasharray: "4 4"
                                        }
                                    }
                                }}
                                useMesh={true}
                            /*   legends={[
                                  {
                                      anchor: 'bottom-right',
                                      direction: 'column',
                                      justify: false,
                                      translateX: 100,
                                      translateY: 0,
                                      itemsSpacing: 0,
                                      itemDirection: 'left-to-right',
                                      itemWidth: 80,
                                      itemHeight: 20,
                                      itemOpacity: 0.75,
                                      symbolSize: 12,
                                      symbolShape: 'circle',
                                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                                      effects: [
                                          {
                                              on: 'hover',
                                              style: {
                                                  itemBackground: 'rgba(0, 0, 0, .03)',
                                                  itemOpacity: 1
                                              }
                                          }
                                      ]
                                  }
                              ]} */
                            />

                        </SubContainerGrafico>
                    </ContainerGrafico>

                    <ContainerGrafico>
                        <SubContainerGrafico backgroundDinamico={this.props.backgroundSegundo}>
                            <ContainerTextGrafico>
                                <div style={{ width: 80, height: 30 }}></div>
                                <TextGrafico colorDinamico={this.props.coresLetraPadrao}>Estados com mais Assinantes</TextGrafico>
                                <ContainerIconesExport>

                                    <IconeExport onClick={() => {
                                        this.props.exportaCrmParaExcel(this.props.planosComMaisAssinadosParaBaixar, 'estados')
                                    }} colorDinamico="#32CD32" className="icon-spreadsheet" />

                                </ContainerIconesExport>
                            </ContainerTextGrafico>

                            <ResponsiveLine
                                data={this.props.estadosComMaisAssinantes}
                                margin={{ top: 10, right: 110, bottom: 70, left: 60 }}
                                xScale={{ type: 'point' }}
                                yScale={{ type: 'linear', stacked: true, reverse: false }}
                                axisTop={null}
                                axisRight={null}

                                // enableArea
                                curve="catmullRom"
                                colors={this.props.corBotaoPadrao}
                                pointSize={9}
                                pointColor={{ from: 'color', modifiers: [] }}
                                pointBorderWidth={2}
                                pointBorderColor={{ from: 'serieColor' }}
                                pointLabel="y"
                                pointLabelYOffset={-12}
                                sliceTooltip={({ slice }) => {
                                    return (
                                        <div>
                                            {slice.points.map(point => (

                                                <div style={{ background: this.props.corBotaoPadrao, padding: 5, borderRadius: 5 }} key={point.id}>
                                                    <strong style={{ color: this.props.letraBotaoPadrao }}>
                                                        {`${point.data.xFormatted} - ${point.data.yFormatted}`}
                                                    </strong>
                                                </div>
                                            ))}
                                        </div>
                                    );
                                }}
                                enableSlices="x"
                                theme={{
                                    axis: {
                                        ticks: {
                                            line: {
                                                stroke: this.props.coresLetraPadrao
                                            },
                                            text: {
                                                fill: this.props.coresLetraPadrao
                                            }
                                        }
                                    },
                                    grid: {
                                        line: {
                                            stroke: this.props.coresLetraSecundario,
                                            strokeWidth: 2,
                                            strokeDasharray: "4 4"
                                        }
                                    }
                                }}
                                useMesh={true}
                            /*   legends={[
                                  {
                                      anchor: 'bottom-right',
                                      direction: 'column',
                                      justify: false,
                                      translateX: 100,
                                      translateY: 0,
                                      itemsSpacing: 0,
                                      itemDirection: 'left-to-right',
                                      itemWidth: 80,
                                      itemHeight: 20,
                                      itemOpacity: 0.75,
                                      symbolSize: 12,
                                      symbolShape: 'circle',
                                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                                      effects: [
                                          {
                                              on: 'hover',
                                              style: {
                                                  itemBackground: 'rgba(0, 0, 0, .03)',
                                                  itemOpacity: 1
                                              }
                                          }
                                      ]
                                  }
                              ]} */
                            />

                        </SubContainerGrafico>
                        <SubContainerGrafico backgroundDinamico={this.props.backgroundSegundo}>
                            <ContainerTextGrafico>
                                <div style={{ width: 80, height: 30 }}></div>
                                <TextGrafico colorDinamico={this.props.coresLetraPadrao}>Segmentos com mais Assinantes</TextGrafico>
                                <ContainerIconesExport>

                                    <IconeExport onClick={() => {
                                        this.props.exportaCrmParaExcel(this.props.planosComMaisAssinadosParaBaixar, 'segmentos')
                                    }} colorDinamico="#32CD32" className="icon-spreadsheet" />

                                </ContainerIconesExport>
                            </ContainerTextGrafico>


                            <ResponsiveLine
                                data={this.props.segmentosComMaisAssinantes}
                                margin={{ top: 10, right: 110, bottom: 70, left: 60 }}
                                xScale={{ type: 'point' }}
                                yScale={{ type: 'linear', stacked: true, reverse: false }}
                                axisTop={null}
                                axisRight={null}

                                // enableArea
                                curve="catmullRom"
                                colors={this.props.corBotaoPadrao}
                                pointSize={9}
                                pointColor={{ from: 'color', modifiers: [] }}
                                pointBorderWidth={2}
                                pointBorderColor={{ from: 'serieColor' }}
                                pointLabel="y"
                                pointLabelYOffset={-12}
                                sliceTooltip={({ slice }) => {
                                    return (
                                        <div>
                                            {slice.points.map(point => (
                                                <div style={{ background: this.props.corBotaoPadrao, padding: 5, borderRadius: 5 }} key={point.id}>
                                                    <strong style={{ color: this.props.letraBotaoPadrao }}>
                                                        {`${point.data.xFormatted} - ${point.data.yFormatted}`}
                                                    </strong>
                                                </div>
                                            ))}
                                        </div>
                                    );
                                }}
                                enableSlices="x"
                                theme={{
                                    axis: {
                                        ticks: {
                                            line: {
                                                stroke: this.props.coresLetraPadrao
                                            },
                                            text: {
                                                fill: this.props.coresLetraPadrao
                                            }
                                        }
                                    },
                                    grid: {
                                        line: {
                                            stroke: this.props.coresLetraSecundario,
                                            strokeWidth: 2,
                                            strokeDasharray: "4 4"
                                        }
                                    }
                                }}
                                useMesh={true}
                            /*   legends={[
                                  {
                                      anchor: 'bottom-right',
                                      direction: 'column',
                                      justify: false,
                                      translateX: 100,
                                      translateY: 0,
                                      itemsSpacing: 0,
                                      itemDirection: 'left-to-right',
                                      itemWidth: 80,
                                      itemHeight: 20,
                                      itemOpacity: 0.75,
                                      symbolSize: 12,
                                      symbolShape: 'circle',
                                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                                      effects: [
                                          {
                                              on: 'hover',
                                              style: {
                                                  itemBackground: 'rgba(0, 0, 0, .03)',
                                                  itemOpacity: 1
                                              }
                                          }
                                      ]
                                  }
                              ]} */
                            />

                        </SubContainerGrafico>

                    </ContainerGrafico>

                    <ContainerGrafico>
                        <SubContainerGrafico backgroundDinamico={this.props.backgroundSegundo}>
                            <ContainerTextGrafico>
                                <div style={{ width: 80, height: 30 }}></div>
                                <TextGrafico colorDinamico={this.props.coresLetraPadrao}>Modo Conversão Clientes</TextGrafico>
                                <ContainerIconesExport>

                                    <IconeExport onClick={() => {
                                        this.props.exportaCrmParaExcel(this.props.planosComMaisAssinadosParaBaixar, 'clientes')
                                    }} colorDinamico="#32CD32" className="icon-spreadsheet" />

                                </ContainerIconesExport>
                            </ContainerTextGrafico>


                            <ResponsivePie
                                data={this.props.graficoClienteViaAnuncio}
                                margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                                innerRadius={0.5}
                                padAngle={0.7}
                                cornerRadius={3}
                                colors={d => d.color}
                                borderWidth={1}
                                borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                                radialLabelsSkipAngle={10}
                                radialLabelsTextXOffset={6}
                                radialLabelsTextColor={this.props.coresLetraPadrao}
                                radialLabelsLinkOffset={0}
                                radialLabelsLinkDiagonalLength={16}
                                radialLabelsLinkHorizontalLength={24}
                                radialLabelsLinkStrokeWidth={1}
                                radialLabelsLinkColor={{ from: 'color' }}
                                slicesLabelsSkipAngle={10}
                                slicesLabelsTextColor="#000"
                                animate={true}
                                motionStiffness={90}
                                motionDamping={15}
                                tooltip={(e) => {
                                    if (e.value == 0.5) {
                                        return (
                                            <div style={{ height: 30, background: '#FFF', padding: 5, borderRadius: 5, flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center' }} key={e.id}>

                                                <div style={{ width: 13, height: 13, backgroundColor: e.color, marginRight: 5, marginLeft: 5 }}></div>
                                                <div>

                                                    <strong style={{ color: '#000' }}>
                                                        {`${e.id}: ${e.valorZerado}`}
                                                    </strong>
                                                </div>
                                            </div>

                                        );
                                    } else {
                                        return (
                                            <div style={{ height: 30, background: '#FFF', padding: 5, borderRadius: 5, flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center' }} key={e.id}>

                                                <div style={{ width: 13, height: 13, backgroundColor: e.color, marginRight: 5, marginLeft: 5 }}></div>
                                                <div>

                                                    <strong style={{ color: '#000' }}>
                                                        {`${e.id}: ${e.value}`}
                                                    </strong>
                                                </div>
                                            </div>

                                        );
                                    }
                                }}
                                sliceLabel={(d) => {
                                    if (d.value == 0.5) {
                                        return d.valorZerado
                                    } else {
                                        return d.value
                                    }
                                }}

                            /*   legends={[
                                  {
                                      anchor: 'bottom',
                                      direction: 'row',
                                      translateY: 56,
                                      itemWidth: 100,
                                      itemHeight: 18,
                                      itemTextColor: this.props.coresLetraPadrao,
                                      symbolSize: 18,
                                      symbolShape: 'circle',
                                      effects: [
                                          {
                                              on: 'hover',
                                              style: {
                                                  itemTextColor: this.props.coresLetraSecundario
                                              }
                                          }
                                      ]
                                  }
                              ]} */
                            />

                        </SubContainerGrafico>
                        <SubContainerGrafico backgroundDinamico={this.props.backgroundSegundo}>
                            <ContainerTextGrafico>
                                <div style={{ width: 80, height: 30 }}></div>
                                <TextGrafico colorDinamico={this.props.coresLetraPadrao}>Segmentos com mais Cancelamentos</TextGrafico>
                                <ContainerIconesExport>

                                    <IconeExport onClick={() => {
                                        this.props.exportaCrmParaExcel(this.props.planosComMaisAssinadosParaBaixar, 'segmentosCancelados')
                                    }} colorDinamico="#32CD32" className="icon-spreadsheet" />

                                </ContainerIconesExport>
                            </ContainerTextGrafico>


                            <ResponsiveLine
                                data={this.props.segmentosComMaisCancelamentos}
                                margin={{ top: 10, right: 110, bottom: 70, left: 60 }}
                                xScale={{ type: 'point' }}
                                yScale={{ type: 'linear', stacked: true, reverse: false }}
                                axisTop={null}
                                axisRight={null}

                                // enableArea
                                curve="catmullRom"
                                colors={this.props.corBotaoPadrao}
                                pointSize={9}
                                pointColor={{ from: 'color', modifiers: [] }}
                                pointBorderWidth={2}
                                pointBorderColor={{ from: 'serieColor' }}
                                pointLabel="y"
                                pointLabelYOffset={-12}
                                sliceTooltip={({ slice }) => {
                                    return (
                                        <div>
                                            {slice.points.map(point => (
                                                <div style={{ background: this.props.corBotaoPadrao, padding: 5, borderRadius: 5 }} key={point.id}>
                                                    <strong style={{ color: this.props.letraBotaoPadrao }}>
                                                        {`${point.data.xFormatted} - ${point.data.yFormatted}`}
                                                    </strong>
                                                </div>
                                            ))}
                                        </div>
                                    );
                                }}
                                enableSlices="x"
                                theme={{
                                    axis: {
                                        ticks: {
                                            line: {
                                                stroke: this.props.coresLetraPadrao
                                            },
                                            text: {
                                                fill: this.props.coresLetraPadrao
                                            }
                                        }
                                    },
                                    grid: {
                                        line: {
                                            stroke: this.props.coresLetraSecundario,
                                            strokeWidth: 2,
                                            strokeDasharray: "4 4"
                                        }
                                    }
                                }}
                                useMesh={true}
                            /*   legends={[
                                  {
                                      anchor: 'bottom-right',
                                      direction: 'column',
                                      justify: false,
                                      translateX: 100,
                                      translateY: 0,
                                      itemsSpacing: 0,
                                      itemDirection: 'left-to-right',
                                      itemWidth: 80,
                                      itemHeight: 20,
                                      itemOpacity: 0.75,
                                      symbolSize: 12,
                                      symbolShape: 'circle',
                                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                                      effects: [
                                          {
                                              on: 'hover',
                                              style: {
                                                  itemBackground: 'rgba(0, 0, 0, .03)',
                                                  itemOpacity: 1
                                              }
                                          }
                                      ]
                                  }
                              ]} */
                            />

                        </SubContainerGrafico>
                    </ContainerGrafico>
                </Container >
            )
        }
    }
    render() {

        return (
            <>
                {this.renderLoadingRelatorioDetalhado()}
                <Modal focusTrapped={false} showCloseIcon={false} open={this.state.modalSenhaAcesso} onClose={() => { }} center>
                    <ContainerModalPrioridadeAtendimento backgroundDinamico={this.props.backgroundPrimeiro}>
                        <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                            <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Senha de Acesso</TextNovoAtendimento>
                            <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => { this.setState({ modalSenhaAcesso: false }) }} className="icon-close" />
                        </ContainerIconeFecharModal>
                        <ContainerAtendentes>
                            <ContainerInputEBotaoComent>
                                <DivInputComentarChamado >
                                    <p style={{ color: this.props.coresLetraPadrao }}>Senha:</p>
                                    <InputDescricaoChamado
                                        autoFocus
                                        type="textarea"
                                        onChange={(value) => { this.modificaSenhAcesso(value.target.value) }}
                                        corLetraDinamico={this.props.coresLetraPadrao}
                                        value={this.state.senhaAcesso}
                                        corLetraSecundaria={this.props.coresLetraSecundario}
                                        corBordaDinamica={this.props.corBotaoPadrao}
                                        placeholder="Digite a senha"
                                    />
                                </DivInputComentarChamado>
                            </ContainerInputEBotaoComent>
                            <DivBotaoSalvarComentario>

                                <ButtonSalvarComentario onClick={() => {
                                    if (this.state.senhaAcesso === this.props.senhaAcessoCRM) {
                                        let dataAtual = moment()
                                        let dataFinal = moment(dataAtual).subtract(31, 'days')
                                        
                                        
                                        
                                        
                                
                                        this.props.listaRelatoriosCRM(dataFinal, dataAtual)
                                        this.props.modificaDataInicialFinalRelatorioCRM(dataFinal, dataAtual)
                                        this.props.modificaNomeRotaNoHeader('Relatório CRM DropDesk')
                                        this.setState({ modalSenhaAcesso: false })
                                    } else {
                                        toastr.error('Acesso Negado')
                                        this.setState({ numeroTentativa: this.state.numeroTentativa + 1 })
                                    }
                                }}
                                    backgroundBotao={this.props.corBotaoPadrao}>
                                    <TextBotaoSalvarComentar backgroundBotao={this.props.letraBotaoPadrao}>Entrar</TextBotaoSalvarComentar>
                                </ButtonSalvarComentario>

                            </DivBotaoSalvarComentario>
                        </ContainerAtendentes>
                        {/*   <ContainerTextCabecalhoModalAcoes>
                            <TextCabecalhoModalAcoes corLetraDinamico={this.props.corBotaoPadrao}>Selecione o Status do Atendimento:</TextCabecalhoModalAcoes>
                        </ContainerTextCabecalhoModalAcoes>
                        <ContainerAtendentes>
                            <DivAtendentes >
                                <TextAtendentes onClick={() => { this.props.modificaVisibilidadeModalFinalizarAtendimento(false); this.props.atribuiStatusAtendimento(this.props.keyAtendimento, 'Fechado', this.props.dadosAtendimentos[0].dataAbertura, this.props.premium, this.props.dadosAtendimentos[0].keyAtendente, this.props.dadosAtendimentos[0]); }} corLetraBotaoPadrao={this.props.corBotaoPadrao} corLetraDinamico={this.props.coresLetraPadrao}>Fechado</TextAtendentes>
                            </DivAtendentes>
                            <DivAtendentes >
                                <TextAtendentes onClick={() => { this.props.modificaVisibilidadeModalFinalizarAtendimento(false); this.props.atribuiStatusAtendimento(this.props.keyAtendimento, 'Aguardando Cliente', this.props.dadosAtendimentos[0].dataAbertura, this.props.premium, this.props.dadosAtendimentos[0].keyAtendente, this.props.dadosAtendimentos[0]); }} corLetraBotaoPadrao={this.props.corBotaoPadrao} corLetraDinamico={this.props.coresLetraPadrao}>Aguardando o Cliente responder</TextAtendentes>
                            </DivAtendentes>
                        </ContainerAtendentes> */}
                    </ContainerModalPrioridadeAtendimento>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = state => {
    return (
        {

            backgroundPrimeiro: state.CoresPadraoSistema.backgroundPrimeiro,
            backgroundSegundo: state.CoresPadraoSistema.backgroundSegundo,
            backgroundMaisEscuro: state.CoresPadraoSistema.backgroundMaisEscuro,
            coresLetraPadrao: state.CoresPadraoSistema.coresLetraPadrao,
            corBotaoPadrao: state.CoresPadraoSistema.corBotaoPadrao,
            corSegundoBotaoPadrao: state.CoresPadraoSistema.corSegundoBotaoPadrao,
            coresLetraSecundario: state.CoresPadraoSistema.coresLetraSecundario,
            corLetraDescricaoDosCampos: state.CoresPadraoSistema.corLetraDescricaoDosCampos,
            nomeRota: state.HeaderReducer.nomeRota,
            corBordaPadrao: state.CoresPadraoSistema.corBordaPadrao,
            corBordaDinamica: state.CoresPadraoSistema.corBordaDinamica,


            loadingRelatorioCRM: state.DashBoardReducer.loadingRelatorioCRM,
            loadingSemInternetRelatorioDetalhado: state.DashBoardReducer.loadingSemInternetRelatorioDetalhado,
            loadingRefreshControlRelatorioDetalhado: state.DashBoardReducer.loadingRefreshControlRelatorioDetalhado,
            periodoPersonalizadoRelatorioDetalhado: state.DashBoardReducer.periodoPersonalizadoRelatorioDetalhado,

            atendimentosAEFC: state.DashBoardReducer.atendimentosAEFC,
            totalAtendimentosAEFC: state.DashBoardReducer.totalAtendimentosAEFC,
            atendimentosPorPrioridadeRelatorioDetalhado: state.DashBoardReducer.atendimentosPorPrioridadeRelatorioDetalhado,
            totalAtendimentosPorPrioridadeRelatorioDetalhado: state.DashBoardReducer.totalAtendimentosPorPrioridadeRelatorioDetalhado,


            atendimentosPorTempoSolucaoRelatorioDetalhado: state.DashBoardReducer.atendimentosPorTempoSolucaoRelatorioDetalhado,
            totalAtendimentosPorTempoSolucaoRelatorioDetalhado: state.DashBoardReducer.totalAtendimentosPorTempoSolucaoRelatorioDetalhado,
            atendimentosInternoExternoRelatorioDetalhado: state.DashBoardReducer.atendimentosInternoExternoRelatorioDetalhado,
            totalAtendimentosInternoExternoRelatorioDetalhado: state.DashBoardReducer.totalAtendimentosInternoExternoRelatorioDetalhado,
            filtroRelatorioDetalhado: state.DashBoardReducer.filtroRelatorioDetalhado,


            clientesRelatorioAtendimentoDetalhado: state.ChamadoReducer.clientesSectionedConsulta,
            clienteSelecionadoRelatorioAtendimentoDetalhado: state.DashBoardReducer.clienteSelecionadoRelatorioAtendimentoDetalhado,
            atendentesRelatorioAtendimentoDetalhado: state.AtendenteReducer.atendentesSectionedConsulta,
            atendentesSelecionadoRelatorioAtendimentoDetalhado: state.DashBoardReducer.atendentesSelecionadoRelatorioAtendimentoDetalhado,
            SetoresRelatorioAtendimentoDetalhado:state.SetorReducer.setoresSectionedConsulta,
            SetoresSelecionadoRelatorioAtendimentoDetalhado: state.DashBoardReducer.SetoresSelecionadoRelatorioAtendimentoDetalhado,

            loadingClientesRelatorioAtendimentoDetalhado: state.DashBoardReducer.loadingClientesRelatorioAtendimentoDetalhado,
            loadingAtendentesRelatorioAtendimentoDetalhado: state.DashBoardReducer.loadingAtendentesRelatorioAtendimentoDetalhado,
            loadingSetoresRelatorioAtendimentoDetalhado: state.DashBoardReducer.loadingSetoresRelatorioAtendimentoDetalhado,

            filtroClienteSectioned: state.DashBoardReducer.filtroClienteSectioned,
            filtroAtendenteSectioned: state.DashBoardReducer.filtroAtendenteSectioned,
            filtroSetorSectioned: state.DashBoardReducer.filtroSetorSectioned,

            dataInicialConsultaFiltroRelatorioDetalhado: state.DashBoardReducer.dataInicialConsultaFiltroRelatorioDetalhado,
            dataFinalConsultaFiltroRelatorioDetalhado: state.DashBoardReducer.dataFinalConsultaFiltroRelatorioDetalhado,
            licensawebouapp: state.PremiumReducer.licensawebouapp,
            tempoMedioParaSolucaoAtendimento: state.DashBoardReducer.tempoMedioParaSolucaoAtendimento,

            modalInformacaoRelatorioDetalhado: state.DashBoardReducer.modalInformacaoRelatorioDetalhado,

            textCabecalhoModalInfoRelatorioDetalhado: state.DashBoardReducer.textCabecalhoModalInfoRelatorioDetalhado,
            text1ModalInfRelatorioDetalhado: state.DashBoardReducer.text1ModalInfRelatorioDetalhado,
            text2ModalInfRelatorioDetalhado: state.DashBoardReducer.text2ModalInfRelatorioDetalhado,
            premium: state.PremiumReducer.premium,
            arrayChamadosDetalhadosParaDownload: state.DashBoardReducer.arrayChamadosDetalhadosParaDownload,
            mensagemGerandoRelatorio: state.DashBoardReducer.mensagemGerandoRelatorio,
            permissoesAcessarTelasAtendente: state.AtendenteReducer.permissoesAcessarTelasAtendente,
            filtroSelecionadoRelatorioDetalhado: state.DashBoardReducer.filtroSelecionadoRelatorioDetalhado,
            tempoMedioPrimeiraResposta: state.DashBoardReducer.tempoMedioPrimeiraResposta,

            graficoClienteAtivosCancelados: state.DashBoardReducer.graficoClienteAtivosCancelados,
            graficoClienteViaAnuncio: state.DashBoardReducer.graficoClienteViaAnuncio,
            graficoTipoPlanoMensal: state.DashBoardReducer.graficoTipoPlanoMensal,
            graficoFormaPagamento: state.DashBoardReducer.graficoFormaPagamento,
            graficoAssinadoViaWebApp: state.DashBoardReducer.graficoAssinadoViaWebApp,

            graficoNovasAtivacoes: state.DashBoardReducer.graficoNovasAtivacoes,


            dataInicialConsultaRelatorioCRM: state.DashBoardReducer.dataInicialConsultaRelatorioCRM,
            dataFinalConsultaRelatorioCRM: state.DashBoardReducer.dataFinalConsultaRelatorioCRM,

            planosMaisAssinados: state.DashBoardReducer.planosMaisAssinados,
            estadosComMaisAssinantes: state.DashBoardReducer.estadosComMaisAssinantes,
            segmentosComMaisAssinantes: state.DashBoardReducer.segmentosComMaisAssinantes,
            segmentosComMaisCancelamentos: state.DashBoardReducer.segmentosComMaisCancelamentos,
            
            senhaAcessoCRM: state.DashBoardReducer.senhaAcessoCRM,

            planosComMaisAssinadosParaBaixar: state.DashBoardReducer.planosComMaisAssinadosParaBaixar,

        }
    );
};
export default connect(mapStateToProps, {
    modificaCorBordaListaTarefas, insereDataFiltroDashboard, modificaDataInicialFinalRelatorioAtendimentoDetalhado,
    modificaFiltroSelecionadoRelatorioDetalhado,
    modificaClienteTelaRelatorioDetalhado,
    modificaAtendenteRelatorioDetalhado, modificaSetorRelatorioDetalhado,
    modificaFiltroTelaRelatorioDetalhado, modificaFiltroClienteSectionedTelaRelatorioDetalhado, modificaFiltroAtendenteSectionedTelaRelatorioDetalhado,
    modificaFiltroSetorSectionedTelaRelatorioDetalhado, modificaVisibilidadeModalInformacaoRelatorioDetalhado,
    insereTextosInformacionaisModalRelatorioDetalhado, zeraMensagemGerandoRelatorio,
    modificaNomeRotaNoHeader, novalistaAtendimentosZeradoParaRelatorioDetalhado,
    listaAtendimentosPeriodoPersonalizado, listaAtendentesTelaConsultaAtendente,
    listaSetorSectionedTelaConsultaSetor, exportaAtendimentosParaExcelPdf, desligaOuvintesChamadosFirebase,
    listaRelatoriosCRM, modificaDataInicialFinalRelatorioCRM, exportaCrmParaExcel
})(withRouter(RelatorioCRM));
