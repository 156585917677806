import {
    MODIFICANOMEATENDENTE, MODIFICAEMAILACESSOAPP, MODIFICASENHAATENDENTE, MODIFICACONFIRMASENHAATENDENTE,
    INSEREFOTOCADASTROATENDENTE, ATIVALOADINGBOTAOCADASTRARATENDENTE, MENSAGEMCADASTRAATENDENTEERROR, MENSAGEMCADASTRAATENDENTESUCESSO,
    ZERAINFORMACOESCADASTROATENDENTE, MODIFICAVALORDIGITOCONSULTAATENDENTE, ENVIADADOSPARAATENDENTEREDUCER,
    ZERAMSGSUCESSOERRORATENDENTE, ALTERAFOTOATENDENTETELAALTERAR, ALTERANOMEATENDENTETELAALTERAR,
    ALTERAEMAILATENDENTETELAALTERAR, ALTERASENHAATENDENTETELAALTERAR, ALTERACONFIRMASENHAATENDENTETELAALTERAR,
    INSEREFOTOATENDENTETELAALTERARPICKER, MENSAGEMALTERAATENDENTESUCESSO, MENSAGEMCALTERAATENDENTEERRO,
    ATIVALOADINGBOTAOALTERARATENDENTE, ALTERAKEYATENDENTETELAALTERAR, SEMCONEXAOINTERNETCONSULTAATENDENTE,
    SEMCONEXAOINTERNETCADASTRAATENDENTE, SEMCONEXAOINTERNETALTERAATENDENTE, ATIVADESATIVALOADINGFIMDAPAGINATELACONSULTAATENDENTE,
    ATIVADESATIVALOADINGTELACONSULTAATENDENTE, ENVIAULTIMAREFERENCIAKEYATENDENTE, ENVIATENDENTESPARASECTIONED,
    ATIVADESATIVALOADINGSECTIONEDATENDENTE, MODIFICAATENDENTESELECIONADOSECTIONED, MODIFICANOMEATENDENTESELECIONADOSECTIONED,
    ATIVADESATIVALOADINGREFRESHCONTROLATENDENTE, DESATIVALOADINGBOTAOCADASTRARATENDENTE, MUDAVISIBILIDADEMODALCADASTRAATENDENTEVERSAOPREMIUM,
    ZERAMENSAGEMERRORTELACADASTROATENDENTE2,
    ALTERASWITCHPERMISSAOALTERARADMINISTRADOR, ALTERASWITCHPERMISSAOALTERARCLIENTE, ALTERASWITCHPERMISSAOALTERARATENDENTE,
    ALTERASWITCHPERMISSAOALTERARSETORES, ALTERASWITCHPERMISSAOALTERARAVISOS, ALTERASWITCHPERMISSAOACESSORELATORIOS,
    ALTERASWITCHPERMISSAOADMINISTRADORSOZINHO, ALTERAINFORMACAOCONTAPRINCIPALATENDENTE, ZERAMENSAGEMERRORAOALTERARATENDENTE,
    ENVIAPERMISSOESACESSARTELASATENDENTE, MUDAVISIBILIDADEMODALEXCLUIRATENDENTE, ATIVADESATIVALOADINGCONTEUDOEXCLUIRATENDENTE,
    ENVIAUIDATENDENTETELAALTERARATENDENTE, ENVIASEATENDENTEEHADMINISTRADOR,
    MODIFICAFILTROTELAATENDENTES, MODIFICAVISIBLEMODALNOVOATENDENTE, MODIFICAARQUIVOCOMOPLETOATENDENTEPARAUPAR,

    MODIFICANOMEATENDENTENOVOCADASTRO, MODIFICAEMAILACESSOAPPNOVOCADASTRO, MODIFICASENHAATENDENTENOVOCADASTRO,
    MODIFICACONFIRMASENHAATENDENTENOVOCADASTRO, ALTERASWITCHPERMISSAOALTERARADMINISTRADORNOVOCADASTRO,
    ALTERASWITCHPERMISSAOALTERARCLIENTENOVOCADASTRO, ALTERASWITCHPERMISSAOALTERARATENDENTENOVOCADASTRO,
    ALTERASWITCHPERMISSAOALTERARSETORESNOVOCADASTRO, ALTERASWITCHPERMISSAOALTERARAVISOSNOVOCADASTRO, ALTERASWITCHPERMISSAOACESSORELATORIOSNOVOCADASTRO,
    MODIFICAARQUIVOCOMOPLETOATENDENTEPARAUPARNOVOCADASTRO, MODIFICAPERMISSAOCANCELARATENDIMENTONOVOCADASTRO,
    ALTERASWITCHPERMISSAOCANCELARATENDIMENTO
} from '../actions/types';
const imagemPadraoAtendente = require('../imagens/userPadrao.png');


const INITIAL_STATE = {
    atendentes: [],
    inputDigitoConsultaAtendente: '',
    nomeAtendente: '',
    emailAtendente: '',
    senhaAtendente: '',
    confirmaSenhaAtendente: '',
    fotoImagemAtendente: null,
    loadingBotaoCadastrarAtendente: false,
    uriImagemPadraoTelaCadastroAtendente: imagemPadraoAtendente,
    mensagemerrorAoCadastrarAtendente: '',
    mensagemSucessoAoCadastrarAtendente: '',
    backgroundMensagemErrorTelaCadastraAtendente: null,
    backgroundMensagemErrorTelaAlterarAtendente: null,
    fotoImagemTelaAlterarAtendente: null,
    mensagemerrorAoAlterarAtendenteTelaAlterar: '',
    mensagemSucessoAoAlterarAtendenteTelaAlterar: '',
    loadingBotaoAlterarAtendente: false,
    keyAtendente: '',
    verificaConexaoInternetConsultaAtendente: '',
    verificaConexaoInternetCadastraAtendente: '',
    verificaConexaoInternetAlteraAtendente: '',
    loadingConsultaAtendente: true,
    loadingFimDAPaginaCOnsultaAtendente: false,
    referenciaUltimaKeyAtendente: '',
    loadingCarregarAtendentesSectionedConsultaCliente: false,
    atendentesSectionedConsulta: [],
    atendenteSelecionadosectioned: '',
    nomeAtendenteSelecinadoSectioned: '',
    refreshControlConsultaAtendente: false,
    modalCadastraAtendenteVersaoPremium: false,

    permissaoAdministrador: false,
    permissaoAlterarCadastrarCliente: false,
    permissaoAlterarCadastrarAtendente: false,
    permissaoAlterarCadastrarSetores: false,
    permissaoAlterarCadastrarAvisos: false,
    permissaoAcessoRelatorios: false,
    contaPrincipal: false,
    modalExcluirAtendente: false,
    loadingExcluirAtendente: false,
    uidAtendente: '',
    usuarioAdministrador: true,

    filtroAtendente: '',
    modalNovoAtendente: false,

    permissoesAcessarTelasAtendente: {
        key: '',
        contaPrincipal: false,
        permissaoAcessoRelatorios: false,
        permissaoAdministrador: false,
        permissaoAlterarCadastrarAtendente: false,
        permissaoAlterarCadastrarAvisos: false,
        permissaoAlterarCadastrarCliente: false,
        permissaoAlterarCadastrarSetores: false,
        permissaoCancelarAtendimento:true
    },
    arquivoCompletoAtendenteParaUpar: '',
    arquivoCompletoAtendenteParaUparNovoCadastro: '',

    permissaoAdministradorNovoCadastro: false,
    permissaoAlterarCadastrarClienteNovoCadastro: false,
    permissaoAlterarCadastrarAtendenteNovoCadastro: false,
    permissaoAlterarCadastrarSetoresNovoCadastro: false,
    permissaoAlterarCadastrarAvisosNovoCadastro: false,
    permissaoCancelarAtendimentoNovoCadastro: false,
    permissaoAcessoRelatoriosNovoCadastro: false,
    nomeAtendenteNovoCadastro: '',
    emailAtendenteNovoCadastro: '',
    senhaAtendenteNovoCadastro: '',
    confirmaSenhaAtendenteNovoCadastro: '',

    permissaoCancelarAtendimento: true
};
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MODIFICANOMEATENDENTE:
            return { ...state, nomeAtendente: action.payload };
        case MODIFICAEMAILACESSOAPP:
            return { ...state, emailAtendente: action.payload };
        case MODIFICASENHAATENDENTE:
            return { ...state, senhaAtendente: action.payload };
        case MODIFICACONFIRMASENHAATENDENTE:
            return { ...state, confirmaSenhaAtendente: action.payload };
        case INSEREFOTOCADASTROATENDENTE:
            return { ...state, uriImagemPadraoTelaCadastroAtendente: action.payload };
        case ATIVALOADINGBOTAOCADASTRARATENDENTE:
            return { ...state, loadingBotaoCadastrarAtendente: action.payload, mensagemerrorAoCadastrarAtendente: '', mensagemSucessoAoCadastrarAtendente: '', backgroundMensagemErrorTelaCadastraAtendente: null };
        case MENSAGEMCADASTRAATENDENTEERROR:
            return { ...state, mensagemerrorAoCadastrarAtendente: action.payload, backgroundMensagemErrorTelaCadastraAtendente: '#DC143C', loadingBotaoCadastrarAtendente: false, mensagemSucessoAoCadastrarAtendente: '' };
        case MENSAGEMCADASTRAATENDENTESUCESSO:
            return { ...state, mensagemSucessoAoCadastrarAtendente: action.payload, backgroundMensagemErrorTelaCadastraAtendente: '#00FF7F', loadingBotaoCadastrarAtendente: false, mensagemerrorAoCadastrarAtendente: '' };
        case ZERAINFORMACOESCADASTROATENDENTE:
            return {
                ...state, mensagemerrorAoCadastrarAtendente: '', nomeAtendenteNovoCadastro: '', emailAtendenteNovoCadastro: '', senhaAtendenteNovoCadastro: '', confirmaSenhaAtendenteNovoCadastro: '', uriImagemPadraoTelaCadastroAtendente: imagemPadraoAtendente,
                permissaoAdministradorNovoCadastro: false, permissaoAlterarCadastrarClienteNovoCadastro: false, permissaoAlterarCadastrarAtendenteNovoCadastro: false, permissaoAlterarCadastrarSetoresNovoCadastro: false, permissaoAlterarCadastrarAvisosNovoCadastro: false, permissaoAcessoRelatoriosNovoCadastro: false,
                permissaoCancelarAtendimentoNovoCadastro: false
            };
        case MODIFICAVALORDIGITOCONSULTAATENDENTE:
            return { ...state, inputDigitoConsultaAtendente: action.payload };
        case ENVIADADOSPARAATENDENTEREDUCER:
            return { ...state, atendentes: [...action.payload] };
        case ZERAMSGSUCESSOERRORATENDENTE:
            return {
                ...state, mensagemSucessoAoCadastrarAtendente: '', mensagemerrorAoCadastrarAtendente: '',
                backgroundMensagemErrorTelaCadastraAtendente: null, nomeAtendente: '', emailAtendente: '', senhaAtendente: '', confirmaSenhaAtendente: '', uriImagemPadraoTelaCadastroAtendente: imagemPadraoAtendente,
                permissaoAdministrador: false,
                permissaoAlterarCadastrarCliente: false,
                permissaoAlterarCadastrarAtendente: false,
                permissaoAlterarCadastrarSetores: false,
                permissaoAlterarCadastrarAvisos: false,
                permissaoAcessoRelatorios: false,
                contaPrincipal: false
            };
        case ALTERAFOTOATENDENTETELAALTERAR:
            return { ...state, fotoImagemTelaAlterarAtendente: action.payload };
        case ALTERANOMEATENDENTETELAALTERAR:
            return { ...state, nomeAtendente: action.payload };
        case ALTERAEMAILATENDENTETELAALTERAR:
            return { ...state, emailAtendente: action.payload };
        case ALTERASENHAATENDENTETELAALTERAR:
            return { ...state, senhaAtendente: action.payload };
        case ALTERACONFIRMASENHAATENDENTETELAALTERAR:
            return { ...state, confirmaSenhaAtendente: action.payload };
        case INSEREFOTOATENDENTETELAALTERARPICKER:
            return { ...state, fotoImagemTelaAlterarAtendente: action.payload }
        case MENSAGEMCALTERAATENDENTEERRO:
            return { ...state, mensagemerrorAoAlterarAtendenteTelaAlterar: action.payload, mensagemSucessoAoAlterarAtendenteTelaAlterar: '', loadingBotaoAlterarAtendente: false, backgroundMensagemErrorTelaAlterarAtendente: '#DC143C' }
        case MENSAGEMALTERAATENDENTESUCESSO:
            return { ...state, mensagemSucessoAoAlterarAtendenteTelaAlterar: action.payload, mensagemerrorAoAlterarAtendenteTelaAlterar: '', loadingBotaoAlterarAtendente: false }
        case ATIVALOADINGBOTAOALTERARATENDENTE:
            return { ...state, loadingBotaoAlterarAtendente: action.payload }
        case ALTERAKEYATENDENTETELAALTERAR:
            return { ...state, keyAtendente: action.payload }
        case SEMCONEXAOINTERNETCONSULTAATENDENTE:
            return { ...state, verificaConexaoInternetConsultaAtendente: action.payload }
        case SEMCONEXAOINTERNETCADASTRAATENDENTE:
            return { ...state, verificaConexaoInternetCadastraAtendente: action.payload }
        case SEMCONEXAOINTERNETALTERAATENDENTE:
            return { ...state, verificaConexaoInternetAlteraAtendente: action.payload }
        case ATIVADESATIVALOADINGFIMDAPAGINATELACONSULTAATENDENTE:
            return { ...state, loadingFimDAPaginaCOnsultaAtendente: action.payload }
        case ATIVADESATIVALOADINGTELACONSULTAATENDENTE:
            return { ...state, loadingConsultaAtendente: action.payload }
        case ENVIAULTIMAREFERENCIAKEYATENDENTE:
            return { ...state, referenciaUltimaKeyAtendente: action.payload }
        case ENVIATENDENTESPARASECTIONED:
            /*   let arr = state.atendentesSectionedConsulta.length === 0 ? [] : state.atendentesSectionedConsulta.items;
              
              
              
              arr.push(action.payload)
              
              const items = [{
                  name: 'ATENDENTES',
                  type: 'group', items: arr.length > 0 ? arr[0] : arr
              }]
               */
            return { ...state, atendentesSectionedConsulta: action.payload }
        case ATIVADESATIVALOADINGSECTIONEDATENDENTE:
            return { ...state, loadingCarregarAtendentesSectionedConsultaCliente: action.payload }
        case MODIFICAATENDENTESELECIONADOSECTIONED:
            return { ...state, atendenteSelecionadosectioned: action.payload }
        case MODIFICANOMEATENDENTESELECIONADOSECTIONED:
            return { ...state, nomeAtendenteSelecinadoSectioned: action.payload }
        case ATIVADESATIVALOADINGREFRESHCONTROLATENDENTE:
            return { ...state, refreshControlConsultaAtendente: action.payload }
        case DESATIVALOADINGBOTAOCADASTRARATENDENTE:
            return { ...state, loadingBotaoCadastrarAtendente: false }
        case MUDAVISIBILIDADEMODALCADASTRAATENDENTEVERSAOPREMIUM:
            return { ...state, modalCadastraAtendenteVersaoPremium: action.payload }

        case ZERAMENSAGEMERRORTELACADASTROATENDENTE2:
            return { ...state, mensagemerrorAoCadastrarAtendente: '', backgroundMensagemErrorTelaCadastraAtendente: null, mensagemSucessoAoCadastrarAtendente: '' }

        case ALTERASWITCHPERMISSAOALTERARADMINISTRADOR:
            if (action.payload == true) {
                return {
                    ...state,
                    permissaoAdministrador: action.payload,
                    permissaoAlterarCadastrarCliente: true,
                    permissaoAlterarCadastrarAtendente: true,
                    permissaoAlterarCadastrarSetores: true,
                    permissaoAlterarCadastrarAvisos: true,
                    permissaoAcessoRelatorios: true
                }
            } else {
                return {
                    ...state,
                    permissaoAdministrador: action.payload,
                    permissaoAlterarCadastrarCliente: false,
                    permissaoAlterarCadastrarAtendente: false,
                    permissaoAlterarCadastrarSetores: false,
                    permissaoAlterarCadastrarAvisos: false,
                    permissaoAcessoRelatorios: false
                }
            }

        case ALTERASWITCHPERMISSAOALTERARCLIENTE:
            return { ...state, permissaoAlterarCadastrarCliente: action.payload }
        case ALTERASWITCHPERMISSAOALTERARATENDENTE:
            return { ...state, permissaoAlterarCadastrarAtendente: action.payload }
        case ALTERASWITCHPERMISSAOALTERARSETORES:
            return { ...state, permissaoAlterarCadastrarSetores: action.payload }
        case ALTERASWITCHPERMISSAOALTERARAVISOS:
            return { ...state, permissaoAlterarCadastrarAvisos: action.payload }
        case ALTERASWITCHPERMISSAOACESSORELATORIOS:
            return { ...state, permissaoAcessoRelatorios: action.payload }
        case ALTERASWITCHPERMISSAOADMINISTRADORSOZINHO:
            return { ...state, permissaoAdministrador: action.payload }
        case ALTERAINFORMACAOCONTAPRINCIPALATENDENTE:
            return { ...state, contaPrincipal: action.payload }
        case ZERAMENSAGEMERRORAOALTERARATENDENTE:
            return { ...state, mensagemerrorAoAlterarAtendenteTelaAlterar: '', backgroundMensagemErrorTelaAlterarAtendente: null }
        case ENVIAPERMISSOESACESSARTELASATENDENTE:
            return { ...state, permissoesAcessarTelasAtendente: action.payload }
        case MUDAVISIBILIDADEMODALEXCLUIRATENDENTE:
            return { ...state, modalExcluirAtendente: action.payload }
        case ATIVADESATIVALOADINGCONTEUDOEXCLUIRATENDENTE:
            return { ...state, loadingExcluirAtendente: action.payload }
        case ENVIAUIDATENDENTETELAALTERARATENDENTE:
            return { ...state, uidAtendente: action.payload }
        case ENVIASEATENDENTEEHADMINISTRADOR:
            return { ...state, usuarioAdministrador: action.payload }
        case MODIFICAFILTROTELAATENDENTES:
            return { ...state, filtroAtendente: action.payload }
        case MODIFICAVISIBLEMODALNOVOATENDENTE:
            return { ...state, modalNovoAtendente: action.payload }
        case MODIFICAARQUIVOCOMOPLETOATENDENTEPARAUPAR:
            return { ...state, arquivoCompletoAtendenteParaUpar: action.payload }
        case MODIFICAARQUIVOCOMOPLETOATENDENTEPARAUPARNOVOCADASTRO:
            return { ...state, arquivoCompletoAtendenteParaUparNovoCadastro: action.payload }



        case MODIFICANOMEATENDENTENOVOCADASTRO:
            return { ...state, nomeAtendenteNovoCadastro: action.payload };
        case MODIFICAEMAILACESSOAPPNOVOCADASTRO:
            return { ...state, emailAtendenteNovoCadastro: action.payload };
        case MODIFICASENHAATENDENTENOVOCADASTRO:
            return { ...state, senhaAtendenteNovoCadastro: action.payload };
        case MODIFICACONFIRMASENHAATENDENTENOVOCADASTRO:
            return { ...state, confirmaSenhaAtendenteNovoCadastro: action.payload };

        case ALTERASWITCHPERMISSAOALTERARADMINISTRADORNOVOCADASTRO:
            if (action.payload == true) {
                return {
                    ...state,
                    permissaoAdministradorNovoCadastro: action.payload,
                    permissaoAlterarCadastrarClienteNovoCadastro: true,
                    permissaoAlterarCadastrarAtendenteNovoCadastro: true,
                    permissaoAlterarCadastrarSetoresNovoCadastro: true,
                    permissaoAlterarCadastrarAvisosNovoCadastro: true,
                    permissaoAcessoRelatoriosNovoCadastro: true,
                    permissaoCancelarAtendimentoNovoCadastro: true
                }
            } else {
                return {
                    ...state,
                    permissaoAdministradorNovoCadastro: action.payload,
                    permissaoAlterarCadastrarClienteNovoCadastro: false,
                    permissaoAlterarCadastrarAtendenteNovoCadastro: false,
                    permissaoAlterarCadastrarSetoresNovoCadastro: false,
                    permissaoAlterarCadastrarAvisosNovoCadastro: false,
                    permissaoAcessoRelatoriosNovoCadastro: false,
                    permissaoCancelarAtendimentoNovoCadastro: false
                }
            }

        case ALTERASWITCHPERMISSAOALTERARCLIENTENOVOCADASTRO:
            return { ...state, permissaoAlterarCadastrarClienteNovoCadastro: action.payload }
        case ALTERASWITCHPERMISSAOALTERARATENDENTENOVOCADASTRO:
            return { ...state, permissaoAlterarCadastrarAtendenteNovoCadastro: action.payload }
        case ALTERASWITCHPERMISSAOALTERARSETORESNOVOCADASTRO:
            return { ...state, permissaoAlterarCadastrarSetoresNovoCadastro: action.payload }
        case ALTERASWITCHPERMISSAOALTERARAVISOSNOVOCADASTRO:
            return { ...state, permissaoAlterarCadastrarAvisosNovoCadastro: action.payload }
        case ALTERASWITCHPERMISSAOACESSORELATORIOSNOVOCADASTRO:
            return { ...state, permissaoAcessoRelatoriosNovoCadastro: action.payload }

        case MODIFICAPERMISSAOCANCELARATENDIMENTONOVOCADASTRO:
            return { ...state, permissaoCancelarAtendimentoNovoCadastro: action.payload }

        case ALTERASWITCHPERMISSAOCANCELARATENDIMENTO:
            return { ...state, permissaoCancelarAtendimento: action.payload }


        default:
            return state;
    }
};