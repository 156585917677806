import moment from 'moment';
import firebase from '../servicos/FirebaseConnection';
import { toastr } from 'react-redux-toastr';
import {removeFirebaseCachedData} from './cachedData';

import {
    MODIFICANOMESETOR, MODIFICAOBSERVACAO, ATIVALOADINGBOTAOCADASTRASETOR, MENSAGEMCADASTRASETORERROR,
    MENSAGEMCADASTRASETORSUCESSO, ZERAINFORMACOESCADASTROSETOR, MODIFICADIGITOCONSULTASETOR, ENVIADADOSPARASETORREDUCER,
    ZERAMENSAGEMSUCESSOERRROSETOR, ALTERANOMESETORTELAALTERAR, ALTERAOBSSETORTELAALTERAR, ATIVALOADINGBOTAOALTERARSETOR,
    MENSAGEMALTERASETORSUCESSO, MENSAGEMALTERASETORERROR, ALTERAKEYSETORTELAALTERAR, SEMCONEXAOINTERNETCONSULTASETOR,
    SEMCONEXAOINTERNETCADASTRASETOR, SEMCONEXAOINTERNETALTERASETOR, ATIVADESATIVALOADINGTELACONSULTASETOR,
    ENVIAULTIMAREFERENCIAKEYSETOR, ATIVADESATIVALOADINGFIMDAPAGINACONSULTASETOR, ATIVADESATIVALOADINGLISTASETORSECTIONEDTELACONSULTASETOR,
    ENVIASETORPARASECTIONED, MODIFICASETORSELECIONADOSECTIONED, MODIFICANOMESETORSELECIONADOSECTIONED,
    ATIVADESATIVALOADINGREFRESHCONTROLCONSULTASETOR, MUDAVISIBILIDADEMODALEXCLUIRSETOR, ATIVADESATIVALOADINGCONTEUDOEXCLUIRSETOR,
    ATIVADESATIVALOADINGSETORTELARELATORIODETALHADO, ENVIASETORTELARELATORIODETALHADO, ATIVADESATIVALOADINGVINCULAATENDENTESETOR,
    ENVIAATENDENTESECTIONEDVINCULAATENDENTESETOR, MODIFICAATENDENTEVINCULADOSELECIONADOSETOR,
    MODIFICAFILTROSETORESTELASETORES, MODIFICAVISIBLEMODALNOVOSETOR,

    MODIFICANOMESETORNOVOCADASTRO, MODIFICAOBSERVACAONOVOCADASTRO, MODIFICAATENDENTEVINCULADOSELECIONADOSETORNOVOCADASTRO,
    MODIFICAPERMITECLIENTESELECIONARSETOR, MODIFICAPERMITECLIENTESELECIONARSETORCADASTRO,
    ENVIASETORPARAREDUCER
} from './types';


export const cadastraSetor = (nomeSetor2, observacaoSetor2, atendenteVinculadoSelecionado, utilizaAgrupaSetor, permiteClienteSelecionarSetor) => {
    const nomeSetor = nomeSetor2.trim();
    const nomeSetorPesquisa = nomeSetor2.trim().toLowerCase();
    const observacao = observacaoSetor2.trim();
    // 
    return dispatch => {

        if (nomeSetor.trim() === '') {
            toastr.error('Error', 'Digite o nome do Setor.');
        }
        else if (nomeSetor.trim().length < 2) {
            toastr.error('Error', 'O nome do setor deve possuir mais de 1 letra.');
        } else if (atendenteVinculadoSelecionado.length <= 0 && utilizaAgrupaSetor == true) {
            toastr.error('Error', 'Selecione pelo menos 1 atendente que atuará neste SETOR.');
        } else {
            ativaDesativaLoadingBotaoCadastrarSetor(dispatch, true);
            AsyncStorage.getItem('chaveUnica').then((chaveUnica2) => {
                const chaveUnica = chaveUnica2.replace(/"/g, "");
                firebase.database().ref(`${chaveUnica}/setores`).push({ nome: nomeSetor, observacao: observacao, nomePesquisa: nomeSetorPesquisa, alterado: 0 }).then(
                    (dadosSetor) => {
                        if (utilizaAgrupaSetor == true) {
                            let objetocadastro = {};
                            let promissesAtendente = []
                            for (let i = 0; i < atendenteVinculadoSelecionado.length; i++) {
                                objetocadastro[atendenteVinculadoSelecionado[i]] = true;
                            }
                            // 

                            for (let i = 0; i < atendenteVinculadoSelecionado.length; i++) {
                                let keyAtendente = atendenteVinculadoSelecionado[i];

                               

                                promissesAtendente.push(firebase.database().ref(`${chaveUnica}/usuarios/${keyAtendente}`).once('value'));

                            }
                            Promise.all(promissesAtendente).then(responses => {
                                for (let i = 0; i < responses.length; i++) {
                                    let codigoInterno = responses[i].val().codigoInterno == undefined ? 'codigoInterno1' : responses[i].val().codigoInterno;
                                    objetocadastro[codigoInterno] = codigoInterno;
                                }
                                
                                
                                let atendentesVinculados = objetocadastro;
                                firebase.database().ref(`${chaveUnica}/setores/${dadosSetor.key}`).update({ atendentesVinculados, permiteClienteSelecionarSetor }).then(
                                    () => {
                                        toastr.success('SUCESSO', 'Setor cadastrado com sucesso!');
                                        ativaDesativaLoadingBotaoCadastrarSetor(dispatch, false);
                                        zeraInformacoesCadastroSetor(dispatch);
                                    })
                            })

                        } else {
                            toastr.success('SUCESSO', 'Setor cadastrado com sucesso!');
                            ativaDesativaLoadingBotaoCadastrarSetor(dispatch, false);
                            zeraInformacoesCadastroSetor(dispatch);
                        }
                    }
                ).catch((error) => {
                    //error.code
                    //error.message
                    switch (error.code) {
                        case 'auth/network-request-failed':
                            toastr.error('Error', 'Sem conexão com a internet');
                            break;
                        default:
                            toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                            break;
                    }
                })
            })
        }


    }
}

export const alteraSetor = (nomeSetor2, observacaoSetor2, keySetor, atendenteVinculadoSelecionado, utilizaAgrupaSetor, permiteClienteSelecionarSetor) => {
    const nomeSetor = nomeSetor2.trim();
    const nomeSetorPesquisa = nomeSetor2.trim().toLowerCase();
    const observacao = observacaoSetor2.trim();
    // 

    return dispatch => {

        if (nomeSetor.trim() === '') {
            toastr.error('Error', 'Digite o nome do Setor.');
        }
        else if (nomeSetor.trim().length < 2) {
            toastr.error('Error', 'O nome do setor deve possuir mais de 1 letra.');
        } else if (atendenteVinculadoSelecionado.length <= 0 && utilizaAgrupaSetor == true) {
            toastr.error('Error', 'Selecione pelo menos 1 atendente que atuará neste SETOR.');
        } else {
            ativaDesativaLoadingBotaoAlterarSetor(dispatch, true);
            AsyncStorage.getItem('chaveUnica').then((chaveUnica2) => {
                const chaveUnica = chaveUnica2.replace(/"/g, "");
                const setor = firebase.database().ref(`${chaveUnica}/setores/${keySetor}`);
                setor.once('value').then((snapshot) => {
                    const alterado = (1 + snapshot.val().alterado);
                    
                    firebase.database().ref(`${chaveUnica}/setores/${keySetor}`).update({ nome: nomeSetor, nomePesquisa: nomeSetorPesquisa, observacao: observacao, alterado: alterado }).then(
                        () => {
                            if (utilizaAgrupaSetor == true) {
                                let objetocadastro = {};
                                let promissesAtendente = []
                                for (let i = 0; i < atendenteVinculadoSelecionado.length; i++) {
                                    objetocadastro[atendenteVinculadoSelecionado[i]] = true;
                                }

                                for (let i = 0; i < atendenteVinculadoSelecionado.length; i++) {
                                    let keyAtendente = atendenteVinculadoSelecionado[i];

                                   

                                    promissesAtendente.push(firebase.database().ref(`${chaveUnica}/usuarios/${keyAtendente}`).once('value'));

                                }
                                Promise.all(promissesAtendente).then(responses => {
                                    for (let i = 0; i < responses.length; i++) {
                                        let codigoInterno = responses[i].val().codigoInterno == undefined ? 'codigoInterno1' : responses[i].val().codigoInterno;
                                        objetocadastro[codigoInterno] = codigoInterno;
                                    }
                                    
                                    
                                    let atendentesVinculados = objetocadastro;
                                    firebase.database().ref(`${chaveUnica}/setores/${keySetor}`).update({ atendentesVinculados, permiteClienteSelecionarSetor }).then(
                                        () => {
                                            toastr.success('SUCESSO', 'Setor Alterado com sucesso!');
                                            removeFirebaseCachedData(`${chaveUnica}/setores`, keySetor);
                                            ativaDesativaLoadingBotaoAlterarSetor(dispatch, false);
                                        })
                                })
                                // 
                                /*   let atendentesVinculados = objetocadastro;
                                  firebase.database().ref(`${chaveUnica}/setores/${keySetor}`).update({ atendentesVinculados }).then(
                                      () => {
                                        
                                          toastr.success('SUCESSO', 'Setor Alterado com sucesso!');
                                          ativaDesativaLoadingBotaoAlterarSetor(dispatch, false);
                                        
                                      }) */
                            } else {
                                /*     setTimeout(() => { */
                                // dispatch(NavigationActions.back());
                                // zeraInformacoesCadastroSetor(dispatch);
                                removeFirebaseCachedData(`${chaveUnica}/setores`, keySetor);
                                toastr.success('SUCESSO', 'Setor Alterado com sucesso!');
                                ativaDesativaLoadingBotaoAlterarSetor(dispatch, false);
                                /*  }, 700); */
                            }

                        }
                    )
                }).catch((error) => {
                    ativaDesativaLoadingBotaoAlterarSetor(dispatch, false);
                    //error.code
                    //error.message
                    switch (error.code) {
                        case 'auth/network-request-failed':
                            toastr.error('Error', 'Sem conexão com a internet');
                            break;
                        default:
                            toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                            break;
                    }
                })

            }).catch((error) => {
                ativaDesativaLoadingBotaoAlterarSetor(dispatch, false);
                //error.code
                //error.message
                switch (error.code) {
                    case 'auth/network-request-failed':
                        toastr.error('Error', 'Sem conexão com a internet');
                        break;
                    default:
                        toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                        break;
                }
            })
        }


    }
}

export const consultaSetorComSetor = (keySetor) => {
    return dispatch => {
        ativaDesativaLoadingTelaConsultaSetor(dispatch, true);
        ativaDesativaLoadingFimdaPaginaConsultaSetor(dispatch, false);
        AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            firebase.database().ref(`${chaveUnica}/setores/${keySetor}`)
                .on("value", snapshot => {

                    const dadosSetores = [];

                    dadosSetores.push({
                        key: snapshot.key,
                        nome: snapshot.val().nome,
                        alterado: snapshot.val().alterado,
                        observacao: snapshot.val().observacao,
                        permiteClienteSelecionarSetor: snapshot.val().permiteClienteSelecionarSetor == undefined ? true : snapshot.val().permiteClienteSelecionarSetor,
                        chaveUnica: chaveUnica
                    });

                    enviaDadosParaSetorReducer(dispatch, dadosSetores);
                    ativaDesativaLoadingTelaConsultaSetor(dispatch, false);
                })
        })

    }
}

export const listaSetorSectionedTelaConsultaSetor = () => {
    return dispatch => {
    };
};
export const listaSetorSectionedTelaConsultaSetorAntesRemoved = () => {
    return dispatch => {
        ativaDesativeLoadingListaSetorSectionedTelaConsultaSetor(dispatch, true);
        ativaDesativaLoadingSetorTelaRelatorioDetalhado(dispatch, true);

        AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            let primeiraVerificacaoExecucao = false;
            let children2 = [];
            firebase.database().ref(`${chaveUnica}/setores`).orderByKey().limitToLast(1).on("child_added", snapshot => {
                
                
                
                if (snapshot.val() == null) {
                    const arrayUser = [];
                    enviaSetorParaSectioned(dispatch, arrayUser);
                  /*   enviaSetorTelaRelatorioDetalhado(dispatch, arrayUser);
                    enviaSetorParaReducer(dispatch, arrayUser); */

                    ativaDesativeLoadingListaSetorSectionedTelaConsultaSetor(dispatch, false);
                    ativaDesativaLoadingSetorTelaRelatorioDetalhado(dispatch, false);
                } else {
                    let objectModif = { [snapshot.key]: snapshot.val()}
                    // snapshot.val() = [snapshot.key] =snapshot.val();
                     
                     
                    //  snapshot.val() = objectModif;
                     let arrayOfKeys = Object.keys(objectModif).sort().reverse();
                     let results = arrayOfKeys.map(key => objectModif[key]);
                    let referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
                    let aux = 0;
                    results.forEach(childItem => {
                        if (childItem.excluido == 1 || childItem.nome == undefined) {
                            //
                        } else if (children2.filter(e => e.value === referenceToOldestKey).length > 0) {
                            
                        } else {
                            children2.push({
                                value: arrayOfKeys[aux],
                                name: childItem.nome
                            });
                        }
                        aux++
                    });
                   
                   
                    let ultimoUser = children2.length > 0 ? children2[children2.length - 1].value : '';
                   
                   
                    if (primeiraVerificacaoExecucao === true) {
                        // const items = [{ children: children2 }];
                        const items = [{
                            name: 'SETORES',
                            type: 'group', items: children2
                        }];
                        enviaSetorParaSectioned(dispatch, items);
                       /*  enviaSetorTelaRelatorioDetalhado(dispatch, items);
                        enviaSetorParaReducer(dispatch, items); */

                        ativaDesativeLoadingListaSetorSectionedTelaConsultaSetor(dispatch, false);
                        ativaDesativaLoadingSetorTelaRelatorioDetalhado(dispatch, false);
                    } else {
                        firebase.database().ref(`${chaveUnica}/setores`).orderByKey().endAt(referenceToOldestKey).once("value")
                            .then(snapshot => {
                                if (snapshot.val() == null) {
                                    primeiraVerificacaoExecucao = true;
                                    let arrayOrdernado = children2.sort((a, b) => a.name.localeCompare(b.name))
                                    const items = [{
                                        name: 'SETORES',
                                        type: 'group', items: arrayOrdernado
                                    }];
                                    enviaSetorParaSectioned(dispatch, items);
                                   /*  enviaSetorTelaRelatorioDetalhado(dispatch, items);
                                    enviaSetorParaReducer(dispatch, items); */

                                    ativaDesativeLoadingListaSetorSectionedTelaConsultaSetor(dispatch, false);
                                    ativaDesativaLoadingSetorTelaRelatorioDetalhado(dispatch, false);
                                } else {
                                    primeiraVerificacaoExecucao = true;
                                    let arrayOfKeys = Object.keys(snapshot.val()).sort().reverse().slice(1);
                                    let results = arrayOfKeys.map(key => snapshot.val()[key]);
                                    let referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
                                    let aux = 0;
                                    results.forEach(childItem => {
                                        if (childItem.excluido == 1 || childItem.nome == undefined) {
                                            //
                                        } else {
                                            children2.push({
                                                value: arrayOfKeys[aux],
                                                name: childItem.nome
                                            });
                                        }
                                        aux++
                                    });
                                    let arrayOrdernado = children2.sort((a, b) => a.name.localeCompare(b.name))
                                    const items = [{
                                        name: 'SETORES',
                                        type: 'group', items: arrayOrdernado
                                    }];
                                    enviaSetorParaSectioned(dispatch, items);
                                   /*  enviaSetorTelaRelatorioDetalhado(dispatch, items);
                                    enviaSetorParaReducer(dispatch, items); */

                                    ativaDesativeLoadingListaSetorSectionedTelaConsultaSetor(dispatch, false);
                                    ativaDesativaLoadingSetorTelaRelatorioDetalhado(dispatch, false);
                                }
                            })
                    }
                }
            });
        });
    };
};
export const consultaSetor2 = (valor, referenciaUltimaKey, setoresAnteriores) => {
    return dispatch => {
        AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            if (valor == 0) { // if initial fetch
                ativaDesativaLoadingTelaConsultaSetor(dispatch, true);
                ativaDesativeLoadingRefreshControlConsultaSetor(dispatch, true);
                firebase
                    .database()
                    .ref(`${chaveUnica}/setores/`)
                    .orderByChild('excluido')
                    .equalTo(null)
                    .limitToLast(10)
                    .on("value", snapshot => {

                        if (snapshot.val() == null) {
                            const arrayUser = [];
                            enviaDadosParaSetorReducer(dispatch, arrayUser);
                            ativaDesativaLoadingTelaConsultaSetor(dispatch, false);
                            ativaDesativeLoadingRefreshControlConsultaSetor(dispatch, false);
                        } else {
                            let arrayOfKeys = Object.keys(snapshot.val())
                                .sort()
                                .reverse();
                            // transforming to array
                            let results = arrayOfKeys
                                .map((key) => snapshot.val()[key]);
                            // storing reference
                            let referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
                            const dadosSetores = [];
                            snapshot.forEach(childItem => {
                                if (childItem.val().nome == undefined) {

                                } else {
                                    dadosSetores.push({
                                        key: childItem.key,
                                        nome: childItem.val().nome,
                                        observacao: childItem.val().observacao,
                                        alterado: childItem.val().alterado,
                                        permiteClienteSelecionarSetor: childItem.val().permiteClienteSelecionarSetor == undefined ? true : childItem.val().permiteClienteSelecionarSetor,
                                        chaveUnica: chaveUnica
                                    });
                                }
                            });
                            enviaDadosParaSetorReducer(dispatch, dadosSetores);
                            enviaUltimaReferenciaKeySetor(dispatch, referenceToOldestKey);
                            ativaDesativaLoadingTelaConsultaSetor(dispatch, false);
                            ativaDesativeLoadingRefreshControlConsultaSetor(dispatch, false);
                            ativaDesativaLoadingFimdaPaginaConsultaSetor(dispatch, true);
                        }
                    });
            }
            else {
                if (referenciaUltimaKey != undefined && referenciaUltimaKey != '') {
                    // ativaDesativaLoadingFimdaPaginaConsultaSetor(dispatch, true);
                    firebase.database().ref(`${chaveUnica}/setores/`)
                        .orderByChild("excluido")
                        .startAt(null)
                        .endAt(null, referenciaUltimaKey)
                        .limitToLast(11)
                        .on("value", snapshot => {
                            if (snapshot.val() == null) {
                                ativaDesativaLoadingFimdaPaginaConsultaSetor(dispatch, false);
                            } else {
                                //   
                                // changing to reverse chronological order (latest first)
                                let arrayOfKeys = Object.keys(snapshot.val())
                                    .sort()
                                    .reverse()
                                    .slice(1);
                                // transforming to array
                                let results = arrayOfKeys.map((key) => snapshot.val()[key]);
                                // storing reference
                                let referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
                                const dadosSetores = [];

                                for (let i = 0; i < results.length; i++) {
                                    if (results[i].nome == undefined) {

                                    } else {
                                        dadosSetores.push({
                                            key: arrayOfKeys[i],
                                            nome: results[i].nome,
                                            alterado: results[i].alterado,
                                            observacao: results[i].observacao,
                                            chaveUnica: chaveUnica,
                                            permiteClienteSelecionarSetor: results[i].permiteClienteSelecionarSetor == undefined ? true : results[i].permiteClienteSelecionarSetor,
                                        })
                                    }

                                }
                                if (dadosSetores.length > 0) {
                                    enviaDadosParaSetorReducer(dispatch, setoresAnteriores.concat(dadosSetores));
                                }
                                enviaUltimaReferenciaKeySetor(dispatch, referenceToOldestKey);
                                //ativaDesativaLoadingFimdaPaginaConsultaSetor(dispatch, false);
                            }


                        })
                } else {
                    ativaDesativaLoadingFimdaPaginaConsultaSetor(dispatch, false);
                }
            }
        })


    }
}
export const listaAtendentesVincularSetor = (keySetor) => {
    return dispatch => {
        ativaDesativaLoadingVinculaAtendenteSetor(dispatch, true);

        AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild("nome").once('value').then((snapshot) => {
                if (snapshot.val() == null) {
                    const arrayUser = [];
                    enviaAtendenteSectionedVinculaAtendenteSetor(dispatch, arrayUser);
                    ativaDesativaLoadingVinculaAtendenteSetor(dispatch, false);
                } else {
                    //   let todos = [];
                    const children2 = [];
                    const arrayAtendenteVinculados = [];
                    const arrayAtendentesVinculadosSelecionados = [];
                    //   todos.push("TodosPadrao");
                    //   children2.push({ id: "TodosPadrao", name: "TODOS" });
                    snapshot.forEach(childItem => {
                        if (childItem.val().excluido == 1 || childItem.val().clienteBoqueadoDevidoLimiteExcedido == 1 || childItem.val().email == undefined) {
                            // 
                        } else {
                            children2.push({
                                value: childItem.key,
                                name: childItem.val().nome,
                                //codigoInterno: childItem.val().codigoInterno,
                            });
                        }
                    });
                    /*  const items = [{ children: children2 }]; */
                    const items = [{
                        name: 'ATENDENTES',
                        type: 'group', items: children2
                    }];

                    enviaAtendenteSectionedVinculaAtendenteSetor(dispatch, items);
                    //consulto setor para saber qual atendente está vinculado ao setor e popular o atendente selecionado
                    firebase.database().ref(`${chaveUnica}/setores/${keySetor}/atendentesVinculados`).once('value').then((snapshot) => {
                        if (snapshot.val()) {

                            //  console.log('dentro do snapshot.val()')
                            // 
                            snapshot.forEach(childItem => {
                                arrayAtendenteVinculados.push({
                                    value: childItem.key,
                                });
                            })
                            //  
                            //  
                            let result = children2.filter(o1 => arrayAtendenteVinculados.some(o2 => o1.value === o2.value));
                            // 
                            for (let i = 0; i < result.length; i++) {
                                arrayAtendentesVinculadosSelecionados.push(result[i].value)
                            }
                            //  
                            //  


                            modificaAtendenteVinculadoSelecionadoLOCAL(dispatch, arrayAtendentesVinculadosSelecionados)

                            ativaDesativaLoadingVinculaAtendenteSetor(dispatch, false);
                        } else {
                            //tenho que colocar todos os atendentes por padrao
                            // 
                            for (let i = 0; i < children2.length; i++) {
                                arrayAtendentesVinculadosSelecionados.push(children2[i].value)
                            }


                            modificaAtendenteVinculadoSelecionadoLOCAL(dispatch, arrayAtendentesVinculadosSelecionados)
                            ativaDesativaLoadingVinculaAtendenteSetor(dispatch, false);
                        }

                    })

                }
            });
        });
    };
};

export const modificaNomeSetorSelecionadoSectioned = (setor) => {
    return {
        type: MODIFICANOMESETORSELECIONADOSECTIONED,
        payload: setor
    }
}
export const excluirSetor = (keySetor) => {
    return dispatch => {
        AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            ativaDesativaLoadingTelaConsultaSetor(dispatch, true);
            const setor = firebase.database().ref(`${chaveUnica}/setores/${keySetor}`);
            setor.once("value").then(snapshot => {
                const alterado = 1 + snapshot.val().alterado;
                setor.update({
                    excluido: 1,
                    alterado,
                }).then(() => {
                    toastr.success('SUCESSO', 'Setor EXCLUÍDO com SUCESSO!');
                    ativaDesativaLoadingTelaConsultaSetor(dispatch, false);
                });


            })
        })
    }
}

const enviaSetorParaReducer = (dispatch, arraySetor) => {
    dispatch({
        type: ENVIASETORPARAREDUCER,
        payload: arraySetor
    });
};
/* export const excluirSetor = (keySetor) => {
    return dispatch => {
        AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            ativaDesativaLoadingConteudoExcluirSetor(dispatch, true);
            const setor = firebase.database().ref(`${chaveUnica}/setores/${keySetor}`);
            setor.once("value").then(snapshot => {
                const alterado = 1 + snapshot.val().alterado;
                setor.update({
                    excluido: 1,
                    alterado,
                }).then(() => {
                    ativaDesativaLoadingConteudoExcluirSetor(dispatch, false);
                    mudaVisibilidadeModalExcluirSetorLocal(dispatch, false);
                    setTimeout(() => {
                        dispatch(NavigationActions.back());
                        mensagemAlteraSetorSucesso(dispatch, 'Setor EXCLUÍDO com SUCESSO!');
                        
                    }, 100);
                });


            })
        })
    }
} */
const modificaAtendenteVinculadoSelecionadoLOCAL = (dispatch, atendente) => {
    dispatch({
        type: MODIFICAATENDENTEVINCULADOSELECIONADOSETOR,
        payload: atendente
    });
};
export const modificaAtendenteVinculadoSelecionado = atendente => {
    return {
        type: MODIFICAATENDENTEVINCULADOSELECIONADOSETOR,
        payload: atendente
    };
};
export const modificaPermiteClienteSelecionarSetor = value => {
    return {
        type: MODIFICAPERMITECLIENTESELECIONARSETOR,
        payload: value
    };
};
export const modificaPermiteClienteSelecionarSetorCadastro = value => {
    return {
        type: MODIFICAPERMITECLIENTESELECIONARSETORCADASTRO,
        payload: value
    };
};
export const modificaAtendenteVinculadoSelecionadoNovoCadastro = atendente => {
    return {
        type: MODIFICAATENDENTEVINCULADOSELECIONADOSETORNOVOCADASTRO,
        payload: atendente
    };
};
export const modificaVisibleModalNovoSetor = value => {
    return {
        type: MODIFICAVISIBLEMODALNOVOSETOR,
        payload: value
    };
};

const ativaDesativaLoadingSetorTelaRelatorioDetalhado = (dispatch, value) => {
    dispatch({
        type: ATIVADESATIVALOADINGSETORTELARELATORIODETALHADO,
        payload: value
    });
};
const ativaDesativaLoadingVinculaAtendenteSetor = (dispatch, value) => {
    dispatch({
        type: ATIVADESATIVALOADINGVINCULAATENDENTESETOR,
        payload: value
    });
};
const enviaAtendenteSectionedVinculaAtendenteSetor = (dispatch, value) => {
    dispatch({
        type: ENVIAATENDENTESECTIONEDVINCULAATENDENTESETOR,
        payload: value
    });
};
const enviaSetorTelaRelatorioDetalhado = (dispatch, value) => {
    dispatch({
        type: ENVIASETORTELARELATORIODETALHADO,
        payload: value
    });
};


const ativaDesativaLoadingConteudoExcluirSetor = (dispatch, value) => {
    dispatch({
        type: ATIVADESATIVALOADINGCONTEUDOEXCLUIRSETOR,
        payload: value
    });
};
export const modificaSetorSelecionadoSelecionadoSectioned = (setor) => {
    return {
        type: MODIFICASETORSELECIONADOSECTIONED,
        payload: setor
    }
}
const mudaVisibilidadeModalExcluirSetorLocal = (dispatch, visible) => {
    dispatch({
        type: MUDAVISIBILIDADEMODALEXCLUIRSETOR,
        payload: visible
    });
};
export const mudaVisibilidadeModalExcluirSetor = (visible) => {
    return {
        type: MUDAVISIBILIDADEMODALEXCLUIRSETOR,
        payload: visible
    }
}

const enviaSetorParaSectioned = (dispatch, arraySetor) => {
    dispatch({
        type: ENVIASETORPARASECTIONED,
        payload: arraySetor
    });
};
const enviaUltimaReferenciaKeySetor = (dispatch, valor) => {
    dispatch({
        type: ENVIAULTIMAREFERENCIAKEYSETOR,
        payload: valor
    });
}
const ativaDesativeLoadingListaSetorSectionedTelaConsultaSetor = (dispatch, valor) => {
    dispatch({
        type: ATIVADESATIVALOADINGLISTASETORSECTIONEDTELACONSULTASETOR,
        payload: valor
    });
}
const ativaDesativeLoadingRefreshControlConsultaSetor = (dispatch, valor) => {
    dispatch({
        type: ATIVADESATIVALOADINGREFRESHCONTROLCONSULTASETOR,
        payload: valor
    });
}

const ativaDesativaLoadingFimdaPaginaConsultaSetor = (dispatch, valor) => {
    dispatch({
        type: ATIVADESATIVALOADINGFIMDAPAGINACONSULTASETOR,
        payload: valor
    });
}
const ativaDesativaLoadingTelaConsultaSetor = (dispatch, valor) => {
    dispatch({
        type: ATIVADESATIVALOADINGTELACONSULTASETOR,
        payload: valor
    });
}
export const zeraMensagemSemInternetAlteraSetor = () => {
    return {
        type: SEMCONEXAOINTERNETALTERASETOR,
        payload: ''
    }
}
export const zeraMensagemSemInternetCadastraSetor = () => {
    return {
        type: SEMCONEXAOINTERNETCADASTRASETOR,
        payload: ''
    }
}

export const zeraMensagemSemInternetConsultaSetor = () => {
    return {
        type: SEMCONEXAOINTERNETCONSULTASETOR,
        payload: ''
    }
}
export const zeraMensagemAlterarSucesso = () => {
    return {
        type: MENSAGEMALTERASETORSUCESSO,
        payload: ''
    }
}
const enviaDadosParaSetorReducer = (dispatch, arraySetor) => {
    dispatch({
        type: ENVIADADOSPARASETORREDUCER,
        payload: arraySetor
    });
}
export const navegaTelaAlterarSetor = (nomeSetor, ObservacaoSetor, keySetor, permiteClienteSelecionarSetor) => {
    return dispatch => {
        
        dispatch({ type: ALTERANOMESETORTELAALTERAR, payload: nomeSetor });
        dispatch({ type: ALTERAOBSSETORTELAALTERAR, payload: ObservacaoSetor });
        dispatch({ type: ALTERAKEYSETORTELAALTERAR, payload: keySetor });
        dispatch({ type: MODIFICAPERMITECLIENTESELECIONARSETOR, payload: permiteClienteSelecionarSetor });
        //  modificaAtendenteVinculadoSelecionadoLOCAL(dispatch, [])
        /*  dispatch(NavigationActions.navigate({ routeName: 'AlterandoSetor' })); */
    }
}
export const navegaTelaCadastraSetor = () => {
    return dispatch => {
        dispatch({ type: ZERAMENSAGEMSUCESSOERRROSETOR, payload: true });
        /*   dispatch(NavigationActions.navigate({ routeName: 'CadastroSetor' })); */
    }
}
export const modificaDigitoConsultaSetor = (nomeSetor) => {
    return {
        type: MODIFICADIGITOCONSULTASETOR,
        payload: nomeSetor
    };
};
export const modificaNomeSetor = (nomeSetor) => {
    return {
        type: MODIFICANOMESETOR,
        payload: nomeSetor
    };
};
export const modificaObservacaoSetor = (obs) => {
    return {
        type: MODIFICAOBSERVACAO,
        payload: obs
    };
};
export const modificaNomeSetorNovoCadastro = (nomeSetor) => {
    return {
        type: MODIFICANOMESETORNOVOCADASTRO,
        payload: nomeSetor
    };
};
export const modificaObservacaoSetorNovoCadastro = (obs) => {
    return {
        type: MODIFICAOBSERVACAONOVOCADASTRO,
        payload: obs
    };
};
export const modificaFiltroSetoresTelaSetores = (value) => {
    return {
        type: MODIFICAFILTROSETORESTELASETORES,
        payload: value
    };
};

const zeraInformacoesCadastroSetor = (dispatch) => {
    dispatch({
        type: ZERAINFORMACOESCADASTROSETOR,
        payload: true
    });
}
const ativaDesativaLoadingBotaoCadastrarSetor = (dispatch, value) => {
    dispatch({
        type: ATIVALOADINGBOTAOCADASTRASETOR,
        payload: value
    });
}

const ativaDesativaLoadingBotaoAlterarSetor = (dispatch, value) => {
    dispatch({
        type: ATIVALOADINGBOTAOALTERARSETOR,
        payload: value
    });
}
const mensagemAlteraSetorError = (dispatch, error) => {
    dispatch({
        type: MENSAGEMALTERASETORERROR,
        payload: error
    });
}
const mensagemAlteraSetorSucesso = (dispatch, sucesso) => {
    dispatch({
        type: MENSAGEMALTERASETORSUCESSO,
        payload: sucesso
    });
}

const mensagemCasdastraSetorError = (dispatch, error) => {
    dispatch({
        type: MENSAGEMCADASTRASETORERROR,
        payload: error
    });
};
const mensagemCasdastraSetorSucesso = (dispatch, sucesso) => {
    dispatch({
        type: MENSAGEMCADASTRASETORSUCESSO,
        payload: sucesso
    });
};
const AsyncStorage = {
    setItem: function (key, value) {
        return Promise.resolve().then(function () {
            localStorage.setItem(key, value);
        });
    },
    getItem: function (key) {
        return Promise.resolve().then(function () {
            return localStorage.getItem(key);
        });
    }
};
