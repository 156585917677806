import React, { memo } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-responsive-modal';
import './chatelements.css';
import loadContacts from "./loadContacts";
import { removeItemsDuplicateFromListByValue } from "../../servicos/utils";
import firebase from '../../servicos/FirebaseConnection';
import ysFixWebmDuration from "fix-webm-duration";
import { MessageBox, SystemMessage, Input } from '../../servicos/MessageBox/react-chat-elements/src/index';
import moment from 'moment';
import FlatList from 'flatlist-react';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import Linkify from 'react-linkify';
import FlatListEmAtendimento from "./FlatListEmAtendimento";
import FlatListEmEspera from "./FlatListEmEspera";
import FlatListAllContacts from "./FlatListAllContacts";
import ScaleLoader from "react-spinners/ScaleLoader"

import Dropzone from 'react-dropzone';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import 'emoji-mart/css/emoji-mart.css'
import { Picker } from 'emoji-mart'
import MoonLoader from "react-spinners/MoonLoader";
import { withRouter } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import styled from 'styled-components';
import SelectSearch from 'react-select-search';
import ClipLoader from "react-spinners/ClipLoader";
import { toastr } from 'react-redux-toastr';
import MicRecorder from 'mic-recorder-to-mp3';
import vCard from 'vcard-parser';

import imgUser from '../../imagens/userPadrao.png';
import imgTeste from '../../imagens/userPadrao.png';
import excluirSetorPNG from '../../imagens/excluirSetor.png'
import imgunlink from '../../imagens/unlink.png'
import imgexcluirCliente from '../../imagens/delete-user.png';
import { modificaPesquisaTeste } from '../../actions/HeaderAction';
import { shade, opacify, lighten, } from 'polished';
import { Howl, Howler } from 'howler';
import soundnotification from '../../imagens/notificationsound.mp3';
import atendimentoemesperamp3 from '../../imagens/atendimentoemespera.mp3';

import atendendochat from '../../imagens/atendendochat.png';
import {

} from "../../actions/SetorAction";
import { modificaNomeRotaNoHeader } from '../../actions/HeaderAction';
import aguardandoAtendimento from '../../imagens/aguardandoAtendimento.png';
import {
    modificaRetornaTelaClienteParaNovoAtendimento, atribuiAtendimentoAh,
    modificaKeyAtendimentoEmEsperaAtendimentoOnline, modificaVisibilidadeModalAtendimentoOnline,
    atribuiStatusAtendimento, modificaKeyAtendimentoEmAtendimentoOnline,
    alteraComentarioChat, salvarComentario, uploadArquivoChat,
    enviaAtendimentoEmEsperaAtendimentoOnlineExport, modificaChamadosEmAtendimentoViaChatExport,
    ativaDesativaLoadingEnviandoAudioChatExport, modificaResolucaoProblema, getAllContatcsWpp, listaConversasAntigasWppContacts,
    iniciaAtendimentoPorContacts, ativaDesativaLoadingIniciandoCOnversaContactsExport, listaConversasAntigasWppEmEspera,
    listaConversasAntigasWppEmAtendimento, enviaUltimaReeferenciaKeyEmEsperaExport, enviaConversasCarregadasEmEsperaExport,
    enviaUltimaReeferenciaKeyEmAtendimentoExport, enviaConversasCarregadasEmAtendimentoExport, listaConversasAntigasResponder,
    enviaMensagemEncaminhada, comentaEAtribuiAtendimento,
    modificaTitleTab, deletaMensagemDropDesk, enviaContatosParaReducerExport, modificaModalRespostaPadrao,
    setaMensagensComoLidaWhatsAppSocket, modificaModalPreviewImage, modificaUrlPreviewImage, modificaNamePreviewImage,
    ativaDesativaLoadingCarregaContatosWppExport
} from '../../actions/ChamadoAction';
import {
    enviaAtendimentoParaReducerChatExport, desligaOuvintesChamadosFirebase,
    enviaAcoesAtendimentoParaReducerChatExport, listaConversasAnterioresChatAtual,
    enviaUltimaKeyReferenciaCOnversasCarregadasChatExport
} from '../../actions/DashBoardAction';

import { listaRespostasPadroes, verificaSeChatEstaHabilitadoTelaAtendimentoOnline, modificaClicouCFGchat } from '../../actions/ConfiguracaoAction';

import {
    Container, SubContainer, ContainerPrincipal, ContainerSecundario,
    CabecalhoPrincipal, ContainerBatepapo, ContainerEnviaMensagem,

    ContainerDadosCliente, IconeSair, ContainerInputSearch, SubContainerCliente,
    ContainerImagemAlterarCliente, ContainerPrincipalDadosCliente, InputEmpresa, SubContainerClientePermissao,
    ContainerPermitir, TextPermitir, ContainerDadosLoginCliente, ContainerBotaoAlterar, ButtonEntrar,
    DivIconeExcluirCliente, IconeExcluirCliente, DivContainerInputSenha, IconeMostrarSenha,
    IconeEmpresaVinculada, DivContainerInputEmpresaVinculada, ContainerModal, ContainerIconeFecharModal,
    TextNovoAtendimento, IconeFechar, ContainerImagemVincularEmpresa, ContainerExplicacaoVincular,
    ButtonVincular, TextVincular, TextCabecalhoEmAtendimentoLoading, IconeEmpresaVinculadaOff, ContainerModalNovoCliente,
    ContainerDadosNovoCliente, ContainerAtendentesSelecionados, ContainerSelectSearch,
    TextAtendentesSetor, CabecalhoPrincipalLoading,
    TextCabecalhoPrincipalLoading, InputDescricaoChamadoLoading, TextGravandoAudio,

    ContainerChamado, ContainerListaVazia, ContainerDadosChamado,
    ContaineDadosChamadosPrincipal,
    ContainerIconeEFrase, TextCliente,
    SubContainerIconeEFraseClienteAtendente,
    ContainerSeta, TextEmpresa, ImageLoading, ContainerChamadoLoading, ImagemPerfilLoading,
    InputLoadingEmpresa, ContainerDadosClienteLoading, SubContainerClientePermissaoLoading,
    ContainerEmAtendimento, CabecalhoSecundario, ContainerEmEspera,
    ContainerChamadosEmAtendimento, ContainerChamadosEmEspera, DivTextAtendimento, DivBotaoEncerrarAtendimento,
    TextCabecalhoAtendimento, TextSubCabecalhoAtendimento, ButtonEncerrarChamado, CabecalhoSecundarioEmEspera,
    DivTruncarTexto, DivCountChamadoEspera, ButtonImpedidoAtenderChamado, TextTitulo, ImagemComentario, ContainerClipBaixarArquivo, TextBaixarArquivo,
    TextAvaliar, TextMensagem, ContainerModalAlterar, DivSeparadoraMaisInformacoes, DivLAbelCliente, DivCliente, LabelCliente,
    ContainerMaisInformacoes, SubContainerMaisInformacoes, TextBotaoSalvarComentar, TextBotaoCancelarAtendimento, ButtonNaoCancelarAtendimento,
    DivInputMotivoCancelamento, ContainerModalPrioridadeAtendimento, ButtonCancelarAtendimento, ContainerAtendentes,
    ContainerInputDescricaoChamado, InputDescricaoChamado, IconeAcaoChat, TextBotaoNovo, ButtonVariosNovos,
    MarcaDaguaBatePapo, ContainerFlatListBatePapo, TextLoadingEnviandoARquivo, DivRespostasPadroes, TextTitleResposta, TextResposta,
    DivAtendentes, TextAtendentes, ContainerTextCabecalhoModalAcoes, TextCabecalhoModalAcoes, ContainerImagem, DivCountNotif, IconeAudioEmAtendimento,
    ContainerModalRespostas, ButtonLinkCfg, DivTesteFlatList, LabelClienteCabecalho, ContainerMessageClient, MessageCliente,
    ContainerModalGravarAudio, DivInputComentarChamado, ContainerInputEBotaoComent, InputDescricaoChamado2,

    TabsModified, TabListModified, TabModified, TabPanelModified, TextTab,
    IconeTab, ContainerConversas, DivPesquisaConversas, InputPesquisaConversa, ContainerInputConversa,
    ButtonIniciarAtendimento, DivTextAtendimentoEmEspera, ContainerFlatListOverFlow, TextResponder,
    DivTextResponder, ContainerModalEncaminharMensagem, ContainerInputPesquisaConversaEncaminharMensagem,
    DivPesquisaEncaminharMensagem, ContainerInputCompartilhar, RadioCheck, CheckBoxLabelRadio,
    ButtonEnviarMensagemEncaminhada, DivTruncarTextoEncaminharMensagem, ButtonIniciarAtendimentoLoading,
    DivTextoReabrirAtendimento, IconGravadorVoz,
} from './styled';
import 'react-tabs/style/react-tabs.css';
let recorder = null;
let audio = null;
const Mp3Recorder = new MicRecorder({ bitRate: 128 });
const CheckBoxWrapper = styled.div`
position: relative;
margin-left:10px;
height: 20px;
`;
const CheckBoxLabel = styled.label`
position: absolute;
top: 0;
left: 0;
width: 36px;
height: 19px;
border-radius: 15px;
background: #CCC;

cursor: pointer;
&::after {
  content: "";
  display: block;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  margin: 3px;
  background: #ffffff;
  box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
  transition: 0.2s;
}
`;
const CheckBox = styled.input`
opacity: 0;
z-index: 1;

border-radius: 15px;
width: 38px;
height: 22px;
&:checked + ${CheckBoxLabel} {
  background: #00FF7F;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 14px;
    height: 14px;
    margin-left: 18px;
    transition: 0.2s;
  }
}
`;

class ChatWindowEmAtendimento extends React.Component {
    state = {

        modalRespostaPadrao: false,
        viewEmoji: false,
        viewMicrophone: false,
        recordSecs: 0,
        bufferAudio: '',

        duration: '00:00:00',
        statusAudio: 'gravar',

        timer: null,
        audioDetails: {
            url: null,
            blob: null,
            chunks: null,
            duration: {
                h: null,
                m: null,
                s: null,
            }
        },
        blobAudioPuro: '',
        isRecording: false,
        blobURL: '',
        isBlocked: false,

        loadingFileDownload: 0,
        clickFileDownload: false,
        downloadFile: false,
        nomeFileDownload: '',
        pesquisaConversa: '',
        defaultTabIndex: 0,
        noDataContacts: false,
        noDataAtendimento: false,
        noDataEspera: false,
        resultadosPesquisaContacts: [],
        resultadosPesquisaAtendimentos: [],
        resultadosPesquisaEspera: [],
        mostraRefreshControlEmEspera: false,

        viewResponder: false,
        commentResponder: '',
        idResponder: '',
        circleColorResponder: '',
        commentResponderType: null,
        focusMessageBox: 'vazio',
        atendenteResponder: '',


        modalEncaminharMensagem: false,
        pesquisaConversasOuContatosEncaminharMensagem: '',
        noDataContactsEncaminharMensagem: false,
        noDataAtendimentoEncaminharMensagem: false,
        noDataEsperaEncaminharMensagem: false,
        resultadosPesquisaContactsEncaminharMensagem: [],
        resultadosPesquisaAtendimentosEncaminharMensagem: [],
        resultadosPesquisaEsperaEncaminharMensagem: [],

        dadosMensagemParaSerEncaminhada: [],
        arrayKeysClientesEnviaMensagemEncaminhada: [],
        mensagemParaSerEncaminhada: [],
        defaultValeInput: '',

        setandoMensagensLidas: false,
        scrollTop: 0,
        conversasAtual: [],
        primeiraListagemConversas: 0,

        dadosMensagemExcluida: {},
        modalMensagemExcluida: false,

        limiteContato: 20,
        loadingexibeContato: true,

        limiteContatoPesquisado: 20,
        loadingexibeContatoPesquisado: true,
        streamAudioTeste: [],
        visualizaMensagemExcluida: false
    }
    componentWillMount() {

    }

    componentDidMount() {
        this.props.onRef(this);

        /*     
             */
        /*  window.addEventListener("paste", (e) => {
 
             // Handle the event
             this.retrieveImageFromClipboardAsBlob(e, (imageBlob) => {
                 // If there's an image, display it in the canvas
                 if (imageBlob) {
                     var canvas = document.getElementById("mycanvas");
                     var ctx = canvas.getContext('2d');
 
                     // Create an image to render the blob on the canvas
                     var img = new Image();
 
                     // Once the image loads, render the img on the canvas
                     img.onload = () => {
                         // Update dimensions of the canvas with the dimensions of the image
                         canvas.width = this.width;
                         canvas.height = this.height;
 
                         // Draw the image
                         ctx.drawImage(img, 0, 0);
                     };
 
                     // Crossbrowser support for URL
                     var URLObj = window.URL || window.webkitURL;
 
                     // Creates a DOMString containing a URL representing the object given in the parameter
                     // namely the original Blob
                     img.src = URLObj.createObjectURL(imageBlob);
                     
                 }
             });
         }, false); */
        if (this.props.keyAtendimentoEmAtendimentoOnline === this.props.data.key) {
            /*      
                 
                 
                  */
            document.getElementById('refdivInput').addEventListener("paste", (event) => {
                if (this.props.textArquivoChat !== '') {

                } else if (this.props.data.status === 'Fechado') {


                } else {
                    this.retrieveImageFromClipboardAsBlob(event, (blob) => {
                        if (blob !== 'Text' && blob !== null && blob !== undefined) {

                            this.escolheArquivoChamado([blob]);
                            this[this.props.data.key].value = '';
                            this.setState({ defaultValeInput: '' })
                            let input = this[this.props.data.key];
                            input.focus();
                        } else {
                            let input = this[this.props.data.key];
                            input.value = input.value;
                            this.setState({ defaultValeInput: input.value })
                            input.focus();
                            //input.style = { height: 40 };
                        }
                    })
                }

            }, false);
            /*   window.addEventListener('paste', (event) => {
                  
                  
                  if (this.props.textArquivoChat !== '') {
  
                  } else if (this.props.data.status === 'Fechado') {
  
  
                  } else {
                      this.retrieveImageFromClipboardAsBlob(event, (blob) => {
                          
                          if (blob !== 'Text' && blob !== null && blob !== undefined) {
                              this.escolheArquivoChamado([blob])
                          } else {
                              let input = this[this.props.data.key];
                              
                              input.value = input.value + blob;
                              this.setState({ defaultValeInput: input.value + blob })
                              input.focus();
                           //   input.style = { height: 40 };
                          }
                      })
                  }
  
              }, false); */
        }

    }
    componentWillReceiveProps(nextProps, nextState) {
        /*  
         
         
         
          */
        if (this.props.loadingCarregandoRespostasAntigas === true && nextProps.loadingCarregandoRespostasAntigas === false) {
            if (nextProps.idRespostaAntiga !== '') {
                /* 
                 let keyChamado = nextProps.atendimentosPorKeyReducerChat.key;
                 let informacoesCompletas = [];
                 informacoesCompletas[keyChamado] = nextState.conversasAtual;
                  */
                // this.props.enviaAcoesAtendimentoParaReducerChatExport(informacoesCompletas)
                this.setState({ focusMessageBox: nextProps.idRespostaAntiga })
            }
        }

        if (nextProps.keyAtendimentoEmAtendimentoOnline === nextProps.data.key && this.state.conversasAtual.length === 0 && this.state.primeiraListagemConversas === 0) {
            //alert('show')
            this.setState({ primeiraListagemConversas: 1 })
            setTimeout(() => {
                this.carregaConversasChat(nextProps.data.chaveUnica, nextProps.data.key)
            }, 1000);


        }
        /* 
        
        
 */
        if (nextProps.keyAtendimentoEmAtendimentoOnline !== nextProps.data.key && this.state.statusAudio == 'gravacao' || nextProps.keyAtendimentoEmAtendimentoOnline !== nextProps.data.key && this.state.statusAudio == 'gravando') {
            //alert('desliga o som de'+nextProps.data.numeroChamado)
            this.onStopRecord(true);
        }

    }
    componentWillUnmount() {
        this.props.onRef(undefined);
        firebase.database().ref(`${this.props.data.chaveUnica}/acoesChamados/${this.props.data.key}`).limitToLast(15).off("value");
        this.onStopRecord(true);
        //  window.removeEventListener("paste");
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.defaultValeInput !== nextState.defaultValeInput) {
            return false;
        } else {
            return true;
        }
    }
    carregaConversasChat = (chaveUnica, keyChamado) => {

        firebase.database().ref(`${chaveUnica}/acoesChamados/${keyChamado}`).limitToLast(15).on("value", snapshot => {
            let arrayAcoesAtendimento = [];
            if (snapshot.val() == null) {
                this.setState({ conversasAtual: arrayAcoesAtendimento })
                setTimeout(() => {
                    this.carregaConversasChat(chaveUnica, keyChamado)
                }, 1000);
            } else {
                snapshot.forEach(childItem => {
                    if (childItem.val().tipo !== undefined) {
                        arrayAcoesAtendimento.push({
                            key: childItem.key,
                            title: childItem.val().titulo,
                            tipo: childItem.val().tipo,
                            description: childItem.val().mensagem,
                            statusMessage: childItem.val().statusMessage == undefined ? 'sent' : childItem.val().statusMessage,
                            time: childItem.val().time,
                            dataHoraAtual: childItem.val().dataHoraAtual == undefined ? childItem.val().mensagem : childItem.val().dataHoraAtual,
                            visivelATodos: childItem.val().visivelATodos == undefined ? 1 : childItem.val().visivelATodos,
                            lineColor: childItem.val().lineColor,
                            circleColor: childItem.val().circleColor,
                            urlDownload: childItem.val().urlDownload,
                            tipoArquivo: childItem.val().tipoArquivo,
                            alterado: childItem.val().alterado,
                            audio: childItem.val().audio == undefined ? false : childItem.val().audio,
                            duracao: childItem.val().audio == true ? childItem.val().duracao : '',
                            idMensagem: childItem.val().idMensagem == undefined ? childItem.key : childItem.val().idMensagem === '' ? childItem.key : childItem.val().idMensagem,
                            circleColorResponder: childItem.val().circleColorResponder == undefined ? '#00FF7F' : childItem.val().circleColorResponder,
                            commentResponderType: childItem.val().commentResponderType == undefined ? '' : childItem.val().commentResponderType,
                            idMensagemResponder: childItem.val().idMensagemResponder == undefined ? '' : childItem.val().idMensagemResponder,
                            mensagemResposta: childItem.val().mensagemResposta == undefined ? '' : childItem.val().mensagemResposta,
                            possuiResposta: childItem.val().possuiResposta == undefined ? false : childItem.val().possuiResposta,
                            commentResponderTypeArquivo: childItem.val().commentResponderTypeArquivo == undefined ? '' : childItem.val().commentResponderTypeArquivo,
                            commentResponderIsAudio: childItem.val().commentResponderIsAudio == undefined ? '' : childItem.val().commentResponderIsAudio,
                            atendenteResponder: childItem.val().atendenteResponder == undefined ? '' : childItem.val().atendenteResponder,
                            captionArquivo: childItem.val().captionArquivo == undefined ? '' : childItem.val().captionArquivo,
                            keyAtendimentoResponder: childItem.val().keyAtendimentoResponder == undefined ? '' : childItem.val().keyAtendimentoResponder,
                            read: childItem.val().read == undefined ? false : childItem.val().read,
                            deleteMessage: childItem.val().deleteMessage == undefined ? false : childItem.val().deleteMessage,
                            edited: childItem.val().edited ?? false,
                        });
                    }
                });

                const arrayMensagens = [...arrayAcoesAtendimento].reverse();
                this.setState({ conversasAtual: arrayMensagens })
            }
        })


    }
    copiaImagemAreaTransferencia(event) {
        if (this.props.textArquivoChat !== '') {

        } else if (this.props.data.status === 'Fechado') {


        } else {
            this.retrieveImageFromClipboardAsBlob(event, (blob) => {
                if (blob !== 'Text' && blob !== null && blob !== undefined) {
                    this.escolheArquivoChamado([blob])
                } else {
                    let input = this[this.props.data.key];
                    input.value = '';
                    this.setState({ defaultValeInput: '' })
                    input.focus();
                    input.style = { height: 40 };
                }
            })
        }
    }
    retrieveImageFromClipboardAsBlob(pasteEvent, callback) {
        if (pasteEvent.clipboardData == false) {
            if (typeof (callback) == "function") {
                callback(undefined);
            }
        };

        var items = pasteEvent.clipboardData.items;

        if (items == undefined) {
            if (typeof (callback) == "function") {
                callback(undefined);
            }
        };

        for (var i = 0; i < items.length; i++) {
            // Skip content if not image
            /*  if (items[i].type.indexOf("image") == -1) continue; */
            if (items[i].type.indexOf("image") == 0) {
                // Retrieve image on clipboard as blob
                var blob = items[i].getAsFile();

                if (typeof (callback) == "function") {
                    callback(blob);
                }
            } else {
                callback('Text');
            }
        }
    }
    verificaSeEhAlphabeto = (ch) => {
        // 
        let verificaSeEhAlphabeto = typeof ch === "string" && ch.length === 1
            && (ch >= "a" && ch <= "z");
        //
        // 
        if (verificaSeEhAlphabeto) {
            //
            switch (ch) {
                case "a":
                    return require('../../imagens/a.png')
                    break;
                case "b":
                    return require('../../imagens/b.png')
                    break;
                case "c":
                    return require('../../imagens/c.png')
                    break;
                case "d":
                    return require('../../imagens/d.png')
                    break;
                case "e":
                    return require('../../imagens/e.png')
                    break;
                case "f":
                    return require('../../imagens/f.png')
                    break;
                case "g":
                    return require('../../imagens/g.png')
                    break;
                case "h":
                    return require('../../imagens/h.png')
                    break;
                case "i":
                    return require('../../imagens/i.png')
                    break;
                case "j":
                    return require('../../imagens/j.png')
                    break;
                case "k":
                    return require('../../imagens/k.png')
                    break;
                case "l":
                    return require('../../imagens/l.png')
                    break;
                case "m":
                    return require('../../imagens/m.png')
                    break;
                case "n":
                    return require('../../imagens/n.png')
                    break;
                case "o":
                    return require('../../imagens/o.png')
                    break;
                case "p":
                    return require('../../imagens/p.png')
                    break;
                case "q":
                    return require('../../imagens/q.png')
                    break;
                case "r":
                    return require('../../imagens/r.png')
                    break;
                case "s":
                    return require('../../imagens/s.png')
                    break;
                case "t":
                    return require('../../imagens/t.png')
                    break;
                case "u":
                    return require('../../imagens/u.png')
                    break;
                case "v":
                    return require('../../imagens/v.png')
                    break;
                case "x":
                    return require('../../imagens/x.png')
                    break;
                case "z":
                    return require('../../imagens/z.png')
                    break;
                case "w":
                    return require('../../imagens/w.png');
                    break;
                case "y":
                    return require('../../imagens/y.png');
                    break;
                default:
                    return require('../../imagens/userPadrao.png');
                    //  
                    break;

            }
        } else {
            //   
            let urlIMG = require('../../imagens/userPadrao.png')
            return urlIMG;
        }

    }
    baixarArquivo(nomeArquivo, arquivo) {
        /*  AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
             const chaveUnica = chaveUnica2.replace(/"/g, ""); */
        /* toastr.warning('Download Iniciado', 'Baixando Arquivo...'); */
        this.setState({ nomeFileDownload: nomeArquivo, loadingFileDownload: 0.2, downloadFile: true, clickFileDownload: true })
        /* firebase.storage().ref().child(`${this.props.data.chaveUnica}/${this.props.data.key}/${this.props.data.key}/${nomeArquivo}`).getDownloadURL().then((url) => {
           firebase.storage().ref().child(`${this.props.data.chaveUnica}/${this.props.data.key}/${this.props.data.key}/${nomeArquivo}`).getMetadata().then((metadata) => {
               // 
               this.setState({ nomeFileDownload: nomeArquivo, loadingFileDownload: 0.5, downloadFile: true, clickFileDownload: true }) */
        this.setState({ nomeFileDownload: nomeArquivo, loadingFileDownload: 0.5, downloadFile: true, clickFileDownload: true })
        this.getFileBlob(arquivo.urlDownload, (blob) => {

            this.setState({ nomeFileDownload: nomeArquivo, loadingFileDownload: 0.9, downloadFile: true, clickFileDownload: true })
            let file = new File([blob], nomeArquivo, { type: blob.type });
            saveAs(file)
            this.setState({ nomeFileDownload: '', loadingFileDownload: 0, downloadFile: false, clickFileDownload: false })
        })
        /*   saveAs(url, metadata.contentType); */
        /*  var link = document.createElement("a");
        if (link.download !== undefined) {
            //usar quando for abrir imagem
            link.setAttribute("href", url);
            link.setAttribute("target", "_blank");
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } */

        /* }).catch(function (error) {
            
            this.setState({ nomeFileDownload: '', loadingFileDownload: 0, downloadFile: false, clickFileDownload: false })
            // 
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {

                case 'storage/object-not-found':
                    // File doesn't exist
                    break;

                case 'storage/unauthorized':
                    // User doesn't have permission to access the object
                    break;

                case 'storage/canceled':
                    // User canceled the upload
                    break;

                case 'storage/unknown':
                    // Unknown error occurred, inspect the server response
                    break;
            }
        });
    }).catch(function (error) {
        
        this.setState({ nomeFileDownload: '', loadingFileDownload: 0, downloadFile: false, clickFileDownload: false })
        // 
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {

            case 'storage/object-not-found':
                // File doesn't exist
                break;

            case 'storage/unauthorized':
                // User doesn't have permission to access the object
                break;

            case 'storage/canceled':
                // User canceled the upload
                break;

            case 'storage/unknown':
                // Unknown error occurred, inspect the server response
                break;
        }
    }); */
        /*  }) */
    }
    getFileBlob = (url, cb) => {
        let xhr = new XMLHttpRequest();
        xhr.open("GET", url);
        xhr.responseType = "blob";
        xhr.addEventListener('load', function () {
            cb(xhr.response);
        });
        xhr.send();
    };
    zeraListaContatosParaEncaminhar() {
        this.setState({
            dadosMensagemParaSerEncaminhada: [],
            arrayKeysClientesEnviaMensagemEncaminhada: [],
            mensagemParaSerEncaminhada: [],
            modalEncaminharMensagem: false,
            pesquisaConversasOuContatosEncaminharMensagem: '',
            noDataAtendimentoEncaminharMensagem: false,
            noDataContactsEncaminharMensagem: false,
            noDataEsperaEncaminharMensagem: false

        })
    }
    escolheArquivoChamado(res) {
        // 
        if (this.props.data.status === 'Cancelado') {
            toastr.error('Error ao Anexar', 'Atendimento Cancelado');
        } else if (this.props.data.status === 'Fechado') {
            toastr.error('Error ao Anexar', 'Atendimento Fechado');
        } else if (this.props.textArquivoChat !== '') {
            toastr.error('Error ao Anexar', 'Tem arquivo sendo upado neste momento, por favor aguarde.');
        } else {
            if (res.length > 0) {
                //  
                /*  for (let i = 0; i < res.length; i++) { */

                // 
                if (this.props.premium == 0) {
                    toastr.error('Recurso Premium', 'Este é um recurso PREMIUM, para fazer upload de arquivos e imagens, Assine a versão PREMIUM e libere esse e mais recursos!');
                } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
                    toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
                }
                else {
                    //  
                    this.props.uploadArquivoChat('tem arquivo sim', false,
                        '', res, true, this.props.data.key, this.props.data, this.props.data.dataAbertura, this.props.premium, false, this.props.data.keyAtendente, this.props.data, this.props.licensawebouapp, true, this.props.configWhatsapp, this.state.viewResponder, this.state.idResponder, this.state.commentResponder,
                        this.state.circleColorResponder, this.state.commentResponderType, this.state.atendenteResponder);
                    this.zeraMensagemResponder()

                }
                /*  } */
            } else {
                toastr.error('Error ao Anexar', 'Selecione arquivo com no máximo 20MB');
            }
        }
    }
    renderBordaDrag(isDragActive, isDragAccept, isDragReject) {
        if (isDragAccept == true) {
            return '#00FF7F';
        } else {
            return 'red';
        }
    }
    handleDocumentClick = event => {
        let isEmojiClassFound = false;

        event &&
            event.path &&
            event.path.forEach(elem => {
                if (elem && elem.classList) {
                    const data = elem.classList.value;
                    if (data.includes("emoji")) {
                        isEmojiClassFound = true;
                    }
                }
            }); // end
        if (isEmojiClassFound === false && event.target.id !== "emojis-btn")
            this.setState({ viewEmoji: false })
    };
    millisToMinutesAndSeconds(millis) {
        let minutes = Math.floor(millis / 60000);
        let seconds = ((millis % 60000) / 1000).toFixed(0);
        return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
    }
    renderMensagemReply(item) {
        if (item.possuiResposta) {
            /*     { this.state.commentResponderType === 'imagem' && <i className="icon-imagewpp" style={{ fontSize: 14, color: '#000' }} /> }
                { this.state.commentResponderType === 'video' && <i className="icon-video-camera" style={{ fontSize: 14, color: '#000' }} /> }
    
                { this.state.commentResponderType === 'location' && <i className="icon-localization" style={{ fontSize: 14, color: '#000' }} /> }
                { this.state.commentResponderType === 'contato' && <i className="icon-man-user" style={{ fontSize: 14, color: '#000' }} /> }
                { this.state.commentResponderType === 'audio' && <i className="icon-microphone" style={{ fontSize: 14, color: '#000' }} /> }
                { this.state.commentResponderType === 'arquivo' && <i className="icon-documentwpp" style={{ fontSize: 14, color: '#000' }} /> }
                { this.state.commentResponder } */
            return {
                title: item.circleColorResponder === '#39a3f4' ? this.props.data.cliente : item.atendenteResponder,
                titleColor: item.circleColorResponder === '#00FF7F' ? '#16cb9c' : "#39a3f4",
                message:
                    this.renderTextArquivosMensagemRespondidas(item)

             /*    (<p>
                    {item.commentResponderType === 'imagem' && <i className="icon-imagewpp" style={{ fontSize: 13, color: '#000' }} />}
                    {item.commentResponderType === 'video' && <i className="icon-video-camera" style={{ fontSize: 13, color: '#000' }} />}

                    {item.commentResponderType === 'location' && <i className="icon-localization" style={{ fontSize: 13, color: '#000' }} />}
                    {item.commentResponderType === 'contato' && <i className="icon-man-user" style={{ fontSize: 13, color: '#000' }} />}
                    {item.commentResponderType === 'audio' && <i className="icon-microphone" style={{ fontSize: 13, color: '#000' }} />}
                    {item.commentResponderType === 'arquivo' && <i className="icon-documentwpp" style={{ fontSize: 13, color: '#000' }} />}
                    {item.mensagemResposta}

                     {item.commentResponderType === 'link' && item.commentResponderTypeArquivo.includes('image') && <><i className="icon-imagewpp" style={{ fontSize: 13, color: '#000' }} /> Imagem</>}
                    {item.commentResponderType === 'link' && item.commentResponderTypeArquivo.includes('video') && item.commentResponderIsAudio !== true || item.commentResponderTypeArquivo.includes('mp4') && item.commentResponderIsAudio !== true && <> < i className="icon-video-camera" style={{ fontSize: 13, color: '#000' }} /> Video</>}
                    {item.commentResponderType === 'link' && item.commentResponderIsAudio === true && <> <i className="icon-microphone" style={{ fontSize: 13, color: '#000' }} /> Mensagem de Voz</>}
                    {item.commentResponderType === 'link' && !item.commentResponderTypeArquivo.includes('image') && !item.commentResponderTypeArquivo.includes('video') && !item.commentResponderIsAudio === false && !item.commentResponderIsAudio === true && <><i className="icon-documentwpp" style={{ fontSize: 13, color: '#000' }} /> Arquivo</>}

                    {item.commentResponderType !== 'link' && <>{item.mensagemResposta}</>} 
                </p>) */,


            }
        } else {
            return null
        }


    }
    renderClickButtonReply(item, ref) {
        if (this.props.data.chamadoAbertoPorWpp === true) {
            let telefoneCliente = this.props.data.telefoneCliente;
            let chaveUnica = this.props.data.chaveUnica;
            // firebase.database().ref(`relacionamentoMensagensStatusWpp`).orderByChild('idMensagem').equalTo(item.idMensagemResponder).limitToFirst(1).once('value').then((snapshot) => {
            firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild('telefoneCliente').equalTo(telefoneCliente).limitToLast(1).once('value').then((snapshot) => {
                if (snapshot.val()) {
                    let keyChamado = '';
                    snapshot.forEach(childItem => {
                        keyChamado = childItem.key;
                    })
                    let msgMesmaConversa = this.state.conversasAtual.filter(e => e.idMensagem === item.idMensagemResponder).length > 0;
                    if (msgMesmaConversa === true) {
                        this.setState({ focusMessageBox: item.idMensagemResponder })
                    } else {
                        let mensagemJaCarregada = false
                        for (let i = 0; i < this.props.conversasCarregadasEmAtendimento.length; i++) {
                            if (this.props.conversasCarregadasEmAtendimento[i].idMensagem === item.idMensagemResponder) {
                                mensagemJaCarregada = true;
                                break;
                            }
                        }
                        if (mensagemJaCarregada === true) {
                            this.setState({ focusMessageBox: item.idMensagemResponder })
                        } else {
                            // this.props.listaConversasAntigasResponder(keyChamado, item.idMensagemResponder, [], 'emAtendimento')
                            if (item.keyAtendimentoResponder === "" || item.keyAtendimentoResponder === keyChamado) {
                                this.props.listaConversasAntigasResponder(keyChamado, item.idMensagemResponder, [], 'emAtendimento', this.props.verificaAgrupaAtendenteSetor, this.props.isAdmin, this.props.usuarioAdmVisualizaApenasChamadosDoSetorVinculado, this.props.keyUsuarioLogado, this.props.data.keyCliente, this.props.data.chamadoAbertoPorWpp, this.props.atendenteVeApenasProprioHistoricoChamado)
                            } else {
                                this.props.listaConversasAntigasResponder(item.keyAtendimentoResponder, item.idMensagemResponder, [], 'emAtendimento', this.props.verificaAgrupaAtendenteSetor, this.props.isAdmin, this.props.usuarioAdmVisualizaApenasChamadosDoSetorVinculado, this.props.keyUsuarioLogado, this.props.data.keyCliente, this.props.data.chamadoAbertoPorWpp, this.props.atendenteVeApenasProprioHistoricoChamado)
                            }
                        }
                    }
                } else {
                }
            })
        } else {
            let msgMesmaConversa = this.state.conversasAtual.filter(e => e.idMensagem === item.idMensagemResponder).length > 0;
            if (msgMesmaConversa === true) {
                this.setState({ focusMessageBox: item.idMensagemResponder })
            } else {
                let mensagemJaCarregada = false
                for (let i = 0; i < this.props.conversasCarregadasEmAtendimento.length; i++) {
                    if (this.props.conversasCarregadasEmAtendimento[i].idMensagem === item.idMensagemResponder) {
                        mensagemJaCarregada = true;
                        break;
                    }
                }
                if (mensagemJaCarregada === true) {
                    this.setState({ focusMessageBox: item.idMensagemResponder })
                } else {
                    this.props.listaConversasAntigasResponder(this.props.data.key, item.idMensagemResponder, [], 'emAtendimento', this.props.verificaAgrupaAtendenteSetor, this.props.isAdmin, this.props.usuarioAdmVisualizaApenasChamadosDoSetorVinculado, this.props.keyUsuarioLogado, this.props.data.keyCliente, this.props.data.chamadoAbertoPorWpp, this.props.atendenteVeApenasProprioHistoricoChamado)
                }
            }
            //this.setState({ focusMessageBox: item.idMensagemResponder })
        }
    }


    messageBox(item) {
        /*      */
        if (item.deleteMessage === true && this.state.visualizaMensagemExcluida === false) {
            return (
                <MessageBox
                    {...item}
                    id={item.key}
                    reply={this.renderMensagemReply(item)}
                    /*   ref={ref => { this.refsArray[item.key] = ref }} */
                    onReplyMessageClick={(e) => { this.renderClickButtonReply(item, e) }}
                    onReplyClick={() => { this.renderResponderMensagem(item) }}
                    onForwardClick={() => {
                        this._toggleModalEncaminharMensagem();
                        let mensagemParaSerEncaminhada2 = [];
                        item['keyChamado'] = this.props.data.key;
                        mensagemParaSerEncaminhada2.push(item)
                        this.setState({ mensagemParaSerEncaminhada: mensagemParaSerEncaminhada2 })
                    }}
                    onRemoveMessageClick={() => { this.setState({ modalMensagemExcluida: true, dadosMensagemExcluida: item }) }}
                    //onClick={() => {  }}
                    //onOpen={() => {  }}
                    position={item.circleColor === '#00FF7F' ? "right" : "left"}
                    focus={item.idMensagem === this.state.focusMessageBox ? true : false}
                    onMessageFocused={() => { setTimeout(() => { this.setState({ focusMessageBox: 'vazio' }); }, 1000); }}
                    type={'text'}
                    title={(
                        <div
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                        >
                            {item.title}
                            {this.state.visualizaMensagemExcluida === false && <span onClick={() => { this.setState({ visualizaMensagemExcluida: true }) }} style={{ color: '#16cb9c', fontSize: 10, textDecorationLine: 'underline', marginLeft: 10, fontStyle: 'italic', cursor: "pointer" }}>Visualizar</span>}
                        </div>
                    )}
                    titleColor={item.circleColor === '#00FF7F' ? '#16cb9c' : "#39a3f4"}
                    text={
                        (
                            <Linkify
                                componentDecorator={(decoratedHref, decoratedText, key) => (
                                    <a style={{ fontSize: 15, color: '#39a3f4' }} target="blank" href={decoratedHref} key={key}>
                                        {decoratedText}
                                    </a>
                                )}
                            >
                                <i className="icon-block" style={{ fontSize: 14, color: '#808080' }} />
                                <span style={{ color: '#808080', fontSize: 14, fontStyle: 'italic' }}>Mensagem Apagada</span>
                                {/*   {this.state.visualizaMensagemExcluida === false && <span onClick={() => {this.setState({visualizaMensagemExcluida:true})}} style={{ color: '#16cb9c', fontSize: 10, textDecorationLine: 'underline',marginLeft:10, fontStyle: 'italic', cursor:"pointer" }}>Visualizar</span>} */}

                            </Linkify>
                        )
                    }
                    copiableDate
                    dateString={item.dataHoraAtual}
                    forwarded={false}
                    replyButton={false}
                    removeButton={false}
                    status={null} //(waiting, sent, received, read)

                />
            )
        } else if (item.tipo == 'mensagem') {
            if (item.title.includes('Atendimento criado por')) {
                return (
                    <div key={item.key}>

                        <SystemMessage
                            text={`Conversa Iniciada`} />
                        {this.renderDescriptionMessageBox(item)}

                    </div>
                )
                /*  if (item.circleColor.includes('00FF7F')) {
                     return (
                         <div key={item.key}>
                             <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginBottom: 20, marginTop: 10 }} >
                                 < TextMensagem corLetraDinamica={this.props.coresLetraPadrao} >
                                     Chat Iniciado
                       </TextMensagem>
                             </div>
                             <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right' }} >
                                 <div
                                     style={{
                                         background: '#d9edf7',
                                         padding: 10,
                                         // borderRadius: 8,
                                         borderTopLeftRadius: 20,
                                         borderTopRightRadius: 1,
                                         borderBottomRightRadius: 20,
                                         borderBottomLeftRadius: 5,
                                         boxShadow: '0.5rem 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.2)',
                                         margin: 10,
                                         display: 'flex',
                                         flexDirection: 'column'
                                     }}
                                 >
                                     {this.renderDescriptionTimeline(item)}
                                     <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', textAlign: 'left', marginBottom: -8, marginTop: 5 }}>
                                         <p style={{ fontSize: 11, color: '#808080', fontStyle: 'italic' }}>{item.time}</p>
                                     </div>
                                 </div>
                             </div>
 
                         </div>
                     )
                 } else {
                     return (
                         <div key={item.key}>
                             <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginBottom: 20, marginTop: 10 }} key={item.key}>
                                 < TextMensagem corLetraDinamica={this.props.coresLetraPadrao} >
                                     Chat Iniciado
                       </TextMensagem>
                             </div>
                             <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', textAlign: 'left' }}>
                                 <div style={{
                                     background: '#E6E9F2',
                                     padding: 10,
                                     //  borderRadius: 8,
                                     borderTopLeftRadius: 1,
                                     borderTopRightRadius: 20,
                                     borderBottomRightRadius: 5,
                                     borderBottomLeftRadius: 20,
                                     boxShadow: '0.5rem 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.2)',
                                     margin: 10,
                                     display: 'flex',
                                     flexDirection: 'column'
                                 }}>
                                     {this.renderDescriptionTimeline(item)}
                                     <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right', marginBottom: -8, marginTop: 5 }}>
                                         <p style={{ fontSize: 11, color: '#808080', fontStyle: 'italic' }}>{item.time}</p>
                                     </div>
                                 </div>
                             </div>
 
                         </div>
                     )
                 } */
            } else if (item.description.includes(`${item.title} entrou no Chat`)) {
                return (
                    <div key={item.key}>
                        <SystemMessage
                            text={`${item.title} entrou na Conversa`} />
                        {/*   <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginBottom: 20, marginTop: 10 }} key={item.key}>
                          < TextMensagem corLetraDinamica={this.props.coresLetraPadrao} >
                              {item.description}
                          </TextMensagem>
                      </div> */}
                    </div>
                )
            }
            else if (item.description.includes(`Atendimento atribuido ao atendente`)) {
                let atendendoAtribuido = item.description.replace('Atendimento atribuido ao atendente', '')
                return (
                    <div key={item.key}>
                        {/* <SystemMessage
                            text={`${item.description} entrou na Conversa`} /> */}
                        <SystemMessage
                            text={`${item.description}`} />

                        {/*   <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginBottom: 20, marginTop: 10 }} key={item.key}>
                            < TextMensagem corLetraDinamica={this.props.coresLetraPadrao} >
                                {atendendoAtribuido} entrou no Chat
                      </TextMensagem>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginBottom: 20, marginTop: 10 }}>
                            < TextMensagem corLetraDinamica={this.props.coresLetraPadrao} >
                                {item.description}
                            </TextMensagem>
                        </div> */}

                    </div>
                )
            } else if (item.description.includes(`Atendimento transferido para o setor`)) {
                return (
                    <div key={item.key}>
                        {/*   <SystemMessage
                            text={`${item.description} entrou na Conversa`} /> */}
                        <SystemMessage
                            text={`${item.description}`} />

                    </div>
                )
            } else if (item.description.includes(`${item.title} encerrou o chat`)) {
                return (
                    <div key={item.key}>
                        <SystemMessage
                            text={`${item.title} encerrou a Conversa.`} />
                        {/*   <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginBottom: 20, marginTop: 10 }} key={item.key}>
                          < TextMensagem corLetraDinamica={this.props.coresLetraPadrao} >
                              {item.description}
                          </TextMensagem>
                </div> */}
                    </div>
                )
            } else if (item.description.includes(`Inicio do Atendimento: #`)) {
                return (
                    <div key={item.key}>
                        <div style={{
                            display: 'flex', height: 20, flex: 1, backgroundColor: '#39a3f4', borderRadius: 10, alignItems: 'center', justifyContent: 'center', textAlign: 'center',
                            margin: 15
                        }}>
                            {/*  <SystemMessage
                            text={item.description} /> */}
                            {/*   <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginBottom: 20, marginTop: 10 }} key={item.key}> */}
                            < TextMensagem corLetraDinamica={'#000'} >
                                {item.description}
                            </TextMensagem>
                        </div>
                        {/*  </div> */}
                    </div>
                )
            }
            else if (item.description.includes(`Fim do Atendimento: #`)) {
                return (
                    <div key={item.key}>
                        <div style={{
                            display: 'flex', height: 20, flex: 1, backgroundColor: '#FFC04D', borderRadius: 10, alignItems: 'center', justifyContent: 'center', textAlign: 'center',
                            margin: 15
                        }}>
                            {/*  <SystemMessage
                            text={item.description} /> */}
                            {/*   <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginBottom: 20, marginTop: 10 }} key={item.key}> */}
                            < TextMensagem corLetraDinamica={'#000'} >
                                {item.description}
                            </TextMensagem>
                        </div>
                        {/*  </div> */}
                    </div >
                )
            } else if (item.description.includes(`Conversa em Andamento: #`)) {
                return (
                    <div key={item.key}>
                        <div style={{
                            display: 'flex', height: 20, flex: 1, backgroundColor: '#FFC04D', borderRadius: 10, alignItems: 'center', justifyContent: 'center', textAlign: 'center',
                            margin: 15
                        }}>
                            {/*  <SystemMessage
                            text={item.description} /> */}
                            {/*   <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginBottom: 20, marginTop: 10 }} key={item.key}> */}
                            < TextMensagem corLetraDinamica={'#000'} >
                                {item.description}
                            </TextMensagem>
                        </div>
                        {/*  </div> */}
                    </div >
                )
            } else if (item.description.includes(`Atendimento REDIRECIONADO para espera devido ao cliente`) || item.description.includes(`Atendimento redirecionado devido o cliente `)) {
                return (
                    <div key={item.key}>
                        <SystemMessage
                            text={item.description} />
                    </div>
                )
            } else if (item.description.includes(`Atendimento Finalizado Devido á Suspeita de Looping de Bot`)) {
                return (
                    <div key={item.key}>
                        <SystemMessage
                            text={item.description} />
                    </div>
                )
            } else if (item.description.includes(`${item.title} Alterou o local do atendimento de`)) {
                return (
                    <div key={item.key}>
                        <SystemMessage
                            text={item.description} />
                    </div>
                )
            }
            else if (item.description.includes(`${item.title} Alterou o setor de`)) {
                return (
                    <div key={item.key}>
                        <SystemMessage
                            text={item.description} />
                    </div>
                )
            }
            else if (item.description.includes(`${item.title} Alterou a descrição do problema de`)) {
                return (
                    <div key={item.key}>
                        <SystemMessage
                            text={item.description} />
                    </div>
                )
            }
            else if (item.description.includes(`Alterou a data de vencimento de`)) {
                return (
                    <div key={item.key}>
                        <SystemMessage
                            text={item.description} />
                    </div>
                )
            } else if (item.description.includes(`Alterou prioridade do atendimento de`)) {
                return (
                    <div key={item.key}>
                        <SystemMessage
                            text={item.description} />
                    </div>
                )
            }
            else {
                return (
                    <div key={item.key + item.alterado}>

                        <MessageBox

                            /*  retracted={true} */
                            {...item}
                            id={item.key}
                            reply={this.renderMensagemReply(item)}
                            /*   ref={ref => { this.refsArray[item.key] = ref }} */
                            onReplyMessageClick={(e) => { this.renderClickButtonReply(item, e) }}
                            onReplyClick={() => { this.renderResponderMensagem(item) }}
                            onForwardClick={() => {
                                this._toggleModalEncaminharMensagem();
                                let mensagemParaSerEncaminhada2 = [];
                                item['keyChamado'] = this.props.data.key;
                                mensagemParaSerEncaminhada2.push(item)
                                this.setState({ mensagemParaSerEncaminhada: mensagemParaSerEncaminhada2 })
                            }}
                            onRemoveMessageClick={() => { this.setState({ modalMensagemExcluida: true, dadosMensagemExcluida: item }) }}
                            //onClick={() => {  }}
                            //onOpen={() => {  }}
                            position={item.circleColor === '#00FF7F' ? "right" : "left"}
                            focus={item.idMensagem === this.state.focusMessageBox ? true : false}
                            onMessageFocused={() => { setTimeout(() => { this.setState({ focusMessageBox: 'vazio' }); }, 1000); }}
                            type={'text'}
                            title={(
                                <div
                                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                >
                                    {item.title}
                                    {this.state.visualizaMensagemExcluida === true && item.deleteMessage === true && <span onClick={() => {
                                        this.setState({ visualizaMensagemExcluida: false })
                                    }} style={{ color: '#EC6A69', fontSize: 10, textDecorationLine: 'underline', marginLeft: 10, fontStyle: 'italic', cursor: "pointer" }}>Ocultar</span>}
                                </div>
                            )}
                            titleColor={item.circleColor === '#00FF7F' ? '#16cb9c' : "#39a3f4"}
                            text={
                                (
                                    <Linkify
                                        componentDecorator={(decoratedHref, decoratedText, key) => (
                                            <a style={{ fontSize: 15, color: '#39a3f4' }} target="blank" href={decoratedHref} key={key}>
                                                {decoratedText}
                                            </a>
                                        )}
                                    >
                                        {item.description}
                                        {/*   {this.state.visualizaMensagemExcluida === true && item.deleteMessage === true && <span  onClick={() => {this.setState({visualizaMensagemExcluida:false})}} style={{ color: '#EC6A69', fontSize: 10, textDecorationLine: 'underline' ,marginLeft:10, fontStyle: 'italic', cursor:"pointer" }}>Ocultar</span>} */}
                                    </Linkify>
                                )
                            }
                            copiableDate
                            dateString={item.dataHoraAtual}
                            forwarded={item.statusMessage === 'waiting' || item.deleteMessage === true ? false : true}
                            replyButton={item.statusMessage === 'waiting' || item.deleteMessage === true ? false : true}
                            removeButton={item.circleColor === '#39a3f4' || item.deleteMessage === true || this.props.isAdmin === false || item.statusMessage === 'waiting' ? false : true}
                            status={item.circleColor === '#39a3f4' ? null : item.statusMessage} //(waiting, sent, received, read)
                        /*   renderAddCmp={() => (
                              <div style={{ display: 'flex', flexDirection: 'row',position:'relative', float: item.circleColor === '#00FF7F' ? "right" : "left",
                              marginLeft:item.circleColor === '#00FF7F' ? 0 : 10,marginRight:item.circleColor === '#00FF7F' ? 10 : 0  }} >
                                  <i className="icon-danger1" style={{ fontSize: 15, color: "red" }} />
                              </div>
                          )} */
                        // focus
                        //  data={{
                        //   uri: 'https://facebook.github.io/react/img/logo.svg',
                        //    status: {
                        //        click: false,
                        //        loading: 0,
                        //    }
                        // }} 
                        />




                    </div>

                )

            }
        } else {
            //let tipoArquivo = rowData.description.split('.').pop().toLowerCase();
            if (item.tipoArquivo != undefined && item.tipoArquivo != null && item.tipoArquivo != '') {
                if (item.tipoArquivo.includes('image') && !item.tipoArquivo.includes('corel')) {
                    if (item.urlDownload == undefined || item.urlDownload == null || item.urlDownload == '') {
                        //tenho que buscar a url de download no firebase
                        //  
                        //  
                        return (
                            <div key={item.key + item.alterado}>
                                <MessageBox
                                    {...item}
                                    id={item.key}
                                    reply={this.renderMensagemReply(item)}
                                    data={{
                                        uri: item.urlDownload,
                                        caption: item.captionArquivo,
                                        status: {
                                            click: this.state.nomeFileDownload == item.description ? this.state.clickFileDownload : false,
                                            loading: this.state.nomeFileDownload == item.description ? this.state.loadingFileDownload : 0,
                                            download: this.state.downloadFile
                                        },
                                        //size: "100MB",
                                    }}
                                    focus={item.idMensagem === this.state.focusMessageBox ? true : false}
                                    onMessageFocused={() => { setTimeout(() => { this.setState({ focusMessageBox: 'vazio' }); }, 1000); }}
                                    onDownload={event => this.baixarArquivo(item.description, item)}
                                    onReplyClick={() => { this.renderResponderMensagem(item) }}
                                    onReplyMessageClick={() => { this.renderClickButtonReply(item) }}
                                    onForwardClick={() => {
                                        this._toggleModalEncaminharMensagem();
                                        let mensagemParaSerEncaminhada2 = [];
                                        item['keyChamado'] = this.props.data.key;
                                        mensagemParaSerEncaminhada2.push(item)
                                        this.setState({ mensagemParaSerEncaminhada: mensagemParaSerEncaminhada2 })
                                    }}
                                    onRemoveMessageClick={() => { this.setState({ modalMensagemExcluida: true, dadosMensagemExcluida: item }) }}
                                    //onClick={() => {  }}
                                    //onOpen={() => {  }}
                                    position={item.circleColor === '#00FF7F' ? 'right' : "left"}
                                    type={'file'}
                                    title={(
                                        <div
                                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                        >
                                            {item.title}
                                            {this.state.visualizaMensagemExcluida === true && item.deleteMessage === true && <span onClick={() => { this.setState({ visualizaMensagemExcluida: false }) }} style={{ color: '#EC6A69', fontSize: 10, textDecorationLine: 'underline', marginLeft: 10, fontStyle: 'italic', cursor: "pointer" }}>Ocultar</span>}
                                        </div>
                                    )}
                                    titleColor={item.circleColor === '#00FF7F' ? '#16cb9c' : "#39a3f4"}
                                    text={item.description}
                                    copiableDate
                                    dateString={item.dataHoraAtual}
                                    forwarded={item.statusMessage === 'waiting' || item.deleteMessage === true ? false : true}
                                    replyButton={item.statusMessage === 'waiting' || item.deleteMessage === true ? false : true}
                                    removeButton={item.circleColor === '#39a3f4' || item.deleteMessage === true || this.props.isAdmin === false || item.statusMessage === 'waiting' ? false : true}
                                    status={item.circleColor === '#39a3f4' ? null : item.statusMessage} //(waiting, sent, received, read)

                                />
                            </div>
                        )
                        {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right' }} key={item.key + item.alterado}>
                                    <div
                                        style={{
                                            background: '#d9edf7',
                                            padding: 10,
                                            // borderRadius: 8,
                                            borderTopLeftRadius: 20,
                                            borderTopRightRadius: 1,
                                            borderBottomRightRadius: 20,
                                            borderBottomLeftRadius: 5,
                                            boxShadow: '0.5rem 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.2)',
                                            margin: 10,
                                            display: 'flex',
                                            flexDirection: 'column'
                                        }} >
                                        <TextTitulo corLetraDinamica={item.circleColor}>{item.title}</TextTitulo>
                                        <ContainerClipBaixarArquivo backgroundDinamico={this.props.backgroundSegundo} corBordaDinamica={this.props.coresLetraSecundario} onClick={() => { this.baixarArquivo(item.description) }} style={{}}>
                                            <i className="icon-clip2" style={{ fontSize: 15, color: this.props.coresLetraSecundario }} />
                                            <TextBaixarArquivo >{item.description}</TextBaixarArquivo>
                                        </ContainerClipBaixarArquivo>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'left', marginBottom: -8, marginTop: 5 }}>
                                            <p style={{ fontSize: 11, color: '#808080', fontStyle: 'italic' }}>{item.time}</p>
                                        </div>
                                    </div>
                                </div> */}


                    } else {
                        //tenho a URL salva, somente renderizar
                        // 
                        // 

                        return (
                            <div key={item.key + item.alterado}>
                                <MessageBox
                                    {...item}
                                    id={item.key}
                                    reply={this.renderMensagemReply(item)}

                                    data={{
                                        uri: item.urlDownload,
                                        status: {
                                            click: true,
                                            // loading: 1,
                                            download: true
                                        },
                                        width: 300,
                                        height: 300,
                                    }}
                                    //onDownload={event => this.baixarArquivo(item.description, item)}
                                    onReplyMessageClick={() => { this.renderClickButtonReply(item) }}
                                    onReplyClick={() => { this.renderResponderMensagem(item) }}
                                    onForwardClick={() => {
                                        this._toggleModalEncaminharMensagem();
                                        let mensagemParaSerEncaminhada2 = [];
                                        item['keyChamado'] = this.props.data.key;
                                        mensagemParaSerEncaminhada2.push(item)
                                        this.setState({ mensagemParaSerEncaminhada: mensagemParaSerEncaminhada2 })
                                    }}
                                    onRemoveMessageClick={() => { this.setState({ modalMensagemExcluida: true, dadosMensagemExcluida: item }) }}
                                    /*     onClick={(event) => {  this.downloadImage(item.urlDownload, item.description);   }} */
                                    onOpen={() => { this.downloadImage(item.urlDownload, item.description); }}
                                    position={item.circleColor === '#00FF7F' ? 'right' : "left"}
                                    type={'photo'}
                                    //title={item.title}
                                    title={(
                                        <div
                                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                        >
                                            {item.title}
                                            {this.state.visualizaMensagemExcluida === true && item.deleteMessage === true && <span onClick={() => { this.setState({ visualizaMensagemExcluida: false }) }} style={{ color: '#EC6A69', fontSize: 10, textDecorationLine: 'underline', marginLeft: 10, fontStyle: 'italic', cursor: "pointer" }}>Ocultar</span>}
                                        </div>
                                    )}
                                    titleColor={item.circleColor === '#00FF7F' ? '#16cb9c' : "#39a3f4"}
                                    text={item.captionArquivo}

                                    /*  text={(
                                         <Linkify
                                             componentDecorator={(decoratedHref, decoratedText, key) => (
                                                 <a style={{ fontSize: 15, color: '#39a3f4' }} target="blank" href={decoratedHref} key={key}>
                                                     {decoratedText}
                                                 </a>
                                             )}
                                         >
                                             {item.captionArquivo}
                                             {this.state.visualizaMensagemExcluida === true && item.deleteMessage === true && <span  onClick={() => {this.setState({visualizaMensagemExcluida:false})}} style={{ color: '#EC6A69', fontSize: 10, textDecorationLine: 'underline' ,marginLeft:10, fontStyle: 'italic', cursor:"pointer" }}>Ocultar</span>}
                                         </Linkify>
                                     )} */
                                    focus={item.idMensagem === this.state.focusMessageBox ? true : false}
                                    onMessageFocused={() => { setTimeout(() => { this.setState({ focusMessageBox: 'vazio' }); }, 1000); }}
                                    copiableDate
                                    dateString={item.dataHoraAtual}
                                    forwarded={item.statusMessage === 'waiting' || item.deleteMessage === true ? false : true}
                                    replyButton={item.statusMessage === 'waiting' || item.deleteMessage === true ? false : true}
                                    removeButton={item.circleColor === '#39a3f4' || item.deleteMessage === true || this.props.isAdmin === false || item.statusMessage === 'waiting' ? false : true}
                                    status={item.circleColor === '#39a3f4' ? null : item.statusMessage} //(waiting, sent, received, read)

                                />
                            </div>
                        )
                        /*    if (item.circleColor.includes('00FF7F')) {
                               return (
                                   <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right' }} key={item.key + item.alterado}>
                                       <div
                                           style={{
                                               background: '#d9edf7',
                                               padding: 10,
                                               //  borderRadius: 8,
                                               borderTopLeftRadius: 20,
                                               borderTopRightRadius: 1,
                                               borderBottomRightRadius: 20,
                                               borderBottomLeftRadius: 5,
                                               boxShadow: '0.5rem 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.2)',
                                               margin: 10,
                                               display: 'flex',
                                               flexDirection: 'column'
                                           }}
                                           key={item.key + item.alterado}
                                       >
                                           <TextTitulo corLetraDinamica={item.circleColor}>{item.title}</TextTitulo>
                                           <ImagemComentario onClick={() => { this.baixarArquivo(item.description) }} src={item.urlDownload} alt={item.description} />
                                           <ContainerClipBaixarArquivo backgroundDinamico={this.props.backgroundSegundo} corBordaDinamica={this.props.coresLetraSecundario} onClick={() => { this.baixarArquivo(item.description) }} style={{}}>
                                               <i className="icon-clip2" style={{ fontSize: 15, color: this.props.coresLetraSecundario }} />
                                               <TextBaixarArquivo >{item.description}</TextBaixarArquivo>
                                           </ContainerClipBaixarArquivo>
                                           <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'left', marginBottom: -8, marginTop: 5 }}>
                                               <p style={{ fontSize: 11, color: '#808080', fontStyle: 'italic' }}>{item.time}</p>
                                           </div>
                                       </div>
                                   </div>
                               )
                           } else {
                               return (
                                   <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', textAlign: 'left' }} key={item.key + item.alterado}>
                                       <div
                                           style={{
                                               background: '#E6E9F2',
                                               padding: 10,
                                               //borderRadius: 8,
                                               borderTopLeftRadius: 1,
                                               borderTopRightRadius: 20,
                                               borderBottomRightRadius: 5,
                                               borderBottomLeftRadius: 20,
                                               boxShadow: '0.5rem 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.2)',
                                               margin: 10,
                                               display: 'flex',
                                               flexDirection: 'column'
                                           }}
                                           key={item.key + item.alterado}
                                       >
                                           <TextTitulo corLetraDinamica={item.circleColor}>{item.title}</TextTitulo>
                                           <ImagemComentario onClick={() => { this.baixarArquivo(item.description) }} src={item.urlDownload} alt={item.description} />
                                           <ContainerClipBaixarArquivo backgroundDinamico={this.props.backgroundSegundo} corBordaDinamica={this.props.coresLetraSecundario} onClick={() => { this.baixarArquivo(item.description) }} style={{}}>
                                               <i className="icon-clip2" style={{ fontSize: 15, color: this.props.coresLetraSecundario }} />
                                               <TextBaixarArquivo >{item.description}</TextBaixarArquivo>
                                           </ContainerClipBaixarArquivo>
                                           <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right', marginBottom: -8, marginTop: 5 }}>
                                               <p style={{ fontSize: 11, color: '#808080', fontStyle: 'italic' }}>{item.time}</p>
                                           </div>
                                       </div>
                                   </div>
                               )
                           } */

                    }

                } else if (item.tipoArquivo.includes('video') && item.audio !== true || item.tipoArquivo.includes('mp4') && item.audio !== true) {
                    if (item.urlDownload == undefined || item.urlDownload == null || item.urlDownload == '') {
                        //tenho que buscar a url de download no firebase
                        //  
                        //  
                        return (
                            <div key={item.key + item.alterado}>
                                <MessageBox
                                    {...item}
                                    id={item.key}
                                    reply={this.renderMensagemReply(item)}
                                    data={{
                                        uri: item.urlDownload,
                                        caption: item.captionArquivo,
                                        status: {
                                            click: this.state.nomeFileDownload == item.description ? this.state.clickFileDownload : false,
                                            loading: this.state.nomeFileDownload == item.description ? this.state.loadingFileDownload : 0,
                                            download: this.state.downloadFile,
                                        },
                                        //size: "100MB",
                                    }}
                                    focus={item.idMensagem === this.state.focusMessageBox ? true : false}
                                    onMessageFocused={() => { setTimeout(() => { this.setState({ focusMessageBox: 'vazio' }); }, 1000); }}
                                    onDownload={event => this.baixarArquivo(item.description, item)}
                                    onReplyClick={() => { this.renderResponderMensagem(item) }}
                                    onReplyMessageClick={() => { this.renderClickButtonReply(item) }}
                                    onForwardClick={() => {
                                        this._toggleModalEncaminharMensagem();
                                        let mensagemParaSerEncaminhada2 = [];
                                        item['keyChamado'] = this.props.data.key;
                                        mensagemParaSerEncaminhada2.push(item)
                                        this.setState({ mensagemParaSerEncaminhada: mensagemParaSerEncaminhada2 })
                                    }}
                                    onRemoveMessageClick={() => { this.setState({ modalMensagemExcluida: true, dadosMensagemExcluida: item }) }}
                                    //onClick={() => {  }}
                                    //onOpen={() => {  }}
                                    position={item.circleColor === '#00FF7F' ? 'right' : "left"}
                                    type={'file'}
                                    title={(
                                        <div
                                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                        >
                                            {item.title}
                                            {this.state.visualizaMensagemExcluida === true && item.deleteMessage === true && <span onClick={() => { this.setState({ visualizaMensagemExcluida: false }) }} style={{ color: '#EC6A69', fontSize: 10, textDecorationLine: 'underline', marginLeft: 10, fontStyle: 'italic', cursor: "pointer" }}>Ocultar</span>}
                                        </div>
                                    )}
                                    titleColor={item.circleColor === '#00FF7F' ? '#16cb9c' : "#39a3f4"}
                                    text={item.description}
                                    copiableDate
                                    dateString={item.dataHoraAtual}
                                    forwarded={item.statusMessage === 'waiting' || item.deleteMessage === true ? false : true}
                                    replyButton={item.statusMessage === 'waiting' || item.deleteMessage === true ? false : true}
                                    removeButton={item.circleColor === '#39a3f4' || item.deleteMessage === true || this.props.isAdmin === false || item.statusMessage === 'waiting' ? false : true}
                                    status={item.circleColor === '#39a3f4' ? null : item.statusMessage}//(waiting, sent, received, read)

                                />
                            </div>
                        )
                        {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right' }} key={item.key + item.alterado}>
                                    <div
                                        style={{
                                            background: '#d9edf7',
                                            padding: 10,
                                            // borderRadius: 8,
                                            borderTopLeftRadius: 20,
                                            borderTopRightRadius: 1,
                                            borderBottomRightRadius: 20,
                                            borderBottomLeftRadius: 5,
                                            boxShadow: '0.5rem 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.2)',
                                            margin: 10,
                                            display: 'flex',
                                            flexDirection: 'column'
                                        }} >
                                        <TextTitulo corLetraDinamica={item.circleColor}>{item.title}</TextTitulo>
                                        <ContainerClipBaixarArquivo backgroundDinamico={this.props.backgroundSegundo} corBordaDinamica={this.props.coresLetraSecundario} onClick={() => { this.baixarArquivo(item.description) }} style={{}}>
                                            <i className="icon-clip2" style={{ fontSize: 15, color: this.props.coresLetraSecundario }} />
                                            <TextBaixarArquivo >{item.description}</TextBaixarArquivo>
                                        </ContainerClipBaixarArquivo>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'left', marginBottom: -8, marginTop: 5 }}>
                                            <p style={{ fontSize: 11, color: '#808080', fontStyle: 'italic' }}>{item.time}</p>
                                        </div>
                                    </div>
                                </div> */}


                    } else {
                        //tenho a URL salva, somente renderizar
                        // 
                        // 

                        return (
                            <div key={item.key + item.alterado}>
                                <MessageBox
                                    {...item}
                                    id={item.key}
                                    reply={this.renderMensagemReply(item)}

                                    data={{
                                        videoURL: item.urlDownload,
                                        status: {
                                            click: true,
                                            // loading: 1,
                                            download: true
                                        },
                                        width: 300,
                                        height: 300,
                                    }}
                                    focus={item.idMensagem === this.state.focusMessageBox ? true : false}
                                    onMessageFocused={() => { setTimeout(() => { this.setState({ focusMessageBox: 'vazio' }); }, 1000); }}
                                    //onDownload={event => this.baixarArquivo(item.description, item)}
                                    onReplyMessageClick={() => { this.renderClickButtonReply(item) }}
                                    onReplyClick={() => { this.renderResponderMensagem(item) }}
                                    onForwardClick={() => {
                                        this._toggleModalEncaminharMensagem();
                                        let mensagemParaSerEncaminhada2 = [];
                                        item['keyChamado'] = this.props.data.key;
                                        mensagemParaSerEncaminhada2.push(item)
                                        this.setState({ mensagemParaSerEncaminhada: mensagemParaSerEncaminhada2 })
                                    }}
                                    onRemoveMessageClick={() => { this.setState({ modalMensagemExcluida: true, dadosMensagemExcluida: item }) }}
                                    /* onClick={(event) => {  this.downloadImage(item.urlDownload, item.description);  }} */
                                    onOpen={() => { this.downloadImage(item.urlDownload, item.description); }}
                                    position={item.circleColor === '#00FF7F' ? 'right' : "left"}
                                    type={'video'}
                                    title={(
                                        <div
                                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                        >
                                            {item.title}
                                            {this.state.visualizaMensagemExcluida === true && item.deleteMessage === true && <span onClick={() => { this.setState({ visualizaMensagemExcluida: false }) }} style={{ color: '#EC6A69', fontSize: 10, textDecorationLine: 'underline', marginLeft: 10, fontStyle: 'italic', cursor: "pointer" }}>Ocultar</span>}
                                        </div>
                                    )}
                                    titleColor={item.circleColor === '#00FF7F' ? '#16cb9c' : "#39a3f4"}
                                    text={item.captionArquivo}
                                    copiableDate
                                    dateString={item.dataHoraAtual}
                                    forwarded={item.statusMessage === 'waiting' || item.deleteMessage === true ? false : true}
                                    replyButton={item.statusMessage === 'waiting' || item.deleteMessage === true ? false : true}
                                    removeButton={item.circleColor === '#39a3f4' || item.deleteMessage === true || this.props.isAdmin === false || item.statusMessage === 'waiting' ? false : true}
                                    status={item.circleColor === '#39a3f4' ? null : item.statusMessage}//(waiting, sent, received, read)

                                />
                            </div>
                        )
                        /*    if (item.circleColor.includes('00FF7F')) {
                               return (
                                   <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right' }} key={item.key + item.alterado}>
                                       <div
                                           style={{
                                               background: '#d9edf7',
                                               padding: 10,
                                               //  borderRadius: 8,
                                               borderTopLeftRadius: 20,
                                               borderTopRightRadius: 1,
                                               borderBottomRightRadius: 20,
                                               borderBottomLeftRadius: 5,
                                               boxShadow: '0.5rem 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.2)',
                                               margin: 10,
                                               display: 'flex',
                                               flexDirection: 'column'
                                           }}
                                           key={item.key + item.alterado}
                                       >
                                           <TextTitulo corLetraDinamica={item.circleColor}>{item.title}</TextTitulo>
                                           <ImagemComentario onClick={() => { this.baixarArquivo(item.description) }} src={item.urlDownload} alt={item.description} />
                                           <ContainerClipBaixarArquivo backgroundDinamico={this.props.backgroundSegundo} corBordaDinamica={this.props.coresLetraSecundario} onClick={() => { this.baixarArquivo(item.description) }} style={{}}>
                                               <i className="icon-clip2" style={{ fontSize: 15, color: this.props.coresLetraSecundario }} />
                                               <TextBaixarArquivo >{item.description}</TextBaixarArquivo>
                                           </ContainerClipBaixarArquivo>
                                           <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'left', marginBottom: -8, marginTop: 5 }}>
                                               <p style={{ fontSize: 11, color: '#808080', fontStyle: 'italic' }}>{item.time}</p>
                                           </div>
                                       </div>
                                   </div>
                               )
                           } else {
                               return (
                                   <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', textAlign: 'left' }} key={item.key + item.alterado}>
                                       <div
                                           style={{
                                               background: '#E6E9F2',
                                               padding: 10,
                                               //borderRadius: 8,
                                               borderTopLeftRadius: 1,
                                               borderTopRightRadius: 20,
                                               borderBottomRightRadius: 5,
                                               borderBottomLeftRadius: 20,
                                               boxShadow: '0.5rem 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.2)',
                                               margin: 10,
                                               display: 'flex',
                                               flexDirection: 'column'
                                           }}
                                           key={item.key + item.alterado}
                                       >
                                           <TextTitulo corLetraDinamica={item.circleColor}>{item.title}</TextTitulo>
                                           <ImagemComentario onClick={() => { this.baixarArquivo(item.description) }} src={item.urlDownload} alt={item.description} />
                                           <ContainerClipBaixarArquivo backgroundDinamico={this.props.backgroundSegundo} corBordaDinamica={this.props.coresLetraSecundario} onClick={() => { this.baixarArquivo(item.description) }} style={{}}>
                                               <i className="icon-clip2" style={{ fontSize: 15, color: this.props.coresLetraSecundario }} />
                                               <TextBaixarArquivo >{item.description}</TextBaixarArquivo>
                                           </ContainerClipBaixarArquivo>
                                           <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right', marginBottom: -8, marginTop: 5 }}>
                                               <p style={{ fontSize: 11, color: '#808080', fontStyle: 'italic' }}>{item.time}</p>
                                           </div>
                                       </div>
                                   </div>
                               )
                           } */

                    }

                }
                else if (item.audio == true) {
                    return (
                        <div key={item.key + item.alterado}>
                            <MessageBox
                                {...item}
                                id={item.key}
                                reply={this.renderMensagemReply(item)}
                                /*  data={{
                                     uri: item.urlDownload,
                                     status: {
                                         click: false,
                                         loading: 0,
                                     }
                                 }} */
                                focus={item.idMensagem === this.state.focusMessageBox ? true : false}
                                onMessageFocused={() => { setTimeout(() => { this.setState({ focusMessageBox: 'vazio' }); }, 1000); }}
                                onReplyMessageClick={() => { this.renderClickButtonReply(item) }}
                                onReplyClick={() => { this.renderResponderMensagem(item) }}
                                onForwardClick={() => {
                                    this._toggleModalEncaminharMensagem();
                                    let mensagemParaSerEncaminhada2 = [];
                                    item['keyChamado'] = this.props.data.key;
                                    mensagemParaSerEncaminhada2.push(item)
                                    this.setState({ mensagemParaSerEncaminhada: mensagemParaSerEncaminhada2 })
                                }}
                                onRemoveMessageClick={() => { this.setState({ modalMensagemExcluida: true, dadosMensagemExcluida: item }) }}
                                //onClick={() => {  }}
                                //onOpen={() => {  }}
                                position={item.circleColor === '#00FF7F' ? 'right' : "left"}
                                type={'text'}
                                title={(
                                    <div
                                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                    >
                                        {item.title}
                                        {this.state.visualizaMensagemExcluida === true && item.deleteMessage === true && <span onClick={() => { this.setState({ visualizaMensagemExcluida: false }) }} style={{ color: '#EC6A69', fontSize: 10, textDecorationLine: 'underline', marginLeft: 10, fontStyle: 'italic', cursor: "pointer" }}>Ocultar</span>}
                                    </div>
                                )}
                                titleColor={item.circleColor === '#00FF7F' ? '#16cb9c' : "#39a3f4"}
                                text={
                                    (
                                        <audio style={{ height: 60, width: 250, }} src={item.urlDownload} controls />
                                        /*  <AudioPlayer
                                             src={item.urlDownload}
                                             preload='auto'
                                             showSkipControls={false}
                                             showJumpControls={false}
                                             layout={'horizontal-reverse'}
                                             defaultDuration={this.millisToMinutesAndSeconds(item.duracao)}
                                             customControlsSection={
                                                 [
                                                     RHAP_UI.MAIN_CONTROLS,
                                                     //  RHAP_UI.VOLUME_CONTROLS 
                                                 ]
                                             }
                                             style={{
                                                 width: '300px',
                                                 borderRadius: 10,
                                                 background: '#E9E9E9'
                                             }}
                                         // other props here
                                         /> */
                                    )
                                }
                                copiableDate
                                dateString={item.dataHoraAtual}
                                forwarded={item.statusMessage === 'waiting' || item.deleteMessage === true ? false : true}
                                replyButton={item.statusMessage === 'waiting' || item.deleteMessage === true ? false : true}
                                removeButton={item.circleColor === '#39a3f4' || item.deleteMessage === true || this.props.isAdmin === false || item.statusMessage === 'waiting' ? false : true}
                                status={item.circleColor === '#39a3f4' ? null : item.statusMessage} //(waiting, sent, received, read)

                            />
                        </div>
                    )
                    /*     if (item.circleColor.includes('00FF7F')) {
    
                            return (
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right' }} key={item.key + item.alterado}>
                                    <div
                                        style={{
                                            background: '#d9edf7',
                                            padding: 10,
                                            // borderRadius: 8,
                                            borderTopLeftRadius: 20,
                                            borderTopRightRadius: 1,
                                            borderBottomRightRadius: 20,
                                            borderBottomLeftRadius: 5,
                                            boxShadow: '0.5rem 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.2)',
                                            margin: 10,
                                            display: 'flex',
                                            flexDirection: 'column'
                                        }}
                                    >
                                        <TextTitulo corLetraDinamica={item.circleColor}>{item.title}</TextTitulo>
                                        <div style={{ marginTop: 10, marginBottom: 10 }}>
    
                                            <AudioPlayer
                                                src={item.urlDownload}
                                                preload='auto'
                                                showSkipControls={false}
                                                showJumpControls={false}
                                                layout={'horizontal-reverse'}
                                                defaultDuration={this.millisToMinutesAndSeconds(item.duracao)}
                                                customControlsSection={
                                                    [
                                                        RHAP_UI.MAIN_CONTROLS,
                                                    ]
                                                }
                                                style={{
                                                    width: '300px',
                                                    borderRadius: 10,
                                                    background: '#F0f0f7'
                                                }}
                                            // other props here
                                            />
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'left', marginBottom: -8, marginTop: 5 }}>
                                            <p style={{ fontSize: 11, color: '#808080', fontStyle: 'italic' }}>{item.time}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        } else {
                            return (
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', textAlign: 'left' }} key={item.key + item.alterado}>
                                    <div
                                        style={{
                                            background: '#E6E9F2',
                                            padding: 10,
                                            // borderRadius: 8,
                                            borderTopLeftRadius: 1,
                                            borderTopRightRadius: 20,
                                            borderBottomRightRadius: 5,
                                            borderBottomLeftRadius: 20,
                                            boxShadow: '0.5rem 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.2)',
                                            margin: 10,
                                            display: 'flex',
                                            flexDirection: 'column'
                                        }}
                                    >
                                        <TextTitulo corLetraDinamica={item.circleColor}>{item.title}</TextTitulo>
                                        <div style={{ marginTop: 10, marginBottom: 10 }}>
    
                                            <AudioPlayer
                                                src={item.urlDownload}
                                                preload='auto'
                                                showSkipControls={false}
                                                showJumpControls={false}
                                                layout={'horizontal-reverse'}
                                                defaultDuration={this.millisToMinutesAndSeconds(item.duracao)}
                                                customControlsSection={
                                                    [
                                                        RHAP_UI.MAIN_CONTROLS,
                                                    ]
                                                }
                                                style={{
                                                    width: '300px',
                                                    borderRadius: 10,
                                                    background: '#F0f0f7'
                                                }}
                                            // other props here
                                            />
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right', marginBottom: -8, marginTop: 5 }}>
                                            <p style={{ fontSize: 11, color: '#808080', fontStyle: 'italic' }}>{item.time}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        } */
                } else {
                    // 
                    return (
                        <div key={item.key + item.alterado}>
                            <MessageBox
                                {...item}
                                id={item.key}
                                reply={this.renderMensagemReply(item)}
                                data={{
                                    uri: item.urlDownload,
                                    caption: item.captionArquivo,
                                    status: {
                                        click: this.state.nomeFileDownload == item.description ? this.state.clickFileDownload : false,
                                        loading: this.state.nomeFileDownload == item.description ? this.state.loadingFileDownload : 0,
                                        download: this.state.downloadFile,
                                        caption: this.state.captionArquivo

                                    },
                                    //size: "100MB",
                                }}
                                focus={item.idMensagem === this.state.focusMessageBox ? true : false}
                                onMessageFocused={() => { setTimeout(() => { this.setState({ focusMessageBox: 'vazio' }); }, 1000); }}
                                onDownload={event => this.baixarArquivo(item.description, item)}
                                onReplyClick={() => { this.renderResponderMensagem(item) }}
                                onReplyMessageClick={() => { this.renderClickButtonReply(item) }}
                                onForwardClick={() => {
                                    this._toggleModalEncaminharMensagem();
                                    let mensagemParaSerEncaminhada2 = [];
                                    item['keyChamado'] = this.props.data.key;
                                    mensagemParaSerEncaminhada2.push(item)
                                    this.setState({ mensagemParaSerEncaminhada: mensagemParaSerEncaminhada2 })
                                }}
                                onRemoveMessageClick={() => { this.setState({ modalMensagemExcluida: true, dadosMensagemExcluida: item }) }}
                                //onClick={() => {  }}
                                //onOpen={() => {  }}
                                position={item.circleColor === '#00FF7F' ? 'right' : "left"}
                                type={'file'}
                                title={(
                                    <div
                                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                    >
                                        {item.title}
                                        {this.state.visualizaMensagemExcluida === true && item.deleteMessage === true && <span onClick={() => { this.setState({ visualizaMensagemExcluida: false }) }} style={{ color: '#EC6A69', fontSize: 10, textDecorationLine: 'underline', marginLeft: 10, fontStyle: 'italic', cursor: "pointer" }}>Ocultar</span>}
                                    </div>
                                )}
                                titleColor={item.circleColor === '#00FF7F' ? '#16cb9c' : "#39a3f4"}
                                text={item.description}
                                copiableDate
                                dateString={item.dataHoraAtual}
                                forwarded={item.statusMessage === 'waiting' || item.deleteMessage === true ? false : true}
                                replyButton={item.statusMessage === 'waiting' || item.deleteMessage === true ? false : true}
                                removeButton={item.circleColor === '#39a3f4' || item.deleteMessage === true || this.props.isAdmin === false || item.statusMessage === 'waiting' ? false : true}
                                status={item.circleColor === '#39a3f4' ? null : item.statusMessage} //(waiting, sent, received, read)

                            />
                        </div>
                    )
                    /*  if (item.circleColor.includes('00FF7F')) {
 
                         return (
                             <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right' }} key={item.key + item.alterado}>
                                 <div
                                     style={{
                                         background: '#d9edf7',
                                         padding: 10,
                                         // borderRadius: 8,
                                         borderTopLeftRadius: 20,
                                         borderTopRightRadius: 1,
                                         borderBottomRightRadius: 20,
                                         borderBottomLeftRadius: 5,
                                         boxShadow: '0.5rem 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.2)',
                                         margin: 10,
                                         display: 'flex',
                                         flexDirection: 'column'
                                     }}
                                 >
                                     <TextTitulo corLetraDinamica={item.circleColor}>{item.title}</TextTitulo>
                                     <ContainerClipBaixarArquivo backgroundDinamico={this.props.backgroundSegundo} corBordaDinamica={this.props.coresLetraSecundario} onClick={() => { this.baixarArquivo(item.description) }} style={{}}>
                                         <i className="icon-clip2" style={{ fontSize: 15, color: this.props.coresLetraSecundario }} />
                                         <TextBaixarArquivo >{item.description}</TextBaixarArquivo>
                                     </ContainerClipBaixarArquivo>
                                     <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'left', marginBottom: -8, marginTop: 5 }}>
                                         <p style={{ fontSize: 11, color: '#808080', fontStyle: 'italic' }}>{item.time}</p>
                                     </div>
                                 </div>
                             </div>
                         )
                     } else {
                         return (
                             <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', textAlign: 'left' }} key={item.key + item.alterado}>
                                 <div
                                     style={{
                                         background: '#E6E9F2',
                                         padding: 10,
                                         // borderRadius: 8,
                                         borderTopLeftRadius: 1,
                                         borderTopRightRadius: 20,
                                         borderBottomRightRadius: 5,
                                         borderBottomLeftRadius: 20,
                                         boxShadow: '0.5rem 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.2)',
                                         margin: 10,
                                         display: 'flex',
                                         flexDirection: 'column'
                                     }}
                                 >
                                     <TextTitulo corLetraDinamica={item.circleColor}>{item.title}</TextTitulo>
                                     <ContainerClipBaixarArquivo backgroundDinamico={this.props.backgroundSegundo} corBordaDinamica={this.props.coresLetraSecundario} onClick={() => { this.baixarArquivo(item.description) }} style={{}}>
                                         <i className="icon-clip2" style={{ fontSize: 15, color: this.props.coresLetraSecundario }} />
                                         <TextBaixarArquivo >{item.description}</TextBaixarArquivo>
                                     </ContainerClipBaixarArquivo>
                                     <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right', marginBottom: -8, marginTop: 5 }}>
                                         <p style={{ fontSize: 11, color: '#808080', fontStyle: 'italic' }}>{item.time}</p>
                                     </div>
                                 </div>
                             </div>
                         )
                     } */
                }
            } else {
                if (item.tipo === 'location') {
                    let dados = item.description.split(" - ");
                    /*  
                     
                      */
                    return (
                        <div key={item.key + item.alterado}>
                            <MessageBox

                                {...item}
                                id={item.key}
                                apiKey="AIzaSyDz1f3-p7gxOvHjVYnmt23VHuLCbZ0TS6s"
                                reply={this.renderMensagemReply(item)}
                                data={{
                                    // uri: item.urlDownload,
                                    /* status: {
                                        click: this.state.nomeFileDownload == item.description ? this.state.clickFileDownload : false,
                                        loading: this.state.nomeFileDownload == item.description ? this.state.loadingFileDownload : 0,
                                        download: this.state.downloadFile
                                    }, */
                                    // size: "100MB",
                                    latitude: dados[0],
                                    longitude: dados[1],

                                }}
                                focus={item.idMensagem === this.state.focusMessageBox ? true : false}
                                onMessageFocused={() => { setTimeout(() => { this.setState({ focusMessageBox: 'vazio' }); }, 1000); }}
                                onReplyClick={() => { this.renderResponderMensagem(item) }}
                                onReplyMessageClick={() => { this.renderClickButtonReply(item) }}
                                onForwardClick={() => {
                                    this._toggleModalEncaminharMensagem();
                                    let mensagemParaSerEncaminhada2 = [];
                                    item['keyChamado'] = this.props.data.key;
                                    mensagemParaSerEncaminhada2.push(item)
                                    this.setState({ mensagemParaSerEncaminhada: mensagemParaSerEncaminhada2 })
                                }}
                                onRemoveMessageClick={() => { this.setState({ modalMensagemExcluida: true, dadosMensagemExcluida: item }) }}
                                //onClick={() => {  }}
                                //onOpen={() => {  }}
                                position={item.circleColor === '#00FF7F' ? 'right' : "left"}
                                type={'location'}
                                /*  onDownload={event => this.baixarArquivo(item.description, item)} */

                                title={(
                                    <div
                                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                    >
                                        {item.title}
                                        {this.state.visualizaMensagemExcluida === true && item.deleteMessage === true && <span onClick={() => { this.setState({ visualizaMensagemExcluida: false }) }} style={{ color: '#EC6A69', fontSize: 10, textDecorationLine: 'underline', marginLeft: 10, fontStyle: 'italic', cursor: "pointer" }}>Ocultar</span>}
                                    </div>
                                )}
                                titleColor={item.circleColor === '#00FF7F' ? '#16cb9c' : "#39a3f4"}
                                text={''}
                                copiableDate
                                dateString={item.dataHoraAtual}
                                forwarded={item.statusMessage === 'waiting' || item.deleteMessage === true ? false : true}
                                replyButton={item.statusMessage === 'waiting' || item.deleteMessage === true ? false : true}
                                removeButton={item.circleColor === '#39a3f4' || item.deleteMessage === true || this.props.isAdmin === false || item.statusMessage === 'waiting' ? false : true}
                                status={item.circleColor === '#39a3f4' ? null : item.statusMessage}//(waiting, sent, received, read) 

                            />
                            {/* < LocationMessage
                                key={item.key}
                                target="_blank"
                                data={{
                                     // latitude: dados[0],
                                    // longitude: dados[1], 
                                    latitude: '37.773972',
                                    longitude: '-122.431297',
                                    // staticURL: '<optional>',
                                    // mapURL: '<optional>'
                                }
                                } /> */}
                        </div>
                    )
                } if (item.tipo === 'vcard') {
                    let card = vCard.parse(item.description);
                    return (
                        <div key={item.key + item.alterado}>

                            <MessageBox
                                /* avatar={imgTeste} */
                                {...item}
                                id={item.key}
                                reply={this.renderMensagemReply(item)}
                                onReplyClick={() => { this.renderResponderMensagem(item) }}
                                onReplyMessageClick={() => { this.renderClickButtonReply(item) }}
                                onForwardClick={() => {
                                    this._toggleModalEncaminharMensagem();
                                    let mensagemParaSerEncaminhada2 = [];
                                    item['keyChamado'] = this.props.data.key;
                                    mensagemParaSerEncaminhada2.push(item)
                                    this.setState({ mensagemParaSerEncaminhada: mensagemParaSerEncaminhada2 })
                                }}
                                onRemoveMessageClick={() => { this.setState({ modalMensagemExcluida: true, dadosMensagemExcluida: item }) }}
                                focus={item.idMensagem === this.state.focusMessageBox ? true : false}
                                onMessageFocused={() => { setTimeout(() => { this.setState({ focusMessageBox: 'vazio' }); }, 1000); }}
                                /*  data={{
                                     uri: item.urlDownload,
                                     status: {
                                         click: this.state.nomeFileDownload == item.description ? this.state.clickFileDownload : false,
                                         loading: this.state.nomeFileDownload == item.description ? this.state.loadingFileDownload : 0,
                                         download: this.state.downloadFile
                                     },
                                     size: "100MB",
                                 }} */
                                /*       onReplyMessageClick={() => } */
                                //onClick={() => {  }}
                                //onOpen={() => {  }}
                                position={item.circleColor === '#00FF7F' ? 'right' : "left"}
                                type={'text'}
                                /* onDownload={event => this.baixarArquivo(item.description, item)} */

                                title={(
                                    <div
                                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                    >
                                        {item.title}
                                        {this.state.visualizaMensagemExcluida === true && item.deleteMessage === true && <span onClick={() => { this.setState({ visualizaMensagemExcluida: false }) }} style={{ color: '#EC6A69', fontSize: 10, textDecorationLine: 'underline', marginLeft: 10, fontStyle: 'italic', cursor: "pointer" }}>Ocultar</span>}
                                    </div>
                                )}
                                titleColor={item.circleColor === '#00FF7F' ? '#16cb9c' : "#39a3f4"}
                                /* text={(
                                    <p>{card.fn[0].value}</p>
                                )} */
                                text={(
                                    <div>
                                        {card.fn && card.fn.map(function (item, i) {
                                            return (
                                                <p key={i}>{item.value}</p>
                                            )
                                        })}
                                        {card.tel && card.tel.map(function (item, i) {
                                            return <p key={i}>{item.value}</p>
                                        })}
                                    </div>
                                )}
                                copiableDate
                                dateString={item.dataHoraAtual}
                                forwarded={item.statusMessage === 'waiting' || item.deleteMessage === true ? false : true}
                                replyButton={item.statusMessage === 'waiting' || item.deleteMessage === true ? false : true}
                                removeButton={item.circleColor === '#39a3f4' || item.deleteMessage === true || this.props.isAdmin === false || item.statusMessage === 'waiting' ? false : true}
                                status={item.circleColor === '#39a3f4' ? null : item.statusMessage} //(waiting, sent, received, read)

                            />
                        </div>
                    )
                } else {
                    return (
                        <div key={item.key + item.alterado}>
                            <MessageBox
                                {...item}
                                id={item.key}
                                reply={this.renderMensagemReply(item)}
                                data={{
                                    uri: item.urlDownload,
                                    caption: item.captionArquivo,
                                    status: {
                                        click: this.state.nomeFileDownload == item.description ? this.state.clickFileDownload : false,
                                        loading: this.state.nomeFileDownload == item.description ? this.state.loadingFileDownload : 0,
                                        download: this.state.downloadFile,
                                        caption: this.state.captionArquivo
                                    },
                                    // size: "100MB",
                                }}
                                onForwardClick={() => {
                                    this._toggleModalEncaminharMensagem();
                                    let mensagemParaSerEncaminhada2 = [];
                                    item['keyChamado'] = this.props.data.key;
                                    mensagemParaSerEncaminhada2.push(item)
                                    this.setState({ mensagemParaSerEncaminhada: mensagemParaSerEncaminhada2 })
                                }}
                                onRemoveMessageClick={() => { this.setState({ modalMensagemExcluida: true, dadosMensagemExcluida: item }) }}
                                focus={item.idMensagem === this.state.focusMessageBox ? true : false}
                                onMessageFocused={() => { setTimeout(() => { this.setState({ focusMessageBox: 'vazio' }); }, 1000); }}
                                onReplyMessageClick={() => { this.renderClickButtonReply(item) }}
                                onReplyClick={() => { this.renderResponderMensagem(item) }}
                                //onClick={() => {  }}
                                //onOpen={() => {  }}
                                position={item.circleColor === '#00FF7F' ? 'right' : "left"}
                                type={'file'}
                                onDownload={event => this.baixarArquivo(item.description, item)}

                                title={(
                                    <div
                                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                    >
                                        {item.title}
                                        {this.state.visualizaMensagemExcluida === true && item.deleteMessage === true && <span onClick={() => { this.setState({ visualizaMensagemExcluida: false }) }} style={{ color: '#EC6A69', fontSize: 10, textDecorationLine: 'underline', marginLeft: 10, fontStyle: 'italic', cursor: "pointer" }}>Ocultar</span>}
                                    </div>
                                )}
                                titleColor={item.circleColor === '#00FF7F' ? '#16cb9c' : "#39a3f4"}
                                text={item.description}
                                copiableDate
                                dateString={item.dataHoraAtual}
                                forwarded={item.statusMessage === 'waiting' || item.deleteMessage === true ? false : true}
                                replyButton={item.statusMessage === 'waiting' || item.deleteMessage === true ? false : true}
                                removeButton={item.circleColor === '#39a3f4' || item.deleteMessage === true || this.props.isAdmin === false || item.statusMessage === 'waiting' ? false : true}
                                status={item.circleColor === '#39a3f4' ? null : item.statusMessage} //(waiting, sent, received, read)

                            />
                        </div>
                    )
                }

                /*  if (item.circleColor.includes('00FF7F')) {
                     return (
                         <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right' }} key={item.key + item.alterado}>
                             <div
                                 style={{
                                     background: '#d9edf7',
                                     padding: 10,
                                     //  borderRadius: 8,
                                     borderTopLeftRadius: 20,
                                     borderTopRightRadius: 1,
                                     borderBottomRightRadius: 20,
                                     borderBottomLeftRadius: 5,
                                     boxShadow: '0.5rem 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.2)',
                                     margin: 10,
                                     display: 'flex',
                                     flexDirection: 'column'
                                 }}
                             >
                                 <TextTitulo corLetraDinamica={item.circleColor}>{item.title}</TextTitulo>
                                 <ContainerClipBaixarArquivo backgroundDinamico={this.props.backgroundSegundo} corBordaDinamica={this.props.coresLetraSecundario} onClick={() => { this.baixarArquivo(item.description) }} style={{}}>
                                     <i className="icon-clip2" style={{ fontSize: 15, color: this.props.coresLetraSecundario }} />
                                     <TextBaixarArquivo >{item.description}</TextBaixarArquivo>
                                 </ContainerClipBaixarArquivo>
                                 <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'left', marginBottom: -8, marginTop: 5 }}>
                                     <p style={{ fontSize: 11, color: '#808080', fontStyle: 'italic' }}>{item.time}</p>
                                 </div>
                             </div>
                         </div>
                     )
                 } else {
                     return (
                         <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', textAlign: 'left' }} key={item.key + item.alterado}>
                             <div
                                 style={{
                                     background: '#E6E9F2',
                                     padding: 10,
                                     // borderRadius: 8,
                                     borderTopLeftRadius: 1,
                                     borderTopRightRadius: 20,
                                     borderBottomRightRadius: 5,
                                     borderBottomLeftRadius: 20,
                                     boxShadow: '0.5rem 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.2)',
                                     margin: 10,
                                     display: 'flex',
                                     flexDirection: 'column'
                                 }}
                             >
                                 <TextTitulo corLetraDinamica={item.circleColor}>{item.title}</TextTitulo>
                                 <ContainerClipBaixarArquivo backgroundDinamico={this.props.backgroundSegundo} corBordaDinamica={this.props.coresLetraSecundario} onClick={() => { this.baixarArquivo(item.description) }} style={{}}>
                                     <i className="icon-clip2" style={{ fontSize: 15, color: this.props.coresLetraSecundario }} />
                                     <TextBaixarArquivo >{item.description}</TextBaixarArquivo>
                                 </ContainerClipBaixarArquivo>
                                 <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right', marginBottom: -8, marginTop: 5 }}>
                                     <p style={{ fontSize: 11, color: '#808080', fontStyle: 'italic' }}>{item.time}</p>
                                 </div>
                             </div>
                         </div>
                     )
                 } */
            }
        }

        /* if (item.circleColor.includes('00FF7F')) {
            return (
                <MessageBox
                    position={'right'}
                    type={'photo'}
                    text={'react.svg'}
                    data={{
                        uri: 'https://facebook.github.io/react/img/logo.svg',
                        status: {
                            click: false,
                            loading: 0,
                        }
                    }} />
            )
        } else {
            return (
                <MessageBox
                    position={'left'}
                    type={'photo'}
                    text={'react.svg'}
                    data={{
                        uri: 'https://facebook.github.io/react/img/logo.svg',
                        status: {
                            click: false,
                            loading: 0,
                        }
                    }} />
            )
    
        } */
    }

    renderRefreshMaisAtendimentosEmAtendimento() {
        if (this.props.referenciaUltimakeyUltimoChamadoEmAtendimento !== undefined && this.props.configWhatsapp !== undefined && this.props.configWhatsapp.length > 0 && this.props.data.chamadoAbertoPorWpp === true && this.props.loadingCarregandoConversasChatAtual === false) {
            if (this.props.refreshControlCarregaMaisChamadosEmAtendimento === true) {
                return (
                    <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center', marginTop: 5/* position: 'absolute', top: 15, center: 0 */ }}>
                        <div style={{ display: 'flex', height: 40, width: 40, borderRadius: '50%', marginTop: 5, backgroundColor: this.props.backgroundSegundo, alignItems: 'center', justifyContent: 'center' }}>
                            <ClipLoader
                                css={{}}
                                size={25}
                                color={this.props.corBotaoPadrao}
                                loading={true}
                            />
                        </div>
                    </div>
                )
            } else {
                return (
                    <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center',/* position: 'absolute', top: 15, center: 0, */ background: 'transparent' }}>
                        <div onClick={() => { this.props.listaConversasAntigasWppEmAtendimento(0, this.props.data.keyCliente, this.props.referenciaUltimakeyUltimoChamadoEmAtendimento, this.props.conversasCarregadasEmAtendimento, this.props.keyAtendimentoEmAtendimentoOnline, this.props.verificaAgrupaAtendenteSetor, this.props.isAdmin, this.props.usuarioAdmVisualizaApenasChamadosDoSetorVinculado, this.props.keyUsuarioLogado, this.props.atendenteVeApenasProprioHistoricoChamado) }} style={{ display: 'flex', height: 40, width: 40, marginTop: 5, cursor: 'pointer', borderRadius: '50%', backgroundColor: this.props.backgroundSegundo, alignItems: 'center', justifyContent: 'center' }}>
                            <i className="icon-refresh-arrow" style={{ fontSize: 22, color: this.props.coresLetraPadrao }} />
                        </div>
                    </div>
                )
            }

        } else {
            if (this.props.loadingCarregandoConversasChatAtual === true) {
                return (
                    <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center', marginTop: 5/* position: 'absolute', top: 15, center: 0 */ }}>
                        <div style={{ display: 'flex', height: 40, width: 40, borderRadius: '50%', marginTop: 5, backgroundColor: 'transparent', alignItems: 'center', justifyContent: 'center' }}>
                            <ClipLoader
                                css={{}}
                                size={25}
                                color={this.props.corBotaoPadrao}
                                loading={true}
                            />
                        </div>
                    </div>
                )
            } else {
                return null;
            }
        }
    }
    zeraMensagemResponder() {
        this.setState({
            viewResponder: false,
            commentResponder: '',
            idResponder: '',
            circleColorResponder: '',
            commentResponderType: null,
            atendenteResponder: '',
        })
        this.setState({
            defaultValeInput: ''
        })
    }
    _toggleModalRespostaPadrao() {
        this.setState({ modalRespostaPadrao: !this.state.modalRespostaPadrao })
    }


    _handleKeyDown = (e) => {
        /*      */

        /*  if (this.props.enviandoComentario == false) { */
        /*       let ctrlPress = false;
              let ctrlKey = 17;
              let cmdKey = 91;
              let vKey = 86;
              
              if (e.keyCode == ctrlKey || e.keyCode == cmdKey) {
                  ctrlPress = true;
              }
               */

        /*  
         
          */
        if (e.key === 'Enter' && e.shiftKey) {
            /*   $('#app').append("<br/> Detected Shift+Enter") */
        } else if (e.keyCode === 86 && e.metaKey || e.keyCode === 86 && e.ctrlKey) {

            // e.preventDefault()

            /*   this[this.props.data.key].value = '';
              this.setState({ defaultValeInput: '' })
              this[this.props.data.key].focus(); */
        }
        else if (e.key === 'Enter') {
            if (this.props.data.status !== 'Fechado') {
                let comentario = this[this.props.data.key].value;
                if (comentario.trim() == '') {
                    // toastr.error('Error', 'Digite uma mensagem');
                    e.preventDefault();
                } else {
                    e.preventDefault();
                    this.salvarComentario();
                    this[this.props.data.key].style = { height: 40 };
                    this[this.props.data.key].focus()
                }


            } else {
                e.preventDefault();
                toastr.error('Esta conversa já foi encerrada');
            }
        }
        /*  } */
    }

    renderIconeUploadArquivoChat() {

        if (this.props.textArquivoChat !== '') {
            return (
                <ClipLoader
                    css={{ marginLeft: 10, marginRight: 10 }}
                    style={{ marginLeft: 10, marginRight: 10 }}
                    size={18}
                    color={'#383838'}
                    loading={true}
                />
                /*   <TextLoadingEnviandoARquivo corDinamico={this.props.corBotaoPadrao}>{this.props.textArquivoChat}</TextLoadingEnviandoARquivo> */
            )
        } else if (this.props.data.status === 'Fechado') {
            return (
                <IconeMostrarSenha style={{ cursor: 'no-drop' }} onClick={() => { toastr.error('Error', 'Atendimento encerrado') }} className="icon-clip" data-tip="Enviar arquivos" data-for="tip-top" colorDinamico={'#383838'} backgroundBotao={'#383838'} />

            )

        } else {
            return (
                <Dropzone
                    multiple={true}
                    noKeyboard
                    noDrag
                    maxSize={2e+7}
                    /*  onDropRejected={() => {
                         toastr.error('Error', 'Adicione arquivos de no máximo 20MB')
                     }} */
                    onDrop={acceptedFiles => this.escolheArquivoChamado(acceptedFiles)}>
                    {({ getRootProps, getInputProps, isDragActive }) => {
                        // 

                        return (
                            /*     <section> */
                            <div   {...getRootProps()}>
                                <input  {...getInputProps()} />
                                <IconeMostrarSenha style={{ cursor: 'pointer' }} className="icon-clip" data-tip="Enviar arquivos" data-for="tip-top" colorDinamico='#383838' backgroundBotao='#383838' />
                            </div>
                            /*   </section> */
                        )
                    }}
                </Dropzone>
            )
        }
    }

    renderRespostaPadrao(item) {
        return (
            <DivRespostasPadroes key={item.value}>
                <TextTitleResposta onClick={() => {
                    /* setTimeout(() => { */

                    //  this._toggleModalRespostaPadrao();
                    let input = this[this.props.data.key];
                    input.value = item.name;
                    input.focus();
                    this.setState({ defaultValeInput: item.name, modalRespostaPadrao: false });

                    /* }, 500); */

                }} corLetraBotaoPadrao={this.props.corBotaoPadrao} corLetraDinamico={this.props.coresLetraPadrao}>{item.subTitle}: <TextResposta corLetraBotaoPadrao={this.props.corBotaoPadrao} corLetraDinamico={this.props.coresLetraSecundario} >{item.name}</TextResposta></TextTitleResposta>
            </DivRespostasPadroes>
        )
    }
    renderMicrophoneRecording() {
        if (this.state.statusAudio == 'gravando') {
            return (
                <div style={{ display: 'flex', flexDirection: 'row', justifyItems: 'center', alignItems: 'center' }}>
                    < IconGravadorVoz
                        style={{ cursor: this.props.data.status === 'Fechado' ? 'no-drop' : 'pointer', fontSize: 18 }}
                        onClick={() => { if (this.props.data.status === 'Fechado') { } else { this.onStopRecord(true); } }}
                        colorDinamico={'#000'}
                        bordaDinamica={this.props.corBotaoPadrao}
                        className="icon-rubbish-bin" />

                    {<p style={{
                        color: "#000",
                        fontSize: 17,
                        fontWeight: 'bold',
                        letterSpacing: 3,
                        marginLeft: 8,
                        marginRight: 8
                    }}>{this.transformaMinutosEmSegundos(this.state.recordSecs)}</p>}
                    <ScaleLoader
                        css={{ marginLeft: 10, marginRight: 10, marginTop: 5 }}
                        width={20}
                        height={12}
                        //size={60}
                        color={'#909090'}
                        loading
                    />
                    < IconGravadorVoz
                        style={{ cursor: this.props.data.status === 'Fechado' ? 'no-drop' : 'pointer', marginRight: 3 }}
                        onClick={() => { if (this.props.data.status === 'Fechado') { } else { this.onStopRecord(false); } }}
                        colorDinamico={'#ff5e3e'}
                        bordaDinamica={this.props.corBotaoPadrao}
                        className="icon-stop-button" />
                    < IconGravadorVoz
                        style={{ cursor: this.props.data.status === 'Fechado' ? 'no-drop' : 'pointer' }}
                        // data-tip={this.props.data.status === 'Fechado' ? 'Atendimento Encerrado' : "Enviar mensagem de áudio"} data-for="tip-top"
                        onClick={() => {
                            if (this.props.data.status === 'Fechado') { } else {
                                this.onStopRecord(false);
                                setTimeout(() => {
                                    this.enviarAudioFirebase()
                                }, 500);
                            }
                        }}
                        // colorDinamico={'#00FA9A'}
                        colorDinamico={'#00BEA4'}
                        bordaDinamica={this.props.corBotaoPadrao}
                        className="icon-send2" />
                </div>
            )
        } else if (this.state.statusAudio == 'gravacao') {
            return (

                <div style={{ display: 'flex', flexDirection: 'row', justifyItems: 'center', alignItems: 'center' }}>
                    < IconGravadorVoz
                        style={{ cursor: this.props.data.status === 'Fechado' ? 'no-drop' : 'pointer', fontSize: 18 }}
                        onClick={() => { if (this.props.data.status === 'Fechado') { } else { this.onStopRecord(true); } }}
                        colorDinamico={'#000'}
                        bordaDinamica={this.props.corBotaoPadrao}
                        className="icon-rubbish-bin" />

                    <div style={{ display: 'flex', width: 250, alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                        <audio style={{ height: 35, width: 250 }} src={this.state.blobURL} controls />
                    </div>
                    {/*  < IconGravadorVoz
                        style={{ cursor: this.props.data.status === 'Fechado' ? 'no-drop' : 'pointer', marginRight: 3 }}
                        onClick={() => { if (this.props.data.status === 'Fechado') { } else { this.onStopRecord(false); } }}
                        colorDinamico={'#ff5e3e'}
                        bordaDinamica={this.props.corBotaoPadrao}
                        className="icon-stop-button" /> */}
                    < IconeMostrarSenha
                        style={{ cursor: this.props.data.status === 'Fechado' ? 'no-drop' : 'pointer' }}
                        //  data-tip={this.props.data.status === 'Fechado' ? 'Atendimento Encerrado' : "Enviar mensagem de áudio"} data-for="tip-top"
                        onClick={() => { if (this.props.data.status === 'Fechado') { } else { this.enviarAudioFirebase() } }}
                        // colorDinamico={'#00FA9A'}
                        colorDinamico={'#00BEA4'}
                        bordaDinamica={this.props.corBotaoPadrao}
                        className="icon-send2" />
                </div>

            )
        }
        else {
            return (
                < IconeMostrarSenha
                    style={{ cursor: this.props.data.status === 'Fechado' ? 'no-drop' : 'pointer', marginLeft: 10 }}
                    data-tip={this.props.data.status === 'Fechado' ? 'Atendimento Encerrado' : "Enviar mensagem de áudio"} data-for="tip-top"
                    onClick={() => { if (this.props.data.status === 'Fechado') { } else { this.onStartRecord() } }}
                    colorDinamico={'#383838'}
                    bordaDinamica={this.props.corBotaoPadrao}
                    className="icon-microphone" />
            )
        }
    }
    renderBotoesDireito() {
        {/* <IconeAcaoChat data-tip="Exibir respostas padrões" data-for="tip-top" className="icon-list-on-window" colorDinamico={this.props.coresLetraSecundario} backgroundBotao={this.props.corBotaoPadrao} onClick={() => {
                if (this.props.data.status === 'Fechado') {
                    toastr.error('Error', 'Chat encerrado')
                } else {
                    this._toggleModalRespostaPadrao()
                }
            }} /> */}
        return (
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>


                {this.state.statusAudio !== 'gravando' && this.state.statusAudio !== 'gravacao' &&
                    <i style={{ fontSize: 20, color: '#383838', marginLeft: 10, cursor: this.props.data.status === 'Fechado' ? 'no-drop' : 'pointer' }} data-tip="Exibir respostas padrões" data-for="tip-top" className="icon-list-on-window" colorDinamico={this.props.coresLetraSecundario} backgroundBotao={this.props.corBotaoPadrao} onClick={() => {
                        if (this.props.data.status === 'Fechado') {
                            toastr.error('Error', 'Chat encerrado')
                        } else {
                            //this._toggleModalRespostaPadrao()
                            this.props.modificaModalRespostaPadrao(!this.props.modalRespostaPadrao)
                        }
                    }} />
                    /*  <i
                     onClick={() => { if (this.props.data.status === 'Fechado') { } else { this.salvarComentario() } }}
                     className="icon-send2" style={{ fontSize: 20, color: '#383838', marginLeft: 10, cursor: this.props.data.status === 'Fechado' ? 'no-drop' : 'pointer' }} /> */

                }
                {this.props.loadingEnviandoAudioChat == true && <ClipLoader
                    css={{ marginLeft: 10, }}
                    style={{ marginLeft: 10, }}
                    size={21}
                    color={'#383838'}
                    loading={true}
                />}
                {this.props.loadingEnviandoAudioChat == false && this.renderMicrophoneRecording()}
                {this.state.statusAudio !== 'gravando' && this.state.statusAudio !== 'gravacao' && <i
                    onClick={() => { if (this.props.data.status === 'Fechado') { } else { this.salvarComentario() } }}
                    className="icon-send2" style={{ fontSize: 20, color: '#383838', marginLeft: 10, cursor: this.props.data.status === 'Fechado' ? 'no-drop' : 'pointer' }} />}

            </div>
        )
    }

    renderConversasEncaminharContatos(item) {
        return (
            <ContainerChamado key={item.key} onClick={() => {
                this.handleInputChange(item)
            }} bordadinamica={this.props.coresLetraSecundario} backGroundDinamico={this.props.backgroundPrimeiro} type="button" >
                <ContainerInputCompartilhar>

                    <CheckBoxWrapper>
                        <RadioCheck
                            id={item.key}
                            colordinamico={this.props.coresLetraSecundario}
                            type="checkbox"
                            checked={this.state.arrayKeysClientesEnviaMensagemEncaminhada.includes(item.key) || this.state.dadosMensagemParaSerEncaminhada.filter(e => e.telefoneCliente === item.key).length > 0}
                            onChange={(e) => { }}
                            value={item.key}
                            disabled
                            backgroundinamico={this.props.corBotaoPadrao}
                        />

                        <CheckBoxLabelRadio htmlFor={item.key} corLetraDinamico={this.props.coresLetraPadrao} backgrounddinamico={this.props.backgroundPrimeiro} />
                        <i className="icon-checkwpp2" style={{ fontSize: 13, color: this.state.arrayKeysClientesEnviaMensagemEncaminhada.includes(item.key) === true || this.state.dadosMensagemParaSerEncaminhada.filter(e => e.telefoneCliente === item.key).length > 0 ? this.props.letraBotaoPadrao : this.props.backgroundPrimeiro, zIndex: 999, position: 'absolute', right: 0, bottom: 2 }} />
                    </CheckBoxWrapper>
                </ContainerInputCompartilhar>
                <ContainerImagem>
                    {this.renderImagemConversasEmAtendimentoEmcaminharContatos(item)}
                    {/*  <img src={item.urlImagem === null || item.urlImagem === '' || item.urlImagem === undefined ? imgUser : item.urlImagem} alt="foto" style={{ height: 40, width: 40, borderRadius: 50, backgroundColor: 'rgba(159,173,183, 0.3)' }} /> */}
                </ContainerImagem>
                <ContainerDadosChamado bordadinamica={this.props.coresLetraSecundario}>
                    <ContaineDadosChamadosPrincipal>
                        <SubContainerIconeEFraseClienteAtendente >
                            <TextCliente corLetraDinamica={this.props.coresLetraPadrao}>{item.nome}</TextCliente>
                        </SubContainerIconeEFraseClienteAtendente>
                        <SubContainerIconeEFraseClienteAtendente >
                            <TextEmpresa data-tip={this.state.lastMessage} data-for="tip-top" corLetraDinamica={this.props.coresLetraSecundario}>{item.phone}</TextEmpresa>
                        </SubContainerIconeEFraseClienteAtendente>
                    </ContaineDadosChamadosPrincipal>

                </ContainerDadosChamado>


            </ContainerChamado>
        )
    }
    downloadImage(url, name) {


        this.props.modificaUrlPreviewImage(url)
        this.props.modificaModalPreviewImage(true)
        this.props.modificaNamePreviewImage(name)
        /*  let link = document.createElement("a");
       link.setAttribute("href", url);
       link.setAttribute("download", name);
       link.setAttribute("target", "_blank");
       link.style.visibility = 'hidden';
       link.style.display = 'none';
       document.body.appendChild(link);
       link.click();
       document.body.removeChild(link);   */
        /*   function savefile() {
             
             saveAs(url, name)
         }
 
         // alert('${url}');
 
 
         let arquivo = { urlDownload: url }
         const image_window = window.open("", "_blank")
         image_window.document.write(`
               <html>
                 <head>
                 <meta charset="UTF-8" />
                 <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                 <title>Visualização Imagem</title>
                 <style type="text/css">
         #cabecalho{
             background-color: #383838;
             width: auto;
             color: white;
             height:8%;
             padding-left:20px;
             padding-top:10px;
             margin:0;
             display:flex;
             align-items:center;
             justify-content:flex-end;
         }
         
         #container{
             margin:0;
             padding:0;
         }
         #imagemPrincipal{
             height:89%;
             width: auto;
             padding:10px;
         }
         #imagemDownload{
             height:40px;
             width: 40px;
             padding:10px; 
         }
         #containerImagemPrincipal{
             height:89%;
             display:flex;
             align-items:center;
             justify-content:center;
         }
         #ancoraImagem{
             cursor:pointer;
         }
         
     </style>   
                 </head>
                 <body id="container">
                 <div id="container">
                    <div id="cabecalho">
                    
                    <div id="ancoraImagem">
                    <button onclick="${savefile()}">Download</button>
                     </div>
                    </div>
                    <div id="containerImagemPrincipal">
                      <img src="${url}" alt="Imagem" id="imagemPrincipal" />
                   </div>
                   
                 </div>
                
                 </body>
               </html>
         `);  */


        /*  const a = document.createElement('a');
        a.href = url;
        a.download = name;
        a.click();
        a.target = "_blank";
        
        window.URL.revokeObjectURL(url);  */
        /*  const a = document.createElement('a');
        a.href = url;
        a.download = name;
        a.click();
        a.target = "_blank";
        window.open(url);  */
    };
    salvarComentario() {
        let comentario = this[this.props.data.key].value;
        if (comentario.trim() == '') {
            /*  toastr.error('Error', 'Digite uma mensagem'); */
        } else {
            this.props.salvarComentario('', false, comentario, [], 1, this.props.data.key, this.props.data.dataAbertura, this.props.premium, this.props.data.keyAtendente, this.props.data, true, this.props.configWhatsapp, this.state.viewResponder, this.state.idResponder, this.state.commentResponder,
                this.state.circleColorResponder, this.state.commentResponderType, this.state.atendenteResponder, this.props.desativarAssistenteVirtualBotWpp, this.props.mostrarNomeAtendenteWpp);
            let input = this[this.props.data.key];
            input.value = '';
            this.setState({ defaultValeInput: '' })
            input.focus();
            input.style = { height: 40 };
            this.zeraMensagemResponder();
        }

    }
    addEmoji = (event, emojiObject) => {

        /* this.props.alteraComentarioChat(this.props.comentarioChat + event.native); */
        let input = this[this.props.data.key];
        input.value = this[this.props.data.key].value + event.native;
        input.focus();
    };
    searchDebounce = _.debounce((value) => {
        this.updateSearchEncaminhar(value);
    }, 500);

    updateSearchEncaminhar(value) {
        this.searchTextContatosEncaminharMensagem(value)
    }
    searchTextContatosEncaminharMensagem = async (digitoPesquisa) => {
        let text = digitoPesquisa.toLowerCase();
        let trucksEmAtendimento = this.props.chamadosEmAtendimentoOnline;
        let filteredNameEmAtendimento = trucksEmAtendimento.filter((item) => {
            return item.cliente.toLowerCase().match(text)
        })
        if (!text || text === '') {

            this.setState({
                noDataAtendimentoEncaminharMensagem: false
            })
            /*  this.setState({
               data: initial
             }) */
        } else if (filteredNameEmAtendimento.length == 0) {
            // set no data flag to true so as to render flatlist conditionally

            this.setState({
                noDataAtendimentoEncaminharMensagem: true
            })
        } else if (Array.isArray(filteredNameEmAtendimento)) {


            this.setState({
                noDataAtendimentoEncaminharMensagem: false,
                resultadosPesquisaAtendimentosEncaminharMensagem: filteredNameEmAtendimento
            })
        }


        /*   let text = digitoPesquisa.toLowerCase(); */
        const contactsSearch = await loadContacts(digitoPesquisa, 0);
        const mergedContacts = [...this.props.allContactsWhatsapp, ...contactsSearch];
        const contacts = removeItemsDuplicateFromListByValue(mergedContacts, 'phone');
        let filteredNameContacts = contacts.filter((item) => {
            return item.nome.toLowerCase().match(text) || item.phone.toLowerCase().match(text)
        })
        if (!text || text === '') {

            this.setState({
                noDataContactsEncaminharMensagem: false
            })
            /*  this.setState({
               data: initial
             }) */
        } else if (filteredNameContacts.length == 0) {
            // set no data flag to true so as to render flatlist conditionally

            this.setState({
                noDataContactsEncaminharMensagem: true
            })
        } else if (Array.isArray(filteredNameContacts)) {


            this.setState({
                noDataContactsEncaminharMensagem: false,
                resultadosPesquisaContactsEncaminharMensagem: filteredNameContacts
            })
        }

        /*   let text = digitoPesquisa.toLowerCase(); */
        let trucksEmEspera = this.props.chamadosEmEsperaAtendimentoOnline;
        let filteredNameEmEspera = trucksEmEspera.filter((item) => {
            return item.cliente.toLowerCase().match(text)
        })
        if (!text || text === '') {

            this.setState({
                noDataEsperaEncaminharMensagem: false
            })
            /*  this.setState({
               data: initial
             }) */
        } else if (filteredNameEmEspera.length == 0) {
            // set no data flag to true so as to render flatlist conditionally

            this.setState({
                noDataEsperaEncaminharMensagem: true
            })
        } else if (Array.isArray(filteredNameEmEspera)) {


            this.setState({
                noDataEsperaEncaminharMensagem: false,
                resultadosPesquisaEsperaEncaminharMensagem: filteredNameEmEspera
            })
        }
        this.props.ativaDesativaLoadingCarregaContatosWppExport(false);
    }
    renderImagemConversasEmAtendimentoEmcaminhar(item) {

        return (
            <img src={item.urlImagemNuvem === null || item.urlImagemNuvem === '' || item.urlImagemNuvem === undefined ? imgUser : item.urlImagemNuvem} alt="foto" style={{ height: 40, width: 40, borderRadius: 50, backgroundColor: 'rgba(159,173,183, 0.3)' }} />
        )

    }
    renderImagemConversasEmAtendimentoEmcaminharContatos(item) {

        let urlImagem = imgUser;
        if (item.urlImagem === null || item.urlImagem === undefined || item.urlImagem === '') {
            const primeiraLetra = item.nome.substring(0, 1).trim().toLowerCase();
            urlImagem = this.verificaSeEhAlphabeto(primeiraLetra);
        } else {
            urlImagem = item.urlImagem;
        }
        return (
            <img src={urlImagem} alt="foto" style={{ height: 40, width: 40, borderRadius: 50, backgroundColor: 'rgba(159,173,183, 0.3)' }} />
        )

    }
    renderResponderMensagem(item) {
        //onReplyClick={() => { this.renderResponderMensagem(item) }}
        let input = this[this.props.data.key];
        input.focus();
        if (item.tipo === 'mensagem') {
            this.setState({ commentResponderType: 'texto', commentResponder: item.description, idResponder: item.idMensagem, circleColorResponder: item.circleColor, viewResponder: true, atendenteResponder: item.title })
        } else if (item.tipo === 'location') {
            this.setState({ commentResponderType: 'location', commentResponder: 'Localização', idResponder: item.idMensagem, circleColorResponder: item.circleColor, viewResponder: true, atendenteResponder: item.title })
        } else if (item.tipo === 'vcard') {
            this.setState({ commentResponderType: 'contato', commentResponder: 'Contato', idResponder: item.idMensagem, circleColorResponder: item.circleColor, viewResponder: true, atendenteResponder: item.title })
        } else {
            if (item.tipoArquivo.includes('image') && !item.tipoArquivo.includes('corel')) {
                this.setState({ commentResponderType: 'imagem', commentResponder: 'Imagem', idResponder: item.idMensagem, circleColorResponder: item.circleColor, viewResponder: true, atendenteResponder: item.title })
            } else if (item.tipoArquivo.includes('video') && item.audio !== true || item.tipoArquivo.includes('mp4') && item.audio !== true) {
                this.setState({ commentResponderType: 'video', commentResponder: 'Vídeo', idResponder: item.idMensagem, circleColorResponder: item.circleColor, viewResponder: true, atendenteResponder: item.title })
            } else if (item.audio == true) {
                this.setState({ commentResponderType: 'audio', commentResponder: 'Mensagem de Voz', idResponder: item.idMensagem, circleColorResponder: item.circleColor, viewResponder: true, atendenteResponder: item.title })
            } else {
                this.setState({ commentResponderType: 'arquivo', commentResponder: 'Arquivo', idResponder: item.idMensagem, circleColorResponder: item.circleColor, viewResponder: true, atendenteResponder: item.title })
            }
        }
    }
    handleInputChange(item) {
        /* setTimeout(() => { */
        let arrayKeys = [];
        let dadosMensagem = this.state.dadosMensagemParaSerEncaminhada;
        let arrayAtual = this.state.arrayKeysClientesEnviaMensagemEncaminhada;
        if (!arrayAtual.includes(item.key)) {
            if (dadosMensagem.length >= 5) {
                toastr.warning('Você só pode compartilhar com até 5 conversas')
            } else {
                arrayKeys.push(item.key)
                dadosMensagem.push(item)
                let newArray = arrayAtual.concat(arrayKeys);
                this.setState({ arrayKeysClientesEnviaMensagemEncaminhada: newArray, dadosMensagemParaSerEncaminhada: dadosMensagem })


            }
        } else {
            const index = arrayAtual.indexOf(item.key);
            if (index > -1) {
                arrayAtual.splice(index, 1);
            }


            for (let i = 0; i < dadosMensagem.length; i++) {
                if (dadosMensagem[i].key === item.key) {
                    dadosMensagem.splice(i, 1);
                }
            }
            this.setState({ arrayKeysClientesEnviaMensagemEncaminhada: arrayAtual, dadosMensagemParaSerEncaminhada: dadosMensagem })


        }
        /* }, 200); */
    }
    _toggleModalEncaminharMensagem() {
        this.setState({ modalEncaminharMensagem: !this.state.modalEncaminharMensagem })

        if (!this.state.modalEncaminharMensagem === true) {

            this.props.getAllContatcsWpp(this.props.configWhatsapp, this.props.allClientes)
        } else {

            this.props.enviaContatosParaReducerExport([])
        }
    }
    renderTextArquivosMensagemRespondidas(item) {
        if (item.commentResponderType === 'imagem') {
            return <p><i className="icon-imagewpp" style={{ fontSize: 13, color: '#5a5a5a' }} /> Imagem</p>
        } else if (item.commentResponderType === 'video') {
            return <p><i className="icon-video-camera" style={{ fontSize: 13, color: '#5a5a5a' }} /> Vídeo</p>
        } else if (item.commentResponderType === 'location') {
            return <p><i className="icon-localization" style={{ fontSize: 13, color: '#5a5a5a' }} /> Localização</p>
        } else if (item.commentResponderType === 'contato') {
            return <p><i className="icon-man-user" style={{ fontSize: 13, color: '#5a5a5a' }} /> Contato</p>
        } else if (item.commentResponderType === 'audio') {
            return <p><i className="icon-microphone" style={{ fontSize: 13, color: '#5a5a5a' }} /> Mensagem de Voz</p>
        } else if (item.commentResponderType === 'arquivo') {
            return <p><i className="icon-documentwpp" style={{ fontSize: 13, color: '#5a5a5a' }} /> Arquivo</p>
        }
        else if (item.commentResponderType === 'link' && item.commentResponderTypeArquivo.includes('image') && !item.commentResponderTypeArquivo.includes('corel')) {
            return <p><i className="icon-imagewpp" style={{ fontSize: 13, color: '#5a5a5a' }} /> Imagem</p>
        } else if (item.commentResponderType === 'link' && item.commentResponderTypeArquivo.includes('video') && item.commentResponderIsAudio !== true || item.commentResponderTypeArquivo.includes('mp4') && item.commentResponderIsAudio !== true) {
            return <p><i className="icon-video-camera" style={{ fontSize: 13, color: '#5a5a5a' }} /> Vídeo</p>
        }
        else if (item.commentResponderType === 'link' && item.commentResponderIsAudio === true) {
            return <p><i className="icon-microphone" style={{ fontSize: 13, color: '#5a5a5a' }} /> Mensagem de Voz</p>
        }
        else if (item.commentResponderType === 'link' && !item.commentResponderTypeArquivo.includes('image') && !item.commentResponderTypeArquivo.includes('corel') && !item.commentResponderTypeArquivo.includes('video') && item.commentResponderIsAudio === false) {
            return <p><i className="icon-documentwpp" style={{ fontSize: 13, color: '#5a5a5a' }} /> Arquivo</p>
        }
        else {
            return <p>{item.mensagemResposta}</p>
        }
    }
    renderDescriptionMessageBox(item) {
        if (item.title == 'Avaliar Este Atendimento') {

            return (
                <div key={item.key}>

                    <TextMensagem corLetraDinamica='#000'>
                        {item.description}
                    </TextMensagem>
                    <TextAvaliar corPrincipalChat={this.props.corPrincipalChat} onClick={() => {
                        toastr.error('Error', 'Somente cliente pode avaliar o atendimento');
                    }} corLetraDinamica={this.props.coresLetraPadrao}>{item.title}</TextAvaliar>

                </div>
            )


        } else {
            if (item.description.includes('Conversa Iniciada por ')) {
                return null
            } else {
                //onReplyClick={() => { this.renderResponderMensagem(item) }}
                return (
                    <div key={item.key}>
                        <MessageBox

                            {...item}

                            /*   ref={scrollTo} */
                            id={item.key}
                            reply={this.renderMensagemReply(item)}
                            onReplyMessageClick={() => { this.renderClickButtonReply(item) }}
                            onReplyClick={() => { this.renderResponderMensagem(item) }}
                            onForwardClick={() => {
                                this._toggleModalEncaminharMensagem();
                                let mensagemParaSerEncaminhada2 = [];
                                item['keyChamado'] = this.props.data.key;
                                mensagemParaSerEncaminhada2.push(item)
                                this.setState({ mensagemParaSerEncaminhada: mensagemParaSerEncaminhada2 })
                            }}
                            onRemoveMessageClick={() => { this.setState({ modalMensagemExcluida: true, dadosMensagemExcluida: item }) }}
                            //onClick={() => {  }}
                            //onOpen={() => {  }}
                            position={item.circleColor === '#00FF7F' ? "right" : "left"}
                            type={'text'}
                            /*  title={item.title.includes('Atendimento criado por') ? item.title.replace('Atendimento criado por: ', '') : item.title} */
                            title={(
                                <div
                                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                >
                                    {item.title.includes('Atendimento criado por') ? item.title.replace('Atendimento criado por: ', '') : item.title}
                                    {this.state.visualizaMensagemExcluida === true && item.deleteMessage === true && <span onClick={() => { this.setState({ visualizaMensagemExcluida: false }) }} style={{ color: '#EC6A69', fontSize: 10, textDecorationLine: 'underline', marginLeft: 10, fontStyle: 'italic', cursor: "pointer" }}>Ocultar</span>}
                                </div>
                            )}

                            titleColor={item.circleColor === '#00FF7F' ? '#16cb9c' : "#39a3f4"}
                            focus={item.idMensagem === this.state.focusMessageBox ? true : false}
                            onMessageFocused={() => { setTimeout(() => { this.setState({ focusMessageBox: 'vazio' }); }, 1000); }}
                            text={
                                (
                                    <Linkify
                                        componentDecorator={(decoratedHref, decoratedText, key) => (
                                            <a style={{ fontSize: 15, color: '#39a3f4' }} target="blank" href={decoratedHref} key={key}>
                                                {decoratedText}
                                            </a>
                                        )}
                                    >
                                        {item.description}
                                    </Linkify>
                                )
                            }
                            copiableDate
                            dateString={item.dataHoraAtual}
                            forwarded={item.statusMessage === 'waiting' || item.deleteMessage === true ? false : true}
                            replyButton={item.statusMessage === 'waiting' || item.deleteMessage === true ? false : true}
                            removeButton={item.circleColor === '#39a3f4' || item.deleteMessage === true || this.props.isAdmin === false || item.statusMessage === 'waiting' ? false : true}
                            status={item.circleColor === '#39a3f4' ? null : item.statusMessage} //(waiting, sent, received, read)
                        />
                    </div>
                )
            }
        }
    }

    _toggleViewEmoji() {
        this.setState({ viewEmoji: !this.state.viewEmoji })
    }
    moveCaretAtEnd(e) {
        var temp_value = e.target.value;
        e.target.value = '';
        e.target.value = temp_value;

    }
    setaMensagemLidaFocus(objetoChamado) {

        if (objetoChamado) {
            if (objetoChamado.lastmessageChatCount > 0) {
                firebase.database().ref(`${objetoChamado.chaveUnica}/chamados/${objetoChamado.key}`).update({
                    lastmessageChatCount: 0
                })

                if (this.props.configWhatsapp !== undefined && this.props.configWhatsapp.length > 0 && this.props.atendimentosPorKeyReducerChat.chamadoAbertoPorWpp === true) {
                    let apiKey = this.props.configWhatsapp[0].apiKey;
                    let urlApi = this.props.configWhatsapp[0].urlApi;
                    let sessionclient = this.props.configWhatsapp[0].sessionclient;
                    let statusConnect = this.props.configWhatsapp[0].statusConnect;

                    if (statusConnect === 'connected' || statusConnect === 'chatsAvailable' || statusConnect === 'isSynced' ||
                        statusConnect === "isConnected" || statusConnect === "isLogged" || statusConnect === 'isSync') {
                        if (urlApi.includes("teste") || urlApi.includes("socket")) {
                            this.props.setaMensagensComoLidaWhatsAppSocket(this.props.atendimentosPorKeyReducerChat, apiKey, sessionclient, urlApi);
                        }

                    }

                }
            }
        }
    }
    transformaMinutosEmSegundos(s) {

        function duas_casas(numero) {
            if (numero <= 9) {
                numero = "0" + numero;
            }
            return numero;
        }

        let hora = duas_casas(Math.trunc(s / 3600));
        let minuto = duas_casas(Math.trunc((s % 3600) / 60));
        let segundo = duas_casas((s % 3600) % 60);

        let formatado = minuto + ":" + segundo;

        return formatado;
    }


    _toggleModalGravarAudio() {
        this.handleRest()
        this.setState({ viewMicrophone: !this.state.viewMicrophone });
    }
    renderTextGravacaoAudio() {
        if (this.state.statusAudio == 'gravar') {
            return (
                <p style={{
                    color: this.props.coresLetraPadrao,
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: 18,
                    fontFamily: 'Source Sans Pro'
                }}>Pressione no Microphone para Gravar</p>
            )
        } else if (this.state.statusAudio == 'gravando') {
            return (
                <TextGravandoAudio corDinamico={this.props.coresLetraPadrao} >GRAVANDO</TextGravandoAudio>
            )
        } else {
            //play audio
            return (
                <p style={{
                    color: this.props.coresLetraPadrao,
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: 18,
                    fontFamily: 'Source Sans Pro'
                }}>Gravação</p>
            )
        }

    }
    renderBotaoGravarAudio() {

        if (this.state.statusAudio == 'gravar') {
            return (
                <div style={{ display: 'flex', height: 100, justifyContent: 'center', alignItems: 'center', paddingBottom: 5 }}>
                    <div style={{
                        display: 'flex', height: 60, width: 60, borderRadius: 50, backgroundColor: '#F50257', justifyContent: 'center', alignItems: 'center', cursor: 'pointer'
                    }} onClick={() => this.onStartRecord()}>
                        <i className="icon-microphone" style={{ fontSize: 28, color: '#FFF' }} />
                    </div>
                </div>
            )
        } else if (this.state.statusAudio == 'gravando') {

            return (
                <div style={{ display: 'flex', height: 100, justifyContent: 'center', alignItems: 'center', paddingBottom: 5 }}>

                    <div style={{
                        display: 'flex', height: 60, width: 60, borderRadius: 50, backgroundColor: this.props.corSegundoBotaoPadrao, justifyContent: 'center', alignItems: 'center',
                        cursor: 'pointer'
                    }} onClick={() => {
                        /*     setTimeout(() => {
                                this.setState({
                                    recordSecs: 0,
                                    statusAudio: 'gravacao'
                                });
                            }, 100);
                            AudioManager.stopRecord(); */


                        this.onStopRecord(false);

                    }}>
                        <i className="icon-stop-button-black-rounded-square" style={{ fontSize: 19, color: this.props.letraBotaoPadrao }} />
                    </div>
                </div>
            )
        } else {
            //play audio

            return (
                <div style={{ height: 300, display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                        <audio src={this.state.blobURL} controls />
                    </div>
                    <div style={{ display: 'flex', height: 65, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around', height: 45, width: 90, background: this.props.corSegundoBotaoPadrao, borderRadius: 10, cursor: 'pointer' }} onClick={() => {

                            this.onStopRecord(true)
                        }}>
                            {/*   <Icon name="rubbish-bin" size={15} color={'#EC6A69'} style={{ marginRight: 3 }}
                            /> */}
                            {/*  <i className="icon-download" style={{ fontSize: 15, color: this.props.corBotaoPadrao }} /> */}
                            <p style={{ color: this.props.letraBotaoPadrao, fontSize: 17, fontWeight: 'bold' }}>Limpar</p>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around', height: 45, width: 90, background: this.props.corBotaoPadrao, borderRadius: 10, cursor: 'pointer' }} onClick={() => { this.enviarAudioFirebase() }}>
                            {/*      <i className="icon-download" style={{ fontSize: 15, color: this.props.corBotaoPadrao }} /> */}
                            <p style={{ color: this.props.letraBotaoPadrao, fontSize: 17, fontWeight: 'bold' }}>Enviar</p>
                        </div>
                    </div>
                    {/* <TouchableOpacity
                        style={styles.viewBarWrapper}
                        onPress={(e) => { this.onStatusPress(e) }}
                    >
                        <View style={styles.viewBar}>
                            <View style={[styles.viewBarPlay, { width: playWidth }]} />
                        </View>
                    </TouchableOpacity>*/}


                </div >
            )
        }

    }
    renderBotaoGravarAudioEmEsperaParaIniciarAtendimento() {

        if (this.state.statusAudio == 'gravar') {
            return (
                <div style={{ display: 'flex', height: 100, justifyContent: 'center', alignItems: 'center', paddingBottom: 5 }}>
                    <div style={{
                        display: 'flex', height: 60, width: 60, borderRadius: 50, backgroundColor: '#F50257', justifyContent: 'center', alignItems: 'center', cursor: 'pointer'
                    }} onClick={() => this.onStartRecord()}>
                        <i className="icon-microphone" style={{ fontSize: 28, color: '#FFF' }} />
                    </div>
                </div>
            )
        } else if (this.state.statusAudio == 'gravando') {

            return (
                <div style={{ display: 'flex', height: 100, justifyContent: 'center', alignItems: 'center', paddingBottom: 5 }}>

                    <div style={{
                        display: 'flex', height: 60, width: 60, borderRadius: 50, backgroundColor: this.props.corSegundoBotaoPadrao, justifyContent: 'center', alignItems: 'center',
                        cursor: 'pointer'
                    }} onClick={() => {
                        /*     setTimeout(() => {
                                this.setState({
                                    recordSecs: 0,
                                    statusAudio: 'gravacao'
                                });
                            }, 100);
                            AudioManager.stopRecord(); */


                        this.onStopRecord(false);

                    }}>
                        <i className="icon-stop-button-black-rounded-square" style={{ fontSize: 19, color: this.props.letraBotaoPadrao }} />
                    </div>
                </div>
            )
        } else {
            //play audio

            return (
                <div style={{ height: 300, display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                        <audio src={this.state.blobURL} controls />
                    </div>
                    <div style={{ display: 'flex', height: 65, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around', height: 45, width: 90, background: this.props.corSegundoBotaoPadrao, borderRadius: 10, cursor: 'pointer' }} onClick={() => {

                            this.onStopRecord(true)
                        }}>
                            {/*   <Icon name="rubbish-bin" size={15} color={'#EC6A69'} style={{ marginRight: 3 }}
                            /> */}
                            {/*  <i className="icon-download" style={{ fontSize: 15, color: this.props.corBotaoPadrao }} /> */}
                            <p style={{ color: this.props.letraBotaoPadrao, fontSize: 17, fontWeight: 'bold' }}>Limpar</p>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around', height: 45, width: 90, background: this.props.corBotaoPadrao, borderRadius: 10, cursor: 'pointer' }} onClick={() => { this.enviarAudioFirebaseEmEsperaParaIniciarAtendimento() }}>
                            {/*      <i className="icon-download" style={{ fontSize: 15, color: this.props.corBotaoPadrao }} /> */}
                            <p style={{ color: this.props.letraBotaoPadrao, fontSize: 17, fontWeight: 'bold' }}>Enviar</p>
                        </div>
                    </div>
                    {/* <TouchableOpacity
                        style={styles.viewBarWrapper}
                        onPress={(e) => { this.onStatusPress(e) }}
                    >
                        <View style={styles.viewBar}>
                            <View style={[styles.viewBarPlay, { width: playWidth }]} />
                        </View>
                    </TouchableOpacity>*/}


                </div >
            )
        }

    }
    stopStream(stream) {
        stream.getTracks().forEach(track => track.stop());
    };
    async recordAudio() {
        return new Promise(async resolve => {

            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

            // const mediaRecorder = new MediaRecorder(stream, { mimeType: 'audio/ogg;codecs=opus' },workerOptions);
            const mediaRecorder = new MediaRecorder(stream, { mimeType: 'audio/ogg; codecs=opus' });
            let audioChunks = [];
            mediaRecorder.addEventListener('dataavailable', event => {

                audioChunks.push(event.data);
            });

            const start = () => {

                audioChunks = [];
                mediaRecorder.start();
            };

            const stop = () => new Promise(resolve => {
                mediaRecorder.addEventListener('stop', () => {

                    const audioBlob = new Blob(audioChunks, { type: 'audio/ogg; codecs=opus' });
                    let duracao = (this.state.recordSecs * 1000);
                    ysFixWebmDuration(audioBlob, duracao, { logger: false })
                        .then(function (fixedBlob) {
                            let blob = fixedBlob;


                            const audioUrl = URL.createObjectURL(blob);
                            const audio = new Audio(audioUrl);
                            const play = () => audio.play();
                            const pause = () => audio.pause();
                            // stream.getTracks().forEach(
                            // 	function(track){track.stop();
                            // });
                            resolve({ audioChunks, blob, audioUrl, play, pause });
                        });

                });



                /*  if (mediaRecorder.state !== "inactive") { */
                mediaRecorder.stop();
                /*  } */
                this.stopStream(stream)
                // mediaRecorder.stream.stop();

            });


            resolve({ start, stop });
        }).then((response) => {


            return response
        });
    }
    /*  async onStartRecordTeste() {
         this.setState({ isRecording: true, statusAudio: 'gravando' });
         let timer = setInterval(this.tick, 1000);
         this.setState({ timer });
         if (!recorder) {
             
             this.recordAudio().then((recorder2) => {
                 
                 
                 recorder = recorder2;
                 recorder.start();
             });
         } else {
             
             recorder.start();
         }
 
 
     } */
    async onStopRecordTeste(apenasLimpar) {
        if (apenasLimpar == true) {
            if (recorder) {
                let recorder = null;
                let audio = null;
                clearInterval(this.state.timer);
                this.setState({
                    blobURL: '',
                    isRecording: false,
                    recordSecs: 0,
                    blobAudioPuro: '',
                    statusAudio: 'gravar',
                    timer: null,
                    bufferAudio: ''
                });


            } else {
                clearInterval(this.state.timer);
                this.setState({
                    blobURL: '',
                    isRecording: false,
                    recordSecs: 0,
                    blobAudioPuro: '',
                    statusAudio: 'gravar',
                    timer: null,
                    bufferAudio: ''
                });
            }

        } else {

            if (!recorder) {

                this.recordAudio().then((recorder) => {


                    audio = recorder.stop();

                });
            }
            audio = await recorder.stop();



            let blob = audio.blob;
            const blobURL = audio.audioUrl



            clearInterval(this.state.timer);
            this.setState({
                blobURL,
                isRecording: false,
                //recordSecs: 0,
                blobAudioPuro: blob,
                statusAudio: 'gravacao',
                timer: null,
                // bufferAudio: buffer
            });
        }
        /*  let unixdateatual = Date.now();
         let name = `audioweb-${new Date().getTime()}.ogg`;
         let caminhoPadraoArquivo1 = firebase.storage().ref(`${"Q2WYv6EFRUd3vIEcCUBDeIlojt42"}/${"-MmhY6dZYNnVTzR0b2FZ"}/${"-MmhY6dZYNnVTzR0b2FZ"}/${name}`);
         let mime = 'audio/webm; codecs=opus';
 
         let blob = audio.audioBlob;
         caminhoPadraoArquivo1.put(blob).then(url => {
             url.ref.getDownloadURL().then(function (downloadURL) {
                 
                 const urlDownload = downloadURL;
                 
                 firebase.database().ref(`${"Q2WYv6EFRUd3vIEcCUBDeIlojt42"}/acoesChamados/${"MmhY6dZYNnVTzR0b2FZ"}/`).push({
                     tipo: "link",
                     titulo: "Sergio",
                     mensagem: name,
                     urlDownload: downloadURL,
                     tipoArquivo: "audio",
                     audio: true,
                     duracao: "00:03",
                     dataHoraAtual: unixdateatual,
                     dataAtualPostagem: unixdateatual,
                     time: unixdateatual,
                     lineColor: "#00FF7F",
                     circleColor: "#00FF7F",
                     visivelATodos: 1,
                     keyDeQuemAlterouChamado: "-MeAJZESciME47rfxVLD",
                     statusMessage: 'waiting',
                     idMensagemResponder: "",
                     possuiResposta: false,
                     mensagemResposta: "",
                     circleColorResponder: "",
                     commentResponderType: "",
                 })
                     .then((dadosAcoesChamado) => {
 
                         let typeFile = 'audio';
 
                         let data = {
                             numeroCliente: "5521997622412",
                             text: name,
                             session: `15`,
                             urlApi: "https://teste.dropdesk.app.br",
                             type: typeFile,
                             url: urlDownload,
                             keyAcoesChamado: dadosAcoesChamado.key,
                             keyChamado: "-MmhY6dZYNnVTzR0b2FZ",
                             chaveUnica: "Q2WYv6EFRUd3vIEcCUBDeIlojt42",
                             file: `${"Q2WYv6EFRUd3vIEcCUBDeIlojt42"}/${"-MmhY6dZYNnVTzR0b2FZ"}/${"-MmhY6dZYNnVTzR0b2FZ"}/${name}`,
                             mimeType: 'audio/ogg; codecs=opus',
                             responder: false,
                             idMensagemResponder: "",
                             nomeAtendente: "Sergio",
 
                             // url: 'http://www.orimi.com/pdf-test.pdf',
                         }
 
                         const options = {
                             method: 'POST',
                             headers: {
                                 'Content-Type': 'application/json; charset=utf-8',
                                 'Accept': 'application/json',
                             },
 
                             data: data,
                             url: url,
                         };
                         axios(options).then((response) => {
                             
                             
 
                         }).catch((err) => {
                             
                             
 
                         })
 
 
                     }).catch((err) => {
                         
                         
 
                     });
             })
         }) */
    }

    onStopRecord = (apenasLimpar) => {
        if (apenasLimpar == true) {
            if (this.state.isRecording) {
                Mp3Recorder.stop()
                    .getMp3()
                    .then(([buffer, blob]) => {
                        clearInterval(this.state.timer);
                        this.setState({
                            blobURL: '',
                            isRecording: false,
                            recordSecs: 0,
                            blobAudioPuro: '',
                            statusAudio: 'gravar',
                            timer: null,
                            bufferAudio: ''
                        });

                    }).catch((e) => { });
            } else {
                clearInterval(this.state.timer);
                this.setState({
                    blobURL: '',
                    isRecording: false,
                    recordSecs: 0,
                    blobAudioPuro: '',
                    statusAudio: 'gravar',
                    timer: null,
                    bufferAudio: ''
                });
            }

        } else {
            Mp3Recorder.stop()
                .getMp3()
                .then(([buffer, blob]) => {

                    //type: 'audio/mp3; code=opus',


                    const blobURL = URL.createObjectURL(blob)

                    clearInterval(this.state.timer);
                    this.setState({
                        blobURL,
                        isRecording: false,
                        //recordSecs: 0,
                        blobAudioPuro: blob,
                        statusAudio: 'gravacao',
                        timer: null,
                        bufferAudio: buffer
                    });
                }).catch((e) => { });
        }
    }
    onStartRecord = () => {
        /*   navigator.getUserMedia({ audio: true },
              () => {
                  
                  this.setState({ isBlocked: false });
                  if (this.state.isBlocked) {
                      
                  } else { */
        Mp3Recorder
            .start()
            .then((e) => {

                this.setState({ isRecording: true, statusAudio: 'gravando', streamAudioTeste: e });
                let timer = setInterval(this.tick, 1000);
                this.setState({ timer });
            }).catch((e) => console.error(e));
        /* } */
        /*   },
          () => {
              
              this.setState({ isBlocked: true })
          },
      ); */
    }
    handleRest() {
        const reset = {
            url: null,
            blob: null,
            chunks: null,
            duration: {
                h: null,
                m: null,
                s: null,
            }
        }
        this.setState({ audioDetails: reset });
    }
    renderConversasEncaminharFAtendimentos(item) {
        return (
            <ContainerChamado key={item.key} onClick={() => {
                this.handleInputChange(item)
            }} bordadinamica={this.props.coresLetraSecundario} backGroundDinamico={this.props.backgroundPrimeiro} type="button" >
                <ContainerInputCompartilhar>

                    <CheckBoxWrapper>
                        <RadioCheck
                            id={item.key}
                            colordinamico={this.props.coresLetraSecundario}
                            type="checkbox"
                            checked={this.state.arrayKeysClientesEnviaMensagemEncaminhada.includes(item.key) || this.state.dadosMensagemParaSerEncaminhada.filter(e => e.telefoneCliente === item.key).length > 0}
                            onChange={(e) => { }}
                            value={item.key}
                            disabled
                            backgroundinamico={this.props.corBotaoPadrao}
                        />

                        <CheckBoxLabelRadio htmlFor={item.key} corLetraDinamico={this.props.coresLetraPadrao} backgrounddinamico={this.props.backgroundPrimeiro} />

                        <i className="icon-checkwpp2" style={{ fontSize: 13, color: this.state.arrayKeysClientesEnviaMensagemEncaminhada.includes(item.key) === true || this.state.dadosMensagemParaSerEncaminhada.filter(e => e.telefoneCliente === item.key).length > 0 ? this.props.letraBotaoPadrao : this.props.backgroundPrimeiro, zIndex: 999, position: 'absolute', right: 0, bottom: 2 }} />


                    </CheckBoxWrapper>
                </ContainerInputCompartilhar>
                <ContainerImagem>
                    {this.renderImagemConversasEmAtendimentoEmcaminhar(item)}


                </ContainerImagem>
                <ContainerDadosChamado bordadinamica={this.props.coresLetraSecundario}>
                    <ContaineDadosChamadosPrincipal>
                        <SubContainerIconeEFraseClienteAtendente >
                            <TextCliente corLetraDinamica={this.props.coresLetraPadrao}>{item.cliente}</TextCliente>
                        </SubContainerIconeEFraseClienteAtendente>
                        <SubContainerIconeEFraseClienteAtendente >
                            <TextEmpresa data-tip={this.state.lastMessage} data-for="tip-top" corLetraDinamica={this.props.coresLetraSecundario}>{item.empresa}</TextEmpresa>
                        </SubContainerIconeEFraseClienteAtendente>
                    </ContaineDadosChamadosPrincipal>

                </ContainerDadosChamado>


            </ContainerChamado>
        )
    }
    tick = () => {
        this.setState({
            recordSecs: this.state.recordSecs + 1
        });
    }
    /*  onStopRecord = (apenasLimpar) => {
         if (apenasLimpar == true) {
             if (this.state.isRecording) {
                 Mp3Recorder.stop()
                     .getMp3()
                     .then(([buffer, blob]) => {
                         clearInterval(this.state.timer);
                         this.setState({
                             blobURL: '',
                             isRecording: false,
                             recordSecs: 0,
                             blobAudioPuro: '',
                             statusAudio: 'gravar',
                             timer: null,
                             bufferAudio: ''
                         });
 
                     }).catch((e) => );
             } else {
                 clearInterval(this.state.timer);
                 this.setState({
                     blobURL: '',
                     isRecording: false,
                     recordSecs: 0,
                     blobAudioPuro: '',
                     statusAudio: 'gravar',
                     timer: null,
                     bufferAudio: ''
                 });
             }
 
         } else {
             Mp3Recorder.stop()
                 .getMp3()
                 .then(([buffer, blob]) => {
 
                     
                     
 
                     const blobURL = URL.createObjectURL(blob)
                     
                     clearInterval(this.state.timer);
                     this.setState({
                         blobURL,
                         isRecording: false,
                         //recordSecs: 0,
                         blobAudioPuro: blob,
                         statusAudio: 'gravacao',
                         timer: null,
                         bufferAudio: buffer
                     });
 
                    
                     //  player.play();
 
                 }).catch((e) => );
         }
     } */
    enviarAudioFirebase() {
        //  this._toggleModalGravarAudio();
        let duracao = (this.state.recordSecs * 1000);
        let duracaoNan = Number.isNaN(duracao)
        if (duracaoNan == true) {
            duracao = 1000
        }
        let nameFileMp3 = `audioweb-${new Date().getTime()}.mp3`;
        let nameFileOgg = `audioweb-${new Date().getTime()}.ogg`;
        /*   const result = ffmpeg({
              MEMFS: [{ name: nameFileMp3, data: this.state.blobAudioPuro }],
              arguments: ['-i', nameFileMp3, '-vn', '-ab', '128k', '-ar', '44100', '-y', nameFileOgg],
          }); */
        //

        const file = new File([this.state.blobAudioPuro], `audioweb-${new Date().getTime()}.ogg`, {
            type: 'audio/ogg',
            lastModified: Date.now()
        });
        file.audio = true;
        file.duracao = duracao;

        let res = [file]


        if (res.length > 0) {

            if (this.props.premium == 0) {
                toastr.error('Recurso Premium', 'Este é um recurso PREMIUM, para fazer upload de arquivos e imagens, Assine a versão PREMIUM e libere esse e mais recursos!');
            } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
                toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
            }
            else {

                this.props.ativaDesativaLoadingEnviandoAudioChatExport(true)
                this.props.salvarComentario('tem arquivo sim', false, '', res, 1, this.props.data.key, this.props.data.dataAbertura, this.props.premium, this.props.data.keyAtendente, this.props.data, true, this.props.configWhatsapp, this.state.viewResponder, this.state.idResponder, this.state.commentResponder,
                    this.state.circleColorResponder, this.state.commentResponderType, this.state.atendenteResponder, this.props.desativarAssistenteVirtualBotWpp, this.props.mostrarNomeAtendenteWpp);
                this.onStopRecord(true);
                this.zeraMensagemResponder()

            }

        } else {
            toastr.error('Error ao Enviar Audio', 'Tente novamente');
        }
        //   let duracaoMilisegundos = this.milliseconds(this.state.audioDetails.duration.h, this.state.audioDetails.duration.m, this.state.audioDetails.duration.s)
        //  let duracao = duracaoMilisegundos;

    }
    enviarAudioFirebaseEmEsperaParaIniciarAtendimento() {
        this._toggleModalGravarAudio();



        let duracao = (this.state.recordSecs * 1000);
        let duracaoNan = Number.isNaN(duracao)
        if (duracaoNan == true) {
            duracao = 1000
        }
        const file = new File(this.state.bufferAudio, `audioweb-${new Date().getTime()}.mp3`, {
            type: this.state.blobAudioPuro.type,
            lastModified: Date.now()
        });
        file.audio = true;
        file.duracao = duracao;

        let res = [file]


        if (res.length > 0) {

            if (this.props.premium == 0) {
                toastr.error('Recurso Premium', 'Este é um recurso PREMIUM, para fazer upload de arquivos e imagens, Assine a versão PREMIUM e libere esse e mais recursos!');
            } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
                toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
            }
            else {
                /*    this.zeraMensagemResponder() */
                this.props.ativaDesativaLoadingEnviandoAudioChatExport(true);

                this.props.ativaDesativaLoadingIniciandoCOnversaContactsExport(true);
                firebase.database().ref(`${this.props.atendimentoEmEsperaSelecionado.chaveUnica}/acoesChamados/${this.props.atendimentoEmEsperaSelecionado.key}`).off("value");
                let arraySemChamadoAtendido = this.props.chamadosEmEsperaAtendimentoOnline.filter(el => el.key !== this.props.atendimentoEmEsperaSelecionado.key);
                this.props.enviaAtendimentoEmEsperaAtendimentoOnlineExport(arraySemChamadoAtendido);

                let arrayDadosParaComentar = ['tem arquivo sim', false, '', res, 1, this.props.atendimentoEmEsperaSelecionado.key, this.props.atendimentoEmEsperaSelecionado.dataAbertura, this.props.premium, this.props.atendimentoEmEsperaSelecionado.keyAtendente, this.props.atendimentoEmEsperaSelecionado, true, this.props.configWhatsapp, this.state.viewResponder, this.state.idResponder, this.state.commentResponder,
                    this.state.circleColorResponder, this.state.commentResponderType, this.state.atendenteResponder, this.props.desativarAssistenteVirtualBotWpp, this.props.mostrarNomeAtendenteWpp];

                let arrayDadosParaAtribuirChamado = [this.props.keyUsuarioLogado, this.props.atendimentoEmEsperaSelecionado.key, this.props.nome, this.props.atendimentoEmEsperaSelecionado.keySetor, this.props.atendimentoEmEsperaSelecionado, true, this.props.configWhatsapp, '', this.props.desativarAssistenteVirtualBotWpp];
                this.props.comentaEAtribuiAtendimento(arrayDadosParaAtribuirChamado, arrayDadosParaComentar);

                /*    this.props.atribuiAtendimentoAh(this.props.keyUsuarioLogado, this.props.atendimentoEmEsperaSelecionado.key, this.props.nome, this.props.atendimentoEmEsperaSelecionado.keySetor, this.props.atendimentoEmEsperaSelecionado, true, this.props.configWhatsapp); */
                this.props.enviaUltimaReeferenciaKeyEmEsperaExport('');
                this.props.enviaConversasCarregadasEmEsperaExport([]);
                this.props.enviaUltimaReeferenciaKeyEmAtendimentoExport('');
                this.props.enviaConversasCarregadasEmAtendimentoExport([]);
                this.props.limpaPesquisaConversa();
                setTimeout(() => {
                    this.props.onClickTabIndex();
                    this.zeraMensagemResponder();
                }, 500);
                /* this.props.salvarComentario('tem arquivo sim', false, '', res, 1, this.props.data.key, this.props.data.dataAbertura, this.props.premium, this.props.data.keyAtendente, this.props.data, true, this.props.configWhatsapp, this.state.viewResponder, this.state.idResponder, this.state.commentResponder,
                    this.state.circleColorResponder, this.state.commentResponderType, this.state.atendenteResponder);
                this.onStopRecord(true); */

            }

        } else {
            toastr.error('Error ao Enviar Audio', 'Tente novamente');
        }
        //   let duracaoMilisegundos = this.milliseconds(this.state.audioDetails.duration.h, this.state.audioDetails.duration.m, this.state.audioDetails.duration.s)
        //  let duracao = duracaoMilisegundos;

    }



    setInputValue(text, keyChamado) {

        let input = this[keyChamado];
        input.style.height = `${40}px`


        input.value = text;


        let inputStyle = input.style;
        let altura = input.scrollHeight;
        input.style.height = `${altura}px`
        input.focus();


        this.setState({ defaultValeInput: text })
    }
    scrollCheck = event => {



        let scrollInTop = event.target.scrollHeight + event.target.scrollTop;
        //    
        let arrayMensagens = this.state.conversasAtual;
        let arrayKeysSetarLido = [];
        this.setState({ scrollTop: event.target.scrollTop })
        if (event.target.scrollTop === 0) {
            this.props.enviaUltimaKeyReferenciaCOnversasCarregadasChatExport('');
            this.props.enviaConversasCarregadasEmAtendimentoExport([]);
            this.props.enviaUltimaReeferenciaKeyEmAtendimentoExport('');
            //Add in what you want here

            if (this.props.data.lastmessageChatCount > 0) {
                firebase.database().ref(`${this.props.data.chaveUnica}/chamados/${this.props.data.key}`).update({
                    lastmessageChatCount: 0
                })
                if (this.props.configWhatsapp !== undefined && this.props.configWhatsapp.length > 0 && this.props.atendimentosPorKeyReducerChat.chamadoAbertoPorWpp === true) {
                    let apiKey = this.props.configWhatsapp[0].apiKey;
                    let urlApi = this.props.configWhatsapp[0].urlApi;
                    let sessionclient = this.props.configWhatsapp[0].sessionclient;
                    let statusConnect = this.props.configWhatsapp[0].statusConnect;
                    if (statusConnect === 'connected' || statusConnect === 'chatsAvailable' || statusConnect === 'isSynced' ||
                        statusConnect === "isConnected" || statusConnect === "isLogged") {
                        if (urlApi.includes("teste") || urlApi.includes("socket")) {
                            this.props.setaMensagensComoLidaWhatsAppSocket(this.props.atendimentosPorKeyReducerChat, apiKey, sessionclient, urlApi);
                        }

                    }

                }
            }
            /*   for (let i = 0; i < arrayMensagens.length; i++) {
                  if (arrayMensagens[i].circleColor === "#39a3f4" && arrayMensagens[i].read !== true) {
                      arrayKeysSetarLido.push(arrayMensagens[i])
                  }
              }
              if (arrayKeysSetarLido.length > 0) {
                  
                  
                  this.setaMensagensLidas(arrayKeysSetarLido);
                  this.setState({ scrollTop: 0 });
                  let countNotifTela = this.props.countMensagensNaoLidas - 1;
                  this.props.modificaTitleTab(countNotifTela.toString())
              } else {
                  
                  this.setState({ scrollTop: 0 })
              } */

        }
        scrollInTop = (scrollInTop - 20)
        if (scrollInTop <= event.target.clientHeight && this.props.loadingCarregandoConversasChatAtual === false && this.props.ultimaKeyReferenciaConversasChatATual !== undefined) {


            let arrayConversas = [...this.state.conversasAtual]
            // arrayConversas = arrayConversas.reverse();

            //  
            let ultimaKey = this.props.ultimaKeyReferenciaConversasChatATual === '' ? arrayConversas[arrayConversas.length - 1].key : this.props.ultimaKeyReferenciaConversasChatATual;
            let keyAtendimento = this.props.data.key;
            this.props.listaConversasAnterioresChatAtual(ultimaKey, keyAtendimento, this.props.conversasCarregadasEmAtendimento);
        }

    };
    setaMensagensLidas(arrayMensagens) {

        if (arrayMensagens && arrayMensagens !== undefined && arrayMensagens.length > 0) {
            let arrayKeysSetarLido = []


            for (let i = 0; i < arrayMensagens.length; i++) {
                if (arrayMensagens[i].circleColor === "#39a3f4" && arrayMensagens[i].read !== true) {
                    arrayKeysSetarLido.push({ key: arrayMensagens[i].key })
                }
            }

            if (arrayKeysSetarLido.length > 0) {
                const promises = arrayKeysSetarLido.map(async (usuario) => {
                    this.setState({ setandoMensagensLidas: true })

                    return firebase.database().ref(`${this.props.data.chaveUnica}/acoesChamados/${this.props.data.key}/${usuario.key}/`).update({
                        read: true
                    })
                })
                Promise.all(promises).then(responses => {

                    this.setState({ setandoMensagensLidas: false })
                })
            }
        }
    }
    renderConteudo() {
        /*  
         
         
          */
        if (this.props.keyAtendimentoEmAtendimentoOnline === this.props.data.key) {
            return (
                <ContainerBatepapo onScroll={(event) => {
                    if (this.props.keyAtendimentoEmAtendimentoOnline === this.props.data.key) {
                        this.scrollCheck(event)
                    }
                }} bordaDinamica={this.props.coresLetraSecundario} backgroundinamico={this.props.backgroundPrimeiro}>
                    {/*  {this.props.keyAtendimentoEmAtendimentoOnline !== '' && */}
                    {this.props.data !== undefined &&
                        <MarcaDaguaBatePapo>
                            <Dropzone
                                multiple={true}
                                noKeyboard
                                maxSize={2e+7}
                                noClick
                                onDrop={acceptedFiles => this.escolheArquivoChamado(acceptedFiles)}>
                                {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject }) => {
                                    //
                                    if (isDragActive) {
                                        if (this.props.data.status == 'Fechado') {
                                            return (
                                                <div style={{
                                                    display: 'flex',
                                                    flex: 1,
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    padding: '20px',
                                                    borderWidth: 2,
                                                    borderRadius: 2,
                                                    borderColor: 'red',
                                                    borderStyle: 'dashed',
                                                    color: this.props.coresLetraPadrao,
                                                    background: this.props.bac,
                                                    outline: 'none',
                                                    transition: 'border .24s ease-in-out',
                                                    justifyContent: 'center',
                                                    /*  margin: 5, */
                                                }}  {...getRootProps()}>
                                                    {/*   <input {...getInputProps()} /> */}
                                                    <p style={{ color: this.props.coresLetraPadrao, textAlign: 'center', fontSize: 22 }}>Atendimento Finalizado</p>
                                                    <p style={{ color: this.props.coresLetraPadrao, textAlign: 'center', fontSize: 22, marginTop: 20 }}>Abra um novo atendimento para fazer upload de arquivos</p>
                                                </div>
                                            )
                                        } else if (this.props.data.status == 'Cancelado') {
                                            return (
                                                <div style={{
                                                    display: 'flex',
                                                    flex: 1,
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    padding: '20px',
                                                    borderWidth: 2,
                                                    borderRadius: 2,
                                                    borderColor: 'red',
                                                    borderStyle: 'dashed',
                                                    color: this.props.coresLetraPadrao,
                                                    background: this.props.backgroundPrimeiro,
                                                    outline: 'none',
                                                    transition: 'border .24s ease-in-out',
                                                    justifyContent: 'center',
                                                    /*  margin: 5, */
                                                }}  {...getRootProps()}>
                                                    {/*   <input {...getInputProps()} /> */}
                                                    <p style={{ color: this.props.coresLetraPadrao, textAlign: 'center', fontSize: 22 }}>Atendimento Cancelado</p>
                                                    <p style={{ color: this.props.coresLetraPadrao, textAlign: 'center', fontSize: 22, marginTop: 20 }}>Abra um novo atendimento para fazer upload de arquivos</p>
                                                </div>
                                            )
                                        } else {
                                            return (
                                                <div style={{
                                                    display: 'flex',
                                                    flex: 1,
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    padding: '20px',
                                                    borderWidth: 2,
                                                    borderRadius: 2,
                                                    borderColor: this.renderBordaDrag(isDragActive, isDragAccept, isDragReject),
                                                    borderStyle: 'dashed',
                                                    color: this.props.coresLetraPadrao,
                                                    background: this.props.backgroundPrimeiro,
                                                    outline: 'none',
                                                    transition: 'border .24s ease-in-out',
                                                    justifyContent: 'center',
                                                    /*  margin: 5, */
                                                }}  {...getRootProps()}>
                                                    {/*   <input {...getInputProps()} /> */}
                                                    <p style={{ color: this.props.coresLetraPadrao, textAlign: 'center', fontSize: 22 }}>Solte Arquivos para Anexar <i className="icon-clip2" style={{ color: this.props.coresLetraPadrao, fontSize: 21 }} /></p>
                                                    <p style={{ color: this.props.coresLetraPadrao, textAlign: 'center', fontSize: 22, marginTop: 20 }}>Tamanho Máximo de 20MB</p>
                                                </div>
                                            )
                                        }
                                    } else {
                                        return (

                                            /*  <DivTesteFlatList  {...getRootProps()}>
                                                 <input {...getInputProps()} /> */
                                            <>
                                                <ContainerFlatListBatePapo
                                                    backgrounddinamico={this.props.coresLetraSecundario}
                                                    {...getRootProps()} >
                                                    <input {...getInputProps()} />
                                                    {/*    {this.renderRefreshMaisAtendimentosEmAtendimento()}  */}
                                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column-reverse' }}>
                                                        <FlatList
                                                            list={this.state.conversasAtual}
                                                            renderItem={(item) => this.messageBox(item)}
                                                            renderWhenEmpty={() => {
                                                                return (
                                                                    <ContainerListaVazia>
                                                                        <ClipLoader
                                                                            css={{}}
                                                                            size={25}
                                                                            color={this.props.corBotaoPadrao}
                                                                            loading={true}
                                                                        />
                                                                    </ContainerListaVazia>
                                                                )
                                                            }}

                                                        />
                                                        <FlatList
                                                            list={this.props.conversasCarregadasEmAtendimento}
                                                            renderItem={(item) => this.messageBox(item)}
                                                            renderWhenEmpty={() => null}
                                                        /* hasMoreItems={this.props.referenciaUltimakeyUltimoChamadoEmAtendimento !== undefined ? true : false}
                                                        paginationLoadingIndicator={this.renderRefreshMaisAtendimentosEmAtendimento()}
                                                        paginationLoadingIndicatorPosition="center"
                                                        loadMoreItems={() => {  }} */
                                                        />
                                                    </div>
                                                    {this.renderRefreshMaisAtendimentosEmAtendimento()}


                                                </ContainerFlatListBatePapo>
                                                <ContainerInputDescricaoChamado
                                                    id="refdivInput"
                                                    backgroundDinamico={'#F0f0f7'}
                                                    corLetraDinamico={this.props.coresLetraPadrao}
                                                    corLetraSecundaria={this.props.coresLetraSecundario}
                                                    corBordaDinamica={this.props.corBotaoPadrao}
                                                    style={{
                                                        /*  flex: this.props.mostrarMaisDetalhesChamado === true ? 0 : 1, */

                                                    }}>
                                                    {this.state.viewResponder === true &&

                                                        <div style={{ display: 'flex', borderTopLeftRadius: 5, borderTopRightRadius: 5, background: '#FFF', width: '100%', padding: 12, flexDirection: 'row' }}>

                                                            <div style={{ display: 'flex', background: '#F0f0f7', width: '100%', borderRadius: 3, flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                                                                <div style={{ display: 'flex', flexDirection: 'row', flex: 1, alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                                                                    <div style={{ width: 5, height: '100%', borderTopLeftRadius: 3, borderBottomLeftRadius: 3, backgroundColor: this.state.circleColorResponder === '#00FF7F' ? '#16cb9c' : "#39a3f4" }}></div>
                                                                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'flex-start', justifyContent: 'flex-start', padding: 7 }}>
                                                                        <TextResponder style={{ color: this.state.circleColorResponder === '#00FF7F' ? '#16cb9c' : "#39a3f4" }}>{this.state.circleColorResponder === '#39a3f4' ? this.props.data.cliente : this.state.atendenteResponder}</TextResponder>
                                                                        {/*  </div>
                                                                            <div style={{ display: 'flex', flexDirection: 'row' }}> */}

                                                                        <DivTruncarTexto /* style={{ display: 'flex', height: 52, flex: 1, alignItems: 'center', justifyContent: 'flex-start' }} */>


                                                                            <TextResponder style={{ color: '#000' }}>
                                                                                {this.state.commentResponderType === 'imagem' && <i className="icon-imagewpp" style={{ fontSize: 14, color: '#000' }} />}
                                                                                {this.state.commentResponderType === 'video' && <i className="icon-video-camera" style={{ fontSize: 14, color: '#000' }} />}

                                                                                {this.state.commentResponderType === 'location' && <i className="icon-localization" style={{ fontSize: 14, color: '#000' }} />}
                                                                                {this.state.commentResponderType === 'contato' && <i className="icon-man-user" style={{ fontSize: 14, color: '#000' }} />}
                                                                                {this.state.commentResponderType === 'audio' && <i className="icon-microphone" style={{ fontSize: 14, color: '#000' }} />}
                                                                                {this.state.commentResponderType === 'arquivo' && <i className="icon-documentwpp" style={{ fontSize: 14, color: '#000' }} />}
                                                                                {this.state.commentResponder}</TextResponder>

                                                                        </DivTruncarTexto>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div style={{ display: 'flex', width: 40, justifyContent: 'center', alignItems: 'center' }}>
                                                                <i onClick={() => { this.zeraMensagemResponder() }} className="icon-close" style={{ fontSize: 13, color: '#000', cursor: 'pointer' }} />
                                                            </div>
                                                        </div>}
                                                    {/*    <div id="divInput" style={{ display: 'flex', flex: 1,zIndex:999 }}> */}
                                                    <Input

                                                        autofocus={true}
                                                        onFocus={(e) => { this.moveCaretAtEnd(e); this.setaMensagemLidaFocus(this.props.data) }}
                                                        key={this.props.data.key}
                                                        inputRef={ref => this[this.props.data.key] = ref}
                                                        ref={ref => this[`${this.props.data.key}input`] = ref}
                                                        /*  inputRef={ref => this[this.props.data.key]= ref} */
                                                        disabled={this.props.data.status === 'Fechado'}
                                                        onChange={(value) => {
                                                            let valorDigitado = value.target.value
                                                            /*   
                                                               */
                                                            if (valorDigitado === "/" && valorDigitado.length === 1) {
                                                                this.props.modificaModalRespostaPadrao(!this.props.modalRespostaPadrao)
                                                            } else {
                                                                if (this.props.modalRespostaPadrao === true) {
                                                                    this.props.modificaModalRespostaPadrao(!this.props.modalRespostaPadrao)
                                                                }
                                                            }
                                                            let input = this[this.props.data.key];
                                                            input.value = value.target.value;
                                                            this.setState({ defaultValeInput: value.target.value })
                                                            input.focus();
                                                        }}
                                                        defaultValue={this.state.defaultValeInput}
                                                        /* value={this.props.comentarioChat} */
                                                        inputStyle={{ borderRadius: 5 }}
                                                        minHeight={40}
                                                        maxHeight={400}
                                                        placeholder={this.props.data.status === 'Fechado' ? 'Atendimento Encerrado' : "Digite sua mensagem aqui"}
                                                        multiline={true}
                                                        onKeyDown={this._handleKeyDown}
                                                        rightButtons={this.renderBotoesDireito()}
                                                        leftButtons={
                                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                {this.renderIconeUploadArquivoChat()}
                                                                < IconeMostrarSenha style={{ cursor: this.props.data.status === 'Fechado' ? 'no-drop' : 'pointer' }} id="emojis-btn" disabled={this.props.data.status === 'Fechado'} data-tip={this.props.data.status === 'Fechado' ? 'Atendimento Encerrado' : "Enviar Emoji na Conversa"} data-for="tip-top" onClick={() => { if (this.props.data.status === 'Fechado') { } else { this._toggleViewEmoji() } }} colorDinamico={this.state.viewEmoji == true ? '#16cb9c' : '#383838'} bordaDinamica={this.props.corBotaoPadrao} className="icon-smiley" />
                                                            </div>
                                                        }
                                                    />
                                                    {/*   </div> */}
                                                    {this.state.viewEmoji == true && <Picker

                                                        emojiSize={21}
                                                        native
                                                        onSelect={this.addEmoji}
                                                        // onClick={this.addEmoji}
                                                        title='Selecione seu Emoji'
                                                        emoji='point_up'
                                                        showPreview={false}
                                                        autoFocus={false}

                                                        useButton
                                                        tooltip={false}
                                                        style={{ position: 'absolute', bottom: '55px', left: '40px', zIndex: 999 }}
                                                        i18n={{
                                                            search: 'Pesquisar emoji',
                                                            clear: 'Limpar',
                                                            notfound: 'Nenhum Emoji Encontrado',
                                                            skintext: 'Escolha seu tom de pele padrão',
                                                            categories: {
                                                                search: 'Resultados da pesquisa',
                                                                recent: 'Recentes',
                                                                smileys: 'Emojis & Pessoas',
                                                                people: 'Emojis & Pessoas',
                                                                nature: 'Animais & Natureza',
                                                                foods: 'Comidas & Bebidas',
                                                                activity: 'Atividades',
                                                                places: 'Viagens & Locais',
                                                                objects: 'Objetos',
                                                                symbols: 'Símbolos',
                                                                flags: 'Bandeiras',
                                                                custom: 'Custom',
                                                            }
                                                        }}
                                                    />}

                                                </ContainerInputDescricaoChamado>
                                            </>
                                            /*  </DivTesteFlatList> */

                                        )
                                    }
                                }}

                            </Dropzone>

                        </MarcaDaguaBatePapo>
                    }
                    <Modal focusTrapped={false} showCloseIcon={false} open={this.state.modalMensagemExcluida} onClose={() => { }} center>
                        <ContainerModalGravarAudio backgroundDinamico={this.props.backgroundPrimeiro}>
                            <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                                <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Apagar Mensagem</TextNovoAtendimento>
                                <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => {
                                    this.setState({ modalMensagemExcluida: false, dadosMensagemExcluida: {} })

                                }} className="icon-close" />
                            </ContainerIconeFecharModal>

                            {/* <Recorder
                            record={false}
                            title={"New recording"}
                            audioURL={this.state.audioDetails.url}
                            showUIAudio
                            hideHeader
                            handleAudioStop={data => this.handleAudioStop(data)}
                            handleOnChange={(value) => this.handleOnChange(value, 'firstname')}
                            handleAudioUpload={data => this.handleAudioUpload(data)}
                            handleRest={() => this.handleRest()}
                            handleAudioStart={() => {}} /> */}
                            <ContainerAtendentes>
                                <DivTextoReabrirAtendimento >
                                    <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Deseja realmente Apagar a mensagem?</TextNovoAtendimento>
                                </DivTextoReabrirAtendimento>
                                <DivInputMotivoCancelamento>

                                    <ButtonNaoCancelarAtendimento onClick={() => { this.setState({ modalMensagemExcluida: false, dadosMensagemExcluida: {} }) }} borderColorDinamico={this.props.corBotaoPadrao} backgroundBotao={this.props.backgroundPrimeiro}>
                                        <TextBotaoCancelarAtendimento colorDinamico={this.props.corBotaoPadrao}>Não</TextBotaoCancelarAtendimento>
                                    </ButtonNaoCancelarAtendimento>
                                    <ButtonCancelarAtendimento onClick={() => {


                                        let isLastMessage = this.state.dadosMensagemExcluida.key === this.state.conversasAtual[0].key ? true : false;
                                        this.props.deletaMensagemDropDesk(this.state.dadosMensagemExcluida, this.props.data.key, true, this.props.configWhatsapp, isLastMessage, this.props.data)
                                        this.setState({ modalMensagemExcluida: false, dadosMensagemExcluida: {} })

                                    }} backgroundBotao={this.props.corBotaoPadrao}>
                                        <TextBotaoCancelarAtendimento colorDinamico={this.props.letraBotaoPadrao}>Sim</TextBotaoCancelarAtendimento>
                                    </ButtonCancelarAtendimento>
                                </DivInputMotivoCancelamento>
                            </ContainerAtendentes>


                        </ContainerModalGravarAudio>
                    </Modal>
                    <Modal focusTrapped={false} showCloseIcon={false} open={this.state.viewMicrophone} onClose={() => { }} center>
                        <ContainerModalGravarAudio backgroundDinamico={this.props.backgroundPrimeiro}>
                            <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                                <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Gravar Áudio</TextNovoAtendimento>
                                <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => {
                                    this.onStopRecord(true)
                                    this._toggleModalGravarAudio()

                                }} className="icon-close" />
                            </ContainerIconeFecharModal>

                            {/* <Recorder
                            record={false}
                            title={"New recording"}
                            audioURL={this.state.audioDetails.url}
                            showUIAudio
                            hideHeader
                            handleAudioStop={data => this.handleAudioStop(data)}
                            handleOnChange={(value) => this.handleOnChange(value, 'firstname')}
                            handleAudioUpload={data => this.handleAudioUpload(data)}
                            handleRest={() => this.handleRest()}
                            handleAudioStart={() => {}} /> */}
                            <div style={{ backgroundColor: this.state.statusAudio == 'gravando' ? '#EC6A69' : this.props.backgroundPrimeiro }} >


                                {this.state.statusAudio !== 'gravacao' && this.state.statusAudio !== 'executandoAudio' && <div style={{ display: 'flex', height: 180, alignItems: 'center', justifyContent: 'center' }}>
                                    <p style={{
                                        color: this.props.coresLetraPadrao,
                                        fontSize: 45,
                                        fontWeight: '200',
                                        letterSpacing: 3,
                                    }}>{this.transformaMinutosEmSegundos(this.state.recordSecs)}</p>

                                </div>}
                                <div style={{
                                    display: 'flex',
                                    height: 50,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    marginLeft: 5,
                                }}>
                                    <div></div>
                                    {this.renderTextGravacaoAudio()}
                                    <div ></div>
                                </div>
                                {this.renderBotaoGravarAudio()}
                            </div>


                        </ContainerModalGravarAudio>
                    </Modal>
                    <Modal focusTrapped={false} showCloseIcon={false} open={this.state.modalEncaminharMensagem} onClose={() => { }} center>
                        <ContainerModalEncaminharMensagem backgroundDinamico={this.props.backgroundPrimeiro}>
                            <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                                <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Encaminhar Mensagem para</TextNovoAtendimento>
                                <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => { this._toggleModalEncaminharMensagem(); this.zeraListaContatosParaEncaminhar(); }} className="icon-close" />
                            </ContainerIconeFecharModal>
                            <DivPesquisaEncaminharMensagem backgrounddinamico={this.props.backgroundSegundo}>
                                <ContainerInputPesquisaConversaEncaminharMensagem
                                    corLetraDinamico={this.props.coresLetraPadrao}
                                    backgroundinamico={this.props.backgroundPrimeiro}
                                    placeholderColor={this.props.coresLetraPadrao}
                                    corBordaDinamica={this.props.corBotaoPadrao}
                                >
                                    <i data-tip={"Pesquisar pelo nome do cliente"} data-for="tip-top" onClick={() => { this.inputPesquisa.focus() }} className="icon-magnifier" style={{ fontSize: 13, color: this.props.coresLetraPadrao, cursor: 'pointer' }} />
                                    <InputPesquisaConversa
                                        ref={ref => this.inputPesquisa = ref}
                                        corLetraDinamico={this.props.coresLetraPadrao}
                                        backgroundinamico={this.props.backgroundPrimeiro}
                                        placeholderColor={this.props.coresLetraPadrao}
                                        corBordaDinamica={this.props.corBotaoPadrao}
                                        type="text"
                                        placeholder={this.props.configWhatsapp !== undefined && this.props.configWhatsapp.length > 0 ? 'Pesquisar conversas' : 'Pesquisar Atendimentos'}
                                        onChange={(value) => {
                                            this.setState({ pesquisaConversasOuContatosEncaminharMensagem: value.target.value });
                                            this.props.ativaDesativaLoadingCarregaContatosWppExport(true);
                                            this.searchDebounce(value.target.value);
                                        }}
                                        value={this.state.pesquisaConversasOuContatosEncaminharMensagem}
                                    />
                                    {this.state.pesquisaConversasOuContatosEncaminharMensagem !== '' && <i onClick={() => { this.setState({ pesquisaConversasOuContatosEncaminharMensagem: '' }); this.updateSearchEncaminhar('') }} className="icon-close" style={{ fontSize: 11, color: this.props.coresLetraPadrao, cursor: 'pointer', marginRight: 10 }} />}
                                </ContainerInputPesquisaConversaEncaminharMensagem>

                            </DivPesquisaEncaminharMensagem>
                            <ContainerAtendentes style={{ marginBottom: this.state.dadosMensagemParaSerEncaminhada.length > 0 ? 60 : 0 }}>
                                {this.props.chamadosEmAtendimentoOnline.length > 0 && this.state.noDataAtendimentoEncaminharMensagem === false && <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <p style={{ fontSize: 20, paddingLeft: 20, paddingTop: 20, paddingBottom: 20, fontWeight: 'bold', color: this.props.corBotaoPadrao }}>Conversas</p>
                                    {this.props.loadingCarregaChatsEmAtendimento === false && this.state.pesquisaConversasOuContatosEncaminharMensagem === '' && <FlatList
                                        list={this.props.chamadosEmAtendimentoOnline}
                                        renderItem={(item) => this.renderConversasEncaminharFAtendimentos(item)}
                                        /*    renderItem={(item) => <FlatListEmAtendimento data={item} key={item.key + item.alterado} />} */
                                        renderWhenEmpty={() => {
                                            return null

                                        }} />}
                                    {this.props.loadingCarregaChatsEmAtendimento === false && this.state.pesquisaConversasOuContatosEncaminharMensagem !== '' && this.state.noDataAtendimentoEncaminharMensagem === false && <FlatList
                                        list={this.state.resultadosPesquisaAtendimentosEncaminharMensagem}
                                        renderItem={(item) => this.renderConversasEncaminharFAtendimentos(item)}
                                        renderWhenEmpty={() => {
                                            return null

                                        }}
                                    />}
                                </div>}
                                {this.props.chamadosEmEsperaAtendimentoOnline.length > 0 && this.state.noDataEsperaEncaminharMensagem === false && <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <p style={{ fontSize: 20, paddingLeft: 20, paddingTop: 20, paddingBottom: 20, fontWeight: 'bold', color: this.props.corBotaoPadrao }}>Em Espera</p>
                                    {this.props.loadingAtendimentoViaChat === false && this.state.pesquisaConversasOuContatosEncaminharMensagem === '' && <FlatList
                                        list={this.props.chamadosEmEsperaAtendimentoOnline}
                                        renderItem={(item) => this.renderConversasEncaminharFAtendimentos(item)}
                                        /*    renderItem={(item) => <FlatListEmAtendimento data={item} key={item.key + item.alterado} />} */
                                        renderWhenEmpty={() => {
                                            return null

                                        }} />}
                                    {this.props.loadingAtendimentoViaChat === false && this.state.pesquisaConversasOuContatosEncaminharMensagem !== '' && this.state.noDataEsperaEncaminharMensagem === false && <FlatList
                                        list={this.state.resultadosPesquisaEsperaEncaminharMensagem}
                                        renderItem={(item) => this.renderConversasEncaminharFAtendimentos(item)}
                                        renderWhenEmpty={() => {
                                            return null

                                        }}
                                    />}
                                </div>}
                                {this.props.configWhatsapp !== undefined && this.props.configWhatsapp.length > 0 && this.state.noDataContactsEncaminharMensagem === false && <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <p style={{ fontSize: 20, marginLeft: 20, marginTop: 20, marginBottom: 20, fontWeight: 'bold', color: this.props.corBotaoPadrao }}>Contatos</p>

                                    {this.props.loadingCarregaContatosWpp === true && <p style={{ fontSize: 18, color: this.props.coresLetraPadrao, textAlign: 'center' }}>Carregando Contatos ...</p>}

                                    {this.state.pesquisaConversasOuContatosEncaminharMensagem === '' && this.props.loadingCarregaContatosWpp === false && <FlatList
                                        list={this.props.allContactsWhatsapp}
                                        renderItem={(item) => this.renderConversasEncaminharContatos(item)}
                                        renderWhenEmpty={() => {
                                            if (this.props.loadingCarregaContatosWpp === true) {
                                                return <p style={{ fontSize: 18, color: this.props.coresLetraPadrao, textAlign: 'center' }}>Carregando Contatos</p>
                                            } else {
                                                return <p style={{ fontSize: 18, color: this.props.coresLetraPadrao, textAlign: 'center' }}>Error ao Carregar Contatos</p>
                                            }

                                        }}

                                    />}
                                    {this.state.pesquisaConversasOuContatosEncaminharMensagem !== '' && this.props.loadingCarregaContatosWpp === false && this.state.noDataContactsEncaminharMensagem === false &&
                                        <FlatList
                                            list={this.state.resultadosPesquisaContactsEncaminharMensagem}
                                            renderItem={(item) => this.renderConversasEncaminharContatos(item)}
                                            renderWhenEmpty={() => {
                                                return null
                                            }}
                                        />}


                                </div>}
                                {this.state.dadosMensagemParaSerEncaminhada.length > 0 && <div style={{ display: 'flex', flexDirection: 'row', height: 60, width: 400, backgroundColor: this.props.backgroundSegundo, position: 'absolute', bottom: 0, center: 0, zIndex: 999, borderBottomRightRadius: 7, borderBottomLeftRadius: 7 }}>
                                    <div style={{ display: 'flex', width: 320, flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                                        <DivTruncarTextoEncaminharMensagem style={{}} >
                                            {this.state.dadosMensagemParaSerEncaminhada.reverse().map((item) => {
                                                return (
                                                    /*   <div > */
                                                    < p key={item.key} style={{ color: this.props.coresLetraPadrao, fontSize: 14, marginLeft: 5 }}>{item.cliente === undefined || item.cliente === null || item.cliente === '' ? this.state.dadosMensagemParaSerEncaminhada.length > 1 ? `${item.nome}, ` : item.nome : this.state.dadosMensagemParaSerEncaminhada.length > 1 ? `${item.cliente}, ` : item.cliente}</p>
                                                    /*  </div> */
                                                )
                                            })

                                            }
                                        </DivTruncarTextoEncaminharMensagem>
                                    </div>
                                    <div style={{ display: 'flex', width: 80, minWidth: 80, flexDirection: 'row', alignItems: 'center', justifyContent: 'center', zIndex: '998' }}>
                                        <ButtonEnviarMensagemEncaminhada onClick={() => {
                                            this.props.enviaMensagemEncaminhada(this.state.dadosMensagemParaSerEncaminhada, this.state.mensagemParaSerEncaminhada, this.props.nome, this.props.keyUsuarioLogado, this.props.configWhatsapp, this.props.desativarAssistenteVirtualBotWpp, this.props.mostrarNomeAtendenteWpp, this.props.atendimentosPorKeyReducerChat.telefoneCliente); this.zeraListaContatosParaEncaminhar()
                                        }} backgroundinamico={this.props.corBotaoPadrao} corLetraBotao={this.props.letraBotaoPadrao}>
                                            <i className="icon-send2" style={{ fontSize: 22, color: this.props.letraBotaoPadrao, cursor: 'pointer' }} />
                                        </ButtonEnviarMensagemEncaminhada>
                                    </div>
                                </div>}
                            </ContainerAtendentes>
                        </ContainerModalEncaminharMensagem>
                    </Modal>
                </ContainerBatepapo>
            )
        } else {
            return null;
        }
    }
    render() {
        return this.renderConteudo()
    }
}

const mapStateToProps = (state, ownProps) => {
    return (
        {
            backgroundPrimeiro: state.CoresPadraoSistema.backgroundPrimeiro,
            backgroundSegundo: state.CoresPadraoSistema.backgroundSegundo,
            backgroundMaisEscuro: state.CoresPadraoSistema.backgroundMaisEscuro,
            coresLetraPadrao: state.CoresPadraoSistema.coresLetraPadrao,
            corBotaoPadrao: state.CoresPadraoSistema.corBotaoPadrao,
            corSegundoBotaoPadrao: state.CoresPadraoSistema.corSegundoBotaoPadrao,
            coresLetraSecundario: state.CoresPadraoSistema.coresLetraSecundario,
            letraBotaoPadrao: state.CoresPadraoSistema.letraBotaoPadrao,
            corLetraDescricaoDosCampos: state.CoresPadraoSistema.corLetraDescricaoDosCampos,
            nomeRota: state.HeaderReducer.nomeRota,



            licensawebouapp: state.PremiumReducer.licensawebouapp,
            premium: state.PremiumReducer.premium,


            chamadosEmEsperaAtendimentoOnline: state.ChamadoReducer.chamadosEmEsperaAtendimentoOnline,
            atendimentoEmEsperaSelecionado: state.ChamadoReducer.atendimentoEmEsperaSelecionado,
            chamadosEmAtendimentoOnline: state.ChamadoReducer.chamadosEmAtendimentoOnline,
            keyUsuarioLogado: state.PerfilReducer.keyUsuarioLogado,
            nome: state.PerfilReducer.nome,




            keyAtendimentoEmAtendimentoOnline: state.ChamadoReducer.keyAtendimentoEmAtendimentoOnline,
            comentarioChat: state.ChamadoReducer.comentarioChat,
            textArquivoChat: state.ChamadoReducer.textArquivoChat,
            enviandoComentario: state.ChamadoReducer.enviandoComentario,
            keyAtendimentoEmEsperaAtendimentoOnline: state.ChamadoReducer.keyAtendimentoEmEsperaAtendimentoOnline,
            loadingAtendimentoViaChat: state.ChamadoReducer.loadingAtendimentoViaChat,
            loadingEnviandoAudioChat: state.ChamadoReducer.loadingEnviandoAudioChat,


            configWhatsapp: state.ChamadoReducer.configWhatsapp,

            allContactsWhatsapp: state.ChamadoReducer.allContactsWhatsapp,



            refreshControlCarregaMaisChamados: state.ChamadoReducer.refreshControlCarregaMaisChamados,

            loadingCarregaChatsEmAtendimento: state.ChamadoReducer.loadingCarregaChatsEmAtendimento,
            loadingCarregaContatosWpp: state.ChamadoReducer.loadingCarregaContatosWpp,


            referenciaUltimakeyUltimoChamadoEmAtendimento: state.ChamadoReducer.referenciaUltimakeyUltimoChamadoEmAtendimento,
            refreshControlCarregaMaisChamadosEmAtendimento: state.ChamadoReducer.refreshControlCarregaMaisChamadosEmAtendimento,

            conversasCarregadasEmEspera: state.ChamadoReducer.conversasCarregadasEmEspera,
            conversasCarregadasEmAtendimento: state.ChamadoReducer.conversasCarregadasEmAtendimento,

            loadingCarregandoRespostasAntigas: state.ChamadoReducer.loadingCarregandoRespostasAntigas,
            idRespostaAntiga: state.ChamadoReducer.idRespostaAntiga,
            //  acoesDoAtendimentoChat: state.ChamadoReducer.acoesDoAtendimentoChat[ownProps.data.key],
            acoesDoAtendimentoChat: state.ChamadoReducer.acoesDoAtendimentoChat,
            atendimentosPorKeyReducerChat: state.ChamadoReducer.atendimentosPorKeyReducerChat[ownProps.data.key],

            loadingCarregandoConversasChatAtual: state.ChamadoReducer.loadingCarregandoConversasChatAtual,
            ultimaKeyReferenciaConversasChatATual: state.ChamadoReducer.ultimaKeyReferenciaConversasChatATual,

            mostrarNomeAtendenteWpp: state.ConfiguracaoReducer.mostrarNomeAtendenteWpp,
            desativarAssistenteVirtualBotWpp: state.ConfiguracaoReducer.desativarAssistenteVirtualBotWpp,
            isAdmin: state.PerfilReducer.isAdmin,
            modalRespostaPadrao: state.ChamadoReducer.modalRespostaPadrao,


            usuarioAdmVisualizaApenasChamadosDoSetorVinculado: state.PerfilReducer.usuarioAdmVisualizaApenasChamadosDoSetorVinculado,
            verificaAgrupaAtendenteSetor: state.ChamadoReducer.verificaAgrupaAtendenteSetor,

            atendenteVeApenasProprioHistoricoChamado: state.ChamadoReducer.atendenteVeApenasProprioHistoricoChamado,

            allClientes: state.DashBoardReducer.clientesExportarExcel,
        }
    );
};
export default connect(mapStateToProps, {
    modificaNomeRotaNoHeader, atribuiAtendimentoAh,
    modificaKeyAtendimentoEmEsperaAtendimentoOnline, modificaVisibilidadeModalAtendimentoOnline,
    atribuiStatusAtendimento, modificaKeyAtendimentoEmAtendimentoOnline, enviaAtendimentoParaReducerChatExport,
    alteraComentarioChat, salvarComentario, uploadArquivoChat,
    listaRespostasPadroes, verificaSeChatEstaHabilitadoTelaAtendimentoOnline, modificaClicouCFGchat,
    desligaOuvintesChamadosFirebase, enviaAtendimentoEmEsperaAtendimentoOnlineExport, modificaChamadosEmAtendimentoViaChatExport,
    ativaDesativaLoadingEnviandoAudioChatExport, modificaResolucaoProblema, getAllContatcsWpp,
    listaConversasAntigasWppContacts, iniciaAtendimentoPorContacts, ativaDesativaLoadingIniciandoCOnversaContactsExport,
    listaConversasAntigasWppEmEspera, listaConversasAntigasWppEmAtendimento,
    enviaUltimaReeferenciaKeyEmEsperaExport, enviaConversasCarregadasEmEsperaExport,
    enviaUltimaReeferenciaKeyEmAtendimentoExport, enviaConversasCarregadasEmAtendimentoExport,
    listaConversasAntigasResponder, enviaAcoesAtendimentoParaReducerChatExport,
    enviaMensagemEncaminhada, comentaEAtribuiAtendimento, listaConversasAnterioresChatAtual,
    enviaUltimaKeyReferenciaCOnversasCarregadasChatExport,
    modificaTitleTab, deletaMensagemDropDesk, enviaContatosParaReducerExport, modificaModalRespostaPadrao,
    setaMensagensComoLidaWhatsAppSocket, modificaModalPreviewImage, modificaUrlPreviewImage, modificaNamePreviewImage,
    ativaDesativaLoadingCarregaContatosWppExport

})(memo(ChatWindowEmAtendimento));