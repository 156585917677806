import {
    CARREGANOMEPERFIL, CARREGAEMAILPERFIL, CARREGAIMAGEMPERFIL, ENVIATOUCHBACKGROUNDMENUGAVETA, ENVIAKEYUSUARIOPERFIL,
    ACESSOUMEUPERFIL, ENVIAADMINPARAREDUCER, ENVIAADMVISUALIZAAPENASCHAMADOSDOSETORVINCULADO
} from '../actions/types';


const INITIAL_STATE = {
    nome: '',
    email: '',
    foto: null,
    keyUsuarioLogado: '',
    isAdmin: true,
    usuarioAdmVisualizaApenasChamadosDoSetorVinculado: false,
    acessouAtravesPerfil: false,
    backgroundMenusGaveta: [{
        selecionadoInicio: true,
        selecionadoVip: false,
        selecionadoClientes: false,
        selecionadoSair: false,
        selecionadoAtendentes: false,
        selecionadoSetores: false,
        selecionadoConfiguracao: false,
        selecionadoContato: false,
        selecionadoSobre: false,
        selecionadoAvaliar: false,
        selecionadoRedesSocias: false,
        selecionadoCompartilhar: false,
        selecionadoAvisos: false,
        selecionadoConfiguracaoEmpresa: false,
        selecionadoAvaliacaoAtendimento: false,
        selecionadoRelatorio: false,
        modalSairVisible: false,
        modalAvaliarVisible: false,
        modalRedeSocialVisible: false,
        selecionadoCentralDeAjuda: false
    }]
};
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CARREGANOMEPERFIL:
            return { ...state, nome: action.payload };
        case CARREGAEMAILPERFIL:
            return { ...state, email: action.payload };
        case CARREGAIMAGEMPERFIL:
            return { ...state, foto: action.payload };
        case ENVIATOUCHBACKGROUNDMENUGAVETA:
            return { ...state, backgroundMenusGaveta: action.payload };
        case ENVIAKEYUSUARIOPERFIL:
            return { ...state, keyUsuarioLogado: action.payload };
        case ACESSOUMEUPERFIL:
            return { ...state, acessouAtravesPerfil: action.payload };
        case ENVIAADMINPARAREDUCER:
            return { ...state, isAdmin: action.payload };
        case ENVIAADMVISUALIZAAPENASCHAMADOSDOSETORVINCULADO:
            return { ...state, usuarioAdmVisualizaApenasChamadosDoSetorVinculado: action.payload };
        default:
            return state;
    }
};