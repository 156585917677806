import {
  ATIVADESATIVALOADINGTELACONSULTACLIENTE,
  ENVIATAMANHOARQUIVOANEXADOTELAABERTURACHAMADO,
  ALTERASETOR,
  ALTERAPRIORIDADE,
  ALTERALOCALATENDIMENTO,
  ALTERADESCRICAOCHAMADO,
  ENVIAFOTOCLIENTEPARAREDUCER,
  INSEREFOTOCADASTROCLIENTE,
  MODIFICANOMECLIENTETELACADASTROCLIENTE,
  MODIFICAEMPRESACLIENTETELACADASTROCLIENTE,
  MODIFICAEMAILCLIENTETELACADASTROCLIENTE,
  MODIFICASENHACLIENTETELACADASTROCLIENTE,
  MODIFICACONFIRMASENHACLIENTETELACADASTROCLIENTE,
  MENSAGEMCADASTRACLIENTEERROR, MODIFICATABINDEX,
  MENSAGEMCADASTRACLIENTESUCESSO,
  ATIVALOADINGBOTAOCADASTRARCLIENTE,
  ALTERAVALORSWITCHTELACLIENTE,
  ZERAINFORMACOESCADASTROCLIENTE,
  ALTERAVALORDIGITOCONSULTA,
  INSEREFOTOCADASTROCLIENTETELAFLATLISTCONSULTA,
  MODIFICACLIENTESELECIONADO,
  ENVIACLIENTEPARAREDUCER,
  ALTERAMAISDETALHESTELANOVOCHAMADO,
  ATIVALOADINGCARREGARCLIENTETELANOVOCHAMADO,
  DESATIVALOADINGCARREGARCLIENTETELANOVOCHAMADO,
  ATIVALOADINGCARREGARATENDENTETELANOVOCHAMADO,
  DESATIVALOADINGCARREGARATENDENTETELANOVOCHAMADO,
  ENVIAATENDENTEPARAREDUCER,
  MODIFICAATENDENTESELECIONADO,
  MODIFICAPRIORIDADESELECIONADO,
  MODIFICALOCALSELECIONADO,
  ENVIASETORPARAREDUCER,
  ATIVALOADINGCARREGARSETORTELANOVOCHAMADO,
  MODIFICASETORSELECIONADO,
  ALTERAEMPRESACLIENTETELAALTERAR,
  ALTERANOMECLIENTETELAALTERAR,
  ALTERAREMAILCLIENTETELAALTERAR,
  ALTERARPERMISSAOCLIENTETELAALTERAR,
  ALTERASENHACLIENTETELAALTERAR,
  ALTERACONFIRMASENHACLIENTETELAALTERAR,
  ALTERAFOTOCLIENTETELAALTERAR,
  ATIVALOADINGBOTAOALTERARCLIENTE,
  ALTERAKEYCLIENTETELAALTERAR,
  MENSAGEMCADASTRACLIENTEERRORTELAALTERAR,
  MENSAGEMCADASTRACLIENTESUCESSOTELAALTERAR,
  INSEREFOTOALTERARCLIENTE,
  ZERAMSGSUCESSOEERROCADASTRACLIENTE,
  ZERAMENSAGEMDESUCESSOEERRO,
  SEMCONEXAOINTERNETCONSULTACLIENTE,
  SEMCONEXAOINTERNETCADASTRACLIENTE,
  SEMCONEXAOINTERNETALTERACLIENTE,
  ATIVALOADINGBOTAOCADASTRARCHAMADO,
  SEMCONEXAOINTERNETCADASTRACHAMADO,
  CHAMADOCADASTRADOSUCESSO,
  MODIFICANOMEARQUIVO1,
  MODIFICANOMEARQUIVO2,
  MODIFICANOMEARQUIVO3,
  MODIFICARESARQUIVO1,
  MODIFICARESARQUIVO2,
  MODIFICARESARQUIVO3,
  LISTACHAMADOABERTO,
  VERIFICACONEXAOINTERNETCHAMADOABERTO, MODIFICAARQUIVOCOMOPLETOPARAUPAR,
  ZERAMENSAGEMSEMINTERNETCHAMADOABERTO, ATIVALOADINGTELACHAMADOABERTO, ENVIAULTIAMREFERENCIAKEY, MODIFICANOMEATENDENTESELECIONADO,
  MODIFICANOMECLIENTESELECIONADO, ZERACADASTROCHAMADO, ATIVADESATIVALOADINGFINALFLATLISTCHAMADOABERTO, VERIFICACONEXAOINTERNETCHAMADOEMATENDIMENTO,
  ZERAMENSAGEMSEMINTERNETCHAMADOEMATENDIMENTO, ENVIACHAMADOEMATENDIMENTO, ATIVADESATIVALOADINGTELACHAMADOATENDIMENTO,
  ENVIAULTIAMREFERENCIAKEYEMATENDIMENTO, ATIVADESATIVALOADINGFINALFLATLISTCHAMADOEMATENDIMENTO, ENVIACHAMADOFECHADO,
  ATIVADESATIVALOADINGTELACHAMADOFECHADO, VERIFICACONEXAOINTERNETCHAMADOFECHADO, ZERAMENSAGEMSEMINTERNETCHAMADOFECHADO,
  ENVIAULTIAMREFERENCIAKEYFECHADO, ATIVADESATIVALOADINGFINALFLATLISTCHAMADOFECHADO, ZERAMENSAGEMSEMINTERNETCHAMADOCANCELADO,
  ENVIACHAMADOCANCELADO, ATIVADESATIVALOADINGTELACHAMADOCANCELADO, VERIFICACONEXAOINTERNETCHAMADOCANCELADO,
  ENVIAULTIAMREFERENCIAKEYCANCELADO, ATIVADESATIVALOADINGFINALFLATLISTCHAMADOCANCELADO, ENVIACHAMADOTODOS,
  ATIVADESATIVALOADINGTELACHAMADOTODOS, VERIFICACONEXAOINTERNETCHAMADOTODOS, ZERAMENSAGEMSEMINTERNETCHAMADOTODOS,
  ENVIAULTIAMREFERENCIACHAMADOABERTO, ENVIAULTIAMREFERENCIACHAMADOEMATENDIMENTO, ENVIAULTIAMREFERENCIACHAMADOFECHADO,
  ENVIAULTIAMREFERENCIACHAMADOCANCELADO, ATIVADESATIVALOADINGFINALFLATLISTCHAMADOTODOS, INDICAFIMDALISTA,
  ENVIAULTIAMREFERENCIAKEYTODOS, ATIVADESATIVALOADINGCARREGARCLIENTETELACHAMADOTODOS, ENVIACLIENTEPARAREDUCERTELACHAMADOTODOS, MODIFICACLIENTESELECIONADOTELACHAMADOTODOS,
  MODIFICANOMECLIENTESELECIONADOTELACHAMADOTODOS, ENVIAULTIAMREFERENCIAKEYCLIENTECHAMADOTODOS, ENVIAULTIAMREFERENCIAKEYTELACONSULTACLIENTE,
  ATIVADESATIVALOADINGFINALFLATLISTTELACONSULTACLIENTE, ATIVADESATIVALOADINGCARREGARCLIENTETELACONSULTACLIENTE,
  ENVIACLIENTESECTIONEDTELACONSULTACLIENTE, MODIFICANOMECLIENTESELECIONADOTELACONSULTACLIENTE, MODIFICACLIENTESELECIONADOTELACONSULTACLIENTE,
  MODIFICACLIENTESELECIONADOTELACHAMADOTODOSTESTE, ATIVADESATIVALOADINGREFRESHCONTROLCONSULTACLIENTE,
  ATIVADESATIVALOADINGREFRESHCONTROLCHAMADOABERTO, ATIVADESATIVALOADINGREFRESHCONTROLCHAMADOEMATENDIMENTO,
  ATIVADESATIVALOADINGREFRESHCONTROLCHAMADOFECHADO, ATIVADESATIVALOADINGREFRESHCONTROLCHAMADOCANCELADO,
  MODIFICADATAVENCIMENTOTELACHAMADOABERTURA, MODIFICAARQUIVOUPADO1, MODIFICAARQUIVOUPADO2, MODIFICAARQUIVOUPADO3,
  MODIFICACONTAGEMARQUIVO, MODIFICATAMANHOARQUIVO1, MODIFICATAMANHOARQUIVO2, MODIFICATAMANHOARQUIVO3,
  ALTERAKEYCHAMADO, ALTERANUMEROATENDIMENTO, ENVIAATENDIMENTOPARAREDUCER, ATIVADESATIVALOADINGTELAACOESATENDIMENTO,
  ENVIAUSUARIOSTELAACOESATENDIMENTO, VERIFICAERRORTELAALTERARCHAMADO, VERIFICACHAMADOALTERADOSUCESSO,
  ENVIAARQUIVOSUPADOSTELAACOESCHAMADOS, ENVIAARQUIVOSUPADOSTELAACOESCHAMADOS2, ENVIAARQUIVOSUPADOSTELAACOESCHAMADOS3,
  ENVIAACOESATENDIMENTOPARAREDUCER, MODIFICAMOTIVOCANCELAMENTOTELAACOESATENDIMENTO, MODIFICAMENSAGEMERRORMOTIVOCANCELAMENTO,
  MODIFICAVISIBILIDADEMODALCANCELAR, MODIFICAVISIBILIDADEMODALCOMENTARIo, ATIVADESATIVASWITCHCOMENTARIO,
  MODIFICAINPUTCOMENTARIO, MODIFICANOMEARQUIVOCOMENTARIOTELAACOESATENDIMENTO, MODIFICARESARQUIVOCOMENTARIOTELAACOESATENDIMENTO,
  MODIFICACOMENTARIOPUBLICOSIMOUNAO, ZERAMENSAGEMSEMCONEXAOINTERNETSALVARCOMENTARIO, ZERAINFORMACOESCOMENTARIO,
  ENVIAARQUIVOPARASERUPADOTELAABERTURACHAMADO, ENVIARQUIVOSUPADOSANEXADOSAOCHAMADO, ATIVADESATIVALOADINGARQUIVOSUPADOSANEXADOSAOCHAMADO,
  DIMINUITAMANHOTOTALARQUIVOSANEXADOSLOCAL, MODIFICANUMEROEXCLUSIVOCADASTRACLIENTE,
  ATIVADESATIVALOADINGNUMEROEXCLUSIVOCADASTRACLIENTE, DESATIVALOADINGBOTAOCADASTRARCLIENTE, MUDAVISIBILIDADEMODALCADASTRACLIENTEVERSAOPREMIUM,
  VERIFICARSEEHOBRIGATORIOATRIBUIRATENDENTE, ALTERACLIENTESELECIONADOTELACADASTRACLIENTE, MODIFICANOMECLIENTESELECIONADOTELACADASTROCLIENTE,
  ATIVADESATIVALOADINGTELAALTERARCLIENTE, ENVIAEMPRESAPRINCIPALCLIENTE, MODIFICAENDERECOTELACADASTROCLIENTE, MODIFICATELEFONETELACADASTROCLIENTE,
  ATIVADESATIVALOADINGCARREGARCLIENTETELAALTERARCLIENTE, ENVIAEMPRESASECTIONEDTELAALTERARCLIENTE, ALTERAEMPRESASELECIONADOTELAALTERARCLIENTE,
  MODIFICANOMECLIENTESELECIONADOTELAALTERARCLIENTE, MODIFICAEMPRESASELECIONADAAOABRIRCHAMADO, MODIFICAKEYEMPRESAVINCULADASELECIONADAAOABRIRCHAMADO,
  ATIVADESATIVALOADINGBOTAOEXCLUIRCLIENTE, MUDAVISIBILIDADEMODALEXCLUIRCLIENTE, ATIVADESATIVALOADINGCARREGANDOCONFIGURACOESGERAIS,
  VERIFICAATENDENTEVERAPENASPROPRIOCHAMADO, VERIFICAAGRUPARATENDENTESETOR, MODIFICANOMESETORSELECIONADO, MODIFICAMODALFILTROCHAMADO,

  MODIFICAFILTROCHAMADO, MODIFICASELECTTEXTCHAMADO, MODIFICAPESQUISATEXTCHAMADO, MODIFICAVALORARRAYSECTIONEDFILTROCHAMADO,
  MODIFICAFILTROSELECIONADOTELACHAMADOABERTO, MODIFICAFILTROSELECIONADOTELACHAMADOTODOS, MODIFICAFILTROSELECIONADOTELACHAMADOEMATENDIMENTO,
  MODIFICAFILTROSELECIONADOTELACHAMADOFECHADO, MODIFICAFILTROSELECIONADOTELACHAMADOCANCELADO, ATIVADESATIVALOADINTESTETSTE,
  MODIFICATOASTACOESATNEDIMENTO, MODIFICAVISIBILIDADEMODALNOVOATENDIMENTO,
  VISIBLESELECTSEARCH, MODIFICAVISIBILIDADEMODALALTERARATENDIMENTO, MODIFICAVISIBILIDADEMODALATRIBUIRATENDIMENTO, MODIFICAVISIBILIDADEMODALADDTEMPOGASTOATENDIMENTO,
  MODIFICAVISIBILIDADEMODALFINALIZARATENDIMENTO, MODIFICAVISIBILIDADEMODALPRIORIDADEATENDIMENTO, MODIFICAVISIBILIDADEMODALCANCELARATENDIMENTO,
  MODIFICAVISIBLIDADEBOTAOADDVENCIMENTO, MODIFICAFILTROCLIENTESTELACLIENTES, MODIFICAVISIBLEMODALNOVOCLIENTE,

  MODIFICAEMPRESACLIENTETELACADASTROCLIENTENOVOCADASTRO, ALTERAEMPRESASELECIONADOTELAALTERARCLIENTENOVOCADASTRO,
  MODIFICANOMECLIENTETELACADASTROCLIENTENOVOCADASTRO, MODIFICAENDERECOTELACADASTROCLIENTENOVOCADASTRO,
  MODIFICATELEFONETELACADASTROCLIENTENOVOCADASTRO, MODIFICAEMAILCLIENTETELACADASTROCLIENTENOVOCADASTRO,
  MODIFICASENHACLIENTETELACADASTROCLIENTENOVOCADASTRO, MODIFICACONFIRMASENHACLIENTETELACADASTROCLIENTENOVOCADASTRO,
  ALTERAVALORSWITCHTELACLIENTENOVOCADASTRO, MODIFICAARQUIVOCOMOPLETOPARAUPARNOVOCADASTRO,
  MODIFICANOMECLIENTESELECIONADOTELAALTERARCLIENTENOVOCADASTRO, MODIFICAMOSTRARMAISCHAMADOS,
  MODIFICARETORNATELACLIENTESPARANOVOATENDIMENTO, ENVIAATENDIMENTOEMESPERATENDIMENTOONLINE, MODIFICAKEYATENDIMENTOEMESPERAATENDIMENTOONLINE,
  MODIFICAATENDIMENTOEMESPERASELEECIONADO, MODIFICACHAMADOSEMATENDIMENTOVIACHAT, MODIFICAKEYATENDIMENTOEMTENDIMENTOONLINE,
  ENVIAACOESATENDIMENTOPARAREDUCERCHAT, ENVIAATENDIMENTOPARAREDUCERCHAT, MODIFICAVISIBILIDADEMODALATENDIMENTOONLINE,
  ALTERACOMENTARIOCHAT, MODIFICATEXTARQUIVOCHAT, MODIFICAUPANDOARQUIVOCHAT, ENVIAATENDIMENTOPORKEYREDUCERCHAT,
  MODIFICALIDOENAOLIDOATENDIMENTOPORKEYREDUCERCHAT, ATIVADESATIVALOADINGCHATONLINE, MODIFICAVISIBILIDADEMODALPORTALCLIENTE,
  MODIFICAVISIBILIDADEMODALIMPORTARCLIENTES, MODIFICALOADINGCADASTRANDOCLIENTEEMMASSA, MODIFICACLICOUCFGCHAT,
  ZERAINFORMACOESENVIOARQUIVOCHAT, ATIVADESATIVALOADINGMAISRESULTADOSCHAMADOSCLIENTE, ATIVADESATIVALOADINGENVIANDOAUDIOCHAT,
  MODIFICARESOLUCAOPROBLEMA, ENVIAULTIMONOMEREFENCIATELACONSULTACLIENTE, ENVIACONVERSASPARACHATEMESPERA,
  ENVIADADOSWHATSAPPCONFIG, ENVIAATENDIMENTOPORKEYREDUCERCHATEMESPERA, ENVIACONTATOSPARAREDUCER,
  MODIFICAKEYCONTACTS, MODIFICADADOSCONTACTSSELECIONADO,
  ENVIACONVERSASULTIMOCHAMADO, ATIVADESATIVALOADINGCARREGACHAMADOSANTIGOS, ATIVADESATIVALOADINGCARREGAMAISCHAMADOSANTIGOS,
  ENVIAULTIMAREEFERENCIAKEYCONTACTS, ATIVADESATIVALOADINGINICIANDOCONVERSACONTACTS, ATIVADESATIVALOADINGCARREGANDOCHAMADOSEMATENDIMENTO,
  ATIVADESATIVALOADINGCARREGACONTATOSWPP, INSEREMENSAGEMERRORGETCONTACTS,
  ENVIAULTIMAREEFERENCIAKEYEMESPERA, ATIVADESATIVALOADINGCARREGACHAMADOSANTIGOSEMESPERA, ENVIACONVERSASCARREGADASEMESPERA,
  ENVIACONVERSASCARREGADASEMATENDIMENTO, ENVIAULTIMAREEFERENCIAKEYEMATENDIMENTO,
  ATIVADESATIVALOADINGCARREGACHAMADOSANTIGOSEMATENDIMENTO, ATIVADESATIVALOADINGCARREGARESPOSTAANTIGAS, ENVIAIDRESPOSTAANTIGA,
  MODIFICANOMECLIENTETELACHATONLINE, INSEREFOTOCADASTROCONTATO, MODIFICAVISIBILIDADEMODALADICIONARCONTATO,
  MODIFICAARQUIVOCOMOPLETOPARAUPARNOVOCONTATO, MODIFICANOMECONTATO, MODIFICATELEFONECONTATO,
  MODIFICALOADINGCADASTRANOVOCONTATO, ZERAINFORMACOESCADASTROCONTATO, MODIFICAMENSAGEMFINALIZAATENDIMENTOWPP,
  MODIFICATITLETAB, ATIVADESATIVALOADINGCARREGANDOCONVERSASCHATATUALA, ENVIAULTIMAKEYREFERENCIACONVERSASCARREGADASCHAT,
  ENVIAULTIMAKEYREFERENCIACONVERSASCARREGADASCHATEMESPERA, ATIVADESATIVALOADINGCARREGANDOCONVERSASCHATATUALAEMEPSERA,


  ENVIAIDRESPOSTAANTIGAACOESCHAMADO, ATIVADESATIVALOADINGCARREGARESPOSTAANTIGASACOESCHAMADO,
  ENVIACONVERSASANTIGASCHATACOESCHAMADO, ATIVADESATIVALOADINGCARREGANDOMAISACOESCHAMADOACOESCHAMADO,
  ENVIAREFERENCIAULTIMAKEYACOESCHAMADOACOESCHAMADO, MODIFICAOBRIGATORIOINFORMARRESOLUCAOCHAMADODASHBOARD,
  MODIFICALOADINGCARREGANDOCHAMADOSEMESPERAREFRESHCONTROL, MODIFICACHAMADOSCARREGADOSEMESPERA,
  MODIFICAKEYREFERENCIAULTIMOCHAMADOEMESPERA, MODIFICATIMEZONECHATONLINE, MODIFICAVISIBILIDADEMODALEXPORTARCLIENTES, MODIFICAMODALRESPOSTAPADRAO,
  MODIFICAPROBLEMAESTATICOSELECIONADONOVOATENDIMENTO, MODIFICANOMEPROBLEMAESTATICOSELECIONADONOVOATENDIMENTO,

  MODIFICAATENDENTEVEAPENASPROPRIOHISTORICOCHAMADO, MODIFICAENVIARMENSAGEMTRANSSETORWPP,

  MODIFICAURLPREVIEWIMAGE, MODIFICAMODALPREVIEWIMAGE, MODIFICANAMEPREVIEWIMAGE, MODIFICAVISIBILIDADEMODALALTERARCHAT,
  MODIFICACLIENTESFILTRADOSAPOSDIGITO
} from "../actions/types";
const imagemPadraoCliente = require('../imagens/userPadrao.png');

const INITIAL_STATE = {
  keyCliente: "",
  nomeEmpresaCliente: "",
  nomeCliente: "",
  emailCliente: "",
  senhaCliente: "",
  confirmaSenhaCliente: "",
  inputDigitoConsulta: "",
  dataVencimento: '',
  mensagemerrorAoCadastrarCliente: "",
  mensagemSucessoAoCadastrarCliente: "",
  mensagemerrorAoCadastrarClienteTelaAlterar: "",
  mensagemSucessoAoCadastrarClienteTelaAlterar: "",
  verificaConexaoInternetListaChamadoAberto: '',
  backgroundMensagemErrorTelaCadastraCliente: null,
  backgroundMensagemErrorTelaAlterarCliente: null,
  loadingBotaoCadastrarCliente: false,
  valorSwitch: false,
  renderMaisDetalhes: false,
  loadingCarregandoClienteTelaNovoChamado: false,
  loadingCarregandoAtendenteTelaNovoChamado: false,
  loadingCarregandoSetorTelaNovoChamado: false,
  loadingBotaoAlterarCliente: false,
  loadingTelaChamadoAberto: true,
  clientes: [],
  clientesNovoChamado: [],
  setorNovoChamado: [],
  setorSelecionado: '',
  atendenteNovoChamado: [],
  atendenteSelecionado: '',
  setores: [],
  chamados: [],
  clienteSelecionado: '',
  atendente: "",
  fotoImagemClienteTelaConsulta: null,
  fotoImagemClienteTelaAlterar: null,
  uriImagemPadraoTelaCadastroCliente: imagemPadraoCliente,
  //"https://firebasestorage.googleapis.com/v0/b/dark-4b307.appspot.com/o/imagemPadraoDark%2Fuser.png?alt=media&token=5494861d-8431-480d-ac62-442c8b2c39af",
  setor: [],
  prioridade: [
    {
      name: 'PRIORIDADES',
      type: 'group',
      items: [
        { value: "Baixa", name: "Baixa" },
        { value: "Normal", name: "Normal" },
        { value: "Alta", name: "Alta" },
        { value: "Urgente", name: "Urgente" }
      ]
    }
  ],
  prioridadeSelecionada: '',
  localAtendimento: [
    {
      name: 'LOCAL DO ATENDIMENTO',
      type: 'group',
      items: [
        { value: "Interno", name: "Interno" },
        { value: "Externo", name: "Externo" }
      ]
    }
  ],
  localAtendimentoSelecionado: '',
  descricaoAtendimento: "",
  verificaInternetConsultaCliente: "",
  verificaInternetCadastraCliente: "",
  verificaInternetAlteraCliente: "",
  verificaInternetCadastrarChamado: "",
  loadingBotaoCadastrarChamado: false,
  mensagemChamadoCadastradoSucesso: "",
  nomeArquivoUpado1: "",
  nomeArquivoUpado2: "",
  nomeArquivoUpado3: "",
  resDoArquivo1: null,
  resDoArquivo2: null,
  resDoArquivo3: null,
  refreshControlFlatListChamadoAberto: false,
  referenciaUltimaKey: '',
  loadingFimDaPagina: false,
  loadingTelaEmAtendimento: true,
  chamadosEmAtendimentos: [],
  refreshControlFlatListChamadoEmAtendimento: false,
  referenciaUltimaKeyEmAtendimento: '',
  loadingFimDaPaginaEmAtendimento: false,
  verificaConexaoInternetListaChamadoEmAtendimento: '',

  verificaConexaoInternetListaChamadoFechado: '',
  loadingFimDaPaginaFechado: false,
  loadingTelaFechado: true,
  chamadosFechado: [],
  refreshControlFlatListChamadoFechado: false,
  referenciaUltimaKeyFechado: '',
  verificaConexaoInternetListaChamadoCancelado: '',
  loadingFimDaPaginaCancelado: false,
  loadingTelaCancelado: true,
  chamadosCancelados: [],
  refreshControlFlatListChamadoCancelado: false,
  referenciaUltimaKeyCancelado: '',
  chamadoTodos: [],
  verificaConexaoInternetListaChamadoTodos: '',
  loadingTelaTodos: true,
  refreshControlFlatListChamadoTodos: false,
  referenciaUltimaKeyTodos: '',
  loadingFimDaPaginaTodos: false,
  keyTodosChamadoAberto: '',
  keyTodosChamadoEmAtendimento: '',
  keyTodosChamadoFechado: '',
  keyTodosChamadoCancelado: '',
  indicaFimDaListaTodos: false,
  keyTodos: '',
  loadingClienteTelaChamadoTodos: false,
  clientesTelaChamadoTOdos: [],
  nomeClienteSelecionadoTelaChamadoTodos: '',
  clienteSelecionadoTelaChamadoTodos: [],
  keyClienteChamadoTodos: '',
  loadingTelaConsultaCliente: true,
  referenciaUltimaKeyTelaConsultaCliente: '',
  loadingFimDaPAginaTelaConsultaCliente: false,
  loadingCarregarClientesSectionedConsultaCliente: false,
  clientesSectionedConsulta: [],
  clienteSelecionadoTelaConsultaCliente: [],
  nomeClienteSelecionadoTelaConsultaCliente: '',
  refreshControlConsultaCliente: false,
  arquivoUpado1: false,
  arquivoUpado2: false,
  arquivoUpado3: false,
  contagemArquivo: 0,
  tamanhoArquivo1: "",
  tamanhoArquivo2: "",
  tamanhoArquivo3: "",
  keyAtendimento: '',
  numeroAtendimento: '',
  dadosAtendimentos: [],
  loadingTelaAcoesAtendimento: true,
  atendentesAcoesChamado: [],
  verificaErrorTelaAlterarChamado: '',
  verificaChamadoAlteradoSucesso: '',
  arrayArquivosUpados1: [],
  arrayArquivosUpados2: [],
  arrayArquivosUpados3: [],
  acoesDoAtendimento: [],
  motivoCancelamento: '',
  mensagemErroMotivoCancelamento: '',
  loadingModalCancelarAtendimento: false,
  nomeArquivoAnexadoComentario: '',
  resArquivoAnexadoComentario: null,
  switchComentario: false,
  inputComentario: '',
  modalComentarioVisible: false,
  comentarioPublicoSimOuNao: 1,
  verificaInternetSalvarComentario: '',
  arquivosParaSerUpadosNaAberturaChamado: [],
  tamanhoTotalARquivoAnexado: 0,
  arquivosUpadosVinculadoAoChamado: [],
  loadingArquivosUpadosVinculadoAoChamado: true,
  nomeAuxCliente: '',
  nomeAuxAtendente: '',
  nomeAuxSetor: '',
  loadingverificaSeJaTemCodigoCadastraCliente: true,
  numeroExclusivoCadastraCliente: 0,
  modalCadastraClienteVersaoPremium: false,
  verificaSeAtribuirAtendenteObrigatorio: false,
  verificaAgrupaAtendenteSetor: false,
  verificaAtendenteVeSomenteProprioAtendimento: false,
  clienteSelecionadoConsultaClienteTelaCAdastraCliente: [],
  nomeEmpresaSelecionadoConsultaClienteTelaCAdastraCliente: '',
  loadingAlterarCliente: false,
  clienteEmpresaPrincipal: false,
  telefoneCliente: '',
  enderecoCliente: '',
  listaEmpresasTelaAlterarCliente: '',
  empresaSelecionadasTelaAlterarCliente: [],
  loadingEmpresasTelaAlterarCliente: false,
  nomeEmpresaSelecionadoConsultaTelaAlterarCliente: '',
  empresaSelecionadaAoAbrirChamado: '',
  keyEmpresaVinculadaAoAbrirAlterarChamado: '',
  loadingBotaoExcluirCliente: false,
  visibleModalExcluirCliente: false,
  loadingVerificaCarregouConfigAtendimento: true,
  loadginteste: true,

  filtroChamado: '',
  chamadosComFiltro: [],
  filtroSelecionado: '',
  selectTextChamado: '',
  pesquisaTextChamado: '',
  modalFiltroChamado: false,
  filtroSelecionadoEmAtendimento: '',
  filtroSelecionadoFechado: '',
  filtroSelecionadoCancelado: '',
  filtroSelecionadoTodos: '',

  verificaInternetAcoesAtendimento: '',

  modalNovoAtendimento: false,
  visibleSelectSearch: false,
  modalAlterarAtendimento: false,
  modalAtribuirAtendimento: false,
  modalAddTempoGasto: false,
  modalStatusAtendimento: false,
  modalPrioridadeAtendimento: false,
  modalCancelarAtendimento: false,
  visibilidadeBotaoAddDataVencimento: false,
  filtroClientes: '',
  modalNovoCliente: false,
  tabIndex: 0,
  arquivoCompletoParaUpar: '',
  arquivoCompletoParaUparNovoCadastro: '',

  nomeEmpresaClienteNovoCadastro: '',
  nomeClienteNovoCadastro: '',
  enderecoClienteNovoCadastro: '',
  telefoneClienteNovoCadastro: '',
  emailClienteNovoCadastro: '',
  senhaClienteNovoCadastro: '',
  confirmaSenhaClienteNovoCadastro: '',
  valorSwitchNovoCadastro: false,
  empresaSelecionadasTelaCadastraCliente: '',
  nomeClienteSelecionadoTelaCadastroCLiente: '',
  mostrarMaisDetalhesChamado: false,
  retornaTelaClienteParaNovoAtendimento: false,
  retornaTelaClienteParaAlterarChamado: false,

  chamadosEmEsperaAtendimentoOnline: [],
  keyAtendimentoEmEsperaAtendimentoOnline: '',
  atendimentoEmEsperaSelecionado: {},

  chamadosEmAtendimentoOnline: [],
  keyAtendimentoEmAtendimentoOnline: '',

  acoesDoAtendimentoChat: [],
  conversasChatEmEspera: [],
  atendimentosPorKeyReducerChat: [],
  atendimentosPorKeyReducerChatEmEspera: [],
  dadosAtendimentosChat: {},
  modalDetalhesAtendimentoOnline: false,
  comentarioChat: '',
  textArquivoChat: '',
  enviandoComentario: false,
  loadingAtendimentoViaChat: true,
  modalPortalCliente: false,
  urlPadraoAcessoPortalCliente: 'https://clientes.dropdesk.com.br/',
  urlPersonalizadaAcessoPortalCliente: '',
  modalImportarClientes: false,
  loadingCadastrandoClienteMassa: false,
  clicouCFGchat: false,
  loadingMaisResultadosChamados: false,
  loadingEnviandoAudioChat: false,
  solucaoAtendimento: '',
  referenciaUltimoNomeCliente: '',
  configWhatsapp: [],
  allContactsWhatsapp: [],
  keyContacts: '',
  dadosContactsSelecionado: [],
  conversasUltimoAtendimento: [],
  loadingCarregaConversasUltimoAtendimento: true,
  referenciaUltimakeyUltimoChamadoContatos: '',
  refreshControlCarregaMaisChamados: false,
  loadingIniciandoConversaViaContacts: false,
  loadingCarregaChatsEmAtendimento: true,
  loadingCarregaChatsEmEspera: true,
  loadingCarregaContatosWpp: false,
  loadingAssinalaErrorGetContatcs: false,

  referenciaUltimakeyUltimoChamadoEmEspera: '',
  refreshControlCarregaMaisChamadosEmEspera: false,
  conversasCarregadasEmEspera: [],

  referenciaUltimakeyUltimoChamadoEmAtendimento: '',
  refreshControlCarregaMaisChamadosEmAtendimento: false,
  conversasCarregadasEmAtendimento: [],

  loadingCarregandoRespostasAntigas: false,
  idRespostaAntiga: '',
  nomeClienteChatOnline: '',

  nomeContato: '',
  telefoneContato: '55',
  loadingBotaoCadastrarContato: false,
  uriImagemPadraoTelaCadastroNovoContato: imagemPadraoCliente,
  modalNovoContato: false,
  arquivoCompletoParaUparNovoContato: '',
  mensagemFinalizacaoAtendimentoWpp: '',

  countMensagensNaoLidas: 'DropDesk',

  loadingCarregandoConversasChatAtual: false,
  ultimaKeyReferenciaConversasChatATual: '',

  loadingCarregandoConversasChatAtualEmEspera: false,
  ultimaKeyReferenciaConversasChatATualEmEspera: '',

  loadingCarregandoMaisConversasChatAcoesChamado: false,
  referenciaUltimaKeyChatAppAcoesChamado: '',
  arrayConversasAntigasChatAcoesChamado: [],
  loadingCarregandoRespostasAntigasAcoesChamado: false,
  idRespostaAntigaAcoesChamado: '',
  obrigatorioInformarResolucaoChamado: false,

  loadingCarregandoChamadosEmEsperaRefreshControl: false,
  chamadosCarregadosEmEspera: [],
  keyReferenciaUltimoChamadoEmEspera: '',

  timezoneChatOnline: "America/Sao_Paulo",

  modalExportarClientes: false,
  modalRespostaPadrao: false,

  problemaEstaticoSelecionadoNovoAtendimento: '',
  nomeProblemaEstaticoSelecionadoNovoAtendimento: '',

  atendenteVeApenasProprioHistoricoChamado: false,

  modalViewImage: false,
  urlPreViewImage: null,
  nameUrlPreview: '',

  enviarMensagemTransSetorWpp: true,
  modalAlterarChat: false,
  clientesFiltradosAposDigito: []

};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ALTERASETOR:
      return { ...state, setor: [...action.payload] };
    case ALTERAPRIORIDADE:
      return { ...state, prioridadeSelecionada: action.payload };
    case ALTERALOCALATENDIMENTO:
      return { ...state, localAtendimentoSelecionado: action.payload };
    case ALTERADESCRICAOCHAMADO:
      return { ...state, descricaoAtendimento: action.payload };
    case ENVIAFOTOCLIENTEPARAREDUCER:
      return { ...state, clientes: action.payload };
    case ENVIACLIENTEPARAREDUCER:
      if (action.payload.length == 0) {
        return {
          ...state,
          clientesNovoChamado: [...action.payload],
          loadingCarregandoClienteTelaNovoChamado: false
        };
      } else {
        return {
          ...state,
          loadingCarregandoClienteTelaNovoChamado: false,
          clientesNovoChamado: [...action.payload]
        };
      }
    case ENVIACLIENTEPARAREDUCERTELACHAMADOTODOS:
      if (action.payload.length == 0) {
        return {
          ...state,
          clientesTelaChamadoTOdos: [...action.payload],
          loadingClienteTelaChamadoTodos: false
        };
      } else {
        return {
          ...state,
          clientesTelaChamadoTOdos: [...action.payload],
          loadingClienteTelaChamadoTodos: false,
        };
      }
    case ENVIACLIENTESECTIONEDTELACONSULTACLIENTE: //aqui doido
      if (action.payload.length == 0) {
        return {
          ...state,
          clientesSectionedConsulta: [...action.payload],
          loadingCarregarClientesSectionedConsultaCliente: false,

        };
      } else {
        return {
          ...state,
          clientesSectionedConsulta: [...action.payload],
          loadingCarregarClientesSectionedConsultaCliente: false,
        };
      }


    case ENVIAATENDENTEPARAREDUCER:
      return {
        ...state,
        atendenteNovoChamado: action.payload,
        loadingCarregandoAtendenteTelaNovoChamado: false
      };
    case ENVIASETORPARAREDUCER:
      if (action.payload.length == 0) {
        return {
          ...state,
          setorNovoChamado: action.payload,
          loadingCarregandoSetorTelaNovoChamado: false
        };
      } else {
        return {
          ...state,
          setorNovoChamado: action.payload,
          loadingCarregandoSetorTelaNovoChamado: false
        };
      }
    case INSEREFOTOCADASTROCLIENTE:
      return { ...state, uriImagemPadraoTelaCadastroCliente: action.payload };
    case INSEREFOTOCADASTROCLIENTETELAFLATLISTCONSULTA:
      return { ...state, fotoImagemClienteTelaConsulta: action.payload };

    case MODIFICARESARQUIVO1:
      return { ...state, resDoArquivo1: action.payload };
    case MODIFICARESARQUIVO2:
      return { ...state, resDoArquivo2: action.payload };
    case MODIFICARESARQUIVO3:
      return { ...state, resDoArquivo3: action.payload };

    case MODIFICANOMEARQUIVO1:
      return { ...state, nomeArquivoUpado1: action.payload };
    case MODIFICANOMEARQUIVO2:
      return { ...state, nomeArquivoUpado2: action.payload };
    case MODIFICANOMEARQUIVO3:
      return { ...state, nomeArquivoUpado3: action.payload };
    case MODIFICANOMECLIENTETELACADASTROCLIENTE:
      return { ...state, nomeCliente: action.payload };
    case MODIFICAEMPRESACLIENTETELACADASTROCLIENTE:
      return { ...state, nomeEmpresaCliente: action.payload };
    case MODIFICAEMAILCLIENTETELACADASTROCLIENTE:
      return { ...state, emailCliente: action.payload };
    case MODIFICASENHACLIENTETELACADASTROCLIENTE:
      return { ...state, senhaCliente: action.payload };
    case MODIFICACONFIRMASENHACLIENTETELACADASTROCLIENTE:
      return { ...state, confirmaSenhaCliente: action.payload };
    case MENSAGEMCADASTRACLIENTEERROR:
      return {
        ...state,
        mensagemSucessoAoCadastrarCliente: "",
        mensagemerrorAoCadastrarCliente: action.payload,
        backgroundMensagemErrorTelaCadastraCliente: "#DC143C",
        loadingBotaoCadastrarCliente: false
      };
    case MENSAGEMCADASTRACLIENTESUCESSO:
      return {
        ...state,
        mensagemerrorAoCadastrarCliente: "",
        mensagemSucessoAoCadastrarCliente: "Cliente cadastrado com sucesso!",
        backgroundMensagemErrorTelaCadastraCliente: "#00FF7F",
        loadingBotaoCadastrarCliente: false
      };
    case ATIVALOADINGBOTAOCADASTRARCLIENTE:
      return {
        ...state,
        loadingBotaoCadastrarCliente: action.payload,
        mensagemSucessoAoCadastrarCliente: "",
        mensagemerrorAoCadastrarCliente: "",
        backgroundMensagemErrorTelaCadastraCliente: null
      };
    case ALTERAVALORSWITCHTELACLIENTE:
      return { ...state, valorSwitch: action.payload };
    case ZERAINFORMACOESCADASTROCLIENTE:
      return {
        ...state,
        valorSwitchNovoCadastro: false,
        nomeClienteNovoCadastro: "",
        nomeEmpresaClienteNovoCadastro: "",
        emailClienteNovoCadastro: "",
        senhaClienteNovoCadastro: "",
        confirmaSenhaClienteNovoCadastro: "",
        uriImagemPadraoTelaCadastroCliente: imagemPadraoCliente,
        //  "https://firebasestorage.googleapis.com/v0/b/dark-4b307.appspot.com/o/imagemPadraoDark%2Fuser.png?alt=media&token=5494861d-8431-480d-ac62-442c8b2c39af",
        mensagemerrorAoCadastrarCliente: "",
        telefoneClienteNovoCadastro: '',
        enderecoClienteNovoCadastro: '',
        nomeEmpresaSelecionadoConsultaTelaAlterarCliente: '',
        nomeClienteSelecionadoTelaCadastroCLiente: '',
        arquivoCompletoParaUparNovoCadastro: '',
        empresaSelecionadasTelaAlterarCliente: [],
        empresaSelecionadasTelaCadastraCliente: ''
      };
    case ZERAMSGSUCESSOEERROCADASTRACLIENTE:
      return {
        ...state,
        valorSwitch: false,
        nomeCliente: "",
        nomeEmpresaCliente: "",
        emailCliente: "",
        senhaCliente: "",
        confirmaSenhaCliente: "",
        uriImagemPadraoTelaCadastroCliente: imagemPadraoCliente,
        // "https://firebasestorage.googleapis.com/v0/b/dark-4b307.appspot.com/o/imagemPadraoDark%2Fuser.png?alt=media&token=5494861d-8431-480d-ac62-442c8b2c39af",
        mensagemerrorAoCadastrarCliente: "",
        mensagemSucessoAoCadastrarCliente: "",
        backgroundMensagemErrorTelaCadastraCliente: null,
        nomeAuxCliente: '',
        nomeAuxAtendente: '',
        //clienteSelecionadoConsultaClienteTelaCAdastraCliente: [],
        //  nomeEmpresaSelecionadoConsultaClienteTelaCAdastraCliente: '',
        telefoneCliente: '',
        enderecoCliente: '',
        //   empresaSelecionadasTelaAlterarCliente: [],
        nomeEmpresaSelecionadoConsultaTelaAlterarCliente: '',

      };
    case ALTERAVALORDIGITOCONSULTA:
      return { ...state, inputDigitoConsulta: action.payload };
    case MODIFICACLIENTESELECIONADO:
      return { ...state, clienteSelecionado: action.payload };
    case MODIFICAATENDENTESELECIONADO:
      return { ...state, atendenteSelecionado: action.payload };
    case MODIFICAPRIORIDADESELECIONADO:
      return { ...state, prioridadeSelecionada: action.payload };
    case MODIFICALOCALSELECIONADO:
      return { ...state, localAtendimentoSelecionado: action.payload };
    case MODIFICASETORSELECIONADO:
      return { ...state, setorSelecionado: action.payload };
    case ALTERAMAISDETALHESTELANOVOCHAMADO:
      return { ...state, renderMaisDetalhes: action.payload };
    case ATIVALOADINGCARREGARCLIENTETELANOVOCHAMADO:
      return { ...state, loadingCarregandoClienteTelaNovoChamado: true };
    case ATIVALOADINGCARREGARSETORTELANOVOCHAMADO:
      return { ...state, loadingCarregandoSetorTelaNovoChamado: true };
    case DESATIVALOADINGCARREGARCLIENTETELANOVOCHAMADO:
      return { ...state, loadingCarregandoClienteTelaNovoChamado: false };
    case ATIVALOADINGCARREGARATENDENTETELANOVOCHAMADO:
      return { ...state, loadingCarregandoAtendenteTelaNovoChamado: true };
    case DESATIVALOADINGCARREGARATENDENTETELANOVOCHAMADO:
      return { ...state, loadingCarregandoAtendenteTelaNovoChamado: false };
    case ALTERAEMPRESACLIENTETELAALTERAR:
      return { ...state, nomeEmpresaCliente: action.payload };
    case ALTERANOMECLIENTETELAALTERAR:
      return { ...state, nomeCliente: action.payload };
    case ALTERAREMAILCLIENTETELAALTERAR:
      return { ...state, emailCliente: action.payload };
    case ALTERARPERMISSAOCLIENTETELAALTERAR:
      return { ...state, valorSwitch: action.payload };
    case ALTERASENHACLIENTETELAALTERAR:
      return { ...state, senhaCliente: action.payload };
    case ALTERACONFIRMASENHACLIENTETELAALTERAR:
      return { ...state, confirmaSenhaCliente: action.payload };
    case ALTERAFOTOCLIENTETELAALTERAR:
      return { ...state, fotoImagemClienteTelaAlterar: action.payload };
    case INSEREFOTOALTERARCLIENTE:
      return { ...state, fotoImagemClienteTelaAlterar: action.payload };
    case ALTERAKEYCLIENTETELAALTERAR:
      return { ...state, keyCliente: action.payload };
    case ATIVALOADINGBOTAOALTERARCLIENTE:
      return {
        ...state,
        loadingBotaoAlterarCliente: action.payload,
        mensagemSucessoAoCadastrarClienteTelaAlterar: "",
        mensagemerrorAoCadastrarClienteTelaAlterar: "",
        backgroundMensagemErrorTelaAlterarCliente: null
      };
    case MENSAGEMCADASTRACLIENTEERRORTELAALTERAR:
      return {
        ...state,
        mensagemerrorAoCadastrarClienteTelaAlterar: action.payload,
        mensagemSucessoAoCadastrarClienteTelaAlterar: "",
        loadingBotaoAlterarCliente: false,
        backgroundMensagemErrorTelaAlterarCliente: "#DC143C"
      };
    case MENSAGEMCADASTRACLIENTESUCESSOTELAALTERAR:
      return {
        ...state,
        mensagemSucessoAoCadastrarClienteTelaAlterar: action.payload,
        mensagemerrorAoCadastrarClienteTelaAlterar: "",
        loadingBotaoAlterarCliente: false
      };
    case ZERAMENSAGEMDESUCESSOEERRO:
      return {
        ...state,
        mensagemSucessoAoCadastrarClienteTelaAlterar: "",
        mensagemerrorAoCadastrarClienteTelaAlterar: "",
        backgroundMensagemErrorTelaAlterarCliente: null
      };
    case SEMCONEXAOINTERNETCONSULTACLIENTE:
      return { ...state, verificaInternetConsultaCliente: action.payload, loadingBotaoAlterarCliente: false, };
    case SEMCONEXAOINTERNETCADASTRACLIENTE:
      return { ...state, verificaInternetCadastraCliente: action.payload };
    case SEMCONEXAOINTERNETALTERACLIENTE:
      return { ...state, verificaInternetAlteraCliente: action.payload };
    case ATIVALOADINGBOTAOCADASTRARCHAMADO:
      return { ...state, loadingBotaoCadastrarChamado: action.payload };
    case SEMCONEXAOINTERNETCADASTRACHAMADO:
      return {
        ...state,
        verificaInternetCadastrarChamado: action.payload,
        loadingBotaoCadastrarChamado: false
      };
    case CHAMADOCADASTRADOSUCESSO:
      return {
        ...state,
        mensagemChamadoCadastradoSucesso: action.payload,
        //  loadingBotaoCadastrarChamado: false

      };
    case LISTACHAMADOABERTO:
      return { ...state, chamados: [...action.payload] };
    case VERIFICACONEXAOINTERNETCHAMADOABERTO:
      return { ...state, verificaConexaoInternetListaChamadoAberto: action.payload };
    case ZERAMENSAGEMSEMINTERNETCHAMADOABERTO:
      return { ...state, verificaConexaoInternetListaChamadoAberto: action.payload }
    case ATIVALOADINGTELACHAMADOABERTO:
      return { ...state, loadingTelaChamadoAberto: action.payload }
    case ATIVADESATIVALOADINGREFRESHCONTROLCHAMADOABERTO:
      return { ...state, refreshControlFlatListChamadoAberto: action.payload }
    case ENVIAULTIAMREFERENCIAKEY:
      return { ...state, referenciaUltimaKey: action.payload }
    case MODIFICANOMEATENDENTESELECIONADO:
      return { ...state, nomeAuxAtendente: action.payload }
    case MODIFICANOMECLIENTESELECIONADO:
      return { ...state, nomeAuxCliente: action.payload }
    case ZERACADASTROCHAMADO:
      return {
        ...state, loadingBotaoCadastrarChamado: false, clienteSelecionado: [], atendenteSelecionado: [],
        setorSelecionado: [], prioridadeSelecionada: [], localAtendimentoSelecionado: [], descricaoAtendimento: "", dataVencimento: '',
        nomeArquivoUpado1: '', nomeArquivoUpado2: '', nomeArquivoUpado3: '', resDoArquivo1: null, resDoArquivo2: null, resDoArquivo3: null,
        arquivoUpado1: false, arquivoUpado2: false, arquivoUpado3: false, contagemArquivo: 0, tamanhoArquivo1: "", tamanhoArquivo2: "",
        tamanhoArquivo3: "", arquivosParaSerUpadosNaAberturaChamado: [], tamanhoTotalARquivoAnexado: 0, nomeAuxAtendente: '', nomeAuxCliente: '', empresaSelecionadaAoAbrirChamado: '', keyEmpresaVinculadaAoAbrirAlterarChamado: '',
        solucaoAtendimento: '', problemaEstaticoSelecionadoNovoAtendimento: '', nomeProblemaEstaticoSelecionadoNovoAtendimento: ''
      }
    case ATIVADESATIVALOADINGFINALFLATLISTCHAMADOABERTO:
      return { ...state, loadingFimDaPagina: action.payload }
    case VERIFICACONEXAOINTERNETCHAMADOEMATENDIMENTO:
      return { ...state, verificaConexaoInternetListaChamadoEmAtendimento: action.payload }
    case ZERAMENSAGEMSEMINTERNETCHAMADOEMATENDIMENTO:
      return { ...state, verificaConexaoInternetListaChamadoEmAtendimento: action.payload }
    case ENVIACHAMADOEMATENDIMENTO:
      return { ...state, chamadosEmAtendimentos: action.payload }
    case ATIVADESATIVALOADINGTELACHAMADOATENDIMENTO:
      return { ...state, loadingTelaEmAtendimento: action.payload }
    case ENVIAULTIAMREFERENCIAKEYEMATENDIMENTO:
      return { ...state, referenciaUltimaKeyEmAtendimento: action.payload }
    case ATIVADESATIVALOADINGFINALFLATLISTCHAMADOEMATENDIMENTO:
      return { ...state, loadingFimDaPaginaEmAtendimento: action.payload }
    case ENVIACHAMADOFECHADO:
      return { ...state, chamadosFechado: action.payload }
    case ATIVADESATIVALOADINGTELACHAMADOFECHADO:
      return { ...state, loadingTelaFechado: action.payload }
    case VERIFICACONEXAOINTERNETCHAMADOFECHADO:
      return { ...state, verificaConexaoInternetListaChamadoFechado: action.payload }
    case ZERAMENSAGEMSEMINTERNETCHAMADOFECHADO:
      return { ...state, verificaConexaoInternetListaChamadoFechado: action.payload }
    case ENVIAULTIAMREFERENCIAKEYFECHADO:
      return { ...state, referenciaUltimaKeyFechado: action.payload }
    case ATIVADESATIVALOADINGFINALFLATLISTCHAMADOFECHADO:
      return { ...state, loadingFimDaPaginaFechado: action.payload }
    case ZERAMENSAGEMSEMINTERNETCHAMADOCANCELADO:
      return { ...state, verificaConexaoInternetListaChamadoCancelado: action.payload }
    case ENVIACHAMADOCANCELADO:
      return { ...state, chamadosCancelados: action.payload }
    case ATIVADESATIVALOADINGTELACHAMADOCANCELADO:
      return { ...state, loadingTelaCancelado: action.payload }
    case VERIFICACONEXAOINTERNETCHAMADOCANCELADO:
      return { ...state, verificaConexaoInternetListaChamadoCancelado: action.payload }
    case ENVIAULTIAMREFERENCIAKEYCANCELADO:
      return { ...state, referenciaUltimaKeyCancelado: action.payload }
    case ATIVADESATIVALOADINGFINALFLATLISTCHAMADOCANCELADO:
      return { ...state, loadingFimDaPaginaCancelado: action.payload }
    case ENVIACHAMADOTODOS:
      return { ...state, chamadoTodos: action.payload }
    case ATIVADESATIVALOADINGTELACHAMADOTODOS:
      return { ...state, loadingTelaTodos: action.payload }
    case VERIFICACONEXAOINTERNETCHAMADOTODOS:
      return { ...state, verificaConexaoInternetListaChamadoTodos: action.payload }
    case ZERAMENSAGEMSEMINTERNETCHAMADOTODOS:
      return { ...state, verificaConexaoInternetListaChamadoTodos: action.payload }
    case ENVIAULTIAMREFERENCIACHAMADOABERTO:
      return { ...state, keyTodosChamadoAberto: action.payload }
    case ENVIAULTIAMREFERENCIACHAMADOEMATENDIMENTO:
      return { ...state, keyTodosChamadoEmAtendimento: action.payload }
    case ENVIAULTIAMREFERENCIACHAMADOFECHADO:
      return { ...state, keyTodosChamadoFechado: action.payload }
    case ENVIAULTIAMREFERENCIACHAMADOCANCELADO:
      return { ...state, keyTodosChamadoCancelado: action.payload }
    case ATIVADESATIVALOADINGFINALFLATLISTCHAMADOTODOS:
      return { ...state, loadingFimDaPaginaTodos: action.payload }
    case INDICAFIMDALISTA:
      return { ...state, indicaFimDaListaTodos: action.payload }
    case ENVIAULTIAMREFERENCIAKEYTODOS:
      return { ...state, keyTodos: action.payload }
    case ATIVADESATIVALOADINGCARREGARCLIENTETELACHAMADOTODOS:
      return { ...state, loadingClienteTelaChamadoTodos: action.payload }
    case MODIFICACLIENTESELECIONADOTELACHAMADOTODOS:
      return { ...state, clienteSelecionadoTelaChamadoTodos: action.payload }
    case MODIFICANOMECLIENTESELECIONADOTELACHAMADOTODOS:
      return { ...state, nomeClienteSelecionadoTelaChamadoTodos: action.payload }
    case ENVIAULTIAMREFERENCIAKEYCLIENTECHAMADOTODOS:
      return { ...state, keyClienteChamadoTodos: action.payload }
    case ATIVADESATIVALOADINGTELACONSULTACLIENTE:
      return { ...state, loadingTelaConsultaCliente: action.payload }
    case ENVIAULTIAMREFERENCIAKEYTELACONSULTACLIENTE:
      return { ...state, referenciaUltimaKeyTelaConsultaCliente: action.payload }
    case ATIVADESATIVALOADINGFINALFLATLISTTELACONSULTACLIENTE:
      return { ...state, loadingFimDaPAginaTelaConsultaCliente: action.payload }
    case ATIVADESATIVALOADINGCARREGARCLIENTETELACONSULTACLIENTE:
      return { ...state, loadingCarregarClientesSectionedConsultaCliente: action.payload }
    case MODIFICANOMECLIENTESELECIONADOTELACONSULTACLIENTE:
      return { ...state, nomeClienteSelecionadoTelaConsultaCliente: action.payload }
    case MODIFICACLIENTESELECIONADOTELACONSULTACLIENTE:
      return { ...state, clienteSelecionadoTelaConsultaCliente: action.payload }
    case MODIFICACLIENTESELECIONADOTELACHAMADOTODOSTESTE:
      return { ...state, keyTodos: action.payload }
    case ATIVADESATIVALOADINGREFRESHCONTROLCONSULTACLIENTE:
      return { ...state, refreshControlConsultaCliente: action.payload }
    case ATIVADESATIVALOADINGREFRESHCONTROLCHAMADOEMATENDIMENTO:
      return { ...state, refreshControlFlatListChamadoEmAtendimento: action.payload }
    case ATIVADESATIVALOADINGREFRESHCONTROLCHAMADOFECHADO:
      return { ...state, refreshControlFlatListChamadoFechado: action.payload }
    case ATIVADESATIVALOADINGREFRESHCONTROLCHAMADOCANCELADO:
      return { ...state, refreshControlFlatListChamadoCancelado: action.payload }
    case MODIFICADATAVENCIMENTOTELACHAMADOABERTURA:
      return { ...state, dataVencimento: action.payload }

    case MODIFICAARQUIVOUPADO1:
      return { ...state, arquivoUpado1: action.payload }
    case MODIFICAARQUIVOUPADO2:
      return { ...state, arquivoUpado2: action.payload }
    case MODIFICAARQUIVOUPADO3:
      return { ...state, arquivoUpado3: action.payload }

    case MODIFICACONTAGEMARQUIVO:
      return { ...state, contagemArquivo: action.payload }
    case MODIFICATAMANHOARQUIVO1:
      return { ...state, tamanhoArquivo1: action.payload }
    case MODIFICATAMANHOARQUIVO2:
      return { ...state, tamanhoArquivo2: action.payload }
    case MODIFICATAMANHOARQUIVO3:
      return { ...state, tamanhoArquivo3: action.payload }
    case ALTERAKEYCHAMADO:
      return { ...state, keyAtendimento: action.payload }
    case ALTERANUMEROATENDIMENTO:
      return { ...state, numeroAtendimento: action.payload }

    case ENVIAATENDIMENTOPARAREDUCER:
      return { ...state, dadosAtendimentos: action.payload }
    case ATIVADESATIVALOADINGTELAACOESATENDIMENTO:
      return { ...state, loadingTelaAcoesAtendimento: action.payload }
    case ENVIAUSUARIOSTELAACOESATENDIMENTO:
      return { ...state, atendentesAcoesChamado: action.payload }
    case VERIFICAERRORTELAALTERARCHAMADO:
      return { ...state, verificaErrorTelaAlterarChamado: action.payload }
    case VERIFICACHAMADOALTERADOSUCESSO:
      return { ...state, verificaChamadoAlteradoSucesso: action.payload }
    case ENVIAARQUIVOSUPADOSTELAACOESCHAMADOS:
      return { ...state, arrayArquivosUpados1: action.payload }
    case ENVIAARQUIVOSUPADOSTELAACOESCHAMADOS2:
      return { ...state, arrayArquivosUpados2: action.payload }
    case ENVIAARQUIVOSUPADOSTELAACOESCHAMADOS3:
      return { ...state, arrayArquivosUpados3: action.payload }
    case ENVIAACOESATENDIMENTOPARAREDUCER:
      return { ...state, acoesDoAtendimento: action.payload }
    case MODIFICAMOTIVOCANCELAMENTOTELAACOESATENDIMENTO:
      return { ...state, motivoCancelamento: action.payload }
    case MODIFICAMENSAGEMERRORMOTIVOCANCELAMENTO:
      return { ...state, mensagemErroMotivoCancelamento: action.payload }
    case MODIFICAVISIBILIDADEMODALCANCELAR:
      return { ...state, loadingModalCancelarAtendimento: action.payload }
    case MODIFICAVISIBILIDADEMODALCOMENTARIo:
      return { ...state, modalComentarioVisible: action.payload }
    case ATIVADESATIVASWITCHCOMENTARIO:
      return { ...state, switchComentario: action.payload }
    case MODIFICAINPUTCOMENTARIO:
      return { ...state, inputComentario: action.payload }
    case MODIFICANOMEARQUIVOCOMENTARIOTELAACOESATENDIMENTO:
      return { ...state, nomeArquivoAnexadoComentario: action.payload }
    case MODIFICARESARQUIVOCOMENTARIOTELAACOESATENDIMENTO:
      return { ...state, resArquivoAnexadoComentario: action.payload }
    case MODIFICACOMENTARIOPUBLICOSIMOUNAO:
      return { ...state, comentarioPublicoSimOuNao: action.payload }
    case ZERAMENSAGEMSEMCONEXAOINTERNETSALVARCOMENTARIO:
      return { ...state, verificaInternetSalvarComentario: action.payload }
    case ZERAINFORMACOESCOMENTARIO:
      return {
        ...state,
        nomeArquivoAnexadoComentario: '',
        resArquivoAnexadoComentario: null,
        switchComentario: false,
        inputComentario: '',
        comentarioChat: '',
        verificaInternetSalvarComentario: '',
      }
    case ZERAINFORMACOESENVIOARQUIVOCHAT:
      return {
        ...state,
        nomeArquivoAnexadoComentario: '',
        resArquivoAnexadoComentario: null,
        switchComentario: false,
        inputComentario: '',
        verificaInternetSalvarComentario: '',
      }
    case ENVIAARQUIVOPARASERUPADOTELAABERTURACHAMADO:
      return { ...state, arquivosParaSerUpadosNaAberturaChamado: action.payload }

    case ENVIATAMANHOARQUIVOANEXADOTELAABERTURACHAMADO:
      return { ...state, tamanhoTotalARquivoAnexado: action.payload }
    case ENVIARQUIVOSUPADOSANEXADOSAOCHAMADO:
      return { ...state, arquivosUpadosVinculadoAoChamado: action.payload }
    case ATIVADESATIVALOADINGARQUIVOSUPADOSANEXADOSAOCHAMADO:
      return { ...state, loadingArquivosUpadosVinculadoAoChamado: action.payload }
    case DIMINUITAMANHOTOTALARQUIVOSANEXADOSLOCAL:
      return { ...state, tamanhoTotalARquivoAnexado: action.payload }

    case ATIVADESATIVALOADINGNUMEROEXCLUSIVOCADASTRACLIENTE:
      return { ...state, loadingverificaSeJaTemCodigoCadastraCliente: action.payload }
    case MODIFICANUMEROEXCLUSIVOCADASTRACLIENTE:
      return { ...state, numeroExclusivoCadastraCliente: action.payload }
    case DESATIVALOADINGBOTAOCADASTRARCLIENTE:
      return { ...state, loadingBotaoCadastrarCliente: false }
    case MUDAVISIBILIDADEMODALCADASTRACLIENTEVERSAOPREMIUM:
      return { ...state, modalCadastraClienteVersaoPremium: action.payload }
    case VERIFICARSEEHOBRIGATORIOATRIBUIRATENDENTE:
      return { ...state, verificaSeAtribuirAtendenteObrigatorio: action.payload }
    case ALTERACLIENTESELECIONADOTELACADASTRACLIENTE:
      return { ...state, clienteSelecionadoConsultaClienteTelaCAdastraCliente: action.payload }
    case MODIFICANOMECLIENTESELECIONADOTELACADASTROCLIENTE:
      return { ...state, nomeEmpresaSelecionadoConsultaClienteTelaCAdastraCliente: action.payload }
    case ATIVADESATIVALOADINGTELAALTERARCLIENTE:
      return { ...state, loadingAlterarCliente: action.payload }
    case ENVIAEMPRESAPRINCIPALCLIENTE:
      return { ...state, clienteEmpresaPrincipal: action.payload }
    case MODIFICAENDERECOTELACADASTROCLIENTE:
      return { ...state, enderecoCliente: action.payload }
    case MODIFICATELEFONETELACADASTROCLIENTE:
      return { ...state, telefoneCliente: action.payload }
    case ATIVADESATIVALOADINGCARREGARCLIENTETELAALTERARCLIENTE:
      return { ...state, loadingEmpresasTelaAlterarCliente: action.payload }
    case ENVIAEMPRESASECTIONEDTELAALTERARCLIENTE:
      return { ...state, listaEmpresasTelaAlterarCliente: action.payload }
    /*   if (action.payload.length == 0) {
        return {
          ...state,
          listaEmpresasTelaAlterarCliente: [...action.payload],
        };
      } else {
        return {
          ...state,
          listaEmpresasTelaAlterarCliente: [{ name: "Empresas", id: 0 }, ...action.payload],
        };
      } */
    case ALTERAEMPRESASELECIONADOTELAALTERARCLIENTE:
      return { ...state, empresaSelecionadasTelaAlterarCliente: action.payload }
    case MODIFICANOMECLIENTESELECIONADOTELAALTERARCLIENTE:
      return { ...state, nomeEmpresaSelecionadoConsultaTelaAlterarCliente: action.payload }
    case MODIFICAEMPRESASELECIONADAAOABRIRCHAMADO:
      return { ...state, empresaSelecionadaAoAbrirChamado: action.payload }
    case MODIFICAKEYEMPRESAVINCULADASELECIONADAAOABRIRCHAMADO:
      return { ...state, keyEmpresaVinculadaAoAbrirAlterarChamado: action.payload }
    case ATIVADESATIVALOADINGBOTAOEXCLUIRCLIENTE:
      return { ...state, loadingBotaoExcluirCliente: action.payload }
    case MUDAVISIBILIDADEMODALEXCLUIRCLIENTE:
      return { ...state, visibleModalExcluirCliente: action.payload }
    case ATIVADESATIVALOADINGCARREGANDOCONFIGURACOESGERAIS:
      return { ...state, loadingVerificaCarregouConfigAtendimento: action.payload }

    case VERIFICAATENDENTEVERAPENASPROPRIOCHAMADO:
      return { ...state, verificaAtendenteVeSomenteProprioAtendimento: action.payload }
    case VERIFICAAGRUPARATENDENTESETOR:
      return { ...state, verificaAgrupaAtendenteSetor: action.payload }
    case MODIFICANOMESETORSELECIONADO:
      return { ...state, nomeAuxSetor: action.payload }
    case MODIFICAMODALFILTROCHAMADO:
      return { ...state, modalFiltroChamado: action.payload }
    case MODIFICAFILTROCHAMADO:
      return { ...state, filtroChamado: action.payload }
    case MODIFICASELECTTEXTCHAMADO:
      return { ...state, selectTextChamado: action.payload }
    case MODIFICAPESQUISATEXTCHAMADO:
      return { ...state, pesquisaTextChamado: action.payload }
    case MODIFICAVALORARRAYSECTIONEDFILTROCHAMADO:
      return { ...state, chamadosComFiltro: action.payload }
    case MODIFICAFILTROSELECIONADOTELACHAMADOABERTO:
      return { ...state, filtroSelecionado: action.payload }
    case MODIFICAFILTROSELECIONADOTELACHAMADOTODOS:
      return { ...state, filtroSelecionadoTodos: action.payload }
    case MODIFICAFILTROSELECIONADOTELACHAMADOEMATENDIMENTO:
      return { ...state, filtroSelecionadoEmAtendimento: action.payload }
    case MODIFICAFILTROSELECIONADOTELACHAMADOFECHADO:
      return { ...state, filtroSelecionadoFechado: action.payload }
    case MODIFICAFILTROSELECIONADOTELACHAMADOCANCELADO:
      return { ...state, filtroSelecionadoCancelado: action.payload }
    case ATIVADESATIVALOADINTESTETSTE:
      return { ...state, loadginteste: action.payload }
    case MODIFICATOASTACOESATNEDIMENTO:
      return { ...state, verificaInternetAcoesAtendimento: action.payload }
    case MODIFICAVISIBILIDADEMODALNOVOATENDIMENTO:
      return { ...state, modalNovoAtendimento: action.payload }
    case VISIBLESELECTSEARCH:
      return { ...state, visibleSelectSearch: action.payload }
    case MODIFICAVISIBILIDADEMODALALTERARATENDIMENTO:
      return { ...state, modalAlterarAtendimento: action.payload }
    case MODIFICAVISIBILIDADEMODALATRIBUIRATENDIMENTO:
      return { ...state, modalAtribuirAtendimento: action.payload }
    case MODIFICAVISIBILIDADEMODALADDTEMPOGASTOATENDIMENTO:
      return { ...state, modalAddTempoGasto: action.payload }
    case MODIFICAVISIBILIDADEMODALFINALIZARATENDIMENTO:
      return { ...state, modalStatusAtendimento: action.payload }
    case MODIFICAVISIBILIDADEMODALPRIORIDADEATENDIMENTO:
      return { ...state, modalPrioridadeAtendimento: action.payload }
    case MODIFICAVISIBILIDADEMODALCANCELARATENDIMENTO:
      return { ...state, modalCancelarAtendimento: action.payload }
    case MODIFICAVISIBLIDADEBOTAOADDVENCIMENTO:
      return { ...state, visibilidadeBotaoAddDataVencimento: action.payload }
    case MODIFICAFILTROCLIENTESTELACLIENTES:
      return { ...state, filtroClientes: action.payload }
    case MODIFICAVISIBLEMODALNOVOCLIENTE:
      return { ...state, modalNovoCliente: action.payload }
    case MODIFICATABINDEX:
      return { ...state, tabIndex: action.payload }
    case MODIFICAARQUIVOCOMOPLETOPARAUPAR:
      return { ...state, arquivoCompletoParaUpar: action.payload }
    case MODIFICAARQUIVOCOMOPLETOPARAUPARNOVOCADASTRO:
      return { ...state, arquivoCompletoParaUparNovoCadastro: action.payload }

    case MODIFICAEMPRESACLIENTETELACADASTROCLIENTENOVOCADASTRO:
      return { ...state, nomeEmpresaClienteNovoCadastro: action.payload }
    case ALTERAEMPRESASELECIONADOTELAALTERARCLIENTENOVOCADASTRO:
      return { ...state, empresaSelecionadasTelaCadastraCliente: action.payload }
    case MODIFICANOMECLIENTETELACADASTROCLIENTENOVOCADASTRO:
      return { ...state, nomeClienteNovoCadastro: action.payload }
    case MODIFICAENDERECOTELACADASTROCLIENTENOVOCADASTRO:
      return { ...state, enderecoClienteNovoCadastro: action.payload }
    case MODIFICATELEFONETELACADASTROCLIENTENOVOCADASTRO:
      return { ...state, telefoneClienteNovoCadastro: action.payload }
    case MODIFICAEMAILCLIENTETELACADASTROCLIENTENOVOCADASTRO:
      return { ...state, emailClienteNovoCadastro: action.payload }
    case MODIFICASENHACLIENTETELACADASTROCLIENTENOVOCADASTRO:
      return { ...state, senhaClienteNovoCadastro: action.payload }
    case MODIFICACONFIRMASENHACLIENTETELACADASTROCLIENTENOVOCADASTRO:
      return { ...state, confirmaSenhaClienteNovoCadastro: action.payload }
    case ALTERAVALORSWITCHTELACLIENTENOVOCADASTRO:
      return { ...state, valorSwitchNovoCadastro: action.payload }
    case MODIFICANOMECLIENTESELECIONADOTELAALTERARCLIENTENOVOCADASTRO:
      return { ...state, nomeClienteSelecionadoTelaCadastroCLiente: action.payload }
    case MODIFICAMOSTRARMAISCHAMADOS:
      return { ...state, mostrarMaisDetalhesChamado: action.payload }
    case MODIFICARETORNATELACLIENTESPARANOVOATENDIMENTO:
      return { ...state, retornaTelaClienteParaNovoAtendimentos: action.payload }
    case ENVIAATENDIMENTOEMESPERATENDIMENTOONLINE:
      return { ...state, chamadosEmEsperaAtendimentoOnline: action.payload }
    case MODIFICAKEYATENDIMENTOEMESPERAATENDIMENTOONLINE:
      return { ...state, keyAtendimentoEmEsperaAtendimentoOnline: action.payload }
    case MODIFICAATENDIMENTOEMESPERASELEECIONADO:
      return { ...state, atendimentoEmEsperaSelecionado: action.payload }

    case MODIFICACHAMADOSEMATENDIMENTOVIACHAT:
      return { ...state, chamadosEmAtendimentoOnline: action.payload }
    case MODIFICAKEYATENDIMENTOEMTENDIMENTOONLINE:
      return { ...state, keyAtendimentoEmAtendimentoOnline: action.payload }
    case ENVIAACOESATENDIMENTOPARAREDUCERCHAT:
      return { ...state, acoesDoAtendimentoChat: action.payload }
    case ENVIACONVERSASPARACHATEMESPERA:
      return { ...state, conversasChatEmEspera: action.payload }
    case ENVIAATENDIMENTOPARAREDUCERCHAT:
      return { ...state, dadosAtendimentosChat: action.payload }
    case MODIFICAVISIBILIDADEMODALATENDIMENTOONLINE:
      return { ...state, modalDetalhesAtendimentoOnline: action.payload }
    case ALTERACOMENTARIOCHAT:
      return { ...state, comentarioChat: action.payload }
    case MODIFICATEXTARQUIVOCHAT:
      return { ...state, textArquivoChat: action.payload }
    case MODIFICAUPANDOARQUIVOCHAT:
      return { ...state, enviandoComentario: action.payload }
    case ENVIAATENDIMENTOPORKEYREDUCERCHAT:
      return { ...state, atendimentosPorKeyReducerChat: action.payload }
    case ENVIAATENDIMENTOPORKEYREDUCERCHATEMESPERA:
      return { ...state, atendimentosPorKeyReducerChatEmEspera: action.payload }

    case MODIFICALIDOENAOLIDOATENDIMENTOPORKEYREDUCERCHAT:
      let arr = state.atendimentosPorKeyReducerChat;
      // arr[action.key] = { ...arr[action.key] }        // Object.assign({}, arr[index])
      arr[action.key][action.newmessage] = action.value
      return { ...state, atendimentosPorKeyReducerChat: arr }
    case ATIVADESATIVALOADINGCHATONLINE:
      return { ...state, loadingAtendimentoViaChat: action.payload }
    case MODIFICAVISIBILIDADEMODALPORTALCLIENTE:
      return { ...state, modalPortalCliente: action.payload }
    case MODIFICAVISIBILIDADEMODALIMPORTARCLIENTES:
      return { ...state, modalImportarClientes: action.payload }
    case MODIFICALOADINGCADASTRANDOCLIENTEEMMASSA:
      return { ...state, loadingCadastrandoClienteMassa: action.payload }
    case MODIFICACLICOUCFGCHAT:
      return { ...state, clicouCFGchat: action.payload }
    case ATIVADESATIVALOADINGMAISRESULTADOSCHAMADOSCLIENTE:
      return { ...state, loadingMaisResultadosChamados: action.payload }
    case ATIVADESATIVALOADINGENVIANDOAUDIOCHAT:
      return { ...state, loadingEnviandoAudioChat: action.payload }
    case MODIFICARESOLUCAOPROBLEMA:
      return { ...state, solucaoAtendimento: action.payload }
    case ENVIAULTIMONOMEREFENCIATELACONSULTACLIENTE:
      return { ...state, referenciaUltimoNomeCliente: action.payload }
    case ENVIADADOSWHATSAPPCONFIG:
      return { ...state, configWhatsapp: action.payload }
    case ENVIACONTATOSPARAREDUCER:
      return { ...state, allContactsWhatsapp: action.payload }
    case MODIFICAKEYCONTACTS:
      return { ...state, keyContacts: action.payload }
    case MODIFICADADOSCONTACTSSELECIONADO:
      return { ...state, dadosContactsSelecionado: action.payload }
    case ENVIACONVERSASULTIMOCHAMADO:
      return { ...state, conversasUltimoAtendimento: action.payload }
    case ATIVADESATIVALOADINGCARREGACHAMADOSANTIGOS:
      return { ...state, loadingCarregaConversasUltimoAtendimento: action.payload }
    case ATIVADESATIVALOADINGCARREGAMAISCHAMADOSANTIGOS:
      return { ...state, refreshControlCarregaMaisChamados: action.payload }
    case ENVIAULTIMAREEFERENCIAKEYCONTACTS:
      return { ...state, referenciaUltimakeyUltimoChamadoContatos: action.payload }
    case ATIVADESATIVALOADINGINICIANDOCONVERSACONTACTS:
      return { ...state, loadingIniciandoConversaViaContacts: action.payload }
    case ATIVADESATIVALOADINGCARREGANDOCHAMADOSEMATENDIMENTO:
      return { ...state, loadingCarregaChatsEmAtendimento: action.payload }
    case ATIVADESATIVALOADINGCARREGACONTATOSWPP:
      return { ...state, loadingCarregaContatosWpp: action.payload }
    case INSEREMENSAGEMERRORGETCONTACTS:
      return { ...state, loadingAssinalaErrorGetContatcs: action.payload }
    case ENVIAULTIMAREEFERENCIAKEYEMESPERA:
      return { ...state, referenciaUltimakeyUltimoChamadoEmEspera: action.payload }
    case ATIVADESATIVALOADINGCARREGACHAMADOSANTIGOSEMESPERA:
      return { ...state, refreshControlCarregaMaisChamadosEmEspera: action.payload }
    case ENVIACONVERSASCARREGADASEMESPERA:
      return { ...state, conversasCarregadasEmEspera: action.payload }

    case ENVIAULTIMAREEFERENCIAKEYEMATENDIMENTO:
      return { ...state, referenciaUltimakeyUltimoChamadoEmAtendimento: action.payload }
    case ATIVADESATIVALOADINGCARREGACHAMADOSANTIGOSEMATENDIMENTO:
      return { ...state, refreshControlCarregaMaisChamadosEmAtendimento: action.payload }
    case ENVIACONVERSASCARREGADASEMATENDIMENTO:
      return { ...state, conversasCarregadasEmAtendimento: action.payload }

    case ATIVADESATIVALOADINGCARREGARESPOSTAANTIGAS:
      return { ...state, loadingCarregandoRespostasAntigas: action.payload }
    case ENVIAIDRESPOSTAANTIGA:
      return { ...state, idRespostaAntiga: action.payload }
    case MODIFICANOMECLIENTETELACHATONLINE:
      return { ...state, nomeClienteChatOnline: action.payload }

    case MODIFICAARQUIVOCOMOPLETOPARAUPARNOVOCONTATO:
      return { ...state, arquivoCompletoParaUparNovoContato: action.payload }
    case INSEREFOTOCADASTROCONTATO:
      return { ...state, uriImagemPadraoTelaCadastroNovoContato: action.payload }
    case MODIFICAVISIBILIDADEMODALADICIONARCONTATO:
      return { ...state, modalNovoContato: action.payload }
    case MODIFICANOMECONTATO:
      return { ...state, nomeContato: action.payload }
    case MODIFICATELEFONECONTATO:
      return { ...state, telefoneContato: action.payload }
    case MODIFICALOADINGCADASTRANOVOCONTATO:
      return { ...state, loadingBotaoCadastrarContato: action.payload }
    case ZERAINFORMACOESCADASTROCONTATO:
      return {
        ...state,
        nomeContato: '',
        telefoneContato: '55',
        loadingBotaoCadastrarContato: false,
        uriImagemPadraoTelaCadastroNovoContato: imagemPadraoCliente,
        modalNovoContato: false,
        arquivoCompletoParaUparNovoContato: ''
      };
    case MODIFICAMENSAGEMFINALIZAATENDIMENTOWPP:
      return { ...state, mensagemFinalizacaoAtendimentoWpp: action.payload }
    case MODIFICATITLETAB:
      return { ...state, countMensagensNaoLidas: action.payload }
    case ATIVADESATIVALOADINGCARREGANDOCONVERSASCHATATUALA:
      return { ...state, loadingCarregandoConversasChatAtual: action.payload }
    case ENVIAULTIMAKEYREFERENCIACONVERSASCARREGADASCHAT:
      return { ...state, ultimaKeyReferenciaConversasChatATual: action.payload }

    case ATIVADESATIVALOADINGCARREGANDOCONVERSASCHATATUALAEMEPSERA:
      return { ...state, loadingCarregandoConversasChatAtualEmEspera: action.payload }
    case ENVIAULTIMAKEYREFERENCIACONVERSASCARREGADASCHATEMESPERA:
      return { ...state, ultimaKeyReferenciaConversasChatATualEmEspera: action.payload }

    case ATIVADESATIVALOADINGCARREGANDOMAISACOESCHAMADOACOESCHAMADO:
      return { ...state, loadingCarregandoMaisConversasChatAcoesChamado: action.payload }
    case ENVIAREFERENCIAULTIMAKEYACOESCHAMADOACOESCHAMADO:
      return { ...state, referenciaUltimaKeyChatAppAcoesChamado: action.payload }
    case ENVIACONVERSASANTIGASCHATACOESCHAMADO:
      return { ...state, arrayConversasAntigasChatAcoesChamado: action.payload }
    case ATIVADESATIVALOADINGCARREGARESPOSTAANTIGASACOESCHAMADO:
      return { ...state, loadingCarregandoRespostasAntigasAcoesChamado: action.payload }
    case ENVIAIDRESPOSTAANTIGAACOESCHAMADO:
      return { ...state, idRespostaAntigaAcoesChamado: action.payload }
    case MODIFICAOBRIGATORIOINFORMARRESOLUCAOCHAMADODASHBOARD:
      return { ...state, obrigatorioInformarResolucaoChamado: action.payload }

    case MODIFICALOADINGCARREGANDOCHAMADOSEMESPERAREFRESHCONTROL:
      return { ...state, loadingCarregandoChamadosEmEsperaRefreshControl: action.payload }
    case MODIFICACHAMADOSCARREGADOSEMESPERA:
      return { ...state, chamadosCarregadosEmEspera: action.payload }
    case MODIFICAKEYREFERENCIAULTIMOCHAMADOEMESPERA:
      return { ...state, keyReferenciaUltimoChamadoEmEspera: action.payload }

    case MODIFICATIMEZONECHATONLINE:
      return { ...state, timezoneChatOnline: action.payload }

    case MODIFICAVISIBILIDADEMODALEXPORTARCLIENTES:
      return { ...state, modalExportarClientes: action.payload }
    case MODIFICAMODALRESPOSTAPADRAO:
      return { ...state, modalRespostaPadrao: action.payload }

    case MODIFICAPROBLEMAESTATICOSELECIONADONOVOATENDIMENTO:
      return { ...state, problemaEstaticoSelecionadoNovoAtendimento: action.payload }
    case MODIFICANOMEPROBLEMAESTATICOSELECIONADONOVOATENDIMENTO:
      return { ...state, nomeProblemaEstaticoSelecionadoNovoAtendimento: action.payload }
    case MODIFICAATENDENTEVEAPENASPROPRIOHISTORICOCHAMADO:
      return { ...state, atendenteVeApenasProprioHistoricoChamado: action.payload }

    case MODIFICAURLPREVIEWIMAGE:
      return { ...state, urlPreViewImage: action.payload }
    case MODIFICAMODALPREVIEWIMAGE:
      return { ...state, modalViewImage: action.payload }

    case MODIFICANAMEPREVIEWIMAGE:
      return { ...state, nameUrlPreview: action.payload }

    case MODIFICAENVIARMENSAGEMTRANSSETORWPP:
      return { ...state, enviarMensagemTransSetorWpp: action.payload }

    case MODIFICAVISIBILIDADEMODALALTERARCHAT:
      return { ...state, modalAlterarChat: action.payload }

    case MODIFICACLIENTESFILTRADOSAPOSDIGITO:
      return { ...state, clientesFiltradosAposDigito: action.payload }
    default:
      return state;
  }
};
