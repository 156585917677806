import React, { } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-responsive-modal';
import { withRouter } from "react-router-dom";
import SelectSearch from 'react-select-search';
import moment from 'moment';
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import pt from 'date-fns/locale/pt-BR';
//import 'react-responsive-modal/styles.css';
import "react-datepicker/dist/react-datepicker.css";
import ClipLoader from "react-spinners/ClipLoader";
import Dropzone from 'react-dropzone';
import { toastr } from 'react-redux-toastr';
import FlatList from 'flatlist-react';

import {
    Title, Container, TabsModified, TabListModified, TabModified, TabPanelModified,
    ModalModified, ContainerModal, ContainerIconeFecharModal, IconeFechar, TextNovoAtendimento,
    DivAdicionarMaisDetalhes, TextAddDetalhe, SubContainerNovoAtendimento, DivBotaoNovoAtendimento,
    BotaoCadastrarAtendimento, TextCadastrar, ContainerInputDescricaoChamado, InputDescricaoChamado,
    ContainerSelectSearch, ContainerDetalhesModalNovoAtendimento, BotaoAdd, IconeClip, TextTab,
    IconeTab, InputSearch, DivInputSearch, IconeSetaPraBaixo, InputDataVencimento,
    IconeSetaPraBaixoDataVencimento, TextArquivoAnexado
} from './styled';
import ChamadoAberto from '../ChamadoAberto/index';
import ChamadoAtendendo from '../ChamadoAtendendo/index';
import ChamadoFechado from '../ChamadoFechado/index';
import ChamadoCancelado from '../ChamadoCancelado/index';
import ChamadoTodos from '../ChamadoTodos/index';
import ReactTooltip from "react-tooltip";
import SelectSearchPaginate from "../SelectSearch/index";
import 'react-tabs/style/react-tabs.css';

import {
    modificaVisibilidadeModalNovoAtendimento, teste, listaClientesTelaConsultaClientes,

    modificaClienteSelecionado, modificaAtendenteSelecionado, modificaPrioridadeSelecionado,
    modificaLocalSelecionado, modificaSetorSelecionado, modificaDataVencimentoTelaChamadoAbertura,
    alteraDescricaoChamado, cadastraNovoAtendimento, enviaTamanhoArquivoAnexadoTelAberturaChamado,
    enviaArquivosParaFirebaseTelaAbrirChamado, modificaContagemArquivo, enviaArquivosParaArrayDeArquivosAnexados,
    diminuiTamanhoTotalARquivosAnexados, modificaTabIndex, modificaMostrarMaisChamados,
    zeraInformacoesCadastroClienteExport, modificaVisibleModalNovoCLiente, modificaRetornaTelaClienteParaNovoAtendimento,
    modificaEmpresaSelecionadaAoAbrirChamado, modificaKeyEmpresaVinculadaSelecionadaAoAbrirChamado, listaProblemasEstaticosTelaNovoChamado,
    modificaProblemaEstaticoSelecionadoNovoAtendimento, modificaNomeProblemaEstaticoSelecionadoNovoAtendimento,
    testaVerificaTempoLimiteAtingido
} from '../../actions/ChamadoAction';
import {
    modificaVisibleModalNovoAtendente, zeraInformacoesCadastroAtendenteExport, listaAtendentesTelaConsultaAtendente
} from '../../actions/AtendenteAction';
import {

    modificaVisibleModalNovoSetor, listaSetorSectionedTelaConsultaSetor
} from '../../actions/SetorAction';

import { modificaNomeRotaNoHeader } from '../../actions/HeaderAction';
import { listaRespostasPadroes } from '../../actions/ConfiguracaoAction';
import { desligaOuvintesChamadosFirebase } from '../../actions/DashBoardAction';


/* moment.locale('pt-br', {
    months: 'Janeiro_Fevereiro_Março_Abril_Maio_Junho_Julho_Agosto_Setembro_Outubro_Novembro_Dezembro'.split('_')
    //  weekdays:'Seg_Ter_Qua_Qui_Sex_Sab_Dom'.split('_'),
}) */

class Atendimentos extends React.Component {
    state = {
        value: 0,
        search: false,
        mostrarMaisDetalhesChamado: false,
        zerouOuvintesAtendimentos: false,
        dataVencimento: '',
        clientSelected: null
    }

    /*  constructor(props) {
         super(props)
         this.myRef = React.createRef()
     } */

    componentDidMount() {
        //
        if (this.props.criarChamadoProblemaEstaticoAtendimento == true) {
            this.props.listaProblemasEstaticosTelaNovoChamado();
        }
        this.props.listaClientesTelaConsultaClientes();

        this.props.listaAtendentesTelaConsultaAtendente();
        this.props.listaSetorSectionedTelaConsultaSetor();

        //this.props.testaVerificaTempoLimiteAtingido()


        this.unlistRoutes = this.props.history.listen((route) => {
            //
            //
            //
            //
            if (route.pathname == '/visualizaatendimento' || route.pathname == '/atendimentos') {

            } else {
                //
                this.props.desligaOuvintesChamadosFirebase('atendimentos', '', '', '');
                this.setState({ zerouOuvintesAtendimentos: true });
                this.unlistRoutes();
            }
        });
        ReactTooltip.rebuild();
        if (this.props.nomeRota !== 'Atendimentos') {
            this.props.modificaNomeRotaNoHeader('Atendimentos')
        }
        // this.props.listaClientesTelaNovoChamado();
        //  this.props.listaSetorTelaNovoChamado();
    }

    componentWillUnmount() {
        //  toastr.warning('Will unmount', 'Atendimentos');
        this.props.desligaOuvintesChamadosFirebase('atendimentos', '', '', '');

    }

    /* componentWillReceiveProps(nextProps) {


        if (this.props.filtroChamado !== nextProps.filtroChamado && nextProps.filtroChamado !== '') {


            //this.myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
           // window.scrollTo(0, this.myRef.current.offsetTop);
           this.myRef.current.scrollIntoView({ behavior: 'smooth' })
            // this.executeScroll()

        }
    } */
    /*  componentWillReceiveProps(nextProps) {


         if (this.props.filtroChamado !== nextProps.filtroChamado && nextProps.filtroChamado !== '') {

             window.scrollTo(0, 0);
         }
     } */

    /*     onRouteChange(route) {




            if (route.pathname == '/visualizaatendimento' || route.pathname == '/atendimentos') {

            } else {
                this.props.desligaOuvintesChamadosFirebase('atendimentos', '', '', '');
                this.setState({ zerouOuvintesAtendimentos: true })
            }

        } */
    handleMostrarMaisChamados() {
        this.props.modificaMostrarMaisChamados(!this.props.mostrarMaisDetalhesChamado)
        /*   let state = this.state;
          state.mostrarMaisDetalhesChamado = !this.state.mostrarMaisDetalhesChamado;
          this.setState({ state }) */
    }

    renderBordaDrag(isDragActive, isDragAccept, isDragReject) {
        if (isDragAccept == true) {
            return '#00FF7F';
        } else {
            return 'red';
        }
    }

    renderBotaoCadastrarChamado() {
        if (this.props.loadingBotaoCadastrarChamado) {
            return (
                <ClipLoader
                    /* css={{ margin: 10 }} */
                    size={30}
                    color={this.props.corBotaoPadrao}
                    loading={this.props.loadingBotaoCadastrarChamado}
                />
            )
        } else {
            return (
                <BotaoCadastrarAtendimento type="button" onClick={() => {
                    this.props.cadastraNovoAtendimento(
                        this.props.clienteSelecionado,
                        this.props.atendenteSelecionado,
                        this.props.setorSelecionado,
                        this.props.prioridadeSelecionada,
                        this.props.localAtendimentoSelecionado,
                        this.props.descricaoAtendimento,
                        this.props.chamados,
                        this.props.nomeAuxCliente,
                        this.props.nomeAuxAtendente,
                        this.props.dataVencimento,
                        this.props.arquivosParaSerUpadosNaAberturaChamado,
                        this.props.empresaSelecionadaAoAbrirChamado,
                        this.props.keyEmpresaVinculadaAoAbrirAlterarChamado,
                        this.props.verificaAtendenteVeSomenteProprioAtendimento,
                        this.props.verificaAgrupaAtendenteSetor,
                        this.props.contaPrincipal,
                        this.props.nomeAuxSetor,
                        this.props.criarChamadoProblemaEstaticoAtendimento,
                        this.props.problemaEstaticoSelecionadoNovoAtendimento,
                        this.props.nomeProblemaEstaticoSelecionadoNovoAtendimento,
                    );
                    this.setState({ dataVencimento: '', clientSelected: null });
                }} backgroundDinamico={this.props.corBotaoPadrao}>
                    <TextCadastrar corLetraDinamico={this.props.letraBotaoPadrao}>Cadastrar</TextCadastrar>
                </BotaoCadastrarAtendimento>
            )
        }
    }

    bytesToSize(bytes) {
        var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
        if (bytes == 0) return "0 Byte";
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    }

    removeIndex(array, index) {
        return [
            ...array.slice(0, index),
            ...array.slice(index + 1)
        ];
    };

    apagaArquivoSelecionado(item) {
        //
        //
        let tamanhoArquivo = parseInt(item.size / 1024);
        let tamanhoDiminuido = this.props.tamanhoTotalARquivoAnexado - tamanhoArquivo;
        let arquivosAtualizados = [];
        let arrayComArquivos = this.props.arquivosParaSerUpadosNaAberturaChamado;
        for (let i = 0; i < arrayComArquivos.length; i++) {
            if (arrayComArquivos[i].key === item.key) {
                // arrayComArquivos.splice(i, 1);
                arquivosAtualizados = this.removeIndex(arrayComArquivos, i);
            }
        }
        // this.props.enviaArquivosParaArrayDeArquivosAnexados(arrayComArquivos)

        this.props.enviaArquivosParaArrayDeArquivosAnexados(arquivosAtualizados)
        this.props.diminuiTamanhoTotalARquivosAnexados(tamanhoDiminuido)
        toastr.success('', 'Arquivo excluído com sucesso');

    }

    renderItemFlatListArquivosAnexados(item) {
        //
        //

        return (
            <div style={{
                height: 20,
                minHeight: 20,
                width: "100%",
                flexDirection: "row",
                paddingLeft: 10,
                paddingRight: 10,
                marginTop: 3,
                marginBottom: 3,
                display: 'flex',
            }}>
                <div style={{
                    height: 20,
                    minHeight: 20,
                    width: "65%",
                    display: 'flex',
                    justifyContent: "start",
                    alignItems: 'center'
                }}>
                    <TextArquivoAnexado corLetraDinamica={this.props.corBotaoPadrao}>
                        {item.name}
                    </TextArquivoAnexado>
                </div>
                <div style={{
                    height: 20,
                    minHeight: 20,
                    width: "15%",
                    display: 'flex',
                    justifyContent: "start",
                    alignItems: 'center'
                }}>
                    <span style={{ color: this.props.coresLetraPadrao, fontSize: 12, fontFamily: 'Source Sans Pro' }}>
                        {this.bytesToSize(item.size)}
                    </span>
                </div>
                <div style={{
                    height: 20,
                    minHeight: 20,
                    width: "20%",
                    display: 'flex',
                    justifyContent: "start",
                    alignItems: 'center'
                }}>
                    <IconeFechar backgroundDinamico='#FF0000' onClick={() => {
                        this.apagaArquivoSelecionado(item);
                    }} className="icon-close" />

                </div>
            </div>
        )

    }

    escolheArquivoChamado(res) {
        if (res.length > 0) {
            //
            for (let i = 0; i < res.length; i++) {


                const tamanhoArquivo = parseInt(res[i].size / 1024);
                //sou vip e selecionei arquivo maior que 20mb
                if (tamanhoArquivo > 20000 && this.props.premium == 1) {
                    //3000 e o cliente não for VIP
                    toastr.error('Limite excedido', 'Você selecionou um arquivo maior que 20MB.');
                    break;
                } else if (this.props.premium == 0) {
                    toastr.error('Recurso Premium', 'Este é um recurso PREMIUM, para fazer upload de arquivos e imagens, Assine a versão PREMIUM e libere esse e mais recursos!');
                    break;
                } else {
                    let totalTamanho = this.props.tamanhoTotalARquivoAnexado + tamanhoArquivo;
                    //sou premium
                    if (this.props.premium == 0) {
                        toastr.error('Recurso Premium', 'Este é um recurso PREMIUM, para fazer upload de arquivos e imagens, Assine a versão PREMIUM e libere esse e mais recursos!');
                        break;
                    } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
                        toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
                        break;
                    } else {
                        if (totalTamanho <= 20000) {
                            this.props.enviaTamanhoArquivoAnexadoTelAberturaChamado(totalTamanho);
                            res[i].key = this.props.contagemArquivo.toString();
                            this.props.enviaArquivosParaFirebaseTelaAbrirChamado(this.props.arquivosParaSerUpadosNaAberturaChamado.concat(res[i]));
                            toastr.success('', 'Arquivo anexado com sucesso');
                            this.props.modificaContagemArquivo(this.props.contagemArquivo + 1);
                            /*   let reader = new FileReader();
                              reader.readAsDataURL(res[i]);
                              reader.onload = (event) => {


                              //    res[i].base64 = event.target.result;

                              }; */

                        } else {
                            toastr.error('Limite excedido', "Tamanho total dos arquivos anexados tem o limite de 20MB.");
                            break;
                        }
                    }

                }
            }
        } else {
            toastr.error('Error ao Anexar', 'Selecione arquivo com no máximo 20MB');

        }
    }

    render() {
        registerLocale('pt-BR', pt);
        setDefaultLocale("pt-BR");
        const CustomInputDataVencimento = ({ value, onClick }) => (
            <ContainerSelectSearch onClick={onClick}>
                <DivInputSearch >
                    <InputDataVencimento
                        readOnly={true}
                        corLetraDinamico={this.props.coresLetraPadrao}
                        backgroundDinamico={this.props.backgroundMaisEscuro}
                        placeholderColor={this.props.corLetraSecundaria}
                        corBordaDinamica={this.props.corBotaoPadrao}
                        type="text"
                        placeholder='Selecione a Data'
                        value={value}
                    >

                    </InputDataVencimento>
                    <IconeSetaPraBaixoDataVencimento className="icon-angle-arrow-down"
                        colorDinamico={this.props.coresLetraPadrao} />
                </DivInputSearch>
            </ContainerSelectSearch>
        );

        return (
            <Container>

                <Modal focusTrapped={false} showCloseIcon={false} open={this.props.modalNovoAtendimento} onClose={() => {
                }} center>
                    <ContainerModal backgroundDinamico={this.props.backgroundPrimeiro}>
                        <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                            <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Novo Atendimento</TextNovoAtendimento>
                            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                                <Dropzone
                                    multiple={true}
                                    noKeyboard
                                    maxSize={2e+7}
                                    noDrag
                                    onDrop={acceptedFiles => this.escolheArquivoChamado(acceptedFiles)}>
                                    {({ getRootProps, getInputProps, isDragActive }) => {
                                        //

                                        return (
                                            /*     <section> */
                                            <div  {...getRootProps()}>
                                                <input {...getInputProps()} />
                                                {/*  {
                                                    isDragActive ?
                                                        <p>Drop the files here ...</p> :
                                                        <p>Drag 'n' drop some files here, or click to select files</p>
                                                } */}
                                                <IconeClip backgroundDinamico={this.props.coresLetraPadrao} onClick={() => {
                                                }} className="icon-clip" />
                                            </div>
                                            /*   </section> */
                                        )
                                    }}
                                </Dropzone>

                                <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => {
                                    this.props.modificaVisibilidadeModalNovoAtendimento(false)
                                }} className="icon-close" />
                            </div>
                        </ContainerIconeFecharModal>
                        <Dropzone
                            multiple={true}
                            noKeyboard
                            maxSize={2e+7}
                            noClick
                            onDrop={acceptedFiles => this.escolheArquivoChamado(acceptedFiles)}>
                            {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject }) => {
                                //
                                if (isDragActive) {
                                    return (
                                        <div style={{
                                            display: 'flex',
                                            flex: 1,
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            padding: '20px',
                                            borderWidth: 2,
                                            borderRadius: 2,
                                            borderColor: this.renderBordaDrag(isDragActive, isDragAccept, isDragReject),
                                            borderStyle: 'dashed',
                                            color: '#bdbdbd',
                                            outline: 'none',
                                            transition: 'border .24s ease-in-out',
                                            justifyContent: 'center',
                                            /*  margin: 5, */
                                        }}  {...getRootProps()}>
                                            {/*   <input {...getInputProps()} /> */}
                                            <p style={{ color: this.props.coresLetraSecundario, textAlign: 'center', fontSize: 22 }}>Solte Arquivos para
                                                Anexar <i className="icon-clip" style={{ color: this.props.coresLetraPadrao, fontSize: 21 }} /></p>
                                            <p style={{
                                                color: this.props.coresLetraSecundario,
                                                textAlign: 'center',
                                                fontSize: 22,
                                                marginTop: 20
                                            }}>Tamanho Máximo de 20MB</p>
                                        </div>
                                    )
                                } else {
                                    return (
                                        /*     <section> */
                                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}  {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <DivAdicionarMaisDetalhes backgroundDinamico={this.props.corSegundoBotaoPadrao} onClick={() => {
                                                this.handleMostrarMaisChamados()
                                            }}>
                                                {this.props.mostrarMaisDetalhesChamado == false &&
                                                    <TextAddDetalhe corLetraDinamico={this.props.letraBotaoPadrao}>Adicionar mais
                                                        detalhes</TextAddDetalhe>}
                                                {this.props.mostrarMaisDetalhesChamado == false && <i className="icon-down-arrow" style={{
                                                    fontSize: 16,
                                                    marginRight: 15,
                                                    color: this.props.letraBotaoPadrao
                                                }} />}
                                                {this.props.mostrarMaisDetalhesChamado == true &&
                                                    <TextAddDetalhe corLetraDinamico={this.props.letraBotaoPadrao}>Ocultar detalhes</TextAddDetalhe>}
                                                {this.props.mostrarMaisDetalhesChamado == true && <i className="icon-up-arrow" style={{
                                                    fontSize: 15,
                                                    marginRight: 15,
                                                    color: this.props.letraBotaoPadrao
                                                }} />
                                                }

                                            </DivAdicionarMaisDetalhes>

                                            {this.props.mostrarMaisDetalhesChamado == true &&
                                                <ContainerDetalhesModalNovoAtendimento>
                                                    <SubContainerNovoAtendimento>
                                                        <p style={{
                                                            marginLeft: 20,
                                                            color: this.props.corLetraDescricaoDosCampos,
                                                            fontSize: 14
                                                        }}>Cliente:</p>
                                                        <ContainerSelectSearch>
                                                            {this.props.clientesNovoChamado.length === 0 && <div style={{ display: 'flex', flex: 1 }}>
                                                                <SelectSearchPaginate
                                                                    onSelectClient={(client) => {
                                                                        this.setState({ clientSelected: { value: client.value, label: client.label } });
                                                                        const keyEmpresaVinculada = client.keyEmpresaVinculada === "Não Vinculado" ? "" : client.keyEmpresaVinculada;
                                                                        this.props.modificaClienteSelecionado(client.idUser);
                                                                        this.props.modificaEmpresaSelecionadaAoAbrirChamado(client.empresa);
                                                                        this.props.modificaKeyEmpresaVinculadaSelecionadaAoAbrirChamado(keyEmpresaVinculada);
                                                                    }}
                                                                    value={this.state.clientSelected}
                                                                    isMyContact={false}
                                                                    background={this.props.backgroundMaisEscuro}
                                                                    letterColor={this.props.coresLetraPadrao}
                                                                    backgroundDinamico={this.props.backgroundMaisEscuro}
                                                                    placeholderColor={this.props.coresLetraSecundario}
                                                                    corBordaDinamica={this.props.corBotaoPadrao}
                                                                    width={400}
                                                                    letraBotaoPadrao={this.props.letraBotaoPadrao}
                                                                />
                                                            </div>}
                                                            {this.props.clientesNovoChamado.length > 0 && <SelectSearch
                                                                renderValue={(valueProps) =>
                                                                    <DivInputSearch>
                                                                        <InputSearch
                                                                            corLetraDinamico={this.props.coresLetraPadrao}
                                                                            backgroundDinamico={this.props.backgroundMaisEscuro}
                                                                            placeholderColor={this.props.corLetraSecundaria}
                                                                            corBordaDinamica={this.props.corBotaoPadrao}
                                                                            {...valueProps} ></InputSearch>
                                                                        <IconeSetaPraBaixo className="icon-angle-arrow-down"
                                                                            colorDinamico={this.props.coresLetraPadrao} />
                                                                    </DivInputSearch>
                                                                }
                                                                value={this.props.clienteSelecionado}
                                                                options={this.props.clientesNovoChamado}
                                                                onChange={(value, option) => {
                                                                    this.props.modificaClienteSelecionado(value);
                                                                    this.props.modificaEmpresaSelecionadaAoAbrirChamado(option.empresa)
                                                                    this.props.modificaKeyEmpresaVinculadaSelecionadaAoAbrirChamado(option.keyEmpresaVinculada)

                                                                }}
                                                                placeholder={this.props.clientesNovoChamado.length == 0 ? `Nenhum Cliente cadastrado` : "Selecione o Cliente"}
                                                                search={this.props.clientesNovoChamado.length == 0 ? false : true} />}

                                                            <BotaoAdd data-tip="Novo Cliente" data-for="tip-right"
                                                                backgroundDinamico={this.props.corBotaoPadrao} onClick={() => {
                                                                    this.props.history.push(("/clientes"));
                                                                    this.props.zeraInformacoesCadastroClienteExport();
                                                                    this.props.modificaVisibleModalNovoCLiente(true);
                                                                    this.props.modificaRetornaTelaClienteParaNovoAtendimento(true);
                                                                }}>
                                                                <i className="icon-add2-1"
                                                                    style={{ fontSize: 18, color: this.props.letraBotaoPadrao }} />
                                                            </BotaoAdd>
                                                        </ContainerSelectSearch>
                                                    </SubContainerNovoAtendimento>
                                                    <SubContainerNovoAtendimento>
                                                        <p style={{
                                                            marginLeft: 20,
                                                            color: this.props.corLetraDescricaoDosCampos,
                                                            fontSize: 14
                                                        }}>Atendente:</p>
                                                        <ContainerSelectSearch>
                                                            <SelectSearch
                                                                renderValue={(valueProps) =>
                                                                    <DivInputSearch>
                                                                        <InputSearch
                                                                            corLetraDinamico={this.props.coresLetraPadrao}
                                                                            backgroundDinamico={this.props.backgroundMaisEscuro}
                                                                            placeholderColor={this.props.corLetraSecundaria}
                                                                            corBordaDinamica={this.props.corBotaoPadrao}
                                                                            {...valueProps} ></InputSearch>
                                                                        <IconeSetaPraBaixo className="icon-angle-arrow-down"
                                                                            colorDinamico={this.props.coresLetraPadrao} />
                                                                    </DivInputSearch>
                                                                }
                                                                value={this.props.atendenteSelecionado} options={this.props.atendenteNovoChamado}
                                                                onChange={(value) => this.props.modificaAtendenteSelecionado(value)}
                                                                placeholder={this.props.atendenteNovoChamado.length == 0 ? `Nenhum Atendente cadastrado` : "Selecione o Atendente"}
                                                                search={this.props.atendenteNovoChamado.length == 0 ? false : true} />
                                                            <BotaoAdd data-tip="Novo Atendente" data-for="tip-top"
                                                                backgroundDinamico={this.props.corBotaoPadrao} onClick={() => {

                                                                    this.props.history.push(("/atendentes"));
                                                                    this.props.zeraInformacoesCadastroAtendenteExport();
                                                                    this.props.modificaVisibleModalNovoAtendente(true);
                                                                    this.props.modificaRetornaTelaClienteParaNovoAtendimento(true);
                                                                }}>
                                                                <i className="icon-add2-1"
                                                                    style={{ fontSize: 18, color: this.props.letraBotaoPadrao }} />
                                                            </BotaoAdd>
                                                        </ContainerSelectSearch>

                                                    </SubContainerNovoAtendimento>
                                                    <SubContainerNovoAtendimento>
                                                        <p style={{
                                                            marginLeft: 20,
                                                            color: this.props.corLetraDescricaoDosCampos,
                                                            fontSize: 14
                                                        }}>Setor:</p>
                                                        <ContainerSelectSearch>
                                                            <SelectSearch
                                                                renderValue={(valueProps) =>
                                                                    <DivInputSearch>
                                                                        <InputSearch
                                                                            corLetraDinamico={this.props.coresLetraPadrao}
                                                                            backgroundDinamico={this.props.backgroundMaisEscuro}
                                                                            placeholderColor={this.props.corLetraSecundaria}
                                                                            corBordaDinamica={this.props.corBotaoPadrao}
                                                                            {...valueProps} ></InputSearch>
                                                                        <IconeSetaPraBaixo className="icon-angle-arrow-down"
                                                                            colorDinamico={this.props.coresLetraPadrao} />
                                                                    </DivInputSearch>
                                                                }
                                                                value={this.props.setorSelecionado} options={this.props.setorNovoChamado}
                                                                onChange={(value) => this.props.modificaSetorSelecionado(value)}
                                                                placeholder={this.props.setorNovoChamado.length == 0 ? `Nenhum Setor cadastrado` : "Selecione o Setor"}
                                                                search={this.props.setorNovoChamado.length == 0 ? false : true} />
                                                            <BotaoAdd data-tip="Novo Setor" data-for="tip-top"
                                                                backgroundDinamico={this.props.corBotaoPadrao} onClick={() => {
                                                                    this.props.history.push(("/setores"));
                                                                    this.props.modificaVisibleModalNovoSetor(true)
                                                                    this.props.modificaRetornaTelaClienteParaNovoAtendimento(true);
                                                                }}>
                                                                <i className="icon-add2-1"
                                                                    style={{ fontSize: 18, color: this.props.letraBotaoPadrao }} />
                                                            </BotaoAdd>
                                                        </ContainerSelectSearch>
                                                    </SubContainerNovoAtendimento>
                                                    <SubContainerNovoAtendimento>
                                                        <p style={{ marginLeft: 20, fontSize: 14, color: this.props.corLetraDescricaoDosCampos }}>Data
                                                            Vencimento:</p>
                                                        <DatePicker
                                                            onChange={(date) => {
                                                                this.props.modificaDataVencimentoTelaChamadoAbertura(moment(date).format('DD/MM/YYYY HH:mm'));
                                                                this.setState({ dataVencimento: date })
                                                            }}
                                                            locale="pt-BR"
                                                            value={this.props.dataVencimento}
                                                            showTimeSelect
                                                            timeFormat="HH:mm"
                                                            timeCaption="Hora"
                                                            selected={this.state.dataVencimento}
                                                            customInput={<CustomInputDataVencimento />}
                                                            formatWeekDay={d => {
                                                                switch (d) {
                                                                    case 'segunda':
                                                                        return 'Seg';
                                                                    case 'terça':
                                                                        return 'Ter';
                                                                    case 'quarta':
                                                                        return 'Qua';
                                                                    case 'quinta':
                                                                        return 'Qui';
                                                                    case 'sexta':
                                                                        return 'Sex';
                                                                    case 'sábado':
                                                                        return 'Sab';
                                                                    case 'domingo':
                                                                        return 'Dom';
                                                                    default:
                                                                        return d;
                                                                }
                                                            }}
                                                            timeIntervals={5}
                                                            dateFormat="dd/MM/yyyy HH:mm"
                                                            placeholderText="Data Final" />
                                                    </SubContainerNovoAtendimento>
                                                    <SubContainerNovoAtendimento>
                                                        <p style={{
                                                            marginLeft: 20,
                                                            fontSize: 14,
                                                            color: this.props.corLetraDescricaoDosCampos
                                                        }}>Prioridade:</p>
                                                        <ContainerSelectSearch>
                                                            <SelectSearch
                                                                renderValue={(valueProps) =>
                                                                    <DivInputSearch>
                                                                        <InputSearch
                                                                            corLetraDinamico={this.props.coresLetraPadrao}
                                                                            backgroundDinamico={this.props.backgroundMaisEscuro}
                                                                            placeholderColor={this.props.corLetraSecundaria}
                                                                            corBordaDinamica={this.props.corBotaoPadrao}
                                                                            {...valueProps} ></InputSearch>
                                                                        <IconeSetaPraBaixo className="icon-angle-arrow-down"
                                                                            colorDinamico={this.props.coresLetraPadrao} />
                                                                    </DivInputSearch>
                                                                }
                                                                value={this.props.prioridadeSelecionada} options={this.props.prioridade}
                                                                onChange={(value) => this.props.modificaPrioridadeSelecionado(value)}
                                                                placeholder={"Selecione a Prioridade"} search />
                                                        </ContainerSelectSearch>
                                                    </SubContainerNovoAtendimento>
                                                    <SubContainerNovoAtendimento>
                                                        <p style={{
                                                            marginLeft: 20,
                                                            fontSize: 14,
                                                            color: this.props.corLetraDescricaoDosCampos
                                                        }}>Local:</p>
                                                        <ContainerSelectSearch>
                                                            <SelectSearch
                                                                renderValue={(valueProps) =>
                                                                    <DivInputSearch>
                                                                        <InputSearch
                                                                            corLetraDinamico={this.props.coresLetraPadrao}
                                                                            backgroundDinamico={this.props.backgroundMaisEscuro}
                                                                            placeholderColor={this.props.corLetraSecundaria}
                                                                            corBordaDinamica={this.props.corBotaoPadrao}
                                                                            {...valueProps} ></InputSearch>
                                                                        <IconeSetaPraBaixo className="icon-angle-arrow-down"
                                                                            colorDinamico={this.props.coresLetraPadrao} />
                                                                    </DivInputSearch>
                                                                }
                                                                value={this.props.localAtendimentoSelecionado} options={this.props.localAtendimento}
                                                                onChange={(value) => this.props.modificaLocalSelecionado(value)}
                                                                placeholder={"Selecione o Local"} search />
                                                        </ContainerSelectSearch>
                                                    </SubContainerNovoAtendimento>
                                                </ContainerDetalhesModalNovoAtendimento>
                                            }
                                            {this.props.criarChamadoProblemaEstaticoAtendimento === false && <ContainerInputDescricaoChamado
                                                style={{ display: 'flex', flex: this.props.mostrarMaisDetalhesChamado === true ? 0 : 1 }}>
                                                <strong style={{
                                                    color: this.props.corLetraDescricaoDosCampos,
                                                    fontSize: 14,
                                                    marginBottom: 2,
                                                    marginLeft: 5
                                                }}>Descrição do problema:<span style={{ color: 'red', fontSize: 14, marginLeft: 3 }}>*</span></strong>
                                                <InputDescricaoChamado
                                                    autoFocus
                                                    type="textarea"
                                                    onChange={(value) => {
                                                        this.props.alteraDescricaoChamado(value.target.value)
                                                    }}
                                                    corLetraDinamico={this.props.coresLetraPadrao}
                                                    corLetraSecundaria={this.props.coresLetraSecundario}
                                                    corBordaDinamica={this.props.corBotaoPadrao}
                                                    placeholder=" Descreva o problema..."
                                                    value={this.props.descricaoAtendimento}
                                                />
                                            </ContainerInputDescricaoChamado>}
                                            {this.props.criarChamadoProblemaEstaticoAtendimento == true &&
                                                <SubContainerNovoAtendimento style={{ marginTop: 15, marginBottom: 15 }}>
                                                    <p style={{
                                                        marginLeft: 20,
                                                        color: this.props.corLetraDescricaoDosCampos,
                                                        fontSize: 14
                                                    }}>Problema:</p>
                                                    <ContainerSelectSearch>
                                                        <SelectSearch
                                                            renderValue={(valueProps) =>
                                                                <DivInputSearch>
                                                                    <InputSearch
                                                                        corLetraDinamico={this.props.coresLetraPadrao}
                                                                        backgroundDinamico={this.props.backgroundMaisEscuro}
                                                                        placeholderColor={this.props.corLetraSecundaria}
                                                                        corBordaDinamica={this.props.corBotaoPadrao}
                                                                        {...valueProps} ></InputSearch>
                                                                    <IconeSetaPraBaixo className="icon-angle-arrow-down"
                                                                        colorDinamico={this.props.coresLetraPadrao} />
                                                                </DivInputSearch>
                                                            }
                                                            value={this.props.problemaEstaticoSelecionadoNovoAtendimento}
                                                            options={this.props.problemasEstaticos} onChange={(value, option) => {
                                                                this.props.modificaProblemaEstaticoSelecionadoNovoAtendimento(value);
                                                                this.props.modificaNomeProblemaEstaticoSelecionadoNovoAtendimento(option.name);
                                                            }} placeholder="Selecione o Problema" search />

                                                    </ContainerSelectSearch>
                                                </SubContainerNovoAtendimento>}
                                            <FlatList
                                                list={this.props.arquivosParaSerUpadosNaAberturaChamado}
                                                renderWhenEmpty={() => null}
                                                // keyExtractor={(item, index) => item.key + index}
                                                renderItem={(item) => this.renderItemFlatListArquivosAnexados(item)}
                                            />
                                            <DivBotaoNovoAtendimento>
                                                {this.renderBotaoCadastrarChamado()}

                                            </DivBotaoNovoAtendimento>
                                        </div>
                                        /*   </section> */
                                    )
                                }
                            }}
                        </Dropzone>
                    </ContainerModal>
                </Modal>
                {/* <h3 style={{color:this.props.coresLetraPadrao}}>Atendimentos</h3> */}
                <TabsModified
                    // selectedIndex={2}
                    //  defaultFocus
                    defaultIndex={JSON.parse(localStorage.getItem('tabindex'))}
                    onSelect={index => modificaTabIndex(index)}
                // forceRender={true}
                >
                    <TabListModified style={{
                        backgroundColor: this.props.backgroundSegundo, color: this.props.coresLetraPadrao,

                    }}>
                        <TabModified tabIndex={"0"} coriconedinamicoselecionado={this.props.corBotaoPadrao}>
                            <IconeTab className="icon-phone" colorDinamico={this.props.coresLetraPadrao} />
                            <TextTab colorDinamico={this.props.coresLetraPadrao}>Aberto</TextTab>
                        </TabModified>
                        <TabModified tabIndex={"1"} coriconedinamicoselecionado={this.props.corBotaoPadrao}>
                            <IconeTab className="icon-chamadoematendimento2" colorDinamico={this.props.coresLetraPadrao} />
                            <TextTab colorDinamico={this.props.coresLetraPadrao}>Atendendo</TextTab>
                        </TabModified>
                        <TabModified tabIndex={"2"} coriconedinamicoselecionado={this.props.corBotaoPadrao}>
                            <IconeTab className="icon-chamadofechado2" colorDinamico={this.props.coresLetraPadrao} />
                            <TextTab colorDinamico={this.props.coresLetraPadrao}> Fechado</TextTab>
                        </TabModified>
                        <TabModified tabIndex={"3"} coriconedinamicoselecionado={this.props.corBotaoPadrao}>
                            <IconeTab className="icon-chamadocancelado" colorDinamico={this.props.coresLetraPadrao} />
                            <TextTab colorDinamico={this.props.coresLetraPadrao}>Cancelado</TextTab>
                        </TabModified>
                        <TabModified tabIndex={"4"} coriconedinamicoselecionado={this.props.corBotaoPadrao}>
                            <IconeTab className="icon-atendimentotodos" colorDinamico={this.props.coresLetraPadrao} />
                            <TextTab colorDinamico={this.props.coresLetraPadrao}>Todos</TextTab>
                        </TabModified>
                    </TabListModified>

                    <TabPanelModified>
                        <ChamadoAberto />
                    </TabPanelModified>
                    <TabPanelModified>
                        <ChamadoAtendendo />
                    </TabPanelModified>
                    <TabPanelModified>
                        <ChamadoFechado />
                    </TabPanelModified>
                    <TabPanelModified>
                        <ChamadoCancelado />
                    </TabPanelModified>
                    <TabPanelModified>
                        <ChamadoTodos />
                    </TabPanelModified>
                </TabsModified>
                {/*  <AppBar position="static" style={{background:'transparent', boxShadow: 'none'}}>
                    <Tabs
                    style={{background:this.props.backgroundSegundo,marginTop:2}}
                        value={this.state.value}
                        onChange={(evento, newvalue) => this.handleChange(evento, newvalue)}
                        indicatorColor="#FFF"
                        textColor="#FFF"
                        variant="fullWidth"
                        centered

                    >
                        <Tab label="Aberto" icon={<i className="icon-phone" style={{ color: '#FFF', fontSize: 18 }} />}{...this.a11yProps(0)} />
                        <Tab label="Atendendo" icon={<i className="icon-chamadoematendimento2" style={{ color: '#FFF', fontSize: 18 }} />} {...this.a11yProps(1)} />
                        <Tab label="Fechado" icon={<i className="icon-chamadofechado2" style={{ color: '#FFF', fontSize: 18 }} />} {...this.a11yProps(2)} />
                        <Tab label="Cancelado" icon={<i className="icon-chamadocancelado" style={{ color: '#FFF', fontSize: 18 }} />} {...this.a11yProps(3)} />
                        <Tab label="Todos" icon={<i className="icon-atendimentotodos" style={{ color: '#FFF', fontSize: 18 }} />} {...this.a11yProps(4)} />

                    </Tabs>
               </AppBar>

                <TabPanel value={this.state.value} index={0}>
                    <ChamadoAberto />
                </TabPanel>
                <TabPanel value={this.state.value} index={1}>
                    <ChamadoAtendendo />
                </TabPanel>*/}
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return (
        {
            backgroundPrimeiro: state.CoresPadraoSistema.backgroundPrimeiro,
            backgroundSegundo: state.CoresPadraoSistema.backgroundSegundo,
            backgroundMaisEscuro: state.CoresPadraoSistema.backgroundMaisEscuro,
            coresLetraPadrao: state.CoresPadraoSistema.coresLetraPadrao,
            corBotaoPadrao: state.CoresPadraoSistema.corBotaoPadrao,
            corSegundoBotaoPadrao: state.CoresPadraoSistema.corSegundoBotaoPadrao,
            letraBotaoPadrao: state.CoresPadraoSistema.letraBotaoPadrao,
            coresLetraSecundario: state.CoresPadraoSistema.coresLetraSecundario,
            corLetraDescricaoDosCampos: state.CoresPadraoSistema.corLetraDescricaoDosCampos,
            nomeRota: state.HeaderReducer.nomeRota,
            modalNovoAtendimento: state.ChamadoReducer.modalNovoAtendimento,
            visibleSelectSearch: state.ChamadoReducer.visibleSelectSearch,
            clienteSelecionado: state.ChamadoReducer.clienteSelecionado,
            atendente: state.ChamadoReducer.atendente,
            setor: state.ChamadoReducer.setor,
            prioridade: state.ChamadoReducer.prioridade,
            localAtendimento: state.ChamadoReducer.localAtendimento,
            descricaoAtendimento: state.ChamadoReducer.descricaoAtendimento,
            prioridadeSelecionada: state.ChamadoReducer.prioridadeSelecionada,
            localAtendimentoSelecionado:
                state.ChamadoReducer.localAtendimentoSelecionado,
            chamados: state.ChamadoReducer.chamados,
            clientesNovoChamado: state.ChamadoReducer.clientesSectionedConsulta,
            renderMaisDetalhes: state.ChamadoReducer.renderMaisDetalhes,
            loadingCarregandoClienteTelaNovoChamado:
                state.ChamadoReducer.loadingCarregandoClienteTelaNovoChamado,
            loadingCarregandoAtendenteTelaNovoChamado:
                state.ChamadoReducer.loadingCarregandoAtendenteTelaNovoChamado,
            loadingCarregandoSetorTelaNovoChamado:
                state.ChamadoReducer.loadingCarregandoSetorTelaNovoChamado,
            setorNovoChamado: state.SetorReducer.setoresSectionedConsulta,
            setorSelecionado: state.ChamadoReducer.setorSelecionado,
            atendenteNovoChamado: state.AtendenteReducer.atendentesSectionedConsulta,
            atendenteSelecionado: state.ChamadoReducer.atendenteSelecionado,
            loadingBotaoCadastrarChamado:
                state.ChamadoReducer.loadingBotaoCadastrarChamado,
            verificaInternetCadastrarChamado:
                state.ChamadoReducer.verificaInternetCadastrarChamado,
            mensagemChamadoCadastradoSucesso:
                state.ChamadoReducer.mensagemChamadoCadastradoSucesso,
            nomeArquivoUpado1: state.ChamadoReducer.nomeArquivoUpado1,
            nomeArquivoUpado2: state.ChamadoReducer.nomeArquivoUpado2,
            nomeArquivoUpado3: state.ChamadoReducer.nomeArquivoUpado3,
            resDoArquivo1: state.ChamadoReducer.resDoArquivo1,
            resDoArquivo2: state.ChamadoReducer.resDoArquivo2,
            resDoArquivo3: state.ChamadoReducer.resDoArquivo3,
            dataVencimento: state.ChamadoReducer.dataVencimento,
            nomeAuxAtendente: state.ChamadoReducer.nomeAuxAtendente,
            nomeAuxCliente: state.ChamadoReducer.nomeAuxCliente,
            nomeAuxSetor: state.ChamadoReducer.nomeAuxSetor,
            arquivoUpado1: state.ChamadoReducer.arquivoUpado1,
            arquivoUpado2: state.ChamadoReducer.arquivoUpado2,
            arquivoUpado3: state.ChamadoReducer.arquivoUpado3,
            contagemArquivo: state.ChamadoReducer.contagemArquivo,
            tamanhoArquivo1: state.ChamadoReducer.tamanhoArquivo1,
            tamanhoArquivo2: state.ChamadoReducer.tamanhoArquivo2,
            tamanhoArquivo3: state.ChamadoReducer.tamanhoArquivo3,
            mostrarMaisDetalhesChamado: state.ChamadoReducer.mostrarMaisDetalhesChamado,
            tabIndex: state.ChamadoReducer.tabIndex,
            tamanhoTotalARquivoAnexado: state.ChamadoReducer.tamanhoTotalARquivoAnexado,
            arquivosParaSerUpadosNaAberturaChamado: state.ChamadoReducer.arquivosParaSerUpadosNaAberturaChamado,
            permissoesAcessarTelasAtendente: state.AtendenteReducer.permissoesAcessarTelasAtendente,
            empresaSelecionadaAoAbrirChamado: state.ChamadoReducer.empresaSelecionadaAoAbrirChamado,
            keyEmpresaVinculadaAoAbrirAlterarChamado: state.ChamadoReducer.keyEmpresaVinculadaAoAbrirAlterarChamado,
            verificaAgrupaAtendenteSetor: state.ChamadoReducer.verificaAgrupaAtendenteSetor,
            verificaAtendenteVeSomenteProprioAtendimento: state.ChamadoReducer.verificaAtendenteVeSomenteProprioAtendimento,
            contaPrincipal: state.AtendenteReducer.contaPrincipal,
            licensawebouapp: state.PremiumReducer.licensawebouapp,
            retornaTelaClienteParaNovoAtendimentos: state.ChamadoReducer.retornaTelaClienteParaNovoAtendimentos,
            premium: state.PremiumReducer.premium,

            criarChamadoProblemaEstaticoAtendimento: state.PremiumReducer.criarChamadoProblemaEstaticoAtendimento,
            nomeProblemaEstaticoSelecionadoNovoAtendimento: state.ChamadoReducer.nomeProblemaEstaticoSelecionadoNovoAtendimento,
            problemaEstaticoSelecionadoNovoAtendimento: state.ChamadoReducer.problemaEstaticoSelecionadoNovoAtendimento,
            problemasEstaticos: state.ConfiguracaoReducer.problemasEstaticos,
            filtroChamado: state.ChamadoReducer.filtroChamado,
        }
    );
};
export default connect(mapStateToProps, {
    modificaVisibilidadeModalNovoAtendimento, teste,
    modificaNomeRotaNoHeader, listaClientesTelaConsultaClientes,
    listaSetorSectionedTelaConsultaSetor, modificaClienteSelecionado, modificaAtendenteSelecionado,
    modificaPrioridadeSelecionado, modificaLocalSelecionado, modificaSetorSelecionado, modificaDataVencimentoTelaChamadoAbertura,
    alteraDescricaoChamado, cadastraNovoAtendimento, enviaTamanhoArquivoAnexadoTelAberturaChamado,
    enviaArquivosParaFirebaseTelaAbrirChamado, modificaContagemArquivo, enviaArquivosParaArrayDeArquivosAnexados,
    diminuiTamanhoTotalARquivosAnexados, modificaTabIndex, modificaMostrarMaisChamados,
    zeraInformacoesCadastroClienteExport, modificaVisibleModalNovoCLiente, modificaRetornaTelaClienteParaNovoAtendimento,
    modificaVisibleModalNovoAtendente, zeraInformacoesCadastroAtendenteExport, modificaVisibleModalNovoSetor, listaRespostasPadroes,
    desligaOuvintesChamadosFirebase,
    modificaEmpresaSelecionadaAoAbrirChamado, modificaKeyEmpresaVinculadaSelecionadaAoAbrirChamado, listaProblemasEstaticosTelaNovoChamado,
    listaAtendentesTelaConsultaAtendente, modificaProblemaEstaticoSelecionadoNovoAtendimento, modificaNomeProblemaEstaticoSelecionadoNovoAtendimento,
    testaVerificaTempoLimiteAtingido
})(withRouter(Atendimentos));
