import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "./AuthProtected";

const PrivateRoute = ({ component: RouteComponent, ...rest }) => {
  const { currentUser } = useContext(AuthContext);
/*   
   */
  
  return (
    <Route
      {...rest}
      render={routeProps => {
       /*  if (routeProps.location.pathname == '/login') {
          return (
            !!currentUser ? (
              <Redirect to={"/dashboard"} />
            ) : (
                <Redirect to={"/login"} />
              )
          )
        }
        if (routeProps.location.pathname == '/cadastro') {
          return (

            !!currentUser ? (
              <Redirect to={"/dashboard"} />
            ) : (
                <Redirect to={"/cadastro"} />
              )
          )
        } */
        return (
          !!currentUser ? (
            <RouteComponent {...routeProps} />
          ) : (
              <Redirect to={"/"} />
            )
        )
      }

      }
    />
  );
};


export default PrivateRoute;