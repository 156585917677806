import React, { memo, PureComponent } from 'react';
import Avatar from "react-avatar";
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import moment from 'moment';
import firebase from '../../servicos/FirebaseConnection';
import ReactTooltip from "react-tooltip";
import { shade, opacify, lighten, } from 'polished';
import aguardandoAtendimento from '../../imagens/aguardandoAtendimento.png';
import momentz from 'moment-timezone';
import { Howl, Howler } from 'howler';
import {
    DivBadget, ContainerChamado, ContainerImagem, ContainerDadosChamado,
    ContaineDadosChamadosPrincipal,
    ContainerIconeEFrase, TextCliente,
    SubContainerIconeEFraseClienteAtendente,
    ContainerSeta, TextEmpresa, DivData, DivCountNotif, PerfilImage, DivSelect,
    DivInfoVencPrioLocal, ContainerChamadoAux, ContaineDadosChamadosPrincipalAux,
    TextPrioridade
} from './styled';
import {
    modificaKeyAtendimentoEmAtendimentoOnline, modificaAtendimentoEmEsperaSelecionado, modificaKeyAtendimentoEmEsperaAtendimentoOnline,
    modificaKeyContacts, modificaDadosContactsSelecionado, enviaUltimaReeferenciaKeyEmAtendimentoExport, enviaConversasCarregadasEmAtendimentoExport,
    modificaTitleTab, setaMensagensComoLidaWhatsAppSocket
} from '../../actions/ChamadoAction';
import {
    enviaAtendimentoParaReducerChatExport, modificaLidoEnaoLidoAtendimentoPorKeyReducerChat,
    enviaUltimaKeyReferenciaCOnversasCarregadasChatExport
} from '../../actions/DashBoardAction';


import imgTeste from '../../imagens/userPadrao.png';
import soundnotification from '../../imagens/notificationsound.mp3';
import whatsapp1 from '../../imagens/whatsapp1.png';
import chat1 from '../../imagens/chatclient.png';
import atendendochat from '../../imagens/atendendochat.png';
moment.locale('pt-br', {
    months: 'Janeiro_Fevereiro_Março_Abril_Maio_Junho_Julho_Agosto_Setembro_Outubro_Novembro_Dezembro'.split('_')
    //  weekdays:'Seg_Ter_Qua_Qui_Sex_Sab_Dom'.split('_'),
})
const soundnotificationexecute = new Howl({
    src: [soundnotification],
    volume: 0.6,
    // autoplay: true
});
class FlatListEmAtendimento extends PureComponent {

    state = {
        fotoCliente: null,
        newmessage: 0,

        primeiraVerificacaoMensagem: false,
        lastMessageType: null,
        lastMessage: '',
        lastMessageStatus: this.props.data.lastmessageChatStatus,
        lastMessageLineColor: this.props.data.lastMessageLineColor,

        verificaSeJaFoiExecutadoPrimeirVez: false,
        colorTextDataVencimento: this.props.coresLetraSecundario
    }

    componentDidMount() {
        /*     
            */

        /*  if (this.props.acoesDoAtendimentoChat !== undefined && this.props.acoesDoAtendimentoChat.length > 0) {
             
             const arrayMensagens = [...this.props.acoesDoAtendimentoChat].reverse();
             const arrayMensagensInversas = [...this.props.acoesDoAtendimentoChat];
             let ultimaMensagem = this.last(arrayMensagens)
             // 
             // 
             if (ultimaMensagem.tipo === 'mensagem') {
                 this.setState({ lastMessageType: 'texto', lastMessage: ultimaMensagem.description, lastMessageStatus: ultimaMensagem.statusMessage, lastMessageLineColor: ultimaMensagem.circleColor })
             } else if (ultimaMensagem.tipo === 'location') {
                 this.setState({ lastMessageType: 'location', lastMessage: 'Localização', lastMessageStatus: ultimaMensagem.statusMessage, lastMessageLineColor: ultimaMensagem.circleColor })
             } else if (ultimaMensagem.tipo === 'vcard') {
                 this.setState({ lastMessageType: 'contato', lastMessage: 'Contato', lastMessageStatus: ultimaMensagem.statusMessage, lastMessageLineColor: ultimaMensagem.circleColor })
             } else {
                 if (ultimaMensagem.tipoArquivo.includes('image')) {
                     this.setState({ lastMessageType: 'imagem', lastMessage: 'Imagem', lastMessageStatus: ultimaMensagem.statusMessage, lastMessageLineColor: ultimaMensagem.circleColor })
                 } else if (ultimaMensagem.tipoArquivo.includes('video') && ultimaMensagem.audio !== true || ultimaMensagem.tipoArquivo.includes('mp4') && ultimaMensagem.audio !== true) {
                     this.setState({ lastMessageType: 'video', lastMessage: 'Vídeo', lastMessageStatus: ultimaMensagem.statusMessage, lastMessageLineColor: ultimaMensagem.circleColor })
                 } else if (ultimaMensagem.audio == true) {
                     this.setState({ lastMessageType: 'audio', lastMessage: 'Mensagem de Voz', lastMessageStatus: ultimaMensagem.statusMessage, lastMessageLineColor: ultimaMensagem.circleColor })
                 } else {
                     this.setState({ lastMessageType: 'arquivo', lastMessage: 'Arquivo', lastMessageStatus: ultimaMensagem.statusMessage, lastMessageLineColor: ultimaMensagem.circleColor })
                 }
             }
             
             const qtdnewmasse = this.verificaQtdMEnsagens2(arrayMensagensInversas);
            
             if (qtdnewmasse !== undefined) {
                 this.setState({ newmessage: qtdnewmasse })
             }
 
         } */
        this.setState({ verificaSeJaFoiExecutadoPrimeirVez: true })
        /*    if (this.props.data.urlImagemNuvem === null || this.props.data.urlImagemNuvem === undefined || this.props.data.urlImagemNuvem === '') {
               const primeiraLetra = this.props.data.cliente.substring(0, 1).trim().toLowerCase();
               this.verificaSeEhAlphabeto(primeiraLetra);
           } else {
               this.setState({ fotoCliente: this.props.data.urlImagemNuvem });
           } */

        if (this.props.data.dataVencimento !== 'Não informado' && this.props.data.dataVencimento !== undefined) {
            let dataAtual = moment().toDate();
            dataAtual = moment(dataAtual).format('YYYY-MM-DD HH:mm');
            let dataFinalVerificacao = moment(this.props.data.dataVencimento, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm');
            let verificaSeDataInicialMaiorQueDataFinal = moment(dataFinalVerificacao).isBefore(dataAtual);
            if (verificaSeDataInicialMaiorQueDataFinal == true) {
                this.setState({ colorTextDataVencimento: 'red' })
            } else {
                this.setState({ colorTextDataVencimento: '#00FF7F' })
            }
        }


    }


    componentWillUnmount() {



        if (window.location.pathname === "/atendimentoonline" && this.props.keyUsuarioLogado !== this.props.data.keyAtendente) {

            firebase.database().ref(`${this.props.data.chaveUnica}/acoesChamados/${this.props.data.key}`).off('value');
        }
        /*  let possuiAtendimento = this.props.chamadosEmAtendimentoOnline.filter(el => el.key === this.props.data.key);
         
         if (possuiAtendimento.length === 0) {
             
             firebase.database().ref(`${this.props.data.chaveUnica}/acoesChamados/${this.props.data.key}`).off('value');
         } */

    }
    last(array) {
        return array[array.length - 1];
    }
    verificaQtdMEnsagens2(arrayMensagensInversas) {
        let aux = 0;
        for (let i = 0; i < arrayMensagensInversas.length; i++) {

            /*  
              */
            if (arrayMensagensInversas[i].lineColor == "#00FF7F" || arrayMensagensInversas[i].lineColor == "#39a3f4" && arrayMensagensInversas[i].read === true) {
                /*   
                   */
                if (aux > 0) {
                    return aux;
                } else {
                    /*   */
                    return 0;
                }

            } else {
                aux++;
                if (this.props.keyAtendimentoEmAtendimentoOnline !== this.props.data.key) {
                    if (aux == arrayMensagensInversas.length) {
                        return aux;
                    }
                }

            }
        }
    }

    componentWillReceiveProps(nextProps) {
        //  
        let cfgAudio = JSON.parse(localStorage.getItem('reproduzsomematendimento')) == null ? true : JSON.parse(localStorage.getItem('reproduzsomematendimento'))
        if (this.props.data.lastmessageChatCount !== nextProps.data.lastmessageChatCount && nextProps.data.lastmessageChatCount > 0 && nextProps.data.status === "Atendendo" && cfgAudio === true &&
            nextProps.data.key !== nextProps.keyAtendimentoEmAtendimentoOnline) {

            if (soundnotificationexecute.playing() == false && this.state.verificaSeJaFoiExecutadoPrimeirVez == true) {

                soundnotificationexecute.play();
            }

        }

        if (nextProps.data.dataVencimento !== 'Não informado' && nextProps.data.dataVencimento !== undefined) {
            let dataAtual = moment().toDate();
            dataAtual = moment(dataAtual).format('YYYY-MM-DD HH:mm');
            let dataFinalVerificacao = moment(nextProps.data.dataVencimento, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm');
            let verificaSeDataInicialMaiorQueDataFinal = moment(dataFinalVerificacao).isBefore(dataAtual);
            /*  
             
              */
            if (verificaSeDataInicialMaiorQueDataFinal == true) {
                this.setState({ colorTextDataVencimento: 'red' })
            } else {
                this.setState({ colorTextDataVencimento: '#00FF7F' })
            }
        }
    }
    poeMensagensComolidaCliente(arrayMensagensInversas) {
        let aux = 0;
        for (let i = 0; i < arrayMensagensInversas.length; i++) {

            /*  
              */
            if (arrayMensagensInversas[i].lineColor == "#00FF7F" && this.props.keyAtendimentoEmAtendimentoOnline !== this.props.data.key) {
                /*   
                   */
                if (aux > 0) {

                    // return aux;
                } else {
                    //nao faz nada, ultima mensagem do atendente

                    //return 0;
                }

            } else {
                aux++;
                firebase.database().ref(`${this.props.data.chaveUnica}/acoesChamados/${this.props.data.key}/${arrayMensagensInversas[i].key}`).update({ read: true });
                /*  if (this.props.keyAtendimentoEmAtendimentoOnline !== this.props.data.key) {
                     if (aux == arrayMensagensInversas.length) {
                         return aux;
                     }
                 } */

            }
        }
    }
    verificaSeEhAlphabeto = (ch) => {
        // 
        let verificaSeEhAlphabeto = typeof ch === "string" && ch.length === 1
            && (ch >= "a" && ch <= "z");
        //
        // 
        if (verificaSeEhAlphabeto) {
            //
            switch (ch) {
                case "a":
                    this.setState({ fotoCliente: require('../../imagens/a.png') });
                    break;
                case "b":
                    this.setState({ fotoCliente: require('../../imagens/b.png') });
                    break;
                case "c":
                    this.setState({ fotoCliente: require('../../imagens/c.png') });
                    break;
                case "d":
                    this.setState({ fotoCliente: require('../../imagens/d.png') });
                    break;
                case "e":
                    this.setState({ fotoCliente: require('../../imagens/e.png') });
                    break;
                case "f":
                    this.setState({ fotoCliente: require('../../imagens/f.png') });
                    break;
                case "g":
                    this.setState({ fotoCliente: require('../../imagens/g.png') })
                    break;
                case "h":
                    this.setState({ fotoCliente: require('../../imagens/h.png') });
                    break;
                case "i":
                    this.setState({ fotoCliente: require('../../imagens/i.png') });
                    break;
                case "j":
                    this.setState({ fotoCliente: require('../../imagens/j.png') });
                    break;
                case "k":
                    this.setState({ fotoCliente: require('../../imagens/k.png') });
                    break;
                case "l":
                    this.setState({ fotoCliente: require('../../imagens/l.png') });
                    break;
                case "m":
                    this.setState({ fotoCliente: require('../../imagens/m.png') });
                    break;
                case "n":
                    this.setState({ fotoCliente: require('../../imagens/n.png') });
                    break;
                case "o":
                    this.setState({ fotoCliente: require('../../imagens/o.png') });
                    break;
                case "p":
                    this.setState({ fotoCliente: require('../../imagens/p.png') });
                    break;
                case "q":
                    this.setState({ fotoCliente: require('../../imagens/q.png') });
                    break;
                case "r":
                    this.setState({ fotoCliente: require('../../imagens/r.png') });
                    break;
                case "s":
                    this.setState({ fotoCliente: require('../../imagens/s.png') });
                    break;
                case "t":
                    this.setState({ fotoCliente: require('../../imagens/t.png') });
                    break;
                case "u":
                    this.setState({ fotoCliente: require('../../imagens/u.png') });
                    break;
                case "v":
                    this.setState({ fotoCliente: require('../../imagens/v.png') });
                    break;
                case "x":
                    this.setState({ fotoCliente: require('../../imagens/x.png') });
                    break;
                case "z":
                    this.setState({ fotoCliente: require('../../imagens/z.png') });
                    break;
                case "w":
                    this.setState({ fotoCliente: require('../../imagens/w.png') });
                    break;
                case "y":
                    this.setState({ fotoCliente: require('../../imagens/y.png') });
                    break;
                default:
                    this.setState({ fotoCliente: require('../../imagens/userPadrao.png') });
                    //  
                    break;

            }
        } else {
            //   
            let urlIMG = require('../../imagens/userPadrao.png')
            this.setState({ fotoCliente: urlIMG });
        }

    }
    renderIconeVisualizaImagem() {
        /*   
          
          
          
           */
        if (this.props.data.lastMessageChat === `Mensagem Apagada`) {
            return (
                <i className="icon-block" style={{ fontSize: 13, color: this.props.coresLetraSecundario, fontWeight: 'bold' }} />
            )
        }
        else if (this.props.data.lastMessageLineColor === '#00FF7F' && this.props.data.lastmessageChatStatus === 'waiting' && this.props.data.lastMessageChat !== `${this.props.nome} entrou no Chat`
            && !this.props.data.lastMessageChat.includes('Atendimento atribuido ao atendente') && !this.props.data.lastMessageChat.includes('encerrou o Chat')) {
            return (
                <i className="icon-clockteste3" style={{ fontSize: 13, color: this.props.coresLetraSecundario, fontWeight: 'bold' }} />
            )
        } else if (this.props.data.lastMessageLineColor === '#00FF7F' && this.props.data.lastmessageChatStatus === 'sent' && this.props.data.lastMessageChat !== `${this.props.nome} entrou no Chat` && !this.props.data.lastMessageChat.includes('Atendimento atribuido ao atendente') && !this.props.data.lastMessageChat.includes('encerrou o Chat')) {
            return (
                <i className="icon-checkwpp2" style={{ fontSize: 13, color: this.props.coresLetraSecundario }} />
            )
        } else if (this.props.data.lastMessageLineColor === '#00FF7F' && this.props.data.lastmessageChatStatus === 'received' && this.props.data.lastMessageChat !== `${this.props.nome} entrou no Chat` && !this.props.data.lastMessageChat.includes('Atendimento atribuido ao atendente') && !this.props.data.lastMessageChat.includes('encerrou o Chat')) {
            return (
                <i className="icon-double-tick-indicator" style={{ fontSize: 15, color: this.props.coresLetraSecundario }} />
            )
        } else if (this.props.data.lastMessageLineColor === '#00FF7F' && this.props.data.lastmessageChatStatus === 'read' && this.props.data.lastMessageChat !== `${this.props.nome} entrou no Chat` && !this.props.data.lastMessageChat.includes('Atendimento atribuido ao atendente') && !this.props.data.lastMessageChat.includes('encerrou o Chat')) {
            return (
                <i className="icon-double-tick-indicator" style={{ fontSize: 16, color: '#39a3f4' }} />
            )
        } else {
            return null
        }
    }
    match(arr, value) {
        var totalOk = 0;
        arr.every(function (el) {
            if (value == el.lineColor) {
                totalOk++;
            }
        });
        return totalOk;
    }
    navegaTelaAlterarSetorLocal() {

    }
    renderImagem() {
        if (this.state.fotoCliente == null) {
            return (
                <div style={{ height: 40, width: 40, borderRadius: '50%', backgroundColor: 'rgba(159,173,183, 0.3)', border: 'none' }} />
            )
        } else {
            return (
                <img src={this.state.fotoCliente} alt="foto" style={{ height: 40, width: 40, borderRadius: 50, backgroundColor: '#9fadb7' }} />
            )
        }
    }
    /*  renderHoraMinuto(dataHoraAbertura) {
         //  
         //
         let formattedTime = moment(dataHoraAbertura, 'DD/MM/YYYY HH:mm').format('HH:mm');
         return formattedTime;
 
     } */
    renderHoraMinuto(dataHoraAbertura) {
        if (dataHoraAbertura === 0 || this.props.timezoneChatOnline === undefined) {
            return ""
        } else {
            //   let zone = this.props.ti
            let dataTimeStamp = moment(dataHoraAbertura).format("DD-MM-YYYY HH:mm");

            let formattedTime = moment(dataTimeStamp, 'DD-MM-YYYY HH:mm').tz(this.props.timezoneChatOnline).format('DD-MM-YYYY HH:mm');
            return formattedTime;
            /* let formattedTime = moment(dataHoraAbertura, 'DD/MM/YYYY HH:mm').format('HH:mm');
            return formattedTime; */
        }
    }
    carregaConversasChat(chaveUnica, keyChamado) {
        let arrayAcoesAtendimento = [];
        firebase.database().ref(`${chaveUnica}/acoesChamados/${keyChamado}`).limitToLast(15).on("value", snapshot2 => {
            snapshot2.forEach(childItem => {
                arrayAcoesAtendimento.push({
                    key: childItem.key,
                    title: childItem.val().titulo,
                    tipo: childItem.val().tipo,
                    description: childItem.val().mensagem,
                    statusMessage: childItem.val().statusMessage == undefined ? 'sent' : childItem.val().statusMessage,
                    time: childItem.val().time,
                    dataHoraAtual: childItem.val().dataHoraAtual == undefined ? childItem.val().mensagem : childItem.val().dataHoraAtual,
                    visivelATodos: childItem.val().visivelATodos == undefined ? 1 : childItem.val().visivelATodos,
                    lineColor: childItem.val().lineColor,
                    circleColor: childItem.val().circleColor,
                    urlDownload: childItem.val().urlDownload,
                    tipoArquivo: childItem.val().tipoArquivo,
                    alterado: childItem.val().alterado,
                    audio: childItem.val().audio == undefined ? false : childItem.val().audio,
                    duracao: childItem.val().audio == true ? childItem.val().duracao : '',
                    idMensagem: childItem.val().idMensagem == undefined ? childItem.key : childItem.val().idMensagem === '' ? childItem.key : childItem.val().idMensagem,
                    circleColorResponder: childItem.val().circleColorResponder == undefined ? '#00FF7F' : childItem.val().circleColorResponder,
                    commentResponderType: childItem.val().commentResponderType == undefined ? '' : childItem.val().commentResponderType,
                    idMensagemResponder: childItem.val().idMensagemResponder == undefined ? '' : childItem.val().idMensagemResponder,
                    mensagemResposta: childItem.val().mensagemResposta == undefined ? '' : childItem.val().mensagemResposta,
                    possuiResposta: childItem.val().possuiResposta == undefined ? false : childItem.val().possuiResposta,
                    commentResponderTypeArquivo: childItem.val().commentResponderTypeArquivo == undefined ? '' : childItem.val().commentResponderTypeArquivo,
                    commentResponderIsAudio: childItem.val().commentResponderIsAudio == undefined ? '' : childItem.val().commentResponderIsAudio,
                    atendenteResponder: childItem.val().atendenteResponder == undefined ? '' : childItem.val().atendenteResponder,
                    captionArquivo: childItem.val().captionArquivo == undefined ? '' : childItem.val().captionArquivo,

                    read: childItem.val().read == undefined ? false : childItem.val().read,
                });

            });
            this.setState({ conversasAtual: arrayAcoesAtendimento })
        });
    }
    renderIconeMessageStatus(type) {
        /*  
          */
        if (this.props.data.lastMessageChat === `Mensagem Apagada`) {
            return (
                <TextEmpresa data-tip={this.props.data.lastMessageChat} data-for="tip-top" corLetraDinamica={this.props.coresLetraSecundario}>Mensagem Apagada</TextEmpresa>
            )
        }
        else if (type === "mensagem" || type === undefined) {
            return <TextEmpresa data-tip={this.props.data.lastMessageChat} data-for="tip-top" corLetraDinamica={this.props.coresLetraSecundario}>{this.props.data.lastMessageChat}</TextEmpresa>
        } else if (type.includes('image')) {
            return (
                <>
                    <i className="icon-imagewpp" style={{ fontSize: 14, color: this.props.coresLetraSecundario }} />
                    <TextEmpresa data-tip={this.props.data.lastMessageChat} data-for="tip-top" corLetraDinamica={this.props.coresLetraSecundario}>Imagem</TextEmpresa>
                </>
            )
        } else if (type.includes('oga') || type.includes('ogg') || type.includes('mp3') || type.includes('audio')) {
            return (
                <>
                    <i className="icon-microphone" style={{ fontSize: 14, color: this.props.data.lastmessageChatStatus !== 'read' ? this.props.coresLetraSecundario : '#39a3f4' }} />
                    <TextEmpresa data-tip={this.props.data.lastMessageChat} data-for="tip-top" corLetraDinamica={this.props.coresLetraSecundario}>Mensagem de Voz</TextEmpresa>
                </>
            )
            /*   return  */
        } else if (type.includes('video') || type.includes('mp4')) {
            return (
                <>
                    <i className="icon-video-camera" style={{ fontSize: 14, color: this.props.coresLetraSecundario }} />
                    <TextEmpresa data-tip={this.props.data.lastMessageChat} data-for="tip-top" corLetraDinamica={this.props.coresLetraSecundario}>Vídeo</TextEmpresa>
                </>
            )
            /* return <i className="icon-video-camera" style={{ fontSize: 14, color: this.props.coresLetraSecundario }} /> */
        } else if (type.includes('location')) {
            return (
                <>
                    <i className="icon-localization" style={{ fontSize: 14, color: this.props.coresLetraSecundario }} />
                    <TextEmpresa data-tip={this.props.data.lastMessageChat} data-for="tip-top" corLetraDinamica={this.props.coresLetraSecundario}>Localização</TextEmpresa>
                </>
            )

        } else if (type.includes('vcard')) {
            return (
                <>
                    <i className="icon-man-user" style={{ fontSize: 14, color: this.props.coresLetraSecundario }} />
                    <TextEmpresa data-tip={this.props.data.lastMessageChat} data-for="tip-top" corLetraDinamica={this.props.coresLetraSecundario}>Contato</TextEmpresa>
                </>
            )
            /*   return <i className="icon-man-user" style={{ fontSize: 14, color: this.props.coresLetraSecundario }} /> */

        } else {
            return (
                <>
                    <i className="icon-documentwpp" style={{ fontSize: 14, color: this.props.coresLetraSecundario }} />
                    <TextEmpresa data-tip={this.props.data.lastMessageChat} data-for="tip-top" corLetraDinamica={this.props.coresLetraSecundario}>Arquivo</TextEmpresa>
                </>
            )
            /*   return <i className="icon-documentwpp" style={{ fontSize: 14, color: this.props.coresLetraSecundario }} /> */
        }
    }
    renderEtiquetaPrioridade(prioridade) {
        if (prioridade == "Baixa") {
            return (
                <DivSelect style={{ backgroundColor: '#FCFCFC' }}>
                    <TextPrioridade corLetraDinamica={this.props.backgroundPrimeiro}>B</TextPrioridade>
                    <TextPrioridade corLetraDinamica={this.props.backgroundPrimeiro}>A</TextPrioridade>
                    <TextPrioridade corLetraDinamica={this.props.backgroundPrimeiro}>I</TextPrioridade>
                    <TextPrioridade corLetraDinamica={this.props.backgroundPrimeiro}>X</TextPrioridade>
                    <TextPrioridade corLetraDinamica={this.props.backgroundPrimeiro}>A</TextPrioridade>
                </DivSelect>
            )
        } else if (prioridade == "Urgente") {
            return (
                <DivSelect style={{ backgroundColor: '#f70e4c' }}>
                    <TextPrioridade corLetraDinamica={this.props.backgroundPrimeiro}>U</TextPrioridade>
                    <TextPrioridade corLetraDinamica={this.props.backgroundPrimeiro}>R</TextPrioridade>
                    <TextPrioridade corLetraDinamica={this.props.backgroundPrimeiro}>G</TextPrioridade>
                    <TextPrioridade corLetraDinamica={this.props.backgroundPrimeiro}>E</TextPrioridade>
                    <TextPrioridade corLetraDinamica={this.props.backgroundPrimeiro}>N</TextPrioridade>
                    <TextPrioridade corLetraDinamica={this.props.backgroundPrimeiro}>T</TextPrioridade>
                    <TextPrioridade corLetraDinamica={this.props.backgroundPrimeiro}>E</TextPrioridade>
                </DivSelect>
            )
        } else if (prioridade == "Alta") {
            return (
                <DivSelect style={{ backgroundColor: '#FFA500' }}>
                    <TextPrioridade corLetraDinamica={this.props.backgroundPrimeiro}>A</TextPrioridade>
                    <TextPrioridade corLetraDinamica={this.props.backgroundPrimeiro}>L</TextPrioridade>
                    <TextPrioridade corLetraDinamica={this.props.backgroundPrimeiro}>T</TextPrioridade>
                    <TextPrioridade corLetraDinamica={this.props.backgroundPrimeiro}>A</TextPrioridade>
                </DivSelect>
            )
        }
        else if (prioridade == "Normal") {
            return (
                <DivSelect style={{ backgroundColor: '#B0C4DE' }}>
                    <TextPrioridade corLetraDinamica={this.props.backgroundPrimeiro}>N</TextPrioridade>
                    <TextPrioridade corLetraDinamica={this.props.backgroundPrimeiro}>O</TextPrioridade>
                    <TextPrioridade corLetraDinamica={this.props.backgroundPrimeiro}>R</TextPrioridade>
                    <TextPrioridade corLetraDinamica={this.props.backgroundPrimeiro}>M</TextPrioridade>
                    <TextPrioridade corLetraDinamica={this.props.backgroundPrimeiro}>A</TextPrioridade>
                    <TextPrioridade corLetraDinamica={this.props.backgroundPrimeiro}>L</TextPrioridade>
                </DivSelect>
            )
        } else {
            return null;
        }

    }
    render() {

        return (

            <ContainerChamado key={this.props.data.key} onClick={() => {
                setTimeout(() => {
                    if (this.props.keyAtendimentoEmAtendimentoOnline !== this.props.data.key) {

                        this.props.enviaUltimaReeferenciaKeyEmAtendimentoExport('');
                        this.props.enviaConversasCarregadasEmAtendimentoExport([]);
                        this.props.enviaUltimaKeyReferenciaCOnversasCarregadasChatExport('')

                    }
                    this.props.modificaKeyAtendimentoEmAtendimentoOnline(this.props.data.key);
                    this.props.modificaKeyAtendimentoEmEsperaAtendimentoOnline('');
                    this.props.modificaKeyContacts('');
                    this.props.modificaDadosContactsSelecionado([]);
                    if (this.props.data.lastmessageChatCount > 0) {
                        firebase.database().ref(`${this.props.data.chaveUnica}/chamados/${this.props.data.key}`).update({
                            lastmessageChatCount: 0
                        })

                        if (this.props.configWhatsapp !== undefined && this.props.configWhatsapp.length > 0 && this.props.data.chamadoAbertoPorWpp === true) {
                            let apiKey = this.props.configWhatsapp[0].apiKey;
                            let urlApi = this.props.configWhatsapp[0].urlApi;
                            let sessionclient = this.props.configWhatsapp[0].sessionclient;
                            let statusConnect = this.props.configWhatsapp[0].statusConnect;

                            if (statusConnect === 'connected' || statusConnect === 'chatsAvailable' || statusConnect === 'isSynced' ||
                                statusConnect === "isConnected" || statusConnect === "isLogged" || statusConnect === 'isSync') {
                                if (urlApi.includes("teste") || urlApi.includes("socket")) {
                                    this.props.setaMensagensComoLidaWhatsAppSocket(this.props.data, apiKey, sessionclient, urlApi);
                                }

                            }

                        }
                    }
                    /*  if(this.state.conversasAtual.length === 0){
                         this.carregaConversasChat()
                     } */

                    /*   let ultimaMensagem = [...this.props.acoesDoAtendimentoChat];
                      
                      
                      if (ultimaMensagem[0].circleColor === "#39a3f4" && ultimaMensagem[0].read === false) {
                          let countNotifTela = this.props.countMensagensNaoLidas - 1;
                          this.props.modificaTitleTab(countNotifTela.toString())
                      }
  
                      this.setState({ newmessage: 0 });
                      this.poeMensagensComolidaCliente([...this.props.acoesDoAtendimentoChat]); */

                }, 0);
            }} bordadinamica={this.props.coresLetraSecundario} backGroundDinamico={this.props.data.key == this.props.keyAtendimentoEmAtendimentoOnline ? shade(0.4, this.props.backgroundPrimeiro) : this.props.backgroundPrimeiro} type="button" >
                {/* {this.props.data.key == this.props.keyAtendimentoEmAtendimentoOnline && <DivSelect style={{ backgroundColor: this.props.corBotaoPadrao, }}></DivSelect>} */}
                {this.props.data.prioridade !== 'Não informado' && this.props.data.prioridade !== undefined && <div style={{ display: 'flex', flexDirection: 'column', minHeight: 50, justifyContent: 'center', alignItems: 'center', marginLeft: 5 }}>
                    {this.renderEtiquetaPrioridade(this.props.data.prioridade)}

                </div>}
                <ContainerImagem>
                    <Avatar
                        name={this.props.data.cliente}
                        size='50'
                        src={!!this.props.data.urlImagemNuvem ? this.props.data.urlImagemNuvem : undefined}
                        maxInitials={2}
                        round
                        style={{ cursor: "pointer" }}
                    />
                    {/*  <PerfilImage
                        src={this.props.data.urlImagemNuvem ? this.props.data.urlImagemNuvem : null}
                        alt="foto"
                        style={{ height: 50, width: 50, borderRadius: 50, backgroundColor: 'rgba(159,173,183, 0.3)' }}
                    /> */}
                    {this.props.data.chamadoAbertoPorWpp === true && <img src={whatsapp1} alt="canal" style={{ height: 12, width: 12, marginLeft: -11, marginBottom: -30, zIndex: 22 }} />}
                    {this.props.data.chamadoAbertoPorWpp === false && <img src={chat1} alt="canal" style={{ height: 11, width: 11, marginLeft: -11, marginBottom: -30, zIndex: 22 }} />}
                </ContainerImagem>
                <ContainerDadosChamado bordadinamica={this.props.coresLetraSecundario}>
                    <ContaineDadosChamadosPrincipal>
                        <SubContainerIconeEFraseClienteAtendente >
                            <TextCliente corLetraDinamica={this.props.coresLetraPadrao}>{this.props.data.cliente}</TextCliente>
                        </SubContainerIconeEFraseClienteAtendente>
                        <SubContainerIconeEFraseClienteAtendente >
                            {this.renderIconeVisualizaImagem()}
                            {this.renderIconeMessageStatus(this.props.data.lastmessageChatType)}
                            {/*   {this.props.data.lastmessageChatType === 'imagem' && <i className="icon-imagewpp" style={{ fontSize: 14, color: this.props.coresLetraSecundario }} />}
                            {this.props.data.lastmessageChatType === 'video' && <i className="icon-video-camera" style={{ fontSize: 14, color: this.props.coresLetraSecundario }} />}
                            {this.props.data.lastmessageChatType === 'arquivo' && <i className="icon-documentwpp" style={{ fontSize: 14, color: this.props.coresLetraSecundario }} />}
                            {this.props.data.lastmessageChatType === 'location' && <i className="icon-localization" style={{ fontSize: 14, color: this.props.coresLetraSecundario }} />}
                            {this.props.data.lastmessageChatType === 'contato' && <i className="icon-man-user" style={{ fontSize: 14, color: this.props.coresLetraSecundario }} />}
                            {this.props.data.lastmessageChatType === 'audio' && <i className="icon-microphone" style={{ fontSize: 14, color: this.props.data.lastmessageChatStatus !== 'read' ? this.props.coresLetraSecundario : '#39a3f4' }} />} */}

                        </SubContainerIconeEFraseClienteAtendente>
                        {/*  <DivInfoVencPrioLocal>

                        </DivInfoVencPrioLocal> */}
                    </ContaineDadosChamadosPrincipal>
                    <ContainerSeta>
                        <p style={{ fontSize: 11, color: this.props.coresLetraSecundario }}>{this.renderHoraMinuto(this.props.data.lastMessageChatTimeStamp)}</p>

                        {this.props.data.dataVencimento !== 'Não informado' && this.props.data.dataVencimento !== undefined &&
                            <p style={{ fontSize: 11, color: this.state.colorTextDataVencimento }}>{this.props.data.dataVencimento.replace(/[/]/g, "-")}</p>
                        }
                        {this.props.data.lastmessageChatCount > 0 &&

                            <DivCountNotif>
                                <p style={{ color: '#FFF', fontSize: 11, fontWeight: 'bold' }}>{this.props.data.lastmessageChatCount}</p>
                            </DivCountNotif>}
                        {this.props.data.lastmessageChatCount === 0 && <div style={{ height: 11, width: 11 }}>
                        </div>}
                        {/*  {this.props.data.newmessage == true &&

                            <DivCountNotif>
                                <i className="icon-envelope" style={{ fontSize: 10, color: '#FFF' }} />
                            </DivCountNotif>}
                        {this.props.data.newmessage == false && <div>
                        </div>} */}


                    </ContainerSeta>



                </ContainerDadosChamado>


            </ContainerChamado>


        )
        /* 
        return (
            <>
                <ContainerChamado onClick={() => {
                    this.props.modificaKeyAtendimentoEmAtendimentoOnline(this.props.data.key);
                    // this.props.modificaLidoEnaoLidoAtendimentoPorKeyReducerChat(this.props.data.key, false)
                    // this.props.enviaAtendimentoParaReducerChatExport({numeroChamado:this.props.data.numeroChamado,empresa:this.props.data.empresa,cliente:this.props.data.cliente,dados:this.props.data})
                }} bordadinamica={this.props.coresLetraSecundario} backGroundDinamico={this.props.data.key == this.props.keyAtendimentoEmAtendimentoOnline ? shade(0.3, this.props.backgroundSegundo) : this.props.backgroundSegundo} type="button" >
                    {this.props.data.key == this.props.keyAtendimentoEmAtendimentoOnline && <div style={{ width: 5, backgroundColor: this.props.corBotaoPadrao, height: 58, borderTopLeftRadius: 7, borderBottomLeftRadius: 7 }}></div>}
                    <ContainerImagem>
                  
                    
                    <img src={this.props.data.urlImagemNuvem == undefined ? imgTeste : this.props.data.urlImagemNuvem} alt="foto cliente" style={{ height: 40, width: 40, borderRadius: 50 }} />
                    </ContainerImagem>
                    <ContainerDadosChamado bordadinamica={this.props.coresLetraSecundario}>
                        <ContaineDadosChamadosPrincipal>
                            <SubContainerIconeEFraseClienteAtendente >
                                <TextCliente corLetraDinamica={this.props.coresLetraPadrao}>{this.props.data.cliente}</TextCliente>
                            </SubContainerIconeEFraseClienteAtendente>
                            <SubContainerIconeEFraseClienteAtendente >
                                <TextEmpresa corLetraDinamica={this.props.coresLetraSecundario}>{this.props.data.empresa}</TextEmpresa>
                            </SubContainerIconeEFraseClienteAtendente>
                        </ContaineDadosChamadosPrincipal>
                        <ContainerSeta>
                            <p style={{ fontSize: 12, color: this.props.coresLetraSecundario }}>{this.renderHoraMinuto(this.props.data.dataHoraAbertura)}</p>
                            {this.props.data.newmessage == true &&
                                <DivCountNotif>
                                    <i className="icon-envelope" style={{ fontSize: 10, color: '#FFF' }} />
                                </DivCountNotif>}
                            {this.props.data.newmessage == false &&
                                <div></div>
                            }
     
                        </ContainerSeta>
     
                    </ContainerDadosChamado>
     
     
                </ContainerChamado>
     
     
            </>
        ); */
    }
}

const mapStateToProps = (state, ownProps) => {
    // 
    //  
    return (
        {
            backgroundPrimeiro: state.CoresPadraoSistema.backgroundPrimeiro,
            backgroundSegundo: state.CoresPadraoSistema.backgroundSegundo,
            backgroundMaisEscuro: state.CoresPadraoSistema.backgroundMaisEscuro,
            coresLetraPadrao: state.CoresPadraoSistema.coresLetraPadrao,
            coresLetraSecundario: state.CoresPadraoSistema.coresLetraSecundario,
            corBotaoPadrao: state.CoresPadraoSistema.corBotaoPadrao,
            corSegundoBotaoPadrao: state.CoresPadraoSistema.corSegundoBotaoPadrao,
            corLetraDescricaoDosCampos: state.CoresPadraoSistema.corLetraDescricaoDosCampos,
            keyAtendimentoEmAtendimentoOnline: state.ChamadoReducer.keyAtendimentoEmAtendimentoOnline,
            nome: state.PerfilReducer.nome,
            timezoneChatOnline: state.ChamadoReducer.timezoneChatOnline,

            configWhatsapp: state.ChamadoReducer.configWhatsapp,
            keyUsuarioLogado: state.PerfilReducer.keyUsuarioLogado,
        }
    );
};
export default connect(mapStateToProps, {
    modificaKeyAtendimentoEmAtendimentoOnline, modificaAtendimentoEmEsperaSelecionado, enviaAtendimentoParaReducerChatExport,
    modificaLidoEnaoLidoAtendimentoPorKeyReducerChat, modificaKeyAtendimentoEmEsperaAtendimentoOnline,
    modificaKeyContacts, modificaDadosContactsSelecionado, enviaUltimaReeferenciaKeyEmAtendimentoExport, enviaConversasCarregadasEmAtendimentoExport,
    enviaUltimaKeyReferenciaCOnversasCarregadasChatExport, modificaTitleTab, setaMensagensComoLidaWhatsAppSocket
})(memo(FlatListEmAtendimento));
