import React from 'react';
import { connect } from 'react-redux';
import ReactTooltip from "react-tooltip";
import styled from 'styled-components';
import { withRouter } from "react-router-dom";
import { toastr } from 'react-redux-toastr';
import firebase from '../../servicos/FirebaseConnection';
import moment from 'moment';
import SelectSearchPaginate from "../SelectSearch/index";
import FlatList from 'flatlist-react';
import { saveAs } from 'file-saver';
import MoonLoader from "react-spinners/MoonLoader";
import { Timeline, TimelineItem } from 'vertical-timeline-component-for-react';
import { Modal } from 'react-responsive-modal';
import SelectSearch from 'react-select-search';
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import pt from 'date-fns/locale/pt-BR';
import "react-datepicker/dist/react-datepicker.css";
import Dropzone from 'react-dropzone';
import './indexContainerAudio.css'
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'react-h5-audio-player/lib/styles.css';
import { desligaOuvintesChamadosFirebase } from "../../actions/DashBoardAction";
import Linkify from 'react-linkify';
import MicRecorder from 'mic-recorder-to-mp3';
//daqui pra baixo
import { MessageBox, SystemMessage} from '../../servicos/MessageBox/react-chat-elements/src/index';
import './chatelements.css';
import vCard from 'vcard-parser';
import 'react-vertical-timeline-component/style.min.css';
import ScaleLoader from "react-spinners/ScaleLoader"



import {
    Container, ContainerAcoes, ButtonVariosNovos, IconeVariosNovos, TextBotaoNovo,
    TextBotaoNovoTempoGasto, TextProblema, ContainerDataAbertura, TextAbertura, TextDataHoraAbertura,
    ContainerDadosDoChamado, ContainerDadosAbertura, ContainerDadosChamadoPrimeiro, ContainerDadosChamadoSegundo,
    ContainerDadosProblema, ContainerDadosAberturaFechamento, ContainerIconeMaisInformacoes,
    ContainerBotoesAcoes, ContainerIconesLaterais, IconesLateras, IconeAlterar, ContainerIconeLateral,
    ContainerComentario, DivAdicionarComentario, TextAddComent, ContainerMaisInformacoes,
    SubContainerMaisInformacoes, DivLAbelCliente, DivCliente, LabelCliente, DivSeparadoraMaisInformacoes,
    DivTruncarTexto, ContainerTimeLine, TextTitulo, TextMensagem, ImagemComentario, TextBaixarArquivo,
    ContainerClipBaixarArquivo, TextAvaliar,

    ContainerModal, ContainerIconeFecharModal, IconeFechar, TextNovoAtendimento,
    DivAdicionarMaisDetalhes, InputDataVencimento, SubContainerNovoAtendimento, DivBotaoNovoAtendimento,
    BotaoCadastrarAtendimento, TextCadastrar, ContainerInputDescricaoChamado, InputDescricaoChamado,
    ContainerSelectSearch, ContainerDetalhesModalNovoAtendimento, BotaoAdd, IconeClip,

    DivAtendentes, TextAtendentes, ContainerAtendentes, TextCabecalhoModalAcoes, ContainerTextCabecalhoModalAcoes,
    ContainerModalStatusAtendimento, ContainerModalPrioridadeAtendimento, DivInputMotivoCancelamento, InputCancelamento,
    ButtonCancelarAtendimento, TextBotaoCancelarAtendimento, ButtonNaoCancelarAtendimento, DivBotaoSalvarComentario,
    ButtonSalvarComentario, TextBotaoSalvarComentar, ButtonSalvarComentarioSemComent, DivBotaoComentarioInternoPublico,
    TextComentario, BotaoSelecionarCOment, DivInputComentarChamado, ContainerInputEBotaoComent,
    InputSearch, DivInputSearch, IconeSetaPraBaixo, BotaoDesativado, ContainerIconeLateralDesativado,
    DivAdicionarComentarioDesativado, TextProblemaLoading, TextDataHoraAberturaLoading,
    ContainerIconeMaisInformacoesLoading, ButtonVariosNovosLoading, ContainerIconeLateralLoading,
    DivAdicionarComentarioLoading, ContainerTimeLineLoading, IconeSetaPraBaixoDataVencimento,
    ContainerPermitir, TextPermitir, DivTextoReabrirAtendimento, ContainerModalAlterar,
    ContainerIconeResposta, DivRespostasPadroes, TextTitleResposta, TextResposta, LabelClienteCabecalho,
    ContainerIconeRespostaChamadoFechado, ContainerModalGravarAudio, TextGravandoAudio, LabelSolucao,
    DivSOlucao, InputDescricaoChamadoComentaChamado, TextResponder,
    IconGravadorVoz, IconeMostrarSenha,
    ContainerModalViewImage, ContainerIconeFecharImagemPreview, IconeFecharImagePreview, ImagePreview
} from './styled';

import {
    modificaVisibilidadeModalAlterarAtendimento, modificaVisibilidadeModalAtribuirAtendimento,
    modificaVisibilidadeModalAddTempoGastoAtendimento, modificaVisibilidadeModalFinalizarAtendimento,
    modificaVisibilidadeModalPrioridadeAtendimento, modificaVisibilidadeModalCancelarAtendimento,
    modificavisibilidadeModalComentario, modificaInputComentario, modificaComentarioPublicoSimOuNao,
    modificaVisibilidadeBotaoAddVencimento, listaChamadoParaTelaAcoesChamado,
    modificaClienteSelecionado, modificaAtendenteSelecionado, modificaLocalSelecionado,
    modificaPrioridadeSelecionado, modificaDataVencimentoTelaChamadoAbertura, modificaSetorSelecionado,
    alteraDescricaoChamado, modificaKeyEmpresaVinculadaSelecionadaAoAbrirChamado,
    alterarChamado, salvarComentario, atribuiAtendimentoAh, atribuirTempoEsperaAoChamado,
    atribuiStatusAtendimento, alterarPrioridadeAtendimento, alterarDataVencimentoAtendimento, modificaMotivoCancelamentoTelaAcoesAtendimento,
    cancelarChamado, ativaDesativaSwitchComentario, reabrirChamado,
    zeraInformacoesCadastroClienteExport, modificaVisibleModalNovoCLiente, modificaRetornaTelaClienteParaNovoAtendimento,
    modificaNumeroAtendimento, listaClientesTelaConsultaClientes, modificaKeyAtendimento,
    modificaEmpresaSelecionadaAoAbrirChamado, modificaResolucaoProblema,
    listaAcoesChamadoConversasAcoesChamado, listaConversasAntigasResponderAcoes,
    enviaReferenciaultimaKeyAcoesChamadoExportAcoesChamado, enviaConversasAntigasChatExportAcoesChamado,
    deletaMensagemDropDesk, listaProblemasEstaticosTelaNovoChamado,
    modificaProblemaEstaticoSelecionadoNovoAtendimento, modificaNomeProblemaEstaticoSelecionadoNovoAtendimento,
    modificaModalPreviewImage, modificaUrlPreviewImage, modificaNamePreviewImage
} from '../../actions/ChamadoAction';
import {
    modificaVisibleModalNovoAtendente, zeraInformacoesCadastroAtendenteExport, listaAtendentesTelaConsultaAtendente
} from '../../actions/AtendenteAction';
import {
    modificaVisibleModalNovoSetor, listaSetorSectionedTelaConsultaSetor
} from '../../actions/SetorAction';
import { modificaNomeRotaNoHeader } from '../../actions/HeaderAction';

import { exportaAtendimentoParaPDF } from '../../actions/DashBoardAction';

import { listaRespostasPadroes } from '../../actions/ConfiguracaoAction';
moment.locale('pt-br', {
    months: 'Janeiro_Fevereiro_Março_Abril_Maio_Junho_Julho_Agosto_Setembro_Outubro_Novembro_Dezembro'.split('_')
    //  weekdays:'Seg_Ter_Qua_Qui_Sex_Sab_Dom'.split('_'),
})
const Mp3Recorder = new MicRecorder({ bitRate: 128 });
class AcoesChamado extends React.Component {
    state = {
        date: null,
        modalRespostaPadrao: false,
        focused: true,
        modalReabrirChamado: false,
        maisInformacoes: false,
        testeTimeLine: true,
        mostrarMaisDetalhesChamado: false,
        backgroundColor: this.props.corBotaoPadrao,
        backgroundColor2: 'transparent',
        placeholderInputComent: 'Comentário visível para clientes e atendentes',
        nomeAuxiliarCliente: '',
        nomeAuxiliarAtendente: '',
        nomeAuxiliarSetor: '',
        recordSecs: 0,
        bufferAudio: '',
        recordTime: '00:00:00',
        currentPositionSec: 0,
        currentDurationSec: 0,
        playTime: '0:00',
        duration: '00:00:00',
        statusAudio: 'gravar',
        currentDurationSecSlider: 0,
        nomeAudio: '',
        uriAudioFile: '',
        timer: null,
        colorTextComent: this.props.letraBotaoPadrao,
        colorTextComent2: this.props.coresLetraPadrao,
        audioDetails: {
            url: null,
            blob: null,
            chunks: null,
            duration: {
                h: null,
                m: null,
                s: null,
            }
        },
        blobAudioPuro: '',
        isRecording: false,
        blobURL: '',
        isBlocked: false,
        viewMicrophone: false,
        pesquisa: [
            {
                name: 'Hera',
                type: 'group',
                items: [{
                    value: 'Heverton',
                    name: 'Heverton',
                }, {
                    value: 'Joao',
                    name: 'Joao',
                }]
            },
            {
                name: 'MR Automação',
                type: 'group',
                items: [{
                    value: 'Rogerio',
                    name: 'Rogerio',
                }, {
                    value: 'Tom',
                    name: 'Tom',
                }]
            }
        ],
        timeLine: [
            { key: '1', circleColor: '#00FF7F', criadoPor: 'Sergio Souza', lineColor: '#00FF7F', dataHoraAtual: '03/07/2020 19:22', mensagem: 'Qualquer dúvida, estamos à disposição. Por gentileza, avalie meu atendimento. Tenha um ótimo dia!', tipo: 'mensagem', titulo: 'Avaliar Este Atendimento' },
            { key: '2', circleColor: '#00FF7F', criadoPor: 'Sergio Souza', lineColor: '#00FF7F', dataHoraAtual: '03/07/2020 19:21', mensagem: 'Sergio finalizou o atendimento', tipo: 'mensagem', titulo: 'Sergio' },
            { key: '3', circleColor: '#00FF7F', criadoPor: 'Sergio Souza', lineColor: '#00FF7F', dataHoraAtual: '03/07/2020 19:21', mensagem: 'IMG_1384.PNG', tipo: 'link', titulo: 'Sergio', tipoArquivo: 'image/jpeg', urlDownload: 'https://firebasestorage.googleapis.com/v0/b/dark-4b307.appspot.com/o/RnFg0kztluYwsgiUuPtlysIlEgn1%2F-M93ZZUDaPBT-xqtrAZF%2F-M93ZZUDaPBT-xqtrAZF%2FIMG_1384.PNG?alt=media&token=bdddfc46-ea02-4c2d-8b36-bf42616384e1' },
            { key: '11', circleColor: '#00FF7F', criadoPor: 'Sergio Souza', lineColor: '#00FF7F', dataHoraAtual: '03/07/2020 19:21', mensagem: 'IMG_1384.PNG', tipo: 'link', titulo: 'Sergio', tipoArquivo: 'image/jpeg', urlDownload: "https://firebasestorage.googleapis.com/v0/b/dark-4b307.appspot.com/o/RnFg0kztluYwsgiUuPtlysIlEgn1%2F-M93ZZUDaPBT-xqtrAZF%2F-M93ZZUDaPBT-xqtrAZF%2FIMG_1307.PNG?alt=media&token=77a2a155-86b7-4fe9-9887-1fe35fbdf521" },
            { key: '12', circleColor: '#00FF7F', criadoPor: 'Sergio Souza', lineColor: '#00FF7F', dataHoraAtual: '03/07/2020 19:21', mensagem: '33190931049687000183550010000006451000000270.pdf', tipo: 'link', titulo: 'Sergio', tipoArquivo: 'application/pdf', urlDownload: "https://firebasestorage.googleapis.com/v0/b/dark-4b307.appspot.com/o/RnFg0kztluYwsgiUuPtlysIlEgn1%2F-MCH5Lx4C2eVnwelDLAL%2F-MCH5Lx4C2eVnwelDLAL%2F33190931049687000183550010000006451000000270.pdf?alt=media&token=c2f5b36b-0168-4404-858b-63cdc9084841" },
            { key: '4', circleColor: '#39a3f4', criadoPor: 'Juliana Lacerda', lineColor: '#39a3f4', dataHoraAtual: '03/07/2020 19:22', mensagem: 'Aguardando uma resposta', tipo: 'mensagem', titulo: 'Juliana Lacerda' },
            { key: '5', circleColor: '#39a3f4', criadoPor: 'Juliana Lacerda', lineColor: '#39a3f4', dataHoraAtual: '03/07/2020 19:22', mensagem: 'Olá como está meu atendimento? está demorando muito para responder e até agora não consegui acessar, como vou fazer agora?', tipo: 'mensagem', titulo: 'Juliana Lacerda' },
            { key: '6', circleColor: '#00FF7F', criadoPor: 'Sergio Souza', lineColor: '#00FF7F', dataHoraAtual: '03/07/2020 19:22', mensagem: 'Qualquer dúvida, estamos à disposição. Por gentileza, avalie meu atendimento. Tenha um ótimo dia!', tipo: 'mensagem', titulo: 'Sergio' },
            { key: '7', circleColor: '#00FF7F', criadoPor: 'Sergio Souza', lineColor: '#00FF7F', dataHoraAtual: '03/07/2020 19:21', mensagem: 'Sergio finalizou o atendimento', tipo: 'mensagem', titulo: 'Sergio' },
            { key: '8', circleColor: '#39a3f4', criadoPor: 'Juliana Lacerda', lineColor: '#39a3f4', dataHoraAtual: '03/07/2020 19:22', mensagem: 'Aguardando uma resposta', tipo: 'mensagem', titulo: 'Juliana Lacerda' },
            { key: '9', circleColor: '#39a3f4', criadoPor: 'Juliana Lacerda', lineColor: '#39a3f4', dataHoraAtual: '03/07/2020 19:22', mensagem: 'Olá como está meu atendimento? está demorando muito para responder e até agora não consegui acessar, como vou fazer agora?', tipo: 'mensagem', titulo: 'Juliana Lacerda' },
            { key: '10', circleColor: '#00FF7F', criadoPor: 'Sergio Souza', lineColor: '#00FF7F', dataHoraAtual: '03/07/2020 19:22', mensagem: '03/07/2020 19:22', tipo: 'mensagem', titulo: 'Atendimento criado por: Sergio' },
        ],
        atendentes: [
            { key: '1', nomeAtendente: 'Sergio Souza 1' },
            { key: '2', nomeAtendente: 'Juliana Lacerda 1' },
            { key: '3', nomeAtendente: 'Thalita Amorim 1' },
            { key: '4', nomeAtendente: 'Joao Victor 1' },
            { key: '5', nomeAtendente: 'Sergio Souza' },
            { key: '6', nomeAtendente: 'Juliana Lacerda' },
            { key: '7', nomeAtendente: 'Thalita Amorim' },
            { key: '8', nomeAtendente: 'Joao Victor' },
            { key: '9', nomeAtendente: 'Sergio Souza' },
            { key: '10', nomeAtendente: 'Juliana Lacerda' },
            { key: '11', nomeAtendente: 'Thalita Amorim' },
            { key: '12', nomeAtendente: 'Joao Victor' },
            { key: '13', nomeAtendente: 'Sergio Souza' },
            { key: '14', nomeAtendente: 'Juliana Lacerda' },
            { key: '15', nomeAtendente: 'Thalita Amorim' },
            { key: '16', nomeAtendente: 'Joao Victor' },
            { key: '17', nomeAtendente: 'Sergio Souza' },
            { key: '18', nomeAtendente: 'Juliana Lacerda' },
            { key: '19', nomeAtendente: 'Thalita Amorim' },
            { key: '20', nomeAtendente: 'Joao Victor' },
            { key: '21', nomeAtendente: 'Sergio Souza' },
            { key: '22', nomeAtendente: 'Juliana Lacerda' },
            { key: '23', nomeAtendente: 'Thalita Amorim' },
            { key: '24', nomeAtendente: 'Joao Victor' },
            { key: '25', nomeAtendente: 'Sergio Souza F' },
            { key: '26', nomeAtendente: 'Juliana Lacerda F' },
            { key: '27', nomeAtendente: 'Thalita Amorim F' },
            { key: '28', nomeAtendente: 'Joao Victor F' },
        ],
        tempoEspera: [
            { id: '1', hora: '+5m' },
            { id: '2', hora: '+10m' },
            { id: '3', hora: '+15m' },
            { id: '4', hora: '+20m' },
            { id: '5', hora: '+25m' },
            { id: '6', hora: '+30m' },
            { id: '7', hora: '+35m' },
            { id: '8', hora: '+40m' },
            { id: '9', hora: '+45m' },
            { id: '10', hora: '+50m' },
            { id: '11', hora: '+55m' },
            { id: '12', hora: '+1h' },
            { id: '13', hora: '+1h 15m' },
            { id: '14', hora: '+1h 30m' },
            { id: '15', hora: '+1h 45m' },
            { id: '16', hora: '+2h' },
            { id: '17', hora: '+2h 30m' },
            { id: '18', hora: '+3h' },
            { id: '19', hora: '+3h 30m' },
            { id: '20', hora: '+4h' },
            { id: '21', hora: '+6h' },
            { id: '22', hora: '+8h' },
            { id: '23', hora: '+10h' },
            { id: '24', hora: '+12h' },
            { id: '25', hora: '+14h' },
            { id: '26', hora: '+16h' },
            { id: '27', hora: '+20h' },
            { id: '28', hora: '+24h' }
        ],
        viewResponder: false,
        commentResponder: '',
        idResponder: '',
        circleColorResponder: '',
        commentResponderType: null,
        atendenteResponder: '',
        focusMessageBox: 'vazio',
        verificaCLicaDataVencimento: 0,

        dadosMensagemExcluida: {},
        modalMensagemExcluida: false,
        visualizaMensagemExcluida: false,
        dataVencimento: ''
    }


    componentDidMount() {
        this.props.listaRespostasPadroes();
        this.mudaComentarioPublico();
        //
        let paramskey = new URLSearchParams(this.props.history.location.search)
        let keyAtendimento = paramskey.get('key')
        let numeroChamado = paramskey.get('num')
        //
        //
        let keyAtendimentoStorage = localStorage.getItem("keyAtendimento");
        //
        if (keyAtendimento !== null && numeroChamado !== null) {
            this.props.modificaKeyAtendimento(keyAtendimento)
            this.props.listaClientesTelaConsultaClientes();
            this.props.listaAtendentesTelaConsultaAtendente();
            this.props.listaSetorSectionedTelaConsultaSetor();
            if (this.props.criarChamadoProblemaEstaticoAtendimento == true) {
                this.props.listaProblemasEstaticosTelaNovoChamado();
            }
            // this.props.listaChamadoParaTelaAcoesChamado(keyAtendimento);
            this.props.modificaNomeRotaNoHeader(`Atendimento #${numeroChamado}`);

            localStorage.setItem(`keyAtendimento`, JSON.stringify(keyAtendimento));
            localStorage.setItem(`numeroAtendimento`, JSON.stringify(numeroChamado));
        }
        else if (this.props.keyAtendimento.length == 0 && keyAtendimentoStorage !== null) {
            let keyAtendimento = JSON.parse(localStorage.getItem("keyAtendimento"));
            this.props.modificaKeyAtendimento(keyAtendimento)
            //
            this.props.listaClientesTelaConsultaClientes();
            this.props.listaAtendentesTelaConsultaAtendente();
            this.props.listaSetorSectionedTelaConsultaSetor();
            if (this.props.criarChamadoProblemaEstaticoAtendimento == true) {
                this.props.listaProblemasEstaticosTelaNovoChamado();
            }
            //  this.props.listaChamadoParaTelaAcoesChamado(keyAtendimento);
            let numeroAtendimento = JSON.parse(localStorage.getItem("numeroAtendimento"));
            this.props.modificaNomeRotaNoHeader(`Atendimento #${numeroAtendimento}`)
        } else if (this.props.keyAtendimento.length > 0) {

            //  this.props.modificaKeyAtendimento(this.props.keyAtendimento)
            this.props.listaChamadoParaTelaAcoesChamado(this.props.keyAtendimento);
            localStorage.setItem(`keyAtendimento`, JSON.stringify(this.props.keyAtendimento));
            localStorage.setItem(`numeroAtendimento`, JSON.stringify(this.props.numeroAtendimento));


        } else {
            this.props.history.push(("/atendimentos"));
            toastr.warning('Houve um problema', 'Entre no atendimento novamente')
            //
        }

        //;
        //
        ReactTooltip.rebuild();
        /*  if (!this.props.nomeRota.includes('Atendimento #')) {
             this.props.modificaNomeRotaNoHeader('Atendimento #')
         } */
    }
    componentWillReceiveProps(nextProps) {
        //ReactTooltip.rebuild();
        if (this.props.keyAtendimento !== nextProps.keyAtendimento) {
            if (this.props.dadosAtendimentos.length > 0) {
                //
                //
                localStorage.setItem(`keyAtendimento`, JSON.stringify(nextProps.keyAtendimento));
                localStorage.setItem(`numeroAtendimento`, JSON.stringify(this.props.numeroAtendimento));
                this.props.listaChamadoParaTelaAcoesChamado(nextProps.keyAtendimento);
                firebase.database().ref(`${this.props.dadosAtendimentos[0].chaveUnica}/acoesChamados/${this.props.keyAtendimento}`).limitToLast(14).off("value");
                //  firebase.database().ref(`${this.props.dadosAtendimentos[0].chaveUnica}/acoesChamados/${this.props.keyAtendimento}`).off("value");
                firebase.database().ref(`${this.props.dadosAtendimentos[0].chaveUnica}/chamados/`).orderByKey().equalTo(this.props.keyAtendimento).limitToLast(1).off("value")
            } else {
                //
                //
                //
                localStorage.setItem(`keyAtendimento`, JSON.stringify(nextProps.keyAtendimento));
                this.props.listaChamadoParaTelaAcoesChamado(nextProps.keyAtendimento);
            }
        }
        if (this.props.loadingCarregandoRespostasAntigasAcoesChamado === true && nextProps.loadingCarregandoRespostasAntigasAcoesChamado === false) {
            if (nextProps.idRespostaAntigaAcoesChamado !== '') {


                this.setState({ focusMessageBox: nextProps.idRespostaAntigaAcoesChamado })
            }
        }

        if (this.props.keyAtendimento !== nextProps.keyAtendimento && this.state.statusAudio == 'gravacao' || this.props.keyAtendimento !== nextProps.keyAtendimento && this.state.statusAudio == 'gravando') {
            //alert('desliga o som de'+nextProps.data.numeroChamado)
            this.onStopRecord(true);
        }
        setTimeout(() => {
            ReactTooltip.rebuild();
        }, 1000);
    }

    componentWillUnmount() {




        if (this.props.dadosAtendimentos.length > 0) {
            /*  if (this.props.dadosAtendimentos[0].status !== 'Aberto' && this.props.dadosAtendimentos[0].status !== 'Atendendo' && this.props.aberto) { */

            firebase.database().ref(`${this.props.dadosAtendimentos[0].chaveUnica}/acoesChamados/${this.props.keyAtendimento}`).limitToLast(14).off("value");
            /* } */
            firebase.database().ref(`${this.props.dadosAtendimentos[0].chaveUnica}/chamados/`).orderByKey().equalTo(this.props.keyAtendimento).limitToLast(1).off("value")
        }
        this.props.desligaOuvintesChamadosFirebase('atendimentos', '', '', '');
        this.onStopRecord(true);
    }
    _toggleModalGravarAudio() {
        this.handleRest()
        this.setState({ viewMicrophone: !this.state.viewMicrophone });
    }
    millisToMinutesAndSeconds(millis) {
        let minutes = Math.floor(millis / 60000);
        let seconds = ((millis % 60000) / 1000).toFixed(0);
        return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
    }
    renderRefreshConversasAnteriores() {

        if (this.props.loadingCarregandoMaisConversasChatAcoesChamado === true) {
            return (
                <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center', marginTop: 5/* position: 'absolute', top: 15, center: 0 */ }}>
                    <div style={{ display: 'flex', height: 55, width: 55, borderRadius: '50%', marginTop: 5, backgroundColor: 'transparent', alignItems: 'center', justifyContent: 'center' }}>
                        <MoonLoader
                            // css={{}}
                            size={40}
                            color={this.props.corBotaoPadrao}
                            loading={true}
                            css={{ marginBottom: 10 }}
                        />
                    </div>
                </div>
            )
        } else {
            return null;
        }

    }
    scrollCheck = event => {
        /*





          */
        if (event.target.scrollTop <= 0) {

            this.props.enviaReferenciaultimaKeyAcoesChamadoExportAcoesChamado('');
            this.props.enviaConversasAntigasChatExportAcoesChamado([])
        }


        let scrollInTop = event.target.scrollHeight - event.target.scrollTop;
        scrollInTop = (scrollInTop - 20)
        //
        if (scrollInTop <= event.target.clientHeight && this.props.loadingCarregandoMaisConversasChatAcoesChamado === false && this.props.referenciaUltimaKeyChatAppAcoesChamado !== undefined && this.props.dadosAtendimentos.length > 0) {
            //Add in what you want here

            let arrayConversas = [...this.props.acoesDoAtendimento]
            // arrayConversas = arrayConversas.reverse();

            //
            let ultimaKey = this.props.referenciaUltimaKeyChatAppAcoesChamado === '' ? arrayConversas[arrayConversas.length - 1].key : this.props.referenciaUltimaKeyChatAppAcoesChamado;



            this.props.listaAcoesChamadoConversasAcoesChamado(1, this.props.dadosAtendimentos[0].chaveUnica, this.props.dadosAtendimentos[0].key, ultimaKey, this.props.arrayConversasAntigasChatAcoesChamado);
        }

    };
    moveCaretAtEnd(e) {
        var temp_value = e.target.value
        e.target.value = ''
        e.target.value = temp_value
    }
    renderBotaoGravarAudio() {

        if (this.state.statusAudio == 'gravar') {
            return (
                <div style={{ display: 'flex', height: 100, justifyContent: 'center', alignItems: 'center', paddingBottom: 5 }}>
                    <div style={{
                        display: 'flex', height: 60, width: 60, borderRadius: 50, backgroundColor: '#F50257', justifyContent: 'center', alignItems: 'center', cursor: 'pointer'
                    }} onClick={() => this.onStartRecord()}>
                        <i className="icon-microphone" style={{ fontSize: 28, color: '#FFF' }} />
                    </div>
                </div>
            )
        } else if (this.state.statusAudio == 'gravando') {

            return (
                <div style={{ display: 'flex', height: 100, justifyContent: 'center', alignItems: 'center', paddingBottom: 5 }}>

                    <div style={{
                        display: 'flex', height: 60, width: 60, borderRadius: 50, backgroundColor: this.props.corSegundoBotaoPadrao, justifyContent: 'center', alignItems: 'center',
                        cursor: 'pointer'
                    }} onClick={() => {
                        /*     setTimeout(() => {
                                this.setState({
                                    recordSecs: 0,
                                    statusAudio: 'gravacao'
                                });
                            }, 100);
                            AudioManager.stopRecord(); */


                        this.onStopRecord(false);

                    }}>
                        <i className="icon-stop-button-black-rounded-square" style={{ fontSize: 19, color: this.props.letraBotaoPadrao }} />
                    </div>
                </div>
            )
        } else {
            //play audio

            return (
                <div style={{ height: 300, display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                        <audio src={this.state.blobURL} controls />
                    </div>
                    <div style={{ display: 'flex', height: 65, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around', height: 45, width: 90, background: this.props.corSegundoBotaoPadrao, borderRadius: 10, cursor: 'pointer' }} onClick={() => {

                            this.onStopRecord(true)
                        }}>
                            {/*   <Icon name="rubbish-bin" size={15} color={'#EC6A69'} style={{ marginRight: 3 }}
                            /> */}
                            {/*  <i className="icon-download" style={{ fontSize: 15, color: this.props.corBotaoPadrao }} /> */}
                            <p style={{ color: this.props.letraBotaoPadrao, fontSize: 17, fontWeight: 'bold' }}>Limpar</p>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around', height: 45, width: 90, background: this.props.corBotaoPadrao, borderRadius: 10, cursor: 'pointer' }} onClick={() => { this.enviarAudioFirebase() }}>
                            {/*      <i className="icon-download" style={{ fontSize: 15, color: this.props.corBotaoPadrao }} /> */}
                            <p style={{ color: this.props.letraBotaoPadrao, fontSize: 17, fontWeight: 'bold' }}>Enviar</p>
                        </div>
                    </div>
                    {/* <TouchableOpacity
                        style={styles.viewBarWrapper}
                        onPress={(e) => { this.onStatusPress(e) }}
                    >
                        <View style={styles.viewBar}>
                            <View style={[styles.viewBarPlay, { width: playWidth }]} />
                        </View>
                    </TouchableOpacity>*/}


                </div >
            )
        }

    }
    renderIconeMessageStatus(type, descricaoProblema) {
        /*
          */
        if (type === "mensagem" || type === undefined) {
            return descricaoProblema;
        } else if (type.includes('image') && !type.includes('corel')) {
            return "📷 Foto"
        } else if (type.includes('oga') || type.includes('ogg') || type.includes('mp3') || type.includes('audio')) {
            return "🎤 Mensagem de Voz"
        } else if (type.includes('video') || type.includes('mp4')) {

            return "📹 Vídeo"
            /* return (
                <>
                    <i className="icon-video-camera" style={{ fontSize: 14, color: this.props.coresLetraSecundario }} />
                    <TextEmpresa data-tip={this.props.data.lastMessageChat} data-for="tip-top" corLetraDinamica={this.props.coresLetraSecundario}>Vídeo</TextEmpresa>
                </>
            ) */
            /* return <i className="icon-video-camera" style={{ fontSize: 14, color: this.props.coresLetraSecundario }} /> */
        } else if (type.includes('location')) {
            return "📍Localização"
            /*  return (
                 <>
                     <i className="icon-localization" style={{ fontSize: 14, color: this.props.coresLetraSecundario }} />
                     <TextEmpresa data-tip={this.props.data.lastMessageChat} data-for="tip-top" corLetraDinamica={this.props.coresLetraSecundario}>Localização</TextEmpresa>
                 </>
             ) */

        } else if (type.includes('vcard')) {
            return "👤 Contato"
            /*  return (
                 <>
                     <i className="icon-man-user" style={{ fontSize: 14, color: this.props.coresLetraSecundario }} />
                     <TextEmpresa data-tip={this.props.data.lastMessageChat} data-for="tip-top" corLetraDinamica={this.props.coresLetraSecundario}>Contato</TextEmpresa>
                 </>
             ) */
            /*   return <i className="icon-man-user" style={{ fontSize: 14, color: this.props.coresLetraSecundario }} /> */

        } else {
            return "📁 Arquivo"
            /*  return (
                 <>
                     <i className="icon-documentwpp" style={{ fontSize: 14, color: this.props.coresLetraSecundario }} />
                     <TextEmpresa data-tip={this.props.data.lastMessageChat} data-for="tip-top" corLetraDinamica={this.props.coresLetraSecundario}>Arquivo</TextEmpresa>
                 </>
             ) */
            /*   return <i className="icon-documentwpp" style={{ fontSize: 14, color: this.props.coresLetraSecundario }} /> */
        }
    }
    onStartRecord = () => {
        /*  navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {


             this.setState({ isBlocked: false });
             if (this.state.isBlocked) {

             } else { */
        Mp3Recorder
            .start()
            .then((e) => {

                this.setState({ isRecording: true, statusAudio: 'gravando' });
                let timer = setInterval(this.tick, 1000);
                this.setState({ timer });
            }).catch((e) => console.error(e));
        /*  }
     }).catch(() => {

         this.setState({ isBlocked: true })
     }) */


    }
    /* onStartRecord = () => {
        navigator.getUserMedia({ audio: true },
            () => {

                this.setState({ isBlocked: false });
                if (this.state.isBlocked) {

                } else {
                    Mp3Recorder
                        .start()
                        .then((e) => {

                            this.setState({ isRecording: true, statusAudio: 'gravando' });
                            let timer = setInterval(this.tick, 1000);
                            this.setState({ timer });
                        }).catch((e) => console.error(e));
                }
            },
            () => {

                this.setState({ isBlocked: true })
            },
        );
    } */
    onStopRecord = (apenasLimpar) => {
        if (apenasLimpar == true) {
            if (this.state.isRecording) {
                Mp3Recorder.stop()
                    .getMp3()
                    .then(([buffer, blob]) => {
                        clearInterval(this.state.timer);
                        this.setState({
                            blobURL: '',
                            isRecording: false,
                            recordSecs: 0,
                            blobAudioPuro: '',
                            statusAudio: 'gravar',
                            timer: null,
                            bufferAudio: ''
                        });

                    }).catch((e) => { });
            } else {
                clearInterval(this.state.timer);
                this.setState({
                    blobURL: '',
                    isRecording: false,
                    recordSecs: 0,
                    blobAudioPuro: '',
                    statusAudio: 'gravar',
                    timer: null,
                    bufferAudio: ''
                });
            }

        } else {
            Mp3Recorder.stop()
                .getMp3()
                .then(([buffer, blob]) => {



                    const blobURL = URL.createObjectURL(blob)

                    clearInterval(this.state.timer);
                    this.setState({
                        blobURL,
                        isRecording: false,
                        //recordSecs: 0,
                        blobAudioPuro: blob,
                        statusAudio: 'gravacao',
                        timer: null,
                        bufferAudio: buffer
                    });

                    /* const file = new File(buffer, `audioweb${new Date().getTime()}.mp3`, {
                        type: blob.type,
                        lastModified: Date.now()
                    });

                    const player = new Audio(URL.createObjectURL(file));
                    player.controls = true; */
                    //  player.play();

                }).catch((e) => { });
        }
    }
    tick = () => {
        this.setState({
            recordSecs: this.state.recordSecs + 1
        });
    }
    milliseconds = (h, m, s) => ((h * 60 * 60 + m * 60 + s) * 1000);
    getFileBlob = (url, cb) => {
        let xhr = new XMLHttpRequest();
        xhr.open("GET", url);
        xhr.responseType = "blob";
        xhr.addEventListener('load', function () {
            cb(xhr.response);
        });
        xhr.send();
    };
    handleAudioStop(data) {

        this.setState({ audioDetails: data });
    }
    enviarAudioFirebase() {
        //this._toggleModalGravarAudio();


        let duracao = (this.state.recordSecs * 1000);
        let duracaoNan = Number.isNaN(duracao)
        if (duracaoNan == true) {
            duracao = 1000
        }
        const file = new File(this.state.bufferAudio, `audioweb-${new Date().getTime()}.oga`, {
            type: 'audio-ogg',
            lastModified: Date.now()
        });
        file.audio = true;
        file.duracao = duracao;

        let res = [file]


        if (res.length > 0) {

            if (this.props.premium == 0) {
                toastr.error('Recurso Premium', 'Este é um recurso PREMIUM, para fazer upload de arquivos e imagens, Assine a versão PREMIUM e libere esse e mais recursos!');
            } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
                toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
            }
            else {
                //
                this.props.salvarComentario('tem arquivo sim', false,
                    '', res, true, this.props.keyAtendimento, this.props.dadosAtendimentos[0].dataAbertura, this.props.premium, this.props.dadosAtendimentos[0].keyAtendente, this.props.dadosAtendimentos[0], false, [], this.state.viewResponder, this.state.idResponder, this.state.commentResponder, this.state.circleColorResponder, this.state.commentResponderType, this.state.atendenteResponder)
                this.zeraMensagemResponder()
                this.onStopRecord(true)
            }

        } else {
            toastr.error('Error ao Enviar Audio', 'Tente novamente');
        }
        //   let duracaoMilisegundos = this.milliseconds(this.state.audioDetails.duration.h, this.state.audioDetails.duration.m, this.state.audioDetails.duration.s)
        //  let duracao = duracaoMilisegundos;

    }
    handleAudioUpload(file) {
        if (this.state.audioDetails.url == null) {
            toastr.warning('Aviso', 'Clique no microphone para gravar mensagens de áudio')
        } else {


            this._toggleModalGravarAudio();
            let duracaoMilisegundos = this.milliseconds(this.state.audioDetails.duration.h, this.state.audioDetails.duration.m, this.state.audioDetails.duration.s)
            let duracao = duracaoMilisegundos;

            let duracaoNan = Number.isNaN(duracao)
            if (duracaoNan == true) {
                duracao = 1000
            }
            let options = { type: 'video/mp4' };
            const file2 = new File([file], `audioweb-${new Date().getTime()}.mp4`, options);

            file2.audio = true;
            file2.duracao = duracao;
            let res = [file2]


            if (res.length > 0) {

                if (this.props.premium == 0) {
                    toastr.error('Recurso Premium', 'Este é um recurso PREMIUM, para fazer upload de arquivos e imagens, Assine a versão PREMIUM e libere esse e mais recursos!');
                } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
                    toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
                }
                else {
                    //
                    this.props.salvarComentario('tem arquivo sim', false,
                        '', res, true, this.props.keyAtendimento, this.props.dadosAtendimentos[0].dataAbertura, this.props.premium, this.props.dadosAtendimentos[0].keyAtendente, this.props.dadosAtendimentos[0], false, [], this.state.viewResponder, this.state.idResponder, this.state.commentResponder, this.state.circleColorResponder, this.state.commentResponderType, this.state.atendenteResponder)
                    this.zeraMensagemResponder()
                }

            } else {
                toastr.error('Error ao Enviar Audio', 'Tente novamente');
            }
        }

    }
    handleOnChange(e1, e2) {


    }
    handleRest() {
        const reset = {
            url: null,
            blob: null,
            chunks: null,
            duration: {
                h: null,
                m: null,
                s: null,
            }
        }
        this.setState({ audioDetails: reset });
    }
    transformaMinutosEmSegundos(s) {

        function duas_casas(numero) {
            if (numero <= 9) {
                numero = "0" + numero;
            }
            return numero;
        }

        let hora = duas_casas(Math.trunc(s / 3600));
        let minuto = duas_casas(Math.trunc((s % 3600) / 60));
        let segundo = duas_casas((s % 3600) % 60);

        let formatado = minuto + ":" + segundo;

        return formatado;
    }
    handleMaisInformacoes() {
        let state = this.state;
        state.maisInformacoes = !this.state.maisInformacoes;
        this.setState({ state });
    }
    handleWeekDays = (day) => {
        //Change week day with custom day array
        day._locale._weekdaysMin = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'];
        // return the actual dates value(like 1,2,3 ...) from the moment object.
        return (day.format('D'));
    }
    renderArquivosAnexadosAoChamado() {
        if (this.props.loadingArquivosUpadosVinculadoAoChamado) {
            return (
                <p
                    style={{
                        paddingLeft: 10, fontFamily: 'Source Sans Pro', color: this.props.corBotaoPadrao, textAlign: 'center'
                    }}>
                    Carregando Arquivos...</p>
            )
        } else {
            return (

                <FlatList
                    list={this.props.arquivosUpadosVinculadoAoChamado}
                    renderItem={(item) => this.renderItemArquivosAnexadosAoChamado(item)}
                    renderWhenEmpty={() => null}
                //   keyExtractor={(item, index) => item.url}

                />

            )
        }
    }
    renderItemArquivosAnexadosAoChamado(item) {
        return (
            <div key={item.url}
                style={{ display: 'flex', flexDirection: 'row', margin: 10, alignItems: 'center', cursor: 'pointer' }}
            // onClick={() => { this.permissaoBaixarARquivoAnexadoChamado(item) }}
            >
                {/* <p
                    style={{
                        paddingLeft: 10, fontFamily: 'Source Sans Pro', color: this.props.corBotaoPadrao,
                        textDecorationLine: 'underline'
                    }}>
                    {item.nome}</p> */}
                <a style={{
                    paddingLeft: 10, fontFamily: 'Source Sans Pro', color: this.props.corBotaoPadrao,
                    textDecorationLine: 'underline'
                }} href={item.url} target="_blank">{item.nome}</a>
                <i className="icon-download" style={{ fontSize: 13, marginLeft: 10, color: this.props.corBotaoPadrao }} />

            </div>

        )
    }
    permissaoBaixarARquivoAnexadoChamado(item) {
        try {
            //;
            //
            //
            let date = new Date()
            this.download(item.url, item.name)
        } catch (err) {
            //
        }

    }
    handleDatePicked(date) {

        let dataVencimentoALterada = moment(date).format('DD/MM/YYYY HH:mm');
        /*   let dataVencimentoState = moment(this.state.dataVencimento).format('DD/MM/YYYY');
          let dataVencimentoDate = moment(date).format('DD/MM/YYYY'); */

        let horaSelecionada = moment(date).format('HH:mm');
        /*
          */
        if (this.state.verificaCLicaDataVencimento === 0) {
            this.setState({ verificaCLicaDataVencimento: this.state.verificaCLicaDataVencimento + 1 })

        } else {
            /*
              if (moment(dataVencimentoDate).isSame(dataVencimentoState)) { */

            this.props.alterarDataVencimentoAtendimento(this.props.keyAtendimento, dataVencimentoALterada, this.props.dadosAtendimentos[0].dataVencimento, this.props.dadosAtendimentos[0])
            //    this.props.modificaDataVencimentoTelaChamadoAbertura(moment(date).format('DD/MM/YYYY'));
            this.setState({ dataVencimento: '', verificaCLicaDataVencimento: 0 })
            /* } */
        }

    };
    renderDescriptionTimeline(item) {

        if (item.title == 'Avaliar Este Atendimento') {

            return (
                <TimelineItem
                    key={item.key}
                    dateText={item.dataHoraAtual}
                    dateInnerStyle={{ background: item.circleColor, color: '#000' }}
                    dateStyle={{ textAlign: 'center' }}
                    style={{ color: item.circleColor }}
                    bodyContainerStyle={{
                        background: this.props.backgroundSegundo,
                        padding: '10px',
                        borderRadius: '8px',
                        boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                        width: "100%"
                    }}
                >

                    <TextTitulo corLetraDinamica={this.props.coresLetraPadrao}>
                        {item.description}
                    </TextTitulo>
                    <TextAvaliar onClick={() => { toastr.warning('Aviso', 'Apenas cliente pode avaliar o atendimento') }} corLetraDinamica={this.props.corLetraDescricaoDosCampos}>{item.title}</TextAvaliar>
                    <div style={{ alignItems: 'flex-end', justifyContent: 'flex-end', display: 'flex' }}>
                        <p style={{ color: this.props.coresLetraSecundario, fontSize: 9 }}>{item.dataHoraAtual}</p>
                    </div>
                </TimelineItem>
            )


        } else {
            return (
                <TimelineItem
                    key={item.key}
                    dateText={item.dataHoraAtual}
                    dateInnerStyle={{ background: item.circleColor, color: '#000' }}
                    dateStyle={{ textAlign: 'center' }}
                    style={{ color: item.circleColor }}
                    bodyContainerStyle={{
                        background: this.props.backgroundSegundo,
                        padding: '10px',
                        borderRadius: '8px',
                        boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                        width: "100%"
                    }}
                >
                    <TextTitulo corLetraDinamica={this.props.corLetraDescricaoDosCampos}>{item.title}</TextTitulo>
                    <Linkify
                        componentDecorator={(decoratedHref, decoratedText, key) => (
                            <a style={{ fontSize: 13, color: '#39a3f4' }} target="blank" href={decoratedHref} key={key}>
                                {decoratedText}
                            </a>
                        )}
                    >
                        <TextMensagem corLetraDinamica={this.props.coresLetraPadrao}>
                            {item.description}
                        </TextMensagem>
                    </Linkify>
                    {/*  <TextMensagem corLetraDinamica={this.props.coresLetraPadrao}>
                        {item.description}
                    </TextMensagem> */}
                    <div style={{ alignItems: 'flex-end', justifyContent: 'flex-end', display: 'flex', flexDirection: 'column' }}>
                        <p style={{ color: this.props.coresLetraSecundario, fontSize: 9 }}>{item.dataHoraAtual}</p>
                        {item.visivelATodos == 0 && <p style={{ color: this.props.coresLetraSecundario, fontSize: 9, fontStyle: 'italic', marginBottom: -10 }}>comentário interno</p>}
                    </div>
                </TimelineItem >
            )
        }
    }
    renderTextGravacaoAudio() {
        if (this.state.statusAudio == 'gravar') {
            return (
                <p style={{
                    color: this.props.coresLetraPadrao,
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: 18,
                    fontFamily: 'Source Sans Pro'
                }}>Pressione no Microphone para Gravar</p>
            )
        } else if (this.state.statusAudio == 'gravando') {
            return (
                <TextGravandoAudio corDinamico={this.props.coresLetraPadrao} >GRAVANDO</TextGravandoAudio>
            )
        } else {
            //play audio
            return (
                <p style={{
                    color: this.props.coresLetraPadrao,
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: 18,
                    fontFamily: 'Source Sans Pro'
                }}>Gravação</p>
            )
        }

    }
    renderTimeLineItem(item) {
        if (item.tipo == 'mensagem') {
            return (
                <div key={item.key}>
                    {this.renderDescriptionTimeline(item)}
                </div>
            )
        } else {
            //
            //let tipoArquivo = rowData.description.split('.').pop().toLowerCase();
            if (item.tipoArquivo != undefined && item.tipoArquivo != null && item.tipoArquivo != '') {
                if (item.tipoArquivo.includes('image') && !item.tipoArquivo.includes('corel')) {
                    if (item.urlDownload == undefined || item.urlDownload == null || item.urlDownload == '') {
                        //tenho que buscar a url de download no firebase
                        //
                        //
                        return (
                            <TimelineItem
                                key={item.key}
                                dateText={item.dataHoraAtual}
                                dateInnerStyle={{ background: item.circleColor, color: '#000' }}
                                dateStyle={{ textAlign: 'center' }}
                                style={{ color: item.circleColor }}
                                bodyContainerStyle={{
                                    background: this.props.backgroundSegundo,
                                    padding: '10px',
                                    borderRadius: '8px',
                                    boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                                    width: "100%"
                                }}
                            >
                                <TextTitulo corLetraDinamica={this.props.corLetraDescricaoDosCampos}>{item.title}</TextTitulo>
                                <ContainerClipBaixarArquivo backgroundDinamico={this.props.backgroundSegundo} corBordaDinamica={this.props.coresLetraSecundario} onClick={() => { this.baixarArquivo(item.description, item) }} style={{}}>
                                    <i className="icon-clip2" style={{ fontSize: 15, color: this.props.coresLetraSecundario }} />
                                    <TextBaixarArquivo >{item.description}</TextBaixarArquivo>
                                </ContainerClipBaixarArquivo>
                                <div style={{ alignItems: 'flex-end', justifyContent: 'flex-end', display: 'flex' }}>
                                    <p style={{ color: this.props.coresLetraSecundario, fontSize: 9 }}>{item.dataHoraAtual}</p>
                                </div>
                            </TimelineItem>
                        )
                    } else {
                        //tenho a URL salva, somente renderizar
                        //
                        //
                        return (
                            <TimelineItem
                                key={item.key}
                                dateText={item.dataHoraAtual}
                                dateInnerStyle={{ background: item.circleColor, color: '#000' }}
                                dateStyle={{ textAlign: 'center' }}
                                style={{ color: item.circleColor }}
                                bodyContainerStyle={{
                                    background: this.props.backgroundSegundo,
                                    padding: '10px',
                                    borderRadius: '8px',
                                    boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                                    width: "100%"
                                }}
                            >
                                <TextTitulo corLetraDinamica={this.props.corLetraDescricaoDosCampos}>{item.title}</TextTitulo>
                                <ImagemComentario onClick={() => { this.baixarArquivo(item.description) }} src={item.urlDownload} alt={item.description} />
                                <ContainerClipBaixarArquivo backgroundDinamico={this.props.backgroundSegundo} corBordaDinamica={this.props.coresLetraSecundario} onClick={() => { this.baixarArquivo(item.description, item) }} style={{}}>
                                    <i className="icon-clip2" style={{ fontSize: 15, color: this.props.coresLetraSecundario }} />
                                    <TextBaixarArquivo >{item.description}</TextBaixarArquivo>
                                </ContainerClipBaixarArquivo>
                                <div style={{ alignItems: 'flex-end', justifyContent: 'flex-end', display: 'flex' }}>
                                    <p style={{ color: this.props.coresLetraSecundario, fontSize: 9 }}>{item.dataHoraAtual}</p>
                                </div>
                            </TimelineItem>
                        )
                        /* return (
                            <View style={{ flex: 1 }}>
                                <Text style={styles.titleStyle2}>{rowData.title}</Text>
                                <TouchableOpacity onPress={() => {
                                    checkInternetConnection().then(
                                        (isConnected) => {
                                            if (isConnected) {
                                                this.insereImagemModalState(rowData.urlDownload)
                                            } else {
                                                this.mensagemToastPersonalizada("Sem Conexão com a INTERNET!")
                                            }
                                        })

                                }}>

                                    <Image
                                        style={{ height: 170, width: 170, borderRadius: 20, borderWidth: .7, borderColor: '#CCC', marginLeft: 20, marginTop: 10, backgroundColor: '#CCC' }}
                                        source={{ uri: rowData.urlDownload }}
                                        resizeMethod="resize"

                                    />
                                </TouchableOpacity>
                                <View style={{ borderWidth: .5, borderColor: '#CCC', borderRadius: 5, marginTop: 15 }}>
                                    <TouchableOpacity
                                        style={{ flexDirection: 'row' }}
                                        onPress={() => { this.permissaoBaixarARquivo(rowData.description) }}
                                    >
                                        <Icon name="clip2" size={17} color="#CCC" style={{ padding: 5 }} />
                                        <Text numberOfLines={2} style={styles.descriptionStyle3}>{rowData.description}</Text>
                                    </TouchableOpacity>
                                </View>
                                <View style={{ alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                                    <Text style={styles.descriptionStyleDataHoraAtual}>{rowData.dataHoraAtual}</Text>
                                </View>
                            </View >
                        ) */
                    }

                } else if (item.audio == true) {
                    return (
                        <TimelineItem
                            key={item.key}
                            dateText={item.dataHoraAtual}
                            dateInnerStyle={{ background: item.circleColor, color: '#000' }}
                            dateStyle={{ textAlign: 'center' }}
                            style={{ color: item.circleColor }}
                            bodyContainerStyle={{
                                background: this.props.backgroundSegundo,
                                padding: '10px',
                                borderRadius: '8px',
                                boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                                width: "100%"
                            }}
                        >
                            <TextTitulo corLetraDinamica={this.props.corLetraDescricaoDosCampos}>{item.title}</TextTitulo>
                            <div style={{ marginTop: 10, marginBottom: 10 }}>
                                <audio style={{ height: 60, width: 250 }} src={item.urlDownload} controls />
                                {/*       <AudioPlayer
                                    src={item.urlDownload}
                                    preload='auto'
                                    showSkipControls={false}
                                    showJumpControls={false}
                                    layout={'horizontal-reverse'}
                                    defaultDuration={this.millisToMinutesAndSeconds(item.duracao)}
                                    customControlsSection={
                                        [
                                            RHAP_UI.MAIN_CONTROLS,
                                        ]
                                    }
                                    style={{
                                        width: '300px',
                                        borderRadius: 10,
                                        background: '#F0f0f7'
                                    }}
                                // other props here
                                /> */}
                            </div>
                            {/*    <ContainerClipBaixarArquivo backgroundDinamico={this.props.backgroundSegundo} corBordaDinamica={this.props.coresLetraSecundario} onClick={() => { this.baixarArquivo(item.description) }} style={{}}>
                                <i className="icon-clip2" style={{ fontSize: 15, color: this.props.coresLetraSecundario }} />
                                <TextBaixarArquivo >{item.description}</TextBaixarArquivo>
                            </ContainerClipBaixarArquivo> */}
                            <div style={{ alignItems: 'flex-end', justifyContent: 'flex-end', display: 'flex' }}>
                                <p style={{ color: this.props.coresLetraSecundario, fontSize: 9 }}>{item.dataHoraAtual}</p>
                            </div>
                        </TimelineItem>
                        /*       <View style={{ flex: 1 }}>
                                  <Text style={[styles.titleStyle2, { color: this.props.corLetraDescricaoDosCampos }]}>{rowData.title}</Text>
                                  <View style={{ flex: 1, alignItems: 'flex-end' }}>
                                      <TouchableOpacity onPress={() => {

                                          this.setState({ urlTeste: rowData.urlDownload })
                                      }} style={{ height: 55, width: '80%', backgroundColor: '#CCC', margin: 5, borderRadius: 25, flexDirection: 'row' }}>
                                          <View style={{ height: 55, width: '25%', alignItems: 'center', justifyContent: 'center' }}>
                                              <Icon name="play-button-arrowhead" size={18} color={this.props.letraBotaoPadrao} style={{}} />
                                          </View>
                                          <View style={{ flex: 1, alignItems: 'flex-start', justifyContent: 'space-between', marginRight: 7 }}>
                                              <Image
                                                  style={{ height: 55, width: '100%' }}
                                                  source={audioExecution}
                                                  resizeMethod="resize"
                                              />
                                              <View style={{ height: 15, marginTop: -15 }}>
                                                  <Text style={{ fontSize: 12, color: '#000', fontWeight: 'bold', }}>{this.millisToMinutesAndSeconds(rowData.duracao)}</Text>
                                              </View>
                                          </View>

                                      </TouchableOpacity>
                                  </View>
                                  <View style={{ alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                                      <Text style={[styles.descriptionStyleDataHoraAtual, { color: this.props.coresLetraSecundario }]}>{rowData.dataHoraAtual}</Text>
                                  </View>
                              </View > */
                        /*    <AudioConversa url={rowData.urlDownload} duracao={rowData.duracao} dataHoraAtual={rowData.dataHoraAtual} title={rowData.title} /> */
                    )
                } else {
                    //
                    return (
                        <TimelineItem
                            key={item.key}
                            dateText={item.dataHoraAtual}
                            dateInnerStyle={{ background: item.circleColor, color: '#000' }}
                            dateStyle={{ textAlign: 'center' }}
                            style={{ color: item.circleColor }}
                            bodyContainerStyle={{
                                background: this.props.backgroundSegundo,
                                padding: '10px',
                                borderRadius: '8px',
                                boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                                width: "100%"
                            }}
                        >
                            <TextTitulo corLetraDinamica={this.props.corLetraDescricaoDosCampos}>{item.title}</TextTitulo>
                            <ContainerClipBaixarArquivo backgroundDinamico={this.props.backgroundSegundo} corBordaDinamica={this.props.coresLetraSecundario} onClick={() => { this.baixarArquivo(item.description, item) }} style={{}}>
                                <i className="icon-clip2" style={{ fontSize: 15, color: this.props.coresLetraSecundario }} />
                                <TextBaixarArquivo >{item.description}</TextBaixarArquivo>
                            </ContainerClipBaixarArquivo>
                            <div style={{ alignItems: 'flex-end', justifyContent: 'flex-end', display: 'flex' }}>
                                <p style={{ color: this.props.coresLetraSecundario, fontSize: 9 }}>{item.dataHoraAtual}</p>
                            </div>
                        </TimelineItem>
                        /*  <View style={{ flex: 1 }}>
                             <Text style={styles.titleStyle2}>{rowData.title}</Text>
                             <View style={{ borderWidth: .5, borderColor: '#CCC', borderRadius: 5 }}>
                                 <TouchableOpacity
                                     style={{ flexDirection: 'row' }}
                                     onPress={() => { this.permissaoBaixarARquivo(rowData.description) }}
                                 >
                                     <Icon name="clip2" size={17} color="#CCC" style={{ padding: 5 }} />
                                     <Text numberOfLines={2} style={styles.descriptionStyle3}>{rowData.description}</Text>
                                 </TouchableOpacity>
                             </View>
                             <View style={{ alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                                 <Text style={styles.descriptionStyleDataHoraAtual}>{rowData.dataHoraAtual}</Text>
                             </View>
                         </View > */

                    )
                }
            } else {
                return (
                    <TimelineItem
                        key={item.key}
                        dateText={item.dataHoraAtual}
                        dateInnerStyle={{ background: item.circleColor, color: '#000' }}
                        dateStyle={{ textAlign: 'center' }}
                        style={{ color: item.circleColor }}
                        bodyContainerStyle={{
                            background: this.props.backgroundSegundo,
                            padding: '10px',
                            borderRadius: '8px',
                            boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                            width: "100%"
                        }}
                    >
                        <TextTitulo corLetraDinamica={this.props.corLetraDescricaoDosCampos}>{item.title}</TextTitulo>
                        <ContainerClipBaixarArquivo backgroundDinamico={this.props.backgroundSegundo} corBordaDinamica={this.props.coresLetraSecundario} onClick={() => { this.baixarArquivo(item.description, item) }} style={{}}>
                            <i className="icon-clip2" style={{ fontSize: 15, color: this.props.coresLetraSecundario }} />
                            <TextBaixarArquivo >{item.description}</TextBaixarArquivo>
                        </ContainerClipBaixarArquivo>
                        <div style={{ alignItems: 'flex-end', justifyContent: 'flex-end', display: 'flex' }}>
                            <p style={{ color: this.props.coresLetraSecundario, fontSize: 9 }}>{item.dataHoraAtual}</p>
                        </div>
                    </TimelineItem>
                )
            }
        }
    }
    renderAtendentes(item) {
        //
        //
        return (
            <DivAtendentes key={item.value}>
                <TextAtendentes onClick={() => {
                    this.props.modificaVisibilidadeModalAtribuirAtendimento(false);
                    this.props.atribuiAtendimentoAh(item.value, this.props.keyAtendimento, item.name, this.props.dadosAtendimentos[0].keySetor, this.props.dadosAtendimentos[0], false);
                }} corLetraBotaoPadrao={this.props.corBotaoPadrao} corLetraDinamico={this.props.coresLetraPadrao}>{item.name}</TextAtendentes>
            </DivAtendentes>
        )
    }
    abreModalAlterarChamado() {
        //

        /*   this.props.listaClientesTelaNovoChamado();
          this.props.listaSetorTelaNovoChamado(); */
        if (this.props.dadosAtendimentos[0].prioridade != "Não informado") {
            this.props.modificaPrioridadeSelecionado(this.props.dadosAtendimentos[0].prioridade)
            //  this.onSelectedItemsChangePrioridade([this.props.dadosAtendimentos[0].prioridade])
        } else {
            this.props.modificaPrioridadeSelecionado('')
        }
        if (this.props.dadosAtendimentos[0].keyAtendente != "Não informado") {
            this.props.modificaAtendenteSelecionado(this.props.dadosAtendimentos[0].keyAtendente)
            // this.onSelectedItemsChangeAtendente([this.props.dadosAtendimentos[0].keyAtendente])
        } else {
            this.props.modificaAtendenteSelecionado('')
        }
        if (this.props.dadosAtendimentos[0].keyCliente != "Não informado") {
            this.props.modificaClienteSelecionado(this.props.dadosAtendimentos[0].keyCliente);
            this.setState({ clientSelected: { value: this.props.dadosAtendimentos[0].keyCliente, label: this.props.dadosAtendimentos[0].cliente } });
            // this.onSelectedItemsChange([this.props.dadosAtendimentos[0].keyCliente])
        } else {
            this.props.modificaClienteSelecionado('')
        }
        if (this.props.dadosAtendimentos[0].keySetor != "Não informado") {
            this.props.modificaSetorSelecionado(this.props.dadosAtendimentos[0].keySetor)
            // this.onSelectedItemsChangeSetor([this.props.dadosAtendimentos[0].keySetor])
        } else {
            this.props.modificaSetorSelecionado('')
        }
        if (this.props.dadosAtendimentos[0].local != "Não informado") {
            this.props.modificaLocalSelecionado(this.props.dadosAtendimentos[0].local)
            //  this.onSelectedItemsChangeLocal([this.props.dadosAtendimentos[0].local])
        } else {
            this.props.modificaLocalSelecionado('')
        }

        if (this.props.dadosAtendimentos[0].dataVencimento != "Não informado") {
            this.props.modificaDataVencimentoTelaChamadoAbertura(this.props.dadosAtendimentos[0].dataVencimento)
        } else {
            this.props.modificaDataVencimentoTelaChamadoAbertura('')
        }
        //
        //
        this.props.alteraDescricaoChamado(this.props.dadosAtendimentos[0].descricaoProblema)

        this.props.modificaKeyEmpresaVinculadaSelecionadaAoAbrirChamado(this.props.dadosAtendimentos[0].keyEmpresaVinculada)
        this.props.modificaVisibilidadeModalAlterarAtendimento(true)
        this.setState({ dataVencimento: '' })

    }
    renderConteudoModalAlteracao() {

        return (
            <ContainerDetalhesModalNovoAtendimento >
                <SubContainerNovoAtendimento>
                    <p style={{ marginLeft: 20, color: this.props.corLetraDescricaoDosCampos, fontSize: 14 }}>Cliente:</p>
                    <ContainerSelectSearch>
                        {this.props.clientesNovoChamado.length === 0 && <div style={{ display: 'flex', flex: 1 }}>
                            <SelectSearchPaginate
                                onSelectClient={(client) => {

                                    this.setState({ clientSelected: { value: client.value, label: client.label } });
                                    const keyEmpresaVinculada = client.keyEmpresaVinculada === "Não Vinculado" ? "" : client.keyEmpresaVinculada;
                                    this.setState({ nomeAuxiliarCliente: client.nome });
                                    this.props.modificaClienteSelecionado(client.idUser);
                                    this.props.modificaEmpresaSelecionadaAoAbrirChamado(client.empresa);
                                    this.props.modificaKeyEmpresaVinculadaSelecionadaAoAbrirChamado(keyEmpresaVinculada);

                                }}
                                value={this.state.clientSelected}
                                isMyContact={false}
                                background={this.props.backgroundMaisEscuro}
                                letterColor={this.props.coresLetraPadrao}
                                backgroundDinamico={this.props.backgroundMaisEscuro}
                                placeholderColor={this.props.coresLetraSecundario}
                                corBordaDinamica={this.props.corBotaoPadrao}
                                width={400}
                                letraBotaoPadrao={this.props.letraBotaoPadrao}
                            />
                        </div>}
                        {this.props.clientesNovoChamado.length > 0 && <SelectSearch
                            renderValue={(valueProps) =>
                                <DivInputSearch>
                                    <InputSearch
                                        corLetraDinamico={this.props.coresLetraPadrao}
                                        backgroundDinamico={this.props.backgroundMaisEscuro}
                                        placeholderColor={this.props.corLetraSecundaria}
                                        corBordaDinamica={this.props.corBotaoPadrao}
                                        {...valueProps} ></InputSearch>
                                    <IconeSetaPraBaixo className="icon-angle-arrow-down" colorDinamico={this.props.coresLetraPadrao} />
                                </DivInputSearch>
                            }
                            value={this.props.clienteSelecionado} options={this.props.clientesNovoChamado} onChange={(value, option) => {
                                this.props.modificaClienteSelecionado(value);
                                this.setState({ nomeAuxiliarCliente: option.name });
                                this.props.modificaEmpresaSelecionadaAoAbrirChamado(option.empresa)
                                this.props.modificaKeyEmpresaVinculadaSelecionadaAoAbrirChamado(option.keyEmpresaVinculada)
                            }} placeholder={this.props.clientesNovoChamado.length == 0 ? `Nenhum Cliente cadastrado` : "Selecione o Cliente"} search={this.props.clientesNovoChamado.length == 0 ? false : true}
                         />}
                        <BotaoAdd data-tip="Novo Cliente" data-for="tip-right" backgroundDinamico={this.props.corBotaoPadrao} onClick={() => {
                            this.props.history.push(("/clientes"));
                            this.props.zeraInformacoesCadastroClienteExport();
                            this.props.modificaVisibleModalNovoCLiente(true);
                            this.props.modificaRetornaTelaClienteParaNovoAtendimento(true);
                        }}>
                            <i className="icon-add2-1" style={{ fontSize: 18, color: this.props.letraBotaoPadrao }} />
                        </BotaoAdd>
                    </ContainerSelectSearch>
                </SubContainerNovoAtendimento>
                <SubContainerNovoAtendimento>
                    <p style={{ marginLeft: 20, color: this.props.corLetraDescricaoDosCampos, fontSize: 14 }}>Atendente:</p>
                    <ContainerSelectSearch>
                        <SelectSearch
                            renderValue={(valueProps) =>
                                <DivInputSearch>
                                    <InputSearch
                                        corLetraDinamico={this.props.coresLetraPadrao}
                                        backgroundDinamico={this.props.backgroundMaisEscuro}
                                        placeholderColor={this.props.corLetraSecundaria}
                                        corBordaDinamica={this.props.corBotaoPadrao}
                                        {...valueProps} ></InputSearch>
                                    <IconeSetaPraBaixo className="icon-angle-arrow-down" colorDinamico={this.props.coresLetraPadrao} />
                                </DivInputSearch>
                            }
                            value={this.props.atendenteSelecionado} options={this.props.atendenteNovoChamado} onChange={(value, option) => { this.props.modificaAtendenteSelecionado(value); this.setState({ nomeAuxiliarAtendente: option.name }) }} placeholder={this.props.atendenteNovoChamado.length == 0 ? `Nenhum Atendente cadastrado` : "Selecione o Atendente"} search={this.props.atendenteNovoChamado.length == 0 ? false : true} />
                        <BotaoAdd data-tip="Novo Atendente" data-for="tip-top" backgroundDinamico={this.props.corBotaoPadrao} onClick={() => {
                            this.props.history.push(("/atendentes"));
                            this.props.zeraInformacoesCadastroAtendenteExport();
                            this.props.modificaVisibleModalNovoAtendente(true);
                            this.props.modificaRetornaTelaClienteParaNovoAtendimento(true);
                        }}>
                            <i className="icon-add2-1" style={{ fontSize: 18, color: this.props.letraBotaoPadrao }} />
                        </BotaoAdd>
                    </ContainerSelectSearch>

                </SubContainerNovoAtendimento>
                <SubContainerNovoAtendimento>
                    <p style={{ marginLeft: 20, color: this.props.corLetraDescricaoDosCampos, fontSize: 14 }}>Setor:</p>
                    <ContainerSelectSearch>
                        <SelectSearch
                            renderValue={(valueProps) =>
                                <DivInputSearch>
                                    <InputSearch
                                        corLetraDinamico={this.props.coresLetraPadrao}
                                        backgroundDinamico={this.props.backgroundMaisEscuro}
                                        placeholderColor={this.props.corLetraSecundaria}
                                        corBordaDinamica={this.props.corBotaoPadrao}
                                        {...valueProps} ></InputSearch>
                                    <IconeSetaPraBaixo className="icon-angle-arrow-down" colorDinamico={this.props.coresLetraPadrao} />
                                </DivInputSearch>
                            }
                            value={this.props.setorSelecionado} options={this.props.setorNovoChamado} onChange={(value, option) => { this.props.modificaSetorSelecionado(value); this.setState({ nomeAuxiliarSetor: option.name }) }} placeholder={this.props.setorNovoChamado.length == 0 ? `Nenhum Setor cadastrado` : "Selecione o Setor"} search={this.props.setorNovoChamado.length == 0 ? false : true} />
                        <BotaoAdd data-tip="Novo Setor" data-for="tip-top" backgroundDinamico={this.props.corBotaoPadrao} onClick={() => {
                            this.props.history.push(("/setores"));
                            this.props.modificaVisibleModalNovoSetor(true)
                            this.props.modificaRetornaTelaClienteParaNovoAtendimento(true);
                        }}>
                            <i className="icon-add2-1" style={{ fontSize: 18, color: this.props.letraBotaoPadrao }} />
                        </BotaoAdd>
                    </ContainerSelectSearch>
                </SubContainerNovoAtendimento>
                <SubContainerNovoAtendimento>
                    <p style={{ marginLeft: 20, fontSize: 14, color: this.props.corLetraDescricaoDosCampos }}>Data Vencimento:</p>
                    <DatePicker

                        /* selected={new Date()} */
                        onChange={(date) => { this.props.modificaDataVencimentoTelaChamadoAbertura(moment(date).format('DD/MM/YYYY HH:mm')); this.setState({ dataVencimento: date }) }}
                        locale="pt-BR"
                        value={this.props.dataVencimento}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeCaption="Hora"
                        selected={this.state.dataVencimento}
                        customInput={<ContainerSelectSearch>
                            <DivInputSearch>
                                <InputDataVencimento
                                    readOnly
                                    corLetraDinamico={this.props.coresLetraPadrao}
                                    backgroundDinamico={this.props.backgroundMaisEscuro}
                                    placeholderColor={this.props.corLetraSecundaria}
                                    corBordaDinamica={this.props.corBotaoPadrao}
                                    type="text"
                                    placeholder='Selecione a Data'
                                    value={this.props.dataVencimento}
                                >

                                </InputDataVencimento>
                                <IconeSetaPraBaixoDataVencimento className="icon-angle-arrow-down" colorDinamico={this.props.coresLetraPadrao} />
                            </DivInputSearch>
                        </ContainerSelectSearch>}
                        formatWeekDay={d => {
                            switch (d) {
                                case 'segunda':
                                    return 'Seg';
                                case 'terça':
                                    return 'Ter';
                                case 'quarta':
                                    return 'Qua';
                                case 'quinta':
                                    return 'Qui';
                                case 'sexta':
                                    return 'Sex';
                                case 'sábado':
                                    return 'Sab';
                                case 'domingo':
                                    return 'Dom';
                                default:
                                    return d;
                            }
                        }}
                        timeIntervals={5}
                        dateFormat="dd/MM/yyyy HH:mm"
                        placeholderText="Data Final" />
                </SubContainerNovoAtendimento>
                <SubContainerNovoAtendimento>
                    <p style={{ marginLeft: 20, fontSize: 14, color: this.props.corLetraDescricaoDosCampos }}>Prioridade:</p>
                    <ContainerSelectSearch>
                        <SelectSearch
                            renderValue={(valueProps) =>
                                <DivInputSearch>
                                    <InputSearch
                                        corLetraDinamico={this.props.coresLetraPadrao}
                                        backgroundDinamico={this.props.backgroundMaisEscuro}
                                        placeholderColor={this.props.corLetraSecundaria}
                                        corBordaDinamica={this.props.corBotaoPadrao}
                                        {...valueProps} ></InputSearch>
                                    <IconeSetaPraBaixo className="icon-angle-arrow-down" colorDinamico={this.props.coresLetraPadrao} />
                                </DivInputSearch>
                            }
                            value={this.props.prioridadeSelecionada} options={this.props.prioridade} onChange={(value) => this.props.modificaPrioridadeSelecionado(value)} placeholder={"Selecione a Prioridade"} search />
                    </ContainerSelectSearch>
                </SubContainerNovoAtendimento>
                <SubContainerNovoAtendimento>
                    <p style={{ marginLeft: 20, fontSize: 14, color: this.props.corLetraDescricaoDosCampos }}>Local:</p>
                    <ContainerSelectSearch>
                        <SelectSearch
                            renderValue={(valueProps) =>
                                <DivInputSearch>
                                    <InputSearch
                                        corLetraDinamico={this.props.coresLetraPadrao}
                                        backgroundDinamico={this.props.backgroundMaisEscuro}
                                        placeholderColor={this.props.corLetraSecundaria}
                                        corBordaDinamica={this.props.corBotaoPadrao}
                                        {...valueProps} ></InputSearch>
                                    <IconeSetaPraBaixo className="icon-angle-arrow-down" colorDinamico={this.props.coresLetraPadrao} />
                                </DivInputSearch>
                            }
                            value={this.props.localAtendimentoSelecionado} options={this.props.localAtendimento} onChange={(value) => this.props.modificaLocalSelecionado(value)} placeholder={"Selecione o Local"} search />
                    </ContainerSelectSearch>
                </SubContainerNovoAtendimento>
            </ContainerDetalhesModalNovoAtendimento>
        )
    }
    renderTempoGasto(item) {
        return (
            <DivAtendentes key={item.id}>
                <TextAtendentes onClick={() => {
                    this.props.modificaVisibilidadeModalAddTempoGastoAtendimento(false);
                    this.props.atribuirTempoEsperaAoChamado(this.props.keyAtendimento, item.hora);
                }} corLetraBotaoPadrao={this.props.corBotaoPadrao} corLetraDinamico={this.props.coresLetraPadrao}>{item.hora}</TextAtendentes>
            </DivAtendentes>
        )
    }
    renderRespostaPadrao(item) {
        return (
            /*    <DivTruncarTexto> */
            <DivRespostasPadroes style={{ marginTop: 15, marginLeft: 15 }} backgrounddinamico={this.props.backgroundSegundo} key={item.value}>
                <TextTitleResposta onClick={() => {
                    this._toggleModalRespostaPadrao();
                    this.props.modificaInputComentario(item.name);
                    this.props.modificavisibilidadeModalComentario(true)
                    //    this.props.salvarComentario(this.props.nomeArquivoAnexadoComentario, false,
                    //     item.name, [], 1, this.props.keyAtendimento, this.props.dadosAtendimentos[0].dataAbertura, this.props.premium, this.props.dadosAtendimentos[0].keyAtendente, this.props.dadosAtendimentos[0], false, [], this.state.viewResponder, this.state.idResponder, this.state.commentResponder, this.state.circleColorResponder, this.state.commentResponderType,this.state.atendenteResponder)
                    this.zeraMensagemResponder()
                }} corLetraBotaoPadrao={this.props.corBotaoPadrao} corLetraDinamico={this.props.coresLetraPadrao}>{item.subTitle}  <TextResposta corLetraBotaoPadrao={this.props.corBotaoPadrao} corLetraDinamico={this.props.coresLetraSecundario} >{item.name}</TextResposta></TextTitleResposta>
            </DivRespostasPadroes>
            /*  </DivTruncarTexto> */
        )
    }
    renderBotaoSalvarComentario() {
        if (this.props.inputComentario != '') {
            return (
                <ButtonSalvarComentario onClick={() => {
                    this.props.salvarComentario(this.props.nomeArquivoAnexadoComentario, this.props.switchComentario,
                        this.props.inputComentario, [], this.props.comentarioPublicoSimOuNao, this.props.keyAtendimento, this.props.dadosAtendimentos[0].dataAbertura, this.props.premium, this.props.dadosAtendimentos[0].keyAtendente, this.props.dadosAtendimentos[0], false, [], this.state.viewResponder, this.state.idResponder, this.state.commentResponder, this.state.circleColorResponder, this.state.commentResponderType, this.state.atendenteResponder);
                    this.zeraMensagemResponder()
                }} backgroundBotao={this.props.corBotaoPadrao}>
                    <TextBotaoSalvarComentar backgroundBotao={this.props.letraBotaoPadrao}>Salvar</TextBotaoSalvarComentar>
                </ButtonSalvarComentario>
            )
        } else {
            return (
                <ButtonSalvarComentarioSemComent onClick={() => { }} backgroundBotao={this.props.corSegundoBotaoPadrao}>
                    <TextBotaoSalvarComentar backgroundBotao={this.props.letraBotaoPadrao}>Salvar</TextBotaoSalvarComentar>
                </ButtonSalvarComentarioSemComent>
            )
        }
    }
    renderBotaoFecharAtendimento() {
        if (this.props.solucaoAtendimento != '') {
            return (
                <ButtonSalvarComentario onClick={() => { this.props.modificaVisibilidadeModalFinalizarAtendimento(false); this.props.atribuiStatusAtendimento(this.props.keyAtendimento, 'Fechado', this.props.dadosAtendimentos[0].dataAbertura, this.props.premium, this.props.dadosAtendimentos[0].keyAtendente, this.props.dadosAtendimentos[0], false, false, this.props.solucaoAtendimento); }}
                    backgroundBotao={this.props.corBotaoPadrao}>
                    <TextBotaoSalvarComentar backgroundBotao={this.props.letraBotaoPadrao}>Fechar</TextBotaoSalvarComentar>
                </ButtonSalvarComentario>
            )
        } else {
            return (
                <ButtonSalvarComentarioSemComent data-tip="Informe a solução para fechar o atendimento" data-for="tip-top" onClick={() => { }} backgroundBotao={this.props.corSegundoBotaoPadrao}>
                    <TextBotaoSalvarComentar backgroundBotao={this.props.letraBotaoPadrao}>Fechar</TextBotaoSalvarComentar>
                </ButtonSalvarComentarioSemComent>
            )
        }
    }
    mudaComentarioPublico() {
        let state = this.state;
        state.backgroundColor = this.props.corBotaoPadrao;
        state.colorTextComent = this.props.letraBotaoPadrao;
        state.colorTextComent2 = this.props.coresLetraPadrao;
        state.backgroundColor2 = 'transparent';
        state.placeholderInputComent = 'Comentário visível para clientes e atendentes';
        this.setState({ state });
        this.props.modificaComentarioPublicoSimOuNao(1)
    }
    mudaComentarioInterno() {
        let state = this.state;
        state.colorTextComent = this.props.coresLetraPadrao;
        state.backgroundColor = 'transparent';
        state.backgroundColor2 = this.props.corBotaoPadrao;
        state.colorTextComent2 = this.props.letraBotaoPadrao;
        state.placeholderInputComent = 'Comentário visível apenas para atendentes';
        this.setState({ state });
        this.props.modificaComentarioPublicoSimOuNao(0)
    }
    escolheArquivoChamado(res) {
        //
        if (this.props.dadosAtendimentos[0].status == 'Cancelado') {
            toastr.error('Error ao Anexar', 'Chamado Cancelado, reabra para fazer upload');
        } else if (this.props.dadosAtendimentos[0].status == 'Fechado') {
            toastr.error('Error ao Anexar', 'Chamado Fechado, reabra para fazer upload');
        } else {
            if (res.length > 0) {
                //
                /*  for (let i = 0; i < res.length; i++) { */

                //
                if (this.props.premium == 0) {
                    toastr.error('Recurso Premium', 'Este é um recurso PREMIUM, para fazer upload de arquivos e imagens, Assine a versão PREMIUM e libere esse e mais recursos!');
                } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
                    toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
                }
                else {

                    //
                    this.props.salvarComentario('tem arquivo sim', false,
                        '', res, true, this.props.keyAtendimento, this.props.dadosAtendimentos[0].dataAbertura, this.props.premium, this.props.dadosAtendimentos[0].keyAtendente, this.props.dadosAtendimentos[0], false, [], this.state.viewResponder, this.state.idResponder, this.state.commentResponder, this.state.circleColorResponder, this.state.commentResponderType, this.state.atendenteResponder)
                    this.zeraMensagemResponder()

                }
                /*  } */
            } else {
                toastr.error('Error ao Anexar', 'Selecione arquivo com no máximo 20MB');
            }
        }
    }
    download(url, name) {
        const a = document.createElement('a');
        a.href = url;
        a.download = name;
        a.click();
        a.target = "_blank";
        window.open(url);
    };
    baixarArquivo(nomeArquivo, item) {
        /*  AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
             const chaveUnica = chaveUnica2.replace(/"/g, ""); */
        toastr.warning('Download Iniciado', 'Baixando Arquivo...');
        /*  firebase.storage().ref().child(`${this.props.dadosAtendimentos[0].chaveUnica}/${this.props.keyAtendimento}/${this.props.keyAtendimento}/${nomeArquivo}`).getDownloadURL().then((url) => {
             firebase.storage().ref().child(`${this.props.dadosAtendimentos[0].chaveUnica}/${this.props.keyAtendimento}/${this.props.keyAtendimento}/${nomeArquivo}`).getMetadata().then((metadata) => { */
        //
        this.getFileBlob(item.urlDownload, (blob) => {

            let file = new File([blob], nomeArquivo, { type: blob.type });
            saveAs(file)
        })
        /*    saveAs(url, metadata.contentType); */
        /*  var link = document.createElement("a");
        if (link.download !== undefined) {
            //usar quando for abrir imagem
            link.setAttribute("href", url);
            link.setAttribute("target", "_blank");
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } */

        /* }).catch(function (error) {
            //
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {

                case 'storage/object-not-found':
                    // File doesn't exist
                    break;

                case 'storage/unauthorized':
                    // User doesn't have permission to access the object
                    break;

                case 'storage/canceled':
                    // User canceled the upload
                    break;

                case 'storage/unknown':
                    // Unknown error occurred, inspect the server response
                    break;
            }
        });
    }).catch(function (error) {
        //
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {

            case 'storage/object-not-found':
                // File doesn't exist
                break;

            case 'storage/unauthorized':
                // User doesn't have permission to access the object
                break;

            case 'storage/canceled':
                // User canceled the upload
                break;

            case 'storage/unknown':
                // Unknown error occurred, inspect the server response
                break;
        }
    }); */
        /*  }) */
    }
    renderBordaDrag(isDragActive, isDragAccept, isDragReject) {
        if (isDragAccept == true) {
            return '#00FF7F';
        } else {
            return 'red';
        }
    }

    _toggleModalRespostaPadrao() {
        this.setState({ modalRespostaPadrao: !this.state.modalRespostaPadrao })
    }
    renderMicrophoneRecording() {
        if (this.state.statusAudio == 'gravando') {
            return (
                <div style={{ display: 'flex', flexDirection: 'row', justifyItems: 'center', alignItems: 'center', marginBottom: 7, marginLeft: 5 }}>
                    < IconGravadorVoz
                        style={{ cursor: 'pointer', fontSize: 18 }}
                        onClick={() => { this.onStopRecord(true); }}
                        colorDinamico={this.props.coresLetraPadrao}
                        bordaDinamica={this.props.corBotaoPadrao}
                        className="icon-rubbish-bin" />

                    {<p style={{
                        color: this.props.coresLetraPadrao,
                        fontSize: 17,
                        fontWeight: 'bold',
                        letterSpacing: 3,
                        marginLeft: 8,
                        marginRight: 8
                    }}>{this.transformaMinutosEmSegundos(this.state.recordSecs)}</p>}
                    <ScaleLoader
                        css={{ marginLeft: 10, marginRight: 10, marginTop: 5 }}
                        width={20}
                        height={12}
                        //size={60}
                        color={this.props.corSegundoBotaoPadrao}
                        loading
                    />
                    < IconGravadorVoz
                        style={{ cursor: 'pointer', marginRight: 3 }}
                        onClick={() => { this.onStopRecord(false) }}
                        colorDinamico={'#ff5e3e'}
                        bordaDinamica={this.props.corBotaoPadrao}
                        className="icon-stop-button" />
                    < IconGravadorVoz
                        style={{ cursor: 'pointer' }}
                        onClick={() => {

                            this.onStopRecord(false);
                            setTimeout(() => {
                                this.enviarAudioFirebase()
                            }, 500);

                        }}
                        // colorDinamico={'#00FA9A'}
                        colorDinamico={'#00BEA4'}
                        bordaDinamica={this.props.corBotaoPadrao}
                        className="icon-send2" />
                </div>
            )
        } else if (this.state.statusAudio == 'gravacao') {
            return (

                <div style={{ display: 'flex', flexDirection: 'row', justifyItems: 'center', alignItems: 'center', marginBottom: 7, marginLeft: 5 }}>
                    < IconGravadorVoz
                        style={{ cursor: 'pointer', fontSize: 18 }}
                        onClick={() => { this.onStopRecord(true) }}
                        colorDinamico={this.props.coresLetraPadrao}
                        bordaDinamica={this.props.corBotaoPadrao}
                        className="icon-rubbish-bin" />

                    <div style={{ display: 'flex', width: 250, alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                        <audio style={{ height: 35, width: 250 }} src={this.state.blobURL} controls />
                    </div>
                    {/*  < IconGravadorVoz
                        style={{ cursor: this.props.data.status === 'Fechado' ? 'no-drop' : 'pointer', marginRight: 3 }}
                        onClick={() => { if (this.props.data.status === 'Fechado') { } else { this.onStopRecord(false); } }}
                        colorDinamico={'#ff5e3e'}
                        bordaDinamica={this.props.corBotaoPadrao}
                        className="icon-stop-button" /> */}
                    < IconeMostrarSenha
                        style={{ cursor: 'pointer' }}
                        onClick={() => { this.enviarAudioFirebase() }}
                        // colorDinamico={'#00FA9A'}
                        colorDinamico={'#00BEA4'}
                        bordaDinamica={this.props.corBotaoPadrao}
                        className="icon-send2" />
                </div>

            )
        }
        else {
            return (
                /*   < IconeMostrarSenha
                      style={{ cursor: this.props.data.status === 'Fechado' ? 'no-drop' : 'pointer', marginLeft: 10 }}
                      data-tip={this.props.data.status === 'Fechado' ? 'Atendimento Encerrado' : "Enviar mensagem de áudio"} data-for="tip-top"
                      onClick={() => { if (this.props.data.status === 'Fechado') { } else { this.onStartRecord() } }}
                      colorDinamico={'#383838'}
                      bordaDinamica={this.props.corBotaoPadrao}
                      className="icon-microphone" /> */
                <ContainerIconeResposta onClick={() => { this.onStartRecord() }} backgroundBotao={this.props.corSegundoBotaoPadrao} hoverBotao={this.props.coresLetraPadrao}>
                    <i className="icon-microphone" data-tip="Enviar mensagem de áudio" data-for="tip-top" style={{ fontSize: 15, color: this.props.letraBotaoPadrao, }} />
                </ContainerIconeResposta>
            )
        }
    }
    renderBotesAcoesChamado() {
        /* return (

            <ContainerAcoes backGroundDinamico={this.props.backgroundMaisEscuro}>
                <ContainerBotoesAcoes>
                    <ButtonVariosNovos onClick={() => { this.props.modificaVisibilidadeModalAtribuirAtendimento(true) }} data-tip="Atribuir atendimento ao atendente" data-for="tip-top" backgroundBotao={this.props.corSegundoBotaoPadrao} hoverBotao={this.props.coresLetraPadrao}>
                        <IconeVariosNovos className="icon-atender" colorPrimario={this.props.letraBotaoPadrao} />
                        <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Atribuir</TextBotaoNovo>
                    </ButtonVariosNovos>
                    <ButtonVariosNovos onClick={() => { this.props.modificaVisibilidadeModalAddTempoGastoAtendimento(true) }} data-tip="Adicionar o tempo gasto no atendimento do chamado" data-for="tip-top" backgroundBotao={this.props.corSegundoBotaoPadrao} hoverBotao={this.props.coresLetraPadrao}>
                        <IconeVariosNovos className="icon-esperawev" colorPrimario={this.props.letraBotaoPadrao} />
                        <TextBotaoNovoTempoGasto colorDinamico={this.props.letraBotaoPadrao}>Tempo Gasto</TextBotaoNovoTempoGasto>
                    </ButtonVariosNovos>




                    <ButtonVariosNovos onClick={() => { this.props.modificaVisibilidadeModalFinalizarAtendimento(true) }} data-tip="Finalizar o atendimento" data-for="tip-top" backgroundBotao={this.props.corSegundoBotaoPadrao} hoverBotao={this.props.coresLetraPadrao}>
                        <IconeVariosNovos className="icon-feitoweb" colorPrimario={this.props.letraBotaoPadrao} />
                        <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Fechar</TextBotaoNovo>
                    </ButtonVariosNovos>
                    <ButtonVariosNovos onClick={() => { this.props.modificaVisibilidadeModalPrioridadeAtendimento(true) }} data-tip="Setar uma prioridade no atendimento" data-for="tip-top" backgroundBotao={this.props.corSegundoBotaoPadrao} hoverBotao={this.props.coresLetraPadrao}>
                        <IconeVariosNovos className="icon-prioridadeweb3" colorPrimario={this.props.letraBotaoPadrao} />
                        <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Prioridade</TextBotaoNovo>
                    </ButtonVariosNovos>
                    <DatePicker
                        selected={this.state.dataFinal}
                        onChange={(date) => { }}
                        locale="pt-BR"
                        onClose
                        customInput={<ButtonVariosNovos data-tip="Inserir um vencimento ao atendimento" data-for="tip-top" backgroundBotao={this.props.corSegundoBotaoPadrao} hoverBotao={this.props.coresLetraPadrao}>
                            <IconeVariosNovos className="icon-calendarweb" colorPrimario={this.props.letraBotaoPadrao} />
                            <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Vencimento</TextBotaoNovo>
                        </ButtonVariosNovos>}
                        formatWeekDay={d => {
                            switch (d) {
                                case 'segunda':
                                    return 'Seg';
                                case 'terça':
                                    return 'Ter';
                                case 'quarta':
                                    return 'Qua';
                                case 'quinta':
                                    return 'Qui';
                                case 'sexta':
                                    return 'Sex';
                                case 'sábado':
                                    return 'Sab';
                                case 'domingo':
                                    return 'Dom';
                                default:
                                    return d;
                            }
                        }}
                        timeIntervals={5}
                        dateFormat="dd/MM/yyyy HH:mm"
                        placeholderText="Data Final" />
                    <ButtonVariosNovos onClick={() => { this.props.modificaVisibilidadeModalCancelarAtendimento(true) }} data-tip="Cancelar o atendimento" data-for="tip-top" backgroundBotao={this.props.corSegundoBotaoPadrao} hoverBotao={this.props.coresLetraPadrao}>
                        <IconeVariosNovos className="icon-sair" colorPrimario={this.props.letraBotaoPadrao} />
                        <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Cancelar</TextBotaoNovo>
                    </ButtonVariosNovos>
                </ContainerBotoesAcoes>
                <ContainerIconesLaterais>
                    <ContainerIconeLateral onClick={() => {

                        this.props.modificaVisibilidadeModalAlterarAtendimento(true)

                    }} data-tip="Alterar informações do atendimento" data-for="tip-top" backgroundBotao={this.props.corSegundoBotaoPadrao} hoverBotao={this.props.coresLetraPadrao}>
                        <i className="icon-editar" style={{ fontSize: 18, color: this.props.letraBotaoPadrao }} />
                    </ContainerIconeLateral>
                    <ContainerIconeLateral backgroundBotao={this.props.corSegundoBotaoPadrao} hoverBotao={this.props.coresLetraPadrao}>
                        <i className="icon-clip" data-tip="Fazer upload de arquivos e imagens" data-for="tip-top" style={{ fontSize: 18, color: this.props.letraBotaoPadrao }} />
                    </ContainerIconeLateral>
                    <ContainerIconeLateral backgroundBotao={this.props.corSegundoBotaoPadrao} hoverBotao={this.props.coresLetraPadrao}>
                        <i className="icon-pdf2" data-tip="Relatório em PDF do atendimento" data-for="tip-top" style={{ fontSize: 18, color: this.props.letraBotaoPadrao }} />
                    </ContainerIconeLateral>
                </ContainerIconesLaterais>
            </ContainerAcoes>
        ) */

        if (this.props.verificaSeAtribuirAtendenteObrigatorio == true) {
            //
            // nao tem atendente
            /*  if (this.props.dadosAtendimentos[0].channelChamado.includes('Chat') && this.props.isAdmin === false) {
                 //cancelado
                 return (
                     <>
                         <ContainerAcoes backGroundDinamico={this.props.backgroundMaisEscuro}>
                             <ContainerBotoesAcoes>
                                 <BotaoDesativado data-tip="Atendimento Em Andamento Via Atendimentos Online, Somente após finalizar para executar alguma ação" data-for="tip-top" backgroundBotao="#9fadb7">
                                     <IconeVariosNovos className="icon-atender" colorPrimario={this.props.letraBotaoPadrao} />
                                     <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Atribuir</TextBotaoNovo>
                                 </BotaoDesativado>
                                 <BotaoDesativado data-tip="Atendimento Em Andamento Via Atendimentos Online, Somente após finalizar para executar alguma ação" data-for="tip-top" backgroundBotao="#9fadb7" hoverBotao={this.props.coresLetraPadrao}>
                                     <IconeVariosNovos className="icon-esperawev" colorPrimario={this.props.letraBotaoPadrao} />
                                     <TextBotaoNovoTempoGasto colorDinamico={this.props.letraBotaoPadrao}>Tempo Gasto</TextBotaoNovoTempoGasto>
                                 </BotaoDesativado>
                                 <BotaoDesativado data-tip="Atendimento Em Andamento Via Atendimentos Online, Somente após finalizar para executar alguma ação" data-for="tip-top" backgroundBotao="#9fadb7" hoverBotao={this.props.coresLetraPadrao}>
                                     <IconeVariosNovos className="icon-feitoweb" colorPrimario={this.props.letraBotaoPadrao} />
                                     <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Fechar</TextBotaoNovo>
                                 </BotaoDesativado>
                                 <BotaoDesativado data-tip="Atendimento Em Andamento Via Atendimentos Online, Somente após finalizar para executar alguma ação" data-for="tip-top" backgroundBotao="#9fadb7" hoverBotao={this.props.coresLetraPadrao}>
                                     <IconeVariosNovos className="icon-prioridadeweb3" colorPrimario={this.props.letraBotaoPadrao} />
                                     <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Prioridade</TextBotaoNovo>
                                 </BotaoDesativado>
                                 <BotaoDesativado data-tip="Atendimento Em Andamento Via Atendimentos Online, Somente após finalizar para executar alguma ação" data-for="tip-top" backgroundBotao="#9fadb7" hoverBotao={this.props.coresLetraPadrao}>
                                     <IconeVariosNovos className="icon-vencimento" colorPrimario={this.props.letraBotaoPadrao} />
                                     <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Vencimento</TextBotaoNovo>
                                 </BotaoDesativado>
                                 <ButtonVariosNovos onClick={() => { this._toggleModalReabrirChamado() }} data-tip="Reabrir atendimento" data-for="tip-top" backgroundBotao="#9fadb7" hoverBotao={this.props.coresLetraPadrao}>
                                     <IconeVariosNovos className="icon-sair" colorPrimario={this.props.letraBotaoPadrao} />
                                     <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Cancelado</TextBotaoNovo>
                                 </ButtonVariosNovos>
                             </ContainerBotoesAcoes>
                             <ContainerIconesLaterais>
                                 <ContainerIconeLateralDesativado data-tip="Atendimento Em Andamento Via Atendimentos Online, Somente após finalizar para executar alguma ação" data-for="tip-top" backgroundBotao="#9fadb7" hoverBotao={this.props.coresLetraPadrao}>
                                     <i className="icon-editar" style={{ fontSize: 18, color: this.props.letraBotaoPadrao }} />
                                 </ContainerIconeLateralDesativado>
                                 <ContainerIconeLateralDesativado backgroundBotao="#9fadb7" hoverBotao={this.props.coresLetraPadrao}>
                                     <i className="icon-clip" data-tip="Atendimento Em Andamento Via Atendimentos Online, Somente após finalizar para executar alguma ação" data-for="tip-top" style={{ fontSize: 18, color: this.props.letraBotaoPadrao }} />
                                 </ContainerIconeLateralDesativado>
                                 <ContainerIconeLateral onClick={() => {
                                     if (this.props.premium == 0) {
                                         toastr.error('Recurso Premium', 'Este é um recurso PREMIUM, para fazer upload de arquivos e imagens, Assine a versão PREMIUM e libere esse e mais recursos!');
                                     } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
                                         toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
                                     }
                                     else {
                                         this.props.exportaAtendimentoParaPDF(this.props.dadosAtendimentos[0])
                                     }
                                 }} backgroundBotao={this.props.corSegundoBotaoPadrao} hoverBotao={this.props.coresLetraPadrao}>
                                     <i className="icon-pdf2" data-tip="Relatório em PDF do atendimento" data-for="tip-top" style={{ fontSize: 18, color: this.props.letraBotaoPadrao }} />
                                 </ContainerIconeLateral>
                             </ContainerIconesLaterais>
                         </ContainerAcoes>
                         <ContainerComentario backGroundDinamico={this.props.backgroundMaisEscuro}>
                             <DivAdicionarComentarioDesativado data-tip="Atendimento Em Andamento Via Atendimentos Online, Somente após finalizar para executar alguma ação" data-for="tip-top" backGroundDinamico={this.props.backgroundPrimeiro}>
                                 <TextAddComent corLetraDinamica={this.props.coresLetraSecundario}>Adicionar Comentario</TextAddComent>
                             </DivAdicionarComentarioDesativado>
                             <ContainerIconeRespostaChamadoFechado backgroundBotao='#9fadb7' hoverBotao={this.props.coresLetraPadrao}>
                                 <i className="icon-list-on-window" data-tip="Atendimento Em Andamento Via Atendimentos Online, Somente após finalizar para executar alguma ação" data-for="tip-top" style={{ fontSize: 15, color: this.props.letraBotaoPadrao }} />
                             </ContainerIconeRespostaChamadoFechado>
                             <ContainerIconeRespostaChamadoFechado backgroundBotao='#9fadb7' hoverBotao={this.props.coresLetraPadrao}>
                                 <i className="icon-microphone" data-tip="Atendimento Em Andamento Via Atendimentos Online, Somente após finalizar para executar alguma ação" data-for="tip-top" style={{ fontSize: 15, color: this.props.letraBotaoPadrao, }} />
                             </ContainerIconeRespostaChamadoFechado>
                         </ContainerComentario>
                     </>
                 )
             } */ /* else { */
            if (this.props.dadosAtendimentos[0].atendente == 'Não informado') {
                if (this.props.dadosAtendimentos[0].status == 'Fechado') {
                    return (
                        <>
                            <ContainerAcoes backGroundDinamico={this.props.backgroundMaisEscuro}>
                                <ContainerBotoesAcoes>
                                    <BotaoDesativado data-tip="Atendimento finalizado, Reabra caso deseja executar alguma ação" data-for="tip-top" backgroundBotao="#9fadb7">
                                        <IconeVariosNovos className="icon-atender" colorPrimario={this.props.letraBotaoPadrao} />
                                        <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Atribuir</TextBotaoNovo>
                                    </BotaoDesativado>
                                    <BotaoDesativado data-tip="Atendimento finalizado, Reabra caso deseja executar alguma ação" data-for="tip-top" backgroundBotao="#9fadb7" hoverBotao={this.props.coresLetraPadrao}>
                                        <IconeVariosNovos className="icon-esperawev" colorPrimario={this.props.letraBotaoPadrao} />
                                        <TextBotaoNovoTempoGasto colorDinamico={this.props.letraBotaoPadrao}>Tempo Gasto</TextBotaoNovoTempoGasto>
                                    </BotaoDesativado>
                                    <ButtonVariosNovos data-tip="Reabrir atendimento" onClick={() => { this._toggleModalReabrirChamado() }} data-for="tip-top" backgroundBotao="#00FF7F" hoverBotao={this.props.coresLetraPadrao}>
                                        <IconeVariosNovos className="icon-feitoweb" colorPrimario={this.props.letraBotaoPadrao} />
                                        <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Fechado</TextBotaoNovo>
                                    </ButtonVariosNovos>
                                    <BotaoDesativado data-tip="Atendimento finalizado, Reabra caso deseja executar alguma ação" data-for="tip-top" backgroundBotao="#9fadb7" hoverBotao={this.props.coresLetraPadrao}>
                                        <IconeVariosNovos className="icon-prioridadeweb3" colorPrimario={this.props.letraBotaoPadrao} />
                                        <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Prioridade</TextBotaoNovo>
                                    </BotaoDesativado>
                                    <BotaoDesativado data-tip="Atendimento finalizado, Reabra caso deseja executar alguma ação" data-for="tip-top" backgroundBotao="#9fadb7" hoverBotao={this.props.coresLetraPadrao}>
                                        <IconeVariosNovos className="icon-vencimento" colorPrimario={this.props.letraBotaoPadrao} />
                                        <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Vencimento</TextBotaoNovo>
                                    </BotaoDesativado>
                                    {this.props.permissoesAcessarTelasAtendente.permissaoCancelarAtendimento === true && <BotaoDesativado data-tip="Atendimento finalizado, Reabra caso deseja executar alguma ação" data-for="tip-top" backgroundBotao="#9fadb7" hoverBotao={this.props.coresLetraPadrao}>
                                        <IconeVariosNovos className="icon-sair" colorPrimario={this.props.letraBotaoPadrao} />
                                        <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Cancelar</TextBotaoNovo>
                                    </BotaoDesativado>}
                                </ContainerBotoesAcoes>
                                <ContainerIconesLaterais>
                                    <ContainerIconeLateralDesativado data-tip="Atendimento finalizado, Reabra caso deseja executar alguma ação" data-for="tip-top" backgroundBotao="#9fadb7" hoverBotao={this.props.coresLetraPadrao}>
                                        <i className="icon-editar" style={{ fontSize: 18, color: this.props.letraBotaoPadrao }} />
                                    </ContainerIconeLateralDesativado>
                                    <ContainerIconeLateralDesativado backgroundBotao="#9fadb7" hoverBotao={this.props.coresLetraPadrao}>
                                        <i className="icon-clip" data-tip="Atendimento finalizado, Reabra caso deseja executar alguma ação" data-for="tip-top" style={{ fontSize: 18, color: this.props.letraBotaoPadrao }} />
                                    </ContainerIconeLateralDesativado>
                                    <ContainerIconeLateral onClick={() => {
                                        if (this.props.premium == 0) {
                                            toastr.error('Recurso Premium', 'Este é um recurso PREMIUM, para fazer upload de arquivos e imagens, Assine a versão PREMIUM e libere esse e mais recursos!');
                                        } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
                                            toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
                                        }
                                        else {
                                            this.props.exportaAtendimentoParaPDF(this.props.dadosAtendimentos[0])
                                        }
                                    }} backgroundBotao={this.props.corSegundoBotaoPadrao} hoverBotao={this.props.coresLetraPadrao}>
                                        <i className="icon-pdf2" data-tip="Relatório em PDF do atendimento" data-for="tip-top" style={{ fontSize: 18, color: this.props.letraBotaoPadrao }} />
                                    </ContainerIconeLateral>
                                </ContainerIconesLaterais>

                            </ContainerAcoes>
                            <ContainerComentario backGroundDinamico={this.props.backgroundMaisEscuro}>
                                <DivAdicionarComentarioDesativado data-tip="Atendimento finalizado, Reabra caso deseja executar alguma ação" data-for="tip-top" backGroundDinamico={this.props.backgroundPrimeiro}>
                                    <TextAddComent corLetraDinamica={this.props.coresLetraSecundario}>Adicionar Comentario</TextAddComent>
                                </DivAdicionarComentarioDesativado>
                                <ContainerIconeRespostaChamadoFechado backgroundBotao='#9fadb7' hoverBotao={this.props.coresLetraPadrao}>
                                    <i className="icon-list-on-window" data-tip="Reabra caso deseja comentar alguma resposta padrão" data-for="tip-top" style={{ fontSize: 15, color: this.props.letraBotaoPadrao, }} />
                                </ContainerIconeRespostaChamadoFechado>
                                {this.renderMicrophoneRecording()}
                                {/*  <ContainerIconeRespostaChamadoFechado backgroundBotao='#9fadb7' hoverBotao={this.props.coresLetraPadrao}>
                                    <i className="icon-microphone" data-tip="Reabra caso deseja enviar mensagem de audio" data-for="tip-top" style={{ fontSize: 15, color: this.props.letraBotaoPadrao, }} />
                                </ContainerIconeRespostaChamadoFechado> */}
                            </ContainerComentario>
                        </>
                    )
                } else if (this.props.dadosAtendimentos[0].status == 'Cancelado') {
                    //cancelado
                    return (
                        <>
                            <ContainerAcoes backGroundDinamico={this.props.backgroundMaisEscuro}>
                                <ContainerBotoesAcoes>
                                    <BotaoDesativado data-tip="Atendimento Cancelado, Reabra caso deseja executar alguma ação" data-for="tip-top" backgroundBotao="#9fadb7">
                                        <IconeVariosNovos className="icon-atender" colorPrimario={this.props.letraBotaoPadrao} />
                                        <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Atribuir</TextBotaoNovo>
                                    </BotaoDesativado>
                                    <BotaoDesativado data-tip="Atendimento Cancelado, Reabra caso deseja executar alguma ação" data-for="tip-top" backgroundBotao="#9fadb7" hoverBotao={this.props.coresLetraPadrao}>
                                        <IconeVariosNovos className="icon-esperawev" colorPrimario={this.props.letraBotaoPadrao} />
                                        <TextBotaoNovoTempoGasto colorDinamico={this.props.letraBotaoPadrao}>Tempo Gasto</TextBotaoNovoTempoGasto>
                                    </BotaoDesativado>
                                    <BotaoDesativado data-tip="Atendimento Cancelado, Reabra caso deseja executar alguma ação" data-for="tip-top" backgroundBotao="#9fadb7" hoverBotao={this.props.coresLetraPadrao}>
                                        <IconeVariosNovos className="icon-feitoweb" colorPrimario={this.props.letraBotaoPadrao} />
                                        <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Fechar</TextBotaoNovo>
                                    </BotaoDesativado>
                                    <BotaoDesativado data-tip="Atendimento Cancelado, Reabra caso deseja executar alguma ação" data-for="tip-top" backgroundBotao="#9fadb7" hoverBotao={this.props.coresLetraPadrao}>
                                        <IconeVariosNovos className="icon-prioridadeweb3" colorPrimario={this.props.letraBotaoPadrao} />
                                        <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Prioridade</TextBotaoNovo>
                                    </BotaoDesativado>
                                    <BotaoDesativado data-tip="Atendimento Cancelado, Reabra caso deseja executar alguma ação" data-for="tip-top" backgroundBotao="#9fadb7" hoverBotao={this.props.coresLetraPadrao}>
                                        <IconeVariosNovos className="icon-vencimento" colorPrimario={this.props.letraBotaoPadrao} />
                                        <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Vencimento</TextBotaoNovo>
                                    </BotaoDesativado>
                                    <ButtonVariosNovos onClick={() => { this._toggleModalReabrirChamado() }} data-tip="Reabrir atendimento" data-for="tip-top" backgroundBotao="#f70e4c" hoverBotao={this.props.coresLetraPadrao}>
                                        <IconeVariosNovos className="icon-sair" colorPrimario={this.props.letraBotaoPadrao} />
                                        <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Cancelado</TextBotaoNovo>
                                    </ButtonVariosNovos>
                                </ContainerBotoesAcoes>
                                <ContainerIconesLaterais>
                                    <ContainerIconeLateralDesativado data-tip="Atendimento Cancelado, Reabra caso deseja executar alguma ação" data-for="tip-top" backgroundBotao="#9fadb7" hoverBotao={this.props.coresLetraPadrao}>
                                        <i className="icon-editar" style={{ fontSize: 18, color: this.props.letraBotaoPadrao }} />
                                    </ContainerIconeLateralDesativado>
                                    <ContainerIconeLateralDesativado backgroundBotao="#9fadb7" hoverBotao={this.props.coresLetraPadrao}>
                                        <i className="icon-clip" data-tip="Atendimento Cancelado, Reabra caso deseja executar alguma ação" data-for="tip-top" style={{ fontSize: 18, color: this.props.letraBotaoPadrao }} />
                                    </ContainerIconeLateralDesativado>
                                    <ContainerIconeLateral onClick={() => {
                                        if (this.props.premium == 0) {
                                            toastr.error('Recurso Premium', 'Este é um recurso PREMIUM, para fazer upload de arquivos e imagens, Assine a versão PREMIUM e libere esse e mais recursos!');
                                        } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
                                            toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
                                        }
                                        else {
                                            this.props.exportaAtendimentoParaPDF(this.props.dadosAtendimentos[0])
                                        }
                                    }} backgroundBotao={this.props.corSegundoBotaoPadrao} hoverBotao={this.props.coresLetraPadrao}>
                                        <i className="icon-pdf2" data-tip="Relatório em PDF do atendimento" data-for="tip-top" style={{ fontSize: 18, color: this.props.letraBotaoPadrao }} />
                                    </ContainerIconeLateral>
                                </ContainerIconesLaterais>
                            </ContainerAcoes>
                            <ContainerComentario backGroundDinamico={this.props.backgroundMaisEscuro}>
                                <DivAdicionarComentarioDesativado data-tip="Atendimento Cancelado, Reabra caso deseja executar alguma ação" data-for="tip-top" backGroundDinamico={this.props.backgroundPrimeiro}>
                                    <TextAddComent corLetraDinamica={this.props.coresLetraSecundario}>Adicionar Comentario</TextAddComent>
                                </DivAdicionarComentarioDesativado>
                                <ContainerIconeRespostaChamadoFechado backgroundBotao='#9fadb7' hoverBotao={this.props.coresLetraPadrao}>
                                    <i className="icon-list-on-window" data-tip="Reabra caso deseja comentar alguma resposta padrão" data-for="tip-top" style={{ fontSize: 15, color: this.props.letraBotaoPadrao }} />
                                </ContainerIconeRespostaChamadoFechado>
                                {this.renderMicrophoneRecording()}
                                {/*   <ContainerIconeRespostaChamadoFechado backgroundBotao='#9fadb7' hoverBotao={this.props.coresLetraPadrao}>
                                    <i className="icon-microphone" data-tip="Reabra caso deseja enviar mensagem de audio" data-for="tip-top" style={{ fontSize: 15, color: this.props.letraBotaoPadrao, }} />
                                </ContainerIconeRespostaChamadoFechado> */}
                            </ContainerComentario>
                        </>
                    )
                }
                else {
                    //chamado atendendo ou aberto e sem atendente
                    return (
                        <>
                            <ContainerAcoes backGroundDinamico={this.props.backgroundMaisEscuro}>
                                <ContainerBotoesAcoes>
                                    <ButtonVariosNovos onClick={() => { this.props.modificaVisibilidadeModalAtribuirAtendimento(true) }} data-tip="Atribuir um atendente ao atendimento" data-for="tip-top" backgroundBotao={this.props.corSegundoBotaoPadrao}>
                                        <IconeVariosNovos className="icon-atender" colorPrimario={this.props.letraBotaoPadrao} />
                                        <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Atribuir</TextBotaoNovo>
                                    </ButtonVariosNovos>
                                    <BotaoDesativado data-tip="Atribua um atendente, caso deseja executar alguma ação" data-for="tip-top" backgroundBotao="#9fadb7" hoverBotao={this.props.coresLetraPadrao}>
                                        <IconeVariosNovos className="icon-esperawev" colorPrimario={this.props.letraBotaoPadrao} />
                                        <TextBotaoNovoTempoGasto colorDinamico={this.props.letraBotaoPadrao}>Tempo Gasto</TextBotaoNovoTempoGasto>
                                    </BotaoDesativado>
                                    <BotaoDesativado data-tip="Atribua um atendente, caso deseja executar alguma ação" data-for="tip-top" backgroundBotao="#9fadb7" hoverBotao={this.props.coresLetraPadrao}>
                                        <IconeVariosNovos className="icon-feitoweb" colorPrimario={this.props.letraBotaoPadrao} />
                                        <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Fechar</TextBotaoNovo>
                                    </BotaoDesativado>
                                    <BotaoDesativado data-tip="Atribua um atendente, caso deseja executar alguma ação" data-for="tip-top" backgroundBotao="#9fadb7" hoverBotao={this.props.coresLetraPadrao}>
                                        <IconeVariosNovos className="icon-prioridadeweb3" colorPrimario={this.props.letraBotaoPadrao} />
                                        <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Prioridade</TextBotaoNovo>
                                    </BotaoDesativado>
                                    <BotaoDesativado data-tip="Atribua um atendente, caso deseja executar alguma ação" data-for="tip-top" backgroundBotao="#9fadb7" hoverBotao={this.props.coresLetraPadrao}>
                                        <IconeVariosNovos className="icon-vencimento" colorPrimario={this.props.letraBotaoPadrao} />
                                        <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Vencimento</TextBotaoNovo>
                                    </BotaoDesativado>
                                    <BotaoDesativado data-tip="Atribua um atendente, caso deseja executar alguma ação" data-for="tip-top" backgroundBotao="#9fadb7" hoverBotao={this.props.coresLetraPadrao}>
                                        <IconeVariosNovos className="icon-sair" colorPrimario={this.props.letraBotaoPadrao} />
                                        <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Cancelado</TextBotaoNovo>
                                    </BotaoDesativado>
                                </ContainerBotoesAcoes>
                                <ContainerIconesLaterais>
                                    <ContainerIconeLateralDesativado data-tip="Atribua um atendente, caso deseja executar alguma ação" data-for="tip-top" backgroundBotao="#9fadb7" hoverBotao={this.props.coresLetraPadrao}>
                                        <i className="icon-editar" style={{ fontSize: 18, color: this.props.letraBotaoPadrao }} />
                                    </ContainerIconeLateralDesativado>
                                    <ContainerIconeLateralDesativado backgroundBotao="#9fadb7" hoverBotao={this.props.coresLetraPadrao}>
                                        <i className="icon-clip" data-tip="Atribua um atendente, caso deseja executar alguma ação" data-for="tip-top" style={{ fontSize: 18, color: this.props.letraBotaoPadrao }} />
                                    </ContainerIconeLateralDesativado>
                                    <ContainerIconeLateral onClick={() => {
                                        if (this.props.premium == 0) {
                                            toastr.error('Recurso Premium', 'Este é um recurso PREMIUM, para fazer upload de arquivos e imagens, Assine a versão PREMIUM e libere esse e mais recursos!');
                                        } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
                                            toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
                                        }
                                        else {
                                            this.props.exportaAtendimentoParaPDF(this.props.dadosAtendimentos[0])
                                        }
                                    }} backgroundBotao={this.props.corSegundoBotaoPadrao} hoverBotao={this.props.coresLetraPadrao}>
                                        <i className="icon-pdf2" data-tip="Relatório em PDF do atendimento" data-for="tip-top" style={{ fontSize: 18, color: this.props.letraBotaoPadrao }} />
                                    </ContainerIconeLateral>
                                </ContainerIconesLaterais>
                            </ContainerAcoes>
                            <ContainerComentario backGroundDinamico={this.props.backgroundMaisEscuro}>
                                <DivAdicionarComentarioDesativado data-tip="Atribua um atendente, caso deseja executar alguma ação" data-for="tip-top" backGroundDinamico={this.props.backgroundPrimeiro}>
                                    <TextAddComent corLetraDinamica={this.props.coresLetraSecundario}>Adicionar Comentario</TextAddComent>
                                </DivAdicionarComentarioDesativado>
                                <ContainerIconeRespostaChamadoFechado backgroundBotao='#9fadb7' hoverBotao={this.props.coresLetraPadrao}>
                                    <i className="icon-list-on-window" data-tip="Reabra caso deseja comentar alguma resposta padrão" data-for="tip-top" style={{ fontSize: 15, color: this.props.letraBotaoPadrao }} />
                                </ContainerIconeRespostaChamadoFechado>
                                {this.renderMicrophoneRecording()}
                                {/*    <ContainerIconeRespostaChamadoFechado backgroundBotao='#9fadb7' hoverBotao={this.props.coresLetraPadrao}>
                                    <i className="icon-microphone" data-tip="Reabra caso deseja enviar mensagem de audio" data-for="tip-top" style={{ fontSize: 15, color: this.props.letraBotaoPadrao, }} />
                                </ContainerIconeRespostaChamadoFechado> */}
                            </ContainerComentario>
                        </>
                    )

                }
            }
            //tem atendente
            else {

                if (this.props.dadosAtendimentos[0].status == 'Aberto' || this.props.dadosAtendimentos[0].status == 'Atendendo') {
                    return (
                        <>
                            <ContainerAcoes backGroundDinamico={this.props.backgroundMaisEscuro}>
                                <ContainerBotoesAcoes>
                                    <ButtonVariosNovos onClick={() => { this.props.modificaVisibilidadeModalAtribuirAtendimento(true) }} data-tip="Atribuir atendimento ao atendente" data-for="tip-top" backgroundBotao={this.props.corSegundoBotaoPadrao} hoverBotao={this.props.coresLetraPadrao}>
                                        <IconeVariosNovos className="icon-atender" colorPrimario={this.props.letraBotaoPadrao} />
                                        <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Atribuir</TextBotaoNovo>
                                    </ButtonVariosNovos>
                                    <ButtonVariosNovos onClick={() => { this.props.modificaVisibilidadeModalAddTempoGastoAtendimento(true) }} data-tip="Adicionar o tempo gasto no atendimento do chamado" data-for="tip-top" backgroundBotao={this.props.corSegundoBotaoPadrao} hoverBotao={this.props.coresLetraPadrao}>
                                        <IconeVariosNovos className="icon-esperawev" colorPrimario={this.props.letraBotaoPadrao} />
                                        <TextBotaoNovoTempoGasto colorDinamico={this.props.letraBotaoPadrao}>Tempo Gasto</TextBotaoNovoTempoGasto>
                                    </ButtonVariosNovos>
                                    <ButtonVariosNovos onClick={() => { this.props.modificaResolucaoProblema(''); this.props.modificaVisibilidadeModalFinalizarAtendimento(true) }} data-tip="Finalizar o atendimento" data-for="tip-top" backgroundBotao={this.props.corSegundoBotaoPadrao} hoverBotao={this.props.coresLetraPadrao}>
                                        <IconeVariosNovos className="icon-feitoweb" colorPrimario={this.props.letraBotaoPadrao} />
                                        <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Fechar</TextBotaoNovo>
                                    </ButtonVariosNovos>
                                    <ButtonVariosNovos onClick={() => { this.props.modificaVisibilidadeModalPrioridadeAtendimento(true) }} data-tip="Setar uma prioridade no atendimento" data-for="tip-top" backgroundBotao={this.props.corSegundoBotaoPadrao} hoverBotao={this.props.coresLetraPadrao}>
                                        <IconeVariosNovos className="icon-prioridadeweb3" colorPrimario={this.props.letraBotaoPadrao} />
                                        <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Prioridade</TextBotaoNovo>
                                    </ButtonVariosNovos>
                                    <DatePicker

                                        onChange={(date) => { this.handleDatePicked(date); this.setState({ dataVencimento: date }) }}
                                        locale="pt-BR"
                                        selected={this.state.dataVencimento}
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        timeCaption="Hora"
                                        value={this.state.dataVencimento}
                                        customInput={<ButtonVariosNovos onClick={() => { this.setState({ dataVencimento: '' }) }} data-tip="Inserir um vencimento ao atendimento" data-for="tip-top" backgroundBotao={this.props.corSegundoBotaoPadrao} hoverBotao={this.props.coresLetraPadrao}>
                                            <IconeVariosNovos className="icon-vencimento" colorPrimario={this.props.letraBotaoPadrao} />
                                            <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Vencimento</TextBotaoNovo>
                                        </ButtonVariosNovos>}
                                        formatWeekDay={d => {
                                            switch (d) {
                                                case 'segunda':
                                                    return 'Seg';
                                                case 'terça':
                                                    return 'Ter';
                                                case 'quarta':
                                                    return 'Qua';
                                                case 'quinta':
                                                    return 'Qui';
                                                case 'sexta':
                                                    return 'Sex';
                                                case 'sábado':
                                                    return 'Sab';
                                                case 'domingo':
                                                    return 'Dom';
                                                default:
                                                    return d;
                                            }
                                        }}
                                        timeIntervals={5}
                                        dateFormat="dd/MM/yyyy HH:mm"
                                        placeholderText="Data Final" />
                                    {this.props.permissoesAcessarTelasAtendente.permissaoCancelarAtendimento === true && <ButtonVariosNovos onClick={() => { this.props.modificaVisibilidadeModalCancelarAtendimento(true) }} data-tip="Cancelar o atendimento" data-for="tip-top" backgroundBotao={this.props.corSegundoBotaoPadrao} hoverBotao={this.props.coresLetraPadrao}>
                                        <IconeVariosNovos className="icon-sair" colorPrimario={this.props.letraBotaoPadrao} />
                                        <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Cancelar</TextBotaoNovo>
                                    </ButtonVariosNovos>}
                                </ContainerBotoesAcoes>
                                <ContainerIconesLaterais>
                                    <ContainerIconeLateral onClick={() => { this.abreModalAlterarChamado(); }} data-tip="Alterar informações do atendimento" data-for="tip-top" backgroundBotao={this.props.corSegundoBotaoPadrao} hoverBotao={this.props.coresLetraPadrao}>
                                        <i className="icon-editar" style={{ fontSize: 18, color: this.props.letraBotaoPadrao }} />
                                    </ContainerIconeLateral>
                                    <ContainerIconeLateral backgroundBotao={this.props.corSegundoBotaoPadrao} hoverBotao={this.props.coresLetraPadrao}>
                                        <Dropzone
                                            multiple={true}
                                            noKeyboard
                                            noDrag
                                            maxSize={2e+7}
                                            /*  onDropRejected={() => {
                                                 toastr.error('Error', 'Adicione arquivos de no máximo 20MB')
                                             }} */
                                            onDrop={acceptedFiles => this.escolheArquivoChamado(acceptedFiles)}>
                                            {({ getRootProps, getInputProps, isDragActive }) => {
                                                //

                                                return (
                                                    /*     <section> */
                                                    <div  {...getRootProps()}>
                                                        <input {...getInputProps()} />
                                                        {/*  {
                                                    isDragActive ?
                                                        <p>Drop the files here ...</p> :
                                                        <p>Drag 'n' drop some files here, or click to select files</p>
                                                } */}
                                                        <i className="icon-clip" data-tip="Fazer upload de arquivos e imagens" data-for="tip-top" style={{ fontSize: 18, color: this.props.letraBotaoPadrao }} />
                                                    </div>
                                                    /*   </section> */
                                                )
                                            }}
                                        </Dropzone>
                                    </ContainerIconeLateral>
                                    <ContainerIconeLateral onClick={() => {
                                        if (this.props.premium == 0) {
                                            toastr.error('Recurso Premium', 'Este é um recurso PREMIUM, para fazer upload de arquivos e imagens, Assine a versão PREMIUM e libere esse e mais recursos!');
                                        } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
                                            toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
                                        }
                                        else {
                                            this.props.exportaAtendimentoParaPDF(this.props.dadosAtendimentos[0])
                                        }
                                    }} backgroundBotao={this.props.corSegundoBotaoPadrao} hoverBotao={this.props.coresLetraPadrao}>
                                        <i className="icon-pdf2" data-tip="Relatório em PDF do atendimento" data-for="tip-top" style={{ fontSize: 18, color: this.props.letraBotaoPadrao }} />
                                    </ContainerIconeLateral>
                                </ContainerIconesLaterais>
                            </ContainerAcoes>
                            <ContainerComentario backGroundDinamico={this.props.backgroundMaisEscuro}>
                                <DivAdicionarComentario onClick={() => { this.props.modificavisibilidadeModalComentario(true) }} borderColorDinamico={this.props.corBotaoPadrao} data-tip="Adicionar um comentário nas conversas do atendimento" data-for="tip-top" backGroundDinamico={this.props.backgroundPrimeiro}>
                                    <TextAddComent corLetraDinamica={this.props.coresLetraSecundario}>Adicionar Comentario</TextAddComent>
                                </DivAdicionarComentario>
                                <ContainerIconeResposta onClick={() => {
                                    if (this.props.premium == 0) {
                                        toastr.error('Recurso Premium', 'Este é um recurso PREMIUM, para fazer upload de arquivos e imagens, Assine a versão PREMIUM e libere esse e mais recursos!');
                                    } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
                                        toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
                                    }
                                    else {
                                        this._toggleModalRespostaPadrao();
                                    }
                                }} backgroundBotao={this.props.corSegundoBotaoPadrao} hoverBotao={this.props.coresLetraPadrao}>
                                    <i className="icon-list-on-window" data-tip="Respostas Padrões" data-for="tip-top" style={{ fontSize: 15, color: this.props.letraBotaoPadrao }} />
                                </ContainerIconeResposta>
                                {this.renderMicrophoneRecording()}

                            </ContainerComentario>
                        </>
                    )
                } else if (this.props.dadosAtendimentos[0].status == 'Fechado') {
                    return (
                        <>
                            <ContainerAcoes backGroundDinamico={this.props.backgroundMaisEscuro}>
                                <ContainerBotoesAcoes>
                                    <BotaoDesativado data-tip="Atendimento finalizado, Reabra caso deseja executar alguma ação" data-for="tip-top" backgroundBotao="#9fadb7">
                                        <IconeVariosNovos className="icon-atender" colorPrimario={this.props.letraBotaoPadrao} />
                                        <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Atribuir</TextBotaoNovo>
                                    </BotaoDesativado>
                                    <BotaoDesativado data-tip="Atendimento finalizado, Reabra caso deseja executar alguma ação" data-for="tip-top" backgroundBotao="#9fadb7" hoverBotao={this.props.coresLetraPadrao}>
                                        <IconeVariosNovos className="icon-esperawev" colorPrimario={this.props.letraBotaoPadrao} />
                                        <TextBotaoNovoTempoGasto colorDinamico={this.props.letraBotaoPadrao}>Tempo Gasto</TextBotaoNovoTempoGasto>
                                    </BotaoDesativado>
                                    <ButtonVariosNovos data-tip="Reabrir atendimento" onClick={() => { this._toggleModalReabrirChamado() }} data-for="tip-top" backgroundBotao="#00FF7F" hoverBotao={this.props.coresLetraPadrao}>
                                        <IconeVariosNovos className="icon-feitoweb" colorPrimario={this.props.letraBotaoPadrao} />
                                        <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Fechado</TextBotaoNovo>
                                    </ButtonVariosNovos>
                                    <BotaoDesativado data-tip="Atendimento finalizado, Reabra caso deseja executar alguma ação" data-for="tip-top" backgroundBotao="#9fadb7" hoverBotao={this.props.coresLetraPadrao}>
                                        <IconeVariosNovos className="icon-prioridadeweb3" colorPrimario={this.props.letraBotaoPadrao} />
                                        <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Prioridade</TextBotaoNovo>
                                    </BotaoDesativado>
                                    <BotaoDesativado data-tip="Atendimento finalizado, Reabra caso deseja executar alguma ação" data-for="tip-top" backgroundBotao="#9fadb7" hoverBotao={this.props.coresLetraPadrao}>
                                        <IconeVariosNovos className="icon-vencimento" colorPrimario={this.props.letraBotaoPadrao} />
                                        <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Vencimento</TextBotaoNovo>
                                    </BotaoDesativado>
                                    {this.props.permissoesAcessarTelasAtendente.permissaoCancelarAtendimento === true && <BotaoDesativado data-tip="Atendimento finalizado, Reabra caso deseja executar alguma ação" data-for="tip-top" backgroundBotao="#9fadb7" hoverBotao={this.props.coresLetraPadrao}>
                                        <IconeVariosNovos className="icon-sair" colorPrimario={this.props.letraBotaoPadrao} />
                                        <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Cancelar</TextBotaoNovo>
                                    </BotaoDesativado>}
                                </ContainerBotoesAcoes>
                                <ContainerIconesLaterais>
                                    <ContainerIconeLateralDesativado data-tip="Atendimento finalizado, Reabra caso deseja executar alguma ação" data-for="tip-top" backgroundBotao="#9fadb7" hoverBotao={this.props.coresLetraPadrao}>
                                        <i className="icon-editar" style={{ fontSize: 18, color: this.props.letraBotaoPadrao }} />
                                    </ContainerIconeLateralDesativado>
                                    <ContainerIconeLateralDesativado backgroundBotao="#9fadb7" hoverBotao={this.props.coresLetraPadrao}>
                                        <i className="icon-clip" data-tip="Atendimento finalizado, Reabra caso deseja executar alguma ação" data-for="tip-top" style={{ fontSize: 18, color: this.props.letraBotaoPadrao }} />
                                    </ContainerIconeLateralDesativado>
                                    <ContainerIconeLateral onClick={() => {
                                        if (this.props.premium == 0) {
                                            toastr.error('Recurso Premium', 'Este é um recurso PREMIUM, para fazer upload de arquivos e imagens, Assine a versão PREMIUM e libere esse e mais recursos!');
                                        } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
                                            toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
                                        }
                                        else {
                                            this.props.exportaAtendimentoParaPDF(this.props.dadosAtendimentos[0])
                                        }
                                    }} backgroundBotao={this.props.corSegundoBotaoPadrao} hoverBotao={this.props.coresLetraPadrao}>
                                        <i className="icon-pdf2" data-tip="Relatório em PDF do atendimento" data-for="tip-top" style={{ fontSize: 18, color: this.props.letraBotaoPadrao }} />
                                    </ContainerIconeLateral>
                                </ContainerIconesLaterais>
                            </ContainerAcoes>
                            <ContainerComentario backGroundDinamico={this.props.backgroundMaisEscuro}>
                                <DivAdicionarComentarioDesativado data-tip="Atendimento finalizado, Reabra caso deseja executar alguma ação" data-for="tip-top" backGroundDinamico={this.props.backgroundPrimeiro}>
                                    <TextAddComent corLetraDinamica={this.props.coresLetraSecundario}>Adicionar Comentario</TextAddComent>
                                </DivAdicionarComentarioDesativado>
                                <ContainerIconeRespostaChamadoFechado backgroundBotao='#9fadb7' hoverBotao={this.props.coresLetraPadrao}>
                                    <i className="icon-list-on-window" data-tip="Reabra caso deseja comentar alguma resposta padrão" data-for="tip-top" style={{ fontSize: 15, color: this.props.letraBotaoPadrao }} />
                                </ContainerIconeRespostaChamadoFechado>
                                {this.renderMicrophoneRecording()}
                                {/*  <ContainerIconeRespostaChamadoFechado backgroundBotao='#9fadb7' hoverBotao={this.props.coresLetraPadrao}>
                                    <i className="icon-microphone" data-tip="Reabra caso deseja enviar mensagem de audio" data-for="tip-top" style={{ fontSize: 15, color: this.props.letraBotaoPadrao, }} />
                                </ContainerIconeRespostaChamadoFechado> */}
                            </ContainerComentario>
                        </>
                    )
                }
                else {
                    //cancelado
                    return (
                        <>
                            <ContainerAcoes backGroundDinamico={this.props.backgroundMaisEscuro}>
                                <ContainerBotoesAcoes>
                                    <BotaoDesativado data-tip="Atendimento Cancelado, Reabra caso deseja executar alguma ação" data-for="tip-top" backgroundBotao="#9fadb7">
                                        <IconeVariosNovos className="icon-atender" colorPrimario={this.props.letraBotaoPadrao} />
                                        <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Atribuir</TextBotaoNovo>
                                    </BotaoDesativado>
                                    <BotaoDesativado data-tip="Atendimento Cancelado, Reabra caso deseja executar alguma ação" data-for="tip-top" backgroundBotao="#9fadb7" hoverBotao={this.props.coresLetraPadrao}>
                                        <IconeVariosNovos className="icon-esperawev" colorPrimario={this.props.letraBotaoPadrao} />
                                        <TextBotaoNovoTempoGasto colorDinamico={this.props.letraBotaoPadrao}>Tempo Gasto</TextBotaoNovoTempoGasto>
                                    </BotaoDesativado>
                                    <BotaoDesativado data-tip="Atendimento Cancelado, Reabra caso deseja executar alguma ação" data-for="tip-top" backgroundBotao="#9fadb7" hoverBotao={this.props.coresLetraPadrao}>
                                        <IconeVariosNovos className="icon-feitoweb" colorPrimario={this.props.letraBotaoPadrao} />
                                        <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Fechar</TextBotaoNovo>
                                    </BotaoDesativado>
                                    <BotaoDesativado data-tip="Atendimento Cancelado, Reabra caso deseja executar alguma ação" data-for="tip-top" backgroundBotao="#9fadb7" hoverBotao={this.props.coresLetraPadrao}>
                                        <IconeVariosNovos className="icon-prioridadeweb3" colorPrimario={this.props.letraBotaoPadrao} />
                                        <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Prioridade</TextBotaoNovo>
                                    </BotaoDesativado>
                                    <BotaoDesativado data-tip="Atendimento Cancelado, Reabra caso deseja executar alguma ação" data-for="tip-top" backgroundBotao="#9fadb7" hoverBotao={this.props.coresLetraPadrao}>
                                        <IconeVariosNovos className="icon-vencimento" colorPrimario={this.props.letraBotaoPadrao} />
                                        <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Vencimento</TextBotaoNovo>
                                    </BotaoDesativado>
                                    <ButtonVariosNovos data-tip="Reabrir atendimento" onClick={() => { this._toggleModalReabrirChamado() }} data-for="tip-top" backgroundBotao="#f70e4c" hoverBotao={this.props.coresLetraPadrao}>
                                        <IconeVariosNovos className="icon-sair" colorPrimario={this.props.letraBotaoPadrao} />
                                        <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Cancelado</TextBotaoNovo>
                                    </ButtonVariosNovos>
                                </ContainerBotoesAcoes>
                                <ContainerIconesLaterais>
                                    <ContainerIconeLateralDesativado data-tip="Atendimento Cancelado, Reabra caso deseja executar alguma ação" data-for="tip-top" backgroundBotao="#9fadb7" hoverBotao={this.props.coresLetraPadrao}>
                                        <i className="icon-editar" style={{ fontSize: 18, color: this.props.letraBotaoPadrao }} />
                                    </ContainerIconeLateralDesativado>
                                    <ContainerIconeLateralDesativado backgroundBotao="#9fadb7" hoverBotao={this.props.coresLetraPadrao}>
                                        <i className="icon-clip" data-tip="Atendimento Cancelado, Reabra caso deseja executar alguma ação" data-for="tip-top" style={{ fontSize: 18, color: this.props.letraBotaoPadrao }} />
                                    </ContainerIconeLateralDesativado>
                                    <ContainerIconeLateral onClick={() => {
                                        if (this.props.premium == 0) {
                                            toastr.error('Recurso Premium', 'Este é um recurso PREMIUM, para fazer upload de arquivos e imagens, Assine a versão PREMIUM e libere esse e mais recursos!');
                                        } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
                                            toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
                                        }
                                        else {
                                            this.props.exportaAtendimentoParaPDF(this.props.dadosAtendimentos[0])
                                        }
                                    }} backgroundBotao={this.props.corSegundoBotaoPadrao} hoverBotao={this.props.coresLetraPadrao}>
                                        <i className="icon-pdf2" data-tip="Relatório em PDF do atendimento" data-for="tip-top" style={{ fontSize: 18, color: this.props.letraBotaoPadrao }} />
                                    </ContainerIconeLateral>
                                </ContainerIconesLaterais>
                            </ContainerAcoes>
                            <ContainerComentario backGroundDinamico={this.props.backgroundMaisEscuro}>
                                <DivAdicionarComentarioDesativado data-tip="Atendimento Cancelado, Reabra caso deseja executar alguma ação" data-for="tip-top" backGroundDinamico={this.props.backgroundPrimeiro}>
                                    <TextAddComent corLetraDinamica={this.props.coresLetraSecundario}>Adicionar Comentario</TextAddComent>
                                </DivAdicionarComentarioDesativado>
                                <ContainerIconeRespostaChamadoFechado backgroundBotao='#9fadb7' hoverBotao={this.props.coresLetraPadrao}>
                                    <i className="icon-list-on-window" data-tip="Reabra caso deseja comentar alguma resposta padrão" data-for="tip-top" style={{ fontSize: 15, color: this.props.letraBotaoPadrao }} />
                                </ContainerIconeRespostaChamadoFechado>
                                {this.renderMicrophoneRecording()}
                                {/* <ContainerIconeRespostaChamadoFechado backgroundBotao='#9fadb7' hoverBotao={this.props.coresLetraPadrao}>
                                    <i className="icon-microphone" data-tip="Reabra caso deseja enviar mensagem de audio" data-for="tip-top" style={{ fontSize: 15, color: this.props.letraBotaoPadrao, }} />
                                </ContainerIconeRespostaChamadoFechado> */}
                            </ContainerComentario>
                        </>
                    )
                }
            }

            /* } */
        } else {
            //nao é obrigatorio
          /*   if (this.props.dadosAtendimentos[0].channelChamado.includes('Chat') && this.props.isAdmin === false) {
                //cancelado
                return (
                    <>
                        <ContainerAcoes backGroundDinamico={this.props.backgroundMaisEscuro}>
                            <ContainerBotoesAcoes>
                                <BotaoDesativado data-tip="Atendimento Em Andamento Via Atendimentos Online, Somente após finalizar para executar alguma ação" data-for="tip-top" backgroundBotao="#9fadb7">
                                    <IconeVariosNovos className="icon-atender" colorPrimario={this.props.letraBotaoPadrao} />
                                    <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Atribuir</TextBotaoNovo>
                                </BotaoDesativado>
                                <BotaoDesativado data-tip="Atendimento Em Andamento Via Atendimentos Online, Somente após finalizar para executar alguma ação" data-for="tip-top" backgroundBotao="#9fadb7" hoverBotao={this.props.coresLetraPadrao}>
                                    <IconeVariosNovos className="icon-esperawev" colorPrimario={this.props.letraBotaoPadrao} />
                                    <TextBotaoNovoTempoGasto colorDinamico={this.props.letraBotaoPadrao}>Tempo Gasto</TextBotaoNovoTempoGasto>
                                </BotaoDesativado>
                                <BotaoDesativado data-tip="Atendimento Em Andamento Via Atendimentos Online, Somente após finalizar para executar alguma ação" data-for="tip-top" backgroundBotao="#9fadb7" hoverBotao={this.props.coresLetraPadrao}>
                                    <IconeVariosNovos className="icon-feitoweb" colorPrimario={this.props.letraBotaoPadrao} />
                                    <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Fechar</TextBotaoNovo>
                                </BotaoDesativado>
                                <BotaoDesativado data-tip="Atendimento Em Andamento Via Atendimentos Online, Somente após finalizar para executar alguma ação" data-for="tip-top" backgroundBotao="#9fadb7" hoverBotao={this.props.coresLetraPadrao}>
                                    <IconeVariosNovos className="icon-prioridadeweb3" colorPrimario={this.props.letraBotaoPadrao} />
                                    <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Prioridade</TextBotaoNovo>
                                </BotaoDesativado>
                                <BotaoDesativado data-tip="Atendimento Em Andamento Via Atendimentos Online, Somente após finalizar para executar alguma ação" data-for="tip-top" backgroundBotao="#9fadb7" hoverBotao={this.props.coresLetraPadrao}>
                                    <IconeVariosNovos className="icon-vencimento" colorPrimario={this.props.letraBotaoPadrao} />
                                    <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Vencimento</TextBotaoNovo>
                                </BotaoDesativado>
                                <ButtonVariosNovos onClick={() => { this._toggleModalReabrirChamado() }} data-tip="Reabrir atendimento" data-for="tip-top" backgroundBotao="#9fadb7" hoverBotao={this.props.coresLetraPadrao}>
                                    <IconeVariosNovos className="icon-sair" colorPrimario={this.props.letraBotaoPadrao} />
                                    <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Cancelado</TextBotaoNovo>
                                </ButtonVariosNovos>
                            </ContainerBotoesAcoes>
                            <ContainerIconesLaterais>
                                <ContainerIconeLateralDesativado data-tip="Atendimento Em Andamento Via Atendimentos Online, Somente após finalizar para executar alguma ação" data-for="tip-top" backgroundBotao="#9fadb7" hoverBotao={this.props.coresLetraPadrao}>
                                    <i className="icon-editar" style={{ fontSize: 18, color: this.props.letraBotaoPadrao }} />
                                </ContainerIconeLateralDesativado>
                                <ContainerIconeLateralDesativado backgroundBotao="#9fadb7" hoverBotao={this.props.coresLetraPadrao}>
                                    <i className="icon-clip" data-tip="Atendimento Em Andamento Via Atendimentos Online, Somente após finalizar para executar alguma ação" data-for="tip-top" style={{ fontSize: 18, color: this.props.letraBotaoPadrao }} />
                                </ContainerIconeLateralDesativado>
                                <ContainerIconeLateral onClick={() => {
                                    if (this.props.premium == 0) {
                                        toastr.error('Recurso Premium', 'Este é um recurso PREMIUM, para fazer upload de arquivos e imagens, Assine a versão PREMIUM e libere esse e mais recursos!');
                                    } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
                                        toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
                                    }
                                    else {
                                        this.props.exportaAtendimentoParaPDF(this.props.dadosAtendimentos[0])
                                    }
                                }} backgroundBotao={this.props.corSegundoBotaoPadrao} hoverBotao={this.props.coresLetraPadrao}>
                                    <i className="icon-pdf2" data-tip="Relatório em PDF do atendimento" data-for="tip-top" style={{ fontSize: 18, color: this.props.letraBotaoPadrao }} />
                                </ContainerIconeLateral>
                            </ContainerIconesLaterais>
                        </ContainerAcoes>
                        <ContainerComentario backGroundDinamico={this.props.backgroundMaisEscuro}>
                            <DivAdicionarComentarioDesativado data-tip="Atendimento Em Andamento Via Atendimentos Online, Somente após finalizar para executar alguma ação" data-for="tip-top" backGroundDinamico={this.props.backgroundPrimeiro}>
                                <TextAddComent corLetraDinamica={this.props.coresLetraSecundario}>Adicionar Comentario</TextAddComent>
                            </DivAdicionarComentarioDesativado>
                            <ContainerIconeRespostaChamadoFechado backgroundBotao='#9fadb7' hoverBotao={this.props.coresLetraPadrao}>
                                <i className="icon-list-on-window" data-tip="Atendimento Em Andamento Via Atendimentos Online, Somente após finalizar para executar alguma ação" data-for="tip-top" style={{ fontSize: 15, color: this.props.letraBotaoPadrao }} />
                            </ContainerIconeRespostaChamadoFechado>
                            <ContainerIconeRespostaChamadoFechado backgroundBotao='#9fadb7' hoverBotao={this.props.coresLetraPadrao}>
                                <i className="icon-microphone" data-tip="Atendimento Em Andamento Via Atendimentos Online, Somente após finalizar para executar alguma ação" data-for="tip-top" style={{ fontSize: 15, color: this.props.letraBotaoPadrao, }} />
                            </ContainerIconeRespostaChamadoFechado>
                        </ContainerComentario>
                    </>
                )
            }
            else */ if (this.props.dadosAtendimentos[0].status == 'Aberto' || this.props.dadosAtendimentos[0].status == 'Atendendo') {
                return (
                    <>
                        <ContainerAcoes backGroundDinamico={this.props.backgroundMaisEscuro}>
                            <ContainerBotoesAcoes>
                                <ButtonVariosNovos onClick={() => { this.props.modificaVisibilidadeModalAtribuirAtendimento(true) }} data-tip="Atribuir atendimento ao atendente" data-for="tip-top" backgroundBotao={this.props.corSegundoBotaoPadrao} hoverBotao={this.props.coresLetraPadrao}>
                                    <IconeVariosNovos className="icon-atender" colorPrimario={this.props.letraBotaoPadrao} />
                                    <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Atribuir</TextBotaoNovo>
                                </ButtonVariosNovos>
                                <ButtonVariosNovos onClick={() => { this.props.modificaVisibilidadeModalAddTempoGastoAtendimento(true) }} data-tip="Adicionar o tempo gasto no atendimento do chamado" data-for="tip-top" backgroundBotao={this.props.corSegundoBotaoPadrao} hoverBotao={this.props.coresLetraPadrao}>
                                    <IconeVariosNovos className="icon-esperawev" colorPrimario={this.props.letraBotaoPadrao} />
                                    <TextBotaoNovoTempoGasto colorDinamico={this.props.letraBotaoPadrao}>Tempo Gasto</TextBotaoNovoTempoGasto>
                                </ButtonVariosNovos>
                                <ButtonVariosNovos onClick={() => { this.props.modificaResolucaoProblema(''); this.props.modificaVisibilidadeModalFinalizarAtendimento(true) }} data-tip="Finalizar o atendimento" data-for="tip-top" backgroundBotao={this.props.corSegundoBotaoPadrao} hoverBotao={this.props.coresLetraPadrao}>
                                    <IconeVariosNovos className="icon-feitoweb" colorPrimario={this.props.letraBotaoPadrao} />
                                    <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Fechar</TextBotaoNovo>
                                </ButtonVariosNovos>
                                <ButtonVariosNovos onClick={() => { this.props.modificaVisibilidadeModalPrioridadeAtendimento(true) }} data-tip="Setar uma prioridade no atendimento" data-for="tip-top" backgroundBotao={this.props.corSegundoBotaoPadrao} hoverBotao={this.props.coresLetraPadrao}>
                                    <IconeVariosNovos className="icon-prioridadeweb3" colorPrimario={this.props.letraBotaoPadrao} />
                                    <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Prioridade</TextBotaoNovo>
                                </ButtonVariosNovos>
                                <DatePicker

                                    onChange={(date) => { this.handleDatePicked(date); this.setState({ dataVencimento: date }) }}
                                    locale="pt-BR"
                                    selected={this.state.dataVencimento}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeCaption="Hora"
                                    value={this.state.dataVencimento}
                                    customInput={<ButtonVariosNovos onClick={() => { this.setState({ dataVencimento: '' }) }} data-tip="Inserir um vencimento ao atendimento" data-for="tip-top" backgroundBotao={this.props.corSegundoBotaoPadrao} hoverBotao={this.props.coresLetraPadrao}>
                                        <IconeVariosNovos className="icon-vencimento" colorPrimario={this.props.letraBotaoPadrao} />
                                        <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Vencimento</TextBotaoNovo>
                                    </ButtonVariosNovos>}
                                    formatWeekDay={d => {
                                        switch (d) {
                                            case 'segunda':
                                                return 'Seg';
                                            case 'terça':
                                                return 'Ter';
                                            case 'quarta':
                                                return 'Qua';
                                            case 'quinta':
                                                return 'Qui';
                                            case 'sexta':
                                                return 'Sex';
                                            case 'sábado':
                                                return 'Sab';
                                            case 'domingo':
                                                return 'Dom';
                                            default:
                                                return d;
                                        }
                                    }}
                                    timeIntervals={5}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="Vencimento" />
                                {this.props.permissoesAcessarTelasAtendente.permissaoCancelarAtendimento === true && <ButtonVariosNovos onClick={() => { this.props.modificaVisibilidadeModalCancelarAtendimento(true) }} data-tip="Cancelar o atendimento" data-for="tip-top" backgroundBotao={this.props.corSegundoBotaoPadrao} hoverBotao={this.props.coresLetraPadrao}>
                                    <IconeVariosNovos className="icon-sair" colorPrimario={this.props.letraBotaoPadrao} />
                                    <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Cancelar</TextBotaoNovo>
                                </ButtonVariosNovos>}
                            </ContainerBotoesAcoes>
                            <ContainerIconesLaterais>
                                <ContainerIconeLateral onClick={() => { this.abreModalAlterarChamado() }} data-tip="Alterar informações do atendimento" data-for="tip-top" backgroundBotao={this.props.corSegundoBotaoPadrao} hoverBotao={this.props.coresLetraPadrao}>
                                    <i className="icon-editar" style={{ fontSize: 18, color: this.props.letraBotaoPadrao }} />
                                </ContainerIconeLateral>
                                <ContainerIconeLateral backgroundBotao={this.props.corSegundoBotaoPadrao} hoverBotao={this.props.coresLetraPadrao}>
                                    <Dropzone
                                        multiple={true}
                                        noKeyboard
                                        noDrag
                                        maxSize={2e+7}
                                        /*  onDropRejected={() => {
                                             toastr.error('Error', 'Adicione arquivos de no máximo 20MB')
                                         }} */
                                        onDrop={acceptedFiles => this.escolheArquivoChamado(acceptedFiles)}>
                                        {({ getRootProps, getInputProps, isDragActive }) => {
                                            //

                                            return (
                                                /*     <section> */
                                                <div  {...getRootProps()}>
                                                    <input {...getInputProps()} />
                                                    {/*  {
                                                    isDragActive ?
                                                        <p>Drop the files here ...</p> :
                                                        <p>Drag 'n' drop some files here, or click to select files</p>
                                                } */}
                                                    <i className="icon-clip" data-tip="Fazer upload de arquivos e imagens" data-for="tip-top" style={{ fontSize: 18, color: this.props.letraBotaoPadrao }} />
                                                </div>
                                                /*   </section> */
                                            )
                                        }}
                                    </Dropzone>
                                </ContainerIconeLateral>
                                <ContainerIconeLateral onClick={() => {
                                    if (this.props.premium == 0) {
                                        toastr.error('Recurso Premium', 'Este é um recurso PREMIUM, para fazer upload de arquivos e imagens, Assine a versão PREMIUM e libere esse e mais recursos!');
                                    } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
                                        toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
                                    }
                                    else {
                                        this.props.exportaAtendimentoParaPDF(this.props.dadosAtendimentos[0])
                                    }
                                }} backgroundBotao={this.props.corSegundoBotaoPadrao} hoverBotao={this.props.coresLetraPadrao}>
                                    <i className="icon-pdf2" data-tip="Relatório em PDF do atendimento" data-for="tip-top" style={{ fontSize: 18, color: this.props.letraBotaoPadrao }} />
                                </ContainerIconeLateral>
                            </ContainerIconesLaterais>
                        </ContainerAcoes>
                        <ContainerComentario backGroundDinamico={this.props.backgroundMaisEscuro}>
                            <DivAdicionarComentario onClick={() => { this.props.modificavisibilidadeModalComentario(true) }} borderColorDinamico={this.props.corBotaoPadrao} data-tip="Adicionar um comentário nas conversas do atendimento" data-for="tip-top" backGroundDinamico={this.props.backgroundPrimeiro}>
                                <TextAddComent corLetraDinamica={this.props.coresLetraSecundario}>Adicionar Comentario</TextAddComent>
                            </DivAdicionarComentario>
                            <ContainerIconeResposta onClick={() => {
                                if (this.props.premium == 0) {
                                    toastr.error('Recurso Premium', 'Este é um recurso PREMIUM, para fazer upload de arquivos e imagens, Assine a versão PREMIUM e libere esse e mais recursos!');
                                } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
                                    toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
                                }
                                else {
                                    this._toggleModalRespostaPadrao();
                                }
                            }} backgroundBotao={this.props.corSegundoBotaoPadrao} hoverBotao={this.props.coresLetraPadrao}>
                                <i className="icon-list-on-window" data-tip="Respostas Padrões" data-for="tip-top" style={{ fontSize: 15, color: this.props.letraBotaoPadrao }} />
                            </ContainerIconeResposta>
                            {this.renderMicrophoneRecording()}
                            {/* <ContainerIconeResposta onClick={() => { this.setState({ viewMicrophone: true }) }} backgroundBotao={this.props.corSegundoBotaoPadrao} hoverBotao={this.props.coresLetraPadrao}>
                                <i className="icon-microphone" data-tip="Enviar mensagem de áudio" data-for="tip-top" style={{ fontSize: 15, color: this.props.letraBotaoPadrao, }} />
                            </ContainerIconeResposta> */}
                        </ContainerComentario>
                    </>
                )
            } else if (this.props.dadosAtendimentos[0].status == 'Fechado') {
                return (
                    <>
                        <ContainerAcoes backGroundDinamico={this.props.backgroundMaisEscuro}>
                            <ContainerBotoesAcoes>
                                <BotaoDesativado data-tip="Atendimento finalizado, Reabra caso deseja executar alguma ação" data-for="tip-top" backgroundBotao="#9fadb7">
                                    <IconeVariosNovos className="icon-atender" colorPrimario={this.props.letraBotaoPadrao} />
                                    <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Atribuir</TextBotaoNovo>
                                </BotaoDesativado>
                                <BotaoDesativado data-tip="Atendimento finalizado, Reabra caso deseja executar alguma ação" data-for="tip-top" backgroundBotao="#9fadb7" hoverBotao={this.props.coresLetraPadrao}>
                                    <IconeVariosNovos className="icon-esperawev" colorPrimario={this.props.letraBotaoPadrao} />
                                    <TextBotaoNovoTempoGasto colorDinamico={this.props.letraBotaoPadrao}>Tempo Gasto</TextBotaoNovoTempoGasto>
                                </BotaoDesativado>
                                <ButtonVariosNovos data-tip="Reabrir atendimento" onClick={() => { this._toggleModalReabrirChamado() }} data-for="tip-top" backgroundBotao="#00FF7F" hoverBotao={this.props.coresLetraPadrao}>
                                    <IconeVariosNovos className="icon-feitoweb" colorPrimario={this.props.letraBotaoPadrao} />
                                    <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Fechado</TextBotaoNovo>
                                </ButtonVariosNovos>
                                <BotaoDesativado data-tip="Atendimento finalizado, Reabra caso deseja executar alguma ação" data-for="tip-top" backgroundBotao="#9fadb7" hoverBotao={this.props.coresLetraPadrao}>
                                    <IconeVariosNovos className="icon-prioridadeweb3" colorPrimario={this.props.letraBotaoPadrao} />
                                    <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Prioridade</TextBotaoNovo>
                                </BotaoDesativado>
                                <BotaoDesativado data-tip="Atendimento finalizado, Reabra caso deseja executar alguma ação" data-for="tip-top" backgroundBotao="#9fadb7" hoverBotao={this.props.coresLetraPadrao}>
                                    <IconeVariosNovos className="icon-vencimento" colorPrimario={this.props.letraBotaoPadrao} />
                                    <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Vencimento</TextBotaoNovo>
                                </BotaoDesativado>
                                {this.props.permissoesAcessarTelasAtendente.permissaoCancelarAtendimento === true && <BotaoDesativado data-tip="Atendimento finalizado, Reabra caso deseja executar alguma ação" data-for="tip-top" backgroundBotao="#9fadb7" hoverBotao={this.props.coresLetraPadrao}>
                                    <IconeVariosNovos className="icon-sair" colorPrimario={this.props.letraBotaoPadrao} />
                                    <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Cancelar</TextBotaoNovo>
                                </BotaoDesativado>}
                            </ContainerBotoesAcoes>
                            <ContainerIconesLaterais>
                                <ContainerIconeLateralDesativado data-tip="Atendimento finalizado, Reabra caso deseja executar alguma ação" data-for="tip-top" backgroundBotao="#9fadb7" hoverBotao={this.props.coresLetraPadrao}>
                                    <i className="icon-editar" style={{ fontSize: 18, color: this.props.letraBotaoPadrao }} />
                                </ContainerIconeLateralDesativado>
                                <ContainerIconeLateralDesativado backgroundBotao="#9fadb7" hoverBotao={this.props.coresLetraPadrao}>
                                    <i className="icon-clip" data-tip="Atendimento finalizado, Reabra caso deseja executar alguma ação" data-for="tip-top" style={{ fontSize: 18, color: this.props.letraBotaoPadrao }} />
                                </ContainerIconeLateralDesativado>
                                <ContainerIconeLateral onClick={() => {
                                    if (this.props.premium == 0) {
                                        toastr.error('Recurso Premium', 'Este é um recurso PREMIUM, para fazer upload de arquivos e imagens, Assine a versão PREMIUM e libere esse e mais recursos!');
                                    } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
                                        toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
                                    }
                                    else {
                                        this.props.exportaAtendimentoParaPDF(this.props.dadosAtendimentos[0])
                                    }
                                }} backgroundBotao={this.props.corSegundoBotaoPadrao} hoverBotao={this.props.coresLetraPadrao}>
                                    <i className="icon-pdf2" data-tip="Relatório em PDF do atendimento" data-for="tip-top" style={{ fontSize: 18, color: this.props.letraBotaoPadrao }} />
                                </ContainerIconeLateral>
                            </ContainerIconesLaterais>
                        </ContainerAcoes>
                        <ContainerComentario backGroundDinamico={this.props.backgroundMaisEscuro}>
                            <DivAdicionarComentarioDesativado data-tip="Atendimento finalizado, Reabra caso deseja executar alguma ação" data-for="tip-top" backGroundDinamico={this.props.backgroundPrimeiro}>
                                <TextAddComent corLetraDinamica={this.props.coresLetraSecundario}>Adicionar Comentario</TextAddComent>
                            </DivAdicionarComentarioDesativado>
                            <ContainerIconeRespostaChamadoFechado backgroundBotao='#9fadb7' hoverBotao={this.props.coresLetraPadrao}>
                                <i className="icon-list-on-window" data-tip="Reabra caso deseja comentar alguma resposta padrão" data-for="tip-top" style={{ fontSize: 15, color: this.props.letraBotaoPadrao }} />
                            </ContainerIconeRespostaChamadoFechado>
                            {this.renderMicrophoneRecording()}
                            {/*  <ContainerIconeRespostaChamadoFechado backgroundBotao='#9fadb7' hoverBotao={this.props.coresLetraPadrao}>
                                <i className="icon-microphone" data-tip="Reabra caso deseja enviar mensagem de audio" data-for="tip-top" style={{ fontSize: 15, color: this.props.letraBotaoPadrao }} />
                            </ContainerIconeRespostaChamadoFechado> */}
                        </ContainerComentario>
                    </>
                )
            }
            else {
                //cancelado
                return (
                    <>
                        <ContainerAcoes backGroundDinamico={this.props.backgroundMaisEscuro}>
                            <ContainerBotoesAcoes>
                                <BotaoDesativado data-tip="Atendimento Cancelado, Reabra caso deseja executar alguma ação" data-for="tip-top" backgroundBotao="#9fadb7">
                                    <IconeVariosNovos className="icon-atender" colorPrimario={this.props.letraBotaoPadrao} />
                                    <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Atribuir</TextBotaoNovo>
                                </BotaoDesativado>
                                <BotaoDesativado data-tip="Atendimento Cancelado, Reabra caso deseja executar alguma ação" data-for="tip-top" backgroundBotao="#9fadb7" hoverBotao={this.props.coresLetraPadrao}>
                                    <IconeVariosNovos className="icon-esperawev" colorPrimario={this.props.letraBotaoPadrao} />
                                    <TextBotaoNovoTempoGasto colorDinamico={this.props.letraBotaoPadrao}>Tempo Gasto</TextBotaoNovoTempoGasto>
                                </BotaoDesativado>
                                <BotaoDesativado data-tip="Atendimento Cancelado, Reabra caso deseja executar alguma ação" data-for="tip-top" backgroundBotao="#9fadb7" hoverBotao={this.props.coresLetraPadrao}>
                                    <IconeVariosNovos className="icon-feitoweb" colorPrimario={this.props.letraBotaoPadrao} />
                                    <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Fechar</TextBotaoNovo>
                                </BotaoDesativado>
                                <BotaoDesativado data-tip="Atendimento Cancelado, Reabra caso deseja executar alguma ação" data-for="tip-top" backgroundBotao="#9fadb7" hoverBotao={this.props.coresLetraPadrao}>
                                    <IconeVariosNovos className="icon-prioridadeweb3" colorPrimario={this.props.letraBotaoPadrao} />
                                    <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Prioridade</TextBotaoNovo>
                                </BotaoDesativado>
                                <BotaoDesativado data-tip="Atendimento Cancelado, Reabra caso deseja executar alguma ação" data-for="tip-top" backgroundBotao="#9fadb7" hoverBotao={this.props.coresLetraPadrao}>
                                    <IconeVariosNovos className="icon-vencimento" colorPrimario={this.props.letraBotaoPadrao} />
                                    <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Vencimento</TextBotaoNovo>
                                </BotaoDesativado>
                                <ButtonVariosNovos data-tip="Reabrir atendimento" onClick={() => { this._toggleModalReabrirChamado() }} data-for="tip-top" backgroundBotao="#f70e4c" hoverBotao={this.props.coresLetraPadrao}>
                                    <IconeVariosNovos className="icon-sair" colorPrimario={this.props.letraBotaoPadrao} />
                                    <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Cancelado</TextBotaoNovo>
                                </ButtonVariosNovos>
                            </ContainerBotoesAcoes>
                            <ContainerIconesLaterais>
                                <ContainerIconeLateralDesativado data-tip="Atendimento Cancelado, Reabra caso deseja executar alguma ação" data-for="tip-top" backgroundBotao="#9fadb7" hoverBotao={this.props.coresLetraPadrao}>
                                    <i className="icon-editar" style={{ fontSize: 18, color: this.props.letraBotaoPadrao }} />
                                </ContainerIconeLateralDesativado>
                                <ContainerIconeLateralDesativado backgroundBotao="#9fadb7" hoverBotao={this.props.coresLetraPadrao}>
                                    <i className="icon-clip" data-tip="Atendimento Cancelado, Reabra caso deseja executar alguma ação" data-for="tip-top" style={{ fontSize: 18, color: this.props.letraBotaoPadrao }} />
                                </ContainerIconeLateralDesativado>
                                <ContainerIconeLateral onClick={() => {
                                    if (this.props.premium == 0) {
                                        toastr.error('Recurso Premium', 'Este é um recurso PREMIUM, para fazer upload de arquivos e imagens, Assine a versão PREMIUM e libere esse e mais recursos!');
                                    } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
                                        toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
                                    }
                                    else {
                                        this.props.exportaAtendimentoParaPDF(this.props.dadosAtendimentos[0])
                                    }
                                }} backgroundBotao={this.props.corSegundoBotaoPadrao} hoverBotao={this.props.coresLetraPadrao}>
                                    <i className="icon-pdf2" data-tip="Relatório em PDF do atendimento" data-for="tip-top" style={{ fontSize: 18, color: this.props.letraBotaoPadrao }} />
                                </ContainerIconeLateral>
                            </ContainerIconesLaterais>
                        </ContainerAcoes>
                        <ContainerComentario backGroundDinamico={this.props.backgroundMaisEscuro}>
                            <DivAdicionarComentarioDesativado data-tip="Atendimento Cancelado, Reabra caso deseja executar alguma ação" data-for="tip-top" backGroundDinamico={this.props.backgroundPrimeiro}>
                                <TextAddComent corLetraDinamica={this.props.coresLetraSecundario}>Adicionar Comentario</TextAddComent>
                            </DivAdicionarComentarioDesativado>
                            <ContainerIconeRespostaChamadoFechado backgroundBotao='#9fadb7' hoverBotao={this.props.coresLetraPadrao}>
                                <i className="icon-list-on-window" data-tip="Reabra caso deseja comentar alguma resposta padrão" data-for="tip-top" style={{ fontSize: 15, color: this.props.letraBotaoPadrao }} />
                            </ContainerIconeRespostaChamadoFechado>
                            {this.renderMicrophoneRecording()}
                            {/* <ContainerIconeRespostaChamadoFechado backgroundBotao='#9fadb7' hoverBotao={this.props.coresLetraPadrao}>
                                <i className="icon-microphone" data-tip="Reabra caso deseja enviar mensagem de audio" data-for="tip-top" style={{ fontSize: 15, color: this.props.letraBotaoPadrao }} />
                            </ContainerIconeRespostaChamadoFechado> */}
                        </ContainerComentario>
                    </>
                )
            }
        }

    }
    renderDataHoraAbertura(status) {
        //
        if (status == 'Cancelado') {
            return (
                <>
                    <TextAbertura corLetraDinamica={this.props.coresLetraPadrao}>Abertura: </TextAbertura>
                    <TextDataHoraAbertura corLetraDinamica={this.props.coresLetraSecundario}> {this.props.dadosAtendimentos[0].dataHoraAbertura}</TextDataHoraAbertura>
                    <TextAbertura style={{ marginLeft: 10 }} corLetraDinamica={this.props.coresLetraPadrao}>Cancelamento: </TextAbertura>
                    <TextDataHoraAbertura corLetraDinamica={this.props.coresLetraSecundario}>{this.props.dadosAtendimentos[0].dataHoraCancelamento}</TextDataHoraAbertura>
                </>
            )
        } else if (status == 'Fechado') {
            return (
                <>
                    <TextAbertura corLetraDinamica={this.props.coresLetraPadrao}>Abertura: </TextAbertura>
                    <TextDataHoraAbertura corLetraDinamica={this.props.coresLetraSecundario}> {this.props.dadosAtendimentos[0].dataHoraAbertura}</TextDataHoraAbertura>
                    <TextAbertura style={{ marginLeft: 10 }} corLetraDinamica={this.props.coresLetraPadrao}>Fechamento: </TextAbertura>
                    <TextDataHoraAbertura corLetraDinamica={this.props.coresLetraSecundario}>{this.props.dadosAtendimentos[0].dataHoraFechamento}</TextDataHoraAbertura>
                </>

            )
        } else {
            return (
                <>
                    <TextAbertura corLetraDinamica={this.props.coresLetraPadrao}>Abertura: </TextAbertura>
                    <TextDataHoraAbertura corLetraDinamica={this.props.coresLetraSecundario}> {this.props.dadosAtendimentos[0].dataHoraAbertura}</TextDataHoraAbertura>
                </>
            )
        }
    }
    _toggleModalReabrirChamado() {
        this.setState({ modalReabrirChamado: !this.state.modalReabrirChamado });
    }

    renderCanalChamado() {
        if (this.props.dadosAtendimentos.length > 0 && this.props.dadosAtendimentos[0].chamadoAbertoPorWpp === true) {
            //whatsapp
            return (
                <DivSeparadoraMaisInformacoes corBordaDinamica={this.props.coresLetraPadrao}>
                    <DivLAbelCliente>
                        <LabelClienteCabecalho corLetraDinamica={this.props.coresLetraPadrao}>Canal Abertura:</LabelClienteCabecalho>
                    </DivLAbelCliente>
                    <DivCliente>
                        <LabelCliente corLetraDinamica={this.props.coresLetraPadrao}>WhatsApp</LabelCliente>
                    </DivCliente>
                </DivSeparadoraMaisInformacoes>

            )
        } else if (this.props.dadosAtendimentos.length > 0 && this.props.dadosAtendimentos[0].abertoPorChat === "false") {
            //ticket
            return (
                <DivSeparadoraMaisInformacoes corBordaDinamica={this.props.coresLetraPadrao}>
                    <DivLAbelCliente>
                        <LabelClienteCabecalho corLetraDinamica={this.props.coresLetraPadrao}>Canal Abertura:</LabelClienteCabecalho>
                    </DivLAbelCliente>
                    <DivCliente>
                        <LabelCliente corLetraDinamica={this.props.coresLetraPadrao}>Atendimentos</LabelCliente>
                    </DivCliente>
                </DivSeparadoraMaisInformacoes>
            )
        } else {
            //chat
            return (
                <DivSeparadoraMaisInformacoes corBordaDinamica={this.props.coresLetraPadrao}>
                    <DivLAbelCliente>
                        <LabelClienteCabecalho corLetraDinamica={this.props.coresLetraPadrao}>Canal Abertura:</LabelClienteCabecalho>
                    </DivLAbelCliente>
                    <DivCliente>
                        <LabelCliente corLetraDinamica={this.props.coresLetraPadrao}>Chat</LabelCliente>
                    </DivCliente>
                </DivSeparadoraMaisInformacoes>
            )
        }

    }
    renderLoadingAtendimento() {
        if (this.props.loadingTelaAcoesAtendimento) {
            return (
                <>
                    <ContainerDadosDoChamado style={{ marginBottom: 2 }} backGroundDinamico={this.props.backgroundMaisEscuro}>
                        <ContainerDadosChamadoPrimeiro>
                            <ContainerDadosProblema corLetraDinamica={this.props.corLetraDescricaoDosCampos}>
                                <DivTruncarTexto corLetraDinamica={this.props.corLetraDescricaoDosCampos}>
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </DivTruncarTexto>
                            </ContainerDadosProblema>
                            <ContainerDadosAbertura>
                                <TextDataHoraAberturaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextDataHoraAberturaLoading>
                            </ContainerDadosAbertura>

                        </ContainerDadosChamadoPrimeiro>
                        <ContainerDadosChamadoSegundo >
                            <ContainerIconeMaisInformacoesLoading backgroundinamico={this.props.backgroundMaisEscuro}>
                            </ContainerIconeMaisInformacoesLoading>
                        </ContainerDadosChamadoSegundo>

                    </ContainerDadosDoChamado>
                    <ContainerAcoes backGroundDinamico={this.props.backgroundMaisEscuro}>
                        <ContainerBotoesAcoes>
                            <ButtonVariosNovosLoading backgroundinamico={this.props.backgroundMaisEscuro}>
                            </ButtonVariosNovosLoading>
                            <ButtonVariosNovosLoading backgroundinamico={this.props.backgroundMaisEscuro}>
                            </ButtonVariosNovosLoading>
                            <ButtonVariosNovosLoading backgroundinamico={this.props.backgroundMaisEscuro}>
                            </ButtonVariosNovosLoading>
                            <ButtonVariosNovosLoading backgroundinamico={this.props.backgroundMaisEscuro}>
                            </ButtonVariosNovosLoading>
                            <ButtonVariosNovosLoading backgroundinamico={this.props.backgroundMaisEscuro}>
                            </ButtonVariosNovosLoading>
                            <ButtonVariosNovosLoading backgroundinamico={this.props.backgroundMaisEscuro}>
                            </ButtonVariosNovosLoading>
                        </ContainerBotoesAcoes>
                        <ContainerIconesLaterais>
                            <ContainerIconeLateralLoading backgroundinamico={this.props.backgroundMaisEscuro}>
                            </ContainerIconeLateralLoading>
                            <ContainerIconeLateralLoading backgroundinamico={this.props.backgroundMaisEscuro}>
                            </ContainerIconeLateralLoading>
                            <ContainerIconeLateralLoading backgroundinamico={this.props.backgroundMaisEscuro}>
                            </ContainerIconeLateralLoading>
                        </ContainerIconesLaterais>

                    </ContainerAcoes>
                    <ContainerComentario backGroundDinamico={this.props.backgroundMaisEscuro}>
                        <DivAdicionarComentarioLoading backgroundinamico={this.props.backgroundMaisEscuro}>
                        </DivAdicionarComentarioLoading>
                    </ContainerComentario>
                    <ContainerTimeLineLoading backGroundDinamico={this.props.backgroundPrimeiro}>
                        <MoonLoader
                            size={40}
                            color={this.props.corBotaoPadrao}
                            loading={true}
                        />
                    </ContainerTimeLineLoading>
                </>
            )
        } else {
            return (
                <div style={{ display: 'flex', flex: 1, overflow: 'auto', flexDirection: 'column' }} onScroll={this.scrollCheck}>
                    <ContainerDadosDoChamado style={{ marginBottom: 2 }} backGroundDinamico={this.props.backgroundMaisEscuro}>
                        <ContainerDadosChamadoPrimeiro>
                            <ContainerDadosProblema corLetraDinamica={this.props.corLetraDescricaoDosCampos}>
                                <DivTruncarTexto truncarTexto={this.state.maisInformacoes} corLetraDinamica={this.props.corLetraDescricaoDosCampos}>
                                    <TextProblema data-tip="Descrição do Problema" data-for="tip-top" corLetraDinamica={this.props.corLetraDescricaoDosCampos}>{this.renderIconeMessageStatus(this.props.dadosAtendimentos[0].lastmessageChatTypeProblem, this.props.dadosAtendimentos[0].descricaoProblema)}</TextProblema>
                                </DivTruncarTexto>
                            </ContainerDadosProblema>
                            <ContainerDadosAbertura>
                                <ContainerDadosAberturaFechamento>
                                    {this.renderDataHoraAbertura(this.props.dadosAtendimentos[0].status)}

                                    <TextDataHoraAbertura style={{ marginLeft: 10 }} corLetraDinamica={this.props.coresLetraSecundario}>Atendimento criado por {this.props.dadosAtendimentos[0].abertoPor}</TextDataHoraAbertura>
                                </ContainerDadosAberturaFechamento>


                            </ContainerDadosAbertura>

                        </ContainerDadosChamadoPrimeiro>
                        <ContainerDadosChamadoSegundo >
                            {this.state.maisInformacoes == false &&
                                <ContainerIconeMaisInformacoes onClick={() => { this.handleMaisInformacoes(); }} data-tip="Exibir informações do Atendimento" data-for="tip-top" backgroundBotao={this.props.corSegundoBotaoPadrao}>
                                    <i className="icon-down-arrow" style={{ fontSize: 18, color: this.props.letraBotaoPadrao }} />
                                </ContainerIconeMaisInformacoes>}
                            {this.state.maisInformacoes == true &&
                                <ContainerIconeMaisInformacoes onClick={() => { this.handleMaisInformacoes() }} data-tip="Ocultar informações do Atendimento" data-for="tip-top" backgroundBotao={this.props.corSegundoBotaoPadrao}>
                                    <i className="icon-up-arrow" style={{ fontSize: 18, color: this.props.letraBotaoPadrao }} />
                                </ContainerIconeMaisInformacoes>}
                        </ContainerDadosChamadoSegundo>
                    </ContainerDadosDoChamado>
                    {this.state.maisInformacoes == true && <ContainerMaisInformacoes backGroundDinamico={this.props.backgroundSegundo}>
                        <SubContainerMaisInformacoes backGroundDinamico={this.props.backgroundMaisEscuro}>
                            <DivSeparadoraMaisInformacoes style={{ borderTopWidth: 1, borderTopColor: this.props.coresLetraPadrao }} corBordaDinamica={this.props.coresLetraPadrao}>
                                <DivLAbelCliente>
                                    <LabelClienteCabecalho corLetraDinamica={this.props.coresLetraPadrao}>Nome Cliente:</LabelClienteCabecalho>
                                </DivLAbelCliente>
                                <DivCliente>
                                    <LabelCliente corLetraDinamica={this.props.coresLetraPadrao}>{this.props.dadosAtendimentos[0].cliente}</LabelCliente>
                                </DivCliente>
                            </DivSeparadoraMaisInformacoes>
                            <DivSeparadoraMaisInformacoes corBordaDinamica={this.props.coresLetraPadrao}>
                                <DivLAbelCliente>
                                    <LabelClienteCabecalho corLetraDinamica={this.props.coresLetraPadrao}>Empresa:</LabelClienteCabecalho>
                                </DivLAbelCliente>
                                <DivCliente>
                                    <LabelCliente corLetraDinamica={this.props.coresLetraPadrao}>{this.props.dadosAtendimentos[0].empresa}</LabelCliente>
                                </DivCliente>
                            </DivSeparadoraMaisInformacoes>
                            <DivSeparadoraMaisInformacoes style={{ borderTopWidth: 1, borderTopColor: this.props.coresLetraPadrao }} corBordaDinamica={this.props.coresLetraPadrao}>
                                <DivLAbelCliente>
                                    <LabelClienteCabecalho corLetraDinamica={this.props.coresLetraPadrao}>Telefone:</LabelClienteCabecalho>
                                </DivLAbelCliente>
                                <DivCliente>
                                    <LabelCliente corLetraDinamica={this.props.coresLetraPadrao}>{this.props.dadosAtendimentos[0].telefoneCliente === '' ? 'Não informado' : this.props.dadosAtendimentos[0].telefoneCliente}</LabelCliente>
                                </DivCliente>
                            </DivSeparadoraMaisInformacoes>
                            <DivSeparadoraMaisInformacoes style={{ borderTopWidth: 1, borderTopColor: this.props.coresLetraPadrao }} corBordaDinamica={this.props.coresLetraPadrao}>
                                <DivLAbelCliente>
                                    <LabelClienteCabecalho corLetraDinamica={this.props.coresLetraPadrao}>Endereço:</LabelClienteCabecalho>
                                </DivLAbelCliente>
                                <DivCliente>
                                    <LabelCliente corLetraDinamica={this.props.coresLetraPadrao}>{this.props.dadosAtendimentos[0].enderecoCliente === '' ? 'Não informado' : this.props.dadosAtendimentos[0].enderecoCliente}</LabelCliente>
                                </DivCliente>
                            </DivSeparadoraMaisInformacoes>
                            <DivSeparadoraMaisInformacoes corBordaDinamica={this.props.coresLetraPadrao}>
                                <DivLAbelCliente>
                                    <LabelClienteCabecalho corLetraDinamica={this.props.coresLetraPadrao}>Atendente:</LabelClienteCabecalho>
                                </DivLAbelCliente>
                                <DivCliente>
                                    <LabelCliente corLetraDinamica={this.props.coresLetraPadrao}>{this.props.dadosAtendimentos[0].atendente}</LabelCliente>
                                </DivCliente>
                            </DivSeparadoraMaisInformacoes>
                            <DivSeparadoraMaisInformacoes corBordaDinamica={this.props.coresLetraPadrao}>
                                <DivLAbelCliente>
                                    <LabelClienteCabecalho corLetraDinamica={this.props.coresLetraPadrao}>Setor:</LabelClienteCabecalho>
                                </DivLAbelCliente>
                                <DivCliente>
                                    <LabelCliente corLetraDinamica={this.props.coresLetraPadrao}>{this.props.dadosAtendimentos[0].setor}</LabelCliente>
                                </DivCliente>
                            </DivSeparadoraMaisInformacoes>
                            <DivSeparadoraMaisInformacoes corBordaDinamica={this.props.coresLetraPadrao}>
                                <DivLAbelCliente>
                                    <LabelClienteCabecalho corLetraDinamica={this.props.coresLetraPadrao}>Vencimento:</LabelClienteCabecalho>
                                </DivLAbelCliente>
                                <DivCliente>
                                    <LabelCliente corLetraDinamica={this.props.coresLetraPadrao}>{this.props.dadosAtendimentos[0].dataVencimento}</LabelCliente>
                                </DivCliente>
                            </DivSeparadoraMaisInformacoes>
                            <DivSeparadoraMaisInformacoes corBordaDinamica={this.props.coresLetraPadrao}>
                                <DivLAbelCliente>
                                    <LabelClienteCabecalho corLetraDinamica={this.props.coresLetraPadrao}>Prioridade:</LabelClienteCabecalho>
                                </DivLAbelCliente>
                                <DivCliente>
                                    <LabelCliente corLetraDinamica={this.props.coresLetraPadrao}>{this.props.dadosAtendimentos[0].prioridade}</LabelCliente>
                                </DivCliente>
                            </DivSeparadoraMaisInformacoes>
                            <DivSeparadoraMaisInformacoes corBordaDinamica={this.props.coresLetraPadrao}>
                                <DivLAbelCliente>
                                    <LabelClienteCabecalho corLetraDinamica={this.props.coresLetraPadrao}>Local:</LabelClienteCabecalho>
                                </DivLAbelCliente>
                                <DivCliente>
                                    <LabelCliente corLetraDinamica={this.props.coresLetraPadrao}>{this.props.dadosAtendimentos[0].local}</LabelCliente>
                                </DivCliente>
                            </DivSeparadoraMaisInformacoes>
                            <DivSeparadoraMaisInformacoes corBordaDinamica={this.props.coresLetraPadrao}>
                                <DivLAbelCliente>
                                    <LabelClienteCabecalho corLetraDinamica={this.props.coresLetraPadrao}>Status:</LabelClienteCabecalho>
                                </DivLAbelCliente>
                                <DivCliente>
                                    <LabelCliente corLetraDinamica={this.props.coresLetraPadrao}>{this.props.dadosAtendimentos[0].status}</LabelCliente>
                                </DivCliente>
                            </DivSeparadoraMaisInformacoes>
                            {this.props.dadosAtendimentos[0].status == 'Fechado' &&
                                <DivSeparadoraMaisInformacoes corBordaDinamica={this.props.coresLetraPadrao}>
                                    <DivLAbelCliente>
                                        <LabelClienteCabecalho corLetraDinamica={this.props.coresLetraPadrao}>Solução:</LabelClienteCabecalho>
                                    </DivLAbelCliente>
                                    <DivSOlucao>
                                        <LabelSolucao corLetraDinamica={this.props.coresLetraPadrao}>{this.props.dadosAtendimentos[0].solucaoAtendimento}</LabelSolucao>
                                    </DivSOlucao>
                                </DivSeparadoraMaisInformacoes>}
                            {this.renderCanalChamado()}
                        </SubContainerMaisInformacoes>
                        {this.renderArquivosAnexadosAoChamado()}
                    </ContainerMaisInformacoes>}

                    {this.renderBotesAcoesChamado()}
                    <ContainerTimeLine backGroundDinamico={this.props.backgroundPrimeiro}>
                        <Dropzone
                            multiple={true}
                            noKeyboard
                            maxSize={2e+7}
                            noClick
                            onDrop={acceptedFiles => this.escolheArquivoChamado(acceptedFiles)}>
                            {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject }) => {
                                //
                                if (isDragActive) {
                                    if (this.props.dadosAtendimentos[0].status == 'Fechado') {
                                        return (
                                            <div style={{
                                                display: 'flex',
                                                flex: 1,
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                padding: '20px',
                                                borderWidth: 2,
                                                borderRadius: 2,
                                                borderColor: 'red',
                                                borderStyle: 'dashed',
                                                color: '#bdbdbd',
                                                outline: 'none',
                                                transition: 'border .24s ease-in-out',
                                                justifyContent: 'center',
                                                /*  margin: 5, */
                                            }}  {...getRootProps()}>
                                                {/*   <input {...getInputProps()} /> */}
                                                <p style={{ color: this.props.coresLetraSecundario, textAlign: 'center', fontSize: 22 }}>Chamado Cancelado</p>
                                                <p style={{ color: this.props.coresLetraSecundario, textAlign: 'center', fontSize: 22, marginTop: 20 }}>Reabra para fazer upload de arquivos</p>
                                            </div>
                                        )
                                    } else if (this.props.dadosAtendimentos[0].status == 'Cancelado') {
                                        return (
                                            <div style={{
                                                display: 'flex',
                                                flex: 1,
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                padding: '20px',
                                                borderWidth: 2,
                                                borderRadius: 2,
                                                borderColor: 'red',
                                                borderStyle: 'dashed',
                                                color: '#bdbdbd',
                                                outline: 'none',
                                                transition: 'border .24s ease-in-out',
                                                justifyContent: 'center',
                                                /*  margin: 5, */
                                            }}  {...getRootProps()}>
                                                {/*   <input {...getInputProps()} /> */}
                                                <p style={{ color: this.props.coresLetraSecundario, textAlign: 'center', fontSize: 22 }}>Chamado Cancelado</p>
                                                <p style={{ color: this.props.coresLetraSecundario, textAlign: 'center', fontSize: 22, marginTop: 20 }}>Reabra para fazer upload de arquivos</p>
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div style={{
                                                display: 'flex',
                                                flex: 1,
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                padding: '20px',
                                                borderWidth: 2,
                                                borderRadius: 2,
                                                borderColor: this.renderBordaDrag(isDragActive, isDragAccept, isDragReject),
                                                borderStyle: 'dashed',
                                                color: '#bdbdbd',
                                                outline: 'none',
                                                transition: 'border .24s ease-in-out',
                                                justifyContent: 'center',
                                                /*  margin: 5, */
                                            }}  {...getRootProps()}>
                                                {/*   <input {...getInputProps()} /> */}
                                                <p style={{ color: this.props.coresLetraSecundario, textAlign: 'center', fontSize: 22 }}>Solte Arquivos para Anexar <i className="icon-clip" style={{ color: this.props.coresLetraPadrao, fontSize: 21 }} /></p>
                                                <p style={{ color: this.props.coresLetraSecundario, textAlign: 'center', fontSize: 22, marginTop: 20 }}>Tamanho Máximo de 20MB</p>
                                            </div>
                                        )
                                    }
                                } else {
                                    return (

                                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}  {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <Timeline lineColor={this.props.coresLetraSecundario}>
                                                <FlatList
                                                    list={this.props.acoesDoAtendimento}
                                                    renderItem={(item) => this.messageBox(item)}
                                                    renderWhenEmpty={() => null}
                                                />
                                                <FlatList
                                                    list={this.props.arrayConversasAntigasChatAcoesChamado}
                                                    renderItem={(item) => this.messageBox(item)}
                                                    renderWhenEmpty={() => null}
                                                />


                                                {this.renderRefreshConversasAnteriores()}
                                            </Timeline>
                                        </div>

                                    )
                                }
                            }}
                        </Dropzone>
                    </ContainerTimeLine>
                </div>
            )
        }
    }
    cancelarChamadoLocal() {
        if (this.props.motivoCancelamento.trim() == '' && this.props.obrigatorioInformarMotivoCancelamento === true) {
            toastr.error('Error ao Cancelar', 'Informe um motivo de cancelamento');
        } else {
            this.props.modificaVisibilidadeModalCancelarAtendimento(false);
            this.props.cancelarChamado(this.props.keyAtendimento, this.props.motivoCancelamento, this.props.dadosAtendimentos[0].keyAtendente, this.props.dadosAtendimentos[0], this.props.obrigatorioInformarMotivoCancelamento)
        }
    }
    //daqui pra baixo eh o novo messagebox
    renderResponderMensagem(item) {
        //onReplyClick={() => { this.renderResponderMensagem(item) }}
        // let input = this[this.props.data.key];
        /*  input.focus(); */
        this.props.modificavisibilidadeModalComentario(true);
        if (item.tipo === 'mensagem') {
            this.setState({ commentResponderType: 'texto', commentResponder: item.description, idResponder: item.idMensagem, circleColorResponder: item.circleColor, viewResponder: true, atendenteResponder: item.title })
        } else if (item.tipo === 'location') {
            this.setState({ commentResponderType: 'location', commentResponder: 'Localização', idResponder: item.idMensagem, circleColorResponder: item.circleColor, viewResponder: true, atendenteResponder: item.title })
        } else if (item.tipo === 'vcard') {
            this.setState({ commentResponderType: 'contato', commentResponder: 'Contato', idResponder: item.idMensagem, circleColorResponder: item.circleColor, viewResponder: true, atendenteResponder: item.title })
        } else {
            if (item.tipoArquivo.includes('image') && !item.tipoArquivo.includes('corel')) {
                this.setState({ commentResponderType: 'imagem', commentResponder: 'Imagem', idResponder: item.idMensagem, circleColorResponder: item.circleColor, viewResponder: true, atendenteResponder: item.title })
            } else if (item.tipoArquivo.includes('video') && item.audio !== true || item.tipoArquivo.includes('mp4') && item.audio !== true) {
                this.setState({ commentResponderType: 'video', commentResponder: 'Vídeo', idResponder: item.idMensagem, circleColorResponder: item.circleColor, viewResponder: true, atendenteResponder: item.title })
            } else if (item.audio == true) {
                this.setState({ commentResponderType: 'audio', commentResponder: 'Mensagem de Voz', idResponder: item.idMensagem, circleColorResponder: item.circleColor, viewResponder: true, atendenteResponder: item.title })
            } else {
                this.setState({ commentResponderType: 'arquivo', commentResponder: 'Arquivo', idResponder: item.idMensagem, circleColorResponder: item.circleColor, viewResponder: true, atendenteResponder: item.title })
            }
        }
    }
    downloadImage(url, name) {

        this.props.modificaUrlPreviewImage(url)
        this.props.modificaModalPreviewImage(true)
        this.props.modificaNamePreviewImage(name)
        /*  let link = document.createElement("a");
         //usar quando for abrir imagem
         link.setAttribute("href", url);
         link.setAttribute("target", "_blank");
         link.style.visibility = 'hidden';
         document.body.appendChild(link);
         link.click();
         document.body.removeChild(link); */
        /*  const a = document.createElement('a');
         a.href = url;
         a.download = name;
         a.click();
         a.target = "_blank";

         window.URL.revokeObjectURL(url); */
        /*  const a = document.createElement('a');
         a.href = url;
         a.download = name;
         a.click();
         a.target = "_blank";
         window.open(url); */
    };
    renderReplyButton(item) {
        if (this.props.dadosAtendimentos[0].channelChamado.includes('Chat') || this.props.dadosAtendimentos[0].status == 'Fechado' || this.props.dadosAtendimentos[0].status == 'Cancelado' || item.deleteMessage === true) {
            return false
        } else {
            return true
        }
    }
    renderRemoveButton(item) {
        if (this.props.dadosAtendimentos[0].channelChamado.includes('Chat') || this.props.dadosAtendimentos[0].status == 'Fechado' || this.props.dadosAtendimentos[0].status == 'Cancelado' || item.deleteMessage === true || item.circleColor === "#39a3f4" ||
            item.title === "Avaliar Este Atendimento" || this.props.isAdmin === false) {
            return false
        } else {
            return true
        }
    }
    renderMensagemReply(item) {
        if (item.possuiResposta) {
            /*     { this.state.commentResponderType === 'imagem' && <i className="icon-imagewpp" style={{ fontSize: 14, color: '#000' }} /> }
                { this.state.commentResponderType === 'video' && <i className="icon-video-camera" style={{ fontSize: 14, color: '#000' }} /> }

                { this.state.commentResponderType === 'location' && <i className="icon-localization" style={{ fontSize: 14, color: '#000' }} /> }
                { this.state.commentResponderType === 'contato' && <i className="icon-man-user" style={{ fontSize: 14, color: '#000' }} /> }
                { this.state.commentResponderType === 'audio' && <i className="icon-microphone" style={{ fontSize: 14, color: '#000' }} /> }
                { this.state.commentResponderType === 'arquivo' && <i className="icon-documentwpp" style={{ fontSize: 14, color: '#000' }} /> }
                { this.state.commentResponder } */
            return {
                title: item.circleColorResponder === '#39a3f4' ? this.props.dadosAtendimentos[0].cliente : item.atendenteResponder,
                titleColor: item.circleColorResponder === '#00FF7F' ? '#16cb9c' : "#39a3f4",
                message:
                    this.renderTextArquivosMensagemRespondidas(item)

             /*    (<p>
                    {item.commentResponderType === 'imagem' && <i className="icon-imagewpp" style={{ fontSize: 13, color: '#000' }} />}
                    {item.commentResponderType === 'video' && <i className="icon-video-camera" style={{ fontSize: 13, color: '#000' }} />}

                    {item.commentResponderType === 'location' && <i className="icon-localization" style={{ fontSize: 13, color: '#000' }} />}
                    {item.commentResponderType === 'contato' && <i className="icon-man-user" style={{ fontSize: 13, color: '#000' }} />}
                    {item.commentResponderType === 'audio' && <i className="icon-microphone" style={{ fontSize: 13, color: '#000' }} />}
                    {item.commentResponderType === 'arquivo' && <i className="icon-documentwpp" style={{ fontSize: 13, color: '#000' }} />}
                    {item.mensagemResposta}

                     {item.commentResponderType === 'link' && item.commentResponderTypeArquivo.includes('image') && <><i className="icon-imagewpp" style={{ fontSize: 13, color: '#000' }} /> Imagem</>}
                    {item.commentResponderType === 'link' && item.commentResponderTypeArquivo.includes('video') && item.commentResponderIsAudio !== true || item.commentResponderTypeArquivo.includes('mp4') && item.commentResponderIsAudio !== true && <> < i className="icon-video-camera" style={{ fontSize: 13, color: '#000' }} /> Video</>}
                    {item.commentResponderType === 'link' && item.commentResponderIsAudio === true && <> <i className="icon-microphone" style={{ fontSize: 13, color: '#000' }} /> Mensagem de Voz</>}
                    {item.commentResponderType === 'link' && !item.commentResponderTypeArquivo.includes('image') && !item.commentResponderTypeArquivo.includes('video') && !item.commentResponderIsAudio === false && !item.commentResponderIsAudio === true && <><i className="icon-documentwpp" style={{ fontSize: 13, color: '#000' }} /> Arquivo</>}

                    {item.commentResponderType !== 'link' && <>{item.mensagemResposta}</>}
                </p>) */,


            }
        } else {
            return null
        }


    }
    renderClickButtonReply(item, ref) {

        let msgMesmaConversa = this.props.acoesDoAtendimento.filter(e => e.idMensagem === item.idMensagemResponder).length > 0;

        if (msgMesmaConversa === true) {


            this.setState({ focusMessageBox: item.idMensagemResponder })
        } else {
            let mensagemJaCarregada = false
            for (let i = 0; i < this.props.arrayConversasAntigasChatAcoesChamado.length; i++) {

                if (this.props.arrayConversasAntigasChatAcoesChamado[i].idMensagem === item.idMensagemResponder) {
                    mensagemJaCarregada = true;
                    break;
                }
            }

            if (mensagemJaCarregada === true) {
                this.setState({ focusMessageBox: item.idMensagemResponder })
            } else {

                let arrayConversas = [...this.props.acoesDoAtendimento]
                let ultimaKey = arrayConversas[arrayConversas.length - 1].key;
                this.props.listaConversasAntigasResponderAcoes(this.props.dadosAtendimentos[0].key, item.idMensagemResponder, [], 'emAtendimento', ultimaKey, item.idMensagemResponder)
            }
        }
        /*
        if (this.props.dadosAtendimentos[0].chamadoAbertoPorWpp === true) {
            firebase.database().ref(`relacionamentoMensagensStatusWpp`).orderByChild('idMensagem').equalTo(item.idMensagemResponder).once('value').then((snapshot) => {
                if (snapshot.val()) {
                    let keyChamado = '';

                    snapshot.forEach(childItem => {
                        keyChamado = childItem.val().keyChamado;
                    })


                    if (keyChamado === this.props.dadosAtendimentos[0].key) {


                        this.setState({ focusMessageBox: item.idMensagemResponder })
                    } else {

                    }
                } else {

                    for (let i = 0; i < this.props.acoesDoAtendimento.length; i++) {


                        if (this.props.acoesDoAtendimento[i].key === item.idMensagemResponder) {


                            this.setState({ focusMessageBox: this.props.acoesDoAtendimento[i].key })
                            break;
                        }
                    }
                }
            })
        } else {
            this.setState({ focusMessageBox: item.idMensagemResponder })
        } */

    }
    renderStatusMessageBox(item) {
        if (item.circleColor === '#39a3f4' || item.visivelATodos === 0 || this.props.dadosAtendimentos[0].cliente === 'Não informado') {
            return null
        } else {
            return item.statusMessage
        }
    }
    messageBox(item) {

        if (item.deleteMessage === true && this.state.visualizaMensagemExcluida === false) {
            return (
                <TimelineItem
                    key={item.key}
                    dateText={item.dataHoraAtual}
                    dateInnerStyle={{ background: item.circleColor, color: '#000' }}
                    dateStyle={{ textAlign: 'center' }}
                    style={{ color: item.circleColor }}
                    bodyContainerStyle={{
                        background: this.props.backgroundSegundo,
                        padding: '10px',
                        borderRadius: '8px',
                        boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                        width: "100%"
                    }}
                >
                    <MessageBox
                        {...item}
                        id={item.key}
                        reply={this.renderMensagemReply(item)}
                        /*   ref={ref => { this.refsArray[item.key] = ref }} */
                        onReplyMessageClick={(e) => { this.renderClickButtonReply(item, e) }}
                        onReplyClick={() => { this.renderResponderMensagem(item) }}
                        onForwardClick={() => {
                            this._toggleModalEncaminharMensagem();
                            let mensagemParaSerEncaminhada2 = [];
                            item['keyChamado'] = this.props.data.key;
                            mensagemParaSerEncaminhada2.push(item)
                            this.setState({ mensagemParaSerEncaminhada: mensagemParaSerEncaminhada2 })
                        }}
                        onRemoveMessageClick={() => { this.setState({ modalMensagemExcluida: true, dadosMensagemExcluida: item }) }}
                        //onClick={() => {  }}
                        //onOpen={() => {  }}
                        position={item.circleColor === '#00FF7F' ? "left" : "right"}
                        focus={item.idMensagem === this.state.focusMessageBox ? true : false}
                        onMessageFocused={() => { setTimeout(() => { ; this.setState({ focusMessageBox: 'vazio' }); }, 1000); }}
                        type={'text'}
                        title={(
                            <div
                                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                            >
                                {item.title}
                                {this.state.visualizaMensagemExcluida === false && <span onClick={() => { this.setState({ visualizaMensagemExcluida: true }) }} style={{ color: '#16cb9c', fontSize: 10, textDecorationLine: 'underline', marginLeft: 10, fontStyle: 'italic', cursor: "pointer" }}>Visualizar</span>}
                            </div>
                        )}
                        titleColor={item.circleColor === '#00FF7F' ? '#16cb9c' : "#39a3f4"}
                        text={
                            (
                                <Linkify
                                    componentDecorator={(decoratedHref, decoratedText, key) => (
                                        <a style={{ fontSize: 15, color: '#39a3f4' }} target="blank" href={decoratedHref} key={key}>
                                            {decoratedText}
                                        </a>
                                    )}
                                >
                                    <i className="icon-block" style={{ fontSize: 14, color: '#808080' }} />
                                    <span style={{ color: '#808080', fontSize: 14, fontStyle: 'italic' }}>Mensagem Apagada</span>
                                </Linkify>
                            )
                        }
                        copiableDate
                        dateString={item.dataHoraAtual}
                        forwarded={false}
                        replyButton={false}
                        removeButton={false}
                        status={null} //(waiting, sent, received, read)

                    />
                </TimelineItem>
            )
        } else if (item.tipo == 'mensagem') {
            if (item.title.includes('Atendimento criado por')) {
                return (
                    <TimelineItem
                        key={item.key}
                        dateText={item.dataHoraAtual}
                        dateInnerStyle={{ background: item.circleColor, color: '#000' }}
                        dateStyle={{ textAlign: 'center' }}
                        style={{ color: item.circleColor }}
                        bodyContainerStyle={{
                            background: this.props.backgroundSegundo,
                            padding: '10px',
                            borderRadius: '8px',
                            boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                            width: "100%"
                        }}
                    >
                        {this.renderDescriptionMessageBox(item)}
                        <SystemMessage
                            text={`${item.title}`} />


                    </TimelineItem>
                )

            } else if (item.description.includes(`Atendimento atribuido ao atendente`)) {
                let atendendoAtribuido = item.description.replace('Atendimento atribuido ao atendente', '')
                return (
                    <TimelineItem
                        key={item.key}
                        dateText={item.dataHoraAtual}
                        dateInnerStyle={{ background: item.circleColor, color: '#000' }}
                        dateStyle={{ textAlign: 'center' }}
                        style={{ color: item.circleColor }}
                        bodyContainerStyle={{
                            background: this.props.backgroundSegundo,
                            padding: '10px',
                            borderRadius: '8px',
                            boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                            width: "100%"
                        }}
                    >

                        <SystemMessage
                            text={`${item.description}`} />
                        {item.visivelATodos == 0 && <div style={{ alignItems: 'flex-end', justifyContent: 'flex-end', display: 'flex', flexDirection: 'column' }}>
                            <p style={{ color: this.props.coresLetraSecundario, fontSize: 9, fontStyle: 'italic', marginBottom: -10 }}>Comentário Interno</p>
                        </div>}
                        {/* <SystemMessage
                            text={`${item.description} entrou na Conversa`} /> */}

                    </TimelineItem>
                )
            }
            else if (item.description.includes(`Atendimento transferido para o setor`)) {
                return (
                    <TimelineItem
                        key={item.key}
                        dateText={item.dataHoraAtual}
                        dateInnerStyle={{ background: item.circleColor, color: '#000' }}
                        dateStyle={{ textAlign: 'center' }}
                        style={{ color: item.circleColor }}
                        bodyContainerStyle={{
                            background: this.props.backgroundSegundo,
                            padding: '10px',
                            borderRadius: '8px',
                            boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                            width: "100%"
                        }}
                    >

                        <SystemMessage
                            text={`${item.description}`} />
                        {item.visivelATodos == 0 && <div style={{ alignItems: 'flex-end', justifyContent: 'flex-end', display: 'flex', flexDirection: 'column' }}>
                            <p style={{ color: this.props.coresLetraSecundario, fontSize: 9, fontStyle: 'italic', marginBottom: -10 }}>Comentário Interno</p>
                        </div>}
                        {/* <SystemMessage
                            text={`${item.description} entrou na Conversa`} /> */}

                    </TimelineItem>
                )
            } else if (item.description.includes(`Atendimento reaberto por ${item.title}`)) {
                return (
                    <TimelineItem
                        key={item.key}
                        dateText={item.dataHoraAtual}
                        dateInnerStyle={{ background: item.circleColor, color: '#000' }}
                        dateStyle={{ textAlign: 'center' }}
                        style={{ color: item.circleColor }}
                        bodyContainerStyle={{
                            background: this.props.backgroundSegundo,
                            padding: '10px',
                            borderRadius: '8px',
                            boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                            width: "100%"
                        }}
                    >

                        <SystemMessage
                            text={`${item.description}`} />
                        {item.visivelATodos == 0 && <div style={{ alignItems: 'flex-end', justifyContent: 'flex-end', display: 'flex', flexDirection: 'column' }}>
                            <p style={{ color: this.props.coresLetraSecundario, fontSize: 9, fontStyle: 'italic', marginBottom: -10 }}>Comentário Interno</p>
                        </div>}
                        {/* <SystemMessage
                            text={`${item.description} entrou na Conversa`} /> */}

                    </TimelineItem>
                )
            }
            else if (item.description.includes(`${item.title} entrou no Chat`)) {
                return (
                    <TimelineItem
                        key={item.key}
                        dateText={item.dataHoraAtual}
                        dateInnerStyle={{ background: item.circleColor, color: '#000' }}
                        dateStyle={{ textAlign: 'center' }}
                        style={{ color: item.circleColor }}
                        bodyContainerStyle={{
                            background: this.props.backgroundSegundo,
                            padding: '10px',
                            borderRadius: '8px',
                            boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                            width: "100%"
                        }}
                    >
                        <SystemMessage
                            text={`${item.title} entrou na Conversa`} />
                        {item.visivelATodos == 0 && <div style={{ alignItems: 'flex-end', justifyContent: 'flex-end', display: 'flex', flexDirection: 'column' }}>
                            <p style={{ color: this.props.coresLetraSecundario, fontSize: 9, fontStyle: 'italic', marginBottom: -10 }}>Comentário Interno</p>
                        </div>}
                        {/*   <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginBottom: 20, marginTop: 10 }} key={item.key}>
                          < TextMensagem corLetraDinamica={this.props.coresLetraPadrao} >
                              {item.description}
                          </TextMensagem>
                      </div> */}
                    </TimelineItem>
                )
            } else if (item.description.includes(`${item.title} Alterou o setor de`)) {
                return (
                    <TimelineItem
                        key={item.key}
                        dateText={item.dataHoraAtual}
                        dateInnerStyle={{ background: item.circleColor, color: '#000' }}
                        dateStyle={{ textAlign: 'center' }}
                        style={{ color: item.circleColor }}
                        bodyContainerStyle={{
                            background: this.props.backgroundSegundo,
                            padding: '10px',
                            borderRadius: '8px',
                            boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                            width: "100%"
                        }}
                    >
                        <SystemMessage
                            text={`${item.description}`} />
                        {item.visivelATodos == 0 && <div style={{ alignItems: 'flex-end', justifyContent: 'flex-end', display: 'flex', flexDirection: 'column' }}>
                            <p style={{ color: this.props.coresLetraSecundario, fontSize: 9, fontStyle: 'italic', marginBottom: -10 }}>Comentário Interno</p>
                        </div>}
                        {/*   <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginBottom: 20, marginTop: 10 }} key={item.key}>
                          < TextMensagem corLetraDinamica={this.props.coresLetraPadrao} >
                              {item.description}
                          </TextMensagem>
                      </div> */}
                    </TimelineItem>
                )
            }
            else if (item.description.includes(`${item.title} Alterou o cliente de`)) {
                return (
                    <TimelineItem
                        key={item.key}
                        dateText={item.dataHoraAtual}
                        dateInnerStyle={{ background: item.circleColor, color: '#000' }}
                        dateStyle={{ textAlign: 'center' }}
                        style={{ color: item.circleColor }}
                        bodyContainerStyle={{
                            background: this.props.backgroundSegundo,
                            padding: '10px',
                            borderRadius: '8px',
                            boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                            width: "100%"
                        }}
                    >
                        <SystemMessage
                            text={`${item.description}`} />
                        {item.visivelATodos == 0 && <div style={{ alignItems: 'flex-end', justifyContent: 'flex-end', display: 'flex', flexDirection: 'column' }}>
                            <p style={{ color: this.props.coresLetraSecundario, fontSize: 9, fontStyle: 'italic', marginBottom: -10 }}>Comentário Interno</p>
                        </div>}
                        {/*   <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginBottom: 20, marginTop: 10 }} key={item.key}>
                          < TextMensagem corLetraDinamica={this.props.coresLetraPadrao} >
                              {item.description}
                          </TextMensagem>
                      </div> */}
                    </TimelineItem>
                )
            }

            else if (item.description.includes(`${item.title} Alterou o atendente de`)) {
                return (
                    <TimelineItem
                        key={item.key}
                        dateText={item.dataHoraAtual}
                        dateInnerStyle={{ background: item.circleColor, color: '#000' }}
                        dateStyle={{ textAlign: 'center' }}
                        style={{ color: item.circleColor }}
                        bodyContainerStyle={{
                            background: this.props.backgroundSegundo,
                            padding: '10px',
                            borderRadius: '8px',
                            boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                            width: "100%"
                        }}
                    >
                        <SystemMessage
                            text={`${item.description}`} />
                        {item.visivelATodos == 0 && <div style={{ alignItems: 'flex-end', justifyContent: 'flex-end', display: 'flex', flexDirection: 'column' }}>
                            <p style={{ color: this.props.coresLetraSecundario, fontSize: 9, fontStyle: 'italic', marginBottom: -10 }}>Comentário Interno</p>
                        </div>}
                        {/*   <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginBottom: 20, marginTop: 10 }} key={item.key}>
                          < TextMensagem corLetraDinamica={this.props.coresLetraPadrao} >
                              {item.description}
                          </TextMensagem>
                      </div> */}
                    </TimelineItem>
                )
            }

            else if (item.description.includes(`${item.title} finalizou o atendimento.`)) {
                return (
                    <TimelineItem
                        key={item.key}
                        dateText={item.dataHoraAtual}
                        dateInnerStyle={{ background: item.circleColor, color: '#000' }}
                        dateStyle={{ textAlign: 'center' }}
                        style={{ color: item.circleColor }}
                        bodyContainerStyle={{
                            background: this.props.backgroundSegundo,
                            padding: '10px',
                            borderRadius: '8px',
                            boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                            width: "100%"
                        }}
                    >
                        <SystemMessage
                            text={`${item.title} Finalizou o Atendimento.`} />
                        {item.visivelATodos == 0 && <div style={{ alignItems: 'flex-end', justifyContent: 'flex-end', display: 'flex', flexDirection: 'column' }}>
                            <p style={{ color: this.props.coresLetraSecundario, fontSize: 9, fontStyle: 'italic', marginBottom: -10 }}>Comentário Interno</p>
                        </div>}
                        {/*   <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginBottom: 20, marginTop: 10 }} key={item.key}>
                          < TextMensagem corLetraDinamica={this.props.coresLetraPadrao} >
                              {item.description}
                          </TextMensagem>
                </div> */}
                    </TimelineItem>
                )
            }
            else if (item.description.includes(`${item.title} encerrou o chat.`)) {
                return (
                    <TimelineItem
                        key={item.key}
                        dateText={item.dataHoraAtual}
                        dateInnerStyle={{ background: item.circleColor, color: '#000' }}
                        dateStyle={{ textAlign: 'center' }}
                        style={{ color: item.circleColor }}
                        bodyContainerStyle={{
                            background: this.props.backgroundSegundo,
                            padding: '10px',
                            borderRadius: '8px',
                            boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                            width: "100%"
                        }}
                    >
                        <SystemMessage
                            text={`${item.description}`} />
                        {item.visivelATodos == 0 && <div style={{ alignItems: 'flex-end', justifyContent: 'flex-end', display: 'flex', flexDirection: 'column' }}>
                            <p style={{ color: this.props.coresLetraSecundario, fontSize: 9, fontStyle: 'italic', marginBottom: -10 }}>Comentário Interno</p>
                        </div>}
                        {/*   <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginBottom: 20, marginTop: 10 }} key={item.key}>
                          < TextMensagem corLetraDinamica={this.props.coresLetraPadrao} >
                              {item.description}
                          </TextMensagem>
                </div> */}
                    </TimelineItem>
                )
            }
            else if (item.description.includes(`${item.title} Cancelou este atendimento`)) {
                return (
                    <TimelineItem
                        key={item.key}
                        dateText={item.dataHoraAtual}
                        dateInnerStyle={{ background: item.circleColor, color: '#000' }}
                        dateStyle={{ textAlign: 'center' }}
                        style={{ color: item.circleColor }}
                        bodyContainerStyle={{
                            background: this.props.backgroundSegundo,
                            padding: '10px',
                            borderRadius: '8px',
                            boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                            width: "100%"
                        }}
                    >
                        <SystemMessage
                            text={`${item.description}`} />
                        {item.visivelATodos == 0 && <div style={{ alignItems: 'flex-end', justifyContent: 'flex-end', display: 'flex', flexDirection: 'column' }}>
                            <p style={{ color: this.props.coresLetraSecundario, fontSize: 9, fontStyle: 'italic', marginBottom: -10 }}>Comentário Interno</p>
                        </div>}
                        {/*   <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginBottom: 20, marginTop: 10 }} key={item.key}>
                          < TextMensagem corLetraDinamica={this.props.coresLetraPadrao} >
                              {item.description}
                          </TextMensagem>
                </div> */}
                    </TimelineItem>
                )
            }
            else if (item.description.includes(`${item.title} Alterou o local do atendimento de`)) {
                return (
                    <TimelineItem
                        key={item.key}
                        dateText={item.dataHoraAtual}
                        dateInnerStyle={{ background: item.circleColor, color: '#000' }}
                        dateStyle={{ textAlign: 'center' }}
                        style={{ color: item.circleColor }}
                        bodyContainerStyle={{
                            background: this.props.backgroundSegundo,
                            padding: '10px',
                            borderRadius: '8px',
                            boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                            width: "100%"
                        }}
                    >
                        <SystemMessage
                            text={`${item.description}`} />
                        {item.visivelATodos == 0 && <div style={{ alignItems: 'flex-end', justifyContent: 'flex-end', display: 'flex', flexDirection: 'column' }}>
                            <p style={{ color: this.props.coresLetraSecundario, fontSize: 9, fontStyle: 'italic', marginBottom: -10 }}>Comentário Interno</p>
                        </div>}
                        {/*   <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginBottom: 20, marginTop: 10 }} key={item.key}>
                          < TextMensagem corLetraDinamica={this.props.coresLetraPadrao} >
                              {item.description}
                          </TextMensagem>
                      </div> */}
                    </TimelineItem>
                )
            }
            else if (item.description.includes(`${item.title} Alterou a descrição do problema de`)) {
                return (
                    <TimelineItem
                        key={item.key}
                        dateText={item.dataHoraAtual}
                        dateInnerStyle={{ background: item.circleColor, color: '#000' }}
                        dateStyle={{ textAlign: 'center' }}
                        style={{ color: item.circleColor }}
                        bodyContainerStyle={{
                            background: this.props.backgroundSegundo,
                            padding: '10px',
                            borderRadius: '8px',
                            boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                            width: "100%"
                        }}
                    >
                        <SystemMessage
                            text={`${item.description}`} />
                        {item.visivelATodos == 0 && <div style={{ alignItems: 'flex-end', justifyContent: 'flex-end', display: 'flex', flexDirection: 'column' }}>
                            <p style={{ color: this.props.coresLetraSecundario, fontSize: 9, fontStyle: 'italic', marginBottom: -10 }}>Comentário Interno</p>
                        </div>}
                        {/*   <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginBottom: 20, marginTop: 10 }} key={item.key}>
                          < TextMensagem corLetraDinamica={this.props.coresLetraPadrao} >
                              {item.description}
                          </TextMensagem>
                      </div> */}
                    </TimelineItem>
                )
            }
            else if (item.description.includes(`Alterou a data de vencimento de`)) {
                return (
                    <TimelineItem
                        key={item.key}
                        dateText={item.dataHoraAtual}
                        dateInnerStyle={{ background: item.circleColor, color: '#000' }}
                        dateStyle={{ textAlign: 'center' }}
                        style={{ color: item.circleColor }}
                        bodyContainerStyle={{
                            background: this.props.backgroundSegundo,
                            padding: '10px',
                            borderRadius: '8px',
                            boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                            width: "100%"
                        }}
                    >
                        <SystemMessage
                            text={`${item.description}.`} />
                        {item.visivelATodos == 0 && <div style={{ alignItems: 'flex-end', justifyContent: 'flex-end', display: 'flex', flexDirection: 'column' }}>
                            <p style={{ color: this.props.coresLetraSecundario, fontSize: 9, fontStyle: 'italic', marginBottom: -10 }}>Comentário Interno</p>
                        </div>}
                        {/*   <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginBottom: 20, marginTop: 10 }} key={item.key}>
                          < TextMensagem corLetraDinamica={this.props.coresLetraPadrao} >
                              {item.description}
                          </TextMensagem>
                </div> */}
                    </TimelineItem>
                )
            } else if (item.description.includes(`Alterou prioridade do atendimento de`)) {
                return (
                    <TimelineItem
                        key={item.key}
                        dateText={item.dataHoraAtual}
                        dateInnerStyle={{ background: item.circleColor, color: '#000' }}
                        dateStyle={{ textAlign: 'center' }}
                        style={{ color: item.circleColor }}
                        bodyContainerStyle={{
                            background: this.props.backgroundSegundo,
                            padding: '10px',
                            borderRadius: '8px',
                            boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                            width: "100%"
                        }}
                    >
                        <SystemMessage
                            text={`${item.description}.`} />
                        {item.visivelATodos == 0 && <div style={{ alignItems: 'flex-end', justifyContent: 'flex-end', display: 'flex', flexDirection: 'column' }}>
                            <p style={{ color: this.props.coresLetraSecundario, fontSize: 9, fontStyle: 'italic', marginBottom: -10 }}>Comentário Interno</p>
                        </div>}
                        {/*   <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginBottom: 20, marginTop: 10 }} key={item.key}>
                          < TextMensagem corLetraDinamica={this.props.coresLetraPadrao} >
                              {item.description}
                          </TextMensagem>
                </div> */}
                    </TimelineItem>
                )
            }
            else if (item.description.includes(`Atendimento REDIRECIONADO para espera devido ao cliente`) || item.description.includes(`Atendimento redirecionado devido o cliente `)) {
                return (
                    <TimelineItem
                        key={item.key}
                        dateText={item.dataHoraAtual}
                        dateInnerStyle={{ background: item.circleColor, color: '#000' }}
                        dateStyle={{ textAlign: 'center' }}
                        style={{ color: item.circleColor }}
                        bodyContainerStyle={{
                            background: this.props.backgroundSegundo,
                            padding: '10px',
                            borderRadius: '8px',
                            boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                            width: "100%"
                        }}
                    >
                        <SystemMessage
                            text={item.description} />
                        {item.visivelATodos == 0 && <div style={{ alignItems: 'flex-end', justifyContent: 'flex-end', display: 'flex', flexDirection: 'column' }}>
                            <p style={{ color: this.props.coresLetraSecundario, fontSize: 9, fontStyle: 'italic', marginBottom: -10 }}>Comentário Interno</p>
                        </div>}
                    </TimelineItem>
                )
            } else if (item.description.includes(`Atendimento Finalizado Devido á Suspeita de Looping de Bot`)) {
                return (
                    <TimelineItem
                        key={item.key}
                        dateText={item.dataHoraAtual}
                        dateInnerStyle={{ background: item.circleColor, color: '#000' }}
                        dateStyle={{ textAlign: 'center' }}
                        style={{ color: item.circleColor }}
                        bodyContainerStyle={{
                            background: this.props.backgroundSegundo,
                            padding: '10px',
                            borderRadius: '8px',
                            boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                            width: "100%"
                        }}
                    >
                        <SystemMessage
                            text={item.description} />
                        {item.visivelATodos == 0 && <div style={{ alignItems: 'flex-end', justifyContent: 'flex-end', display: 'flex', flexDirection: 'column' }}>
                            <p style={{ color: this.props.coresLetraSecundario, fontSize: 9, fontStyle: 'italic', marginBottom: -10 }}>Comentário Interno</p>
                        </div>}
                    </TimelineItem>
                )
            }

            else {

                return (
                    <TimelineItem
                        key={item.key}
                        dateText={item.dataHoraAtual}
                        dateInnerStyle={{ background: item.circleColor, color: '#000' }}
                        dateStyle={{ textAlign: 'center' }}
                        style={{ color: item.circleColor }}
                        bodyContainerStyle={{
                            background: this.props.backgroundSegundo,
                            padding: '10px',
                            borderRadius: '8px',
                            boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                            width: "100%"
                        }}
                    >

                        <MessageBox
                            {...item}
                            id={item.key}
                            reply={this.renderMensagemReply(item)}
                            /*   ref={ref => { this.refsArray[item.key] = ref }} */
                            onReplyMessageClick={(e) => { this.renderClickButtonReply(item, e) }}
                            onReplyClick={() => { this.renderResponderMensagem(item) }}
                            onRemoveMessageClick={() => { this.setState({ modalMensagemExcluida: true, dadosMensagemExcluida: item }) }}

                            //onClick={() => {  }}
                            //onOpen={() => {  }}
                            position={item.circleColor === '#00FF7F' ? "left" : "right"}
                            focus={item.idMensagem === this.state.focusMessageBox ? true : false}
                            onMessageFocused={() => { setTimeout(() => { ; this.setState({ focusMessageBox: 'vazio' }); }, 1000); }}
                            type={'text'}
                            title={(
                                <div
                                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                >
                                    {item.title}
                                    {this.state.visualizaMensagemExcluida === true && item.deleteMessage === true && <span onClick={() => { this.setState({ visualizaMensagemExcluida: false }) }} style={{ color: '#EC6A69', fontSize: 10, textDecorationLine: 'underline', marginLeft: 10, fontStyle: 'italic', cursor: "pointer" }}>Ocultar</span>}
                                </div>
                            )}
                            titleColor={item.circleColor === '#00FF7F' ? '#16cb9c' : "#39a3f4"}
                            text={
                                (
                                    <Linkify
                                        componentDecorator={(decoratedHref, decoratedText, key) => (
                                            <a style={{ fontSize: 15, color: '#39a3f4' }} target="blank" href={decoratedHref} key={key}>
                                                {decoratedText}
                                            </a>
                                        )}
                                    >
                                        {item.description}
                                    </Linkify>
                                )
                            }
                            copiableDate
                            dateString={item.time}
                            forwarded={false}
                            replyButton={this.renderReplyButton(item)}
                            removeButton={this.renderRemoveButton(item)}
                            status={false}
                        //status={this.renderStatusMessageBox(item)} //(waiting, sent, received, read)
                        // focus
                        //  data={{
                        //   uri: 'https://facebook.github.io/react/img/logo.svg',
                        //    status: {
                        //        click: false,
                        //        loading: 0,
                        //    }
                        // }}
                        />

                        {item.visivelATodos == 0 && <div style={{ alignItems: 'flex-end', justifyContent: 'flex-end', display: 'flex', flexDirection: 'column' }}>
                            <p style={{ color: this.props.coresLetraSecundario, fontSize: 9, fontStyle: 'italic', marginBottom: -10 }}>Comentário Interno</p>
                        </div>}

                    </TimelineItem>

                )

            }
        } else {
            //let tipoArquivo = rowData.description.split('.').pop().toLowerCase();
            if (item.tipoArquivo != undefined && item.tipoArquivo != null && item.tipoArquivo != '') {
                if (item.tipoArquivo.includes('image') && !item.tipoArquivo.includes('corel')) {
                    if (item.urlDownload == undefined || item.urlDownload == null || item.urlDownload == '') {
                        //tenho que buscar a url de download no firebase
                        //
                        //
                        return (
                            <TimelineItem
                                key={item.key}
                                dateText={item.dataHoraAtual}
                                dateInnerStyle={{ background: item.circleColor, color: '#000' }}
                                dateStyle={{ textAlign: 'center' }}
                                style={{ color: item.circleColor }}
                                bodyContainerStyle={{
                                    background: this.props.backgroundSegundo,
                                    padding: '10px',
                                    borderRadius: '8px',
                                    boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                                    width: "100%"
                                }}
                            >
                                <MessageBox
                                    {...item}
                                    id={item.key}
                                    reply={this.renderMensagemReply(item)}
                                    data={{
                                        uri: item.urlDownload,
                                        caption: item.captionArquivo,
                                        status: {
                                            click: this.state.nomeFileDownload == item.description ? this.state.clickFileDownload : false,
                                            loading: this.state.nomeFileDownload == item.description ? this.state.loadingFileDownload : 0,
                                            download: this.state.downloadFile,
                                            
                                        },
                                        //size: "100MB",
                                    }}
                                    focus={item.idMensagem === this.state.focusMessageBox ? true : false}
                                    onMessageFocused={() => { setTimeout(() => { ; this.setState({ focusMessageBox: 'vazio' }); }, 1000); }}
                                    onDownload={event => this.baixarArquivo(item.description, item)}
                                    onReplyClick={() => { this.renderResponderMensagem(item) }}
                                    onReplyMessageClick={() => { this.renderClickButtonReply(item) }}
                                    onRemoveMessageClick={() => { this.setState({ modalMensagemExcluida: true, dadosMensagemExcluida: item }) }}

                                    //onClick={() => {  }}
                                    //onOpen={() => {  }}
                                    position={item.circleColor === '#00FF7F' ? "left" : "right"}
                                    type={'file'}

                                    title={(
                                        <div
                                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                        >
                                            {item.title}
                                            {this.state.visualizaMensagemExcluida === true && item.deleteMessage === true && <span onClick={() => { this.setState({ visualizaMensagemExcluida: false }) }} style={{ color: '#EC6A69', fontSize: 10, textDecorationLine: 'underline', marginLeft: 10, fontStyle: 'italic', cursor: "pointer" }}>Ocultar</span>}
                                        </div>
                                    )}
                                    titleColor={item.circleColor === '#00FF7F' ? '#16cb9c' : "#39a3f4"}
                                    text={item.description}
                                    copiableDate
                                    dateString={item.time}
                                    forwarded={false}
                                    replyButton={this.renderReplyButton(item)}
                                    removeButton={this.renderRemoveButton(item)}
                                    status={false}
                                //status={this.renderStatusMessageBox(item)} //(waiting, sent, received, read)

                                />
                            </TimelineItem>
                        )



                    } else {
                        //tenho a URL salva, somente renderizar
                        //
                        //

                        return (
                            <TimelineItem
                                key={item.key}
                                dateText={item.dataHoraAtual}
                                dateInnerStyle={{ background: item.circleColor, color: '#000' }}
                                dateStyle={{ textAlign: 'center' }}
                                style={{ color: item.circleColor }}
                                bodyContainerStyle={{
                                    background: this.props.backgroundSegundo,
                                    padding: '10px',
                                    borderRadius: '8px',
                                    boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                                    width: "100%"
                                }}
                            >
                                <MessageBox
                                    {...item}
                                    id={item.key}
                                    reply={this.renderMensagemReply(item)}

                                    data={{
                                        uri: item.urlDownload,
                                        status: {
                                            click: true,
                                            // loading: 1,
                                            download: true
                                        },
                                        width: 300,
                                        height: 300,
                                    }}
                                    //onDownload={event => this.baixarArquivo(item.description, item)}
                                    onReplyMessageClick={() => { this.renderClickButtonReply(item) }}
                                    onReplyClick={() => { this.renderResponderMensagem(item) }}
                                    onRemoveMessageClick={() => { this.setState({ modalMensagemExcluida: true, dadosMensagemExcluida: item }) }}

                                    /*     onClick={(event) => {  this.downloadImage(item.urlDownload, item.description);   }} */
                                    onOpen={() => { this.downloadImage(item.urlDownload, item.description); }}
                                    position={item.circleColor === '#00FF7F' ? "left" : "right"}
                                    type={'photo'}
                                    title={(
                                        <div
                                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                        >
                                            {item.title}
                                            {this.state.visualizaMensagemExcluida === true && item.deleteMessage === true && <span onClick={() => { this.setState({ visualizaMensagemExcluida: false }) }} style={{ color: '#EC6A69', fontSize: 10, textDecorationLine: 'underline', marginLeft: 10, fontStyle: 'italic', cursor: "pointer" }}>Ocultar</span>}
                                        </div>
                                    )}
                                    titleColor={item.circleColor === '#00FF7F' ? '#16cb9c' : "#39a3f4"}
                                    text={item.captionArquivo}
                                    focus={item.idMensagem === this.state.focusMessageBox ? true : false}
                                    onMessageFocused={() => { setTimeout(() => { ; this.setState({ focusMessageBox: 'vazio' }); }, 1000); }}
                                    copiableDate
                                    dateString={item.time}
                                    forwarded={false}
                                    replyButton={this.renderReplyButton(item)}
                                    removeButton={this.renderRemoveButton(item)}
                                    status={false}
                                //status={this.renderStatusMessageBox(item)} //(waiting, sent, received, read)

                                />
                            </TimelineItem>
                        )


                    }

                } else if (item.tipoArquivo.includes('video') && item.audio !== true || item.tipoArquivo.includes('mp4') && item.audio !== true) {
                    if (item.urlDownload == undefined || item.urlDownload == null || item.urlDownload == '') {
                        //tenho que buscar a url de download no firebase
                        //
                        //
                        return (
                            <TimelineItem
                                key={item.key}
                                dateText={item.dataHoraAtual}
                                dateInnerStyle={{ background: item.circleColor, color: '#000' }}
                                dateStyle={{ textAlign: 'center' }}
                                style={{ color: item.circleColor }}
                                bodyContainerStyle={{
                                    background: this.props.backgroundSegundo,
                                    padding: '10px',
                                    borderRadius: '8px',
                                    boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                                    width: "100%"
                                }}
                            >
                                <MessageBox
                                    {...item}
                                    id={item.key}
                                    reply={this.renderMensagemReply(item)}
                                    data={{
                                        uri: item.urlDownload,
                                        caption: item.captionArquivo,
                                        status: {
                                            click: this.state.nomeFileDownload == item.description ? this.state.clickFileDownload : false,
                                            loading: this.state.nomeFileDownload == item.description ? this.state.loadingFileDownload : 0,
                                            download: this.state.downloadFile,
                                        },
                                        //size: "100MB",
                                    }}
                                    focus={item.idMensagem === this.state.focusMessageBox ? true : false}
                                    onMessageFocused={() => { setTimeout(() => { ; this.setState({ focusMessageBox: 'vazio' }); }, 1000); }}
                                    onDownload={event => this.baixarArquivo(item.description, item)}
                                    onReplyClick={() => { this.renderResponderMensagem(item) }}
                                    onReplyMessageClick={() => { this.renderClickButtonReply(item) }}
                                    onRemoveMessageClick={() => { this.setState({ modalMensagemExcluida: true, dadosMensagemExcluida: item }) }}

                                    //onClick={() => {  }}
                                    //onOpen={() => {  }}
                                    position={item.circleColor === '#00FF7F' ? "left" : "right"}
                                    type={'file'}

                                    title={(
                                        <div
                                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                        >
                                            {item.title}
                                            {this.state.visualizaMensagemExcluida === true && item.deleteMessage === true && <span onClick={() => { this.setState({ visualizaMensagemExcluida: false }) }} style={{ color: '#EC6A69', fontSize: 10, textDecorationLine: 'underline', marginLeft: 10, fontStyle: 'italic', cursor: "pointer" }}>Ocultar</span>}
                                        </div>
                                    )}
                                    titleColor={item.circleColor === '#00FF7F' ? '#16cb9c' : "#39a3f4"}
                                    text={item.description}
                                    copiableDate
                                    dateString={item.time}
                                    forwarded={false}
                                    replyButton={this.renderReplyButton(item)}
                                    removeButton={this.renderRemoveButton(item)}
                                    status={false}
                                //status={this.renderStatusMessageBox(item)}//(waiting, sent, received, read)

                                />
                            </TimelineItem>
                        )



                    } else {
                        //tenho a URL salva, somente renderizar
                        //
                        //

                        return (
                            <TimelineItem
                                key={item.key}
                                dateText={item.dataHoraAtual}
                                dateInnerStyle={{ background: item.circleColor, color: '#000' }}
                                dateStyle={{ textAlign: 'center' }}
                                style={{ color: item.circleColor }}
                                bodyContainerStyle={{
                                    background: this.props.backgroundSegundo,
                                    padding: '10px',
                                    borderRadius: '8px',
                                    boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                                    width: "100%"
                                }}
                            >
                                <MessageBox
                                    {...item}
                                    id={item.key}
                                    reply={this.renderMensagemReply(item)}

                                    data={{
                                        videoURL: item.urlDownload,
                                        status: {
                                            click: true,
                                            // loading: 1,
                                            download: true
                                        },
                                        width: 300,
                                        height: 300,
                                    }}
                                    focus={item.idMensagem === this.state.focusMessageBox ? true : false}
                                    onMessageFocused={() => { setTimeout(() => { ; this.setState({ focusMessageBox: 'vazio' }); }, 1000); }}
                                    //onDownload={event => this.baixarArquivo(item.description, item)}
                                    onReplyMessageClick={() => { this.renderClickButtonReply(item) }}
                                    onReplyClick={() => { this.renderResponderMensagem(item) }}
                                    onRemoveMessageClick={() => { this.setState({ modalMensagemExcluida: true, dadosMensagemExcluida: item }) }}

                                    /* onClick={(event) => {  this.downloadImage(item.urlDownload, item.description);  }} */
                                    onOpen={() => { this.downloadImage(item.urlDownload, item.description); }}
                                    position={item.circleColor === '#00FF7F' ? "left" : "right"}
                                    type={'video'}
                                    title={(
                                        <div
                                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                        >
                                            {item.title}
                                            {this.state.visualizaMensagemExcluida === true && item.deleteMessage === true && <span onClick={() => { this.setState({ visualizaMensagemExcluida: false }) }} style={{ color: '#EC6A69', fontSize: 10, textDecorationLine: 'underline', marginLeft: 10, fontStyle: 'italic', cursor: "pointer" }}>Ocultar</span>}
                                        </div>
                                    )}
                                    titleColor={item.circleColor === '#00FF7F' ? '#16cb9c' : "#39a3f4"}
                                    text={item.captionArquivo}
                                    copiableDate
                                    dateString={item.time}
                                    forwarded={false}
                                    replyButton={this.renderReplyButton(item)}
                                    removeButton={this.renderRemoveButton(item)}
                                    status={false}
                                //status={this.renderStatusMessageBox(item)}//(waiting, sent, received, read)

                                />
                            </TimelineItem>
                        )


                    }

                }
                else if (item.audio == true) {
                    return (
                        <TimelineItem
                            key={item.key}
                            dateText={item.dataHoraAtual}
                            dateInnerStyle={{ background: item.circleColor, color: '#000' }}
                            dateStyle={{ textAlign: 'center' }}
                            style={{ color: item.circleColor }}
                            bodyContainerStyle={{
                                background: this.props.backgroundSegundo,
                                padding: '10px',
                                borderRadius: '8px',
                                boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                                width: "100%"
                            }}
                        >
                            <MessageBox
                                {...item}
                                id={item.key}
                                reply={this.renderMensagemReply(item)}
                                /*  data={{
                                     uri: item.urlDownload,
                                     status: {
                                         click: false,
                                         loading: 0,
                                     }
                                 }} */
                                focus={item.idMensagem === this.state.focusMessageBox ? true : false}
                                onMessageFocused={() => { setTimeout(() => { ; this.setState({ focusMessageBox: 'vazio' }); }, 1000); }}
                                onReplyMessageClick={() => { this.renderClickButtonReply(item) }}
                                onReplyClick={() => { this.renderResponderMensagem(item) }}
                                onRemoveMessageClick={() => { this.setState({ modalMensagemExcluida: true, dadosMensagemExcluida: item }) }}

                                //onClick={() => {  }}
                                //onOpen={() => {  }}
                                position={item.circleColor === '#00FF7F' ? "left" : "right"}
                                type={'text'}
                                title={(
                                    <div
                                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                    >
                                        {item.title}
                                        {this.state.visualizaMensagemExcluida === true && item.deleteMessage === true && <span onClick={() => { this.setState({ visualizaMensagemExcluida: false }) }} style={{ color: '#EC6A69', fontSize: 10, textDecorationLine: 'underline', marginLeft: 10, fontStyle: 'italic', cursor: "pointer" }}>Ocultar</span>}
                                    </div>
                                )}
                                titleColor={item.circleColor === '#00FF7F' ? '#16cb9c' : "#39a3f4"}
                                text={
                                    (
                                        <audio style={{ height: 60, width: 250 }} src={item.urlDownload} controls />
                                        /*   <AudioPlayer
                                              src={item.urlDownload}
                                              preload='auto'
                                              showSkipControls={false}
                                              showJumpControls={false}
                                              layout={'horizontal-reverse'}
                                              defaultDuration={this.millisToMinutesAndSeconds(item.duracao)}
                                              customControlsSection={
                                                  [
                                                      RHAP_UI.MAIN_CONTROLS,
                                                  ]
                                              }
                                              style={{
                                                  width: '300px',
                                                  borderRadius: 10,
                                                  background: '#E9E9E9'
                                              }}
                                          // other props here
                                          /> */
                                    )
                                }
                                copiableDate
                                dateString={item.time}
                                forwarded={false}
                                replyButton={this.renderReplyButton(item)}
                                removeButton={this.renderRemoveButton(item)}
                                status={false}
                            //status={this.renderStatusMessageBox(item)} //(waiting, sent, received, read)

                            />
                        </TimelineItem>
                    )

                } else {
                    //
                    return (
                        <TimelineItem
                            key={item.key}
                            dateText={item.dataHoraAtual}
                            dateInnerStyle={{ background: item.circleColor, color: '#000' }}
                            dateStyle={{ textAlign: 'center' }}
                            style={{ color: item.circleColor }}
                            bodyContainerStyle={{
                                background: this.props.backgroundSegundo,
                                padding: '10px',
                                borderRadius: '8px',
                                boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                                width: "100%"
                            }}
                        >
                            <MessageBox
                                {...item}
                                id={item.key}
                                reply={this.renderMensagemReply(item)}
                                data={{
                                    uri: item.urlDownload,
                                    caption: item.captionArquivo,
                                    status: {
                                        click: this.state.nomeFileDownload == item.description ? this.state.clickFileDownload : false,
                                        loading: this.state.nomeFileDownload == item.description ? this.state.loadingFileDownload : 0,
                                        download: this.state.downloadFile,
                                    },
                                    //size: "100MB",
                                }}
                                focus={item.idMensagem === this.state.focusMessageBox ? true : false}
                                onMessageFocused={() => { setTimeout(() => { ; this.setState({ focusMessageBox: 'vazio' }); }, 1000); }}
                                onDownload={event => this.baixarArquivo(item.description, item)}
                                onReplyClick={() => { this.renderResponderMensagem(item) }}
                                onReplyMessageClick={() => { this.renderClickButtonReply(item) }}
                                onRemoveMessageClick={() => { this.setState({ modalMensagemExcluida: true, dadosMensagemExcluida: item }) }}

                                //onClick={() => {  }}
                                //onOpen={() => {  }}
                                position={item.circleColor === '#00FF7F' ? "left" : "right"}
                                type={'file'}

                                title={(
                                    <div
                                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                    >
                                        {item.title}
                                        {this.state.visualizaMensagemExcluida === true && item.deleteMessage === true && <span onClick={() => { this.setState({ visualizaMensagemExcluida: false }) }} style={{ color: '#EC6A69', fontSize: 10, textDecorationLine: 'underline', marginLeft: 10, fontStyle: 'italic', cursor: "pointer" }}>Ocultar</span>}
                                    </div>
                                )}
                                titleColor={item.circleColor === '#00FF7F' ? '#16cb9c' : "#39a3f4"}
                                text={item.description}
                                copiableDate
                                dateString={item.time}
                                forwarded={false}
                                replyButton={this.renderReplyButton(item)}
                                removeButton={this.renderRemoveButton(item)}
                                status={false}
                            //status={this.renderStatusMessageBox(item)} //(waiting, sent, received, read)

                            />
                        </TimelineItem>
                    )

                }
            } else {
                if (item.tipo === 'location') {
                    let dados = item.description.split(" - ");
                    /*

                      */
                    return (
                        <TimelineItem
                            key={item.key}
                            dateText={item.dataHoraAtual}
                            dateInnerStyle={{ background: item.circleColor, color: '#000' }}
                            dateStyle={{ textAlign: 'center' }}
                            style={{ color: item.circleColor }}
                            bodyContainerStyle={{
                                background: this.props.backgroundSegundo,
                                padding: '10px',
                                borderRadius: '8px',
                                boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                                width: "100%"
                            }}
                        >
                            <MessageBox

                                {...item}
                                id={item.key}
                                apiKey="AIzaSyDz1f3-p7gxOvHjVYnmt23VHuLCbZ0TS6s"
                                reply={this.renderMensagemReply(item)}
                                data={{
                                    // uri: item.urlDownload,
                                    /* status: {
                                        click: this.state.nomeFileDownload == item.description ? this.state.clickFileDownload : false,
                                        loading: this.state.nomeFileDownload == item.description ? this.state.loadingFileDownload : 0,
                                        download: this.state.downloadFile
                                    }, */
                                    // size: "100MB",
                                    latitude: dados[0],
                                    longitude: dados[1],

                                }}
                                focus={item.idMensagem === this.state.focusMessageBox ? true : false}
                                onMessageFocused={() => { setTimeout(() => { ; this.setState({ focusMessageBox: 'vazio' }); }, 1000); }}
                                onReplyClick={() => { this.renderResponderMensagem(item) }}
                                onReplyMessageClick={() => { this.renderClickButtonReply(item) }}
                                onRemoveMessageClick={() => { this.setState({ modalMensagemExcluida: true, dadosMensagemExcluida: item }) }}

                                //onClick={() => {  }}
                                //onOpen={() => {  }}
                                position={item.circleColor === '#00FF7F' ? "left" : "right"}
                                type={'location'}
                                /*  onDownload={event => this.baixarArquivo(item.description, item)} */

                                title={(
                                    <div
                                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                    >
                                        {item.title}
                                        {this.state.visualizaMensagemExcluida === true && item.deleteMessage === true && <span onClick={() => { this.setState({ visualizaMensagemExcluida: false }) }} style={{ color: '#EC6A69', fontSize: 10, textDecorationLine: 'underline', marginLeft: 10, fontStyle: 'italic', cursor: "pointer" }}>Ocultar</span>}
                                    </div>
                                )}
                                titleColor={item.circleColor === '#00FF7F' ? '#16cb9c' : "#39a3f4"}
                                text={''}
                                copiableDate
                                dateString={item.time}
                                forwarded={false}
                                replyButton={this.renderReplyButton(item)}
                                removeButton={this.renderRemoveButton(item)}
                                status={false}
                            //status={this.renderStatusMessageBox(item)}//(waiting, sent, received, read)

                            />
                            {/* < LocationMessage
                                key={item.key}
                                target="_blank"
                                data={{
                                     // latitude: dados[0],
                                    // longitude: dados[1],
                                    latitude: '37.773972',
                                    longitude: '-122.431297',
                                    // staticURL: '<optional>',
                                    // mapURL: '<optional>'
                                }
                                } /> */}
                        </TimelineItem>
                    )
                } if (item.tipo === 'vcard') {
                    //let vcardMEssage2 = vCard.parse(item.description);
                    let card = vCard.parse(item.description);


                    return (
                        <TimelineItem
                            key={item.key}
                            dateText={item.dataHoraAtual}
                            dateInnerStyle={{ background: item.circleColor, color: '#000' }}
                            dateStyle={{ textAlign: 'center' }}
                            style={{ color: item.circleColor }}
                            bodyContainerStyle={{
                                background: this.props.backgroundSegundo,
                                padding: '10px',
                                borderRadius: '8px',
                                boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                                width: "100%"
                            }}
                        >

                            <MessageBox
                                /* avatar={imgTeste} */
                                {...item}
                                id={item.key}
                                reply={this.renderMensagemReply(item)}
                                onReplyClick={() => { this.renderResponderMensagem(item) }}
                                onReplyMessageClick={() => { this.renderClickButtonReply(item) }}

                                focus={item.idMensagem === this.state.focusMessageBox ? true : false}
                                onMessageFocused={() => { setTimeout(() => { ; this.setState({ focusMessageBox: 'vazio' }); }, 1000); }}
                                onRemoveMessageClick={() => { this.setState({ modalMensagemExcluida: true, dadosMensagemExcluida: item }) }}
                                /*  data={{
                                     uri: item.urlDownload,
                                     status: {
                                         click: this.state.nomeFileDownload == item.description ? this.state.clickFileDownload : false,
                                         loading: this.state.nomeFileDownload == item.description ? this.state.loadingFileDownload : 0,
                                         download: this.state.downloadFile
                                     },
                                     size: "100MB",
                                 }} */
                                /*       onReplyMessageClick={() => } */
                                //onClick={() => {  }}
                                //onOpen={() => {  }}
                                position={item.circleColor === '#00FF7F' ? "left" : "right"}
                                type={'text'}
                                /* onDownload={event => this.baixarArquivo(item.description, item)} */

                                title={(
                                    <div
                                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                    >
                                        {item.title}
                                        {this.state.visualizaMensagemExcluida === true && item.deleteMessage === true && <span onClick={() => { this.setState({ visualizaMensagemExcluida: false }) }} style={{ color: '#EC6A69', fontSize: 10, textDecorationLine: 'underline', marginLeft: 10, fontStyle: 'italic', cursor: "pointer" }}>Ocultar</span>}
                                    </div>
                                )}
                                titleColor={item.circleColor === '#00FF7F' ? '#16cb9c' : "#39a3f4"}
                                /* text={(
                                    <p>{card.fn[0].value}</p>
                                )} */
                                text={(
                                    <div>
                                        {card.fn && card.fn.map(function (item, i) {
                                            return (
                                                <p key={i}>{item.value}</p>
                                            )
                                        })}
                                        {card.tel && card.tel.map(function (item, i) {
                                            return <p key={i}>{item.value}</p>
                                        })}
                                    </div>
                                )}
                                copiableDate
                                dateString={item.time}
                                forwarded={false}
                                replyButton={this.renderReplyButton(item)}
                                removeButton={this.renderRemoveButton(item)}
                                status={false}
                            //status={this.renderStatusMessageBox(item)} //(waiting, sent, received, read)

                            />
                        </TimelineItem>
                    )
                } else {
                    return (
                        <TimelineItem
                            key={item.key}
                            dateText={item.dataHoraAtual}
                            dateInnerStyle={{ background: item.circleColor, color: '#000' }}
                            dateStyle={{ textAlign: 'center' }}
                            style={{ color: item.circleColor }}
                            bodyContainerStyle={{
                                background: this.props.backgroundSegundo,
                                padding: '10px',
                                borderRadius: '8px',
                                boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                                width: "100%"
                            }}
                        >
                            <MessageBox
                                {...item}
                                id={item.key}
                                reply={this.renderMensagemReply(item)}
                                data={{
                                    uri: item.urlDownload,
                                    caption: item.captionArquivo,
                                    status: {
                                        click: this.state.nomeFileDownload == item.description ? this.state.clickFileDownload : false,
                                        loading: this.state.nomeFileDownload == item.description ? this.state.loadingFileDownload : 0,
                                        download: this.state.downloadFile,
                                    },
                                    // size: "100MB",
                                }}
                                onRemoveMessageClick={() => { this.setState({ modalMensagemExcluida: true, dadosMensagemExcluida: item }) }}
                                focus={item.idMensagem === this.state.focusMessageBox ? true : false}
                                onMessageFocused={() => { setTimeout(() => { ; this.setState({ focusMessageBox: 'vazio' }); }, 1000); }}
                                onReplyMessageClick={() => { this.renderClickButtonReply(item) }}
                                onReplyClick={() => { this.renderResponderMensagem(item) }}
                                //onClick={() => {  }}
                                //onOpen={() => {  }}
                                position={item.circleColor === '#00FF7F' ? "left" : "right"}
                                type={'file'}

                                onDownload={event => this.baixarArquivo(item.description, item)}

                                title={(
                                    <div
                                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                    >
                                        {item.title}
                                        {this.state.visualizaMensagemExcluida === true && item.deleteMessage === true && <span onClick={() => { this.setState({ visualizaMensagemExcluida: false }) }} style={{ color: '#EC6A69', fontSize: 10, textDecorationLine: 'underline', marginLeft: 10, fontStyle: 'italic', cursor: "pointer" }}>Ocultar</span>}
                                    </div>
                                )}
                                titleColor={item.circleColor === '#00FF7F' ? '#16cb9c' : "#39a3f4"}
                                text={item.description}
                                copiableDate
                                dateString={item.time}
                                forwarded={false}
                                replyButton={this.renderReplyButton(item)}
                                removeButton={this.renderRemoveButton(item)}
                                status={false}
                            //status={this.renderStatusMessageBox(item)} //(waiting, sent, received, read)

                            />
                        </TimelineItem>
                    )
                }

            }
        }


    }
    renderAlteraDescricaoChamadoDigitado() {
        if (this.props.criarChamadoProblemaEstaticoAtendimento == false) {
            return (
                <ContainerInputDescricaoChamado>
                    <strong style={{ color: this.props.corLetraDescricaoDosCampos, fontSize: 14, marginBottom: 2, marginLeft: 5 }}>Descrição do problema:</strong>
                    <InputDescricaoChamado
                        type="textarea"
                        corBordaDinamica={this.props.corBotaoPadrao}
                        onChange={(value) => { this.props.alteraDescricaoChamado(value.target.value) }}
                        corLetraDinamico={this.props.coresLetraPadrao}
                        value={this.props.descricaoAtendimento}
                        corLetraSecundaria={this.props.coresLetraSecundario}
                        placeholder=" Descreva o problema..."
                    />
                </ContainerInputDescricaoChamado>
            )
        } else {
            return null;
        }

    }
    renderTextArquivosMensagemRespondidas(item) {
        if (item.commentResponderType === 'imagem') {
            return <p><i className="icon-imagewpp" style={{ fontSize: 13, color: '#5a5a5a' }} /> Imagem</p>
        } else if (item.commentResponderType === 'video') {
            return <p><i className="icon-video-camera" style={{ fontSize: 13, color: '#5a5a5a' }} /> Vídeo</p>
        } else if (item.commentResponderType === 'location') {
            return <p><i className="icon-localization" style={{ fontSize: 13, color: '#5a5a5a' }} /> Localização</p>
        } else if (item.commentResponderType === 'contato') {
            return <p><i className="icon-man-user" style={{ fontSize: 13, color: '#5a5a5a' }} /> Contato</p>
        } else if (item.commentResponderType === 'audio') {
            return <p><i className="icon-microphone" style={{ fontSize: 13, color: '#5a5a5a' }} /> Mensagem de Voz</p>
        } else if (item.commentResponderType === 'arquivo') {
            return <p><i className="icon-documentwpp" style={{ fontSize: 13, color: '#5a5a5a' }} /> Arquivo</p>
        }
        else if (item.commentResponderType === 'link' && item.commentResponderTypeArquivo.includes('image') && !item.commentResponderTypeArquivo.includes('corel')) {
            return <p><i className="icon-imagewpp" style={{ fontSize: 13, color: '#5a5a5a' }} /> Imagem</p>
        } else if (item.commentResponderType === 'link' && item.commentResponderTypeArquivo.includes('video') && item.commentResponderIsAudio !== true || item.commentResponderTypeArquivo.includes('mp4') && item.commentResponderIsAudio !== true) {
            return <p><i className="icon-video-camera" style={{ fontSize: 13, color: '#5a5a5a' }} /> Vídeo</p>
        }
        else if (item.commentResponderType === 'link' && item.commentResponderIsAudio === true) {
            return <p><i className="icon-microphone" style={{ fontSize: 13, color: '#5a5a5a' }} /> Mensagem de Voz</p>
        }
        else if (item.commentResponderType === 'link' && !item.commentResponderTypeArquivo.includes('image') && !item.commentResponderTypeArquivo.includes('corel') && !item.commentResponderTypeArquivo.includes('video') && item.commentResponderIsAudio === false) {
            return <p><i className="icon-documentwpp" style={{ fontSize: 13, color: '#5a5a5a' }} /> Arquivo</p>
        }
        else {
            return <p>{item.mensagemResposta}</p>
        }
    }
    renderDescriptionMessageBox(item) {


        if (item.title == 'Avaliar Este Atendimento') {

            return (
                <div key={item.key}>

                    <TextMensagem corLetraDinamica='#000'>
                        {item.description}
                    </TextMensagem>
                    <TextAvaliar corPrincipalChat={this.props.corPrincipalChat} onClick={() => {
                        toastr.error('Error', 'Somente cliente pode avaliar o atendimento');
                    }} corLetraDinamica={this.props.coresLetraPadrao}>{item.title}</TextAvaliar>

                </div>
            )


        } else {
            if (item.title.includes('Conversa Iniciada por ')) {
                return null
            } else {
                //onReplyClick={() => { this.renderResponderMensagem(item) }}
                return (
                    <div key={item.key}>
                        <MessageBox

                            {...item}

                            /*   ref={scrollTo} */
                            id={item.key}
                            reply={this.renderMensagemReply(item)}
                            onReplyMessageClick={() => { this.renderClickButtonReply(item) }}
                            onReplyClick={() => { this.renderResponderMensagem(item) }}

                            //onClick={() => {  }}
                            //onOpen={() => {  }}
                            position={item.circleColor === '#00FF7F' ? "left" : "right"}
                            type={'text'}
                            title={(
                                <div
                                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                >
                                    {item.title.includes('Atendimento criado por') ? item.title.replace('Atendimento criado por: ', '') : item.title}
                                    {this.state.visualizaMensagemExcluida === true && item.deleteMessage === true && <span onClick={() => { this.setState({ visualizaMensagemExcluida: false }) }} style={{ color: '#EC6A69', fontSize: 10, textDecorationLine: 'underline', marginLeft: 10, fontStyle: 'italic', cursor: "pointer" }}>Ocultar</span>}
                                </div>
                            )}
                            /*   title={item.title.includes('Atendimento criado por') ? item.title.replace('Atendimento criado por: ', '') : item.title} */
                            titleColor={item.circleColor === '#00FF7F' ? '#16cb9c' : "#39a3f4"}
                            focus={item.idMensagem === this.state.focusMessageBox ? true : false}
                            onMessageFocused={() => { setTimeout(() => { ; this.setState({ focusMessageBox: 'vazio' }); }, 1000); }}
                            onRemoveMessageClick={() => { this.setState({ modalMensagemExcluida: true, dadosMensagemExcluida: item }) }}
                            text={
                                (
                                    <Linkify
                                        componentDecorator={(decoratedHref, decoratedText, key) => (
                                            <a style={{ fontSize: 15, color: '#39a3f4' }} target="blank" href={decoratedHref} key={key}>
                                                {decoratedText}
                                            </a>
                                        )}
                                    >
                                        {item.description}
                                    </Linkify>
                                )
                            }
                            copiableDate
                            dateString={item.time}
                            forwarded={false}
                            replyButton={this.renderReplyButton(item)}
                            removeButton={this.renderRemoveButton(item)}
                            status={false}
                        //status={this.renderStatusMessageBox(item)} //(waiting, sent, received, read)
                        />
                    </div>
                )
            }
        }
    }
    zeraMensagemResponder() {
        this.setState({
            viewResponder: false,
            commentResponder: '',
            idResponder: '',
            circleColorResponder: '',
            commentResponderType: null,
            atendenteResponder: ''
        })
    }
    render() {
        registerLocale('pt-BR', pt);
        setDefaultLocale("pt-BR");
        const CheckBoxWrapper = styled.div`
        position: relative;
        margin-left:10px;
        height: 24px;
        min-height: 24px;
      `;
        const CheckBoxLabel = styled.label`
        position: absolute;
        top: 0;
        left: 0;
        width: 42px;
        height: 23px;
        min-height: 23px;
        border-radius: 15px;
        background: ${this.props.coresLetraSecundario};
        
        cursor: pointer;
        &::after {
          content: "";
          display: block;
          border-radius: 50%;
          width: 18px;
          height: 18px;
          min-height: 18px;
          
          margin: 3px;
          background: #ffffff;
          box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
          transition: 0.2s;
        }
      `;
        const CheckBox = styled.input`
        opacity: 0;
        z-index: 1;
        
        border-radius: 15px;
        width: 42px;
        height: 26px;
        min-height: 26px;
        &:checked + ${CheckBoxLabel} {
          background: #00FF7F;
          &::after {
            content: "";
            display: block;
            border-radius: 50%;
            width: 18px;
            height: 18px;
            min-height: 18px;
            margin-left: 21px;
            transition: 0.2s;
          }
        }
      `;

        return (
            <Container backGroundDinamico={this.props.backgroundPrimeiro}>
                {this.renderLoadingAtendimento()}
                <Modal focusTrapped={false} showCloseIcon={false} open={this.props.modalViewImage} onClose={() => { }} center>
                    <ContainerModalViewImage backgroundDinamico={this.props.backgroundPrimeiro}>
                        <ContainerIconeFecharImagemPreview backgroundDinamico={this.props.backgroundSegundo}>
                            {/*  <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Detalhes do Chamado</TextNovoAtendimento> */}
                            <div>
                                <IconeFecharImagePreview style={{ fontSize: 20 }} backgroundDinamico={this.props.coresLetraPadrao} onClick={() => {

                                    saveAs(this.props.urlPreViewImage, this.props.nameUrlPreview)
                                }} className="icon-download" />
                                <IconeFecharImagePreview style={{ fontSize: 17 }} backgroundDinamico={this.props.coresLetraPadrao} onClick={() => {
                                    this.props.modificaModalPreviewImage(false);
                                    this.props.modificaUrlPreviewImage(null)
                                    this.props.modificaNamePreviewImage('')
                                }} className="icon-close" />
                            </div>
                        </ContainerIconeFecharImagemPreview>
                        <div style={{ display: 'flex', flex: 1, background: this.props.backgroundPrimeiro, alignItems: 'center', justifyContent: 'center', overflow: 'auto' }}>
                            <ImagePreview
                                src={this.props.urlPreViewImage}
                                alt="Imagem"
                                zoomScale={1.5}
                                hideHint
                                hasSpacer
                            />
                        </div>



                    </ContainerModalViewImage>
                </Modal>
                <Modal focusTrapped={false} showCloseIcon={false} open={this.state.modalMensagemExcluida} onClose={() => { }} center>
                    <ContainerModalGravarAudio backgroundDinamico={this.props.backgroundPrimeiro}>
                        <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                            <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Apagar Mensagem</TextNovoAtendimento>
                            <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => {
                                this.setState({ modalMensagemExcluida: false, dadosMensagemExcluida: {} })

                            }} className="icon-close" />
                        </ContainerIconeFecharModal>

                        {/* <Recorder
                            record={false}
                            title={"New recording"}
                            audioURL={this.state.audioDetails.url}
                            showUIAudio
                            hideHeader
                            handleAudioStop={data => this.handleAudioStop(data)}
                            handleOnChange={(value) => this.handleOnChange(value, 'firstname')}
                            handleAudioUpload={data => this.handleAudioUpload(data)}
                            handleRest={() => this.handleRest()}
                            handleAudioStart={() => {}} /> */}
                        <ContainerAtendentes>
                            <DivTextoReabrirAtendimento >
                                <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Deseja realmente Apagar a mensagem?</TextNovoAtendimento>
                            </DivTextoReabrirAtendimento>
                            <DivInputMotivoCancelamento>

                                <ButtonNaoCancelarAtendimento onClick={() => { this.setState({ modalMensagemExcluida: false, dadosMensagemExcluida: {} }) }} borderColorDinamico={this.props.corBotaoPadrao} backgroundBotao={this.props.backgroundPrimeiro}>
                                    <TextBotaoCancelarAtendimento colorDinamico={this.props.corBotaoPadrao}>Não</TextBotaoCancelarAtendimento>
                                </ButtonNaoCancelarAtendimento>
                                <ButtonCancelarAtendimento onClick={() => {


                                    let isLastMessage = this.state.dadosMensagemExcluida.key === this.props.acoesDoAtendimento[0].key ? true : false;
                                    this.props.deletaMensagemDropDesk(this.state.dadosMensagemExcluida, this.props.dadosAtendimentos[0].key, false, [], isLastMessage, this.props.dadosAtendimentos[0])
                                    this.setState({ modalMensagemExcluida: false, dadosMensagemExcluida: {} })

                                }} backgroundBotao={this.props.corBotaoPadrao}>
                                    <TextBotaoCancelarAtendimento colorDinamico={this.props.letraBotaoPadrao}>Sim</TextBotaoCancelarAtendimento>
                                </ButtonCancelarAtendimento>
                            </DivInputMotivoCancelamento>
                        </ContainerAtendentes>


                    </ContainerModalGravarAudio>
                </Modal>
                <Modal focusTrapped={false} showCloseIcon={false} open={this.props.modalAlterarAtendimento} onClose={() => { }} center>
                    <ContainerModalAlterar backgroundDinamico={this.props.backgroundPrimeiro}>
                        <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                            <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Alterar Atendimento</TextNovoAtendimento>
                            <div>
                                <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => { this.props.modificaVisibilidadeModalAlterarAtendimento(false) }} className="icon-close" />
                            </div>
                        </ContainerIconeFecharModal>


                        {/*   <div style={{ display: 'flex', flex: 1, flexDirection: 'column',backgroundColor:'#CCC' }}> */}
                        {this.renderConteudoModalAlteracao()}

                        {/*   {this.props.criarChamadoProblemaEstaticoAtendimento === false && <ContainerInputDescricaoChamado>
                            <strong style={{ color: this.props.corLetraDescricaoDosCampos, fontSize: 14, marginBottom: 2, marginLeft: 5 }}>Descrição do problema:</strong>
                            <InputDescricaoChamado
                                type="textarea"
                                corBordaDinamica={this.props.corBotaoPadrao}
                                onChange={(value) => { this.props.alteraDescricaoChamado(value.target.value) }}
                                corLetraDinamico={this.props.coresLetraPadrao}
                                value={this.props.descricaoAtendimento}
                                corLetraSecundaria={this.props.coresLetraSecundario}
                                placeholder=" Descreva o problema..."
                            />
                        </ContainerInputDescricaoChamado>} */}
                        {this.renderAlteraDescricaoChamadoDigitado()}
                        {this.props.criarChamadoProblemaEstaticoAtendimento == true &&
                            <SubContainerNovoAtendimento style={{ marginTop: 15, marginBottom: 15 }}>
                                <p style={{ marginLeft: 20, color: this.props.corLetraDescricaoDosCampos, fontSize: 14 }}>Problema:</p>
                                <ContainerSelectSearch>
                                    <SelectSearch
                                        renderValue={(valueProps) =>
                                            <DivInputSearch>
                                                <InputSearch
                                                    corLetraDinamico={this.props.coresLetraPadrao}
                                                    backgroundDinamico={this.props.backgroundMaisEscuro}
                                                    placeholderColor={this.props.corLetraSecundaria}
                                                    corBordaDinamica={this.props.corBotaoPadrao}
                                                    {...valueProps} ></InputSearch>
                                                <IconeSetaPraBaixo className="icon-angle-arrow-down" colorDinamico={this.props.coresLetraPadrao} />
                                            </DivInputSearch>
                                        }
                                        value={this.props.problemaEstaticoSelecionadoNovoAtendimento} options={this.props.problemasEstaticos} onChange={(value, option) => { this.props.modificaProblemaEstaticoSelecionadoNovoAtendimento(value); this.props.modificaNomeProblemaEstaticoSelecionadoNovoAtendimento(option.name); }} placeholder="Selecione o Problema" search />

                                </ContainerSelectSearch>
                            </SubContainerNovoAtendimento>
                        }
                        <DivBotaoNovoAtendimento>
                            <BotaoCadastrarAtendimento type="submit" onClick={() => {
                                this.props.alterarChamado(
                                    this.props.clienteSelecionado,
                                    this.props.atendenteSelecionado,
                                    this.props.setorSelecionado,
                                    this.props.prioridadeSelecionada,
                                    this.props.localAtendimentoSelecionado,
                                    this.props.descricaoAtendimento,
                                    this.props.dataVencimento,
                                    this.props.keyAtendimento,
                                    this.props.dadosAtendimentos,
                                    this.state.nomeAuxiliarAtendente,
                                    this.state.nomeAuxiliarCliente,
                                    this.state.nomeAuxiliarSetor,
                                    this.props.keyEmpresaVinculadaAoAbrirAlterarChamado,
                                    this.props.dadosAtendimentos[0].status,
                                    this.props.dadosAtendimentos[0],
                                    this.props.criarChamadoProblemaEstaticoAtendimento,
                                    this.props.problemaEstaticoSelecionadoNovoAtendimento,
                                    this.props.nomeProblemaEstaticoSelecionadoNovoAtendimento,
                                    this.props.dadosAtendimentos[0].descricaoProblemaEstatico,
                                )
                                this.setState({ dataVencimento: '', clientSelected: null })
                            }} backgroundDinamico={this.props.corBotaoPadrao}>
                                <TextCadastrar corLetraDinamico={this.props.letraBotaoPadrao}>Alterar</TextCadastrar>
                            </BotaoCadastrarAtendimento>
                        </DivBotaoNovoAtendimento>
                        {/*  </div> */}
                    </ContainerModalAlterar>
                </Modal>
                <Modal focusTrapped={false} showCloseIcon={false} open={this.props.modalAtribuirAtendimento} onClose={() => { }} center>
                    <ContainerModal backgroundDinamico={this.props.backgroundPrimeiro}>
                        <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                            <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Atribuir Atendimento</TextNovoAtendimento>
                            <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => { this.props.modificaVisibilidadeModalAtribuirAtendimento(false) }} className="icon-close" />
                        </ContainerIconeFecharModal>
                        <ContainerTextCabecalhoModalAcoes>
                            <TextCabecalhoModalAcoes corLetraDinamico={this.props.corBotaoPadrao}>Atribuir atendimento para o Atendente:</TextCabecalhoModalAcoes>
                        </ContainerTextCabecalhoModalAcoes>
                        <ContainerAtendentes>

                            <FlatList
                                list={this.props.atendentesAcoesChamado}
                                renderItem={(item) => this.renderAtendentes(item)}
                                renderWhenEmpty={() => <div>Lista está vazia!</div>}
                            />
                        </ContainerAtendentes>
                    </ContainerModal>
                </Modal>
                <Modal focusTrapped={false} showCloseIcon={false} open={this.props.modalAddTempoGasto} onClose={() => { }} center>
                    <ContainerModal backgroundDinamico={this.props.backgroundPrimeiro}>
                        <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                            <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Adicionar Tempo Gasto</TextNovoAtendimento>
                            <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => { this.props.modificaVisibilidadeModalAddTempoGastoAtendimento(false) }} className="icon-close" />
                        </ContainerIconeFecharModal>
                        <ContainerTextCabecalhoModalAcoes>
                            <TextCabecalhoModalAcoes corLetraDinamico={this.props.corBotaoPadrao}>Selecione o Tempo Gasto no Atendimento:</TextCabecalhoModalAcoes>
                        </ContainerTextCabecalhoModalAcoes>
                        <ContainerAtendentes>

                            <FlatList
                                list={this.state.tempoEspera}
                                renderItem={(item) => this.renderTempoGasto(item)}
                                renderWhenEmpty={() => <div>Lista está vazia!</div>}
                            />
                        </ContainerAtendentes>
                    </ContainerModal>
                </Modal>
                <Modal focusTrapped={false} showCloseIcon={false} open={this.state.modalRespostaPadrao} onClose={() => { }} center>
                    <ContainerModal backgroundDinamico={this.props.backgroundPrimeiro}>
                        <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                            <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Respostas Padrões</TextNovoAtendimento>
                            <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => { this._toggleModalRespostaPadrao() }} className="icon-close" />
                        </ContainerIconeFecharModal>
                        <ContainerTextCabecalhoModalAcoes>
                            <TextCabecalhoModalAcoes corLetraDinamico={this.props.corBotaoPadrao}>Selecione uma resposta para comentar:</TextCabecalhoModalAcoes>
                        </ContainerTextCabecalhoModalAcoes>
                        <ContainerAtendentes>

                            <FlatList
                                list={this.props.respostasPadroesAcoesChamado}
                                renderItem={(item) => this.renderRespostaPadrao(item)}
                                renderWhenEmpty={() => {
                                    return (
                                        <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                                            <MoonLoader
                                                size={40}
                                                color={this.props.corBotaoPadrao}
                                                loading={true}
                                            />
                                        </div>
                                    )
                                }

                                }
                            />
                        </ContainerAtendentes>
                    </ContainerModal>
                </Modal>
                <Modal focusTrapped={false} showCloseIcon={false} open={this.props.modalStatusAtendimento} onClose={() => { }} center>
                    <ContainerModalPrioridadeAtendimento backgroundDinamico={this.props.backgroundPrimeiro}>
                        <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                            <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Fechar Atendimento</TextNovoAtendimento>
                            <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => { this.props.modificaVisibilidadeModalFinalizarAtendimento(false) }} className="icon-close" />
                        </ContainerIconeFecharModal>
                        {this.props.obrigatorioInformarResolucaoChamado === true && <ContainerAtendentes>
                            <ContainerInputEBotaoComent>
                                <DivInputComentarChamado >
                                    <p style={{ color: this.props.coresLetraPadrao }}>Solução:</p>
                                    <InputDescricaoChamado
                                        autoFocus
                                        type="textarea"
                                        onChange={(value) => { this.props.modificaResolucaoProblema(value.target.value) }}
                                        corLetraDinamico={this.props.coresLetraPadrao}
                                        value={this.props.solucaoAtendimento}
                                        corLetraSecundaria={this.props.coresLetraSecundario}
                                        corBordaDinamica={this.props.corBotaoPadrao}
                                        placeholder="Descreva a SOLUÇÃO do atendimento"
                                    />
                                </DivInputComentarChamado>
                            </ContainerInputEBotaoComent>
                            <DivBotaoSalvarComentario>

                                {this.renderBotaoFecharAtendimento()}

                            </DivBotaoSalvarComentario>
                        </ContainerAtendentes>}

                        {this.props.obrigatorioInformarResolucaoChamado === false && <ContainerAtendentes>
                            <DivTextoReabrirAtendimento >
                                <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Deseja realmente Finalizar o atendimento?</TextNovoAtendimento>
                            </DivTextoReabrirAtendimento>
                            <DivInputMotivoCancelamento>

                                <ButtonNaoCancelarAtendimento onClick={() => { this.props.modificaVisibilidadeModalFinalizarAtendimento(false) }} borderColorDinamico={this.props.corBotaoPadrao} backgroundBotao={this.props.backgroundPrimeiro}>
                                    <TextBotaoCancelarAtendimento colorDinamico={this.props.corBotaoPadrao}>Não</TextBotaoCancelarAtendimento>
                                </ButtonNaoCancelarAtendimento>
                                <ButtonCancelarAtendimento onClick={() => {
                                    this.props.modificaVisibilidadeModalFinalizarAtendimento(false); this.props.atribuiStatusAtendimento(this.props.keyAtendimento, 'Fechado', this.props.dadosAtendimentos[0].dataAbertura, this.props.premium, this.props.dadosAtendimentos[0].keyAtendente, this.props.dadosAtendimentos[0], false, false, 'Não informado');
                                }} backgroundBotao={this.props.corBotaoPadrao}>
                                    <TextBotaoCancelarAtendimento colorDinamico={this.props.letraBotaoPadrao}>Sim</TextBotaoCancelarAtendimento>
                                </ButtonCancelarAtendimento>
                            </DivInputMotivoCancelamento>
                        </ContainerAtendentes>}

                    </ContainerModalPrioridadeAtendimento>
                </Modal>
                <Modal focusTrapped={false} showCloseIcon={false} open={this.props.modalPrioridadeAtendimento} onClose={() => { }} center>
                    <ContainerModalPrioridadeAtendimento backgroundDinamico={this.props.backgroundPrimeiro}>
                        <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                            <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Prioridade do Atendimento</TextNovoAtendimento>
                            <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => { this.props.modificaVisibilidadeModalPrioridadeAtendimento(false) }} className="icon-close" />
                        </ContainerIconeFecharModal>
                        <ContainerTextCabecalhoModalAcoes>
                            <TextCabecalhoModalAcoes corLetraDinamico={this.props.corBotaoPadrao}>Selecione a Prioridade do Atendimento:</TextCabecalhoModalAcoes>
                        </ContainerTextCabecalhoModalAcoes>
                        <ContainerAtendentes>
                            <DivAtendentes >
                                <TextAtendentes onClick={() => { this.props.modificaVisibilidadeModalPrioridadeAtendimento(false); this.props.alterarPrioridadeAtendimento(this.props.keyAtendimento, 'Baixa', this.props.dadosAtendimentos[0].prioridade, this.props.dadosAtendimentos[0]); }} corLetraBotaoPadrao={this.props.corBotaoPadrao} corLetraDinamico={this.props.coresLetraPadrao}>Baixa</TextAtendentes>
                            </DivAtendentes>
                            <DivAtendentes >
                                <TextAtendentes onClick={() => { this.props.modificaVisibilidadeModalPrioridadeAtendimento(false); this.props.alterarPrioridadeAtendimento(this.props.keyAtendimento, 'Normal', this.props.dadosAtendimentos[0].prioridade, this.props.dadosAtendimentos[0]); }} corLetraBotaoPadrao={this.props.corBotaoPadrao} corLetraDinamico={this.props.coresLetraPadrao}>Normal</TextAtendentes>
                            </DivAtendentes>
                            <DivAtendentes >
                                <TextAtendentes onClick={() => { this.props.modificaVisibilidadeModalPrioridadeAtendimento(false); this.props.alterarPrioridadeAtendimento(this.props.keyAtendimento, 'Alta', this.props.dadosAtendimentos[0].prioridade, this.props.dadosAtendimentos[0]); }} corLetraBotaoPadrao={this.props.corBotaoPadrao} corLetraDinamico={this.props.coresLetraPadrao}>Alta</TextAtendentes>
                            </DivAtendentes>
                            <DivAtendentes >
                                <TextAtendentes onClick={() => { this.props.modificaVisibilidadeModalPrioridadeAtendimento(false); this.props.alterarPrioridadeAtendimento(this.props.keyAtendimento, 'Urgente', this.props.dadosAtendimentos[0].prioridade, this.props.dadosAtendimentos[0]); }} corLetraBotaoPadrao={this.props.corBotaoPadrao} corLetraDinamico={this.props.coresLetraPadrao}>Urgente</TextAtendentes>
                            </DivAtendentes>
                        </ContainerAtendentes>
                    </ContainerModalPrioridadeAtendimento>
                </Modal>
                <Modal focusTrapped={false} showCloseIcon={false} open={this.props.modalCancelarAtendimento} onClose={() => { }} center>
                    <ContainerModalPrioridadeAtendimento backgroundDinamico={this.props.backgroundPrimeiro}>
                        <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                            <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Cancelar Atendimento</TextNovoAtendimento>
                            <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => { this.props.modificaVisibilidadeModalCancelarAtendimento(false) }} className="icon-close" />
                        </ContainerIconeFecharModal>

                        {this.props.obrigatorioInformarMotivoCancelamento === true && <ContainerAtendentes>
                            <DivInputMotivoCancelamento >
                                <InputCancelamento
                                    corLetraDinamico={this.props.coresLetraPadrao}
                                    autoFocus
                                    type="text"
                                    placeholder="Motivo do Cancelamento"
                                    corBordaDinamica={this.props.corBotaoPadrao}
                                    value={this.props.motivoCancelamento}
                                    onChange={(e) => { this.props.modificaMotivoCancelamentoTelaAcoesAtendimento(e.target.value) }}
                                // onFocus={() => { this.handleInputEmailFocus() }}
                                // onBlur={() => { this.handleInputEmailBlur() }}
                                />
                            </DivInputMotivoCancelamento>
                            <DivInputMotivoCancelamento>

                                <ButtonNaoCancelarAtendimento onClick={() => { this.props.modificaVisibilidadeModalCancelarAtendimento(false) }} borderColorDinamico={this.props.corBotaoPadrao} backgroundBotao={this.props.backgroundPrimeiro}>
                                    <TextBotaoCancelarAtendimento colorDinamico={this.props.corBotaoPadrao}>Não</TextBotaoCancelarAtendimento>
                                </ButtonNaoCancelarAtendimento>
                                <ButtonCancelarAtendimento onClick={() => { this.cancelarChamadoLocal(); }} backgroundBotao={this.props.corBotaoPadrao}>
                                    <TextBotaoCancelarAtendimento colorDinamico={this.props.letraBotaoPadrao}>Sim</TextBotaoCancelarAtendimento>
                                </ButtonCancelarAtendimento>
                            </DivInputMotivoCancelamento>
                        </ContainerAtendentes>}
                        {this.props.obrigatorioInformarMotivoCancelamento === false &&
                            <ContainerAtendentes>
                                <DivTextoReabrirAtendimento >
                                    <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Deseja realmente Cancelar o Atendimento?</TextNovoAtendimento>
                                </DivTextoReabrirAtendimento>
                                <DivInputMotivoCancelamento>

                                    <ButtonNaoCancelarAtendimento onClick={() => { this.props.modificaVisibilidadeModalCancelarAtendimento(false) }} borderColorDinamico={this.props.corBotaoPadrao} backgroundBotao={this.props.backgroundPrimeiro}>
                                        <TextBotaoCancelarAtendimento colorDinamico={this.props.corBotaoPadrao}>Não</TextBotaoCancelarAtendimento>
                                    </ButtonNaoCancelarAtendimento>
                                    <ButtonCancelarAtendimento onClick={() => {
                                        this.props.modificaVisibilidadeModalCancelarAtendimento(false);
                                        this.props.cancelarChamado(this.props.keyAtendimento, '', this.props.dadosAtendimentos[0].keyAtendente, this.props.dadosAtendimentos[0], this.props.obrigatorioInformarMotivoCancelamento)
                                    }} backgroundBotao={this.props.corBotaoPadrao}>
                                        <TextBotaoCancelarAtendimento colorDinamico={this.props.letraBotaoPadrao}>Sim</TextBotaoCancelarAtendimento>
                                    </ButtonCancelarAtendimento>
                                </DivInputMotivoCancelamento>
                            </ContainerAtendentes>
                        }
                    </ContainerModalPrioridadeAtendimento>
                </Modal>
                <Modal focusTrapped={false} showCloseIcon={false} open={this.props.modalComentarioVisible} onClose={() => { }} center>
                    <ContainerModalPrioridadeAtendimento backgroundDinamico={this.props.backgroundPrimeiro}>
                        <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                            <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Adicionar Comentário</TextNovoAtendimento>
                            <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => { this.props.modificavisibilidadeModalComentario(false); this.zeraMensagemResponder(); }} className="icon-close" />
                        </ContainerIconeFecharModal>

                        <ContainerAtendentes>
                            <ContainerInputEBotaoComent>
                                <DivBotaoComentarioInternoPublico>
                                    <BotaoSelecionarCOment onClick={() => { this.mudaComentarioPublico() }} backGroundDinamico={this.state.backgroundColor} backGroundFalse={this.props.backgroundPrimeiro}>
                                        <TextComentario colorDinamico={this.state.colorTextComent} colorDinamicoFalse={this.props.coresLetraPadrao}>Comentário Público</TextComentario>
                                    </BotaoSelecionarCOment>
                                    <BotaoSelecionarCOment onClick={() => { this.mudaComentarioInterno() }} backGroundDinamico={this.state.backgroundColor2} backGroundFalse={this.props.backgroundPrimeiro}>
                                        <TextComentario colorDinamico={this.state.colorTextComent2} colorDinamicoFalse={this.props.coresLetraPadrao}>Comentário Interno</TextComentario>
                                    </BotaoSelecionarCOment>
                                </DivBotaoComentarioInternoPublico>
                                {this.state.viewResponder === true &&

                                    <div style={{ display: 'flex', borderTopLeftRadius: 5, borderTopRightRadius: 5, background: '#FFF', width: 450, padding: 5, flexDirection: 'row' }}>

                                        <div style={{ display: 'flex', background: '#F0f0f7', width: '100%', borderRadius: 3, flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                                            <div style={{ display: 'flex', flexDirection: 'row', flex: 1, alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                                                <div style={{ width: 5, height: '100%', borderTopLeftRadius: 3, borderBottomLeftRadius: 3, backgroundColor: this.state.circleColorResponder === '#00FF7F' ? '#16cb9c' : "#39a3f4" }}></div>
                                                <div style={{ display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'flex-start', justifyContent: 'flex-start', padding: 5 }}>
                                                    <TextResponder style={{ color: this.state.circleColorResponder === '#00FF7F' ? '#16cb9c' : "#39a3f4" }}>{this.state.circleColorResponder === '#00FF7F' ? this.state.atendenteResponder : this.props.dadosAtendimentos[0].cliente}</TextResponder>
                                                    {/*  </div>
<div style={{ display: 'flex', flexDirection: 'row' }}> */}

                                                    <DivTruncarTexto /* style={{ display: 'flex', height: 52, flex: 1, alignItems: 'center', justifyContent: 'flex-start' }} */>


                                                        <TextResponder style={{ color: '#000' }}>
                                                            {this.state.commentResponderType === 'imagem' && <i className="icon-imagewpp" style={{ fontSize: 14, color: '#000' }} />}
                                                            {this.state.commentResponderType === 'video' && <i className="icon-video-camera" style={{ fontSize: 14, color: '#000' }} />}

                                                            {this.state.commentResponderType === 'location' && <i className="icon-localization" style={{ fontSize: 14, color: '#000' }} />}
                                                            {this.state.commentResponderType === 'contato' && <i className="icon-man-user" style={{ fontSize: 14, color: '#000' }} />}
                                                            {this.state.commentResponderType === 'audio' && <i className="icon-microphone" style={{ fontSize: 14, color: '#000' }} />}
                                                            {this.state.commentResponderType === 'arquivo' && <i className="icon-documentwpp" style={{ fontSize: 14, color: '#000' }} />}
                                                            {this.state.commentResponder}</TextResponder>

                                                    </DivTruncarTexto>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', width: 40, justifyContent: 'center', alignItems: 'center' }}>
                                            <i onClick={() => { this.zeraMensagemResponder() }} className="icon-close" style={{ fontSize: 13, color: '#000', cursor: 'pointer' }} />
                                        </div>
                                    </div>}
                                <DivInputComentarChamado >
                                    <InputDescricaoChamadoComentaChamado
                                        autoFocus
                                        onFocus={this.moveCaretAtEnd}
                                        type="textarea"
                                        onChange={(value) => { this.props.modificaInputComentario(value.target.value) }}
                                        corLetraDinamico={this.props.coresLetraPadrao}
                                        value={this.props.inputComentario}
                                        corLetraSecundaria={this.props.coresLetraSecundario}
                                        corBordaDinamica={this.props.corSegundoBotaoPadrao}
                                        placeholder={this.state.placeholderInputComent}
                                    />
                                </DivInputComentarChamado>
                            </ContainerInputEBotaoComent>
                            <DivBotaoSalvarComentario>
                                <ContainerPermitir>
                                    <TextPermitir corLetraDinamica={this.props.coresLetraPadrao}>Fechar:</TextPermitir>

                                    <CheckBoxWrapper>
                                        <CheckBox id="checkbox" type="checkbox" checked={this.props.switchComentario} onChange={(value) => { this.props.ativaDesativaSwitchComentario(value.target.checked) }} />
                                        <CheckBoxLabel htmlFor="checkbox" />
                                    </CheckBoxWrapper>
                                </ContainerPermitir>
                                {this.renderBotaoSalvarComentario()}

                            </DivBotaoSalvarComentario>
                        </ContainerAtendentes>
                    </ContainerModalPrioridadeAtendimento>
                </Modal>

                <Modal focusTrapped={false} showCloseIcon={false} open={this.state.modalReabrirChamado} onClose={() => { }} center>
                    <ContainerModalPrioridadeAtendimento backgroundDinamico={this.props.backgroundPrimeiro}>
                        <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                            <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Reabrir Atendimento</TextNovoAtendimento>
                            <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => { this._toggleModalReabrirChamado() }} className="icon-close" />
                        </ContainerIconeFecharModal>

                        <ContainerAtendentes>
                            <DivTextoReabrirAtendimento >
                                <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Deseja realmente reabrir o atendimento?</TextNovoAtendimento>
                            </DivTextoReabrirAtendimento>
                            <DivInputMotivoCancelamento>

                                <ButtonNaoCancelarAtendimento onClick={() => { this._toggleModalReabrirChamado() }} borderColorDinamico={this.props.corBotaoPadrao} backgroundBotao={this.props.backgroundPrimeiro}>
                                    <TextBotaoCancelarAtendimento colorDinamico={this.props.corBotaoPadrao}>Não</TextBotaoCancelarAtendimento>
                                </ButtonNaoCancelarAtendimento>
                                <ButtonCancelarAtendimento onClick={() => {
                                    this._toggleModalReabrirChamado();
                                    this.props.reabrirChamado(this.props.keyAtendimento, this.props.dadosAtendimentos[0].keyAtendente, this.props.dadosAtendimentos[0].keySetor, this.props.dadosAtendimentos[0]);
                                }} backgroundBotao={this.props.corBotaoPadrao}>
                                    <TextBotaoCancelarAtendimento colorDinamico={this.props.letraBotaoPadrao}>Sim</TextBotaoCancelarAtendimento>
                                </ButtonCancelarAtendimento>
                            </DivInputMotivoCancelamento>
                        </ContainerAtendentes>
                    </ContainerModalPrioridadeAtendimento>
                </Modal>
                <Modal focusTrapped={false} showCloseIcon={false} open={this.state.viewMicrophone} onClose={() => { }} center>
                    <ContainerModalGravarAudio backgroundDinamico={this.props.backgroundPrimeiro}>
                        <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                            <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Gravar Áudio</TextNovoAtendimento>
                            <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => {
                                this.onStopRecord(true)
                                this._toggleModalGravarAudio()

                            }} className="icon-close" />
                        </ContainerIconeFecharModal>

                        {/* <Recorder
                            record={false}
                            title={"New recording"}
                            audioURL={this.state.audioDetails.url}
                            showUIAudio
                            hideHeader
                            handleAudioStop={data => this.handleAudioStop(data)}
                            handleOnChange={(value) => this.handleOnChange(value, 'firstname')}
                            handleAudioUpload={data => this.handleAudioUpload(data)}
                            handleRest={() => this.handleRest()}
                            handleAudioStart={() => {}} /> */}
                        <div style={{ backgroundColor: this.state.statusAudio == 'gravando' ? '#EC6A69' : this.props.backgroundPrimeiro }} >


                            {this.state.statusAudio !== 'gravacao' && this.state.statusAudio !== 'executandoAudio' && <div style={{ display: 'flex', height: 180, alignItems: 'center', justifyContent: 'center' }}>
                                <p style={{
                                    color: this.props.coresLetraPadrao,
                                    fontSize: 45,
                                    fontWeight: '200',
                                    letterSpacing: 3,
                                }}>{this.transformaMinutosEmSegundos(this.state.recordSecs)}</p>

                            </div>}
                            <div style={{
                                display: 'flex',
                                height: 50,
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'row',
                                marginLeft: 5,
                            }}>
                                <div></div>
                                {this.renderTextGravacaoAudio()}
                                <div ></div>
                            </div>
                            {this.renderBotaoGravarAudio()}
                        </div>


                    </ContainerModalGravarAudio>
                </Modal>
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return (
        {
            backgroundPrimeiro: state.CoresPadraoSistema.backgroundPrimeiro,
            backgroundSegundo: state.CoresPadraoSistema.backgroundSegundo,
            backgroundMaisEscuro: state.CoresPadraoSistema.backgroundMaisEscuro,
            coresLetraPadrao: state.CoresPadraoSistema.coresLetraPadrao,
            corBotaoPadrao: state.CoresPadraoSistema.corBotaoPadrao,
            letraBotaoPadrao: state.CoresPadraoSistema.letraBotaoPadrao,
            coresLetraSecundario: state.CoresPadraoSistema.coresLetraSecundario,
            corSegundoBotaoPadrao: state.CoresPadraoSistema.corSegundoBotaoPadrao,
            corLetraDescricaoDosCampos: state.CoresPadraoSistema.corLetraDescricaoDosCampos,
            modalAlterarAtendimento: state.ChamadoReducer.modalAlterarAtendimento,
            modalAtribuirAtendimento: state.ChamadoReducer.modalAtribuirAtendimento,
            modalAddTempoGasto: state.ChamadoReducer.modalAddTempoGasto,
            modalStatusAtendimento: state.ChamadoReducer.modalStatusAtendimento,
            modalPrioridadeAtendimento: state.ChamadoReducer.modalPrioridadeAtendimento,
            modalCancelarAtendimento: state.ChamadoReducer.modalCancelarAtendimento,
            inputComentario: state.ChamadoReducer.inputComentario,
            comentarioPublicoSimOuNao: state.ChamadoReducer.comentarioPublicoSimOuNao,
            visibilidadeBotaoAddDataVencimento: state.ChamadoReducer.visibilidadeBotaoAddDataVencimento,
            nomeRota: state.HeaderReducer.nomeRota,

            keyAtendimento: state.ChamadoReducer.keyAtendimento,
            numeroAtendimento: state.ChamadoReducer.numeroAtendimento,
            dadosAtendimentos: state.ChamadoReducer.dadosAtendimentos,
            loadingTelaAcoesAtendimento: state.ChamadoReducer.loadingTelaAcoesAtendimento,
            atendentesAcoesChamado: state.ChamadoReducer.atendentesAcoesChamado,
            verificaChamadoAlteradoSucesso: state.ChamadoReducer.verificaChamadoAlteradoSucesso,
            arrayArquivosUpados1: state.ChamadoReducer.arrayArquivosUpados1,
            arrayArquivosUpados2: state.ChamadoReducer.arrayArquivosUpados2,
            arrayArquivosUpados3: state.ChamadoReducer.arrayArquivosUpados3,
            acoesDoAtendimento: state.ChamadoReducer.acoesDoAtendimento,
            motivoCancelamento: state.ChamadoReducer.motivoCancelamento,
            mensagemErroMotivoCancelamento: state.ChamadoReducer.mensagemErroMotivoCancelamento,
            loadingModalCancelarAtendimento: state.ChamadoReducer.loadingModalCancelarAtendimento,

            nomeArquivoAnexadoComentario: state.ChamadoReducer.nomeArquivoAnexadoComentario,
            switchComentario: state.ChamadoReducer.switchComentario,
            modalComentarioVisible: state.ChamadoReducer.modalComentarioVisible,
            resArquivoAnexadoComentario: state.ChamadoReducer.resArquivoAnexadoComentario,
            verificaInternetSalvarComentario: state.ChamadoReducer.verificaInternetSalvarComentario,
            arquivosUpadosVinculadoAoChamado: state.ChamadoReducer.arquivosUpadosVinculadoAoChamado,
            loadingArquivosUpadosVinculadoAoChamado: state.ChamadoReducer.loadingArquivosUpadosVinculadoAoChamado,
            premium: state.PremiumReducer.premium,
            licensawebouapp: state.PremiumReducer.licensawebouapp,
            verificaSeAtribuirAtendenteObrigatorio: state.ChamadoReducer.verificaSeAtribuirAtendenteObrigatorio,
            verificaInternetAcoesAtendimento: state.ChamadoReducer.verificaInternetAcoesAtendimento,

            clientesNovoChamado: state.ChamadoReducer.clientesSectionedConsulta,
            atendenteNovoChamado: state.AtendenteReducer.atendentesSectionedConsulta,
            setorNovoChamado: state.SetorReducer.setoresSectionedConsulta,
            clienteSelecionado: state.ChamadoReducer.clienteSelecionado,
            atendenteSelecionado: state.ChamadoReducer.atendenteSelecionado,
            localAtendimentoSelecionado: state.ChamadoReducer.localAtendimentoSelecionado,
            localAtendimento: state.ChamadoReducer.localAtendimento,
            prioridadeSelecionada: state.ChamadoReducer.prioridadeSelecionada,
            prioridade: state.ChamadoReducer.prioridade,
            setorSelecionado: state.ChamadoReducer.setorSelecionado,
            descricaoAtendimento: state.ChamadoReducer.descricaoAtendimento,
            dataVencimento: state.ChamadoReducer.dataVencimento,
            retornaTelaClienteParaNovoAtendimentos: state.ChamadoReducer.retornaTelaClienteParaNovoAtendimentos,
            tamanhoTotalARquivoAnexado: state.ChamadoReducer.tamanhoTotalARquivoAnexado,
            respostasPadroesAcoesChamado: state.ConfiguracaoReducer.respostasPadroesAcoesChamado,
            keyEmpresaVinculadaAoAbrirAlterarChamado: state.ChamadoReducer.keyEmpresaVinculadaAoAbrirAlterarChamado,
            isAdmin: state.PerfilReducer.isAdmin,
            solucaoAtendimento: state.ChamadoReducer.solucaoAtendimento,
            nome: state.PerfilReducer.nome,

            loadingCarregandoMaisConversasChatAcoesChamado: state.ChamadoReducer.loadingCarregandoMaisConversasChatAcoesChamado,
            referenciaUltimaKeyChatAppAcoesChamado: state.ChamadoReducer.referenciaUltimaKeyChatAppAcoesChamado,
            arrayConversasAntigasChatAcoesChamado: state.ChamadoReducer.arrayConversasAntigasChatAcoesChamado,
            loadingCarregandoRespostasAntigasAcoesChamado: state.ChamadoReducer.loadingCarregandoRespostasAntigasAcoesChamado,
            idRespostaAntigaAcoesChamado: state.ChamadoReducer.idRespostaAntigaAcoesChamado,

            obrigatorioInformarResolucaoChamado: state.ChamadoReducer.obrigatorioInformarResolucaoChamado,

            obrigatorioInformarMotivoCancelamento: state.ConfiguracaoReducer.obrigatorioInformarMotivoCancelamento,

            permissoesAcessarTelasAtendente: state.AtendenteReducer.permissoesAcessarTelasAtendente,

            criarChamadoProblemaEstaticoAtendimento: state.PremiumReducer.criarChamadoProblemaEstaticoAtendimento,
            nomeProblemaEstaticoSelecionadoNovoAtendimento: state.ChamadoReducer.nomeProblemaEstaticoSelecionadoNovoAtendimento,
            problemaEstaticoSelecionadoNovoAtendimento: state.ChamadoReducer.problemaEstaticoSelecionadoNovoAtendimento,
            problemasEstaticos: state.ConfiguracaoReducer.problemasEstaticos,

            modalViewImage: state.ChamadoReducer.modalViewImage,
            urlPreViewImage: state.ChamadoReducer.urlPreViewImage,
            nameUrlPreview: state.ChamadoReducer.nameUrlPreview,
        }
    );
};
export default connect(mapStateToProps, {
    modificaVisibilidadeModalAlterarAtendimento, modificaVisibilidadeModalAtribuirAtendimento,
    modificaVisibilidadeModalAddTempoGastoAtendimento, modificaVisibilidadeModalFinalizarAtendimento,
    modificaVisibilidadeModalPrioridadeAtendimento, modificaVisibilidadeModalCancelarAtendimento,
    modificavisibilidadeModalComentario, modificaInputComentario, modificaComentarioPublicoSimOuNao,
    modificaVisibilidadeBotaoAddVencimento, modificaNomeRotaNoHeader, listaChamadoParaTelaAcoesChamado,
    modificaClienteSelecionado, modificaAtendenteSelecionado, modificaLocalSelecionado, modificaPrioridadeSelecionado,
    modificaDataVencimentoTelaChamadoAbertura, modificaSetorSelecionado, alteraDescricaoChamado,
    modificaKeyEmpresaVinculadaSelecionadaAoAbrirChamado, alterarChamado, salvarComentario,
    exportaAtendimentoParaPDF, atribuiAtendimentoAh, atribuirTempoEsperaAoChamado,
    atribuiStatusAtendimento, alterarPrioridadeAtendimento, alterarDataVencimentoAtendimento, modificaMotivoCancelamentoTelaAcoesAtendimento,
    cancelarChamado, ativaDesativaSwitchComentario, reabrirChamado,
    modificaRetornaTelaClienteParaNovoAtendimento,
    modificaVisibleModalNovoAtendente, zeraInformacoesCadastroAtendenteExport, modificaVisibleModalNovoSetor,
    zeraInformacoesCadastroClienteExport, modificaVisibleModalNovoCLiente, modificaNumeroAtendimento,
    listaClientesTelaConsultaClientes,
    listaSetorSectionedTelaConsultaSetor, modificaKeyAtendimento, listaRespostasPadroes,
    desligaOuvintesChamadosFirebase,
    modificaEmpresaSelecionadaAoAbrirChamado, modificaResolucaoProblema,
    listaAtendentesTelaConsultaAtendente, listaAcoesChamadoConversasAcoesChamado, listaConversasAntigasResponderAcoes,

    enviaReferenciaultimaKeyAcoesChamadoExportAcoesChamado, enviaConversasAntigasChatExportAcoesChamado, deletaMensagemDropDesk,
    listaProblemasEstaticosTelaNovoChamado,
    modificaProblemaEstaticoSelecionadoNovoAtendimento, modificaNomeProblemaEstaticoSelecionadoNovoAtendimento,
    modificaModalPreviewImage, modificaUrlPreviewImage, modificaNamePreviewImage
})(withRouter(AcoesChamado));
