import moment from 'moment';
import React from 'react';
import firebase from '../servicos/FirebaseConnection';
import { jsPDF } from "jspdf";
import _ from 'lodash';
import html2pdf from "html2pdf.js";
import XLSX from 'xlsx';
import { Howl, Howler } from 'howler';
import soundnotification from '../imagens/notificationsound.mp3';
import { getAllFirebaseCachedData } from './cachedData';
import api from '../http_client/http.client';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';


import {
    MODIFICACORBORDALISTATAREFAS,
    MODIFICAFILTROPORDATADASHBOARD,

    ENVIAATENDIMENTOSNOTOTALDASHBOARD,
    ATIVADESATIVALOADINGTELADASHBOARD,
    ENVIACLIENTESNOTOTALDASHBOARD,
    ENVIATENDIMENTOSHOJEDASHBOARD,
    ENVIAATENDIMENTOSDESTASEMANADASHBOARD,
    ATIVADESATIVALOADINGREFRESHCONTROL,
    ENVIATENDIMENTOSPORSTATUSDASHBOARD,
    ENVIATENDIMENTOSPORPRIORIDADEDASHBOARD,
    ENVIATOTALATENDIMENTOPORPRIORIDADE,
    ENVIACLIENTEMAISABRIRAMCHAMADOS,
    ENVIASETORESMAISABRIRAMCHAMADOS,
    ENVIATEMPORESOLUCAOCHAMADOS,
    ENVIATOALTENDIMENTOSPORLOCALDASHBOARD,
    ENVIATENDIMENTOSPORLOCALDASHBOARD,
    ENVIATOTALATENDIMENTOSPERIODORESOLUCAO,
    ENVIASEMCONEXAOINTERNETDASHBOARD,
    ENVIASEEHVERSAOPREMIUM,
    VERIFICAINTERNETTELAVIP,
    LOADINGREFRESHCONTROLVIP,
    LOADINGTELAVIP,
    ENVIAPLANOMENSAL,
    ENVIAPLANOTRIMESTRAL,
    ENVIAPLANOANUAL,
    ENVIADATAVALIDADEVERSAOPREMIUM,
    ENVIAPERIODOPERSONALIZADO,
    MODIFICADATAINICIALCONSULTADASHBOARD,
    MODIFICADATAFINALCONSULTADASHBOARD,
    MENSAGEMPERIODOPERSONALIZADOERROR,
    ENVIADATACOMPRAVERSAOPREMIUM,
    ENVIAPERMISSOESACESSARTELASATENDENTE,
    ATIVADESATIVALOADINGTELARELATORIOATENDIMENTODETALHADOS,
    ENVIASEMCONEXAOINTERNETTELARELATORIODETALHADO,
    ENVIAPERIODOPERSONALIZADORELATORIODETALHADI,
    MODIFICALOADINGCONFIGURACOESEMPRESA,
    MODIFICAMODALDUVIDASFREQUENTES,
    ENVIAATENDIMENTOSPORSTATUSRELATORIODETALHADO,
    ENVIATOTALATENDIMENTOSPORSTATUSRELATORIODETALHADO,
    ENVIAATENDIMENTOSPORPRIORIDADERELATORIODETALHADO,
    ENVIATOTALATENDIMENTOSPORPRIORIDADERELATORIODETALHADO,
    ENVIATEMPORESOLUCAOCHAMADOSRELATORIODETALHADO,
    ENVIATOTALATENDIMENTOSPERIODORESOLUCAORELATORIODETALHADO,
    ENVIATOTALATENDIMENTOSPORLOCALRELATORIODETALHADO,
    ENVIAATENDIMENTOSPORLOCALRELATORIODETALHADO,
    MODIFICASETORRELATORIODETALHADO,
    MODIFICAATENDENTERELATORIODETALHADO,
    MODIFICACLIENTETELARELATORIODETALHADO,
    ZERADATAINICIALFILTRORELATORIODETALHADO,
    ZERADATAFINALFILTRORELATORIODETALHADO,
    MODIFICAFILTROTELARELATORIODETALHADO,
    MODIFICAFILTROCLIENTESECTIONEDTELARELATORIODETALHADO,
    MODIFICAFILTROATENDENTESECTIONEDTELARELATORIODETALHADO,
    MODIFICAFILTROSETORSECTIONEDTELARELATORIODETALHADO,
    MODIFICADATAFINALRELATORIODETALHADO,
    MODIFICADATAINICIALRELATORIODETALHADO,
    MENSAGEMPERIODOPERSONALIZADORELATORIODETALHADOERROR,
    ENVIATEMPOMEDIODESOLUCAOATENDIMENTOS,
    ENVIASEMCONEXAOINTERNETRELATORIOGERAL,
    ATIVADESATIVALOADINGRELATORIOGERAL,
    ENVIAPERIODOPERSONALIZADORELATORIOGERAL,
    ENVIACLIENTEMAISABRIRAMCHAMADOSRELATORIOGERAL,
    ENVIAATENDENTEMAISABRIRAMCHAMADOSRELATORIOGERAL,
    ENVIASETORESMAISABRIRAMCHAMADOSRELATORIOGERAL,
    ENVIATEMPOMEDIODESOLUCAOATENDIMENTOSRELATORIOGERAL,
    ENVIANOTAMEDIAAVALIACAORELATORIOGERAL,
    ZERADATAFINALFILTRORELATORIOGERAL,
    ZERADATAINICIALFILTRORELATORIOGERAL,
    MODIFICADATAINICIALRELATORIOGERAL,
    MODIFICADATAFINALRELATORIOGERAL,
    ATIVADESATIVALOADINGSEMINTERNETRELATORIOGERAL,
    MODIFICAVISIBILIDADEMODALINFORMACAORELATORIOGERAL,
    ENVIATEXT2MODALINF,
    ENVIATEXT1MODALINF,
    ENVIATEXTCABECALHOMODALINF,
    MODIFICAVISIBILIDADEMODALINFORMACAORELATORIODETALHADO,
    ENVIATEXTCABECALHOMODALINFRELATORIODETALHADO,
    ENVIATEXT1MODALINFRELATORIODETALHADO,
    ENVIATEXT2MODALINFRELATORIODETALHADO,
    ENVIACHAMADOSDETALHADOSPARADOWNLOAD,
    MODIFICAMENSAGEMGERANDORELATORIO,
    ENVIAPESSOASCOMMAISATENDIMENTOSPARADOWNLOAD,
    MODIFICAMENSAGEMGERANDORELATORIOVISAOGERAL,
    ENVIADATAVALIDADEATUALPREMIUM,
    ENVIANUMEROPEDIDOCOMPRAPREMIUM,
    ENVIAPLANOPREMIUM,
    ENVIAPLATAFORMAPREMIUM,
    ENVIAQUANTIDADEATENDENTELIBERADOPREMIUM,
    ENVIASTATUSASSINATURAPREMIUM,
    ATIVADESATIVALOADINGVERSAOPREMIUM,
    ENVIATEXTSTATUSASSINATURA,
    ENVIACORTEXTSTATUSASSINATURA,
    MODIFICAVISIBLEMODALADIQUIRAVERSAOPREMIUM,
    MODIFICAVISIBLEMODALASSINATURASUSPENSA,
    MODIFICAVISIBLEMODALASSINATURAPAUSADA,
    MODIFICAVISIBLEMODALVERSAOPREMIUMESGOTOU,
    VERIFICARSEEHOBRIGATORIOATRIBUIRATENDENTE,
    VERIFICAATENDENTEVERAPENASPROPRIOCHAMADO,
    VERIFICAAGRUPARATENDENTESETOR,
    ENVIAIDENTIFICADORUNICACOMPRA,
    ENVIAEMAILASSINANTE,
    ENVIANOMEASSINANTE,
    ENVIAPREMIUMVERSAOGRATIS,
    ATIVADESATIVALOADINGCARREGANDOCONFIGURACOESGERAIS,
    ALTERAINFORMACAOCONTAPRINCIPALATENDENTE,
    VERIFICACRIARCHAMADOPROBLEMAESTATICO,
    ENVIASPROBLEMASMAISABRIRAMCHAMADOSRELATORIOGERAL,
    MODIFICATOASTACOESATNEDIMENTO,
    ENVIASEATENDENTEEHADMINISTRADOR,
    MODIFICAFILTROSELECIONADORELATORIODETALHADO,
    ENVIAATENDIMENTOABERTO,
    ENVIAATENDIMENTOEMATENDIMENTO,
    ENVIAATENDMENTOCANCELADO,
    ENVIAATENDIMENTOFECHADO,
    ENVIATAREFAPARAREDUCER,
    MODIFICAMODALSAIR,
    ENVIAKEYUSUARIOPERFIL,
    CARREGAEMAILPERFIL,
    CARREGANOMEPERFIL,
    CARREGAIMAGEMPERFIL,
    MODIFICACORFUNDOPRINCIPAL,
    MODIFICACORFUNDOSECUNDARIO,
    MODIFICACORFUNDOITENSSELECIONAVEIS,
    MODIFICACORFUNDOBOTAOPRINCIPAL,
    MODIFICACORFUNDOBOTAOSECUNDARIO,
    MODIFICACORLETRAPADRAO,
    MODIFICACORLETRAPRINCIPAL,
    MODIFICACORLETRASECUNDARIA,
    MODIFICACORLETRADESCRICAODOSCAMPOS,
    MODIFICACORFUNDOMENU,
    MODIFICACORLETRAMENU,
    MODIFICALOGOTIPOTELACONFIGURACAO,
    ALTERANUMEROATENDIMENTO,
    ALTERAKEYCHAMADO,
    MODIFICANOMEROTANOHEADER,
    ENVIANOTIFICACOESPARAREDUCER,
    ENVIATOTALNOTIFICACOESPARAREDUCER,
    ATIVADESATIVARENOVARPLANO,
    MODIFICAMOSTRAVERSAOEXPIRANDO,
    MODIFICALICENSAWEBAPP,
    MODIFICACHAMADOSEMATENDIMENTOVIACHAT,
    ENVIAATENDIMENTOPARAREDUCERCHAT,
    ENVIAACOESATENDIMENTOPARAREDUCERCHAT,
    ENVIAATENDIMENTOPORKEYREDUCERCHAT,
    MODIFICALIDOENAOLIDOATENDIMENTOPORKEYREDUCERCHAT,
    ENVIAADMINPARAREDUCER,
    ENVIATEMPOMEDIORESPOSTAATENDIMENTOS,
    MODIFICAMOTIVOCANCELAMENTOTELAVERSAOPREMIUM,
    ENVIACLIENTESATIVOSGRAFICO,
    ENVIACLIENTESVIAANUNCIO,
    ENVIATIPOPLANOGRAFICO,
    ENVIAFORMAPAGAMENTOGRAFICO,
    ENVIAASSINADOVIAWEBAPPGRAFICO,
    MODIFICADATAINICIALRELATORIOCRM,
    MODIFICADATAFINALRELATORIOCRM,
    ENVIAPLANOSMAISASSINADOS,
    ENVIAESTADOSCOMAMAISCLIENTES,
    ENVIASEGMENTOSCOMMAISCLIENTES,
    ATIVADESATIVALOADINGRELATORIOCRM,
    ENVIAPLANOSMAISASSINANTESPRABAIXAR,
    ENVIAADMVISUALIZAAPENASCHAMADOSDOSETORVINCULADO,
    ENVIADADOSWHATSAPPCONFIG,
    ATIVADESATIVALOADINGCARREGANDOCHAMADOSEMATENDIMENTO,
    MODIFICAMENSAGEMFINALIZAATENDIMENTOWPP,
    MODIFICAKEYATENDIMENTOEMTENDIMENTOONLINE,
    ATIVADESATIVALOADINGCARREGANDOCONVERSASCHATATUALA,
    ENVIACONVERSASCARREGADASEMESPERA,
    ENVIACONVERSASCARREGADASEMATENDIMENTO,
    ENVIAULTIMAKEYREFERENCIACONVERSASCARREGADASCHAT,
    ENVIAULTIMAKEYREFERENCIACONVERSASCARREGADASCHATEMESPERA,
    ATIVADESATIVALOADINGCARREGANDOCONVERSASCHATATUALAEMEPSERA,

    ENVIAATENDIMENTOPORKEYREDUCERCHATEMESPERA,
    ENVIACONVERSASPARACHATEMESPERA,
    ATIVADESATIVALOADINGCHATONLINE,
    ENVIAATENDIMENTOEMESPERATENDIMENTOONLINE,
    MODIFICAOBRIGATORIOINFORMARRESOLUCAOCHAMADODASHBOARD,
    ENVIAARRAYDEDADOSDAEMPRESAPAINELADM,
    MODIFICAMEMORIACONTAINERWPP,
    MODIFICASTATUSCONTAINERWPP,
    MODIFICACONFIGURACOESINTERNASWPP,
    MODIFICASTATUSCONEXAOWPPPAINELADM,
    MODIFICANIVELBATERIA,
    MODIFICAMODELOCELULAR,
    MODIFICAVERSAOOS,

    MODIFICALOADINGCARREGANDOCHAMADOSEMESPERAREFRESHCONTROL,
    MODIFICACHAMADOSCARREGADOSEMESPERA,
    MODIFICAKEYREFERENCIAULTIMOCHAMADOEMESPERA,
    MODIFICACONTAINERLISTAEMPRESA,
    MODIFICAURLAPILISTAEMPRESA,
    MODIFICANUMEROCONECTADOWPP,
    MODIFICADESATIVARASSISTENTEVIRTUALBOTWPP,
    MODIFICAMOSTRARNOMEATENDENTEWPP,
    MODIFICATIMEZONECHATONLINE,
    MODIFICASOLICITARNOMECLIENTEPRIMEIROCONTATO,
    MODIFICAVISIBILIDADEMODALEXPORTARCLIENTES,
    MODIFICAINFORMARMOTIVOCANCELAMENTO,
    VERIFICACRIARCHAMADOPROBLEMAESTATICOATENDIMENTO,
    MODIFICAATENDENTEVEAPENASPROPRIOHISTORICOCHAMADO,
    ENVIANOVASATIVACOESNOPERIODO,
    ENVIASEGMENTOSCOMMAISCANCELAMENTOS,
    MODIFICADATACONEXAO,
    MODIFICALOADINGBOTAOATUALIZASTATUSCONEXAOWPP,
    MODIFICAINFOUSUARIOPAINELADM,
    ALTERAVALORSWITCHUTILIZAVERSAOBETA,
    MODIFICAENVIARMENSAGEMTRANSSETORWPP,
    UPDTEISMAINTENANCE,
    UPDTELASTVERSIONAVAILABLE,
    MODIFICAUTILIZAVERSAOBETAPAINELADM
} from './types';

import { listaClientesTelaConsultaClientesAntesRemoved } from './ChamadoAction';
import { listaAtendentesTelaConsultaAtendenteAntesRemoved } from './AtendenteAction';
import { listaSetorSectionedTelaConsultaSetorAntesRemoved } from './SetorAction';
//import { listaRespostasPadroesAntesRemoved } from './ConfiguracaoAction';

import { toastr } from 'react-redux-toastr';


async function getChatsFromExport(chaveUnica, numeroInicial, numeroFinal) {
    try {
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'Accept': 'application/json',
            },

            data: { chaveUnica, numeroInicial, numeroFinal },
            url: 'exportaConversasEAtendimentos',
        };
        const snapshot = await api(options);
        if (snapshot === "SEM CHAMADO" || snapshot === "ERROR") {
            return;
        } else {
            return { chamados: snapshot.data[0], conversas: snapshot.data[1] };
        }
    } catch (e) {
        throw e;
    }


}

async function makePDFChats(chamados, conversas, numeroInicial, numeroFinal) {
    return Promise.resolve().then(function () {
        try {
            let options = {
                info: {
                    title: 'Atendimentos',
                },
                pageSize: 'A4',
                pageOrientation: 'landscape',
                content: [
                    // { text: 'Atendimentos CIOF', margin: [ 0, 16 ], fontSize: 16, bold: true },

                ],
            }

            let aux = 0;
            for (let i = 0; i < chamados.length; i++) {
                let conversasDoChamado = conversas[i];
                options.content.push(
                    {
                        //layout: 'lightHorizontalLines',
                        fontSize: 10,
                        table: {
                            // headers are automatically repeated if the table spans over multiple pages
                            // you can declare how many rows should be treated as headers
                            headerRows: 1,
                            body: [
                                ['Atendimento', 'Problema', 'Status', 'Cliente', 'Empresa', 'Setor', 'Atendente', "Fechamento"],
                                [chamados[i].numeroChamado === undefined ? "" : chamados[i].numeroChamado, chamados[i].descricaoProblema === undefined ? "" : chamados[i].descricaoProblema, chamados[i].status === undefined ? "" : chamados[i].status, chamados[i].cliente === undefined ? "" : chamados[i].cliente, chamados[i].empresa === undefined ? "" : chamados[i].empresa,
                                chamados[i].setor === undefined ? "" : chamados[i].setor, chamados[i].atendente === undefined ? "" : chamados[i].atendente, chamados[i].dataHoraFechamento === undefined ? "" : chamados[i].dataHoraFechamento]
                            ],
                        }
                    }
                )

                for (let o = 0; o < conversasDoChamado.length; o++) {
                    let link = conversasDoChamado[o].urlDownload;
                    let mensagem = conversasDoChamado[o].mensagem === undefined ? "" : conversasDoChamado[o].mensagem;
                    let dataHoraAtual = conversasDoChamado[o].dataHoraAtual === undefined ? "" : conversasDoChamado[o].dataHoraAtual;
                    let titulo = conversasDoChamado[o].titulo === undefined ? "" : conversasDoChamado[o].titulo;
                    if (conversasDoChamado[o].tipo === 'mensagem' || link === undefined) {

                        // options.content[0].push({text:`(${conversasDoChamado[o].dataHoraAtual}) ${conversasDoChamado[o].titulo}: ${conversasDoChamado[o].mensagem}`})
                        if (mensagem !== undefined) {

                        }
                        options.content.push({ text: `(${dataHoraAtual}) ${titulo}: ${mensagem}` })
                        // timeline += '<div style="margin-top:10px"><p style="font-size: 13px">(' + conversasDoChamado[o].dataHoraAtual + ') ' + conversasDoChamado[o].titulo + ': ' + conversasDoChamado[o].mensagem + '</p></div>';
                    } else {

                        let urlDownload = "";
                        if (link.includes("firebasestorage.googleapis.com/v0/b/")) {
                            if (link.includes("?")) {
                                let splitLink = link.split("?");


                                urlDownload = splitLink[0].replace("https://firebasestorage.googleapis.com/v0/b/dark-4b307.appspot.com/o/", "https://storage.googleapis.com/dark-4b307.appspot.com/")

                            } else {
                                urlDownload = link.replace("https://firebasestorage.googleapis.com/v0/b/dark-4b307.appspot.com/o/", "https://storage.googleapis.com/dark-4b307.appspot.com/")
                            }


                        } else {
                            urlDownload = link;
                        }
                        urlDownload = urlDownload === undefined ? "" : urlDownload
                        options.content.push({
                            text: `(${dataHoraAtual}) ${titulo}: ${urlDownload}`,
                            color: "#0000FF",
                            textDecoration: 'underline'
                        })
                    }
                }

                aux++
                if (aux === chamados.length) {
                    pdfMake.vfs = pdfFonts.pdfMake.vfs;
                    pdfMake.createPdf(options).download(`atendimentos_${numeroInicial}_a_${numeroFinal}.pdf`);
                    return Promise.resolve();
                }
            }
        } catch (err) {
            alert(err);
            return Promise.resolve();
        }
    })
}
export const exportaAtendimentosEconversasLGPD = () => {
    return async (dispatch) => {
        const chaveUnica = "znGlbE.......";
        console.log('iniciando processo...');

        const numeroInicial = 1;
        const numeroFinal = 15320;
        const lotes = dividirEmLotes(numeroInicial, numeroFinal, 1000);
        for (let lote of lotes) {
            let loteInicial = lote[0];
            let loteFinal = lote[1];
            console.log(`Processando lote de ${loteInicial} a ${loteFinal}`);
            const dadosChamados = await getChatsFromExport(chaveUnica, loteInicial, loteFinal);
            if (dadosChamados) {
                const chamados = dadosChamados.chamados;
                const conversas = dadosChamados.conversas;
                await makePDFChats(chamados, conversas, loteInicial, loteFinal);
            }
        }

        console.log('Process DONE!');
    }
}
function dividirEmLotes(inicial, final, tamanhoDoLote) {
    let lotes = [];
    for (let i = inicial; i <= final; i += tamanhoDoLote) {
        let loteInicial = i;
        let loteFinal = Math.min(i + tamanhoDoLote - 1, final); // Garante que o último lote não ultrapasse o valor final
        lotes.push([loteInicial, loteFinal]);
    }
    return lotes;
}
export const exportarClientesExcel = (clientes) => {
    return dispatch => {
        // toastr.info("Funcionalidade em manutenção", "Esta funcionalidade está em manutenção, já estamos trabalhando para resolver, obrigado pela compreensão.");

        toastr.info("Aguarde o processamento", "Exportação de clientes iniciada, por favor aguarde...");
        const colunas = ['nome', 'empresa', 'email', 'telefone', 'endereco'];

        exportaClientesparaExcel(colunas, clientes, dispatch);
    }
}
export const excluiVersaoPremiumPainelAdm = (dadosEmpresa) => {
    return dispatch => {
        AsyncStorage.getItem('chaveUnica').then((chaveUnica2) => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                    'Accept': 'application/json',
                },

                data: {
                    chaveUnica,
                    identityKey: dadosEmpresa.chaveUnica,
                    companyKey: dadosEmpresa.key,
                    route: 'remove',
                },
                url: 'AddVersaoPremium',
            };
            api(options).then(
                () => {
                    enviaArrayDeDadosDaEmpresaPainelAdm(dispatch, []);
                    toastr.success('Versão removida com sucesso!');
                }).catch(() => {
                    toastr.success('Erro ao remover versão!');
                })
        })
    }
}
export const AddVersaoGratisPainelAdm = (dadosEmpresa) => {
    return dispatch => {
        let dataAtual = moment().toDate();
        dataAtual = moment(dataAtual).format('DD/MM/YYYY');
        let qtdVersaoTesteLiberada = dadosEmpresa.qtdVersaoTesteLiberada + 1;

        if (dadosEmpresa.versao.includes('ATIVO')) {
            //versao premium ativo nao pode atribuir versao gratis
            toastr.error("Error", "Empresa com Versão Premium Ativo, Por isso não pode ser atribuido versão Teste ")
        } else if (dadosEmpresa.qtdVersaoTesteLiberada >= 1) {
            toastr.error("Error", "Versão Teste já foi liberado uma vez, portanto não poderá liberar novamente")
        } else if (dadosEmpresa.dataValidadeAtual !== '') {
            toastr.error("Error", "Esta empresa já teve Versão Premium assinada, portanto não poderá liberar Versão Teste")
        } else {
            AsyncStorage.getItem('chaveUnica').then((chaveUnica2) => {
                const chaveUnica = chaveUnica2.replace(/"/g, "");
                if (dadosEmpresa.versao.includes('Teste')) {
                    let diferencaDia = calculaDias2(dataAtual, dadosEmpresa.dataValidoAte);

                    if (diferencaDia > 2) {
                        toastr.error("Error", "Esta empresa já tem Versão Teste e com prazo de vencimento maior ou igual a 3 dias")
                    } else {

                        let dataValidadeAte = moment(dataAtual, 'DD/MM/YYYY').add(7, 'days').format('DD/MM/YYYY');

                        const options = {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json; charset=utf-8',
                                'Accept': 'application/json',
                            },

                            data: {
                                chaveUnica,
                                identityKey: dadosEmpresa.chaveUnica,
                                companyKey: dadosEmpresa.key,
                                dataAtual,
                                quantidadeAtendenteLiberado: 120,
                                route: 'free',
                                dataValidadeAte,
                                qtdVersaoTesteLiberada
                            },
                            url: 'AddVersaoPremium',
                        };
                        api(options).then(
                            () => {
                                enviaArrayDeDadosDaEmpresaPainelAdm(dispatch, []);
                                toastr.success('Versão Teste inserida com sucesso!');
                            }).catch(() => {
                                toastr.success('Erro ao inserir versão teste!');
                            })
                    }

                } else {
                    let dataValidadeAte = moment(dataAtual, 'DD/MM/YYYY').add(7, 'days').format('DD/MM/YYYY');

                    const options = {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json; charset=utf-8',
                            'Accept': 'application/json',
                        },

                        data: {
                            chaveUnica,
                            identityKey: dadosEmpresa.chaveUnica,
                            companyKey: dadosEmpresa.key,
                            dataAtual,
                            quantidadeAtendenteLiberado: 120,
                            route: 'free',
                            dataValidadeAte,
                            qtdVersaoTesteLiberada
                        },
                        url: 'AddVersaoPremium',
                    };
                    api(options).then(
                        () => {
                            enviaArrayDeDadosDaEmpresaPainelAdm(dispatch, []);
                            toastr.success('Versão Teste inserida com sucesso!');
                        }).catch(() => {
                            toastr.success('Erro ao inserir versão teste!');
                        })
                }
            })
        }
    }
}
export const AddVersaoPremiumPainelAdm = (dadosEmpresa, qtdAtendentes) => {
    return dispatch => {

        let quantidadeAtendenteLiberado = parseFloat(qtdAtendentes);
        let dataAtual = moment().toDate();
        dataAtual = moment(dataAtual).format('DD/MM/YYYY');


        let diferencaDia = calculaDias2(dataAtual, dadosEmpresa.dataValidoAte);

        if (dadosEmpresa.versao.includes('ATIVO') && diferencaDia > 5) {
            //versao premium ativo nao pode atribuir versao gratis
            toastr.error("Error", "Empresa já possui versão premium com prazo de vencimento maior que 5 dias.")
        } else if (dadosEmpresa.dataValidadeAte === "12/12/2099") {
            toastr.error("Error", "Essa empresa já tem uma versão premium ativo pago em Cartão")
        } else {
            AsyncStorage.getItem('chaveUnica').then((chaveUnica2) => {
                const chaveUnica = chaveUnica2.replace(/"/g, "");
                let dataValidadeAtual = moment(dataAtual, 'DD/MM/YYYY').add(1, 'months').format('DD/MM/YYYY');

                const options = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                        'Accept': 'application/json',
                    },

                    data: {
                        chaveUnica,
                        identityKey: dadosEmpresa.chaveUnica,
                        companyKey: dadosEmpresa.key,
                        dataAtual,
                        dataValidadeAtual,
                        quantidadeAtendenteLiberado,
                        route: 'premium'
                    },
                    url: 'AddVersaoPremium',
                };
                api(options).then(
                    () => {
                        enviaArrayDeDadosDaEmpresaPainelAdm(dispatch, [])
                        toastr.success('Versão Premium inserida com sucesso!');
                    }).catch(() => {
                        toastr.success('Erro ao inserir versão premium!');
                    })
            })
        }
    }
}
export const atualizaStatusConexaoWpp = (apiKeyServidor, sessionclient, urlApi) => {
    return dispatch => {
        modificaloadingBotaoAtualizaStatusConexaoWpp(dispatch, true);
        let statusContainerWpp = '';
        let configuracoesInternasWpp = "";
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'Accept': 'application/json',
            },

            data: {
                'apikey': `${apiKeyServidor}`,
                'sessionclient': `${sessionclient}`,
                'token': `${apiKeyServidor}`,
                'urlApi': `${urlApi}`,
            },
            url: 'retornaCoenexaoContainerWpp',
        };
        api(options).then((response) => {


            let statusConexaoContainer = response.data.conexao;
            let nivelBateria = response.data.nivelBateria;
            let modeloCelular = response.data.modeloCelular;
            let configuracoesInternasWpp = "OK";


            if (urlApi.includes("teste") || urlApi.includes("socket")) {
                if (statusConexaoContainer.status === 200) {
                    if (statusConexaoContainer.state === "desconnectedMobile" || statusConexaoContainer.state === "isLogout" || statusConexaoContainer.state === "tokenRemoved") {
                        //celular desconectado, o cliente chegou e desconectou entrando no whatsapp/configuracoes/aparelhosconectados e removeu a conexao

                        statusContainerWpp = "Token removido do Celular(CELULAR DESCONECTADO)"
                    } else if (statusConexaoContainer.state === "notLogged" || statusConexaoContainer.state === "autocloseCalled" || statusConexaoContainer.state === "disconnected") {

                        statusContainerWpp = "Desconectado"
                    } else {
                        statusContainerWpp = "Conectado"

                    }
                    //se tiver numero do celular, envia o numero
                    if (statusConexaoContainer.device) {
                        modificaNumeroConectadoWpp(dispatch, statusConexaoContainer.device);
                    }
                } else {
                    statusContainerWpp = "Desconectado"

                }

                configuracoesInternasWpp = 'OK';
                //statusContainerWpp = "OK";
                modificanivelBateria(dispatch, nivelBateria === "ERROR" || nivelBateria.status === 404 ? 0 : nivelBateria.level);
                modificamodeloCelular(dispatch, modeloCelular === "ERROR" || modeloCelular.status === 404 || modeloCelular.message === "there was an error in the call, check all parameters" ? "" : modeloCelular.device_manufacturer + " WPP VERSION" + "(" + modeloCelular.wa_version + ")");
                modificaversaoOS(dispatch, modeloCelular === "ERROR" || modeloCelular.status === 404 || modeloCelular.message === "there was an error in the call, check all parameters" ? "" : modeloCelular.device_model);
                modificamemoriaContainerWpp(dispatch, "");
                modificastatusContainerWpp(dispatch, statusContainerWpp);
                modificaUrlApiListaEmpresa(dispatch, urlApi);
                modificaContainerListaEmpresa(dispatch, sessionclient);
                ativaDesativaLoadingRelatorioCRM(dispatch, false);
                modificaloadingBotaoAtualizaStatusConexaoWpp(dispatch, false);
            } else {
                if (statusConexaoContainer.result === 200) {
                    if (statusConexaoContainer.status === "desconnectedMobile") {
                        //celular desconectado, o cliente chegou e desconectou entrando no whatsapp/configuracoes/aparelhosconectados e removeu a conexao

                        statusContainerWpp = "Token removido do Celular(CELULAR DESCONECTADO)"
                    } else if (statusConexaoContainer.status === "notLogged" || statusConexaoContainer.status === "autocloseCalled") {

                        statusContainerWpp = "Desconectado"
                    } else {
                        statusContainerWpp = "Conectado"
                    }

                } else {
                    statusContainerWpp = "Desconectado"

                }

                configuracoesInternasWpp = 'OK';
                //statusContainerWpp = "OK";
                modificanivelBateria(dispatch, nivelBateria.result === 400 || nivelBateria.result === 406 ? 0 : nivelBateria.batterylevel);
                modificamodeloCelular(dispatch, modeloCelular.result === 400 ? "" : modeloCelular.phone.device_manufacturer);
                modificaversaoOS(dispatch, modeloCelular.result === 400 ? "" : modeloCelular.phone.os_version);
                modificamemoriaContainerWpp(dispatch, "");
                modificastatusContainerWpp(dispatch, statusContainerWpp);
                modificaUrlApiListaEmpresa(dispatch, urlApi);
                modificaContainerListaEmpresa(dispatch, sessionclient);
                ativaDesativaLoadingRelatorioCRM(dispatch, false);
                modificaloadingBotaoAtualizaStatusConexaoWpp(dispatch, false);
            }
        }).catch(() => {

            configuracoesInternasWpp = configuracoesInternasWpp + `ERROR\nContainer Desconectado\n`;
            statusContainerWpp = "Container Não encontrado"
            modificamemoriaContainerWpp(dispatch, "");
            modificastatusContainerWpp(dispatch, statusContainerWpp);
            modificaconfiguracoesInternasWpp(dispatch, configuracoesInternasWpp);
            modificaUrlApiListaEmpresa(dispatch, urlApi);
            modificaContainerListaEmpresa(dispatch, sessionclient);
            modificanivelBateria(dispatch, 0);
            modificamodeloCelular(dispatch, "");
            modificaversaoOS(dispatch, "");
            ativaDesativaLoadingRelatorioCRM(dispatch, false);
            modificaloadingBotaoAtualizaStatusConexaoWpp(dispatch, false);
        })
    }
}
export const listaStatusContaClientePainelAdm = (email2, dadosUsuarioPesquisadoPainelAdm) => {
    return dispatch => {
        let email = email2.trim();

        if (email === "") {
            toastr.error("Error", "Informe o Email Principal da Conta")
            modificaInfoUsuarioPainelAdm(dispatch, [])
        } else {

            ativaDesativaLoadingRelatorioCRM(dispatch, true);
            AsyncStorage.getItem('chaveUnica').then((chaveUnica2) => {
                const chaveUnica = chaveUnica2.replace(/"/g, "");
                const options = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                        'Accept': 'application/json',
                    },

                    data: { email: email, chaveUnica },
                    url: 'getDataAccount',
                };
                api(options).then((response) => {

                    const dadosUsuario = response.data;
                    const { snapshotDadosAssinante, snapshotPremium, snapshotUserPrincipal, uid, snapshotUsuariosAllWpp, snapshotWhatsApp } = dadosUsuario;
                    if (!snapshotPremium) {
                        toastr.error("Error", "Este email, não é o Email Principal da conta")
                        enviaArrayDeDadosDaEmpresaPainelAdm(dispatch, [])
                        ativaDesativaLoadingRelatorioCRM(dispatch, false);
                    } else {
                        let verificaModalVersaoPremiumEsgotou = 0;
                        let verificaQuantidadeAtendentesLiberado;
                        let arrayEmpresa = [];
                        let keyEmpresa = '';
                        let descricaoPlano = '';
                        let numeroConectado = "";
                        let descricaoValidade;
                        let identificadorUnicoDaCompra;
                        let dataAtualVerificacaoAssinatura;
                        let dataValidadeAtualVerificacao;
                        let verificaAcabouAssinaturaPremium;
                        let arrayParaEnviarComDados = []
                        let arrayPrincipal = []
                        let dataAtual = moment().toDate();
                        dataAtual = moment(dataAtual).format('DD/MM/YYYY');
                        keyEmpresa = snapshotPremium.key;
                        arrayEmpresa.push({
                            key: snapshotPremium.key,
                            dataCompra: snapshotPremium.dataCompra,
                            dataValidoAte: snapshotPremium.dataValidoAte,
                            premium: snapshotPremium.premium,
                            dataValidadeAtual: snapshotPremium.dataValidadeAtual === undefined ? "" : snapshotPremium.dataValidadeAtual,
                            numeroPedidoCompra: snapshotPremium.numeroPedidoCompra === undefined ? "" : snapshotPremium.numeroPedidoCompra,
                            plano: snapshotPremium.plano === undefined ? "" : snapshotPremium.plano,
                            valorPlano: snapshotPremium.valorPlanoRel === undefined ? "" : snapshotPremium.valorPlanoRel,
                            plataforma: snapshotPremium.plataforma === undefined ? "" : snapshotPremium.plataforma,
                            qtdAtendentesLiberados: snapshotPremium.qtdAtendentesLiberados,
                            statusAssinatura: snapshotPremium.statusAssinatura,
                            identificadorUnicoDaCompra: snapshotPremium.identificadorUnicoDaCompra,
                            uidComprador: snapshotPremium.uidComprador,
                            empresa: snapshotPremium.empresa,
                            segmento: snapshotPremium.segmento === undefined ? "" : snapshotPremium.segmento,
                            nomeAssinante: '',
                            emailAssinante: '',
                            numberConfirmation: snapshotPremium.numberConfirmation === undefined ? "" : snapshotPremium.numberConfirmation,
                            qtdVersaoTesteLiberada: snapshotPremium.qtdVersaoTesteLiberada === undefined ? 0 : snapshotPremium.qtdVersaoTesteLiberada,
                            licensawebouapp: snapshotPremium.licensawebouapp == undefined || snapshotPremium.licensawebouapp == 0 ? 1 : snapshotPremium.licensawebouapp,
                            motivoCancelamento: snapshotPremium.motivoNaoRenovacao === undefined ? "" : snapshotPremium.motivoNaoRenovacao,
                        })
                        arrayEmpresa[0]['chaveUnica'] = uid;
                        //verificacao se tem versao Premium
                        if (arrayEmpresa[0].dataValidoAte !== '') {

                            //verifica se acabou assinatura premium ios quando tirou renovacao automatica
                            if (arrayEmpresa[0].dataValidadeAtual !== '') {
                                dataAtualVerificacaoAssinatura = moment(dataAtual, 'DD/MM/YYYY').format('YYYY-MM-DD');
                                dataValidadeAtualVerificacao = moment(arrayEmpresa[0].dataValidadeAtual, 'DD/MM/YYYY').format('YYYY-MM-DD');
                                verificaAcabouAssinaturaPremium = moment(dataValidadeAtualVerificacao).isBefore(dataAtualVerificacaoAssinatura);
                            }
                            //verifica se acabou versao premium comum
                            let dataAtualVerificacao = moment(dataAtual, 'DD/MM/YYYY').format('YYYY-MM-DD');
                            let dataValidoAteVerificacao = moment(arrayEmpresa[0].dataValidoAte, 'DD/MM/YYYY').format('YYYY-MM-DD');
                            let verificaAcabouPremium = moment(dataValidoAteVerificacao).isBefore(dataAtualVerificacao);
                            //
                            //acabou o periodo de versão premium
                            if (verificaAcabouPremium) {

                                arrayEmpresa[0]['versao'] = 'Expirado (Sem versão Premium)'

                                let arrayUserPrincipal = [];

                                arrayUserPrincipal.push({
                                    email: snapshotUserPrincipal.email,
                                    senha: snapshotUserPrincipal.senha,
                                })

                                arrayEmpresa[0]['email'] = arrayUserPrincipal[0].email
                                arrayEmpresa[0]['senha'] = arrayUserPrincipal[0].senha


                                //  arrayPrincipal =  arrayParaEnviarComDados.concat(arrayUserPrincipal)
                                enviaArrayDeDadosDaEmpresaPainelAdm(dispatch, arrayEmpresa);
                                //  ativaDesativaLoadingRelatorioCRM(dispatch, false);

                            }
                            //aqui é pra verificar se a licenca do cliente expirou quando a licenca é da apple e ele tirou a renovacao automatica
                            else if (arrayEmpresa[0].statusAssinatura == 'tirourenovacaoautomatica' && verificaAcabouAssinaturaPremium == true) {

                                arrayEmpresa[0]['versao'] = 'Expirado (Sem versão Premium IOS)'

                                let arrayUserPrincipal = [];

                                arrayUserPrincipal.push({
                                    email: snapshotUserPrincipal.email,
                                    senha: snapshotUserPrincipal.senha,
                                })

                                arrayEmpresa[0]['email'] = arrayUserPrincipal[0].email
                                arrayEmpresa[0]['senha'] = arrayUserPrincipal[0].senha


                                //  arrayPrincipal =  arrayParaEnviarComDados.concat(arrayUserPrincipal)
                                enviaArrayDeDadosDaEmpresaPainelAdm(dispatch, arrayEmpresa);
                                //  ativaDesativaLoadingRelatorioCRM(dispatch, false);

                            }
                            //ainda tem versão premium
                            else {

                                let diferencaDia = calculaDias2(arrayEmpresa[0].dataCompra, arrayEmpresa[0].dataValidoAte);
                                //

                                if (diferencaDia >= 28 && arrayEmpresa[0].dataValidadeAtual === '') {

                                    arrayEmpresa[0]['versao'] = 'Versão Teste 30 dias'

                                } else if (diferencaDia <= 7 && arrayEmpresa[0].dataValidadeAtual === '') {
                                    //

                                    arrayEmpresa[0]['versao'] = 'Versão Teste 7 dias'
                                } else {

                                    arrayEmpresa[0]['versao'] = 'Versão Premium ATIVO'
                                    /*  enviaSeEhVersaoPremium(dispatch, 1);
                                     //  ativaDesativaLoadingRenderVersaoPremium(dispatch, false);
                                     enviaStatusAssinaturaPremium(dispatch, arrayEmpresa[0].statusAssinatura);
                                     enviaDataValidadeVersaoPremium(dispatch, arrayEmpresa[0].dataValidoAte);
                                     enviaCorTextStatusAssinatura(dispatch, '#00FF7F')
                                     enviaTextStatusAssinatura(dispatch, 'ATIVA');
                                     enviaPremiumVersaoGartis(dispatch, false);
                                     enviaDataCompraVersaoPremium(dispatch, arrayEmpresa[0].dataCompra);
                                     enviaIdentificadorUnicoDaCompra(dispatch, arrayEmpresa[0].identificadorUnicoDaCompra); */
                                    //pega Dados Usuario
                                    if (arrayEmpresa[0].uidComprador !== undefined && arrayEmpresa[0].uidComprador !== null && snapshotDadosAssinante) {

                                        let arrayNomeQuemComprouLicenca = [];

                                        arrayNomeQuemComprouLicenca.push({
                                            nomeAssinante: snapshotDadosAssinante.nome,
                                            emailAssinante: snapshotDadosAssinante.email,

                                        });


                                        arrayEmpresa[0]['nomeAssinante'] = arrayNomeQuemComprouLicenca[0].nomeAssinante
                                        arrayEmpresa[0]['emailAssinante'] = arrayNomeQuemComprouLicenca[0].emailAssinante
                                        // arrayParaEnviarComDados = arrayEmpresa.concat(arrayNomeQuemComprouLicenca)


                                        //  enviaEmailAssinante(dispatch, arrayNomeQuemComprouLicenca[0].emailAssinante)
                                        // enviaNomeAssinante(dispatch, arrayNomeQuemComprouLicenca[0].nomeAssinante)

                                    }
                                    if (arrayEmpresa[0].plano !== undefined && arrayEmpresa[0].plano !== null) {

                                    }
                                    if (arrayEmpresa[0].dataValidadeAtual !== '' && arrayEmpresa[0].dataValidadeAtual !== null) {

                                    }

                                    //verifico se o cliente tem qtd de atendentes liberados na empresa, porque se nao tiver esta na versao premium/gratis antiga

                                }

                                let arrayUserPrincipal = [];

                                arrayUserPrincipal.push({
                                    email: snapshotUserPrincipal.email,
                                    senha: snapshotUserPrincipal.senha,
                                })

                                arrayEmpresa[0]['email'] = arrayUserPrincipal[0].email
                                arrayEmpresa[0]['senha'] = arrayUserPrincipal[0].senha


                                //  arrayPrincipal =  arrayParaEnviarComDados.concat(arrayUserPrincipal)
                                enviaArrayDeDadosDaEmpresaPainelAdm(dispatch, arrayEmpresa);
                                //  ativaDesativaLoadingRelatorioCRM(dispatch, false);

                            }
                        } else {

                            arrayEmpresa[0]['versao'] = 'Expirado (Sem versão Premium)'
                            //dados assinatura
                            if (arrayEmpresa[0].dataValidadeAtual !== '' || arrayEmpresa[0].dataValidadeAtual !== null) {

                            }


                            if (arrayEmpresa[0].uidComprador !== undefined && arrayEmpresa[0].uidComprador !== null && snapshotDadosAssinante) {

                                let arrayNomeQuemComprouLicenca = [];

                                arrayNomeQuemComprouLicenca.push({
                                    nomeAssinante: snapshotDadosAssinante.nome,
                                    emailAssinante: snapshotDadosAssinante.email,

                                });

                                // arrayParaEnviarComDados = arrayEmpresa.concat(arrayNomeQuemComprouLicenca)

                                arrayEmpresa[0]['nomeAssinante'] = arrayNomeQuemComprouLicenca[0].nomeAssinante
                                arrayEmpresa[0]['emailAssinante'] = arrayNomeQuemComprouLicenca[0].emailAssinante


                                //
                                //  enviaEmailAssinante(dispatch, arrayNomeQuemComprouLicenca[0].emailAssinante)
                                //  enviaNomeAssinante(dispatch, arrayNomeQuemComprouLicenca[0].nomeAssinante)

                            }

                            let arrayUserPrincipal = [];

                            arrayUserPrincipal.push({
                                email: snapshotUserPrincipal.email,
                                senha: snapshotUserPrincipal.senha,
                            })

                            arrayEmpresa[0]['email'] = arrayUserPrincipal[0].email
                            arrayEmpresa[0]['senha'] = arrayUserPrincipal[0].senha


                            //  arrayPrincipal =  arrayParaEnviarComDados.concat(arrayUserPrincipal)
                            enviaArrayDeDadosDaEmpresaPainelAdm(dispatch, arrayEmpresa)



                        }

                        let dadosEmpresa = [];
                        let memoriaContainerWpp = '';
                        let statusContainerWpp = '';
                        let configuracoesInternasWpp = '';
                        let statusConexaoWppPainelAdm = '';
                        let arrayInfousuario = [];
                        let utilizaVersaoBeta = 'não'
                        arrayInfousuario.push(dadosUsuario)
                        modificaInfoUsuarioPainelAdm(dispatch, arrayInfousuario)


                        if (!snapshotWhatsApp) {
                            dispatch({ type: MODIFICAUTILIZAVERSAOBETAPAINELADM, payload: 'não' })
                            // enviaDadosWhatsappConfig(dispatch, [])
                            modificaUrlApiListaEmpresa(dispatch, "");
                            modificaContainerListaEmpresa(dispatch, "");
                            modificaNumeroConectadoWpp(dispatch, "");

                            modificamemoriaContainerWpp(dispatch, "");
                            modificastatusContainerWpp(dispatch, "");
                            modificastatusConexaoWppPainelAdm(dispatch, "Não utiliza WhatsApp");
                            modificaconfiguracoesInternasWpp(dispatch, "");

                            modificanivelBateria(dispatch, 0);
                            modificamodeloCelular(dispatch, "");
                            modificaversaoOS(dispatch, "");

                            ativaDesativaLoadingRelatorioCRM(dispatch, false);

                        } else {

                            let dadosEmpresa = [];

                            dadosEmpresa.push({
                                sessionclient: snapshotWhatsApp.sessionclient,
                                statusConnect: snapshotWhatsApp.statusConnect,
                                apiKey: snapshotWhatsApp.apiKey,
                                urlApi: snapshotWhatsApp.urlApi,
                                qrCode: snapshotWhatsApp.qrCode === undefined ? '' : snapshotWhatsApp.qrCode,
                                numeroConectado: snapshotWhatsApp.numeroConectado === undefined ? '' : snapshotWhatsApp.numeroConectado,
                                key: snapshotWhatsApp.key,
                                serverandport: snapshotWhatsApp.serverandport,
                                utilizaVersaoBETA: snapshotWhatsApp.utilizaVersaoBETA ? snapshotWhatsApp.utilizaVersaoBETA : false,
                            })

                            statusConexaoWppPainelAdm = dadosEmpresa[0].statusConnect;

                            modificaNumeroConectadoWpp(dispatch, dadosEmpresa[0].numeroConectado);
                            dispatch({ type: MODIFICAUTILIZAVERSAOBETAPAINELADM, payload: dadosEmpresa[0].utilizaVersaoBETA ? 'sim' : 'não' })
                            if (!snapshotUsuariosAllWpp) {
                                configuracoesInternasWpp = configuracoesInternasWpp + `ERROR\nConfiguração UsuariosAllWpp não encontrado\n`;
                                statusContainerWpp = "Container Não encontrado";
                                //statusConexaoWppPainelAdm = "Desconectado";
                                modificaDataConexao(dispatch, "");
                                modificamemoriaContainerWpp(dispatch, "");
                                modificastatusContainerWpp(dispatch, statusContainerWpp);
                                modificaconfiguracoesInternasWpp(dispatch, configuracoesInternasWpp);
                                modificastatusConexaoWppPainelAdm(dispatch, statusConexaoWppPainelAdm);

                                modificanivelBateria(dispatch, 0);
                                modificamodeloCelular(dispatch, "");
                                modificaversaoOS(dispatch, "");
                                modificaUrlApiListaEmpresa(dispatch, dadosEmpresa[0].urlApi);
                                modificaContainerListaEmpresa(dispatch, dadosEmpresa[0].sessionclient);
                                ativaDesativaLoadingRelatorioCRM(dispatch, false);
                            } else {

                                let dataUltimaConexao = "";

                                dataUltimaConexao = snapshotUsuariosAllWpp.dataAbertura === undefined ? '' : snapshotUsuariosAllWpp.dataAbertura


                                modificaDataConexao(dispatch, dataUltimaConexao);
                                const options = {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json; charset=utf-8',
                                        'Accept': 'application/json',
                                    },

                                    data: {
                                        'sessionclient': `${dadosEmpresa[0].sessionclient}`,
                                        'urlApi': `${dadosEmpresa[0].urlApi}`,
                                    },
                                    url: 'retornaCoenexaoContainerWpp',
                                };
                                api(options).then((response) => {


                                    let statusConexaoContainer = response.data.conexao;
                                    let nivelBateria = response.data.nivelBateria;
                                    let modeloCelular = response.data.modeloCelular;


                                    if (dadosEmpresa[0].urlApi.includes("teste") || dadosEmpresa[0].urlApi.includes("socket")) {
                                        if (statusConexaoContainer.status === 200) {
                                            if (statusConexaoContainer.state === "desconnectedMobile" || statusConexaoContainer.state === "isLogout" || statusConexaoContainer.state === "tokenRemoved") {
                                                //celular desconectado, o cliente chegou e desconectou entrando no whatsapp/configuracoes/aparelhosconectados e removeu a conexao

                                                statusContainerWpp = "Token removido do Celular(CELULAR DESCONECTADO)"
                                            } else if (statusConexaoContainer.state === "notLogged" || statusConexaoContainer.state === "autocloseCalled" || statusConexaoContainer.state === "disconnected") {

                                                statusContainerWpp = "Desconectado"
                                            } else {
                                                statusContainerWpp = "Conectado"

                                            }
                                            //se tiver numero do celular, envia o numero
                                            if (statusConexaoContainer.device) {
                                                modificaNumeroConectadoWpp(dispatch, statusConexaoContainer.device);
                                            }
                                        } else {
                                            statusContainerWpp = "Desconectado"

                                        }

                                        configuracoesInternasWpp = 'OK';
                                        //statusContainerWpp = "OK";
                                        modificanivelBateria(dispatch, nivelBateria === "ERROR" || nivelBateria.status === 404 || !nivelBateria.level ? 0 : nivelBateria.level);
                                        modificamodeloCelular(dispatch, modeloCelular === "ERROR" || modeloCelular.status === 404 || modeloCelular.message === "there was an error in the call, check all parameters" ? "" : modeloCelular.device_manufacturer && modeloCelular.wa_version ? modeloCelular.device_manufacturer + " WPP VERSION" + "(" + modeloCelular.wa_version + ")" : '');
                                        modificaversaoOS(dispatch, modeloCelular === "ERROR" || modeloCelular.status === 404 || modeloCelular.message === "there was an error in the call, check all parameters" ? "" : modeloCelular.device_model ? modeloCelular.device_model : '');
                                        modificamemoriaContainerWpp(dispatch, "");
                                        modificastatusContainerWpp(dispatch, statusContainerWpp);
                                        modificaconfiguracoesInternasWpp(dispatch, configuracoesInternasWpp);
                                        modificastatusConexaoWppPainelAdm(dispatch, statusConexaoWppPainelAdm);
                                        modificaUrlApiListaEmpresa(dispatch, dadosEmpresa[0].urlApi);
                                        modificaContainerListaEmpresa(dispatch, dadosEmpresa[0].sessionclient);
                                        ativaDesativaLoadingRelatorioCRM(dispatch, false);
                                    } else {
                                        if (statusConexaoContainer.result === 200) {
                                            if (statusConexaoContainer.status === "desconnectedMobile") {
                                                //celular desconectado, o cliente chegou e desconectou entrando no whatsapp/configuracoes/aparelhosconectados e removeu a conexao

                                                statusContainerWpp = "Token removido do Celular(CELULAR DESCONECTADO)"
                                            } else if (statusConexaoContainer.status === "notLogged" || statusConexaoContainer.status === "autocloseCalled") {

                                                statusContainerWpp = "Desconectado"
                                            } else {
                                                statusContainerWpp = "Conectado"
                                            }

                                        } else {
                                            statusContainerWpp = "Desconectado"

                                        }

                                        configuracoesInternasWpp = 'OK';
                                        //statusContainerWpp = "OK";
                                        modificanivelBateria(dispatch, nivelBateria.result === 400 || nivelBateria.result === 406 || !nivelBateria.batterylevel ? 0 : nivelBateria.batterylevel);
                                        modificamodeloCelular(dispatch, modeloCelular.result === 400 || !modeloCelular.phone.device_manufacturer ? "" : modeloCelular.phone.device_manufacturer);
                                        modificaversaoOS(dispatch, modeloCelular.result === 400 || !modeloCelular.phone.os_version ? "" : modeloCelular.phone.os_version);
                                        modificamemoriaContainerWpp(dispatch, "");
                                        modificastatusContainerWpp(dispatch, statusContainerWpp);
                                        modificaconfiguracoesInternasWpp(dispatch, configuracoesInternasWpp);
                                        modificastatusConexaoWppPainelAdm(dispatch, statusConexaoWppPainelAdm);
                                        modificaUrlApiListaEmpresa(dispatch, dadosEmpresa[0].urlApi);
                                        modificaContainerListaEmpresa(dispatch, dadosEmpresa[0].sessionclient);
                                        ativaDesativaLoadingRelatorioCRM(dispatch, false);
                                    }
                                }).catch(() => {

                                    configuracoesInternasWpp = configuracoesInternasWpp + `ERROR\nContainer Desconectado\n`;
                                    statusContainerWpp = "Container Não encontrado"
                                    modificamemoriaContainerWpp(dispatch, "");
                                    modificastatusContainerWpp(dispatch, statusContainerWpp);
                                    modificaconfiguracoesInternasWpp(dispatch, configuracoesInternasWpp);
                                    modificastatusConexaoWppPainelAdm(dispatch, statusConexaoWppPainelAdm);
                                    modificaUrlApiListaEmpresa(dispatch, dadosEmpresa[0].urlApi);
                                    modificaContainerListaEmpresa(dispatch, dadosEmpresa[0].sessionclient);
                                    modificanivelBateria(dispatch, 0);
                                    modificamodeloCelular(dispatch, "");
                                    modificaversaoOS(dispatch, "");
                                    ativaDesativaLoadingRelatorioCRM(dispatch, false);
                                })
                            }

                            //enviaDadosWhatsappConfig(dispatch, dadosEmpresa)

                        }



                    }


                }).catch((e) => {
                    console.log(e)
                    toastr.error("Error", "Email Inexistente")
                    modificaInfoUsuarioPainelAdm(dispatch, [])
                    enviaArrayDeDadosDaEmpresaPainelAdm(dispatch, [])
                    ativaDesativaLoadingRelatorioCRM(dispatch, false);
                })
            })
        }
    }
}
export const listaConversasAnterioresChatAtualEmEspera = (ultimaKey, keyAtendimento, conversasAnteriores) => {

    return dispatch => {
        ativaDesativaLoadingCarregandoConversasChatAtualEmEspera(dispatch, true);
        AsyncStorage.getItem('chaveUnica').then((chaveUnica2) => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            if (ultimaKey != undefined && ultimaKey != "") {
                firebase.database().ref(`${chaveUnica}/acoesChamados/${keyAtendimento}`).orderByKey()
                    .endAt(ultimaKey).limitToLast(15).once('value').then((snapshot) => {
                        if (snapshot.val()) {
                            let arrayAcoesAtendimento = [];
                            let arrayOfKeys = Object.keys(snapshot.val()).sort().reverse().slice(1);
                            let referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
                            /*  */
                            snapshot.forEach(childItem => {
                                /*    console.log(childItem.val()) */
                                if (childItem.val().tipo !== undefined) {
                                    arrayAcoesAtendimento.push({
                                        key: childItem.key,
                                        title: childItem.val().titulo,
                                        tipo: childItem.val().tipo,
                                        description: childItem.val().mensagem,
                                        statusMessage: childItem.val().statusMessage == undefined ? 'sent' : childItem.val().statusMessage,
                                        time: childItem.val().time,
                                        dataHoraAtual: childItem.val().dataHoraAtual == undefined ? childItem.val().mensagem : childItem.val().dataHoraAtual,
                                        lineColor: childItem.val().lineColor,
                                        circleColor: childItem.val().circleColor,
                                        urlDownload: childItem.val().urlDownload,
                                        tipoArquivo: childItem.val().tipoArquivo,
                                        duracao: childItem.val().duracao == undefined ? '' : childItem.val().duracao,
                                        audio: childItem.val().audio == undefined ? false : childItem.val().audio,
                                        visivelATodos: childItem.val().visivelATodos == undefined ? 1 : childItem.val().visivelATodos,
                                        idMensagem: childItem.val().idMensagem == undefined ? childItem.key : childItem.val().idMensagem === '' ? childItem.key : childItem.val().idMensagem,
                                        circleColorResponder: childItem.val().circleColorResponder == undefined ? '#00FF7F' : childItem.val().circleColorResponder,
                                        commentResponderType: childItem.val().commentResponderType == undefined ? '' : childItem.val().commentResponderType,
                                        idMensagemResponder: childItem.val().idMensagemResponder == undefined ? '' : childItem.val().idMensagemResponder,
                                        mensagemResposta: childItem.val().mensagemResposta == undefined ? '' : childItem.val().mensagemResposta,
                                        possuiResposta: childItem.val().possuiResposta == undefined ? false : childItem.val().possuiResposta,
                                        commentResponderTypeArquivo: childItem.val().commentResponderTypeArquivo == undefined ? '' : childItem.val().commentResponderTypeArquivo,
                                        commentResponderIsAudio: childItem.val().commentResponderIsAudio == undefined ? '' : childItem.val().commentResponderIsAudio,
                                        atendenteResponder: childItem.val().atendenteResponder == undefined ? '' : childItem.val().atendenteResponder,
                                        captionArquivo: childItem.val().captionArquivo == undefined ? '' : childItem.val().captionArquivo,
                                        read: childItem.val().read == undefined ? false : childItem.val().read,
                                        keyAtendimentoResponder: childItem.val().keyAtendimentoResponder == undefined ? '' : childItem.val().keyAtendimentoResponder,
                                        deleteMessage: childItem.val().deleteMessage == undefined ? false : childItem.val().deleteMessage,

                                    });
                                }
                            });

                            let arrayAcoes = arrayAcoesAtendimento.reverse().slice(1);

                            enviaConversasCarregadasEmEspera(dispatch, conversasAnteriores.concat(arrayAcoes))
                            enviaUltimaKeyReferenciaCOnversasCarregadasChatEmEspera(dispatch, referenceToOldestKey);
                            setTimeout(() => {
                                ativaDesativaLoadingCarregandoConversasChatAtualEmEspera(dispatch, false);
                            }, 500);
                        } else {

                            setTimeout(() => {
                                ativaDesativaLoadingCarregandoConversasChatAtualEmEspera(dispatch, false);
                            }, 500);
                        }
                    })
            } else {
                setTimeout(() => {
                    ativaDesativaLoadingCarregandoConversasChatAtualEmEspera(dispatch, false);
                }, 500);
            }
            // ativaDesativaLoadingCarregandoConversasChatAtual(dispatch, false);
        })
    }
}
export const listaConversasAnterioresChatAtual = (ultimaKey, keyAtendimento, conversasAnteriores) => {

    return dispatch => {
        ativaDesativaLoadingCarregandoConversasChatAtual(dispatch, true);
        AsyncStorage.getItem('chaveUnica').then((chaveUnica2) => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            if (ultimaKey != undefined && ultimaKey != "") {
                firebase.database().ref(`${chaveUnica}/acoesChamados/${keyAtendimento}`).orderByKey()
                    .endAt(ultimaKey).limitToLast(15).once('value').then((snapshot) => {
                        if (snapshot.val()) {
                            let arrayAcoesAtendimento = [];
                            let arrayOfKeys = Object.keys(snapshot.val()).sort().reverse().slice(1);
                            let referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
                            /*  */
                            snapshot.forEach(childItem => {
                                /*    console.log(childItem.val()) */
                                if (childItem.val().tipo !== undefined) {
                                    arrayAcoesAtendimento.push({
                                        key: childItem.key,
                                        title: childItem.val().titulo,
                                        tipo: childItem.val().tipo,
                                        description: childItem.val().mensagem,
                                        statusMessage: childItem.val().statusMessage == undefined ? 'sent' : childItem.val().statusMessage,
                                        time: childItem.val().time,
                                        dataHoraAtual: childItem.val().dataHoraAtual == undefined ? childItem.val().mensagem : childItem.val().dataHoraAtual,
                                        lineColor: childItem.val().lineColor,
                                        circleColor: childItem.val().circleColor,
                                        urlDownload: childItem.val().urlDownload,
                                        tipoArquivo: childItem.val().tipoArquivo,
                                        duracao: childItem.val().duracao == undefined ? '' : childItem.val().duracao,
                                        audio: childItem.val().audio == undefined ? false : childItem.val().audio,
                                        visivelATodos: childItem.val().visivelATodos == undefined ? 1 : childItem.val().visivelATodos,
                                        idMensagem: childItem.val().idMensagem == undefined ? childItem.key : childItem.val().idMensagem === '' ? childItem.key : childItem.val().idMensagem,
                                        circleColorResponder: childItem.val().circleColorResponder == undefined ? '#00FF7F' : childItem.val().circleColorResponder,
                                        commentResponderType: childItem.val().commentResponderType == undefined ? '' : childItem.val().commentResponderType,
                                        idMensagemResponder: childItem.val().idMensagemResponder == undefined ? '' : childItem.val().idMensagemResponder,
                                        mensagemResposta: childItem.val().mensagemResposta == undefined ? '' : childItem.val().mensagemResposta,
                                        possuiResposta: childItem.val().possuiResposta == undefined ? false : childItem.val().possuiResposta,
                                        commentResponderTypeArquivo: childItem.val().commentResponderTypeArquivo == undefined ? '' : childItem.val().commentResponderTypeArquivo,
                                        commentResponderIsAudio: childItem.val().commentResponderIsAudio == undefined ? '' : childItem.val().commentResponderIsAudio,
                                        atendenteResponder: childItem.val().atendenteResponder == undefined ? '' : childItem.val().atendenteResponder,
                                        captionArquivo: childItem.val().captionArquivo == undefined ? '' : childItem.val().captionArquivo,
                                        read: childItem.val().read == undefined ? false : childItem.val().read,
                                        keyAtendimentoResponder: childItem.val().keyAtendimentoResponder == undefined ? '' : childItem.val().keyAtendimentoResponder,
                                        deleteMessage: childItem.val().deleteMessage == undefined ? false : childItem.val().deleteMessage,

                                    });
                                }
                            });

                            let arrayAcoes = arrayAcoesAtendimento.reverse().slice(1);

                            enviaConversasCarregadasEmAtendimento(dispatch, conversasAnteriores.concat(arrayAcoes))
                            enviaUltimaKeyReferenciaCOnversasCarregadasChat(dispatch, referenceToOldestKey);
                            setTimeout(() => {
                                ativaDesativaLoadingCarregandoConversasChatAtual(dispatch, false);
                            }, 500);

                        } else {

                            setTimeout(() => {
                                ativaDesativaLoadingCarregandoConversasChatAtual(dispatch, false);
                            }, 500);
                        }
                    })
            } else {
                setTimeout(() => {
                    ativaDesativaLoadingCarregandoConversasChatAtual(dispatch, false);
                }, 500);
            }
            // ativaDesativaLoadingCarregandoConversasChatAtual(dispatch, false);
        })
    }
}
export const exportaCrmParaExcel = (arrayChamados, operacao) => {
    return dispatch => {

        AsyncStorage.getItem('chaveUnica').then((chaveUnica2) => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            toastr.warning('Gerando Relatório', 'Por favor aguarde...');
            //

            let results = [];
            if (operacao == 'planosmaisassinados') {
                for (let i = 0; i < arrayChamados.length; i++) {
                    if (arrayChamados[i].planos !== undefined && arrayChamados[i].planos !== null) {
                        results.push(arrayChamados[i].planos)
                    }
                }
                let chamados = [];
                for (let i = 0; i < results.length; i++) {
                    chamados.push({
                        quantidade: results[i].value,
                        plano: results[i].key,
                    });
                }

                let colunas = ['Quantidade', 'Plano'];

                exportaRelatorioCRMparaExcel(colunas, chamados, dispatch, 'planos');


                //enviaChamadoAberto(dispatch, chamados);

            }
            if (operacao == 'estados') {

                for (let i = 0; i < arrayChamados.length; i++) {
                    if (arrayChamados[i].estados !== undefined && arrayChamados[i].estados !== null) {
                        results.push(arrayChamados[i].estados)
                    }
                }

                let chamados = [];
                for (let i = 0; i < results.length; i++) {
                    chamados.push({
                        quantidade: results[i].value,
                        estado: results[i].key,
                    });
                }
                let colunas = ['Quantidade', 'Estado'];
                exportaRelatorioCRMparaExcel(colunas, chamados, dispatch, 'estados');


            }
            if (operacao == 'segmentos') {
                for (let i = 0; i < arrayChamados.length; i++) {
                    if (arrayChamados[i].segmentos !== undefined && arrayChamados[i].segmentos !== null) {
                        results.push(arrayChamados[i].segmentos)
                    }
                }

                let chamados = [];
                for (let i = 0; i < results.length; i++) {
                    chamados.push({
                        quantidade: results[i].value,
                        segmento: results[i].key,

                    });
                }
                //
                let colunas = ['Quantidade', 'Segmento'];

                exportaRelatorioCRMparaExcel(colunas, chamados, dispatch, 'segmentos');


            }
            if (operacao == 'clientes') {

                for (let i = 0; i < arrayChamados.length; i++) {
                    if (arrayChamados[i].clientes !== undefined && arrayChamados[i].clientes !== null) {
                        results.push(arrayChamados[i].clientes)
                    }
                }

                let chamados = [];
                for (let i = 0; i < results.length; i++) {
                    chamados.push({
                        statusAssinatura: results[i].status,
                        motivoCancelamento: results[i].status === 'CANCELADO' ? results[i].motivoCancelamento == '' || results[i].motivoCancelamento === undefined ? '' : results[i].motivoCancelamento : '',
                        clientePeloAnuncio: results[i].usuarioViaAnuncio,
                        nomeAssinante: results[i].nomeAssinante == '' ? 'Não Informado' : results[i].nomeAssinante,
                        emailAssinante: results[i].emailAssinante == '' ? 'Não Informado' : results[i].emailAssinante,
                        uf: results[i].estadoRel == '' ? 'Não Informado' : results[i].estadoRel,
                        dataPrimeiraCompra: results[i].dataHoraCompra,
                        dataUltimaCompra: results[i].dataHoraUltimoPagamento,
                        modoPagamento: results[i].modoPagamento == '' ? 'Não Informado' : results[i].modoPagamento,
                        valorPlano: results[i].valorPlanoRel == '' ? 'Não Informado' : results[i].valorPlanoRel,
                        planoMensalOuAnual: results[i].planoRel == '' ? 'Não Informado' : results[i].planoRel,
                        plataformaAssinada: results[i].plataformaRel == '' ? 'Não Informado' : results[i].plataformaRel,
                        empresaDoAssinante: results[i].segmento == '' ? 'Não Informado' : results[i].segmento,
                        keyEmpresa: results[i].keyEmpresaRel == '' ? 'Não Informado' : results[i].keyEmpresaRel,

                        historicoPagamento: results[i].historicoPagamento == '' || results[i].historicoPagamento === undefined ? '' : results[i].historicoPagamento,
                        idCobrancaJuno: results[i].idCobrancaJuno == '' || results[i].idCobrancaJuno === undefined ? '' : results[i].idCobrancaJuno,
                        numeroControlePix: results[i].numeroControlePix == '' || results[i].numeroControlePix === undefined ? '' : results[i].numeroControlePix,
                        novaAtivacao: results[i].novaAtivacao == '' || results[i].novaAtivacao === undefined ? '' : results[i].novaAtivacao,
                        chavePesquisa: results[i].chaveUnica == '' || results[i].chaveUnica === undefined ? '' : results[i].chaveUnica,
                    });
                }
                //
                let colunas = ['statusAssinatura', 'motivoCancelamento', 'clientePeloAnuncio', 'nomeAssinante', 'emailAssinante', 'UF', 'dataPrimeiraCompra', 'dataUltimaCompra', 'modoPagamento',
                    'valorPlano', 'planoMensalOuAnual', 'plataformaAssinada', 'empresaDoAssinante', 'keyEmpresa', 'historicoPagamento', 'idCobrancaJuno', 'numeroControlePix', 'novaAtivacao', 'chavePesquisa'];

                exportaRelatorioCRMparaExcel(colunas, chamados, dispatch, 'clientes');

            }
            if (operacao == 'ativacoes') {

                for (let i = 0; i < arrayChamados.length; i++) {
                    if (arrayChamados[i].clientes !== undefined && arrayChamados[i].clientes !== null) {
                        results.push(arrayChamados[i].clientes)
                    }
                }

                let chamados = [];
                for (let i = 0; i < results.length; i++) {
                    if (results[i].novaAtivacao === true || results[i].status === 'CANCELADO') {
                        if (results[i].status === 'CANCELADO') {

                        }
                        chamados.push({
                            statusAssinatura: results[i].status,
                            motivoCancelamento: results[i].status === 'CANCELADO' ? results[i].motivoCancelamento == '' || results[i].motivoCancelamento === undefined ? '' : results[i].motivoCancelamento : '',
                            clientePeloAnuncio: results[i].usuarioViaAnuncio,
                            nomeAssinante: results[i].nomeAssinante == '' ? 'Não Informado' : results[i].nomeAssinante,
                            emailAssinante: results[i].emailAssinante == '' ? 'Não Informado' : results[i].emailAssinante,
                            uf: results[i].estadoRel == '' ? 'Não Informado' : results[i].estadoRel,
                            dataPrimeiraCompra: results[i].dataHoraCompra,
                            dataUltimaCompra: results[i].dataHoraUltimoPagamento,
                            modoPagamento: results[i].modoPagamento == '' ? 'Não Informado' : results[i].modoPagamento,
                            valorPlano: results[i].valorPlanoRel == '' ? 'Não Informado' : results[i].valorPlanoRel,
                            planoMensalOuAnual: results[i].planoRel == '' ? 'Não Informado' : results[i].planoRel,
                            plataformaAssinada: results[i].plataformaRel == '' ? 'Não Informado' : results[i].plataformaRel,
                            empresaDoAssinante: results[i].segmento == '' ? 'Não Informado' : results[i].segmento,
                            keyEmpresa: results[i].keyEmpresaRel == '' ? 'Não Informado' : results[i].keyEmpresaRel,

                            historicoPagamento: results[i].historicoPagamento == '' || results[i].historicoPagamento === undefined ? '' : results[i].historicoPagamento,
                            idCobrancaJuno: results[i].idCobrancaJuno == '' || results[i].idCobrancaJuno === undefined ? '' : results[i].idCobrancaJuno,
                            numeroControlePix: results[i].numeroControlePix == '' || results[i].numeroControlePix === undefined ? '' : results[i].numeroControlePix,
                            novaAtivacao: results[i].novaAtivacao == '' || results[i].novaAtivacao === undefined ? '' : results[i].novaAtivacao,
                            chavePesquisa: results[i].chaveUnica == '' || results[i].chaveUnica === undefined ? '' : results[i].chaveUnica,
                        });
                    }
                }
                //
                let colunas = ['statusAssinatura', 'motivoCancelamento', 'clientePeloAnuncio', 'nomeAssinante', 'emailAssinante', 'UF', 'dataPrimeiraCompra', 'dataUltimaCompra', 'modoPagamento',
                    'valorPlano', 'planoMensalOuAnual', 'plataformaAssinada', 'empresaDoAssinante', 'keyEmpresa', 'historicoPagamento', 'idCobrancaJuno', 'numeroControlePix', 'novaAtivacao', 'chavePesquisa'];

                exportaRelatorioCRMparaExcel(colunas, chamados, dispatch, 'ativacoes');

            }
            if (operacao == 'segmentosCancelados') {

                for (let i = 0; i < arrayChamados.length; i++) {
                    if (arrayChamados[i].clientes !== undefined && arrayChamados[i].clientes !== null) {
                        results.push(arrayChamados[i].clientes)
                    }
                }

                let chamados = [];
                for (let i = 0; i < results.length; i++) {
                    if (results[i].status === 'CANCELADO') {
                        chamados.push({
                            statusAssinatura: results[i].status,
                            motivoCancelamento: results[i].status === 'CANCELADO' ? results[i].motivoCancelamento == '' || results[i].motivoCancelamento === undefined ? '' : results[i].motivoCancelamento : '',
                            empresaDoAssinante: results[i].segmento == '' ? 'Não Informado' : results[i].segmento,
                            clientePeloAnuncio: results[i].usuarioViaAnuncio,
                            nomeAssinante: results[i].nomeAssinante == '' ? 'Não Informado' : results[i].nomeAssinante,
                            emailAssinante: results[i].emailAssinante == '' ? 'Não Informado' : results[i].emailAssinante,
                            uf: results[i].estadoRel == '' ? 'Não Informado' : results[i].estadoRel,
                            dataPrimeiraCompra: results[i].dataHoraCompra,
                            dataUltimaCompra: results[i].dataHoraUltimoPagamento,
                            modoPagamento: results[i].modoPagamento == '' ? 'Não Informado' : results[i].modoPagamento,
                            valorPlano: results[i].valorPlanoRel == '' ? 'Não Informado' : results[i].valorPlanoRel,
                            planoMensalOuAnual: results[i].planoRel == '' ? 'Não Informado' : results[i].planoRel,
                            plataformaAssinada: results[i].plataformaRel == '' ? 'Não Informado' : results[i].plataformaRel,

                            keyEmpresa: results[i].keyEmpresaRel == '' ? 'Não Informado' : results[i].keyEmpresaRel,

                            historicoPagamento: results[i].historicoPagamento == '' || results[i].historicoPagamento === undefined ? '' : results[i].historicoPagamento,
                            idCobrancaJuno: results[i].idCobrancaJuno == '' || results[i].idCobrancaJuno === undefined ? '' : results[i].idCobrancaJuno,
                            numeroControlePix: results[i].numeroControlePix == '' || results[i].numeroControlePix === undefined ? '' : results[i].numeroControlePix,
                            novaAtivacao: results[i].novaAtivacao == '' || results[i].novaAtivacao === undefined ? '' : results[i].novaAtivacao,
                            chavePesquisa: results[i].chaveUnica == '' || results[i].chaveUnica === undefined ? '' : results[i].chaveUnica,
                        });
                    }
                }
                //
                let colunas = ['statusAssinatura', 'motivoCancelamento', 'empresaDoAssinante', 'clientePeloAnuncio', 'nomeAssinante', 'emailAssinante', 'UF', 'dataPrimeiraCompra', 'dataUltimaCompra', 'modoPagamento',
                    'valorPlano', 'planoMensalOuAnual', 'plataformaAssinada', , 'keyEmpresa', 'historicoPagamento', 'idCobrancaJuno', 'numeroControlePix', 'novaAtivacao', 'chavePesquisa'];

                exportaRelatorioCRMparaExcel(colunas, chamados, dispatch, 'segmentosCancelados');

            }

        })
    }
}

function exportaClientesparaExcel(colunas, linhas, dispatch) {
    AsyncStorage.getItem('chaveUnica').then(async (chaveUnica2) => {
        const chaveUnica = chaveUnica2.replace(/"/g, "");

        const lastValue = await firebase.database().ref(`${chaveUnica}/clientes`).orderByKey().limitToLast(1).once('value');
        const referenceToOldestKey = Object.keys(lastValue.val())[0];

        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'Accept': 'application/json',
            },

            data: { chaveUnica: chaveUnica, referenceToOldestKey: referenceToOldestKey },
            url: 'consultaClientesBD',
        };
        api(options).then((snapshot) => {
            snapshot = snapshot.data;
            const arrayOfKeys = Object.keys(snapshot).sort().reverse().slice(1);
            const results = arrayOfKeys.map(key => snapshot[key]);
            let aux = 0;
            let linhas = []
            results.forEach(childItem => {
                if (childItem.excluido == 1 || childItem.nome == undefined || childItem.empresa == undefined) {
                } else {

                    linhas.push({
                        key: arrayOfKeys[aux],
                        isMyContact: childItem.isMyContact === undefined ? false : childItem.isMyContact,
                        nome: childItem.nome,
                        empresa: childItem.empresa,
                        email: childItem.email,
                        telefoneCliente: childItem.telefoneCliente == undefined ? '' : childItem.telefoneCliente,
                        enderecoCliente: childItem.enderecoCliente == undefined ? '' : childItem.enderecoCliente,
                    });
                }
                aux++
            })
            //pegando data e hora para colocar no nome do arquivo para que nao haja repeticao de nome
            let dataAtual = moment().toDate();
            dataAtual = moment(dataAtual).format("DD-MM-YYYY HH-mm-ss");
            //

            //caminho do arquivo pasta download + nome com a data e hora

            //colunas do excel, recebe array com colunas
            //let users = [["First Name", "Last Name", "Age"]]
            let users = [colunas]
            //
            //
            //se colunas.length for igual a 3 é porque é clientes, se nao for eh setor ou atendente

            linhas.forEach((cliente) => {
                let userArray = [
                    cliente.nome, cliente.empresa, cliente.email, cliente.telefoneCliente, cliente.enderecoCliente
                ]
                users.push(userArray)
            })


            const wb = XLSX.utils.book_new()
            //

            const wsAll = XLSX.utils.aoa_to_sheet(users)
            XLSX.utils.book_append_sheet(wb, wsAll, "All Users")
            const wbout = XLSX.write(wb, { type: 'binary', bookType: "xlsx" });
            const url = window.URL.createObjectURL(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }));
            toastr.success("Sucesso", "Clientes Exportado para Excel")
            download(url, `clientes${dataAtual}.xlsx`);
        })
    })
}

function exportaRelatorioCRMparaExcel(colunas, linhas, dispatch, destino) {
    //

    //pegando data e hora para colocar no nome do arquivo para que nao haja repeticao de nome
    let dataAtual = moment().toDate();
    dataAtual = moment(dataAtual).format("DD-MM-YYYY HH-mm-ss");
    //

    //caminho do arquivo pasta download + nome com a data e hora

    //colunas do excel, recebe array com colunas
    //let users = [["First Name", "Last Name", "Age"]]
    let users = [colunas]
    //
    //
    //se colunas.length for igual a 3 é porque é clientes, se nao for eh setor ou atendente
    if (destino == 'planos') {
        linhas.forEach((atendimento) => {
            let userArray = [
                atendimento.quantidade, atendimento.plano
            ]
            users.push(userArray)
        })
    } else if (destino == 'estados') {
        linhas.forEach((atendimento) => {
            let userArray = [
                atendimento.quantidade, atendimento.estado
            ]
            users.push(userArray)
        })
    } else if (destino == 'segmentos') {
        linhas.forEach((atendimento) => {
            let userArray = [
                atendimento.quantidade, atendimento.segmento
            ]
            users.push(userArray)
        })
    } else if (destino == 'clientes') {
        linhas.forEach((atendimento) => {
            let userArray = [
                atendimento.statusAssinatura, atendimento.motivoCancelamento, atendimento.clientePeloAnuncio, atendimento.nomeAssinante, atendimento.emailAssinante, atendimento.uf, atendimento.dataPrimeiraCompra,
                atendimento.dataUltimaCompra, atendimento.modoPagamento, atendimento.valorPlano, atendimento.planoMensalOuAnual, atendimento.plataformaAssinada, atendimento.empresaDoAssinante,
                atendimento.keyEmpresa, atendimento.historicoPagamento, atendimento.idCobrancaJuno, atendimento.numeroControlePix, atendimento.novaAtivacao, atendimento.chavePesquisa
            ]
            users.push(userArray)
        })
    } else if (destino == 'ativacoes') {
        linhas.forEach((atendimento) => {
            let userArray = [
                atendimento.statusAssinatura, atendimento.motivoCancelamento, atendimento.clientePeloAnuncio, atendimento.nomeAssinante, atendimento.emailAssinante, atendimento.uf, atendimento.dataPrimeiraCompra,
                atendimento.dataUltimaCompra, atendimento.modoPagamento, atendimento.valorPlano, atendimento.planoMensalOuAnual, atendimento.plataformaAssinada, atendimento.empresaDoAssinante,
                atendimento.keyEmpresa, atendimento.historicoPagamento, atendimento.idCobrancaJuno, atendimento.numeroControlePix, atendimento.novaAtivacao, atendimento.chavePesquisa
            ]
            users.push(userArray)
        })
    } else if (destino == 'segmentosCancelados') {
        linhas.forEach((atendimento) => {
            let userArray = [
                atendimento.statusAssinatura, atendimento.motivoCancelamento, atendimento.empresaDoAssinante, atendimento.clientePeloAnuncio, atendimento.nomeAssinante, atendimento.emailAssinante, atendimento.uf, atendimento.dataPrimeiraCompra,
                atendimento.dataUltimaCompra, atendimento.modoPagamento, atendimento.valorPlano, atendimento.planoMensalOuAnual, atendimento.plataformaAssinada,
                atendimento.keyEmpresa, atendimento.historicoPagamento, atendimento.idCobrancaJuno, atendimento.numeroControlePix, atendimento.novaAtivacao, atendimento.chavePesquisa
            ]
            users.push(userArray)
        })
    }

    const wb = XLSX.utils.book_new()
    //

    const wsAll = XLSX.utils.aoa_to_sheet(users)
    XLSX.utils.book_append_sheet(wb, wsAll, "All Users")
    const wbout = XLSX.write(wb, { type: 'binary', bookType: "xlsx" });
    const url = window.URL.createObjectURL(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }));
    download(url, `relatorio${dataAtual}.xlsx`);

}

export const scriptCorrigeChamadosChatsEmEsperaMesmoFechado = () => {
    return dispatch => {
        let referenceToOldestKey;

        AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            /*
             */
            firebase.database().ref(`${chaveUnica}/usuarios/`).orderByChild('excluido').equalTo(null).once('value').then((snapshot) => {

                /*   const idTodosAtendentes = [];
                  let objetocadastro = {};
                  snapshot.forEach((childItem) => {
                      idTodosAtendentes.push({
                          key: childItem.key,
                          contaPrincipal: childItem.val().contaPrincipal == undefined ? false : childItem.val().contaPrincipal,
                          codigoInterno: childItem.val().codigoInterno == undefined ? 'codigoInterno1' : childItem.val().codigoInterno,
                      });
                  })
                  for (let i = 0; i < idTodosAtendentes.length; i++) {
                      objetocadastro[idTodosAtendentes[i].codigoInterno + "status"] = idTodosAtendentes[i].codigoInterno + "Fechado";
                  } */
                let dadosAtendVinc = { vazio: 'vazio' };
                firebase
                    .database()
                    .ref(`${chaveUnica}/chamados/`)
                    .orderByChild("status")
                    .equalTo("Fechado")
                    .limitToLast(150)
                    .once("value")
                    .then(snapshot => {
                        snapshot.forEach(childItem => {


                            if (childItem.val().setor === 'Não informado') {
                                firebase
                                    .database()
                                    .ref(`${chaveUnica}/chamados/${childItem.key}`)
                                    .update({ usuariosVisualizaAtend: dadosAtendVinc })
                            }

                        })
                    })
            })

        });

    };
};
export const listaRelatoriosCRM = (dataInicial2, dataFinal2) => {
    return dispatch => {

        let dataInicial, dataFinal;
        let dataAtual2 = moment();

        if (dataInicial2 === '' && dataFinal2 === '') {
            dataInicial = dataAtual2;
            dataFinal = dataAtual2;
            dispatch({ type: MODIFICADATAINICIALRELATORIOCRM, payload: dataInicial })
            dispatch({ type: MODIFICADATAFINALRELATORIOCRM, payload: dataFinal })
            dataInicial = moment(dataInicial).format('DD/MM/YYYY');
            dataFinal = moment(dataFinal).format('DD/MM/YYYY');
        } else {
            dataInicial = moment(dataInicial2).format('DD/MM/YYYY');
            dataFinal = moment(dataFinal2).format('DD/MM/YYYY');
        }


        let clienteAtivo = 0;
        let clienteCancelado = 0;
        let clienteViaAnuncio = 0;
        let novosClientes = 0;
        let novosCancelamentos = 0;
        let clienteNaoAnuncio = 0;
        let tipoPlanoMensal = 0;
        let tipoPlanoAnual = 0;
        let formaPagamentoCartao = 0;
        let formaPagamentoBoleto = 0;
        let formaPagamentoPix = 0;
        let assinadoViaWeb = 0;
        let assinadoViaCelular = 0;
        let arrayPlanosMaisAssinados = [];
        let arrayEstadosMaisClientes = [];
        let segmentosEstadosMaisClientes = [];
        let segmentosEstadosMaisCancelamentos = [];

        let planosMaisAssinadosPraBaixar = [];

        let dataInicialVerificacao = moment(dataInicial).format('YYYY-MM-DD');
        let dataFinalVerificacao = moment(dataFinal).format('YYYY-MM-DD');
        /*

          */
        let verificaSeDataInicialMaiorQueDataFinal = moment(dataFinalVerificacao).isBefore(dataInicialVerificacao);


        AsyncStorage.getItem('chaveUnica').then((chaveUnica2) => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            //
            if (dataInicial == '' && dataFinal == '' || dataInicial == null && dataFinal == null) {
                toastr.error('Error', 'Informe a DATA INICIAL e DATA FINAL do período de pesquisa.');
            } else if (dataInicial == '' || dataInicial == null) {
                toastr.error('Error', 'Informe a DATA INICIAL do período de pesquisa.');
            } else if (dataFinal == '' || dataFinal == null) {
                toastr.error('Error', 'Informe a DATA FINAL do período de pesquisa.');
            } else if (verificaSeDataInicialMaiorQueDataFinal == true) {
                toastr.error('Error', 'DATA INICIAL maior que DATA FINAL, por favor verifique.');
            } else {
                enviaSemConexaoInternetTelaRelatorioDetalhado(dispatch, false);
                ativaDesativaLoadingRelatorioCRM(dispatch, true);
                enviaPeriodoPersonalizadoRelatorioDetalhado(dispatch, `${dataInicial} - ${dataFinal}`);
                /*  if (valorReferencia == 1) {
                     dispatch(NavigationActions.back());
                 } */
                dataInicial = dataInicial + ' 00:00:01';
                dataFinal = dataFinal + ' 23:59:59';
                let dataAtualVerificacao = moment().toDate();
                dataAtualVerificacao = moment(dataAtualVerificacao).format("YYYY-MM-DD HH:mm");
                let dataInicialSubtraida = moment(dataAtualVerificacao, "YYYY-MM-DD HH:mm").subtract(31, 'days').format("YYYY-MM-DD HH:mm")


                let dataInicioConsulta, dataFinalConsulta;

                //verificacao para saber qual periodo é e passar o periodo correto para a query do firebase
                /*   */
                dataInicioConsulta = retornaDataTimeStamp(dataInicial);
                dataFinalConsulta = retornaDataTimeStamp(dataFinal);


                firebase.database().ref(`gestaoClientes`).orderByChild('dataUltimoPagamentoTimeStamp')
                    .startAt(dataInicioConsulta).endAt(dataFinalConsulta).once("value").then((snapshot) => {

                        if (snapshot.val()) {
                            /*   */
                            let arrayOfKeys = Object.keys(snapshot.val()).sort().reverse();
                            let results = arrayOfKeys.map((key) => snapshot.val()[key]);


                            for (let i = 0; i < results.length; i++) {
                                if (results[i].segmento == '' || results[i].segmento == undefined) {
                                    segmentosEstadosMaisClientes.push('Não Informado')
                                } else {

                                    segmentosEstadosMaisClientes.push(results[i].segmento)
                                }

                                if (results[i].estadoRel == '' || results[i].estadoRel == undefined) {
                                    arrayEstadosMaisClientes.push('Não Informado')
                                } else {

                                    arrayEstadosMaisClientes.push(results[i].estadoRel)
                                }

                                let valorPlano = results[i].valorPlanoRel == '' ? 49.90 : results[i].valorPlanoRel;

                                if (valorPlano == 99.90) {
                                    //app mensal
                                    arrayPlanosMaisAssinados.push('Plano Modesto R$ 99,90')
                                } else if (valorPlano == 199.90) {

                                    //app anual
                                    arrayPlanosMaisAssinados.push('Plano Mensal ENÉRGICO R$ 199,90')
                                } else if (valorPlano == 299.90) {

                                    //app anual
                                    arrayPlanosMaisAssinados.push('Plano Mensal INÉRCIA R$ 299,90')
                                } else if (valorPlano == 699.90) {

                                    //app anual
                                    arrayPlanosMaisAssinados.push('Plano Mensal ACELERAÇÃO R$ 699,90')
                                } else if (valorPlano == 999.90) {

                                    //app anual
                                    arrayPlanosMaisAssinados.push('Plano Mensal ROBUSTO R$ 999,90')
                                } else if (valorPlano == 399.90) {

                                    //app anual
                                    arrayPlanosMaisAssinados.push('Plano Mensal Reconhecimento R$ 399,90')
                                } else if (valorPlano == 499.90) {

                                    //app anual
                                    arrayPlanosMaisAssinados.push('Plano Mensal Grande Metas R$ 499,90')
                                } else if (valorPlano == 599.90) {

                                    //app anual
                                    arrayPlanosMaisAssinados.push('Plano Mensal Crescimento R$ 599,90')
                                } else if (valorPlano == 799.90) {

                                    //app anual
                                    arrayPlanosMaisAssinados.push('Plano Mensal Veloz R$ 799,90')
                                } else if (valorPlano == 899.90) {

                                    //app anual
                                    arrayPlanosMaisAssinados.push('Plano Mensal Imensidão R$ 899,90')
                                }
                                //aqui
                            /*   else if (valorPlano == 190.90) {

                                 //app anual
                                 arrayPlanosMaisAssinados.push('App Anual')
                             } else if (valorPlano == 99.90) {
                                 //app+web mensal
                                 arrayPlanosMaisAssinados.push('AppWeb Mensal')
                             } else if (valorPlano == 1098.90) {
                                 //app+web anual
                                 arrayPlanosMaisAssinados.push('AppWeb Anual')
                             } else if (valorPlano == 878.90) {
                                 //web anual
                                 arrayPlanosMaisAssinados.push('Web Anual')
                             } */ else {
                                    //web mensal
                                    arrayPlanosMaisAssinados.push('Plano Incentivo R$ 49,90')
                                }
                                //por status
                                let dataUltimaCompra = moment(results[i].dataHoraUltimoPagamento, "DD/MM/YYYY").format("YYYY-MM-DD HH:mm");
                                let vigorData = moment(dataUltimaCompra).isBetween(
                                    dataInicialSubtraida,
                                    dataAtualVerificacao
                                );


                                let clienteisActive = results[i].clienteAtivo == '' || results[i].clienteAtivo == undefined ? true : results[i].clienteAtivo;
                                // let pagteste = 'cartao | boleto | pix'
                                let modoPagamento = results[i].modoPagamento.split('|').pop().trim();
                                // let modoPagamento = pagteste.split('|').pop();


                                if (clienteisActive === true && modoPagamento == 'cartao') {
                                    clienteAtivo++
                                    results[i].status = 'ATIVO';
                                    planosMaisAssinadosPraBaixar.push({ clientes: results[i] })
                                } else if (clienteisActive === true && modoPagamento == 'boleto' && vigorData === true) {
                                    clienteAtivo++
                                    results[i].status = 'ATIVO';
                                    planosMaisAssinadosPraBaixar.push({ clientes: results[i] })
                                } else if (clienteisActive === true && modoPagamento == 'pix' && vigorData === true) {
                                    clienteAtivo++
                                    results[i].status = 'ATIVO';
                                    planosMaisAssinadosPraBaixar.push({ clientes: results[i] })
                                } else {
                                    clienteAtivo--;
                                    clienteCancelado++
                                    novosCancelamentos++
                                    results[i].status = 'CANCELADO';


                                    if (results[i].segmento == '' || results[i].segmento == undefined) {
                                        segmentosEstadosMaisCancelamentos.push('Não Informado')
                                    } else {
                                        segmentosEstadosMaisCancelamentos.push(results[i].segmento)
                                    }
                                    planosMaisAssinadosPraBaixar.push({ clientes: results[i] })
                                }


                                let usuarioisAnuncio = results[i].usuarioViaAnuncio == '' ? true : results[i].usuarioViaAnuncio;
                                if (results[i].usuarioViaAnuncio === true) {
                                    clienteViaAnuncio++

                                } else {
                                    clienteNaoAnuncio++
                                }

                                let novosClientesAssinou = results[i].novaAtivacao == undefined ? false : results[i].novaAtivacao;
                                if (novosClientesAssinou === true) {
                                    novosClientes++
                                }

                                let planoisMensal = results[i].planoRel == '' ? 'mensal' : results[i].planoRel;
                                if (planoisMensal == 'mensal') {
                                    tipoPlanoMensal++
                                } else {
                                    tipoPlanoAnual++
                                }
                                let modoPagamentois = results[i].modoPagamento == '' ? 'boleto' : results[i].modoPagamento.split('|').pop();
                                if (modoPagamentois == 'boleto') {
                                    formaPagamentoBoleto++
                                } else if (modoPagamentois == 'pix') {
                                    formaPagamentoPix++
                                } else {
                                    formaPagamentoCartao++
                                }
                                let assinadois = results[i].plataformaRel == '' ? 'web' : results[i].plataformaRel;
                                if (assinadois == 'web') {
                                    assinadoViaWeb++
                                } else {
                                    assinadoViaCelular++
                                }

                            }

                            //implemento array para enviar os chamados por status
                            //implemento array para enviar os chamados por status
                            if (clienteAtivo == 0) {
                                clienteAtivo = 0.5;
                            }

                            if (clienteCancelado == 0) {
                                clienteCancelado = 0.5;
                            }

                            /*     */
                            let graficoClienteAtivo = [
                                { value: clienteAtivo, label: 'Ativo', id: "Ativo", color: "hsl(150, 100%, 50%)", valorZerado: "0" },
                                { value: clienteCancelado, label: 'Cancelado', id: "Cancelado", color: "hsl(344, 94%, 51%)", valorZerado: "0" }];
                            enviaClientesAtivosGrafico(dispatch, graficoClienteAtivo);


                            if (clienteViaAnuncio == 0) {
                                clienteViaAnuncio = 0.5
                            }
                            if (clienteNaoAnuncio == 0) {
                                clienteNaoAnuncio = 0.5
                            }

                            let graficoViaAnuncio = [
                                { value: clienteViaAnuncio, label: 'Anúncio', id: "Anúncio", color: "hsl(195, 100%, 50%)", valorZerado: "0" },
                                { value: clienteNaoAnuncio, label: 'Orgânica', id: "Orgânica", color: "hsl(0, 0%, 83%)", valorZerado: "0" },
                            ];
                            enviaClientesViaAnuncio(dispatch, graficoViaAnuncio)

                            if (tipoPlanoMensal == 0) {
                                tipoPlanoMensal = 0.5
                            }
                            if (tipoPlanoAnual == 0) {
                                tipoPlanoAnual = 0.5
                            }

                            let graficoTipoPlano = [
                                { value: tipoPlanoMensal, label: 'Mensal', id: "Mensal", color: "hsl(195, 100%, 50%)", valorZerado: "0" },
                                { value: tipoPlanoAnual, label: 'Anual', id: "Anual", color: "hsl(233, 100%, 50%)", valorZerado: "0" },
                            ];
                            enviaTipoPlanoGrafico(dispatch, graficoTipoPlano)

                            if (novosClientes == 0) {
                                novosClientes = 0.5
                            }
                            if (novosCancelamentos == 0) {
                                novosCancelamentos = 0.5
                            }

                            let graficoNovosClientes = [
                                { value: novosClientes, label: 'Novas Ativações', id: "Novas Ativações", color: "hsl(195, 100%, 50%)", valorZerado: "0" },
                                {
                                    value: novosCancelamentos,
                                    label: 'Novos Cancelamentos',
                                    id: "Novos Cancelamentos",
                                    color: "hsl(344, 94%, 51%)",
                                    valorZerado: "0"
                                },
                            ];
                            enviaNovasAtivacoesNoPeriodo(dispatch, graficoNovosClientes)

                            if (formaPagamentoCartao == 0) {
                                formaPagamentoCartao = 0.5
                            }
                            if (formaPagamentoBoleto == 0) {
                                formaPagamentoBoleto = 0.5
                            }
                            if (formaPagamentoPix == 0) {
                                formaPagamentoPix = 0.5;
                            }

                            let graficoTipoPagamento = [
                                { value: formaPagamentoCartao, label: 'Cartão', id: "Cartão", color: "hsl(195, 100%, 50%)", valorZerado: "0" },
                                { value: formaPagamentoBoleto, label: 'Boleto', id: "Boleto", color: "hsl(163, 100%, 50%)", valorZerado: "0" },
                                { value: formaPagamentoPix, label: 'Pix', id: "Pix", color: "hsl(233, 100%, 50%)", valorZerado: "0" },
                            ];
                            enviaFormaPagamentoGrafico(dispatch, graficoTipoPagamento)


                            if (assinadoViaWeb == 0) {
                                assinadoViaWeb = 0.5
                            }
                            if (assinadoViaCelular == 0) {
                                assinadoViaCelular = 0.5
                            }

                            let graficoAssinadoViaWebApp = [
                                { value: assinadoViaWeb, label: 'Web', id: "Web", color: "hsl(195, 100%, 50%)", valorZerado: "0" },
                                { value: assinadoViaCelular, label: 'App', id: "App", color: "hsl(42, 94%, 60%)", valorZerado: "0" },
                            ];
                            enviaAssinadoViaWebAppGrafico(dispatch, graficoAssinadoViaWebApp)

                            if (arrayPlanosMaisAssinados.length != 0) {
                                let arrayDeKeyRepetidos = arrayPlanosMaisAssinados.reduce(function (prev, cur) {
                                    prev[cur] = (prev[cur] || 0) + 1;
                                    return prev;
                                }, {});

                                let testeOrdenacao = ordenaObjeto(arrayDeKeyRepetidos);
                                for (let i = 0; i < testeOrdenacao.length; i++) {
                                    planosMaisAssinadosPraBaixar.push({ planos: testeOrdenacao[i] })
                                }

                                //se for maior tenho que pegar somente as 4 primeiras casas do array

                                let arrayChamado = [];
                                let promisseCliente;
                                let labelClientes = [];
                                let valoresClientes = []


                                for (let i = 0; i < testeOrdenacao.length; i++) {
                                    valoresClientes.push({
                                        x: testeOrdenacao[i].key,
                                        y: testeOrdenacao[i].value
                                    })

                                    let dataLineChartCliente = [{
                                        "id": "Planos",
                                        "data": valoresClientes
                                    }]
                                    //
                                    enviaPlanosMaisAssinados(dispatch, dataLineChartCliente);
                                }


                            } else {
                                //se não, não tem dados, então envia zerado
                                //
                                let clientesMaisAbriramChamadosZerado = [
                                    {
                                        "id": "Sem Planos",
                                        "data": [
                                            {
                                                "x": "0",
                                                "y": 0
                                            },
                                        ]
                                    },
                                ];
                                enviaPlanosMaisAssinados(dispatch, clientesMaisAbriramChamadosZerado);
                            }
                            if (arrayEstadosMaisClientes.length != 0) {
                                let arrayDeKeyRepetidos = arrayEstadosMaisClientes.reduce(function (prev, cur) {
                                    prev[cur] = (prev[cur] || 0) + 1;
                                    return prev;
                                }, {});

                                let testeOrdenacao = ordenaObjeto(arrayDeKeyRepetidos);
                                for (let i = 0; i < testeOrdenacao.length; i++) {
                                    planosMaisAssinadosPraBaixar.push({ estados: testeOrdenacao[i] })
                                }
                                if (testeOrdenacao.length >= 4) {
                                    let arrayChamado = [];
                                    let promisseCliente;
                                    let labelClientes = [];
                                    let valoresClientes = []


                                    for (let i = 0; i < 4; i++) {
                                        valoresClientes.push({
                                            x: testeOrdenacao[i].key,
                                            y: testeOrdenacao[i].value
                                        })
                                        /*  labelClientes.push(
    
                                             responses[i].val(),
    
                                         );
                                         valoresClientes.push(
    
                                             testeOrdenacao[i].value,
    
                                         ); */


                                    }
                                    //

                                    let dataLineChartCliente = [{
                                        "id": "EstadosMaisAssinados",
                                        "data": valoresClientes
                                    }]
                                    enviaEstadosComMaisClientes(dispatch, dataLineChartCliente);

                                }
                                //se não eu pego todo array, já que o mesmo será menos que 4
                                else {
                                    let arrayChamado = [];
                                    let promisseCliente;
                                    let labelClientes = [];
                                    let valoresClientes = []


                                    for (let i = 0; i < testeOrdenacao.length; i++) {
                                        valoresClientes.push({
                                            x: testeOrdenacao[i].key,
                                            y: testeOrdenacao[i].value
                                        })
                                        /* labelClientes.push(
    
                                            responses[i].val(),
    
                                        );
                                        valoresClientes.push(
    
                                            testeOrdenacao[i].value,
    
                                        ); */


                                    }
                                    //
                                    //
                                    //
                                    let dataLineChartCliente = [{
                                        "id": "EstadosMaisAssinados",
                                        "data": valoresClientes
                                    }]
                                    //
                                    enviaEstadosComMaisClientes(dispatch, dataLineChartCliente);

                                }
                            } else {
                                //se não, não tem dados, então envia zerado
                                //
                                let clientesMaisAbriramChamadosZerado = [
                                    {
                                        "id": "EstadosMaisAssinados",
                                        "data": [
                                            {
                                                "x": "0",
                                                "y": 0
                                            },
                                        ]
                                    },
                                ];
                                enviaEstadosComMaisClientes(dispatch, clientesMaisAbriramChamadosZerado);
                            }


                            if (segmentosEstadosMaisClientes.length != 0) {
                                let arrayDeKeyRepetidos = segmentosEstadosMaisClientes.reduce(function (prev, cur) {
                                    prev[cur] = (prev[cur] || 0) + 1;
                                    return prev;
                                }, {});

                                let testeOrdenacao = ordenaObjeto(arrayDeKeyRepetidos);
                                for (let i = 0; i < testeOrdenacao.length; i++) {
                                    planosMaisAssinadosPraBaixar.push({ segmentos: testeOrdenacao[i] })
                                }
                                if (testeOrdenacao.length >= 4) {
                                    let arrayChamado = [];
                                    let promisseCliente;
                                    let labelClientes = [];
                                    let valoresClientes = []


                                    for (let i = 0; i < 4; i++) {
                                        valoresClientes.push({
                                            x: testeOrdenacao[i].key,
                                            y: testeOrdenacao[i].value
                                        })
                                        /*  labelClientes.push(
    
                                             responses[i].val(),
    
                                         );
                                         valoresClientes.push(
    
                                             testeOrdenacao[i].value,
    
                                         ); */


                                    }
                                    //

                                    let dataLineChartCliente = [{
                                        "id": "SegmentosMaisAssinados",
                                        "data": valoresClientes
                                    }]
                                    enviaSegmentosComMaisClientes(dispatch, dataLineChartCliente);

                                }
                                //se não eu pego todo array, já que o mesmo será menos que 4
                                else {
                                    let arrayChamado = [];
                                    let promisseCliente;
                                    let labelClientes = [];
                                    let valoresClientes = []


                                    for (let i = 0; i < testeOrdenacao.length; i++) {
                                        valoresClientes.push({
                                            x: testeOrdenacao[i].key,
                                            y: testeOrdenacao[i].value
                                        })
                                        /* labelClientes.push(
    
                                            responses[i].val(),
    
                                        );
                                        valoresClientes.push(
    
                                            testeOrdenacao[i].value,
    
                                        ); */


                                    }
                                    //
                                    //
                                    //
                                    let dataLineChartCliente = [{
                                        "id": "SegmentosMaisAssinados",
                                        "data": valoresClientes
                                    }]
                                    //
                                    enviaSegmentosComMaisClientes(dispatch, dataLineChartCliente);

                                }
                            }
                            if (segmentosEstadosMaisCancelamentos.length != 0) {
                                let arrayDeKeyRepetidos = segmentosEstadosMaisCancelamentos.reduce(function (prev, cur) {
                                    prev[cur] = (prev[cur] || 0) + 1;
                                    return prev;
                                }, {});

                                let testeOrdenacao = ordenaObjeto(arrayDeKeyRepetidos);
                                for (let i = 0; i < testeOrdenacao.length; i++) {
                                    planosMaisAssinadosPraBaixar.push({ segmentosCancelados: testeOrdenacao[i] })
                                }
                                if (testeOrdenacao.length >= 4) {
                                    let arrayChamado = [];
                                    let promisseCliente;
                                    let labelClientes = [];
                                    let valoresClientes = []


                                    for (let i = 0; i < 4; i++) {
                                        valoresClientes.push({
                                            x: testeOrdenacao[i].key,
                                            y: testeOrdenacao[i].value
                                        })
                                        /*  labelClientes.push(
    
                                             responses[i].val(),
    
                                         );
                                         valoresClientes.push(
    
                                             testeOrdenacao[i].value,
    
                                         ); */


                                    }
                                    //

                                    let dataLineChartCliente = [{
                                        "id": "SegmentosMaisCancelados",
                                        "data": valoresClientes
                                    }]
                                    enviaSegmentosComMaisCancelamentos(dispatch, dataLineChartCliente);

                                }
                                //se não eu pego todo array, já que o mesmo será menos que 4
                                else {
                                    let arrayChamado = [];
                                    let promisseCliente;
                                    let labelClientes = [];
                                    let valoresClientes = []


                                    for (let i = 0; i < testeOrdenacao.length; i++) {
                                        valoresClientes.push({
                                            x: testeOrdenacao[i].key,
                                            y: testeOrdenacao[i].value
                                        })
                                        /* labelClientes.push(
    
                                            responses[i].val(),
    
                                        );
                                        valoresClientes.push(
    
                                            testeOrdenacao[i].value,
    
                                        ); */


                                    }
                                    //
                                    //
                                    //
                                    let dataLineChartCliente = [{
                                        "id": "SegmentosMaisCancelados",
                                        "data": valoresClientes
                                    }]
                                    //
                                    enviaSegmentosComMaisCancelamentos(dispatch, dataLineChartCliente);

                                }
                            } else {
                                //se não, não tem dados, então envia zerado
                                //
                                let clientesMaisAbriramChamadosZerado = [
                                    {
                                        "id": "SegmentosMaisAssinados",
                                        "data": [
                                            {
                                                "x": "0",
                                                "y": 0
                                            },
                                        ]
                                    },
                                ];
                                enviaSegmentosComMaisCancelamentos(dispatch, clientesMaisAbriramChamadosZerado);
                            }
                            enviaPlanosMaisAssinantesPraBaixar(dispatch, planosMaisAssinadosPraBaixar);
                            ativaDesativaLoadingRelatorioCRM(dispatch, false);
                            // ativaDesativaLoadingRefreshControol(dispatch, false);
                        } else {
                            //
                            //sem informacoes
                            let graficoClienteAtivo = [
                                { value: 0.5, label: 'Ativo', id: "Ativo", color: "hsl(150, 100%, 50%)", valorZerado: "0" },
                                { value: 0.5, label: 'Cancelado', id: "Cancelado", color: "hsl(344, 94%, 51%)", valorZerado: "0" }];
                            enviaClientesAtivosGrafico(dispatch, graficoClienteAtivo);

                            let graficoViaAnuncio = [
                                { value: 0.5, label: 'Anúncio', id: "Anúncio", color: "hsl(195, 100%, 50%)", valorZerado: "0" },
                                { value: 0.5, label: 'Orgânica', id: "Orgânica", color: "hsl(0, 0%, 83%)", valorZerado: "0" },
                            ];
                            enviaClientesViaAnuncio(dispatch, graficoViaAnuncio)
                            //  enviaTotalAtendimentosPorPRioridade(dispatch, 0)
                            let graficoTipoPlano = [
                                { value: 0.5, label: 'Mensal', id: "Mensal", color: "hsl(195, 100%, 50%)", valorZerado: "0" },
                                { value: 0.5, label: 'Anual', id: "Anual", color: "hsl(233, 100%, 50%)", valorZerado: "0" },
                            ];
                            enviaTipoPlanoGrafico(dispatch, graficoTipoPlano)
                            // enviaTotalAtendimentosPeriodoResolucao(dispatch, 0);

                            let graficoTipoPagamento = [
                                { value: 0.5, label: 'Cartão', id: "Cartão", color: "hsl(195, 100%, 50%)", valorZerado: "0" },
                                { value: 0.5, label: 'Boleto', id: "Boleto", color: "hsl(163, 100%, 50%)", valorZerado: "0" },
                            ];
                            enviaFormaPagamentoGrafico(dispatch, graficoTipoPagamento)


                            let graficoNovosClientes = [
                                { value: 0.5, label: 'Novas Ativações', id: "Novas Ativações", color: "hsl(195, 100%, 50%)", valorZerado: "0" },
                                { value: 0.5, label: 'Novos Cancelamentos', id: "Novos Cancelamentos", color: "hsl(344, 94%, 51%)", valorZerado: "0" },
                            ];
                            enviaNovasAtivacoesNoPeriodo(dispatch, graficoNovosClientes)
                            let graficoAssinadoViaWebApp = [
                                { value: 0.5, label: 'Web', id: "Web", color: "hsl(195, 100%, 50%)", valorZerado: "0" },
                                { value: 0.5, label: 'App', id: "App", color: "hsl(42, 94%, 60%)", valorZerado: "0" },
                            ];
                            enviaAssinadoViaWebAppGrafico(dispatch, graficoAssinadoViaWebApp)

                            let planosMaisAbriramChamadosZerado = [
                                {
                                    "id": "Sem Planos",
                                    "data": [
                                        {
                                            "x": "0",
                                            "y": 0
                                        },
                                    ]
                                },
                            ];
                            enviaPlanosMaisAssinados(dispatch, planosMaisAbriramChamadosZerado);
                            let estadosMaisAbriramChamadosZerado = [
                                {
                                    "id": "EstadosMaisAssinados",
                                    "data": [
                                        {
                                            "x": "0",
                                            "y": 0
                                        },
                                    ]
                                },
                            ];
                            enviaEstadosComMaisClientes(dispatch, estadosMaisAbriramChamadosZerado);
                            let segmentosMaisAbriramChamadosZerado = [
                                {
                                    "id": "SegmentosMaisAssinados",
                                    "data": [
                                        {
                                            "x": "0",
                                            "y": 0
                                        },
                                    ]
                                },
                            ];
                            enviaSegmentosComMaisClientes(dispatch, estadosMaisAbriramChamadosZerado);
                            let segmentosMaisAbriramChamadosZerado2 = [
                                {
                                    "id": "SegmentosMaisCancelados",
                                    "data": [
                                        {
                                            "x": "0",
                                            "y": 0
                                        },
                                    ]
                                },
                            ];
                            enviaSegmentosComMaisCancelamentos(dispatch, segmentosMaisAbriramChamadosZerado2);
                            enviaPlanosMaisAssinantesPraBaixar(dispatch, []);
                            ativaDesativaLoadingRelatorioCRM(dispatch, false);
                            //
                        }
                    })
            }

        })

    }
}

export const downloadUsuariosCadastradosExcel = (colunas, linhas) => {
    //
    return dispatch => {
        //pegando data e hora para colocar no nome do arquivo para que nao haja repeticao de nome
        let dataAtual = moment().toDate();
        dataAtual = moment(dataAtual).format("DD-MM-YYYY HH-mm-ss");
        //

        //caminho do arquivo pasta download + nome com a data e hora

        //colunas do excel, recebe array com colunas
        //let users = [["First Name", "Last Name", "Age"]]
        let users = [colunas]
        //
        //
        //se colunas.length for igual a 3 é porque é clientes, se nao for eh setor ou atendente

        linhas.forEach((usuario) => {
            let userArray = [
                usuario
            ]
            users.push(userArray)
        })


        const wb = XLSX.utils.book_new()
        //

        const wsAll = XLSX.utils.aoa_to_sheet(users)
        XLSX.utils.book_append_sheet(wb, wsAll, "All Users")
        const wbout = XLSX.write(wb, { type: 'binary', bookType: "xlsx" });
        const url = window.URL.createObjectURL(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }));
        download(url, `relatorio${dataAtual}.xlsx`);
    }
}


export const desligaOuvintesChamadosFirebase = (tela, atendenteVeApenasProprioChamado, agruparAtendenteSetor, contaPrincipal, adminVisualizaApenasProprioSetor, referenciaUltimaKeys) => {
    return dispatch => {
        AsyncStorage.getItem('chaveUnica').then((chaveUnica2) => {
            if (chaveUnica2 && firebase.auth().currentUser) {
                const chaveUnica = chaveUnica2.replace(/"/g, "");
                if (tela == 'atendimentoAberto') {
                    //
                    if (atendenteVeApenasProprioChamado == true && agruparAtendenteSetor == false && contaPrincipal == false) {

                        const { currentUser } = firebase.auth();
                        firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild('uid').equalTo(currentUser.uid).once("value").then((snapshot) => {
                            let key = Object.keys(snapshot.val()).toString();
                            const filtro = `Aberto${key}`;

                            firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild("status_atendente").equalTo(filtro).limitToLast(7).off("value");


                            for (const key of referenciaUltimaKeys) {
                                firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild("status_atendente").startAt(filtro)
                                    .endAt(filtro, key).limitToLast(8).off("value");
                            }
                        })
                    } else if (atendenteVeApenasProprioChamado == true && agruparAtendenteSetor == true && contaPrincipal == false) {

                        const { currentUser } = firebase.auth();
                        firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild('uid').equalTo(currentUser.uid).once("value").then((snapshot) => {
                            let key = Object.keys(snapshot.val()).toString();
                            let codigoInterno;
                            snapshot.forEach(childItem => {
                                codigoInterno = childItem.val().codigoInterno;
                            })


                            firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild(`usuariosVisualizaAtend/dadosAtendVinc/${codigoInterno}`).equalTo(`${codigoInterno}`).limitToLast(7).off("value");

                            for (const key of referenciaUltimaKeys) {
                                firebase
                                    .database()
                                    .ref(`${chaveUnica}/chamados/`)
                                    .orderByChild(`usuariosVisualizaAtend/dadosAtendVinc/${codigoInterno}`)
                                    .startAt(`${codigoInterno}`)
                                    .endAt(`${codigoInterno}`, key)
                                    //  .startAt('Aberto', referenciaUltimaKey)
                                    .limitToLast(8)
                                    .off("value");
                            }
                        })
                    } else if (atendenteVeApenasProprioChamado == true && agruparAtendenteSetor == true && contaPrincipal == true && adminVisualizaApenasProprioSetor == true) {
                        const { currentUser } = firebase.auth();
                        firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild('uid').equalTo(currentUser.uid).once("value").then((snapshot) => {
                            let key = Object.keys(snapshot.val()).toString();
                            let codigoInterno;
                            snapshot.forEach(childItem => {
                                codigoInterno = childItem.val().codigoInterno;
                            })


                            firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild(`usuariosVisualizaAtend/dadosAtendVinc/${codigoInterno}status`).equalTo(`${codigoInterno}Aberto`).limitToLast(7).off("value");


                            for (const key of referenciaUltimaKeys) {
                                firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild(`usuariosVisualizaAtend/dadosAtendVinc/${codigoInterno}status`).startAt(`${codigoInterno}Aberto`)
                                    .endAt(`${codigoInterno}Aberto`, key).limitToLast(8).off("value");
                            }
                        })
                    } else {
                        //
                        firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild("status").equalTo("Aberto").limitToLast(7).off("value");
                        for (const key of referenciaUltimaKeys) {
                            firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild("status").startAt("Aberto").endAt("Aberto", key).limitToLast(8).off("value");

                        }
                    }
                } else if (tela == 'atendimentoEmAtendimento') {
                    //
                    if (atendenteVeApenasProprioChamado == true && contaPrincipal == false) {
                        //
                        const { currentUser } = firebase.auth();
                        firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild('uid').equalTo(currentUser.uid).once("value").then((snapshot) => {
                            let key = Object.keys(snapshot.val()).toString();
                            const filtro = `Atendendo${key}`;
                            //
                            firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild("status_atendente").equalTo(filtro).limitToLast(7).off("value");

                            for (const key of referenciaUltimaKeys) {
                                firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild("status_atendente").startAt(filtro)
                                    .endAt(filtro, key).limitToLast(8).off("value");
                            }

                        })
                    } else if (atendenteVeApenasProprioChamado == true && agruparAtendenteSetor == true && contaPrincipal == true && adminVisualizaApenasProprioSetor == true) {
                        const { currentUser } = firebase.auth();
                        firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild('uid').equalTo(currentUser.uid).once("value").then((snapshot) => {
                            let key = Object.keys(snapshot.val()).toString();
                            let codigoInterno;
                            snapshot.forEach(childItem => {
                                codigoInterno = childItem.val().codigoInterno;
                            })


                            firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild(`usuariosVisualizaAtend/dadosAtendVinc/${codigoInterno}status`).equalTo(`${codigoInterno}Atendendo`).limitToLast(7).off("value");


                            for (const key of referenciaUltimaKeys) {
                                firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild(`usuariosVisualizaAtend/dadosAtendVinc/${codigoInterno}status`).startAt(`${codigoInterno}Atendendo`)
                                    .endAt(`${codigoInterno}Atendendo`, key).limitToLast(8).off("value");
                            }
                        })
                    } else {
                        //
                        firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild("status").equalTo("Atendendo").limitToLast(7).off("value");
                        for (const key of referenciaUltimaKeys) {
                            firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild("status").startAt("Atendendo").endAt("Atendendo", key).limitToLast(8).off("value");

                        }
                    }
                } else if (tela == 'atendimentoFechado') {
                    //
                    if (atendenteVeApenasProprioChamado == true && contaPrincipal == false) {
                        //
                        const { currentUser } = firebase.auth();
                        firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild('uid').equalTo(currentUser.uid).once("value").then((snapshot) => {
                            let key = Object.keys(snapshot.val()).toString();
                            const filtro = `Fechado${key}`;
                            //
                            firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild("status_atendente").equalTo(filtro).limitToLast(7).off("value");
                            for (const key of referenciaUltimaKeys) {
                                firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild("status_atendente").startAt(filtro)
                                    .endAt(filtro, key).limitToLast(8).off("value");
                            }

                        })
                    } else if (atendenteVeApenasProprioChamado == true && agruparAtendenteSetor == true && contaPrincipal == true && adminVisualizaApenasProprioSetor == true) {
                        const { currentUser } = firebase.auth();
                        firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild('uid').equalTo(currentUser.uid).once("value").then((snapshot) => {
                            let key = Object.keys(snapshot.val()).toString();
                            let codigoInterno;
                            snapshot.forEach(childItem => {
                                codigoInterno = childItem.val().codigoInterno;
                            })


                            firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild(`usuariosVisualizaAtend/dadosAtendVinc/${codigoInterno}status`).equalTo(`${codigoInterno}Fechado`).limitToLast(7).off("value");


                            for (const key of referenciaUltimaKeys) {
                                firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild(`usuariosVisualizaAtend/dadosAtendVinc/${codigoInterno}status`).startAt(`${codigoInterno}Fechado`)
                                    .endAt(`${codigoInterno}Fechado`, key).limitToLast(8).off("value");
                            }
                        })
                    } else {
                        //
                        firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild("status").equalTo("Fechado").limitToLast(7).off("value");
                        for (const key of referenciaUltimaKeys) {
                            firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild("status").startAt("Fechado").endAt("Fechado", key).limitToLast(8).off("value");

                        }
                    }
                } else if (tela == 'atendimentoCancelado') {
                    //
                    if (atendenteVeApenasProprioChamado == true && contaPrincipal == false) {
                        //
                        const { currentUser } = firebase.auth();

                        firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild('uid').equalTo(currentUser.uid).once("value").then((snapshot) => {
                            let key = Object.keys(snapshot.val()).toString();
                            const filtro = `Cancelado${key}`;
                            //
                            firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild("status_atendente").equalTo(filtro).limitToLast(7).off("value");

                            for (const key of referenciaUltimaKeys) {
                                firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild("status_atendente").startAt(filtro)
                                    .endAt(filtro, key).limitToLast(8).off("value");

                            }
                        })
                    } else if (atendenteVeApenasProprioChamado == true && agruparAtendenteSetor == true && contaPrincipal == true && adminVisualizaApenasProprioSetor == true) {
                        const { currentUser } = firebase.auth();
                        firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild('uid').equalTo(currentUser.uid).once("value").then((snapshot) => {
                            let key = Object.keys(snapshot.val()).toString();
                            let codigoInterno;
                            snapshot.forEach(childItem => {
                                codigoInterno = childItem.val().codigoInterno;
                            })


                            firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild(`usuariosVisualizaAtend/dadosAtendVinc/${codigoInterno}status`).equalTo(`${codigoInterno}Cancelado`).limitToLast(7).off("value");

                            for (const key of referenciaUltimaKeys) {
                                firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild(`usuariosVisualizaAtend/dadosAtendVinc/${codigoInterno}status`).startAt(`${codigoInterno}Cancelado`)
                                    .endAt(`${codigoInterno}Cancelado`, key).limitToLast(8).off("value");

                            }
                        })
                    } else {
                        //
                        firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild("status").equalTo("Cancelado").limitToLast(7).off("value");
                        for (const key of referenciaUltimaKeys) {
                            firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild("status").startAt("Cancelado").endAt("Cancelado", key).limitToLast(8).off("value");
                        }
                    }
                } else if (tela == 'atendimentoTodos') {
                    //
                    if (atendenteVeApenasProprioChamado == true && contaPrincipal == false) {
                        //
                        const { currentUser } = firebase.auth();
                        firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild('uid').equalTo(currentUser.uid).once("value").then((snapshot) => {
                            let key = Object.keys(snapshot.val()).toString();
                            const filtro = key;
                            //
                            firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild('atendente').equalTo(filtro)
                                .limitToLast(7).off("value");

                            for (const key of referenciaUltimaKeys) {
                                firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild('atendente').startAt(filtro).endAt(filtro, key)
                                    .limitToLast(8).off("value");
                            }
                        })
                    } else {
                        //
                        firebase.database().ref(`${chaveUnica}/chamados/`).orderByKey().limitToLast(7).off("value");
                        //
                    }
                } else if (tela == 'avisos') {
                    const { currentUser } = firebase.auth();
                    firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild('uid').equalTo(currentUser.uid).once("value").then((snapshot) => {
                        let key = Object.keys(snapshot.val()).toString();
                        firebase.database().ref(`${chaveUnica}/avisos/`).orderByChild("ativo").equalTo(true).off("value");
                        firebase.database().ref(`${chaveUnica}/tarefas`).off("value");
                        //
                    })

                } else if (tela == 'atendimentos') {
                    // firebase.database().ref(`${chaveUnica}/chamados`).off("value");
                    // firebase.database().ref(`${chaveUnica}/clientes`).orderByChild("empresa").off("value");
                    //firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild("nome").off("value");
                    //firebase.database().ref(`${chaveUnica}/setores`).orderByChild("nome").off("value");
                    firebase.database().ref(`${chaveUnica}/respostapadrao`).orderByKey().off("value");
                    firebase.database().ref(`${chaveUnica}/problemaEstatico`).orderByChild("selecionado").equalTo(1).off("value");
                    //
                }
                /*   else if (tela == 'acoesChamados') {
                      // firebase.database().ref(`${chaveUnica}/chamados`).off("value");
                     firebase.database().ref(`${chaveUnica}/clientes`).orderByChild("empresa").off("value");
                      firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild("nome").off("value");
                      firebase.database().ref(`${chaveUnica}/setores`).orderByChild("nome").off("value");
                      firebase.database().ref(`${chaveUnica}/respostapadrao`).orderByKey().off("value");
                      firebase.database().ref(`${chaveUnica}/problemaEstatico`).orderByChild("selecionado").equalTo(1).off("value");
                      firebase.database().ref(`${chaveUnica}/acoesChamados/${this.props.keyAtendimento}`).limitToLast(14).off("value", this.props.acoesDoAtendimento[0].refOuvinte);
                      firebase.database().ref(`${chaveUnica}/chamados/`).orderByKey().equalTo(this.props.keyAtendimento).off("value")

                  } */
                else if (tela == 'chat') {
                    firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild('uid').equalTo(firebase.auth().currentUser.uid).once("value").then((snapshot) => {
                        let key = Object.keys(snapshot.val()).toString();
                        const filtro = `Atendendo${key}true`;
                        let codigoInterno;
                        snapshot.forEach(childItem => {
                            codigoInterno = childItem.val().codigoInterno;
                        })


                        firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild("status_atendente_abertoviachat").equalTo(filtro).off("value");
                        firebase.database().ref(`${chaveUnica}/configuracao/chatOnline`).off("value");
                        //firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild("nome").off("value");
                        firebase.database().ref(`${chaveUnica}/respostapadrao`).orderByKey().off("value");
                        if (agruparAtendenteSetor == true && contaPrincipal == false) {
                            firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild(`usuariosVisualizaAtend/dadosAtendVinc/${key}Chat`).equalTo(true).off("value");
                        } else if (atendenteVeApenasProprioChamado == true && agruparAtendenteSetor == true && contaPrincipal == true && adminVisualizaApenasProprioSetor == true) {

                            firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild(`usuariosVisualizaAtend/dadosAtendVinc/${codigoInterno}Chatstatus`)
                                .equalTo(`${codigoInterno}ChatAberto`).off("value");

                        } else {
                            firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild("status_atendente_abertoviachat").equalTo("AbertoNão informadotrue").off("value");

                        }
                        //
                    })
                } else if (tela == 'clientes') {
                    firebase.database().ref(`${chaveUnica}/clientes/`).orderByChild('excluido').equalTo(null).limitToLast(10).off("value");
                    firebase.database().ref(`${chaveUnica}/clientes/`).orderByChild("excluido").startAt(null).endAt(null).limitToLast(11).off("value");
                    //firebase.database().ref(`${chaveUnica}/clientes`).orderByChild("empresa").off("value");
                    //
                } else if (tela == 'atendentes') {
                    firebase.database().ref(`${chaveUnica}/usuarios/`).orderByChild('excluido').equalTo(null).limitToFirst(10).off("value");
                    firebase.database().ref(`${chaveUnica}/usuarios/`).orderByChild("excluido").startAt(null).endAt(null).limitToFirst(11).off("value");
                    //firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild("nome").off("value");
                    //
                } else if (tela == 'setores') {
                    firebase.database().ref(`${chaveUnica}/setores/`).orderByChild('excluido').equalTo(null).limitToLast(10).off("value");
                    firebase.database().ref(`${chaveUnica}/setores/`).orderByChild("excluido").startAt(null).endAt(null).limitToLast(11).off("value");
                    //firebase.database().ref(`${chaveUnica}/setores`).orderByChild("nome").off("value");
                    //
                } else if (tela == 'cadastrosetor') {
                    // firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild("nome").off("value");
                    //
                } else if (tela == 'alterasetor') {
                    // firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild("nome").off("value");
                    //
                } else if (tela == 'consultaavisos') {
                    firebase.database().ref(`${chaveUnica}/avisos/`).orderByKey().limitToLast(10).off("value");
                    // firebase.database().ref(`${chaveUnica}/avisos/`).orderByKey().limitToLast(11).off("value");
                    //firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild("nome").off("value");
                    //firebase.database().ref(`${chaveUnica}/clientes`).orderByChild("empresa").off("value");
                    //
                } else if (tela == 'relatorioatendimentodetalhado') {
                    // firebase.database().ref(`${chaveUnica}/clientes`).orderByChild("empresa").off("value");
                    //  firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild("nome").off("value");
                    // firebase.database().ref(`${chaveUnica}/setores`).orderByChild("nome").off("value");
                    //
                } else if (tela == 'relatorioavaliacaoatendente') {
                    // firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild("nome").off("value");
                    //
                } else if (tela == 'relatorioavaliacaotodas') {
                    // firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild("nome").off("value");
                    // firebase.database().ref(`${chaveUnica}/clientes`).orderByChild("empresa").off("value");
                    firebase.database().ref(`${chaveUnica}/avaliacoes/`).orderByKey().limitToLast(9).off("value");
                    // firebase.database().ref(`${chaveUnica}/avaliacoes/`).orderByKey().endAt(null).limitToLast(10).off("value");
                    //
                } else if (tela == 'configuracaoatendimento') {
                    firebase.database().ref(`${chaveUnica}/respostapadrao`).orderByKey().off("value");
                    firebase.database().ref(`${chaveUnica}/problemaEstatico`).orderByKey().off("value");
                    //
                } else if (tela == 'corlogomarca') {
                    firebase.database().ref(`${chaveUnica}/configuracao/cores`).off("value");
                    //
                } else if (tela == 'configuracaochat') {
                    firebase.database().ref(`${chaveUnica}/configuracao/chatOnline`).off("value");
                    //
                } else if (tela == 'configuracoesall') {
                    firebase.database().ref(`${chaveUnica}/respostapadrao`).orderByKey().off("value");
                    firebase.database().ref(`${chaveUnica}/problemaEstatico`).orderByKey().off("value");
                    firebase.database().ref(`${chaveUnica}/configuracao/cores`).off("value");
                    firebase.database().ref(`${chaveUnica}/configuracao/chatOnline`).off("value");
                    //  firebase.database().ref(`${chaveUnica}/configuracao/whatsapp`).off("value");
                    //
                } else if (tela == 'chamados') {
                    firebase.database().ref(`${chaveUnica}/chamados`).off("value");
                } else {
                    //
                }
            } else {

            }
        })
    }
}

export const inserePlanoPremium = (plano, idSubscription, descricaoPlano, emailAssinante, nomeAssinante, valorPlano, estado) => {
    return dispatch => {
        //
        loadingTelalVIP(dispatch, true);
        let dataAtual = retornaDataAtual();
        let dataValidadePlano, dataValidadeAtual;
        let licensawebouapp = 3;
        let planoAnualOuMensal = 'mensal';
        let quantidadeAtendenteLiberado = 5;

        dataValidadeAtual = moment(dataAtual, 'DD/MM/YYYY').add(1, 'months').format('DD/MM/YYYY');
        planoAnualOuMensal = 'mensal';
        licensawebouapp = 3;

        if (plano == '10') {
            quantidadeAtendenteLiberado = 10;
        } else if (plano == '15') {
            quantidadeAtendenteLiberado = 15;
        } else if (plano == '20') {
            quantidadeAtendenteLiberado = 20;
        } else if (plano == '30') {
            quantidadeAtendenteLiberado = 30;
        } else if (plano == '40') {
            quantidadeAtendenteLiberado = 40;
        } else if (plano == '50') {
            quantidadeAtendenteLiberado = 50;
        } else if (plano == '80') {
            quantidadeAtendenteLiberado = 80;
        } else if (plano == '100') {
            quantidadeAtendenteLiberado = 100;
        } else if (plano == '60') {
            quantidadeAtendenteLiberado = 60;
        } else if (plano == '120') {
            quantidadeAtendenteLiberado = 120;
        } else {
            quantidadeAtendenteLiberado = 5;

        }

        AsyncStorage.getItem('chaveUnica').then((chaveUnica2) => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            let uidComprador = firebase.auth().currentUser.uid;

            firebase.database().ref(`${chaveUnica}/empresa`).once("value").then((snapshot) => {
                let keyEmpresa = '';
                snapshot.forEach(childItem => {
                    keyEmpresa = childItem.key;
                })
                firebase.database().ref(`${chaveUnica}/empresa/${keyEmpresa}`).update({
                    dataCompra: dataAtual,
                    premium: 1,
                    dataValidoAte: '12/12/2099',
                    plataforma: 'web',
                    qtdAtendentesLiberados: quantidadeAtendenteLiberado,
                    plano: descricaoPlano ?? '',
                    statusAssinatura: '',
                    dataValidadeAtual,
                    identificadorUnicoDaCompra: idSubscription,
                    uidComprador: uidComprador,
                    licensawebouapp,
                    dataCompraRel: dataAtual,
                    emailRel: emailAssinante ?? '',
                    nomeRel: nomeAssinante ?? '',
                    planoRel: planoAnualOuMensal,
                    plataformaRel: 'web',
                    valorPlanoRel: valorPlano ?? '',
                    estadoRel: estado ?? '',
                    newVersionIapBusiness: 's2p'
                }).then(
                    () => {

                        firebase.database().ref(`comprasNoAplicativo`).push({
                            chaveUnica: chaveUnica,
                            dataCompra: dataAtual,
                            premium: 1,
                            dataValidoAte: '12/12/2099',
                            identificadorUnicoDaCompra: idSubscription,
                            plano: descricaoPlano ?? '',
                            keyEmpresa,
                            plataforma: 'web',
                            uidComprador: uidComprador,
                            licensawebouapp
                        }).then(
                            () => {
                                loadingTelalVIP(dispatch, false);
                            })

                    })


            })
        })
    }
}

export const inserePlanoPremiumBoleto = (plano, idSubscription, descricaoPlano, renovacaoBoleto2, dataValidoAte, emailAssinante, nomeAssinante, valorPlano, estado) => {
    return dispatch => {
        //
        //  loadingTelalVIP(dispatch, true);
        let dataAtual = retornaDataAtual();
        let dataValidadePlano, dataValidadeAtual;
        let licensawebouapp = 0;
        let planoAnualOuMensal = 'mensal';
        let renovacaoBoleto = false;
        if (renovacaoBoleto2 == true) {
            renovacaoBoleto = true;
        }
        if (dataValidoAte === "12/12/2099") {
            dataValidoAte = dataAtual;
        }
        let quantidadeAtendenteLiberado = 5;
        dataValidadeAtual = moment(dataAtual, 'DD/MM/YYYY').add(1, 'months').format('DD/MM/YYYY');
        planoAnualOuMensal = 'mensal';
        licensawebouapp = 3;
        //
        if (plano == '10') {
            quantidadeAtendenteLiberado = 10;
        } else if (plano == '15') {
            quantidadeAtendenteLiberado = 15;
        } else if (plano == '20') {
            quantidadeAtendenteLiberado = 20;
        } else if (plano == '60') {
            quantidadeAtendenteLiberado = 60;
        } else if (plano == '30') {
            quantidadeAtendenteLiberado = 30;
        } else if (plano == '40') {
            quantidadeAtendenteLiberado = 40;
        } else if (plano == '50') {
            quantidadeAtendenteLiberado = 50;
        } else if (plano == '80') {
            quantidadeAtendenteLiberado = 80;
        } else if (plano == '100') {
            quantidadeAtendenteLiberado = 100;
        } else if (plano == '120') {
            quantidadeAtendenteLiberado = 120;
        } else {
            quantidadeAtendenteLiberado = 5;

        }


        AsyncStorage.getItem('chaveUnica').then((chaveUnica2) => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            let uidComprador = firebase.auth().currentUser.uid;

            firebase.database().ref(`${chaveUnica}/empresa`).once("value").then((snapshot) => {
                let keyEmpresa = '';
                snapshot.forEach(childItem => {
                    keyEmpresa = childItem.key;
                })
                firebase.database().ref(`${chaveUnica}/empresa/${keyEmpresa}`).update({
                    dataCompraRel: dataAtual,
                    emailRel: emailAssinante ?? '',
                    nomeRel: nomeAssinante ?? '',
                    planoRel: planoAnualOuMensal,
                    plataformaRel: 'web',
                    valorPlanoRel: valorPlano ?? '',
                    estadoRel: estado ?? '',
                    statusAssinatura: 'boleto',
                    uidComprador: uidComprador,
                    newVersionIapBusiness: 's2p'
                }).then(
                    () => {
                        firebase.database().ref(`comprasNoAplicativo`).push({
                            chaveUnica: chaveUnica,
                            dataCompra: dataAtual,
                            premium: 0,
                            identificadorUnicoDaCompra: idSubscription,
                            plano: descricaoPlano ?? '',
                            keyEmpresa,
                            plataforma: 'web',
                            uidComprador: uidComprador,
                            licensawebouapp,
                            statusAssinatura: 'boleto',
                            renovacaoBoleto,
                            dataValidoAte
                        }).then(
                            () => {
                                //loadingTelalVIP(dispatch, false);
                            })
                    })
            })
        })
    }
}
export const inserePlanoPremiumPix = (plano, idSubscription, descricaoPlano, renovacaoBoleto2, dataValidoAte, emailAssinante, nomeAssinante, valorPlano, estado) => {
    return dispatch => {
        //
        //  loadingTelalVIP(dispatch, true);
        let dataAtual = retornaDataAtual();
        let dataValidadePlano, dataValidadeAtual;
        let licensawebouapp = 0;
        let planoAnualOuMensal = 'mensal';
        let renovacaoBoleto = false;
        if (renovacaoBoleto2 == true) {
            renovacaoBoleto = true;
        }
        if (dataValidoAte === "12/12/2099") {
            dataValidoAte = dataAtual;
        }
        let quantidadeAtendenteLiberado = 5;
        dataValidadeAtual = moment(dataAtual, 'DD/MM/YYYY').add(1, 'months').format('DD/MM/YYYY');
        planoAnualOuMensal = 'mensal';
        licensawebouapp = 3;
        //
        if (plano == '10') {
            quantidadeAtendenteLiberado = 10;
        } else if (plano == '15') {
            quantidadeAtendenteLiberado = 15;
        } else if (plano == '20') {
            quantidadeAtendenteLiberado = 20;
        } else if (plano == '60') {
            quantidadeAtendenteLiberado = 60;
        } else if (plano == '30') {
            quantidadeAtendenteLiberado = 30;
        } else if (plano == '40') {
            quantidadeAtendenteLiberado = 40;
        } else if (plano == '50') {
            quantidadeAtendenteLiberado = 50;
        } else if (plano == '80') {
            quantidadeAtendenteLiberado = 80;
        } else if (plano == '100') {
            quantidadeAtendenteLiberado = 100;
        } else if (plano == '120') {
            quantidadeAtendenteLiberado = 120;
        } else {
            quantidadeAtendenteLiberado = 5;

        }
        //licensawebou app == 1 (app) == 2 (web) == 3 (webapp)
        /*    if (plano == 'mensalapp' || plano == 'mensalweb' || plano == 'mensalwebapp') {
               dataValidadeAtual = moment(dataAtual, 'DD/MM/YYYY').add(1, 'months').format('DD/MM/YYYY');
           } else {
               dataValidadeAtual = moment(dataAtual, 'DD/MM/YYYY').add(1, 'years').format('DD/MM/YYYY');
           }
           if (plano == 'mensalapp') {
               licensawebouapp = 1;
               planoAnualOuMensal = 'mensal';
           } else if (plano == 'anualapp') {
               licensawebouapp = 1;
               planoAnualOuMensal = 'anual';
           } else if (plano == 'mensalweb') {
               licensawebouapp = 2;
               planoAnualOuMensal = 'mensal';
           } else if (plano == 'anualweb') {
               licensawebouapp = 2;
               planoAnualOuMensal = 'anual';
           }
           else if (plano == 'mensalwebapp') {
               licensawebouapp = 3;
               planoAnualOuMensal = 'mensal';
           } else {
               licensawebouapp = 3;
               planoAnualOuMensal = 'anual';

           } */


        AsyncStorage.getItem('chaveUnica').then((chaveUnica2) => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            let uidComprador = firebase.auth().currentUser.uid;

            firebase.database().ref(`${chaveUnica}/empresa`).once("value").then((snapshot) => {
                let keyEmpresa = '';
                snapshot.forEach(childItem => {
                    keyEmpresa = childItem.key;
                })
                firebase.database().ref(`${chaveUnica}/empresa/${keyEmpresa}`).update({
                    dataCompraRel: dataAtual,
                    emailRel: emailAssinante ?? '',
                    nomeRel: nomeAssinante ?? '',
                    planoRel: planoAnualOuMensal,
                    plataformaRel: 'web',
                    valorPlanoRel: valorPlano ?? '',
                    estadoRel: estado ?? '',
                    statusAssinatura: 'pix',
                    uidComprador: uidComprador,
                    newVersionIapBusiness: 's2p'
                }).then(
                    () => {
                        firebase.database().ref(`comprasNoAplicativo`).push({
                            chaveUnica: chaveUnica,
                            dataCompra: dataAtual,
                            premium: 0,
                            identificadorUnicoDaCompra: idSubscription,
                            plano: descricaoPlano ?? '',
                            keyEmpresa,
                            plataforma: 'web',
                            uidComprador: uidComprador,
                            licensawebouapp,
                            statusAssinatura: 'pix',
                            renovacaoBoleto,
                            dataValidoAte
                        }).then(
                            () => {
                                //loadingTelalVIP(dispatch, false);
                            })
                    })
            })
        })
    }
}
export const insereMotivoCancelamentoBanco = (motivoCancelamento) => {
    return dispatch => {
        AsyncStorage.getItem('chaveUnica').then((chaveUnica2) => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            let uidComprador = firebase.auth().currentUser.uid;

            firebase.database().ref(`${chaveUnica}/empresa`).once("value").then((snapshot) => {
                let keyEmpresa = '';
                let dataValidade = '';
                snapshot.forEach(childItem => {
                    keyEmpresa = childItem.key;
                })
                firebase.database().ref(`${chaveUnica}/empresa/${keyEmpresa}`).update({ motivoCancelamento }).then(
                    () => {

                    })


            })
        })
    }
}

export const exportaAtendimentosParaExcelPdfVisaoGeral = (arrayChamados, operacao, modoExportacao) => {
    return dispatch => {
        //
        AsyncStorage.getItem('chaveUnica').then((chaveUnica2) => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            toastr.warning('Gerando Relatório', 'Por favor aguarde...');
            //

            let results = [];
            if (operacao == 'clientes') {
                for (let i = 0; i < arrayChamados.length; i++) {
                    if (arrayChamados[i].clientes !== undefined && arrayChamados[i].clientes !== null) {
                        results.push(arrayChamados[i].clientes)
                    }
                }

                let arrayChamadoPromisse = [];
                let promisseCliente;
                let chamados = [];
                for (let i = 0; i < results.length; i++) {
                    let cliente = results[i].key;
                    promisseCliente = firebase.database().ref(`${chaveUnica}/clientes/${cliente}/`).once("value");
                    arrayChamadoPromisse.push(promisseCliente);
                }
                let aux = 0;
                //
                Promise.all(arrayChamadoPromisse).then(responses => {
                    //
                    for (let i = 0; i < responses.length; i++) {
                        chamados.push({
                            quantidadeAtendimento: results[i].value,
                            nomeCliente: responses[i].val() == null ? "" : responses[i].val().nome,
                            empresa: responses[i].val() == null ? "" : responses[i].val().empresa

                        });
                        aux++;
                    }
                    //
                    let colunaPrincipal = ['Os Atendimentos ABAIXO são Provenientes do Relatório de Clientes que MAIS abriram Atendimentos!']
                    let colunas = ['Quantidade de Atendimentos', 'Nome do Cliente', 'Empresa'];
                    if (modoExportacao == 'EXCEL') {
                        //
                        exportRelatorioAtendimentosParaExcelVisaoGeral(colunaPrincipal, colunas, chamados, dispatch);
                    } else {
                        //
                        exportRelatorioAtendimentosParaPDFVisaoGeral('Clientes', chamados, dispatch);
                    }

                    //enviaChamadoAberto(dispatch, chamados);
                });

            }
            if (operacao == 'atendentes') {

                for (let i = 0; i < arrayChamados.length; i++) {
                    if (arrayChamados[i].atendentes !== undefined && arrayChamados[i].atendentes !== null) {
                        results.push(arrayChamados[i].atendentes)
                    }
                }

                let arrayChamadoPromisse = [];
                let promisseCliente;
                let chamados = [];
                for (let i = 0; i < results.length; i++) {
                    let cliente = results[i].key;
                    promisseCliente = firebase.database().ref(`${chaveUnica}/usuarios/${cliente}/nome`).once("value");
                    arrayChamadoPromisse.push(promisseCliente);
                }
                let aux = 0;
                //
                Promise.all(arrayChamadoPromisse).then(responses => {
                    //
                    for (let i = 0; i < responses.length; i++) {
                        chamados.push({
                            quantidadeAtendimento: results[i].value,
                            nome: responses[i].val() == null ? "" : responses[i].val(),
                        });
                        aux++;
                    }
                    //
                    //
                    let colunaPrincipal = ['Os Atendimentos ABAIXO são Provenientes do Relatório de Atendentes que MAIS atenderam!']
                    let colunas = ['Quantidade de Atendimentos', 'Nome do Atendente'];
                    if (modoExportacao == 'EXCEL') {
                        //
                        exportRelatorioAtendimentosParaExcelVisaoGeral(colunaPrincipal, colunas, chamados, dispatch);
                    } else {
                        //
                        exportRelatorioAtendimentosParaPDFVisaoGeral('Atendentes', chamados, dispatch);
                    }

                    //enviaChamadoAberto(dispatch, chamados);
                });
            }
            if (operacao == 'setores') {


                for (let i = 0; i < arrayChamados.length; i++) {
                    if (arrayChamados[i].setores !== undefined && arrayChamados[i].setores !== null) {
                        results.push(arrayChamados[i].setores)
                    }
                }

                let arrayChamadoPromisse = [];
                let promisseCliente;
                let chamados = [];
                for (let i = 0; i < results.length; i++) {
                    let cliente = results[i].key;
                    promisseCliente = firebase.database().ref(`${chaveUnica}/setores/${cliente}/nome`).once("value");
                    arrayChamadoPromisse.push(promisseCliente);
                }
                let aux = 0;
                //
                Promise.all(arrayChamadoPromisse).then(responses => {
                    //
                    for (let i = 0; i < responses.length; i++) {
                        chamados.push({
                            quantidadeAtendimento: results[i].value,
                            nome: responses[i].val() == null ? "" : responses[i].val()

                        });
                        aux++;
                    }
                    //
                    let colunaPrincipal = ['Os Atendimentos ABAIXO são Provenientes do Relatório de Setores que MAIS abriram Atendimentos!']
                    let colunas = ['Quantidade de Atendimentos', 'Nome do Setor'];
                    if (modoExportacao == 'EXCEL') {
                        //
                        exportRelatorioAtendimentosParaExcelVisaoGeral(colunaPrincipal, colunas, chamados, dispatch);
                    } else {
                        //
                        exportRelatorioAtendimentosParaPDFVisaoGeral('Setores', chamados, dispatch);
                    }

                    //enviaChamadoAberto(dispatch, chamados);
                });
            }
            if (operacao == 'problemas') {

                //
                for (let i = 0; i < arrayChamados.length; i++) {
                    if (arrayChamados[i].problemas !== undefined && arrayChamados[i].problemas !== null) {
                        results.push(arrayChamados[i].problemas)
                    }
                }

                let arrayChamadoPromisse = [];
                let promisseCliente;
                let chamados = [];
                /*    for (let i = 0; i < results.length; i++) {
                       let cliente = results[i].key;
                       promisseCliente = firebase.database().ref(`${chaveUnica}/setores/${cliente}/nome`).once("value");
                       arrayChamadoPromisse.push(promisseCliente);
                   } */
                let aux = 0;

                //
                for (let i = 0; i < results.length; i++) {
                    chamados.push({
                        quantidadeAtendimento: results[i].value,
                        problema: results[i].key

                    });
                    aux++;
                }
                //
                let colunaPrincipal = ['Os atendimentos ABAIXO são provenientes do relatório de Problemas Estaticos que mais abriram atendimentos!']
                let colunas = ['Quantidade de Atendimentos', 'Descrição Problema'];
                if (modoExportacao == 'EXCEL') {
                    //
                    exportRelatorioAtendimentosParaExcelVisaoGeral(colunaPrincipal, colunas, chamados, dispatch);
                } else {
                    //
                    exportRelatorioAtendimentosParaPDFVisaoGeral('Problemas', chamados, dispatch);
                }

                //enviaChamadoAberto(dispatch, chamados);

            }
        })
    }
}
export const exportaAtendimentosParaExcelPdf = (arrayChamados, operacao, modoExportacao) => {
    return dispatch => {
        AsyncStorage.getItem('chaveUnica').then((chaveUnica2) => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            toastr.warning('Gerando Relatório', 'Por favor aguarde...')
            //
            //
            //
            let results = [];
            if (operacao == 'status') {
                //
                for (let i = 0; i < arrayChamados.length; i++) {
                    if (arrayChamados[i].status !== undefined && arrayChamados[i].status !== null) {
                        results.push(arrayChamados[i].status)
                    }
                }

                let arrayChamadoPromisse = [];
                let promisseCliente;
                let promisseAtendente;
                let promisseSetor;
                let chamados = [];
                for (let i = 0; i < results.length; i++) {
                    let cliente = results[i].cliente;
                    let atendente = results[i].atendente;
                    let setor = results[i].setor;
                    promisseCliente = firebase.database().ref(`${chaveUnica}/clientes/${cliente}/`).once("value");
                    promisseAtendente = firebase.database().ref(`${chaveUnica}/usuarios/${atendente}/nome`).once("value");
                    promisseSetor = firebase.database().ref(`${chaveUnica}/setores/${setor}/nome`).once("value");
                    arrayChamadoPromisse.push(promisseAtendente, promisseCliente, promisseSetor);
                }
                let aux = 0;
                //
                Promise.all(arrayChamadoPromisse).then(responses => {
                    //
                    for (let i = 0; i < responses.length; i += 3) {
                        chamados.push({
                            cliente: responses[i + 1].val() == null ? "" : responses[i + 1].val().nome,
                            empresa: responses[i + 1].val() == null ? "" : responses[i + 1].val().empresa,
                            setor: responses[i + 2].val() == null ? "" : responses[i + 2].val(),
                            atendente: responses[i].val() == null ? "" : responses[i].val(),
                            dataHoraCancelamento: results[aux].dataHoraCancelamento == undefined ? "" : results[aux].dataHoraCancelamento,
                            dataHoraFechamento: results[aux].dataHoraFechamento == undefined ? "" : results[aux].dataHoraFechamento,
                            dataVencimento: results[aux].dataVencimento == "Não informado" ? "" : results[aux].dataVencimento,
                            local: results[aux].local == "Não informado" ? "" : results[aux].local,
                            periodoResolucaoChamado: results[aux].periodoResolucaoChamado == undefined ? "" : results[aux].periodoResolucaoChamado + ' dia(s)',
                            dataHoraAbertura: results[aux].dataHoraAbertura,
                            status: results[aux].status,
                            prioridade: results[aux].prioridade === "Não informado" ? "" : results[aux].prioridade,
                            descricaoProblema: results[aux].descricaoProblema,
                            numeroChamado: results[aux].numeroChamado,
                            tempoAtendimentoGasto: results[aux].tempoGasto == 0 ? "" : results[aux].tempoGasto + ' min.',
                            solucaoAtendimento: results[aux].solucaoAtendimento == undefined ? "" : results[aux].solucaoAtendimento,
                        });
                        aux++;
                    }
                    //
                    let colunaPrincipal = ['Os Atendimentos ABAIXO são Provenientes do Relatório de Atendimento Por STATUS']
                    let colunas = ['Numero do Atendimento', 'Problema', 'Solução', 'Status', 'Cliente'
                        , 'Empresa', 'Setor', 'Atendente(Técnico)', 'Data Abertura Atendimento', 'Prioridade'
                        , 'Local', 'Data Vencimento Atendimento', 'Data Fechamento Atendimento',
                        'Data Cancelamento Atendimento', 'Período Resolução Chamado', 'Tempo Atendimento Gasto',];
                    if (modoExportacao == 'EXCEL') {
                        //
                        exportRelatorioAtendimentosParaExcel(colunaPrincipal, colunas, chamados, dispatch);
                    } else {
                        //
                        exportRelatorioAtendimentosParaPDF('STATUS', chamados, dispatch);
                    }

                    //enviaChamadoAberto(dispatch, chamados);
                });

            }
            if (operacao == 'prioridade') {
                //
                for (let i = 0; i < arrayChamados.length; i++) {
                    if (arrayChamados[i].prioridade !== undefined && arrayChamados[i].prioridade !== null) {
                        results.push(arrayChamados[i].prioridade)
                    }
                }

                let arrayChamadoPromisse = [];
                let promisseCliente;
                let promisseAtendente;
                let promisseSetor;
                let chamados = [];
                for (let i = 0; i < results.length; i++) {
                    let cliente = results[i].cliente;
                    let atendente = results[i].atendente;
                    let setor = results[i].setor;
                    promisseCliente = firebase.database().ref(`${chaveUnica}/clientes/${cliente}/`).once("value");
                    promisseAtendente = firebase.database().ref(`${chaveUnica}/usuarios/${atendente}/nome`).once("value");
                    promisseSetor = firebase.database().ref(`${chaveUnica}/setores/${setor}/nome`).once("value");
                    arrayChamadoPromisse.push(promisseAtendente, promisseCliente, promisseSetor);
                }
                let aux = 0;
                //
                Promise.all(arrayChamadoPromisse).then(responses => {
                    //
                    for (let i = 0; i < responses.length; i += 3) {
                        chamados.push({
                            cliente: responses[i + 1].val() == null ? "" : responses[i + 1].val().nome,
                            empresa: responses[i + 1].val() == null ? "" : responses[i + 1].val().empresa,
                            setor: responses[i + 2].val() == null ? "" : responses[i + 2].val(),
                            atendente: responses[i].val() == null ? "" : responses[i].val(),
                            dataHoraCancelamento: results[aux].dataHoraCancelamento == undefined ? "" : results[aux].dataHoraCancelamento,
                            dataHoraFechamento: results[aux].dataHoraFechamento == undefined ? "" : results[aux].dataHoraFechamento,
                            dataVencimento: results[aux].dataVencimento == "Não informado" ? "" : results[aux].dataVencimento,
                            local: results[aux].local == "Não informado" ? "" : results[aux].local,
                            periodoResolucaoChamado: results[aux].periodoResolucaoChamado == undefined ? "" : results[aux].periodoResolucaoChamado + ' dia(s)',
                            dataHoraAbertura: results[aux].dataHoraAbertura,
                            status: results[aux].status,
                            prioridade: results[aux].prioridade === "Não informado" ? "" : results[aux].prioridade,
                            descricaoProblema: results[aux].descricaoProblema,
                            numeroChamado: results[aux].numeroChamado,
                            tempoAtendimentoGasto: results[aux].tempoGasto == 0 ? "" : results[aux].tempoGasto + ' min.',
                            solucaoAtendimento: results[aux].solucaoAtendimento == undefined ? "" : results[aux].solucaoAtendimento,
                        });
                        aux++;
                    }
                    //
                    let colunaPrincipal = ['Os Atendimentos ABAIXO são Provenientes do Relatório de Atendimento Por PRIORIDADE']
                    let colunas = ['Numero do Atendimento', 'Problema', 'Solução', 'Status', 'Cliente'
                        , 'Empresa', 'Setor', 'Atendente(Técnico)', 'Data Abertura Atendimento', 'Prioridade'
                        , 'Local', 'Data Vencimento Atendimento', 'Data Fechamento Atendimento',
                        'Data Cancelamento Atendimento', 'Período Resolução Chamado', 'Tempo Atendimento Gasto',];
                    if (modoExportacao == 'EXCEL') {
                        //
                        exportRelatorioAtendimentosParaExcel(colunaPrincipal, colunas, chamados, dispatch);
                    } else {
                        //
                        exportRelatorioAtendimentosParaPDF('PRIORIDADE', chamados, dispatch);
                    }
                    //enviaChamadoAberto(dispatch, chamados);
                });

            }
            if (operacao == 'local') {
                //
                for (let i = 0; i < arrayChamados.length; i++) {
                    if (arrayChamados[i].local !== undefined && arrayChamados[i].local !== null) {
                        results.push(arrayChamados[i].local)
                    }
                }

                let arrayChamadoPromisse = [];
                let promisseCliente;
                let promisseAtendente;
                let promisseSetor;
                let chamados = [];
                for (let i = 0; i < results.length; i++) {
                    let cliente = results[i].cliente;
                    let atendente = results[i].atendente;
                    let setor = results[i].setor;
                    promisseCliente = firebase.database().ref(`${chaveUnica}/clientes/${cliente}/`).once("value");
                    promisseAtendente = firebase.database().ref(`${chaveUnica}/usuarios/${atendente}/nome`).once("value");
                    promisseSetor = firebase.database().ref(`${chaveUnica}/setores/${setor}/nome`).once("value");
                    arrayChamadoPromisse.push(promisseAtendente, promisseCliente, promisseSetor);
                }
                let aux = 0;
                //
                Promise.all(arrayChamadoPromisse).then(responses => {
                    //
                    for (let i = 0; i < responses.length; i += 3) {
                        chamados.push({
                            cliente: responses[i + 1].val() == null ? "" : responses[i + 1].val().nome,
                            empresa: responses[i + 1].val() == null ? "" : responses[i + 1].val().empresa,
                            setor: responses[i + 2].val() == null ? "" : responses[i + 2].val(),
                            atendente: responses[i].val() == null ? "" : responses[i].val(),
                            dataHoraCancelamento: results[aux].dataHoraCancelamento == undefined ? "" : results[aux].dataHoraCancelamento,
                            dataHoraFechamento: results[aux].dataHoraFechamento == undefined ? "" : results[aux].dataHoraFechamento,
                            dataVencimento: results[aux].dataVencimento == "Não informado" ? "" : results[aux].dataVencimento,
                            local: results[aux].local == "Não informado" ? "" : results[aux].local,
                            periodoResolucaoChamado: results[aux].periodoResolucaoChamado == undefined ? "" : results[aux].periodoResolucaoChamado + ' dia(s)',
                            dataHoraAbertura: results[aux].dataHoraAbertura,
                            status: results[aux].status,
                            prioridade: results[aux].prioridade === "Não informado" ? "" : results[aux].prioridade,
                            descricaoProblema: results[aux].descricaoProblema,
                            numeroChamado: results[aux].numeroChamado,
                            tempoAtendimentoGasto: results[aux].tempoGasto == 0 ? "" : results[aux].tempoGasto + ' min.',
                            solucaoAtendimento: results[aux].solucaoAtendimento == undefined ? "" : results[aux].solucaoAtendimento,
                        });
                        aux++;
                    }
                    //
                    let colunaPrincipal = ['Os Atendimentos ABAIXO são Provenientes do Relatório de Atendimento Por LOCAL']
                    let colunas = ['Numero do Atendimento', 'Problema', 'Solução', 'Status', 'Cliente'
                        , 'Empresa', 'Setor', 'Atendente(Técnico)', 'Data Abertura Atendimento', 'Prioridade'
                        , 'Local', 'Data Vencimento Atendimento', 'Data Fechamento Atendimento',
                        'Data Cancelamento Atendimento', 'Período Resolução Chamado', 'Tempo Atendimento Gasto',];
                    if (modoExportacao == 'EXCEL') {
                        //
                        exportRelatorioAtendimentosParaExcel(colunaPrincipal, colunas, chamados, dispatch);
                    } else {
                        //
                        exportRelatorioAtendimentosParaPDF('LOCAL', chamados, dispatch);
                    }
                    //enviaChamadoAberto(dispatch, chamados);
                });

            }
            if (operacao == 'solucao') {
                //
                for (let i = 0; i < arrayChamados.length; i++) {
                    if (arrayChamados[i].solucao !== undefined && arrayChamados[i].solucao !== null) {
                        results.push(arrayChamados[i].solucao)
                    }
                }

                let arrayChamadoPromisse = [];
                let promisseCliente;
                let promisseAtendente;
                let promisseSetor;
                let chamados = [];
                for (let i = 0; i < results.length; i++) {
                    let cliente = results[i].cliente;
                    let atendente = results[i].atendente;
                    let setor = results[i].setor;
                    promisseCliente = firebase.database().ref(`${chaveUnica}/clientes/${cliente}/`).once("value");
                    promisseAtendente = firebase.database().ref(`${chaveUnica}/usuarios/${atendente}/nome`).once("value");
                    promisseSetor = firebase.database().ref(`${chaveUnica}/setores/${setor}/nome`).once("value");
                    arrayChamadoPromisse.push(promisseAtendente, promisseCliente, promisseSetor);
                }
                let aux = 0;
                //
                Promise.all(arrayChamadoPromisse).then(responses => {
                    //
                    for (let i = 0; i < responses.length; i += 3) {
                        chamados.push({
                            cliente: responses[i + 1].val() == null ? "" : responses[i + 1].val().nome,
                            empresa: responses[i + 1].val() == null ? "" : responses[i + 1].val().empresa,
                            setor: responses[i + 2].val() == null ? "" : responses[i + 2].val(),
                            atendente: responses[i].val() == null ? "" : responses[i].val(),
                            dataHoraCancelamento: results[aux].dataHoraCancelamento == undefined ? "" : results[aux].dataHoraCancelamento,
                            dataHoraFechamento: results[aux].dataHoraFechamento == undefined ? "" : results[aux].dataHoraFechamento,
                            dataVencimento: results[aux].dataVencimento == "Não informado" ? "" : results[aux].dataVencimento,
                            local: results[aux].local == "Não informado" ? "" : results[aux].local,
                            periodoResolucaoChamado: results[aux].periodoResolucaoChamado == undefined ? "" : results[aux].periodoResolucaoChamado + ' dias(s)',
                            dataHoraAbertura: results[aux].dataHoraAbertura,
                            status: results[aux].status,
                            prioridade: results[aux].prioridade === "Não informado" ? "" : results[aux].prioridade,
                            descricaoProblema: results[aux].descricaoProblema,
                            numeroChamado: results[aux].numeroChamado,
                            tempoAtendimentoGasto: results[aux].tempoGasto == 0 ? "" : results[aux].tempoGasto + ' min.',
                            solucaoAtendimento: results[aux].solucaoAtendimento == undefined ? "" : results[aux].solucaoAtendimento,
                        });
                        aux++;
                    }
                    //
                    let colunaPrincipal = ['Os Atendimentos ABAIXO são Provenientes do Relatório de Atendimento Por PERIODO DE RESOLUÇÃO']
                    let colunas = ['Numero do Atendimento', 'Problema', 'Solução', 'Status', 'Cliente'
                        , 'Empresa', 'Setor', 'Atendente(Técnico)', 'Data Abertura Atendimento', 'Prioridade'
                        , 'Local', 'Data Vencimento Atendimento', 'Data Fechamento Atendimento',
                        'Data Cancelamento Atendimento', 'Período Resolução Chamado', 'Tempo Atendimento Gasto'];
                    if (modoExportacao == 'EXCEL') {
                        //
                        exportRelatorioAtendimentosParaExcel(colunaPrincipal, colunas, chamados, dispatch);
                    } else {
                        //
                        exportRelatorioAtendimentosParaPDF('PERÍODO RESOLUÇÃO CHAMADO', chamados, dispatch);
                    }
                    //enviaChamadoAberto(dispatch, chamados);
                });

            }
        })
    }
}

function exportRelatorioAtendimentosParaPDF(operacao, chamados, dispatch) {

    try {
        //pegando data e hora para colocar no nome do arquivo para que nao haja repeticao de nome
        let dataAtual = moment().toDate();
        dataAtual = moment(dataAtual).format("DD-MM-YYYY HH-mm-ss");
        //

        let mytable =
            '<table border="1" borderColor="#CCC">' +
            '<caption>Relatório de Atendimentos Por ' + operacao + '</caption>' +
            '<th style="background-color:#A9A9A9">Atendimento</th>' +
            '<th style="background-color:#A9A9A9">Problema</th>' +
            '<th style="background-color:#A9A9A9">Solução</th>' +
            '<th style="background-color:#A9A9A9">Status</th>' +
            '<th style="background-color:#A9A9A9">Cliente</th>' +
            '<th style="background-color:#A9A9A9">Empresa</th>' +
            '<th style="background-color:#A9A9A9">Setor</th>' +
            '<th style="background-color:#A9A9A9">Atendente</th>' +
            '<th style="background-color:#A9A9A9">Abertura</th>' +
            '<th style="background-color:#A9A9A9">Prioridade</th>' +
            '<th style="background-color:#A9A9A9">Local</th>' +
            '<th style="background-color:#A9A9A9">Vencimento</th>' +
            '<th style="background-color:#A9A9A9">Fechamento</th>' +
            '<th style="background-color:#A9A9A9">Cancelamento</th>' +
            '<th style="background-color:#A9A9A9">Periodo Resolução</th>' +
            '<th style="background-color:#A9A9A9">Tempo Atend. Gasto</th>' +
            '<tr>';
        let aux = 1;
        for (let i = 0; i < chamados.length; i++) {
            if (aux % 2 == 0 && i !== 0) {
                mytable += "<tr> <td style=background-color:#CCC>#" + chamados[i].numeroChamado + "</td>" +
                    "<td style=background-color:#CCC>" + chamados[i].descricaoProblema + "</td>" +
                    "<td style=background-color:#CCC>" + chamados[i].solucaoAtendimento + "</td>" +
                    "<td style=background-color:#CCC>" + chamados[i].status + "</td>" +
                    "<td style=background-color:#CCC>" + chamados[i].cliente + "</td>" +
                    "<td style=background-color:#CCC>" + chamados[i].empresa + "</td>" +
                    "<td style=background-color:#CCC>" + chamados[i].setor + "</td>" +
                    "<td style=background-color:#CCC>" + chamados[i].atendente + "</td>" +
                    "<td style=background-color:#CCC>" + chamados[i].dataHoraAbertura + "</td>" +
                    "<td style=background-color:#CCC>" + chamados[i].prioridade + "</td>" +
                    "<td style=background-color:#CCC>" + chamados[i].local + "</td>" +
                    "<td style=background-color:#CCC>" + chamados[i].dataVencimento + "</td>" +
                    "<td style=background-color:#CCC>" + chamados[i].dataHoraFechamento + "</td>" +
                    "<td style=background-color:#CCC>" + chamados[i].dataHoraCancelamento + "</td>" +
                    "<td style=background-color:#CCC>" + chamados[i].periodoResolucaoChamado + "</td>" +
                    "<td style=background-color:#CCC>" + chamados[i].tempoAtendimentoGasto + "</td> </tr>";
            } else {
                mytable += "<tr> <td>#" + chamados[i].numeroChamado + "</td>" +
                    "<td>" + chamados[i].descricaoProblema + "</td>" +
                    "<td>" + chamados[i].solucaoAtendimento + "</td>" +
                    "<td>" + chamados[i].status + "</td>" +
                    "<td>" + chamados[i].cliente + "</td>" +
                    "<td>" + chamados[i].empresa + "</td>" +
                    "<td>" + chamados[i].setor + "</td>" +
                    "<td>" + chamados[i].atendente + "</td>" +
                    "<td>" + chamados[i].dataHoraAbertura + "</td>" +
                    "<td>" + chamados[i].prioridade + "</td>" +
                    "<td>" + chamados[i].local + "</td>" +
                    "<td>" + chamados[i].dataVencimento + "</td>" +
                    "<td>" + chamados[i].dataHoraFechamento + "</td>" +
                    "<td>" + chamados[i].dataHoraCancelamento + "</td>" +
                    "<td>" + chamados[i].periodoResolucaoChamado + "</td>" +
                    "<td>" + chamados[i].tempoAtendimentoGasto + "</td> </tr>";
            }
            aux++
        }
        //
        //
        mytable += " </table>";
        //
        //
        let opt = {
            filename: `relatorio${dataAtual}.pdf`,
            jsPDF: { orientation: 'landscape', unit: 'pt', format: 'a4' },
            pagebreak: { mode: ['css'], avoid: 'tr' },
            html2canvas: {
                dpi: 300,
                letterRendering: true,
                useCORS: true
            }
        };


        html2pdf().from(mytable).set(opt).toPdf().output('blob').then(function (pdfBlob) {
            window.open(URL.createObjectURL(pdfBlob));
        });


    } catch (err) {
        alert(err);
    }


    //  XLSX.writeFile(wb, "export-demo.xlsx")
}

function exportRelatorioAtendimentosParaExcel(colunaPrincipal, colunas, linhas, dispatch, modoSalvarRelatorioLocalOuEmail) {

    //pegando data e hora para colocar no nome do arquivo para que nao haja repeticao de nome
    let dataAtual = moment().toDate();
    dataAtual = moment(dataAtual).format("DD-MM-YYYY HH-mm-ss");
    //

    //colunas do excel, recebe array com colunas
    //let users = [["First Name", "Last Name", "Age"]]
    let users = [colunaPrincipal, colunas]
    //valores em array
    linhas.forEach((atendimento) => {
        let userArray = [
            atendimento.numeroChamado, atendimento.descricaoProblema, atendimento.solucaoAtendimento, atendimento.status, atendimento.cliente,
            atendimento.empresa, atendimento.setor, atendimento.atendente, atendimento.dataHoraAbertura,
            atendimento.prioridade, atendimento.local, atendimento.dataVencimento, atendimento.dataHoraFechamento,
            atendimento.dataHoraCancelamento, atendimento.periodoResolucaoChamado, atendimento.tempoAtendimentoGasto
        ]
        users.push(userArray)
    })
    //
    const wb = XLSX.utils.book_new()

    const wsAll = XLSX.utils.aoa_to_sheet(users)
    XLSX.utils.book_append_sheet(wb, wsAll, "All Users")
    const wbout = XLSX.write(wb, { type: 'binary', bookType: "xlsx" });
    //
    const url = window.URL.createObjectURL(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }));
    download(url, `relatorio${dataAtual}.xlsx`);
}

function exportRelatorioAtendimentosParaExcelVisaoGeral(colunaPrincipal, colunas, linhas, dispatch, modoSalvarRelatorioLocalOuEmail) {
    //

    //pegando data e hora para colocar no nome do arquivo para que nao haja repeticao de nome
    let dataAtual = moment().toDate();
    dataAtual = moment(dataAtual).format("DD-MM-YYYY HH-mm-ss");
    //

    //caminho do arquivo pasta download + nome com a data e hora

    //colunas do excel, recebe array com colunas
    //let users = [["First Name", "Last Name", "Age"]]
    let users = [colunaPrincipal, colunas]
    //
    //
    //se colunas.length for igual a 3 é porque é clientes, se nao for eh setor ou atendente
    if (colunas.length == 3) {
        linhas.forEach((atendimento) => {
            let userArray = [
                atendimento.quantidadeAtendimento, atendimento.nomeCliente, atendimento.empresa
            ]
            users.push(userArray)
        })
    } else {
        linhas.forEach((atendimento) => {
            let userArray = [
                atendimento.quantidadeAtendimento, atendimento.nome
            ]
            users.push(userArray)
        })
    }

    const wb = XLSX.utils.book_new()
    //

    const wsAll = XLSX.utils.aoa_to_sheet(users)
    XLSX.utils.book_append_sheet(wb, wsAll, "All Users")
    const wbout = XLSX.write(wb, { type: 'binary', bookType: "xlsx" });
    const url = window.URL.createObjectURL(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }));
    download(url, `relatorio${dataAtual}.xlsx`);

}

function exportRelatorioAtendimentosParaPDFVisaoGeral(operacao, chamados, dispatch, modoSalvarRelatorioLocalOuEmail) {
    try {
        //pegando data e hora para colocar no nome do arquivo para que nao haja repeticao de nome
        let dataAtual = moment().toDate();
        dataAtual = moment(dataAtual).format("DD-MM-YYYY HH-mm-ss");
        //

        let mytable;
        if (operacao == 'Clientes') {
            mytable =
                '<table border="1" borderColor="#CCC">' +
                '<caption>Relatório de ' + operacao + ' com mais Atendimentos</caption>' +
                '<th style="background-color:#A9A9A9">Qtd Atendimento</th>' +
                '<th style="background-color:#A9A9A9">Nome</th>' +
                '<th style="background-color:#A9A9A9">Empresa</th>' +
                '<tr>';
            let aux = 1;
            for (let i = 0; i < chamados.length; i++) {

                if (aux % 2 == 0 && i !== 0) {
                    mytable += "<tr> <td style=background-color:#CCC>" + chamados[i].quantidadeAtendimento + "</td>" +
                        "<td style=background-color:#CCC>" + chamados[i].nomeCliente + "</td>" +
                        "<td style=background-color:#CCC>" + chamados[i].empresa + "</td>" +
                        "</tr>";
                } else {
                    mytable += "<tr> <td>" + chamados[i].quantidadeAtendimento + "</td>" +
                        "<td>" + chamados[i].nomeCliente + "</td>" +
                        "<td>" + chamados[i].empresa + "</td>" +
                        "</tr>";
                }
                aux++
            }
        } else if (operacao == 'Problemas') {
            mytable =
                '<table border="1" borderColor="#CCC">' +
                '<caption>Relatório de ' + operacao + ' Estáticos com mais Atendimentos</caption>' +
                '<th style="background-color:#A9A9A9">Qtd Atendimento</th>' +
                '<th style="background-color:#A9A9A9">Nome</th>' +
                '<tr>';
            let aux = 1;
            for (let i = 0; i < chamados.length; i++) {

                if (aux % 2 == 0 && i !== 0) {
                    mytable += "<tr> <td style=background-color:#CCC>" + chamados[i].quantidadeAtendimento + "</td>" +
                        "<td style=background-color:#CCC>" + chamados[i].problema + "</td>" +
                        "</tr>";
                } else {
                    mytable += "<tr> <td>" + chamados[i].quantidadeAtendimento + "</td>" +
                        "<td>" + chamados[i].problema + "</td>" +
                        "</tr>";
                }
                aux++
            }
        } else {
            mytable =
                '<table border="1" borderColor="#CCC">' +
                '<caption>Relatório de ' + operacao + ' com mais Atendimentos</caption>' +
                '<th style="background-color:#A9A9A9">Qtd Atendimento</th>' +
                '<th style="background-color:#A9A9A9">Nome</th>' +
                '<tr>';
            let aux = 1;
            for (let i = 0; i < chamados.length; i++) {

                if (aux % 2 == 0 && i !== 0) {
                    mytable += "<tr> <td style=background-color:#CCC>" + chamados[i].quantidadeAtendimento + "</td>" +
                        "<td style=background-color:#CCC>" + chamados[i].nome + "</td>" +
                        "</tr>";
                } else {
                    mytable += "<tr> <td>" + chamados[i].quantidadeAtendimento + "</td>" +
                        "<td>" + chamados[i].nome + "</td>" +
                        "</tr>";
                }
                aux++
            }
        }
        //
        //
        mytable += " </table>";
        //
        //
        let opt = {
            filename: `relatorio${dataAtual}.pdf`,
            jsPDF: { orientation: 'landscape', unit: 'pt', format: 'a4' },
            pagebreak: { mode: ['css'], avoid: 'tr' },
            html2canvas: {
                dpi: 300,
                letterRendering: true,
                useCORS: true
            }
        };


        html2pdf().from(mytable).set(opt).toPdf().output('blob').then(function (pdfBlob) {
            window.open(URL.createObjectURL(pdfBlob));
        });

    } catch (err) {
        alert(err);
    }


    //  XLSX.writeFile(wb, "export-demo.xlsx")
}

export const listaAtendimentosRelatorioGeralPersonalizado = (dataInicial2, dataFinal2, valorReferencia) => {
    return dispatch => {
        let dataInicial, dataFinal;
        if (valorReferencia == 0) {
            //valor Personalizado
            dataInicial = moment(dataInicial2).format('DD/MM/YYYY')
            dataFinal = moment(dataFinal2).format('DD/MM/YYYY')
        } else {
            //valor Inicial
            let dataHojeWeb = moment().toDate();
            let dataHojeEnviaDatePicker = moment().toDate();
            dataHojeEnviaDatePicker = moment(dataHojeEnviaDatePicker);
            dataHojeWeb = moment(dataHojeWeb).format('DD/MM/YYYY');

            dataInicial = dataHojeWeb;
            dataFinal = dataHojeWeb;
            dispatch({ type: MODIFICADATAINICIALRELATORIOGERAL, payload: dataHojeEnviaDatePicker });
            dispatch({ type: MODIFICADATAFINALRELATORIOGERAL, payload: dataHojeEnviaDatePicker });
        }
        //
        let arrayChamadosComClientes = [];
        let arrayChamadosComAtendentes = [];
        let arrayChamadosComSetores = [];
        let arrayChamadosComProblemasEstaticos = [];
        let tempoMedioParaSolucaoAtendimentos = 0;
        let tempoMedioPrimeiraRespospota = [];
        let tempoParaSolucaoAtendimentos = [];
        let atendimento1estrela = 0;
        let atendimento2estrela = 0;
        let atendimento3estrela = 0;
        let atendimento4estrela = 0;
        let atendimento5estrela = 0;
        let totalAvaliacoes = 0;
        let pessoasComMaisAtendimentosPraBaixar = [];


        let dataInicialVerificacao = moment(dataInicial, 'DD/MM/YYYY').format('YYYY-MM-DD');
        let dataFinalVerificacao = moment(dataFinal, 'DD/MM/YYYY').format('YYYY-MM-DD');
        let verificaSeDataInicialMaiorQueDataFinal = moment(dataFinalVerificacao).isBefore(dataInicialVerificacao);


        AsyncStorage.getItem('chaveUnica').then((chaveUnica2) => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            //
            if (dataInicial == '' && dataFinal == '' || dataInicial == null && dataFinal == null && valorReferencia == 0) {
                enviaSemConexaoInternetRelatorioGeral(dispatch, 'Informe a DATA INICIAL e DATA FINAL do período de pesquisa.');
            } else if (dataInicial == '' || dataInicial == null && valorReferencia == 0) {
                enviaSemConexaoInternetRelatorioGeral(dispatch, 'Informe a DATA INICIAL do período de pesquisa.');
            } else if (dataFinal == '' || dataFinal == null && valorReferencia == 0) {
                enviaSemConexaoInternetRelatorioGeral(dispatch, 'Informe a DATA FINAL do período de pesquisa.');
            } else if (verificaSeDataInicialMaiorQueDataFinal == true && valorReferencia == 0) {
                enviaSemConexaoInternetRelatorioGeral(dispatch, 'DATA INICIAL maior que DATA FINAL, por favor verifique.');
            } else {
                ativaDesativaLoadingSemInternetRelatorioGeral(dispatch, false);
                ativaDesativaLoadingRelatorioGeral(dispatch, true);
                enviaPeriodoPersonalizadoRelatorioGeral(dispatch, `${dataInicial} - ${dataFinal}`);

                dataInicial = dataInicial + ' 00:00:01';
                dataFinal = dataFinal + ' 23:59:59';
                let dataAtual = retornaDataAtual();
                let dataInicioConsulta, dataFinalConsulta;

                //verificacao para saber qual periodo é e passar o periodo correto para a query do firebase
                //
                dataInicioConsulta = retornaDataTimeStamp(dataInicial);
                dataFinalConsulta = retornaDataTimeStamp(dataFinal);
                //
                //;
                /*   firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild('dataAberturaTimeStamp')
                      .startAt(dataInicioConsulta).endAt(dataFinalConsulta).once("value").then((snapshot) => {

                          if (snapshot.val()) { */
                const options = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                        'Accept': 'application/json',
                    },

                    data: {
                        chaveUnica: chaveUnica,
                        dataInicioConsulta: dataInicioConsulta,
                        dataFinalConsulta: dataFinalConsulta,
                        rota: 'atendimentodetalhado'
                    },
                    url: 'gerarRelatoriosAtendimentos',
                };
                api(options).then((snapshot) => {


                    snapshot = snapshot.data;

                    if (snapshot !== null) {

                        //
                        let arrayOfKeys = Object.keys(snapshot).sort().reverse();
                        let results = arrayOfKeys.map((key) => snapshot[key]);
                        for (let i = 0; i < results.length; i++) {

                            if (results[i].clienteVinculado == 1) {
                                //
                                //
                                arrayChamadosComClientes.push(results[i].cliente)
                            }
                            if (results[i].setorVinculado == 1) {
                                arrayChamadosComSetores.push(results[i].setor);
                            }
                            if (results[i].atendente != 'Não informado' && results[i].atendente != '' &&
                                results[i].atendente != null && results[i].atendente != undefined) {
                                arrayChamadosComAtendentes.push(results[i].atendente);
                            }
                            if (results[i].descricaoProblemaEstatico == 1) {
                                arrayChamadosComProblemasEstaticos.push(results[i].descricaoProblema);
                            }
                            if (results[i].status == 'Fechado') {
                                if (results[i].dataHoraAbertura != undefined && results[i].dataHoraAbertura != '' &&
                                    results[i].dataHoraAbertura != null && results[i].dataHoraFechamento != undefined &&
                                    results[i].dataHoraFechamento != '' && results[i].dataHoraFechamento != null) {
                                    //
                                    //

                                    let dataSubtraida = moment(results[i].dataHoraFechamento, 'DD/MM/YYYY HH:mm').diff(moment(results[i].dataHoraAbertura, 'DD/MM/YYYY HH:mm'))
                                    //;
                                    tempoParaSolucaoAtendimentos.push(dataSubtraida);
                                    tempoMedioPrimeiraRespospota.push(results[i].tempoPrimeiraResposta == undefined ? 0 : results[i].tempoPrimeiraResposta);
                                }
                            }
                        }
                        if (arrayChamadosComClientes.length != 0) {
                            let arrayDeKeyRepetidos = arrayChamadosComClientes.reduce(function (prev, cur) {
                                prev[cur] = (prev[cur] || 0) + 1;
                                return prev;
                            }, {});

                            let testeOrdenacao = ordenaObjeto(arrayDeKeyRepetidos);
                            for (let i = 0; i < testeOrdenacao.length; i++) {
                                pessoasComMaisAtendimentosPraBaixar.push({ clientes: testeOrdenacao[i] })
                            }
                            //se for maior tenho que pegar somente as 4 primeiras casas do array
                            if (testeOrdenacao.length >= 4) {
                                let arrayChamado = [];
                                let promisseCliente;
                                let labelClientes = [];
                                let valoresClientes = []
                                for (let i = 0; i < 4; i++) {
                                    let cliente = testeOrdenacao[i].key;

                                    promisseCliente = firebase.database().ref(`${chaveUnica}/clientes/${cliente}/nome`).once('value')
                                    arrayChamado.push(promisseCliente);

                                }
                                Promise.all(arrayChamado).then(responses => {
                                    for (let i = 0; i < 4; i++) {
                                        valoresClientes.push({
                                            x: responses[i].val(),
                                            y: testeOrdenacao[i].value
                                        })
                                        /*  labelClientes.push(

                                             responses[i].val(),

                                         );
                                         valoresClientes.push(

                                             testeOrdenacao[i].value,

                                         ); */


                                    }
                                    //

                                    let dataLineChartCliente = [{
                                        "id": "Clientes",
                                        "data": valoresClientes
                                    }]
                                    enviaClientesMaisAbriramChamadosRelatorioGeral(dispatch, dataLineChartCliente);
                                });
                            }
                            //se não eu pego todo array, já que o mesmo será menos que 4
                            else {
                                let arrayChamado = [];
                                let promisseCliente;
                                let labelClientes = [];
                                let valoresClientes = []
                                for (let i = 0; i < testeOrdenacao.length; i++) {
                                    let cliente = testeOrdenacao[i].key;

                                    promisseCliente = firebase.database().ref(`${chaveUnica}/clientes/${cliente}/nome`).once('value')
                                    arrayChamado.push(promisseCliente);

                                }
                                Promise.all(arrayChamado).then(responses => {
                                    for (let i = 0; i < testeOrdenacao.length; i++) {
                                        valoresClientes.push({
                                            x: responses[i].val(),
                                            y: testeOrdenacao[i].value
                                        })
                                        /* labelClientes.push(

                                            responses[i].val(),

                                        );
                                        valoresClientes.push(

                                            testeOrdenacao[i].value,

                                        ); */


                                    }
                                    //
                                    //
                                    //
                                    let dataLineChartCliente = [{
                                        "id": "Clientes",
                                        "data": valoresClientes
                                    }]
                                    //
                                    enviaClientesMaisAbriramChamadosRelatorioGeral(dispatch, dataLineChartCliente);
                                });
                            }
                        } else {
                            //se não, não tem dados, então envia zerado
                            //
                            let clientesMaisAbriramChamadosZerado = [
                                {
                                    "id": "Clientes",
                                    "data": [
                                        {
                                            "x": "0",
                                            "y": 0
                                        },
                                    ]
                                },
                            ];
                            enviaClientesMaisAbriramChamadosRelatorioGeral(dispatch, clientesMaisAbriramChamadosZerado);
                        }
                        if (arrayChamadosComSetores.length != 0) {
                            let arrayDeKeyRepetidos = arrayChamadosComSetores.reduce(function (prev, cur) {
                                prev[cur] = (prev[cur] || 0) + 1;
                                return prev;
                            }, {});
                            let testeOrdenacao = ordenaObjeto(arrayDeKeyRepetidos);
                            for (let i = 0; i < testeOrdenacao.length; i++) {
                                pessoasComMaisAtendimentosPraBaixar.push({ setores: testeOrdenacao[i] })
                            }
                            if (testeOrdenacao.length >= 4) {
                                let arrayChamado = [];
                                let promisseSetor;
                                let labelClientes = [];
                                let valoresClientes = []
                                for (let i = 0; i < 4; i++) {
                                    let setor = testeOrdenacao[i].key;

                                    promisseSetor = firebase.database().ref(`${chaveUnica}/setores/${setor}/nome`).once('value')
                                    arrayChamado.push(promisseSetor);

                                }
                                Promise.all(arrayChamado).then(responses => {
                                    for (let i = 0; i < 4; i++) {

                                        valoresClientes.push({
                                            x: responses[i].val(),
                                            y: testeOrdenacao[i].value
                                        })
                                        /*   labelClientes.push(

                                              responses[i].val(),

                                          );
                                          valoresClientes.push(

                                              testeOrdenacao[i].value,

                                          ); */


                                    }
                                    let dataLineChartSetor = [{
                                        "id": "Setores",
                                        "data": valoresClientes
                                    }]
                                    enviaSetoresMaisAbriramChamadosRelatorioGeral(dispatch, dataLineChartSetor);
                                });
                            }
                            //se não eu pego todo array, já que o mesmo será menos que 4
                            else {
                                let arrayChamado = [];
                                let promisseSetor;
                                let labelClientes = [];
                                let valoresClientes = []
                                for (let i = 0; i < testeOrdenacao.length; i++) {
                                    let setor = testeOrdenacao[i].key;

                                    promisseSetor = firebase.database().ref(`${chaveUnica}/setores/${setor}/nome`).once('value')
                                    arrayChamado.push(promisseSetor);

                                }

                                Promise.all(arrayChamado).then(responses => {
                                    for (let i = 0; i < testeOrdenacao.length; i++) {
                                        valoresClientes.push({
                                            x: responses[i].val(),
                                            y: testeOrdenacao[i].value
                                        })
                                        /*  labelClientes.push(

                                             responses[i].val(),

                                         );
                                         valoresClientes.push(

                                             testeOrdenacao[i].value,

                                         ); */


                                    }
                                    let dataLineChartSetor = [{
                                        "id": "Setores",
                                        "data": valoresClientes
                                    }]
                                    enviaSetoresMaisAbriramChamadosRelatorioGeral(dispatch, dataLineChartSetor);
                                });
                            }
                        } else {
                            let SetoresMaisAbriramChamadosZerado = [
                                {
                                    "id": "Setores",
                                    "data": [
                                        {
                                            "x": "0",
                                            "y": 0
                                        },
                                    ]
                                },
                            ];
                            enviaSetoresMaisAbriramChamadosRelatorioGeral(dispatch, SetoresMaisAbriramChamadosZerado)
                        }
                        if (arrayChamadosComAtendentes.length != 0) {
                            let arrayDeKeyRepetidos = arrayChamadosComAtendentes.reduce(function (prev, cur) {
                                prev[cur] = (prev[cur] || 0) + 1;
                                return prev;
                            }, {});
                            let testeOrdenacao = ordenaObjeto(arrayDeKeyRepetidos);
                            for (let i = 0; i < testeOrdenacao.length; i++) {
                                pessoasComMaisAtendimentosPraBaixar.push({ atendentes: testeOrdenacao[i] })
                            }
                            if (testeOrdenacao.length >= 4) {
                                let arrayChamado = [];
                                let promisseSetor;
                                let labelClientes = [];
                                let valoresClientes = []
                                for (let i = 0; i < 4; i++) {
                                    let setor = testeOrdenacao[i].key;

                                    promisseSetor = firebase.database().ref(`${chaveUnica}/usuarios/${setor}/nome`).once('value')
                                    arrayChamado.push(promisseSetor);

                                }
                                Promise.all(arrayChamado).then(responses => {
                                    for (let i = 0; i < 4; i++) {
                                        valoresClientes.push({
                                            x: responses[i].val(),
                                            y: testeOrdenacao[i].value
                                        })
                                        /*  labelClientes.push(

                                             responses[i].val(),

                                         );
                                         valoresClientes.push(

                                             testeOrdenacao[i].value,

                                         ); */


                                    }
                                    let dataLineChartSetor = [{
                                        "id": "Atendentes",
                                        "data": valoresClientes
                                    }]
                                    enviaAtendentesMaisAbriramChamadosRelatorioGeral(dispatch, dataLineChartSetor);
                                });
                            }
                            //se não eu pego todo array, já que o mesmo será menos que 4
                            else {
                                let arrayChamado = [];
                                let promisseSetor;
                                let labelClientes = [];
                                let valoresClientes = []
                                for (let i = 0; i < testeOrdenacao.length; i++) {
                                    let setor = testeOrdenacao[i].key;

                                    promisseSetor = firebase.database().ref(`${chaveUnica}/usuarios/${setor}/nome`).once('value')
                                    arrayChamado.push(promisseSetor);

                                }

                                Promise.all(arrayChamado).then(responses => {
                                    for (let i = 0; i < testeOrdenacao.length; i++) {
                                        valoresClientes.push({
                                            x: responses[i].val(),
                                            y: testeOrdenacao[i].value
                                        })
                                        /*  labelClientes.push(

                                             responses[i].val(),

                                         );
                                         valoresClientes.push(

                                             testeOrdenacao[i].value,

                                         ); */


                                    }
                                    let dataLineChartSetor = [{
                                        "id": "Atendentes",
                                        "data": valoresClientes
                                    }]
                                    enviaAtendentesMaisAbriramChamadosRelatorioGeral(dispatch, dataLineChartSetor);
                                });
                            }
                        } else {
                            let SetoresMaisAbriramChamadosZerado = [
                                {
                                    "id": "Atendentes",
                                    "data": [
                                        {
                                            "x": "0",
                                            "y": 0
                                        },
                                    ]
                                },
                            ];
                            enviaAtendentesMaisAbriramChamadosRelatorioGeral(dispatch, SetoresMaisAbriramChamadosZerado)
                        }
                        if (arrayChamadosComProblemasEstaticos.length != 0) {
                            let arrayDeKeyRepetidos = arrayChamadosComProblemasEstaticos.reduce(function (prev, cur) {
                                prev[cur] = (prev[cur] || 0) + 1;
                                return prev;
                            }, {});

                            let testeOrdenacao = ordenaObjeto(arrayDeKeyRepetidos);
                            for (let i = 0; i < testeOrdenacao.length; i++) {
                                pessoasComMaisAtendimentosPraBaixar.push({ problemas: testeOrdenacao[i] })
                            }
                            //
                            //
                            //se for maior tenho que pegar somente as 4 primeiras casas do array
                            if (testeOrdenacao.length >= 4) {
                                let arrayChamado = [];
                                let promisseCliente;
                                let labelClientes = [];
                                let valoresClientes = []
                                /*   for (let i = 0; i < 4; i++) {
                                      let cliente = testeOrdenacao[i].key;

                                      promisseCliente = firebase.database().ref(`${chaveUnica}/clientes/${cliente}/nome`).once('value')
                                      arrayChamado.push(promisseCliente);

                                  } */

                                for (let i = 0; i < 4; i++) {
                                    valoresClientes.push({
                                        x: testeOrdenacao[i].key,
                                        y: testeOrdenacao[i].value
                                    })
                                    /*     labelClientes.push(testeOrdenacao[i].key);
                                        valoresClientes.push(testeOrdenacao[i].value); */
                                }
                                let dataLineChartCliente = [{
                                    "id": "Problemas",
                                    "data": valoresClientes
                                }]
                                enviaProblemasMaisAbriramChamadosRelatorioGeral(dispatch, dataLineChartCliente);

                            }
                            //se não eu pego todo array, já que o mesmo será menos que 4
                            else {
                                let arrayChamado = [];
                                let promisseCliente;
                                let labelClientes = [];
                                let valoresClientes = []


                                for (let i = 0; i < testeOrdenacao.length; i++) {
                                    valoresClientes.push({
                                        x: testeOrdenacao[i].key,
                                        y: testeOrdenacao[i].value
                                    })
                                    /* labelClientes.push(testeOrdenacao[i].key);
                                    valoresClientes.push(testeOrdenacao[i].value); */
                                }
                                let dataLineChartCliente = [{
                                    "id": "Problemas",
                                    "data": valoresClientes
                                }]
                                enviaProblemasMaisAbriramChamadosRelatorioGeral(dispatch, dataLineChartCliente);

                            }
                        } else {
                            //se não, não tem dados, então envia zerado
                            let clientesMaisAbriramChamadosZerado = [
                                {
                                    "id": "Clientes",
                                    "data": [
                                        {
                                            "x": "0",
                                            "y": 0
                                        },
                                    ]
                                },
                            ];
                            enviaProblemasMaisAbriramChamadosRelatorioGeral(dispatch, clientesMaisAbriramChamadosZerado);
                        }
                        if (tempoParaSolucaoAtendimentos.length !== 0) {
                            tempoMedioParaSolucaoAtendimentos = retornaMedia(tempoParaSolucaoAtendimentos);
                            let durationTempoMedio = moment.duration(tempoMedioParaSolucaoAtendimentos);
                            // console.log(durationTempoMedio.asHours())
                            if (durationTempoMedio.asHours() >= 24) {
                                let dataConvertidaDoTempoMedio = Math.floor(durationTempoMedio.asDays()) + "d" + moment.utc(tempoMedioParaSolucaoAtendimentos).format(" hh") + "h";
                                //
                                //
                                enviaTempoMedioDeSolucaoAtendimentosRelatorioGeral(dispatch, dataConvertidaDoTempoMedio)
                            } else {
                                let dataConvertidaDoTempoMedio = Math.floor(durationTempoMedio.asHours()) + "h" + moment.utc(tempoMedioParaSolucaoAtendimentos).format(" mm") + "m";
                                //
                                //
                                enviaTempoMedioDeSolucaoAtendimentosRelatorioGeral(dispatch, dataConvertidaDoTempoMedio)
                            }

                        } else {
                            //
                            enviaTempoMedioDeSolucaoAtendimentosRelatorioGeral(dispatch, '0h 00m')
                        }
                        if (tempoMedioPrimeiraRespospota.length > 0) {
                            tempoMedioPrimeiraRespospota = retornaMedia(tempoMedioPrimeiraRespospota);
                            const tempoPrimeiraRespostaFormatado = minutes_to_hhmm(tempoMedioPrimeiraRespospota);
                            enviaTempoMedioRespostaAtendimentos(dispatch, tempoPrimeiraRespostaFormatado)
                        } else {
                            enviaTempoMedioRespostaAtendimentos(dispatch, '0h 00m')
                        }
                        enviaPessoasComMaisAtendimentosParaDownload(dispatch, pessoasComMaisAtendimentosPraBaixar);
                        // ativaDesativaLoadingRelatorioGeral(dispatch, false);
                    } else {

                        //;
                        enviaPessoasComMaisAtendimentosParaDownload(dispatch, []);
                        let clientesMaisAbriramChamadosZerado = [
                            {
                                "id": "Clientes",
                                "data": [
                                    {
                                        "x": "0",
                                        "y": 0
                                    },
                                ]
                            },
                        ];
                        enviaClientesMaisAbriramChamadosRelatorioGeral(dispatch, clientesMaisAbriramChamadosZerado);

                        let atendentesMaisAbriramChamadosZerado = [
                            {
                                "id": "Atendentes",
                                "data": [
                                    {
                                        "x": "0",
                                        "y": 0
                                    },
                                ]
                            },
                        ];
                        enviaAtendentesMaisAbriramChamadosRelatorioGeral(dispatch, atendentesMaisAbriramChamadosZerado)

                        let SetoresMaisAbriramChamadosZerado = [
                            {
                                "id": "Setores",
                                "data": [
                                    {
                                        "x": "0",
                                        "y": 0
                                    },
                                ]
                            },
                        ];
                        enviaSetoresMaisAbriramChamadosRelatorioGeral(dispatch, SetoresMaisAbriramChamadosZerado)
                        let problemasMaisAbriramChamadosZerado = [
                            {
                                "id": "Problemas",
                                "data": [
                                    {
                                        "x": "0",
                                        "y": 0
                                    },
                                ]
                            },
                        ];
                        enviaProblemasMaisAbriramChamadosRelatorioGeral(dispatch, problemasMaisAbriramChamadosZerado);
                        enviaTempoMedioDeSolucaoAtendimentosRelatorioGeral(dispatch, '0h 00m');
                        enviaTempoMedioRespostaAtendimentos(dispatch, '0h 00m')
                        // ativaDesativaLoadingRelatorioGeral(dispatch, false);
                    }
                })
                /*  firebase.database().ref(`${chaveUnica}/avaliacoes`).orderByChild('dataHoraAvaliacaoTimeStamp')
                     .startAt(dataInicioConsulta).endAt(dataFinalConsulta).once("value").then((snapshot) => {
                         //
                         //
                         if (snapshot.val()) { */
                const options2 = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                        'Accept': 'application/json',
                    },

                    data: { chaveUnica: chaveUnica, dataInicioConsulta: dataInicioConsulta, dataFinalConsulta: dataFinalConsulta, rota: 'avaliacao' },
                    url: 'gerarRelatoriosAtendimentos',
                };
                api(options2).then((snapshot) => {


                    snapshot = snapshot.data;

                    if (snapshot !== null) {
                        //envia os valores para o grafico
                        let arrayOfKeys = Object.keys(snapshot).sort().reverse();
                        let results = arrayOfKeys.map((key) => snapshot[key]);
                        for (let i = 0; i < results.length; i++) {
                            //
                            totalAvaliacoes++;
                            if (results[i].valorAvaliacao == 1) {
                                atendimento1estrela++;
                            }
                            if (results[i].valorAvaliacao == 2) {
                                atendimento2estrela++;
                            }
                            if (results[i].valorAvaliacao == 3) {
                                atendimento3estrela++;
                            }
                            if (results[i].valorAvaliacao == 4) {
                                atendimento4estrela++;
                            }
                            if (results[i].valorAvaliacao == 5) {
                                atendimento5estrela++;
                            }
                        }
                        /*




                          */
                        let valor1estrelaParaPorcentagem = atendimento1estrela * 1;
                        let valor2estrelaParaPorcentagem = atendimento2estrela * 2;
                        let valor3estrelaParaPorcentagem = atendimento3estrela * 3;
                        let valor4estrelaParaPorcentagem = atendimento4estrela * 4;
                        let valor5estrelaParaPorcentagem = atendimento5estrela * 5;
                        /*



                         */
                        let notaMedia = (valor1estrelaParaPorcentagem + valor2estrelaParaPorcentagem + valor3estrelaParaPorcentagem + valor4estrelaParaPorcentagem + valor5estrelaParaPorcentagem) / totalAvaliacoes;
                        //  console.log('notaMedia: ' + notaMedia.toFixed(2).replace('.', ','));
                        enviaNotaMediaAvaliacaoRelatorioGeral(dispatch, notaMedia.toFixed(2).replace('.', ','));

                        ativaDesativaLoadingRelatorioGeral(dispatch, false);
                    } else {
                        //
                        enviaNotaMediaAvaliacaoRelatorioGeral(dispatch, 'Sem Avaliações');
                        ativaDesativaLoadingRelatorioGeral(dispatch, false);
                    }
                })

            }
        })

    }
}
export const listaAtendimentosPeriodoPersonalizado = (dataInicial2, dataFinal2, valorReferencia, filtro, keyFiltro) => {
    let dataInicial = moment(dataInicial2).format('DD/MM/YYYY')
    let dataFinal = moment(dataFinal2).format('DD/MM/YYYY')
    //
    let atendimentosAbertoHoje = 0;
    let atendimentosAbertoNaSemana = 0;
    let atendimentosAtendendoNaSemana = 0;
    let atendimentosFechadoNaSemana = 0;
    let atendimentosCanceladoNaSemana = 0;
    let atendimentosAbertoAtendendoPrioridadeBaixa = 0;
    let atendimentosAbertoAtendendoPrioridadeNormal = 0;
    let atendimentosAbertoAtendendoPrioridadeAlta = 0;
    let atendimentosAbertoAtendendoPrioridadeUrgente = 0;
    let tempoResolucaoChamadoMenorque1 = 0;
    let tempoResolucaoChamado1 = 0;
    let tempoResolucaoChamado2 = 0;
    let tempoResolucaoChamado3 = 0;
    let tempoResolucaoChamado4 = 0;
    let tempoResolucaoChamado5 = 0;
    let tempoResolucaoChamado6 = 0;
    let tempoResolucaoChamado7 = 0;
    let tempoResolucaoChamadoMaiorque7 = 0;
    let atendimentosPorLocalInterno = 0;
    let atendimentosPorLocalExterno = 0;
    let atendimentosPorLocal = [];
    let arrayChamadosComClientes = [];
    let arrayChamadosComSetores = [];
    let arrayChamadosPorStatus = [];
    let tempoMedioParaSolucaoAtendimentos = 0;
    let tempoMedioPrimeiraRespospota = [];
    let tempoParaSolucaoAtendimentos = [];
    let arrayComChamadosParaBaixar = [];

    let dataInicialVerificacao = moment(dataInicial).format('YYYY-MM-DD');
    let dataFinalVerificacao = moment(dataFinal).format('YYYY-MM-DD');
    /*

      */
    let verificaSeDataInicialMaiorQueDataFinal = moment(dataFinalVerificacao).isBefore(dataInicialVerificacao);
    return dispatch => {

        AsyncStorage.getItem('chaveUnica').then((chaveUnica2) => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            //
            if (dataInicial2 == '' && dataFinal2 == '' || dataInicial2 == null && dataFinal2 == null) {
                toastr.error('Error', 'Informe a DATA INICIAL e DATA FINAL do período de pesquisa.');
            } else if (dataInicial2 == '' || dataInicial2 == null) {
                toastr.error('Error', 'Informe a DATA INICIAL do período de pesquisa.');
            } else if (dataFinal2 == '' || dataFinal2 == null) {
                toastr.error('Error', 'Informe a DATA FINAL do período de pesquisa.');
            } else if (verificaSeDataInicialMaiorQueDataFinal == true) {
                toastr.error('Error', 'DATA INICIAL maior que DATA FINAL, por favor verifique.');
            } else {
                enviaSemConexaoInternetTelaRelatorioDetalhado(dispatch, false);
                ativaDesativaLoadingTelaRelatorioAtendimentoDetalhados(dispatch, true);
                enviaPeriodoPersonalizadoRelatorioDetalhado(dispatch, `${dataInicial} - ${dataFinal}`);
                /*  if (valorReferencia == 1) {
                     dispatch(NavigationActions.back());
                 } */
                dataInicial = dataInicial + ' 00:00:01';
                dataFinal = dataFinal + ' 23:59:59';
                let dataAtual = retornaDataAtual();
                let dataInicioConsulta, dataFinalConsulta;

                //verificacao para saber qual periodo é e passar o periodo correto para a query do firebase
                /*   */
                dataInicioConsulta = retornaDataTimeStamp(dataInicial);
                dataFinalConsulta = retornaDataTimeStamp(dataFinal);


                /* firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild('dataAberturaTimeStamp')
                    .startAt(dataInicioConsulta).endAt(dataFinalConsulta).once("value").then((snapshot) => {

                        if (snapshot.val()) { */
                const options = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                        'Accept': 'application/json',
                    },

                    data: {
                        chaveUnica: chaveUnica,
                        dataInicioConsulta: dataInicioConsulta,
                        dataFinalConsulta: dataFinalConsulta,
                        rota: 'atendimentodetalhado'
                    },
                    url: 'gerarRelatoriosAtendimentos',
                };
                api(options).then((snapshot) => {


                    snapshot = snapshot.data;

                    if (snapshot !== null) {
                        /*   */
                        let arrayOfKeys = Object.keys(snapshot).sort().reverse();
                        let results = arrayOfKeys.map((key) => snapshot[key]);
                        if (filtro == 'cliente') {
                            /*      */
                            for (let i = 0; i < results.length; i++) {
                                if (results[i].cliente == keyFiltro) {


                                    //por status
                                    if (results[i].status == 'Aberto') {
                                        arrayComChamadosParaBaixar.push({ status: results[i] })
                                        atendimentosAbertoNaSemana++
                                    }
                                    if (results[i].status == 'Atendendo') {
                                        arrayComChamadosParaBaixar.push({ status: results[i] })
                                        atendimentosAtendendoNaSemana++
                                    }
                                    if (results[i].status == 'Fechado') {
                                        arrayComChamadosParaBaixar.push({ status: results[i] })
                                        //
                                        atendimentosFechadoNaSemana++
                                        if (results[i].periodoResolucaoChamado == '<1') {
                                            arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                            tempoResolucaoChamadoMenorque1++
                                        }
                                        if (results[i].periodoResolucaoChamado == '1') {
                                            arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                            tempoResolucaoChamado1++
                                        }
                                        if (results[i].periodoResolucaoChamado == '2') {
                                            arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                            tempoResolucaoChamado2++
                                        }
                                        if (results[i].periodoResolucaoChamado == '3') {
                                            arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                            tempoResolucaoChamado3++
                                        }
                                        if (results[i].periodoResolucaoChamado == '4') {
                                            arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                            tempoResolucaoChamado4++
                                        }
                                        if (results[i].periodoResolucaoChamado == '5') {
                                            arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                            tempoResolucaoChamado5++
                                        }
                                        if (results[i].periodoResolucaoChamado == '6') {
                                            arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                            tempoResolucaoChamado6++
                                        }
                                        if (results[i].periodoResolucaoChamado == '7') {
                                            arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                            tempoResolucaoChamado7++
                                        }
                                        if (results[i].periodoResolucaoChamado == '>7') {
                                            arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                            tempoResolucaoChamadoMaiorque7++
                                        }
                                        if (results[i].dataHoraAbertura != undefined && results[i].dataHoraAbertura != '' &&
                                            results[i].dataHoraAbertura != null && results[i].dataHoraFechamento != undefined &&
                                            results[i].dataHoraFechamento != '' && results[i].dataHoraFechamento != null) {
                                            //
                                            //

                                            let dataSubtraida = moment(results[i].dataHoraFechamento, 'DD/MM/YYYY HH:mm').diff(moment(results[i].dataHoraAbertura, 'DD/MM/YYYY HH:mm'))
                                            //
                                            tempoParaSolucaoAtendimentos.push(dataSubtraida);
                                            tempoMedioPrimeiraRespospota.push(results[i].tempoPrimeiraResposta == undefined ? 0 : results[i].tempoPrimeiraResposta);
                                        }
                                    }
                                    if (results[i].status == 'Cancelado') {
                                        arrayComChamadosParaBaixar.push({ status: results[i] })
                                        atendimentosCanceladoNaSemana++
                                    }
                                    //por prioridade
                                    if (results[i].prioridade == 'Baixa') {
                                        arrayComChamadosParaBaixar.push({ prioridade: results[i] })
                                        atendimentosAbertoAtendendoPrioridadeBaixa++;
                                    }
                                    if (results[i].prioridade == 'Normal') {
                                        arrayComChamadosParaBaixar.push({ prioridade: results[i] })
                                        atendimentosAbertoAtendendoPrioridadeNormal++;
                                    }
                                    if (results[i].prioridade == 'Alta') {
                                        arrayComChamadosParaBaixar.push({ prioridade: results[i] })
                                        atendimentosAbertoAtendendoPrioridadeAlta++;
                                    }
                                    if (results[i].prioridade == 'Urgente') {
                                        arrayComChamadosParaBaixar.push({ prioridade: results[i] })
                                        atendimentosAbertoAtendendoPrioridadeUrgente++;
                                    }

                                    if (results[i].local == 'Interno') {
                                        arrayComChamadosParaBaixar.push({ local: results[i] })
                                        atendimentosPorLocalInterno++
                                    }
                                    if (results[i].local == 'Externo') {
                                        arrayComChamadosParaBaixar.push({ local: results[i] })
                                        atendimentosPorLocalExterno++
                                    }
                                }
                            }
                        }
                        if (filtro == 'atendente') {
                            for (let i = 0; i < results.length; i++) {
                                if (results[i].atendente == keyFiltro) {


                                    //por status
                                    if (results[i].status == 'Aberto') {
                                        arrayComChamadosParaBaixar.push({ status: results[i] })
                                        atendimentosAbertoNaSemana++
                                    }
                                    if (results[i].status == 'Atendendo') {
                                        arrayComChamadosParaBaixar.push({ status: results[i] })
                                        atendimentosAtendendoNaSemana++
                                    }
                                    if (results[i].status == 'Fechado') {
                                        arrayComChamadosParaBaixar.push({ status: results[i] })
                                        //
                                        atendimentosFechadoNaSemana++
                                        if (results[i].periodoResolucaoChamado == '<1') {
                                            arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                            tempoResolucaoChamadoMenorque1++
                                        }
                                        if (results[i].periodoResolucaoChamado == '1') {
                                            arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                            tempoResolucaoChamado1++
                                        }
                                        if (results[i].periodoResolucaoChamado == '2') {
                                            arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                            tempoResolucaoChamado2++
                                        }
                                        if (results[i].periodoResolucaoChamado == '3') {
                                            arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                            tempoResolucaoChamado3++
                                        }
                                        if (results[i].periodoResolucaoChamado == '4') {
                                            arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                            tempoResolucaoChamado4++
                                        }
                                        if (results[i].periodoResolucaoChamado == '5') {
                                            arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                            tempoResolucaoChamado5++
                                        }
                                        if (results[i].periodoResolucaoChamado == '6') {
                                            arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                            tempoResolucaoChamado6++
                                        }
                                        if (results[i].periodoResolucaoChamado == '7') {
                                            arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                            tempoResolucaoChamado7++
                                        }
                                        if (results[i].periodoResolucaoChamado == '>7') {
                                            arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                            tempoResolucaoChamadoMaiorque7++
                                        }
                                        if (results[i].dataHoraAbertura != undefined && results[i].dataHoraAbertura != '' &&
                                            results[i].dataHoraAbertura != null && results[i].dataHoraFechamento != undefined &&
                                            results[i].dataHoraFechamento != '' && results[i].dataHoraFechamento != null) {
                                            //
                                            //

                                            let dataSubtraida = moment(results[i].dataHoraFechamento, 'DD/MM/YYYY HH:mm').diff(moment(results[i].dataHoraAbertura, 'DD/MM/YYYY HH:mm'))
                                            //
                                            tempoParaSolucaoAtendimentos.push(dataSubtraida);
                                            tempoMedioPrimeiraRespospota.push(results[i].tempoPrimeiraResposta == undefined ? 0 : results[i].tempoPrimeiraResposta);
                                        }
                                    }
                                    if (results[i].status == 'Cancelado') {
                                        arrayComChamadosParaBaixar.push({ status: results[i] })
                                        atendimentosCanceladoNaSemana++
                                    }
                                    //por prioridade
                                    if (results[i].prioridade == 'Baixa') {
                                        arrayComChamadosParaBaixar.push({ prioridade: results[i] })
                                        atendimentosAbertoAtendendoPrioridadeBaixa++;
                                    }
                                    if (results[i].prioridade == 'Normal') {
                                        arrayComChamadosParaBaixar.push({ prioridade: results[i] })
                                        atendimentosAbertoAtendendoPrioridadeNormal++;
                                    }
                                    if (results[i].prioridade == 'Alta') {
                                        arrayComChamadosParaBaixar.push({ prioridade: results[i] })
                                        atendimentosAbertoAtendendoPrioridadeAlta++;
                                    }
                                    if (results[i].prioridade == 'Urgente') {
                                        arrayComChamadosParaBaixar.push({ prioridade: results[i] })
                                        atendimentosAbertoAtendendoPrioridadeUrgente++;
                                    }

                                    if (results[i].local == 'Interno') {
                                        arrayComChamadosParaBaixar.push({ local: results[i] })
                                        atendimentosPorLocalInterno++
                                    }
                                    if (results[i].local == 'Externo') {
                                        arrayComChamadosParaBaixar.push({ local: results[i] })
                                        atendimentosPorLocalExterno++
                                    }
                                }
                            }
                        }
                        if (filtro == 'setor') {
                            for (let i = 0; i < results.length; i++) {
                                if (results[i].setor == keyFiltro) {


                                    //por status
                                    if (results[i].status == 'Aberto') {
                                        arrayComChamadosParaBaixar.push({ status: results[i] })
                                        atendimentosAbertoNaSemana++
                                    }
                                    if (results[i].status == 'Atendendo') {
                                        arrayComChamadosParaBaixar.push({ status: results[i] })
                                        atendimentosAtendendoNaSemana++
                                    }
                                    if (results[i].status == 'Fechado') {
                                        arrayComChamadosParaBaixar.push({ status: results[i] })
                                        //
                                        atendimentosFechadoNaSemana++
                                        if (results[i].periodoResolucaoChamado == '<1') {
                                            arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                            tempoResolucaoChamadoMenorque1++
                                        }
                                        if (results[i].periodoResolucaoChamado == '1') {
                                            arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                            tempoResolucaoChamado1++
                                        }
                                        if (results[i].periodoResolucaoChamado == '2') {
                                            arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                            tempoResolucaoChamado2++
                                        }
                                        if (results[i].periodoResolucaoChamado == '3') {
                                            arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                            tempoResolucaoChamado3++
                                        }
                                        if (results[i].periodoResolucaoChamado == '4') {
                                            arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                            tempoResolucaoChamado4++
                                        }
                                        if (results[i].periodoResolucaoChamado == '5') {
                                            arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                            tempoResolucaoChamado5++
                                        }
                                        if (results[i].periodoResolucaoChamado == '6') {
                                            arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                            tempoResolucaoChamado6++
                                        }
                                        if (results[i].periodoResolucaoChamado == '7') {
                                            arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                            tempoResolucaoChamado7++
                                        }
                                        if (results[i].periodoResolucaoChamado == '>7') {
                                            arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                            tempoResolucaoChamadoMaiorque7++
                                        }
                                        if (results[i].dataHoraAbertura != undefined && results[i].dataHoraAbertura != '' &&
                                            results[i].dataHoraAbertura != null && results[i].dataHoraFechamento != undefined &&
                                            results[i].dataHoraFechamento != '' && results[i].dataHoraFechamento != null) {
                                            //
                                            //

                                            let dataSubtraida = moment(results[i].dataHoraFechamento, 'DD/MM/YYYY HH:mm').diff(moment(results[i].dataHoraAbertura, 'DD/MM/YYYY HH:mm'))
                                            //;
                                            tempoParaSolucaoAtendimentos.push(dataSubtraida);
                                            tempoMedioPrimeiraRespospota.push(results[i].tempoPrimeiraResposta == undefined ? 0 : results[i].tempoPrimeiraResposta);
                                        }
                                    }
                                    if (results[i].status == 'Cancelado') {
                                        arrayComChamadosParaBaixar.push({ status: results[i] })
                                        atendimentosCanceladoNaSemana++
                                    }
                                    //por prioridade
                                    if (results[i].prioridade == 'Baixa') {
                                        arrayComChamadosParaBaixar.push({ prioridade: results[i] })
                                        atendimentosAbertoAtendendoPrioridadeBaixa++;
                                    }
                                    if (results[i].prioridade == 'Normal') {
                                        arrayComChamadosParaBaixar.push({ prioridade: results[i] })
                                        atendimentosAbertoAtendendoPrioridadeNormal++;
                                    }
                                    if (results[i].prioridade == 'Alta') {
                                        arrayComChamadosParaBaixar.push({ prioridade: results[i] })
                                        atendimentosAbertoAtendendoPrioridadeAlta++;
                                    }
                                    if (results[i].prioridade == 'Urgente') {
                                        arrayComChamadosParaBaixar.push({ prioridade: results[i] })
                                        atendimentosAbertoAtendendoPrioridadeUrgente++;
                                    }

                                    if (results[i].local == 'Interno') {
                                        arrayComChamadosParaBaixar.push({ local: results[i] })
                                        atendimentosPorLocalInterno++
                                    }
                                    if (results[i].local == 'Externo') {
                                        arrayComChamadosParaBaixar.push({ local: results[i] })
                                        atendimentosPorLocalExterno++
                                    }
                                }
                            }
                        }
                        if (filtro == 'padrao') {
                            for (let i = 0; i < results.length; i++) {
                                //por status
                                if (results[i].status == 'Aberto') {
                                    arrayComChamadosParaBaixar.push({ status: results[i] })
                                    atendimentosAbertoNaSemana++
                                }
                                if (results[i].status == 'Atendendo') {
                                    arrayComChamadosParaBaixar.push({ status: results[i] })
                                    atendimentosAtendendoNaSemana++
                                }
                                if (results[i].status == 'Fechado') {
                                    arrayComChamadosParaBaixar.push({ status: results[i] })
                                    //;
                                    atendimentosFechadoNaSemana++
                                    if (results[i].periodoResolucaoChamado == '<1') {
                                        arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                        tempoResolucaoChamadoMenorque1++
                                    }
                                    if (results[i].periodoResolucaoChamado == '1') {
                                        arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                        tempoResolucaoChamado1++
                                    }
                                    if (results[i].periodoResolucaoChamado == '2') {
                                        arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                        tempoResolucaoChamado2++
                                    }
                                    if (results[i].periodoResolucaoChamado == '3') {
                                        arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                        tempoResolucaoChamado3++
                                    }
                                    if (results[i].periodoResolucaoChamado == '4') {
                                        arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                        tempoResolucaoChamado4++
                                    }
                                    if (results[i].periodoResolucaoChamado == '5') {
                                        arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                        tempoResolucaoChamado5++
                                    }
                                    if (results[i].periodoResolucaoChamado == '6') {
                                        arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                        tempoResolucaoChamado6++
                                    }
                                    if (results[i].periodoResolucaoChamado == '7') {
                                        arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                        tempoResolucaoChamado7++
                                    }
                                    if (results[i].periodoResolucaoChamado == '>7') {
                                        arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                        tempoResolucaoChamadoMaiorque7++
                                    }
                                    if (results[i].dataHoraAbertura != undefined && results[i].dataHoraAbertura != '' &&
                                        results[i].dataHoraAbertura != null && results[i].dataHoraFechamento != undefined &&
                                        results[i].dataHoraFechamento != '' && results[i].dataHoraFechamento != null) {
                                        //
                                        //

                                        let dataSubtraida = moment(results[i].dataHoraFechamento, 'DD/MM/YYYY HH:mm').diff(moment(results[i].dataHoraAbertura, 'DD/MM/YYYY HH:mm'))
                                        //
                                        tempoParaSolucaoAtendimentos.push(dataSubtraida);
                                        tempoMedioPrimeiraRespospota.push(results[i].tempoPrimeiraResposta == undefined ? 0 : results[i].tempoPrimeiraResposta);
                                    }
                                }
                                if (results[i].status == 'Cancelado') {
                                    arrayComChamadosParaBaixar.push({ status: results[i] })
                                    atendimentosCanceladoNaSemana++
                                }
                                //por prioridade
                                if (results[i].prioridade == 'Baixa') {
                                    arrayComChamadosParaBaixar.push({ prioridade: results[i] })
                                    atendimentosAbertoAtendendoPrioridadeBaixa++;
                                }
                                if (results[i].prioridade == 'Normal') {
                                    arrayComChamadosParaBaixar.push({ prioridade: results[i] })
                                    atendimentosAbertoAtendendoPrioridadeNormal++;
                                }
                                if (results[i].prioridade == 'Alta') {
                                    arrayComChamadosParaBaixar.push({ prioridade: results[i] })
                                    atendimentosAbertoAtendendoPrioridadeAlta++;
                                }
                                if (results[i].prioridade == 'Urgente') {
                                    arrayComChamadosParaBaixar.push({ prioridade: results[i] })
                                    atendimentosAbertoAtendendoPrioridadeUrgente++;
                                }

                                if (results[i].local == 'Interno') {
                                    arrayComChamadosParaBaixar.push({ local: results[i] })
                                    atendimentosPorLocalInterno++
                                }
                                if (results[i].local == 'Externo') {
                                    arrayComChamadosParaBaixar.push({ local: results[i] })
                                    atendimentosPorLocalExterno++
                                }

                            }
                        }
                        //implemento array para enviar os chamados por status
                        //implemento array para enviar os chamados por status
                        if (atendimentosAbertoNaSemana == 0) {
                            atendimentosAbertoNaSemana = 0.5;
                        }

                        if (atendimentosAtendendoNaSemana == 0) {
                            atendimentosAtendendoNaSemana = 0.5;
                        }
                        if (atendimentosFechadoNaSemana == 0) {
                            atendimentosFechadoNaSemana = 0.5;
                        }
                        if (atendimentosCanceladoNaSemana == 0) {
                            atendimentosCanceladoNaSemana = 0.5;

                        }
                        /*     */
                        let atendimentosPorStatusEnviaDashBoard = [
                            { value: atendimentosAbertoNaSemana, label: 'Aberto', id: "Aberto", color: "hsl(195, 100%, 50%)", valorZerado: "0" },
                            { value: atendimentosAtendendoNaSemana, label: 'Atendendo', id: "Atendendo", color: "hsl(42, 94%, 60%)", valorZerado: "0" },
                            { value: atendimentosFechadoNaSemana, label: 'Fechado', id: "Fechado", color: "hsl(150, 100%, 50%)", valorZerado: "0" },
                            {
                                value: atendimentosCanceladoNaSemana,
                                label: 'Cancelado',
                                id: "Cancelado",
                                color: "hsl(344, 94%, 51%)",
                                valorZerado: "0"
                            }];
                        enviaAtendimentosPorStatusRelatorioDetalhado(dispatch, atendimentosPorStatusEnviaDashBoard);
                        /*   let atendimentosPorStatusEnviaDashBoard = {
                              dataSets: [{
                                  values: arrayChamadosPorStatus,
                                  label: '',
                                  config: {
                                      colors: [processColor('#00BFFF'), processColor('#F9BF3B'), processColor('#00FF7F'), processColor('red')],
                                      valueTextSize: 18,
                                      valueTextColor: processColor('#000'),
                                      sliceSpace: 5,
                                      selectionShift: 13,
                                      // xValuePosition: "OUTSIDE_SLICE",
                                      // yValuePosition: "OUTSIDE_SLICE",
                                      // valueFormatter: "#.#'%'",
                                      valueFormatter: "#",
                                      valueLineColor: processColor('#000'),
                                      valueLinePart1Length: 0.5
                                  }
                              }],
                          }
                          enviaAtendimentosPorStatusRelatorioDetalhado(dispatch, atendimentosPorStatusEnviaDashBoard); */

                        /*       let TotalAtendimentosPorStatus = atendimentosAbertoNaSemana +
                                  atendimentosAtendendoNaSemana + atendimentosFechadoNaSemana +
                                  atendimentosCanceladoNaSemana;
                              enviaTotalAtendimentosPorStatusRelatorioDetalhado(dispatch, TotalAtendimentosPorStatus) */


                        //
                        //envio chamados por prioridades
                        /*        let totalAtendimentosAbertoAtendendoPorPrioridade = atendimentosAbertoAtendendoPrioridadeBaixa +
                                   atendimentosAbertoAtendendoPrioridadeNormal + atendimentosAbertoAtendendoPrioridadeAlta +
                                   atendimentosAbertoAtendendoPrioridadeUrgente;
                               enviaTotalAtendimentosPorPRioridadeRelatorioDetalhado(dispatch, totalAtendimentosAbertoAtendendoPorPrioridade) */
                        if (atendimentosAbertoAtendendoPrioridadeBaixa == 0) {
                            atendimentosAbertoAtendendoPrioridadeBaixa = 0.5
                        }
                        if (atendimentosAbertoAtendendoPrioridadeNormal == 0) {
                            atendimentosAbertoAtendendoPrioridadeNormal = 0.5
                        }
                        if (atendimentosAbertoAtendendoPrioridadeAlta == 0) {
                            atendimentosAbertoAtendendoPrioridadeAlta = 0.5
                        }
                        if (atendimentosAbertoAtendendoPrioridadeUrgente == 0) {
                            atendimentosAbertoAtendendoPrioridadeUrgente = 0.5
                        }
                        let atendimentosPorPrioridadeEnviaDashBoard = [
                            {
                                value: atendimentosAbertoAtendendoPrioridadeBaixa,
                                label: 'Baixa',
                                id: "Baixa",
                                color: "hsl(195, 100%, 50%)",
                                valorZerado: "0"
                            },
                            {
                                value: atendimentosAbertoAtendendoPrioridadeNormal,
                                label: 'Normal',
                                id: "Normal",
                                color: "hsl(0, 0%, 83%)",
                                valorZerado: "0"
                            },
                            {
                                value: atendimentosAbertoAtendendoPrioridadeAlta,
                                label: 'Alta',
                                id: "Alta",
                                color: "hsl(42, 94%, 60%)",
                                valorZerado: "0"
                            },
                            {
                                value: atendimentosAbertoAtendendoPrioridadeUrgente,
                                label: 'Urgente',
                                id: "Urgente",
                                color: "hsl(344, 94%, 51%)",
                                valorZerado: "0"
                            }];
                        enviaAtendimentosPorPrioridadeRelatorioDetalhado(dispatch, atendimentosPorPrioridadeEnviaDashBoard)
                        /*     let atendimentosPorPrioridadeEnviaDashBoard = {
                                dataSets: [{
                                    values: [
                                        { value: atendimentosAbertoAtendendoPrioridadeBaixa, label: 'Baixa' },
                                        { value: atendimentosAbertoAtendendoPrioridadeNormal, label: 'Normal' },
                                        { value: atendimentosAbertoAtendendoPrioridadeAlta, label: 'Alta' },
                                        { value: atendimentosAbertoAtendendoPrioridadeUrgente, label: 'Urgente' }],
                                    label: '',
                                    config: {
                                        colors: [processColor('#00BFFF'), processColor('#B0C4DE'), processColor('#F9BF3B'), processColor('#f70e4c')],
                                        valueTextSize: 18,
                                        valueTextColor: processColor('#000'),
                                        sliceSpace: 5,
                                        selectionShift: 13,
                                        // xValuePosition: "OUTSIDE_SLICE",
                                        // yValuePosition: "OUTSIDE_SLICE",
                                        // valueFormatter: "#.#'%'",
                                        valueFormatter: "#",
                                        valueLineColor: processColor('#000'),
                                        valueLinePart1Length: 0.5
                                    }
                                }],
                            }
                            enviaAtendimentosPorPrioridadeRelatorioDetalhado(dispatch, atendimentosPorPrioridadeEnviaDashBoard) */
                        //
                        if (tempoResolucaoChamadoMenorque1 == 0) {
                            tempoResolucaoChamadoMenorque1 = 0.5
                        }
                        if (tempoResolucaoChamado1 == 0) {
                            tempoResolucaoChamado1 = 0.5
                        }
                        if (tempoResolucaoChamado2 == 0) {
                            tempoResolucaoChamado2 = 0.5
                        }
                        if (tempoResolucaoChamado3 == 0) {
                            tempoResolucaoChamado3 = 0.5
                        }
                        if (tempoResolucaoChamado4 == 0) {
                            tempoResolucaoChamado4 = 0.5
                        }
                        if (tempoResolucaoChamado5 == 0) {
                            tempoResolucaoChamado5 = 0.5
                        }
                        if (tempoResolucaoChamado6 == 0) {
                            tempoResolucaoChamado6 = 0.5
                        }
                        if (tempoResolucaoChamado7 == 0) {
                            tempoResolucaoChamado7 = 0.5
                        }
                        if (tempoResolucaoChamadoMaiorque7 == 0) {
                            tempoResolucaoChamadoMaiorque7 = 0.5
                        }
                        let atendimentosPorTempOResolucao = [
                            { value: tempoResolucaoChamadoMenorque1, label: '< 1 dia', id: "< 1 dia", color: "hsl(150, 100%, 50%)", valorZerado: "0" },
                            { value: tempoResolucaoChamado1, label: '1 dia', id: "1 dia", color: "hsl(0, 0%, 83%)", valorZerado: "0" },
                            { value: tempoResolucaoChamado2, label: '2 dias', id: "2 dias", color: "hsl(42, 94%, 60%)", valorZerado: "0" },
                            { value: tempoResolucaoChamado3, label: '3 dias', id: "3 dias", color: "hsl(344, 94%, 51%)", valorZerado: "0" },
                            { value: tempoResolucaoChamado4, label: '4 dias', id: "4 dias", color: "hsl(214, 41%, 78%)", valorZerado: "0" },
                            { value: tempoResolucaoChamado5, label: '5 dias', id: "5 dias", color: "hsl(195, 100%, 50%)", valorZerado: "0" },
                            { value: tempoResolucaoChamado6, label: '6 dias', id: "6 dias", color: "hsl(180, 100%, 50%)", valorZerado: "0" },
                            { value: tempoResolucaoChamado7, label: '7 dias', id: "7 dias", color: "hsl(25, 75%, 47%)", valorZerado: "0" },
                            {
                                value: tempoResolucaoChamadoMaiorque7,
                                label: '> 7 dias',
                                id: "> 7 dias",
                                color: "hsl(180, 100%, 27%)",
                                valorZerado: "0"
                            }];
                        enviaTempoResolucaoChamadosRelatorioDetalhado(dispatch, atendimentosPorTempOResolucao);
                        /* let atendimentosPorTempOResolucao = {
                            dataSets: [{
                                values: [
                                    { value: tempoResolucaoChamadoMenorque1, label: '< 1 dia' },
                                    { value: tempoResolucaoChamado1, label: '1 dia' },
                                    { value: tempoResolucaoChamado2, label: '2 dias' },
                                    { value: tempoResolucaoChamado3, label: '3 dias' },
                                    { value: tempoResolucaoChamado4, label: '4 dias' },
                                    { value: tempoResolucaoChamado5, label: '5 dias' },
                                    { value: tempoResolucaoChamado6, label: '6 dias' },
                                    { value: tempoResolucaoChamado7, label: '7 dias' },
                                    { value: tempoResolucaoChamadoMaiorque7, label: '> 7 dias' }],
                                label: '',
                                config: {
                                    colors: [processColor('#00FF7F'), processColor('#D3D3D3'), processColor('#F9BF3B'), processColor('#f70e4c'), processColor('#B0C4DE'),
                                    processColor('#00BFFF'), processColor('#00FFFF'), processColor('#D2691E'), processColor('#008B8B')],
                                    valueTextSize: 18,
                                    valueTextColor: processColor('#000'),
                                    sliceSpace: 5,
                                    selectionShift: 13,
                                    // xValuePosition: "OUTSIDE_SLICE",
                                    // yValuePosition: "OUTSIDE_SLICE",
                                    // valueFormatter: "#.#'%'",
                                    valueFormatter: "#",
                                    valueLineColor: processColor('#000'),
                                    valueLinePart1Length: 0.5
                                }
                            }],
                        }
                        enviaTempoResolucaoChamadosRelatorioDetalhado(dispatch, atendimentosPorTempOResolucao);
                        enviaTotalAtendimentosPeriodoResolucaoRelatorioDetalhado(dispatch, atendimentosFechadoNaSemana); */
                        //
                        if (atendimentosPorLocalInterno == 0) {
                            atendimentosPorLocal.push({
                                value: 0.5, label: 'Interno', id: "Interno", color: "hsl(195, 100%, 50%)", valorZerado: "0"
                            })

                        } else {
                            atendimentosPorLocal.push({
                                value: atendimentosPorLocalInterno, label: 'Interno', id: "Interno", color: "hsl(195, 100%, 50%)", valorZerado: "0"
                            })
                        }
                        if (atendimentosPorLocalExterno == 0) {
                            atendimentosPorLocal.push({
                                value: 0.5, label: 'Externo', id: "Externo", color: "hsl(214, 41%, 78%)", valorZerado: "0"
                            })

                        } else {
                            atendimentosPorLocal.push({
                                value: atendimentosPorLocalExterno, label: 'Externo', id: "Externo", color: "hsl(214, 41%, 78%)", valorZerado: "0"
                            })
                        }

                        enviaAtendimentosPorLocalRelatorioDetalhado(dispatch, atendimentosPorLocal)
                        //atendimentos interno e externo
                        /*     if (atendimentosPorLocalInterno == 0) {
                                atendimentosPorLocal.push({
                                    value: 0.5, label: 'Interno'
                                })

                            } else {
                                atendimentosPorLocal.push({
                                    value: atendimentosPorLocalInterno, label: 'Interno'
                                })
                            }
                            if (atendimentosPorLocalExterno == 0) {
                                atendimentosPorLocal.push({
                                    value: 0.5, label: 'Externo'
                                })

                            } else {
                                atendimentosPorLocal.push({
                                    value: atendimentosPorLocalExterno, label: 'Externo'
                                })
                            }

                            let atendimentosPorLocalArray = {
                                dataSets: [{
                                    values: atendimentosPorLocal,
                                    label: '',
                                    config: {
                                        colors: [processColor('#00BFFF'), processColor('#B0C4DE')],
                                        valueTextSize: 18,
                                        valueTextColor: processColor('#000'),
                                        sliceSpace: 5,
                                        selectionShift: 13,
                                        // xValuePosition: "OUTSIDE_SLICE",
                                        // yValuePosition: "OUTSIDE_SLICE",
                                        // valueFormatter: "#.#'%'",
                                        valueFormatter: "#",
                                        valueLineColor: processColor('#000'),
                                        valueLinePart1Length: 0.5
                                    }
                                }],
                            }
                            let totalAtendimentosPorLocal = atendimentosPorLocalInterno + atendimentosPorLocalExterno;
                            enviaTotalAtendimentosPorLocalRelatorioDetalhado(dispatch, totalAtendimentosPorLocal)
                            enviaAtendimentosPorLocalRelatorioDetalhado(dispatch, atendimentosPorLocalArray) */
                        //
                        //envia tempo medio para solucao
                        //;
                        if (tempoParaSolucaoAtendimentos.length !== 0) {
                            tempoMedioParaSolucaoAtendimentos = retornaMedia(tempoParaSolucaoAtendimentos);
                            let durationTempoMedio = moment.duration(tempoMedioParaSolucaoAtendimentos);
                            //  console.log(durationTempoMedio.asHours())
                            if (durationTempoMedio.asHours() >= 24) {
                                let dataConvertidaDoTempoMedio = Math.floor(durationTempoMedio.asDays()) + "d" + moment.utc(tempoMedioParaSolucaoAtendimentos).format(" hh") + "h";
                                //
                                //
                                enviaTempoMedioDeSolucaoAtendimentos(dispatch, dataConvertidaDoTempoMedio)
                            } else {
                                let dataConvertidaDoTempoMedio = Math.floor(durationTempoMedio.asHours()) + "h" + moment.utc(tempoMedioParaSolucaoAtendimentos).format(" mm") + "m";
                                //
                                //
                                enviaTempoMedioDeSolucaoAtendimentos(dispatch, dataConvertidaDoTempoMedio)
                            }

                        } else {
                            //
                            enviaTempoMedioDeSolucaoAtendimentos(dispatch, '0h 00m')
                        }
                        if (tempoMedioPrimeiraRespospota.length > 0) {
                            tempoMedioPrimeiraRespospota = retornaMedia(tempoMedioPrimeiraRespospota);
                            const tempoPrimeiraRespostaFormatado = minutes_to_hhmm(tempoMedioPrimeiraRespospota);
                            enviaTempoMedioRespostaAtendimentos(dispatch, tempoPrimeiraRespostaFormatado)
                        } else {
                            enviaTempoMedioRespostaAtendimentos(dispatch, '0h 00m')
                        }
                        enviaChamadosDetalhadosParaDownload(dispatch, arrayComChamadosParaBaixar);
                        ativaDesativaLoadingTelaRelatorioAtendimentoDetalhados(dispatch, false);
                        // ativaDesativaLoadingRefreshControol(dispatch, false);
                    } else {
                        //
                        //sem informacoes
                        let atendimentosPorStatusZerado = [
                            { value: 0.5, label: 'Aberto', id: "Aberto", color: "hsl(195, 100%, 50%)", valorZerado: "0" },
                            { value: 0.5, label: 'Atendendo', id: "Atendendo", color: "hsl(42, 94%, 60%)", valorZerado: "0" },
                            { value: 0.5, label: 'Fechado', id: "Fechado", color: "hsl(150, 100%, 50%)", valorZerado: "0" },
                            { value: 0.5, label: 'Cancelado', id: "Cancelado", color: "hsl(344, 94%, 51%)", valorZerado: "0" }];
                        enviaAtendimentosPorStatusRelatorioDetalhado(dispatch, atendimentosPorStatusZerado);
                        let atendimentoPorPrioridadeZerado = [
                            { value: 0.5, label: 'Baixa', id: "Baixa", color: "hsl(195, 100%, 50%)", valorZerado: "0" },
                            { value: 0.5, label: 'Normal', id: "Normal", color: "hsl(0, 0%, 83%)", valorZerado: "0" },
                            { value: 0.5, label: 'Alta', id: "Alta", color: "hsl(42, 94%, 60%)", valorZerado: "0" },
                            { value: 0.5, label: 'Urgente', id: "Urgente", color: "hsl(344, 94%, 51%)", valorZerado: "0" }
                        ]


                        enviaAtendimentosPorPrioridadeRelatorioDetalhado(dispatch, atendimentoPorPrioridadeZerado);
                        //  enviaTotalAtendimentosPorPRioridade(dispatch, 0)
                        let atendimentoPorResolucaoZerado = [
                            { value: 0.5, label: '< 1 dia', id: "< 1 dia", color: "hsl(150, 100%, 50%)", valorZerado: "0" },
                            { value: 0.5, label: '1 dia', id: "1 dia", color: "hsl(0, 0%, 83%)", valorZerado: "0" },
                            { value: 0.5, label: '2 dias', id: "2 dias", color: "hsl(42, 94%, 60%)", valorZerado: "0" },
                            { value: 0.5, label: '3 dias', id: "3 dias", color: "hsl(344, 94%, 51%)", valorZerado: "0" },
                            { value: 0.5, label: '4 dias', id: "4 dias", color: "hsl(214, 41%, 78%)", valorZerado: "0" },
                            { value: 0.5, label: '5 dias', id: "5 dias", color: "hsl(195, 100%, 50%)", valorZerado: "0" },
                            { value: 0.5, label: '6 dias', id: "6 dias", color: "hsl(180, 100%, 50%)", valorZerado: "0" },
                            { value: 0.5, label: '7 dias', id: "7 dias", color: "hsl(25, 75%, 47%)", valorZerado: "0" },
                            { value: 0.5, label: '> 7 dias', id: "> 7 dias", color: "hsl(180, 100%, 27%)", valorZerado: "0" }]
                        enviaTempoResolucaoChamadosRelatorioDetalhado(dispatch, atendimentoPorResolucaoZerado);
                        // enviaTotalAtendimentosPeriodoResolucao(dispatch, 0);

                        let atendimentoPorLocalZerado = [
                            { value: 0.5, label: 'Interno', id: "Interno", color: "hsl(195, 100%, 50%)", valorZerado: "0" },
                            { value: 0.5, label: 'Externo', id: "Externo", color: "hsl(214, 41%, 78%)", valorZerado: "0" }
                        ];
                        // enviaTotalAtendimentosPorLocalDashboard(dispatch, 0)
                        enviaAtendimentosPorLocalRelatorioDetalhado(dispatch, atendimentoPorLocalZerado)

                        //envia tempo medio zerado
                        enviaTempoMedioDeSolucaoAtendimentos(dispatch, '0h 00m')
                        enviaTempoMedioRespostaAtendimentos(dispatch, '0h 00m')
                        enviaChamadosDetalhadosParaDownload(dispatch, []);
                        ativaDesativaLoadingTelaRelatorioAtendimentoDetalhados(dispatch, false);
                        //
                    }
                })
            }

        })

    }
}
export const novalistaAtendimentosZeradoParaRelatorioDetalhado = (periodoEstatico, valorReferencia) => {

    //
    //
    //nome dos meses pt br
    moment.locale('pt-br', {
        months: 'Janeiro_Fevereiro_Março_Abril_Maio_Junho_Julho_Agosto_Setembro_Outubro_Novembro_Dezembro'.split('_')
    })
    //HOJE
    let dataHoje = moment().toDate();
    //
    dataHoje = moment(dataHoje).format('DD/MM/YYYY');

    //ONTEM
    let datadeOntem = moment().subtract(1, 'days').toDate(); // pego data atual
    datadeOntem = moment(datadeOntem).format('DD/MM/YYYY');

    //SEMANA ATUAL
    const comecoDaSemana = moment().startOf('week').toDate();
    const fimDaSemana = moment().endOf('week').toDate();
    let dataComecoSemana = moment(comecoDaSemana).format('DD/MM/YYYY');
    let dataFimSemana = moment(fimDaSemana).format('DD/MM/YYYY');
    // dataComecoSemana = dataComecoSemana + ' 00:00:01';
    // dataFimSemana = dataFimSemana + ' 23:59:59';

    //SEMANA PASSADA
    let comecoSemanaPassada = moment().weekday(-7).toDate();
    let fimDaSemanaPassada = moment().weekday(-7).add(6, 'day').toDate()
    let dataComecoSemanaPassada = moment(comecoSemanaPassada).format('DD/MM/YYYY');
    let dataFimDaSemanaPassada = moment(fimDaSemanaPassada).format('DD/MM/YYYY');

    //MES ATUAL
    const nomeMesAtual = moment().startOf('month').format('MMMM');
    const comecoDoMesAtual = moment().startOf('month').format('DD/MM/YYYY');
    const fimDoMesAtual = moment().endOf('month').format('DD/MM/YYYY');

    //MES PASSADO
    const nomeMesPassado = moment().subtract(1, 'months').startOf('month').format('MMMM');
    const comecoDoMesPassado = moment().subtract(1, 'months').startOf('month').format('DD/MM/YYYY');
    const fimDoMesPassado = moment().subtract(1, 'months').endOf('month').format('DD/MM/YYYY');

    /*




    */


    let atendimentosAbertoHoje = 0;
    let atendimentosAbertoNaSemana = 0;
    let atendimentosAtendendoNaSemana = 0;
    let atendimentosFechadoNaSemana = 0;
    let atendimentosCanceladoNaSemana = 0;
    let atendimentosAbertoAtendendoPrioridadeBaixa = 0;
    let atendimentosAbertoAtendendoPrioridadeNormal = 0;
    let atendimentosAbertoAtendendoPrioridadeAlta = 0;
    let atendimentosAbertoAtendendoPrioridadeUrgente = 0;
    let tempoResolucaoChamadoMenorque1 = 0;
    let tempoResolucaoChamado1 = 0;
    let tempoResolucaoChamado2 = 0;
    let tempoResolucaoChamado3 = 0;
    let tempoResolucaoChamado4 = 0;
    let tempoResolucaoChamado5 = 0;
    let tempoResolucaoChamado6 = 0;
    let tempoResolucaoChamado7 = 0;
    let tempoResolucaoChamadoMaiorque7 = 0;
    let atendimentosPorLocalInterno = 0;
    let atendimentosPorLocalExterno = 0;
    let atendimentosPorLocal = [];
    let arrayChamadosComClientes = [];
    let arrayChamadosComSetores = [];
    let arrayChamadosPorStatus = [];
    let tempoMedioParaSolucaoAtendimentos = 0;
    let tempoMedioPrimeiraRespospota = [];
    let tempoParaSolucaoAtendimentos = [];
    let arrayComChamadosParaBaixar = [];
    let arrayChamadosPorStatusDownload = [];
    // dataComecoSemana = dataComecoSemana + ' 00:00:01';
    //dataFimSemana = dataFimSemana + ' 23:59:59';
    //  let dataComecoSemanaTS = retornaDataTimeStamp(dataComecoSemana);
    // let dataFimSemanaTS = retornaDataTimeStamp(dataFimSemana);

    //como converter uma data de timestamp em data normal
    // let date2 = new Date(1562016662685).toUTCString();
    // let dataAtualTimeStamp = moment(date2).format('DD/MM/YYYY');
    return dispatch => {

        AsyncStorage.getItem('chaveUnica').then((chaveUnica2) => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            //;
            enviaSemConexaoInternetTelaRelatorioDetalhado(dispatch, false);
            ativaDesativaLoadingTelaRelatorioAtendimentoDetalhados(dispatch, true);
            let dataAtual = retornaDataAtual();
            let dataInicioConsulta, dataFinalConsulta;

            //verificacao para saber qual periodo é e passar o periodo correto para a query do firebase
            if (periodoEstatico == 'Hoje') {
                dataInicioConsulta = dataHoje + ' 00:00:01';
                dataFinalConsulta = dataHoje + ' 23:59:59';
                let dataHojeWeb = moment().toDate();
                dataHojeWeb = moment(dataHojeWeb)
                dispatch({ type: MODIFICADATAINICIALRELATORIODETALHADO, payload: dataHojeWeb });
                dispatch({ type: MODIFICADATAFINALRELATORIODETALHADO, payload: dataHojeWeb });
                enviaPeriodoPersonalizadoRelatorioDetalhado(dispatch, periodoEstatico);
            } else if (periodoEstatico == 'Ontem') {
                dataInicioConsulta = datadeOntem + ' 00:00:01';
                dataFinalConsulta = datadeOntem + ' 23:59:59';
                let datadeOntemWeb = moment().subtract(1, 'days').toDate(); // pego data atual
                datadeOntemWeb = moment(datadeOntemWeb);
                dispatch({ type: MODIFICADATAINICIALRELATORIODETALHADO, payload: datadeOntemWeb });
                dispatch({ type: MODIFICADATAFINALRELATORIODETALHADO, payload: datadeOntemWeb });
                enviaPeriodoPersonalizadoRelatorioDetalhado(dispatch, periodoEstatico);
            } else if (periodoEstatico == 'Semana Atual') {
                //
                dataInicioConsulta = dataComecoSemana + ' 00:00:01';
                dataFinalConsulta = dataFimSemana + ' 23:59:59';
                let comecoDaSemanaWeb = moment().startOf('week').toDate();
                let fimDaSemanaWeb = moment().endOf('week').toDate();
                comecoDaSemanaWeb = moment(comecoDaSemanaWeb);
                fimDaSemanaWeb = moment(fimDaSemanaWeb);
                dispatch({ type: MODIFICADATAINICIALRELATORIODETALHADO, payload: comecoDaSemanaWeb });
                dispatch({ type: MODIFICADATAFINALRELATORIODETALHADO, payload: fimDaSemanaWeb });

                enviaPeriodoPersonalizadoRelatorioDetalhado(dispatch, periodoEstatico);
            } else if (periodoEstatico == 'Semana Passada') {
                dataInicioConsulta = dataComecoSemanaPassada + ' 00:00:01';
                dataFinalConsulta = dataFimDaSemanaPassada + ' 23:59:59';
                enviaPeriodoPersonalizadoRelatorioDetalhado(dispatch, periodoEstatico);
            } else if (periodoEstatico.includes('Mês Atual') && valorReferencia == 1) {
                dataInicioConsulta = comecoDoMesAtual + ' 00:00:01';
                dataFinalConsulta = fimDoMesAtual + ' 23:59:59';
                enviaPeriodoPersonalizadoRelatorioDetalhado(dispatch, `${periodoEstatico}, ${nomeMesAtual}.`);
            } else if (periodoEstatico.includes('Mês Passado') && valorReferencia == 1) {
                dataInicioConsulta = comecoDoMesPassado + ' 00:00:01';
                dataFinalConsulta = fimDoMesPassado + ' 23:59:59';
                enviaPeriodoPersonalizadoRelatorioDetalhado(dispatch, `${periodoEstatico}, ${nomeMesPassado}.`);
            } else if (periodoEstatico.includes('Mês Atual') && valorReferencia == 0) {
                dataInicioConsulta = comecoDoMesAtual + ' 00:00:01';
                dataFinalConsulta = fimDoMesAtual + ' 23:59:59';
                //  enviaPeriodoPersonalizado(dispatch, `${periodoEstatico}, ${nomeMesAtual}.`);
            } else if (periodoEstatico.includes('Mês Passado') && valorReferencia == 0) {
                dataInicioConsulta = comecoDoMesPassado + ' 00:00:01';
                dataFinalConsulta = fimDoMesPassado + ' 23:59:59';
                //enviaPeriodoPersonalizado(dispatch, `${periodoEstatico}, ${nomeMesPassado}.`);
            } else {
                //
                //nunca vai chegar aqui, mas pra previnir vou retornar como se fosse semana atual
                dataInicioConsulta = dataComecoSemana + ' 00:00:01';
                dataFinalConsulta = dataFimSemana + ' 23:59:59';
                enviaPeriodoPersonalizadoRelatorioDetalhado(dispatch, periodoEstatico);

            }
            /*

              */
            dataInicioConsulta = retornaDataTimeStamp(dataInicioConsulta);
            dataFinalConsulta = retornaDataTimeStamp(dataFinalConsulta);
            //  zeraDataInicialFiltroRelatorioDetalhado(dispatch);
            // zeraDataFinalFiltroRelatorioDetalhado(dispatch);
            /*
             */
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                    'Accept': 'application/json',
                },

                data: {
                    chaveUnica: chaveUnica,
                    dataInicioConsulta: dataInicioConsulta,
                    dataFinalConsulta: dataFinalConsulta,
                    rota: 'atendimentodetalhado'
                },
                url: 'gerarRelatoriosAtendimentos',
            };
            api(options).then((snapshot) => {


                snapshot = snapshot.data;

                /*  firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild('dataAberturaTimeStamp').startAt(dataInicioConsulta).endAt(dataFinalConsulta).once("value").then((snapshot) => { */
                if (snapshot !== null) {
                    //
                    let arrayOfKeys = Object.keys(snapshot).sort().reverse();
                    let results = arrayOfKeys.map((key) => snapshot[key]);
                    for (let i = 0; i < results.length; i++) {
                        //por status
                        if (results[i].status == 'Aberto') {
                            arrayComChamadosParaBaixar.push({ status: results[i] })
                            atendimentosAbertoNaSemana++
                        }
                        if (results[i].status == 'Atendendo') {
                            arrayComChamadosParaBaixar.push({ status: results[i] })
                            atendimentosAtendendoNaSemana++
                        }
                        if (results[i].status == 'Fechado') {
                            arrayComChamadosParaBaixar.push({ status: results[i] })
                            //;
                            atendimentosFechadoNaSemana++
                            if (results[i].periodoResolucaoChamado == '<1') {
                                arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                tempoResolucaoChamadoMenorque1++
                            }
                            if (results[i].periodoResolucaoChamado == '1') {
                                arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                tempoResolucaoChamado1++
                            }
                            if (results[i].periodoResolucaoChamado == '2') {
                                arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                tempoResolucaoChamado2++
                            }
                            if (results[i].periodoResolucaoChamado == '3') {
                                arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                tempoResolucaoChamado3++
                            }
                            if (results[i].periodoResolucaoChamado == '4') {
                                arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                tempoResolucaoChamado4++
                            }
                            if (results[i].periodoResolucaoChamado == '5') {
                                arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                tempoResolucaoChamado5++
                            }
                            if (results[i].periodoResolucaoChamado == '6') {
                                arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                tempoResolucaoChamado6++
                            }
                            if (results[i].periodoResolucaoChamado == '7') {
                                arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                tempoResolucaoChamado7++
                            }
                            if (results[i].periodoResolucaoChamado == '>7') {
                                arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                tempoResolucaoChamadoMaiorque7++
                            }

                            if (results[i].dataHoraAbertura != undefined && results[i].dataHoraAbertura != '' &&
                                results[i].dataHoraAbertura != null && results[i].dataHoraFechamento != undefined &&
                                results[i].dataHoraFechamento != '' && results[i].dataHoraFechamento != null) {
                                /*
                                   */

                                let dataSubtraida = moment(results[i].dataHoraFechamento, 'DD/MM/YYYY HH:mm').diff(moment(results[i].dataHoraAbertura, 'DD/MM/YYYY HH:mm'))
                                //;
                                tempoParaSolucaoAtendimentos.push(dataSubtraida);
                                tempoMedioPrimeiraRespospota.push(results[i].tempoPrimeiraResposta == undefined ? 0 : results[i].tempoPrimeiraResposta);
                            }
                        }
                        if (results[i].status == 'Cancelado') {
                            arrayComChamadosParaBaixar.push({ status: results[i] })
                            atendimentosCanceladoNaSemana++
                        }
                        //por prioridade
                        if (results[i].prioridade == 'Baixa') {
                            arrayComChamadosParaBaixar.push({ prioridade: results[i] })
                            atendimentosAbertoAtendendoPrioridadeBaixa++;
                        }
                        if (results[i].prioridade == 'Normal') {
                            arrayComChamadosParaBaixar.push({ prioridade: results[i] })
                            atendimentosAbertoAtendendoPrioridadeNormal++;
                        }
                        if (results[i].prioridade == 'Alta') {
                            arrayComChamadosParaBaixar.push({ prioridade: results[i] })
                            atendimentosAbertoAtendendoPrioridadeAlta++;
                        }
                        if (results[i].prioridade == 'Urgente') {
                            arrayComChamadosParaBaixar.push({ prioridade: results[i] })
                            atendimentosAbertoAtendendoPrioridadeUrgente++;
                        }

                        if (results[i].local == 'Interno') {
                            arrayComChamadosParaBaixar.push({ local: results[i] })
                            atendimentosPorLocalInterno++
                        }
                        if (results[i].local == 'Externo') {
                            arrayComChamadosParaBaixar.push({ local: results[i] })
                            atendimentosPorLocalExterno++
                        }

                    }


                    //implemento array para enviar os chamados por status
                    if (atendimentosAbertoNaSemana == 0) {
                        atendimentosAbertoNaSemana = 0.5;
                        /*  arrayChamadosPorStatus.push({
                             value: 0.5, label: 'Aberto'
                         }) */
                    } /* else {
                                    arrayChamadosPorStatus.push({
                                        value: atendimentosAbertoNaSemana, label: 'Aberto'
                                    })
                                } */

                    if (atendimentosAtendendoNaSemana == 0) {
                        atendimentosAtendendoNaSemana = 0.5;
                        /*  arrayChamadosPorStatus.push({
                             value: 0.5, label: 'Atendendo'
                         }) */
                    } /* else {
                                    arrayChamadosPorStatus.push({
                                        value: atendimentosAtendendoNaSemana, label: 'Atendendo'
                                    })
                                } */
                    if (atendimentosFechadoNaSemana == 0) {
                        atendimentosFechadoNaSemana = 0.5;
                        /*  arrayChamadosPorStatus.push({
                             value: 0.5, label: 'Fechado'
                         }) */
                    } /* else {
                                    arrayChamadosPorStatus.push({
                                        value: atendimentosFechadoNaSemana, label: 'Fechado'
                                    })
                                } */
                    if (atendimentosCanceladoNaSemana == 0) {
                        atendimentosCanceladoNaSemana = 0.5;
                        /*   arrayChamadosPorStatus.push({
                              value: 0.5, label: 'Cancelado'
                          }) */
                    } /* else {
                                    arrayChamadosPorStatus.push({
                                        value: atendimentosCanceladoNaSemana, label: 'Cancelado'
                                    })
                                } */
                    //
                    let atendimentosPorStatusEnviaDashBoard = [
                        { value: atendimentosAbertoNaSemana, label: 'Aberto', id: "Aberto", color: "hsl(195, 100%, 50%)", valorZerado: "0" },
                        { value: atendimentosAtendendoNaSemana, label: 'Atendendo', id: "Atendendo", color: "hsl(42, 94%, 60%)", valorZerado: "0" },
                        { value: atendimentosFechadoNaSemana, label: 'Fechado', id: "Fechado", color: "hsl(150, 100%, 50%)", valorZerado: "0" },
                        { value: atendimentosCanceladoNaSemana, label: 'Cancelado', id: "Cancelado", color: "hsl(344, 94%, 51%)", valorZerado: "0" }];
                    enviaAtendimentosPorStatusRelatorioDetalhado(dispatch, atendimentosPorStatusEnviaDashBoard);

                    /*        let atendimentosPorStatusEnviaDashBoard = {
                               dataSets: [{
                                   values: arrayChamadosPorStatus,
                                   label: '',
                                   config: {
                                       colors: [processColor('#00BFFF'), processColor('#F9BF3B'), processColor('#00FF7F'), processColor('red')],
                                       valueTextSize: 18,
                                       valueTextColor: processColor('#000'),
                                       sliceSpace: 5,
                                       selectionShift: 13,
                                       // xValuePosition: "OUTSIDE_SLICE",
                                       // yValuePosition: "OUTSIDE_SLICE",
                                       // valueFormatter: "#.#'%'",
                                       valueFormatter: "#",
                                       valueLineColor: processColor('#000'),
                                       valueLinePart1Length: 0.5
                                   }
                               }],
                           }
                           enviaAtendimentosPorStatusRelatorioDetalhado(dispatch, atendimentosPorStatusEnviaDashBoard);*/
                    /*     let TotalAtendimentosPorStatus = atendimentosAbertoNaSemana +
                            atendimentosAtendendoNaSemana + atendimentosFechadoNaSemana +
                            atendimentosCanceladoNaSemana;
                        enviaTotalAtendimentosPorStatusRelatorioDetalhado(dispatch, TotalAtendimentosPorStatus) */


                    //
                    //envio chamados por prioridades
                    let totalAtendimentosAbertoAtendendoPorPrioridade = atendimentosAbertoAtendendoPrioridadeBaixa +
                        atendimentosAbertoAtendendoPrioridadeNormal + atendimentosAbertoAtendendoPrioridadeAlta +
                        atendimentosAbertoAtendendoPrioridadeUrgente;
                    enviaTotalAtendimentosPorPRioridadeRelatorioDetalhado(dispatch, totalAtendimentosAbertoAtendendoPorPrioridade)
                    if (atendimentosAbertoAtendendoPrioridadeBaixa == 0) {
                        atendimentosAbertoAtendendoPrioridadeBaixa = 0.5
                    }
                    if (atendimentosAbertoAtendendoPrioridadeNormal == 0) {
                        atendimentosAbertoAtendendoPrioridadeNormal = 0.5
                    }
                    if (atendimentosAbertoAtendendoPrioridadeAlta == 0) {
                        atendimentosAbertoAtendendoPrioridadeAlta = 0.5
                    }
                    if (atendimentosAbertoAtendendoPrioridadeUrgente == 0) {
                        atendimentosAbertoAtendendoPrioridadeUrgente = 0.5
                    }
                    let atendimentosPorPrioridadeEnviaDashBoard = [
                        {
                            value: atendimentosAbertoAtendendoPrioridadeBaixa,
                            label: 'Baixa',
                            id: "Baixa",
                            color: "hsl(195, 100%, 50%)",
                            valorZerado: "0"
                        },
                        {
                            value: atendimentosAbertoAtendendoPrioridadeNormal,
                            label: 'Normal',
                            id: "Normal",
                            color: "hsl(0, 0%, 83%)",
                            valorZerado: "0"
                        },
                        { value: atendimentosAbertoAtendendoPrioridadeAlta, label: 'Alta', id: "Alta", color: "hsl(42, 94%, 60%)", valorZerado: "0" },
                        {
                            value: atendimentosAbertoAtendendoPrioridadeUrgente,
                            label: 'Urgente',
                            id: "Urgente",
                            color: "hsl(344, 94%, 51%)",
                            valorZerado: "0"
                        }];
                    enviaAtendimentosPorPrioridadeRelatorioDetalhado(dispatch, atendimentosPorPrioridadeEnviaDashBoard)

                    /*     let atendimentosPorPrioridadeEnviaDashBoard = {
                            dataSets: [{
                                values: [
                                    { value: atendimentosAbertoAtendendoPrioridadeBaixa, label: 'Baixa' },
                                    { value: atendimentosAbertoAtendendoPrioridadeNormal, label: 'Normal' },
                                    { value: atendimentosAbertoAtendendoPrioridadeAlta, label: 'Alta' },
                                    { value: atendimentosAbertoAtendendoPrioridadeUrgente, label: 'Urgente' }],
                                label: '',
                                config: {
                                    colors: [processColor('#00BFFF'), processColor('#B0C4DE'), processColor('#F9BF3B'), processColor('#f70e4c')],
                                    valueTextSize: 18,
                                    valueTextColor: processColor('#000'),
                                    sliceSpace: 5,
                                    selectionShift: 13,
                                    // xValuePosition: "OUTSIDE_SLICE",
                                    // yValuePosition: "OUTSIDE_SLICE",
                                    // valueFormatter: "#.#'%'",
                                    valueFormatter: "#",
                                    valueLineColor: processColor('#000'),
                                    valueLinePart1Length: 0.5
                                }
                            }],
                        }
                        enviaAtendimentosPorPrioridadeRelatorioDetalhado(dispatch, atendimentosPorPrioridadeEnviaDashBoard) */
                    //
                    if (tempoResolucaoChamadoMenorque1 == 0) {
                        tempoResolucaoChamadoMenorque1 = 0.5
                    }
                    if (tempoResolucaoChamado1 == 0) {
                        tempoResolucaoChamado1 = 0.5
                    }
                    if (tempoResolucaoChamado2 == 0) {
                        tempoResolucaoChamado2 = 0.5
                    }
                    if (tempoResolucaoChamado3 == 0) {
                        tempoResolucaoChamado3 = 0.5
                    }
                    if (tempoResolucaoChamado4 == 0) {
                        tempoResolucaoChamado4 = 0.5
                    }
                    if (tempoResolucaoChamado5 == 0) {
                        tempoResolucaoChamado5 = 0.5
                    }
                    if (tempoResolucaoChamado6 == 0) {
                        tempoResolucaoChamado6 = 0.5
                    }
                    if (tempoResolucaoChamado7 == 0) {
                        tempoResolucaoChamado7 = 0.5
                    }
                    if (tempoResolucaoChamadoMaiorque7 == 0) {
                        tempoResolucaoChamadoMaiorque7 = 0.5
                    }
                    let atendimentosPorTempOResolucao = [
                        { value: tempoResolucaoChamadoMenorque1, label: '< 1 dia', id: "< 1 dia", color: "hsl(150, 100%, 50%)", valorZerado: "0" },
                        { value: tempoResolucaoChamado1, label: '1 dia', id: "1 dia", color: "hsl(0, 0%, 83%)", valorZerado: "0" },
                        { value: tempoResolucaoChamado2, label: '2 dias', id: "2 dias", color: "hsl(42, 94%, 60%)", valorZerado: "0" },
                        { value: tempoResolucaoChamado3, label: '3 dias', id: "3 dias", color: "hsl(344, 94%, 51%)", valorZerado: "0" },
                        { value: tempoResolucaoChamado4, label: '4 dias', id: "4 dias", color: "hsl(214, 41%, 78%)", valorZerado: "0" },
                        { value: tempoResolucaoChamado5, label: '5 dias', id: "5 dias", color: "hsl(195, 100%, 50%)", valorZerado: "0" },
                        { value: tempoResolucaoChamado6, label: '6 dias', id: "6 dias", color: "hsl(180, 100%, 50%)", valorZerado: "0" },
                        { value: tempoResolucaoChamado7, label: '7 dias', id: "7 dias", color: "hsl(25, 75%, 47%)", valorZerado: "0" },
                        { value: tempoResolucaoChamadoMaiorque7, label: '> 7 dias', id: "> 7 dias", color: "hsl(180, 100%, 27%)", valorZerado: "0" }];
                    enviaTempoResolucaoChamadosRelatorioDetalhado(dispatch, atendimentosPorTempOResolucao);
                    /*       let atendimentosPorTempOResolucao = {
                              dataSets: [{
                                  values: [
                                      { value: tempoResolucaoChamadoMenorque1, label: '< 1 dia' },
                                      { value: tempoResolucaoChamado1, label: '1 dia' },
                                      { value: tempoResolucaoChamado2, label: '2 dias' },
                                      { value: tempoResolucaoChamado3, label: '3 dias' },
                                      { value: tempoResolucaoChamado4, label: '4 dias' },
                                      { value: tempoResolucaoChamado5, label: '5 dias' },
                                      { value: tempoResolucaoChamado6, label: '6 dias' },
                                      { value: tempoResolucaoChamado7, label: '7 dias' },
                                      { value: tempoResolucaoChamadoMaiorque7, label: '> 7 dias' }],
                                  label: '',
                                  config: {
                                      colors: [processColor('#00FF7F'), processColor('#D3D3D3'), processColor('#F9BF3B'), processColor('#f70e4c'), processColor('#B0C4DE'),
                                      processColor('#00BFFF'), processColor('#00FFFF'), processColor('#D2691E'), processColor('#008B8B')],
                                      valueTextSize: 18,
                                      valueTextColor: processColor('#000'),
                                      sliceSpace: 5,
                                      selectionShift: 13,
                                      // xValuePosition: "OUTSIDE_SLICE",
                                      // yValuePosition: "OUTSIDE_SLICE",
                                      // valueFormatter: "#.#'%'",
                                      valueFormatter: "#",
                                      valueLineColor: processColor('#000'),
                                      valueLinePart1Length: 0.5
                                  }
                              }],
                          }
                          enviaTempoResolucaoChamadosRelatorioDetalhado(dispatch, atendimentosPorTempOResolucao);
                          enviaTotalAtendimentosPeriodoResolucaoRelatorioDetalhado(dispatch, atendimentosFechadoNaSemana); */
                    //
                    //atendimentos interno e externo
                    /*    if (atendimentosPorLocalInterno == 0) {
                           atendimentosPorLocal.push({
                               value: 0.5, label: 'Interno'
                           })

                       } else {
                           atendimentosPorLocal.push({
                               value: atendimentosPorLocalInterno, label: 'Interno'
                           })
                       }
                       if (atendimentosPorLocalExterno == 0) {
                           atendimentosPorLocal.push({
                               value: 0.5, label: 'Externo'
                           })

                       } else {
                           atendimentosPorLocal.push({
                               value: atendimentosPorLocalExterno, label: 'Externo'
                           })
                       }

                       let atendimentosPorLocalArray = {
                           dataSets: [{
                               values: atendimentosPorLocal,
                               label: '',
                               config: {
                                   colors: [processColor('#00BFFF'), processColor('#B0C4DE')],
                                   valueTextSize: 18,
                                   valueTextColor: processColor('#000'),
                                   sliceSpace: 5,
                                   selectionShift: 13,
                                   // xValuePosition: "OUTSIDE_SLICE",
                                   // yValuePosition: "OUTSIDE_SLICE",
                                   // valueFormatter: "#.#'%'",
                                   valueFormatter: "#",
                                   valueLineColor: processColor('#000'),
                                   valueLinePart1Length: 0.5
                               }
                           }],
                       }
                       let totalAtendimentosPorLocal = atendimentosPorLocalInterno + atendimentosPorLocalExterno;
                       enviaTotalAtendimentosPorLocalRelatorioDetalhado(dispatch, totalAtendimentosPorLocal)
                       enviaAtendimentosPorLocalRelatorioDetalhado(dispatch, atendimentosPorLocalArray) */
                    if (atendimentosPorLocalInterno == 0) {
                        atendimentosPorLocal.push({
                            value: 0.5, label: 'Interno', id: "Interno", color: "hsl(195, 100%, 50%)", valorZerado: "0"
                        })

                    } else {
                        atendimentosPorLocal.push({
                            value: atendimentosPorLocalInterno, label: 'Interno', id: "Interno", color: "hsl(195, 100%, 50%)", valorZerado: "0"
                        })
                    }
                    if (atendimentosPorLocalExterno == 0) {
                        atendimentosPorLocal.push({
                            value: 0.5, label: 'Externo', id: "Externo", color: "hsl(214, 41%, 78%)", valorZerado: "0"
                        })

                    } else {
                        atendimentosPorLocal.push({
                            value: atendimentosPorLocalExterno, label: 'Externo', id: "Externo", color: "hsl(214, 41%, 78%)", valorZerado: "0"
                        })
                    }

                    enviaAtendimentosPorLocalRelatorioDetalhado(dispatch, atendimentosPorLocal)
                    //

                    //tempo médio para solucao de atendimentos
                    //
                    if (tempoParaSolucaoAtendimentos.length !== 0) {
                        tempoMedioParaSolucaoAtendimentos = retornaMedia(tempoParaSolucaoAtendimentos);
                        let durationTempoMedio = moment.duration(tempoMedioParaSolucaoAtendimentos);
                        // console.log(durationTempoMedio.asHours())
                        if (durationTempoMedio.asHours() >= 24) {
                            let dataConvertidaDoTempoMedio = Math.floor(durationTempoMedio.asDays()) + "d" + moment.utc(tempoMedioParaSolucaoAtendimentos).format(" hh") + "h";
                            //
                            //
                            enviaTempoMedioDeSolucaoAtendimentos(dispatch, dataConvertidaDoTempoMedio)
                        } else {
                            let dataConvertidaDoTempoMedio = Math.floor(durationTempoMedio.asHours()) + "h" + moment.utc(tempoMedioParaSolucaoAtendimentos).format(" mm") + "m";
                            //
                            //
                            enviaTempoMedioDeSolucaoAtendimentos(dispatch, dataConvertidaDoTempoMedio)
                        }

                    } else {
                        //
                        enviaTempoMedioDeSolucaoAtendimentos(dispatch, '0h 00m')
                    }
                    if (tempoMedioPrimeiraRespospota.length > 0) {
                        tempoMedioPrimeiraRespospota = retornaMedia(tempoMedioPrimeiraRespospota);
                        const tempoPrimeiraRespostaFormatado = minutes_to_hhmm(tempoMedioPrimeiraRespospota);
                        enviaTempoMedioRespostaAtendimentos(dispatch, tempoPrimeiraRespostaFormatado)
                    } else {
                        enviaTempoMedioRespostaAtendimentos(dispatch, '0h 00m')
                    }

                    //
                    enviaChamadosDetalhadosParaDownload(dispatch, arrayComChamadosParaBaixar);
                    ativaDesativaLoadingTelaRelatorioAtendimentoDetalhados(dispatch, false);
                    // ativaDesativaLoadingRefreshControol(dispatch, false);
                } else {
                    //
                    //sem informacoes

                    let atendimentosPorStatusZerado = [
                        { value: 0.5, label: 'Aberto', id: "Aberto", color: "hsl(195, 100%, 50%)", valorZerado: "0" },
                        { value: 0.5, label: 'Atendendo', id: "Atendendo", color: "hsl(42, 94%, 60%)", valorZerado: "0" },
                        { value: 0.5, label: 'Fechado', id: "Fechado", color: "hsl(150, 100%, 50%)", valorZerado: "0" },
                        { value: 0.5, label: 'Cancelado', id: "Cancelado", color: "hsl(344, 94%, 51%)", valorZerado: "0" }];
                    enviaAtendimentosPorStatusRelatorioDetalhado(dispatch, atendimentosPorStatusZerado);
                    let atendimentoPorPrioridadeZerado = [
                        { value: 0.5, label: 'Baixa', id: "Baixa", color: "hsl(195, 100%, 50%)", valorZerado: "0" },
                        { value: 0.5, label: 'Normal', id: "Normal", color: "hsl(0, 0%, 83%)", valorZerado: "0" },
                        { value: 0.5, label: 'Alta', id: "Alta", color: "hsl(42, 94%, 60%)", valorZerado: "0" },
                        { value: 0.5, label: 'Urgente', id: "Urgente", color: "hsl(344, 94%, 51%)", valorZerado: "0" }
                    ]


                    enviaAtendimentosPorPrioridadeRelatorioDetalhado(dispatch, atendimentoPorPrioridadeZerado);
                    //  enviaTotalAtendimentosPorPRioridade(dispatch, 0)
                    let atendimentoPorResolucaoZerado = [
                        { value: 0.5, label: '< 1 dia', id: "< 1 dia", color: "hsl(150, 100%, 50%)", valorZerado: "0" },
                        { value: 0.5, label: '1 dia', id: "1 dia", color: "hsl(0, 0%, 83%)", valorZerado: "0" },
                        { value: 0.5, label: '2 dias', id: "2 dias", color: "hsl(42, 94%, 60%)", valorZerado: "0" },
                        { value: 0.5, label: '3 dias', id: "3 dias", color: "hsl(344, 94%, 51%)", valorZerado: "0" },
                        { value: 0.5, label: '4 dias', id: "4 dias", color: "hsl(214, 41%, 78%)", valorZerado: "0" },
                        { value: 0.5, label: '5 dias', id: "5 dias", color: "hsl(195, 100%, 50%)", valorZerado: "0" },
                        { value: 0.5, label: '6 dias', id: "6 dias", color: "hsl(180, 100%, 50%)", valorZerado: "0" },
                        { value: 0.5, label: '7 dias', id: "7 dias", color: "hsl(25, 75%, 47%)", valorZerado: "0" },
                        { value: 0.5, label: '> 7 dias', id: "> 7 dias", color: "hsl(180, 100%, 27%)", valorZerado: "0" }]
                    enviaTempoResolucaoChamadosRelatorioDetalhado(dispatch, atendimentoPorResolucaoZerado);
                    // enviaTotalAtendimentosPeriodoResolucao(dispatch, 0);

                    let atendimentoPorLocalZerado = [
                        { value: 0.5, label: 'Interno', id: "Interno", color: "hsl(195, 100%, 50%)", valorZerado: "0" },
                        { value: 0.5, label: 'Externo', id: "Externo", color: "hsl(214, 41%, 78%)", valorZerado: "0" }
                    ];
                    // enviaTotalAtendimentosPorLocalDashboard(dispatch, 0)
                    enviaAtendimentosPorLocalRelatorioDetalhado(dispatch, atendimentoPorLocalZerado)

                    //envia tempo medio zerado
                    enviaTempoMedioDeSolucaoAtendimentos(dispatch, '0h 00m')

                    enviaTempoMedioRespostaAtendimentos(dispatch, '0h 00m')

                    enviaChamadosDetalhadosParaDownload(dispatch, []);
                    ativaDesativaLoadingTelaRelatorioAtendimentoDetalhados(dispatch, false);
                    //
                }
            })

        })

    }
}

export const exportaAtendimentoParaPDF = (arrayChamados) => {
    return dispatch => {

        AsyncStorage.getItem('chaveUnica').then((chaveUnica2) => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            toastr.warning('Gerando PDF', 'Por favor aguarde...')


            let chamado = [];
            chamado.push({
                cliente: arrayChamados.cliente == "Não informado" ? "" : arrayChamados.cliente,
                empresa: arrayChamados.empresa == "Não informado" ? "" : arrayChamados.empresa,
                setor: arrayChamados.setor == "Não informado" ? "" : arrayChamados.setor,
                atendente: arrayChamados.atendente == "Não informado" ? "" : arrayChamados.atendente,
                dataHoraCancelamento: arrayChamados.dataHoraCancelamento == undefined ? "" : arrayChamados.dataHoraCancelamento,
                dataHoraFechamento: arrayChamados.dataHoraFechamento == undefined ? "" : arrayChamados.dataHoraFechamento,
                dataVencimento: arrayChamados.dataVencimento == "Não informado" ? "" : arrayChamados.dataVencimento,
                local: arrayChamados.local == "Não informado" ? "" : arrayChamados.local,
                periodoResolucaoChamado: arrayChamados.periodoResolucaoChamado == "" ? "" : arrayChamados.periodoResolucaoChamado + ' dia(s)',
                dataHoraAbertura: arrayChamados.dataHoraAbertura,
                status: arrayChamados.status,
                prioridade: arrayChamados.prioridade === "Não informado" ? "" : arrayChamados.prioridade,
                descricaoProblema: arrayChamados.descricaoProblema,
                numeroChamado: arrayChamados.numeroChamado,
                tempoAtendimentoGasto: arrayChamados.tempoGasto == 0 ? "" : arrayChamados.tempoGasto + ' min.',
                solucaoAtendimento: arrayChamados.solucaoAtendimento == undefined ? '' : arrayChamados.solucaoAtendimento,
            })
            firebase.database().ref(`${chaveUnica}/acoesChamados/${arrayChamados.key}`).once("value").then(snapshot => {
                let arrayAcoesAtendimento = [];
                snapshot.forEach(childItem => {
                    if (childItem.val().tipo) {
                        arrayAcoesAtendimento.push({
                            key: childItem.key,
                            title: childItem.val().titulo,
                            tipo: childItem.val().tipo,
                            description: childItem.val().mensagem,
                            statusMessage: childItem.val().statusMessage == undefined ? 'sent' : childItem.val().statusMessage,
                            time: childItem.val().time,
                            lineColor: childItem.val().lineColor,
                            circleColor: childItem.val().circleColor,
                            urlDownload: childItem.val().urlDownload,
                            tipoArquivo: childItem.val().tipoArquivo,
                            audio: childItem.val().audio == undefined ? false : childItem.val().audio,
                            duracao: childItem.val().audio == true ? childItem.val().duracao : '',
                            dataHoraAtual: childItem.val().dataHoraAtual == undefined ? childItem.val().mensagem : childItem.val().dataHoraAtual,
                            visivelATodos: childItem.val().visivelATodos == undefined ? 1 : childItem.val().visivelATodos,
                            idMensagem: childItem.val().idMensagem == undefined ? childItem.key : childItem.val().idMensagem === '' ? childItem.key : childItem.val().idMensagem,
                            circleColorResponder: childItem.val().circleColorResponder == undefined ? '#00FF7F' : childItem.val().circleColorResponder,
                            commentResponderType: childItem.val().commentResponderType == undefined ? '' : childItem.val().commentResponderType,
                            idMensagemResponder: childItem.val().idMensagemResponder == undefined ? '' : childItem.val().idMensagemResponder,
                            mensagemResposta: childItem.val().mensagemResposta == undefined ? '' : childItem.val().mensagemResposta,
                            possuiResposta: childItem.val().possuiResposta == undefined ? false : childItem.val().possuiResposta,
                            commentResponderTypeArquivo: childItem.val().commentResponderTypeArquivo == undefined ? '' : childItem.val().commentResponderTypeArquivo,
                            commentResponderIsAudio: childItem.val().commentResponderIsAudio == undefined ? '' : childItem.val().commentResponderIsAudio,
                            atendenteResponder: childItem.val().atendenteResponder == undefined ? '' : childItem.val().atendenteResponder,
                            captionArquivo: childItem.val().captionArquivo == undefined ? '' : childItem.val().captionArquivo,
                            read: childItem.val().read == undefined ? false : childItem.val().read,
                            keyAtendimentoResponder: childItem.val().keyAtendimentoResponder == undefined ? '' : childItem.val().keyAtendimentoResponder,
                            deleteMessage: childItem.val().deleteMessage == undefined ? false : childItem.val().deleteMessage,

                        });
                    }
                });
                //ativaDesativaLoadingTelaAcoesAtendimento(dispatch, false);
                exportRelatorioAtendimentoParaPDF(chamado, dispatch, arrayAcoesAtendimento.reverse());
            });



        })
    }
}

async function exportRelatorioAtendimentoParaPDF(chamados, dispatch, conversasDoChamado) {
    try {
        let options = {
            info: {
                title: 'Atendimentos',
            },
            pageSize: 'A4',
            pageOrientation: 'landscape',
            content: [],
        }
        options.content.push(
            {
                fontSize: 10,
                table: {
                    headerRows: 1,
                    body: [
                        ['Protocolo', 'Problema', 'Status', 'Cliente', 'Empresa', 'Setor', 'Atendente', "Abertura", "Fechamento", "Vencimento", "Prioridade"],
                        [chamados[0].numeroChamado === undefined ? "" : chamados[0].numeroChamado, chamados[0].descricaoProblema === undefined ? "" : chamados[0].descricaoProblema, chamados[0].status === undefined ? "" : chamados[0].status, chamados[0].cliente === undefined ? "" : chamados[0].cliente, chamados[0].empresa === undefined ? "" : chamados[0].empresa,
                        chamados[0].setor === undefined ? "" : chamados[0].setor,
                        chamados[0].atendente === undefined ? "" : chamados[0].atendente,
                        chamados[0].dataHoraAbertura === undefined ? "" : chamados[0].dataHoraAbertura,
                        chamados[0].dataHoraFechamento === undefined ? "" : chamados[0].dataHoraFechamento,
                        chamados[0].dataVencimento === undefined ? "" : chamados[0].dataVencimento,
                        chamados[0].prioridade === undefined ? "" : chamados[0].prioridade,
                        ]
                    ],
                }
            }
        )
        for (let o = 0; o < conversasDoChamado.length; o++) {
            let link = conversasDoChamado[o].urlDownload;
            let mensagem = conversasDoChamado[o].description === undefined ? "" : conversasDoChamado[o].description;
            let dataHoraAtual = conversasDoChamado[o].dataHoraAtual === undefined ? "" : conversasDoChamado[o].dataHoraAtual;
            let titulo = conversasDoChamado[o].title === undefined ? "" : conversasDoChamado[o].title;
            const tipoArquivo = conversasDoChamado[o].tipoArquivo === undefined ? "" : conversasDoChamado[o].tipoArquivo;

            if (conversasDoChamado[o].tipo === 'mensagem' || link === undefined) {

                // options.content[0].push({text:`(${conversasDoChamado[o].dataHoraAtual}) ${conversasDoChamado[o].titulo}: ${conversasDoChamado[o].mensagem}`})
                if (mensagem !== undefined) {

                }
                options.content.push({ text: [`(${dataHoraAtual}) ${titulo}:`, { text: mensagem, fontSize: 14, bold: false },], margin: 5, fontSize: 14, bold: true })
                // timeline += '<div style="margin-top:10px"><p style="font-size: 13px">(' + conversasDoChamado[o].dataHoraAtual + ') ' + conversasDoChamado[o].titulo + ': ' + conversasDoChamado[o].mensagem + '</p></div>';
            } else {

                let urlDownload = "";
                if (link.includes("firebasestorage.googleapis.com/v0/b/")) {
                    if (link.includes("?")) {
                        let splitLink = link.split("?");


                        urlDownload = splitLink[0].replace("https://firebasestorage.googleapis.com/v0/b/dark-4b307.appspot.com/o/", "https://storage.googleapis.com/dark-4b307.appspot.com/")

                    } else {
                        urlDownload = link.replace("https://firebasestorage.googleapis.com/v0/b/dark-4b307.appspot.com/o/", "https://storage.googleapis.com/dark-4b307.appspot.com/")
                    }


                } else {
                    urlDownload = link;

                }

                urlDownload = urlDownload === undefined ? "" : urlDownload;

                if (!!tipoArquivo && isFileImage(tipoArquivo)
                    // && urlDownload !== "https://storage.googleapis.com/dark-4b307.appspot.com/LqDgFyBq5qTLEDcMakqxXHfK3Bo2/-NJVEtny_NENnoQD8mz-/-NJVEtny_NENnoQD8mz-/8211059773055825CC52BCEB5EE45A9BA1592F378074.jpeg"
                ) {
                    /*   console.log(tipoArquivo)
                      console.log(urlDownload) */
                    /*   const file = await getFileFromUrl(urlDownload, new Date().getTime());
                      const base64File = await fileToBase64(file);
                      console.log(file);
                      console.log(base64File); */
                    const base64Url = await getBase64FromImageUrl(urlDownload, tipoArquivo);
                    if (base64Url) {
                        options.content.push([
                            `(${dataHoraAtual}) ${titulo}:`,
                            {
                                image: base64Url,
                                fit: [250, 250],
                            },
                        ])

                        options.images = {
                            ...options.images,
                            [urlDownload]: base64Url,
                        };
                    }else{
                        options.content.push({
                            text: `(${dataHoraAtual}) ${titulo}: ${urlDownload}`,
                            link: urlDownload,
                            color: "#0000FF",
                            textDecoration: 'underline',
                            margin: 5,
                            fontSize: 14,
                            bold: true
                        })
                    }


                } else {

                    options.content.push({
                        text: `(${dataHoraAtual}) ${titulo}: ${urlDownload}`,
                        link: urlDownload,
                        color: "#0000FF",
                        textDecoration: 'underline',
                        margin: 5,
                        fontSize: 14,
                        bold: true
                    })
                }
            }


        }
        // const fetches = [];
        // console.log(options)
        /*  options.images.forEach(src => {
             fetches.push(fetchImage(src).then(data => { options.images[src] = data; }));
         }); */
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
        const now = new Date();
        const date = `${now.getDate()}_${now.getMonth() + 1}_${now.getFullYear()}`;

        /*    Promise.all(fetches).then(() => { */
        pdfMake.createPdf(options).download(`relatorio_atendimento_${date}.pdf`);
    } catch (e) {
        console.log(e);
    }
    /*  }) */
}
/* 
function exportRelatorioAtendimentoParaPDF(chamados, dispatch, conversas) {
    

    //
    //;
    let timeline = '';
    for (let i = 0; i < conversas.length; i++) {
        if (conversas[i].tipo == 'mensagem') {
            timeline += '<div style="margin-top:10px"><p style="font-size: 15px">(' + conversas[i].dataHoraAtual + ') ' + conversas[i].title + ': ' + conversas[i].description + '</p></div>';
        } else {
            if (conversas[i].tipoArquivo != undefined && conversas[i].tipoArquivo != null && conversas[i].tipoArquivo != '') {
                if (conversas[i].tipoArquivo.includes('image')) {
                    if (conversas[i].urlDownload == undefined || conversas[i].urlDownload == null || conversas[i].urlDownload == '') {
                        timeline += '<div style="margin-top:10px"><p style="font-size: 15px">(' + conversas[i].dataHoraAtual + ') ' + conversas[i].title + ': ' + conversas[i].description + '</p></div>';
                    } else {
                     
                        timeline += '<div style="margin-top:10px"><p style="font-size: 15px">(' + conversas[i].dataHoraAtual + ') ' + conversas[i].title + ': </p></div>';
                        timeline += '<div><img width="350" height="350" src=' + conversas[i].urlDownload + ' alt="Image"/></div>';


                    }
                } else {
                    timeline += '<div style="margin-top:10px"><p style="font-size: 15px">(' + conversas[i].dataHoraAtual + ') ' + conversas[i].title + ': ' + conversas[i].description + '</p></div>';
                }
            } else {
                timeline += '<div style="margin-top:10px"><p style="font-size: 13px">(' + conversas[i].dataHoraAtual + ') ' + conversas[i].title + ': ' + conversas[i].description + '</p></div>';
            }
        }
    }
    try {
        //pegando data e hora para colocar no nome do arquivo para que nao haja repeticao de nome
        let dataAtual = moment().toDate();
        dataAtual = moment(dataAtual).format("DD-MM-YYYY HH-mm-ss");
        //


        const doc = new jsPDF({
            orientation: 'p',
            unit: 'pt',
            format: 'a4'
        });
        //  relatorio${dataAtual}.pdf`
        let myTableTeste = '<div >' +
            '<h3 style="text-align: center">Relatório do atendimento #' + chamados[0].numeroChamado + '</h3>' +
            '<div style="margin-bottom:10px">' +
            '<label style="font-size:11px,font-weight:bold">Cliente: <label style="font-size:12px">' + chamados[0].cliente + ' </label> </label>' +
            '</div>'
            + '<hr>' +
            '<div style="margin-bottom:10px">' +
            '<label style="font-size:12px,font-weight:bold">Empresa:  <label style="font-size:12px">' + chamados[0].empresa + '</label> </label>' +
            '</div>' +
            '<hr>' +
            '<div style="margin-bottom:10px">' +
            '<label style="font-size:12px,font-weight:bold">Atendente:  <label style="font-size:12px">' + chamados[0].atendente + '</label> </label>' +
            '</div>' +
            '<hr>' +
            '<div style="margin-bottom:10px">' +
            '<label style="font-size:12px,font-weight:bold">Setor:  <label style="font-size:12px">' + chamados[0].setor + '</label> </label>' +
            '</div>' +
            '<hr>' +
            '<div style="margin-bottom:10px">' +
            '<label style="font-size:12px,font-weight:bold">Vencimento:  <label style="font-size:12px">' + chamados[0].dataVencimento + '</label> </label>' +
            '</div>' +
            '<hr>' +
            '<div style="margin-bottom:10px">' +
            '<label style="font-size:12px,font-weight:bold">Prioridade:  <label style="font-size:12px">' + chamados[0].prioridade + '</label> </label>' +
            '</div>' +
            '<hr>' +
            '<div style="margin-bottom:10px">' +
            '<label style="font-size:12px,font-weight:bold">Local:  <label style="font-size:12px">' + chamados[0].local + '</label> </label>' +
            '</div>' +
            '<hr>' +
            '<div style="margin-bottom:10px">' +
            '<label style="font-size:12px,font-weight:bold">Problema:  <label style="font-size:12px">' + chamados[0].descricaoProblema + '</label> </label>' +
            '</div>' +
            '<hr>' +
            '<div style="margin-bottom:10px">' +
            '<label style="font-size:12px,font-weight:bold">Solucao:  <label style="font-size:12px">' + chamados[0].solucaoAtendimento + '</label> </label>' +
            '</div>' +
            '<hr>' +
            '<div style="margin-bottom:10px">' +
            '<label style="font-size:12px,font-weight:bold">Status:  <label style="font-size:12px">' + chamados[0].status + '</label> </label>' +
            '</div>' +
            '<hr>' +
            '<div style="margin-bottom:10px">' +
            '<label style="font-size:12px,font-weight:bold">Abertura:  <label style="font-size:12px">' + chamados[0].dataHoraAbertura + '</label> </label>' +
            '</div>' +
            '<hr>' +
            '<div style="margin-bottom:10px">' +
            '<label style="font-size:12px,font-weight:bold">Fechamento:  <label style="font-size:12px">' + chamados[0].dataHoraFechamento + '</label> </label>' +
            '</div>' +
            '<hr>' +
            '<div style="margin-bottom:10px">' +
            '<label style="font-size:12px,font-weight:bold">Cancelamento:  <label style="font-size:12px">' + chamados[0].dataHoraCancelamento + '</label> </label>' +
            '</div>' +
            '<hr>' +
            '<div style="margin-bottom:10px">' +
            '<label style="font-size:12px,font-weight:bold">Periodo Resolução:  <label style="font-size:12px">' + chamados[0].periodoResolucaoChamado + '</label> </label>' +
            '</div>' +
            '<hr>' +
            '<div style="margin-bottom:10px">' +
            '<label style="font-size:12px,font-weight:bold">Tempo Atend. Gasto:  <label style="font-size:12px">' + chamados[0].tempoAtendimentoGasto + '</label> </label>' +
            '</div>' +
            '<hr>' +
            '<div style="margin-top:15px">' +
            '<h3 style="text-align: center">Comentarios do atendimento #' + chamados[0].numeroChamado + ' iniciado em ' + chamados[0].dataHoraAbertura + '</h3>' +
            timeline +
            '</div>' +
            '</div>';
        let opt = {
            filename: `relatorio${dataAtual}.pdf`,
            jsPDF: { orientation: 'p', unit: 'pt', format: 'a4' },
            html2canvas: {
                dpi: 300,
                letterRendering: true,
                useCORS: true
            }
        };


        html2pdf().from(myTableTeste).set(opt).toPdf().output('blob').then(function (pdfBlob) {
            window.open(URL.createObjectURL(pdfBlob));
        });


    } catch (err) {
        alert(err);
    }


} */

export const novalistaInformacoesDashBoard = () => {
    return dispatch => {
        let dataHojeEnviaDatePicker = moment().toDate();
        dataHojeEnviaDatePicker = moment(dataHojeEnviaDatePicker);

        let dataAtual2 = moment().toDate();
        let dataAtual = moment(dataAtual2).format('DD/MM/YYYY');
        const comecoDaSemana = moment().startOf('week').toDate();
        const fimDaSemana = moment().endOf('week').toDate();
        dispatch({ type: MODIFICADATAINICIALCONSULTADASHBOARD, payload: moment(dataAtual2) });
        dispatch({ type: MODIFICADATAFINALCONSULTADASHBOARD, payload: moment(dataAtual2) });
        let dataComecoSemana = dataAtual;
        let dataFimSemana = dataAtual;
        let atendimentosAbertoHoje = 0;
        let atendimentosAbertoNaSemana = 0;
        let atendimentosAtendendoNaSemana = 0;
        let atendimentosFechadoNaSemana = 0;
        let atendimentosCanceladoNaSemana = 0;
        let atendimentosAbertoAtendendoPrioridadeBaixa = 0;
        let atendimentosAbertoAtendendoPrioridadeNormal = 0;
        let atendimentosAbertoAtendendoPrioridadeAlta = 0;
        let atendimentosAbertoAtendendoPrioridadeUrgente = 0;
        let tempoResolucaoChamadoMenorque1 = 0;
        let tempoResolucaoChamado1 = 0;
        let tempoResolucaoChamado2 = 0;
        let tempoResolucaoChamado3 = 0;
        let tempoResolucaoChamado4 = 0;
        let tempoResolucaoChamado5 = 0;
        let tempoResolucaoChamado6 = 0;
        let tempoResolucaoChamado7 = 0;
        let tempoResolucaoChamadoMaiorque7 = 0;
        let atendimentosPorLocalInterno = 0;
        let atendimentosPorLocalExterno = 0;
        let atendimentosPorLocal = [];
        let atendimentoAbertoStatus = 0;
        let atendimentoEmAtendimentoStatus = 0;
        let atendimentoFechadoStatus = 0;
        let atendimentoCanceladoStatus = 0;
        let arrayChamadosPorStatus = [];
        dataComecoSemana = dataComecoSemana + ' 00:00:01';
        dataFimSemana = dataFimSemana + ' 23:59:59';
        let dataComecoSemanaTS = retornaDataTimeStamp(dataComecoSemana);
        let dataFimSemanaTS = retornaDataTimeStamp(dataFimSemana);
        //como converter uma data de timestamp em data normal
        let date2 = new Date(1562016662685).toUTCString();
        let dataAtualTimeStamp = retornaDataTimeStamp(dataAtual);
        //
        //

        ativaDesativaLoadingCarregandoCOnfiguracoesGerais(dispatch, true);
        AsyncStorage.getItem('chaveUnica').then((chaveUnica2) => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            //

            firebase.database().ref(`${chaveUnica}/usuarios/`).orderByChild("uid").equalTo(firebase.auth().currentUser.uid).once("value").then(snapshot => {
                let keyUsuario = '';
                snapshot.forEach(childItem => {
                    keyUsuario = childItem.key;

                });
                //
                //
                firebase.database().ref(`${chaveUnica}/tarefas`).orderByChild("keyAtendente").equalTo(keyUsuario).on("value", snapshot => {
                    let arrayTarefas = [];
                    if (snapshot.val()) {
                        //
                        //
                        snapshot.forEach(childItem => {
                            arrayTarefas.push({
                                key: childItem.key,
                                tarefa: childItem.val().tarefa,
                                feito: childItem.val().feito,
                            });
                        });
                        enviaTarefaParaReducer(dispatch, arrayTarefas);
                        //
                    } else {
                        //
                        enviaTarefaParaReducer(dispatch, arrayTarefas);
                    }

                })
            })
            //envio periodo personalizado semana atual porque o padrao do relatório é da semana atual
            enviaPeriodoPersonalizado(dispatch, 'Hoje');
            enviaSemConexaoInternetDashBoard(dispatch, false);
            ativaDesativaLoadingTelaDashBoard(dispatch, true);
            // ativaDesativaLoadingRenderVersaoPremium(dispatch, true);
            let dataAtual = retornaDataAtual();
            const { currentUser } = firebase.auth();


            /*  firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild('dataAberturaTimeStamp')
                 .startAt(dataComecoSemanaTS).endAt(dataFimSemanaTS).once("value").then((snapshot) => {
                     if (snapshot.val()) { */
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                    'Accept': 'application/json',
                },

                data: {
                    chaveUnica: chaveUnica,
                    dataInicioConsulta: dataComecoSemanaTS,
                    dataFinalConsulta: dataFimSemanaTS,
                    rota: 'atendimentodetalhado'
                },
                url: 'gerarRelatoriosAtendimentos',
            };
            api(options).then((snapshot) => {


                snapshot = snapshot.data;

                if (snapshot !== null) {


                    let arrayOfKeys = Object.keys(snapshot).sort().reverse();
                    enviaAtendimentosDestaSemanaDashBoard(dispatch, arrayOfKeys.length);
                    let results = arrayOfKeys.map((key) => snapshot[key]);
                    for (let i = 0; i < results.length; i++) {
                        if (results[i].dataAbertura == dataAtual) {
                            atendimentosAbertoHoje++
                        }
                        //por status
                        if (results[i].status == 'Aberto') {
                            atendimentosAbertoNaSemana++
                            //por prioridade
                            if (results[i].prioridade == 'Baixa') {
                                atendimentosAbertoAtendendoPrioridadeBaixa++;
                            }
                            if (results[i].prioridade == 'Normal') {
                                atendimentosAbertoAtendendoPrioridadeNormal++;
                            }
                            if (results[i].prioridade == 'Alta') {
                                atendimentosAbertoAtendendoPrioridadeAlta++;
                            }
                            if (results[i].prioridade == 'Urgente') {
                                atendimentosAbertoAtendendoPrioridadeUrgente++;
                            }
                        }
                        if (results[i].status == 'Atendendo') {
                            atendimentosAtendendoNaSemana++
                        }
                        if (results[i].status == 'Fechado') {
                            atendimentosFechadoNaSemana++
                            if (results[i].periodoResolucaoChamado == '<1') {
                                tempoResolucaoChamadoMenorque1++
                            }
                            if (results[i].periodoResolucaoChamado == '1') {
                                tempoResolucaoChamado1++
                            }
                            if (results[i].periodoResolucaoChamado == '2') {
                                tempoResolucaoChamado2++
                            }
                            if (results[i].periodoResolucaoChamado == '3') {
                                tempoResolucaoChamado3++
                            }
                            if (results[i].periodoResolucaoChamado == '4') {
                                tempoResolucaoChamado4++
                            }
                            if (results[i].periodoResolucaoChamado == '5') {
                                tempoResolucaoChamado5++
                            }
                            if (results[i].periodoResolucaoChamado == '6') {
                                tempoResolucaoChamado6++
                            }
                            if (results[i].periodoResolucaoChamado == '7') {
                                tempoResolucaoChamado7++
                            }
                            if (results[i].periodoResolucaoChamado == '>7') {
                                tempoResolucaoChamadoMaiorque7++
                            }
                        }
                        if (results[i].status == 'Cancelado') {
                            atendimentosCanceladoNaSemana++
                        }
                        if (results[i].local == 'Interno') {
                            atendimentosPorLocalInterno++
                        }
                        if (results[i].local == 'Externo') {
                            atendimentosPorLocalExterno++
                        }

                    }
                    //envio chamados abertos hoje
                    enviaAtendimentosHojeDashBoard(dispatch, atendimentosAbertoHoje);


                    //implemento array para enviar os chamados por status
                    if (atendimentosAbertoNaSemana == 0) {
                        atendimentoAbertoStatus = 0;
                    } else {
                        atendimentoAbertoStatus = atendimentosAbertoNaSemana;

                    }

                    if (atendimentosAtendendoNaSemana == 0) {
                        atendimentoEmAtendimentoStatus = 0;
                    } else {
                        atendimentoEmAtendimentoStatus = atendimentosAtendendoNaSemana;

                    }
                    if (atendimentosFechadoNaSemana == 0) {
                        atendimentoFechadoStatus = 0;
                    } else {
                        atendimentoFechadoStatus = atendimentosFechadoNaSemana;
                    }
                    if (atendimentosCanceladoNaSemana == 0) {
                        atendimentoCanceladoStatus = 0;
                    } else {
                        atendimentoCanceladoStatus = atendimentosCanceladoNaSemana;

                    }

                    enviaAtendimentoAberto(dispatch, atendimentoAbertoStatus);
                    enviaAtendimentoCancelado(dispatch, atendimentoCanceladoStatus);
                    enviaAtendimentoFechado(dispatch, atendimentoFechadoStatus);
                    enviaAtendimentoEmAtendimento(dispatch, atendimentoEmAtendimentoStatus);


                    //envio chamados por prioridades
                    /*    let totalAtendimentosAbertoAtendendoPorPrioridade = atendimentosAbertoAtendendoPrioridadeBaixa +
                           atendimentosAbertoAtendendoPrioridadeNormal + atendimentosAbertoAtendendoPrioridadeAlta +
                           atendimentosAbertoAtendendoPrioridadeUrgente;
                       enviaTotalAtendimentosPorPRioridade(dispatch, totalAtendimentosAbertoAtendendoPorPrioridade) */
                    if (atendimentosAbertoAtendendoPrioridadeBaixa == 0) {
                        atendimentosAbertoAtendendoPrioridadeBaixa = 0.5
                    }
                    if (atendimentosAbertoAtendendoPrioridadeNormal == 0) {
                        atendimentosAbertoAtendendoPrioridadeNormal = 0.5
                    }
                    if (atendimentosAbertoAtendendoPrioridadeAlta == 0) {
                        atendimentosAbertoAtendendoPrioridadeAlta = 0.5
                    }
                    if (atendimentosAbertoAtendendoPrioridadeUrgente == 0) {
                        atendimentosAbertoAtendendoPrioridadeUrgente = 0.5
                    }

                    let atendimentosPorPrioridadeEnviaDashBoard = [
                        {
                            value: atendimentosAbertoAtendendoPrioridadeBaixa,
                            label: 'Baixa',
                            id: "Baixa",
                            color: "hsl(195, 100%, 50%)",
                            valorZerado: "0"
                        },
                        {
                            value: atendimentosAbertoAtendendoPrioridadeNormal,
                            label: 'Normal',
                            id: "Normal",
                            color: "hsl(0, 0%, 83%)",
                            valorZerado: "0"
                        },
                        { value: atendimentosAbertoAtendendoPrioridadeAlta, label: 'Alta', id: "Alta", color: "hsl(42, 94%, 60%)", valorZerado: "0" },
                        {
                            value: atendimentosAbertoAtendendoPrioridadeUrgente,
                            label: 'Urgente',
                            id: "Urgente",
                            color: "hsl(344, 94%, 51%)",
                            valorZerado: "0"
                        }];
                    enviaAtendimentosPorPrioridadeDashboard(dispatch, atendimentosPorPrioridadeEnviaDashBoard)

                    if (tempoResolucaoChamadoMenorque1 == 0) {
                        tempoResolucaoChamadoMenorque1 = 0.5
                    }
                    if (tempoResolucaoChamado1 == 0) {
                        tempoResolucaoChamado1 = 0.5
                    }
                    if (tempoResolucaoChamado2 == 0) {
                        tempoResolucaoChamado2 = 0.5
                    }
                    if (tempoResolucaoChamado3 == 0) {
                        tempoResolucaoChamado3 = 0.5
                    }
                    if (tempoResolucaoChamado4 == 0) {
                        tempoResolucaoChamado4 = 0.5
                    }
                    if (tempoResolucaoChamado5 == 0) {
                        tempoResolucaoChamado5 = 0.5
                    }
                    if (tempoResolucaoChamado6 == 0) {
                        tempoResolucaoChamado6 = 0.5
                    }
                    if (tempoResolucaoChamado7 == 0) {
                        tempoResolucaoChamado7 = 0.5
                    }
                    if (tempoResolucaoChamadoMaiorque7 == 0) {
                        tempoResolucaoChamadoMaiorque7 = 0.5
                    }
                    let atendimentosPorTempOResolucao = [
                        { value: tempoResolucaoChamadoMenorque1, label: '< 1 dia', id: "< 1 dia", color: "hsl(150, 100%, 50%)", valorZerado: "0" },
                        { value: tempoResolucaoChamado1, label: '1 dia', id: "1 dia", color: "hsl(0, 0%, 83%)", valorZerado: "0" },
                        { value: tempoResolucaoChamado2, label: '2 dias', id: "2 dias", color: "hsl(42, 94%, 60%)", valorZerado: "0" },
                        { value: tempoResolucaoChamado3, label: '3 dias', id: "3 dias", color: "hsl(344, 94%, 51%)", valorZerado: "0" },
                        { value: tempoResolucaoChamado4, label: '4 dias', id: "4 dias", color: "hsl(214, 41%, 78%)", valorZerado: "0" },
                        { value: tempoResolucaoChamado5, label: '5 dias', id: "5 dias", color: "hsl(195, 100%, 50%)", valorZerado: "0" },
                        { value: tempoResolucaoChamado6, label: '6 dias', id: "6 dias", color: "hsl(180, 100%, 50%)", valorZerado: "0" },
                        { value: tempoResolucaoChamado7, label: '7 dias', id: "7 dias", color: "hsl(25, 75%, 47%)", valorZerado: "0" },
                        { value: tempoResolucaoChamadoMaiorque7, label: '> 7 dias', id: "> 7 dias", color: "hsl(180, 100%, 27%)", valorZerado: "0" }];
                    enviaTempoResolucaoChamados(dispatch, atendimentosPorTempOResolucao);
                    //   enviaTotalAtendimentosPeriodoResolucao(dispatch, atendimentosFechadoNaSemana);
                    //atendimentos interno e externo
                    if (atendimentosPorLocalInterno == 0) {
                        atendimentosPorLocal.push({
                            value: 0.5, label: 'Interno', id: "Interno", color: "hsl(195, 100%, 50%)", valorZerado: "0"
                        })

                    } else {
                        atendimentosPorLocal.push({
                            value: atendimentosPorLocalInterno, label: 'Interno', id: "Interno", color: "hsl(195, 100%, 50%)", valorZerado: "0"
                        })
                    }
                    if (atendimentosPorLocalExterno == 0) {
                        atendimentosPorLocal.push({
                            value: 0.5, label: 'Externo', id: "Externo", color: "hsl(214, 41%, 78%)", valorZerado: "0"
                        })

                    } else {
                        atendimentosPorLocal.push({
                            value: atendimentosPorLocalExterno, label: 'Externo', id: "Externo", color: "hsl(214, 41%, 78%)", valorZerado: "0"
                        })
                    }

                    // let totalAtendimentosPorLocal = atendimentosPorLocalInterno + atendimentosPorLocalExterno;
                    // enviaTotalAtendimentosPorLocalDashboard(dispatch, totalAtendimentosPorLocal)
                    enviaAtendimentosPorLocalDashboard(dispatch, atendimentosPorLocal)
                    //envio total de clientes

                    //envio total de chamados

                    //
                    ativaDesativaLoadingCarregandoCOnfiguracoesGerais(dispatch, false);
                    ativaDesativaLoadingTelaDashBoard(dispatch, false);
                    ativaDesativaLoadingRefreshControol(dispatch, false);
                } else {
                    //sem informacoes
                    enviaAtendimentosDestaSemanaDashBoard(dispatch, 0);
                    enviaAtendimentosHojeDashBoard(dispatch, 0);
                    enviaAtendimentoAberto(dispatch, 0);
                    enviaAtendimentoCancelado(dispatch, 0);
                    enviaAtendimentoFechado(dispatch, 0);
                    enviaAtendimentoEmAtendimento(dispatch, 0);

                    let atendimentoPorPrioridadeZerado = [
                        { value: 0.5, label: 'Baixa', id: "Baixa", color: "hsl(195, 100%, 50%)", valorZerado: "0" },
                        { value: 0.5, label: 'Normal', id: "Normal", color: "hsl(0, 0%, 83%)", valorZerado: "0" },
                        { value: 0.5, label: 'Alta', id: "Alta", color: "hsl(42, 94%, 60%)", valorZerado: "0" },
                        { value: 0.5, label: 'Urgente', id: "Urgente", color: "hsl(344, 94%, 51%)", valorZerado: "0" }
                    ]


                    enviaAtendimentosPorPrioridadeDashboard(dispatch, atendimentoPorPrioridadeZerado);
                    //  enviaTotalAtendimentosPorPRioridade(dispatch, 0)
                    let atendimentoPorResolucaoZerado = [
                        { value: 0.5, label: '< 1 dia', id: "< 1 dia", color: "hsl(150, 100%, 50%)", valorZerado: "0" },
                        { value: 0.5, label: '1 dia', id: "1 dia", color: "hsl(0, 0%, 83%)", valorZerado: "0" },
                        { value: 0.5, label: '2 dias', id: "2 dias", color: "hsl(42, 94%, 60%)", valorZerado: "0" },
                        { value: 0.5, label: '3 dias', id: "3 dias", color: "hsl(344, 94%, 51%)", valorZerado: "0" },
                        { value: 0.5, label: '4 dias', id: "4 dias", color: "hsl(214, 41%, 78%)", valorZerado: "0" },
                        { value: 0.5, label: '5 dias', id: "5 dias", color: "hsl(195, 100%, 50%)", valorZerado: "0" },
                        { value: 0.5, label: '6 dias', id: "6 dias", color: "hsl(180, 100%, 50%)", valorZerado: "0" },
                        { value: 0.5, label: '7 dias', id: "7 dias", color: "hsl(25, 75%, 47%)", valorZerado: "0" },
                        { value: 0.5, label: '> 7 dias', id: "> 7 dias", color: "hsl(180, 100%, 27%)", valorZerado: "0" }]
                    enviaTempoResolucaoChamados(dispatch, atendimentoPorResolucaoZerado);
                    // enviaTotalAtendimentosPeriodoResolucao(dispatch, 0);

                    let atendimentoPorLocalZerado = [
                        { value: 0.5, label: 'Interno', id: "Interno", color: "hsl(195, 100%, 50%)", valorZerado: "0" },
                        { value: 0.5, label: 'Externo', id: "Externo", color: "hsl(214, 41%, 78%)", valorZerado: "0" }
                    ];
                    // enviaTotalAtendimentosPorLocalDashboard(dispatch, 0)
                    enviaAtendimentosPorLocalDashboard(dispatch, atendimentoPorLocalZerado)

                    ativaDesativaLoadingTelaDashBoard(dispatch, false);

                    ativaDesativaLoadingRefreshControol(dispatch, false);
                    //
                    ativaDesativaLoadingCarregandoCOnfiguracoesGerais(dispatch, false);
                }
            })

        })


    }
}
export const listaConfiguracoesDaEmpresa = (history, pathname) => {
    return dispatch => {
        let nome = '';
        let keyUsuario = '';
        let dataAtual = moment().toDate();
        dataAtual = moment(dataAtual).format('DD/MM/YYYY');
        AsyncStorage.getItem('chaveUnica').then((chaveUnica2) => {

            modificaLoadingConfiguracoesEmpresa(dispatch, true);
            if (chaveUnica2 == null) {

                firebase.database().ref(`usuariosALL/${firebase.auth().currentUser.uid}`).once('value').then((snapshot) => {
                    if (snapshot.val()) {
                        //
                        const dadosUsuario = _.first(_.values(snapshot.val()));
                        let chaveUnica = dadosUsuario.chaveUnica;
                        //se quem tiver logando for admin, pode logar normalmente, caso seja cliente vai para o else
                        if (dadosUsuario.admin == 1) {
                            localStorage.setItem(`chaveUnica`, JSON.stringify(chaveUnica));

                            firebase.database().ref(`${chaveUnica}/configuracao/whatsapp`).on("value", snapshot => {
                                let dadosEmpresa = [];
                                if (snapshot.val() == null) {
                                    enviaDadosWhatsappConfig(dispatch, [])
                                    //dispatch({ type: ALTERAVALORSWITCHUTILIZAVERSAOBETA, payload: false })
                                } else {

                                    let dadosEmpresa = [];
                                    snapshot.forEach(childItem => {
                                        dadosEmpresa.push({
                                            sessionclient: childItem.val().sessionclient,
                                            statusConnect: childItem.val().statusConnect,
                                            apiKey: childItem.val().apiKey,
                                            urlApi: childItem.val().urlApi,
                                            qrCode: childItem.val().qrCode === undefined ? '' : childItem.val().qrCode,
                                            utilizaVersaoBETA: childItem.val().utilizaVersaoBETA === undefined ? false : childItem.val().utilizaVersaoBETA,

                                            key: childItem.key
                                        })
                                    })
                                    // dispatch({ type: ALTERAVALORSWITCHUTILIZAVERSAOBETA, payload: dadosEmpresa[0].utilizaVersaoBETA })
                                    enviaDadosWhatsappConfig(dispatch, dadosEmpresa)

                                }
                            })
                            firebase.database().ref(`status_server`).on("value", snapshot => {
                                if (snapshot.val() !== null) {
                                    snapshot.forEach(childItem => {
                                        let isMaintenance = childItem.val().isMaintenance;
                                        let lastVersionAvailable = childItem.val().lastVersionAvailable;
                                        dispatch({ type: UPDTEISMAINTENANCE, payload: isMaintenance })
                                        dispatch({ type: UPDTELASTVERSIONAVAILABLE, payload: lastVersionAvailable })
                                        if (childItem.val().isLogout) {
                                            let keysToRemove = ["user", "login", "chaveUnica", "configuracoesInseridas8", "tabindex", "menuOpen", "keyAtendimento", "numeroAtendimento", "backgroundPrimeiro", "corBotaoPadrao", "enviaraopressionarenter", 'reproduzsomemespera', `reproduzsomematendimento`, 'verificacaousersetor'];
                                            for (const key of keysToRemove) {
                                                localStorage.removeItem(key);
                                            }
                                            firebase.auth().signOut();
                                            window.location.reload();
                                        }
                                    })
                                }
                            })
                            firebase.database().ref(`${chaveUnica}/empresa/`).on('value', snapshotPremium => {
                                let verificaModalVersaoPremiumEsgotou = 0;
                                let verificaQuantidadeAtendentesLiberado;
                                let arrayEmpresa = [];
                                let keyEmpresa = '';
                                let descricaoPlano = '';
                                let descricaoValidade;
                                let identificadorUnicoDaCompra;
                                let dataAtualVerificacaoAssinatura;
                                let dataValidadeAtualVerificacao;
                                let verificaAcabouAssinaturaPremium;
                                let licensawebouapp;

                                snapshotPremium.forEach(childItem => {
                                    keyEmpresa = childItem.key;
                                    arrayEmpresa.push({
                                        dataCompra: childItem.val().dataCompra,
                                        dataValidoAte: childItem.val().dataValidoAte,
                                        premium: childItem.val().premium,
                                        dataValidadeAtual: childItem.val().dataValidadeAtual === undefined ? "" : childItem.val().dataValidadeAtual,
                                        numeroPedidoCompra: childItem.val().numeroPedidoCompra,
                                        plano: childItem.val().plano,
                                        plataforma: childItem.val().plataforma,
                                        qtdAtendentesLiberados: childItem.val().qtdAtendentesLiberados,
                                        statusAssinatura: childItem.val().statusAssinatura,
                                        identificadorUnicoDaCompra: childItem.val().identificadorUnicoDaCompra,
                                        uidComprador: childItem.val().uidComprador,
                                        licensawebouapp: childItem.val().licensawebouapp == undefined || childItem.val().licensawebouapp == 0 ? 1 : childItem.val().licensawebouapp,

                                    })
                                })
                                modificaLicensawebouapp(dispatch, arrayEmpresa[0].licensawebouapp)
                                //
                                //verificacao se tem versao Premium
                                if (arrayEmpresa[0].dataValidoAte !== '') {

                                    //verifica se acabou assinatura premium ios quando tirou renovacao automatica
                                    if (arrayEmpresa[0].dataValidadeAtual !== undefined && arrayEmpresa[0].dataValidadeAtual !== '') {


                                        dataAtualVerificacaoAssinatura = moment(dataAtual, 'DD/MM/YYYY').format('YYYY-MM-DD');
                                        dataValidadeAtualVerificacao = moment(arrayEmpresa[0].dataValidadeAtual, 'DD/MM/YYYY').format('YYYY-MM-DD');
                                        if (arrayEmpresa[0].dataValidoAte === "12/12/2099") {
                                            dataValidadeAtualVerificacao = moment(dataValidadeAtualVerificacao, 'YYYY-MM-DD').add(2, 'days').format('YYYY-MM-DD');
                                        }

                                        verificaAcabouAssinaturaPremium = moment(dataValidadeAtualVerificacao).isBefore(dataAtualVerificacaoAssinatura);
                                    }
                                    //verifica se acabou versao premium comum
                                    let dataAtualVerificacao = moment(dataAtual, 'DD/MM/YYYY').format('YYYY-MM-DD');
                                    let dataValidoAteVerificacao = moment(arrayEmpresa[0].dataValidoAte, 'DD/MM/YYYY').format('YYYY-MM-DD');
                                    let verificaAcabouPremium = moment(dataValidoAteVerificacao).isBefore(dataAtualVerificacao);

                                    //acabou o periodo de versão premium
                                    if (verificaAcabouPremium) {
                                        //

                                        firebase.database().ref(`${chaveUnica}/empresa/${keyEmpresa}`).update({
                                            dataCompra: '',
                                            premium: 0,
                                            dataValidoAte: '',
                                            qtdAtendentesLiberados: 1,
                                            statusAssinatura: '',
                                            licensawebouapp: 1
                                        }).then(
                                            () => {
                                                verificaQuantidadeAtendentesLiberado = 1;
                                                enviaSeEhVersaoPremium(dispatch, 0);
                                                enviaCorTextStatusAssinatura(dispatch, '#00BFFF');
                                                enviaTextStatusAssinatura(dispatch, 'ASSINAR');
                                                enviaQtdAtendentesLiberadosPremium(dispatch, 1);
                                                enviaPremiumVersaoGartis(dispatch, false);
                                                //    modificaVisibleModalVersaoPremiumEsgotouLocal(dispatch, true);
                                                //  modificaVisibleModalAdiquiraVersaoPremium(dispatch, true);
                                                //  ativaDesativaLoadingRenderVersaoPremium(dispatch, false);
                                                //  Alert.alert('Versão Premium Esgotou!', 'Corra e renove seu plano Premium para desbloquear todos os recursos!');
                                            }
                                        )
                                    }
                                    //aqui é pra verificar se a licenca do cliente expirou quando a licenca é da apple e ele tirou a renovacao automatica
                                    else if (arrayEmpresa[0].statusAssinatura == 'tirourenovacaoautomatica' && verificaAcabouAssinaturaPremium == true) {
                                        firebase.database().ref(`${chaveUnica}/empresa/${keyEmpresa}`).update({
                                            dataCompra: '',
                                            premium: 0,
                                            dataValidoAte: '',
                                            qtdAtendentesLiberados: 1,
                                            plano: '',
                                            statusAssinatura: 'canceladaAposTirarRenovacaoAutomaticaIOS',
                                            dataValidadeAtual: ''
                                        }).then(
                                            () => {
                                                verificaQuantidadeAtendentesLiberado = 1;
                                                enviaSeEhVersaoPremium(dispatch, 0);
                                                enviaCorTextStatusAssinatura(dispatch, '#00BFFF');
                                                enviaTextStatusAssinatura(dispatch, 'ASSINAR');
                                                enviaQtdAtendentesLiberadosPremium(dispatch, 1);
                                                enviaPremiumVersaoGartis(dispatch, false);
                                                //    modificaVisibleModalVersaoPremiumEsgotouLocal(dispatch, true);
                                                //  modificaVisibleModalAdiquiraVersaoPremium(dispatch, true);
                                                //  ativaDesativaLoadingRenderVersaoPremium(dispatch, false);
                                                //  Alert.alert('Versão Premium Esgotou!', 'Corra e renove seu plano Premium para desbloquear todos os recursos!');
                                            }
                                        )
                                    }
                                    //ainda tem versão premium
                                    else {

                                        let diferencaDia = calculaDias2(arrayEmpresa[0].dataCompra, arrayEmpresa[0].dataValidoAte);

                                        if (diferencaDia <= 31 && arrayEmpresa[0].dataValidadeAtual === '') {
                                            //
                                            enviaSeEhVersaoPremium(dispatch, 1);
                                            //  ativaDesativaLoadingRenderVersaoPremium(dispatch, false);
                                            enviaDataValidadeVersaoPremium(dispatch, arrayEmpresa[0].dataValidoAte);
                                            enviaDataCompraVersaoPremium(dispatch, arrayEmpresa[0].dataCompra);
                                            enviaCorTextStatusAssinatura(dispatch, '#00FF7F')
                                            enviaTextStatusAssinatura(dispatch, 'ATIVA(Grátis)');
                                            enviaPremiumVersaoGartis(dispatch, true);
                                            enviaDataValidadeAtualPremium(dispatch, undefined);
                                            //verifico se o cliente tem qtd de atendentes liberados na empresa, porque se nao tiver esta na versao premium/gratis antiga
                                            if (arrayEmpresa[0].qtdAtendentesLiberados == undefined) {
                                                //nao atribuo nada
                                            } else {
                                                verificaQuantidadeAtendentesLiberado = arrayEmpresa[0].qtdAtendentesLiberados;
                                                enviaQtdAtendentesLiberadosPremium(dispatch, arrayEmpresa[0].qtdAtendentesLiberados);
                                            }
                                        } else if (diferencaDia <= 7 && arrayEmpresa[0].dataValidadeAtual === '') {
                                            //
                                            enviaSeEhVersaoPremium(dispatch, 1);
                                            //  ativaDesativaLoadingRenderVersaoPremium(dispatch, false);
                                            enviaDataValidadeVersaoPremium(dispatch, arrayEmpresa[0].dataValidoAte);
                                            enviaDataCompraVersaoPremium(dispatch, arrayEmpresa[0].dataCompra);
                                            enviaCorTextStatusAssinatura(dispatch, '#00FF7F')
                                            enviaTextStatusAssinatura(dispatch, 'ATIVA(Grátis)');
                                            enviaPremiumVersaoGartis(dispatch, true);
                                            enviaDataValidadeAtualPremium(dispatch, undefined);
                                            //verifico se o cliente tem qtd de atendentes liberados na empresa, porque se nao tiver esta na versao premium/gratis antiga
                                            if (arrayEmpresa[0].qtdAtendentesLiberados == undefined) {
                                                //nao atribuo nada
                                            } else {
                                                verificaQuantidadeAtendentesLiberado = arrayEmpresa[0].qtdAtendentesLiberados;
                                                enviaQtdAtendentesLiberadosPremium(dispatch, arrayEmpresa[0].qtdAtendentesLiberados);
                                            }
                                        } else {
                                            //
                                            enviaSeEhVersaoPremium(dispatch, 1);
                                            //  ativaDesativaLoadingRenderVersaoPremium(dispatch, false);
                                            enviaStatusAssinaturaPremium(dispatch, arrayEmpresa[0].statusAssinatura);
                                            enviaDataValidadeVersaoPremium(dispatch, arrayEmpresa[0].dataValidoAte);
                                            enviaCorTextStatusAssinatura(dispatch, '#00FF7F')
                                            enviaTextStatusAssinatura(dispatch, 'ATIVA');
                                            enviaPremiumVersaoGartis(dispatch, false);
                                            enviaDataCompraVersaoPremium(dispatch, arrayEmpresa[0].dataCompra);
                                            enviaIdentificadorUnicoDaCompra(dispatch, arrayEmpresa[0].identificadorUnicoDaCompra);
                                            //pega Dados Usuario
                                            if (arrayEmpresa[0].uidComprador !== undefined && arrayEmpresa[0].uidComprador !== null) {
                                                firebase.database().ref(`${chaveUnica}/usuarios/`).orderByChild("uid").equalTo(arrayEmpresa[0].uidComprador).once("value").then(snapshot => {
                                                    let arrayNomeQuemComprouLicenca = [];
                                                    snapshot.forEach(childItem => {
                                                        arrayNomeQuemComprouLicenca.push({
                                                            nomeAssinante: childItem.val().nome,
                                                            emailAssinante: childItem.val().email,

                                                        });

                                                    });
                                                    //
                                                    enviaEmailAssinante(dispatch, arrayNomeQuemComprouLicenca[0].emailAssinante)
                                                    enviaNomeAssinante(dispatch, arrayNomeQuemComprouLicenca[0].nomeAssinante)
                                                }).catch((err) => { /*  */
                                                })
                                            }
                                            if (arrayEmpresa[0].plano !== undefined && arrayEmpresa[0].plano !== null) {

                                                if (arrayEmpresa[0].plataforma == 'web') {
                                                    descricaoPlano = arrayEmpresa[0].plano;
                                                } else {
                                                    if (arrayEmpresa[0].plano === 'mensal1atendentes' || arrayEmpresa[0].plano === 'mensal1atendente') {
                                                        descricaoPlano = 'Plano Mensal 1 Atendente';
                                                    } else {
                                                        for (let i = 1; i <= 52; i++) {

                                                            if (`mensal${i}atendente` === arrayEmpresa[0].plano) {
                                                                descricaoPlano = `Plano Mensal ${i} Atendentes`;
                                                                break;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            if (arrayEmpresa[0].dataValidadeAtual !== '' && arrayEmpresa[0].dataValidadeAtual !== null) {
                                                moment.locale('pt-br', {
                                                    months: 'Janeiro_Fevereiro_Março_Abril_Maio_Junho_Julho_Agosto_Setembro_Outubro_Novembro_Dezembro'.split('_')
                                                })
                                                let check = moment(arrayEmpresa[0].dataValidadeAtual, 'DD/MM/YYYY')

                                                let month = check.format('MMMM');
                                                let year = check.format('YYYY');
                                                descricaoValidade = `${month}, ${year}`
                                            }

                                            enviaDataValidadeAtualPremium(dispatch, arrayEmpresa[0].dataValidadeAtual);
                                            enviaNumeroPedidoCompraPremium(dispatch, arrayEmpresa[0].numeroPedidoCompra);
                                            if (descricaoPlano == '') {
                                                enviaPlanoPremium(dispatch, arrayEmpresa[0].plano);

                                            } else {
                                                enviaPlanoPremium(dispatch, descricaoPlano);
                                            }
                                            enviaPlataformaPremium(dispatch, arrayEmpresa[0].plataforma);

                                            //verifico se o cliente tem qtd de atendentes liberados na empresa, porque se nao tiver esta na versao premium/gratis antiga
                                            if (arrayEmpresa[0].qtdAtendentesLiberados == undefined) {
                                                //nao atribuo nada
                                            } else {
                                                verificaQuantidadeAtendentesLiberado = arrayEmpresa[0].qtdAtendentesLiberados;
                                                enviaQtdAtendentesLiberadosPremium(dispatch, arrayEmpresa[0].qtdAtendentesLiberados);
                                            }


                                        }
                                    }
                                } else {
                                    //
                                    enviaSeEhVersaoPremium(dispatch, 0);
                                    //dados assinatura
                                    if (arrayEmpresa[0].plano !== undefined || arrayEmpresa[0].plano !== null) {
                                        if (arrayEmpresa[0].plano === 'mensal1atendentes' || arrayEmpresa[0].plano === 'mensal1atendente') {
                                            descricaoPlano = 'Plano Mensal 1 Atendente';
                                        } else {
                                            for (let i = 1; i <= 52; i++) {

                                                if (`mensal${i}atendente` === arrayEmpresa[0].plano) {
                                                    descricaoPlano = `Plano Mensal ${i} Atendentes`;
                                                    break;
                                                }
                                            }
                                        }
                                    }
                                    if (arrayEmpresa[0].dataValidadeAtual !== '' || arrayEmpresa[0].dataValidadeAtual !== null) {
                                        moment.locale('pt-br', {
                                            months: 'Janeiro_Fevereiro_Março_Abril_Maio_Junho_Julho_Agosto_Setembro_Outubro_Novembro_Dezembro'.split('_')
                                        })
                                        let check = moment(arrayEmpresa[0].dataValidadeAtual, 'DD/MM/YYYY')

                                        let month = check.format('MMMM');
                                        let year = check.format('YYYY');
                                        descricaoValidade = `${month}, ${year}`
                                    }
                                    enviaDataValidadeAtualPremium(dispatch, arrayEmpresa[0].dataValidadeAtual);
                                    enviaNumeroPedidoCompraPremium(dispatch, arrayEmpresa[0].numeroPedidoCompra);
                                    if (descricaoPlano == '') {
                                        enviaPlanoPremium(dispatch, arrayEmpresa[0].plano);

                                    } else {
                                        enviaPlanoPremium(dispatch, descricaoPlano);
                                    }
                                    enviaPlataformaPremium(dispatch, arrayEmpresa[0].plataforma);
                                    enviaQtdAtendentesLiberadosPremium(dispatch, 1);
                                    verificaQuantidadeAtendentesLiberado = 1;
                                    enviaStatusAssinaturaPremium(dispatch, arrayEmpresa[0].statusAssinatura);
                                    enviaPremiumVersaoGartis(dispatch, false);

                                    if (arrayEmpresa[0].statusAssinatura == 'suspensa' || arrayEmpresa[0].statusAssinatura == 'suspensaCartaoRecusado') {
                                        enviaCorTextStatusAssinatura(dispatch, '#F9BF3B')
                                        enviaTextStatusAssinatura(dispatch, 'SUSPENSA')
                                        AsyncStorage.getItem('telaNaoDesejaVer').then((dadosTela) => {

                                            if (dadosTela !== null) {
                                                const arrayTela = JSON.parse(dadosTela);

                                                //
                                                //
                                                const nomeTela = arrayTela[0].tela.trim();
                                                //
                                                if (nomeTela == 'suspensa') {
                                                    //
                                                } else {
                                                    //
                                                    modificaVisibleModalAssinaturaSuspensa(dispatch, true);
                                                }

                                            } else {
                                                modificaVisibleModalAssinaturaSuspensa(dispatch, true);
                                            }
                                        })

                                    } else if (arrayEmpresa[0].statusAssinatura == 'pausada') {
                                        enviaCorTextStatusAssinatura(dispatch, '#F9BF3B')
                                        enviaTextStatusAssinatura(dispatch, 'PAUSADA');
                                        AsyncStorage.getItem('telaNaoDesejaVer').then((dadosTela) => {

                                            if (dadosTela !== null) {
                                                const arrayTela = JSON.parse(dadosTela);

                                                //
                                                //
                                                const nomeTela = arrayTela[0].tela.trim();
                                                //
                                                if (nomeTela == 'pausada') {
                                                    //
                                                } else {
                                                    //
                                                    modificaVisibleModalAssinaturaPausada(dispatch, true);
                                                }

                                            } else {
                                                modificaVisibleModalAssinaturaPausada(dispatch, true);
                                            }
                                        })
                                    } else {
                                        /*  setTimeout(() => { */
                                        enviaCorTextStatusAssinatura(dispatch, '#00BFFF')
                                        enviaTextStatusAssinatura(dispatch, 'ASSINAR')
                                        // modificaVisibleModalAdiquiraVersaoPremium(dispatch, true);
                                        /*   }, 2000); */

                                    }
                                    //  ativaDesativaLoadingRenderVersaoPremium(dispatch, false);

                                    if (arrayEmpresa[0].uidComprador !== undefined && arrayEmpresa[0].uidComprador !== null) {
                                        firebase.database().ref(`${chaveUnica}/usuarios/`).orderByChild("uid").equalTo(arrayEmpresa[0].uidComprador).once("value").then(snapshot => {
                                            let arrayNomeQuemComprouLicenca = [];
                                            snapshot.forEach(childItem => {
                                                arrayNomeQuemComprouLicenca.push({
                                                    nomeAssinante: childItem.val().nome,
                                                    emailAssinante: childItem.val().email,

                                                });

                                            });
                                            //
                                            enviaEmailAssinante(dispatch, arrayNomeQuemComprouLicenca[0].emailAssinante)
                                            enviaNomeAssinante(dispatch, arrayNomeQuemComprouLicenca[0].nomeAssinante)
                                        }).catch((err) => {
                                        })
                                    }
                                    enviaIdentificadorUnicoDaCompra(dispatch, arrayEmpresa[0].identificadorUnicoDaCompra);

                                }

                                //aqui verifico a quantidade de atendentes liberados e bloqueio os restantes
                                //
                                //
                                if (verificaQuantidadeAtendentesLiberado !== undefined) {
                                    firebase.database().ref(`${chaveUnica}/usuarios/`).orderByChild('excluido').equalTo(null).once("value").then((snapshot) => {
                                        let contadorAtendentesLiberadosParaCadastro = 1;
                                        if (snapshot.val() == null) {
                                            //
                                        } else {
                                            //
                                            snapshot.forEach(childItem => {
                                                //  console.log(childItem.val().nome)
                                                if (contadorAtendentesLiberadosParaCadastro > verificaQuantidadeAtendentesLiberado) {
                                                    //update no atendente para bloquear

                                                    /*    if (childItem.val().uid === firebase.auth().currentUser.uid) {

                                                           firebase.auth().signOut();
                                                       } */
                                                    firebase.database().ref(`${chaveUnica}/usuarios/${childItem.key}`).update({ clienteBoqueadoDevidoLimiteExcedido: 1 }).then(
                                                        () => {
                                                            if (firebase.auth().currentUser && childItem.val().uid === firebase.auth().currentUser.uid) {

                                                                firebase.auth().signOut();
                                                            }
                                                        }
                                                    );
                                                    ;
                                                } else {
                                                    //update no atendente para liberar o bloqueio
                                                    //
                                                    firebase.database().ref(`${chaveUnica}/usuarios/${childItem.key}`).update({ clienteBoqueadoDevidoLimiteExcedido: 0 });
                                                }
                                                contadorAtendentesLiberadosParaCadastro++;

                                            })
                                        }
                                    })
                                }
                            })
                            firebase.database().ref(`${chaveUnica}/configuracao/cores`).on("value", snapshot => {
                                let backgroundPrimeiro;
                                let backgroundSegundo;
                                let backgroundMaisEscuro;
                                let coresLetraPadrao;
                                let coresLetraSecundario;
                                let corBotaoPadrao;
                                let letraBotaoPadrao;
                                let corSegundoBotaoPadrao;
                                let corLetraDescricaoDosCampos;
                                let corFundoMenu;
                                let corLetraMenu;
                                if (snapshot.val()) {
                                    snapshot.forEach((childItem) => {
                                        backgroundPrimeiro = childItem.val().backgroundPrimeiro;
                                        backgroundSegundo = childItem.val().backgroundSegundo;
                                        backgroundMaisEscuro = childItem.val().backgroundMaisEscuro;
                                        coresLetraPadrao = childItem.val().coresLetraPadrao;
                                        coresLetraSecundario = childItem.val().coresLetraSecundario;
                                        corBotaoPadrao = childItem.val().corBotaoPadrao;
                                        letraBotaoPadrao = childItem.val().letraBotaoPadrao;
                                        corSegundoBotaoPadrao = childItem.val().corSegundoBotaoPadrao;
                                        corLetraDescricaoDosCampos = childItem.val().corLetraDescricaoDosCampos;
                                        corFundoMenu = childItem.val().corFundoMenu;
                                        corLetraMenu = childItem.val().corLetraMenu;
                                    })
                                    /*   console.log(backgroundPrimeiro, backgroundSegundo, backgroundMaisEscuro, coresLetraPadrao, coresLetraSecundario,
                                          corBotaoPadrao, letraBotaoPadrao, corSegundoBotaoPadrao, corLetraDescricaoDosCampos, corFundoMenu, corLetraMenu); */


                                    dispatch({
                                        type: MODIFICACORFUNDOPRINCIPAL,
                                        payload: backgroundPrimeiro
                                    })
                                    dispatch({
                                        type: MODIFICACORFUNDOSECUNDARIO,
                                        payload: backgroundSegundo
                                    })
                                    dispatch({
                                        type: MODIFICACORFUNDOITENSSELECIONAVEIS,
                                        payload: backgroundMaisEscuro
                                    })
                                    dispatch({
                                        type: MODIFICACORFUNDOBOTAOPRINCIPAL,
                                        payload: corBotaoPadrao
                                    })
                                    dispatch({
                                        type: MODIFICACORFUNDOBOTAOSECUNDARIO,
                                        payload: corSegundoBotaoPadrao
                                    })
                                    dispatch({
                                        type: MODIFICACORLETRAPADRAO,
                                        payload: letraBotaoPadrao
                                    })
                                    dispatch({
                                        type: MODIFICACORLETRAPRINCIPAL,
                                        payload: coresLetraPadrao
                                    })

                                    dispatch({
                                        type: MODIFICACORLETRASECUNDARIA,
                                        payload: coresLetraSecundario
                                    })
                                    dispatch({
                                        type: MODIFICACORLETRADESCRICAODOSCAMPOS,
                                        payload: corLetraDescricaoDosCampos
                                    })
                                    dispatch({
                                        type: MODIFICACORFUNDOMENU,
                                        payload: corFundoMenu
                                    })
                                    dispatch({
                                        type: MODIFICACORLETRAMENU,
                                        payload: corLetraMenu
                                    })
                                    localStorage.setItem('backgroundPrimeiro', JSON.stringify(backgroundPrimeiro))
                                    localStorage.setItem('corBotaoPadrao', JSON.stringify(corBotaoPadrao))
                                    //ativaDesativaLoadingTelaNotificacao(dispatch, false);
                                } else {
                                }

                            })
                            firebase.database().ref(`${chaveUnica}/configuracao/chatOnline`).on("value", snapshot => {
                                if (snapshot.val()) {
                                    snapshot.forEach(childItem => {
                                        let fusoHorario = childItem.val().fusoHorario == undefined || childItem.val().fusoHorario == '' || childItem.val().length === 0 ? "America/Sao_Paulo" : childItem.val().fusoHorario;
                                        modificaTimeZoneChatOnline(dispatch, fusoHorario);
                                    })
                                } else {
                                    modificaTimeZoneChatOnline(dispatch, "America/Sao_Paulo");
                                }

                            })
                            firebase.database().ref(`${chaveUnica}/configuracao/atendimento`).on('value', snapshot => {
                                const dadosConfiguracaoAtendimento = [];
                                snapshot.forEach(childItem => {
                                    let atribuirAtendenteObrigatorio = childItem.val().atribuirAtendenteObrigatorio == undefined ? false : childItem.val().atribuirAtendenteObrigatorio;
                                    let atendenteVeApenasProprioChamado = childItem.val().atendenteVeApenasProprioChamado == undefined ? false : childItem.val().atendenteVeApenasProprioChamado;
                                    let agruparAtendenteSetor = childItem.val().agruparAtendenteSetor == undefined ? false : childItem.val().agruparAtendenteSetor;
                                    let obrigatorioInformarResolucaoChamado = childItem.val().obrigatorioInformarResolucaoChamado == undefined ? false : childItem.val().obrigatorioInformarResolucaoChamado;
                                    let criarChamadoProblemaEstatico = childItem.val().criarChamadoProblemaEstatico == undefined ? false : childItem.val().criarChamadoProblemaEstatico;
                                    let mensagemFinalizaAtendimentoWpp = childItem.val().mensagemFinalizacaoChamado === undefined ? `Agradecemos o seu contato!\n\n*Pedimos por gentileza que não responde essa mensagem.*\n\nPois esse atendimento foi concluido e qualquer nova mensagem abrirá um novo atendimento.\n\nMas lembramos que qualquer dúvida estamos sempre á disposição. Conte com a gente.\n\nAtendimento Finalizado!\nProtocolo de atendimento *#{{protocolo}}*` : childItem.val().mensagemFinalizacaoChamado;

                                    let mostrarNomeAtendenteWpp = childItem.val().mostrarNomeAtendenteMensagem == undefined ? true : childItem.val().mostrarNomeAtendenteMensagem;
                                    let desativarAssistenteVirtualBotWpp = childItem.val().desativarAssistenteVirtualWpp == undefined ? false : childItem.val().desativarAssistenteVirtualWpp;
                                    let solicitarNomeClientePrimeirocontato = childItem.val().solicitarNomeClientePrimeirocontato == undefined ? true : childItem.val().solicitarNomeClientePrimeirocontato;
                                    let atendenteVeApenasProprioHistoricoChamado = childItem.val().atendenteVeApenasProprioHistorico == undefined ? false : childItem.val().atendenteVeApenasProprioHistorico;
                                    let obrigatorioInformarMotivoCancelamento = childItem.val().obrigatorioInformarMotivoCancelamento == undefined ? true : childItem.val().obrigatorioInformarMotivoCancelamento;

                                    let enviarMensagemTransfSetorWpp = childItem.val().enviarMensagemTransfSetorWpp == undefined ? true : childItem.val().enviarMensagemTransfSetorWpp;
                                    modificaEnviarMensagemTransSetorWpp(dispatch, enviarMensagemTransfSetorWpp);
                                    modificaSolicitarNomeClientePrimeirocontato(dispatch, solicitarNomeClientePrimeirocontato);
                                    /*   let fusoHorario = childItem.val().fusoHorario == undefined ? "America/Sao_Paulo" : childItem.val().fusoHorario;
                                      modificaTimeZoneChatOnline(dispatch, fusoHorario); */
                                    modificaMensagemFinalizaAtendimentoWpp(dispatch, mensagemFinalizaAtendimentoWpp);
                                    verificaSeEhObrigatorioATribuirAtendente(dispatch, atribuirAtendenteObrigatorio);
                                    verificaAgruparAtendenteSetor(dispatch, agruparAtendenteSetor);
                                    modificaObrigatorioInformarResolucaoChamadoDashboard(dispatch, obrigatorioInformarResolucaoChamado);
                                    verificaAtendenteVeApenasProprioChamado(dispatch, atendenteVeApenasProprioChamado);
                                    verificaCriarChamadoProblemaEstatico(dispatch, criarChamadoProblemaEstatico);
                                    verificaCriarChamadoProblemaEstaticoAtendimento(dispatch, criarChamadoProblemaEstatico);
                                    modificaInformarMotivoCancelamento(dispatch, obrigatorioInformarMotivoCancelamento);
                                    modificamostrarNomeAtendenteWpp(dispatch, mostrarNomeAtendenteWpp);
                                    modificadesativarAssistenteVirtualBotWpp(dispatch, desativarAssistenteVirtualBotWpp);
                                    modificaAtendenteVeApenasProprioHistoricoChamado(dispatch, atendenteVeApenasProprioHistoricoChamado);

                                })
                                //

                            })
                            firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild('uid').equalTo(firebase.auth().currentUser.uid).limitToFirst(1).on('value', (snapshot) => {
                                if (snapshot.val()) {
                                    snapshot.forEach((childItem) => {
                                        keyUsuario = childItem.key;
                                        nome = childItem.val().nome;
                                        let adminVisualizaApenasProprioSetor = childItem.val().usuarioAdmVisualizaApenasChamadosDoSetorVinculado === undefined ? false : childItem.val().usuarioAdmVisualizaApenasChamadosDoSetorVinculado;
                                        let isAdmin = childItem.val().StringAdmin === 'Admin' ? true : false
                                        enviaNomeParaReducer(dispatch, childItem.val().nome);
                                        enviaAdminParaReducer(dispatch, childItem.val().StringAdmin === 'Admin' ? true : false);
                                        enviaEmailParaReducer(dispatch, childItem.val().email);
                                        enviaKeyUsuarioPerfil(dispatch, childItem.key);
                                        enviaAdmVisualizaApenasChamadosDoSetorVinculado(dispatch, childItem.val().usuarioAdmVisualizaApenasChamadosDoSetorVinculado === undefined ? false : childItem.val().usuarioAdmVisualizaApenasChamadosDoSetorVinculado);
                                        // listaOsChamadosEmEsperaLocal(dispatch, chaveUnica, atendenteVeApenasProprioChamado, agruparAtendenteSetor, isAdmin, adminVisualizaApenasProprioSetor);


                                        if (childItem.val().urlImagemNuvem == undefined || childItem.val().urlImagemNuvem == null || childItem.val().urlImagemNuvem == '') {
                                            if (childItem.val().nome.length > 0) {
                                                const primeiraLetra = childItem.val().nome.substring(0, 1).trim().toLowerCase();
                                                /*   */
                                                verificaSeEhAlphabeto(primeiraLetra, dispatch);

                                            } else {
                                                const fotoURI = 'https://firebasestorage.googleapis.com/v0/b/dark-4b307.appspot.com/o/imagemPadraoDark%2Fuser.png?alt=media&token=5494861d-8431-480d-ac62-442c8b2c39af'
                                                enviaImagemPerfil(dispatch, fotoURI)
                                            }
                                        } else {
                                            enviaImagemPerfil(dispatch, childItem.val().urlImagemNuvem)
                                        }
                                    })


                                } else {
                                }
                            })

                            /*  firebase.database().ref(`${chaveUnica}/usuarios/`).orderByChild('uid').equalTo(firebase.auth().currentUser.uid).once("value").then(snapshot => {
                                 if (snapshot.val()) {
                                     let qtdNotificacoes = 0;
                                     snapshot.forEach((childItem) => {
                                         keyUsuario = childItem.key;
                                     })

                                     firebase
                                         .database()
                                         .ref(`${chaveUnica}/notificacoes/`)
                                         .orderByChild("keyAtendente")
                                         .equalTo(keyUsuario)
                                         .limitToLast(1)
                                         .on('child_added', function (childSnapshot, prevChildName) {
                                             if (snapshot.val()) {
                                                 if (qtdNotificacoes == 0) {

                                                 } else {
                                                     const dadosNotif = childSnapshot.val();

                                                     if (dadosNotif.viaChat === "true") {

                                                     } else {
                                                     }
                                                 }
                                                 qtdNotificacoes = 1;
                                             }
                                         })
                                     firebase
                                         .database()
                                         .ref(`${chaveUnica}/notificacoes/`)
                                         .orderByChild("keyAtendente")
                                         .equalTo(keyUsuario)
                                         .limitToLast(15)
                                         .on("value", snapshot => {
                                             if (snapshot.val() == null) {
                                                 enviaNotificacoesParaReducer(dispatch, []);
                                                 enviaTotalNotificacoesParaReducer(dispatch, 0);
                                             } else {

                                                 let aux = 0;
                                                 let notificacoes = [];
                                                 let notificacoesNaoLidas = 0;
                                                 snapshot.forEach(childItem => {
                                                     notificacoes.push({
                                                         key: childItem.key,
                                                         title: childItem.val().title,
                                                         body: childItem.val().body,
                                                         keyChamado: childItem.val().keyChamado,
                                                         numeroChamado: childItem.val().numeroChamado,
                                                         lido: childItem.val().lido,
                                                         clicked: childItem.val().clicked,
                                                         dataAtual: childItem.val().dataAtual,
                                                         chaveUnica: chaveUnica,
                                                         keyUsuario,
                                                         viaChat: childItem.val().viaChat,
                                                     });


                                                     if (childItem.val().lido == "false") {
                                                         notificacoesNaoLidas++
                                                     }
                                                 })

                                                 enviaNotificacoesParaReducer(dispatch, notificacoes.reverse());
                                                 enviaTotalNotificacoesParaReducer(dispatch, notificacoesNaoLidas);
                                             }
                                         })


                                 } else {
                                 }
                             }) */

                            firebase.database().ref(`${chaveUnica}/configuracao/logotipo`).on("value", snapshot2 => {
                                let uriLogoTipo = [];
                                if (snapshot2.val()) {
                                    snapshot2.forEach(childItem => {
                                        uriLogoTipo.push({
                                            uri: childItem.val().uri,
                                        })
                                    })
                                    dispatch({
                                        type: MODIFICALOGOTIPOTELACONFIGURACAO,
                                        payload: uriLogoTipo[0].uri
                                    })
                                    // ativaDesativaLoadingConfiguracaoAtendimento(dispatch, false);
                                    //ativaDesativaLoadingTelaNotificacao(dispatch, false);
                                } else {
                                    dispatch({
                                        type: MODIFICALOGOTIPOTELACONFIGURACAO,
                                        payload: 'semURI'
                                    })
                                    //ativaDesativaLoadingConfiguracaoAtendimento(dispatch, false);
                                }

                            })
                            firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild('uid').equalTo(firebase.auth().currentUser.uid).limitToFirst(1).on('value', (snapshot) => {
                                if (snapshot.val()) {
                                    let arrayPermissoesAtendente = {};
                                    snapshot.forEach((childItem) => {
                                        arrayPermissoesAtendente['key'] = childItem.key;
                                        arrayPermissoesAtendente['contaPrincipal'] = (childItem.val().contaPrincipal == undefined) ? true : childItem.val().contaPrincipal;
                                        arrayPermissoesAtendente['permissaoAcessoRelatorios'] = (childItem.val().permissaoAcessoRelatorios == undefined) ? true : childItem.val().permissaoAcessoRelatorios;
                                        arrayPermissoesAtendente['permissaoAdministrador'] = (childItem.val().permissaoAdministrador == undefined) ? true : childItem.val().permissaoAdministrador;
                                        arrayPermissoesAtendente['permissaoAlterarCadastrarAtendente'] = (childItem.val().permissaoAlterarCadastrarAtendente == undefined) ? true : childItem.val().permissaoAlterarCadastrarAtendente;
                                        arrayPermissoesAtendente['permissaoAlterarCadastrarAvisos'] = (childItem.val().permissaoAlterarCadastrarAvisos == undefined) ? true : childItem.val().permissaoAlterarCadastrarAvisos;
                                        arrayPermissoesAtendente['permissaoAlterarCadastrarCliente'] = (childItem.val().permissaoAlterarCadastrarCliente == undefined) ? true : childItem.val().permissaoAlterarCadastrarCliente;
                                        arrayPermissoesAtendente['permissaoAlterarCadastrarSetores'] = (childItem.val().permissaoAlterarCadastrarSetores == undefined) ? true : childItem.val().permissaoAlterarCadastrarSetores;

                                        arrayPermissoesAtendente['permissaoCancelarAtendimento'] = (childItem.val().permissaoCancelarAtendimentoNovoCadastro == undefined) ? true : childItem.val().permissaoCancelarAtendimentoNovoCadastro;
                                        /*
                                         */
                                        enviaSeAtendenteEhAdministrador(dispatch, arrayPermissoesAtendente.permissaoAdministrador)
                                        enviaPermissoesAcessarTelasAtendente(dispatch, arrayPermissoesAtendente);
                                        dispatch({ type: ALTERAINFORMACAOCONTAPRINCIPALATENDENTE, payload: arrayPermissoesAtendente.contaPrincipal });
                                        modificaLoadingConfiguracoesEmpresa(dispatch, false);
                                    })

                                } else {
                                    modificaLoadingConfiguracoesEmpresa(dispatch, false);
                                }
                            })
                            // listaChamadosEmAbertoeAtendendoChatOnlineLocal(dispatch, chaveUnica);
                            listaChamadosEmAbertoeAtendendoChatOnlineLocalModified(dispatch, chaveUnica);
                            // if (!IsSearchFromHasura) {
                            //     dispatch(listaClientesTelaConsultaClientesAntesRemoved(''));
                            //     console.log("listando clientes")
                            // }

                            dispatch(listaAtendentesTelaConsultaAtendenteAntesRemoved(''))
                            dispatch(listaSetorSectionedTelaConsultaSetorAntesRemoved(''))
                            if (pathname === '/' || pathname === '/company') {
                                history.push("/dashboard");
                            } else {
                                history.push(pathname);
                            }
                            // dispatch(listaRespostasPadroesAntesRemoved(''))

                            firebase.database().ref(`${chaveUnica}/configuracao/atendimento`).once("value").then((snapshot) => {
                                snapshot.forEach(childItem => {
                                    let atendenteVeApenasProprioChamado = childItem.val().atendenteVeApenasProprioChamado == undefined ? false : childItem.val().atendenteVeApenasProprioChamado;
                                    let agruparAtendenteSetor = childItem.val().agruparAtendenteSetor == undefined ? false : childItem.val().agruparAtendenteSetor;
                                    firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild('uid').equalTo(firebase.auth().currentUser.uid).limitToFirst(1).once("value").then((snapshot) => {
                                        if (snapshot.val()) {
                                            snapshot.forEach((childItem) => {
                                                //  keyUsuario = childItem.key;
                                                //   nome = childItem.val().nome;
                                                let adminVisualizaApenasProprioSetor = childItem.val().usuarioAdmVisualizaApenasChamadosDoSetorVinculado === undefined ? false : childItem.val().usuarioAdmVisualizaApenasChamadosDoSetorVinculado;
                                                let isAdmin = childItem.val().StringAdmin === 'Admin' ? true : false

                                                listaOsChamadosEmEsperaLocalModified(dispatch, chaveUnica, atendenteVeApenasProprioChamado, agruparAtendenteSetor, isAdmin, adminVisualizaApenasProprioSetor);
                                            })
                                        }
                                    })
                                })
                            })
                            const messaging = firebase.messaging();
                            Notification.requestPermission().then((permission) => {
                                if (permission === 'granted') {
                                    return messaging.getToken()
                                }
                                return '';
                            }).then(token => {
                                /*
                                 */
                                firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild('uid').equalTo(firebase.auth().currentUser.uid).once("value").then((snapshot) => {
                                    let key = Object.keys(snapshot.val()).toString();
                                    let dadosUsuarioBanco = [];
                                    snapshot.forEach((childItem) => {
                                        dadosUsuarioBanco.push({
                                            key: childItem.key,
                                            fcmTokenWEB: childItem.val().fcmTokenWEB,
                                        })
                                    })
                                    /*
                                       */
                                    if (dadosUsuarioBanco[0].fcmTokenWEB == token) {
                                        //
                                    } else {
                                        firebase.database().ref(`${chaveUnica}/usuarios/${key}`).update({ fcmTokenWEB: token })

                                    }

                                })
                            }).catch((err) => {
                                console.log('catch token', err);

                            })
                            messaging.onMessage((payload) => {

                                /*  const sound = new Howl({
                                     src: [soundnotification],
                                     autoplay: true
                                 });

                                 sound.play(); */


                                if (payload.data.title.includes('Resposta no Chat') && window.location.pathname.includes('atendimentoonline')) {

                                } else {
                                    const toastrOptions = {
                                        timeOut: 5000, // by setting to 0 it will prevent the auto close
                                        icon: (<i className="icon-sino" style={{ color: '#000', fontSize: 22 }} />), // You can add any component you want but note that the width and height are 70px)
                                        onShowComplete: () => {
                                        },
                                        onHideComplete: () => {
                                        },
                                        onCloseButtonClick: () => {
                                        },
                                        onToastrClick: () => {
                                            /*   firebase.database().ref(`${chaveUnica}/usuarios/`).orderByChild('uid').equalTo(firebase.auth().currentUser.uid).once("value").then(snapshot => {
                                                  let keyUsuario = Object.keys(snapshot.val()).toString();
                                                  firebase.database().ref(`${chaveUnica}/usuarios/${keyUsuario}/notificacoes/`).orderByChild("title").equalTo(payload.data.title).once("value").then(snapshot2 => {
                                                      let keyNotif = Object.keys(snapshot2.val()).toString();
                                                      firebase.database().ref(`${chaveUnica}/usuarios/${keyUsuario}/notificacoes/${keyNotif}`).update({ lido: "true" });
                                                  })
                                              }) */
                                            firebase.database().ref(`${chaveUnica}/usuarios/`).orderByChild('uid').equalTo(firebase.auth().currentUser.uid).once("value").then(snapshot => {
                                                let keyUsuario = Object.keys(snapshot.val()).toString();
                                                let filtro = `${keyUsuario}${payload.data.title}`

                                                firebase.database().ref(`${chaveUnica}/notificacoes/`).orderByChild("keyAtendenteTitle").equalTo(filtro).once("value").then(snapshot2 => {

                                                    let keyNotif = Object.keys(snapshot2.val()).toString();

                                                    firebase.database().ref(`${chaveUnica}/notificacoes/${keyNotif}`).update({ lido: "true" });
                                                })
                                            })
                                            if (payload.data.viaChat == 'true') {
                                                history.push('/atendimentoonline');
                                                dispatch({ type: MODIFICAKEYATENDIMENTOEMTENDIMENTOONLINE, payload: payload.data.keyChamado })
                                            } else {
                                                let numeroAtendimento = parseInt(payload.data.numeroChamado);
                                                dispatch({ type: ALTERANUMEROATENDIMENTO, payload: numeroAtendimento });
                                                dispatch({ type: ALTERAKEYCHAMADO, payload: payload.data.keyChamado });
                                                dispatch({ type: MODIFICANOMEROTANOHEADER, payload: `Atendimento #${numeroAtendimento}` })
                                                history.push('/visualizaatendimento')
                                            }
                                        },
                                        showCloseButton: true, // false by default
                                        closeOnToastrClick: true, // false by default, this will close the toastr when user clicks on it
                                        /*   component: ( // this option will give you a func 'remove' as props
                                            <MyCustomComponent myProp="myValue">
                                              <span>Hello, World!</span>
                                            </MyCustomComponent>
                                          ) */
                                    }
                                    //
                                    toastr.light(payload.data.title, payload.data.body, toastrOptions)
                                }
                            })
                            //  verificaSeUserTemCodigoInternoEalteraSetorNewCodInternoExport(dispatch)
                        } else {
                            modificaLoadingConfiguracoesEmpresa(dispatch, false);
                            // toastr.error('Error', 'Essa conta é uma conta cliente, por favor baixe o aplicativo dropdesk cliente.');
                            //firebase.auth().signOut();
                            //desativaLoadingBotaoLogarTelaLogin(dispatch);
                        }
                    } else {
                    }
                })
            } else {

                const chaveUnica = chaveUnica2.replace(/"/g, "");

                firebase.database().ref(`${chaveUnica}/configuracao/whatsapp`).on("value", snapshot => {
                    let dadosEmpresa = [];
                    if (snapshot.val() == null) {
                        enviaDadosWhatsappConfig(dispatch, [])
                        // dispatch({ type: ALTERAVALORSWITCHUTILIZAVERSAOBETA, payload: false })
                    } else {


                        snapshot.forEach(childItem => {
                            dadosEmpresa.push({
                                sessionclient: childItem.val().sessionclient,
                                statusConnect: childItem.val().statusConnect,
                                apiKey: childItem.val().apiKey,
                                urlApi: childItem.val().urlApi,
                                qrCode: childItem.val().qrCode === undefined ? '' : childItem.val().qrCode,
                                utilizaVersaoBETA: childItem.val().utilizaVersaoBETA === undefined ? false : childItem.val().utilizaVersaoBETA,
                                key: childItem.key
                            })
                        })


                        //  dispatch({ type: ALTERAVALORSWITCHUTILIZAVERSAOBETA, payload: dadosEmpresa[0].utilizaVersaoBETA })
                        enviaDadosWhatsappConfig(dispatch, dadosEmpresa)

                    }
                })
                firebase.database().ref(`status_server`).on("value", snapshot => {
                    if (snapshot.val() !== null) {
                        snapshot.forEach(childItem => {
                            let isMaintenance = childItem.val().isMaintenance;
                            let lastVersionAvailable = childItem.val().lastVersionAvailable;
                            dispatch({ type: UPDTEISMAINTENANCE, payload: isMaintenance })
                            dispatch({ type: UPDTELASTVERSIONAVAILABLE, payload: lastVersionAvailable })
                            if (childItem.val().isLogout) {
                                let keysToRemove = ["user", "login", "chaveUnica", "configuracoesInseridas8", "tabindex", "menuOpen", "keyAtendimento", "numeroAtendimento", "backgroundPrimeiro", "corBotaoPadrao", "enviaraopressionarenter", 'reproduzsomemespera', `reproduzsomematendimento`, 'verificacaousersetor'];
                                for (const key of keysToRemove) {
                                    localStorage.removeItem(key);
                                }
                                firebase.auth().signOut();
                                window.location.reload();
                            }
                        })
                    }
                })
                // const { currentUser } = firebase.auth();
                firebase.database().ref(`${chaveUnica}/empresa/`).on('value', snapshotPremium => {
                    let verificaModalVersaoPremiumEsgotou = 0;
                    let verificaQuantidadeAtendentesLiberado;
                    let arrayEmpresa = [];
                    let keyEmpresa = '';
                    let descricaoPlano = '';
                    let descricaoValidade;
                    let identificadorUnicoDaCompra;
                    let dataAtualVerificacaoAssinatura;
                    let dataValidadeAtualVerificacao;
                    let verificaAcabouAssinaturaPremium;

                    snapshotPremium.forEach(childItem => {
                        keyEmpresa = childItem.key;
                        arrayEmpresa.push({
                            dataCompra: childItem.val().dataCompra,
                            dataValidoAte: childItem.val().dataValidoAte,
                            premium: childItem.val().premium,
                            dataValidadeAtual: childItem.val().dataValidadeAtual === undefined ? "" : childItem.val().dataValidadeAtual,
                            numeroPedidoCompra: childItem.val().numeroPedidoCompra,
                            plano: childItem.val().plano,
                            plataforma: childItem.val().plataforma,
                            qtdAtendentesLiberados: childItem.val().qtdAtendentesLiberados,
                            statusAssinatura: childItem.val().statusAssinatura,
                            identificadorUnicoDaCompra: childItem.val().identificadorUnicoDaCompra,
                            uidComprador: childItem.val().uidComprador,
                            licensawebouapp: childItem.val().licensawebouapp == undefined || childItem.val().licensawebouapp == 0 ? 1 : childItem.val().licensawebouapp,
                        })
                    })
                    modificaLicensawebouapp(dispatch, arrayEmpresa[0].licensawebouapp)
                    //verificacao se tem versao Premium
                    if (arrayEmpresa[0].dataValidoAte !== '') {

                        //verifica se acabou assinatura premium ios quando tirou renovacao automatica
                        if (arrayEmpresa[0].dataValidadeAtual !== undefined && arrayEmpresa[0].dataValidadeAtual !== '') {
                            dataAtualVerificacaoAssinatura = moment(dataAtual, 'DD/MM/YYYY').format('YYYY-MM-DD');
                            dataValidadeAtualVerificacao = moment(arrayEmpresa[0].dataValidadeAtual, 'DD/MM/YYYY').format('YYYY-MM-DD');
                            if (arrayEmpresa[0].dataValidoAte === "12/12/2099") {
                                dataValidadeAtualVerificacao = moment(dataValidadeAtualVerificacao, 'YYYY-MM-DD').add(2, 'days').format('YYYY-MM-DD');
                            }
                            verificaAcabouAssinaturaPremium = moment(dataValidadeAtualVerificacao).isBefore(dataAtualVerificacaoAssinatura);
                        }
                        //verifica se acabou versao premium comum
                        let dataAtualVerificacao = moment(dataAtual, 'DD/MM/YYYY').format('YYYY-MM-DD');
                        let dataValidoAteVerificacao = moment(arrayEmpresa[0].dataValidoAte, 'DD/MM/YYYY').format('YYYY-MM-DD');
                        let verificaAcabouPremium = moment(dataValidoAteVerificacao).isBefore(dataAtualVerificacao);
                        //
                        //acabou o periodo de versão premium
                        if (verificaAcabouPremium) {
                            //

                            firebase.database().ref(`${chaveUnica}/empresa/${keyEmpresa}`).update({
                                dataCompra: '',
                                premium: 0,
                                dataValidoAte: '',
                                qtdAtendentesLiberados: 1,
                                statusAssinatura: '',
                                licensawebouapp: 1
                            }).then(
                                () => {
                                    verificaQuantidadeAtendentesLiberado = 1;
                                    enviaSeEhVersaoPremium(dispatch, 0);
                                    enviaCorTextStatusAssinatura(dispatch, '#00BFFF');
                                    enviaTextStatusAssinatura(dispatch, 'ASSINAR');
                                    enviaQtdAtendentesLiberadosPremium(dispatch, 1);
                                    enviaPremiumVersaoGartis(dispatch, false);
                                    //    modificaVisibleModalVersaoPremiumEsgotouLocal(dispatch, true);
                                    //  modificaVisibleModalAdiquiraVersaoPremium(dispatch, true);
                                    //  ativaDesativaLoadingRenderVersaoPremium(dispatch, false);
                                    //  Alert.alert('Versão Premium Esgotou!', 'Corra e renove seu plano Premium para desbloquear todos os recursos!');
                                }
                            )
                        }
                        //aqui é pra verificar se a licenca do cliente expirou quando a licenca é da apple e ele tirou a renovacao automatica
                        else if (arrayEmpresa[0].statusAssinatura == 'tirourenovacaoautomatica' && verificaAcabouAssinaturaPremium == true) {
                            firebase.database().ref(`${chaveUnica}/empresa/${keyEmpresa}`).update({
                                dataCompra: '',
                                premium: 0,
                                dataValidoAte: '',
                                qtdAtendentesLiberados: 1,
                                plano: '',
                                statusAssinatura: 'canceladaAposTirarRenovacaoAutomaticaIOS',
                                dataValidadeAtual: ''
                            }).then(
                                () => {
                                    verificaQuantidadeAtendentesLiberado = 1;
                                    enviaSeEhVersaoPremium(dispatch, 0);
                                    enviaCorTextStatusAssinatura(dispatch, '#00BFFF');
                                    enviaTextStatusAssinatura(dispatch, 'ASSINAR');
                                    enviaQtdAtendentesLiberadosPremium(dispatch, 1);
                                    enviaPremiumVersaoGartis(dispatch, false);
                                    //    modificaVisibleModalVersaoPremiumEsgotouLocal(dispatch, true);
                                    //  modificaVisibleModalAdiquiraVersaoPremium(dispatch, true);
                                    //  ativaDesativaLoadingRenderVersaoPremium(dispatch, false);
                                    //  Alert.alert('Versão Premium Esgotou!', 'Corra e renove seu plano Premium para desbloquear todos os recursos!');
                                }
                            )
                        }
                        //ainda tem versão premium
                        else {

                            let diferencaDia = calculaDias2(arrayEmpresa[0].dataCompra, arrayEmpresa[0].dataValidoAte);
                            //

                            if (diferencaDia <= 31 && arrayEmpresa[0].dataValidadeAtual === '') {
                                //
                                enviaSeEhVersaoPremium(dispatch, 1);
                                //  ativaDesativaLoadingRenderVersaoPremium(dispatch, false);
                                enviaDataValidadeVersaoPremium(dispatch, arrayEmpresa[0].dataValidoAte);
                                enviaDataCompraVersaoPremium(dispatch, arrayEmpresa[0].dataCompra);
                                enviaCorTextStatusAssinatura(dispatch, '#00FF7F')
                                enviaTextStatusAssinatura(dispatch, 'ATIVA(Grátis)');
                                enviaPremiumVersaoGartis(dispatch, true);
                                enviaDataValidadeAtualPremium(dispatch, undefined);
                                //verifico se o cliente tem qtd de atendentes liberados na empresa, porque se nao tiver esta na versao premium/gratis antiga
                                if (arrayEmpresa[0].qtdAtendentesLiberados == undefined) {
                                    //nao atribuo nada
                                } else {

                                    verificaQuantidadeAtendentesLiberado = arrayEmpresa[0].qtdAtendentesLiberados;
                                    enviaQtdAtendentesLiberadosPremium(dispatch, arrayEmpresa[0].qtdAtendentesLiberados);
                                }
                            } else if (diferencaDia <= 7 && arrayEmpresa[0].dataValidadeAtual === '') {
                                //
                                enviaSeEhVersaoPremium(dispatch, 1);
                                //  ativaDesativaLoadingRenderVersaoPremium(dispatch, false);
                                enviaDataValidadeVersaoPremium(dispatch, arrayEmpresa[0].dataValidoAte);
                                enviaDataCompraVersaoPremium(dispatch, arrayEmpresa[0].dataCompra);
                                enviaCorTextStatusAssinatura(dispatch, '#00FF7F')
                                enviaTextStatusAssinatura(dispatch, 'ATIVA(Grátis)');
                                enviaPremiumVersaoGartis(dispatch, true);
                                enviaDataValidadeAtualPremium(dispatch, undefined);
                                //verifico se o cliente tem qtd de atendentes liberados na empresa, porque se nao tiver esta na versao premium/gratis antiga
                                if (arrayEmpresa[0].qtdAtendentesLiberados == undefined) {
                                    //nao atribuo nada
                                } else {

                                    verificaQuantidadeAtendentesLiberado = arrayEmpresa[0].qtdAtendentesLiberados;
                                    enviaQtdAtendentesLiberadosPremium(dispatch, arrayEmpresa[0].qtdAtendentesLiberados);
                                }
                            } else {
                                //;
                                enviaSeEhVersaoPremium(dispatch, 1);
                                //  ativaDesativaLoadingRenderVersaoPremium(dispatch, false);
                                enviaStatusAssinaturaPremium(dispatch, arrayEmpresa[0].statusAssinatura);
                                enviaDataValidadeVersaoPremium(dispatch, arrayEmpresa[0].dataValidoAte);
                                enviaCorTextStatusAssinatura(dispatch, '#00FF7F')
                                enviaTextStatusAssinatura(dispatch, 'ATIVA');
                                enviaPremiumVersaoGartis(dispatch, false);
                                enviaDataCompraVersaoPremium(dispatch, arrayEmpresa[0].dataCompra);
                                enviaIdentificadorUnicoDaCompra(dispatch, arrayEmpresa[0].identificadorUnicoDaCompra);
                                //pega Dados Usuario
                                if (arrayEmpresa[0].uidComprador !== undefined && arrayEmpresa[0].uidComprador !== null) {
                                    firebase.database().ref(`${chaveUnica}/usuarios/`).orderByChild("uid").equalTo(arrayEmpresa[0].uidComprador).once("value").then(snapshot => {
                                        let arrayNomeQuemComprouLicenca = [];
                                        snapshot.forEach(childItem => {
                                            arrayNomeQuemComprouLicenca.push({
                                                nomeAssinante: childItem.val().nome,
                                                emailAssinante: childItem.val().email,

                                            });

                                        });
                                        //
                                        enviaEmailAssinante(dispatch, arrayNomeQuemComprouLicenca[0].emailAssinante)
                                        enviaNomeAssinante(dispatch, arrayNomeQuemComprouLicenca[0].nomeAssinante)
                                    }).catch((err) => {/*    */
                                    })
                                }
                                if (arrayEmpresa[0].plano !== undefined && arrayEmpresa[0].plano !== null) {
                                    if (arrayEmpresa[0].plataforma == 'web') {
                                        descricaoPlano = arrayEmpresa[0].plano;
                                    } else {
                                        if (arrayEmpresa[0].plano === 'mensal1atendentes' || arrayEmpresa[0].plano === 'mensal1atendente') {
                                            descricaoPlano = 'Plano Mensal 1 Atendente';
                                        } else {
                                            for (let i = 1; i <= 52; i++) {

                                                if (`mensal${i}atendente` === arrayEmpresa[0].plano) {
                                                    descricaoPlano = `Plano Mensal ${i} Atendentes`;
                                                    break;
                                                }
                                            }
                                        }
                                    }
                                }
                                if (arrayEmpresa[0].dataValidadeAtual !== '' && arrayEmpresa[0].dataValidadeAtual !== null) {
                                    moment.locale('pt-br', {
                                        months: 'Janeiro_Fevereiro_Março_Abril_Maio_Junho_Julho_Agosto_Setembro_Outubro_Novembro_Dezembro'.split('_')
                                    })
                                    let check = moment(arrayEmpresa[0].dataValidadeAtual, 'DD/MM/YYYY')

                                    let month = check.format('MMMM');
                                    let year = check.format('YYYY');
                                    descricaoValidade = `${month}, ${year}`
                                }
                                enviaDataValidadeAtualPremium(dispatch, arrayEmpresa[0].dataValidadeAtual);
                                enviaNumeroPedidoCompraPremium(dispatch, arrayEmpresa[0].numeroPedidoCompra);
                                if (descricaoPlano == '') {
                                    enviaPlanoPremium(dispatch, arrayEmpresa[0].plano);

                                } else {
                                    enviaPlanoPremium(dispatch, descricaoPlano);
                                }
                                enviaPlataformaPremium(dispatch, arrayEmpresa[0].plataforma);

                                //verifico se o cliente tem qtd de atendentes liberados na empresa, porque se nao tiver esta na versao premium/gratis antiga
                                if (arrayEmpresa[0].qtdAtendentesLiberados == undefined) {
                                    //nao atribuo nada
                                } else {
                                    verificaQuantidadeAtendentesLiberado = arrayEmpresa[0].qtdAtendentesLiberados;
                                    enviaQtdAtendentesLiberadosPremium(dispatch, arrayEmpresa[0].qtdAtendentesLiberados);
                                }


                            }
                        }
                    } else {
                        //
                        enviaSeEhVersaoPremium(dispatch, 0);
                        //dados assinatura
                        if (arrayEmpresa[0].plano !== undefined || arrayEmpresa[0].plano !== null) {
                            if (arrayEmpresa[0].plano === 'mensal1atendentes' || arrayEmpresa[0].plano === 'mensal1atendente') {
                                descricaoPlano = 'Plano Mensal 1 Atendente';
                            } else {
                                for (let i = 1; i <= 52; i++) {

                                    if (`mensal${i}atendente` === arrayEmpresa[0].plano) {
                                        descricaoPlano = `Plano Mensal ${i} Atendentes`;
                                        break;
                                    }
                                }
                            }
                        }
                        if (arrayEmpresa[0].dataValidadeAtual !== '' || arrayEmpresa[0].dataValidadeAtual !== null) {
                            moment.locale('pt-br', {
                                months: 'Janeiro_Fevereiro_Março_Abril_Maio_Junho_Julho_Agosto_Setembro_Outubro_Novembro_Dezembro'.split('_')
                            })
                            let check = moment(arrayEmpresa[0].dataValidadeAtual, 'DD/MM/YYYY')

                            let month = check.format('MMMM');
                            let year = check.format('YYYY');
                            descricaoValidade = `${month}, ${year}`
                        }
                        enviaDataValidadeAtualPremium(dispatch, arrayEmpresa[0].dataValidadeAtual);
                        enviaNumeroPedidoCompraPremium(dispatch, arrayEmpresa[0].numeroPedidoCompra);
                        if (descricaoPlano == '') {
                            enviaPlanoPremium(dispatch, arrayEmpresa[0].plano);

                        } else {
                            enviaPlanoPremium(dispatch, descricaoPlano);
                        }
                        enviaPlataformaPremium(dispatch, arrayEmpresa[0].plataforma);
                        enviaQtdAtendentesLiberadosPremium(dispatch, 1);
                        verificaQuantidadeAtendentesLiberado = 1;
                        enviaStatusAssinaturaPremium(dispatch, arrayEmpresa[0].statusAssinatura);
                        enviaPremiumVersaoGartis(dispatch, false);

                        if (arrayEmpresa[0].statusAssinatura == 'suspensa' || arrayEmpresa[0].statusAssinatura == 'suspensaCartaoRecusado') {
                            enviaCorTextStatusAssinatura(dispatch, '#F9BF3B')
                            enviaTextStatusAssinatura(dispatch, 'SUSPENSA')
                            AsyncStorage.getItem('telaNaoDesejaVer').then((dadosTela) => {

                                if (dadosTela !== null) {
                                    const arrayTela = JSON.parse(dadosTela);

                                    //
                                    //
                                    const nomeTela = arrayTela[0].tela.trim();
                                    //
                                    if (nomeTela == 'suspensa') {
                                        //
                                    } else {
                                        //
                                        modificaVisibleModalAssinaturaSuspensa(dispatch, true);
                                    }

                                } else {
                                    modificaVisibleModalAssinaturaSuspensa(dispatch, true);
                                }
                            })

                        } else if (arrayEmpresa[0].statusAssinatura == 'pausada') {
                            enviaCorTextStatusAssinatura(dispatch, '#F9BF3B')
                            enviaTextStatusAssinatura(dispatch, 'PAUSADA');
                            AsyncStorage.getItem('telaNaoDesejaVer').then((dadosTela) => {

                                if (dadosTela !== null) {
                                    const arrayTela = JSON.parse(dadosTela);

                                    //;
                                    //
                                    const nomeTela = arrayTela[0].tela.trim();
                                    //
                                    if (nomeTela == 'pausada') {
                                        //
                                    } else {
                                        //
                                        modificaVisibleModalAssinaturaPausada(dispatch, true);
                                    }

                                } else {
                                    modificaVisibleModalAssinaturaPausada(dispatch, true);
                                }
                            })
                        } else {
                            /*  setTimeout(() => { */
                            enviaCorTextStatusAssinatura(dispatch, '#00BFFF')
                            enviaTextStatusAssinatura(dispatch, 'ASSINAR')
                            // modificaVisibleModalAdiquiraVersaoPremium(dispatch, true);
                            /*   }, 2000); */

                        }
                        //  ativaDesativaLoadingRenderVersaoPremium(dispatch, false);

                        if (arrayEmpresa[0].uidComprador !== undefined && arrayEmpresa[0].uidComprador !== null) {
                            firebase.database().ref(`${chaveUnica}/usuarios/`).orderByChild("uid").equalTo(arrayEmpresa[0].uidComprador).once("value").then(snapshot => {
                                let arrayNomeQuemComprouLicenca = [];
                                snapshot.forEach(childItem => {
                                    arrayNomeQuemComprouLicenca.push({
                                        nomeAssinante: childItem.val().nome,
                                        emailAssinante: childItem.val().email,

                                    });

                                });
                                //
                                enviaEmailAssinante(dispatch, arrayNomeQuemComprouLicenca[0].emailAssinante)
                                enviaNomeAssinante(dispatch, arrayNomeQuemComprouLicenca[0].nomeAssinante)
                            }).catch((err) => {
                            })
                        }
                        enviaIdentificadorUnicoDaCompra(dispatch, arrayEmpresa[0].identificadorUnicoDaCompra);

                    }

                    //aqui verifico a quantidade de atendentes liberados e bloqueio os restantes
                    //
                    //
                    if (verificaQuantidadeAtendentesLiberado !== undefined) {
                        firebase.database().ref(`${chaveUnica}/usuarios/`).orderByChild('excluido').equalTo(null).once("value").then((snapshot) => {
                            let contadorAtendentesLiberadosParaCadastro = 1;
                            if (snapshot.val() == null) {
                                //
                            } else {
                                //
                                snapshot.forEach(childItem => {
                                    // console.log(childItem.val().nome)
                                    if (contadorAtendentesLiberadosParaCadastro > verificaQuantidadeAtendentesLiberado) {
                                        //update no atendente para bloquear
                                        //
                                        firebase.database().ref(`${chaveUnica}/usuarios/${childItem.key}`).update({ clienteBoqueadoDevidoLimiteExcedido: 1 }).then(
                                            () => {
                                                if (firebase.auth().currentUser && childItem.val().uid === firebase.auth().currentUser.uid) {

                                                    firebase.auth().signOut();
                                                }
                                            }
                                        );
                                    } else {
                                        //update no atendente para liberar o bloqueio
                                        //
                                        firebase.database().ref(`${chaveUnica}/usuarios/${childItem.key}`).update({ clienteBoqueadoDevidoLimiteExcedido: 0 });
                                    }
                                    contadorAtendentesLiberadosParaCadastro++;

                                })
                            }
                        })
                    }
                })
                firebase.database().ref(`${chaveUnica}/configuracao/chatOnline`).on("value", snapshot => {
                    if (snapshot.val()) {
                        snapshot.forEach(childItem => {
                            let fusoHorario = childItem.val().fusoHorario == undefined || childItem.val().fusoHorario == '' || childItem.val().length === 0 ? "America/Sao_Paulo" : childItem.val().fusoHorario;
                            modificaTimeZoneChatOnline(dispatch, fusoHorario);
                        })
                    } else {
                        modificaTimeZoneChatOnline(dispatch, "America/Sao_Paulo");
                    }

                })
                firebase.database().ref(`${chaveUnica}/configuracao/cores`).on("value", snapshot => {
                    let backgroundPrimeiro;
                    let backgroundSegundo;
                    let backgroundMaisEscuro;
                    let coresLetraPadrao;
                    let coresLetraSecundario;
                    let corBotaoPadrao;
                    let letraBotaoPadrao;
                    let corSegundoBotaoPadrao;
                    let corLetraDescricaoDosCampos;
                    let corFundoMenu;
                    let corLetraMenu;
                    if (snapshot.val()) {
                        snapshot.forEach((childItem) => {
                            backgroundPrimeiro = childItem.val().backgroundPrimeiro;
                            backgroundSegundo = childItem.val().backgroundSegundo;
                            backgroundMaisEscuro = childItem.val().backgroundMaisEscuro;
                            coresLetraPadrao = childItem.val().coresLetraPadrao;
                            coresLetraSecundario = childItem.val().coresLetraSecundario;
                            corBotaoPadrao = childItem.val().corBotaoPadrao;
                            letraBotaoPadrao = childItem.val().letraBotaoPadrao;
                            corSegundoBotaoPadrao = childItem.val().corSegundoBotaoPadrao;
                            corLetraDescricaoDosCampos = childItem.val().corLetraDescricaoDosCampos;
                            corFundoMenu = childItem.val().corFundoMenu;
                            corLetraMenu = childItem.val().corLetraMenu;
                        })
                        /*  console.log(backgroundPrimeiro, backgroundSegundo, backgroundMaisEscuro, coresLetraPadrao, coresLetraSecundario,
                             corBotaoPadrao, letraBotaoPadrao, corSegundoBotaoPadrao, corLetraDescricaoDosCampos, corFundoMenu, corLetraMenu); */


                        dispatch({
                            type: MODIFICACORFUNDOPRINCIPAL,
                            payload: backgroundPrimeiro
                        })
                        dispatch({
                            type: MODIFICACORFUNDOSECUNDARIO,
                            payload: backgroundSegundo
                        })
                        dispatch({
                            type: MODIFICACORFUNDOITENSSELECIONAVEIS,
                            payload: backgroundMaisEscuro
                        })
                        dispatch({
                            type: MODIFICACORFUNDOBOTAOPRINCIPAL,
                            payload: corBotaoPadrao
                        })
                        dispatch({
                            type: MODIFICACORFUNDOBOTAOSECUNDARIO,
                            payload: corSegundoBotaoPadrao
                        })
                        dispatch({
                            type: MODIFICACORLETRAPADRAO,
                            payload: letraBotaoPadrao
                        })
                        dispatch({
                            type: MODIFICACORLETRAPRINCIPAL,
                            payload: coresLetraPadrao
                        })

                        dispatch({
                            type: MODIFICACORLETRASECUNDARIA,
                            payload: coresLetraSecundario
                        })
                        dispatch({
                            type: MODIFICACORLETRADESCRICAODOSCAMPOS,
                            payload: corLetraDescricaoDosCampos
                        })
                        dispatch({
                            type: MODIFICACORFUNDOMENU,
                            payload: corFundoMenu
                        })
                        dispatch({
                            type: MODIFICACORLETRAMENU,
                            payload: corLetraMenu
                        })
                        localStorage.setItem('backgroundPrimeiro', JSON.stringify(backgroundPrimeiro))
                        localStorage.setItem('corBotaoPadrao', JSON.stringify(corBotaoPadrao))
                        //ativaDesativaLoadingTelaNotificacao(dispatch, false);
                    } else {
                    }

                })
                /*    firebase.database().ref(`${chaveUnica}/configuracao/atendimento`).on('value', snapshot => {
                       const dadosConfiguracaoAtendimento = [];
                       snapshot.forEach(childItem => {
                           let atribuirAtendenteObrigatorio = childItem.val().atribuirAtendenteObrigatorio == undefined ? false : childItem.val().atribuirAtendenteObrigatorio;
                           let atendenteVeApenasProprioChamado = childItem.val().atendenteVeApenasProprioChamado == undefined ? false : childItem.val().atendenteVeApenasProprioChamado;
                           let agruparAtendenteSetor = childItem.val().agruparAtendenteSetor == undefined ? false : childItem.val().agruparAtendenteSetor;
                           let criarChamadoProblemaEstatico = childItem.val().criarChamadoProblemaEstatico == undefined ? false : childItem.val().criarChamadoProblemaEstatico;
                           let mensagemFinalizaAtendimentoWpp = childItem.val().mensagemFinalizacaoChamado === undefined ? `Agradecemos o seu contato!\n\n*Pedimos por gentileza que não responde essa mensagem.*\n\nPois esse atendimento foi concluido e qualquer nova mensagem abrirá um novo atendimento.\n\nMas lembramos que qualquer dúvida estamos sempre á disposição. Conte com a gente.\n\nAtendimento Finalizado!\nProtocolo de atendimento *#{{protocolo}}*` : childItem.val().mensagemFinalizacaoChamado;
                           modificaMensagemFinalizaAtendimentoWpp(dispatch, mensagemFinalizaAtendimentoWpp);
                           verificaSeEhObrigatorioATribuirAtendente(dispatch, atribuirAtendenteObrigatorio);
                           verificaAgruparAtendenteSetor(dispatch, agruparAtendenteSetor);
                           verificaAtendenteVeApenasProprioChamado(dispatch, atendenteVeApenasProprioChamado);
                           verificaCriarChamadoProblemaEstatico(dispatch, criarChamadoProblemaEstatico);

                       })


                   }) */
                firebase.database().ref(`${chaveUnica}/configuracao/atendimento`).on('value', snapshot => {
                    const dadosConfiguracaoAtendimento = [];
                    snapshot.forEach(childItem => {
                        let atribuirAtendenteObrigatorio = childItem.val().atribuirAtendenteObrigatorio == undefined ? false : childItem.val().atribuirAtendenteObrigatorio;
                        let atendenteVeApenasProprioChamado = childItem.val().atendenteVeApenasProprioChamado == undefined ? false : childItem.val().atendenteVeApenasProprioChamado;
                        let agruparAtendenteSetor = childItem.val().agruparAtendenteSetor == undefined ? false : childItem.val().agruparAtendenteSetor;
                        let criarChamadoProblemaEstatico = childItem.val().criarChamadoProblemaEstatico == undefined ? false : childItem.val().criarChamadoProblemaEstatico;
                        let mensagemFinalizaAtendimentoWpp = childItem.val().mensagemFinalizacaoChamado === undefined ? `Agradecemos o seu contato!\n\n*Pedimos por gentileza que não responde essa mensagem.*\n\nPois esse atendimento foi concluido e qualquer nova mensagem abrirá um novo atendimento.\n\nMas lembramos que qualquer dúvida estamos sempre á disposição. Conte com a gente.\n\nAtendimento Finalizado!\nProtocolo de atendimento *#{{protocolo}}*` : childItem.val().mensagemFinalizacaoChamado;
                        let obrigatorioInformarResolucaoChamado = childItem.val().obrigatorioInformarResolucaoChamado == undefined ? false : childItem.val().obrigatorioInformarResolucaoChamado;
                        let mostrarNomeAtendenteWpp = childItem.val().mostrarNomeAtendenteMensagem == undefined ? true : childItem.val().mostrarNomeAtendenteMensagem;
                        let desativarAssistenteVirtualBotWpp = childItem.val().desativarAssistenteVirtualWpp == undefined ? false : childItem.val().desativarAssistenteVirtualWpp;
                        let obrigatorioInformarMotivoCancelamento = childItem.val().obrigatorioInformarMotivoCancelamento == undefined ? true : childItem.val().obrigatorioInformarMotivoCancelamento;
                        let solicitarNomeClientePrimeirocontato = childItem.val().solicitarNomeClientePrimeirocontato == undefined ? true : childItem.val().solicitarNomeClientePrimeirocontato;
                        let atendenteVeApenasProprioHistoricoChamado = childItem.val().atendenteVeApenasProprioHistorico == undefined ? false : childItem.val().atendenteVeApenasProprioHistorico;
                        let enviarMensagemTransfSetorWpp = childItem.val().enviarMensagemTransfSetorWpp == undefined ? true : childItem.val().enviarMensagemTransfSetorWpp;

                        modificaEnviarMensagemTransSetorWpp(dispatch, enviarMensagemTransfSetorWpp);
                        modificaSolicitarNomeClientePrimeirocontato(dispatch, solicitarNomeClientePrimeirocontato);
                        modificaMensagemFinalizaAtendimentoWpp(dispatch, mensagemFinalizaAtendimentoWpp);
                        verificaSeEhObrigatorioATribuirAtendente(dispatch, atribuirAtendenteObrigatorio);
                        verificaAgruparAtendenteSetor(dispatch, agruparAtendenteSetor);
                        verificaAtendenteVeApenasProprioChamado(dispatch, atendenteVeApenasProprioChamado);
                        verificaCriarChamadoProblemaEstatico(dispatch, criarChamadoProblemaEstatico);
                        verificaCriarChamadoProblemaEstaticoAtendimento(dispatch, criarChamadoProblemaEstatico);
                        modificaObrigatorioInformarResolucaoChamadoDashboard(dispatch, obrigatorioInformarResolucaoChamado);
                        modificaInformarMotivoCancelamento(dispatch, obrigatorioInformarMotivoCancelamento);
                        modificamostrarNomeAtendenteWpp(dispatch, mostrarNomeAtendenteWpp);
                        modificadesativarAssistenteVirtualBotWpp(dispatch, desativarAssistenteVirtualBotWpp);
                        modificaAtendenteVeApenasProprioHistoricoChamado(dispatch, atendenteVeApenasProprioHistoricoChamado);


                    })
                    //

                })
                firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild('uid').equalTo(firebase.auth().currentUser.uid).limitToFirst(1).on('value', (snapshot) => {
                    if (snapshot.val()) {
                        snapshot.forEach((childItem) => {
                            keyUsuario = childItem.key;
                            nome = childItem.val().nome;
                            let adminVisualizaApenasProprioSetor = childItem.val().usuarioAdmVisualizaApenasChamadosDoSetorVinculado === undefined ? false : childItem.val().usuarioAdmVisualizaApenasChamadosDoSetorVinculado;
                            let isAdmin = childItem.val().StringAdmin === 'Admin' ? true : false
                            enviaNomeParaReducer(dispatch, childItem.val().nome);
                            enviaAdminParaReducer(dispatch, childItem.val().StringAdmin === 'Admin' ? true : false);
                            enviaEmailParaReducer(dispatch, childItem.val().email);
                            enviaKeyUsuarioPerfil(dispatch, childItem.key);
                            enviaAdmVisualizaApenasChamadosDoSetorVinculado(dispatch, childItem.val().usuarioAdmVisualizaApenasChamadosDoSetorVinculado === undefined ? false : childItem.val().usuarioAdmVisualizaApenasChamadosDoSetorVinculado);
                            //listaOsChamadosEmEsperaLocal(dispatch, chaveUnica, atendenteVeApenasProprioChamado, agruparAtendenteSetor, isAdmin, adminVisualizaApenasProprioSetor);

                            if (childItem.val().urlImagemNuvem == undefined || childItem.val().urlImagemNuvem == null || childItem.val().urlImagemNuvem == '') {
                                if (childItem.val().nome.length > 0) {
                                    const primeiraLetra = childItem.val().nome.substring(0, 1).trim().toLowerCase();
                                    /*   */
                                    verificaSeEhAlphabeto(primeiraLetra, dispatch);

                                } else {
                                    const fotoURI = 'https://firebasestorage.googleapis.com/v0/b/dark-4b307.appspot.com/o/imagemPadraoDark%2Fuser.png?alt=media&token=5494861d-8431-480d-ac62-442c8b2c39af'
                                    enviaImagemPerfil(dispatch, fotoURI)
                                }
                            } else {
                                enviaImagemPerfil(dispatch, childItem.val().urlImagemNuvem)
                            }
                        })


                    } else {
                    }
                })

                /*  firebase.database().ref(`${chaveUnica}/usuarios/`).orderByChild('uid').equalTo(firebase.auth().currentUser.uid).once("value").then(snapshot => {
                     if (snapshot.val()) {
                         let qtdNotificacoes = 0;
                         snapshot.forEach((childItem) => {
                             keyUsuario = childItem.key;
                         })
                         firebase
                             .database()
                             .ref(`${chaveUnica}/notificacoes/`)
                             .orderByChild("keyAtendente")
                             .equalTo(keyUsuario)
                             .limitToLast(1)
                             .on('child_added', function (childSnapshot, prevChildName) {

                                 if (snapshot.val()) {
                                     if (qtdNotificacoes == 0) {

                                     } else {
                                         const dadosNotif = childSnapshot.val();
                                         if (dadosNotif.viaChat === "true") {

                                         } else {
                                         }
                                     }
                                     qtdNotificacoes = 1;
                                 }
                             })
                         firebase
                             .database()
                             .ref(`${chaveUnica}/notificacoes/`)
                             .orderByChild("keyAtendente")
                             .equalTo(keyUsuario)
                             .limitToLast(15)
                             .on("value", snapshot => {
                                 if (snapshot.val() == null) {
                                     enviaNotificacoesParaReducer(dispatch, []);
                                     enviaTotalNotificacoesParaReducer(dispatch, 0);
                                 } else {


                                     let aux = 0;
                                     let notificacoes = [];
                                     let notificacoesNaoLidas = 0;
                                     snapshot.forEach(childItem => {
                                         notificacoes.push({
                                             key: childItem.key,
                                             title: childItem.val().title,
                                             body: childItem.val().body,
                                             keyChamado: childItem.val().keyChamado,
                                             numeroChamado: childItem.val().numeroChamado,
                                             lido: childItem.val().lido,
                                             clicked: childItem.val().clicked,
                                             dataAtual: childItem.val().dataAtual,
                                             chaveUnica: chaveUnica,
                                             keyUsuario,
                                             viaChat: childItem.val().viaChat,
                                         });


                                         if (childItem.val().lido == "false") {
                                             notificacoesNaoLidas++
                                         }
                                     })

                                     enviaNotificacoesParaReducer(dispatch, notificacoes.reverse());
                                     enviaTotalNotificacoesParaReducer(dispatch, notificacoesNaoLidas);
                                 }
                             })

                     } else {
                     }
                 }) */
                firebase.database().ref(`${chaveUnica}/configuracao/logotipo`).on("value", snapshot2 => {
                    let uriLogoTipo = [];
                    if (snapshot2.val()) {
                        snapshot2.forEach(childItem => {
                            uriLogoTipo.push({
                                uri: childItem.val().uri,
                            })
                        })
                        dispatch({
                            type: MODIFICALOGOTIPOTELACONFIGURACAO,
                            payload: uriLogoTipo[0].uri
                        })
                        // ativaDesativaLoadingConfiguracaoAtendimento(dispatch, false);
                        //ativaDesativaLoadingTelaNotificacao(dispatch, false);
                    } else {
                        dispatch({
                            type: MODIFICALOGOTIPOTELACONFIGURACAO,
                            payload: 'semURI'
                        })
                        //ativaDesativaLoadingConfiguracaoAtendimento(dispatch, false);
                    }

                })
                firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild('uid').equalTo(firebase.auth().currentUser.uid).limitToFirst(1).on('value', (snapshot) => {
                    if (snapshot.val()) {
                        let arrayPermissoesAtendente = {};
                        snapshot.forEach((childItem) => {

                            arrayPermissoesAtendente['key'] = childItem.key;
                            arrayPermissoesAtendente['contaPrincipal'] = (childItem.val().contaPrincipal == undefined) ? true : childItem.val().contaPrincipal;
                            arrayPermissoesAtendente['permissaoAcessoRelatorios'] = (childItem.val().permissaoAcessoRelatorios == undefined) ? true : childItem.val().permissaoAcessoRelatorios;
                            arrayPermissoesAtendente['permissaoAdministrador'] = (childItem.val().permissaoAdministrador == undefined) ? true : childItem.val().permissaoAdministrador;
                            arrayPermissoesAtendente['permissaoAlterarCadastrarAtendente'] = (childItem.val().permissaoAlterarCadastrarAtendente == undefined) ? true : childItem.val().permissaoAlterarCadastrarAtendente;
                            arrayPermissoesAtendente['permissaoAlterarCadastrarAvisos'] = (childItem.val().permissaoAlterarCadastrarAvisos == undefined) ? true : childItem.val().permissaoAlterarCadastrarAvisos;
                            arrayPermissoesAtendente['permissaoAlterarCadastrarCliente'] = (childItem.val().permissaoAlterarCadastrarCliente == undefined) ? true : childItem.val().permissaoAlterarCadastrarCliente;
                            arrayPermissoesAtendente['permissaoAlterarCadastrarSetores'] = (childItem.val().permissaoAlterarCadastrarSetores == undefined) ? true : childItem.val().permissaoAlterarCadastrarSetores;
                            arrayPermissoesAtendente['permissaoCancelarAtendimento'] = (childItem.val().permissaoCancelarAtendimentoNovoCadastro == undefined) ? true : childItem.val().permissaoCancelarAtendimentoNovoCadastro;

                            enviaSeAtendenteEhAdministrador(dispatch, arrayPermissoesAtendente.permissaoAdministrador)
                            enviaPermissoesAcessarTelasAtendente(dispatch, arrayPermissoesAtendente);
                            dispatch({ type: ALTERAINFORMACAOCONTAPRINCIPALATENDENTE, payload: arrayPermissoesAtendente.contaPrincipal });
                            modificaLoadingConfiguracoesEmpresa(dispatch, false);
                        })

                    } else {
                        modificaLoadingConfiguracoesEmpresa(dispatch, false);
                    }
                })
                // listaChamadosEmAbertoeAtendendoChatOnlineLocal(dispatch, chaveUnica);
                firebase.database().ref(`${chaveUnica}/configuracao/atendimento`).once("value").then((snapshot) => {
                    snapshot.forEach(childItem => {
                        let atendenteVeApenasProprioChamado = childItem.val().atendenteVeApenasProprioChamado == undefined ? false : childItem.val().atendenteVeApenasProprioChamado;
                        let agruparAtendenteSetor = childItem.val().agruparAtendenteSetor == undefined ? false : childItem.val().agruparAtendenteSetor;
                        firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild('uid').equalTo(firebase.auth().currentUser.uid).limitToFirst(1).once("value").then((snapshot) => {
                            if (snapshot.val()) {
                                snapshot.forEach((childItem) => {
                                    //  keyUsuario = childItem.key;
                                    //   nome = childItem.val().nome;
                                    let adminVisualizaApenasProprioSetor = childItem.val().usuarioAdmVisualizaApenasChamadosDoSetorVinculado === undefined ? false : childItem.val().usuarioAdmVisualizaApenasChamadosDoSetorVinculado;
                                    let isAdmin = childItem.val().StringAdmin === 'Admin' ? true : false

                                    listaOsChamadosEmEsperaLocalModified(dispatch, chaveUnica, atendenteVeApenasProprioChamado, agruparAtendenteSetor, isAdmin, adminVisualizaApenasProprioSetor);
                                })
                            }
                        })
                    })
                })
                listaChamadosEmAbertoeAtendendoChatOnlineLocalModified(dispatch, chaveUnica);
                // if (!IsSearchFromHasura) {
                //     dispatch(listaClientesTelaConsultaClientesAntesRemoved(''))
                //     console.log("listando clientes")
                // }
                dispatch(listaAtendentesTelaConsultaAtendenteAntesRemoved(''))
                dispatch(listaSetorSectionedTelaConsultaSetorAntesRemoved(''))
                if (pathname === '/' || pathname === '/company') {
                    history.push("/dashboard");
                } else {
                    history.push(pathname);
                }

                // dispatch(listaRespostasPadroesAntesRemoved(''))
                const messaging = firebase.messaging();
                Notification.requestPermission().then((permission) => {
                    if (permission === 'granted') {
                        return messaging.getToken()
                    }
                    return '';
                }).then(token => {

                    firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild('uid').equalTo(firebase.auth().currentUser.uid).once("value").then((snapshot) => {
                        if (snapshot.val()) {
                            // let key = Object.keys(snapshot.val()).toString();
                            let dadosUsuarioBanco = [];
                            snapshot.forEach((childItem) => {
                                dadosUsuarioBanco.push({
                                    key: childItem.key,
                                    fcmTokenWEB: childItem.val().fcmTokenWEB,
                                })
                            })
                            /*
                               */
                            if (dadosUsuarioBanco[0].fcmTokenWEB == token) {
                                /*    */
                            } else {
                                firebase.database().ref(`${chaveUnica}/usuarios/${dadosUsuarioBanco[0].key}`).update({ fcmTokenWEB: token })

                            }
                        }

                    })
                }).catch((err) => {
                    console.log('catch token', err);

                })
                messaging.onMessage((payload) => {
                    /*    */
                    /*      const sound = new Howl({
                             src: [soundnotification],
                             autoplay: true
                         });

                         sound.play(); */
                    /*  const audio = new Audio('../imagens/notificationsound.mp3');
                     audio.play(); */


                    if (payload.data.title.includes('Resposta no Chat') && window.location.pathname.includes('atendimentoonline')) {

                    } else {
                        const toastrOptions = {
                            timeOut: 5000, // by setting to 0 it will prevent the auto close
                            icon: (<i className="icon-sino" style={{ color: '#000', fontSize: 22 }} />), // You can add any component you want but note that the width and height are 70px)
                            onShowComplete: () => {
                            },
                            onHideComplete: () => {
                            },
                            onCloseButtonClick: () => {
                            },
                            onToastrClick: () => {
                                /* */
                                firebase.database().ref(`${chaveUnica}/usuarios/`).orderByChild('uid').equalTo(firebase.auth().currentUser.uid).once("value").then(snapshot => {
                                    let keyUsuario = Object.keys(snapshot.val()).toString();
                                    let filtro = `${keyUsuario}${payload.data.title}`

                                    firebase.database().ref(`${chaveUnica}/notificacoes/`).orderByChild("keyAtendenteTitle").equalTo(filtro).once("value").then(snapshot2 => {

                                        let keyNotif = Object.keys(snapshot2.val()).toString();

                                        firebase.database().ref(`${chaveUnica}/notificacoes/${keyNotif}`).update({ lido: "true" });
                                    })
                                })
                                if (payload.data.viaChat == 'true') {
                                    history.push('/atendimentoonline');
                                    dispatch({ type: MODIFICAKEYATENDIMENTOEMTENDIMENTOONLINE, payload: payload.data.keyChamado })
                                } else {

                                    let numeroAtendimento = parseInt(payload.data.numeroChamado);
                                    dispatch({ type: ALTERANUMEROATENDIMENTO, payload: numeroAtendimento });
                                    dispatch({ type: ALTERAKEYCHAMADO, payload: payload.data.keyChamado });
                                    dispatch({ type: MODIFICANOMEROTANOHEADER, payload: `Atendimento #${numeroAtendimento}` })
                                    history.push('/visualizaatendimento')
                                }

                            },
                            showCloseButton: true, // false by default
                            closeOnToastrClick: true, // false by default, this will close the toastr when user clicks on it
                            /*   component: ( // this option will give you a func 'remove' as props
                                <MyCustomComponent myProp="myValue">
                                  <span>Hello, World!</span>
                                </MyCustomComponent>
                              ) */
                        }
                        /*  */
                        toastr.light(payload.data.title, payload.data.body, toastrOptions)
                    }
                })
                // verificaSeUserTemCodigoInternoEalteraSetorNewCodInternoExport(dispatch)


            }
        })
    }
}


const listaChamadosEmAbertoeAtendendoChatOnlineLocalModified = (dispatch, chaveUnica) => {

    ativaDesativaLoadingCarregandoChamadosEmAtendimento(dispatch, true);
    let keyUsuario = '';
    let nome = "";
    let countNotifTab = 0;
    let verificaSeJaFoiExecutadoPrimeirVez = false;

    firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild('uid').equalTo(firebase.auth().currentUser.uid).once("value").then((snapshot) => {
        if (snapshot.val()) {
            snapshot.forEach((childItem) => {
                keyUsuario = childItem.key;
                nome = childItem.val().nome;
            })

            const filtro = `Atendendo${keyUsuario}true`;
            firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild("status_atendente_abertoviachat").equalTo(filtro)
                .on("value", async snapshot => {

                    let chamados = [];
                    let dadosAtendimento = [];

                    /*  console.log(snapshot.val()); */
                    if (snapshot.val() == null) {
                        verificaSeJaFoiExecutadoPrimeirVez = true;
                        modificaChamadosEmAtendimentoViaChat(dispatch, []);
                        enviaAtendimentosPorKeyReducerChat(dispatch, []);
                        ativaDesativaLoadingCarregandoChamadosEmAtendimento(dispatch, false);
                    } else {


                        let arrayOfKeys = Object.keys(snapshot.val()).sort().reverse();
                        let results = arrayOfKeys.map(key => snapshot.val()[key]);

                        const { responseClientes, responseAtendentes, responseSetores } = await getAllFirebaseCachedData(chaveUnica, results);

                        for (let aux = 0; aux < results.length; aux++) {
                            const cliente = responseClientes.find((cliente) => cliente.key === results[aux].cliente);
                            const atendente = responseAtendentes.find((atendente) => atendente.key === results[aux].atendente);
                            const setor = responseSetores.find((setor) => setor.key === results[aux].setor);

                            chamados.push({
                                key: arrayOfKeys[aux],
                                cliente: !cliente || !cliente.data ? "Não informado" : cliente.data.nome,
                                empresa: !cliente || !cliente.data ? "Não informado" : cliente.data.empresa,
                                setor: !setor || !setor.data ? "Não informado" : setor.data,
                                urlImagemNuvem: !cliente || !cliente.data ? null : cliente.data.urlImagemNuvem,
                                atendente: !atendente || !atendente.data ? "Não informado" : atendente.data,

                                dataHoraAbertura: results[aux].dataHoraAbertura,
                                dataAberturaTimeStamp: results[aux].dataAberturaTimeStamp,
                                status: results[aux].status,
                                prioridade:
                                    results[aux].prioridade === ""
                                        ? "Não informado"
                                        : results[aux].prioridade,
                                descricaoProblema: results[aux].descricaoProblema,
                                alterado: results[aux].alterado,
                                chaveUnica: chaveUnica,
                                keyCliente: results[aux].cliente,
                                numeroChamado: results[aux].numeroChamado,
                                channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                                keyAtendente: results[aux].atendente,
                                keyProblemaEstatico: (results[aux].keyProblemaEstatico == undefined) ? '' : results[aux].keyProblemaEstatico,
                                chamadoAbertoPorAdminOuCliente: results[aux].chamadoAbertoPorAdminOuCliente,
                                dataAbertura: results[aux].dataAbertura,
                                dataVencimento: results[aux].dataVencimento,
                                local: results[aux].local,
                                keySetor: results[aux].setor,
                                telefoneCliente: (results[aux].telefoneCliente === undefined) ? 'Não informado' : results[aux].telefoneCliente,
                                chamadoAbertoPorWpp: (results[aux].chamadoAbertoPorWpp === undefined) ? false : results[aux].chamadoAbertoPorWpp,
                                keyEmpresaVinculada: results[aux].keyEmpresaVinculada == undefined ? 'Não Vinculado' : results[aux].keyEmpresaVinculada,
                                lastMessageChat: results[aux].lastMessageChat == undefined ? '' : results[aux].lastMessageChat,
                                lastMessageChatTimeStamp: results[aux].lastMessageChatTimeStamp == undefined ? 0 : results[aux].lastMessageChatTimeStamp,
                                lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                                lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                                lastmessageChatStatus: results[aux].lastmessageChatStatus == undefined ? 'waiting' : results[aux].lastmessageChatStatus,
                                lastMessageLineColor: results[aux].lastMessageLineColor == undefined ? '#39a3f4' : results[aux].lastMessageLineColor,
                            });
                            dadosAtendimento[arrayOfKeys[aux]] = chamados[aux];
                        }

                        let sortedTickets = chamados.sort((d1, d2) => d2.lastMessageChatTimeStamp - d1.lastMessageChatTimeStamp)

                        enviaAtendimentosPorKeyReducerChat(dispatch, dadosAtendimento);
                        modificaChamadosEmAtendimentoViaChat(dispatch, sortedTickets);
                        ativaDesativaLoadingCarregandoChamadosEmAtendimento(dispatch, false);
                        verificaSeJaFoiExecutadoPrimeirVez = true;

                    }
                })


        } else {
            ativaDesativaLoadingCarregandoChamadosEmAtendimento(dispatch, false);
        }
    })

}

const listaOsChamadosEmEsperaLocalModified = (dispatch, chaveUnica, atendenteVeApenasProprioChamado, agruparAtendenteSetor, contaPrincipal, adminVisualizaApenasProprioSetor) => {

    let referenceToOldestKey;
    ativaDesativaLoadingChatOnline(dispatch, true);
    //aqui irei mostrar apenas os chamados do proprio atendente e os chamados provenientes do setor que este atendente está vinculado
    if (agruparAtendenteSetor == true && contaPrincipal == false) {
        //aqui o atendente vê  os proprios chamados e chamados abertos com setor vinculado a ele
        const { currentUser } = firebase.auth();
        firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild('uid').equalTo(currentUser.uid).once("value").then((snapshot) => {

            let key = Object.keys(snapshot.val()).toString();
            let codigoInterno;
            snapshot.forEach(childItem => {
                codigoInterno = childItem.val().codigoInterno;
            })


            let objetoPesquisa = {};
            objetoPesquisa[key] = true;
            /*      */
            firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild(`usuariosVisualizaAtend/dadosAtendVinc/${codigoInterno}Chat`)
                .equalTo(`${codigoInterno}Chat`)
                .limitToFirst(25)
                .on("value", async snapshot => {

                    if (snapshot.val() == null) {

                        setTimeout(() => {
                            enviaAtendimentoEmEsperaAtendimentoOnline(dispatch, []);
                            ativaDesativaLoadingChatOnline(dispatch, false);
                        }, 100);

                    } else {

                        let chamados = [];
                        let dadosAtendimento = [];

                        const arrayOfKeys = Object.keys(snapshot.val()).sort();
                        let results = arrayOfKeys.map(key => snapshot.val()[key]);
                        referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];

                        const { responseClientes, responseAtendentes, responseSetores } = await getAllFirebaseCachedData(chaveUnica, results);

                        for (let aux = 0; aux < results.length; aux++) {


                            const permiteVisualizarChamado = results[aux].permiteVisualizarWpp === undefined ? true : results[aux].permiteVisualizarWpp;
                            const cliente = responseClientes.find((cliente) => cliente.key === results[aux].cliente);
                            const atendente = responseAtendentes.find((atendente) => atendente.key === results[aux].atendente);
                            const setor = responseSetores.find((setor) => setor.key === results[aux].setor);

                            chamados.push({
                                key: arrayOfKeys[aux],
                                cliente: !cliente || !cliente.data ? "Não informado" : cliente.data.nome,
                                empresa: !cliente || !cliente.data ? "Não informado" : cliente.data.empresa,
                                setor: !setor || !setor.data ? "Não informado" : setor.data,
                                urlImagemNuvem: !cliente || !cliente.data ? null : cliente.data.urlImagemNuvem,
                                atendente: !atendente || !atendente.data ? "Não informado" : atendente.data,

                                dataHoraAbertura: results[aux].dataHoraAbertura,
                                dataVencimento: results[aux].dataVencimento,
                                dataAberturaTimeStamp: results[aux].dataAberturaTimeStamp,
                                status: results[aux].status,
                                prioridade:
                                    results[aux].prioridade === ""
                                        ? "Não informado"
                                        : results[aux].prioridade,
                                descricaoProblema: results[aux].descricaoProblema,
                                alterado: results[aux].alterado,
                                chaveUnica: chaveUnica,
                                keyCliente: results[aux].cliente,
                                numeroChamado: results[aux].numeroChamado,
                                channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                                keyAtendente: results[aux].atendente,
                                keyProblemaEstatico: (results[aux].keyProblemaEstatico == undefined) ? '' : results[aux].keyProblemaEstatico,
                                chamadoAbertoPorAdminOuCliente: results[aux].chamadoAbertoPorAdminOuCliente,
                                dataAbertura: results[aux].dataAbertura,
                                dataVencimento: results[aux].dataVencimento,
                                local: results[aux].local,
                                keySetor: results[aux].setor,
                                permiteVisualizarChamado,
                                chamadoAbertoPorWpp: (results[aux].chamadoAbertoPorWpp === undefined) ? false : results[aux].chamadoAbertoPorWpp,
                                telefoneCliente: (results[aux].telefoneCliente === undefined) ? 'Não informado' : results[aux].telefoneCliente,
                                keyEmpresaVinculada: results[aux].keyEmpresaVinculada == undefined ? 'Não Vinculado' : results[aux].keyEmpresaVinculada,
                                lastMessageChat: results[aux].lastMessageChat == undefined ? '' : results[aux].lastMessageChat,
                                lastMessageChatTimeStamp: results[aux].lastMessageChatTimeStamp == undefined ? 0 : results[aux].lastMessageChatTimeStamp,
                                lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                                lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                                lastMessageLineColor: results[aux].lastMessageLineColor == undefined ? '#39a3f4' : results[aux].lastMessageLineColor,
                            });
                            dadosAtendimento[arrayOfKeys[aux]] = chamados[aux];

                        }

                        enviaAtendimentoEmEsperaAtendimentoOnline(dispatch, chamados);
                        modificakeyReferenciaUltimoChamadoEmEspera(dispatch, referenceToOldestKey);
                        enviaAtendimentosPorKeyReducerChatEmEspera(dispatch, dadosAtendimento);
                        if (chamados.length >= 24) {
                            modificaloadingCarregandoChamadosEmEsperaRefreshControl(dispatch, true);
                        }
                        ativaDesativaLoadingChatOnline(dispatch, false);

                    }
                })


        })
    } else if (atendenteVeApenasProprioChamado == true && agruparAtendenteSetor == true && contaPrincipal == true && adminVisualizaApenasProprioSetor == true) {

        const { currentUser } = firebase.auth();
        firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild('uid').equalTo(currentUser.uid).once("value").then((snapshot) => {
            let key = Object.keys(snapshot.val()).toString();
            let codigoInterno;
            snapshot.forEach(childItem => {
                codigoInterno = childItem.val().codigoInterno;
            })

            firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild(`usuariosVisualizaAtend/dadosAtendVinc/${codigoInterno}Chatstatus`)
                .equalTo(`${codigoInterno}ChatAberto`)
                .limitToFirst(25)
                .on("value", async snapshot => {

                    if (snapshot.val() == null) {

                        setTimeout(() => {
                            enviaAtendimentoEmEsperaAtendimentoOnline(dispatch, []);
                            ativaDesativaLoadingChatOnline(dispatch, false);
                        }, 100);

                    } else {

                        let chamados = [];
                        let dadosAtendimento = [];

                        const arrayOfKeys = Object.keys(snapshot.val()).sort();
                        let results = arrayOfKeys.map(key => snapshot.val()[key]);
                        referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];

                        const { responseClientes, responseAtendentes, responseSetores } = await getAllFirebaseCachedData(chaveUnica, results);

                        for (let aux = 0; aux < results.length; aux++) {

                            const permiteVisualizarChamado = results[aux].permiteVisualizarWpp === undefined ? true : results[aux].permiteVisualizarWpp;
                            const cliente = responseClientes.find((cliente) => cliente.key === results[aux].cliente);
                            const atendente = responseAtendentes.find((atendente) => atendente.key === results[aux].atendente);
                            const setor = responseSetores.find((setor) => setor.key === results[aux].setor);

                            chamados.push({
                                key: arrayOfKeys[aux],
                                cliente: !cliente || !cliente.data ? "Não informado" : cliente.data.nome,
                                empresa: !cliente || !cliente.data ? "Não informado" : cliente.data.empresa,
                                setor: !setor || !setor.data ? "Não informado" : setor.data,
                                urlImagemNuvem: !cliente || !cliente.data ? null : cliente.data.urlImagemNuvem,
                                atendente: !atendente || !atendente.data ? "Não informado" : atendente.data,

                                dataHoraAbertura: results[aux].dataHoraAbertura,
                                dataVencimento: results[aux].dataVencimento,
                                dataAberturaTimeStamp: results[aux].dataAberturaTimeStamp,
                                status: results[aux].status,
                                prioridade:
                                    results[aux].prioridade === ""
                                        ? "Não informado"
                                        : results[aux].prioridade,
                                descricaoProblema: results[aux].descricaoProblema,
                                alterado: results[aux].alterado,
                                chaveUnica: chaveUnica,
                                keyCliente: results[aux].cliente,
                                numeroChamado: results[aux].numeroChamado,
                                channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                                keyAtendente: results[aux].atendente,
                                keyProblemaEstatico: (results[aux].keyProblemaEstatico == undefined) ? '' : results[aux].keyProblemaEstatico,
                                chamadoAbertoPorAdminOuCliente: results[aux].chamadoAbertoPorAdminOuCliente,
                                dataAbertura: results[aux].dataAbertura,
                                dataVencimento: results[aux].dataVencimento,
                                local: results[aux].local,
                                keySetor: results[aux].setor,
                                permiteVisualizarChamado,
                                chamadoAbertoPorWpp: (results[aux].chamadoAbertoPorWpp === undefined) ? false : results[aux].chamadoAbertoPorWpp,
                                telefoneCliente: (results[aux].telefoneCliente === undefined) ? 'Não informado' : results[aux].telefoneCliente,
                                keyEmpresaVinculada: results[aux].keyEmpresaVinculada == undefined ? 'Não Vinculado' : results[aux].keyEmpresaVinculada,
                                lastMessageChat: results[aux].lastMessageChat == undefined ? '' : results[aux].lastMessageChat,
                                lastMessageChatTimeStamp: results[aux].lastMessageChatTimeStamp == undefined ? 0 : results[aux].lastMessageChatTimeStamp,
                                lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                                lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                                lastMessageLineColor: results[aux].lastMessageLineColor == undefined ? '#39a3f4' : results[aux].lastMessageLineColor,
                            });
                            dadosAtendimento[arrayOfKeys[aux]] = chamados[aux];

                        }

                        enviaAtendimentoEmEsperaAtendimentoOnline(dispatch, chamados);
                        modificakeyReferenciaUltimoChamadoEmEspera(dispatch, referenceToOldestKey);
                        enviaAtendimentosPorKeyReducerChatEmEspera(dispatch, dadosAtendimento);
                        if (chamados.length >= 24) {
                            modificaloadingCarregandoChamadosEmEsperaRefreshControl(dispatch, true);
                        }
                        ativaDesativaLoadingChatOnline(dispatch, false);
                    }
                })


        })
    }
    //chamado padrao
    else {
        firebase
            .database()
            .ref(`${chaveUnica}/chamados/`)
            .orderByChild("status_atendente_abertoviachat")
            .equalTo("AbertoNão informadotrue")
            .limitToFirst(25)
            .on("value", async snapshot => {

                if (snapshot.val() == null) {
                    setTimeout(() => {
                        enviaAtendimentoEmEsperaAtendimentoOnline(dispatch, []);
                        ativaDesativaLoadingChatOnline(dispatch, false);
                    }, 100);

                } else {

                    let chamados = [];
                    let dadosAtendimento = [];

                    const arrayOfKeys = Object.keys(snapshot.val()).sort();
                    let results = arrayOfKeys.map(key => snapshot.val()[key]);
                    referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];

                    const { responseClientes, responseAtendentes, responseSetores } = await getAllFirebaseCachedData(chaveUnica, results);


                    for (let aux = 0; aux < results.length; aux++) {

                        const permiteVisualizarChamado = results[aux].permiteVisualizarWpp === undefined ? true : results[aux].permiteVisualizarWpp;
                        const cliente = responseClientes.find((cliente) => cliente.key === results[aux].cliente);
                        const atendente = responseAtendentes.find((atendente) => atendente.key === results[aux].atendente);
                        const setor = responseSetores.find((setor) => setor.key === results[aux].setor);

                        chamados.push({
                            key: arrayOfKeys[aux],
                            cliente: !cliente || !cliente.data ? "Não informado" : cliente.data.nome,
                            empresa: !cliente || !cliente.data ? "Não informado" : cliente.data.empresa,
                            setor: !setor || !setor.data ? "Não informado" : setor.data,
                            urlImagemNuvem: !cliente || !cliente.data ? null : cliente.data.urlImagemNuvem,
                            atendente: !atendente || !atendente.data ? "Não informado" : atendente.data,

                            dataHoraAbertura: results[aux].dataHoraAbertura,
                            dataVencimento: results[aux].dataVencimento,
                            dataAberturaTimeStamp: results[aux].dataAberturaTimeStamp,
                            status: results[aux].status,
                            prioridade:
                                results[aux].prioridade === ""
                                    ? "Não informado"
                                    : results[aux].prioridade,
                            descricaoProblema: results[aux].descricaoProblema,
                            alterado: results[aux].alterado,
                            chaveUnica: chaveUnica,
                            keyCliente: results[aux].cliente,
                            numeroChamado: results[aux].numeroChamado,
                            channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                            keyAtendente: results[aux].atendente,
                            keyProblemaEstatico: (results[aux].keyProblemaEstatico == undefined) ? '' : results[aux].keyProblemaEstatico,
                            chamadoAbertoPorAdminOuCliente: results[aux].chamadoAbertoPorAdminOuCliente,
                            dataAbertura: results[aux].dataAbertura,
                            dataVencimento: results[aux].dataVencimento,
                            local: results[aux].local,
                            keySetor: results[aux].setor,
                            permiteVisualizarChamado,
                            chamadoAbertoPorWpp: (results[aux].chamadoAbertoPorWpp === undefined) ? false : results[aux].chamadoAbertoPorWpp,
                            telefoneCliente: (results[aux].telefoneCliente === undefined) ? 'Não informado' : results[aux].telefoneCliente,
                            keyEmpresaVinculada: results[aux].keyEmpresaVinculada == undefined ? 'Não Vinculado' : results[aux].keyEmpresaVinculada,
                            lastMessageChat: results[aux].lastMessageChat == undefined ? '' : results[aux].lastMessageChat,
                            lastMessageChatTimeStamp: results[aux].lastMessageChatTimeStamp == undefined ? 0 : results[aux].lastMessageChatTimeStamp,
                            lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                            lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                            lastMessageLineColor: results[aux].lastMessageLineColor == undefined ? '#39a3f4' : results[aux].lastMessageLineColor,
                        });
                        dadosAtendimento[arrayOfKeys[aux]] = chamados[aux];
                    }

                    enviaAtendimentoEmEsperaAtendimentoOnline(dispatch, chamados);
                    modificakeyReferenciaUltimoChamadoEmEspera(dispatch, referenceToOldestKey);
                    enviaAtendimentosPorKeyReducerChatEmEspera(dispatch, dadosAtendimento);
                    if (chamados.length >= 24) {
                        modificaloadingCarregandoChamadosEmEsperaRefreshControl(dispatch, true);
                    }
                    ativaDesativaLoadingChatOnline(dispatch, false);



                }
            })


    }

}

export const listaOsUltimosChamadosEmEspera = (
    referenciaUltimaKey,
    chamadosAnteriores,
    atendenteVeApenasProprioChamado,
    agruparAtendenteSetor,
    contaPrincipal,
    adminVisualizaApenasProprioSetor,
    atendimentosPorKeyReducerChatEmEsperaTotal
) => {
    return dispatch => {
        let referenceToOldestKey;

        AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            if (agruparAtendenteSetor == true && contaPrincipal == false) {
                if (referenciaUltimaKey === undefined || referenciaUltimaKey === "") {
                    modificaloadingCarregandoChamadosEmEsperaRefreshControl(dispatch, false)
                } else {
                    //aqui o atendente vê  os proprios chamados e chamados abertos com setor vinculado a ele
                    const { currentUser } = firebase.auth();
                    firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild('uid').equalTo(currentUser.uid).once("value").then((snapshot) => {
                        let key = Object.keys(snapshot.val()).toString();
                        let codigoInterno;
                        snapshot.forEach(childItem => {
                            codigoInterno = childItem.val().codigoInterno;
                        })
                        firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild(`usuariosVisualizaAtend/dadosAtendVinc/${codigoInterno}Chat`)
                            //.equalTo(`${codigoInterno}Chat`)
                            .startAt(`${codigoInterno}Chat`, referenciaUltimaKey)
                            .endAt(`${codigoInterno}Chat`)
                            .limitToFirst(25)
                            .on("value", async snapshot => {
                                if (snapshot.val() == null) {

                                    modificaloadingCarregandoChamadosEmEsperaRefreshControl(dispatch, false)
                                } else {

                                    let chamados = [];


                                    const arrayOfKeys = Object.keys(snapshot.val()).sort().slice(1);
                                    let results = arrayOfKeys.map(key => snapshot.val()[key]);
                                    referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];


                                    const { responseClientes, responseAtendentes, responseSetores } = await getAllFirebaseCachedData(chaveUnica, results);


                                    for (let aux = 0; aux < results.length; aux++) {
                                        let permiteVisualizarChamado = results[aux].permiteVisualizarWpp === undefined ? true : results[aux].permiteVisualizarWpp;
                                        const cliente = responseClientes.find((cliente) => cliente.key === results[aux].cliente);
                                        const atendente = responseAtendentes.find((atendente) => atendente.key === results[aux].atendente);
                                        const setor = responseSetores.find((setor) => setor.key === results[aux].setor);

                                        if (results[aux].status === 'Fechado') {

                                            firebase.database().ref(`${chaveUnica}/chamados/${arrayOfKeys[aux]}/usuariosVisualizaAtend/`).remove();
                                            firebase.database().ref(`${chaveUnica}/chamados/${arrayOfKeys[aux]}`).update({
                                                status_atendente_abertoviachat: '',
                                                channelChamado: '',
                                            })
                                        } else if (results[aux].status === 'Cancelado') {

                                            firebase.database().ref(`${chaveUnica}/chamados/${arrayOfKeys[aux]}/usuariosVisualizaAtend/`).remove();
                                            firebase.database().ref(`${chaveUnica}/chamados/${arrayOfKeys[aux]}`).update({
                                                status_atendente_abertoviachat: '',
                                                channelChamado: '',
                                            })
                                        } else if (results[aux].status !== 'Aberto') {


                                        } else {
                                            chamados.push({
                                                key: arrayOfKeys[aux],
                                                cliente: !cliente || !cliente.data ? "Não informado" : cliente.data.nome,
                                                empresa: !cliente || !cliente.data ? "Não informado" : cliente.data.empresa,
                                                setor: !setor || !setor.data ? "Não informado" : setor.data,
                                                urlImagemNuvem: !cliente || !cliente.data ? null : cliente.data.urlImagemNuvem,
                                                atendente: !atendente || !atendente.data ? "Não informado" : atendente.data,
                                                dataHoraAbertura: results[aux].dataHoraAbertura,
                                                dataVencimento: results[aux].dataVencimento,

                                                status: results[aux].status,
                                                dataAberturaTimeStamp: results[aux].dataAberturaTimeStamp,
                                                prioridade:
                                                    results[aux].prioridade === ""
                                                        ? "Não informado"
                                                        : results[aux].prioridade,
                                                descricaoProblema: results[aux].descricaoProblema,
                                                alterado: results[aux].alterado,
                                                chaveUnica: chaveUnica,
                                                keyCliente: results[aux].cliente,
                                                numeroChamado: results[aux].numeroChamado,
                                                channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                                                permiteVisualizarChamado,
                                                keyAtendente: results[aux].atendente,

                                                keyProblemaEstatico: (results[aux].keyProblemaEstatico == undefined) ? '' : results[aux].keyProblemaEstatico,
                                                chamadoAbertoPorAdminOuCliente: results[aux].chamadoAbertoPorAdminOuCliente,
                                                dataAbertura: results[aux].dataAbertura,
                                                local: results[aux].local,
                                                keySetor: results[aux].setor,
                                                chamadoAbertoPorWpp: (results[aux].chamadoAbertoPorWpp === undefined) ? false : results[aux].chamadoAbertoPorWpp,
                                                telefoneCliente: (results[aux].telefoneCliente === undefined) ? 'Não informado' : results[aux].telefoneCliente,
                                                keyEmpresaVinculada: results[aux].keyEmpresaVinculada == undefined ? 'Não Vinculado' : results[aux].keyEmpresaVinculada,
                                                lastMessageChat: results[aux].lastMessageChat == undefined ? '' : results[aux].lastMessageChat,
                                                lastMessageChatTimeStamp: results[aux].lastMessageChatTimeStamp == undefined ? 0 : results[aux].lastMessageChatTimeStamp,
                                                lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                                                lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                                                lastMessageLineColor: results[aux].lastMessageLineColor == undefined ? '#39a3f4' : results[aux].lastMessageLineColor,
                                            });
                                        }
                                        atendimentosPorKeyReducerChatEmEsperaTotal[arrayOfKeys[aux]] = chamados[aux];
                                    }

                                    modificakeyReferenciaUltimoChamadoEmEspera(dispatch, referenceToOldestKey);
                                    enviaAtendimentoEmEsperaAtendimentoOnline(dispatch, chamadosAnteriores.concat(chamados));
                                    enviaAtendimentosPorKeyReducerChatEmEspera(dispatch, atendimentosPorKeyReducerChatEmEsperaTotal);

                                }
                            })


                    })
                }
            } else if (atendenteVeApenasProprioChamado == true && agruparAtendenteSetor == true && contaPrincipal == true && adminVisualizaApenasProprioSetor == true) {
                if (referenciaUltimaKey === undefined || referenciaUltimaKey === "") {
                    modificaloadingCarregandoChamadosEmEsperaRefreshControl(dispatch, false)
                } else {
                    //aqui o atendente vê  os proprios chamados e chamados abertos com setor vinculado a ele
                    const { currentUser } = firebase.auth();
                    firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild('uid').equalTo(currentUser.uid).once("value").then((snapshot) => {
                        let key = Object.keys(snapshot.val()).toString();
                        let codigoInterno;
                        snapshot.forEach(childItem => {
                            codigoInterno = childItem.val().codigoInterno;
                        })
                        firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild(`usuariosVisualizaAtend/dadosAtendVinc/${codigoInterno}Chatstatus`)
                            //.equalTo(`${codigoInterno}ChatAberto`)
                            .startAt(`${codigoInterno}ChatAberto`, referenciaUltimaKey)
                            .endAt(`${codigoInterno}ChatAberto`)
                            .limitToFirst(25)
                            .on("value", async snapshot => {

                                if (snapshot.val() == null) {
                                    modificaloadingCarregandoChamadosEmEsperaRefreshControl(dispatch, false)
                                } else {


                                    let chamados = [];
                                    const arrayClientesChamado = [];
                                    const arraySetoresChamado = [];
                                    const arrayAtendentesChamado = [];
                                    const clientesKeys = [];
                                    const setoresKeys = [];
                                    const atendentesKeys = [];

                                    const arrayOfKeys = Object.keys(snapshot.val()).sort().slice(1);
                                    let results = arrayOfKeys.map(key => snapshot.val()[key]);
                                    referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];



                                    const { responseClientes, responseAtendentes, responseSetores } = await getAllFirebaseCachedData(chaveUnica, results);

                                    //


                                    for (let aux = 0; aux < results.length; aux++) {
                                        if (results[aux].status !== 'Fechado') {
                                            let permiteVisualizarChamado = results[aux].permiteVisualizarWpp === undefined ? true : results[aux].permiteVisualizarWpp;
                                            const cliente = responseClientes.find((cliente) => cliente.key === results[aux].cliente);
                                            const atendente = responseAtendentes.find((atendente) => atendente.key === results[aux].atendente);
                                            const setor = responseSetores.find((setor) => setor.key === results[aux].setor);

                                            chamados.push({

                                                key: arrayOfKeys[aux],
                                                cliente: !cliente || !cliente.data ? "Não informado" : cliente.data.nome,
                                                empresa: !cliente || !cliente.data ? "Não informado" : cliente.data.empresa,
                                                setor: !setor || !setor.data ? "Não informado" : setor.data,
                                                urlImagemNuvem: !cliente || !cliente.data ? null : cliente.data.urlImagemNuvem,
                                                atendente: !atendente || !atendente.data ? "Não informado" : atendente.data,
                                                dataHoraAbertura: results[aux].dataHoraAbertura,
                                                dataVencimento: results[aux].dataVencimento,
                                                dataAberturaTimeStamp: results[aux].dataAberturaTimeStamp,
                                                status: results[aux].status,
                                                prioridade:
                                                    results[aux].prioridade === ""
                                                        ? "Não informado"
                                                        : results[aux].prioridade,
                                                descricaoProblema: results[aux].descricaoProblema,
                                                alterado: results[aux].alterado,
                                                chaveUnica: chaveUnica,
                                                keyCliente: results[aux].cliente,
                                                numeroChamado: results[aux].numeroChamado,
                                                channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                                                keyAtendente: results[aux].atendente,
                                                keyProblemaEstatico: (results[aux].keyProblemaEstatico == undefined) ? '' : results[aux].keyProblemaEstatico,
                                                chamadoAbertoPorAdminOuCliente: results[aux].chamadoAbertoPorAdminOuCliente,
                                                dataAbertura: results[aux].dataAbertura,
                                                local: results[aux].local,
                                                keySetor: results[aux].setor,
                                                permiteVisualizarChamado,
                                                chamadoAbertoPorWpp: (results[aux].chamadoAbertoPorWpp === undefined) ? false : results[aux].chamadoAbertoPorWpp,
                                                telefoneCliente: (results[aux].telefoneCliente === undefined) ? 'Não informado' : results[aux].telefoneCliente,
                                                keyEmpresaVinculada: results[aux].keyEmpresaVinculada == undefined ? 'Não Vinculado' : results[aux].keyEmpresaVinculada,
                                                lastMessageChat: results[aux].lastMessageChat == undefined ? '' : results[aux].lastMessageChat,
                                                lastMessageChatTimeStamp: results[aux].lastMessageChatTimeStamp == undefined ? 0 : results[aux].lastMessageChatTimeStamp,
                                                lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                                                lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                                                lastMessageLineColor: results[aux].lastMessageLineColor == undefined ? '#39a3f4' : results[aux].lastMessageLineColor,
                                            });

                                            atendimentosPorKeyReducerChatEmEsperaTotal[arrayOfKeys[aux]] = chamados[aux];

                                        }
                                    }

                                    modificakeyReferenciaUltimoChamadoEmEspera(dispatch, referenceToOldestKey);
                                    enviaAtendimentoEmEsperaAtendimentoOnline(dispatch, chamadosAnteriores.concat(chamados));
                                    enviaAtendimentosPorKeyReducerChatEmEspera(dispatch, atendimentosPorKeyReducerChatEmEsperaTotal);

                                }
                            })


                    })
                }
            }
            //chamado padrao
            else {
                if (referenciaUltimaKey === undefined || referenciaUltimaKey === "") {
                    modificaloadingCarregandoChamadosEmEsperaRefreshControl(dispatch, false)
                } else {

                    firebase
                        .database()
                        .ref(`${chaveUnica}/chamados/`)
                        .orderByChild("status_atendente_abertoviachat")
                        .startAt("AbertoNão informadotrue", referenciaUltimaKey)
                        .endAt("AbertoNão informadotrue")
                        .limitToFirst(25)
                        .on("value", async snapshot => {


                            if (snapshot.val() == null) {
                                modificaloadingCarregandoChamadosEmEsperaRefreshControl(dispatch, false)
                            } else {


                                let chamados = [];
                                const arrayClientesChamado = [];
                                const arraySetoresChamado = [];
                                const arrayAtendentesChamado = [];
                                const clientesKeys = [];
                                const setoresKeys = [];
                                const atendentesKeys = [];

                                const arrayOfKeys = Object.keys(snapshot.val()).sort().slice(1);
                                let results = arrayOfKeys.map(key => snapshot.val()[key]);
                                referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];



                                const { responseClientes, responseAtendentes, responseSetores } = await getAllFirebaseCachedData(chaveUnica, results);



                                for (let aux = 0; aux < results.length; aux++) {
                                    if (results[aux].status !== 'Fechado') {
                                        let permiteVisualizarChamado = results[aux].permiteVisualizarWpp === undefined ? true : results[aux].permiteVisualizarWpp;
                                        const cliente = responseClientes.find((cliente) => cliente.key === results[aux].cliente);
                                        const atendente = responseAtendentes.find((atendente) => atendente.key === results[aux].atendente);
                                        const setor = responseSetores.find((setor) => setor.key === results[aux].setor);

                                        chamados.push({

                                            key: arrayOfKeys[aux],
                                            cliente: !cliente || !cliente.data ? "Não informado" : cliente.data.nome,
                                            empresa: !cliente || !cliente.data ? "Não informado" : cliente.data.empresa,
                                            setor: !setor || !setor.data ? "Não informado" : setor.data,
                                            urlImagemNuvem: !cliente || !cliente.data ? null : cliente.data.urlImagemNuvem,
                                            atendente: !atendente || !atendente.data ? "Não informado" : atendente.data,
                                            dataHoraAbertura: results[aux].dataHoraAbertura,
                                            dataVencimento: results[aux].dataVencimento,
                                            dataAberturaTimeStamp: results[aux].dataAberturaTimeStamp,
                                            status: results[aux].status,
                                            prioridade:
                                                results[aux].prioridade === ""
                                                    ? "Não informado"
                                                    : results[aux].prioridade,
                                            descricaoProblema: results[aux].descricaoProblema,
                                            alterado: results[aux].alterado,
                                            chaveUnica: chaveUnica,
                                            keyCliente: results[aux].cliente,
                                            numeroChamado: results[aux].numeroChamado,
                                            channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                                            keyAtendente: results[aux].atendente,
                                            keyProblemaEstatico: (results[aux].keyProblemaEstatico == undefined) ? '' : results[aux].keyProblemaEstatico,
                                            chamadoAbertoPorAdminOuCliente: results[aux].chamadoAbertoPorAdminOuCliente,
                                            dataAbertura: results[aux].dataAbertura,
                                            local: results[aux].local,
                                            keySetor: results[aux].setor,
                                            permiteVisualizarChamado,
                                            chamadoAbertoPorWpp: (results[aux].chamadoAbertoPorWpp === undefined) ? false : results[aux].chamadoAbertoPorWpp,
                                            telefoneCliente: (results[aux].telefoneCliente === undefined) ? 'Não informado' : results[aux].telefoneCliente,
                                            keyEmpresaVinculada: results[aux].keyEmpresaVinculada == undefined ? 'Não Vinculado' : results[aux].keyEmpresaVinculada,
                                            lastMessageChat: results[aux].lastMessageChat == undefined ? '' : results[aux].lastMessageChat,
                                            lastMessageChatTimeStamp: results[aux].lastMessageChatTimeStamp == undefined ? 0 : results[aux].lastMessageChatTimeStamp,
                                            lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                                            lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                                            lastMessageLineColor: results[aux].lastMessageLineColor == undefined ? '#39a3f4' : results[aux].lastMessageLineColor,
                                        });

                                        atendimentosPorKeyReducerChatEmEsperaTotal[arrayOfKeys[aux]] = chamados[aux];

                                    }
                                }

                                modificakeyReferenciaUltimoChamadoEmEspera(dispatch, referenceToOldestKey);
                                enviaAtendimentoEmEsperaAtendimentoOnline(dispatch, chamadosAnteriores.concat(chamados));
                                enviaAtendimentosPorKeyReducerChatEmEspera(dispatch, atendimentosPorKeyReducerChatEmEsperaTotal);
                            }
                        })
                }
            }
        })

    };
};


export const novalistaInformacoesDashBoarPeriodoPersonalizado = (dataInicial, dataFinal) => {
    //
    dataInicial = moment(dataInicial).format('DD/MM/YYYY');
    dataFinal = moment(dataFinal).format('DD/MM/YYYY');
    //
    let atendimentosAbertoHoje = 0;
    let atendimentosAbertoNaSemana = 0;
    let atendimentosAtendendoNaSemana = 0;
    let atendimentosFechadoNaSemana = 0;
    let atendimentosCanceladoNaSemana = 0;
    let atendimentosAbertoAtendendoPrioridadeBaixa = 0;
    let atendimentosAbertoAtendendoPrioridadeNormal = 0;
    let atendimentosAbertoAtendendoPrioridadeAlta = 0;
    let atendimentosAbertoAtendendoPrioridadeUrgente = 0;
    let tempoResolucaoChamadoMenorque1 = 0;
    let tempoResolucaoChamado1 = 0;
    let tempoResolucaoChamado2 = 0;
    let tempoResolucaoChamado3 = 0;
    let tempoResolucaoChamado4 = 0;
    let tempoResolucaoChamado5 = 0;
    let tempoResolucaoChamado6 = 0;
    let tempoResolucaoChamado7 = 0;
    let tempoResolucaoChamadoMaiorque7 = 0;
    let atendimentosPorLocalInterno = 0;
    let atendimentosPorLocalExterno = 0;
    let atendimentosPorLocal = [];
    let atendimentoAbertoStatus = 0;
    let atendimentoEmAtendimentoStatus = 0;
    let atendimentoFechadoStatus = 0;
    let atendimentoCanceladoStatus = 0;
    let arrayChamadosComClientes = [];
    let arrayChamadosComSetores = [];
    let arrayChamadosPorStatus = [];

    let dataInicialVerificacao = moment(dataInicial, 'DD/MM/YYYY').format('YYYY-MM-DD');
    let dataFinalVerificacao = moment(dataFinal, 'DD/MM/YYYY').format('YYYY-MM-DD');
    let verificaSeDataInicialMaiorQueDataFinal = moment(dataFinalVerificacao).isBefore(dataInicialVerificacao);
    return dispatch => {

        AsyncStorage.getItem('chaveUnica').then((chaveUnica2) => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            //
            if (dataInicial == '' && dataFinal == '' || dataInicial == null && dataFinal == null) {
                mensagemPeriodoPersonalizadoError(dispatch, 'Informe a DATA INICIAL e DATA FINAL do período de pesquisa.');
            } else if (dataInicial == '' || dataInicial == null) {
                mensagemPeriodoPersonalizadoError(dispatch, 'Informe a DATA INICIAL do período de pesquisa.');
            } else if (dataFinal == '' || dataFinal == null) {
                mensagemPeriodoPersonalizadoError(dispatch, 'Informe a DATA FINAL do período de pesquisa.');
            } else if (verificaSeDataInicialMaiorQueDataFinal == true) {
                mensagemPeriodoPersonalizadoError(dispatch, 'DATA INICIAL maior que DATA FINAL, por favor verifique.');
            } else {
                enviaSemConexaoInternetDashBoard(dispatch, false);
                ativaDesativaLoadingTelaDashBoard(dispatch, true);
                enviaPeriodoPersonalizado(dispatch, `${dataInicial} - ${dataFinal}`);

                dataInicial = dataInicial + ' 00:00:01';
                dataFinal = dataFinal + ' 23:59:59';
                let dataAtual = retornaDataAtual();
                let dataInicioConsulta, dataFinalConsulta;

                //verificacao para saber qual periodo é e passar o periodo correto para a query do firebase
                //
                dataInicioConsulta = retornaDataTimeStamp(dataInicial);
                dataFinalConsulta = retornaDataTimeStamp(dataFinal);
                //;
                //
                /*    firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild('dataAberturaTimeStamp')
                       .startAt(dataInicioConsulta).endAt(dataFinalConsulta).once("value").then((snapshot) => {
                           if (snapshot.val()) { */
                const options = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                        'Accept': 'application/json',
                    },

                    data: {
                        chaveUnica: chaveUnica,
                        dataInicioConsulta: dataInicioConsulta,
                        dataFinalConsulta: dataFinalConsulta,
                        rota: 'atendimentodetalhado'
                    },
                    url: 'gerarRelatoriosAtendimentos',
                };
                api(options).then((snapshot) => {


                    snapshot = snapshot.data;

                    if (snapshot !== null) {


                        let arrayOfKeys = Object.keys(snapshot).sort().reverse();
                        enviaAtendimentosDestaSemanaDashBoard(dispatch, arrayOfKeys.length);
                        let results = arrayOfKeys.map((key) => snapshot[key]);
                        for (let i = 0; i < results.length; i++) {
                            if (results[i].dataAbertura == dataAtual) {
                                atendimentosAbertoHoje++
                            }
                            //por status
                            if (results[i].status == 'Aberto') {
                                atendimentosAbertoNaSemana++
                                //por prioridade
                                if (results[i].prioridade == 'Baixa') {
                                    atendimentosAbertoAtendendoPrioridadeBaixa++;
                                }
                                if (results[i].prioridade == 'Normal') {
                                    atendimentosAbertoAtendendoPrioridadeNormal++;
                                }
                                if (results[i].prioridade == 'Alta') {
                                    atendimentosAbertoAtendendoPrioridadeAlta++;
                                }
                                if (results[i].prioridade == 'Urgente') {
                                    atendimentosAbertoAtendendoPrioridadeUrgente++;
                                }
                            }
                            if (results[i].status == 'Atendendo') {
                                atendimentosAtendendoNaSemana++
                            }
                            if (results[i].status == 'Fechado') {
                                atendimentosFechadoNaSemana++
                                if (results[i].periodoResolucaoChamado == '<1') {
                                    tempoResolucaoChamadoMenorque1++
                                }
                                if (results[i].periodoResolucaoChamado == '1') {
                                    tempoResolucaoChamado1++
                                }
                                if (results[i].periodoResolucaoChamado == '2') {
                                    tempoResolucaoChamado2++
                                }
                                if (results[i].periodoResolucaoChamado == '3') {
                                    tempoResolucaoChamado3++
                                }
                                if (results[i].periodoResolucaoChamado == '4') {
                                    tempoResolucaoChamado4++
                                }
                                if (results[i].periodoResolucaoChamado == '5') {
                                    tempoResolucaoChamado5++
                                }
                                if (results[i].periodoResolucaoChamado == '6') {
                                    tempoResolucaoChamado6++
                                }
                                if (results[i].periodoResolucaoChamado == '7') {
                                    tempoResolucaoChamado7++
                                }
                                if (results[i].periodoResolucaoChamado == '>7') {
                                    tempoResolucaoChamadoMaiorque7++
                                }
                            }
                            if (results[i].status == 'Cancelado') {
                                atendimentosCanceladoNaSemana++
                            }
                            if (results[i].local == 'Interno') {
                                atendimentosPorLocalInterno++
                            }
                            if (results[i].local == 'Externo') {
                                atendimentosPorLocalExterno++
                            }

                        }
                        //envio chamados abertos hoje
                        enviaAtendimentosHojeDashBoard(dispatch, atendimentosAbertoHoje);


                        //implemento array para enviar os chamados por status
                        if (atendimentosAbertoNaSemana == 0) {
                            atendimentoAbertoStatus = 0;
                        } else {
                            atendimentoAbertoStatus = atendimentosAbertoNaSemana;

                        }

                        if (atendimentosAtendendoNaSemana == 0) {
                            atendimentoEmAtendimentoStatus = 0;
                        } else {
                            atendimentoEmAtendimentoStatus = atendimentosAtendendoNaSemana;

                        }
                        if (atendimentosFechadoNaSemana == 0) {
                            atendimentoFechadoStatus = 0;
                        } else {
                            atendimentoFechadoStatus = atendimentosFechadoNaSemana;
                        }
                        if (atendimentosCanceladoNaSemana == 0) {
                            atendimentoCanceladoStatus = 0;
                        } else {
                            atendimentoCanceladoStatus = atendimentosCanceladoNaSemana;

                        }

                        enviaAtendimentoAberto(dispatch, atendimentoAbertoStatus);
                        enviaAtendimentoCancelado(dispatch, atendimentoCanceladoStatus);
                        enviaAtendimentoFechado(dispatch, atendimentoFechadoStatus);
                        enviaAtendimentoEmAtendimento(dispatch, atendimentoEmAtendimentoStatus);


                        //envio chamados por prioridades
                        /*    let totalAtendimentosAbertoAtendendoPorPrioridade = atendimentosAbertoAtendendoPrioridadeBaixa +
                               atendimentosAbertoAtendendoPrioridadeNormal + atendimentosAbertoAtendendoPrioridadeAlta +
                               atendimentosAbertoAtendendoPrioridadeUrgente;
                           enviaTotalAtendimentosPorPRioridade(dispatch, totalAtendimentosAbertoAtendendoPorPrioridade) */
                        if (atendimentosAbertoAtendendoPrioridadeBaixa == 0) {
                            atendimentosAbertoAtendendoPrioridadeBaixa = 0.5
                        }
                        if (atendimentosAbertoAtendendoPrioridadeNormal == 0) {
                            atendimentosAbertoAtendendoPrioridadeNormal = 0.5
                        }
                        if (atendimentosAbertoAtendendoPrioridadeAlta == 0) {
                            atendimentosAbertoAtendendoPrioridadeAlta = 0.5
                        }
                        if (atendimentosAbertoAtendendoPrioridadeUrgente == 0) {
                            atendimentosAbertoAtendendoPrioridadeUrgente = 0.5
                        }

                        let atendimentosPorPrioridadeEnviaDashBoard = [
                            {
                                value: atendimentosAbertoAtendendoPrioridadeBaixa,
                                label: 'Baixa',
                                id: "Baixa",
                                color: "hsl(195, 100%, 50%)",
                                valorZerado: "0"
                            },
                            {
                                value: atendimentosAbertoAtendendoPrioridadeNormal,
                                label: 'Normal',
                                id: "Normal",
                                color: "hsl(0, 0%, 83%)",
                                valorZerado: "0"
                            },
                            {
                                value: atendimentosAbertoAtendendoPrioridadeAlta,
                                label: 'Alta',
                                id: "Alta",
                                color: "hsl(42, 94%, 60%)",
                                valorZerado: "0"
                            },
                            {
                                value: atendimentosAbertoAtendendoPrioridadeUrgente,
                                label: 'Urgente',
                                id: "Urgente",
                                color: "hsl(344, 94%, 51%)",
                                valorZerado: "0"
                            }];
                        enviaAtendimentosPorPrioridadeDashboard(dispatch, atendimentosPorPrioridadeEnviaDashBoard)

                        if (tempoResolucaoChamadoMenorque1 == 0) {
                            tempoResolucaoChamadoMenorque1 = 0.5
                        }
                        if (tempoResolucaoChamado1 == 0) {
                            tempoResolucaoChamado1 = 0.5
                        }
                        if (tempoResolucaoChamado2 == 0) {
                            tempoResolucaoChamado2 = 0.5
                        }
                        if (tempoResolucaoChamado3 == 0) {
                            tempoResolucaoChamado3 = 0.5
                        }
                        if (tempoResolucaoChamado4 == 0) {
                            tempoResolucaoChamado4 = 0.5
                        }
                        if (tempoResolucaoChamado5 == 0) {
                            tempoResolucaoChamado5 = 0.5
                        }
                        if (tempoResolucaoChamado6 == 0) {
                            tempoResolucaoChamado6 = 0.5
                        }
                        if (tempoResolucaoChamado7 == 0) {
                            tempoResolucaoChamado7 = 0.5
                        }
                        if (tempoResolucaoChamadoMaiorque7 == 0) {
                            tempoResolucaoChamadoMaiorque7 = 0.5
                        }
                        let atendimentosPorTempOResolucao = [
                            { value: tempoResolucaoChamadoMenorque1, label: '< 1 dia', id: "< 1 dia", color: "hsl(150, 100%, 50%)", valorZerado: "0" },
                            { value: tempoResolucaoChamado1, label: '1 dia', id: "1 dia", color: "hsl(0, 0%, 83%)", valorZerado: "0" },
                            { value: tempoResolucaoChamado2, label: '2 dias', id: "2 dias", color: "hsl(42, 94%, 60%)", valorZerado: "0" },
                            { value: tempoResolucaoChamado3, label: '3 dias', id: "3 dias", color: "hsl(344, 94%, 51%)", valorZerado: "0" },
                            { value: tempoResolucaoChamado4, label: '4 dias', id: "4 dias", color: "hsl(214, 41%, 78%)", valorZerado: "0" },
                            { value: tempoResolucaoChamado5, label: '5 dias', id: "5 dias", color: "hsl(195, 100%, 50%)", valorZerado: "0" },
                            { value: tempoResolucaoChamado6, label: '6 dias', id: "6 dias", color: "hsl(180, 100%, 50%)", valorZerado: "0" },
                            { value: tempoResolucaoChamado7, label: '7 dias', id: "7 dias", color: "hsl(25, 75%, 47%)", valorZerado: "0" },
                            {
                                value: tempoResolucaoChamadoMaiorque7,
                                label: '> 7 dias',
                                id: "> 7 dias",
                                color: "hsl(180, 100%, 27%)",
                                valorZerado: "0"
                            }];
                        enviaTempoResolucaoChamados(dispatch, atendimentosPorTempOResolucao);
                        //   enviaTotalAtendimentosPeriodoResolucao(dispatch, atendimentosFechadoNaSemana);
                        //atendimentos interno e externo
                        if (atendimentosPorLocalInterno == 0) {
                            atendimentosPorLocal.push({
                                value: 0.5, label: 'Interno', id: "Interno", color: "hsl(195, 100%, 50%)", valorZerado: "0"
                            })

                        } else {
                            atendimentosPorLocal.push({
                                value: atendimentosPorLocalInterno, label: 'Interno', id: "Interno", color: "hsl(195, 100%, 50%)", valorZerado: "0"
                            })
                        }
                        if (atendimentosPorLocalExterno == 0) {
                            atendimentosPorLocal.push({
                                value: 0.5, label: 'Externo', id: "Externo", color: "hsl(214, 41%, 78%)", valorZerado: "0"
                            })

                        } else {
                            atendimentosPorLocal.push({
                                value: atendimentosPorLocalExterno, label: 'Externo', id: "Externo", color: "hsl(214, 41%, 78%)", valorZerado: "0"
                            })
                        }

                        // let totalAtendimentosPorLocal = atendimentosPorLocalInterno + atendimentosPorLocalExterno;
                        // enviaTotalAtendimentosPorLocalDashboard(dispatch, totalAtendimentosPorLocal)
                        enviaAtendimentosPorLocalDashboard(dispatch, atendimentosPorLocal)
                        //envio total de clientes

                        //envio total de chamados

                        //
                        ativaDesativaLoadingCarregandoCOnfiguracoesGerais(dispatch, false);
                        ativaDesativaLoadingTelaDashBoard(dispatch, false);
                        ativaDesativaLoadingRefreshControol(dispatch, false);
                    } else {
                        //sem informacoes
                        enviaAtendimentosDestaSemanaDashBoard(dispatch, 0);
                        enviaAtendimentosHojeDashBoard(dispatch, 0);
                        enviaAtendimentoAberto(dispatch, 0);
                        enviaAtendimentoCancelado(dispatch, 0);
                        enviaAtendimentoFechado(dispatch, 0);
                        enviaAtendimentoEmAtendimento(dispatch, 0);

                        let atendimentoPorPrioridadeZerado = [
                            { value: 0.5, label: 'Baixa', id: "Baixa", color: "hsl(195, 100%, 50%)", valorZerado: "0" },
                            { value: 0.5, label: 'Normal', id: "Normal", color: "hsl(0, 0%, 83%)", valorZerado: "0" },
                            { value: 0.5, label: 'Alta', id: "Alta", color: "hsl(42, 94%, 60%)", valorZerado: "0" },
                            { value: 0.5, label: 'Urgente', id: "Urgente", color: "hsl(344, 94%, 51%)", valorZerado: "0" }
                        ]


                        enviaAtendimentosPorPrioridadeDashboard(dispatch, atendimentoPorPrioridadeZerado);
                        //  enviaTotalAtendimentosPorPRioridade(dispatch, 0)
                        let atendimentoPorResolucaoZerado = [
                            { value: 0.5, label: '< 1 dia', id: "< 1 dia", color: "hsl(150, 100%, 50%)", valorZerado: "0" },
                            { value: 0.5, label: '1 dia', id: "1 dia", color: "hsl(0, 0%, 83%)", valorZerado: "0" },
                            { value: 0.5, label: '2 dias', id: "2 dias", color: "hsl(42, 94%, 60%)", valorZerado: "0" },
                            { value: 0.5, label: '3 dias', id: "3 dias", color: "hsl(344, 94%, 51%)", valorZerado: "0" },
                            { value: 0.5, label: '4 dias', id: "4 dias", color: "hsl(214, 41%, 78%)", valorZerado: "0" },
                            { value: 0.5, label: '5 dias', id: "5 dias", color: "hsl(195, 100%, 50%)", valorZerado: "0" },
                            { value: 0.5, label: '6 dias', id: "6 dias", color: "hsl(180, 100%, 50%)", valorZerado: "0" },
                            { value: 0.5, label: '7 dias', id: "7 dias", color: "hsl(25, 75%, 47%)", valorZerado: "0" },
                            { value: 0.5, label: '> 7 dias', id: "> 7 dias", color: "hsl(180, 100%, 27%)", valorZerado: "0" }]
                        enviaTempoResolucaoChamados(dispatch, atendimentoPorResolucaoZerado);
                        // enviaTotalAtendimentosPeriodoResolucao(dispatch, 0);

                        let atendimentoPorLocalZerado = [
                            { value: 0.5, label: 'Interno', id: "Interno", color: "hsl(195, 100%, 50%)", valorZerado: "0" },
                            { value: 0.5, label: 'Externo', id: "Externo", color: "hsl(214, 41%, 78%)", valorZerado: "0" }
                        ];
                        // enviaTotalAtendimentosPorLocalDashboard(dispatch, 0)
                        enviaAtendimentosPorLocalDashboard(dispatch, atendimentoPorLocalZerado)

                        ativaDesativaLoadingTelaDashBoard(dispatch, false);

                        ativaDesativaLoadingRefreshControol(dispatch, false);
                        //
                        ativaDesativaLoadingCarregandoCOnfiguracoesGerais(dispatch, false);
                    }
                })
            }

        })

    }
}
export const verificaSeExisteConfiguracaoCriada = () => {
    return dispatch => {
        //se tiver dados na configuracoes inseridsas é porqeu já tem todas as configuracoes inseridas no banco do firebase
        //entao nao precisa ficar consultando
        AsyncStorage.getItem('configuracoesInseridas8').then(
            (dadosConfig) => {
                if (dadosConfig !== null) {
                    //
                    //
                } else {

                    AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
                        const chaveUnica = chaveUnica2.replace(/"/g, "");
                        let verificaSeJaExisteConfigAvaliacao = false;
                        let verificaSeJaExisteConfigAtendimento = false;
                        let verificaSeJaExisteConfigUserPrincipal = false;
                        let verificaSeJaExisteConfigProblemaEstatico = false;
                        //verifica configuracao de avaliacao
                        firebase.database().ref(`${chaveUnica}/setores/`).once('value').then((snapshot) => {
                            //
                            if (snapshot.val()) {

                            } else {
                                //;
                                firebase.database().ref(`${chaveUnica}/setores/`).push({
                                    nome: 'GERAL', observacao: '', nomePesquisa: 'GERAL', alterado: 0
                                })
                            }
                            firebase.database().ref(`${chaveUnica}/configuracao/avaliacao`).once('value').then((snapshot) => {
                                //
                                if (snapshot.val()) {
                                    //
                                    verificaSeJaExisteConfigAvaliacao = true;
                                } else {
                                    //;
                                    firebase.database().ref(`${chaveUnica}/configuracao/avaliacao`).push({
                                        utilizaAvaliacao: true,
                                        mensagemPadrao: 'Qualquer dúvida, estamos à disposição. Por gentileza, avalie meu atendimento. Tenha um ótimo dia!'
                                    }).then(() => {
                                        //
                                        verificaSeJaExisteConfigAvaliacao = true;
                                    })
                                }
                                firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild('uid').equalTo(firebase.auth().currentUser.uid).once('value').then((snapshot) => {
                                    if (snapshot.val()) {
                                        let keyUsuario = Object.keys(snapshot.val()).toString();
                                        firebase.database().ref(`${chaveUnica}/usuarios/${keyUsuario}/tarefas`).once('value').then((snapshot) => {
                                            //
                                            if (snapshot.val()) {

                                                snapshot.forEach((childItem) => {
                                                    firebase.database().ref(`${chaveUnica}/tarefas/`).push({
                                                        key: childItem.key,
                                                        feito: childItem.val().feito,
                                                        tarefa: childItem.val().tarefa,
                                                        keyAtendente: keyUsuario
                                                    })
                                                })
                                                setTimeout(() => {
                                                    firebase.database().ref(`${chaveUnica}/usuarios/${keyUsuario}/tarefas`).remove().then(
                                                        () => {

                                                        }).catch((err) => {
                                                        })
                                                }, 2000);
                                            } else {

                                            }
                                        })
                                    }
                                    //verifica configuracoes de atendimento em geral
                                    firebase.database().ref(`${chaveUnica}/configuracao/atendimento`).once('value').then((snapshot) => {
                                        //
                                        if (snapshot.val()) {
                                            //
                                            const dadosConfiguracaoAtendimento = [];
                                            snapshot.forEach(childItem => {
                                                dadosConfiguracaoAtendimento.push({
                                                    key: childItem.key,
                                                    atribuirAtendenteObrigatorio: childItem.val().atribuirAtendenteObrigatorio,
                                                    atendenteVeApenasProprioChamado: childItem.val().atendenteVeApenasProprioChamado,
                                                    agruparAtendenteSetor: childItem.val().agruparAtendenteSetor,
                                                    criarChamadoProblemaEstatico: childItem.val().criarChamadoProblemaEstatico,
                                                })
                                            })
                                            //
                                            //
                                            //
                                            if (dadosConfiguracaoAtendimento[0].agruparAtendenteSetor == undefined || dadosConfiguracaoAtendimento[0].atendenteVeApenasProprioChamado == undefined) {
                                                firebase.database().ref(`${chaveUnica}/configuracao/atendimento/${dadosConfiguracaoAtendimento[0].key}`).update({
                                                    atendenteVeApenasProprioChamado: false,
                                                    agruparAtendenteSetor: false,
                                                })
                                            }
                                            //nova cfg
                                            if (dadosConfiguracaoAtendimento[0].criarChamadoProblemaEstatico == undefined) {
                                                firebase.database().ref(`${chaveUnica}/configuracao/atendimento/${dadosConfiguracaoAtendimento[0].key}`).update({
                                                    criarChamadoProblemaEstatico: false,
                                                })
                                            }
                                            //

                                            verificaSeJaExisteConfigAtendimento = true;
                                        } else {
                                            //
                                            firebase.database().ref(`${chaveUnica}/configuracao/atendimento`).push({
                                                atribuirAtendenteObrigatorio: false,
                                                atendenteVeApenasProprioChamado: false,
                                                agruparAtendenteSetor: false,
                                                criarChamadoProblemaEstatico: false
                                            }).then(() => {
                                                //
                                                verificaSeJaExisteConfigAtendimento = true;
                                            })
                                        }
                                        firebase.database().ref(`${chaveUnica}/usuarios/`).limitToFirst(1).once('value').then((snapshot) => {

                                            if (snapshot.val()) {
                                                //
                                                //;
                                                snapshot.forEach(childItem => {
                                                    //  console.log(childItem.val().contaPrincipal);
                                                    if (childItem.val().contaPrincipal == undefined || childItem.val().contaPrincipal == null || childItem.val().contaPrincipal == false) {
                                                        //
                                                        firebase.database().ref(`${chaveUnica}/usuarios/${childItem.key}`).update({
                                                            contaPrincipal: true,
                                                            permissaoAcessoRelatorios: true,
                                                            permissaoAdministrador: true,
                                                            permissaoAlterarCadastrarAtendente: true,
                                                            permissaoAlterarCadastrarAvisos: true,
                                                            permissaoAlterarCadastrarCliente: true,
                                                            permissaoAlterarCadastrarSetores: true,
                                                            StringAdmin: 'Admin'
                                                        });
                                                        verificaSeJaExisteConfigUserPrincipal = true;
                                                    } else {
                                                        //
                                                        verificaSeJaExisteConfigUserPrincipal = true;
                                                    }
                                                })
                                            } else {
                                                //
                                            }
                                            firebase.database().ref(`${chaveUnica}/respostapadrao`).once('value').then((snapshot) => {
                                                if (snapshot.val()) {
                                                    //
                                                } else {
                                                    firebase.database().ref(`${chaveUnica}/respostapadrao/`).push({
                                                        tituloresposta: 'bom dia',
                                                        resposta: 'Olá bom dia, como podemos ajudar?',
                                                        selecionado: 1
                                                    })
                                                }
                                                //
                                                //
                                                //
                                                //
                                                if (verificaSeJaExisteConfigAvaliacao == true && verificaSeJaExisteConfigAtendimento == true &&
                                                    verificaSeJaExisteConfigUserPrincipal == true) {
                                                    //
                                                    localStorage.setItem(`configuracoesInseridas8`, JSON.stringify('OK'));
                                                } else {
                                                    //
                                                }
                                            })
                                        })

                                    })
                                })
                            })
                        })


                    })
                }
            })


    }
}
const verificaSeUserTemCodigoInternoEalteraSetorNewCodInternoExport = (dispatch) => {
    AsyncStorage.getItem('verificacaousersetor').then(
        (dadosConfig) => {
            if (dadosConfig !== null) {
                //

            } else {
                AsyncStorage.getItem('chaveUnica').then((chaveUnica2) => {
                    const chaveUnica = chaveUnica2.replace(/"/g, "");
                    //aqui eu vou popular os codigos internos em todos os usuarios durante um mês, código feito dia 21/01/2021
                    //também irei popular os códigos internos nos setores ao invés da key
                    firebase.database().ref(`${chaveUnica}/usuarios/`).orderByChild('excluido').equalTo(null)
                        .once('value').then((snapshot) => {
                            let aux = 0;
                            snapshot.forEach(childItem => {
                                aux++;
                                let codigoInternoUsuario = childItem.val().codigoInterno;

                                if (codigoInternoUsuario == undefined) {

                                    let codigoInterno = childItem.val().codigoInterno == undefined ? `codigoInterno${aux}` : childItem.val().codigoInterno;
                                    firebase.database().ref(`${chaveUnica}/usuarios/${childItem.key}`).update({ codigoInterno: codigoInterno })
                                } else {

                                }
                            })
                            firebase.database().ref(`${chaveUnica}/setores/`).orderByChild('excluido').equalTo(null)
                                .once('value').then((snapshot) => {
                                    let aux = 0;
                                    snapshot.forEach(childItem => {
                                        let codigoSetorVinculadoExecutado = childItem.val().codigoSetorVinculadoExecutado;
                                        let atendentesVinculados = childItem.val().atendentesVinculados;


                                        if (atendentesVinculados == undefined) {
                                            //não tem atendentes vinculados no setor, então não excuto nada

                                        } else {

                                            if (codigoSetorVinculadoExecutado == undefined) {
                                                //não foi executado ainda, tenho que executar
                                                let keysAtendentes = Object.keys(atendentesVinculados)


                                                for (let i = 0; i < keysAtendentes.length; i++) {
                                                    firebase.database().ref(`${chaveUnica}/usuarios/${keysAtendentes[i]}/codigoInterno`).once('value').then((snapshot) => {

                                                        let codigoInterno = snapshot.val();

                                                        if (codigoInterno !== null) {


                                                            firebase.database().ref(`${chaveUnica}/setores/${childItem.key}/atendentesVinculados`).update({ [codigoInterno]: codigoInterno })
                                                        }

                                                    })
                                                }
                                            } else {

                                            }
                                        }
                                    })
                                })
                            localStorage.setItem(`verificacaousersetor`, JSON.stringify('OK'));
                        })
                })
            }
        })

}
export const cadastraTarefas = (nomeTarefa) => {
    return dispatch => {
        AsyncStorage.getItem('chaveUnica').then((chaveUnica2) => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");

            firebase.database().ref(`${chaveUnica}/usuarios/`).orderByChild("uid").equalTo(firebase.auth().currentUser.uid).once("value").then(snapshot => {
                let keyUsuario = '';
                snapshot.forEach(childItem => {
                    keyUsuario = childItem.key;

                });
                //
                //
                firebase.database().ref(`${chaveUnica}/tarefas`).push({
                    keyAtendente: keyUsuario,
                    tarefa: nomeTarefa,
                    feito: false
                }).then(
                    () => {
                        //
                    })
            })

        })


    }
}
export const marcarTodasNotifComoLido = (notificacoesarray) => {
    return dispatch => {


        AsyncStorage.getItem('chaveUnica').then((chaveUnica2) => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            let keyNotificacoesMarcarComoLido = []
            for (let i = 0; i < notificacoesarray.length; i++) {

                if (notificacoesarray[i].lido == "false") {


                    keyNotificacoesMarcarComoLido.push(notificacoesarray[i].key)
                }
            }

            if (keyNotificacoesMarcarComoLido.length > 0) {
                let aux = 0;
                for (let i = 0; i < keyNotificacoesMarcarComoLido.length; i++) {
                    aux++;
                    firebase.database().ref(`${chaveUnica}/notificacoes/${keyNotificacoesMarcarComoLido[i]}`).update({ lido: "true" }).then(() => {
                        if (keyNotificacoesMarcarComoLido.length == aux) {

                            toastr.success('Sucesso', 'Notificações marcadas como lidas!');
                        }
                    });
                }
            } else {

                toastr.success('Sucesso', 'Notificações marcadas como lidas!');
            }
            /*   firebase.database().ref(`${chaveUnica}/usuarios/`).orderByChild('uid').equalTo(firebase.auth().currentUser.uid).once("value").then(snapshot => {
                  let keyUsuario = Object.keys(snapshot.val()).toString();
                  firebase
                      .database()
                      .ref(`${chaveUnica}/usuarios/${keyUsuario}/notificacoes`)
                      .orderByKey()
                      .limitToLast(30)
                      .once("value").then(snapshot => {
                          if (snapshot.val()) {
                              let aux = 0;
                              //  console.log(snapshot.numChildren())
                              snapshot.forEach((childItem) => {
                                  aux++;
                                  firebase.database().ref(`${chaveUnica}/usuarios/${keyUsuario}/notificacoes/${childItem.key}`).update({ lido: "true" }).then(() => {
                                      if (snapshot.numChildren() == aux) {
                                          toastr.success('Sucesso', 'Todas as notificações foram alteradas');
                                      }
                                  });
                              })
                          }

                      })
              }) */

        })


    }
}
export const excluirTarefa = (keyTarefa) => {
    return dispatch => {
        AsyncStorage.getItem('chaveUnica').then((chaveUnica2) => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            //
            //
            firebase.database().ref(`${chaveUnica}/tarefas/${keyTarefa}`).remove().then(
                () => {
                    //
                })

        })


    }
}
export const marcarTarefaComoFeito = (keyTarefa, statusFeito) => {
    return dispatch => {
        AsyncStorage.getItem('chaveUnica').then((chaveUnica2) => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");


            //
            //
            firebase.database().ref(`${chaveUnica}/tarefas/${keyTarefa}`).update({ feito: statusFeito }).then(
                () => {
                    //
                })
        })


    }
}


const modificaDataConexao = (dispatch, value) => {
    dispatch({
        type: MODIFICADATACONEXAO,
        payload: value
    });
};
const modificaTimeZoneChatOnline = (dispatch, value) => {
    dispatch({
        type: MODIFICATIMEZONECHATONLINE,
        payload: value
    });
};
const modificaInformarMotivoCancelamento = (dispatch, value) => {
    dispatch({
        type: MODIFICAINFORMARMOTIVOCANCELAMENTO,
        payload: value
    });
};
const modificamostrarNomeAtendenteWpp = (dispatch, value) => {
    dispatch({
        type: MODIFICAMOSTRARNOMEATENDENTEWPP,
        payload: value
    });
};
const modificaEnviarMensagemTransSetorWpp = (dispatch, value) => {
    dispatch({
        type: MODIFICAENVIARMENSAGEMTRANSSETORWPP,
        payload: value
    });
};
const modificadesativarAssistenteVirtualBotWpp = (dispatch, value) => {
    dispatch({
        type: MODIFICADESATIVARASSISTENTEVIRTUALBOTWPP,
        payload: value
    });
};

const modificaloadingCarregandoChamadosEmEsperaRefreshControl = (dispatch, value) => {
    dispatch({
        type: MODIFICALOADINGCARREGANDOCHAMADOSEMESPERAREFRESHCONTROL,
        payload: value
    });
};
export const modificaloadingCarregandoChamadosEmEsperaRefreshControlExport = (value) => {
    return {
        type: MODIFICALOADINGCARREGANDOCHAMADOSEMESPERAREFRESHCONTROL,
        payload: value
    };
};
const modificachamadosCarregadosEmEspera = (dispatch, value) => {
    dispatch({
        type: MODIFICACHAMADOSCARREGADOSEMESPERA,
        payload: value
    });
};
const modificakeyReferenciaUltimoChamadoEmEspera = (dispatch, value) => {
    dispatch({
        type: MODIFICAKEYREFERENCIAULTIMOCHAMADOEMESPERA,
        payload: value
    });
};
const modificaloadingBotaoAtualizaStatusConexaoWpp = (dispatch, value) => {
    dispatch({
        type: MODIFICALOADINGBOTAOATUALIZASTATUSCONEXAOWPP,
        payload: value
    });
};

const modificanivelBateria = (dispatch, error) => {
    dispatch({
        type: MODIFICANIVELBATERIA,
        payload: error
    });
};
const modificamodeloCelular = (dispatch, error) => {
    dispatch({
        type: MODIFICAMODELOCELULAR,
        payload: error
    });
};
const modificaversaoOS = (dispatch, error) => {
    dispatch({
        type: MODIFICAVERSAOOS,
        payload: error
    });
};

const modificamemoriaContainerWpp = (dispatch, error) => {
    dispatch({
        type: MODIFICAMEMORIACONTAINERWPP,
        payload: error
    });
};
const modificastatusContainerWpp = (dispatch, error) => {
    dispatch({
        type: MODIFICASTATUSCONTAINERWPP,
        payload: error
    });
};
const modificaconfiguracoesInternasWpp = (dispatch, error) => {
    dispatch({
        type: MODIFICACONFIGURACOESINTERNASWPP,
        payload: error
    });
};
const modificastatusConexaoWppPainelAdm = (dispatch, error) => {
    dispatch({
        type: MODIFICASTATUSCONEXAOWPPPAINELADM,
        payload: error
    });
};
const enviaArrayDeDadosDaEmpresaPainelAdm = (dispatch, valor) => {
    dispatch({
        type: ENVIAARRAYDEDADOSDAEMPRESAPAINELADM,
        payload: valor
    });
};

const enviaAtendimentoEmEsperaAtendimentoOnline = (dispatch, valor) => {
    dispatch({
        type: ENVIAATENDIMENTOEMESPERATENDIMENTOONLINE,
        payload: valor
    });
};
const ativaDesativaLoadingChatOnline = (dispatch, value) => {
    dispatch({
        type: ATIVADESATIVALOADINGCHATONLINE,
        payload: value
    });
};
const enviaConversasParaChatEmEspera = (dispatch, array) => {
    dispatch({
        type: ENVIACONVERSASPARACHATEMESPERA,
        payload: array
    });
};
const enviaAtendimentosPorKeyReducerChatEmEspera = (dispatch, array) => {
    dispatch({
        type: ENVIAATENDIMENTOPORKEYREDUCERCHATEMESPERA,
        payload: array
    });
};
//daqui pra cima
const enviaConversasCarregadasEmEspera = (dispatch, key) => {
    dispatch({
        type: ENVIACONVERSASCARREGADASEMESPERA,
        payload: key
    });
};
const enviaUltimaKeyReferenciaCOnversasCarregadasChat = (dispatch, key) => {
    dispatch({
        type: ENVIAULTIMAKEYREFERENCIACONVERSASCARREGADASCHAT,
        payload: key
    });
};
export const enviaUltimaKeyReferenciaCOnversasCarregadasChatExport = (key) => {
    return {
        type: ENVIAULTIMAKEYREFERENCIACONVERSASCARREGADASCHAT,
        payload: key
    };
};
const enviaConversasCarregadasEmAtendimento = (dispatch, key) => {
    dispatch({
        type: ENVIACONVERSASCARREGADASEMATENDIMENTO,
        payload: key
    });
};
const ativaDesativaLoadingCarregandoConversasChatAtual = (dispatch, value) => {
    dispatch({
        type: ATIVADESATIVALOADINGCARREGANDOCONVERSASCHATATUALA,
        payload: value
    });
};
const ativaDesativaLoadingCarregandoConversasChatAtualEmEspera = (dispatch, value) => {
    dispatch({
        type: ATIVADESATIVALOADINGCARREGANDOCONVERSASCHATATUALAEMEPSERA,
        payload: value
    });
};
const enviaUltimaKeyReferenciaCOnversasCarregadasChatEmEspera = (dispatch, key) => {
    dispatch({
        type: ENVIAULTIMAKEYREFERENCIACONVERSASCARREGADASCHATEMESPERA,
        payload: key
    });
};
export const enviaUltimaKeyReferenciaCOnversasCarregadasChatExportEmEspera = (key) => {
    return {
        type: ENVIAULTIMAKEYREFERENCIACONVERSASCARREGADASCHATEMESPERA,
        payload: key
    };
};
const enviaClientesAtivosGrafico = (dispatch, array) => {
    dispatch({
        type: ENVIACLIENTESATIVOSGRAFICO,
        payload: array
    });
};
const enviaClientesViaAnuncio = (dispatch, array) => {
    dispatch({
        type: ENVIACLIENTESVIAANUNCIO,
        payload: array
    });
};

const enviaNovasAtivacoesNoPeriodo = (dispatch, array) => {
    dispatch({
        type: ENVIANOVASATIVACOESNOPERIODO,
        payload: array
    });
};
const enviaTipoPlanoGrafico = (dispatch, array) => {
    dispatch({
        type: ENVIATIPOPLANOGRAFICO,
        payload: array
    });
};
const enviaFormaPagamentoGrafico = (dispatch, array) => {
    dispatch({
        type: ENVIAFORMAPAGAMENTOGRAFICO,
        payload: array
    });
};
const enviaAssinadoViaWebAppGrafico = (dispatch, array) => {
    dispatch({
        type: ENVIAASSINADOVIAWEBAPPGRAFICO,
        payload: array
    });
};


export const enviaAcoesAtendimentoParaReducerChatExport = (array) => {
    return {
        type: ENVIAACOESATENDIMENTOPARAREDUCERCHAT,
        payload: array
    };
};


const enviaAcoesAtendimentoParaReducerChat = (dispatch, array) => {
    dispatch({
        type: ENVIAACOESATENDIMENTOPARAREDUCERCHAT,
        payload: array
    });
};
const enviaAtendimentosPorKeyReducerChat = (dispatch, array) => {
    dispatch({
        type: ENVIAATENDIMENTOPORKEYREDUCERCHAT,
        payload: array
    });
};
export const modificaLidoEnaoLidoAtendimentoPorKeyReducerChat = (key, value) => {
    return {
        type: MODIFICALIDOENAOLIDOATENDIMENTOPORKEYREDUCERCHAT,
        value: value,
        key: key,
        newmessage: 'newmessage'
    }
};
const enviaAtendimentoParaReducerChat = (dispatch, arrayAtendimento) => {
    dispatch({
        type: ENVIAATENDIMENTOPARAREDUCERCHAT,
        payload: arrayAtendimento
    });
};


export const enviaAtendimentoParaReducerChatExport = (value) => {
    return {
        type: ENVIAATENDIMENTOPARAREDUCERCHAT,
        payload: value
    }
};
export const modificaModalSair = (value) => {
    return {
        type: MODIFICAMODALSAIR,
        payload: value
    }
};
export const modificaCorBordaListaTarefas = (value) => {
    return {
        type: MODIFICACORBORDALISTATAREFAS,
        payload: value
    }
};
export const insereDataFiltroDashboard = (dataInicial, dataFinal) => {

    return dispatch => {
        //
        //
        dispatch({ type: MODIFICADATAINICIALCONSULTADASHBOARD, payload: dataInicial });
        dispatch({ type: MODIFICADATAFINALCONSULTADASHBOARD, payload: dataFinal });
    }
};
export const modificaFiltroPorDataDashboard = (value) => {
    return {
        type: MODIFICAFILTROPORDATADASHBOARD,
        payload: value
    }
};
export const modificaFiltroSelecionadoRelatorioDetalhado = (value) => {
    return {
        type: MODIFICAFILTROSELECIONADORELATORIODETALHADO,
        payload: value
    }
};

export const modificaDataInicialFinalRelatorioCRM = (dataInicial, dataFinal) => {
    return dispatch => {


        dispatch({ type: MODIFICADATAINICIALRELATORIOCRM, payload: dataInicial });
        dispatch({ type: MODIFICADATAFINALRELATORIOCRM, payload: dataFinal });
    }
};
export const modificaDataInicialFinalRelatorioAtendimentoDetalhado = (dataInicial, dataFinal) => {
    return dispatch => {
        //
        //
        dispatch({ type: MODIFICADATAINICIALRELATORIODETALHADO, payload: dataInicial });
        dispatch({ type: MODIFICADATAFINALRELATORIODETALHADO, payload: dataFinal });
    }
};
const modificaDataInicialFinalRelatorioAtendimentoDetalhadoLocal = (dataInicial, dataFinal) => {
    return dispatch => {
        //
        //
        dispatch({ type: MODIFICADATAINICIALRELATORIODETALHADO, payload: dataInicial });
        dispatch({ type: MODIFICADATAFINALRELATORIODETALHADO, payload: dataFinal });
    }
};
export const modificaDataInicialFinalRelatorioAtendimentoGeral = (dataInicial, dataFinal) => {
    return dispatch => {
        //
        //
        dispatch({ type: MODIFICADATAINICIALRELATORIOGERAL, payload: dataInicial });
        dispatch({ type: MODIFICADATAFINALRELATORIOGERAL, payload: dataFinal });
    }
};


export const insereTextosInformacionaisModal = (textCabecalho, text1, text2) => {
    return dispatch => {
        enviaTextCabecalhoModalInf(dispatch, textCabecalho);
        enviaText1ModalInf(dispatch, text1);
        enviaText2ModalInf(dispatch, text2);
        modificaVisibilidadeModalInformacaoRelatorioGeralLocal(dispatch, true);
    }
}
export const insereTextosInformacionaisModalRelatorioDetalhado = (textCabecalho, text1, text2) => {
    return dispatch => {
        enviaTextCabecalhoModalInfRelatorioDetalhado(dispatch, textCabecalho);
        enviaText1ModalInfRelatorioDetalhado(dispatch, text1);
        enviaText2ModalInfRelatorioDetalhado(dispatch, text2);
        modificaVisibilidadeModalInformacaoRelatorioDetalhadoLocal(dispatch, true);
    }
}
export const modificaVisibleModalVersaoPremiumEsgotou = (value) => {
    return {
        type: MODIFICAVISIBLEMODALVERSAOPREMIUMESGOTOU,
        payload: value
    }
}
const enviaIdentificadorUnicoDaCompra = (dispatch, value) => {
    dispatch({
        type: ENVIAIDENTIFICADORUNICACOMPRA,
        payload: value
    });
};
const enviaNotificacoesParaReducer = (dispatch, value) => {
    dispatch({
        type: ENVIANOTIFICACOESPARAREDUCER,
        payload: value
    });
};
const modificaLicensawebouapp = (dispatch, value) => {
    dispatch({
        type: MODIFICALICENSAWEBAPP,
        payload: value
    });
};
const enviaTotalNotificacoesParaReducer = (dispatch, value) => {
    dispatch({
        type: ENVIATOTALNOTIFICACOESPARAREDUCER,
        payload: value
    });
};
export const enviaTotalNotificacoesParaReducerExport = (value) => {
    return {
        type: ENVIATOTALNOTIFICACOESPARAREDUCER,
        payload: value
    }
}
export const modificaVisibleModalAssinaturaPausadaExterno = (value) => {
    return {
        type: MODIFICAVISIBLEMODALASSINATURAPAUSADA,
        payload: value
    }
}
const enviaTarefaParaReducer = (dispatch, value) => {
    dispatch({
        type: ENVIATAREFAPARAREDUCER,
        payload: value
    });
}
const ativaDesativaLoadingCarregandoCOnfiguracoesGerais = (dispatch, value) => {
    dispatch({
        type: ATIVADESATIVALOADINGCARREGANDOCONFIGURACOESGERAIS,
        payload: value
    });
}
const modificaVisibleModalAssinaturaPausada = (dispatch, value) => {
    dispatch({
        type: MODIFICAVISIBLEMODALASSINATURAPAUSADA,
        payload: value
    });
};

const enviaAtendimentoAberto = (dispatch, value) => {
    dispatch({
        type: ENVIAATENDIMENTOABERTO,
        payload: value
    });
};
const enviaAtendimentoEmAtendimento = (dispatch, value) => {
    dispatch({
        type: ENVIAATENDIMENTOEMATENDIMENTO,
        payload: value
    });
};
const enviaAtendimentoCancelado = (dispatch, value) => {
    dispatch({
        type: ENVIAATENDMENTOCANCELADO,
        payload: value
    });
};
const enviaAtendimentoFechado = (dispatch, value) => {
    dispatch({
        type: ENVIAATENDIMENTOFECHADO,
        payload: value
    });
};


export const modificaVisibleModalAdiquiraVersaoPremiumExterno = (value) => {
    return {
        type: MODIFICAVISIBLEMODALADIQUIRAVERSAOPREMIUM,
        payload: value
    }
}
export const modificaVisibleModalAssinaturaSuspensaExterno = (value) => {
    return {
        type: MODIFICAVISIBLEMODALASSINATURASUSPENSA,
        payload: value
    }
}
const modificaVisibleModalAssinaturaSuspensa = (dispatch, value) => {
    dispatch({
        type: MODIFICAVISIBLEMODALASSINATURASUSPENSA,
        payload: value
    });
};

const modificaVisibleModalAdiquiraVersaoPremium = (dispatch, value) => {
    dispatch({
        type: MODIFICAVISIBLEMODALADIQUIRAVERSAOPREMIUM,
        payload: value
    });
};

const enviaTextCabecalhoModalInfRelatorioDetalhado = (dispatch, value) => {
    dispatch({
        type: ENVIATEXTCABECALHOMODALINFRELATORIODETALHADO,
        payload: value
    });
};
const enviaText1ModalInfRelatorioDetalhado = (dispatch, value) => {
    dispatch({
        type: ENVIATEXT1MODALINFRELATORIODETALHADO,
        payload: value
    });
};
const enviaText2ModalInfRelatorioDetalhado = (dispatch, value) => {
    dispatch({
        type: ENVIATEXT2MODALINFRELATORIODETALHADO,
        payload: value
    });
};

const enviaTextCabecalhoModalInf = (dispatch, value) => {
    dispatch({
        type: ENVIATEXTCABECALHOMODALINF,
        payload: value
    });
};
const enviaText1ModalInf = (dispatch, value) => {
    dispatch({
        type: ENVIATEXT1MODALINF,
        payload: value
    });
};
const enviaAdminParaReducer = (dispatch, value) => {
    dispatch({
        type: ENVIAADMINPARAREDUCER,
        payload: value
    });
};
const enviaText2ModalInf = (dispatch, value) => {
    dispatch({
        type: ENVIATEXT2MODALINF,
        payload: value
    });
};


const enviaNotaMediaAvaliacaoRelatorioGeral = (dispatch, value) => {
    dispatch({
        type: ENVIANOTAMEDIAAVALIACAORELATORIOGERAL,
        payload: value
    });
};

const enviaPlanoMensal = (dispatch, value) => {
    dispatch({
        type: ENVIAPLANOMENSAL,
        payload: value
    });
};
const enviaPeriodoPersonalizado = (dispatch, value) => {
    dispatch({
        type: ENVIAPERIODOPERSONALIZADO,
        payload: value
    });
};
const enviaPlanoTrimestral = (dispatch, value) => {
    dispatch({
        type: ENVIAPLANOTRIMESTRAL,
        payload: value
    });
};
const enviaPlanoAnual = (dispatch, value) => {
    dispatch({
        type: ENVIAPLANOANUAL,
        payload: value
    });
};
const verificaAgruparAtendenteSetor = (dispatch, valor) => {
    dispatch({
        type: VERIFICAAGRUPARATENDENTESETOR,
        payload: valor
    });
};
const modificaObrigatorioInformarResolucaoChamadoDashboard = (dispatch, valor) => {
    dispatch({
        type: MODIFICAOBRIGATORIOINFORMARRESOLUCAOCHAMADODASHBOARD,
        payload: valor
    });
};
const verificaAtendenteVeApenasProprioChamado = (dispatch, valor) => {
    dispatch({
        type: VERIFICAATENDENTEVERAPENASPROPRIOCHAMADO,
        payload: valor
    });
};
const verificaCriarChamadoProblemaEstatico = (dispatch, valor) => {
    dispatch({
        type: VERIFICACRIARCHAMADOPROBLEMAESTATICO,
        payload: valor
    });
};
const verificaCriarChamadoProblemaEstaticoAtendimento = (dispatch, valor) => {
    dispatch({
        type: VERIFICACRIARCHAMADOPROBLEMAESTATICOATENDIMENTO,
        payload: valor
    });
};

const verificaSeEhObrigatorioATribuirAtendente = (dispatch, valor) => {
    dispatch({
        type: VERIFICARSEEHOBRIGATORIOATRIBUIRATENDENTE,
        payload: valor
    });
};
const enviaClientesMaisAbriramChamadosRelatorioGeral = (dispatch, clientes) => {
    dispatch({
        type: ENVIACLIENTEMAISABRIRAMCHAMADOSRELATORIOGERAL,
        payload: clientes
    });
};
const enviaPlanosMaisAssinados = (dispatch, array) => {
    dispatch({
        type: ENVIAPLANOSMAISASSINADOS,
        payload: array
    });
};
const enviaAtendentesMaisAbriramChamadosRelatorioGeral = (dispatch, atendentes) => {
    dispatch({
        type: ENVIAATENDENTEMAISABRIRAMCHAMADOSRELATORIOGERAL,
        payload: atendentes
    });
};
const enviaSetoresMaisAbriramChamadosRelatorioGeral = (dispatch, setores) => {
    dispatch({
        type: ENVIASETORESMAISABRIRAMCHAMADOSRELATORIOGERAL,
        payload: setores
    });
};
const enviaProblemasMaisAbriramChamadosRelatorioGeral = (dispatch, problemas) => {
    dispatch({
        type: ENVIASPROBLEMASMAISABRIRAMCHAMADOSRELATORIOGERAL,
        payload: problemas
    });
};
const modificaMensagemFinalizaAtendimentoWpp = (dispatch, msg) => {
    dispatch({
        type: MODIFICAMENSAGEMFINALIZAATENDIMENTOWPP,
        payload: msg
    });
};
const enviaSemConexaoInternetRelatorioGeral = (dispatch, value) => {
    dispatch({
        type: ENVIASEMCONEXAOINTERNETRELATORIOGERAL,
        payload: value
    });
};
const modificaLoadingConfiguracoesEmpresa = (dispatch, value) => {
    dispatch({
        type: MODIFICALOADINGCONFIGURACOESEMPRESA,
        payload: value
    });
};
const enviaDadosWhatsappConfig = (dispatch, value) => {
    dispatch({
        type: ENVIADADOSWHATSAPPCONFIG,
        payload: value
    });
};
const modificaAtendenteVeApenasProprioHistoricoChamado = (dispatch, value) => {
    dispatch({
        type: MODIFICAATENDENTEVEAPENASPROPRIOHISTORICOCHAMADO,
        payload: value
    });
};

export const modificaLoadingConfiguracoesEmpresaExport = (value) => {
    return {
        type: MODIFICALOADINGCONFIGURACOESEMPRESA,
        payload: value
    }
}
export const zeraEnviaSemConexaoInternetRelatorioGeral = () => {
    return {
        type: ENVIASEMCONEXAOINTERNETRELATORIOGERAL,
        payload: ''
    }
}
export const modificaVisibilidadeModalInformacaoRelatorioGeral = (value) => {
    return {
        type: MODIFICAVISIBILIDADEMODALINFORMACAORELATORIOGERAL,
        payload: value
    }
}
const modificaInfoUsuarioPainelAdm = (dispatch, value) => {
    dispatch({
        type: MODIFICAINFOUSUARIOPAINELADM,
        payload: value
    });
};
const modificaMensagemGerandoRelatorio = (dispatch, value) => {
    dispatch({
        type: MODIFICAMENSAGEMGERANDORELATORIO,
        payload: value
    });
}

function modificaMensagemGerandoRelatorioLocal(value) {
    //
    return {
        type: MODIFICAMENSAGEMGERANDORELATORIO,
        payload: value
    }
}

export const zeraMensagemGerandoRelatorio = () => {
    return {
        type: MODIFICAMENSAGEMGERANDORELATORIO,
        payload: ''
    }
}
const modificaVisibilidadeModalInformacaoRelatorioGeralLocal = (dispatch, value) => {
    dispatch({
        type: MODIFICAVISIBILIDADEMODALINFORMACAORELATORIOGERAL,
        payload: value
    });
}


export const modificaVisibilidadeModalInformacaoRelatorioDetalhado = (value) => {
    return {
        type: MODIFICAVISIBILIDADEMODALINFORMACAORELATORIODETALHADO,
        payload: value
    }
}
const modificaVisibilidadeModalInformacaoRelatorioDetalhadoLocal = (dispatch, value) => {
    dispatch({
        type: MODIFICAVISIBILIDADEMODALINFORMACAORELATORIODETALHADO,
        payload: value
    });
}


const ativaDesativaLoadingRelatorioGeral = (dispatch, value) => {
    dispatch({
        type: ATIVADESATIVALOADINGRELATORIOGERAL,
        payload: value
    });
};
const enviaPeriodoPersonalizadoRelatorioGeral = (dispatch, value) => {
    dispatch({
        type: ENVIAPERIODOPERSONALIZADORELATORIOGERAL,
        payload: value
    });
};
export const modificaVisibilidadeModalExportarClientes = (value) => {
    return {
        type: MODIFICAVISIBILIDADEMODALEXPORTARCLIENTES,
        payload: value
    };
};


const verificaInternetTelaVIP = (dispatch, value) => {
    dispatch({
        type: VERIFICAINTERNETTELAVIP,
        payload: value
    });
};
const loadingTelalVIP = (dispatch, value) => {
    dispatch({
        type: LOADINGTELAVIP,
        payload: value
    });
};
const loadingRefreshControlVIP = (dispatch, value) => {
    dispatch({
        type: LOADINGREFRESHCONTROLVIP,
        payload: value
    });
};

const mensagemPeriodoPersonalizadoError = (dispatch, error) => {
    dispatch({
        type: MENSAGEMPERIODOPERSONALIZADOERROR,
        payload: error
    });
};
const mensagemPeriodoPersonalizadoRelatorioDetalhadoError = (dispatch, error) => {
    dispatch({
        type: MENSAGEMPERIODOPERSONALIZADORELATORIODETALHADOERROR,
        payload: error
    });
};

const enviaChamadosDetalhadosParaDownload = (dispatch, value) => {
    dispatch({
        type: ENVIACHAMADOSDETALHADOSPARADOWNLOAD,
        payload: value
    });
};
export const zeraMensagemPeriodoPersonalizadoRelatorioDetalhadoError = () => {
    return {
        type: MENSAGEMPERIODOPERSONALIZADORELATORIODETALHADOERROR,
        payload: ''
    }
}

export const zeraMensagemErrorMensagemConsultaPersonalizada = () => {
    return {
        type: MENSAGEMPERIODOPERSONALIZADOERROR,
        payload: ''
    }
}

export const modificaFiltroTelaRelatorioDetalhado = (filtro) => {
    return {
        type: MODIFICAFILTROTELARELATORIODETALHADO,
        payload: filtro
    }
}

export const modificaDataInicialRelatorioDetalhado = (filtro) => {
    return {
        type: MODIFICADATAINICIALRELATORIODETALHADO,
        payload: filtro
    }
}
export const modificaModalDuvidasFrequentes = (value) => {
    return {
        type: MODIFICAMODALDUVIDASFREQUENTES,
        payload: value
    }
}

export const modificaDataFinalRelatorioDetalhado = (filtro) => {
    return {
        type: MODIFICADATAFINALRELATORIODETALHADO,
        payload: filtro
    }
}


export const modificaFiltroClienteSectionedTelaRelatorioDetalhado = (value) => {
    return {
        type: MODIFICAFILTROCLIENTESECTIONEDTELARELATORIODETALHADO,
        payload: value
    }
}
export const modificaFiltroAtendenteSectionedTelaRelatorioDetalhado = (value) => {
    return {
        type: MODIFICAFILTROATENDENTESECTIONEDTELARELATORIODETALHADO,
        payload: value
    }
}
export const modificaFiltroSetorSectionedTelaRelatorioDetalhado = (value) => {
    return {
        type: MODIFICAFILTROSETORSECTIONEDTELARELATORIODETALHADO,
        payload: value
    }
}


export const modificaClienteTelaRelatorioDetalhado = (value) => {
    return {
        type: MODIFICACLIENTETELARELATORIODETALHADO,
        payload: value
    }
}
export const modificaAtendenteRelatorioDetalhado = (value) => {
    return {
        type: MODIFICAATENDENTERELATORIODETALHADO,
        payload: value
    }
}
export const modificaSetorRelatorioDetalhado = (value) => {
    return {
        type: MODIFICASETORRELATORIODETALHADO,
        payload: value
    }
}
export const modificaMotivoCancelamentoTelaVersaoPremium = (value) => {
    return {
        type: MODIFICAMOTIVOCANCELAMENTOTELAVERSAOPREMIUM,
        payload: value
    }
}

const ativaDesativaLoadingSemInternetRelatorioGeral = (dispatch, value) => {
    dispatch({
        type: ATIVADESATIVALOADINGSEMINTERNETRELATORIOGERAL,
        payload: value
    });
};
const enviaAtendimentosNoTotalDashBoard = (dispatch, qtd) => {
    dispatch({
        type: ENVIAATENDIMENTOSNOTOTALDASHBOARD,
        payload: qtd
    });
};
const enviaSemConexaoInternetDashBoard = (dispatch, qtd) => {
    dispatch({
        type: ENVIASEMCONEXAOINTERNETDASHBOARD,
        payload: qtd
    });
};

const ativaDesativaLoadingTelaRelatorioAtendimentoDetalhados = (dispatch, value) => {
    dispatch({
        type: ATIVADESATIVALOADINGTELARELATORIOATENDIMENTODETALHADOS,
        payload: value
    });
};
const enviaSemConexaoInternetTelaRelatorioDetalhado = (dispatch, value) => {
    dispatch({
        type: ENVIASEMCONEXAOINTERNETTELARELATORIODETALHADO,
        payload: value
    });
};
const enviaPeriodoPersonalizadoRelatorioDetalhado = (dispatch, value) => {
    dispatch({
        type: ENVIAPERIODOPERSONALIZADORELATORIODETALHADI,
        payload: value
    });
};

const enviaTempoMedioDeSolucaoAtendimentos = (dispatch, value) => {
    dispatch({
        type: ENVIATEMPOMEDIODESOLUCAOATENDIMENTOS,
        payload: value
    });
};
const enviaTempoMedioDeSolucaoAtendimentosRelatorioGeral = (dispatch, value) => {
    dispatch({
        type: ENVIATEMPOMEDIODESOLUCAOATENDIMENTOSRELATORIOGERAL,
        payload: value
    });
};
const enviaTempoMedioRespostaAtendimentos = (dispatch, value) => {
    dispatch({
        type: ENVIATEMPOMEDIORESPOSTAATENDIMENTOS,
        payload: value
    });
};

const enviaAtendimentosPorStatusRelatorioDetalhado = (dispatch, value) => {
    dispatch({
        type: ENVIAATENDIMENTOSPORSTATUSRELATORIODETALHADO,
        payload: value
    });
};
const enviaTotalAtendimentosPorStatusRelatorioDetalhado = (dispatch, value) => {
    dispatch({
        type: ENVIATOTALATENDIMENTOSPORSTATUSRELATORIODETALHADO,
        payload: value
    });
};
const enviaAtendimentosPorPrioridadeRelatorioDetalhado = (dispatch, value) => {
    dispatch({
        type: ENVIAATENDIMENTOSPORPRIORIDADERELATORIODETALHADO,
        payload: value
    });
};
const enviaTotalAtendimentosPorPRioridadeRelatorioDetalhado = (dispatch, value) => {
    dispatch({
        type: ENVIATOTALATENDIMENTOSPORPRIORIDADERELATORIODETALHADO,
        payload: value
    });
};
const enviaTempoResolucaoChamadosRelatorioDetalhado = (dispatch, value) => {
    dispatch({
        type: ENVIATEMPORESOLUCAOCHAMADOSRELATORIODETALHADO,
        payload: value
    });
};
const enviaTotalAtendimentosPeriodoResolucaoRelatorioDetalhado = (dispatch, value) => {
    dispatch({
        type: ENVIATOTALATENDIMENTOSPERIODORESOLUCAORELATORIODETALHADO,
        payload: value
    });
};
const enviaTotalAtendimentosPorLocalRelatorioDetalhado = (dispatch, value) => {
    dispatch({
        type: ENVIATOTALATENDIMENTOSPORLOCALRELATORIODETALHADO,
        payload: value
    });
};
const enviaAtendimentosPorLocalRelatorioDetalhado = (dispatch, value) => {
    dispatch({
        type: ENVIAATENDIMENTOSPORLOCALRELATORIODETALHADO,
        payload: value
    });
};


const enviaClientesNoTOtalDashBoard = (dispatch, qtd) => {
    dispatch({
        type: ENVIACLIENTESNOTOTALDASHBOARD,
        payload: qtd
    });
};
const enviaAtendimentosHojeDashBoard = (dispatch, qtd) => {
    dispatch({
        type: ENVIATENDIMENTOSHOJEDASHBOARD,
        payload: qtd
    });
};
const enviaAtendimentosDestaSemanaDashBoard = (dispatch, qtd) => {
    dispatch({
        type: ENVIAATENDIMENTOSDESTASEMANADASHBOARD,
        payload: qtd
    });
};
const enviaTotalAtendimentosPorPRioridade = (dispatch, qtd) => {
    dispatch({
        type: ENVIATOTALATENDIMENTOPORPRIORIDADE,
        payload: qtd
    });
};
const enviaAtendimentosPorStatusDashboard = (dispatch, array) => {
    dispatch({
        type: ENVIATENDIMENTOSPORSTATUSDASHBOARD,
        payload: array
    });
};

const enviaAtendimentosPorLocalDashboard = (dispatch, array) => {
    dispatch({
        type: ENVIATENDIMENTOSPORLOCALDASHBOARD,
        payload: array
    });
};
const enviaTotalAtendimentosPorLocalDashboard = (dispatch, array) => {
    dispatch({
        type: ENVIATOALTENDIMENTOSPORLOCALDASHBOARD,
        payload: array
    });
};

const enviaAtendimentosPorPrioridadeDashboard = (dispatch, array) => {
    dispatch({
        type: ENVIATENDIMENTOSPORPRIORIDADEDASHBOARD,
        payload: array
    });
};
const enviaClientesMaisAbriramChamados = (dispatch, clientes) => {
    dispatch({
        type: ENVIACLIENTEMAISABRIRAMCHAMADOS,
        payload: clientes
    });
};
const enviaPermissoesAcessarTelasAtendente = (dispatch, permissoes) => {
    dispatch({
        type: ENVIAPERMISSOESACESSARTELASATENDENTE,
        payload: permissoes
    });
};
const enviaSetoresMaisAbriramChamados = (dispatch, setores) => {
    dispatch({
        type: ENVIASETORESMAISABRIRAMCHAMADOS,
        payload: setores
    });
};
const enviaSeAtendenteEhAdministrador = (dispatch, value) => {
    dispatch({
        type: ENVIASEATENDENTEEHADMINISTRADOR,
        payload: value
    });
};
const enviaTempoResolucaoChamados = (dispatch, array) => {
    dispatch({
        type: ENVIATEMPORESOLUCAOCHAMADOS,
        payload: array
    });
};
const enviaTotalAtendimentosPeriodoResolucao = (dispatch, array) => {
    dispatch({
        type: ENVIATOTALATENDIMENTOSPERIODORESOLUCAO,
        payload: array
    });
};

const ativaDesativaLoadingTelaDashBoard = (dispatch, status) => {
    dispatch({
        type: ATIVADESATIVALOADINGTELADASHBOARD,
        payload: status
    });
};
const ativaDesativaLoadingRenderVersaoPremium = (dispatch, valor) => {
    dispatch({
        type: ATIVADESATIVALOADINGVERSAOPREMIUM,
        payload: valor
    });
};

const enviaNomeAssinante = (dispatch, valor) => {
    dispatch({
        type: ENVIANOMEASSINANTE,
        payload: valor
    });
};
const enviaEmailAssinante = (dispatch, valor) => {
    dispatch({
        type: ENVIAEMAILASSINANTE,
        payload: valor
    });
};

const enviaPremiumVersaoGartis = (dispatch, valor) => {
    dispatch({
        type: ENVIAPREMIUMVERSAOGRATIS,
        payload: valor
    });
};

const enviaTextStatusAssinatura = (dispatch, valor) => {
    dispatch({
        type: ENVIATEXTSTATUSASSINATURA,
        payload: valor
    });
};
const enviaCorTextStatusAssinatura = (dispatch, valor) => {
    dispatch({
        type: ENVIACORTEXTSTATUSASSINATURA,
        payload: valor
    });
};
const ativaDesativaLoadingRefreshControol = (dispatch, status) => {
    dispatch({
        type: ATIVADESATIVALOADINGREFRESHCONTROL,
        payload: status
    });
};
const enviaSeEhVersaoPremium = (dispatch, valor) => {
    dispatch({
        type: ENVIASEEHVERSAOPREMIUM,
        payload: valor
    });
};
const enviaDataValidadeVersaoPremium = (dispatch, valor) => {
    dispatch({
        type: ENVIADATAVALIDADEVERSAOPREMIUM,
        payload: valor
    });
};
const enviaDataCompraVersaoPremium = (dispatch, valor) => {
    dispatch({
        type: ENVIADATACOMPRAVERSAOPREMIUM,
        payload: valor
    });
};

///////////////

const enviaDataValidadeAtualPremium = (dispatch, valor) => {
    dispatch({
        type: ENVIADATAVALIDADEATUALPREMIUM,
        payload: valor
    });
};
const enviaNumeroPedidoCompraPremium = (dispatch, valor) => {
    dispatch({
        type: ENVIANUMEROPEDIDOCOMPRAPREMIUM,
        payload: valor
    });
};
const enviaPlanoPremium = (dispatch, valor) => {
    dispatch({
        type: ENVIAPLANOPREMIUM,
        payload: valor
    });
};
const ativaDesativaLoadingRelatorioCRM = (dispatch, valor) => {
    dispatch({
        type: ATIVADESATIVALOADINGRELATORIOCRM,
        payload: valor
    });
};
export const ativaDesativaLoadingRelatorioCRMExport = (valor) => {
    return {
        type: ATIVADESATIVALOADINGRELATORIOCRM,
        payload: valor
    };
};
const enviaPlataformaPremium = (dispatch, valor) => {
    dispatch({
        type: ENVIAPLATAFORMAPREMIUM,
        payload: valor
    });
};
const enviaQtdAtendentesLiberadosPremium = (dispatch, valor) => {
    dispatch({
        type: ENVIAQUANTIDADEATENDENTELIBERADOPREMIUM,
        payload: valor
    });
};
const enviaStatusAssinaturaPremium = (dispatch, valor) => {
    dispatch({
        type: ENVIASTATUSASSINATURAPREMIUM,
        payload: valor
    });
};
////////////////

const zeraDataInicial = (dispatch) => {
    dispatch({
        type: MODIFICADATAINICIALCONSULTADASHBOARD,
        payload: null
    });
};
const zeraDataFinal = (dispatch) => {
    dispatch({
        type: MODIFICADATAFINALCONSULTADASHBOARD,
        payload: null
    });
};

const zeraDataInicialFiltroRelatorioDetalhado = (dispatch) => {
    dispatch({
        type: ZERADATAINICIALFILTRORELATORIODETALHADO,
        payload: null
    });
};

const zeraDataInicialFiltroRelatorioGeral = (dispatch) => {
    dispatch({
        type: ZERADATAINICIALFILTRORELATORIOGERAL,
        payload: null
    });
};
const zeraDataFinalFiltroRelatorioGeral = (dispatch) => {
    dispatch({
        type: ZERADATAFINALFILTRORELATORIOGERAL,
        payload: null
    });
};
const enviaPessoasComMaisAtendimentosParaDownload = (dispatch, array) => {
    dispatch({
        type: ENVIAPESSOASCOMMAISATENDIMENTOSPARADOWNLOAD,
        payload: array
    });
};
const enviaPlanosMaisAssinantesPraBaixar = (dispatch, array) => {
    dispatch({
        type: ENVIAPLANOSMAISASSINANTESPRABAIXAR,
        payload: array
    });
};
const zeraDataFinalFiltroRelatorioDetalhado = (dispatch) => {
    dispatch({
        type: ZERADATAFINALFILTRORELATORIODETALHADO,
        payload: null
    });
}

const modificaMensagemGerandoRelatorioVisaoGeral = (dispatch, value) => {
    dispatch({
        type: MODIFICAMENSAGEMGERANDORELATORIOVISAOGERAL,
        payload: value
    });
}
const modificaToastAcoesAtendimento = (dispatch, value) => {
    dispatch({
        type: MODIFICATOASTACOESATNEDIMENTO,
        payload: value
    });
}
export const zeraToastAcoesAtendimento = value => {
    return {
        type: MODIFICATOASTACOESATNEDIMENTO,
        payload: value
    };
};
export const zeraModificaMensagemGerandoRelatorioVisaoGeral = () => {
    return {
        type: MODIFICAMENSAGEMGERANDORELATORIOVISAOGERAL,
        payload: ''
    };
};


export const modificaDataInicialConsultaDashBoard = data => {
    return {
        type: MODIFICADATAINICIALCONSULTADASHBOARD,
        payload: data
    };
};
export const modificaDataFinalConsultaDashBoard = data => {
    return {
        type: MODIFICADATAFINALCONSULTADASHBOARD,
        payload: data
    };
};

const enviaNomeParaReducer = (dispatch, nome) => {
    dispatch({
        type: CARREGANOMEPERFIL,
        payload: nome
    });
}
const enviaEmailParaReducer = (dispatch, email) => {
    dispatch({
        type: CARREGAEMAILPERFIL,
        payload: email
    });

}
const enviaKeyUsuarioPerfil = (dispatch, key) => {
    dispatch({
        type: ENVIAKEYUSUARIOPERFIL,
        payload: key
    });
}
const enviaAdmVisualizaApenasChamadosDoSetorVinculado = (dispatch, key) => {
    dispatch({
        type: ENVIAADMVISUALIZAAPENASCHAMADOSDOSETORVINCULADO,
        payload: key
    });
}
const enviaImagemPerfil = (dispatch, img) => {
    dispatch({
        type: CARREGAIMAGEMPERFIL,
        payload: img
    });
}

const enviaEstadosComMaisClientes = (dispatch, img) => {
    dispatch({
        type: ENVIAESTADOSCOMAMAISCLIENTES,
        payload: img
    });
}
const ativaDesativaLoadingCarregandoChamadosEmAtendimento = (dispatch, value) => {
    dispatch({
        type: ATIVADESATIVALOADINGCARREGANDOCHAMADOSEMATENDIMENTO,
        payload: value
    });
}
const enviaSegmentosComMaisClientes = (dispatch, img) => {
    dispatch({
        type: ENVIASEGMENTOSCOMMAISCLIENTES,
        payload: img
    });
}
const enviaSegmentosComMaisCancelamentos = (dispatch, img) => {
    dispatch({
        type: ENVIASEGMENTOSCOMMAISCANCELAMENTOS,
        payload: img
    });
}


const modificaSolicitarNomeClientePrimeirocontato = (dispatch, valor) => {
    dispatch({
        type: MODIFICASOLICITARNOMECLIENTEPRIMEIROCONTATO,
        payload: valor
    });
};
export const ativaDesativaRenovarPLano = value => {
    return {
        type: ATIVADESATIVARENOVARPLANO,
        payload: value
    };
};

export const modificaMostraVersaoExpirando = value => {
    return {
        type: MODIFICAMOSTRAVERSAOEXPIRANDO,
        payload: value
    };
};

export const modificaDataInicialRelatorioGeral = data => {
    return {
        type: MODIFICADATAINICIALRELATORIOGERAL,
        payload: data
    };
};
export const modificaDataFinalRelatorioGeral = data => {
    return {
        type: MODIFICADATAFINALRELATORIOGERAL,
        payload: data
    };
};
const modificaChamadosEmAtendimentoViaChat = (dispatch, array) => {
    dispatch({
        type: MODIFICACHAMADOSEMATENDIMENTOVIACHAT,
        payload: array
    })
};

const modificaContainerListaEmpresa = (dispatch, array) => {
    dispatch({
        type: MODIFICACONTAINERLISTAEMPRESA,
        payload: array
    })
};
const modificaUrlApiListaEmpresa = (dispatch, array) => {
    dispatch({
        type: MODIFICAURLAPILISTAEMPRESA,
        payload: array
    })
};
const modificaNumeroConectadoWpp = (dispatch, number) => {
    dispatch({
        type: MODIFICANUMEROCONECTADOWPP,
        payload: number
    })
};

function ordenaObjeto(obj) {
    var arr = [];
    for (var prop in obj) {
        if (obj.hasOwnProperty(prop)) {
            arr.push({
                'key': prop,
                'value': obj[prop]
            });
        }
    }
    arr.sort(function (a, b) {
        return a.value - b.value;
    });
    //arr.sort(function(a, b) { a.value.toLowerCase().localeCompare(b.value.toLowerCase()); }); //use this to sort as strings
    return arr.reverse(); // returns array
}

function retornaDataTimeStamp(data) {
    var myDate = data;
    myDate = myDate.split("/");
    var newDate = myDate[1] + "/" + myDate[0] + "/" + myDate[2];
    return new Date(newDate).getTime();
}

function retornaDataAtual() {
    let dataAtual = moment().toDate();
    dataAtual = moment(dataAtual).format('DD/MM/YYYY');
    return dataAtual;
}

function retornaMedia(arrayComValores) {
    const total = arrayComValores.reduce((acc, c) => acc + c, 0);
    return total / arrayComValores.length;
}

function calculaDias2(date1, date2) {
    //formato do brasil 'pt-br'
    // moment.locale('pt-br');
    //setando data1
    let data1 = moment(date1, 'DD/MM/YYYY');
    //setando data2
    let data2 = moment(date2, 'DD/MM/YYYY');
    //tirando a diferenca da data2 - data1 em dias
    // let diff = data2.diff(data1, 'days');
    let diff = moment.duration(data2.diff(data1)).asDays();

    return diff;
}

function encurtaTexto(text, length) {
    //
    //
    //
    if (text == null) {
        return "";
    }
    if (text.length <= length) {
        return text;
    }
    text = text.substring(0, length);
    let last = text.lastIndexOf(" ");
    text = text.substring(0, last);
    //
    return text + "...";
}

const AsyncStorage = {
    setItem: function (key, value) {
        return Promise.resolve().then(function () {
            localStorage.setItem(key, value);
        });
    },
    getItem: function (key) {
        return Promise.resolve().then(function () {
            return localStorage.getItem(key);
        });
    }
};

function toDataUrl(url, callback) {
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
        const reader = new FileReader();
        reader.onloadend = () => {
            callback(reader.result);
        };
        reader.readAsDataURL(xhr.response);
        //
        //
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
};

function _renderImages(url) {
    toDataUrl(url, (myBase64) => {

        //
        //
        return myBase64
    });


}

function download(url, name) {
    const a = document.createElement('a');
    a.href = url;
    a.download = name;
    a.click();
    window.URL.revokeObjectURL(url);
};

function s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) {
        view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
}

function fetchImage(url) {
    return fetch(url)
        .then((response) => response.blob())
        .then(
            (blob) =>
                new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onloadend = () => resolve(reader.result);
                    reader.onerror = reject;
                    reader.readAsDataURL(blob);
                })
        );
}

function minutes_to_hhmm(numberOfMinutes) {
    //create duration object from moment.duration
    var duration = moment.duration(numberOfMinutes, 'minutes');

    //calculate hours
    var hh = (duration.years() * (365 * 24)) + (duration.months() * (30 * 24)) + (duration.days() * 24) + (duration.hours());

    //get minutes
    var mm = duration.minutes();

    //return total time in hh:mm format
    return hh + 'h ' + mm + 'm';
}
function isFileImage(mimeType) {
    return mimeType.split('/')[0] === 'image' && (mimeType.split('/')[1] === 'png' || mimeType.split('/')[1] === 'jpeg');
};
function verificaSeEhAlphabeto(ch, dispatch) {
    //
    let verificaSeEhAlphabeto = typeof ch === "string" && ch.length === 1
        && (ch >= "a" && ch <= "z");
    //
    //
    if (verificaSeEhAlphabeto) {
        //
        switch (ch) {
            case "a":
                let a = require('../imagens/a.png')
                enviaImagemPerfil(dispatch, a);
                break;
            case "b":
                let b = require('../imagens/b.png');
                enviaImagemPerfil(dispatch, b);
                break;
            case "c":
                let c = require('../imagens/c.png');
                enviaImagemPerfil(dispatch, c);
                break;
            case "d":
                let d = require('../imagens/d.png');
                enviaImagemPerfil(dispatch, d);
                break;
            case "e":
                let e = require('../imagens/e.png');
                enviaImagemPerfil(dispatch, e);
                break;
            case "f":
                let f = require('../imagens/f.png');
                enviaImagemPerfil(dispatch, f);
                break;
            case "g":
                let g = require('../imagens/g.png');
                enviaImagemPerfil(dispatch, g)
                break;
            case "h":
                let h = require('../imagens/h.png');
                enviaImagemPerfil(dispatch, h);
                break;
            case "i":
                let i = require('../imagens/i.png');
                enviaImagemPerfil(dispatch, i);
                break;
            case "j":
                let j = require('../imagens/j.png');
                enviaImagemPerfil(dispatch, j);
                break;
            case "k":
                let k = require('../imagens/k.png');
                enviaImagemPerfil(dispatch, k);
                break;
            case "l":
                let l = require('../imagens/l.png');
                enviaImagemPerfil(dispatch, l);
                break;
            case "m":
                let m = require('../imagens/m.png');
                enviaImagemPerfil(dispatch, m);
                break;
            case "n":
                let n = require('../imagens/n.png');
                enviaImagemPerfil(dispatch, n);
                break;
            case "o":
                let o = require('../imagens/o.png');
                enviaImagemPerfil(dispatch, o);
                break;
            case "p":
                let p = require('../imagens/p.png');
                enviaImagemPerfil(dispatch, p);
                break;
            case "q":
                let q = require('../imagens/q.png');
                enviaImagemPerfil(dispatch, q);
                break;
            case "r":
                let r = require('../imagens/r.png');
                enviaImagemPerfil(dispatch, r);
                break;
            case "s":
                let s = require('../imagens/s.png');
                enviaImagemPerfil(dispatch, s);
                break;
            case "t":
                let t = require('../imagens/t.png');
                enviaImagemPerfil(dispatch, t);
                break;
            case "u":
                let u = require('../imagens/u.png');
                enviaImagemPerfil(dispatch, u);
                break;
            case "v":
                let v = require('../imagens/v.png');
                enviaImagemPerfil(dispatch, v);
                break;
            case "x":
                let x = require('../imagens/x.png');
                enviaImagemPerfil(dispatch, x);
                break;
            case "z":
                let z = require('../imagens/z.png');
                enviaImagemPerfil(dispatch, z);
                break;
            case "w":
                let w = require('../imagens/w.png');
                enviaImagemPerfil(dispatch, w);
                break;
            case "y":
                let y = require('../imagens/y.png');
                enviaImagemPerfil(dispatch, y);
                break;
            default:
                let defaultimage = require('../imagens/userPadrao.png');
                enviaImagemPerfil(dispatch, defaultimage);
                //
                break;

        }
    } else {
        //
        let urlIMG = require('../imagens/userPadrao.png')
        enviaImagemPerfil(dispatch, urlIMG);
    }



}

async function getFileFromUrl(url, name, defaultType = 'image/jpeg') {
    const response = await fetch(url);

    const data = await response.blob();
    // console.log(data)
    return new File([data], name, {
        type: data.type || defaultType,
    });
}

async function fileToBase64(file) {
    return new Promise(async (resolve, reject) => {
        const filereader = new FileReader();
        filereader.readAsDataURL(file);
        filereader.onload = function (evt) {
            // console.log(evt)
            const base64 = evt.target.result;
            //resolve(`data:${file.type};base64,${base64}`);
            resolve(base64);
        }
    }).then((base64) => {
        return base64;
    })
}
async function getBase64FromImageUrl(url, type) {
    return new Promise(async (resolve, reject) => {
        const img = new Image();

        img.setAttribute('crossOrigin', 'anonymous');
        img.src = url;

        img.onload = function () {
            const canvas = document.createElement("canvas");
            canvas.width = this.width;
            canvas.height = this.height;

            const ctx = canvas.getContext("2d");
            ctx.drawImage(this, 0, 0);

            const dataURL = canvas.toDataURL(type);
            return resolve(dataURL);

        };
        img.onerror = function(err) {
            return resolve(null);
        }
    }).then((base64) => {
        return base64;
    }).catch((err) => {
        return null;
    })

}