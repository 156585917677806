import api from '../../http_client/http.client';

const AsyncStorage = {
    setItem: function (key, value) {
        return Promise.resolve().then(function () {
            localStorage.setItem(key, value);
        });
    },
    getItem: function (key) {
        return Promise.resolve().then(function () {
            return localStorage.getItem(key);
        });
    }
};

const loadContacts = async (search, page) => {

    const idCompany = await AsyncStorage.getItem('chaveUnica');
    const _idCompany = JSON.parse(idCompany);

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept': 'application/json',
        },
        data: { search: search, idCompany: _idCompany, page: page, isMyContact: true },
        url: 'searchClients',
    };

    const response = await api(options);
    const result = response.data;
    const parseResult = result.data.map((entry) => { return { ...entry, key: entry.idUser, phone: entry.telefoneCliente, } });
    return parseResult;
};

export default loadContacts;
