import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-responsive-modal';
import FlatList from 'flatlist-react';
import Avatar from "react-avatar";
import { withRouter } from "react-router-dom";
import FlatListAtendentes from "./FlatListAtendentes";
import { shade, opacify, lighten, darken } from 'polished';
import ReactTooltip from "react-tooltip";
import styled from 'styled-components';
import SelectSearch from 'react-select-search';
import { toastr } from 'react-redux-toastr';
import firebase from '../../servicos/FirebaseConnection';
import MoonLoader from "react-spinners/MoonLoader";
import imgTeste from '../../imagens/userPadrao.png';
import ClipLoader from "react-spinners/ClipLoader";
import Dropzone from 'react-dropzone';
import imglink from '../../imagens/link.png'
import imgunlink from '../../imagens/unlink.png'
import imgexcluirCliente from '../../imagens/delete-user.png';
import { modificaPesquisaTeste, modificaNomeRotaNoHeader } from '../../actions/HeaderAction';
import { desligaOuvintesChamadosFirebase } from "../../actions/DashBoardAction";

import {
    modificaSenhaAtendente, modificaConfirmaSenhaAtendente, alteraSwitchPermissaoAlterarAtendente,
    alteraSwitchPermissaoAlterarSetores, alteraSwitchPermissaoAlterarAvisos, alteraSwitchPermissaoAcessoRelatorios,
    modificaNomeAtendente, modificaEmailAcessoApp, alteraSwitchPermissaoAlterarCliente, alteraSwitchPermissaoAlterarAdministrador,
    modificaFiltroClientesTelaAtendentes, mudaVisibilidadeModalExcluirAtendente,
    modificaVisibleModalNovoAtendente, consultaAtendente2, insereFotoAtendenteTelaAlterar,
    modificaArquivoCompletoAtendenteParaUpar, modificaAtendenteSelecionadoSectioned, listaAtendentesTelaConsultaAtendente,
    consultaAtendentecomAtendente, alterarAtendente, excluiAtendente,

    alteraSwitchPermissaoAcessoRelatoriosNovoCadastro, alteraSwitchPermissaoAlterarAvisosNovoCadastro,
    alteraSwitchPermissaoAlterarSetoresNovoCadastro, modificaNomeAtendenteNovoCadastro,
    modificaEmailAcessoAppNovoCadastro, modificaSenhaAtendenteNovoCadastro,
    modificaConfirmaSenhaAtendenteNovoCadastro, alteraSwitchPermissaoAlterarAdministradorNovoCadastro,
    alteraSwitchPermissaoAlterarClienteNovoCadastro, alteraSwitchPermissaoAlterarAtendenteNovoCadastro,
    insereFotoCadastroAtendente, modificaArquivoCompletoAtendenteParaUparNovoCadastro,
    cadastrarAtendente, modificaPermissaoCancelarAtendimentoNovoCadastro, alteraSwitchPermissaoCancelarAtendimento
} from '../../actions/AtendenteAction';
import { modificaRetornaTelaClienteParaNovoAtendimento } from '../../actions/ChamadoAction';
import { acessouMeuPErfil } from '../../actions/PerfilAction';

import {
    ContainerDadosCliente, Container, IconeSair, ContainerInputSearch, SubContainer, SubContainerCliente,
    ContainerImagemAlterarCliente, ContainerPrincipalDadosCliente, InputEmpresa, SubContainerClientePermissao,
    ContainerPermitir, TextPermitir, ContainerDadosLoginCliente, ContainerBotaoAlterar, ButtonEntrar,
    DivIconeExcluirCliente, IconeExcluirCliente, DivContainerInputSenha, IconeMostrarSenha,
    IconeEmpresaVinculada, DivContainerInputEmpresaVinculada, ContainerModal, ContainerIconeFecharModal,
    TextNovoAtendimento, IconeFechar, ContainerImagemVincularEmpresa, ContainerExplicacaoVincular,
    ButtonVincular, TextVincular, ImagePerfil, IconeEmpresaVinculadaOff, ContainerModalNovoCliente,
    ContainerDadosNovoCliente, ContainerPermissoesAtendentes, ContainerPermissao, TextPermissao,
    TextContaPrincipal, IconeInterrogacao, DivContainerTextPermissoes,
    InputSearch, DivInputSearch, IconeSetaPraBaixo,

    ContainerChamado, ContainerImagem, ContainerDadosChamado,
    ContaineDadosChamadosPrincipal,
    ContainerIconeEFrase, TextCliente,
    SubContainerIconeEFraseClienteAtendente,
    ContainerSeta, TextEmpresa, ImageLoading, ContainerChamadoLoading, TextClienteLoading, ImagemPerfilLoading,
    InputLoadingEmpresa, ContainerDadosClienteLoading, SubContainerClientePermissaoLoading,
    ContainerListaVazia
} from './styled';

const cadeadoAberto = require('../../imagens/cadeadoaberto2.png');
class Atendentes extends React.Component {
    state = {
        filtroTeste: false,
        hasMoreItems: false,
        modalDesvinculaCliente: false,
        offset: 0,
        fotoCliente: imgTeste,
        search: '',
        inputFicticio: '',
        checkFicticio: false,
        maisDados: [],
        tipoInputSenha: 'password',
        tipoInputConfirmaSenha: 'password',
        loading: true,
        visaoInputSenha: false,
        visaoInputConfirmaSenha: false,
        pesquisaCliente: false,
        modalNovoCliente: false,
        modalPesquisaCliente: false,
        modalTornarAdministrador: false,
        modalCadastrarAlterarCliente: false,
        modalCadastrarAlterarAtendente: false,
        modalCadastrarAlterarSetor: false,
        modalCadastrarAlterarAviso: false,
        modalPermissaoCancelarAtendimento: false,
        modalAcessoRelatorio: false,
        uidUsuarioLogado: 'excluirDepoois',
        //uidUsuarioLogado : firebase.auth().currentUser.uid
        testeEmpresas: [
            { value: 'Hera', name: 'Hera' },
            { value: 'Mr Automac', name: 'Mr Automac' },
            { value: 'Autocom', name: 'Autocom' },
        ],
        chamados:
            [
                {
                    key: '1', nome: 'Heverton HevertonHevertonHevertonHevertonHevertonHeverton', numeroChamado: 1, empresa: 'Hera Engenharia de Software LTDA Hera Engenharia de Software LTDAHera Engenharia de Software LTDAs',
                    setor: 'ERP', descricaoProblema: 'Computador Não liga', dataHoraAbertura: '19/10/2020 12:12:01',
                    enderecoCliente: 'Rua Paulo Lavoier', telefoneCliente: '997622111', prioridade: 'Alta', email: 'heverton_farias@hotmail.com heverton_farias@hotmail.com heverton_farias@hotmail.com',
                    permissaoAPP: false, senha: '123456',
                    permissaoAcessoRelatorios: true, permissaoAdministrador: true, permissaoAlterarCadastrarAtendente: true,
                    permissaoAlterarCadastrarAvisos: true, permissaoAlterarCadastrarCliente: true, permissaoAlterarCadastrarSetores: true,
                    contaPrincipal: true, StringAdmin: 'Admin'
                },
                {
                    key: '2', nome: 'Thalita Amorim', numeroChamado: 2, empresa: 'Play games',
                    setor: 'Funeraria', descricaoProblema: 'Sistema não está imprimindo na impressora A4', dataHoraAbertura: '19/10/2020 12:12:01',
                    enderecoCliente: 'Rua Paulo de Tarso', telefoneCliente: '3655-5878', prioridade: 'Normal', email: 'th@hotmail.com',
                    permissaoAPP: true, senha: '123456',
                    permissaoAcessoRelatorios: false, permissaoAdministrador: false, permissaoAlterarCadastrarAtendente: false,
                    permissaoAlterarCadastrarAvisos: true, permissaoAlterarCadastrarCliente: false, permissaoAlterarCadastrarSetores: true,
                    contaPrincipal: false, StringAdmin: ''
                },
                {
                    key: '3', nome: 'Sérgio Andrade', numeroChamado: 3, empresa: 'DropDesk',
                    setor: 'App', descricaoProblema: 'Aplicativo não transmite dados', dataHoraAbertura: '19/10/2020 12:12:01',
                    enderecoCliente: 'não Informado', telefoneCliente: '26591940', prioridade: 'Urgente', email: 'sergio.andradee@hotmail.com',
                    permissaoAPP: false, senha: '123456', contaPrincipal: false,
                    permissaoAcessoRelatorios: true, permissaoAdministrador: true, permissaoAlterarCadastrarAtendente: true,
                    permissaoAlterarCadastrarAvisos: true, permissaoAlterarCadastrarCliente: true, permissaoAlterarCadastrarSetores: true,
                    StringAdmin: 'Admin'
                },
                {
                    key: '4', nome: 'João Victor', numeroChamado: 4, empresa: 'Jiu Jtsu',
                    setor: 'Faixa', descricaoProblema: 'Faixa ruim', dataHoraAbertura: '19/10/2020 12:12:01',
                    enderecoCliente: 'Não informado', telefoneCliente: '99762412', prioridade: 'Baixa', email: 'jogramador@hotmail.com',
                    permissaoAPP: true, senha: '123456', contaPrincipal: false,
                    permissaoAcessoRelatorios: true, permissaoAdministrador: true, permissaoAlterarCadastrarAtendente: true,
                    permissaoAlterarCadastrarAvisos: true, permissaoAlterarCadastrarCliente: true, permissaoAlterarCadastrarSetores: true,
                    StringAdmin: 'Admin'
                },
                {
                    key: '5', nome: 'Andre fer', numeroChamado: 5, empresa: 'Andrelinos',
                    setor: 'Não informado', descricaoProblema: 'No Break Ruim', dataHoraAbertura: '19/10/2020 12:12:01',
                    enderecoCliente: 'Ceciliano Prado', telefoneCliente: '997622111', prioridade: 'Não informado', email: 'Andre@mr.com',
                    permissaoAPP: false, senha: '123456',
                    permissaoAcessoRelatorios: true, permissaoAdministrador: false, permissaoAlterarCadastrarAtendente: true,
                    permissaoAlterarCadastrarAvisos: true, permissaoAlterarCadastrarCliente: true, permissaoAlterarCadastrarSetores: true,
                    contaPrincipal: false, StringAdmin: ''
                },
                {
                    key: '6', nome: 'Heverton', numeroChamado: 1, empresa: 'Hera Engenharia de Software LTDA',
                    setor: 'ERP', descricaoProblema: 'Computador Não liga', dataHoraAbertura: '19/10/2020 12:12:01',
                    enderecoCliente: 'Rua Paulo Lavoier', telefoneCliente: '997622111', prioridade: 'Alta', email: 'heverton_farias@hotmail.com',
                    permissaoAPP: false, senha: '123456',
                    permissaoAcessoRelatorios: true, permissaoAdministrador: true, permissaoAlterarCadastrarAtendente: true,
                    permissaoAlterarCadastrarAvisos: true, permissaoAlterarCadastrarCliente: true, permissaoAlterarCadastrarSetores: true,
                    contaPrincipal: true, StringAdmin: 'Admin'
                },
                {
                    key: '7', nome: 'Thalita Amorim', numeroChamado: 2, empresa: 'Play games',
                    setor: 'Funeraria', descricaoProblema: 'Sistema não está imprimindo na impressora A4', dataHoraAbertura: '19/10/2020 12:12:01',
                    enderecoCliente: 'Rua Paulo de Tarso', telefoneCliente: '3655-5878', prioridade: 'Normal', email: 'th@hotmail.com',
                    permissaoAPP: true, senha: '123456',
                    permissaoAcessoRelatorios: false, permissaoAdministrador: false, permissaoAlterarCadastrarAtendente: false,
                    permissaoAlterarCadastrarAvisos: true, permissaoAlterarCadastrarCliente: false, permissaoAlterarCadastrarSetores: true,
                    contaPrincipal: false, StringAdmin: ''
                },
                {
                    key: '8', nome: 'Sérgio Andrade', numeroChamado: 3, empresa: 'DropDesk',
                    setor: 'App', descricaoProblema: 'Aplicativo não transmite dados', dataHoraAbertura: '19/10/2020 12:12:01',
                    enderecoCliente: 'não Informado', telefoneCliente: '26591940', prioridade: 'Urgente', email: 'sergio.andradee@hotmail.com',
                    permissaoAPP: false, senha: '123456', contaPrincipal: false,
                    permissaoAcessoRelatorios: true, permissaoAdministrador: true, permissaoAlterarCadastrarAtendente: true,
                    permissaoAlterarCadastrarAvisos: true, permissaoAlterarCadastrarCliente: true, permissaoAlterarCadastrarSetores: true,
                    StringAdmin: 'Admin'
                },
                {
                    key: '9', nome: 'João Victor', numeroChamado: 4, empresa: 'Jiu Jtsu',
                    setor: 'Faixa', descricaoProblema: 'Faixa ruim', dataHoraAbertura: '19/10/2020 12:12:01',
                    enderecoCliente: 'Não informado', telefoneCliente: '99762412', prioridade: 'Baixa', email: 'jogramador@hotmail.com',
                    permissaoAPP: true, senha: '123456', contaPrincipal: false,
                    permissaoAcessoRelatorios: true, permissaoAdministrador: true, permissaoAlterarCadastrarAtendente: true,
                    permissaoAlterarCadastrarAvisos: true, permissaoAlterarCadastrarCliente: true, permissaoAlterarCadastrarSetores: true,
                    StringAdmin: 'Admin'
                },
                {
                    key: '10', nome: 'Andre fer', numeroChamado: 5, empresa: 'Andrelinos',
                    setor: 'Não informado', descricaoProblema: 'No Break Ruim', dataHoraAbertura: '19/10/2020 12:12:01',
                    enderecoCliente: 'Ceciliano Prado', telefoneCliente: '997622111', prioridade: 'Não informado', email: 'Andre@mr.com',
                    permissaoAPP: false, senha: '123456',
                    permissaoAcessoRelatorios: true, permissaoAdministrador: false, permissaoAlterarCadastrarAtendente: true,
                    permissaoAlterarCadastrarAvisos: true, permissaoAlterarCadastrarCliente: true, permissaoAlterarCadastrarSetores: true,
                    contaPrincipal: false, StringAdmin: ''
                },
                {
                    key: '11', nome: 'Heverton', numeroChamado: 1, empresa: 'Hera Engenharia de Software LTDA',
                    setor: 'ERP', descricaoProblema: 'Computador Não liga', dataHoraAbertura: '19/10/2020 12:12:01',
                    enderecoCliente: 'Rua Paulo Lavoier', telefoneCliente: '997622111', prioridade: 'Alta', email: 'heverton_farias@hotmail.com',
                    permissaoAPP: false, senha: '123456',
                    permissaoAcessoRelatorios: true, permissaoAdministrador: true, permissaoAlterarCadastrarAtendente: true,
                    permissaoAlterarCadastrarAvisos: true, permissaoAlterarCadastrarCliente: true, permissaoAlterarCadastrarSetores: true,
                    contaPrincipal: true, StringAdmin: 'Admin'
                },
                {
                    key: '12', nome: 'Thalita Amorim', numeroChamado: 2, empresa: 'Play games',
                    setor: 'Funeraria', descricaoProblema: 'Sistema não está imprimindo na impressora A4', dataHoraAbertura: '19/10/2020 12:12:01',
                    enderecoCliente: 'Rua Paulo de Tarso', telefoneCliente: '3655-5878', prioridade: 'Normal', email: 'th@hotmail.com',
                    permissaoAPP: true, senha: '123456',
                    permissaoAcessoRelatorios: false, permissaoAdministrador: false, permissaoAlterarCadastrarAtendente: false,
                    permissaoAlterarCadastrarAvisos: true, permissaoAlterarCadastrarCliente: false, permissaoAlterarCadastrarSetores: true,
                    contaPrincipal: false, StringAdmin: ''
                },
                {
                    key: '13', nome: 'Sérgio Andrade', numeroChamado: 3, empresa: 'DropDesk',
                    setor: 'App', descricaoProblema: 'Aplicativo não transmite dados', dataHoraAbertura: '19/10/2020 12:12:01',
                    enderecoCliente: 'não Informado', telefoneCliente: '26591940', prioridade: 'Urgente', email: 'sergio.andradee@hotmail.com',
                    permissaoAPP: false, senha: '123456', contaPrincipal: false,
                    permissaoAcessoRelatorios: true, permissaoAdministrador: true, permissaoAlterarCadastrarAtendente: true,
                    permissaoAlterarCadastrarAvisos: true, permissaoAlterarCadastrarCliente: true, permissaoAlterarCadastrarSetores: true,
                    StringAdmin: 'Admin'
                },
                {
                    key: '14', nome: 'João Victor', numeroChamado: 4, empresa: 'Jiu Jtsu',
                    setor: 'Faixa', descricaoProblema: 'Faixa ruim', dataHoraAbertura: '19/10/2020 12:12:01',
                    enderecoCliente: 'Não informado', telefoneCliente: '99762412', prioridade: 'Baixa', email: 'jogramador@hotmail.com',
                    permissaoAPP: true, senha: '123456', contaPrincipal: false,
                    permissaoAcessoRelatorios: true, permissaoAdministrador: true, permissaoAlterarCadastrarAtendente: true,
                    permissaoAlterarCadastrarAvisos: true, permissaoAlterarCadastrarCliente: true, permissaoAlterarCadastrarSetores: true,
                    StringAdmin: 'Admin'
                },
                {
                    key: '15', nome: 'Andre fer', numeroChamado: 5, empresa: 'Andrelinos',
                    setor: 'Não informado', descricaoProblema: 'No Break Ruim', dataHoraAbertura: '19/10/2020 12:12:01',
                    enderecoCliente: 'Ceciliano Prado', telefoneCliente: '997622111', prioridade: 'Não informado', email: 'Andre@mr.com',
                    permissaoAPP: false, senha: '123456',
                    permissaoAcessoRelatorios: true, permissaoAdministrador: false, permissaoAlterarCadastrarAtendente: true,
                    permissaoAlterarCadastrarAvisos: true, permissaoAlterarCadastrarCliente: true, permissaoAlterarCadastrarSetores: true,
                    contaPrincipal: false, StringAdmin: ''
                },
                /* {
                    key: '6', nome: 'Aldair Fernandes', numeroChamado: 6, empresa: 'Hera Engenharia de Software LTDA',
                    setor: 'Financeiro', descricaoProblema: 'Computador Não liga', dataHoraAbertura: '19/10/2020 12:12:01',
                    status: 'Aberto', atendente: 'João Victor', prioridade: 'Urgente'
                },
                {
                    key: '7', nome: 'Aldair Fernandes', numeroChamado: 7, empresa: 'Hera Engenharia de Software LTDA',
                    setor: 'Financeiro', descricaoProblema: 'Computador Não liga', dataHoraAbertura: '19/10/2020 12:12:01',
                    status: 'Aberto', atendente: 'João Victor', prioridade: 'Urgente'
                },
                {
                    key: '8', nome: 'Aldair Fernandes', numeroChamado: 8, empresa: 'Hera Engenharia de Software LTDA',
                    setor: 'Financeiro', descricaoProblema: 'Computador Não liga', dataHoraAbertura: '19/10/2020 12:12:01',
                    status: 'Aberto', atendente: 'João Victor', prioridade: 'Urgente'
                },
                {
                    key: '9', nome: 'Aldair Fernandes', numeroChamado: 9, empresa: 'Hera Engenharia de Software LTDA',
                    setor: 'Financeiro', descricaoProblema: 'Computador Não liga', dataHoraAbertura: '19/10/2020 12:12:01',
                    status: 'Aberto', atendente: 'João Victor', prioridade: 'Urgente'
                },
                {
                    key: '10', nome: 'Aldair Fernandes', numeroChamado: 10, empresa: 'Hera Engenharia de Software LTDA',
                    setor: 'Financeiro', descricaoProblema: 'Computador Não liga', dataHoraAbertura: '19/10/2020 12:12:01',
                    status: 'Aberto', atendente: 'João Victor', prioridade: 'Urgente'
                }, */
            ]
    }
    componentDidMount() {
        this.props.listaAtendentesTelaConsultaAtendente();
        // 
        if (this.props.permissoesAcessarTelasAtendente.permissaoAlterarCadastrarAtendente == true) {
            if (this.props.acessouAtravesPerfil == true) {
                //veio através do perfil
                this.props.modificaAtendenteSelecionadoSectioned(this.props.keyUsuarioLogado);
                this.props.consultaAtendentecomAtendente(this.props.keyUsuarioLogado)
            } else {
                //acessou sem ser pelo MEu Peerfil
                this.props.consultaAtendente2(0, '', [], this.props.qtdAtendentesLiberados);
            }
            ReactTooltip.rebuild();
            if (this.props.nomeRota !== 'Atendentes') {
                this.props.modificaNomeRotaNoHeader('Atendentes')
            }
        } else {
            toastr.error('Acesso Negado', 'Você não tem permissão para acessar esta tela');
            this.props.history.push('/dashboard')
        }
        /*    alert(this.props.contaPrincipal) */
    }

    componentWillUnmount() {
        this.props.desligaOuvintesChamadosFirebase('atendentes', '', '', '');
    }
    componentWillReceiveProps(nextProps) {
        setTimeout(() => {
            ReactTooltip.rebuild();
        }, 1000);

        /* if (this.props.keyAtendente !== nextProps.keyAtendente) {
            ReactTooltip.rebuild();
        } */
        if (this.props.acessouAtravesPerfil == false && nextProps.acessouAtravesPerfil == true) {
            this.props.modificaAtendenteSelecionadoSectioned(this.props.keyUsuarioLogado);
            this.props.consultaAtendentecomAtendente(this.props.keyUsuarioLogado)
        }
        if (this.props.atendenteSelecionadosectioned !== nextProps.atendenteSelecionadosectioned) {
            /*   
             
             
               */
            let clienteAnterior = this.props.atendenteSelecionadosectioned;
            if (clienteAnterior.length > 0) {
                const chaveUnica2 = JSON.parse(localStorage.getItem('chaveUnica'));
                if (chaveUnica2) {
                    const chaveUnica = chaveUnica2.replace(/"/g, "");
                    // 
                    firebase.database().ref(`${chaveUnica}/usuarios/${clienteAnterior}`).off("value");
                    //  
                } else {

                }
            }

        }
    }
    handleSubmit(e) {
        e.preventDefault();
    }
    alteraAtendenteLocal(e) {
        e.preventDefault();
        this.props.alterarAtendente(this.props.nomeAtendente, this.props.emailAtendente, this.props.senhaAtendente, this.props.confirmaSenhaAtendente, this.props.fotoImagemTelaAlterarAtendente, this.props.keyAtendente,
            this.props.permissaoAdministrador, this.props.permissaoAlterarCadastrarCliente, this.props.permissaoAlterarCadastrarAtendente, this.props.permissaoAlterarCadastrarSetores, this.props.permissaoAlterarCadastrarAvisos, this.props.permissaoAcessoRelatorios, this.props.contaPrincipal, this.props.arquivoCompletoAtendenteParaUpar, this.props.permissaoCancelarAtendimento)
    }
    cadastraAtendenteLocal(e) {
        e.preventDefault();
        this.props.cadastrarAtendente(this.props.nomeAtendenteNovoCadastro, this.props.emailAtendenteNovoCadastro, this.props.senhaAtendenteNovoCadastro, this.props.confirmaSenhaAtendenteNovoCadastro, this.props.uriImagemPadraoTelaCadastroAtendente, this.props.premium,
            this.props.permissaoAdministradorNovoCadastro, this.props.permissaoAlterarCadastrarClienteNovoCadastro, this.props.permissaoAlterarCadastrarAtendenteNovoCadastro, this.props.permissaoAlterarCadastrarSetoresNovoCadastro,
            this.props.permissaoAlterarCadastrarAvisosNovoCadastro, this.props.permissaoAcessoRelatoriosNovoCadastro, this.props.qtdAtendentesLiberados, this.props.arquivoCompletoAtendenteParaUparNovoCadastro, this.props.permissaoCancelarAtendimentoNovoCadastro)
    }
    _toggleModalTornarAdministrador() {
        this.setState({ modalTornarAdministrador: !this.state.modalTornarAdministrador });
    }
    _toggleModalCadastrarAlterarCliente() {
        this.setState({ modalCadastrarAlterarCliente: !this.state.modalCadastrarAlterarCliente });
    }
    _toggleModalCadastrarAlterarAtendente() {
        this.setState({ modalCadastrarAlterarAtendente: !this.state.modalCadastrarAlterarAtendente });
    }
    _toggleModalCadastrarAlterarSetor() {
        this.setState({ modalCadastrarAlterarSetor: !this.state.modalCadastrarAlterarSetor });
    }
    _toggleModalCadastrarAlterarAviso() {
        this.setState({ modalCadastrarAlterarAviso: !this.state.modalCadastrarAlterarAviso });
    }
    _toggleModalPermissaoCancelarAtendimentos() {
        this.setState({ modalPermissaoCancelarAtendimento: !this.state.modalPermissaoCancelarAtendimento });
    }
    _toggleModalAcessoRelatorios() {
        this.setState({ modalAcessoRelatorio: !this.state.modalAcessoRelatorio });
    }
    renderIconeExcluirAtendente() {
        if (this.props.contaPrincipal == true) {
            return <DivIconeExcluirCliente style={{ marginTop: 15 }}></DivIconeExcluirCliente>
        } else if (this.props.uidAtendente == this.state.uidUsuarioLogado) {
            return <DivIconeExcluirCliente style={{ marginTop: 15 }}></DivIconeExcluirCliente>
        } else if (this.props.keyUsuarioLogado !== this.props.keyAtendente && this.props.isAdmin == false) {
            return <DivIconeExcluirCliente style={{ marginTop: 15 }}></DivIconeExcluirCliente>
        }  else if (this.props.keyUsuarioLogado === this.props.keyAtendente) {
            return <DivIconeExcluirCliente style={{ marginTop: 15 }}></DivIconeExcluirCliente>
        }  else {
            return (
                <DivIconeExcluirCliente>
                    <IconeExcluirCliente
                        onClick={() => { this.props.mudaVisibilidadeModalExcluirAtendente(true) }}
                        data-tip="Excluir Atendente" data-for="tip-top"
                        className="icon-rubbish-bin"
                        colorDinamico={this.props.coresLetraPadrao} />
                </DivIconeExcluirCliente>
            )
        }

    }
    _toggleMostrarSenha() {
        this.setState({ visaoInputSenha: !this.state.visaoInputSenha });
    }
    _toggleSectionedPesquisaCliente() {
        this.setState({ pesquisaCliente: !this.state.pesquisaCliente });
    }
    _toggleModalPesquisarCliente() {
        this.setState({ modalPesquisaCliente: !this.state.modalPesquisaCliente });
    }
    _toggleModalDesvincularCliente() {
        this.setState({ modalDesvinculaCliente: !this.state.modalDesvinculaCliente });
    }
    handleVisualizaSenha(value) {
        let state = this.state;
        state.tipoInputSenha = value;
        this.setState({ state });
    }
    handleVisualizaConfirmaSenha(value) {
        let state = this.state;
        state.tipoInputConfirmaSenha = value;
        this.setState({ state });
    }
    handleModalNovoCliente(value) {
        let state = this.state;
        state.modalNovoCliente = value;
        this.setState({ state });
    }
    updateSearch = search => {
        let state = this.state;
        state.search = search;
        this.setState({ state });

        // this.searchText(search)
    };
    escolheArquivoChamadoNovoCadastro(res) {
        if (res.length > 0) {
            //
            let uri;
            res.map(file => Object.assign(file, {
                uri: URL.createObjectURL(file)
            }));
            // 
            this.props.modificaArquivoCompletoAtendenteParaUparNovoCadastro(res[0])
            this.props.insereFotoCadastroAtendente(res[0].uri);
        } else {
            toastr.error('Error ao Anexar', 'Selecione uma foto com no máximo 5mb');

        }
    }
    escolheArquivoChamado(res) {
        if (res.length > 0) {
            // 
            let uri;
            res.map(file => Object.assign(file, {
                uri: URL.createObjectURL(file)
            }));
            // 
            this.props.modificaArquivoCompletoAtendenteParaUpar(res[0])
            this.props.insereFotoAtendenteTelaAlterar(res[0].uri);
        } else {
            toastr.error('Error ao Anexar', 'Selecione uma foto com no máximo 5mb');

        }
    }
    renderSenhaConfirmaSenha() {
        if (this.props.keyUsuarioLogado === this.props.keyAtendente) {
            return (
                <> <p style={{ color: this.props.corLetraDescricaoDosCampos, fontWeight: 'bold', fontSize: 12, marginTop: 5 }}>Senha<span style={{ color: 'red', fontSize: 14, marginLeft: 3 }}>*</span></p>
                    <DivContainerInputSenha>
                        <InputEmpresa

                            type={this.state.tipoInputSenha}
                            placeholder="Senha"
                            colorDinamica={this.props.coresLetraPadrao}
                            placeholderDinamico={this.props.coresLetraSecundario}
                            borderDinamico={this.props.corBotaoPadrao}
                            value={this.props.senhaAtendente}
                            onChange={(e) => { this.props.modificaSenhaAtendente(e.target.value) }}
                        //  onFocus={() => { this.handleInputEmailFocus() }}
                        // onBlur={() => { this.handleInputEmailBlur() }}
                        />
                        {this.state.tipoInputSenha == 'password' && < IconeMostrarSenha onClick={() => { this.handleVisualizaSenha('text') }} colorDinamico={this.props.coresLetraPadrao} bordaDinamica={this.props.corBotaoPadrao} className="icon-semvisao" />}
                        {this.state.tipoInputSenha == 'text' && < IconeMostrarSenha onClick={() => { this.handleVisualizaSenha('password') }} colorDinamico={this.props.coresLetraPadrao} bordaDinamica={this.props.corBotaoPadrao} className="icon-comvisao" />}
                    </DivContainerInputSenha>
                    <p style={{ color: this.props.corLetraDescricaoDosCampos, fontWeight: 'bold', fontSize: 12, marginTop: 5 }}>Confirmar Senha<span style={{ color: 'red', fontSize: 14, marginLeft: 3 }}>*</span></p>
                    <DivContainerInputSenha>
                        <InputEmpresa

                            type={this.state.tipoInputConfirmaSenha}
                            placeholder="Confirma Senha"
                            colorDinamica={this.props.coresLetraPadrao}
                            placeholderDinamico={this.props.coresLetraSecundario}
                            borderDinamico={this.props.corBotaoPadrao}
                            value={this.props.confirmaSenhaAtendente}
                            onChange={(e) => { this.props.modificaConfirmaSenhaAtendente(e.target.value) }}
                        //  onFocus={() => { this.handleInputEmailFocus() }}
                        // onBlur={() => { this.handleInputEmailBlur() }}
                        />
                        {this.state.tipoInputConfirmaSenha == 'password' && < IconeMostrarSenha onClick={() => { this.handleVisualizaConfirmaSenha('text') }} colorDinamico={this.props.coresLetraPadrao} bordaDinamica={this.props.corBotaoPadrao} className="icon-semvisao" />}
                        {this.state.tipoInputConfirmaSenha == 'text' && < IconeMostrarSenha onClick={() => { this.handleVisualizaConfirmaSenha('password') }} colorDinamico={this.props.coresLetraPadrao} bordaDinamica={this.props.corBotaoPadrao} className="icon-comvisao" />}
                    </DivContainerInputSenha></>
            )
        } else if (this.props.permissoesAcessarTelasAtendente.contaPrincipal == true) {
            return (
                <> <p style={{ color: this.props.corLetraDescricaoDosCampos, fontWeight: 'bold', fontSize: 12, marginTop: 5 }}>Senha<span style={{ color: 'red', fontSize: 14, marginLeft: 3 }}>*</span></p>
                    <DivContainerInputSenha>
                        <InputEmpresa

                            type={this.state.tipoInputSenha}
                            placeholder="Senha"
                            colorDinamica={this.props.coresLetraPadrao}
                            placeholderDinamico={this.props.coresLetraSecundario}
                            borderDinamico={this.props.corBotaoPadrao}
                            value={this.props.senhaAtendente}
                            onChange={(e) => { this.props.modificaSenhaAtendente(e.target.value) }}
                        //  onFocus={() => { this.handleInputEmailFocus() }}
                        // onBlur={() => { this.handleInputEmailBlur() }}
                        />
                        {this.state.tipoInputSenha == 'password' && < IconeMostrarSenha onClick={() => { this.handleVisualizaSenha('text') }} colorDinamico={this.props.coresLetraPadrao} bordaDinamica={this.props.corBotaoPadrao} className="icon-semvisao" />}
                        {this.state.tipoInputSenha == 'text' && < IconeMostrarSenha onClick={() => { this.handleVisualizaSenha('password') }} colorDinamico={this.props.coresLetraPadrao} bordaDinamica={this.props.corBotaoPadrao} className="icon-comvisao" />}
                    </DivContainerInputSenha>
                    <p style={{ color: this.props.corLetraDescricaoDosCampos, fontWeight: 'bold', fontSize: 12, marginTop: 5 }}>Confirmar Senha<span style={{ color: 'red', fontSize: 14, marginLeft: 3 }}>*</span></p>
                    <DivContainerInputSenha>
                        <InputEmpresa

                            type={this.state.tipoInputConfirmaSenha}
                            placeholder="Confirma Senha"
                            colorDinamica={this.props.coresLetraPadrao}
                            placeholderDinamico={this.props.coresLetraSecundario}
                            borderDinamico={this.props.corBotaoPadrao}
                            value={this.props.confirmaSenhaAtendente}
                            onChange={(e) => { this.props.modificaConfirmaSenhaAtendente(e.target.value) }}
                        //  onFocus={() => { this.handleInputEmailFocus() }}
                        // onBlur={() => { this.handleInputEmailBlur() }}
                        />
                        {this.state.tipoInputConfirmaSenha == 'password' && < IconeMostrarSenha onClick={() => { this.handleVisualizaConfirmaSenha('text') }} colorDinamico={this.props.coresLetraPadrao} bordaDinamica={this.props.corBotaoPadrao} className="icon-semvisao" />}
                        {this.state.tipoInputConfirmaSenha == 'text' && < IconeMostrarSenha onClick={() => { this.handleVisualizaConfirmaSenha('password') }} colorDinamico={this.props.coresLetraPadrao} bordaDinamica={this.props.corBotaoPadrao} className="icon-comvisao" />}
                    </DivContainerInputSenha></>
            )

        } else {
            return null
        }

    }
    renderImagem() {
       
            return (
                <Dropzone
                    accept="image/*"
                    multiple={false}
                    noKeyboard
                    maxSize={5e+6}
                    noDrag
                    onDrop={acceptedFiles => this.escolheArquivoChamado(acceptedFiles)}>
                    {({ getRootProps, getInputProps, isDragActive }) => {

                        return (
                            /*     <section> */
                            <div  {...getRootProps()}>
                                <input {...getInputProps()} />
                                <Avatar
                                    name={this.props.nomeAtendente}
                                    size='150'
                                    src={this.props.fotoImagemTelaAlterarAtendente}
                                    maxInitials={2}
                                    round
                                    style={{ cursor: "pointer" }}
                                />
                               {/*  <ImagePerfil bordaDinamico={this.props.corBotaoPadrao} src={this.props.fotoImagemTelaAlterarAtendente} alt="foto atendnete" /> */}
                            </div>
                            /*   </section> */
                        )
                    }}
                </Dropzone>
            )
    }
    exibeUltimosClientes() {
        if (this.props.loadingConsultaAtendente == false) {
            if (this.props.nomeAtendenteSelecinadoSectioned == '') {
                this.props.consultaAtendente2(1, this.props.referenciaUltimaKeyAtendente, this.props.atendentes, this.props.qtdAtendentesLiberados);
            }
        }
    }
    removeFiltrosAll() {
        //this.props.ativaLoadingTelaAberturaChamadoRemoveFiltro(true)
        setTimeout(() => {
            // this.props.listaOsUltimosChamadosAbertos(0, '', [], 'willmount', this.props.verificaAtendenteVeSomenteProprioAtendimento, this.props.verificaAgrupaAtendenteSetor, this.props.contaPrincipal);
            this.props.modificaFiltroClientesTelaAtendentes('');
            this.props.modificaAtendenteSelecionadoSectioned('');
            this.props.acessouMeuPErfil(false);
            this.props.consultaAtendente2(0, '', [], this.props.qtdAtendentesLiberados);
        }, 200);
    }
    renderLoadingConsultaAtendente() {
        const CheckBoxWrapper = styled.div`
        position: relative;
        margin-left:10px;
      `;
        const CheckBoxLabel = styled.label`
        position: absolute;
        top: 0;
        left: 0;
        width: 42px;
        height: 23px;
        border-radius: 15px;
        background: ${this.props.coresLetraSecundario};
        cursor: pointer;
        &::after {
          content: "";
          display: block;
          border-radius: 50%;
          width: 18px;
          height: 18px;
          margin: 3px;
          background: #ffffff;
          box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
          transition: 0.2s;
        }
      `;
        const CheckBox = styled.input`
        opacity: 0;
        z-index: 1;
        border-radius: 15px;
        width: 42px;
        height: 26px;
        &:checked + ${CheckBoxLabel} {
          background: #00FF7F;
          &::after {
            content: "";
            display: block;
            border-radius: 50%;
            width: 18px;
            height: 18px;
            margin-left: 21px;
            transition: 0.2s;
          }
        }
        &:disabled + ${CheckBoxLabel}{
            background: ${darken(0.2, '#00FF7F')};
        }
      `;
        if (this.props.loadingConsultaAtendente) {
            return (
                <Container>
                    <SubContainer>
                        <ContainerChamadoLoading backGroundDinamico={this.props.backgroundMaisEscuro}>
                            <ContainerImagem>
                                <ImageLoading backgroundinamico={this.props.backgroundMaisEscuro}></ImageLoading>
                                {/*  <img src={this.state.fotoCliente} alt="foto cliente" style={{ height: 40, width: 40, borderRadius: 50 }} /> */}
                            </ContainerImagem>
                            <ContainerDadosChamado>
                                <ContaineDadosChamadosPrincipal>
                                    <SubContainerIconeEFraseClienteAtendente >
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                    <SubContainerIconeEFraseClienteAtendente >
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                </ContaineDadosChamadosPrincipal>


                            </ContainerDadosChamado>


                        </ContainerChamadoLoading>
                        <ContainerChamadoLoading backGroundDinamico={this.props.backgroundMaisEscuro}>
                            <ContainerImagem>
                                <ImageLoading backgroundinamico={this.props.backgroundMaisEscuro}></ImageLoading>
                                {/*  <img src={this.state.fotoCliente} alt="foto cliente" style={{ height: 40, width: 40, borderRadius: 50 }} /> */}
                            </ContainerImagem>
                            <ContainerDadosChamado>
                                <ContaineDadosChamadosPrincipal>
                                    <SubContainerIconeEFraseClienteAtendente >
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                    <SubContainerIconeEFraseClienteAtendente >
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                </ContaineDadosChamadosPrincipal>


                            </ContainerDadosChamado>


                        </ContainerChamadoLoading>
                        <ContainerChamadoLoading backGroundDinamico={this.props.backgroundMaisEscuro}>
                            <ContainerImagem>
                                <ImageLoading backgroundinamico={this.props.backgroundMaisEscuro}></ImageLoading>
                                {/*  <img src={this.state.fotoCliente} alt="foto cliente" style={{ height: 40, width: 40, borderRadius: 50 }} /> */}
                            </ContainerImagem>
                            <ContainerDadosChamado>
                                <ContaineDadosChamadosPrincipal>
                                    <SubContainerIconeEFraseClienteAtendente >
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                    <SubContainerIconeEFraseClienteAtendente >
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                </ContaineDadosChamadosPrincipal>


                            </ContainerDadosChamado>


                        </ContainerChamadoLoading>
                        <ContainerChamadoLoading backGroundDinamico={this.props.backgroundMaisEscuro}>
                            <ContainerImagem>
                                <ImageLoading backgroundinamico={this.props.backgroundMaisEscuro}></ImageLoading>
                                {/*  <img src={this.state.fotoCliente} alt="foto cliente" style={{ height: 40, width: 40, borderRadius: 50 }} /> */}
                            </ContainerImagem>
                            <ContainerDadosChamado>
                                <ContaineDadosChamadosPrincipal>
                                    <SubContainerIconeEFraseClienteAtendente >
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                    <SubContainerIconeEFraseClienteAtendente >
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                </ContaineDadosChamadosPrincipal>


                            </ContainerDadosChamado>


                        </ContainerChamadoLoading>
                        <ContainerChamadoLoading backGroundDinamico={this.props.backgroundMaisEscuro}>
                            <ContainerImagem>
                                <ImageLoading backgroundinamico={this.props.backgroundMaisEscuro}></ImageLoading>
                                {/*  <img src={this.state.fotoCliente} alt="foto cliente" style={{ height: 40, width: 40, borderRadius: 50 }} /> */}
                            </ContainerImagem>
                            <ContainerDadosChamado>
                                <ContaineDadosChamadosPrincipal>
                                    <SubContainerIconeEFraseClienteAtendente >
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                    <SubContainerIconeEFraseClienteAtendente >
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                </ContaineDadosChamadosPrincipal>


                            </ContainerDadosChamado>


                        </ContainerChamadoLoading>
                        <ContainerChamadoLoading backGroundDinamico={this.props.backgroundMaisEscuro}>
                            <ContainerImagem>
                                <ImageLoading backgroundinamico={this.props.backgroundMaisEscuro}></ImageLoading>
                                {/*  <img src={this.state.fotoCliente} alt="foto cliente" style={{ height: 40, width: 40, borderRadius: 50 }} /> */}
                            </ContainerImagem>
                            <ContainerDadosChamado>
                                <ContaineDadosChamadosPrincipal>
                                    <SubContainerIconeEFraseClienteAtendente >
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                    <SubContainerIconeEFraseClienteAtendente >
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                </ContaineDadosChamadosPrincipal>


                            </ContainerDadosChamado>


                        </ContainerChamadoLoading>
                        <ContainerChamadoLoading backGroundDinamico={this.props.backgroundMaisEscuro}>
                            <ContainerImagem>
                                <ImageLoading backgroundinamico={this.props.backgroundMaisEscuro}></ImageLoading>
                                {/*  <img src={this.state.fotoCliente} alt="foto cliente" style={{ height: 40, width: 40, borderRadius: 50 }} /> */}
                            </ContainerImagem>
                            <ContainerDadosChamado>
                                <ContaineDadosChamadosPrincipal>
                                    <SubContainerIconeEFraseClienteAtendente >
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                    <SubContainerIconeEFraseClienteAtendente >
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                </ContaineDadosChamadosPrincipal>


                            </ContainerDadosChamado>


                        </ContainerChamadoLoading>
                    </SubContainer>

                    <ContainerPrincipalDadosCliente backGroundDinamico={this.props.backgroundSegundo}>
                        <SubContainerCliente>
                            <ContainerImagemAlterarCliente>
                                <ImagemPerfilLoading backgroundinamico={this.props.backgroundSegundo}></ImagemPerfilLoading>
                                {/*   <ImagePerfil onClick={() => { alert('trocar imagem') }} src={this.state.fotoCliente} alt="foto cliente" style={{}} /> */}
                            </ContainerImagemAlterarCliente>
                            <ContainerDadosClienteLoading>

                                <InputLoadingEmpresa backgroundinamico={this.props.backgroundSegundo}></InputLoadingEmpresa>
                                <InputLoadingEmpresa backgroundinamico={this.props.backgroundSegundo}></InputLoadingEmpresa>
                                <InputLoadingEmpresa backgroundinamico={this.props.backgroundSegundo}></InputLoadingEmpresa>
                                <InputLoadingEmpresa backgroundinamico={this.props.backgroundSegundo}></InputLoadingEmpresa>

                            </ContainerDadosClienteLoading>
                        </SubContainerCliente>
                        <SubContainerClientePermissaoLoading>
                            <InputLoadingEmpresa backgroundinamico={this.props.backgroundSegundo}></InputLoadingEmpresa>
                            <InputLoadingEmpresa backgroundinamico={this.props.backgroundSegundo}></InputLoadingEmpresa>
                            <InputLoadingEmpresa backgroundinamico={this.props.backgroundSegundo}></InputLoadingEmpresa>
                            <InputLoadingEmpresa backgroundinamico={this.props.backgroundSegundo}></InputLoadingEmpresa>
                        </SubContainerClientePermissaoLoading>
                    </ContainerPrincipalDadosCliente>

                </Container>
            )
        } else {
            return (
                <Container>
                    <SubContainer>
                        {this.props.filtroAtendente !== '' &&
                            <div style={{ justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                <p style={{ marginLeft: 40, color: this.props.coresLetraPadrao }}>Atendentes:</p>
                                <ContainerInputSearch backGroundDinamico={this.props.backgroundMaisEscuro}>
                                    <IconeSair className="icon-close" onClick={() => { this.removeFiltrosAll(); }} />
                                    <div style={{ borderRadius: 3, height: 30, flex: 1 }}>
                                        <SelectSearch
                                            renderValue={(valueProps) =>
                                                <DivInputSearch>
                                                    <InputSearch
                                                        corLetraDinamico={this.props.coresLetraPadrao}
                                                        backgroundDinamico={this.props.backgroundMaisEscuro}
                                                        placeholderColor={this.props.corLetraSecundaria}
                                                        corBordaDinamica={this.props.corBotaoPadrao}
                                                        {...valueProps} ></InputSearch>
                                                    <IconeSetaPraBaixo className="icon-angle-arrow-down" colorDinamico={this.props.coresLetraPadrao} />
                                                </DivInputSearch>
                                            }
                                            value={this.props.atendenteSelecionadosectioned}
                                            options={this.props.atendentesSectionedConsulta} onChange={(value) => {
                                                this.props.modificaAtendenteSelecionadoSectioned(value);
                                                this.props.consultaAtendentecomAtendente(value);
                                                this.props.desligaOuvintesChamadosFirebase('atendentes', '', '', '');
                                            }} placeholder={this.props.atendentesSectionedConsulta.length == 0 ? `Nenhum Atendente cadastrado` : "Selecione o Atendente"} search={this.props.atendentesSectionedConsulta.length == 0 ? false : true} />
                                    </div>
                                </ContainerInputSearch>
                            </div>}
                        {/*     <div style={{ display: 'flex', flex: 1, flexDirection: 'column', width: '100%', padding: 10 }}> */}
                        <FlatList
                            list={this.props.atendentes}
                            renderItem={(item) => <FlatListAtendentes data={item} key={item.key + item.alterado} />}
                            renderWhenEmpty={() => {
                                return (
                                    <ContainerListaVazia>
                                        <i className="icon-box" style={{ color: this.props.coresLetraPadrao, fontSize: 110 }} />
                                        <p style={{ color: this.props.coresLetraPadrao, fontSize: 20 }}>Nenhum Atendente Cadastrado</p>
                                    </ContainerListaVazia>
                                )

                            }}
                            hasMoreItems={this.props.loadingFimDAPaginaCOnsultaAtendente}
                            loadMoreItems={() => this.exibeUltimosClientes()}
                            paginationLoadingIndicator={<MoonLoader
                                css={{ margin: 10 }}
                                size={40}
                                color={this.props.corBotaoPadrao}
                                loading={this.props.loadingFimDAPaginaCOnsultaAtendente}
                            />}

                        //sortBy={["firstName", { key: "lastName", descending: true }]}
                        // groupBy={person => person.info.age > 18 ? 'Over 18' : 'Under 18'}
                        />
                        {/*   </div> */}
                    </SubContainer>
                    <ContainerPrincipalDadosCliente onSubmit={(e) => this.alteraAtendenteLocal(e)} backGroundDinamico={this.props.backgroundSegundo}>
                        {this.props.modalNovoAtendente == false && <SubContainerCliente>

                            <ContainerImagemAlterarCliente>
                                {this.renderImagem()}
                            </ContainerImagemAlterarCliente>
                            <ContainerDadosCliente>
                                {this.renderIconeExcluirAtendente()}
                                <p style={{ color: this.props.corLetraDescricaoDosCampos, fontWeight: 'bold', fontSize: 12, marginTop: this.props.contaPrincipal == true ? 10 : 0 }}>Nome<span style={{ color: 'red', fontSize: 14, marginLeft: 3 }}>*</span></p>
                                <DivContainerInputEmpresaVinculada>
                                    {this.state.pesquisaCliente == false && <InputEmpresa
                                        autoFocus
                                        type="text"
                                        placeholder="Nome do Atendente"
                                        disabled={this.props.keyUsuarioLogado === this.props.keyAtendente ? false : this.props.permissoesAcessarTelasAtendente.contaPrincipal === false ? true : false}
                                        colorDinamica={this.props.coresLetraPadrao}
                                        placeholderDinamico={this.props.coresLetraSecundario}
                                        borderDinamico={this.props.corBotaoPadrao}
                                        value={this.props.nomeAtendente}
                                        onChange={(e) => { this.props.modificaNomeAtendente(e.target.value) }}
                                    //  onFocus={() => { this.handleInputEmailFocus() }}
                                    // onBlur={() => { this.handleInputEmailBlur() }}
                                    />}


                                </DivContainerInputEmpresaVinculada>
                                {/*  </div> */}
                                <p style={{ color: this.props.corLetraDescricaoDosCampos, fontWeight: 'bold', fontSize: 12, marginTop: 5 }}>Email<span style={{ color: 'red', fontSize: 14, marginLeft: 3 }}>*</span></p>
                                <InputEmpresa
                                    type="text"
                                    placeholder="Email do Atendente"
                                    disabled={this.props.keyUsuarioLogado === this.props.keyAtendente ? false : this.props.permissoesAcessarTelasAtendente.contaPrincipal == false ? true : false}
                                    colorDinamica={this.props.coresLetraPadrao}
                                    placeholderDinamico={this.props.coresLetraSecundario}
                                    borderDinamico={this.props.corBotaoPadrao}
                                    value={this.props.emailAtendente}
                                    onChange={(e) => { this.props.modificaEmailAcessoApp(e.target.value) }}
                                //  onFocus={() => { this.handleInputEmailFocus() }}
                                // onBlur={() => { this.handleInputEmailBlur() }}
                                />
                                {this.renderSenhaConfirmaSenha()}
                            </ContainerDadosCliente>

                        </SubContainerCliente>}

                        {this.props.modalNovoAtendente == false && this.props.isAdmin == true && <ContainerPermissoesAtendentes>
                            <DivContainerTextPermissoes >
                                {this.props.contaPrincipal == true && <TextContaPrincipal onClick={() => { toastr.warning('Atenção', 'Essa conta é a conta Principal da sua empresa, por esse motivo, não pode ser removidos as permissões') }} data-tip="Essa conta é a conta Principal da sua empresa, por esse motivo, não pode ser removidos as permissões" data-for="tip-top">Permissões(Conta Principal)</TextContaPrincipal>}
                                {this.props.contaPrincipal == false && <TextContaPrincipal onClick={() => { toastr.warning('Permissões', 'Permissões que este atendente poderá fazer no sistema') }} data-tip="Permissões que este atendente poderá fazer no sistema" data-for="tip-top">Permissões</TextContaPrincipal>}
                            </DivContainerTextPermissoes>
                            <ContainerPermissao>
                                <TextPermissao onClick={() => { this._toggleModalTornarAdministrador() }} corLetraDinamica={this.props.corLetraDescricaoDosCampos}>Administrador< IconeInterrogacao colorDinamico={this.props.coresLetraSecundario} className="icon-question-mark-on-a-circular-black-background" /></TextPermissao>
                                <CheckBoxWrapper>
                                    <CheckBox disabled={this.props.contaPrincipal} id="checkbox" type="checkbox" checked={this.props.permissaoAdministrador} onChange={(value) => {
                                        if (this.props.premium == 0) {
                                            toastr.error(
                                                'Atenção',
                                                'Este é um recurso da versão PREMIUM,Adquira a versão PREMIUM e LIBERE esse e mais recursos.'
                                            );
                                        } else {
                                            if (this.props.permissaoAdministrador == true) {
                                                this.props.alteraSwitchPermissaoAlterarAdministrador(value.target.checked)
                                            } else {
                                                // this.props.alteraSwitchPermissaoAlterarAdministrador(value.target.checked)
                                                this._toggleModalTornarAdministrador()
                                            }
                                        }
                                    }} />
                                    <CheckBoxLabel htmlFor="checkbox" />
                                </CheckBoxWrapper>
                            </ContainerPermissao>

                            <ContainerPermissao>
                                <TextPermissao onClick={() => { this._toggleModalCadastrarAlterarCliente() }} corLetraDinamica={this.props.corLetraDescricaoDosCampos}>Cadastrar/Alterar Clientes< IconeInterrogacao colorDinamico={this.props.coresLetraSecundario} className="icon-question-mark-on-a-circular-black-background" /></TextPermissao>
                                <CheckBoxWrapper>
                                    <CheckBox disabled={this.props.permissaoAdministrador} id="checkbox2" type="checkbox" checked={this.props.permissaoAlterarCadastrarCliente} onChange={(value) => {
                                        if (this.props.premium == 0) {
                                            toastr.error(
                                                'Atenção',
                                                'Este é um recurso da versão PREMIUM,Adquira a versão PREMIUM e LIBERE esse e mais recursos.'
                                            );
                                        } else {
                                            this.props.alteraSwitchPermissaoAlterarCliente(value.target.checked)
                                        }
                                    }} />
                                    <CheckBoxLabel htmlFor="checkbox2" />
                                </CheckBoxWrapper>
                            </ContainerPermissao>
                            <ContainerPermissao>
                                <TextPermissao onClick={() => { this._toggleModalCadastrarAlterarAtendente() }} corLetraDinamica={this.props.corLetraDescricaoDosCampos}>Cadastrar/Alterar Atendentes< IconeInterrogacao colorDinamico={this.props.coresLetraSecundario} className="icon-question-mark-on-a-circular-black-background" /></TextPermissao>
                                <CheckBoxWrapper>
                                    <CheckBox disabled={this.props.permissaoAdministrador} id="checkbox3" type="checkbox" checked={this.props.permissaoAlterarCadastrarAtendente} onChange={(value) => {
                                        if (this.props.premium == 0) {
                                            toastr.error(
                                                'Atenção',
                                                'Este é um recurso da versão PREMIUM,Adquira a versão PREMIUM e LIBERE esse e mais recursos.'
                                            );
                                        } else {
                                            this.props.alteraSwitchPermissaoAlterarAtendente(value.target.checked)
                                        }
                                    }} />
                                    <CheckBoxLabel htmlFor="checkbox3" />
                                </CheckBoxWrapper>
                            </ContainerPermissao>
                            <ContainerPermissao>
                                <TextPermissao onClick={() => { this._toggleModalCadastrarAlterarSetor(); }} corLetraDinamica={this.props.corLetraDescricaoDosCampos}>Cadastrar/Alterar Setores< IconeInterrogacao colorDinamico={this.props.coresLetraSecundario} className="icon-question-mark-on-a-circular-black-background" /></TextPermissao>
                                <CheckBoxWrapper>
                                    <CheckBox disabled={this.props.permissaoAdministrador} id="checkbox4" type="checkbox" checked={this.props.permissaoAlterarCadastrarSetores} onChange={(value) => {
                                        if (this.props.premium == 0) {
                                            toastr.error(
                                                'Atenção',
                                                'Este é um recurso da versão PREMIUM,Adquira a versão PREMIUM e LIBERE esse e mais recursos.'
                                            );
                                        } else {
                                            this.props.alteraSwitchPermissaoAlterarSetores(value.target.checked)
                                        }
                                    }} />
                                    <CheckBoxLabel htmlFor="checkbox4" />
                                </CheckBoxWrapper>
                            </ContainerPermissao>
                            <ContainerPermissao>
                                <TextPermissao onClick={() => { this._toggleModalCadastrarAlterarAviso(); }} corLetraDinamica={this.props.corLetraDescricaoDosCampos}>Cadastrar/Alterar Avisos< IconeInterrogacao colorDinamico={this.props.coresLetraSecundario} className="icon-question-mark-on-a-circular-black-background" /></TextPermissao>
                                <CheckBoxWrapper>
                                    <CheckBox disabled={this.props.permissaoAdministrador} id="checkbox5" type="checkbox" checked={this.props.permissaoAlterarCadastrarAvisos} onChange={(value) => {
                                        if (this.props.premium == 0) {
                                            toastr.error(
                                                'Atenção',
                                                'Este é um recurso da versão PREMIUM,Adquira a versão PREMIUM e LIBERE esse e mais recursos.'
                                            );
                                        } else {
                                            this.props.alteraSwitchPermissaoAlterarAvisos(value.target.checked)
                                        }
                                    }} />
                                    <CheckBoxLabel htmlFor="checkbox5" />
                                </CheckBoxWrapper>
                            </ContainerPermissao>
                            <ContainerPermissao>
                                <TextPermissao onClick={() => { this._toggleModalPermissaoCancelarAtendimentos(); }} corLetraDinamica={this.props.corLetraDescricaoDosCampos}>Cancelar Atendimentos< IconeInterrogacao colorDinamico={this.props.coresLetraSecundario} className="icon-question-mark-on-a-circular-black-background" /></TextPermissao>
                                <CheckBoxWrapper>
                                    <CheckBox disabled={this.props.permissaoAdministrador} id="checkbox552" type="checkbox" checked={this.props.permissaoCancelarAtendimento} onChange={(value) => {
                                        if (this.props.premium == 0) {
                                            toastr.error(
                                                'Atenção',
                                                'Este é um recurso da versão PREMIUM,Adquira a versão PREMIUM e LIBERE esse e mais recursos.'
                                            );
                                        } else {
                                            this.props.alteraSwitchPermissaoCancelarAtendimento(value.target.checked)
                                        }
                                    }} />
                                    <CheckBoxLabel htmlFor="checkbox552" />
                                </CheckBoxWrapper>
                            </ContainerPermissao>
                            <ContainerPermissao>
                                <TextPermissao onClick={() => { this._toggleModalAcessoRelatorios(); }} corLetraDinamica={this.props.corLetraDescricaoDosCampos}>Acesso aos Relatórios< IconeInterrogacao colorDinamico={this.props.coresLetraSecundario} className="icon-question-mark-on-a-circular-black-background" /></TextPermissao>
                                <CheckBoxWrapper>
                                    <CheckBox disabled={this.props.permissaoAdministrador} id="checkbox6" type="checkbox" checked={this.props.permissaoAcessoRelatorios} onChange={(value) => {
                                        if (this.props.premium == 0) {
                                            toastr.error(
                                                'Atenção',
                                                'Este é um recurso da versão PREMIUM,Adquira a versão PREMIUM e LIBERE esse e mais recursos.'
                                            );
                                        } else {
                                            this.props.alteraSwitchPermissaoAcessoRelatorios(value.target.checked)
                                        }
                                    }} />
                                    <CheckBoxLabel htmlFor="checkbox6" />
                                </CheckBoxWrapper>
                            </ContainerPermissao>
                        </ContainerPermissoesAtendentes>}


                        {this.props.modalNovoAtendente == false && <ContainerBotaoAlterar>
                            {this.props.loadingBotaoAlterarAtendente == false && <ButtonEntrar
                                disabled={this.props.keyUsuarioLogado === this.props.keyAtendente ? false : this.props.isAdmin == false ? true : false}
                                backGroundDinamico={this.props.corBotaoPadrao} corLetraBotao={this.props.letraBotaoPadrao} type="submit" onClick={((e) => this.alteraAtendenteLocal(e))}>
                                Alterar
     </ButtonEntrar>}
                            {this.props.loadingBotaoAlterarAtendente == true && <ClipLoader
                                css={{ marginBottom: 10 }}
                                size={35}
                                color={this.props.corBotaoPadrao}
                                loading={this.props.loadingBotaoAlterarAtendente}
                            />}
                        </ContainerBotaoAlterar>}
                    </ContainerPrincipalDadosCliente>
                </Container>
            )
        }
    }
    render() {
        const CheckBoxWrapper = styled.div`
                                        position: relative;
                                        margin-left:10px;
                                      `;
        const CheckBoxLabel = styled.label`
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        width: 42px;
                                        height: 23px;
                                        border-radius: 15px;
        background: ${this.props.coresLetraSecundario};
                                        cursor: pointer;
        &::after {
                            content: "";
                    display: block;
                    border-radius: 50%;
                    width: 18px;
                    height: 18px;
                    margin: 3px;
                    background: #ffffff;
                    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
                    transition: 0.2s;
                  }
                `;
        const CheckBox = styled.input`
                  opacity: 0;
                  z-index: 1;
                  border-radius: 15px;
                  width: 42px;
                  height: 26px;
        &:checked + ${CheckBoxLabel} {
                            background: #00FF7F;
          &::after {
                            content: "";
                    display: block;
                    border-radius: 50%;
                    width: 18px;
                    height: 18px;
                    margin-left: 21px;
                    transition: 0.2s;
                  }
                }
        &:disabled + ${CheckBoxLabel}{
                            background: ${darken(0.2, '#00FF7F')};
                }
              `;
        return (
            <>
                {this.renderLoadingConsultaAtendente()}
                <Modal focusTrapped={false} showCloseIcon={false} open={this.state.modalAcessoRelatorio} onClose={() => { }} center>
                    <ContainerModal backgroundDinamico={this.props.backgroundPrimeiro}>
                        <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                            <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Permissões: Relatórios</TextNovoAtendimento>
                            <div>
                                <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => { this._toggleModalAcessoRelatorios() }} className="icon-close" />
                            </div>
                        </ContainerIconeFecharModal>
                        <ContainerImagemVincularEmpresa>
                            <img src={cadeadoAberto} alt="foto cadeado" style={{ height: 130, width: 130 }} />
                        </ContainerImagemVincularEmpresa>

                        <ContainerExplicacaoVincular>
                            <TextVincular corLetraDinamica={this.props.coresLetraPadrao}>Está opção permite <span style={{ color: this.props.coresLetraPadrao, fontSize: 16, fontWeight: 'bold' }}>{this.props.modalNovoAtendente == true ? this.props.nomeAtendenteNovoCadastro : this.props.nomeAtendente}</span> visualizar qualquer Relatórios no DropDesk!</TextVincular>
                        </ContainerExplicacaoVincular>
                        <ContainerBotaoAlterar>
                            <ButtonVincular backGroundDinamico={this.props.corBotaoPadrao} corLetraBotao={this.props.letraBotaoPadrao} type="submit" onClick={() => {
                                if (this.props.premium == 0) {
                                    toastr.error(
                                        'Atenção',
                                        'Este é um recurso da versão PREMIUM,Adquira a versão PREMIUM e LIBERE esse e mais recursos.',
                                    );
                                } else {
                                    if (this.props.modalNovoAtendente == true) {
                                        this.props.alteraSwitchPermissaoAcessoRelatoriosNovoCadastro(true);
                                    } else {
                                        this.props.alteraSwitchPermissaoAcessoRelatorios(true);
                                    }

                                    this._toggleModalAcessoRelatorios();
                                }
                            }}>
                                Permitir Visualizar Relatórios
                                </ButtonVincular>
                        </ContainerBotaoAlterar>
                    </ContainerModal>
                </Modal>
                <Modal focusTrapped={false} showCloseIcon={false} open={this.state.modalCadastrarAlterarAviso} onClose={() => { }} center>
                    <ContainerModal backgroundDinamico={this.props.backgroundPrimeiro}>
                        <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                            <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Permissões: Avisos</TextNovoAtendimento>
                            <div>
                                <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => { this._toggleModalCadastrarAlterarAviso() }} className="icon-close" />
                            </div>
                        </ContainerIconeFecharModal>
                        <ContainerImagemVincularEmpresa>
                            <img src={cadeadoAberto} alt="foto cadeado" style={{ height: 130, width: 130 }} />
                        </ContainerImagemVincularEmpresa>

                        <ContainerExplicacaoVincular>
                            <TextVincular corLetraDinamica={this.props.coresLetraPadrao}>Está opção permite <span style={{ color: this.props.coresLetraPadrao, fontSize: 16, fontWeight: 'bold' }}>{this.props.modalNovoAtendente == true ? this.props.nomeAtendenteNovoCadastro : this.props.nomeAtendente}</span> cadastrar novos Avisos e alterar dados de Avisos já cadastrados!</TextVincular>
                        </ContainerExplicacaoVincular>
                        <ContainerBotaoAlterar>
                            <ButtonVincular backGroundDinamico={this.props.corBotaoPadrao} corLetraBotao={this.props.letraBotaoPadrao} type="submit" onClick={() => {
                                if (this.props.premium == 0) {
                                    toastr.error(
                                        'Atenção',
                                        'Este é um recurso da versão PREMIUM,Adquira a versão PREMIUM e LIBERE esse e mais recursos.',
                                    );
                                } else {
                                    if (this.props.modalNovoAtendente == true) {
                                        this.props.alteraSwitchPermissaoAlterarAvisosNovoCadastro(true);
                                    } else {
                                        this.props.alteraSwitchPermissaoAlterarAvisos(true);
                                    }

                                    this._toggleModalCadastrarAlterarAviso();
                                }
                            }}>
                                Permitir Cad/Alterar Avisos
                                </ButtonVincular>
                        </ContainerBotaoAlterar>
                    </ContainerModal>
                </Modal>
                <Modal focusTrapped={false} showCloseIcon={false} open={this.state.modalPermissaoCancelarAtendimento} onClose={() => { }} center>
                    <ContainerModal backgroundDinamico={this.props.backgroundPrimeiro}>
                        <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                            <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Permissões: Cancelar Atendimentos</TextNovoAtendimento>
                            <div>
                                <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => { this._toggleModalPermissaoCancelarAtendimentos() }} className="icon-close" />
                            </div>
                        </ContainerIconeFecharModal>
                        <ContainerImagemVincularEmpresa>
                            <img src={cadeadoAberto} alt="foto cadeado" style={{ height: 130, width: 130 }} />
                        </ContainerImagemVincularEmpresa>

                        <ContainerExplicacaoVincular>
                            <TextVincular corLetraDinamica={this.props.coresLetraPadrao}>Está opção permite <span style={{ color: this.props.coresLetraPadrao, fontSize: 16, fontWeight: 'bold' }}>{this.props.modalNovoAtendente == true ? this.props.nomeAtendenteNovoCadastro : this.props.nomeAtendente}</span> Cancelar Atendimentos!</TextVincular>
                        </ContainerExplicacaoVincular>
                        <ContainerBotaoAlterar>
                            <ButtonVincular backGroundDinamico={this.props.corBotaoPadrao} corLetraBotao={this.props.letraBotaoPadrao} type="submit" onClick={() => {
                                if (this.props.premium == 0) {
                                    toastr.error(
                                        'Atenção',
                                        'Este é um recurso da versão PREMIUM,Adquira a versão PREMIUM e LIBERE esse e mais recursos.',
                                    );
                                } else {
                                    if (this.props.modalNovoAtendente == true) {
                                        this.props.modificaPermissaoCancelarAtendimentoNovoCadastro(true);
                                    } else {
                                        this.props.alteraSwitchPermissaoCancelarAtendimento(true);
                                    }
                                    this._toggleModalPermissaoCancelarAtendimentos();
                                }
                            }}>
                                Permitir Cancelar Atendimentos
                                </ButtonVincular>
                        </ContainerBotaoAlterar>
                    </ContainerModal>
                </Modal>
                <Modal focusTrapped={false} showCloseIcon={false} open={this.state.modalCadastrarAlterarSetor} onClose={() => { }} center>
                    <ContainerModal backgroundDinamico={this.props.backgroundPrimeiro}>
                        <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                            <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Permissões: Setores</TextNovoAtendimento>
                            <div>
                                <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => { this._toggleModalCadastrarAlterarSetor() }} className="icon-close" />
                            </div>
                        </ContainerIconeFecharModal>
                        <ContainerImagemVincularEmpresa>
                            <img src={cadeadoAberto} alt="foto cadeado" style={{ height: 130, width: 130 }} />
                        </ContainerImagemVincularEmpresa>

                        <ContainerExplicacaoVincular>
                            <TextVincular corLetraDinamica={this.props.coresLetraPadrao}>Está opção permite <span style={{ color: this.props.coresLetraPadrao, fontSize: 16, fontWeight: 'bold' }}>{this.props.modalNovoAtendente == true ? this.props.nomeAtendenteNovoCadastro : this.props.nomeAtendente}</span> Cadastrar novos setores, Alterar dados de setores e Excluir setores!</TextVincular>
                        </ContainerExplicacaoVincular>
                        <ContainerBotaoAlterar>
                            <ButtonVincular backGroundDinamico={this.props.corBotaoPadrao} corLetraBotao={this.props.letraBotaoPadrao} type="submit" onClick={() => {
                                if (this.props.premium == 0) {
                                    toastr.error(
                                        'Atenção',
                                        'Este é um recurso da versão PREMIUM,Adquira a versão PREMIUM e LIBERE esse e mais recursos.',
                                    );
                                } else {
                                    if (this.props.modalNovoAtendente == true) {
                                        this.props.alteraSwitchPermissaoAlterarSetoresNovoCadastro(true);
                                    } else {
                                        this.props.alteraSwitchPermissaoAlterarSetores(true);
                                    }

                                    this._toggleModalCadastrarAlterarSetor();
                                }
                            }}>
                                Permitir Cad/Alterar Setor
                                </ButtonVincular>
                        </ContainerBotaoAlterar>
                    </ContainerModal>
                </Modal>

                <Modal focusTrapped={false} showCloseIcon={false} open={this.props.modalNovoAtendente} onClose={() => { }} center>
                    <ContainerModalNovoCliente onSubmit={(e) => this.cadastraAtendenteLocal(e)} backgroundDinamico={this.props.backgroundPrimeiro}>
                        <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                            <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Cadastro Atendente</TextNovoAtendimento>
                            <div>
                                <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => {
                                    if (this.props.retornaTelaClienteParaNovoAtendimentos) {
                                        this.props.history.goBack();
                                        this.props.modificaVisibleModalNovoAtendente(false)
                                        this.props.modificaRetornaTelaClienteParaNovoAtendimento(false);
                                    } else {
                                        this.props.modificaVisibleModalNovoAtendente(false)
                                    }


                                }} className="icon-close" />
                            </div>
                        </ContainerIconeFecharModal>
                        <SubContainerCliente>

                            <ContainerImagemAlterarCliente>
                                <Dropzone
                                    accept="image/*"
                                    multiple={false}
                                    noKeyboard
                                    maxSize={5e+6}
                                    noDrag
                                    onDrop={acceptedFiles => this.escolheArquivoChamadoNovoCadastro(acceptedFiles)}>
                                    {({ getRootProps, getInputProps, isDragActive }) => {

                                        return (
                                            /*     <section> */
                                            <div  {...getRootProps()}>
                                                <input {...getInputProps()} />
                                                <ImagePerfil bordaDinamico={this.props.corBotaoPadrao} src={this.props.uriImagemPadraoTelaCadastroAtendente} alt="foto atendente" />
                                            </div>
                                            /*   </section> */
                                        )
                                    }}
                                </Dropzone>
                            </ContainerImagemAlterarCliente>
                            <ContainerDadosCliente>

                                <p style={{ color: this.props.corLetraDescricaoDosCampos, fontWeight: 'bold', fontSize: 12, marginTop: 10 }}>Nome<span style={{ color: 'red', fontSize: 14, marginLeft: 3 }}>*</span></p>
                                <DivContainerInputEmpresaVinculada>
                                    {this.state.pesquisaCliente == false && <InputEmpresa
                                        autoFocus
                                        type="text"
                                        placeholder="Nome do Atendente"
                                        colorDinamica={this.props.coresLetraPadrao}
                                        placeholderDinamico={this.props.coresLetraSecundario}
                                        borderDinamico={this.props.corBotaoPadrao}
                                        value={this.props.nomeAtendenteNovoCadastro}
                                        onChange={(e) => { this.props.modificaNomeAtendenteNovoCadastro(e.target.value) }}
                                    //  onFocus={() => { this.handleInputEmailFocus() }}
                                    // onBlur={() => { this.handleInputEmailBlur() }}
                                    />}


                                </DivContainerInputEmpresaVinculada>
                                {/*  </div> */}
                                <p style={{ color: this.props.corLetraDescricaoDosCampos, fontWeight: 'bold', fontSize: 12, marginTop: 5 }}>Email<span style={{ color: 'red', fontSize: 14, marginLeft: 3 }}>*</span></p>
                                <InputEmpresa
                                    type="text"
                                    placeholder="Email do Atendente"
                                    colorDinamica={this.props.coresLetraPadrao}
                                    placeholderDinamico={this.props.coresLetraSecundario}
                                    borderDinamico={this.props.corBotaoPadrao}
                                    value={this.props.emailAtendenteNovoCadastro}
                                    onChange={(e) => { this.props.modificaEmailAcessoAppNovoCadastro(e.target.value) }}
                                //  onFocus={() => { this.handleInputEmailFocus() }}
                                // onBlur={() => { this.handleInputEmailBlur() }}
                                />
                                <p style={{ color: this.props.corLetraDescricaoDosCampos, fontWeight: 'bold', fontSize: 12, marginTop: 5 }}>Senha<span style={{ color: 'red', fontSize: 14, marginLeft: 3 }}>*</span></p>
                                <DivContainerInputSenha>
                                    <InputEmpresa

                                        type={this.state.tipoInputSenha}
                                        placeholder="Senha"
                                        colorDinamica={this.props.coresLetraPadrao}
                                        placeholderDinamico={this.props.coresLetraSecundario}
                                        borderDinamico={this.props.corBotaoPadrao}
                                        value={this.props.senhaAtendenteNovoCadastro}
                                        onChange={(e) => { this.props.modificaSenhaAtendenteNovoCadastro(e.target.value) }}
                                    //  onFocus={() => { this.handleInputEmailFocus() }}
                                    // onBlur={() => { this.handleInputEmailBlur() }}
                                    />
                                    {this.state.tipoInputSenha == 'password' && < IconeMostrarSenha onClick={() => { this.handleVisualizaSenha('text') }} colorDinamico={this.props.coresLetraPadrao} bordaDinamica={this.props.corBotaoPadrao} className="icon-semvisao" />}
                                    {this.state.tipoInputSenha == 'text' && < IconeMostrarSenha onClick={() => { this.handleVisualizaSenha('password') }} colorDinamico={this.props.coresLetraPadrao} bordaDinamica={this.props.corBotaoPadrao} className="icon-comvisao" />}
                                </DivContainerInputSenha>
                                <p style={{ color: this.props.corLetraDescricaoDosCampos, fontWeight: 'bold', fontSize: 12, marginTop: 5 }}>Confirmar Senha<span style={{ color: 'red', fontSize: 14, marginLeft: 3 }}>*</span></p>
                                <DivContainerInputSenha>
                                    <InputEmpresa

                                        type={this.state.tipoInputConfirmaSenha}
                                        placeholder="Confirma Senha"
                                        colorDinamica={this.props.coresLetraPadrao}
                                        placeholderDinamico={this.props.coresLetraSecundario}
                                        borderDinamico={this.props.corBotaoPadrao}
                                        value={this.props.confirmaSenhaAtendenteNovoCadastro}
                                        onChange={(e) => { this.props.modificaConfirmaSenhaAtendenteNovoCadastro(e.target.value) }}
                                    //  onFocus={() => { this.handleInputEmailFocus() }}
                                    // onBlur={() => { this.handleInputEmailBlur() }}
                                    />
                                    {this.state.tipoInputConfirmaSenha == 'password' && < IconeMostrarSenha onClick={() => { this.handleVisualizaConfirmaSenha('text') }} colorDinamico={this.props.coresLetraPadrao} bordaDinamica={this.props.corBotaoPadrao} className="icon-semvisao" />}
                                    {this.state.tipoInputConfirmaSenha == 'text' && < IconeMostrarSenha onClick={() => { this.handleVisualizaConfirmaSenha('password') }} colorDinamico={this.props.coresLetraPadrao} bordaDinamica={this.props.corBotaoPadrao} className="icon-comvisao" />}
                                </DivContainerInputSenha>
                            </ContainerDadosCliente>

                        </SubContainerCliente>

                        <ContainerPermissoesAtendentes>
                            <DivContainerTextPermissoes >
                                <TextContaPrincipal onClick={() => { toastr.warning('Permissões', 'Permissões que este atendente poderá fazer no sistema') }} data-tip="Permissões que este atendente poderá fazer no sistema" data-for="tip-top">Permissões</TextContaPrincipal>
                            </DivContainerTextPermissoes>
                            <ContainerPermissao>
                                <TextPermissao onClick={() => { this._toggleModalTornarAdministrador() }} corLetraDinamica={this.props.corLetraDescricaoDosCampos}>Administrador< IconeInterrogacao colorDinamico={this.props.coresLetraSecundario} className="icon-question-mark-on-a-circular-black-background" /></TextPermissao>
                                <CheckBoxWrapper>
                                    <CheckBox id="checkbox" type="checkbox" checked={this.props.permissaoAdministradorNovoCadastro} onChange={(value) => {
                                        if (this.props.premium == 0) {
                                            toastr.error(
                                                'Atenção',
                                                'Este é um recurso da versão PREMIUM,Adquira a versão PREMIUM e LIBERE esse e mais recursos.'
                                            );
                                        } else {
                                            if (this.props.permissaoAdministradorNovoCadastro == true) {
                                                this.props.alteraSwitchPermissaoAlterarAdministradorNovoCadastro(value.target.checked)
                                            } else {
                                                // this.props.alteraSwitchPermissaoAlterarAdministrador(value.target.checked)
                                                this._toggleModalTornarAdministrador()
                                            }
                                        }
                                    }} />
                                    <CheckBoxLabel htmlFor="checkbox" />
                                </CheckBoxWrapper>
                            </ContainerPermissao>

                            <ContainerPermissao>
                                <TextPermissao onClick={() => { this._toggleModalCadastrarAlterarCliente() }} corLetraDinamica={this.props.corLetraDescricaoDosCampos}>Cadastrar/Alterar Clientes< IconeInterrogacao colorDinamico={this.props.coresLetraSecundario} className="icon-question-mark-on-a-circular-black-background" /></TextPermissao>
                                <CheckBoxWrapper>
                                    <CheckBox disabled={this.props.permissaoAdministradorNovoCadastro} id="checkbox2" type="checkbox" checked={this.props.permissaoAlterarCadastrarClienteNovoCadastro} onChange={(value) => {
                                        if (this.props.premium == 0) {
                                            toastr.error(
                                                'Atenção',
                                                'Este é um recurso da versão PREMIUM,Adquira a versão PREMIUM e LIBERE esse e mais recursos.'
                                            );
                                        } else {
                                            this.props.alteraSwitchPermissaoAlterarClienteNovoCadastro(value.target.checked)
                                        }
                                    }} />
                                    <CheckBoxLabel htmlFor="checkbox2" />
                                </CheckBoxWrapper>
                            </ContainerPermissao>
                            <ContainerPermissao>
                                <TextPermissao onClick={() => { this._toggleModalCadastrarAlterarAtendente() }} corLetraDinamica={this.props.corLetraDescricaoDosCampos}>Cadastrar/Alterar Atendentes< IconeInterrogacao colorDinamico={this.props.coresLetraSecundario} className="icon-question-mark-on-a-circular-black-background" /></TextPermissao>
                                <CheckBoxWrapper>
                                    <CheckBox disabled={this.props.permissaoAdministradorNovoCadastro} id="checkbox3" type="checkbox" checked={this.props.permissaoAlterarCadastrarAtendenteNovoCadastro} onChange={(value) => {
                                        if (this.props.premium == 0) {
                                            toastr.error(
                                                'Atenção',
                                                'Este é um recurso da versão PREMIUM,Adquira a versão PREMIUM e LIBERE esse e mais recursos.'
                                            );
                                        } else {
                                            this.props.alteraSwitchPermissaoAlterarAtendenteNovoCadastro(value.target.checked)
                                        }
                                    }} />
                                    <CheckBoxLabel htmlFor="checkbox3" />
                                </CheckBoxWrapper>
                            </ContainerPermissao>
                            <ContainerPermissao>
                                <TextPermissao onClick={() => { this._toggleModalCadastrarAlterarSetor(); }} corLetraDinamica={this.props.corLetraDescricaoDosCampos}>Cadastrar/Alterar Setores< IconeInterrogacao colorDinamico={this.props.coresLetraSecundario} className="icon-question-mark-on-a-circular-black-background" /></TextPermissao>
                                <CheckBoxWrapper>
                                    <CheckBox disabled={this.props.permissaoAdministradorNovoCadastro} id="checkbox4" type="checkbox" checked={this.props.permissaoAlterarCadastrarSetoresNovoCadastro} onChange={(value) => {
                                        if (this.props.premium == 0) {
                                            toastr.error(
                                                'Atenção',
                                                'Este é um recurso da versão PREMIUM,Adquira a versão PREMIUM e LIBERE esse e mais recursos.'
                                            );
                                        } else {
                                            this.props.alteraSwitchPermissaoAlterarSetoresNovoCadastro(value.target.checked)
                                        }
                                    }} />
                                    <CheckBoxLabel htmlFor="checkbox4" />
                                </CheckBoxWrapper>
                            </ContainerPermissao>
                            <ContainerPermissao>
                                <TextPermissao onClick={() => { this._toggleModalCadastrarAlterarAviso(); }} corLetraDinamica={this.props.corLetraDescricaoDosCampos}>Cadastrar/Alterar Avisos< IconeInterrogacao colorDinamico={this.props.coresLetraSecundario} className="icon-question-mark-on-a-circular-black-background" /></TextPermissao>
                                <CheckBoxWrapper>
                                    <CheckBox disabled={this.props.permissaoAdministradorNovoCadastro} id="checkbox5" type="checkbox" checked={this.props.permissaoAlterarCadastrarAvisosNovoCadastro} onChange={(value) => {
                                        if (this.props.premium == 0) {
                                            toastr.error(
                                                'Atenção',
                                                'Este é um recurso da versão PREMIUM,Adquira a versão PREMIUM e LIBERE esse e mais recursos.'
                                            );
                                        } else {
                                            this.props.alteraSwitchPermissaoAlterarAvisosNovoCadastro(value.target.checked)
                                        }
                                    }} />
                                    <CheckBoxLabel htmlFor="checkbox5" />
                                </CheckBoxWrapper>
                            </ContainerPermissao>

                            <ContainerPermissao>
                                <TextPermissao onClick={() => { this._toggleModalPermissaoCancelarAtendimentos(); }} corLetraDinamica={this.props.corLetraDescricaoDosCampos}>Cancelar Atendimentos< IconeInterrogacao colorDinamico={this.props.coresLetraSecundario} className="icon-question-mark-on-a-circular-black-background" /></TextPermissao>
                                <CheckBoxWrapper>
                                    <CheckBox disabled={this.props.permissaoAdministradorNovoCadastro} id="checkbox55" type="checkbox" checked={this.props.permissaoCancelarAtendimentoNovoCadastro} onChange={(value) => {
                                        if (this.props.premium == 0) {
                                            toastr.error(
                                                'Atenção',
                                                'Este é um recurso da versão PREMIUM,Adquira a versão PREMIUM e LIBERE esse e mais recursos.'
                                            );
                                        } else {
                                            this.props.modificaPermissaoCancelarAtendimentoNovoCadastro(value.target.checked)
                                        }
                                    }} />
                                    <CheckBoxLabel htmlFor="checkbox55" />
                                </CheckBoxWrapper>
                            </ContainerPermissao>

                            <ContainerPermissao>
                                <TextPermissao onClick={() => { this._toggleModalAcessoRelatorios(); }} corLetraDinamica={this.props.corLetraDescricaoDosCampos}>Acesso aos Relatórios< IconeInterrogacao colorDinamico={this.props.coresLetraSecundario} className="icon-question-mark-on-a-circular-black-background" /></TextPermissao>
                                <CheckBoxWrapper>
                                    <CheckBox disabled={this.props.permissaoAdministradorNovoCadastro} id="checkbox6" type="checkbox" checked={this.props.permissaoAcessoRelatoriosNovoCadastro} onChange={(value) => {
                                        if (this.props.premium == 0) {
                                            toastr.error(
                                                'Atenção',
                                                'Este é um recurso da versão PREMIUM,Adquira a versão PREMIUM e LIBERE esse e mais recursos.'
                                            );
                                        } else {
                                            this.props.alteraSwitchPermissaoAcessoRelatoriosNovoCadastro(value.target.checked)
                                        }
                                    }} />
                                    <CheckBoxLabel htmlFor="checkbox6" />
                                </CheckBoxWrapper>
                            </ContainerPermissao>
                        </ContainerPermissoesAtendentes>


                        <ContainerBotaoAlterar>
                            {this.props.loadingBotaoCadastrarAtendente == false && <ButtonEntrar backGroundDinamico={this.props.corBotaoPadrao} corLetraBotao={this.props.letraBotaoPadrao} type="submit" onClick={(e) => this.cadastraAtendenteLocal(e)}>
                                Cadastrar
                             </ButtonEntrar>}

                            {this.props.loadingBotaoCadastrarAtendente == true && <ClipLoader
                                css={{ marginBottom: 10 }}
                                size={35}
                                color={this.props.corBotaoPadrao}
                                loading={this.props.loadingBotaoCadastrarAtendente}
                            />}
                        </ContainerBotaoAlterar>

                    </ContainerModalNovoCliente>
                </Modal>

                <Modal focusTrapped={false} showCloseIcon={false} open={this.state.modalCadastrarAlterarAtendente} onClose={() => { }} center>
                    <ContainerModal backgroundDinamico={this.props.backgroundPrimeiro}>
                        <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                            <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Permissões: Atendentes</TextNovoAtendimento>
                            <div>
                                <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => { this._toggleModalCadastrarAlterarAtendente() }} className="icon-close" />
                            </div>
                        </ContainerIconeFecharModal>
                        <ContainerImagemVincularEmpresa>
                            <img src={cadeadoAberto} alt="foto cadeado" style={{ height: 130, width: 130 }} />
                        </ContainerImagemVincularEmpresa>

                        <ContainerExplicacaoVincular>
                            <TextVincular corLetraDinamica={this.props.coresLetraPadrao}>Está opção permite <span style={{ color: this.props.coresLetraPadrao, fontSize: 16, fontWeight: 'bold' }}>{this.props.modalNovoAtendente == true ? this.props.nomeAtendenteNovoCadastro : this.props.nomeAtendente}</span> Cadastrar novos atendentes e Alterar dados de atendentes e Excluir atendentes!</TextVincular>
                        </ContainerExplicacaoVincular>
                        <ContainerBotaoAlterar>
                            <ButtonVincular backGroundDinamico={this.props.corBotaoPadrao} corLetraBotao={this.props.letraBotaoPadrao} type="submit" onClick={() => {
                                if (this.props.premium == 0) {
                                    toastr.error(
                                        'Atenção',
                                        'Este é um recurso da versão PREMIUM,Adquira a versão PREMIUM e LIBERE esse e mais recursos.',
                                    );
                                } else {
                                    if (this.props.modalNovoAtendente == true) {
                                        this.props.alteraSwitchPermissaoAlterarAtendenteNovoCadastro(true);
                                    } else {
                                        this.props.alteraSwitchPermissaoAlterarAtendente(true);

                                    }
                                    this._toggleModalCadastrarAlterarAtendente();
                                }
                            }}>
                                Permitir Cad/Alterar Atendente
                                </ButtonVincular>
                        </ContainerBotaoAlterar>
                    </ContainerModal>
                </Modal>
                <Modal focusTrapped={false} showCloseIcon={false} open={this.state.modalCadastrarAlterarCliente} onClose={() => { }} center>
                    <ContainerModal backgroundDinamico={this.props.backgroundPrimeiro}>
                        <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                            <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Permissões: Clientes</TextNovoAtendimento>
                            <div>
                                <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => { this._toggleModalCadastrarAlterarCliente() }} className="icon-close" />
                            </div>
                        </ContainerIconeFecharModal>
                        <ContainerImagemVincularEmpresa>
                            <img src={cadeadoAberto} alt="foto cadeado" style={{ height: 130, width: 130 }} />
                        </ContainerImagemVincularEmpresa>

                        <ContainerExplicacaoVincular>
                            <TextVincular corLetraDinamica={this.props.coresLetraPadrao}>Está opção permite <span style={{ color: this.props.coresLetraPadrao, fontSize: 16, fontWeight: 'bold' }}>{this.props.modalNovoAtendente == true ? this.props.nomeAtendenteNovoCadastro : this.props.nomeAtendente}</span> Cadastrar novos clientes e Alterar dados de clientes e Excluir clientes!</TextVincular>
                        </ContainerExplicacaoVincular>
                        <ContainerBotaoAlterar>
                            <ButtonVincular backGroundDinamico={this.props.corBotaoPadrao} corLetraBotao={this.props.letraBotaoPadrao} type="submit" onClick={() => {
                                if (this.props.premium == 0) {
                                    toastr.error(
                                        'Atenção',
                                        'Este é um recurso da versão PREMIUM,Adquira a versão PREMIUM e LIBERE esse e mais recursos.',
                                    );
                                } else {
                                    if (this.props.modalNovoAtendente == true) {
                                        this.props.alteraSwitchPermissaoAlterarClienteNovoCadastro(true);
                                    } else {
                                        this.props.alteraSwitchPermissaoAlterarCliente(true);
                                    }
                                    this._toggleModalCadastrarAlterarCliente();
                                }
                            }}>
                                Permitir Cad/Alterar Cliente
                                </ButtonVincular>
                        </ContainerBotaoAlterar>
                    </ContainerModal>
                </Modal>
                <Modal focusTrapped={false} showCloseIcon={false} open={this.state.modalTornarAdministrador} onClose={() => { }} center>
                    <ContainerModal backgroundDinamico={this.props.backgroundPrimeiro}>
                        <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                            <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Permissões: Administrador</TextNovoAtendimento>
                            <div>
                                <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => { this._toggleModalTornarAdministrador() }} className="icon-close" />
                            </div>
                        </ContainerIconeFecharModal>
                        <ContainerImagemVincularEmpresa>
                            <img src={cadeadoAberto} alt="foto cadeado" style={{ height: 130, width: 130 }} />
                        </ContainerImagemVincularEmpresa>

                        <ContainerExplicacaoVincular>
                            <TextVincular corLetraDinamica={this.props.coresLetraPadrao}>Ao tornar <span style={{ color: this.props.coresLetraPadrao, fontSize: 16, fontWeight: 'bold' }}>{this.props.modalNovoAtendente == true ? this.props.nomeAtendenteNovoCadastro : this.props.nomeAtendente}</span> um administrador, ele terá <span style={{ color: this.props.coresLetraPadrao, fontSize: 16, fontWeight: 'bold' }}>acesso irrestrito</span> ao DropDesk e poderá: Cadastrar/Alterar Clientes, Atendentes, Setores e Avisos, Acesso a relatórios etc...</TextVincular>
                        </ContainerExplicacaoVincular>
                        <ContainerBotaoAlterar>
                            <ButtonVincular backGroundDinamico={this.props.corBotaoPadrao} corLetraBotao={this.props.letraBotaoPadrao} type="submit" onClick={() => {

                                if (this.props.premium == 0) {
                                    toastr.error(
                                        'Atenção',
                                        'Este é um recurso da versão PREMIUM,Adquira a versão PREMIUM e LIBERE esse e mais recursos.',
                                    );
                                } else {
                                    if (this.props.modalNovoAtendente == true) {
                                        this.props.alteraSwitchPermissaoAlterarAdministradorNovoCadastro(true);
                                    } else {
                                        this.props.alteraSwitchPermissaoAlterarAdministrador(true);
                                    }

                                    this._toggleModalTornarAdministrador();
                                }
                            }}>
                                Tornar Administrador
                                </ButtonVincular>
                        </ContainerBotaoAlterar>
                    </ContainerModal>
                </Modal>

                <Modal focusTrapped={false} showCloseIcon={false} open={this.props.modalExcluirAtendente} onClose={() => { }} center>
                    <ContainerModal backgroundDinamico={this.props.backgroundPrimeiro}>
                        <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                            <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Excluir Atendente</TextNovoAtendimento>
                            <div>
                                <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => { this.props.mudaVisibilidadeModalExcluirAtendente(false) }} className="icon-close" />

                            </div>
                        </ContainerIconeFecharModal>
                        <ContainerImagemVincularEmpresa>
                            <img src={imgexcluirCliente} alt="foto excluir" style={{ height: 130, width: 130 }} />
                            <span style={{ color: this.props.coresLetraPadrao }}>Tem certeza que deseja excluir <span style={{ color: this.props.coresLetraPadrao, fontWeight: 'bold' }}>{this.props.nomeAtendente}</span>?</span>
                        </ContainerImagemVincularEmpresa>

                        <ContainerExplicacaoVincular>
                            <TextVincular corLetraDinamica={this.props.coresLetraPadrao}>A exclusão do atendente <span style={{ color: this.props.coresLetraPadrao, fontSize: 16, fontWeight: 'bold' }}>{this.props.nomeAtendente}</span> não apaga seus atendimentos, avaliações e nenhuma outra ação já feita por ele, apenas o atendente é excluído e não terá mais acesso ao Aplicativo / Web.</TextVincular>
                        </ContainerExplicacaoVincular>
                        <ContainerBotaoAlterar>
                            <ButtonVincular backGroundDinamico={this.props.corBotaoPadrao} corLetraBotao={this.props.letraBotaoPadrao} type="button" onClick={() => {
                                this.props.excluiAtendente(this.props.keyAtendente);
                                if (this.props.filtroAtendente !== "") {
                                    this.removeFiltrosAll();
                                }
                                this.props.mudaVisibilidadeModalExcluirAtendente(false);
                            }}>
                                Excluir
                                </ButtonVincular>
                        </ContainerBotaoAlterar>
                    </ContainerModal>
                </Modal>

            </>



        );
    }
}

const mapStateToProps = state => {
    return (
        {
            backgroundPrimeiro: state.CoresPadraoSistema.backgroundPrimeiro,
            backgroundSegundo: state.CoresPadraoSistema.backgroundSegundo,
            backgroundMaisEscuro: state.CoresPadraoSistema.backgroundMaisEscuro,
            coresLetraPadrao: state.CoresPadraoSistema.coresLetraPadrao,
            corBotaoPadrao: state.CoresPadraoSistema.corBotaoPadrao,
            corSegundoBotaoPadrao: state.CoresPadraoSistema.corSegundoBotaoPadrao,
            coresLetraSecundario: state.CoresPadraoSistema.coresLetraSecundario,
            letraBotaoPadrao: state.CoresPadraoSistema.letraBotaoPadrao,
            corLetraDescricaoDosCampos: state.CoresPadraoSistema.corLetraDescricaoDosCampos,
            nomeRota: state.HeaderReducer.nomeRota,
            testePesquisa: state.HeaderReducer.testePesquisa,

            fotoImagemTelaAlterarAtendente: state.AtendenteReducer.fotoImagemTelaAlterarAtendente,
            loadingBotaoAlterarAtendente: state.AtendenteReducer.loadingBotaoAlterarAtendente,
            backgroundMensagemErrorTelaAlterarAtendente: state.AtendenteReducer.backgroundMensagemErrorTelaAlterarAtendente,
            nomeAtendente: state.AtendenteReducer.nomeAtendente,
            emailAtendente: state.AtendenteReducer.emailAtendente,
            senhaAtendente: state.AtendenteReducer.senhaAtendente,
            keyAtendente: state.AtendenteReducer.keyAtendente,
            confirmaSenhaAtendente: state.AtendenteReducer.confirmaSenhaAtendente,
            mensagemerrorAoAlterarAtendenteTelaAlterar: state.AtendenteReducer.mensagemerrorAoAlterarAtendenteTelaAlterar,
            verificaConexaoInternetAlteraAtendente: state.AtendenteReducer.verificaConexaoInternetAlteraAtendente,
            permissaoAdministrador: state.AtendenteReducer.permissaoAdministrador,
            permissaoAlterarCadastrarCliente: state.AtendenteReducer.permissaoAlterarCadastrarCliente,
            permissaoAlterarCadastrarAtendente: state.AtendenteReducer.permissaoAlterarCadastrarAtendente,
            permissaoAlterarCadastrarSetores: state.AtendenteReducer.permissaoAlterarCadastrarSetores,
            permissaoAlterarCadastrarAvisos: state.AtendenteReducer.permissaoAlterarCadastrarAvisos,
            permissaoAcessoRelatorios: state.AtendenteReducer.permissaoAcessoRelatorios,
            contaPrincipal: state.AtendenteReducer.contaPrincipal,
            filtroAtendente: state.AtendenteReducer.filtroAtendente,
            modalExcluirAtendente: state.AtendenteReducer.modalExcluirAtendente,
            atendentes: state.AtendenteReducer.atendentes,
            uidAtendente: state.AtendenteReducer.uidAtendente,
            modalNovoAtendente: state.AtendenteReducer.modalNovoAtendente,
            premium: state.PremiumReducer.premium,


            verificaConexaoInternetConsultaAtendente: state.AtendenteReducer.verificaConexaoInternetConsultaAtendente,
            inputDigitoConsultaAtendente: state.AtendenteReducer.inputDigitoConsultaAtendente,
            mensagemSucessoAoAlterarAtendenteTelaAlterar: state.AtendenteReducer.mensagemSucessoAoAlterarAtendenteTelaAlterar,
            loadingConsultaAtendente: state.AtendenteReducer.loadingConsultaAtendente,
            loadingFimDAPaginaCOnsultaAtendente: state.AtendenteReducer.loadingFimDAPaginaCOnsultaAtendente,
            referenciaUltimaKeyAtendente: state.AtendenteReducer.referenciaUltimaKeyAtendente,
            atendentesSectionedConsulta: state.AtendenteReducer.atendentesSectionedConsulta,
            atendenteSelecionadosectioned: state.AtendenteReducer.atendenteSelecionadosectioned,
            nomeAtendenteSelecinadoSectioned: state.AtendenteReducer.nomeAtendenteSelecinadoSectioned,
            refreshControlConsultaAtendente: state.AtendenteReducer.refreshControlConsultaAtendente,
            qtdAtendentesLiberados: state.PremiumReducer.qtdAtendentesLiberados,
            arquivoCompletoAtendenteParaUpar: state.AtendenteReducer.arquivoCompletoAtendenteParaUpar,


            permissaoAdministradorNovoCadastro: state.AtendenteReducer.permissaoAdministradorNovoCadastro,
            permissaoAlterarCadastrarClienteNovoCadastro: state.AtendenteReducer.permissaoAlterarCadastrarClienteNovoCadastro,
            permissaoAlterarCadastrarAtendenteNovoCadastro: state.AtendenteReducer.permissaoAlterarCadastrarAtendenteNovoCadastro,
            permissaoAlterarCadastrarSetoresNovoCadastro: state.AtendenteReducer.permissaoAlterarCadastrarSetoresNovoCadastro,
            permissaoAlterarCadastrarAvisosNovoCadastro: state.AtendenteReducer.permissaoAlterarCadastrarAvisosNovoCadastro,
            permissaoAcessoRelatoriosNovoCadastro: state.AtendenteReducer.permissaoAcessoRelatoriosNovoCadastro,
            nomeAtendenteNovoCadastro: state.AtendenteReducer.nomeAtendenteNovoCadastro,
            emailAtendenteNovoCadastro: state.AtendenteReducer.emailAtendenteNovoCadastro,
            senhaAtendenteNovoCadastro: state.AtendenteReducer.senhaAtendenteNovoCadastro,
            confirmaSenhaAtendenteNovoCadastro: state.AtendenteReducer.confirmaSenhaAtendenteNovoCadastro,

            uriImagemPadraoTelaCadastroAtendente: state.AtendenteReducer.uriImagemPadraoTelaCadastroAtendente,
            arquivoCompletoAtendenteParaUparNovoCadastro: state.AtendenteReducer.arquivoCompletoAtendenteParaUparNovoCadastro,
            loadingBotaoCadastrarAtendente: state.AtendenteReducer.loadingBotaoCadastrarAtendente,
            retornaTelaClienteParaNovoAtendimentos: state.ChamadoReducer.retornaTelaClienteParaNovoAtendimentos,
            keyUsuarioLogado: state.PerfilReducer.keyUsuarioLogado,
            acessouAtravesPerfil: state.PerfilReducer.acessouAtravesPerfil,
            permissoesAcessarTelasAtendente: state.AtendenteReducer.permissoesAcessarTelasAtendente,
            licensawebouapp: state.PremiumReducer.licensawebouapp,
            isAdmin: state.PerfilReducer.isAdmin,


            permissaoCancelarAtendimentoNovoCadastro: state.AtendenteReducer.permissaoCancelarAtendimentoNovoCadastro,

            permissaoCancelarAtendimento: state.AtendenteReducer.permissaoCancelarAtendimento,

        }
    );
};
export default connect(mapStateToProps, {

    modificaSenhaAtendente, modificaConfirmaSenhaAtendente, alteraSwitchPermissaoAlterarAtendente,
    alteraSwitchPermissaoAlterarSetores, alteraSwitchPermissaoAlterarAvisos, alteraSwitchPermissaoAcessoRelatorios,
    modificaNomeAtendente, modificaEmailAcessoApp, alteraSwitchPermissaoAlterarCliente, alteraSwitchPermissaoAlterarAdministrador,
    modificaFiltroClientesTelaAtendentes,
    mudaVisibilidadeModalExcluirAtendente, modificaVisibleModalNovoAtendente, modificaNomeRotaNoHeader,
    consultaAtendente2, insereFotoAtendenteTelaAlterar, modificaArquivoCompletoAtendenteParaUpar,
    modificaAtendenteSelecionadoSectioned, listaAtendentesTelaConsultaAtendente,
    consultaAtendentecomAtendente, alterarAtendente, excluiAtendente,

    alteraSwitchPermissaoAcessoRelatoriosNovoCadastro, alteraSwitchPermissaoAlterarAvisosNovoCadastro,
    alteraSwitchPermissaoAlterarSetoresNovoCadastro, modificaNomeAtendenteNovoCadastro,
    modificaEmailAcessoAppNovoCadastro, modificaSenhaAtendenteNovoCadastro,
    modificaConfirmaSenhaAtendenteNovoCadastro, alteraSwitchPermissaoAlterarAdministradorNovoCadastro,
    alteraSwitchPermissaoAlterarClienteNovoCadastro, alteraSwitchPermissaoAlterarAtendenteNovoCadastro,
    insereFotoCadastroAtendente, modificaArquivoCompletoAtendenteParaUparNovoCadastro,
    cadastrarAtendente, modificaRetornaTelaClienteParaNovoAtendimento,
    acessouMeuPErfil, desligaOuvintesChamadosFirebase, modificaPermissaoCancelarAtendimentoNovoCadastro, alteraSwitchPermissaoCancelarAtendimento
})(withRouter(Atendentes));
