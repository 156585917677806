import moment from 'moment';
import firebase from '../servicos/FirebaseConnection';
import { toastr } from 'react-redux-toastr';


import {
    ALTERASWITCHNOTIFATENDABERTOPORMIM, ALTERASWITCHNOTIFATENDESTOUATENDENDO, ALTERASWITCHNOTIFRESPATENDABERTOPORMIM,
    ALTERASWITCHNOTIFRESPATENDQUEESTOUATENDENDO, ATIVADESATIVALOADINGTELANOTIFICACAO,
    ATIVADESATIVALOADINBOTAOALTERARNOTIFICACAO, VERIFICACONEXAOINTERNETNOTIFICAO, ZERAVERIFICASEALTEROUCOMSUCESSONOTIFICACAO,
    VERIFICASEALTEROUCOMSUCESSONOTIFICACAO, ALTERASWITCHNOTIFCOMENTARIOINTERNO
} from './types';


export const alterarConfiguracaoNotificao = (notifAtendAberto2, notifAtendEstouAtendendo2, notifRespAtendAbertoPorMim2, notifRespAtendQueEstouAtendendo2, notifComentarioInterno) => {
    return dispatch => {
        // 
        let notifAtendAberto = (notifAtendAberto2 == true) ? 1 : 0;
        let notifAtendEstouAtendendo = (notifAtendEstouAtendendo2 == true) ? 1 : 0;
        let notifRespAtendAbertoPorMim = (notifRespAtendAbertoPorMim2 == true) ? 1 : 0;
        let notifRespAtendQueEstouAtendendo = (notifRespAtendQueEstouAtendendo2 == true) ? 1 : 0;
        AsyncStorage.getItem('chaveUnica').then((chaveUnica2) => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            ativaDesativaLoadingBOtaoAlterarNotificacao(dispatch, true)
            firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild('uid').equalTo(firebase.auth().currentUser.uid).once("value").then((snapshot) => {
                let keyAtendente = Object.keys(snapshot.val()).toString()
                firebase.database().ref(`${chaveUnica}/usuarios/${keyAtendente}`).update({
                    notifNovoAtend: notifAtendAberto,
                    notifAtendimentoAtribuidoAmim: notifAtendEstouAtendendo,
                    notifRespAtendAbertosPorMim: notifRespAtendAbertoPorMim,
                    notifRespAtendEmQueEstouAtend: notifRespAtendQueEstouAtendendo,
                    notifComentarioInterno
                }).then(() => {
                    ativaDesativaLoadingBOtaoAlterarNotificacao(dispatch, false);
                    toastr.success('Sucesso', 'Notificação Alterada com sucesso!');
                })
            })
        })
    }
}
export const ativaDesativaLoadingBOtaoAlterarNotificacao = (dispatch, valor) => {
    dispatch({
        type: ATIVADESATIVALOADINBOTAOALTERARNOTIFICACAO,
        payload: valor
    })
}
export const ativaDesativaLoadingTelaNotificacao = (dispatch, valor) => {
    dispatch({
        type: ATIVADESATIVALOADINGTELANOTIFICACAO,
        payload: valor
    })
}



export const verificaConexaoInternetNotificacao = (valor) => {
    return {
        type: VERIFICACONEXAOINTERNETNOTIFICAO,
        payload: valor
    }
}

export const alteraSwitchNotifAtendABertoPorMim = (valor) => {
    return {
        type: ALTERASWITCHNOTIFATENDABERTOPORMIM,
        payload: valor
    }
}
export const alteraSwitchNotifAtendEstouAtendendo = (valor) => {
    return {
        type: ALTERASWITCHNOTIFATENDESTOUATENDENDO,
        payload: valor
    }
}
export const alteraSwitchNotifRespAtendAbertoPorMim = (valor) => {
    return {
        type: ALTERASWITCHNOTIFRESPATENDABERTOPORMIM,
        payload: valor
    }
}
export const alteraSwitchNotifRespAtendQueEstouAtendendo = (valor) => {
    return {
        type: ALTERASWITCHNOTIFRESPATENDQUEESTOUATENDENDO,
        payload: valor
    }
}
export const alteraSwitchNotifComentarioInterno = (valor) => {
    return {
        type: ALTERASWITCHNOTIFCOMENTARIOINTERNO,
        payload: valor
    }
}




export const alteraSwitchNotifAtendABertoPorMimLocal = (dispatch, valor) => {
    dispatch({
        type: ALTERASWITCHNOTIFATENDABERTOPORMIM,
        payload: valor
    })
}
export const alteraSwitchNotifAtendEstouAtendendoLocal = (dispatch, valor) => {
    dispatch({
        type: ALTERASWITCHNOTIFATENDESTOUATENDENDO,
        payload: valor
    })
}
export const alteraSwitchNotifRespAtendAbertoPorMimLocal = (dispatch, valor) => {
    dispatch({
        type: ALTERASWITCHNOTIFRESPATENDABERTOPORMIM,
        payload: valor
    })
}
export const alteraSwitchNotifRespAtendQueEstouAtendendoLocal = (dispatch, valor) => {
    dispatch({
        type: ALTERASWITCHNOTIFRESPATENDQUEESTOUATENDENDO,
        payload: valor
    })
}
const verificaSeAlterouComSucessoNotificacao = (dispatch, texto) => {
    dispatch({
        type: VERIFICASEALTEROUCOMSUCESSONOTIFICACAO,
        payload: texto
    });
}
export const zeraverificaSeAlterouComSucessoNotificacao = () => {
    return {
        type: VERIFICASEALTEROUCOMSUCESSONOTIFICACAO,
        payload: ''
    }
}

const AsyncStorage = {
    setItem: function (key, value) {
        return Promise.resolve().then(function () {
            localStorage.setItem(key, value);
        });
    },
    getItem: function (key) {
        return Promise.resolve().then(function () {
            return localStorage.getItem(key);
        });
    }
};