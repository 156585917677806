import React from 'react';
import { connect } from 'react-redux';
import api from '../../http_client/http.client';
import SelectSearch from 'react-select-search';
import firebase from '../../servicos/FirebaseConnection';
import { withRouter } from "react-router-dom";
import moment from 'moment';
import { Modal } from 'react-responsive-modal';
import ClipLoader from "react-spinners/ClipLoader";
import { Link } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Cards from 'react-credit-cards';
import ReactTooltip from "react-tooltip";
import qrcodeimage from '../../imagens/qr-code.png';
import XLSX from 'xlsx';
import { modificaNomeRotaNoHeader } from '../../actions/HeaderAction';

import {
    modificaModalDuvidasFrequentes, inserePlanoPremium, inserePlanoPremiumBoleto, ativaDesativaRenovarPLano,
    modificaMotivoCancelamentoTelaVersaoPremium, insereMotivoCancelamentoBanco, inserePlanoPremiumPix,
    modificaMostraVersaoExpirando
} from '../../actions/DashBoardAction';
import 'react-credit-cards/es/styles-compiled.css';
import NumberFormat from 'react-number-format';
import { cpf, cnpj } from 'cpf-cnpj-validator';
import { toastr } from 'react-redux-toastr';
import {
    Container, CabecalhoVersaoPremium, TextCabecalho, ContainerDivMensalAnual,
    DivMensal, DivAnual, TextMensalAnual, ContainerPlanos, Planos,
    DivTextPlano, TextPlano, TextSubPlano, DivIconePlano, DivPreco,
    TextPreco, TextMes, DivContainerOpcoes, ButtonAssinar, DivPrecoAnual,
    DivEconomia, TextMesEconomia, ContainerModal, ContainerIconeFecharModal, TextNovoAtendimento, IconeFechar,
    ContainerVersaoPremiumAtiva, ContainerAssinaturaAtiva, DivTextVPAtivo, TextVersaoPremium, ButtonTrocarPlano,
    ContainerVersaoPremiumSuspensa, ContainerModalCompra,
    InputSearch, DivInputSearch, IconeSetaPraBaixo, ContainerModalCompraSucesso, ContainerModalCancelarAssinatura,
    ButtonCancelar, DivRecomendadoContainer, DivRecomendado, InputCancelamento, TextAssinaVersaoPremium,
    ContainerSelectSearch, InputSearchQtdAtendentes
} from './styled';

import imgProtection from '../../imagens/protection.png';
import imgNoProtected from '../../imagens/noprotected.png';
import imgPix from '../../imagens/logo-pix-512.png';
import versaoPremiumAtivaSucesso from '../../imagens/versaoPremiumAtivaSucesso.jpg';
import backGroundStopVersaoPremium from '../../imagens/backGroundStopVersaoPremium.jpg';
const AsyncStorage = {
    setItem: function (key, value) {
        return Promise.resolve().then(function () {
            localStorage.setItem(key, value);
        });
    },
    getItem: function (key) {
        return Promise.resolve().then(function () {
            return localStorage.getItem(key);
        });
    }
};

class VersaoPremium extends React.Component {
    state = {
        mensal: true,
        qtdAtendenteSelecionado: 1,
        pickerOpacity: 0,
        opacityOfOtherItems: 1,
        loadingBotaoAssinatura: false,
        loadingModalCancelar: false,
        valorDinamicoMensalidade: 11.90,
        telaDownloadBoleto: false,
        telaLeituraPixQrCode: false,
        modalCompra: false,
        modalSairVisible: false,
        textPlanoVisible: false,
        textCompraSeguraVisible: false,
        aposCompraVisible: false,
        trocarCelularVisible: false,
        paraTodosVisible: false,
        baratoVisible: false,
        maisDuvidasVisible: false,
        textDevolucaoPagamento: false,
        cancelarinscricao: false,
        productList: [],
        subsList: [],
        receipt: '',
        availableItemsMessage: '',
        trocarPlano: false,
        idProduto: '',
        versaoGratis: false,
        DiasTerminarTesteGratis: 0,
        comprarVersaoPremium: false,
        modalErrorPagamento: false,
        modalVersaoPremiumAtivaSucesso: false,
        modalErrorTrocarPlano: false,
        modalErrorGerenciarAssinatura: false,
        modalTrocarPlano: false,
        modalPagamento: false,
        modalCancelarAssinatura: false,
        cvc: '',
        expiry: '',
        focus: '',
        name: '',
        number: '',
        cpf: '',
        email: '',
        endereco: '',
        telephone: '',
        numero: '',
        cidade: '',
        ibge: '',
        cep: '',
        uf: '',
        linkBoleto: '',
        nomeBoleto: '',
        mostraVersaoExpirando: false,
        bordaNomeBoleto: '#FFF',
        bordaNumeroCartao: '#FFF',
        bordaNomeCartao: '#FFF',
        bordaValidadeCartao: '#FFF',
        bordaCvcCartao: '#FFF',

        bordaCpf: '#FFF',
        bordaEmail: '#FFF',
        bordaEndreco: '#FFF',
        bordaNumero: '#FFF',
        bordaCidade: '#FFF',
        bordaCEP: '#FFF',
        mensagemPlanoSelecionado: '',
        planoSelecionado: '',
        loadingBotaoFinalizar: false,
        formapagamento: [
            {
                name: 'SELECIONE A FORMA DE PAGAMENTO',
                type: 'group',
                items: [
                    { value: "Pix", name: "Pix" },
                    { value: "Cartão de Crédito", name: "Cartão de Crédito" },
                    { value: "Boleto Bancário", name: "Boleto Bancário" },
                ]
            }
        ],
        formapagamentosomenteboleto: [
            {
                name: 'SELECIONE A FORMA DE PAGAMENTO',
                type: 'group',
                items: [
                    /*  { value: "Cartão de Crédito", name: "Cartão de Crédito" }, */
                    { value: "Boleto Bancário", name: "Boleto Bancário" },
                ]
            }
        ],
        pagamentoSelecionado: 'Pix',
        pagamentoSelecionadosomenteboleto: 'Boleto Bancário',
        quantidadeAtendentes: [
            {
                name: 'QUANTIDADE DE ATENDENTES',
                type: 'group',
                items: [
                    /*  { value: "Cartão de Crédito", name: "Cartão de Crédito" }, */
                    { value: "5", name: "5 Atendentes" },
                    { value: "10", name: "10 Atendentes" },
                    { value: "15", name: "15 Atendentes" },
                    { value: "20", name: "20 Atendentes" },
                    { value: "30", name: "30 Atendentes" },
                    { value: "40", name: "40 Atendentes" },
                    { value: "50", name: "50 Atendentes" },
                    { value: "60", name: "60 Atendentes" },
                    { value: "80", name: "80 Atendentes" },
                    { value: "100", name: "100 Atendentes" },
                    { value: "120", name: "120 Atendentes" },
                ]
            }
        ],
        atendenteSelecionado: '',

        gerandoQRCODEPIX: true,
        qrCodePix: '',
        pixCopiaCola: '',
        diasRestantesBoleto: null
        // qrCodePix: 'data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAADIAQAAAACFI5MzAAACzklEQVR4Xu2XPY6kMBCFCzlwBhew5GuQ+UpwAX4u0FzJma+BxAUgc2BR+6pHgu7VbtC2tNqgrdGoh6+FwfXq1Rvivy36/cK1vuRLZP1L4qmKqWt5YT58agKRLiCBjwDIO1HDptMWVwoI9XHtHD9c6ohPbapC4tUcN/bbwWvtigmnwdmJ7C4nUUhwBnaOamYzMr71fjqfEtQnmP7+eavcxwRLqm0PKc42yyYFxFNHZgz4rY6YiLaHKyBxW/w2tfhKIpeaSHUJwT6tPZ3Fue5tgoieD55LYqpb3N4+2rXWG4rfcAnBjWmQfTYUvCN8KCDBnoRjoEGjSjhOvEEJkRaRVvP24bCPaLOA0EBoO3RbGrRaeB1LiFdTK9pBHzOLdpYSwmlkJdoJPDM6WF1KzCFBHbh9y7O3R6Q+3PtkEeiFd8enSxU+e6p1AZGeWytRkNrF9tDKJUQBQol49SOuEPilnRwiNTEyK0i003tq7vpkEBoDhCNeVdN2hHRpJ4f4FfYJ7VRBtNOIlRaQgH0stDOznRmlTpdX5RBeCSPR2Z1E4B3R/T4ZJPBDG5RlIhLtyJ8lhIiU7ON4wuiGJRSRFca5eIXmABnDS89lECga1qLN6Pkk00d7e3wGYXVSavyzS/Q6QuZFZK0C3E6G2MFoX7oyRQ7Bw7biLgvs+Snq6wzyyN7C5pHCeI4Gc/KemhmEE560520iWMs2I3jKxVziDWlJnZPbZJ+X2ZhDMKhDGpyCK+/IFAz/KyDQTlzx1KcW25vat4n+OVGnTkOLSWsGLS34XLnES8wZf7IYwiymbgkRSDLN2Mj0xp66gEiGhXAgGbsgEQfzlm4/JcjXLFlM8rV41a2dLII0gWqLY+0OHUwvHp9HkMUQATC9cQZ3TswkCHcaZoyay9Beigj+L8Gr4wwS/Bh5504oGUTqA7ejWrI/jWjl18p9Sv68vuRLZP3P5BeQbGBcejhVBAAAAABJRU5ErkJggg=='
    }
    componentDidMount() {

        if (this.props.isAdmin == true) {
            ReactTooltip.rebuild();
            if (this.props.nomeRota !== 'Versão Premium') {
                this.props.modificaNomeRotaNoHeader('Versão Premium')
            }

        } else {
            toastr.error('Acesso Negado', 'Somente ADMINISTRADOR tem permissão para acessar esta tela');
            this.props.history.push('/dashboard')
        }
        // this.criarAssinatura()
    }
    componentWillMount() {
        /*
        */
        if (this.props.dataCompra != '' && this.props.dataValidoAte != '' && this.props.premium == 1) {
            let diferencaDia = this.calculaDias2(this.props.dataCompra, this.props.dataValidoAte);


            if (diferencaDia <= 31 && this.props.dataValidadeAtual === undefined) {

                let dataAtual = this.retornaDataAtual();
                let diasRestantesVersaoGratis = this.calculaDiasRestantesVersaoGratis(dataAtual, this.props.dataValidoAte);

                this.setState({ DiasTerminarTesteGratis: diasRestantesVersaoGratis, versaoGratis: true })
            } else {

                this.setState({ versaoGratis: false })
            }

        }


    }
    componentWillReceiveProps(nextProps) {
        if (this.props.dataValidoAte !== nextProps.dataValidoAte && nextProps.statusAssinatura === 'pix') {
            this.setState({ modalPagamento: false });
            this.props.modificaMostraVersaoExpirando(false);
            setTimeout(() => {
                this._toggleModalVersaoPremiumSucesso();
            }, 300);

        }

        if (nextProps.dataValidoAte && !this.props.mostraVersaoExpirando) {
            let dataAtual = this.retornaDataAtual();
            let diasRestantesBoleto = this.calculaDiasRestantesVersaoGratis(dataAtual, nextProps.dataValidoAte);
            if (diasRestantesBoleto <= 10 && nextProps.statusAssinatura == 'boleto' || diasRestantesBoleto <= 10 && nextProps.statusAssinatura == 'pix') {
                this.props.modificaMostraVersaoExpirando(true)
                this.setState({ diasRestantesBoleto: diasRestantesBoleto })
            } else {
                this.props.modificaMostraVersaoExpirando(false)
                //    this.setState({ mostraVersaoExpirando: false });
                this.props.ativaDesativaRenovarPLano(false);
            }
        }
    }
    renderTextWebAppAssinaturaATiva() {
        if (this.props.licensawebouapp === 0 || this.props.licensawebouapp === 1) {
            return <p style={{ color: this.props.coresLetraPadrao, fontSize: 12 }}>Acesso via APP</p>
        } else if (this.props.licensawebouapp === 2) {
            return <p style={{ color: this.props.coresLetraPadrao, fontSize: 12 }}>Acesso via WEB</p>
        } else {
            return <p style={{ color: this.props.coresLetraPadrao, fontSize: 12 }}>Acesso via WEB e APP</p>
        }
    }
    handleInputFocus = (e) => {
        this.setState({ focus: e.target.name, bordaNumeroCartao: '#00FF7F' });
    }
    handleLoadingBotaoFinalizar = (value) => {
        this.setState({ loadingBotaoFinalizar: value });
    }


    handleInputChange = (e) => {
        const { name, value } = e.target;

        this.setState({ [name]: value });
    }
    handleInputFocusNome = (e) => {
        this.setState({ focus: e.target.name, bordaNomeCartao: '#00FF7F' });
    }
    _toggleLoadingModalCancelar(value) {
        this.setState({ loadingModalCancelar: value })
    }
    handleInputChangeNome = (e) => {
        const { name, value } = e.target;

        this.setState({ [name]: value });
    }
    handlechangeCPF(e) {
        this.setState({ cpf: e.target.value })
    }
    handlechangeEmail(e) {
        this.setState({ email: e.target.value })
    }

    handleChangeTelephone(e) {
        this.setState({ telephone: e.target.value.replace(/\D/g, "") })
    }
    handlechangeEndereco(e) {
        this.setState({ endereco: e.target.value })
    }
    handlechangeNumero(e) {
        this.setState({ numero: e.target.value })
    }
    handlechangeCidade(e) {
        this.setState({ cidade: e.target.value })
    }
    handlechangeCidadeIBGE(value) {
        this.setState({ ibge: value })
    }
    handlechangeCEP(e) {
        this.setState({ cep: e.target.value })
    }
    handleInputChangeValidade = (e) => {
        const { name, value } = e.target;

        this.setState({ [name]: value });
    }
    handleInputFocusValidade = (e) => {
        this.setState({ focus: e.target.name, bordaValidadeCartao: '#00FF7F' });
    }
    handlechangePagamento = (value) => {
        this.setState({ pagamentoSelecionado: value });
    }
    handleInputChangeCodSeguranca = (e) => {
        const { name, value } = e.target;

        this.setState({ [name]: value });
    }
    handlechangeNomeBoleto(e) {
        this.setState({ nomeBoleto: e.target.value })
    }
    handleInputFocusCodSeguranca = (e) => {
        this.setState({ focus: e.target.name, bordaCvcCartao: '#00FF7F' });
    }
    toggleTextoCompraSegura() {
        this.setState({ textCompraSeguraVisible: !this.state.textCompraSeguraVisible });
    }
    toggleModalPagamento(qtdAtendentes) {
        let planoSelecionado = '';
        let mensagemPlano = '';
        if (qtdAtendentes === '') {
            toastr.error('ERROR', 'Selecione a quantidade de atendentes da sua empresa')
        } else if (qtdAtendentes === "10") {
            let state = this.state;
            state.mensagemPlanoSelecionado = 'Plano Modesto R$ 99,90';
            state.planoSelecionado = qtdAtendentes;
            state.modalPagamento = !this.state.modalPagamento;
            this.setState({ state });
        } else if (qtdAtendentes === "15") {
            let state = this.state;
            state.mensagemPlanoSelecionado = 'Plano Enérgico R$ 199,90';
            state.planoSelecionado = qtdAtendentes;
            state.modalPagamento = !this.state.modalPagamento;
            this.setState({ state });
        } else if (qtdAtendentes === "20") {
            let state = this.state;
            state.mensagemPlanoSelecionado = 'Plano Inércia R$ 299,90';
            state.planoSelecionado = qtdAtendentes;
            state.modalPagamento = !this.state.modalPagamento;
            this.setState({ state });
        } else if (qtdAtendentes === "30") {
            let state = this.state;
            state.mensagemPlanoSelecionado = 'Plano Reconhecimento R$ 399,90';
            state.planoSelecionado = qtdAtendentes;
            state.modalPagamento = !this.state.modalPagamento;
            this.setState({ state });
        }
        else if (qtdAtendentes === "40") {
            let state = this.state;
            state.mensagemPlanoSelecionado = 'Plano Grande Metas R$ 499,90';
            state.planoSelecionado = qtdAtendentes;
            state.modalPagamento = !this.state.modalPagamento;
            this.setState({ state });
        }
        else if (qtdAtendentes === "50") {
            let state = this.state;
            state.mensagemPlanoSelecionado = 'Plano Crescimento R$ 599,90';
            state.planoSelecionado = qtdAtendentes;
            state.modalPagamento = !this.state.modalPagamento;
            this.setState({ state });
        }
        else if (qtdAtendentes === "80") {
            let state = this.state;
            state.mensagemPlanoSelecionado = 'Plano Veloz R$ 799,90';
            state.planoSelecionado = qtdAtendentes;
            state.modalPagamento = !this.state.modalPagamento;
            this.setState({ state });
        }
        else if (qtdAtendentes === "100") {
            let state = this.state;
            state.mensagemPlanoSelecionado = 'Plano Imensidão R$ 899,90';
            state.planoSelecionado = qtdAtendentes;
            state.modalPagamento = !this.state.modalPagamento;
            this.setState({ state });
        }


        else if (qtdAtendentes === "60") {
            let state = this.state;
            state.mensagemPlanoSelecionado = 'Plano Aceleração R$ 699,90';
            state.planoSelecionado = qtdAtendentes;
            state.modalPagamento = !this.state.modalPagamento;
            this.setState({ state });
        } else if (qtdAtendentes === "120") {
            let state = this.state;
            state.mensagemPlanoSelecionado = 'Plano Robusto R$ 999,90';
            state.planoSelecionado = qtdAtendentes;
            state.modalPagamento = !this.state.modalPagamento;
            this.setState({ state });
        } else {
            //5
            let state = this.state;
            state.mensagemPlanoSelecionado = 'Plano Incentivo R$ 49,90';
            state.planoSelecionado = qtdAtendentes;
            state.modalPagamento = !this.state.modalPagamento;
            this.setState({ state });
        }

    }
    toggleAposCompra() {
        this.setState({ aposCompraVisible: !this.state.aposCompraVisible });
    }
    toggleTrocarCelular() {
        this.setState({ trocarCelularVisible: !this.state.trocarCelularVisible });
    }
    renderPlanoVersaoNova() {
        if (this.props.plano == 'Plano Mensal 51 Atendentes') {
            return 'DROPDESK PREMIUM MENSAL'
        } else if (this.props.plano == 'Plano Mensal 52 Atendentes') {
            return 'DROPDESK PREMIUM ANUAL'
        } else {
            return this.props.plano;
        }
    }
    toggleParaTodos() {
        this.setState({ paraTodosVisible: !this.state.paraTodosVisible });
    }
    toggleBarato() {
        this.setState({ baratoVisible: !this.state.baratoVisible });
    }
    toggleMaisDuvidas() {
        this.setState({ maisDuvidasVisible: !this.state.maisDuvidasVisible });
    }
    toggleDevolucaoPagamento() {
        this.setState({ textDevolucaoPagamento: !this.state.textDevolucaoPagamento });
    }
    _toggleMensal(value) {
        this.setState({ mensal: value })
    }
    retornaDataAtual() {
        let dataAtual = moment().toDate();
        dataAtual = moment(dataAtual).format('DD/MM/YYYY');
        return dataAtual;
    }
    renderQtdAtendLiberados() {
        if (this.props.qtdAtendentesLiberados == 999) {
            return 'ilimitado';
        } else {
            return this.props.qtdAtendentesLiberados;
        }
    }
    calculaDiasRestantesVersaoGratis(date1, date2) {
        let data1 = moment(date1, 'DD/MM/YYYY');
        let data2 = moment(date2, 'DD/MM/YYYY');
        let diff = Math.abs(data2.diff(data1, 'days'));
        return diff;
    }
    calculaDias2(date1, date2) {
        //formato do brasil 'pt-br'
        // moment.locale('pt-br');
        //setando data1
        let data1 = moment(date1, 'DD/MM/YYYY');
        //setando data2
        let data2 = moment(date2, 'DD/MM/YYYY');
        //tirando a diferenca da data2 - data1 em dias
        // let diff = data2.diff(data1, 'days');
        let diff = moment.duration(data2.diff(data1)).asDays();

        return diff;
    }
    renderValorMensalidadeQtdAtendente() {
        if (this.state.atendenteSelecionado === "10") {
            return (
                <>
                    <TextPreco corPrincipal={this.props.coresLetraPadrao}>R$ 99,90</TextPreco>
                    <TextMes corPrincipal={this.props.coresLetraPadrao}>/mensal</TextMes>
                </>
            )
        } else if (this.state.atendenteSelecionado === "15") {
            return (
                <>
                    <TextPreco corPrincipal={this.props.coresLetraPadrao}>R$ 199,90</TextPreco>
                    <TextMes corPrincipal={this.props.coresLetraPadrao}>/mensal</TextMes>
                </>
            )
        } else if (this.state.atendenteSelecionado === "20") {
            return (
                <>
                    <TextPreco corPrincipal={this.props.coresLetraPadrao}>R$ 299,90</TextPreco>
                    <TextMes corPrincipal={this.props.coresLetraPadrao}>/mensal</TextMes>
                </>
            )
        }
        else if (this.state.atendenteSelecionado === "30") {
            return (
                <>
                    <TextPreco corPrincipal={this.props.coresLetraPadrao}>R$ 399,90</TextPreco>
                    <TextMes corPrincipal={this.props.coresLetraPadrao}>/mensal</TextMes>
                </>
            )
        }
        else if (this.state.atendenteSelecionado === "40") {
            return (
                <>
                    <TextPreco corPrincipal={this.props.coresLetraPadrao}>R$ 499,90</TextPreco>
                    <TextMes corPrincipal={this.props.coresLetraPadrao}>/mensal</TextMes>
                </>
            )
        }
        else if (this.state.atendenteSelecionado === "50") {
            return (
                <>
                    <TextPreco corPrincipal={this.props.coresLetraPadrao}>R$ 599,90</TextPreco>
                    <TextMes corPrincipal={this.props.coresLetraPadrao}>/mensal</TextMes>
                </>
            )
        }
        else if (this.state.atendenteSelecionado === "60") {
            return (
                <>
                    <TextPreco corPrincipal={this.props.coresLetraPadrao}>R$ 699,90</TextPreco>
                    <TextMes corPrincipal={this.props.coresLetraPadrao}>/mensal</TextMes>
                </>
            )
        }

        else if (this.state.atendenteSelecionado === "80") {
            return (
                <>
                    <TextPreco corPrincipal={this.props.coresLetraPadrao}>R$ 799,90</TextPreco>
                    <TextMes corPrincipal={this.props.coresLetraPadrao}>/mensal</TextMes>
                </>
            )
        }
        else if (this.state.atendenteSelecionado === "100") {
            return (
                <>
                    <TextPreco corPrincipal={this.props.coresLetraPadrao}>R$ 899,90</TextPreco>
                    <TextMes corPrincipal={this.props.coresLetraPadrao}>/mensal</TextMes>
                </>
            )
        }
        else if (this.state.atendenteSelecionado === "120") {
            return (
                <>
                    <TextPreco corPrincipal={this.props.coresLetraPadrao}>R$ 999,90</TextPreco>
                    <TextMes corPrincipal={this.props.coresLetraPadrao}>/mensal</TextMes>
                </>
            )
        } else {
            return (
                <>
                    <TextPreco corPrincipal={this.props.coresLetraPadrao}>R$ 49,90</TextPreco>
                    <TextMes corPrincipal={this.props.coresLetraPadrao}>/mensal</TextMes>
                </>
            )
        }
    }
    renderLoadingVersaoVIP() {
        //tela loading
        if (this.props.loadingTelaVIP) {
            return (
                <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                    <ClipLoader
                        css={{ margin: 10 }}
                        size={40}
                        color={this.props.corBotaoPadrao}
                        loading={true}
                    />
                </div>
            )
        }
        //Tela suspensa, cartão nao passou Juno
        else if (this.props.premium == 0 && this.props.premiumVersaoGratis == false && this.props.statusAssinatura == 'suspensaCartaoRecusado' && this.props.renovarPlano == false) {
            return (

                <ContainerAssinaturaAtiva>
                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                        <ContainerVersaoPremiumSuspensa backgroundDinamico={this.props.backgroundSegundo}>
                            <div style={{ display: 'flex', margin: 10, flexDirection: 'row', }}>
                                <DivTextVPAtivo>
                                    <TextVersaoPremium corPrincipal={this.props.coresLetraPadrao}>Versão Premium</TextVersaoPremium>
                                    <p style={{ fontSize: 20, fontWeight: 'bold', color: '#F9BF3B' }}>SUSPENSA</p>
                                </DivTextVPAtivo>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <img src={imgNoProtected} alt="transacao segura" style={{ height: 40, width: 40 }} />

                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 10, marginTop: 30, marginBottom: 20 }}>
                                <p style={{ fontSize: 13, color: '#FFF', fontWeight: 'bold', textAlign: 'center' }}>Há um problema para realizar o pagamento no seu cartão vinculado ao DropDesk. Por esse motivo os recursos PREMIUM foram retirados e sua assinatura já foi cancelada. Porém você pode assinar novamente e ter a versão Premium liberada imediatamente, clicando em <span style={{ color: this.props.corBotaoPadrao }}>"INFORMAR DADOS NOVAMENTE"</span>.</p>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <ButtonTrocarPlano backGroundDinamico={this.props.corBotaoPadrao} corLetraBotao={this.props.letraBotaoPadrao} type="button"
                                    onClick={() => { this.props.ativaDesativaRenovarPLano(true); }}>
                                    {/* <i className="icon-trocarplano" style={{ color: this.props.letraBotaoPadrao, fontSize: 16, padding: 5 }} /> */}
                                    INFORMAR DADOS NOVAMENTE
                                </ButtonTrocarPlano>
                            </div>
                            {/*  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 20 }}>
                                <ButtonTrocarPlano backGroundDinamico={this.props.corBotaoPadrao} corLetraBotao={this.props.letraBotaoPadrao} type="button" onClick={() => { }}>
                                    GERENCIAR ASSINATURA
                                </ButtonTrocarPlano>
                            </div> */}
                            <div style={{ display: 'flex', flex: 1, justifyContent: 'center', }}>
                                <div style={{ margin: 10, marginTop: 30 }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', flex: 1 }}>
                                            <i className="icon-close" style={{ color: '#9fadb7', fontSize: 13, padding: 5 }} />
                                            <p style={{ color: '#9fadb7', fontSize: 12, textDecoration: 'line-through' }}>Acesso via WEB e APP</p>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', flex: 1 }}>
                                            <i className="icon-close" style={{ color: '#9fadb7', fontSize: 13, padding: 5 }} />
                                            <p style={{ color: '#9fadb7', fontSize: 12, textDecoration: 'line-through' }}>Suporte via CHAT</p>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', flex: 1 }}>
                                            <i className="icon-close" style={{ color: '#9fadb7', fontSize: 13, padding: 5 }} />
                                            <p style={{ color: '#9fadb7', fontSize: 12, textDecoration: 'line-through' }}>Sem anúncios</p>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', flex: 1 }}>
                                            <i className="icon-close" style={{ color: '#9fadb7', fontSize: 13, padding: 5 }} />
                                            <p style={{ color: '#9fadb7', fontSize: 12, textDecoration: 'line-through' }}>Relatórios completos</p>
                                        </div>
                                    </div>

                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', flex: 1 }}>
                                            <i className="icon-close" style={{ color: '#9fadb7', fontSize: 13, padding: 5 }} />
                                            <p style={{ color: '#9fadb7', fontSize: 12, textDecoration: 'line-through' }}>Upload Arquivos</p>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', flex: 1 }}>
                                            <i className="icon-close" style={{ color: '#9fadb7', fontSize: 13, padding: 5 }} />
                                            <p style={{ color: '#9fadb7', fontSize: 12, textDecoration: 'line-through' }}>Emitir Avisos</p>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', flex: 1 }}>
                                            <i className="icon-close" style={{ color: '#9fadb7', fontSize: 13, padding: 5 }} />
                                            <p style={{ color: '#9fadb7', fontSize: 12, textDecoration: 'line-through' }}>Clientes ilimitados</p>
                                        </div>

                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', flex: 1 }}>
                                            <i className="icon-close" style={{ color: '#9fadb7', fontSize: 13, padding: 5 }} />
                                            <p style={{ color: '#9fadb7', fontSize: 12, textDecoration: 'line-through' }}>Avaliar atendimento</p>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', flex: 1 }}>
                                            <i className="icon-close" style={{ color: '#9fadb7', fontSize: 13, padding: 5 }} />
                                            <p style={{ color: '#9fadb7', fontSize: 12, textDecoration: 'line-through' }}>Integração WhatsApp</p>
                                        </div>

                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', flex: 1 }}>
                                            <i className="icon-close" style={{ color: '#9fadb7', fontSize: 13, padding: 5 }} />
                                            <p style={{ color: '#9fadb7', fontSize: 12, textDecoration: 'line-through' }}>Futuras funcionalidades</p>
                                        </div>
                                    </div>


                                </div>
                            </div>

                        </ContainerVersaoPremiumSuspensa>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 15 }} >

                        <div style={{ marginTop: 10, width: 600 }}>
                            <div style={{ margin: 10 }}>
                                <p style={{ fontSize: 14, color: '#F9BF3B', fontWeight: 'bold' }}>Assinatura Suspensa:</p>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 50 }}>
                                    <div style={{ height: 14, width: 14, backgroundColor: '#F9BF3B', borderRadius: 50, marginHorizontal: 10 }}>
                                    </div>
                                </div>
                                <div style={{}}>
                                    <p style={{ fontSize: 15, color: '#F9BF3B', fontWeight: 'bold' }}>{this.renderPlanoVersaoNova()}</p>
                                    <p style={{ fontSize: 13, color: this.props.coresLetraPadrao }}>Válido até: {this.props.dataValidadeAtual}</p>
                                    <p style={{ fontSize: 13, color: this.props.coresLetraPadrao }}>Atendentes Liberados para Cadastro: <span style={{ fontSize: 13, color: this.props.coresLetraPadrao, fontWeight: 'bold' }}>{this.renderQtdAtendLiberados()}</span></p>
                                    <p style={{ fontSize: 13, color: this.props.coresLetraPadrao }}>Nome Assinante: <span style={{ fontSize: 13, color: this.props.coresLetraPadrao, fontWeight: 'bold' }}>{this.props.nomeAssinante}</span></p>
                                    <p style={{ fontSize: 13, color: this.props.coresLetraPadrao }}>Email Assinante: <span style={{ fontSize: 13, color: this.props.coresLetraPadrao, fontWeight: 'bold' }}>{this.props.emailAssinante}</span></p>
                                    {/*   <p style={{ fontSize: 13, color: this.props.coresLetraPadrao }}>Plano: <span style={{ fontSize: 13, color: this.props.coresLetraPadrao, fontWeight: 'bold' }}>{this.props.plano}</span></p> */}
                                </div>
                            </div>
                        </div>

                    </div>

                </ContainerAssinaturaAtiva>
            )
        }
        //Tela Assinatura SUSPENSA

        //Tela assinatura ATIVA
        else if (this.props.premium == 1 && this.props.premiumVersaoGratis == false && this.props.dataValidoAte == '12/12/2099' && this.props.renovarPlano == false) {
            return (
                <ContainerAssinaturaAtiva>
                    <div style={{ display: 'flex', justifyContent: 'center', height: 360, }}>
                        <ContainerVersaoPremiumAtiva backgroundDinamico={this.props.backgroundSegundo}>
                            <div style={{ display: 'flex', margin: 10, flexDirection: 'row', }}>
                                <DivTextVPAtivo>
                                    <TextVersaoPremium corPrincipal={this.props.coresLetraPadrao}>Versão Premium</TextVersaoPremium>
                                    <p style={{ fontSize: 20, fontWeight: 'bold', color: '#00FF7F' }}>ATIVA</p>
                                </DivTextVPAtivo>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <img src={imgProtection} alt="transacao segura" style={{ height: 40, width: 40 }} />

                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 10, marginTop: 30, marginBottom: 20 }}>
                                <p style={{ fontSize: 15, color: this.props.coresLetraPadrao }}>Todas as funcionalidades PREMIUM estão liberadas.</p>
                            </div>
                            <div style={{ display: 'flex', flex: 1, justifyContent: 'center', }}>
                                <div style={{ margin: 10, marginTop: 30 }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', flex: 1 }}>
                                            <i className="icon-check" style={{ color: '#00FF7F', fontSize: 15, padding: 5 }} />
                                            <p style={{ color: this.props.coresLetraPadrao, fontSize: 12 }}>Acesso via WEB e APP</p>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', flex: 1 }}>
                                            <i className="icon-check" style={{ color: '#00FF7F', fontSize: 15, padding: 5 }} />
                                            <p style={{ color: this.props.coresLetraPadrao, fontSize: 12 }}>Suporte via CHAT</p>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', flex: 1 }}>
                                            <i className="icon-check" style={{ color: '#00FF7F', fontSize: 15, padding: 5 }} />
                                            <p style={{ color: this.props.coresLetraPadrao, fontSize: 12 }}>Integração com WhatsApp</p>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', flex: 1 }}>
                                            <i className="icon-check" style={{ color: '#00FF7F', fontSize: 15, padding: 5 }} />
                                            <p style={{ color: this.props.coresLetraPadrao, fontSize: 12 }}>Relatórios completos</p>
                                        </div>
                                    </div>

                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', flex: 1 }}>
                                            <i className="icon-check" style={{ color: '#00FF7F', fontSize: 15, padding: 5 }} />
                                            <p style={{ color: this.props.coresLetraPadrao, fontSize: 12 }}>Upload Arquivos</p>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', flex: 1 }}>
                                            <i className="icon-check" style={{ color: '#00FF7F', fontSize: 15, padding: 5 }} />
                                            <p style={{ color: this.props.coresLetraPadrao, fontSize: 12 }}>Emitir Avisos</p>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', flex: 1 }}>
                                            <i className="icon-check" style={{ color: '#00FF7F', fontSize: 15, padding: 5 }} />
                                            <p style={{ color: this.props.coresLetraPadrao, fontSize: 12 }}>Clientes ilimitados</p>
                                        </div>

                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', flex: 1 }}>
                                            <i className="icon-check" style={{ color: '#00FF7F', fontSize: 15, padding: 5 }} />
                                            <p style={{ color: this.props.coresLetraPadrao, fontSize: 12 }}>Avaliar atendimento</p>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', flex: 1 }}>
                                            <i className="icon-check" style={{ color: '#00FF7F', fontSize: 15, padding: 5 }} />
                                            <p style={{ color: this.props.coresLetraPadrao, fontSize: 12 }}>Sem Anúncios</p>
                                        </div>

                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', flex: 1 }}>
                                            <i className="icon-check" style={{ color: '#00FF7F', fontSize: 15, padding: 5 }} />
                                            <p style={{ color: this.props.coresLetraPadrao, fontSize: 12 }}>Todas Funcionalidades</p>
                                        </div>
                                    </div>


                                </div>
                            </div>

                        </ContainerVersaoPremiumAtiva>
                    </div>
                    <div style={{ display: 'flex', flex: 1, justifyContent: 'center', }} >

                        <div style={{ width: 600 }}>
                            <div style={{ margin: 10 }}>
                                <p style={{ fontSize: 14, color: this.props.coresLetraPadrao, fontWeight: 'bold' }}>Assinatura Ativa:</p>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 50 }}>
                                    <div style={{ height: 14, width: 14, backgroundColor: '#00FF7F', borderRadius: 50, marginHorizontal: 10 }}>
                                    </div>
                                </div>
                                <div style={{}}>
                                    <p style={{ fontSize: 15, color: this.props.coresLetraPadrao, fontWeight: 'bold' }}>{this.props.plano}</p>
                                    <p style={{ fontSize: 13, color: this.props.coresLetraPadrao }}>Válido até: {this.props.dataValidadeAtual}</p>
                                    <p style={{ fontSize: 13, color: this.props.coresLetraPadrao }}>Atendentes Liberados para Cadastro: <span style={{ fontSize: 13, color: this.props.coresLetraPadrao, fontWeight: 'bold' }}>{this.props.qtdAtendentesLiberados}</span></p>
                                    <p style={{ fontSize: 13, color: this.props.coresLetraPadrao }}>Nome Assinante: <span style={{ fontSize: 13, color: this.props.coresLetraPadrao, fontWeight: 'bold' }}>{this.props.nomeAssinante}</span></p>
                                    <p style={{ fontSize: 13, color: this.props.coresLetraPadrao }}>Email Assinante: <span style={{ fontSize: 13, color: this.props.coresLetraPadrao, fontWeight: 'bold' }}>{this.props.emailAssinante}</span></p>
                                    {/*  <p style={{ fontSize: 13, color: this.props.coresLetraPadrao }}>Plano: <span style={{ fontSize: 13, color: this.props.coresLetraPadrao, fontWeight: 'bold' }}>{this.props.plano}</span></p> */}
                                </div>
                            </div>
                        </div>

                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 20 }}>
                        <ButtonTrocarPlano backGroundDinamico={this.props.corBotaoPadrao} corLetraBotao={this.props.letraBotaoPadrao} type="button" onClick={() => { this._toggleModalCancelarAssinatura() }}>
                            {/* <i className="icon-trocarplano" style={{ color: this.props.letraBotaoPadrao, fontSize: 16, padding: 5 }} /> */}
                            CANCELAR ASSINATURA
                        </ButtonTrocarPlano>
                    </div>
                </ContainerAssinaturaAtiva>
            )
        }
        //Tela versao Premium Tradicional
        else if (this.props.premium == 1 && this.props.premiumVersaoGratis == false && this.props.dataValidoAte != '12/12/2099' && this.props.renovarPlano == false) {
            return (

                <ContainerAssinaturaAtiva>
                    {this.props.mostraVersaoExpirando == true && this.props.isAdmin === true && <div onClick={() => this.props.ativaDesativaRenovarPLano(true)} style={{ display: 'flex', position: 'relative', justifyContent: 'center', alignItems: 'center', paddingVertical: 7, backgroundColor: '#FFFFDD' }}>
                        <span style={{ color: '#000', fontSize: 14, fontWeight: 'bold' }}>Sua Versão Premium EXPIRARÁ em menos de {this.state.diasRestantesBoleto} dias, <Link style={{ color: '#39A3F4' }} to="/versaopremium">clique aqui</Link> para escolher um novo plano e gerar um novo PIX/boleto para renovar.</span>
                    </div>}
                    <div style={{ display: 'flex', justifyContent: 'center', height: 310, width: '100%' }}>
                        <ContainerVersaoPremiumAtiva backgroundDinamico={this.props.backgroundSegundo}>
                            <div style={{ display: 'flex', margin: 10, flexDirection: 'row', }}>
                                <DivTextVPAtivo>
                                    <TextVersaoPremium corPrincipal={this.props.coresLetraPadrao}>Versão Premium</TextVersaoPremium>
                                    <p style={{ fontSize: 20, fontWeight: 'bold', color: '#00FF7F' }}>ATIVA</p>
                                </DivTextVPAtivo>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <img src={imgProtection} alt="transacao segura" style={{ height: 40, width: 40 }} />

                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 10, marginTop: 30, marginBottom: 20 }}>
                                <p style={{ fontSize: 15, color: this.props.coresLetraPadrao }}>Todas as funcionalidades PREMIUM estão liberadas.</p>
                            </div>
                            <div style={{ display: 'flex', flex: 1, justifyContent: 'center', }}>
                                <div style={{ margin: 10, marginTop: 30 }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', flex: 1 }}>
                                            <i className="icon-check" style={{ color: '#00FF7F', fontSize: 15, padding: 5 }} />
                                            <p style={{ color: this.props.coresLetraPadrao, fontSize: 12 }}>Acesso via WEB e APP</p>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', flex: 1 }}>
                                            <i className="icon-check" style={{ color: '#00FF7F', fontSize: 15, padding: 5 }} />
                                            <p style={{ color: this.props.coresLetraPadrao, fontSize: 12 }}>Suporte via CHAT</p>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', flex: 1 }}>
                                            <i className="icon-check" style={{ color: '#00FF7F', fontSize: 15, padding: 5 }} />
                                            <p style={{ color: this.props.coresLetraPadrao, fontSize: 12 }}>Integração com WhatsApp</p>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', flex: 1 }}>
                                            <i className="icon-check" style={{ color: '#00FF7F', fontSize: 15, padding: 5 }} />
                                            <p style={{ color: this.props.coresLetraPadrao, fontSize: 12 }}>Relatórios completos</p>
                                        </div>
                                    </div>

                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', flex: 1 }}>
                                            <i className="icon-check" style={{ color: '#00FF7F', fontSize: 15, padding: 5 }} />
                                            <p style={{ color: this.props.coresLetraPadrao, fontSize: 12 }}>Upload Arquivos</p>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', flex: 1 }}>
                                            <i className="icon-check" style={{ color: '#00FF7F', fontSize: 15, padding: 5 }} />
                                            <p style={{ color: this.props.coresLetraPadrao, fontSize: 12 }}>Emitir Avisos</p>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', flex: 1 }}>
                                            <i className="icon-check" style={{ color: '#00FF7F', fontSize: 15, padding: 5 }} />
                                            <p style={{ color: this.props.coresLetraPadrao, fontSize: 12 }}>Clientes ilimitados</p>
                                        </div>

                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', flex: 1 }}>
                                            <i className="icon-check" style={{ color: '#00FF7F', fontSize: 15, padding: 5 }} />
                                            <p style={{ color: this.props.coresLetraPadrao, fontSize: 12 }}>Avaliar atendimento</p>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', flex: 1 }}>
                                            <i className="icon-check" style={{ color: '#00FF7F', fontSize: 15, padding: 5 }} />
                                            <p style={{ color: this.props.coresLetraPadrao, fontSize: 12 }}>Sem Anúncios</p>
                                        </div>

                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', flex: 1 }}>
                                            <i className="icon-check" style={{ color: '#00FF7F', fontSize: 15, padding: 5 }} />
                                            <p style={{ color: this.props.coresLetraPadrao, fontSize: 12 }}>Todas Funcionalidades</p>
                                        </div>
                                    </div>


                                </div>
                            </div>

                        </ContainerVersaoPremiumAtiva>
                    </div>
                    <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }} >

                        <div style={{ marginTop: 10, width: 600 }}>
                            <div style={{ margin: 10 }}>
                                <p style={{ fontSize: 14, color: this.props.coresLetraPadrao, fontWeight: 'bold' }}>Versão Premium Ativa:</p>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 50 }}>
                                    <div style={{ height: 12, width: 12, backgroundColor: '#00FF7F', borderRadius: 50, marginHorizontal: 10 }}>
                                    </div>
                                </div>
                                <div style={{}}>
                                    <p style={{ fontSize: 15, color: this.props.coresLetraPadrao }}>Válido até: {this.props.dataValidoAte}</p>
                                    <p style={{ fontSize: 15, color: this.props.coresLetraPadrao }}>{this.props.plano}</p>
                                    <p style={{ fontSize: 15, color: this.props.coresLetraPadrao }}>Atendentes Liberados para Cadastro: <span style={{ fontSize: 13, color: this.props.coresLetraPadrao, fontWeight: 'bold' }}>{this.props.qtdAtendentesLiberados}</span></p>
                                </div>
                            </div>
                        </div>

                    </div>
                    {this.props.mostraVersaoExpirando == true && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 20 }}>
                        <ButtonTrocarPlano backGroundDinamico={this.props.corBotaoPadrao} corLetraBotao={this.props.letraBotaoPadrao} type="button" onClick={() => { this.props.ativaDesativaRenovarPLano(true) }}>
                            {/* <i className="icon-trocarplano" style={{ color: this.props.letraBotaoPadrao, fontSize: 16, padding: 5 }} /> */}
                            RENOVAR PLANO
                        </ButtonTrocarPlano>
                    </div>}

                </ContainerAssinaturaAtiva>
            )
        }
        //nova tela de compra
        else {
            return (
                <>
                    {this.props.premiumVersaoGratis == true && this.props.premium == 1 && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingVertical: 7, backgroundColor: '#FFFFDD' }}>
                        <span style={{ color: '#000', fontSize: 14, fontWeight: 'bold' }}>Você ainda tem {this.state.DiasTerminarTesteGratis} dia(s) Premium na versão Grátis.</span>
                        <span style={{ color: '#000', fontSize: 14, fontWeight: 'bold', marginLeft: 10 }}>Quantidade de Atendentes Liberados: {this.props.qtdAtendentesLiberados}</span>
                    </div>}
                    {this.props.premiumVersaoGratis == false && this.props.premium == 0 && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingVertical: 7, backgroundColor: '#FFFFDD' }}>
                        <p style={{ color: '#000', fontSize: 14, fontWeight: 'bold' }}>Quantidade de Atendentes Liberados: {this.props.qtdAtendentesLiberados}</p>
                    </div>}
                    <CabecalhoVersaoPremium backgroundDinamico={this.props.backgroundSegundo}>
                        <TextCabecalho corPrincipal={this.props.coresLetraPadrao}>Descubra qual opção ideal para você.</TextCabecalho>
                        {/*  <ContainerDivMensalAnual backgroundDinamico={this.props.corSegundoBotaoPadrao}>
                            <DivMensal onClick={() => { this._toggleMensal(true) }} backgroundDinamico={this.state.mensal == true ? this.props.corBotaoPadrao : this.props.corSegundoBotaoPadrao}>
                                <TextMensalAnual corLetraBotao={this.props.letraBotaoPadrao} >MENSAL</TextMensalAnual>
                            </DivMensal>
                            <DivAnual onClick={() => { this._toggleMensal(false) }} backgroundDinamico={this.state.mensal == false ? this.props.corBotaoPadrao : this.props.corSegundoBotaoPadrao}>
                                <TextMensalAnual corLetraBotao={this.props.letraBotaoPadrao}>ANUAL</TextMensalAnual>
                            </DivAnual>
                        </ContainerDivMensalAnual> */}
                    </CabecalhoVersaoPremium>

                    <ContainerPlanos>

                        <ContainerVersaoPremiumAtiva backgroundDinamico={this.props.backgroundSegundo}>
                            <div style={{ display: 'flex', margin: 10, flexDirection: 'column', }}>
                                <DivTextVPAtivo>
                                    <TextAssinaVersaoPremium corPrincipal={this.props.coresLetraPadrao}>Selecione a Quantidade de Atendentes</TextAssinaVersaoPremium>
                                </DivTextVPAtivo>
                                {/* <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <img src={imgProtection} alt="transacao segura" style={{ height: 40, width: 40 }} />

                                </div> */}
                            </div>
                            {/*  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 10, marginTop: 30, marginBottom: 20 }}>
                                <p style={{ fontSize: 15, color: this.props.coresLetraPadrao }}>Todas as funcionalidades PREMIUM estão liberadas.</p>
                            </div> */}
                            <div style={{ display: 'flex', flex: 1, justifyContent: 'space-between', alignItems: 'center', flexDirection: 'column' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%', height: 50 }}>
                                    <p style={{ color: this.props.coresLetraPadrao, fontSize: 15, fontWeight: 'bold', marginRight: 10 }}>Quantidade de Atendentes: </p>
                                    {/*     <ContainerSelectSearch> */}
                                    <SelectSearch
                                        renderValue={(valueProps) =>
                                            <DivInputSearch>
                                                <InputSearchQtdAtendentes
                                                    corLetraDinamico={'#000'}
                                                    backgroundDinamico={'#FFF'}
                                                    placeholderColor={'#000'}
                                                    corBordaDinamica={'#000'}
                                                    {...valueProps} ></InputSearchQtdAtendentes>
                                                <IconeSetaPraBaixo className="icon-angle-arrow-down" colorDinamico={this.props.coresLetraPadrao} />
                                            </DivInputSearch>
                                        }
                                        value={this.state.atendenteSelecionado}
                                        options={this.state.quantidadeAtendentes}
                                        onChange={(value, option) => {
                                            this.setState({ atendenteSelecionado: value })
                                            //  this.props.modificaClienteSelecionado(value);


                                        }}
                                        placeholder={"Selecione a Quantidade"}
                                        search={false} />

                                    {/* </ContainerSelectSearch> */}
                                </div>
                                <DivPreco>
                                    {this.renderValorMensalidadeQtdAtendente()}
                                </DivPreco>
                                <div style={{}}>



                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', flex: 1 }}>
                                            <i className="icon-check" style={{ color: '#00FF7F', fontSize: 15, padding: 5 }} />
                                            <p style={{ color: this.props.coresLetraPadrao, fontSize: 12 }}>Acesso via WEB e APP</p>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', flex: 1 }}>
                                            <i className="icon-check" style={{ color: '#00FF7F', fontSize: 15, padding: 5 }} />
                                            <p style={{ color: this.props.coresLetraPadrao, fontSize: 12 }}>Suporte via CHAT</p>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', flex: 1 }}>
                                            <i className="icon-check" style={{ color: '#00FF7F', fontSize: 15, padding: 5 }} />
                                            <p style={{ color: this.props.coresLetraPadrao, fontSize: 12 }}>Integração com WhatsApp</p>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', flex: 1 }}>
                                            <i className="icon-check" style={{ color: '#00FF7F', fontSize: 15, padding: 5 }} />
                                            <p style={{ color: this.props.coresLetraPadrao, fontSize: 12 }}>Relatórios completos</p>
                                        </div>
                                    </div>

                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', flex: 1 }}>
                                            <i className="icon-check" style={{ color: '#00FF7F', fontSize: 15, padding: 5 }} />
                                            <p style={{ color: this.props.coresLetraPadrao, fontSize: 12 }}>Upload Arquivos</p>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', flex: 1 }}>
                                            <i className="icon-check" style={{ color: '#00FF7F', fontSize: 15, padding: 5 }} />
                                            <p style={{ color: this.props.coresLetraPadrao, fontSize: 12 }}>Emitir Avisos</p>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', flex: 1 }}>
                                            <i className="icon-check" style={{ color: '#00FF7F', fontSize: 15, padding: 5 }} />
                                            <p style={{ color: this.props.coresLetraPadrao, fontSize: 12 }}>Clientes ilimitados</p>
                                        </div>

                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', flex: 1 }}>
                                            <i className="icon-check" style={{ color: '#00FF7F', fontSize: 15, padding: 5 }} />
                                            <p style={{ color: this.props.coresLetraPadrao, fontSize: 12 }}>Avaliar atendimento</p>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', flex: 1 }}>
                                            <i className="icon-check" style={{ color: '#00FF7F', fontSize: 15, padding: 5 }} />
                                            <p style={{ color: this.props.coresLetraPadrao, fontSize: 12 }}>Sem Anúncios</p>
                                        </div>

                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', flex: 1 }}>
                                            <i className="icon-check" style={{ color: '#00FF7F', fontSize: 15, padding: 5 }} />
                                            <p style={{ color: this.props.coresLetraPadrao, fontSize: 12 }}>Todas Funcionalidades</p>
                                        </div>
                                    </div>


                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 5 }}>
                                <ButtonTrocarPlano backGroundDinamico={this.props.corBotaoPadrao} corLetraBotao={this.props.letraBotaoPadrao} type="button" onClick={() => {

                                    setTimeout(() => {
                                        this.toggleModalPagamento(this.state.atendenteSelecionado)

                                    }, 0);


                                }}>
                                    {/* <i className="icon-trocarplano" style={{ color: this.props.letraBotaoPadrao, fontSize: 16, padding: 5 }} /> */}
                                    ASSINAR
                                </ButtonTrocarPlano>
                            </div>
                        </ContainerVersaoPremiumAtiva>
                        {/*   <Planos style={{ marginRight: 40 }} backgroundDinamico={this.props.backgroundSegundo}>
                       
                            <DivTextPlano style={{marginTop:30}}>
                                <TextPlano corPrincipal={this.props.coresLetraPadrao}>SMART APP</TextPlano>
                                <TextSubPlano corPrincipal={this.props.coresLetraPadrao}>( Somente Licença APP )</TextSubPlano>
                            </DivTextPlano>
                            <DivIconePlano>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                    <i className="icon-nocomputer" style={{ color: '#CCC', fontSize: 40 }} />
                                    <i className="icon-close" style={{ color: 'red', fontSize: 12 }} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                    <i className="icon-phone123" style={{ color: '#CCC', fontSize: 40 }} />
                                    <i className="icon-check" style={{ color: '#00FF7F', fontSize: 12 }} />
                                </div>
                            </DivIconePlano>
                            {this.state.mensal == true && <DivPreco>
                                <TextPreco corPrincipal={this.props.coresLetraPadrao}>R$ 19,90</TextPreco>
                                <TextMes corPrincipal={this.props.coresLetraPadrao}>/mensal</TextMes>
                            </DivPreco>}
                            {this.state.mensal == false &&
                                <div style={{ display: 'flex', flexDirection: "column" }}>
                                    <DivPrecoAnual>
                                        <TextPreco corPrincipal={this.props.coresLetraPadrao}>R$ 199,90</TextPreco>
                                        <TextMesEconomia corPrincipal={this.props.coresLetraPadrao}>/anual</TextMesEconomia>
                                    </DivPrecoAnual>
                                    <DivEconomia>
                                        <p style={{ color: 'red' }}>Economia 2 mês</p>
                                    </DivEconomia>
                                </div>}
                            <DivContainerOpcoes>
                                <div style={{ display: 'flex', flexDirection: 'row', height: 40, alignItems: 'center', marginLeft: 15 }}>
                                    <i className="icon-close" style={{ color: '#9fadb7', fontSize: 14, marginLeft: 4 }} />
                                    <p style={{ color: '#9fadb7', fontSize: 16, textDecoration: 'line-through', marginLeft: 4 }}>Integração Com WhatsApp</p>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', height: 40, alignItems: 'center', marginLeft: 15 }}>
                                    <i className="icon-close" style={{ color: '#9fadb7', fontSize: 14, marginLeft: 4 }} />
                                    <p style={{ color: '#9fadb7', fontSize: 16, textDecoration: 'line-through', marginLeft: 4 }}>Acesso via Web</p>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', height: 40, alignItems: 'center', marginLeft: 15 }}>
                                    <i className="icon-check" style={{ color: '#00FF7F', fontSize: 18 }} />
                                    <p style={{ color: this.props.coresLetraPadrao, fontSize: 16 }}>Acesso via App</p>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', height: 40, alignItems: 'center', marginLeft: 15 }}>
                                    <i className="icon-check" style={{ color: '#00FF7F', fontSize: 18 }} />
                                    <p style={{ color: this.props.coresLetraPadrao, fontSize: 16 }}>Atendentes <span style={{ color: this.props.coresLetraPadrao, fontSize: 16, fontWeight: 'bold' }}>ilimitados</span></p>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', height: 40, alignItems: 'center', marginLeft: 15 }}>
                                    <i className="icon-check" style={{ color: '#00FF7F', fontSize: 18 }} />
                                    <p style={{ color: this.props.coresLetraPadrao, fontSize: 16 }}>Clientes <span style={{ color: this.props.coresLetraPadrao, fontSize: 16, fontWeight: 'bold' }}>ilimitados</span></p>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', height: 40, alignItems: 'center', marginLeft: 15 }}>
                                    <i className="icon-check" style={{ color: '#00FF7F', fontSize: 18 }} />
                                    <p style={{ color: this.props.coresLetraPadrao, fontSize: 16 }}>Emitir Avisos</p>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', height: 40, alignItems: 'center', marginLeft: 15 }}>
                                    <i className="icon-check" style={{ color: '#00FF7F', fontSize: 18 }} />
                                    <p style={{ color: this.props.coresLetraPadrao, fontSize: 16 }}>Upload arquivos</p>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', height: 40, alignItems: 'center', marginLeft: 15 }}>
                                    <i className="icon-check" style={{ color: '#00FF7F', fontSize: 18 }} />
                                    <p style={{ color: this.props.coresLetraPadrao, fontSize: 16 }}>Avaliação dos atendimentos</p>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', height: 40, alignItems: 'center', marginLeft: 15 }}>
                                    <i className="icon-check" style={{ color: '#00FF7F', fontSize: 18 }} />
                                    <p style={{ color: this.props.coresLetraPadrao, fontSize: 16 }}>Relatórios Completos</p>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', height: 40, alignItems: 'center', marginLeft: 15 }}>
                                    <i className="icon-check" style={{ color: '#00FF7F', fontSize: 18 }} />
                                    <p style={{ color: this.props.coresLetraPadrao, fontSize: 16 }}>Suporte por Chat</p>
                                </div>
                            </DivContainerOpcoes>
                          
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <ButtonAssinar backGroundDinamico={this.props.corBotaoPadrao} corLetraBotao={this.props.letraBotaoPadrao} type="button" onClick={() => {
                                    this.toggleModalPagamento('app')
                                    // this.criarAssinatura()
                                }}>
                                    Assinar
                   </ButtonAssinar>
                            </div>
                           
                        </Planos> */}



                        {/*   <Planos backgroundDinamico={this.props.backgroundSegundo}>
                            <DivRecomendadoContainer>
                                RECOMENDADO
                            </DivRecomendadoContainer>
                            <DivTextPlano>
                                <TextPlano corPrincipal={this.props.coresLetraPadrao}>ESSENCIAL</TextPlano>
                                <TextSubPlano corPrincipal={this.props.coresLetraPadrao}>(Licença WEB + APP )</TextSubPlano>
                            </DivTextPlano>
                            <DivIconePlano>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                    <i className="icon-computerlicense" style={{ color: '#CCC', fontSize: 40 }} />
                                    <i className="icon-check" style={{ color: '#00FF7F', fontSize: 12 }} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                    <i className="icon-phone123" style={{ color: '#CCC', fontSize: 40 }} />
                                    <i className="icon-check" style={{ color: '#00FF7F', fontSize: 12 }} />
                                </div>
                            </DivIconePlano>
                            {this.state.mensal == true && <DivPreco>
                                <TextPreco corPrincipal={this.props.coresLetraPadrao}>R$ 49,90</TextPreco>
                                <TextMes corPrincipal={this.props.coresLetraPadrao}>/mensal</TextMes>
                            </DivPreco>}
                            {this.state.mensal == false &&
                                <div style={{ display: 'flex', flexDirection: "column" }}>
                                    <DivPrecoAnual>
                                        <TextPreco corPrincipal={this.props.coresLetraPadrao}>R$ 548,90</TextPreco>
                                        <TextMesEconomia corPrincipal={this.props.coresLetraPadrao}>/anual</TextMesEconomia>
                                    </DivPrecoAnual>
                                    <DivEconomia>
                                        <p style={{ color: 'red' }}>Economia 1 mês</p>
                                    </DivEconomia>
                                </div>}
                            <DivContainerOpcoes>
                                <div style={{ display: 'flex', flexDirection: 'row', height: 40, alignItems: 'center', marginLeft: 15 }}>
                                    <i className="icon-check" style={{ color: '#00FF7F', fontSize: 18 }} />
                                    <p style={{ color: this.props.coresLetraPadrao, fontSize: 16 }}>Integração Com WhatsApp</p>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', height: 40, alignItems: 'center', marginLeft: 15 }}>
                                    <i className="icon-check" style={{ color: '#00FF7F', fontSize: 18 }} />
                                    <p style={{ color: this.props.coresLetraPadrao, fontSize: 16 }}>Acesso via Web</p>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', height: 40, alignItems: 'center', marginLeft: 15 }}>
                                    <i className="icon-check" style={{ color: '#00FF7F', fontSize: 18 }} />
                                    <p style={{ color: this.props.coresLetraPadrao, fontSize: 16 }}>Acesso via App</p>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', height: 40, alignItems: 'center', marginLeft: 15 }}>
                                    <i className="icon-check" style={{ color: '#00FF7F', fontSize: 18 }} />
                                    <p style={{ color: this.props.coresLetraPadrao, fontSize: 16 }}>Atendentes <span style={{ color: this.props.coresLetraPadrao, fontSize: 16, fontWeight: 'bold' }}>ilimitados</span></p>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', height: 40, alignItems: 'center', marginLeft: 15 }}>
                                    <i className="icon-check" style={{ color: '#00FF7F', fontSize: 18 }} />
                                    <p style={{ color: this.props.coresLetraPadrao, fontSize: 16 }}>Clientes <span style={{ color: this.props.coresLetraPadrao, fontSize: 16, fontWeight: 'bold' }}>ilimitados</span></p>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', height: 40, alignItems: 'center', marginLeft: 15 }}>
                                    <i className="icon-check" style={{ color: '#00FF7F', fontSize: 18 }} />
                                    <p style={{ color: this.props.coresLetraPadrao, fontSize: 16 }}>Emitir Avisos</p>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', height: 40, alignItems: 'center', marginLeft: 15 }}>
                                    <i className="icon-check" style={{ color: '#00FF7F', fontSize: 18 }} />
                                    <p style={{ color: this.props.coresLetraPadrao, fontSize: 16 }}>Upload arquivos</p>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', height: 40, alignItems: 'center', marginLeft: 15 }}>
                                    <i className="icon-check" style={{ color: '#00FF7F', fontSize: 18 }} />
                                    <p style={{ color: this.props.coresLetraPadrao, fontSize: 16 }}>Avaliação dos atendimentos</p>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', height: 40, alignItems: 'center', marginLeft: 15 }}>
                                    <i className="icon-check" style={{ color: '#00FF7F', fontSize: 18 }} />
                                    <p style={{ color: this.props.coresLetraPadrao, fontSize: 16 }}>Relatórios Completos</p>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', height: 40, alignItems: 'center', marginLeft: 15 }}>
                                    <i className="icon-check" style={{ color: '#00FF7F', fontSize: 18 }} />
                                    <p style={{ color: this.props.coresLetraPadrao, fontSize: 16 }}>Suporte por Chat</p>
                                </div>
                            </DivContainerOpcoes>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <ButtonAssinar backGroundDinamico={this.props.corBotaoPadrao} corLetraBotao={this.props.letraBotaoPadrao} type="button" onClick={() => { this.toggleModalPagamento('appweb') }}>
                                    Assinar
                   </ButtonAssinar>
                            </div>
                        </Planos> */}

                    </ContainerPlanos>
                </>
            )
        }
    }
    _toggleModalVersaoPremiumSucesso() {
        this.setState({ modalVersaoPremiumAtivaSucesso: !this.state.modalVersaoPremiumAtivaSucesso });
    }
    _toggleModalCancelarAssinatura() {
        this.setState({ modalCancelarAssinatura: !this.state.modalCancelarAssinatura });
    }
    s2ab(s) {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i !== s.length; ++i) {
            view[i] = s.charCodeAt(i) & 0xff;
        }
        return buf;
    }
    downloadUsuariosCadastradosExcel(colunas, linhas) {
        //
        //pegando data e hora para colocar no nome do arquivo para que nao haja repeticao de nome
        let dataAtual = moment().toDate();
        dataAtual = moment(dataAtual).format("DD-MM-YYYY HH-mm-ss");
        // 

        //caminho do arquivo pasta download + nome com a data e hora

        //colunas do excel, recebe array com colunas
        //let users = [["First Name", "Last Name", "Age"]]
        let users = [colunas]
        //
        // 
        //se colunas.length for igual a 3 é porque é clientes, se nao for eh setor ou atendente

        linhas.forEach((usuario) => {
            let userArray = [
                usuario
            ]
            users.push(userArray)
        })


        const wb = XLSX.utils.book_new()
        //

        const wsAll = XLSX.utils.aoa_to_sheet(users)
        XLSX.utils.book_append_sheet(wb, wsAll, "All Users")
        const wbout = XLSX.write(wb, { type: 'binary', bookType: "xlsx" });
        const url = window.URL.createObjectURL(new Blob([this.s2ab(wbout)], { type: 'application/octet-stream' }));
        this.download(url, `relatorio${dataAtual}.xlsx`);
    }
    criarAssinatura() {
        //const url = `https://us-central1-dark-4b307.cloudfunctions.net/criarPlano`;

        //   const url = `https://us-central1-dark-4b307.cloudfunctions.net/iapIniciaCompra`;
        //   const url = `https://us-central1-dark-4b307.cloudfunctions.net/criarPlano`;
        //  const url = `https://us-central1-dark-4b307.cloudfunctions.net/importarNovosUsuarios`;
        /*    const url = `https://us-central1-dark-4b307.cloudfunctions.net/listarTodosUsuarios`; */
        // const url = `https://us-central1-dark-4b307.cloudfunctions.net/consultaStatusAssinatura`;
        /* let data = {
            idSubscription: 'sub_510BB9BE371A46E0',
        } */
        const data = {
            nomePlano: 'Plano Mensal ROBUSTO R$ 999,90',
            valorPlano: 999.90
        }
        //  const data2 = JSON.stringify(data);
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'Accept': 'application/json',
            },

            data: data,
            url: 'criarChaveAleatoriaPixProd',
        };
        api(options).then((response) => {


            // this.downloadUsuariosCadastradosExcel(['email'], response.data)
        }).catch((err) => {
            // 
            //
            if (err.response) {
                /*
                 * The request was made and the server responded with a
                 * status code that falls out of the range of 2xx
                 */




            } else {

            }
        })

    }
    geraTokenPagSeguro() {
        //id plano assinatura juno: pln_ED1DB8B0D31340A3
        //
        const email = 'dropdesk@dropdesk.com.br';
        const token = '1DE0D121FFD549349AF8A425889E028E';
        // const url = `https://us-central1-dark-4b307.cloudfunctions.net/iapIniciaCompra`;
        const data = {
            nomePlano: "DropDesk",
            testeSenha: "TESTESENHA2"
        }
        const data2 = JSON.stringify(data);
        // 
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'Accept': 'application/json',
            },

            data: data2,
            url: 'criarAssinatura',
        };
        api(options).then(function (response) {
            // 
        }).catch((err) => {
            //  
            //
        })
    }
    formatarCampo(campoTexto) {
        //  this.setState({ bordaCpf: this.state.cpf.trim() == '' ? 'red' : '#FFF' })

        if (campoTexto.value.length <= 11) {
            campoTexto.value = this.mascaraCpf(campoTexto.value);

        } else {
            campoTexto.value = this.mascaraCnpj(campoTexto.value);

        }
    }
    formatarCampoCEP(campoTexto) {
        const cep = this.mascaraCEP(campoTexto.value);
        campoTexto.value = cep;

        if (cep) { this.getCEP(this.retiraFormatacaoCEP(cep)) }
        else {
            this.setState({ bordaCEP: 'red' })
        }
    }
    retiraFormatacaoCEP(cep) {
        if (cep) {
            return cep.replace(/(\.|\/|\-)/g, "");
        } else {
            return "";
        }
    }
    async getCEP(cep) {
        if (!cep) {
            this.setState({ bordaCEP: 'red' })
            alert('Informe o CEP para buscar')
        } else {
            cep = this.retiraFormatacaoCEP(cep);

            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                    'Accept': 'application/json',
                },

                data: { cep: cep },
                url: `getCEP`,
            };
            try {
                this.setState({ loadingCep: true });
                const response = await api(options);
                const dados = response.data;
                if (dados.erro && dados.erro === 'true') {
                    this.setState({ bordaCEP: 'red', loadingCep: false });
                } else {
                    this.setState({ cidade: dados.localidade, ibge: dados.ibge, endereco: dados.logradouro, bordaCEP: '#FFF', uf: dados.uf, loadingCep: false });
                }
            } catch (error) {
                this.setState({ bordaCEP: 'red', loadingCep: false });

                alert('Erro ao buscar CEP, verifique o CEP informado e tente novamente')
            }


        }
    }
    async validCEP(cep) {
        if (!cep) {
            return false;
        } else {
            cep = this.retiraFormatacaoCEP(cep);

            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                    'Accept': 'application/json',
                },

                data: { cep: cep },
                url: `getCEP`,
            };
            try {
                const response = await api(options);
                const dados = response.data;

                if (dados.erro && dados.erro === 'true') {
                    return false;
                } else {
                    return true;
                }
            } catch (error) {
                return false;
            }

        }
    }
    retirarFormatacao(campoTexto) {
        campoTexto.value = campoTexto.value.replace(/(\.|\/|\-)/g, "");
        //this.setState({ bordaCpf: '#00FF7F' });
    }
    mascaraCpf(valor) {
        return valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3\-\$4");
    }
    mascaraCnpj(valor) {
        return valor.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3\/\$4\-\$5");
    }
    mascaraCEP(valor) {
        return valor.replace(/(\d{2})(\d{3})(\d{3})/g, "\$1.\$2\-\$3");
    }
    onBlurNumeroCartao() {
        if (this.state.number.trim() == '') {
            this.setState({ bordaNumeroCartao: 'red' })
        } else {
            this.setState({ bordaNumeroCartao: '#FFF' })
        }
    }
    download(url, name) {
        const a = document.createElement('a');
        a.href = url;
        a.download = name;
        a.click();
        window.URL.revokeObjectURL(url);

    };
    onBlurNomeCartao() {
        if (this.state.name.trim() == '') {
            this.setState({ bordaNomeCartao: 'red' })
        } else {
            this.setState({ bordaNomeCartao: '#FFF' })
        }
    }
    onBlurValidadeCartao() {
        if (this.state.expiry.trim() == '') {
            this.setState({ bordaValidadeCartao: 'red' })
        } else {
            this.setState({ bordaValidadeCartao: '#FFF' })
        }
    }
    onBlurCvcCartao() {
        if (this.state.cvc.trim() == '') {
            this.setState({ bordaCvcCartao: 'red' })
        } else {
            this.setState({ bordaCvcCartao: '#FFF' })
        }
    }
    async finalizaPagamentoCartao(e) {
        e.preventDefault();
        this.handleLoadingBotaoFinalizar(true);
        let cpfValido = cpf.isValid(this.state.cpf);
        let cnpjValido = cnpj.isValid(this.state.cpf);
        let emailValido = this.validEmail(this.state.email.trim())
        let cepValido = await this.validCEP(this.state.cep);

        if (this.state.number.trim().length == 0) {
            toastr.error('Error', 'Informe o número do cartão de crédito')
            this.handleLoadingBotaoFinalizar(false);
        } else if (this.state.name.trim().length == 0) {
            toastr.error('Error', 'Informe o nome do titular do cartão de crédito')
            this.handleLoadingBotaoFinalizar(false);
        }
        else if (this.state.name.trim().length >= 25) {
            toastr.error('Error', 'O nome do titular do cartão deve possuir menos de 25 digitos')
            this.handleLoadingBotaoFinalizar(false);
        }
        else if (this.state.expiry.trim().includes('A') || this.state.expiry.trim().includes('M') || this.state.expiry.trim().length == 0) {
            toastr.error('Error', 'Informe a validade do cartão de crédito')
            this.handleLoadingBotaoFinalizar(false);
        }
        else if (this.state.cvc.trim().length == 0) {
            toastr.error('Error', 'Informe o código de segurança do cartão de crédito')
            this.handleLoadingBotaoFinalizar(false);
        }

        else if (this.state.cpf.trim().length == 0) {
            toastr.error('Error', 'Informe um CPF ou CNPJ')
            this.handleLoadingBotaoFinalizar(false);
        }
        else if (this.state.cpf.trim().length <= 11 && cpfValido == false) {
            toastr.error('Error', 'CPF INVÁLIDO, informe um CPF válido')
            this.handleLoadingBotaoFinalizar(false);
        }
        else if (this.state.cpf.trim().length > 11 && cnpjValido == false) {
            toastr.error('Error', 'CNPJ INVÁLIDO, informe um CNPJ válido')
            this.handleLoadingBotaoFinalizar(false);
        } else if (this.state.email.trim().length == 0) {
            toastr.error('Error', 'Informe um email')
            this.handleLoadingBotaoFinalizar(false);
        } else if (emailValido == false) {
            toastr.error('Error', 'EMAIL INVÁLIDO, informe um EMAIL válido')
            this.handleLoadingBotaoFinalizar(false);
        }
        else if (this.state.endereco.trim().length == 0) {
            toastr.error('Error', 'Informe um endereço (Rua)')
            this.handleLoadingBotaoFinalizar(false);
        }
        else if (this.state.numero.trim().length == 0) {
            toastr.error('Error', 'Informe um número')
            this.handleLoadingBotaoFinalizar(false);
        }

        else if (this.state.ibge.trim().length == 0) {
            toastr.error('Error', 'Informe o CEP corretamente')
            this.handleLoadingBotaoFinalizar(false);
        }
        else if (this.state.cep.trim().length == 0) {
            toastr.error('Error', 'Informe um CEP')
            this.handleLoadingBotaoFinalizar(false);
        }
        else if (cepValido === false) {
            toastr.error('Error', 'CEP INVÁLIDO, informe um CEP válido')
            this.handleLoadingBotaoFinalizar(false);
        }
        else if (this.state.telephone.trim().length <= 10) {
            toastr.error('Error', 'TELEFONE INVÁLIDO, informe um telefone válido, por favor verifique.')
            this.handleLoadingBotaoFinalizar(false);
        }
        else {


            AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
                const chaveUnica = chaveUnica2.replace(/"/g, "");
                if (!chaveUnica) {
                    toastr.error('Error', 'Ocorreu um error, entre em contato com o suporte DropDesk, telefone: 55 21 97882-0515');
                    this.handleLoadingBotaoFinalizar(false);
                } else {
                    let amount = 0;
                    if (this.state.atendenteSelecionado == '10') {
                        amount = 99.90
                    } else if (this.state.atendenteSelecionado == '15') {
                        amount = 199.90
                    } else if (this.state.atendenteSelecionado == '20') {
                        amount = 299.90
                    }
                    else if (this.state.atendenteSelecionado == '30') {
                        amount = 399.90
                    }
                    else if (this.state.atendenteSelecionado == '40') {
                        amount = 499.90
                    }
                    else if (this.state.atendenteSelecionado == '50') {
                        amount = 599.90
                    }
                    else if (this.state.atendenteSelecionado == '80') {
                        amount = 799.90
                    }
                    else if (this.state.atendenteSelecionado == '100') {
                        amount = 899.90
                    }
                    else if (this.state.atendenteSelecionado == '60') {
                        amount = 699.90
                    }
                    else if (this.state.atendenteSelecionado == '120') {
                        amount = 999.90
                    } else {
                        amount = 49.90
                    }
                    let arrayValidade = this.state.expiry.split("/")
                    let dataAtual = moment().toDate();
                    dataAtual = parseInt(moment(dataAtual).format('D'));

                    let data = {
                        description: this.state.mensagemPlanoSelecionado,
                        amount: amount,
                        holderName: this.state.name.toUpperCase(),
                        cardNumber: this.state.number,
                        securityCode: this.state.cvc,
                        expirationMonth: arrayValidade[0],
                        expirationYear: arrayValidade[1],
                        cpf: this.state.cpf,
                        email: this.state.email.trim(),
                        street: this.state.endereco,
                        number: this.state.numero,
                        telephone: this.state.telephone,
                        city: this.state.cidade,
                        postCode: this.state.cep,
                        dataAtual,
                        tipoPagamento: 'cartao',
                        codeIBGE: this.state.ibge,
                        uf: this.state.uf,
                        chaveUnica,
                        nomeAssinante: this.props.nome,
                        plataforma: "web",
                        plano: this.state.mensagemPlanoSelecionado
                    }

                    const options = {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json; charset=utf-8',
                            'Accept': 'application/json',
                        },

                        data: data,
                        url: 'pushNewPayS2p',
                    };
                    api(options).then((response) => {
                        const dados = response.data;
                        if (dados.HasError && dados.HasError === true) {
                            this.handleLoadingBotaoFinalizar(false)
                            toastr.error(`${dados.Error}`);
                        } else {


                            this.handleLoadingBotaoFinalizar(false);
                            this.setState({ modalPagamento: false });
                            this.props.ativaDesativaRenovarPLano(false);
                            //this.props.inserePlanoPremium(this.state.atendenteSelecionado, dados.data.idSubscription, this.state.mensagemPlanoSelecionado, this.state.email.trim(), this.state.name, amount, this.state.ibge);
                            this._toggleModalVersaoPremiumSucesso();
                        }

                    }).catch((err) => {


                        const messageError = err.response.data;

                        if (err && err.response && messageError.Errors) {
                            messageError.Errors.map((entry) => {
                                toastr.error(entry.Message)
                            })
                            this.handleLoadingBotaoFinalizar(false)
                        } else {
                            // Something happened in setting up the request and triggered an Error
                            toastr.error('Error', 'Verifique seus dados e tente novamente');
                            this.handleLoadingBotaoFinalizar(false)
                        }
                    })
                }
            })
        }

    }
    /*  removeSpecialChars(text, removeSpaces = false) {
        if (removeSpaces) return text.replace(/[^\d]+/g, '');
        return text.replace(new RegExp(/[^\w\s]/gi), '');
      } */
    finalizaPagamentoBoleto() {
        let cpfValido = cpf.isValid(this.state.cpf);
        let cnpjValido = cnpj.isValid(this.state.cpf);
        let emailValido = this.validEmail(this.state.email.trim())

        if (this.state.nomeBoleto.trim().length == 0) {
            toastr.error('Error', 'Informe seu nome')
        } else if (this.state.cpf.trim().length == 0) {
            toastr.error('Error', 'Informe um CPF ou CNPJ')
        }
        else if (this.state.cpf.trim().length <= 11 && cpfValido == false) {
            toastr.error('Error', 'CPF INVÁLIDO, informe um CPF válido')
        }
        else if (this.state.cpf.trim().length > 11 && cnpjValido == false) {
            toastr.error('Error', 'CNPJ INVÁLIDO, informe um CNPJ válido')
        }
        else if (this.state.endereco.trim().length == 0) {
            toastr.error('Error', 'Informe um endereço (Rua)')
        }
        else if (this.state.numero.trim().length == 0) {
            toastr.error('Error', 'Informe um número')
        }
        else if (this.state.cidade.trim().length == 0) {
            toastr.error('Error', 'Informe uma cidade')
        }
        else if (this.state.email.trim().length == 0) {
            toastr.error('Error', 'Informe um email')
        } else if (emailValido == false) {
            toastr.error('Error', 'EMAIL INVÁLIDO, informe um EMAIL válido')
        }
        else if (this.state.cep.trim().length == 0) {
            toastr.error('Error', 'Informe um CEP')
        }
        else if (this.state.cep.trim().length < 8) {
            toastr.error('Error', 'CEP INVÁLIDO, informe um CEP válido')
        } else {
            this.handleLoadingBotaoFinalizar(true);
            AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
                const chaveUnica = chaveUnica2.replace(/"/g, "");
                if (!chaveUnica) {
                    toastr.error('Error', 'Ocorreu um error, entre em contato com o suporte DropDesk, telefone: 55 21 97882-0515');
                    this.handleLoadingBotaoFinalizar(false);
                } else {


                    let amount = 0;
                    if (this.state.atendenteSelecionado == '10') {
                        amount = 99.90
                    } else if (this.state.atendenteSelecionado == '15') {
                        amount = 199.90
                    } else if (this.state.atendenteSelecionado == '20') {
                        amount = 299.90
                    } else if (this.state.atendenteSelecionado == '60') {
                        amount = 699.90
                    }
                    else if (this.state.atendenteSelecionado == '30') {
                        amount = 399.90
                    }
                    else if (this.state.atendenteSelecionado == '40') {
                        amount = 499.90
                    }
                    else if (this.state.atendenteSelecionado == '50') {
                        amount = 599.90
                    }
                    else if (this.state.atendenteSelecionado == '80') {
                        amount = 799.90
                    }
                    else if (this.state.atendenteSelecionado == '100') {
                        amount = 899.90
                    }
                    else if (this.state.atendenteSelecionado == '120') {
                        amount = 999.90
                    } else {
                        amount = 49.90
                    }

                    let data = {
                        description: this.state.mensagemPlanoSelecionado,
                        // amount: 0.10,
                        amount: amount,
                        name: this.state.nomeBoleto.toUpperCase(),
                        cpf: this.state.cpf,
                        email: this.state.email.trim(),
                        street: this.state.endereco,
                        number: this.state.numero,
                        city: this.state.cidade,
                        postCode: this.state.cep,
                        tipoPagamento: 'boleto',
                        uf: this.state.uf,
                        chaveUnica,
                        nomeAssinante: this.props.nome,
                        plataforma: "web",
                        plano: this.state.mensagemPlanoSelecionado
                    }
                    const options = {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json; charset=utf-8',
                            'Accept': 'application/json',
                        },

                        data: data,
                        url: 'pushNewPayS2p',
                    };
                    api(options).then((response) => {

                        const dados = response.data;
                        if (dados.HasError && dados.HasError === true) {
                            this.setState({
                                loadingBotaoFinalizar: false,
                            })
                            toastr.error(`${dados.Error}`)
                        } else {
                            // this.props.inserePlanoPremiumBoleto(this.state.atendenteSelecionado, dados.ResponseDetail.IdTransaction, this.state.mensagemPlanoSelecionado, this.props.renovarPlano, this.props.dataValidoAte, this.state.email.trim(), this.state.nomeBoleto, amount, this.state.ibge)
                            this.setState({ telaDownloadBoleto: true, linkBoleto: dados.ResponseDetail.BankSlipUrl });
                            this.handleLoadingBotaoFinalizar(false)
                            this.props.ativaDesativaRenovarPLano(false);
                        }

                    }).catch((err) => {
                        //  console.log(err.response.data)
                        const messageError = err.response.data.Error;
                        this.setState({
                            gerandoQRCODEPIX: false,
                            pixCopiaCola: '',
                            loadingBotaoFinalizar: false,
                        })
                        if (err && err.response) {
                            toastr.error(messageError)
                            this.handleLoadingBotaoFinalizar(false)
                        } else {
                            // Something happened in setting up the request and triggered an Error
                            toastr.error('Erro ao gerar boleto, Verique seus dados e tente novamente.')
                            this.handleLoadingBotaoFinalizar(false);
                        }
                    })
                }
            })
        }
    }

    finalizaPagamentoPix() {
        let cpfValido = cpf.isValid(this.state.cpf);
        let cnpjValido = cnpj.isValid(this.state.cpf);
        let emailValido = this.validEmail(this.state.email.trim())

        if (this.state.nomeBoleto.trim().length == 0) {
            toastr.error('Error', 'Informe seu nome')
        } else if (this.state.cpf.trim().length == 0) {
            toastr.error('Error', 'Informe um CPF ou CNPJ')
        }
        else if (this.state.cpf.trim().length <= 11 && cpfValido == false) {
            toastr.error('Error', 'CPF INVÁLIDO, informe um CPF válido')
        }
        else if (this.state.cpf.trim().length > 11 && cnpjValido == false) {
            toastr.error('Error', 'CNPJ INVÁLIDO, informe um CNPJ válido')
        }
        else if (this.state.endereco.trim().length == 0) {
            toastr.error('Error', 'Informe um endereço (Rua)')
        }
        else if (this.state.numero.trim().length == 0) {
            toastr.error('Error', 'Informe um número')
        }
        else if (this.state.cidade.trim().length == 0) {
            toastr.error('Error', 'Informe uma cidade')
        }
        else if (this.state.email.trim().length == 0) {
            toastr.error('Error', 'Informe um email')
        } else if (emailValido == false) {
            toastr.error('Error', 'EMAIL INVÁLIDO, informe um EMAIL válido')
        }
        else if (this.state.cep.trim().length == 0) {
            toastr.error('Error', 'Informe um CEP')
        }
        else if (this.state.cep.trim().length < 8) {
            toastr.error('Error', 'CEP INVÁLIDO, informe um CEP válido')
        } else {
            this.setState({
                qrCodePix: '',
                gerandoQRCODEPIX: true,
                pixCopiaCola: '',
                loadingBotaoFinalizar: true,
            })
            AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
                const chaveUnica = chaveUnica2.replace(/"/g, "");
                if (!chaveUnica) {
                    toastr.error('Error', 'Ocorreu um error, entre em contato com o suporte DropDesk, telefone: 55 21 97882-0515');
                    this.setState({
                        qrCodePix: 'error',
                        gerandoQRCODEPIX: false,
                        pixCopiaCola: '',
                        loadingBotaoFinalizar: false,
                    })
                } else {
                    let amount = 0;
                    if (this.state.atendenteSelecionado == '10') {
                        amount = 99.90
                    } else if (this.state.atendenteSelecionado == '15') {
                        amount = 199.90
                    } else if (this.state.atendenteSelecionado == '20') {
                        amount = 299.90
                    } else if (this.state.atendenteSelecionado == '60') {
                        amount = 699.90
                    }
                    else if (this.state.atendenteSelecionado == '30') {
                        amount = 399.90
                    }
                    else if (this.state.atendenteSelecionado == '40') {
                        amount = 499.90
                    }
                    else if (this.state.atendenteSelecionado == '50') {
                        amount = 599.90
                    }
                    else if (this.state.atendenteSelecionado == '80') {
                        amount = 799.90
                    }
                    else if (this.state.atendenteSelecionado == '100') {
                        amount = 899.90
                    }
                    else if (this.state.atendenteSelecionado == '120') {
                        amount = 999.90
                    } else {
                        amount = 49.90
                    }
                    let data = {
                        description: this.state.mensagemPlanoSelecionado,
                        amount: amount,
                        // amount: 0.10,
                        name: this.state.nomeBoleto.toUpperCase(),
                        cpf: this.state.cpf,
                        email: this.state.email.trim(),
                        street: this.state.endereco,
                        number: this.state.numero,
                        city: this.state.cidade,
                        postCode: this.state.cep,
                        tipoPagamento: 'pix',
                        uf: this.state.uf,
                        chaveUnica,
                        nomeAssinante: this.props.nome,
                        plataforma: "web",
                        plano: this.state.mensagemPlanoSelecionado
                    }

                    const options = {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json; charset=utf-8',
                            'Accept': 'application/json',
                        },

                        data: data,
                        url: 'pushNewPayS2p',
                    };
                    api(options).then((response) => {

                        const dados = response.data;
                        if (dados.HasError && dados.HasError === true) {
                            this.setState({
                                loadingBotaoFinalizar: false,
                            })
                            toastr.error(`${dados.Error}`)
                        } else {
                            // this.props.inserePlanoPremiumPix(this.state.atendenteSelecionado, dados.ResponseDetail.IdTransaction, this.state.mensagemPlanoSelecionado, this.props.renovarPlano, this.props.dataValidoAte, this.props.email, this.props.nome, amount, this.state.ibge)
                            this.setState({
                                qrCodePix: dados.ResponseDetail.QrCode,
                                gerandoQRCODEPIX: false,
                                pixCopiaCola: dados.ResponseDetail.Key,
                                telaLeituraPixQrCode: true,
                                loadingBotaoFinalizar: false,
                            })
                            this.props.ativaDesativaRenovarPLano(false);
                        }
                    }).catch((err) => {
                        const messageError = err.response.data.Error;
                        this.setState({
                            qrCodePix: 'error',
                            gerandoQRCODEPIX: false,
                            pixCopiaCola: '',
                            loadingBotaoFinalizar: false,
                        })
                        if (err && err.response) {
                            toastr.error(messageError)
                            this.handleLoadingBotaoFinalizar(false)
                        } else {
                            // Something happened in setting up the request and triggered an Error
                            toastr.error('Erro ao gerar QR CODE PIX, tente novamente.')
                            this.handleLoadingBotaoFinalizar(false)
                        }

                    })
                }
            })
        }
    }



    cancelarAssinatura() {
        if (this.props.motivoCancelamento.trim().length <= 15) {
            toastr.error('Error', 'Informe um MOTIVO de CANCELAMENTO com mais de 15 letras')
        } else {
            this._toggleLoadingModalCancelar(true)
            AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
                const chaveUnica = chaveUnica2.replace(/"/g, "");
                firebase.database().ref(`${chaveUnica}/empresa`).once("value").then((snapshot) => {
                    let keyEmpresa = '';
                    let newVersionIapBusiness = false;
                    snapshot.forEach(childItem => {
                        keyEmpresa = childItem.key;
                        newVersionIapBusiness = childItem.val().newVersionIapBusiness === undefined ? false : childItem.val().newVersionIapBusiness;
                    })
                    this.props.insereMotivoCancelamentoBanco(this.props.motivoCancelamento.trim())

                    let data = {
                        idSubscription: this.props.identificadorUnicoCompra,
                        newVersionIapBusiness: newVersionIapBusiness,
                        chaveUnica,
                        motivoCancelamento: this.props.motivoCancelamento
                    }
                    const options = {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json; charset=utf-8',
                            'Accept': 'application/json',
                        },
                        data: data,
                        url: 'cancelarAssinatura',
                    };
                    api(options).then((response) => {
                        toastr.success('Sucesso', 'Cancelamento efetuado com sucesso!');
                        this._toggleLoadingModalCancelar(false);
                        this._toggleModalCancelarAssinatura();
                        this.props.modificaMotivoCancelamentoTelaVersaoPremium('')
                    }).catch((err) => {
                        /*  
                           */
                        if (err.response) {
                            /*
                             * The request was made and the server responded with a
                             * status code that falls out of the range of 2xx
                             */
                            const messageError = err.response.data;
                            if (err.response.data[0] && err.response.data[0].field) {
                                if (err.response.data[0].field.includes('billing.name')) {
                                    toastr.error('Error', 'Digite um nome válido')
                                }
                                else if (err.response.data[0].field.includes('billing.email')) {
                                    toastr.error('Error', 'Digite um email válido')
                                } else if (err.response.data[0].field.includes('billing.document')) {
                                    toastr.error('Error', 'Digite um CPF/CNPJ válido')
                                } else {
                                    toastr.error('Error', err.response.data[0].message)
                                }
                            } else if (err && err.response && messageError.Errors) {
                                messageError.Errors.map((entry) => {
                                    toastr.error(entry.Message)
                                })
                                this._toggleLoadingModalCancelar(false)
                            } else {
                                toastr.error('Error', err.response.data[0].message)
                            }
                            this._toggleLoadingModalCancelar(false)
                        }

                        else {
                            // Something happened in setting up the request and triggered an Error
                            toastr.error('Error', err);
                            this._toggleLoadingModalCancelar(false)
                        }
                        //toastr.error('Error', err);
                    })
                })
            })

        }
    }

    onFocusEmail() {
        this.setState({ bordaEmail: '#00FF7F' });
    }
    onBlurEmail() {
        if (this.state.email.trim() == '') {
            this.setState({ bordaEmail: 'red' })
        } else {
            this.setState({ bordaEmail: '#FFF' })
        }
    }

    onFocusTelephone() {
        this.setState({ bordaTelephone: '#00FF7F' });
    }
    onBlurTelephone() {
        if (this.state.telephone.trim() == '') {
            this.setState({ bordaTelephone: 'red' })
        } else {
            this.setState({ bordaTelephone: '#FFF' })
        }
    }
    onFocusEndereco() {
        this.setState({ bordaEndreco: '#00FF7F' });
    }
    onBlurEndereco() {
        if (this.state.endereco.trim() == '') {
            this.setState({ bordaEndreco: 'red' })
        } else {
            this.setState({ bordaEndreco: '#FFF' })
        }
    }
    onFocusNumero() {
        this.setState({ bordaNumero: '#00FF7F' });
    }
    onBlurNumero() {
        if (this.state.numero.trim() == '') {
            this.setState({ bordaNumero: 'red' })
        } else {
            this.setState({ bordaNumero: '#FFF' })
        }
    }
    onFocusCEP() {
        this.setState({ bordaCEP: '#00FF7F' });
    }
    onBlurCEP() {
        if (this.state.cep.trim() == '') {
            this.setState({ bordaCEP: 'red' })
        } else {
            this.setState({ bordaCEP: '#FFF' })
        }
    }
    onFocusNomeBoleto() {
        this.setState({ bordaNomeBoleto: '#00FF7F' });
    }
    onBlurNomeBoleto() {
        if (this.state.nomeBoleto.trim() == '') {
            this.setState({ bordaNomeBoleto: 'red' })
        } else {
            this.setState({ bordaNomeBoleto: '#FFF' })
        }
    }
    renderCOnteudoFinalizandoPagamento() {

        if (this.state.pagamentoSelecionado == 'Cartão de Crédito') {
            return (
                <>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 25 }}>
                        <Cards
                            cvc={this.state.cvc}
                            expiry={this.state.expiry}
                            focused={this.state.focus}
                            name={this.state.name}
                            number={this.state.number}
                            placeholders={{ name: 'NOME DO TITULAR' }}
                            locale={{ valid: 'valido até' }}
                        />
                        <form onSubmit={(e) => { e.preventDefault() }} style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'space-between', marginLeft: 10 }}>
                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                                <p style={{ color: '#FFF', fontSize: 14 }}>Numero do Cartão: {this.state.bordaNumeroCartao == 'red' && <span style={{ color: 'red', marginLeft: 10 }}>( Campo Obrigatório )</span>}</p>
                                <input
                                    style={{ height: 35, borderWidth: 2, borderColor: this.state.bordaNumeroCartao }}
                                    type="string"
                                    name="number"
                                    placeholder=""
                                    maxLength="16"
                                    onChange={this.handleInputChange}
                                    onFocus={this.handleInputFocus}
                                    onBlur={() => this.onBlurNumeroCartao()}
                                />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                                <p style={{ color: '#FFF', fontSize: 14 }}>Nome do Titular:<span style={{ color: '#CCC', marginLeft: 10 }}>(Como está gravado no cartão)</span>{this.state.bordaNomeCartao == 'red' && <span style={{ color: 'red', marginLeft: 10 }}>( Campo Obrigatório )</span>}</p>
                                <input
                                    style={{ height: 35, borderWidth: 2, borderColor: this.state.bordaNomeCartao }}
                                    type="text"
                                    name="name"
                                    placeholder=""
                                    onChange={this.handleInputChangeNome}
                                    onFocus={this.handleInputFocusNome}
                                    onBlur={() => this.onBlurNomeCartao()}
                                />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                                <p style={{ color: '#FFF', fontSize: 14 }}>Validade: {this.state.bordaValidadeCartao == 'red' && <span style={{ color: 'red', marginLeft: 10 }}>( Campo Obrigatório )</span>}</p>
                                <NumberFormat
                                    style={{ height: 35, borderWidth: 2, borderColor: this.state.bordaValidadeCartao }}
                                    format="##/####"
                                    mask={['M', 'M', 'A', 'A', 'A', 'A']}
                                    type="text"
                                    name="expiry"
                                    placeholder="MM/AAAA"
                                    onChange={this.handleInputChangeValidade}
                                    onFocus={this.handleInputFocusValidade}
                                    onBlur={() => this.onBlurValidadeCartao()}
                                />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                                <p style={{ color: '#FFF', fontSize: 14 }}>Código de Segurança: {this.state.bordaCvcCartao == 'red' && <span style={{ color: 'red', marginLeft: 10 }}>( Campo Obrigatório )</span>}</p>
                                <input
                                    style={{ height: 35, borderWidth: 2, borderColor: this.state.bordaCvcCartao }}
                                    type="tel"
                                    name="cvc"
                                    placeholder=""
                                    //maxLength="3"
                                    onChange={this.handleInputChangeCodSeguranca}
                                    onFocus={this.handleInputFocusCodSeguranca}
                                    onBlur={() => this.onBlurCvcCartao()}
                                />
                            </div>
                        </form>
                    </div>
                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 25 }}>
                        <div style={{ display: 'flex', flexDirection: 'row', height: 50, minHeight: 50, marginBottom: 15 }}>
                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10, flex: 1, height: 50, minHeight: 50, marginRight: 23 }}>
                                <p style={{ color: '#FFF', fontSize: 14 }}>CPF ou CNPJ do titular:{this.state.bordaCpf == 'red' && <span style={{ color: 'red', marginLeft: 10 }}>( Campo Obrigatório )</span>}</p>
                                <input
                                    style={{ height: 35, borderWidth: 2, borderColor: this.state.bordaCpf }}
                                    onFocus={(e) => this.retirarFormatacao(e.target)}
                                    onBlur={(e) => {
                                        this.formatarCampo(e.target);

                                    }}
                                    maxLength="14"
                                    placeholder=""
                                    value={this.state.cpf}
                                    onChange={(e) => this.handlechangeCPF(e)}
                                />
                                {/* <NumberFormat
                                style={{ height: 35 }}
                                type="number"
                                format="### ### ### ##"
                                 mask="_"
                                placeholder="0000 0000 0000 0000"
                                onChange={this.handleInputChange}
                                onFocus={this.handleInputFocus}
                            /> */}
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10, flex: 1, height: 50, minHeight: 50 }}>
                                <p style={{ color: '#FFF', fontSize: 14 }}>CEP: {this.state.bordaCEP == 'red' && <span style={{ color: 'red', marginLeft: 10 }}>( Campo Obrigatório )</span>} <span onClick={() => { this.getCEP(this.state.cep) }} style={{ color: '#39a3f4', marginLeft: 10, textDecoration: 'underline', cursor: 'pointer' }}>Buscar CEP</span></p>
                                <input
                                    style={{ height: 35, borderWidth: 2, borderColor: this.state.bordaCEP }}
                                    onFocus={(e) => this.retirarFormatacao(e.target)}
                                    onBlur={(e) => {
                                        this.formatarCampoCEP(e.target);
                                    }}
                                    type="text"
                                    placeholder=""
                                    maxLength="8"
                                    value={this.state.cep}
                                    onChange={(e) => this.handlechangeCEP(e)}
                                //  onFocus={() => this.onFocusCEP()}
                                // onBlur={() => this.onBlurCEP()}
                                />
                            </div>

                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', height: 50, minHeight: 50, marginBottom: 15 }}>
                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10, flex: 1, height: 50, minHeight: 50, marginRight: 23 }}>
                                <p style={{ color: '#FFF', fontSize: 14 }}>Endereço (Rua):{this.state.bordaEndreco == 'red' && <span style={{ color: 'red', marginLeft: 10 }}>( Campo Obrigatório )</span>}</p>
                                <input
                                    style={{ height: 35, borderWidth: 2, borderColor: this.state.bordaEndreco }}
                                    type="text"
                                    placeholder=""
                                    value={this.state.endereco}
                                    onChange={(e) => this.handlechangeEndereco(e)}
                                    onFocus={() => this.onFocusEndereco()}
                                    onBlur={() => this.onBlurEndereco()}
                                />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10, flex: 1, height: 50, minHeight: 50 }}>
                                <p style={{ color: '#FFF', fontSize: 14 }}>Número:{this.state.bordaNumero == 'red' && <span style={{ color: 'red', marginLeft: 10 }}>( Campo Obrigatório )</span>}</p>
                                <input
                                    style={{ height: 35, borderWidth: 2, borderColor: this.state.bordaNumero }}
                                    type="text"
                                    placeholder=""
                                    value={this.state.numero}
                                    onChange={(e) => this.handlechangeNumero(e)}
                                    onFocus={() => this.onFocusNumero()}
                                    onBlur={() => this.onBlurNumero()}
                                />
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', height: 50, minHeight: 50, marginBottom: 15 }}>

                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10, flex: 1, height: 50, minHeight: 50, marginRight: 23 }}>
                                <p style={{ color: '#FFF', fontSize: 14 }}>Telefone <span style={{ color: '#CCC', marginleft: 15 }}>21 98888 - 7777</span> {this.state.bordaTelephone == 'red' && <span style={{ color: 'red', marginLeft: 10 }}>( Campo Obrigatório )</span>}</p>
                                <input
                                    style={{ height: 35, borderWidth: 2, borderColor: this.state.bordaTelephone }}
                                    type="text"
                                    placeholder=""
                                    value={this.state.telephone}
                                    maxLength="80"
                                    onChange={(e) => this.handleChangeTelephone(e)}
                                    onFocus={() => this.onFocusTelephone()}
                                    onBlur={() => this.onBlurTelephone()}
                                />
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10, flex: 1, height: 50, minHeight: 50 }}>
                                <p style={{ color: '#FFF', fontSize: 14 }}>E-mail:{this.state.bordaEmail == 'red' && <span style={{ color: 'red', marginLeft: 10 }}>( Campo Obrigatório )</span>}</p>
                                <input
                                    style={{ height: 35, borderWidth: 2, borderColor: this.state.bordaEmail }}
                                    type="text"
                                    placeholder=""
                                    value={this.state.email}
                                    maxLength="80"
                                    onChange={(e) => this.handlechangeEmail(e)}
                                    onFocus={() => this.onFocusEmail()}
                                    onBlur={() => this.onBlurEmail()}
                                />
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', height: 70, minHeight: 70, marginBottom: 15 }}>

                            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10, flex: 1, height: 70, minHeight: 70, alignItems: 'center', justifyContent: 'flex-end' }}>
                                <p style={{ color: '#FFF', fontSize: 20 }}>{this.renderPrecoPlanoModal()}</p>
                                <ButtonAssinar disabled={this.state.loadingCep} backGroundDinamico='#00FF7F' corLetraBotao='#000' type="submit" onClick={(e) => { this.finalizaPagamentoCartao(e) }}>
                                    {!this.state.loadingCep && <span>Finalizar</span>}
                                    {this.state.loadingCep && <ClipLoader
                                        css={{}}
                                        size={35}
                                        color={'#000'}
                                        loading={true}
                                    />}
                                </ButtonAssinar>
                            </div>
                        </div>
                    </div>
                </>
            )
        } else if (this.state.pagamentoSelecionado == 'Pix') {

            return (
                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 25 }}>
                    <div style={{ display: 'flex', flexDirection: 'row', height: 50, minHeight: 50, marginBottom: 15 }}>

                        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10, flex: 1, height: 50, minHeight: 50 }}>
                            <p style={{ color: '#FFF', fontSize: 14 }}>Nome:{this.state.bordaNomeBoleto == 'red' && <span style={{ color: 'red', marginLeft: 10 }}>( Campo Obrigatório )</span>}</p>
                            <input
                                style={{ height: 35, borderWidth: 2, borderColor: this.state.bordaNomeBoleto }}
                                type="text"
                                placeholder=""
                                value={this.state.nomeBoleto}
                                maxLength="80"
                                onChange={(e) => this.handlechangeNomeBoleto(e)}
                                onFocus={() => this.onFocusNomeBoleto()}
                                onBlur={() => this.onBlurNomeBoleto()}
                            />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10, flex: 1, height: 50, minHeight: 50, marginLeft: 23 }}>
                            <p style={{ color: '#FFF', fontSize: 14 }}>CPF ou CNPJ:{this.state.bordaCpf == 'red' && <span style={{ color: 'red', marginLeft: 10 }}>( Campo Obrigatório )</span>}</p>
                            <input
                                style={{ height: 35, borderWidth: 2, borderColor: this.state.bordaCpf }}
                                onFocus={(e) => this.retirarFormatacao(e.target)}
                                onBlur={(e) => {
                                    this.formatarCampo(e.target);

                                }}
                                maxLength="14"
                                placeholder=""
                                value={this.state.cpf}
                                onChange={(e) => this.handlechangeCPF(e)}
                            />

                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', height: 50, minHeight: 50, marginBottom: 15 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10, flex: 1, height: 50, minHeight: 50, marginRight: 23 }}>
                            <p style={{ color: '#FFF', fontSize: 14 }}>Endereço (Rua):{this.state.bordaEndreco == 'red' && <span style={{ color: 'red', marginLeft: 10 }}>( Campo Obrigatório )</span>}</p>
                            <input
                                style={{ height: 35, borderWidth: 2, borderColor: this.state.bordaEndreco }}
                                type="text"
                                placeholder=""
                                value={this.state.endereco}
                                onChange={(e) => this.handlechangeEndereco(e)}
                                onFocus={() => this.onFocusEndereco()}
                                onBlur={() => this.onBlurEndereco()}
                            />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10, flex: 1, height: 50, minHeight: 50 }}>
                            <p style={{ color: '#FFF', fontSize: 14 }}>Número:{this.state.bordaNumero == 'red' && <span style={{ color: 'red', marginLeft: 10 }}>( Campo Obrigatório )</span>}</p>
                            <input
                                style={{ height: 35, borderWidth: 2, borderColor: this.state.bordaNumero }}
                                type="text"
                                placeholder=""
                                value={this.state.numero}
                                onChange={(e) => this.handlechangeNumero(e)}
                                onFocus={() => this.onFocusNumero()}
                                onBlur={() => this.onBlurNumero()}
                            />
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', height: 50, minHeight: 50, marginBottom: 15 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10, flex: 1, height: 50, minHeight: 50, marginRight: 23 }}>
                            <p style={{ color: '#FFF', fontSize: 14 }}>Cidade:{this.state.bordaCidade == 'red' && <span style={{ color: 'red', marginLeft: 10 }}>( Campo Obrigatório )</span>}</p>
                            <input
                                style={{ height: 35, borderWidth: 2, borderColor: this.state.bordaCidade }}
                                type="string"
                                placeholder=""
                                value={this.state.cidade}
                                onChange={(e) => this.handlechangeCidade(e)}
                                onFocus={() => this.onFocusCidade()}
                                onBlur={() => this.onBlurCidade()}
                            />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10, flex: 1, height: 50, minHeight: 50 }}>
                            <p style={{ color: '#FFF', fontSize: 14 }}>E-mail:{this.state.bordaEmail == 'red' && <span style={{ color: 'red', marginLeft: 10 }}>( Campo Obrigatório )</span>}</p>
                            <input
                                style={{ height: 35, borderWidth: 2, borderColor: this.state.bordaEmail }}
                                type="text"
                                placeholder=""
                                value={this.state.email}
                                maxLength="80"
                                onChange={(e) => this.handlechangeEmail(e)}
                                onFocus={() => this.onFocusEmail()}
                                onBlur={() => this.onBlurEmail()}
                            />
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', height: 70, minHeight: 70, marginBottom: 15 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10, width: 200, height: 50, minHeight: 50, marginRight: 23 }}>
                            <p style={{ color: '#FFF', fontSize: 14 }}>CEP:  <span onClick={() => { this.getCEP(this.state.cep) }} style={{ color: '#39a3f4', marginLeft: 10, textDecoration: 'underline', cursor: 'pointer' }}>Buscar CEP</span></p>
                            <input
                                style={{ height: 35, borderWidth: 2, borderColor: this.state.bordaCEP }}
                                onFocus={(e) => this.retirarFormatacao(e.target)}
                                onBlur={(e) => {
                                    this.formatarCampoCEP(e.target);

                                }}
                                type="text"
                                placeholder=""
                                maxLength="8"
                                value={this.state.cep}
                                onChange={(e) => this.handlechangeCEP(e)}
                            />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10, flex: 1, height: 70, minHeight: 70, alignItems: 'center', justifyContent: 'flex-end' }}>
                            <p style={{ color: '#FFF', fontSize: 20 }}>{this.renderPrecoPlanoModal()}</p>
                            <ButtonAssinar disabled={this.state.loadingCep} backGroundDinamico='#00FF7F' corLetraBotao='#000' type="submit" onClick={(e) => {
                                this.finalizaPagamentoPix()
                            }}>
                                {!this.state.loadingCep && <span>Finalizar</span>}
                                {this.state.loadingCep && <ClipLoader
                                    css={{}}
                                    size={35}
                                    color={'#000'}
                                    loading={true}
                                />}
                            </ButtonAssinar>
                        </div>
                    </div>
                </div>
            )

        } else {
            return (
                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 25 }}>
                    <div style={{ display: 'flex', flexDirection: 'row', height: 50, minHeight: 50, marginBottom: 15 }}>

                        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10, flex: 1, height: 50, minHeight: 50 }}>
                            <p style={{ color: '#FFF', fontSize: 14 }}>Nome:{this.state.bordaNomeBoleto == 'red' && <span style={{ color: 'red', marginLeft: 10 }}>( Campo Obrigatório )</span>}</p>
                            <input
                                style={{ height: 35, borderWidth: 2, borderColor: this.state.bordaNomeBoleto }}
                                type="text"
                                placeholder=""
                                value={this.state.nomeBoleto}
                                maxLength="80"
                                onChange={(e) => this.handlechangeNomeBoleto(e)}
                                onFocus={() => this.onFocusNomeBoleto()}
                                onBlur={() => this.onBlurNomeBoleto()}
                            />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10, flex: 1, height: 50, minHeight: 50, marginLeft: 23 }}>
                            <p style={{ color: '#FFF', fontSize: 14 }}>CPF ou CNPJ:{this.state.bordaCpf == 'red' && <span style={{ color: 'red', marginLeft: 10 }}>( Campo Obrigatório )</span>}</p>
                            <input
                                style={{ height: 35, borderWidth: 2, borderColor: this.state.bordaCpf }}
                                onFocus={(e) => this.retirarFormatacao(e.target)}
                                onBlur={(e) => {
                                    this.formatarCampo(e.target);

                                }}
                                maxLength="14"
                                placeholder=""
                                value={this.state.cpf}
                                onChange={(e) => this.handlechangeCPF(e)}
                            />

                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', height: 50, minHeight: 50, marginBottom: 15 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10, flex: 1, height: 50, minHeight: 50, marginRight: 23 }}>
                            <p style={{ color: '#FFF', fontSize: 14 }}>Endereço (Rua):{this.state.bordaEndreco == 'red' && <span style={{ color: 'red', marginLeft: 10 }}>( Campo Obrigatório )</span>}</p>
                            <input
                                style={{ height: 35, borderWidth: 2, borderColor: this.state.bordaEndreco }}
                                type="text"
                                placeholder=""
                                value={this.state.endereco}
                                onChange={(e) => this.handlechangeEndereco(e)}
                                onFocus={() => this.onFocusEndereco()}
                                onBlur={() => this.onBlurEndereco()}
                            />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10, flex: 1, height: 50, minHeight: 50 }}>
                            <p style={{ color: '#FFF', fontSize: 14 }}>Número:{this.state.bordaNumero == 'red' && <span style={{ color: 'red', marginLeft: 10 }}>( Campo Obrigatório )</span>}</p>
                            <input
                                style={{ height: 35, borderWidth: 2, borderColor: this.state.bordaNumero }}
                                type="text"
                                placeholder=""
                                value={this.state.numero}
                                onChange={(e) => this.handlechangeNumero(e)}
                                onFocus={() => this.onFocusNumero()}
                                onBlur={() => this.onBlurNumero()}
                            />
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', height: 50, minHeight: 50, marginBottom: 15 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10, flex: 1, height: 50, minHeight: 50, marginRight: 23 }}>
                            <p style={{ color: '#FFF', fontSize: 14 }}>Cidade:{this.state.bordaCidade == 'red' && <span style={{ color: 'red', marginLeft: 10 }}>( Campo Obrigatório )</span>}</p>
                            <input
                                style={{ height: 35, borderWidth: 2, borderColor: this.state.bordaCidade }}
                                type="string"
                                placeholder=""
                                value={this.state.cidade}
                                onChange={(e) => this.handlechangeCidade(e)}
                                onFocus={() => this.onFocusCidade()}
                                onBlur={() => this.onBlurCidade()}
                            />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10, flex: 1, height: 50, minHeight: 50 }}>
                            <p style={{ color: '#FFF', fontSize: 14 }}>E-mail:{this.state.bordaEmail == 'red' && <span style={{ color: 'red', marginLeft: 10 }}>( Campo Obrigatório )</span>}</p>
                            <input
                                style={{ height: 35, borderWidth: 2, borderColor: this.state.bordaEmail }}
                                type="text"
                                placeholder=""
                                value={this.state.email}
                                maxLength="80"
                                onChange={(e) => this.handlechangeEmail(e)}
                                onFocus={() => this.onFocusEmail()}
                                onBlur={() => this.onBlurEmail()}
                            />
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', height: 70, minHeight: 70, marginBottom: 15 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10, width: 200, height: 50, minHeight: 50, marginRight: 23 }}>
                            <p style={{ color: '#FFF', fontSize: 14 }}>CEP:  <span onClick={() => { this.getCEP(this.state.cep) }} style={{ color: '#39a3f4', marginLeft: 10, textDecoration: 'underline', cursor: 'pointer' }}>Buscar CEP</span></p>
                            <input
                                style={{ height: 35, borderWidth: 2, borderColor: this.state.bordaCEP }}
                                onFocus={(e) => this.retirarFormatacao(e.target)}
                                onBlur={(e) => {
                                    this.formatarCampoCEP(e.target);

                                }}
                                type="text"
                                placeholder=""
                                maxLength="8"
                                value={this.state.cep}
                                onChange={(e) => this.handlechangeCEP(e)}
                            //  onFocus={() => this.onFocusCEP()}
                            // onBlur={() => this.onBlurCEP()}
                            />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10, flex: 1, height: 70, minHeight: 70, alignItems: 'center', justifyContent: 'flex-end' }}>
                            <p style={{ color: '#FFF', fontSize: 20 }}>{this.renderPrecoPlanoModal()}</p>
                            <ButtonAssinar disabled={this.state.loadingCep} backGroundDinamico='#00FF7F' corLetraBotao='#000' type="submit" onClick={(e) => { this.finalizaPagamentoBoleto() }}>
                                {!this.state.loadingCep && <span>Finalizar</span>}
                                {this.state.loadingCep && <ClipLoader
                                    css={{}}
                                    size={35}
                                    color={'#000'}
                                    loading={true}
                                />}
                            </ButtonAssinar>
                        </div>
                    </div>
                </div>
            )
        }

    }
    renderConteudoModalConcluirAssinatura() {
        if (this.state.loadingBotaoFinalizar == false) {
            if (this.state.telaDownloadBoleto == true) {
                return (
                    <div style={{ display: 'flex', flex: 1, padding: 16, flexDirection: 'column' }}>
                        <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                            <div>
                                <p style={{ color: '#FFF', fontSize: 20, fontWeight: 'bold', marginBottom: 20 }}>Atente-se aos Detalhes:</p>
                                <li style={{ color: '#FFF', fontSize: 17, marginLeft: 40, marginBottom: 7 }}>Boleto (somente à vista)</li>
                                <li style={{ color: '#FFF', fontSize: 17, marginLeft: 40, marginBottom: 7 }}>Pagamentos com Boleto Bancário levam até 3 dias úteis para serem compensados e então terem os serviços liberados</li>
                                <li style={{ color: '#FFF', fontSize: 17, marginLeft: 40, marginBottom: 7 }}>Atente-se ao vencimento do boleto. Você pode pagar o boleto em qualquer banco ou casa lotérica até o dia do vencimento</li>
                            </div>
                        </div>
                        <div style={{ flex: 1, display: 'flex', justifyContent: 'space-around', marginTop: 20 }}>
                            <ButtonTrocarPlano backGroundDinamico={this.props.corBotaoPadrao} corLetraBotao={this.props.letraBotaoPadrao} type="button" onClick={() => { window.open(this.state.linkBoleto); }}>
                                <i className="icon-pdf2" style={{ color: this.props.letraBotaoPadrao, fontSize: 16, padding: 5 }} />
                                Visualizar Boleto
                            </ButtonTrocarPlano>
                            <ButtonTrocarPlano backGroundDinamico={this.props.corBotaoPadrao} corLetraBotao={this.props.letraBotaoPadrao} type="button" onClick={() => {
                                window.open(this.state.linkBoleto);

                            }}>
                                <i className="icon-download" style={{ color: this.props.letraBotaoPadrao, fontSize: 16, padding: 5 }} />
                                Baixar Boleto
                            </ButtonTrocarPlano>

                        </div>
                    </div>
                )
            } else if (this.state.telaLeituraPixQrCode == true) {

                return (
                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: 16 }}>
                        <p style={{ fontSize: 19, color: this.props.corBotaoPadrao, marginBottom: 15, fontWeight: 'bold' }}> Pague via PIX e libere a versão Premium AGORA!</p>
                        {this.state.gerandoQRCODEPIX === false && this.state.qrCodePix !== 'error' && <div style={{ height: 300, width: 300, borderRadius: 5, background: '#FFF' }}>
                            <img src={this.state.qrCodePix} alt="QR CODE" style={{ height: 300, width: 300 }} />
                        </ div>}

                        {this.state.gerandoQRCODEPIX === false && this.state.qrCodePix === 'error' &&
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#FFF', padding: 20, borderRadius: 7 }}>
                                <img src={qrcodeimage} alt="QR CODE" style={{ height: 200, width: 200, opacity: 0.7 }} />
                                <div onClick={() => {
                                    this.finalizaPagamentoPix()
                                }} style={{ display: 'flex', height: 140, width: 140, borderRadius: '50%', backgroundColor: '#00BEA4', cursor: 'pointer', alignItems: 'center', justifyContent: 'center', position: 'absolute', center: 0, flexDirection: 'column' }}>
                                    <i className="icon-refresh-arrow" style={{ fontSize: 22, color: '#FFF', marginBottom: 7 }} />
                                    <p style={{ fontSize: 16, color: '#FFF' }}> Gerar QR CODE</p>
                                </div>
                            </div>}

                        {this.state.gerandoQRCODEPIX === true &&
                            <div style={{ display: 'flex', height: 300, width: 300, borderRadius: 5, background: '#FFF', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                <img src={qrcodeimage} alt="QR CODE" style={{ height: 230, width: 230, opacity: 0.7 }} />
                                <div style={{ display: 'flex', height: 110, width: 110, borderRadius: '50%', backgroundColor: '#00BEA4', cursor: 'pointer', alignItems: 'center', justifyContent: 'center', position: 'absolute', center: 0, flexDirection: 'column' }}>
                                    <ClipLoader
                                        css={{}}
                                        size={40}
                                        color='#FFF'
                                        loading={true}
                                    />
                                    <p style={{ fontSize: 16, color: '#FFF', fontWeight: 'bold' }}> Gerando...</p>
                                </div>

                            </div>}

                        {this.state.gerandoQRCODEPIX === false && this.state.qrCodePix !== 'error' && <div style={{ display: 'flex', flexDirection: 'column', marginTop: 15, width: '100%' }}>

                            <p style={{ color: '#FFF', fontSize: 17, fontWeight: 'bold' }}>Pix Copia e Cola:</p>

                            <div style={{
                                height: 60,
                                borderRadius: 7, display: 'flex', background: '#CCC', flexDirection: 'row',
                                alignItems: 'center', justifyContent: 'space-between', width: '100%', paddingLeft: 10, paddingRight: 10,
                                marginTop: 10
                            }}>
                                <textarea onChange={(e) => { }} value={this.state.pixCopiaCola} style={{ height: 60, color: '#000', fontSize: 15, fontWeight: 'bold', width: '100%', background: '#CCC', border: 0 }}>{this.state.pixCopiaCola}</textarea>
                                <CopyToClipboard text={this.state.pixCopiaCola}
                                    onCopy={() => { toastr.success('Copiado para área de transferência') }}>
                                    <i className="icon-copy" style={{ color: '#000', fontSize: 14, cursor: 'pointer' }} />
                                </CopyToClipboard>
                            </div>
                        </div>}
                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: 15, width: '100%' }}>
                            <p style={{ color: '#FFF', fontSize: 17, fontWeight: 'bold' }}>Após o pagamento, poderá demorar até 5 minutos para ser compensado.</p>
                        </div>
                    </div>
                )
            } else {
                return (
                    <div style={{ display: 'flex', flex: 1, padding: 16, flexDirection: 'column' }}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <p style={{ color: '#FFF', fontSize: 19, fontWeight: 'bold' }}>Plano Selecionado: </p>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                    <p style={{ color: '#FFF', fontSize: 17, marginLeft: 5 }}>{this.state.mensagemPlanoSelecionado}</p>
                                    <p style={{ color: '#FFF', fontSize: 17, marginLeft: 5 }}> Atendentes Liberados: {this.state.atendenteSelecionado}</p>
                                </div>
                            </div>
                            {this.state.pagamentoSelecionado == 'Pix' && <div style={{ borderRadius: 5 }}>
                                <img src={imgPix} alt="Img Pix" style={{ height: 70, width: 150 }} />
                            </ div>}

                        </div>
                        <div>
                            <p style={{ color: '#FFF', fontSize: 19, fontWeight: 'bold', marginTop: 15 }}>Pagamento</p>
                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10, flex: 1, height: 50, minHeight: 50, marginTop: 10 }}>
                                <p style={{ color: '#FFF', fontSize: 14 }}>Selecione uma forma de pagamento:</p>
                                <div style={{ flex: 1 }}>
                                    <SelectSearch
                                        renderValue={(valueProps) =>
                                            <DivInputSearch>
                                                <InputSearch
                                                    corLetraDinamico='#000'
                                                    backgroundDinamico='#FFF'
                                                    placeholderColor='#CCC'
                                                    corBordaDinamica='#FFF'
                                                    {...valueProps} ></InputSearch>
                                                <IconeSetaPraBaixo className="icon-angle-arrow-down" colorDinamico={this.props.coresLetraPadrao} />
                                            </DivInputSearch>
                                        }
                                        value={this.state.mensal == true ? this.state.pagamentoSelecionado : this.state.pagamentoSelecionadosomenteboleto} options={this.state.mensal == true ? this.state.formapagamento : this.state.formapagamentosomenteboleto} onChange={(value) => { this.handlechangePagamento(value) }} placeholder={"Selecione o Pagamento"} search={false}
                                    //  style={{ color: '#FFF' }} onChange={(value) => alert(value)} options={this.props.clientesSectionedConsulta} placeholder={this.props.clientesSectionedConsulta.length == 0 ? `Nenhum Cliente cadastrado, Por favor verifique!` : "Selecione o Cliente"} search={this.props.clientesSectionedConsulta.length == 0 ? false : true} 
                                    />
                                </div>
                            </div>
                        </div>
                        {this.renderCOnteudoFinalizandoPagamento()}



                        <div style={{ display: 'flex', height: 50, minHeight: 50, justifyContent: 'space-between', alignItems: 'center' }}>
                            <p style={{ color: '#FFF', fontSize: 16 }}>Esse site é protegido e os <a style={{ color: '#39A3F4', textDecoration: 'none' }} href="https://policies.google.com/terms" target="_blank">Termos de Serviço</a> e <a style={{ color: '#39A3F4', textDecoration: 'none' }} href="https://policies.google.com/privacy" target="_blank">Política de Privacidade</a> do Google se aplicam</p>
                            <img src={imgProtection} alt="transacao segura" style={{ height: 40, width: 40, minHeight: 40 }} />

                        </div>
                    </div>

                )
            }
        } else {
            return (
                <div style={{ display: 'flex', height: 400, width: 800 }}>
                    <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                        <p style={{ color: this.props.coresLetraPadrao, fontSize: 20, fontWeight: 'bold' }}>Carregando</p>
                        <ClipLoader
                            css={{ margin: 10 }}
                            size={40}
                            color='#00FF7F'
                            loading={true}
                        />
                    </div>
                </div>
            )
        }

    }
    onFocusCidade() {
        this.setState({ bordaCidade: '#00FF7F' });
    }
    onBlurCidade() {
        if (this.state.cidade.trim() == '') {
            this.setState({ bordaCidade: 'red' })
        } else {
            this.setState({ bordaCidade: '#FFF' })
        }
    }
    validEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    };
    renderPrecoPlanoModal() {
        if (this.state.planoSelecionado === "10") {
            return 'Plano Modesto R$ 99,90';
        } else if (this.state.planoSelecionado === "15") {
            return 'Plano Enérgico R$ 199,90';
        } else if (this.state.planoSelecionado === "20") {
            return 'Plano Inércia R$ 299,90';
        } else if (this.state.planoSelecionado === "60") {
            return 'Plano Aceleração R$ 699,90';
        } else if (this.state.planoSelecionado === "120") {
            return 'Plano Robusto R$ 999,90';
        } else {
            return 'Plano Incentivo R$ 49,90';
        }
    }
    render() {
        return (
            <Container backgroundDinamico={this.props.backgroundPrimeiro}>

                {this.renderLoadingVersaoVIP()}
                <Modal focusTrapped={false} showCloseIcon={false} open={this.state.modalVersaoPremiumAtivaSucesso} onClose={() => { }} center>
                    <ContainerModalCompraSucesso backgroundDinamico='#29454D'>
                        <ContainerIconeFecharModal backgroundDinamico='#3F535A'>
                            <TextNovoAtendimento corLetraDinamico='#FFF'>Compra feita com Sucesso</TextNovoAtendimento>
                            <div>
                                <IconeFechar backgroundDinamico='#FFF' onClick={() => {
                                    this._toggleModalVersaoPremiumSucesso()
                                }} className="icon-close" />
                            </div>
                        </ContainerIconeFecharModal>

                        <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                            <div style={{}}>
                                <img src={versaoPremiumAtivaSucesso} style={{ width: '100%', height: 100 }} />
                            </div>
                            <div style={{ height: 60, justifyContent: 'center', alignItems: 'center', paddingHorizontal: 30, margin: 20, display: 'flex' }}>
                                <p style={{ fontSize: 19, fontWeight: 'bold', color: '#FFF', textAlign: 'center' }}>Compra Efetuada com <span style={{ fontSize: 19, fontWeight: 'bold', color: '#00FF7F' }}>SUCESSO!</span></p>
                            </div>
                            <div stle={{ justifyContent: 'center', alignItems: 'center', margin: 20, display: 'flex' }}>
                                <p style={{ color: '#FFF', fontSize: 15, textAlign: 'center' }}>Parabéns, Agora todos os recursos Premium estão disponiveis para todos os seus clientes e atendentes do seu Help Desk!</p>
                                {/*  <Text style={{ color: '#FFF', fontSize: 13, textAlign: 'center' }}>Qualquer dúvid.</Text> */}
                            </div>

                        </div>
                    </ContainerModalCompraSucesso>
                </Modal>
                <Modal focusTrapped={false} showCloseIcon={false} open={this.state.modalCancelarAssinatura} onClose={() => { }} center>
                    <ContainerModalCancelarAssinatura backgroundDinamico='#29454D'>
                        <ContainerIconeFecharModal backgroundDinamico='#3F535A'>
                            <TextNovoAtendimento corLetraDinamico='#FFF'>Cancelar Assinatura</TextNovoAtendimento>
                            <div>
                                <IconeFechar backgroundDinamico='#FFF' onClick={() => {
                                    this._toggleModalCancelarAssinatura();

                                }} className="icon-close" />
                            </div>
                        </ContainerIconeFecharModal>

                        {this.state.loadingModalCancelar == true &&
                            <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                                <ClipLoader
                                    css={{ margin: 10 }}
                                    size={40}
                                    color='#00FF7F'
                                    loading={true}
                                />
                            </div>
                        } {this.state.loadingModalCancelar == false && <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                            <div style={{}}>
                                <img src={backGroundStopVersaoPremium} style={{ width: '100%', height: 150 }} />
                            </div>
                            <div style={{ height: 60, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                <p style={{ fontSize: 19, fontWeight: 'bold', color: '#FFF', textAlign: 'center' }}>Deseja Realmente <span style={{ fontSize: 19, fontWeight: 'bold', color: 'red' }}>Cancelar</span> a assinatura?</p>
                            </div>
                            <div style={{ justifyContent: 'center', alignItems: 'center', margin: 20, display: 'flex' }}>
                                <p style={{ color: '#FFF', fontSize: 15, textAlign: 'center' }}>Após o cancelamento a sua versão premium irá expirar somente na data de vencimento da mesma.</p>
                                {/*  <Text style={{ color: '#FFF', fontSize: 13, textAlign: 'center' }}>Qualquer dúvid.</Text> */}
                            </div>
                            <div style={{ justifyContent: 'center', alignItems: 'center', marginTop: 20, marginBottom: 20, display: 'flex' }}>
                                <InputCancelamento
                                    corLetraDinamico={this.props.coresLetraPadrao}
                                    autoFocus
                                    type="text"
                                    placeholder="Motivo do Cancelamento"
                                    corBordaDinamica={this.props.corBotaoPadrao}
                                    value={this.props.motivoCancelamento}
                                    onChange={(e) => { this.props.modificaMotivoCancelamentoTelaVersaoPremium(e.target.value) }}
                                // onFocus={() => { this.handleInputEmailFocus() }}
                                // onBlur={() => { this.handleInputEmailBlur() }}
                                />
                            </div>

                        </div>}
                        {this.state.loadingModalCancelar == false && <div style={{ height: 50, minHeight: 50, justifyContent: 'space-around', alignItems: 'center', margin: 10, display: 'flex', flexDirection: 'row' }}>
                            <ButtonCancelar backGroundDinamico='#29454D' corLetraBotao='#FFF' corBorda='#00FF7F' type="button" onClick={() => {
                                this.cancelarAssinatura();
                                // this.criarAssinatura()
                            }}>
                                <p style={{ fontSize: 16, color: '#FFF' }}>Sim</p>
                            </ButtonCancelar>
                            <ButtonCancelar backGroundDinamico='#29454D' corLetraBotao='#FFF' corBorda='#00FF7F' type="button" onClick={() => { this._toggleModalCancelarAssinatura() }}>
                                <p style={{ fontSize: 16, color: '#FFF' }}>Não</p>
                            </ButtonCancelar>
                        </div>}
                    </ContainerModalCancelarAssinatura>
                </Modal>
                <Modal focusTrapped={false} showCloseIcon={false} open={this.state.modalPagamento} onClose={() => { }} center>
                    <ContainerModalCompra backgroundDinamico='#29454D'>
                        <ContainerIconeFecharModal backgroundDinamico='#3F535A'>
                            <TextNovoAtendimento corLetraDinamico='#FFF'>Concluir Assinatura</TextNovoAtendimento>
                            <div>
                                <IconeFechar disabled={this.state.loadingBotaoFinalizar} backgroundDinamico='#FFF' onClick={() => {
                                    if (this.state.loadingBotaoFinalizar) {

                                    } else {
                                        this.setState({ modalPagamento: false, telaDownloadBoleto: false, gerandoQRCODEPIX: true, telaLeituraPixQrCode: false })
                                    }
                                }} className="icon-close" />
                            </div>
                        </ContainerIconeFecharModal>
                        {this.renderConteudoModalConcluirAssinatura()}

                    </ContainerModalCompra>
                </Modal>
                <Modal focusTrapped={false} showCloseIcon={false} open={this.props.modalDuvidasFrequentes} onClose={() => { }} center>
                    <ContainerModal backgroundDinamico={this.props.backgroundPrimeiro}>
                        <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                            <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Dúvidas Frequentes</TextNovoAtendimento>
                            <div>
                                <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => { this.props.modificaModalDuvidasFrequentes(false) }} className="icon-close" />
                            </div>
                        </ContainerIconeFecharModal>
                        <div style={{
                            borderBottom: '1px solid #CCC',
                            marginTop: 20,
                            cursor: 'pointer'
                        }}>
                            < div onClick={() => { this.toggleTextoCompraSegura() }} style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }} >
                                <p style={{
                                    fontSize: 14,
                                    fontWeight: 'bold',
                                    color: this.props.coresLetraPadrao,
                                    marginLeft: 15
                                }}>A compra é segura? Vocês terão acessos aos meus dados pessoais?</p>

                                <i className="icon-angle-arrow-down" style={{ padding: 5, color: this.props.coresLetraPadrao, fontSize: 18 }} />

                            </div>
                            <div style={{ padding: 5, paddingTop: 10 }}>
                                {this.state.textCompraSeguraVisible == true && <p style={{
                                    fontSize: 14,
                                    marginLeft: 15,
                                    textAlign: 'center',
                                    color: this.props.coresLetraPadrao
                                }}>A compra é totalmente segura e nós não teremos acesso aos seus dados e nem ao seu cartão. Todo o processo de pagamento é realizado com segurança  da Juno Pagamentos <span style={{ color: '#39a3f4' }}>( https://juno.com.br )</span>.</p>}
                            </div>
                        </div>
                        <div style={{
                            borderBottom: '1px solid #CCC',
                            marginTop: 20,
                            cursor: 'pointer'
                        }}>
                            < div onClick={() => { this.toggleAposCompra() }} style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }} >
                                <p style={{
                                    fontSize: 14,
                                    fontWeight: 'bold',
                                    color: this.props.coresLetraPadrao,
                                    marginLeft: 15
                                }}>Após a compra, como faço para ter certeza que vou ter acesso ao conteúdo Premium?</p>

                                <i className="icon-angle-arrow-down" style={{ padding: 5, color: this.props.coresLetraPadrao, fontSize: 18 }} />

                            </div>
                            <div style={{ padding: 5, paddingTop: 10 }}>
                                {this.state.aposCompraVisible == true && <p style={{
                                    fontSize: 14,
                                    marginLeft: 15,
                                    textAlign: 'center',
                                    color: this.props.coresLetraPadrao
                                }}>Após o pagamento os recursos Premium serão desbloqueados automaticamente,de acordo com o prazo da determinada forma de pagamento.</p>}
                            </div>
                        </div>
                        <div style={{
                            borderBottom: '1px solid #CCC',
                            marginTop: 20,
                            cursor: 'pointer'
                        }}>
                            < div onClick={() => { this.toggleTrocarCelular() }} style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }} >
                                <p style={{
                                    fontSize: 14,
                                    fontWeight: 'bold',
                                    color: this.props.coresLetraPadrao,
                                    marginLeft: 15
                                }}>Se eu trocar de celular/computador ou formatar, vou perder meu plano Premium?</p>

                                <i className="icon-angle-arrow-down" style={{ padding: 5, color: this.props.coresLetraPadrao, fontSize: 18 }} />

                            </div>
                            <div style={{ padding: 5, paddingTop: 10 }}>
                                {this.state.trocarCelularVisible == true && <p style={{
                                    fontSize: 14,
                                    marginLeft: 15,
                                    textAlign: 'center',
                                    color: this.props.coresLetraPadrao
                                }}>Não, nem você nem seus clientes irão perde o plano Premium. Basta acessar sua conta normalmente que irá entrar o plano normalmente.</p>}
                            </div>
                        </div>
                        <div style={{
                            borderBottom: '1px solid #CCC',
                            marginTop: 20,
                            cursor: 'pointer'
                        }}>
                            < div onClick={() => { this.toggleParaTodos() }} style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }} >
                                <p style={{
                                    fontSize: 14,
                                    fontWeight: 'bold',
                                    color: this.props.coresLetraPadrao,
                                    marginLeft: 15
                                }}>A versão Premium é exclusiva para um dispositivo/computador?</p>

                                <i className="icon-angle-arrow-down" style={{ padding: 5, color: this.props.coresLetraPadrao, fontSize: 18 }} />

                            </div>
                            <div style={{ padding: 5, paddingTop: 10 }}>
                                {this.state.paraTodosVisible == true && <p style={{
                                    fontSize: 14,
                                    marginLeft: 15,
                                    textAlign: 'center',
                                    color: this.props.coresLetraPadrao
                                }}>Não. A versão Premium é para todos os atendentes e clientes de sua empresa.</p>}
                            </div>
                        </div>
                        <div style={{
                            borderBottom: '1px solid #CCC',
                            marginTop: 20,
                            cursor: 'pointer'
                        }}>
                            < div onClick={() => { this.toggleBarato() }} style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }} >
                                <p style={{
                                    fontSize: 14,
                                    fontWeight: 'bold',
                                    color: this.props.coresLetraPadrao,
                                    marginLeft: 15
                                }}>Por que o preço é extremamente mais barato em relação aos concorrentes?</p>

                                <i className="icon-angle-arrow-down" style={{ padding: 5, color: this.props.coresLetraPadrao, fontSize: 18 }} />

                            </div>
                            <div style={{ padding: 5, paddingTop: 10 }}>
                                {this.state.baratoVisible == true && <p style={{
                                    fontSize: 14,
                                    marginLeft: 15,
                                    textAlign: 'center',
                                    color: this.props.coresLetraPadrao
                                }}>Diferente dos sistemas concorrentes, nosso sistema é focado no público brasileiro. Desse modo, os preços são reajustados para a realidade do país.</p>}
                            </div>
                        </div>
                        <div style={{
                            borderBottom: '1px solid #CCC',
                            marginTop: 20,
                            cursor: 'pointer'
                        }}>
                            < div onClick={() => { this.toggleMaisDuvidas() }} style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }} >
                                <p style={{
                                    fontSize: 14,
                                    fontWeight: 'bold',
                                    color: this.props.coresLetraPadrao,
                                    marginLeft: 15
                                }}>Se eu tiver mais dúvidas, como faço pra entrar em contato?</p>

                                <i className="icon-angle-arrow-down" style={{ padding: 5, color: this.props.coresLetraPadrao, fontSize: 18 }} />

                            </div>
                            <div style={{ padding: 5, paddingTop: 10 }}>
                                {this.state.maisDuvidasVisible == true && <p style={{
                                    fontSize: 14,
                                    marginLeft: 15,
                                    textAlign: 'center',
                                    color: this.props.coresLetraPadrao
                                }}>No site {<a href="https://www.dropdesk.com.br" target="_blank"><span style={{ fontSize: 14, color: '#39a3f4', textDecoration: 'none' }}>www.dropdesk.com.br</span></a>} temos uma central de chat, pode nos chamar por lá ou então pode enviar um email direto para dropdesk@dropdesk.com.br</p>}
                            </div>
                        </div>
                    </ContainerModal>
                </Modal>
            </Container >
        )
    }
}
const mapStateToProps = state => {
    return (
        {
            backgroundPrimeiro: state.CoresPadraoSistema.backgroundPrimeiro,
            backgroundSegundo: state.CoresPadraoSistema.backgroundSegundo,
            backgroundMaisEscuro: state.CoresPadraoSistema.backgroundMaisEscuro,
            coresLetraPadrao: state.CoresPadraoSistema.coresLetraPadrao,
            corBotaoPadrao: state.CoresPadraoSistema.corBotaoPadrao,
            corSegundoBotaoPadrao: state.CoresPadraoSistema.corSegundoBotaoPadrao,
            corLetraBotao: state.CoresPadraoSistema.corLetraBotao,
            coresLetraSecundario: state.CoresPadraoSistema.coresLetraSecundario,
            letraBotaoPadrao: state.CoresPadraoSistema.letraBotaoPadrao,
            corLetraDescricaoDosCampos: state.CoresPadraoSistema.corLetraDescricaoDosCampos,
            nomeRota: state.HeaderReducer.nomeRota,
            testePesquisa: state.HeaderReducer.testePesquisa,
            loadingTelaVIP: state.PremiumReducer.loadingTelaVIP,
            planoMensal: state.PremiumReducer.planoMensal,
            planoTrimestral: state.PremiumReducer.planoTrimestral,
            planoAnual: state.PremiumReducer.planoAnual,
            semInternetVIP: state.PremiumReducer.semInternetVIP,
            loadingRefreshControlVIP: state.PremiumReducer.loadingRefreshControlVIP,
            premium: state.PremiumReducer.premium,
            dataValidoAte: state.PremiumReducer.dataValidoAte,
            dataCompra: state.PremiumReducer.dataCompra,

            dataValidadeAtual: state.PremiumReducer.dataValidadeAtual,
            numeroPedidoCompra: state.PremiumReducer.numeroPedidoCompra,
            plano: state.PremiumReducer.plano,
            plataforma: state.PremiumReducer.plataforma,
            qtdAtendentesLiberados: state.PremiumReducer.qtdAtendentesLiberados,
            statusAssinatura: state.PremiumReducer.statusAssinatura,
            identificadorUnicoCompra: state.PremiumReducer.identificadorUnicoCompra,
            nomeAssinante: state.PremiumReducer.nomeAssinante,
            emailAssinante: state.PremiumReducer.emailAssinante,
            premiumVersaoGratis: state.PremiumReducer.premiumVersaoGratis,
            modalDuvidasFrequentes: state.PremiumReducer.modalDuvidasFrequentes,
            renovarPlano: state.PremiumReducer.renovarPlano,
            licensawebouapp: state.PremiumReducer.licensawebouapp,
            mostraVersaoExpirando: state.PremiumReducer.mostraVersaoExpirando,
            isAdmin: state.PerfilReducer.isAdmin,

            nome: state.PerfilReducer.nome,
            email: state.PerfilReducer.email,

            motivoCancelamento: state.PremiumReducer.motivoCancelamento,
        }
    );
};
export default connect(mapStateToProps, {
    modificaNomeRotaNoHeader, modificaModalDuvidasFrequentes, inserePlanoPremium, inserePlanoPremiumBoleto,
    ativaDesativaRenovarPLano, modificaMotivoCancelamentoTelaVersaoPremium,
    insereMotivoCancelamentoBanco, inserePlanoPremiumPix, modificaMostraVersaoExpirando
})(withRouter(VersaoPremium));