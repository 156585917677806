export const MODIFICAEMAILLOGIN = 'modificaEmailLogin';
export const MODIFICASENHALOGIN = 'modificaSenhaLogin';
export const MODIFICACONFIRMASENHA = 'modificaConfirmaSenha';
export const MODIFICACOMPANIA = 'modificaCompania';
export const MODIFICANOME = 'modificaNome';
export const MENSAGEMCADASTRAEMAILERROR = 'mensagemCadastraEmailError';
export const MENSAGEMCADASTRAEMAILSUCESSO = 'mensagemCadastraEmailSucesso';
export const ZERAINFORMACOESNOMECOMPANIACONFIRMASENHA = 'zeraInformacoesNomeCompaniaConfirmaSenha';
export const ATIVALOADINGBOTAOCADASTRARTELACADASTRARCOMPANIA = 'ativaLoadingBotaoCadastrarTelaCadastraCompania';
export const MENSAGEMLOGAREMAILERROR = 'mensagemLogarEmailError';
export const ATIVALOADINGBOTAOLOGARTELALOGIN = 'ativaLoadingBotaoLogarTelaLogin';
export const DESATIVALOADINGBOTAOLOGARTELALOGIN = 'DESATIVALOADINGBOTAOLOGARTELALOGIN';
export const ALTERACLIENTE = 'alteraCliente';
export const ALTERAATENDENTE = 'alteraAtendente';
export const ALTERASETOR = 'alteraSetor';
export const ALTERAPRIORIDADE = 'alteraPrioridade';
export const ALTERALOCALATENDIMENTO = 'AlteraLocalAtendimento';
export const ALTERADESCRICAOCHAMADO = 'AlteraDescricaoChamado';
export const ENVIAFOTOCLIENTEPARAREDUCER = 'enviaFotoClienteParaReducer';
export const INSEREFOTOCADASTROCLIENTE = 'insereFotoCadastroCliente';
export const MENSAGEMCADASTRACLIENTEERROR = 'mensagemCadastraClienteError'
export const MODIFICANOMECLIENTETELACADASTROCLIENTENOVOCADASTRO = 'modificaNomeClienteTelaCadastroClienteNovoCadastro';
export const MODIFICANOMECLIENTETELACADASTROCLIENTE = 'modificaNomeClienteTelaCadastroCliente';
export const MODIFICAEMPRESACLIENTETELACADASTROCLIENTE = 'modificaEmpresaClienteTelaCadastroCliente';
export const MODIFICAEMPRESACLIENTETELACADASTROCLIENTENOVOCADASTRO = 'modificaEmpresaClienteTelaCadastroClienteNovoCadastro';
export const MODIFICAEMAILCLIENTETELACADASTROCLIENTE = 'modificaEmailClienteTelaCadastroCliente';
export const MODIFICASENHACLIENTETELACADASTROCLIENTE = 'modificaSenhaClienteTelaCadastroCliente';
export const MODIFICACONFIRMASENHACLIENTETELACADASTROCLIENTE = 'modificaConfirmaClienteTelaCadastroCliente';
export const MODIFICAEMAILCLIENTETELACADASTROCLIENTENOVOCADASTRO = 'modificaEmailClienteTelaCadastroClienteNovoCadastro';
export const MODIFICASENHACLIENTETELACADASTROCLIENTENOVOCADASTRO = 'modificaSenhaClienteTelaCadastroClienteNovoCadastro';
export const MODIFICACONFIRMASENHACLIENTETELACADASTROCLIENTENOVOCADASTRO = 'modificaConfirmaClienteTelaCadastroClienteNovoCadastro';
export const MENSAGEMCADASTRACLIENTESUCESSO = 'mensagemCasdastraClienteSucesso';
export const ATIVALOADINGBOTAOCADASTRARCLIENTE = 'ativaloadingbotaocadastrarcliente';
export const ALTERAVALORSWITCHTELACLIENTE = 'alteravalorswitchtelacliente';
export const ALTERAVALORSWITCHTELACLIENTENOVOCADASTRO = 'alteravalorswitchtelaclienteNovoCadastro';
export const ZERAINFORMACOESCADASTROCLIENTE = 'zeraInformacoesCadastroCliente';
export const ALTERAVALORDIGITOCONSULTA = 'alteraValorDigitoConsulta';
export const INSEREFOTOCADASTROCLIENTETELAFLATLISTCONSULTA = 'InsereFotoCadastroClienteTelaFlatListConsulta';
export const MODIFICACLIENTESELECIONADO = 'modificaClienteSelecionado';
export const ENVIACLIENTEPARAREDUCER = 'enviaClienteParaReducer';
export const ALTERAMAISDETALHESTELANOVOCHAMADO = 'alteValorMaisDetalhes';
export const ATIVALOADINGCARREGARCLIENTETELANOVOCHAMADO = 'ativaLoadingCarregarClienteTelaNovoChamado';
export const DESATIVALOADINGCARREGARCLIENTETELANOVOCHAMADO = 'desativaLoadingCarregarClienteTelaNovoChamado';
export const ATIVALOADINGCARREGARATENDENTETELANOVOCHAMADO = 'ativaLoadingCarregarAtendenteTelaNovoChamado';
export const DESATIVALOADINGCARREGARATENDENTETELANOVOCHAMADO = 'desativaLoadingCarregarAtendenteTelaNovoChamado';
export const ENVIAATENDENTEPARAREDUCER = 'enviaAtendenteParaReducer';
export const MODIFICAATENDENTESELECIONADO = 'modificaAtendenteSelcionado';
export const MODIFICAPRIORIDADESELECIONADO = 'modificaPrioridadeSelecionado';
export const MODIFICALOCALSELECIONADO = 'modificaLocalSelecionado';
export const MODIFICANOMEATENDENTE = 'modificaNomeAtendente';
export const MODIFICAEMAILACESSOAPP = 'modificaEmailAcessoApp';
export const MODIFICASENHAATENDENTE = 'modificaSenhaAtendente';
export const MODIFICACONFIRMASENHAATENDENTE = 'modificaConfirmaSenhaAtendente';
export const INSEREFOTOCADASTROATENDENTE = 'insereFotoCadastroAtendente';
export const ATIVALOADINGBOTAOCADASTRARATENDENTE = 'ativaLoadingBotaoCadastrarAtendente';
export const MENSAGEMCADASTRAATENDENTEERROR = 'mensagemCasdastraAtendenteError';
export const MENSAGEMCADASTRAATENDENTESUCESSO = 'mensagemCasdastraAtendenteSucesso';
export const ZERAINFORMACOESCADASTROATENDENTE = 'zeraInformacoesCadastroAtendente';
export const MODIFICAVALORDIGITOCONSULTAATENDENTE = 'modificaValorDigitoConsultaAtendente';
export const ENVIADADOSPARAATENDENTEREDUCER = 'enviaDadosParaAtendenteReducer';
export const MODIFICANOMESETOR = 'modificaNomeSetor';
export const MODIFICAOBSERVACAO = 'modificaObservacaoSetor';
export const ATIVALOADINGBOTAOCADASTRASETOR = 'ativaLoadingBotaoCadastrarSetor';
export const MENSAGEMCADASTRASETORERROR = 'mensagemCasdastraSetorError';
export const MENSAGEMCADASTRASETORSUCESSO = 'mensagemCasdastraSetorSucesso';
export const ZERAINFORMACOESCADASTROSETOR = 'zeraInformacoesCadastroSetor';
export const MODIFICADIGITOCONSULTASETOR = 'modificaDigitoConsultaSetor';
export const ENVIADADOSPARASETORREDUCER = 'enviaDadosParaSetorReducer';
export const ENVIASETORPARAREDUCER = 'enviaSetorParaReducer';
export const ATIVALOADINGCARREGARSETORTELANOVOCHAMADO = 'ativaLoadingCarregarSetorTelaNovoChamado';
export const MODIFICASETORSELECIONADO = 'modificaSetorSelecionado';
export const CARREGANOMEPERFIL = 'carregaNomePerfil';
export const CARREGAEMAILPERFIL = 'carregaEmailPerfil';
export const CARREGAIMAGEMPERFIL = 'carregaImagemPerfil';
export const ALTERAEMPRESACLIENTETELAALTERAR = 'alteraEmpresaClienteTelaAlterar';
export const ALTERANOMECLIENTETELAALTERAR = 'alteranomeclientetelaalterar';
export const ALTERAREMAILCLIENTETELAALTERAR = 'alteraremailtelaalterar';
export const ALTERARPERMISSAOCLIENTETELAALTERAR = 'alterarpermissaotelaalterar';
export const ALTERASENHACLIENTETELAALTERAR = 'alterarsenhatelaalterar';
export const ALTERACONFIRMASENHACLIENTETELAALTERAR = 'alterarconfirmasenhatelaalterar';
export const ALTERAFOTOCLIENTETELAALTERAR = 'alterafototelaalterar';
export const INSEREFOTOALTERARCLIENTE = 'inserefotoalterarclientetelaalterar';
export const ALTERAKEYCLIENTETELAALTERAR = 'alterakeyclientetelaalterar';
export const ATIVALOADINGBOTAOALTERARCLIENTE = 'ativaLoadingBotaoAlterarCliente';
export const MENSAGEMCADASTRACLIENTEERRORTELAALTERAR = 'mensagemCasdastraClienteErrorTelaAlterar';
export const MENSAGEMCADASTRACLIENTESUCESSOTELAALTERAR = 'mensagemCasdastraClienteSucessoTelaAlterar';
export const ZERAMENSAGEMDESUCESSOEERRO = 'zeramensagemdesucessoeerro';
export const ZERAMSGSUCESSOEERROCADASTRACLIENTE = 'zeramsg';
export const ZERAMSGSUCESSOERRORATENDENTE = 'zeramensagemsucessoerroatendente';
export const ZERAMENSAGEMSUCESSOERRROSETOR = 'zeramensagemsucessoerrrosetor';
export const ALTERAFOTOATENDENTETELAALTERAR = 'alterafotoatendentetelaalterar';
export const ALTERANOMEATENDENTETELAALTERAR = 'alteranomeatendentetelaalterar';
export const ALTERAEMAILATENDENTETELAALTERAR = 'alteraemailatendentetelaalterar';
export const ALTERASENHAATENDENTETELAALTERAR = 'alterasenhaatendentetelaalterar';
export const ALTERACONFIRMASENHAATENDENTETELAALTERAR = 'alteraconfirmasenhaatendentetelaalterar';
export const INSEREFOTOATENDENTETELAALTERARPICKER = 'insereFotoAtendeneTelaAlterarPicker';
export const MENSAGEMALTERAATENDENTESUCESSO = 'mensagemAlteraAtendenteSucesso';
export const MENSAGEMCALTERAATENDENTEERRO = 'mensagemAlteraAtendenteErro';
export const ATIVALOADINGBOTAOALTERARATENDENTE = 'ativaLoadingBotaoAlterarAtendente';
export const ALTERAKEYATENDENTETELAALTERAR = 'AlterarKeyAtendenteTelaAlterar';
export const ALTERANOMESETORTELAALTERAR = 'alteraNomeSetorTelaAlterar';
export const ALTERAOBSSETORTELAALTERAR = 'AlteraObsSetorTelaAlterar';
export const ATIVALOADINGBOTAOALTERARSETOR = 'ativaLoadingBotaoAlterarSetor';
export const MENSAGEMALTERASETORERROR = 'mensagemAlteraSetorError';
export const MENSAGEMALTERASETORSUCESSO = 'mensagemAlteraSetorSucesso';
export const ALTERAKEYSETORTELAALTERAR = 'alteraKeySetorTelaALterar';
export const ZERAMENSAGEMDESUCRSSO = 'zeraMensagemDeSucesso';
export const LOGINSEMINTERNET = 'loginSemInternet';
export const SEMINTERNETCADASTROUSUARIO = 'semConexaoInternetCadastro';
export const SEMCONEXAOINTERNETCONSULTACLIENTE = 'semConexaoInternetConsultaCliente';
export const SEMCONEXAOINTERNETCADASTRACLIENTE = 'semConexaoInternetCadastraCliente';
export const SEMCONEXAOINTERNETALTERACLIENTE = 'semConexaoInternetAlteraCliente';
export const SEMCONEXAOINTERNETCONSULTASETOR = 'semConexaoInternetConsultaSetor';
export const SEMCONEXAOINTERNETCADASTRASETOR = 'semConexaoInternetCadastraSetor';
export const SEMCONEXAOINTERNETALTERASETOR = 'semConexaoInternetAlteraSetor';
export const SEMCONEXAOINTERNETCONSULTAATENDENTE = 'semInternetConsultaAtendente';
export const SEMCONEXAOINTERNETCADASTRAATENDENTE = 'semConexaoInternetCadastraAtendente';
export const SEMCONEXAOINTERNETALTERAATENDENTE = 'semConexaoInternetAlteraAtendente';
export const ATIVALOADINGBOTAOCADASTRARCHAMADO = 'ativaLoadingBotaoCadastrarChamado';
export const SEMCONEXAOINTERNETCADASTRACHAMADO = 'semConexaoInternetCadastraChamado';
export const CHAMADOCADASTRADOSUCESSO = 'chamadoCadastradoSucesso';
export const MODIFICANOMEARQUIVO1 = 'modificaNomeArquivo1';
export const MODIFICANOMEARQUIVO2 = 'modificaNomeArquivo2';
export const MODIFICANOMEARQUIVO3 = 'modificaNomeArquivo3';
export const MODIFICARESARQUIVO1 = 'modificaResArquivo1';
export const MODIFICARESARQUIVO2 = 'modificaResArquivo2';
export const MODIFICARESARQUIVO3 = 'modificaResArquivo3';
export const LISTACHAMADOABERTO = 'listaChamadoAberto';
export const VERIFICACONEXAOINTERNETCHAMADOABERTO = 'verificaConexaoInternetListaChamadoAberto';
export const ZERAMENSAGEMSEMINTERNETCHAMADOABERTO = 'zeraMensagemSemInternetChamadoAberto';
export const ATIVALOADINGTELACHAMADOABERTO = 'ativaLoadingTelaCHamadoAberto';
export const ENVIAULTIAMREFERENCIAKEY = 'enviaUltimaReferenciaKey';
export const MODIFICANOMECLIENTESELECIONADO = 'modificaNomeClienteSelecionado';
export const MODIFICANOMEATENDENTESELECIONADO = 'modificaNomeAtendenteSelecionado';
export const ZERACADASTROCHAMADO = 'zeraCadastroChamado';
export const ATIVADESATIVALOADINGFINALFLATLISTCHAMADOABERTO = 'ativaDesativaLoadingFinalFlatListChamadoAberto';
export const VERIFICACONEXAOINTERNETCHAMADOEMATENDIMENTO = 'semConexaoInternetListaChamadoEmAtendimento';
export const ZERAMENSAGEMSEMINTERNETCHAMADOEMATENDIMENTO = 'zeraMensagemSemInternetChamadoEmAtendimento'
export const ENVIACHAMADOEMATENDIMENTO = 'enviaChamadoEmAtendimento';
export const ATIVADESATIVALOADINGTELACHAMADOATENDIMENTO = 'ativaDesativaLoadingTelaChamadoAtendimento';
export const ENVIAULTIAMREFERENCIAKEYEMATENDIMENTO = 'enviaUltimaReferenciaKeyEmAtendimento';
export const ATIVADESATIVALOADINGFINALFLATLISTCHAMADOEMATENDIMENTO = 'ativaDesativaLoadingFinalFlatListChamadoEmAtendimento';
export const ENVIACHAMADOFECHADO = 'enviaChamadoFechado';
export const ATIVADESATIVALOADINGTELACHAMADOFECHADO = 'ativaDesativaLoadingTelaChamadoFechado';
export const VERIFICACONEXAOINTERNETCHAMADOFECHADO = 'verificaConexaoInternetChamadoFechado';
export const ZERAMENSAGEMSEMINTERNETCHAMADOFECHADO = 'zeraMensagemSemInternetChamadoFechado';
export const ENVIAULTIAMREFERENCIAKEYFECHADO = 'enviaUltimaReferenciaKeyFechado';
export const ATIVADESATIVALOADINGFINALFLATLISTCHAMADOFECHADO = 'ativaDesativaLoadingFinalFlatListChamadoFechado';
export const ZERAMENSAGEMSEMINTERNETCHAMADOCANCELADO = 'zeraMensagemSemInternetChamadoCancelado';
export const ENVIACHAMADOCANCELADO = 'enviaChamadoCancelado';
export const ATIVADESATIVALOADINGTELACHAMADOCANCELADO = 'ativaDesativaLoadingTelaChamadoCancelado';
export const VERIFICACONEXAOINTERNETCHAMADOCANCELADO = 'semConexaoInternetListaChamadoCancelado';
export const ENVIAULTIAMREFERENCIAKEYCANCELADO = 'enviaUltimaReferenciaKeyCancelado';
export const ATIVADESATIVALOADINGFINALFLATLISTCHAMADOCANCELADO = 'ativaDesativaLoadingFinalFlatListChamadoCancelado';
export const ENVIACHAMADOTODOS = 'enviaChamadoTodos';
export const ATIVADESATIVALOADINGTELACHAMADOTODOS = 'ativaDesativaLoadingTelaChamadoTodos';
export const VERIFICACONEXAOINTERNETCHAMADOTODOS = 'semConexaoInternetListaChamadoTodos';
export const ZERAMENSAGEMSEMINTERNETCHAMADOTODOS = 'zeraMensagemSemInternetChamadoTodos';
export const ENVIAULTIAMREFERENCIACHAMADOABERTO = 'enviaUltimaReferenciaChamadoAberto';
export const ENVIAULTIAMREFERENCIACHAMADOEMATENDIMENTO = 'enviaUltimaReferenciaChamadoEmAtendimento';
export const ENVIAULTIAMREFERENCIACHAMADOFECHADO = 'enviaUltimaReferenciaChamadoFechado';
export const ENVIAULTIAMREFERENCIACHAMADOCANCELADO = 'enviaUltimaReferenciaChamadoCancelado';
export const ATIVADESATIVALOADINGFINALFLATLISTCHAMADOTODOS = 'ativaDesativaLoadingFinalFlatListChamadoTodos';
export const INDICAFIMDALISTA = 'indicaFimDalistaTodos';
export const ENVIAULTIAMREFERENCIAKEYTODOS = 'enviaUltimaReferenciaKeyTodos';
export const ATIVADESATIVALOADINGCARREGARCLIENTETELACHAMADOTODOS = 'ativaDesativaLoadingCarregarClienteTelaChamadoTodos';
export const ENVIACLIENTEPARAREDUCERTELACHAMADOTODOS = 'enviaClienteParaReducerTelaChamadoTodos';
export const MODIFICACLIENTESELECIONADOTELACHAMADOTODOS = 'modificaClienteSelecionadoTelaChamadoTodos';
export const MODIFICANOMECLIENTESELECIONADOTELACHAMADOTODOS = 'modificaNomeClienteSelecionadoTelaChamadoTodos';
export const ENVIAULTIAMREFERENCIAKEYCLIENTECHAMADOTODOS = 'enviaUltimaReferenciaKeyClienteChamadoTodos';
export const ATIVADESATIVALOADINGTELACONSULTACLIENTE = 'ativaDesativaLoadingTelaConsultaCliente';
export const ENVIAULTIAMREFERENCIAKEYTELACONSULTACLIENTE = 'enviaUltimaReferenciaKeyTelaConsultaCliente';
export const ATIVADESATIVALOADINGFINALFLATLISTTELACONSULTACLIENTE = 'ativaDesativaLoadingFinalFlatListTelaConsultaCliente';
export const ATIVADESATIVALOADINGCARREGARCLIENTETELACONSULTACLIENTE = 'ativaDesativaLoadingCarregarClienteTelaConsultaCliente';
export const ENVIACLIENTESECTIONEDTELACONSULTACLIENTE = 'enviaClienteSectionedTelaCOnsultaCliente';
export const MODIFICANOMECLIENTESELECIONADOTELACONSULTACLIENTE = 'modificaNomeClienteSelecionadoTelaConsultaCliente';
export const MODIFICACLIENTESELECIONADOTELACONSULTACLIENTE = 'modificaClienteSelecionadoTelaConsultaCliente';
export const ATIVADESATIVALOADINGFIMDAPAGINATELACONSULTAATENDENTE = 'ativaDesativaLoadingFimDaPaginaTelaConsultaAtendente';
export const ATIVADESATIVALOADINGTELACONSULTAATENDENTE = 'ativaDesativaLoadingTelaConsultaAtendente';
export const ENVIAULTIMAREFERENCIAKEYATENDENTE = 'enviaUltimaReferenciaKeyAtendente';
export const ENVIATENDENTESPARASECTIONED = 'enviaAtendentesParaSectioned';
export const ATIVADESATIVALOADINGSECTIONEDATENDENTE = 'ativaDesativaLoadingSectionedAtendente';
export const MODIFICAATENDENTESELECIONADOSECTIONED = 'modificaAtendenteSelecionadoSectioned';
export const MODIFICANOMEATENDENTESELECIONADOSECTIONED = 'modificaNomeAtendenteSelecionadoSectioned';
export const ATIVADESATIVALOADINGTELACONSULTASETOR = 'ativaDesativaLoadingTelaConsultaSetor';
export const ENVIAULTIMAREFERENCIAKEYSETOR = 'enviaUltimaReferenciaKeySetor';
export const ATIVADESATIVALOADINGFIMDAPAGINACONSULTASETOR = 'ativaDesativaLoadingFimdaPaginaConsultaSetor';
export const ATIVADESATIVALOADINGLISTASETORSECTIONEDTELACONSULTASETOR = 'ativaDesativeLoadingListaSetorSectionedTelaConsultaSetor';
export const ENVIASETORPARASECTIONED = 'enviaSetorParaSectioned';
export const MODIFICASETORSELECIONADOSECTIONED = 'modificaSetorSelecionadoSelecionadoSectioned';
export const MODIFICANOMESETORSELECIONADOSECTIONED = 'modificaNomeSetorSelecionadoSectioned';
export const MODIFICACLIENTESELECIONADOTELACHAMADOTODOSTESTE = `zeraClienteSelecionadoChamadoTodos`
export const ATIVADESATIVALOADINGREFRESHCONTROLCONSULTACLIENTE = `ativaDesativaLoadingRefreshControlConsultaCliente`

export const ATIVADESATIVALOADINGREFRESHCONTROLATENDENTE = `ativaDesativaLoadingRefreshControlAtendente`
export const ATIVADESATIVALOADINGREFRESHCONTROLCONSULTASETOR = `ativaDesativeLoadingRefreshControlConsultaSetor`

export const ATIVADESATIVALOADINGREFRESHCONTROLCHAMADOABERTO = `ativaDesativaLoadingRefreshControlChamadoAberto`
export const ATIVADESATIVALOADINGREFRESHCONTROLCHAMADOEMATENDIMENTO = `ativaDesativaLoadingRefreshControlChamadoEmAtendimento`
export const ATIVADESATIVALOADINGREFRESHCONTROLCHAMADOFECHADO = `ativaDesativaLoadingRefreshControlChamadoFechado`
export const ATIVADESATIVALOADINGREFRESHCONTROLCHAMADOCANCELADO = `ativaDesativaLoadingRefreshControlChamadoCancelado`

export const ATIVADESATIVALOADINGREFRESHCONTROLCHAMADOTODOS = `ativaDesativaLoadingRefreshControlChamadoTodos`
export const ATIVADESATIVALOADINGREFRESHCONTROLCHAMADOTODOSCOMCLIENTE = `ativaDesativaLoadingRefreshControlChamadoTodosComCliente`
export const ZERAINFORMACOESLOGIN = 'zeraInformacoesLogin';
export const MODIFICAEMAILCONTATO = 'modificaEmailContato';
export const MODIFICANOMELCONTATO = 'modificaNomeContato';
export const MODIFICAASSUNTOCONTATO = 'modificaAssuntoContato';
export const MODIFICAMENSAGEMCONTATO = 'modificaMensagemContato';
export const MENSAGEMENVIAEMAILERROR = 'mensagemEnviaEmailError';
export const ZERAVARIAVEISCONTATO = 'zeraVariaveisContato';
export const ATIVADESATIVALOADINGBOTAOENVIAR = 'ativaDesativaLoadingBotaoEnviar';
export const MODIFICADATAVENCIMENTOTELACHAMADOABERTURA = 'modificaDataVencimentoTelaChamadoAbertura';

export const MODIFICAARQUIVOUPADO1 = 'modificaArquivoUpado1';
export const MODIFICAARQUIVOUPADO2 = 'modificaArquivoUpado2';
export const MODIFICAARQUIVOUPADO3 = 'modificaArquivoUpado3';

export const MODIFICACONTAGEMARQUIVO = 'modificaContagemArquivo';

export const MODIFICATAMANHOARQUIVO1 = 'modificaTamanhoArquivo1';
export const MODIFICATAMANHOARQUIVO2 = 'modificaTamanhoArquivo2';
export const MODIFICATAMANHOARQUIVO3 = 'modificaTamanhoArquivo3';

export const ALTERANUMEROATENDIMENTO = 'ALTERANUMEROATENDIMENTO';
export const ALTERAKEYCHAMADO = 'ALTERAKEYCHAMADO';
export const ENVIAATENDIMENTOPARAREDUCER = 'enviaAtendimentoParaReducer';
export const ATIVADESATIVALOADINGTELAACOESATENDIMENTO = 'ativaDesativaLoadingTelaAcoesAtendimento';
export const ENVIAUSUARIOSTELAACOESATENDIMENTO = 'enviaUsuariosTelaAcoesAtendimento';
export const VERIFICAERRORTELAALTERARCHAMADO = 'verificaErrorTelaALterarChamado';
export const VERIFICACHAMADOALTERADOSUCESSO = 'verificaChamadoAlteradoSucesso';
export const ENVIAARQUIVOSUPADOSTELAACOESCHAMADOS = 'enviaArquivosUpadosTelaAcoesChamados';
export const ENVIAARQUIVOSUPADOSTELAACOESCHAMADOS2 = 'enviaArquivosUpadosTelaAcoesChamados2';
export const ENVIAARQUIVOSUPADOSTELAACOESCHAMADOS3 = 'enviaArquivosUpadosTelaAcoesChamados3';
export const ENVIAACOESATENDIMENTOPARAREDUCER = 'enviaAcoesAtendimentoParaReducer';
export const MODIFICAMOTIVOCANCELAMENTOTELAACOESATENDIMENTO = 'modificaMotivoCancelamentoTelaAcoesAtendimento';
export const MODIFICAMENSAGEMERRORMOTIVOCANCELAMENTO = 'modificaMensagemErroMotivoCancelamento';
export const MODIFICAVISIBILIDADEMODALCANCELAR = 'modificavisibilidadeModalCancelar';
export const MODIFICAVISIBILIDADEMODALCOMENTARIo = 'modificaVIsibilidadeModalComentario';
export const ATIVADESATIVASWITCHCOMENTARIO = 'ativaDesativaSwitchComentario';
export const MODIFICAINPUTCOMENTARIO = 'modificaInputComentario';
export const MODIFICANOMEARQUIVOCOMENTARIOTELAACOESATENDIMENTO = 'modificaNomeArquivoComentarioTelaAcoesAtendimento';
export const MODIFICARESARQUIVOCOMENTARIOTELAACOESATENDIMENTO = 'modificaResArquivoComentarioTelaAcoesAtendimento';
export const MODIFICACOMENTARIOPUBLICOSIMOUNAO = 'modificaComentarioPublicoSimOuNao';
export const ZERAMENSAGEMSEMCONEXAOINTERNETSALVARCOMENTARIO = 'zeraMensagemSemConexaoInternetSalvarComentario';
export const ZERAINFORMACOESCOMENTARIO = 'zeraInformacoesComentario';
export const ENVIAATENDIMENTOSNOTOTALDASHBOARD = 'enviaAtendimentosNoTotalDashBoard';
export const ATIVADESATIVALOADINGTELADASHBOARD = 'ativaDesativaLoadingTelaDashBoard';
export const ENVIACLIENTESNOTOTALDASHBOARD = 'enviaClientesNoTOtalDashBoard';
export const ENVIATENDIMENTOSHOJEDASHBOARD = 'enviaAtendimentosHojeDashBoard';
export const ENVIAATENDIMENTOSDESTASEMANADASHBOARD = 'enviaAtendimentosDestaSemanaDashBoard';
export const ATIVADESATIVALOADINGREFRESHCONTROL = 'ativaDesativaLoadingRefreshControol';
export const ENVIATENDIMENTOSPORSTATUSDASHBOARD = 'enviaAtendimentosPorStatusDashboard';
export const ENVIATENDIMENTOSPORPRIORIDADEDASHBOARD = 'enviaAtendimentosPorPrioridadeDashboard';
export const ENVIATOTALATENDIMENTOPORPRIORIDADE = 'enviaTotalAtendimentosPorPRioridade';
export const ENVIACLIENTEMAISABRIRAMCHAMADOS = 'enviaClientesMaisAbriramChamados';
export const ENVIASETORESMAISABRIRAMCHAMADOS = 'enviaSetoresMaisAbriramChamados';
export const ENVIATEMPORESOLUCAOCHAMADOS = 'enviaTempoResolucaoChamados';
export const ENVIATOALTENDIMENTOSPORLOCALDASHBOARD = 'enviaTotalAtendimentosPorLocalDashboard';
export const ENVIATENDIMENTOSPORLOCALDASHBOARD = 'enviaAtendimentosPorLocalDashboard';
export const ENVIATOTALATENDIMENTOSPERIODORESOLUCAO = 'enviaTotalAtendimentosPeriodoResolucao';
export const ENVIASEMCONEXAOINTERNETDASHBOARD = 'enviaSemConexaoInternetDashBoard';

export const ALTERASWITCHNOTIFATENDABERTOPORMIM = 'alteraSwitchNotifAtendABertoPorMim';
export const ALTERASWITCHNOTIFATENDESTOUATENDENDO = 'alteraSwitchNotifAtendEstouAtendendo';
export const ALTERASWITCHNOTIFRESPATENDABERTOPORMIM = 'alteraSwitchNotifRespAtendAbertoPorMim';
export const ALTERASWITCHNOTIFRESPATENDQUEESTOUATENDENDO = 'alteraSwitchNotifRespAtendQueEstouAtendendo';
export const ATIVADESATIVALOADINGTELANOTIFICACAO = 'ativaDesativaLoadingTelaNotificacao';
export const ATIVADESATIVALOADINBOTAOALTERARNOTIFICACAO = 'ativaDesativaLoadingBOtaoAlterarNotificacao';
export const VERIFICACONEXAOINTERNETNOTIFICAO = 'verificaConexaoInternetNotificacao';
export const VERIFICAINTERNETESQUECEUSENHA = 'verificaInternetEsqueceuSenha';
export const ATIVADESATIVALOADINGBOTAOESQUECEUSENHA = 'ativaDesativaLoadingBotaoEsqueceuSenha';
export const ENVIATOUCHBACKGROUNDMENUGAVETA = 'enviaTochBackGroundMenuGaveta';
export const ENVIAARQUIVOPARASERUPADOTELAABERTURACHAMADO = 'enviaArquivoParaSerUpadoTelaAberturaChamado';

export const ENVIATAMANHOARQUIVOANEXADOTELAABERTURACHAMADO = 'enviaTamanhoArquivoAnexadoTelAberturaChamado';
export const ENVIARQUIVOSUPADOSANEXADOSAOCHAMADO = 'enviaArquivosUpadosAnexadoAoChamado';
export const ATIVADESATIVALOADINGARQUIVOSUPADOSANEXADOSAOCHAMADO = 'ativaDesatidaLoadingArquivosUpadosAnexadoAoChamado';

export const DIMINUITAMANHOTOTALARQUIVOSANEXADOSLOCAL = 'diminuiTamanhoTotalArquivosAnexadosLocal';
export const ENVIASEEHVERSAOPREMIUM = 'enviaSeEhVersaoPremium';
export const VERIFICAINTERNETTELAVIP = 'verificaInternetTelaVIP';
export const LOADINGREFRESHCONTROLVIP = 'loadingRefreshControlVIP';
export const LOADINGTELAVIP = 'loadingTelalVIP';
export const ENVIAPLANOMENSAL = 'enviaPlanoMensal';
export const ENVIAPLANOTRIMESTRAL = 'enviaPlanoTrimestral';
export const ENVIAPLANOANUAL = 'enviaPlanoAnual';
export const ENVIADATAVALIDADEVERSAOPREMIUM = 'enviaDataValidadeVersaoPremium';
export const VERIFICASEALTEROUCOMSUCESSONOTIFICACAO = 'verificaSeAlterouComSucessoNotificacao';
export const ZERAVERIFICASEALTEROUCOMSUCESSONOTIFICACAO = 'zeraverificaSeAlterouComSucessoNotificacao';
export const MODIFICANUMEROEXCLUSIVOCADASTRACLIENTE = 'modificaNumeroExclusivoCadastraCliente';
export const ATIVADESATIVALOADINGNUMEROEXCLUSIVOCADASTRACLIENTE = 'ativaDesativaLoadingNumeroExclusivoCadastraCliente';
export const MODIFICADESTINATARIOSELECIONADO = 'modificaDestinatarioSelecionado';
export const ATIVADESATIVALOADINGCARREGARATENDENTETELANOVOAVISO = 'ativaDesativaLoadingCarregarAtendenteTelaNovoAviso';
export const ENVIAATENDENTEPARAREDUCERNOVOAVISO = 'enviaAtendenteParaReducerNovoAviso';
export const MODIFICAATENDENTESELECIONADONOVOAVISO = 'modificaAtendenteSelecionadoNovoAviso';
export const ATIVADESATIVALOADINGCARREGARCLIENTETELANOVOAVISO = 'ativaDesativaLoadingCarregarClienteTelaNovoAviso';
export const ENVIACLIENTEPARAREDUCERNOVOAVISO = 'enviaClienteParaReducerNovoAviso';
export const MODIFICAClIENTESELECIONADONOVOAVISO = 'modificaClienteSelecionado';
export const ALTERADESCRICAOMENSAGEMNOVOAVISO = 'alteraDescricaoMensagemNovoAviso';
export const MENSAGEMENVIAAVISOERROR = 'mensagemEnviaAvisoError';
export const ATIVADESATIVALOADINGCADASTRARNOVOAVISO = 'ativaDesativaLoadingCadastrarNovoAviso';
export const ZERACADASTROAVISO = 'zeraCadastroAviso';
export const ALTERARVALORSWITCHNOVOAVISO = 'alteraValorSwitchNovoAviso';
export const MODIFICADATAINICIALNOVOAVISO = 'modificaDataInicialNovoAviso';
export const MODIFICADATAFINALNOVOAVISO = 'modificaDataFinalNovoAviso';
export const ATIVADESATIVALOADINGTELANOVOAVISO = 'ativaDesativaLoadingTelaNovoAviso';
export const ENVIAAVISOPARAREDUCER = 'enviaAvisoParaReducer';
export const ATIVADESATIVALOADINGREFRESHCONTROLNOVOAVISO = 'ativaDesativaLoadingRefreshControlNovoAviso';
export const ENVIAREFERENCIAULTIMAKEYAVISO = 'enviaReferenciaUltimaKeyAviso';
export const ATIVADESATIVALOADINGFIMDAPAGINACONSULTAAVISO = 'ativaDesativaLoadingFimdaPaginaConsultaAviso';
export const ENVIASEMCONEXAOINTERNETCONSULTAAVISO = 'enviaSemConexaoInternetConsultaAviso';
export const ALTERAFILTROSELECIONADOTELANOVOAVISO = 'alteraFiltroSelecionadoTelaNovoAviso';

export const ALTERAKEYAVISOPARAALTERACAO = 'AlteraKeyAvisoParaAlteracao';
export const ALTERASTATUSAVISOPARAALTERACAO = 'AlteraStatusAvisoParaAlteracao';
export const ZERACADASTROAVISOPARAALTERACAO = 'zeraCadastroAvisoParaAlteracao';
export const ENVIAAVISOPARATELADASHBOARD = 'enviaAvisoParaTelaDashBoard';
export const ATIVADESATIVALOADINGALTERARAVISO = 'ativaDesativaLoadingAlterarAviso';
export const MUDAVISIBILIDADEMODALAVISOVERSAOPREMIUM = 'mudaVisibilidadeModalAvisoVersaoPremium';
export const DESATIVALOADINGBOTAOCADASTRARATENDENTE = 'desativaLoadingBotaoCadastrarAtendente';
export const DESATIVALOADINGBOTAOCADASTRARCLIENTE = 'desativaLoadingBotaoCadastrarCliente';
export const MUDAVISIBILIDADEMODALCADASTRACLIENTEVERSAOPREMIUM = 'mudaVisibilidadeModalCadastraClienteVersaoPremium';
export const MUDAVISIBILIDADEMODALCADASTRAATENDENTEVERSAOPREMIUM = 'mudaVisibilidadeModalCadastraAtendenteVersaoPremium';
export const ENVIAPERIODOPERSONALIZADO = 'enviaPeriodoPersonalizado';
export const MODIFICADATAINICIALCONSULTADASHBOARD = 'modificaDataInicialConsultaDashBoard';
export const MODIFICADATAFINALCONSULTADASHBOARD = 'modificaDataFinalConsultaDashBoard';
export const MENSAGEMPERIODOPERSONALIZADOERROR = 'mensagemPeriodoPersonalizadoError';
export const MENSAGEMSEMCONEXAOINTERNETCONFIGEMPRESA = 'mensagemSemConexaoInternetConfigEmpresa';
export const ATIVADESATIVALOADINGTELACONFIGEMPRESA = 'ativaDesativaLoadingTelaConfigEmpresa';
export const ENVIANOMEEMPRESAPARAREDUCER = 'enviaNomeEmpresReducer';
export const MODIFICANOMEEMPRESACONFIGURAEMPRESA = 'modificaNomeEmpresa';
export const ATIVADESATIVALOADINGBOTAOALTERARNOMEEMPRESACONFIGEMPRESA = 'ativaDesativaLoadingBotaoAlterarNomeEmpresaConfigEmpresa';
export const MENSAGEMSEMCONEXAOINTERNETCONFIGAVALIACAO = 'mensagemSemConexaoInternetConfigAvaliacao';
export const ATIVADESATIVALOADINGTELACONFIGAVALIACAO = 'ativaDesativaLoadingTelaConfigAvaliacao';
export const ATIVADESATIVALOADINGBOTAOALTERARCONFIGAVALIACAO = 'ativaDesativaLoadingBotaoAlterarConfigAvaliacao';
export const ENVIAMENSAGEMAVALIACAOPARAREDUCER = 'enviaMensagemAvaliacaoParaReducer';
export const ENVIASEDESEJAMANDARMENSAGEMAVALIACAOAPOSFINALIZARATENDIMENTO = 'enviaSeDesejaMandarMensagemAvaliacaoAposFInalizarAtendimento';
export const ENVIADATACOMPRAVERSAOPREMIUM = 'enviaDataCompraVersaoPremium';
export const MODIFICADATAINICIALAVALIACAOGERAL = 'modificaDataInicialAvaliacaoGeral';
export const MODIFICADATAFINALAVALIACAOGERAL = 'modificaDataFinalAvaliacaoGeral';
export const ENVIAPERIODOPERSONALIZADOAVALIACAOGERAL = 'enviaPeriodoPersonalizadoAvaliacaoGeral';
export const ATIVADESATIVALOADINGTELARELATORIOAVALIACAOGERAL = 'ativaDesativaLoadingTelaRelatorioAvaliacaoGeral';
export const MENSAGEMERRORAPLICAPERIODOPERSONALIZADO = 'mensagemErrorAplicaPeriodoPersonalizado';
export const ENVIAAVALIACOESGERALDASHBOARD = 'enviaAvaliacoesGeralDashBoard';
export const ENVIATOTALAVALIACOESGERAL = 'enviaTotalAvaliacoesGeral';
export const ENVIAAVALIACAO1ESTRELA = 'enviaAvaliacao1estrela';
export const ENVIAAVALIACAO2ESTRELA = 'enviaAvaliacao2estrela';
export const ENVIAAVALIACAO3ESTRELA = 'enviaAvaliacao3estrela';
export const ENVIAAVALIACAO4ESTRELA = 'enviaAvaliacao4estrela';
export const ENVIAAVALIACAO5ESTRELA = 'enviaAvaliacao5estrela';
export const ENVIATOTALAVALIACOES = 'enviaTotalAvaliacoes';
export const ENVIAPORCENTAGEM1ESTRELA = 'enviaPorcentagem1estrela';
export const ENVIAPORCENTAGEM2ESTRELA = 'enviaPorcentagem2estrela';
export const ENVIAPORCENTAGEM3ESTRELA = 'enviaPorcentagem3estrela';
export const ENVIAPORCENTAGEM4ESTRELA = 'enviaPorcentagem4estrela';
export const ENVIAPORCENTAGEM5ESTRELA = 'enviaPorcentagem5estrela';
export const ENVIANSOGERAL = 'enviaNSOGeral';
export const ENVIANOTAMEDIAGERAL = 'enviaNotaMediaGeral';
export const ENVIATENDENTEPARASECTIONEDRELATORIOATENDENTE = 'enviaAtendentesParaSectionedRelatorioAtendente';
export const ATIVADESATIVALOADINGSECTIONEDATENDENTERELATORIOATENDENTE = 'ativaDesativaLoadingSectionedAtendenteRelatorioAtendente';
export const MODIFICAATENDENTESELECIONADOSECTIONEDRELATORIOAVALIACAO = 'modificaAtendenteSelecionadoSectionedRelatorioAvaliacao';

export const ATIVADESATIVALOADINGTELARELATORIOAVALIACAOATENDENTE = 'ativaDesativaLoadingTelaRelatorioAvaliacaoAtendente';
export const ENVIAPERIODOPERSONALIZADOAVALIACAOATENDENTE = 'enviaPeriodoPersonalizadoAvaliacaoAtendente';


export const ENVIAAVALIACAO1ESTRELAATENDENTE = 'enviaAvaliacao1estrelaAtendente';
export const ENVIAAVALIACAO2ESTRELAATENDENTE = 'enviaAvaliacao2estrelaAtendente';
export const ENVIAAVALIACAO3ESTRELAATENDENTE = 'enviaAvaliacao3estrelaAtendente';
export const ENVIAAVALIACAO4ESTRELAATENDENTE = 'enviaAvaliacao4estrelaAtendente';
export const ENVIAAVALIACAO5ESTRELAATENDENTE = 'enviaAvaliacao5estrelaAtendente';
export const ENVIATOTALAVALIACOESATENDENTE = 'enviaTotalAvaliacoesAtendente';

export const ENVIAPORCENTAGEM1ESTRELAATENDENTE = 'enviaPorcentagem1estrelaAtendente';
export const ENVIAPORCENTAGEM2ESTRELAATENDENTE = 'enviaPorcentagem2estrelaAtendente';
export const ENVIAPORCENTAGEM3ESTRELAATENDENTE = 'enviaPorcentagem3estrelaAtendente';
export const ENVIAPORCENTAGEM4ESTRELAATENDENTE = 'enviaPorcentagem4estrelaAtendente';
export const ENVIAPORCENTAGEM5ESTRELAATENDENTE = 'enviaPorcentagem5estrelaAtendente';
export const ENVIANSOGERALATENDENTE = 'enviaNSOGeralAtendente';
export const ENVIANOTAMEDIAGERALATENDENTE = 'enviaNotaMediaGeralAtendente';

export const MODIFICADATAINICIALAVALIACAOATENDENTE = 'modificaDataInicialAvaliacaoAtendente';
export const MODIFICADATAFINALAVALIACAOATENDENTE = 'modificaDataFinalAvaliacaoAtendente';

export const MENSAGEMERRORAPLICAPERIODOPERSONALIZADOATENDENTE = 'mensagemErrorAplicaPeriodoPersonalizadoAtendente';

export const ATIVADESATIVALOADINGTELATODASAVALIACOES = 'ativaDesativaLoadingTelaTodasAvaliacoes';
export const ATIVADESATIVALOADINGREFRESHCONTROLTODASAVALIACOES = 'ativaDesativaLoadingRefreshControlTodasAvaliacoes';

export const ENVIAAVALIACOESTELATODASAVALIACOS = 'enviaAvaliacoesTelaTodasAvaliacoes';
export const ENVIAULTIMAREFERENCIAKEYAVALIACAO = 'enviaUltimaReferenciaKeyAvaliacao';
export const ATIVADESATIVALOADINGFINALPAGINATODASAVALIACOES = 'ativaDesativaLoadingFinalPaginaTodasAvaliacoes';
export const VERIFICACONEXAOINTERNETELATODASAVALIACOES = 'verificaConexaoInternetTelaTOdasAvaliacoes';
export const ENVIADETALHESAVALIACAOTELADETALHESAVALIACAO = 'enviaDetalhesAvaliacaoTelaDetalhesAvaliacao';

export const ENVIACLIENTEPARAREDUCERTODASAVALIACOES = 'enviaClienteParaReducerTodasAvaliacoes';
export const ENVIAATENDENTEPARAREDUCERTODASAVALIACOES = 'enviaAtendenteParaReducerTodasAvaliacoes';

export const ATIVADESATIVALOADINGENVIAATENDENTETELAAVALIACAOTODAS = 'ativaDesativaLoadingEnviaAtendenteTelaAvaliacaoTodas';
export const ATIVADESATIVALOADINGENVIACLIENTETELAAVALIACAOTODAS = 'ativaDesativaLoadingEnviaClienteTelaAvaliacaoTodas';

export const MODIFICACLIENTESELECIONADOTELAVALIACAOTODAS = 'modificaClienteSelecionadoTelaAvaliacaoTodas';
export const MODIFICAATENDENTESELECIONADOTELAVALIACAOTODAS = 'modificaAtendenteSelecionadoTelaAvaliacaoTodas';
export const MODIFICAVALORAVALIACAOSELECIONADOTELAVALIACAOTODAS = 'modificaValorAvaliacaoSelecionadoTelaAvaliacaoTodas';
export const ENVIAULTIMAKEYREFERENCIAKEYCLIENTETODASAVALIACOES = 'enviaUltimaReferenciaKeyClienteTodasAvaliacoes';
export const ENVIAULTIMAKEYREFERENCIAKEYATENDENTETODASAVALIACOES = 'enviaUltimaReferenciaKeyAtendenteTodasAvaliacoes';
export const ENVIAULTIMAKEYREFERENCIAKEYVALORAVALIACAOTODASAVALIACOES = 'enviaUltimaReferenciaKeyValorAvaliacaoTodasAvaliacoes';

export const ALTERAVALORSWITCHCONFIGATENDIMENTO = 'alteraValorSwitchConfigAtendimento';

export const ENVIADADOSCONFIGURACAOATENDIMENTO = 'enviaDadosConfiguracaoAtendimento';
export const ATIVADESATIVALOADINGCONFIGURACAOATENDIMENTO = 'ativaDesativaLoadingConfiguracaoAtendimento';

export const MENSAGEMSEMCONEXAOINTERNETCONFIGURACAOATENDIMENTO = 'mensagemSemConexaoInternetConfiguracaoAtendimento';
export const ATIVADESATIVALOADINGBOTAOALTERARCONFIGURACAOATENDIMENTO = 'ativaDesativaLoadingBotaoAlterarConfiguracaoAtendimento';
export const VERIFICARSEEHOBRIGATORIOATRIBUIRATENDENTE = 'verificaSeEhObrigatorioATribuirAtendente';

export const ZERAMENSAGEMERRORTELACADASTROATENDENTE2 = 'zeraMensagemErrorTelaCadastroAtendente2';


export const ALTERASWITCHPERMISSAOALTERARADMINISTRADOR = 'alteraSwitchPermissaoAlterarAdministrador';
export const ALTERASWITCHPERMISSAOALTERARCLIENTE = 'alteraSwitchPermissaoAlterarCliente';
export const ALTERASWITCHPERMISSAOALTERARATENDENTE = 'alteraSwitchPermissaoAlterarAtendente';
export const ALTERASWITCHPERMISSAOALTERARSETORES = 'alteraSwitchPermissaoAlterarSetores';
export const ALTERASWITCHPERMISSAOALTERARAVISOS = 'alteraSwitchPermissaoAlterarAvisos';
export const ALTERASWITCHPERMISSAOACESSORELATORIOS = 'alteraSwitchPermissaoAcessoRelatorios';

export const ALTERAINFORMACAOCONTAPRINCIPALATENDENTE = 'alteraInformacaoContaPrincipalAtendente';
export const ALTERASWITCHPERMISSAOADMINISTRADORSOZINHO = 'AlteraSwitchPermissaoADministradorSozinho';

export const ZERAMENSAGEMERRORAOALTERARATENDENTE = 'ZERAMENSAGEMERRORAOALTERARATENDENTE';
export const ENVIAPERMISSOESACESSARTELASATENDENTE = 'enviaPermissoesAcessarTelasAtendente';

export const ALTERACLIENTESELECIONADOTELACADASTRACLIENTE = 'alteraClienteSelecionadoTelaCadastraCliente';
export const MODIFICANOMECLIENTESELECIONADOTELACADASTROCLIENTE = 'modificaNomeClienteSelecionadoTelaCadastroCliente';
export const MODIFICANOMECLIENTESELECIONADOTELAALTERARCLIENTE = 'modificaNomeClienteSelecionadoTelaAlterarCliente';
export const ATIVADESATIVALOADINGTELAALTERARCLIENTE = 'ativaDesativaLoadingTelaAlterarCliente';
export const ENVIAEMPRESAPRINCIPALCLIENTE = 'enviaEmpresaPrincipalCliente';

export const MODIFICAENDERECOTELACADASTROCLIENTENOVOCADASTRO = 'modificaEnderecoTelaCAdastroClienteNovoCadastro';
export const MODIFICATELEFONETELACADASTROCLIENTENOVOCADASTRO = 'modificaTelefoneTelaCAdastroClienteNovoCadastro';
export const MODIFICAENDERECOTELACADASTROCLIENTE = 'modificaEnderecoTelaCAdastroCliente';
export const MODIFICATELEFONETELACADASTROCLIENTE = 'modificaTelefoneTelaCAdastroCliente';

export const ENVIAEMPRESASECTIONEDTELAALTERARCLIENTE = 'enviaEmpresaSectionedTelaAlterarCliente';
export const ATIVADESATIVALOADINGCARREGARCLIENTETELAALTERARCLIENTE = 'ativaDesativaLoadingCarregarClienteTelaAlterarCliente';
export const ALTERAEMPRESASELECIONADOTELAALTERARCLIENTE = 'alteraEmpresaSelecionadoTelaAlterarCliente';
export const ALTERAEMPRESASELECIONADOTELAALTERARCLIENTENOVOCADASTRO = 'alteraEmpresaSelecionadoTelaAlterarClienteNovoCadastro';
export const MODIFICAEMPRESASELECIONADAAOABRIRCHAMADO = 'modificaEmpresaSelecionadaAoAbrirChamado';
export const MODIFICAKEYEMPRESAVINCULADASELECIONADAAOABRIRCHAMADO = 'modificaKeyEmpresaVinculadaSelecionadaAoAbrirChamado';
export const ATIVADESATIVALOADINGBOTAOEXCLUIRCLIENTE = 'ativaDesativaLoadingBotaoExcluirCliente';
export const MUDAVISIBILIDADEMODALEXCLUIRCLIENTE = 'mudaVisibilidadeModalExcluirCliente';
export const MUDAVISIBILIDADEMODALEXCLUIRATENDENTE = 'mudaVisibilidadeModalExcluirAtendente';
export const ATIVADESATIVALOADINGCONTEUDOEXCLUIRATENDENTE = 'ativaDesativaLoadingConteudoExcluirAtendete';
export const ENVIAUIDATENDENTETELAALTERARATENDENTE = 'enviaUidAtendenteTelaAlterarAtendente';
export const MUDAVISIBILIDADEMODALEXCLUIRSETOR = 'mudaVisibilidadeModalExcluirSetor';
export const ATIVADESATIVALOADINGCONTEUDOEXCLUIRSETOR = 'ativaDesativaLoadingConteudoExcluirSetor';

export const ATIVADESATIVALOADINGTELARELATORIOATENDIMENTODETALHADOS = 'ativaDesativaLoadingTelaRelatorioAtendimentoDetalhados';
export const ENVIASEMCONEXAOINTERNETTELARELATORIODETALHADO = 'enviaSemConexaoInternetTelaRelatorioDetalhado';
export const ENVIAPERIODOPERSONALIZADORELATORIODETALHADI = 'enviaPeriodoPersonalizadoRelatorioDetalhado';


export const ENVIAATENDIMENTOSPORSTATUSRELATORIODETALHADO = 'enviaAtendimentosPorStatusRelatorioDetalhado';
export const ENVIATOTALATENDIMENTOSPORSTATUSRELATORIODETALHADO = 'enviaTotalAtendimentosPorStatusRelatorioDetalhado';

export const ENVIAATENDIMENTOSPORPRIORIDADERELATORIODETALHADO = 'enviaAtendimentosPorPrioridadeRelatorioDetalhado';
export const ENVIATOTALATENDIMENTOSPORPRIORIDADERELATORIODETALHADO = 'enviaTotalAtendimentosPorPRioridadeRelatorioDetalhado';

export const ENVIATEMPORESOLUCAOCHAMADOSRELATORIODETALHADO = 'enviaTempoResolucaoChamadosRelatorioDetalhado';
export const ENVIATOTALATENDIMENTOSPERIODORESOLUCAORELATORIODETALHADO = 'enviaTotalAtendimentosPeriodoResolucaoRelatorioDetalhado';

export const ENVIATOTALATENDIMENTOSPORLOCALRELATORIODETALHADO = 'enviaTotalAtendimentosPorLocalRelatorioDetalhado';
export const ENVIAATENDIMENTOSPORLOCALRELATORIODETALHADO = 'enviaAtendimentosPorLocalRelatorioDetalhado';

export const MODIFICACLIENTETELARELATORIODETALHADO = 'modificaClienteTelaRelatorioDetalhado';
export const MODIFICAATENDENTERELATORIODETALHADO = 'modificaAtendenteRelatorioDetalhado';
export const MODIFICASETORRELATORIODETALHADO = 'modificaSetorRelatorioDetalhado';

export const ENVIACLIENTEPARATELARELATORIODETALHADO = 'enviaClienteParaTelaRelatorioDetalhado';
export const LOADINGCLIENTETELARELATORIODETALHADO = 'loadingClienteTelaRelatorioDetalhado';

export const ENVIASETORTELARELATORIODETALHADO = 'enviaSetorTelaRelatorioDetalhado';
export const ATIVADESATIVALOADINGSETORTELARELATORIODETALHADO = 'ativaDesativaLoadingSetorTelaRelatorioDetalhado';

export const ATIVADESATIVALOADINGATENDENTERELATORIODETALHADO = 'ativaDesativaLoadingAtendenteRelatorioDetalhado';
export const ENVIAATENDENTERELATORIODETALHADO = 'enviaAtendenteRelatorioDetalhado';

export const ZERADATAINICIALFILTRORELATORIODETALHADO = 'zeraDataInicialFiltroRelatorioDetalhado';
export const ZERADATAFINALFILTRORELATORIODETALHADO = 'zeraDataFinalFiltroRelatorioDetalhado';

export const MODIFICAFILTROTELARELATORIODETALHADO = 'modificaFiltroTelaRelatorioDetalhado';

export const MODIFICAFILTROCLIENTESECTIONEDTELARELATORIODETALHADO = 'modificaFiltroClienteSectionedTelaRelatorioDetalhado';
export const MODIFICAFILTROATENDENTESECTIONEDTELARELATORIODETALHADO = 'modificaFiltroAtendenteSectionedTelaRelatorioDetalhado';
export const MODIFICAFILTROSETORSECTIONEDTELARELATORIODETALHADO = 'modificaFiltroSetorSectionedTelaRelatorioDetalhado';

export const MODIFICADATAFINALRELATORIODETALHADO = 'modificaDataFinalRelatorioDetalhado';
export const MODIFICADATAINICIALRELATORIODETALHADO = 'modificaDataInicialRelatorioDetalhado';

export const MENSAGEMPERIODOPERSONALIZADORELATORIODETALHADOERROR = 'mensagemPeriodoPersonalizadoRelatorioDetalhadoError';

export const ENVIATEMPOMEDIODESOLUCAOATENDIMENTOS = 'enviaTempoMedioDeSolucaoAtendimentos';

export const ENVIASEMCONEXAOINTERNETRELATORIOGERAL = 'enviaSemConexaoInternetRelatorioGeral';
export const ATIVADESATIVALOADINGRELATORIOGERAL = 'ativaDesativaLoadingRelatorioGeral';
export const ENVIAPERIODOPERSONALIZADORELATORIOGERAL = 'enviaPeriodoPersonalizadoRelatorioGeral';


export const ENVIACLIENTEMAISABRIRAMCHAMADOSRELATORIOGERAL = 'enviaClientesMaisAbriramChamadosRelatorioGeral';
export const ENVIAATENDENTEMAISABRIRAMCHAMADOSRELATORIOGERAL = 'enviaAtendentesMaisAbriramChamadosRelatorioGeral';
export const ENVIASETORESMAISABRIRAMCHAMADOSRELATORIOGERAL = 'enviaSetoresMaisAbriramChamadosRelatorioGeral';
export const ENVIATEMPOMEDIODESOLUCAOATENDIMENTOSRELATORIOGERAL = 'enviaTempoMedioDeSolucaoAtendimentosRelatorioGeral';
export const ENVIANOTAMEDIAAVALIACAORELATORIOGERAL = 'enviaNotaMediaAvaliacaoRelatorioGeral';

export const ZERADATAFINALFILTRORELATORIOGERAL = 'zeraDataFinalFiltroRelatorioGeral';
export const ZERADATAINICIALFILTRORELATORIOGERAL = 'zeraDataInicialFiltroRelatorioGeral';

export const MODIFICADATAINICIALRELATORIOGERAL = 'modificaDataInicialRelatorioGeral';
export const MODIFICADATAFINALRELATORIOGERAL = 'modificaDataFinalRelatorioGeral';

export const ATIVADESATIVALOADINGSEMINTERNETRELATORIOGERAL = 'ativaDesativaLoadingSemInternetRelatorioGeral';
export const MODIFICAVISIBILIDADEMODALINFORMACAORELATORIOGERAL = 'modificaVisibilidadeModalInformacaoRelatorioGeral';

export const ENVIATEXT2MODALINF = 'enviaText2ModalInf';
export const ENVIATEXT1MODALINF = 'enviaText1ModalInf';
export const ENVIATEXTCABECALHOMODALINF = 'enviaTextCabecalhoModalInf';

export const MODIFICAVISIBILIDADEMODALINFORMACAORELATORIODETALHADO = 'modificaVisibilidadeModalInformacaoRelatorioDetalhado';

export const ENVIATEXTCABECALHOMODALINFRELATORIODETALHADO = 'enviaTextCabecalhoModalInfRelatorioDetalhado';
export const ENVIATEXT1MODALINFRELATORIODETALHADO = 'enviaText1ModalInfRelatorioDetalhado';
export const ENVIATEXT2MODALINFRELATORIODETALHADO = 'enviaText2ModalInfRelatorioDetalhado';

export const ENVIACHAMADOSDETALHADOSPARADOWNLOAD = 'enviaChamadosDetalhadosParaDownload';
export const MODIFICAMENSAGEMGERANDORELATORIO = 'modificaMensagemGerandoRelatorio';

export const ENVIAPESSOASCOMMAISATENDIMENTOSPARADOWNLOAD = 'enviaPessoasComMaisAtendimentosParaDownload';

export const MODIFICAMENSAGEMGERANDORELATORIOVISAOGERAL = 'modificaMensagemGerandoRelatorioVisaoGeral';

export const ENVIADATAVALIDADEATUALPREMIUM = 'enviaDataValidadeAtualPremium';
export const ENVIANUMEROPEDIDOCOMPRAPREMIUM = 'enviaNumeroPedidoCompraPremium';
export const ENVIAPLANOPREMIUM = 'enviaPlanoPremium';
export const ENVIAPLATAFORMAPREMIUM = 'enviaPlataformaPremium';
export const ENVIAQUANTIDADEATENDENTELIBERADOPREMIUM = 'enviaQtdAtendentesLiberadosPremium';
export const ENVIASTATUSASSINATURAPREMIUM = 'enviaStatusAssinaturaPremium';

export const ATIVADESATIVALOADINGVERSAOPREMIUM = 'ativaDesativaLoadingRenderVersaoPremium';

export const ENVIACORTEXTSTATUSASSINATURA = 'enviaCorTextStatusAssinatura';
export const ENVIATEXTSTATUSASSINATURA = 'enviaTextStatusAssinatura';

export const MODIFICAVISIBLEMODALADIQUIRAVERSAOPREMIUM = 'modificaVisibleModalAdiquiraVersaoPremium';
export const MODIFICAVISIBLEMODALASSINATURASUSPENSA = 'modificaVisibleModalAssinaturaSuspensa';
export const MODIFICAVISIBLEMODALASSINATURAPAUSADA = 'modificaVisibleModalAssinaturaPausada';
export const MODIFICAVISIBLEMODALVERSAOPREMIUMESGOTOU = 'modificaVisibleModalVersaoPremiumEsgotou';

export const ENVIAIDENTIFICADORUNICACOMPRA = 'enviaIdentificadorUnicoDaCompra';

export const ENVIAEMAILASSINANTE = 'enviaEmailAssinante';
export const ENVIANOMEASSINANTE = 'enviaNomeAssinante';

export const ENVIAPREMIUMVERSAOGRATIS = 'enviaPremiumVersaoGartis';

export const ALTERAAGRUPAATENDENTESETOR = 'alteraAgrupaAtendenteSetor';
export const ALTERAATENDENTEVEAPENASPROPRIOCHAMADO = 'alteraAtendenteVeApenasProprioChamado';
export const ATIVADESATIVALOADINGCARREGANDOCONFIGURACOESGERAIS = 'ativaDesativaLoadingCarregandoCOnfiguracoesGerais';

export const VERIFICAATENDENTEVERAPENASPROPRIOCHAMADO = 'verificaAtendenteVeApenasProprioChamado';
export const VERIFICAAGRUPARATENDENTESETOR = 'verificaAgruparAtendenteSetor';

export const ENVIAATENDENTESECTIONEDVINCULAATENDENTESETOR = 'enviaAtendenteSectionedVinculaAtendenteSetor';
export const ATIVADESATIVALOADINGVINCULAATENDENTESETOR = 'ativaDesativaLoadingVinculaAtendenteSetor';
export const MODIFICAATENDENTEVINCULADOSELECIONADOSETOR = 'modificaAtendenteVinculadoSelecionado';

export const MODIFICANOMESETORSELECIONADO = 'modificaNomeSetorSelecionado';

export const MODIFICAMODALFILTROCHAMADO = 'modificaModalFiltroChamado';

export const MODIFICAFILTROCHAMADO = 'modificaFiltroChamado';
export const MODIFICASELECTTEXTCHAMADO = 'modificaSelectTextChamado';
export const MODIFICAPESQUISATEXTCHAMADO = 'modificaPesquisaTextChamado';

export const MODIFICAVALORARRAYSECTIONEDFILTROCHAMADO = 'modificaValorArraySectionedFiltroChamado';

export const MODIFICAFILTROSELECIONADOTELACHAMADOABERTO = 'modificaFiltroSelecionadoTelaChamadoAberto';
export const MODIFICAFILTROSELECIONADOTELACHAMADOEMATENDIMENTO = 'modificaFiltroSelecionadoTelaChamadoEmAtendimento';
export const MODIFICAFILTROSELECIONADOTELACHAMADOFECHADO = 'modificaFiltroSelecionadoTelaChamadoFechado';
export const MODIFICAFILTROSELECIONADOTELACHAMADOCANCELADO = 'modificaFiltroSelecionadoTelaChamadoCancelado';
export const MODIFICAFILTROSELECIONADOTELACHAMADOTODOS = 'modificaFiltroSelecionadoTelaChamadoTodos';

export const ATIVADESATIVALOADINTESTETSTE = 'ATIVADESATIVALOADINTESTETESTE';
export const VERIFICACRIARCHAMADOPROBLEMAESTATICO = 'verificaCriarChamadoProblemaEstatico';
export const ALTERAVALORSWITCHCONFIGCHAMADOESTATICO = 'alteraValorSwitchConfigChamadoEstatico';
export const MODIFICADESCRICAOPROBLEMAESTATICO = 'modificaDescricaoProblemaEstatico';
export const ATIVADESATIVALOADINGBOTAONOVOPROBLEMA = 'ativaDesativaLoadingBotaoNovoProblema';

export const ENVIAPROBLEMAPARAPROBLEMASELECIONADO = 'enviaProblemaParaProblemaSelecionado';
export const ENVIAPROBLEMAPARAREDUCER = 'enviaProblemasParaReducer';
export const ATIVADESATIVALOADINGCARREGANDOPROBLEMAS = 'ativaDesativaLoadingCarregandoProblemas';

export const ENVIASPROBLEMASMAISABRIRAMCHAMADOSRELATORIOGERAL = 'enviaProblemasMaisAbriramChamadosRelatorioGeral';
export const MODIFICATOASTACOESATNEDIMENTO = 'zeraToastAcoesAtendimento';
export const ENVIASEATENDENTEEHADMINISTRADOR = 'enviaSeAtendenteEhAdministrador';

export const MODIFICACORBORDAEMAILLOGIN = 'modificaCorBordaInputEmailLogin';
export const MODIFICACORBORDASENHALOGIN = 'modificaCorBordaInputSenhaLogin';

export const MODIFICAERRORSENHALOGIN = 'modificaErrorSenhaLogin';

export const MODIFICACORICONEEMAILLOGIN = 'modificaCorIconeInputEmailLogin';
export const MODIFICACORICONESENHALOGIN = 'modificaCorIconeInputSenhaLogin';

export const MODIFICACORICONEINPUTEMPRESACADASTRO = 'modificaCorIconeInputEmpresaCadastro';
export const MODIFICACORBORDAEMPRESACADASTRO = 'modificaCorBordaInputEmpresaCadastro';

export const MODIFICACORICONEINPUTEMAILCADASTRO = 'modificaCorIconeInputEmailCadastro';
export const MODIFICACORBORDAEMAILCADASTRO = 'modificaCorBordaInputEmailCadastro';

export const MODIFICACORICONENOMECADASTRO = 'modificaCorIconeInputNomeCadastro';
export const MODIFICACORBORDANOMECADASTRO = 'modificaCorBordaInputNomeCadastro';

export const MODIFICACORICONESENHACADASTRO = 'modificaCorIconeInputSenhaCadastro';
export const MODIFICACORBORDASENHACADASTRO = 'modificaCorBordaInputSenhaCadastro';

export const MODIFICACORICONECONFIRMASENHACADASTRO = 'modificaCorIconeInputConfirmaSenhaCadastro';
export const MODIFICACORBORDACONFIRMASENHACADASTRO = 'modificaCorBordaInputConfirmaSenhaCadastro';

export const MODIFICAMENSAGEMERRORINPUTEMPRESACADASTRO = 'modificaMensagemErrorInputEmpresaCadastro';
export const MODIFICAMENSAGEMERRORINPUTEMAILCADASTRO = 'modificaMensagemErrorInputEmailCadastro';
export const MODIFICAMENSAGEMERRORINPUTNOMECADASTRO = 'modificaMensagemErrorInputNomeCadastro';
export const MODIFICAMENSAGEMERRORINPUTSENHACADASTRO = 'modificaMensagemErrorInputSenhaCadastro';
export const MODIFICAMENSAGEMERRORINPUTCONFIRMASENHACADASTRO = 'modificaMensagemErrorInputConfirmaSenhaCadastro';

export const MODIFICANOMEROTANOHEADER = 'modificaNomeRotaNoHeader';
export const MODIFICACORBORDALISTATAREFAS = 'modificaCorBordaListaTarefas';

export const MODIFICAPESQUISATESTE = 'modificaPesquisaTeste';

export const MODIFICAVISIBILIDADEMODALNOVOATENDIMENTO = 'modificaVisibilidadeModalNovoAtendimento';

export const VISIBLESELECTSEARCH = 'visibleSelectSearch';
export const MODIFICAVISIBILIDADEMODALALTERARATENDIMENTO = 'modificaVisibilidadeModalAlterarAtendimento';

export const MODIFICAVISIBILIDADEMODALATRIBUIRATENDIMENTO = 'modificaVisibilidadeModalAtribuirAtendimento';
export const MODIFICAVISIBILIDADEMODALADDTEMPOGASTOATENDIMENTO = 'modificaVisibilidadeModalAddTempoGastoAtendimento';

export const MODIFICAVISIBILIDADEMODALFINALIZARATENDIMENTO = 'modificaVisibilidadeModalFinalizarAtendimento';
export const MODIFICAVISIBILIDADEMODALPRIORIDADEATENDIMENTO = 'modificaVisibilidadeModalPrioridadeAtendimento';
export const MODIFICAVISIBILIDADEMODALCANCELARATENDIMENTO = 'modificaVisibilidadeModalCancelarAtendimento';

export const MODIFICAFILTROPORDATADASHBOARD = 'modificaFiltroPorDataDashboard';
export const MODIFICAVISIBLIDADEBOTAOADDVENCIMENTO = 'modificaVisibilidadeBotaoAddVencimento';

export const MODIFICAFILTROCLIENTESTELACLIENTES = 'modificaFiltroClientesTelaClientes';
export const MODIFICAVISIBLEMODALNOVOCLIENTE = 'modificaVisibleModalNovoCLiente';
export const MODIFICAFILTROTELAATENDENTES = 'modificaFiltroClientesTelaAtendentes';
export const MODIFICAVISIBLEMODALNOVOATENDENTE = 'modificaVisibleModalNovoAtendente';
export const MODIFICAFILTROSETORESTELASETORES = 'modificaFiltroSetoresTelaSetores';
export const MODIFICAVISIBLEMODALNOVOSETOR = 'modificaVisibleModalNovoSetor';
export const MODIFICAVISIBLEMODALNOVOAVISO = 'modificaVisibleModalNovoAviso';

export const MODIFICAFILTROSELECIONADORELATORIODETALHADO = 'modificaFiltroSelecionadoRelatorioDetalhado';

export const MODIFICAKEYAVALIACAO = 'modificaKeyAvaliacao';
export const MODIFICAFILTROAVALIACAO = 'modificaFiltroAvaliacao';

export const MODIFICAFILTRORELATORIOAVALIACAOGERAL = 'modificaFiltroRelatorioAvaliacaoGeral';

export const MODIFICACORFUNDOPRINCIPAL = 'modificaCorFundoPrincipal';
export const MODIFICACORFUNDOSECUNDARIO = 'modificaCorFundoSecundario';
export const MODIFICACORFUNDOITENSSELECIONAVEIS = 'modificaCorFundoItensSelecionaveis';
export const MODIFICACORFUNDOBOTAOPRINCIPAL = 'modificaCorFundoBotaoPrincipal';
export const MODIFICACORFUNDOBOTAOSECUNDARIO = 'modificaCorFundoBotaoSecundario';
export const MODIFICACORLETRAPADRAO = 'modificaCorLetraBotao';
export const MODIFICACORLETRAPRINCIPAL = 'modificaCorLetraPrincipal';
export const MODIFICACORLETRASECUNDARIA = 'modificaCorLetraSecundaria';
export const MODIFICACORLETRADESCRICAODOSCAMPOS = 'modificaCorDescricaoDosCampos';

export const MODIFICACORLETRAMENU = 'modificaCorLetraMenu';
export const MODIFICACORFUNDOMENU = 'modificaCorFundoMenu';

export const MODIFICACHECKBOXCONTINUARCONECTADO = 'modificaCheckBoxContinuarConectado';

export const MODIFICAMODALESQUECISENHA = 'modificaModalEsqueciSenha';

export const ENVIAATENDIMENTOABERTO = 'enviaAtendimentoAberto';
export const ENVIAATENDIMENTOEMATENDIMENTO = 'enviaAtendimentoEmAtendimento';
export const ENVIAATENDMENTOCANCELADO = 'enviaAtendimentoCancelado';
export const ENVIAATENDIMENTOFECHADO = 'enviaAtendimentoFechado';

export const ENVIATAREFAPARAREDUCER = 'enviaTarefaParaReducer';
export const MODIFICAMODALSAIR = 'modificaModalSair';
export const MODIFICABERTURAMENU = 'modificaAberturaMenu';

export const MODIFICATABINDEX = 'modificaTabIndex';

export const MODIFICAARQUIVOCOMOPLETOPARAUPAR = 'modificaArquivoCompletoParaUpar';
export const MODIFICANOMECLIENTESELECIONADOTELAALTERARCLIENTENOVOCADASTRO = 'modificaNomeClienteSelecionadoTelaAlterarClienteNovoCadastro';
export const MODIFICAARQUIVOCOMOPLETOPARAUPARNOVOCADASTRO = 'modificaArquivoCompletoParaUparNovoCadastro';

export const MODIFICAARQUIVOCOMOPLETOATENDENTEPARAUPAR = 'modificaArquivoCompletoAtendenteParaUpar';


export const ALTERASWITCHPERMISSAOALTERARADMINISTRADORNOVOCADASTRO = 'alteraSwitchPermissaoAlterarAdministradorNovoCadastro';
export const ALTERASWITCHPERMISSAOALTERARCLIENTENOVOCADASTRO = 'alteraSwitchPermissaoAlterarClienteNovoCadastro';
export const ALTERASWITCHPERMISSAOALTERARATENDENTENOVOCADASTRO = 'alteraSwitchPermissaoAlterarAtendenteNovoCadastro';
export const ALTERASWITCHPERMISSAOALTERARSETORESNOVOCADASTRO = 'alteraSwitchPermissaoAlterarSetoresNovoCadastro';
export const ALTERASWITCHPERMISSAOALTERARAVISOSNOVOCADASTRO = 'alteraSwitchPermissaoAlterarAvisosNovoCadastro';
export const ALTERASWITCHPERMISSAOACESSORELATORIOSNOVOCADASTRO = 'alteraSwitchPermissaoAcessoRelatoriosNovoCadastro';
export const MODIFICANOMEATENDENTENOVOCADASTRO = 'modificaNomeAtendenteNovoCadastro';
export const MODIFICAEMAILACESSOAPPNOVOCADASTRO = 'modificaEmailAcessoAppNovoCadastro';
export const MODIFICASENHAATENDENTENOVOCADASTRO = 'modificaSenhaAtendenteNovoCadastro';
export const MODIFICACONFIRMASENHAATENDENTENOVOCADASTRO = 'modificaConfirmaSenhaAtendenteNovoCadastro';

export const MODIFICAARQUIVOCOMOPLETOATENDENTEPARAUPARNOVOCADASTRO = 'modificaArquivoCompletoAtendenteParaUparNovoCadastro';

export const MODIFICANOMESETORNOVOCADASTRO = 'modificaNomeSetorNovoCadastro';
export const MODIFICAOBSERVACAONOVOCADASTRO = 'modificaObservacaoSetorNovoCadastro';
export const MODIFICAATENDENTEVINCULADOSELECIONADOSETORNOVOCADASTRO = 'modificaAtendenteVinculadoSelecionadoNovoCadastro';


export const MODIFICADESTINATARIOSELECIONADONOVOCADASTRO = 'modificaDestinatarioSelecionadoNovoCadastro';
export const MODIFICAATENDENTESELECIONADONOVOAVISONOVOCADASTRO = 'modificaAtendenteSelecionadoNovoCadastro';
export const MODIFICAClIENTESELECIONADONOVOAVISONOVOCADASTRO = 'modificaClienteSelecionadoNovoCadastro';
export const ALTERARVALORSWITCHNOVOAVISONOVOCADASTRO = 'alteraValorSwitchNovoAvisoNovoCadastro';
export const MODIFICADATAINICIALNOVOAVISONOVOCADASTRO = 'modificaDataInicialNovoAvisoNovoCadastro';
export const MODIFICADATAFINALNOVOAVISONOVOCADASTRO = 'modificaDataFinalNovoAvisoNovoCadastro';
export const ALTERADESCRICAOMENSAGEMNOVOAVISONOVOCADASTRO = 'alteraDescricaoMensagemNovoAvisoNovoCadastro';

export const ENVIAIMAGEMPERFILCLIENTETELADETALHESAVALIACAO = 'enviaImagemPerfilClienteTelaDetalhesAvaliacao';

export const MODIFICAFILTROAVALIACAOWEB = 'modificaFiltroAvaliacaoWeb';

export const MODIFICAIMAGEMCOMPLETALOGOTIPOCONFIGURACAO = 'modificaImagemCompletaLogoTipoConfiguracao';
export const MODIFICALOGOTIPOTELACONFIGURACAO = 'modificaLogoTipoTelaConfiguracao';
export const MODIFICAMOSTRARMAISCHAMADOS = 'modificaMostrarMaisChamados';
export const MODIFICARETORNATELACLIENTESPARANOVOATENDIMENTO = 'modificaRetornaTelaClienteParaNovoAtendimento';
export const ENVIAKEYUSUARIOPERFIL = 'enviaKeyUsuarioPerfil';
export const ACESSOUMEUPERFIL = 'acessouMeuPErfil';
export const MODIFICALOADINGCONFIGURACOESEMPRESA = 'modificaLoadingConfiguracoesEmpresa';
export const ENVIANOTIFICACOESPARAREDUCER = 'enviaNotificacoesParaReducer';
export const ENVIATOTALNOTIFICACOESPARAREDUCER = 'enviaTotalNotificacoesParaReducer';
export const MODIFICAMODALDUVIDASFREQUENTES = 'modificaModalDuvidasFrequentes';
export const ATIVADESATIVARENOVARPLANO = 'ativaDesativaRenovarPLano';
export const MODIFICAMOSTRAVERSAOEXPIRANDO = 'modificaMostraVersaoExpirando';
export const MODIFICALICENSAWEBAPP = 'modificaLicensawebouapp';

export const ENVIAATENDIMENTOEMESPERATENDIMENTOONLINE = 'enviaAtendimentoEmEsperaAtendimentoOnline';
export const MODIFICAKEYATENDIMENTOEMESPERAATENDIMENTOONLINE = 'modificaKeyAtendimentoEmEsperaAtendimentoOnline';
export const MODIFICAATENDIMENTOEMESPERASELEECIONADO = 'modificaAtendimentoEsperaSelecionado';
export const MODIFICACHAMADOSEMATENDIMENTOVIACHAT = 'modificaChamadosEmAtendimentoViaChat';
export const MODIFICAKEYATENDIMENTOEMTENDIMENTOONLINE = 'modificaKeyAtendimentoEmAtendimentoOnline';
export const ENVIAACOESATENDIMENTOPARAREDUCERCHAT = 'enviaAcoesAtendimentoParaReducerChat';
export const ENVIAATENDIMENTOPARAREDUCERCHAT = 'enviaAtendimentoParaReducerChat';
export const MODIFICAVISIBILIDADEMODALATENDIMENTOONLINE = 'modificaVisibilidadeModalAtendimentoOnline';
export const ALTERACOMENTARIOCHAT = 'alteraComentarioChat';
export const MODIFICATEXTARQUIVOCHAT = 'modificaTextArquivoChat';
export const MODIFICAUPANDOARQUIVOCHAT = 'modificaUpandoARquivoChat';
export const ENVIAATENDIMENTOPORKEYREDUCERCHAT = 'enviaAtendimentosPorKeyReducerChat';
export const MODIFICALIDOENAOLIDOATENDIMENTOPORKEYREDUCERCHAT = 'modificaLidoEnaoLidoAtendimentoPorKeyReducerChat';
export const ATIVADESATIVALOADINGCHATONLINE = 'ativaDesativaLoadingChatOnline';


export const ALTERAVALORSWITCHUTILIZARCHAT = 'alteraValorSwitchUtilizarChat';
export const ALTERAVALORSWITCHUTILIZAR2HORARIOSCHAT = 'alteraValorSwitcUtilizar2HorariosChat';
export const ALTERAVALORCHECKSEGUNDAEIRA = 'alteraValorCheckSegundaFeira';

export const MODIFICAHORARIOOPERACAOSEGUNDAFEIRA2 = 'modificaHorarioOperacaoSegundaFeira2';
export const MODIFICAHORARIOOPERACAOSEGUNDAFEIRA = 'modificaHorarioOperacaoSegundaFeira';
export const MODIFICAHORARIOOPERACAOTERCAFEIRA2 = 'modificaHorarioOperacaoTercaFeira2';
export const MODIFICAHORARIOOPERACAOTERCAFEIRA = 'modificaHorarioOperacaoTercaFeira';
export const MODIFICAHORARIOOPERACAOQUARTAFEIRA2 = 'modificaHorarioOperacaoQuartaFeira2';
export const MODIFICAHORARIOOPERACAOQUARTAFEIRA = 'modificaHorarioOperacaoQuartaFeira';
export const MODIFICAHORARIOOPERACAOQUINTAFEIRA2 = 'modificaHorarioOperacaoQuintaFeira2';
export const MODIFICAHORARIOOPERACAOQUINTAFEIRA = 'modificaHorarioOperacaoQuintaFeira';
export const MODIFICAHORARIOOPERACAOSEXTAFEIRA2 = 'modificaHorarioOperacaoSextaFeira2';
export const MODIFICAHORARIOOPERACAOSEXTAFEIRA = 'modificaHorarioOperacaoSextaFeira';
export const MODIFICAHORARIOOPERACAOSABADOFEIRA2 = 'modificaHorarioOperacaoSabadoFeira2';
export const MODIFICAHORARIOOPERACAOSABADOFEIRA = 'modificaHorarioOperacaoSabadoFeira';
export const MODIFICAHORARIOOPERACAODOMINGOFEIRA2 = 'modificaHorarioOperacaoDomingoFeira2';
export const MODIFICAHORARIOOPERACAODOMINGOFEIRA = 'modificaHorarioOperacaoDomingoFeira';


export const ALTERAVALORCHECKTERCAEIRA = 'alteraValorCheckTercaFeira';
export const ALTERAVALORCHECKQUARTAEIRA = 'alteraValorCheckQuartaFeira';
export const ALTERAVALORCHECKQUINTAEIRA = 'alteraValorCheckQuintaFeira';
export const ALTERAVALORCHECKSEXTAEIRA = 'alteraValorCheckSextaFeira';
export const ALTERAVALORCHECKSABADOEIRA = 'alteraValorCheckSabadoFeira';
export const ALTERAVALORCHECKDOMINGOEIRA = 'alteraValorCheckDomingoFeira';

export const ATIVADESATIVALOADINGBOTAOALTERARCONIGCHATONLINE = 'ativaDesativaLoadingBotaoAlterarConfigChatOnline';
export const MODIFICAFUSOHORARIOSELECIONADO = 'modificaFusoHorarioSelecionado';

export const MODIFICACORPRINCIPALCHAT = 'modificaCorPrincipalChat';
export const ENVIARESPOSTASSELECIONADASPARAREDUCER = 'enviaRespostasSelecionadasParaReducer';
export const ENVIARESPOSTAPADROESPARAREDUCER = 'enviaRespostaPadroesParaReducer';
export const MODIFICADESCROCAORESPOSTAPADRAO = 'modificaDescricaoRespostaPadrao';
export const MODIFICAMENSAGEMRESPOSTAPADRAO = 'modificaMensagemRespostaPadrao';
export const ATIVADESATIVALOADINGBOTAONVOARESPOSTA = 'ativaDesativaLoadingBOtaoNovaResposta';
export const MODIFICARESPOSTASELECIONADA = 'modificaRespostaSelecionada';
export const ENVIARESPOSTAPARAACOESCHAMADO = 'enviaRespostaParaAcoesChamado';
export const ENVIASEUTILIZACHATATENDIMENTOONLINE = 'enviaSeUtilizaChatAtendimentoOnline';
export const MODIFICAVISIBILIDADEMODALPORTALCLIENTE = 'modificaVisibilidadeModalPortalCliente';
export const MODIFICAVISIBILIDADEMODALIMPORTARCLIENTES = 'modificaVisibilidadeModalImportarClientes';
export const MODIFICALOADINGCADASTRANDOCLIENTEEMMASSA = 'modificaLoadingCadastrandoClienteEmMassa';
export const MODIFICACLICOUCFGCHAT = 'modificaClicouCFGchat';
export const MODIFICAISADMIN = 'modificaIsAdmin';
export const ZERAINFORMACOESENVIOARQUIVOCHAT = 'zeraInformacoesEnvioArquivoChat';
export const ATIVADESATIVALOADINGMAISRESULTADOSCHAMADOSCLIENTE = 'ativaDesativaLoadingMaisResultadosChamadosCliente';
export const ATIVADESATIVALOADINGENVIANDOAUDIOCHAT = 'ativaDesativaLoadingEnviandoAudioChat';
export const ENVIAADMINPARAREDUCER = 'enviaAdminParaReducer';

export const MODIFICARESOLUCAOPROBLEMA = 'modificaResolucaoProblema';

export const ENVIATEMPOMEDIORESPOSTAATENDIMENTOS = 'enviaTempoMedioRespostaAtendimentos';
export const MODIFICASEGMENTOCADASTRO = 'modificaSegmentoCadastro';
export const MODIFICAMOTIVOCANCELAMENTOTELAVERSAOPREMIUM = 'modificaMotivoCancelamentoTelaVersaoPremium';


export const ENVIACLIENTESATIVOSGRAFICO = 'enviaClientesAtivosGrafico';
export const ENVIACLIENTESVIAANUNCIO = 'enviaClientesViaAnuncio';
export const ENVIATIPOPLANOGRAFICO = 'enviaTipoPlanoGrafico';
export const ENVIAFORMAPAGAMENTOGRAFICO = 'enviaFormaPagamentoGrafico';
export const ENVIAASSINADOVIAWEBAPPGRAFICO = 'enviaAssinadoViaWebAppGrafico';

export const MODIFICADATAINICIALRELATORIOCRM = 'modificaDataInicialRelatorioCRM';
export const MODIFICADATAFINALRELATORIOCRM = 'modificaDataFinalRelatorioCRM';

export const ENVIAPLANOSMAISASSINADOS = 'enviaPlanosMaisAssinados';
export const ENVIAESTADOSCOMAMAISCLIENTES = 'enviaEstadosComMaisClientes';
export const ENVIASEGMENTOSCOMMAISCLIENTES = 'enviaSegmentosComMaisClientes';
export const ATIVADESATIVALOADINGRELATORIOCRM = 'ativaDesativaLoadingRelatorioCRM';

export const ENVIAPLANOSMAISASSINANTESPRABAIXAR = 'enviaPlanosMaisAssinantesPraBaixar';
export const ALTERASWITCHNOTIFCOMENTARIOINTERNO = 'alteraSwitchNotifComentarioInterno';
export const MODIFICAPERMITECLIENTESELECIONARSETOR = 'modificaPermiteClienteSelecionarSetor';
export const MODIFICAPERMITECLIENTESELECIONARSETORCADASTRO = 'modificaPermiteClienteSelecionarSetorCadastro';

export const MODIFICASWITCHADMVISUALIZAAPENASCHAMADOSETOR = 'modificaSwitchAdmVisualizaApenasChamadoSetor';

export const ENVIAADMVISUALIZAAPENASCHAMADOSDOSETORVINCULADO = 'enviaAdmVisualizaApenasChamadosDoSetorVinculado';

export const ENVIAULTIMONOMEREFENCIATELACONSULTACLIENTE = 'enviaUltimoNomeReferenciaTelaConsultaCliente';
export const ENVIACONVERSASPARACHATEMESPERA = 'enviaConversasParaChatEmEspera';
export const ENVIASESSIONCLIENT = 'enviaSessionClient';
export const ENVIASTATUSCONNECT = 'enviaStatusConnect';
export const ENVIAQRCODE = 'enviaQrCode';
export const ENVIADADOSWHATSAPPCONFIG = 'enviaDadosWhatsappConfig';

export const ENVIAATENDIMENTOPORKEYREDUCERCHATEMESPERA = 'enviaAtendimentosPorKeyReducerChatEmEspera';

export const ENVIACONTATOSPARAREDUCER = 'enviaContatosParaReducer';
export const MODIFICAKEYCONTACTS = 'modificaKeyContacts';
export const MODIFICADADOSCONTACTSSELECIONADO = 'modificaDadosContactsSelecionado';

export const ENVIACONVERSASULTIMOCHAMADO = 'enviaConversasUltimoChamado';
export const ATIVADESATIVALOADINGCARREGACHAMADOSANTIGOS = 'ativaDesativaLoadingCarregaChamadosAntigos';
export const ENVIAULTIMAREEFERENCIAKEYCONTACTS = 'enviaUltimaReeferenciaKeyContacts';
export const ATIVADESATIVALOADINGCARREGAMAISCHAMADOSANTIGOS = 'ativaDesativaLoadingCarregaMaisChamadosAntigos';

export const ATIVADESATIVALOADINGINICIANDOCONVERSACONTACTS = 'ativaDesativaLoadingIniciandoCOnversaContacts';

export const ATIVADESATIVALOADINGCARREGANDOCHAMADOSEMATENDIMENTO = 'ativaDesativaLoadingCarregandoChamadosEmAtendimento';

export const ATIVADESATIVALOADINGCARREGACONTATOSWPP = 'ativaDesativaLoadingCarregaContatosWpp';

export const INSEREMENSAGEMERRORGETCONTACTS = 'insereMensagemErrorGetContacts';

export const ATIVADESATIVALOADINGCARREGACHAMADOSANTIGOSEMESPERA = 'ativaDesativaLoadingCarregaChamadosAntigosEmEspera';
export const ENVIAULTIMAREEFERENCIAKEYEMESPERA = 'enviaUltimaReeferenciaKeyEmEspera';
export const ENVIACONVERSASCARREGADASEMESPERA = 'enviaConversasCarregadasEmEspera';

export const ATIVADESATIVALOADINGCARREGACHAMADOSANTIGOSEMATENDIMENTO = 'ativaDesativaLoadingCarregaChamadosAntigosEmAtendimento';
export const ENVIAULTIMAREEFERENCIAKEYEMATENDIMENTO = 'enviaUltimaReeferenciaKeyEmAtendimento';
export const ENVIACONVERSASCARREGADASEMATENDIMENTO = 'enviaConversasCarregadasEmAtendimento';

export const ATIVADESATIVALOADINGCARREGARESPOSTAANTIGAS = 'ativaDesativaLoadingCarregaRespostasAntigas';
export const ENVIAIDRESPOSTAANTIGA = 'enviaIdRespostaAntiga';
export const MODIFICANOMECLIENTETELACHATONLINE = 'modificaNomeClienteTelaChatOnline';


export const MODIFICAARQUIVOCOMOPLETOPARAUPARNOVOCONTATO = 'modificaArquivoCompletoParaUparNovoContato';
export const INSEREFOTOCADASTROCONTATO = 'insereFotoCadastroContato';
export const MODIFICAVISIBILIDADEMODALADICIONARCONTATO = 'modificaVisibilidadeModalAdicionarContato';
export const MODIFICATELEFONECONTATO = 'modificaTelefoneContato';
export const MODIFICANOMECONTATO = 'modificaNomeContato';
export const MODIFICALOADINGCADASTRANOVOCONTATO = 'modificaLoadingCadastraNovoContato';
export const ZERAINFORMACOESCADASTROCONTATO = 'zeraInformacoesCadastroContato';


export const MODIFICAMENSAGEMBOASVINDASWPP = 'modificaMensagemBoasVindasWpp';
export const MODIFICAMENSAGEMESCOLHADEPARTAMENTO = 'modificaMensagemEscolhaDepartamento';
export const MODIFICAMENSAGEMFILAATENDIMENTO = 'modificaMensagemFilaAtendimento';
export const MODIFICAMENSAGEMFINALIZACAOCHAMADO = 'modificaMensagemFinalizacaoChamado';
export const MODIFICAMENSAGEMFORAHORARIOOPERACAO = 'modificaMensagemForaHorarioOperacao';
export const ATIVADESATIVALOADINGSALVARCONFIGWHATSAPP = 'ativaDesativaLoadingSalvarConfigWhatsApp';
export const MODIFICAMENSAGEMFINALIZAATENDIMENTOWPP = 'modificaMensagemFinalizaAtendimentoWpp';

export const MODIFICALIMITEDEESPERAFILACHAT = 'modificaLimiteDeEsperaFilaChat';

export const ALTERAVALORSWITCHENVIATENDIMENTOPARASETORAPOSLIMITE = 'alteraValorSwitchEnviaAtendimentoParaSetorAposLimite';
export const ENVIASETORPARACONFIGURACAOLIMITE = 'enviaSetorParaConfiguracaoLimite';
export const MODIFICAKEYSETORSELECIONADOPARAENVIARATENDIMENTOAPOSLIMITE = 'modificaKeySetorSelecionadoParaEnviarAtendimentoAposLimite';

export const MODIFICATITLETAB = 'modificaTitleTab';

export const ATIVADESATIVALOADINGCARREGANDOCONVERSASCHATATUALA = 'ativaDesativaLoadingCarregandoConversasChatAtual';
export const ENVIAULTIMAKEYREFERENCIACONVERSASCARREGADASCHAT = 'enviaUltimaKeyReferenciaCOnversasCarregadasChat';

export const ATIVADESATIVALOADINGCARREGANDOCONVERSASCHATATUALAEMEPSERA = 'ativaDesativaLoadingCarregandoConversasChatAtualEmEspera';
export const ENVIAULTIMAKEYREFERENCIACONVERSASCARREGADASCHATEMESPERA = 'enviaUltimaKeyReferenciaCOnversasCarregadasChatEmEspera';

export const ENVIAREFERENCIAULTIMAKEYACOESCHAMADOACOESCHAMADO = 'enviaReferenciaultimaKeyAcoesChamadoAcoesChamado';
export const ATIVADESATIVALOADINGCARREGANDOMAISACOESCHAMADOACOESCHAMADO = 'ativaDesativaLoadingCarregandoMaisAcoesChamadoAcoesChamado';
export const ENVIACONVERSASANTIGASCHATACOESCHAMADO = 'enviaConversasAntigasChatAcoesChamado';
export const ATIVADESATIVALOADINGCARREGARESPOSTAANTIGASACOESCHAMADO = 'ativaDesativaLoadingCarregaRespostasAntigasAcoesChamado';
export const ENVIAIDRESPOSTAANTIGAACOESCHAMADO = 'enviaIdRespostaAntigaAcoesChamado';

export const MODIFICAOBRIGATORIORESOLUCAOCHAMADO = 'modificaObrigatorioResolucaoChamado';
export const MODIFICAOBRIGATORIOINFORMARRESOLUCAOCHAMADODASHBOARD = 'modificaObrigatorioInformarResolucaoChamadoDashboard';

export const ENVIAARRAYDEDADOSDAEMPRESAPAINELADM = 'enviaArrayDeDadosDaEmpresaPainelAdm';


export const MODIFICAMEMORIACONTAINERWPP = 'modificamemoriaContainerWpp';
export const MODIFICASTATUSCONTAINERWPP = 'modificastatusContainerWpp';
export const MODIFICACONFIGURACOESINTERNASWPP = 'modificaconfiguracoesInternasWpp';
export const MODIFICASTATUSCONEXAOWPPPAINELADM = 'modificastatusConexaoWppPainelAdm';

export const MODIFICANIVELBATERIA = 'modificanivelBateria';
export const MODIFICAMODELOCELULAR = 'modificamodeloCelular';
export const MODIFICAVERSAOOS = 'modificaversaoOS';

export const MODIFICALOADINGCARREGANDOCHAMADOSEMESPERAREFRESHCONTROL = 'modificaloadingCarregandoChamadosEmEsperaRefreshControl';
export const MODIFICACHAMADOSCARREGADOSEMESPERA = 'modificachamadosCarregadosEmEspera';
export const MODIFICAKEYREFERENCIAULTIMOCHAMADOEMESPERA = 'modificakeyReferenciaUltimoChamadoEmEspera';

export const MODIFICACONTAINERLISTAEMPRESA = 'modificaContainerListaEmpresa';
export const MODIFICAURLAPILISTAEMPRESA = 'modificaUrlApiListaEmpresa';
export const MODIFICANUMEROCONECTADOWPP = 'modificaNumeroConectadoWpp';

export const MODIFICADESATIVARASSISTENTEVIRTUALWPP = 'modificaDesativarAssistenteVirtualWpp';
export const MODIFICAMOSTRARNOMEATENDENTEMENSAGEM = 'modificaMostrarNomeAtendenteMensagem';


export const MODIFICADESATIVARASSISTENTEVIRTUALBOTWPP = 'modificadesativarAssistenteVirtualBotWpp';
export const MODIFICAMOSTRARNOMEATENDENTEWPP = 'modificamostrarNomeAtendenteWpp';

export const MODIFICATIMEZONECHATONLINE = 'modificaTimeZoneChatOnline';


export const MODIFICASOLICITARNOMECLIENTEPRIMEIROCONTATO = 'modificaSolicitarNomeClientePrimeirocontato';

export const MODIFICADESCROCAORESPOSTAPADRAOALTERAR = 'modificaDescricaoRespostaPadraoAlterar';
export const MODIFICAMENSAGEMRESPOSTAPADRAOALTERAR = 'modificaMensagemRespostaPadraoAlterar';

export const MODIFICAVISIBILIDADEMODALEXPORTARCLIENTES = 'modificaVisibilidadeModalExportarClientes';

export const MODIFICACLIENTESEXPORTARCLIENTES = 'modificaClientesExportarClientes';

export const MODIFICAMODALRESPOSTAPADRAO = 'modificaModalRespostaPadrao';

export const MODIFICAMOTIVOCANCELAMENTOCONFIG = 'modificaMotivoCancelamentoConfig';

export const MODIFICAINFORMARMOTIVOCANCELAMENTO = 'modificaInformarMotivoCancelamento';

export const MODIFICAPERMISSAOCANCELARATENDIMENTONOVOCADASTRO = 'modificaPermissaoCancelarAtendimentoNovoCadastro';
export const ALTERASWITCHPERMISSAOCANCELARATENDIMENTO = 'alteraSwitchPermissaoCancelarAtendimento';

export const VERIFICACRIARCHAMADOPROBLEMAESTATICOATENDIMENTO = 'verificaCriarChamadoProblemaEstaticoAtendimento';

export const MODIFICANOMEPROBLEMAESTATICOSELECIONADONOVOATENDIMENTO = 'modificaNomeProblemaEstaticoSelecionadoNovoAtendimento';
export const MODIFICAPROBLEMAESTATICOSELECIONADONOVOATENDIMENTO = 'modificaProblemaEstaticoSelecionadoNovoAtendimento';

export const MODIFICAATENDENTEVEAPENASPROPRIOHISTORICO = 'modificaAtendenteVeApenasProprioHistorico';
export const MODIFICAATENDENTEVEAPENASPROPRIOHISTORICOCHAMADO = 'modificaAtendenteVeApenasProprioHistoricoChamado';

export const ENVIANOVASATIVACOESNOPERIODO = 'enviaNovasAtivacoesNoPeriodo';

export const ENVIASEGMENTOSCOMMAISCANCELAMENTOS = 'segmentosComMaisCancelamentos';

export const MODIFICAMODALPREVIEWIMAGE = 'modificaModalPreviewImage';
export const MODIFICAURLPREVIEWIMAGE = 'modificaUrlPreviewImage';
export const MODIFICANAMEPREVIEWIMAGE = 'modificaNamePreviewImage';

export const MODIFICADATACONEXAO = 'modificaDataConexao';

export const MODIFICALOADINGBOTAOATUALIZASTATUSCONEXAOWPP = 'modificaloadingBotaoAtualizaStatusConexaoWpp';
export const MODIFICAINFOUSUARIOPAINELADM = 'modificaInfoUsuarioPainelAdm';
export const ALTERAVALORSWITCHUTILIZAVERSAOBETA = 'alteraValorSwitchUtilizaVersaoBETA';

export const MODIFICAENVIARMENSAGEMTRANSSETORWPPCONFIG= 'modificaEnviarMensagemTransSetorWppConfig';
export const MODIFICAENVIARMENSAGEMTRANSSETORWPP = 'modificaEnviarMensagemTransSetorWpp';

export const MODIFICAVISIBILIDADEMODALALTERARCHAT = 'modificaVisibilidadeModalAlterarChat';

export const MODIFICACLIENTESFILTRADOSAPOSDIGITO = 'modificaClientesFiltradosAposDigito';

export const UPDTEISMAINTENANCE = 'updateMaintenance';
export const UPDTELASTVERSIONAVAILABLE = 'updateLastVersionAvailable';

export const MODIFICAUTILIZAVERSAOBETAPAINELADM = 'modificaUtilizaVersaoBetaPainelADM';

/* else if (atendenteVeApenasProprioChamado == true && agruparAtendenteSetor == true && contaPrincipal == false) {
    //aqui o atendente vê  os proprios chamados e chamados abertos com setor vinculado a ele
    const { currentUser } = firebase.auth();
    firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild('uid').equalTo(currentUser.uid).once("value").then((snapshot) => {
      let key = Object.keys(snapshot.val()).toString();
      if (valor == 0) {
        // if initial fetch
        ativaLoadingTelaAberturaChamado(dispatch);
        ativaDesativaLoadingRefreshControlChamadoAberto(dispatch, true);
        // 
        let objetoPesquisa = {};
        objetoPesquisa[key] = true; */
        /*   firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild(`usuariosVisualizaAtend/dadosAtendVinc/${key}`).equalTo(true).limitToLast(7) */
        /*             firebase.database().ref(`${chaveUnica}/chamados/`).child(`usuariosVisualizaAtend`).child('dadosAtendVinc').child(`${key}`) */
        /*   firebase.database().ref(`${chaveUnica}/chamados/{userid}`).child(`usuariosVisualizaAtend/dadosAtendVinc/${key}`) */
        /*    firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild(`usuariosVisualizaAtend/dadosAtendVinc/`).endAt(key) */
        /*    firebase.database().ref(`${chaveUnica}`).child('chamados').child('-MGJHNRK8WtoBPptxAIj').child('usuariosVisualizaAtend').child('dadosAtendVinc').orderByKey().equalTo(key) */
       /*  firebase.database().ref(`${chaveUnica}/chamados/`).orderByKey().limitToLast(100).on("value", snapshot => {
         
          if (snapshot.val() == null) {
            enviaChamadoAberto(dispatch, []);
            desativaLoadingTelaChamadoAberto(dispatch);
            ativaDesativaLoadingRefreshControlChamadoAberto(
              dispatch,
              false
            );
          } else {
            // changing to reverse chronological order (latest first)
            let arrayOfKeys = Object.keys(snapshot.val()).sort().reverse();
            // transforming to array
            let results = arrayOfKeys.map(key => snapshot.val()[key]);
            

            // storing reference
            referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
            let chamados = [];
            let resultsChamadoRelacionado = [];
            let caminhoPesquisa = `usuariosVisualizaAtend.dadosAtendVinc.${key}`;
            

            let aux2 = 0;
            for (let o = 0; o < results.length; o++) {
              aux2++;
              let verifyObject = _.has(results[o], caminhoPesquisa);
              
              if (verifyObject) {
                resultsChamadoRelacionado.push(results[o])
              }
              if (aux2 == results.length) {
                
                let arrayChamado = [];
                let promisseCliente;
                let promisseAtendente;
                let promisseSetor;
                for (let i = 0; i < resultsChamadoRelacionado.length; i++) {
                  let cliente = resultsChamadoRelacionado[i].cliente;
                  let atendente = resultsChamadoRelacionado[i].atendente;
                  let setor = resultsChamadoRelacionado[i].setor;
                  promisseCliente = firebase
                    .database()
                    .ref(`${chaveUnica}/clientes/${cliente}/`)
                    .once("value");
                  promisseAtendente = firebase
                    .database()
                    .ref(`${chaveUnica}/usuarios/${atendente}/nome`)
                    .once("value");
                  promisseSetor = firebase
                    .database()
                    .ref(`${chaveUnica}/setores/${setor}/nome`)
                    .once("value");
                  arrayChamado.push(promisseAtendente, promisseCliente, promisseSetor);
                }
                let aux = 0; */
                // 
              /*   Promise.all(arrayChamado).then(responses => { */
                  /*   
                     */
               /*    for (let i = 0; i < responses.length; i += 3) {
                    chamados.push({
                      key: arrayOfKeys[aux],
                      cliente: responses[i + 1].val() == null ? "Não informado" : responses[i + 1].val().nome,
                      empresa: responses[i + 1].val() == null ? "Não informado" : responses[i + 1].val().empresa,
                      setor: responses[i + 2].val() == null ? "Não informado" : responses[i + 2].val(),
                      urlImagemNuvem: responses[i + 1].val() == null ? undefined : responses[i + 1].val().urlImagemNuvem,
                      // cliente:'teste',
                      atendente: responses[i].val() == null ? "Não informado" : responses[i].val(),
                      dataHoraAbertura: resultsChamadoRelacionado[aux].dataHoraAbertura,
                      status: resultsChamadoRelacionado[aux].status,
                      prioridade:
                        resultsChamadoRelacionado[aux].prioridade === ""
                          ? "Não informado"
                          : resultsChamadoRelacionado[aux].prioridade,
                      descricaoProblema: resultsChamadoRelacionado[aux].descricaoProblema,
                      alterado: resultsChamadoRelacionado[aux].alterado,
                      chaveUnica: chaveUnica,
                      keyCliente: resultsChamadoRelacionado[aux].cliente,
                      numeroChamado: resultsChamadoRelacionado[aux].numeroChamado,
                      channelChamado: (resultsChamadoRelacionado[aux].channelChamado === undefined) ? 'Não informado' : resultsChamadoRelacionado[aux].channelChamado,
                    });
                    aux++;
                  }
                  enviaChamadoAberto(dispatch, chamados);
                  desativaLoadingTelaChamadoAberto(dispatch);

                  enviaUltimaReferenciaKey(dispatch, referenceToOldestKey);
                  ativaDesativaLoadingRefreshControlChamadoAberto(dispatch, false);
                  ativaDesativaLoadingFinalFlatListChamadoAberto(dispatch, true);
                });
              } */
              /*      */
              /*   if (results[o].usuariosVisualizaAtend.dadosAtendVinc !== undefined) {
                  if (results[o].usuariosVisualizaAtend.dadosAtendVinc.hasOwnProperty(key)) { */
              /*    
                  */
              /*     resultsChamadoRelacionado.push(results[o])
                } else { */
              /*  
                */
              /*     }
                } else { */
              /*   
                 */
              /*  } */
          /*   } */


            //    enviaChamadoAberto(dispatch, chamados);
            //enviaUltimaReferenciaKey(dispatch, referenceToOldestKey)
         /*  }
        }) */

/* 
      } else {
        
        
        if (referenciaUltimaKey != undefined && referenciaUltimaKey != "") {
          ativaDesativaLoadingMaisResultadosChamadosCliente(dispatch, true);
          firebase
            .database()
            .ref(`${chaveUnica}/chamados/`)
            .orderByKey()
            .endAt(referenciaUltimaKey)
            .limitToLast(51)
            .on("value", snapshot => {
              console.log(snapshot.val())
              if (snapshot.val() == null) {
                ativaDesativaLoadingFinalFlatListChamadoAberto(dispatch, false);
                ativaDesativaLoadingMaisResultadosChamadosCliente(dispatch, false);
              } else {

                // changing to reverse chronological order (latest first)
                // & removing duplicate
                let arrayOfKeys = Object.keys(snapshot.val())
                  .sort()
                  .reverse()
                  .slice(1);
                // transforming to array
                let results = arrayOfKeys.map(key => snapshot.val()[key]);
                // updating reference
                referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];

                let chamados = [];
                let resultsChamadoRelacionado = [];
                for (let o = 0; o < results.length; o++) {
                  //    
                  let verifyObject = _.has(results[o], 'usuariosVisualizaAtend.dadosAtendVinc');
                  
                  
                  if (verifyObject === true) {
                    if (results[o].usuariosVisualizaAtend.dadosAtendVinc.hasOwnProperty(key)) {
                      
                      
                      resultsChamadoRelacionado.push(results[o])
                    }
                  }
                 
                }
                let arrayChamado = [];
                let promisseCliente;
                let promisseAtendente;
                let promisseSetor;
                for (let i = 0; i < resultsChamadoRelacionado.length; i++) {
                  let cliente = resultsChamadoRelacionado[i].cliente;
                  let atendente = resultsChamadoRelacionado[i].atendente;
                  let setor = resultsChamadoRelacionado[i].setor;
                  promisseCliente = firebase
                    .database()
                    .ref(`${chaveUnica}/clientes/${cliente}/`)
                    .once("value");
                  promisseAtendente = firebase
                    .database()
                    .ref(`${chaveUnica}/usuarios/${atendente}/nome`)
                    .once("value");
                  promisseSetor = firebase
                    .database()
                    .ref(`${chaveUnica}/setores/${setor}/nome`)
                    .once("value");
                  arrayChamado.push(promisseAtendente, promisseCliente, promisseSetor);
                }
                let aux = 0;
                Promise.all(arrayChamado).then(responses => {
                  for (let i = 0; i < responses.length; i += 3) {
                    chamados.push({
                      key: arrayOfKeys[aux],
                      cliente: responses[i + 1].val() == null ? "Não informado" : responses[i + 1].val().nome,
                      empresa: responses[i + 1].val() == null ? "Não informado" : responses[i + 1].val().empresa,
                      setor: responses[i + 2].val() == null ? "Não informado" : responses[i + 2].val(),
                      urlImagemNuvem: responses[i + 1].val() == null ? undefined : responses[i + 1].val().urlImagemNuvem,
                      //cliente:responses[i + 1].val() == null ? "Não informado" : responses[i + 1].val(),
                      atendente:
                        responses[i].val() == null
                          ? "Não informado"
                          : responses[i].val(),
                      dataHoraAbertura: resultsChamadoRelacionado[aux].dataHoraAbertura,
                      status: resultsChamadoRelacionado[aux].status,
                      prioridade:
                        resultsChamadoRelacionado[aux].prioridade === ""
                          ? "Não informado"
                          : resultsChamadoRelacionado[aux].prioridade,
                      descricaoProblema: resultsChamadoRelacionado[aux].descricaoProblema,
                      alterado: resultsChamadoRelacionado[aux].alterado,
                      chaveUnica: chaveUnica,
                      keyCliente: resultsChamadoRelacionado[aux].cliente,
                      numeroChamado: resultsChamadoRelacionado[aux].numeroChamado,
                      channelChamado: (resultsChamadoRelacionado[aux].channelChamado === undefined) ? 'Não informado' : resultsChamadoRelacionado[aux].channelChamado,
                    });
                    aux++;
                  }

                  enviaChamadoAberto(
                    dispatch,
                    chamadosAnteriores.concat(chamados)
                  );
                  enviaUltimaReferenciaKey(dispatch, undefined);
                  ativaDesativaLoadingMaisResultadosChamadosCliente(dispatch, false);
                });
              }
            })


        } else {
          ativaDesativaLoadingFinalFlatListChamadoAberto(dispatch, false);
        }
      }
    })
  } */


/*   "usuariosVisualizaAtend/dadosAtendVinc/codigoInterno1status",
  "usuariosVisualizaAtend/dadosAtendVinc/codigoInterno2status",
                       "usuariosVisualizaAtend/dadosAtendVinc/codigoInterno3status","usuariosVisualizaAtend/dadosAtendVinc/codigoInterno4status",
                       "usuariosVisualizaAtend/dadosAtendVinc/codigoInterno5status",
                       "usuariosVisualizaAtend/dadosAtendVinc/codigoInterno6status","usuariosVisualizaAtend/dadosAtendVinc/codigoInterno7status",
                       "usuariosVisualizaAtend/dadosAtendVinc/codigoInterno8status","usuariosVisualizaAtend/dadosAtendVinc/codigoInterno9status",
                       "usuariosVisualizaAtend/dadosAtendVinc/codigoInterno10status","usuariosVisualizaAtend/dadosAtendVinc/codigoInterno11status",
                       "usuariosVisualizaAtend/dadosAtendVinc/codigoInterno12status","usuariosVisualizaAtend/dadosAtendVinc/codigoInterno13status",
                       "usuariosVisualizaAtend/dadosAtendVinc/codigoInterno14status","usuariosVisualizaAtend/dadosAtendVinc/codigoInterno15status",
                       "usuariosVisualizaAtend/dadosAtendVinc/codigoInterno16status","usuariosVisualizaAtend/dadosAtendVinc/codigoInterno17status",
                       "usuariosVisualizaAtend/dadosAtendVinc/codigoInterno18status","usuariosVisualizaAtend/dadosAtendVinc/codigoInterno19status",
                       "usuariosVisualizaAtend/dadosAtendVinc/codigoInterno20status","usuariosVisualizaAtend/dadosAtendVinc/codigoInterno21status",
                       "usuariosVisualizaAtend/dadosAtendVinc/codigoInterno22status","usuariosVisualizaAtend/dadosAtendVinc/codigoInterno23status",
                       "usuariosVisualizaAtend/dadosAtendVinc/codigoInterno24status","usuariosVisualizaAtend/dadosAtendVinc/codigoInterno25status",
                        "usuariosVisualizaAtend/dadosAtendVinc/codigoInterno1Chatstatus","usuariosVisualizaAtend/dadosAtendVinc/codigoInterno2Chatstatus",
                       "usuariosVisualizaAtend/dadosAtendVinc/codigoInterno3Chatstatus","usuariosVisualizaAtend/dadosAtendVinc/codigoInterno4Chatstatus",
                       "usuariosVisualizaAtend/dadosAtendVinc/codigoInterno5Chatstatus",
                       "usuariosVisualizaAtend/dadosAtendVinc/codigoInterno6Chatstatus","usuariosVisualizaAtend/dadosAtendVinc/codigoInterno7Chatstatus",
                       "usuariosVisualizaAtend/dadosAtendVinc/codigoInterno8Chatstatus","usuariosVisualizaAtend/dadosAtendVinc/codigoInterno9Chatstatus",
                       "usuariosVisualizaAtend/dadosAtendVinc/codigoInterno10Chatstatus","usuariosVisualizaAtend/dadosAtendVinc/codigoInterno11Chatstatus",
                       "usuariosVisualizaAtend/dadosAtendVinc/codigoInterno12Chatstatus","usuariosVisualizaAtend/dadosAtendVinc/codigoInterno13Chatstatus",
                       "usuariosVisualizaAtend/dadosAtendVinc/codigoInterno14Chatstatus","usuariosVisualizaAtend/dadosAtendVinc/codigoInterno15Chatstatus",
                       "usuariosVisualizaAtend/dadosAtendVinc/codigoInterno16Chatstatus","usuariosVisualizaAtend/dadosAtendVinc/codigoInterno17Chatstatus",
                       "usuariosVisualizaAtend/dadosAtendVinc/codigoInterno18Chatstatus","usuariosVisualizaAtend/dadosAtendVinc/codigoInterno19Chatstatus",
                       "usuariosVisualizaAtend/dadosAtendVinc/codigoInterno20Chatstatus","usuariosVisualizaAtend/dadosAtendVinc/codigoInterno21Chatstatus",
                       "usuariosVisualizaAtend/dadosAtendVinc/codigoInterno22Chatstatus","usuariosVisualizaAtend/dadosAtendVinc/codigoInterno23Chatstatus",
                       "usuariosVisualizaAtend/dadosAtendVinc/codigoInterno24Chatstatus","usuariosVisualizaAtend/dadosAtendVinc/codigoInterno25Chatstatus" */

                    