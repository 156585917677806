import {
    MENSAGEMSEMCONEXAOINTERNETCONFIGEMPRESA, ATIVADESATIVALOADINGTELACONFIGEMPRESA, ENVIANOMEEMPRESAPARAREDUCER,
    MODIFICANOMEEMPRESACONFIGURAEMPRESA, ATIVADESATIVALOADINGBOTAOALTERARNOMEEMPRESACONFIGEMPRESA,
    MENSAGEMSEMCONEXAOINTERNETCONFIGAVALIACAO, ATIVADESATIVALOADINGTELACONFIGAVALIACAO,
    ENVIASEDESEJAMANDARMENSAGEMAVALIACAOAPOSFINALIZARATENDIMENTO,
    ENVIAMENSAGEMAVALIACAOPARAREDUCER, ATIVADESATIVALOADINGBOTAOALTERARCONFIGAVALIACAO, ALTERAVALORSWITCHCONFIGATENDIMENTO,
    ENVIADADOSCONFIGURACAOATENDIMENTO, ATIVADESATIVALOADINGCONFIGURACAOATENDIMENTO, MENSAGEMSEMCONEXAOINTERNETCONFIGURACAOATENDIMENTO,
    ATIVADESATIVALOADINGBOTAOALTERARCONFIGURACAOATENDIMENTO, ALTERAAGRUPAATENDENTESETOR, ALTERAATENDENTEVEAPENASPROPRIOCHAMADO,
    VERIFICACRIARCHAMADOPROBLEMAESTATICO, ALTERAVALORSWITCHCONFIGCHAMADOESTATICO, MODIFICADESCRICAOPROBLEMAESTATICO,
    ATIVADESATIVALOADINGBOTAONOVOPROBLEMA, ENVIAPROBLEMAPARAPROBLEMASELECIONADO, ENVIAPROBLEMAPARAREDUCER, ATIVADESATIVALOADINGCARREGANDOPROBLEMAS,
    ALTERAVALORSWITCHUTILIZARCHAT, ALTERAVALORSWITCHUTILIZAR2HORARIOSCHAT,
    MODIFICARESPOSTASELECIONADA,


    MODIFICAHORARIOOPERACAOSEGUNDAFEIRA, MODIFICAHORARIOOPERACAOSEGUNDAFEIRA2, ALTERAVALORCHECKSEGUNDAEIRA,
    MODIFICAHORARIOOPERACAOTERCAFEIRA, MODIFICAHORARIOOPERACAOQUARTAFEIRA, MODIFICAHORARIOOPERACAOQUINTAFEIRA,
    MODIFICAHORARIOOPERACAOSEXTAFEIRA, MODIFICAHORARIOOPERACAOSABADOFEIRA, MODIFICAHORARIOOPERACAODOMINGOFEIRA,
    MODIFICAHORARIOOPERACAOTERCAFEIRA2, MODIFICAHORARIOOPERACAOQUARTAFEIRA2, MODIFICAHORARIOOPERACAOQUINTAFEIRA2,
    MODIFICAHORARIOOPERACAOSEXTAFEIRA2, MODIFICAHORARIOOPERACAOSABADOFEIRA2, MODIFICAHORARIOOPERACAODOMINGOFEIRA2,
    ALTERAVALORCHECKDOMINGOEIRA, ALTERAVALORCHECKSABADOEIRA, ALTERAVALORCHECKSEXTAEIRA, ALTERAVALORCHECKQUINTAEIRA,
    ALTERAVALORCHECKQUARTAEIRA, ALTERAVALORCHECKTERCAEIRA,
    ATIVADESATIVALOADINGBOTAOALTERARCONIGCHATONLINE, MODIFICAFUSOHORARIOSELECIONADO,
    ENVIARESPOSTASSELECIONADASPARAREDUCER, ENVIARESPOSTAPADROESPARAREDUCER, MODIFICADESCROCAORESPOSTAPADRAO, MODIFICAMENSAGEMRESPOSTAPADRAO,
    ATIVADESATIVALOADINGBOTAONVOARESPOSTA, ENVIARESPOSTAPARAACOESCHAMADO, ENVIASEUTILIZACHATATENDIMENTOONLINE,
    MODIFICASWITCHADMVISUALIZAAPENASCHAMADOSETOR, ENVIASESSIONCLIENT, ENVIASTATUSCONNECT, ENVIAQRCODE,
    MODIFICAMENSAGEMBOASVINDASWPP, MODIFICAMENSAGEMESCOLHADEPARTAMENTO, MODIFICAMENSAGEMFILAATENDIMENTO,
    MODIFICAMENSAGEMFINALIZACAOCHAMADO, MODIFICAMENSAGEMFORAHORARIOOPERACAO, ATIVADESATIVALOADINGSALVARCONFIGWHATSAPP,
    MODIFICALIMITEDEESPERAFILACHAT, ALTERAVALORSWITCHENVIATENDIMENTOPARASETORAPOSLIMITE, ENVIASETORPARACONFIGURACAOLIMITE,
    MODIFICAKEYSETORSELECIONADOPARAENVIARATENDIMENTOAPOSLIMITE,
    MODIFICAOBRIGATORIORESOLUCAOCHAMADO, MODIFICADESATIVARASSISTENTEVIRTUALWPP, MODIFICAMOSTRARNOMEATENDENTEMENSAGEM,
    MODIFICADESATIVARASSISTENTEVIRTUALBOTWPP, MODIFICAMOSTRARNOMEATENDENTEWPP, MODIFICASOLICITARNOMECLIENTEPRIMEIROCONTATO,
    MODIFICADESCROCAORESPOSTAPADRAOALTERAR, MODIFICAMENSAGEMRESPOSTAPADRAOALTERAR, MODIFICAMOTIVOCANCELAMENTOCONFIG,
    MODIFICAINFORMARMOTIVOCANCELAMENTO, MODIFICAATENDENTEVEAPENASPROPRIOHISTORICO, ALTERAVALORSWITCHUTILIZAVERSAOBETA,
    MODIFICAENVIARMENSAGEMTRANSSETORWPPCONFIG
} from '../actions/types';


const INITIAL_STATE = {
    nomeEmpresa: '',
    verificaConexaoInternetConfigEmpresa: '',
    loadingConfigEmpresa: false,
    loadingBotaoAlterarNomeConfigEmpresa: false,
    mensagemAvaliacao: '',
    switchMEnsagemAvaliacao: false,
    verificaConexaoInternetConfigAvaliacao: '',
    loadingConfiAvaliacao: false,
    loadingBotaoAlterarConfigAvaliacao: false,
    switchObrigatorioAtribuirAtendenteAtendimento: false,
    loadingConfigAtendimentos: false,
    loadingBotaoAlterarConfigAtendimento: false,
    verificaConexaoInternetConfigAtendimento: '',
    atendenteVeApenasProprioChamado: false,
    agruparAtendenteSetor: false,
    verificaCriarChamadoProblemaEstatico: false,
    problemasSelecionado: [],
    problemasEstaticos: [],
    loadingBotaoNovoPRoblema: false,
    descricaoProblemaEstatico: '',
    loadingCarregandoProblemas: false,
    switchUtilizarChat: false,
    switchUtilizar2HorariosChat: false,
    segundaFeiraChat: true,
    tercaFeiraChat: true,
    quartaFeiraChat: true,
    quintaFeiraChat: true,
    sextaFeiraChat: true,
    sabadoChat: false,
    domingoChat: false,
    respostasSelecionadas: [],
    respostasPadroes: [],
    mensagemRespostaPadrao: '',
    descricaoRespostaPadrao: '',
    loadingBotaoNovaResposta: false,
    utilizaChatAtendimentoOnline: false,
    respostasPadroesAcoesChamado: [],


    horarioOperacaoSegundaFeira: {
        start: "09:00",
        end: "18:00"
    },
    horarioOperacaoSegundaFeira2: {
        start: "09:00",
        end: "18:00"
    },
    horarioOperacaoTercaFeira: {
        start: "09:00",
        end: "18:00"
    },
    horarioOperacaoTercaFeira2: {
        start: "09:00",
        end: "18:00"
    },
    horarioOperacaoQuartaFeira: {
        start: "09:00",
        end: "18:00"
    },
    horarioOperacaoQuartaFeira2: {
        start: "09:00",
        end: "18:00"
    },
    horarioOperacaoQuintaFeira: {
        start: "09:00",
        end: "18:00"
    },
    horarioOperacaoQuintaFeira2: {
        start: "09:00",
        end: "18:00"
    },
    horarioOperacaoSextaFeira: {
        start: "09:00",
        end: "18:00"
    },
    horarioOperacaoSextaFeira2: {
        start: "09:00",
        end: "18:00"
    },
    horarioOperacaoSabadoFeira: {
        start: "09:00",
        end: "18:00"
    },
    horarioOperacaoSabadoFeira2: {
        start: "09:00",
        end: "18:00"
    },
    horarioOperacaoDomingoFeira: {
        start: "09:00",
        end: "18:00"
    },
    horarioOperacaoDomingoFeira2: {
        start: "09:00",
        end: "18:00"
    },
    loadingBotaoConfigChatOnline: false,
    fusoHorarios: [
        {
            name: 'FUSO HORÁRIO',
            type: 'group',
            items: [
                { value: "America/Sao_Paulo", name: "Fuso Horário de Brasilia -03:00" },
                { value: "Brazil/DeNoronha", name: "Fuso Horário de Fernando de Noronha -02:00" },
                { value: "America/Manaus", name: "Fuso Horário de Amazonas -04:00" },
                { value: "America/Rio_Branco", name: "Fuso Horário do Acre -05:00" }
            ]
        }
    ],
    fusoHorarioSelelcionado: '',
    switchVisualizaChamadosDosSetoresVinculadosADM: false,

    sessionCliente: '',
    qrCode: '',
    statusConnect: '',

    mensagemBemVindo: '',
    mensagemEscolhaDepartamento: '',
    mensagemFilaAtendimento: '',
    mensagemFinalizacaoAtendimento: '',
    mensagemForaHorarioOperacao: '',
    loadingBotaoAlterarConfigWhatsApp: false,

    limitesEmEEspera: [
        {
            name: 'Limite De Espera',
            type: 'group',
            items: [
                { value: "1", name: "1 minuto" },
                { value: "2", name: "2 minutos" },
                { value: "3", name: "3 minutos" },
                { value: "4", name: "4 minutos" },

                { value: "5", name: "5 minutos" },
                { value: "6", name: "6 minutos" },
                { value: "7", name: "7 minutos" },
                { value: "8", name: "8 minutos" },
                { value: "9", name: "9 minutos" },

                { value: "10", name: "10 minutos" },
                { value: "15", name: "15 minutos" },
                { value: "20", name: "20 minutos" },
                { value: "25", name: "25 minutos" },

                { value: "30", name: "30 minutos" },
                { value: "35", name: "35 minutos" },
                { value: "40", name: "40 minutos" },
                { value: "50", name: "50 minutos" },
                { value: "60", name: "60 minutos" }
            ]
        }
    ],
    limiteEmEsperaSelecionado: '',

    enviaAtendimentoParaSetorAposLimite: false,
    keySetorSelecionadoParaEnviarAtendimentoAposLimite: '',
    setoresParaLimite: [],

    obrigatorioInformarResolucaoChamadoConfig: false,

    fusoHorarioConfigurado: "America/Sao_Paulo",


    mostrarNomeAtendenteMensagem: true,
    desativarAssistenteVirtualWpp: false,

    mostrarNomeAtendenteWpp: true,
    desativarAssistenteVirtualBotWpp: false,

    solicitarNomeClientePrimeirocontato: true,

    mensagemRespostaPadraoAlterar: '',
    descricaoRespostaPadraoAlterar: '',

    obrigatorioInformarMotivoCancelamentoConfig: true,
    obrigatorioInformarMotivoCancelamento: true,

    atendenteVeApenasProprioHistorico: false,
    utilizaVersaoBETA: true,
    enviarMensagemTransSetorWppConfig: true

};
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MENSAGEMSEMCONEXAOINTERNETCONFIGEMPRESA:
            return { ...state, verificaConexaoInternetConfigEmpresa: action.payload };
        case ATIVADESATIVALOADINGTELACONFIGEMPRESA:
            return { ...state, loadingConfigEmpresa: action.payload };
        case ENVIANOMEEMPRESAPARAREDUCER:
            return { ...state, nomeEmpresa: action.payload };
        case MODIFICANOMEEMPRESACONFIGURAEMPRESA:
            return { ...state, nomeEmpresa: action.payload };
        case ATIVADESATIVALOADINGBOTAOALTERARNOMEEMPRESACONFIGEMPRESA:
            return { ...state, loadingBotaoAlterarNomeConfigEmpresa: action.payload };
        case MENSAGEMSEMCONEXAOINTERNETCONFIGAVALIACAO:
            return { ...state, verificaConexaoInternetConfigAvaliacao: action.payload };
        case ATIVADESATIVALOADINGTELACONFIGAVALIACAO:
            return { ...state, loadingConfiAvaliacao: action.payload };
        case ENVIASEDESEJAMANDARMENSAGEMAVALIACAOAPOSFINALIZARATENDIMENTO:
            return { ...state, switchMEnsagemAvaliacao: action.payload };
        case ENVIAMENSAGEMAVALIACAOPARAREDUCER:
            return { ...state, mensagemAvaliacao: action.payload };
        case ATIVADESATIVALOADINGBOTAOALTERARCONFIGAVALIACAO:
            return { ...state, loadingBotaoAlterarConfigAvaliacao: action.payload };
        case ALTERAVALORSWITCHCONFIGATENDIMENTO:
            return { ...state, switchObrigatorioAtribuirAtendenteAtendimento: action.payload };
        case ENVIADADOSCONFIGURACAOATENDIMENTO:
            return { ...state, switchObrigatorioAtribuirAtendenteAtendimento: action.payload };
        case ATIVADESATIVALOADINGCONFIGURACAOATENDIMENTO:
            return { ...state, loadingConfigAtendimentos: action.payload };
        case MENSAGEMSEMCONEXAOINTERNETCONFIGURACAOATENDIMENTO:
            return { ...state, verificaConexaoInternetConfigAtendimento: action.payload };
        case ATIVADESATIVALOADINGBOTAOALTERARCONFIGURACAOATENDIMENTO:
            return { ...state, loadingBotaoAlterarConfigAtendimento: action.payload };
        case ALTERAAGRUPAATENDENTESETOR:
            return { ...state, agruparAtendenteSetor: action.payload };
        case ALTERAATENDENTEVEAPENASPROPRIOCHAMADO:
            return { ...state, atendenteVeApenasProprioChamado: action.payload };
        case VERIFICACRIARCHAMADOPROBLEMAESTATICO:
            return { ...state, verificaCriarChamadoProblemaEstatico: action.payload };
        case ALTERAVALORSWITCHCONFIGCHAMADOESTATICO:
            return { ...state, verificaCriarChamadoProblemaEstatico: action.payload };
        case MODIFICADESCRICAOPROBLEMAESTATICO:
            return { ...state, descricaoProblemaEstatico: action.payload };
        case ATIVADESATIVALOADINGBOTAONOVOPROBLEMA:
            return { ...state, loadingBotaoNovoPRoblema: action.payload };
        case ENVIAPROBLEMAPARAPROBLEMASELECIONADO:
            return { ...state, problemasSelecionado: action.payload };
        case ENVIAPROBLEMAPARAREDUCER:
            return { ...state, problemasEstaticos: action.payload };

        case ATIVADESATIVALOADINGCARREGANDOPROBLEMAS:
            return { ...state, loadingCarregandoProblemas: action.payload };
        case ALTERAVALORSWITCHUTILIZARCHAT:
            return { ...state, switchUtilizarChat: action.payload };
        case MODIFICAHORARIOOPERACAOSEGUNDAFEIRA:
            return { ...state, horarioOperacaoSegundaFeira: action.payload };
        case ALTERAVALORSWITCHUTILIZAR2HORARIOSCHAT:
            return { ...state, switchUtilizar2HorariosChat: action.payload };
        case ALTERAVALORCHECKSEGUNDAEIRA:
            return { ...state, segundaFeiraChat: action.payload };
        case MODIFICAHORARIOOPERACAOSEGUNDAFEIRA2:
            return { ...state, horarioOperacaoSegundaFeira2: action.payload };

        case MODIFICAHORARIOOPERACAOTERCAFEIRA:
            return { ...state, horarioOperacaoTercaFeira: action.payload };
        case MODIFICAHORARIOOPERACAOTERCAFEIRA2:
            return { ...state, horarioOperacaoTercaFeira2: action.payload };

        case MODIFICAHORARIOOPERACAOQUARTAFEIRA:
            return { ...state, horarioOperacaoQuartaFeira: action.payload };
        case MODIFICAHORARIOOPERACAOQUARTAFEIRA2:
            return { ...state, horarioOperacaoQuartaFeira2: action.payload };

        case MODIFICAHORARIOOPERACAOQUINTAFEIRA:
            return { ...state, horarioOperacaoQuintaFeira: action.payload };
        case MODIFICAHORARIOOPERACAOQUINTAFEIRA2:
            return { ...state, horarioOperacaoQuintaFeira2: action.payload };


        case MODIFICAHORARIOOPERACAOSEXTAFEIRA:
            return { ...state, horarioOperacaoSextaFeira: action.payload };
        case MODIFICAHORARIOOPERACAOSEXTAFEIRA2:
            return { ...state, horarioOperacaoSextaFeira2: action.payload };

        case MODIFICAHORARIOOPERACAOSABADOFEIRA:
            return { ...state, horarioOperacaoSabadoFeira: action.payload };
        case MODIFICAHORARIOOPERACAOSABADOFEIRA2:
            return { ...state, horarioOperacaoSabadoFeira2: action.payload };

        case MODIFICAHORARIOOPERACAODOMINGOFEIRA:
            return { ...state, horarioOperacaoDomingoFeira: action.payload };
        case MODIFICAHORARIOOPERACAODOMINGOFEIRA2:
            return { ...state, horarioOperacaoDomingoFeira2: action.payload };

        case ALTERAVALORCHECKTERCAEIRA:
            return { ...state, tercaFeiraChat: action.payload };
        case ALTERAVALORCHECKQUARTAEIRA:
            return { ...state, quartaFeiraChat: action.payload };
        case ALTERAVALORCHECKQUINTAEIRA:
            return { ...state, quintaFeiraChat: action.payload };
        case ALTERAVALORCHECKSEXTAEIRA:
            return { ...state, sextaFeiraChat: action.payload };
        case ALTERAVALORCHECKSABADOEIRA:
            return { ...state, sabadoChat: action.payload };
        case ALTERAVALORCHECKDOMINGOEIRA:
            return { ...state, domingoChat: action.payload };
        case ATIVADESATIVALOADINGBOTAOALTERARCONIGCHATONLINE:
            return { ...state, loadingBotaoConfigChatOnline: action.payload };
        case MODIFICAFUSOHORARIOSELECIONADO:
            return { ...state, fusoHorarioSelelcionado: action.payload };
        case ENVIARESPOSTASSELECIONADASPARAREDUCER:
            return { ...state, respostasSelecionadas: action.payload };
        case ENVIARESPOSTAPADROESPARAREDUCER:
            return { ...state, respostasPadroes: action.payload };
        case MODIFICADESCROCAORESPOSTAPADRAO:
            return { ...state, descricaoRespostaPadrao: action.payload };
        case MODIFICAMENSAGEMRESPOSTAPADRAO:
            return { ...state, mensagemRespostaPadrao: action.payload };
        case ATIVADESATIVALOADINGBOTAONVOARESPOSTA:
            return { ...state, loadingBotaoNovaResposta: action.payload };
        case MODIFICARESPOSTASELECIONADA:
            return { ...state, respostasSelecionadas: action.payload };
        case ENVIARESPOSTAPARAACOESCHAMADO:
            return { ...state, respostasPadroesAcoesChamado: action.payload };
        case ENVIASEUTILIZACHATATENDIMENTOONLINE:
            return { ...state, utilizaChatAtendimentoOnline: action.payload };

        case MODIFICASWITCHADMVISUALIZAAPENASCHAMADOSETOR:
            return { ...state, switchVisualizaChamadosDosSetoresVinculadosADM: action.payload };

        case ENVIASESSIONCLIENT:
            return { ...state, sessionCliente: action.payload };
        case ENVIASTATUSCONNECT:
            return { ...state, statusConnect: action.payload };
        case ENVIAQRCODE:
            return { ...state, qrCode: action.payload };

        case MODIFICAMENSAGEMBOASVINDASWPP:
            return { ...state, mensagemBemVindo: action.payload }
        case MODIFICAMENSAGEMESCOLHADEPARTAMENTO:
            return { ...state, mensagemEscolhaDepartamento: action.payload }
        case MODIFICAMENSAGEMFILAATENDIMENTO:
            return { ...state, mensagemFilaAtendimento: action.payload }
        case MODIFICAMENSAGEMFINALIZACAOCHAMADO:
            return { ...state, mensagemFinalizacaoAtendimento: action.payload }
        case MODIFICAMENSAGEMFORAHORARIOOPERACAO:
            return { ...state, mensagemForaHorarioOperacao: action.payload }
        case ATIVADESATIVALOADINGSALVARCONFIGWHATSAPP:
            return { ...state, loadingBotaoAlterarConfigWhatsApp: action.payload }
        case MODIFICALIMITEDEESPERAFILACHAT:
            return { ...state, limiteEmEsperaSelecionado: action.payload }

        case ALTERAVALORSWITCHENVIATENDIMENTOPARASETORAPOSLIMITE:
            return { ...state, enviaAtendimentoParaSetorAposLimite: action.payload }
        case ENVIASETORPARACONFIGURACAOLIMITE:
            return { ...state, setoresParaLimite: action.payload }
        case MODIFICAKEYSETORSELECIONADOPARAENVIARATENDIMENTOAPOSLIMITE:
            return { ...state, keySetorSelecionadoParaEnviarAtendimentoAposLimite: action.payload }
        case MODIFICAOBRIGATORIORESOLUCAOCHAMADO:
            return { ...state, obrigatorioInformarResolucaoChamadoConfig: action.payload }

        case MODIFICADESATIVARASSISTENTEVIRTUALWPP:
            return { ...state, desativarAssistenteVirtualWpp: action.payload }
        case MODIFICAMOSTRARNOMEATENDENTEMENSAGEM:
            return { ...state, mostrarNomeAtendenteMensagem: action.payload }

        case MODIFICADESATIVARASSISTENTEVIRTUALBOTWPP:
            return { ...state, desativarAssistenteVirtualBotWpp: action.payload }
        case MODIFICAMOSTRARNOMEATENDENTEWPP:
            return { ...state, mostrarNomeAtendenteWpp: action.payload }

        case MODIFICASOLICITARNOMECLIENTEPRIMEIROCONTATO:
            return { ...state, solicitarNomeClientePrimeirocontato: action.payload }


        case MODIFICADESCROCAORESPOSTAPADRAOALTERAR:
            return { ...state, descricaoRespostaPadraoAlterar: action.payload }
        case MODIFICAMENSAGEMRESPOSTAPADRAOALTERAR:
            return { ...state, mensagemRespostaPadraoAlterar: action.payload }
        case MODIFICAMOTIVOCANCELAMENTOCONFIG:
            return { ...state, obrigatorioInformarMotivoCancelamentoConfig: action.payload }

        case MODIFICAINFORMARMOTIVOCANCELAMENTO:
            return { ...state, obrigatorioInformarMotivoCancelamento: action.payload }
        case MODIFICAATENDENTEVEAPENASPROPRIOHISTORICO:
            return { ...state, atendenteVeApenasProprioHistorico: action.payload }

        case ALTERAVALORSWITCHUTILIZAVERSAOBETA:
            return { ...state, utilizaVersaoBETA: action.payload }

        case MODIFICAENVIARMENSAGEMTRANSSETORWPPCONFIG:
            return { ...state, enviarMensagemTransSetorWppConfig: action.payload }
        default:
            return state;
    }
};