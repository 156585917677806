import styled, { keyframes } from 'styled-components';
import { shade, opacify } from 'polished';
import { BrowserRouter, Switch, Route, Link, useLocation, Router, withRouter } from 'react-router-dom';
import { ProSidebar, Menu as MenuSide, MenuItem as MenuItemSide, SubMenu, SidebarHeader, SidebarFooter } from 'react-pro-sidebar';

const bounce = keyframes`
    0% {
    transform: translateY(0px);
  }
  40% {
    transform: translateY(-3px);
  }
  80%,
  100% {
    transform: translateY(0px);
  }
`;
export const Container = styled.div`
   display:flex;
   flex:1;
   height:100vh;
`;
export const DivLoadingLogged = styled.div`
   display:flex;
   flex:1;
   height:100vh;
   background:${props => props.backgroundDinamico};
`;

/* export const Container = styled.div`
   display:flex;
   flex:1;
   height:100vh;
   flex-direction:row;
`; */
export const SubContainer = styled.div`
   display:flex;
   flex:1;
   height:100vh;
   flex-direction:column;
`;
export const PageBody = styled.div`
   display:flex;
   flex:1;
   overflow-y:auto;
   background:${props => props.corBackgroundDinamico};
   
`;


export const ButtonMenu = styled.div`
   display:flex;
   flex:1;
   height:100%;
   justify-content:center;
   align-items:center;

`;

export const ButtonExpandeMenu = styled.button`
justify-content:center;
align-items:center;
display:flex;
margin-right:4px;
background:#2b2b2b;
border-radius:4px;
border:0;
height:33px;
min-height:33px;
width: 33px;
min-width: 33px;
z-index: 110;
position: absolute; 
   right: auto;
  left:auto;
  bottom:auto;
  top:auto;
`;
export const BorderBottomMenu = styled.div`
display:flex;
border-bottom: 1px solid rgba(173, 173, 173, 0.2);
`;
export const HeaderPadrao = styled.div`
height:61px;
min-height:61px;
flex-direction:row;
display:flex;
justify-content:space-between;
padding-right:20px;
padding-left:20px;
align-items:center;
-webkit-box-shadow: 0px 0px 6px 1px #000;
-moz-box-shadow: 0px 0px 6px 1px #000;
box-shadow: 0px 0px 6px 1px #000;
position: relative;
background:${props => props.backgroundDinamico};
`;
export const TelaInteira = styled.div`
   display:flex;
   flex:1;
   justify-content:center;
align-items:center;
background:${props => props.backgroundDinamico};
`;
export const NomeRota = styled.strong`
font-size:16;
color:${props => props.colorDinamico};
letter-spacing: 1.5px;
`;

export const ContainerPerfil = styled.div`
   display:flex;
   flex-direction:row;
   justify-content:center;
   align-items:center;
`;
export const DivNomeEmail = styled.div`
   flex-direction:column;
   justify-content:center;
   align-items:flex-end;
   margin-right:10px;
   margin-left:10px;
   width:180px;
   max-width:180px;
   display: flex;
 
`;
export const TextNome = styled.p`
  color:${props => props.colorDinamico};
  font-size:13px;
 font-weight:bold;
 max-width:180px;
overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
`;
export const TextEmail = styled.p`
  color:${props => props.colorDinamico};
  font-size:11px;
  max-width:180px;
overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
`;


export const ButtonImagemPerfil = styled.button`
display:flex;
border:0;
height:45px;
min-height:45px;
width: 45px;
min-width: 45px;
background: rgba(159,173,183, 0.3);
border-radius:50%;
`;

export const ButtonNotificacao = styled.button`
display:flex;
background:transparent;
border:0;
border-radius:10px;
justify-content:center;
align-items:center;
height:35px;
min-height:35px;
width: 35px;
min-width: 35px;
z-index:300;
`;

export const IconeNotificacao = styled.i`
  color:${props => props.colorPrimario};
  font-size:19px;
  border-radius:10px;
  padding: 5px 5px 5px 5px;
  &:hover{
   color: ${props => props.colorSecundario};
   background: ${shade(0.2, 'rgba(0, 0, 0, 0.2)')};
}
`;

export const ButtonVariosNovos = styled.button`
display:flex;
flex-direction:row;
border:0;
justify-content:space-between;
height:33px;
min-height:33px;
width:100px;
margin-right:10px;
   margin-left:10px;
align-items:center;
border-radius:5px;
transition: background-color 0.2s;
background:${props => props.backgroundbotao};
&:hover{
   background:${props => shade(0.2, props.backgroundbotao)};
}
`;
export const ButtonVariosNovosExcel = styled(Link)`
display:flex;
flex-direction:row;
border:0;
justify-content:space-between;
height:33px;
min-height:33px;
width:35px;
margin-right:10px;
   margin-left:10px;
align-items:center;
border-radius:5px;
transition: background-color 0.2s;
background:${props => props.backgroundbotao};
&:hover{
   background:${props => shade(0.2, props.backgroundbotao)};
}
`;
export const ButtonAddNew = styled(Link)`
display:flex;
flex-direction:row;
border:0;
justify-content:space-between;
height:33px;
min-height:33px;
margin-right:10px;
   margin-left:10px;
align-items:center;
border-radius:5px;
transition: background-color 0.2s;
background:${props => props.backgroundbotao};
text-decoration:none;
&:hover{
   background:${props => shade(0.2, props.backgroundbotao)};
}
`;

export const ButtonPortalCliente = styled.button`
display:flex;
flex-direction:row;
border:0;
justify-content:space-between;
height:33px;
min-height:33px;
width:130px;
margin-right:10px;
   margin-left:10px;
align-items:center;
border-radius:5px;
transition: background-color 0.2s;
background:${props => props.backgroundbotao};
animation: ${bounce} 2s infinite;
&:hover{
   background:${props => shade(0.2, props.backgroundbotao)};
}
`;

export const IconeVariosNovos = styled.i`
  color:${props => props.colorPrimario};
  font-size:13px;
  padding: 5px 5px 5px 5px;
 
`;
export const IconeVariosNovosSeta = styled.i`
  color:${props => props.colorPrimario};
  font-size:11px;
  padding: 5px 5px 5px 5px;
 
`;

export const TextBotaoNovo = styled.span`
  color:${props => props.colorDinamico};
  font-size:13px;
 font-weight:bold;
`;

export const DivMenuNovo = styled.div`
flex-direction:column;
justify-content:space-around;
background:#FFF;
align-items:flex-start;
display: flex;
height:170px;
min-height:170px;
width:125px;
`;
export const TextNovoMenuLink = styled(Link)`
  color:#000;
  font-size:12px;
  padding:8px;
  width:125px;
  &:hover{
   color: #FFF;
   background: ${shade(0.2, '#CCC')};
   cursor:pointer;
}
text-decoration:none;
`;
export const TextAddNew = styled(Link)`
 color:${props => props.colorDinamico};
  font-size:13px;
 font-weight:bold;
 text-align:center;
 padding-right:10px;
text-decoration:none;
`;

export const TextNovoMenu = styled.span`
  color:#000;
  font-size:12px;
  padding:8px;
  width:125px;
  &:hover{
   color: #FFF;
   background: ${shade(0.2, '#CCC')};
   cursor:pointer;
}
`;
export const TextFiltrarPor = styled.strong`
  color:#000;
  font-size:13px;
  height:20px;
  min-height:20px;
  width:135px;
  text-align:center;
  justify-content:center;
  align-items:center;
  background:#FFF;
`;
export const TextFiltrarPorAvisos = styled.strong`
  color:#000;
  font-size:13px;
  padding:8px;
  width:135px;
  text-align:center;
  justify-content:center;
  align-items:center;
  background:#FFF;
`;
export const DivMenuFiltro = styled.div`
flex-direction:column;
justify-content:space-around;
background:#FFF;
align-items:flex-start;
display: flex;
height:275px;
min-height:275px;
width:135px;
`;
export const DivMenuFiltroAtendimento = styled.div`
flex-direction:column;
justify-content:space-around;
background:#FFF;
align-items:flex-start;
display: flex;
height:275px;
min-height:275px;
width:135px;
`;
export const DivMenuFiltroAvisos = styled.div`
flex-direction:column;
justify-content:space-around;
background:#FFF;
align-items:flex-start;
display: flex;
height:200px;
min-height:200px;
width:100px;
`;
export const DivMenuFiltroClientes = styled.div`
flex-direction:column;
justify-content:space-around;
background:#FFF;
align-items:flex-start;
display: flex;
height:60px;
min-height:60px;
width:135px;
`;
export const DivMenuFiltroAtendimentoDetalhado = styled.div`
flex-direction:column;
justify-content:space-around;
background:#FFF;
align-items:flex-start;
display: flex;
height:130px;
min-height:130px;
width:135px;
`;

export const DivIconeTextFiltro = styled.div`
flex-direction:row;
justify-content:center;
background:#FFF;
align-items:center;
width:135px;
display: flex;
  &:hover{
   color: #FFF;
   background: ${shade(0.2, '#CCC')};
   cursor:pointer;
   span{
      color:#FFF;
   }
  }
`;
export const TextMenuFiltroAtendimento = styled.span`
  color:#000;
  font-size:12px;
  padding:8px;
  width:115px;
`;
export const TextMenuFiltro = styled.span`
  color:#000;
  font-size:12px;
  padding:8px;
  width:135px;
`;
export const TextMenuFiltroAvisos = styled.span`
  color:#000;
  font-size:12px;
  padding:8px;
  width:100px;
`;

export const DivMenuPerfil = styled.div`
flex-direction:column;
justify-content:center;
background:#FFF;
align-items:flex-start;
display: flex;
height:70px;
min-height:70px;
width:100px;
`;

export const TextPerfil = styled.span`
  color:#000;
  font-size:12px;
  padding:8px;
  width:100px;
  &:hover{
   color: #FFF;
   background: ${shade(0.2, '#CCC')};
   cursor:pointer;
}
`;
export const TextLinkPerfil = styled(Link)`
  color:#000;
  font-size:12px;
  padding:8px;
  width:100px;
  text-decoration:none;
  &:hover{
   color: #FFF;
   background: ${shade(0.2, '#CCC')};
   cursor:pointer;
}
`;

export const ContainerModalPrioridadeAtendimento = styled.div`
 display:flex;
   width:550px;
  height:260px;
  min-height:260px;
  overflow:auto;
   flex-direction:column;
   border-radius:7px;
   background:${props => props.backgroundDinamico};
`;
export const ContainerIconeFecharModal = styled.div`
padding-top:5px;
padding-bottom:5px;
justify-content:space-between;
align-items:center;
display:flex;
border-radius: 7px 7px 0 0;
width:100%;
background:${props => props.backgroundDinamico};
`;
export const TextNovoAtendimento = styled.strong`
font-size:17px;
color:${props => props.corLetraDinamico};
margin-left:12px;
letter-spacing: 1.5px;
`;
export const TextCabecalhoModalAcoes = styled.strong`
font-size:18px;
color:${props => props.corLetraDinamico};
text-align:center;
`;
export const IconeFechar = styled.i`
font-size:15px;
margin-right:5px;
color:${props => props.backgroundDinamico};
cursor: pointer;
`;
export const ContainerAtendentes = styled.div`
display:flex;
  flex:1;
  align-items:center;
  justify-content:center;
  flex-direction:column;
`;
export const DivInputMotivoCancelamento = styled.div`
 display:flex;
 flex:1;
justify-content:center;
align-items:flex-end;
`;
export const ButtonNaoCancelarAtendimento = styled.button`
display:flex;
flex-direction:row;
border:0;
margin:10px;
justify-content:space-around;
height:40px;
min-height:40px;
width:150px;
align-items:center;
border-radius:5px;
transition: background-color 0.2s;
background:${props => props.backgroundbotao};
border:${props => ` 1px solid ${props.borderColorDinamico}`};
&:hover{
    background:${props => shade(0.2, props.backgroundbotao)};
}
`;
export const ButtonCancelarAtendimento = styled.button`
display:flex;
flex-direction:row;
border:0;
margin:10px;
justify-content:space-around;
height:40px;
min-height:40px;
width:150px;
align-items:center;
border-radius:5px;
transition: background-color 0.2s;
background:${props => props.backgroundbotao};
&:hover{
    background:${props => shade(0.2, props.backgroundbotao)};
}
`;
export const TextBotaoCancelarAtendimento = styled.span`
  color:${props => props.colorDinamico};
  font-size:16px;
 font-weight:bold;
 flex:1;
 position: relative;
 margin-right:7px;
 text-align:center;
 align-items:center;
 justify-content:center;
`;
export const DivMensagemSair = styled.div`
 display:flex;
 flex:1;
justify-content:center;
align-items:center;
`;
export const ProSidebarTeste = styled(ProSidebar).attrs({
})`
  &.pro-sidebar{ 
    background:${props => props.backgroundinamico};
  color:${props => props.colordinamico};
  .pro-menu .pro-menu-item.active {
      color: ${props => props.highlightdinamico}; 
    }
    &.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover{
      color: ${props => props.highlightdinamico}; 
    }
  /*  ul{
    background-color:${props => props.backgroundDinamico};
  }  */

  }
 
`;

export const DivCountNotif = styled.div`
height: 14px;
min-height: 14px;
width: 14px;
max-width: 14px;
display: flex;
align-items: center;
flex-direction:row;
justify-content: center;
position: absolute; 
top: 17px;
right: 270px;
font-size: 10px;
border-radius: 50%;
background-color: red;  
font-weight: 400; 
color: white;
padding:1px;
`;
export const ContainerSemInternet = styled.div`
display:flex;
height: 80px;
min-height: 80px;
width: 370px;
max-width: 370px;
display: flex;
align-items: flex-start;
flex-direction:column;
justify-content: space-between;
position: absolute; 
top: 175px;
right: 20px;
background-color: #F3564B;
border-radius:3px;  
padding:10px;
`;
export const ContainerSemInternetLaranja = styled.div`
display:flex;
height: 100px;
min-height: 100px;
width: 370px;
max-width: 370px;
display: flex;
align-items: flex-start;
flex-direction:column;
justify-content: space-between;
position: absolute; 
top: 70px;
right: 20px;
background-color: #FE9D33;
border-radius:3px;
padding:10px;  
`;


export const TextCountNotif = styled.p`
color:#FFF;
font-size:12px;
font-weight:bold;
`;
export const ContainerNotif = styled.div`
border-radius:5px;
width: 400px;
height:400px;
min-height:400px;

box-shadow: 0 3px 8px rgba(0, 0, 0, 0.25);
top: 60px;
right: 270px;
position: absolute; 
 z-index: 199;
background-color: #FFF;
max-width: 400px;
max-height:400px;
`;
export const BackgroundSemInternet = styled.div`
position:fixed;
left:0;
top:0;
right:0;
bottom:0;
z-index:9999;
   background-color:rgba(0,0,0,0.4);   

`;
export const BackgroundModal = styled.div`
position:fixed;
left:0;
top:0;
right:0;
bottom:0;
z-index:199;
   background-color:rgba(0,0,0,0.7);   
&:after {
  content: "";
    width: 0; 
    height: 0; 
    border-left: 10px solid transparent;
    border-right: 10px solid transparent; 
    border-bottom:10px solid #CCC; 
    top: 52px;
   right: 277px;
    position: absolute;
    z-index:199;
  }
`;
export const HeaderNotif = styled.div`
display:flex;
width: 400px;
height:40px;
min-height:40px;
align-items:center;
border-radius:5px 5px 0 0;
padding-right:15px;
padding-left:15px;
flex-direction:row;
justify-content:space-between;
background-color: #CCC;
max-width: 400px;
max-height:40;
`;

export const TextNotification = styled.p`
color:#000;
font-size:16px;
font-weight:bold;
`;
export const TextMarcaLido = styled.p`
color:#0088ff;
font-size:13px;
margin-left:15px;
letter-spacing:0.5px;
text-decoration:underline;
transition: background-color 0.2s;
cursor: pointer;
&:hover{
   color:${props => shade(0.2, '#0088ff')};
}

`;
export const SubContainerNotif = styled.div`
display:flex;
flex:1;
height:100%;
flex-direction:column;
background-color: #FFF;
border-radius:0px 0px 5px 5px;
overflow:auto;
position: relative;
`;
export const ContainerMensagem = styled(Link)`
display:flex;
width:100%;
height:70px;
min-height:70px;
flex-direction:row;
border-bottom:0.2px solid #CCC;
text-decoration:none;
/* background-color: #FFF; */
cursor: pointer;
&:hover{
   background:${props => opacify(0.2, '#9fadb7')};
}
`;
export const ContainerImagem = styled.div`
display:flex;
width: 55px;
height:70px;
/* min-height:70px; */
flex-direction:row;
justify-content:center;
align-items:center;
`;
export const ContainerMensagemTexto = styled.div`
display:flex;
flex:1;
/* min-height:70px; */
height:70px;
flex-direction:column;
`;
export const ContainerTitle = styled.div`
display:flex;
flex:1;
/* min-height:70px; */
height:70px;
flex-direction:column;
padding:5px;
padding-left:10px;
padding-right:10px;
justify-content:center;
align-items:space-around;
`;
export const ContainerDataNotif = styled.div`
display:flex;
min-height:19px;
height:19px;
flex-direction:row;
justify-content:space-between;
padding-left:10px;
padding-right:10px;
align-items:center;
`;

export const TitleNotif = styled.p`
color:#000000;
font-size:13px;
font-weight:bold;
`;
export const SubTitleNotif = styled.p`
color:#000000;
font-size:13px;
max-width:290px;
overflow:hidden;
white-space:nowrap;
text-overflow:ellipsis;
`;
export const TextData = styled.p`
color:#000;
font-size:9px;
`;
export const TextNew = styled.p`
color:#0076B3;
font-size:8px;
font-weight:bold;
`;

export const ImageNotif = styled.img`
min-height:30px;
height:30px;
width:30px;
border-radius:50%;
background-size: contain;
background-repeat: no-repeat;

background:#CCC;
`;
export const LinkDropDesk = styled.a`

font-family: Source Sans Pro;
color: #7f8e9e;
text-decoration: none;
text-align: right;
font-size: 12px;
letter-spacing: 1px;
&:hover{
  color:${props => shade(0.2, '#7f8e9e')};
}
`;


export const DivCountChamadoEspera = styled.div`
height: 16px;
width: 16px;
max-width: 16px;
min-height: 16px;
display: flex;
align-items: center;
flex-direction:row;
justify-content: center;
font-size: 11px;
border-radius: 50%;
background: ${props => props.backgrounddinamico};;
font-weight: 600; 
color: ${props => props.corletradinamica};
padding-bottom:1px;
`;
export const ContainerModalCompraSucesso = styled.div`
 display:flex;
   overflow:auto;
 flex:1;
   width:550px;
   height:700px;
   min-height:300px;
   flex-direction:column;
   border-radius:7px;
   background:${props => props.backgroundDinamico};
`;
