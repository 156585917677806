import React from 'react';
import { connect } from 'react-redux';
import { ResponsivePie } from '@nivo/pie';
import { ResponsiveLine } from '@nivo/line'
import { toastr } from 'react-redux-toastr';
import { withRouter } from "react-router-dom";
import moment from 'moment';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController, isSameDay } from 'react-dates';
import SelectSearch from 'react-select-search';
import { Modal } from 'react-responsive-modal';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import {
    modificaCorBordaListaTarefas, insereDataFiltroDashboard, modificaDataInicialFinalRelatorioAtendimentoDetalhado,
    modificaFiltroSelecionadoRelatorioDetalhado,

    modificaClienteTelaRelatorioDetalhado,
    modificaAtendenteRelatorioDetalhado, modificaSetorRelatorioDetalhado,
    modificaFiltroTelaRelatorioDetalhado, modificaFiltroClienteSectionedTelaRelatorioDetalhado, modificaFiltroAtendenteSectionedTelaRelatorioDetalhado,
    modificaFiltroSetorSectionedTelaRelatorioDetalhado, modificaVisibilidadeModalInformacaoRelatorioDetalhado,
    insereTextosInformacionaisModalRelatorioDetalhado, zeraMensagemGerandoRelatorio, modificaDataInicialFinalRelatorioAtendimentoGeral,

    modificaVisibilidadeModalInformacaoRelatorioGeral, insereTextosInformacionaisModal,
    zeraModificaMensagemGerandoRelatorioVisaoGeral, listaAtendimentosRelatorioGeralPersonalizado,
    exportaAtendimentosParaExcelPdfVisaoGeral
} from '../../actions/DashBoardAction';
import { modificaNomeRotaNoHeader } from '../../actions/HeaderAction';

import {
    Title, Container, ContainerBox, Box, TextStatus, TextResultado, ContainerGrafico, SubContainerGrafico,
    ContainerTextGrafico, TextGrafico, ContainerAviso, TextAviso, SubContainerListaDeTarefas, TextTarefas,
    SubContainerInputEmail, InputEmail, DivContainerSelectDate, BotaoPesquisarChamadoPorNumero,
    ContainerGraficoTempoMedio, SubContainerGraficoTempoMedio, SubContainerGraficoTempoMedioSemSombra,
    ContainerTextTempoMedio, TextTempoMedio, ContainerIconesExport, IconeExport,
    ContainerTextTituloTempoMedio, DivTextPeriodo, TextPeriodo, ContainerInputSearch, IconeSair, DivPeriodoBotao,
    ContainerIconesExportTempoMedio, ContainerModal, ContainerIconeFecharModal, TextNovoAtendimento, ContainerExplicacaoVincular, TextVincular,
    IconeFechar,

    ContainerTextGraficoLoading, TextGraficoLoading, ContainerGraficoLoading, GraficoLoading,
    TextGraficoLoadingTempoMedio
} from './styled';

class RelatorioAtendimentoGeral extends React.Component {
    state = {
        startDate: null,
        endDate: null,
        focusedInput: null,
        dataLine: [
            {
                "id": "Clientes",
                "data": [
                    {
                        "x": "Heverton",
                        "y": 0
                    },
                    {
                        "x": "Rogerio",
                        "y": 1
                    },
                    {
                        "x": "Joao",
                        "y": 2
                    },
                    {
                        "x": "Sergio",
                        "y": 3
                    },
                    {
                        "x": "Juliana",
                        "y": 3
                    },
                    {
                        "x": "Julia",
                        "y": 4
                    },

                ]
            },
        ],
        data: [
            {
                id: "Baixa",
                label: "Baixa",
                value: 50,
                color: "hsl(195, 100%, 50%)"
            },
            {
                id: "Normal",
                label: "Normal",
                value: 61,
                color: "hsl(177, 100%, 41%)"
            },
            {
                id: "Alta",
                label: "Alta",
                value: 470,
                color: "hsl(42, 94%, 60%)"
            },
            {
                id: "Urgente",
                label: "Urgente",
                value: 148,
                color: "hsl(344, 94%, 51%)"
            },

        ],
        data2: [
            {
                id: "< 1 dia", label: "< 1 dia", value: 50, color: "hsl(150, 100%, 50%)"
            },
            {
                id: "1 dia", label: "1 dia", value: 96, color: "hsl(0, 0%, 83%)"
            },
            {
                id: "2 dias", label: "2 dias", value: 470, color: "hsl(42, 94%, 60%)"
            },
            {
                id: "3 dias", label: " 3dias", value: 148, color: "hsl(344, 94%, 51%)"
            },
            {
                id: "4 dias", label: " 4 dias", value: 148, color: "hsl(214, 41%, 78%)"
            },
            {
                id: "5 dias", label: " 5 dias", value: 148, color: "hsl(195, 100%, 50%)"
            },
            {
                id: "6 dias", label: " 6 dias", value: 148, color: "hsl(180, 100%, 50%)"
            },
            {
                id: "7 dias", label: " 7 dias", value: 148, color: "hsl(25, 75%, 47%)"
            },
            {
                id: "> 7 dias", label: "> 7 dias", value: 148, color: "hsl(180, 100%, 27%)"
            },

        ],
        data3: [
            {
                id: "Interno", label: "Interno", value: 50, color: "hsl(195, 100%, 50%)"
            },
            {
                id: "Externo", label: "Externo", value: 96, color: "hsl(214, 41%, 78%)"
            },
        ],
        aviso: [],
        tarefas: [
            { id: 1, tarefa: 'Aprender git hub' },
            { id: 2, tarefa: 'Instalar windows na maquina' }]
    }

    componentDidMount() {
        if (this.props.premium == 0) {
            toastr.error('Recurso Premium', 'Este é um recurso PREMIUM, Assine a versão PREMIUM e libere esse e mais recursos!');
            this.props.history.push('/dashboard')

        } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
            toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
            this.props.history.push('/dashboard')

        } else {
            if (this.props.permissoesAcessarTelasAtendente.permissaoAcessoRelatorios == true) {
                moment.locale('pt-br', {
                    months: 'Janeiro_Fevereiro_Março_Abril_Maio_Junho_Julho_Agosto_Setembro_Outubro_Novembro_Dezembro'.split('_')
                })
                this.props.listaAtendimentosRelatorioGeralPersonalizado(this.props.dataInicialConsultaFiltroRelatorioGeral, this.props.dataFinalConsultaFiltroRelatorioGeral, 1);
                if (this.props.nomeRota !== 'Relatório Atendimentos Geral') {
                    this.props.modificaNomeRotaNoHeader('Relatório Atendimentos Geral')
                }
            } else {
                toastr.error('Acesso Negado', 'Você não tem permissão para acessar esta tela');
                this.props.history.push('/dashboard')
            }
        }

    }
    removeFiltrosAll() {
        this.props.modificaFiltroSelecionadoRelatorioDetalhado('');
    }
    handleInputEmailBlur() {
        this.props.modificaCorBordaListaTarefas(this.props.corBordaDinamica);

    }
    renderTextoNivelSatistacao(media) {
        if (media == 'Sem Avaliações') {
            return null
        } else {
            let mediaFloat = parseFloat(media)
            //
            // 
            if (mediaFloat >= 5) {
                return (
                    <TextTempoMedio corLetraDinamica="#00FF7F">Excelente</TextTempoMedio>

                )
            } else if (mediaFloat >= 4 && mediaFloat < 5) {
                return (
                    <TextTempoMedio corLetraDinamica="#98FB98">Ótimo</TextTempoMedio>
                )
            }
            else if (mediaFloat >= 3 && mediaFloat < 4) {
                return (
                    <TextTempoMedio corLetraDinamica="#F9BF3B">Bom</TextTempoMedio>
                )
            } else if (mediaFloat >= 2 && mediaFloat < 3) {
                return (
                    <TextTempoMedio corLetraDinamica="#E67E22">Regular</TextTempoMedio>
                )
            } else {
                return (
                    <TextTempoMedio corLetraDinamica="red">Ruim</TextTempoMedio>
                )
            }
        }
    }
    handleInputEmailFocus() {
        this.props.modificaCorBordaListaTarefas(this.props.corBotaoPadrao);
    }
    handleWeekDays = (day) => {
        //Change week day with custom day array
        day._locale._weekdaysMin = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'];
        // return the actual dates value(like 1,2,3 ...) from the moment object.
        return (day.format('D'));
    }
    insereDataInicialFinal = ({ dataInicial, dataFinal }) => {
        //
        // 
        if (dataInicial !== null) {
            let dataInicio = moment(dataInicial, 'MM/DD/YYYY').format('DD/MM/YYYY');
            let state = this.state;
            state.startDate = dataInicio;
            this.setState({ state })
        }
        if (dataFinal !== null) {
            let dataFim = moment(dataFinal, 'MM/DD/YYYY').format('DD/MM/YYYY');
            let state = this.state;
            state.endDate = dataFim;
            this.setState({ state })
        }

    }
    renderLoadingAtendimentoGeral() {
        if (this.props.loadingRelatorioAtendimentoGeral) {
            return (
                <Container backgroundDinamico={this.props.backgroundPrimeiro}>
                    <ContainerGrafico>
                        <SubContainerGrafico backgroundDinamico={this.props.backgroundSegundo}>
                            <ContainerTextGraficoLoading>
                                <TextGraficoLoading backgroundinamico={this.props.backgroundSegundo}></TextGraficoLoading>
                            </ContainerTextGraficoLoading>
                            <ContainerGraficoLoading>
                                <GraficoLoading backgroundinamico={this.props.backgroundSegundo}></GraficoLoading>
                            </ContainerGraficoLoading>
                        </SubContainerGrafico>
                        <SubContainerGrafico backgroundDinamico={this.props.backgroundSegundo}>
                            <ContainerTextGraficoLoading>
                                <TextGraficoLoading backgroundinamico={this.props.backgroundSegundo}></TextGraficoLoading>
                            </ContainerTextGraficoLoading>
                            <ContainerGraficoLoading>
                                <GraficoLoading backgroundinamico={this.props.backgroundSegundo}></GraficoLoading>
                            </ContainerGraficoLoading>
                        </SubContainerGrafico>

                    </ContainerGrafico>
                    <ContainerGrafico >
                        <SubContainerGrafico backgroundDinamico={this.props.backgroundSegundo}>
                            <ContainerTextGraficoLoading>
                                <TextGraficoLoading backgroundinamico={this.props.backgroundSegundo}></TextGraficoLoading>
                            </ContainerTextGraficoLoading>
                            <ContainerGraficoLoading>
                                <GraficoLoading backgroundinamico={this.props.backgroundSegundo}></GraficoLoading>
                            </ContainerGraficoLoading>
                        </SubContainerGrafico >
                        <SubContainerGrafico backgroundDinamico={this.props.backgroundSegundo}>
                            <ContainerTextGraficoLoading>
                                <TextGraficoLoading backgroundinamico={this.props.backgroundSegundo}></TextGraficoLoading>
                            </ContainerTextGraficoLoading>
                            <ContainerGraficoLoading>
                                <GraficoLoading backgroundinamico={this.props.backgroundSegundo}></GraficoLoading>
                            </ContainerGraficoLoading>

                        </SubContainerGrafico>
                    </ContainerGrafico>
                    <ContainerGraficoTempoMedio>
                        <SubContainerGraficoTempoMedio backgroundDinamico={this.props.backgroundSegundo}>
                            <ContainerTextTituloTempoMedio>
                                <TextGraficoLoadingTempoMedio backgroundinamico={this.props.backgroundSegundo}></TextGraficoLoadingTempoMedio>
                            </ContainerTextTituloTempoMedio>
                            <ContainerTextTempoMedio>

                                <TextGraficoLoadingTempoMedio backgroundinamico={this.props.backgroundSegundo}></TextGraficoLoadingTempoMedio>
                            </ContainerTextTempoMedio>
                        </SubContainerGraficoTempoMedio>
                        <SubContainerGraficoTempoMedio backgroundDinamico={this.props.backgroundSegundo}>
                            <ContainerTextTituloTempoMedio>
                                <TextGraficoLoadingTempoMedio backgroundinamico={this.props.backgroundSegundo}></TextGraficoLoadingTempoMedio>
                            </ContainerTextTituloTempoMedio>
                            <ContainerTextTempoMedio>

                                <TextGraficoLoadingTempoMedio backgroundinamico={this.props.backgroundSegundo}></TextGraficoLoadingTempoMedio>
                            </ContainerTextTempoMedio>
                        </SubContainerGraficoTempoMedio>
                    </ContainerGraficoTempoMedio>

                </Container >
            )
        } else {
            return (
                <Container backgroundDinamico={this.props.backgroundPrimeiro}>

                    <DivContainerSelectDate>
                        <div style={{}}>
                            <DivTextPeriodo>
                                <i className="icon-calendarweb" style={{ color: this.props.coresLetraPadrao, fontSize: 15, marginRight: 3 }} />
                                <TextPeriodo corLetraDinamica={this.props.coresLetraPadrao}>Período:</TextPeriodo>
                            </DivTextPeriodo>
                            <DivPeriodoBotao >
                                <DateRangePicker
                                    // customInputIcon={<i className="icon-calendar" style={{ color: '#000', marginRight: 5 }} />}
                                    //   customArrowIcon={<Icon icon="next" size="xxs" />}
                                    // customCloseIcon={<i className="icon-close" style={{ color: '#000', marginRight: 5 }} />}
                                    displayFormat={() => "DD/MM/YYYY"}
                                    small
                                    isOutsideRange={() => false}
                                    minimumNights={0}
                                    firstDayOfWeek={1}
                                    renderDayContents={this.handleWeekDays}
                                    isDayHighlighted={day => isSameDay(moment(new Date()), moment(day))}
                                    hideKeyboardShortcutsPanel
                                    startDatePlaceholderText="Data Inicial"
                                    endDatePlaceholderText="Data Final"
                                    startDate={this.props.dataInicialConsultaFiltroRelatorioGeral} // momentPropTypes.momentObj or null,
                                    startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                                    endDate={this.props.dataFinalConsultaFiltroRelatorioGeral} // momentPropTypes.momentObj or null,
                                    endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                                    //onDatesChange={this.insereDataInicialFinal} // PropTypes.func.isRequired,
                                    onDatesChange={({ startDate, endDate }) => this.props.modificaDataInicialFinalRelatorioAtendimentoGeral(startDate, endDate)}
                                    focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                    onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                                />
                                {this.props.dataInicialConsultaFiltroRelatorioGeral !== null && this.props.dataFinalConsultaFiltroRelatorioGeral !== null && <BotaoPesquisarChamadoPorNumero onClick={() => {
                                    this.props.listaAtendimentosRelatorioGeralPersonalizado(this.props.dataInicialConsultaFiltroRelatorioGeral, this.props.dataFinalConsultaFiltroRelatorioGeral, 0);
                                }} backGroundDinamico={this.props.corBotaoPadrao} type="button">
                                    <i className="icon-magnifier" style={{ color: this.props.letraBotaoPadrao, fontSize: 15 }} />
                                </BotaoPesquisarChamadoPorNumero>}
                            </DivPeriodoBotao>
                        </div>

                    </DivContainerSelectDate>

                    <ContainerGrafico>
                        <SubContainerGrafico backgroundDinamico={this.props.backgroundSegundo}>
                            <ContainerTextGrafico>
                                <div style={{ width: 80, height: 30 }}></div>
                                <TextGrafico colorDinamico={this.props.coresLetraPadrao}>Clientes com mais Atendimentos</TextGrafico>
                                <ContainerIconesExport>
                                    <IconeExport onClick={() => {
                                        this.props.exportaAtendimentosParaExcelPdfVisaoGeral(this.props.arrayPessoasComMaisAtendimentosParaDownload, 'clientes', 'PDF')
                                    }} colorDinamico="red" className="icon-pdf2" />
                                    <IconeExport onClick={() => {
                                        this.props.exportaAtendimentosParaExcelPdfVisaoGeral(this.props.arrayPessoasComMaisAtendimentosParaDownload, 'clientes', 'EXCEL')
                                    }} colorDinamico="#32CD32" className="icon-spreadsheet" />
                                    <IconeExport onClick={() => {
                                        this.props.insereTextosInformacionaisModal(
                                            'Clientes com + Atendimentos',
                                            ' Com o Relatório de Clientes com mais Atendimentos você consegue acompanhar qual cliente abriu mais atendimentos no período.',
                                            ' Com estes dados, você consegue analisar qual cliente precisa de mais atenção e por qual motivo este cliente abre tantos atendimentos. Para que futuramente não haja uma insatisfação por parte do cliente e por fim um rompimento de contrato.'
                                        )
                                    }} colorDinamico="#FFF" className="icon-danger1" />
                                </ContainerIconesExport>
                            </ContainerTextGrafico>

                            <ResponsiveLine
                                data={this.props.clientesMaisAbriramChamadosRelatorioGeral}
                                margin={{ top: 10, right: 110, bottom: 70, left: 60 }}
                                xScale={{ type: 'point' }}
                                yScale={{ type: 'linear', stacked: true, reverse: false }}
                                axisTop={null}
                                axisRight={null}

                                // enableArea
                                curve="catmullRom"
                                colors={this.props.corBotaoPadrao}
                                pointSize={9}
                                pointColor={{ from: 'color', modifiers: [] }}
                                pointBorderWidth={2}
                                pointBorderColor={{ from: 'serieColor' }}
                                pointLabel="y"
                                pointLabelYOffset={-12}
                                sliceTooltip={({ slice }) => {
                                    return (
                                        <div>
                                            {slice.points.map(point => (

                                                <div style={{ background: this.props.corBotaoPadrao, padding: 5, borderRadius: 5 }} key={point.id}>
                                                    <strong style={{ color: this.props.letraBotaoPadrao }}>
                                                        {`${point.data.xFormatted} - ${point.data.yFormatted}`}
                                                    </strong>
                                                </div>
                                            ))}
                                        </div>
                                    );
                                }}
                                enableSlices="x"
                                theme={{
                                    axis: {
                                        ticks: {
                                            line: {
                                                stroke: this.props.coresLetraPadrao
                                            },
                                            text: {
                                                fill: this.props.coresLetraPadrao
                                            }
                                        }
                                    },
                                    grid: {
                                        line: {
                                            stroke: this.props.coresLetraSecundario,
                                            strokeWidth: 2,
                                            strokeDasharray: "4 4"
                                        }
                                    }
                                }}
                                useMesh={true}
                            /*   legends={[
                                  {
                                      anchor: 'bottom-right',
                                      direction: 'column',
                                      justify: false,
                                      translateX: 100,
                                      translateY: 0,
                                      itemsSpacing: 0,
                                      itemDirection: 'left-to-right',
                                      itemWidth: 80,
                                      itemHeight: 20,
                                      itemOpacity: 0.75,
                                      symbolSize: 12,
                                      symbolShape: 'circle',
                                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                                      effects: [
                                          {
                                              on: 'hover',
                                              style: {
                                                  itemBackground: 'rgba(0, 0, 0, .03)',
                                                  itemOpacity: 1
                                              }
                                          }
                                      ]
                                  }
                              ]} */
                            />

                        </SubContainerGrafico>
                        <SubContainerGrafico backgroundDinamico={this.props.backgroundSegundo}>
                            <ContainerTextGrafico>
                                <div style={{ width: 80, height: 30 }}></div>
                                <TextGrafico colorDinamico={this.props.coresLetraPadrao}>Atendentes com mais Atendimentos</TextGrafico>
                                <ContainerIconesExport>
                                    <IconeExport onClick={() => {
                                        this.props.exportaAtendimentosParaExcelPdfVisaoGeral(this.props.arrayPessoasComMaisAtendimentosParaDownload, 'atendentes', 'PDF')
                                    }} colorDinamico="red" className="icon-pdf2" />
                                    <IconeExport onClick={() => {
                                        this.props.exportaAtendimentosParaExcelPdfVisaoGeral(this.props.arrayPessoasComMaisAtendimentosParaDownload, 'atendentes', 'EXCEL')
                                    }} colorDinamico="#32CD32" className="icon-spreadsheet" />
                                    <IconeExport onClick={() => {
                                        this.props.insereTextosInformacionaisModal(
                                            'Atendentes com + Atendimentos',
                                            ' Com o Relatório de Atendentes que MAIS atenderam um chamado, você consegue acompanhar a produtividade de seus Atendentes no período.',
                                            ' É sempre importante que você saiba quais são os seus atendentes mais produtivos, qual a média de atendimentos diários da sua equipe e o impacto disso para os seus negócios.'
                                        )
                                    }} colorDinamico="#FFF" className="icon-danger1" />
                                </ContainerIconesExport>
                            </ContainerTextGrafico>


                            <ResponsiveLine
                                data={this.props.atendentesMaisAbriramChamadosRelatorioGeral}
                                margin={{ top: 10, right: 110, bottom: 70, left: 60 }}
                                xScale={{ type: 'point' }}
                                yScale={{ type: 'linear', stacked: true, reverse: false }}
                                axisTop={null}
                                axisRight={null}

                                // enableArea
                                curve="catmullRom"
                                colors={this.props.corBotaoPadrao}
                                pointSize={9}
                                pointColor={{ from: 'color', modifiers: [] }}
                                pointBorderWidth={2}
                                pointBorderColor={{ from: 'serieColor' }}
                                pointLabel="y"
                                pointLabelYOffset={-12}
                                sliceTooltip={({ slice }) => {
                                    return (
                                        <div>
                                            {slice.points.map(point => (
                                                <div style={{ background: this.props.corBotaoPadrao, padding: 5, borderRadius: 5 }} key={point.id}>
                                                    <strong style={{ color: this.props.letraBotaoPadrao }}>
                                                        {`${point.data.xFormatted} - ${point.data.yFormatted}`}
                                                    </strong>
                                                </div>
                                            ))}
                                        </div>
                                    );
                                }}
                                enableSlices="x"
                                theme={{
                                    axis: {
                                        ticks: {
                                            line: {
                                                stroke: this.props.coresLetraPadrao
                                            },
                                            text: {
                                                fill: this.props.coresLetraPadrao
                                            }
                                        }
                                    },
                                    grid: {
                                        line: {
                                            stroke: this.props.coresLetraSecundario,
                                            strokeWidth: 2,
                                            strokeDasharray: "4 4"
                                        }
                                    }
                                }}
                                useMesh={true}
                            /*   legends={[
                                  {
                                      anchor: 'bottom-right',
                                      direction: 'column',
                                      justify: false,
                                      translateX: 100,
                                      translateY: 0,
                                      itemsSpacing: 0,
                                      itemDirection: 'left-to-right',
                                      itemWidth: 80,
                                      itemHeight: 20,
                                      itemOpacity: 0.75,
                                      symbolSize: 12,
                                      symbolShape: 'circle',
                                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                                      effects: [
                                          {
                                              on: 'hover',
                                              style: {
                                                  itemBackground: 'rgba(0, 0, 0, .03)',
                                                  itemOpacity: 1
                                              }
                                          }
                                      ]
                                  }
                              ]} */
                            />

                        </SubContainerGrafico>

                    </ContainerGrafico>
                    <ContainerGrafico >
                        <SubContainerGrafico backgroundDinamico={this.props.backgroundSegundo}>
                            <ContainerTextGrafico>
                                <div style={{ width: 80, height: 30 }}></div>
                                <TextGrafico colorDinamico={this.props.coresLetraPadrao}>Setores com mais Atendimentos</TextGrafico>
                                <ContainerIconesExport>
                                    <IconeExport onClick={() => {
                                        this.props.exportaAtendimentosParaExcelPdfVisaoGeral(this.props.arrayPessoasComMaisAtendimentosParaDownload, 'setores', 'PDF')
                                    }} colorDinamico="red" className="icon-pdf2" />
                                    <IconeExport onClick={() => {
                                        this.props.exportaAtendimentosParaExcelPdfVisaoGeral(this.props.arrayPessoasComMaisAtendimentosParaDownload, 'setores', 'EXCEL')
                                    }} colorDinamico="#32CD32" className="icon-spreadsheet" />
                                    <IconeExport onClick={() => {
                                        this.props.insereTextosInformacionaisModal(
                                            'Setores com + Atendimentos',
                                            ' Ao categorizar os atendimentos é possível obter uma análise mais precisa sobre os atendimentos que anda recebendo. Assim entende-se o comportamento do usuário em cada categoria.',
                                            ' Concluindo quais são os setores que andam recebendo mais atendimentos, quais precisam de mais atenção e o que cada usuário espera de determinada categoria.'
                                        )
                                    }} colorDinamico="#FFF" className="icon-danger1" />
                                </ContainerIconesExport>
                            </ContainerTextGrafico>


                            <ResponsiveLine
                                data={this.props.setoresMaisAbriramChamadosRelatorioGeral}
                                margin={{ top: 10, right: 110, bottom: 70, left: 60 }}
                                xScale={{ type: 'point' }}
                                yScale={{ type: 'linear', stacked: true, reverse: false }}
                                axisTop={null}
                                axisRight={null}

                                // enableArea
                                curve="catmullRom"
                                colors={this.props.corBotaoPadrao}
                                pointSize={9}
                                pointColor={{ from: 'color', modifiers: [] }}
                                pointBorderWidth={2}
                                pointBorderColor={{ from: 'serieColor' }}
                                pointLabel="y"
                                pointLabelYOffset={-12}
                                sliceTooltip={({ slice }) => {
                                    return (
                                        <div>
                                            {slice.points.map(point => (
                                                <div style={{ background: this.props.corBotaoPadrao, padding: 5, borderRadius: 5 }} key={point.id}>
                                                    <strong style={{ color: this.props.letraBotaoPadrao }}>
                                                        {`${point.data.xFormatted} - ${point.data.yFormatted}`}
                                                    </strong>
                                                </div>
                                            ))}
                                        </div>
                                    );
                                }}
                                enableSlices="x"
                                theme={{
                                    axis: {
                                        ticks: {
                                            line: {
                                                stroke: this.props.coresLetraPadrao
                                            },
                                            text: {
                                                fill: this.props.coresLetraPadrao
                                            }
                                        }
                                    },
                                    grid: {
                                        line: {
                                            stroke: this.props.coresLetraSecundario,
                                            strokeWidth: 2,
                                            strokeDasharray: "4 4"
                                        }
                                    }
                                }}
                                useMesh={true}
                            /*   legends={[
                                  {
                                      anchor: 'bottom-right',
                                      direction: 'column',
                                      justify: false,
                                      translateX: 100,
                                      translateY: 0,
                                      itemsSpacing: 0,
                                      itemDirection: 'left-to-right',
                                      itemWidth: 80,
                                      itemHeight: 20,
                                      itemOpacity: 0.75,
                                      symbolSize: 12,
                                      symbolShape: 'circle',
                                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                                      effects: [
                                          {
                                              on: 'hover',
                                              style: {
                                                  itemBackground: 'rgba(0, 0, 0, .03)',
                                                  itemOpacity: 1
                                              }
                                          }
                                      ]
                                  }
                              ]} */
                            />

                        </SubContainerGrafico >
                        <SubContainerGrafico backgroundDinamico={this.props.backgroundSegundo}>
                            <ContainerTextGrafico>
                                <div style={{ width: 80, height: 30 }}></div>
                                <TextGrafico colorDinamico={this.props.coresLetraPadrao}>Problemas Estáticos + Atendimentos</TextGrafico>
                                <ContainerIconesExport>
                                    <IconeExport onClick={() => {
                                        this.props.exportaAtendimentosParaExcelPdfVisaoGeral(this.props.arrayPessoasComMaisAtendimentosParaDownload, 'problemas', 'PDF')
                                    }} colorDinamico="red" className="icon-pdf2" />
                                    <IconeExport onClick={() => {
                                        this.props.exportaAtendimentosParaExcelPdfVisaoGeral(this.props.arrayPessoasComMaisAtendimentosParaDownload, 'problemas', 'EXCEL')
                                    }} colorDinamico="#32CD32" className="icon-spreadsheet" />
                                    <IconeExport onClick={() => {
                                        this.props.insereTextosInformacionaisModal(
                                            'Problemas Estáticos + Atendimentos',
                                            ' Com o relatório de Problemas Estáticos você consegue analisar qual o problema mais recorrente da sua organização .',
                                            ' Concluindo quais são os Problemas que andam recebendo mais atendimentos, quais precisam de mais atenção.'
                                        )
                                    }} colorDinamico="#FFF" className="icon-danger1" />
                                </ContainerIconesExport>
                            </ContainerTextGrafico>

                            <ResponsiveLine
                                data={this.props.problemasMaisAbriramChamadosRelatorioGeral}
                                margin={{ top: 10, right: 110, bottom: 70, left: 60 }}
                                xScale={{ type: 'point' }}
                                yScale={{ type: 'linear', stacked: true, reverse: false }}
                                axisTop={null}
                                axisRight={null}

                                //  enableArea
                                curve="catmullRom"
                                colors={this.props.corBotaoPadrao}
                                pointSize={9}
                                pointColor={{ from: 'color', modifiers: [] }}
                                pointBorderWidth={2}
                                pointBorderColor={{ from: 'serieColor' }}
                                pointLabel="y"
                                pointLabelYOffset={-12}
                                sliceTooltip={({ slice }) => {
                                    return (
                                        <div>
                                            {slice.points.map(point => (
                                                <div style={{ background: this.props.corBotaoPadrao, padding: 5, borderRadius: 5 }} key={point.id}>
                                                    <strong style={{ color: this.props.letraBotaoPadrao }}>
                                                        {`${point.data.xFormatted} - ${point.data.yFormatted}`}
                                                    </strong>
                                                </div>
                                            ))}
                                        </div>
                                    );
                                }}
                                enableSlices="x"
                                theme={{
                                    axis: {
                                        ticks: {
                                            line: {
                                                stroke: this.props.coresLetraPadrao
                                            },
                                            text: {
                                                fill: this.props.coresLetraPadrao
                                            }
                                        }
                                    },
                                    grid: {
                                        line: {
                                            stroke: this.props.coresLetraSecundario,
                                            strokeWidth: 2,
                                            strokeDasharray: "4 4"
                                        }
                                    }
                                }}
                                useMesh={true}
                            /*   legends={[
                                  {
                                      anchor: 'bottom-right',
                                      direction: 'column',
                                      justify: false,
                                      translateX: 100,
                                      translateY: 0,
                                      itemsSpacing: 0,
                                      itemDirection: 'left-to-right',
                                      itemWidth: 80,
                                      itemHeight: 20,
                                      itemOpacity: 0.75,
                                      symbolSize: 12,
                                      symbolShape: 'circle',
                                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                                      effects: [
                                          {
                                              on: 'hover',
                                              style: {
                                                  itemBackground: 'rgba(0, 0, 0, .03)',
                                                  itemOpacity: 1
                                              }
                                          }
                                      ]
                                  }
                              ]} */
                            />

                        </SubContainerGrafico>
                    </ContainerGrafico>
                    <ContainerGraficoTempoMedio>
                        <SubContainerGraficoTempoMedio backgroundDinamico={this.props.backgroundSegundo}>
                            <ContainerTextTituloTempoMedio>
                                <div style={{ width: 30, height: 30 }}></div>
                                <TextGrafico colorDinamico={this.props.coresLetraPadrao}>Tempo Médio Solução dos Atendimentos</TextGrafico>
                                <ContainerIconesExportTempoMedio>
                                    <IconeExport onClick={() => {
                                        this.props.insereTextosInformacionaisModal(
                                            'Tempo Médio Solução',
                                            ' O Tempo Médio de Solução dos Atendimentos define em média o tempo em que o atendimento fica dentro do seu sistema até ser solucionado permanentemente.',
                                            ' O Tempo Médio de Solução dos Atendimentos é bom para ver o quanto a sua equipe de suporte é responsiva em relação ao volume de atendimentos que recebe.'
                                        )
                                    }} colorDinamico="#FFF" className="icon-danger1" style={{ marginRight: 5 }} />
                                </ContainerIconesExportTempoMedio>
                            </ContainerTextTituloTempoMedio>
                            <ContainerTextTempoMedio>
                                <TextTempoMedio corLetraDinamica={this.props.coresLetraPadrao}>{this.props.tempoMedioSolucaoAtendimentoRelatorioGeral}</TextTempoMedio>
                            </ContainerTextTempoMedio>
                        </SubContainerGraficoTempoMedio>
                        <SubContainerGraficoTempoMedio backgroundDinamico={this.props.backgroundSegundo}>
                            <ContainerTextTituloTempoMedio>
                                <div style={{ width: 30, height: 30 }}></div>
                                <TextGrafico colorDinamico={this.props.coresLetraPadrao}>Média Geral Satisfação - Escala de (1 a 5)</TextGrafico>
                                <ContainerIconesExportTempoMedio>
                                    <IconeExport onClick={() => {
                                        this.props.insereTextosInformacionaisModal(
                                            'Média Geral Satisfação',
                                            ' A satisfação do consumidor talvez seja a métrica mais importante. Esta é a métrica que irá dar uma ideia geral de todas as métricas.',
                                            ' A satisfação irá idealizar como a sua equipe está agindo por inteiro. Caso todas as outras métricas estejam alinhadas a satisfação será grande.'
                                        )
                                    }} colorDinamico="#FFF" className="icon-danger1" style={{ marginRight: 5 }} />
                                </ContainerIconesExportTempoMedio>
                            </ContainerTextTituloTempoMedio>
                            <ContainerTextTempoMedio>
                                <TextTempoMedio style={{ marginRight: 5 }} corLetraDinamica={this.props.coresLetraPadrao}>{this.props.mediaSatisfacaoRelatorioGeral} </TextTempoMedio>
                                {this.renderTextoNivelSatistacao(this.props.mediaSatisfacaoRelatorioGeral)}
                            </ContainerTextTempoMedio>
                        </SubContainerGraficoTempoMedio>
                    </ContainerGraficoTempoMedio>
                    <ContainerGraficoTempoMedio style={{marginTop:15}}>
                        <SubContainerGraficoTempoMedio backgroundDinamico={this.props.backgroundSegundo}>
                            <ContainerTextTituloTempoMedio>
                                <div style={{ width: 55, height: 30 }}></div>
                                <TextGrafico colorDinamico={this.props.coresLetraPadrao}>Tempo Médio da Primeira Resposta</TextGrafico>
                                <ContainerIconesExportTempoMedio>
                                    <IconeExport onClick={() => {
                                        this.props.insereTextosInformacionaisModal(
                                            'Tempo Médio Primeira Resposta',
                                            ' O Tempo de Primeira Resposta diz respeito a agilidade do retorno de um chamado que o cliente abre para o suporte da empresa. Serão contados os minutos entre o horário que o chamado é criado e o retorno (data/hora que o atendente é atribuído ao chamado).',
                                            ' Quando existe a análise correta constante desse tempo, é possível verificar se o atendimento consegue alcançar as soluções de forma ágil. Aqui, é importante observar que o tempo de primeira resposta no atendimento deve considerar se o problema do cliente foi resolvido.'
                                        )
                                    }} colorDinamico="#FFF" className="icon-danger1" style={{ marginRight: 5 }} />
                                </ContainerIconesExportTempoMedio>
                            </ContainerTextTituloTempoMedio>
                            <ContainerTextTempoMedio>
                                <TextTempoMedio corLetraDinamica={this.props.coresLetraPadrao}>{this.props.tempoMedioPrimeiraResposta}</TextTempoMedio>
                            </ContainerTextTempoMedio>
                        </SubContainerGraficoTempoMedio>
                        <SubContainerGraficoTempoMedioSemSombra backgroundDinamico={this.props.backgroundPrimeiro}>

                        </SubContainerGraficoTempoMedioSemSombra>
                    </ContainerGraficoTempoMedio>
                    <Modal focusTrapped={false} showCloseIcon={false} open={this.props.modalInformacaoRelatorioGeral} onClose={() => { }} center>
                        <ContainerModal backgroundDinamico={this.props.backgroundPrimeiro}>
                            <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                                <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>{this.props.textCabecalhoModalInf}</TextNovoAtendimento>
                                <div>
                                    <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => { this.props.modificaVisibilidadeModalInformacaoRelatorioGeral(false) }} className="icon-close" />
                                </div>
                            </ContainerIconeFecharModal>


                            <ContainerExplicacaoVincular>
                                <TextVincular corLetraDinamica={this.props.coresLetraPadrao}>{this.props.text1ModalInf}</TextVincular>
                            </ContainerExplicacaoVincular>
                            <ContainerExplicacaoVincular>
                                <TextVincular corLetraDinamica={this.props.coresLetraPadrao}>{this.props.text2ModalInf}</TextVincular>
                            </ContainerExplicacaoVincular>

                        </ContainerModal>
                    </Modal>
                </Container >
            )
        }
    }
    render() {

        return (
            <>
                {this.renderLoadingAtendimentoGeral()}
            </>
        );
    }
}

const mapStateToProps = state => {
    return (
        {

            backgroundPrimeiro: state.CoresPadraoSistema.backgroundPrimeiro,
            backgroundSegundo: state.CoresPadraoSistema.backgroundSegundo,
            backgroundMaisEscuro: state.CoresPadraoSistema.backgroundMaisEscuro,
            coresLetraPadrao: state.CoresPadraoSistema.coresLetraPadrao,
            corBotaoPadrao: state.CoresPadraoSistema.corBotaoPadrao,
            corSegundoBotaoPadrao: state.CoresPadraoSistema.corSegundoBotaoPadrao,
            coresLetraSecundario: state.CoresPadraoSistema.coresLetraSecundario,
            corLetraDescricaoDosCampos: state.CoresPadraoSistema.corLetraDescricaoDosCampos,
            nomeRota: state.HeaderReducer.nomeRota,
            corBordaPadrao: state.CoresPadraoSistema.corBordaPadrao,
            corBordaDinamica: state.CoresPadraoSistema.corBordaDinamica,


            loadingRelatorioDetalhado: state.DashBoardReducer.loadingRelatorioDetalhado,
            loadingSemInternetRelatorioDetalhado: state.DashBoardReducer.loadingSemInternetRelatorioDetalhado,
            loadingRefreshControlRelatorioDetalhado: state.DashBoardReducer.loadingRefreshControlRelatorioDetalhado,
            periodoPersonalizadoRelatorioDetalhado: state.DashBoardReducer.periodoPersonalizadoRelatorioDetalhado,

            atendimentosAEFC: state.DashBoardReducer.atendimentosAEFC,
            totalAtendimentosAEFC: state.DashBoardReducer.totalAtendimentosAEFC,
            atendimentosPorPrioridadeRelatorioDetalhado: state.DashBoardReducer.atendimentosPorPrioridadeRelatorioDetalhado,
            totalAtendimentosPorPrioridadeRelatorioDetalhado: state.DashBoardReducer.totalAtendimentosPorPrioridadeRelatorioDetalhado,


            atendimentosPorTempoSolucaoRelatorioDetalhado: state.DashBoardReducer.atendimentosPorTempoSolucaoRelatorioDetalhado,
            totalAtendimentosPorTempoSolucaoRelatorioDetalhado: state.DashBoardReducer.totalAtendimentosPorTempoSolucaoRelatorioDetalhado,
            atendimentosInternoExternoRelatorioDetalhado: state.DashBoardReducer.atendimentosInternoExternoRelatorioDetalhado,
            totalAtendimentosInternoExternoRelatorioDetalhado: state.DashBoardReducer.totalAtendimentosInternoExternoRelatorioDetalhado,
            filtroRelatorioDetalhado: state.DashBoardReducer.filtroRelatorioDetalhado,


            clientesRelatorioAtendimentoDetalhado: state.ChamadoReducer.clientesSectionedConsulta,
            clienteSelecionadoRelatorioAtendimentoDetalhado: state.DashBoardReducer.clienteSelecionadoRelatorioAtendimentoDetalhado,
            atendentesRelatorioAtendimentoDetalhado: state.AtendenteReducer.atendentesSectionedConsulta,
            atendentesSelecionadoRelatorioAtendimentoDetalhado: state.DashBoardReducer.atendentesSelecionadoRelatorioAtendimentoDetalhado,
            SetoresRelatorioAtendimentoDetalhado:state.SetorReducer.setoresSectionedConsulta,
            SetoresSelecionadoRelatorioAtendimentoDetalhado: state.DashBoardReducer.SetoresSelecionadoRelatorioAtendimentoDetalhado,

            loadingClientesRelatorioAtendimentoDetalhado: state.DashBoardReducer.loadingClientesRelatorioAtendimentoDetalhado,
            loadingAtendentesRelatorioAtendimentoDetalhado: state.DashBoardReducer.loadingAtendentesRelatorioAtendimentoDetalhado,
            loadingSetoresRelatorioAtendimentoDetalhado: state.DashBoardReducer.loadingSetoresRelatorioAtendimentoDetalhado,

            filtroClienteSectioned: state.DashBoardReducer.filtroClienteSectioned,
            filtroAtendenteSectioned: state.DashBoardReducer.filtroAtendenteSectioned,
            filtroSetorSectioned: state.DashBoardReducer.filtroSetorSectioned,
            tempoMedioPrimeiraResposta: state.DashBoardReducer.tempoMedioPrimeiraResposta,

            dataInicialConsultaFiltroRelatorioDetalhado: state.DashBoardReducer.dataInicialConsultaFiltroRelatorioDetalhado,
            dataFinalConsultaFiltroRelatorioDetalhado: state.DashBoardReducer.dataFinalConsultaFiltroRelatorioDetalhado,

            tempoMedioParaSolucaoAtendimento: state.DashBoardReducer.tempoMedioParaSolucaoAtendimento,

            modalInformacaoRelatorioDetalhado: state.DashBoardReducer.modalInformacaoRelatorioDetalhado,

            textCabecalhoModalInfoRelatorioDetalhado: state.DashBoardReducer.textCabecalhoModalInfoRelatorioDetalhado,
            text1ModalInfRelatorioDetalhado: state.DashBoardReducer.text1ModalInfRelatorioDetalhado,
            text2ModalInfRelatorioDetalhado: state.DashBoardReducer.text2ModalInfRelatorioDetalhado,

            arrayChamadosDetalhadosParaDownload: state.DashBoardReducer.arrayChamadosDetalhadosParaDownload,
            mensagemGerandoRelatorio: state.DashBoardReducer.mensagemGerandoRelatorio,

            filtroSelecionadoRelatorioDetalhado: state.DashBoardReducer.filtroSelecionadoRelatorioDetalhado,

            loadingRelatorioAtendimentoGeral: state.DashBoardReducer.loadingRelatorioAtendimentoGeral,
            semInternetRelatorioGeral: state.DashBoardReducer.semInternetRelatorioGeral,
            loadingRefreshControlRelatorioGeral: state.DashBoardReducer.loadingRefreshControlRelatorioGeral,
            periodoPesquisaRelatorioGeral: state.DashBoardReducer.periodoPesquisaRelatorioGeral,

            clientesMaisAbriramChamadosRelatorioGeral: state.DashBoardReducer.clientesMaisAbriramChamadosRelatorioGeral,
            atendentesMaisAbriramChamadosRelatorioGeral: state.DashBoardReducer.atendentesMaisAbriramChamadosRelatorioGeral,
            setoresMaisAbriramChamadosRelatorioGeral: state.DashBoardReducer.setoresMaisAbriramChamadosRelatorioGeral,

            tempoMedioSolucaoAtendimentoRelatorioGeral: state.DashBoardReducer.tempoMedioSolucaoAtendimentoRelatorioGeral,
            mediaSatisfacaoRelatorioGeral: state.DashBoardReducer.mediaSatisfacaoRelatorioGeral,
            premium: state.PremiumReducer.premium,
            dataInicialConsultaFiltroRelatorioGeral: state.DashBoardReducer.dataInicialConsultaFiltroRelatorioGeral,
            dataFinalConsultaFiltroRelatorioGeral: state.DashBoardReducer.dataFinalConsultaFiltroRelatorioGeral,
            loadingSemInternetRelatorioGeral: state.DashBoardReducer.loadingSemInternetRelatorioGeral,
            modalInformacaoRelatorioGeral: state.DashBoardReducer.modalInformacaoRelatorioGeral,
            permissoesAcessarTelasAtendente: state.AtendenteReducer.permissoesAcessarTelasAtendente,
            textCabecalhoModalInf: state.DashBoardReducer.textCabecalhoModalInf,
            text1ModalInf: state.DashBoardReducer.text1ModalInf,
            text2ModalInf: state.DashBoardReducer.text2ModalInf,
            arrayPessoasComMaisAtendimentosParaDownload: state.DashBoardReducer.arrayPessoasComMaisAtendimentosParaDownload,
            problemasMaisAbriramChamadosRelatorioGeral: state.DashBoardReducer.problemasMaisAbriramChamadosRelatorioGeral,
            mensagemGerandoRelatorioVisaoGeral: state.DashBoardReducer.mensagemGerandoRelatorioVisaoGeral,
            licensawebouapp: state.PremiumReducer.licensawebouapp,
        }
    );
};
export default connect(mapStateToProps, {
    modificaCorBordaListaTarefas, insereDataFiltroDashboard, modificaDataInicialFinalRelatorioAtendimentoDetalhado,
    modificaFiltroSelecionadoRelatorioDetalhado,
    modificaClienteTelaRelatorioDetalhado,
    modificaAtendenteRelatorioDetalhado, modificaSetorRelatorioDetalhado,
    modificaFiltroTelaRelatorioDetalhado, modificaFiltroClienteSectionedTelaRelatorioDetalhado, modificaFiltroAtendenteSectionedTelaRelatorioDetalhado,
    modificaFiltroSetorSectionedTelaRelatorioDetalhado, modificaVisibilidadeModalInformacaoRelatorioDetalhado,
    insereTextosInformacionaisModalRelatorioDetalhado, zeraMensagemGerandoRelatorio,
    modificaDataInicialFinalRelatorioAtendimentoGeral,

    modificaVisibilidadeModalInformacaoRelatorioGeral, insereTextosInformacionaisModal,
    zeraModificaMensagemGerandoRelatorioVisaoGeral, modificaNomeRotaNoHeader, listaAtendimentosRelatorioGeralPersonalizado,
    exportaAtendimentosParaExcelPdfVisaoGeral
})(withRouter(RelatorioAtendimentoGeral));
