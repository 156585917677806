import {
    MODIFICAEMAILLOGIN, MODIFICASENHALOGIN, MODIFICACONFIRMASENHA, MODIFICACOMPANIA, MODIFICANOME,
    MENSAGEMCADASTRAEMAILERROR, ZERAINFORMACOESNOMECOMPANIACONFIRMASENHA, MENSAGEMCADASTRAEMAILSUCESSO,
    ATIVALOADINGBOTAOCADASTRARTELACADASTRARCOMPANIA, MENSAGEMLOGAREMAILERROR, ATIVALOADINGBOTAOLOGARTELALOGIN,
    DESATIVALOADINGBOTAOLOGARTELALOGIN, ZERAMENSAGEMDESUCRSSO, LOGINSEMINTERNET,
    SEMINTERNETCADASTROUSUARIO, ZERAINFORMACOESLOGIN, VERIFICAINTERNETESQUECEUSENHA, ATIVADESATIVALOADINGBOTAOESQUECEUSENHA,
    MODIFICACORBORDAEMAILLOGIN, MODIFICACORBORDASENHALOGIN, MODIFICAERRORSENHALOGIN, MODIFICACORICONEEMAILLOGIN,
    MODIFICACORICONESENHALOGIN,

    MODIFICACORICONEINPUTEMPRESACADASTRO, MODIFICACORBORDAEMPRESACADASTRO,
    MODIFICACORICONEINPUTEMAILCADASTRO, MODIFICACORBORDAEMAILCADASTRO,
    MODIFICACORICONENOMECADASTRO, MODIFICACORBORDANOMECADASTRO,
    MODIFICACORICONESENHACADASTRO, MODIFICACORBORDASENHACADASTRO,
    MODIFICACORICONECONFIRMASENHACADASTRO, MODIFICACORBORDACONFIRMASENHACADASTRO,

    MODIFICAMENSAGEMERRORINPUTEMPRESACADASTRO, MODIFICAMENSAGEMERRORINPUTEMAILCADASTRO,
    MODIFICAMENSAGEMERRORINPUTNOMECADASTRO, MODIFICAMENSAGEMERRORINPUTSENHACADASTRO,
    MODIFICAMENSAGEMERRORINPUTCONFIRMASENHACADASTRO, MODIFICACHECKBOXCONTINUARCONECTADO,
    MODIFICAMODALESQUECISENHA, MODIFICAISADMIN, MODIFICASEGMENTOCADASTRO,
    UPDTEISMAINTENANCE, UPDTELASTVERSIONAVAILABLE
} from '../actions/types';

const INITIAL_STATE = {
    email: '',
    senha: '',
    confirmarSenha: '',
    empresa: '',
    nome: '',
    areaAtuacao: [
        {
            name: 'Area de Atuação',
            type: 'group',
            items: [
                { value: "Agência de Marketing e Publicidade", name: "Agência de Marketing e Publicidade" },
                { value: "Auto peças", name: "Auto peças" },
                { value: "Automação Comercial", name: "Automação Comercial" },
                { value: "Bens de Consumo e Produção", name: "Bens de Consumo e Produção" },
                { value: "Consultoria e Serviços Profissionais", name: "Consultoria e Serviços Profissionais" },
                { value: "Central de Alarme", name: "Central de Alarme" },
                { value: "Educação", name: "Educação" },
                { value: "Energia", name: "Energia" },
                { value: "Farmacêuticos, Biotecnologia e Ciências da Vida", name: "Farmacêuticos, Biotecnologia e Ciências da Vida" },
                { value: "Financeiro", name: "Financeiro" },
                { value: "Hardware", name: "Hardware" },
                { value: "Imobiliárias", name: "Imobiliárias" },
                { value: "Indústria", name: "Indústria" },
                { value: "Manuntenção de Maquinás", name: "Manuntenção de Maquinás" },
                { value: "Monitoramento Eletrônico", name: "Monitoramento Eletrônico" },
                { value: "Recursos Humanos", name: "Recursos Humanos" },
                { value: "Saúde", name: "Saúde" },
                { value: "Seguros", name: "Seguros" },
                { value: "Serviços Diversos", name: "Serviços Diversos" },
                { value: "Software & Cloud", name: "Software & Cloud" },
                { value: "Supermercados", name: "Supermercados" },
                { value: "Tecnologia da Informação (TI)", name: "Tecnologia da Informação (TI)" },
                { value: "Telecomunicações", name: "Telecomunicações" },
                { value: "Transportes e Logística", name: "Transportes e Logística" },
                { value: "Têxtil", name: "Têxtil" },
                { value: "Utilidades Públicas", name: "Utilidades Públicas" },
                { value: "Varejo", name: "Varejo" },
                { value: "Vestuário", name: "Vestuário" },
                { value: "Outros", name: "Outros" },
            ]
        }
    ],
    areaAtuacaoSelecionada: '',
    mensagemDeErrorTelaLogin: '',
    mensagemDeErrorSenhaTelaLogin: '',
    mensagemDeErrorTelaCadastro: '',
    mensagemDeSucessoTelaCadastro: '',
    loadingBotaoCadastrarCompania: false,
    corBordaInputEmailLogin: '#FFF',
    corBordaInputSenhaLogin: '#FFF',
    corIconeInputEmailLogin: '#FFF',
    corIconeInputSenhaLogin: '#FFF',
    loadingBotaoLogarTelaLogin: false,
    statusConexao: true,
    verificaInternetLogin: '',
    verificaInternetTelaCadastro: '',
    verificaIntertEsqueceuSenha: '',
    loadingBotaoEsqueciSenha: false,

    corIconeInputEmpresaCadastro: '#FFF',
    corBordaInputEmpresaCadastro: '#FFF',

    corIconeInputEmailCadastro: '#FFF',
    corBordaInputEmailCadastro: '#FFF',

    corIconeInputNomeCadastro: '#FFF',
    corBordaInputNomeCadastro: '#FFF',

    corIconeInputSenhaCadastro: '#FFF',
    corBordaInputSenhaCadastro: '#FFF',

    corIconeInputConfirmaSenhaCadastro: '#FFF',
    corBordaInputConfirmaSenhaCadastro: '#FFF',

    textErrorInputEmpresaCadastro: '',
    textErrorInputEmailCadastro: '',
    textErrorInputNomeCadastro: '',
    textErrorInputSenhaCadastro: '',
    textErrorInputConfirmaSenhaCadastro: '',
    checkBoxConectarAutomaticamente: false,
    modalEsqueciSenha: false,
    nomeTeste: 'nome teste',
    isadmin: true,
    isMaintenance: false,
    isNewVersionAvailable: false,
    lastVersionAvailable: 2

};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MODIFICAEMAILLOGIN:
            return { ...state, email: action.payload };
        case MODIFICASENHALOGIN:
            return { ...state, senha: action.payload };
        case MODIFICACONFIRMASENHA:
            return { ...state, confirmarSenha: action.payload };
        case MODIFICACOMPANIA:
            return { ...state, empresa: action.payload };
        case MODIFICANOME:
            return { ...state, nome: action.payload };
        case MENSAGEMCADASTRAEMAILERROR:
            return {
                ...state, mensagemDeErrorTelaCadastro: action.payload, mensagemDeSucessoTelaCadastro: '',
                loadingBotaoCadastrarCompania: false
            };
        case ZERAINFORMACOESNOMECOMPANIACONFIRMASENHA:
            return { ...state, nome: '', empresa: '', confirmarSenha: '' };
        case MENSAGEMCADASTRAEMAILSUCESSO:
            return {
                ...state, mensagemDeErrorTelaCadastro: '',
                loadingBotaoCadastrarCompania: false, mensagemDeErrorTelaLogin: '',
            };
        case ATIVALOADINGBOTAOCADASTRARTELACADASTRARCOMPANIA:
            return { ...state, loadingBotaoCadastrarCompania: action.payload, mensagemDeErrorTelaCadastro: '' };
        case DESATIVALOADINGBOTAOLOGARTELALOGIN:
            return { ...state, loadingBotaoLogarTelaLogin: action.payload };
        case MENSAGEMLOGAREMAILERROR:
            return { ...state, mensagemDeErrorTelaLogin: action.payload };
        case ATIVALOADINGBOTAOLOGARTELALOGIN:
            return { ...state, loadingBotaoLogarTelaLogin: action.payload, mensagemDeErrorTelaLogin: '' };
        case ZERAMENSAGEMDESUCRSSO:
            return { ...state, mensagemDeSucessoTelaCadastro: '' };
        case LOGINSEMINTERNET:
            return { ...state, verificaInternetLogin: action.payload };
        case SEMINTERNETCADASTROUSUARIO:
            return { ...state, verificaInternetTelaCadastro: action.payload };
        case ZERAINFORMACOESLOGIN:
            return {
                ...state, email: '', senha: '', confirmarSenha: '', empresa: '', nome: '', mensagemDeErrorTelaLogin: '',
                mensagemDeErrorTelaCadastro: '', mensagemDeSucessoTelaCadastro: '', loadingBotaoCadastrarCompania: false, loadingBotaoLogarTelaLogin: false,
            };
        case VERIFICAINTERNETESQUECEUSENHA:
            return { ...state, verificaIntertEsqueceuSenha: action.payload };
        case ATIVADESATIVALOADINGBOTAOESQUECEUSENHA:
            return { ...state, loadingBotaoEsqueciSenha: action.payload };
        case MODIFICACORBORDAEMAILLOGIN:
            return { ...state, corBordaInputEmailLogin: action.payload };
        case MODIFICACORBORDASENHALOGIN:
            return { ...state, corBordaInputSenhaLogin: action.payload };
        case MODIFICAERRORSENHALOGIN:
            return { ...state, mensagemDeErrorSenhaTelaLogin: action.payload };
        case MODIFICACORICONEEMAILLOGIN:
            return { ...state, corIconeInputEmailLogin: action.payload };
        case MODIFICACORICONESENHALOGIN:
            return { ...state, corIconeInputSenhaLogin: action.payload };

        case MODIFICACORICONEINPUTEMPRESACADASTRO:
            return { ...state, corIconeInputEmpresaCadastro: action.payload };
        case MODIFICACORBORDAEMPRESACADASTRO:
            return { ...state, corBordaInputEmpresaCadastro: action.payload };

        case MODIFICACORICONEINPUTEMAILCADASTRO:
            return { ...state, corIconeInputEmailCadastro: action.payload };
        case MODIFICACORBORDAEMAILCADASTRO:
            return { ...state, corBordaInputEmailCadastro: action.payload };

        case MODIFICACORICONENOMECADASTRO:
            return { ...state, corIconeInputNomeCadastro: action.payload };
        case MODIFICACORBORDANOMECADASTRO:
            return { ...state, corBordaInputNomeCadastro: action.payload };

        case MODIFICACORICONESENHACADASTRO:
            return { ...state, corIconeInputSenhaCadastro: action.payload };
        case MODIFICACORBORDASENHACADASTRO:
            return { ...state, corBordaInputSenhaCadastro: action.payload };

        case MODIFICACORICONECONFIRMASENHACADASTRO:
            return { ...state, corIconeInputConfirmaSenhaCadastro: action.payload };
        case MODIFICACORBORDACONFIRMASENHACADASTRO:
            return { ...state, corBordaInputConfirmaSenhaCadastro: action.payload };



        case MODIFICAMENSAGEMERRORINPUTEMPRESACADASTRO:
            return { ...state, textErrorInputEmpresaCadastro: action.payload };
        case MODIFICAMENSAGEMERRORINPUTEMAILCADASTRO:
            return { ...state, textErrorInputEmailCadastro: action.payload };
        case MODIFICAMENSAGEMERRORINPUTNOMECADASTRO:
            return { ...state, textErrorInputNomeCadastro: action.payload };
        case MODIFICAMENSAGEMERRORINPUTSENHACADASTRO:
            return { ...state, textErrorInputSenhaCadastro: action.payload };
        case MODIFICAMENSAGEMERRORINPUTCONFIRMASENHACADASTRO:
            return { ...state, textErrorInputConfirmaSenhaCadastro: action.payload };
        case MODIFICACHECKBOXCONTINUARCONECTADO:
            return { ...state, checkBoxConectarAutomaticamente: action.payload };
        case MODIFICAMODALESQUECISENHA:
            return { ...state, modalEsqueciSenha: action.payload };
        case MODIFICAISADMIN:
            return { ...state, isadmin: action.payload };
        case MODIFICASEGMENTOCADASTRO:
            return { ...state, areaAtuacaoSelecionada: action.payload };
        case UPDTEISMAINTENANCE:
            return { ...state, isMaintenance: action.payload };
        case UPDTELASTVERSIONAVAILABLE:
            let lastVersion = action.payload;
            if (lastVersion > state.lastVersionAvailable) {
                return { ...state, isNewVersionAvailable: true };
            } else {
                return { ...state, isNewVersionAvailable: false };
            }


        default:
            return state;
    }
};