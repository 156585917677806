import React from 'react';
import { connect } from 'react-redux';
import { ResponsivePie } from '@nivo/pie';
import moment from 'moment';
import { toastr } from 'react-redux-toastr';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController, isSameDay } from 'react-dates';
import SelectSearchPaginate from "../SelectSearch/index";
import SelectSearch from 'react-select-search';
import { Modal } from 'react-responsive-modal';
import { withRouter } from "react-router-dom";
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import {
    modificaCorBordaListaTarefas, insereDataFiltroDashboard, modificaDataInicialFinalRelatorioAtendimentoDetalhado,
    modificaFiltroSelecionadoRelatorioDetalhado,

    modificaClienteTelaRelatorioDetalhado,
    modificaAtendenteRelatorioDetalhado, modificaSetorRelatorioDetalhado,
    modificaFiltroTelaRelatorioDetalhado, modificaFiltroClienteSectionedTelaRelatorioDetalhado, modificaFiltroAtendenteSectionedTelaRelatorioDetalhado,
    modificaFiltroSetorSectionedTelaRelatorioDetalhado, modificaVisibilidadeModalInformacaoRelatorioDetalhado,
    insereTextosInformacionaisModalRelatorioDetalhado, zeraMensagemGerandoRelatorio, novalistaAtendimentosZeradoParaRelatorioDetalhado,
    listaAtendimentosPeriodoPersonalizado, exportaAtendimentosParaExcelPdf, desligaOuvintesChamadosFirebase
} from '../../actions/DashBoardAction';
import { modificaNomeRotaNoHeader } from '../../actions/HeaderAction';
import { listaAtendentesTelaConsultaAtendente } from '../../actions/AtendenteAction';
import { listaSetorSectionedTelaConsultaSetor } from '../../actions/SetorAction';
import {
    listaClientesTelaConsultaClientes
} from '../../actions/ChamadoAction';
import {
    Title, Container, ContainerBox, Box, TextStatus, TextResultado, ContainerGrafico, SubContainerGrafico,
    ContainerTextGrafico, TextGrafico, ContainerAviso, TextAviso, SubContainerListaDeTarefas, TextTarefas,
    SubContainerInputEmail, InputEmail, DivContainerSelectDate, BotaoPesquisarChamadoPorNumero,
    ContainerGraficoTempoMedio, SubContainerGraficoTempoMedio, SubContainerGraficoTempoMedioSemSombra,
    ContainerTextTempoMedio, TextTempoMedio, ContainerIconesExport, IconeExport,
    ContainerTextTituloTempoMedio, DivTextPeriodo, TextPeriodo, ContainerInputSearch, IconeSair, DivPeriodoBotao,
    ContainerIconesExportTempoMedio, ContainerModal, ContainerIconeFecharModal, TextNovoAtendimento, ContainerExplicacaoVincular, TextVincular,
    IconeFechar,
    InputSearch, DivInputSearch, IconeSetaPraBaixo,

    ContainerTextGraficoLoading, TextGraficoLoading, ContainerGraficoLoading, GraficoLoading,
    TextGraficoLoadingTempoMedio
} from './styled';

class RelatorioAtendimentoDetalhado extends React.Component {
    state = {
        startDate: null,
        endDate: null,
        focusedInput: null,
        clientSelected: null,
        aviso: [],
        tarefas: [
            { id: 1, tarefa: 'Aprender git hub' },
            { id: 2, tarefa: 'Instalar windows na maquina' }]
    }


    componentWillUnmount() {
        this.props.desligaOuvintesChamadosFirebase('relatorioatendimentodetalhado', '', '', '');
    }
    componentDidMount() {
        if (this.props.premium == 0) {
            toastr.error('Recurso Premium', 'Este é um recurso PREMIUM, Assine a versão PREMIUM e libere esse e mais recursos!');
            this.props.history.push('/dashboard')

        } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
            toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
            this.props.history.push('/dashboard')

        } else {
            if (this.props.permissoesAcessarTelasAtendente.permissaoAcessoRelatorios == true) {
                moment.locale('pt-br', {
                    months: 'Janeiro_Fevereiro_Março_Abril_Maio_Junho_Julho_Agosto_Setembro_Outubro_Novembro_Dezembro'.split('_')
                })
                if (this.props.periodoPersonalizadoRelatorioDetalhado == '') {
                    this.props.novalistaAtendimentosZeradoParaRelatorioDetalhado('Hoje', 0);
                }

                this.props.listaClientesTelaConsultaClientes();
                this.props.listaAtendentesTelaConsultaAtendente('relatorio');
                this.props.listaSetorSectionedTelaConsultaSetor()
                if (this.props.nomeRota !== 'Relatório Atendimentos') {
                    this.props.modificaNomeRotaNoHeader('Relatório Atendimentos')
                }
            } else {
                toastr.error('Acesso Negado', 'Você não tem permissão para acessar esta tela');
                this.props.history.push('/dashboard')
            }
        }
    }
    removeFiltrosAll() {
        this.props.modificaFiltroSelecionadoRelatorioDetalhado('');
        this.props.modificaAtendenteRelatorioDetalhado('');
        this.props.modificaClienteTelaRelatorioDetalhado('');
        this.props.modificaSetorRelatorioDetalhado('');
        this.setState({ clientSelected: null });
        this.props.listaAtendimentosPeriodoPersonalizado(this.props.dataInicialConsultaFiltroRelatorioDetalhado, this.props.dataFinalConsultaFiltroRelatorioDetalhado, 1, 'padrao', '')
    }
    handleInputEmailBlur() {
        this.props.modificaCorBordaListaTarefas(this.props.corBordaDinamica);

    }
    handleInputEmailFocus() {
        this.props.modificaCorBordaListaTarefas(this.props.corBotaoPadrao);
    }
    handleWeekDays = (day) => {
        //Change week day with custom day array
        day._locale._weekdaysMin = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'];
        // return the actual dates value(like 1,2,3 ...) from the moment object.
        return (day.format('D'));
    }
    insereDataInicialFinal = ({ dataInicial, dataFinal }) => {
        //
        //
        if (dataInicial !== null) {
            let dataInicio = moment(dataInicial, 'MM/DD/YYYY').format('DD/MM/YYYY');
            let state = this.state;
            state.startDate = dataInicio;
            this.setState({ state })
        }
        if (dataFinal !== null) {
            let dataFim = moment(dataFinal, 'MM/DD/YYYY').format('DD/MM/YYYY');
            let state = this.state;
            state.endDate = dataFim;
            this.setState({ state })
        }

    }
    renderLoadingRelatorioDetalhado() {
        if (this.props.loadingRelatorioDetalhado) {
            return (
                <Container backgroundDinamico={this.props.backgroundPrimeiro}>
                    <ContainerGrafico>
                        <SubContainerGrafico backgroundDinamico={this.props.backgroundSegundo}>
                            <ContainerTextGraficoLoading>
                                <TextGraficoLoading backgroundinamico={this.props.backgroundSegundo}></TextGraficoLoading>
                            </ContainerTextGraficoLoading>
                            <ContainerGraficoLoading>
                                <GraficoLoading backgroundinamico={this.props.backgroundSegundo}></GraficoLoading>
                            </ContainerGraficoLoading>
                        </SubContainerGrafico>
                        <SubContainerGrafico backgroundDinamico={this.props.backgroundSegundo}>
                            <ContainerTextGraficoLoading>
                                <TextGraficoLoading backgroundinamico={this.props.backgroundSegundo}></TextGraficoLoading>
                            </ContainerTextGraficoLoading>
                            <ContainerGraficoLoading>
                                <GraficoLoading backgroundinamico={this.props.backgroundSegundo}></GraficoLoading>
                            </ContainerGraficoLoading>
                        </SubContainerGrafico>

                    </ContainerGrafico>
                    <ContainerGrafico >
                        <SubContainerGrafico backgroundDinamico={this.props.backgroundSegundo}>
                            <ContainerTextGraficoLoading>
                                <TextGraficoLoading backgroundinamico={this.props.backgroundSegundo}></TextGraficoLoading>
                            </ContainerTextGraficoLoading>
                            <ContainerGraficoLoading>
                                <GraficoLoading backgroundinamico={this.props.backgroundSegundo}></GraficoLoading>
                            </ContainerGraficoLoading>
                        </SubContainerGrafico >
                        <SubContainerGrafico backgroundDinamico={this.props.backgroundSegundo}>
                            <ContainerTextGraficoLoading>
                                <TextGraficoLoading backgroundinamico={this.props.backgroundSegundo}></TextGraficoLoading>
                            </ContainerTextGraficoLoading>
                            <ContainerGraficoLoading>
                                <GraficoLoading backgroundinamico={this.props.backgroundSegundo}></GraficoLoading>
                            </ContainerGraficoLoading>

                        </SubContainerGrafico>
                    </ContainerGrafico>
                    <ContainerGraficoTempoMedio>
                        <SubContainerGraficoTempoMedio backgroundDinamico={this.props.backgroundSegundo}>
                            <ContainerTextTituloTempoMedio>
                                <TextGraficoLoadingTempoMedio backgroundinamico={this.props.backgroundSegundo}></TextGraficoLoadingTempoMedio>
                            </ContainerTextTituloTempoMedio>
                            <ContainerTextTempoMedio>

                                <TextGraficoLoadingTempoMedio backgroundinamico={this.props.backgroundSegundo}></TextGraficoLoadingTempoMedio>
                            </ContainerTextTempoMedio>
                        </SubContainerGraficoTempoMedio>
                        <SubContainerGraficoTempoMedioSemSombra backgroundDinamico={this.props.backgroundPrimeiro}>

                        </SubContainerGraficoTempoMedioSemSombra>
                    </ContainerGraficoTempoMedio>

                </Container >
            )
        } else {
            return (
                <Container backgroundDinamico={this.props.backgroundPrimeiro}>

                    <DivContainerSelectDate>
                        <div style={{}}>
                            <DivTextPeriodo>
                                <i className="icon-calendarweb" style={{ color: this.props.coresLetraPadrao, fontSize: 15, marginRight: 3 }} />
                                <TextPeriodo corLetraDinamica={this.props.coresLetraPadrao}>Período:</TextPeriodo>
                            </DivTextPeriodo>
                            <DivPeriodoBotao >
                                <DateRangePicker
                                    // customInputIcon={<i className="icon-calendar" style={{ color: '#000', marginRight: 5 }} />}
                                    //   customArrowIcon={<Icon icon="next" size="xxs" />}
                                    // customCloseIcon={<i className="icon-close" style={{ color: '#000', marginRight: 5 }} />}
                                    displayFormat={() => "DD/MM/YYYY"}
                                    small
                                    isOutsideRange={() => false}
                                    minimumNights={0}
                                    firstDayOfWeek={1}
                                    renderDayContents={this.handleWeekDays}
                                    isDayHighlighted={day => isSameDay(moment(new Date()), moment(day))}
                                    hideKeyboardShortcutsPanel
                                    startDatePlaceholderText="Data Inicial"
                                    endDatePlaceholderText="Data Final"
                                    startDate={this.props.dataInicialConsultaFiltroRelatorioDetalhado} // momentPropTypes.momentObj or null,
                                    startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                                    endDate={this.props.dataFinalConsultaFiltroRelatorioDetalhado} // momentPropTypes.momentObj or null,
                                    endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                                    //onDatesChange={this.insereDataInicialFinal} // PropTypes.func.isRequired,
                                    onDatesChange={({ startDate, endDate }) => this.props.modificaDataInicialFinalRelatorioAtendimentoDetalhado(startDate, endDate)}
                                    focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                    onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                                />
                                {this.props.dataInicialConsultaFiltroRelatorioDetalhado !== null && this.props.dataFinalConsultaFiltroRelatorioDetalhado !== null && <BotaoPesquisarChamadoPorNumero onClick={() => {
                                    /*  alert(this.props.filtroSelecionadoRelatorioDetalhado) */
                                    if (this.props.filtroSelecionadoRelatorioDetalhado == 'cliente' && this.props.clienteSelecionadoRelatorioAtendimentoDetalhado.length > 0) {
                                        this.props.listaAtendimentosPeriodoPersonalizado(this.props.dataInicialConsultaFiltroRelatorioDetalhado, this.props.dataFinalConsultaFiltroRelatorioDetalhado, 1, this.props.filtroSelecionadoRelatorioDetalhado, this.props.clienteSelecionadoRelatorioAtendimentoDetalhado);
                                        // this.props.navigation.pop();
                                    }
                                    else if (this.props.filtroSelecionadoRelatorioDetalhado == 'atendente' && this.props.atendentesSelecionadoRelatorioAtendimentoDetalhado.length > 0) {
                                        this.props.listaAtendimentosPeriodoPersonalizado(this.props.dataInicialConsultaFiltroRelatorioDetalhado, this.props.dataFinalConsultaFiltroRelatorioDetalhado, 1, this.props.filtroSelecionadoRelatorioDetalhado, this.props.atendentesSelecionadoRelatorioAtendimentoDetalhado);
                                        // this.props.navigation.pop();
                                    }
                                    else if (this.props.filtroSelecionadoRelatorioDetalhado == 'setor' && this.props.SetoresSelecionadoRelatorioAtendimentoDetalhado.length > 0) {
                                        this.props.listaAtendimentosPeriodoPersonalizado(this.props.dataInicialConsultaFiltroRelatorioDetalhado, this.props.dataFinalConsultaFiltroRelatorioDetalhado, 1, this.props.filtroSelecionadoRelatorioDetalhado, this.props.SetoresSelecionadoRelatorioAtendimentoDetalhado);
                                        //  this.props.navigation.pop();
                                    } else {
                                        this.props.listaAtendimentosPeriodoPersonalizado(this.props.dataInicialConsultaFiltroRelatorioDetalhado, this.props.dataFinalConsultaFiltroRelatorioDetalhado, 1, 'padrao', '');
                                    }
                                }} backGroundDinamico={this.props.corBotaoPadrao} type="button">
                                    <i className="icon-magnifier" style={{ color: this.props.letraBotaoPadrao, fontSize: 15 }} />
                                </BotaoPesquisarChamadoPorNumero>}
                            </DivPeriodoBotao>
                        </div>
                        {this.props.filtroSelecionadoRelatorioDetalhado == 'cliente' && <div style={{ marginTop: 18 }}>
                            <TextPeriodo style={{ marginLeft: 40 }} corLetraDinamica={this.props.coresLetraPadrao}>Cliente:</TextPeriodo>
                            <ContainerInputSearch backGroundDinamico={this.props.backgroundMaisEscuro}>
                                <IconeSair className="icon-close" onClick={() => { this.removeFiltrosAll(); }} />
                                <div style={{ background: this.props.corSegundoBotaoPadrao, borderRadius: 3, height: 30 }}>
                                    {this.props.clientesRelatorioAtendimentoDetalhado.length === 0 && <SelectSearchPaginate
                                        onSelectClient={(client) => {

                                            this.setState({ clientSelected: { value: client.value, label: client.label } });
                                            // this.props.modificaClienteTelaRelatorioDetalhado(value);
                                            this.props.listaAtendimentosPeriodoPersonalizado(this.props.dataInicialConsultaFiltroRelatorioDetalhado, this.props.dataFinalConsultaFiltroRelatorioDetalhado, 1, 'cliente', client.value);

                                        }}
                                        value={this.state.clientSelected}
                                        isMyContact={false}
                                        background={this.props.backgroundMaisEscuro}
                                        letterColor={this.props.coresLetraPadrao}
                                        backgroundDinamico={this.props.backgroundMaisEscuro}
                                        placeholderColor={this.props.coresLetraSecundario}
                                        corBordaDinamica={this.props.corBotaoPadrao}
                                        width={400}
                                        letraBotaoPadrao={this.props.letraBotaoPadrao}
                                    />}
                                    {this.props.clientesRelatorioAtendimentoDetalhado.length > 0 && <SelectSearch
                                        renderValue={(valueProps) =>
                                            <DivInputSearch>
                                                <InputSearch
                                                    corLetraDinamico={this.props.coresLetraPadrao}
                                                    backgroundDinamico={this.props.backgroundMaisEscuro}
                                                    placeholderColor={this.props.corLetraSecundaria}
                                                    corBordaDinamica={this.props.corBotaoPadrao}
                                                    {...valueProps} ></InputSearch>
                                                <IconeSetaPraBaixo className="icon-angle-arrow-down" colorDinamico={this.props.coresLetraPadrao} />
                                            </DivInputSearch>
                                        }

                                        onChange={(value) => {
                                            this.props.modificaClienteTelaRelatorioDetalhado(value);
                                            this.props.listaAtendimentosPeriodoPersonalizado(this.props.dataInicialConsultaFiltroRelatorioDetalhado, this.props.dataFinalConsultaFiltroRelatorioDetalhado, 1, 'cliente', value);
                                        }}
                                        value={this.props.clienteSelecionadoRelatorioAtendimentoDetalhado}
                                        options={this.props.clientesRelatorioAtendimentoDetalhado}
                                        placeholder={this.props.clientesRelatorioAtendimentoDetalhado.length == 0 ? `Nenhum Cliente cadastrado, Por favor verifique!` : 'Selecione o Cliente'}
                                        search={this.props.clientesRelatorioAtendimentoDetalhado.length == 0 ? false : true}
                                    />}
                                </div>
                            </ContainerInputSearch>
                        </div>}

                        {this.props.filtroSelecionadoRelatorioDetalhado == 'atendente' && <div style={{ marginTop: 18 }}>
                            <TextPeriodo style={{ marginLeft: 40 }} corLetraDinamica={this.props.coresLetraPadrao}>Atendente:</TextPeriodo>
                            <ContainerInputSearch backGroundDinamico={this.props.backgroundMaisEscuro}>
                                <IconeSair className="icon-close" onClick={() => { this.removeFiltrosAll(); }} />
                                <div style={{ background: this.props.corSegundoBotaoPadrao, borderRadius: 3, height: 30 }}>
                                    <SelectSearch
                                        renderValue={(valueProps) =>
                                            <DivInputSearch>
                                                <InputSearch
                                                    corLetraDinamico={this.props.coresLetraPadrao}
                                                    backgroundDinamico={this.props.backgroundMaisEscuro}
                                                    placeholderColor={this.props.corLetraSecundaria}
                                                    corBordaDinamica={this.props.corBotaoPadrao}
                                                    {...valueProps} ></InputSearch>
                                                <IconeSetaPraBaixo className="icon-angle-arrow-down" colorDinamico={this.props.coresLetraPadrao} />
                                            </DivInputSearch>
                                        }
                                        onChange={(value) => {
                                            this.props.modificaAtendenteRelatorioDetalhado(value);
                                            this.props.listaAtendimentosPeriodoPersonalizado(this.props.dataInicialConsultaFiltroRelatorioDetalhado, this.props.dataFinalConsultaFiltroRelatorioDetalhado, 1, 'atendente', value);
                                        }}
                                        value={this.props.atendentesSelecionadoRelatorioAtendimentoDetalhado}
                                        options={this.props.atendentesRelatorioAtendimentoDetalhado}
                                        placeholder={this.props.atendentesRelatorioAtendimentoDetalhado.length == 0 ? `Nenhum Atendente cadastrado, Por favor verifique!` : 'Selecione o Atendente'}
                                        search={this.props.atendentesRelatorioAtendimentoDetalhado.length == 0 ? false : true}
                                    />
                                </div>
                            </ContainerInputSearch>
                        </div>}
                        {this.props.filtroSelecionadoRelatorioDetalhado == 'setor' && <div style={{ marginTop: 18 }}>
                            <TextPeriodo style={{ marginLeft: 40 }} corLetraDinamica={this.props.coresLetraPadrao}>Setor:</TextPeriodo>
                            <ContainerInputSearch backGroundDinamico={this.props.backgroundMaisEscuro}>
                                <IconeSair className="icon-close" onClick={() => { this.removeFiltrosAll(); }} />
                                <div style={{ background: this.props.corSegundoBotaoPadrao, borderRadius: 3, height: 30 }}>
                                    <SelectSearch
                                        renderValue={(valueProps) =>
                                            <DivInputSearch>
                                                <InputSearch
                                                    corLetraDinamico={this.props.coresLetraPadrao}
                                                    backgroundDinamico={this.props.backgroundMaisEscuro}
                                                    placeholderColor={this.props.corLetraSecundaria}
                                                    corBordaDinamica={this.props.corBotaoPadrao}
                                                    {...valueProps} ></InputSearch>
                                                <IconeSetaPraBaixo className="icon-angle-arrow-down" colorDinamico={this.props.coresLetraPadrao} />
                                            </DivInputSearch>
                                        }
                                        onChange={(value) => {
                                            this.props.modificaSetorRelatorioDetalhado(value);
                                            this.props.listaAtendimentosPeriodoPersonalizado(this.props.dataInicialConsultaFiltroRelatorioDetalhado, this.props.dataFinalConsultaFiltroRelatorioDetalhado, 1, 'setor', value);
                                        }}
                                        value={this.props.SetoresSelecionadoRelatorioAtendimentoDetalhado}
                                        options={this.props.SetoresRelatorioAtendimentoDetalhado}
                                        placeholder={this.props.SetoresRelatorioAtendimentoDetalhado.length == 0 ? `Nenhum Setor cadastrado, Por favor verifique!` : 'Selecione o Setor'}
                                        search={this.props.SetoresRelatorioAtendimentoDetalhado.length == 0 ? false : true} />
                                </div>
                            </ContainerInputSearch>
                        </div>}
                    </DivContainerSelectDate>

                    <ContainerGrafico>
                        <SubContainerGrafico backgroundDinamico={this.props.backgroundSegundo}>
                            <ContainerTextGrafico>
                                <div style={{ width: 80, height: 30 }}></div>
                                <TextGrafico colorDinamico={this.props.coresLetraPadrao}>Atendimento Por Status</TextGrafico>
                                <ContainerIconesExport>
                                    <IconeExport onClick={() => {
                                        this.props.exportaAtendimentosParaExcelPdf(this.props.arrayChamadosDetalhadosParaDownload, 'status', 'PDF')
                                    }} colorDinamico="red" className="icon-pdf2" />
                                    <IconeExport onClick={() => {
                                        this.props.exportaAtendimentosParaExcelPdf(this.props.arrayChamadosDetalhadosParaDownload, 'status', 'EXCEL')
                                    }} colorDinamico="#32CD32" className="icon-spreadsheet" />
                                    <IconeExport onClick={() => {
                                        this.props.insereTextosInformacionaisModalRelatorioDetalhado(
                                            'Volume de Atendimentos',
                                            ' Com o volume geral de atendimentos que a sua organização recebe, é possível ver o número de atendimentos que foram abertos,que estão em atendimento,fechados e cancelados.',
                                            ' Desta forma é possível analisar se é preciso incluir mais atendentes em sua operação, se o seu contact center está como manda o figurino e se o volume de atendimentos que você recebe condiz com o tamanho do seu negócio.'
                                        )
                                    }} colorDinamico="#FFF" className="icon-danger1" />
                                </ContainerIconesExport>
                            </ContainerTextGrafico>

                            <ResponsivePie
                                data={this.props.atendimentosAEFC}
                                margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                                innerRadius={0.5}
                                padAngle={0.7}
                                cornerRadius={3}
                                colors={d => d.color}
                                borderWidth={1}
                                borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                                radialLabelsSkipAngle={10}
                                radialLabelsTextXOffset={6}
                                radialLabelsTextColor={this.props.coresLetraPadrao}
                                radialLabelsLinkOffset={0}
                                radialLabelsLinkDiagonalLength={16}
                                radialLabelsLinkHorizontalLength={24}
                                radialLabelsLinkStrokeWidth={1}
                                radialLabelsLinkColor={{ from: 'color' }}
                                slicesLabelsSkipAngle={10}
                                slicesLabelsTextColor="#000"
                                animate={true}
                                motionStiffness={90}
                                motionDamping={15}
                                tooltip={(e) => {
                                    if (e.value == 0.5) {
                                        return (
                                            <div style={{ height: 30, background: '#FFF', padding: 5, borderRadius: 5, flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center' }} key={e.id}>

                                                <div style={{ width: 13, height: 13, backgroundColor: e.color, marginRight: 5, marginLeft: 5 }}></div>
                                                <div>

                                                    <strong style={{ color: '#000' }}>
                                                        {`${e.id}: ${e.valorZerado}`}
                                                    </strong>
                                                </div>
                                            </div>

                                        );
                                    } else {
                                        return (
                                            <div style={{ height: 30, background: '#FFF', padding: 5, borderRadius: 5, flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center' }} key={e.id}>

                                                <div style={{ width: 13, height: 13, backgroundColor: e.color, marginRight: 5, marginLeft: 5 }}></div>
                                                <div>

                                                    <strong style={{ color: '#000' }}>
                                                        {`${e.id}: ${e.value}`}
                                                    </strong>
                                                </div>
                                            </div>

                                        );
                                    }
                                }}
                                sliceLabel={(d) => {
                                    if (d.value == 0.5) {
                                        return d.valorZerado
                                    } else {
                                        return d.value
                                    }
                                }}

                            /*   legends={[
                                  {
                                      anchor: 'bottom',
                                      direction: 'row',
                                      translateY: 56,
                                      itemWidth: 100,
                                      itemHeight: 18,
                                      itemTextColor: this.props.coresLetraPadrao,
                                      symbolSize: 18,
                                      symbolShape: 'circle',
                                      effects: [
                                          {
                                              on: 'hover',
                                              style: {
                                                  itemTextColor: this.props.coresLetraSecundario
                                              }
                                          }
                                      ]
                                  }
                              ]} */
                            />

                        </SubContainerGrafico>
                        <SubContainerGrafico backgroundDinamico={this.props.backgroundSegundo}>
                            <ContainerTextGrafico>
                                <div style={{ width: 80, height: 30 }}></div>
                                <TextGrafico colorDinamico={this.props.coresLetraPadrao}>Atendimentos por Prioridade</TextGrafico>
                                <ContainerIconesExport>
                                    <IconeExport colorDinamico="red" onClick={() => {
                                        this.props.exportaAtendimentosParaExcelPdf(this.props.arrayChamadosDetalhadosParaDownload, 'prioridade', 'PDF')
                                    }} className="icon-pdf2" />
                                    <IconeExport onClick={() => {
                                        this.props.exportaAtendimentosParaExcelPdf(this.props.arrayChamadosDetalhadosParaDownload, 'prioridade', 'EXCEL')
                                    }} colorDinamico="#32CD32" className="icon-spreadsheet" />
                                    <IconeExport onClick={() => {
                                        this.props.insereTextosInformacionaisModalRelatorioDetalhado(
                                            'Atendimentos por Prioridade',
                                            ' Este gráfico mostra o detalhamento dos atendimentos criados durante o período selecionado com base na prioridade atual.',
                                            ' Você poderá ver quantos atendimentos de prioridade Urgente,Alta,Normal e Baixa foram criados e pode usar o Relatório para descobrir se elas foram resolvidos.')
                                    }} colorDinamico="#FFF" className="icon-danger1" />
                                </ContainerIconesExport>
                            </ContainerTextGrafico>


                            <ResponsivePie
                                data={this.props.atendimentosPorPrioridadeRelatorioDetalhado}
                                margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                                innerRadius={0.5}
                                padAngle={0.7}
                                cornerRadius={3}
                                colors={d => d.color}
                                borderWidth={1}
                                borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                                radialLabelsSkipAngle={10}
                                radialLabelsTextXOffset={6}
                                radialLabelsTextColor={this.props.coresLetraPadrao}
                                radialLabelsLinkOffset={0}
                                radialLabelsLinkDiagonalLength={16}
                                radialLabelsLinkHorizontalLength={24}
                                radialLabelsLinkStrokeWidth={1}
                                radialLabelsLinkColor={{ from: 'color' }}
                                slicesLabelsSkipAngle={10}
                                slicesLabelsTextColor="#000"
                                animate={true}
                                motionStiffness={90}
                                motionDamping={15}
                                tooltip={(e) => {
                                    if (e.value == 0.5) {
                                        return (
                                            <div style={{ height: 30, background: '#FFF', padding: 5, borderRadius: 5, flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center' }} key={e.id}>

                                                <div style={{ width: 13, height: 13, backgroundColor: e.color, marginRight: 5, marginLeft: 5 }}></div>
                                                <div>

                                                    <strong style={{ color: '#000' }}>
                                                        {`${e.id}: ${e.valorZerado}`}
                                                    </strong>
                                                </div>
                                            </div>

                                        );
                                    } else {
                                        return (
                                            <div style={{ height: 30, background: '#FFF', padding: 5, borderRadius: 5, flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center' }} key={e.id}>

                                                <div style={{ width: 13, height: 13, backgroundColor: e.color, marginRight: 5, marginLeft: 5 }}></div>
                                                <div>

                                                    <strong style={{ color: '#000' }}>
                                                        {`${e.id}: ${e.value}`}
                                                    </strong>
                                                </div>
                                            </div>

                                        );
                                    }
                                }}
                                sliceLabel={(d) => {
                                    if (d.value == 0.5) {
                                        return d.valorZerado
                                    } else {
                                        return d.value
                                    }
                                }}

                            /*   legends={[
                                  {
                                      anchor: 'bottom',
                                      direction: 'row',
                                      translateY: 56,
                                      itemWidth: 100,
                                      itemHeight: 18,
                                      itemTextColor: this.props.coresLetraPadrao,
                                      symbolSize: 18,
                                      symbolShape: 'circle',
                                      effects: [
                                          {
                                              on: 'hover',
                                              style: {
                                                  itemTextColor: this.props.coresLetraSecundario
                                              }
                                          }
                                      ]
                                  }
                              ]} */
                            />

                        </SubContainerGrafico>

                    </ContainerGrafico>
                    <ContainerGrafico >
                        <SubContainerGrafico backgroundDinamico={this.props.backgroundSegundo}>
                            <ContainerTextGrafico>
                                <div style={{ width: 80, height: 30 }}></div>
                                <TextGrafico colorDinamico={this.props.coresLetraPadrao}>Atendimentos Interno/Externo</TextGrafico>
                                <ContainerIconesExport>
                                    <IconeExport colorDinamico="red" onClick={() => {
                                        this.props.exportaAtendimentosParaExcelPdf(this.props.arrayChamadosDetalhadosParaDownload, 'local', 'PDF')
                                    }} className="icon-pdf2" />
                                    <IconeExport onClick={() => {
                                        this.props.exportaAtendimentosParaExcelPdf(this.props.arrayChamadosDetalhadosParaDownload, 'local', 'EXCEL')
                                    }} colorDinamico="#32CD32" className="icon-spreadsheet" />
                                    <IconeExport onClick={() => {
                                        this.props.insereTextosInformacionaisModalRelatorioDetalhado(
                                            'Segmentação de Atendimentos',
                                            ' Uma boa maneira de quantificar as demandas mais recorrentes no serviço de help desk é segmentando cada tipo de solicitação.',
                                            ' Nesse contexto, a métrica em questão serve de base para apontar os tipos de atendimentos que a empresa recebe.'
                                        )
                                    }} colorDinamico="#FFF" className="icon-danger1" />
                                </ContainerIconesExport>
                            </ContainerTextGrafico>


                            <ResponsivePie
                                data={this.props.atendimentosInternoExternoRelatorioDetalhado}
                                margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                                innerRadius={0.5}
                                padAngle={0.7}
                                cornerRadius={3}
                                colors={d => d.color}
                                borderWidth={1}
                                borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                                radialLabelsSkipAngle={10}
                                radialLabelsTextXOffset={6}
                                radialLabelsTextColor={this.props.coresLetraPadrao}
                                radialLabelsLinkOffset={0}
                                radialLabelsLinkDiagonalLength={16}
                                radialLabelsLinkHorizontalLength={24}
                                radialLabelsLinkStrokeWidth={1}
                                radialLabelsLinkColor={{ from: 'color' }}
                                slicesLabelsSkipAngle={10}
                                slicesLabelsTextColor="#000"
                                animate={true}
                                motionStiffness={90}
                                motionDamping={15}
                                tooltip={(e) => {
                                    if (e.value == 0.5) {
                                        return (
                                            <div style={{ height: 30, background: '#FFF', padding: 5, borderRadius: 5, flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center' }} key={e.id}>

                                                <div style={{ width: 13, height: 13, backgroundColor: e.color, marginRight: 5, marginLeft: 5 }}></div>
                                                <div>

                                                    <strong style={{ color: '#000' }}>
                                                        {`${e.id}: ${e.valorZerado}`}
                                                    </strong>
                                                </div>
                                            </div>

                                        );
                                    } else {
                                        return (
                                            <div style={{ height: 30, background: '#FFF', padding: 5, borderRadius: 5, flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center' }} key={e.id}>

                                                <div style={{ width: 13, height: 13, backgroundColor: e.color, marginRight: 5, marginLeft: 5 }}></div>
                                                <div>

                                                    <strong style={{ color: '#000' }}>
                                                        {`${e.id}: ${e.value}`}
                                                    </strong>
                                                </div>
                                            </div>

                                        );
                                    }
                                }}
                                sliceLabel={(d) => {
                                    if (d.value == 0.5) {
                                        return d.valorZerado
                                    } else {
                                        return d.value
                                    }
                                }}

                            /* legends={[
                                {
                                    anchor: 'bottom',
                                    direction: 'row',
                                    translateY: 56,
                                    itemWidth: 100,
                                    itemHeight: 18,
                                    itemTextColor: this.props.coresLetraPadrao,
                                    symbolSize: 18,
                                    symbolShape: 'circle',
                                    effects: [
                                        {
                                            on: 'hover',
                                            style: {
                                                itemTextColor: this.props.coresLetraSecundario
                                            }
                                        }
                                    ]
                                }
                            ]} */
                            />

                        </SubContainerGrafico >
                        <SubContainerGrafico backgroundDinamico={this.props.backgroundSegundo}>
                            <ContainerTextGrafico>
                                <div style={{ width: 80, height: 30 }}></div>
                                <TextGrafico colorDinamico={this.props.coresLetraPadrao}>Tempo de Solução dos Atendimentos</TextGrafico>
                                <ContainerIconesExport>
                                    <IconeExport colorDinamico="red" onClick={() => {
                                        this.props.exportaAtendimentosParaExcelPdf(this.props.arrayChamadosDetalhadosParaDownload, 'solucao', 'PDF')
                                    }} className="icon-pdf2" />
                                    <IconeExport onClick={() => {
                                        this.props.exportaAtendimentosParaExcelPdf(this.props.arrayChamadosDetalhadosParaDownload, 'solucao', 'EXCEL')
                                    }} colorDinamico="#32CD32" className="icon-spreadsheet" />
                                    <IconeExport onClick={() => {
                                        this.props.insereTextosInformacionaisModalRelatorioDetalhado(
                                            'Tempo de Solução',
                                            ' O Tempo de Solução dos Atendimentos define quanto tempo o atendimento fica dentro do seu sistema até ser solucionado permanentemente.',
                                            ' O Tempo de Solução dos Atendimentos é bom para ver o quanto a sua equipe de suporte é responsiva em relação ao volume de atendimentos que recebe.'
                                        )
                                    }} colorDinamico="#FFF" className="icon-danger1" />
                                </ContainerIconesExport>
                            </ContainerTextGrafico>

                            <ResponsivePie
                                data={this.props.atendimentosPorTempoSolucaoRelatorioDetalhado}
                                margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                                innerRadius={0.5}
                                padAngle={0.7}
                                cornerRadius={3}
                                colors={d => d.color}
                                borderWidth={1}
                                borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                                radialLabelsSkipAngle={10}
                                radialLabelsTextXOffset={6}
                                radialLabelsTextColor={this.props.coresLetraPadrao}
                                radialLabelsLinkOffset={0}
                                radialLabelsLinkDiagonalLength={16}
                                radialLabelsLinkHorizontalLength={24}
                                radialLabelsLinkStrokeWidth={1}
                                radialLabelsLinkColor={{ from: 'color' }}
                                slicesLabelsSkipAngle={10}
                                slicesLabelsTextColor="#000"
                                animate={true}
                                motionStiffness={90}
                                motionDamping={15}
                                tooltip={(e) => {
                                    if (e.value == 0.5) {
                                        return (
                                            <div style={{ height: 30, background: '#FFF', padding: 5, borderRadius: 5, flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center' }} key={e.id}>

                                                <div style={{ width: 13, height: 13, backgroundColor: e.color, marginRight: 5, marginLeft: 5 }}></div>
                                                <div>

                                                    <strong style={{ color: '#000' }}>
                                                        {`${e.id}: ${e.valorZerado}`}
                                                    </strong>
                                                </div>
                                            </div>

                                        );
                                    } else {
                                        return (
                                            <div style={{ height: 30, background: '#FFF', padding: 5, borderRadius: 5, flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center' }} key={e.id}>

                                                <div style={{ width: 13, height: 13, backgroundColor: e.color, marginRight: 5, marginLeft: 5 }}></div>
                                                <div>

                                                    <strong style={{ color: '#000' }}>
                                                        {`${e.id}: ${e.value}`}
                                                    </strong>
                                                </div>
                                            </div>

                                        );
                                    }
                                }}
                                sliceLabel={(d) => {
                                    if (d.value == 0.5) {
                                        return d.valorZerado
                                    } else {
                                        return d.value
                                    }
                                }}
                            /*   legends={[
                                  {
                                      anchor: 'bottom',
                                      direction: 'row',
                                      translateY: 56,
                                      itemWidth: 100,
                                      itemHeight: 18,
                                      itemTextColor: this.props.coresLetraPadrao,
                                      symbolSize: 18,
                                      symbolShape: 'circle',
                                      effects: [
                                          {
                                              on: 'hover',
                                              style: {
                                                  itemTextColor: this.props.coresLetraSecundario
                                              }
                                          }
                                      ]
                                  }
                              ]} */
                            />

                        </SubContainerGrafico>
                    </ContainerGrafico>
                    <ContainerGraficoTempoMedio>
                        <SubContainerGraficoTempoMedio backgroundDinamico={this.props.backgroundSegundo}>
                            <ContainerTextTituloTempoMedio>
                                <div style={{ width: 55, height: 30 }}></div>
                                <TextGrafico colorDinamico={this.props.coresLetraPadrao}>Tempo Médio Solução dos Atendimentos</TextGrafico>
                                <ContainerIconesExportTempoMedio>
                                    <IconeExport onClick={() => {
                                        this.props.insereTextosInformacionaisModalRelatorioDetalhado(
                                            'Tempo Médio Solução',
                                            ' O Tempo Médio de Solução dos Atendimentos define em média o tempo em que o atendimento fica dentro do seu sistema até ser solucionado permanentemente.',
                                            ' O Tempo Médio de Solução dos Atendimentos é bom para ver o quanto a sua equipe de suporte é responsiva em relação ao volume de atendimentos que recebe.'
                                        )
                                    }} colorDinamico="#FFF" className="icon-danger1" style={{ marginRight: 5 }} />
                                </ContainerIconesExportTempoMedio>
                            </ContainerTextTituloTempoMedio>
                            <ContainerTextTempoMedio>
                                <TextTempoMedio corLetraDinamica={this.props.coresLetraPadrao}>{this.props.tempoMedioParaSolucaoAtendimento}</TextTempoMedio>
                            </ContainerTextTempoMedio>
                        </SubContainerGraficoTempoMedio>
                        <SubContainerGraficoTempoMedio backgroundDinamico={this.props.backgroundSegundo}>
                            <ContainerTextTituloTempoMedio>
                                <div style={{ width: 55, height: 30 }}></div>
                                <TextGrafico colorDinamico={this.props.coresLetraPadrao}>Tempo Médio da Primeira Resposta</TextGrafico>
                                <ContainerIconesExportTempoMedio>
                                    <IconeExport onClick={() => {
                                        this.props.insereTextosInformacionaisModalRelatorioDetalhado(
                                            'Tempo Médio Primeira Resposta',
                                            ' O Tempo de Primeira Resposta diz respeito a agilidade do retorno de um chamado que o cliente abre para o suporte da empresa. Serão contados os minutos entre o horário que o chamado é criado e o retorno (data/hora que o atendente é atribuído ao chamado).',
                                            ' Quando existe a análise correta constante desse tempo, é possível verificar se o atendimento consegue alcançar as soluções de forma ágil. Aqui, é importante observar que o tempo de primeira resposta no atendimento deve considerar se o problema do cliente foi resolvido.'
                                        )
                                    }} colorDinamico="#FFF" className="icon-danger1" style={{ marginRight: 5 }} />
                                </ContainerIconesExportTempoMedio>
                            </ContainerTextTituloTempoMedio>
                            <ContainerTextTempoMedio>
                                <TextTempoMedio corLetraDinamica={this.props.coresLetraPadrao}>{this.props.tempoMedioPrimeiraResposta}</TextTempoMedio>
                            </ContainerTextTempoMedio>
                        </SubContainerGraficoTempoMedio>
                        {/*  <SubContainerGraficoTempoMedioSemSombra backgroundDinamico={this.props.backgroundPrimeiro}>

                        </SubContainerGraficoTempoMedioSemSombra> */}
                    </ContainerGraficoTempoMedio>

                    <Modal focusTrapped={false} showCloseIcon={false} open={this.props.modalInformacaoRelatorioDetalhado} onClose={() => { }} center>
                        <ContainerModal backgroundDinamico={this.props.backgroundPrimeiro}>
                            <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                                <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>{this.props.textCabecalhoModalInfoRelatorioDetalhado}</TextNovoAtendimento>
                                <div>
                                    <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => { this.props.modificaVisibilidadeModalInformacaoRelatorioDetalhado(false) }} className="icon-close" />
                                </div>
                            </ContainerIconeFecharModal>


                            <ContainerExplicacaoVincular>
                                <TextVincular corLetraDinamica={this.props.coresLetraPadrao}>{this.props.text1ModalInfRelatorioDetalhado}</TextVincular>
                            </ContainerExplicacaoVincular>
                            <ContainerExplicacaoVincular>
                                <TextVincular corLetraDinamica={this.props.coresLetraPadrao}>{this.props.text2ModalInfRelatorioDetalhado}</TextVincular>
                            </ContainerExplicacaoVincular>

                        </ContainerModal>
                    </Modal>
                </Container >
            )
        }
    }
    render() {

        return (
            <>
                {this.renderLoadingRelatorioDetalhado()}
            </>
        );
    }
}

const mapStateToProps = state => {
    return (
        {

            backgroundPrimeiro: state.CoresPadraoSistema.backgroundPrimeiro,
            backgroundSegundo: state.CoresPadraoSistema.backgroundSegundo,
            backgroundMaisEscuro: state.CoresPadraoSistema.backgroundMaisEscuro,
            coresLetraPadrao: state.CoresPadraoSistema.coresLetraPadrao,
            corBotaoPadrao: state.CoresPadraoSistema.corBotaoPadrao,
            corSegundoBotaoPadrao: state.CoresPadraoSistema.corSegundoBotaoPadrao,
            coresLetraSecundario: state.CoresPadraoSistema.coresLetraSecundario,
            corLetraDescricaoDosCampos: state.CoresPadraoSistema.corLetraDescricaoDosCampos,
            nomeRota: state.HeaderReducer.nomeRota,
            corBordaPadrao: state.CoresPadraoSistema.corBordaPadrao,
            corBordaDinamica: state.CoresPadraoSistema.corBordaDinamica,


            loadingRelatorioDetalhado: state.DashBoardReducer.loadingRelatorioDetalhado,
            loadingSemInternetRelatorioDetalhado: state.DashBoardReducer.loadingSemInternetRelatorioDetalhado,
            loadingRefreshControlRelatorioDetalhado: state.DashBoardReducer.loadingRefreshControlRelatorioDetalhado,
            periodoPersonalizadoRelatorioDetalhado: state.DashBoardReducer.periodoPersonalizadoRelatorioDetalhado,

            atendimentosAEFC: state.DashBoardReducer.atendimentosAEFC,
            totalAtendimentosAEFC: state.DashBoardReducer.totalAtendimentosAEFC,
            atendimentosPorPrioridadeRelatorioDetalhado: state.DashBoardReducer.atendimentosPorPrioridadeRelatorioDetalhado,
            totalAtendimentosPorPrioridadeRelatorioDetalhado: state.DashBoardReducer.totalAtendimentosPorPrioridadeRelatorioDetalhado,


            atendimentosPorTempoSolucaoRelatorioDetalhado: state.DashBoardReducer.atendimentosPorTempoSolucaoRelatorioDetalhado,
            totalAtendimentosPorTempoSolucaoRelatorioDetalhado: state.DashBoardReducer.totalAtendimentosPorTempoSolucaoRelatorioDetalhado,
            atendimentosInternoExternoRelatorioDetalhado: state.DashBoardReducer.atendimentosInternoExternoRelatorioDetalhado,
            totalAtendimentosInternoExternoRelatorioDetalhado: state.DashBoardReducer.totalAtendimentosInternoExternoRelatorioDetalhado,
            filtroRelatorioDetalhado: state.DashBoardReducer.filtroRelatorioDetalhado,


            clientesRelatorioAtendimentoDetalhado: state.ChamadoReducer.clientesSectionedConsulta,
            clienteSelecionadoRelatorioAtendimentoDetalhado: state.DashBoardReducer.clienteSelecionadoRelatorioAtendimentoDetalhado,
            atendentesRelatorioAtendimentoDetalhado: state.AtendenteReducer.atendentesSectionedConsulta,
            atendentesSelecionadoRelatorioAtendimentoDetalhado: state.DashBoardReducer.atendentesSelecionadoRelatorioAtendimentoDetalhado,
            SetoresRelatorioAtendimentoDetalhado: state.SetorReducer.setoresSectionedConsulta,
            SetoresSelecionadoRelatorioAtendimentoDetalhado: state.DashBoardReducer.SetoresSelecionadoRelatorioAtendimentoDetalhado,

            loadingClientesRelatorioAtendimentoDetalhado: state.DashBoardReducer.loadingClientesRelatorioAtendimentoDetalhado,
            loadingAtendentesRelatorioAtendimentoDetalhado: state.DashBoardReducer.loadingAtendentesRelatorioAtendimentoDetalhado,
            loadingSetoresRelatorioAtendimentoDetalhado: state.DashBoardReducer.loadingSetoresRelatorioAtendimentoDetalhado,

            filtroClienteSectioned: state.DashBoardReducer.filtroClienteSectioned,
            filtroAtendenteSectioned: state.DashBoardReducer.filtroAtendenteSectioned,
            filtroSetorSectioned: state.DashBoardReducer.filtroSetorSectioned,

            dataInicialConsultaFiltroRelatorioDetalhado: state.DashBoardReducer.dataInicialConsultaFiltroRelatorioDetalhado,
            dataFinalConsultaFiltroRelatorioDetalhado: state.DashBoardReducer.dataFinalConsultaFiltroRelatorioDetalhado,
            licensawebouapp: state.PremiumReducer.licensawebouapp,
            tempoMedioParaSolucaoAtendimento: state.DashBoardReducer.tempoMedioParaSolucaoAtendimento,

            modalInformacaoRelatorioDetalhado: state.DashBoardReducer.modalInformacaoRelatorioDetalhado,

            textCabecalhoModalInfoRelatorioDetalhado: state.DashBoardReducer.textCabecalhoModalInfoRelatorioDetalhado,
            text1ModalInfRelatorioDetalhado: state.DashBoardReducer.text1ModalInfRelatorioDetalhado,
            text2ModalInfRelatorioDetalhado: state.DashBoardReducer.text2ModalInfRelatorioDetalhado,
            premium: state.PremiumReducer.premium,
            arrayChamadosDetalhadosParaDownload: state.DashBoardReducer.arrayChamadosDetalhadosParaDownload,
            mensagemGerandoRelatorio: state.DashBoardReducer.mensagemGerandoRelatorio,
            permissoesAcessarTelasAtendente: state.AtendenteReducer.permissoesAcessarTelasAtendente,
            filtroSelecionadoRelatorioDetalhado: state.DashBoardReducer.filtroSelecionadoRelatorioDetalhado,
            tempoMedioPrimeiraResposta: state.DashBoardReducer.tempoMedioPrimeiraResposta,
        }
    );
};
export default connect(mapStateToProps, {
    modificaCorBordaListaTarefas, insereDataFiltroDashboard, modificaDataInicialFinalRelatorioAtendimentoDetalhado,
    modificaFiltroSelecionadoRelatorioDetalhado,
    modificaClienteTelaRelatorioDetalhado,
    modificaAtendenteRelatorioDetalhado, modificaSetorRelatorioDetalhado,
    modificaFiltroTelaRelatorioDetalhado, modificaFiltroClienteSectionedTelaRelatorioDetalhado, modificaFiltroAtendenteSectionedTelaRelatorioDetalhado,
    modificaFiltroSetorSectionedTelaRelatorioDetalhado, modificaVisibilidadeModalInformacaoRelatorioDetalhado,
    insereTextosInformacionaisModalRelatorioDetalhado, zeraMensagemGerandoRelatorio,
    modificaNomeRotaNoHeader, novalistaAtendimentosZeradoParaRelatorioDetalhado,
    listaAtendimentosPeriodoPersonalizado, listaClientesTelaConsultaClientes, listaAtendentesTelaConsultaAtendente,
    listaSetorSectionedTelaConsultaSetor, exportaAtendimentosParaExcelPdf, desligaOuvintesChamadosFirebase
})(withRouter(RelatorioAtendimentoDetalhado));
