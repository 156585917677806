import moment from 'moment';
import firebase from '../servicos/FirebaseConnection';
import api from '../http_client/http.client';
import { toastr } from 'react-redux-toastr';
import { removeFirebaseCachedData } from './cachedData';
import {
    MODIFICANOMEATENDENTE, MODIFICAEMAILACESSOAPP, MODIFICASENHAATENDENTE, MODIFICACONFIRMASENHAATENDENTE,
    INSEREFOTOCADASTROATENDENTE, ATIVALOADINGBOTAOCADASTRARATENDENTE, DESATIVALOADINGBOTAOCADASTRARATENDENTE,
    MENSAGEMCADASTRAATENDENTEERROR, MENSAGEMCADASTRAATENDENTESUCESSO, ZERAINFORMACOESCADASTROATENDENTE,
    MODIFICAVALORDIGITOCONSULTAATENDENTE, ENVIADADOSPARAATENDENTEREDUCER, ZERAMSGSUCESSOERRORATENDENTE,
    ALTERAFOTOATENDENTETELAALTERAR, ALTERANOMEATENDENTETELAALTERAR, ALTERAEMAILATENDENTETELAALTERAR,
    ALTERASENHAATENDENTETELAALTERAR, ALTERACONFIRMASENHAATENDENTETELAALTERAR, INSEREFOTOATENDENTETELAALTERARPICKER,
    MENSAGEMALTERAATENDENTESUCESSO, MENSAGEMCALTERAATENDENTEERRO, ATIVALOADINGBOTAOALTERARATENDENTE,
    ALTERAKEYATENDENTETELAALTERAR, SEMCONEXAOINTERNETCONSULTAATENDENTE, SEMCONEXAOINTERNETCADASTRAATENDENTE,
    SEMCONEXAOINTERNETALTERAATENDENTE, ATIVADESATIVALOADINGFIMDAPAGINATELACONSULTAATENDENTE, ATIVADESATIVALOADINGTELACONSULTAATENDENTE,
    ENVIAULTIMAREFERENCIAKEYATENDENTE, ENVIATENDENTESPARASECTIONED, ATIVADESATIVALOADINGSECTIONEDATENDENTE, MODIFICAATENDENTESELECIONADOSECTIONED,
    MODIFICANOMEATENDENTESELECIONADOSECTIONED, ATIVADESATIVALOADINGREFRESHCONTROLATENDENTE, MUDAVISIBILIDADEMODALCADASTRAATENDENTEVERSAOPREMIUM,
    ZERAMENSAGEMERRORTELACADASTROATENDENTE2,
    ALTERASWITCHPERMISSAOALTERARADMINISTRADOR, ALTERASWITCHPERMISSAOALTERARCLIENTE, ALTERASWITCHPERMISSAOALTERARATENDENTE,
    ALTERASWITCHPERMISSAOALTERARSETORES, ALTERASWITCHPERMISSAOALTERARAVISOS, ALTERASWITCHPERMISSAOACESSORELATORIOS, ALTERASWITCHPERMISSAOADMINISTRADORSOZINHO,
    ALTERAINFORMACAOCONTAPRINCIPALATENDENTE, ZERAMENSAGEMERRORAOALTERARATENDENTE, MUDAVISIBILIDADEMODALEXCLUIRATENDENTE,
    ATIVADESATIVALOADINGCONTEUDOEXCLUIRATENDENTE, ENVIAUIDATENDENTETELAALTERARATENDENTE,
    ENVIAATENDENTERELATORIODETALHADO, ATIVADESATIVALOADINGATENDENTERELATORIODETALHADO,
    MODIFICAFILTROTELAATENDENTES, MODIFICAVISIBLEMODALNOVOATENDENTE, MODIFICAARQUIVOCOMOPLETOATENDENTEPARAUPAR,


    MODIFICANOMEATENDENTENOVOCADASTRO, MODIFICAEMAILACESSOAPPNOVOCADASTRO, MODIFICASENHAATENDENTENOVOCADASTRO,
    MODIFICACONFIRMASENHAATENDENTENOVOCADASTRO, ALTERASWITCHPERMISSAOALTERARADMINISTRADORNOVOCADASTRO,
    ALTERASWITCHPERMISSAOALTERARCLIENTENOVOCADASTRO, ALTERASWITCHPERMISSAOALTERARATENDENTENOVOCADASTRO,
    ALTERASWITCHPERMISSAOALTERARSETORESNOVOCADASTRO, ALTERASWITCHPERMISSAOALTERARAVISOSNOVOCADASTRO, ALTERASWITCHPERMISSAOACESSORELATORIOSNOVOCADASTRO,
    MODIFICAARQUIVOCOMOPLETOATENDENTEPARAUPARNOVOCADASTRO,
    ATIVADESATIVALOADINGENVIAATENDENTETELAAVALIACAOTODAS, ENVIATENDENTEPARASECTIONEDRELATORIOATENDENTE,
    ENVIAATENDENTEPARAREDUCERTODASAVALIACOES,
    ENVIAATENDENTEPARAREDUCERNOVOAVISO, ATIVADESATIVALOADINGCARREGARATENDENTETELANOVOAVISO,
    ENVIAUSUARIOSTELAACOESATENDIMENTO, ENVIAATENDENTEPARAREDUCER,
    DESATIVALOADINGCARREGARATENDENTETELANOVOCHAMADO, ATIVALOADINGCARREGARATENDENTETELANOVOCHAMADO, MODIFICAPERMISSAOCANCELARATENDIMENTONOVOCADASTRO,
    ALTERASWITCHPERMISSAOCANCELARATENDIMENTO
} from './types';



export const cadastrarAtendente = (nomeAtendente2, emailAtendente2, senhaAtendente2, confirmaSenhaAtendente2, uriImagem2, premium,
    permissaoAdministrador, permissaoCliente, permissaoAtendente, permissaoSetor, permissaoAvisos, permissaoAcessoRelatorios, qtdAtendentesLiberados, arquivoCompletoParaUpar,
    permissaoCancelarAtendimentoNovoCadastro) => {











    let tipoUri = isNumber(uriImagem2);
    // 
    let uriImagem = tipoUri == true ? "https://firebasestorage.googleapis.com/v0/b/dark-4b307.appspot.com/o/imagemPadraoDark%2Fuser.png?alt=media&token=5494861d-8431-480d-ac62-442c8b2c39af" : uriImagem2.uri;
    let StringAdmin = '';
    let contaPrincipal = false;
    let uriHTTP = 0;
    const nomeAtendente = nomeAtendente2.trim();
    const nomeAtendentePesquisa = nomeAtendente2.trim().toLowerCase();
    const emailAtendente = emailAtendente2.trim().toLowerCase();
    const senhaAtendente = senhaAtendente2.trim();
    const confirmaSenhaAtendente = confirmaSenhaAtendente2.trim();
    if (permissaoAdministrador) {
        StringAdmin = 'Admin';
    }
    if (uriImagem2.includes('/static/media/userPadrao') || uriImagem2 === null || uriImagem2 === undefined) {
        // 
        //é uma imagem local nao precisa upar
        uriHTTP = 1;
    } else if (arquivoCompletoParaUpar === '') {
        //  
        uriHTTP = 1;
    } else {
        // 
        //é uma imagem que precisa upar
        //  uriImagem = uriImagem2.uri;
    }
    //
    return dispatch => {


        if (nomeAtendente.trim() === '') {
            toastr.error('Error', 'Digite o nome do atendente.');
        }
        else if (nomeAtendente.trim().length < 2) {
            toastr.error('Error', 'O nome do atendente deve possuir mais de 1 letra.');
        }
        else if (emailAtendente.trim() === '') {
            toastr.error('Error', 'Digite o email para o atendente acessar ao APP.');
        }
        else if (senhaAtendente.trim() === '') {
            toastr.error('Error', 'Digite a senha de acesso.');
        }
        else if (confirmaSenhaAtendente.trim() === '') {
            toastr.error('Error', 'Confirme a senha de acesso.');
        }
        else if (senhaAtendente.trim().length < 6) {
            toastr.error('Error', 'A senha de acesso deve conter pelo menos 6 digitos.');
        }
        else if (senhaAtendente !== confirmaSenhaAtendente) {
            toastr.error('Error', 'Senhas diferentes, por favor digite novamente.');
        } else {
            ativaLoadingBotaoCadastrarAtendente(dispatch);
            AsyncStorage.getItem('chaveUnica').then((chaveUnica2) => {
                const chaveUnica = chaveUnica2.replace(/"/g, "");

                firebase.database().ref(`${chaveUnica}/usuarios/`).orderByChild('excluido').equalTo(null).once('value').then((snapshot) => {
                    //  

                    let proximaQuantidadeDeAtendente = snapshot.numChildren() + 1;
                    if (qtdAtendentesLiberados < proximaQuantidadeDeAtendente) {
                        toastr.error('Limite de Atendentes Atingido', `Você não poderá cadastrar mais atendentes, porque já atingiu o limite de cadastro da sua versão premium`);
                        desativaLoadingBotaoCadastrarAtendente(dispatch)
                    } else {
                        //  

                        /*  secondaryApp.auth().createUserWithEmailAndPassword(emailAtendente, senhaAtendente).then(
                             (user) => {
                                 //codigo useruid para virar minha chave unica principal
                                 const useruid = user.uid; */
                        const options = {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json; charset=utf-8',
                                'Accept': 'application/json',
                            },

                            data: { email: emailAtendente, senha: senhaAtendente, rota: 'create',chaveUnica },
                            url: 'crudUserReactNative',
                        };
                        api(options).then((response) => {


                            //codigo useruid para virar minha chave unica principal
                            let useruid = response.data.uid;

                            let numeroCodigoInterno = (snapshot.numChildren() + 1);
                            let codigoIterno = `codigoInterno${numeroCodigoInterno}`;

                            firebase.database().ref(`${chaveUnica}/usuarios/`).once('value').then((snapshot2) => {
                                let numeroCodigoInterno = (snapshot2.numChildren() + 1);
                                let codigoIterno = `codigoInterno${numeroCodigoInterno}`;
                                //cadastrando a empresa na tabela principal( email base 64)
                                firebase.database().ref(`${chaveUnica}/usuarios`).push({
                                    nome: nomeAtendente, email: emailAtendente, nomePesquisa: nomeAtendentePesquisa,
                                    uid: useruid, senha: senhaAtendente, alterado: 0, fcmToken: '', notifNovoAtend: 1,
                                    notifRespAtendAbertosPorMim: 1, notifRespAtendEmQueEstouAtend: 1, notifAtendimentoAtribuidoAmim: 1,
                                    permissaoAdministrador: permissaoAdministrador, permissaoAlterarCadastrarCliente: permissaoCliente, permissaoAlterarCadastrarAtendente: permissaoAtendente, permissaoAlterarCadastrarSetores: permissaoSetor, permissaoAlterarCadastrarAvisos: permissaoAvisos,
                                    permissaoAcessoRelatorios: permissaoAcessoRelatorios, StringAdmin: StringAdmin, contaPrincipal: contaPrincipal, urlImagemNuvem: "", codigoInterno: codigoIterno,
                                    permissaoCancelarAtendimentoNovoCadastro: permissaoCancelarAtendimentoNovoCadastro
                                }).then(
                                    (dadosCliente) =>
                                        //cadastrando o nome do usuario na tabela principal (email base 64)
                                        firebase.database().ref(`usuariosALL/${useruid}`).push({ chaveUnica: chaveUnica, admin: 1 }).then(
                                            () => {
                                                // let uri = uriImagem.replace('file://', '');//remove o file:// que tem no ios
                                                if (uriHTTP === 0) {
                                                    let caminhoPadraoImagem = firebase.storage().ref(`${chaveUnica}/${dadosCliente.key}/imagem.jpg`);
                                                    let mime = 'image/jpeg';
                                                    /*    RNFetchBlob.fs.readFile(uri, 'base64').then(
                                                           (data) => {
                                                               return RNFetchBlob.polyfill.Blob.build(data, { type: mime + ';BASE64' });
                                                           }).then(
                                                               (blob) => { */
                                                    caminhoPadraoImagem.put(arquivoCompletoParaUpar).then(
                                                        (url) => {
                                                            url.ref.getDownloadURL().then(function (downloadURL) {
                                                                // blob.close();
                                                                //alterado o alterado para 1 pra poder atualizar o flatlist e carregar a foto
                                                                firebase.database().ref(`${chaveUnica}/usuarios/${dadosCliente.key}`).update({ alterado: 1, urlImagemNuvem: downloadURL });
                                                                zeraInformacoesCadastroAtendente(dispatch);
                                                                //  dispatch(NavigationActions.navigate({ routeName: 'CadastroAtendentes' }));
                                                                // let urlImagem = caminhoPadraoImagem.getDownloadURL(); pega url da imagem upada

                                                                toastr.success('Sucesso', 'Atendente cadastrado com Sucesso!');
                                                                desativaLoadingBotaoCadastrarAtendente(dispatch)
                                                            })
                                                        }).catch((error) => {
                                                            desativaLoadingBotaoCadastrarAtendente(dispatch)
                                                            //error.code
                                                            //error.message
                                                            switch (error.code) {
                                                                case 'auth/invalid-email':
                                                                    toastr.error('Error', 'Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br');
                                                                    break;
                                                                case 'auth/email-already-in-use':
                                                                    toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                                                                    break;
                                                                case 'auth/network-request-failed':
                                                                    toastr.error('Error', 'Sem conexão com a internet');
                                                                    break;
                                                                default:
                                                                    toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                                                                    break;
                                                            }
                                                        })
                                                    /*   }) */
                                                } else {
                                                    zeraInformacoesCadastroAtendente(dispatch);
                                                    //   dispatch(NavigationActions.navigate({ routeName: 'CadastroAtendentes' }));
                                                    // let urlImagem = caminhoPadraoImagem.getDownloadURL(); pega url da imagem upada
                                                    //mensagemAlteraAtendenteErro(dispatch, 'Atendente cadastrado com Sucesso!');
                                                    toastr.success('Sucesso', 'Atendente cadastrado com Sucesso!');
                                                    desativaLoadingBotaoCadastrarAtendente(dispatch)
                                                }

                                            }
                                        ).catch((error) => {
                                            desativaLoadingBotaoCadastrarAtendente(dispatch)
                                            //error.code
                                            //error.message
                                            switch (error.code) {
                                                case 'auth/invalid-email':
                                                    toastr.error('Error', 'Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br');
                                                    break;
                                                case 'auth/email-already-in-use':
                                                    toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                                                    break;
                                                case 'auth/network-request-failed':
                                                    toastr.error('Error', 'Sem conexão com a internet');
                                                    break;
                                                default:
                                                    toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                                                    break;
                                            }
                                        })
                                ).catch((error) => {
                                    desativaLoadingBotaoCadastrarAtendente(dispatch)
                                    //error.code
                                    //error.message
                                    switch (error.code) {
                                        case 'auth/invalid-email':
                                            toastr.error('Error', 'Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br');
                                            break;
                                        case 'auth/email-already-in-use':
                                            toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                                            break;
                                        case 'auth/network-request-failed':
                                            toastr.error('Error', 'Sem conexão com a internet');
                                            break;
                                        default:
                                            toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                                            break;
                                    }
                                })
                            })
                        }
                        ).catch((error) => {
                            //error.code
                            desativaLoadingBotaoCadastrarAtendente(dispatch)
                            //error.message
                            if (error.response) {


                                switch (error.response.data.code) {
                                    case 'auth/email-already-exists':
                                        toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                                        break;
                                    case 'auth/invalid-email':
                                        toastr.error('Error', 'Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br');
                                        break;
                                    case 'auth/email-already-in-use':
                                        toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                                        break;
                                    case 'auth/network-request-failed':
                                        toastr.error('Error', 'Sem conexão com a internet');
                                        break;
                                    default:
                                        toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                                        break;
                                }
                            } else {
                                toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                            }
                        })
                    }
                })
            })

        }


    }
}
export const excluiAtendente = (keyAtendente) => {
    return dispatch => {
        AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            ativaDesativaLoadingTelaConsultaAtendente(dispatch, true);
            const atendente = firebase.database().ref(`${chaveUnica}/usuarios/${keyAtendente}`);
            atendente.once("value").then(snapshot => {
                const emailBanco = snapshot.val().email;
                const alterado = 1 + snapshot.val().alterado;
                const senhaBanco = snapshot.val().senha;
                let uidAtendente = snapshot.val().uid;

                /*   secondaryApp.auth().signInWithEmailAndPassword(emailBanco, senhaBanco)
                      .then(userCredential => {
                          userCredential.delete().then(
                              () => { */
                const options = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                        'Accept': 'application/json',
                    },

                    data: { email: emailBanco, senha: senhaBanco, rota: 'excluir', uid: uidAtendente },
                    url: 'crudUserReactNative',
                };
                api(options).then(() => {
                    // 
                    atendente.update({
                        excluido: 1,
                        email: '',
                        alterado,
                        fcmToken: '',
                        fcmTokenWEB: ''
                    }).then(() => {
                        //
                        ativaDesativaLoadingTelaConsultaAtendente(dispatch, false);
                        //  mudaVisibilidadeModalExcluirAtendenteLocal(dispatch, false);
                        setTimeout(() => {
                            //dispatch(NavigationActions.back());
                            toastr.success('SUCESSO', 'Atendente excluído com sucesso!');
                            //   
                            firebase.database().ref(`${chaveUnica}/empresa/`).once('value').then((snapshot) => {
                                let keyEmpresa = '';
                                snapshot.forEach(childItem => {
                                    keyEmpresa = childItem.key;

                                })
                                let alterado2 = retornaDataAtualTimeStamp();
                                //
                                firebase.database().ref(`${chaveUnica}/empresa/${keyEmpresa}`).update({ alterado: alterado2 })
                            })
                        }, 100);
                    });


                }).catch((error) => {

                    ativaDesativaLoadingTelaConsultaAtendente(dispatch, false);
                    if (error.response) {


                        switch (error.response.data.code) {
                            case 'auth/email-already-exists':
                                toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                                break;
                            case 'auth/invalid-email':
                                toastr.error('Error', 'Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br');
                                break;
                            case 'auth/email-already-in-use':
                                toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                                break;
                            case 'auth/network-request-failed':
                                toastr.error('Error', 'Sem conexão com a internet');
                                break;
                            default:
                                toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                                break;
                        }
                    } else {
                        toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                    }
                })
            })
        })
    }
}
export const alterarAtendente = (nomeAtendente2, emailAtendente2, senhaAtendente2, confirmaSenhaAtendente2, uriImagemAtendente2, keyAtendente,
    permissaoAdministrador, permissaoAlterarCadastrarCliente, permissaoAlterarCadastrarAtendente, permissaoAlterarCadastrarSetores,
    permissaoAlterarCadastrarAvisos, permissaoAcessoRelatorios, contaPrincipal, arquivoCompletoParaUpar, permissaoCancelarAtendimento) => {
    let StringAdmin = '';
    /*     console.log(nomeAtendente2, emailAtendente2, senhaAtendente2, confirmaSenhaAtendente2, uriImagemAtendente2, keyAtendente,
            permissaoAdministrador, permissaoAlterarCadastrarCliente, permissaoAlterarCadastrarAtendente, permissaoAlterarCadastrarSetores,
            permissaoAlterarCadastrarAvisos, permissaoAcessoRelatorios, contaPrincipal, arquivoCompletoParaUpar) */
    if (permissaoAdministrador) {
        StringAdmin = 'Admin';
    }
    const nomeAtendente = nomeAtendente2.trim();
    const nomeAtendentePesquisa = nomeAtendente2.trim().toLowerCase();
    const emailAtendente = emailAtendente2.trim().toLowerCase();
    const senhaAtendente = senhaAtendente2.trim();
    const confirmaSenhaAtendente = confirmaSenhaAtendente2.trim();
    let uriHTTP = 0;
    let uriImagemAtendente;
    let verificaSeUriImagemEhImagemUpadaOuNao = false;


    if (uriImagemAtendente2 === null || uriImagemAtendente2 === undefined || uriImagemAtendente2.includes('/static/media/userPadrao') || uriImagemAtendente2.includes('data:image/png;base64')) {
        // 
        //é uma imagem local nao precisa upar
        uriHTTP = 1;
    } else if (uriImagemAtendente2.includes("http") && !uriImagemAtendente2.includes("blob")) {
        verificaSeUriImagemEhImagemUpadaOuNao = true;
        uriImagemAtendente = uriImagemAtendente2;
        // 
        uriHTTP = 1;
    } else if (arquivoCompletoParaUpar === '') {
        // 
        uriHTTP = 1;
    } else {
        // 
        //é uma imagem que precisa upar
        uriImagemAtendente = uriImagemAtendente2.uri;
    }
    return dispatch => {


        if (nomeAtendente.trim() === '') {
            toastr.error('Error', 'Digite o nome do atendente.');
        }
        else if (nomeAtendente.trim().length < 2) {
            toastr.error('Error', 'O nome do atendente deve possuir mais de 1 letra.');
        }
        else if (emailAtendente.trim() === '') {
            toastr.error('Error', 'Digite o email para o atendente acessar ao APP.');
        }
        else if (senhaAtendente.trim() === '') {
            toastr.error('Error', 'Digite a senha de acesso.');
        }
        else if (confirmaSenhaAtendente.trim() === '') {
            toastr.error('Error', 'Confirme a senha de acesso.');
        }
        else if (senhaAtendente.trim().length < 6) {
            toastr.error('Error', 'A senha de acesso deve conter pelo menos 6 digitos.');
        }
        else if (senhaAtendente !== confirmaSenhaAtendente) {
            toastr.error('Error', 'Senhas diferentes, por favor digite novamente.');
        } else {
            ativaDesativaLoadingBotaoAlterarAtendente(dispatch, true);
            AsyncStorage.getItem('chaveUnica').then((chaveUnica2) => {
                const chaveUnica = chaveUnica2.replace(/"/g, "");
                const atendente = firebase.database().ref(`${chaveUnica}/usuarios/${keyAtendente}`)
                atendente.once('value').then((snapshot) => {
                    const emailBanco = snapshot.val().email;
                    const alterado = (1 + snapshot.val().alterado);
                    const senhaBanco = (snapshot.val().senha);
                    let uidAtendente = snapshot.val().uid;

                    //email iguais e senha iguais então só altero o nome e a foto
                    if (emailBanco == emailAtendente && senhaBanco == senhaAtendente) {
                        /*   const primeiraLetra = nomeAtendente.substring(0, 1).trim().toLowerCase();
                          firebase.storage().ref(`letras/${primeiraLetra}.png`).getDownloadURL().then((url) => { */
                        if (uriHTTP == 1) {
                            //  
                            firebase.database().ref(`${chaveUnica}/usuarios/${keyAtendente}`).update({
                                nome: nomeAtendente, nomePesquisa: nomeAtendentePesquisa, alterado: alterado, permissaoAdministrador: permissaoAdministrador, permissaoAlterarCadastrarCliente, permissaoAlterarCadastrarAtendente, permissaoAlterarCadastrarSetores,
                                permissaoAlterarCadastrarAvisos, permissaoAcessoRelatorios, contaPrincipal, StringAdmin,
                                urlImagemNuvem: verificaSeUriImagemEhImagemUpadaOuNao == true ? uriImagemAtendente : "",
                                permissaoCancelarAtendimentoNovoCadastro: permissaoCancelarAtendimento
                            }).then(
                                () => {
                                    setTimeout(() => {
                                        removeFirebaseCachedData(`${chaveUnica}/usuarios`, keyAtendente);
                                        toastr.success('SUCESSO', 'Atendente alterado com sucesso!');
                                        ativaDesativaLoadingBotaoAlterarAtendente(dispatch, false);
                                    }, 700);
                                }
                            ).catch((error) => {
                                ativaDesativaLoadingBotaoAlterarAtendente(dispatch, false);
                                //error.code
                                //error.message
                                switch (error.code) {
                                    case 'auth/invalid-email':
                                        toastr.error('Error', 'Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br');
                                        break;
                                    case 'auth/email-already-in-use':
                                        toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                                        break;
                                    case 'auth/network-request-failed':
                                        toastr.error('Error', 'Sem conexão com a internet');
                                        break;
                                    default:
                                        toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                                        break;
                                }
                            })
                        }
                        else {
                            // 
                            // let uri = uriImagemAtendente.replace('file://', '');
                            let caminhoPadraoImagem = firebase.storage().ref(`${chaveUnica}/${keyAtendente}/imagem.jpg`);
                            let mime = 'image/jpeg';
                            /*     RNFetchBlob.fs.readFile(uri, 'base64').then(
                                    (data) => {
                                        return RNFetchBlob.polyfill.Blob.build(data, { type: mime + ';BASE64' });
                                    }).then(
                                        (blob) => { */
                            caminhoPadraoImagem.put(arquivoCompletoParaUpar).then(
                                (url) => {
                                    url.ref.getDownloadURL().then(function (downloadURL) {
                                        firebase.database().ref(`${chaveUnica}/usuarios/${keyAtendente}`).update({
                                            nome: nomeAtendente, nomePesquisa: nomeAtendentePesquisa, alterado: alterado, permissaoAdministrador, permissaoAlterarCadastrarCliente, permissaoAlterarCadastrarAtendente, permissaoAlterarCadastrarSetores,
                                            permissaoAlterarCadastrarAvisos, permissaoAcessoRelatorios, contaPrincipal, StringAdmin,
                                            urlImagemNuvem: downloadURL, permissaoCancelarAtendimentoNovoCadastro: permissaoCancelarAtendimento
                                        }).then(
                                            () => {
                                                setTimeout(() => {
                                                    //   blob.close();
                                                    removeFirebaseCachedData(`${chaveUnica}/usuarios`, keyAtendente);
                                                    ativaDesativaLoadingBotaoAlterarAtendente(dispatch, false);
                                                    toastr.success('SUCESSO', 'Atendente alterado com sucesso!')
                                                }, 700);
                                            }
                                        ).catch((error) => {
                                            ativaDesativaLoadingBotaoAlterarAtendente(dispatch, false);
                                            //error.code
                                            //error.message
                                            switch (error.code) {
                                                case 'auth/invalid-email':
                                                    toastr.error('Error', 'Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br');
                                                    break;
                                                case 'auth/email-already-in-use':
                                                    toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                                                    break;
                                                case 'auth/network-request-failed':
                                                    toastr.error('Error', 'Sem conexão com a internet');
                                                    break;
                                                default:
                                                    toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                                                    break;
                                            }
                                        })
                                    })
                                }).catch((error) => {
                                    ativaDesativaLoadingBotaoAlterarAtendente(dispatch, false);
                                    //
                                    //error.code
                                    //error.message
                                    switch (error.code) {
                                        case 'auth/invalid-email':
                                            toastr.error('Error', 'Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br');
                                            break;
                                        case 'auth/email-already-in-use':
                                            toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                                            break;
                                        case 'auth/network-request-failed':
                                            toastr.error('Error', 'Sem conexão com a internet');
                                            break;
                                        default:
                                            toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                                            break;
                                    }
                                })
                            /*       }).catch((error) => {
                                      //error.code
                                      //error.message
                                      switch (error.code) {
                                          case 'auth/invalid-email':
                                              toastr.error('Error', 'Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br');
                                              break;
                                          case 'auth/email-already-in-use':
                                              toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                                              break;
                                          case 'auth/network-request-failed':
                                              toastr.error('Error', 'Sem conexão com a internet');
                                              break;
                                          default:
                                              toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                                              break;
                                      }
                                  }) */
                        }
                        /*     }).catch((error) => {
                                //error.code
                                //error.message
                                
                                switch (error.code) {
                                    case 'auth/invalid-email':
                                        toastr.error('Error', 'Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br');
                                        break;
                                    case 'auth/email-already-in-use':
                                        toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                                        break;
                                    case 'auth/network-request-failed':
                                        toastr.error('Error', 'Sem conexão com a internet');
                                        break;
                                    case 'storage/object-not-found':
                                        toastr.error('Error', 'Nome inválido, utilize letras!');
                                        break;
                                    default:
                                        toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                                        break;
                                }
                            }) */
                    }
                    //email iguais altero a senha nome e a foto
                    else if (emailBanco == emailAtendente && senhaBanco != senhaAtendente) {
                        // 
                        /*      secondaryApp.auth().signInWithEmailAndPassword(emailAtendente, senhaBanco).then(
                                 (user2) => {
                                     user2.updatePassword(senhaAtendente).then(
                                         () => { */
                        /* const primeiraLetra = nomeAtendente.substring(0, 1).trim().toLowerCase();
                        firebase.storage().ref(`letras/${primeiraLetra}.png`).getDownloadURL().then((url) => { */
                        const options = {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json; charset=utf-8',
                                'Accept': 'application/json',
                            },

                            data: { email: emailAtendente, senha: senhaAtendente, rota: 'alterarsenha', uid: uidAtendente },
                            url: 'crudUserReactNative',
                        };
                        api(options).then((response) => {


                            if (uriHTTP == 1) {
                                //  
                                firebase.database().ref(`${chaveUnica}/usuarios/${keyAtendente}`).update({
                                    nome: nomeAtendente, nomePesquisa: nomeAtendentePesquisa, alterado: alterado, senha: senhaAtendente,
                                    permissaoAdministrador, permissaoAlterarCadastrarCliente, permissaoAlterarCadastrarAtendente, permissaoAlterarCadastrarSetores,
                                    permissaoAlterarCadastrarAvisos, permissaoAcessoRelatorios, contaPrincipal, StringAdmin,
                                    urlImagemNuvem: verificaSeUriImagemEhImagemUpadaOuNao == true ? uriImagemAtendente : "",
                                    permissaoCancelarAtendimentoNovoCadastro: permissaoCancelarAtendimento
                                }).then(
                                    () => {
                                        setTimeout(() => {
                                            removeFirebaseCachedData(`${chaveUnica}/usuarios`, keyAtendente);
                                            ativaDesativaLoadingBotaoAlterarAtendente(dispatch, false);
                                            toastr.success('SUCESSO', 'Atendente alterado com sucesso!');
                                        }, 700);

                                    }
                                ).catch((error) => {
                                    ativaDesativaLoadingBotaoAlterarAtendente(dispatch, false);
                                    //error.code
                                    //error.message
                                    switch (error.code) {
                                        case 'auth/invalid-email':
                                            toastr.error('Error', 'Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br');
                                            break;
                                        case 'auth/email-already-in-use':
                                            toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                                            break;
                                        case 'auth/network-request-failed':
                                            toastr.error('Error', 'Sem conexão com a internet');
                                            break;
                                        default:
                                            toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                                            break;
                                    }
                                })
                            }
                            else {
                                // 
                                //     let uri = uriImagemAtendente.replace('file://', '');
                                let caminhoPadraoImagem = firebase.storage().ref(`${chaveUnica}/${keyAtendente}/imagem.jpg`);
                                let mime = 'image/jpeg';
                                /*  RNFetchBlob.fs.readFile(uri, 'base64').then(
                                     (data) => {
                                         return RNFetchBlob.polyfill.Blob.build(data, { type: mime + ';BASE64' });
                                     }).then(
                                         (blob) => { */
                                caminhoPadraoImagem.put(arquivoCompletoParaUpar).then(
                                    (url) => {
                                        url.ref.getDownloadURL().then(function (downloadURL) {
                                            firebase.database().ref(`${chaveUnica}/usuarios/${keyAtendente}`).update({
                                                nome: nomeAtendente, nomePesquisa: nomeAtendentePesquisa, alterado: alterado, senha: senhaAtendente,
                                                permissaoAdministrador, permissaoAlterarCadastrarCliente, permissaoAlterarCadastrarAtendente, permissaoAlterarCadastrarSetores,
                                                permissaoAlterarCadastrarAvisos, permissaoAcessoRelatorios, contaPrincipal, StringAdmin,
                                                urlImagemNuvem: downloadURL, permissaoCancelarAtendimentoNovoCadastro: permissaoCancelarAtendimento
                                            }).then(
                                                () => {
                                                    setTimeout(() => {
                                                        removeFirebaseCachedData(`${chaveUnica}/usuarios`, keyAtendente);
                                                        toastr.success('SUCESSO', 'Atendente alterado com sucesso!');
                                                        ativaDesativaLoadingBotaoAlterarAtendente(dispatch, false);
                                                        //   blob.close();
                                                    }, 700);

                                                }
                                            ).catch((error) => {
                                                ativaDesativaLoadingBotaoAlterarAtendente(dispatch, false);
                                                //error.code
                                                //error.message
                                                switch (error.code) {
                                                    case 'auth/invalid-email':
                                                        toastr.error('Error', 'Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br');
                                                        break;
                                                    case 'auth/email-already-in-use':
                                                        toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                                                        break;
                                                    case 'auth/network-request-failed':
                                                        toastr.error('Error', 'Sem conexão com a internet');
                                                        break;
                                                    default:
                                                        toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                                                        break;
                                                }
                                            })
                                        })
                                    }).catch((error) => {
                                        ativaDesativaLoadingBotaoAlterarAtendente(dispatch, false);
                                        //error.code
                                        //error.message
                                        switch (error.code) {
                                            case 'auth/invalid-email':
                                                toastr.error('Error', 'Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br');
                                                break;
                                            case 'auth/email-already-in-use':
                                                toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                                                break;
                                            case 'auth/network-request-failed':
                                                toastr.error('Error', 'Sem conexão com a internet');
                                                break;
                                            default:
                                                toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                                                break;
                                        }
                                    })
                                /*     }).catch((error) => {
                                        //error.code
                                        //error.message
                                        switch (error.code) {
                                            case 'auth/invalid-email':
                                                toastr.error('Error', 'Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br');
                                                break;
                                            case 'auth/email-already-in-use':
                                                toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                                                break;
                                            case 'auth/network-request-failed':
                                                toastr.error('Error', 'Sem conexão com a internet');
                                                break;
                                            default:
                                                toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                                                break;
                                        }
                                    }) */
                            }
                            /*   }).catch((error) => {
                                  //error.code
                                  //error.message
                                  
                                  switch (error.code) {
                                      case 'auth/invalid-email':
                                          toastr.error('Error', 'Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br');
                                          break;
                                      case 'auth/email-already-in-use':
                                          toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                                          break;
                                      case 'auth/network-request-failed':
                                          toastr.error('Error', 'Sem conexão com a internet');
                                          break;
                                      case 'storage/object-not-found':
                                          toastr.error('Error', 'Nome inválido, utilize letras!');
                                          break;
                                      default:
                                          toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                                          break;
                                  }
                              }) */
                            /*    }).catch((error) => {
                                   ativaDesativaLoadingBotaoAlterarAtendente(dispatch, false);
                                   //error.code
                                   //error.message
                                   switch (error.code) {
                                       case 'auth/invalid-email':
                                           toastr.error('Error', 'Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br');
                                           break;
                                       case 'auth/email-already-in-use':
                                           toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                                           break;
                                       case 'auth/network-request-failed':
                                           toastr.error('Error', 'Sem conexão com a internet');
                                           break;
                                       default:
                                           toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                                           break;
                                   }
                               }) */
                        }).catch((error) => {
                            ativaDesativaLoadingBotaoAlterarAtendente(dispatch, false);
                            //error.code
                            //error.message
                            if (error.response) {


                                switch (error.response.data.code) {
                                    case 'auth/email-already-exists':
                                        toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                                        break;
                                    case 'auth/invalid-email':
                                        toastr.error('Error', 'Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br');
                                        break;
                                    case 'auth/email-already-in-use':
                                        toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                                        break;
                                    case 'auth/network-request-failed':
                                        toastr.error('Error', 'Sem conexão com a internet');
                                        break;
                                    default:
                                        toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                                        break;
                                }
                            } else {
                                toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                            }
                        })

                    }
                    //senhas iguais então altero o email, nome e a foto
                    else if (senhaBanco == senhaAtendente && emailBanco != emailAtendente) {
                        /*   secondaryApp.auth().signInWithEmailAndPassword(emailBanco, senhaBanco).then(
                              (userCredential) => {
                                  userCredential.updateEmail(emailAtendente).then(
                                      () => { */
                        /* const primeiraLetra = nomeAtendente.substring(0, 1).trim().toLowerCase();
                        firebase.storage().ref(`letras/${primeiraLetra}.png`).getDownloadURL().then((url) => { */
                        const options = {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json; charset=utf-8',
                                'Accept': 'application/json',
                            },

                            data: { email: emailAtendente, senha: senhaAtendente, rota: 'alteraremail', uid: uidAtendente },
                            url: 'crudUserReactNative',
                        };
                        api(options).then((response) => {


                            if (uriHTTP == 1) {
                                //  
                                firebase.database().ref(`${chaveUnica}/usuarios/${keyAtendente}`).update({
                                    nome: nomeAtendente, nomePesquisa: nomeAtendentePesquisa, alterado: alterado, email: emailAtendente,
                                    permissaoAdministrador, permissaoAlterarCadastrarCliente, permissaoAlterarCadastrarAtendente, permissaoAlterarCadastrarSetores,
                                    permissaoAlterarCadastrarAvisos, permissaoAcessoRelatorios, contaPrincipal, StringAdmin,
                                    urlImagemNuvem: verificaSeUriImagemEhImagemUpadaOuNao == true ? uriImagemAtendente : "",
                                    permissaoCancelarAtendimentoNovoCadastro: permissaoCancelarAtendimento
                                }).then(
                                    () => {
                                        setTimeout(() => {
                                            removeFirebaseCachedData(`${chaveUnica}/usuarios`, keyAtendente);
                                            ativaDesativaLoadingBotaoAlterarAtendente(dispatch, false);
                                            toastr.success('SUCESSO', 'Atendente alterado com sucesso!');
                                        }, 700);

                                    }
                                ).catch((error) => {
                                    ativaDesativaLoadingBotaoAlterarAtendente(dispatch, false);
                                    //error.code
                                    //error.message
                                    switch (error.code) {
                                        case 'auth/invalid-email':
                                            toastr.error('Error', 'Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br');
                                            break;
                                        case 'auth/email-already-in-use':
                                            toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                                            break;
                                        case 'auth/network-request-failed':
                                            toastr.error('Error', 'Sem conexão com a internet');
                                            break;
                                        default:
                                            toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                                            break;
                                    }
                                })
                            }
                            else {
                                //  
                                //    let uri = uriImagemAtendente.replace('file://', '');
                                let caminhoPadraoImagem = firebase.storage().ref(`${chaveUnica}/${keyAtendente}/imagem.jpg`);
                                let mime = 'image/jpeg';
                                /* RNFetchBlob.fs.readFile(uri, 'base64').then(
                                    (data) => {
                                        return RNFetchBlob.polyfill.Blob.build(data, { type: mime + ';BASE64' });
                                    }).then(
                                        (blob) => { */
                                caminhoPadraoImagem.put(arquivoCompletoParaUpar).then(
                                    (url) => {
                                        url.ref.getDownloadURL().then(function (downloadURL) {
                                            firebase.database().ref(`${chaveUnica}/usuarios/${keyAtendente}`).update({
                                                nome: nomeAtendente, nomePesquisa: nomeAtendentePesquisa, alterado: alterado, email: emailAtendente, permissaoAdministrador, permissaoAlterarCadastrarCliente, permissaoAlterarCadastrarAtendente, permissaoAlterarCadastrarSetores,
                                                permissaoAlterarCadastrarAvisos, permissaoAcessoRelatorios, contaPrincipal, StringAdmin,
                                                urlImagemNuvem: downloadURL,
                                                permissaoCancelarAtendimentoNovoCadastro: permissaoCancelarAtendimento
                                            }).then(
                                                () => {
                                                    setTimeout(() => {
                                                        removeFirebaseCachedData(`${chaveUnica}/usuarios`, keyAtendente);
                                                        ativaDesativaLoadingBotaoAlterarAtendente(dispatch, false);
                                                        // blob.close();
                                                        toastr.success('SUCESSO', 'Atendente alterado com sucesso!');
                                                    }, 700);

                                                }
                                            ).catch((error) => {
                                                ativaDesativaLoadingBotaoAlterarAtendente(dispatch, false);
                                                //error.code
                                                //error.message
                                                switch (error.code) {
                                                    case 'auth/invalid-email':
                                                        toastr.error('Error', 'Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br');
                                                        break;
                                                    case 'auth/email-already-in-use':
                                                        toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                                                        break;
                                                    case 'auth/network-request-failed':
                                                        toastr.error('Error', 'Sem conexão com a internet');
                                                        break;
                                                    default:
                                                        toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                                                        break;
                                                }
                                            })
                                        })
                                    }).catch((error) => {
                                        ativaDesativaLoadingBotaoAlterarAtendente(dispatch, false);
                                        //error.code
                                        //error.message
                                        switch (error.code) {
                                            case 'auth/invalid-email':
                                                toastr.error('Error', 'Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br');
                                                break;
                                            case 'auth/email-already-in-use':
                                                toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                                                break;
                                            case 'auth/network-request-failed':
                                                toastr.error('Error', 'Sem conexão com a internet');
                                                break;
                                            default:
                                                toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                                                break;
                                        }
                                    })
                                /*    }).catch((error) => {
                                       //error.code
                                       //error.message
                                       switch (error.code) {
                                           case 'auth/invalid-email':
                                               toastr.error('Error', 'Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br');
                                               break;
                                           case 'auth/email-already-in-use':
                                               toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                                               break;
                                           case 'auth/network-request-failed':
                                               toastr.error('Error', 'Sem conexão com a internet');
                                               break;
                                           default:
                                               toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                                               break;
                                       }
                                   }) */
                            }
                            /*    }).catch((error) => {
                                   //error.code
                                   //error.message
                                   
                                   switch (error.code) {
                                       case 'auth/invalid-email':
                                           toastr.error('Error', 'Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br');
                                           break;
                                       case 'auth/email-already-in-use':
                                           toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                                           break;
                                       case 'auth/network-request-failed':
                                           toastr.error('Error', 'Sem conexão com a internet');
                                           break;
                                       case 'storage/object-not-found':
                                           toastr.error('Error', 'Nome inválido, utilize letras!');
                                           break;
                                       default:
                                           toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                                           break;
                                   }
                               }) */
                            /*  }).catch((error) => {
                                 ativaDesativaLoadingBotaoAlterarAtendente(dispatch, false);
                                 //error.code
                                 //error.message
                                 switch (error.code) {
                                     case 'auth/invalid-email':
                                         toastr.error('Error', 'Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br');
                                         break;
                                     case 'auth/email-already-in-use':
                                         toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                                         break;
                                     case 'auth/network-request-failed':
                                         toastr.error('Error', 'Sem conexão com a internet');
                                         break;
                                     default:
                                         toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                                         break;
                                 }
                             }) */
                        }).catch((error) => {
                            ativaDesativaLoadingBotaoAlterarAtendente(dispatch, false);
                            //error.code
                            //error.message
                            if (error.response) {


                                switch (error.response.data.code) {
                                    case 'auth/email-already-exists':
                                        toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                                        break;
                                    case 'auth/invalid-email':
                                        toastr.error('Error', 'Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br');
                                        break;
                                    case 'auth/email-already-in-use':
                                        toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                                        break;
                                    case 'auth/network-request-failed':
                                        toastr.error('Error', 'Sem conexão com a internet');
                                        break;
                                    default:
                                        toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                                        break;
                                }
                            } else {
                                toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                            }
                        })
                    }
                    //altero tudo
                    else {
                        // 
                        /*    secondaryApp.auth().signInWithEmailAndPassword(emailBanco, senhaBanco).then(
                               (userCredential) => {
                                   userCredential.updateEmail(emailAtendente).then(
                                       () => {
                                           // secondaryApp.auth().signOut();
                                           secondaryApp.auth().signInWithEmailAndPassword(emailAtendente, senhaBanco).then(
                                               (user2) => {
                                                   user2.updatePassword(senhaAtendente).then(
                                                       () => { */
                        /* const primeiraLetra = nomeAtendente.substring(0, 1).trim().toLowerCase();
                        firebase.storage().ref(`letras/${primeiraLetra}.png`).getDownloadURL().then((url) => { */
                        const options = {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json; charset=utf-8',
                                'Accept': 'application/json',
                            },

                            data: { email: emailAtendente, senha: senhaAtendente, rota: 'alteraremailesenha', uid: uidAtendente },
                            url: 'crudUserReactNative',
                        };
                        api(options).then((response) => {


                            if (uriHTTP == 1) {
                                // 
                                firebase.database().ref(`${chaveUnica}/usuarios/${keyAtendente}`).update({
                                    nome: nomeAtendente, nomePesquisa: nomeAtendentePesquisa, alterado: alterado, email: emailAtendente, senha: senhaAtendente, permissaoAdministrador, permissaoAlterarCadastrarCliente, permissaoAlterarCadastrarAtendente, permissaoAlterarCadastrarSetores,
                                    permissaoAlterarCadastrarAvisos, permissaoAcessoRelatorios, contaPrincipal, StringAdmin,
                                    urlImagemNuvem: verificaSeUriImagemEhImagemUpadaOuNao == true ? uriImagemAtendente : "",
                                    permissaoCancelarAtendimentoNovoCadastro: permissaoCancelarAtendimento
                                }).then(
                                    () => {
                                        setTimeout(() => {
                                            removeFirebaseCachedData(`${chaveUnica}/usuarios`, keyAtendente);
                                            ativaDesativaLoadingBotaoAlterarAtendente(dispatch, false);
                                            toastr.success('SUCESSO', 'Atendente alterado com sucesso!');
                                        }, 700);

                                    }
                                ).catch((error) => {
                                    ativaDesativaLoadingBotaoAlterarAtendente(dispatch, false);
                                    // 
                                    //error.code
                                    //error.message
                                    switch (error.code) {
                                        case 'auth/invalid-email':
                                            toastr.error('Error', 'Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br');
                                            break;
                                        case 'auth/email-already-in-use':
                                            toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                                            break;
                                        case 'auth/network-request-failed':
                                            toastr.error('Error', 'Sem conexão com a internet');
                                            break;
                                        default:
                                            toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                                            break;
                                    }
                                })
                            }
                            else {
                                //  
                                let uri = uriImagemAtendente.replace('file://', '');
                                let caminhoPadraoImagem = firebase.storage().ref(`${chaveUnica}/${keyAtendente}/imagem.jpg`);
                                let mime = 'image/jpeg';
                                /*  RNFetchBlob.fs.readFile(uri, 'base64').then(
                                     (data) => {
                                         return RNFetchBlob.polyfill.Blob.build(data, { type: mime + ';BASE64' });
                                     }).then(
                                         (blob) => { */
                                caminhoPadraoImagem.put(arquivoCompletoParaUpar).then(
                                    (url) => {
                                        url.ref.getDownloadURL().then(function (downloadURL) {
                                            firebase.database().ref(`${chaveUnica}/usuarios/${keyAtendente}`).update({
                                                nome: nomeAtendente, nomePesquisa: nomeAtendentePesquisa, alterado: alterado, email: emailAtendente, senha: senhaAtendente,
                                                permissaoAdministrador, permissaoAlterarCadastrarCliente, permissaoAlterarCadastrarAtendente, permissaoAlterarCadastrarSetores,
                                                permissaoAlterarCadastrarAvisos, permissaoAcessoRelatorios, contaPrincipal, StringAdmin,
                                                urlImagemNuvem: downloadURL,
                                                permissaoCancelarAtendimentoNovoCadastro: permissaoCancelarAtendimento
                                            }).then(
                                                () => {
                                                    setTimeout(() => {
                                                        removeFirebaseCachedData(`${chaveUnica}/usuarios`, keyAtendente);
                                                        toastr.success('SUCESSO', 'Atendente alterado com sucesso!');
                                                        ativaDesativaLoadingBotaoAlterarAtendente(dispatch, false);
                                                        // blob.close();
                                                    }, 700);

                                                }
                                            ).catch((error) => {
                                                ativaDesativaLoadingBotaoAlterarAtendente(dispatch, false);
                                                //  
                                                //error.code
                                                //error.message
                                                switch (error.code) {
                                                    case 'auth/invalid-email':
                                                        toastr.error('Error', 'Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br');
                                                        break;
                                                    case 'auth/email-already-in-use':
                                                        toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                                                        break;
                                                    case 'auth/network-request-failed':
                                                        toastr.error('Error', 'Sem conexão com a internet');
                                                        break;
                                                    default:
                                                        toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                                                        break;
                                                }
                                            })
                                        })
                                    }).catch((error) => {
                                        ativaDesativaLoadingBotaoAlterarAtendente(dispatch, false);
                                        //  
                                        //error.code
                                        //error.message
                                        switch (error.code) {
                                            case 'auth/invalid-email':
                                                toastr.error('Error', 'Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br');
                                                break;
                                            case 'auth/email-already-in-use':
                                                toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                                                break;
                                            case 'auth/network-request-failed':
                                                toastr.error('Error', 'Sem conexão com a internet');
                                                break;
                                            default:
                                                toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                                                break;
                                        }
                                    })
                                /*  }).catch((error) => {
                                     
                                     //error.code
                                     //error.message
                                     switch (error.code) {
                                         case 'auth/invalid-email':
                                             toastr.error('Error', 'Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br');
                                             break;
                                         case 'auth/email-already-in-use':
                                             toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                                             break;
                                         case 'auth/network-request-failed':
                                             toastr.error('Error', 'Sem conexão com a internet');
                                             break;
                                         default:
                                             toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                                             break;
                                     }
                                 }) */
                            }
                            /*  }).catch((error) => {
                                 
                                 //error.code
                                 //error.message
                                 
                                 switch (error.code) {
                                     case 'auth/invalid-email':
                                         toastr.error('Error', 'Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br');
                                         break;
                                     case 'auth/email-already-in-use':
                                         toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                                         break;
                                     case 'auth/network-request-failed':
                                         toastr.error('Error', 'Sem conexão com a internet');
                                         break;
                                     case 'storage/object-not-found':
                                         toastr.error('Error', 'Nome inválido, utilize letras!');
                                         break;
                                     default:
                                         toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                                         break;
                                 }
                             }) */
                            /*  }).catch((error) => {
                                 ativaDesativaLoadingBotaoAlterarAtendente(dispatch, false);
                                 //  
                                 //error.code
                                 //error.message
                                 switch (error.code) {
                                     case 'auth/invalid-email':
                                         toastr.error('Error', 'Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br');
                                         break;
                                     case 'auth/email-already-in-use':
                                         toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                                         break;
                                     case 'auth/network-request-failed':
                                         toastr.error('Error', 'Sem conexão com a internet');
                                         break;
                                     default:
                                         toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                                         break;
                                 }
                             })
                     }).catch((error) => {
                         ativaDesativaLoadingBotaoAlterarAtendente(dispatch, false);
                         //  
                         //error.code
                         //error.message
                         switch (error.code) {
                             case 'auth/invalid-email':
                                 toastr.error('Error', 'Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br');
                                 break;
                             case 'auth/email-already-in-use':
                                 toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                                 break;
                             case 'auth/network-request-failed':
                                 toastr.error('Error', 'Sem conexão com a internet');
                                 break;
                             default:
                                 toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                                 break;
                         }
                     })
             }).catch((error) => {
                 ativaDesativaLoadingBotaoAlterarAtendente(dispatch, false);
                 // 
                 //error.code
                 //error.message
                 switch (error.code) {
                     case 'auth/invalid-email':
                         toastr.error('Error', 'Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br');
                         break;
                     case 'auth/email-already-in-use':
                         toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                         break;
                     case 'auth/network-request-failed':
                         toastr.error('Error', 'Sem conexão com a internet');
                         break;
                     default:
                         toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                         break;
                 }
             }) */
                        }).catch((error) => {
                            ativaDesativaLoadingBotaoAlterarAtendente(dispatch, false);
                            //  
                            //error.code
                            //error.message
                            if (error.response) {


                                switch (error.response.data.code) {
                                    case 'auth/email-already-exists':
                                        toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                                        break;
                                    case 'auth/invalid-email':
                                        toastr.error('Error', 'Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br');
                                        break;
                                    case 'auth/email-already-in-use':
                                        toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                                        break;
                                    case 'auth/network-request-failed':
                                        toastr.error('Error', 'Sem conexão com a internet');
                                        break;
                                    default:
                                        toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                                        break;
                                }
                            } else {
                                toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                            }
                        })
                    }

                })

            });
        }


    }
}
export const consultaAtendentecomAtendente = (keyAtendente) => {
    return dispatch => {
        ativaDesativaLoadingFimDaPaginaTelaConsultaAtendente(dispatch, false);
        ativaDesativaLoadingTelaConsultaAtendente(dispatch, true);
        AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            firebase.database().ref(`${chaveUnica}/usuarios/${keyAtendente}`)
                .on("value", snapshot => {

                    const dadoscliente = [];

                    dadoscliente.push({
                        key: snapshot.key,
                        nome: snapshot.val().nome,
                        // empresa: snapshot.val().empresa,
                        email: snapshot.val().email,
                        // permissaoAPP: snapshot.val().permissaoAPP,
                        senha: snapshot.val().senha,
                        alterado: snapshot.val().alterado,
                        permissaoCancelarAtendimento: (snapshot.val().permissaoCancelarAtendimentoNovoCadastro == undefined) ? true : snapshot.val().permissaoCancelarAtendimentoNovoCadastro,
                        permissaoAcessoRelatorios: (snapshot.val().permissaoAcessoRelatorios == undefined) ? true : snapshot.val().permissaoAcessoRelatorios,
                        permissaoAdministrador: (snapshot.val().permissaoAdministrador == undefined) ? true : snapshot.val().permissaoAdministrador,
                        permissaoAlterarCadastrarAtendente: (snapshot.val().permissaoAlterarCadastrarAtendente == undefined) ? true : snapshot.val().permissaoAlterarCadastrarAtendente,
                        permissaoAlterarCadastrarAvisos: (snapshot.val().permissaoAlterarCadastrarAvisos == undefined) ? true : snapshot.val().permissaoAlterarCadastrarAvisos,
                        permissaoAlterarCadastrarCliente: (snapshot.val().permissaoAlterarCadastrarCliente == undefined) ? true : snapshot.val().permissaoAlterarCadastrarCliente,
                        permissaoAlterarCadastrarSetores: (snapshot.val().permissaoAlterarCadastrarSetores == undefined) ? true : snapshot.val().permissaoAlterarCadastrarSetores,
                        contaPrincipal: (snapshot.val().contaPrincipal == undefined) ? false : snapshot.val().contaPrincipal,
                        StringAdmin: (snapshot.val().StringAdmin == undefined) ? 'Admin' : snapshot.val().StringAdmin,
                        urlImagemNuvem: snapshot.val().urlImagemNuvem,
                        chaveUnica: chaveUnica
                    });

                    enviaDadosParaAtendenteReducer(dispatch, dadoscliente);
                    ativaDesativaLoadingTelaConsultaAtendente(dispatch, false);
                })
        })


    }
}
export const listaAtendentesTelaConsultaAtendente = (tela) => {
    return dispatch => {
    };
};
export const listaAtendentesTelaConsultaAtendenteAntesRemoved = (tela) => {
    return dispatch => {
        ativaDesativaLoadingSectionedAtendente(dispatch, true);
        ativaDesativaLoadingAtendenteRelatorioDetalhado(dispatch, true);
        ativaDesativaLoadingEnviaAtendenteTelaAvaliacaoTodas(dispatch, true);
        ativaDesativaLoadingCarregarAtendenteTelaNovoAviso(dispatch, true);
        ativaLoadingCarregarAtendenteTelaNovoChamado(dispatch);

        let primeiraVerificacaoExecucao = false;
        let children2 = [];
        AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            firebase
                .database()
                .ref(`${chaveUnica}/usuarios`)
                .orderByKey()
                .limitToLast(1)
                .on("child_added", snapshot => {



                    if (snapshot.val() == null) {
                        primeiraVerificacaoExecucao = true
                        const arrayUser = [];
                        enviaAtendentesParaSectioned(dispatch, arrayUser);
                        /*      enviaAtendenteRelatorioDetalhado(dispatch, arrayUser);
                             enviaAtendenteParaReducerTodasAvaliacoes(dispatch, arrayUser);
                             enviaAtendentesParaSectionedRelatorioAtendente(dispatch, arrayUser);
                             enviaAtendenteParaReducerNovoAviso(dispatch, arrayUser);
                             enviaAtendenteParaReducer(dispatch, arrayUser);
      */
                        ativaDesativaLoadingSectionedAtendente(dispatch, false);
                        ativaDesativaLoadingAtendenteRelatorioDetalhado(dispatch, false);
                        ativaDesativaLoadingEnviaAtendenteTelaAvaliacaoTodas(dispatch, false);
                        ativaDesativaLoadingCarregarAtendenteTelaNovoAviso(dispatch, false);
                        desativaLoadingCarregarAtendenteTelaNovoChamado(dispatch);
                    } else {
                        let objectModif = { [snapshot.key]: snapshot.val() }
                        // snapshot.val() = [snapshot.key] =snapshot.val();


                        //  snapshot.val() = objectModif;
                        let arrayOfKeys = Object.keys(objectModif).sort().reverse();
                        let results = arrayOfKeys.map(key => objectModif[key]);
                        // storing reference
                        let referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];




                        let aux = 0;
                        if (tela == 'avisos') {
                            children2.push({ value: "TodosPadrao", name: "TODOS" });
                        }
                        results.forEach(childItem => {
                            if (tela == 'relatorio') {
                                if (childItem.excluido == 1 || childItem.clienteBoqueadoDevidoLimiteExcedido == 1 || childItem.email == undefined) {
                                    // 
                                } else if (children2.filter(e => e.value === referenceToOldestKey).length > 0) {

                                } else {
                                    children2.push({
                                        value: arrayOfKeys[aux],
                                        name: childItem.nome
                                    });
                                }
                            } else {
                                if (childItem.excluido == 1 || childItem.email == undefined) {
                                    //   
                                } else if (children2.filter(e => e.value === referenceToOldestKey).length > 0) {

                                } else {
                                    children2.push({
                                        value: arrayOfKeys[aux],
                                        name: childItem.nome
                                    });
                                }
                            }
                            aux++
                        });


                        let ultimoUser = children2.length > 0 ? children2[children2.length - 1].value : '';


                        if (primeiraVerificacaoExecucao === true) {
                            const items = [{
                                name: 'ATENDENTES',
                                type: 'group', items: children2
                            }];
                            enviaAtendentesParaSectioned(dispatch, items);
                            enviaUsuariosTelaAcoesAtendimento(dispatch, children2);

                            /* enviaAtendenteRelatorioDetalhado(dispatch, items);
                            enviaAtendenteParaReducerTodasAvaliacoes(dispatch, items);
                            enviaAtendentesParaSectionedRelatorioAtendente(dispatch, items);
                            enviaAtendenteParaReducerNovoAviso(dispatch, items);
                            enviaAtendenteParaReducer(dispatch, items); */

                            ativaDesativaLoadingSectionedAtendente(dispatch, false);
                            ativaDesativaLoadingAtendenteRelatorioDetalhado(dispatch, false);
                            ativaDesativaLoadingEnviaAtendenteTelaAvaliacaoTodas(dispatch, false);
                            ativaDesativaLoadingCarregarAtendenteTelaNovoAviso(dispatch, false);
                            desativaLoadingCarregarAtendenteTelaNovoChamado(dispatch);
                        } else {
                            firebase
                                .database()
                                .ref(`${chaveUnica}/usuarios`)
                                .orderByKey()
                                .endAt(referenceToOldestKey)
                                .once("value")
                                .then(snapshot => {
                                    if (snapshot.val() == null) {
                                        primeiraVerificacaoExecucao = true;
                                        let arrayOrdernado = children2.sort((a, b) => a.name.localeCompare(b.name))
                                        const items = [{
                                            name: 'ATENDENTES',
                                            type: 'group', items: arrayOrdernado
                                        }];
                                        enviaAtendentesParaSectioned(dispatch, items);
                                        /*   enviaAtendenteRelatorioDetalhado(dispatch, items);
                                          enviaAtendenteParaReducerTodasAvaliacoes(dispatch, items);
                                          enviaAtendenteParaReducerNovoAviso(dispatch, items);
                                          enviaAtendentesParaSectionedRelatorioAtendente(dispatch, items); */
                                        enviaUsuariosTelaAcoesAtendimento(dispatch, arrayOrdernado);
                                        /*      enviaAtendenteParaReducer(dispatch, items); */

                                        ativaDesativaLoadingSectionedAtendente(dispatch, false);
                                        ativaDesativaLoadingAtendenteRelatorioDetalhado(dispatch, false);
                                        ativaDesativaLoadingEnviaAtendenteTelaAvaliacaoTodas(dispatch, false);
                                        desativaLoadingCarregarAtendenteTelaNovoChamado(dispatch);
                                    } else {
                                        primeiraVerificacaoExecucao = true


                                        let arrayOfKeys = Object.keys(snapshot.val())
                                            .sort()
                                            .reverse()
                                            .slice(1);
                                        // transforming to array
                                        let results = arrayOfKeys.map(key => snapshot.val()[key]);
                                        // storing reference
                                        let referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
                                        /*  let newArray = [];
                                         newArray = [...children2];
                                          */
                                        let aux = 0;
                                        results.forEach(childItem => {
                                            /* 
                                             */
                                            if (tela == 'relatorio') {
                                                if (childItem.excluido == 1 || childItem.clienteBoqueadoDevidoLimiteExcedido == 1 || childItem.email == undefined) {
                                                    // 
                                                } else {
                                                    children2.push({
                                                        value: arrayOfKeys[aux],
                                                        name: childItem.nome
                                                    });
                                                }
                                            } else {
                                                if (childItem.excluido == 1 || childItem.email == undefined) {

                                                } else {
                                                    children2.push({
                                                        value: arrayOfKeys[aux],
                                                        name: childItem.nome
                                                    });
                                                }
                                            }
                                            aux++
                                        });

                                        let arrayOrdernado = children2.sort((a, b) => a.name.localeCompare(b.name))
                                        const items = [{
                                            name: 'ATENDENTES',
                                            type: 'group', items: arrayOrdernado
                                        }];
                                        enviaAtendentesParaSectioned(dispatch, items);
                                        /*   enviaAtendenteRelatorioDetalhado(dispatch, items);
                                          enviaAtendenteParaReducerTodasAvaliacoes(dispatch, items);
                                          enviaAtendentesParaSectionedRelatorioAtendente(dispatch, items);
                                          enviaAtendenteParaReducerNovoAviso(dispatch, items); */
                                        enviaUsuariosTelaAcoesAtendimento(dispatch, arrayOrdernado);
                                        /*   enviaAtendenteParaReducer(dispatch, items); */

                                        ativaDesativaLoadingSectionedAtendente(dispatch, false);
                                        ativaDesativaLoadingAtendenteRelatorioDetalhado(dispatch, false);
                                        ativaDesativaLoadingEnviaAtendenteTelaAvaliacaoTodas(dispatch, false);
                                        ativaDesativaLoadingCarregarAtendenteTelaNovoAviso(dispatch, false);
                                        desativaLoadingCarregarAtendenteTelaNovoChamado(dispatch);
                                    }
                                })
                        }
                    }
                });
        });
    };
};

export const consultaAtendente2 = (valor, referenciaUltimaKey, atendentesAnteriores) => {
    return dispatch => {


        AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            if (valor == 0) { // if initial fetch
                ativaDesativaLoadingTelaConsultaAtendente(dispatch, true);
                ativaDesativaLoadingRefreshControlAtendente(dispatch, true);
                firebase
                    .database()
                    .ref(`${chaveUnica}/usuarios/`)
                    .orderByChild('excluido')
                    .equalTo(null)
                    .limitToFirst(10)
                    .on("value", snapshot => {

                        if (snapshot.val() == null) {
                            const arrayUser = [];
                            enviaDadosParaAtendenteReducer(dispatch, arrayUser);
                            ativaDesativaLoadingTelaConsultaAtendente(dispatch, false);
                            ativaDesativaLoadingRefreshControlAtendente(dispatch, false);
                        } else {
                            let arrayOfKeys = Object.keys(snapshot.val()).sort()
                            //.reverse();
                            // transforming to array
                            let results = arrayOfKeys
                                .map((key) => snapshot.val()[key]);
                            // storing reference
                            let referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
                            const dadosAtendente = [];
                            snapshot.forEach(childItem => {
                                if (childItem.val().email == undefined) {

                                } else {
                                    dadosAtendente.push({
                                        key: childItem.key,
                                        nome: childItem.val().nome,
                                        //empresa: childItem.val().empresa,
                                        email: childItem.val().email,
                                        //permissaoAPP: childItem.val().permissaoAPP,
                                        senha: childItem.val().senha,
                                        alterado: childItem.val().alterado,
                                        uid: childItem.val().uid,
                                        permissaoAcessoRelatorios: (childItem.val().permissaoAcessoRelatorios == undefined) ? true : childItem.val().permissaoAcessoRelatorios,
                                        permissaoAdministrador: (childItem.val().permissaoAdministrador == undefined) ? true : childItem.val().permissaoAdministrador,
                                        permissaoAlterarCadastrarAtendente: (childItem.val().permissaoAlterarCadastrarAtendente == undefined) ? true : childItem.val().permissaoAlterarCadastrarAtendente,
                                        permissaoAlterarCadastrarAvisos: (childItem.val().permissaoAlterarCadastrarAvisos == undefined) ? true : childItem.val().permissaoAlterarCadastrarAvisos,
                                        permissaoAlterarCadastrarCliente: (childItem.val().permissaoAlterarCadastrarCliente == undefined) ? true : childItem.val().permissaoAlterarCadastrarCliente,
                                        permissaoAlterarCadastrarSetores: (childItem.val().permissaoAlterarCadastrarSetores == undefined) ? true : childItem.val().permissaoAlterarCadastrarSetores,
                                        contaPrincipal: (childItem.val().contaPrincipal == undefined) ? false : childItem.val().contaPrincipal,
                                        StringAdmin: (childItem.val().StringAdmin == undefined) ? 'Admin' : childItem.val().StringAdmin,
                                        urlImagemNuvem: childItem.val().urlImagemNuvem,
                                        clienteBoqueadoDevidoLimiteExcedido: childItem.val().clienteBoqueadoDevidoLimiteExcedido,

                                        permissaoCancelarAtendimento: (childItem.val().permissaoCancelarAtendimentoNovoCadastro == undefined) ? true : childItem.val().permissaoCancelarAtendimentoNovoCadastro,
                                        chaveUnica: chaveUnica
                                    });
                                }
                            });
                            //  
                            //
                            enviaDadosParaAtendenteReducer(dispatch, dadosAtendente);
                            enviaUltimaReferenciaKeyAtendente(dispatch, referenceToOldestKey);
                            ativaDesativaLoadingTelaConsultaAtendente(dispatch, false);
                            ativaDesativaLoadingRefreshControlAtendente(dispatch, false);
                            ativaDesativaLoadingFimDaPaginaTelaConsultaAtendente(dispatch, true);
                        }
                    });
            }
            else {
                if (referenciaUltimaKey != undefined && referenciaUltimaKey != '') {
                    // ativaDesativaLoadingFimDaPaginaTelaConsultaAtendente(dispatch, true);
                    firebase.database().ref(`${chaveUnica}/usuarios/`)
                        .orderByChild("excluido")
                        .startAt(null, referenciaUltimaKey)
                        .endAt(null)
                        .limitToFirst(11)
                        .on("value", snapshot => {
                            if (snapshot.val() == null) {
                                ativaDesativaLoadingFimDaPaginaTelaConsultaAtendente(dispatch, false);
                            } else {

                                // changing to reverse chronological order (latest first)
                                let arrayOfKeys = Object.keys(snapshot.val())
                                    .sort()
                                    // .reverse()
                                    .slice(1);
                                // transforming to array
                                let results = arrayOfKeys
                                    .map((key) => snapshot.val()[key]);
                                // storing reference
                                let referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
                                const dadosAtendente = [];

                                for (let i = 0; i < results.length; i++) {
                                    //  
                                    if (results[i].email == undefined || results[i].excluido === 1) {

                                    } else {
                                        dadosAtendente.push({
                                            key: arrayOfKeys[i],
                                            nome: results[i].nome,
                                            //   empresa: results[i].empresa,
                                            email: results[i].email,
                                            //    permissaoAPP: results[i].permissaoAPP,
                                            senha: results[i].senha,
                                            alterado: results[i].alterado,

                                            permissaoCancelarAtendimento: (results[i].permissaoCancelarAtendimentoNovoCadastro == undefined) ? true : results[i].permissaoCancelarAtendimentoNovoCadastro,
                                            permissaoAcessoRelatorios: (results[i].permissaoAcessoRelatorios == undefined) ? true : results[i].permissaoAcessoRelatorios,
                                            permissaoAdministrador: (results[i].permissaoAdministrador == undefined) ? true : results[i].permissaoAdministrador,
                                            permissaoAlterarCadastrarAtendente: (results[i].permissaoAlterarCadastrarAtendente == undefined) ? true : results[i].permissaoAlterarCadastrarAtendente,
                                            permissaoAlterarCadastrarAvisos: (results[i].permissaoAlterarCadastrarAvisos == undefined) ? true : results[i].permissaoAlterarCadastrarAvisos,
                                            permissaoAlterarCadastrarCliente: (results[i].permissaoAlterarCadastrarCliente == undefined) ? true : results[i].permissaoAlterarCadastrarCliente,
                                            permissaoAlterarCadastrarSetores: (results[i].permissaoAlterarCadastrarSetores == undefined) ? true : results[i].permissaoAlterarCadastrarSetores,
                                            contaPrincipal: (results[i].contaPrincipal == undefined) ? false : results[i].contaPrincipal,
                                            StringAdmin: (results[i].StringAdmin == undefined) ? 'Admin' : results[i].StringAdmin,
                                            urlImagemNuvem: results[i].urlImagemNuvem,
                                            clienteBoqueadoDevidoLimiteExcedido: results[i].clienteBoqueadoDevidoLimiteExcedido,
                                            chaveUnica: chaveUnica
                                        })
                                    }

                                }

                                //  
                                // 
                                enviaDadosParaAtendenteReducer(dispatch, atendentesAnteriores.concat(dadosAtendente));
                                enviaUltimaReferenciaKeyAtendente(dispatch, referenceToOldestKey);
                                // ativaDesativaLoadingFimDaPaginaTelaConsultaAtendente(dispatch, false);
                            }


                        })
                } else {
                    ativaDesativaLoadingFimDaPaginaTelaConsultaAtendente(dispatch, false);
                }
            }
        })


    }
}
const ativaLoadingCarregarAtendenteTelaNovoChamado = dispatch => {
    dispatch({
        type: ATIVALOADINGCARREGARATENDENTETELANOVOCHAMADO,
        payload: true
    });
};

const desativaLoadingCarregarAtendenteTelaNovoChamado = dispatch => {
    dispatch({
        type: DESATIVALOADINGCARREGARATENDENTETELANOVOCHAMADO,
        payload: false
    });
};
const enviaAtendenteParaReducer = (dispatch, arrayAtendente) => {
    dispatch({
        type: ENVIAATENDENTEPARAREDUCER,
        payload: arrayAtendente
    });
};
const enviaUsuariosTelaAcoesAtendimento = (dispatch, arrayUsuario) => {
    dispatch({
        type: ENVIAUSUARIOSTELAACOESATENDIMENTO,
        payload: arrayUsuario
    });
};
const enviaAtendenteParaReducerNovoAviso = (dispatch, arrayAtendente) => {
    dispatch({
        type: ENVIAATENDENTEPARAREDUCERNOVOAVISO,
        payload: arrayAtendente
    });
};
const ativaDesativaLoadingCarregarAtendenteTelaNovoAviso = (
    dispatch,
    valor
) => {
    dispatch({
        type: ATIVADESATIVALOADINGCARREGARATENDENTETELANOVOAVISO,
        payload: valor
    });
};
export const modificaVisibleModalNovoAtendente = (value) => {
    return {
        type: MODIFICAVISIBLEMODALNOVOATENDENTE,
        payload: value
    }
};

export const alteraSwitchPermissaoAlterarAdministrador = (value) => {
    return {
        type: ALTERASWITCHPERMISSAOALTERARADMINISTRADOR,
        payload: value
    }
};

export const alteraSwitchPermissaoAlterarCliente = (value) => {
    return {
        type: ALTERASWITCHPERMISSAOALTERARCLIENTE,
        payload: value
    }
};
export const alteraSwitchPermissaoAlterarAtendente = (value) => {
    return {
        type: ALTERASWITCHPERMISSAOALTERARATENDENTE,
        payload: value
    }
};
export const alteraSwitchPermissaoAlterarSetores = (value) => {
    return {
        type: ALTERASWITCHPERMISSAOALTERARSETORES,
        payload: value
    }
};
export const alteraSwitchPermissaoAlterarAvisos = (value) => {
    return {
        type: ALTERASWITCHPERMISSAOALTERARAVISOS,
        payload: value
    }
};
export const alteraSwitchPermissaoAcessoRelatorios = (value) => {
    return {
        type: ALTERASWITCHPERMISSAOACESSORELATORIOS,
        payload: value
    }
};

export const alteraSwitchPermissaoCancelarAtendimento = (value) => {
    return {
        type: ALTERASWITCHPERMISSAOCANCELARATENDIMENTO,
        payload: value
    }
};

const ativaDesativaLoadingConteudoExcluirAtendete = (dispatch, visible) => {
    dispatch({
        type: ATIVADESATIVALOADINGCONTEUDOEXCLUIRATENDENTE,
        payload: visible
    });
};
const zeraMensagemErrorTelaCadastroAtendente2 = (dispatch) => {
    dispatch({
        type: ZERAMENSAGEMERRORTELACADASTROATENDENTE2,
        payload: 'null'
    });
}
const mudaVisibilidadeModalCadastraAtendenteVersaoPremiumLocal = (dispatch, visible) => {
    dispatch({
        type: MUDAVISIBILIDADEMODALCADASTRAATENDENTEVERSAOPREMIUM,
        payload: visible
    });
};
const mudaVisibilidadeModalExcluirAtendenteLocal = (dispatch, visible) => {
    dispatch({
        type: MUDAVISIBILIDADEMODALEXCLUIRATENDENTE,
        payload: visible
    });
}
const enviaAtendenteParaReducerTodasAvaliacoes = (dispatch, value) => {
    dispatch({
        type: ENVIAATENDENTEPARAREDUCERTODASAVALIACOES,
        payload: value
    });
};
export const mudaVisibilidadeModalExcluirAtendente = (visible) => {
    return {
        type: MUDAVISIBILIDADEMODALEXCLUIRATENDENTE,
        payload: visible
    }
}
const enviaAtendentesParaSectionedRelatorioAtendente = (dispatch, value) => {
    dispatch({
        type: ENVIATENDENTEPARASECTIONEDRELATORIOATENDENTE,
        payload: value
    });
};
const ativaDesativaLoadingEnviaAtendenteTelaAvaliacaoTodas = (dispatch, value) => {
    dispatch({
        type: ATIVADESATIVALOADINGENVIAATENDENTETELAAVALIACAOTODAS,
        payload: value
    });
};
export const mudaVisibilidadeModalCadastraAtendenteVersaoPremium = (visible) => {
    return {
        type: MUDAVISIBILIDADEMODALCADASTRAATENDENTEVERSAOPREMIUM,
        payload: visible
    }
}
const enviaAtendentesParaSectioned = (dispatch, arrayUser) => {
    dispatch({
        type: ENVIATENDENTESPARASECTIONED,
        payload: arrayUser
    });
}

const ativaDesativaLoadingRefreshControlAtendente = (dispatch, valor) => {
    dispatch({
        type: ATIVADESATIVALOADINGREFRESHCONTROLATENDENTE,
        payload: valor
    });
}

const ativaDesativaLoadingSectionedAtendente = (dispatch, valor) => {
    dispatch({
        type: ATIVADESATIVALOADINGSECTIONEDATENDENTE,
        payload: valor
    });
}
export const modificaAtendenteSelecionadoSectioned = (atendente) => {
    return {
        type: MODIFICAATENDENTESELECIONADOSECTIONED,
        payload: atendente
    }
}
export const modificaNomeAtendenteSelecionadoSectioned = (atendente) => {
    return {
        type: MODIFICANOMEATENDENTESELECIONADOSECTIONED,
        payload: atendente
    }
}

const enviaDadosParaAtendenteReducer = (dispatch, arrayUser) => {
    dispatch({
        type: ENVIADADOSPARAATENDENTEREDUCER,
        payload: arrayUser
    });
}
const ativaDesativaLoadingBotaoAlterarAtendente = (dispatch, value) => {
    dispatch({
        type: ATIVALOADINGBOTAOALTERARATENDENTE,
        payload: value
    });
}
const ativaDesativaLoadingTelaConsultaAtendente = (dispatch, valor) => {
    dispatch({
        type: ATIVADESATIVALOADINGTELACONSULTAATENDENTE,
        payload: valor
    });
}
const enviaUltimaReferenciaKeyAtendente = (dispatch, valor) => {
    dispatch({
        type: ENVIAULTIMAREFERENCIAKEYATENDENTE,
        payload: valor
    });
}
const ativaDesativaLoadingFimDaPaginaTelaConsultaAtendente = (dispatch, valor) => {
    dispatch({
        type: ATIVADESATIVALOADINGFIMDAPAGINATELACONSULTAATENDENTE,
        payload: valor
    });
}
export const zeraMensagemSemInternetAlteraAtendente = () => {
    return {
        type: SEMCONEXAOINTERNETALTERAATENDENTE,
        payload: ''
    }
}
export const zeraMensagemSemInternetCadastraAtendente = () => {
    return {
        type: SEMCONEXAOINTERNETCADASTRAATENDENTE,
        payload: ''
    }
}
export const zeraMensagemSemInternetConsultaAtendente = () => {
    return {
        type: SEMCONEXAOINTERNETCONSULTAATENDENTE,
        payload: ''
    }
}
export const zeraMensagemAlterarSucesso = () => {
    return {
        type: MENSAGEMALTERAATENDENTESUCESSO,
        payload: ''
    }
}
export const navegaTelaAlterarAtendente = (fotoAtendente, nome, email, senha, confirmaSenha, keyAtendente, permissaoAcessoRelatorios, permissaoAdministrador, permissaoAtendente,
    permissaoAvisos, permissaoCliente, permissaoSetor, contaPrincipal, StringAdmin, uid, permissaoCancelarAtendimento) => {

    return dispatch => {
        /*  console.log(
            fotoAtendente,
            permissaoAcessoRelatorios, permissaoAdministrador, permissaoAtendente,
            permissaoAvisos, permissaoCliente, permissaoSetor, contaPrincipal, StringAdmin,uid,permissaoCancelarAtendimento)  */

        dispatch({ type: ALTERAFOTOATENDENTETELAALTERAR, payload: fotoAtendente });
        dispatch({ type: ALTERANOMEATENDENTETELAALTERAR, payload: nome });
        dispatch({ type: ALTERAEMAILATENDENTETELAALTERAR, payload: email });
        dispatch({ type: ALTERASENHAATENDENTETELAALTERAR, payload: senha });
        dispatch({ type: ALTERACONFIRMASENHAATENDENTETELAALTERAR, payload: confirmaSenha });
        dispatch({ type: ALTERAKEYATENDENTETELAALTERAR, payload: keyAtendente });

        dispatch({ type: ALTERASWITCHPERMISSAOADMINISTRADORSOZINHO, payload: permissaoAdministrador });
        dispatch({ type: ALTERASWITCHPERMISSAOACESSORELATORIOS, payload: permissaoAcessoRelatorios });
        dispatch({ type: ALTERASWITCHPERMISSAOALTERARATENDENTE, payload: permissaoAtendente });
        dispatch({ type: ALTERASWITCHPERMISSAOALTERARCLIENTE, payload: permissaoCliente });
        dispatch({ type: ALTERASWITCHPERMISSAOALTERARAVISOS, payload: permissaoAvisos });
        dispatch({ type: ALTERASWITCHPERMISSAOALTERARSETORES, payload: permissaoSetor });
        dispatch({ type: ALTERAINFORMACAOCONTAPRINCIPALATENDENTE, payload: contaPrincipal });
        dispatch({ type: ALTERASWITCHPERMISSAOCANCELARATENDIMENTO, payload: permissaoCancelarAtendimento });

        dispatch({ type: ZERAMENSAGEMERRORAOALTERARATENDENTE, payload: true });
        // dispatch({ type: ENVIAUIDATENDENTETELAALTERARATENDENTE, payload: uid });

        //     dispatch(NavigationActions.navigate({ routeName: 'AlterandoAtendente' }));
    }
}
export const insereFotoAtendenteTelaAlterar = (uri) => {
    return {
        type: INSEREFOTOATENDENTETELAALTERARPICKER,
        payload: uri
    };
}


export const navegaTelaCadastraAtendente = () => {
    return dispatch => {
        dispatch({ type: ZERAMSGSUCESSOERRORATENDENTE, payload: 'zeraInformacoes' });
        //  dispatch(NavigationActions.navigate({ routeName: 'CadastroAtendentes' }));
    }
}
const zeraInformacoesCadastroAtendente = (dispatch) => {
    dispatch({
        type: ZERAINFORMACOESCADASTROATENDENTE,
        payload: 'zeraInformacoes'
    });
};
export const zeraInformacoesCadastroAtendenteExport = () => {
    return {
        type: ZERAINFORMACOESCADASTROATENDENTE,
        payload: 'zeraInformacoes'
    }
};
const mensagemCasdastraAtendenteError = (dispatch, error) => {
    dispatch({
        type: MENSAGEMCADASTRAATENDENTEERROR,
        payload: error
    });
};
const mensagemCasdastraAtendenteSucesso = (dispatch, sucesso) => {
    dispatch({
        type: MENSAGEMCADASTRAATENDENTESUCESSO,
        payload: sucesso
    });
};

const mensagemAlteraAtendenteSucesso = (dispatch, sucesso) => {
    dispatch({
        type: MENSAGEMALTERAATENDENTESUCESSO,
        payload: sucesso
    });
};
const mensagemAlteraAtendenteErro = (dispatch, erro) => {
    dispatch({
        type: MENSAGEMCALTERAATENDENTEERRO,
        payload: erro
    });
};

const ativaLoadingBotaoCadastrarAtendente = (dispatch) => {
    dispatch({
        type: ATIVALOADINGBOTAOCADASTRARATENDENTE,
        payload: true
    });
};
const desativaLoadingBotaoCadastrarAtendente = (dispatch) => {
    dispatch({
        type: DESATIVALOADINGBOTAOCADASTRARATENDENTE,
        payload: false
    });
};

const ativaDesativaLoadingAtendenteRelatorioDetalhado = (dispatch, value) => {
    dispatch({
        type: ATIVADESATIVALOADINGATENDENTERELATORIODETALHADO,
        payload: value
    });
};
const enviaAtendenteRelatorioDetalhado = (dispatch, value) => {
    dispatch({
        type: ENVIAATENDENTERELATORIODETALHADO,
        payload: value
    });
};

export const modificaValorDigitoConsultaAtendente = (digito) => {
    return {
        type: MODIFICAVALORDIGITOCONSULTAATENDENTE,
        payload: digito
    };
}
export const modificaFiltroClientesTelaAtendentes = (value) => {
    return {
        type: MODIFICAFILTROTELAATENDENTES,
        payload: value
    };
}

export const modificaPermissaoCancelarAtendimentoNovoCadastro = (value) => {
    return {
        type: MODIFICAPERMISSAOCANCELARATENDIMENTONOVOCADASTRO,
        payload: value
    };
}


export const insereFotoCadastroAtendente = (uri) => {
    return {
        type: INSEREFOTOCADASTROATENDENTE,
        payload: uri
    };
}

export const modificaNomeAtendente = (nomeAtendente) => {
    return {
        type: MODIFICANOMEATENDENTE,
        payload: nomeAtendente
    };
};
export const modificaEmailAcessoApp = (email) => {
    return {
        type: MODIFICAEMAILACESSOAPP,
        payload: email
    };
};
export const modificaSenhaAtendente = (senha) => {
    return {
        type: MODIFICASENHAATENDENTE,
        payload: senha
    };
};
export const modificaConfirmaSenhaAtendente = (confirmaSenha) => {
    return {
        type: MODIFICACONFIRMASENHAATENDENTE,
        payload: confirmaSenha
    };
};
const modificaArquivoCompletoAtendenteParaUparLocal = (dispatch, value) => {
    dispatch({
        type: MODIFICAARQUIVOCOMOPLETOATENDENTEPARAUPAR,
        payload: value
    });
};
export const modificaArquivoCompletoAtendenteParaUpar = (value) => {
    return {
        type: MODIFICAARQUIVOCOMOPLETOATENDENTEPARAUPAR,
        payload: value
    };
};
export const modificaArquivoCompletoAtendenteParaUparNovoCadastro = (value) => {
    return {
        type: MODIFICAARQUIVOCOMOPLETOATENDENTEPARAUPARNOVOCADASTRO,
        payload: value
    };
};

export const modificaNomeAtendenteNovoCadastro = (nomeAtendente) => {
    return {
        type: MODIFICANOMEATENDENTENOVOCADASTRO,
        payload: nomeAtendente
    };
};
export const modificaEmailAcessoAppNovoCadastro = (email) => {
    return {
        type: MODIFICAEMAILACESSOAPPNOVOCADASTRO,
        payload: email
    };
};
export const modificaSenhaAtendenteNovoCadastro = (senha) => {
    return {
        type: MODIFICASENHAATENDENTENOVOCADASTRO,
        payload: senha
    };
};
export const modificaConfirmaSenhaAtendenteNovoCadastro = (confirmaSenha) => {
    return {
        type: MODIFICACONFIRMASENHAATENDENTENOVOCADASTRO,
        payload: confirmaSenha
    };
};
export const alteraSwitchPermissaoAlterarAdministradorNovoCadastro = (value) => {
    return {
        type: ALTERASWITCHPERMISSAOALTERARADMINISTRADORNOVOCADASTRO,
        payload: value
    }
};

export const alteraSwitchPermissaoAlterarClienteNovoCadastro = (value) => {
    return {
        type: ALTERASWITCHPERMISSAOALTERARCLIENTENOVOCADASTRO,
        payload: value
    }
};
export const alteraSwitchPermissaoAlterarAtendenteNovoCadastro = (value) => {
    return {
        type: ALTERASWITCHPERMISSAOALTERARATENDENTENOVOCADASTRO,
        payload: value
    }
};
export const alteraSwitchPermissaoAlterarSetoresNovoCadastro = (value) => {
    return {
        type: ALTERASWITCHPERMISSAOALTERARSETORESNOVOCADASTRO,
        payload: value
    }
};
export const alteraSwitchPermissaoAlterarAvisosNovoCadastro = (value) => {
    return {
        type: ALTERASWITCHPERMISSAOALTERARAVISOSNOVOCADASTRO,
        payload: value
    }
};
export const alteraSwitchPermissaoAcessoRelatoriosNovoCadastro = (value) => {
    return {
        type: ALTERASWITCHPERMISSAOACESSORELATORIOSNOVOCADASTRO,
        payload: value
    }
};
function validEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
};
function isNumber(val) {
    return typeof val === "number"
}
function retornaDataAtualTimeStamp() {

    return new Date().getTime();
}
const AsyncStorage = {
    setItem: function (key, value) {
        return Promise.resolve().then(function () {
            localStorage.setItem(key, value);
        });
    },
    getItem: function (key) {
        return Promise.resolve().then(function () {
            return localStorage.getItem(key);
        });
    }
};