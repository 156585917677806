import styled, { keyframes } from 'styled-components';
import { shade, opacify, lighten } from 'polished';
import { NONAME } from 'dns';
const animacaoLoading = keyframes`
   0%{
        background-position: 0% 0%;
     }
     100%{
        background-position: -135% 0%;
     }
  }
`;
export const Title = styled.h1`
   font-size: 35px;
`;
export const Container = styled.div`
 display:flex;
   flex:1;
   height:calc(100vh - 65px);
   flex-direction:column;
   background:${props => props.backgroundDinamico};
`;
export const ContainerBox = styled.div`
 display:flex;
   flex-direction:row;
   height:110px;
   min-height:110px;
   max-height:110px;
   justify-content:space-around;
   align-items:center;
`;
export const Box = styled.div`
 display:flex;
   flex-direction:column;
   background:${props => props.backgroundDinamico};
   max-height:85px;
   min-height:85px;
   height:85px;
   width:150px;
   margin-top:10px;
   max-width:150px;
   justify-content:center;
   align-items:flex-start;
   padding-left:20px;
   border-radius:5px;
   -webkit-box-shadow: 0px 0px 2px 1px #000;
  -moz-box-shadow: 0px 0px 2px 1px #000;
  box-shadow: 0px 0px 2px 1px #000;
`;
export const TextStatus = styled.span`
   color:${props => props.colorDinamico};
   font-size:19px;
`;
export const TextResultado = styled.strong`
  color:${props => props.colorDinamico};
   font-size:22px;
`;



export const ContainerGrafico = styled.div`
 display:flex;
 flex-direction:row;
 justify-content:space-around;
 align-items:center;
`;
export const SubContainerGrafico = styled.div`
 display:flex;
 flex-direction:column;
 justify-content:center;
 background-color:${props => props.backgroundDinamico};
 align-items:center;
 height:300px;
 min-height:300px;
 width:45%;
 margin:10px;
 max-width:45%;
 -webkit-box-shadow: 0px 0px 4px 1px #000;
  -moz-box-shadow: 0px 0px 4px 1px #000;
  box-shadow: 0px 0px 4px 1px #000;
  border-radius:7px;
`;
export const ContainerAviso = styled.div`
  flex-direction:row;
 padding:10px;
  justify-content:flex-start;
  padding-left:10px;
  padding-right:10px;
  align-items:center;
  display:flex;
  background-color:#F9BF3B;
  margin:5px;
  border-radius:2px;
`;
export const TextAviso = styled.strong`
   color: #000;
   font-size:14px;
`;
export const TextConteudoAviso = styled.p`
   color: #000;
   font-size:14px;
`;


export const TextGrafico = styled.strong`
   font-size:12px;
   color:${props => props.colorDinamico};
`;



export const ContainerTextGrafico = styled.div`
height:20px;
min-height:20px;
margin-top:15px;
`;
export const SubContainerListaDeTarefas = styled.div`
 display:flex;
 flex-direction:column;
 justify-content:flex-start;
 background-color:${props => props.backgroundDinamico};
 align-items:flex-start;
 padding:15px;
 height:300px;
 min-height:300px;
 width:45%;
 margin:10px;
 max-width:45%;
 -webkit-box-shadow: 0px 0px 4px 1px #000;
  -moz-box-shadow: 0px 0px 4px 1px #000;
  box-shadow: 0px 0px 4px 1px #000;
  border-radius:7px;
`;
export const TextTarefas = styled.strong`
   font-size:15px;
   color:${props => props.colorDinamico};
`;

export const InputEmail = styled.input`
display:flex;
background-Color:transparent;
flex:1;
height:40px;
min-height:40px;
border:0;
color:#FFF;
&::placeholder{
   color:#a8a8B3
}
`;
export const SubContainerInputEmail = styled.div`
height:30px;
min-height:30px;
margin-top:5px;
border-bottom: 1px solid ${props => props.corBordaPadrao};
display:flex;
width:85%;
align-items:center;
&:focus-within{
   border-bottom: 1px solid ${props => props.corBordaInputEmail};
}
`;
export const SubContainerInputTarefa = styled.div`
height:30px;
min-height:30px;
margin-top:5px;
border-bottom: 0.5px solid ${props => props.corBordaPadrao};
display:flex;
width:85%;
align-items:center;

`;
export const DivContainerSelectDate = styled.div`
  flex-direction:row;
  justify-content:flex-start;
  padding-left:10px;
  padding-right:10px;
  align-items:center;
  display:flex;
  margin:5px;
  margin-left:20px;
  border-radius:2px;
`;


export const BotaoPesquisarChamadoPorNumero = styled.button`
display:flex;
justify-content:center;
align-items:center;
background:${props => props.backGroundDinamico};
width:34px;
border:0;
transition: background-color 0.2s;
height:34px;
min-height:34px;
margin-left:2px;
border-radius: 3px;
&:hover{
   background:${props => shade(0.2, props.backGroundDinamico)};
}
`;
export const DivTextPeriodo = styled.div`
 display:flex;
 flex-direction:row;
 margin-top:15px;
`;
export const TextPeriodo = styled.p`
font-size:14px;
font-weight:bold;
color:${props => props.corLetraDinamica};
`;
export const DivPeriodoBotao = styled.div`
display:flex;
flex-direction:row;
`;

export const BoxLoading = styled.div`
 display:flex;
   flex-direction:column;
/*    background-size:400% 400%; */
   max-height:85px;
   height:85px;
   min-height:85px;
   
   width:150px;
   max-width:150px;
   margin:10px;
   justify-content:space-around;
   align-items:center;
   border-radius:5px;
   -webkit-box-shadow: 0px 0px 2px 1px #000;
  -moz-box-shadow: 0px 0px 2px 1px #000;
  box-shadow: 0px 0px 2px 1px #000;
  background:${props => props.backgroundinamico};
/*   background:${props => `linear-gradient(to right, ${props.backgroundinamico} 10%, ${lighten(0.2, props.backgroundinamico)} 28%, ${props.backgroundinamico} 33%)`};
  animation:${animacaoLoading} 1.2s linear infinite;
  background-size: 400% 400%; */



`;

export const ParagrafoLoading = styled.p`
display:flex;
flex:1;
height:300px;
min-height:300px;
width:500px;
color:#FFF;
border-radius:7px;
`;

export const SubContainerGraficoLoading = styled.div`
 display:flex;
 flex-direction:column;
 justify-content:center;
 align-items:center;
 height:300px;
 min-height:300px;
 width:45%;
 margin:10px;
 max-width:45%;
 -webkit-box-shadow: 0px 0px 4px 1px #000;
  -moz-box-shadow: 0px 0px 4px 1px #000;
  box-shadow: 0px 0px 4px 1px #000;
  border-radius:7px;
  background:${props => props.backgroundDinamico};
  /* animation-duration: 1.2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${animacaoLoading};
  animation-timing-function: linear; */
`;
export const TextBoxLoading = styled.div`
 height:25px;
 min-height:25px;
 width:130px;
 background:${props => `linear-gradient(to right, ${props.backgroundinamico} 10%, ${lighten(0.2, props.backgroundinamico)} 28%, ${props.backgroundinamico} 33%)`};
animation:${animacaoLoading} 1.2s linear infinite;
  background-size: 400% 400%; 
  border-radius:5px;
`;

export const TextGraficoLoading = styled.div`
 height:25px;
 min-height:25px;
 flex:1;
 width:100%;
 border-radius:5px;
 background:${props => `linear-gradient(to right, ${props.backgroundinamico} 10%, ${lighten(0.2, props.backgroundinamico)} 28%, ${props.backgroundinamico} 33%)`};
animation:${animacaoLoading} 1.2s linear infinite;
  background-size: 400% 400%; 
`;
export const ContainerTextGraficoLoading = styled.div`
 height:25px;
 min-height:25px;
 width:90%;
margin-top:15px;
`;
export const ContainerGraficoLoading = styled.div`
 flex:1;
 height: 100%;
 width: 100%;
display:flex;
justify-content:center;
align-items:center;
`;
export const GraficoLoading = styled.div`
 height: 80%;
 width: 50%;
display:flex;
border-radius:50%;
background:${props => `linear-gradient(to right, ${props.backgroundinamico} 10%, ${lighten(0.2, props.backgroundinamico)} 28%, ${props.backgroundinamico} 33%)`};
animation:${animacaoLoading} 1.2s linear infinite;
  background-size: 400% 400%; 
`;

export const IconeAddTarefa = styled.i`
 color:#00FF7F;
  margin-right: 5px;
  font-size:13px;
  cursor: pointer;
`;
export const IconeTarefa = styled.i`
 color:${props => props.colorDinamico};
  margin-right: 5px;
  font-size:13px;
  cursor: pointer;
`;
export const ContainerTarefas = styled.div`
 flex:1;
 width:100%;
display:flex;
flex-direction:column;
overflow:auto;
padding-left:10px;
`;


export const InputTarefa = styled.input`
display:flex;
background-Color:transparent;
flex:1;
height:40px;
min-height:40px;
border:0;
color:${props => {
      if (props.feito == true) {
         return props.placeholderDinamico;
         
      } else {
         return props.colorDinamico;
      }

   }}; 
&::placeholder{
   color:${props => props.placeholderDinamico};
}
text-decoration:${props => {
      if (props.feito == true) {
         return 'line-through';
      } else {
        return 'none';
      }

   }}; 
`;
export const CheckManterConectado = styled.input`
border: 0;
height:35px;
min-height:35px;
margin-right:10px;
background:transparent;
cursor: pointer;
`;

export const ContainerModalPrioridadeAtendimento = styled.div`
 display:flex;
   width:550px;
  height:260px;
  min-height:260px;
  overflow:auto;
   flex-direction:column;
   border-radius:7px;
   background:${props => props.backgroundDinamico};
`;
export const ContainerIconeFecharModal = styled.div`
padding-top:5px;
padding-bottom:5px;
justify-content:space-between;
align-items:center;
display:flex;
border-radius: 7px 7px 0 0;
width:100%;
background:${props => props.backgroundDinamico};
`;
export const TextNovoAtendimento = styled.strong`
font-size:17px;
color:${props => props.corLetraDinamico};
margin-left:12px;
letter-spacing: 1.5px;
`;
export const TextCabecalhoModalAcoes = styled.strong`
font-size:18px;
color:${props => props.corLetraDinamico};
text-align:center;
`;
export const IconeFechar = styled.i`
font-size:15px;
margin-right:5px;
color:${props => props.backgroundDinamico};
cursor: pointer;
`;
export const ContainerAtendentes = styled.div`
display:flex;
  flex:1;
  align-items:center;
  justify-content:center;
  flex-direction:column;
`;
export const DivInputMotivoCancelamento = styled.div`
 display:flex;
 flex:1;
justify-content:center;
align-items:flex-end;
`;
export const ButtonNaoCancelarAtendimento = styled.button`
display:flex;
flex-direction:row;
border:0;
margin:10px;
justify-content:space-around;
height:40px;
min-height:40px;
width:150px;
align-items:center;
border-radius:5px;
transition: background-color 0.2s;
background:${props => props.backgroundBotao};
border:${props => ` 1px solid ${props.borderColorDinamico}`};
&:hover{
    background:${props => shade(0.2, props.backgroundBotao)};
}
`;
export const ButtonCancelarAtendimento = styled.button`
display:flex;
flex-direction:row;
border:0;
margin:10px;
justify-content:space-around;
height:40px;
min-height:40px;
width:150px;
align-items:center;
border-radius:5px;
transition: background-color 0.2s;
background:${props => props.backgroundBotao};
&:hover{
    background:${props => shade(0.2, props.backgroundBotao)};
}
`;
export const TextBotaoCancelarAtendimento = styled.span`
  color:${props => props.colorDinamico};
  font-size:16px;
 font-weight:bold;
 flex:1;
 position: relative;
 margin-right:7px;
 text-align:center;
 align-items:center;
 justify-content:center;
`;
export const DivMensagemSair = styled.div`
 display:flex;
 flex:1;
justify-content:center;
align-items:center;
`;