import styled from 'styled-components';
import { shade, opacify, lighten, } from 'polished';

export const Container = styled.div`
 display:flex;
 position:relative;
   width:100%;
   flex-direction:column;
   flex:1;
   background:${props => props.backgroundDinamico};
`;
export const CabecalhoVersaoPremium = styled.div`
 display:flex;
 max-height:80px;
min-height:80px;
 height:80px;
 width:100%;
 background:${props => props.backgroundDinamico};
 flex-direction:column;
 justify-content:center;
 align-items:center;
 margin-top:1px;
`;
export const TextCabecalho = styled.p`
font-size:28px;
font-weight:bold;
color:${props => props.corPrincipal};
`;
export const ContainerDivMensalAnual = styled.div`
 display:flex;
 height:35px;
 min-height:35px;
 width:250px;
 margin-top:15px;
 max-height:35px;
 max-width:250px;
 background:${props => props.backgroundDinamico};
 border-radius:4px;
  justify-content:center;
 align-items:center;
`;
export const DivMensal = styled.div`
 display:flex;
 height:31px;
 min-height:31px;
 width:150px;
 max-height:31px;
 max-width:150px;
 background:${props => props.backgroundDinamico};
 justify-content:center;
 align-items:center;
 cursor: pointer;
 border-radius:4px;
 margin:2px;
`;
export const DivAnual = styled.div`
display:flex;
 height:31px;
 min-height:31px;
 width:150px;
 max-height:31px;
 max-width:150px;
 background:${props => props.backgroundDinamico};
 justify-content:center;
 align-items:center;
 cursor: pointer;
 border-radius:4px;
 margin:2px;
`;
export const TextMensalAnual = styled.p`
font-size:16px;
font-weight:bold;
color:${props => props.corLetraBotao};
`;


export const ContainerPlanos = styled.div`
 display:flex;
 flex-direction:row;
 justify-content:center;
 align-items:center;
`;
export const Planos = styled.div`
 display:flex;
 flex-direction:column;
 background-color:${props => props.backgroundDinamico};
 height:750px;
 min-height:750px;
 width:250px;
 margin:10px;
 max-width:250px;
 -webkit-box-shadow: 0px 0px 4px 1px #000;
  -moz-box-shadow: 0px 0px 4px 1px #000;
  box-shadow: 0px 0px 4px 1px #000;
  border-radius:7px;
`;
export const DivTextPlano = styled.div`
 display:flex;
 justify-content:center;
 align-items:center;
 width:250px;
 max-width:250px;
 height:80px;
 min-height:80px;
 flex-direction:column;
`;
export const TextPlano = styled.p`
font-size:22px;
font-weight:bold;
color:${props => props.corPrincipal};
`;
export const TextSubPlano = styled.p`
font-size:12px;
color:${props => props.corPrincipal};
`;
export const DivIconePlano = styled.div`
 display:flex;
 justify-content:center;
 align-items:center;
 width:250px;
 max-width:250px;
 height:40px;
 min-height:40px;
 flex-direction:row;
`;
export const DivPreco = styled.div`
 display:flex;
 justify-content:center;
 align-items:center;
/*  width:250px;
 max-width:250px;
 height:130px;
 min-height:130px; */
 flex-direction:row;
`;
export const DivPrecoAnual = styled.div`
 display:flex;
 justify-content:center;
 align-items:flex-end;
 width:250px;
 max-width:250px;
 height:80px;
 min-height:80px;
 flex-direction:row;
`;
export const DivEconomia = styled.div`
 display:flex;
 justify-content:center;
 align-items:center;
 width:250px;
 max-width:250px;
 height:55px;
 min-height:55px;
 flex-direction:row;
`;

export const TextPreco = styled.p`
font-size:38px;
font-weight:bold;
color:${props => props.corPrincipal};
`;
export const TextMes = styled.p`
font-size:15px;
color:${props => props.corPrincipal};
text-align:flex-end;
margin-top:13px;
margin-left:1px;
`;
export const TextMesEconomia = styled.p`
font-size:15px;
color:${props => props.corPrincipal};
text-align:flex-end;
margin-left:1px;
margin-bottom:10px;
`;

export const DivContainerOpcoes = styled.div`
 display:flex;
flex:1;
 width:250px;
 flex-direction:column;
`;
export const ButtonAssinar = styled.button`
display:flex;
justify-content:center;
align-items:center;
background-color:${props => props.backGroundDinamico};
color:${props => props.corLetraBotao};
letter-spacing: 1px;
font-size:18px;
font-weight:bold;
border:0;
height:40px;
min-height:40px;
width:220px;
margin:10px;
transition: background-color 0.2s;
border-radius: 5px;
&:hover{
   background: ${props => shade(0.2, props.backGroundDinamico)};
}
`;
export const ButtonCancelar = styled.button`
display:flex;
justify-content:center;
align-items:center;
background-color:${props => props.backGroundDinamico};
color:${props => props.corLetraBotao};
letter-spacing: 1px;
font-size:18px;
font-weight:bold;
border:1px solid ${props => props.corBorda};
height:40px;
min-height:40px;
width:150px;
margin:10px;
transition: background-color 0.2s;
border-radius: 5px;
&:hover{
   background: ${props => shade(0.2, props.backGroundDinamico)};
}
`;

export const ButtonTrocarPlano = styled.button`
display:flex;
justify-content:center;
align-items:center;
background-color:${props => props.backGroundDinamico};
color:${props => props.corLetraBotao};
letter-spacing: 1px;
font-size:18px;
font-weight:bold;
border:0;
height:40px;
min-height:40px;
width:300px;
margin:10px;
transition: background-color 0.2s;
border-radius: 5px;
&:hover{
   background: ${props => shade(0.2, props.backGroundDinamico)};
}

`;



export const ContainerModal = styled.div`
 display:flex;
   overflow:auto;
 flex:1;
   width:600px;
   height:600px;
   min-height:600px;
   flex-direction:column;
   border-radius:7px;
   background:${props => props.backgroundDinamico};
`;
export const ContainerModalCompra = styled.div`
 display:flex;
   overflow:auto;
   width:800px;
   flex-direction:column;
   border-radius:7px;
   background:${props => props.backgroundDinamico};
`;
export const ContainerModalCompraSucesso = styled.div`
 display:flex;
   overflow:auto;
 flex:1;
   width:400px;
   height:300px;
   min-height:300px;
   flex-direction:column;
   border-radius:7px;
   background:${props => props.backgroundDinamico};
`;
export const ContainerModalCancelarAssinatura = styled.div`
 display:flex;
   overflow:auto;
 flex:1;
   width:500px;
   height:550px;
   min-height:550px;
   flex-direction:column;
   border-radius:7px;
   background:${props => props.backgroundDinamico};
`;
export const ContainerIconeFecharModal = styled.div`
padding-top:5px;
padding-bottom:5px;
justify-content:space-between;
align-items:center;
display:flex;
border-radius: 7px 7px 0 0;
width:100%;
background:${props => props.backgroundDinamico};
`;
export const TextNovoAtendimento = styled.strong`
font-size:17px;
color:${props => props.corLetraDinamico};
margin-left:12px;
letter-spacing: 1.5px;
`;
export const IconeFechar = styled.i`
font-size:15px;
margin-right:5px;
color:${props => props.backgroundDinamico};
cursor: pointer;
`;
export const ContainerVersaoPremiumAtiva = styled.div`
 display:flex;
 flex-direction:column;
 background-color:${props => props.backgroundDinamico};
 height:460px;
 min-height:460px;
 width:700px;
 margin:10px;
 margin-top:30px;
 margin-bottom:10px;
 max-width:700px;
 -webkit-box-shadow: 0px 0px 4px 1px #000;
  -moz-box-shadow: 0px 0px 4px 1px #000;
  box-shadow: 0px 0px 4px 1px #000;
  border-radius:7px;
`;
export const ContainerVersaoPremiumSuspensa = styled.div`
 display:flex;
 flex-direction:column;
 background-color:${props => props.backgroundDinamico};
 height:400px;
 min-height:350px;
 width:600px;
 margin:10px;
 margin-top:30px;
 max-width:600px;
 -webkit-box-shadow: 0px 0px 4px 1px #000;
  -moz-box-shadow: 0px 0px 4px 1px #000;
  box-shadow: 0px 0px 4px 1px #000;
  border-radius:7px;
`;

export const ContainerAssinaturaAtiva = styled.div`
 display:flex;
 flex-direction:column;
 flex:1;
`;
export const DivTextVPAtivo = styled.div`
 display:flex;
 flex-direction:column;
height:50px;
min-height:50px;
flex:1;
 align-items:center;
 justify-content:center;
`;
export const TextVersaoPremium = styled.p`
font-size:18px;
font-weight:bold;
color:${props => props.corPrincipal};
`;
export const TextAssinaVersaoPremium = styled.p`
font-size:20px;
font-weight:bold;
color:${props => props.corPrincipal};
`;

export const InputSearch = styled.input`
display:flex;
height:32px;
min-height:32px;
flex:1;
width:100%;
padding-left:7px;
font-size:14px;
border:0;
color:${props => props.corLetraDinamico};
background-color: ${props => props.backgroundDinamico};

border:none;
cursor: pointer;
&::placeholder{
   color:${props => props.placeholderColor};
   font-style: italic;
}
&:hover{
 }

`;

export const DivInputSearch = styled.div`
 display:flex;
  flex-direction:row;
  align-items:center;
`;
export const IconeSetaPraBaixo = styled.i`
font-size: 12px;
position: absolute;
top:10px;
right: 19px;
width: 11px;
height: 20px;
min-height: 20px;
color:'#000';
pointer-events: none;
`;
export const DivRecomendadoContainer = styled.div`
height:30px;
font-size: 14px;
font-weight: 800px;
text-align: center;
align-items:center;
justify-content:center;
line-height: 2em;
letter-spacing: 2px;
font-weight:bold;
color: #FFF;
background: #39a3f4;
border-top-left-radius:7px;
border-top-right-radius:7px;

&::before{
  -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
`;
export const DivRecomendado = styled.div`
color: #FFF;

    font-weight:bold;
    text-transform: uppercase;
    font-style: normal;
    line-height: 2em;
    letter-spacing: 0.5px;
    text-align: center;
    left: 0;
    width: 200%;
    -webkit-transform: translateY(-50%) translateX(-50%) translateX(35px) rotate(-45deg);
    -ms-transform: translateY(-50%) translateX(-50%) translateX(35px) rotate(-45deg);
    transform: translateY(-50%) translateX(-50%) translateX(35px) rotate(-45deg);
    margin-top: 35px;
    
`;

export const InputCancelamento = styled.input`
display:flex;
background-Color:transparent;
flex:1;
height:60px;
min-height:60px;
border:0;
color:${ props => props.corLetraDinamico};
border-radius:5px;
padding-left:5px;
width:480px;
border: ${props => ` 1px solid ${props.corLetraDinamico}`};
&::placeholder{
   color:#a8a8B3;
}
&:focus {
  border:1px solid ${props => props.corBordaDinamica};
  }
`;

export const ContainerSelectSearch = styled.div`
display:flex;
flex-direction:row;
height:30px;
min-height:30px;
width:435px;
margin-left:15px;
background:blue;
`;
export const InputSearchQtdAtendentes = styled.input`
display:flex;
flex:1;
height:30px;
min-height:30px;
width:250px;
border-radius:5px;
padding-left:7px;
font-size:14px;
border:0;
color:${props => props.corLetraDinamico};
background-color: ${props => props.backgroundDinamico};

border:${props => `outset 0.5px ${props.corLetraDinamico}`};
cursor: pointer;
&::placeholder{
   color:${props => props.placeholderColor};
   font-style: italic;
}
&:hover{
  border:${props => `solid 0.5px ${props.corBordaDinamica}`};
 }

`;