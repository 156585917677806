
import moment from 'moment';
import firebase from '../servicos/FirebaseConnection';
import loadContacts from "../components/AtendimentoOnline/loadContacts";
import api from '../http_client/http.client';
import getMimeType from '../servicos/MimeType';
import { removeItemsDuplicateFromListByValue, generateUUIDv4, renameFile } from "../servicos/utils";
import { removeFirebaseCachedData, getAllFirebaseCachedData, getFirebaseCachedData, clearCacheRedis } from './cachedData';

import {
  MODIFICAVISIBILIDADEMODALNOVOATENDIMENTO, VISIBLESELECTSEARCH, MODIFICANOMEROTANOHEADER,
  MODIFICAVISIBILIDADEMODALALTERARATENDIMENTO, MODIFICAVISIBILIDADEMODALATRIBUIRATENDIMENTO,
  MODIFICAVISIBILIDADEMODALADDTEMPOGASTOATENDIMENTO, MODIFICAVISIBILIDADEMODALFINALIZARATENDIMENTO,
  MODIFICAVISIBILIDADEMODALPRIORIDADEATENDIMENTO, MODIFICAVISIBILIDADEMODALCANCELARATENDIMENTO,
  MODIFICAVISIBILIDADEMODALCOMENTARIo, MODIFICAINPUTCOMENTARIO, MODIFICACOMENTARIOPUBLICOSIMOUNAO,
  MODIFICAARQUIVOCOMOPLETOPARAUPAR,
  MODIFICANOMECLIENTESELECIONADOTELAALTERARCLIENTENOVOCADASTRO,
  ZERAMENSAGEMSEMINTERNETCHAMADOABERTO,
  ENVIASETORPARAREDUCER,
  ALTERASETOR,
  ALTERAPRIORIDADE,
  ALTERALOCALATENDIMENTO,
  ALTERADESCRICAOCHAMADO,
  ENVIAFOTOCLIENTEPARAREDUCER,
  INSEREFOTOCADASTROCLIENTE,
  MODIFICANOMECLIENTETELACADASTROCLIENTE,
  MODIFICAEMPRESACLIENTETELACADASTROCLIENTE,
  MODIFICAEMAILCLIENTETELACADASTROCLIENTE,
  MODIFICASENHACLIENTETELACADASTROCLIENTE,
  MODIFICACONFIRMASENHACLIENTETELACADASTROCLIENTE,
  MENSAGEMCADASTRACLIENTEERROR,
  MENSAGEMCADASTRACLIENTESUCESSO,
  ATIVALOADINGBOTAOCADASTRARCLIENTE,
  ALTERAVALORDIGITOCONSULTA,
  ALTERAVALORSWITCHTELACLIENTE,
  ZERAINFORMACOESCADASTROCLIENTE,
  INSEREFOTOCADASTROCLIENTETELAFLATLISTCONSULTA,
  MODIFICACLIENTESELECIONADO,
  ENVIACLIENTEPARAREDUCER,
  ALTERAMAISDETALHESTELANOVOCHAMADO,
  ATIVALOADINGCARREGARCLIENTETELANOVOCHAMADO,
  DESATIVALOADINGCARREGARCLIENTETELANOVOCHAMADO,
  ATIVALOADINGCARREGARATENDENTETELANOVOCHAMADO,
  DESATIVALOADINGCARREGARATENDENTETELANOVOCHAMADO,
  ENVIAATENDENTEPARAREDUCER,
  MODIFICAATENDENTESELECIONADO,
  MODIFICAPRIORIDADESELECIONADO,
  MODIFICALOCALSELECIONADO,
  ATIVALOADINGCARREGARSETORTELANOVOCHAMADO,
  MODIFICASETORSELECIONADO,
  ALTERAEMPRESACLIENTETELAALTERAR,
  ALTERANOMECLIENTETELAALTERAR,
  ALTERAREMAILCLIENTETELAALTERAR,
  ALTERARPERMISSAOCLIENTETELAALTERAR,
  ALTERASENHACLIENTETELAALTERAR,
  ALTERACONFIRMASENHACLIENTETELAALTERAR,
  ALTERAFOTOCLIENTETELAALTERAR,
  INSEREFOTOALTERARCLIENTE,
  ALTERAKEYCLIENTETELAALTERAR,
  ATIVALOADINGBOTAOALTERARCLIENTE,
  MENSAGEMCADASTRACLIENTEERRORTELAALTERAR,
  MENSAGEMCADASTRACLIENTESUCESSOTELAALTERAR,
  ZERAMENSAGEMDESUCESSOEERRO,
  ZERAMSGSUCESSOEERROCADASTRACLIENTE,
  SEMCONEXAOINTERNETCONSULTACLIENTE,
  SEMCONEXAOINTERNETCADASTRACLIENTE,
  SEMCONEXAOINTERNETALTERACLIENTE,
  ATIVALOADINGBOTAOCADASTRARCHAMADO,
  SEMCONEXAOINTERNETCADASTRACHAMADO,
  CHAMADOCADASTRADOSUCESSO,
  MODIFICANOMEARQUIVO1,
  MODIFICANOMEARQUIVO2,
  MODIFICANOMEARQUIVO3,
  MODIFICARESARQUIVO1,
  MODIFICARESARQUIVO2,
  MODIFICARESARQUIVO3,
  LISTACHAMADOABERTO,
  VERIFICACONEXAOINTERNETCHAMADOABERTO,
  ATIVALOADINGTELACHAMADOABERTO,
  ENVIAULTIAMREFERENCIAKEY,
  MODIFICANOMECLIENTESELECIONADO,
  MODIFICANOMEATENDENTESELECIONADO,
  ZERACADASTROCHAMADO,
  ATIVADESATIVALOADINGFINALFLATLISTCHAMADOABERTO,
  MODIFICAMOSTRARMAISCHAMADOS,
  ZERAMENSAGEMSEMINTERNETCHAMADOEMATENDIMENTO,
  ENVIACHAMADOEMATENDIMENTO,
  ATIVADESATIVALOADINGTELACHAMADOATENDIMENTO,
  ENVIAULTIAMREFERENCIAKEYEMATENDIMENTO,
  ATIVADESATIVALOADINGFINALFLATLISTCHAMADOEMATENDIMENTO,
  ENVIACHAMADOFECHADO,
  ATIVADESATIVALOADINGTELACHAMADOFECHADO,
  VERIFICACONEXAOINTERNETCHAMADOFECHADO,
  ZERAMENSAGEMSEMINTERNETCHAMADOFECHADO,
  ENVIAULTIAMREFERENCIAKEYFECHADO,
  ATIVADESATIVALOADINGFINALFLATLISTCHAMADOFECHADO,
  ZERAMENSAGEMSEMINTERNETCHAMADOCANCELADO,
  ENVIACHAMADOCANCELADO,
  VERIFICACONEXAOINTERNETCHAMADOCANCELADO,
  ATIVADESATIVALOADINGTELACHAMADOCANCELADO,
  ENVIAULTIAMREFERENCIAKEYCANCELADO,
  ATIVADESATIVALOADINGFINALFLATLISTCHAMADOCANCELADO,
  ENVIACHAMADOTODOS,
  ATIVADESATIVALOADINGTELACHAMADOTODOS,
  VERIFICACONEXAOINTERNETCHAMADOTODOS,
  ZERAMENSAGEMSEMINTERNETCHAMADOTODOS,
  ATIVADESATIVALOADINGCARREGARCLIENTETELACONSULTACLIENTE,
  ENVIAULTIAMREFERENCIACHAMADOEMATENDIMENTO,
  ENVIAULTIAMREFERENCIACHAMADOFECHADO,
  ENVIAULTIAMREFERENCIACHAMADOCANCELADO,
  ATIVADESATIVALOADINGFINALFLATLISTCHAMADOTODOS,
  INDICAFIMDALISTA,
  ENVIAULTIAMREFERENCIAKEYTODOS,
  ATIVADESATIVALOADINGCARREGARCLIENTETELACHAMADOTODOS,
  ENVIACLIENTEPARAREDUCERTELACHAMADOTODOS,
  MODIFICACLIENTESELECIONADOTELACHAMADOTODOS,
  MODIFICANOMECLIENTESELECIONADOTELACHAMADOTODOS,
  ENVIAULTIAMREFERENCIAKEYCLIENTECHAMADOTODOS,
  ATIVADESATIVALOADINGTELACONSULTACLIENTE,
  ENVIAULTIAMREFERENCIAKEYTELACONSULTACLIENTE,
  ATIVADESATIVALOADINGFINALFLATLISTTELACONSULTACLIENTE,
  ENVIACLIENTESECTIONEDTELACONSULTACLIENTE,
  MODIFICANOMECLIENTESELECIONADOTELACONSULTACLIENTE,
  MODIFICACLIENTESELECIONADOTELACONSULTACLIENTE,
  MODIFICACLIENTESELECIONADOTELACHAMADOTODOSTESTE,
  ATIVADESATIVALOADINGREFRESHCONTROLCONSULTACLIENTE,
  ATIVADESATIVALOADINGREFRESHCONTROLCHAMADOABERTO,
  ATIVADESATIVALOADINGREFRESHCONTROLCHAMADOEMATENDIMENTO,
  ATIVADESATIVALOADINGREFRESHCONTROLCHAMADOFECHADO,
  ATIVADESATIVALOADINGREFRESHCONTROLCHAMADOCANCELADO,
  MODIFICADATAVENCIMENTOTELACHAMADOABERTURA,
  MODIFICAARQUIVOUPADO1,
  MODIFICAARQUIVOUPADO2,
  MODIFICAARQUIVOUPADO3,
  MODIFICACONTAGEMARQUIVO,
  MODIFICATAMANHOARQUIVO1,
  MODIFICATAMANHOARQUIVO2,
  MODIFICATAMANHOARQUIVO3,
  ALTERANUMEROATENDIMENTO,
  ALTERAKEYCHAMADO,
  ENVIAATENDIMENTOPARAREDUCER,
  ATIVADESATIVALOADINGTELAACOESATENDIMENTO,
  ENVIAUSUARIOSTELAACOESATENDIMENTO,
  VERIFICAERRORTELAALTERARCHAMADO,
  VERIFICACHAMADOALTERADOSUCESSO,
  ENVIAARQUIVOSUPADOSTELAACOESCHAMADOS,
  ENVIAARQUIVOSUPADOSTELAACOESCHAMADOS2,
  ENVIAARQUIVOSUPADOSTELAACOESCHAMADOS3,
  ENVIAACOESATENDIMENTOPARAREDUCER,
  MODIFICAMOTIVOCANCELAMENTOTELAACOESATENDIMENTO,
  MODIFICAMENSAGEMERRORMOTIVOCANCELAMENTO,
  MODIFICAVISIBILIDADEMODALCANCELAR,
  ATIVADESATIVASWITCHCOMENTARIO,
  MODIFICANOMEARQUIVOCOMENTARIOTELAACOESATENDIMENTO,
  MODIFICARESARQUIVOCOMENTARIOTELAACOESATENDIMENTO,
  ZERAMENSAGEMSEMCONEXAOINTERNETSALVARCOMENTARIO,
  ZERAINFORMACOESCOMENTARIO,
  ENVIAARQUIVOPARASERUPADOTELAABERTURACHAMADO,
  ENVIATAMANHOARQUIVOANEXADOTELAABERTURACHAMADO,
  ENVIARQUIVOSUPADOSANEXADOSAOCHAMADO,
  ATIVADESATIVALOADINGARQUIVOSUPADOSANEXADOSAOCHAMADO,
  DIMINUITAMANHOTOTALARQUIVOSANEXADOSLOCAL,
  ATIVADESATIVALOADINGNUMEROEXCLUSIVOCADASTRACLIENTE,
  MODIFICANUMEROEXCLUSIVOCADASTRACLIENTE,
  DESATIVALOADINGBOTAOCADASTRARCLIENTE,
  MUDAVISIBILIDADEMODALCADASTRACLIENTEVERSAOPREMIUM,
  VERIFICARSEEHOBRIGATORIOATRIBUIRATENDENTE,
  ALTERACLIENTESELECIONADOTELACADASTRACLIENTE,
  MODIFICANOMECLIENTESELECIONADOTELACADASTROCLIENTE,
  ATIVADESATIVALOADINGTELAALTERARCLIENTE, MODIFICATABINDEX,
  ENVIAEMPRESAPRINCIPALCLIENTE, MODIFICAENDERECOTELACADASTROCLIENTE, MODIFICATELEFONETELACADASTROCLIENTE,
  ATIVADESATIVALOADINGCARREGARCLIENTETELAALTERARCLIENTE, ENVIAEMPRESASECTIONEDTELAALTERARCLIENTE, ALTERAEMPRESASELECIONADOTELAALTERARCLIENTE,
  MODIFICANOMECLIENTESELECIONADOTELAALTERARCLIENTE, MODIFICAEMPRESASELECIONADAAOABRIRCHAMADO, MODIFICAKEYEMPRESAVINCULADASELECIONADAAOABRIRCHAMADO,
  ATIVADESATIVALOADINGBOTAOEXCLUIRCLIENTE, MUDAVISIBILIDADEMODALEXCLUIRCLIENTE,
  ENVIACLIENTEPARATELARELATORIODETALHADO, LOADINGCLIENTETELARELATORIODETALHADO,
  MODIFICANOMESETORSELECIONADO, MODIFICAMODALFILTROCHAMADO, MODIFICAFILTROCHAMADO, MODIFICASELECTTEXTCHAMADO, MODIFICAPESQUISATEXTCHAMADO,
  MODIFICAVALORARRAYSECTIONEDFILTROCHAMADO, MODIFICAFILTROSELECIONADOTELACHAMADOABERTO, MODIFICAFILTROSELECIONADOTELACHAMADOEMATENDIMENTO,
  MODIFICAFILTROSELECIONADOTELACHAMADOFECHADO, MODIFICAFILTROSELECIONADOTELACHAMADOCANCELADO, MODIFICAFILTROSELECIONADOTELACHAMADOTODOS,
  ATIVADESATIVALOADINTESTETSTE, MODIFICAVISIBLIDADEBOTAOADDVENCIMENTO, MODIFICAFILTROCLIENTESTELACLIENTES,
  MODIFICAVISIBLEMODALNOVOCLIENTE,
  MODIFICAEMPRESACLIENTETELACADASTROCLIENTENOVOCADASTRO, ALTERAEMPRESASELECIONADOTELAALTERARCLIENTENOVOCADASTRO,
  MODIFICANOMECLIENTETELACADASTROCLIENTENOVOCADASTRO, MODIFICAENDERECOTELACADASTROCLIENTENOVOCADASTRO,
  MODIFICATELEFONETELACADASTROCLIENTENOVOCADASTRO, MODIFICAEMAILCLIENTETELACADASTROCLIENTENOVOCADASTRO,
  MODIFICASENHACLIENTETELACADASTROCLIENTENOVOCADASTRO, MODIFICACONFIRMASENHACLIENTETELACADASTROCLIENTENOVOCADASTRO,
  ALTERAVALORSWITCHTELACLIENTENOVOCADASTRO, MODIFICAARQUIVOCOMOPLETOPARAUPARNOVOCADASTRO,
  MODIFICARETORNATELACLIENTESPARANOVOATENDIMENTO, ENVIAATENDIMENTOEMESPERATENDIMENTOONLINE,
  MODIFICAKEYATENDIMENTOEMESPERAATENDIMENTOONLINE, MODIFICAATENDIMENTOEMESPERASELEECIONADO, MODIFICACHAMADOSEMATENDIMENTOVIACHAT,
  MODIFICAKEYATENDIMENTOEMTENDIMENTOONLINE, ENVIAACOESATENDIMENTOPARAREDUCERCHAT, ENVIAATENDIMENTOPARAREDUCERCHAT,
  MODIFICAVISIBILIDADEMODALATENDIMENTOONLINE, ALTERACOMENTARIOCHAT, MODIFICAUPANDOARQUIVOCHAT, MODIFICATEXTARQUIVOCHAT,
  ATIVADESATIVALOADINGCHATONLINE, MODIFICAVISIBILIDADEMODALPORTALCLIENTE, MODIFICAVISIBILIDADEMODALIMPORTARCLIENTES,
  MODIFICALOADINGCADASTRANDOCLIENTEEMMASSA, ZERAINFORMACOESENVIOARQUIVOCHAT, ATIVADESATIVALOADINGMAISRESULTADOSCHAMADOSCLIENTE,
  ATIVADESATIVALOADINGENVIANDOAUDIOCHAT, MODIFICARESOLUCAOPROBLEMA, ENVIAULTIMONOMEREFENCIATELACONSULTACLIENTE,
  ENVIAATENDIMENTOPORKEYREDUCERCHAT, ENVIACONVERSASPARACHATEMESPERA, ENVIAATENDIMENTOPORKEYREDUCERCHATEMESPERA,
  ENVIACONTATOSPARAREDUCER, MODIFICAKEYCONTACTS, MODIFICADADOSCONTACTSSELECIONADO,
  ENVIACONVERSASULTIMOCHAMADO, ATIVADESATIVALOADINGCARREGACHAMADOSANTIGOS, ENVIAULTIMAREEFERENCIAKEYCONTACTS,
  ATIVADESATIVALOADINGCARREGAMAISCHAMADOSANTIGOS, ATIVADESATIVALOADINGINICIANDOCONVERSACONTACTS, ATIVADESATIVALOADINGCARREGACONTATOSWPP,
  INSEREMENSAGEMERRORGETCONTACTS, ENVIAULTIMAREEFERENCIAKEYEMESPERA, ATIVADESATIVALOADINGCARREGACHAMADOSANTIGOSEMESPERA,
  ENVIACONVERSASCARREGADASEMESPERA, ENVIACONVERSASCARREGADASEMATENDIMENTO, ENVIAULTIMAREEFERENCIAKEYEMATENDIMENTO,
  ATIVADESATIVALOADINGCARREGACHAMADOSANTIGOSEMATENDIMENTO,
  ATIVADESATIVALOADINGCARREGARESPOSTAANTIGAS, ENVIAIDRESPOSTAANTIGA, MODIFICANOMECLIENTETELACHATONLINE,
  MODIFICAARQUIVOCOMOPLETOPARAUPARNOVOCONTATO, INSEREFOTOCADASTROCONTATO, MODIFICAVISIBILIDADEMODALADICIONARCONTATO,
  MODIFICANOMECONTATO, MODIFICATELEFONECONTATO, MODIFICALOADINGCADASTRANOVOCONTATO, ZERAINFORMACOESCADASTROCONTATO,
  MODIFICAMENSAGEMFORAHORARIOOPERACAO, MODIFICAMENSAGEMESCOLHADEPARTAMENTO, MODIFICAMENSAGEMFILAATENDIMENTO,
  MODIFICAMENSAGEMFINALIZACAOCHAMADO, MODIFICATITLETAB, ATIVADESATIVALOADINGENVIACLIENTETELAAVALIACAOTODAS, ENVIACLIENTEPARAREDUCERTODASAVALIACOES,
  ENVIACLIENTEPARAREDUCERNOVOAVISO, ATIVADESATIVALOADINGCARREGARCLIENTETELANOVOAVISO,

  ENVIAIDRESPOSTAANTIGAACOESCHAMADO, ATIVADESATIVALOADINGCARREGARESPOSTAANTIGASACOESCHAMADO,
  ENVIACONVERSASANTIGASCHATACOESCHAMADO, ATIVADESATIVALOADINGCARREGANDOMAISACOESCHAMADOACOESCHAMADO,
  ENVIAREFERENCIAULTIMAKEYACOESCHAMADOACOESCHAMADO, MODIFICACLIENTESEXPORTARCLIENTES, MODIFICAMODALRESPOSTAPADRAO,
  ENVIAPROBLEMAPARAREDUCER, ATIVADESATIVALOADINGCARREGANDOPROBLEMAS,
  MODIFICAPROBLEMAESTATICOSELECIONADONOVOATENDIMENTO, MODIFICANOMEPROBLEMAESTATICOSELECIONADONOVOATENDIMENTO,
  MODIFICAURLPREVIEWIMAGE, MODIFICAMODALPREVIEWIMAGE, MODIFICANAMEPREVIEWIMAGE, MODIFICAVISIBILIDADEMODALALTERARCHAT,
  MODIFICACLIENTESFILTRADOSAPOSDIGITO
} from './types';
import { toastr } from 'react-redux-toastr';


export const testaVerificaTempoLimiteAtingido = (keyCliente) => {
  return dispatch => {
    AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");
      firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild("status").equalTo("Aberto").limitToLast(50).once("value")
        .then(snapshot => {


        })
    })
  }
}
export const setaMensagensComoLidaWhatsAppSocket = (dadosChamado, apiKey, sessionclient, urlApi) => {
  return dispatch => {

    let data = {
      numeroCliente: dadosChamado.telefoneCliente,
      apiKey: `${apiKey}`,
      session: `${sessionclient}`,
      urlApi: urlApi,
      chaveUnica: dadosChamado.chaveUnica,
    }
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Accept': 'application/json',
      },

      data: data,
      url: 'marcarMensagemComoLidaWppSocket',
    };
    api(options).then((response) => {

    }).catch((err) => {


    })
  };
};

export const listaProblemasEstaticosTelaNovoChamado = () => {
  return dispatch => {
    ativaDesativaLoadingCarregandoProblemas(dispatch, true);

    AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");

      firebase.database().ref(`${chaveUnica}/problemaEstatico`).orderByChild("selecionado").equalTo(1).on("value", snapshot => {
        if (snapshot.val() == null) {
          const arrayUser = [];
          enviaProblemasParaReducer(dispatch, arrayUser);
          ativaDesativaLoadingCarregandoProblemas(dispatch, false);
        } else {
          const children2 = [];
          snapshot.forEach(childItem => {

            children2.push({
              value: childItem.key,
              name: childItem.val().descricaoProblema
            });

          });
          const items = [{
            name: 'PROBLEMAS',
            type: 'group', items: children2
          }];
          enviaProblemasParaReducer(dispatch, items);
          ativaDesativaLoadingCarregandoProblemas(dispatch, false);
        }
      });
    });
  };
};
export const deletaMensagemDropDesk = (dadosMensagem, keyChamado, viaChat, configWhatsapp, isLastMessage, dadosAtendimento) => {
  return dispatch => {
    let keyAcoesChamado = dadosMensagem.key;



    let apiKey = '';
    let urlApi = '';
    let sessionclient = '';
    let statusConnect = '';
    let utilizaVersaoBETA = false;
    if (configWhatsapp !== undefined && configWhatsapp.length > 0) {

      apiKey = configWhatsapp[0].apiKey;
      urlApi = configWhatsapp[0].urlApi;
      sessionclient = configWhatsapp[0].sessionclient;
      statusConnect = configWhatsapp[0].statusConnect;
      utilizaVersaoBETA = configWhatsapp[0].utilizaVersaoBETA;
    }


    AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");
      if (isLastMessage === true) {
        firebase.database().ref(`${chaveUnica}/acoesChamados/${keyChamado}/${keyAcoesChamado}`).update({ deleteMessage: true, /* mensagem: 'Mensagem Apagada', tipo: 'mensagem',  audio: false*/ })
        firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}`).update({ lastMessageChat: 'Mensagem Apagada',/*  lastmessageChatType: 'mensagem' */ })

      } else {
        firebase.database().ref(`${chaveUnica}/acoesChamados/${keyChamado}/${keyAcoesChamado}`).update({ deleteMessage: true,/*  mensagem: 'Mensagem Apagada', tipo: 'mensagem',  audio: false*/ })
      }


      if (viaChat === true && dadosAtendimento.chamadoAbertoPorWpp === true) {
        let data = {
          numeroCliente: dadosAtendimento.telefoneCliente,
          messageid: dadosMensagem.idMensagem,
          apiKey: `${apiKey}`,
          session: `${sessionclient}`,
          urlApi: urlApi,
          chaveUnica: chaveUnica,
        }
        const options = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept': 'application/json',
          },

          data: data,
          url: 'deleteMessageWhatsAppSocket',
        };
        api(options).then((response) => {


        }).catch((err) => {


          if (err.response) {


            if (err.response.status === 400) {
              toastr.error('Error', 'Não foi possivel apagar mensagem.')
            } else {

            }
          } else {

            toastr.error('Error', 'Não foi possivel apagar mensagem.')
          }
        })
      }

    })
  }
}

export const transferiAtendimentoParaSetorChat = (
  keyAtendente,
  keyChamado,
  nomeAtendenteQueIraSerAtribuido,
  setorAtendimento,
  dadosAtendimento,
  viaChat,
  configWhatsapp,
  comentarioTransferencia,
  nomeSetor,
  aberturaViaChat,
  desativarAssistenteVirtualBotWpp2,
  enviarMensagemTransSetorWpp2
) => {
  return dispatch => {
    let enviarMensagemTransSetorWpp = enviarMensagemTransSetorWpp2 === undefined ? true : enviarMensagemTransSetorWpp2;
    let comentarioTransf = comentarioTransferencia === undefined ? '' : comentarioTransferencia;
    let desativarAssistenteVirtualBotWpp = desativarAssistenteVirtualBotWpp2 === undefined ? false : desativarAssistenteVirtualBotWpp2;


    setorAtendimento = setorAtendimento === '' ? "Não informado" : setorAtendimento;
    dadosAtendimento.keySetor = setorAtendimento !== 'Não informado' ? setorAtendimento : dadosAtendimento.keySetor;
    let keyDeQuemAbriuChamado = keyAtendente;
    let dadosChamado = dadosAtendimento;
    let apiKey = '';
    let urlApi = '';
    let sessionclient = '';
    let statusConnect = '';
    let utilizaVersaoBETA = false;
    if (configWhatsapp !== undefined && configWhatsapp.length > 0) {

      apiKey = configWhatsapp[0].apiKey;
      urlApi = configWhatsapp[0].urlApi;
      sessionclient = configWhatsapp[0].sessionclient;
      statusConnect = configWhatsapp[0].statusConnect;
      utilizaVersaoBETA = configWhatsapp[0].utilizaVersaoBETA;
    }
    let keyAtendimento = keyChamado;
    let atendenteAtendimento = keyAtendente;
    const timeAtual = retornaHoraAtual();
    const dataHoraAtual = retornaDataHoraAtual();
    const dataAtualPostagem = retornaDataAtual();
    let dataVencimentoUnix = '';

    AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");
      let mensagem = '';
      if (viaChat == true) {

        if (comentarioTransf !== '') {
          mensagem = `Atendimento transferido para o setor ${nomeSetor}\n\nComentário: ${comentarioTransf}`;
          // mensagem = "Atendimento atribuido ao atendente" + nomeAtendenteQueIraSerAtribuido + '\n' + `Comentário: ${comentarioTransf}`;
        } else {
          mensagem = `Atendimento transferido para o setor ${nomeSetor}`
        }

      }
      else {
        mensagem = `Atendimento transferido para o setor ${nomeSetor}`
      }
      firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}/`).update({
        lastmessageChatStatus: dadosAtendimento.chamadoAbertoPorWpp === true ? 'waiting' : "sent",
        lastMessageChat: mensagem,
        lastMessageChatTimeStamp: firebase.database.ServerValue.TIMESTAMP,
        lastmessageChatCount: 1,
        lastmessageChatType: 'mensagem',
        lastMessageLineColor: '#00FF7F'
      })
      firebase.database().ref(`${chaveUnica}/usuarios/`).orderByChild("uid").equalTo(firebase.auth().currentUser.uid).once("value").then(snapshot => {
        let arrayNomeQuemAbriuChamado = [];
        snapshot.forEach(childItem => {
          arrayNomeQuemAbriuChamado.push({
            nomeDeQuemAbriuChamado: childItem.val().nome
          });
        });
        let keyDeQuemAlterouChamado = Object.keys(snapshot.val()).toString();
        let keyAtendenteQueAlterouChamado = Object.keys(snapshot.val()).toString();
        //novos dados a ser atualizados
        let AbertoVencimento = dadosAtendimento.dataVencimento.toString() == "Não informado" ? 0 : dadosAtendimento.dataVencimento;
        if (AbertoVencimento !== 0) {
          AbertoVencimento = moment(AbertoVencimento, 'DD/MM/YYYY').unix();
          dataVencimentoUnix = AbertoVencimento;
        } else {
          AbertoVencimento = false;
        }
        let AbertoVencimentoAtendente = {};
        let testeObjeto = {};
        //ADICIONAR QUANDO FOR IMPLEMENTAR EM DROPDESKADMIN62
        if (viaChat === true || dadosAtendimento.chamadoAbertoPorWpp === true) {
          firebase.database().ref(`${chaveUnica}/clientes/${dadosAtendimento.keyCliente}`).update({
            statusAndamento: 3
          })
        }
        let Aberto = 'Atendendo';
        AbertoVencimentoAtendente[atendenteAtendimento] = AbertoVencimento;
        testeObjeto[Aberto] = AbertoVencimentoAtendente;
        AbertoVencimento = { Atendendo: AbertoVencimento }

        /*   */
        //

        //let mensagem = viaChat == true ? keyDeQuemAlterouChamado !== keyAtendente ? "Atendimento atribuido ao atendente" + nomeAtendenteQueIraSerAtribuido : `${nomeAtendenteQueIraSerAtribuido} entrou no Chat` : "Atendimento atribuido ao atendente " + nomeAtendenteQueIraSerAtribuido;
        firebase.database().ref(`${chaveUnica}/acoesChamados/${keyChamado}/`).push({
          tipo: "mensagem",
          titulo: arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado,
          mensagem: mensagem,
          dataHoraAtual: dataHoraAtual,
          dataAtualPostagem: dataAtualPostagem,
          time: timeAtual,
          lineColor: "#00FF7F",
          circleColor: "#00FF7F",
          visivelATodos: 1,
          keyDeQuemAlterouChamado
        })
          .then((dadosAcoesChamado) => {
            let keyAcoesChamadoWpp = dadosAcoesChamado.key;
            let dadosAtendVinc = { vazio: 'vazio' };
            //condicional para eu fazer o update do tempo de primeira resposta
            firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}`).update({
              atendente: keyAtendente,
              status: "Aberto",
              status_atendente: `Aberto${keyAtendente}`,
              // usuariosVisualizaAtend: dadosAtendVinc,
              keyAtendenteQueAlterouChamado: keyAtendenteQueAlterouChamado,

              status_cliente: `Aberto${dadosAtendimento.keyCliente}`, //filtro cliente comum
              status_setor: `Aberto${dadosAtendimento.keySetor}`, //filtro setor comum
              status_prioridade: `Aberto${dadosAtendimento.prioridade}`, // filtro prioridade comum
              status_local: `Aberto${dadosAtendimento.local}`, // filtro local comum
              status_dataVencimento: AbertoVencimento, // filtro local comum
              status_numeroChamado: `Aberto${dadosAtendimento.numeroChamado}`,

              status_atendente_cliente: `Aberto${keyAtendente}${dadosAtendimento.keyCliente}`, //filtro cliente vê proprio chamado
              status_atendente_setor: `Aberto${keyAtendente}${dadosAtendimento.keySetor}`, //filtro setor vê proprio chamado
              status_atendente_prioridade: `Aberto${keyAtendente}${dadosAtendimento.prioridade}`, // filtro prioridade vê proprio chamado
              status_atendente_local: `Aberto${keyAtendente}${dadosAtendimento.local}`, // filtro local vê proprio chamado
              // status_atendente_dataVencimento: testeObjeto, // filtro local vê proprio chamado
              status_atendente_numeroChamado: `Aberto${keyAtendente}${dadosAtendimento.numeroChamado}`,

              atendente_cliente: `${keyAtendente}${dadosAtendimento.keyCliente}`,
              atendente_setor: `${keyAtendente}${dadosAtendimento.keySetor}`,
              atendente_prioridade: `${keyAtendente}${dadosAtendimento.prioridade}`,
              atendente_local: `${keyAtendente}${dadosAtendimento.local}`,
              atendente_dataVencimento: AbertoVencimentoAtendente,
              atendente_numeroChamado: `${keyAtendente}${dadosAtendimento.numeroChamado}`,
              dataVencimentoUnix: dataVencimentoUnix,
              //tempoPrimeiraResposta,
              setor: dadosAtendimento.keySetor,
              channelChamado: 'clienteChatWeb',
              status_atendente_abertoviachat: "AbertoNão informadotrue",
              permiteVisualizarWpp: true,
              lastMessageChat: mensagem,
              lastMessageChatTimeStamp: firebase.database.ServerValue.TIMESTAMP,
              lastmessageChatCount: 0,
              lastmessageChatType: 'mensagem',
              lastMessageLineColor: '#00FF7F'
            })
              .then(() => {
                if (setorAtendimento !== "Não informado") {
                  //
                  firebase.database().ref(`${chaveUnica}/setores/${setorAtendimento}/atendentesVinculados`).once('value').then((snapshotSetor) => {
                    dadosAtendVinc = snapshotSetor.val();
                    //    console.log(snapshotSetor.val());

                    let keyAtendentesVinculados = Object.keys(snapshotSetor.val());

                    for (let i = 0; i < keyAtendentesVinculados.length; i++) {
                      if (keyAtendentesVinculados[i].includes('codigoInterno')) {
                        dadosAtendVinc[keyAtendentesVinculados[i] + "status"] = keyAtendentesVinculados[i] + "Aberto";
                      }
                    }

                    if (snapshotSetor.val()) {


                      const dadosAtendentesVinculadosSetor = [];
                      snapshotSetor.forEach((childItem) => {
                        dadosAtendentesVinculadosSetor.push({
                          key: childItem.key,
                        });
                        if (aberturaViaChat == true) {
                          if (childItem.key !== undefined) {
                            if (childItem.key.includes('codigo')) {
                              dadosAtendVinc[`${childItem.key}Chat`] = `${childItem.key}Chat`;
                              dadosAtendVinc[`${childItem.key}Chatstatus`] = `${childItem.key}ChatAberto`;
                            } else {
                              dadosAtendVinc[`${childItem.key}Chat`] = true;
                            }
                          }
                          /*  dadosAtendVinc[`${childItem.key}Chat`] = true; //retirar depois de 1 mes
                           dadosAtendVinc[`${childItem.key}Chat`] = `${childItem.key}Chat`; */
                        }
                      })
                      //
                      firebase.database().ref(`${chaveUnica}/chamados/${dadosChamado.key}/usuariosVisualizaAtend`).update({ dadosAtendVinc })
                    } else {
                      //
                      firebase.database().ref(`${chaveUnica}/usuarios/`).orderByChild('excluido').equalTo(null).once('value').then((snapshot) => {
                        //
                        const idTodosAtendentes = [];
                        let objetocadastro = {};
                        snapshot.forEach((childItem) => {
                          idTodosAtendentes.push({
                            key: childItem.key,
                            codigoInterno: childItem.val().codigoInterno == undefined ? 'codigoInterno1' : childItem.val().codigoInterno,
                          });
                        })
                        //
                        for (let i = 0; i < idTodosAtendentes.length; i++) {
                          //objetocadastro[idTodosAtendentes[i].key] = true;
                          objetocadastro[idTodosAtendentes[i].codigoInterno] = idTodosAtendentes[i].codigoInterno;
                          objetocadastro[idTodosAtendentes[i].codigoInterno + "status"] = idTodosAtendentes[i].codigoInterno + "Aberto";

                          if (aberturaViaChat == true) {
                            objetocadastro[`${idTodosAtendentes[i].key}Chat`] = true;
                            objetocadastro[`${idTodosAtendentes[i].codigoInterno}Chat`] = `${idTodosAtendentes[i].codigoInterno}Chat`;
                          }
                        }
                        //
                        firebase.database().ref(`${chaveUnica}/chamados/${dadosChamado.key}/usuariosVisualizaAtend/dadosAtendVinc`).update(objetocadastro)
                        //enviaChamadoAberto(dispatch, chamado.concat(chamadosAnteriores));
                      })

                    }
                  })
                } else {
                  //verifica se o atendente é dierente de naão informado, porque se for, preciso criar o campo atendentes vinculados pra esse atendente visualizar este atendimento
                  //
                  firebase.database().ref(`${chaveUnica}/usuarios/`).orderByChild('excluido').equalTo(null).once('value').then((snapshot) => {
                    //
                    const idTodosAtendentes = [];
                    let objetocadastro = {};
                    snapshot.forEach((childItem) => {
                      idTodosAtendentes.push({
                        key: childItem.key,
                        codigoInterno: childItem.val().codigoInterno == undefined ? 'codigoInterno1' : childItem.val().codigoInterno,
                      });
                    })
                    //
                    for (let i = 0; i < idTodosAtendentes.length; i++) {
                      // objetocadastro[idTodosAtendentes[i].key] = true;
                      objetocadastro[idTodosAtendentes[i].codigoInterno] = idTodosAtendentes[i].codigoInterno;
                      objetocadastro[idTodosAtendentes[i].codigoInterno + "status"] = idTodosAtendentes[i].codigoInterno + "Aberto";
                      if (aberturaViaChat == true) {
                        objetocadastro[`${idTodosAtendentes[i].key}Chat`] = true;
                        objetocadastro[`${idTodosAtendentes[i].codigoInterno}Chat`] = `${idTodosAtendentes[i].codigoInterno}Chat`;
                      }
                    }
                    //
                    firebase.database().ref(`${chaveUnica}/chamados/${dadosChamado.key}/usuariosVisualizaAtend/dadosAtendVinc`).update(objetocadastro)
                    //enviaChamadoAberto(dispatch, chamado.concat(chamadosAnteriores));
                  })
                  /* let objetocadastro = {}
                  if (atendenteAtendimento != "Não informado") {


                    objetocadastro[atendenteAtendimento] = true;

                    let dadosAtendVinc = objetocadastro;
                    firebase.database().ref(`${chaveUnica}/chamados/${dadosChamado.key}/usuariosVisualizaAtend/`).update({dadosAtendVinc})
                  } */
                }
                dispatch({
                  type: MODIFICAKEYATENDIMENTOEMESPERAATENDIMENTOONLINE,
                  payload: ''
                })
                dispatch({
                  type: MODIFICAKEYCONTACTS,
                  payload: ''
                })
                dispatch({
                  type: MODIFICAKEYATENDIMENTOEMTENDIMENTOONLINE,
                  payload: keyAtendimento
                })
                if (viaChat === true && dadosAtendimento.chamadoAbertoPorWpp === true && desativarAssistenteVirtualBotWpp === false && enviarMensagemTransSetorWpp === true) {
                  let data = {
                    numeroCliente: dadosAtendimento.telefoneCliente,
                    text: `Você foi transferido para o setor ${nomeSetor}, aguarde um momento que logo um atendente irá lhe atender`,
                    apiKey: `${apiKey}`,
                    session: `${sessionclient}`,
                    urlApi: urlApi,
                    type: 'text',
                    keyAcoesChamado: keyAcoesChamadoWpp,
                    keyChamado: keyChamado,
                    chaveUnica: chaveUnica,
                    file: null,
                    responder: '',
                    idMensagemResponder: '',
                    nomeAtendente: 'Assistente Virtual',
                    utilizaVersaoBETA
                  }
                  const options = {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json; charset=utf-8',
                      'Accept': 'application/json',
                    },

                    data: data,
                    url: 'enviaMensagemWhatsappSocket',
                  };
                  api(options).then((response) => {

                    firebase.database().ref(`${chaveUnica}/acoesChamados/${keyChamado}/${keyAcoesChamadoWpp}`)
                      .update({ statusMessage: 'sent' }).then(() => {
                        firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}/`).update({ lastmessageChatStatus: 'sent', lastMessageLineColor: '#00FF7F' })
                      });
                  }).catch((err) => {


                    if (err.response) {


                      if (err.response.status === 400) {
                        toastr.error('Telefone Desconectado', 'Seu Telefone está sem Conexão com a Internet, verifique a conexão do seu Aparelho e tente novamente.')
                      } else {

                      }
                    } else {

                      toastr.error('Telefone Desconectado', 'Seu Telefone está sem Conexão com a Internet, verifique a conexão do seu Aparelho e tente novamente.')
                    }
                  })
                }
              });


          });
      });
    });
  };
};
export const comentaEAtribuiAtendimento = (arrayDadosParaAtribuirChamado, arrayDadosParaComentar) => {
  return dispatch => {




    dispatch(atribuiAtendimentoAh(...arrayDadosParaAtribuirChamado));


    setTimeout(() => {
      dispatch(salvarComentario(...arrayDadosParaComentar))
    }, 1000);




  }
};
export const enviaMensagemEncaminhada = (arrayUsuariosParaEnviarMensagem, mensagemEncaminhada, nomeUsuario, keyDeQuemAlterouChamado, configWhatsapp,
  desativarAssistenteVirtualBotWpp2, mostrarNomeAtendenteWpp2, chatId) => {


  let desativarAssistenteVirtualBotWpp = desativarAssistenteVirtualBotWpp2 === undefined ? false : desativarAssistenteVirtualBotWpp2;
  let mostrarNomeAtendenteWpp = mostrarNomeAtendenteWpp2 === undefined ? true : mostrarNomeAtendenteWpp2;


  return dispatch => {
    AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");
      let apiKey = '';
      let urlApi = '';
      let sessionclient = '';
      let statusConnect = '';
      let arrayNumerosCompartilharMensagem = [];
      let arrayKeyAcoesChamadoCompartilhaMensagem = [];
      let arrayKeyChamadosCompartilharMensagem = [];
      let idMensagemWpp = '';



      let utilizaVersaoBETA = false;
      if (configWhatsapp !== undefined && configWhatsapp.length > 0) {

        apiKey = configWhatsapp[0].apiKey;
        urlApi = configWhatsapp[0].urlApi;
        sessionclient = configWhatsapp[0].sessionclient;
        statusConnect = configWhatsapp[0].statusConnect;
        utilizaVersaoBETA = configWhatsapp[0].utilizaVersaoBETA;
      }

      const timeAtual = retornaHoraAtual();
      const dataHoraAtual = retornaDataHoraAtual();
      const dataAtualPostagem = retornaDataAtual();
      const promises = arrayUsuariosParaEnviarMensagem.map(async (usuario) => {


        if (usuario.status) {
          if (usuario.chamadoAbertoPorWpp === true) {
            //chamado proveniente do Whatsapp
            if (usuario.telefoneCliente !== 'Não informado') {
              arrayKeyChamadosCompartilharMensagem.push(usuario.key);
            }
          }
          return firebase.database().ref(`${chaveUnica}/acoesChamados/${usuario.key}/`).push({
            alterado: 0,
            audio: mensagemEncaminhada[0].audio,
            duracao: mensagemEncaminhada[0].duracao,
            tipo: mensagemEncaminhada[0].tipo,
            titulo: nomeUsuario,
            mensagem: mensagemEncaminhada[0].description,
            dataHoraAtual: dataHoraAtual,
            dataAtualPostagem: dataAtualPostagem,
            time: timeAtual,
            lineColor: "#00FF7F",
            circleColor: "#00FF7F",
            visivelATodos: 1,
            keyDeQuemAlterouChamado,
            statusMessage: 'sent',
            idMensagemResponder: '',
            possuiResposta: false,
            mensagemResposta: '',
            circleColorResponder: '',
            commentResponderType: '',
            idMensagem: '',
            tipoArquivo: mensagemEncaminhada[0].tipoArquivo === undefined ? '' : mensagemEncaminhada[0].tipoArquivo,
            urlDownload: mensagemEncaminhada[0].urlDownload === undefined ? '' : mensagemEncaminhada[0].urlDownload,
            compartilhada: true,

          }).then((keyAcoes) => {
            return firebase.database().ref(`${chaveUnica}/chamados/${usuario.key}`).update({
              lastMessageChat: mensagemEncaminhada[0].description,
              lastMessageChatTimeStamp: firebase.database.ServerValue.TIMESTAMP,
              lastmessageChatCount: 0,
              lastmessageChatType: mensagemEncaminhada[0].tipo,
              lastMessageLineColor: '#00FF7F'
            }).then(() => {
              let key = { key: keyAcoes.key }
              return key;
            });
          })
        } else {
          let key = { key: 'semkey' }
          return key;
        }
      });
      /* Promise.all(promises); */
      Promise.all(promises).then(responses => {


        if (responses) {
          for (let i = 0; i < responses.length; i++) {


            if (responses[i].key == 'semKey') {

            } else {

              if (arrayUsuariosParaEnviarMensagem[i].chamadoAbertoPorWpp === true) {
                //chamado proveniente do Whatsapp
                if (arrayUsuariosParaEnviarMensagem[i].telefoneCliente !== 'Não informado') {
                  arrayKeyAcoesChamadoCompartilhaMensagem.push(responses[i].key)
                }
              }
              if (arrayUsuariosParaEnviarMensagem[i].chamadoAbertoPorWpp === false) {
                firebase.database().ref(`${chaveUnica}/acoesChamados/${arrayUsuariosParaEnviarMensagem[i].key}/${responses[i].key}`).update({ statusMessage: 'sent' }).then(() => {
                  firebase.database().ref(`${chaveUnica}/chamados/${arrayUsuariosParaEnviarMensagem[i].key}/`).update({ lastmessageChatStatus: 'sent', lastMessageLineColor: '#00FF7F' })
                });
              } else {
                // firebase.database().ref(`${chaveUnica}/acoesChamados/${arrayUsuariosParaEnviarMensagem[i].key}/${responses[i].key}`).update({ statusMessage: 'sent' })
              }
            }
          }



          for (let i = 0; i < arrayUsuariosParaEnviarMensagem.length; i++) {
            if (arrayUsuariosParaEnviarMensagem[i].chamadoAbertoPorWpp === true) {
              //chamado proveniente do Whatsapp
              if (arrayUsuariosParaEnviarMensagem[i].telefoneCliente !== 'Não informado') {
                arrayNumerosCompartilharMensagem.push(arrayUsuariosParaEnviarMensagem[i].telefoneCliente)
              }

            } else {
              if (arrayUsuariosParaEnviarMensagem[i].phone) {
                arrayNumerosCompartilharMensagem.push(arrayUsuariosParaEnviarMensagem[i].phone)
              }
            }
          }










          if (mensagemEncaminhada[0].idMensagem.includes('@c.us') || !mensagemEncaminhada[0].idMensagem.includes('-') && mensagemEncaminhada[0].idMensagem !== "") {
            //envio mensagem compartilhada whatsapp
            let data = {
              numeroCliente: arrayNumerosCompartilharMensagem,
              text: mensagemEncaminhada[0].tipo !== 'mensagem' ? mensagemEncaminhada[0].description : '',
              apiKey: `${apiKey}`,
              session: `${sessionclient}`,
              urlApi: urlApi,
              type: mensagemEncaminhada[0].tipo === 'mensagem' ? 'text' : mensagemEncaminhada[0].tipoArquivo,
              keyAcoesChamado: mensagemEncaminhada[0].key,
              keyChamado: mensagemEncaminhada[0].keyChamado,
              arrayKeyAcoesChamado: arrayKeyAcoesChamadoCompartilhaMensagem,
              arrayKeyChamado: arrayKeyChamadosCompartilharMensagem,
              chaveUnica: chaveUnica,
              file: `${chaveUnica}/${mensagemEncaminhada[0].keyChamado}/${mensagemEncaminhada[0].keyChamado}/${mensagemEncaminhada[0].description}`,
              responder: false,
              idMensagemResponder: '',
              idMensagemForward: mensagemEncaminhada[0].idMensagem,
              enviarForward: true,
              nomeAtendente: nomeUsuario,
              desativarAssistenteVirtualBotWpp: desativarAssistenteVirtualBotWpp,
              mostrarNomeAtendenteWpp: mostrarNomeAtendenteWpp,
              chatId: chatId
            }


            const options = {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'Accept': 'application/json',
              },

              data: data,
              url: 'compartilharMensagemWppSocket',
            };
            api(options).then((response) => {


            }).catch((err) => {


            })
          } else {
            //envio mensagem compartilhada whatsapp porem como se fosse uma nova mensagem

            let typeFile = 'text';

            if (mensagemEncaminhada[0].tipoArquivo) {
              if (mensagemEncaminhada[0].tipoArquivo.includes('image')) {
                typeFile = 'image';
              } else if (mensagemEncaminhada[0].tipoArquivo.includes('audio')) {
                typeFile = 'audio';
              } else if (mensagemEncaminhada[0].tipoArquivo.includes('video')) {
                typeFile = 'video';
              } else {
                typeFile = 'file';
              }
            } else {
              typeFile = 'text';
            }


            let data = {
              numeroCliente: arrayNumerosCompartilharMensagem,
              text: mensagemEncaminhada[0].description,
              apiKey: `${apiKey}`,
              session: `${sessionclient}`,
              urlApi: urlApi,
              type: typeFile,
              keyAcoesChamado: mensagemEncaminhada[0].key,
              keyChamado: mensagemEncaminhada[0].keyChamado,
              arrayKeyAcoesChamado: arrayKeyAcoesChamadoCompartilhaMensagem,
              arrayKeyChamado: arrayKeyChamadosCompartilharMensagem,
              chaveUnica: chaveUnica,
              file: `${chaveUnica}/${mensagemEncaminhada[0].keyChamado}/${mensagemEncaminhada[0].keyChamado}/${mensagemEncaminhada[0].description}`,
              responder: false,
              idMensagemResponder: '',
              enviarForward: false,
              nomeAtendente: nomeUsuario,
              chatId: chatId
            }
            const options = {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'Accept': 'application/json',
              },

              data: data,
              url: 'compartilharMensagemWppSocket',
            };
            api(options).then((response) => {


            }).catch((err) => {


            })
          }
        }
      });
    })
  }
}
export const listaConversasAntigasWppContacts = (valorReferenciaInicial, phone, oldReferenceKey, conversasAnteriores,
  agruparAtendenteSetor, contaPrincipal, adminVisualizaApenasProprioSetor, keyAtendente, atendenteVeApenasProprioHistoricoChamado) => {
  return dispatch => {
    ativaDesativaLoadingCarregaRespostasAntigas(dispatch, true);

    if (valorReferenciaInicial === 0) {
      ativaDesativaLoadingCarregaChamadosAntigos(dispatch, true);
    } else {
      ativaDesativaLoadingCarregaMaisChamadosAntigos(dispatch, true);
    }

    AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");
      if (valorReferenciaInicial === 0) {
        //verifica se cliente está cadastrado, se não estiver, já seto que não tem conversa anterior,
        // porém se tiver eu procuro o ultimo chamado pra carregar conversa
        firebase.database().ref(`${chaveUnica}/clientes/`).orderByChild('telefoneCliente').equalTo(phone).once('value').then((snapshot) => {
          let keyCliente = '';
          if (snapshot.val()) {
            snapshot.forEach(childItem => {
              keyCliente = childItem.key;
            })


            if (atendenteVeApenasProprioHistoricoChamado === true && contaPrincipal === false) {
              //aqui o atendente ve apenas os chamados do setor relacionado
              //
              firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild('atendente_cliente').equalTo(`${keyAtendente}${keyCliente}`).limitToLast(2).once('value').then((snapshot) => {
                if (snapshot.val()) {


                  let arrayOfKeys = Object.keys(snapshot.val()).sort().reverse();

                  let referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];

                  let arrayAcoesAtendimento = [];
                  let arrayDadosAberturaChamado = [];
                  let keyAtendimento = '';
                  let verificaSeColocoInformacoesFinaisAtendimento = false;
                  let clienteComSomente1chamado = false;
                  let statusChamado = '';
                  let dataFechamentoChamado = '';
                  let numeroChamado = '';
                  let aux = 0;

                  snapshot.forEach(childItem => {
                    if (aux === 0 && snapshot.numChildren() === 1 || aux === 1 && snapshot.numChildren() === 2) {

                      keyAtendimento = childItem.key;
                      verificaSeColocoInformacoesFinaisAtendimento = childItem.val().status === 'Aberto' || childItem.val().status === 'Atendendo' ? false : true;
                      statusChamado = childItem.val().status;
                      dataFechamentoChamado = childItem.val().status === 'Fechado' ? childItem.val().dataHoraFechamento : childItem.val().dataHoraCancelamento;
                      numeroChamado = childItem.val().numeroChamado;
                      arrayAcoesAtendimento.push({
                        key: childItem.key + 'inicio',
                        title: `Inicio do Atendimento: #${childItem.val().numeroChamado} - ${childItem.val().dataHoraAbertura}`,
                        tipo: 'mensagem',
                        description: `Inicio do Atendimento: #${childItem.val().numeroChamado} - ${childItem.val().dataHoraAbertura}`,
                        statusMessage: 'sent',
                        time: "09:50",
                        dataHoraAtual: '01/01/2099',
                        lineColor: '#00FF7F',
                        circleColor: '#00FF7F',
                        visivelATodos: 1,

                        //  dataHoraAbertura: `Inicio do Atendimento: #${childItem.val().numeroChamado} - ${childItem.val().dataHoraAbertura}`,
                        //  dataHoraFechamneto: `Fim do Atendimento: #${childItem.val().numeroChamado} - ${childItem.val().dataHoraFechamento}`,
                        // dataHoraCancelamento: `Fim do Atendimento: #${childItem.val().numeroChamado} - ${childItem.val().dataHoraCancelamento}`,
                      });
                      if (snapshot.numChildren() === 1) {
                        clienteComSomente1chamado = true;
                      }
                    }
                    aux++;
                  })

                  firebase.database().ref(`${chaveUnica}/acoesChamados/${keyAtendimento}`).once('value').then((snapshot2) => {

                    /*  */
                    snapshot2.forEach(childItem => {
                      /*    console.log(childItem.val()) */
                      if (childItem.val().tipo !== undefined) {
                        arrayAcoesAtendimento.push({
                          key: childItem.key,
                          title: childItem.val().titulo,
                          tipo: childItem.val().tipo,
                          description: childItem.val().mensagem,
                          statusMessage: childItem.val().statusMessage == undefined ? 'sent' : childItem.val().statusMessage,
                          time: childItem.val().time,
                          dataHoraAtual: childItem.val().dataHoraAtual == undefined ? childItem.val().mensagem : childItem.val().dataHoraAtual,
                          lineColor: childItem.val().lineColor,
                          circleColor: childItem.val().circleColor,
                          urlDownload: childItem.val().urlDownload,
                          tipoArquivo: childItem.val().tipoArquivo,
                          duracao: childItem.val().duracao == undefined ? '' : childItem.val().duracao,
                          audio: childItem.val().audio == undefined ? false : childItem.val().audio,
                          visivelATodos: childItem.val().visivelATodos == undefined ? 1 : childItem.val().visivelATodos,
                          idMensagem: childItem.val().idMensagem == undefined ? childItem.key : childItem.val().idMensagem === '' ? childItem.key : childItem.val().idMensagem,
                          circleColorResponder: childItem.val().circleColorResponder == undefined ? '#00FF7F' : childItem.val().circleColorResponder,
                          commentResponderType: childItem.val().commentResponderType == undefined ? '' : childItem.val().commentResponderType,
                          idMensagemResponder: childItem.val().idMensagemResponder == undefined ? '' : childItem.val().idMensagemResponder,
                          mensagemResposta: childItem.val().mensagemResposta == undefined ? '' : childItem.val().mensagemResposta,
                          possuiResposta: childItem.val().possuiResposta == undefined ? false : childItem.val().possuiResposta,
                          commentResponderTypeArquivo: childItem.val().commentResponderTypeArquivo == undefined ? '' : childItem.val().commentResponderTypeArquivo,
                          commentResponderIsAudio: childItem.val().commentResponderIsAudio == undefined ? '' : childItem.val().commentResponderIsAudio,
                          atendenteResponder: childItem.val().atendenteResponder == undefined ? '' : childItem.val().atendenteResponder,
                          captionArquivo: childItem.val().captionArquivo == undefined ? '' : childItem.val().captionArquivo,
                          read: childItem.val().read == undefined ? false : childItem.val().read,
                          deleteMessage: childItem.val().deleteMessage == undefined ? false : childItem.val().deleteMessage,
                          edited: childItem.val().edited ?? false,
                        });
                      }
                    });
                    if (verificaSeColocoInformacoesFinaisAtendimento) {
                      arrayAcoesAtendimento.push({
                        key: keyAtendimento + 'Fim',
                        title: `Fim do Atendimento: #${numeroChamado} - ${dataFechamentoChamado}`,
                        tipo: 'mensagem',
                        description: `Fim do Atendimento: #${numeroChamado} - ${dataFechamentoChamado}`,
                        statusMessage: 'sent',
                        time: "09:50",
                        dataHoraAtual: '01/01/2099',
                        lineColor: '#00FF7F',
                        circleColor: '#00FF7F',
                        visivelATodos: 1,
                      });

                    }

                    //ativaDesativaLoadingTelaAcoesAtendimento(dispatch, false);
                    enviaConversasUltimoChamado(dispatch, arrayAcoesAtendimento.reverse());
                    ativaDesativaLoadingCarregaChamadosAntigos(dispatch, false);
                    ativaDesativaLoadingCarregaRespostasAntigas(dispatch, false);
                    if (clienteComSomente1chamado) {
                      enviaUltimaReeferenciaKeyContacts(dispatch, undefined)
                    } else {
                      enviaUltimaReeferenciaKeyContacts(dispatch, referenceToOldestKey)
                    }


                  });
                } else {
                  ativaDesativaLoadingCarregaChamadosAntigos(dispatch, false);
                  ativaDesativaLoadingCarregaRespostasAntigas(dispatch, false);
                  enviaUltimaReeferenciaKeyContacts(dispatch, undefined)
                }
                /*  enviaConversasUltimoChamado(dispatch, arrayAcoesAtendimento.reverse()); */

              })
            } else {
              firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild('cliente').equalTo(keyCliente).limitToLast(2).once('value').then((snapshot) => {
                if (snapshot.val()) {


                  let arrayOfKeys = Object.keys(snapshot.val()).sort().reverse();

                  let referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];

                  let arrayAcoesAtendimento = [];
                  let arrayDadosAberturaChamado = [];
                  let keyAtendimento = '';
                  let verificaSeColocoInformacoesFinaisAtendimento = false;
                  let clienteComSomente1chamado = false;
                  let statusChamado = '';
                  let dataFechamentoChamado = '';
                  let numeroChamado = '';
                  let aux = 0;

                  snapshot.forEach(childItem => {
                    if (aux === 0 && snapshot.numChildren() === 1 || aux === 1 && snapshot.numChildren() === 2) {

                      keyAtendimento = childItem.key;
                      verificaSeColocoInformacoesFinaisAtendimento = childItem.val().status === 'Aberto' || childItem.val().status === 'Atendendo' ? false : true;
                      statusChamado = childItem.val().status;
                      dataFechamentoChamado = childItem.val().status === 'Fechado' ? childItem.val().dataHoraFechamento : childItem.val().dataHoraCancelamento;
                      numeroChamado = childItem.val().numeroChamado;
                      arrayAcoesAtendimento.push({
                        key: childItem.key + 'inicio',
                        title: `Inicio do Atendimento: #${childItem.val().numeroChamado} - ${childItem.val().dataHoraAbertura}`,
                        tipo: 'mensagem',
                        description: `Inicio do Atendimento: #${childItem.val().numeroChamado} - ${childItem.val().dataHoraAbertura}`,
                        statusMessage: 'sent',
                        time: "09:50",
                        dataHoraAtual: '01/01/2099',
                        lineColor: '#00FF7F',
                        circleColor: '#00FF7F',
                        visivelATodos: 1,
                        //  dataHoraAbertura: `Inicio do Atendimento: #${childItem.val().numeroChamado} - ${childItem.val().dataHoraAbertura}`,
                        //  dataHoraFechamneto: `Fim do Atendimento: #${childItem.val().numeroChamado} - ${childItem.val().dataHoraFechamento}`,
                        // dataHoraCancelamento: `Fim do Atendimento: #${childItem.val().numeroChamado} - ${childItem.val().dataHoraCancelamento}`,
                      });
                      if (snapshot.numChildren() === 1) {
                        clienteComSomente1chamado = true;
                      }
                    }
                    aux++;
                  })

                  firebase.database().ref(`${chaveUnica}/acoesChamados/${keyAtendimento}`).once('value').then((snapshot2) => {

                    /*  */
                    snapshot2.forEach(childItem => {
                      /*    console.log(childItem.val()) */
                      if (childItem.val().tipo !== undefined) {
                        arrayAcoesAtendimento.push({
                          key: childItem.key,
                          title: childItem.val().titulo,
                          tipo: childItem.val().tipo,
                          description: childItem.val().mensagem,
                          statusMessage: childItem.val().statusMessage == undefined ? 'sent' : childItem.val().statusMessage,
                          time: childItem.val().time,
                          dataHoraAtual: childItem.val().dataHoraAtual == undefined ? childItem.val().mensagem : childItem.val().dataHoraAtual,
                          lineColor: childItem.val().lineColor,
                          circleColor: childItem.val().circleColor,
                          urlDownload: childItem.val().urlDownload,
                          tipoArquivo: childItem.val().tipoArquivo,
                          duracao: childItem.val().duracao == undefined ? '' : childItem.val().duracao,
                          audio: childItem.val().audio == undefined ? false : childItem.val().audio,
                          visivelATodos: childItem.val().visivelATodos == undefined ? 1 : childItem.val().visivelATodos,
                          idMensagem: childItem.val().idMensagem == undefined ? childItem.key : childItem.val().idMensagem === '' ? childItem.key : childItem.val().idMensagem,
                          circleColorResponder: childItem.val().circleColorResponder == undefined ? '#00FF7F' : childItem.val().circleColorResponder,
                          commentResponderType: childItem.val().commentResponderType == undefined ? '' : childItem.val().commentResponderType,
                          idMensagemResponder: childItem.val().idMensagemResponder == undefined ? '' : childItem.val().idMensagemResponder,
                          mensagemResposta: childItem.val().mensagemResposta == undefined ? '' : childItem.val().mensagemResposta,
                          possuiResposta: childItem.val().possuiResposta == undefined ? false : childItem.val().possuiResposta,
                          commentResponderTypeArquivo: childItem.val().commentResponderTypeArquivo == undefined ? '' : childItem.val().commentResponderTypeArquivo,
                          commentResponderIsAudio: childItem.val().commentResponderIsAudio == undefined ? '' : childItem.val().commentResponderIsAudio,
                          atendenteResponder: childItem.val().atendenteResponder == undefined ? '' : childItem.val().atendenteResponder,
                          captionArquivo: childItem.val().captionArquivo == undefined ? '' : childItem.val().captionArquivo,
                          read: childItem.val().read == undefined ? false : childItem.val().read,
                          deleteMessage: childItem.val().deleteMessage == undefined ? false : childItem.val().deleteMessage,
                          edited: childItem.val().edited ?? false,
                        });
                      }
                    });
                    if (verificaSeColocoInformacoesFinaisAtendimento) {
                      arrayAcoesAtendimento.push({
                        key: keyAtendimento + 'Fim',
                        title: `Fim do Atendimento: #${numeroChamado} - ${dataFechamentoChamado}`,
                        tipo: 'mensagem',
                        description: `Fim do Atendimento: #${numeroChamado} - ${dataFechamentoChamado}`,
                        statusMessage: 'sent',
                        time: "09:50",
                        dataHoraAtual: '01/01/2099',
                        lineColor: '#00FF7F',
                        circleColor: '#00FF7F',
                        visivelATodos: 1,
                      });

                    }

                    //ativaDesativaLoadingTelaAcoesAtendimento(dispatch, false);
                    enviaConversasUltimoChamado(dispatch, arrayAcoesAtendimento.reverse());
                    ativaDesativaLoadingCarregaChamadosAntigos(dispatch, false);
                    ativaDesativaLoadingCarregaRespostasAntigas(dispatch, false);
                    if (clienteComSomente1chamado) {
                      enviaUltimaReeferenciaKeyContacts(dispatch, undefined)
                    } else {
                      enviaUltimaReeferenciaKeyContacts(dispatch, referenceToOldestKey)
                    }


                  });
                } else {
                  ativaDesativaLoadingCarregaChamadosAntigos(dispatch, false);
                  ativaDesativaLoadingCarregaRespostasAntigas(dispatch, false);
                  enviaUltimaReeferenciaKeyContacts(dispatch, undefined)
                }
                /*  enviaConversasUltimoChamado(dispatch, arrayAcoesAtendimento.reverse()); */

              })
            }
          } else {

            enviaConversasUltimoChamado(dispatch, []);
            ativaDesativaLoadingCarregaChamadosAntigos(dispatch, false);
            ativaDesativaLoadingCarregaRespostasAntigas(dispatch, false);
            enviaUltimaReeferenciaKeyContacts(dispatch, undefined)
          }
        })

      } else {
        if (oldReferenceKey != undefined && oldReferenceKey != "") {

          firebase.database().ref(`${chaveUnica}/clientes/`).orderByChild('telefoneCliente').equalTo(phone).once('value').then((snapshot) => {
            let keyCliente = '';
            if (snapshot.val()) {
              snapshot.forEach(childItem => {
                keyCliente = childItem.key;
              })

              if (atendenteVeApenasProprioHistoricoChamado === true && contaPrincipal === false) {
                firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild("atendente_cliente").startAt(`${keyAtendente}${keyCliente}`).endAt(`${keyAtendente}${keyCliente}`, oldReferenceKey)
                  .limitToLast(2).once('value').then((snapshot) => {

                    if (snapshot.val()) {
                      let arrayOfKeys = Object.keys(snapshot.val()).sort().reverse().slice(1);
                      let referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];


                      let arrayAcoesAtendimento = [];
                      let arrayDadosAberturaChamado = [];
                      let keyAtendimento = '';
                      let verificaSeColocoInformacoesFinaisAtendimento = false;
                      let statusChamado = '';
                      let dataFechamentoChamado = '';
                      let numeroChamado = '';
                      let aux = 0;
                      snapshot.forEach(childItem => {
                        if (aux === 0 && snapshot.numChildren() === 1 || aux === 1 && snapshot.numChildren() === 2) {
                          keyAtendimento = childItem.key;
                          verificaSeColocoInformacoesFinaisAtendimento = childItem.val().status === 'Aberto' || childItem.val().status === 'Atendendo' ? false : true;
                          statusChamado = childItem.val().status;
                          dataFechamentoChamado = childItem.val().status === 'Fechado' ? childItem.val().dataHoraFechamento : childItem.val().dataHoraCancelamento;
                          numeroChamado = childItem.val().numeroChamado;
                          arrayAcoesAtendimento.push({
                            key: childItem.key + 'inicio',
                            title: `Inicio do Atendimento: #${childItem.val().numeroChamado} - ${childItem.val().dataHoraAbertura}`,
                            tipo: 'mensagem',
                            description: `Inicio do Atendimento: #${childItem.val().numeroChamado} - ${childItem.val().dataHoraAbertura}`,
                            statusMessage: 'sent',
                            time: "09:50",
                            dataHoraAtual: '01/01/2099',
                            lineColor: '#00FF7F',
                            circleColor: '#00FF7F',
                            visivelATodos: 1,
                            //  dataHoraAbertura: `Inicio do Atendimento: #${childItem.val().numeroChamado} - ${childItem.val().dataHoraAbertura}`,
                            //  dataHoraFechamneto: `Fim do Atendimento: #${childItem.val().numeroChamado} - ${childItem.val().dataHoraFechamento}`,
                            // dataHoraCancelamento: `Fim do Atendimento: #${childItem.val().numeroChamado} - ${childItem.val().dataHoraCancelamento}`,
                          });
                        }
                        aux++;
                      })

                      firebase.database().ref(`${chaveUnica}/acoesChamados/${keyAtendimento}`).once('value').then((snapshot2) => {

                        /*  */
                        snapshot2.forEach(childItem => {
                          /*    console.log(childItem.val()) */
                          if (childItem.val().tipo !== undefined) {
                            arrayAcoesAtendimento.push({
                              key: childItem.key,
                              title: childItem.val().titulo,
                              tipo: childItem.val().tipo,
                              description: childItem.val().mensagem,
                              statusMessage: childItem.val().statusMessage == undefined ? 'sent' : childItem.val().statusMessage,
                              time: childItem.val().time,
                              dataHoraAtual: childItem.val().dataHoraAtual == undefined ? childItem.val().mensagem : childItem.val().dataHoraAtual,
                              lineColor: childItem.val().lineColor,
                              circleColor: childItem.val().circleColor,
                              urlDownload: childItem.val().urlDownload,
                              tipoArquivo: childItem.val().tipoArquivo,
                              duracao: childItem.val().duracao == undefined ? '' : childItem.val().duracao,
                              audio: childItem.val().audio == undefined ? false : childItem.val().audio,
                              visivelATodos: childItem.val().visivelATodos == undefined ? 1 : childItem.val().visivelATodos,
                              idMensagem: childItem.val().idMensagem == undefined ? childItem.key : childItem.val().idMensagem === '' ? childItem.key : childItem.val().idMensagem,
                              circleColorResponder: childItem.val().circleColorResponder == undefined ? '#00FF7F' : childItem.val().circleColorResponder,
                              commentResponderType: childItem.val().commentResponderType == undefined ? '' : childItem.val().commentResponderType,
                              idMensagemResponder: childItem.val().idMensagemResponder == undefined ? '' : childItem.val().idMensagemResponder,
                              mensagemResposta: childItem.val().mensagemResposta == undefined ? '' : childItem.val().mensagemResposta,
                              possuiResposta: childItem.val().possuiResposta == undefined ? false : childItem.val().possuiResposta,
                              commentResponderTypeArquivo: childItem.val().commentResponderTypeArquivo == undefined ? '' : childItem.val().commentResponderTypeArquivo,
                              commentResponderIsAudio: childItem.val().commentResponderIsAudio == undefined ? '' : childItem.val().commentResponderIsAudio,
                              atendenteResponder: childItem.val().atendenteResponder == undefined ? '' : childItem.val().atendenteResponder,
                              captionArquivo: childItem.val().captionArquivo == undefined ? '' : childItem.val().captionArquivo,
                              read: childItem.val().read == undefined ? false : childItem.val().read,
                              deleteMessage: childItem.val().deleteMessage == undefined ? false : childItem.val().deleteMessage,
                              edited: childItem.val().edited ?? false,
                            });
                          }
                        });
                        if (verificaSeColocoInformacoesFinaisAtendimento) {
                          arrayAcoesAtendimento.push({
                            key: keyAtendimento + 'Fim',
                            title: `Fim do Atendimento: #${numeroChamado} - ${dataFechamentoChamado}`,
                            tipo: 'mensagem',
                            description: `Fim do Atendimento: #${numeroChamado} - ${dataFechamentoChamado}`,
                            statusMessage: 'sent',
                            time: "09:50",
                            dataHoraAtual: '01/01/2099',
                            lineColor: '#00FF7F',
                            circleColor: '#00FF7F',
                            visivelATodos: 1,
                          });

                        }

                        //ativaDesativaLoadingTelaAcoesAtendimento(dispatch, false);
                        enviaConversasUltimoChamado(dispatch, conversasAnteriores.concat(arrayAcoesAtendimento.reverse()));
                        ativaDesativaLoadingCarregaChamadosAntigos(dispatch, false);
                        ativaDesativaLoadingCarregaRespostasAntigas(dispatch, false);
                        enviaUltimaReeferenciaKeyContacts(dispatch, referenceToOldestKey);
                        ativaDesativaLoadingCarregaMaisChamadosAntigos(dispatch, false);

                      });
                    } else {
                      ativaDesativaLoadingCarregaChamadosAntigos(dispatch, false);
                      ativaDesativaLoadingCarregaRespostasAntigas(dispatch, false);
                      enviaUltimaReeferenciaKeyContacts(dispatch, undefined);
                      ativaDesativaLoadingCarregaMaisChamadosAntigos(dispatch, false);
                    }
                  })
              } else {
                firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild("cliente").startAt(keyCliente).endAt(keyCliente, oldReferenceKey)
                  .limitToLast(2).once('value').then((snapshot) => {

                    if (snapshot.val()) {
                      let arrayOfKeys = Object.keys(snapshot.val()).sort().reverse().slice(1);
                      let referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];


                      let arrayAcoesAtendimento = [];
                      let arrayDadosAberturaChamado = [];
                      let keyAtendimento = '';
                      let verificaSeColocoInformacoesFinaisAtendimento = false;
                      let statusChamado = '';
                      let dataFechamentoChamado = '';
                      let numeroChamado = '';
                      let aux = 0;
                      snapshot.forEach(childItem => {
                        if (aux === 0 && snapshot.numChildren() === 1 || aux === 1 && snapshot.numChildren() === 2) {
                          keyAtendimento = childItem.key;
                          verificaSeColocoInformacoesFinaisAtendimento = childItem.val().status === 'Aberto' || childItem.val().status === 'Atendendo' ? false : true;
                          statusChamado = childItem.val().status;
                          dataFechamentoChamado = childItem.val().status === 'Fechado' ? childItem.val().dataHoraFechamento : childItem.val().dataHoraCancelamento;
                          numeroChamado = childItem.val().numeroChamado;
                          arrayAcoesAtendimento.push({
                            key: childItem.key + 'inicio',
                            title: `Inicio do Atendimento: #${childItem.val().numeroChamado} - ${childItem.val().dataHoraAbertura}`,
                            tipo: 'mensagem',
                            description: `Inicio do Atendimento: #${childItem.val().numeroChamado} - ${childItem.val().dataHoraAbertura}`,
                            statusMessage: 'sent',
                            time: "09:50",
                            dataHoraAtual: '01/01/2099',
                            lineColor: '#00FF7F',
                            circleColor: '#00FF7F',
                            visivelATodos: 1,
                            //  dataHoraAbertura: `Inicio do Atendimento: #${childItem.val().numeroChamado} - ${childItem.val().dataHoraAbertura}`,
                            //  dataHoraFechamneto: `Fim do Atendimento: #${childItem.val().numeroChamado} - ${childItem.val().dataHoraFechamento}`,
                            // dataHoraCancelamento: `Fim do Atendimento: #${childItem.val().numeroChamado} - ${childItem.val().dataHoraCancelamento}`,
                          });
                        }
                        aux++;
                      })

                      firebase.database().ref(`${chaveUnica}/acoesChamados/${keyAtendimento}`).once('value').then((snapshot2) => {

                        /*  */
                        snapshot2.forEach(childItem => {
                          /*    console.log(childItem.val()) */
                          if (childItem.val().tipo !== undefined) {
                            arrayAcoesAtendimento.push({
                              key: childItem.key,
                              title: childItem.val().titulo,
                              tipo: childItem.val().tipo,
                              description: childItem.val().mensagem,
                              statusMessage: childItem.val().statusMessage == undefined ? 'sent' : childItem.val().statusMessage,
                              time: childItem.val().time,
                              dataHoraAtual: childItem.val().dataHoraAtual == undefined ? childItem.val().mensagem : childItem.val().dataHoraAtual,
                              lineColor: childItem.val().lineColor,
                              circleColor: childItem.val().circleColor,
                              urlDownload: childItem.val().urlDownload,
                              tipoArquivo: childItem.val().tipoArquivo,
                              duracao: childItem.val().duracao == undefined ? '' : childItem.val().duracao,
                              audio: childItem.val().audio == undefined ? false : childItem.val().audio,
                              visivelATodos: childItem.val().visivelATodos == undefined ? 1 : childItem.val().visivelATodos,
                              idMensagem: childItem.val().idMensagem == undefined ? childItem.key : childItem.val().idMensagem === '' ? childItem.key : childItem.val().idMensagem,
                              circleColorResponder: childItem.val().circleColorResponder == undefined ? '#00FF7F' : childItem.val().circleColorResponder,
                              commentResponderType: childItem.val().commentResponderType == undefined ? '' : childItem.val().commentResponderType,
                              idMensagemResponder: childItem.val().idMensagemResponder == undefined ? '' : childItem.val().idMensagemResponder,
                              mensagemResposta: childItem.val().mensagemResposta == undefined ? '' : childItem.val().mensagemResposta,
                              possuiResposta: childItem.val().possuiResposta == undefined ? false : childItem.val().possuiResposta,
                              commentResponderTypeArquivo: childItem.val().commentResponderTypeArquivo == undefined ? '' : childItem.val().commentResponderTypeArquivo,
                              commentResponderIsAudio: childItem.val().commentResponderIsAudio == undefined ? '' : childItem.val().commentResponderIsAudio,
                              atendenteResponder: childItem.val().atendenteResponder == undefined ? '' : childItem.val().atendenteResponder,
                              captionArquivo: childItem.val().captionArquivo == undefined ? '' : childItem.val().captionArquivo,
                              read: childItem.val().read == undefined ? false : childItem.val().read,
                              deleteMessage: childItem.val().deleteMessage == undefined ? false : childItem.val().deleteMessage,
                              edited: childItem.val().edited ?? false,
                            });
                          }
                        });
                        if (verificaSeColocoInformacoesFinaisAtendimento) {
                          arrayAcoesAtendimento.push({
                            key: keyAtendimento + 'Fim',
                            title: `Fim do Atendimento: #${numeroChamado} - ${dataFechamentoChamado}`,
                            tipo: 'mensagem',
                            description: `Fim do Atendimento: #${numeroChamado} - ${dataFechamentoChamado}`,
                            statusMessage: 'sent',
                            time: "09:50",
                            dataHoraAtual: '01/01/2099',
                            lineColor: '#00FF7F',
                            circleColor: '#00FF7F',
                            visivelATodos: 1,
                          });

                        }

                        //ativaDesativaLoadingTelaAcoesAtendimento(dispatch, false);
                        enviaConversasUltimoChamado(dispatch, conversasAnteriores.concat(arrayAcoesAtendimento.reverse()));
                        ativaDesativaLoadingCarregaChamadosAntigos(dispatch, false);
                        ativaDesativaLoadingCarregaRespostasAntigas(dispatch, false);
                        enviaUltimaReeferenciaKeyContacts(dispatch, referenceToOldestKey);
                        ativaDesativaLoadingCarregaMaisChamadosAntigos(dispatch, false);

                      });
                    } else {
                      ativaDesativaLoadingCarregaChamadosAntigos(dispatch, false);
                      ativaDesativaLoadingCarregaRespostasAntigas(dispatch, false);
                      enviaUltimaReeferenciaKeyContacts(dispatch, undefined);
                      ativaDesativaLoadingCarregaMaisChamadosAntigos(dispatch, false);
                    }
                  })
                ativaDesativaLoadingCarregaChamadosAntigos(dispatch, false);
                ativaDesativaLoadingCarregaRespostasAntigas(dispatch, false);
              }
            } else {
              ativaDesativaLoadingCarregaMaisChamadosAntigos(dispatch, false);
              ativaDesativaLoadingCarregaRespostasAntigas(dispatch, false);
            }

          })
        } else {
          enviaUltimaReeferenciaKeyContacts(dispatch, undefined);
          ativaDesativaLoadingCarregaRespostasAntigas(dispatch, false);
          ativaDesativaLoadingCarregaMaisChamadosAntigos(dispatch, false);
        }
      }
    });
  }
};
export const listaConversasAntigasWppEmEspera = (valorReferenciaInicial, keyCliente, oldReferenceKey, conversasAnteriores, keyChamado,
  agruparAtendenteSetor, contaPrincipal, adminVisualizaApenasProprioSetor, keyAtendente, atendenteVeApenasProprioHistoricoChamado) => {
  return dispatch => {

    ativaDesativaLoadingCarregaChamadosAntigosEmEspera(dispatch, true);
    /* if (oldReferenceKey === '') {
      oldReferenceKey = keyChamado;
    }
     */
    AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");


      if (oldReferenceKey != undefined && oldReferenceKey != "") {


        if (atendenteVeApenasProprioHistoricoChamado === true && contaPrincipal === false) {
          firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild("atendente_cliente").startAt(`${keyAtendente}${keyCliente}`).endAt(`${keyAtendente}${keyCliente}`, oldReferenceKey)
            .limitToLast(2).once('value').then((snapshot) => {

              if (snapshot.val()) {
                let arrayOfKeys = Object.keys(snapshot.val()).sort().reverse().slice(1);
                let referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];


                let arrayAcoesAtendimento = [];
                let arrayDadosAberturaChamado = [];
                let keyAtendimento = '';
                let verificaSeColocoInformacoesFinaisAtendimento = false;
                let statusChamado = '';
                let dataFechamentoChamado = '';
                let numeroChamado = '';
                let aux = 0;
                snapshot.forEach(childItem => {
                  if (aux === 0 && snapshot.numChildren() === 1 || aux === 1 && snapshot.numChildren() === 2) {
                    keyAtendimento = childItem.key;
                    verificaSeColocoInformacoesFinaisAtendimento = childItem.val().status === 'Aberto' || childItem.val().status === 'Atendendo' ? false : true;
                    statusChamado = childItem.val().status;
                    dataFechamentoChamado = childItem.val().status === 'Fechado' ? childItem.val().dataHoraFechamento : childItem.val().dataHoraCancelamento;
                    numeroChamado = childItem.val().numeroChamado;
                    arrayAcoesAtendimento.push({
                      key: childItem.key + 'inicio',
                      title: `Inicio do Atendimento: #${childItem.val().numeroChamado} - ${childItem.val().dataHoraAbertura}`,
                      tipo: 'mensagem',
                      description: `Inicio do Atendimento: #${childItem.val().numeroChamado} - ${childItem.val().dataHoraAbertura}`,
                      statusMessage: 'sent',
                      time: "09:50",
                      dataHoraAtual: '01/01/2099',
                      lineColor: '#00FF7F',
                      circleColor: '#00FF7F',
                      visivelATodos: 1,
                      //  dataHoraAbertura: `Inicio do Atendimento: #${childItem.val().numeroChamado} - ${childItem.val().dataHoraAbertura}`,
                      //  dataHoraFechamneto: `Fim do Atendimento: #${childItem.val().numeroChamado} - ${childItem.val().dataHoraFechamento}`,
                      // dataHoraCancelamento: `Fim do Atendimento: #${childItem.val().numeroChamado} - ${childItem.val().dataHoraCancelamento}`,
                    });
                  }
                  aux++;
                })

                firebase.database().ref(`${chaveUnica}/acoesChamados/${keyAtendimento}`).once('value').then((snapshot2) => {

                  /*  */
                  snapshot2.forEach(childItem => {
                    /*    console.log(childItem.val()) */
                    if (childItem.val().tipo !== undefined) {
                      arrayAcoesAtendimento.push({
                        key: childItem.key,
                        title: childItem.val().titulo,
                        tipo: childItem.val().tipo,
                        description: childItem.val().mensagem,
                        statusMessage: childItem.val().statusMessage == undefined ? 'sent' : childItem.val().statusMessage,
                        time: childItem.val().time,
                        dataHoraAtual: childItem.val().dataHoraAtual == undefined ? childItem.val().mensagem : childItem.val().dataHoraAtual,
                        lineColor: childItem.val().lineColor,
                        circleColor: childItem.val().circleColor,
                        urlDownload: childItem.val().urlDownload,
                        tipoArquivo: childItem.val().tipoArquivo,
                        duracao: childItem.val().duracao == undefined ? '' : childItem.val().duracao,
                        audio: childItem.val().audio == undefined ? false : childItem.val().audio,
                        visivelATodos: childItem.val().visivelATodos == undefined ? 1 : childItem.val().visivelATodos,
                        idMensagem: childItem.val().idMensagem == undefined ? childItem.key : childItem.val().idMensagem === '' ? childItem.key : childItem.val().idMensagem,
                        circleColorResponder: childItem.val().circleColorResponder == undefined ? '#00FF7F' : childItem.val().circleColorResponder,
                        commentResponderType: childItem.val().commentResponderType == undefined ? '' : childItem.val().commentResponderType,
                        idMensagemResponder: childItem.val().idMensagemResponder == undefined ? '' : childItem.val().idMensagemResponder,
                        mensagemResposta: childItem.val().mensagemResposta == undefined ? '' : childItem.val().mensagemResposta,
                        possuiResposta: childItem.val().possuiResposta == undefined ? false : childItem.val().possuiResposta,
                        commentResponderTypeArquivo: childItem.val().commentResponderTypeArquivo == undefined ? '' : childItem.val().commentResponderTypeArquivo,
                        commentResponderIsAudio: childItem.val().commentResponderIsAudio == undefined ? '' : childItem.val().commentResponderIsAudio,
                        atendenteResponder: childItem.val().atendenteResponder == undefined ? '' : childItem.val().atendenteResponder,
                        captionArquivo: childItem.val().captionArquivo == undefined ? '' : childItem.val().captionArquivo,
                        read: childItem.val().read == undefined ? false : childItem.val().read,
                        deleteMessage: childItem.val().deleteMessage == undefined ? false : childItem.val().deleteMessage,
                        edited: childItem.val().edited ?? false,
                      });
                    }
                  });
                  if (verificaSeColocoInformacoesFinaisAtendimento) {
                    arrayAcoesAtendimento.push({
                      key: keyAtendimento + 'Fim',
                      title: `Fim do Atendimento: #${numeroChamado} - ${dataFechamentoChamado}`,
                      tipo: 'mensagem',
                      description: `Fim do Atendimento: #${numeroChamado} - ${dataFechamentoChamado}`,
                      statusMessage: 'sent',
                      time: "09:50",
                      dataHoraAtual: '01/01/2099',
                      lineColor: '#00FF7F',
                      circleColor: '#00FF7F',
                      visivelATodos: 1,
                    });

                  }

                  //enviaConversasUltimoChamado(dispatch, conversasAnteriores.concat(arrayAcoesAtendimento.reverse()));
                  enviaConversasCarregadasEmEspera(dispatch, conversasAnteriores.concat(arrayAcoesAtendimento.reverse()))
                  enviaUltimaReeferenciaKeyEmEspera(dispatch, referenceToOldestKey);
                  ativaDesativaLoadingCarregaChamadosAntigosEmEspera(dispatch, false);

                });
              } else {
                enviaUltimaReeferenciaKeyEmEspera(dispatch, undefined);
                ativaDesativaLoadingCarregaChamadosAntigosEmEspera(dispatch, false);
              }
            })
        } else {

          firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild("cliente").startAt(keyCliente).endAt(keyCliente, oldReferenceKey)
            .limitToLast(2).once('value').then((snapshot) => {

              if (snapshot.val()) {
                let arrayOfKeys = Object.keys(snapshot.val()).sort().reverse().slice(1);
                let referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];


                let arrayAcoesAtendimento = [];
                let arrayDadosAberturaChamado = [];
                let keyAtendimento = '';
                let verificaSeColocoInformacoesFinaisAtendimento = false;
                let statusChamado = '';
                let dataFechamentoChamado = '';
                let numeroChamado = '';
                let aux = 0;
                snapshot.forEach(childItem => {
                  if (aux === 0 && snapshot.numChildren() === 1 || aux === 1 && snapshot.numChildren() === 2) {
                    keyAtendimento = childItem.key;
                    verificaSeColocoInformacoesFinaisAtendimento = childItem.val().status === 'Aberto' || childItem.val().status === 'Atendendo' ? false : true;
                    statusChamado = childItem.val().status;
                    dataFechamentoChamado = childItem.val().status === 'Fechado' ? childItem.val().dataHoraFechamento : childItem.val().dataHoraCancelamento;
                    numeroChamado = childItem.val().numeroChamado;
                    arrayAcoesAtendimento.push({
                      key: childItem.key + 'inicio',
                      title: `Inicio do Atendimento: #${childItem.val().numeroChamado} - ${childItem.val().dataHoraAbertura}`,
                      tipo: 'mensagem',
                      description: `Inicio do Atendimento: #${childItem.val().numeroChamado} - ${childItem.val().dataHoraAbertura}`,
                      statusMessage: 'sent',
                      time: "09:50",
                      dataHoraAtual: '01/01/2099',
                      lineColor: '#00FF7F',
                      circleColor: '#00FF7F',
                      visivelATodos: 1,
                      //  dataHoraAbertura: `Inicio do Atendimento: #${childItem.val().numeroChamado} - ${childItem.val().dataHoraAbertura}`,
                      //  dataHoraFechamneto: `Fim do Atendimento: #${childItem.val().numeroChamado} - ${childItem.val().dataHoraFechamento}`,
                      // dataHoraCancelamento: `Fim do Atendimento: #${childItem.val().numeroChamado} - ${childItem.val().dataHoraCancelamento}`,
                    });
                  }
                  aux++;
                })

                firebase.database().ref(`${chaveUnica}/acoesChamados/${keyAtendimento}`).once('value').then((snapshot2) => {

                  /*  */
                  snapshot2.forEach(childItem => {
                    /*    console.log(childItem.val()) */
                    if (childItem.val().tipo !== undefined) {
                      arrayAcoesAtendimento.push({
                        key: childItem.key,
                        title: childItem.val().titulo,
                        tipo: childItem.val().tipo,
                        description: childItem.val().mensagem,
                        statusMessage: childItem.val().statusMessage == undefined ? 'sent' : childItem.val().statusMessage,
                        time: childItem.val().time,
                        dataHoraAtual: childItem.val().dataHoraAtual == undefined ? childItem.val().mensagem : childItem.val().dataHoraAtual,
                        lineColor: childItem.val().lineColor,
                        circleColor: childItem.val().circleColor,
                        urlDownload: childItem.val().urlDownload,
                        tipoArquivo: childItem.val().tipoArquivo,
                        duracao: childItem.val().duracao == undefined ? '' : childItem.val().duracao,
                        audio: childItem.val().audio == undefined ? false : childItem.val().audio,
                        visivelATodos: childItem.val().visivelATodos == undefined ? 1 : childItem.val().visivelATodos,
                        idMensagem: childItem.val().idMensagem == undefined ? childItem.key : childItem.val().idMensagem === '' ? childItem.key : childItem.val().idMensagem,
                        circleColorResponder: childItem.val().circleColorResponder == undefined ? '#00FF7F' : childItem.val().circleColorResponder,
                        commentResponderType: childItem.val().commentResponderType == undefined ? '' : childItem.val().commentResponderType,
                        idMensagemResponder: childItem.val().idMensagemResponder == undefined ? '' : childItem.val().idMensagemResponder,
                        mensagemResposta: childItem.val().mensagemResposta == undefined ? '' : childItem.val().mensagemResposta,
                        possuiResposta: childItem.val().possuiResposta == undefined ? false : childItem.val().possuiResposta,
                        commentResponderTypeArquivo: childItem.val().commentResponderTypeArquivo == undefined ? '' : childItem.val().commentResponderTypeArquivo,
                        commentResponderIsAudio: childItem.val().commentResponderIsAudio == undefined ? '' : childItem.val().commentResponderIsAudio,
                        atendenteResponder: childItem.val().atendenteResponder == undefined ? '' : childItem.val().atendenteResponder,
                        captionArquivo: childItem.val().captionArquivo == undefined ? '' : childItem.val().captionArquivo,
                        read: childItem.val().read == undefined ? false : childItem.val().read,
                        deleteMessage: childItem.val().deleteMessage == undefined ? false : childItem.val().deleteMessage,
                        edited: childItem.val().edited ?? false,
                      });
                    }
                  });
                  if (verificaSeColocoInformacoesFinaisAtendimento) {
                    arrayAcoesAtendimento.push({
                      key: keyAtendimento + 'Fim',
                      title: `Fim do Atendimento: #${numeroChamado} - ${dataFechamentoChamado}`,
                      tipo: 'mensagem',
                      description: `Fim do Atendimento: #${numeroChamado} - ${dataFechamentoChamado}`,
                      statusMessage: 'sent',
                      time: "09:50",
                      dataHoraAtual: '01/01/2099',
                      lineColor: '#00FF7F',
                      circleColor: '#00FF7F',
                      visivelATodos: 1,
                    });

                  }

                  //enviaConversasUltimoChamado(dispatch, conversasAnteriores.concat(arrayAcoesAtendimento.reverse()));
                  enviaConversasCarregadasEmEspera(dispatch, conversasAnteriores.concat(arrayAcoesAtendimento.reverse()))
                  enviaUltimaReeferenciaKeyEmEspera(dispatch, referenceToOldestKey);
                  ativaDesativaLoadingCarregaChamadosAntigosEmEspera(dispatch, false);

                });
              } else {
                enviaUltimaReeferenciaKeyEmEspera(dispatch, undefined);
                ativaDesativaLoadingCarregaChamadosAntigosEmEspera(dispatch, false);
              }
            })
        }
      } else {




        if (atendenteVeApenasProprioHistoricoChamado === true && contaPrincipal === false) {
          firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild("atendente_cliente").startAt(`${keyAtendente}${keyCliente}`).endAt(`${keyAtendente}${keyCliente}`, keyChamado)
            .limitToLast(2).once('value').then((snapshot) => {

              if (snapshot.val()) {
                let arrayOfKeys = Object.keys(snapshot.val()).sort().reverse().slice(1);
                let referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];


                let arrayAcoesAtendimento = [];
                let arrayDadosAberturaChamado = [];
                let keyAtendimento;
                let verificaSeColocoInformacoesFinaisAtendimento = false;
                let statusChamado = '';
                let dataFechamentoChamado = '';
                let numeroChamado = '';
                let aux = 0;
                snapshot.forEach(childItem => {
                  if (aux === 1 && snapshot.numChildren() === 2 || aux === 1 && snapshot.numChildren() === 3) {
                    keyAtendimento = childItem.key;
                    verificaSeColocoInformacoesFinaisAtendimento = childItem.val().status === 'Aberto' || childItem.val().status === 'Atendendo' ? false : true;
                    statusChamado = childItem.val().status;
                    dataFechamentoChamado = childItem.val().status === 'Fechado' ? childItem.val().dataHoraFechamento : childItem.val().dataHoraCancelamento;
                    numeroChamado = childItem.val().numeroChamado;
                    arrayAcoesAtendimento.push({
                      key: childItem.key + 'inicio',
                      title: `Inicio do Atendimento: #${childItem.val().numeroChamado} - ${childItem.val().dataHoraAbertura}`,
                      tipo: 'mensagem',
                      description: `Inicio do Atendimento: #${childItem.val().numeroChamado} - ${childItem.val().dataHoraAbertura}`,
                      statusMessage: 'sent',
                      time: "09:50",
                      dataHoraAtual: '01/01/2099',
                      lineColor: '#00FF7F',
                      circleColor: '#00FF7F',
                      visivelATodos: 1,
                      //  dataHoraAbertura: `Inicio do Atendimento: #${childItem.val().numeroChamado} - ${childItem.val().dataHoraAbertura}`,
                      //  dataHoraFechamneto: `Fim do Atendimento: #${childItem.val().numeroChamado} - ${childItem.val().dataHoraFechamento}`,
                      // dataHoraCancelamento: `Fim do Atendimento: #${childItem.val().numeroChamado} - ${childItem.val().dataHoraCancelamento}`,
                    });
                  }
                  aux++;
                })

                firebase.database().ref(`${chaveUnica}/acoesChamados/${keyAtendimento}`).once('value').then((snapshot2) => {

                  /*  */
                  snapshot2.forEach(childItem => {
                    /*    console.log(childItem.val()) */
                    if (childItem.val().tipo !== undefined) {
                      arrayAcoesAtendimento.push({
                        key: childItem.key,
                        title: childItem.val().titulo,
                        tipo: childItem.val().tipo,
                        description: childItem.val().mensagem,
                        statusMessage: childItem.val().statusMessage == undefined ? 'sent' : childItem.val().statusMessage,
                        time: childItem.val().time,
                        dataHoraAtual: childItem.val().dataHoraAtual == undefined ? childItem.val().mensagem : childItem.val().dataHoraAtual,
                        lineColor: childItem.val().lineColor,
                        circleColor: childItem.val().circleColor,
                        urlDownload: childItem.val().urlDownload,
                        tipoArquivo: childItem.val().tipoArquivo,
                        duracao: childItem.val().duracao == undefined ? '' : childItem.val().duracao,
                        audio: childItem.val().audio == undefined ? false : childItem.val().audio,
                        visivelATodos: childItem.val().visivelATodos == undefined ? 1 : childItem.val().visivelATodos,
                        idMensagem: childItem.val().idMensagem == undefined ? childItem.key : childItem.val().idMensagem === '' ? childItem.key : childItem.val().idMensagem,
                        circleColorResponder: childItem.val().circleColorResponder == undefined ? '#00FF7F' : childItem.val().circleColorResponder,
                        commentResponderType: childItem.val().commentResponderType == undefined ? '' : childItem.val().commentResponderType,
                        idMensagemResponder: childItem.val().idMensagemResponder == undefined ? '' : childItem.val().idMensagemResponder,
                        mensagemResposta: childItem.val().mensagemResposta == undefined ? '' : childItem.val().mensagemResposta,
                        possuiResposta: childItem.val().possuiResposta == undefined ? false : childItem.val().possuiResposta,
                        commentResponderTypeArquivo: childItem.val().commentResponderTypeArquivo == undefined ? '' : childItem.val().commentResponderTypeArquivo,
                        commentResponderIsAudio: childItem.val().commentResponderIsAudio == undefined ? '' : childItem.val().commentResponderIsAudio,
                        atendenteResponder: childItem.val().atendenteResponder == undefined ? '' : childItem.val().atendenteResponder,
                        captionArquivo: childItem.val().captionArquivo == undefined ? '' : childItem.val().captionArquivo,
                        read: childItem.val().read == undefined ? false : childItem.val().read,
                        deleteMessage: childItem.val().deleteMessage == undefined ? false : childItem.val().deleteMessage,
                        edited: childItem.val().edited ?? false,
                      });
                    }
                  });
                  if (verificaSeColocoInformacoesFinaisAtendimento) {
                    arrayAcoesAtendimento.push({
                      key: keyAtendimento + 'Fim',
                      title: `Fim do Atendimento: #${numeroChamado} - ${dataFechamentoChamado}`,
                      tipo: 'mensagem',
                      description: `Fim do Atendimento: #${numeroChamado} - ${dataFechamentoChamado}`,
                      statusMessage: 'sent',
                      time: "09:50",
                      dataHoraAtual: '01/01/2099',
                      lineColor: '#00FF7F',
                      circleColor: '#00FF7F',
                      visivelATodos: 1,
                    });

                  }

                  //enviaConversasUltimoChamado(dispatch, conversasAnteriores.concat(arrayAcoesAtendimento.reverse()));
                  /*    informacoesCompletas[keyAtendimento] = conversasAnteriores.concat(arrayAcoesAtendimento.reverse());
                             enviaConversasParaChatEmEspera(dispatch, informacoesCompletas); */
                  /*    console.log(arrayAcoesAtendimento.reverse()) */
                  if (conversasAnteriores.length > 0) {
                    enviaConversasCarregadasEmEspera(dispatch, conversasAnteriores.concat(arrayAcoesAtendimento.reverse()))
                  } else {
                    enviaConversasCarregadasEmEspera(dispatch, arrayAcoesAtendimento.reverse());
                  }
                  //  enviaConversasCarregadasEmEspera(dispatch, arrayAcoesAtendimento.reverse());
                  enviaUltimaReeferenciaKeyEmEspera(dispatch, referenceToOldestKey);
                  ativaDesativaLoadingCarregaChamadosAntigosEmEspera(dispatch, false);

                });
              } else {
                enviaUltimaReeferenciaKeyEmEspera(dispatch, undefined);
                ativaDesativaLoadingCarregaChamadosAntigosEmEspera(dispatch, false);
              }
            })
        } else {
          firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild("cliente").startAt(keyCliente).endAt(keyCliente, keyChamado)
            .limitToLast(3).once('value').then((snapshot) => {

              if (snapshot.val()) {
                let arrayOfKeys = Object.keys(snapshot.val()).sort().reverse().slice(1);
                let referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];


                let arrayAcoesAtendimento = [];
                let arrayDadosAberturaChamado = [];
                let keyAtendimento;
                let verificaSeColocoInformacoesFinaisAtendimento = false;
                let statusChamado = '';
                let dataFechamentoChamado = '';
                let numeroChamado = '';
                let aux = 0;
                snapshot.forEach(childItem => {
                  if (aux === 1 && snapshot.numChildren() === 2 || aux === 1 && snapshot.numChildren() === 3) {
                    keyAtendimento = childItem.key;
                    verificaSeColocoInformacoesFinaisAtendimento = childItem.val().status === 'Aberto' || childItem.val().status === 'Atendendo' ? false : true;
                    statusChamado = childItem.val().status;
                    dataFechamentoChamado = childItem.val().status === 'Fechado' ? childItem.val().dataHoraFechamento : childItem.val().dataHoraCancelamento;
                    numeroChamado = childItem.val().numeroChamado;
                    arrayAcoesAtendimento.push({
                      key: childItem.key + 'inicio',
                      title: `Inicio do Atendimento: #${childItem.val().numeroChamado} - ${childItem.val().dataHoraAbertura}`,
                      tipo: 'mensagem',
                      description: `Inicio do Atendimento: #${childItem.val().numeroChamado} - ${childItem.val().dataHoraAbertura}`,
                      statusMessage: 'sent',
                      time: "09:50",
                      dataHoraAtual: '01/01/2099',
                      lineColor: '#00FF7F',
                      circleColor: '#00FF7F',
                      visivelATodos: 1,
                      //  dataHoraAbertura: `Inicio do Atendimento: #${childItem.val().numeroChamado} - ${childItem.val().dataHoraAbertura}`,
                      //  dataHoraFechamneto: `Fim do Atendimento: #${childItem.val().numeroChamado} - ${childItem.val().dataHoraFechamento}`,
                      // dataHoraCancelamento: `Fim do Atendimento: #${childItem.val().numeroChamado} - ${childItem.val().dataHoraCancelamento}`,
                    });
                  }
                  aux++;
                })

                firebase.database().ref(`${chaveUnica}/acoesChamados/${keyAtendimento}`).once('value').then((snapshot2) => {

                  /*  */
                  snapshot2.forEach(childItem => {
                    /*    console.log(childItem.val()) */
                    if (childItem.val().tipo !== undefined) {
                      arrayAcoesAtendimento.push({
                        key: childItem.key,
                        title: childItem.val().titulo,
                        tipo: childItem.val().tipo,
                        description: childItem.val().mensagem,
                        statusMessage: childItem.val().statusMessage == undefined ? 'sent' : childItem.val().statusMessage,
                        time: childItem.val().time,
                        dataHoraAtual: childItem.val().dataHoraAtual == undefined ? childItem.val().mensagem : childItem.val().dataHoraAtual,
                        lineColor: childItem.val().lineColor,
                        circleColor: childItem.val().circleColor,
                        urlDownload: childItem.val().urlDownload,
                        tipoArquivo: childItem.val().tipoArquivo,
                        duracao: childItem.val().duracao == undefined ? '' : childItem.val().duracao,
                        audio: childItem.val().audio == undefined ? false : childItem.val().audio,
                        visivelATodos: childItem.val().visivelATodos == undefined ? 1 : childItem.val().visivelATodos,
                        idMensagem: childItem.val().idMensagem == undefined ? childItem.key : childItem.val().idMensagem === '' ? childItem.key : childItem.val().idMensagem,
                        circleColorResponder: childItem.val().circleColorResponder == undefined ? '#00FF7F' : childItem.val().circleColorResponder,
                        commentResponderType: childItem.val().commentResponderType == undefined ? '' : childItem.val().commentResponderType,
                        idMensagemResponder: childItem.val().idMensagemResponder == undefined ? '' : childItem.val().idMensagemResponder,
                        mensagemResposta: childItem.val().mensagemResposta == undefined ? '' : childItem.val().mensagemResposta,
                        possuiResposta: childItem.val().possuiResposta == undefined ? false : childItem.val().possuiResposta,
                        commentResponderTypeArquivo: childItem.val().commentResponderTypeArquivo == undefined ? '' : childItem.val().commentResponderTypeArquivo,
                        commentResponderIsAudio: childItem.val().commentResponderIsAudio == undefined ? '' : childItem.val().commentResponderIsAudio,
                        atendenteResponder: childItem.val().atendenteResponder == undefined ? '' : childItem.val().atendenteResponder,
                        captionArquivo: childItem.val().captionArquivo == undefined ? '' : childItem.val().captionArquivo,
                        read: childItem.val().read == undefined ? false : childItem.val().read,
                        deleteMessage: childItem.val().deleteMessage == undefined ? false : childItem.val().deleteMessage,
                        edited: childItem.val().edited ?? false,
                      });
                    }
                  });
                  if (verificaSeColocoInformacoesFinaisAtendimento) {
                    arrayAcoesAtendimento.push({
                      key: keyAtendimento + 'Fim',
                      title: `Fim do Atendimento: #${numeroChamado} - ${dataFechamentoChamado}`,
                      tipo: 'mensagem',
                      description: `Fim do Atendimento: #${numeroChamado} - ${dataFechamentoChamado}`,
                      statusMessage: 'sent',
                      time: "09:50",
                      dataHoraAtual: '01/01/2099',
                      lineColor: '#00FF7F',
                      circleColor: '#00FF7F',
                      visivelATodos: 1,
                    });

                  }

                  //enviaConversasUltimoChamado(dispatch, conversasAnteriores.concat(arrayAcoesAtendimento.reverse()));
                  /*    informacoesCompletas[keyAtendimento] = conversasAnteriores.concat(arrayAcoesAtendimento.reverse());
                             enviaConversasParaChatEmEspera(dispatch, informacoesCompletas); */
                  /*    console.log(arrayAcoesAtendimento.reverse()) */
                  if (conversasAnteriores.length > 0) {
                    enviaConversasCarregadasEmEspera(dispatch, conversasAnteriores.concat(arrayAcoesAtendimento.reverse()))
                  } else {
                    enviaConversasCarregadasEmEspera(dispatch, arrayAcoesAtendimento.reverse());
                  }
                  //  enviaConversasCarregadasEmEspera(dispatch, arrayAcoesAtendimento.reverse());
                  enviaUltimaReeferenciaKeyEmEspera(dispatch, referenceToOldestKey);
                  ativaDesativaLoadingCarregaChamadosAntigosEmEspera(dispatch, false);

                });
              } else {
                enviaUltimaReeferenciaKeyEmEspera(dispatch, undefined);
                ativaDesativaLoadingCarregaChamadosAntigosEmEspera(dispatch, false);
              }
            })
        }
      }

    })



  }
};
export const listaConversasAntigasWppEmAtendimento = (valorReferenciaInicial, keyCliente, oldReferenceKey, conversasAnteriores, keyChamado,
  agruparAtendenteSetor, contaPrincipal, adminVisualizaApenasProprioSetor, keyAtendente, atendenteVeApenasProprioHistoricoChamado) => {
  return dispatch => {

    ativaDesativaLoadingCarregaChamadosAntigosEmAtendimento(dispatch, true);
    /* if (oldReferenceKey === '') {
      oldReferenceKey = keyChamado;
    }
     */
    AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");


      if (oldReferenceKey != undefined && oldReferenceKey != "") {



        if (atendenteVeApenasProprioHistoricoChamado === true && contaPrincipal === false) {
          firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild("atendente_cliente").startAt(`${keyAtendente}${keyCliente}`).endAt(`${keyAtendente}${keyCliente}`, oldReferenceKey)
            .limitToLast(2).once('value').then((snapshot) => {

              if (snapshot.val()) {
                let arrayOfKeys = Object.keys(snapshot.val()).sort().reverse().slice(1);
                let referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];


                let arrayAcoesAtendimento = [];
                let arrayDadosAberturaChamado = [];
                let keyAtendimento = '';
                let verificaSeColocoInformacoesFinaisAtendimento = false;
                let statusChamado = '';
                let dataFechamentoChamado = '';
                let numeroChamado = '';
                let aux = 0;
                snapshot.forEach(childItem => {
                  if (aux === 0 && snapshot.numChildren() === 1 || aux === 1 && snapshot.numChildren() === 2) {
                    keyAtendimento = childItem.key;
                    verificaSeColocoInformacoesFinaisAtendimento = childItem.val().status === 'Aberto' || childItem.val().status === 'Atendendo' ? false : true;
                    statusChamado = childItem.val().status;
                    dataFechamentoChamado = childItem.val().status === 'Fechado' ? childItem.val().dataHoraFechamento : childItem.val().dataHoraCancelamento;
                    numeroChamado = childItem.val().numeroChamado;
                    arrayAcoesAtendimento.push({
                      key: childItem.key + 'inicio',
                      title: `Inicio do Atendimento: #${childItem.val().numeroChamado} - ${childItem.val().dataHoraAbertura}`,
                      tipo: 'mensagem',
                      description: `Inicio do Atendimento: #${childItem.val().numeroChamado} - ${childItem.val().dataHoraAbertura}`,
                      statusMessage: 'sent',
                      time: "09:50",
                      dataHoraAtual: '01/01/2099',
                      lineColor: '#00FF7F',
                      circleColor: '#00FF7F',
                      visivelATodos: 1,
                      //  dataHoraAbertura: `Inicio do Atendimento: #${childItem.val().numeroChamado} - ${childItem.val().dataHoraAbertura}`,
                      //  dataHoraFechamneto: `Fim do Atendimento: #${childItem.val().numeroChamado} - ${childItem.val().dataHoraFechamento}`,
                      // dataHoraCancelamento: `Fim do Atendimento: #${childItem.val().numeroChamado} - ${childItem.val().dataHoraCancelamento}`,
                    });
                  }
                  aux++;
                })

                firebase.database().ref(`${chaveUnica}/acoesChamados/${keyAtendimento}`).once('value').then((snapshot2) => {

                  /*  */
                  snapshot2.forEach(childItem => {
                    if (childItem.val().tipo !== undefined) {
                      /*    console.log(childItem.val()) */
                      arrayAcoesAtendimento.push({
                        key: childItem.key,
                        title: childItem.val().titulo,
                        tipo: childItem.val().tipo,
                        description: childItem.val().mensagem,
                        statusMessage: childItem.val().statusMessage == undefined ? 'sent' : childItem.val().statusMessage,
                        time: childItem.val().time,
                        dataHoraAtual: childItem.val().dataHoraAtual == undefined ? childItem.val().mensagem : childItem.val().dataHoraAtual,
                        lineColor: childItem.val().lineColor,
                        circleColor: childItem.val().circleColor,
                        urlDownload: childItem.val().urlDownload,
                        tipoArquivo: childItem.val().tipoArquivo,
                        duracao: childItem.val().duracao == undefined ? '' : childItem.val().duracao,
                        audio: childItem.val().audio == undefined ? false : childItem.val().audio,
                        visivelATodos: childItem.val().visivelATodos == undefined ? 1 : childItem.val().visivelATodos,
                        idMensagem: childItem.val().idMensagem == undefined ? childItem.key : childItem.val().idMensagem === '' ? childItem.key : childItem.val().idMensagem,
                        circleColorResponder: childItem.val().circleColorResponder == undefined ? '#00FF7F' : childItem.val().circleColorResponder,
                        commentResponderType: childItem.val().commentResponderType == undefined ? '' : childItem.val().commentResponderType,
                        idMensagemResponder: childItem.val().idMensagemResponder == undefined ? '' : childItem.val().idMensagemResponder,
                        mensagemResposta: childItem.val().mensagemResposta == undefined ? '' : childItem.val().mensagemResposta,
                        possuiResposta: childItem.val().possuiResposta == undefined ? false : childItem.val().possuiResposta,
                        commentResponderTypeArquivo: childItem.val().commentResponderTypeArquivo == undefined ? '' : childItem.val().commentResponderTypeArquivo,
                        commentResponderIsAudio: childItem.val().commentResponderIsAudio == undefined ? '' : childItem.val().commentResponderIsAudio,
                        atendenteResponder: childItem.val().atendenteResponder == undefined ? '' : childItem.val().atendenteResponder,
                        captionArquivo: childItem.val().captionArquivo == undefined ? '' : childItem.val().captionArquivo,
                        read: childItem.val().read == undefined ? false : childItem.val().read,
                        deleteMessage: childItem.val().deleteMessage == undefined ? false : childItem.val().deleteMessage,
                        edited: childItem.val().edited ?? false,
                      });
                    }
                  });
                  if (verificaSeColocoInformacoesFinaisAtendimento) {
                    arrayAcoesAtendimento.push({
                      key: keyAtendimento + 'Fim',
                      title: `Fim do Atendimento: #${numeroChamado} - ${dataFechamentoChamado}`,
                      tipo: 'mensagem',
                      description: `Fim do Atendimento: #${numeroChamado} - ${dataFechamentoChamado}`,
                      statusMessage: 'sent',
                      time: "09:50",
                      dataHoraAtual: '01/01/2099',
                      lineColor: '#00FF7F',
                      circleColor: '#00FF7F',
                      visivelATodos: 1,
                    });

                  }

                  //enviaConversasUltimoChamado(dispatch, conversasAnteriores.concat(arrayAcoesAtendimento.reverse()));
                  enviaConversasCarregadasEmAtendimento(dispatch, conversasAnteriores.concat(arrayAcoesAtendimento.reverse()))
                  enviaUltimaReeferenciaKeyEmAtendimento(dispatch, referenceToOldestKey);
                  ativaDesativaLoadingCarregaChamadosAntigosEmAtendimento(dispatch, false);

                });
              } else {
                enviaUltimaReeferenciaKeyEmAtendimento(dispatch, undefined);
                ativaDesativaLoadingCarregaChamadosAntigosEmAtendimento(dispatch, false);
              }
            })
        } else {
          firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild("cliente").startAt(keyCliente).endAt(keyCliente, oldReferenceKey)
            .limitToLast(2).once('value').then((snapshot) => {

              if (snapshot.val()) {
                let arrayOfKeys = Object.keys(snapshot.val()).sort().reverse().slice(1);
                let referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];


                let arrayAcoesAtendimento = [];
                let arrayDadosAberturaChamado = [];
                let keyAtendimento = '';
                let verificaSeColocoInformacoesFinaisAtendimento = false;
                let statusChamado = '';
                let dataFechamentoChamado = '';
                let numeroChamado = '';
                let aux = 0;
                snapshot.forEach(childItem => {
                  if (aux === 0 && snapshot.numChildren() === 1 || aux === 1 && snapshot.numChildren() === 2) {
                    keyAtendimento = childItem.key;
                    verificaSeColocoInformacoesFinaisAtendimento = childItem.val().status === 'Aberto' || childItem.val().status === 'Atendendo' ? false : true;
                    statusChamado = childItem.val().status;
                    dataFechamentoChamado = childItem.val().status === 'Fechado' ? childItem.val().dataHoraFechamento : childItem.val().dataHoraCancelamento;
                    numeroChamado = childItem.val().numeroChamado;
                    arrayAcoesAtendimento.push({
                      key: childItem.key + 'inicio',
                      title: `Inicio do Atendimento: #${childItem.val().numeroChamado} - ${childItem.val().dataHoraAbertura}`,
                      tipo: 'mensagem',
                      description: `Inicio do Atendimento: #${childItem.val().numeroChamado} - ${childItem.val().dataHoraAbertura}`,
                      statusMessage: 'sent',
                      time: "09:50",
                      dataHoraAtual: '01/01/2099',
                      lineColor: '#00FF7F',
                      circleColor: '#00FF7F',
                      visivelATodos: 1,
                      //  dataHoraAbertura: `Inicio do Atendimento: #${childItem.val().numeroChamado} - ${childItem.val().dataHoraAbertura}`,
                      //  dataHoraFechamneto: `Fim do Atendimento: #${childItem.val().numeroChamado} - ${childItem.val().dataHoraFechamento}`,
                      // dataHoraCancelamento: `Fim do Atendimento: #${childItem.val().numeroChamado} - ${childItem.val().dataHoraCancelamento}`,
                    });
                  }
                  aux++;
                })

                firebase.database().ref(`${chaveUnica}/acoesChamados/${keyAtendimento}`).once('value').then((snapshot2) => {

                  /*  */
                  snapshot2.forEach(childItem => {
                    if (childItem.val().tipo !== undefined) {
                      /*    console.log(childItem.val()) */
                      arrayAcoesAtendimento.push({
                        key: childItem.key,
                        title: childItem.val().titulo,
                        tipo: childItem.val().tipo,
                        description: childItem.val().mensagem,
                        statusMessage: childItem.val().statusMessage == undefined ? 'sent' : childItem.val().statusMessage,
                        time: childItem.val().time,
                        dataHoraAtual: childItem.val().dataHoraAtual == undefined ? childItem.val().mensagem : childItem.val().dataHoraAtual,
                        lineColor: childItem.val().lineColor,
                        circleColor: childItem.val().circleColor,
                        urlDownload: childItem.val().urlDownload,
                        tipoArquivo: childItem.val().tipoArquivo,
                        duracao: childItem.val().duracao == undefined ? '' : childItem.val().duracao,
                        audio: childItem.val().audio == undefined ? false : childItem.val().audio,
                        visivelATodos: childItem.val().visivelATodos == undefined ? 1 : childItem.val().visivelATodos,
                        idMensagem: childItem.val().idMensagem == undefined ? childItem.key : childItem.val().idMensagem === '' ? childItem.key : childItem.val().idMensagem,
                        circleColorResponder: childItem.val().circleColorResponder == undefined ? '#00FF7F' : childItem.val().circleColorResponder,
                        commentResponderType: childItem.val().commentResponderType == undefined ? '' : childItem.val().commentResponderType,
                        idMensagemResponder: childItem.val().idMensagemResponder == undefined ? '' : childItem.val().idMensagemResponder,
                        mensagemResposta: childItem.val().mensagemResposta == undefined ? '' : childItem.val().mensagemResposta,
                        possuiResposta: childItem.val().possuiResposta == undefined ? false : childItem.val().possuiResposta,
                        commentResponderTypeArquivo: childItem.val().commentResponderTypeArquivo == undefined ? '' : childItem.val().commentResponderTypeArquivo,
                        commentResponderIsAudio: childItem.val().commentResponderIsAudio == undefined ? '' : childItem.val().commentResponderIsAudio,
                        atendenteResponder: childItem.val().atendenteResponder == undefined ? '' : childItem.val().atendenteResponder,
                        captionArquivo: childItem.val().captionArquivo == undefined ? '' : childItem.val().captionArquivo,
                        read: childItem.val().read == undefined ? false : childItem.val().read,
                        deleteMessage: childItem.val().deleteMessage == undefined ? false : childItem.val().deleteMessage,
                        edited: childItem.val().edited ?? false,
                      });
                    }
                  });
                  if (verificaSeColocoInformacoesFinaisAtendimento) {
                    arrayAcoesAtendimento.push({
                      key: keyAtendimento + 'Fim',
                      title: `Fim do Atendimento: #${numeroChamado} - ${dataFechamentoChamado}`,
                      tipo: 'mensagem',
                      description: `Fim do Atendimento: #${numeroChamado} - ${dataFechamentoChamado}`,
                      statusMessage: 'sent',
                      time: "09:50",
                      dataHoraAtual: '01/01/2099',
                      lineColor: '#00FF7F',
                      circleColor: '#00FF7F',
                      visivelATodos: 1,
                    });

                  }

                  //enviaConversasUltimoChamado(dispatch, conversasAnteriores.concat(arrayAcoesAtendimento.reverse()));
                  enviaConversasCarregadasEmAtendimento(dispatch, conversasAnteriores.concat(arrayAcoesAtendimento.reverse()))
                  enviaUltimaReeferenciaKeyEmAtendimento(dispatch, referenceToOldestKey);
                  ativaDesativaLoadingCarregaChamadosAntigosEmAtendimento(dispatch, false);

                });
              } else {
                enviaUltimaReeferenciaKeyEmAtendimento(dispatch, undefined);
                ativaDesativaLoadingCarregaChamadosAntigosEmAtendimento(dispatch, false);
              }
            })
        }
      } else {




        if (atendenteVeApenasProprioHistoricoChamado === true && contaPrincipal === false) {
          firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild("atendente_cliente").startAt(`${keyAtendente}${keyCliente}`).endAt(`${keyAtendente}${keyCliente}`, keyChamado)
            .limitToLast(3).once('value').then((snapshot) => {

              if (snapshot.val()) {
                let arrayOfKeys = Object.keys(snapshot.val()).sort().reverse().slice(1);
                let referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];


                let arrayAcoesAtendimento = [];
                let arrayDadosAberturaChamado = [];
                let keyAtendimento;
                let verificaSeColocoInformacoesFinaisAtendimento = false;
                let statusChamado = '';
                let dataFechamentoChamado = '';
                let numeroChamado = '';
                let aux = 0;
                snapshot.forEach(childItem => {
                  if (aux === 0 && snapshot.numChildren() === 2 || aux === 1 && snapshot.numChildren() === 3) {
                    keyAtendimento = childItem.key;
                    verificaSeColocoInformacoesFinaisAtendimento = childItem.val().status === 'Aberto' || childItem.val().status === 'Atendendo' ? false : true;
                    statusChamado = childItem.val().status;
                    dataFechamentoChamado = childItem.val().status === 'Fechado' ? childItem.val().dataHoraFechamento : childItem.val().dataHoraCancelamento;
                    numeroChamado = childItem.val().numeroChamado;
                    arrayAcoesAtendimento.push({
                      key: childItem.key + 'inicio',
                      title: `Inicio do Atendimento: #${childItem.val().numeroChamado} - ${childItem.val().dataHoraAbertura}`,
                      tipo: 'mensagem',
                      description: `Inicio do Atendimento: #${childItem.val().numeroChamado} - ${childItem.val().dataHoraAbertura}`,
                      statusMessage: 'sent',
                      time: "09:50",
                      dataHoraAtual: '01/01/2099',
                      lineColor: '#00FF7F',
                      circleColor: '#00FF7F',
                      visivelATodos: 1,
                      //  dataHoraAbertura: `Inicio do Atendimento: #${childItem.val().numeroChamado} - ${childItem.val().dataHoraAbertura}`,
                      //  dataHoraFechamneto: `Fim do Atendimento: #${childItem.val().numeroChamado} - ${childItem.val().dataHoraFechamento}`,
                      // dataHoraCancelamento: `Fim do Atendimento: #${childItem.val().numeroChamado} - ${childItem.val().dataHoraCancelamento}`,
                    });
                  }
                  aux++;
                })

                firebase.database().ref(`${chaveUnica}/acoesChamados/${keyAtendimento}`).once('value').then((snapshot2) => {

                  /*  */
                  snapshot2.forEach(childItem => {
                    if (childItem.val().tipo !== undefined) {
                      /*    console.log(childItem.val()) */
                      arrayAcoesAtendimento.push({
                        key: childItem.key,
                        title: childItem.val().titulo,
                        tipo: childItem.val().tipo,
                        description: childItem.val().mensagem,
                        statusMessage: childItem.val().statusMessage == undefined ? 'sent' : childItem.val().statusMessage,
                        time: childItem.val().time,
                        dataHoraAtual: childItem.val().dataHoraAtual == undefined ? childItem.val().mensagem : childItem.val().dataHoraAtual,
                        lineColor: childItem.val().lineColor,
                        circleColor: childItem.val().circleColor,
                        urlDownload: childItem.val().urlDownload,
                        tipoArquivo: childItem.val().tipoArquivo,
                        duracao: childItem.val().duracao == undefined ? '' : childItem.val().duracao,
                        audio: childItem.val().audio == undefined ? false : childItem.val().audio,
                        visivelATodos: childItem.val().visivelATodos == undefined ? 1 : childItem.val().visivelATodos,


                        idMensagem: childItem.val().idMensagem == undefined ? childItem.key : childItem.val().idMensagem === '' ? childItem.key : childItem.val().idMensagem,
                        circleColorResponder: childItem.val().circleColorResponder == undefined ? '#00FF7F' : childItem.val().circleColorResponder,
                        commentResponderType: childItem.val().commentResponderType == undefined ? '' : childItem.val().commentResponderType,
                        idMensagemResponder: childItem.val().idMensagemResponder == undefined ? '' : childItem.val().idMensagemResponder,
                        mensagemResposta: childItem.val().mensagemResposta == undefined ? '' : childItem.val().mensagemResposta,
                        possuiResposta: childItem.val().possuiResposta == undefined ? false : childItem.val().possuiResposta,
                        commentResponderTypeArquivo: childItem.val().commentResponderTypeArquivo == undefined ? '' : childItem.val().commentResponderTypeArquivo,
                        commentResponderIsAudio: childItem.val().commentResponderIsAudio == undefined ? '' : childItem.val().commentResponderIsAudio,
                        atendenteResponder: childItem.val().atendenteResponder == undefined ? '' : childItem.val().atendenteResponder,
                        captionArquivo: childItem.val().captionArquivo == undefined ? '' : childItem.val().captionArquivo,
                        read: childItem.val().read == undefined ? false : childItem.val().read,
                        deleteMessage: childItem.val().deleteMessage == undefined ? false : childItem.val().deleteMessage,
                        edited: childItem.val().edited ?? false,

                      });
                    }
                  });
                  if (verificaSeColocoInformacoesFinaisAtendimento) {
                    arrayAcoesAtendimento.push({
                      key: keyAtendimento + 'Fim',
                      title: `Fim do Atendimento: #${numeroChamado} - ${dataFechamentoChamado}`,
                      tipo: 'mensagem',
                      description: `Fim do Atendimento: #${numeroChamado} - ${dataFechamentoChamado}`,
                      statusMessage: 'sent',
                      time: "09:50",
                      dataHoraAtual: '01/01/2099',
                      lineColor: '#00FF7F',
                      circleColor: '#00FF7F',
                      visivelATodos: 1,
                    });

                  }

                  //enviaConversasUltimoChamado(dispatch, conversasAnteriores.concat(arrayAcoesAtendimento.reverse()));
                  /*    informacoesCompletas[keyAtendimento] = conversasAnteriores.concat(arrayAcoesAtendimento.reverse());
                             enviaConversasParaChatEmEspera(dispatch, informacoesCompletas); */
                  /*    console.log(arrayAcoesAtendimento.reverse()) */
                  if (conversasAnteriores.length > 0) {
                    enviaConversasCarregadasEmAtendimento(dispatch, conversasAnteriores.concat(arrayAcoesAtendimento.reverse()))
                  } else {
                    enviaConversasCarregadasEmAtendimento(dispatch, arrayAcoesAtendimento.reverse());
                  }

                  enviaUltimaReeferenciaKeyEmAtendimento(dispatch, referenceToOldestKey);
                  ativaDesativaLoadingCarregaChamadosAntigosEmAtendimento(dispatch, false);

                });
              } else {
                enviaUltimaReeferenciaKeyEmAtendimento(dispatch, undefined);
                ativaDesativaLoadingCarregaChamadosAntigosEmAtendimento(dispatch, false);
              }
            })
        } else {
          firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild("cliente").startAt(keyCliente).endAt(keyCliente, keyChamado)
            .limitToLast(3).once('value').then((snapshot) => {

              if (snapshot.val()) {
                let arrayOfKeys = Object.keys(snapshot.val()).sort().reverse().slice(1);
                let referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];


                let arrayAcoesAtendimento = [];
                let arrayDadosAberturaChamado = [];
                let keyAtendimento;
                let verificaSeColocoInformacoesFinaisAtendimento = false;
                let statusChamado = '';
                let dataFechamentoChamado = '';
                let numeroChamado = '';
                let aux = 0;
                snapshot.forEach(childItem => {
                  if (aux === 0 && snapshot.numChildren() === 2 || aux === 1 && snapshot.numChildren() === 3) {
                    keyAtendimento = childItem.key;
                    verificaSeColocoInformacoesFinaisAtendimento = childItem.val().status === 'Aberto' || childItem.val().status === 'Atendendo' ? false : true;
                    statusChamado = childItem.val().status;
                    dataFechamentoChamado = childItem.val().status === 'Fechado' ? childItem.val().dataHoraFechamento : childItem.val().dataHoraCancelamento;
                    numeroChamado = childItem.val().numeroChamado;
                    arrayAcoesAtendimento.push({
                      key: childItem.key + 'inicio',
                      title: `Inicio do Atendimento: #${childItem.val().numeroChamado} - ${childItem.val().dataHoraAbertura}`,
                      tipo: 'mensagem',
                      description: `Inicio do Atendimento: #${childItem.val().numeroChamado} - ${childItem.val().dataHoraAbertura}`,
                      statusMessage: 'sent',
                      time: "09:50",
                      dataHoraAtual: '01/01/2099',
                      lineColor: '#00FF7F',
                      circleColor: '#00FF7F',
                      visivelATodos: 1,
                      //  dataHoraAbertura: `Inicio do Atendimento: #${childItem.val().numeroChamado} - ${childItem.val().dataHoraAbertura}`,
                      //  dataHoraFechamneto: `Fim do Atendimento: #${childItem.val().numeroChamado} - ${childItem.val().dataHoraFechamento}`,
                      // dataHoraCancelamento: `Fim do Atendimento: #${childItem.val().numeroChamado} - ${childItem.val().dataHoraCancelamento}`,
                    });
                  }
                  aux++;
                })

                firebase.database().ref(`${chaveUnica}/acoesChamados/${keyAtendimento}`).once('value').then((snapshot2) => {

                  /*  */
                  snapshot2.forEach(childItem => {
                    if (childItem.val().tipo !== undefined) {
                      /*    console.log(childItem.val()) */
                      arrayAcoesAtendimento.push({
                        key: childItem.key,
                        title: childItem.val().titulo,
                        tipo: childItem.val().tipo,
                        description: childItem.val().mensagem,
                        statusMessage: childItem.val().statusMessage == undefined ? 'sent' : childItem.val().statusMessage,
                        time: childItem.val().time,
                        dataHoraAtual: childItem.val().dataHoraAtual == undefined ? childItem.val().mensagem : childItem.val().dataHoraAtual,
                        lineColor: childItem.val().lineColor,
                        circleColor: childItem.val().circleColor,
                        urlDownload: childItem.val().urlDownload,
                        tipoArquivo: childItem.val().tipoArquivo,
                        duracao: childItem.val().duracao == undefined ? '' : childItem.val().duracao,
                        audio: childItem.val().audio == undefined ? false : childItem.val().audio,
                        visivelATodos: childItem.val().visivelATodos == undefined ? 1 : childItem.val().visivelATodos,


                        idMensagem: childItem.val().idMensagem == undefined ? childItem.key : childItem.val().idMensagem === '' ? childItem.key : childItem.val().idMensagem,
                        circleColorResponder: childItem.val().circleColorResponder == undefined ? '#00FF7F' : childItem.val().circleColorResponder,
                        commentResponderType: childItem.val().commentResponderType == undefined ? '' : childItem.val().commentResponderType,
                        idMensagemResponder: childItem.val().idMensagemResponder == undefined ? '' : childItem.val().idMensagemResponder,
                        mensagemResposta: childItem.val().mensagemResposta == undefined ? '' : childItem.val().mensagemResposta,
                        possuiResposta: childItem.val().possuiResposta == undefined ? false : childItem.val().possuiResposta,
                        commentResponderTypeArquivo: childItem.val().commentResponderTypeArquivo == undefined ? '' : childItem.val().commentResponderTypeArquivo,
                        commentResponderIsAudio: childItem.val().commentResponderIsAudio == undefined ? '' : childItem.val().commentResponderIsAudio,
                        atendenteResponder: childItem.val().atendenteResponder == undefined ? '' : childItem.val().atendenteResponder,
                        captionArquivo: childItem.val().captionArquivo == undefined ? '' : childItem.val().captionArquivo,
                        read: childItem.val().read == undefined ? false : childItem.val().read,
                        deleteMessage: childItem.val().deleteMessage == undefined ? false : childItem.val().deleteMessage,
                        edited: childItem.val().edited ?? false,
                      });
                    }
                  });
                  if (verificaSeColocoInformacoesFinaisAtendimento) {
                    arrayAcoesAtendimento.push({
                      key: keyAtendimento + 'Fim',
                      title: `Fim do Atendimento: #${numeroChamado} - ${dataFechamentoChamado}`,
                      tipo: 'mensagem',
                      description: `Fim do Atendimento: #${numeroChamado} - ${dataFechamentoChamado}`,
                      statusMessage: 'sent',
                      time: "09:50",
                      dataHoraAtual: '01/01/2099',
                      lineColor: '#00FF7F',
                      circleColor: '#00FF7F',
                      visivelATodos: 1,
                    });

                  }

                  //enviaConversasUltimoChamado(dispatch, conversasAnteriores.concat(arrayAcoesAtendimento.reverse()));
                  /*    informacoesCompletas[keyAtendimento] = conversasAnteriores.concat(arrayAcoesAtendimento.reverse());
                             enviaConversasParaChatEmEspera(dispatch, informacoesCompletas); */
                  /*    console.log(arrayAcoesAtendimento.reverse()) */
                  if (conversasAnteriores.length > 0) {
                    enviaConversasCarregadasEmAtendimento(dispatch, conversasAnteriores.concat(arrayAcoesAtendimento.reverse()))
                  } else {
                    enviaConversasCarregadasEmAtendimento(dispatch, arrayAcoesAtendimento.reverse());
                  }

                  enviaUltimaReeferenciaKeyEmAtendimento(dispatch, referenceToOldestKey);
                  ativaDesativaLoadingCarregaChamadosAntigosEmAtendimento(dispatch, false);

                });
              } else {
                enviaUltimaReeferenciaKeyEmAtendimento(dispatch, undefined);
                ativaDesativaLoadingCarregaChamadosAntigosEmAtendimento(dispatch, false);
              }
            })
        }
      }

    })



  }
};

export const listaConversasAntigasResponder = (keyChamado, idMensagem, conversasAnteriores, rota,
  agruparAtendenteSetor, contaPrincipal, adminVisualizaApenasProprioSetor, keyAtendente, keyCliente, chamadoAbertoWpp,
  atendenteVeApenasProprioHistoricoChamado) => {
  return dispatch => {

    ativaDesativaLoadingCarregaChamadosAntigosEmAtendimento(dispatch, true);
    ativaDesativaLoadingCarregaChamadosAntigosEmEspera(dispatch, true);
    ativaDesativaLoadingCarregaChamadosAntigos(dispatch, true);
    ativaDesativaLoadingCarregaMaisChamadosAntigos(dispatch, true);


    AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");


      firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}`).once('value').then((snapshot) => {

        if (snapshot.val()) {
          ativaDesativaLoadingCarregaRespostasAntigas(dispatch, true);
          let dadosChamado = snapshot.val();
          let arrayOfKeys = Object.keys(snapshot.val()).sort().reverse().slice(1);
          let referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];


          let arrayAcoesAtendimento = [];
          let arrayDadosAberturaChamado = [];
          let keyAtendimento = '';
          let verificaSeColocoInformacoesFinaisAtendimento = false;
          let statusChamado = '';
          let dataFechamentoChamado = '';
          let numeroChamado = '';
          let aux = 0;
          let atendente_cliente = dadosChamado.atendente_cliente;


          /* snapshot.forEach(childItem => { */
          if (atendenteVeApenasProprioHistoricoChamado === true && contaPrincipal === false && atendente_cliente !== `${keyAtendente}${keyCliente}` && chamadoAbertoWpp === true) {
            ativaDesativaLoadingCarregaChamadosAntigosEmAtendimento(dispatch, false);
            ativaDesativaLoadingCarregaChamadosAntigosEmEspera(dispatch, false);
            ativaDesativaLoadingCarregaChamadosAntigos(dispatch, false);
            ativaDesativaLoadingCarregaMaisChamadosAntigos(dispatch, false);
            ativaDesativaLoadingCarregaRespostasAntigas(dispatch, false);
            toastr.error('Error', "Você não tem permissão para acessar histórico de conversas de outros atendentes");

          } else {
            keyAtendimento = keyChamado;
            verificaSeColocoInformacoesFinaisAtendimento = dadosChamado.status === 'Aberto' || dadosChamado.status === 'Atendendo' ? false : true;
            statusChamado = dadosChamado.status;
            dataFechamentoChamado = dadosChamado.status === 'Fechado' ? dadosChamado.dataHoraFechamento : dadosChamado.dataHoraCancelamento;
            numeroChamado = dadosChamado.numeroChamado;

            arrayAcoesAtendimento.push({
              key: keyChamado + 'inicio',
              title: `Inicio do Atendimento: #${dadosChamado.numeroChamado} - ${dadosChamado.dataHoraAbertura}`,
              tipo: 'mensagem',
              description: `Inicio do Atendimento: #${dadosChamado.numeroChamado} - ${dadosChamado.dataHoraAbertura}`,
              statusMessage: 'sent',
              time: "09:50",
              dataHoraAtual: '01/01/2099',
              lineColor: '#00FF7F',
              circleColor: '#00FF7F',
              visivelATodos: 1,
              //  dataHoraAbertura: `Inicio do Atendimento: #${childItem.val().numeroChamado} - ${childItem.val().dataHoraAbertura}`,
              //  dataHoraFechamneto: `Fim do Atendimento: #${childItem.val().numeroChamado} - ${childItem.val().dataHoraFechamento}`,
              // dataHoraCancelamento: `Fim do Atendimento: #${childItem.val().numeroChamado} - ${childItem.val().dataHoraCancelamento}`,
            });

            /* }) */

            firebase.database().ref(`${chaveUnica}/acoesChamados/${keyAtendimento}`).once('value').then((snapshot2) => {

              /*  */
              snapshot2.forEach(childItem => {
                /*    console.log(childItem.val()) */
                if (childItem.val().tipo !== undefined) {
                  arrayAcoesAtendimento.push({
                    key: childItem.key,
                    title: childItem.val().titulo,
                    tipo: childItem.val().tipo,
                    description: childItem.val().mensagem,
                    statusMessage: childItem.val().statusMessage == undefined ? 'sent' : childItem.val().statusMessage,
                    time: childItem.val().time,
                    dataHoraAtual: childItem.val().dataHoraAtual == undefined ? childItem.val().mensagem : childItem.val().dataHoraAtual,
                    lineColor: childItem.val().lineColor,
                    circleColor: childItem.val().circleColor,
                    urlDownload: childItem.val().urlDownload,
                    tipoArquivo: childItem.val().tipoArquivo,
                    duracao: childItem.val().duracao == undefined ? '' : childItem.val().duracao,

                    audio: childItem.val().audio == undefined ? false : childItem.val().audio,
                    visivelATodos: childItem.val().visivelATodos == undefined ? 1 : childItem.val().visivelATodos,
                    idMensagem: childItem.val().idMensagem == undefined ? childItem.key : childItem.val().idMensagem === '' ? childItem.key : childItem.val().idMensagem,
                    circleColorResponder: childItem.val().circleColorResponder == undefined ? '#00FF7F' : childItem.val().circleColorResponder,
                    commentResponderType: childItem.val().commentResponderType == undefined ? '' : childItem.val().commentResponderType,
                    idMensagemResponder: childItem.val().idMensagemResponder == undefined ? '' : childItem.val().idMensagemResponder,
                    mensagemResposta: childItem.val().mensagemResposta == undefined ? '' : childItem.val().mensagemResposta,
                    possuiResposta: childItem.val().possuiResposta == undefined ? false : childItem.val().possuiResposta,
                    commentResponderTypeArquivo: childItem.val().commentResponderTypeArquivo == undefined ? '' : childItem.val().commentResponderTypeArquivo,
                    commentResponderIsAudio: childItem.val().commentResponderIsAudio == undefined ? '' : childItem.val().commentResponderIsAudio,
                    atendenteResponder: childItem.val().atendenteResponder == undefined ? '' : childItem.val().atendenteResponder,
                    captionArquivo: childItem.val().captionArquivo == undefined ? '' : childItem.val().captionArquivo,
                    read: childItem.val().read == undefined ? false : childItem.val().read,
                    deleteMessage: childItem.val().deleteMessage == undefined ? false : childItem.val().deleteMessage,
                    edited: childItem.val().edited ?? false,
                  });
                }
              });
              if (verificaSeColocoInformacoesFinaisAtendimento) {
                arrayAcoesAtendimento.push({
                  key: keyAtendimento + 'Fim',
                  title: `Fim do Atendimento: #${numeroChamado} - ${dataFechamentoChamado}`,
                  tipo: 'mensagem',
                  description: `Fim do Atendimento: #${numeroChamado} - ${dataFechamentoChamado}`,
                  statusMessage: 'sent',
                  time: "09:50",
                  dataHoraAtual: '01/01/2099',
                  lineColor: '#00FF7F',
                  circleColor: '#00FF7F',
                  visivelATodos: 1,
                });

              } else {
                arrayAcoesAtendimento.push({
                  key: keyAtendimento + 'Andamento',
                  title: `Conversa em Andamento: #${numeroChamado}`,
                  tipo: 'mensagem',
                  description: `Conversa em Andamento: #${numeroChamado}`,
                  statusMessage: 'sent',
                  time: "09:50",
                  dataHoraAtual: '01/01/2099',
                  lineColor: '#00FF7F',
                  circleColor: '#00FF7F',
                  visivelATodos: 1,
                });
              }

              //enviaConversasUltimoChamado(dispatch, conversasAnteriores.concat(arrayAcoesAtendimento.reverse()));
              if (rota === 'emEspera') {
                enviaConversasCarregadasEmEspera(dispatch, arrayAcoesAtendimento.reverse());
              }
              if (rota === 'emAtendimento') {
                enviaConversasCarregadasEmAtendimento(dispatch, arrayAcoesAtendimento.reverse());
              }
              if (rota === 'contacts') {
                enviaConversasUltimoChamado(dispatch, conversasAnteriores.concat(arrayAcoesAtendimento.reverse()));
              }



              enviaIdRespostaAntiga(dispatch, idMensagem)
              /* enviaUltimaReeferenciaKeyEmAtendimento(dispatch, keyChamado); */
              ativaDesativaLoadingCarregaChamadosAntigosEmAtendimento(dispatch, false);
              ativaDesativaLoadingCarregaChamadosAntigosEmEspera(dispatch, false);
              ativaDesativaLoadingCarregaChamadosAntigos(dispatch, false);
              ativaDesativaLoadingCarregaMaisChamadosAntigos(dispatch, false);
              ativaDesativaLoadingCarregaRespostasAntigas(dispatch, false);

            });
          }
        } else {
          enviaUltimaReeferenciaKeyEmAtendimento(dispatch, undefined);
          enviaUltimaReeferenciaKeyEmEspera(dispatch, undefined);
          enviaUltimaReeferenciaKeyContacts(dispatch, undefined)
          ativaDesativaLoadingCarregaChamadosAntigosEmAtendimento(dispatch, false);
          ativaDesativaLoadingCarregaChamadosAntigosEmEspera(dispatch, false);
          ativaDesativaLoadingCarregaChamadosAntigos(dispatch, false);
          ativaDesativaLoadingCarregaMaisChamadosAntigos(dispatch, false);
        }
      })


    })



  }
};

export const iniciaAtendimentoPorContacts = (keyCliente, keyAtendente, nomeAtendente, telefoneCliente, chamadoEmAndamento, keyChamado, statusChamado, keySetor, numeroChamado, arrayDadosParaComentar) => {
  return dispatch => {
    console.log('telefoneCliente', telefoneCliente);
    AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");
      let keyDeQuemAbriuChamado = keyAtendente;
      let problemaEstaticoSelecionado = '';
      let dataVencimento = 'Não informado';
      let clienteAtendimento = keyCliente;
      let prioridadeAtendimento = 'Não informado';
      let atendenteAtendimento = keyAtendente;
      let keyAtendimento = keyChamado;
      let setorAtendimento = 'Não informado';
      let localAtendimento = 'Não informado';
      let chamadoAbertoPor = 'admin';
      let dadosAtendVinc = '';
      const dataAbertura = retornaDataAtual();
      const timeAtual = retornaHoraAtual();
      const dataHoraAtual = retornaDataHoraAtual();
      let descricaoAtendimento = `Conversa Iniciada por ${nomeAtendente}`;
      let numeroAtual = ''
      let keyNumeracao = '';
      if (chamadoEmAndamento === true) {
        if (statusChamado === 'Aberto') {
          firebase.database().ref(`${chaveUnica}/acoesChamados/${keyChamado}/`).push({
            tipo: "mensagem",
            titulo: nomeAtendente,
            mensagem: `${nomeAtendente} entrou no Chat`,
            dataHoraAtual: dataHoraAtual,
            dataAtualPostagem: dataAbertura,
            time: timeAtual,
            lineColor: "#00FF7F",
            circleColor: "#00FF7F",
            visivelATodos: 1,
            keyDeQuemAlterouChamado: keyChamado
          }).then(() => {

            firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}`).update({
              atendente: keyAtendente,
              status: "Atendendo",
              status_atendente: `Atendendo${keyAtendente}`,
              keyAtendenteQueAlterouChamado: keyAtendente,

              status_cliente: `Atendendo${keyCliente}`, //filtro cliente comum
              status_setor: `Atendendo${keySetor}`, //filtro setor comum
              status_numeroChamado: `Atendendo_${numeroChamado}`,

              status_atendente_cliente: `Atendendo${keyAtendente}${keyCliente}`, //filtro cliente vê proprio chamado
              status_atendente_setor: `Atendendo${keyAtendente}${keySetor}`, //filtro setor vê proprio chamado
              status_atendente_numeroChamado: `Atendendo_${keyAtendente}${numeroChamado}`,

              atendente_cliente: `${keyAtendente}${keyCliente}`,
              atendente_setor: `${keyAtendente}${keySetor}`,
              atendente_numeroChamado: `${keyAtendente}${numeroChamado}`,
              status_atendente_abertoviachat: `Atendendo${keyAtendente}true`,
              chamadoAbertoPorWpp: true,
              lastMessageChat: `${nomeAtendente} entrou no Chat`,
              lastMessageChatTimeStamp: firebase.database.ServerValue.TIMESTAMP,
              lastmessageChatCount: 0,
              lastmessageChatType: 'mensagem',
              lastMessageLineColor: '#00FF7F',
              telefoneCliente: telefoneCliente
            }).then(() => {

              if (setorAtendimento !== "Não informado") {
                //busco clientes vinculados ao setor, se nao tiver eu seto todos os atendentes se tiver seto apenas os atendentes vinculados
                firebase.database().ref(`${chaveUnica}/setores/${setorAtendimento}/atendentesVinculados`).once('value').then((snapshotSetor) => {
                  if (snapshotSetor.val()) {
                    let dadosAtendVinc = {};

                    let keyAtendentesVinculados = Object.keys(snapshotSetor.val());

                    for (let i = 0; i < keyAtendentesVinculados.length; i++) {
                      if (keyAtendentesVinculados[i].includes('codigoInterno')) {
                        dadosAtendVinc[keyAtendentesVinculados[i] + "status"] = keyAtendentesVinculados[i] + "Atendendo";
                      }
                    }


                    //tem atendentes vinculados ao setor
                    const dadosAtendentesVinculadosSetor = [];
                    snapshotSetor.forEach((childItem) => {
                      dadosAtendentesVinculadosSetor.push({
                        key: childItem.key,
                      });
                    })

                    let verificoSeAtendenteDoChamadoNaoEstaNoSetorVinculado = 0;
                    if (atendenteAtendimento !== 'Não informado') {
                      //verifico se o atendente do chamado nao esta vinculado a este setor
                      for (let i = 0; i < dadosAtendentesVinculadosSetor.length; i++) {
                        if (dadosAtendentesVinculadosSetor[i].key == atendenteAtendimento) {
                          verificoSeAtendenteDoChamadoNaoEstaNoSetorVinculado = 1;

                        }
                      }
                      //atendente nao esta no grupo porem foi vinculado ao atendimento, coloco ele nos vinculados do chamado
                      if (verificoSeAtendenteDoChamadoNaoEstaNoSetorVinculado == 0) {
                        let objetocadastro = {};
                        firebase.database().ref(`${chaveUnica}/usuarios/${atendenteAtendimento}`).once("value").then((snapshot) => {
                          let key = snapshot.key;
                          let codigoInterno = snapshot.val().codigoInterno;
                          //  objetocadastro[codigoInterno] = codigoInterno;
                          objetocadastro[codigoInterno + "status"] = codigoInterno + "Atendendo";

                          //  objetocadastro[key] = true;

                          firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/usuariosVisualizaAtend/dadosAtendVinc`).update(objetocadastro)
                        })

                      } else {
                        //creio que ja cai na situaçaão acima
                      }
                    }

                    firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/usuariosVisualizaAtend`).update({ dadosAtendVinc })
                  } else {
                    //
                    firebase.database().ref(`${chaveUnica}/usuarios/`).orderByChild('excluido').equalTo(null).once('value').then((snapshot) => {
                      const idTodosAtendentes = [];
                      let objetocadastro = {};
                      snapshot.forEach((childItem) => {
                        idTodosAtendentes.push({
                          key: childItem.key,
                          codigoInterno: childItem.val().codigoInterno == undefined ? 'codigoInterno1' : childItem.val().codigoInterno,
                        });
                      })
                      //
                      for (let i = 0; i < idTodosAtendentes.length; i++) {
                        // objetocadastro[idTodosAtendentes[i].key] = true;
                        objetocadastro[idTodosAtendentes[i].codigoInterno] = idTodosAtendentes[i].codigoInterno;
                        objetocadastro[idTodosAtendentes[i].codigoInterno + "status"] = idTodosAtendentes[i].codigoInterno + "Atendendo";

                      }
                      //
                      firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/usuariosVisualizaAtend/dadosAtendVinc`).update(objetocadastro)
                    })

                  }
                })
              } else if (atendenteAtendimento !== "Não informado") {
                let dadosAtendVinc = {};
                firebase.database().ref(`${chaveUnica}/usuarios/${atendenteAtendimento}`).once("value").then((snapshot) => {
                  let key = snapshot.key;
                  let codigoInterno = snapshot.val().codigoInterno;
                  //  objetocadastro[codigoInterno] = codigoInterno;
                  dadosAtendVinc[codigoInterno + "status"] = codigoInterno + "Atendendo";


                  // dadosAtendVinc[key] = true;

                  //
                  // firebase.database().ref(`${chaveUnica}/dadosAtendVinc/${key}/${dadosChamado.key}`).push({ true: 'true' });
                  firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/usuariosVisualizaAtend/`).remove().then(
                    () => {
                      firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/usuariosVisualizaAtend/`).update(dadosAtendVinc)
                    })
                  //firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/usuariosVisualizaAtend/`).update(dadosAtendVinc)
                })
              } else {
                let dadosAtendVinc = { vazio: 'vazio' };
                firebase
                  .database()
                  .ref(`${chaveUnica}/chamados/${keyAtendimento}`)
                  .update({ usuariosVisualizaAtend: dadosAtendVinc })
              }
              dispatch({
                type: MODIFICAKEYATENDIMENTOEMESPERAATENDIMENTOONLINE,
                payload: ''
              })
              dispatch({
                type: MODIFICAKEYCONTACTS,
                payload: ''
              })
              dispatch({
                type: MODIFICAKEYATENDIMENTOEMTENDIMENTOONLINE,
                payload: keyChamado
              })
              ativaDesativaLoadingIniciandoCOnversaContacts(dispatch, false);

            })
          })
        } else {
          //Atendendo
          firebase.database().ref(`${chaveUnica}/proximaNumeracao/`).orderByChild('numeroAtual').once('value').then((snapshot) => {
            if (snapshot.val() === null) {
              numeroAtual = 1;
              firebase.database().ref(`${chaveUnica}/proximaNumeracao`).push({ numeroAtual: 1 });
            } else {
              snapshot.forEach(childItem => {
                numeroAtual = childItem.val().numeroAtual;
                keyNumeracao = childItem.key;
              })

            }
            numeroAtual = numeroAtual + 1;
            firebase.database().ref(`${chaveUnica}/proximaNumeracao/${keyNumeracao}`).update({ numeroAtual: numeroAtual });
            firebase.database().ref(`${chaveUnica}/chamados/`).push({
              atendente: keyAtendente,
              cliente: clienteAtendimento,
              setor: setorAtendimento,
              prioridade: prioridadeAtendimento,
              local: localAtendimento,
              descricaoProblema: descricaoAtendimento,
              descricaoProblemaEstatico: 0,
              keyProblemaEstatico: problemaEstaticoSelecionado,
              dataAbertura: dataAbertura,
              dataHoraAbertura: dataHoraAtual,
              abertoPor: keyDeQuemAbriuChamado,
              status: "Atendendo",
              alterado: 0,
              telefoneCliente: telefoneCliente,
              numeroChamado: numeroAtual,
              chamadoAbertoPorAdminOuCliente: chamadoAbertoPor,
              dataVencimento: dataVencimento,
              tempoGasto: 0,
              clienteVinculado: 1,
              status_atendente: `Atendendo${keyAtendente}`,
              dataAberturaTimeStamp: firebase.database.ServerValue.TIMESTAMP,
              setorVinculado: 0,
              keyEmpresaVinculada: 'Não Vinculado',
              channelChamado: 'clienteChatWeb',
              abertoPorChat: `${keyDeQuemAbriuChamado}Chat`,
              status_atendente_abertoviachat: `Atendendo${atendenteAtendimento}true`,
              chamadoAbertoPorWpp: true,
              tempoPrimeiraResposta: 0,
              keyDeQuemAlterouChamado: keyAtendente,
              conversaIniciadaViaContacts: true,
              status_cliente: `Atendendo${keyCliente}`, //filtro cliente comum
              status_setor: `Atendendo${keySetor}`, //filtro setor comum
              status_numeroChamado: `Atendendo_${numeroChamado}`,

              status_atendente_cliente: `Atendendo${keyAtendente}${keyCliente}`, //filtro cliente vê proprio chamado
              status_atendente_setor: `Atendendo${keyAtendente}${keySetor}`, //filtro setor vê proprio chamado
              status_atendente_numeroChamado: `Atendendo_${keyAtendente}${numeroChamado}`,

              atendente_cliente: `${keyAtendente}${keyCliente}`,
              atendente_setor: `${keyAtendente}${keySetor}`,
              atendente_numeroChamado: `${keyAtendente}${numeroChamado}`,
              lastMessageChat: `${nomeAtendente} entrou no Chat`,
              lastMessageChatTimeStamp: firebase.database.ServerValue.TIMESTAMP,
              lastmessageChatCount: 0,
              lastmessageChatType: 'mensagem',
              lastMessageLineColor: '#00FF7F'
            }).then((dadosChamado) => {
              let keyAtendimento = dadosChamado.key;
              firebase.database().ref(`${chaveUnica}/acoesChamados/${keyAtendimento}/`).push({
                tipo: "mensagem",
                titulo: nomeAtendente,
                mensagem: `${nomeAtendente} entrou no Chat`,
                dataHoraAtual: dataHoraAtual,
                dataAtualPostagem: dataAbertura,
                time: timeAtual,
                lineColor: "#00FF7F",
                circleColor: "#00FF7F",
                visivelATodos: 1,
                keyDeQuemAlterouChamado: keyChamado
              }).then(() => {

                if (setorAtendimento !== "Não informado") {
                  //busco clientes vinculados ao setor, se nao tiver eu seto todos os atendentes se tiver seto apenas os atendentes vinculados
                  firebase.database().ref(`${chaveUnica}/setores/${setorAtendimento}/atendentesVinculados`).once('value').then((snapshotSetor) => {
                    if (snapshotSetor.val()) {
                      let dadosAtendVinc = {};

                      let keyAtendentesVinculados = Object.keys(snapshotSetor.val());

                      for (let i = 0; i < keyAtendentesVinculados.length; i++) {
                        if (keyAtendentesVinculados[i].includes('codigoInterno')) {
                          dadosAtendVinc[keyAtendentesVinculados[i] + "status"] = keyAtendentesVinculados[i] + "Atendendo";
                        }
                      }


                      //tem atendentes vinculados ao setor
                      const dadosAtendentesVinculadosSetor = [];
                      snapshotSetor.forEach((childItem) => {
                        dadosAtendentesVinculadosSetor.push({
                          key: childItem.key,
                        });
                      })

                      let verificoSeAtendenteDoChamadoNaoEstaNoSetorVinculado = 0;
                      if (atendenteAtendimento !== 'Não informado') {
                        //verifico se o atendente do chamado nao esta vinculado a este setor
                        for (let i = 0; i < dadosAtendentesVinculadosSetor.length; i++) {
                          if (dadosAtendentesVinculadosSetor[i].key == atendenteAtendimento) {
                            verificoSeAtendenteDoChamadoNaoEstaNoSetorVinculado = 1;

                          }
                        }
                        //atendente nao esta no grupo porem foi vinculado ao atendimento, coloco ele nos vinculados do chamado
                        if (verificoSeAtendenteDoChamadoNaoEstaNoSetorVinculado == 0) {
                          let objetocadastro = {};
                          firebase.database().ref(`${chaveUnica}/usuarios/${atendenteAtendimento}`).once("value").then((snapshot) => {
                            let key = snapshot.key;
                            let codigoInterno = snapshot.val().codigoInterno;
                            //  objetocadastro[codigoInterno] = codigoInterno;
                            objetocadastro[codigoInterno + "status"] = codigoInterno + "Atendendo";

                            // objetocadastro[key] = true;

                            firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/usuariosVisualizaAtend/dadosAtendVinc`).update(objetocadastro)
                          })

                        } else {
                          //creio que ja cai na situaçaão acima
                        }
                      }

                      firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/usuariosVisualizaAtend`).update({ dadosAtendVinc })
                    } else {
                      //
                      firebase.database().ref(`${chaveUnica}/usuarios/`).orderByChild('excluido').equalTo(null).once('value').then((snapshot) => {
                        const idTodosAtendentes = [];
                        let objetocadastro = {};
                        snapshot.forEach((childItem) => {
                          idTodosAtendentes.push({
                            key: childItem.key,
                            codigoInterno: childItem.val().codigoInterno == undefined ? 'codigoInterno1' : childItem.val().codigoInterno,
                          });
                        })
                        //
                        for (let i = 0; i < idTodosAtendentes.length; i++) {
                          // objetocadastro[idTodosAtendentes[i].key] = true;
                          objetocadastro[idTodosAtendentes[i].codigoInterno] = idTodosAtendentes[i].codigoInterno;
                          objetocadastro[idTodosAtendentes[i].codigoInterno + "status"] = idTodosAtendentes[i].codigoInterno + "Atendendo";

                        }
                        //
                        firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/usuariosVisualizaAtend/dadosAtendVinc`).update(objetocadastro)
                      })

                    }
                  })
                } else if (atendenteAtendimento !== "Não informado") {
                  let dadosAtendVinc = {};
                  firebase.database().ref(`${chaveUnica}/usuarios/${atendenteAtendimento}`).once("value").then((snapshot) => {
                    let key = snapshot.key;
                    let codigoInterno = snapshot.val().codigoInterno;
                    //  objetocadastro[codigoInterno] = codigoInterno;
                    dadosAtendVinc[codigoInterno + "status"] = codigoInterno + "Atendendo";


                    //  dadosAtendVinc[key] = true;

                    //
                    // firebase.database().ref(`${chaveUnica}/dadosAtendVinc/${key}/${dadosChamado.key}`).push({ true: 'true' });

                    firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/usuariosVisualizaAtend/`).remove().then(
                      () => {
                        firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/usuariosVisualizaAtend/`).update(dadosAtendVinc)
                      })
                    // firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/usuariosVisualizaAtend/`).update(dadosAtendVinc)
                  })
                } else {
                  let dadosAtendVinc = { vazio: 'vazio' };
                  firebase
                    .database()
                    .ref(`${chaveUnica}/chamados/${keyAtendimento}`)
                    .update({ usuariosVisualizaAtend: dadosAtendVinc })
                }
                dispatch({
                  type: MODIFICAKEYATENDIMENTOEMESPERAATENDIMENTOONLINE,
                  payload: ''
                })
                dispatch({
                  type: MODIFICAKEYCONTACTS,
                  payload: ''
                })
                dispatch({
                  type: MODIFICAKEYATENDIMENTOEMTENDIMENTOONLINE,
                  payload: keyChamado
                })
                ativaDesativaLoadingIniciandoCOnversaContacts(dispatch, false);

              })
            })
          })
        }
      } else {
        firebase.database().ref(`${chaveUnica}/proximaNumeracao/`).orderByChild('numeroAtual').once('value').then((snapshot) => {
          if (snapshot.val() === null) {
            numeroAtual = 1;
            firebase.database().ref(`${chaveUnica}/proximaNumeracao`).push({ numeroAtual: 1 });
          } else {
            snapshot.forEach(childItem => {
              numeroAtual = childItem.val().numeroAtual;
              keyNumeracao = childItem.key;
            })

          }
          numeroAtual = numeroAtual + 1;
          firebase.database().ref(`${chaveUnica}/proximaNumeracao/${keyNumeracao}`).update({ numeroAtual: numeroAtual });
          firebase.database().ref(`${chaveUnica}/chamados/`).push({
            cliente: clienteAtendimento,
            atendente: atendenteAtendimento,
            setor: setorAtendimento,
            prioridade: prioridadeAtendimento,
            local: localAtendimento,
            descricaoProblema: descricaoAtendimento,
            descricaoProblemaEstatico: 0,
            keyProblemaEstatico: problemaEstaticoSelecionado,
            dataAbertura: dataAbertura,
            dataHoraAbertura: dataHoraAtual,
            abertoPor: keyDeQuemAbriuChamado,
            status: "Atendendo",
            alterado: 0,
            telefoneCliente: telefoneCliente,
            numeroChamado: numeroAtual,
            chamadoAbertoPorAdminOuCliente: chamadoAbertoPor,
            dataVencimento: dataVencimento,
            tempoGasto: 0,
            clienteVinculado: 1,
            status_atendente: `Atendendo${keyAtendente}`,
            dataAberturaTimeStamp: firebase.database.ServerValue.TIMESTAMP,
            setorVinculado: 0,
            keyEmpresaVinculada: 'Não Vinculado',
            status_cliente: `Atendendo${keyCliente}`,
            status_setor: `Atendendo${setorAtendimento}`, //filtro setor comum
            status_numeroChamado: `Atendendo${numeroAtual}`, // filtro local comum
            channelChamado: 'clienteChatWeb',
            abertoPorChat: `${keyDeQuemAbriuChamado}Chat`,
            status_atendente_abertoviachat: `Atendendo${atendenteAtendimento}true`,
            chamadoAbertoPorWpp: true,
            tempoPrimeiraResposta: 0,
            keyDeQuemAlterouChamado: keyAtendente,
            conversaIniciadaViaContacts: true,
            atendente_cliente: `${keyAtendente}${keyCliente}`,
            atendente_setor: `${keyAtendente}${keySetor}`,
            atendente_numeroChamado: `${keyAtendente}${numeroAtual}`,
            lastMessageChat: `Conversa Iniciada por ${nomeAtendente}`,
            lastMessageChatTimeStamp: firebase.database.ServerValue.TIMESTAMP,
            lastmessageChatCount: 0,
            lastmessageChatType: 'mensagem',
            lastMessageLineColor: '#39a3f4'
          }).then(
            (dadosChamado) => {
              let keyChamado = dadosChamado.key;
              firebase.database().ref(`${chaveUnica}/acoesChamados/${dadosChamado.key}/`)
                .push({
                  tipo: "mensagem",
                  titulo:
                    "Atendimento criado por: " + nomeAtendente,
                  criadoPor: keyDeQuemAbriuChamado,
                  mensagem: `Conversa Iniciada por ${nomeAtendente}`,
                  dataHoraAtual: dataHoraAtual,
                  time: timeAtual,
                  lineColor: "#00FF7F",
                  circleColor: "#00FF7F",
                  dataAtualPostagem: dataAbertura,
                  visivelATodos: 1
                })
                .then(() => {
                  let dadosCompletoChamado = {
                    cliente: clienteAtendimento,
                    atendente: atendenteAtendimento,
                    setor: setorAtendimento,
                    prioridade: prioridadeAtendimento,
                    local: localAtendimento,
                    descricaoProblema: descricaoAtendimento,
                    descricaoProblemaEstatico: 0,
                    keyProblemaEstatico: problemaEstaticoSelecionado,
                    dataAbertura: dataAbertura,
                    dataHoraAbertura: dataHoraAtual,
                    abertoPor: keyDeQuemAbriuChamado,
                    status: "Atendendo",
                    alterado: 0,
                    telefoneCliente: telefoneCliente,
                    numeroChamado: numeroAtual,
                    chamadoAbertoPorAdminOuCliente: chamadoAbertoPor,
                    dataVencimento: dataVencimento,
                    tempoGasto: 0,
                    clienteVinculado: 1,
                    status_atendente: `Atendendo${keyAtendente}`,
                    dataAberturaTimeStamp: firebase.database.ServerValue.TIMESTAMP,
                    setorVinculado: 0,
                    keyEmpresaVinculada: 'Não Vinculado',
                    status_cliente: `Atendendo${keyCliente}`,
                    status_setor: `Atendendo${setorAtendimento}`, //filtro setor comum
                    status_numeroChamado: `Atendendo${numeroAtual}`, // filtro local comum
                    atendente_cliente: `${keyAtendente}${keyCliente}`,
                    atendente_setor: `${keyAtendente}${keySetor}`,
                    atendente_numeroChamado: `${keyAtendente}${numeroAtual}`,
                    channelChamado: 'clienteChatWeb',
                    abertoPorChat: `${keyDeQuemAbriuChamado}Chat`,
                    status_atendente_abertoviachat: `Atendendo${atendenteAtendimento}true`,
                    chamadoAbertoPorWpp: true,
                    tempoPrimeiraResposta: 0,
                    keyDeQuemAlterouChamado: keyAtendente,
                    conversaIniciadaViaContacts: true
                  }
                  if (arrayDadosParaComentar.length > 0) {
                    let eArr = arrayDadosParaComentar.values();
                    let aux = 0
                    for (let letter of eArr) {

                      if (letter === 'keyChamado') {
                        arrayDadosParaComentar[aux] = keyChamado;
                      }
                      if (letter === 'dataAbertura') {
                        arrayDadosParaComentar[aux] = dadosCompletoChamado.dataAbertura;
                      }
                      if (letter === 'arrayAtendimentoCompleto') {
                        arrayDadosParaComentar[aux] = dadosCompletoChamado;
                      }
                      aux++
                    }
                    dispatch(salvarComentario(...arrayDadosParaComentar))
                  }
                  dispatch({
                    type: MODIFICAKEYATENDIMENTOEMESPERAATENDIMENTOONLINE,
                    payload: ''
                  })
                  dispatch({
                    type: MODIFICAKEYCONTACTS,
                    payload: ''
                  })
                  dispatch({
                    type: MODIFICAKEYATENDIMENTOEMTENDIMENTOONLINE,
                    payload: dadosChamado.key
                  })
                  ativaDesativaLoadingIniciandoCOnversaContacts(dispatch, false);
                })
            })

        })
      }
      firebase.database().ref(`${chaveUnica}/clientes/${keyCliente}`).update({ statusAndamento: 3 });
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          'Accept': 'application/json',
        },

        data: { chaveUnica: chaveUnica, numeroCliente: telefoneCliente, status: 3 },
        url: 'updateCache',
      };
      api(options).then();
    })
  }
}


export const cadastraClienteEmMassa = (
  arrayCliente
) => {
  return dispatch => {
    /*  */
    dispatch({ type: MODIFICALOADINGCADASTRANDOCLIENTEEMMASSA, payload: true });
    dispatch({ type: ENVIAULTIAMREFERENCIAKEYTELACONSULTACLIENTE, payload: '' });
    AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");
      let aux = 0;
      for (let i = 0; i < arrayCliente.length; i++) {
        setTimeout(() => {
          aux++;
          let telefone = arrayCliente[i].telefone == undefined ? '' : arrayCliente[i].telefone.toString();
          let endereco = arrayCliente[i].endereco == undefined ? '' : arrayCliente[i].endereco.toString();
          let nome = arrayCliente[i].nome.toString();
          let empresa = arrayCliente[i].empresa.toString();
          /*  */
          //cadastrando o cliente na tabela principal
          firebase
            .database()
            .ref(`${chaveUnica}/clientes`)
            .push({
              nome: nome,
              empresaPesquisa: '',
              email: "",
              empresa: empresa,
              senha: "",
              permissaoAPP: false,
              alterado: 0,
              notifNovoAtendimento: 1,
              notifRespNosAtendimentos: 1,
              vinculadoEmpresa: false,
              keyEmpresaVinculada: "Não Vinculado",
              enderecoCliente: endereco,
              telefoneCliente: telefone,
              urlImagemNuvem: ""
            });
          if (aux == arrayCliente.length) {
            dispatch({ type: MODIFICALOADINGCADASTRANDOCLIENTEEMMASSA, payload: false });
            dispatch({ type: MODIFICAVISIBILIDADEMODALIMPORTARCLIENTES, payload: false });
            toastr.success('Sucesso', 'Cadastro em massa realizado com sucesso!')
          }
        }, 200);
      }

    });



  }
};
function getUserContacts(clientes, session, chaveUnica, urlApi, apiKey) {
  const contacts = clientes.filter((entry) => !!entry.isMyContact && entry.isMyContact === true && !!entry.telefoneCliente);
  let parseContacts = [];
  for (let i = 0; i < contacts.length; i++) {
    parseContacts.push({
      nome: contacts[i].nome,
      phone: contacts[i].telefoneCliente,
      key: contacts[i].key,
      urlImagem: contacts[i].urlImagemNuvem === '' ? null : contacts[i].urlImagemNuvem,
      session: session,
      chaveUnica: chaveUnica,
      urlApi,
      apiKey,
    })
  }
  return parseContacts;
}


export const getAllContatcsWpp = (configWhatsapp) => {
  return dispatch => {

    ativaDesativaLoadingCarregaContatosWpp(dispatch, true);
    insereMensagemErrorGetContacts(dispatch, false);
    let apiKey = '';
    let urlApi = '';
    let sessionclient = '';
    let statusConnect = '';
    let utilizaVersaoBETA = '';


    if (configWhatsapp !== undefined && configWhatsapp.length > 0) {

      apiKey = configWhatsapp[0].apiKey;
      urlApi = configWhatsapp[0].urlApi;
      sessionclient = configWhatsapp[0].sessionclient;
      statusConnect = configWhatsapp[0].statusConnect;
      utilizaVersaoBETA = configWhatsapp[0].utilizaVersaoBETA;
    }
    AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");
      let data = {
        session: sessionclient,
        chaveUnica: chaveUnica,
        urlApi,
        apiKey,
        type: 'allcontacts'
      }
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          'Accept': 'application/json',
        },

        data: data,
        url: 'getAllContactsWppSocket',
      };
      api(options).then(async (response) => {
        const contatos = response.data;
        const contatosBySystem = await loadContacts('', 0);
        const mergedContacts = [...contatos, ...contatosBySystem];
        const eligibleContacts = removeItemsDuplicateFromListByValue(mergedContacts, 'phone');

        enviaContatosParaReducer(dispatch, eligibleContacts);
        ativaDesativaLoadingCarregaContatosWpp(dispatch, false);
        insereMensagemErrorGetContacts(dispatch, false);

      }).catch((err) => {
        ativaDesativaLoadingCarregaContatosWpp(dispatch, false);
        insereMensagemErrorGetContacts(dispatch, true);
      })
    })
  }
}


const fileUpload = (file, ref, uuid) => {
  const newFile = renameFile(file, uuid);
  const uploadTask = ref.put(newFile);
  return new Promise((resolve, reject) => {
    uploadTask.on(
      "state_changed",
      (snapshot) => {

      },
      (error) => {
        reject(error);
      },
      () => {
        ref
          .getDownloadURL()
          .then((url) => {
            resolve(url);
          });
      }
    );
  });
}

export const uploadArquivoChat = (
  nomeArquivo, statusFechar, comentario2, resArquivo, comentarioPublicoSimOuNao, keyChamado, dadosChamado, dataAbertura, premium, permissaoParaAvaliarChamado, keyAtendente, dadosAtendimento, licensawebouapp, viaChat,
  configWhatsapp, responderMensagem, idMensagemResponder, mensagemResposta, circleColorResponder, commentResponderType, atendenteResponder
) => {

  const comentario = comentario2.trim();
  return dispatch => {
    try {


      /* console.log(
        nomeArquivo, statusFechar, comentario2, resArquivo, comentarioPublicoSimOuNao, keyChamado, dadosChamado, dataAbertura, premium, permissaoParaAvaliarChamado, keyAtendente, dadosAtendimento, licensawebouapp
      ); */
      if (viaChat === true && resArquivo.length !== 0) {
        // toastr.warning('Enviando arquivo', 'Aguarde um momento...');
        //  modificaUpandoARquivoChat(dispatch, true);

      }
      let apiKey = '';
      let urlApi = '';
      let sessionclient = '';
      let statusConnect = '';
      let utilizaVersaoBETA = false;
      if (configWhatsapp !== undefined && configWhatsapp.length > 0) {

        apiKey = configWhatsapp[0].apiKey;
        urlApi = configWhatsapp[0].urlApi;
        sessionclient = configWhatsapp[0].sessionclient;
        statusConnect = configWhatsapp[0].statusConnect;
        utilizaVersaoBETA = configWhatsapp[0].utilizaVersaoBETA;
      }

      let atendenteAtendimento = keyAtendente.toString() == "" ? "Não informado" : keyAtendente.toString();
      const timeAtual = retornaHoraAtual();
      const dataHoraAtual = retornaDataHoraAtual();
      const dataAtualPostagem = retornaDataAtual();
      let AbertoVencimento = dadosAtendimento.dataVencimento.toString() == "Não informado" ? 0 : dadosAtendimento.dataVencimento;
      if (AbertoVencimento !== 0) {
        AbertoVencimento = moment(AbertoVencimento, 'DD/MM/YYYY').unix();
      } else {
        AbertoVencimento = false;
      }

      let AbertoVencimentoAtendente = {};
      let testeObjeto = {};
      let Aberto = 'Fechado';
      AbertoVencimentoAtendente[keyAtendente] = AbertoVencimento;
      testeObjeto[Aberto] = AbertoVencimentoAtendente;
      AbertoVencimento = { Fechado: AbertoVencimento }
      //
      AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
        const chaveUnica = chaveUnica2.replace(/"/g, "");
        /* firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}/`).update({
          lastmessageChatStatus: dadosAtendimento.chamadoAbertoPorWpp === true ? 'waiting' : "sent",
          lastmessageChatType: 'arquivo',
          lastMessageChatTimeStamp: firebase.database.ServerValue.TIMESTAMP,
          lastmessageChatCount: 0,
          lastMessageLineColor: '#00FF7F'
        }) */

        //
        firebase
          .database()
          .ref(`${chaveUnica}/usuarios/`)
          .orderByChild("uid")
          .equalTo(firebase.auth().currentUser.uid)
          .once("value")
          .then(async snapshot => {
            let arrayNomeQuemAbriuChamado = [];
            snapshot.forEach(childItem => {
              arrayNomeQuemAbriuChamado.push({
                nomeDeQuemAbriuChamado: childItem.val().nome
              });
            });
            let diferencaDeDias;
            if (statusFechar) {
              //calculo a quantidade de dias para resolver o chamado e populo no banco

              let total = calculaDias2(dataAbertura, dataAtualPostagem);
              switch (total) {
                case 0:
                  diferencaDeDias = "<1";
                  break;
                case 1:
                  diferencaDeDias = "1";
                  break;
                case 2:
                  diferencaDeDias = "2";
                  break;
                case 3:
                  diferencaDeDias = "3";
                  break;
                case 4:
                  diferencaDeDias = "4";
                  break;
                case 5:
                  diferencaDeDias = "5";
                  break;
                case 6:
                  diferencaDeDias = "6";
                  break;
                case 7:
                  diferencaDeDias = "7";
                  break;
                default:
                  diferencaDeDias = ">7";
                  break;
              }
            }
            let keyDeQuemAlterouChamado = Object.keys(snapshot.val()).toString();
            //
            //então tenho arquivo
            let aux = 0;
            let contadorArquivoText = 1;
            for (let i = 0; i < resArquivo.length; i++) {


              aux++;
              let unixdateatual = Date.now();
              let name = appendToFilename(resArquivo[i].name, unixdateatual);
              const uuid = generateUUIDv4();
              const mimetypeLocal = resArquivo[i].name.split('.').pop().toLowerCase();
              let mime = resArquivo[i].type;
              if (mime === "") {
                mime = getMimeType(mimetypeLocal);
              }
              const ref = firebase.storage().ref(`${chaveUnica}/${keyChamado}/${keyChamado}/${uuid}.${mimetypeLocal}`);
              modificaTextArquivoChat(dispatch, `Enviando arquivo %`);
              const downloadURL = await fileUpload(resArquivo[i], ref, uuid);

              contadorArquivoText++;
              const dadosAcoesChamado = await firebase.database().ref(`${chaveUnica}/acoesChamados/${keyChamado}/`).push({
                tipo: "link",
                titulo:
                  arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado,
                mensagem: name,
                urlDownload: downloadURL,
                tipoArquivo: resArquivo[i].type,
                dataHoraAtual: dataHoraAtual,
                dataAtualPostagem: dataAtualPostagem,
                time: timeAtual,
                lineColor: "#00FF7F",
                circleColor: "#00FF7F",
                visivelATodos: 1,
                keyDeQuemAlterouChamado,
                statusMessage: 'waiting',
                idMensagemResponder,
                possuiResposta: responderMensagem,
                mensagemResposta: mensagemResposta,
                circleColorResponder,
                commentResponderType,
                atendenteResponder
              })
              await firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}`).update({
                lastMessageChat: name,
                lastMessageChatTimeStamp: firebase.database.ServerValue.TIMESTAMP,
                lastmessageChatCount: 0,
                lastmessageChatType: resArquivo[i].type,
                lastMessageLineColor: '#00FF7F',
                lastmessageChatStatus: "waiting",

              });

              if (viaChat === true && dadosAtendimento.chamadoAbertoPorWpp === true) {


                let typeFile = 'file';
                if (resArquivo[i].type.includes('image')) {
                  typeFile = 'image';
                } else if (resArquivo[i].type.includes('audio')) {
                  typeFile = 'audio';
                } else if (resArquivo[i].type.includes('video')) {
                  typeFile = 'video';
                } else {
                  typeFile = 'file';
                }
                /* let nomeArquivo = encodeURI(resArquivo[i].name) */
                let data = {
                  numeroCliente: dadosAtendimento.telefoneCliente,
                  text: name,
                  apiKey: `${apiKey}`,
                  session: `${sessionclient}`,
                  urlApi: urlApi,
                  type: typeFile,
                  url: downloadURL,
                  keyAcoesChamado: dadosAcoesChamado.key,
                  keyChamado: keyChamado,
                  chaveUnica: chaveUnica,
                  file: `${chaveUnica}/${keyChamado}/${keyChamado}/${name}`,
                  mimeType: mime,
                  responder: responderMensagem,
                  idMensagemResponder: idMensagemResponder,
                  nomeAtendente: arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado,
                  utilizaVersaoBETA

                  // url: 'http://www.orimi.com/pdf-test.pdf',
                }
                /*
                 let nomeEncode = encodeURIComponent(data.text);
                  */

                const options = {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                    'Accept': 'application/json',
                  },

                  data: data,
                  url: 'enviaMensagemWhatsappSocket',
                };
                try {

                  await api(options);

                } catch (err) {
                  if (err.response) {
                    if (err.response.status === 400) {
                      toastr.error('Telefone Desconectado', 'Seu Telefone está sem Conexão com a Internet, verifique a conexão do seu Aparelho e tente novamente.')
                    } else {
                    }
                  } else {
                    toastr.error('Telefone Desconectado', 'Seu Telefone está sem Conexão com a Internet, verifique a conexão do seu Aparelho e tente novamente.')
                  }
                }

              }

              /* .then(() => {

                firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}/`).update({ lastmessageChatStatus: 'sent', lastMessageLineColor: '#00FF7F' })
                      }); */
              /*
                  */
              if (aux == resArquivo.length) {

                ativaDesativaLoadingTelaAcoesAtendimento(dispatch, false);
                zeraInformacoesEnvioArquivoChat(dispatch);
                if (viaChat === true && resArquivo.length !== 0) {
                  // toastr.success('Arquivo enviado com sucesso!');
                  modificaTextArquivoChat(dispatch, '')
                  //  modificaUpandoARquivoChat(dispatch, false);
                }


              }
              /*   });

              }) */




            }


          });
      });
    } catch (e) {
      toastr.error('Error ao enviar arquivo, tente novamente.');
    }
  };
};
export const navegaTelaALterarChamadoNotificacao = (keyChamado, numeroAtendimento2) => {
  return dispatch => {
    //
    //
    let numeroAtendimento = parseInt(numeroAtendimento2);
    dispatch({ type: ALTERANUMEROATENDIMENTO, payload: numeroAtendimento });
    dispatch({ type: ALTERAKEYCHAMADO, payload: keyChamado });
    //   dispatch(NavigationActions.navigate({ routeName: "AcoesAtendimento" }));
  };
};


export const excluiCliente = (keyCliente) => {
  return dispatch => {
    AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");
      ativaDesativaLoadingTelaConsultaCliente(dispatch, true);
      const cliente = firebase.database().ref(`${chaveUnica}/clientes/${keyCliente}`);
      cliente.once("value").then(snapshot => {
        const permissaoAppBanco = snapshot.val().permissaoAPP;
        const useruid = snapshot.val().useruid;
        const emailBanco = snapshot.val().email;
        const alterado = 1 + snapshot.val().alterado;
        const senhaBanco = snapshot.val().senha;

        if (permissaoAppBanco) {
          //;
          /*           secondaryApp.auth().signInWithEmailAndPassword(emailBanco, senhaBanco)
                      .then(userCredential => {
                        userCredential.delete().then(
                          () => { */
          const options = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json; charset=utf-8',
              'Accept': 'application/json',
            },

            data: { email: emailBanco, senha: senhaBanco, rota: 'excluir', uid: useruid },
            url: 'crudUserReactNative',
          };
          api(options).then(() => {
            //;
            cliente.update({
              excluido: 1,
              email: '',
              alterado,
              keyEmpresaVinculada: '',
              vinculadoEmpresa: false,
              fcmToken: '',
              fcmTokenWEB: '',
              telefoneCliente: '',
              statusAndamento: 3,
              isMyContact: false
            }).then(() => {
              firebase
                .database()
                .ref(`${chaveUnica}/chamados/`)
                .orderByChild(`cliente`)
                .equalTo(keyCliente)
                .limitToLast(1)
                .once("value").then((snapshot) => {

                  if (snapshot.val() == null) {
                    //
                    ativaDesativaLoadingTelaConsultaCliente(dispatch, false);
                    setTimeout(() => {
                      toastr.success('SUCESSO', 'Cliente EXCLUÍDO com SUCESSO!');
                      //
                    }, 100);
                  } else {
                    snapshot.forEach(childItem => {
                      firebase.database().ref(`${chaveUnica}/chamados/${childItem.key}`).update({ clienteVinculadoExcluido: 1 }).then(
                        () => {
                          ativaDesativaLoadingTelaConsultaCliente(dispatch, false);
                          setTimeout(() => {
                            toastr.success('SUCESSO', 'Cliente EXCLUÍDO com SUCESSO!');
                            //
                          }, 100);
                        }
                      )
                    })
                  }
                })
            });
          }
          ).catch((error) => {

            ativaDesativaLoadingTelaConsultaCliente(dispatch, false);
            if (error.response) {


              switch (error.response.data.code) {
                case 'auth/email-already-exists':
                  toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                  break;
                case 'auth/invalid-email':
                  toastr.error('Error', 'Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br');
                  break;
                case 'auth/email-already-in-use':
                  toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                  break;
                case 'auth/network-request-failed':
                  toastr.error('Error', 'Sem conexão com a internet');
                  break;
                default:
                  toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                  break;
              }
            } else {
              toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
            }
          })

        } else {
          //
          cliente.update({
            excluido: 1,
            alterado,
            email: '',
            keyEmpresaVinculada: '',
            vinculadoEmpresa: false,
            telefoneCliente: '',
            fcmToken: '',
            fcmTokenWEB: '',
            statusAndamento: 3,
            isMyContact: false
          }).then(() => {

            firebase
              .database()
              .ref(`${chaveUnica}/chamados/`)
              .orderByChild(`cliente`)
              .equalTo(keyCliente)
              .limitToLast(1)
              .once("value").then((snapshot) => {

                if (snapshot.val() == null) {
                  //
                  ativaDesativaLoadingTelaConsultaCliente(dispatch, false);
                  setTimeout(() => {
                    toastr.success('SUCESSO', 'Cliente EXCLUÍDO com SUCESSO!');
                    //
                  }, 100);
                } else {
                  snapshot.forEach(childItem => {
                    firebase.database().ref(`${chaveUnica}/chamados/${childItem.key}`).update({ clienteVinculadoExcluido: 1 }).then(
                      () => {
                        ativaDesativaLoadingTelaConsultaCliente(dispatch, false);
                        setTimeout(() => {
                          toastr.success('SUCESSO', 'Cliente EXCLUÍDO com SUCESSO!');
                          //
                        }, 100);
                      }
                    )
                  })
                }
              })
            /*
            ativaDesativaLoadingTelaConsultaCliente(dispatch, false);

            setTimeout(() => {

              toastr.success('SUCESSO', 'Cliente EXCLUÍDO com SUCESSO!');
              //
            }, 100); */
          });

        }
      })
    })
  }
}


export const cadastraContato = (nomeEmpresa2, nomeCliente2, telefoneCliente2, uriImagem2, arquivoCompletoParaUpar, configWhatsapp) => {

  let apiKey = '';
  let urlApi = '';
  let sessionclient = '';
  let statusConnect = '';
  let utilizaVersaoBETA = false;
  if (configWhatsapp !== undefined && configWhatsapp.length > 0) {

    apiKey = configWhatsapp[0].apiKey;
    urlApi = configWhatsapp[0].urlApi;
    sessionclient = configWhatsapp[0].sessionclient;
    statusConnect = configWhatsapp[0].statusConnect;
    utilizaVersaoBETA = configWhatsapp[0].utilizaVersaoBETA;
  }



  let uriHTTP = 0;
  let tipoUri = isNumber(uriImagem2);
  if (uriImagem2 === null || uriImagem2 === undefined || uriImagem2.includes('/static/media/userPadrao')) {
    /*   */
    //é uma imagem local nao precisa upar
    uriHTTP = 1;
  } else if (arquivoCompletoParaUpar === '') {
    /*    */
    uriHTTP = 1;
  } else {
    /*    */
    //é uma imagem que precisa upar
    //  uriImagem = uriImagem2.uri;
  }
  //
  let uriImagem = tipoUri == true ? "https://firebasestorage.googleapis.com/v0/b/dark-4b307.appspot.com/o/imagemPadraoDark%2Fuser.png?alt=media&token=5494861d-8431-480d-ac62-442c8b2c39af" : uriImagem2.uri;
  //
  let telefoneCliente = telefoneCliente2.trim();
  const nomeEmpresa = nomeEmpresa2.trim();
  const nomeCliente = nomeCliente2.trim();
  const empresaPesquisa = nomeEmpresa.toLowerCase();

  return dispatch => {
    if (nomeCliente.trim() === "") {
      toastr.error('Error', "Digite o nome do Contato");
    } else if (nomeCliente.trim().length < 2) {
      toastr.error('Error',
        "O nome do Contato deve possuir mais de 1 letra."
      );
    } else if (telefoneCliente.trim().length < 2) {
      toastr.error('Error',
        "Informe o Telefone do Contato no Formato de Exemplo."
      );
    } else {

      modificaLoadingCadastraNovoContato(dispatch, true);
      AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
        const chaveUnica = chaveUnica2.replace(/"/g, "");
        // console.log(chaveUnica);
        // console.log(telefoneCliente);

        let data = {
          session: sessionclient,
          chaveUnica: chaveUnica,
          urlApi,
          apiKey,
          numeroCliente: telefoneCliente
        }
        const options = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept': 'application/json',
          },

          data: data,
          url: 'verificaSeNumeroEhValidoWppSocket',
        };
        const optionsGetName = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept': 'application/json',
          },

          data: data,
          url: 'verificaSeNumeroEhContatoAdicionadoBuscaNome',
        };
        api(options).then((response) => {
          api(optionsGetName).then((responseGetName2) => {
            let responseGetName = responseGetName2.data
            responseGetName.myContact = false; //retirar ao voltar novamente para o bloqueio de numero cadastrado na agenda
            if (responseGetName.myContact && responseGetName.myContact === true) {
              let nomeDoContatoNaAgenda = '';
              if (responseGetName.pushName && responseGetName.pushName !== null && responseGetName.pushName !== undefined && responseGetName.pushName !== '') {
                nomeDoContatoNaAgenda = responseGetName.pushName;
              }
              if (nomeDoContatoNaAgenda !== '') {
                modificaLoadingCadastraNovoContato(dispatch, false);
                toastr.error('Error', `Esse contato já está adicionado na sua agenda telefonica com o nome de ${nomeDoContatoNaAgenda}`)
              } else {
                modificaLoadingCadastraNovoContato(dispatch, false);
                toastr.error('Error', 'Esse contato já está adicionado na sua agenda telefonica')
              }

            } else {
              let retornoNumero = response.data;

              if (retornoNumero.profile.numberExists && retornoNumero.profile.numberExists === true) {

                firebase.database().ref(`${chaveUnica}/clientes/`).orderByChild("telefoneCliente").equalTo(telefoneCliente).limitToLast(1).once("value").then(snapshot => {
                  let isMyContact = false;
                  let nomeClienteBD = '';
                  /* console.log(snapshot.val());
                   */

                  if (snapshot.val()) {
                    snapshot.forEach(childItem => {
                      isMyContact = childItem.val().isMyContact === undefined ? false : childItem.val().isMyContact;
                      nomeClienteBD = childItem.val().nome;
                    });
                  } else {
                    isMyContact = false;
                  }
                  if (isMyContact === true) {
                    toastr.error('Error', `Este Número já se encontra cadastrado, com o cliente ${nomeClienteBD}.`)
                    modificaLoadingCadastraNovoContato(dispatch, false);
                  } else {

                    //cadastrando o cliente na tabela principal
                    firebase
                      .database()
                      .ref(`${chaveUnica}/clientes`)
                      .push({
                        nome: nomeCliente,
                        empresaPesquisa: empresaPesquisa,
                        email: "",
                        empresa: telefoneCliente,
                        senha: "",
                        permissaoAPP: false,
                        alterado: 0,
                        notifNovoAtendimento: 1,
                        notifRespNosAtendimentos: 1,
                        vinculadoEmpresa: false,
                        keyEmpresaVinculada: "Não Vinculado",
                        enderecoCliente: '',
                        telefoneCliente: telefoneCliente,
                        urlImagemNuvem: "",
                        isMyContact: true
                      })
                      .then(dadosCliente => {
                        // let uri = uriImagem.replace("file://", ""); //remove o file:// que tem no ios
                        if (uriHTTP == 0) {
                          let caminhoPadraoImagem = firebase.storage().ref(`${chaveUnica}/${dadosCliente.key}/imagem.jpg`);
                          let mime = "image/jpeg";

                          caminhoPadraoImagem
                            .put(arquivoCompletoParaUpar)
                            .then((url) => {
                              url.ref.getDownloadURL().then(function (downloadURL) {
                                //alterado o alterado para 1 pra poder atualizar o flatlist e carregar a foto
                                firebase.database().ref(`${chaveUnica}/clientes/${dadosCliente.key}`).update({ alterado: 1, urlImagemNuvem: downloadURL });
                                toastr.success('Sucesso', 'Contato adicionado com Sucesso!');
                                modificaLoadingCadastraNovoContato(dispatch, false);
                                zeraInformacoesCadastroContato(dispatch, true);
                              })
                            })
                            .catch(error => {
                              modificaLoadingCadastraNovoContato(dispatch, false);
                              //error.code
                              //error.message
                              switch (error.code) {
                                case "auth/invalid-email":
                                  toastr.error('Error',
                                    "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                                  );
                                  break;
                                case "auth/email-already-in-use":
                                  toastr.error('Error',
                                    "Este email já está sendo utilizado, informe outro email."
                                  );
                                  break;
                                case "auth/network-request-failed":
                                  toastr.error('Error',
                                    "Sem conexão com a internet"
                                  );
                                  break;
                                default:
                                  toastr.error('Error',
                                    "Erro ao cadastrar, tente novamente mais tarde"
                                  );
                                  break;
                              }
                            });
                          /* }); */
                        } else {
                          toastr.success('Sucesso', 'Contato adicionado com Sucesso!');
                          modificaLoadingCadastraNovoContato(dispatch, false);
                          zeraInformacoesCadastroContato(dispatch, true);

                        }
                      })
                      .catch(error => {
                        modificaLoadingCadastraNovoContato(dispatch, false);
                        //error.code

                        switch (error.code) {
                          case "auth/invalid-email":
                            toastr.error('Error',
                              "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                            );
                            break;
                          case "auth/email-already-in-use":
                            toastr.error('Error',
                              "Este email já está sendo utilizado, informe outro email."
                            );
                            break;
                          case "auth/network-request-failed":
                            toastr.error('Error',
                              "Sem conexão com a internet"
                            );
                            break;
                          default:
                            toastr.error('Error',
                              "Erro ao cadastrar, tente novamente mais tarde"
                            );

                            break;
                        }
                      });
                  }
                })
              } else {
                toastr.error('Telefone Inválido', 'Verifique o Número do Contato, informe conforme o Exemplo.')
                modificaLoadingCadastraNovoContato(dispatch, false);
              }
            }
          }).catch((err) => {

            //  console.log(err);
            modificaLoadingCadastraNovoContato(dispatch, false);
            toastr.error('Telefone Desconectado', 'Seu Telefone está sem Conexão com a Internet, verifique a conexão do seu Aparelho e tente novamente.')
          })
        }).catch((err) => {
          // console.log(err);
          modificaLoadingCadastraNovoContato(dispatch, false);
          toastr.error('Telefone Desconectado', 'Seu Telefone está sem Conexão com a Internet, verifique a conexão do seu Aparelho e tente novamente.')
        })
      })
    }
  }

}

export const cadastraCliente = (
  nomeEmpresa2,
  nomeCliente2,
  emailCliente2,
  permissaoParaAcessarApp,
  senha2,
  confirmasenha2,
  uriImagem2,
  premium,
  vinculadoEmpresa,
  keyEmpresaVinculada2,
  nomeEmpresaSelecionadoConsultaClienteTelaCAdastraCliente,
  enderecoCliente2,
  telefoneCliente2,
  arquivoCompletoParaUpar,
  configWhatsapp
) => {

  configWhatsapp = configWhatsapp === undefined ? [] : configWhatsapp
  /*  console.log(
     nomeEmpresa2,
     nomeCliente2,
     emailCliente2,
     permissaoParaAcessarApp,
     senha2,
     confirmasenha2,
     uriImagem2,
     premium,
     vinculadoEmpresa,
     keyEmpresaVinculada2,
     nomeEmpresaSelecionadoConsultaClienteTelaCAdastraCliente,
     enderecoCliente2,
     telefoneCliente2,
     arquivoCompletoParaUpar
   ); */
  let apiKey = '';
  let urlApi = '';
  let sessionclient = '';
  let statusConnect = '';
  let utilizaVersaoBETA = false;
  if (configWhatsapp !== undefined && configWhatsapp.length > 0) {

    apiKey = configWhatsapp[0].apiKey;
    urlApi = configWhatsapp[0].urlApi;
    sessionclient = configWhatsapp[0].sessionclient;
    statusConnect = configWhatsapp[0].statusConnect;
    utilizaVersaoBETA = configWhatsapp[0].utilizaVersaoBETA;
  }



  let uriHTTP = 0;
  let tipoUri = isNumber(uriImagem2);
  if (uriImagem2 === null || uriImagem2 === undefined || uriImagem2.includes('/static/media/userPadrao')) {
    /*   */
    //é uma imagem local nao precisa upar
    uriHTTP = 1;
  } else if (arquivoCompletoParaUpar === '') {
    /*    */
    uriHTTP = 1;
  } else {
    /*    */
    //é uma imagem que precisa upar
    //  uriImagem = uriImagem2.uri;
  }
  //
  let uriImagem = tipoUri == true ? "https://firebasestorage.googleapis.com/v0/b/dark-4b307.appspot.com/o/imagemPadraoDark%2Fuser.png?alt=media&token=5494861d-8431-480d-ac62-442c8b2c39af" : uriImagem2.uri;
  //
  const enderecoCliente = enderecoCliente2.trim();
  const telefoneCliente = telefoneCliente2.trim();
  const nomeEmpresa = nomeEmpresa2.trim();
  const nomeCliente = nomeCliente2.trim();
  let isTelAndWpp = false;

  const empresaPesquisa =
    vinculadoEmpresa == true
      ? nomeEmpresaSelecionadoConsultaClienteTelaCAdastraCliente.toLowerCase()
      : nomeEmpresa.toLowerCase();
  const emailCliente = emailCliente2.trim().toLowerCase();
  let keyEmpresaVinculada = keyEmpresaVinculada2;
  const senha = senha2.trim();
  const confirmarSenha = confirmasenha2.trim();
  return dispatch => {

    //

    if (nomeEmpresa.trim() === "" && vinculadoEmpresa == false) {
      toastr.error('Error',
        "Digite a Empresa do cliente."
      );
    } else if (
      keyEmpresaVinculada2.length == 0 &&
      vinculadoEmpresa == true
    ) {
      toastr.error('Error',
        "Selecione a Empresa que Deseja vincular este Cliente."
      );
    } else if (nomeEmpresa.trim().length < 2 && vinculadoEmpresa == false) {
      toastr.error('Error',
        "A empresa do cliente deve possuir mais de 1 letra."
      );
    } else if (nomeCliente.trim() === "") {
      toastr.error('Error', "Digite o nome do cliente");
    } else if (nomeCliente.trim().length < 2) {
      toastr.error('Error',
        "O nome do cliente deve possuir mais de 1 letra."
      );
    } else {

      AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
        const chaveUnica = chaveUnica2.replace(/"/g, "");
        ativaLoadingBotaoCadastrarCliente(dispatch);
        if (telefoneCliente !== "" && configWhatsapp.length > 0) {
          isTelAndWpp = true
        }
        verificaSeNumeroEhValidoWhatsApp(isTelAndWpp, telefoneCliente, sessionclient, chaveUnica, urlApi, apiKey, "", "").then(
          (response) => {
            if (response.includes("TelefoneJaCadastradoBancoDados")) {
              let nomeCliente = response.replace("TelefoneJaCadastradoBancoDados", "")
              toastr.error('Error', `Este Número já se encontra cadastrado no cliente ${nomeCliente}.`)
              desativaLoadingBotaoCadastrarCliente(dispatch);
            } /* else if (response === "TelefoneInvalido") {
              toastr.error('Telefone Inválido', 'Verifique o Número do Contato, informe conforme o Exemplo.')
              desativaLoadingBotaoCadastrarCliente(dispatch);
            } */ else if (response === "TelefoneDesconectado") {
              toastr.error('Telefone Desconectado', 'Seu Telefone está sem Conexão com a Internet, verifique a conexão do seu Aparelho e tente novamente.')
              desativaLoadingBotaoCadastrarCliente(dispatch);
            } else if (response === "TelefoneJaCadastradoNaAgenda") {
              toastr.error('Error', 'Este Número já se encontra cadastrado na sua agenda telefônica.')
              desativaLoadingBotaoCadastrarCliente(dispatch);
            }
            else {
              if (response === "TelefoneInvalido") {
                //toastr.error('Telefone Inválido', 'Verifique o Número do Contato, informe conforme o Exemplo.')
                isTelAndWpp = false;
              }
              //Tem permissao para acessar app
              if (permissaoParaAcessarApp) {
                //
                if (emailCliente.trim() === "") {
                  toastr.error('Error',
                    "Digite um email."
                  );
                  desativaLoadingBotaoCadastrarCliente(dispatch);
                } else if (senha.trim() === "") {
                  toastr.error('Error',
                    "Digite a senha de acesso."
                  );
                  desativaLoadingBotaoCadastrarCliente(dispatch);
                } else if (senha.trim().length < 6) {
                  toastr.error('Error',
                    "A senha de acesso deve conter pelo menos 6 digitos."
                  );
                  desativaLoadingBotaoCadastrarCliente(dispatch);
                } else if (confirmarSenha.trim() === "") {
                  toastr.error('Error',
                    "Confirme a senha de acesso."
                  );
                  desativaLoadingBotaoCadastrarCliente(dispatch);
                } else if (senha !== confirmarSenha) {
                  toastr.error('Error',
                    "Senhas diferentes, por favor digite novamente."
                  );
                  desativaLoadingBotaoCadastrarCliente(dispatch);
                } else {
                  //pegando email mestre para inserir dados
                  AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
                    const chaveUnica = chaveUnica2.replace(/"/g, "");
                    /*   secondaryApp
                        .auth()
                        .createUserWithEmailAndPassword(emailCliente, senha)
                        .then(user => {
                          const useruid = user.uid; */
                    const options = {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                        'Accept': 'application/json',
                      },

                      data: { email: emailCliente, senha: senha, rota: 'create', chaveUnica },
                      url: 'crudUserReactNative',
                    };
                    api(options).then((response) => {


                      let useruid = response.data.uid;
                      //cadastrando o cliente na tabela principal
                      firebase
                        .database()
                        .ref(`${chaveUnica}/clientes`)
                        .push({
                          nome: nomeCliente,
                          empresaPesquisa: empresaPesquisa,
                          email: emailCliente,
                          empresa: vinculadoEmpresa == true ? nomeEmpresaSelecionadoConsultaClienteTelaCAdastraCliente : nomeEmpresa,
                          senha: senha,
                          permissaoAPP: true,
                          useruid: useruid,
                          alterado: 0,
                          notifNovoAtendimento: 1,
                          notifRespNosAtendimentos: 1,
                          vinculadoEmpresa,
                          keyEmpresaVinculada: keyEmpresaVinculada == undefined ? "Não Vinculado" : keyEmpresaVinculada,
                          enderecoCliente,
                          telefoneCliente,
                          urlImagemNuvem: "",
                          isMyContact: isTelAndWpp
                        })
                        .then(dadosCliente =>
                          //cadastrando o nome do usuario na tabela principal (email base 64)
                          firebase
                            .database()
                            .ref(`usuariosALL/${useruid}`)
                            .push({ chaveUnica: chaveUnica, admin: 0 })
                            .then(() => {
                              //
                              // let uri = uriImagem.replace("file://", ""); //remove o file:// que tem no ios
                              if (uriHTTP == 0) {
                                let caminhoPadraoImagem = firebase
                                  .storage()
                                  .ref(
                                    `${chaveUnica}/${dadosCliente.key}/imagem.jpg`
                                  );
                                let mime = "image/jpeg";
                                /*  RNFetchBlob.fs
                                   .readFile(uri, "base64")
                                   .then(data => {
                                     return RNFetchBlob.polyfill.Blob.build(
                                       data,
                                       {
                                         type: mime + ";BASE64"
                                       }
                                     );
                                   })
                                   .then(blob => { */
                                caminhoPadraoImagem.put(arquivoCompletoParaUpar)
                                  .then((url) => {
                                    url.ref.getDownloadURL().then(function (downloadURL) {
                                      /*

                                         */
                                      // blob.close();
                                      //alterado o alterado para 1 pra poder atualizar o flatlist e carregar a foto
                                      firebase
                                        .database()
                                        .ref(`${chaveUnica}/clientes/${dadosCliente.key}`)
                                        .update({ alterado: 1, urlImagemNuvem: downloadURL });
                                      desativaLoadingBotaoCadastrarCliente(dispatch);
                                      toastr.success('Sucesso', 'Cliente cadastrado com Sucesso!')
                                      zeraInformacoesCadastroCliente(
                                        dispatch
                                      );
                                      if (vinculadoEmpresa) {
                                        firebase
                                          .database()
                                          .ref(
                                            `${chaveUnica}/clientes/${keyEmpresaVinculada}`
                                          )
                                          .update({
                                            empresaPrincipal: true,
                                            keyEmpresaVinculada
                                          });
                                      }
                                    })
                                  })
                                  .catch(error => {
                                    desativaLoadingBotaoCadastrarCliente(dispatch);
                                    //error.code
                                    //error.message
                                    switch (error.code) {
                                      case "auth/invalid-email":
                                        toastr.error('Error',
                                          "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                                        );
                                        break;
                                      case "auth/email-already-in-use":
                                        toastr.error('Error',
                                          "Este email já está sendo utilizado, informe outro email."
                                        );
                                        break;
                                      case "auth/network-request-failed":
                                        toastr.error('Error',
                                          "Sem conexão com a internet"
                                        );
                                        break;
                                      default:
                                        toastr.error('Error',
                                          "Erro ao cadastrar, tente novamente mais tarde"
                                        );
                                        break;
                                    }
                                  });
                                /*  }); */
                              } else {
                                /*  firebase
                                   .database()
                                   .ref(`${chaveUnica}/clientes/${dadosCliente.key}`)
                                   .update({ urlImagemNuvem: "" }); */
                                /*   */
                                desativaLoadingBotaoCadastrarCliente(dispatch);
                                toastr.success('Sucesso', 'Cliente cadastrado com Sucesso!')
                                zeraInformacoesCadastroCliente(dispatch);
                                if (vinculadoEmpresa) {
                                  firebase
                                    .database()
                                    .ref(
                                      `${chaveUnica}/clientes/${keyEmpresaVinculada}`
                                    )
                                    .update({ empresaPrincipal: true, keyEmpresaVinculada });
                                }
                              }
                            })
                            .catch(error => {
                              desativaLoadingBotaoCadastrarCliente(dispatch);
                              //error.code
                              //error.message
                              switch (error.code) {
                                case "auth/invalid-email":
                                  toastr.error('Error',
                                    "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                                  );
                                  break;
                                case "auth/email-already-in-use":
                                  toastr.error('Error',
                                    "Este email já está sendo utilizado, informe outro email."
                                  );
                                  break;
                                case "auth/network-request-failed":
                                  toastr.error('Error',
                                    "Sem conexão com a internet"
                                  );
                                  break;
                                default:
                                  toastr.error('Error',
                                    "Erro ao cadastrar, tente novamente mais tarde"
                                  );
                                  break;
                              }
                            })
                        )
                        .catch(error => {
                          desativaLoadingBotaoCadastrarCliente(dispatch);
                          //error.code
                          //error.message
                          switch (error.code) {
                            case "auth/invalid-email":
                              toastr.error('Error',
                                "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                              );
                              break;
                            case "auth/email-already-in-use":
                              toastr.error('Error',
                                "Este email já está sendo utilizado, informe outro email."
                              );
                              break;
                            case "auth/network-request-failed":
                              toastr.error('Error',
                                "Sem conexão com a internet"
                              );
                              break;
                            default:
                              toastr.error('Error',
                                "Erro ao cadastrar, tente novamente mais tarde"
                              );
                              break;
                          }
                        });
                    }).catch(error => {
                      desativaLoadingBotaoCadastrarCliente(dispatch);
                      //error.code
                      //error.message
                      if (error.response) {


                        switch (error.response.data.code) {
                          case 'auth/email-already-exists':
                            toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                            break;
                          case 'auth/invalid-email':
                            toastr.error('Error', 'Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br');
                            break;
                          case 'auth/email-already-in-use':
                            toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                            break;
                          case 'auth/network-request-failed':
                            toastr.error('Error', 'Sem conexão com a internet');
                            break;
                          default:
                            toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                            break;
                        }
                      } else {
                        toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                      }
                    });
                  });
                }
                //'não tem permissao mas tem email'
              } else if (emailCliente !== "" && permissaoParaAcessarApp == false) {
                //pegando email mestre para inserir dados
                AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
                  const chaveUnica = chaveUnica2.replace(/"/g, "");
                  const senhaPadrao = "exemplo@exemplo2659";
                  /*   secondaryApp
                      .auth()
                      .createUserWithEmailAndPassword(
                        emailCliente,
                        senhaPadrao
                      )
                      .then(user => {
                        const useruid = user.uid; */
                  const options = {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json; charset=utf-8',
                      'Accept': 'application/json',
                    },

                    data: { email: emailCliente, senha: senhaPadrao, rota: 'create', chaveUnica },
                    url: 'crudUserReactNative',
                  };
                  api(options).then((response) => {


                    //codigo useruid para virar minha chave unica principal
                    let useruid = response.data.uid;
                    //cadastrando o cliente na tabela principal
                    firebase
                      .database()
                      .ref(`${chaveUnica}/clientes/`)
                      .push({
                        nome: nomeCliente,
                        empresaPesquisa: empresaPesquisa,
                        email: emailCliente,
                        empresa: vinculadoEmpresa == true ? nomeEmpresaSelecionadoConsultaClienteTelaCAdastraCliente : nomeEmpresa,
                        senha: "",
                        permissaoAPP: false,
                        useruid: useruid,
                        alterado: 0,
                        notifNovoAtendimento: 1,
                        notifRespNosAtendimentos: 1,
                        vinculadoEmpresa,
                        keyEmpresaVinculada: keyEmpresaVinculada == undefined ? "Não Vinculado" : keyEmpresaVinculada,
                        enderecoCliente,
                        telefoneCliente,
                        urlImagemNuvem: "",
                        isMyContact: isTelAndWpp
                      })
                      .then(dadosCliente => {
                        // let uri = uriImagem.replace("file://", ""); //remove o file:// que tem no ios
                        if (uriHTTP == 0) {
                          let caminhoPadraoImagem = firebase
                            .storage()
                            .ref(
                              `${chaveUnica}/${dadosCliente.key}/imagem.jpg`
                            );
                          let mime = "image/jpeg";
                          /*  RNFetchBlob.fs
                             .readFile(uri, "base64")
                             .then(data => {
                               return RNFetchBlob.polyfill.Blob.build(
                                 data,
                                 {
                                   type: mime + ";BASE64"
                                 }
                               );
                             })
                             .then(blob => { */
                          caminhoPadraoImagem
                            .put(arquivoCompletoParaUpar)
                            .then((url) => {
                              url.ref.getDownloadURL().then(function (downloadURL) {
                                /*

                                   */
                                // blob.close();
                                //alterado o alterado para 1 pra poder atualizar o flatlist e carregar a foto
                                firebase
                                  .database()
                                  .ref(
                                    `${chaveUnica}/clientes/${dadosCliente.key}`
                                  )
                                  .update({ alterado: 1, urlImagemNuvem: downloadURL });
                                toastr.success('Sucesso', 'Cliente cadastrado com Sucesso!');
                                desativaLoadingBotaoCadastrarCliente(dispatch);
                                zeraInformacoesCadastroCliente(
                                  dispatch
                                );
                                if (vinculadoEmpresa) {
                                  firebase
                                    .database()
                                    .ref(
                                      `${chaveUnica}/clientes/${keyEmpresaVinculada}`
                                    )
                                    .update({ empresaPrincipal: true, keyEmpresaVinculada });
                                }
                              })
                            })
                            .catch(error => {
                              desativaLoadingBotaoCadastrarCliente(dispatch);
                              //error.code
                              //error.message
                              switch (error.code) {
                                case "auth/invalid-email":
                                  toastr.error('Error',
                                    "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                                  );
                                  break;
                                case "auth/email-already-in-use":
                                  toastr.error('Error',
                                    "Este email já está sendo utilizado, informe outro email."
                                  );
                                  break;
                                case "auth/network-request-failed":
                                  toastr.error('Error',
                                    "Sem conexão com a internet"
                                  );
                                  break;
                                default:
                                  toastr.error('Error',
                                    "Erro ao cadastrar, tente novamente mais tarde"
                                  );
                                  break;
                              }
                            });
                          /*  }); */
                        } else {
                          /*  firebase
                             .database()
                             .ref(`${chaveUnica}/clientes/${dadosCliente.key}`)
                             .update({ urlImagemNuvem: "" }); */
                          //
                          toastr.success('Sucesso', 'Cliente cadastrado com Sucesso!');
                          desativaLoadingBotaoCadastrarCliente(dispatch);
                          zeraInformacoesCadastroCliente(dispatch);
                          if (vinculadoEmpresa) {
                            firebase
                              .database()
                              .ref(
                                `${chaveUnica}/clientes/${keyEmpresaVinculada}`
                              )
                              .update({ empresaPrincipal: true, keyEmpresaVinculada });
                          }
                        }
                      })
                      .catch(error => {
                        desativaLoadingBotaoCadastrarCliente(dispatch);
                        //error.code
                        //error.message
                        switch (error.code) {
                          case "auth/invalid-email":
                            toastr.error('Error',
                              "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                            );
                            break;
                          case "auth/email-already-in-use":
                            toastr.error('Error',
                              "Este email já está sendo utilizado, informe outro email."
                            );
                            break;
                          case "auth/network-request-failed":
                            toastr.error('Error',
                              "Sem conexão com a internet"
                            );
                            break;
                          default:
                            toastr.error('Error',
                              "Erro ao cadastrar, tente novamente mais tarde"
                            );
                            break;
                        }
                      });
                  })
                    .catch(error => {
                      desativaLoadingBotaoCadastrarCliente(dispatch);
                      //error.code
                      //error.message
                      if (error.response) {


                        switch (error.response.data.code) {
                          case 'auth/email-already-exists':
                            toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                            break;
                          case 'auth/invalid-email':
                            toastr.error('Error', 'Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br');
                            break;
                          case 'auth/email-already-in-use':
                            toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                            break;
                          case 'auth/network-request-failed':
                            toastr.error('Error', 'Sem conexão com a internet');
                            break;
                          default:
                            toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                            break;
                        }
                      } else {
                        toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                      }
                    });
                });
              }
              //'não tem permissao e não tem email'
              else {
                //pegando email mestre para inserir dados
                AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
                  const chaveUnica = chaveUnica2.replace(/"/g, "");
                  //cadastrando o cliente na tabela principal
                  firebase
                    .database()
                    .ref(`${chaveUnica}/clientes`)
                    .push({
                      nome: nomeCliente,
                      empresaPesquisa: empresaPesquisa,
                      email: "",
                      empresa:
                        vinculadoEmpresa == true
                          ? nomeEmpresaSelecionadoConsultaClienteTelaCAdastraCliente
                          : nomeEmpresa,
                      senha: "",
                      permissaoAPP: false,
                      alterado: 0,
                      notifNovoAtendimento: 1,
                      notifRespNosAtendimentos: 1,
                      vinculadoEmpresa,
                      keyEmpresaVinculada: keyEmpresaVinculada == undefined ? "Não Vinculado" : keyEmpresaVinculada,
                      enderecoCliente,
                      telefoneCliente,
                      urlImagemNuvem: "",
                      isMyContact: isTelAndWpp
                    })
                    .then(dadosCliente => {
                      // let uri = uriImagem.replace("file://", ""); //remove o file:// que tem no ios
                      if (uriHTTP == 0) {
                        let caminhoPadraoImagem = firebase
                          .storage()
                          .ref(
                            `${chaveUnica}/${dadosCliente.key}/imagem.jpg`
                          );
                        let mime = "image/jpeg";
                        /*    RNFetchBlob.fs
                             .readFile(uri, "base64")
                             .then(data => {
                               return RNFetchBlob.polyfill.Blob.build(data, {
                                 type: mime + ";BASE64"
                               });
                             })
                             .then(blob => { */
                        caminhoPadraoImagem
                          .put(arquivoCompletoParaUpar)
                          .then((url) => {
                            url.ref.getDownloadURL().then(function (downloadURL) {
                              /*

                                 */
                              // blob.close();
                              //alterado o alterado para 1 pra poder atualizar o flatlist e carregar a foto
                              firebase
                                .database()
                                .ref(
                                  `${chaveUnica}/clientes/${dadosCliente.key}`
                                )
                                .update({ alterado: 1, urlImagemNuvem: downloadURL });

                              toastr.success('Sucesso', 'Cliente cadastrado com Sucesso!');
                              desativaLoadingBotaoCadastrarCliente(dispatch);
                              zeraInformacoesCadastroCliente(dispatch);
                              if (vinculadoEmpresa) {
                                firebase
                                  .database()
                                  .ref(
                                    `${chaveUnica}/clientes/${keyEmpresaVinculada}`
                                  )
                                  .update({ empresaPrincipal: true, keyEmpresaVinculada });
                              }
                            })
                          })
                          .catch(error => {
                            desativaLoadingBotaoCadastrarCliente(dispatch);
                            //error.code
                            //error.message
                            switch (error.code) {
                              case "auth/invalid-email":
                                toastr.error('Error',
                                  "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                                );
                                break;
                              case "auth/email-already-in-use":
                                toastr.error('Error',
                                  "Este email já está sendo utilizado, informe outro email."
                                );
                                break;
                              case "auth/network-request-failed":
                                toastr.error('Error',
                                  "Sem conexão com a internet"
                                );
                                break;
                              default:
                                toastr.error('Error',
                                  "Erro ao cadastrar, tente novamente mais tarde"
                                );
                                break;
                            }
                          });
                        /* }); */
                      } else {
                        /*     */
                        /*  firebase
                           .database()
                           .ref(`${chaveUnica}/clientes/${dadosCliente.key}`)
                           .update({ urlImagemNuvem: "" }); */
                        toastr.success('Sucesso', 'Cliente cadastrado com Sucesso!');
                        desativaLoadingBotaoCadastrarCliente(dispatch);
                        zeraInformacoesCadastroCliente(dispatch);
                        if (vinculadoEmpresa) {
                          firebase
                            .database()
                            .ref(
                              `${chaveUnica}/clientes/${keyEmpresaVinculada}`
                            )
                            .update({ empresaPrincipal: true, keyEmpresaVinculada });
                        }
                      }
                    })
                    .catch(error => {
                      desativaLoadingBotaoCadastrarCliente(dispatch);
                      //error.code

                      switch (error.code) {
                        case "auth/invalid-email":
                          toastr.error('Error',
                            "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                          );
                          break;
                        case "auth/email-already-in-use":
                          toastr.error('Error',
                            "Este email já está sendo utilizado, informe outro email."
                          );
                          break;
                        case "auth/network-request-failed":
                          toastr.error('Error',
                            "Sem conexão com a internet"
                          );
                          break;
                        default:
                          toastr.error('Error',
                            "Erro ao cadastrar, tente novamente mais tarde"
                          );

                          break;
                      }
                    });
                });
              }
            }
          })
      });
    }

  };
};


export const alteraCliente = (
  empresaCliente,
  nomeCliente2,
  email,
  permissaoApp,
  senha2,
  confirmaSenha2,
  uriImagem2,
  key,
  vinculadoEmpresa,
  keyEmpresaVinculada2,
  nomeEmpresaSelecionadoConsultaClienteTelaCAdastraCliente,
  clienteEmpresaPrincipal,
  telefoneCliente2,
  enderecoCliente2,
  arquivoCompletoParaUpar,
  configWhatsapp
) => {
  configWhatsapp = configWhatsapp === undefined ? [] : configWhatsapp
  const enderecoCliente = enderecoCliente2.trim();
  const telefoneCliente = telefoneCliente2.trim();

  let apiKey = '';
  let urlApi = '';
  let sessionclient = '';
  let statusConnect = '';
  let utilizaVersaoBETA = false;
  if (configWhatsapp !== undefined && configWhatsapp.length > 0) {

    apiKey = configWhatsapp[0].apiKey;
    urlApi = configWhatsapp[0].urlApi;
    sessionclient = configWhatsapp[0].sessionclient;
    statusConnect = configWhatsapp[0].statusConnect;
    utilizaVersaoBETA = configWhatsapp[0].utilizaVersaoBETA;
  }
  let isTelAndWpp = false;
  const nomeEmpresa = empresaCliente.trim();
  const nomeCliente = nomeCliente2.trim();
  const empresaPesquisa = vinculadoEmpresa == true ? nomeEmpresaSelecionadoConsultaClienteTelaCAdastraCliente.toLowerCase() : nomeEmpresa.toLowerCase();
  const emailCliente = email.trim().toLowerCase();
  const senha = senha2.trim();
  const confirmarSenha = confirmaSenha2.trim();
  let keyEmpresaVinculada = keyEmpresaVinculada2;
  let uriHTTP = 0;
  let uriImagem;
  let tipoUri = isNumber(uriImagem2);
  let verificaSeUriImagemEhImagemUpadaOuNao = false;
  /*

    */


  if (uriImagem2 === null || uriImagem2 === undefined || uriImagem2.includes('/static/media/userPadrao') || uriImagem2.includes('data:image/png;base64')) {
    /*  */
    //é uma imagem local nao precisa upar
    uriHTTP = 1;
  } else if (uriImagem2.includes("http") && !uriImagem2.includes("blob")) {
    verificaSeUriImagemEhImagemUpadaOuNao = true;
    uriImagem = uriImagem2;
    /*   */
    uriHTTP = 1;
  } else if (arquivoCompletoParaUpar === '') {
    /*   */
    uriHTTP = 1;
  } else {
    /*   */
    //é uma imagem que precisa upar
    uriImagem = uriImagem2.uri;
  }
  /*  */

  /*  if (uriImagem2 !== null) {
     uriImagem = uriImagem2.uri;
     if (uriImagem.includes("http")) {
       uriHTTP = 1;
     }
   } */

  return dispatch => {

    ativaLoadingBotaoAlterarCliente(dispatch);
    if (nomeEmpresa.trim() === "" && vinculadoEmpresa == false) {
      toastr.error('Error', "Digite o nome da Empresa do cliente.");
      desativaLoadingBotaoAlterarCliente(dispatch);

    } else if (keyEmpresaVinculada2.length == 0 && vinculadoEmpresa == true) {
      toastr.error('Error', "Selecione a Empresa que Deseja vincular este Cliente.");
      desativaLoadingBotaoAlterarCliente(dispatch);
    } else if (keyEmpresaVinculada == key && vinculadoEmpresa == true) {
      toastr.error('Error', "Este cliente já está vinculado á essa empresa.");
      desativaLoadingBotaoAlterarCliente(dispatch);
    }
    else if (nomeEmpresa.trim().length < 2) {
      toastr.error('Error', "A empresa do cliente deve possuir mais de 1 letra.");
      desativaLoadingBotaoAlterarCliente(dispatch);
    } else if (nomeCliente.trim() === "") {
      toastr.error('Error', "Digite o nome do cliente");
      desativaLoadingBotaoAlterarCliente(dispatch);
    } else if (nomeCliente.trim().length < 2) {
      toastr.error('Error', "O nome do cliente deve possuir mais de 1 letra.");
      desativaLoadingBotaoAlterarCliente(dispatch);
    } else {
      //faço essa verificação pra saber se a imagem vem do firebase ou do celular
      //se tiver http na uri, então vem do firebase, então as imagens são iguais
      AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
        const chaveUnica = chaveUnica2.replace(/"/g, "");
        const cliente = firebase.database().ref(`${chaveUnica}/clientes/${key}`);
        if (telefoneCliente !== "" && configWhatsapp.length > 0) {
          isTelAndWpp = true
        }
        verificaSeNumeroEhValidoWhatsApp(isTelAndWpp, telefoneCliente, sessionclient, chaveUnica, urlApi, apiKey, "alter", key).then(
          (response) => {

            if (response.includes("TelefoneJaCadastradoBancoDados")) {
              let nomeCliente = response.replace("TelefoneJaCadastradoBancoDados", "")
              toastr.error('Error', `Este Número já se encontra cadastrado no cliente ${nomeCliente}.`)
              desativaLoadingBotaoAlterarCliente(dispatch);
            }
            else if (response === "TelefoneDesconectado") {
              toastr.error('Telefone Desconectado', 'Seu Telefone está sem Conexão com a Internet, verifique a conexão do seu Aparelho e tente novamente.')
              desativaLoadingBotaoAlterarCliente(dispatch);
            }
            else {
              if (response === "TelefoneInvalido") {
                //toastr.error('Telefone Inválido', 'Verifique o Número do Contato, informe conforme o Exemplo.')
                isTelAndWpp = false;
              }

              //Clintte tem permissao para acessar APP
              if (permissaoApp) {
                if (emailCliente.trim() === "") {
                  toastr.error('Error', "Digite um email.");
                  desativaLoadingBotaoAlterarCliente(dispatch);
                } else if (senha.trim() === "") {
                  toastr.error('Error', "Digite a senha de acesso.");
                  desativaLoadingBotaoAlterarCliente(dispatch);
                } else if (senha.trim().length < 6) {
                  toastr.error('Error', "A senha de acesso deve conter pelo menos 6 digitos.");
                  desativaLoadingBotaoAlterarCliente(dispatch);
                } else if (confirmarSenha.trim() === "") {
                  toastr.error('Error', "Confirme a senha de acesso.");
                  desativaLoadingBotaoAlterarCliente(dispatch);
                } else if (senha !== confirmarSenha) {
                  toastr.error('Error', "Senhas diferentes, por favor digite novamente.");
                  desativaLoadingBotaoAlterarCliente(dispatch);
                } else {
                  AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
                    const chaveUnica = chaveUnica2.replace(/"/g, "");
                    const cliente = firebase.database().ref(`${chaveUnica}/clientes/${key}`);
                    cliente.once("value").then(snapshot => {
                      const nomeEmpresaBancoMinusculo = snapshot.val().empresa.trim().toLowerCase();
                      const nomeEmpresaBanco = snapshot.val().empresa.trim();
                      const email = snapshot.val().email;
                      const alterado = 1 + snapshot.val().alterado;
                      const senhaBanco = snapshot.val().senha;
                      const useruid = snapshot.val().useruid;
                      if (vinculadoEmpresa) {
                        firebase
                          .database()
                          .ref(`${chaveUnica}/clientes/${keyEmpresaVinculada}`)
                          .update({ empresaPrincipal: true, keyEmpresaVinculada });
                      }
                      //
                      //
                      if (clienteEmpresaPrincipal == true && nomeEmpresaBanco !== nomeEmpresa) {
                        //
                        firebase.database().ref(`${chaveUnica}/clientes/`).orderByChild("keyEmpresaVinculada").equalTo(key).once("value")
                          .then(snapshot => {
                            //
                            snapshot.forEach(childItem => {
                              firebase
                                .database()
                                .ref(`${chaveUnica}/clientes/${childItem.key}`)
                                .update({
                                  empresa: nomeEmpresa,
                                  empresaPesquisa: empresaPesquisa,
                                  alterado
                                });
                            });
                          });
                      }
                      //beleza não precisa fazer verificação se já existe empresa com mesmo nome
                      //não tinha email então não tinha permissão, só criar email agora e setar os dados
                      if (email == "") {
                        //
                        /*   secondaryApp.auth().createUserWithEmailAndPassword(emailCliente, senha).then(user => {
                            const useruid = user.uid; */
                        const options = {
                          method: 'POST',
                          headers: {
                            'Content-Type': 'application/json; charset=utf-8',
                            'Accept': 'application/json',
                          },

                          data: { email: emailCliente, senha: senha, rota: 'create', chaveUnica },
                          url: 'crudUserReactNative',
                        };
                        api(options).then((response) => {


                          let useruid = response.data.uid;
                          /*    const primeiraLetra = nomeEmpresa.substring(0, 1).trim().toLowerCase();
                             //pesquiso para pegar a url da foto da letra pra fazer comparacao
                             firebase.storage().ref(`letras/${primeiraLetra}.png`).getDownloadURL().then(url => { */
                          //a foto que está no cliente é a mesma da pasta imagens, então não upo a foto
                          if (uriHTTP == 1) {
                            firebase
                              .database()
                              .ref(`${chaveUnica}/clientes/${key}`)
                              .update({
                                empresa:
                                  vinculadoEmpresa == true
                                    ? nomeEmpresaSelecionadoConsultaClienteTelaCAdastraCliente
                                    : nomeEmpresa,
                                nome: nomeCliente,
                                empresaPesquisa: empresaPesquisa,
                                email: emailCliente,
                                telefoneCliente,
                                enderecoCliente,
                                senha: senha,
                                permissaoAPP: true,
                                useruid: useruid,
                                alterado: alterado,
                                vinculadoEmpresa,
                                keyEmpresaVinculada: keyEmpresaVinculada == undefined ? "Não Vinculado" : keyEmpresaVinculada,
                                urlImagemNuvem: verificaSeUriImagemEhImagemUpadaOuNao == true ? uriImagem : "",
                                isMyContact: isTelAndWpp
                              })
                              .then(firebase.database().ref(`usuariosALL/${useruid}`).push({ chaveUnica: chaveUnica, admin: 0 }).then(
                                //cadastrando o nome do usuario na tabela principal (email base 64)
                                () => {
                                  setTimeout(() => {
                                    //dispatch(NavigationActions.back());
                                    removeFirebaseCachedData(`${chaveUnica}/clientes`, key);
                                    clearCacheRedis(chaveUnica,telefoneCliente);
                                    toastr.success('Sucesso', "Cliente alterado com sucesso!");
                                    modificaArquivoCompletoParaUparLocal(dispatch, '');
                                    desativaLoadingBotaoAlterarCliente(dispatch);
                                  }, 200);
                                }
                              )
                                .catch(error => {
                                  desativaLoadingBotaoAlterarCliente(dispatch);
                                  //error.code
                                  //error.message
                                  switch (error.code) {
                                    case "auth/invalid-email":
                                      toastr.error('Error',
                                        "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                                      );
                                      break;
                                    case "auth/email-already-in-use":
                                      toastr.error('Error',
                                        "Este email já está sendo utilizado, informe outro email."
                                      );
                                      break;
                                    case "auth/network-request-failed":
                                      toastr.error('Error',
                                        "Sem conexão com a internet"
                                      );
                                      break;
                                    default:
                                      toastr.error('Error',
                                        "Erro ao Alterar, tente novamente mais tarde"
                                      );
                                      break;
                                  }
                                })
                              )
                              .catch(error => {
                                //error.code
                                //error.message
                                desativaLoadingBotaoAlterarCliente(dispatch);
                                switch (error.code) {
                                  case "auth/invalid-email":
                                    toastr.error('Error',
                                      "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                                    );
                                    break;
                                  case "auth/email-already-in-use":
                                    toastr.error('Error',
                                      "Este email já está sendo utilizado, informe outro email."
                                    );
                                    break;
                                  case "auth/network-request-failed":
                                    toastr.error('Error',
                                      "Sem conexão com a internet"
                                    );
                                    break;
                                  default:
                                    toastr.error('Error',
                                      "Erro ao Alterar, tente novamente mais tarde"
                                    );
                                    break;
                                }
                              });
                          }
                          //foto diferente, vou upar no banco
                          else {
                            //  let uri = uriImagem.replace("file://", "");
                            let caminhoPadraoImagem = firebase.storage().ref(`${chaveUnica}/${key}/imagem.jpg`);
                            let mime = "image/jpeg";
                            /*    RNFetchBlob.fs.readFile(uri, "base64").then(data => {
                                 return RNFetchBlob.polyfill.Blob.build(data, {
                                   type: mime + ";BASE64"
                                 });
                               })
                                 .then(blob => { */

                            caminhoPadraoImagem.put(arquivoCompletoParaUpar).then((url) => {
                              url.ref.getDownloadURL().then(function (downloadURL) {
                                firebase
                                  .database()
                                  .ref(`${chaveUnica}/clientes/${key}`)
                                  .update({
                                    empresa:
                                      vinculadoEmpresa == true
                                        ? nomeEmpresaSelecionadoConsultaClienteTelaCAdastraCliente
                                        : nomeEmpresa,
                                    nome: nomeCliente,
                                    empresaPesquisa: empresaPesquisa,
                                    telefoneCliente,
                                    enderecoCliente,
                                    email: emailCliente,
                                    //  empresa: nomeEmpresa,
                                    senha: senha,
                                    permissaoAPP: true,
                                    useruid: useruid,
                                    alterado: alterado,
                                    vinculadoEmpresa,
                                    keyEmpresaVinculada: keyEmpresaVinculada == undefined ? "Não Vinculado" : keyEmpresaVinculada,
                                    urlImagemNuvem: downloadURL,
                                    isMyContact: isTelAndWpp
                                  })
                                  .then(
                                    firebase
                                      .database()
                                      .ref(`usuariosALL/${useruid}`)
                                      .push({
                                        chaveUnica: chaveUnica,
                                        admin: 0
                                      })
                                      .then(
                                        //cadastrando o nome do usuario na tabela principal (email base 64)
                                        () => {
                                          setTimeout(() => {
                                            removeFirebaseCachedData(`${chaveUnica}/clientes`, key);
                                            clearCacheRedis(chaveUnica,telefoneCliente);
                                            modificaArquivoCompletoParaUparLocal(dispatch, '');
                                            // blob.close();
                                            toastr.success('Sucesso',
                                              "Cliente alterado com sucesso!"
                                            );
                                            desativaLoadingBotaoAlterarCliente(dispatch);
                                          }, 200);
                                        }
                                      )
                                      .catch(error => {
                                        desativaLoadingBotaoAlterarCliente(dispatch);
                                        //error.code
                                        //error.message
                                        switch (error.code) {
                                          case "auth/invalid-email":
                                            toastr.error('Error',
                                              "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                                            );
                                            break;
                                          case "auth/email-already-in-use":
                                            toastr.error('Error',
                                              "Este email já está sendo utilizado, informe outro email."
                                            );
                                            break;
                                          case "auth/network-request-failed":
                                            toastr.error('Error',
                                              "Sem conexão com a internet"
                                            );
                                            break;
                                          default:
                                            toastr.error('Error',
                                              "Erro ao Alterar, tente novamente mais tarde"
                                            );
                                            break;
                                        }
                                      })
                                  )
                                  .catch(error => {
                                    desativaLoadingBotaoAlterarCliente(dispatch);
                                    //error.code
                                    //error.message
                                    switch (error.code) {
                                      case "auth/invalid-email":
                                        toastr.error('Error',
                                          "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                                        );
                                        break;
                                      case "auth/email-already-in-use":
                                        toastr.error('Error',
                                          "Este email já está sendo utilizado, informe outro email."
                                        );
                                        break;
                                      case "auth/network-request-failed":
                                        toastr.error('Error',
                                          "Sem conexão com a internet"
                                        );
                                        break;
                                      default:
                                        toastr.error('Error',
                                          "Erro ao Alterar, tente novamente mais tarde"
                                        );
                                        break;
                                    }
                                  });
                              })
                            })
                              .catch(error => {
                                desativaLoadingBotaoAlterarCliente(dispatch);
                                //error.code
                                //error.message
                                switch (error.code) {
                                  case "auth/invalid-email":
                                    toastr.error('Error',
                                      "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                                    );
                                    break;
                                  case "auth/email-already-in-use":
                                    toastr.error('Error',
                                      "Este email já está sendo utilizado, informe outro email."
                                    );
                                    break;
                                  case "auth/network-request-failed":
                                    toastr.error('Error',
                                      "Sem conexão com a internet"
                                    );
                                    break;
                                  default:
                                    toastr.error('Error',
                                      "Erro ao Alterar, tente novamente mais tarde"
                                    );
                                    break;
                                }
                              });
                            /*    })
                               .catch(error => {
                                 //error.code
                                 //error.message
                                 switch (error.code) {
                                   case "auth/invalid-email":
                                      toastr.error('Error',
                                       "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                                     );
                                     break;
                                   case "auth/email-already-in-use":
                                      toastr.error('Error',
                                       "Este email já está sendo utilizado, informe outro email."
                                     );
                                     break;
                                   case "auth/network-request-failed":
                                      toastr.error('Error',
                                       "Sem conexão com a internet"
                                     );
                                     break;
                                   default:
                                      toastr.error('Error',
                                       "Erro ao Alterar, tente novamente mais tarde"
                                     );
                                     break;
                                 }
                               }); */
                          }
                          /*  })
                           .catch(error => {
                             //error.code
                             //error.message
                             switch (error.code) {
                               case "auth/invalid-email":
                                 mensagemCasdastraClienteErrorTelaAlterar(
                                   dispatch,
                                   "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                                 );
                                 break;
                               case "auth/email-already-in-use":
                                 mensagemCasdastraClienteErrorTelaAlterar(
                                   dispatch,
                                   "Este email já está sendo utilizado, informe outro email."
                                 );
                                 break;
                               case "auth/network-request-failed":
                                 mensagemCasdastraClienteErrorTelaAlterar(
                                   dispatch,
                                   "Sem conexão com a internet"
                                 );
                                 break;
                               case "storage/object-not-found":
                                 mensagemCasdastraClienteErrorTelaAlterar(
                                   dispatch,
                                   "Nome inválido, utilize Letras!"
                                 );
                                 break;
                               default:
                                 mensagemCasdastraClienteErrorTelaAlterar(
                                   dispatch,
                                   "Erro ao Alterar, tente novamente mais tarde"
                                 );
                                 break;
                             }
                           }); */
                        })
                          .catch(error => {
                            desativaLoadingBotaoAlterarCliente(dispatch);
                            //error.code
                            //error.message
                            if (error.response) {


                              switch (error.response.data.code) {
                                case 'auth/email-already-exists':
                                  toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                                  break;
                                case 'auth/invalid-email':
                                  toastr.error('Error', 'Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br');
                                  break;
                                case 'auth/email-already-in-use':
                                  toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                                  break;
                                case 'auth/network-request-failed':
                                  toastr.error('Error', 'Sem conexão com a internet');
                                  break;
                                default:
                                  toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                                  break;
                              }
                            } else {
                              toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                            }
                          });
                      }
                      //tenho email no banco, então eu verifico se o email do banco é diferente do
                      // email digitado, se for eu já altero o email e senha, se não eu só altero a senha
                      else if (email !== emailCliente) {
                        //
                        //não tem senha no banco então utilizo a senha padrao
                        if (senhaBanco == "") {
                          //
                          /*     secondaryApp.auth().signInWithEmailAndPassword(email, "exemplo@exemplo2659")
                                .then(userCredential => {
                                  userCredential.updateEmail(emailCliente).then(() => {
                                    const useruid = userCredential.uid;
                                    // secondaryApp.auth().signOut();
                                    secondaryApp.auth().signInWithEmailAndPassword(emailCliente, "exemplo@exemplo2659")
                                      .then(user2 => {
                                        user2
                                          .updatePassword(senha)
                                          .then(() => { */
                          const options = {
                            method: 'POST',
                            headers: {
                              'Content-Type': 'application/json; charset=utf-8',
                              'Accept': 'application/json',
                            },

                            data: { email: emailCliente, senha: senha, rota: 'alteraremailesenha', uid: useruid },
                            url: 'crudUserReactNative',
                          };
                          api(options).then((response) => {


                            /*   const primeiraLetra = nomeEmpresa.substring(0, 1).trim().toLowerCase();
                              firebase.storage().ref(`letras/${primeiraLetra}.png`).getDownloadURL().then(url => { */
                            //a foto que está no cliente é a mesma do banco, então não upo a foto
                            if (uriHTTP == 1) {
                              firebase
                                .database()
                                .ref(
                                  `${chaveUnica}/clientes/${key}`
                                )
                                .update({
                                  empresa:
                                    vinculadoEmpresa == true
                                      ? nomeEmpresaSelecionadoConsultaClienteTelaCAdastraCliente
                                      : nomeEmpresa,
                                  nome: nomeCliente,
                                  telefoneCliente,
                                  enderecoCliente,
                                  empresaPesquisa: empresaPesquisa,
                                  email: emailCliente,
                                  senha: senha,
                                  permissaoAPP: true,
                                  alterado: alterado,
                                  vinculadoEmpresa,
                                  keyEmpresaVinculada:
                                    keyEmpresaVinculada ==
                                      undefined
                                      ? "Não Vinculado"
                                      : keyEmpresaVinculada,
                                  urlImagemNuvem: verificaSeUriImagemEhImagemUpadaOuNao == true ? uriImagem : "",
                                  isMyContact: isTelAndWpp
                                })
                                .then(
                                  firebase
                                    .database()
                                    .ref(`usuariosALL/${useruid}`)
                                    .push({
                                      chaveUnica: chaveUnica,
                                      admin: 0
                                    })
                                    .then(() => {
                                      setTimeout(() => {

                                        removeFirebaseCachedData(`${chaveUnica}/clientes`, key);
                                        clearCacheRedis(chaveUnica,telefoneCliente);
                                        desativaLoadingBotaoAlterarCliente(dispatch);
                                        toastr.success('Sucesso',
                                          "Cliente alterado com sucesso!"
                                        );
                                        modificaArquivoCompletoParaUparLocal(dispatch, '');
                                      }, 200);
                                    })
                                    .catch(error => {
                                      desativaLoadingBotaoAlterarCliente(dispatch);
                                      //error.code
                                      //error.message
                                      switch (error.code) {
                                        case "auth/invalid-email":
                                          toastr.error('Error',
                                            "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                                          );
                                          break;
                                        case "auth/email-already-in-use":
                                          toastr.error('Error',
                                            "Este email já está sendo utilizado, informe outro email."
                                          );
                                          break;
                                        case "auth/network-request-failed":
                                          toastr.error('Error',
                                            "Sem conexão com a internet"
                                          );
                                          break;
                                        default:
                                          toastr.error('Error',
                                            "Erro ao Alterar, tente novamente mais tarde"
                                          );
                                          break;
                                      }
                                    })
                                )
                                .catch(error => {
                                  desativaLoadingBotaoAlterarCliente(dispatch);
                                  //error.code
                                  //error.message
                                  switch (error.code) {
                                    case "auth/invalid-email":
                                      toastr.error('Error',
                                        "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                                      );
                                      break;
                                    case "auth/email-already-in-use":
                                      toastr.error('Error',
                                        "Este email já está sendo utilizado, informe outro email."
                                      );
                                      break;
                                    case "auth/network-request-failed":
                                      toastr.error('Error',
                                        "Sem conexão com a internet"
                                      );
                                      break;
                                    default:
                                      toastr.error('Error',
                                        "Erro ao Alterar, tente novamente mais tarde"
                                      );
                                      break;
                                  }
                                });
                            }
                            //foto diferente, vou upar no banco
                            else {
                              //  let uri = uriImagem.replace("file://","");
                              let caminhoPadraoImagem = firebase.storage().ref(`${chaveUnica}/${key}/imagem.jpg`);
                              let mime = "image/jpeg";
                              /*   RNFetchBlob.fs
                                  .readFile(uri, "base64")
                                  .then(data => {
                                    return RNFetchBlob.polyfill.Blob.build(
                                      data,
                                      { type: mime + ";BASE64" }
                                    );
                                  })
                                  .then(blob => { */
                              caminhoPadraoImagem.put(arquivoCompletoParaUpar).then((url) => {
                                url.ref.getDownloadURL().then(function (downloadURL) {
                                  firebase
                                    .database()
                                    .ref(
                                      `${chaveUnica}/clientes/${key}`
                                    )
                                    .update({
                                      empresa:
                                        vinculadoEmpresa ==
                                          true
                                          ? nomeEmpresaSelecionadoConsultaClienteTelaCAdastraCliente
                                          : nomeEmpresa,
                                      nome: nomeCliente,
                                      empresaPesquisa: empresaPesquisa,
                                      email: emailCliente,
                                      senha: senha,
                                      permissaoAPP: true,
                                      alterado: alterado,
                                      vinculadoEmpresa,
                                      keyEmpresaVinculada:
                                        keyEmpresaVinculada ==
                                          undefined
                                          ? "Não Vinculado"
                                          : keyEmpresaVinculada,
                                      urlImagemNuvem: downloadURL,
                                      isMyContact: isTelAndWpp
                                    })
                                    .then(
                                      firebase
                                        .database()
                                        .ref(
                                          `usuariosALL/${useruid}`
                                        )
                                        .push({
                                          chaveUnica: chaveUnica,
                                          admin: 0
                                        })
                                        .then(() => {
                                          setTimeout(() => {

                                            removeFirebaseCachedData(`${chaveUnica}/clientes`, key);
                                            clearCacheRedis(chaveUnica,telefoneCliente);
                                            desativaLoadingBotaoAlterarCliente(dispatch);
                                            toastr.success('Sucesso',
                                              "Cliente alterado com sucesso!"
                                            );
                                          }, 200);
                                          modificaArquivoCompletoParaUparLocal(dispatch, '');
                                        })
                                        .catch(error => {
                                          desativaLoadingBotaoAlterarCliente(dispatch);
                                          //error.code
                                          //error.message
                                          switch (
                                          error.code
                                          ) {
                                            case "auth/invalid-email":
                                              toastr.error('Error',
                                                "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                                              );
                                              break;
                                            case "auth/email-already-in-use":
                                              toastr.error('Error',
                                                "Este email já está sendo utilizado, informe outro email."
                                              );
                                              break;
                                            case "auth/network-request-failed":
                                              toastr.error('Error',
                                                "Sem conexão com a internet"
                                              );
                                              break;
                                            default:
                                              toastr.error('Error',
                                                "Erro ao Alterar, tente novamente mais tarde"
                                              );
                                              break;
                                          }
                                        })
                                    )
                                    .catch(error => {
                                      desativaLoadingBotaoAlterarCliente(dispatch);
                                      //error.code
                                      //error.message
                                      switch (error.code) {
                                        case "auth/invalid-email":
                                          toastr.error('Error',
                                            "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                                          );
                                          break;
                                        case "auth/email-already-in-use":
                                          toastr.error('Error',
                                            "Este email já está sendo utilizado, informe outro email."
                                          );
                                          break;
                                        case "auth/network-request-failed":
                                          toastr.error('Error',
                                            "Sem conexão com a internet"
                                          );
                                          break;
                                        default:
                                          toastr.error('Error',
                                            "Erro ao Alterar, tente novamente mais tarde"
                                          );
                                          break;
                                      }
                                    });
                                })
                              })
                                .catch(error => {
                                  desativaLoadingBotaoAlterarCliente(dispatch);
                                  switch (error.code) {
                                    case "auth/invalid-email":
                                      toastr.error('Error',
                                        "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                                      );
                                      break;
                                    case "auth/email-already-in-use":
                                      toastr.error('Error',
                                        "Este email já está sendo utilizado, informe outro email."
                                      );
                                      break;
                                    case "auth/network-request-failed":
                                      toastr.error('Error',
                                        "Sem conexão com a internet"
                                      );
                                      break;
                                    default:
                                      toastr.error('Error',
                                        "Erro ao Alterar, tente novamente mais tarde"
                                      );
                                      break;
                                  }
                                });
                              /*  })
                               .catch(error => {
                                 //error.code
                                 //error.message
                                 switch (error.code) {
                                   case "auth/invalid-email":
                                     toastr.error('Error',
                                       "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                                     );
                                     break;
                                   case "auth/email-already-in-use":
                                     toastr.error('Error',
                                       "Este email já está sendo utilizado, informe outro email."
                                     );
                                     break;
                                   case "auth/network-request-failed":
                                     toastr.error('Error',
                                       "Sem conexão com a internet"
                                     );
                                     break;
                                   default:
                                     toastr.error('Error',
                                       "Erro ao Alterar, tente novamente mais tarde"
                                     );
                                     break;
                                 }
                               }); */
                            }
                            /*  })
                             .catch(error => {
                               //error.code
                               //error.message
                               switch (error.code) {
                                 case "auth/invalid-email":
                                   mensagemCasdastraClienteErrorTelaAlterar(
                                     dispatch,
                                     "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                                   );
                                   break;
                                 case "auth/email-already-in-use":
                                   mensagemCasdastraClienteErrorTelaAlterar(
                                     dispatch,
                                     "Este email já está sendo utilizado, informe outro email."
                                   );
                                   break;
                                 case "auth/network-request-failed":
                                   mensagemCasdastraClienteErrorTelaAlterar(
                                     dispatch,
                                     "Sem conexão com a internet"
                                   );
                                   break;
                                 case "storage/object-not-found":
                                   mensagemCasdastraClienteErrorTelaAlterar(
                                     dispatch,
                                     "Nome inválido, utilize Letras!"
                                   );
                                   break;

                                 default:
                                   mensagemCasdastraClienteErrorTelaAlterar(
                                     dispatch,
                                     "Erro ao Alterar, tente novamente mais tarde"
                                   );
                                   break;
                               }
                             }); */


                            /* */
                          })
                            .catch(error => {
                              desativaLoadingBotaoAlterarCliente(dispatch);
                              if (error.response) {


                                switch (error.response.data.code) {
                                  case 'auth/email-already-exists':
                                    toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                                    break;
                                  case 'auth/invalid-email':
                                    toastr.error('Error', 'Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br');
                                    break;
                                  case 'auth/email-already-in-use':
                                    toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                                    break;
                                  case 'auth/network-request-failed':
                                    toastr.error('Error', 'Sem conexão com a internet');
                                    break;
                                  default:
                                    toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                                    break;
                                }
                              } else {
                                toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                              }
                            });
                        }
                        // tem senha no banco, então utilizo a senha que vem do banco
                        else {
                          /*    //
                             secondaryApp.auth().signInWithEmailAndPassword(email, senhaBanco).then(userCredential => {
                               userCredential.updateEmail(emailCliente).then(() => {
                                 // secondaryApp.auth().signOut();
                                 secondaryApp.auth().signInWithEmailAndPassword(emailCliente, senhaBanco)
                                   .then(user2 => {
                                     user2.updatePassword(senha).then(() => { */
                          const options = {
                            method: 'POST',
                            headers: {
                              'Content-Type': 'application/json; charset=utf-8',
                              'Accept': 'application/json',
                            },

                            data: { email: emailCliente, senha: senha, rota: 'alteraremailesenha', uid: useruid },
                            url: 'crudUserReactNative',
                          };
                          api(options).then((response) => {


                            /*  const primeiraLetra = nomeEmpresa
                               .substring(0, 1)
                               .trim()
                               .toLowerCase();
                             firebase
                               .storage()
                               .ref(`letras/${primeiraLetra}.png`)
                               .getDownloadURL()
                               .then(url => { */
                            //a foto que está no cliente é a mesma do banco, então não upo a foto
                            if (uriHTTP == 1) {
                              firebase
                                .database()
                                .ref(
                                  `${chaveUnica}/clientes/${key}`
                                )
                                .update({
                                  empresa:
                                    vinculadoEmpresa == true
                                      ? nomeEmpresaSelecionadoConsultaClienteTelaCAdastraCliente
                                      : nomeEmpresa,
                                  nome: nomeCliente,
                                  empresaPesquisa: empresaPesquisa,
                                  telefoneCliente,
                                  enderecoCliente,
                                  email: emailCliente,
                                  senha: senha,
                                  permissaoAPP: true,
                                  alterado: alterado,
                                  vinculadoEmpresa,
                                  keyEmpresaVinculada:
                                    keyEmpresaVinculada ==
                                      undefined
                                      ? "Não Vinculado"
                                      : keyEmpresaVinculada,
                                  urlImagemNuvem: verificaSeUriImagemEhImagemUpadaOuNao == true ? uriImagem : "",
                                  isMyContact: isTelAndWpp
                                })
                                .then(() => {
                                  setTimeout(() => {
                                    removeFirebaseCachedData(`${chaveUnica}/clientes`, key);
                                    clearCacheRedis(chaveUnica,telefoneCliente);
                                    desativaLoadingBotaoAlterarCliente(dispatch);
                                    modificaArquivoCompletoParaUparLocal(dispatch, '');
                                    toastr.success('Sucesso',
                                      "Cliente alterado com sucesso!"
                                    );
                                  }, 700);
                                })
                                .catch(error => {
                                  desativaLoadingBotaoAlterarCliente(dispatch);
                                  //error.code
                                  //error.message
                                  switch (error.code) {
                                    case "auth/invalid-email":
                                      toastr.error('Error',
                                        "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                                      );
                                      break;
                                    case "auth/email-already-in-use":
                                      toastr.error('Error',
                                        "Este email já está sendo utilizado, informe outro email."
                                      );
                                      break;
                                    case "auth/network-request-failed":
                                      toastr.error('Error',
                                        "Sem conexão com a internet"
                                      );
                                      break;
                                    default:
                                      toastr.error('Error',
                                        "Erro ao Alterar, tente novamente mais tarde"
                                      );
                                      break;
                                  }
                                });
                            }
                            //foto diferente, vou upar no banco
                            else {
                              // let uri = uriImagem.replace("file://","");
                              let caminhoPadraoImagem = firebase.storage().ref(`${chaveUnica}/${key}/imagem.jpg`);
                              let mime = "image/jpeg";
                              /*  RNFetchBlob.fs
                                 .readFile(uri, "base64")
                                 .then(data => {
                                   return RNFetchBlob.polyfill.Blob.build(
                                     data,
                                     { type: mime + ";BASE64" }
                                   );
                                 })
                                 .then(blob => { */
                              caminhoPadraoImagem.put(arquivoCompletoParaUpar).then((url) => {
                                url.ref.getDownloadURL().then(function (downloadURL) {
                                  firebase
                                    .database()
                                    .ref(
                                      `${chaveUnica}/clientes/${key}`
                                    )
                                    .update({
                                      empresa:
                                        vinculadoEmpresa ==
                                          true
                                          ? nomeEmpresaSelecionadoConsultaClienteTelaCAdastraCliente
                                          : nomeEmpresa,
                                      nome: nomeCliente,
                                      empresaPesquisa: empresaPesquisa,
                                      email: emailCliente,
                                      senha: senha,
                                      permissaoAPP: true,
                                      alterado: alterado,
                                      vinculadoEmpresa,
                                      keyEmpresaVinculada:
                                        keyEmpresaVinculada ==
                                          undefined
                                          ? "Não Vinculado"
                                          : keyEmpresaVinculada,
                                      urlImagemNuvem: downloadURL,
                                      isMyContact: isTelAndWpp
                                    })
                                    .then(() => {
                                      setTimeout(() => {

                                        removeFirebaseCachedData(`${chaveUnica}/clientes`, key);
                                        clearCacheRedis(chaveUnica,telefoneCliente);
                                        //  blob.close();
                                        desativaLoadingBotaoAlterarCliente(dispatch);
                                        modificaArquivoCompletoParaUparLocal(dispatch, '');
                                        toastr.success('Sucesso',
                                          "Cliente alterado com sucesso!"
                                        );
                                      }, 200);
                                    })
                                    .catch(error => {
                                      desativaLoadingBotaoAlterarCliente(dispatch);
                                      //error.code
                                      //error.message
                                      switch (error.code) {
                                        case "auth/invalid-email":
                                          toastr.error('Error',
                                            "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                                          );
                                          break;
                                        case "auth/email-already-in-use":
                                          toastr.error('Error',
                                            "Este email já está sendo utilizado, informe outro email."
                                          );
                                          break;
                                        case "auth/network-request-failed":
                                          toastr.error('Error',
                                            "Sem conexão com a internet"
                                          );
                                          break;
                                        default:
                                          toastr.error('Error',
                                            "Erro ao Alterar, tente novamente mais tarde"
                                          );
                                          break;
                                      }
                                    });
                                })
                              })
                                .catch(error => {
                                  desativaLoadingBotaoAlterarCliente(dispatch);
                                  switch (error.code) {
                                    case "auth/invalid-email":
                                      toastr.error('Error',
                                        "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                                      );
                                      break;
                                    case "auth/email-already-in-use":
                                      toastr.error('Error',
                                        "Este email já está sendo utilizado, informe outro email."
                                      );
                                      break;
                                    case "auth/network-request-failed":
                                      toastr.error('Error',
                                        "Sem conexão com a internet"
                                      );
                                      break;
                                    default:
                                      toastr.error('Error',
                                        "Erro ao Alterar, tente novamente mais tarde"
                                      );
                                      break;
                                  }
                                });
                              /*  })
                               .catch(error => {
                                 //error.code
                                 //error.message
                                 switch (error.code) {
                                   case "auth/invalid-email":
                                      toastr.error('Error',
                                       "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                                     );
                                     break;
                                   case "auth/email-already-in-use":
                                      toastr.error('Error',
                                       "Este email já está sendo utilizado, informe outro email."
                                     );
                                     break;
                                   case "auth/network-request-failed":
                                      toastr.error('Error',
                                       "Sem conexão com a internet"
                                     );
                                     break;
                                   default:
                                      toastr.error('Error',
                                       "Erro ao Alterar, tente novamente mais tarde"
                                     );
                                     break;
                                 }
                               }); */
                            }
                            /*  })
                             .catch(error => {
                               //error.code
                               //error.message
                               switch (error.code) {
                                 case "auth/invalid-email":
                                   mensagemCasdastraClienteErrorTelaAlterar(
                                     dispatch,
                                     "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                                   );
                                   break;
                                 case "auth/email-already-in-use":
                                   mensagemCasdastraClienteErrorTelaAlterar(
                                     dispatch,
                                     "Este email já está sendo utilizado, informe outro email."
                                   );
                                   break;
                                 case "storage/object-not-found":
                                   mensagemCasdastraClienteErrorTelaAlterar(
                                     dispatch,
                                     "Nome inválido, utilize Letras!"
                                   );
                                   break;
                                 case "auth/network-request-failed":
                                   mensagemCasdastraClienteErrorTelaAlterar(
                                     dispatch,
                                     "Sem conexão com a internet"
                                   );
                                   break;
                                 default:
                                   mensagemCasdastraClienteErrorTelaAlterar(
                                     dispatch,
                                     "Erro ao Alterar, tente novamente mais tarde"
                                   );
                                   break;
                               }
                             }); */


                            /* */
                          })
                            .catch(error => {
                              desativaLoadingBotaoAlterarCliente(dispatch);
                              if (error.response) {


                                switch (error.response.data.code) {
                                  case 'auth/email-already-exists':
                                    toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                                    break;
                                  case 'auth/invalid-email':
                                    toastr.error('Error', 'Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br');
                                    break;
                                  case 'auth/email-already-in-use':
                                    toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                                    break;
                                  case 'auth/network-request-failed':
                                    toastr.error('Error', 'Sem conexão com a internet');
                                    break;
                                  default:
                                    toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                                    break;
                                }
                              } else {
                                toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                              }
                            });
                        }
                      }
                      //email é igual ao que tem no banco, só altero a senha
                      else {
                        //
                        if (senhaBanco == "") {
                          //
                          /*    secondaryApp
                               .auth()
                               .signInWithEmailAndPassword(
                                 emailCliente,
                                 "exemplo@exemplo2659"
                               )
                               .then(userCredential => {
                                 userCredential
                                   .updatePassword(senha)
                                   .then(() => {
                                     const useruid = userCredential.uid; */
                          const options = {
                            method: 'POST',
                            headers: {
                              'Content-Type': 'application/json; charset=utf-8',
                              'Accept': 'application/json',
                            },

                            data: { email: emailCliente, senha: senha, rota: 'alterarsenha', uid: useruid },
                            url: 'crudUserReactNative',
                          };
                          api(options).then((response) => {


                            /*  const primeiraLetra = nomeEmpresa
                               .substring(0, 1)
                               .trim()
                               .toLowerCase();
                             firebase
                               .storage()
                               .ref(`letras/${primeiraLetra}.png`)
                               .getDownloadURL()
                               .then(url => { */
                            //a foto que está no cliente é a mesma do banco, então não upo a foto
                            if (uriHTTP == 1) {
                              firebase
                                .database()
                                .ref(`${chaveUnica}/clientes/${key}`)
                                .update({
                                  empresa:
                                    vinculadoEmpresa == true
                                      ? nomeEmpresaSelecionadoConsultaClienteTelaCAdastraCliente
                                      : nomeEmpresa,
                                  nome: nomeCliente,
                                  telefoneCliente,
                                  enderecoCliente,
                                  empresaPesquisa: empresaPesquisa,
                                  senha: senha,
                                  permissaoAPP: true,
                                  alterado: alterado,
                                  vinculadoEmpresa,
                                  keyEmpresaVinculada:
                                    keyEmpresaVinculada == undefined
                                      ? "Não Vinculado"
                                      : keyEmpresaVinculada,
                                  urlImagemNuvem: verificaSeUriImagemEhImagemUpadaOuNao == true ? uriImagem : "",
                                  isMyContact: isTelAndWpp
                                })
                                .then(
                                  firebase
                                    .database()
                                    .ref(`usuariosALL/${useruid}`)
                                    .push({
                                      chaveUnica: chaveUnica,
                                      admin: 0
                                    })
                                    .then(() => {
                                      setTimeout(() => {
                                        removeFirebaseCachedData(`${chaveUnica}/clientes`, key);
                                        clearCacheRedis(chaveUnica,telefoneCliente);
                                        desativaLoadingBotaoAlterarCliente(dispatch);
                                        modificaArquivoCompletoParaUparLocal(dispatch, '');
                                        toastr.success('Sucesso',
                                          "Cliente alterado com sucesso!"
                                        );
                                      }, 200);
                                    })
                                    .catch(error => {
                                      desativaLoadingBotaoAlterarCliente(dispatch);
                                      //error.code
                                      //error.message
                                      switch (error.code) {
                                        case "auth/invalid-email":
                                          toastr.error('Error',
                                            "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                                          );
                                          break;
                                        case "auth/email-already-in-use":
                                          toastr.error('Error',
                                            "Este email já está sendo utilizado, informe outro email."
                                          );
                                          break;
                                        case "auth/network-request-failed":
                                          toastr.error('Error',
                                            "Sem conexão com a internet"
                                          );
                                          break;
                                        default:
                                          toastr.error('Error',
                                            "Erro ao Alterar, tente novamente mais tarde"
                                          );
                                          break;
                                      }
                                    })
                                )
                                .catch(error => {
                                  desativaLoadingBotaoAlterarCliente(dispatch);
                                  //error.code
                                  //error.message
                                  switch (error.code) {
                                    case "auth/invalid-email":
                                      toastr.error('Error',
                                        "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                                      );
                                      break;
                                    case "auth/email-already-in-use":
                                      toastr.error('Error',
                                        "Este email já está sendo utilizado, informe outro email."
                                      );
                                      break;
                                    case "auth/network-request-failed":
                                      toastr.error('Error',
                                        "Sem conexão com a internet"
                                      );
                                      break;
                                    default:
                                      toastr.error('Error',
                                        "Erro ao Alterar, tente novamente mais tarde"
                                      );
                                      break;
                                  }
                                });
                            }
                            //foto diferente, vou upar no banco
                            else {
                              // let uri = uriImagem.replace("file://", "");
                              let caminhoPadraoImagem = firebase.storage().ref(`${chaveUnica}/${key}/imagem.jpg`);
                              let mime = "image/jpeg";
                              /*   RNFetchBlob.fs
                                  .readFile(uri, "base64")
                                  .then(data => {
                                    return RNFetchBlob.polyfill.Blob.build(
                                      data,
                                      { type: mime + ";BASE64" }
                                    );
                                  })
                                  .then(blob => { */
                              caminhoPadraoImagem
                                .put(arquivoCompletoParaUpar)
                                .then((url) => {
                                  url.ref.getDownloadURL().then(function (downloadURL) {
                                    firebase
                                      .database()
                                      .ref(
                                        `${chaveUnica}/clientes/${key}`
                                      )
                                      .update({
                                        empresa:
                                          vinculadoEmpresa == true
                                            ? nomeEmpresaSelecionadoConsultaClienteTelaCAdastraCliente
                                            : nomeEmpresa,
                                        nome: nomeCliente,
                                        telefoneCliente,
                                        enderecoCliente,
                                        empresaPesquisa: empresaPesquisa,
                                        senha: senha,
                                        permissaoAPP: true,
                                        alterado: alterado,
                                        vinculadoEmpresa,
                                        keyEmpresaVinculada:
                                          keyEmpresaVinculada ==
                                            undefined
                                            ? "Não Vinculado"
                                            : keyEmpresaVinculada,
                                        urlImagemNuvem: downloadURL,
                                        isMyContact: isTelAndWpp
                                      })
                                      .then(
                                        firebase
                                          .database()
                                          .ref(`usuariosALL/${useruid}`)
                                          .push({
                                            chaveUnica: chaveUnica,
                                            admin: 0
                                          })
                                          .then(() => {
                                            setTimeout(() => {

                                              removeFirebaseCachedData(`${chaveUnica}/clientes`, key);
                                              clearCacheRedis(chaveUnica,telefoneCliente);
                                              //  blob.close();
                                              desativaLoadingBotaoAlterarCliente(dispatch);
                                              modificaArquivoCompletoParaUparLocal(dispatch, '');
                                              toastr.success('Sucesso',
                                                "Cliente alterado com sucesso!"
                                              );
                                            }, 200);
                                          })
                                          .catch(error => {
                                            desativaLoadingBotaoAlterarCliente(dispatch);
                                            //error.code
                                            //error.message
                                            switch (error.code) {
                                              case "auth/invalid-email":
                                                toastr.error('Error',
                                                  "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                                                );
                                                break;
                                              case "auth/email-already-in-use":
                                                toastr.error('Error',
                                                  "Este email já está sendo utilizado, informe outro email."
                                                );
                                                break;
                                              case "auth/network-request-failed":
                                                toastr.error('Error',
                                                  "Sem conexão com a internet"
                                                );
                                                break;
                                              default:
                                                toastr.error('Error',
                                                  "Erro ao Alterar, tente novamente mais tarde"
                                                );
                                                break;
                                            }
                                          })
                                      )
                                      .catch(error => {
                                        desativaLoadingBotaoAlterarCliente(dispatch);
                                        //error.code
                                        //error.message
                                        switch (error.code) {
                                          case "auth/invalid-email":
                                            toastr.error('Error',
                                              "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                                            );
                                            break;
                                          case "auth/email-already-in-use":
                                            toastr.error('Error',
                                              "Este email já está sendo utilizado, informe outro email."
                                            );
                                            break;
                                          case "auth/network-request-failed":
                                            toastr.error('Error',
                                              "Sem conexão com a internet"
                                            );
                                            break;
                                          default:
                                            toastr.error('Error',
                                              "Erro ao Alterar, tente novamente mais tarde"
                                            );
                                            break;
                                        }
                                      });
                                  })
                                })
                                .catch(error => {
                                  desativaLoadingBotaoAlterarCliente(dispatch);
                                  switch (error.code) {
                                    case "auth/invalid-email":
                                      toastr.error('Error',
                                        "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                                      );
                                      break;
                                    case "auth/email-already-in-use":
                                      toastr.error('Error',
                                        "Este email já está sendo utilizado, informe outro email."
                                      );
                                      break;
                                    case "auth/network-request-failed":
                                      toastr.error('Error',
                                        "Sem conexão com a internet"
                                      );
                                      break;
                                    default:
                                      toastr.error('Error',
                                        "Erro ao Alterar, tente novamente mais tarde"
                                      );
                                      break;
                                  }
                                });

                            }
                            /* })
                            .catch(error => {
                              //error.code
                              //error.message
                              switch (error.code) {
                                case "auth/invalid-email":
                                   toastr.error('Error',
                                    "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                                  );
                                  break;
                                case "auth/email-already-in-use":
                                   toastr.error('Error',
                                    "Este email já está sendo utilizado, informe outro email."
                                  );
                                  break;
                                case "auth/network-request-failed":
                                   toastr.error('Error',
                                    "Sem conexão com a internet"
                                  );
                                  break;
                                case "storage/object-not-found":
                                   toastr.error('Error',
                                    "Nome inválido, utilize Letras!"
                                  );
                                  break;
                                default:
                                   toastr.error('Error',
                                    "Erro ao Alterar, tente novamente mais tarde"
                                  );
                                  break;
                              }
                            }); */

                          })
                            .catch(error => {
                              desativaLoadingBotaoAlterarCliente(dispatch);
                              if (error.response) {


                                switch (error.response.data.code) {
                                  case 'auth/email-already-exists':
                                    toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                                    break;
                                  case 'auth/invalid-email':
                                    toastr.error('Error', 'Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br');
                                    break;
                                  case 'auth/email-already-in-use':
                                    toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                                    break;
                                  case 'auth/network-request-failed':
                                    toastr.error('Error', 'Sem conexão com a internet');
                                    break;
                                  default:
                                    toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                                    break;
                                }
                              } else {
                                toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                              }
                            });
                        }
                        //tem senha no banco, então utilizo a senha que vem do banco
                        else {
                          //
                          /*  secondaryApp
                             .auth()
                             .signInWithEmailAndPassword(emailCliente, senhaBanco)
                             .then(userCredential => {
                               userCredential
                                 .updatePassword(senha)
                                 .then(() => { */
                          const options = {
                            method: 'POST',
                            headers: {
                              'Content-Type': 'application/json; charset=utf-8',
                              'Accept': 'application/json',
                            },

                            data: { email: emailCliente, senha: senha, rota: 'alterarsenha', uid: useruid },
                            url: 'crudUserReactNative',
                          };
                          api(options).then((response) => {


                            /* const primeiraLetra = nomeEmpresa
                              .substring(0, 1)
                              .trim()
                              .toLowerCase();
                            firebase
                              .storage()
                              .ref(`letras/${primeiraLetra}.png`)
                              .getDownloadURL()
                              .then(url => { */
                            //a foto que está no cliente é a mesma do banco, então não upo a foto
                            if (uriHTTP == 1) {
                              firebase
                                .database()
                                .ref(`${chaveUnica}/clientes/${key}`)
                                .update({
                                  empresa:
                                    vinculadoEmpresa == true
                                      ? nomeEmpresaSelecionadoConsultaClienteTelaCAdastraCliente
                                      : nomeEmpresa,
                                  nome: nomeCliente,
                                  telefoneCliente,
                                  enderecoCliente,
                                  empresaPesquisa: empresaPesquisa,
                                  senha: senha,
                                  permissaoAPP: true,
                                  alterado: alterado,
                                  vinculadoEmpresa,
                                  keyEmpresaVinculada:
                                    keyEmpresaVinculada == undefined
                                      ? "Não Vinculado"
                                      : keyEmpresaVinculada,
                                  urlImagemNuvem: verificaSeUriImagemEhImagemUpadaOuNao == true ? uriImagem : "",
                                  isMyContact: isTelAndWpp
                                })
                                .then(() => {
                                  setTimeout(() => {
                                    removeFirebaseCachedData(`${chaveUnica}/clientes`, key);
                                    clearCacheRedis(chaveUnica,telefoneCliente);
                                    desativaLoadingBotaoAlterarCliente(dispatch);
                                    modificaArquivoCompletoParaUparLocal(dispatch, '');
                                    toastr.success('Sucesso',
                                      "Cliente alterado com sucesso!"
                                    );
                                  }, 700);
                                });
                            }
                            //foto diferente, vou upar no banco
                            else {
                              // let uri = uriImagem.replace("file://", "");
                              let caminhoPadraoImagem = firebase
                                .storage()
                                .ref(`${chaveUnica}/${key}/imagem.jpg`);
                              let mime = "image/jpeg";
                              /*    RNFetchBlob.fs
                                   .readFile(uri, "base64")
                                   .then(data => {
                                     return RNFetchBlob.polyfill.Blob.build(
                                       data,
                                       { type: mime + ";BASE64" }
                                     );
                                   })
                                   .then(blob => { */
                              caminhoPadraoImagem
                                .put(arquivoCompletoParaUpar)
                                .then((url) => {
                                  url.ref.getDownloadURL().then(function (downloadURL) {
                                    firebase
                                      .database()
                                      .ref(
                                        `${chaveUnica}/clientes/${key}`
                                      )
                                      .update({
                                        empresa:
                                          vinculadoEmpresa == true
                                            ? nomeEmpresaSelecionadoConsultaClienteTelaCAdastraCliente
                                            : nomeEmpresa,
                                        nome: nomeCliente,
                                        empresaPesquisa: empresaPesquisa,
                                        email: emailCliente,
                                        telefoneCliente,
                                        enderecoCliente,
                                        // empresa: nomeEmpresa,
                                        senha: senha,
                                        permissaoAPP: true,
                                        alterado: alterado,
                                        vinculadoEmpresa,
                                        keyEmpresaVinculada:
                                          keyEmpresaVinculada ==
                                            undefined
                                            ? "Não Vinculado"
                                            : keyEmpresaVinculada,
                                        urlImagemNuvem: downloadURL,
                                        isMyContact: isTelAndWpp
                                      })
                                      .then(() => {
                                        setTimeout(() => {
                                          removeFirebaseCachedData(`${chaveUnica}/clientes`, key);
                                          clearCacheRedis(chaveUnica,telefoneCliente);
                                          desativaLoadingBotaoAlterarCliente(dispatch);
                                          modificaArquivoCompletoParaUparLocal(dispatch, '');
                                          toastr.success('Sucesso',
                                            "Cliente alterado com sucesso!"
                                          );
                                        }, 700);
                                      });
                                  })
                                })
                                .catch(error => {
                                  desativaLoadingBotaoAlterarCliente(dispatch);
                                  switch (error.code) {
                                    case "auth/invalid-email":
                                      toastr.error('Error',
                                        "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                                      );
                                      break;
                                    case "auth/email-already-in-use":
                                      toastr.error('Error',
                                        "Este email já está sendo utilizado, informe outro email."
                                      );
                                      break;
                                    case "auth/network-request-failed":
                                      toastr.error('Error',
                                        "Sem conexão com a internet"
                                      );
                                      break;
                                    default:
                                      toastr.error('Error',
                                        "Erro ao Alterar, tente novamente mais tarde"
                                      );
                                      break;
                                  }
                                });

                            }
                            /*  })
                             .catch(error => {
                               switch (error.code) {
                                 case "auth/invalid-email":
                                   mensagemCasdastraClienteErrorTelaAlterar(
                                     dispatch,
                                     "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                                   );
                                   break;
                                 case "auth/email-already-in-use":
                                   mensagemCasdastraClienteErrorTelaAlterar(
                                     dispatch,
                                     "Este email já está sendo utilizado, informe outro email."
                                   );
                                   break;
                                 case "auth/network-request-failed":
                                   mensagemCasdastraClienteErrorTelaAlterar(
                                     dispatch,
                                     "Sem conexão com a internet"
                                   );
                                   break;
                                 case "storage/object-not-found":
                                   mensagemCasdastraClienteErrorTelaAlterar(
                                     dispatch,
                                     "Nome inválido, utilize Letras!"
                                   );
                                   break;
                                 default:
                                   mensagemCasdastraClienteErrorTelaAlterar(
                                     dispatch,
                                     "Erro ao Alterar, tente novamente mais tarde"
                                   );
                                   break;
                               }
                             }); */

                          })
                            .catch(error => {
                              desativaLoadingBotaoAlterarCliente(dispatch);
                              if (error.response) {


                                switch (error.response.data.code) {
                                  case 'auth/email-already-exists':
                                    toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                                    break;
                                  case 'auth/invalid-email':
                                    toastr.error('Error', 'Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br');
                                    break;
                                  case 'auth/email-already-in-use':
                                    toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                                    break;
                                  case 'auth/network-request-failed':
                                    toastr.error('Error', 'Sem conexão com a internet');
                                    break;
                                  default:
                                    toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                                    break;
                                }
                              } else {
                                toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                              }
                            });
                        }
                      }
                      // mensagemToastTelaCOnsultaCliente(dispatch, 'Sucesso teste')
                    });
                  });
                }
              }
              //nao tem permissao para acessar app
              //ou seja, agora ele não tem permissão pra acessar app, então tenho que
              //inativar email que está no banco
              else {
                AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
                  const chaveUnica = chaveUnica2.replace(/"/g, "");
                  const cliente = firebase.database().ref(`${chaveUnica}/clientes/${key}`);
                  cliente.once("value").then(snapshot => {
                    const nomeEmpresaBancoMinusculo = snapshot.val().empresa.trim().toLowerCase();
                    const nomeEmpresaBanco = snapshot.val().empresa.trim();
                    const permissaoAppBanco = snapshot.val().permissaoAPP;
                    const useruid = snapshot.val().useruid;
                    const emailBanco = snapshot.val().email;
                    const alterado = 1 + snapshot.val().alterado;
                    const senhaBanco = snapshot.val().senha;
                    if (vinculadoEmpresa) {
                      firebase
                        .database()
                        .ref(`${chaveUnica}/clientes/${keyEmpresaVinculada}`)
                        .update({ empresaPrincipal: true, keyEmpresaVinculada });
                    }
                    if (clienteEmpresaPrincipal == true && nomeEmpresaBanco !== nomeEmpresa) {
                      //
                      firebase
                        .database()
                        .ref(`${chaveUnica}/clientes/`)
                        .orderByChild("keyEmpresaVinculada")
                        .equalTo(key)
                        .once("value")
                        .then(snapshot => {
                          //
                          snapshot.forEach(childItem => {
                            firebase
                              .database()
                              .ref(`${chaveUnica}/clientes/${childItem.key}`)
                              .update({
                                empresa: nomeEmpresa,
                                empresaPesquisa: empresaPesquisa,
                                alterado
                              });
                          });
                        });
                    }
                    //nome da empresa igual então não preciso verificar se já tem cliente com este nome

                    //tem permissao no banco mas não tem mais via app
                    if (permissaoAppBanco == true && permissaoApp == false) {
                      //
                      //tem permsisao no banco e não tem mais via app
                      //então eu só troco a senha e deixo o campo do email vazio no banco
                      if (emailCliente == "") {
                        //
                        /*       secondaryApp
                                .auth()
                                .signInWithEmailAndPassword(emailBanco, senhaBanco)
                                .then(userCredential => { */
                        const uidUsuario = useruid;

                        const options = {
                          method: 'POST',
                          headers: {
                            'Content-Type': 'application/json; charset=utf-8',
                            'Accept': 'application/json',
                          },

                          data: { email: emailBanco, senha: senhaBanco, rota: 'excluir', uid: useruid },
                          url: 'crudUserReactNative',
                        };
                        api(options).then(() => {

                          /* const primeiraLetra = nomeEmpresa
                            .substring(0, 1)
                            .trim()
                            .toLowerCase();
                          firebase
                            .storage()
                            .ref(`letras/${primeiraLetra}.png`)
                            .getDownloadURL()
                            .then(url => { */
                          //a foto que está no cliente é a mesma do banco, então não upo a foto
                          if (uriHTTP == 1) {
                            firebase
                              .database()
                              .ref(`${chaveUnica}/clientes/${key}`)
                              .update({
                                empresa:
                                  vinculadoEmpresa == true
                                    ? nomeEmpresaSelecionadoConsultaClienteTelaCAdastraCliente
                                    : nomeEmpresa,
                                nome: nomeCliente,
                                telefoneCliente,
                                enderecoCliente,
                                empresaPesquisa: empresaPesquisa,
                                senha: "",
                                email: "",
                                permissaoAPP: false,
                                alterado: alterado,
                                useruid: "",
                                vinculadoEmpresa,
                                keyEmpresaVinculada:
                                  keyEmpresaVinculada == undefined
                                    ? "Não Vinculado"
                                    : keyEmpresaVinculada,
                                urlImagemNuvem: verificaSeUriImagemEhImagemUpadaOuNao == true ? uriImagem : "",
                                isMyContact: isTelAndWpp
                              })
                              .then(() => {
                                setTimeout(() => {
                                  removeFirebaseCachedData(`${chaveUnica}/clientes`, key);
                                  clearCacheRedis(chaveUnica,telefoneCliente);
                                  modificaArquivoCompletoParaUparLocal(dispatch, '');
                                  desativaLoadingBotaoAlterarCliente(dispatch);
                                  toastr.success('Sucesso',
                                    "Cliente alterado com sucesso!"
                                  );
                                }, 700);
                              })
                              .catch(error => {
                                desativaLoadingBotaoAlterarCliente(dispatch);
                                //error.code
                                //error.message
                                switch (error.code) {
                                  case "auth/invalid-email":
                                    toastr.error('Error',
                                      "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                                    );
                                    break;
                                  case "auth/email-already-in-use":
                                    toastr.error('Error',
                                      "Este email já está sendo utilizado, informe outro email."
                                    );
                                    break;
                                  case "auth/network-request-failed":
                                    toastr.error('Error',
                                      "Sem conexão com a internet"
                                    );
                                    break;
                                  default:
                                    toastr.error('Error',
                                      "Erro ao Alterar, tente novamente mais tarde"
                                    );
                                    break;
                                }
                              });
                          }
                          //foto diferente, vou upar no banco
                          else {
                            //  let uri = uriImagem.replace("file://", "");
                            let caminhoPadraoImagem = firebase
                              .storage()
                              .ref(`${chaveUnica}/${key}/imagem.jpg`);
                            let mime = "image/jpeg";
                            /*   RNFetchBlob.fs
                                .readFile(uri, "base64")
                                .then(data => {
                                  return RNFetchBlob.polyfill.Blob.build(
                                    data,
                                    { type: mime + ";BASE64" }
                                  );
                                })
                                .then(blob => { */
                            caminhoPadraoImagem
                              .put(arquivoCompletoParaUpar)
                              .then((url) => {
                                url.ref.getDownloadURL().then(function (downloadURL) {
                                  firebase
                                    .database()
                                    .ref(
                                      `${chaveUnica}/clientes/${key}`
                                    )
                                    .update({
                                      empresa:
                                        vinculadoEmpresa == true
                                          ? nomeEmpresaSelecionadoConsultaClienteTelaCAdastraCliente
                                          : nomeEmpresa,
                                      nome: nomeCliente,
                                      telefoneCliente,
                                      enderecoCliente,
                                      empresaPesquisa: empresaPesquisa,
                                      senha: "",
                                      email: "",
                                      permissaoAPP: false,
                                      alterado: alterado,
                                      useruid: "",
                                      vinculadoEmpresa,
                                      keyEmpresaVinculada:
                                        keyEmpresaVinculada ==
                                          undefined
                                          ? "Não Vinculado"
                                          : keyEmpresaVinculada,
                                      urlImagemNuvem: downloadURL,
                                      isMyContact: isTelAndWpp
                                    })
                                    .then(() => {
                                      setTimeout(() => {


                                        desativaLoadingBotaoAlterarCliente(dispatch);
                                        modificaArquivoCompletoParaUparLocal(dispatch, '');
                                        removeFirebaseCachedData(`${chaveUnica}/clientes`, key);
                                        clearCacheRedis(chaveUnica,telefoneCliente);
                                        toastr.success('Sucesso',
                                          "Cliente alterado com sucesso!"
                                        );
                                      }, 700);
                                    })
                                    .catch(error => {
                                      desativaLoadingBotaoAlterarCliente(dispatch);
                                      //error.code
                                      //error.message
                                      switch (error.code) {
                                        case "auth/invalid-email":
                                          toastr.error('Error',
                                            "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                                          );
                                          break;
                                        case "auth/email-already-in-use":
                                          toastr.error('Error',
                                            "Este email já está sendo utilizado, informe outro email."
                                          );
                                          break;
                                        case "auth/network-request-failed":
                                          toastr.error('Error',
                                            "Sem conexão com a internet"
                                          );
                                          break;
                                        default:
                                          toastr.error('Error',
                                            "Erro ao Alterar, tente novamente mais tarde"
                                          );
                                          break;
                                      }
                                    });
                                })
                              })
                              .catch(error => {
                                desativaLoadingBotaoAlterarCliente(dispatch);
                                switch (error.code) {
                                  case "auth/invalid-email":
                                    toastr.error('Error',
                                      "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                                    );
                                    break;
                                  case "auth/email-already-in-use":
                                    toastr.error('Error',
                                      "Este email já está sendo utilizado, informe outro email."
                                    );
                                    break;
                                  case "auth/network-request-failed":
                                    toastr.error('Error',
                                      "Sem conexão com a internet"
                                    );
                                    break;
                                  default:
                                    toastr.error('Error',
                                      "Erro ao alterar, tente novamente mais tarde"
                                    );
                                    break;
                                }
                              });
                            /*  })
                             .catch(error => {
                               //error.code
                               //error.message
                               switch (error.code) {
                                 case "auth/invalid-email":
                                    toastr.error('Error',
                                     "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                                   );
                                   break;
                                 case "auth/email-already-in-use":
                                    toastr.error('Error',
                                     "Este email já está sendo utilizado, informe outro email."
                                   );
                                   break;
                                 case "auth/network-request-failed":
                                    toastr.error('Error',
                                     "Sem conexão com a internet"
                                   );
                                   break;
                                 default:
                                    toastr.error('Error',
                                     "Erro ao Alterar, tente novamente mais tarde"
                                   );
                                   break;
                               }
                             }); */
                          }
                          /* })
                          .catch(error => {
                            //error.code
                            //error.message
                            switch (error.code) {
                              case "auth/invalid-email":
                                 toastr.error('Error',
                                  "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                                );
                                break;
                              case "auth/email-already-in-use":
                                 toastr.error('Error',
                                  "Este email já está sendo utilizado, informe outro email."
                                );
                                break;
                              case "auth/network-request-failed":
                                 toastr.error('Error',
                                  "Sem conexão com a internet"
                                );
                                break;
                              case "storage/object-not-found":
                                 toastr.error('Error',
                                  "Nome inválido, utilize Letras!"
                                );
                                break;
                              default:
                                 toastr.error('Error',
                                  "Erro ao Alterar, tente novamente mais tarde"
                                );
                                break;
                            }
                          }); */
                        })
                          .catch(error => {
                            desativaLoadingBotaoAlterarCliente(dispatch);
                            if (error.response) {


                              switch (error.response.data.code) {
                                case 'auth/email-already-exists':
                                  toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                                  break;
                                case 'auth/invalid-email':
                                  toastr.error('Error', 'Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br');
                                  break;
                                case 'auth/email-already-in-use':
                                  toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                                  break;
                                case 'auth/network-request-failed':
                                  toastr.error('Error', 'Sem conexão com a internet');
                                  break;
                                default:
                                  toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                                  break;
                              }
                            } else {
                              toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                            }
                          })


                      }
                      //tem permissão no banco e não tem no app e tem email
                      //troco o email e senha do usuario firebase e deixo só o email no banco
                      else {
                        //
                        /* secondaryApp
                          .auth()
                          .signInWithEmailAndPassword(emailBanco, senhaBanco)
                          .then(userCredential => {
                            userCredential
                              .updateEmail(emailCliente)
                              .then(() => {
                                secondaryApp
                                  .auth()
                                  .signInWithEmailAndPassword(
                                    emailCliente,
                                    senhaBanco
                                  )
                                  .then(user2 => {
                                    user2
                                      .updatePassword("exemplo@exemplo2659")
                                      .then(() => {
                                        const uidUsuario = user2.uid; */
                        const options = {
                          method: 'POST',
                          headers: {
                            'Content-Type': 'application/json; charset=utf-8',
                            'Accept': 'application/json',
                          },

                          data: { email: emailCliente, senha: "exemplo@exemplo2659", rota: 'alteraremailesenha', uid: useruid },
                          url: 'crudUserReactNative',
                        };
                        api(options).then((response) => {


                          const uidUsuario = useruid;
                          /*  const primeiraLetra = nomeEmpresa
                             .substring(0, 1)
                             .trim()
                             .toLowerCase();
                           firebase
                             .storage()
                             .ref(`letras/${primeiraLetra}.png`)
                             .getDownloadURL()
                             .then(url => { */
                          //a foto que está no cliente é a mesma do banco, então não upo a foto
                          if (uriHTTP == 1) {
                            firebase
                              .database()
                              .ref(
                                `${chaveUnica}/clientes/${key}`
                              )
                              .update({
                                empresa:
                                  vinculadoEmpresa == true
                                    ? nomeEmpresaSelecionadoConsultaClienteTelaCAdastraCliente
                                    : nomeEmpresa,
                                nome: nomeCliente,
                                telefoneCliente,
                                enderecoCliente,
                                empresaPesquisa: empresaPesquisa,
                                email: emailCliente,
                                senha: "",
                                permissaoAPP: false,
                                alterado: alterado,
                                vinculadoEmpresa,
                                keyEmpresaVinculada:
                                  keyEmpresaVinculada == undefined
                                    ? "Não Vinculado"
                                    : keyEmpresaVinculada,
                                urlImagemNuvem: verificaSeUriImagemEhImagemUpadaOuNao == true ? uriImagem : "",
                                isMyContact: isTelAndWpp
                              })
                              .then(() => {

                                setTimeout(() => {

                                  desativaLoadingBotaoAlterarCliente(dispatch);
                                  modificaArquivoCompletoParaUparLocal(dispatch, '');
                                  removeFirebaseCachedData(`${chaveUnica}/clientes`, key);
                                  clearCacheRedis(chaveUnica,telefoneCliente);
                                  toastr.success('Sucesso',
                                    "Cliente alterado com sucesso!"
                                  );
                                }, 700);

                              })
                              .catch(error => {
                                desativaLoadingBotaoAlterarCliente(dispatch);
                                //error.code
                                //error.message
                                switch (error.code) {
                                  case "auth/invalid-email":
                                    toastr.error('Error',
                                      "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                                    );
                                    break;
                                  case "auth/email-already-in-use":
                                    toastr.error('Error',
                                      "Este email já está sendo utilizado, informe outro email."
                                    );
                                    break;
                                  case "auth/network-request-failed":
                                    toastr.error('Error',
                                      "Sem conexão com a internet"
                                    );
                                    break;
                                  default:
                                    toastr.error('Error',
                                      "Erro ao Alterar, tente novamente mais tarde"
                                    );
                                    break;
                                }
                              });
                          }
                          //foto diferente, vou upar no banco
                          else {
                            //  let uri = uriImagem.replace( "file://","");
                            let caminhoPadraoImagem = firebase
                              .storage()
                              .ref(
                                `${chaveUnica}/${key}/imagem.jpg`
                              );
                            let mime = "image/jpeg";
                            /*    RNFetchBlob.fs
                                 .readFile(uri, "base64")
                                 .then(data => {
                                   return RNFetchBlob.polyfill.Blob.build(
                                     data,
                                     { type: mime + ";BASE64" }
                                   );
                                 })
                                 .then(blob => { */
                            caminhoPadraoImagem
                              .put(arquivoCompletoParaUpar)
                              .then((url) => {
                                url.ref.getDownloadURL().then(function (downloadURL) {
                                  firebase
                                    .database()
                                    .ref(
                                      `${chaveUnica}/clientes/${key}`
                                    )
                                    .update({
                                      empresa:
                                        vinculadoEmpresa == true
                                          ? nomeEmpresaSelecionadoConsultaClienteTelaCAdastraCliente
                                          : nomeEmpresa,
                                      nome: nomeCliente,
                                      telefoneCliente,
                                      enderecoCliente,
                                      email: emailCliente,
                                      empresaPesquisa: empresaPesquisa,
                                      senha: "",
                                      permissaoAPP: false,
                                      alterado: alterado,
                                      vinculadoEmpresa,
                                      keyEmpresaVinculada:
                                        keyEmpresaVinculada ==
                                          undefined
                                          ? "Não Vinculado"
                                          : keyEmpresaVinculada,
                                      urlImagemNuvem: downloadURL,
                                      isMyContact: isTelAndWpp
                                    })
                                    .then(() => {

                                      setTimeout(() => {
                                        modificaArquivoCompletoParaUparLocal(dispatch, '');
                                        removeFirebaseCachedData(`${chaveUnica}/clientes`, key);
                                        clearCacheRedis(chaveUnica,telefoneCliente);
                                        desativaLoadingBotaoAlterarCliente(dispatch);
                                        toastr.success('Sucesso',
                                          "Cliente alterado com sucesso!"
                                        );
                                      }, 700);

                                    }).catch(error => {
                                      desativaLoadingBotaoAlterarCliente(dispatch);
                                      //error.code
                                      //error.message
                                      switch (error.code) {
                                        case "auth/invalid-email":
                                          toastr.error('Error',
                                            "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                                          );
                                          break;
                                        case "auth/email-already-in-use":
                                          toastr.error('Error',
                                            "Este email já está sendo utilizado, informe outro email."
                                          );
                                          break;
                                        case "auth/network-request-failed":
                                          toastr.error('Error',
                                            "Sem conexão com a internet"
                                          );
                                          break;
                                        default:
                                          toastr.error('Error',
                                            "Erro ao Alterar, tente novamente mais tarde"
                                          );
                                          break;
                                      }
                                    });
                                })
                              })
                              .catch(error => {
                                desativaLoadingBotaoAlterarCliente(dispatch);
                                switch (error.code) {
                                  case "auth/invalid-email":
                                    toastr.error('Error',
                                      "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                                    );
                                    break;
                                  case "auth/email-already-in-use":
                                    toastr.error('Error',
                                      "Este email já está sendo utilizado, informe outro email."
                                    );
                                    break;
                                  case "auth/network-request-failed":
                                    toastr.error('Error',
                                      "Sem conexão com a internet"
                                    );
                                    break;
                                  default:
                                    toastr.error('Error',
                                      "Erro ao Alterar, tente novamente mais tarde"
                                    );
                                    break;
                                }
                              });
                            /*   })
                              .catch(error => {
                                //error.code
                                //error.message
                                switch (error.code) {
                                  case "auth/invalid-email":
                                    toastr.error('Error',
                                      "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                                    );
                                    break;
                                  case "auth/email-already-in-use":
                                    toastr.error('Error',
                                      "Este email já está sendo utilizado, informe outro email."
                                    );
                                    break;
                                  case "auth/network-request-failed":
                                    toastr.error('Error',
                                      "Sem conexão com a internet"
                                    );
                                    break;
                                  default:
                                    toastr.error('Error',
                                      "Erro ao Alterar, tente novamente mais tarde"
                                    );
                                    break;
                                }
                              }); */
                          }
                          /* })
                          .catch(error => {
                            //error.code
                            //error.message
                            switch (error.code) {
                              case "auth/invalid-email":
                                 toastr.error('Error',
                                  "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                                );
                                break;
                              case "auth/email-already-in-use":
                                 toastr.error('Error',
                                  "Este email já está sendo utilizado, informe outro email."
                                );
                                break;
                              case "auth/network-request-failed":
                                 toastr.error('Error',
                                  "Sem conexão com a internet"
                                );
                                break;
                              case "storage/object-not-found":
                                 toastr.error('Error',
                                  "Nome inválido, utilize Letras!"
                                );
                                break;
                              default:
                                 toastr.error('Error',
                                  "Erro ao Alterar, tente novamente mais tarde"
                                );
                                break;
                            }
                          }); */


                          /* */
                        })
                          .catch(error => {
                            desativaLoadingBotaoAlterarCliente(dispatch);
                            if (error.response) {


                              switch (error.response.data.code) {
                                case 'auth/email-already-exists':
                                  toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                                  break;
                                case 'auth/invalid-email':
                                  toastr.error('Error', 'Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br');
                                  break;
                                case 'auth/email-already-in-use':
                                  toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                                  break;
                                case 'auth/network-request-failed':
                                  toastr.error('Error', 'Sem conexão com a internet');
                                  break;
                                default:
                                  toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                                  break;
                              }
                            } else {
                              toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                            }
                          });
                      }
                    }
                    //não tenho permissao no banco e não tenho no app
                    else {
                      //
                      //se não tiver email no banco eu altero só o nome e a imagem
                      if (emailBanco == "" && emailCliente == "") {
                        //
                        /*  const primeiraLetra = nomeEmpresa
                           .substring(0, 1)
                           .trim()
                           .toLowerCase();
                         firebase
                           .storage()
                           .ref(`letras/${primeiraLetra}.png`)
                           .getDownloadURL()
                           .then(url => { */
                        //a foto que está no cliente é a mesma do banco, então não upo a foto
                        if (uriHTTP == 1) {
                          firebase
                            .database()
                            .ref(`${chaveUnica}/clientes/${key}`)
                            .update({
                              empresa:
                                vinculadoEmpresa == true
                                  ? nomeEmpresaSelecionadoConsultaClienteTelaCAdastraCliente
                                  : nomeEmpresa,
                              nome: nomeCliente,
                              telefoneCliente,
                              enderecoCliente,
                              empresaPesquisa: empresaPesquisa,
                              senha: "",
                              email: emailCliente,
                              permissaoAPP: false,
                              alterado: alterado,
                              vinculadoEmpresa,
                              keyEmpresaVinculada:
                                keyEmpresaVinculada == undefined
                                  ? "Não Vinculado"
                                  : keyEmpresaVinculada,
                              urlImagemNuvem: verificaSeUriImagemEhImagemUpadaOuNao == true ? uriImagem : "",
                              isMyContact: isTelAndWpp
                            })
                            .then(() => {
                              setTimeout(() => {
                                modificaArquivoCompletoParaUparLocal(dispatch, '');
                                desativaLoadingBotaoAlterarCliente(dispatch);
                                removeFirebaseCachedData(`${chaveUnica}/clientes`, key);
                                clearCacheRedis(chaveUnica,telefoneCliente);
                                toastr.success('Sucesso',
                                  "Cliente alterado com sucesso!"
                                );
                              }, 700);
                            })
                            .catch(error => {
                              desativaLoadingBotaoAlterarCliente(dispatch);
                              //error.code
                              //error.message
                              switch (error.code) {
                                case "auth/invalid-email":
                                  toastr.error('Error',
                                    "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                                  );
                                  break;
                                case "auth/email-already-in-use":
                                  toastr.error('Error',
                                    "Este email já está sendo utilizado, informe outro email."
                                  );
                                  break;
                                case "auth/network-request-failed":
                                  toastr.error('Error',
                                    "Sem conexão com a internet"
                                  );
                                  break;
                                default:
                                  toastr.error('Error',
                                    "Erro ao Alterar, tente novamente mais tarde"
                                  );
                                  break;
                              }
                            });
                        }
                        //foto diferente, vou upar no banco
                        else {
                          // let uri = uriImagem.replace("file://", "");
                          let caminhoPadraoImagem = firebase
                            .storage()
                            .ref(`${chaveUnica}/${key}/imagem.jpg`);
                          let mime = "image/jpeg";
                          /*   RNFetchBlob.fs
                              .readFile(uri, "base64")
                              .then(data => {
                                return RNFetchBlob.polyfill.Blob.build(data, {
                                  type: mime + ";BASE64"
                                });
                              })
                              .then(blob => { */
                          caminhoPadraoImagem
                            .put(arquivoCompletoParaUpar)
                            .then((url) => {
                              url.ref.getDownloadURL().then(function (downloadURL) {
                                //
                                firebase
                                  .database()
                                  .ref(`${chaveUnica}/clientes/${key}`)
                                  .update({
                                    urlImagemNuvem: downloadURL,
                                    empresa:
                                      vinculadoEmpresa == true
                                        ? nomeEmpresaSelecionadoConsultaClienteTelaCAdastraCliente
                                        : nomeEmpresa,
                                    nome: nomeCliente,
                                    telefoneCliente,
                                    enderecoCliente,
                                    empresaPesquisa: empresaPesquisa,
                                    senha: "",
                                    email: emailCliente,
                                    permissaoAPP: false,
                                    alterado: alterado,
                                    vinculadoEmpresa,
                                    keyEmpresaVinculada:
                                      keyEmpresaVinculada == undefined
                                        ? "Não Vinculado"
                                        : keyEmpresaVinculada,
                                    isMyContact: isTelAndWpp

                                  })
                                  .then(() => {
                                    setTimeout(() => {

                                      modificaArquivoCompletoParaUparLocal(dispatch, '');
                                      desativaLoadingBotaoAlterarCliente(dispatch);
                                      removeFirebaseCachedData(`${chaveUnica}/clientes`, key);
                                      clearCacheRedis(chaveUnica,telefoneCliente);
                                      toastr.success('Sucesso',
                                        "Cliente alterado com sucesso!"
                                      );
                                    }, 700);
                                  })
                                  .catch(error => {
                                    desativaLoadingBotaoAlterarCliente(dispatch);
                                    //error.code
                                    //error.message
                                    switch (error.code) {
                                      case "auth/invalid-email":
                                        toastr.error('Error',
                                          "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                                        );
                                        break;
                                      case "auth/email-already-in-use":
                                        toastr.error('Error',
                                          "Este email já está sendo utilizado, informe outro email."
                                        );
                                        break;
                                      case "auth/network-request-failed":
                                        toastr.error('Error',
                                          "Sem conexão com a internet"
                                        );
                                        break;
                                      default:
                                        toastr.error('Error',
                                          "Erro ao Alterar, tente novamente mais tarde"
                                        );
                                        break;
                                    }
                                  });
                              })
                            })
                            .catch(error => {

                              desativaLoadingBotaoAlterarCliente(dispatch);
                              switch (error.code) {
                                case "auth/invalid-email":
                                  toastr.error('Error',
                                    "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                                  );
                                  break;
                                case "auth/email-already-in-use":
                                  toastr.error('Error',
                                    "Este email já está sendo utilizado, informe outro email."
                                  );
                                  break;
                                case "auth/network-request-failed":
                                  toastr.error('Error',
                                    "Sem conexão com a internet"
                                  );
                                  break;
                                default:
                                  toastr.error('Error',
                                    "Erro ao Alterar, tente novamente mais tarde"
                                  );
                                  break;
                              }
                            });
                          /*  })
                           .catch(error => {
                             //error.code
                             //error.message
                             switch (error.code) {
                               case "auth/invalid-email":
                                  toastr.error('Error',
                                   "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                                 );
                                 break;
                               case "auth/email-already-in-use":
                                  toastr.error('Error',
                                   "Este email já está sendo utilizado, informe outro email."
                                 );
                                 break;
                               case "auth/network-request-failed":
                                  toastr.error('Error',
                                   "Sem conexão com a internet"
                                 );
                                 break;
                               default:
                                  toastr.error('Error',
                                   "Erro ao Alterar, tente novamente mais tarde"
                                 );
                                 break;
                             }
                           }); */
                        }
                        /*  })
                         .catch(error => {
                           //error.code
                           //error.message
                           switch (error.code) {
                             case "auth/invalid-email":
                               mensagemCasdastraClienteErrorTelaAlterar(
                                 dispatch,
                                 "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                               );
                               break;
                             case "auth/email-already-in-use":
                               mensagemCasdastraClienteErrorTelaAlterar(
                                 dispatch,
                                 "Este email já está sendo utilizado, informe outro email."
                               );
                               break;
                             case "auth/network-request-failed":
                               mensagemCasdastraClienteErrorTelaAlterar(
                                 dispatch,
                                 "Sem conexão com a internet"
                               );
                               break;
                             case "storage/object-not-found":
                               mensagemCasdastraClienteErrorTelaAlterar(
                                 dispatch,
                                 "Nome inválido, utilize Letras!"
                               );
                               break;
                             default:
                               mensagemCasdastraClienteErrorTelaAlterar(
                                 dispatch,
                                 "Erro ao Alterar, tente novamente mais tarde"
                               );
                               break;
                           }
                         }); */
                      }
                      //tem email no app, então crio com senha padrão
                      else if (emailBanco == "" && emailCliente != "") {
                        //
                        /* secondaryApp
                          .auth()
                          .createUserWithEmailAndPassword(
                            emailCliente,
                            "exemplo@exemplo2659"
                          )
                          .then(user => {
                            const useruid = user.uid; */
                        const options = {
                          method: 'POST',
                          headers: {
                            'Content-Type': 'application/json; charset=utf-8',
                            'Accept': 'application/json',
                          },

                          data: { email: emailCliente, senha: "exemplo@exemplo2659", rota: 'create', chaveUnica },
                          url: 'crudUserReactNative',
                        };
                        api(options).then((response) => {


                          let useruid = response.data.uid;
                          /*  const primeiraLetra = nomeEmpresa
                             .substring(0, 1)
                             .trim()
                             .toLowerCase();
                           //pesquiso para pegar a url da foto da letra pra fazer comparacao
                           firebase
                             .storage()
                             .ref(`letras/${primeiraLetra}.png`)
                             .getDownloadURL()
                             .then(url => { */
                          //a foto que está no cliente é a mesma do banco, então não upo a foto
                          if (uriHTTP == 1) {
                            firebase
                              .database()
                              .ref(`${chaveUnica}/clientes/${key}`)
                              .update({
                                empresa:
                                  vinculadoEmpresa == true
                                    ? nomeEmpresaSelecionadoConsultaClienteTelaCAdastraCliente
                                    : nomeEmpresa,
                                nome: nomeCliente,
                                email: emailCliente,
                                telefoneCliente,
                                enderecoCliente,
                                empresaPesquisa: empresaPesquisa,
                                senha: "",
                                permissaoAPP: false,
                                useruid: useruid,
                                alterado: alterado,
                                vinculadoEmpresa,
                                keyEmpresaVinculada:
                                  keyEmpresaVinculada == undefined
                                    ? "Não Vinculado"
                                    : keyEmpresaVinculada,
                                urlImagemNuvem: verificaSeUriImagemEhImagemUpadaOuNao == true ? uriImagem : "",
                                isMyContact: isTelAndWpp
                              })
                              .then(
                                //cadastrando o nome do usuario na tabela principal (email base 64)
                                () => {
                                  setTimeout(() => {
                                    modificaArquivoCompletoParaUparLocal(dispatch, '');
                                    desativaLoadingBotaoAlterarCliente(dispatch);
                                    removeFirebaseCachedData(`${chaveUnica}/clientes`, key);
                                    clearCacheRedis(chaveUnica,telefoneCliente);
                                    toastr.success('Sucesso',
                                      "Cliente alterado com sucesso!"
                                    );
                                  }, 700);
                                }
                              )
                              .catch(error => {

                                desativaLoadingBotaoAlterarCliente(dispatch);
                                //error.code
                                //error.message
                                switch (error.code) {
                                  case "auth/invalid-email":
                                    toastr.error('Error',
                                      "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                                    );
                                    break;
                                  case "auth/email-already-in-use":
                                    toastr.error('Error',
                                      "Este email já está sendo utilizado, informe outro email."
                                    );
                                    break;
                                  case "auth/network-request-failed":
                                    toastr.error('Error',
                                      "Sem conexão com a internet"
                                    );
                                    break;
                                  default:
                                    toastr.error('Error',
                                      "Erro ao Alterar, tente novamente mais tarde"
                                    );
                                    break;
                                }
                              });
                          }
                          //foto diferente, vou upar no banco
                          else {
                            let caminhoPadraoImagem = firebase
                              .storage()
                              .ref(`${chaveUnica}/${key}/imagem.jpg`);
                            let mime = "image/jpeg";
                            let uri = uriImagem.replace("file://", "");

                            /*  RNFetchBlob.fs
                               .readFile(uri, "base64")
                               .then(data => {
                                 return RNFetchBlob.polyfill.Blob.build(data, {
                                   type: mime + ";BASE64"
                                 });
                               })
                               .then(blob => { */
                            caminhoPadraoImagem
                              .put(arquivoCompletoParaUpar)
                              .then((url) => {
                                url.ref.getDownloadURL().then(function (downloadURL) {
                                  firebase
                                    .database()
                                    .ref(`${chaveUnica}/clientes/${key}`)
                                    .update({
                                      empresa:
                                        vinculadoEmpresa == true
                                          ? nomeEmpresaSelecionadoConsultaClienteTelaCAdastraCliente
                                          : nomeEmpresa,
                                      nome: nomeCliente,
                                      email: emailCliente,
                                      telefoneCliente,
                                      enderecoCliente,
                                      empresaPesquisa: empresaPesquisa,
                                      senha: "",
                                      permissaoAPP: false,
                                      useruid: useruid,
                                      alterado: alterado,
                                      vinculadoEmpresa,
                                      keyEmpresaVinculada:
                                        keyEmpresaVinculada == undefined
                                          ? "Não Vinculado"
                                          : keyEmpresaVinculada,
                                      urlImagemNuvem: downloadURL,
                                      isMyContact: isTelAndWpp
                                    })
                                    .then(
                                      //cadastrando o nome do usuario na tabela principal (email base 64)
                                      () => {
                                        setTimeout(() => {

                                          modificaArquivoCompletoParaUparLocal(dispatch, '');
                                          desativaLoadingBotaoAlterarCliente(dispatch);
                                          removeFirebaseCachedData(`${chaveUnica}/clientes`, key);
                                          clearCacheRedis(chaveUnica,telefoneCliente);
                                          toastr.success('Sucesso',
                                            "Cliente alterado com sucesso!"
                                          );
                                        }, 700);
                                      }
                                    )
                                    .catch(error => {

                                      desativaLoadingBotaoAlterarCliente(dispatch);
                                      //error.code
                                      //error.message
                                      switch (error.code) {
                                        case "auth/invalid-email":
                                          toastr.error('Error',
                                            "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                                          );
                                          break;
                                        case "auth/email-already-in-use":
                                          toastr.error('Error',
                                            "Este email já está sendo utilizado, informe outro email."
                                          );
                                          break;
                                        case "auth/network-request-failed":
                                          toastr.error('Error',
                                            "Sem conexão com a internet"
                                          );
                                          break;
                                        default:
                                          toastr.error('Error',
                                            "Erro ao Alterar, tente novamente mais tarde"
                                          );
                                          break;
                                      }
                                    });
                                })
                              })
                              .catch(error => {

                                desativaLoadingBotaoAlterarCliente(dispatch);
                                //error.code
                                //error.message
                                switch (error.code) {
                                  case "auth/invalid-email":
                                    toastr.error('Error',
                                      "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                                    );
                                    break;
                                  case "auth/email-already-in-use":
                                    toastr.error('Error',
                                      "Este email já está sendo utilizado, informe outro email."
                                    );
                                    break;
                                  case "auth/network-request-failed":
                                    toastr.error('Error',
                                      "Sem conexão com a internet"
                                    );
                                    break;
                                  default:
                                    toastr.error('Error',
                                      "Erro ao Alterar, tente novamente mais tarde"
                                    );
                                    break;
                                }
                              });
                            /*   })
                              .catch(error => {
                                //error.code
                                //error.message
                                switch (error.code) {
                                  case "auth/invalid-email":
                                     toastr.error('Error',
                                      "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                                    );
                                    break;
                                  case "auth/email-already-in-use":
                                     toastr.error('Error',
                                      "Este email já está sendo utilizado, informe outro email."
                                    );
                                    break;
                                  case "auth/network-request-failed":
                                     toastr.error('Error',
                                      "Sem conexão com a internet"
                                    );
                                    break;
                                  default:
                                     toastr.error('Error',
                                      "Erro ao Alterar, tente novamente mais tarde"
                                    );
                                    break;
                                }
                              }); */
                          }
                          /*  })
                           .catch(error => {
                             //error.code
                             //error.message
                             switch (error.code) {
                               case "auth/invalid-email":
                                 mensagemCasdastraClienteErrorTelaAlterar(
                                   dispatch,
                                   "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                                 );
                                 break;
                               case "auth/email-already-in-use":
                                 mensagemCasdastraClienteErrorTelaAlterar(
                                   dispatch,
                                   "Este email já está sendo utilizado, informe outro email."
                                 );
                                 break;
                               case "auth/network-request-failed":
                                 mensagemCasdastraClienteErrorTelaAlterar(
                                   dispatch,
                                   "Sem conexão com a internet"
                                 );
                                 break;
                               case "storage/object-not-found":
                                 mensagemCasdastraClienteErrorTelaAlterar(
                                   dispatch,
                                   "Nome inválido, utilize Letras!"
                                 );
                                 break;
                               default:
                                 mensagemCasdastraClienteErrorTelaAlterar(
                                   dispatch,
                                   "Erro ao Alterar, tente novamente mais tarde"
                                 );
                                 break;
                             }
                           }); */
                        })
                          .catch(error => {

                            desativaLoadingBotaoAlterarCliente(dispatch);
                            //error.code
                            //error.message
                            if (error.response) {


                              switch (error.response.data.code) {
                                case 'auth/email-already-exists':
                                  toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                                  break;
                                case 'auth/invalid-email':
                                  toastr.error('Error', 'Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br');
                                  break;
                                case 'auth/email-already-in-use':
                                  toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                                  break;
                                case 'auth/network-request-failed':
                                  toastr.error('Error', 'Sem conexão com a internet');
                                  break;
                                default:
                                  toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                                  break;
                              }
                            } else {
                              toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                            }
                          });
                      }
                      //tem email no banco mas não tem email no cliente, só troco a imagem, nome cliente e troco o email
                      // do banco firebase eo email da conta firebase
                      else if (emailBanco != "" && emailCliente == "") {
                        //
                        /*  secondaryApp
                           .auth()
                           .signInWithEmailAndPassword(
                             emailBanco,
                             "exemplo@exemplo2659"
                           )
                           .then(userCredential => { */
                        const uidUsuario = useruid;

                        const options = {
                          method: 'POST',
                          headers: {
                            'Content-Type': 'application/json; charset=utf-8',
                            'Accept': 'application/json',
                          },

                          data: { email: emailBanco, senha: "exemplo@exemplo2659", rota: 'excluir', uid: useruid },
                          url: 'crudUserReactNative',
                        };
                        api(options).then(() => {

                          /*   const primeiraLetra = nomeEmpresa
                              .substring(0, 1)
                              .trim()
                              .toLowerCase();
                            firebase
                              .storage()
                              .ref(`letras/${primeiraLetra}.png`)
                              .getDownloadURL()
                              .then(url => { */
                          //a foto que está no cliente é a mesma do banco, então não upo a foto
                          if (uriHTTP == 1) {
                            firebase
                              .database()
                              .ref(`${chaveUnica}/clientes/${key}`)
                              .update({
                                empresa:
                                  vinculadoEmpresa == true
                                    ? nomeEmpresaSelecionadoConsultaClienteTelaCAdastraCliente
                                    : nomeEmpresa,
                                nome: nomeCliente,
                                telefoneCliente,
                                enderecoCliente,
                                empresaPesquisa: empresaPesquisa,
                                senha: "",
                                email: "",
                                permissaoAPP: false,
                                alterado: alterado,
                                useruid: "",
                                vinculadoEmpresa,
                                keyEmpresaVinculada:
                                  keyEmpresaVinculada == undefined
                                    ? "Não Vinculado"
                                    : keyEmpresaVinculada,
                                urlImagemNuvem: verificaSeUriImagemEhImagemUpadaOuNao == true ? uriImagem : "",
                                isMyContact: isTelAndWpp
                              })
                              .then(() => {
                                setTimeout(() => {
                                  modificaArquivoCompletoParaUparLocal(dispatch, '');
                                  desativaLoadingBotaoAlterarCliente(dispatch);
                                  removeFirebaseCachedData(`${chaveUnica}/clientes`, key);
                                  clearCacheRedis(chaveUnica,telefoneCliente);
                                  toastr.success('Sucesso',
                                    "Cliente alterado com sucesso!"
                                  );
                                }, 700);
                              })
                              .catch(error => {

                                desativaLoadingBotaoAlterarCliente(dispatch);
                                //error.code
                                //error.message
                                switch (error.code) {
                                  case "auth/invalid-email":
                                    toastr.error('Error',
                                      "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                                    );
                                    break;
                                  case "auth/email-already-in-use":
                                    toastr.error('Error',
                                      "Este email já está sendo utilizado, informe outro email."
                                    );
                                    break;
                                  case "auth/network-request-failed":
                                    toastr.error('Error',
                                      "Sem conexão com a internet"
                                    );
                                    break;
                                  default:
                                    toastr.error('Error',
                                      "Erro ao Alterar, tente novamente mais tarde"
                                    );
                                    break;
                                }
                              });
                          }
                          //foto diferente, vou upar no banco
                          else {
                            //   let uri = uriImagem.replace( "file://","");
                            let caminhoPadraoImagem = firebase
                              .storage()
                              .ref(`${chaveUnica}/${key}/imagem.jpg`);
                            let mime = "image/jpeg";
                            /*   RNFetchBlob.fs
                                .readFile(uri, "base64")
                                .then(data => {
                                  return RNFetchBlob.polyfill.Blob.build(
                                    data,
                                    { type: mime + ";BASE64" }
                                  );
                                })
                                .then(blob => { */
                            caminhoPadraoImagem
                              .put(arquivoCompletoParaUpar)
                              .then((url) => {
                                url.ref.getDownloadURL().then(function (downloadURL) {
                                  firebase
                                    .database()
                                    .ref(
                                      `${chaveUnica}/clientes/${key}`
                                    )
                                    .update({
                                      empresa:
                                        vinculadoEmpresa == true
                                          ? nomeEmpresaSelecionadoConsultaClienteTelaCAdastraCliente
                                          : nomeEmpresa,
                                      nome: nomeCliente,
                                      telefoneCliente,
                                      enderecoCliente,
                                      empresaPesquisa: empresaPesquisa,
                                      senha: "",
                                      email: "",
                                      permissaoAPP: false,
                                      alterado: alterado,
                                      useruid: "",
                                      vinculadoEmpresa,
                                      keyEmpresaVinculada:
                                        keyEmpresaVinculada ==
                                          undefined
                                          ? "Não Vinculado"
                                          : keyEmpresaVinculada,
                                      urlImagemNuvem: downloadURL,
                                      isMyContact: isTelAndWpp
                                    })
                                    .then(() => {
                                      setTimeout(() => {
                                        modificaArquivoCompletoParaUparLocal(dispatch, '');
                                        desativaLoadingBotaoAlterarCliente(dispatch);
                                        removeFirebaseCachedData(`${chaveUnica}/clientes`, key);
                                        clearCacheRedis(chaveUnica,telefoneCliente);

                                        //blob.close();
                                        toastr.success('Sucesso',
                                          "Cliente alterado com sucesso!"
                                        );
                                      }, 700);
                                    })
                                    .catch(error => {

                                      desativaLoadingBotaoAlterarCliente(dispatch);
                                      //error.code
                                      //error.message
                                      switch (error.code) {
                                        case "auth/invalid-email":
                                          toastr.error('Error',
                                            "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                                          );
                                          break;
                                        case "auth/email-already-in-use":
                                          toastr.error('Error',
                                            "Este email já está sendo utilizado, informe outro email."
                                          );
                                          break;
                                        case "auth/network-request-failed":
                                          toastr.error('Error',
                                            "Sem conexão com a internet"
                                          );
                                          break;
                                        default:
                                          toastr.error('Error',
                                            "Erro ao Alterar, tente novamente mais tarde"
                                          );
                                          break;
                                      }
                                    });
                                })
                              })
                              .catch(error => {
                                desativaLoadingBotaoAlterarCliente(dispatch);
                                switch (error.code) {
                                  case "auth/invalid-email":
                                    toastr.error('Error',
                                      "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                                    );
                                    break;
                                  case "auth/email-already-in-use":
                                    toastr.error('Error',
                                      "Este email já está sendo utilizado, informe outro email."
                                    );
                                    break;
                                  case "auth/network-request-failed":
                                    toastr.error('Error',
                                      "Sem conexão com a internet"
                                    );
                                    break;
                                  default:
                                    toastr.error('Error',
                                      "Erro ao Alterar, tente novamente mais tarde"
                                    );
                                    break;
                                }
                              });
                            /*  })
                             .catch(error => {
                               //error.code
                               //error.message
                               switch (error.code) {
                                 case "auth/invalid-email":
                                    toastr.error('Error',
                                     "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                                   );
                                   break;
                                 case "auth/email-already-in-use":
                                    toastr.error('Error',
                                     "Este email já está sendo utilizado, informe outro email."
                                   );
                                   break;
                                 case "auth/network-request-failed":
                                    toastr.error('Error',
                                     "Sem conexão com a internet"
                                   );
                                   break;
                                 default:
                                    toastr.error('Error',
                                     "Erro ao Alterar, tente novamente mais tarde"
                                   );
                                   break;
                               }
                             }); */
                          }
                          /*  })
                           .catch(error => {
                             //error.code
                             //error.message
                             switch (error.code) {
                               case "auth/invalid-email":
                                 mensagemCasdastraClienteErrorTelaAlterar(
                                   dispatch,
                                   "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                                 );
                                 break;
                               case "auth/email-already-in-use":
                                 mensagemCasdastraClienteErrorTelaAlterar(
                                   dispatch,
                                   "Este email já está sendo utilizado, informe outro email."
                                 );
                                 break;
                               case "auth/network-request-failed":
                                 mensagemCasdastraClienteErrorTelaAlterar(
                                   dispatch,
                                   "Sem conexão com a internet"
                                 );
                                 break;
                               case "storage/object-not-found":
                                 mensagemCasdastraClienteErrorTelaAlterar(
                                   dispatch,
                                   "Nome inválido, utilize Letras!"
                                 );
                                 break;
                               default:
                                 mensagemCasdastraClienteErrorTelaAlterar(
                                   dispatch,
                                   "Erro ao Alterar, tente novamente mais tarde"
                                 );
                                 break;
                             }
                           }); */
                        })
                          .catch(error => {
                            desativaLoadingBotaoAlterarCliente(dispatch);
                            if (error.response) {


                              switch (error.response.data.code) {
                                case 'auth/email-already-exists':
                                  toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                                  break;
                                case 'auth/invalid-email':
                                  toastr.error('Error', 'Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br');
                                  break;
                                case 'auth/email-already-in-use':
                                  toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                                  break;
                                case 'auth/network-request-failed':
                                  toastr.error('Error', 'Sem conexão com a internet');
                                  break;
                                default:
                                  toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                                  break;
                              }
                            } else {
                              toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                            }
                          })

                      }
                      //tem email no banco e tem email no app, porém são os mesmos
                      //então altero somente imagem, nome e permissão e a senha do usuario do firebase
                      //a senha no firebase coloco como vazio
                      else if (emailBanco != "" && emailCliente != "" && emailBanco == emailCliente) {
                        //
                        /* const primeiraLetra = nomeEmpresa
                          .substring(0, 1)
                          .trim()
                          .toLowerCase();
                        firebase
                          .storage()
                          .ref(`letras/${primeiraLetra}.png`)
                          .getDownloadURL()
                          .then(url => { */
                        //a foto que está no cliente é a mesma do banco, então não upo a foto
                        if (uriHTTP == 1) {
                          firebase
                            .database()
                            .ref(`${chaveUnica}/clientes/${key}`)
                            .update({
                              empresa:
                                vinculadoEmpresa == true
                                  ? nomeEmpresaSelecionadoConsultaClienteTelaCAdastraCliente
                                  : nomeEmpresa,
                              nome: nomeCliente,
                              telefoneCliente,
                              enderecoCliente,
                              empresaPesquisa: empresaPesquisa,
                              senha: "",
                              permissaoAPP: false,
                              alterado: alterado,
                              vinculadoEmpresa,
                              keyEmpresaVinculada:
                                keyEmpresaVinculada == undefined
                                  ? "Não Vinculado"
                                  : keyEmpresaVinculada,
                              urlImagemNuvem: verificaSeUriImagemEhImagemUpadaOuNao == true ? uriImagem : "",
                              isMyContact: isTelAndWpp
                            })
                            .then(() => {
                              setTimeout(() => {
                                modificaArquivoCompletoParaUparLocal(dispatch, '');
                                desativaLoadingBotaoAlterarCliente(dispatch);
                                removeFirebaseCachedData(`${chaveUnica}/clientes`, key);
                                clearCacheRedis(chaveUnica,telefoneCliente);
                                toastr.success('Sucesso',
                                  "Cliente alterado com sucesso!"
                                );
                              }, 700);
                            })
                            .catch(error => {
                              desativaLoadingBotaoAlterarCliente(dispatch);
                              //error.code
                              //error.message
                              switch (error.code) {
                                case "auth/invalid-email":
                                  toastr.error('Error',
                                    "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                                  );
                                  break;
                                case "auth/email-already-in-use":
                                  toastr.error('Error',
                                    "Este email já está sendo utilizado, informe outro email."
                                  );
                                  break;
                                case "auth/network-request-failed":
                                  toastr.error('Error',
                                    "Sem conexão com a internet"
                                  );
                                  break;
                                default:
                                  toastr.error('Error',
                                    "Erro ao Alterar, tente novamente mais tarde"
                                  );
                                  break;
                              }
                            });
                        }
                        //foto diferente, vou upar no banco
                        else {
                          //  let uri = uriImagem.replace("file://", "");
                          let caminhoPadraoImagem = firebase
                            .storage()
                            .ref(`${chaveUnica}/${key}/imagem.jpg`);
                          let mime = "image/jpeg";
                          /*   RNFetchBlob.fs
                              .readFile(uri, "base64")
                              .then(data => {
                                return RNFetchBlob.polyfill.Blob.build(data, {
                                  type: mime + ";BASE64"
                                });
                              })
                              .then(blob => { */
                          caminhoPadraoImagem
                            .put(arquivoCompletoParaUpar)
                            .then((url) => {
                              url.ref.getDownloadURL().then(function (downloadURL) {
                                firebase
                                  .database()
                                  .ref(`${chaveUnica}/clientes/${key}`)
                                  .update({
                                    empresa:
                                      vinculadoEmpresa == true
                                        ? nomeEmpresaSelecionadoConsultaClienteTelaCAdastraCliente
                                        : nomeEmpresa,
                                    nome: nomeCliente,
                                    telefoneCliente,
                                    enderecoCliente,
                                    empresaPesquisa: empresaPesquisa,
                                    senha: "",
                                    permissaoAPP: false,
                                    alterado: alterado,
                                    vinculadoEmpresa,
                                    keyEmpresaVinculada:
                                      keyEmpresaVinculada == undefined
                                        ? "Não Vinculado"
                                        : keyEmpresaVinculada,
                                    urlImagemNuvem: downloadURL,
                                    isMyContact: isTelAndWpp
                                  })
                                  .then(() => {
                                    setTimeout(() => {
                                      modificaArquivoCompletoParaUparLocal(dispatch, '');
                                      desativaLoadingBotaoAlterarCliente(dispatch);
                                      removeFirebaseCachedData(`${chaveUnica}/clientes`, key);
                                      clearCacheRedis(chaveUnica,telefoneCliente);
                                      //  blob.close();
                                      toastr.success('Sucesso',
                                        "Cliente alterado com sucesso!"
                                      );
                                    }, 700);
                                  })
                                  .catch(error => {
                                    desativaLoadingBotaoAlterarCliente(dispatch);
                                    //error.code
                                    //error.message
                                    switch (error.code) {
                                      case "auth/invalid-email":
                                        toastr.error('Error',
                                          "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                                        );
                                        break;
                                      case "auth/email-already-in-use":
                                        toastr.error('Error',
                                          "Este email já está sendo utilizado, informe outro email."
                                        );
                                        break;
                                      case "auth/network-request-failed":
                                        toastr.error('Error',
                                          "Sem conexão com a internet"
                                        );
                                        break;
                                      default:
                                        toastr.error('Error',
                                          "Erro ao Alterar, tente novamente mais tarde"
                                        );
                                        break;
                                    }
                                  });
                              })
                            })
                            .catch(error => {
                              desativaLoadingBotaoAlterarCliente(dispatch);
                              switch (error.code) {
                                case "auth/invalid-email":
                                  toastr.error('Error',
                                    "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                                  );
                                  break;
                                case "auth/email-already-in-use":
                                  toastr.error('Error',
                                    "Este email já está sendo utilizado, informe outro email."
                                  );
                                  break;
                                case "auth/network-request-failed":
                                  toastr.error('Error',
                                    "Sem conexão com a internet"
                                  );
                                  break;
                                default:
                                  toastr.error('Error',
                                    "Erro ao Alterar, tente novamente mais tarde"
                                  );
                                  break;
                              }
                            });
                          /*  })
                           .catch(error => {
                             //error.code
                             //error.message
                             switch (error.code) {
                               case "auth/invalid-email":
                                  toastr.error('Error',
                                   "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                                 );
                                 break;
                               case "auth/email-already-in-use":
                                  toastr.error('Error',
                                   "Este email já está sendo utilizado, informe outro email."
                                 );
                                 break;
                               case "auth/network-request-failed":
                                  toastr.error('Error',
                                   "Sem conexão com a internet"
                                 );
                                 break;
                               default:
                                  toastr.error('Error',
                                   "Erro ao Alterar, tente novamente mais tarde"
                                 );
                                 break;
                             }
                           }); */
                        }
                        /* })
                        .catch(error => {
                          //error.code
                          //error.message
                          switch (error.code) {
                            case "auth/invalid-email":
                              mensagemCasdastraClienteErrorTelaAlterar(
                                dispatch,
                                "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                              );
                              break;
                            case "auth/email-already-in-use":
                              mensagemCasdastraClienteErrorTelaAlterar(
                                dispatch,
                                "Este email já está sendo utilizado, informe outro email."
                              );
                              break;
                            case "auth/network-request-failed":
                              mensagemCasdastraClienteErrorTelaAlterar(
                                dispatch,
                                "Sem conexão com a internet"
                              );
                              break;
                            case "storage/object-not-found":
                              mensagemCasdastraClienteErrorTelaAlterar(
                                dispatch,
                                "Nome inválido, utilize Letras!"
                              );
                              break;
                            default:
                              mensagemCasdastraClienteErrorTelaAlterar(
                                dispatch,
                                "Erro ao Alterar, tente novamente mais tarde"
                              );
                              break;
                          }
                        }); */
                      }
                      //tem email no banco e no app
                      //e os emails são diferentes
                      //tenho que mudar a senha do email antigo e criar uma novasenha para o email novo
                      //e alterar os dados no banco.
                      else {
                        //
                        //email diferente ao do banco, então tenho que alterar o email
                        /*     secondaryApp
                              .auth()
                              .signInWithEmailAndPassword(
                                emailBanco,
                                "exemplo@exemplo2659"
                              )
                              .then(userCredential => {
                                userCredential
                                  .updateEmail(emailCliente)
                                  .then(() => { */
                        const options = {
                          method: 'POST',
                          headers: {
                            'Content-Type': 'application/json; charset=utf-8',
                            'Accept': 'application/json',
                          },

                          data: { email: emailCliente, senha: "exemplo@exemplo2659", rota: 'alteraremail', uid: useruid },
                          url: 'crudUserReactNative',
                        };
                        api(options).then((response) => {


                          /*  const primeiraLetra = nomeEmpresa
                             .substring(0, 1)
                             .trim()
                             .toLowerCase();
                           firebase
                             .storage()
                             .ref(`letras/${primeiraLetra}.png`)
                             .getDownloadURL()
                             .then(url => { */
                          //a foto que está no cliente é a mesma do banco, então não upo a foto
                          if (uriHTTP == 1) {
                            firebase
                              .database()
                              .ref(`${chaveUnica}/clientes/${key}`)
                              .update({
                                empresa:
                                  vinculadoEmpresa == true
                                    ? nomeEmpresaSelecionadoConsultaClienteTelaCAdastraCliente
                                    : nomeEmpresa,
                                nome: nomeCliente,
                                email: emailCliente,
                                telefoneCliente,
                                enderecoCliente,
                                empresaPesquisa: empresaPesquisa,
                                senha: "",
                                permissaoAPP: false,
                                alterado: alterado,
                                vinculadoEmpresa,
                                keyEmpresaVinculada:
                                  keyEmpresaVinculada == undefined
                                    ? "Não Vinculado"
                                    : keyEmpresaVinculada,
                                urlImagemNuvem: verificaSeUriImagemEhImagemUpadaOuNao == true ? uriImagem : "",
                                isMyContact: isTelAndWpp
                              })
                              .then(() => {
                                setTimeout(() => {
                                  removeFirebaseCachedData(`${chaveUnica}/clientes`, key);
                                  clearCacheRedis(chaveUnica,telefoneCliente);
                                  modificaArquivoCompletoParaUparLocal(dispatch, '');
                                  desativaLoadingBotaoAlterarCliente(dispatch);

                                  toastr.success('Sucesso',
                                    "Cliente alterado com sucesso!"
                                  );
                                }, 700);
                              })
                              .catch(error => {
                                desativaLoadingBotaoAlterarCliente(dispatch);
                                //error.code
                                //error.message
                                switch (error.code) {
                                  case "auth/invalid-email":
                                    toastr.error('Error',
                                      "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                                    );
                                    break;
                                  case "auth/email-already-in-use":
                                    toastr.error('Error',
                                      "Este email já está sendo utilizado, informe outro email."
                                    );
                                    break;
                                  case "auth/network-request-failed":
                                    toastr.error('Error',
                                      "Sem conexão com a internet"
                                    );
                                    break;
                                  default:
                                    toastr.error('Error',
                                      "Erro ao Alterar, tente novamente mais tarde"
                                    );
                                    break;
                                }
                              });
                          }
                          //foto diferente, vou upar no banco
                          else {
                            //  let uri = uriImagem.replace("file://", "");
                            let caminhoPadraoImagem = firebase
                              .storage()
                              .ref(`${chaveUnica}/${key}/imagem.jpg`);
                            let mime = "image/jpeg";
                            /*    RNFetchBlob.fs
                                 .readFile(uri, "base64")
                                 .then(data => {
                                   return RNFetchBlob.polyfill.Blob.build(
                                     data,
                                     { type: mime + ";BASE64" }
                                   );
                                 })
                                 .then(blob => { */
                            caminhoPadraoImagem
                              .put(arquivoCompletoParaUpar)
                              .then((url) => {
                                url.ref.getDownloadURL().then(function (downloadURL) {
                                  firebase
                                    .database()
                                    .ref(
                                      `${chaveUnica}/clientes/${key}`
                                    )
                                    .update({
                                      empresa:
                                        vinculadoEmpresa == true
                                          ? nomeEmpresaSelecionadoConsultaClienteTelaCAdastraCliente
                                          : nomeEmpresa,
                                      nome: nomeCliente,
                                      email: emailCliente,
                                      telefoneCliente,
                                      enderecoCliente,
                                      empresaPesquisa: empresaPesquisa,
                                      senha: "",
                                      permissaoAPP: false,
                                      alterado: alterado,
                                      vinculadoEmpresa,
                                      keyEmpresaVinculada:
                                        keyEmpresaVinculada == undefined
                                          ? "Não Vinculado"
                                          : keyEmpresaVinculada,
                                      urlImagemNuvem: downloadURL,
                                      isMyContact: isTelAndWpp
                                    })
                                    .then(() => {
                                      setTimeout(() => {
                                        modificaArquivoCompletoParaUparLocal(dispatch, '');
                                        removeFirebaseCachedData(`${chaveUnica}/clientes`, key);
                                        clearCacheRedis(chaveUnica,telefoneCliente);
                                        desativaLoadingBotaoAlterarCliente(dispatch);
                                        //   blob.close();
                                        toastr.success('Sucesso',
                                          "Cliente alterado com sucesso!"
                                        );
                                      }, 700);
                                    })
                                    .catch(error => {
                                      desativaLoadingBotaoAlterarCliente(dispatch);
                                      //error.code
                                      //error.message
                                      switch (error.code) {
                                        case "auth/invalid-email":
                                          toastr.error('Error',
                                            "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                                          );
                                          break;
                                        case "auth/email-already-in-use":
                                          toastr.error('Error',
                                            "Este email já está sendo utilizado, informe outro email."
                                          );
                                          break;
                                        case "auth/network-request-failed":
                                          toastr.error('Error',
                                            "Sem conexão com a internet"
                                          );
                                          break;
                                        default:
                                          toastr.error('Error',
                                            "Erro ao Alterar, tente novamente mais tarde"
                                          );
                                          break;
                                      }
                                    });
                                })
                              })
                              .catch(error => {
                                desativaLoadingBotaoAlterarCliente(dispatch);
                                switch (error.code) {
                                  case "auth/invalid-email":
                                    toastr.error('Error',
                                      "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                                    );
                                    break;
                                  case "auth/email-already-in-use":
                                    toastr.error('Error',
                                      "Este email já está sendo utilizado, informe outro email."
                                    );
                                    break;
                                  case "auth/network-request-failed":
                                    toastr.error('Error',
                                      "Sem conexão com a internet"
                                    );
                                    break;
                                  default:
                                    toastr.error('Error',
                                      "Erro ao Alterar, tente novamente mais tarde"
                                    );
                                    break;
                                }
                              });
                            /*   })
                              .catch(error => {
                                //error.code
                                //error.message
                                switch (error.code) {
                                  case "auth/invalid-email":
                                     toastr.error('Error',
                                      "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                                    );
                                    break;
                                  case "auth/email-already-in-use":
                                     toastr.error('Error',
                                      "Este email já está sendo utilizado, informe outro email."
                                    );
                                    break;
                                  case "auth/network-request-failed":
                                     toastr.error('Error',
                                      "Sem conexão com a internet"
                                    );
                                    break;
                                  default:
                                     toastr.error('Error',
                                      "Erro ao Alterar, tente novamente mais tarde"
                                    );
                                    break;
                                }
                              }); */
                          }
                          /*   })
                            .catch(error => {
                              //error.code
                              //error.message
                              switch (error.code) {
                                case "auth/invalid-email":
                                   toastr.error('Error',
                                    "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                                  );
                                  break;
                                case "auth/email-already-in-use":
                                   toastr.error('Error',
                                    "Este email já está sendo utilizado, informe outro email."
                                  );
                                  break;
                                case "auth/network-request-failed":
                                   toastr.error('Error',
                                    "Sem conexão com a internet"
                                  );
                                  break;
                                case "storage/object-not-found":
                                   toastr.error('Error',
                                    "Nome inválido, utilize Letras!"
                                  );
                                  break;
                                default:
                                   toastr.error('Error',
                                    "Erro ao Alterar, tente novamente mais tarde"
                                  );
                                  break;
                              }
                            }); */


                          /* */
                        })
                          .catch(error => {
                            desativaLoadingBotaoAlterarCliente(dispatch);
                            if (error.response) {


                              switch (error.response.data.code) {
                                case 'auth/email-already-exists':
                                  toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                                  break;
                                case 'auth/invalid-email':
                                  toastr.error('Error', 'Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br');
                                  break;
                                case 'auth/email-already-in-use':
                                  toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                                  break;
                                case 'auth/network-request-failed':
                                  toastr.error('Error', 'Sem conexão com a internet');
                                  break;
                                default:
                                  toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                                  break;
                              }
                            } else {
                              toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                            }
                          });
                      }
                    }
                  });
                });
              }
            }
          })
      })
    }

  };
};

export const consultaClienteComCliente = keyCliente => {
  return dispatch => {

    ativaDesativaLoadingTelaConsultaCliente(dispatch, true);
    ativaDesativaLoadingFinalFlatListTelaConsultaCliente(dispatch, false);
    AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");
      firebase.database().ref(`${chaveUnica}/clientes/${keyCliente}`).on("value", snapshot => {
        //;
        const dadoscliente = [];

        dadoscliente.push({
          key: snapshot.key,
          nome: snapshot.val().nome,
          empresa: snapshot.val().empresa,
          email: snapshot.val().email,
          permissaoAPP: snapshot.val().permissaoAPP,
          senha: snapshot.val().senha,
          alterado: snapshot.val().alterado,
          telefoneCliente: snapshot.val().telefoneCliente == undefined ? '' : snapshot.val().telefoneCliente,
          enderecoCliente: snapshot.val().enderecoCliente == undefined ? '' : snapshot.val().enderecoCliente,
          empresaPrincipal: snapshot.val().empresaPrincipal == undefined ? false : snapshot.val().empresaPrincipal,
          keyEmpresaVinculada: snapshot.val().keyEmpresaVinculada == undefined ? "Não Vinculado" : snapshot.val().keyEmpresaVinculada,
          chaveUnica: chaveUnica,
          urlImagemNuvem: snapshot.val().urlImagemNuvem,
        });

        enviaFotoClienteParaReducer(dispatch, dadoscliente);
        ativaDesativaLoadingTelaConsultaCliente(dispatch, false);
      });
    });

  };
};
export const listaClientesTelaConsultaClientes = (tela) => {
  return dispatch => {

  };
};
export const pesquisaClientePorDigito = (digito) => {
  return dispatch => {
    const textoDigitado = escapeRegexCharacters(digito.trim());


    ativaDesativaLoadingCarregarClienteTelaConsultaCliente(dispatch, true);
    /* ativaDesativaLoadingCarregarClienteTelaAlterarCliente(dispatch, true);
    ativaDesativaLoadingEnviaClienteTelaAvaliacaoTodas(dispatch, true);
    ativaDesativaLoadingCarregarClienteTelaNovoAviso(dispatch, true);
    ativaDesativaLoadingCarregarClienteTelaChamadoTodos(dispatch, true);
    loadingClienteTelaRelatorioDetalhado(dispatch, true);
    ativaLoadingCarregarClienteTelaNovoChamado(dispatch); */

    // let primeiraVerificacaoExecucao = false; //reability deploy
    // let primeiraVerificacaoExecucao = true;
    let children2 = [];
    let children3 = [];
    let clientesExportarExcel = [];
    AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");

      firebase.database().ref(`${chaveUnica}/clientes`).orderByChild('nome').startAt(textoDigitado)
        .endAt(textoDigitado + "\uf8ff").limitToFirst(5).once("value").then(snapshot => {
          //.limitToLast(1).on("child_added", snapshot => {



          if (snapshot.val() == null) {
            //  primeiraVerificacaoExecucao = true
            const arrayUser = [];
            modificaClientesFiltradosAposDigito(dispatch, arrayUser);
            /*  enviaEmpresaSectionedTelaAlterarCliente(dispatch, arrayUser);
             modificaClientesExportarClientes(dispatch, arrayUser); */

            ativaDesativaLoadingCarregarClienteTelaConsultaCliente(dispatch, false);
            /*   ativaDesativaLoadingCarregarClienteTelaAlterarCliente(dispatch, false);
              ativaDesativaLoadingEnviaClienteTelaAvaliacaoTodas(dispatch, false);
              ativaDesativaLoadingCarregarClienteTelaNovoAviso(dispatch, false);
              ativaDesativaLoadingCarregarClienteTelaChamadoTodos(dispatch, false);
              loadingClienteTelaRelatorioDetalhado(dispatch, false);
              desativaLoadingCarregarClienteTelaNovoChamado(dispatch); */
          } else {
            /*    let objectModif = { [snapshot.key]: snapshot.val() }
               // snapshot.val() = [snapshot.key] =snapshot.val();

                */
            //  snapshot.val() = objectModif;
            let arrayOfKeys = Object.keys(snapshot.val()).sort().reverse();
            let results = arrayOfKeys.map(key => snapshot.val()[key]);
            let referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];



            let aux = 0;
            results.forEach(childItem => {
              if (childItem.excluido == 1 || childItem.nome == undefined || childItem.empresa == undefined) {
              } else if (children2.filter(e => e.value === referenceToOldestKey).length > 0) {

              } else {
                /*  if (childItem.val().vinculadoEmpresa != true) { */
                children2.push({
                  value: arrayOfKeys[aux],
                  name: childItem.nome,
                  empresa: childItem.empresa,
                  keyEmpresaVinculada: childItem.keyEmpresaVinculada == undefined ? 'Não Vinculado' : childItem.keyEmpresaVinculada,
                  // name: `${childItem.val().nome} - (${childItem.val().empresa})`
                });
                if (childItem.vinculadoEmpresa != true) {
                  children3.push({
                    value: arrayOfKeys[aux],
                    name: childItem.empresa
                  });
                }
              }
              aux++
              /*  } */
            });
            const empresas = children2.reduce((obj, { empresa, name, value, keyEmpresaVinculada }) => {
              if (!obj[empresa]) obj[empresa] = [];
              obj[empresa].push({ value, name, empresa, keyEmpresaVinculada });
              return obj;
            }, {});
            const valoresConvertidos = Object.keys(empresas).map((empresa, value) => {
              return {
                title: empresa,
                //type: 'group',
                items: empresas[empresa]
              };
            });



            let items = [{
              name: 'EMPRESAS',
              type: 'group', items: children3
            }];
            //    const items = [{ children: children3 }];


            for (let i = 0; i < valoresConvertidos.length; i++) {

              if (valoresConvertidos[i].items.length > 1) {
                valoresConvertidos[i].items.sort(function (a, b) {
                  var textA = a.name.toUpperCase();
                  var textB = b.name.toUpperCase();
                  return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                });
              }

            }


            //  enviaEmpresaSectionedTelaAlterarCliente(dispatch, items)
            modificaClientesFiltradosAposDigito(dispatch, valoresConvertidos);
            // modificaClientesExportarClientes(dispatch, clientesExportarExcel);

            /*   enviaClienteParaReducerTodasAvaliacoes(dispatch, valoresConvertidos);
              enviaClienteParaReducerNovoAviso(dispatch, valoresConvertidos);
              enviaClienteParaReducerTelaChamadoTodos(dispatch, valoresConvertidos);
              enviaClienteParaTelaRelatorioDetalhado(dispatch, valoresConvertidos);
              enviaClienteParaReducer(dispatch, valoresConvertidos);*/

            // ativaDesativaLoadingCarregarClienteTelaAlterarCliente(dispatch, false);
            ativaDesativaLoadingCarregarClienteTelaConsultaCliente(dispatch, false);
            /*  ativaDesativaLoadingEnviaClienteTelaAvaliacaoTodas(dispatch, false);
             ativaDesativaLoadingCarregarClienteTelaNovoAviso(dispatch, false);
             ativaDesativaLoadingCarregarClienteTelaChamadoTodos(dispatch, false);
             loadingClienteTelaRelatorioDetalhado(dispatch, false);
             desativaLoadingCarregarClienteTelaNovoChamado(dispatch); */

          }
        });
    });
  };
};
export const listaClientesTelaConsultaClientesAntesRemoved = (tela) => {
  return dispatch => {
    ativaDesativaLoadingCarregarClienteTelaConsultaCliente(dispatch, true);
    ativaDesativaLoadingCarregarClienteTelaAlterarCliente(dispatch, true);
    ativaDesativaLoadingEnviaClienteTelaAvaliacaoTodas(dispatch, true);
    ativaDesativaLoadingCarregarClienteTelaNovoAviso(dispatch, true);
    ativaDesativaLoadingCarregarClienteTelaChamadoTodos(dispatch, true);
    loadingClienteTelaRelatorioDetalhado(dispatch, true);
    ativaLoadingCarregarClienteTelaNovoChamado(dispatch);

    let primeiraVerificacaoExecucao = false; //reability deploy
    // let primeiraVerificacaoExecucao = true;
    let children2 = [];
    let children3 = [];
    let clientesExportarExcel = [];
    AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");

      firebase.database().ref(`${chaveUnica}/clientes`).orderByKey()
        .limitToLast(1).on("child_added", snapshot => {



          if (snapshot.val() == null) {
            primeiraVerificacaoExecucao = true
            const arrayUser = [];
            enviaClienteSectionedTelaCOnsultaCliente(dispatch, arrayUser);
            enviaEmpresaSectionedTelaAlterarCliente(dispatch, arrayUser);
            modificaClientesExportarClientes(dispatch, arrayUser);
            ativaDesativaLoadingCarregarClienteTelaConsultaCliente(dispatch, false);
            ativaDesativaLoadingCarregarClienteTelaAlterarCliente(dispatch, false);
            ativaDesativaLoadingEnviaClienteTelaAvaliacaoTodas(dispatch, false);
            ativaDesativaLoadingCarregarClienteTelaNovoAviso(dispatch, false);
            ativaDesativaLoadingCarregarClienteTelaChamadoTodos(dispatch, false);
            loadingClienteTelaRelatorioDetalhado(dispatch, false);
            desativaLoadingCarregarClienteTelaNovoChamado(dispatch);
          } else {
            let objectModif = { [snapshot.key]: snapshot.val() }
            // snapshot.val() = [snapshot.key] =snapshot.val();


            //  snapshot.val() = objectModif;
            let arrayOfKeys = Object.keys(objectModif).sort().reverse();
            let results = arrayOfKeys.map(key => objectModif[key]);
            let referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];



            let aux = 0;
            if (tela === 'avisos') {
              children2.push({ value: 'TodosPadrao', name: 'TODOS', empresa: 'Todos os Clientes' });
            }
            results.forEach(childItem => {
              if (childItem.excluido == 1 || childItem.nome == undefined || childItem.empresa == undefined) {
              } else if (children2.filter(e => e.value === referenceToOldestKey).length > 0) {

              } else {
                /*  if (childItem.val().vinculadoEmpresa != true) { */
                children2.push({
                  value: arrayOfKeys[aux],
                  name: childItem.nome,
                  empresa: childItem.empresa,
                  //urlImagemNuvem: childItem.urlImagemNuvem,
                  keyEmpresaVinculada: childItem.keyEmpresaVinculada == undefined ? 'Não Vinculado' : childItem.keyEmpresaVinculada,
                  // name: `${childItem.val().nome} - (${childItem.val().empresa})`
                });
                if (childItem.vinculadoEmpresa != true) {
                  children3.push({
                    value: arrayOfKeys[aux],
                    name: childItem.empresa
                  });
                }
                clientesExportarExcel.push({
                  key: arrayOfKeys[aux],
                  isMyContact: childItem.isMyContact === undefined ? false : childItem.isMyContact,

                  nome: childItem.nome,
                  empresa: childItem.empresa,
                  email: childItem.email,
                  //permissaoAPP: childItem.permissaoAPP,
                  // senha: childItem.senha,
                  // excluido: childItem.excluido == undefined ? 0 : childItem.excluido,
                  telefoneCliente: childItem.telefoneCliente == undefined ? '' : childItem.telefoneCliente,
                  enderecoCliente: childItem.enderecoCliente == undefined ? '' : childItem.enderecoCliente,
                  //  chaveUnica: chaveUnica
                });
              }
              aux++
              /*  } */
            });
            const empresas = children2.reduce((obj, { empresa, name, value, keyEmpresaVinculada }) => {
              if (!obj[empresa]) obj[empresa] = [];
              obj[empresa].push({ value, name, empresa, keyEmpresaVinculada });
              return obj;
            }, {});
            const valoresConvertidos = Object.keys(empresas).map((empresa, value) => {
              return {
                name: empresa,
                type: 'group',
                items: empresas[empresa]
              };
            });



            let ultimoUser = children2.length > 0 ? children2[children2.length - 1].value : '';


            let items = [{
              name: 'EMPRESAS',
              type: 'group', items: children3
            }];
            //    const items = [{ children: children3 }];


            for (let i = 0; i < valoresConvertidos.length; i++) {

              if (valoresConvertidos[i].items.length > 1) {
                valoresConvertidos[i].items.sort(function (a, b) {
                  var textA = a.name.toUpperCase();
                  var textB = b.name.toUpperCase();
                  return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                });
              }

            }
            if (primeiraVerificacaoExecucao === true) {

              enviaEmpresaSectionedTelaAlterarCliente(dispatch, items)
              enviaClienteSectionedTelaCOnsultaCliente(dispatch, valoresConvertidos);
              modificaClientesExportarClientes(dispatch, clientesExportarExcel);

              /*   enviaClienteParaReducerTodasAvaliacoes(dispatch, valoresConvertidos);
                enviaClienteParaReducerNovoAviso(dispatch, valoresConvertidos);
                enviaClienteParaReducerTelaChamadoTodos(dispatch, valoresConvertidos);
                enviaClienteParaTelaRelatorioDetalhado(dispatch, valoresConvertidos);
                enviaClienteParaReducer(dispatch, valoresConvertidos);*/

              ativaDesativaLoadingCarregarClienteTelaAlterarCliente(dispatch, false);
              ativaDesativaLoadingCarregarClienteTelaConsultaCliente(dispatch, false);
              ativaDesativaLoadingEnviaClienteTelaAvaliacaoTodas(dispatch, false);
              ativaDesativaLoadingCarregarClienteTelaNovoAviso(dispatch, false);
              ativaDesativaLoadingCarregarClienteTelaChamadoTodos(dispatch, false);
              loadingClienteTelaRelatorioDetalhado(dispatch, false);
              desativaLoadingCarregarClienteTelaNovoChamado(dispatch);
            } else {
              /*  firebase.database().ref(`${chaveUnica}/clientes`).orderByKey().endAt(referenceToOldestKey)
                 .once("value").then(snapshot => {
                   if (snapshot.val() == null) { */
              const options = {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json; charset=utf-8',
                  'Accept': 'application/json',
                },

                data: { chaveUnica: chaveUnica, referenceToOldestKey: referenceToOldestKey },
                url: 'consultaClientesBD',
              };
              api(options).then((snapshot) => {

                /* console.log("lcbd new test logg") */

                snapshot = snapshot.data;
                if (snapshot == null) {
                  enviaEmpresaSectionedTelaAlterarCliente(dispatch, items)
                  enviaClienteSectionedTelaCOnsultaCliente(dispatch, valoresConvertidos);
                  modificaClientesExportarClientes(dispatch, clientesExportarExcel);

                  /*   enviaClienteParaReducerTodasAvaliacoes(dispatch, valoresConvertidos);
                    enviaClienteParaReducerNovoAviso(dispatch, valoresConvertidos);
                    enviaClienteParaReducerTelaChamadoTodos(dispatch, valoresConvertidos);
                    enviaClienteParaTelaRelatorioDetalhado(dispatch, valoresConvertidos);
                    enviaClienteParaReducer(dispatch, valoresConvertidos); */

                  ativaDesativaLoadingCarregarClienteTelaAlterarCliente(dispatch, false);
                  ativaDesativaLoadingCarregarClienteTelaConsultaCliente(dispatch, false);
                  ativaDesativaLoadingEnviaClienteTelaAvaliacaoTodas(dispatch, false);
                  ativaDesativaLoadingCarregarClienteTelaNovoAviso(dispatch, false);
                  ativaDesativaLoadingCarregarClienteTelaChamadoTodos(dispatch, false);
                  loadingClienteTelaRelatorioDetalhado(dispatch, false);
                  desativaLoadingCarregarClienteTelaNovoChamado(dispatch);
                } else {
                  primeiraVerificacaoExecucao = true;

                  let arrayOfKeys = Object.keys(snapshot).sort().reverse().slice(1);
                  let results = arrayOfKeys.map(key => snapshot[key]);
                  let referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];



                  let aux = 0;
                  results.forEach(childItem => {
                    if (childItem.excluido == 1 || childItem.nome == undefined || childItem.empresa == undefined) {
                    } else {
                      /*  if (childItem.val().vinculadoEmpresa != true) { */
                      children2.push({
                        value: arrayOfKeys[aux],
                        name: childItem.nome,
                        empresa: childItem.empresa,
                        keyEmpresaVinculada: childItem.keyEmpresaVinculada == undefined ? 'Não Vinculado' : childItem.keyEmpresaVinculada,
                        // name: `${childItem.val().nome} - (${childItem.val().empresa})`
                      });
                      if (childItem.vinculadoEmpresa != true) {
                        children3.push({
                          value: arrayOfKeys[aux],
                          name: childItem.empresa
                        });
                      }
                      clientesExportarExcel.push({
                        // key: arrayOfKeys[aux],
                        key: arrayOfKeys[aux],
                        isMyContact: childItem.isMyContact === undefined ? false : childItem.isMyContact,
                        nome: childItem.nome,
                        empresa: childItem.empresa,
                        email: childItem.email,
                        //permissaoAPP: childItem.permissaoAPP,
                        // senha: childItem.senha,
                        // excluido: childItem.excluido == undefined ? 0 : childItem.excluido,
                        telefoneCliente: childItem.telefoneCliente == undefined ? '' : childItem.telefoneCliente,
                        enderecoCliente: childItem.enderecoCliente == undefined ? '' : childItem.enderecoCliente,
                        //  chaveUnica: chaveUnica
                      });
                    }
                    aux++
                    /*  } */
                  });
                  const empresas = children2.reduce((obj, { empresa, name, value, keyEmpresaVinculada }) => {
                    if (!obj[empresa]) obj[empresa] = [];
                    obj[empresa].push({ value, name, empresa, keyEmpresaVinculada });
                    return obj;
                  }, {});
                  const valoresConvertidos = Object.keys(empresas).map((empresa, value) => {
                    return {
                      name: empresa,
                      type: 'group',
                      items: empresas[empresa]
                    };
                  });



                  let ultimoUser = children2.length > 0 ? children2[children2.length - 1].value : '';


                  let items = [{
                    name: 'EMPRESAS',
                    type: 'group', items: children3
                  }];
                  //    const items = [{ children: children3 }];


                  for (let i = 0; i < valoresConvertidos.length; i++) {

                    if (valoresConvertidos[i].items.length > 1) {
                      valoresConvertidos[i].items.sort(function (a, b) {
                        var textA = a.name.toUpperCase();
                        var textB = b.name.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                      });
                    }

                  }
                  enviaEmpresaSectionedTelaAlterarCliente(dispatch, items)
                  enviaClienteSectionedTelaCOnsultaCliente(dispatch, valoresConvertidos);
                  modificaClientesExportarClientes(dispatch, clientesExportarExcel);

                  /*    enviaClienteParaReducerTodasAvaliacoes(dispatch, valoresConvertidos);
                     enviaClienteParaReducerNovoAviso(dispatch, valoresConvertidos);
                     enviaClienteParaReducerTelaChamadoTodos(dispatch, valoresConvertidos);
                     enviaClienteParaTelaRelatorioDetalhado(dispatch, valoresConvertidos);
                     enviaClienteParaReducer(dispatch, valoresConvertidos);
    */
                  ativaDesativaLoadingCarregarClienteTelaAlterarCliente(dispatch, false);
                  ativaDesativaLoadingEnviaClienteTelaAvaliacaoTodas(dispatch, false);
                  ativaDesativaLoadingCarregarClienteTelaConsultaCliente(dispatch, false);
                  ativaDesativaLoadingCarregarClienteTelaNovoAviso(dispatch, false);
                  ativaDesativaLoadingCarregarClienteTelaChamadoTodos(dispatch, false);
                  loadingClienteTelaRelatorioDetalhado(dispatch, false);
                  desativaLoadingCarregarClienteTelaNovoChamado(dispatch);
                }

              })

            }
          }
        });
    });
  };
};
/* export const listaClientesTelaConsultaClientes = () => {
  return dispatch => {
    ativaDesativaLoadingCarregarClienteTelaConsultaCliente(dispatch, true);
    ativaDesativaLoadingCarregarClienteTelaAlterarCliente(dispatch, true);

    AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");

      firebase.database().ref(`${chaveUnica}/clientes`).orderByChild("empresa").on("value", snapshot => {
        if (snapshot.val() == null) {
          const arrayUser = [];
          enviaClienteSectionedTelaCOnsultaCliente(dispatch, arrayUser);
          enviaEmpresaSectionedTelaAlterarCliente(dispatch, arrayUser)
          ativaDesativaLoadingCarregarClienteTelaConsultaCliente(dispatch, false);
          ativaDesativaLoadingCarregarClienteTelaAlterarCliente(dispatch, false);
        } else {
          const children2 = [];
          const children3 = [];
          snapshot.forEach(childItem => {
            if (childItem.val().excluido == 1 || childItem.val().nome == undefined || childItem.val().empresa == undefined) {
            } else {
              children2.push({
                value: childItem.key,
                name: childItem.val().nome,
                empresa: childItem.val().empresa,
                // name: `${childItem.val().nome} - (${childItem.val().empresa})`
              });
              if (childItem.val().vinculadoEmpresa != true) {
                children3.push({
                  value: childItem.key,
                  name: childItem.val().empresa
                });
              }
            }
          });
          let arrayTeste = [];
          const empresas = children2.reduce((obj, { empresa, name, value }) => {
            if (!obj[empresa]) obj[empresa] = [];
            obj[empresa].push({ value, name });
            return obj;
          }, {});
          const valoresConvertidos = Object.keys(empresas).map((empresa, value) => {
            return {
              name: empresa,
              type: 'group',
              items: empresas[empresa]
            };
          });

          const items = [{
            name: 'EMPRESAS',
            type: 'group', items: children3
          }];
          //    const items = [{ children: children3 }];


          for (let i = 0; i < valoresConvertidos.length; i++) {

            if (valoresConvertidos[i].items.length > 1) {
              valoresConvertidos[i].items.sort(function (a, b) {
                var textA = a.name.toUpperCase();
                var textB = b.name.toUpperCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
              });
            }

          }

          enviaEmpresaSectionedTelaAlterarCliente(dispatch, items)
          enviaClienteSectionedTelaCOnsultaCliente(dispatch, valoresConvertidos);
          ativaDesativaLoadingCarregarClienteTelaAlterarCliente(dispatch, false);
          ativaDesativaLoadingCarregarClienteTelaConsultaCliente(dispatch, false);
        }
      });
    });
  };
}; */
export const consultaCliente2 = (
  valor,
  referenciaUltimaKey,
  clientesAnteriores,
) => {
  return dispatch => {
    //
    AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");


      if (valor == 0) {
        // if initial fetch
        ativaDesativaLoadingTelaConsultaCliente(dispatch, true);
        ativaDesativaLoadingRefreshControlConsultaCliente(dispatch, true);
        firebase.database().ref(`${chaveUnica}/clientes/`).orderByChild('excluido').equalTo(null).limitToLast(10).on("value", snapshot => {
          //
          if (snapshot.val() == null) {
            const arrayUser = [];
            enviaFotoClienteParaReducer(dispatch, arrayUser);
            ativaDesativaLoadingTelaConsultaCliente(dispatch, false);
            ativaDesativaLoadingRefreshControlConsultaCliente(dispatch, false);
          } else {
            let arrayOfKeys = Object.keys(snapshot.val())
              .sort()
              .reverse();
            // transforming to array
            let results = arrayOfKeys.map(key => snapshot.val()[key]);
            // storing reference
            let referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
            const dadoscliente = [];
            snapshot.forEach(childItem => {
              if (childItem.val().nome == undefined || childItem.val().empresa == undefined) {

              } else {
                dadoscliente.push({
                  key: childItem.key,
                  nome: childItem.val().nome,
                  empresa: childItem.val().empresa,
                  email: childItem.val().email,
                  permissaoAPP: childItem.val().permissaoAPP,
                  senha: childItem.val().senha,
                  alterado: childItem.val().alterado,
                  urlImagemNuvem: childItem.val().urlImagemNuvem,
                  excluido: childItem.val().excluido == undefined ? 0 : childItem.val().excluido,
                  telefoneCliente: childItem.val().telefoneCliente == undefined ? '' : childItem.val().telefoneCliente,
                  enderecoCliente: childItem.val().enderecoCliente == undefined ? '' : childItem.val().enderecoCliente,
                  empresaPrincipal: childItem.val().empresaPrincipal == undefined ? false : childItem.val().empresaPrincipal,
                  keyEmpresaVinculada: childItem.val().keyEmpresaVinculada == undefined ? "Não Vinculado" : childItem.val().keyEmpresaVinculada,
                  chaveUnica: chaveUnica
                });
              }


            });
            //
            enviaFotoClienteParaReducer(dispatch, dadoscliente);
            enviaUltimaReferenciaKeyTelaConsultaCliente(dispatch, referenceToOldestKey);
            ativaDesativaLoadingTelaConsultaCliente(dispatch, false);
            ativaDesativaLoadingRefreshControlConsultaCliente(dispatch, false);
            ativaDesativaLoadingFinalFlatListTelaConsultaCliente(dispatch, true);
          }
        });
      } else {
        if (referenciaUltimaKey != undefined && referenciaUltimaKey != "") {

          firebase.database().ref(`${chaveUnica}/clientes/`).orderByChild("excluido")
            .startAt(null)
            .endAt(null, referenciaUltimaKey).limitToLast(11).on("value", snapshot => {
              if (snapshot.val() == null) {
                ativaDesativaLoadingFinalFlatListTelaConsultaCliente(dispatch, false);
              } else {
                // changing to reverse chronological order (latest first)
                let arrayOfKeys = Object.keys(snapshot.val())
                  .sort()
                  .reverse()
                  .slice(1);
                // transforming to array
                let results = arrayOfKeys.map(key => snapshot.val()[key]);
                // storing reference
                let referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
                const dadoscliente = [];

                for (let i = 0; i < results.length; i++) {
                  if (results[i].nome == undefined || results[i].empresa == undefined) {

                  } else {
                    dadoscliente.push({
                      key: arrayOfKeys[i],
                      nome: results[i].nome,
                      empresa: results[i].empresa,
                      email: results[i].email,
                      permissaoAPP: results[i].permissaoAPP,
                      senha: results[i].senha,
                      alterado: results[i].alterado,
                      urlImagemNuvem: results[i].urlImagemNuvem,
                      excluido: results[i].excluido == undefined ? 0 : results[i].excluido,
                      telefoneCliente: results[i].telefoneCliente == undefined ? '' : results[i].telefoneCliente,
                      enderecoCliente: results[i].enderecoCliente == undefined ? '' : results[i].enderecoCliente,
                      empresaPrincipal: results[i].empresaPrincipal == undefined ? false : results[i].empresaPrincipal,
                      keyEmpresaVinculada: results[i].keyEmpresaVinculada == undefined ? "Não Vinculado" : results[i].keyEmpresaVinculada,
                      chaveUnica: chaveUnica
                    });
                  }
                }


                enviaFotoClienteParaReducer(dispatch, clientesAnteriores.concat(dadoscliente));
                enviaUltimaReferenciaKeyTelaConsultaCliente(dispatch, referenceToOldestKey);

              }
            });
        } else {
          ativaDesativaLoadingFinalFlatListTelaConsultaCliente(dispatch, false);
        }
      }
    });

  };
};

export const reabrirChamado = (keyChamado, keyAtendente, keySetor, dadosAtendimento) => {
  return dispatch => {
    ativaDesativaLoadingTelaAcoesAtendimento(dispatch, true);
    let atendenteAtendimento = keyAtendente.toString() == "" ? "Não informado" : keyAtendente.toString();
    let setorAtendimento = keySetor.toString() == "" ? "Não informado" : keySetor.toString();
    const timeAtual = retornaHoraAtual();
    const dataHoraAtual = retornaDataHoraAtual();
    const dataAtualPostagem = retornaDataAtual();
    let AbertoVencimento = dadosAtendimento.dataVencimento.toString() == "Não informado" ? 0 : dadosAtendimento.dataVencimento;
    if (AbertoVencimento !== 0) {
      AbertoVencimento = moment(AbertoVencimento, 'DD/MM/YYYY').unix();
    } else {
      AbertoVencimento = false;
    }
    let AbertoVencimentoAtendente = {};
    let testeObjeto = {};
    let Aberto = 'Aberto';
    AbertoVencimentoAtendente[atendenteAtendimento] = AbertoVencimento;
    testeObjeto[Aberto] = AbertoVencimentoAtendente;
    AbertoVencimento = { Aberto: AbertoVencimento }

    //

    AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");
      firebase
        .database()
        .ref(`${chaveUnica}/usuarios/`)
        .orderByChild("uid")
        .equalTo(firebase.auth().currentUser.uid)
        .once("value")
        .then(snapshot => {
          let arrayNomeQuemAbriuChamado = [];
          snapshot.forEach(childItem => {
            arrayNomeQuemAbriuChamado.push({
              nomeDeQuemAbriuChamado: childItem.val().nome
            });
          });
          let keyDeQuemAlterouChamado = Object.keys(snapshot.val()).toString();

          firebase
            .database()
            .ref(`${chaveUnica}/acoesChamados/${keyChamado}/`)
            .push({
              tipo: "mensagem",
              titulo:
                arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado,
              mensagem: "Atendimento reaberto por " + arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado,
              dataHoraAtual: dataHoraAtual,
              dataAtualPostagem: dataAtualPostagem,
              time: timeAtual,
              lineColor: "#00FF7F",
              circleColor: "#00FF7F",
              visivelATodos: 1,
              keyDeQuemAlterouChamado
            })
            .then(() => {
              firebase
                .database()
                .ref(`${chaveUnica}/chamados/${keyChamado}`)
                .update({
                  status: "Aberto", status_atendente: `Aberto${keyAtendente}`,
                  status_cliente: `Aberto${dadosAtendimento.keyCliente}`, //filtro cliente comum
                  status_setor: `Aberto${dadosAtendimento.keySetor}`, //filtro setor comum
                  status_prioridade: `Aberto${dadosAtendimento.prioridade}`, // filtro prioridade comum
                  status_local: `Aberto${dadosAtendimento.local}`, // filtro local comum
                  status_dataVencimento: AbertoVencimento, // filtro local comum
                  status_numeroChamado: `Aberto_${dadosAtendimento.numeroChamado}`,

                  status_atendente_cliente: `Aberto${keyAtendente}${dadosAtendimento.keyCliente}`, //filtro cliente vê proprio chamado
                  status_atendente_setor: `Aberto${keyAtendente}${dadosAtendimento.keySetor}`, //filtro setor vê proprio chamado
                  status_atendente_prioridade: `Aberto${keyAtendente}${dadosAtendimento.prioridade}`, // filtro prioridade vê proprio chamado
                  status_atendente_local: `Aberto${keyAtendente}${dadosAtendimento.local}`, // filtro local vê proprio chamado
                  status_atendente_dataVencimento: testeObjeto, // filtro local vê proprio chamado
                  status_atendente_numeroChamado: `Aberto_${keyAtendente}${dadosAtendimento.numeroChamado}`,
                  lastMessageChat: "Atendimento reaberto por " + arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado,
                  lastMessageChatTimeStamp: firebase.database.ServerValue.TIMESTAMP,
                  lastmessageChatCount: 0,
                  lastmessageChatType: 'mensagem',
                  lastMessageLineColor: '#00FF7F'
                })
                .then(() => {

                  if (setorAtendimento !== "Não informado") {
                    let keyAtendimento = keyChamado;
                    firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/usuariosVisualizaAtend/`).remove().then(
                      () => {
                        //
                        //busco clientes vinculados ao setor, se nao tiver eu seto todos os atendentes se tiver seto apenas os atendentes vinculados
                        firebase.database().ref(`${chaveUnica}/setores/${setorAtendimento}/atendentesVinculados`).once('value').then((snapshotSetor) => {
                          if (snapshotSetor.val()) {
                            let dadosAtendVinc = snapshotSetor.val();

                            let keyAtendentesVinculados = Object.keys(snapshotSetor.val());

                            for (let i = 0; i < keyAtendentesVinculados.length; i++) {
                              if (keyAtendentesVinculados[i].includes('codigoInterno')) {
                                dadosAtendVinc[keyAtendentesVinculados[i] + "status"] = keyAtendentesVinculados[i] + "Aberto";
                              }
                            }

                            //  console.log(snapshotSetor.val());

                            //tem atendentes vinculados ao setor
                            const dadosAtendentesVinculadosSetor = [];
                            snapshotSetor.forEach((childItem) => {
                              dadosAtendentesVinculadosSetor.push({
                                key: childItem.key,
                              });
                            })
                            //

                            let verificoSeAtendenteDoChamadoNaoEstaNoSetorVinculado = 0;
                            if (atendenteAtendimento !== 'Não informado') {
                              //verifico se o atendente do chamado nao esta vinculado a este setor
                              for (let i = 0; i < dadosAtendentesVinculadosSetor.length; i++) {
                                //
                                if (dadosAtendentesVinculadosSetor[i].key == atendenteAtendimento) {
                                  verificoSeAtendenteDoChamadoNaoEstaNoSetorVinculado = 1;

                                }
                              }
                              //atendente nao esta no grupo porem foi vinculado ao atendimento, coloco ele nos vinculados do chamado
                              if (verificoSeAtendenteDoChamadoNaoEstaNoSetorVinculado == 0) {
                                //
                                let objetocadastro = {};
                                firebase.database().ref(`${chaveUnica}/usuarios/${atendenteAtendimento}`).once("value").then((snapshot) => {
                                  let key = snapshot.key;
                                  let codigoInterno = snapshot.val().codigoInterno;
                                  objetocadastro[codigoInterno] = codigoInterno;
                                  objetocadastro[codigoInterno + "status"] = `${codigoInterno}Aberto`;
                                  // objetocadastro[key] = true;
                                  //
                                  firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}/usuariosVisualizaAtend/dadosAtendVinc`).update(objetocadastro)
                                })

                              } else {
                                //creio que ja cai na situaçaão acima
                                //
                              }
                            }

                            firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}/usuariosVisualizaAtend`).update({ dadosAtendVinc })
                          } else {
                            //
                            firebase.database().ref(`${chaveUnica}/usuarios/`).orderByChild('excluido').equalTo(null).once('value').then((snapshot) => {
                              //
                              const idTodosAtendentes = [];
                              let objetocadastro = {};
                              snapshot.forEach((childItem) => {
                                idTodosAtendentes.push({
                                  key: childItem.key,
                                  codigoInterno: childItem.val().codigoInterno == undefined ? 'codigoInterno1' : childItem.val().codigoInterno,
                                });
                              })
                              //
                              for (let i = 0; i < idTodosAtendentes.length; i++) {
                                //  objetocadastro[idTodosAtendentes[i].key] = true;
                                objetocadastro[idTodosAtendentes[i].codigoInterno] = idTodosAtendentes[i].codigoInterno;
                                objetocadastro[idTodosAtendentes[i].codigoInterno + "status"] = idTodosAtendentes[i].codigoInterno + 'Aberto';
                              }
                              //
                              firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}/usuariosVisualizaAtend/dadosAtendVinc`).update(objetocadastro)
                            })

                          }
                        })
                      })
                  } else {
                    //verifica se o atendente é dierente de naão informado, porque se for, preciso criar o campo atendentes vinculados pra esse atendente visualizar este atendimento
                    //
                    let objetocadastro = {}
                    if (atendenteAtendimento != "Não informado") {
                      //
                      //
                      objetocadastro[atendenteAtendimento] = true;
                      //

                      firebase.database().ref(`${chaveUnica}/usuarios/${atendenteAtendimento}/codigoInterno/`).once('value').then((snapshot) => {

                        let codigoInterno = snapshot.val();
                        objetocadastro[codigoInterno] = codigoInterno;
                        objetocadastro[codigoInterno + "status"] = `${codigoInterno}Aberto`;

                        let dadosAtendVinc = objetocadastro;
                        firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}/usuariosVisualizaAtend/`).update({ dadosAtendVinc })
                      })

                    }
                  }
                  ativaDesativaLoadingTelaAcoesAtendimento(dispatch, false);
                });
            });
        });
    });
  };
};
export const cancelarChamado = (keyChamado, motivo2, keyAtendente, dadosAtendimento, obrigatorioInformarMotivoCancelamento) => {
  return dispatch => {
    const timeAtual = retornaHoraAtual();
    const dataHoraAtual = retornaDataHoraAtual();
    const dataAtualPostagem = retornaDataAtual();
    const motivo = motivo2.trim();
    let AbertoVencimento = dadosAtendimento.dataVencimento.toString() == "Não informado" ? 0 : dadosAtendimento.dataVencimento;
    if (AbertoVencimento !== 0) {
      AbertoVencimento = moment(AbertoVencimento, 'DD/MM/YYYY').unix();
    } else {
      AbertoVencimento = false;
    }

    let AbertoVencimentoAtendente = {};
    let testeObjeto = {};
    let Aberto = 'Cancelado';
    AbertoVencimentoAtendente[keyAtendente] = AbertoVencimento;
    testeObjeto[Aberto] = AbertoVencimentoAtendente;
    AbertoVencimento = { Cancelado: AbertoVencimento }


    //

    AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");
      if (motivo.trim() == "" && obrigatorioInformarMotivoCancelamento === true) {
        modificaMensagemErroMotivoCancelamento(
          dispatch,
          "Informe um motivo de Cancelamento!"
        );
      } else {
        modificavisibilidadeModalCancelarLocal(dispatch, false);
        ativaDesativaLoadingTelaAcoesAtendimento(dispatch, true);
        firebase.database().ref(`${chaveUnica}/usuarios/`).orderByChild("uid").equalTo(firebase.auth().currentUser.uid).once("value")
          .then(snapshot => {
            let arrayNomeQuemAbriuChamado = [];
            snapshot.forEach(childItem => {
              arrayNomeQuemAbriuChamado.push({
                nomeDeQuemAbriuChamado: childItem.val().nome
              });
            });
            let keyDeQuemAlterouChamado = Object.keys(
              snapshot.val()
            ).toString();

            firebase
              .database()
              .ref(`${chaveUnica}/acoesChamados/${keyChamado}/`)
              .push({
                tipo: "mensagem",
                titulo:
                  arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado,
                mensagem: arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado + " Cancelou este atendimento pelo motivo: " + motivo,
                dataHoraAtual: dataHoraAtual,
                dataAtualPostagem: dataAtualPostagem,
                time: timeAtual,
                lineColor: "#00FF7F",
                circleColor: "#00FF7F",
                visivelATodos: 1,
                keyDeQuemAlterouChamado
              })
              .then(() => {
                let dadosAtendVinc = { vazio: 'vazio' };
                firebase
                  .database()
                  .ref(`${chaveUnica}/chamados/${keyChamado}`)
                  .update({
                    status: "Cancelado",
                    // usuariosVisualizaAtend: dadosAtendVinc,
                    dataCancelamento: dataAtualPostagem,
                    dataHoraCancelamento: dataHoraAtual,
                    status_atendente: `Cancelado${keyAtendente}`,

                    status_cliente: `Cancelado${dadosAtendimento.keyCliente}`, //filtro cliente comum
                    status_setor: `Cancelado${dadosAtendimento.keySetor}`, //filtro setor comum
                    status_prioridade: `Cancelado${dadosAtendimento.prioridade}`, // filtro prioridade comum
                    status_local: `Cancelado${dadosAtendimento.local}`, // filtro local comum
                    status_dataVencimento: AbertoVencimento, // filtro local comum
                    status_numeroChamado: `Cancelado_${dadosAtendimento.numeroChamado}`,

                    status_atendente_cliente: `Cancelado${keyAtendente}${dadosAtendimento.keyCliente}`, //filtro cliente vê proprio chamado
                    status_atendente_setor: `Cancelado${keyAtendente}${dadosAtendimento.keySetor}`, //filtro setor vê proprio chamado
                    status_atendente_prioridade: `Cancelado${keyAtendente}${dadosAtendimento.prioridade}`, // filtro prioridade vê proprio chamado
                    status_atendente_local: `Cancelado${keyAtendente}${dadosAtendimento.local}`, // filtro local vê proprio chamado
                    status_atendente_dataVencimento: testeObjeto, // filtro local vê proprio chamado
                    status_atendente_numeroChamado: `Cancelado_${keyAtendente}${dadosAtendimento.numeroChamado}`,
                    status_atendente_abertoviachat: '',
                    channelChamado: '',
                    lastMessageChat: arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado + " Cancelou este atendimento pelo motivo: " + motivo,
                    lastMessageChatTimeStamp: firebase.database.ServerValue.TIMESTAMP,
                    lastmessageChatCount: 0,
                    lastmessageChatType: 'mensagem',
                    lastMessageLineColor: '#00FF7F'
                    //  telefoneCliente:''
                  })
                  .then(() => {
                    let setorAtendimento = dadosAtendimento.keySetor.toString() == "" ? "Não informado" : dadosAtendimento.keySetor.toString();
                    let atendenteAtendimento = dadosAtendimento.keyAtendente.toString() == "" ? "Não informado" : dadosAtendimento.keyAtendente.toString();
                    let keyAtendimento = dadosAtendimento.key;


                    if (setorAtendimento !== "Não informado") {
                      let keyAtendimento = keyChamado;
                      firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/usuariosVisualizaAtend/`).remove().then(
                        () => {
                          //busco clientes vinculados ao setor, se nao tiver eu seto todos os atendentes se tiver seto apenas os atendentes vinculados
                          firebase.database().ref(`${chaveUnica}/setores/${setorAtendimento}/atendentesVinculados`).once('value').then((snapshotSetor) => {
                            if (snapshotSetor.val()) {
                              let dadosAtendVinc = {};

                              let keyAtendentesVinculados = Object.keys(snapshotSetor.val());

                              for (let i = 0; i < keyAtendentesVinculados.length; i++) {
                                if (keyAtendentesVinculados[i].includes('codigoInterno')) {
                                  dadosAtendVinc[keyAtendentesVinculados[i] + "status"] = keyAtendentesVinculados[i] + "Cancelado";
                                }
                              }


                              //tem atendentes vinculados ao setor
                              const dadosAtendentesVinculadosSetor = [];
                              snapshotSetor.forEach((childItem) => {
                                dadosAtendentesVinculadosSetor.push({
                                  key: childItem.key,
                                });
                              })

                              let verificoSeAtendenteDoChamadoNaoEstaNoSetorVinculado = 0;
                              if (atendenteAtendimento !== 'Não informado') {
                                //verifico se o atendente do chamado nao esta vinculado a este setor
                                for (let i = 0; i < dadosAtendentesVinculadosSetor.length; i++) {
                                  if (dadosAtendentesVinculadosSetor[i].key == atendenteAtendimento) {
                                    verificoSeAtendenteDoChamadoNaoEstaNoSetorVinculado = 1;

                                  }
                                }
                                //atendente nao esta no grupo porem foi vinculado ao atendimento, coloco ele nos vinculados do chamado
                                if (verificoSeAtendenteDoChamadoNaoEstaNoSetorVinculado == 0) {
                                  let objetocadastro = {};
                                  firebase.database().ref(`${chaveUnica}/usuarios/${atendenteAtendimento}`).once("value").then((snapshot) => {
                                    let key = snapshot.key;
                                    let codigoInterno = snapshot.val().codigoInterno;
                                    // objetocadastro[codigoInterno] = codigoInterno;
                                    objetocadastro[codigoInterno + "status"] = codigoInterno + "Cancelado";

                                    // objetocadastro[key] = true;

                                    firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/usuariosVisualizaAtend/dadosAtendVinc`).update(objetocadastro)
                                  })

                                } else {
                                  //creio que ja cai na situaçaão acima
                                }
                              }

                              firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/usuariosVisualizaAtend`).update({ dadosAtendVinc })
                            } else {
                              //
                              firebase.database().ref(`${chaveUnica}/usuarios/`).orderByChild('excluido').equalTo(null).once('value').then((snapshot) => {
                                const idTodosAtendentes = [];
                                let objetocadastro = {};
                                snapshot.forEach((childItem) => {
                                  idTodosAtendentes.push({
                                    key: childItem.key,
                                    codigoInterno: childItem.val().codigoInterno == undefined ? 'codigoInterno1' : childItem.val().codigoInterno,
                                  });
                                })
                                //
                                for (let i = 0; i < idTodosAtendentes.length; i++) {
                                  // objetocadastro[idTodosAtendentes[i].key] = true;
                                  //objetocadastro[idTodosAtendentes[i].codigoInterno] = idTodosAtendentes[i].codigoInterno;
                                  objetocadastro[idTodosAtendentes[i].codigoInterno + "status"] = idTodosAtendentes[i].codigoInterno + "Cancelado";

                                }
                                //
                                firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/usuariosVisualizaAtend/dadosAtendVinc`).update(objetocadastro)
                              })

                            }
                          })
                        })
                    } else if (atendenteAtendimento !== "Não informado") {
                      let objetocadastro = {};
                      firebase.database().ref(`${chaveUnica}/usuarios/${atendenteAtendimento}`).once("value").then((snapshot) => {
                        let key = snapshot.key;
                        let codigoInterno = snapshot.val().codigoInterno;
                        // objetocadastro[codigoInterno] = codigoInterno;
                        objetocadastro[codigoInterno + "status"] = codigoInterno + "Cancelado";


                        // objetocadastro[key] = true;

                        //
                        // firebase.database().ref(`${chaveUnica}/dadosAtendVinc/${key}/${dadosChamado.key}`).push({ true: 'true' });

                        firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/usuariosVisualizaAtend/`).remove().then(
                          () => {
                            firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/usuariosVisualizaAtend/dadosAtendVinc`).update(objetocadastro)
                          })
                        //firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/usuariosVisualizaAtend/dadosAtendVinc`).update(objetocadastro)
                      })
                    } else {
                      let dadosAtendVinc = { vazio: 'vazio' };
                      firebase
                        .database()
                        .ref(`${chaveUnica}/chamados/${keyAtendimento}`)
                        .update({ usuariosVisualizaAtend: dadosAtendVinc })
                    }
                    ativaDesativaLoadingTelaAcoesAtendimento(dispatch, false);
                    modificaMensagemErroMotivoCancelamento(dispatch, "");
                    modificaMotivoCancelamentoTelaAcoesAtendimentoLocal(
                      dispatch,
                      ""
                    );
                  });
              });
          });
      }
    });
  };
};
export const alterarDataVencimentoAtendimento = (
  keyChamado,
  dataVencimentoAtual,
  dataVencimenoPassada,
  dadosAtendimento
) => {
  return dispatch => {
    ativaDesativaLoadingTelaAcoesAtendimento(dispatch, true);

    const timeAtual = retornaHoraAtual();
    const dataHoraAtual = retornaDataHoraAtual();
    const dataAtualPostagem = retornaDataAtual();
    let dataVencimentoUnix = '';
    let AbertoVencimento = dataVencimentoAtual.toString() == "Não informado" ? 0 : dataVencimentoAtual;
    if (AbertoVencimento !== 0) {
      AbertoVencimento = moment(AbertoVencimento, 'DD/MM/YYYY').unix();
      dataVencimentoUnix = AbertoVencimento;
    } else {
      AbertoVencimento = false;
    }
    let AbertoVencimentoAtendente = {};
    let testeObjeto = {};
    let Aberto = '';
    if (dadosAtendimento.status == 'Aberto') {
      Aberto = 'Aberto';
      AbertoVencimentoAtendente[dadosAtendimento.keyAtendente] = AbertoVencimento;
      testeObjeto[Aberto] = AbertoVencimentoAtendente;
      AbertoVencimento = { Aberto: AbertoVencimento }
      // AbertoVencimentoAtendente[dadosAtendimento.keyAtendente] = Aberto = AbertoVencimento;
    }
    if (dadosAtendimento.status == 'Atendendo') {
      Aberto = 'Atendendo';
      AbertoVencimentoAtendente[dadosAtendimento.keyAtendente] = AbertoVencimento;
      testeObjeto[Aberto] = AbertoVencimentoAtendente;
      AbertoVencimento = { Atendendo: AbertoVencimento }
      //AbertoVencimentoAtendente[dadosAtendimento.keyAtendente] = Atendendo = AbertoVencimento;
    }
    if (dadosAtendimento.status == 'Fechado') {
      Aberto = 'Fechado';
      AbertoVencimentoAtendente[dadosAtendimento.keyAtendente] = AbertoVencimento;
      testeObjeto[Aberto] = AbertoVencimentoAtendente;
      AbertoVencimento = { Fechado: AbertoVencimento }
      // AbertoVencimentoAtendente[dadosAtendimento.keyAtendente] = Fechado = AbertoVencimento;
    }
    if (dadosAtendimento.status == 'Cancelado') {
      Aberto = 'Cancelado';
      AbertoVencimentoAtendente[dadosAtendimento.keyAtendente] = AbertoVencimento;
      testeObjeto[Aberto] = AbertoVencimentoAtendente;
      AbertoVencimento = { Cancelado: AbertoVencimento }
      //  AbertoVencimentoAtendente[dadosAtendimento.keyAtendente] = Cancelado = AbertoVencimento;
    }

    //
    AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");
      firebase.database().ref(`${chaveUnica}/usuarios/`).orderByChild("uid").equalTo(firebase.auth().currentUser.uid).once("value")
        .then(snapshot => {
          let arrayNomeQuemAbriuChamado = [];
          snapshot.forEach(childItem => {
            arrayNomeQuemAbriuChamado.push({
              nomeDeQuemAbriuChamado: childItem.val().nome
            });
          });
          let keyDeQuemAlterouChamado = Object.keys(snapshot.val()).toString();

          firebase.database().ref(`${chaveUnica}/acoesChamados/${keyChamado}/`).push({
            tipo: "mensagem",
            titulo:
              arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado,
            mensagem: " Alterou a data de vencimento de " + dataVencimenoPassada + " para " + dataVencimentoAtual,
            dataHoraAtual: dataHoraAtual,
            dataAtualPostagem: dataAtualPostagem,
            time: timeAtual,
            lineColor: "#00FF7F",
            circleColor: "#00FF7F",
            visivelATodos: 1,
            keyDeQuemAlterouChamado
          })
            .then(() => {
              firebase
                .database()
                .ref(`${chaveUnica}/chamados/${keyChamado}`)
                .update({
                  dataVencimento: dataVencimentoAtual, status_dataVencimento: AbertoVencimento, status_atendente_dataVencimento: testeObjeto,
                  atendente_dataVencimento: AbertoVencimentoAtendente, dataVencimentoUnix: dataVencimentoUnix,
                  lastMessageChat: " Alterou a data de vencimento de " + dataVencimenoPassada + " para " + dataVencimentoAtual,
                  lastMessageChatTimeStamp: firebase.database.ServerValue.TIMESTAMP,
                  lastmessageChatCount: 0,
                  lastmessageChatType: 'mensagem',
                  lastMessageLineColor: '#00FF7F'
                })
                .then(() => {
                  ativaDesativaLoadingTelaAcoesAtendimento(dispatch, false);
                });
            });
        });
    });
  };
};
export const alterarPrioridadeAtendimento = (
  keyChamado,
  prioridade,
  prioridadeAnterior,
  dadosAtendimento
) => {
  return dispatch => {
    ativaDesativaLoadingTelaAcoesAtendimento(dispatch, true);

    const timeAtual = retornaHoraAtual();
    const dataHoraAtual = retornaDataHoraAtual();
    const dataAtualPostagem = retornaDataAtual();

    AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");
      if (prioridade == "Baixa") {
        firebase.database().ref(`${chaveUnica}/usuarios/`).orderByChild("uid").equalTo(firebase.auth().currentUser.uid).once("value")
          .then(snapshot => {
            let arrayNomeQuemAbriuChamado = [];
            snapshot.forEach(childItem => {
              arrayNomeQuemAbriuChamado.push({
                nomeDeQuemAbriuChamado: childItem.val().nome
              });
            });
            let keyDeQuemAlterouChamado = Object.keys(
              snapshot.val()
            ).toString();

            firebase
              .database()
              .ref(`${chaveUnica}/acoesChamados/${keyChamado}/`)
              .push({
                tipo: "mensagem",
                titulo:
                  arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado,
                mensagem: arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado + " Alterou prioridade do atendimento de " + prioridadeAnterior + " para Baixa",
                dataHoraAtual: dataHoraAtual,
                dataAtualPostagem: dataAtualPostagem,
                time: timeAtual,
                lineColor: "#00FF7F",
                circleColor: "#00FF7F",
                visivelATodos: 0,
                keyDeQuemAlterouChamado
              })
              .then(() => {
                firebase
                  .database()
                  .ref(`${chaveUnica}/chamados/${keyChamado}`)
                  .update({
                    prioridade: "Baixa", status_prioridade: `${dadosAtendimento.status}Baixa`,
                    status_atendente_prioridade: `${dadosAtendimento.status}${dadosAtendimento.keyAtendente}Baixa`,
                    atendente_prioridade: `${dadosAtendimento.keyAtendente}Baixa`,
                    lastMessageChat: arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado + " Alterou prioridade do atendimento de " + prioridadeAnterior + " para Baixa",
                    lastMessageChatTimeStamp: firebase.database.ServerValue.TIMESTAMP,
                    lastmessageChatCount: 0,
                    lastmessageChatType: 'mensagem',
                    lastMessageLineColor: '#00FF7F'
                  })
                  .then(() => {
                    ativaDesativaLoadingTelaAcoesAtendimento(dispatch, false);
                  });
              });
          });
      } else if (prioridade == "Normal") {
        firebase
          .database()
          .ref(`${chaveUnica}/usuarios/`)
          .orderByChild("uid")
          .equalTo(firebase.auth().currentUser.uid)
          .once("value")
          .then(snapshot => {
            let arrayNomeQuemAbriuChamado = [];
            snapshot.forEach(childItem => {
              arrayNomeQuemAbriuChamado.push({
                nomeDeQuemAbriuChamado: childItem.val().nome
              });
            });
            let keyDeQuemAlterouChamado = Object.keys(
              snapshot.val()
            ).toString();

            firebase
              .database()
              .ref(`${chaveUnica}/acoesChamados/${keyChamado}/`)
              .push({
                tipo: "mensagem",
                titulo:
                  arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado,
                mensagem: arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado + " Alterou prioridade do atendimento de " + prioridadeAnterior + " para Normal",
                dataHoraAtual: dataHoraAtual,
                dataAtualPostagem: dataAtualPostagem,
                time: timeAtual,
                lineColor: "#00FF7F",
                circleColor: "#00FF7F",
                visivelATodos: 0,
                keyDeQuemAlterouChamado
              })
              .then(() => {
                firebase
                  .database()
                  .ref(`${chaveUnica}/chamados/${keyChamado}`)
                  .update({
                    prioridade: "Normal", status_prioridade: `${dadosAtendimento.status}Normal`,
                    status_atendente_prioridade: `${dadosAtendimento.status}${dadosAtendimento.keyAtendente}Normal`,
                    atendente_prioridade: `${dadosAtendimento.keyAtendente}Normal`,
                    lastMessageChat: arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado + " Alterou prioridade do atendimento de " + prioridadeAnterior + " para Normal",
                    lastMessageChatTimeStamp: firebase.database.ServerValue.TIMESTAMP,
                    lastmessageChatCount: 0,
                    lastmessageChatType: 'mensagem',
                    lastMessageLineColor: '#00FF7F'
                  })
                  .then(() => {
                    ativaDesativaLoadingTelaAcoesAtendimento(dispatch, false);
                  });
              });
          });
      } else if (prioridade == "Alta") {
        firebase
          .database()
          .ref(`${chaveUnica}/usuarios/`)
          .orderByChild("uid")
          .equalTo(firebase.auth().currentUser.uid)
          .once("value")
          .then(snapshot => {
            let arrayNomeQuemAbriuChamado = [];
            snapshot.forEach(childItem => {
              arrayNomeQuemAbriuChamado.push({
                nomeDeQuemAbriuChamado: childItem.val().nome
              });
            });
            let keyDeQuemAlterouChamado = Object.keys(
              snapshot.val()
            ).toString();

            firebase
              .database()
              .ref(`${chaveUnica}/acoesChamados/${keyChamado}/`)
              .push({
                tipo: "mensagem",
                titulo: arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado,
                mensagem: arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado + " Alterou prioridade do atendimento de " + prioridadeAnterior + " para Alta",
                dataHoraAtual: dataHoraAtual,
                dataAtualPostagem: dataAtualPostagem,
                time: timeAtual,
                lineColor: "#00FF7F",
                circleColor: "#00FF7F",
                visivelATodos: 0,
                keyDeQuemAlterouChamado
              })
              .then(() => {
                firebase
                  .database()
                  .ref(`${chaveUnica}/chamados/${keyChamado}`)
                  .update({
                    prioridade: "Alta", status_prioridade: `${dadosAtendimento.status}Alta`,
                    status_atendente_prioridade: `${dadosAtendimento.status}${dadosAtendimento.keyAtendente}Alta`,
                    atendente_prioridade: `${dadosAtendimento.keyAtendente}Alta`,
                    lastMessageChat: arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado + " Alterou prioridade do atendimento de " + prioridadeAnterior + " para Alta",
                    lastMessageChatTimeStamp: firebase.database.ServerValue.TIMESTAMP,
                    lastmessageChatCount: 0,
                    lastmessageChatType: 'mensagem',
                    lastMessageLineColor: '#00FF7F'

                  })
                  .then(() => {
                    ativaDesativaLoadingTelaAcoesAtendimento(dispatch, false);
                  });
              });
          });
      }
      //Urgente
      else {
        firebase
          .database()
          .ref(`${chaveUnica}/usuarios/`)
          .orderByChild("uid")
          .equalTo(firebase.auth().currentUser.uid)
          .once("value")
          .then(snapshot => {
            let arrayNomeQuemAbriuChamado = [];
            snapshot.forEach(childItem => {
              arrayNomeQuemAbriuChamado.push({
                nomeDeQuemAbriuChamado: childItem.val().nome
              });
            });
            let keyDeQuemAlterouChamado = Object.keys(
              snapshot.val()
            ).toString();

            firebase
              .database()
              .ref(`${chaveUnica}/acoesChamados/${keyChamado}/`)
              .push({
                tipo: "mensagem",
                titulo:
                  arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado,
                mensagem: arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado + " Alterou prioridade do atendimento de " + prioridadeAnterior + " para Urgente",
                dataHoraAtual: dataHoraAtual,
                dataAtualPostagem: dataAtualPostagem,
                time: timeAtual,
                lineColor: "#00FF7F",
                circleColor: "#00FF7F",
                visivelATodos: 0,
                keyDeQuemAlterouChamado
              })
              .then(() => {
                firebase
                  .database()
                  .ref(`${chaveUnica}/chamados/${keyChamado}`)
                  .update({
                    prioridade: "Urgente", status_prioridade: `${dadosAtendimento.status}Urgente`,
                    status_atendente_prioridade: `${dadosAtendimento.status}${dadosAtendimento.keyAtendente}Urgente`,
                    atendente_prioridade: `${dadosAtendimento.keyAtendente}Urgente`,
                    lastMessageChat: arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado + " Alterou prioridade do atendimento de " + prioridadeAnterior + " para Urgente",
                    lastMessageChatTimeStamp: firebase.database.ServerValue.TIMESTAMP,
                    lastmessageChatCount: 0,
                    lastmessageChatType: 'mensagem',
                    lastMessageLineColor: '#00FF7F'

                  })
                  .then(() => {
                    ativaDesativaLoadingTelaAcoesAtendimento(dispatch, false);
                  });
              });
          });
      }
    });
  };
};
//teste
export const atribuiStatusAtendimento = (
  keyChamado,
  status,
  dataAbertura,
  premium,
  keyAtendente,
  dadosAtendimento,
  viaChat,
  chatEncerradoPorCliente,
  solucaoAtendimento,
  configWhatsapp,
  mensagemFinalizacaoWpp,
  desativarAssistenteVirtualBotWpp2,
  mostrarNomeAtendenteWpp2
) => {
  return dispatch => {

    let mostrarNomeAtendenteWpp = mostrarNomeAtendenteWpp2 === undefined ? true : mostrarNomeAtendenteWpp2;
    let desativarAssistenteVirtualBotWpp = desativarAssistenteVirtualBotWpp2 === undefined ? false : desativarAssistenteVirtualBotWpp2;

    ativaDesativaLoadingTelaAcoesAtendimento(dispatch, true);
    let apiKey = '';
    let urlApi = '';
    let sessionclient = '';
    let statusConnect = '';
    let utilizaVersaoBETA = false;
    if (configWhatsapp !== undefined && configWhatsapp.length > 0) {

      apiKey = configWhatsapp[0].apiKey;
      urlApi = configWhatsapp[0].urlApi;
      sessionclient = configWhatsapp[0].sessionclient;
      statusConnect = configWhatsapp[0].statusConnect;
      utilizaVersaoBETA = configWhatsapp[0].utilizaVersaoBETA;
    }
    const timeAtual = retornaHoraAtual();
    const dataHoraAtual = retornaDataHoraAtual();
    const dataAtualPostagem = retornaDataAtual();

    AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");

      //  alert('antes do primeiro firebase')
      firebase.database().ref(`${chaveUnica}/usuarios/`).orderByChild("uid").equalTo(firebase.auth().currentUser.uid).once("value")
        .then(snapshot => {
          let arrayNomeQuemAbriuChamado = [];
          snapshot.forEach(childItem => {
            arrayNomeQuemAbriuChamado.push({
              nomeDeQuemAbriuChamado: childItem.val().nome
            });
          });

          let AbertoVencimento = dadosAtendimento.dataVencimento.toString() == "Não informado" ? 0 : dadosAtendimento.dataVencimento;
          if (AbertoVencimento !== 0) {
            AbertoVencimento = moment(AbertoVencimento, 'DD/MM/YYYY').unix();
          } else {
            AbertoVencimento = false;
          }
          let AbertoVencimentoAtendente = {};
          let testeObjeto = {};
          let Aberto = 'Fechado';
          AbertoVencimentoAtendente[keyAtendente] = AbertoVencimento;
          testeObjeto[Aberto] = AbertoVencimentoAtendente;
          AbertoVencimento = { Fechado: AbertoVencimento }

          /*    */
          //  alert('depois do primeiro firebase')
          let keyDeQuemAlterouChamado = Object.keys(snapshot.val()).toString();
          if (chatEncerradoPorCliente == true) {
            firebase
              .database()
              .ref(`${chaveUnica}/chamados/${keyChamado}`)
              .update({
                status_atendente_abertoviachat: '',
                channelChamado: '',
              })
          }
          else {

            if (status == "Fechado") {
              //calculo a quantidade de dias para resolver o chamado e populo no banco
              let diferencaDeDias;
              let total = calculaDias2(dataAbertura, dataAtualPostagem);
              switch (total) {
                case 0:
                  diferencaDeDias = "<1";
                  break;
                case 1:
                  diferencaDeDias = "1";
                  break;
                case 2:
                  diferencaDeDias = "2";
                  break;
                case 3:
                  diferencaDeDias = "3";
                  break;
                case 4:
                  diferencaDeDias = "4";
                  break;
                case 5:
                  diferencaDeDias = "5";
                  break;
                case 6:
                  diferencaDeDias = "6";
                  break;
                case 7:
                  diferencaDeDias = "7";
                  break;
                default:
                  diferencaDeDias = ">7";
                  break;
              }

              firebase.database().ref(`${chaveUnica}/acoesChamados/${keyChamado}/`).push({
                tipo: "mensagem",
                /*   titulo: arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado + viaChat === true ? arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado + " encerrou o chat." : arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado + " finalizou o atendimento.",
                  mensagem: `Solução: ${solucaoAtendimento}`, */
                titulo: arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado,
                mensagem: viaChat === true ? arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado + ' encerrou o chat.' : arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado + ' finalizou o atendimento.',
                dataHoraAtual: dataHoraAtual,
                dataAtualPostagem: dataAtualPostagem,
                time: timeAtual,
                lineColor: "#00FF7F",
                circleColor: "#00FF7F",
                visivelATodos: 1,
                keyDeQuemAlterouChamado
              }).then((dadosAcoesChamado) => {
                let keyAcoesChamadoWpp = dadosAcoesChamado.key;

                firebase
                  .database()
                  .ref(`${chaveUnica}/configuracao/avaliacao`)
                  .once("value")
                  .then(snapshot => {
                    const dadosEmpresa = [];
                    snapshot.forEach(childItem => {
                      dadosEmpresa.push({
                        key: childItem.key,
                        mensagemPadrao: childItem.val().mensagemPadrao,
                        utilizaAvaliacao: childItem.val().utilizaAvaliacao
                      });
                    });


                    if (dadosEmpresa[0].utilizaAvaliacao == true && premium == 1) {
                      firebase
                        .database()
                        .ref(`${chaveUnica}/acoesChamados/${keyChamado}/`)
                        .push({
                          tipo: "mensagem",
                          titulo: "Avaliar Este Atendimento",
                          mensagem: dadosAtendimento.chamadoAbertoPorWpp === true && viaChat === true ? `Por favor, digite uma nota entre 0 e 5 para avaliar meu atendimento.\n\n*ATENÇÃO* caso digite qualquer outra mensagem que não seja uma nota de 0 a 5, abrirá um novo atendimento.` : dadosEmpresa[0].mensagemPadrao,
                          dataHoraAtual: dataHoraAtual,
                          dataAtualPostagem: dataAtualPostagem,
                          time: timeAtual,
                          lineColor: "#00FF7F",
                          circleColor: "#00FF7F",
                          visivelATodos: 1,
                          keyDeQuemAlterouChamado
                        })
                        .then(() => {
                          let dadosAtendVinc = { vazio: 'vazio' };
                          firebase
                            .database()
                            .ref(`${chaveUnica}/chamados/${keyChamado}`)
                            .update({
                              status: "Fechado",
                              // usuariosVisualizaAtend: dadosAtendVinc,
                              dataFechamento: dataAtualPostagem,
                              dataHoraFechamento: dataHoraAtual,
                              status_atendente: `Fechado${keyAtendente}`,
                              periodoResolucaoChamado: diferencaDeDias,
                              status_cliente: `Fechado${dadosAtendimento.keyCliente}`, //filtro cliente comum
                              status_setor: `Fechado${dadosAtendimento.keySetor}`, //filtro setor comum
                              status_prioridade: `Fechado${dadosAtendimento.prioridade}`, // filtro prioridade comum
                              status_local: `Fechado${dadosAtendimento.local}`, // filtro local comum
                              status_dataVencimento: AbertoVencimento, // filtro local comum
                              status_numeroChamado: `Fechado_${dadosAtendimento.numeroChamado}`,

                              status_atendente_cliente: `Fechado${keyAtendente}${dadosAtendimento.keyCliente}`, //filtro cliente vê proprio chamado
                              status_atendente_setor: `Fechado${keyAtendente}${dadosAtendimento.keySetor}`, //filtro setor vê proprio chamado
                              status_atendente_prioridade: `Fechado${keyAtendente}${dadosAtendimento.prioridade}`, // filtro prioridade vê proprio chamado
                              status_atendente_local: `Fechado${keyAtendente}${dadosAtendimento.local}`, // filtro local vê proprio chamado
                              status_atendente_dataVencimento: testeObjeto, // filtro local vê proprio chamado
                              status_atendente_numeroChamado: `Fechado_${keyAtendente}${dadosAtendimento.numeroChamado}`,
                              status_atendente_abertoviachat: '',
                              channelChamado: '',
                              solucaoAtendimento: solucaoAtendimento,

                              lastMessageChat: dadosAtendimento.chamadoAbertoPorWpp === true && viaChat === true ? `Por favor, digite uma nota entre 0 e 5 para avaliar meu atendimento.\n\n*ATENÇÃO* caso digite qualquer outra mensagem que não seja uma nota de 0 a 5, abrirá um novo atendimento.` : dadosEmpresa[0].mensagemPadrao,
                              lastMessageChatTimeStamp: firebase.database.ServerValue.TIMESTAMP,
                              lastmessageChatCount: 0,
                              lastmessageChatType: 'mensagem',
                              lastMessageLineColor: '#00FF7F'

                              // telefoneCliente:''
                            })
                            .then(() => {
                              let setorAtendimento = dadosAtendimento.keySetor.toString() == "" ? "Não informado" : dadosAtendimento.keySetor.toString();
                              let atendenteAtendimento = dadosAtendimento.keyAtendente.toString() == "" ? "Não informado" : dadosAtendimento.keyAtendente.toString();
                              let keyAtendimento = dadosAtendimento.key;


                              if (setorAtendimento !== "Não informado") {
                                let keyAtendimento = keyChamado;
                                firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/usuariosVisualizaAtend/`).remove().then(
                                  () => {
                                    //busco clientes vinculados ao setor, se nao tiver eu seto todos os atendentes se tiver seto apenas os atendentes vinculados
                                    firebase.database().ref(`${chaveUnica}/setores/${setorAtendimento}/atendentesVinculados`).once('value').then((snapshotSetor) => {
                                      if (snapshotSetor.val()) {
                                        let dadosAtendVinc = {};

                                        let keyAtendentesVinculados = Object.keys(snapshotSetor.val());

                                        for (let i = 0; i < keyAtendentesVinculados.length; i++) {
                                          if (keyAtendentesVinculados[i].includes('codigoInterno')) {
                                            dadosAtendVinc[keyAtendentesVinculados[i] + "status"] = keyAtendentesVinculados[i] + "Fechado";
                                          }
                                        }


                                        //tem atendentes vinculados ao setor
                                        const dadosAtendentesVinculadosSetor = [];
                                        snapshotSetor.forEach((childItem) => {
                                          dadosAtendentesVinculadosSetor.push({
                                            key: childItem.key,
                                          });
                                        })

                                        let verificoSeAtendenteDoChamadoNaoEstaNoSetorVinculado = 0;
                                        if (atendenteAtendimento !== 'Não informado') {
                                          //verifico se o atendente do chamado nao esta vinculado a este setor
                                          for (let i = 0; i < dadosAtendentesVinculadosSetor.length; i++) {
                                            if (dadosAtendentesVinculadosSetor[i].key == atendenteAtendimento) {
                                              verificoSeAtendenteDoChamadoNaoEstaNoSetorVinculado = 1;

                                            }
                                          }
                                          //atendente nao esta no grupo porem foi vinculado ao atendimento, coloco ele nos vinculados do chamado
                                          if (verificoSeAtendenteDoChamadoNaoEstaNoSetorVinculado == 0) {
                                            let objetocadastro = {};
                                            firebase.database().ref(`${chaveUnica}/usuarios/${atendenteAtendimento}`).once("value").then((snapshot) => {
                                              let key = snapshot.key;
                                              let codigoInterno = snapshot.val().codigoInterno;
                                              // objetocadastro[codigoInterno] = codigoInterno;
                                              objetocadastro[codigoInterno + "status"] = codigoInterno + "Fechado";


                                              firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/usuariosVisualizaAtend/dadosAtendVinc`).update(objetocadastro)
                                            })

                                          } else {
                                            //creio que ja cai na situaçaão acima
                                          }
                                        }

                                        firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/usuariosVisualizaAtend`).update({ dadosAtendVinc })
                                      } else {
                                        //
                                        firebase.database().ref(`${chaveUnica}/usuarios/`).orderByChild('excluido').equalTo(null).once('value').then((snapshot) => {
                                          const idTodosAtendentes = [];
                                          let objetocadastro = {};
                                          snapshot.forEach((childItem) => {
                                            idTodosAtendentes.push({
                                              key: childItem.key,
                                              codigoInterno: childItem.val().codigoInterno == undefined ? 'codigoInterno1' : childItem.val().codigoInterno,
                                            });
                                          })
                                          //
                                          for (let i = 0; i < idTodosAtendentes.length; i++) {

                                            objetocadastro[idTodosAtendentes[i].codigoInterno + "status"] = idTodosAtendentes[i].codigoInterno + "Fechado";

                                          }
                                          //
                                          firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/usuariosVisualizaAtend/dadosAtendVinc`).update(objetocadastro)
                                        })

                                      }
                                    })
                                  })
                              } else if (atendenteAtendimento !== "Não informado") {
                                let objetocadastro = {};
                                firebase.database().ref(`${chaveUnica}/usuarios/${atendenteAtendimento}`).once("value").then((snapshot) => {
                                  let key = snapshot.key;
                                  let codigoInterno = snapshot.val().codigoInterno;
                                  // objetocadastro[codigoInterno] = codigoInterno;
                                  objetocadastro[codigoInterno + "status"] = codigoInterno + "Fechado";



                                  //
                                  // firebase.database().ref(`${chaveUnica}/dadosAtendVinc/${key}/${dadosChamado.key}`).push({ true: 'true' });
                                  firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/usuariosVisualizaAtend/`).remove().then(
                                    () => {
                                      firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/usuariosVisualizaAtend/dadosAtendVinc`).update(objetocadastro)
                                    })
                                  //  firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/usuariosVisualizaAtend/dadosAtendVinc`).update(objetocadastro)
                                })
                              } else {
                                let dadosAtendVinc = { vazio: 'vazio' };
                                firebase
                                  .database()
                                  .ref(`${chaveUnica}/chamados/${keyAtendimento}`)
                                  .update({ usuariosVisualizaAtend: dadosAtendVinc })
                              }
                              ativaDesativaLoadingTelaAcoesAtendimento(
                                dispatch,
                                false
                              );
                              firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}/`).update({
                                lastmessageChatStatus: dadosAtendimento.chamadoAbertoPorWpp === true ? 'waiting' : "sent",
                                lastMessageChat: viaChat === true ? arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado + ' encerrou o chat.' : arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado + ' finalizou o atendimento.',
                                lastMessageChatTimeStamp: firebase.database.ServerValue.TIMESTAMP,
                                lastmessageChatCount: 0,
                                lastmessageChatType: 'mensagem',
                                lastMessageLineColor: '#00FF7F'
                              })
                              if (viaChat === true && dadosAtendimento.chamadoAbertoPorWpp === true && desativarAssistenteVirtualBotWpp === false) {
                                let data = {
                                  numeroCliente: dadosAtendimento.telefoneCliente,
                                  text: `Atendimento Finalizado com sucesso.`,
                                  apiKey: `${apiKey}`,
                                  session: `${sessionclient}`,
                                  urlApi: urlApi,
                                  type: 'text',
                                  keyAcoesChamado: keyAcoesChamadoWpp,
                                  keyChamado: keyChamado,
                                  chaveUnica: chaveUnica,
                                  file: null,
                                  responder: '',
                                  idMensagemResponder: '',
                                  nomeAtendente: arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado,
                                  mostrarNomeAtendenteWpp: mostrarNomeAtendenteWpp,
                                  utilizaVersaoBETA
                                }
                                const options = {
                                  method: 'POST',
                                  headers: {
                                    'Content-Type': 'application/json; charset=utf-8',
                                    'Accept': 'application/json',
                                  },

                                  data: data,
                                  url: 'enviaMensagemWhatsappSocket',
                                };
                                api(options).then((response) => {

                                  firebase.database().ref(`${chaveUnica}/acoesChamados/${keyChamado}/${keyAcoesChamadoWpp}`).update({ statusMessage: 'sent' }).then(() => {
                                    firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}/`).update({ lastmessageChatStatus: 'sent', lastMessageLineColor: '#00FF7F' }).then(
                                      () => {

                                        let data = {
                                          numeroCliente: dadosAtendimento.telefoneCliente,
                                          text: `Por favor, digite uma nota entre 0 e 5 para avaliar meu atendimento.\n\n*ATENÇÃO* caso digite qualquer outra mensagem que não seja uma nota de 0 a 5, abrirá um novo atendimento.`,
                                          apiKey: `${apiKey}`,
                                          session: `${sessionclient}`,
                                          urlApi: urlApi,
                                          type: 'text',
                                          keyAcoesChamado: keyAcoesChamadoWpp,
                                          keyChamado: keyChamado,
                                          chaveUnica: chaveUnica,
                                          file: null,
                                          responder: '',
                                          idMensagemResponder: '',
                                          nomeAtendente: arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado,
                                          mostrarNomeAtendenteWpp: mostrarNomeAtendenteWpp,
                                          utilizaVersaoBETA
                                        }
                                        const options = {
                                          method: 'POST',
                                          headers: {
                                            'Content-Type': 'application/json; charset=utf-8',
                                            'Accept': 'application/json',
                                          },

                                          data: data,
                                          url: 'enviaMensagemWhatsappSocket',
                                        };
                                        api(options).then((response) => {

                                          firebase.database().ref(`${chaveUnica}/acoesChamados/${keyChamado}/${keyAcoesChamadoWpp}`)
                                            .update({ statusMessage: 'sent' }).then(() => {
                                              firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}/`).update({ lastmessageChatStatus: 'sent', lastMessageLineColor: '#00FF7F' }).then(() => {
                                                firebase.database().ref(`${chaveUnica}/clientes/${dadosAtendimento.keyCliente}`)
                                                  .update({ statusAndamento: 5 });
                                              });
                                            })
                                        }).catch((err) => {


                                          if (err.response) {


                                            if (err.response.status === 400) {
                                              toastr.error('Telefone Desconectado', 'Seu Telefone está sem Conexão com a Internet, verifique a conexão do seu Aparelho e tente novamente.')
                                            } else {

                                            }
                                          } else {

                                            toastr.error('Telefone Desconectado', 'Seu Telefone está sem Conexão com a Internet, verifique a conexão do seu Aparelho e tente novamente.')
                                          }
                                        })
                                      });
                                  });
                                }).catch((err) => {


                                  if (err.response) {


                                    if (err.response.status === 400) {
                                      toastr.error('Telefone Desconectado', 'Seu Telefone está sem Conexão com a Internet, verifique a conexão do seu Aparelho e tente novamente.')
                                    } else {

                                    }
                                  } else {

                                    toastr.error('Telefone Desconectado', 'Seu Telefone está sem Conexão com a Internet, verifique a conexão do seu Aparelho e tente novamente.')
                                  }
                                })
                              }

                            });
                        });
                    } else {
                      let dadosAtendVinc = { vazio: 'vazio' };
                      firebase
                        .database()
                        .ref(`${chaveUnica}/chamados/${keyChamado}`)
                        .update({
                          status: "Fechado",
                          // usuariosVisualizaAtend: dadosAtendVinc,
                          dataFechamento: dataAtualPostagem,
                          dataHoraFechamento: dataHoraAtual,
                          status_atendente: `Fechado${keyAtendente}`,
                          periodoResolucaoChamado: diferencaDeDias,
                          status_cliente: `Fechado${dadosAtendimento.keyCliente}`, //filtro cliente comum
                          status_setor: `Fechado${dadosAtendimento.keySetor}`, //filtro setor comum
                          status_prioridade: `Fechado${dadosAtendimento.prioridade}`, // filtro prioridade comum
                          status_local: `Fechado${dadosAtendimento.local}`, // filtro local comum
                          status_dataVencimento: AbertoVencimento, // filtro local comum
                          status_numeroChamado: `Fechado_${dadosAtendimento.numeroChamado}`,

                          status_atendente_cliente: `Fechado${keyAtendente}${dadosAtendimento.keyCliente}`, //filtro cliente vê proprio chamado
                          status_atendente_setor: `Fechado${keyAtendente}${dadosAtendimento.keySetor}`, //filtro setor vê proprio chamado
                          status_atendente_prioridade: `Fechado${keyAtendente}${dadosAtendimento.prioridade}`, // filtro prioridade vê proprio chamado
                          status_atendente_local: `Fechado${keyAtendente}${dadosAtendimento.local}`, // filtro local vê proprio chamado
                          status_atendente_dataVencimento: testeObjeto, // filtro local vê proprio chamado
                          status_atendente_numeroChamado: `Fechado_${keyAtendente}${dadosAtendimento.numeroChamado}`,
                          status_atendente_abertoviachat: '',
                          channelChamado: '',
                          solucaoAtendimento: solucaoAtendimento,
                          lastMessageChat: viaChat === true ? arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado + ' encerrou o chat.' : arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado + ' finalizou o atendimento.',
                          lastMessageChatTimeStamp: firebase.database.ServerValue.TIMESTAMP,
                          lastmessageChatCount: 0,
                          lastmessageChatType: 'mensagem',
                          lastMessageLineColor: '#00FF7F'
                          //  telefoneCliente:''
                        })
                        .then(() => {
                          let setorAtendimento = dadosAtendimento.keySetor.toString() == "" ? "Não informado" : dadosAtendimento.keySetor.toString();
                          let atendenteAtendimento = dadosAtendimento.keyAtendente.toString() == "" ? "Não informado" : dadosAtendimento.keyAtendente.toString();
                          let keyAtendimento = dadosAtendimento.key;
                          if (setorAtendimento !== "Não informado") {
                            firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/usuariosVisualizaAtend/`).remove().then(
                              () => {
                                //busco clientes vinculados ao setor, se nao tiver eu seto todos os atendentes se tiver seto apenas os atendentes vinculados
                                firebase.database().ref(`${chaveUnica}/setores/${setorAtendimento}/atendentesVinculados`).once('value').then((snapshotSetor) => {
                                  if (snapshotSetor.val()) {
                                    let dadosAtendVinc = {};

                                    let keyAtendentesVinculados = Object.keys(snapshotSetor.val());

                                    for (let i = 0; i < keyAtendentesVinculados.length; i++) {
                                      if (keyAtendentesVinculados[i].includes('codigoInterno')) {
                                        dadosAtendVinc[keyAtendentesVinculados[i] + "status"] = keyAtendentesVinculados[i] + "Fechado";
                                      }
                                    }


                                    //tem atendentes vinculados ao setor
                                    const dadosAtendentesVinculadosSetor = [];
                                    snapshotSetor.forEach((childItem) => {
                                      dadosAtendentesVinculadosSetor.push({
                                        key: childItem.key,
                                      });
                                    })

                                    let verificoSeAtendenteDoChamadoNaoEstaNoSetorVinculado = 0;
                                    if (atendenteAtendimento !== 'Não informado') {
                                      //verifico se o atendente do chamado nao esta vinculado a este setor
                                      for (let i = 0; i < dadosAtendentesVinculadosSetor.length; i++) {
                                        if (dadosAtendentesVinculadosSetor[i].key == atendenteAtendimento) {
                                          verificoSeAtendenteDoChamadoNaoEstaNoSetorVinculado = 1;

                                        }
                                      }
                                      //atendente nao esta no grupo porem foi vinculado ao atendimento, coloco ele nos vinculados do chamado
                                      if (verificoSeAtendenteDoChamadoNaoEstaNoSetorVinculado == 0) {
                                        let objetocadastro = {};
                                        firebase.database().ref(`${chaveUnica}/usuarios/${atendenteAtendimento}`).once("value").then((snapshot) => {
                                          let key = snapshot.key;
                                          let codigoInterno = snapshot.val().codigoInterno;
                                          //  objetocadastro[codigoInterno] = codigoInterno;
                                          objetocadastro[codigoInterno + "status"] = codigoInterno + "Fechado";

                                          firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/usuariosVisualizaAtend/dadosAtendVinc`).update(objetocadastro)
                                        })

                                      } else {
                                        //creio que ja cai na situaçaão acima
                                      }
                                    }

                                    firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/usuariosVisualizaAtend`).update({ dadosAtendVinc })
                                  } else {
                                    //
                                    firebase.database().ref(`${chaveUnica}/usuarios/`).orderByChild('excluido').equalTo(null).once('value').then((snapshot) => {
                                      const idTodosAtendentes = [];
                                      let objetocadastro = {};
                                      snapshot.forEach((childItem) => {
                                        idTodosAtendentes.push({
                                          key: childItem.key,
                                          codigoInterno: childItem.val().codigoInterno == undefined ? 'codigoInterno1' : childItem.val().codigoInterno,
                                        });
                                      })
                                      //
                                      for (let i = 0; i < idTodosAtendentes.length; i++) {
                                        objetocadastro[idTodosAtendentes[i].codigoInterno + "status"] = idTodosAtendentes[i].codigoInterno + "Fechado";

                                      }
                                      //
                                      firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/usuariosVisualizaAtend/dadosAtendVinc`).update(objetocadastro)
                                    })

                                  }
                                })
                              })
                          } else if (atendenteAtendimento !== "Não informado") {
                            let objetocadastro = {};
                            firebase.database().ref(`${chaveUnica}/usuarios/${atendenteAtendimento}`).once("value").then((snapshot) => {
                              let key = snapshot.key;
                              let codigoInterno = snapshot.val().codigoInterno;
                              //  objetocadastro[codigoInterno] = codigoInterno;
                              objetocadastro[codigoInterno + "status"] = codigoInterno + "Fechado";


                              //
                              // firebase.database().ref(`${chaveUnica}/dadosAtendVinc/${key}/${dadosChamado.key}`).push({ true: 'true' });
                              firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/usuariosVisualizaAtend/`).remove().then(
                                () => {
                                  firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/usuariosVisualizaAtend/dadosAtendVinc`).update(objetocadastro)
                                })

                            })
                          } else {
                            let dadosAtendVinc = { vazio: 'vazio' };
                            firebase
                              .database()
                              .ref(`${chaveUnica}/chamados/${keyAtendimento}`)
                              .update({ usuariosVisualizaAtend: dadosAtendVinc })
                          }
                          ativaDesativaLoadingTelaAcoesAtendimento(
                            dispatch,
                            false
                          );
                          firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}/`).update({
                            lastmessageChatStatus: dadosAtendimento.chamadoAbertoPorWpp === true ? 'waiting' : "sent",
                            lastMessageChat: viaChat === true ? arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado + ' encerrou o chat.' : arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado + ' finalizou o atendimento.',
                            lastMessageChatTimeStamp: firebase.database.ServerValue.TIMESTAMP,
                            lastmessageChatCount: 0,
                            lastmessageChatType: 'mensagem',
                            lastMessageLineColor: '#00FF7F'
                          })
                          if (viaChat === true && dadosAtendimento.chamadoAbertoPorWpp === true && desativarAssistenteVirtualBotWpp === false) {
                            let data = {
                              numeroCliente: dadosAtendimento.telefoneCliente,
                              text: `${mensagemFinalizacaoWpp.replace(/{{protocolo}}/g, dadosAtendimento.numeroChamado)}`,
                              apiKey: `${apiKey}`,
                              session: `${sessionclient}`,
                              urlApi: urlApi,
                              type: 'text',
                              keyAcoesChamado: keyAcoesChamadoWpp,
                              keyChamado: keyChamado,
                              chaveUnica: chaveUnica,
                              file: null,
                              responder: '',
                              idMensagemResponder: '',
                              nomeAtendente: arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado,
                              mostrarNomeAtendenteWpp: mostrarNomeAtendenteWpp,
                              utilizaVersaoBETA
                            }
                            const options = {
                              method: 'POST',
                              headers: {
                                'Content-Type': 'application/json; charset=utf-8',
                                'Accept': 'application/json',
                              },

                              data: data,
                              url: 'enviaMensagemWhatsappSocket',
                            };
                            api(options).then((response) => {

                              firebase.database().ref(`${chaveUnica}/acoesChamados/${keyChamado}/${keyAcoesChamadoWpp}`).update({ statusMessage: 'sent' }).then(() => {
                                firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}/`).update({ lastmessageChatStatus: 'sent', lastMessageLineColor: '#00FF7F' });
                              })
                            }).catch((err) => {


                              if (err.response) {


                                if (err.response.status === 400) {
                                  toastr.error('Telefone Desconectado', 'Seu Telefone está sem Conexão com a Internet, verifique a conexão do seu Aparelho e tente novamente.')
                                } else {

                                }
                              } else {

                                toastr.error('Telefone Desconectado', 'Seu Telefone está sem Conexão com a Internet, verifique a conexão do seu Aparelho e tente novamente.')
                              }
                            })
                          }

                        });
                    }

                  });

              });
            } else {
              firebase
                .database()
                .ref(`${chaveUnica}/acoesChamados/${keyChamado}/`)
                .push({
                  tipo: "mensagem",
                  titulo:
                    arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado,
                  mensagem:
                    arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado +
                    " está aguardando o cliente responder.",
                  dataHoraAtual: dataHoraAtual,
                  dataAtualPostagem: dataAtualPostagem,
                  time: timeAtual,
                  lineColor: "#00FF7F",
                  circleColor: "#00FF7F",
                  visivelATodos: 1,
                  keyDeQuemAlterouChamado
                })
                .then(() => {
                  ativaDesativaLoadingTelaAcoesAtendimento(dispatch, false);
                });
            }
          }
        });
    });
  };
};
export const atribuirTempoEsperaAoChamado = (keyChamado, tempo2) => {
  return dispatch => {
    ativaDesativaLoadingTelaAcoesAtendimento(dispatch, true);
    let tempo = tempo2;
    let tempoBancoDados;
    switch (tempo) {
      case "+5m":
        tempo = "5 minutos";
        tempoBancoDados = 5;
        break;
      case "+10m":
        tempo = "10 minutos";
        tempoBancoDados = 10;
        break;
      case "+15m":
        tempo = "15 minutos";
        tempoBancoDados = 15;
        break;
      case "+20m":
        tempo = "20 minutos";
        tempoBancoDados = 20;
        break;
      case "+25m":
        tempo = "25 minutos";
        tempoBancoDados = 25;
        break;
      case "+30m":
        tempo = "30 minutos";
        tempoBancoDados = 30;
        break;
      case "+35m":
        tempo = "35 minutos";
        tempoBancoDados = 35;
        break;
      case "+40m":
        tempo = "40 minutos";
        tempoBancoDados = 40;
        break;
      case "+45m":
        tempo = "45 minutos";
        tempoBancoDados = 45;
        break;
      case "+50m":
        tempo = "50 minutos";
        tempoBancoDados = 50;
        break;
      case "+55m":
        tempo = "55 minutos";
        tempoBancoDados = 55;
        break;
      case "+1h":
        tempo = "1 hora";
        tempoBancoDados = 60;
        break;
      case "+1h 15m":
        tempo = "1 hora e 15 minutos";
        tempoBancoDados = 75;
        break;
      case "+1h 30m":
        tempo = "1 hora e 30 minutos";
        tempoBancoDados = 90;
        break;
      case "+1h 45m":
        tempo = "1 hora e 45 minutos";
        tempoBancoDados = 105;
        break;
      case "+2h":
        tempo = "2 horas";
        tempoBancoDados = 120;
        break;
      case "+2h 30m":
        tempo = "2 horas e 30 minutos";
        tempoBancoDados = 150;
        break;
      case "+3h":
        tempo = "3 horas";
        tempoBancoDados = 180;
        break;
      case "+3h 30m":
        tempo = "3 horas e 30 minutos";
        tempoBancoDados = 210;
        break;
      case "+4h":
        tempo = "4 horas";
        tempoBancoDados = 240;
        break;
      case "+6h":
        tempo = "6 horas";
        tempoBancoDados = 360;
        break;
      case "+8h":
        tempo = "8 horas";
        tempoBancoDados = 480;
        break;
      case "+10h":
        tempo = "10 horas";
        tempoBancoDados = 600;
        break;
      case "+12h":
        tempo = "12 horas";
        tempoBancoDados = 720;
        break;
      case "+14h":
        tempo = "14 horas";
        tempoBancoDados = 840;
        break;
      case "+16h":
        tempo = "16 horas";
        tempoBancoDados = 960;
        break;
      case "+20h":
        tempo = "20 horas";
        tempoBancoDados = 1200;
        break;
      case "+24h":
        tempo = "24 horas";
        tempoBancoDados = 1440;
        break;

      default:
    }
    const timeAtual = retornaHoraAtual();
    const dataHoraAtual = retornaDataHoraAtual();
    const dataAtualPostagem = retornaDataAtual();

    AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");
      firebase
        .database()
        .ref(`${chaveUnica}/usuarios/`)
        .orderByChild("uid")
        .equalTo(firebase.auth().currentUser.uid)
        .once("value")
        .then(snapshot => {
          let arrayNomeQuemAbriuChamado = [];
          snapshot.forEach(childItem => {
            arrayNomeQuemAbriuChamado.push({
              nomeDeQuemAbriuChamado: childItem.val().nome
            });
          });
          let keyDeQuemAlterouChamado = Object.keys(snapshot.val()).toString();

          firebase
            .database()
            .ref(`${chaveUnica}/acoesChamados/${keyChamado}/`)
            .push({
              tipo: "mensagem",
              titulo:
                arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado,
              mensagem: " trabalhei neste chamado por " + tempo,
              dataHoraAtual: dataHoraAtual,
              dataAtualPostagem: dataAtualPostagem,
              time: timeAtual,
              lineColor: "#00FF7F",
              circleColor: "#00FF7F",
              visivelATodos: 1,
              keyDeQuemAlterouChamado
            })
            .then(() => {
              firebase
                .database()
                .ref(`${chaveUnica}/chamados/${keyChamado}`)
                .once("value")
                .then(snapshot => {
                  tempoBancoDados = tempoBancoDados + snapshot.val().tempoGasto;
                  firebase
                    .database()
                    .ref(`${chaveUnica}/chamados/${keyChamado}`)
                    .update({
                      tempoGasto: tempoBancoDados,
                      lastMessageChat: " trabalhei neste chamado por " + tempo,
                      lastMessageChatTimeStamp: firebase.database.ServerValue.TIMESTAMP,
                      lastmessageChatCount: 0,
                      lastmessageChatType: 'mensagem',
                      lastMessageLineColor: '#00FF7F'

                    })
                    .then(() => {
                      ativaDesativaLoadingTelaAcoesAtendimento(dispatch, false);
                    });
                });
            });
        });
    });
  };
};

export const atribuiAtendimentoAh = (
  keyAtendente,
  keyChamado,
  nomeAtendenteQueIraSerAtribuido,
  setorAtendimento,
  dadosAtendimento,
  viaChat,
  configWhatsapp,
  comentarioTransferencia,
  desativarAssistenteVirtualBotWpp2,
  mostrarNomeAtendenteWpp2
) => {
  return dispatch => {
    let mostrarNomeAtendenteWpp = mostrarNomeAtendenteWpp2 === undefined ? true : mostrarNomeAtendenteWpp2;
    let desativarAssistenteVirtualBotWpp = desativarAssistenteVirtualBotWpp2 === undefined ? false : desativarAssistenteVirtualBotWpp2;

    let comentarioTransf = comentarioTransferencia === undefined ? '' : comentarioTransferencia;
    setorAtendimento = setorAtendimento === '' ? "Não informado" : setorAtendimento;
    dadosAtendimento.keySetor = setorAtendimento !== 'Não informado' ? setorAtendimento : dadosAtendimento.keySetor;

    let apiKey = '';
    let urlApi = '';
    let sessionclient = '';
    let statusConnect = '';
    let utilizaVersaoBETA = false;
    if (configWhatsapp !== undefined && configWhatsapp.length > 0) {

      apiKey = configWhatsapp[0].apiKey;
      urlApi = configWhatsapp[0].urlApi;
      sessionclient = configWhatsapp[0].sessionclient;
      statusConnect = configWhatsapp[0].statusConnect;
      utilizaVersaoBETA = configWhatsapp[0].utilizaVersaoBETA;
    }
    let keyAtendimento = keyChamado;
    let insereTempoPrimeiraResposta = dadosAtendimento.status == 'Aberto' ? true : false;
    let tempoPrimeiraResposta = 0;

    if (insereTempoPrimeiraResposta) {
      let dataAtual = moment().toDate();
      dataAtual = moment(dataAtual).format('DD/MM/YYYY HH:mm');
      let dataAberturaChamado = dadosAtendimento.dataHoraAbertura;
      let diferencaValor = calculaDiferencaEntreDatasEmSegundos(dataAberturaChamado, dataAtual)



      tempoPrimeiraResposta = diferencaValor;
    }

    /*    */
    let atendenteAtendimento = keyAtendente;
    ativaDesativaLoadingTelaAcoesAtendimento(dispatch, true);
    const timeAtual = retornaHoraAtual();
    const dataHoraAtual = retornaDataHoraAtual();
    const dataAtualPostagem = retornaDataAtual();
    let dataVencimentoUnix = '';

    AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");

      firebase.database().ref(`${chaveUnica}/usuarios/`).orderByChild("uid").equalTo(firebase.auth().currentUser.uid).once("value").then(snapshot => {
        let arrayNomeQuemAbriuChamado = [];
        snapshot.forEach(childItem => {
          arrayNomeQuemAbriuChamado.push({
            nomeDeQuemAbriuChamado: childItem.val().nome
          });
        });

        let keyDeQuemAlterouChamado = Object.keys(snapshot.val()).toString();
        let keyAtendenteQueAlterouChamado = Object.keys(snapshot.val()).toString();

        let mensagem = '';
        if (viaChat == true) {
          if (keyDeQuemAlterouChamado !== keyAtendente) {
            if (comentarioTransf !== '') {
              mensagem = `Atendimento atribuido ao atendente ${nomeAtendenteQueIraSerAtribuido}\n\nComentário: ${comentarioTransf}`;
              // mensagem = "Atendimento atribuido ao atendente" + nomeAtendenteQueIraSerAtribuido + '\n' + `Comentário: ${comentarioTransf}`;
            } else {
              mensagem = "Atendimento atribuido ao atendente " + nomeAtendenteQueIraSerAtribuido;
            }

          } else {
            mensagem = `${nomeAtendenteQueIraSerAtribuido} entrou no Chat`;
          }
        } else {
          mensagem = "Atendimento atribuido ao atendente " + nomeAtendenteQueIraSerAtribuido;
        }

        //novos dados a ser atualizados
        let AbertoVencimento = dadosAtendimento.dataVencimento.toString() == "Não informado" ? 0 : dadosAtendimento.dataVencimento;
        if (AbertoVencimento !== 0) {
          AbertoVencimento = moment(AbertoVencimento, 'DD/MM/YYYY').unix();
          dataVencimentoUnix = AbertoVencimento;
        } else {
          AbertoVencimento = false;
        }
        let AbertoVencimentoAtendente = {};
        let testeObjeto = {};
        //ADICIONAR QUANDO FOR IMPLEMENTAR EM DROPDESKADMIN62
        if (viaChat === true || dadosAtendimento.chamadoAbertoPorWpp === true) {
          firebase.database().ref(`${chaveUnica}/clientes/${dadosAtendimento.keyCliente}`).update({
            statusAndamento: 3
          })
        }
        let Aberto = 'Atendendo';
        AbertoVencimentoAtendente[atendenteAtendimento] = AbertoVencimento;
        testeObjeto[Aberto] = AbertoVencimentoAtendente;
        AbertoVencimento = { Atendendo: AbertoVencimento }

        /*   */
        //

        //let mensagem = viaChat == true ? keyDeQuemAlterouChamado !== keyAtendente ? "Atendimento atribuido ao atendente" + nomeAtendenteQueIraSerAtribuido : `${nomeAtendenteQueIraSerAtribuido} entrou no Chat` : "Atendimento atribuido ao atendente " + nomeAtendenteQueIraSerAtribuido;
        firebase.database().ref(`${chaveUnica}/acoesChamados/${keyChamado}/`).push({
          tipo: "mensagem",
          titulo: arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado,
          mensagem: mensagem,
          dataHoraAtual: dataHoraAtual,
          dataAtualPostagem: dataAtualPostagem,
          time: timeAtual,
          lineColor: "#00FF7F",
          circleColor: "#00FF7F",
          visivelATodos: 1,
          keyDeQuemAlterouChamado
        })
          .then((dadosAcoesChamado) => {
            let keyAcoesChamadoWpp = dadosAcoesChamado.key;
            let dadosAtendVinc = { vazio: 'vazio' };
            //condicional para eu fazer o update do tempo de primeira resposta
            if (insereTempoPrimeiraResposta) {
              firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}`).update({
                atendente: keyAtendente,
                status: "Atendendo",
                status_atendente: `Atendendo${keyAtendente}`,
                // usuariosVisualizaAtend: dadosAtendVinc,
                keyAtendenteQueAlterouChamado: keyAtendenteQueAlterouChamado,

                status_cliente: `Atendendo${dadosAtendimento.keyCliente}`, //filtro cliente comum
                status_setor: `Atendendo${dadosAtendimento.keySetor}`, //filtro setor comum
                status_prioridade: `Atendendo${dadosAtendimento.prioridade}`, // filtro prioridade comum
                status_local: `Atendendo${dadosAtendimento.local}`, // filtro local comum
                status_dataVencimento: AbertoVencimento, // filtro local comum
                status_numeroChamado: `Atendendo_${dadosAtendimento.numeroChamado}`,

                status_atendente_cliente: `Atendendo${keyAtendente}${dadosAtendimento.keyCliente}`, //filtro cliente vê proprio chamado
                status_atendente_setor: `Atendendo${keyAtendente}${dadosAtendimento.keySetor}`, //filtro setor vê proprio chamado
                status_atendente_prioridade: `Atendendo${keyAtendente}${dadosAtendimento.prioridade}`, // filtro prioridade vê proprio chamado
                status_atendente_local: `Atendendo${keyAtendente}${dadosAtendimento.local}`, // filtro local vê proprio chamado
                status_atendente_dataVencimento: testeObjeto, // filtro local vê proprio chamado
                status_atendente_numeroChamado: `Atendendo_${keyAtendente}${dadosAtendimento.numeroChamado}`,

                atendente_cliente: `${keyAtendente}${dadosAtendimento.keyCliente}`,
                atendente_setor: `${keyAtendente}${dadosAtendimento.keySetor}`,
                atendente_prioridade: `${keyAtendente}${dadosAtendimento.prioridade}`,
                atendente_local: `${keyAtendente}${dadosAtendimento.local}`,
                atendente_dataVencimento: AbertoVencimentoAtendente,
                atendente_numeroChamado: `${keyAtendente}${dadosAtendimento.numeroChamado}`,
                dataVencimentoUnix: dataVencimentoUnix,
                status_atendente_abertoviachat: viaChat == true || dadosAtendimento.chamadoAbertoPorWpp === true ? `Atendendo${atendenteAtendimento}true` : '',
                tempoPrimeiraResposta,
                setor: dadosAtendimento.keySetor,
                lastMessageChat: mensagem,
                lastMessageChatTimeStamp: firebase.database.ServerValue.TIMESTAMP,
                lastmessageChatCount: 1,
                lastmessageChatType: 'mensagem',
                lastMessageLineColor: '#00FF7F'


              })
                .then(() => {
                  if (setorAtendimento !== "Não informado") {
                    firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/usuariosVisualizaAtend/`).remove().then(
                      () => {
                        //busco clientes vinculados ao setor, se nao tiver eu seto todos os atendentes se tiver seto apenas os atendentes vinculados
                        firebase.database().ref(`${chaveUnica}/setores/${setorAtendimento}/atendentesVinculados`).once('value').then((snapshotSetor) => {
                          if (snapshotSetor.val()) {
                            let dadosAtendVinc = {};

                            let keyAtendentesVinculados = Object.keys(snapshotSetor.val());

                            for (let i = 0; i < keyAtendentesVinculados.length; i++) {
                              if (keyAtendentesVinculados[i].includes('codigoInterno')) {
                                dadosAtendVinc[keyAtendentesVinculados[i] + "status"] = keyAtendentesVinculados[i] + "Atendendo";
                              }
                            }


                            //tem atendentes vinculados ao setor
                            const dadosAtendentesVinculadosSetor = [];
                            snapshotSetor.forEach((childItem) => {
                              dadosAtendentesVinculadosSetor.push({
                                key: childItem.key,
                              });
                            })

                            let verificoSeAtendenteDoChamadoNaoEstaNoSetorVinculado = 0;
                            if (atendenteAtendimento !== 'Não informado') {
                              //verifico se o atendente do chamado nao esta vinculado a este setor
                              for (let i = 0; i < dadosAtendentesVinculadosSetor.length; i++) {
                                if (dadosAtendentesVinculadosSetor[i].key == atendenteAtendimento) {
                                  verificoSeAtendenteDoChamadoNaoEstaNoSetorVinculado = 1;

                                }
                              }
                              //atendente nao esta no grupo porem foi vinculado ao atendimento, coloco ele nos vinculados do chamado


                              if (verificoSeAtendenteDoChamadoNaoEstaNoSetorVinculado == 0) {
                                let objetocadastro = {};
                                firebase.database().ref(`${chaveUnica}/usuarios/${atendenteAtendimento}`).once("value").then((snapshot) => {
                                  let key = snapshot.key;
                                  let codigoInterno = snapshot.val().codigoInterno;
                                  // objetocadastro[codigoInterno] = codigoInterno;
                                  objetocadastro[codigoInterno + "status"] = codigoInterno + "Atendendo";

                                  firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/usuariosVisualizaAtend/dadosAtendVinc`).update(objetocadastro)
                                })

                              } else {
                                //creio que ja cai na situaçaão acima
                              }
                            }

                            firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/usuariosVisualizaAtend`).update({ dadosAtendVinc })
                          } else {
                            //
                            firebase.database().ref(`${chaveUnica}/usuarios/`).orderByChild('excluido').equalTo(null).once('value').then((snapshot) => {
                              const idTodosAtendentes = [];
                              let objetocadastro = {};
                              snapshot.forEach((childItem) => {
                                idTodosAtendentes.push({
                                  key: childItem.key,
                                  codigoInterno: childItem.val().codigoInterno == undefined ? 'codigoInterno1' : childItem.val().codigoInterno,
                                });
                              })
                              //
                              for (let i = 0; i < idTodosAtendentes.length; i++) {
                                objetocadastro[idTodosAtendentes[i].codigoInterno + "status"] = idTodosAtendentes[i].codigoInterno + "Atendendo";

                              }
                              //
                              firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/usuariosVisualizaAtend/dadosAtendVinc`).update(objetocadastro)
                            })

                          }
                        })
                      })
                  } else if (atendenteAtendimento !== "Não informado") {
                    let objetocadastro = {};
                    firebase.database().ref(`${chaveUnica}/usuarios/${atendenteAtendimento}`).once("value").then((snapshot) => {
                      let key = snapshot.key;
                      let codigoInterno = snapshot.val().codigoInterno;
                      //  objetocadastro[codigoInterno] = codigoInterno;
                      objetocadastro[codigoInterno + "status"] = codigoInterno + "Atendendo";


                      //
                      // firebase.database().ref(`${chaveUnica}/dadosAtendVinc/${key}/${dadosChamado.key}`).push({ true: 'true' });
                      firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/usuariosVisualizaAtend/`).remove().then(
                        () => {
                          firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/usuariosVisualizaAtend/dadosAtendVinc`).update(objetocadastro)
                        })
                      // firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/usuariosVisualizaAtend/dadosAtendVinc`).update(objetocadastro)
                    })
                  } else {
                    let dadosAtendVinc = { vazio: 'vazio' };
                    firebase
                      .database()
                      .ref(`${chaveUnica}/chamados/${keyAtendimento}`)
                      .update({ usuariosVisualizaAtend: dadosAtendVinc })
                  }
                  dispatch({
                    type: MODIFICAKEYATENDIMENTOEMESPERAATENDIMENTOONLINE,
                    payload: ''
                  })
                  dispatch({
                    type: MODIFICAKEYCONTACTS,
                    payload: ''
                  })
                  dispatch({
                    type: MODIFICAKEYATENDIMENTOEMTENDIMENTOONLINE,
                    payload: keyAtendimento
                  })
                  ativaDesativaLoadingIniciandoCOnversaContacts(dispatch, false);
                  ativaDesativaLoadingTelaAcoesAtendimento(dispatch, false);
                  firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}/`).update({
                    lastmessageChatStatus: dadosAtendimento.chamadoAbertoPorWpp === true ? 'waiting' : "sent",
                    lastMessageChat: mensagem,
                    lastMessageChatTimeStamp: firebase.database.ServerValue.TIMESTAMP,
                    lastmessageChatCount: 1,
                    lastmessageChatType: 'mensagem',
                    lastMessageLineColor: '#00FF7F'
                  })
                  if (dadosAtendimento.chamadoAbertoPorWpp === true) {
                    const options = {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                        'Accept': 'application/json',
                      },

                      data: { chaveUnica: chaveUnica, numeroCliente: dadosAtendimento.telefoneCliente, status: 3 },
                      url: 'updateCache',
                    };
                    api(options).then();
                  }
                  if (viaChat === true && dadosAtendimento.chamadoAbertoPorWpp === true && desativarAssistenteVirtualBotWpp === false && keyDeQuemAlterouChamado !== keyAtendente && mostrarNomeAtendenteWpp === true) {
                    let data = {
                      numeroCliente: dadosAtendimento.telefoneCliente,
                      text: viaChat == true ? keyDeQuemAlterouChamado !== keyAtendente ? "Atendimento atribuido ao atendente " + nomeAtendenteQueIraSerAtribuido : `${nomeAtendenteQueIraSerAtribuido} entrou na Conversa` : "Atendimento atribuido ao atendente " + nomeAtendenteQueIraSerAtribuido,
                      apiKey: `${apiKey}`,
                      session: `${sessionclient}`,
                      urlApi: urlApi,
                      type: 'text',
                      keyAcoesChamado: keyAcoesChamadoWpp,
                      keyChamado: keyChamado,
                      chaveUnica: chaveUnica,
                      file: null,
                      responder: '',
                      idMensagemResponder: '',
                      nomeAtendente: 'Assistente Virtual',
                      utilizaVersaoBETA
                    }
                    const options = {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                        'Accept': 'application/json',
                      },

                      data: data,
                      url: 'enviaMensagemWhatsappSocket',
                    };
                    api(options).then((response) => {

                      firebase.database().ref(`${chaveUnica}/acoesChamados/${keyChamado}/${keyAcoesChamadoWpp}`)
                        .update({ statusMessage: 'sent' }).then(() => {
                          firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}/`).update({ lastmessageChatStatus: 'sent', lastMessageLineColor: '#00FF7F' });
                        })
                    }).catch((err) => {


                      if (err.response) {


                        if (err.response.status === 400) {
                          toastr.error('Telefone Desconectado', 'Seu Telefone está sem Conexão com a Internet, verifique a conexão do seu Aparelho e tente novamente.')
                        } else {

                        }
                      } else {

                        toastr.error('Telefone Desconectado', 'Seu Telefone está sem Conexão com a Internet, verifique a conexão do seu Aparelho e tente novamente.')
                      }
                    })
                  }

                });
            } else {
              firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}`).update({
                atendente: keyAtendente,
                status: "Atendendo",
                status_atendente: `Atendendo${keyAtendente}`,
                //usuariosVisualizaAtend: dadosAtendVinc,
                keyAtendenteQueAlterouChamado: keyAtendenteQueAlterouChamado,

                status_cliente: `Atendendo${dadosAtendimento.keyCliente}`, //filtro cliente comum
                status_setor: `Atendendo${dadosAtendimento.keySetor}`, //filtro setor comum
                status_prioridade: `Atendendo${dadosAtendimento.prioridade}`, // filtro prioridade comum
                status_local: `Atendendo${dadosAtendimento.local}`, // filtro local comum
                status_dataVencimento: AbertoVencimento, // filtro local comum
                status_numeroChamado: `Atendendo_${dadosAtendimento.numeroChamado}`,

                status_atendente_cliente: `Atendendo${keyAtendente}${dadosAtendimento.keyCliente}`, //filtro cliente vê proprio chamado
                status_atendente_setor: `Atendendo${keyAtendente}${dadosAtendimento.keySetor}`, //filtro setor vê proprio chamado
                status_atendente_prioridade: `Atendendo${keyAtendente}${dadosAtendimento.prioridade}`, // filtro prioridade vê proprio chamado
                status_atendente_local: `Atendendo${keyAtendente}${dadosAtendimento.local}`, // filtro local vê proprio chamado
                status_atendente_dataVencimento: testeObjeto, // filtro local vê proprio chamado
                status_atendente_numeroChamado: `Atendendo_${keyAtendente}${dadosAtendimento.numeroChamado}`,

                atendente_cliente: `${keyAtendente}${dadosAtendimento.keyCliente}`,
                atendente_setor: `${keyAtendente}${dadosAtendimento.keySetor}`,
                atendente_prioridade: `${keyAtendente}${dadosAtendimento.prioridade}`,
                atendente_local: `${keyAtendente}${dadosAtendimento.local}`,
                atendente_dataVencimento: AbertoVencimentoAtendente,
                atendente_numeroChamado: `${keyAtendente}${dadosAtendimento.numeroChamado}`,
                dataVencimentoUnix: dataVencimentoUnix,
                status_atendente_abertoviachat: viaChat == true || dadosAtendimento.chamadoAbertoPorWpp === true ? `Atendendo${atendenteAtendimento}true` : '',
                setor: dadosAtendimento.keySetor,
                lastMessageChat: mensagem,
                lastMessageChatTimeStamp: firebase.database.ServerValue.TIMESTAMP,
                lastmessageChatCount: 1,
                lastmessageChatType: 'mensagem',
                lastMessageLineColor: '#00FF7F'
              })
                .then(() => {

                  if (setorAtendimento !== "Não informado") {
                    firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/usuariosVisualizaAtend/`).remove().then(
                      () => {
                        //busco clientes vinculados ao setor, se nao tiver eu seto todos os atendentes se tiver seto apenas os atendentes vinculados
                        firebase.database().ref(`${chaveUnica}/setores/${setorAtendimento}/atendentesVinculados`).once('value').then((snapshotSetor) => {
                          if (snapshotSetor.val()) {
                            let dadosAtendVinc = {};

                            let keyAtendentesVinculados = Object.keys(snapshotSetor.val());

                            for (let i = 0; i < keyAtendentesVinculados.length; i++) {
                              if (keyAtendentesVinculados[i].includes('codigoInterno')) {
                                dadosAtendVinc[keyAtendentesVinculados[i] + "status"] = keyAtendentesVinculados[i] + "Atendendo";
                              }
                            }


                            //tem atendentes vinculados ao setor
                            const dadosAtendentesVinculadosSetor = [];
                            snapshotSetor.forEach((childItem) => {
                              dadosAtendentesVinculadosSetor.push({
                                key: childItem.key,
                              });
                            })

                            let verificoSeAtendenteDoChamadoNaoEstaNoSetorVinculado = 0;
                            if (atendenteAtendimento !== 'Não informado') {
                              //verifico se o atendente do chamado nao esta vinculado a este setor
                              for (let i = 0; i < dadosAtendentesVinculadosSetor.length; i++) {
                                if (dadosAtendentesVinculadosSetor[i].key == atendenteAtendimento) {
                                  verificoSeAtendenteDoChamadoNaoEstaNoSetorVinculado = 1;

                                }
                              }
                              //atendente nao esta no grupo porem foi vinculado ao atendimento, coloco ele nos vinculados do chamado
                              if (verificoSeAtendenteDoChamadoNaoEstaNoSetorVinculado == 0) {
                                let objetocadastro = {};
                                firebase.database().ref(`${chaveUnica}/usuarios/${atendenteAtendimento}`).once("value").then((snapshot) => {
                                  let key = snapshot.key;
                                  let codigoInterno = snapshot.val().codigoInterno;
                                  //  objetocadastro[codigoInterno] = codigoInterno;
                                  objetocadastro[codigoInterno + "status"] = codigoInterno + "Atendendo";

                                  firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/usuariosVisualizaAtend/dadosAtendVinc`).update(objetocadastro)
                                })

                              } else {
                                //creio que ja cai na situaçaão acima
                              }
                            }

                            firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/usuariosVisualizaAtend`).update({ dadosAtendVinc })
                          } else {
                            //
                            firebase.database().ref(`${chaveUnica}/usuarios/`).orderByChild('excluido').equalTo(null).once('value').then((snapshot) => {
                              const idTodosAtendentes = [];
                              let objetocadastro = {};
                              snapshot.forEach((childItem) => {
                                idTodosAtendentes.push({
                                  key: childItem.key,
                                  codigoInterno: childItem.val().codigoInterno == undefined ? 'codigoInterno1' : childItem.val().codigoInterno,
                                });
                              })
                              //
                              for (let i = 0; i < idTodosAtendentes.length; i++) {
                                objetocadastro[idTodosAtendentes[i].codigoInterno + "status"] = idTodosAtendentes[i].codigoInterno + "Atendendo";

                              }
                              //
                              firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/usuariosVisualizaAtend/dadosAtendVinc`).update(objetocadastro)
                            })

                          }
                        })
                      })
                  } else if (atendenteAtendimento !== "Não informado") {
                    let objetocadastro = {};
                    firebase.database().ref(`${chaveUnica}/usuarios/${atendenteAtendimento}`).once("value").then((snapshot) => {
                      let key = snapshot.key;
                      let codigoInterno = snapshot.val().codigoInterno;
                      //  objetocadastro[codigoInterno] = codigoInterno;
                      objetocadastro[codigoInterno + "status"] = codigoInterno + "Atendendo";


                      //
                      // firebase.database().ref(`${chaveUnica}/dadosAtendVinc/${key}/${dadosChamado.key}`).push({ true: 'true' });
                      firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/usuariosVisualizaAtend/`).remove().then(
                        () => {
                          firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/usuariosVisualizaAtend/dadosAtendVinc`).update(objetocadastro)
                        })
                      //firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/usuariosVisualizaAtend/dadosAtendVinc`).update(objetocadastro)
                    })
                  } else {
                    let dadosAtendVinc = { vazio: 'vazio' };
                    firebase
                      .database()
                      .ref(`${chaveUnica}/chamados/${keyAtendimento}`)
                      .update({ usuariosVisualizaAtend: dadosAtendVinc })
                  }
                  dispatch({
                    type: MODIFICAKEYATENDIMENTOEMTENDIMENTOONLINE,
                    payload: keyAtendimento
                  })
                  dispatch({
                    type: MODIFICAKEYATENDIMENTOEMESPERAATENDIMENTOONLINE,
                    payload: ''
                  })
                  dispatch({
                    type: MODIFICAKEYCONTACTS,
                    payload: ''
                  })

                  ativaDesativaLoadingIniciandoCOnversaContacts(dispatch, false);
                  ativaDesativaLoadingTelaAcoesAtendimento(dispatch, false);
                  firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}/`).update({
                    lastmessageChatStatus: dadosAtendimento.chamadoAbertoPorWpp === true ? 'waiting' : "sent",
                    lastMessageChat: mensagem,
                    lastMessageChatTimeStamp: firebase.database.ServerValue.TIMESTAMP,
                    lastmessageChatCount: 1,
                    lastmessageChatType: 'mensagem',
                    lastMessageLineColor: '#00FF7F'
                  })
                  if (dadosAtendimento.chamadoAbertoPorWpp === true) {
                    const options = {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                        'Accept': 'application/json',
                      },

                      data: { chaveUnica: chaveUnica, numeroCliente: dadosAtendimento.telefoneCliente, status: 3 },
                      url: 'updateCache',
                    };
                    api(options).then();
                  }
                  if (viaChat === true && dadosAtendimento.chamadoAbertoPorWpp === true && desativarAssistenteVirtualBotWpp === false && keyDeQuemAlterouChamado !== keyAtendente && mostrarNomeAtendenteWpp === true) {
                    let data = {
                      numeroCliente: dadosAtendimento.telefoneCliente,
                      text: viaChat == true ? keyDeQuemAlterouChamado !== keyAtendente ? "Atendimento atribuido ao atendente " + nomeAtendenteQueIraSerAtribuido : `${nomeAtendenteQueIraSerAtribuido} entrou na Conversa` : "Atendimento atribuido ao atendente " + nomeAtendenteQueIraSerAtribuido,
                      apiKey: `${apiKey}`,
                      session: `${sessionclient}`,
                      urlApi: urlApi,
                      type: 'text',
                      keyAcoesChamado: keyAcoesChamadoWpp,
                      keyChamado: keyChamado,
                      chaveUnica: chaveUnica,
                      file: null,
                      responder: '',
                      idMensagemResponder: '',
                      nomeAtendente: 'Assistente Virtual',
                      utilizaVersaoBETA
                    }
                    const options = {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                        'Accept': 'application/json',
                      },

                      data: data,
                      url: 'enviaMensagemWhatsappSocket',
                    };
                    api(options).then((response) => {

                      firebase.database().ref(`${chaveUnica}/acoesChamados/${keyChamado}/${keyAcoesChamadoWpp}`)
                        .update({ statusMessage: 'sent' }).then(() => {
                          firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}/`).update({ lastmessageChatStatus: 'sent', lastMessageLineColor: '#00FF7F' });
                        })
                    }).catch((err) => {


                      if (err.response) {


                        if (err.response.status === 400) {
                          toastr.error('Telefone Desconectado', 'Seu Telefone está sem Conexão com a Internet, verifique a conexão do seu Aparelho e tente novamente.')
                        } else {

                        }
                      } else {

                        toastr.error('Telefone Desconectado', 'Seu Telefone está sem Conexão com a Internet, verifique a conexão do seu Aparelho e tente novamente.')
                      }
                    })
                  }

                });
            }

          });
      });
    });
  };
};
export const salvarComentario = (
  nomeArquivo,
  statusFechar,
  comentario2,
  resArquivo,
  comentarioPublicoSimOuNao,
  keyChamado,
  dataAbertura,
  premium,
  keyAtendente,
  dadosAtendimento,
  viaChat,
  configWhatsapp,
  responderMensagem,
  idMensagemResponder,
  mensagemResposta,
  circleColorResponder,
  commentResponderType,
  atendenteResponder,
  desativarAssistenteVirtualBotWpp2,
  mostrarNomeAtendenteWpp2
) => {
  const comentario = comentario2.trim();
  let desativarAssistenteVirtualBotWpp = desativarAssistenteVirtualBotWpp2 === undefined ? false : desativarAssistenteVirtualBotWpp2;
  let mostrarNomeAtendenteWpp = mostrarNomeAtendenteWpp2 === undefined ? true : mostrarNomeAtendenteWpp2;
  return dispatch => {
    let apiKey = '';
    let urlApi = '';
    let sessionclient = '';
    let statusConnect = '';
    let utilizaVersaoBETA = false;
    if (configWhatsapp !== undefined && configWhatsapp.length > 0) {

      apiKey = configWhatsapp[0].apiKey;
      urlApi = configWhatsapp[0].urlApi;
      sessionclient = configWhatsapp[0].sessionclient;
      statusConnect = configWhatsapp[0].statusConnect;
      utilizaVersaoBETA = configWhatsapp[0].utilizaVersaoBETA;
    }


    if (viaChat == true) {
      modificaEnviandoComentario(dispatch, true);
    }

    ativaDesativaLoadingTelaAcoesAtendimento(dispatch, true);
    const timeAtual = retornaHoraAtual();
    const dataHoraAtual = retornaDataHoraAtual();
    const dataAtualPostagem = retornaDataAtual();
    let AbertoVencimento = dadosAtendimento.dataVencimento.toString() == "Não informado" ? 0 : dadosAtendimento.dataVencimento;
    if (AbertoVencimento !== 0) {
      AbertoVencimento = moment(AbertoVencimento, 'DD/MM/YYYY').unix();
    } else {
      AbertoVencimento = false;
    }

    let AbertoVencimentoAtendente = {};
    let testeObjeto = {};
    let Aberto = 'Fechado';
    AbertoVencimentoAtendente[keyAtendente] = AbertoVencimento;
    testeObjeto[Aberto] = AbertoVencimentoAtendente;
    AbertoVencimento = { Fechado: AbertoVencimento }
    /*    */
    AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");
      if (resArquivo.length === 0) {
        firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}/`).update({
          lastMessageChat: comentario,
          lastmessageChatStatus: dadosAtendimento.chamadoAbertoPorWpp === true ? 'waiting' : "sent",
          lastmessageChatType: "mensagem",
          lastMessageChatTimeStamp: firebase.database.ServerValue.TIMESTAMP,
          lastmessageChatCount: 0,
          lastMessageLineColor: '#00FF7F'
        })

      } else {
        firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}/`).update({
          lastmessageChatStatus: dadosAtendimento.chamadoAbertoPorWpp === true ? 'waiting' : "sent",
        })
      }

      modificavisibilidadeModalComentarioLocal(dispatch, false);
      /*   */
      firebase
        .database()
        .ref(`${chaveUnica}/usuarios/`)
        .orderByChild("uid")
        .equalTo(firebase.auth().currentUser.uid)
        .once("value")
        .then(snapshot => {
          let arrayNomeQuemAbriuChamado = [];
          snapshot.forEach(childItem => {
            arrayNomeQuemAbriuChamado.push({
              nomeDeQuemAbriuChamado: childItem.val().nome
            });
          });
          let diferencaDeDias;
          if (statusFechar) {
            //calculo a quantidade de dias para resolver o chamado e populo no banco

            let total = calculaDias2(dataAbertura, dataAtualPostagem);
            switch (total) {
              case 0:
                diferencaDeDias = "<1";
                break;
              case 1:
                diferencaDeDias = "1";
                break;
              case 2:
                diferencaDeDias = "2";
                break;
              case 3:
                diferencaDeDias = "3";
                break;
              case 4:
                diferencaDeDias = "4";
                break;
              case 5:
                diferencaDeDias = "5";
                break;
              case 6:
                diferencaDeDias = "6";
                break;
              case 7:
                diferencaDeDias = "7";
                break;
              default:
                diferencaDeDias = ">7";
                break;
            }
          }
          let keyDeQuemAlterouChamado = Object.keys(snapshot.val()).toString();
          /*    */
          //então tenho arquivo
          let aux = 0;
          if (nomeArquivo !== "") {
            for (let i = 0; i < resArquivo.length; i++) {
              aux++;
              /*   */
              //    let uri = resArquivo.uri.replace("file://", ""); //remove o file:// que tem no ios
              let unixdateatual = Date.now();
              let name = appendToFilename(resArquivo[i].name, unixdateatual);
              const uuid = generateUUIDv4();
              const mimetypeLocal = resArquivo[i].name.split('.').pop();
              let mime = resArquivo[i].type;

              let caminhoPadraoArquivo1 = firebase.storage().ref(`${chaveUnica}/${keyChamado}/${keyChamado}/${uuid}.${mimetypeLocal}`);
              const newFile = renameFile(resArquivo[i], uuid);
              /*    */
              /*    RNFetchBlob.fs.readFile(uri, "base64")
                   .then(data => {

                     return RNFetchBlob.polyfill.Blob.build(data, {
                       type: mime + ";BASE64"
                     });
                   }).catch((err) => {  }) */
              /*   .then(blob => { */
              caminhoPadraoArquivo1.put(newFile).then(url => {
                url.ref.getDownloadURL().then(function (downloadURL) {

                  const urlDownload = downloadURL;

                  firebase.database().ref(`${chaveUnica}/acoesChamados/${keyChamado}/`).push({
                    tipo: "link",
                    titulo:
                      arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado,
                    mensagem: name,
                    urlDownload: downloadURL,
                    tipoArquivo: resArquivo[i].type,
                    audio: resArquivo[i].audio == undefined ? false : resArquivo[i].audio,
                    duracao: resArquivo[i].audio == true ? resArquivo[i].duracao : '',
                    dataHoraAtual: dataHoraAtual,
                    dataAtualPostagem: dataAtualPostagem,
                    time: timeAtual,
                    lineColor: "#00FF7F",
                    circleColor: "#00FF7F",
                    visivelATodos: 1,
                    keyDeQuemAlterouChamado,
                    statusMessage: 'waiting',
                    idMensagemResponder,
                    possuiResposta: responderMensagem,
                    mensagemResposta: mensagemResposta,
                    circleColorResponder,
                    commentResponderType,
                    atendenteResponder
                  })
                    .then((dadosAcoesChamado) => {
                      if (viaChat === true && dadosAtendimento.chamadoAbertoPorWpp === true) {
                        let typeFile = 'file';
                        if (resArquivo[i].type.includes('image')) {
                          typeFile = 'image';
                        } else if (resArquivo[i].audio === true) {
                          if (resArquivo[i].type.includes('audio') && !resArquivo[i].type.includes('ogg') && !resArquivo[i].type.includes('mp3')) {
                            typeFile = 'audio';
                          } else {
                            typeFile = 'voice';
                          }
                        } else if (resArquivo[i].type.includes('video')) {
                          typeFile = 'video';
                        } else {
                          typeFile = 'file';
                        }
                        let data = {
                          numeroCliente: dadosAtendimento.telefoneCliente,
                          text: name,
                          apiKey: `${apiKey}`,
                          session: `${sessionclient}`,
                          urlApi: urlApi,
                          type: typeFile,
                          url: urlDownload,
                          keyAcoesChamado: dadosAcoesChamado.key,
                          keyChamado: keyChamado,
                          chaveUnica: chaveUnica,
                          file: `${chaveUnica}/${keyChamado}/${keyChamado}/${name}`,
                          mimeType: mime,
                          responder: responderMensagem,
                          idMensagemResponder: idMensagemResponder,
                          nomeAtendente: arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado,
                          utilizaVersaoBETA,

                          // url: 'http://www.orimi.com/pdf-test.pdf',
                        }

                        const options = {
                          method: 'POST',
                          headers: {
                            'Content-Type': 'application/json; charset=utf-8',
                            'Accept': 'application/json',
                          },

                          data: data,
                          url: 'enviaMensagemWhatsappSocket',
                        };
                        api(options).then((response) => {
                          firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}/`).update({ lastMessageLineColor: '#00FF7F' });
                        }).catch((err) => {
                          if (err.response) {
                            if (err.response.status === 400) {
                              toastr.error('Telefone Desconectado', 'Seu Telefone está sem Conexão com a Internet, verifique a conexão do seu Aparelho e tente novamente.')
                            } else {

                            }
                          } else {

                            toastr.error('Telefone Desconectado', 'Seu Telefone está sem Conexão com a Internet, verifique a conexão do seu Aparelho e tente novamente.')
                          }
                        })
                      } else {
                        firebase.database().ref(`${chaveUnica}/acoesChamados/${keyChamado}/${dadosAcoesChamado.key}`).update({ statusMessage: 'sent' })
                        /* .then(() => {
                          firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}/`).update({ lastmessageChatStatus: 'sent', lastMessageLineColor: '#00FF7F' });
                        }) */
                      }
                      /*

                        */
                      if (aux == resArquivo.length) {
                        firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}`).update({
                          lastMessageChat: name,
                          lastMessageChatTimeStamp: firebase.database.ServerValue.TIMESTAMP,
                          lastmessageChatCount: 0,
                          lastmessageChatType: resArquivo[i].type,
                          lastMessageLineColor: '#00FF7F',
                          // lastmessageChatStatus: "sent",
                        }).then(() => {
                          ativaDesativaLoadingTelaAcoesAtendimento(dispatch, false);
                          ativaDesativaLoadingEnviandoAudioChat(dispatch, false);
                          modificaEnviandoComentario(dispatch, false);
                          zeraInformacoesComentario(dispatch);
                        })
                      }
                      /*    */
                    });
                })
              })
                .catch(error => {
                  ativaDesativaLoadingEnviandoAudioChat(dispatch, false);
                  /*    */
                  //error.code
                  //error.message
                  // console.log(JSON.stringify(error));
                  switch (error.code) {
                    case "auth/network-request-failed":
                      ativaDesativaLoadingTelaAcoesAtendimento(dispatch, false);
                      modificaEnviandoComentario(dispatch, false);
                      toastr.error('Error', 'Sem conexão com a INTERNET!');
                      break;
                    default:
                      ativaDesativaLoadingTelaAcoesAtendimento(dispatch, false);
                      modificaEnviandoComentario(dispatch, false);
                      toastr.error('Error', error.message);
                      break;
                  }
                });
              /*  })
               .catch(error => {

                 //error.code
                 //error.message
                 switch (error.code) {
                   case "auth/network-request-failed":
                     ativaDesativaLoadingTelaAcoesAtendimento(
                       dispatch,
                       false
                     );
                     zeraMensagemSemConexaoInternetSalvarComentarioLocal(
                       dispatch,
                       "semConexaoInternet"
                     );
                     break;
                   default:
                     ativaDesativaLoadingTelaAcoesAtendimento(
                       dispatch,
                       false
                     );
                     zeraMensagemSemConexaoInternetSalvarComentarioLocal(
                       dispatch,
                       "semConexaoInternet"
                     );
                     break;
                 }
               }); */
            }
          }

          //somente comentario
          else {
            //comentario publico
            if (comentarioPublicoSimOuNao == 1) {
              if (statusFechar) {
                firebase
                  .database()
                  .ref(`${chaveUnica}/acoesChamados/${keyChamado}/`)
                  .push({
                    tipo: "mensagem",
                    titulo:
                      arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado +
                      " Finalizou o atendimento",
                    mensagem: `${comentario}`,
                    dataHoraAtual: dataHoraAtual,
                    dataAtualPostagem: dataAtualPostagem,
                    time: timeAtual,
                    lineColor: "#00FF7F",
                    circleColor: "#00FF7F",
                    visivelATodos: 1,
                    keyDeQuemAlterouChamado,
                    statusMessage: 'waiting',
                    idMensagemResponder,
                    possuiResposta: responderMensagem,
                    mensagemResposta: mensagemResposta,
                    circleColorResponder,
                    commentResponderType,
                    atendenteResponder
                  })
                  .then(() => {
                    let dadosAtendVinc = { vazio: 'vazio' };
                    firebase
                      .database()
                      .ref(`${chaveUnica}/chamados/${keyChamado}/`)
                      .update({
                        status: "Fechado",
                        status_atendente: `Fechado${keyAtendente}`,
                        // usuariosVisualizaAtend: dadosAtendVinc,
                        periodoResolucaoChamado: diferencaDeDias,
                        dataFechamento: dataAtualPostagem,
                        status_cliente: `Fechado${dadosAtendimento.keyCliente}`, //filtro cliente comum
                        status_setor: `Fechado${dadosAtendimento.keySetor}`, //filtro setor comum
                        status_prioridade: `Fechado${dadosAtendimento.prioridade}`, // filtro prioridade comum
                        status_local: `Fechado${dadosAtendimento.local}`, // filtro local comum
                        status_dataVencimento: AbertoVencimento, // filtro local comum
                        status_numeroChamado: `Fechado_${dadosAtendimento.numeroChamado}`,

                        status_atendente_cliente: `Fechado${keyAtendente}${dadosAtendimento.keyCliente}`, //filtro cliente vê proprio chamado
                        status_atendente_setor: `Fechado${keyAtendente}${dadosAtendimento.keySetor}`, //filtro setor vê proprio chamado
                        status_atendente_prioridade: `Fechado${keyAtendente}${dadosAtendimento.prioridade}`, // filtro prioridade vê proprio chamado
                        status_atendente_local: `Fechado${keyAtendente}${dadosAtendimento.local}`, // filtro local vê proprio chamado
                        status_atendente_dataVencimento: testeObjeto, // filtro local vê proprio chamado
                        status_atendente_numeroChamado: `Fechado_${keyAtendente}${dadosAtendimento.numeroChamado}`,
                        dataHoraFechamento: dataHoraAtual,
                        solucaoAtendimento: comentario,
                        lastMessageChat: `${comentario}`,
                        lastMessageChatTimeStamp: firebase.database.ServerValue.TIMESTAMP,
                        lastmessageChatCount: 0,
                        lastmessageChatType: 'mensagem',
                        lastMessageLineColor: '#00FF7F'

                      })
                      .then(() => {
                        firebase
                          .database()
                          .ref(`${chaveUnica}/configuracao/avaliacao`)
                          .once("value")
                          .then(snapshot => {
                            const dadosEmpresa = [];
                            snapshot.forEach(childItem => {
                              dadosEmpresa.push({
                                key: childItem.key,
                                mensagemPadrao: childItem.val().mensagemPadrao,
                                utilizaAvaliacao: childItem.val()
                                  .utilizaAvaliacao
                              });
                            });
                            /*

                              */
                            if (dadosEmpresa[0].utilizaAvaliacao == true && premium == 1) {
                              firebase
                                .database()
                                .ref(
                                  `${chaveUnica}/acoesChamados/${keyChamado}/`
                                )
                                .push({
                                  tipo: "mensagem",
                                  titulo: "Avaliar Este Atendimento",
                                  mensagem: dadosEmpresa[0].mensagemPadrao,
                                  dataHoraAtual: dataHoraAtual,
                                  dataAtualPostagem: dataAtualPostagem,
                                  time: timeAtual,
                                  lineColor: "#00FF7F",
                                  circleColor: "#00FF7F",
                                  visivelATodos: 1,
                                  keyDeQuemAlterouChamado
                                })
                                .then(() => {
                                  ativaDesativaLoadingTelaAcoesAtendimento(
                                    dispatch,
                                    false
                                  );
                                  let setorAtendimento = dadosAtendimento.keySetor.toString() == "" ? "Não informado" : dadosAtendimento.keySetor.toString();
                                  let atendenteAtendimento = dadosAtendimento.keyAtendente.toString() == "" ? "Não informado" : dadosAtendimento.keyAtendente.toString();
                                  let keyAtendimento = dadosAtendimento.key;
                                  if (setorAtendimento !== "Não informado") {
                                    firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/usuariosVisualizaAtend/`).remove().then(
                                      () => {
                                        //
                                        //busco clientes vinculados ao setor, se nao tiver eu seto todos os atendentes se tiver seto apenas os atendentes vinculados
                                        firebase.database().ref(`${chaveUnica}/setores/${setorAtendimento}/atendentesVinculados`).once('value').then((snapshotSetor) => {
                                          if (snapshotSetor.val()) {
                                            let dadosAtendVinc = {};

                                            let keyAtendentesVinculados = Object.keys(snapshotSetor.val());

                                            for (let i = 0; i < keyAtendentesVinculados.length; i++) {
                                              if (keyAtendentesVinculados[i].includes('codigoInterno')) {
                                                dadosAtendVinc[keyAtendentesVinculados[i] + "status"] = keyAtendentesVinculados[i] + "Fechado";
                                              }
                                            }

                                            //  console.log(snapshotSetor.val());

                                            //tem atendentes vinculados ao setor
                                            const dadosAtendentesVinculadosSetor = [];
                                            snapshotSetor.forEach((childItem) => {
                                              dadosAtendentesVinculadosSetor.push({
                                                key: childItem.key,
                                              });
                                            })
                                            //

                                            let verificoSeAtendenteDoChamadoNaoEstaNoSetorVinculado = 0;
                                            if (atendenteAtendimento !== 'Não informado') {
                                              //verifico se o atendente do chamado nao esta vinculado a este setor
                                              for (let i = 0; i < dadosAtendentesVinculadosSetor.length; i++) {
                                                //
                                                if (dadosAtendentesVinculadosSetor[i].key == atendenteAtendimento) {
                                                  verificoSeAtendenteDoChamadoNaoEstaNoSetorVinculado = 1;

                                                }
                                              }
                                              //atendente nao esta no grupo porem foi vinculado ao atendimento, coloco ele nos vinculados do chamado
                                              if (verificoSeAtendenteDoChamadoNaoEstaNoSetorVinculado == 0) {
                                                //
                                                let objetocadastro = {};
                                                firebase.database().ref(`${chaveUnica}/usuarios/${atendenteAtendimento}`).once("value").then((snapshot) => {
                                                  let key = snapshot.key;
                                                  let codigoInterno = snapshot.val().codigoInterno;
                                                  //  objetocadastro[codigoInterno] = codigoInterno;
                                                  objetocadastro[codigoInterno + "status"] = `${codigoInterno}Fechado`;
                                                  //   objetocadastro[key] = true;
                                                  //
                                                  firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}/usuariosVisualizaAtend/dadosAtendVinc`).update(objetocadastro)
                                                })

                                              } else {
                                                //creio que ja cai na situaçaão acima
                                                //
                                              }
                                            }

                                            firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}/usuariosVisualizaAtend`).update({ dadosAtendVinc })
                                          } else {
                                            //
                                            firebase.database().ref(`${chaveUnica}/usuarios/`).orderByChild('excluido').equalTo(null).once('value').then((snapshot) => {
                                              //
                                              const idTodosAtendentes = [];
                                              let objetocadastro = {};
                                              snapshot.forEach((childItem) => {
                                                idTodosAtendentes.push({
                                                  key: childItem.key,
                                                  codigoInterno: childItem.val().codigoInterno == undefined ? 'codigoInterno1' : childItem.val().codigoInterno,
                                                });
                                              })
                                              //
                                              for (let i = 0; i < idTodosAtendentes.length; i++) {
                                                // objetocadastro[idTodosAtendentes[i].key] = true;
                                                objetocadastro[idTodosAtendentes[i].codigoInterno] = idTodosAtendentes[i].codigoInterno;
                                                objetocadastro[idTodosAtendentes[i].codigoInterno + "status"] = idTodosAtendentes[i].codigoInterno + 'Fechado';
                                              }
                                              //
                                              firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}/usuariosVisualizaAtend/dadosAtendVinc`).update(objetocadastro)
                                            })

                                          }
                                        })
                                      })
                                  } else {
                                    //verifica se o atendente é dierente de naão informado, porque se for, preciso criar o campo atendentes vinculados pra esse atendente visualizar este atendimento
                                    //
                                    let objetocadastro = {}
                                    if (atendenteAtendimento != "Não informado") {
                                      //
                                      //
                                      objetocadastro[atendenteAtendimento] = true;
                                      //

                                      firebase.database().ref(`${chaveUnica}/usuarios/${atendenteAtendimento}/codigoInterno/`).once('value').then((snapshot) => {

                                        let codigoInterno = snapshot.val();
                                        //  objetocadastro[codigoInterno] = codigoInterno;
                                        objetocadastro[codigoInterno + "status"] = `${codigoInterno}Fechado`;

                                        let dadosAtendVinc = objetocadastro;
                                        firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}/usuariosVisualizaAtend/`).update({ dadosAtendVinc })
                                      })

                                    }
                                  }
                                  zeraInformacoesComentario(dispatch);
                                });
                            } else {
                              let setorAtendimento = dadosAtendimento.keySetor.toString() == "" ? "Não informado" : dadosAtendimento.keySetor.toString();
                              let atendenteAtendimento = dadosAtendimento.keyAtendente.toString() == "" ? "Não informado" : dadosAtendimento.keyAtendente.toString();
                              let keyAtendimento = dadosAtendimento.key;
                              if (setorAtendimento !== "Não informado") {
                                firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/usuariosVisualizaAtend/`).remove().then(
                                  () => {
                                    //
                                    //busco clientes vinculados ao setor, se nao tiver eu seto todos os atendentes se tiver seto apenas os atendentes vinculados
                                    firebase.database().ref(`${chaveUnica}/setores/${setorAtendimento}/atendentesVinculados`).once('value').then((snapshotSetor) => {
                                      if (snapshotSetor.val()) {
                                        let dadosAtendVinc = {};

                                        let keyAtendentesVinculados = Object.keys(snapshotSetor.val());

                                        for (let i = 0; i < keyAtendentesVinculados.length; i++) {
                                          if (keyAtendentesVinculados[i].includes('codigoInterno')) {
                                            dadosAtendVinc[keyAtendentesVinculados[i] + "status"] = keyAtendentesVinculados[i] + "Fechado";
                                          }
                                        }

                                        //  console.log(snapshotSetor.val());

                                        //tem atendentes vinculados ao setor
                                        const dadosAtendentesVinculadosSetor = [];
                                        snapshotSetor.forEach((childItem) => {
                                          dadosAtendentesVinculadosSetor.push({
                                            key: childItem.key,
                                          });
                                        })
                                        //

                                        let verificoSeAtendenteDoChamadoNaoEstaNoSetorVinculado = 0;
                                        if (atendenteAtendimento !== 'Não informado') {
                                          //verifico se o atendente do chamado nao esta vinculado a este setor
                                          for (let i = 0; i < dadosAtendentesVinculadosSetor.length; i++) {
                                            //
                                            if (dadosAtendentesVinculadosSetor[i].key == atendenteAtendimento) {
                                              verificoSeAtendenteDoChamadoNaoEstaNoSetorVinculado = 1;

                                            }
                                          }
                                          //atendente nao esta no grupo porem foi vinculado ao atendimento, coloco ele nos vinculados do chamado
                                          if (verificoSeAtendenteDoChamadoNaoEstaNoSetorVinculado == 0) {
                                            //
                                            let objetocadastro = {};
                                            firebase.database().ref(`${chaveUnica}/usuarios/${atendenteAtendimento}`).once("value").then((snapshot) => {
                                              let key = snapshot.key;
                                              let codigoInterno = snapshot.val().codigoInterno;
                                              //  objetocadastro[codigoInterno] = codigoInterno;
                                              objetocadastro[codigoInterno + "status"] = `${codigoInterno}Fechado`;
                                              // objetocadastro[key] = true;
                                              //
                                              firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}/usuariosVisualizaAtend/dadosAtendVinc`).update(objetocadastro)
                                            })

                                          } else {
                                            //creio que ja cai na situaçaão acima
                                            //
                                          }
                                        }

                                        firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}/usuariosVisualizaAtend`).update({ dadosAtendVinc })
                                      } else {
                                        //
                                        firebase.database().ref(`${chaveUnica}/usuarios/`).orderByChild('excluido').equalTo(null).once('value').then((snapshot) => {
                                          //
                                          const idTodosAtendentes = [];
                                          let objetocadastro = {};
                                          snapshot.forEach((childItem) => {
                                            idTodosAtendentes.push({
                                              key: childItem.key,
                                              codigoInterno: childItem.val().codigoInterno == undefined ? 'codigoInterno1' : childItem.val().codigoInterno,
                                            });
                                          })
                                          //
                                          for (let i = 0; i < idTodosAtendentes.length; i++) {
                                            //  objetocadastro[idTodosAtendentes[i].key] = true;
                                            objetocadastro[idTodosAtendentes[i].codigoInterno] = idTodosAtendentes[i].codigoInterno;
                                            objetocadastro[idTodosAtendentes[i].codigoInterno + "status"] = idTodosAtendentes[i].codigoInterno + 'Fechado';
                                          }
                                          //
                                          firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}/usuariosVisualizaAtend/dadosAtendVinc`).update(objetocadastro)
                                        })

                                      }
                                    })
                                  })
                              } else {
                                //verifica se o atendente é dierente de naão informado, porque se for, preciso criar o campo atendentes vinculados pra esse atendente visualizar este atendimento
                                //
                                let objetocadastro = {}
                                if (atendenteAtendimento != "Não informado") {
                                  //
                                  //
                                  objetocadastro[atendenteAtendimento] = true;
                                  //

                                  firebase.database().ref(`${chaveUnica}/usuarios/${atendenteAtendimento}/codigoInterno/`).once('value').then((snapshot) => {

                                    let codigoInterno = snapshot.val();
                                    // objetocadastro[codigoInterno] = codigoInterno;
                                    objetocadastro[codigoInterno + "status"] = `${codigoInterno}Fechado`;

                                    let dadosAtendVinc = objetocadastro;
                                    firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}/usuariosVisualizaAtend/`).update({ dadosAtendVinc })
                                  })

                                }
                              }
                              ativaDesativaLoadingTelaAcoesAtendimento(
                                dispatch,
                                false
                              );
                              zeraInformacoesComentario(dispatch);
                            }
                          });
                      })
                      .catch(error => {
                        //error.code
                        //error.message
                        switch (error.code) {
                          case "auth/network-request-failed":
                            ativaDesativaLoadingTelaAcoesAtendimento(
                              dispatch,
                              false
                            );
                            zeraMensagemSemConexaoInternetSalvarComentarioLocal(
                              dispatch,
                              "semConexaoInternet"
                            );
                            break;
                          default:
                            ativaDesativaLoadingTelaAcoesAtendimento(
                              dispatch,
                              false
                            );
                            zeraMensagemSemConexaoInternetSalvarComentarioLocal(
                              dispatch,
                              "semConexaoInternet"
                            );
                            break;
                        }
                      });
                  })
                  .catch(error => {
                    //error.code
                    //error.message
                    switch (error.code) {
                      case "auth/network-request-failed":
                        ativaDesativaLoadingTelaAcoesAtendimento(
                          dispatch,
                          false
                        );
                        zeraMensagemSemConexaoInternetSalvarComentarioLocal(
                          dispatch,
                          "semConexaoInternet"
                        );
                        break;
                      default:
                        ativaDesativaLoadingTelaAcoesAtendimento(
                          dispatch,
                          false
                        );
                        zeraMensagemSemConexaoInternetSalvarComentarioLocal(
                          dispatch,
                          "semConexaoInternet"
                        );
                        break;
                    }
                  });
              }
              //nao fecho
              else {
                firebase
                  .database()
                  .ref(`${chaveUnica}/acoesChamados/${keyChamado}/`)
                  .push({
                    tipo: "mensagem",
                    titulo: arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado,
                    mensagem: comentario,
                    dataHoraAtual: dataHoraAtual,
                    dataAtualPostagem: dataAtualPostagem,
                    time: timeAtual,
                    lineColor: "#00FF7F",
                    circleColor: "#00FF7F",
                    visivelATodos: 1,
                    keyDeQuemAlterouChamado,
                    statusMessage: 'waiting',
                    idMensagemResponder,
                    possuiResposta: responderMensagem,
                    mensagemResposta: mensagemResposta,
                    circleColorResponder,
                    commentResponderType,
                    atendenteResponder,

                  })
                  .then((dadosAcoesChamado) => {
                    ativaDesativaLoadingTelaAcoesAtendimento(dispatch, false);
                    modificaEnviandoComentario(dispatch, false);
                    zeraInformacoesComentario(dispatch);
                    if (viaChat === true && dadosAtendimento.chamadoAbertoPorWpp === true) {
                      let data = {
                        numeroCliente: dadosAtendimento.telefoneCliente,
                        text: comentario,
                        apiKey: `${apiKey}`,
                        session: `${sessionclient}`,
                        urlApi: urlApi,
                        type: 'text',
                        keyAcoesChamado: dadosAcoesChamado.key,
                        keyChamado: keyChamado,
                        chaveUnica: chaveUnica,
                        file: null,
                        responder: responderMensagem,
                        idMensagemResponder: idMensagemResponder,
                        nomeAtendente: arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado,
                        desativarAssistenteVirtualBotWpp: desativarAssistenteVirtualBotWpp,
                        mostrarNomeAtendenteWpp: mostrarNomeAtendenteWpp,
                        utilizaVersaoBETA
                      }
                      const options = {
                        method: 'POST',
                        headers: {
                          'Content-Type': 'application/json; charset=utf-8',
                          'Accept': 'application/json',
                        },

                        data: data,
                        url: 'enviaMensagemWhatsappSocket',
                      };
                      api(options).then((response) => {
                        firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}/`).update({ lastMessageLineColor: '#00FF7F' });
                      }).catch((err) => {


                        if (err.response) {


                          if (err.response.status === 400) {
                            toastr.error('Telefone Desconectado', 'Seu Telefone está sem Conexão com a Internet, verifique a conexão do seu Aparelho e tente novamente.')
                          } else {

                          }
                        } else {

                          toastr.error('Telefone Desconectado', 'Seu Telefone está sem Conexão com a Internet, verifique a conexão do seu Aparelho e tente novamente.')
                        }
                      })
                    } else {
                      firebase.database().ref(`${chaveUnica}/acoesChamados/${keyChamado}/${dadosAcoesChamado.key}`).update({ statusMessage: 'sent' })/* .then(() => {
                        firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}/`).update({ lastmessageChatStatus: 'sent', lastMessageLineColor: '#00FF7F' });
                      }); */
                    }
                    /*  firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}`).update({
                       lastMessageChat: comentario,
                       lastMessageChatTimeStamp: firebase.database.ServerValue.TIMESTAMP,
                       lastmessageChatCount: 0,
                       lastmessageChatType: 'mensagem',
                       lastMessageLineColor: '#00FF7F'
                     }) */

                  })
                  .catch(error => {
                    //error.code
                    //error.message
                    modificaEnviandoComentario(dispatch, false);
                    switch (error.code) {
                      case "auth/network-request-failed":
                        ativaDesativaLoadingTelaAcoesAtendimento(
                          dispatch,
                          false
                        );
                        zeraMensagemSemConexaoInternetSalvarComentarioLocal(
                          dispatch,
                          "semConexaoInternet"
                        );
                        break;
                      default:
                        ativaDesativaLoadingTelaAcoesAtendimento(
                          dispatch,
                          false
                        );
                        zeraMensagemSemConexaoInternetSalvarComentarioLocal(
                          dispatch,
                          "semConexaoInternet"
                        );
                        break;
                    }
                  });
              }
            }
            //comentario privado
            else {
              if (statusFechar) {
                firebase
                  .database()
                  .ref(`${chaveUnica}/acoesChamados/${keyChamado}/`)
                  .push({
                    tipo: "mensagem",
                    titulo:
                      arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado +
                      " Finalizou o atendimento",
                    mensagem: `${comentario}`,
                    dataHoraAtual: dataHoraAtual,
                    dataAtualPostagem: dataAtualPostagem,
                    time: timeAtual,
                    lineColor: "#00FF7F",
                    circleColor: "#00FF7F",
                    visivelATodos: 0,
                    keyDeQuemAlterouChamado,
                    statusMessage: 'waiting',
                    idMensagemResponder,
                    possuiResposta: responderMensagem,
                    mensagemResposta: mensagemResposta,
                    circleColorResponder,
                    commentResponderType,
                    atendenteResponder
                  })
                  .then(() => {
                    let dadosAtendVinc = { vazio: 'vazio' };
                    firebase
                      .database()
                      .ref(`${chaveUnica}/chamados/${keyChamado}/`)
                      .update({
                        status: "Fechado",
                        status_atendente: `Fechado${keyAtendente}`,
                        //  usuariosVisualizaAtend: dadosAtendVinc,
                        periodoResolucaoChamado: diferencaDeDias,
                        dataFechamento: dataAtualPostagem,
                        dataHoraFechamento: dataHoraAtual,
                        status_cliente: `Fechado${dadosAtendimento.keyCliente}`, //filtro cliente comum
                        status_setor: `Fechado${dadosAtendimento.keySetor}`, //filtro setor comum
                        status_prioridade: `Fechado${dadosAtendimento.prioridade}`, // filtro prioridade comum
                        status_local: `Fechado${dadosAtendimento.local}`, // filtro local comum
                        status_dataVencimento: AbertoVencimento, // filtro local comum
                        status_numeroChamado: `Fechado_${dadosAtendimento.numeroChamado}`,

                        status_atendente_cliente: `Fechado${keyAtendente}${dadosAtendimento.keyCliente}`, //filtro cliente vê proprio chamado
                        status_atendente_setor: `Fechado${keyAtendente}${dadosAtendimento.keySetor}`, //filtro setor vê proprio chamado
                        status_atendente_prioridade: `Fechado${keyAtendente}${dadosAtendimento.prioridade}`, // filtro prioridade vê proprio chamado
                        status_atendente_local: `Fechado${keyAtendente}${dadosAtendimento.local}`, // filtro local vê proprio chamado
                        status_atendente_dataVencimento: testeObjeto, // filtro local vê proprio chamado
                        status_atendente_numeroChamado: `Fechado_${keyAtendente}${dadosAtendimento.numeroChamado}`,
                        solucaoAtendimento: comentario,
                        lastMessageChat: `${comentario}`,
                        lastMessageChatTimeStamp: firebase.database.ServerValue.TIMESTAMP,
                        lastmessageChatCount: 0,
                        lastmessageChatType: 'mensagem',
                        lastMessageLineColor: '#00FF7F'
                      })
                      .then(() => {
                        firebase
                          .database()
                          .ref(`${chaveUnica}/configuracao/avaliacao`)
                          .once("value")
                          .then(snapshot => {
                            const dadosEmpresa = [];
                            snapshot.forEach(childItem => {
                              dadosEmpresa.push({
                                key: childItem.key,
                                mensagemPadrao: childItem.val().mensagemPadrao,
                                utilizaAvaliacao: childItem.val()
                                  .utilizaAvaliacao
                              });
                            });
                            /*

                              */
                            if (
                              dadosEmpresa[0].utilizaAvaliacao == true &&
                              premium == 1
                            ) {
                              firebase
                                .database()
                                .ref(
                                  `${chaveUnica}/acoesChamados/${keyChamado}/`
                                )
                                .push({
                                  tipo: "mensagem",
                                  titulo: "Avaliar Este Atendimento",
                                  mensagem: dadosEmpresa[0].mensagemPadrao,
                                  dataHoraAtual: dataHoraAtual,
                                  dataAtualPostagem: dataAtualPostagem,
                                  time: timeAtual,
                                  lineColor: "#00FF7F",
                                  circleColor: "#00FF7F",
                                  visivelATodos: 1,
                                  keyDeQuemAlterouChamado,
                                })
                                .then(() => {
                                  let setorAtendimento = dadosAtendimento.keySetor.toString() == "" ? "Não informado" : dadosAtendimento.keySetor.toString();
                                  let atendenteAtendimento = dadosAtendimento.keyAtendente.toString() == "" ? "Não informado" : dadosAtendimento.keyAtendente.toString();
                                  let keyAtendimento = dadosAtendimento.key;
                                  if (setorAtendimento !== "Não informado") {
                                    firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/usuariosVisualizaAtend/`).remove().then(
                                      () => {
                                        //
                                        //busco clientes vinculados ao setor, se nao tiver eu seto todos os atendentes se tiver seto apenas os atendentes vinculados
                                        firebase.database().ref(`${chaveUnica}/setores/${setorAtendimento}/atendentesVinculados`).once('value').then((snapshotSetor) => {
                                          if (snapshotSetor.val()) {
                                            let dadosAtendVinc = {};

                                            let keyAtendentesVinculados = Object.keys(snapshotSetor.val());

                                            for (let i = 0; i < keyAtendentesVinculados.length; i++) {
                                              if (keyAtendentesVinculados[i].includes('codigoInterno')) {
                                                dadosAtendVinc[keyAtendentesVinculados[i] + "status"] = keyAtendentesVinculados[i] + "Fechado";
                                              }
                                            }

                                            //  console.log(snapshotSetor.val());

                                            //tem atendentes vinculados ao setor
                                            const dadosAtendentesVinculadosSetor = [];
                                            snapshotSetor.forEach((childItem) => {
                                              dadosAtendentesVinculadosSetor.push({
                                                key: childItem.key,
                                              });
                                            })
                                            //

                                            let verificoSeAtendenteDoChamadoNaoEstaNoSetorVinculado = 0;
                                            if (atendenteAtendimento !== 'Não informado') {
                                              //verifico se o atendente do chamado nao esta vinculado a este setor
                                              for (let i = 0; i < dadosAtendentesVinculadosSetor.length; i++) {
                                                //
                                                if (dadosAtendentesVinculadosSetor[i].key == atendenteAtendimento) {
                                                  verificoSeAtendenteDoChamadoNaoEstaNoSetorVinculado = 1;

                                                }
                                              }
                                              //atendente nao esta no grupo porem foi vinculado ao atendimento, coloco ele nos vinculados do chamado
                                              if (verificoSeAtendenteDoChamadoNaoEstaNoSetorVinculado == 0) {
                                                //
                                                let objetocadastro = {};
                                                firebase.database().ref(`${chaveUnica}/usuarios/${atendenteAtendimento}`).once("value").then((snapshot) => {
                                                  let key = snapshot.key;
                                                  let codigoInterno = snapshot.val().codigoInterno;
                                                  //  objetocadastro[codigoInterno] = codigoInterno;
                                                  objetocadastro[codigoInterno + "status"] = `${codigoInterno}Fechado`;
                                                  //  objetocadastro[key] = true;
                                                  //
                                                  firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}/usuariosVisualizaAtend/dadosAtendVinc`).update(objetocadastro)
                                                })

                                              } else {
                                                //creio que ja cai na situaçaão acima
                                                //
                                              }
                                            }

                                            firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}/usuariosVisualizaAtend`).update({ dadosAtendVinc })
                                          } else {
                                            //
                                            firebase.database().ref(`${chaveUnica}/usuarios/`).orderByChild('excluido').equalTo(null).once('value').then((snapshot) => {
                                              //
                                              const idTodosAtendentes = [];
                                              let objetocadastro = {};
                                              snapshot.forEach((childItem) => {
                                                idTodosAtendentes.push({
                                                  key: childItem.key,
                                                  codigoInterno: childItem.val().codigoInterno == undefined ? 'codigoInterno1' : childItem.val().codigoInterno,
                                                });
                                              })
                                              //
                                              for (let i = 0; i < idTodosAtendentes.length; i++) {
                                                // objetocadastro[idTodosAtendentes[i].key] = true;
                                                objetocadastro[idTodosAtendentes[i].codigoInterno] = idTodosAtendentes[i].codigoInterno;
                                                objetocadastro[idTodosAtendentes[i].codigoInterno + "status"] = idTodosAtendentes[i].codigoInterno + 'Fechado';
                                              }
                                              //
                                              firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}/usuariosVisualizaAtend/dadosAtendVinc`).update(objetocadastro)
                                            })

                                          }
                                        })
                                      })
                                  } else {
                                    //verifica se o atendente é dierente de naão informado, porque se for, preciso criar o campo atendentes vinculados pra esse atendente visualizar este atendimento
                                    //
                                    let objetocadastro = {}
                                    if (atendenteAtendimento != "Não informado") {
                                      //
                                      //
                                      objetocadastro[atendenteAtendimento] = true;
                                      //

                                      firebase.database().ref(`${chaveUnica}/usuarios/${atendenteAtendimento}/codigoInterno/`).once('value').then((snapshot) => {

                                        let codigoInterno = snapshot.val();
                                        //  objetocadastro[codigoInterno] = codigoInterno;
                                        objetocadastro[codigoInterno + "status"] = `${codigoInterno}Fechado`;

                                        let dadosAtendVinc = objetocadastro;
                                        firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}/usuariosVisualizaAtend/`).update({ dadosAtendVinc })
                                      })

                                    }
                                  }
                                  ativaDesativaLoadingTelaAcoesAtendimento(
                                    dispatch,
                                    false
                                  );
                                  zeraInformacoesComentario(dispatch);
                                });
                            } else {
                              let setorAtendimento = dadosAtendimento.keySetor.toString() == "" ? "Não informado" : dadosAtendimento.keySetor.toString();
                              let atendenteAtendimento = dadosAtendimento.keyAtendente.toString() == "" ? "Não informado" : dadosAtendimento.keyAtendente.toString();
                              let keyAtendimento = dadosAtendimento.key;
                              if (setorAtendimento !== "Não informado") {
                                firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/usuariosVisualizaAtend/`).remove().then(
                                  () => {
                                    //
                                    //busco clientes vinculados ao setor, se nao tiver eu seto todos os atendentes se tiver seto apenas os atendentes vinculados
                                    firebase.database().ref(`${chaveUnica}/setores/${setorAtendimento}/atendentesVinculados`).once('value').then((snapshotSetor) => {
                                      if (snapshotSetor.val()) {
                                        let dadosAtendVinc = {};

                                        let keyAtendentesVinculados = Object.keys(snapshotSetor.val());

                                        for (let i = 0; i < keyAtendentesVinculados.length; i++) {
                                          if (keyAtendentesVinculados[i].includes('codigoInterno')) {
                                            dadosAtendVinc[keyAtendentesVinculados[i] + "status"] = keyAtendentesVinculados[i] + "Fechado";
                                          }
                                        }

                                        //  console.log(snapshotSetor.val());

                                        //tem atendentes vinculados ao setor
                                        const dadosAtendentesVinculadosSetor = [];
                                        snapshotSetor.forEach((childItem) => {
                                          dadosAtendentesVinculadosSetor.push({
                                            key: childItem.key,
                                          });
                                        })
                                        //

                                        let verificoSeAtendenteDoChamadoNaoEstaNoSetorVinculado = 0;
                                        if (atendenteAtendimento !== 'Não informado') {
                                          //verifico se o atendente do chamado nao esta vinculado a este setor
                                          for (let i = 0; i < dadosAtendentesVinculadosSetor.length; i++) {
                                            //
                                            if (dadosAtendentesVinculadosSetor[i].key == atendenteAtendimento) {
                                              verificoSeAtendenteDoChamadoNaoEstaNoSetorVinculado = 1;

                                            }
                                          }
                                          //atendente nao esta no grupo porem foi vinculado ao atendimento, coloco ele nos vinculados do chamado
                                          if (verificoSeAtendenteDoChamadoNaoEstaNoSetorVinculado == 0) {
                                            //
                                            let objetocadastro = {};
                                            firebase.database().ref(`${chaveUnica}/usuarios/${atendenteAtendimento}`).once("value").then((snapshot) => {
                                              let key = snapshot.key;
                                              let codigoInterno = snapshot.val().codigoInterno;
                                              //  objetocadastro[codigoInterno] = codigoInterno;
                                              objetocadastro[codigoInterno + "status"] = `${codigoInterno}Fechado`;
                                              // objetocadastro[key] = true;
                                              //
                                              firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}/usuariosVisualizaAtend/dadosAtendVinc`).update(objetocadastro)
                                            })

                                          } else {
                                            //creio que ja cai na situaçaão acima
                                            //
                                          }
                                        }

                                        firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}/usuariosVisualizaAtend`).update({ dadosAtendVinc })
                                      } else {
                                        //
                                        firebase.database().ref(`${chaveUnica}/usuarios/`).orderByChild('excluido').equalTo(null).once('value').then((snapshot) => {
                                          //
                                          const idTodosAtendentes = [];
                                          let objetocadastro = {};
                                          snapshot.forEach((childItem) => {
                                            idTodosAtendentes.push({
                                              key: childItem.key,
                                              codigoInterno: childItem.val().codigoInterno == undefined ? 'codigoInterno1' : childItem.val().codigoInterno,
                                            });
                                          })
                                          //
                                          for (let i = 0; i < idTodosAtendentes.length; i++) {
                                            // objetocadastro[idTodosAtendentes[i].key] = true;
                                            objetocadastro[idTodosAtendentes[i].codigoInterno] = idTodosAtendentes[i].codigoInterno;
                                            objetocadastro[idTodosAtendentes[i].codigoInterno + "status"] = idTodosAtendentes[i].codigoInterno + 'Fechado';
                                          }
                                          //
                                          firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}/usuariosVisualizaAtend/dadosAtendVinc`).update(objetocadastro)
                                        })

                                      }
                                    })
                                  })
                              } else {
                                //verifica se o atendente é dierente de naão informado, porque se for, preciso criar o campo atendentes vinculados pra esse atendente visualizar este atendimento
                                //
                                let objetocadastro = {}
                                if (atendenteAtendimento != "Não informado") {
                                  //
                                  //
                                  objetocadastro[atendenteAtendimento] = true;
                                  //

                                  firebase.database().ref(`${chaveUnica}/usuarios/${atendenteAtendimento}/codigoInterno/`).once('value').then((snapshot) => {

                                    let codigoInterno = snapshot.val();
                                    // objetocadastro[codigoInterno] = codigoInterno;
                                    objetocadastro[codigoInterno + "status"] = `${codigoInterno}Fechado`;

                                    let dadosAtendVinc = objetocadastro;
                                    firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}/usuariosVisualizaAtend/`).update({ dadosAtendVinc })
                                  })

                                }
                              }
                              ativaDesativaLoadingTelaAcoesAtendimento(
                                dispatch,
                                false
                              );
                              zeraInformacoesComentario(dispatch);
                            }
                          });
                      })
                      .catch(error => {
                        //error.code
                        //error.message
                        switch (error.code) {
                          case "auth/network-request-failed":
                            ativaDesativaLoadingTelaAcoesAtendimento(
                              dispatch,
                              false
                            );
                            zeraMensagemSemConexaoInternetSalvarComentarioLocal(
                              dispatch,
                              "semConexaoInternet"
                            );
                            break;
                          default:
                            ativaDesativaLoadingTelaAcoesAtendimento(
                              dispatch,
                              false
                            );
                            zeraMensagemSemConexaoInternetSalvarComentarioLocal(
                              dispatch,
                              "semConexaoInternet"
                            );
                            break;
                        }
                      });
                  })
                  .catch(error => {
                    //error.code
                    //error.message
                    switch (error.code) {
                      case "auth/network-request-failed":
                        ativaDesativaLoadingTelaAcoesAtendimento(
                          dispatch,
                          false
                        );
                        zeraMensagemSemConexaoInternetSalvarComentarioLocal(
                          dispatch,
                          "semConexaoInternet"
                        );
                        break;
                      default:
                        ativaDesativaLoadingTelaAcoesAtendimento(
                          dispatch,
                          false
                        );
                        zeraMensagemSemConexaoInternetSalvarComentarioLocal(
                          dispatch,
                          "semConexaoInternet"
                        );
                        break;
                    }
                  });
              }
              //nao fecho
              else {
                firebase
                  .database()
                  .ref(`${chaveUnica}/acoesChamados/${keyChamado}/`)
                  .push({
                    tipo: "mensagem",
                    titulo:

                      arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado,
                    mensagem: comentario,
                    dataHoraAtual: dataHoraAtual,
                    dataAtualPostagem: dataAtualPostagem,
                    time: timeAtual,
                    lineColor: "#00FF7F",
                    circleColor: "#00FF7F",
                    visivelATodos: 0,
                    keyDeQuemAlterouChamado,
                    statusMessage: 'waiting',
                    idMensagemResponder,
                    possuiResposta: responderMensagem,
                    mensagemResposta: mensagemResposta,
                    circleColorResponder,
                    commentResponderType,
                    atendenteResponder
                  })
                  .then(() => {
                    ativaDesativaLoadingTelaAcoesAtendimento(dispatch, false);
                    zeraInformacoesComentario(dispatch);
                    /*   firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}`).update({
                        lastMessageChat: comentario,
                        lastMessageChatTimeStamp: firebase.database.ServerValue.TIMESTAMP,
                        lastmessageChatCount: 0,
                        lastmessageChatType: 'mensagem',
                        lastMessageLineColor: '#00FF7F'
                      }) */
                  })
                  .catch(error => {
                    //error.code
                    //error.message
                    switch (error.code) {
                      case "auth/network-request-failed":
                        ativaDesativaLoadingTelaAcoesAtendimento(
                          dispatch,
                          false
                        );
                        zeraMensagemSemConexaoInternetSalvarComentarioLocal(
                          dispatch,
                          "semConexaoInternet"
                        );
                        break;
                      default:
                        ativaDesativaLoadingTelaAcoesAtendimento(
                          dispatch,
                          false
                        );
                        zeraMensagemSemConexaoInternetSalvarComentarioLocal(
                          dispatch,
                          "semConexaoInternet"
                        );
                        break;
                    }
                  });
              }
            }
          }
        });
    });
  };
};
export const alterarInfoChat = (
  prioridadeAtendimento2,
  localAtendimento2,
  descricaoAtendimento2,
  dataVencimento2,
  keyAtendimento,
  arrayInformacoesAnteriores,
  dadosAtendimento,
  statusChamado,
  criarChamadoProblemaEstatico,
  problemaEstaticoSelecionado2,
  nomeProblemaEstaticoSelecionado,
  utilizaProblemaEstatico,
  setorAtendimento2
) => {

  let problemaEstaticoSelecionado = '';
  if (problemaEstaticoSelecionado2.length > 0) {
    problemaEstaticoSelecionado = problemaEstaticoSelecionado2
  }
  /*
   */
  let dataVencimento = dataVencimento2.toString() == "" ? "Não informado" : dataVencimento2;
  let prioridadeAtendimento = prioridadeAtendimento2.toString() == "" ? "Não informado" : prioridadeAtendimento2.toString();
  let localAtendimento = localAtendimento2.toString() == "" ? "Não informado" : localAtendimento2.toString();
  let atendenteAtendimento = dadosAtendimento.keyAtendente === "Não informado" || dadosAtendimento.keyAtendente === undefined || dadosAtendimento.keyAtendente === null || dadosAtendimento.keyAtendente === '' ? "Não informado" : dadosAtendimento.keyAtendente;
  let dataVencimentoUnix = '';
  const descricaoAtendimento = descricaoAtendimento2.trim();
  return dispatch => {

    const timeAtual = retornaHoraAtual();
    const dataHoraAtual = retornaDataHoraAtual();
    const dataAtualPostagem = retornaDataAtual();
    let AbertoVencimento = dataVencimento.toString() == "Não informado" ? 0 : dataVencimento;
    let setorAtendimento = setorAtendimento2.toString() == "" ? "Não informado" : setorAtendimento2.toString();

    let AbertoVencimentoAtendente = {};
    //  let testeObjeto = {};
    let Aberto = '';
    if (AbertoVencimento !== 0) {
      AbertoVencimento = moment(AbertoVencimento, 'DD/MM/YYYY').unix();
      dataVencimentoUnix = AbertoVencimento;
    } else {
      AbertoVencimento = false;
    }
    if (statusChamado == 'Aberto') {
      Aberto = 'Aberto'
      // AbertoVencimentoAtendente[atendenteAtendimento] = AbertoVencimento;
      //    testeObjeto[Aberto] = AbertoVencimentoAtendente;
      AbertoVencimento = { Aberto: AbertoVencimento }
    }
    if (statusChamado == 'Atendendo') {
      Aberto = 'Atendendo'
      // AbertoVencimentoAtendente[atendenteAtendimento] = AbertoVencimento;
      //  testeObjeto[Aberto] = AbertoVencimentoAtendente;
      AbertoVencimento = { Atendendo: AbertoVencimento }
    }
    if (statusChamado == 'Fechado') {
      Aberto = 'Fechado'
      //  AbertoVencimentoAtendente[atendenteAtendimento] = AbertoVencimento;
      // testeObjeto[Aberto] = AbertoVencimentoAtendente;
      AbertoVencimento = { Fechado: AbertoVencimento }
    }
    if (statusChamado == 'Cancelado') {
      Aberto = 'Cancelado';
      //AbertoVencimentoAtendente[atendenteAtendimento] = AbertoVencimento;
      //  testeObjeto[Aberto] = AbertoVencimentoAtendente;
      AbertoVencimento = { Cancelado: AbertoVencimento }

    }


    if (descricaoAtendimento == "" && criarChamadoProblemaEstatico === false) {
      toastr.error('Error', 'informe a DESCRIÇÃO DO PROBLEMA');
    } else if (criarChamadoProblemaEstatico === true && problemaEstaticoSelecionado === "") {
      toastr.error('Error', 'Selecione o PROBLEMA');
    } else {

      AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
        dispatch({ type: MODIFICAVISIBILIDADEMODALALTERARCHAT, payload: false });
        //  dispatch(NavigationActions.back());
        const chaveUnica = chaveUnica2.replace(/"/g, "");

        firebase.database().ref(`${chaveUnica}/usuarios/`).orderByChild("uid").equalTo(firebase.auth().currentUser.uid).once("value").then(snapshot => {
          let arrayNomeQuemAbriuChamado = [];
          snapshot.forEach(childItem => {
            arrayNomeQuemAbriuChamado.push({
              nomeDeQuemAbriuChamado: childItem.val().nome
            });
          });
          let keyAtendenteQueAlterouChamado = Object.keys(snapshot.val()).toString();
          firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}`).update({
            // atendente: atendenteAtendimento,
            //cliente: clienteAtendimento,
            // descricaoProblema: descricaoAtendimento,
            descricaoProblema: criarChamadoProblemaEstatico == true ? nomeProblemaEstaticoSelecionado : descricaoAtendimento,
            descricaoProblemaEstatico: criarChamadoProblemaEstatico == true ? 1 : 0,
            keyProblemaEstatico: criarChamadoProblemaEstatico == true ? problemaEstaticoSelecionado : '',
            local: localAtendimento,
            prioridade: prioridadeAtendimento,
            setor: setorAtendimento,
            //status_atendente: `${statusChamado}${atendenteAtendimento}`,
            //        status: (nomeAuxiliarAtendente.toString() == 'Não informado') ? 'Aberto' : 'Atendendo',
            dataVencimento: dataVencimento,
            // clienteVinculado: clienteAtendimento == "Não informado" ? 0 : 1,
            setorVinculado: setorAtendimento == "Não informado" ? 0 : 1,
            keyAtendenteQueAlterouChamado: keyAtendenteQueAlterouChamado,
            // keyEmpresaVinculada,

            //status_cliente: `${statusChamado}${clienteAtendimento}`, //filtro cliente comum
            status_setor: `${statusChamado}${setorAtendimento}`, //filtro setor comum
            status_prioridade: `${statusChamado}${prioridadeAtendimento}`, // filtro prioridade comum
            status_local: `${statusChamado}${localAtendimento}`, // filtro local comum
            status_dataVencimento: AbertoVencimento, // filtro local comum
            status_numeroChamado: `${statusChamado}_${dadosAtendimento.numeroChamado}`,

            //status_atendente_cliente: `${statusChamado}${atendenteAtendimento}${clienteAtendimento}`, //filtro cliente vê proprio chamado
            // status_atendente_setor: `${statusChamado}${atendenteAtendimento}${setorAtendimento}`, //filtro setor vê proprio chamado
            // status_atendente_prioridade: `${statusChamado}${atendenteAtendimento}${prioridadeAtendimento}`, // filtro prioridade vê proprio chamado
            // status_atendente_local: `${statusChamado}${atendenteAtendimento}${localAtendimento}`, // filtro local vê proprio chamado
            //  status_atendente_dataVencimento: testeObjeto, // filtro local vê proprio chamado
            //  status_atendente_numeroChamado: `${statusChamado}_${atendenteAtendimento}${dadosAtendimento.numeroChamado}`,

            // atendente_cliente: `${atendenteAtendimento}${clienteAtendimento}`,
            // atendente_setor: `${atendenteAtendimento}${setorAtendimento}`,
            //  atendente_prioridade: `${atendenteAtendimento}${prioridadeAtendimento}`,
            // atendente_local: `${atendenteAtendimento}${localAtendimento}`,
            // atendente_dataVencimento: AbertoVencimentoAtendente,
            // atendente_numeroChamado: `${atendenteAtendimento}${dadosAtendimento.numeroChamado}`,
            dataVencimentoUnix: dataVencimentoUnix

          })
            .then(sucess => {
              if (arrayInformacoesAnteriores[0].prioridade !== prioridadeAtendimento) {
                firebase.database().ref(`${chaveUnica}/acoesChamados/${keyAtendimento}/`).push({
                  tipo: "mensagem",
                  titulo: arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado,
                  mensagem: arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado + " Alterou prioridade do atendimento de " +
                    arrayInformacoesAnteriores[0].prioridade + " para " + prioridadeAtendimento,
                  dataHoraAtual: dataHoraAtual,
                  dataAtualPostagem: dataAtualPostagem,
                  time: timeAtual,
                  lineColor: "#00FF7F",
                  circleColor: "#00FF7F",
                  visivelATodos: 0,
                  keyDeQuemAlterouChamado: keyAtendenteQueAlterouChamado
                })/* .then(() => {
                  firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}`).update({
                    lastMessageChat: arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado + " Alterou prioridade do atendimento de " +
                      arrayInformacoesAnteriores[0].prioridade + " para " + prioridadeAtendimento,
                    lastMessageChatTimeStamp: firebase.database.ServerValue.TIMESTAMP,
                    lastmessageChatCount: 0,
                    lastmessageChatType: 'mensagem',
                    lastMessageLineColor: '#00FF7F'
                  })
                }); */
              }
              if (arrayInformacoesAnteriores[0].local !== localAtendimento) {
                firebase.database().ref(`${chaveUnica}/acoesChamados/${keyAtendimento}/`).push({
                  tipo: "mensagem",
                  titulo:

                    arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado,
                  mensagem:
                    arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado +
                    " Alterou o local do atendimento de " +
                    arrayInformacoesAnteriores[0].local +
                    " para " +
                    localAtendimento,
                  dataHoraAtual: dataHoraAtual,
                  dataAtualPostagem: dataAtualPostagem,
                  time: timeAtual,
                  lineColor: "#00FF7F",
                  circleColor: "#00FF7F",
                  visivelATodos: 0,
                  keyDeQuemAlterouChamado: keyAtendenteQueAlterouChamado
                })/* .then(() => {
                  firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}`).update({
                    lastMessageChat: arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado +
                      " Alterou o local do atendimento de " +
                      arrayInformacoesAnteriores[0].local +
                      " para " +
                      localAtendimento,
                    lastMessageChatTimeStamp: firebase.database.ServerValue.TIMESTAMP,
                    lastmessageChatCount: 0,
                    lastmessageChatType: 'mensagem',
                    lastMessageLineColor: '#00FF7F'
                  })
                }); */
              }
              if (arrayInformacoesAnteriores[0].dataVencimento !== dataVencimento) {
                firebase.database().ref(`${chaveUnica}/acoesChamados/${keyAtendimento}/`).push({
                  tipo: "mensagem",
                  titulo:

                    arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado,
                  mensagem:
                    " Alterou a data de vencimento de " +
                    arrayInformacoesAnteriores[0].dataVencimento +
                    " para " +
                    dataVencimento,
                  dataHoraAtual: dataHoraAtual,
                  dataAtualPostagem: dataAtualPostagem,
                  time: timeAtual,
                  lineColor: "#00FF7F",
                  circleColor: "#00FF7F",
                  visivelATodos: 1,
                  keyDeQuemAlterouChamado: keyAtendenteQueAlterouChamado
                })/* .then(() => {
                  firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}`).update({
                    lastMessageChat: " Alterou a data de vencimento de " +
                      arrayInformacoesAnteriores[0].dataVencimento +
                      " para " +
                      dataVencimento,
                    lastMessageChatTimeStamp: firebase.database.ServerValue.TIMESTAMP,
                    lastmessageChatCount: 0,
                    lastmessageChatType: 'mensagem',
                    lastMessageLineColor: '#00FF7F'
                  })
                });*/
              }
              let descAux = criarChamadoProblemaEstatico == true ? nomeProblemaEstaticoSelecionado : descricaoAtendimento;
              if (arrayInformacoesAnteriores[0].descricaoProblema !== descricaoAtendimento && criarChamadoProblemaEstatico === false || problemaEstaticoSelecionado !== arrayInformacoesAnteriores[0].keyProblemaEstatico && problemaEstaticoSelecionado !== "" && criarChamadoProblemaEstatico === true) {
                firebase.database().ref(`${chaveUnica}/acoesChamados/${keyAtendimento}/`).push({
                  tipo: "mensagem",
                  titulo:

                    arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado,
                  mensagem: arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado +
                    " Alterou a descrição do problema de " +
                    arrayInformacoesAnteriores[0].descricaoProblema +
                    " para " +
                    descAux,
                  dataHoraAtual: dataHoraAtual,
                  dataAtualPostagem: dataAtualPostagem,
                  time: timeAtual,
                  lineColor: "#00FF7F",
                  circleColor: "#00FF7F",
                  visivelATodos: 1,
                  keyDeQuemAlterouChamado: keyAtendenteQueAlterouChamado
                })/* .then(() => {
                  firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}`).update({
                    lastMessageChat: arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado +
                      " Alterou a descrição do problema de " +
                      arrayInformacoesAnteriores[0].descricaoProblema +
                      " para " +
                      descricaoAtendimento,
                    lastMessageChatTimeStamp: firebase.database.ServerValue.TIMESTAMP,
                    lastmessageChatCount: 0,
                    lastmessageChatType: 'mensagem',
                    lastMessageLineColor: '#00FF7F',
                    lastmessageChatTypeProblem: 'mensagem',
                  })
                }); */
              }


              ativaDesativaLoadingTelaAcoesAtendimento(dispatch, false);
              //dispatch(NavigationActions.back());

              //dispatch({ type: VERIFICACHAMADOALTERADOSUCESSO, payload: "chamadoAlteradoComSucesso" });
            });
        });
      });
    }


  }
}
export const alterarChamado = (
  clienteAtendimento2,
  atendenteAtendimento2,
  setorAtendimento2,
  prioridadeAtendimento2,
  localAtendimento2,
  descricaoAtendimento2,
  dataVencimento2,
  keyAtendimento,
  arrayInformacoesAnteriores,
  nomeAuxiliarAtendente2,
  nomeAuxiliarCliente2,
  nomeAuxiliarSetor2,
  keyEmpresaVinculada2,
  statusChamado,
  dadosAtendimento, criarChamadoProblemaEstatico,
  problemaEstaticoSelecionado2, nomeProblemaEstaticoSelecionado, utilizaProblemaEstatico,
) => {
  let keyEmpresaVinculada;
  if (keyEmpresaVinculada2 != "" && keyEmpresaVinculada2 != undefined) {
    keyEmpresaVinculada = keyEmpresaVinculada2;
  } else {
    keyEmpresaVinculada = 'Não Vinculado'
  }

  let problemaEstaticoSelecionado = '';
  if (problemaEstaticoSelecionado2.length > 0) {
    problemaEstaticoSelecionado = problemaEstaticoSelecionado2
  }
  /*
   */
  let nomeAuxiliarAtendente = nomeAuxiliarAtendente2.toString() == "" ? "Não informado" : nomeAuxiliarAtendente2;
  let nomeAuxiliarCliente = nomeAuxiliarCliente2.toString() == "" ? "Não informado" : nomeAuxiliarCliente2;
  let nomeAuxiliarSetor = nomeAuxiliarSetor2.toString() == "" ? "Não informado" : nomeAuxiliarSetor2;

  let dataVencimento = dataVencimento2.toString() == "" ? "Não informado" : dataVencimento2;
  let clienteAtendimento = clienteAtendimento2.toString() == "" ? "Não informado" : clienteAtendimento2.toString();
  let prioridadeAtendimento = prioridadeAtendimento2.toString() == "" ? "Não informado" : prioridadeAtendimento2.toString();
  let atendenteAtendimento = atendenteAtendimento2.toString() == "" ? "Não informado" : atendenteAtendimento2.toString();
  let setorAtendimento = setorAtendimento2.toString() == "" ? "Não informado" : setorAtendimento2.toString();
  let localAtendimento = localAtendimento2.toString() == "" ? "Não informado" : localAtendimento2.toString();
  let dataVencimentoUnix = '';
  const descricaoAtendimento = descricaoAtendimento2.trim();
  return dispatch => {
    const timeAtual = retornaHoraAtual();
    const dataHoraAtual = retornaDataHoraAtual();
    const dataAtualPostagem = retornaDataAtual();
    let AbertoVencimento = dataVencimento.toString() == "Não informado" ? 0 : dataVencimento;
    let AbertoVencimentoAtendente = {};
    let testeObjeto = {};
    let Aberto = '';
    if (AbertoVencimento !== 0) {
      AbertoVencimento = moment(AbertoVencimento, 'DD/MM/YYYY').unix();
      dataVencimentoUnix = AbertoVencimento;
    } else {
      AbertoVencimento = false;
    }
    if (statusChamado == 'Aberto') {
      Aberto = 'Aberto'
      AbertoVencimentoAtendente[atendenteAtendimento] = AbertoVencimento;
      testeObjeto[Aberto] = AbertoVencimentoAtendente;
      AbertoVencimento = { Aberto: AbertoVencimento }
    }
    if (statusChamado == 'Atendendo') {
      Aberto = 'Atendendo'
      AbertoVencimentoAtendente[atendenteAtendimento] = AbertoVencimento;
      testeObjeto[Aberto] = AbertoVencimentoAtendente;
      AbertoVencimento = { Atendendo: AbertoVencimento }
    }
    if (statusChamado == 'Fechado') {
      Aberto = 'Fechado'
      AbertoVencimentoAtendente[atendenteAtendimento] = AbertoVencimento;
      testeObjeto[Aberto] = AbertoVencimentoAtendente;
      AbertoVencimento = { Fechado: AbertoVencimento }
    }
    if (statusChamado == 'Cancelado') {
      Aberto = 'Cancelado';
      AbertoVencimentoAtendente[atendenteAtendimento] = AbertoVencimento;
      testeObjeto[Aberto] = AbertoVencimentoAtendente;
      AbertoVencimento = { Cancelado: AbertoVencimento }

    }


    if (descricaoAtendimento == "" && criarChamadoProblemaEstatico === false) {
      toastr.error('Error', 'informe a DESCRIÇÃO DO PROBLEMA');
    } else if (criarChamadoProblemaEstatico === true && problemaEstaticoSelecionado === "") {
      toastr.error('Error', 'Selecione o PROBLEMA');
    } else {
      AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
        ativaDesativaLoadingTelaAcoesAtendimento(dispatch, true);
        dispatch({ type: MODIFICAVISIBILIDADEMODALALTERARATENDIMENTO, payload: false });
        //  dispatch(NavigationActions.back());
        const chaveUnica = chaveUnica2.replace(/"/g, "");

        firebase.database().ref(`${chaveUnica}/usuarios/`).orderByChild("uid").equalTo(firebase.auth().currentUser.uid).once("value").then(snapshot => {
          let arrayNomeQuemAbriuChamado = [];
          snapshot.forEach(childItem => {
            arrayNomeQuemAbriuChamado.push({
              nomeDeQuemAbriuChamado: childItem.val().nome
            });
          });
          let keyAtendenteQueAlterouChamado = Object.keys(snapshot.val()).toString();
          firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}`).update({
            atendente: atendenteAtendimento,
            cliente: clienteAtendimento,
            // descricaoProblema: descricaoAtendimento,
            descricaoProblema: criarChamadoProblemaEstatico == true ? nomeProblemaEstaticoSelecionado : descricaoAtendimento,
            descricaoProblemaEstatico: criarChamadoProblemaEstatico == true ? 1 : 0,
            keyProblemaEstatico: criarChamadoProblemaEstatico == true ? problemaEstaticoSelecionado : '',
            local: localAtendimento,
            prioridade: prioridadeAtendimento,
            setor: setorAtendimento,
            status_atendente: `${statusChamado}${atendenteAtendimento}`,
            //        status: (nomeAuxiliarAtendente.toString() == 'Não informado') ? 'Aberto' : 'Atendendo',
            dataVencimento: dataVencimento,
            clienteVinculado: clienteAtendimento == "Não informado" ? 0 : 1,
            setorVinculado: setorAtendimento == "Não informado" ? 0 : 1,
            keyAtendenteQueAlterouChamado: keyAtendenteQueAlterouChamado,
            keyEmpresaVinculada,

            status_cliente: `${statusChamado}${clienteAtendimento}`, //filtro cliente comum
            status_setor: `${statusChamado}${setorAtendimento}`, //filtro setor comum
            status_prioridade: `${statusChamado}${prioridadeAtendimento}`, // filtro prioridade comum
            status_local: `${statusChamado}${localAtendimento}`, // filtro local comum
            status_dataVencimento: AbertoVencimento, // filtro local comum
            status_numeroChamado: `${statusChamado}_${dadosAtendimento.numeroChamado}`,

            status_atendente_cliente: `${statusChamado}${atendenteAtendimento}${clienteAtendimento}`, //filtro cliente vê proprio chamado
            status_atendente_setor: `${statusChamado}${atendenteAtendimento}${setorAtendimento}`, //filtro setor vê proprio chamado
            status_atendente_prioridade: `${statusChamado}${atendenteAtendimento}${prioridadeAtendimento}`, // filtro prioridade vê proprio chamado
            status_atendente_local: `${statusChamado}${atendenteAtendimento}${localAtendimento}`, // filtro local vê proprio chamado
            status_atendente_dataVencimento: testeObjeto, // filtro local vê proprio chamado
            status_atendente_numeroChamado: `${statusChamado}_${atendenteAtendimento}${dadosAtendimento.numeroChamado}`,

            atendente_cliente: `${atendenteAtendimento}${clienteAtendimento}`,
            atendente_setor: `${atendenteAtendimento}${setorAtendimento}`,
            atendente_prioridade: `${atendenteAtendimento}${prioridadeAtendimento}`,
            atendente_local: `${atendenteAtendimento}${localAtendimento}`,
            atendente_dataVencimento: AbertoVencimentoAtendente,
            atendente_numeroChamado: `${atendenteAtendimento}${dadosAtendimento.numeroChamado}`,
            dataVencimentoUnix: dataVencimentoUnix

          })
            .then(sucess => {
              if (setorAtendimento !== "Não informado") {
                firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/usuariosVisualizaAtend/`).remove().then(
                  () => {
                    //busco clientes vinculados ao setor, se nao tiver eu seto todos os atendentes se tiver seto apenas os atendentes vinculados
                    firebase.database().ref(`${chaveUnica}/setores/${setorAtendimento}/atendentesVinculados`).once('value').then((snapshotSetor) => {
                      if (snapshotSetor.val()) {
                        let dadosAtendVinc = {}

                        let keyAtendentesVinculados = Object.keys(snapshotSetor.val());

                        for (let i = 0; i < keyAtendentesVinculados.length; i++) {
                          if (keyAtendentesVinculados[i].includes('codigoInterno')) {
                            dadosAtendVinc[keyAtendentesVinculados[i] + "status"] = keyAtendentesVinculados[i] + statusChamado;
                            if (statusChamado == 'Aberto') {
                              dadosAtendVinc[keyAtendentesVinculados[i]] = keyAtendentesVinculados[i];
                            }
                          }

                        }

                        /*  let dadosAtendVincArray = [];
                         dadosAtendVincArray.push(dadosAtendVinc)


                         for (let i = 0; i < dadosAtendVincArray.length; i++) {
                           dadosAtendVinc = dadosAtendVincArray[i]
                         } */

                        //tem atendentes vinculados ao setor
                        const dadosAtendentesVinculadosSetor = [];
                        snapshotSetor.forEach((childItem) => {
                          dadosAtendentesVinculadosSetor.push({
                            key: childItem.key,
                          });
                          /*     if (childItem.key) {
                                if (childItem.key.includes('codigo') && dadosAtendimento.channelChamado && dadosAtendimento.channelChamado.toLowerCase().includes('chat')) {
                                  dadosAtendVinc[`${childItem.key}Chat`] = `${childItem.key}Chat`;
                                  dadosAtendVinc[`${childItem.key}Chatstatus`] = `${childItem.key}Chat${statusChamado}`;
                                } else if (dadosAtendimento.channelChamado && dadosAtendimento.channelChamado.toLowerCase().includes('chat')) {
                                  dadosAtendVinc[`${childItem.key}Chat`] = true;
                                }
                              } */
                        })

                        let verificoSeAtendenteDoChamadoNaoEstaNoSetorVinculado = 0;
                        if (atendenteAtendimento !== 'Não informado') {
                          //verifico se o atendente do chamado nao esta vinculado a este setor
                          for (let i = 0; i < dadosAtendentesVinculadosSetor.length; i++) {
                            if (dadosAtendentesVinculadosSetor[i].key == atendenteAtendimento) {
                              verificoSeAtendenteDoChamadoNaoEstaNoSetorVinculado = 1;

                            }
                          }
                          //atendente nao esta no grupo porem foi vinculado ao atendimento, coloco ele nos vinculados do chamado
                          if (verificoSeAtendenteDoChamadoNaoEstaNoSetorVinculado == 0) {
                            let objetocadastro = {};
                            firebase.database().ref(`${chaveUnica}/usuarios/${atendenteAtendimento}`).once("value").then((snapshot) => {
                              let key = snapshot.key;
                              let codigoInterno = snapshot.val().codigoInterno;
                              if (statusChamado == 'Aberto') {
                                objetocadastro[codigoInterno] = codigoInterno;
                              }
                              objetocadastro[codigoInterno + "status"] = codigoInterno + statusChamado;

                              //objetocadastro[key] = true;

                              firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/usuariosVisualizaAtend/dadosAtendVinc`).update(objetocadastro)
                            })

                          } else {
                            //creio que ja cai na situaçaão acima
                          }
                        }

                        firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/usuariosVisualizaAtend`).update({ dadosAtendVinc })
                      } else {
                        //
                        firebase.database().ref(`${chaveUnica}/usuarios/`).orderByChild('excluido').equalTo(null).once('value').then((snapshot) => {
                          const idTodosAtendentes = [];
                          let objetocadastro = {};
                          snapshot.forEach((childItem) => {
                            idTodosAtendentes.push({
                              key: childItem.key,
                              codigoInterno: childItem.val().codigoInterno == undefined ? 'codigoInterno1' : childItem.val().codigoInterno,
                            });
                          })
                          //
                          for (let i = 0; i < idTodosAtendentes.length; i++) {
                            //  objetocadastro[idTodosAtendentes[i].key] = true;
                            if (statusChamado == 'Aberto') {
                              objetocadastro[idTodosAtendentes[i].codigoInterno] = idTodosAtendentes[i].codigoInterno;
                            }
                            objetocadastro[idTodosAtendentes[i].codigoInterno + "status"] = idTodosAtendentes[i].codigoInterno + statusChamado;

                          }
                          //
                          firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/usuariosVisualizaAtend/dadosAtendVinc`).update(objetocadastro)
                        })

                      }
                    })
                  })
              } else if (atendenteAtendimento !== "Não informado") {
                //verifica se o atendente é dierente de naão informado, porque se for, preciso criar o campo atendentes vinculados pra esse atendente visualizar este atendimento
                //
                /*      let dadosAtendVinc = { vazio: 'vazio' };
                     firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}`).update({ usuariosVisualizaAtend: dadosAtendVinc }) */
                let objetocadastro = {};
                firebase.database().ref(`${chaveUnica}/usuarios/${atendenteAtendimento}`).once("value").then((snapshot) => {
                  let key = snapshot.key;
                  let codigoInterno = snapshot.val().codigoInterno;
                  if (statusChamado == 'Aberto') {
                    objetocadastro[codigoInterno] = codigoInterno;
                  }
                  objetocadastro[codigoInterno + "status"] = codigoInterno + statusChamado;


                  // objetocadastro[key] = true;

                  //
                  // firebase.database().ref(`${chaveUnica}/dadosAtendVinc/${key}/${dadosChamado.key}`).push({ true: 'true' });
                  firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/usuariosVisualizaAtend/`).remove().then(
                    () => {
                      firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/usuariosVisualizaAtend/dadosAtendVinc`).update(objetocadastro)
                    })
                  //firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/usuariosVisualizaAtend/dadosAtendVinc`).update(objetocadastro)
                })
              }

              if (arrayInformacoesAnteriores[0].prioridade !== prioridadeAtendimento) {
                firebase.database().ref(`${chaveUnica}/acoesChamados/${keyAtendimento}/`).push({
                  tipo: "mensagem",
                  titulo: arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado,
                  mensagem: arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado + " Alterou prioridade do atendimento de " +
                    arrayInformacoesAnteriores[0].prioridade + " para " + prioridadeAtendimento,
                  dataHoraAtual: dataHoraAtual,
                  dataAtualPostagem: dataAtualPostagem,
                  time: timeAtual,
                  lineColor: "#00FF7F",
                  circleColor: "#00FF7F",
                  visivelATodos: 0,
                  keyDeQuemAlterouChamado: keyAtendenteQueAlterouChamado
                })/* .then(() => {
                  firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}`).update({
                    lastMessageChat: arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado + " Alterou prioridade do atendimento de " +
                      arrayInformacoesAnteriores[0].prioridade + " para " + prioridadeAtendimento,
                    lastMessageChatTimeStamp: firebase.database.ServerValue.TIMESTAMP,
                    lastmessageChatCount: 0,
                    lastmessageChatType: 'mensagem',
                    lastMessageLineColor: '#00FF7F'
                  })
                }); */
              }
              if (arrayInformacoesAnteriores[0].local !== localAtendimento) {
                firebase.database().ref(`${chaveUnica}/acoesChamados/${keyAtendimento}/`).push({
                  tipo: "mensagem",
                  titulo:

                    arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado,
                  mensagem:
                    arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado +
                    " Alterou o local do atendimento de " +
                    arrayInformacoesAnteriores[0].local +
                    " para " +
                    localAtendimento,
                  dataHoraAtual: dataHoraAtual,
                  dataAtualPostagem: dataAtualPostagem,
                  time: timeAtual,
                  lineColor: "#00FF7F",
                  circleColor: "#00FF7F",
                  visivelATodos: 0,
                  keyDeQuemAlterouChamado: keyAtendenteQueAlterouChamado
                })/* .then(() => {
                  firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}`).update({
                    lastMessageChat: arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado +
                      " Alterou o local do atendimento de " +
                      arrayInformacoesAnteriores[0].local +
                      " para " +
                      localAtendimento,
                    lastMessageChatTimeStamp: firebase.database.ServerValue.TIMESTAMP,
                    lastmessageChatCount: 0,
                    lastmessageChatType: 'mensagem',
                    lastMessageLineColor: '#00FF7F'
                  })
                }); */
              }
              if (arrayInformacoesAnteriores[0].dataVencimento !== dataVencimento) {
                firebase.database().ref(`${chaveUnica}/acoesChamados/${keyAtendimento}/`).push({
                  tipo: "mensagem",
                  titulo:

                    arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado,
                  mensagem:
                    " Alterou a data de vencimento de " +
                    arrayInformacoesAnteriores[0].dataVencimento +
                    " para " +
                    dataVencimento,
                  dataHoraAtual: dataHoraAtual,
                  dataAtualPostagem: dataAtualPostagem,
                  time: timeAtual,
                  lineColor: "#00FF7F",
                  circleColor: "#00FF7F",
                  visivelATodos: 1,
                  keyDeQuemAlterouChamado: keyAtendenteQueAlterouChamado
                })/* .then(() => {
                  firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}`).update({
                    lastMessageChat: " Alterou a data de vencimento de " +
                      arrayInformacoesAnteriores[0].dataVencimento +
                      " para " +
                      dataVencimento,
                    lastMessageChatTimeStamp: firebase.database.ServerValue.TIMESTAMP,
                    lastmessageChatCount: 0,
                    lastmessageChatType: 'mensagem',
                    lastMessageLineColor: '#00FF7F'
                  })
                }); */
              }
              let descAux = criarChamadoProblemaEstatico == true ? nomeProblemaEstaticoSelecionado : descricaoAtendimento;
              if (arrayInformacoesAnteriores[0].descricaoProblema !== descricaoAtendimento && criarChamadoProblemaEstatico === false || problemaEstaticoSelecionado !== arrayInformacoesAnteriores[0].keyProblemaEstatico && problemaEstaticoSelecionado !== "" && criarChamadoProblemaEstatico === true) {
                firebase.database().ref(`${chaveUnica}/acoesChamados/${keyAtendimento}/`).push({
                  tipo: "mensagem",
                  titulo:

                    arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado,
                  mensagem:
                    arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado +
                    " Alterou a descrição do problema de " +
                    arrayInformacoesAnteriores[0].descricaoProblema +
                    " para " +
                    descAux,
                  dataHoraAtual: dataHoraAtual,
                  dataAtualPostagem: dataAtualPostagem,
                  time: timeAtual,
                  lineColor: "#00FF7F",
                  circleColor: "#00FF7F",
                  visivelATodos: 1,
                  keyDeQuemAlterouChamado: keyAtendenteQueAlterouChamado
                })/* .then(() => {
                  firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}`).update({
                    lastMessageChat: arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado +
                      " Alterou a descrição do problema de " +
                      arrayInformacoesAnteriores[0].descricaoProblema +
                      " para " +
                      descAux,
                    lastMessageChatTimeStamp: firebase.database.ServerValue.TIMESTAMP,
                    lastmessageChatCount: 0,
                    lastmessageChatType: 'mensagem',
                    lastMessageLineColor: '#00FF7F',
                    lastmessageChatTypeProblem: 'mensagem',
                  })
                }); */
              }
              if (arrayInformacoesAnteriores[0].keyAtendente !== atendenteAtendimento) {
                firebase.database().ref(`${chaveUnica}/acoesChamados/${keyAtendimento}/`).push({
                  tipo: "mensagem",
                  titulo:

                    arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado,
                  mensagem:
                    arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado +
                    " Alterou o atendente de " +
                    arrayInformacoesAnteriores[0].atendente +
                    " para " +
                    nomeAuxiliarAtendente,
                  dataHoraAtual: dataHoraAtual,
                  dataAtualPostagem: dataAtualPostagem,
                  time: timeAtual,
                  lineColor: "#00FF7F",
                  circleColor: "#00FF7F",
                  visivelATodos: 1,
                  keyDeQuemAlterouChamado: keyAtendenteQueAlterouChamado
                })/* .then(() => {
                  firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}`).update({
                    lastMessageChat: arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado +
                      " Alterou o atendente de " +
                      arrayInformacoesAnteriores[0].atendente +
                      " para " +
                      nomeAuxiliarAtendente,
                    lastMessageChatTimeStamp: firebase.database.ServerValue.TIMESTAMP,
                    lastmessageChatCount: 0,
                    lastmessageChatType: 'mensagem',
                    lastMessageLineColor: '#00FF7F'
                  })
                }); */
              }
              if (arrayInformacoesAnteriores[0].keyCliente !== clienteAtendimento) {
                firebase.database().ref(`${chaveUnica}/acoesChamados/${keyAtendimento}/`).push({
                  tipo: "mensagem",
                  titulo:

                    arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado,
                  mensagem:
                    arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado +
                    " Alterou o cliente de " +
                    arrayInformacoesAnteriores[0].cliente +
                    " para " +
                    nomeAuxiliarCliente,
                  dataHoraAtual: dataHoraAtual,
                  dataAtualPostagem: dataAtualPostagem,
                  time: timeAtual,
                  lineColor: "#00FF7F",
                  circleColor: "#00FF7F",
                  visivelATodos: 0,
                  keyDeQuemAlterouChamado: keyAtendenteQueAlterouChamado
                })/* .then(() => {
                  firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}`).update({
                    lastMessageChat: arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado +
                      " Alterou o cliente de " +
                      arrayInformacoesAnteriores[0].cliente +
                      " para " +
                      nomeAuxiliarCliente,
                    lastMessageChatTimeStamp: firebase.database.ServerValue.TIMESTAMP,
                    lastmessageChatCount: 0,
                    lastmessageChatType: 'mensagem',
                    lastMessageLineColor: '#00FF7F'
                  })
                }); */
              }
              if (arrayInformacoesAnteriores[0].keySetor !== setorAtendimento) {
                firebase
                  .database()
                  .ref(`${chaveUnica}/acoesChamados/${keyAtendimento}/`)
                  .push({
                    tipo: "mensagem",
                    titulo:

                      arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado,
                    mensagem:
                      arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado +
                      " Alterou o setor de " +
                      arrayInformacoesAnteriores[0].setor +
                      " para " +
                      nomeAuxiliarSetor,
                    dataHoraAtual: dataHoraAtual,
                    dataAtualPostagem: dataAtualPostagem,
                    time: timeAtual,
                    lineColor: "#00FF7F",
                    circleColor: "#00FF7F",
                    visivelATodos: 0,
                    keyDeQuemAlterouChamado: keyAtendenteQueAlterouChamado
                  })/* .then(() => {
                    firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}`).update({
                      lastMessageChat: arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado +
                        " Alterou o setor de " +
                        arrayInformacoesAnteriores[0].setor +
                        " para " +
                        nomeAuxiliarSetor,
                      lastMessageChatTimeStamp: firebase.database.ServerValue.TIMESTAMP,
                      lastmessageChatCount: 0,
                      lastmessageChatType: 'mensagem',
                      lastMessageLineColor: '#00FF7F'
                    })
                  }); */
              }
              ativaDesativaLoadingTelaAcoesAtendimento(dispatch, false);
              toastr.success('Sucesso', 'Atendimento alterado com sucesso!')
              //dispatch(NavigationActions.back());

              //dispatch({ type: VERIFICACHAMADOALTERADOSUCESSO, payload: "chamadoAlteradoComSucesso" });
            });
        });
      });
    }
  };
};

export const cadastraNovoAtendimento = (
  clienteAtendimento2,
  atendenteAtendimento2,
  setorAtendimento2,
  prioridadeAtendimento2,
  localAtendimento2,
  descricaoAtendimento2,
  chamadosAnteriores2,
  nomeAuxCliente,
  nomeAuxAtendente,
  dataVencimento2,
  arquivosParaSerUpadosNaAberturaChamado,
  empresaSelecionada2,
  keyEmpresaVinculada2,
  atendenteVeApenasProprioChamado,
  agruparAtendenteSetor,
  contaPrincipal,
  nomeAuxSetor,
  criarChamadoProblemaEstatico,
  problemaEstaticoSelecionado2,
  nomeProblemaSelecionado,
) => {
  let problemaEstaticoSelecionado = '';
  if (problemaEstaticoSelecionado2.length > 0) {
    problemaEstaticoSelecionado = problemaEstaticoSelecionado2;
  }
  /*   console.log(  empresaSelecionada2,
      keyEmpresaVinculada2) */
  let keyEmpresaVinculada;
  if (keyEmpresaVinculada2 != "" && keyEmpresaVinculada2 != undefined) {
    keyEmpresaVinculada = keyEmpresaVinculada2;
  } else {
    keyEmpresaVinculada = 'Não Vinculado';
  }
  let empresaSelecionada = empresaSelecionada2.toString() == "" ? "Não informado" : empresaSelecionada2.toString();
  let dataVencimento = dataVencimento2.toString() == "" ? "Não informado" : dataVencimento2;
  //let chamadosAnteriores = chamadosAnteriores2[0].clientes == "semChamadosAbertos" ? [] : chamadosAnteriores2;
  let clienteAtendimento = clienteAtendimento2.toString() == "" ? "Não informado" : clienteAtendimento2.toString();
  let prioridadeAtendimento = prioridadeAtendimento2.toString() == "" ? "Não informado" : prioridadeAtendimento2.toString();
  let atendenteAtendimento = atendenteAtendimento2.toString() == "" ? "Não informado" : atendenteAtendimento2.toString();
  let setorAtendimento = setorAtendimento2.toString() == "" ? "Não informado" : setorAtendimento2.toString();
  let localAtendimento = localAtendimento2.toString() == "" ? "Não informado" : localAtendimento2.toString();
  let chamadoAbertoPor = "admin";
  let dadosAtendVinc = '';
  let dataVencimentoUnix = '';
  /*
    console.log(
      clienteAtendimento2,
      atendenteAtendimento2,
      setorAtendimento2,
      prioridadeAtendimento2,
      localAtendimento2,
      descricaoAtendimento2,
      nomeAuxCliente,
      nomeAuxAtendente,
      dataVencimento2,
      atendenteVeApenasProprioChamado,
      agruparAtendenteSetor,
      contaPrincipal,
      nomeAuxSetor
    );  */
  let AbertoVencimento = dataVencimento2.toString() == "" ? 0 : dataVencimento2;
  if (AbertoVencimento !== 0) {
    AbertoVencimento = moment(AbertoVencimento, 'DD/MM/YYYY').unix();
    dataVencimentoUnix = AbertoVencimento;
  } else {
    AbertoVencimento = false;
  }

  let AbertoVencimentoAtendente = {};
  let testeObjeto = {};
  let Aberto = 'Aberto';
  AbertoVencimentoAtendente[atendenteAtendimento] = AbertoVencimento;
  testeObjeto[Aberto] = AbertoVencimentoAtendente;
  AbertoVencimento = { Aberto: AbertoVencimento }
  // AbertoVencimentoAtendente[atendenteAtendimento] = AbertoVencimento;
  /*  let atendenteAtendimento = atendenteAtendimento2.toString();
   let setorAtendimento = setorAtendimento2.toString();
   let prioridadeAtendimento = prioridadeAtendimento2.toString();
   let localAtendimento = localAtendimento2.toString(); */
  const dataAbertura = retornaDataAtual();
  const timeAtual = retornaHoraAtual();
  const dataHoraAtual = retornaDataHoraAtual();

  const descricaoAtendimento = descricaoAtendimento2.trim();
  return dispatch => {


    if (descricaoAtendimento == "" && criarChamadoProblemaEstatico == false) {
      toastr.error('Error', 'informe a DESCRIÇÃO DO PROBLEMA');
    } else if (criarChamadoProblemaEstatico == true && problemaEstaticoSelecionado.length == 0) {
      toastr.error('Error', 'Selecione o Problema');
      dispatch({ type: ATIVALOADINGBOTAOCADASTRARCHAMADO, payload: false })
      //  dispatch({ type: SEMCONEXAOINTERNETCADASTRACHAMADO, payload: "semProblemaEstaticoInformado" });
    } else {
      ativaLoadingBotaoCadastrarChamado(dispatch);
      AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
        const chaveUnica = chaveUnica2.replace(/"/g, "");
        //
        firebase.database().ref(`${chaveUnica}/usuarios/`).orderByChild("uid").equalTo(firebase.auth().currentUser.uid).once("value").then(snapshot => {
          let keyDeQuemAbriuChamado = Object.keys(snapshot.val())[0];
          let arrayNomeQuemAbriuChamado = [];
          snapshot.forEach(childItem => {
            arrayNomeQuemAbriuChamado.push({
              nomeDeQuemAbriuChamado: childItem.val().nome
            });
          });

          firebase.database().ref(`${chaveUnica}/proximaNumeracao/`).orderByChild("numeroAtual").once("value").then(snapshot => {
            if (snapshot.val() == null) {
              firebase.database().ref(`${chaveUnica}/proximaNumeracao`).push({ numeroAtual: 1 })
                .then(() => {
                  firebase.database().ref(`${chaveUnica}/chamados/`)
                    .push({
                      cliente: clienteAtendimento,
                      atendente: atendenteAtendimento,
                      setor: setorAtendimento,
                      prioridade: prioridadeAtendimento,
                      local: localAtendimento,
                      descricaoProblema: (criarChamadoProblemaEstatico == true) ? nomeProblemaSelecionado : descricaoAtendimento,
                      descricaoProblemaEstatico: (criarChamadoProblemaEstatico == true) ? 1 : 0,
                      keyProblemaEstatico: problemaEstaticoSelecionado,
                      // descricaoProblema: descricaoAtendimento,
                      dataAbertura: dataAbertura,
                      dataHoraAbertura: dataHoraAtual,
                      abertoPor: keyDeQuemAbriuChamado,
                      keyEmpresaVinculada,
                      status: "Aberto",
                      status_atendente: `Aberto${atendenteAtendimento}`,
                      alterado: 0,
                      numeroChamado: 1,
                      chamadoAbertoPorAdminOuCliente: chamadoAbertoPor,
                      dataVencimento: dataVencimento,
                      tempoGasto: 0,
                      clienteVinculado: clienteAtendimento == "Não informado" ? 0 : 1,
                      setorVinculado: setorAtendimento == "Não informado" ? 0 : 1,
                      dataAberturaTimeStamp: firebase.database.ServerValue.TIMESTAMP,
                      status_cliente: `Aberto${clienteAtendimento}`, //filtro cliente comum
                      status_setor: `Aberto${setorAtendimento}`, //filtro setor comum
                      status_prioridade: `Aberto${prioridadeAtendimento}`, // filtro prioridade comum
                      status_local: `Aberto${localAtendimento}`, // filtro local comum
                      status_dataVencimento: AbertoVencimento, // filtro local comum
                      status_problema: `Aberto_${descricaoAtendimento}`, // filtro local comum
                      status_numeroChamado: `Aberto_${1}`, // filtro local comum
                      status_atendente_cliente: `Aberto${atendenteAtendimento}${clienteAtendimento}`, //filtro cliente vê proprio chamado
                      status_atendente_setor: `Aberto${atendenteAtendimento}${setorAtendimento}`, //filtro setor vê proprio chamado
                      status_atendente_prioridade: `Aberto${atendenteAtendimento}${prioridadeAtendimento}`, // filtro prioridade vê proprio chamado
                      status_atendente_local: `Aberto${atendenteAtendimento}${localAtendimento}`, // filtro local vê proprio chamado
                      status_atendente_dataVencimento: testeObjeto, // filtro local vê proprio chamado
                      status_atendente_problema: `Aberto_${atendenteAtendimento}_${descricaoAtendimento}`, // filtro problema vê proprio chamado
                      status_atendente_numeroChamado: `Aberto_${atendenteAtendimento}${1}`, // filtro numero chamado vê proprio chamado

                      atendente_cliente: `${atendenteAtendimento}${clienteAtendimento}`,
                      atendente_setor: `${atendenteAtendimento}${setorAtendimento}`,
                      atendente_prioridade: `${atendenteAtendimento}${prioridadeAtendimento}`,
                      atendente_local: `${atendenteAtendimento}${localAtendimento}`,
                      atendente_dataVencimento: AbertoVencimentoAtendente,
                      atendente_numeroChamado: `${atendenteAtendimento}${1}`,
                      dataVencimentoUnix: dataVencimentoUnix,
                      channelChamado: 'adminWEB',

                      lastMessageChat: descricaoAtendimento,
                      lastMessageChatTimeStamp: firebase.database.ServerValue.TIMESTAMP,
                      lastmessageChatCount: 0,
                      lastmessageChatType: 'mensagem',
                      lastMessageLineColor: '#00FF7F'
                    })
                    .then(dadosChamado => {
                      const chamado = [
                        {
                          key: dadosChamado.key,
                          cliente: nomeAuxCliente == "" ? "Não informado" : nomeAuxCliente,
                          atendente: nomeAuxAtendente == "" ? "Não informado" : nomeAuxAtendente,
                          prioridade: prioridadeAtendimento == "" ? "Não informado" : prioridadeAtendimento,
                          setor: nomeAuxSetor == "" ? "Não informado" : nomeAuxSetor,
                          local: localAtendimento,
                          descricaoProblema: descricaoAtendimento,
                          dataAbertura: dataAbertura,
                          dataHoraAbertura: dataHoraAtual,
                          status: "Aberto",
                          alterado: 0,
                          keyCliente: clienteAtendimento,
                          chaveUnica: chaveUnica,
                          empresa: empresaSelecionada,
                          numeroChamado: 1,

                        }
                      ];
                      if (setorAtendimento !== "Não informado") {

                        //
                        firebase.database().ref(`${chaveUnica}/setores/${setorAtendimento}/atendentesVinculados`).once('value').then((snapshotSetor) => {
                          if (snapshotSetor.val()) {
                            dadosAtendVinc = snapshotSetor.val();

                            let keyAtendentesVinculados = Object.keys(snapshotSetor.val());

                            for (let i = 0; i < keyAtendentesVinculados.length; i++) {
                              if (keyAtendentesVinculados[i].includes('codigoInterno')) {
                                dadosAtendVinc[keyAtendentesVinculados[i] + "status"] = keyAtendentesVinculados[i] + "Aberto";
                              }
                            }

                            //  console.log(snapshotSetor.val());



                            const dadosAtendentesVinculadosSetor = [];
                            snapshotSetor.forEach((childItem) => {
                              dadosAtendentesVinculadosSetor.push({
                                key: childItem.key,
                              });
                            })
                            //
                            if (atendenteVeApenasProprioChamado == true && agruparAtendenteSetor == false && contaPrincipal == false) {
                              //
                              //
                              if (atendenteAtendimento == keyDeQuemAbriuChamado) {
                                // enviaChamadoAberto(dispatch, chamado.concat(chamadosAnteriores));
                              }
                            } else if (atendenteVeApenasProprioChamado == true && agruparAtendenteSetor == true && contaPrincipal == false) {
                              let verificoSeAtendenteDoChamadoNaoEstaNoSetorVinculado = 0;
                              if (atendenteAtendimento !== 'Não informado') {
                                for (let i = 0; i < dadosAtendentesVinculadosSetor.length; i++) {
                                  //
                                  if (dadosAtendentesVinculadosSetor[i].key == keyDeQuemAbriuChamado && atendenteAtendimento == keyDeQuemAbriuChamado) {
                                    //  enviaChamadoAberto(dispatch, chamado.concat(chamadosAnteriores));
                                    break;
                                  }
                                }
                                //verifico se o atendente do chamado nao esta vinculado a este setor
                                for (let i = 0; i < dadosAtendentesVinculadosSetor.length; i++) {
                                  //
                                  if (dadosAtendentesVinculadosSetor[i].key == atendenteAtendimento) {
                                    verificoSeAtendenteDoChamadoNaoEstaNoSetorVinculado = 1;

                                  }
                                }
                                //atendente nao esta no grupo porem foi vinculado ao atendimento, coloco ele nos vinculados do chamado
                                if (verificoSeAtendenteDoChamadoNaoEstaNoSetorVinculado == 0) {
                                  //
                                  let objetocadastro = {};
                                  firebase.database().ref(`${chaveUnica}/usuarios/${atendenteAtendimento}`).once("value").then((snapshot) => {
                                    let key = snapshot.key;
                                    let codigoInterno = snapshot.val().codigoInterno;
                                    objetocadastro[codigoInterno] = codigoInterno;
                                    objetocadastro[codigoInterno + "status"] = codigoInterno + "Aberto";


                                    // objetocadastro[key] = true;

                                    //
                                    // firebase.database().ref(`${chaveUnica}/dadosAtendVinc/${key}/${dadosChamado.key}`).push({ true: 'true' });
                                    firebase.database().ref(`${chaveUnica}/chamados/${dadosChamado.key}/usuariosVisualizaAtend/dadosAtendVinc`).update(objetocadastro)
                                  })
                                  if (atendenteAtendimento == keyDeQuemAbriuChamado) {
                                    //se o atendente que nao está vinculado é o mesmo que está abrindo atendimento eu envio o chamado
                                    //
                                    // enviaChamadoAberto(dispatch, chamado.concat(chamadosAnteriores));
                                  }
                                } else {
                                  //creio que ja cai na situaçaão acima
                                  //
                                }
                              } else {
                                for (let i = 0; i < dadosAtendentesVinculadosSetor.length; i++) {
                                  //
                                  if (dadosAtendentesVinculadosSetor[i].key == keyDeQuemAbriuChamado) {
                                    // enviaChamadoAberto(dispatch, chamado.concat(chamadosAnteriores));
                                    break;
                                  }
                                }
                              }
                              /* if(atendenteAtendimento == keyDeQuemAbriuChamado){
                                enviaChamadoAberto(dispatch,chamado.concat(chamadosAnteriores));
                               }else if(){

                               }else{

                               } */
                            } else {
                              //
                              //  enviaChamadoAberto(dispatch, chamado.concat(chamadosAnteriores));
                              if (atendenteAtendimento !== 'Não informado') {
                                let verificoSeAtendenteDoChamadoNaoEstaNoSetorVinculado = 0;
                                //verifico se o atendente do chamado nao esta vinculado a este setor
                                for (let i = 0; i < dadosAtendentesVinculadosSetor.length; i++) {
                                  //
                                  if (dadosAtendentesVinculadosSetor[i].key == atendenteAtendimento) {
                                    verificoSeAtendenteDoChamadoNaoEstaNoSetorVinculado = 1;

                                  }
                                }
                                if (verificoSeAtendenteDoChamadoNaoEstaNoSetorVinculado == 0) {
                                  //
                                  let objetocadastro = {};
                                  firebase.database().ref(`${chaveUnica}/usuarios/${atendenteAtendimento}`).once("value").then((snapshot) => {
                                    let key = snapshot.key;
                                    let codigoInterno = snapshot.val().codigoInterno;
                                    objetocadastro[codigoInterno] = codigoInterno;
                                    objetocadastro[codigoInterno + "status"] = codigoInterno + "Aberto";
                                    //  objetocadastro[key] = true;
                                    //
                                    // firebase.database().ref(`${chaveUnica}/dadosAtendVinc/${key}/${dadosChamado.key}`).push({ true: 'true' });
                                    firebase.database().ref(`${chaveUnica}/chamados/${dadosChamado.key}/usuariosVisualizaAtend/dadosAtendVinc`).update(objetocadastro)
                                  })

                                } else {
                                  //creio que ja cai na situaçaão acima
                                  //
                                }
                              }
                            }
                            /*   let arrayOfKeysAtendentes = Object.keys(dadosAtendVinc)

                              for (let i = 0; i < arrayOfKeysAtendentes.length; i++) {

                                firebase.database().ref(`${chaveUnica}/dadosAtendVinc/${arrayOfKeysAtendentes[i]}/${dadosChamado.key}`).push({ true: 'true' });
                              } */
                            firebase.database().ref(`${chaveUnica}/chamados/${dadosChamado.key}/usuariosVisualizaAtend`).update({ dadosAtendVinc })
                          } else {
                            //
                            firebase.database().ref(`${chaveUnica}/usuarios/`).orderByChild('excluido').equalTo(null).once('value').then((snapshot) => {
                              //
                              const idTodosAtendentes = [];
                              let objetocadastro = {};
                              snapshot.forEach((childItem) => {
                                idTodosAtendentes.push({
                                  key: childItem.key,
                                  codigoInterno: childItem.val().codigoInterno == undefined ? 'codigoInterno1' : childItem.val().codigoInterno,
                                });
                              })
                              //
                              for (let i = 0; i < idTodosAtendentes.length; i++) {
                                // objetocadastro[idTodosAtendentes[i].key] = true;
                                objetocadastro[idTodosAtendentes[i].codigoInterno] = idTodosAtendentes[i].codigoInterno;
                                objetocadastro[idTodosAtendentes[i].codigoInterno + "status"] = idTodosAtendentes[i].codigoInterno + "Aberto";
                                // firebase.database().ref(`${chaveUnica}/dadosAtendVinc/${idTodosAtendentes[i].key}/${dadosChamado.key}`).push({ true: 'true' });
                              }
                              //
                              firebase.database().ref(`${chaveUnica}/chamados/${dadosChamado.key}/usuariosVisualizaAtend/dadosAtendVinc`).update(objetocadastro)
                              // enviaChamadoAberto(dispatch, chamado.concat(chamadosAnteriores));
                            })

                          }
                        })
                      } else {
                        //verifica se o atendente é dierente de naão informado, porque se for, preciso criar o campo atendentes vinculados pra esse atendente visualizar este atendimento
                        //
                        /*  let objetocadastro = {}
                         if (atendenteAtendimento != "Não informado") {
                           //
                           //
                           objetocadastro[atendenteAtendimento] = true;
                           //
                           let dadosAtendVinc = objetocadastro;
                           // firebase.database().ref(`${chaveUnica}/dadosAtendVinc/${atendenteAtendimento}/${dadosChamado.key}`).push({ true: 'true' });
                           firebase.database().ref(`${chaveUnica}/chamados/${dadosChamado.key}/usuariosVisualizaAtend/`).update({ dadosAtendVinc })
                         } */
                        firebase.database().ref(`${chaveUnica}/usuarios/`).orderByChild('excluido').equalTo(null).once('value').then((snapshot) => {
                          //
                          const idTodosAtendentes = [];
                          let objetocadastro = {};
                          snapshot.forEach((childItem) => {
                            idTodosAtendentes.push({
                              key: childItem.key,
                              contaPrincipal: childItem.val().contaPrincipal == undefined ? false : childItem.val().contaPrincipal,
                              codigoInterno: childItem.val().codigoInterno == undefined ? 'codigoInterno1' : childItem.val().codigoInterno,
                            });
                          })
                          for (let i = 0; i < idTodosAtendentes.length; i++) {
                            if (idTodosAtendentes[i].contaPrincipal == true) {
                              // objetocadastro[idTodosAtendentes[i].key] = true;
                              objetocadastro[idTodosAtendentes[i].codigoInterno] = idTodosAtendentes[i].codigoInterno;
                              objetocadastro[idTodosAtendentes[i].codigoInterno + "status"] = idTodosAtendentes[i].codigoInterno + "Aberto";
                              // firebase.database().ref(`${chaveUnica}/dadosAtendVinc/${idTodosAtendentes[i].key}/${dadosChamado.key}`).push({ true: 'true' });
                            }
                            if (atendenteAtendimento != "Não informado" && atendenteAtendimento === idTodosAtendentes[i].key) {
                              objetocadastro[idTodosAtendentes[i].codigoInterno] = idTodosAtendentes[i].codigoInterno;
                              objetocadastro[idTodosAtendentes[i].codigoInterno + "status"] = idTodosAtendentes[i].codigoInterno + "Aberto";
                              // objetocadastro[idTodosAtendentes[i].key] = true;
                            }
                          }

                          //objetocadastro['keyChamado'] = dadosChamado.key;

                          firebase.database().ref(`${chaveUnica}/chamados/${dadosChamado.key}/usuariosVisualizaAtend/dadosAtendVinc`).update(objetocadastro)
                        })
                      }
                      firebase
                        .database().ref(`${chaveUnica}/acoesChamados/${dadosChamado.key}/`)
                        .push({
                          tipo: "mensagem",
                          titulo:
                            "Atendimento criado por: " +
                            arrayNomeQuemAbriuChamado[0]
                              .nomeDeQuemAbriuChamado,
                          criadoPor: keyDeQuemAbriuChamado,
                          mensagem: dataHoraAtual,
                          dataHoraAtual: dataHoraAtual,
                          time: timeAtual,
                          lineColor: "#00FF7F",
                          circleColor: "#00FF7F",
                          dataAtualPostagem: dataAbertura,
                          visivelATodos: 1
                        })
                        .then(() => {
                          if (arquivosParaSerUpadosNaAberturaChamado.length != []) {
                            //
                            let aux = 0;
                            for (let i = 0; i < arquivosParaSerUpadosNaAberturaChamado.length; i++) {
                              //  let uri = arquivosParaSerUpadosNaAberturaChamado[i].uri.replace("file://", ""); //remove o file:// que tem no ios
                              let nomeArquivoParaSerUpado = arquivosParaSerUpadosNaAberturaChamado[i].name == undefined ? arquivosParaSerUpadosNaAberturaChamado[i].fileName : arquivosParaSerUpadosNaAberturaChamado[i].name;
                              let caminhoPadraoArquivo1 = firebase.storage().ref(`${chaveUnica}/${dadosChamado.key}/${nomeArquivoParaSerUpado}`);
                              let mime = arquivosParaSerUpadosNaAberturaChamado[i].type;
                              //
                              /*   RNFetchBlob.fs
                                  .readFile(uri, "base64")
                                  .then(data => {


                                    return RNFetchBlob.polyfill.Blob.build(
                                      data,
                                      {
                                        type: mime + ";BASE64"
                                      }
                                    );
                                  })
                                  .catch(err => {

                                  })
                                  .then(blob => {*/
                              //
                              caminhoPadraoArquivo1.put(arquivosParaSerUpadosNaAberturaChamado[i]).then(() => {
                                //
                                aux++;
                                /*   blob.close(); */
                                firebase.database().ref(`${chaveUnica}/chamados/${dadosChamado.key}/imagensVinculadas/`)
                                  .push({ nomeArquivo: nomeArquivoParaSerUpado });
                                //aqui eu faço a verificação para saber quando o ultimo arquivo foi upado
                                if (aux == arquivosParaSerUpadosNaAberturaChamado.length) {/*
                                        if (atendenteVeApenasProprioChamado == true && agruparAtendenteSetor == false && contaPrincipal == false && setorAtendimento == "Não informado") {

                                          if (atendenteAtendimento == keyDeQuemAbriuChamado) {
                                       //    enviaChamadoAberto(dispatch, chamado.concat(chamadosAnteriores));
                                          }
                                        } else if (atendenteVeApenasProprioChamado == true && agruparAtendenteSetor == true && contaPrincipal == false && setorAtendimento == "Não informado") {

                                          if (atendenteAtendimento == keyDeQuemAbriuChamado) {
                                         //   enviaChamadoAberto(dispatch, chamado.concat(chamadosAnteriores));
                                          }
                                        } else if (atendenteVeApenasProprioChamado == false && agruparAtendenteSetor == false) {

                                        //  enviaChamadoAberto(dispatch, chamado.concat(chamadosAnteriores));
                                        } */

                                  toastr.success('Sucesso', 'Atendimento criado com sucesso!');
                                  zeraCadastroChamado(dispatch);
                                }
                              })
                                .catch(error => {
                                  //error.code
                                  //error.message
                                  switch (error.code) {
                                    case "auth/network-request-failed":
                                      toastr.error('Error', 'Sem conexão com a INTERNET!');
                                      break;
                                    default:
                                      toastr.error('Error', error.message);
                                      break;
                                  }
                                });
                              /*  })
                               .catch(error => {
                                 //error.code
                                 //error.message

                                 switch (error.code) {
                                   case "auth/network-request-failed":
                                     dispatch({
                                       type: SEMCONEXAOINTERNETCADASTRACHAMADO,
                                       payload:
                                         "semConexaoInternetCadastraChamado"
                                     });
                                     break;
                                   default:
                                     dispatch({
                                       type: SEMCONEXAOINTERNETCADASTRACHAMADO,
                                       payload:
                                         "semConexaoInternetCadastraChamado"
                                     });
                                     break;
                                 }
                               }); */
                            }
                          } else {
                            if (atendenteVeApenasProprioChamado == true && agruparAtendenteSetor == false && contaPrincipal == false && setorAtendimento == "Não informado") {
                              //
                              if (atendenteAtendimento == keyDeQuemAbriuChamado) {
                                // enviaChamadoAberto(dispatch, chamado.concat(chamadosAnteriores));
                              }
                            } else if (atendenteVeApenasProprioChamado == true && agruparAtendenteSetor == true && contaPrincipal == false && setorAtendimento == "Não informado") {
                              //
                              if (atendenteAtendimento == keyDeQuemAbriuChamado) {
                                // enviaChamadoAberto(dispatch, chamado.concat(chamadosAnteriores));
                              }
                            } else if (atendenteVeApenasProprioChamado == false && agruparAtendenteSetor == false) {
                              //
                              // enviaChamadoAberto(dispatch, chamado.concat(chamadosAnteriores));
                            }
                            toastr.success('Sucesso', 'Atendimento criado com sucesso!');
                            zeraCadastroChamado(dispatch);
                          }
                        })
                        .catch(error => {
                          //error.code
                          //error.message
                          switch (error.code) {
                            case "auth/network-request-failed":
                              toastr.error('Error', 'Sem conexão com a INTERNET!');
                              /* dispatch({
                                type: SEMCONEXAOINTERNETCADASTRACHAMADO,
                                payload:
                                  "semConexaoInternetCadastraChamado"
                              }); */
                              break;
                            default:
                              toastr.error('Error', error.message);
                              /* dispatch({
                                type: SEMCONEXAOINTERNETCADASTRACHAMADO,
                                payload:
                                  "semConexaoInternetCadastraChamado"
                              }); */
                              break;
                          }
                        });
                    });
                });
            } else {
              //
              let numeroAtual;
              let keyNumeracao;
              snapshot.forEach(childItem => {
                numeroAtual = childItem.val().numeroAtual;
                keyNumeracao = childItem.key;
              });
              let proximaNumeracao = numeroAtual + 1;
              firebase.database().ref(`${chaveUnica}/proximaNumeracao/${keyNumeracao}`).update({ numeroAtual: proximaNumeracao }).then(() => {
                //
                firebase.database().ref(`${chaveUnica}/chamados/`).push({
                  cliente: clienteAtendimento,
                  atendente: atendenteAtendimento,
                  setor: setorAtendimento,
                  prioridade: prioridadeAtendimento,
                  local: localAtendimento,
                  descricaoProblema: (criarChamadoProblemaEstatico == true) ? nomeProblemaSelecionado : descricaoAtendimento,
                  descricaoProblemaEstatico: (criarChamadoProblemaEstatico == true) ? 1 : 0,
                  keyProblemaEstatico: problemaEstaticoSelecionado,
                  //  descricaoProblema: descricaoAtendimento,
                  dataAbertura: dataAbertura,
                  dataHoraAbertura: dataHoraAtual,
                  keyEmpresaVinculada,
                  status: "Aberto",
                  status_atendente: `Aberto${atendenteAtendimento}`, // server também pra filtro atendente comum
                  alterado: 0,
                  abertoPor: keyDeQuemAbriuChamado,
                  numeroChamado: proximaNumeracao,
                  chamadoAbertoPorAdminOuCliente: chamadoAbertoPor,
                  dataVencimento: dataVencimento,
                  tempoGasto: 0,
                  clienteVinculado: clienteAtendimento == "Não informado" ? 0 : 1,
                  setorVinculado: setorAtendimento == "Não informado" ? 0 : 1,
                  dataAberturaTimeStamp: firebase.database.ServerValue.TIMESTAMP,
                  status_cliente: `Aberto${clienteAtendimento}`, //filtro cliente comum
                  status_setor: `Aberto${setorAtendimento}`, //filtro setor comum
                  status_prioridade: `Aberto${prioridadeAtendimento}`, // filtro prioridade comum
                  status_local: `Aberto${localAtendimento}`, // filtro local comum
                  status_dataVencimento: AbertoVencimento, // filtro local comum
                  status_problema: `Aberto_${descricaoAtendimento}`, // filtro local comum
                  status_numeroChamado: `Aberto_${proximaNumeracao}`, // filtro numero chamado vê proprio chamado
                  status_atendente_cliente: `Aberto${atendenteAtendimento}${clienteAtendimento}`, //filtro cliente vê proprio chamado
                  status_atendente_setor: `Aberto${atendenteAtendimento}${setorAtendimento}`, //filtro setor vê proprio chamado
                  status_atendente_prioridade: `Aberto${atendenteAtendimento}${prioridadeAtendimento}`, // filtro prioridade vê proprio chamado
                  status_atendente_local: `Aberto${atendenteAtendimento}${localAtendimento}`, // filtro local vê proprio chamado
                  status_atendente_dataVencimento: testeObjeto, // filtro local vê proprio chamado
                  status_atendente_problema: `Aberto_${atendenteAtendimento}_${descricaoAtendimento}`, // filtro problema vê proprio chamado
                  status_atendente_numeroChamado: `Aberto_${atendenteAtendimento}${proximaNumeracao}`, // filtro numero chamado vê proprio chamado

                  atendente_cliente: `${atendenteAtendimento}${clienteAtendimento}`,
                  atendente_setor: `${atendenteAtendimento}${setorAtendimento}`,
                  atendente_prioridade: `${atendenteAtendimento}${prioridadeAtendimento}`,
                  atendente_local: `${atendenteAtendimento}${localAtendimento}`,
                  atendente_dataVencimento: AbertoVencimentoAtendente,
                  atendente_numeroChamado: `${atendenteAtendimento}${proximaNumeracao}`,
                  dataVencimentoUnix: dataVencimentoUnix,
                  channelChamado: 'adminWEB',

                  lastMessageChat: descricaoAtendimento,
                  lastMessageChatTimeStamp: firebase.database.ServerValue.TIMESTAMP,
                  lastmessageChatCount: 0,
                  lastmessageChatType: 'mensagem',
                  lastMessageLineColor: '#00FF7F'
                })
                  .then(dadosChamado => {
                    //
                    const chamado = [{
                      key: dadosChamado.key,
                      cliente: nomeAuxCliente == "" ? "Não informado" : nomeAuxCliente,
                      atendente: nomeAuxAtendente == "" ? "Não informado" : nomeAuxAtendente,
                      setor: nomeAuxSetor == "" ? "Não informado" : nomeAuxSetor,
                      prioridade: prioridadeAtendimento == "" ? "Não informado" : prioridadeAtendimento,
                      local: localAtendimento,
                      descricaoProblema: descricaoAtendimento,
                      dataAbertura: dataAbertura,
                      dataHoraAbertura: dataHoraAtual,
                      status: "Aberto",
                      alterado: 0,
                      keyCliente: clienteAtendimento,
                      chaveUnica: chaveUnica,
                      empresa: empresaSelecionada,
                      numeroChamado: proximaNumeracao
                    }];
                    if (setorAtendimento !== "Não informado") {
                      //
                      firebase.database().ref(`${chaveUnica}/setores/${setorAtendimento}/atendentesVinculados`).once('value').then((snapshotSetor) => {
                        if (snapshotSetor.val()) {
                          let dadosAtendVinc = snapshotSetor.val();

                          let keyAtendentesVinculados = Object.keys(snapshotSetor.val());

                          for (let i = 0; i < keyAtendentesVinculados.length; i++) {
                            if (keyAtendentesVinculados[i].includes('codigoInterno')) {
                              dadosAtendVinc[keyAtendentesVinculados[i] + "status"] = keyAtendentesVinculados[i] + "Aberto";
                            }
                          }

                          //  console.log(snapshotSetor.val());



                          const dadosAtendentesVinculadosSetor = [];
                          snapshotSetor.forEach((childItem) => {
                            dadosAtendentesVinculadosSetor.push({
                              key: childItem.key,
                            });
                          })
                          //
                          if (atendenteVeApenasProprioChamado == true && agruparAtendenteSetor == false && contaPrincipal == false) {
                            //
                            //
                            if (atendenteAtendimento == keyDeQuemAbriuChamado) {
                              //enviaChamadoAberto(dispatch, chamado.concat(chamadosAnteriores));
                            }
                          } else if (atendenteVeApenasProprioChamado == true && agruparAtendenteSetor == true && contaPrincipal == false) {
                            let verificoSeAtendenteDoChamadoNaoEstaNoSetorVinculado = 0;
                            if (atendenteAtendimento !== 'Não informado') {
                              for (let i = 0; i < dadosAtendentesVinculadosSetor.length; i++) {
                                //
                                if (dadosAtendentesVinculadosSetor[i].key == keyDeQuemAbriuChamado && atendenteAtendimento == keyDeQuemAbriuChamado) {
                                  //  enviaChamadoAberto(dispatch, chamado.concat(chamadosAnteriores));
                                  break;
                                }
                              }
                              //verifico se o atendente do chamado nao esta vinculado a este setor
                              for (let i = 0; i < dadosAtendentesVinculadosSetor.length; i++) {


                                if (dadosAtendentesVinculadosSetor[i].key == atendenteAtendimento) {
                                  verificoSeAtendenteDoChamadoNaoEstaNoSetorVinculado = 1;

                                }
                              }
                              //atendente nao esta no grupo porem foi vinculado ao atendimento, coloco ele nos vinculados do chamado
                              if (verificoSeAtendenteDoChamadoNaoEstaNoSetorVinculado == 0) {

                                let objetocadastro = {};
                                firebase.database().ref(`${chaveUnica}/usuarios/${atendenteAtendimento}`).once("value").then((snapshot) => {
                                  let key = snapshot.key;
                                  let codigoInterno = snapshot.val().codigoInterno;
                                  objetocadastro[codigoInterno] = codigoInterno;
                                  objetocadastro[codigoInterno + 'status'] = codigoInterno + 'Aberto';
                                  //  objetocadastro[key] = true;

                                  // objetocadastro['keyChamado'] = dadosChamado.key;

                                  // firebase.database().ref(`${chaveUnica}/dadosAtendVinc/${key}/${dadosChamado.key}`).push({ true: 'true' });
                                  firebase.database().ref(`${chaveUnica}/chamados/${dadosChamado.key}/usuariosVisualizaAtend/dadosAtendVinc`).update(objetocadastro)
                                })
                                if (atendenteAtendimento == keyDeQuemAbriuChamado) {
                                  //se o atendente que nao está vinculado é o mesmo que está abrindo atendimento eu envio o chamado
                                  //
                                  //  enviaChamadoAberto(dispatch, chamado.concat(chamadosAnteriores));
                                }
                              } else {
                                //creio que ja cai na situaçaão acima

                              }
                            } else {
                              for (let i = 0; i < dadosAtendentesVinculadosSetor.length; i++) {
                                //
                                if (dadosAtendentesVinculadosSetor[i].key == keyDeQuemAbriuChamado) {
                                  // enviaChamadoAberto(dispatch, chamado.concat(chamadosAnteriores));
                                  break;
                                }
                              }
                            }
                            /* if(atendenteAtendimento == keyDeQuemAbriuChamado){
                              enviaChamadoAberto(dispatch,chamado.concat(chamadosAnteriores));
                             }else if(){

                             }else{

                             } */
                          } else {
                            //
                            //  enviaChamadoAberto(dispatch, chamado.concat(chamadosAnteriores));
                            if (atendenteAtendimento !== 'Não informado') {
                              let verificoSeAtendenteDoChamadoNaoEstaNoSetorVinculado = 0;
                              //verifico se o atendente do chamado nao esta vinculado a este setor
                              for (let i = 0; i < dadosAtendentesVinculadosSetor.length; i++) {
                                //
                                if (dadosAtendentesVinculadosSetor[i].key == atendenteAtendimento) {
                                  verificoSeAtendenteDoChamadoNaoEstaNoSetorVinculado = 1;

                                }
                              }
                              if (verificoSeAtendenteDoChamadoNaoEstaNoSetorVinculado == 0) {

                                let objetocadastro = {};
                                firebase.database().ref(`${chaveUnica}/usuarios/${atendenteAtendimento}`).once("value").then((snapshot) => {
                                  let key = snapshot.key;
                                  let codigoInterno = snapshot.val().codigoInterno;
                                  objetocadastro[codigoInterno] = codigoInterno;
                                  objetocadastro[codigoInterno + 'status'] = codigoInterno + 'Aberto';
                                  // objetocadastro[key] = true;
                                  //  objetocadastro['keyChamado'] = dadosChamado.key;

                                  // firebase.database().ref(`${chaveUnica}/dadosAtendVinc/${key}/${dadosChamado.key}`).push({ true: 'true' });
                                  firebase.database().ref(`${chaveUnica}/chamados/${dadosChamado.key}/usuariosVisualizaAtend/dadosAtendVinc`).update(objetocadastro)
                                })

                              } else {
                                //creio que ja cai na situaçaão acima
                                //
                              }
                            }
                          }


                          /*  let arrayOfKeysAtendentes = Object.keys(dadosAtendVinc)

                           for (let i = 0; i < arrayOfKeysAtendentes.length; i++) {

                             firebase.database().ref(`${chaveUnica}/dadosAtendVinc/${arrayOfKeysAtendentes[i]}/${dadosChamado.key}`).push({ true: 'true' });
                           } */
                          firebase.database().ref(`${chaveUnica}/chamados/${dadosChamado.key}/usuariosVisualizaAtend`).update({ dadosAtendVinc })
                        } else {

                          firebase.database().ref(`${chaveUnica}/usuarios/`).orderByChild('excluido').equalTo(null).once('value').then((snapshot) => {
                            //
                            const idTodosAtendentes = [];
                            let objetocadastro = {};
                            snapshot.forEach((childItem) => {
                              idTodosAtendentes.push({
                                key: childItem.key,
                                codigoInterno: childItem.val().codigoInterno == undefined ? 'codigoInterno1' : childItem.val().codigoInterno,
                              });
                            })
                            //
                            for (let i = 0; i < idTodosAtendentes.length; i++) {
                              // objetocadastro[idTodosAtendentes[i].key] = true;
                              objetocadastro[idTodosAtendentes[i].codigoInterno] = idTodosAtendentes[i].codigoInterno;
                              objetocadastro[idTodosAtendentes[i].codigoInterno + "status"] = idTodosAtendentes[i].codigoInterno + "Aberto";
                              //firebase.database().ref(`${chaveUnica}/dadosAtendVinc/${idTodosAtendentes[i].key}/${dadosChamado.key}`).push({ true: 'true' });
                            }
                            // objetocadastro['keyChamado'] = dadosChamado.key;


                            firebase.database().ref(`${chaveUnica}/chamados/${dadosChamado.key}/usuariosVisualizaAtend/dadosAtendVinc`).update(objetocadastro)
                            // enviaChamadoAberto(dispatch, chamado.concat(chamadosAnteriores));
                          })

                        }
                      })
                    } else {
                      //verifica se o atendente é dierente de naão informado, porque se for, preciso criar o campo atendentes vinculados pra esse atendente visualizar este atendimento
                      //

                      /*     if (atendenteAtendimento != "Não informado") {
                            //
                            let objetocadastro = {};

                            objetocadastro[atendenteAtendimento] = true;

                            //firebase.database().ref(`${chaveUnica}/dadosAtendVinc/${atendenteAtendimento}/${dadosChamado.key}`).push({ true: 'true' });
                            firebase.database().ref(`${chaveUnica}/chamados/${dadosChamado.key}/usuariosVisualizaAtend/dadosAtendVinc`).update(objetocadastro)
                          } */
                      firebase.database().ref(`${chaveUnica}/usuarios/`).orderByChild('excluido').equalTo(null).once('value').then((snapshot) => {
                        //
                        const idTodosAtendentes = [];
                        let objetocadastro = {};
                        snapshot.forEach((childItem) => {
                          idTodosAtendentes.push({
                            key: childItem.key,
                            contaPrincipal: childItem.val().contaPrincipal == undefined ? false : childItem.val().contaPrincipal,
                            codigoInterno: childItem.val().codigoInterno == undefined ? 'codigoInterno1' : childItem.val().codigoInterno,

                          });
                        })
                        for (let i = 0; i < idTodosAtendentes.length; i++) {
                          if (idTodosAtendentes[i].contaPrincipal == true) {
                            // objetocadastro[idTodosAtendentes[i].key] = true;
                            objetocadastro[idTodosAtendentes[i].codigoInterno] = idTodosAtendentes[i].codigoInterno;
                            objetocadastro[idTodosAtendentes[i].codigoInterno + 'status'] = idTodosAtendentes[i].codigoInterno + 'Aberto';
                            // firebase.database().ref(`${chaveUnica}/dadosAtendVinc/${idTodosAtendentes[i].key}/${dadosChamado.key}`).push({ true: 'true' });
                          }
                          if (atendenteAtendimento != "Não informado" && atendenteAtendimento === idTodosAtendentes[i].key) {
                            objetocadastro[idTodosAtendentes[i].codigoInterno] = idTodosAtendentes[i].codigoInterno;
                            // objetocadastro[idTodosAtendentes[i].key] = true;
                            objetocadastro[idTodosAtendentes[i].codigoInterno + 'status'] = idTodosAtendentes[i].codigoInterno + 'Aberto';
                          }
                        }

                        //objetocadastro['keyChamado'] = dadosChamado.key;

                        firebase.database().ref(`${chaveUnica}/chamados/${dadosChamado.key}/usuariosVisualizaAtend/dadosAtendVinc`).update(objetocadastro)
                      })
                    }
                    firebase.database().ref(`${chaveUnica}/acoesChamados/${dadosChamado.key}/`).push({
                      tipo: "mensagem",
                      titulo: "Atendimento criado por: " + arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado,
                      criadoPor: keyDeQuemAbriuChamado,
                      mensagem: dataHoraAtual,
                      dataHoraAtual: dataHoraAtual,
                      time: timeAtual,
                      lineColor: "#00FF7F",
                      circleColor: "#00FF7F",
                      visivelATodos: 1
                    })
                      .then(() => {
                        //
                        //tem arquivos, então eu upo
                        if (arquivosParaSerUpadosNaAberturaChamado.length != []) {
                          //
                          let aux = 0;
                          for (let i = 0; i < arquivosParaSerUpadosNaAberturaChamado.length; i++) {
                            //  let uri = arquivosParaSerUpadosNaAberturaChamado[i].uri.replace("file://", ""); //remove o file:// que tem no ios
                            let nomeArquivoParaSerUpado = arquivosParaSerUpadosNaAberturaChamado[i].name == undefined ? arquivosParaSerUpadosNaAberturaChamado[i].fileName : arquivosParaSerUpadosNaAberturaChamado[i].name;
                            let caminhoPadraoArquivo1 = firebase.storage().ref(`${chaveUnica}/${dadosChamado.key}/${nomeArquivoParaSerUpado}`);
                            let mime = arquivosParaSerUpadosNaAberturaChamado[i].type;
                            //
                            /*   RNFetchBlob.fs
                                .readFile(uri, "base64")
                                .then(data => {


                                  return RNFetchBlob.polyfill.Blob.build(
                                    data,
                                    {
                                      type: mime + ";BASE64"
                                    }
                                  );
                                })
                                .catch(err => {

                                })
                                .then(blob => {*/
                            //
                            caminhoPadraoArquivo1.put(arquivosParaSerUpadosNaAberturaChamado[i]).then(() => {
                              //
                              aux++;
                              /*   blob.close(); */
                              firebase.database().ref(`${chaveUnica}/chamados/${dadosChamado.key}/imagensVinculadas/`)
                                .push({ nomeArquivo: nomeArquivoParaSerUpado });
                              //aqui eu faço a verificação para saber quando o ultimo arquivo foi upado
                              if (aux == arquivosParaSerUpadosNaAberturaChamado.length) {/*
                                     if (atendenteVeApenasProprioChamado == true && agruparAtendenteSetor == false && contaPrincipal == false && setorAtendimento == "Não informado") {

                                       if (atendenteAtendimento == keyDeQuemAbriuChamado) {
                                    //    enviaChamadoAberto(dispatch, chamado.concat(chamadosAnteriores));
                                       }
                                     } else if (atendenteVeApenasProprioChamado == true && agruparAtendenteSetor == true && contaPrincipal == false && setorAtendimento == "Não informado") {

                                       if (atendenteAtendimento == keyDeQuemAbriuChamado) {
                                      //   enviaChamadoAberto(dispatch, chamado.concat(chamadosAnteriores));
                                       }
                                     } else if (atendenteVeApenasProprioChamado == false && agruparAtendenteSetor == false) {

                                     //  enviaChamadoAberto(dispatch, chamado.concat(chamadosAnteriores));
                                     } */

                                toastr.success('Sucesso', 'Atendimento criado com sucesso!');
                                zeraCadastroChamado(dispatch);
                              }
                            })
                              .catch(error => {
                                //error.code
                                //error.message
                                switch (error.code) {
                                  case "auth/network-request-failed":
                                    toastr.error('Error', 'Sem conexão com a INTERNET!');
                                    break;
                                  default:
                                    toastr.error('Error', error.message);
                                    break;
                                }
                              });
                            /*  })
                             .catch(error => {
                               //error.code
                               //error.message

                               switch (error.code) {
                                 case "auth/network-request-failed":
                                   dispatch({
                                     type: SEMCONEXAOINTERNETCADASTRACHAMADO,
                                     payload:
                                       "semConexaoInternetCadastraChamado"
                                   });
                                   break;
                                 default:
                                   dispatch({
                                     type: SEMCONEXAOINTERNETCADASTRACHAMADO,
                                     payload:
                                       "semConexaoInternetCadastraChamado"
                                   });
                                   break;
                               }
                             }); */
                          }
                        } else {
                          if (atendenteVeApenasProprioChamado == true && agruparAtendenteSetor == false && contaPrincipal == false && setorAtendimento == "Não informado") {
                            //
                            if (atendenteAtendimento == keyDeQuemAbriuChamado) {
                              //   enviaChamadoAberto(dispatch, chamado.concat(chamadosAnteriores));
                            }
                          } else if (atendenteVeApenasProprioChamado == true && agruparAtendenteSetor == true && contaPrincipal == false && setorAtendimento == "Não informado") {
                            //
                            if (atendenteAtendimento == keyDeQuemAbriuChamado) {
                              //  enviaChamadoAberto(dispatch, chamado.concat(chamadosAnteriores));
                            }
                          } else if (atendenteVeApenasProprioChamado == false && agruparAtendenteSetor == false) {
                            //
                            //   enviaChamadoAberto(dispatch, chamado.concat(chamadosAnteriores));
                          }
                          toastr.success('Sucesso', 'Atendimento criado com sucesso!');
                          zeraCadastroChamado(dispatch);
                        }
                      });
                  })
                  .catch(error => {
                    //error.code
                    //error.message
                    switch (error.code) {
                      case "auth/network-request-failed":
                        toastr.error('Error', 'Sem conexão com a INTERNET!');
                        break;
                      default:
                        toastr.error('Error', error.message);
                        break;
                    }
                  });
              });
            }
          });
        });
      });
    }

  };
};
export const listaChamadosCanceladoComFiltro = (
  valor,
  referenciaUltimaKey,
  destino,
  chamadosAnteriores,
  atendenteVeApenasProprioChamado,
  contaPrincipal,
  filtroChamado2,
  status,
  filtro
) => {
  return dispatch => {
    let referenceToOldestKey;
    //

    //
    AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");
      const filtroChamado = filtroChamado2.toLowerCase();
      //
      //
      //
      let orderByChild, filtroPesquisa;
      //
      const { currentUser } = firebase.auth();
      firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild('uid').equalTo(currentUser.uid).once("value").then((snapshot) => {
        let keyAtendente = Object.keys(snapshot.val()).toString();
        if (atendenteVeApenasProprioChamado == true && contaPrincipal == false) {
          //aqui o atendente vê somente os proprios chamados
          orderByChild = `status_atendente_${filtroChamado}`;
          filtroPesquisa = `Cancelado${keyAtendente}${filtro}`;
        } else {
          orderByChild = `status_${filtroChamado}`;
          filtroPesquisa = `Cancelado${filtro}`;
        }

        //
        //
        if (valor == 0) {
          // if initial fetch
          ativaDesativaLoadingTelaChamadoCancelado(dispatch, true);
          ativaDesativaLoadingRefreshControlChamadoCancelado(dispatch, true);
          firebase
            .database()
            .ref(`${chaveUnica}/chamados/`)
            .orderByChild(orderByChild)
            .equalTo(filtroPesquisa)
            .limitToLast(7)
            .once("value").then(async (snapshot) => {

              if (snapshot.val() == null) {
                enviaChamadoCancelado(dispatch, []);
                ativaDesativaLoadingTelaChamadoCancelado(dispatch, false);
                ativaDesativaLoadingRefreshControlChamadoCancelado(
                  dispatch,
                  false
                );
              } else {
                // changing to reverse chronological order (latest first)
                let arrayOfKeys = Object.keys(snapshot.val())
                  .sort()
                  .reverse();
                // transforming to array
                let results = arrayOfKeys.map(key => snapshot.val()[key]);
                // storing reference
                referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];

                let chamados = [];
                const { responseClientes, responseAtendentes, responseSetores } = await getAllFirebaseCachedData(chaveUnica, results);

                for (let aux = 0; aux < results.length; aux++) {
                  const cliente = responseClientes.find((cliente) => cliente.key === results[aux].cliente);
                  const atendente = responseAtendentes.find((atendente) => atendente.key === results[aux].atendente);
                  const setor = responseSetores.find((setor) => setor.key === results[aux].setor);

                  chamados.push({
                    key: arrayOfKeys[aux],
                    cliente: !cliente || !cliente.data ? "Não informado" : cliente.data.nome,
                    empresa: !cliente || !cliente.data ? "Não informado" : cliente.data.empresa,
                    setor: !setor || !setor.data ? "Não informado" : setor.data,
                    urlImagemNuvem: !cliente || !cliente.data ? null : cliente.data.urlImagemNuvem,
                    atendente: !atendente || !atendente.data ? "Não informado" : atendente.data,




                    dataHoraAbertura: results[aux].dataHoraAbertura,
                    dataVencimento: results[aux].dataVencimento,
                    status: results[aux].status,
                    prioridade:
                      results[aux].prioridade === ""
                        ? "Não informado"
                        : results[aux].prioridade,
                    descricaoProblema: results[aux].descricaoProblema,
                    alterado: results[aux].alterado,
                    chaveUnica: chaveUnica,
                    keyCliente: results[aux].cliente,
                    numeroChamado: results[aux].numeroChamado,
                    dataHoraCancelamento: results[aux].dataHoraCancelamento,
                    channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                    telefoneCliente: (results[aux].telefoneCliente === undefined) ? 'Não informado' : results[aux].telefoneCliente,
                    chamadoAbertoPorWpp: (results[aux].chamadoAbertoPorWpp === undefined) ? false : results[aux].chamadoAbertoPorWpp,
                    abertoPorChat: (results[aux].abertoPorChat === undefined) ? "false" : results[aux].abertoPorChat,

                    lastMessageChat: results[aux].lastMessageChat == undefined ? '' : results[aux].lastMessageChat,
                    lastMessageChatTimeStamp: results[aux].lastMessageChatTimeStamp == undefined ? 0 : results[aux].lastMessageChatTimeStamp,
                    lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                    lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                    lastMessageLineColor: results[aux].lastMessageLineColor == undefined ? '#39a3f4' : results[aux].lastMessageLineColor,
                    lastmessageChatTypeProblem: (results[aux].lastmessageChatTypeProblem === undefined) ? "mensagem" : results[aux].lastmessageChatTypeProblem,
                  });
                }
                enviaChamadoCancelado(dispatch, chamados);
                ativaDesativaLoadingTelaChamadoCancelado(dispatch, false);
                ativaDesativaLoadingRefreshControlChamadoCancelado(
                  dispatch,
                  false
                );
                enviaUltimaReferenciaKeyCancelado(
                  dispatch,
                  referenceToOldestKey
                );
                if (chamados.length == 7) {
                  ativaDesativaLoadingFinalFlatListChamadoCancelado(dispatch, true);
                } else {
                  ativaDesativaLoadingFinalFlatListChamadoCancelado(dispatch, false);
                }
              }
            })


        } else {
          if (referenciaUltimaKey != undefined && referenciaUltimaKey != "") {
            /*    ativaDesativaLoadingFinalFlatListChamadoCancelado(
                 dispatch,
                 true
               ); */
            firebase
              .database()
              .ref(`${chaveUnica}/chamados/`)
              .orderByChild(orderByChild)
              .startAt(filtroPesquisa)
              .endAt(filtroPesquisa, referenciaUltimaKey)
              .limitToLast(8)
              .once("value").then(async (snapshot) => {
                if (snapshot.val() == null) {
                  ativaDesativaLoadingFinalFlatListChamadoCancelado(
                    dispatch,
                    false
                  );
                } else {

                  // changing to reverse chronological order (latest first)
                  // & removing duplicate
                  let arrayOfKeys = Object.keys(snapshot.val())
                    .sort()
                    .reverse()
                    .slice(1);
                  // transforming to array
                  let results = arrayOfKeys.map(key => snapshot.val()[key]);
                  // updating reference
                  referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
                  let chamados = [];
                  const { responseClientes, responseAtendentes, responseSetores } = await getAllFirebaseCachedData(chaveUnica, results);

                  for (let aux = 0; aux < results.length; aux++) {
                    const cliente = responseClientes.find((cliente) => cliente.key === results[aux].cliente);
                    const atendente = responseAtendentes.find((atendente) => atendente.key === results[aux].atendente);
                    const setor = responseSetores.find((setor) => setor.key === results[aux].setor);

                    chamados.push({
                      key: arrayOfKeys[aux],
                      cliente: !cliente || !cliente.data ? "Não informado" : cliente.data.nome,
                      empresa: !cliente || !cliente.data ? "Não informado" : cliente.data.empresa,
                      setor: !setor || !setor.data ? "Não informado" : setor.data,
                      urlImagemNuvem: !cliente || !cliente.data ? null : cliente.data.urlImagemNuvem,
                      atendente: !atendente || !atendente.data ? "Não informado" : atendente.data,




                      dataHoraAbertura: results[aux].dataHoraAbertura,
                      dataVencimento: results[aux].dataVencimento,
                      status: results[aux].status,
                      prioridade:
                        results[aux].prioridade === ""
                          ? "Não informado"
                          : results[aux].prioridade,
                      descricaoProblema: results[aux].descricaoProblema,
                      alterado: results[aux].alterado,
                      chaveUnica: chaveUnica,
                      keyCliente: results[aux].cliente,
                      numeroChamado: results[aux].numeroChamado,
                      dataHoraCancelamento:
                        results[aux].dataHoraCancelamento,
                      channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                      telefoneCliente: (results[aux].telefoneCliente === undefined) ? 'Não informado' : results[aux].telefoneCliente,
                      chamadoAbertoPorWpp: (results[aux].chamadoAbertoPorWpp === undefined) ? false : results[aux].chamadoAbertoPorWpp,
                      abertoPorChat: (results[aux].abertoPorChat === undefined) ? "false" : results[aux].abertoPorChat,
                      lastMessageChat: results[aux].lastMessageChat == undefined ? '' : results[aux].lastMessageChat,
                      lastMessageChatTimeStamp: results[aux].lastMessageChatTimeStamp == undefined ? 0 : results[aux].lastMessageChatTimeStamp,
                      lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                      lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                      lastMessageLineColor: results[aux].lastMessageLineColor == undefined ? '#39a3f4' : results[aux].lastMessageLineColor,
                      lastmessageChatTypeProblem: (results[aux].lastmessageChatTypeProblem === undefined) ? "mensagem" : results[aux].lastmessageChatTypeProblem,
                    });
                  }
                  /*  ativaDesativaLoadingFinalFlatListChamadoCancelado(
                     dispatch,
                     false
                   ); */
                  enviaChamadoCancelado(
                    dispatch,
                    chamadosAnteriores.concat(chamados)
                  );
                  enviaUltimaReferenciaKeyCancelado(
                    dispatch,
                    referenceToOldestKey
                  );
                }
              })

            /* .catch(error => {

            }); */
          } else {
            ativaDesativaLoadingFinalFlatListChamadoCancelado(
              dispatch,
              false
            );
          }
        }
      })
    });

  };
};
export const listaChamadosCanceladoComFiltroDigitadoEVencimento = (
  valor,
  referenciaUltimaKey,
  destino,
  chamadosAnteriores,
  atendenteVeApenasProprioChamado,
  contaPrincipal,
  filtroChamado2,
  status,
  filtro2
) => {
  return dispatch => {
    let referenceToOldestKey;
    //

    //
    AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");
      let filtroChamado, dataInicialSearch, dataFinalSearch;
      if (filtroChamado2 == 'Número Chamado') {
        filtroChamado = 'numeroChamado'
      } else {
        filtroChamado = filtroChamado2.toLowerCase();
      }
      //
      //
      let orderByChild, filtroPesquisa;
      //
      let dataAtual = retornaDataAtual();
      let datadeOntem = moment().subtract(1, 'days').toDate(); // pego data atual
      datadeOntem = moment(datadeOntem).format('DD/MM/YYYY');
      if (filtro2 == 'Ontem') {
        //
        //
        dataInicialSearch = moment(datadeOntem, 'DD/MM/YYYY').unix();
        dataFinalSearch = moment(datadeOntem, 'DD/MM/YYYY').unix();
        //
        //

      } else if (filtro2 == 'Hoje') {
        dataInicialSearch = dataAtual;
        dataFinalSearch = dataAtual;
        //
        //
        dataInicialSearch = moment(dataInicialSearch, 'DD/MM/YYYY').unix();
        dataFinalSearch = moment(dataFinalSearch, 'DD/MM/YYYY').unix();
        //
        //
      } else if (filtro2 == 'Amanhã') {
        dataInicialSearch = moment(dataAtual, 'DD/MM/YYYY').add(1, 'days').format('DD/MM/YYYY');
        dataFinalSearch = moment(dataAtual, 'DD/MM/YYYY').add(1, 'days').format('DD/MM/YYYY');
        //
        //
        dataInicialSearch = moment(dataInicialSearch, 'DD/MM/YYYY').unix();
        dataFinalSearch = moment(dataFinalSearch, 'DD/MM/YYYY').unix();
        //
        //

      } else if (filtro2 == 'Semana Atual') {
        const comecoDaSemana = moment().startOf('week').toDate();
        const fimDaSemana = moment().endOf('week').toDate();
        dataInicialSearch = moment(comecoDaSemana).format('DD/MM/YYYY');
        dataFinalSearch = moment(fimDaSemana).format('DD/MM/YYYY');
        //
        //
        dataInicialSearch = moment(dataInicialSearch, 'DD/MM/YYYY').unix();
        dataFinalSearch = moment(dataFinalSearch, 'DD/MM/YYYY').unix();
        //
        //
      } else if (filtro2 == 'Semana Passada') {
        let comecoSemanaPassada = moment().weekday(-7).toDate();
        let fimDaSemanaPassada = moment().weekday(-7).add(6, 'day').toDate();
        dataInicialSearch = moment(comecoSemanaPassada).format('DD/MM/YYYY');
        dataFinalSearch = moment(fimDaSemanaPassada).format('DD/MM/YYYY');
        //
        //
        dataInicialSearch = moment(dataInicialSearch, 'DD/MM/YYYY').unix();
        dataFinalSearch = moment(dataFinalSearch, 'DD/MM/YYYY').unix();
        //
        //
      }
      else if (filtro2 == 'Mês Atual') {
        //
        const comecoDoMesAtual = moment().startOf('month').format('DD/MM/YYYY');
        const fimDoMesAtual = moment().endOf('month').format('DD/MM/YYYY');
        dataInicialSearch = comecoDoMesAtual;
        dataFinalSearch = fimDoMesAtual;
        //
        //
        dataInicialSearch = moment(dataInicialSearch, 'DD/MM/YYYY').unix();
        dataFinalSearch = moment(dataFinalSearch, 'DD/MM/YYYY').unix();
        //
        //
      }
      else if (filtro2 == 'Mês Passado') {
        //
        const comecoDoMesPassado = moment().subtract(1, 'months').startOf('month').format('DD/MM/YYYY');
        const fimDoMesPassado = moment().subtract(1, 'months').endOf('month').format('DD/MM/YYYY');
        dataInicialSearch = comecoDoMesPassado;
        dataFinalSearch = fimDoMesPassado;
        //
        //
        dataInicialSearch = moment(dataInicialSearch, 'DD/MM/YYYY').unix();
        dataFinalSearch = moment(dataFinalSearch, 'DD/MM/YYYY').unix();
        //
        //
      }
      else {

        //
      }

      const { currentUser } = firebase.auth();
      firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild('uid').equalTo(currentUser.uid).once("value").then((snapshot) => {
        let keyAtendente = Object.keys(snapshot.val()).toString();
        if (atendenteVeApenasProprioChamado == true && contaPrincipal == false) {
          //aqui o atendente vê somente os proprios chamados
          if (filtroChamado !== 'vencimento') {
            orderByChild = `status_atendente_${filtroChamado}`;
            dataInicialSearch = `Cancelado_${keyAtendente}${filtro2}`;
            dataFinalSearch = `Cancelado_${keyAtendente}${filtro2}`;
          } else {
            orderByChild = `status_atendente_dataVencimento/Cancelado/${keyAtendente}`;
          }
        } else {
          if (filtroChamado !== 'vencimento') {
            orderByChild = `status_${filtroChamado}`;
            filtroPesquisa = `Cancelado_${filtro2}`;
            dataInicialSearch = `Cancelado_${filtro2}`;
            dataFinalSearch = `Cancelado_${filtro2}`;
          } else {
            orderByChild = 'status_dataVencimento/Cancelado/';
          }
        }

        //
        //

        // if initial fetch
        ativaDesativaLoadingTelaChamadoCancelado(dispatch, true);
        ativaDesativaLoadingRefreshControlChamadoCancelado(dispatch, true);
        firebase
          .database()
          .ref(`${chaveUnica}/chamados/`)
          .orderByChild(orderByChild).startAt(dataInicialSearch)
          .endAt(dataFinalSearch)
          .once("value").then(async (snapshot) => {

            if (snapshot.val() == null) {
              enviaChamadoCancelado(dispatch, []);
              ativaDesativaLoadingTelaChamadoCancelado(dispatch, false);
              ativaDesativaLoadingRefreshControlChamadoCancelado(
                dispatch,
                false
              );
            } else {
              // changing to reverse chronological order (latest first)
              let arrayOfKeys = Object.keys(snapshot.val())
                .sort()
                .reverse();
              // transforming to array
              let results = arrayOfKeys.map(key => snapshot.val()[key]);
              // storing reference
              referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];

              let chamados = [];
              const { responseClientes, responseAtendentes, responseSetores } = await getAllFirebaseCachedData(chaveUnica, results);

              for (let aux = 0; aux < results.length; aux++) {
                const cliente = responseClientes.find((cliente) => cliente.key === results[aux].cliente);
                const atendente = responseAtendentes.find((atendente) => atendente.key === results[aux].atendente);
                const setor = responseSetores.find((setor) => setor.key === results[aux].setor);

                chamados.push({
                  key: arrayOfKeys[aux],
                  cliente: !cliente || !cliente.data ? "Não informado" : cliente.data.nome,
                  empresa: !cliente || !cliente.data ? "Não informado" : cliente.data.empresa,
                  setor: !setor || !setor.data ? "Não informado" : setor.data,
                  urlImagemNuvem: !cliente || !cliente.data ? null : cliente.data.urlImagemNuvem,
                  atendente: !atendente || !atendente.data ? "Não informado" : atendente.data,




                  dataHoraAbertura: results[aux].dataHoraAbertura,
                  dataVencimento: results[aux].dataVencimento,
                  status: results[aux].status,
                  prioridade:
                    results[aux].prioridade === ""
                      ? "Não informado"
                      : results[aux].prioridade,
                  descricaoProblema: results[aux].descricaoProblema,
                  alterado: results[aux].alterado,
                  chaveUnica: chaveUnica,
                  keyCliente: results[aux].cliente,
                  numeroChamado: results[aux].numeroChamado,
                  dataHoraCancelamento: results[aux].dataHoraCancelamento,
                  channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                  telefoneCliente: (results[aux].telefoneCliente === undefined) ? 'Não informado' : results[aux].telefoneCliente,
                  chamadoAbertoPorWpp: (results[aux].chamadoAbertoPorWpp === undefined) ? false : results[aux].chamadoAbertoPorWpp,
                  abertoPorChat: (results[aux].abertoPorChat === undefined) ? "false" : results[aux].abertoPorChat,
                  lastMessageChat: results[aux].lastMessageChat == undefined ? '' : results[aux].lastMessageChat,
                  lastMessageChatTimeStamp: results[aux].lastMessageChatTimeStamp == undefined ? 0 : results[aux].lastMessageChatTimeStamp,
                  lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                  lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                  lastMessageLineColor: results[aux].lastMessageLineColor == undefined ? '#39a3f4' : results[aux].lastMessageLineColor,
                  lastmessageChatTypeProblem: (results[aux].lastmessageChatTypeProblem === undefined) ? "mensagem" : results[aux].lastmessageChatTypeProblem,
                });
              }
              enviaChamadoCancelado(dispatch, chamados);
              ativaDesativaLoadingTelaChamadoCancelado(dispatch, false);
              ativaDesativaLoadingRefreshControlChamadoCancelado(
                dispatch,
                false
              );
              enviaUltimaReferenciaKeyCancelado(
                dispatch,
                referenceToOldestKey
              );
              ativaDesativaLoadingFinalFlatListChamadoCancelado(dispatch, false);
            }
          })

        /* .catch(error => {

        }); */

      })
    });

  };
};

//chamados todos novo filtro
export const listaChamadosTodosComFiltro = (
  valor,
  referenciaUltimaKey,
  destino,
  chamadosAnteriores,
  ultimakeyTodos,
  atendenteVeApenasProprioChamado,
  contaPrincipal,
  filtroChamado2,
  status,
  filtro
) => {
  return dispatch => {
    let referenceToOldestKey;
    //

    //;
    AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");
      const filtroChamado = filtroChamado2.toLowerCase();
      /*    console.log(
           valor,
           referenciaUltimaKey,
           destino,
           chamadosAnteriores,
           ultimakeyTodos,
           atendenteVeApenasProprioChamado,
           contaPrincipal,
           filtroChamado2,
           status,
           filtro) */

      let orderByChild, filtroPesquisa;
      //;
      const { currentUser } = firebase.auth();
      firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild('uid').equalTo(currentUser.uid).once("value").then((snapshot) => {
        let keyAtendente = Object.keys(snapshot.val()).toString();
        if (atendenteVeApenasProprioChamado == true && contaPrincipal == false) {
          //aqui o atendente vê somente os proprios chamados
          orderByChild = `atendente_${filtroChamado}`;
          filtroPesquisa = `${keyAtendente}${filtro}`;
        } else {
          orderByChild = `${filtroChamado}`;
          filtroPesquisa = `${filtro}`;
        }

        //
        //
        if (valor == 0) {
          ativaDesativaLoadingTelaChamadoTodos(dispatch, true);
          // if initial fetch
          firebase
            .database()
            .ref(`${chaveUnica}/chamados/`)
            .orderByChild(orderByChild).equalTo(filtroPesquisa)
            .limitToLast(7)
            .once("value").then(async (snapshot) => {

              if (snapshot.val() == null) {
                enviaChamadoTodos(dispatch, []);
                ativaDesativaLoadingTelaChamadoTodos(dispatch, false);
              } else {
                // changing to reverse chronological order (latest first)
                let arrayOfKeys = Object.keys(snapshot.val())
                  .sort()
                  .reverse();
                // transforming to array
                let results = arrayOfKeys.map(key => snapshot.val()[key]);
                // storing reference
                referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];

                let chamados = [];
                const { responseClientes, responseAtendentes, responseSetores } = await getAllFirebaseCachedData(chaveUnica, results);

                for (let aux = 0; aux < results.length; aux++) {
                  const cliente = responseClientes.find((cliente) => cliente.key === results[aux].cliente);
                  const atendente = responseAtendentes.find((atendente) => atendente.key === results[aux].atendente);
                  const setor = responseSetores.find((setor) => setor.key === results[aux].setor);

                  chamados.push({
                    key: arrayOfKeys[aux],
                    cliente: !cliente || !cliente.data ? "Não informado" : cliente.data.nome,
                    empresa: !cliente || !cliente.data ? "Não informado" : cliente.data.empresa,
                    setor: !setor || !setor.data ? "Não informado" : setor.data,
                    urlImagemNuvem: !cliente || !cliente.data ? null : cliente.data.urlImagemNuvem,
                    atendente: !atendente || !atendente.data ? "Não informado" : atendente.data,




                    dataHoraAbertura: results[aux].dataHoraAbertura,
                    dataVencimento: results[aux].dataVencimento,
                    status: results[aux].status,
                    prioridade:
                      results[aux].prioridade === ""
                        ? "Não informado"
                        : results[aux].prioridade,
                    descricaoProblema: results[aux].descricaoProblema,
                    alterado: results[aux].alterado,
                    chaveUnica: chaveUnica,
                    keyCliente: results[aux].cliente,
                    numeroChamado: results[aux].numeroChamado,
                    dataHoraFechamento: results[aux].dataHoraFechamento,
                    dataHoraCancelamento: results[aux].dataHoraCancelamento,
                    channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                    telefoneCliente: (results[aux].telefoneCliente === undefined) ? 'Não informado' : results[aux].telefoneCliente,
                    chamadoAbertoPorWpp: (results[aux].chamadoAbertoPorWpp === undefined) ? false : results[aux].chamadoAbertoPorWpp,
                    abertoPorChat: (results[aux].abertoPorChat === undefined) ? "false" : results[aux].abertoPorChat,
                    lastMessageChat: results[aux].lastMessageChat == undefined ? '' : results[aux].lastMessageChat,
                    lastMessageChatTimeStamp: results[aux].lastMessageChatTimeStamp == undefined ? 0 : results[aux].lastMessageChatTimeStamp,
                    lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                    lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                    lastMessageLineColor: results[aux].lastMessageLineColor == undefined ? '#39a3f4' : results[aux].lastMessageLineColor,
                    lastmessageChatTypeProblem: (results[aux].lastmessageChatTypeProblem === undefined) ? "mensagem" : results[aux].lastmessageChatTypeProblem,
                  });
                }
                ativaDesativaLoadingTelaChamadoTodos(dispatch, false);
                enviaUltimaReferenciaKeyTodos(
                  dispatch,
                  referenceToOldestKey
                );
                enviaChamadoTodos(dispatch, chamados);
                if (chamados.length == 7) {
                  ativaDesativaLoadingFinalFlatListChamadoTodos(dispatch, true);
                } else {
                  ativaDesativaLoadingFinalFlatListChamadoTodos(dispatch, false);
                }
              }
            })

          /*   .catch(error => {

            }); */
        } else {
          if (ultimakeyTodos != undefined && ultimakeyTodos != "") {
            /*    ativaDesativaLoadingFinalFlatListChamadoTodos(
                 dispatch,
                 true
               ); */
            firebase
              .database()
              .ref(`${chaveUnica}/chamados/`)
              .orderByChild(orderByChild)
              .startAt(filtroPesquisa)
              .endAt(filtroPesquisa, ultimakeyTodos)
              .limitToLast(8)
              .once("value").then(async (snapshot) => {
                if (snapshot.val() == null) {
                  //
                  ativaDesativaLoadingFinalFlatListChamadoTodos(
                    dispatch,
                    false
                  );
                } else {

                  // changing to reverse chronological order (latest first)
                  let arrayOfKeys = Object.keys(snapshot.val())
                    .sort()
                    .reverse()
                    .slice(1);
                  // transforming to array
                  let results = arrayOfKeys.map(key => snapshot.val()[key]);
                  // storing reference
                  referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];

                  let chamados = [];
                  const { responseClientes, responseAtendentes, responseSetores } = await getAllFirebaseCachedData(chaveUnica, results);

                  for (let aux = 0; aux < results.length; aux++) {
                    const cliente = responseClientes.find((cliente) => cliente.key === results[aux].cliente);
                    const atendente = responseAtendentes.find((atendente) => atendente.key === results[aux].atendente);
                    const setor = responseSetores.find((setor) => setor.key === results[aux].setor);

                    chamados.push({
                      key: arrayOfKeys[aux],
                      cliente: !cliente || !cliente.data ? "Não informado" : cliente.data.nome,
                      empresa: !cliente || !cliente.data ? "Não informado" : cliente.data.empresa,
                      setor: !setor || !setor.data ? "Não informado" : setor.data,
                      urlImagemNuvem: !cliente || !cliente.data ? null : cliente.data.urlImagemNuvem,
                      atendente: !atendente || !atendente.data ? "Não informado" : atendente.data,




                      dataHoraAbertura: results[aux].dataHoraAbertura,
                      dataVencimento: results[aux].dataVencimento,
                      status: results[aux].status,
                      prioridade:
                        results[aux].prioridade === ""
                          ? "Não informado"
                          : results[aux].prioridade,
                      descricaoProblema: results[aux].descricaoProblema,
                      alterado: results[aux].alterado,
                      chaveUnica: chaveUnica,
                      keyCliente: results[aux].cliente,
                      numeroChamado: results[aux].numeroChamado,
                      dataHoraFechamento: results[aux].dataHoraFechamento,
                      dataHoraCancelamento:
                        results[aux].dataHoraCancelamento,
                      channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                      telefoneCliente: (results[aux].telefoneCliente === undefined) ? 'Não informado' : results[aux].telefoneCliente,
                      chamadoAbertoPorWpp: (results[aux].chamadoAbertoPorWpp === undefined) ? false : results[aux].chamadoAbertoPorWpp,
                      abertoPorChat: (results[aux].abertoPorChat === undefined) ? "false" : results[aux].abertoPorChat,
                      lastMessageChat: results[aux].lastMessageChat == undefined ? '' : results[aux].lastMessageChat,
                      lastMessageChatTimeStamp: results[aux].lastMessageChatTimeStamp == undefined ? 0 : results[aux].lastMessageChatTimeStamp,
                      lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                      lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                      lastMessageLineColor: results[aux].lastMessageLineColor == undefined ? '#39a3f4' : results[aux].lastMessageLineColor,
                      lastmessageChatTypeProblem: (results[aux].lastmessageChatTypeProblem === undefined) ? "mensagem" : results[aux].lastmessageChatTypeProblem,
                    });
                  }
                  /*   ativaDesativaLoadingFinalFlatListChamadoTodos(
                      dispatch,
                      false
                    ); */
                  enviaUltimaReferenciaKeyTodos(
                    dispatch,
                    referenceToOldestKey
                  );
                  //
                  //
                  enviaChamadoTodos(
                    dispatch,
                    chamadosAnteriores.concat(chamados)
                  );
                }
              })

            /* .catch(error => {

            }); */
          } else {
            ativaDesativaLoadingFinalFlatListChamadoTodos(
              dispatch,
              false
            );
          }
        }
      })
    });

  };
};
export const listaChamadosTodosComFiltroDigitadoEVencimento = (
  valor,
  referenciaUltimaKey,
  destino,
  chamadosAnteriores,
  ultimakeyTodos,
  atendenteVeApenasProprioChamado,
  contaPrincipal,
  filtroChamado2,
  status,
  filtro2
) => {
  return dispatch => {
    let referenceToOldestKey;
    //

    //
    AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");
      let filtroChamado, dataInicialSearch, dataFinalSearch;
      if (filtroChamado2 == 'Número Chamado') {
        filtroChamado = 'numeroChamado'
      } else {
        filtroChamado = filtroChamado2.toLowerCase();
      }
      /*  console.log(valor,
         referenciaUltimaKey,
         destino,
         chamadosAnteriores,
         ultimakeyTodos,
         atendenteVeApenasProprioChamado,
         contaPrincipal,
         filtroChamado2,
         status,
         filtro2); */
      //
      let orderByChild, filtroPesquisa;
      //;
      let dataAtual = retornaDataAtual();
      let datadeOntem = moment().subtract(1, 'days').toDate(); // pego data atual
      datadeOntem = moment(datadeOntem).format('DD/MM/YYYY');
      if (filtro2 == 'Ontem') {
        //
        //
        dataInicialSearch = moment(datadeOntem, 'DD/MM/YYYY').unix();
        dataFinalSearch = moment(datadeOntem, 'DD/MM/YYYY').unix();
        //
        //

      } else if (filtro2 == 'Hoje') {
        dataInicialSearch = dataAtual;
        dataFinalSearch = dataAtual;
        //
        //
        dataInicialSearch = moment(dataInicialSearch, 'DD/MM/YYYY').unix();
        dataFinalSearch = moment(dataFinalSearch, 'DD/MM/YYYY').unix();
        //;
        //
      } else if (filtro2 == 'Amanhã') {
        dataInicialSearch = moment(dataAtual, 'DD/MM/YYYY').add(1, 'days').format('DD/MM/YYYY');
        dataFinalSearch = moment(dataAtual, 'DD/MM/YYYY').add(1, 'days').format('DD/MM/YYYY');
        //
        //
        dataInicialSearch = moment(dataInicialSearch, 'DD/MM/YYYY').unix();
        dataFinalSearch = moment(dataFinalSearch, 'DD/MM/YYYY').unix();
        //
        //

      } else if (filtro2 == 'Semana Atual') {
        const comecoDaSemana = moment().startOf('week').toDate();
        const fimDaSemana = moment().endOf('week').toDate();
        dataInicialSearch = moment(comecoDaSemana).format('DD/MM/YYYY');
        dataFinalSearch = moment(fimDaSemana).format('DD/MM/YYYY');
        //
        //
        dataInicialSearch = moment(dataInicialSearch, 'DD/MM/YYYY').unix();
        dataFinalSearch = moment(dataFinalSearch, 'DD/MM/YYYY').unix();
        //
        //
      } else if (filtro2 == 'Semana Passada') {
        let comecoSemanaPassada = moment().weekday(-7).toDate();
        let fimDaSemanaPassada = moment().weekday(-7).add(6, 'day').toDate();
        dataInicialSearch = moment(comecoSemanaPassada).format('DD/MM/YYYY');
        dataFinalSearch = moment(fimDaSemanaPassada).format('DD/MM/YYYY');
        //
        //
        dataInicialSearch = moment(dataInicialSearch, 'DD/MM/YYYY').unix();
        dataFinalSearch = moment(dataFinalSearch, 'DD/MM/YYYY').unix();
        //
        //
      }
      else if (filtro2 == 'Mês Atual') {
        //
        const comecoDoMesAtual = moment().startOf('month').format('DD/MM/YYYY');
        const fimDoMesAtual = moment().endOf('month').format('DD/MM/YYYY');
        dataInicialSearch = comecoDoMesAtual;
        dataFinalSearch = fimDoMesAtual;
        //
        //
        dataInicialSearch = moment(dataInicialSearch, 'DD/MM/YYYY').unix();
        dataFinalSearch = moment(dataFinalSearch, 'DD/MM/YYYY').unix();
        //
        //
      }
      else if (filtro2 == 'Mês Passado') {
        //
        const comecoDoMesPassado = moment().subtract(1, 'months').startOf('month').format('DD/MM/YYYY');
        const fimDoMesPassado = moment().subtract(1, 'months').endOf('month').format('DD/MM/YYYY');
        dataInicialSearch = comecoDoMesPassado;
        dataFinalSearch = fimDoMesPassado;
        //
        //
        dataInicialSearch = moment(dataInicialSearch, 'DD/MM/YYYY').unix();
        dataFinalSearch = moment(dataFinalSearch, 'DD/MM/YYYY').unix();
        //
        //
      }
      else {

        //
      }

      const { currentUser } = firebase.auth();
      firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild('uid').equalTo(currentUser.uid).once("value").then((snapshot) => {
        let keyAtendente = Object.keys(snapshot.val()).toString();
        if (atendenteVeApenasProprioChamado == true && contaPrincipal == false) {
          //aqui o atendente vê somente os proprios chamados
          if (filtroChamado !== 'vencimento') {
            orderByChild = `atendente_${filtroChamado}`;
            dataInicialSearch = `${keyAtendente}${filtro2}`;
            dataFinalSearch = `${keyAtendente}${filtro2}`;
          } else {
            orderByChild = `atendente_dataVencimento/${keyAtendente}`;
          }
        } else {
          if (filtroChamado !== 'vencimento') {
            orderByChild = `${filtroChamado}`;
            filtroPesquisa = `${filtro2}`;
            dataInicialSearch = parseInt(filtro2);
            dataFinalSearch = parseInt(filtro2);
          } else {
            orderByChild = 'dataVencimentoUnix';
          }
        }

        //
        //
        //
        //
        ativaDesativaLoadingTelaChamadoTodos(dispatch, true);
        // if initial fetch
        firebase
          .database()
          .ref(`${chaveUnica}/chamados/`)
          .orderByChild(orderByChild).startAt(dataInicialSearch)
          .endAt(dataFinalSearch)
          .once("value").then(async (snapshot) => {

            if (snapshot.val() == null) {
              enviaChamadoTodos(dispatch, []);
              ativaDesativaLoadingTelaChamadoTodos(dispatch, false);
            } else {
              // changing to reverse chronological order (latest first)
              let arrayOfKeys = Object.keys(snapshot.val())
                .sort()
                .reverse();
              // transforming to array
              let results = arrayOfKeys.map(key => snapshot.val()[key]);
              // storing reference
              referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];

              let chamados = [];
              const { responseClientes, responseAtendentes, responseSetores } = await getAllFirebaseCachedData(chaveUnica, results);

              for (let aux = 0; aux < results.length; aux++) {
                const cliente = responseClientes.find((cliente) => cliente.key === results[aux].cliente);
                const atendente = responseAtendentes.find((atendente) => atendente.key === results[aux].atendente);
                const setor = responseSetores.find((setor) => setor.key === results[aux].setor);

                chamados.push({
                  key: arrayOfKeys[aux],
                  cliente: !cliente || !cliente.data ? "Não informado" : cliente.data.nome,
                  empresa: !cliente || !cliente.data ? "Não informado" : cliente.data.empresa,
                  setor: !setor || !setor.data ? "Não informado" : setor.data,
                  urlImagemNuvem: !cliente || !cliente.data ? null : cliente.data.urlImagemNuvem,
                  atendente: !atendente || !atendente.data ? "Não informado" : atendente.data,




                  dataHoraAbertura: results[aux].dataHoraAbertura,
                  dataVencimento: results[aux].dataVencimento,
                  status: results[aux].status,
                  prioridade:
                    results[aux].prioridade === ""
                      ? "Não informado"
                      : results[aux].prioridade,
                  descricaoProblema: results[aux].descricaoProblema,
                  alterado: results[aux].alterado,
                  chaveUnica: chaveUnica,
                  keyCliente: results[aux].cliente,
                  numeroChamado: results[aux].numeroChamado,
                  dataHoraFechamento: results[aux].dataHoraFechamento,
                  dataHoraCancelamento: results[aux].dataHoraCancelamento,
                  channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                  telefoneCliente: (results[aux].telefoneCliente === undefined) ? 'Não informado' : results[aux].telefoneCliente,
                  chamadoAbertoPorWpp: (results[aux].chamadoAbertoPorWpp === undefined) ? false : results[aux].chamadoAbertoPorWpp,
                  abertoPorChat: (results[aux].abertoPorChat === undefined) ? "false" : results[aux].abertoPorChat,
                  lastMessageChat: results[aux].lastMessageChat == undefined ? '' : results[aux].lastMessageChat,
                  lastMessageChatTimeStamp: results[aux].lastMessageChatTimeStamp == undefined ? 0 : results[aux].lastMessageChatTimeStamp,
                  lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                  lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                  lastMessageLineColor: results[aux].lastMessageLineColor == undefined ? '#39a3f4' : results[aux].lastMessageLineColor,
                  lastmessageChatTypeProblem: (results[aux].lastmessageChatTypeProblem === undefined) ? "mensagem" : results[aux].lastmessageChatTypeProblem,
                });
              }
              ativaDesativaLoadingTelaChamadoTodos(dispatch, false);
              //
              //
              //
              enviaUltimaReferenciaKeyTodos(
                dispatch,
                referenceToOldestKey
              );
              enviaChamadoTodos(dispatch, chamados);
              ativaDesativaLoadingFinalFlatListChamadoTodos(dispatch, false);
            }
          })

        /*   .catch(error => {

          }); */

      })
    });

  };
};
export const listaChamadosFechadoComFiltro = (
  valor,
  referenciaUltimaKey,
  destino,
  chamadosAnteriores,
  atendenteVeApenasProprioChamado,
  contaPrincipal,
  filtroChamado2,
  status,
  filtro
) => {
  return dispatch => {
    let referenceToOldestKey;
    //

    //
    AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");
      const filtroChamado = filtroChamado2.toLowerCase();
      //
      //
      //
      let orderByChild, filtroPesquisa;
      //
      const { currentUser } = firebase.auth();
      firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild('uid').equalTo(currentUser.uid).once("value").then((snapshot) => {
        let keyAtendente = Object.keys(snapshot.val()).toString();
        if (atendenteVeApenasProprioChamado == true && contaPrincipal == false) {
          //aqui o atendente vê somente os proprios chamados
          orderByChild = `status_atendente_${filtroChamado}`;
          filtroPesquisa = `Fechado${keyAtendente}${filtro}`;
        } else {
          orderByChild = `status_${filtroChamado}`;
          filtroPesquisa = `Fechado${filtro}`;
        }

        //
        //
        if (valor == 0) {
          // if initial fetch
          //
          ativaDesativaLoadingTelaChamadoFechado(dispatch, true);
          ativaDesativaLoadingRefreshControlChamadoFechado(dispatch, true);
          firebase
            .database()
            .ref(`${chaveUnica}/chamados/`)
            .orderByChild(orderByChild).equalTo(filtroPesquisa)
            .limitToLast(7)
            .once("value").then(async (snapshot) => {
              if (snapshot.val() == null) {
                enviaChamadoFechado(dispatch, []);
                ativaDesativaLoadingTelaChamadoFechado(dispatch, false);
                ativaDesativaLoadingRefreshControlChamadoFechado(
                  dispatch,
                  false
                );
                //
              } else {
                // changing to reverse chronological order (latest first)
                let arrayOfKeys = Object.keys(snapshot.val())
                  .sort()
                  .reverse();
                // transforming to array
                let results = arrayOfKeys.map(key => snapshot.val()[key]);
                // storing reference
                referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
                let chamados = [];
                const { responseClientes, responseAtendentes, responseSetores } = await getAllFirebaseCachedData(chaveUnica, results);

                for (let aux = 0; aux < results.length; aux++) {
                  const cliente = responseClientes.find((cliente) => cliente.key === results[aux].cliente);
                  const atendente = responseAtendentes.find((atendente) => atendente.key === results[aux].atendente);
                  const setor = responseSetores.find((setor) => setor.key === results[aux].setor);

                  chamados.push({
                    key: arrayOfKeys[aux],
                    cliente: !cliente || !cliente.data ? "Não informado" : cliente.data.nome,
                    empresa: !cliente || !cliente.data ? "Não informado" : cliente.data.empresa,
                    setor: !setor || !setor.data ? "Não informado" : setor.data,
                    urlImagemNuvem: !cliente || !cliente.data ? null : cliente.data.urlImagemNuvem,
                    atendente: !atendente || !atendente.data ? "Não informado" : atendente.data,




                    dataHoraAbertura: results[aux].dataHoraAbertura,
                    dataVencimento: results[aux].dataVencimento,
                    status: results[aux].status,
                    prioridade:
                      results[aux].prioridade === ""
                        ? "Não informado"
                        : results[aux].prioridade,
                    descricaoProblema: results[aux].descricaoProblema,
                    alterado: results[aux].alterado,
                    chaveUnica: chaveUnica,
                    keyCliente: results[aux].cliente,
                    numeroChamado: results[aux].numeroChamado,
                    dataHoraFechamento: results[aux].dataHoraFechamento,
                    channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                    telefoneCliente: (results[aux].telefoneCliente === undefined) ? 'Não informado' : results[aux].telefoneCliente,
                    chamadoAbertoPorWpp: (results[aux].chamadoAbertoPorWpp === undefined) ? false : results[aux].chamadoAbertoPorWpp,
                    abertoPorChat: (results[aux].abertoPorChat === undefined) ? "false" : results[aux].abertoPorChat,
                    lastMessageChat: results[aux].lastMessageChat == undefined ? '' : results[aux].lastMessageChat,
                    lastMessageChatTimeStamp: results[aux].lastMessageChatTimeStamp == undefined ? 0 : results[aux].lastMessageChatTimeStamp,
                    lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                    lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                    lastMessageLineColor: results[aux].lastMessageLineColor == undefined ? '#39a3f4' : results[aux].lastMessageLineColor,
                    lastmessageChatTypeProblem: (results[aux].lastmessageChatTypeProblem === undefined) ? "mensagem" : results[aux].lastmessageChatTypeProblem,
                  });
                }
                enviaChamadoFechado(dispatch, chamados);
                ativaDesativaLoadingTelaChamadoFechado(dispatch, false);
                ativaDesativaLoadingRefreshControlChamadoFechado(
                  dispatch,
                  false
                );
                enviaUltimaReferenciaKeyFechado(
                  dispatch,
                  referenceToOldestKey
                );
                if (chamados.length == 7) {
                  ativaDesativaLoadingFinalFlatListChamadoFechado(dispatch, true);
                } else {
                  ativaDesativaLoadingFinalFlatListChamadoFechado(dispatch, false);
                }

              }
            })

          /*   .catch(error => {

            }); */
        } else {
          if (referenciaUltimaKey != undefined && referenciaUltimaKey != "") {
            /*  ativaDesativaLoadingFinalFlatListChamadoFechado(
               dispatch,
               true
             ); */
            firebase
              .database()
              .ref(`${chaveUnica}/chamados/`)
              .orderByChild(orderByChild)
              .startAt(filtroPesquisa)
              .endAt(filtroPesquisa, referenciaUltimaKey)
              .limitToLast(8)
              .once("value").then(async (snapshot) => {
                if (snapshot.val() == null) {
                  ativaDesativaLoadingFinalFlatListChamadoFechado(dispatch, false);
                } else {

                  // changing to reverse chronological order (latest first)
                  // & removing duplicate
                  let arrayOfKeys = Object.keys(snapshot.val())
                    .sort()
                    .reverse()
                    .slice(1);
                  // transforming to array
                  let results = arrayOfKeys.map(key => snapshot.val()[key]);
                  // updating reference
                  referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
                  let chamados = [];
                  const { responseClientes, responseAtendentes, responseSetores } = await getAllFirebaseCachedData(chaveUnica, results);

                  for (let aux = 0; aux < results.length; aux++) {
                    const cliente = responseClientes.find((cliente) => cliente.key === results[aux].cliente);
                    const atendente = responseAtendentes.find((atendente) => atendente.key === results[aux].atendente);
                    const setor = responseSetores.find((setor) => setor.key === results[aux].setor);

                    chamados.push({
                      key: arrayOfKeys[aux],
                      cliente: !cliente || !cliente.data ? "Não informado" : cliente.data.nome,
                      empresa: !cliente || !cliente.data ? "Não informado" : cliente.data.empresa,
                      setor: !setor || !setor.data ? "Não informado" : setor.data,
                      urlImagemNuvem: !cliente || !cliente.data ? null : cliente.data.urlImagemNuvem,
                      atendente: !atendente || !atendente.data ? "Não informado" : atendente.data,




                      dataHoraAbertura: results[aux].dataHoraAbertura,
                      dataVencimento: results[aux].dataVencimento,
                      status: results[aux].status,
                      prioridade:
                        results[aux].prioridade === ""
                          ? "Não informado"
                          : results[aux].prioridade,
                      descricaoProblema: results[aux].descricaoProblema,
                      alterado: results[aux].alterado,
                      chaveUnica: chaveUnica,
                      keyCliente: results[aux].cliente,
                      numeroChamado: results[aux].numeroChamado,
                      dataHoraFechamento: results[aux].dataHoraFechamento,
                      channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                      telefoneCliente: (results[aux].telefoneCliente === undefined) ? 'Não informado' : results[aux].telefoneCliente,
                      chamadoAbertoPorWpp: (results[aux].chamadoAbertoPorWpp === undefined) ? false : results[aux].chamadoAbertoPorWpp,
                      abertoPorChat: (results[aux].abertoPorChat === undefined) ? "false" : results[aux].abertoPorChat,
                      lastMessageChat: results[aux].lastMessageChat == undefined ? '' : results[aux].lastMessageChat,
                      lastMessageChatTimeStamp: results[aux].lastMessageChatTimeStamp == undefined ? 0 : results[aux].lastMessageChatTimeStamp,
                      lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                      lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                      lastMessageLineColor: results[aux].lastMessageLineColor == undefined ? '#39a3f4' : results[aux].lastMessageLineColor,
                      lastmessageChatTypeProblem: (results[aux].lastmessageChatTypeProblem === undefined) ? "mensagem" : results[aux].lastmessageChatTypeProblem,
                    });
                  }
                  /*   ativaDesativaLoadingFinalFlatListChamadoFechado(
                      dispatch,
                      false
                    ); */
                  enviaChamadoFechado(
                    dispatch,
                    chamadosAnteriores.concat(chamados)
                  );
                  enviaUltimaReferenciaKeyFechado(
                    dispatch,
                    referenceToOldestKey
                  );
                }
              })

            /*  .catch(error => {

             }); */
          } else {
            ativaDesativaLoadingFinalFlatListChamadoFechado(dispatch, false);
          }
        }
      })
    });

  };
};
export const listaChamadosFechadoComFiltroDigitadoEVencimento = (
  valor,
  referenciaUltimaKey,
  destino,
  chamadosAnteriores,
  atendenteVeApenasProprioChamado,
  contaPrincipal,
  filtroChamado2,
  status,
  filtro2
) => {
  return dispatch => {
    let referenceToOldestKey;
    //

    //
    AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");
      let filtroChamado, dataInicialSearch, dataFinalSearch;
      if (filtroChamado2 == 'Número Chamado') {
        filtroChamado = 'numeroChamado'
      } else {
        filtroChamado = filtroChamado2.toLowerCase();
      }
      //
      //
      let orderByChild, filtroPesquisa;
      //
      let dataAtual = retornaDataAtual();
      let datadeOntem = moment().subtract(1, 'days').toDate(); // pego data atual
      datadeOntem = moment(datadeOntem).format('DD/MM/YYYY');
      if (filtro2 == 'Ontem') {
        //;
        //
        dataInicialSearch = moment(datadeOntem, 'DD/MM/YYYY').unix();
        dataFinalSearch = moment(datadeOntem, 'DD/MM/YYYY').unix();
        //
        //

      } else if (filtro2 == 'Hoje') {
        dataInicialSearch = dataAtual;
        dataFinalSearch = dataAtual;
        //
        //
        dataInicialSearch = moment(dataInicialSearch, 'DD/MM/YYYY').unix();
        dataFinalSearch = moment(dataFinalSearch, 'DD/MM/YYYY').unix();
        //
        //
      } else if (filtro2 == 'Amanhã') {
        dataInicialSearch = moment(dataAtual, 'DD/MM/YYYY').add(1, 'days').format('DD/MM/YYYY');
        dataFinalSearch = moment(dataAtual, 'DD/MM/YYYY').add(1, 'days').format('DD/MM/YYYY');
        //
        //
        dataInicialSearch = moment(dataInicialSearch, 'DD/MM/YYYY').unix();
        dataFinalSearch = moment(dataFinalSearch, 'DD/MM/YYYY').unix();
        //
        //

      } else if (filtro2 == 'Semana Atual') {
        const comecoDaSemana = moment().startOf('week').toDate();
        const fimDaSemana = moment().endOf('week').toDate();
        dataInicialSearch = moment(comecoDaSemana).format('DD/MM/YYYY');
        dataFinalSearch = moment(fimDaSemana).format('DD/MM/YYYY');
        //
        //
        dataInicialSearch = moment(dataInicialSearch, 'DD/MM/YYYY').unix();
        dataFinalSearch = moment(dataFinalSearch, 'DD/MM/YYYY').unix();
        //
        //
      } else if (filtro2 == 'Semana Passada') {
        let comecoSemanaPassada = moment().weekday(-7).toDate();
        let fimDaSemanaPassada = moment().weekday(-7).add(6, 'day').toDate();
        dataInicialSearch = moment(comecoSemanaPassada).format('DD/MM/YYYY');
        dataFinalSearch = moment(fimDaSemanaPassada).format('DD/MM/YYYY');
        //
        //
        dataInicialSearch = moment(dataInicialSearch, 'DD/MM/YYYY').unix();
        dataFinalSearch = moment(dataFinalSearch, 'DD/MM/YYYY').unix();
        //
        //
      }
      else if (filtro2 == 'Mês Atual') {
        //
        const comecoDoMesAtual = moment().startOf('month').format('DD/MM/YYYY');
        const fimDoMesAtual = moment().endOf('month').format('DD/MM/YYYY');
        dataInicialSearch = comecoDoMesAtual;
        dataFinalSearch = fimDoMesAtual;
        //
        //
        dataInicialSearch = moment(dataInicialSearch, 'DD/MM/YYYY').unix();
        dataFinalSearch = moment(dataFinalSearch, 'DD/MM/YYYY').unix();
        //
        //
      }
      else if (filtro2 == 'Mês Passado') {
        //
        const comecoDoMesPassado = moment().subtract(1, 'months').startOf('month').format('DD/MM/YYYY');
        const fimDoMesPassado = moment().subtract(1, 'months').endOf('month').format('DD/MM/YYYY');
        dataInicialSearch = comecoDoMesPassado;
        dataFinalSearch = fimDoMesPassado;
        //
        //
        dataInicialSearch = moment(dataInicialSearch, 'DD/MM/YYYY').unix();
        dataFinalSearch = moment(dataFinalSearch, 'DD/MM/YYYY').unix();
        //
        //
      }
      else {

        //
      }

      const { currentUser } = firebase.auth();
      firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild('uid').equalTo(currentUser.uid).once("value").then((snapshot) => {
        let keyAtendente = Object.keys(snapshot.val()).toString();
        if (atendenteVeApenasProprioChamado == true && contaPrincipal == false) {
          //aqui o atendente vê somente os proprios chamados
          if (filtroChamado !== 'vencimento') {
            orderByChild = `status_atendente_${filtroChamado}`;
            dataInicialSearch = `Fechado_${keyAtendente}${filtro2}`;
            dataFinalSearch = `Fechado_${keyAtendente}${filtro2}`;
          } else {
            orderByChild = `status_atendente_dataVencimento/Fechado/${keyAtendente}`;
          }
        } else {
          if (filtroChamado !== 'vencimento') {
            orderByChild = `status_${filtroChamado}`;
            filtroPesquisa = `Fechado_${filtro2}`;
            dataInicialSearch = `Fechado_${filtro2}`;
            dataFinalSearch = `Fechado_${filtro2}`;
          } else {
            orderByChild = 'status_dataVencimento/Fechado/';
          }
        }

        //
        //

        // if initial fetch
        //
        ativaDesativaLoadingTelaChamadoFechado(dispatch, true);
        ativaDesativaLoadingRefreshControlChamadoFechado(dispatch, true);
        firebase
          .database()
          .ref(`${chaveUnica}/chamados/`)
          .orderByChild(orderByChild).startAt(dataInicialSearch)
          .endAt(dataFinalSearch)
          .once("value").then(async (snapshot) => {
            if (snapshot.val() == null) {
              enviaChamadoFechado(dispatch, []);
              ativaDesativaLoadingTelaChamadoFechado(dispatch, false);
              ativaDesativaLoadingRefreshControlChamadoFechado(
                dispatch,
                false
              );
              //
            } else {
              // changing to reverse chronological order (latest first)
              let arrayOfKeys = Object.keys(snapshot.val())
                .sort()
                .reverse();
              // transforming to array
              let results = arrayOfKeys.map(key => snapshot.val()[key]);
              // storing reference
              referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
              let chamados = [];
              const { responseClientes, responseAtendentes, responseSetores } = await getAllFirebaseCachedData(chaveUnica, results);

              for (let aux = 0; aux < results.length; aux++) {
                const cliente = responseClientes.find((cliente) => cliente.key === results[aux].cliente);
                const atendente = responseAtendentes.find((atendente) => atendente.key === results[aux].atendente);
                const setor = responseSetores.find((setor) => setor.key === results[aux].setor);

                chamados.push({
                  key: arrayOfKeys[aux],
                  cliente: !cliente || !cliente.data ? "Não informado" : cliente.data.nome,
                  empresa: !cliente || !cliente.data ? "Não informado" : cliente.data.empresa,
                  setor: !setor || !setor.data ? "Não informado" : setor.data,
                  urlImagemNuvem: !cliente || !cliente.data ? null : cliente.data.urlImagemNuvem,
                  atendente: !atendente || !atendente.data ? "Não informado" : atendente.data,




                  dataHoraAbertura: results[aux].dataHoraAbertura,
                  dataVencimento: results[aux].dataVencimento,
                  status: results[aux].status,
                  prioridade:
                    results[aux].prioridade === ""
                      ? "Não informado"
                      : results[aux].prioridade,
                  descricaoProblema: results[aux].descricaoProblema,
                  alterado: results[aux].alterado,
                  chaveUnica: chaveUnica,
                  keyCliente: results[aux].cliente,
                  numeroChamado: results[aux].numeroChamado,
                  dataHoraFechamento: results[aux].dataHoraFechamento,
                  channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                  telefoneCliente: (results[aux].telefoneCliente === undefined) ? 'Não informado' : results[aux].telefoneCliente,
                  chamadoAbertoPorWpp: (results[aux].chamadoAbertoPorWpp === undefined) ? false : results[aux].chamadoAbertoPorWpp,
                  abertoPorChat: (results[aux].abertoPorChat === undefined) ? "false" : results[aux].abertoPorChat,
                  lastMessageChat: results[aux].lastMessageChat == undefined ? '' : results[aux].lastMessageChat,
                  lastMessageChatTimeStamp: results[aux].lastMessageChatTimeStamp == undefined ? 0 : results[aux].lastMessageChatTimeStamp,
                  lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                  lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                  lastMessageLineColor: results[aux].lastMessageLineColor == undefined ? '#39a3f4' : results[aux].lastMessageLineColor,
                  lastmessageChatTypeProblem: (results[aux].lastmessageChatTypeProblem === undefined) ? "mensagem" : results[aux].lastmessageChatTypeProblem,
                });
              }
              enviaChamadoFechado(dispatch, chamados);
              ativaDesativaLoadingTelaChamadoFechado(dispatch, false);
              ativaDesativaLoadingRefreshControlChamadoFechado(
                dispatch,
                false
              );
              ativaDesativaLoadingFinalFlatListChamadoFechado(dispatch, false);
              enviaUltimaReferenciaKeyFechado(
                dispatch,
                referenceToOldestKey
              );

            }
          })

        /*  .catch(error => {

         }); */

      })
    });

  };
};

export const listaChamadosAtendimentoComFiltro = (
  valor,
  referenciaUltimaKey,
  destino,
  chamadosAnteriores,
  atendenteVeApenasProprioChamado,
  contaPrincipal,
  filtroChamado2,
  status,
  filtro
) => {
  return dispatch => {
    let referenceToOldestKey;
    //

    //
    AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");
      const filtroChamado = filtroChamado2.toLowerCase();
      //
      //
      //
      let orderByChild, filtroPesquisa;
      //
      const { currentUser } = firebase.auth();
      firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild('uid').equalTo(currentUser.uid).once("value").then((snapshot) => {
        let keyAtendente = Object.keys(snapshot.val()).toString();
        if (atendenteVeApenasProprioChamado == true && contaPrincipal == false) {
          //aqui o atendente vê somente os proprios chamados
          orderByChild = `status_atendente_${filtroChamado}`;
          filtroPesquisa = `Atendendo${keyAtendente}${filtro}`;
        } else {
          orderByChild = `status_${filtroChamado}`;
          filtroPesquisa = `Atendendo${filtro}`;
        }

        //;
        //
        if (valor == 0) {
          // if initial fetch
          ativaDesativaLoadingTelaChamadoAtendimento(dispatch, true);
          ativaDesativaLoadingRefreshControlChamadoEmAtendimento(dispatch, true);

          firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild(orderByChild).equalTo(filtroPesquisa).limitToLast(7)
            .once("value").then(async (snapshot) => {
              if (snapshot.val() == null) {
                enviaChamadoEmAtendimento(dispatch, []);
                ativaDesativaLoadingTelaChamadoAtendimento(dispatch, false);
                ativaDesativaLoadingRefreshControlChamadoEmAtendimento(
                  dispatch,
                  false
                );
                //
              } else {
                // changing to reverse chronological order (latest first)
                let arrayOfKeys = Object.keys(snapshot.val()).sort().reverse();
                // transforming to array
                let results = arrayOfKeys.map(key => snapshot.val()[key]);
                // storing reference
                referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
                let chamados = [];
                const { responseClientes, responseAtendentes, responseSetores } = await getAllFirebaseCachedData(chaveUnica, results);

                for (let aux = 0; aux < results.length; aux++) {
                  const cliente = responseClientes.find((cliente) => cliente.key === results[aux].cliente);
                  const atendente = responseAtendentes.find((atendente) => atendente.key === results[aux].atendente);
                  const setor = responseSetores.find((setor) => setor.key === results[aux].setor);

                  chamados.push({
                    key: arrayOfKeys[aux],
                    cliente: !cliente || !cliente.data ? "Não informado" : cliente.data.nome,
                    empresa: !cliente || !cliente.data ? "Não informado" : cliente.data.empresa,
                    setor: !setor || !setor.data ? "Não informado" : setor.data,
                    urlImagemNuvem: !cliente || !cliente.data ? null : cliente.data.urlImagemNuvem,
                    atendente: !atendente || !atendente.data ? "Não informado" : atendente.data,




                    dataHoraAbertura: results[aux].dataHoraAbertura,
                    dataVencimento: results[aux].dataVencimento,
                    status: results[aux].status,
                    prioridade:
                      results[aux].prioridade === ""
                        ? "Não informado"
                        : results[aux].prioridade,
                    descricaoProblema: results[aux].descricaoProblema,
                    alterado: results[aux].alterado,
                    chaveUnica: chaveUnica,
                    keyCliente: results[aux].cliente,
                    numeroChamado: results[aux].numeroChamado,
                    channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                    telefoneCliente: (results[aux].telefoneCliente === undefined) ? 'Não informado' : results[aux].telefoneCliente,
                    chamadoAbertoPorWpp: (results[aux].chamadoAbertoPorWpp === undefined) ? false : results[aux].chamadoAbertoPorWpp,
                    abertoPorChat: (results[aux].abertoPorChat === undefined) ? "false" : results[aux].abertoPorChat,
                    lastMessageChat: results[aux].lastMessageChat == undefined ? '' : results[aux].lastMessageChat,
                    lastMessageChatTimeStamp: results[aux].lastMessageChatTimeStamp == undefined ? 0 : results[aux].lastMessageChatTimeStamp,
                    lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                    lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                    lastMessageLineColor: results[aux].lastMessageLineColor == undefined ? '#39a3f4' : results[aux].lastMessageLineColor,
                    lastmessageChatTypeProblem: (results[aux].lastmessageChatTypeProblem === undefined) ? "mensagem" : results[aux].lastmessageChatTypeProblem,
                  });
                }
                enviaUltimaReferenciaKeyEmAtendimento(
                  dispatch,
                  referenceToOldestKey
                );
                enviaChamadoEmAtendimento(dispatch, chamados);
                ativaDesativaLoadingTelaChamadoAtendimento(dispatch, false);
                ativaDesativaLoadingRefreshControlChamadoEmAtendimento(
                  dispatch,
                  false
                );
                if (chamados.length == 7) {
                  ativaDesativaLoadingFinalFlatListChamadoEmAtendimento(dispatch, true);
                } else {
                  ativaDesativaLoadingFinalFlatListChamadoEmAtendimento(dispatch, false);
                }
                //
              }
            })

          /*  .catch(error => {

           }); */
        } else {
          if (referenciaUltimaKey != undefined && referenciaUltimaKey != "") {
            //   ativaDesativaLoadingFinalFlatListChamadoEmAtendimento(dispatch,true);
            firebase
              .database()
              .ref(`${chaveUnica}/chamados/`)
              .orderByChild(orderByChild)
              .startAt(filtroPesquisa)
              .endAt(filtroPesquisa, referenciaUltimaKey)
              .limitToLast(8)
              .once("value").then(async (snapshot) => {
                if (snapshot.val() == null) {
                  ativaDesativaLoadingFinalFlatListChamadoEmAtendimento(
                    dispatch,
                    false
                  );
                } else {

                  // changing to reverse chronological order (latest first)
                  // & removing duplicate
                  let arrayOfKeys = Object.keys(snapshot.val())
                    .sort()
                    .reverse()
                    .slice(1);
                  // transforming to array
                  let results = arrayOfKeys.map(key => snapshot.val()[key]);
                  // updating reference
                  referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
                  let chamados = [];
                  const { responseClientes, responseAtendentes, responseSetores } = await getAllFirebaseCachedData(chaveUnica, results);

                  for (let aux = 0; aux < results.length; aux++) {
                    const cliente = responseClientes.find((cliente) => cliente.key === results[aux].cliente);
                    const atendente = responseAtendentes.find((atendente) => atendente.key === results[aux].atendente);
                    const setor = responseSetores.find((setor) => setor.key === results[aux].setor);

                    chamados.push({
                      key: arrayOfKeys[aux],
                      cliente: !cliente || !cliente.data ? "Não informado" : cliente.data.nome,
                      empresa: !cliente || !cliente.data ? "Não informado" : cliente.data.empresa,
                      setor: !setor || !setor.data ? "Não informado" : setor.data,
                      urlImagemNuvem: !cliente || !cliente.data ? null : cliente.data.urlImagemNuvem,
                      atendente: !atendente || !atendente.data ? "Não informado" : atendente.data,




                      dataHoraAbertura: results[aux].dataHoraAbertura,
                      dataVencimento: results[aux].dataVencimento,
                      status: results[aux].status,
                      prioridade:
                        results[aux].prioridade === ""
                          ? "Não informado"
                          : results[aux].prioridade,
                      descricaoProblema: results[aux].descricaoProblema,
                      alterado: results[aux].alterado,
                      chaveUnica: chaveUnica,
                      keyCliente: results[aux].cliente,
                      numeroChamado: results[aux].numeroChamado,
                      channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                      telefoneCliente: (results[aux].telefoneCliente === undefined) ? 'Não informado' : results[aux].telefoneCliente,
                      chamadoAbertoPorWpp: (results[aux].chamadoAbertoPorWpp === undefined) ? false : results[aux].chamadoAbertoPorWpp,
                      abertoPorChat: (results[aux].abertoPorChat === undefined) ? "false" : results[aux].abertoPorChat,
                      lastMessageChat: results[aux].lastMessageChat == undefined ? '' : results[aux].lastMessageChat,
                      lastMessageChatTimeStamp: results[aux].lastMessageChatTimeStamp == undefined ? 0 : results[aux].lastMessageChatTimeStamp,
                      lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                      lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                      lastMessageLineColor: results[aux].lastMessageLineColor == undefined ? '#39a3f4' : results[aux].lastMessageLineColor,
                      lastmessageChatTypeProblem: (results[aux].lastmessageChatTypeProblem === undefined) ? "mensagem" : results[aux].lastmessageChatTypeProblem,
                    });
                  }
                  /*    ativaDesativaLoadingFinalFlatListChamadoEmAtendimento(
                       dispatch,
                       false
                     ); */
                  //
                  //
                  //
                  //
                  enviaChamadoEmAtendimento(
                    dispatch,
                    chamadosAnteriores.concat(chamados)
                  );
                  enviaUltimaReferenciaKeyEmAtendimento(
                    dispatch,
                    referenceToOldestKey
                  );
                }
              })

            /*    .catch(error => {

               }); */
          } else {
            ativaDesativaLoadingFinalFlatListChamadoEmAtendimento(dispatch, false);
          }
        }
      })
    });

  };
};
export const listaChamadosAtendimentoComFiltroDigitadoEVencimento = (
  valor,
  referenciaUltimaKey,
  destino,
  chamadosAnteriores,
  atendenteVeApenasProprioChamado,
  contaPrincipal,
  filtroChamado2,
  status,
  filtro2
) => {
  return dispatch => {
    let referenceToOldestKey;
    //

    //
    AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");
      let filtroChamado, dataInicialSearch, dataFinalSearch;
      if (filtroChamado2 == 'Número Chamado') {
        filtroChamado = 'numeroChamado'
      } else {
        filtroChamado = filtroChamado2.toLowerCase();
      }
      //
      //
      let orderByChild, filtroPesquisa;
      //
      let dataAtual = retornaDataAtual();
      let datadeOntem = moment().subtract(1, 'days').toDate(); // pego data atual
      datadeOntem = moment(datadeOntem).format('DD/MM/YYYY');
      if (filtro2 == 'Ontem') {
        //
        //
        dataInicialSearch = moment(datadeOntem, 'DD/MM/YYYY').unix();
        dataFinalSearch = moment(datadeOntem, 'DD/MM/YYYY').unix();
        //
        //

      } else if (filtro2 == 'Hoje') {
        dataInicialSearch = dataAtual;
        dataFinalSearch = dataAtual;
        //
        //
        dataInicialSearch = moment(dataInicialSearch, 'DD/MM/YYYY').unix();
        dataFinalSearch = moment(dataFinalSearch, 'DD/MM/YYYY').unix();
        //
        //
      } else if (filtro2 == 'Amanhã') {
        dataInicialSearch = moment(dataAtual, 'DD/MM/YYYY').add(1, 'days').format('DD/MM/YYYY');
        dataFinalSearch = moment(dataAtual, 'DD/MM/YYYY').add(1, 'days').format('DD/MM/YYYY');
        //;
        //;
        dataInicialSearch = moment(dataInicialSearch, 'DD/MM/YYYY').unix();
        dataFinalSearch = moment(dataFinalSearch, 'DD/MM/YYYY').unix();
        //
        //

      } else if (filtro2 == 'Semana Atual') {
        const comecoDaSemana = moment().startOf('week').toDate();
        const fimDaSemana = moment().endOf('week').toDate();
        dataInicialSearch = moment(comecoDaSemana).format('DD/MM/YYYY');
        dataFinalSearch = moment(fimDaSemana).format('DD/MM/YYYY');
        //
        //
        dataInicialSearch = moment(dataInicialSearch, 'DD/MM/YYYY').unix();
        dataFinalSearch = moment(dataFinalSearch, 'DD/MM/YYYY').unix();
        //
        //
      } else if (filtro2 == 'Semana Passada') {
        let comecoSemanaPassada = moment().weekday(-7).toDate();
        let fimDaSemanaPassada = moment().weekday(-7).add(6, 'day').toDate();
        dataInicialSearch = moment(comecoSemanaPassada).format('DD/MM/YYYY');
        dataFinalSearch = moment(fimDaSemanaPassada).format('DD/MM/YYYY');
        //
        //
        dataInicialSearch = moment(dataInicialSearch, 'DD/MM/YYYY').unix();
        dataFinalSearch = moment(dataFinalSearch, 'DD/MM/YYYY').unix();
        //
        //
      }
      else if (filtro2 == 'Mês Atual') {
        //
        const comecoDoMesAtual = moment().startOf('month').format('DD/MM/YYYY');
        const fimDoMesAtual = moment().endOf('month').format('DD/MM/YYYY');
        dataInicialSearch = comecoDoMesAtual;
        dataFinalSearch = fimDoMesAtual;
        //;
        //
        dataInicialSearch = moment(dataInicialSearch, 'DD/MM/YYYY').unix();
        dataFinalSearch = moment(dataFinalSearch, 'DD/MM/YYYY').unix();
        //;
        //;
      }
      else if (filtro2 == 'Mês Passado') {
        //
        const comecoDoMesPassado = moment().subtract(1, 'months').startOf('month').format('DD/MM/YYYY');
        const fimDoMesPassado = moment().subtract(1, 'months').endOf('month').format('DD/MM/YYYY');
        dataInicialSearch = comecoDoMesPassado;
        dataFinalSearch = fimDoMesPassado;
        //;
        //
        dataInicialSearch = moment(dataInicialSearch, 'DD/MM/YYYY').unix();
        dataFinalSearch = moment(dataFinalSearch, 'DD/MM/YYYY').unix();
        //
        //
      }
      else {

        //
      }

      const { currentUser } = firebase.auth();
      firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild('uid').equalTo(currentUser.uid).once("value").then((snapshot) => {
        let keyAtendente = Object.keys(snapshot.val()).toString();
        if (atendenteVeApenasProprioChamado == true && contaPrincipal == false) {
          //aqui o atendente vê somente os proprios chamados
          if (filtroChamado !== 'vencimento') {
            orderByChild = `status_atendente_${filtroChamado}`;
            dataInicialSearch = `Atendendo_${keyAtendente}${filtro2}`;
            dataFinalSearch = `Atendendo_${keyAtendente}${filtro2}`;
          } else {
            orderByChild = `status_atendente_dataVencimento/Atendendo/${keyAtendente}`;
          }
        } else {
          if (filtroChamado !== 'vencimento') {
            orderByChild = `status_${filtroChamado}`;
            filtroPesquisa = `Atendendo_${filtro2}`;
            dataInicialSearch = `Atendendo_${filtro2}`;
            dataFinalSearch = `Atendendo_${filtro2}`;
          } else {
            orderByChild = 'status_dataVencimento/Atendendo/';
          }
        }

        //
        //

        // if initial fetch
        ativaDesativaLoadingTelaChamadoAtendimento(dispatch, true);
        ativaDesativaLoadingRefreshControlChamadoEmAtendimento(dispatch, true);

        firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild(orderByChild).startAt(dataInicialSearch)
          .endAt(dataFinalSearch).once("value").then(async (snapshot) => {
            if (snapshot.val() == null) {
              enviaChamadoEmAtendimento(dispatch, []);
              ativaDesativaLoadingTelaChamadoAtendimento(dispatch, false);
              ativaDesativaLoadingRefreshControlChamadoEmAtendimento(
                dispatch,
                false
              );
              //
            } else {
              // changing to reverse chronological order (latest first)
              let arrayOfKeys = Object.keys(snapshot.val()).sort().reverse();
              // transforming to array
              let results = arrayOfKeys.map(key => snapshot.val()[key]);
              // storing reference
              referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
              let chamados = [];
              const { responseClientes, responseAtendentes, responseSetores } = await getAllFirebaseCachedData(chaveUnica, results);

              for (let aux = 0; aux < results.length; aux++) {
                const cliente = responseClientes.find((cliente) => cliente.key === results[aux].cliente);
                const atendente = responseAtendentes.find((atendente) => atendente.key === results[aux].atendente);
                const setor = responseSetores.find((setor) => setor.key === results[aux].setor);

                chamados.push({
                  key: arrayOfKeys[aux],
                  cliente: !cliente || !cliente.data ? "Não informado" : cliente.data.nome,
                  empresa: !cliente || !cliente.data ? "Não informado" : cliente.data.empresa,
                  setor: !setor || !setor.data ? "Não informado" : setor.data,
                  urlImagemNuvem: !cliente || !cliente.data ? null : cliente.data.urlImagemNuvem,
                  atendente: !atendente || !atendente.data ? "Não informado" : atendente.data,




                  dataHoraAbertura: results[aux].dataHoraAbertura,
                  dataVencimento: results[aux].dataVencimento,
                  status: results[aux].status,
                  prioridade:
                    results[aux].prioridade === ""
                      ? "Não informado"
                      : results[aux].prioridade,
                  descricaoProblema: results[aux].descricaoProblema,
                  alterado: results[aux].alterado,
                  chaveUnica: chaveUnica,
                  keyCliente: results[aux].cliente,
                  numeroChamado: results[aux].numeroChamado,
                  channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                  telefoneCliente: (results[aux].telefoneCliente === undefined) ? 'Não informado' : results[aux].telefoneCliente,
                  chamadoAbertoPorWpp: (results[aux].chamadoAbertoPorWpp === undefined) ? false : results[aux].chamadoAbertoPorWpp,
                  abertoPorChat: (results[aux].abertoPorChat === undefined) ? "false" : results[aux].abertoPorChat,
                  lastMessageChat: results[aux].lastMessageChat == undefined ? '' : results[aux].lastMessageChat,
                  lastMessageChatTimeStamp: results[aux].lastMessageChatTimeStamp == undefined ? 0 : results[aux].lastMessageChatTimeStamp,
                  lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                  lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                  lastMessageLineColor: results[aux].lastMessageLineColor == undefined ? '#39a3f4' : results[aux].lastMessageLineColor,
                  lastmessageChatTypeProblem: (results[aux].lastmessageChatTypeProblem === undefined) ? "mensagem" : results[aux].lastmessageChatTypeProblem,
                });
              }
              enviaUltimaReferenciaKeyEmAtendimento(
                dispatch,
                referenceToOldestKey
              );
              enviaChamadoEmAtendimento(dispatch, chamados);
              ativaDesativaLoadingTelaChamadoAtendimento(dispatch, false);
              ativaDesativaLoadingFinalFlatListChamadoEmAtendimento(dispatch, false);
              ativaDesativaLoadingRefreshControlChamadoEmAtendimento(
                dispatch,
                false
              );
            }
          })

        /*    .catch(error => {

           }); */

      })
    });

  };
};

export const listaChamadosAbertoComFiltro = (
  valor,
  referenciaUltimaKey,
  chamadosAnteriores,
  destino,
  atendenteVeApenasProprioChamado,
  contaPrincipal,
  filtroChamado2,
  status,
  filtro
) => {
  return dispatch => {
    let referenceToOldestKey;

    AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");
      const filtroChamado = filtroChamado2.toLowerCase();
      /*

        */
      let orderByChild, filtroPesquisa;
      const { currentUser } = firebase.auth();
      firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild('uid').equalTo(currentUser.uid).once("value").then((snapshot) => {
        let keyAtendente = Object.keys(snapshot.val()).toString();
        if (atendenteVeApenasProprioChamado == true && contaPrincipal == false) {
          orderByChild = `status_atendente_${filtroChamado}`;
          filtroPesquisa = `Aberto${keyAtendente}${filtro}`;
        }
        //chamado padrao
        else {
          orderByChild = `status_${filtroChamado}`;
          filtroPesquisa = `Aberto${filtro}`;
        }
        /*
           */
        if (valor == 0) {
          // if initial fetch
          ativaLoadingTelaAberturaChamado(dispatch);
          ativaDesativaLoadingRefreshControlChamadoAberto(dispatch, true);
          firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild(orderByChild).equalTo(filtroPesquisa).limitToLast(7)
            .once("value").then(async (snapshot) => {
              if (snapshot.val() == null) {
                enviaChamadoAberto(dispatch, []);
                desativaLoadingTelaChamadoAberto(dispatch);
                ativaDesativaLoadingRefreshControlChamadoAberto(
                  dispatch,
                  false
                );
              } else {
                // changing to reverse chronological order (latest first)
                let arrayOfKeys = Object.keys(snapshot.val()).sort().reverse();

                // transforming to array
                let results = arrayOfKeys.map(key => snapshot.val()[key]);
                // storing reference
                referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
                let chamados = [];
                const { responseClientes, responseAtendentes, responseSetores } = await getAllFirebaseCachedData(chaveUnica, results);

                for (let aux = 0; aux < results.length; aux++) {
                  const cliente = responseClientes.find((cliente) => cliente.key === results[aux].cliente);
                  const atendente = responseAtendentes.find((atendente) => atendente.key === results[aux].atendente);
                  const setor = responseSetores.find((setor) => setor.key === results[aux].setor);

                  chamados.push({
                    key: arrayOfKeys[aux],
                    cliente: !cliente || !cliente.data ? "Não informado" : cliente.data.nome,
                    empresa: !cliente || !cliente.data ? "Não informado" : cliente.data.empresa,
                    setor: !setor || !setor.data ? "Não informado" : setor.data,
                    urlImagemNuvem: !cliente || !cliente.data ? null : cliente.data.urlImagemNuvem,
                    atendente: !atendente || !atendente.data ? "Não informado" : atendente.data,




                    dataHoraAbertura: results[aux].dataHoraAbertura,
                    dataVencimento: results[aux].dataVencimento,
                    status: results[aux].status,
                    prioridade: results[aux].prioridade === "" ? "Não informado" : results[aux].prioridade,
                    descricaoProblema: results[aux].descricaoProblema,
                    alterado: results[aux].alterado,
                    chaveUnica: chaveUnica,
                    keyCliente: results[aux].cliente,
                    numeroChamado: results[aux].numeroChamado,
                    channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                    telefoneCliente: (results[aux].telefoneCliente === undefined) ? 'Não informado' : results[aux].telefoneCliente,
                    chamadoAbertoPorWpp: (results[aux].chamadoAbertoPorWpp === undefined) ? false : results[aux].chamadoAbertoPorWpp,
                    abertoPorChat: (results[aux].abertoPorChat === undefined) ? "false" : results[aux].abertoPorChat,
                    lastMessageChat: results[aux].lastMessageChat == undefined ? '' : results[aux].lastMessageChat,
                    lastMessageChatTimeStamp: results[aux].lastMessageChatTimeStamp == undefined ? 0 : results[aux].lastMessageChatTimeStamp,
                    lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                    lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                    lastMessageLineColor: results[aux].lastMessageLineColor == undefined ? '#39a3f4' : results[aux].lastMessageLineColor,
                    lastmessageChatTypeProblem: (results[aux].lastmessageChatTypeProblem === undefined) ? "mensagem" : results[aux].lastmessageChatTypeProblem,
                  });
                }
                enviaChamadoAberto(dispatch, chamados);
                desativaLoadingTelaChamadoAberto(dispatch);
                ativaDesativaLoadingRefreshControlChamadoAberto(dispatch, false);
                enviaUltimaReferenciaKey(dispatch, referenceToOldestKey);
                if (chamados.length == 7) {
                  ativaDesativaLoadingFinalFlatListChamadoAberto(dispatch, true);
                } else {
                  ativaDesativaLoadingFinalFlatListChamadoAberto(dispatch, false);
                }
                // ativaDesativaLoadingFinalFlatListChamadoAberto(dispatch, true);
                /*

                    */

                //    enviaChamadoAberto(dispatch, chamados);
                //enviaUltimaReferenciaKey(dispatch, referenceToOldestKey)
              }
            })/* .catch(error => {


            }); */
        } else {
          if (referenciaUltimaKey != undefined && referenciaUltimaKey != "") {
            // ativaDesativaLoadingFinalFlatListChamadoAberto(dispatch, true);
            firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild(orderByChild).startAt(filtroPesquisa).endAt(filtroPesquisa, referenciaUltimaKey)
              .limitToLast(8)
              .once("value").then(async (snapshot) => {
                if (snapshot.val() == null) {
                  ativaDesativaLoadingFinalFlatListChamadoAberto(dispatch, false);
                } else {
                  // changing to reverse chronological order (latest first)
                  // & removing duplicate
                  let arrayOfKeys = Object.keys(snapshot.val())
                    .sort()
                    .reverse()
                    .slice(1);
                  // transforming to array
                  let results = arrayOfKeys.map(key => snapshot.val()[key]);
                  // updating reference
                  referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];

                  let chamados = [];
                  const { responseClientes, responseAtendentes, responseSetores } = await getAllFirebaseCachedData(chaveUnica, results);

                  for (let aux = 0; aux < results.length; aux++) {
                    const cliente = responseClientes.find((cliente) => cliente.key === results[aux].cliente);
                    const atendente = responseAtendentes.find((atendente) => atendente.key === results[aux].atendente);
                    const setor = responseSetores.find((setor) => setor.key === results[aux].setor);

                    chamados.push({
                      key: arrayOfKeys[aux],
                      cliente: !cliente || !cliente.data ? "Não informado" : cliente.data.nome,
                      empresa: !cliente || !cliente.data ? "Não informado" : cliente.data.empresa,
                      setor: !setor || !setor.data ? "Não informado" : setor.data,
                      urlImagemNuvem: !cliente || !cliente.data ? null : cliente.data.urlImagemNuvem,
                      atendente: !atendente || !atendente.data ? "Não informado" : atendente.data,




                      dataHoraAbertura: results[aux].dataHoraAbertura,
                      dataVencimento: results[aux].dataVencimento,
                      status: results[aux].status,
                      prioridade: results[aux].prioridade === "" ? "Não informado" : results[aux].prioridade,
                      descricaoProblema: results[aux].descricaoProblema,
                      alterado: results[aux].alterado,
                      chaveUnica: chaveUnica,
                      keyCliente: results[aux].cliente,
                      numeroChamado: results[aux].numeroChamado,
                      channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                      telefoneCliente: (results[aux].telefoneCliente === undefined) ? 'Não informado' : results[aux].telefoneCliente,
                      chamadoAbertoPorWpp: (results[aux].chamadoAbertoPorWpp === undefined) ? false : results[aux].chamadoAbertoPorWpp,
                      abertoPorChat: (results[aux].abertoPorChat === undefined) ? "false" : results[aux].abertoPorChat,
                      lastMessageChat: results[aux].lastMessageChat == undefined ? '' : results[aux].lastMessageChat,
                      lastMessageChatTimeStamp: results[aux].lastMessageChatTimeStamp == undefined ? 0 : results[aux].lastMessageChatTimeStamp,
                      lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                      lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                      lastMessageLineColor: results[aux].lastMessageLineColor == undefined ? '#39a3f4' : results[aux].lastMessageLineColor,
                      lastmessageChatTypeProblem: (results[aux].lastmessageChatTypeProblem === undefined) ? "mensagem" : results[aux].lastmessageChatTypeProblem,
                    });
                  }
                  //  ativaDesativaLoadingFinalFlatListChamadoAberto(dispatch, false);
                  enviaChamadoAberto(dispatch, chamadosAnteriores.concat(chamados));

                  enviaUltimaReferenciaKey(dispatch, referenceToOldestKey);
                }
              })/* .catch(error => {

              }); */
          } else {
            ativaDesativaLoadingFinalFlatListChamadoAberto(dispatch, false);
          }
        }
      })
    });

  };
};
export const listaChamadosAbertoComFiltroDigitado = (
  valor,
  referenciaUltimaKey,
  chamadosAnteriores,
  destino,
  atendenteVeApenasProprioChamado,
  contaPrincipal,
  filtroChamado2,
  status,
  filtro
) => {
  return dispatch => {
    let referenceToOldestKey;

    AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");
      let filtroChamado;
      if (filtroChamado2 == 'Número Chamado') {
        filtroChamado = 'numeroChamado'
      } else {
        filtroChamado = filtroChamado2.toLowerCase();

      }
      /*
      */
      let orderByChild, filtroPesquisa;
      const { currentUser } = firebase.auth();
      firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild('uid').equalTo(currentUser.uid).once("value").then((snapshot) => {
        let keyAtendente = Object.keys(snapshot.val()).toString();
        if (atendenteVeApenasProprioChamado == true && contaPrincipal == false) {
          orderByChild = `status_atendente_${filtroChamado}`;
          filtroPesquisa = `Aberto_${keyAtendente}${filtro}`;
        }
        //chamado padrao
        else {
          orderByChild = `status_${filtroChamado}`;
          filtroPesquisa = `Aberto_${filtro}`;
        }
        /*
         */


        if (valor == 0) {
          // if initial fetch
          ativaLoadingTelaAberturaChamado(dispatch);
          ativaDesativaLoadingRefreshControlChamadoAberto(dispatch, true);
          firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild(orderByChild).startAt(filtroPesquisa)
            .endAt(filtroPesquisa).limitToLast(7).once("value").then(async (snapshot) => {
              if (snapshot.val() == null) {
                enviaChamadoAberto(dispatch, []);
                desativaLoadingTelaChamadoAberto(dispatch);
                ativaDesativaLoadingRefreshControlChamadoAberto(
                  dispatch,
                  false
                );
              } else {
                // changing to reverse chronological order (latest first)
                let arrayOfKeys = Object.keys(snapshot.val()).sort().reverse();

                // transforming to array
                let results = arrayOfKeys.map(key => snapshot.val()[key]);
                // storing reference
                referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
                let chamados = [];
                const { responseClientes, responseAtendentes, responseSetores } = await getAllFirebaseCachedData(chaveUnica, results);

                for (let aux = 0; aux < results.length; aux++) {
                  const cliente = responseClientes.find((cliente) => cliente.key === results[aux].cliente);
                  const atendente = responseAtendentes.find((atendente) => atendente.key === results[aux].atendente);
                  const setor = responseSetores.find((setor) => setor.key === results[aux].setor);

                  chamados.push({
                    key: arrayOfKeys[aux],
                    cliente: !cliente || !cliente.data ? "Não informado" : cliente.data.nome,
                    empresa: !cliente || !cliente.data ? "Não informado" : cliente.data.empresa,
                    setor: !setor || !setor.data ? "Não informado" : setor.data,
                    urlImagemNuvem: !cliente || !cliente.data ? null : cliente.data.urlImagemNuvem,
                    atendente: !atendente || !atendente.data ? "Não informado" : atendente.data,




                    dataHoraAbertura: results[aux].dataHoraAbertura,
                    dataVencimento: results[aux].dataVencimento,
                    status: results[aux].status,
                    prioridade: results[aux].prioridade === "" ? "Não informado" : results[aux].prioridade,
                    descricaoProblema: results[aux].descricaoProblema,
                    alterado: results[aux].alterado,
                    chaveUnica: chaveUnica,
                    keyCliente: results[aux].cliente,
                    numeroChamado: results[aux].numeroChamado,
                    channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                    telefoneCliente: (results[aux].telefoneCliente === undefined) ? 'Não informado' : results[aux].telefoneCliente,
                    chamadoAbertoPorWpp: (results[aux].chamadoAbertoPorWpp === undefined) ? false : results[aux].chamadoAbertoPorWpp,
                    abertoPorChat: (results[aux].abertoPorChat === undefined) ? "false" : results[aux].abertoPorChat,
                    lastMessageChat: results[aux].lastMessageChat == undefined ? '' : results[aux].lastMessageChat,
                    lastMessageChatTimeStamp: results[aux].lastMessageChatTimeStamp == undefined ? 0 : results[aux].lastMessageChatTimeStamp,
                    lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                    lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                    lastMessageLineColor: results[aux].lastMessageLineColor == undefined ? '#39a3f4' : results[aux].lastMessageLineColor,
                    lastmessageChatTypeProblem: (results[aux].lastmessageChatTypeProblem === undefined) ? "mensagem" : results[aux].lastmessageChatTypeProblem,
                  });
                }
                enviaChamadoAberto(dispatch, chamados);
                desativaLoadingTelaChamadoAberto(dispatch);
                ativaDesativaLoadingRefreshControlChamadoAberto(dispatch, false);
                enviaUltimaReferenciaKey(dispatch, referenceToOldestKey);
                if (chamados.length == 7) {
                  ativaDesativaLoadingFinalFlatListChamadoAberto(dispatch, true);
                } else {
                  ativaDesativaLoadingFinalFlatListChamadoAberto(dispatch, false);
                }

                //    enviaChamadoAberto(dispatch, chamados);
                //enviaUltimaReferenciaKey(dispatch, referenceToOldestKey)
              }
            })/* .catch(error => {


                }); */
        } else {
          if (referenciaUltimaKey != undefined && referenciaUltimaKey != "") {
            //   ativaDesativaLoadingFinalFlatListChamadoAberto(dispatch, true);
            firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild(orderByChild).startAt(filtroPesquisa)
              .endAt(filtroPesquisa, referenciaUltimaKey)
              .limitToLast(8)
              .once("value").then(async (snapshot) => {
                if (snapshot.val() == null) {
                  ativaDesativaLoadingFinalFlatListChamadoAberto(dispatch, false);
                } else {
                  // changing to reverse chronological order (latest first)
                  // & removing duplicate
                  let arrayOfKeys = Object.keys(snapshot.val())
                    .sort()
                    .reverse()
                    .slice(1);
                  // transforming to array
                  let results = arrayOfKeys.map(key => snapshot.val()[key]);
                  // updating reference
                  referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];

                  let chamados = [];
                  const { responseClientes, responseAtendentes, responseSetores } = await getAllFirebaseCachedData(chaveUnica, results);

                  for (let aux = 0; aux < results.length; aux++) {
                    const cliente = responseClientes.find((cliente) => cliente.key === results[aux].cliente);
                    const atendente = responseAtendentes.find((atendente) => atendente.key === results[aux].atendente);
                    const setor = responseSetores.find((setor) => setor.key === results[aux].setor);

                    chamados.push({
                      key: arrayOfKeys[aux],
                      cliente: !cliente || !cliente.data ? "Não informado" : cliente.data.nome,
                      empresa: !cliente || !cliente.data ? "Não informado" : cliente.data.empresa,
                      setor: !setor || !setor.data ? "Não informado" : setor.data,
                      urlImagemNuvem: !cliente || !cliente.data ? null : cliente.data.urlImagemNuvem,
                      atendente: !atendente || !atendente.data ? "Não informado" : atendente.data,




                      dataHoraAbertura: results[aux].dataHoraAbertura,
                      dataVencimento: results[aux].dataVencimento,
                      status: results[aux].status,
                      prioridade: results[aux].prioridade === "" ? "Não informado" : results[aux].prioridade,
                      descricaoProblema: results[aux].descricaoProblema,
                      alterado: results[aux].alterado,
                      chaveUnica: chaveUnica,
                      keyCliente: results[aux].cliente,
                      numeroChamado: results[aux].numeroChamado,
                      channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                      telefoneCliente: (results[aux].telefoneCliente === undefined) ? 'Não informado' : results[aux].telefoneCliente,
                      chamadoAbertoPorWpp: (results[aux].chamadoAbertoPorWpp === undefined) ? false : results[aux].chamadoAbertoPorWpp,
                      abertoPorChat: (results[aux].abertoPorChat === undefined) ? "false" : results[aux].abertoPorChat,
                      lastMessageChat: results[aux].lastMessageChat == undefined ? '' : results[aux].lastMessageChat,
                      lastMessageChatTimeStamp: results[aux].lastMessageChatTimeStamp == undefined ? 0 : results[aux].lastMessageChatTimeStamp,
                      lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                      lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                      lastMessageLineColor: results[aux].lastMessageLineColor == undefined ? '#39a3f4' : results[aux].lastMessageLineColor,
                      lastmessageChatTypeProblem: (results[aux].lastmessageChatTypeProblem === undefined) ? "mensagem" : results[aux].lastmessageChatTypeProblem,
                    });
                  }
                  // ativaDesativaLoadingFinalFlatListChamadoAberto(dispatch, false);
                  enviaChamadoAberto(dispatch, chamadosAnteriores.concat(chamados));
                  enviaUltimaReferenciaKey(dispatch, referenceToOldestKey);
                }
              }).catch(error => {
                /*  */
              });
          } else {
            ativaDesativaLoadingFinalFlatListChamadoAberto(dispatch, false);
          }
        }
      })
    });

  };
};
export const listaChamadosAbertoComFiltroVencimento = (
  valor,
  referenciaUltimaKey,
  chamadosAnteriores,
  destino,
  atendenteVeApenasProprioChamado,
  contaPrincipal,
  filtroChamado2,
  status,
  filtro2
) => {
  return dispatch => {
    let referenceToOldestKey;

    AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");
      let filtroChamado = filtroChamado2.toLowerCase();
      let filtro, dataInicialSearch, dataFinalSearch;
      let dataAtual = retornaDataAtual();
      let datadeOntem = moment().subtract(1, 'days').toDate(); // pego data atual
      datadeOntem = moment(datadeOntem).format('DD/MM/YYYY');
      if (filtro2 == 'Ontem') {
        /*
           */
        dataInicialSearch = moment(datadeOntem, 'DD/MM/YYYY').unix();
        dataFinalSearch = moment(datadeOntem, 'DD/MM/YYYY').unix();
        /*

   */
      } else if (filtro2 == 'Hoje') {
        dataInicialSearch = dataAtual;
        dataFinalSearch = dataAtual;
        /*
          */
        dataInicialSearch = moment(dataInicialSearch, 'DD/MM/YYYY').unix();
        dataFinalSearch = moment(dataFinalSearch, 'DD/MM/YYYY').unix();
        /*
           */
      } else if (filtro2 == 'Amanhã') {
        dataInicialSearch = moment(dataAtual, 'DD/MM/YYYY').add(1, 'days').format('DD/MM/YYYY');
        dataFinalSearch = moment(dataAtual, 'DD/MM/YYYY').add(1, 'days').format('DD/MM/YYYY');
        /*
         */
        dataInicialSearch = moment(dataInicialSearch, 'DD/MM/YYYY').unix();
        dataFinalSearch = moment(dataFinalSearch, 'DD/MM/YYYY').unix();
        /*
         */

      } else if (filtro2 == 'Semana Atual') {
        const comecoDaSemana = moment().startOf('week').toDate();
        const fimDaSemana = moment().endOf('week').toDate();
        dataInicialSearch = moment(comecoDaSemana).format('DD/MM/YYYY');
        dataFinalSearch = moment(fimDaSemana).format('DD/MM/YYYY');
        /*
         */
        dataInicialSearch = moment(dataInicialSearch, 'DD/MM/YYYY').unix();
        dataFinalSearch = moment(dataFinalSearch, 'DD/MM/YYYY').unix();
        /*
          */
      } else if (filtro2 == 'Semana Passada') {
        let comecoSemanaPassada = moment().weekday(-7).toDate();
        let fimDaSemanaPassada = moment().weekday(-7).add(6, 'day').toDate();
        dataInicialSearch = moment(comecoSemanaPassada).format('DD/MM/YYYY');
        dataFinalSearch = moment(fimDaSemanaPassada).format('DD/MM/YYYY');
        /*
          */
        dataInicialSearch = moment(dataInicialSearch, 'DD/MM/YYYY').unix();
        dataFinalSearch = moment(dataFinalSearch, 'DD/MM/YYYY').unix();
        /*
         */
      }
      else if (filtro2 == 'Mês Atual') {
        /*   */
        const comecoDoMesAtual = moment().startOf('month').format('DD/MM/YYYY');
        const fimDoMesAtual = moment().endOf('month').format('DD/MM/YYYY');
        dataInicialSearch = comecoDoMesAtual;
        dataFinalSearch = fimDoMesAtual;
        /*
         */
        dataInicialSearch = moment(dataInicialSearch, 'DD/MM/YYYY').unix();
        dataFinalSearch = moment(dataFinalSearch, 'DD/MM/YYYY').unix();
        /*
         */
      }
      else if (filtro2 == 'Mês Passado') {
        //
        const comecoDoMesPassado = moment().subtract(1, 'months').startOf('month').format('DD/MM/YYYY');
        const fimDoMesPassado = moment().subtract(1, 'months').endOf('month').format('DD/MM/YYYY');
        dataInicialSearch = comecoDoMesPassado;
        dataFinalSearch = fimDoMesPassado;
        /*
         */
        dataInicialSearch = moment(dataInicialSearch, 'DD/MM/YYYY').unix();
        dataFinalSearch = moment(dataFinalSearch, 'DD/MM/YYYY').unix();
        /*
          */
      }
      else {

        /*    */
      }
      /*

       */
      //se a opcao do atendente ve apenas proprio chamado estiver marcado e agrupar o atendente no setor nao estiver marcado
      //com isso no app cliente nao vai ter setor para ser marcado e o setor vem como nao informado
      //listo apenas os chamados para este atendente
      if (atendenteVeApenasProprioChamado == true && contaPrincipal == false) {
        //aqui o atendente vê somente os proprios chamados
        const { currentUser } = firebase.auth();
        firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild('uid').equalTo(currentUser.uid).once("value").then((snapshot) => {
          let key = Object.keys(snapshot.val()).toString();
          /*
            */

          // if initial fetch
          ativaLoadingTelaAberturaChamado(dispatch);
          ativaDesativaLoadingRefreshControlChamadoAberto(dispatch, true);
          firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild(`status_atendente_dataVencimento/Aberto/${key}`).startAt(dataInicialSearch)
            .endAt(dataFinalSearch).once("value").then(async (snapshot) => {
              if (snapshot.val() == null) {
                enviaChamadoAberto(dispatch, []);
                desativaLoadingTelaChamadoAberto(dispatch);
                ativaDesativaLoadingRefreshControlChamadoAberto(
                  dispatch,
                  false
                );
              } else {
                // changing to reverse chronological order (latest first)
                let arrayOfKeys = Object.keys(snapshot.val()).sort().reverse();

                // transforming to array
                let results = arrayOfKeys.map(key => snapshot.val()[key]);
                // storing reference
                referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
                let chamados = [];
                const { responseClientes, responseAtendentes, responseSetores } = await getAllFirebaseCachedData(chaveUnica, results);

                for (let aux = 0; aux < results.length; aux++) {
                  const cliente = responseClientes.find((cliente) => cliente.key === results[aux].cliente);
                  const atendente = responseAtendentes.find((atendente) => atendente.key === results[aux].atendente);
                  const setor = responseSetores.find((setor) => setor.key === results[aux].setor);

                  chamados.push({
                    key: arrayOfKeys[aux],
                    cliente: !cliente || !cliente.data ? "Não informado" : cliente.data.nome,
                    empresa: !cliente || !cliente.data ? "Não informado" : cliente.data.empresa,
                    setor: !setor || !setor.data ? "Não informado" : setor.data,
                    urlImagemNuvem: !cliente || !cliente.data ? null : cliente.data.urlImagemNuvem,
                    atendente: !atendente || !atendente.data ? "Não informado" : atendente.data,




                    dataHoraAbertura: results[aux].dataHoraAbertura,
                    dataVencimento: results[aux].dataVencimento,
                    status: results[aux].status,
                    prioridade: results[aux].prioridade === "" ? "Não informado" : results[aux].prioridade,
                    descricaoProblema: results[aux].descricaoProblema,
                    alterado: results[aux].alterado,
                    chaveUnica: chaveUnica,
                    keyCliente: results[aux].cliente,
                    numeroChamado: results[aux].numeroChamado,
                    channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                    telefoneCliente: (results[aux].telefoneCliente === undefined) ? 'Não informado' : results[aux].telefoneCliente,
                    chamadoAbertoPorWpp: (results[aux].chamadoAbertoPorWpp === undefined) ? false : results[aux].chamadoAbertoPorWpp,
                    abertoPorChat: (results[aux].abertoPorChat === undefined) ? "false" : results[aux].abertoPorChat,
                    lastMessageChat: results[aux].lastMessageChat == undefined ? '' : results[aux].lastMessageChat,
                    lastMessageChatTimeStamp: results[aux].lastMessageChatTimeStamp == undefined ? 0 : results[aux].lastMessageChatTimeStamp,
                    lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                    lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                    lastMessageLineColor: results[aux].lastMessageLineColor == undefined ? '#39a3f4' : results[aux].lastMessageLineColor,
                    lastmessageChatTypeProblem: (results[aux].lastmessageChatTypeProblem === undefined) ? "mensagem" : results[aux].lastmessageChatTypeProblem,
                  });
                }
                enviaChamadoAberto(dispatch, chamados);
                desativaLoadingTelaChamadoAberto(dispatch);
                ativaDesativaLoadingRefreshControlChamadoAberto(dispatch, false);
                enviaUltimaReferenciaKey(dispatch, referenceToOldestKey);
                ativaDesativaLoadingFinalFlatListChamadoAberto(dispatch, false);
                /*

                   */

                //    enviaChamadoAberto(dispatch, chamados);
                //enviaUltimaReferenciaKey(dispatch, referenceToOldestKey)
              }
            })/* .catch(error => {


                }); */

        })
      }
      //chamado padrao
      else {

        // if initial fetch
        ativaLoadingTelaAberturaChamado(dispatch);
        ativaDesativaLoadingRefreshControlChamadoAberto(dispatch, true);
        /*    */
        firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild('status_dataVencimento/Aberto/').startAt(dataInicialSearch)
          .endAt(dataFinalSearch).once("value").then(async (snapshot) => {
            if (snapshot.val() == null) {
              enviaChamadoAberto(dispatch, []);
              desativaLoadingTelaChamadoAberto(dispatch);
              ativaDesativaLoadingRefreshControlChamadoAberto(
                dispatch,
                false
              );
            } else {
              // changing to reverse chronological order (latest first)
              let arrayOfKeys = Object.keys(snapshot.val()).sort().reverse();

              // transforming to array
              let results = arrayOfKeys.map(key => snapshot.val()[key]);
              // storing reference
              referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
              let chamados = [];
              const { responseClientes, responseAtendentes, responseSetores } = await getAllFirebaseCachedData(chaveUnica, results);

              for (let aux = 0; aux < results.length; aux++) {
                const cliente = responseClientes.find((cliente) => cliente.key === results[aux].cliente);
                const atendente = responseAtendentes.find((atendente) => atendente.key === results[aux].atendente);
                const setor = responseSetores.find((setor) => setor.key === results[aux].setor);

                chamados.push({
                  key: arrayOfKeys[aux],
                  cliente: !cliente || !cliente.data ? "Não informado" : cliente.data.nome,
                  empresa: !cliente || !cliente.data ? "Não informado" : cliente.data.empresa,
                  setor: !setor || !setor.data ? "Não informado" : setor.data,
                  urlImagemNuvem: !cliente || !cliente.data ? null : cliente.data.urlImagemNuvem,
                  atendente: !atendente || !atendente.data ? "Não informado" : atendente.data,




                  dataHoraAbertura: results[aux].dataHoraAbertura,
                  dataVencimento: results[aux].dataVencimento,
                  status: results[aux].status,
                  prioridade: results[aux].prioridade === "" ? "Não informado" : results[aux].prioridade,
                  descricaoProblema: results[aux].descricaoProblema,
                  alterado: results[aux].alterado,
                  chaveUnica: chaveUnica,
                  keyCliente: results[aux].cliente,
                  numeroChamado: results[aux].numeroChamado,
                  channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                  telefoneCliente: (results[aux].telefoneCliente === undefined) ? 'Não informado' : results[aux].telefoneCliente,
                  chamadoAbertoPorWpp: (results[aux].chamadoAbertoPorWpp === undefined) ? false : results[aux].chamadoAbertoPorWpp,
                  abertoPorChat: (results[aux].abertoPorChat === undefined) ? "false" : results[aux].abertoPorChat,
                  lastMessageChat: results[aux].lastMessageChat == undefined ? '' : results[aux].lastMessageChat,
                  lastMessageChatTimeStamp: results[aux].lastMessageChatTimeStamp == undefined ? 0 : results[aux].lastMessageChatTimeStamp,
                  lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                  lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                  lastMessageLineColor: results[aux].lastMessageLineColor == undefined ? '#39a3f4' : results[aux].lastMessageLineColor,
                  lastmessageChatTypeProblem: (results[aux].lastmessageChatTypeProblem === undefined) ? "mensagem" : results[aux].lastmessageChatTypeProblem,
                });
              }
              enviaChamadoAberto(dispatch, chamados);
              desativaLoadingTelaChamadoAberto(dispatch);
              ativaDesativaLoadingRefreshControlChamadoAberto(dispatch, false);
              ativaDesativaLoadingFinalFlatListChamadoAberto(dispatch, false);
              enviaUltimaReferenciaKey(dispatch, referenceToOldestKey);

              //    enviaChamadoAberto(dispatch, chamados);
              //enviaUltimaReferenciaKey(dispatch, referenceToOldestKey)
            }
          })/* .catch(error => {


              }); */

      }
    });

  };
};
export const listaTodosOsChamados = (
  valor,
  referenciaUltimaKey,
  destino,
  chamadosAnteriores,
  ultimakeyTodos,
  atendenteVeApenasProprioChamado,
  contaPrincipal
) => {
  return dispatch => {
    /* */
    let referenceToOldestKey;
    AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");

      if (atendenteVeApenasProprioChamado == true && contaPrincipal == false) {
        //aqui o atendente vê somente os proprios chamados
        const { currentUser } = firebase.auth();
        firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild('uid').equalTo(currentUser.uid).once("value").then((snapshot) => {
          let key = Object.keys(snapshot.val()).toString();
          const filtro = key;
          if (valor == 0) {
            // if initial fetch
            ativaDesativaLoadingTelaChamadoTodos(dispatch, true);
            firebase
              .database()
              .ref(`${chaveUnica}/chamados/`)
              .orderByChild('atendente').equalTo(filtro)
              .limitToLast(7)
              .on("value", async snapshot => {

                if (snapshot.val() == null) {
                  enviaChamadoTodos(dispatch, []);
                  ativaDesativaLoadingTelaChamadoTodos(dispatch, false);
                } else {
                  // changing to reverse chronological order (latest first)
                  let arrayOfKeys = Object.keys(snapshot.val())
                    .sort()
                    .reverse();
                  // transforming to array
                  let results = arrayOfKeys.map(key => snapshot.val()[key]);
                  // storing reference
                  referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];

                  let chamados = [];
                  const { responseClientes, responseAtendentes, responseSetores } = await getAllFirebaseCachedData(chaveUnica, results);

                  for (let aux = 0; aux < results.length; aux++) {
                    const cliente = responseClientes.find((cliente) => cliente.key === results[aux].cliente);
                    const atendente = responseAtendentes.find((atendente) => atendente.key === results[aux].atendente);
                    const setor = responseSetores.find((setor) => setor.key === results[aux].setor);

                    chamados.push({
                      key: arrayOfKeys[aux],
                      cliente: !cliente || !cliente.data ? "Não informado" : cliente.data.nome,
                      empresa: !cliente || !cliente.data ? "Não informado" : cliente.data.empresa,
                      setor: !setor || !setor.data ? "Não informado" : setor.data,
                      urlImagemNuvem: !cliente || !cliente.data ? null : cliente.data.urlImagemNuvem,
                      atendente: !atendente || !atendente.data ? "Não informado" : atendente.data,



                      dataHoraAbertura: results[aux].dataHoraAbertura,
                      dataVencimento: results[aux].dataVencimento,
                      status: results[aux].status,
                      prioridade:
                        results[aux].prioridade === ""
                          ? "Não informado"
                          : results[aux].prioridade,
                      descricaoProblema: results[aux].descricaoProblema,
                      alterado: results[aux].alterado,
                      chaveUnica: chaveUnica,
                      keyCliente: results[aux].cliente,
                      numeroChamado: results[aux].numeroChamado,
                      dataHoraFechamento: results[aux].dataHoraFechamento,
                      dataHoraCancelamento: results[aux].dataHoraCancelamento,
                      channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                      telefoneCliente: (results[aux].telefoneCliente === undefined) ? 'Não informado' : results[aux].telefoneCliente,
                      chamadoAbertoPorWpp: (results[aux].chamadoAbertoPorWpp === undefined) ? false : results[aux].chamadoAbertoPorWpp,
                      abertoPorChat: (results[aux].abertoPorChat === undefined) ? "false" : results[aux].abertoPorChat,
                      lastMessageChat: results[aux].lastMessageChat == undefined ? '' : results[aux].lastMessageChat,
                      lastMessageChatTimeStamp: results[aux].lastMessageChatTimeStamp == undefined ? 0 : results[aux].lastMessageChatTimeStamp,
                      lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                      lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                      lastMessageLineColor: results[aux].lastMessageLineColor == undefined ? '#39a3f4' : results[aux].lastMessageLineColor,
                      lastmessageChatTypeProblem: (results[aux].lastmessageChatTypeProblem === undefined) ? "mensagem" : results[aux].lastmessageChatTypeProblem,
                    });
                  }
                  ativaDesativaLoadingTelaChamadoTodos(dispatch, false);
                  enviaUltimaReferenciaKeyTodos(
                    dispatch,
                    referenceToOldestKey
                  );
                  enviaChamadoTodos(dispatch, chamados);
                  ativaDesativaLoadingFinalFlatListChamadoTodos(dispatch, true);
                }
              })

            /*   .catch(error => {

              }); */
          } else {
            if (ultimakeyTodos != undefined && ultimakeyTodos != "") {
              /*  ativaDesativaLoadingFinalFlatListChamadoTodos(
                 dispatch,
                 true
               ); */
              firebase
                .database()
                .ref(`${chaveUnica}/chamados/`)
                .orderByChild('atendente')
                .startAt(filtro)
                .endAt(filtro, ultimakeyTodos)
                .limitToLast(8)
                .once("value").then(async snapshot => {
                  if (snapshot.val() == null) {
                    ativaDesativaLoadingFinalFlatListChamadoTodos(
                      dispatch,
                      false
                    );
                  } else {

                    // changing to reverse chronological order (latest first)
                    let arrayOfKeys = Object.keys(snapshot.val())
                      .sort()
                      .reverse()
                      .slice(1);
                    // transforming to array
                    let results = arrayOfKeys.map(key => snapshot.val()[key]);
                    // storing reference
                    referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];

                    let chamados = [];
                    const { responseClientes, responseAtendentes, responseSetores } = await getAllFirebaseCachedData(chaveUnica, results);

                    for (let aux = 0; aux < results.length; aux++) {
                      const cliente = responseClientes.find((cliente) => cliente.key === results[aux].cliente);
                      const atendente = responseAtendentes.find((atendente) => atendente.key === results[aux].atendente);
                      const setor = responseSetores.find((setor) => setor.key === results[aux].setor);

                      chamados.push({
                        key: arrayOfKeys[aux],
                        cliente: !cliente || !cliente.data ? "Não informado" : cliente.data.nome,
                        empresa: !cliente || !cliente.data ? "Não informado" : cliente.data.empresa,
                        setor: !setor || !setor.data ? "Não informado" : setor.data,
                        urlImagemNuvem: !cliente || !cliente.data ? null : cliente.data.urlImagemNuvem,
                        atendente: !atendente || !atendente.data ? "Não informado" : atendente.data,



                        dataHoraAbertura: results[aux].dataHoraAbertura,
                        dataVencimento: results[aux].dataVencimento,
                        status: results[aux].status,
                        prioridade:
                          results[aux].prioridade === ""
                            ? "Não informado"
                            : results[aux].prioridade,
                        descricaoProblema: results[aux].descricaoProblema,
                        alterado: results[aux].alterado,
                        chaveUnica: chaveUnica,
                        keyCliente: results[aux].cliente,
                        numeroChamado: results[aux].numeroChamado,
                        dataHoraFechamento: results[aux].dataHoraFechamento,
                        channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                        dataHoraCancelamento: results[aux].dataHoraCancelamento,
                        telefoneCliente: (results[aux].telefoneCliente === undefined) ? 'Não informado' : results[aux].telefoneCliente,
                        chamadoAbertoPorWpp: (results[aux].chamadoAbertoPorWpp === undefined) ? false : results[aux].chamadoAbertoPorWpp,
                        abertoPorChat: (results[aux].abertoPorChat === undefined) ? "false" : results[aux].abertoPorChat,
                        lastMessageChat: results[aux].lastMessageChat == undefined ? '' : results[aux].lastMessageChat,
                        lastMessageChatTimeStamp: results[aux].lastMessageChatTimeStamp == undefined ? 0 : results[aux].lastMessageChatTimeStamp,
                        lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                        lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                        lastMessageLineColor: results[aux].lastMessageLineColor == undefined ? '#39a3f4' : results[aux].lastMessageLineColor,
                        lastmessageChatTypeProblem: (results[aux].lastmessageChatTypeProblem === undefined) ? "mensagem" : results[aux].lastmessageChatTypeProblem,
                      });
                    }
                    /* ativaDesativaLoadingFinalFlatListChamadoTodos(
                      dispatch,
                      false
                    ); */
                    enviaUltimaReferenciaKeyTodos(
                      dispatch,
                      referenceToOldestKey
                    );
                    enviaChamadoTodos(
                      dispatch,
                      chamadosAnteriores.concat(chamados)
                    );
                  }
                })

              /*  .catch(error => {

               }); */
            } else {
              ativaDesativaLoadingFinalFlatListChamadoTodos(dispatch, false);
            }
          }
        })
      } else {
        if (valor == 0) {
          ativaDesativaLoadingTelaChamadoTodos(dispatch, true);
          // if initial fetch
          firebase
            .database()
            .ref(`${chaveUnica}/chamados/`)
            .orderByKey()
            .limitToLast(7)
            .on("value", async snapshot => {

              if (snapshot.val() == null) {
                enviaChamadoTodos(dispatch, []);
                ativaDesativaLoadingTelaChamadoTodos(dispatch, false);
              } else {
                // changing to reverse chronological order (latest first)
                let arrayOfKeys = Object.keys(snapshot.val())
                  .sort()
                  .reverse();
                // transforming to array
                let results = arrayOfKeys.map(key => snapshot.val()[key]);
                // storing reference
                referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];

                let chamados = [];
                const { responseClientes, responseAtendentes, responseSetores } = await getAllFirebaseCachedData(chaveUnica, results);

                for (let aux = 0; aux < results.length; aux++) {
                  const cliente = responseClientes.find((cliente) => cliente.key === results[aux].cliente);
                  const atendente = responseAtendentes.find((atendente) => atendente.key === results[aux].atendente);
                  const setor = responseSetores.find((setor) => setor.key === results[aux].setor);

                  chamados.push({
                    key: arrayOfKeys[aux],
                    cliente: !cliente || !cliente.data ? "Não informado" : cliente.data.nome,
                    empresa: !cliente || !cliente.data ? "Não informado" : cliente.data.empresa,
                    setor: !setor || !setor.data ? "Não informado" : setor.data,
                    urlImagemNuvem: !cliente || !cliente.data ? null : cliente.data.urlImagemNuvem,
                    atendente: !atendente || !atendente.data ? "Não informado" : atendente.data,



                    dataHoraAbertura: results[aux].dataHoraAbertura,
                    dataVencimento: results[aux].dataVencimento,
                    status: results[aux].status,
                    prioridade:
                      results[aux].prioridade === ""
                        ? "Não informado"
                        : results[aux].prioridade,
                    descricaoProblema: results[aux].descricaoProblema,
                    alterado: results[aux].alterado,
                    chaveUnica: chaveUnica,
                    keyCliente: results[aux].cliente,
                    numeroChamado: results[aux].numeroChamado,
                    dataHoraFechamento: results[aux].dataHoraFechamento,
                    dataHoraCancelamento: results[aux].dataHoraCancelamento,
                    channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                    telefoneCliente: (results[aux].telefoneCliente === undefined) ? 'Não informado' : results[aux].telefoneCliente,
                    chamadoAbertoPorWpp: (results[aux].chamadoAbertoPorWpp === undefined) ? false : results[aux].chamadoAbertoPorWpp,
                    abertoPorChat: (results[aux].abertoPorChat === undefined) ? "false" : results[aux].abertoPorChat,
                    lastMessageChat: results[aux].lastMessageChat == undefined ? '' : results[aux].lastMessageChat,
                    lastMessageChatTimeStamp: results[aux].lastMessageChatTimeStamp == undefined ? 0 : results[aux].lastMessageChatTimeStamp,
                    lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                    lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                    lastMessageLineColor: results[aux].lastMessageLineColor == undefined ? '#39a3f4' : results[aux].lastMessageLineColor,
                    lastmessageChatTypeProblem: (results[aux].lastmessageChatTypeProblem === undefined) ? "mensagem" : results[aux].lastmessageChatTypeProblem,
                  });
                }
                ativaDesativaLoadingTelaChamadoTodos(dispatch, false);
                enviaUltimaReferenciaKeyTodos(
                  dispatch,
                  referenceToOldestKey
                );
                enviaChamadoTodos(dispatch, chamados);
                ativaDesativaLoadingFinalFlatListChamadoTodos(dispatch, true);
              }
            })

          /*    .catch(error => {

             }); */
        } else {
          if (ultimakeyTodos != undefined && ultimakeyTodos != "") {
            //  ativaDesativaLoadingFinalFlatListChamadoTodos(dispatch,true);
            firebase
              .database()
              .ref(`${chaveUnica}/chamados/`)
              .orderByKey()
              .endAt(ultimakeyTodos)
              .limitToLast(8)
              .once("value").then(async snapshot => {
                if (snapshot.val() == null) {
                  ativaDesativaLoadingFinalFlatListChamadoTodos(
                    dispatch,
                    false
                  );
                } else {

                  // changing to reverse chronological order (latest first)
                  let arrayOfKeys = Object.keys(snapshot.val())
                    .sort()
                    .reverse()
                    .slice(1);
                  // transforming to array
                  let results = arrayOfKeys.map(key => snapshot.val()[key]);
                  // storing reference
                  referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];

                  let chamados = [];
                  const { responseClientes, responseAtendentes, responseSetores } = await getAllFirebaseCachedData(chaveUnica, results);

                  for (let aux = 0; aux < results.length; aux++) {
                    const cliente = responseClientes.find((cliente) => cliente.key === results[aux].cliente);
                    const atendente = responseAtendentes.find((atendente) => atendente.key === results[aux].atendente);
                    const setor = responseSetores.find((setor) => setor.key === results[aux].setor);

                    chamados.push({
                      key: arrayOfKeys[aux],
                      cliente: !cliente || !cliente.data ? "Não informado" : cliente.data.nome,
                      empresa: !cliente || !cliente.data ? "Não informado" : cliente.data.empresa,
                      setor: !setor || !setor.data ? "Não informado" : setor.data,
                      urlImagemNuvem: !cliente || !cliente.data ? null : cliente.data.urlImagemNuvem,
                      atendente: !atendente || !atendente.data ? "Não informado" : atendente.data,



                      dataHoraAbertura: results[aux].dataHoraAbertura,
                      dataVencimento: results[aux].dataVencimento,
                      status: results[aux].status,
                      prioridade:
                        results[aux].prioridade === ""
                          ? "Não informado"
                          : results[aux].prioridade,
                      descricaoProblema: results[aux].descricaoProblema,
                      alterado: results[aux].alterado,
                      chaveUnica: chaveUnica,
                      keyCliente: results[aux].cliente,
                      numeroChamado: results[aux].numeroChamado,
                      dataHoraFechamento: results[aux].dataHoraFechamento,
                      channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                      telefoneCliente: (results[aux].telefoneCliente === undefined) ? 'Não informado' : results[aux].telefoneCliente,
                      chamadoAbertoPorWpp: (results[aux].chamadoAbertoPorWpp === undefined) ? false : results[aux].chamadoAbertoPorWpp,
                      abertoPorChat: (results[aux].abertoPorChat === undefined) ? "false" : results[aux].abertoPorChat,
                      lastMessageChat: results[aux].lastMessageChat == undefined ? '' : results[aux].lastMessageChat,
                      lastMessageChatTimeStamp: results[aux].lastMessageChatTimeStamp == undefined ? 0 : results[aux].lastMessageChatTimeStamp,
                      lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                      lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                      lastMessageLineColor: results[aux].lastMessageLineColor == undefined ? '#39a3f4' : results[aux].lastMessageLineColor,
                      lastmessageChatTypeProblem: (results[aux].lastmessageChatTypeProblem === undefined) ? "mensagem" : results[aux].lastmessageChatTypeProblem,
                      dataHoraCancelamento:
                        results[aux].dataHoraCancelamento
                    });
                  }
                  /*   ativaDesativaLoadingFinalFlatListChamadoTodos(
                      dispatch,
                      false
                    ); */
                  enviaUltimaReferenciaKeyTodos(
                    dispatch,
                    referenceToOldestKey
                  );
                  enviaChamadoTodos(
                    dispatch,
                    chamadosAnteriores.concat(chamados)
                  );
                }
              })

            /*  .catch(error => {

             }); */
          } else {
            ativaDesativaLoadingFinalFlatListChamadoTodos(dispatch, false);
          }
        }
      }
    });

  };
};
export const listaOsUltimosChamadosCancelado = (
  valor,
  referenciaUltimaKey,
  destino,
  chamadosAnteriores,
  atendenteVeApenasProprioChamado,
  contaPrincipal,
  adminVisualizaApenasProprioSetor,
  agruparAtendenteSetor
) => {
  return dispatch => {
    let referenceToOldestKey;

    /*
    */
    AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");
      if (atendenteVeApenasProprioChamado == true && contaPrincipal == false) {
        //aqui o atendente vê somente os proprios chamados
        const { currentUser } = firebase.auth();
        firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild('uid').equalTo(currentUser.uid).once("value").then((snapshot) => {
          let key = Object.keys(snapshot.val()).toString();
          const filtro = `Cancelado${key}`;
          /*     */
          if (valor == 0) {
            // if initial fetch
            ativaDesativaLoadingTelaChamadoCancelado(dispatch, true);
            ativaDesativaLoadingRefreshControlChamadoCancelado(
              dispatch,
              true
            );
            firebase
              .database()
              .ref(`${chaveUnica}/chamados/`)
              .orderByChild("status_atendente")
              .equalTo(filtro)
              .limitToLast(7)
              .on("value", async snapshot => {

                if (snapshot.val() == null) {
                  enviaChamadoCancelado(dispatch, []);
                  ativaDesativaLoadingTelaChamadoCancelado(dispatch, false);
                  ativaDesativaLoadingRefreshControlChamadoCancelado(
                    dispatch,
                    false
                  );
                } else {
                  // changing to reverse chronological order (latest first)
                  let arrayOfKeys = Object.keys(snapshot.val())
                    .sort()
                    .reverse();
                  // transforming to array
                  let results = arrayOfKeys.map(key => snapshot.val()[key]);
                  // storing reference
                  referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];

                  let chamados = [];
                  const { responseClientes, responseAtendentes, responseSetores } = await getAllFirebaseCachedData(chaveUnica, results);

                  for (let aux = 0; aux < results.length; aux++) {
                    const cliente = responseClientes.find((cliente) => cliente.key === results[aux].cliente);
                    const atendente = responseAtendentes.find((atendente) => atendente.key === results[aux].atendente);
                    const setor = responseSetores.find((setor) => setor.key === results[aux].setor);

                    chamados.push({
                      key: arrayOfKeys[aux],
                      cliente: !cliente || !cliente.data ? "Não informado" : cliente.data.nome,
                      empresa: !cliente || !cliente.data ? "Não informado" : cliente.data.empresa,
                      setor: !setor || !setor.data ? "Não informado" : setor.data,
                      urlImagemNuvem: !cliente || !cliente.data ? null : cliente.data.urlImagemNuvem,
                      atendente: !atendente || !atendente.data ? "Não informado" : atendente.data,



                      dataHoraAbertura: results[aux].dataHoraAbertura,
                      dataVencimento: results[aux].dataVencimento,
                      status: results[aux].status,
                      prioridade:
                        results[aux].prioridade === ""
                          ? "Não informado"
                          : results[aux].prioridade,
                      descricaoProblema: results[aux].descricaoProblema,
                      alterado: results[aux].alterado,
                      chaveUnica: chaveUnica,
                      keyCliente: results[aux].cliente,
                      numeroChamado: results[aux].numeroChamado,
                      dataHoraCancelamento: results[aux].dataHoraCancelamento,
                      channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                      telefoneCliente: (results[aux].telefoneCliente === undefined) ? 'Não informado' : results[aux].telefoneCliente,
                      chamadoAbertoPorWpp: (results[aux].chamadoAbertoPorWpp === undefined) ? false : results[aux].chamadoAbertoPorWpp,
                      abertoPorChat: (results[aux].abertoPorChat === undefined) ? "false" : results[aux].abertoPorChat,
                      lastMessageChat: results[aux].lastMessageChat == undefined ? '' : results[aux].lastMessageChat,
                      lastMessageChatTimeStamp: results[aux].lastMessageChatTimeStamp == undefined ? 0 : results[aux].lastMessageChatTimeStamp,
                      lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                      lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                      lastMessageLineColor: results[aux].lastMessageLineColor == undefined ? '#39a3f4' : results[aux].lastMessageLineColor,
                      lastmessageChatTypeProblem: (results[aux].lastmessageChatTypeProblem === undefined) ? "mensagem" : results[aux].lastmessageChatTypeProblem,
                    });
                  }
                  enviaChamadoCancelado(dispatch, chamados);
                  ativaDesativaLoadingTelaChamadoCancelado(dispatch, false);
                  ativaDesativaLoadingRefreshControlChamadoCancelado(
                    dispatch,
                    false
                  );
                  enviaUltimaReferenciaKeyCancelado(
                    dispatch,
                    referenceToOldestKey
                  );
                  ativaDesativaLoadingFinalFlatListChamadoCancelado(dispatch, true);
                }
              })

            /*    .catch(error => {

               }); */
          } else {
            if (referenciaUltimaKey != undefined && referenciaUltimaKey != "") {
              //  ativaDesativaLoadingFinalFlatListChamadoCancelado(dispatch,true);
              firebase
                .database()
                .ref(`${chaveUnica}/chamados/`)
                .orderByChild("status_atendente")
                .startAt(filtro)
                .endAt(filtro, referenciaUltimaKey)
                .limitToLast(8)
                .once("value").then(async snapshot => {
                  if (snapshot.val() == null) {
                    ativaDesativaLoadingFinalFlatListChamadoCancelado(
                      dispatch,
                      false
                    );
                  } else {

                    // changing to reverse chronological order (latest first)
                    // & removing duplicate
                    let arrayOfKeys = Object.keys(snapshot.val())
                      .sort()
                      .reverse()
                      .slice(1);
                    // transforming to array
                    let results = arrayOfKeys.map(key => snapshot.val()[key]);
                    // updating reference
                    referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
                    let chamados = [];
                    const { responseClientes, responseAtendentes, responseSetores } = await getAllFirebaseCachedData(chaveUnica, results);

                    for (let aux = 0; aux < results.length; aux++) {
                      const cliente = responseClientes.find((cliente) => cliente.key === results[aux].cliente);
                      const atendente = responseAtendentes.find((atendente) => atendente.key === results[aux].atendente);
                      const setor = responseSetores.find((setor) => setor.key === results[aux].setor);

                      chamados.push({
                        key: arrayOfKeys[aux],
                        cliente: !cliente || !cliente.data ? "Não informado" : cliente.data.nome,
                        empresa: !cliente || !cliente.data ? "Não informado" : cliente.data.empresa,
                        setor: !setor || !setor.data ? "Não informado" : setor.data,
                        urlImagemNuvem: !cliente || !cliente.data ? null : cliente.data.urlImagemNuvem,
                        atendente: !atendente || !atendente.data ? "Não informado" : atendente.data,



                        dataHoraAbertura: results[aux].dataHoraAbertura,
                        dataVencimento: results[aux].dataVencimento,
                        status: results[aux].status,
                        prioridade:
                          results[aux].prioridade === ""
                            ? "Não informado"
                            : results[aux].prioridade,
                        descricaoProblema: results[aux].descricaoProblema,
                        alterado: results[aux].alterado,
                        chaveUnica: chaveUnica,
                        keyCliente: results[aux].cliente,
                        numeroChamado: results[aux].numeroChamado,
                        dataHoraCancelamento:
                          results[aux].dataHoraCancelamento,
                        channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                        telefoneCliente: (results[aux].telefoneCliente === undefined) ? 'Não informado' : results[aux].telefoneCliente,
                        chamadoAbertoPorWpp: (results[aux].chamadoAbertoPorWpp === undefined) ? false : results[aux].chamadoAbertoPorWpp,
                        abertoPorChat: (results[aux].abertoPorChat === undefined) ? "false" : results[aux].abertoPorChat,
                        lastMessageChat: results[aux].lastMessageChat == undefined ? '' : results[aux].lastMessageChat,
                        lastMessageChatTimeStamp: results[aux].lastMessageChatTimeStamp == undefined ? 0 : results[aux].lastMessageChatTimeStamp,
                        lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                        lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                        lastMessageLineColor: results[aux].lastMessageLineColor == undefined ? '#39a3f4' : results[aux].lastMessageLineColor,
                        lastmessageChatTypeProblem: (results[aux].lastmessageChatTypeProblem === undefined) ? "mensagem" : results[aux].lastmessageChatTypeProblem,
                      });
                    }
                    /*    ativaDesativaLoadingFinalFlatListChamadoCancelado(
                         dispatch,
                         false
                       ); */
                    enviaChamadoCancelado(
                      dispatch,
                      chamadosAnteriores.concat(chamados)
                    );
                    enviaUltimaReferenciaKeyCancelado(
                      dispatch,
                      referenceToOldestKey
                    );
                  }
                })

              /*  .catch(error => {

               }); */
            } else {
              ativaDesativaLoadingFinalFlatListChamadoCancelado(dispatch, false);
            }
          }
        })
      }
      else if (atendenteVeApenasProprioChamado == true && agruparAtendenteSetor == true && contaPrincipal == true && adminVisualizaApenasProprioSetor == true) {

        //aqui o atendente vê somente os proprios chamados
        const { currentUser } = firebase.auth();
        firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild('uid').equalTo(currentUser.uid).once("value").then((snapshot) => {
          let key = Object.keys(snapshot.val()).toString();
          let codigoInterno;
          snapshot.forEach(childItem => {
            codigoInterno = childItem.val().codigoInterno;
          })


          /*     */
          if (valor == 0) {
            // if initial fetch
            ativaDesativaLoadingTelaChamadoCancelado(dispatch, true);
            ativaDesativaLoadingRefreshControlChamadoCancelado(
              dispatch,
              true
            );
            firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild(`usuariosVisualizaAtend/dadosAtendVinc/${codigoInterno}status`)
              .equalTo(`${codigoInterno}Cancelado`).limitToLast(7).on("value", async snapshot => {


                if (snapshot.val() == null) {
                  enviaChamadoCancelado(dispatch, []);
                  ativaDesativaLoadingTelaChamadoCancelado(dispatch, false);
                  ativaDesativaLoadingRefreshControlChamadoCancelado(
                    dispatch,
                    false
                  );
                } else {
                  // changing to reverse chronological order (latest first)
                  let arrayOfKeys = Object.keys(snapshot.val())
                    .sort()
                    .reverse();
                  // transforming to array
                  let results = arrayOfKeys.map(key => snapshot.val()[key]);
                  // storing reference
                  referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];

                  let chamados = [];
                  const { responseClientes, responseAtendentes, responseSetores } = await getAllFirebaseCachedData(chaveUnica, results);

                  for (let aux = 0; aux < results.length; aux++) {
                    const cliente = responseClientes.find((cliente) => cliente.key === results[aux].cliente);
                    const atendente = responseAtendentes.find((atendente) => atendente.key === results[aux].atendente);
                    const setor = responseSetores.find((setor) => setor.key === results[aux].setor);

                    chamados.push({
                      key: arrayOfKeys[aux],
                      cliente: !cliente || !cliente.data ? "Não informado" : cliente.data.nome,
                      empresa: !cliente || !cliente.data ? "Não informado" : cliente.data.empresa,
                      setor: !setor || !setor.data ? "Não informado" : setor.data,
                      urlImagemNuvem: !cliente || !cliente.data ? null : cliente.data.urlImagemNuvem,
                      atendente: !atendente || !atendente.data ? "Não informado" : atendente.data,



                      dataHoraAbertura: results[aux].dataHoraAbertura,
                      dataVencimento: results[aux].dataVencimento,
                      status: results[aux].status,
                      prioridade:
                        results[aux].prioridade === ""
                          ? "Não informado"
                          : results[aux].prioridade,
                      descricaoProblema: results[aux].descricaoProblema,
                      alterado: results[aux].alterado,
                      chaveUnica: chaveUnica,
                      keyCliente: results[aux].cliente,
                      numeroChamado: results[aux].numeroChamado,
                      dataHoraCancelamento: results[aux].dataHoraCancelamento,
                      channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                      telefoneCliente: (results[aux].telefoneCliente === undefined) ? 'Não informado' : results[aux].telefoneCliente,
                      chamadoAbertoPorWpp: (results[aux].chamadoAbertoPorWpp === undefined) ? false : results[aux].chamadoAbertoPorWpp,
                      abertoPorChat: (results[aux].abertoPorChat === undefined) ? "false" : results[aux].abertoPorChat,
                      lastMessageChat: results[aux].lastMessageChat == undefined ? '' : results[aux].lastMessageChat,
                      lastMessageChatTimeStamp: results[aux].lastMessageChatTimeStamp == undefined ? 0 : results[aux].lastMessageChatTimeStamp,
                      lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                      lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                      lastMessageLineColor: results[aux].lastMessageLineColor == undefined ? '#39a3f4' : results[aux].lastMessageLineColor,
                      lastmessageChatTypeProblem: (results[aux].lastmessageChatTypeProblem === undefined) ? "mensagem" : results[aux].lastmessageChatTypeProblem,
                    });
                  }
                  enviaChamadoCancelado(dispatch, chamados);
                  ativaDesativaLoadingTelaChamadoCancelado(dispatch, false);
                  ativaDesativaLoadingRefreshControlChamadoCancelado(
                    dispatch,
                    false
                  );
                  enviaUltimaReferenciaKeyCancelado(
                    dispatch,
                    referenceToOldestKey
                  );
                  ativaDesativaLoadingFinalFlatListChamadoCancelado(dispatch, true);
                }
              })

            /*    .catch(error => {
    
               }); */
          } else {
            if (referenciaUltimaKey != undefined && referenciaUltimaKey != "") {
              //  ativaDesativaLoadingFinalFlatListChamadoCancelado(dispatch,true);
              firebase
                .database()
                .ref(`${chaveUnica}/chamados/`)
                .orderByChild(`usuariosVisualizaAtend/dadosAtendVinc/${codigoInterno}status`)
                .startAt(`${codigoInterno}Cancelado`)
                .endAt(`${codigoInterno}Cancelado`, referenciaUltimaKey)
                //  .startAt('Aberto', referenciaUltimaKey)
                .limitToLast(8)
                .once("value").then(async snapshot => {
                  if (snapshot.val() == null) {
                    ativaDesativaLoadingFinalFlatListChamadoCancelado(
                      dispatch,
                      false
                    );
                  } else {

                    // changing to reverse chronological order (latest first)
                    // & removing duplicate
                    let arrayOfKeys = Object.keys(snapshot.val())
                      .sort()
                      .reverse()
                      .slice(1);
                    // transforming to array
                    let results = arrayOfKeys.map(key => snapshot.val()[key]);
                    // updating reference
                    referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
                    let chamados = [];
                    const { responseClientes, responseAtendentes, responseSetores } = await getAllFirebaseCachedData(chaveUnica, results);

                    for (let aux = 0; aux < results.length; aux++) {
                      const cliente = responseClientes.find((cliente) => cliente.key === results[aux].cliente);
                      const atendente = responseAtendentes.find((atendente) => atendente.key === results[aux].atendente);
                      const setor = responseSetores.find((setor) => setor.key === results[aux].setor);

                      chamados.push({
                        key: arrayOfKeys[aux],
                        cliente: !cliente || !cliente.data ? "Não informado" : cliente.data.nome,
                        empresa: !cliente || !cliente.data ? "Não informado" : cliente.data.empresa,
                        setor: !setor || !setor.data ? "Não informado" : setor.data,
                        urlImagemNuvem: !cliente || !cliente.data ? null : cliente.data.urlImagemNuvem,
                        atendente: !atendente || !atendente.data ? "Não informado" : atendente.data,



                        dataHoraAbertura: results[aux].dataHoraAbertura,
                        dataVencimento: results[aux].dataVencimento,
                        status: results[aux].status,
                        prioridade:
                          results[aux].prioridade === ""
                            ? "Não informado"
                            : results[aux].prioridade,
                        descricaoProblema: results[aux].descricaoProblema,
                        alterado: results[aux].alterado,
                        chaveUnica: chaveUnica,
                        keyCliente: results[aux].cliente,
                        numeroChamado: results[aux].numeroChamado,
                        dataHoraCancelamento:
                          results[aux].dataHoraCancelamento,
                        channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                        telefoneCliente: (results[aux].telefoneCliente === undefined) ? 'Não informado' : results[aux].telefoneCliente,
                        chamadoAbertoPorWpp: (results[aux].chamadoAbertoPorWpp === undefined) ? false : results[aux].chamadoAbertoPorWpp,
                        abertoPorChat: (results[aux].abertoPorChat === undefined) ? "false" : results[aux].abertoPorChat,
                        lastMessageChat: results[aux].lastMessageChat == undefined ? '' : results[aux].lastMessageChat,
                        lastMessageChatTimeStamp: results[aux].lastMessageChatTimeStamp == undefined ? 0 : results[aux].lastMessageChatTimeStamp,
                        lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                        lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                        lastMessageLineColor: results[aux].lastMessageLineColor == undefined ? '#39a3f4' : results[aux].lastMessageLineColor,
                        lastmessageChatTypeProblem: (results[aux].lastmessageChatTypeProblem === undefined) ? "mensagem" : results[aux].lastmessageChatTypeProblem,
                      });
                    }
                    /*    ativaDesativaLoadingFinalFlatListChamadoCancelado(
                         dispatch,
                         false
                       ); */
                    enviaChamadoCancelado(
                      dispatch,
                      chamadosAnteriores.concat(chamados)
                    );
                    enviaUltimaReferenciaKeyCancelado(
                      dispatch,
                      referenceToOldestKey
                    );
                  }
                })

              /*  .catch(error => {
       
               }); */
            } else {
              ativaDesativaLoadingFinalFlatListChamadoCancelado(dispatch, false);
            }
          }
        })
      }
      else {
        if (valor == 0) {
          // if initial fetch
          ativaDesativaLoadingTelaChamadoCancelado(dispatch, true);
          ativaDesativaLoadingRefreshControlChamadoCancelado(dispatch, true);
          firebase
            .database()
            .ref(`${chaveUnica}/chamados/`)
            .orderByChild("status")
            .equalTo("Cancelado")
            .limitToLast(7)
            .on("value", async snapshot => {
              /*   */
              //ativaDesativaLoadingTeste(dispatch,true);

              if (snapshot.val() == null) {
                enviaChamadoCancelado(dispatch, []);
                ativaDesativaLoadingTelaChamadoCancelado(dispatch, false);
                ativaDesativaLoadingRefreshControlChamadoCancelado(dispatch, false);
                /*    */
              } else {
                // changing to reverse chronological order (latest first)
                let arrayOfKeys = Object.keys(snapshot.val())
                  .sort()
                  .reverse();
                // transforming to array
                let results = arrayOfKeys.map(key => snapshot.val()[key]);
                // storing reference
                referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];

                let chamados = [];
                const { responseClientes, responseAtendentes, responseSetores } = await getAllFirebaseCachedData(chaveUnica, results);

                for (let aux = 0; aux < results.length; aux++) {
                  const cliente = responseClientes.find((cliente) => cliente.key === results[aux].cliente);
                  const atendente = responseAtendentes.find((atendente) => atendente.key === results[aux].atendente);
                  const setor = responseSetores.find((setor) => setor.key === results[aux].setor);

                  chamados.push({
                    key: arrayOfKeys[aux],
                    cliente: !cliente || !cliente.data ? "Não informado" : cliente.data.nome,
                    empresa: !cliente || !cliente.data ? "Não informado" : cliente.data.empresa,
                    setor: !setor || !setor.data ? "Não informado" : setor.data,
                    urlImagemNuvem: !cliente || !cliente.data ? null : cliente.data.urlImagemNuvem,
                    atendente: !atendente || !atendente.data ? "Não informado" : atendente.data,



                    dataHoraAbertura: results[aux].dataHoraAbertura,
                    dataVencimento: results[aux].dataVencimento,
                    status: results[aux].status,
                    prioridade:
                      results[aux].prioridade === ""
                        ? "Não informado"
                        : results[aux].prioridade,
                    descricaoProblema: results[aux].descricaoProblema,
                    alterado: results[aux].alterado,
                    chaveUnica: chaveUnica,
                    keyCliente: results[aux].cliente,
                    numeroChamado: results[aux].numeroChamado,
                    dataHoraCancelamento: results[aux].dataHoraCancelamento,
                    channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                    telefoneCliente: (results[aux].telefoneCliente === undefined) ? 'Não informado' : results[aux].telefoneCliente,
                    chamadoAbertoPorWpp: (results[aux].chamadoAbertoPorWpp === undefined) ? false : results[aux].chamadoAbertoPorWpp,
                    abertoPorChat: (results[aux].abertoPorChat === undefined) ? "false" : results[aux].abertoPorChat,
                    lastMessageChat: results[aux].lastMessageChat == undefined ? '' : results[aux].lastMessageChat,
                    lastMessageChatTimeStamp: results[aux].lastMessageChatTimeStamp == undefined ? 0 : results[aux].lastMessageChatTimeStamp,
                    lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                    lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                    lastMessageLineColor: results[aux].lastMessageLineColor == undefined ? '#39a3f4' : results[aux].lastMessageLineColor,
                    lastmessageChatTypeProblem: (results[aux].lastmessageChatTypeProblem === undefined) ? "mensagem" : results[aux].lastmessageChatTypeProblem,
                  });
                }
                /*    */
                enviaChamadoCancelado(dispatch, chamados);
                ativaDesativaLoadingTelaChamadoCancelado(dispatch, false);
                ativaDesativaLoadingRefreshControlChamadoCancelado(dispatch, false);

                enviaUltimaReferenciaKeyCancelado(
                  dispatch,
                  referenceToOldestKey
                );
                ativaDesativaLoadingFinalFlatListChamadoCancelado(dispatch, true);
              }
            })

          /*   .catch(error => {
       
            }); */
        } else {
          if (referenciaUltimaKey != undefined && referenciaUltimaKey != "") {
            //  ativaDesativaLoadingFinalFlatListChamadoCancelado(dispatch, true);
            firebase
              .database()
              .ref(`${chaveUnica}/chamados/`)
              .orderByChild("status")
              .startAt("Cancelado")
              .endAt("Cancelado", referenciaUltimaKey)
              .limitToLast(8)
              .once("value").then(async snapshot => {
                if (snapshot.val() == null) {
                  ativaDesativaLoadingFinalFlatListChamadoCancelado(dispatch, false);
                } else {

                  // changing to reverse chronological order (latest first)
                  // & removing duplicate
                  let arrayOfKeys = Object.keys(snapshot.val())
                    .sort()
                    .reverse()
                    .slice(1);
                  // transforming to array
                  let results = arrayOfKeys.map(key => snapshot.val()[key]);
                  // updating reference
                  referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
                  let chamados = [];
                  const { responseClientes, responseAtendentes, responseSetores } = await getAllFirebaseCachedData(chaveUnica, results);

                  for (let aux = 0; aux < results.length; aux++) {
                    const cliente = responseClientes.find((cliente) => cliente.key === results[aux].cliente);
                    const atendente = responseAtendentes.find((atendente) => atendente.key === results[aux].atendente);
                    const setor = responseSetores.find((setor) => setor.key === results[aux].setor);

                    chamados.push({
                      key: arrayOfKeys[aux],
                      cliente: !cliente || !cliente.data ? "Não informado" : cliente.data.nome,
                      empresa: !cliente || !cliente.data ? "Não informado" : cliente.data.empresa,
                      setor: !setor || !setor.data ? "Não informado" : setor.data,
                      urlImagemNuvem: !cliente || !cliente.data ? null : cliente.data.urlImagemNuvem,
                      atendente: !atendente || !atendente.data ? "Não informado" : atendente.data,



                      dataHoraAbertura: results[aux].dataHoraAbertura,
                      dataVencimento: results[aux].dataVencimento,
                      status: results[aux].status,
                      prioridade:
                        results[aux].prioridade === ""
                          ? "Não informado"
                          : results[aux].prioridade,
                      descricaoProblema: results[aux].descricaoProblema,
                      alterado: results[aux].alterado,
                      chaveUnica: chaveUnica,
                      keyCliente: results[aux].cliente,
                      numeroChamado: results[aux].numeroChamado,
                      dataHoraCancelamento:
                        results[aux].dataHoraCancelamento,
                      channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                      telefoneCliente: (results[aux].telefoneCliente === undefined) ? 'Não informado' : results[aux].telefoneCliente,
                      chamadoAbertoPorWpp: (results[aux].chamadoAbertoPorWpp === undefined) ? false : results[aux].chamadoAbertoPorWpp,
                      abertoPorChat: (results[aux].abertoPorChat === undefined) ? "false" : results[aux].abertoPorChat,
                      lastMessageChat: results[aux].lastMessageChat == undefined ? '' : results[aux].lastMessageChat,
                      lastMessageChatTimeStamp: results[aux].lastMessageChatTimeStamp == undefined ? 0 : results[aux].lastMessageChatTimeStamp,
                      lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                      lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                      lastMessageLineColor: results[aux].lastMessageLineColor == undefined ? '#39a3f4' : results[aux].lastMessageLineColor,
                      lastmessageChatTypeProblem: (results[aux].lastmessageChatTypeProblem === undefined) ? "mensagem" : results[aux].lastmessageChatTypeProblem,
                    });
                  }
                  //  ativaDesativaLoadingFinalFlatListChamadoCancelado(dispatch, false);
                  enviaChamadoCancelado(dispatch, chamadosAnteriores.concat(chamados));
                  enviaUltimaReferenciaKeyCancelado(
                    dispatch,
                    referenceToOldestKey
                  );
                }
              })

            /*  .catch(error => {
       
             }); */
          } else {
            ativaDesativaLoadingFinalFlatListChamadoCancelado(dispatch, false);
          }
        }
      }
    });

  };
};
export const listaOsUltimosChamadosFechado = (
  valor,
  referenciaUltimaKey,
  destino,
  chamadosAnteriores,
  atendenteVeApenasProprioChamado,
  contaPrincipal,
  adminVisualizaApenasProprioSetor,
  agruparAtendenteSetor
) => {
  return dispatch => {



    let referenceToOldestKey;

    //
    AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");
      if (atendenteVeApenasProprioChamado == true && contaPrincipal == false) {
        //aqui o atendente vê somente os proprios chamados
        const { currentUser } = firebase.auth();
        firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild('uid').equalTo(currentUser.uid).once("value").then((snapshot) => {
          let key = Object.keys(snapshot.val()).toString();
          const filtro = `Fechado${key}`;
          //
          if (valor == 0) {
            // if initial fetch
            //
            ativaDesativaLoadingTelaChamadoFechado(dispatch, true);
            ativaDesativaLoadingRefreshControlChamadoFechado(dispatch, true);
            firebase
              .database()
              .ref(`${chaveUnica}/chamados/`)
              .orderByChild("status_atendente")
              .equalTo(filtro)
              .limitToLast(7)
              .on("value", async snapshot => {
                if (snapshot.val() == null) {
                  enviaChamadoFechado(dispatch, []);
                  ativaDesativaLoadingTelaChamadoFechado(dispatch, false);
                  ativaDesativaLoadingRefreshControlChamadoFechado(
                    dispatch,
                    false
                  );
                  //
                } else {
                  // changing to reverse chronological order (latest first)
                  let arrayOfKeys = Object.keys(snapshot.val())
                    .sort()
                    .reverse();
                  // transforming to array
                  let results = arrayOfKeys.map(key => snapshot.val()[key]);
                  // storing reference
                  referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
                  let chamados = [];

                  const { responseClientes, responseAtendentes, responseSetores } = await getAllFirebaseCachedData(chaveUnica, results);

                  for (let aux = 0; aux < results.length; aux++) {
                    const cliente = responseClientes.find((cliente) => cliente.key === results[aux].cliente);
                    const atendente = responseAtendentes.find((atendente) => atendente.key === results[aux].atendente);
                    const setor = responseSetores.find((setor) => setor.key === results[aux].setor);

                    chamados.push({
                      key: arrayOfKeys[aux],
                      cliente: !cliente || !cliente.data ? "Não informado" : cliente.data.nome,
                      empresa: !cliente || !cliente.data ? "Não informado" : cliente.data.empresa,
                      setor: !setor || !setor.data ? "Não informado" : setor.data,
                      urlImagemNuvem: !cliente || !cliente.data ? null : cliente.data.urlImagemNuvem,
                      atendente: !atendente || !atendente.data ? "Não informado" : atendente.data,



                      dataHoraAbertura: results[aux].dataHoraAbertura,
                      dataVencimento: results[aux].dataVencimento,
                      status: results[aux].status,
                      prioridade:
                        results[aux].prioridade === ""
                          ? "Não informado"
                          : results[aux].prioridade,
                      descricaoProblema: results[aux].descricaoProblema,
                      alterado: results[aux].alterado,
                      chaveUnica: chaveUnica,
                      keyCliente: results[aux].cliente,
                      numeroChamado: results[aux].numeroChamado,
                      dataHoraFechamento: results[aux].dataHoraFechamento,
                      channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                      telefoneCliente: (results[aux].telefoneCliente === undefined) ? 'Não informado' : results[aux].telefoneCliente,
                      chamadoAbertoPorWpp: (results[aux].chamadoAbertoPorWpp === undefined) ? false : results[aux].chamadoAbertoPorWpp,
                      abertoPorChat: (results[aux].abertoPorChat === undefined) ? "false" : results[aux].abertoPorChat,
                      lastMessageChat: results[aux].lastMessageChat == undefined ? '' : results[aux].lastMessageChat,
                      lastMessageChatTimeStamp: results[aux].lastMessageChatTimeStamp == undefined ? 0 : results[aux].lastMessageChatTimeStamp,
                      lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                      lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                      lastMessageLineColor: results[aux].lastMessageLineColor == undefined ? '#39a3f4' : results[aux].lastMessageLineColor,
                      lastmessageChatTypeProblem: (results[aux].lastmessageChatTypeProblem === undefined) ? "mensagem" : results[aux].lastmessageChatTypeProblem,
                    });
                  }
                  enviaChamadoFechado(dispatch, chamados);
                  ativaDesativaLoadingTelaChamadoFechado(dispatch, false);
                  ativaDesativaLoadingRefreshControlChamadoFechado(
                    dispatch,
                    false
                  );
                  enviaUltimaReferenciaKeyFechado(
                    dispatch,
                    referenceToOldestKey
                  );
                  ativaDesativaLoadingFinalFlatListChamadoFechado(dispatch, true);
                  //
                }
              })

            /* .catch(error => {

            }); */
          } else {
            if (referenciaUltimaKey != undefined && referenciaUltimaKey != "") {
              // ativaDesativaLoadingFinalFlatListChamadoFechado(dispatch, true);
              firebase
                .database()
                .ref(`${chaveUnica}/chamados/`)
                .orderByChild("status_atendente")
                .startAt(filtro)
                .endAt(filtro, referenciaUltimaKey)
                .limitToLast(8)
                .once("value").then(async snapshot => {
                  if (snapshot.val() == null) {
                    ativaDesativaLoadingFinalFlatListChamadoFechado(dispatch, false);
                  } else {

                    // changing to reverse chronological order (latest first)
                    // & removing duplicate
                    let arrayOfKeys = Object.keys(snapshot.val())
                      .sort()
                      .reverse()
                      .slice(1);
                    // transforming to array
                    let results = arrayOfKeys.map(key => snapshot.val()[key]);
                    // updating reference
                    referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
                    let chamados = [];
                    const { responseClientes, responseAtendentes, responseSetores } = await getAllFirebaseCachedData(chaveUnica, results);

                    for (let aux = 0; aux < results.length; aux++) {
                      const cliente = responseClientes.find((cliente) => cliente.key === results[aux].cliente);
                      const atendente = responseAtendentes.find((atendente) => atendente.key === results[aux].atendente);
                      const setor = responseSetores.find((setor) => setor.key === results[aux].setor);

                      chamados.push({
                        key: arrayOfKeys[aux],
                        cliente: !cliente || !cliente.data ? "Não informado" : cliente.data.nome,
                        empresa: !cliente || !cliente.data ? "Não informado" : cliente.data.empresa,
                        setor: !setor || !setor.data ? "Não informado" : setor.data,
                        urlImagemNuvem: !cliente || !cliente.data ? null : cliente.data.urlImagemNuvem,
                        atendente: !atendente || !atendente.data ? "Não informado" : atendente.data,



                        dataHoraAbertura: results[aux].dataHoraAbertura,
                        dataVencimento: results[aux].dataVencimento,
                        status: results[aux].status,
                        prioridade:
                          results[aux].prioridade === ""
                            ? "Não informado"
                            : results[aux].prioridade,
                        descricaoProblema: results[aux].descricaoProblema,
                        alterado: results[aux].alterado,
                        chaveUnica: chaveUnica,
                        keyCliente: results[aux].cliente,
                        numeroChamado: results[aux].numeroChamado,
                        dataHoraFechamento: results[aux].dataHoraFechamento,
                        channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                        telefoneCliente: (results[aux].telefoneCliente === undefined) ? 'Não informado' : results[aux].telefoneCliente,
                        chamadoAbertoPorWpp: (results[aux].chamadoAbertoPorWpp === undefined) ? false : results[aux].chamadoAbertoPorWpp,
                        abertoPorChat: (results[aux].abertoPorChat === undefined) ? "false" : results[aux].abertoPorChat,
                        lastMessageChat: results[aux].lastMessageChat == undefined ? '' : results[aux].lastMessageChat,
                        lastMessageChatTimeStamp: results[aux].lastMessageChatTimeStamp == undefined ? 0 : results[aux].lastMessageChatTimeStamp,
                        lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                        lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                        lastMessageLineColor: results[aux].lastMessageLineColor == undefined ? '#39a3f4' : results[aux].lastMessageLineColor,
                        lastmessageChatTypeProblem: (results[aux].lastmessageChatTypeProblem === undefined) ? "mensagem" : results[aux].lastmessageChatTypeProblem,
                      });
                    }

                    enviaChamadoFechado(
                      dispatch,
                      chamadosAnteriores.concat(chamados)
                    );
                    enviaUltimaReferenciaKeyFechado(
                      dispatch,
                      referenceToOldestKey
                    );
                  }
                })

              /*  .catch(error => {

               }); */
            } else {
              ativaDesativaLoadingFinalFlatListChamadoFechado(dispatch, false);
            }
          }
        })
      }
      else if (atendenteVeApenasProprioChamado == true && agruparAtendenteSetor == true && contaPrincipal == true && adminVisualizaApenasProprioSetor == true) {

        //aqui o atendente vê somente os proprios chamados
        const { currentUser } = firebase.auth();
        firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild('uid').equalTo(currentUser.uid).once("value").then((snapshot) => {
          let key = Object.keys(snapshot.val()).toString();
          let codigoInterno;
          snapshot.forEach(childItem => {
            codigoInterno = childItem.val().codigoInterno;
          })


          //
          if (valor == 0) {
            // if initial fetch
            //
            ativaDesativaLoadingTelaChamadoFechado(dispatch, true);
            ativaDesativaLoadingRefreshControlChamadoFechado(dispatch, true);
            firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild(`usuariosVisualizaAtend/dadosAtendVinc/${codigoInterno}status`)
              .equalTo(`${codigoInterno}Fechado`).limitToLast(7).on("value", async snapshot => {
                if (snapshot.val() == null) {
                  enviaChamadoFechado(dispatch, []);
                  ativaDesativaLoadingTelaChamadoFechado(dispatch, false);
                  ativaDesativaLoadingRefreshControlChamadoFechado(
                    dispatch,
                    false
                  );
                  //
                } else {
                  // changing to reverse chronological order (latest first)
                  let arrayOfKeys = Object.keys(snapshot.val())
                    .sort()
                    .reverse();

                  let results = arrayOfKeys.map(key => snapshot.val()[key]);
                  referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
                  let chamados = [];
                  const { responseClientes, responseAtendentes, responseSetores } = await getAllFirebaseCachedData(chaveUnica, results);

                  for (let aux = 0; aux < results.length; aux++) {
                    const cliente = responseClientes.find((cliente) => cliente.key === results[aux].cliente);
                    const atendente = responseAtendentes.find((atendente) => atendente.key === results[aux].atendente);
                    const setor = responseSetores.find((setor) => setor.key === results[aux].setor);

                    chamados.push({
                      key: arrayOfKeys[aux],
                      cliente: !cliente || !cliente.data ? "Não informado" : cliente.data.nome,
                      empresa: !cliente || !cliente.data ? "Não informado" : cliente.data.empresa,
                      setor: !setor || !setor.data ? "Não informado" : setor.data,
                      urlImagemNuvem: !cliente || !cliente.data ? null : cliente.data.urlImagemNuvem,
                      atendente: !atendente || !atendente.data ? "Não informado" : atendente.data,


                      dataHoraAbertura: results[aux].dataHoraAbertura,
                      dataVencimento: results[aux].dataVencimento,
                      status: results[aux].status,
                      prioridade:
                        results[aux].prioridade === ""
                          ? "Não informado"
                          : results[aux].prioridade,
                      descricaoProblema: results[aux].descricaoProblema,
                      alterado: results[aux].alterado,
                      chaveUnica: chaveUnica,
                      keyCliente: results[aux].cliente,
                      numeroChamado: results[aux].numeroChamado,
                      dataHoraFechamento: results[aux].dataHoraFechamento,
                      channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                      telefoneCliente: (results[aux].telefoneCliente === undefined) ? 'Não informado' : results[aux].telefoneCliente,
                      chamadoAbertoPorWpp: (results[aux].chamadoAbertoPorWpp === undefined) ? false : results[aux].chamadoAbertoPorWpp,
                      abertoPorChat: (results[aux].abertoPorChat === undefined) ? "false" : results[aux].abertoPorChat,
                      lastMessageChat: results[aux].lastMessageChat == undefined ? '' : results[aux].lastMessageChat,
                      lastMessageChatTimeStamp: results[aux].lastMessageChatTimeStamp == undefined ? 0 : results[aux].lastMessageChatTimeStamp,
                      lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                      lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                      lastMessageLineColor: results[aux].lastMessageLineColor == undefined ? '#39a3f4' : results[aux].lastMessageLineColor,
                      lastmessageChatTypeProblem: (results[aux].lastmessageChatTypeProblem === undefined) ? "mensagem" : results[aux].lastmessageChatTypeProblem,
                    });
                  }
                  enviaChamadoFechado(dispatch, chamados);
                  ativaDesativaLoadingTelaChamadoFechado(dispatch, false);
                  ativaDesativaLoadingRefreshControlChamadoFechado(
                    dispatch,
                    false
                  );
                  enviaUltimaReferenciaKeyFechado(
                    dispatch,
                    referenceToOldestKey
                  );
                  ativaDesativaLoadingFinalFlatListChamadoFechado(dispatch, true);
                  //
                }
              })

            /* .catch(error => {

            }); */
          } else {
            if (referenciaUltimaKey != undefined && referenciaUltimaKey != "") {
              // ativaDesativaLoadingFinalFlatListChamadoFechado(dispatch, true);
              firebase
                .database()
                .ref(`${chaveUnica}/chamados/`)
                .orderByChild(`usuariosVisualizaAtend/dadosAtendVinc/${codigoInterno}status`)
                .startAt(`${codigoInterno}Fechado`)
                .endAt(`${codigoInterno}Fechado`, referenciaUltimaKey)
                //  .startAt('Aberto', referenciaUltimaKey)
                .limitToLast(8)
                .once("value").then(async snapshot => {
                  if (snapshot.val() == null) {
                    ativaDesativaLoadingFinalFlatListChamadoFechado(dispatch, false);
                  } else {

                    // changing to reverse chronological order (latest first)
                    // & removing duplicate
                    let arrayOfKeys = Object.keys(snapshot.val())
                      .sort()
                      .reverse()
                      .slice(1);
                    // transforming to array
                    let results = arrayOfKeys.map(key => snapshot.val()[key]);
                    // updating reference
                    referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
                    let chamados = [];
                    const { responseClientes, responseAtendentes, responseSetores } = await getAllFirebaseCachedData(chaveUnica, results);

                    for (let aux = 0; aux < results.length; aux++) {
                      const cliente = responseClientes.find((cliente) => cliente.key === results[aux].cliente);
                      const atendente = responseAtendentes.find((atendente) => atendente.key === results[aux].atendente);
                      const setor = responseSetores.find((setor) => setor.key === results[aux].setor);

                      chamados.push({
                        key: arrayOfKeys[aux],
                        cliente: !cliente || !cliente.data ? "Não informado" : cliente.data.nome,
                        empresa: !cliente || !cliente.data ? "Não informado" : cliente.data.empresa,
                        setor: !setor || !setor.data ? "Não informado" : setor.data,
                        urlImagemNuvem: !cliente || !cliente.data ? null : cliente.data.urlImagemNuvem,
                        atendente: !atendente || !atendente.data ? "Não informado" : atendente.data,


                        dataHoraAbertura: results[aux].dataHoraAbertura,
                        dataVencimento: results[aux].dataVencimento,
                        status: results[aux].status,
                        prioridade:
                          results[aux].prioridade === ""
                            ? "Não informado"
                            : results[aux].prioridade,
                        descricaoProblema: results[aux].descricaoProblema,
                        alterado: results[aux].alterado,
                        chaveUnica: chaveUnica,
                        keyCliente: results[aux].cliente,
                        numeroChamado: results[aux].numeroChamado,
                        dataHoraFechamento: results[aux].dataHoraFechamento,
                        channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                        telefoneCliente: (results[aux].telefoneCliente === undefined) ? 'Não informado' : results[aux].telefoneCliente,
                        chamadoAbertoPorWpp: (results[aux].chamadoAbertoPorWpp === undefined) ? false : results[aux].chamadoAbertoPorWpp,
                        abertoPorChat: (results[aux].abertoPorChat === undefined) ? "false" : results[aux].abertoPorChat,
                        lastMessageChat: results[aux].lastMessageChat == undefined ? '' : results[aux].lastMessageChat,
                        lastMessageChatTimeStamp: results[aux].lastMessageChatTimeStamp == undefined ? 0 : results[aux].lastMessageChatTimeStamp,
                        lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                        lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                        lastMessageLineColor: results[aux].lastMessageLineColor == undefined ? '#39a3f4' : results[aux].lastMessageLineColor,
                        lastmessageChatTypeProblem: (results[aux].lastmessageChatTypeProblem === undefined) ? "mensagem" : results[aux].lastmessageChatTypeProblem,
                      });
                    }
                    /*    ativaDesativaLoadingFinalFlatListChamadoFechado(
                         dispatch,
                         false
                       ); */
                    enviaChamadoFechado(
                      dispatch,
                      chamadosAnteriores.concat(chamados)
                    );
                    enviaUltimaReferenciaKeyFechado(
                      dispatch,
                      referenceToOldestKey
                    );
                  }
                })

              /*  .catch(error => {

               }); */
            } else {
              ativaDesativaLoadingFinalFlatListChamadoFechado(dispatch, false);
            }
          }
        })
      }
      else {
        if (valor == 0) {
          // if initial fetch

          ativaDesativaLoadingTelaChamadoFechado(dispatch, true);
          ativaDesativaLoadingRefreshControlChamadoFechado(dispatch, true);
          firebase
            .database()
            .ref(`${chaveUnica}/chamados/`)
            .orderByChild("status")
            .equalTo("Fechado")
            .limitToLast(7)
            .on("value", async snapshot => {
              if (snapshot.val() == null) {
                enviaChamadoFechado(dispatch, [

                ]);
                ativaDesativaLoadingTelaChamadoFechado(dispatch, false);
                ativaDesativaLoadingRefreshControlChamadoFechado(
                  dispatch,
                  false
                );
                //
              } else {
                let arrayOfKeys = Object.keys(snapshot.val()).sort().reverse();
                let results = arrayOfKeys.map(key => snapshot.val()[key]);
                referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
                let chamados = [];

                const { responseClientes, responseAtendentes, responseSetores } = await getAllFirebaseCachedData(chaveUnica, results);

                for (let aux = 0; aux < results.length; aux++) {
                  const cliente = responseClientes.find((cliente) => cliente.key === results[aux].cliente);
                  const atendente = responseAtendentes.find((atendente) => atendente.key === results[aux].atendente);
                  const setor = responseSetores.find((setor) => setor.key === results[aux].setor);

                  chamados.push({
                    key: arrayOfKeys[aux],
                    cliente: !cliente || !cliente.data ? "Não informado" : cliente.data.nome,
                    empresa: !cliente || !cliente.data ? "Não informado" : cliente.data.empresa,
                    setor: !setor || !setor.data ? "Não informado" : setor.data,
                    urlImagemNuvem: !cliente || !cliente.data ? null : cliente.data.urlImagemNuvem,
                    atendente: !atendente || !atendente.data ? "Não informado" : atendente.data,

                    dataHoraAbertura: results[aux].dataHoraAbertura,
                    dataVencimento: results[aux].dataVencimento,
                    status: results[aux].status,
                    prioridade:
                      results[aux].prioridade === ""
                        ? "Não informado"
                        : results[aux].prioridade,
                    descricaoProblema: results[aux].descricaoProblema,
                    alterado: results[aux].alterado,
                    chaveUnica: chaveUnica,
                    keyCliente: results[aux].cliente,
                    numeroChamado: results[aux].numeroChamado,
                    dataHoraFechamento: results[aux].dataHoraFechamento,
                    channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                    telefoneCliente: (results[aux].telefoneCliente === undefined) ? 'Não informado' : results[aux].telefoneCliente,
                    chamadoAbertoPorWpp: (results[aux].chamadoAbertoPorWpp === undefined) ? false : results[aux].chamadoAbertoPorWpp,
                    abertoPorChat: (results[aux].abertoPorChat === undefined) ? "false" : results[aux].abertoPorChat,
                    lastMessageChat: results[aux].lastMessageChat == undefined ? '' : results[aux].lastMessageChat,
                    lastMessageChatTimeStamp: results[aux].lastMessageChatTimeStamp == undefined ? 0 : results[aux].lastMessageChatTimeStamp,
                    lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                    lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                    lastMessageLineColor: results[aux].lastMessageLineColor == undefined ? '#39a3f4' : results[aux].lastMessageLineColor,
                    lastmessageChatTypeProblem: (results[aux].lastmessageChatTypeProblem === undefined) ? "mensagem" : results[aux].lastmessageChatTypeProblem,
                  });
                }
                enviaChamadoFechado(dispatch, chamados);
                ativaDesativaLoadingTelaChamadoFechado(dispatch, false);
                ativaDesativaLoadingRefreshControlChamadoFechado(
                  dispatch,
                  false
                );
                enviaUltimaReferenciaKeyFechado(
                  dispatch,
                  referenceToOldestKey
                );
                ativaDesativaLoadingFinalFlatListChamadoFechado(dispatch, true);
                //

              }
            })

          /* .catch(error => {

          }); */
        } else {
          if (referenciaUltimaKey != undefined && referenciaUltimaKey != "") {
            //  ativaDesativaLoadingFinalFlatListChamadoFechado(dispatch, true);
            firebase
              .database()
              .ref(`${chaveUnica}/chamados/`)
              .orderByChild("status")
              .startAt("Fechado")
              .endAt("Fechado", referenciaUltimaKey)
              .limitToLast(8)
              .once("value").then(async snapshot => {
                if (snapshot.val() == null) {
                  ativaDesativaLoadingFinalFlatListChamadoFechado(dispatch, false);
                } else {

                  let arrayOfKeys = Object.keys(snapshot.val())
                    .sort()
                    .reverse()
                    .slice(1);
                  let results = arrayOfKeys.map(key => snapshot.val()[key]);
                  referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
                  let chamados = [];

                  const { responseClientes, responseAtendentes, responseSetores } = await getAllFirebaseCachedData(chaveUnica, results);

                  for (let aux = 0; aux < results.length; aux++) {
                    const cliente = responseClientes.find((cliente) => cliente.key === results[aux].cliente);
                    const atendente = responseAtendentes.find((atendente) => atendente.key === results[aux].atendente);
                    const setor = responseSetores.find((setor) => setor.key === results[aux].setor);
                    chamados.push({
                      key: arrayOfKeys[aux],
                      cliente: !cliente || !cliente.data ? "Não informado" : cliente.data.nome,
                      empresa: !cliente || !cliente.data ? "Não informado" : cliente.data.empresa,
                      setor: !setor || !setor.data ? "Não informado" : setor.data,
                      urlImagemNuvem: !cliente || !cliente.data ? null : cliente.data.urlImagemNuvem,
                      atendente: !atendente || !atendente.data ? "Não informado" : atendente.data,

                      dataHoraAbertura: results[aux].dataHoraAbertura,
                      dataVencimento: results[aux].dataVencimento,
                      status: results[aux].status,
                      prioridade:
                        results[aux].prioridade === ""
                          ? "Não informado"
                          : results[aux].prioridade,
                      descricaoProblema: results[aux].descricaoProblema,
                      alterado: results[aux].alterado,
                      chaveUnica: chaveUnica,
                      keyCliente: results[aux].cliente,
                      numeroChamado: results[aux].numeroChamado,
                      dataHoraFechamento: results[aux].dataHoraFechamento,
                      channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                      telefoneCliente: (results[aux].telefoneCliente === undefined) ? 'Não informado' : results[aux].telefoneCliente,
                      chamadoAbertoPorWpp: (results[aux].chamadoAbertoPorWpp === undefined) ? false : results[aux].chamadoAbertoPorWpp,
                      abertoPorChat: (results[aux].abertoPorChat === undefined) ? "false" : results[aux].abertoPorChat,
                      lastMessageChat: results[aux].lastMessageChat == undefined ? '' : results[aux].lastMessageChat,
                      lastMessageChatTimeStamp: results[aux].lastMessageChatTimeStamp == undefined ? 0 : results[aux].lastMessageChatTimeStamp,
                      lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                      lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                      lastMessageLineColor: results[aux].lastMessageLineColor == undefined ? '#39a3f4' : results[aux].lastMessageLineColor,
                      lastmessageChatTypeProblem: (results[aux].lastmessageChatTypeProblem === undefined) ? "mensagem" : results[aux].lastmessageChatTypeProblem,
                    });
                  }

                  enviaChamadoFechado(
                    dispatch,
                    chamadosAnteriores.concat(chamados)
                  );
                  enviaUltimaReferenciaKeyFechado(
                    dispatch,
                    referenceToOldestKey
                  );
                }
              })

            /* .catch(error => {

            }); */
          } else {
            ativaDesativaLoadingFinalFlatListChamadoFechado(dispatch, false);
          }
        }
      }
    });

  };
};

export const listaOsUltimosChamadosEmAtendimento = (
  valor,
  referenciaUltimaKey,
  destino,
  chamadosAnteriores,
  atendenteVeApenasProprioChamado,
  contaPrincipal,
  adminVisualizaApenasProprioSetor,
  agruparAtendenteSetor
) => {
  return dispatch => {
    let referenceToOldestKey;

    /*

     */

    /* */
    AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");
      /*    */
      if (atendenteVeApenasProprioChamado == true && contaPrincipal == false) {
        //aqui o atendente vê somente os proprios chamados
        const { currentUser } = firebase.auth();
        firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild('uid').equalTo(currentUser.uid).once("value").then((snapshot) => {
          let key = Object.keys(snapshot.val()).toString();
          const filtro = `Atendendo${key}`;
          /*     */
          if (valor == 0) {
            // if initial fetch
            ativaDesativaLoadingTelaChamadoAtendimento(dispatch, true);
            ativaDesativaLoadingRefreshControlChamadoEmAtendimento(
              dispatch,
              true
            );

            firebase
              .database()
              .ref(`${chaveUnica}/chamados/`)
              .orderByChild("status_atendente")
              .equalTo(filtro)
              .limitToLast(7)
              .on("value", async snapshot => {
                if (snapshot.val() == null) {
                  enviaChamadoEmAtendimento(dispatch, []);
                  ativaDesativaLoadingTelaChamadoAtendimento(dispatch, false);
                  ativaDesativaLoadingRefreshControlChamadoEmAtendimento(
                    dispatch,
                    false
                  );
                  /*   */
                } else {
                  // changing to reverse chronological order (latest first)
                  let arrayOfKeys = Object.keys(snapshot.val()).sort().reverse();
                  // transforming to array
                  let results = arrayOfKeys.map(key => snapshot.val()[key]);
                  // storing reference
                  referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
                  let chamados = [];
                  const { responseClientes, responseAtendentes, responseSetores } = await getAllFirebaseCachedData(chaveUnica, results);

                  for (let aux = 0; aux < results.length; aux++) {
                    const cliente = responseClientes.find((cliente) => cliente.key === results[aux].cliente);
                    const atendente = responseAtendentes.find((atendente) => atendente.key === results[aux].atendente);
                    const setor = responseSetores.find((setor) => setor.key === results[aux].setor);

                    chamados.push({
                      key: arrayOfKeys[aux],
                      cliente: !cliente || !cliente.data ? "Não informado" : cliente.data.nome,
                      empresa: !cliente || !cliente.data ? "Não informado" : cliente.data.empresa,
                      setor: !setor || !setor.data ? "Não informado" : setor.data,
                      urlImagemNuvem: !cliente || !cliente.data ? null : cliente.data.urlImagemNuvem,
                      atendente: !atendente || !atendente.data ? "Não informado" : atendente.data,



                      dataHoraAbertura: results[aux].dataHoraAbertura,
                      dataVencimento: results[aux].dataVencimento,
                      status: results[aux].status,
                      prioridade:
                        results[aux].prioridade === ""
                          ? "Não informado"
                          : results[aux].prioridade,
                      descricaoProblema: results[aux].descricaoProblema,
                      alterado: results[aux].alterado,
                      chaveUnica: chaveUnica,
                      keyCliente: results[aux].cliente,
                      numeroChamado: results[aux].numeroChamado,
                      channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                      telefoneCliente: (results[aux].telefoneCliente === undefined) ? 'Não informado' : results[aux].telefoneCliente,
                      chamadoAbertoPorWpp: (results[aux].chamadoAbertoPorWpp === undefined) ? false : results[aux].chamadoAbertoPorWpp,
                      abertoPorChat: (results[aux].abertoPorChat === undefined) ? "false" : results[aux].abertoPorChat,
                      lastMessageChat: results[aux].lastMessageChat == undefined ? '' : results[aux].lastMessageChat,
                      lastMessageChatTimeStamp: results[aux].lastMessageChatTimeStamp == undefined ? 0 : results[aux].lastMessageChatTimeStamp,
                      lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                      lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                      lastMessageLineColor: results[aux].lastMessageLineColor == undefined ? '#39a3f4' : results[aux].lastMessageLineColor,
                      lastmessageChatTypeProblem: (results[aux].lastmessageChatTypeProblem === undefined) ? "mensagem" : results[aux].lastmessageChatTypeProblem,
                    });
                  }
                  enviaUltimaReferenciaKeyEmAtendimento(
                    dispatch,
                    referenceToOldestKey
                  );
                  enviaChamadoEmAtendimento(dispatch, chamados);
                  ativaDesativaLoadingTelaChamadoAtendimento(dispatch, false);
                  ativaDesativaLoadingRefreshControlChamadoEmAtendimento(
                    dispatch,
                    false
                  );
                  ativaDesativaLoadingFinalFlatListChamadoEmAtendimento(dispatch, true);
                  /* */
                }
              })

            /*  .catch(error => {

             }); */
          } else {
            if (referenciaUltimaKey != undefined && referenciaUltimaKey != "") {
              //   ativaDesativaLoadingFinalFlatListChamadoEmAtendimento(dispatch, true);
              firebase
                .database()
                .ref(`${chaveUnica}/chamados/`)
                .orderByChild("status_atendente")
                .startAt(filtro)
                .endAt(filtro, referenciaUltimaKey)
                .limitToLast(8)
                .on("value", async snapshot => {
                  if (snapshot.val() == null) {
                    ativaDesativaLoadingFinalFlatListChamadoEmAtendimento(dispatch, false);
                  } else {

                    // changing to reverse chronological order (latest first)
                    // & removing duplicate
                    let arrayOfKeys = Object.keys(snapshot.val())
                      .sort().reverse().slice(1);
                    // transforming to array
                    let results = arrayOfKeys.map(key => snapshot.val()[key]);
                    // updating reference
                    referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
                    let chamados = [];
                    const { responseClientes, responseAtendentes, responseSetores } = await getAllFirebaseCachedData(chaveUnica, results);

                    for (let aux = 0; aux < results.length; aux++) {
                      const cliente = responseClientes.find((cliente) => cliente.key === results[aux].cliente);
                      const atendente = responseAtendentes.find((atendente) => atendente.key === results[aux].atendente);
                      const setor = responseSetores.find((setor) => setor.key === results[aux].setor);

                      chamados.push({
                        key: arrayOfKeys[aux],
                        cliente: !cliente || !cliente.data ? "Não informado" : cliente.data.nome,
                        empresa: !cliente || !cliente.data ? "Não informado" : cliente.data.empresa,
                        setor: !setor || !setor.data ? "Não informado" : setor.data,
                        urlImagemNuvem: !cliente || !cliente.data ? null : cliente.data.urlImagemNuvem,
                        atendente: !atendente || !atendente.data ? "Não informado" : atendente.data,



                        dataHoraAbertura: results[aux].dataHoraAbertura,
                        dataVencimento: results[aux].dataVencimento,
                        status: results[aux].status,
                        prioridade:
                          results[aux].prioridade === ""
                            ? "Não informado"
                            : results[aux].prioridade,
                        descricaoProblema: results[aux].descricaoProblema,
                        alterado: results[aux].alterado,
                        chaveUnica: chaveUnica,
                        keyCliente: results[aux].cliente,
                        numeroChamado: results[aux].numeroChamado,
                        channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                        telefoneCliente: (results[aux].telefoneCliente === undefined) ? 'Não informado' : results[aux].telefoneCliente,
                        chamadoAbertoPorWpp: (results[aux].chamadoAbertoPorWpp === undefined) ? false : results[aux].chamadoAbertoPorWpp,
                        abertoPorChat: (results[aux].abertoPorChat === undefined) ? "false" : results[aux].abertoPorChat,
                        lastMessageChat: results[aux].lastMessageChat == undefined ? '' : results[aux].lastMessageChat,
                        lastMessageChatTimeStamp: results[aux].lastMessageChatTimeStamp == undefined ? 0 : results[aux].lastMessageChatTimeStamp,
                        lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                        lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                        lastMessageLineColor: results[aux].lastMessageLineColor == undefined ? '#39a3f4' : results[aux].lastMessageLineColor,
                        lastmessageChatTypeProblem: (results[aux].lastmessageChatTypeProblem === undefined) ? "mensagem" : results[aux].lastmessageChatTypeProblem,
                      });
                    }
                    /*    ativaDesativaLoadingFinalFlatListChamadoEmAtendimento(
                         dispatch,
                         false
                       ); */
                    enviaChamadoEmAtendimento(
                      dispatch,
                      chamadosAnteriores.concat(chamados)
                    );
                    enviaUltimaReferenciaKeyEmAtendimento(
                      dispatch,
                      referenceToOldestKey
                    );
                  }
                })

              /*  .catch(error => {

               }); */
            } else {
              ativaDesativaLoadingFinalFlatListChamadoEmAtendimento(dispatch, false);
            }
          }
        })
      }
      else if (atendenteVeApenasProprioChamado == true && agruparAtendenteSetor == true && contaPrincipal == true && adminVisualizaApenasProprioSetor == true) {

        const { currentUser } = firebase.auth();
        firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild('uid').equalTo(currentUser.uid).once("value").then((snapshot) => {
          let key = Object.keys(snapshot.val()).toString();
          let codigoInterno;
          snapshot.forEach(childItem => {
            codigoInterno = childItem.val().codigoInterno;
          })


          if (valor == 0) {
            // if initial fetch
            ativaDesativaLoadingTelaChamadoAtendimento(dispatch, true);
            ativaDesativaLoadingRefreshControlChamadoEmAtendimento(
              dispatch,
              true
            );

            firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild(`usuariosVisualizaAtend/dadosAtendVinc/${codigoInterno}status`)
              .equalTo(`${codigoInterno}Atendendo`).limitToLast(7).on("value", async snapshot => {
                if (snapshot.val() == null) {
                  enviaChamadoEmAtendimento(dispatch, []);
                  ativaDesativaLoadingTelaChamadoAtendimento(dispatch, false);
                  ativaDesativaLoadingRefreshControlChamadoEmAtendimento(
                    dispatch,
                    false
                  );
                  /*   */
                } else {
                  // changing to reverse chronological order (latest first)
                  let arrayOfKeys = Object.keys(snapshot.val()).sort().reverse();
                  // transforming to array
                  let results = arrayOfKeys.map(key => snapshot.val()[key]);
                  // storing reference
                  referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
                  let chamados = [];
                  const { responseClientes, responseAtendentes, responseSetores } = await getAllFirebaseCachedData(chaveUnica, results);

                  for (let aux = 0; aux < results.length; aux++) {
                    const cliente = responseClientes.find((cliente) => cliente.key === results[aux].cliente);
                    const atendente = responseAtendentes.find((atendente) => atendente.key === results[aux].atendente);
                    const setor = responseSetores.find((setor) => setor.key === results[aux].setor);

                    chamados.push({
                      key: arrayOfKeys[aux],
                      cliente: !cliente || !cliente.data ? "Não informado" : cliente.data.nome,
                      empresa: !cliente || !cliente.data ? "Não informado" : cliente.data.empresa,
                      setor: !setor || !setor.data ? "Não informado" : setor.data,
                      urlImagemNuvem: !cliente || !cliente.data ? null : cliente.data.urlImagemNuvem,
                      atendente: !atendente || !atendente.data ? "Não informado" : atendente.data,




                      dataHoraAbertura: results[aux].dataHoraAbertura,
                      dataVencimento: results[aux].dataVencimento,
                      status: results[aux].status,
                      prioridade:
                        results[aux].prioridade === ""
                          ? "Não informado"
                          : results[aux].prioridade,
                      descricaoProblema: results[aux].descricaoProblema,
                      alterado: results[aux].alterado,
                      chaveUnica: chaveUnica,
                      keyCliente: results[aux].cliente,
                      numeroChamado: results[aux].numeroChamado,
                      channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                      telefoneCliente: (results[aux].telefoneCliente === undefined) ? 'Não informado' : results[aux].telefoneCliente,
                      chamadoAbertoPorWpp: (results[aux].chamadoAbertoPorWpp === undefined) ? false : results[aux].chamadoAbertoPorWpp,
                      abertoPorChat: (results[aux].abertoPorChat === undefined) ? "false" : results[aux].abertoPorChat,
                      lastMessageChat: results[aux].lastMessageChat == undefined ? '' : results[aux].lastMessageChat,
                      lastMessageChatTimeStamp: results[aux].lastMessageChatTimeStamp == undefined ? 0 : results[aux].lastMessageChatTimeStamp,
                      lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                      lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                      lastMessageLineColor: results[aux].lastMessageLineColor == undefined ? '#39a3f4' : results[aux].lastMessageLineColor,
                      lastmessageChatTypeProblem: (results[aux].lastmessageChatTypeProblem === undefined) ? "mensagem" : results[aux].lastmessageChatTypeProblem,
                    });
                  }
                  enviaUltimaReferenciaKeyEmAtendimento(
                    dispatch,
                    referenceToOldestKey
                  );
                  enviaChamadoEmAtendimento(dispatch, chamados);
                  ativaDesativaLoadingTelaChamadoAtendimento(dispatch, false);
                  ativaDesativaLoadingRefreshControlChamadoEmAtendimento(
                    dispatch,
                    false
                  );
                  ativaDesativaLoadingFinalFlatListChamadoEmAtendimento(dispatch, true);
                  /* */
                }
              })

            /*  .catch(error => {

             }); */
          } else {
            if (referenciaUltimaKey != undefined && referenciaUltimaKey != "") {
              //   ativaDesativaLoadingFinalFlatListChamadoEmAtendimento(dispatch, true);
              firebase
                .database()
                .ref(`${chaveUnica}/chamados/`)
                .orderByChild(`usuariosVisualizaAtend/dadosAtendVinc/${codigoInterno}status`)
                .startAt(`${codigoInterno}Atendendo`)
                .endAt(`${codigoInterno}Atendendo`, referenciaUltimaKey)
                //  .startAt('Aberto', referenciaUltimaKey)
                .limitToLast(8)
                .on("value", async snapshot => {
                  if (snapshot.val() == null) {
                    ativaDesativaLoadingFinalFlatListChamadoEmAtendimento(dispatch, false);
                  } else {

                    // changing to reverse chronological order (latest first)
                    // & removing duplicate
                    let arrayOfKeys = Object.keys(snapshot.val())
                      .sort().reverse().slice(1);
                    // transforming to array
                    let results = arrayOfKeys.map(key => snapshot.val()[key]);
                    // updating reference
                    referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
                    let chamados = [];
                    const { responseClientes, responseAtendentes, responseSetores } = await getAllFirebaseCachedData(chaveUnica, results);

                    for (let aux = 0; aux < results.length; aux++) {
                      const cliente = responseClientes.find((cliente) => cliente.key === results[aux].cliente);
                      const atendente = responseAtendentes.find((atendente) => atendente.key === results[aux].atendente);
                      const setor = responseSetores.find((setor) => setor.key === results[aux].setor);

                      chamados.push({
                        key: arrayOfKeys[aux],
                        cliente: !cliente || !cliente.data ? "Não informado" : cliente.data.nome,
                        empresa: !cliente || !cliente.data ? "Não informado" : cliente.data.empresa,
                        setor: !setor || !setor.data ? "Não informado" : setor.data,
                        urlImagemNuvem: !cliente || !cliente.data ? null : cliente.data.urlImagemNuvem,
                        atendente: !atendente || !atendente.data ? "Não informado" : atendente.data,




                        dataHoraAbertura: results[aux].dataHoraAbertura,
                        dataVencimento: results[aux].dataVencimento,
                        status: results[aux].status,
                        prioridade:
                          results[aux].prioridade === ""
                            ? "Não informado"
                            : results[aux].prioridade,
                        descricaoProblema: results[aux].descricaoProblema,
                        alterado: results[aux].alterado,
                        chaveUnica: chaveUnica,
                        keyCliente: results[aux].cliente,
                        numeroChamado: results[aux].numeroChamado,
                        channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                        telefoneCliente: (results[aux].telefoneCliente === undefined) ? 'Não informado' : results[aux].telefoneCliente,
                        chamadoAbertoPorWpp: (results[aux].chamadoAbertoPorWpp === undefined) ? false : results[aux].chamadoAbertoPorWpp,
                        abertoPorChat: (results[aux].abertoPorChat === undefined) ? "false" : results[aux].abertoPorChat,
                        lastMessageChat: results[aux].lastMessageChat == undefined ? '' : results[aux].lastMessageChat,
                        lastMessageChatTimeStamp: results[aux].lastMessageChatTimeStamp == undefined ? 0 : results[aux].lastMessageChatTimeStamp,
                        lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                        lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                        lastMessageLineColor: results[aux].lastMessageLineColor == undefined ? '#39a3f4' : results[aux].lastMessageLineColor,
                        lastmessageChatTypeProblem: (results[aux].lastmessageChatTypeProblem === undefined) ? "mensagem" : results[aux].lastmessageChatTypeProblem,
                      });
                    }
                    /*    ativaDesativaLoadingFinalFlatListChamadoEmAtendimento(
                         dispatch,
                         false
                       ); */
                    enviaChamadoEmAtendimento(
                      dispatch,
                      chamadosAnteriores.concat(chamados)
                    );
                    enviaUltimaReferenciaKeyEmAtendimento(
                      dispatch,
                      referenceToOldestKey
                    );
                  }
                })

              /*  .catch(error => {

               }); */
            } else {
              ativaDesativaLoadingFinalFlatListChamadoEmAtendimento(dispatch, false);
            }
          }
        })
      }
      else {
        if (valor == 0) {
          // if initial fetch
          ativaDesativaLoadingTelaChamadoAtendimento(dispatch, true);
          ativaDesativaLoadingRefreshControlChamadoEmAtendimento(dispatch, true);

          firebase
            .database()
            .ref(`${chaveUnica}/chamados/`)
            .orderByChild("status")
            .equalTo("Atendendo")
            .limitToLast(7)
            .on("value", async snapshot => {
              if (snapshot.val() == null) {
                enviaChamadoEmAtendimento(dispatch, []);
                ativaDesativaLoadingTelaChamadoAtendimento(dispatch, false);
                ativaDesativaLoadingRefreshControlChamadoEmAtendimento(
                  dispatch,
                  false
                );
                /* */
              } else {
                let arrayOfKeys = Object.keys(snapshot.val()).sort().reverse();
                let results = arrayOfKeys.map(key => snapshot.val()[key]);
                // storing reference
                referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
                let chamados = [];
                const { responseClientes, responseAtendentes, responseSetores } = await getAllFirebaseCachedData(chaveUnica, results);

                for (let aux = 0; aux < results.length; aux++) {
                  const cliente = responseClientes.find((cliente) => cliente.key === results[aux].cliente);
                  const atendente = responseAtendentes.find((atendente) => atendente.key === results[aux].atendente);
                  const setor = responseSetores.find((setor) => setor.key === results[aux].setor);

                  chamados.push({
                    key: arrayOfKeys[aux],
                    cliente: !cliente || !cliente.data ? "Não informado" : cliente.data.nome,
                    empresa: !cliente || !cliente.data ? "Não informado" : cliente.data.empresa,
                    setor: !setor || !setor.data ? "Não informado" : setor.data,
                    urlImagemNuvem: !cliente || !cliente.data ? null : cliente.data.urlImagemNuvem,
                    atendente: !atendente || !atendente.data ? "Não informado" : atendente.data,




                    dataHoraAbertura: results[aux].dataHoraAbertura,
                    dataVencimento: results[aux].dataVencimento,
                    status: results[aux].status,
                    prioridade:
                      results[aux].prioridade === ""
                        ? "Não informado"
                        : results[aux].prioridade,
                    descricaoProblema: results[aux].descricaoProblema,
                    alterado: results[aux].alterado,
                    chaveUnica: chaveUnica,
                    keyCliente: results[aux].cliente,
                    numeroChamado: results[aux].numeroChamado,
                    channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                    telefoneCliente: (results[aux].telefoneCliente === undefined) ? 'Não informado' : results[aux].telefoneCliente,
                    chamadoAbertoPorWpp: (results[aux].chamadoAbertoPorWpp === undefined) ? false : results[aux].chamadoAbertoPorWpp,
                    abertoPorChat: (results[aux].abertoPorChat === undefined) ? "false" : results[aux].abertoPorChat,
                    lastMessageChat: results[aux].lastMessageChat == undefined ? '' : results[aux].lastMessageChat,
                    lastMessageChatTimeStamp: results[aux].lastMessageChatTimeStamp == undefined ? 0 : results[aux].lastMessageChatTimeStamp,
                    lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                    lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                    lastMessageLineColor: results[aux].lastMessageLineColor == undefined ? '#39a3f4' : results[aux].lastMessageLineColor,
                    lastmessageChatTypeProblem: (results[aux].lastmessageChatTypeProblem === undefined) ? "mensagem" : results[aux].lastmessageChatTypeProblem,
                  });
                }
                enviaUltimaReferenciaKeyEmAtendimento(
                  dispatch,
                  referenceToOldestKey
                );
                enviaChamadoEmAtendimento(dispatch, chamados);
                ativaDesativaLoadingTelaChamadoAtendimento(dispatch, false);
                ativaDesativaLoadingRefreshControlChamadoEmAtendimento(
                  dispatch,
                  false
                );
                ativaDesativaLoadingFinalFlatListChamadoEmAtendimento(dispatch, true);
                /*  */
              }
            })

          /*   .catch(error => {

            }); */
        } else {
          if (referenciaUltimaKey != undefined && referenciaUltimaKey != "") {
            //  ativaDesativaLoadingFinalFlatListChamadoEmAtendimento(dispatch, true);
            firebase
              .database()
              .ref(`${chaveUnica}/chamados/`)
              .orderByChild("status")
              .startAt("Atendendo")
              .endAt("Atendendo", referenciaUltimaKey)
              .limitToLast(8)
              .on("value", async snapshot => {
                if (snapshot.val() == null) {
                  ativaDesativaLoadingFinalFlatListChamadoEmAtendimento(dispatch, false);
                } else {

                  // changing to reverse chronological order (latest first)
                  // & removing duplicate
                  let arrayOfKeys = Object.keys(snapshot.val())
                    .sort()
                    .reverse()
                    .slice(1);
                  // transforming to array
                  let results = arrayOfKeys.map(key => snapshot.val()[key]);
                  // updating reference
                  referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
                  let chamados = [];
                  const { responseClientes, responseAtendentes, responseSetores } = await getAllFirebaseCachedData(chaveUnica, results);

                  for (let aux = 0; aux < results.length; aux++) {
                    const cliente = responseClientes.find((cliente) => cliente.key === results[aux].cliente);
                    const atendente = responseAtendentes.find((atendente) => atendente.key === results[aux].atendente);
                    const setor = responseSetores.find((setor) => setor.key === results[aux].setor);

                    chamados.push({
                      key: arrayOfKeys[aux],
                      cliente: !cliente || !cliente.data ? "Não informado" : cliente.data.nome,
                      empresa: !cliente || !cliente.data ? "Não informado" : cliente.data.empresa,
                      setor: !setor || !setor.data ? "Não informado" : setor.data,
                      urlImagemNuvem: !cliente || !cliente.data ? null : cliente.data.urlImagemNuvem,
                      atendente: !atendente || !atendente.data ? "Não informado" : atendente.data,




                      dataHoraAbertura: results[aux].dataHoraAbertura,
                      dataVencimento: results[aux].dataVencimento,
                      status: results[aux].status,
                      prioridade:
                        results[aux].prioridade === ""
                          ? "Não informado"
                          : results[aux].prioridade,
                      descricaoProblema: results[aux].descricaoProblema,
                      alterado: results[aux].alterado,
                      chaveUnica: chaveUnica,
                      keyCliente: results[aux].cliente,
                      numeroChamado: results[aux].numeroChamado,
                      channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                      telefoneCliente: (results[aux].telefoneCliente === undefined) ? 'Não informado' : results[aux].telefoneCliente,
                      chamadoAbertoPorWpp: (results[aux].chamadoAbertoPorWpp === undefined) ? false : results[aux].chamadoAbertoPorWpp,
                      abertoPorChat: (results[aux].abertoPorChat === undefined) ? "false" : results[aux].abertoPorChat,
                      lastMessageChat: results[aux].lastMessageChat == undefined ? '' : results[aux].lastMessageChat,
                      lastMessageChatTimeStamp: results[aux].lastMessageChatTimeStamp == undefined ? 0 : results[aux].lastMessageChatTimeStamp,
                      lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                      lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                      lastMessageLineColor: results[aux].lastMessageLineColor == undefined ? '#39a3f4' : results[aux].lastMessageLineColor,
                      lastmessageChatTypeProblem: (results[aux].lastmessageChatTypeProblem === undefined) ? "mensagem" : results[aux].lastmessageChatTypeProblem,
                    });
                  }
                  /*   ativaDesativaLoadingFinalFlatListChamadoEmAtendimento(
                      dispatch,
                      false
                    ); */
                  enviaChamadoEmAtendimento(
                    dispatch,
                    chamadosAnteriores.concat(chamados)
                  );
                  enviaUltimaReferenciaKeyEmAtendimento(
                    dispatch,
                    referenceToOldestKey
                  );
                }
              })

            /*  .catch(error => {

             }); */
          } else {
            ativaDesativaLoadingFinalFlatListChamadoEmAtendimento(dispatch, false);
          }
        }
      }
    });

  };
};

export const listaOsUltimosChamadosAbertos = (
  valor,
  referenciaUltimaKey,
  chamadosAnteriores,
  destino,
  atendenteVeApenasProprioChamado,
  agruparAtendenteSetor,
  contaPrincipal,
  adminVisualizaApenasProprioSetor
) => {
  return dispatch => {
    let referenceToOldestKey;

    AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");
      if (atendenteVeApenasProprioChamado == true && agruparAtendenteSetor == false && contaPrincipal == false) {
        //aqui o atendente vê somente os proprios chamados

        const { currentUser } = firebase.auth();
        firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild('uid').equalTo(currentUser.uid).once("value").then((snapshot) => {
          let key = Object.keys(snapshot.val()).toString();
          const filtro = `Aberto${key}`;
          //
          if (valor == 0) {
            // if initial fetch
            ativaLoadingTelaAberturaChamado(dispatch);
            ativaDesativaLoadingRefreshControlChamadoAberto(dispatch, true);
            firebase
              .database()
              .ref(`${chaveUnica}/chamados/`)
              .orderByChild("status_atendente")
              .equalTo(filtro)
              .limitToLast(7)
              .on("value", async snapshot => {
                if (snapshot.val() == null) {
                  enviaChamadoAberto(dispatch, []);
                  desativaLoadingTelaChamadoAberto(dispatch);
                  ativaDesativaLoadingRefreshControlChamadoAberto(
                    dispatch,
                    false
                  );
                } else {
                  // changing to reverse chronological order (latest first)
                  let arrayOfKeys = Object.keys(snapshot.val()).sort().reverse();
                  // transforming to array
                  let results = arrayOfKeys.map(key => snapshot.val()[key]);
                  // storing reference
                  referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
                  let chamados = [];
                  const { responseClientes, responseAtendentes, responseSetores } = await getAllFirebaseCachedData(chaveUnica, results);

                  for (let aux = 0; aux < results.length; aux++) {
                    const cliente = responseClientes.find((cliente) => cliente.key === results[aux].cliente);
                    const atendente = responseAtendentes.find((atendente) => atendente.key === results[aux].atendente);
                    const setor = responseSetores.find((setor) => setor.key === results[aux].setor);

                    chamados.push({
                      key: arrayOfKeys[aux],
                      cliente: !cliente || !cliente.data ? "Não informado" : cliente.data.nome,
                      empresa: !cliente || !cliente.data ? "Não informado" : cliente.data.empresa,
                      setor: !setor || !setor.data ? "Não informado" : setor.data,
                      urlImagemNuvem: !cliente || !cliente.data ? null : cliente.data.urlImagemNuvem,
                      atendente: !atendente || !atendente.data ? "Não informado" : atendente.data,



                      dataHoraAbertura: results[aux].dataHoraAbertura,
                      dataVencimento: results[aux].dataVencimento,
                      status: results[aux].status,
                      prioridade: results[aux].prioridade === "" ? "Não informado" : results[aux].prioridade,
                      descricaoProblema: results[aux].descricaoProblema,
                      alterado: results[aux].alterado,
                      chaveUnica: chaveUnica,
                      numeroChamado: results[aux].numeroChamado,
                      channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                      chamadoAbertoPorWpp: (results[aux].chamadoAbertoPorWpp === undefined) ? false : results[aux].chamadoAbertoPorWpp,
                      abertoPorChat: (results[aux].abertoPorChat === undefined) ? "false" : results[aux].abertoPorChat,
                      lastmessageChatTypeProblem: (results[aux].lastmessageChatTypeProblem === undefined) ? "mensagem" : results[aux].lastmessageChatTypeProblem,
                    });
                  }
                  enviaChamadoAberto(dispatch, chamados);
                  desativaLoadingTelaChamadoAberto(dispatch);
                  enviaUltimaReferenciaKey(dispatch, referenceToOldestKey);
                  ativaDesativaLoadingRefreshControlChamadoAberto(
                    dispatch,
                    false
                  );
                  ativaDesativaLoadingFinalFlatListChamadoAberto(dispatch, true);
                  //    enviaChamadoAberto(dispatch, chamados);
                  //enviaUltimaReferenciaKey(dispatch, referenceToOldestKey)
                }
              })

            /*  .catch(error => {


             }); */
          } else {
            if (referenciaUltimaKey != undefined && referenciaUltimaKey != "") {
              // ativaDesativaLoadingFinalFlatListChamadoAberto(dispatch, true);
              firebase
                .database()
                .ref(`${chaveUnica}/chamados/`)
                .orderByChild("status_atendente")
                .startAt(filtro)
                .endAt(filtro, referenciaUltimaKey)
                //  .startAt('Aberto', referenciaUltimaKey)
                .limitToLast(8)
                .on("value", async snapshot => {
                  if (snapshot.val() == null) {
                    ativaDesativaLoadingFinalFlatListChamadoAberto(dispatch, false);
                  } else {

                    // changing to reverse chronological order (latest first)
                    // & removing duplicate
                    let arrayOfKeys = Object.keys(snapshot.val())
                      .sort()
                      .reverse()
                      .slice(1);
                    // transforming to array
                    let results = arrayOfKeys.map(key => snapshot.val()[key]);
                    // updating reference
                    referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];

                    let chamados = [];
                    const { responseClientes, responseAtendentes, responseSetores } = await getAllFirebaseCachedData(chaveUnica, results);

                    for (let aux = 0; aux < results.length; aux++) {
                      const cliente = responseClientes.find((cliente) => cliente.key === results[aux].cliente);
                      const atendente = responseAtendentes.find((atendente) => atendente.key === results[aux].atendente);
                      const setor = responseSetores.find((setor) => setor.key === results[aux].setor);

                      chamados.push({
                        key: arrayOfKeys[aux],
                        cliente: !cliente || !cliente.data ? "Não informado" : cliente.data.nome,
                        empresa: !cliente || !cliente.data ? "Não informado" : cliente.data.empresa,
                        setor: !setor || !setor.data ? "Não informado" : setor.data,
                        urlImagemNuvem: !cliente || !cliente.data ? null : cliente.data.urlImagemNuvem,
                        atendente: !atendente || !atendente.data ? "Não informado" : atendente.data,



                        dataHoraAbertura: results[aux].dataHoraAbertura,
                        dataVencimento: results[aux].dataVencimento,
                        status: results[aux].status,
                        prioridade:
                          results[aux].prioridade === ""
                            ? "Não informado"
                            : results[aux].prioridade,
                        descricaoProblema: results[aux].descricaoProblema,
                        alterado: results[aux].alterado,
                        chaveUnica: chaveUnica,
                        keyCliente: results[aux].cliente,
                        numeroChamado: results[aux].numeroChamado,
                        channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                        telefoneCliente: (results[aux].telefoneCliente === undefined) ? 'Não informado' : results[aux].telefoneCliente,
                        chamadoAbertoPorWpp: (results[aux].chamadoAbertoPorWpp === undefined) ? false : results[aux].chamadoAbertoPorWpp,
                        abertoPorChat: (results[aux].abertoPorChat === undefined) ? "false" : results[aux].abertoPorChat,
                        lastMessageChat: results[aux].lastMessageChat == undefined ? '' : results[aux].lastMessageChat,
                        lastMessageChatTimeStamp: results[aux].lastMessageChatTimeStamp == undefined ? 0 : results[aux].lastMessageChatTimeStamp,
                        lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                        lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                        lastMessageLineColor: results[aux].lastMessageLineColor == undefined ? '#39a3f4' : results[aux].lastMessageLineColor,
                        lastmessageChatTypeProblem: (results[aux].lastmessageChatTypeProblem === undefined) ? "mensagem" : results[aux].lastmessageChatTypeProblem,
                      });
                    }
                    //  ativaDesativaLoadingFinalFlatListChamadoAberto(dispatch, false);
                    enviaChamadoAberto(
                      dispatch,
                      chamadosAnteriores.concat(chamados)
                    );
                    enviaUltimaReferenciaKey(dispatch, referenceToOldestKey);
                  }
                })

              /*   .catch(error => {

                }); */
            } else {
              ativaDesativaLoadingFinalFlatListChamadoAberto(dispatch, false);
            }
          }
        })
      }
      else if (atendenteVeApenasProprioChamado == true && agruparAtendenteSetor == true && contaPrincipal == false) {
        //aqui o atendente é admin porém quer ve apenas os chamados do setor dele

        const { currentUser } = firebase.auth();
        firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild('uid').equalTo(currentUser.uid).once("value").then((snapshot) => {
          let key = Object.keys(snapshot.val()).toString();

          //pra fazer a consulta pelo novo codigo interno, basta mudar a key da consulta do firebase para este codigo interno
          // e mudar o equalto true para codigo interno tambem.
          let codigoInterno;
          snapshot.forEach(childItem => {
            codigoInterno = childItem.val().codigoInterno;
          })


          if (valor == 0) {
            // if initial fetch
            ativaLoadingTelaAberturaChamado(dispatch);
            ativaDesativaLoadingRefreshControlChamadoAberto(dispatch, true);
            //
            /*   firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild(`usuariosVisualizaAtend/dadosAtendVinc/${key}`).equalTo(true).limitToLast(7) */
            /*             firebase.database().ref(`${chaveUnica}/chamados/`).child(`usuariosVisualizaAtend`).child('dadosAtendVinc').child(`${key}`) */
            /*   firebase.database().ref(`${chaveUnica}/chamados/{userid}`).child(`usuariosVisualizaAtend/dadosAtendVinc/${key}`) */
            /*    firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild(`usuariosVisualizaAtend/dadosAtendVinc/`).endAt(key) */
            /*    firebase.database().ref(`${chaveUnica}`).child('chamados').child('-MGJHNRK8WtoBPptxAIj').child('usuariosVisualizaAtend').child('dadosAtendVinc').orderByKey().equalTo(key) */
            firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild(`usuariosVisualizaAtend/dadosAtendVinc/${codigoInterno}`)
              .equalTo(`${codigoInterno}`).limitToLast(7).on("value", async snapshot => {
                /*  firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild(`usuariosVisualizaAtend/dadosAtendVinc/${codigoInterno}status`).equalTo(`${codigoInterno}Aberto`).limitToLast(7).on("value", snapshot => { */
                if (snapshot.val() == null) {
                  enviaChamadoAberto(dispatch, []);
                  desativaLoadingTelaChamadoAberto(dispatch);
                  ativaDesativaLoadingRefreshControlChamadoAberto(
                    dispatch,
                    false
                  );
                } else {
                  // changing to reverse chronological order (latest first)
                  let arrayOfKeys = Object.keys(snapshot.val()).sort().reverse();
                  // transforming to array
                  let results = arrayOfKeys.map(key => snapshot.val()[key]);
                  // storing reference
                  referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
                  let chamados = [];
                  const { responseClientes, responseAtendentes, responseSetores } = await getAllFirebaseCachedData(chaveUnica, results);

                  for (let aux = 0; aux < results.length; aux++) {
                    const cliente = responseClientes.find((cliente) => cliente.key === results[aux].cliente);
                    const atendente = responseAtendentes.find((atendente) => atendente.key === results[aux].atendente);
                    const setor = responseSetores.find((setor) => setor.key === results[aux].setor);

                    if (results[aux].status === 'Fechado' || results[aux].status === 'Atendendo' || results[aux].status === 'Cancelado') {

                      firebase.database().ref(`${chaveUnica}/chamados/${arrayOfKeys[aux]}/usuariosVisualizaAtend/`).remove();
                    } else {
                      chamados.push({
                        key: arrayOfKeys[aux],
                        cliente: !cliente || !cliente.data ? "Não informado" : cliente.data.nome,
                        empresa: !cliente || !cliente.data ? "Não informado" : cliente.data.empresa,
                        setor: !setor || !setor.data ? "Não informado" : setor.data,
                        urlImagemNuvem: !cliente || !cliente.data ? null : cliente.data.urlImagemNuvem,
                        atendente: !atendente || !atendente.data ? "Não informado" : atendente.data,
                        dataHoraAbertura: results[aux].dataHoraAbertura,
                        dataVencimento: results[aux].dataVencimento,
                        status: results[aux].status,
                        prioridade:
                          results[aux].prioridade === ""
                            ? "Não informado"
                            : results[aux].prioridade,
                        descricaoProblema: results[aux].descricaoProblema,
                        alterado: results[aux].alterado,
                        chaveUnica: chaveUnica,
                        keyCliente: results[aux].cliente,
                        numeroChamado: results[aux].numeroChamado,
                        channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                        telefoneCliente: (results[aux].telefoneCliente === undefined) ? 'Não informado' : results[aux].telefoneCliente,
                        chamadoAbertoPorWpp: (results[aux].chamadoAbertoPorWpp === undefined) ? false : results[aux].chamadoAbertoPorWpp,
                        abertoPorChat: (results[aux].abertoPorChat === undefined) ? "false" : results[aux].abertoPorChat,
                        lastMessageChat: results[aux].lastMessageChat == undefined ? '' : results[aux].lastMessageChat,
                        lastMessageChatTimeStamp: results[aux].lastMessageChatTimeStamp == undefined ? 0 : results[aux].lastMessageChatTimeStamp,
                        lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                        lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                        lastMessageLineColor: results[aux].lastMessageLineColor == undefined ? '#39a3f4' : results[aux].lastMessageLineColor,
                        lastmessageChatTypeProblem: (results[aux].lastmessageChatTypeProblem === undefined) ? "mensagem" : results[aux].lastmessageChatTypeProblem,
                      });
                    }
                  }
                  enviaChamadoAberto(dispatch, chamados);
                  desativaLoadingTelaChamadoAberto(dispatch);

                  enviaUltimaReferenciaKey(dispatch, referenceToOldestKey);
                  ativaDesativaLoadingRefreshControlChamadoAberto(dispatch, false);
                  ativaDesativaLoadingFinalFlatListChamadoAberto(dispatch, true);

                  //    enviaChamadoAberto(dispatch, chamados);
                  //enviaUltimaReferenciaKey(dispatch, referenceToOldestKey)
                }
              })


          } else {


            if (referenciaUltimaKey != undefined && referenciaUltimaKey != "") {
              ativaDesativaLoadingMaisResultadosChamadosCliente(dispatch, true);
              firebase
                .database()
                .ref(`${chaveUnica}/chamados/`)
                .orderByChild(`usuariosVisualizaAtend/dadosAtendVinc/${codigoInterno}`)
                .startAt(`${codigoInterno}`)
                .endAt(`${codigoInterno}`, referenciaUltimaKey)
                //  .startAt('Aberto', referenciaUltimaKey)
                .limitToLast(8)
                .on("value", async snapshot => {

                  if (snapshot.val() == null) {
                    ativaDesativaLoadingFinalFlatListChamadoAberto(dispatch, false);
                    ativaDesativaLoadingMaisResultadosChamadosCliente(dispatch, false);
                  } else {

                    // changing to reverse chronological order (latest first)
                    // & removing duplicate
                    let arrayOfKeys = Object.keys(snapshot.val())
                      .sort()
                      .reverse()
                      .slice(1);
                    // transforming to array
                    let results = arrayOfKeys.map(key => snapshot.val()[key]);
                    // updating reference
                    referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];

                    let chamados = [];

                    const { responseClientes, responseAtendentes, responseSetores } = await getAllFirebaseCachedData(chaveUnica, results);

                    for (let aux = 0; aux < results.length; aux++) {
                      const cliente = responseClientes.find((cliente) => cliente.key === results[aux].cliente);
                      const atendente = responseAtendentes.find((atendente) => atendente.key === results[aux].atendente);
                      const setor = responseSetores.find((setor) => setor.key === results[aux].setor);
                      if (results[aux].status === 'Fechado' || results[aux].status === 'Atendendo' || results[aux].status === 'Cancelado') {

                        firebase.database().ref(`${chaveUnica}/chamados/${arrayOfKeys[aux]}/usuariosVisualizaAtend/`).remove();
                      } else {
                        chamados.push({
                          key: arrayOfKeys[aux],
                          cliente: !cliente || !cliente.data ? "Não informado" : cliente.data.nome,
                          empresa: !cliente || !cliente.data ? "Não informado" : cliente.data.empresa,
                          setor: !setor || !setor.data ? "Não informado" : setor.data,
                          urlImagemNuvem: !cliente || !cliente.data ? null : cliente.data.urlImagemNuvem,
                          atendente: !atendente || !atendente.data ? "Não informado" : atendente.data,
                          dataHoraAbertura: results[aux].dataHoraAbertura,
                          dataVencimento: results[aux].dataVencimento,
                          status: results[aux].status,
                          prioridade:
                            results[aux].prioridade === ""
                              ? "Não informado"
                              : results[aux].prioridade,
                          descricaoProblema: results[aux].descricaoProblema,
                          alterado: results[aux].alterado,
                          chaveUnica: chaveUnica,
                          keyCliente: results[aux].cliente,
                          numeroChamado: results[aux].numeroChamado,
                          channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                          telefoneCliente: (results[aux].telefoneCliente === undefined) ? 'Não informado' : results[aux].telefoneCliente,
                          chamadoAbertoPorWpp: (results[aux].chamadoAbertoPorWpp === undefined) ? false : results[aux].chamadoAbertoPorWpp,
                          abertoPorChat: (results[aux].abertoPorChat === undefined) ? "false" : results[aux].abertoPorChat,
                          lastMessageChat: results[aux].lastMessageChat == undefined ? '' : results[aux].lastMessageChat,
                          lastMessageChatTimeStamp: results[aux].lastMessageChatTimeStamp == undefined ? 0 : results[aux].lastMessageChatTimeStamp,
                          lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                          lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                          lastMessageLineColor: results[aux].lastMessageLineColor == undefined ? '#39a3f4' : results[aux].lastMessageLineColor,
                          lastmessageChatTypeProblem: (results[aux].lastmessageChatTypeProblem === undefined) ? "mensagem" : results[aux].lastmessageChatTypeProblem,
                        });
                      }
                    }

                    enviaChamadoAberto(
                      dispatch,
                      chamadosAnteriores.concat(chamados)
                    );
                    enviaUltimaReferenciaKey(dispatch, referenceToOldestKey);
                    ativaDesativaLoadingMaisResultadosChamadosCliente(dispatch, false);
                  }
                })


            } else {
              ativaDesativaLoadingFinalFlatListChamadoAberto(dispatch, false);
            }
          }
        })
      }
      //aqui irei mostrar apenas os chamados do proprio atendente e os chamados provenientes do setor que este atendente está vinculado
      else if (atendenteVeApenasProprioChamado == true && agruparAtendenteSetor == true && contaPrincipal == true && adminVisualizaApenasProprioSetor == true) {
        //aqui o atendente é admin porém quer ve apenas os chamados do setor dele

        const { currentUser } = firebase.auth();
        firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild('uid').equalTo(currentUser.uid).once("value").then((snapshot) => {
          let key = Object.keys(snapshot.val()).toString();

          //pra fazer a consulta pelo novo codigo interno, basta mudar a key da consulta do firebase para este codigo interno
          // e mudar o equalto true para codigo interno tambem.
          let codigoInterno;
          snapshot.forEach(childItem => {
            codigoInterno = childItem.val().codigoInterno;
          })


          if (valor == 0) {
            // if initial fetch
            ativaLoadingTelaAberturaChamado(dispatch);
            ativaDesativaLoadingRefreshControlChamadoAberto(dispatch, true);
            //
            /*   firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild(`usuariosVisualizaAtend/dadosAtendVinc/${key}`).equalTo(true).limitToLast(7) */
            /*             firebase.database().ref(`${chaveUnica}/chamados/`).child(`usuariosVisualizaAtend`).child('dadosAtendVinc').child(`${key}`) */
            /*   firebase.database().ref(`${chaveUnica}/chamados/{userid}`).child(`usuariosVisualizaAtend/dadosAtendVinc/${key}`) */
            /*    firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild(`usuariosVisualizaAtend/dadosAtendVinc/`).endAt(key) */
            /*    firebase.database().ref(`${chaveUnica}`).child('chamados').child('-MGJHNRK8WtoBPptxAIj').child('usuariosVisualizaAtend').child('dadosAtendVinc').orderByKey().equalTo(key) */
            firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild(`usuariosVisualizaAtend/dadosAtendVinc/${codigoInterno}status`)
              .equalTo(`${codigoInterno}Aberto`).limitToLast(7).on("value", async snapshot => {
                /*  firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild(`usuariosVisualizaAtend/dadosAtendVinc/${codigoInterno}status`).equalTo(`${codigoInterno}Aberto`).limitToLast(7).on("value", snapshot => { */
                if (snapshot.val() == null) {
                  enviaChamadoAberto(dispatch, []);
                  desativaLoadingTelaChamadoAberto(dispatch);
                  ativaDesativaLoadingRefreshControlChamadoAberto(
                    dispatch,
                    false
                  );
                } else {
                  // changing to reverse chronological order (latest first)
                  let arrayOfKeys = Object.keys(snapshot.val()).sort().reverse();
                  // transforming to array
                  let results = arrayOfKeys.map(key => snapshot.val()[key]);
                  // storing reference
                  referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
                  let chamados = [];
                  const { responseClientes, responseAtendentes, responseSetores } = await getAllFirebaseCachedData(chaveUnica, results);

                  for (let aux = 0; aux < results.length; aux++) {
                    const cliente = responseClientes.find((cliente) => cliente.key === results[aux].cliente);
                    const atendente = responseAtendentes.find((atendente) => atendente.key === results[aux].atendente);
                    const setor = responseSetores.find((setor) => setor.key === results[aux].setor);

                    chamados.push({
                      key: arrayOfKeys[aux],
                      cliente: !cliente || !cliente.data ? "Não informado" : cliente.data.nome,
                      empresa: !cliente || !cliente.data ? "Não informado" : cliente.data.empresa,
                      setor: !setor || !setor.data ? "Não informado" : setor.data,
                      urlImagemNuvem: !cliente || !cliente.data ? null : cliente.data.urlImagemNuvem,
                      atendente: !atendente || !atendente.data ? "Não informado" : atendente.data,



                      dataHoraAbertura: results[aux].dataHoraAbertura,
                      dataVencimento: results[aux].dataVencimento,
                      status: results[aux].status,
                      prioridade:
                        results[aux].prioridade === ""
                          ? "Não informado"
                          : results[aux].prioridade,
                      descricaoProblema: results[aux].descricaoProblema,
                      alterado: results[aux].alterado,
                      chaveUnica: chaveUnica,
                      keyCliente: results[aux].cliente,
                      numeroChamado: results[aux].numeroChamado,
                      channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                      telefoneCliente: (results[aux].telefoneCliente === undefined) ? 'Não informado' : results[aux].telefoneCliente,
                      chamadoAbertoPorWpp: (results[aux].chamadoAbertoPorWpp === undefined) ? false : results[aux].chamadoAbertoPorWpp,
                      abertoPorChat: (results[aux].abertoPorChat === undefined) ? "false" : results[aux].abertoPorChat,
                      lastMessageChat: results[aux].lastMessageChat == undefined ? '' : results[aux].lastMessageChat,
                      lastMessageChatTimeStamp: results[aux].lastMessageChatTimeStamp == undefined ? 0 : results[aux].lastMessageChatTimeStamp,
                      lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                      lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                      lastMessageLineColor: results[aux].lastMessageLineColor == undefined ? '#39a3f4' : results[aux].lastMessageLineColor,
                      lastmessageChatTypeProblem: (results[aux].lastmessageChatTypeProblem === undefined) ? "mensagem" : results[aux].lastmessageChatTypeProblem,
                    });
                  }
                  enviaChamadoAberto(dispatch, chamados);
                  desativaLoadingTelaChamadoAberto(dispatch);

                  enviaUltimaReferenciaKey(dispatch, referenceToOldestKey);
                  ativaDesativaLoadingRefreshControlChamadoAberto(dispatch, false);
                  ativaDesativaLoadingFinalFlatListChamadoAberto(dispatch, true);

                  //    enviaChamadoAberto(dispatch, chamados);
                  //enviaUltimaReferenciaKey(dispatch, referenceToOldestKey)
                }
              })


          } else {


            if (referenciaUltimaKey != undefined && referenciaUltimaKey != "") {
              ativaDesativaLoadingMaisResultadosChamadosCliente(dispatch, true);
              firebase
                .database()
                .ref(`${chaveUnica}/chamados/`)
                .orderByChild(`usuariosVisualizaAtend/dadosAtendVinc/${codigoInterno}status`)
                .startAt(`${codigoInterno}Aberto`)
                .endAt(`${codigoInterno}Aberto`, referenciaUltimaKey)
                //  .startAt('Aberto', referenciaUltimaKey)
                .limitToLast(8)
                .on("value", async snapshot => {

                  if (snapshot.val() == null) {
                    ativaDesativaLoadingFinalFlatListChamadoAberto(dispatch, false);
                    ativaDesativaLoadingMaisResultadosChamadosCliente(dispatch, false);
                  } else {

                    // changing to reverse chronological order (latest first)
                    // & removing duplicate
                    let arrayOfKeys = Object.keys(snapshot.val())
                      .sort()
                      .reverse()
                      .slice(1);
                    // transforming to array
                    let results = arrayOfKeys.map(key => snapshot.val()[key]);
                    // updating reference
                    referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];

                    let chamados = [];

                    const { responseClientes, responseAtendentes, responseSetores } = await getAllFirebaseCachedData(chaveUnica, results);

                    for (let aux = 0; aux < results.length; aux++) {
                      const cliente = responseClientes.find((cliente) => cliente.key === results[aux].cliente);
                      const atendente = responseAtendentes.find((atendente) => atendente.key === results[aux].atendente);
                      const setor = responseSetores.find((setor) => setor.key === results[aux].setor);

                      chamados.push({
                        key: arrayOfKeys[aux],
                        cliente: !cliente || !cliente.data ? "Não informado" : cliente.data.nome,
                        empresa: !cliente || !cliente.data ? "Não informado" : cliente.data.empresa,
                        setor: !setor || !setor.data ? "Não informado" : setor.data,
                        urlImagemNuvem: !cliente || !cliente.data ? null : cliente.data.urlImagemNuvem,
                        atendente: !atendente || !atendente.data ? "Não informado" : atendente.data,



                        dataHoraAbertura: results[aux].dataHoraAbertura,
                        dataVencimento: results[aux].dataVencimento,
                        status: results[aux].status,
                        prioridade:
                          results[aux].prioridade === ""
                            ? "Não informado"
                            : results[aux].prioridade,
                        descricaoProblema: results[aux].descricaoProblema,
                        alterado: results[aux].alterado,
                        chaveUnica: chaveUnica,
                        keyCliente: results[aux].cliente,
                        numeroChamado: results[aux].numeroChamado,
                        channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                        telefoneCliente: (results[aux].telefoneCliente === undefined) ? 'Não informado' : results[aux].telefoneCliente,
                        chamadoAbertoPorWpp: (results[aux].chamadoAbertoPorWpp === undefined) ? false : results[aux].chamadoAbertoPorWpp,
                        abertoPorChat: (results[aux].abertoPorChat === undefined) ? "false" : results[aux].abertoPorChat,
                        lastMessageChat: results[aux].lastMessageChat == undefined ? '' : results[aux].lastMessageChat,
                        lastMessageChatTimeStamp: results[aux].lastMessageChatTimeStamp == undefined ? 0 : results[aux].lastMessageChatTimeStamp,
                        lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                        lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                        lastMessageLineColor: results[aux].lastMessageLineColor == undefined ? '#39a3f4' : results[aux].lastMessageLineColor,
                        lastmessageChatTypeProblem: (results[aux].lastmessageChatTypeProblem === undefined) ? "mensagem" : results[aux].lastmessageChatTypeProblem,
                      });
                    }

                    enviaChamadoAberto(
                      dispatch,
                      chamadosAnteriores.concat(chamados)
                    );
                    enviaUltimaReferenciaKey(dispatch, referenceToOldestKey);
                    ativaDesativaLoadingMaisResultadosChamadosCliente(dispatch, false);
                  }
                })


            } else {
              ativaDesativaLoadingFinalFlatListChamadoAberto(dispatch, false);
            }
          }
        })
      }
      //chamado padrao
      else {

        if (valor == 0) {
          // if initial fetch
          ativaLoadingTelaAberturaChamado(dispatch);
          ativaDesativaLoadingRefreshControlChamadoAberto(dispatch, true);
          firebase
            .database()
            .ref(`${chaveUnica}/chamados/`)
            .orderByChild("status")
            .equalTo("Aberto")
            .limitToLast(7)
            .on("value", async snapshot => {
              if (snapshot.val() == null) {
                enviaChamadoAberto(dispatch, []);
                desativaLoadingTelaChamadoAberto(dispatch);
                ativaDesativaLoadingRefreshControlChamadoAberto(
                  dispatch,
                  false
                );
              } else {
                // changing to reverse chronological order (latest first)
                let arrayOfKeys = Object.keys(snapshot.val()).sort().reverse();

                // transforming to array
                let results = arrayOfKeys.map(key => snapshot.val()[key]);
                // storing reference
                referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
                let chamados = [];
                const { responseClientes, responseAtendentes, responseSetores } = await getAllFirebaseCachedData(chaveUnica, results);

                for (let aux = 0; aux < results.length; aux++) {
                  const cliente = responseClientes.find((cliente) => cliente.key === results[aux].cliente);
                  const atendente = responseAtendentes.find((atendente) => atendente.key === results[aux].atendente);
                  const setor = responseSetores.find((setor) => setor.key === results[aux].setor);

                  chamados.push({
                    key: arrayOfKeys[aux],
                    cliente: !cliente || !cliente.data ? "Não informado" : cliente.data.nome,
                    empresa: !cliente || !cliente.data ? "Não informado" : cliente.data.empresa,
                    setor: !setor || !setor.data ? "Não informado" : setor.data,
                    urlImagemNuvem: !cliente || !cliente.data ? null : cliente.data.urlImagemNuvem,
                    atendente: !atendente || !atendente.data ? "Não informado" : atendente.data,



                    dataHoraAbertura: results[aux].dataHoraAbertura,
                    dataVencimento: results[aux].dataVencimento,
                    status: results[aux].status,
                    prioridade:
                      results[aux].prioridade === ""
                        ? "Não informado"
                        : results[aux].prioridade,
                    descricaoProblema: results[aux].descricaoProblema,
                    alterado: results[aux].alterado,
                    chaveUnica: chaveUnica,
                    keyCliente: results[aux].cliente,
                    numeroChamado: results[aux].numeroChamado,
                    channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                    telefoneCliente: (results[aux].telefoneCliente === undefined) ? 'Não informado' : results[aux].telefoneCliente,
                    chamadoAbertoPorWpp: (results[aux].chamadoAbertoPorWpp === undefined) ? false : results[aux].chamadoAbertoPorWpp,
                    abertoPorChat: (results[aux].abertoPorChat === undefined) ? "false" : results[aux].abertoPorChat,
                    lastMessageChat: results[aux].lastMessageChat == undefined ? '' : results[aux].lastMessageChat,
                    lastMessageChatTimeStamp: results[aux].lastMessageChatTimeStamp == undefined ? 0 : results[aux].lastMessageChatTimeStamp,
                    lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                    lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                    lastMessageLineColor: results[aux].lastMessageLineColor == undefined ? '#39a3f4' : results[aux].lastMessageLineColor,
                    lastmessageChatTypeProblem: (results[aux].lastmessageChatTypeProblem === undefined) ? "mensagem" : results[aux].lastmessageChatTypeProblem,
                  });
                }
                enviaChamadoAberto(dispatch, chamados);
                desativaLoadingTelaChamadoAberto(dispatch);

                enviaUltimaReferenciaKey(dispatch, referenceToOldestKey);
                //
                ativaDesativaLoadingRefreshControlChamadoAberto(dispatch, false);
                ativaDesativaLoadingFinalFlatListChamadoAberto(dispatch, true);
                //    enviaChamadoAberto(dispatch, chamados);
                //enviaUltimaReferenciaKey(dispatch, referenceToOldestKey)
              }
            })

          /*  .catch(error => {


           }); */
        } else {
          /*
              */
          if (referenciaUltimaKey != undefined && referenciaUltimaKey != "") {

            //  ativaDesativaLoadingFinalFlatListChamadoAberto(dispatch, true);
            firebase
              .database()
              .ref(`${chaveUnica}/chamados/`)
              .orderByChild("status")
              .startAt("Aberto")
              .endAt("Aberto", referenciaUltimaKey)
              //  .startAt('Aberto', referenciaUltimaKey)
              .limitToLast(8)
              .on("value", async snapshot => {
                if (snapshot.val() == null) {
                  ativaDesativaLoadingFinalFlatListChamadoAberto(dispatch, false);
                } else {

                  // changing to reverse chronological order (latest first)
                  // & removing duplicate
                  let arrayOfKeys = Object.keys(snapshot.val())
                    .sort()
                    .reverse()
                    .slice(1);
                  // transforming to array
                  let results = arrayOfKeys.map(key => snapshot.val()[key]);
                  // updating reference
                  referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];

                  let chamados = [];
                  const { responseClientes, responseAtendentes, responseSetores } = await getAllFirebaseCachedData(chaveUnica, results);

                  for (let aux = 0; aux < results.length; aux++) {
                    const cliente = responseClientes.find((cliente) => cliente.key === results[aux].cliente);
                    const atendente = responseAtendentes.find((atendente) => atendente.key === results[aux].atendente);
                    const setor = responseSetores.find((setor) => setor.key === results[aux].setor);

                    chamados.push({
                      key: arrayOfKeys[aux],
                      cliente: !cliente || !cliente.data ? "Não informado" : cliente.data.nome,
                      empresa: !cliente || !cliente.data ? "Não informado" : cliente.data.empresa,
                      setor: !setor || !setor.data ? "Não informado" : setor.data,
                      urlImagemNuvem: !cliente || !cliente.data ? null : cliente.data.urlImagemNuvem,
                      atendente: !atendente || !atendente.data ? "Não informado" : atendente.data,



                      dataHoraAbertura: results[aux].dataHoraAbertura,
                      dataVencimento: results[aux].dataVencimento,
                      status: results[aux].status,
                      prioridade:
                        results[aux].prioridade === ""
                          ? "Não informado"
                          : results[aux].prioridade,
                      descricaoProblema: results[aux].descricaoProblema,
                      alterado: results[aux].alterado,
                      chaveUnica: chaveUnica,
                      keyCliente: results[aux].cliente,
                      numeroChamado: results[aux].numeroChamado,
                      channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                      telefoneCliente: (results[aux].telefoneCliente === undefined) ? 'Não informado' : results[aux].telefoneCliente,
                      chamadoAbertoPorWpp: (results[aux].chamadoAbertoPorWpp === undefined) ? false : results[aux].chamadoAbertoPorWpp,
                      abertoPorChat: (results[aux].abertoPorChat === undefined) ? "false" : results[aux].abertoPorChat,
                      lastMessageChat: results[aux].lastMessageChat == undefined ? '' : results[aux].lastMessageChat,
                      lastMessageChatTimeStamp: results[aux].lastMessageChatTimeStamp == undefined ? 0 : results[aux].lastMessageChatTimeStamp,
                      lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                      lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                      lastMessageLineColor: results[aux].lastMessageLineColor == undefined ? '#39a3f4' : results[aux].lastMessageLineColor,
                      lastmessageChatTypeProblem: (results[aux].lastmessageChatTypeProblem === undefined) ? "mensagem" : results[aux].lastmessageChatTypeProblem,
                    });
                  }
                  enviaChamadoAberto(dispatch, chamadosAnteriores.concat(chamados));
                  enviaUltimaReferenciaKey(dispatch, referenceToOldestKey);
                  //  ativaDesativaLoadingFinalFlatListChamadoAberto(dispatch, false);
                }
              })/* .catch(error => {

                  }); */
          } else {
            ativaDesativaLoadingFinalFlatListChamadoAberto(dispatch, false);
          }
        }
      }
    });

  };
};


export const listaChamadoParaTelaAcoesChamado = keyAtendimento => {
  return dispatch => {

    ativaDesativaLoadingTelaAcoesAtendimento(dispatch, true);
    AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");


      dispatch(listaAcoesChamadoConversasAcoesChamado(0, chaveUnica, keyAtendimento, '', []));

      firebase.database().ref(`${chaveUnica}/chamados/`).orderByKey().equalTo(keyAtendimento).limitToLast(1).on("value", async snapshot => {
        let keyAtendimento = Object.keys(snapshot.val());
        let promisseAbertoPor;
        let promiseCliente;
        let promiseAtendente;
        let promiseSetor;
        const clienteChamado = snapshot.val()[keyAtendimento].cliente;
        const atendenteChamado = snapshot.val()[keyAtendimento].atendente;
        const setorChamado = snapshot.val()[keyAtendimento].setor;
        const abertoPorChamado = snapshot.val()[keyAtendimento].abertoPor;

        if (clienteChamado && clienteChamado.toLowerCase() !== 'não informado') {
          promiseCliente = await getFirebaseCachedData(`${chaveUnica}/clientes`, clienteChamado, null);
        }

        if (atendenteChamado && atendenteChamado.toLowerCase() !== 'não informado') {
          promiseAtendente = await getFirebaseCachedData(`${chaveUnica}/usuarios`, atendenteChamado, 'nome');
        }

        if (setorChamado && setorChamado.toLowerCase() !== 'não informado') {
          promiseSetor = await getFirebaseCachedData(`${chaveUnica}/setores`, setorChamado, 'nome');
        }

        if (abertoPorChamado && abertoPorChamado.toLowerCase() !== 'não informado') {
          if (snapshot.val()[keyAtendimento].chamadoAbertoPorAdminOuCliente == "cliente") {
            promisseAbertoPor = await getFirebaseCachedData(`${chaveUnica}/clientes`, abertoPorChamado, 'nome');
          } else {
            promisseAbertoPor = await getFirebaseCachedData(`${chaveUnica}/usuarios`, abertoPorChamado, 'nome');
          }
        }
        const cliente = await promiseCliente;
        const atendente = await promiseAtendente;
        const setor = await promiseSetor;
        const abertoPor = await promisseAbertoPor;
        const dadosAtendimento = [];
        snapshot.forEach(childItem => {
          dadosAtendimento.push({
            key: childItem.key,
            //  atendente: responses[0].val() == null ? "Não informado" : responses[0].val(),
            atendente: !atendente || !atendente.data ? "Não informado" : atendente.data,

            // abertoPor: responses[3].val() == null ? "Não informado" : responses[3].val(),
            abertoPor: !abertoPor || !abertoPor.data ? "Não informado" : snapshot.val()[keyAtendimento].chamadoAbertoPorAdminOuCliente == "cliente" ? abertoPor.data.nome : abertoPor.data,

            // cliente: responses[1].val() == null ? "Não informado" : responses[1].val().nome,
            // empresa: responses[1].val() == null ? "Não informado" : responses[1].val().empresa,
            cliente: !cliente || !cliente.data ? "Não informado" : cliente.data.nome,
            empresa: !cliente || !cliente.data ? "Não informado" : cliente.data.empresa,
            urlImagemNuvem: !cliente || !cliente.data ? null : cliente.data.urlImagemNuvem,
            telefoneCliente: !cliente || !cliente.data.telefoneCliente ? "Não informado" : cliente.data.telefoneCliente,

            enderecoCliente: !cliente || !cliente.data.enderecoCliente ? "Não informado" : cliente.data.enderecoCliente,

            //  telefoneCliente: responses[1].val() == null ? "Não informado" : responses[1].val().telefoneCliente,
            //  enderecoCliente: responses[1].val() == null ? "Não informado" : responses[1].val().enderecoCliente,

            setor: !setor || !setor.data ? "Não informado" : setor.data,
            // setor: responses[2].val() == null ? "Não informado" : responses[2].val(),

            keyAtendente: childItem.val().atendente,
            chamadoAbertoPorAdminOuCliente: childItem.val().chamadoAbertoPorAdminOuCliente,
            keyCliente: childItem.val().cliente,
            alterado: childItem.val().alterado,
            dataAbertura: childItem.val().dataAbertura,
            dataHoraAbertura: childItem.val().dataHoraAbertura,
            dataHoraFechamento: childItem.val().dataHoraFechamento == undefined ? "" : childItem.val().dataHoraFechamento,
            dataHoraCancelamento: childItem.val().dataHoraCancelamento == undefined ? "" : childItem.val().dataHoraCancelamento,
            dataVencimento: childItem.val().dataVencimento,
            descricaoProblema: childItem.val().descricaoProblema,
            local: childItem.val().local,
            numeroChamado: childItem.val().numeroChamado,
            prioridade: childItem.val().prioridade,
            tempoGasto: childItem.val().tempoGasto,
            periodoResolucaoChamado: childItem.val().periodoResolucaoChamado == undefined ? "" : childItem.val().periodoResolucaoChamado,
            keySetor: childItem.val().setor,
            status: childItem.val().status,
            keyEmpresaVinculada: childItem.val().keyEmpresaVinculada == undefined ? 'Não Vinculado' : childItem.val().keyEmpresaVinculada,
            solucaoAtendimento: childItem.val().solucaoAtendimento == undefined ? 'Não informado' : childItem.val().solucaoAtendimento,
            chaveUnica: chaveUnica,
            usuariosVisualizaAtend: childItem.val().usuariosVisualizaAtend,

            channelChamado: childItem.val().channelChamado == undefined ? "" : childItem.val().channelChamado,
            chamadoAbertoPorWpp: childItem.val().chamadoAbertoPorWpp == undefined ? false : childItem.val().chamadoAbertoPorWpp,
            abertoPorChat: (childItem.val().abertoPorChat === undefined) ? "false" : childItem.val().abertoPorChat,
            lastMessageChat: childItem.val().lastMessageChat == undefined ? '' : childItem.val().lastMessageChat,
            lastMessageChatTimeStamp: childItem.val().lastMessageChatTimeStamp == undefined ? 0 : childItem.val().lastMessageChatTimeStamp,
            lastmessageChatCount: childItem.val().lastmessageChatCount == undefined ? 0 : childItem.val().lastmessageChatCount,
            lastmessageChatType: childItem.val().lastmessageChatType == undefined ? 'mensagem' : childItem.val().lastmessageChatType,
            lastMessageLineColor: childItem.val().lastMessageLineColor == undefined ? '#39a3f4' : childItem.val().lastMessageLineColor,
            lastmessageChatTypeProblem: childItem.val().lastmessageChatTypeProblem == undefined ? 'mensagem' : childItem.val().lastmessageChatTypeProblem,
            descricaoProblemaEstatico: (childItem.val().descricaoProblemaEstatico == undefined) ? 0 : childItem.val().descricaoProblemaEstatico,
            keyProblemaEstatico: (childItem.val().keyProblemaEstatico == undefined) ? '' : childItem.val().keyProblemaEstatico,
          });

          dispatch({ type: MODIFICAPROBLEMAESTATICOSELECIONADONOVOATENDIMENTO, payload: dadosAtendimento[0].keyProblemaEstatico })
          dispatch({ type: MODIFICANOMEPROBLEMAESTATICOSELECIONADONOVOATENDIMENTO, payload: dadosAtendimento[0].descricaoProblema })
          enviaAtendimentoParaReducer(dispatch, dadosAtendimento);

          ativaDesativaLoadingTelaAcoesAtendimento(dispatch, false);
        });
      });
      /*  firebase
         .database()
         .ref(`${chaveUnica}/configuracao/atendimento`)
         .on("value", snapshot => {
           if (snapshot.val()) {

             const dadosConfiguracaoAtendimento = [];
             snapshot.forEach(childItem => {
               dadosConfiguracaoAtendimento.push({
                 key: childItem.key,
                 atribuirAtendenteObrigatorio: childItem.val()
                   .atribuirAtendenteObrigatorio
               });
             });

             console.log(
               dadosConfiguracaoAtendimento[0].atribuirAtendenteObrigatorio
             );
             //envia se tem ou nao permissao pra avaliar
             if (dadosConfiguracaoAtendimento[0].atribuirAtendenteObrigatorio) {
               verificaSeEhObrigatorioATribuirAtendente(dispatch, true);
             } else {
               verificaSeEhObrigatorioATribuirAtendente(dispatch, false);
             }
           } else {

             //envia como nao tem permissao
             verificaSeEhObrigatorioATribuirAtendente(dispatch, false);
           }
         }); */
      firebase
        .database()
        .ref(`${chaveUnica}/chamados/${keyAtendimento}/imagensVinculadas`)
        .once("value")
        .then(snapshot => {
          //
          //tem arquivos vinculados
          let nomesArquivosAnexados = [];
          let arrayArquivosUpados = [];
          if (snapshot.val()) {
            //ativo loading

            snapshot.forEach(childItem => {
              nomesArquivosAnexados.push({
                key: childItem.key,
                nomeArquivo: childItem.val().nomeArquivo
              });
            });
            let aux = 0;
            for (let i = 0; i < nomesArquivosAnexados.length; i++) {
              firebase
                .storage()
                .ref()
                .child(
                  `${chaveUnica}/${keyAtendimento}/${nomesArquivosAnexados[i].nomeArquivo}`
                )
                .getDownloadURL()
                .then(url => {
                  firebase
                    .storage()
                    .ref()
                    .child(
                      `${chaveUnica}/${keyAtendimento}/${nomesArquivosAnexados[i].nomeArquivo}`
                    )
                    .getMetadata()
                    .then(metadata => {
                      arrayArquivosUpados.push({
                        url: url,
                        nome: metadata.name,
                        tipo: metadata.contentType
                      });
                      aux++;
                      if (aux == nomesArquivosAnexados.length) {
                        enviaArquivosUpadosAnexadoAoChamado(
                          dispatch,
                          arrayArquivosUpados
                        );
                        //
                        //
                        ativaDesatidaLoadingArquivosUpadosAnexadoAoChamado(
                          dispatch,
                          false
                        );
                      }
                    });
                });
            }
          }

          //nao tem arquivo
          else {
            //
            //desativo loading
            enviaArquivosUpadosAnexadoAoChamado(dispatch, arrayArquivosUpados);
            ativaDesatidaLoadingArquivosUpadosAnexadoAoChamado(dispatch, false);
          }
        });
    });
    /*    } else {

         ativaDesativaLoadingTelaAcoesAtendimento(dispatch, false);
         ativaDesatidaLoadingArquivosUpadosAnexadoAoChamado(dispatch, false)
       }
     }).catch((err) => ) */
  };
};
export const listaAcoesChamadoConversasAcoesChamado = (valor, chaveUnica, keyAtendimento, referenciaUltimaKey, conversasAnteriores) => {
  return dispatch => {
    ativaDesativaLoadingCarregandoMaisAcoesChamadoAcoesChamado(dispatch, true);


    let refOuvinte;
    if (valor === 0) {
      enviaConversasAntigasChatAcoesChamado(dispatch, []);
      enviaReferenciaultimaKeyAcoesChamadoAcoesChamado(dispatch, '');

      refOuvinte = firebase.database().ref(`${chaveUnica}/acoesChamados/${keyAtendimento}`).limitToLast(14).on("value", snapshot2 => {
        let arrayAcoesAtendimento = [];

        /*  */
        snapshot2.forEach(childItem => {
          if (childItem.val().tipo !== undefined) {

            arrayAcoesAtendimento.push({
              key: childItem.key,
              title: childItem.val().titulo,
              tipo: childItem.val().tipo,
              description: childItem.val().mensagem,
              time: childItem.val().time,
              lineColor: childItem.val().lineColor,
              dataHoraAtual: childItem.val().dataHoraAtual == undefined ? childItem.val().mensagem : childItem.val().dataHoraAtual,
              circleColor: childItem.val().circleColor,
              urlDownload: childItem.val().urlDownload,
              visivelATodos: childItem.val().visivelATodos == undefined ? 1 : childItem.val().visivelATodos,
              tipoArquivo: childItem.val().tipoArquivo,
              mensagemSistema: childItem.val().mensagemSistema,
              duracao: childItem.val().duracao == undefined ? '' : childItem.val().duracao,
              audio: childItem.val().audio == undefined ? false : childItem.val().audio,
              statusMessage: childItem.val().statusMessage == undefined ? 'sent' : childItem.val().statusMessage,
              idMensagem: childItem.val().idMensagem == undefined ? childItem.key : childItem.val().idMensagem === '' ? childItem.key : childItem.val().idMensagem,
              circleColorResponder: childItem.val().circleColorResponder == undefined ? '#00FF7F' : childItem.val().circleColorResponder,
              commentResponderType: childItem.val().commentResponderType == undefined ? '' : childItem.val().commentResponderType,
              idMensagemResponder: childItem.val().idMensagemResponder == undefined ? '' : childItem.val().idMensagemResponder,
              mensagemResposta: childItem.val().mensagemResposta == undefined ? '' : childItem.val().mensagemResposta,
              possuiResposta: childItem.val().possuiResposta == undefined ? false : childItem.val().possuiResposta,
              commentResponderTypeArquivo: childItem.val().commentResponderTypeArquivo == undefined ? '' : childItem.val().commentResponderTypeArquivo,
              commentResponderIsAudio: childItem.val().commentResponderIsAudio == undefined ? '' : childItem.val().commentResponderIsAudio,
              atendenteResponder: childItem.val().atendenteResponder == undefined ? '' : childItem.val().atendenteResponder,
              refOuvinte: refOuvinte,
              deleteMessage: childItem.val().deleteMessage == undefined ? false : childItem.val().deleteMessage,
              captionArquivo: childItem.val().captionArquivo == undefined ? '' : childItem.val().captionArquivo,
              edited: childItem.val().edited ?? false,
            })
          }



        })



        arrayAcoesAtendimento = arrayAcoesAtendimento.reverse()

        // enviaReferenciaultimaKeyAcoesChamado(dispatch, referenceToOldestKey);
        enviaAcoesAtendimentoParaReducer(dispatch, arrayAcoesAtendimento);
        ativaDesativaLoadingCarregandoMaisAcoesChamadoAcoesChamado(dispatch, false);

      })
    } else {
      if (referenciaUltimaKey === undefined) {
        setTimeout(() => {
          ativaDesativaLoadingCarregandoMaisAcoesChamadoAcoesChamado(dispatch, false);
        }, 500);
      } else {

        firebase.database().ref(`${chaveUnica}/acoesChamados/${keyAtendimento}`).orderByKey()
          .endAt(referenciaUltimaKey).limitToLast(15).once('value').then((snapshot) => {
            if (snapshot.val()) {
              let arrayNovasConversas = [];
              let arrayOfKeys = Object.keys(snapshot.val()).sort().reverse().slice(1);
              let referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
              snapshot.forEach(childItem => {
                if (childItem.val().tipo !== undefined) {
                  arrayNovasConversas.push({
                    key: childItem.key,
                    title: childItem.val().titulo,
                    tipo: childItem.val().tipo,
                    description: childItem.val().mensagem,
                    time: childItem.val().time,
                    lineColor: childItem.val().lineColor,
                    dataHoraAtual: childItem.val().dataHoraAtual == undefined ? childItem.val().mensagem : childItem.val().dataHoraAtual,
                    circleColor: childItem.val().circleColor,
                    urlDownload: childItem.val().urlDownload,
                    visivelATodos: childItem.val().visivelATodos == undefined ? 1 : childItem.val().visivelATodos,
                    tipoArquivo: childItem.val().tipoArquivo,
                    mensagemSistema: childItem.val().mensagemSistema,
                    duracao: childItem.val().duracao == undefined ? '' : childItem.val().duracao,
                    audio: childItem.val().audio == undefined ? false : childItem.val().audio,
                    statusMessage: childItem.val().statusMessage == undefined ? 'sent' : childItem.val().statusMessage,
                    idMensagem: childItem.val().idMensagem == undefined ? childItem.key : childItem.val().idMensagem === '' ? childItem.key : childItem.val().idMensagem,
                    circleColorResponder: childItem.val().circleColorResponder == undefined ? '#00FF7F' : childItem.val().circleColorResponder,
                    commentResponderType: childItem.val().commentResponderType == undefined ? '' : childItem.val().commentResponderType,
                    idMensagemResponder: childItem.val().idMensagemResponder == undefined ? '' : childItem.val().idMensagemResponder,
                    mensagemResposta: childItem.val().mensagemResposta == undefined ? '' : childItem.val().mensagemResposta,
                    possuiResposta: childItem.val().possuiResposta == undefined ? false : childItem.val().possuiResposta,
                    commentResponderTypeArquivo: childItem.val().commentResponderTypeArquivo == undefined ? '' : childItem.val().commentResponderTypeArquivo,
                    commentResponderIsAudio: childItem.val().commentResponderIsAudio == undefined ? '' : childItem.val().commentResponderIsAudio,
                    atendenteResponder: childItem.val().atendenteResponder == undefined ? '' : childItem.val().atendenteResponder,
                    refOuvinte,
                    deleteMessage: childItem.val().deleteMessage == undefined ? false : childItem.val().deleteMessage,
                    captionArquivo: childItem.val().captionArquivo == undefined ? '' : childItem.val().captionArquivo,
                    edited: childItem.val().edited ?? false,
                  })
                }
              })

              let arrayAcoes = arrayNovasConversas.reverse().slice(1);

              //enviaConversasCarregadasEmEspera(dispatch, conversasAnteriores.concat(arrayAcoes))
              enviaReferenciaultimaKeyAcoesChamadoAcoesChamado(dispatch, referenceToOldestKey);
              enviaConversasAntigasChatAcoesChamado(dispatch, conversasAnteriores.concat(arrayAcoes))
              setTimeout(() => {
                ativaDesativaLoadingCarregandoMaisAcoesChamadoAcoesChamado(dispatch, false);
              }, 500);
              //enviaReferenciaultimaKeyAcoesChamado(dispatch, referenceToOldestKey);
              // enviaAcoesAtendimentoParaReducerChat(dispatch, arrayAcoesNovo);
              // ativaDesativaLoadingCarregandoMaisAcoesChamado(dispatch, false);
            } else {
              setTimeout(() => {
                ativaDesativaLoadingCarregandoMaisAcoesChamadoAcoesChamado(dispatch, false);
              }, 500);
            }
          })
      }
    }
  }
}
export const listaConversasAntigasResponderAcoes = (keyChamado, idMensagem, conversasAnteriores, rota, keyUltimaMensagem, keyMensagemResponder) => {
  return dispatch => {

    ativaDesativaLoadingCarregandoMaisAcoesChamadoAcoesChamado(dispatch, true);
    /*   ativaDesativaLoadingCarregandoMaisAcoesChamado(dispatch, true);
      ativaDesativaLoadingCarregaChamadosAntigosEmEspera(dispatch, true);
      ativaDesativaLoadingCarregaChamadosAntigos(dispatch, true);
      ativaDesativaLoadingCarregaMaisChamadosAntigos(dispatch, true); */


    AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");

      firebase.database().ref(`${chaveUnica}/acoesChamados/${keyChamado}`).orderByKey().startAt(keyMensagemResponder).endAt(keyUltimaMensagem).once('value').then((snapshot2) => {
        /*  */
        let arrayAcoesAtendimento = [];
        if (snapshot2.val()) {
          ativaDesativaLoadingCarregaRespostasAntigasAcoesChamado(dispatch, true);
          snapshot2.forEach(childItem => {
            /*    console.log(childItem.val()) */
            if (childItem.val().tipo !== undefined) {
              arrayAcoesAtendimento.push({
                key: childItem.key,
                title: childItem.val().titulo,
                tipo: childItem.val().tipo,
                description: childItem.val().mensagem,
                statusMessage: childItem.val().statusMessage == undefined ? 'sent' : childItem.val().statusMessage,
                time: childItem.val().time,
                dataHoraAtual: childItem.val().dataHoraAtual == undefined ? childItem.val().mensagem : childItem.val().dataHoraAtual,
                lineColor: childItem.val().lineColor,
                circleColor: childItem.val().circleColor,
                urlDownload: childItem.val().urlDownload,
                tipoArquivo: childItem.val().tipoArquivo,
                duracao: childItem.val().duracao == undefined ? '' : childItem.val().duracao,
                audio: childItem.val().audio == undefined ? false : childItem.val().audio,
                visivelATodos: childItem.val().visivelATodos == undefined ? 1 : childItem.val().visivelATodos,
                idMensagem: childItem.val().idMensagem == undefined ? childItem.key : childItem.val().idMensagem === '' ? childItem.key : childItem.val().idMensagem,
                circleColorResponder: childItem.val().circleColorResponder == undefined ? '#00FF7F' : childItem.val().circleColorResponder,
                commentResponderType: childItem.val().commentResponderType == undefined ? '' : childItem.val().commentResponderType,
                idMensagemResponder: childItem.val().idMensagemResponder == undefined ? '' : childItem.val().idMensagemResponder,
                mensagemResposta: childItem.val().mensagemResposta == undefined ? '' : childItem.val().mensagemResposta,
                possuiResposta: childItem.val().possuiResposta == undefined ? false : childItem.val().possuiResposta,
                commentResponderTypeArquivo: childItem.val().commentResponderTypeArquivo == undefined ? '' : childItem.val().commentResponderTypeArquivo,
                commentResponderIsAudio: childItem.val().commentResponderIsAudio == undefined ? '' : childItem.val().commentResponderIsAudio,
                atendenteResponder: childItem.val().atendenteResponder == undefined ? '' : childItem.val().atendenteResponder,
                captionArquivo: childItem.val().captionArquivo == undefined ? '' : childItem.val().captionArquivo,
                read: childItem.val().read == undefined ? false : childItem.val().read,
                deleteMessage: childItem.val().deleteMessage == undefined ? false : childItem.val().deleteMessage,
                edited: childItem.val().edited ?? false,
              });
            }
          });
          /*  if (verificaSeColocoInformacoesFinaisAtendimento) {
             arrayAcoesAtendimento.push({
               key: keyAtendimento + 'Fim',
               title: `Fim do Atendimento: #${numeroChamado} - ${dataFechamentoChamado}`,
               tipo: 'mensagem',
               description: `Fim do Atendimento: #${numeroChamado} - ${dataFechamentoChamado}`,
               statusMessage: 'sent',
               time: "09:50",
               dataHoraAtual: '01/01/2099',
               lineColor: '#00FF7F',
               circleColor: '#00FF7F',
               visivelATodos: 1,
             });

           } else {
             arrayAcoesAtendimento.push({
               key: keyAtendimento + 'Andamento',
               title: `Conversa em Andamento: #${numeroChamado}`,
               tipo: 'mensagem',
               description: `Conversa em Andamento: #${numeroChamado}`,
               statusMessage: 'sent',
               time: "09:50",
               dataHoraAtual: '01/01/2099',
               lineColor: '#00FF7F',
               circleColor: '#00FF7F',
               visivelATodos: 1,
             });
           } */


          let arrayConversas = arrayAcoesAtendimento.reverse().slice(1);
          let referenceToOldestKey = arrayConversas[arrayConversas.length - 1].key;

          enviaReferenciaultimaKeyAcoesChamadoAcoesChamado(dispatch, referenceToOldestKey)
          enviaConversasAntigasChatAcoesChamado(dispatch, arrayConversas);
          enviaIdRespostaAntigaAcoesChamado(dispatch, idMensagem);
          ativaDesativaLoadingCarregandoMaisAcoesChamadoAcoesChamado(dispatch, false);
          ativaDesativaLoadingCarregaRespostasAntigasAcoesChamado(dispatch, false);
        }
      });

    })



  }
};
//metodo de quando clica no chamado nas abas de chamado aberto, fechado, em atendimento
export const navegaTelaALterarChamado = (keyChamado, numeroAtendimento, history) => {
  return dispatch => {
    //

    dispatch({ type: ATIVADESATIVALOADINGTELAACOESATENDIMENTO, payload: true });
    dispatch({ type: ALTERANUMEROATENDIMENTO, payload: numeroAtendimento });
    dispatch({ type: ALTERAKEYCHAMADO, payload: keyChamado });

    /*  dispatch({ type: MODIFICAFILTROSELECIONADOTELACHAMADOABERTO, payload: '' });
     dispatch({ type: MODIFICAFILTROSELECIONADOTELACHAMADOEMATENDIMENTO, payload: '' });
     dispatch({ type: MODIFICAFILTROSELECIONADOTELACHAMADOFECHADO, payload: '' });
     dispatch({ type: MODIFICAFILTROSELECIONADOTELACHAMADOCANCELADO, payload: '' });
     dispatch({ type: MODIFICAFILTROSELECIONADOTELACHAMADOTODOS, payload: '' });
     dispatch({ type: MODIFICAFILTROCHAMADO, payload: '' });
     dispatch({ type: MODIFICASELECTTEXTCHAMADO, payload: '' }); */



    modificaNomeRotaNoHeader(dispatch, `Atendimento #${numeroAtendimento}`)
    history.push("/visualizaatendimento")
    // window.location = "/visualizaatendimento";
  };
};
export const verificaSeJaTeMCodigoCadastraCliente = () => {
  return dispatch => {
    AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");
      ativaDesativaLoadingNumeroExclusivoCadastraCliente(dispatch, true);
      firebase
        .database()
        .ref(`codigoCliente`)
        .orderByChild("chaveUnica")
        .equalTo(chaveUnica)
        .once("value")
        .then(snapshot => {
          if (snapshot.val()) {
            let arrayNumeroExclusivo = [];
            snapshot.forEach(childItem => {
              arrayNumeroExclusivo.push({
                numeroExclusivoCadastraCliente: childItem.val()
                  .numeroExclusivoCadastraCliente
              });
            });
            modificaNumeroExclusivoCadastraCliente(
              dispatch,
              arrayNumeroExclusivo[0].numeroExclusivoCadastraCliente
            );
            ativaDesativaLoadingNumeroExclusivoCadastraCliente(dispatch, false);
          } else {
            let numeroExclusivoCadastraCliente = Math.floor(
              Date.now() * Math.random()
            );
            firebase
              .database()
              .ref(`codigoCliente`)
              .push({
                numeroExclusivoCadastraCliente: numeroExclusivoCadastraCliente,
                chaveUnica: chaveUnica
              })
              .then(() => {
                modificaNumeroExclusivoCadastraCliente(
                  dispatch,
                  numeroExclusivoCadastraCliente
                );
                ativaDesativaLoadingNumeroExclusivoCadastraCliente(
                  dispatch,
                  false
                );
              }).catch((e) => {
                toastr.error('Error', 'Erro ao se autenticar');
              });
          }
        }).catch((e) => {
          toastr.error('Error', 'Erro ao se autenticar');
        });
    });
  };
};

export const modificaVisibilidadeModalAlterarChat = status => {
  return {
    type: MODIFICAVISIBILIDADEMODALALTERARCHAT,
    payload: status
  };
};
export const modificaProblemaEstaticoSelecionadoNovoAtendimento = status => {
  return {
    type: MODIFICAPROBLEMAESTATICOSELECIONADONOVOATENDIMENTO,
    payload: status
  };
};
export const modificaNomeProblemaEstaticoSelecionadoNovoAtendimento = status => {
  return {
    type: MODIFICANOMEPROBLEMAESTATICOSELECIONADONOVOATENDIMENTO,
    payload: status
  };
};


const ativaDesativaLoadingCarregaRespostasAntigasAcoesChamado = (dispatch, value) => {
  dispatch({
    type: ATIVADESATIVALOADINGCARREGARESPOSTAANTIGASACOESCHAMADO,
    payload: value
  });
};
const enviaProblemasParaReducer = (dispatch, valor) => {
  dispatch({
    type: ENVIAPROBLEMAPARAREDUCER,
    payload: valor
  });
};

const ativaDesativaLoadingCarregandoProblemas = (dispatch, valor) => {
  dispatch({
    type: ATIVADESATIVALOADINGCARREGANDOPROBLEMAS,
    payload: valor
  });
};
const enviaIdRespostaAntigaAcoesChamado = (dispatch, id) => {
  dispatch({
    type: ENVIAIDRESPOSTAANTIGAACOESCHAMADO,
    payload: id
  });
};
const enviaConversasAntigasChatAcoesChamado = (dispatch, valor) => {
  dispatch({
    type: ENVIACONVERSASANTIGASCHATACOESCHAMADO,
    payload: valor
  });
};
export const enviaConversasAntigasChatExportAcoesChamado = (valor) => {
  return {
    type: ENVIACONVERSASANTIGASCHATACOESCHAMADO,
    payload: valor
  };
};
const ativaDesativaLoadingCarregandoMaisAcoesChamadoAcoesChamado = (dispatch, value) => {
  dispatch({
    type: ATIVADESATIVALOADINGCARREGANDOMAISACOESCHAMADOACOESCHAMADO,
    payload: value
  })
};
export const enviaReferenciaultimaKeyAcoesChamadoExportAcoesChamado = status => {
  return {
    type: ENVIAREFERENCIAULTIMAKEYACOESCHAMADOACOESCHAMADO,
    payload: status
  };
};
const enviaReferenciaultimaKeyAcoesChamadoAcoesChamado = (dispatch, value) => {
  dispatch({
    type: ENVIAREFERENCIAULTIMAKEYACOESCHAMADOACOESCHAMADO,
    payload: value
  })
};
//daqui pra cima
const enviaClienteParaReducerNovoAviso = (dispatch, arrayCliente) => {
  dispatch({
    type: ENVIACLIENTEPARAREDUCERNOVOAVISO,
    payload: arrayCliente
  });
};
const ativaDesativaLoadingCarregarClienteTelaNovoAviso = (dispatch, valor) => {
  dispatch({
    type: ATIVADESATIVALOADINGCARREGARCLIENTETELANOVOAVISO,
    payload: valor
  });
};
export const modificaKeyAtendimento = (value) => {
  return {
    type: ALTERAKEYCHAMADO,
    payload: value
  };
};
export const modificaVisibilidadeModalPortalCliente = (value) => {
  return {
    type: MODIFICAVISIBILIDADEMODALPORTALCLIENTE,
    payload: value
  };
};
export const modificaVisibilidadeModalImportarClientes = (value) => {
  return {
    type: MODIFICAVISIBILIDADEMODALIMPORTARCLIENTES,
    payload: value
  };
};
export const modificaVisibilidadeModalNovoAtendimento = (value) => {

  return dispatch => {
    dispatch({ type: MODIFICAVISIBILIDADEMODALNOVOATENDIMENTO, payload: value });
    zeraCadastroChamado(dispatch);
  };
};
export const modificaVisibilidadeModalAlterarAtendimento = (value) => {

  return dispatch => {
    dispatch({ type: MODIFICAVISIBILIDADEMODALALTERARATENDIMENTO, payload: value });
    /*   setTimeout(() => {
        dispatch({ type: VISIBLESELECTSEARCH, payload: value });
    }, 100); */
    //dispatch({ type: ALTERAKEYCHAMADO, payload: keyChamado });
    //window.location = "/atendimentos"
  };
};
export const modificaVisibilidadeModalAtribuirAtendimento = (value) => {

  return dispatch => {
    dispatch({ type: MODIFICAVISIBILIDADEMODALATRIBUIRATENDIMENTO, payload: value });
  };
};
export const modificaVisibilidadeModalAtendimentoOnline = (value) => {
  return {
    type: MODIFICAVISIBILIDADEMODALATENDIMENTOONLINE,
    payload: value
  };
};
export const modificaNomeClienteTelaChatOnline = (value) => {
  return {
    type: MODIFICANOMECLIENTETELACHATONLINE,
    payload: value
  };
};

export const modificaVisibilidadeModalAddTempoGastoAtendimento = (value) => {

  return dispatch => {
    dispatch({ type: MODIFICAVISIBILIDADEMODALADDTEMPOGASTOATENDIMENTO, payload: value });
  };
};
export const modificaVisibilidadeModalFinalizarAtendimento = (value) => {

  return dispatch => {
    dispatch({ type: MODIFICAVISIBILIDADEMODALFINALIZARATENDIMENTO, payload: value });
  };
};
export const modificaVisibilidadeModalPrioridadeAtendimento = (value) => {

  return dispatch => {
    dispatch({ type: MODIFICAVISIBILIDADEMODALPRIORIDADEATENDIMENTO, payload: value });
  };
};
export const modificaVisibilidadeModalCancelarAtendimento = (value) => {

  return dispatch => {
    dispatch({ type: MODIFICAVISIBILIDADEMODALCANCELARATENDIMENTO, payload: value });
  };
};
export const teste = () => {
  return dispatch => {
    toastr.error('Sucesso', 'Usuario logado')
    //dispatch({ type: ALTERANUMEROATENDIMENTO, payload: numeroAtendimento });
    //dispatch({ type: ALTERAKEYCHAMADO, payload: keyChamado });
    //window.location = "/dashboard"
  };
};
const modificaChamadosEmAtendimentoViaChat = (dispatch, array) => {
  dispatch({
    type: MODIFICACHAMADOSEMATENDIMENTOVIACHAT,
    payload: array
  })
};
export const modificaChamadosEmAtendimentoViaChatExport = (array) => {
  return {
    type: MODIFICACHAMADOSEMATENDIMENTOVIACHAT,
    payload: array
  }
};

const modificaNomeRotaNoHeader = (dispatch, nome) => {
  return dispatch({
    type: MODIFICANOMEROTANOHEADER,
    payload: nome
  })
};
export const modificavisibilidadeModalComentario = status => {
  return {
    type: MODIFICAVISIBILIDADEMODALCOMENTARIo,
    payload: status
  };
};
export const modificaKeyAtendimentoEmEsperaAtendimentoOnline = key => {
  return {
    type: MODIFICAKEYATENDIMENTOEMESPERAATENDIMENTOONLINE,
    payload: key
  };
};
export const modificaKeyAtendimentoEmAtendimentoOnline = key => {
  return {
    type: MODIFICAKEYATENDIMENTOEMTENDIMENTOONLINE,
    payload: key
  };
};
export const modificaKeyContacts = key => {
  return {
    type: MODIFICAKEYCONTACTS,
    payload: key
  };
};
export const modificaDadosContactsSelecionado = dados => {
  return {
    type: MODIFICADADOSCONTACTSSELECIONADO,
    payload: dados
  };
};
const enviaAcoesAtendimentoParaReducerChat = (dispatch, array) => {
  dispatch({
    type: ENVIAACOESATENDIMENTOPARAREDUCERCHAT,
    payload: array
  });
};
const enviaConversasParaChatEmEspera = (dispatch, array) => {
  dispatch({
    type: ENVIACONVERSASPARACHATEMESPERA,
    payload: array
  });
};
const enviaAtendimentoParaReducerChat = (dispatch, arrayAtendimento) => {
  dispatch({
    type: ENVIAATENDIMENTOPARAREDUCERCHAT,
    payload: arrayAtendimento
  });
};
export const modificaAtendimentoEmEsperaSelecionado = array => {
  return {
    type: MODIFICAATENDIMENTOEMESPERASELEECIONADO,
    payload: array
  };
};
export const modificaInputComentario = texto => {
  //
  //
  return {
    type: MODIFICAINPUTCOMENTARIO,
    payload: texto
  };
};
export const modificaComentarioPublicoSimOuNao = valor => {
  return {
    type: MODIFICACOMENTARIOPUBLICOSIMOUNAO,
    payload: valor
  };
};


//metodo ao clicar em alterar chamado na tela acoes atendimento
export const navegaTelaEditarChamado = () => {
  return dispatch => {
    zeraCadastroChamado(dispatch);
    // dispatch(NavigationActions.navigate({ routeName: "AlterandoChamado" }));
  };
};
export const navegaTelaNovoChamado = () => {
  return dispatch => {
    zeraCadastroChamado(dispatch);
    //  dispatch(NavigationActions.navigate({ routeName: "TelaAberturaChamado" }));
  };
};

export const modificaNomeClienteSelecionadoTelaCadastroCliente = nome => {
  return {
    type: MODIFICANOMECLIENTESELECIONADOTELACADASTROCLIENTE,
    payload: nome
  };
};
export const modificaNomeClienteSelecionadoTelaAlterarCliente = nome => {
  return {
    type: MODIFICANOMECLIENTESELECIONADOTELAALTERARCLIENTE,
    payload: nome
  };
};
export const modificaNomeClienteSelecionadoTelaAlterarClienteNovoCadastro = nome => {
  return {
    type: MODIFICANOMECLIENTESELECIONADOTELAALTERARCLIENTENOVOCADASTRO,
    payload: nome
  };
};

//metodo de quando clica no chamado nas abas de chamado aberto, fechado, em atendimento
/* export const navegaTelaALterarChamado = (keyChamado, numeroAtendimento) => {
  return dispatch => {
    dispatch({ type: ALTERANUMEROATENDIMENTO, payload: numeroAtendimento });
    dispatch({ type: ALTERAKEYCHAMADO, payload: keyChamado });
    dispatch(NavigationActions.navigate({ routeName: "AcoesAtendimento" }));
  };
}; */
//metodo de quando clica na notificacao pra entrar no chamado



export const navegaTelaAlterarCliente = (
  uriFoto,
  empresaCliente,
  nomeCliente,
  email,
  permissaoApp,
  senha,
  confirmaSenha,
  key,
  clienteSelecionadoConsultaClienteTelaCAdastraCliente,
  clienteEmpresaPrincipal,
  telefoneCliente,
  enderecoCliente
) => {

  let keyClienteVinculado;
  if (
    clienteSelecionadoConsultaClienteTelaCAdastraCliente == undefined ||
    clienteSelecionadoConsultaClienteTelaCAdastraCliente == "Não Vinculado"
  ) {
    keyClienteVinculado = [];
  } else {
    keyClienteVinculado = clienteSelecionadoConsultaClienteTelaCAdastraCliente;
  }
  // /keyClienteVinculado = (clienteSelecionadoConsultaClienteTelaCAdastraCliente == undefined) ? [] : clienteSelecionadoConsultaClienteTelaCAdastraCliente;
  /*

    */
  /*   if (keyClienteVinculado.length !== 0) {
      keyClienteVinculado = keyClienteVinculado;
    } */
  return dispatch => {
    dispatch({
      type: ALTERAEMPRESACLIENTETELAALTERAR,
      payload: empresaCliente
    });
    dispatch({ type: ALTERANOMECLIENTETELAALTERAR, payload: nomeCliente });
    dispatch({ type: ALTERAREMAILCLIENTETELAALTERAR, payload: email });
    dispatch({
      type: ALTERARPERMISSAOCLIENTETELAALTERAR,
      payload: permissaoApp
    });
    dispatch({ type: ALTERASENHACLIENTETELAALTERAR, payload: senha });
    dispatch({
      type: ALTERACONFIRMASENHACLIENTETELAALTERAR,
      payload: confirmaSenha
    });
    dispatch({ type: ALTERAFOTOCLIENTETELAALTERAR, payload: uriFoto });
    dispatch({ type: ALTERAKEYCLIENTETELAALTERAR, payload: key });
    dispatch({ type: ZERAMENSAGEMDESUCESSOEERRO, payload: true });
    dispatch({ type: MODIFICAENDERECOTELACADASTROCLIENTE, payload: enderecoCliente });
    dispatch({ type: MODIFICATELEFONETELACADASTROCLIENTE, payload: telefoneCliente });

    /*
    */
    dispatch({
      type: ALTERAEMPRESASELECIONADOTELAALTERARCLIENTE,
      payload: keyClienteVinculado
    });
    dispatch({
      type: MODIFICANOMECLIENTESELECIONADOTELAALTERARCLIENTE,
      payload: empresaCliente
    });

    dispatch({
      type: ENVIAEMPRESAPRINCIPALCLIENTE,
      payload: clienteEmpresaPrincipal
    });

    //dispatch(NavigationActions.navigate({ routeName: "AlterandoCliente" }));
  };
};
const enviaDadosClienteTelaAlterar = (
  uriFoto,
  empresaCliente,
  nomeCliente,
  email,
  permissaoApp,
  senha,
  confirmaSenha,
  key,
  clienteSelecionadoConsultaClienteTelaCAdastraCliente,
  clienteEmpresaPrincipal,
  telefoneCliente,
  enderecoCliente
) => {
  /*
   console.log(uriFoto,
     empresaCliente,
     nomeCliente,
     email,
     permissaoApp,
     senha,
     confirmaSenha,
     key,
     clienteSelecionadoConsultaClienteTelaCAdastraCliente,
     clienteEmpresaPrincipal,
     telefoneCliente,
     enderecoCliente); */
  let keyClienteVinculado;
  if (
    clienteSelecionadoConsultaClienteTelaCAdastraCliente == undefined ||
    clienteSelecionadoConsultaClienteTelaCAdastraCliente == "Não Vinculado"
  ) {
    keyClienteVinculado = [];
  } else {
    keyClienteVinculado = clienteSelecionadoConsultaClienteTelaCAdastraCliente;
  }
  // /keyClienteVinculado = (clienteSelecionadoConsultaClienteTelaCAdastraCliente == undefined) ? [] : clienteSelecionadoConsultaClienteTelaCAdastraCliente;
  /*

   */
  if (keyClienteVinculado.length !== 0) {
    keyClienteVinculado = [keyClienteVinculado];
  }
  return dispatch => {
    dispatch({
      type: ALTERAEMPRESACLIENTETELAALTERAR,
      payload: empresaCliente
    });
    dispatch({ type: ALTERANOMECLIENTETELAALTERAR, payload: nomeCliente });
    dispatch({ type: ALTERAREMAILCLIENTETELAALTERAR, payload: email });
    dispatch({
      type: ALTERARPERMISSAOCLIENTETELAALTERAR,
      payload: permissaoApp
    });
    dispatch({ type: ALTERASENHACLIENTETELAALTERAR, payload: senha });
    dispatch({
      type: ALTERACONFIRMASENHACLIENTETELAALTERAR,
      payload: confirmaSenha
    });
    dispatch({ type: ALTERAFOTOCLIENTETELAALTERAR, payload: uriFoto });
    dispatch({ type: ALTERAKEYCLIENTETELAALTERAR, payload: key });
    dispatch({ type: ZERAMENSAGEMDESUCESSOEERRO, payload: true });
    dispatch({ type: MODIFICAENDERECOTELACADASTROCLIENTE, payload: enderecoCliente });
    dispatch({ type: MODIFICATELEFONETELACADASTROCLIENTE, payload: telefoneCliente });

    /*
     */
    dispatch({
      type: ALTERAEMPRESASELECIONADOTELAALTERARCLIENTE,
      payload: keyClienteVinculado
    });
    dispatch({
      type: MODIFICANOMECLIENTESELECIONADOTELAALTERARCLIENTE,
      payload: empresaCliente
    });

    dispatch({
      type: ENVIAEMPRESAPRINCIPALCLIENTE,
      payload: clienteEmpresaPrincipal
    });

    //dispatch(NavigationActions.navigate({ routeName: "AlterandoCliente" }));
  };
};
export const modificaNumeroAtendimento = numero => {
  return {
    type: MODIFICANOMEROTANOHEADER,
    payload: numero
  };
};
export const alteraClienteSelecionadoTelaCadastraCliente = key => {
  return {
    type: ALTERACLIENTESELECIONADOTELACADASTRACLIENTE,
    payload: key
  };
};
export const alteraEmpresaSelecionadoTelaAlterarCliente = key => {
  //
  return {
    type: ALTERAEMPRESASELECIONADOTELAALTERARCLIENTE,
    payload: key
  };
};
export const alteraEmpresaSelecionadoTelaAlterarClienteNovoCadastro = key => {
  //
  return {
    type: ALTERAEMPRESASELECIONADOTELAALTERARCLIENTENOVOCADASTRO,
    payload: key
  };
};
export const mudaVisibilidadeModalCadastraClienteVersaoPremium = visible => {
  return {
    type: MUDAVISIBILIDADEMODALCADASTRACLIENTEVERSAOPREMIUM,
    payload: visible
  };
};
export const modificaVisibleModalNovoCLiente = visible => {
  return {
    type: MODIFICAVISIBLEMODALNOVOCLIENTE,
    payload: visible
  };
};
export const mudaVisibilidadeModalExcluirCliente = visible => {
  return {
    type: MUDAVISIBILIDADEMODALEXCLUIRCLIENTE,
    payload: visible
  };
};
export const modificaValorArraySectionedFiltroChamado = value => {
  //
  return {
    type: MODIFICAVALORARRAYSECTIONEDFILTROCHAMADO,
    payload: value
  };
};
export const enviaArquivosParaFirebaseTelaAbrirChamado = resArquivo => {
  return dispatch => {
    enviaArquivoParaSerUpadoTelaAberturaChamado(dispatch, resArquivo);
  };
};
export const diminuiTamanhoTotalARquivosAnexados = tamanho => {
  return dispatch => {
    diminuiTamanhoTotalArquivosAnexadosLocal(dispatch, tamanho);
  };
};
export const ativaDesativaLoadingIniciandoCOnversaContactsExport = value => {
  return {
    type: ATIVADESATIVALOADINGINICIANDOCONVERSACONTACTS,
    payload: value
  };
};
const ativaDesativaLoadingIniciandoCOnversaContacts = (dispatch, valor) => {
  dispatch({
    type: ATIVADESATIVALOADINGINICIANDOCONVERSACONTACTS,
    payload: valor
  });
};

const enviaEmpresaPrincipalCliente = (dispatch, valor) => {
  dispatch({
    type: ENVIAEMPRESAPRINCIPALCLIENTE,
    payload: valor
  });
};
const ativaDesativaLoadingNumeroExclusivoCadastraCliente = (
  dispatch,
  valor
) => {
  dispatch({
    type: ATIVADESATIVALOADINGNUMEROEXCLUSIVOCADASTRACLIENTE,
    payload: valor
  });
};

const ativaDesativaLoadingBotaoExcluirCliente = (dispatch, valor) => {
  dispatch({
    type: ATIVADESATIVALOADINGBOTAOEXCLUIRCLIENTE,
    payload: valor
  });
};
const enviaAtendimentoEmEsperaAtendimentoOnline = (dispatch, valor) => {
  dispatch({
    type: ENVIAATENDIMENTOEMESPERATENDIMENTOONLINE,
    payload: valor
  });
};
export const enviaAtendimentoEmEsperaAtendimentoOnlineExport = (valor) => {
  return {
    type: ENVIAATENDIMENTOEMESPERATENDIMENTOONLINE,
    payload: valor
  };
};
const ativaDesativaLoadingTelaAlterarCliente = (dispatch, valor) => {
  dispatch({
    type: ATIVADESATIVALOADINGTELAALTERARCLIENTE,
    payload: valor
  });
};
const modificaNumeroExclusivoCadastraCliente = (dispatch, numero) => {
  dispatch({
    type: MODIFICANUMEROEXCLUSIVOCADASTRACLIENTE,
    payload: numero
  });
};

const diminuiTamanhoTotalArquivosAnexadosLocal = (dispatch, tamanho) => {
  dispatch({
    type: DIMINUITAMANHOTOTALARQUIVOSANEXADOSLOCAL,
    payload: tamanho
  });
};
const mudaVisibilidadeModalExcluirClienteLocal = (dispatch, visible) => {
  dispatch({
    type: MUDAVISIBILIDADEMODALEXCLUIRCLIENTE,
    payload: visible
  });
};
export const modificaFiltroChamado = value => {
  return {
    type: MODIFICAFILTROCHAMADO,
    payload: value
  };
};
export const modificaSelectTextChamado = value => {
  return {
    type: MODIFICASELECTTEXTCHAMADO,
    payload: value
  };
};
export const modificaPesquisaTextChamado = value => {
  return {
    type: MODIFICAPESQUISATEXTCHAMADO,
    payload: value
  };
};

export const modificaFiltroSelecionadoTelaChamadoAberto = cliente => {
  return {
    type: MODIFICAFILTROSELECIONADOTELACHAMADOABERTO,
    payload: cliente
  };
};
export const modificaFiltroSelecionadoTelaChamadoEmAtendimento = cliente => {
  return {
    type: MODIFICAFILTROSELECIONADOTELACHAMADOEMATENDIMENTO,
    payload: cliente
  };
};
export const modificaFiltroSelecionadoTelaChamadoFechado = cliente => {
  return {
    type: MODIFICAFILTROSELECIONADOTELACHAMADOFECHADO,
    payload: cliente
  };
};
export const modificaFiltroSelecionadoTelaChamadoCancelado = cliente => {
  return {
    type: MODIFICAFILTROSELECIONADOTELACHAMADOCANCELADO,
    payload: cliente
  };
};
export const modificaFiltroSelecionadoTelaChamadoTodos = cliente => {
  return {
    type: MODIFICAFILTROSELECIONADOTELACHAMADOTODOS,
    payload: cliente
  };
};

export const modificaClienteSelecionadoTelaChamadoTodos = cliente => {
  return {
    type: MODIFICACLIENTESELECIONADOTELACHAMADOTODOS,
    payload: cliente
  };
};
export const modificaTabIndex = indeex => {
  localStorage.setItem('tabindex', JSON.stringify(indeex));
  return {
    type: MODIFICATABINDEX,
    payload: indeex
  };
};

export const modificaClienteSelecionadoTelaConsultaCliente = cliente => {
  return {
    type: MODIFICACLIENTESELECIONADOTELACONSULTACLIENTE,
    payload: cliente
  };
};
export const modificaEmpresaSelecionadaAoAbrirChamado = empresa => {
  return {
    type: MODIFICAEMPRESASELECIONADAAOABRIRCHAMADO,
    payload: empresa
  };
};
export const modificaKeyEmpresaVinculadaSelecionadaAoAbrirChamado = empresa => {
  return {
    type: MODIFICAKEYEMPRESAVINCULADASELECIONADAAOABRIRCHAMADO,
    payload: empresa
  };
};

export const enviaTamanhoArquivoAnexadoTelAberturaChamado = tamanho => {
  return {
    type: ENVIATAMANHOARQUIVOANEXADOTELAABERTURACHAMADO,
    payload: tamanho
  };
};
export const zeraClienteSelecionadoChamadoTodos = cliente => {
  return {
    type: MODIFICACLIENTESELECIONADOTELACHAMADOTODOSTESTE,
    payload: cliente
  };
};
export const modificaModalFiltroChamado = value => {
  return {
    type: MODIFICAMODALFILTROCHAMADO,
    payload: value
  };
};

export const modificaNomeClienteSelecionadoTelaChamadoTodos = cliente => {
  return {
    type: MODIFICANOMECLIENTESELECIONADOTELACHAMADOTODOS,
    payload: cliente
  };
};
export const modificaDataVencimentoTelaChamadoAbertura = data => {
  return {
    type: MODIFICADATAVENCIMENTOTELACHAMADOABERTURA,
    payload: data
  };
};
export const modificaFiltroClientesTelaClientes = data => {
  return {
    type: MODIFICAFILTROCLIENTESTELACLIENTES,
    payload: data
  };
};
export const modificaNomeClienteSelecionadoTelaConsultaCliente = cliente => {
  return {
    type: MODIFICANOMECLIENTESELECIONADOTELACONSULTACLIENTE,
    payload: cliente
  };
};

export const modificaClienteSelecionado = cliente => {
  return {
    type: MODIFICACLIENTESELECIONADO,
    payload: cliente
  };
};
export const modificaNomeClienteSelecionado = cliente => {
  return {
    type: MODIFICANOMECLIENTESELECIONADO,
    payload: cliente
  };
};
export const modificaNomeAtendenteSelecionado = atendente => {
  return {
    type: MODIFICANOMEATENDENTESELECIONADO,
    payload: atendente
  };
};
export const modificaNomeSetorSelecionado = atendente => {
  return {
    type: MODIFICANOMESETORSELECIONADO,
    payload: atendente
  };
};

export const modificaNomeArquivo1 = arquivo1 => {
  return {
    type: MODIFICANOMEARQUIVO1,
    payload: arquivo1
  };
};
export const modificaNomeArquivo2 = arquivo2 => {
  return {
    type: MODIFICANOMEARQUIVO2,
    payload: arquivo2
  };
};

export const desativaLoadingTelaChamadoAberto = dispatch => {
  dispatch({
    type: ATIVALOADINGTELACHAMADOABERTO,
    payload: false
  });
};

/* const verificaSeEhObrigatorioATribuirAtendente = (dispatch, valor) => {
  dispatch({
    type: VERIFICARSEEHOBRIGATORIOATRIBUIRATENDENTE,
    payload: valor
  });
}; */

const enviaArquivosUpadosAnexadoAoChamado = (dispatch, array) => {
  dispatch({
    type: ENVIARQUIVOSUPADOSANEXADOSAOCHAMADO,
    payload: array
  });
};

const ativaDesatidaLoadingArquivosUpadosAnexadoAoChamado = (
  dispatch,
  valor
) => {
  dispatch({
    type: ATIVADESATIVALOADINGARQUIVOSUPADOSANEXADOSAOCHAMADO,
    payload: valor
  });
};
export const ativaLoadingTelaAberturaChamadoRemoveFiltro = value => {
  return {
    type: ATIVALOADINGTELACHAMADOABERTO,
    payload: value
  };
};

export const ativaLoadingTelaAberturaChamado = dispatch => {
  dispatch({
    type: ATIVALOADINGTELACHAMADOABERTO,
    payload: true
  });
};
const ativaDesativaLoadingFinalFlatListChamadoAberto = (dispatch, value) => {
  dispatch({
    type: ATIVADESATIVALOADINGFINALFLATLISTCHAMADOABERTO,
    payload: value
  });
};
export const ativaDesativaLoadingFinalFlatListChamadoAbertoExport = status => {
  return {
    type: ATIVADESATIVALOADINGFINALFLATLISTCHAMADOABERTO,
    payload: status
  };
};
export const modificavisibilidadeModalCancelar = status => {
  return {
    type: MODIFICAVISIBILIDADEMODALCANCELAR,
    payload: status
  };
};

export const zeraMensagemSemConexaoInternetSalvarComentario = msg => {
  return {
    type: ZERAMENSAGEMSEMCONEXAOINTERNETSALVARCOMENTARIO,
    payload: msg
  };
};
const zeraMensagemSemConexaoInternetSalvarComentarioLocal = (dispatch, msg) => {
  dispatch({
    type: ZERAMENSAGEMSEMCONEXAOINTERNETSALVARCOMENTARIO,
    payload: msg
  });
};
const zeraInformacoesComentario = dispatch => {
  dispatch({
    type: ZERAINFORMACOESCOMENTARIO,
    payload: true
  });
};
const zeraInformacoesEnvioArquivoChat = dispatch => {
  dispatch({
    type: ZERAINFORMACOESENVIOARQUIVOCHAT,
    payload: true
  });
};
const enviaArquivoParaSerUpadoTelaAberturaChamado = (dispatch, res) => {
  dispatch({
    type: ENVIAARQUIVOPARASERUPADOTELAABERTURACHAMADO,
    payload: res
  });
};
export const enviaArquivosParaArrayDeArquivosAnexados = array => {
  return dispatch => {
    enviaArquivoParaSerUpadoTelaAberturaChamado(dispatch, array);
  };
};

const modificavisibilidadeModalCancelarLocal = (dispatch, status) => {
  dispatch({
    type: MODIFICAVISIBILIDADEMODALCANCELAR,
    payload: status
  });
};

/* export const modificavisibilidadeModalComentario = status => {
  return {
    type: MODIFICAVISIBILIDADEMODALCOMENTARIo,
    payload: status
  };
}; */
const modificavisibilidadeModalComentarioLocal = (dispatch, status) => {
  dispatch({
    type: MODIFICAVISIBILIDADEMODALCOMENTARIo,
    payload: status
  });
};
const modificaEnviandoComentario = (dispatch, valor) => {
  dispatch({
    type: MODIFICAUPANDOARQUIVOCHAT,
    payload: valor
  });
};
const modificaMensagemErroMotivoCancelamento = (dispatch, error) => {
  dispatch({
    type: MODIFICAMENSAGEMERRORMOTIVOCANCELAMENTO,
    payload: error
  });
};

const enviaArquivosUpadosTelaAcoesChamados1 = (dispatch, array) => {
  dispatch({
    type: ENVIAARQUIVOSUPADOSTELAACOESCHAMADOS,
    payload: array
  });
};
const enviaArquivosUpadosTelaAcoesChamados2 = (dispatch, array) => {
  dispatch({
    type: ENVIAARQUIVOSUPADOSTELAACOESCHAMADOS2,
    payload: array
  });
};
const enviaArquivosUpadosTelaAcoesChamados3 = (dispatch, array) => {
  dispatch({
    type: ENVIAARQUIVOSUPADOSTELAACOESCHAMADOS3,
    payload: array
  });
};

const ativaDesativaLoadingRefreshControlConsultaCliente = (dispatch, value) => {
  dispatch({
    type: ATIVADESATIVALOADINGREFRESHCONTROLCONSULTACLIENTE,
    payload: value
  });
};

const ativaDesativaLoadingFinalFlatListChamadoEmAtendimento = (
  dispatch,
  value
) => {
  dispatch({
    type: ATIVADESATIVALOADINGFINALFLATLISTCHAMADOEMATENDIMENTO,
    payload: value
  });
};
const ativaDesativaLoadingFinalFlatListChamadoFechado = (
  dispatch,
  value
) => {
  dispatch({
    type: ATIVADESATIVALOADINGFINALFLATLISTCHAMADOFECHADO,
    payload: value
  });
};
const ativaDesativaLoadingFinalFlatListChamadoCancelado = (
  dispatch,
  value
) => {
  dispatch({
    type: ATIVADESATIVALOADINGFINALFLATLISTCHAMADOCANCELADO,
    payload: value
  });
};
const ativaDesativaLoadingFinalFlatListChamadoTodos = (
  dispatch,
  value
) => {
  dispatch({
    type: ATIVADESATIVALOADINGFINALFLATLISTCHAMADOTODOS,
    payload: value
  });
};


export const ativaDesativaLoadingFinalFlatListTelaConsultaCliente = (
  dispatch,
  value
) => {
  dispatch({
    type: ATIVADESATIVALOADINGFINALFLATLISTTELACONSULTACLIENTE,
    payload: value
  });
};

export const ativaDesativaSwitchComentario = value => {
  return {
    type: ATIVADESATIVASWITCHCOMENTARIO,
    payload: value
  };
};
export const ativaDesativaLoadingFinalFlatListChamadoAtendimentoExport = value => {
  return {
    type: ATIVADESATIVALOADINGFINALFLATLISTCHAMADOEMATENDIMENTO,
    payload: value
  };
};
export const ativaDesativaLoadingFinalFlatListChamadoFechadoExport = value => {
  return {
    type: ATIVADESATIVALOADINGFINALFLATLISTCHAMADOFECHADO,
    payload: value
  };
};
export const ativaDesativaLoadingFinalFlatListChamadoCanceladoExport = value => {
  return {
    type: ATIVADESATIVALOADINGFINALFLATLISTCHAMADOCANCELADO,
    payload: value
  };
};
export const ativaDesativaLoadingFinalFlatListChamadoTodosExport = value => {
  return {
    type: ATIVADESATIVALOADINGFINALFLATLISTCHAMADOTODOS,
    payload: value
  };
};

const mensagemChamadoAlteradoComSucesso = msg => {
  return {
    type: VERIFICACHAMADOALTERADOSUCESSO,
    payload: msg
  };
};

const indicaFimDalistaTodos = (dispatch, value) => {
  dispatch({
    type: INDICAFIMDALISTA,
    payload: value
  });
};
export const ativaDesativaLoadingTelaChamadoAtendimentoRemoveFiltro = (value) => {
  return {
    type: ATIVADESATIVALOADINGTELACHAMADOATENDIMENTO,
    payload: value
  };
};

const ativaDesativaLoadingTelaChamadoAtendimento = (dispatch, value) => {
  dispatch({
    type: ATIVADESATIVALOADINGTELACHAMADOATENDIMENTO,
    payload: value
  });
};
const ativaDesativaLoadingTelaChamadoFechado = (dispatch, value) => {
  dispatch({
    type: ATIVADESATIVALOADINGTELACHAMADOFECHADO,
    payload: value
  });
};
export const ativaDesativaLoadingTelaChamadoFechadoRemoveFiltro = (value) => {
  return {
    type: ATIVADESATIVALOADINGTELACHAMADOFECHADO,
    payload: value
  };
};
const ativaDesativaLoadingTeste = (dispatch, value) => {
  dispatch({
    type: ATIVADESATIVALOADINTESTETSTE,
    payload: value
  });
};
const ativaDesativaLoadingTelaChamadoCancelado = (dispatch, value) => {
  dispatch({
    type: ATIVADESATIVALOADINGTELACHAMADOCANCELADO,
    payload: value
  });
};
export const ativaDesativaLoadingTelaChamadoCanceladoRemoveFIltro = (value) => {
  return {
    type: ATIVADESATIVALOADINGTELACHAMADOCANCELADO,
    payload: value
  };
};
export const ativaDesativaLoadingTelaChamadoTodosRemoveFiltro = (value) => {
  return {
    type: ATIVADESATIVALOADINGTELACHAMADOTODOS,
    payload: value
  }
};
const ativaDesativaLoadingTelaChamadoTodos = (dispatch, value) => {
  dispatch({
    type: ATIVADESATIVALOADINGTELACHAMADOTODOS,
    payload: value
  });
};
export const ativaDesativaLoadingTelaConsultaCliente = (dispatch, value) => {
  dispatch({
    type: ATIVADESATIVALOADINGTELACONSULTACLIENTE,
    payload: value
  });
};
const ativaDesativaLoadingTelaAcoesAtendimento = (dispatch, value) => {
  dispatch({
    type: ATIVADESATIVALOADINGTELAACOESATENDIMENTO,
    payload: value
  });
};
export const ativaDesativaLoadingTelaAcoesAtendimentoExport = (value) => {
  return {
    type: ATIVADESATIVALOADINGTELAACOESATENDIMENTO,
    payload: value
  };
};
export const modificaNomeArquivoComentarioTelaAcoesAtendimento = arquivo => {
  return {
    type: MODIFICANOMEARQUIVOCOMENTARIOTELAACOESATENDIMENTO,
    payload: arquivo
  };
};
export const modificaResArquivoComentarioTelaAcoesAtendimento = res => {
  return {
    type: MODIFICARESARQUIVOCOMENTARIOTELAACOESATENDIMENTO,
    payload: res
  };
};

export const modificaNomeArquivo3 = arquivo3 => {
  return {
    type: MODIFICANOMEARQUIVO3,
    payload: arquivo3
  };
};

export const modificaResArquivo1 = resarquivo1 => {
  return {
    type: MODIFICARESARQUIVO1,
    payload: resarquivo1
  };
};
export const modificaResArquivo2 = resarquivo2 => {
  return {
    type: MODIFICARESARQUIVO2,
    payload: resarquivo2
  };
};
export const modificaResArquivo3 = resarquivo3 => {
  return {
    type: MODIFICARESARQUIVO3,
    payload: resarquivo3
  };
};

export const modificaLocalSelecionado = local => {
  return {
    type: MODIFICALOCALSELECIONADO,
    payload: local
  };
};
export const modificaSetorSelecionado = setor => {
  return {
    type: MODIFICASETORSELECIONADO,
    payload: setor
  };
};
export const modificaPrioridadeSelecionado = prioridade => {
  return {
    type: MODIFICAPRIORIDADESELECIONADO,
    payload: prioridade
  };
};
const modificaMotivoCancelamentoTelaAcoesAtendimentoLocal = (
  dispatch,
  mensagem
) => {
  dispatch({
    type: MODIFICAMOTIVOCANCELAMENTOTELAACOESATENDIMENTO,
    payload: mensagem
  });
};
export const modificaMotivoCancelamentoTelaAcoesAtendimento = mensagem => {
  return {
    type: MODIFICAMOTIVOCANCELAMENTOTELAACOESATENDIMENTO,
    payload: mensagem
  };
};

export const modificaAtendenteSelecionado = atendente => {
  return {
    type: MODIFICAATENDENTESELECIONADO,
    payload: atendente
  };
};
const zeraCadastroChamado = dispatch => {
  dispatch({
    type: ZERACADASTROCHAMADO,
    payload: ""
  });
};
const modificaArquivoCompletoParaUparLocal = (dispatch, value) => {
  dispatch({
    type: MODIFICAARQUIVOCOMOPLETOPARAUPAR,
    payload: value
  });
};
export const modificaArquivoCompletoParaUpar = (value) => {
  return {
    type: MODIFICAARQUIVOCOMOPLETOPARAUPAR,
    payload: value
  };
};
const modificaArquivoCompletoParaUparLocalNovoCadastro = (dispatch, value) => {
  dispatch({
    type: MODIFICAARQUIVOCOMOPLETOPARAUPARNOVOCADASTRO,
    payload: value
  });
};
export const modificaArquivoCompletoParaUparNovoCadastro = (value) => {
  return {
    type: MODIFICAARQUIVOCOMOPLETOPARAUPARNOVOCADASTRO,
    payload: value
  };
};
//aqui new
export const modificaArquivoCompletoParaUparNovoContato = (value) => {
  return {
    type: MODIFICAARQUIVOCOMOPLETOPARAUPARNOVOCONTATO,
    payload: value
  };
};
export const insereFotoCadastroContato = uri => {
  return {
    type: INSEREFOTOCADASTROCONTATO,
    payload: uri
  };
};
export const modificaVisibilidadeModalAdicionarContato = value => {
  return {
    type: MODIFICAVISIBILIDADEMODALADICIONARCONTATO,
    payload: value
  };
};
export const modificaNomeContato = (value) => {
  return {
    type: MODIFICANOMECONTATO,
    payload: value
  };
};
export const modificaTelefoneContato = (value) => {

  return {
    type: MODIFICATELEFONECONTATO,
    payload: value
  };
};

const modificaLoadingCadastraNovoContato = (dispatch, value) => {
  dispatch({
    type: MODIFICALOADINGCADASTRANOVOCONTATO,
    payload: value
  });
};
const zeraInformacoesCadastroContato = (dispatch, value) => {
  dispatch({
    type: ZERAINFORMACOESCADASTROCONTATO,
    payload: value
  });
};



export const zeraMensagemChamadoAlteradoSucesso = () => {
  return {
    type: VERIFICACHAMADOALTERADOSUCESSO,
    payload: ""
  };
};
export const zeraMensagemErrorAlterarChamado = () => {
  return {
    type: VERIFICAERRORTELAALTERARCHAMADO,
    payload: ""
  };
};
export const zeraMensagemSemInternetChamadoEmAtendimento = () => {
  return {
    type: ZERAMENSAGEMSEMINTERNETCHAMADOEMATENDIMENTO,
    payload: ""
  };
};
export const zeraMensagemSemInternetChamadoCancelado = () => {
  return {
    type: ZERAMENSAGEMSEMINTERNETCHAMADOCANCELADO,
    payload: ""
  };
};
export const zeraIndicaFimDaListaTOdos = () => {
  return {
    type: INDICAFIMDALISTA,
    payload: false
  };
};
export const zeraMensagemSemInternetChamadoTodos = () => {
  return {
    type: ZERAMENSAGEMSEMINTERNETCHAMADOTODOS,
    payload: ""
  };
};
export const zeraMensagemSemInternetChamadoFechado = () => {
  return {
    type: ZERAMENSAGEMSEMINTERNETCHAMADOFECHADO,
    payload: ""
  };
};
export const zeraMensagemChamadoCadastradoSucesso = () => {
  return {
    type: CHAMADOCADASTRADOSUCESSO,
    payload: ""
  };
};
const zeraInformacoesCadastroCliente = dispatch => {
  dispatch({
    type: ZERAINFORMACOESCADASTROCLIENTE,
    payload: "zeraInformacoes"
  });
};
export const zeraInformacoesCadastroClienteExport = () => {
  return {
    type: ZERAINFORMACOESCADASTROCLIENTE,
    payload: "zeraInformacoes"
  }
};
export const zeraMensagemSemInternetAlteraCliente = () => {
  return {
    type: SEMCONEXAOINTERNETALTERACLIENTE,
    payload: ""
  };
};
export const zeraMensagemSemInternetChamadoAberto = () => {
  return {
    type: ZERAMENSAGEMSEMINTERNETCHAMADOABERTO,
    payload: ""
  };
};

export const zeraMensagemSemInternetTelaCadastroCliente = () => {
  return {
    type: SEMCONEXAOINTERNETCADASTRACLIENTE,
    payload: ""
  };
};
export const zeraMensagemSemInternetCadastraChamado = () => {
  return {
    type: SEMCONEXAOINTERNETCADASTRACHAMADO,
    payload: ""
  };
};

/* export const mensagemToastTelaConsultaCliente = (dispatch, mensagem) => {
  dispatch({
    type: SEMCONEXAOINTERNETCONSULTACLIENTE,
    payload: mensagem
  })
}; */

const mensagemToastTelaCOnsultaCliente = (dispatch, mensagem) => {
  dispatch({
    type: SEMCONEXAOINTERNETCONSULTACLIENTE,
    payload: mensagem
  });
};
const ativaDesativaLoadingChatOnline = (dispatch, value) => {
  dispatch({
    type: ATIVADESATIVALOADINGCHATONLINE,
    payload: value
  });
};
export const zeraMensagemSemInternetConsultaCliente = () => {
  return {
    type: SEMCONEXAOINTERNETCONSULTACLIENTE,
    payload: ""
  };
};
export const zeraMensagemAlteradoSucesso = () => {
  return {
    type: MENSAGEMCADASTRACLIENTESUCESSOTELAALTERAR,
    payload: ""
  };
};

const ativaLoadingBotaoCadastrarCliente = dispatch => {
  dispatch({
    type: ATIVALOADINGBOTAOCADASTRARCLIENTE,
    payload: true
  });
};
const desativaLoadingBotaoCadastrarCliente = dispatch => {
  dispatch({
    type: DESATIVALOADINGBOTAOCADASTRARCLIENTE,
    payload: false
  });
};
const mudaVisibilidadeModalCadastraClienteVersaoPremiumLocal = (
  dispatch,
  visible
) => {
  dispatch({
    type: MUDAVISIBILIDADEMODALCADASTRACLIENTEVERSAOPREMIUM,
    payload: visible
  });
};
const ativaLoadingBotaoCadastrarChamado = dispatch => {
  dispatch({
    type: ATIVALOADINGBOTAOCADASTRARCHAMADO,
    payload: true
  });
};
const ativaLoadingBotaoAlterarCliente = dispatch => {
  dispatch({
    type: ATIVALOADINGBOTAOALTERARCLIENTE,
    payload: true
  });
};
const desativaLoadingBotaoAlterarCliente = dispatch => {
  dispatch({
    type: ATIVALOADINGBOTAOALTERARCLIENTE,
    payload: false
  });
};

const ativaLoadingCarregarClienteTelaNovoChamado = dispatch => {
  dispatch({
    type: ATIVALOADINGCARREGARCLIENTETELANOVOCHAMADO,
    payload: true
  });
};
const modificaClientesExportarClientes = (dispatch, value) => {
  dispatch({
    type: MODIFICACLIENTESEXPORTARCLIENTES,
    payload: value
  });
};

const enviaClienteParaTelaRelatorioDetalhado = (dispatch, value) => {
  dispatch({
    type: ENVIACLIENTEPARATELARELATORIODETALHADO,
    payload: value
  });
};
const loadingClienteTelaRelatorioDetalhado = (dispatch, value) => {
  dispatch({
    type: LOADINGCLIENTETELARELATORIODETALHADO,
    payload: value
  });
};

const ativaDesativaLoadingCarregarClienteTelaChamadoTodos = (
  dispatch,
  value
) => {
  dispatch({
    type: ATIVADESATIVALOADINGCARREGARCLIENTETELACHAMADOTODOS,
    payload: value
  });
};
const ativaDesativaLoadingCarregarClienteTelaConsultaCliente = (
  dispatch,
  value
) => {
  dispatch({
    type: ATIVADESATIVALOADINGCARREGARCLIENTETELACONSULTACLIENTE,
    payload: value
  });
};
const desativaLoadingCarregarClienteTelaNovoChamado = dispatch => {
  dispatch({
    type: DESATIVALOADINGCARREGARCLIENTETELANOVOCHAMADO,
    payload: false
  });
};
const ativaLoadingCarregarAtendenteTelaNovoChamado = dispatch => {
  dispatch({
    type: ATIVALOADINGCARREGARATENDENTETELANOVOCHAMADO,
    payload: true
  });
};
const ativaLoadingCarregarSetorTelaNovoChamado = dispatch => {
  dispatch({
    type: ATIVALOADINGCARREGARSETORTELANOVOCHAMADO,
    payload: true
  });
};
const desativaLoadingCarregarAtendenteTelaNovoChamado = dispatch => {
  dispatch({
    type: DESATIVALOADINGCARREGARATENDENTETELANOVOCHAMADO,
    payload: false
  });
};
const mensagemCasdastraClienteError = (dispatch, error) => {
  dispatch({
    type: MENSAGEMCADASTRACLIENTEERROR,
    payload: error
  });
};
const ativaDesativaLoadingEnviandoAudioChat = (dispatch, value) => {
  dispatch({
    type: ATIVADESATIVALOADINGENVIANDOAUDIOCHAT,
    payload: value
  });
};
export const ativaDesativaLoadingEnviandoAudioChatExport = value => {
  return {
    type: ATIVADESATIVALOADINGENVIANDOAUDIOCHAT,
    payload: value
  };
};
const mensagemCasdastraClienteErrorTelaAlterar = (dispatch, error) => {
  dispatch({
    type: MENSAGEMCADASTRACLIENTEERRORTELAALTERAR,
    payload: error
  });
};
const mensagemCasdastraClienteSucesso = (dispatch, sucesso) => {
  dispatch({
    type: MENSAGEMCADASTRACLIENTESUCESSO,
    payload: sucesso
  });
};
export const navegaTelaCadastraCliente = () => {
  return dispatch => {
    dispatch({
      type: ZERAMSGSUCESSOEERROCADASTRACLIENTE,
      payload: "zeraInformacoes"
    });
    // dispatch(NavigationActions.navigate({ routeName: "CadastroClientes" }));
  };
};
export const testeDrawer = () => {
  return dispatch => {
    // dispatch(NavigationActions.navigate({ routeName: "DrawerOpen" }));
  };
};
export const modificaMostrarMaisChamados = (value) => {
  return {
    type: MODIFICAMOSTRARMAISCHAMADOS,
    payload: value
  };
};
export const modificaRetornaTelaClienteParaNovoAtendimento = (value) => {
  return {
    type: MODIFICARETORNATELACLIENTESPARANOVOATENDIMENTO,
    payload: value
  };
};


const enviaFotoClienteParaReducer = (dispatch, arrayUser) => {
  dispatch({
    type: ENVIAFOTOCLIENTEPARAREDUCER,
    payload: arrayUser
  });
};

const enviaChamadoAberto = (dispatch, arrayChamado) => {
  dispatch({
    type: LISTACHAMADOABERTO,
    payload: arrayChamado
  });
};
const enviaChamadoEmAtendimento = (dispatch, arrayChamado) => {
  dispatch({
    type: ENVIACHAMADOEMATENDIMENTO,
    payload: arrayChamado
  });
};
const enviaChamadoFechado = (dispatch, arrayChamado) => {
  dispatch({
    type: ENVIACHAMADOFECHADO,
    payload: arrayChamado
  });
};
const enviaChamadoCancelado = (dispatch, arrayChamado) => {
  dispatch({
    type: ENVIACHAMADOCANCELADO,
    payload: arrayChamado
  });
};
const enviaChamadoTodos = (dispatch, arrayChamado) => {
  dispatch({
    type: ENVIACHAMADOTODOS,
    payload: arrayChamado
  });
};
const ativaDesativaLoadingCarregaContatosWpp = (dispatch, value) => {
  dispatch({
    type: ATIVADESATIVALOADINGCARREGACONTATOSWPP,
    payload: value
  });
};
export const ativaDesativaLoadingCarregaContatosWppExport = (value) => {
  return {
    type: ATIVADESATIVALOADINGCARREGACONTATOSWPP,
    payload: value
  };
};
const enviaUsuariosTelaAcoesAtendimento = (dispatch, arrayUsuario) => {
  dispatch({
    type: ENVIAUSUARIOSTELAACOESATENDIMENTO,
    payload: arrayUsuario
  });
};
export const enviaChamadoAberto2 = arrayChamado => {
  return {
    type: LISTACHAMADOABERTO,
    payload: arrayChamado
  };
};
const enviaUltimaReferenciaKey = (dispatch, referencia) => {
  dispatch({
    type: ENVIAULTIAMREFERENCIAKEY,
    payload: referencia
  });
};

const enviaUltimaReferenciaKeyTelaConsultaCliente = (dispatch, referencia) => {
  dispatch({
    type: ENVIAULTIAMREFERENCIAKEYTELACONSULTACLIENTE,
    payload: referencia
  });
};
const enviaUltimoNomeReferenciaTelaConsultaCliente = (dispatch, referencia) => {
  dispatch({
    type: ENVIAULTIMONOMEREFENCIATELACONSULTACLIENTE,
    payload: referencia
  });
};
const enviaUltimaReferenciaKeyEmAtendimento = (dispatch, referencia) => {
  dispatch({
    type: ENVIAULTIAMREFERENCIAKEYEMATENDIMENTO,
    payload: referencia
  });
};
const enviaUltimaReferenciaKeyTodos = (dispatch, referencia) => {
  dispatch({
    type: ENVIAULTIAMREFERENCIAKEYTODOS,
    payload: referencia
  });
};
const enviaUltimaReferenciaKeyFechado = (dispatch, referencia) => {
  dispatch({
    type: ENVIAULTIAMREFERENCIAKEYFECHADO,
    payload: referencia
  });
};
const enviaUltimaReferenciaKeyCancelado = (dispatch, referencia) => {
  dispatch({
    type: ENVIAULTIAMREFERENCIAKEYCANCELADO,
    payload: referencia
  });
};
const enviaUltimaReferenciaKeyClienteChamadoTodos = (dispatch, referencia) => {
  dispatch({
    type: ENVIAULTIAMREFERENCIAKEYCLIENTECHAMADOTODOS,
    payload: referencia
  });
};
const enviaUltimaReferenciaChamadoEmAtendimento = (dispatch, referencia) => {
  dispatch({
    type: ENVIAULTIAMREFERENCIACHAMADOEMATENDIMENTO,
    payload: referencia
  });
};
const enviaUltimaReferenciaChamadoFechado = (dispatch, referencia) => {
  dispatch({
    type: ENVIAULTIAMREFERENCIACHAMADOFECHADO,
    payload: referencia
  });
};
const enviaUltimaReferenciaChamadoCancelado = (dispatch, referencia) => {
  dispatch({
    type: ENVIAULTIAMREFERENCIACHAMADOCANCELADO,
    payload: referencia
  });
};

const enviaClienteParaReducer = (dispatch, arrayCliente) => {
  dispatch({
    type: ENVIACLIENTEPARAREDUCER,
    payload: arrayCliente
  });
};
const enviaAtendimentoParaReducer = (dispatch, arrayAtendimento) => {
  dispatch({
    type: ENVIAATENDIMENTOPARAREDUCER,
    payload: arrayAtendimento
  });
};
const enviaAcoesAtendimentoParaReducer = (dispatch, array) => {
  dispatch({
    type: ENVIAACOESATENDIMENTOPARAREDUCER,
    payload: array
  });
};
const enviaClienteParaReducerTelaChamadoTodos = (dispatch, arrayCliente) => {
  dispatch({
    type: ENVIACLIENTEPARAREDUCERTELACHAMADOTODOS,
    payload: arrayCliente
  });
};
const enviaClienteSectionedTelaCOnsultaCliente = (dispatch, arrayCliente) => {
  dispatch({
    type: ENVIACLIENTESECTIONEDTELACONSULTACLIENTE,
    payload: arrayCliente
  });
};
export const removeListaClientesFiltrados = (value) => {
  return {
    type: MODIFICACLIENTESFILTRADOSAPOSDIGITO,
    payload: value
  };
};

const modificaClientesFiltradosAposDigito = (dispatch, arrayCliente) => {
  dispatch({
    type: MODIFICACLIENTESFILTRADOSAPOSDIGITO,
    payload: arrayCliente
  });
};
const enviaEmpresaSectionedTelaAlterarCliente = (dispatch, arrayEmpresa) => {
  dispatch({
    type: ENVIAEMPRESASECTIONEDTELAALTERARCLIENTE,
    payload: arrayEmpresa
  });
};
const ativaDesativaLoadingCarregarClienteTelaAlterarCliente = (dispatch, arrayEmpresa) => {
  dispatch({
    type: ATIVADESATIVALOADINGCARREGARCLIENTETELAALTERARCLIENTE,
    payload: arrayEmpresa
  });
};

const ativaDesativaLoadingCarregaChamadosAntigosEmAtendimento = (dispatch, arrayEmpresa) => {
  dispatch({
    type: ATIVADESATIVALOADINGCARREGACHAMADOSANTIGOSEMATENDIMENTO,
    payload: arrayEmpresa
  });
};
const enviaUltimaReeferenciaKeyEmAtendimento = (dispatch, key) => {
  dispatch({
    type: ENVIAULTIMAREEFERENCIAKEYEMATENDIMENTO,
    payload: key
  });
};
const enviaConversasCarregadasEmAtendimento = (dispatch, key) => {
  dispatch({
    type: ENVIACONVERSASCARREGADASEMATENDIMENTO,
    payload: key
  });
};
export const enviaUltimaReeferenciaKeyEmAtendimentoExport = (key) => {
  return {
    type: ENVIAULTIMAREEFERENCIAKEYEMATENDIMENTO,
    payload: key
  };
};
export const enviaConversasCarregadasEmAtendimentoExport = (key) => {
  return {
    type: ENVIACONVERSASCARREGADASEMATENDIMENTO,
    payload: key
  };
};


const ativaDesativaLoadingCarregaChamadosAntigosEmEspera = (dispatch, arrayEmpresa) => {
  dispatch({
    type: ATIVADESATIVALOADINGCARREGACHAMADOSANTIGOSEMESPERA,
    payload: arrayEmpresa
  });
};
const enviaUltimaReeferenciaKeyEmEspera = (dispatch, key) => {
  dispatch({
    type: ENVIAULTIMAREEFERENCIAKEYEMESPERA,
    payload: key
  });
};
const enviaConversasCarregadasEmEspera = (dispatch, key) => {
  dispatch({
    type: ENVIACONVERSASCARREGADASEMESPERA,
    payload: key
  });
};
export const enviaConversasCarregadasEmEsperaExport = (key) => {
  return {
    type: ENVIACONVERSASCARREGADASEMESPERA,
    payload: key
  };
};
export const enviaUltimaReeferenciaKeyEmEsperaExport = (key) => {
  return {
    type: ENVIAULTIMAREEFERENCIAKEYEMESPERA,
    payload: key
  };
};


const ativaDesativaLoadingCarregaRespostasAntigas = (dispatch, value) => {
  dispatch({
    type: ATIVADESATIVALOADINGCARREGARESPOSTAANTIGAS,
    payload: value
  });
};
const enviaIdRespostaAntiga = (dispatch, id) => {
  dispatch({
    type: ENVIAIDRESPOSTAANTIGA,
    payload: id
  });
};


const ativaDesativaLoadingCarregaChamadosAntigos = (dispatch, arrayEmpresa) => {
  dispatch({
    type: ATIVADESATIVALOADINGCARREGACHAMADOSANTIGOS,
    payload: arrayEmpresa
  });
};
const ativaDesativaLoadingCarregaMaisChamadosAntigos = (dispatch, arrayEmpresa) => {
  dispatch({
    type: ATIVADESATIVALOADINGCARREGAMAISCHAMADOSANTIGOS,
    payload: arrayEmpresa
  });
};
const enviaUltimaReeferenciaKeyContacts = (dispatch, key) => {
  dispatch({
    type: ENVIAULTIMAREEFERENCIAKEYCONTACTS,
    payload: key
  });
};
const enviaConversasUltimoChamado = (dispatch, array) => {
  dispatch({
    type: ENVIACONVERSASULTIMOCHAMADO,
    payload: array
  });
};

const insereMensagemErrorGetContacts = (dispatch, arrayAtendente) => {
  dispatch({
    type: INSEREMENSAGEMERRORGETCONTACTS,
    payload: arrayAtendente
  });
};

const enviaAtendenteParaReducer = (dispatch, arrayAtendente) => {
  dispatch({
    type: ENVIAATENDENTEPARAREDUCER,
    payload: arrayAtendente
  });
};
const enviaSetorParaReducer = (dispatch, arraySetor) => {
  dispatch({
    type: ENVIASETORPARAREDUCER,
    payload: arraySetor
  });
};
export const enviaFotoClienteParaReducer2 = arrayUser => {
  return {
    type: ENVIAFOTOCLIENTEPARAREDUCER,
    payload: arrayUser
  }
};
export const modificaModalPreviewImage = valor => {
  //
  return {
    type: MODIFICAMODALPREVIEWIMAGE,
    payload: valor
  };
};
export const modificaNamePreviewImage = valor => {
  //
  return {
    type: MODIFICANAMEPREVIEWIMAGE,
    payload: valor
  };
};

export const modificaUrlPreviewImage = valor => {
  //
  return {
    type: MODIFICAURLPREVIEWIMAGE,
    payload: valor
  };
};
export const alteraValorSwitch = valor => {
  //
  return {
    type: ALTERAVALORSWITCHTELACLIENTE,
    payload: valor
  };
};
export const alteraValorSwitchNovoCadastro = valor => {
  //
  return {
    type: ALTERAVALORSWITCHTELACLIENTENOVOCADASTRO,
    payload: valor
  };
};
export const alteValorMaisDetalhes = valor => {
  const valorAlterado = !valor;
  return {
    type: ALTERAMAISDETALHESTELANOVOCHAMADO,
    payload: valorAlterado
  };
};
export const alteraValorDigitoConsulta = digito => {
  return {
    type: ALTERAVALORDIGITOCONSULTA,
    payload: digito
  };
};
export const insereFotoCadastroClienteTelaFlatListConsulta = uri => {
  return {
    type: INSEREFOTOCADASTROCLIENTETELAFLATLISTCONSULTA,
    payload: uri
  };
};
export const insereFotoAlterarCliente = uri => {
  return {
    type: INSEREFOTOALTERARCLIENTE,
    payload: uri
  };
};

export const insereFotoCadastroCliente = uri => {
  return {
    type: INSEREFOTOCADASTROCLIENTE,
    payload: uri
  };
};
export const navegaTelaTeste = () => {
  return dispatch => {
    // dispatch(NavigationActions.navigate({ routeName: "TelaTeste2" }));
  };
};

export const navegaTelaConsultaCliente = () => {
  return dispatch => {
    //  dispatch(NavigationActions.navigate({ routeName: "ConsultaCliente" }));
  };
};
export const modificaNomeClienteTelaCadastroCliente = nome => {
  return {
    type: MODIFICANOMECLIENTETELACADASTROCLIENTE,
    payload: nome
  };
};
export const modificaModalRespostaPadrao = value => {
  return {
    type: MODIFICAMODALRESPOSTAPADRAO,
    payload: value
  };
};

export const modificaNomeClienteTelaCadastroClienteNovoCadastro = nome => {
  return {
    type: MODIFICANOMECLIENTETELACADASTROCLIENTENOVOCADASTRO,
    payload: nome
  };
};
export const modificaEmpresaClienteTelaCadastroCliente = empresa => {
  return {
    type: MODIFICAEMPRESACLIENTETELACADASTROCLIENTE,
    payload: empresa
  };
};
export const modificaEmpresaClienteTelaCadastroClienteNovoCadastro = empresa => {
  return {
    type: MODIFICAEMPRESACLIENTETELACADASTROCLIENTENOVOCADASTRO,
    payload: empresa
  };
};
export const modificaEmailClienteTelaCadastroCliente = email => {
  return {
    type: MODIFICAEMAILCLIENTETELACADASTROCLIENTE,
    payload: email
  };
};
export const modificaEmailClienteTelaCadastroClienteNovoCadastro = email => {
  return {
    type: MODIFICAEMAILCLIENTETELACADASTROCLIENTENOVOCADASTRO,
    payload: email
  };
};


export const modificaEnderecoTelaCAdastroCliente = end => {
  return {
    type: MODIFICAENDERECOTELACADASTROCLIENTE,
    payload: end
  };
};
export const modificaEnderecoTelaCAdastroClienteNovoCadastro = end => {
  return {
    type: MODIFICAENDERECOTELACADASTROCLIENTENOVOCADASTRO,
    payload: end
  };
};
export const modificaTelefoneTelaCAdastroClienteNovoCadastro = tel => {
  return {
    type: MODIFICATELEFONETELACADASTROCLIENTENOVOCADASTRO,
    payload: tel
  };
};
export const modificaTelefoneTelaCAdastroCliente = tel => {
  return {
    type: MODIFICATELEFONETELACADASTROCLIENTE,
    payload: tel
  };
};
const ativaDesativaLoadingMaisResultadosChamadosCliente = (dispatch, valor) => {
  dispatch({
    type: ATIVADESATIVALOADINGMAISRESULTADOSCHAMADOSCLIENTE,
    payload: valor
  });
};
export const modificaSenhaClienteTelaCadastroCliente = senha => {
  return {
    type: MODIFICASENHACLIENTETELACADASTROCLIENTE,
    payload: senha
  };
};
export const modificaSenhaClienteTelaCadastroClienteNovoCadastro = senha => {
  return {
    type: MODIFICASENHACLIENTETELACADASTROCLIENTENOVOCADASTRO,
    payload: senha
  };
};
export const modificaConfirmaClienteTelaCadastroClienteNovoCadastro = senha => {
  return {
    type: MODIFICACONFIRMASENHACLIENTETELACADASTROCLIENTENOVOCADASTRO,
    payload: senha
  };
};
export const modificaConfirmaClienteTelaCadastroCliente = senha => {
  return {
    type: MODIFICACONFIRMASENHACLIENTETELACADASTROCLIENTE,
    payload: senha
  };
};
export const modificaResolucaoProblema = value => {
  return {
    type: MODIFICARESOLUCAOPROBLEMA,
    payload: value
  };
};

export const alteraSetor = setor => {
  return {
    type: ALTERASETOR,
    payload: setor
  };
};
export const alteraPrioridade = prioridade => {
  return {
    type: ALTERAPRIORIDADE,
    payload: prioridade
  };
};
export const alteraLocalAtendimento = localAtendimento => {
  return {
    type: ALTERALOCALATENDIMENTO,
    payload: localAtendimento
  };
};
export const alteraDescricaoChamado = descricao => {
  return {
    type: ALTERADESCRICAOCHAMADO,
    payload: descricao
  };
};
export const alteraDescricaoChamadoAudio = (descricaoAudio, descricao) => {
  const descricaoFinal = descricao + " " + descricaoAudio;
  return {
    type: ALTERADESCRICAOCHAMADO,
    payload: descricaoFinal
  };
};
export const alteraDescricaoComentario = (descricaoAudio, descricao) => {
  const descricaoFinal = descricao + " " + descricaoAudio;
  return {
    type: MODIFICAINPUTCOMENTARIO,
    payload: descricaoFinal
  };
};

export const ativaDesativaLoadingRefreshControlChamadoAberto = (
  dispatch,
  value
) => {
  dispatch({
    type: ATIVADESATIVALOADINGREFRESHCONTROLCHAMADOABERTO,
    payload: value
  });
};
export const alteraComentarioChat = (value) => {
  return {
    type: ALTERACOMENTARIOCHAT,
    payload: value
  };
};
export const ativaDesativaLoadingRefreshControlChamadoEmAtendimento = (
  dispatch,
  value
) => {
  dispatch({
    type: ATIVADESATIVALOADINGREFRESHCONTROLCHAMADOEMATENDIMENTO,
    payload: value
  });
};

export const ativaDesativaLoadingRefreshControlChamadoFechado = (
  dispatch,
  value
) => {
  dispatch({
    type: ATIVADESATIVALOADINGREFRESHCONTROLCHAMADOFECHADO,
    payload: value
  });
};
const modificaTextArquivoChat = (dispatch, valor) => {
  dispatch({
    type: MODIFICATEXTARQUIVOCHAT,
    payload: valor
  });
};
const enviaContatosParaReducer = (dispatch, valor) => {
  dispatch({
    type: ENVIACONTATOSPARAREDUCER,
    payload: valor
  });
};
export const enviaContatosParaReducerExport = (valor) => {
  return {
    type: ENVIACONTATOSPARAREDUCER,
    payload: valor
  };
};
const ativaDesativaLoadingRefreshControlChamadoCancelado = (dispatch, value) => {
  dispatch({
    type: ATIVADESATIVALOADINGREFRESHCONTROLCHAMADOCANCELADO,
    payload: value
  });
};
const enviaAtendimentosPorKeyReducerChat = (dispatch, array) => {
  dispatch({
    type: ENVIAATENDIMENTOPORKEYREDUCERCHAT,
    payload: array
  });
};
const enviaClienteParaReducerTodasAvaliacoes = (dispatch, value) => {
  dispatch({
    type: ENVIACLIENTEPARAREDUCERTODASAVALIACOES,
    payload: value
  });
};
const ativaDesativaLoadingEnviaClienteTelaAvaliacaoTodas = (dispatch, value) => {
  dispatch({
    type: ATIVADESATIVALOADINGENVIACLIENTETELAAVALIACAOTODAS,
    payload: value
  });
};
export const modificaArquivoUpado1 = status => {
  return {
    type: MODIFICAARQUIVOUPADO1,
    payload: status
  };
};
export const modificaArquivoUpado2 = status => {
  return {
    type: MODIFICAARQUIVOUPADO2,
    payload: status
  };
};
export const modificaArquivoUpado3 = status => {
  return {
    type: MODIFICAARQUIVOUPADO3,
    payload: status
  };
};

export const modificaContagemArquivo = arquivo => {
  return {
    type: MODIFICACONTAGEMARQUIVO,
    payload: arquivo
  };
};
export const modificaTamanhoArquivo1 = tamanho => {
  return {
    type: MODIFICATAMANHOARQUIVO1,
    payload: tamanho
  };
};
export const modificaTitleTab = title => {
  return {
    type: MODIFICATITLETAB,
    payload: title
  };
};
export const modificaTamanhoArquivo2 = tamanho => {
  return {
    type: MODIFICATAMANHOARQUIVO2,
    payload: tamanho
  };
};
const enviaAtendimentosPorKeyReducerChatEmEspera = (dispatch, array) => {
  dispatch({
    type: ENVIAATENDIMENTOPORKEYREDUCERCHATEMESPERA,
    payload: array
  });
};
export const modificaTamanhoArquivo3 = tamanho => {
  return {
    type: MODIFICATAMANHOARQUIVO3,
    payload: tamanho
  };
};
export const modificaVisibilidadeBotaoAddVencimento = value => {
  return {
    type: MODIFICAVISIBLIDADEBOTAOADDVENCIMENTO,
    payload: value
  };
};

function calculaDias2(date1, date2) {
  //formato do brasil 'pt-br'
  // moment.locale('pt-br');
  //setando data1
  let data1 = moment(date1, "DD/MM/YYYY");
  //setando data2
  let data2 = moment(date2, "DD/MM/YYYY");
  //tirando a diferenca da data2 - data1 em dias
  // let diff = data2.diff(data1, 'days');
  let diff = moment.duration(data2.diff(data1)).asDays();

  return diff;
}
function calculaDias(date1, date2) {
  //formato do brasil 'pt-br'
  // moment.locale('pt-br');
  //setando data1
  let data1 = moment(date1, "DD/MM/YYYY");
  //setando data2
  let data2 = moment(date2, "DD/MM/YYYY");
  //tirando a diferenca da data2 - data1 em dias
  let diff = data2.diff(data1, "days");

  return diff;
}
function calculaDiferencaEntreDatasEmSegundos(date1, date2) {
  //formato do brasil 'pt-br'
  moment.locale('pt-br');
  //setando data1
  let data1 = moment(date1, "DD/MM/YYYY HH:mm");
  //setando data2
  let data2 = moment(date2, "DD/MM/YYYY HH:mm");
  //tirando a diferenca da data2 - data1 em dias
  let diff = data2.diff(data1, "minutes");

  return diff;
}

function retornaDataAtual() {
  let dataAtual = moment().toDate();
  dataAtual = moment(dataAtual).format("DD/MM/YYYY");
  return dataAtual;
}
function retornaDataHoraAtual() {
  let dataAtual = moment().toDate();
  dataAtual = moment(dataAtual).format("DD/MM/YYYY HH:mm");
  return dataAtual;
}
function retornaHoraAtual() {
  let dataAtual = moment().toDate();
  let horaAtual = moment(dataAtual).format("HH:mm");
  return horaAtual;
}



function isNumber(val) {
  return typeof val === "number"
}
const AsyncStorage = {
  setItem: function (key, value) {
    return Promise.resolve().then(function () {
      localStorage.setItem(key, value);
    });
  },
  getItem: function (key) {
    return Promise.resolve().then(function () {
      return localStorage.getItem(key);
    });
  }
};
function fixedEncodeURIComponent(str) {


  return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
    return '';
  });
}
function appendToFilename(filename, string) {
  var dotIndex = filename.lastIndexOf(".");
  if (dotIndex == -1) return filename + string;
  else return filename.substring(0, dotIndex) + string + filename.substring(dotIndex);
}
function verificaSeNumeroEhValidoWhatsApp(isTel, telefoneCliente, sessionclient, chaveUnica, urlApi, apiKey, route, keyCliente) {
  return new Promise(function (resolve, reject) {
    if (isTel === false) {
      resolve("")
    } else {
      let data = {
        session: sessionclient,
        chaveUnica: chaveUnica,
        urlApi,
        apiKey,
        numeroCliente: telefoneCliente
      }
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          'Accept': 'application/json',
        },

        data: data,
        url: 'verificaSeNumeroEhValidoWppSocket',
      };
      const optionsGetName = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          'Accept': 'application/json',
        },

        data: data,
        url: 'verificaSeNumeroEhContatoAdicionadoBuscaNome',
      };
      api(options).then((response) => {


        let retornoNumero = response.data;

        api(optionsGetName).then((responseGetName2) => {


          let responseGetName = responseGetName2.data;
          responseGetName.myContact = false; //retirar ao voltar novamente para o bloqueio de numero cadastrado na agenda
          if (responseGetName.myContact && responseGetName.myContact === true) {

            if (route === "alter") {
              firebase.database().ref(`${chaveUnica}/clientes/`).orderByChild("telefoneCliente").equalTo(telefoneCliente).limitToLast(1).once("value").then(snapshot => {
                if (snapshot.val()) {
                  snapshot.forEach(childItem => {
                    let isMyContact = childItem.val().isMyContact === undefined ? false : childItem.val().isMyContact;
                    let nomeClienteBD = childItem.val().nome;
                    if (childItem.key === keyCliente || isMyContact === false) {
                      resolve("TUDOOK")
                    } else {
                      resolve(`TelefoneJaCadastradoBancoDados${nomeClienteBD}`)
                    }
                  });

                } else {
                  resolve("TUDOOK")
                }
              })
            } else {
              resolve("TelefoneJaCadastradoNaAgenda")
            }
          }
          else {
            if (retornoNumero.profile.numberExists && retornoNumero.profile.numberExists === true) {

              firebase.database().ref(`${chaveUnica}/clientes/`).orderByChild("telefoneCliente").equalTo(telefoneCliente).limitToLast(1).once("value").then(snapshot => {
                if (snapshot.val()) {

                  if (route === "alter") {
                    snapshot.forEach(childItem => {
                      let isMyContact = childItem.val().isMyContact === undefined ? false : childItem.val().isMyContact;
                      let nomeClienteBD = childItem.val().nome;
                      if (childItem.key === keyCliente || isMyContact === false) {
                        resolve("TUDOOK")
                      } else {
                        resolve(`TelefoneJaCadastradoBancoDados${nomeClienteBD}`)
                      }
                    });

                  } else {
                    snapshot.forEach(childItem => {
                      let isMyContact = childItem.val().isMyContact === undefined ? false : childItem.val().isMyContact;
                      let nomeClienteBD = childItem.val().nome;
                      if (childItem.key === keyCliente || isMyContact === false) {
                        resolve("TUDOOK")
                      } else {
                        resolve(`TelefoneJaCadastradoBancoDados${nomeClienteBD}`)
                      }
                    });
                  }
                } else {
                  resolve("TUDOOK")
                }
              })
            } else {
              resolve("TelefoneInvalido")
            }
          }
        }).catch((err) => {


          resolve("TelefoneDesconectado")
        })
      }).catch(() => {
        resolve("TelefoneDesconectado")
      })
    }

  }).then((resp) => {


    return resp;
  })
};
function escapeRegexCharacters(str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}
