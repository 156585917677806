import React from 'react';
import firebase from './servicos/FirebaseConnection';
import { BrowserRouter, Switch, Route, Link, useLocation, Router, withRouter, Redirect } from 'react-router-dom';
import { ProSidebar, Menu as MenuSide, MenuItem as MenuItemSide, SubMenu, SidebarHeader, SidebarFooter, SidebarContent } from 'react-pro-sidebar';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import ReactTooltip from "react-tooltip";
import { Offline, Online, Detector } from "react-detect-offline";
import moment from 'moment';
import FlatList from 'flatlist-react';
import Notifications from "react-notifications-menu";
import Dropdown from 'rc-dropdown';
import { toastr } from 'react-redux-toastr';
import { AuthProvider } from "./servicos/AuthProtected";
import PrivateRoute from "./servicos/PrivateRoute";
import { Modal } from 'react-responsive-modal';
import axiosProx from 'axios-https-proxy-fix';
import 'rc-dropdown/assets/index.css';
import './menu.css';

//import 'react-pro-sidebar/dist/css/styles.css';

import Login from './components/Login/index';
import Dashboard from './components/Dashboard/index';
import NotFound from './components/NotFound/index';
import VersaoPremium from './components/VersaoPremium/index';
import Cadastro from './components/Cadastro/index';
import Atendimentos from './components/Atendimentos/index';
import ChamadoAberto from './components/ChamadoAberto/index';
import ChamadoAtendendo from './components/ChamadoAtendendo/index';
import ChamadoFechado from './components/ChamadoFechado/index';
import ChamadoCancelado from './components/ChamadoCancelado/index';
import ChamadoTodos from './components/ChamadoTodos/index';
import AcoesChamado from './components/AcoesChamado/index';
import Clientes from './components/Clientes/index';
import ExportClients from './components/Export_Clients/index';
import Atendentes from './components/Atendentes/index';
import AtendimentoOnline from './components/AtendimentoOnline/index';
import Setor from './components/Setor/index';
import Avisos from './components/Avisos/index';
import RelatorioAtendimentoDetalhado from './components/RelatorioAtendimentoDetalhado/index';
import RelatorioAtendimentoGeral from './components/RelatorioAtendimentoGeral/index';
import RelatorioRegistroAvaliacoes from './components/RelatorioRegistroAvaliacoes/index';
import RelatorioAvaliacaoGeral from './components/RelatorioAvaliacaoGeral/index';
import Configuracao from './components/Configuracao/index';
import RelatorioCRM from './components/RelatorioCRM/index';
import PainelADM from './components/PainelADM/index';
import backGroundStopVersaoPremium from './imagens/backGroundStopVersaoPremium.jpg';



import Routes from './Routes';
import './App.css';
import history from './history';
import {
  Container, ButtonMenu, ButtonExpandeMenu, BorderBottomMenu, HeaderPadrao,
  TelaInteira, SubContainer, NomeRota, ContainerPerfil, DivNomeEmail, TextNome, TextEmail,
  ButtonImagemPerfil, ButtonNotificacao, IconeNotificacao, ButtonVariosNovos, IconeVariosNovos,
  TextBotaoNovo, IconeVariosNovosSeta, PageBody, DivMenuNovo, TextNovoMenu, TextFiltrarPor,
  DivMenuFiltro, DivIconeTextFiltro, TextMenuFiltro, DivMenuPerfil, TextPerfil,
  DivMenuFiltroClientes, DivMenuFiltroAvisos, TextMenuFiltroAvisos, TextFiltrarPorAvisos,
  DivMenuFiltroAtendimento, TextMenuFiltroAtendimento, DivMenuFiltroAtendimentoDetalhado,
  TextBotaoCancelarAtendimento, ButtonNaoCancelarAtendimento, ButtonCancelarAtendimento,
  SubContainerNotif, TextMarcaLido, TextCabecalhoModalAcoes, TextNovoAtendimento,
  TextNotification, DivCountNotif, TextCountNotif,
  HeaderNotif, ProSidebarTeste, TextNovoMenuLink, ContainerNotif, TextLinkPerfil,
  BackgroundModal, ContainerMensagem, ContainerImagem, ContainerMensagemTexto,
  ContainerTitle, ContainerDataNotif, TitleNotif, SubTitleNotif, TextData, TextNew,
  ImageNotif, ButtonPortalCliente, LinkDropDesk, ButtonAddNew, TextAddNew, ContainerSemInternet,
  BackgroundSemInternet, ContainerSemInternetLaranja,
  DivCountChamadoEspera, ButtonVariosNovosExcel, ContainerModalCompraSucesso, ContainerIconeFecharModal
} from './AppStyled';

import userPadrao from './imagens/userPadrao.png';
import logoPadrao from './imagens/logodropdesk2.png';

import imgNotifComent from './imagens/commentnotif.svg';
import imgNotifNovoChamado from './imagens/notifNovoChamado.svg';
import imgNotifAtribuido from './imagens/telemarketing.svg';
import { modificaNomeRotaNoHeader, modificaPesquisaTeste, modificaAberturaMenu } from '../src/actions/HeaderAction';

import {
  modificaVisibilidadeModalNovoAtendimento,
  modificaModalFiltroChamado, modificaFiltroChamado, modificaSelectTextChamado, modificaPesquisaTextChamado,
  modificaValorArraySectionedFiltroChamado, modificaFiltroClientesTelaClientes, modificaVisibleModalNovoCLiente,
  zeraInformacoesCadastroClienteExport,
  navegaTelaALterarChamadoNotificacao, modificaVisibilidadeModalPortalCliente, verificaSeJaTeMCodigoCadastraCliente, modificaVisibilidadeModalImportarClientes,
  modificaTitleTab,
} from "../src/actions/ChamadoAction";
import {
  modificaFiltroPorDataDashboard, insereDataFiltroDashboard, modificaFiltroSelecionadoRelatorioDetalhado,
  modificaModalSair, enviaTotalNotificacoesParaReducerExport, marcarTodasNotifComoLido, modificaModalDuvidasFrequentes,
  ativaDesativaRenovarPLano, modificaMostraVersaoExpirando, modificaVisibilidadeModalExportarClientes
} from '../src/actions/DashBoardAction';
import { modificaFiltroClientesTelaAtendentes, modificaVisibleModalNovoAtendente, zeraInformacoesCadastroAtendenteExport } from '../src/actions/AtendenteAction';
import { modificaFiltroSetoresTelaSetores, modificaVisibleModalNovoSetor } from '../src/actions/SetorAction'
import { alteraFiltroSelecionadoTelaNovoAviso, modificaVisibleModalNovoAviso, zerCadastroAvisoExport } from '../src/actions/AvisoAction';
import { modificaFiltroAvaliacao, modificaFiltroRelatorioAvaliacaoGeral } from '../src/actions/AvaliacaoAction';
import { acessouMeuPErfil, sairDoApp } from '../src/actions/PerfilAction';

const polling = {
  enabled: true,
  interval: 15000,
  timeout: 5000,
  //url: "https://ipv4.icanhazip.com/",
  // url: "https://api.ipify.org/?format=json",
};
class App extends React.Component {
  state = {
    menuOpen: JSON.parse(localStorage.getItem('menuOpen')) == null ? false : JSON.parse(localStorage.getItem('menuOpen')),
    modalInternet: false,
    sairDoDropDesk: false,
    conexaoInternetOffline: JSON.parse(localStorage.getItem('internetConnectionDropDesk')) == null ? false : JSON.parse(localStorage.getItem('internetConnectionDropDesk')),
    deslogado: false,
    modalNotif: false,
    isLoadingLogged: true,
    mostraVersaoExpirando: false,
    testeNotif2: [{
      key: '1',
      title: 'Atendimento atribuido à você #122',
      subTitle: 'Computador não liga',
      data: '10/10/2020 13:55',
      lido: true
    },
    {
      key: '2',
      title: 'Novo Atendimento #123',
      subTitle: 'No Break ruim,No Break ruim,No Break ruim,No Break ruim,No Break ruim,No Break ruim',
      data: '10/07/2019 14:22',
      lido: false
    }],
    testeNotif: [{
      key: '1',
      title: 'Atendimento atribuido à você #122',
      subTitle: 'Computador não liga,Computador não liga,Computador não liga,Computador não liga,Computador não liga,Computador não liga,Computador não liga',
      data: '10/10/2020 13:55',
      lido: true
    },
    {
      key: '2',
      title: 'Novo Atendimento #123',
      subTitle: 'No Break ruim,No Break ruim,No Break ruim,No Break ruim,No Break ruim,No Break ruim,No Break ruim',
      data: '10/07/2019 14:22',
      lido: false
    },
    {
      key: '3',
      title: 'Comentario #123',
      subTitle: 'No Break ruim',
      data: '10/07/2019 14:22',
      lido: false
    },
    {
      key: '4',
      title: 'Novo Coment #123',
      subTitle: 'No Break ruim',
      data: '10/07/2019 14:22',
      lido: false
    },
    {
      key: '5',
      title: 'Novo Atendimento #123',
      subTitle: 'No Break ruim',
      data: '10/07/2019 14:22',
      lido: false
    },
    {
      key: '10',
      title: 'Novo Atendimento #123',
      subTitle: 'No Break ruim',
      data: '10/07/2019 14:22',
      lido: false
    },
    {
      key: '6',
      title: 'Novo Atendimento #123',
      subTitle: 'No Break ruim',
      data: '10/07/2019 14:22',
      lido: false
    },
    {
      key: '7',
      title: 'Novo Atendimento #123',
      subTitle: 'No Break ruim',
      data: '10/07/2019 14:22',
      lido: false
    },
    {
      key: '8',
      title: 'Novo Atendimento #123',
      subTitle: 'No Break ruim',
      data: '10/07/2019 14:22',
      lido: false
    },
    {
      key: '9',
      title: 'Novo Atendimento #123',
      subTitle: 'No Break ruim',
      data: '10/07/2019 14:22',
      lido: false
    }],
    dataTeste:
      [
        {
          image: 'https://synergi-dev.s3.ap-southeast-1.amazonaws.com/profile-pictures/6b9.png',
          message: 'Lorem ipsum dolor sit amet.',
          detailPage: '/events',
          receivedTime: '12h ago'
        }
      ],
    diasRestantesBoleto: null

  }

  componentWillMount() {
    localStorage.setItem('internetConnectionDropDesk', JSON.stringify(false));

  }

  componentDidMount() {
    /*
     let countNotifTela = 0;
     let acoesChamadosEmAtendimento = this.props.acoesDoAtendimentoChat;
     let chamadosEmEspera = this.props.chamadosEmEsperaAtendimentoOnline;

     acoesChamadosEmAtendimento.forEach(childItem => {

     })
     chamadosEmEspera.forEach(childItem => {

       if (childItem.permiteVisualizarChamado === true) {
         countNotifTela++;
       }
     })
      */
    /*  (async () => {
       try {
         window.networkPoll = setInterval(async () => {
           const networkStatus = await axiosProx.get("https://www.google.com/");

           if (networkStatus.code != 200) {
             this.setState({ modalInternet: false })


           }
           if (networkStatus.code == 200) {
             this.setState({ modalInternet: true })

           }

         }, 5000);
       }
       catch (error) {
         this.setState({ modalInternet: false })

       }
     })(); */
  }
  componentWillReceiveProps(nextProps) {
    //
    /*    if (this.props.chamadosEmEsperaAtendimentoOnline !== nextProps.chamadosEmEsperaAtendimentoOnline) {



         if (this.props.chamadosEmEsperaAtendimentoOnline.length > 0) {
           let filteredArray = this.props.chamadosEmEsperaAtendimentoOnline.filter(o => !nextProps.chamadosEmEsperaAtendimentoOnline.some(i => i.key === o.key));


           if (filteredArray.length > 0 && nextProps.keyAtendimentoEmAtendimentoOnline !== filteredArray[0].key) {


             firebase.database().ref(`${filteredArray[0].chaveUnica}/acoesChamados/${filteredArray[0].key}`).off("value");
           }
         }

       } */

    if (this.props.chamadosEmEsperaAtendimentoOnline !== nextProps.chamadosEmEsperaAtendimentoOnline || this.props.chamadosEmAtendimentoOnline !== nextProps.chamadosEmAtendimentoOnline) {
      let countAtual = 0;
      nextProps.chamadosEmAtendimentoOnline.forEach(childItem => {
        //;
        let countChamado = childItem.lastmessageChatCount == undefined ? 0 : childItem.lastmessageChatCount
        if (countChamado > 0) {
          countAtual++
        }

      })
      nextProps.chamadosEmEsperaAtendimentoOnline.forEach(childItem => {
        //;
        if (childItem.permiteVisualizarChamado === true) {
          countAtual++
        }
      })
      this.props.modificaTitleTab(countAtual)
      //5
      // se this.props.chamadosEmEsperaAtendimentoOnline for igual a 0, soma os chamados, se não tem que diminuir com o chamado atual
      /* if (this.props.chamadosEmEsperaAtendimentoOnline.length > 0) {
        let chamadoEmEsperaAtual = nextProps.chamadosEmEsperaAtendimentoOnline.length - this.props.chamadosEmEsperaAtendimentoOnline.length;



        countAtual = nextProps.countMensagensNaoLidas + chamadoEmEsperaAtual;
        this.props.modificaTitleTab(countAtual)
      } else {
        this.props.modificaTitleTab(nextProps.chamadosEmEsperaAtendimentoOnline.length)
      } */
    }
    /*   let arrayOfKeys = Object.keys(nextProps.acoesDoAtendimentoChat);
      let results = arrayOfKeys.map(key => nextProps.acoesDoAtendimentoChat[key]);


      let arrayOfKeys2 = Object.keys(this.props.acoesDoAtendimentoChat);
      let results2 = arrayOfKeys2.map(key => this.props.acoesDoAtendimentoChat[key]);



      if (this.props.chamadosEmEsperaAtendimentoOnline !== nextProps.chamadosEmEsperaAtendimentoOnline || this.props.acoesDoAtendimentoChat !== nextProps.acoesDoAtendimentoChat || results2 !== results) {
        setTimeout(() => {
          let countNotifTela = 0;
          let arrayOfKeys = Object.keys(nextProps.acoesDoAtendimentoChat);
          let results = arrayOfKeys.map(key => nextProps.acoesDoAtendimentoChat[key]);
          let acoesChamadosEmAtendimento = results;
          let chamadosEmEspera = nextProps.chamadosEmEsperaAtendimentoOnline;
          //
          //
          acoesChamadosEmAtendimento.forEach(childItem => {
            //;
            let ultimaMensagem = childItem[0];
            if (ultimaMensagem.circleColor === "#39a3f4" && ultimaMensagem.read === false) {
              countNotifTela++;
            }
          })
          chamadosEmEspera.forEach(childItem => {
            //;
            if (childItem.permiteVisualizarChamado === true) {
              countNotifTela++;
            }
          })
          this.props.modificaTitleTab(countNotifTela)


        }, 200);
      } */
  }
  componentWillUnmount() {
    /*   alert('willunmount app')
      firebase.auth().signOut(); */
  }
  calculaDiasRestantesVersaoGratis(date1, date2) {
    //formato do brasil 'pt-br'
    // moment.locale('pt-br');
    //setando data1
    let data1 = moment(date1, 'DD/MM/YYYY');
    //setando data2
    let data2 = moment(date2, 'DD/MM/YYYY');

    //tirando a diferenca da data2 - data1 em dias
    // let diff = data2.diff(data1, 'days');
    let diff = Math.abs(data2.diff(data1, 'days'));

    return diff;
  }
  insereConexaoOffline() {
    /*  */
    this.setState({ conexaoInternetOffline: true })
  }
  simulateClick(e) {
    /*    */
    e.click()
  }
  _toggleDrawerMenu() {
    localStorage.setItem('menuOpen', JSON.stringify(!this.state.menuOpen));
    let state = this.state;
    state.menuOpen = !this.state.menuOpen;
    this.setState({ state })

  }
  _toggleSairDropDesk() {
    let state = this.state;
    state.sairDoDropDesk = !this.state.sairDoDropDesk;
    this.setState({ state });

  }
  renderImagemNotif(item) {
    if (item.title.includes('Novo atendimento')) {
      return (
        <img src={imgNotifNovoChamado} alt="notif" style={{ height: 30, width: 30, borderRadius: '50%', background: '#CCC' }} />
      )
    } else if (item.title.includes('Atendimento atribuido à você')) {
      return (
        <img src={imgNotifAtribuido} alt="notif" style={{ height: 30, width: 30, borderRadius: '50%', background: '#CCC' }} />
      )
    } else {
      return (
        <ImageNotif src={imgNotifComent} alt="notif" />
      )
    }
  }
  renderTextTotalNotif() {
    if (this.props.totalNotificacoes <= 0) {
      return null
    } else if (this.props.totalNotificacoes > 9) {
      return (
        <DivCountNotif>
          <TextCountNotif>
            9
          </TextCountNotif>
          +
        </DivCountNotif>

      )
    } else {
      return (
        <DivCountNotif>
          <TextCountNotif>
            {this.props.totalNotificacoes}
          </TextCountNotif>
        </DivCountNotif>

      )
    }
  }
  _toggleModalNotif() {
    //
    let state = this.state;
    state.modalNotif = !this.state.modalNotif;
    this.setState({ state });

  }


  onSelect({ key }) {
  }

  onVisibleChange(visible) {
  }
  retornaDataAtual() {
    moment.locale('pt-br')
    let dataAtual = moment().toDate();
    dataAtual = moment(dataAtual).format('DD/MM/YYYY');
    return dataAtual;
  }
  renderCountNotifChat() {
    if (this.props.countMensagensNaoLidas > 0 && this.props.countMensagensNaoLidas <= 9) {
      /*   return   <DivCountChamadoEspera backgrounddinamico={'red'} corletradinamica={'#FFF'}>{this.props.countMensagensNaoLidas}</DivCountChamadoEspera> */
      return <DivCountChamadoEspera backgrounddinamico={'red'} corletradinamica={'#FFF'}>{this.props.countMensagensNaoLidas}</DivCountChamadoEspera>
    } else if (this.props.countMensagensNaoLidas > 9) {
      return <DivCountChamadoEspera backgrounddinamico={'red'} corletradinamica={'#FFF'}>9+</DivCountChamadoEspera>
    } else {
      return null
    }
  }
  renderMenuCliente() {
    if (this.props.permissoesAcessarTelasAtendente.permissaoAlterarCadastrarCliente === true) {
      return (
        <MenuItemSide translate="no" active={this.props.nomeRota == 'Clientes' || this.props.nomeRota == 'Exportar Clientes' ? true : false} title='Clientes' onClick={() => { this.props.modificaNomeRotaNoHeader('Clientes') }} icon={<i className="icon-man-user" style={{ color: '#FFF', fontSize: 18 }} />}>Clientes <Link to="/clientes" /></MenuItemSide>
      )
    } else {
      return (
        <MenuItemSide translate="no" suffix={<i className="icon-senha" style={{ color: 'red', fontSize: 15 }} />} active={this.props.nomeRota == 'Clientes' ? true : false} title='Clientes' onClick={() => { toastr.error('Acesso Negado', 'Você não tem permissão para acessar esta tela'); }} icon={<i className="icon-man-user" style={{ color: '#FFF', fontSize: 18 }} />}>Clientes </MenuItemSide>
      )
    }
  }
  renderMenuAtendimentoOnline() {
    if (this.props.premium == 0) {
      return (
        <MenuItemSide translate="no" suffix={<i className="icon-senha" style={{ color: 'red', fontSize: 15 }} />} active={this.props.nomeRota == 'Chat' ? true : false} title='Chat' onClick={() => { toastr.error('Recurso Premium', 'Este é um recurso PREMIUM, Assine a versão PREMIUM e libere esse e mais recursos!'); }} icon={<i className="icon-iconwpp" style={{ color: '#FFF', fontSize: 18 }} />}>Chat</MenuItemSide>
      )

    } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
      return (
        <MenuItemSide translate="no" suffix={<i className="icon-senha" style={{ color: 'red', fontSize: 15 }} />} active={this.props.nomeRota == 'Chat' ? true : false} title='Chat' onClick={() => { toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!'); }} icon={<i className="icon-iconwpp" style={{ color: '#FFF', fontSize: 18 }} />}>Chat </MenuItemSide>
      )

    } else {

      return (
        <MenuItemSide translate="no" suffix={this.renderCountNotifChat()} active={this.props.nomeRota == 'Chat' ? true : false} title='Chat' onClick={() => { this.props.modificaNomeRotaNoHeader('Chat') }} icon={<i className="icon-iconwpp" style={{ color: '#FFF', fontSize: 18 }} />}>Chat<Link to="/atendimentoonline" /> </MenuItemSide>

      )

    }
  }

  renderMenuAtendente() {
    if (this.props.permissoesAcessarTelasAtendente.permissaoAlterarCadastrarAtendente === true) {
      return (
        <MenuItemSide translate="no" active={this.props.nomeRota == 'Atendentes' ? true : false} title='Atendentes' onClick={() => { this.props.modificaNomeRotaNoHeader('Atendentes') }} icon={<i className="icon-atendimentos" style={{ color: '#FFF', fontSize: 18 }} />}>Atendentes <Link to="/atendentes" /></MenuItemSide>
      )
    } else {
      return (
        <MenuItemSide translate="no" suffix={<i className="icon-senha" style={{ color: 'red', fontSize: 15 }} />} active={this.props.nomeRota == 'Atendentes' ? true : false} title='Atendentes' onClick={() => { toastr.error('Acesso Negado', 'Você não tem permissão para acessar esta tela'); }} icon={<i className="icon-atendimentos" style={{ color: '#FFF', fontSize: 18 }} />}>Atendentes</MenuItemSide>
      )
    }
  }
  renderMenuVersaoPremium() {
    if (this.props.isAdmin === true) {
      return (
        <MenuItemSide translate="no" suffix={
          <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", }}>
            <div style={{
              display: 'flex',
              backgroundColor: this.props.corTextStatusAssinaturaDrawer, borderRadius: 4, height: 20, width: 45, justifyContent: 'center',
              alignItems: 'center', marginLeft: 5, overflow: 'hidden'
              // paddingHorizontal: 7
            }}>
              <span style={{ fontSize: 8, color: this.props.textStatusAssinaturaDrawer == 'ASSINAR' || this.props.textStatusAssinaturaDrawer == 'SUSPENSA' || this.props.textStatusAssinaturaDrawer == 'PAUSADA' ? '#FFF' : '#000', fontWeight: 'bold' }}>{this.props.textStatusAssinaturaDrawer}</span>
            </div>
          </div>
        } active={this.props.nomeRota == 'Versão Premium' ? true : false} title={this.props.premium == 0 ? 'Seja Premium' : 'Premium'} onClick={() => { this.props.modificaNomeRotaNoHeader('Versão Premium') }} style={{ borderBottomWidth: 1, borderBottomColor: '#FFF' }} icon={<i className="icon-vip" style={{ color: '#FFF', fontSize: 18 }} />}>Versão Premium <Link to="/versaopremium" /></MenuItemSide>
      )
    } else {
      return (
        <MenuItemSide translate="no" suffix={<i className="icon-senha" style={{ color: 'red', fontSize: 15 }} />} active={this.props.nomeRota == 'Versão Premium' ? true : false} title={this.props.premium == 0 ? 'Seja Premium' : 'Premium'} onClick={() => { toastr.error('Acesso Negado', 'Somente ADMINISTRADOR tem permissão para acessar esta tela'); }} style={{ borderBottomWidth: 1, borderBottomColor: '#FFF' }} icon={<i className="icon-vip" style={{ color: '#FFF', fontSize: 18 }} />}>Versão Premium</MenuItemSide>
      )
    }
  }

  renderMenuSetores() {
    if (this.props.permissoesAcessarTelasAtendente.permissaoAlterarCadastrarSetores === true) {
      return (
        <MenuItemSide translate="no" active={this.props.nomeRota == 'Setores' ? true : false} title='Setores' onClick={() => { this.props.modificaNomeRotaNoHeader('Setores') }} icon={<i className="icon-icon" style={{ color: '#FFF', fontSize: 18 }} />}>Setores <Link to="/setores" /></MenuItemSide>
      )
    } else {
      return (
        <MenuItemSide translate="no" suffix={<i className="icon-senha" style={{ color: 'red', fontSize: 15 }} />} active={this.props.nomeRota == 'Setores' ? true : false} title='Setores' onClick={() => { toastr.error('Acesso Negado', 'Você não tem permissão para acessar esta tela'); }} icon={<i className="icon-icon" style={{ color: '#FFF', fontSize: 18 }} />}>Setores</MenuItemSide>
      )
    }
  }
  renderMenuAvisos() {
    if (this.props.premium == 0) {
      return (
        <MenuItemSide translate="no" suffix={<i className="icon-senha" style={{ color: 'red', fontSize: 15 }} />} active={this.props.nomeRota == 'Mural de Avisos' ? true : false} title='Mural de Avisos' onClick={() => { toastr.error('Recurso Premium', 'Este é um recurso PREMIUM, Assine a versão PREMIUM e libere esse e mais recursos!'); }} icon={<i className="icon-megaphone" style={{ color: '#FFF', fontSize: 18 }} />}>Mural de Avisos </MenuItemSide>
      )

    } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
      return (
        <MenuItemSide translate="no" suffix={<i className="icon-senha" style={{ color: 'red', fontSize: 15 }} />} active={this.props.nomeRota == 'Mural de Avisos' ? true : false} title='Mural de Avisos' onClick={() => { toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!'); }} icon={<i className="icon-megaphone" style={{ color: '#FFF', fontSize: 18 }} />}>Mural de Avisos </MenuItemSide>
      )

    } else {
      if (this.props.permissoesAcessarTelasAtendente.permissaoAlterarCadastrarAvisos === true) {
        return (
          <MenuItemSide translate="no" active={this.props.nomeRota == 'Mural de Avisos' ? true : false} title='Mural de Avisos' onClick={() => { this.props.modificaNomeRotaNoHeader('Mural de Avisos') }} icon={<i className="icon-megaphone" style={{ color: '#FFF', fontSize: 18 }} />}>Mural de Avisos <Link to="/avisos" /></MenuItemSide>
        )
      } else {
        return (
          <MenuItemSide translate="no" suffix={<i className="icon-senha" style={{ color: 'red', fontSize: 15 }} />} active={this.props.nomeRota == 'Mural de Avisos' ? true : false} title='Mural de Avisos' onClick={() => { toastr.error('Acesso Negado', 'Você não tem permissão para acessar esta tela'); }} icon={<i className="icon-megaphone" style={{ color: '#FFF', fontSize: 18 }} />}>Mural de Avisos </MenuItemSide>
        )
      }
    }
  }
  renderMenuRelatorios() {
    if (this.props.premium == 0) {
      return (
        <MenuItemSide translate="no" suffix={<i className="icon-senha" style={{ color: 'red', fontSize: 15 }} />} active={this.props.nomeRota == 'Relatório Atendimentos' ? true : false} title='Relatórios' onClick={() => { toastr.error('Recurso Premium', 'Este é um recurso PREMIUM, Assine a versão PREMIUM e libere esse e mais recursos!'); }} icon={<i className="icon-bar-chart2" style={{ color: '#FFF', fontSize: 18 }} />}>Relatórios </MenuItemSide>
      )

    } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
      return (
        <MenuItemSide translate="no" suffix={<i className="icon-senha" style={{ color: 'red', fontSize: 15 }} />} active={this.props.nomeRota == 'Relatório Atendimentos' ? true : false} title='Relatórios' onClick={() => { toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!'); }} icon={<i className="icon-bar-chart2" style={{ color: '#FFF', fontSize: 18 }} />}>Relatórios </MenuItemSide>
      )

    } else {
      if (this.props.permissoesAcessarTelasAtendente.permissaoAcessoRelatorios === true) {
        return (
          <SubMenu onClick={() => { }} title='Relatórios' icon={<i className="icon-bar-chart2" style={{ color: '#FFF', fontSize: 18 }} />}>
            <SubMenu title='Atendimento' icon={<i className="icon-headset" style={{ color: '#FFF', fontSize: 18 }} />}>
              <MenuItemSide translate="no" active={this.props.nomeRota == 'Relatório Atendimentos' ? true : false} onClick={() => { this.props.modificaNomeRotaNoHeader('Relatório Atendimentos') }}>Atendimentos<Link to="/relatorioatendimentodetalhado" /></MenuItemSide>
              <MenuItemSide translate="no" active={this.props.nomeRota == 'Relatório Atendimentos Geral' ? true : false} onClick={() => { this.props.modificaNomeRotaNoHeader('Relatório Atendimentos Geral') }}>Visão Geral<Link to="/relatorioatendimentogeral" /></MenuItemSide>
            </SubMenu>
            <SubMenu title='Avaliação' icon={<i className="icon-star" style={{ color: '#FFF', fontSize: 18 }} />}>
              <MenuItemSide translate="no" active={this.props.nomeRota == 'Relatório Registros Avaliações' ? true : false} onClick={() => { this.props.modificaNomeRotaNoHeader('Relatório Registros Avaliações') }}>Avaliações<Link to="/relatorioregistroavaliacao" /></MenuItemSide>
              <MenuItemSide translate="no" active={this.props.nomeRota == 'Relatório Avaliação Geral' ? true : false} onClick={() => { this.props.modificaNomeRotaNoHeader('Relatório Avaliação Geral') }}>Visão Geral<Link to="/relatorioavaliacaogeral" /></MenuItemSide>
            </SubMenu>

          </SubMenu>
        )
      } else {
        return (
          <MenuItemSide translate="no" suffix={<i className="icon-senha" style={{ color: 'red', fontSize: 15 }} />} active={this.props.nomeRota == 'Relatório Atendimentos' ? true : false} title='Relatórios' onClick={() => { toastr.error('Acesso Negado', 'Você não tem permissão para acessar esta tela'); }} icon={<i className="icon-bar-chart2" style={{ color: '#FFF', fontSize: 18 }} />}>Relatórios </MenuItemSide>
        )
      }
    }
  }
  renderMenuConfiguracoes() {
    if (this.props.permissoesAcessarTelasAtendente.permissaoAdministrador === true) {
      return (
        <MenuItemSide translate="no" active={this.props.nomeRota == 'Configurações' ? true : false} title='Configurações' onClick={() => { this.props.modificaNomeRotaNoHeader('Configurações') }} icon={<i className="icon-settings-gears" style={{ color: '#FFF', fontSize: 18 }} />}>Configurações <Link to="/configuracao" /></MenuItemSide>
      )
    } else {
      return (
        <MenuItemSide translate="no" suffix={<i className="icon-senha" style={{ color: 'red', fontSize: 15 }} />} active={this.props.nomeRota == 'Configurações' ? true : false} title='Configurações' onClick={() => { toastr.error('Acesso Negado', 'Somente ADMINISTRADOR tem permissão para acessar esta tela'); }} icon={<i className="icon-settings-gears" style={{ color: '#FFF', fontSize: 18 }} />}>Configurações</MenuItemSide>
      )
    }
  }
  renderImagemPerfil() {
    if (this.props.foto == null) {
      return (
        <div style={{ height: 45, width: 45, borderRadius: '50%', backgroundColor: 'rgba(159,173,183, 0.3)', border: 'none' }} />
      )
    } else {
      return (
        <ButtonImagemPerfil type="button">
          <img src={this.props.foto} alt="Imagem Perfil" style={{ height: 45, width: 45, borderRadius: '50%' }} />
        </ButtonImagemPerfil>
      )
    }
  }
  renderImagemLogoMenu() {
    if (this.props.logotipo == null) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', height: this.state.menuOpen == false ? '100%' : '100%', maxWidth: this.state.menuOpen == false ? '100%' : '100%', borderRadius: '50%', backgroundColor: 'rgba(159,173,183, 0.3)', border: 'none' }} />
          {/* <div style={{ height: 50, flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
            <LinkDropDesk href="https://www.dropdesk.com.br" target="_blank">dropdesk</LinkDropDesk>
          </div> */}
        </div>
      )
    } else {
      return (
        /*         <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',overflow:'auto',background:'#FFF' }}> */
        <img src={this.props.logotipo == 'semURI' ? logoPadrao : this.props.logotipo} alt="Logo" style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', maxHeight: this.state.menuOpen == false ? '100%' : '100%', maxWidth: this.state.menuOpen == false ? '100%' : '100%' }} />

        /*  </div> */
      )
    }
  }
  /*  shouldComponentUpdate(nextProps) {
     if (this.props.abrirMenu !== nextProps.abrirMenu) {
       return true;
     } else {
       return false;
     }
   } */
  renderFiltroAtendente() {
    if (this.props.verificaAtendenteVeSomenteProprioAtendimento == false && this.props.nomeRota == 'Atendimentos') {
      return (
        <DivIconeTextFiltro onClick={() => {
          //
          if (this.props.premium == 0) {
            //
            toastr.error('Recurso Premium', 'Este é um recurso PREMIUM, para filtrar os chamados, Assine a versão PREMIUM e libere esse e mais recursos!')
          } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
            toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
          } else {
            this.props.modificaFiltroChamado('Atendente');
            this.props.modificaSelectTextChamado('Selecione o Atendente');
            this.props.modificaValorArraySectionedFiltroChamado(this.props.atendenteNovoChamado)
            //
            //
          }
        }}>
          <i className="icon-atendimentos" style={{ color: '#000', fontSize: 12, marginLeft: 3 }} />
          <TextMenuFiltroAtendimento >Atendente</TextMenuFiltroAtendimento>
        </DivIconeTextFiltro>
      )
    } else if (this.props.isAdmin === true && this.props.nomeRota == 'Atendimentos') {
      return (
        <DivIconeTextFiltro onClick={() => {
          if (this.props.premium == 0) {
            toastr.error('Recurso Premium', 'Este é um recurso PREMIUM, para filtrar os chamados, Assine a versão PREMIUM e libere esse e mais recursos!')
          } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
            toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
          } else {
            this.props.modificaFiltroChamado('Atendente');
            this.props.modificaSelectTextChamado('Selecione o Atendente');
            this.props.modificaValorArraySectionedFiltroChamado(this.props.atendenteNovoChamado)
          }
        }}>
          <i className="icon-atendimentos" style={{ color: '#000', fontSize: 12, marginLeft: 3 }} />
          <TextMenuFiltro >Atendente</TextMenuFiltro>
        </DivIconeTextFiltro>
      )
    } else {
      return null;
    }
  }

  render() {
    const menu = (
      <DivMenuNovo>
        <TextNovoMenuLink to="/atendimentos" onClick={() => {

          this.props.modificaVisibilidadeModalNovoAtendimento(true)

        }}>Novo Atendimento</TextNovoMenuLink>
        <TextNovoMenuLink to="/clientes" onClick={() => {/* history.push('/clientes'); */ this.props.zeraInformacoesCadastroClienteExport(); this.props.modificaVisibleModalNovoCLiente(true); }}>Novo Cliente</TextNovoMenuLink>
        <TextNovoMenuLink to="/atendentes" onClick={() => { this.props.zeraInformacoesCadastroAtendenteExport(); this.props.modificaVisibleModalNovoAtendente(true); }}>Novo Atendente</TextNovoMenuLink>
        <TextNovoMenuLink to="/setores" onClick={() => { this.props.modificaVisibleModalNovoSetor(true) }}>Novo Setor</TextNovoMenuLink>
        <TextNovoMenuLink to="/avisos" onClick={() => {
          if (this.props.premium == 0) {
            toastr.error('Recurso Premium', 'Este é um recurso PREMIUM, Assine a versão PREMIUM e libere esse e mais recursos!');
          } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
            toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
          } else {
            this.props.zerCadastroAvisoExport(); this.props.modificaVisibleModalNovoAviso(true)
          }
        }}>Novo Aviso</TextNovoMenuLink>
      </DivMenuNovo>
    );
    const menuPerfil = (
      <DivMenuPerfil>
        <TextLinkPerfil onClick={() => {
          this.props.acessouMeuPErfil(true);
          this.props.modificaFiltroClientesTelaAtendentes('atendentes')
          //history.push('/atendentes')
          //

        }}
          to="/atendentes"
        >Meu Perfil</TextLinkPerfil>
        <TextPerfil onClick={() => {

          this.props.sairDoApp()
          // window.location = "/login";
        }}>Sair</TextPerfil>
      </DivMenuPerfil>
    );
    const filtrarAtendimento = (
      <DivMenuFiltroAtendimento>
        <TextFiltrarPor>Filtrar Por:</TextFiltrarPor>
        <DivIconeTextFiltro onClick={() => {
          if (this.props.premium == 0) {
            toastr.error('Recurso Premium', 'Este é um recurso PREMIUM, para filtrar os chamados, Assine a versão PREMIUM e libere esse e mais recursos!')
          } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
            toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
          } else {
            this.props.modificaFiltroChamado('Cliente');
            this.props.modificaSelectTextChamado('Selecione o Cliente');
            this.props.modificaValorArraySectionedFiltroChamado(this.props.clientesNovoChamado)
          }
        }}>
          <i className="icon-man-user" style={{ color: '#000', fontSize: 12, marginLeft: 3 }} />
          <TextMenuFiltroAtendimento >Cliente</TextMenuFiltroAtendimento>
        </DivIconeTextFiltro>
        {this.renderFiltroAtendente()}

        <DivIconeTextFiltro onClick={() => {
          if (this.props.premium == 0) {
            toastr.error('Recurso Premium', 'Este é um recurso PREMIUM, para filtrar os chamados, Assine a versão PREMIUM e libere esse e mais recursos!')
          } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
            toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
          } else {
            this.props.modificaFiltroChamado('Setor');
            this.props.modificaSelectTextChamado('Selecione o Setor');
            this.props.modificaValorArraySectionedFiltroChamado(this.props.setorNovoChamado)
          }
        }}>
          <i className="icon-icon" style={{ color: '#000', fontSize: 12, marginLeft: 3 }} />
          <TextMenuFiltroAtendimento >Setor</TextMenuFiltroAtendimento>
        </DivIconeTextFiltro>
        <DivIconeTextFiltro onClick={() => {
          if (this.props.premium == 0) {
            toastr.error('Recurso Premium', 'Este é um recurso PREMIUM, para filtrar os chamados, Assine a versão PREMIUM e libere esse e mais recursos!')
          } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
            toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
          } else {
            this.props.modificaFiltroChamado('Número Chamado');
            this.props.modificaSelectTextChamado('Digite o Numero do Chamado');
          }
        }}>
          <i className="icon-numbers" style={{ color: '#000', fontSize: 12, marginLeft: 3 }} />
          <TextMenuFiltroAtendimento>Número Chamado</TextMenuFiltroAtendimento>
        </DivIconeTextFiltro>
        <DivIconeTextFiltro onClick={() => {
          if (this.props.premium == 0) {
            toastr.error('Recurso Premium', 'Este é um recurso PREMIUM, para filtrar os chamados, Assine a versão PREMIUM e libere esse e mais recursos!')
          } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
            toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
          } else {
            this.props.modificaFiltroChamado('Vencimento');
            this.props.modificaSelectTextChamado('Selecione o Periodo de Vencimento');

            const vencimento = [
              {
                name: "Vencimento",
                type: 'group',
                items: [
                  { value: "Ontem", name: "Ontem" },
                  { value: "Hoje", name: "Hoje" },
                  { value: "Amanhã", name: "Amanhã" },
                  { value: "Semana Atual", name: "Semana Atual" },
                  { value: "Semana Passada", name: "Semana Passada" },
                  { value: "Mês Atual", name: "Mês Atual" },
                  { value: "Mês Passado", name: "Mês Passado" },
                ]
              }
            ];
            this.props.modificaValorArraySectionedFiltroChamado(vencimento)
          }
        }}>
          <i className="icon-vencimento" style={{ color: '#000', fontSize: 12, marginLeft: 3 }} />
          <TextMenuFiltroAtendimento >Vencimento</TextMenuFiltroAtendimento>
        </DivIconeTextFiltro>
        <DivIconeTextFiltro onClick={() => {
          if (this.props.premium == 0) {
            toastr.error('Recurso Premium', 'Este é um recurso PREMIUM, para filtrar os chamados, Assine a versão PREMIUM e libere esse e mais recursos!')
          } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
            toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
          } else {
            this.props.modificaFiltroChamado('Prioridade');
            this.props.modificaSelectTextChamado('Selecione a Prioridade');
            const prioridade = [
              {
                name: "Prioridades",
                type: 'group',
                items: [
                  { value: "Baixa", name: "Baixa" },
                  { value: "Normal", name: "Normal" },
                  { value: "Alta", name: "Alta" },
                  { value: "Urgente", name: "Urgente" }
                ]
              }
            ];
            this.props.modificaValorArraySectionedFiltroChamado(prioridade)
          }
        }}>
          <i className="icon-danger1" style={{ color: '#000', fontSize: 12, marginLeft: 3 }} />
          <TextMenuFiltroAtendimento >Prioridade</TextMenuFiltroAtendimento>
        </DivIconeTextFiltro>
        <DivIconeTextFiltro onClick={() => {
          if (this.props.premium == 0) {
            toastr.error('Recurso Premium', 'Este é um recurso PREMIUM, para filtrar os chamados, Assine a versão PREMIUM e libere esse e mais recursos!')
          } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
            toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
          } else {
            this.props.modificaFiltroChamado('Local');
            this.props.modificaSelectTextChamado('Selecione o Local');
            const local = [
              {
                name: "Local do Atendimento",
                type: 'group',
                items: [
                  { value: "Interno", name: "Interno" },
                  { value: "Externo", name: "Externo" }
                ]
              }
            ];
            this.props.modificaValorArraySectionedFiltroChamado(local)
          }
        }}>
          <i className="icon-localization" style={{ color: '#000', fontSize: 12, marginLeft: 3 }} />
          <TextMenuFiltroAtendimento >Local</TextMenuFiltroAtendimento>
        </DivIconeTextFiltro>
      </DivMenuFiltroAtendimento>
    );
    const filtrarDashboard = (
      <DivMenuFiltro>
        <TextFiltrarPor>Filtrar Por:</TextFiltrarPor>
        <DivIconeTextFiltro onClick={() => {
          if (this.props.premium == 0) {
            toastr.error('Recurso Premium', 'Este é um recurso PREMIUM, para filtrar os chamados, Assine a versão PREMIUM e libere esse e mais recursos!')
          } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
            toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
          } else {
            this.props.modificaFiltroPorDataDashboard('Personalizada');
          }
        }}>
          <TextMenuFiltroAtendimento >Data Personalizada</TextMenuFiltroAtendimento>
        </DivIconeTextFiltro>
        <DivIconeTextFiltro onClick={() => {
          if (this.props.premium == 0) {
            toastr.error('Recurso Premium', 'Este é um recurso PREMIUM, para filtrar os chamados, Assine a versão PREMIUM e libere esse e mais recursos!')
          } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
            toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
          } else {
            this.props.modificaFiltroPorDataDashboard('Hoje');
            let dataHoje = moment().toDate();
            dataHoje = moment(dataHoje)
            this.props.insereDataFiltroDashboard(dataHoje, dataHoje);
          }
        }}>
          <TextMenuFiltroAtendimento >Hoje</TextMenuFiltroAtendimento>
        </DivIconeTextFiltro>


        <DivIconeTextFiltro onClick={() => {
          if (this.props.premium == 0) {
            toastr.error('Recurso Premium', 'Este é um recurso PREMIUM, para filtrar os chamados, Assine a versão PREMIUM e libere esse e mais recursos!')
          } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
            toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
          } else {
            this.props.modificaFiltroPorDataDashboard('Ontem');
            let datadeOntem = moment().subtract(1, 'days').toDate(); // pego data atual
            datadeOntem = moment(datadeOntem);
            this.props.insereDataFiltroDashboard(datadeOntem, datadeOntem);
          }
        }}>
          <TextMenuFiltroAtendimento >Ontem</TextMenuFiltroAtendimento>
        </DivIconeTextFiltro>
        <DivIconeTextFiltro onClick={() => {
          if (this.props.premium == 0) {
            toastr.error('Recurso Premium', 'Este é um recurso PREMIUM, para filtrar os chamados, Assine a versão PREMIUM e libere esse e mais recursos!')
          } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
            toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
          } else {
            this.props.modificaFiltroPorDataDashboard('Semana Atual');
            const comecoDaSemana = moment().startOf('week').toDate();
            const fimDaSemana = moment().endOf('week').toDate();
            let dataComecoSemana = moment(comecoDaSemana);
            let dataFimSemana = moment(fimDaSemana);
            this.props.insereDataFiltroDashboard(dataComecoSemana, dataFimSemana);
          }
        }}>
          <TextMenuFiltroAtendimento>Semana Atual</TextMenuFiltroAtendimento>
        </DivIconeTextFiltro>
        <DivIconeTextFiltro onClick={() => {
          if (this.props.premium == 0) {
            toastr.error('Recurso Premium', 'Este é um recurso PREMIUM, para filtrar os chamados, Assine a versão PREMIUM e libere esse e mais recursos!')
          } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
            toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
          } else {
            this.props.modificaFiltroPorDataDashboard('Semana Passada');
            let comecoSemanaPassada = moment().weekday(-7).toDate();
            let fimDaSemanaPassada = moment().weekday(-7).add(6, 'day').toDate();
            let dataComecoSemanaPassada = moment(comecoSemanaPassada);
            let dataFimDaSemanaPassada = moment(fimDaSemanaPassada);
            this.props.insereDataFiltroDashboard(dataComecoSemanaPassada, dataFimDaSemanaPassada);

          }
        }}>
          <TextMenuFiltroAtendimento >Semana Passada</TextMenuFiltroAtendimento>
        </DivIconeTextFiltro>
        <DivIconeTextFiltro onClick={() => {
          if (this.props.premium == 0) {
            toastr.error('Recurso Premium', 'Este é um recurso PREMIUM, para filtrar os chamados, Assine a versão PREMIUM e libere esse e mais recursos!')
          } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
            toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
          } else {
            this.props.modificaFiltroPorDataDashboard('Mês Atual');
            const nomeMesAtual = moment().startOf('month').format('MMMM');
            const comecoDoMesAtual = moment().startOf('month');
            const fimDoMesAtual = moment().endOf('month');
            this.props.insereDataFiltroDashboard(comecoDoMesAtual, fimDoMesAtual);
          }
        }}>
          <TextMenuFiltroAtendimento >Mês Atual</TextMenuFiltroAtendimento>
        </DivIconeTextFiltro>
        <DivIconeTextFiltro onClick={() => {
          if (this.props.premium == 0) {
            toastr.error('Recurso Premium', 'Este é um recurso PREMIUM, para filtrar os chamados, Assine a versão PREMIUM e libere esse e mais recursos!')
          } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
            toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
          } else {
            this.props.modificaFiltroPorDataDashboard('Mês Passado');
            const nomeMesPassado = moment().subtract(1, 'months').startOf('month').format('MMMM');
            const comecoDoMesPassado = moment().subtract(1, 'months').startOf('month');
            const fimDoMesPassado = moment().subtract(1, 'months').endOf('month');
            this.props.insereDataFiltroDashboard(comecoDoMesPassado, fimDoMesPassado)
          }
        }}>
          <TextMenuFiltroAtendimento >Mês Passado</TextMenuFiltroAtendimento>
        </DivIconeTextFiltro>
      </DivMenuFiltro>
    );
    const filtrarClientes = (
      <DivMenuFiltroClientes>
        <TextFiltrarPor>Filtrar Por:</TextFiltrarPor>
        <DivIconeTextFiltro onClick={() => {
          this.props.modificaFiltroClientesTelaClientes('clientes')
        }}>

          <TextMenuFiltro >Nome Cliente</TextMenuFiltro>
        </DivIconeTextFiltro>
      </DivMenuFiltroClientes>
    );
    const filtrarAtendentes = (
      <DivMenuFiltroClientes>
        <TextFiltrarPor>Filtrar Por:</TextFiltrarPor>
        <DivIconeTextFiltro onClick={() => {
          this.props.modificaFiltroClientesTelaAtendentes('atendentes')
        }}>

          <TextMenuFiltro >Nome Atendente</TextMenuFiltro>
        </DivIconeTextFiltro>
      </DivMenuFiltroClientes>
    );
    const filtrarRelatorioAtendimentoDetalhado = (
      <DivMenuFiltroAtendimentoDetalhado>
        <TextFiltrarPor>Filtrar Por:</TextFiltrarPor>
        <DivIconeTextFiltro onClick={() => {
          this.props.modificaFiltroSelecionadoRelatorioDetalhado('cliente')
        }}>

          <TextMenuFiltro >Cliente</TextMenuFiltro>
        </DivIconeTextFiltro>
        <DivIconeTextFiltro onClick={() => {
          this.props.modificaFiltroSelecionadoRelatorioDetalhado('atendente')
        }}>

          <TextMenuFiltro >Atendente</TextMenuFiltro>
        </DivIconeTextFiltro>
        <DivIconeTextFiltro onClick={() => {
          this.props.modificaFiltroSelecionadoRelatorioDetalhado('setor')
        }}>

          <TextMenuFiltro >Setor</TextMenuFiltro>
        </DivIconeTextFiltro>
      </DivMenuFiltroAtendimentoDetalhado>
    );
    const filtrarAvaliacoes = (
      <DivMenuFiltroAtendimentoDetalhado>
        <TextFiltrarPor>Filtrar Por:</TextFiltrarPor>
        <DivIconeTextFiltro onClick={() => {
          this.props.modificaFiltroAvaliacao('Cliente')
          // this.props.modificaFiltroAvaliacaoWeb(this.props.clientesTodasAvaliacoes)
        }}>

          <TextMenuFiltro >Cliente</TextMenuFiltro>
        </DivIconeTextFiltro>
        <DivIconeTextFiltro onClick={() => {
          this.props.modificaFiltroAvaliacao('Atendente')
          //  this.props.modificaFiltroAvaliacaoWeb(this.props.atendenteTodasAvaliacoes)
        }}>

          <TextMenuFiltro >Atendente</TextMenuFiltro>
        </DivIconeTextFiltro>
        <DivIconeTextFiltro onClick={() => {
          this.props.modificaFiltroAvaliacao('Avaliação')
          //  this.props.modificaFiltroAvaliacaoWeb(this.props.valorAvaliacaoTodasAvaliacoes)
        }}>

          <TextMenuFiltro >Avaliação</TextMenuFiltro>
        </DivIconeTextFiltro>
      </DivMenuFiltroAtendimentoDetalhado>
    );
    const filtrarSetores = (
      <DivMenuFiltroClientes>
        <TextFiltrarPor>Filtrar Por:</TextFiltrarPor>
        <DivIconeTextFiltro onClick={() => {
          this.props.modificaFiltroSetoresTelaSetores('setores')
        }}>

          <TextMenuFiltro >Nome Setor</TextMenuFiltro>
        </DivIconeTextFiltro>
      </DivMenuFiltroClientes>
    );
    const filtrarAvaliacaoGeral = (
      <DivMenuFiltroClientes>
        <TextFiltrarPor>Filtrar Por:</TextFiltrarPor>
        <DivIconeTextFiltro onClick={() => {
          this.props.modificaFiltroRelatorioAvaliacaoGeral('atendentes')
        }}>

          <TextMenuFiltro >Atendentes</TextMenuFiltro>
        </DivIconeTextFiltro>
      </DivMenuFiltroClientes>
    );
    const filtrarAvisos = (
      <DivMenuFiltroAvisos>
        <TextFiltrarPorAvisos>Filtrar Por:</TextFiltrarPorAvisos>
        <DivIconeTextFiltro onClick={() => {
          this.props.alteraFiltroSelecionadoTelaNovoAviso('TODOS');
        }}>
          <TextMenuFiltroAvisos >Todos</TextMenuFiltroAvisos>
        </DivIconeTextFiltro>
        <DivIconeTextFiltro onClick={() => {
          this.props.alteraFiltroSelecionadoTelaNovoAviso('ATENDENTES');
        }}>
          <TextMenuFiltroAvisos >Atendentes</TextMenuFiltroAvisos>
        </DivIconeTextFiltro>


        <DivIconeTextFiltro onClick={() => {
          this.props.alteraFiltroSelecionadoTelaNovoAviso('CLIENTES');
        }}>
          <TextMenuFiltroAvisos >Clientes</TextMenuFiltroAvisos>
        </DivIconeTextFiltro>
        <DivIconeTextFiltro onClick={() => {
          this.props.alteraFiltroSelecionadoTelaNovoAviso('ATIVOS');
        }}>
          <TextMenuFiltroAvisos>Ativos</TextMenuFiltroAvisos>
        </DivIconeTextFiltro>
        <DivIconeTextFiltro onClick={() => {
          this.props.alteraFiltroSelecionadoTelaNovoAviso('INATIVOS');
        }}>
          <TextMenuFiltroAvisos >Inativos</TextMenuFiltroAvisos>
        </DivIconeTextFiltro>

      </DivMenuFiltroAvisos>
    );


    return (
      <BrowserRouter translate="no" history={history}>
        <Helmet defer={false}>
          <meta charSet="utf-8" />
          {this.props.countMensagensNaoLidas > 0 && <title>({this.props.countMensagensNaoLidas.toString()}) DropDesk</title>}
          {this.props.countMensagensNaoLidas <= 0 && <title>DropDesk</title>}
        </Helmet>
        <AuthProvider>

          <Switch>
            {/*  <Route path="/" exact component={() => { window.location = 'https://www.dropdesk.com.br/'; return null;} }>
            </Route> */}
            <Route exact path="/">
              <Login />
            </Route>

            <Route exact path="/cadastro">
              <Cadastro />
            </Route>
            {/*  <PrivateRoute exact path='/login' component={Login} />
            <PrivateRoute exact path='/cadastro' component={Cadastro} /> */}


            <Container>
              <Modal focusTrapped={false} showCloseIcon={false} open={this.props.isMaintenance} onClose={() => { }} center>
                <ContainerModalCompraSucesso backgroundDinamico='#29454D'>
                  <ContainerIconeFecharModal backgroundDinamico='#3F535A'>
                    <TextNovoAtendimento corLetraDinamico='#FFF'>MANUNTENÇÃO</TextNovoAtendimento>
                    {/*  <div>
                      <IconeFechar backgroundDinamico='#FFF' onClick={() => {
                        this._toggleModalVersaoPremiumSucesso()
                      }} className="icon-close" />
                    </div> */}
                  </ContainerIconeFecharModal>

                  <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    <div style={{}}>
                      <img src={backGroundStopVersaoPremium} style={{ width: '100%', height: 300 }} />
                    </div>
                    <div style={{ height: 60, justifyContent: 'center', alignItems: 'center', paddingHorizontal: 30, margin: 20, display: 'flex' }}>
                      <p style={{ fontSize: 19, fontWeight: 'bold', color: '#FFF', textAlign: 'center' }}></p>
                    </div>
                    <div stle={{ justifyContent: 'center', margin: 20, display: 'flex', alignItems: 'center' }}>
                      <p style={{ color: '#FFF', fontSize: 23, textAlign: 'center' }}>Estamos passando por uma MANUNTENÇÃO para melhorar ainda mais a ferramenta, retornaremos EM BREVE!</p>
                      {/*  <Text style={{ color: '#FFF', fontSize: 13, textAlign: 'center' }}>Qualquer dúvid.</Text> */}
                    </div>

                  </div>
                </ContainerModalCompraSucesso>
              </Modal>
              <Modal focusTrapped={false} showCloseIcon={false} open={this.props.isNewVersionAvailable} onClose={() => { }} center>
                <ContainerModalCompraSucesso backgroundDinamico='#29454D'>
                  <ContainerIconeFecharModal backgroundDinamico='#3F535A'>
                    <TextNovoAtendimento corLetraDinamico='#FFF'>Nova Atualização</TextNovoAtendimento>
                    {/*  <div>
                      <IconeFechar backgroundDinamico='#FFF' onClick={() => {
                        this._toggleModalVersaoPremiumSucesso()
                      }} className="icon-close" />
                    </div> */}
                  </ContainerIconeFecharModal>

                  <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    <div style={{}}>
                      <img src={backGroundStopVersaoPremium} style={{ width: '100%', height: 300 }} />
                    </div>
                    <div style={{ height: 60, justifyContent: 'center', alignItems: 'center', paddingHorizontal: 30, margin: 20, display: 'flex' }}>
                      <p style={{ fontSize: 19, fontWeight: 'bold', color: '#FFF', textAlign: 'center' }}></p>
                    </div>
                    <div stle={{ justifyContent: 'center', margin: 20, display: 'flex', alignItems: 'center' }}>
                      <p style={{ color: '#FFF', fontSize: 23, textAlign: 'center' }}>Há uma nova atualização do DropDesk, por favor atualize a página para continuar utilizando.</p>
                      {/*  <Text style={{ color: '#FFF', fontSize: 13, textAlign: 'center' }}>Qualquer dúvid.</Text> */}
                    </div>

                  </div>
                </ContainerModalCompraSucesso>
              </Modal>
              <ProSidebarTeste
                backgrounddinamico2={this.props.coresLetraSecundario}
                backgroundinamico={this.props.corFundoMenu}
                colordinamico={this.props.corLetraMenu}
                highlightdinamico={this.props.corBotaoPadrao}

                /*  className="pro-sidebar"
                 style={{ "--bg": String("red")}} */
                collapsed={this.state.menuOpen}
              // image={logoPadrao}
              >
                <SidebarHeader >

                  <ButtonMenu >

                    <ButtonExpandeMenu type="button" onClick={() => this._toggleDrawerMenu()}>
                      <i className="icon-open-menu1" style={{
                        color: '#FFF', fontSize: 17,
                        //backgroundColor:'rgba(173, 173, 173, 0.2)',padding:5,borderRadius:5
                      }} />
                    </ButtonExpandeMenu>


                  </ButtonMenu>
                  <BorderBottomMenu ></BorderBottomMenu>
                </SidebarHeader>
                <SidebarContent >
                  <MenuSide iconShape="round">

                    <MenuItemSide translate="no" active={this.props.nomeRota == 'Dashboard' ? true : false} title='Dashboard' onClick={() => { this.props.modificaNomeRotaNoHeader('Dashboard') }} icon={<i className="icon-dashboardoff" style={{ color: '#FFF', fontSize: 18 }} />}>Dashboard  <Link to="/dashboard" /> </MenuItemSide>

                    <MenuItemSide translate="no" active={this.props.nomeRota == 'Atendimentos' || this.props.nomeRota.includes('Atendimento #') ? true : false} title='Atendimentos' onClick={() => { this.props.modificaNomeRotaNoHeader('Atendimentos') }} icon={<i className="icon-headset" style={{ color: '#FFF', fontSize: 18 }} />}>Atendimentos <Link to="/atendimentos" /></MenuItemSide>
                    {this.renderMenuAtendimentoOnline()}
                    {this.renderMenuVersaoPremium()}

                    <BorderBottomMenu ></BorderBottomMenu>
                    {this.renderMenuCliente()}
                    {this.renderMenuAtendente()}
                    {this.renderMenuSetores()}
                    {this.renderMenuAvisos()}
                    {this.renderMenuRelatorios()}
                    {/*  <BorderBottomMenu ></BorderBottomMenu> */}
                    {this.renderMenuConfiguracoes()}
                    {/*     <MenuItemSide translate="no" active={this.props.nomeRota == 'Sair' ? true : false} title='Sair' onClick={() => { this.props.modificaModalSair(true) }} icon={<i className="icon-logout" style={{ color: '#FFF', fontSize: 18 }} />}>Sair</MenuItemSide> */}


                  </MenuSide>
                </SidebarContent >
                <SidebarFooter>
                  {this.renderImagemLogoMenu()}

                  {/*                     <img src={this.props.logotipo} alt="logo" style={{ height: this.state.menuOpen == false ? '50%': '80%', width: this.state.menuOpen == false ? '50%': '80%' }} /> */}


                </SidebarFooter>
              </ProSidebarTeste>
              <SubContainer>
                <HeaderPadrao backgroundDinamico={this.props.backgroundSegundo}>
                  <NomeRota colorDinamico={this.props.coresLetraPadrao}>{this.props.nomeRota}   {this.props.nomeRota.includes('Atendimento #') &&
                    <Link to="/atendimentos" style={{ fontSize: 13, textDecoration: 'none', color: this.props.coresLetraPadrao, fontWeight: 'bold', backgroundColor: 'transparent', border: 0 }} ><i className="icon-setavoltar" style={{ color: this.props.coresLetraPadrao, marginRight: 5, fontSize: 14, marginLeft: 45, fontWeight: 'bold' }} />Voltar</Link>
                  }
                    {this.props.nomeRota.includes('Exportar Clientes') &&
                      <Link to="/clientes" style={{ fontSize: 13, textDecoration: 'none', color: this.props.coresLetraPadrao, fontWeight: 'bold', backgroundColor: 'transparent', border: 0 }} ><i className="icon-setavoltar" style={{ color: this.props.coresLetraPadrao, marginRight: 5, fontSize: 14, marginLeft: 45, fontWeight: 'bold' }} />Voltar</Link>
                    }</NomeRota>
                  <ContainerPerfil>
                    {this.props.nomeRota == 'Atendimentos' &&
                      <Dropdown
                        trigger={['click']}
                        overlay={filtrarAtendimento}
                        animation="slide-up"
                        onVisibleChange={this.onVisibleChange}
                      >
                        <ButtonVariosNovos backgroundbotao={this.props.corSegundoBotaoPadrao} hoverbotao={this.props.coresLetraPadrao} type="submit" >
                          <IconeVariosNovos className="icon-funnel" colorPrimario={this.props.letraBotaoPadrao} />
                          <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Filtrar</TextBotaoNovo>
                          <IconeVariosNovosSeta className="icon-angle-arrow-down" colorPrimario={this.props.letraBotaoPadrao} />
                        </ButtonVariosNovos>
                      </Dropdown>
                    }
                    {this.props.nomeRota == 'Mural de Avisos' &&
                      <Dropdown
                        trigger={['click']}
                        overlay={filtrarAvisos}
                        animation="slide-up"
                        onVisibleChange={this.onVisibleChange}
                      >
                        <ButtonVariosNovos backgroundbotao={this.props.corSegundoBotaoPadrao} hoverbotao={this.props.coresLetraPadrao} type="submit" >
                          <IconeVariosNovos className="icon-funnel" colorPrimario={this.props.letraBotaoPadrao} />
                          <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Filtrar</TextBotaoNovo>
                          <IconeVariosNovosSeta className="icon-angle-arrow-down" colorPrimario={this.props.letraBotaoPadrao} />
                        </ButtonVariosNovos>
                      </Dropdown>
                    }
                    {this.props.nomeRota == 'Relatório Avaliação Geral' &&
                      <Dropdown
                        trigger={['click']}
                        overlay={filtrarAvaliacaoGeral}
                        animation="slide-up"
                        onVisibleChange={this.onVisibleChange}
                      >
                        <ButtonVariosNovos backgroundbotao={this.props.corSegundoBotaoPadrao} hoverbotao={this.props.coresLetraPadrao} type="submit" >
                          <IconeVariosNovos className="icon-funnel" colorPrimario={this.props.letraBotaoPadrao} />
                          <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Filtrar</TextBotaoNovo>
                          <IconeVariosNovosSeta className="icon-angle-arrow-down" colorPrimario={this.props.letraBotaoPadrao} />
                        </ButtonVariosNovos>
                      </Dropdown>
                    }
                    {this.props.nomeRota == 'Relatório Registros Avaliações' &&
                      <Dropdown
                        trigger={['click']}
                        overlay={filtrarAvaliacoes}
                        animation="slide-up"
                        onVisibleChange={this.onVisibleChange}
                      >
                        <ButtonVariosNovos backgroundbotao={this.props.corSegundoBotaoPadrao} hoverbotao={this.props.coresLetraPadrao} type="submit" >
                          <IconeVariosNovos className="icon-funnel" colorPrimario={this.props.letraBotaoPadrao} />
                          <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Filtrar</TextBotaoNovo>
                          <IconeVariosNovosSeta className="icon-angle-arrow-down" colorPrimario={this.props.letraBotaoPadrao} />
                        </ButtonVariosNovos>
                      </Dropdown>
                    }
                    {this.props.nomeRota == 'Clientes' && this.props.permissoesAcessarTelasAtendente.permissaoAdministrador === true &&

                      <ButtonVariosNovosExcel to="/exportClients" data-tip="Exportar Clientes para EXCEL" data-for="tip-top" backgroundbotao={this.props.corSegundoBotaoPadrao} hoverbotao={this.props.coresLetraPadrao} type="button" >

                        <div style={{ width: 5, height: 5 }}></div>
                        <IconeVariosNovos className="icon-excel" colorPrimario={"green"} />
                        <div style={{ width: 5, height: 5 }}></div>
                        {/*  <IconeVariosNovosSeta className="icon-angle-arrow-down" colorPrimario={this.props.letraBotaoPadrao} /> */}
                      </ButtonVariosNovosExcel>}
                    {this.props.nomeRota == 'Clientes' &&

                      <ButtonPortalCliente onClick={() => {
                        this.props.modificaVisibilidadeModalPortalCliente(true);
                        this.props.verificaSeJaTeMCodigoCadastraCliente()
                      }} backgroundbotao={this.props.corBotaoPadrao} hoverbotao={this.props.coresLetraPadrao} type="button" >
                        <IconeVariosNovos style={{ marginTop: 3 }} className="icon-web-site" colorPrimario={this.props.letraBotaoPadrao} />
                        <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Portal do Cliente</TextBotaoNovo>
                        <div style={{ width: 10 }}></div>
                      </ButtonPortalCliente>

                    }

                    {this.props.nomeRota == 'Clientes' &&

                      <ButtonVariosNovos onClick={() => { this.props.modificaVisibilidadeModalImportarClientes(true) }} data-tip="Importar Clientes a partir do EXCEL" data-for="tip-top" backgroundbotao={this.props.corSegundoBotaoPadrao} hoverbotao={this.props.coresLetraPadrao} type="button" >
                        <IconeVariosNovos className="icon-download" colorPrimario={this.props.letraBotaoPadrao} />
                        <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Importar</TextBotaoNovo>
                        <div style={{ width: 20, height: 20 }}></div>
                        {/*  <IconeVariosNovosSeta className="icon-angle-arrow-down" colorPrimario={this.props.letraBotaoPadrao} /> */}
                      </ButtonVariosNovos>}


                    {this.props.nomeRota == 'Clientes' &&
                      <Dropdown
                        trigger={['click']}
                        overlay={filtrarClientes}
                        animation="slide-up"
                        onVisibleChange={this.onVisibleChange}
                      >
                        <ButtonVariosNovos backgroundbotao={this.props.corSegundoBotaoPadrao} hoverbotao={this.props.coresLetraPadrao} type="button" >
                          <IconeVariosNovos className="icon-funnel" colorPrimario={this.props.letraBotaoPadrao} />
                          <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Filtrar</TextBotaoNovo>
                          <IconeVariosNovosSeta className="icon-angle-arrow-down" colorPrimario={this.props.letraBotaoPadrao} />
                        </ButtonVariosNovos>
                      </Dropdown>
                    }

                    {this.props.nomeRota == 'Atendentes' &&
                      <Dropdown
                        trigger={['click']}
                        overlay={filtrarAtendentes}
                        animation="slide-up"
                        onVisibleChange={this.onVisibleChange}
                      >
                        <ButtonVariosNovos backgroundbotao={this.props.corSegundoBotaoPadrao} hoverbotao={this.props.coresLetraPadrao} type="submit" >
                          <IconeVariosNovos className="icon-funnel" colorPrimario={this.props.letraBotaoPadrao} />
                          <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Filtrar</TextBotaoNovo>
                          <IconeVariosNovosSeta className="icon-angle-arrow-down" colorPrimario={this.props.letraBotaoPadrao} />
                        </ButtonVariosNovos>
                      </Dropdown>
                    }
                    {this.props.nomeRota == 'Relatório Atendimentos' &&
                      <Dropdown
                        trigger={['click']}
                        overlay={filtrarRelatorioAtendimentoDetalhado}
                        animation="slide-up"
                        onVisibleChange={this.onVisibleChange}
                      >
                        <ButtonVariosNovos backgroundbotao={this.props.corSegundoBotaoPadrao} hoverbotao={this.props.coresLetraPadrao} type="submit" >
                          <IconeVariosNovos className="icon-funnel" colorPrimario={this.props.letraBotaoPadrao} />
                          <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Filtrar</TextBotaoNovo>
                          <IconeVariosNovosSeta className="icon-angle-arrow-down" colorPrimario={this.props.letraBotaoPadrao} />
                        </ButtonVariosNovos>
                      </Dropdown>
                    }
                    {this.props.nomeRota == 'Setores' &&
                      <Dropdown
                        trigger={['click']}
                        overlay={filtrarSetores}
                        animation="slide-up"
                        onVisibleChange={this.onVisibleChange}
                      >
                        <ButtonVariosNovos backgroundbotao={this.props.corSegundoBotaoPadrao} hoverbotao={this.props.coresLetraPadrao} type="submit" >
                          <IconeVariosNovos className="icon-funnel" colorPrimario={this.props.letraBotaoPadrao} />
                          <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Filtrar</TextBotaoNovo>
                          <IconeVariosNovosSeta className="icon-angle-arrow-down" colorPrimario={this.props.letraBotaoPadrao} />
                        </ButtonVariosNovos>
                      </Dropdown>
                    }
                    {this.props.nomeRota == 'Dashboard' &&
                      <Dropdown
                        trigger={['click']}
                        overlay={filtrarDashboard}
                        animation="slide-up"
                        onVisibleChange={this.onVisibleChange}
                      >
                        <ButtonVariosNovos backgroundbotao={this.props.corSegundoBotaoPadrao} hoverbotao={this.props.coresLetraPadrao} type="submit" >
                          <IconeVariosNovos className="icon-funnel" colorPrimario={this.props.letraBotaoPadrao} />
                          <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Filtrar</TextBotaoNovo>
                          <IconeVariosNovosSeta className="icon-angle-arrow-down" colorPrimario={this.props.letraBotaoPadrao} />
                        </ButtonVariosNovos>
                      </Dropdown>
                    }
                    <Dropdown
                      trigger={['click']}
                      overlay={menu}
                      animation="slide-up"
                      onVisibleChange={this.onVisibleChange}
                    >
                      <ButtonVariosNovos backgroundbotao={this.props.corSegundoBotaoPadrao} hoverbotao={this.props.coresLetraPadrao}>
                        <IconeVariosNovos className="icon-add2-1" colorPrimario={this.props.letraBotaoPadrao} />
                        <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Novo</TextBotaoNovo>
                        <IconeVariosNovosSeta className="icon-angle-arrow-down" colorPrimario={this.props.letraBotaoPadrao} />
                      </ButtonVariosNovos>
                    </Dropdown>
                    {this.props.nomeRota === 'Atendimentos' && <ButtonAddNew style={{ width: 150 }} onClick={() => {
                      this.props.modificaVisibilidadeModalNovoAtendimento(true)
                    }} to="/atendimentos" backgroundbotao={this.props.corSegundoBotaoPadrao} hoverbotao={this.props.coresLetraPadrao}>
                      <IconeVariosNovos className="icon-add2-1" colorPrimario={this.props.letraBotaoPadrao} />
                      <p style={{
                        color: this.props.letraBotaoPadrao,
                        fontSize: 13,
                        fontWeight: 'bold',
                        textAlign: 'center',
                        textDecoration: 'none'
                      }} >Novo Atendimento</p>
                      <div style={{ width: 15 }}></div>
                    </ButtonAddNew>}
                    {this.props.nomeRota === 'Clientes' && <ButtonAddNew style={{ width: 120 }} onClick={() => {
                      this.props.zeraInformacoesCadastroClienteExport();
                      this.props.modificaVisibleModalNovoCLiente(true);
                    }} to="/clientes" backgroundbotao={this.props.corSegundoBotaoPadrao} hoverbotao={this.props.coresLetraPadrao}>
                      <IconeVariosNovos className="icon-add2-1" colorPrimario={this.props.letraBotaoPadrao} />
                      <p style={{
                        color: this.props.letraBotaoPadrao,
                        fontSize: 13,
                        fontWeight: 'bold',
                        textAlign: 'center',
                        textDecoration: 'none'
                      }} >Novo Cliente</p>
                      <div style={{ width: 15 }}></div>
                    </ButtonAddNew>}
                    {this.props.nomeRota === 'Atendentes' && <ButtonAddNew style={{ width: 135 }} onClick={() => {
                      this.props.zeraInformacoesCadastroAtendenteExport(); this.props.modificaVisibleModalNovoAtendente(true);
                    }} to="/atendentes" backgroundbotao={this.props.corSegundoBotaoPadrao} hoverbotao={this.props.coresLetraPadrao}>
                      <IconeVariosNovos className="icon-add2-1" colorPrimario={this.props.letraBotaoPadrao} />
                      <p style={{
                        color: this.props.letraBotaoPadrao,
                        fontSize: 13,
                        fontWeight: 'bold',
                        textAlign: 'center',
                        textDecoration: 'none'
                      }} >Novo Atendente</p>
                      <div style={{ width: 15 }}></div>
                    </ButtonAddNew>}
                    {this.props.nomeRota === 'Setores' && <ButtonAddNew style={{ width: 110 }} onClick={() => {
                      this.props.modificaVisibleModalNovoSetor(true)
                    }} to="/setores" backgroundbotao={this.props.corSegundoBotaoPadrao} hoverbotao={this.props.coresLetraPadrao}>
                      <IconeVariosNovos className="icon-add2-1" colorPrimario={this.props.letraBotaoPadrao} />
                      <p style={{
                        color: this.props.letraBotaoPadrao,
                        fontSize: 13,
                        fontWeight: 'bold',
                        textAlign: 'center',
                        textDecoration: 'none'
                      }} >Novo Setor</p>
                      <div style={{ width: 15 }}></div>
                    </ButtonAddNew>}
                    {this.props.nomeRota === 'Mural de Avisos' && <ButtonAddNew style={{ width: 110 }} onClick={() => {
                      this.props.zerCadastroAvisoExport(); this.props.modificaVisibleModalNovoAviso(true)
                    }} to="/avisos" backgroundbotao={this.props.corSegundoBotaoPadrao} hoverbotao={this.props.coresLetraPadrao}>
                      <IconeVariosNovos className="icon-add2-1" colorPrimario={this.props.letraBotaoPadrao} />
                      <p style={{
                        color: this.props.letraBotaoPadrao,
                        fontSize: 13,
                        fontWeight: 'bold',
                        textAlign: 'center',
                        textDecoration: 'none'
                      }} >Novo Aviso</p>
                      <div style={{ width: 15 }}></div>
                    </ButtonAddNew>}
                    {
                      this.props.nomeRota == "Versão Premium" &&
                      <ButtonNotificacao data-tip="Duvidas Frequentes" data-for="tip-top" type="button" onClick={() => { this.props.modificaModalDuvidasFrequentes(true) }}>
                        <IconeNotificacao className="icon-interrogacao" colorSecundario={this.props.coresLetraPadrao} colorPrimario={this.props.corSegundoBotaoPadrao} />
                      </ButtonNotificacao>
                    }
                    <ButtonNotificacao type="button" onClick={() => { this._toggleModalNotif(); this.props.enviaTotalNotificacoesParaReducerExport(0) }}>
                      {this.renderTextTotalNotif()}


                      <IconeNotificacao className="icon-sino" colorSecundario={this.props.coresLetraPadrao} colorPrimario={this.props.corSegundoBotaoPadrao} />
                    </ButtonNotificacao>
                    {this.state.modalNotif == true && <BackgroundModal onClick={() => { this._toggleModalNotif(); }}>

                    </BackgroundModal>}
                    {this.state.modalNotif == true &&

                      <ContainerNotif>
                        <HeaderNotif>
                          <TextNotification>Notificações</TextNotification>
                          {this.props.notificacoes.length > 0 && <TextMarcaLido onClick={() => { this.props.marcarTodasNotifComoLido(this.props.notificacoes) }}>Marcar como lido</TextMarcaLido>}
                        </HeaderNotif>
                        <SubContainerNotif>
                          <FlatList
                            list={this.props.notificacoes}
                            renderItem={(item) => {
                              let viaChat = item.viaChat == undefined ? 'false' : item.viaChat;
                              return (

                                <ContainerMensagem to={viaChat == 'false' ? '/visualizaatendimento' : '/atendimentoonline'} onClick={() => {
                                  if (viaChat == 'true') {
                                    firebase.database().ref(`${item.chaveUnica}/notificacoes/${item.key}`).update({ lido: "true" });
                                    this._toggleModalNotif();

                                  } else {


                                    firebase.database().ref(`${item.chaveUnica}/notificacoes/${item.key}`).update({ lido: "true" });
                                    this.props.navegaTelaALterarChamadoNotificacao(item.keyChamado, item.numeroChamado);
                                    this.props.modificaNomeRotaNoHeader(`Atendimento #${item.numeroChamado}`);
                                    this._toggleModalNotif();
                                  }
                                  // window.open(`/visualizaatendimento?key=${item.keyChamado}&num=${item.numeroChamado}`, '_blank');
                                }} key={item.key}>

                                  <ContainerImagem>
                                    {this.renderImagemNotif(item)}
                                  </ContainerImagem>
                                  <ContainerMensagemTexto>
                                    <ContainerTitle>
                                      <TitleNotif>
                                        {item.title}
                                      </TitleNotif>
                                      <SubTitleNotif>
                                        {item.body}
                                        {/*  */}
                                      </SubTitleNotif>
                                    </ContainerTitle>
                                    <ContainerDataNotif>
                                      <TextData>{item.dataAtual}</TextData>
                                      {
                                        item.lido == "false" &&
                                        <div style={{
                                          display: 'flex', flexDirection: 'row', height: 15, width: 35, backgroundColor: '#96CAE8', borderRadius: 15, marginBottom: 2,
                                          justifyContent: 'center', alignItems: 'center'
                                        }}>
                                          <i className="icon-star" style={{ color: '#0076B3', fontSize: 6 }} />
                                          <TextNew>Novo</TextNew>
                                        </div>}
                                    </ContainerDataNotif>
                                  </ContainerMensagemTexto>
                                </ContainerMensagem>

                              )
                            }
                            }
                            renderWhenEmpty={() => {
                              return (
                                <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                                  <SubTitleNotif>Você não tem notificações</SubTitleNotif>
                                </div>
                              )
                            }}
                          // hasMoreItems={this.props.loadingFimDaPagina}
                          //loadMoreItems={() => this.exibeUltimosChamadosAbertos()}
                          /* paginationLoadingIndicator={<MoonLoader
                            css={{ margin: 10 }}
                            size={40}
                            color={this.props.corBotaoPadrao}
                            loading={this.props.loadingFimDaPagina}
                          />} */
                          />
                        </SubContainerNotif>
                      </ContainerNotif>}
                    <DivNomeEmail>
                      <TextNome colorDinamico={this.props.coresLetraPadrao}>{this.props.nome}</TextNome>
                      <TextEmail colorDinamico={this.props.coresLetraSecundario}>{this.props.email}</TextEmail>
                    </DivNomeEmail>
                    <Dropdown
                      trigger={['click']}
                      overlay={menuPerfil}
                      animation="slide-up"
                      onVisibleChange={this.onVisibleChange}
                    >
                      {this.renderImagemPerfil()}
                    </Dropdown>

                  </ContainerPerfil>
                </HeaderPadrao>

                <PageBody corBackgroundDinamico={this.props.backgroundPrimeiro}>
                  <Switch>
                    <PrivateRoute exact path='/dashboard' component={Dashboard} />
                    <PrivateRoute exact path='/atendimentos' component={Atendimentos} />
                    <PrivateRoute exact path='/visualizaatendimento' component={AcoesChamado} />
                    <PrivateRoute exact path='/clientes' component={Clientes} />
                    <PrivateRoute exact path='/exportClients' component={ExportClients} />
                    <PrivateRoute exact path='/atendentes' component={Atendentes} />
                    <PrivateRoute exact path='/setores' component={Setor} />
                    <PrivateRoute exact path='/avisos' component={Avisos} />
                    <PrivateRoute exact path='/relatorioatendimentodetalhado' component={RelatorioAtendimentoDetalhado} />
                    <PrivateRoute exact path='/relatorioatendimentogeral' component={RelatorioAtendimentoGeral} />
                    <PrivateRoute exact path='/relatorioregistroavaliacao' component={RelatorioRegistroAvaliacoes} />
                    <PrivateRoute exact path='/RelatorioAvaliacaoGeral' component={RelatorioAvaliacaoGeral} />
                    <PrivateRoute exact path='/configuracao' component={Configuracao} />
                    <PrivateRoute exact path='/chamadoaberto' component={ChamadoAberto} />
                    <PrivateRoute exact path='/chamadoatendendo' component={ChamadoAtendendo} />
                    <PrivateRoute exact path='/chamadofechado' component={ChamadoFechado} />
                    <PrivateRoute exact path='/chamadocancelado' component={ChamadoCancelado} />
                    <PrivateRoute exact path='/chamadotodos' component={ChamadoTodos} />
                    <PrivateRoute exact path='/versaopremium' component={VersaoPremium} />
                    <PrivateRoute exact path='/atendimentoonline' component={AtendimentoOnline} />
                    <PrivateRoute exact path='/crm' component={RelatorioCRM} />
                    <PrivateRoute exact path='/paineladm' component={PainelADM} />

                    <Route path='*' component={NotFound} />
                  </Switch>
                </PageBody>

              </SubContainer>

              {/* <Detector
                polling={polling}
                render={({ online }) => {

                  if (online) {
                    let conexaoInternetOffline = JSON.parse(localStorage.getItem('internetConnectionDropDesk')) == null ? false : JSON.parse(localStorage.getItem('internetConnectionDropDesk'));

                    if (conexaoInternetOffline == true) {
                      return (
                        <BackgroundSemInternet>
                          <ContainerSemInternetLaranja>
                            <p style={{ fontSize: 18, color: '#000', fontWeight: 'bold', textAlign: 'left' }}>Você foi desconectado do DropDesk.</p>
                            <p style={{ fontSize: 18, color: '#000', fontWeight: 'bold', textAlign: 'left' }}>Sem conexão com a INTERNET.</p>
                            <p style={{ fontSize: 18, color: '#000', fontWeight: 'bold', textAlign: 'left' }}>Recarregue a página para voltar a se conectar.</p>
                          </ContainerSemInternetLaranja>
                          <ContainerSemInternet>
                            <p style={{ fontSize: 18, color: '#FFF', fontWeight: 'bold', textAlign: 'left' }}>Não foi possível conectar ao DropDesk.</p>
                            <div onClick={() => { window.location.reload(); }} style={{ height: 30, width: 160, backgroundColor: '#CD4A42', alignItems: 'center', justifyContent: 'center', display: 'flex', borderRadius: 5, cursor: 'pointer' }}><p style={{ fontSize: 18, color: '#FFF', fontWeight: 'bold', textAlign: 'left' }}>Recarregar Página</p></div>
                          </ContainerSemInternet>
                        </BackgroundSemInternet>
                      );
                    } else {

                      return null;
                    }

                  } else {
                    localStorage.setItem('internetConnectionDropDesk', JSON.stringify(true));
                    return (
                      <BackgroundSemInternet>
                        <ContainerSemInternetLaranja>
                          <p style={{ fontSize: 18, color: '#000', fontWeight: 'bold', textAlign: 'left' }}>Você foi desconectado do DropDesk.</p>
                          <p style={{ fontSize: 18, color: '#000', fontWeight: 'bold', textAlign: 'left' }}>Sem conexão com a INTERNET.</p>
                          <p style={{ fontSize: 18, color: '#000', fontWeight: 'bold', textAlign: 'left' }}>Recarregue a página para voltar a se conectar.</p>
                        </ContainerSemInternetLaranja>
                        <ContainerSemInternet>
                          <p style={{ fontSize: 18, color: '#FFF', fontWeight: 'bold', textAlign: 'left' }}>Não foi possível conectar ao DropDesk.</p>
                          <div onClick={() => { window.location.reload(); }} style={{ height: 30, width: 160, backgroundColor: '#CD4A42', alignItems: 'center', justifyContent: 'center', display: 'flex', borderRadius: 5, cursor: 'pointer' }}><p style={{ fontSize: 18, color: '#FFF', fontWeight: 'bold', textAlign: 'left' }}>Recarregar Página</p></div>
                        </ContainerSemInternet>
                      </BackgroundSemInternet>
                    );
                  }
                }}

              /> */}

              {/*   <Offline polling={polling}>
                <BackgroundSemInternet ref={this.simulateClick} onClick={()=> {this.insereConexaoOffline()}}>
                  <ContainerSemInternet>
                    <p>Sem Net</p>

                  </ContainerSemInternet>
                </BackgroundSemInternet>
              </Offline> */}
              {/*     {this.insereConexaoOffline()} */}




            </Container>



          </Switch>

        </AuthProvider>
        <ReactTooltip id="tip-top" place="top" effect="solid" multiline />
        <ReactTooltip id="tip-right" place="right" effect="solid" multiline />
      </BrowserRouter>

    );
  }
}
const mapStateToProps = state => {
  return (
    {
      backgroundPrimeiro: state.CoresPadraoSistema.backgroundPrimeiro,
      backgroundSegundo: state.CoresPadraoSistema.backgroundSegundo,
      backgroundMaisEscuro: state.CoresPadraoSistema.backgroundMaisEscuro,
      coresLetraPadrao: state.CoresPadraoSistema.coresLetraPadrao,
      coresLetraSecundario: state.CoresPadraoSistema.coresLetraSecundario,
      corFundoMenu: state.CoresPadraoSistema.corFundoMenu,
      corLetraMenu: state.CoresPadraoSistema.corLetraMenu,

      corBotaoPadrao: state.CoresPadraoSistema.corBotaoPadrao,
      corSegundoBotaoPadrao: state.CoresPadraoSistema.corSegundoBotaoPadrao,
      letraBotaoPadrao: state.CoresPadraoSistema.letraBotaoPadrao,
      nomeRota: state.HeaderReducer.nomeRota,
      premium: state.PremiumReducer.premium,
      verificaAtendenteVeSomenteProprioAtendimento: state.ChamadoReducer.verificaAtendenteVeSomenteProprioAtendimento,
      contaPrincipal: state.AtendenteReducer.contaPrincipal,

      clientesNovoChamado: state.ChamadoReducer.clientesSectionedConsulta,
      atendenteNovoChamado: state.AtendenteReducer.atendentesSectionedConsulta,
      setorNovoChamado: state.SetorReducer.setoresSectionedConsulta,

      abrirMenu: state.HeaderReducer.abrirMenu,
      logotipo: state.CoresPadraoSistema.logotipo,
      valorAvaliacaoTodasAvaliacoes: state.AvaliacaoReducer.valorAvaliacaoTodasAvaliacoes,
      clientesTodasAvaliacoes: state.AvaliacaoReducer.clientesTodasAvaliacoes,
      atendenteTodasAvaliacoes: state.AvaliacaoReducer.atendenteTodasAvaliacoes,
      notificacoes: state.DashBoardReducer.notificacoes,
      totalNotificacoes: state.DashBoardReducer.totalNotificacoes,
      usuarioAdministrador: state.AtendenteReducer.usuarioAdministrador,
      email: state.PerfilReducer.email,
      nome: state.PerfilReducer.nome,
      foto: state.PerfilReducer.foto,

      dataValidoAte: state.PremiumReducer.dataValidoAte,
      statusAssinatura: state.PremiumReducer.statusAssinatura,
      mostraVersaoExpirando: state.PremiumReducer.mostraVersaoExpirando,
      //permissaoAdministrador: state.AtendenteReducer.permissaoAdministrador,
      // permissaoAlterarCadastrarCliente: state.AtendenteReducer.permissaoAlterarCadastrarCliente,
      // permissaoAlterarCadastrarAtendente: state.AtendenteReducer.permissaoAlterarCadastrarAtendente,
      //  permissaoAlterarCadastrarSetores: state.AtendenteReducer.permissaoAlterarCadastrarSetores,
      //  permissaoAlterarCadastrarAvisos: state.AtendenteReducer.permissaoAlterarCadastrarAvisos,
      //  permissaoAcessoRelatorios: state.AtendenteReducer.permissaoAcessoRelatorios,
      permissoesAcessarTelasAtendente: state.AtendenteReducer.permissoesAcessarTelasAtendente,
      textStatusAssinaturaDrawer: state.PremiumReducer.textStatusAssinaturaDrawer,
      corTextStatusAssinaturaDrawer: state.PremiumReducer.corTextStatusAssinaturaDrawer,
      licensawebouapp: state.PremiumReducer.licensawebouapp,
      isAdmin: state.PerfilReducer.isAdmin,
      usuarioAdmVisualizaApenasChamadosDoSetorVinculado: state.PerfilReducer.usuarioAdmVisualizaApenasChamadosDoSetorVinculado,
      // sairDoDropDesk: state.DashBoardReducer.sairDoDropDesk,
      countMensagensNaoLidas: state.ChamadoReducer.countMensagensNaoLidas,
      acoesDoAtendimentoChat: state.ChamadoReducer.acoesDoAtendimentoChat,
      chamadosEmEsperaAtendimentoOnline: state.ChamadoReducer.chamadosEmEsperaAtendimentoOnline,
      chamadosEmAtendimentoOnline: state.ChamadoReducer.chamadosEmAtendimentoOnline,
      isMaintenance: state.LoginReducer.isMaintenance,
      isNewVersionAvailable: state.LoginReducer.isNewVersionAvailable,
      lastVersionAvailable: state.LoginReducer.lastVersionAvailable,
    }
  );
};
export default connect(mapStateToProps, {
  modificaNomeRotaNoHeader, modificaPesquisaTeste,
  modificaVisibilidadeModalNovoAtendimento,

  modificaModalFiltroChamado, modificaFiltroChamado, modificaSelectTextChamado, modificaPesquisaTextChamado,
  modificaValorArraySectionedFiltroChamado, modificaFiltroPorDataDashboard, insereDataFiltroDashboard,
  modificaFiltroClientesTelaClientes, modificaVisibleModalNovoCLiente, modificaFiltroClientesTelaAtendentes,
  modificaVisibleModalNovoAtendente, modificaFiltroSetoresTelaSetores, modificaVisibleModalNovoSetor,
  alteraFiltroSelecionadoTelaNovoAviso, modificaVisibleModalNovoAviso, modificaFiltroSelecionadoRelatorioDetalhado,
  modificaFiltroAvaliacao, modificaFiltroRelatorioAvaliacaoGeral, modificaModalSair,
  modificaAberturaMenu,
  zeraInformacoesCadastroClienteExport, zeraInformacoesCadastroAtendenteExport, zerCadastroAvisoExport,
  acessouMeuPErfil, sairDoApp, enviaTotalNotificacoesParaReducerExport, marcarTodasNotifComoLido,
  navegaTelaALterarChamadoNotificacao, modificaModalDuvidasFrequentes, ativaDesativaRenovarPLano, modificaMostraVersaoExpirando,
  modificaVisibilidadeModalPortalCliente, verificaSeJaTeMCodigoCadastraCliente, modificaVisibilidadeModalImportarClientes,
  modificaTitleTab, modificaVisibilidadeModalExportarClientes
})(App);
