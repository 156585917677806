import moment from 'moment';
import firebase from '../servicos/FirebaseConnection';
import { toastr } from 'react-redux-toastr';
import _ from 'lodash';
import {
    CARREGANOMEPERFIL, CARREGAEMAILPERFIL, CARREGAIMAGEMPERFIL, ENVIATOUCHBACKGROUNDMENUGAVETA,
    ENVIAKEYUSUARIOPERFIL, ACESSOUMEUPERFIL
} from './types';

/* export const preenchePerfilUsuario = () => {
    let nome = '';
    let keyUsuario = ''
    return dispatch => {
        AsyncStorage.getItem('chaveUnica').then((chaveUnica2) => {
            if (chaveUnica2 == null) {
                firebase.database().ref(`usuariosALL/${firebase.auth().currentUser.uid}`).once('value').then((snapshot) => {
                    if (snapshot.val()) {
                        
                        const dadosUsuario = _.first(_.values(snapshot.val()));
                        let chaveUnica = dadosUsuario.chaveUnica;
                        //se quem tiver logando for admin, pode logar normalmente, caso seja cliente vai para o else
                        if (dadosUsuario.admin == 1) {
                            localStorage.setItem(`chaveUnica`, JSON.stringify(chaveUnica));
                            firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild('uid').equalTo(firebase.auth().currentUser.uid).on('value', (snapshot) => {
                                if (snapshot.val()) {
                                    snapshot.forEach((childItem) => {
                                        keyUsuario = childItem.key;
                                        nome = childItem.val().nome;
                                        enviaNomeParaReducer(dispatch, childItem.val().nome);
                                        enviaEmailParaReducer(dispatch, childItem.val().email);
                                        enviaKeyUsuarioPerfil(dispatch, childItem.key);
                                    })
                                    firebase.storage().ref().child(`${chaveUnica}/${keyUsuario}/imagem.jpg`).getDownloadURL().then((url) => {
                                        enviaImagemPerfil(dispatch, url)
                                    }).catch(() => {
                                        const primeiraLetra = nome.substring(0, 1).trim().toLowerCase();
                                        firebase.storage().ref(`letras/${primeiraLetra}.png`).getDownloadURL().then((url) => {
                                            enviaImagemPerfil(dispatch, url)
                                        }).catch(() => {
                                            const fotoURI = 'https://firebasestorage.googleapis.com/v0/b/dark-4b307.appspot.com/o/imagemPadraoDark%2Fuser.png?alt=media&token=5494861d-8431-480d-ac62-442c8b2c39af'
                                            enviaImagemPerfil(dispatch, fotoURI)
                                        })
                                    })

                                } else {
                                }
                            })
                        } else {
                            // toastr.error('Error', 'Essa conta é uma conta cliente, por favor baixe o aplicativo dropdesk cliente.');
                            //firebase.auth().signOut();
                            //desativaLoadingBotaoLogarTelaLogin(dispatch);
                        }
                    } else {
                    }
                })
            } else {
                const chaveUnica = chaveUnica2.replace(/"/g, "");
                // const { currentUser } = firebase.auth();
                firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild('uid').equalTo(firebase.auth().currentUser.uid).on('value', (snapshot) => {
                    if (snapshot.val()) {
                        snapshot.forEach((childItem) => {
                            keyUsuario = childItem.key;
                            nome = childItem.val().nome;
                            enviaNomeParaReducer(dispatch, childItem.val().nome);
                            enviaEmailParaReducer(dispatch, childItem.val().email);
                            enviaKeyUsuarioPerfil(dispatch, childItem.key);
                        })
                        firebase.storage().ref().child(`${chaveUnica}/${keyUsuario}/imagem.jpg`).getDownloadURL().then((url) => {
                            enviaImagemPerfil(dispatch, url)
                        }).catch(() => {
                            const primeiraLetra = nome.substring(0, 1).trim().toLowerCase();
                            firebase.storage().ref(`letras/${primeiraLetra}.png`).getDownloadURL().then((url) => {
                                enviaImagemPerfil(dispatch, url)
                            }).catch(() => {
                                const fotoURI = 'https://firebasestorage.googleapis.com/v0/b/dark-4b307.appspot.com/o/imagemPadraoDark%2Fuser.png?alt=media&token=5494861d-8431-480d-ac62-442c8b2c39af'
                                enviaImagemPerfil(dispatch, fotoURI)
                            })
                        })

                    } else {
                    }
                })
            }
        });
    }
} */
export const sairDoApp = () => {
    return dispatch => {
        AsyncStorage.getItem('chaveUnica').then((chaveUnica2) => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            const { currentUser } = firebase.auth();
            firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild('uid').equalTo(currentUser.uid).once("value").then((snapshot) => {
                let key = Object.keys(snapshot.val()).toString()
                firebase.database().ref(`${chaveUnica}/usuarios/${key}`).update({ fcmTokenWEB: '' }).then(() => {
                    let keysToRemove = ["user", "login","chaveUnica","configuracoesInseridas8","tabindex","menuOpen","keyAtendimento","numeroAtendimento","backgroundPrimeiro","corBotaoPadrao","enviaraopressionarenter",'reproduzsomemespera',`reproduzsomematendimento`,'verificacaousersetor','token'];
                    for (const key of keysToRemove) {
                        localStorage.removeItem(key);
                    }
                    firebase.auth().signOut();
                    window.location.reload();
                   /*  localStorage.removeItem('user');
                    localStorage.removeItem('login');
                    localStorage.removeItem('chaveUnica');
                    localStorage.removeItem('configuracoesInseridas3');
                    localStorage.removeItem('tabindex');
                    localStorage.removeItem('menuOpen');
                    localStorage.removeItem('keyAtendimento');
                    localStorage.removeItem('numeroAtendimento');
                    localStorage.removeItem('backgroundPrimeiro');
                    localStorage.removeItem('corBotaoPadrao'); */
                    
                })
            }).catch(() => {
                let keysToRemove = ["user", "login","chaveUnica","configuracoesInseridas8","tabindex","menuOpen","keyAtendimento","numeroAtendimento","backgroundPrimeiro","corBotaoPadrao","enviaraopressionarenter",'reproduzsomemespera',`reproduzsomematendimento`,'verificacaousersetor','token'];
                for (const key of keysToRemove) {
                    localStorage.removeItem(key);
                }
                firebase.auth().signOut();
                window.location.reload();
            })
        })


    }
}

export const mudaToachBackGround = (botao) => {
    let arrayTouchBackGround = [];
    return dispatch => {
        switch (botao) {
            case 'Inicio':

                arrayTouchBackGround.push({
                    selecionadoInicio: true,
                    selecionadoVip: false,
                    selecionadoClientes: false,
                    selecionadoAtendentes: false,
                    selecionadoSetores: false,
                    selecionadoConfiguracao: false,
                    selecionadoContato: false,
                    selecionadoSobre: false,
                    selecionadoAvaliar: false,
                    selecionadoRedesSocias: false,
                    selecionadoCompartilhar: false,
                    selecionadoAvisos: false,
                    selecionadoSair: false,
                    selecionadoConfiguracaoEmpresa: false,
                    selecionadoAvaliacaoAtendimento: false,
                    selecionadoRelatorio: false,
                    selecionadoCentralDeAjuda: false,
                });
                enviaTochBackGroundMenuGaveta(dispatch, arrayTouchBackGround);
                break;
            case 'Vip':
                arrayTouchBackGround.push({
                    selecionadoInicio: false,
                    selecionadoVip: true,
                    selecionadoClientes: false,
                    selecionadoAtendentes: false,
                    selecionadoSetores: false,
                    selecionadoConfiguracao: false,
                    selecionadoContato: false,
                    selecionadoSobre: false,
                    selecionadoAvaliar: false,
                    selecionadoRedesSocias: false,
                    selecionadoCompartilhar: false,
                    selecionadoAvisos: false,
                    selecionadoSair: false,
                    selecionadoConfiguracaoEmpresa: false,
                    selecionadoAvaliacaoAtendimento: false,
                    selecionadoRelatorio: false,
                    selecionadoCentralDeAjuda: false,
                });
                enviaTochBackGroundMenuGaveta(dispatch, arrayTouchBackGround);
                break;
            case 'Clientes':
                arrayTouchBackGround.push({
                    selecionadoInicio: false,
                    selecionadoVip: false,
                    selecionadoClientes: true,
                    selecionadoAtendentes: false,
                    selecionadoSetores: false,
                    selecionadoConfiguracao: false,
                    selecionadoContato: false,
                    selecionadoSobre: false,
                    selecionadoAvaliar: false,
                    selecionadoRedesSocias: false,
                    selecionadoCompartilhar: false,
                    selecionadoSair: false,
                    selecionadoAvisos: false,
                    selecionadoConfiguracaoEmpresa: false,
                    selecionadoAvaliacaoAtendimento: false,
                    selecionadoRelatorio: false,
                    selecionadoCentralDeAjuda: false,
                });
                enviaTochBackGroundMenuGaveta(dispatch, arrayTouchBackGround);
                break;
            case 'Atendentes':
                arrayTouchBackGround.push({
                    selecionadoInicio: false,
                    selecionadoVip: false,
                    selecionadoClientes: false,
                    selecionadoAtendentes: true,
                    selecionadoSetores: false,
                    selecionadoConfiguracao: false,
                    selecionadoContato: false,
                    selecionadoSobre: false,
                    selecionadoAvaliar: false,
                    selecionadoRedesSocias: false,
                    selecionadoCompartilhar: false,
                    selecionadoSair: false,
                    selecionadoAvisos: false,
                    selecionadoConfiguracaoEmpresa: false,
                    selecionadoAvaliacaoAtendimento: false,
                    selecionadoRelatorio: false,
                    selecionadoCentralDeAjuda: false,
                });
                enviaTochBackGroundMenuGaveta(dispatch, arrayTouchBackGround);
                break;
            case 'Setores':
                arrayTouchBackGround.push({
                    selecionadoInicio: false,
                    selecionadoVip: false,
                    selecionadoClientes: false,
                    selecionadoAtendentes: false,
                    selecionadoSetores: true,
                    selecionadoConfiguracao: false,
                    selecionadoContato: false,
                    selecionadoSobre: false,
                    selecionadoAvaliar: false,
                    selecionadoRedesSocias: false,
                    selecionadoCompartilhar: false,
                    selecionadoAvisos: false,
                    selecionadoSair: false,
                    selecionadoConfiguracaoEmpresa: false,
                    selecionadoAvaliacaoAtendimento: false,
                    selecionadoRelatorio: false,
                    selecionadoCentralDeAjuda: false,
                });
                enviaTochBackGroundMenuGaveta(dispatch, arrayTouchBackGround);
                break;
            case 'Configuracao':
                arrayTouchBackGround.push({
                    selecionadoInicio: false,
                    selecionadoVip: false,
                    selecionadoClientes: false,
                    selecionadoAtendentes: false,
                    selecionadoSetores: false,
                    selecionadoConfiguracao: true,
                    selecionadoContato: false,
                    selecionadoSobre: false,
                    selecionadoAvaliar: false,
                    selecionadoRedesSocias: false,
                    selecionadoCompartilhar: false,
                    selecionadoSair: false,
                    selecionadoAvisos: false,
                    selecionadoConfiguracaoEmpresa: false,
                    selecionadoAvaliacaoAtendimento: false,
                    selecionadoRelatorio: false,
                    selecionadoCentralDeAjuda: false,
                });
                enviaTochBackGroundMenuGaveta(dispatch, arrayTouchBackGround);
                break;
            case 'Contato':
                arrayTouchBackGround.push({
                    selecionadoInicio: false,
                    selecionadoVip: false,
                    selecionadoClientes: false,
                    selecionadoAtendentes: false,
                    selecionadoSetores: false,
                    selecionadoConfiguracao: false,
                    selecionadoContato: true,
                    selecionadoSobre: false,
                    selecionadoAvaliar: false,
                    selecionadoRedesSocias: false,
                    selecionadoCompartilhar: false,
                    selecionadoAvisos: false,
                    selecionadoSair: false,
                    selecionadoConfiguracaoEmpresa: false,
                    selecionadoAvaliacaoAtendimento: false,
                    selecionadoRelatorio: false,
                    selecionadoCentralDeAjuda: false,
                });
                enviaTochBackGroundMenuGaveta(dispatch, arrayTouchBackGround);
                break;
            case 'Sobre':
                arrayTouchBackGround.push({
                    selecionadoInicio: false,
                    selecionadoVip: false,
                    selecionadoClientes: false,
                    selecionadoAtendentes: false,
                    selecionadoSetores: false,
                    selecionadoConfiguracao: false,
                    selecionadoContato: false,
                    selecionadoSobre: true,
                    selecionadoAvaliar: false,
                    selecionadoRedesSocias: false,
                    selecionadoCompartilhar: false,
                    selecionadoAvisos: false,
                    selecionadoSair: false,
                    selecionadoConfiguracaoEmpresa: false,
                    selecionadoAvaliacaoAtendimento: false,
                    selecionadoRelatorio: false,
                    selecionadoCentralDeAjuda: false,
                });
                enviaTochBackGroundMenuGaveta(dispatch, arrayTouchBackGround);
                break;
            case 'Avaliar':
                arrayTouchBackGround.push({
                    selecionadoInicio: false,
                    selecionadoVip: false,
                    selecionadoClientes: false,
                    selecionadoAtendentes: false,
                    selecionadoSetores: false,
                    selecionadoConfiguracao: false,
                    selecionadoContato: false,
                    selecionadoSobre: false,
                    selecionadoAvaliar: true,
                    selecionadoRedesSocias: false,
                    selecionadoCompartilhar: false,
                    selecionadoAvisos: false,
                    selecionadoSair: false,
                    selecionadoConfiguracaoEmpresa: false,
                    selecionadoAvaliacaoAtendimento: false,
                    selecionadoRelatorio: false,
                    selecionadoCentralDeAjuda: false,
                });
                enviaTochBackGroundMenuGaveta(dispatch, arrayTouchBackGround);
                break;
            case 'RedesSocias':
                arrayTouchBackGround.push({
                    selecionadoInicio: false,
                    selecionadoVip: false,
                    selecionadoClientes: false,
                    selecionadoAtendentes: false,
                    selecionadoSetores: false,
                    selecionadoConfiguracao: false,
                    selecionadoContato: false,
                    selecionadoSobre: false,
                    selecionadoAvaliar: false,
                    selecionadoRedesSocias: true,
                    selecionadoCompartilhar: false,
                    selecionadoAvisos: false,
                    selecionadoSair: false,
                    selecionadoConfiguracaoEmpresa: false,
                    selecionadoAvaliacaoAtendimento: false,
                    selecionadoRelatorio: false,
                    selecionadoCentralDeAjuda: false,
                });
                enviaTochBackGroundMenuGaveta(dispatch, arrayTouchBackGround);
                break;
            case 'Compartilhar':
                arrayTouchBackGround.push({
                    selecionadoInicio: false,
                    selecionadoVip: false,
                    selecionadoClientes: false,
                    selecionadoAtendentes: false,
                    selecionadoSetores: false,
                    selecionadoConfiguracao: false,
                    selecionadoContato: false,
                    selecionadoSobre: false,
                    selecionadoAvaliar: false,
                    selecionadoRedesSocias: false,
                    selecionadoCompartilhar: true,
                    selecionadoAvisos: false,
                    selecionadoSair: false,
                    selecionadoConfiguracaoEmpresa: false,
                    selecionadoAvaliacaoAtendimento: false,
                    selecionadoRelatorio: false,
                    selecionadoCentralDeAjuda: false,
                });
                enviaTochBackGroundMenuGaveta(dispatch, arrayTouchBackGround);
                break;
            case 'Sair':
                arrayTouchBackGround.push({
                    selecionadoInicio: false,
                    selecionadoVip: false,
                    selecionadoClientes: false,
                    selecionadoAtendentes: false,
                    selecionadoSetores: false,
                    selecionadoConfiguracao: false,
                    selecionadoContato: false,
                    selecionadoSobre: false,
                    selecionadoAvaliar: false,
                    selecionadoRedesSocias: false,
                    selecionadoCompartilhar: false,
                    selecionadoAvisos: false,
                    selecionadoSair: true,
                    selecionadoConfiguracaoEmpresa: false,
                    selecionadoAvaliacaoAtendimento: false,
                    selecionadoRelatorio: false,
                    selecionadoCentralDeAjuda: false,
                });
                enviaTochBackGroundMenuGaveta(dispatch, arrayTouchBackGround);
                break;
            case 'Avisos':
                arrayTouchBackGround.push({
                    selecionadoInicio: false,
                    selecionadoVip: false,
                    selecionadoClientes: false,
                    selecionadoAtendentes: false,
                    selecionadoSetores: false,
                    selecionadoConfiguracao: false,
                    selecionadoContato: false,
                    selecionadoSobre: false,
                    selecionadoAvaliar: false,
                    selecionadoRedesSocias: false,
                    selecionadoCompartilhar: false,
                    selecionadoSair: false,
                    selecionadoAvisos: true,
                    selecionadoConfiguracaoEmpresa: false,
                    selecionadoAvaliacaoAtendimento: false,
                    selecionadoRelatorio: false,
                    selecionadoCentralDeAjuda: false,
                });
                enviaTochBackGroundMenuGaveta(dispatch, arrayTouchBackGround);
                break;
            case 'ConfiguracaoEmpresa':
                arrayTouchBackGround.push({
                    selecionadoInicio: false,
                    selecionadoVip: false,
                    selecionadoClientes: false,
                    selecionadoAtendentes: false,
                    selecionadoSetores: false,
                    selecionadoConfiguracao: false,
                    selecionadoContato: false,
                    selecionadoSobre: false,
                    selecionadoAvaliar: false,
                    selecionadoRedesSocias: false,
                    selecionadoCompartilhar: false,
                    selecionadoSair: false,
                    selecionadoAvisos: false,
                    selecionadoConfiguracaoEmpresa: true,
                    selecionadoAvaliacaoAtendimento: false,
                    selecionadoRelatorio: false,
                    selecionadoCentralDeAjuda: false,
                });
                enviaTochBackGroundMenuGaveta(dispatch, arrayTouchBackGround);
                break;
            case 'Avaliacao':
                arrayTouchBackGround.push({
                    selecionadoInicio: false,
                    selecionadoVip: false,
                    selecionadoClientes: false,
                    selecionadoAtendentes: false,
                    selecionadoSetores: false,
                    selecionadoConfiguracao: false,
                    selecionadoContato: false,
                    selecionadoSobre: false,
                    selecionadoAvaliar: false,
                    selecionadoRedesSocias: false,
                    selecionadoCompartilhar: false,
                    selecionadoSair: false,
                    selecionadoAvisos: false,
                    selecionadoConfiguracaoEmpresa: false,
                    selecionadoAvaliacaoAtendimento: true,
                    selecionadoRelatorio: false,
                    selecionadoCentralDeAjuda: false,
                });
                enviaTochBackGroundMenuGaveta(dispatch, arrayTouchBackGround);
                break;
            case 'Relatorios':
                arrayTouchBackGround.push({
                    selecionadoInicio: false,
                    selecionadoVip: false,
                    selecionadoClientes: false,
                    selecionadoAtendentes: false,
                    selecionadoSetores: false,
                    selecionadoConfiguracao: false,
                    selecionadoContato: false,
                    selecionadoSobre: false,
                    selecionadoAvaliar: false,
                    selecionadoRedesSocias: false,
                    selecionadoCompartilhar: false,
                    selecionadoSair: false,
                    selecionadoAvisos: false,
                    selecionadoConfiguracaoEmpresa: false,
                    selecionadoAvaliacaoAtendimento: false,
                    selecionadoRelatorio: true,
                    selecionadoCentralDeAjuda: false,

                });
                enviaTochBackGroundMenuGaveta(dispatch, arrayTouchBackGround);
                break;

            case 'CentralDeAjuda':
                arrayTouchBackGround.push({
                    selecionadoInicio: false,
                    selecionadoVip: false,
                    selecionadoClientes: false,
                    selecionadoAtendentes: false,
                    selecionadoSetores: false,
                    selecionadoConfiguracao: false,
                    selecionadoContato: false,
                    selecionadoSobre: false,
                    selecionadoAvaliar: false,
                    selecionadoRedesSocias: false,
                    selecionadoCompartilhar: false,
                    selecionadoSair: false,
                    selecionadoAvisos: false,
                    selecionadoConfiguracaoEmpresa: false,
                    selecionadoAvaliacaoAtendimento: false,
                    selecionadoRelatorio: false,
                    selecionadoCentralDeAjuda: true,
                });
                enviaTochBackGroundMenuGaveta(dispatch, arrayTouchBackGround);
                break;
        }
    }
}
const enviaNomeParaReducer = (dispatch, nome) => {
    dispatch({
        type: CARREGANOMEPERFIL,
        payload: nome
    });
}
const enviaEmailParaReducer = (dispatch, email) => {
    dispatch({
        type: CARREGAEMAILPERFIL,
        payload: email
    });

}
const enviaKeyUsuarioPerfil = (dispatch, key) => {
    dispatch({
        type: ENVIAKEYUSUARIOPERFIL,
        payload: key
    });
}
const enviaTochBackGroundMenuGaveta = (dispatch, array) => {
    dispatch({
        type: ENVIATOUCHBACKGROUNDMENUGAVETA,
        payload: array
    });
}
const enviaImagemPerfil = (dispatch, img) => {
    dispatch({
        type: CARREGAIMAGEMPERFIL,
        payload: img
    });
}
export const acessouMeuPErfil = (value) => {
    return {
        type: ACESSOUMEUPERFIL,
        payload: value
    }
}
const AsyncStorage = {
    setItem: function (key, value) {
        return Promise.resolve().then(function () {
            localStorage.setItem(key, value);
        });
    },
    getItem: function (key) {
        return Promise.resolve().then(function () {
            return localStorage.getItem(key);
        });
    }
};