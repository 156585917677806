import React from 'react';
import {AsyncPaginate} from 'react-select-async-paginate';
import loadOptions from './loadOptions';
import {connect} from "react-redux";
import {
    listaClientesTelaConsultaClientesAntesRemoved
} from '../../actions/ChamadoAction';
class SelectSearchPaginate extends React.Component {

    render() {

        const AsyncStorage = {
            setItem: function (key, value) {
                return Promise.resolve().then(function () {
                    localStorage.setItem(key, value);
                });
            },
            getItem: function (key) {
                return Promise.resolve().then(function () {
                    return localStorage.getItem(key);
                });
            }
        };


        const wrappedLoadOptions = async (search, prevOptions, {page}) => {

            const idCompany = await AsyncStorage.getItem('chaveUnica')
            const _idCompany = JSON.parse(idCompany);
            const results = await loadOptions(search, _idCompany, page, this.props.isMyContact, this.props.isScreenNotices ?? false, this.props.isClientLinked ?? false);

            if (results.searchFromHasura === true && this.props.clientesFirebase.length === 0) {
                this.props.listaClientesTelaConsultaClientesAntesRemoved();
            }
            return results;
        };

        return (
            <AsyncPaginate
                debounceTimeout={300}
                value={this.props.value}
                loadOptions={wrappedLoadOptions}
                onChange={this.props.onSelectClient}
                noOptionsMessage={() => 'Nenhum resultado encontrado.'}
                loadingMessage={() => 'Carregando...'}   //minor type-O here
                placeholder={this.props.placeHodler ?? "Selecione o Cliente"}
                additional={{
                    page: 0
                }}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 5,
                    colors: {
                        ...theme.colors,
                        primary: this.props.corBordaDinamica,
                        neutral80: this.props.letterColor
                    },
                })}

                styles={{
                    control: defaultStyles => {

                        return {
                            ...defaultStyles,
                            height: 35,
                            minHeight: 35,
                            width: this.props.width,
                            background: this.props.background,
                            border: `1px solid ${this.props.letterColor}`,
                            boxShadow: "none",
                            fontSize: 14,
                            cursor: 'pointer',
                            "&:hover": {
                                borderColor: this.props.corBordaDinamica,
                            }
                        }
                    },
                    option: (defaultStyles, state) => {
                        return {
                            ...defaultStyles,
                            cursor: 'pointer',
                            color: state.isSelected ? this.props.letraBotaoPadrao : '#000',
                        };
                    },
                    menu: defaultStyles => ({...defaultStyles, zIndex: 9999}),
                    dropdownIndicator: defaultStyles => ({
                        ...defaultStyles,
                        color: this.props.letterColor
                    }),
                    loadingIndicator: defaultStyles => ({
                        ...defaultStyles,
                        color: this.props.letterColor
                    }),
                    placeholder: (defaultStyles) => {
                        return {
                            ...defaultStyles,
                            color: this.props.placeholderColor,
                            fontStyle: 'italic'
                        }
                    }
                }}
                components={{
                    IndicatorSeparator: () => null
                }}

            />

        );
    }
}

const mapStateToProps = state => {
    return (
        {
            clientesFirebase: state.ChamadoReducer.clientesSectionedConsulta,
        }
    );
};
export default connect(mapStateToProps, {listaClientesTelaConsultaClientesAntesRemoved})(SelectSearchPaginate);
//export default SelectSearchPaginate;
