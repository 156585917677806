import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import {
    Title, ContainerChamado, ContainerImagem, ContainerDadosChamado,
    ContaineDadosChamadosPrincipal, ContaineDadosChamadosSecundario,
    ContainerIconeEFrase, TextCliente, TextProblema, ContainerIconeEFraseClienteAtendente,
    SubContainerIconeEFraseClienteAtendente, TextNumeroChamado,
    ContainerNumeroChamado, ContainerDataAbertura, ContainerSeta, TextEmpresa
} from './styled';
import {
    navegaTelaAlterarSetor
} from '../../actions/SetorAction';
import imgTeste from '../../imagens/userPadrao.png'

class FlatListSetores extends React.Component {
    state = {
        nome: this.props.data.nome,
        observacao: this.props.data.observacao,
    }

    componentDidMount() {
        //
        if (this.props.setores[0].key == this.props.data.key) {
            this.props.navegaTelaAlterarSetor(this.props.data.nome, this.props.data.observacao, this.props.data.key, this.props.data.permiteClienteSelecionarSetor)
        }
    }

    navegaTelaAlterarSetorLocal() {
        /*      if (this.props.permissoesAcessarTelasAtendente[0].permissaoAlterarCadastrarSetores == undefined) {
                 alert('Verifique se há conexão com a INTERNET e depois tente novamente.');
             } else { */
        /*   if (this.props.permissoesAcessarTelasAtendente[0].permissaoAlterarCadastrarSetores) { */
        this.props.navegaTelaAlterarSetor(this.state.nome, this.state.observacao, this.props.data.key, this.props.data.permiteClienteSelecionarSetor)
        /*  } else {
             Alert.alert(
                 'Atenção',
                 'Você não tem permissão para Cadastrar/Alterar Setores.',

                 [{ text: 'OK', onPress: () => { } }],
             );
         } */
        /*     } */
    }

    render() {
        return (

            <ContainerChamado backGroundDinamico={this.props.backgroundMaisEscuro} type="button" onClick={() => { this.navegaTelaAlterarSetorLocal() }}>
                {this.props.data.key == this.props.keySetor && <div style={{ width: 5, backgroundColor: this.props.corBotaoPadrao, height: 58, borderTopLeftRadius: 7, borderBottomLeftRadius: 7 }}></div>}
                <ContainerDadosChamado>
                    <ContaineDadosChamadosPrincipal>
                        <SubContainerIconeEFraseClienteAtendente >
                            <TextCliente data-tip="Nome" data-for="tip-top" corLetraDinamica={this.props.coresLetraPadrao}>{this.state.nome}</TextCliente>
                        </SubContainerIconeEFraseClienteAtendente>
                        <SubContainerIconeEFraseClienteAtendente >
                            <TextEmpresa data-tip="Observação" data-for="tip-top" corLetraDinamica={this.props.coresLetraSecundario}>{this.state.observacao}</TextEmpresa>
                        </SubContainerIconeEFraseClienteAtendente>
                    </ContaineDadosChamadosPrincipal>
                    <ContainerSeta>
                        <i className="icon-nextbold" style={{ fontSize: 10, color: this.props.coresLetraSecundario }} />
                    </ContainerSeta>

                </ContainerDadosChamado>


            </ContainerChamado>

        );
    }
}

const mapStateToProps = state => {
    return (
        {
            backgroundPrimeiro: state.CoresPadraoSistema.backgroundPrimeiro,
            backgroundSegundo: state.CoresPadraoSistema.backgroundSegundo,
            backgroundMaisEscuro: state.CoresPadraoSistema.backgroundMaisEscuro,
            coresLetraPadrao: state.CoresPadraoSistema.coresLetraPadrao,
            coresLetraSecundario: state.CoresPadraoSistema.coresLetraSecundario,
            corBotaoPadrao: state.CoresPadraoSistema.corBotaoPadrao,
            corSegundoBotaoPadrao: state.CoresPadraoSistema.corSegundoBotaoPadrao,
            corLetraDescricaoDosCampos: state.CoresPadraoSistema.corLetraDescricaoDosCampos,
            nomeRota: state.HeaderReducer.nomeRota,
            email: state.LoginReducer.email,
            keySetor: state.SetorReducer.keySetor,
            permissoesAcessarTelasAtendente: state.AtendenteReducer.permissoesAcessarTelasAtendente,
            setores: state.SetorReducer.setores,
        }
    );
};
export default connect(mapStateToProps, {
    navegaTelaAlterarSetor
})(withRouter(FlatListSetores));
