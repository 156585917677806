import React ,{memo}from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import firebase from '../../servicos/FirebaseConnection';
import {
    Title, ContainerChamado, ContainerImagem, ContainerDadosChamado,
    ContaineDadosChamadosPrincipal, ContaineDadosChamadosSecundario,
    ContainerIconeEFrase, TextCliente, TextProblema, ContainerIconeEFraseClienteAtendente,
    SubContainerIconeEFraseClienteAtendente, TextNumeroChamado,
    ContainerNumeroChamado, ContainerDataAbertura, ContainerSeta, TextEmpresa, TextComentario,
    DivDataAvaliacao,
} from './styled';
import {
    navegaTelaDetalhesAvaliacao
} from '../../actions/AvaliacaoAction';
import imgTeste from '../../imagens/userPadrao.png'

class FlatListRelatorioRegistroAvaliacoes extends React.Component {
    state = {
        fotoCliente: null,
    }

    componentDidMount() {
       // 
        if (this.props.data.clientes != 'semConexaoInternetDark2659' && this.props.data.clientes != 'semChamadosAbertos') {
            firebase.storage().ref().child(`${this.props.data.chaveUnica}/${this.props.data.keyCliente}/imagem.jpg`).getDownloadURL().then((url) => {
                this.setState({ fotoCliente: url });
                if (this.props.arrayTodasAvaliacoes[0].key == this.props.data.key) {
                    this.props.navegaTelaDetalhesAvaliacao(this.props.data,this.state.fotoCliente)
                }
            }).catch(() => {
                const primeiraLetra = this.props.data.cliente.substring(0, 1).trim().toLowerCase();

                firebase.storage().ref(`letras/${primeiraLetra}.png`).getDownloadURL().then((url) => {

                    this.setState({ fotoCliente: url });
                    if (this.props.arrayTodasAvaliacoes[0].key == this.props.data.key) {
                        this.props.navegaTelaDetalhesAvaliacao(this.props.data,this.state.fotoCliente)
                    }
                }).catch(() => {

                    const fotoURI = 'https://firebasestorage.googleapis.com/v0/b/dark-4b307.appspot.com/o/imagemPadraoDark%2Fuser.png?alt=media&token=5494861d-8431-480d-ac62-442c8b2c39af'
                    this.setState({ fotoCliente: fotoURI });
                    if (this.props.arrayTodasAvaliacoes[0].key == this.props.data.key) {
                        this.props.navegaTelaDetalhesAvaliacao(this.props.data,this.state.fotoCliente)
                    }
                })
            });
        }

    }
    renderAvaliacao() {
        if (this.props.data.valorAvaliacao == 1) {
            return (
                <div>
                    <i className="icon-star" style={{ padding: 2, fontSize: 11, color: 'yellow' }} />
                    <i className="icon-starnotavalied" style={{ padding: 2, fontSize: 11, color: '#a8a8B3' }} />
                    <i className="icon-starnotavalied" style={{ padding: 2, fontSize: 11, color: '#a8a8B3' }} />
                    <i className="icon-starnotavalied" style={{ padding: 2, fontSize: 11, color: '#a8a8B3' }} />
                    <i className="icon-starnotavalied" style={{ padding: 2, fontSize: 11, color: '#a8a8B3' }} />
                </div>
            )
        } else if (this.props.data.valorAvaliacao == 2) {
            return (
                <div>
                    <i className="icon-star" style={{ padding: 2, fontSize: 11, color: 'yellow' }} />
                    <i className="icon-star" style={{ padding: 2, fontSize: 11, color: 'yellow' }} />
                    <i className="icon-starnotavalied" style={{ padding: 2, fontSize: 11, color: '#a8a8B3' }} />
                    <i className="icon-starnotavalied" style={{ padding: 2, fontSize: 11, color: '#a8a8B3' }} />
                    <i className="icon-starnotavalied" style={{ padding: 2, fontSize: 11, color: '#a8a8B3' }} />
                </div>
            )
        }
        else if (this.props.data.valorAvaliacao == 3) {
            return (
                <div>
                    <i className="icon-star" style={{ padding: 2, fontSize: 11, color: 'yellow' }} />
                    <i className="icon-star" style={{ padding: 2, fontSize: 11, color: 'yellow' }} />
                    <i className="icon-star" style={{ padding: 2, fontSize: 11, color: 'yellow' }} />
                    <i className="icon-starnotavalied" style={{ padding: 2, fontSize: 11, color: '#a8a8B3' }} />
                    <i className="icon-starnotavalied" style={{ padding: 2, fontSize: 11, color: '#a8a8B3' }} />
                </div>
            )
        }
        else if (this.props.data.valorAvaliacao == 4) {
            return (
                <div >
                    <i className="icon-star" style={{ padding: 2, fontSize: 11, color: 'yellow' }} />
                    <i className="icon-star" style={{ padding: 2, fontSize: 11, color: 'yellow' }} />
                    <i className="icon-star" style={{ padding: 2, fontSize: 11, color: 'yellow' }} />
                    <i className="icon-star" style={{ padding: 2, fontSize: 11, color: 'yellow' }} />
                    <i className="icon-starnotavalied" style={{ padding: 2, fontSize: 11, color: '#a8a8B3' }} />
                </div>
            )
        }
        else {
            return (
                <div >
                    <i className="icon-star" style={{ padding: 2, fontSize: 11, color: 'yellow' }} />
                    <i className="icon-star" style={{ padding: 2, fontSize: 11, color: 'yellow' }} />
                    <i className="icon-star" style={{ padding: 2, fontSize: 11, color: 'yellow' }} />
                    <i className="icon-star" style={{ padding: 2, fontSize: 11, color: 'yellow' }} />
                    <i className="icon-star" style={{ padding: 2, fontSize: 11, color: 'yellow' }} />
                </div>
            )
        }
    }
    navegaParaTelaDetalhesAvaliacao() {

        this.props.navegaTelaDetalhesAvaliacao(this.props.data,this.state.fotoCliente)


    }
    verificaSeEhAlphabeto = (ch) => {
        // 
        let verificaSeEhAlphabeto = typeof ch === "string" && ch.length === 1
            && (ch >= "a" && ch <= "z");
        //
        // 
        if (verificaSeEhAlphabeto) {
            //
            switch (ch) {
                case "a":
                    this.setState({ fotoCliente: require('../../imagens/a.png') });
                    break;
                case "b":
                    this.setState({ fotoCliente: require('../../imagens/b.png') });
                    break;
                case "c":
                    this.setState({ fotoCliente: require('../../imagens/c.png') });
                    break;
                case "d":
                    this.setState({ fotoCliente: require('../../imagens/d.png') });
                    break;
                case "e":
                    this.setState({ fotoCliente: require('../../imagens/e.png') });
                    break;
                case "f":
                    this.setState({ fotoCliente: require('../../imagens/f.png') });
                    break;
                case "g":
                    this.setState({ fotoCliente: require('../../imagens/g.png') })
                    break;
                case "h":
                    this.setState({ fotoCliente: require('../../imagens/h.png') });
                    break;
                case "i":
                    this.setState({ fotoCliente: require('../../imagens/i.png') });
                    break;
                case "j":
                    this.setState({ fotoCliente: require('../../imagens/j.png') });
                    break;
                case "k":
                    this.setState({ fotoCliente: require('../../imagens/k.png') });
                    break;
                case "l":
                    this.setState({ fotoCliente: require('../../imagens/l.png') });
                    break;
                case "m":
                    this.setState({ fotoCliente: require('../../imagens/m.png') });
                    break;
                case "n":
                    this.setState({ fotoCliente: require('../../imagens/n.png') });
                    break;
                case "o":
                    this.setState({ fotoCliente: require('../../imagens/o.png') });
                    break;
                case "p":
                    this.setState({ fotoCliente: require('../../imagens/p.png') });
                    break;
                case "q":
                    this.setState({ fotoCliente: require('../../imagens/q.png') });
                    break;
                case "r":
                    this.setState({ fotoCliente: require('../../imagens/r.png') });
                    break;
                case "s":
                    this.setState({ fotoCliente: require('../../imagens/s.png') });
                    break;
                case "t":
                    this.setState({ fotoCliente: require('../../imagens/t.png') });
                    break;
                case "u":
                    this.setState({ fotoCliente: require('../../imagens/u.png') });
                    break;
                case "v":
                    this.setState({ fotoCliente: require('../../imagens/v.png') });
                    break;
                case "x":
                    this.setState({ fotoCliente: require('../../imagens/x.png') });
                    break;
                case "z":
                    this.setState({ fotoCliente: require('../../imagens/z.png') });
                    break;
                case "w":
                    this.setState({ fotoCliente: require('../../imagens/w.png') });
                    break;
                case "y":
                    this.setState({ fotoCliente: require('../../imagens/y.png') });
                    break;
                default:
                    this.setState({ fotoCliente: require('../../imagens/userPadrao.png') });
                  //  
                    break;

            }
        } else {
            //   
            let urlIMG = require('../../imagens/userPadrao.png')
            this.setState({ fotoCliente: urlIMG });
        }

    }
    renderImagem() {
        if (this.state.fotoCliente == null) {
            return (
                <div style={{ height: 40, width: 40, borderRadius: '50%', backgroundColor: 'rgba(159,173,183, 0.3)', border: 'none' }} />
            )
        } else {
            return (
                <img src={this.state.fotoCliente} alt="foto cliente" style={{ height: 40, width: 40, borderRadius: 50 }} />
            )
        }
    }

    render() {
        return (

            <ContainerChamado backGroundDinamico={this.props.backgroundMaisEscuro} type="button" onClick={() => { this.navegaParaTelaDetalhesAvaliacao() }}>
                {this.props.data.key == this.props.keyAvaliacao && <div style={{ width: 5, backgroundColor: this.props.corBotaoPadrao, height: 78, borderTopLeftRadius: 7, borderBottomLeftRadius: 7 }}></div>}
                <ContainerImagem>
                    {this.renderImagem()}
                </ContainerImagem>
                <ContainerDadosChamado>
                    <ContaineDadosChamadosPrincipal>
                        <SubContainerIconeEFraseClienteAtendente data-tip="Cliente que fez a avaliação" data-for="tip-top">
                            <i className="icon-man-user" style={{ color: this.props.coresLetraSecundario, fontSize: 11 }} />
                            <TextCliente corLetraDinamica={this.props.coresLetraPadrao}>{this.props.data.cliente}</TextCliente>
                        </SubContainerIconeEFraseClienteAtendente>
                        <SubContainerIconeEFraseClienteAtendente data-tip="Comentário da Avaliação" data-for="tip-top">
                            <i className="icon-comment2" style={{ color: this.props.coresLetraSecundario, fontSize: 10 }} />
                            <TextComentario corLetraDinamica={this.props.corLetraDescricaoDosCampos}>
                                {this.props.data.comentarioAvaliacao = (this.props.data.comentarioAvaliacao == '') ? 'Comentário Não informado' : this.props.data.comentarioAvaliacao}
                            </TextComentario>
                        </SubContainerIconeEFraseClienteAtendente>
                        <SubContainerIconeEFraseClienteAtendente >
                            <i className="icon-hotel1" style={{ color: this.props.coresLetraSecundario, fontSize: 11 }} />
                            <TextComentario data-tip="Empresa" data-for="tip-top" corLetraDinamica={this.props.coresLetraPadrao}>{this.props.data.empresa}</TextComentario>
                        </SubContainerIconeEFraseClienteAtendente>
                        <DivDataAvaliacao >
                            <p style={{ color: this.props.coresLetraSecundario, fontSize: 9, margin: 0, padding: 0, textAlign: 'left', paddingLeft: 10 }}>{this.props.data.dataHoraAbertura}</p>
                            {this.renderAvaliacao()}
                        </DivDataAvaliacao>
                    </ContaineDadosChamadosPrincipal>
                    <ContainerSeta>
                        <TextCliente corLetraDinamica={this.props.coresLetraPadrao}>#{this.props.data.numeroChamado}</TextCliente>
                        <i className="icon-nextbold" style={{ fontSize: 10, color: this.props.coresLetraSecundario, marginRight: 3 }} />
                        <i className="icon-nextbold" style={{ fontSize: 10, color: this.props.backgroundMaisEscuro }} />
                    </ContainerSeta>

                </ContainerDadosChamado>


            </ContainerChamado>

        );
    }
}

const mapStateToProps = state => {
    return (
        {
            backgroundPrimeiro: state.CoresPadraoSistema.backgroundPrimeiro,
            backgroundSegundo: state.CoresPadraoSistema.backgroundSegundo,
            backgroundMaisEscuro: state.CoresPadraoSistema.backgroundMaisEscuro,
            coresLetraPadrao: state.CoresPadraoSistema.coresLetraPadrao,
            coresLetraSecundario: state.CoresPadraoSistema.coresLetraSecundario,
            corBotaoPadrao: state.CoresPadraoSistema.corBotaoPadrao,
            corSegundoBotaoPadrao: state.CoresPadraoSistema.corSegundoBotaoPadrao,
            corLetraDescricaoDosCampos: state.CoresPadraoSistema.corLetraDescricaoDosCampos,
            nomeRota: state.HeaderReducer.nomeRota,
            keyCliente: state.ChamadoReducer.keyCliente,
            email: state.LoginReducer.email,
            keyAvaliacao: state.AvaliacaoReducer.keyAvaliacao,
            arrayTodasAvaliacoes: state.AvaliacaoReducer.arrayTodasAvaliacoes,
        }
    );
};
export default connect(mapStateToProps, {
    navegaTelaDetalhesAvaliacao
})(memo(FlatListRelatorioRegistroAvaliacoes));
