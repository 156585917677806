import React from 'react';
import { connect } from 'react-redux';
import List from 'react-virtualized/dist/commonjs/List';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import { Modal } from 'react-responsive-modal';
import './chatelements.css';
import { removeFirebaseCachedData, clearCacheRedis } from '../../actions/cachedData';
import loadContacts from "./loadContacts";
import { removeItemsDuplicateFromListByValue } from "../../servicos/utils";
import api from '../../http_client/http.client';
import firebase from '../../servicos/FirebaseConnection';
import { MessageBox, MeetingMessage, SystemMessage, Popup, MessageList, Dropdown, LocationMessage, Input } from '../../servicos/MessageBox/react-chat-elements/src/index';
import moment from 'moment';
import FlatList from 'flatlist-react';
import Linkify from 'react-linkify';
import FlatListEmAtendimento from "./FlatListEmAtendimento";
import FlatListEmEspera from "./FlatListEmEspera";
import FlatListAllContacts from "./FlatListAllContacts";
import ChatWindowEmAtendimento from './ChatWindowEmAtendimento';
import ChatWindowEmEspera from './ChatWindowEmEspera';
import HashLoader from "react-spinners/HashLoader";
import { Helmet } from "react-helmet";
import ScaleLoader from "react-spinners/ScaleLoader"
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import pt from 'date-fns/locale/pt-BR';
import "react-datepicker/dist/react-datepicker.css";

import Dropzone from 'react-dropzone';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import 'emoji-mart/css/emoji-mart.css'
import { Picker } from 'emoji-mart'
import MoonLoader from "react-spinners/MoonLoader";
import { withRouter, Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import styled from 'styled-components';
import SelectSearch from 'react-select-search';
import ClipLoader from "react-spinners/ClipLoader";
import { toastr } from 'react-redux-toastr';
import MicRecorder from 'mic-recorder-to-mp3';
import vCard from 'vcard-parser';

import imgUser from '../../imagens/userPadrao.png';
import { shade, opacify, lighten, } from 'polished';
import { Howl, Howler } from 'howler';
import soundnotification from '../../imagens/notificationsound.mp3';
import atendimentoemesperamp3 from '../../imagens/atendimentoemespera.mp3';

import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';
import InnerImageZoom from 'react-inner-image-zoom';
import {

} from "../../actions/SetorAction";
import { modificaNomeRotaNoHeader } from '../../actions/HeaderAction';
import aguardandoAtendimento from '../../imagens/userPadrao.png';

import pandawhite from '../../imagens/pandawhite.png';
import selectwhite from '../../imagens/selectwhite.png';


import chatimage from '../../imagens/chatclient.png';
import wppimage from '../../imagens/whatsapp.png'
import qrcodeimage from '../../imagens/qr-code.png';
import approved from '../../imagens/approved.png';

import {
    modificaRetornaTelaClienteParaNovoAtendimento, atribuiAtendimentoAh,
    modificaKeyAtendimentoEmEsperaAtendimentoOnline, modificaVisibilidadeModalAtendimentoOnline,
    atribuiStatusAtendimento, modificaKeyAtendimentoEmAtendimentoOnline,
    alteraComentarioChat, salvarComentario, uploadArquivoChat,
    enviaAtendimentoEmEsperaAtendimentoOnlineExport, modificaChamadosEmAtendimentoViaChatExport,
    ativaDesativaLoadingEnviandoAudioChatExport, modificaResolucaoProblema, getAllContatcsWpp, listaConversasAntigasWppContacts,
    iniciaAtendimentoPorContacts, ativaDesativaLoadingIniciandoCOnversaContactsExport, listaConversasAntigasWppEmEspera,
    listaConversasAntigasWppEmAtendimento, enviaUltimaReeferenciaKeyEmEsperaExport, enviaConversasCarregadasEmEsperaExport,
    enviaUltimaReeferenciaKeyEmAtendimentoExport, enviaConversasCarregadasEmAtendimentoExport, listaConversasAntigasResponder,
    enviaMensagemEncaminhada, comentaEAtribuiAtendimento, modificaNomeClienteTelaChatOnline,
    modificaArquivoCompletoParaUparNovoContato, insereFotoCadastroContato, modificaVisibilidadeModalAdicionarContato,
    modificaTelefoneContato, modificaNomeContato, cadastraContato, modificaTitleTab,
    cancelarChamado, transferiAtendimentoParaSetorChat,
    modificaKeyContacts, modificaDadosContactsSelecionado, enviaContatosParaReducerExport, modificaModalRespostaPadrao,
    modificaModalPreviewImage, modificaUrlPreviewImage, modificaNamePreviewImage, modificaPrioridadeSelecionado, modificaLocalSelecionado,
    modificaDataVencimentoTelaChamadoAbertura, alteraDescricaoChamado, modificaProblemaEstaticoSelecionadoNovoAtendimento, modificaNomeProblemaEstaticoSelecionadoNovoAtendimento,
    listaProblemasEstaticosTelaNovoChamado, alterarInfoChat, modificaSetorSelecionado, modificaVisibilidadeModalAlterarChat,
    ativaDesativaLoadingCarregaContatosWppExport
} from '../../actions/ChamadoAction';
import {
    enviaAtendimentoParaReducerChatExport, desligaOuvintesChamadosFirebase,
    enviaAcoesAtendimentoParaReducerChatExport, listaOsUltimosChamadosEmEspera, modificaloadingCarregandoChamadosEmEsperaRefreshControlExport
} from '../../actions/DashBoardAction';
import {
    listaAtendentesTelaConsultaAtendente
} from '../../actions/AtendenteAction';
import { listaRespostasPadroes, verificaSeChatEstaHabilitadoTelaAtendimentoOnline, modificaClicouCFGchat } from '../../actions/ConfiguracaoAction';
import {

    listaSetorSectionedTelaConsultaSetor
} from "../../actions/SetorAction";
import {
    Container, SubContainer, ContainerPrincipal, ContainerSecundario,
    CabecalhoPrincipal, ContainerBatepapo, ContainerEnviaMensagem,

    ContainerDadosCliente, IconeSair, ContainerInputSearch, SubContainerCliente,
    ContainerImagemAlterarCliente, ContainerPrincipalDadosCliente, InputEmpresa, SubContainerClientePermissao,
    ContainerPermitir, TextPermitir, ContainerDadosLoginCliente, ContainerBotaoAlterar, ButtonEntrar,
    DivIconeExcluirCliente, IconeExcluirCliente, DivContainerInputSenha, IconeMostrarSenha,
    IconeEmpresaVinculada, DivContainerInputEmpresaVinculada, ContainerModal, ContainerIconeFecharModal,
    TextNovoAtendimento, IconeFechar, ContainerImagemVincularEmpresa, ContainerExplicacaoVincular,
    ButtonVincular, TextVincular, TextCabecalhoEmAtendimentoLoading, IconeEmpresaVinculadaOff, ContainerModalNovoCliente,
    ContainerDadosNovoCliente, ContainerAtendentesSelecionados, ContainerSelectSearch,
    TextAtendentesSetor, CabecalhoPrincipalLoading,
    TextCabecalhoPrincipalLoading, InputDescricaoChamadoLoading, TextGravandoAudio,

    ContainerChamado, ContainerListaVazia, ContainerDadosChamado,
    ContaineDadosChamadosPrincipal,
    ContainerIconeEFrase, TextCliente,
    SubContainerIconeEFraseClienteAtendente,
    ContainerSeta, TextEmpresa, ImageLoading, ContainerChamadoLoading, ImagemPerfilLoading,
    InputLoadingEmpresa, ContainerDadosClienteLoading, SubContainerClientePermissaoLoading,
    ContainerEmAtendimento, CabecalhoSecundario, ContainerEmEspera,
    ContainerChamadosEmAtendimento, ContainerChamadosEmEspera, DivTextAtendimento, DivBotaoEncerrarAtendimento,
    TextCabecalhoAtendimento, TextSubCabecalhoAtendimento, ButtonEncerrarChamado, CabecalhoSecundarioEmEspera,
    DivTruncarTexto, DivCountChamadoEspera, ButtonImpedidoAtenderChamado, TextTitulo, ImagemComentario, ContainerClipBaixarArquivo, TextBaixarArquivo,
    TextAvaliar, TextMensagem, ContainerModalAlterar, DivSeparadoraMaisInformacoes, DivLAbelCliente, DivCliente, LabelCliente,
    ContainerMaisInformacoes, SubContainerMaisInformacoes, TextBotaoSalvarComentar, TextBotaoCancelarAtendimento, ButtonNaoCancelarAtendimento,
    DivInputMotivoCancelamento, ContainerModalPrioridadeAtendimento, ButtonCancelarAtendimento, ContainerAtendentes,
    ContainerInputDescricaoChamado, InputDescricaoChamado, IconeAcaoChat, TextBotaoNovo, ButtonVariosNovos,
    MarcaDaguaBatePapo, ContainerFlatListBatePapo, TextLoadingEnviandoARquivo, DivRespostasPadroes, TextTitleResposta, TextResposta,
    DivAtendentes, TextAtendentes, ContainerTextCabecalhoModalAcoes, TextCabecalhoModalAcoes, ContainerImagem, DivCountNotif, IconeAudioEmAtendimento,
    ContainerModalRespostas, ButtonLinkCfg, DivTesteFlatList, LabelClienteCabecalho, ContainerMessageClient, MessageCliente,
    ContainerModalGravarAudio, DivInputComentarChamado, ContainerInputEBotaoComent, InputDescricaoChamado2,

    TabsModified, TabListModified, TabModified, TabPanelModified, TextTab,
    IconeTab, ContainerConversas, DivPesquisaConversas, InputPesquisaConversa, ContainerInputConversa,
    ButtonIniciarAtendimento, DivTextAtendimentoEmEspera, ContainerFlatListOverFlow, TextResponder,
    DivTextResponder, ContainerModalEncaminharMensagem, ContainerInputPesquisaConversaEncaminharMensagem,
    DivPesquisaEncaminharMensagem, ContainerInputCompartilhar, RadioCheck, CheckBoxLabelRadio,
    ButtonEnviarMensagemEncaminhada, DivTruncarTextoEncaminharMensagem, ImageLoadingPerfil, ButtonIniciarAtendimentoLoading,
    ButtonWhatsApp, ContentImageInnerZoom, ContainerModalConectarWpp, DivBotaoSalvarComentario, ContainerAtendentes2,
    ImagePerfil, InputMaskModied, InputCancelamento, DivTextoReabrirAtendimento,
    InputSearch, DivInputSearch, IconeSetaPraBaixo, ContainerInputComentarioTransferir, InputComentarioTransferir,
    ButtonEnviarTransferencia, ButtonNaoCancelarTransferencia, IconeExcluirOpcaoMarcada,
    IconGravadorVoz, ContainerDetalhesModalNovoAtendimento, SubContainerNovoAtendimento, InputDataVencimento,
    IconeSetaPraBaixoDataVencimento, DivBotaoNovoAtendimento, BotaoCadastrarAtendimento,
    TextCadastrar, ContainerModalAlterarConversa, ContainerInputDescricaoChamadoAlterChat, InputDescricaoChamadoAlterChat,
    ContainerModalViewImage, ContainerIconeFecharImagemPreview, IconeFecharImagePreview, ImagePreview, DivSelectCabecalho, TextPrioridadeCabecalho

} from './styled';
import 'react-tabs/style/react-tabs.css';
const sound = new Howl({
    src: [soundnotification],
    //  autoplay: true,
    // loop: true,
    volume: 0.6,
    /*  onend: function () {

     } */
});
const soundEmEspera = new Howl({
    src: [atendimentoemesperamp3],
    //autoplay: true,
    loop: true,
    volume: 0.5,
    /*   onend: function () {

      } */
});
const CheckBoxWrapper = styled.div`
position: relative;
margin-left:10px;
height: 20px;
`;
const CheckBoxLabel = styled.label`
position: absolute;
top: 0;
left: 0;
width: 36px;
height: 19px;
border-radius: 15px;
background: #CCC;

cursor: pointer;
&::after {
  content: "";
  display: block;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  margin: 3px;
  background: #ffffff;
  box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
  transition: 0.2s;
}
`;
const CheckBox = styled.input`
opacity: 0;
z-index: 1;

border-radius: 15px;
width: 38px;
height: 22px;
&:checked + ${CheckBoxLabel} {
  background: #00FF7F;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 14px;
    height: 14px;
    margin-left: 18px;
    transition: 0.2s;
  }
}
`;
const AsyncStorage = {
    setItem: function (key, value) {
        return Promise.resolve().then(function () {
            localStorage.setItem(key, value);
        });
    },
    getItem: function (key) {
        return Promise.resolve().then(function () {
            return localStorage.getItem(key);
        });
    }
};
moment.locale('pt-br', {
    months: 'Janeiro_Fevereiro_Março_Abril_Maio_Junho_Julho_Agosto_Setembro_Outubro_Novembro_Dezembro'.split('_')
    //  weekdays:'Seg_Ter_Qua_Qui_Sex_Sab_Dom'.split('_'),
})
const Mp3Recorder = new MicRecorder({ bitRate: 128 });

class AtendimentoOnline extends React.Component {
    state = {
        modalEncerrarChat: false,
        modalAtribuirAtendimento: false,
        cfgAudio: JSON.parse(localStorage.getItem('reproduzsomematendimento')) == null ? true : JSON.parse(localStorage.getItem('reproduzsomematendimento')),
        cfgAudioEmEspera: JSON.parse(localStorage.getItem('reproduzsomemespera')) == null ? true : JSON.parse(localStorage.getItem('reproduzsomemespera')),
        audioEmEsperaExecucao: false,
        modalRespostaPadrao: false,
        viewEmoji: false,
        viewMicrophone: false,
        recordSecs: 0,
        bufferAudio: '',
        duration: '00:00:00',
        statusAudio: 'gravar',
        timer: null,
        audioDetails: {
            url: null,
            blob: null,
            chunks: null,
            duration: {
                h: null,
                m: null,
                s: null,
            }
        },
        blobAudioPuro: '',
        isRecording: false,
        blobURL: '',
        isBlocked: false,

        loadingFileDownload: 0,
        clickFileDownload: false,
        downloadFile: false,
        nomeFileDownload: '',
        pesquisaConversa: '',
        defaultTabIndex: 0,
        dadosModalAtendimentoEmProcesso: [],
        noDataContacts: false,
        noDataAtendimento: false,
        noDataEspera: false,
        resultadosPesquisaContacts: [],
        resultadosPesquisaAtendimentos: [],
        resultadosPesquisaEspera: [],
        mostraRefreshControlEmEspera: false,

        viewResponder: false,
        commentResponder: '',
        idResponder: '',
        circleColorResponder: '',
        commentResponderType: null,
        focusMessageBox: 'vazio',
        atendenteResponder: '',


        modalEncaminharMensagem: false,
        pesquisaConversasOuContatosEncaminharMensagem: '',
        noDataContactsEncaminharMensagem: false,
        noDataAtendimentoEncaminharMensagem: false,
        noDataEsperaEncaminharMensagem: false,
        resultadosPesquisaContactsEncaminharMensagem: [],
        resultadosPesquisaAtendimentosEncaminharMensagem: [],
        resultadosPesquisaEsperaEncaminharMensagem: [],

        dadosMensagemParaSerEncaminhada: [],
        arrayKeysClientesEnviaMensagemEncaminhada: [],
        mensagemParaSerEncaminhada: [],
        modalAlterarNome: false,

        criandoContainer: true,
        //resetar para false abaixo
        modalConnectWhatsapp: false,
        infoConectadoOk: false,
        modalDesconectarWhatsapp: false,
        desconectandoContainer: false,
        errorConnectarContainer: false,
        modalNovoCliente: false,
        titlePage: '',
        motivoCancelamento: '',
        modalCancelarAtendimento: false,

        arrayAtendentesTransferir: [],
        atendenteSelecionadoTransferir: '',
        nomeAtendenteSelecionadoTransferir: '',

        comentarioTransferirChamado: '',

        arraySetoresTransferir: [],
        setorSelecionadoTransferir: '',
        nomeSetorSelecionadoTransf: '',
        limiteContato: 20,
        loadingexibeContato: true,

        limiteContatoPesquisado: 20,
        loadingexibeContatoPesquisado: true,
        visualizaMensagemExcluida: false,
        modalAlterarChat: false,
        dataVencimento: ''

    }
    componentWillMount() {
        this.refsArray = [];
        this.props.verificaSeChatEstaHabilitadoTelaAtendimentoOnline()
    }
    componentDidMount() {





        if (this.props.criarChamadoProblemaEstaticoAtendimento == true) {
            this.props.listaProblemasEstaticosTelaNovoChamado();
        }
        this.props.listaRespostasPadroes();
        if (this.props.nomeRota !== 'Chat') {
            this.props.modificaNomeRotaNoHeader('Chat')
        }



    }
    componentDidUpdate(nextProps, nextState) {

        /*


                 */

        if (this.props.atendentesAcoesChamado !== nextProps.atendentesAcoesChamado || nextProps.atendentesAcoesChamado.length > 0 && nextState.arrayAtendentesTransferir.length === 0) {
            if (nextProps.atendentesAcoesChamado.length > 0) {
                const items = [{
                    name: 'ATENDENTES',
                    type: 'group', items: nextProps.atendentesAcoesChamado
                }];
                this.setState({ arrayAtendentesTransferir: items })
            } else if (this.props.atendentesAcoesChamado.length > 0) {
                const items = [{
                    name: 'ATENDENTES',
                    type: 'group', items: nextProps.atendentesAcoesChamado
                }];
                this.setState({ arrayAtendentesTransferir: items })
            }


        }
        if (this.props.setoresSectionedConsulta !== nextProps.setoresSectionedConsulta || nextProps.setoresSectionedConsulta.length > 0 && nextState.arraySetoresTransferir.length === 0) {
            /*  const items = [{
                 name: 'ATENDENTES',
                 type: 'group', items: nextProps.atendentesAcoesChamado
             }]; */
            if (nextProps.setoresSectionedConsulta.length > 0) {
                this.setState({ arraySetoresTransferir: nextProps.setoresSectionedConsulta })
            } else if (this.props.setoresSectionedConsulta.length > 0) {
                this.setState({ arraySetoresTransferir: this.props.setoresSectionedConsulta })
            }
        }
        /*      if (this.props.chamadosEmAtendimentoOnline !== nextProps.chamadosEmAtendimentoOnline && this.props.chamadosEmAtendimentoOnline !== undefined && nextProps.chamadosEmAtendimentoOnline !== undefined) {
                 let result = nextProps.chamadosEmAtendimentoOnline.filter(function (o1) {
                     return this.props.chamadosEmAtendimentoOnline.some(function (o2) {
                         return o1.key !== o2.key; // return the ones with equal id
                     });
                 });


             } */
        if (nextProps.atendimentosPorKeyReducerChat !== undefined && this.props.atendimentosPorKeyReducerChat !== nextProps.atendimentosPorKeyReducerChat) {

            if (nextProps.atendimentosPorKeyReducerChat.status == 'Fechado') {
                firebase.database().ref(`${nextProps.atendimentosPorKeyReducerChat.chaveUnica}/acoesChamados/${nextProps.atendimentosPorKeyReducerChat.key}`).off("value");
                firebase.database().ref(`${nextProps.atendimentosPorKeyReducerChat.chaveUnica}/chamados/`).orderByKey().equalTo(nextProps.atendimentosPorKeyReducerChat.key).off("value")
                //se o channel chamado for vazioo, é porque o atendente encerrou o chat, atraves do botao encerrar,
                //entao posso tirar o chat da tela
                //
                if (nextProps.atendimentosPorKeyReducerChat.channelChamado == '') {
                    //  this.props.enviaAtendimentoParaReducerChatExport([])
                    // this.props.modificaKeyAtendimentoEmAtendimentoOnline('');
                }

            }

        }
        if (this.props.loadingCarregandoRespostasAntigas === true && nextProps.loadingCarregandoRespostasAntigas === false) {
            if (nextProps.idRespostaAntiga !== '') {
                /*
                 let keyChamado = nextProps.atendimentosPorKeyReducerChat.key;
                 let informacoesCompletas = [];
                 informacoesCompletas[keyChamado] = nextProps.acoesDoAtendimentoChat;
                  */
                // this.props.enviaAcoesAtendimentoParaReducerChatExport(informacoesCompletas)
                this.setState({ focusMessageBox: nextProps.idRespostaAntiga })
            }
        }
        //desativando a conversa do chat em espera, quando ele é atendido, tenho que desligar a conversa



        if (this.props.keyContacts !== nextProps.keyContacts) {
            this.zeraMensagemResponder()
        }
        if (this.props.configWhatsapp.length > 0 && nextProps.configWhatsapp.length > 0 &&
            this.props.configWhatsapp[0].statusConnect !== nextProps.configWhatsapp[0].statusConnect) {

            if (nextProps.configWhatsapp[0].statusConnect === 'connected' || nextProps.configWhatsapp[0].statusConnect === 'chatsAvailable' || nextProps.configWhatsapp[0].statusConnect === 'isSynced' ||
                nextProps.configWhatsapp[0].statusConnect === "isConnected" || nextProps.configWhatsapp[0].statusConnect === "isLogged") {
                this.setState({ infoConectadoOk: true });
            }
            if (nextProps.configWhatsapp[0].statusConnect === 'connected' && nextState.defaultTabIndex === 2 ||
                nextProps.configWhatsapp[0].statusConnect === 'chatsAvailable' && nextState.defaultTabIndex === 2
                || nextProps.configWhatsapp[0].statusConnect === "isConnected" || nextProps.configWhatsapp[0].statusConnect === "isLogged") {

                //this.props.getAllContatcsWpp(this.props.configWhatsapp);
            }
        }
        if (this.props.keyContacts !== nextProps.keyContacts && this.state.statusAudio == 'gravacao' || this.props.keyContacts !== nextProps.keyContacts && this.state.statusAudio == 'gravando') {
            //alert('desliga o som de'+nextProps.data.numeroChamado)
            this.onStopRecord(true);
        }
        /*   if (this.props.acoesDoAtendimentoChat !== nextProps.acoesDoAtendimentoChat) {
              let acoesChamadosEmAtendimento = nextProps.acoesDoAtendimentoChat;



              acoesChamadosEmAtendimento.forEach(childItem => {

              })

          } */
        setTimeout(() => {
            ReactTooltip.rebuild();
        }, 1000);

    }
    componentWillUnmount() {
        AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            firebase.database().ref(`${chaveUnica}/setores`).orderByKey().limitToLast(1).off("value");
        })
        this.setState({
            defaultTabIndex: 0, limiteContato: 20, limiteContatoPesquisado: 20, loadingexibeContatoPesquisado: true,
            loadingexibeContato: true
        })
        this.props.modificaKeyAtendimentoEmAtendimentoOnline('');
        this.props.modificaKeyAtendimentoEmEsperaAtendimentoOnline('');
        this.props.modificaKeyContacts('');
        this.props.modificaDadosContactsSelecionado([]);
        this.props.enviaContatosParaReducerExport([])

        if (soundEmEspera.playing() == true) {
            soundEmEspera.stop();
        }
        this.onStopRecord(true);


    }
    searchDebounce = _.debounce((value, isContact) => {
        if (isContact) {
            this.updateSearch(value);
        } else {
            this.updateSearchEncaminhar(value);
        }
    }, 500);

    pesquisaClientesExcluidosComErroChamado() {
        AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            firebase.database().ref(`${chaveUnica}/clientes/`).orderByChild('excluido').equalTo(1).once('value').then((snapshot) => {
                let arrayUltimosChamados = [];
                snapshot.forEach(childItem => {
                    firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild('telefoneCliente').equalTo(childItem.val().empresa).limitToLast(1).once('value').then((snapshot) => {
                        arrayUltimosChamados.push(snapshot.val())
                    })
                });

            })
        })
    }
    toggleModalAlterarNome() {
        this.setState({ modalAlterarNome: !this.state.modalAlterarNome })
    }
    /*   shouldComponentUpdate(nextProps, nextState) {
          if (nextProps.acoesDoAtendimentoChat ||
              nextProps.conversasCarregadasEmEspera) {
              return false;
          } else {
              return true;
          }
      } */
    comparer(otherArray) {
        return function (current) {
            return otherArray.filter(function (other) {
                return other.key == current.key
            }).length == 0;
        }
    }
    addStr(str, index, stringToAdd) {
        return str.substring(0, index) + stringToAdd + str.substring(index, str.length);
    }
    removeStr(str, index) {
        return str = str.slice(0, index) + str.slice(index + 1);
    }
    stripPhonenumber(phonenumber) {
        return phonenumber.replace(/[^0-9]+/g, "");
    }
    calculaDias2(date1, date2) {
        //formato do brasil 'pt-br'
        // moment.locale('pt-br');
        //setando data1
        let data1 = moment(date1, 'DD/MM/YYYY');
        //setando data2
        let data2 = moment(date2, 'DD/MM/YYYY');
        //tirando a diferenca da data2 - data1 em dias
        // let diff = data2.diff(data1, 'days');
        let diff = moment.duration(data2.diff(data1)).asDays();

        return diff;
    }
    handleWeekDays = (day) => {
        //Change week day with custom day array
        day._locale._weekdaysMin = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'];
        // return the actual dates value(like 1,2,3 ...) from the moment object.
        return (day.format('D'));
    }
    retornaDataAtual() {
        let dataAtual = moment().toDate();
        dataAtual = moment(dataAtual).format('DD/MM/YYYY');
        return dataAtual;
    }
    renderQtdAtendLiberados() {
        if (this.props.qtdAtendentesLiberados == 999) {
            return 'ilimitado';
        } else {
            return this.props.qtdAtendentesLiberados;
        }
    }
    calculaDiasRestantesVersaoGratis(date1, date2) {
        //formato do brasil 'pt-br'
        // moment.locale('pt-br');
        //setando data1
        let data1 = moment(date1, 'DD/MM/YYYY');
        //setando data2
        let data2 = moment(date2, 'DD/MM/YYYY');

        //tirando a diferenca da data2 - data1 em dias
        // let diff = data2.diff(data1, 'days');
        let diff = Math.abs(data2.diff(data1, 'days'));

        return diff;
    }
    renderMicrophoneRecording() {
        if (this.state.statusAudio == 'gravando') {
            return (
                <div style={{ display: 'flex', flexDirection: 'row', justifyItems: 'center', alignItems: 'center' }}>
                    < IconGravadorVoz
                        style={{ cursor: 'pointer', fontSize: 18 }}
                        onClick={() => { this.onStopRecord(true); }}
                        colorDinamico={'#000'}
                        bordaDinamica={this.props.corBotaoPadrao}
                        className="icon-rubbish-bin" />

                    {<p style={{
                        color: "#000",
                        fontSize: 17,
                        fontWeight: 'bold',
                        letterSpacing: 3,
                        marginLeft: 8,
                        marginRight: 8
                    }}>{this.transformaMinutosEmSegundos(this.state.recordSecs)}</p>}
                    <ScaleLoader
                        css={{ marginLeft: 10, marginRight: 10, marginTop: 5 }}
                        width={20}
                        height={12}
                        //size={60}
                        color={'#909090'}
                        loading
                    />
                    < IconGravadorVoz
                        style={{ cursor: 'pointer', marginRight: 3 }}
                        onClick={() => { this.onStopRecord(false) }}
                        colorDinamico={'#ff5e3e'}
                        bordaDinamica={this.props.corBotaoPadrao}
                        className="icon-stop-button" />
                    < IconGravadorVoz
                        style={{ cursor: 'pointer' }}
                        onClick={() => {

                            this.onStopRecord(false);
                            setTimeout(() => {
                                this.enviarAudioFirebaseEmEsperaParaIniciarAtendimento()
                            }, 500);

                        }}
                        // colorDinamico={'#00FA9A'}
                        colorDinamico={'#00BEA4'}
                        bordaDinamica={this.props.corBotaoPadrao}
                        className="icon-send2" />
                </div>
            )
        } else if (this.state.statusAudio == 'gravacao') {
            return (

                <div style={{ display: 'flex', flexDirection: 'row', justifyItems: 'center', alignItems: 'center' }}>
                    < IconGravadorVoz
                        style={{ cursor: 'pointer', fontSize: 18 }}
                        onClick={() => { this.onStopRecord(true) }}
                        colorDinamico={'#000'}
                        bordaDinamica={this.props.corBotaoPadrao}
                        className="icon-rubbish-bin" />

                    <div style={{ display: 'flex', width: 250, alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                        <audio style={{ height: 35, width: 250 }} src={this.state.blobURL} controls />
                    </div>
                    {/*  < IconGravadorVoz
                        style={{ cursor: this.props.data.status === 'Fechado' ? 'no-drop' : 'pointer', marginRight: 3 }}
                        onClick={() => { if (this.props.data.status === 'Fechado') { } else { this.onStopRecord(false); } }}
                        colorDinamico={'#ff5e3e'}
                        bordaDinamica={this.props.corBotaoPadrao}
                        className="icon-stop-button" /> */}
                    < IconeMostrarSenha
                        style={{ cursor: 'pointer' }}
                        onClick={() => { this.enviarAudioFirebaseEmEsperaParaIniciarAtendimento() }}
                        // colorDinamico={'#00FA9A'}
                        colorDinamico={'#00BEA4'}
                        bordaDinamica={this.props.corBotaoPadrao}
                        className="icon-send2" />
                </div>

            )
        }
        else {
            return (
                /*   < IconeMostrarSenha
                      style={{ cursor: this.props.data.status === 'Fechado' ? 'no-drop' : 'pointer', marginLeft: 10 }}
                      data-tip={this.props.data.status === 'Fechado' ? 'Atendimento Encerrado' : "Enviar mensagem de áudio"} data-for="tip-top"
                      onClick={() => { if (this.props.data.status === 'Fechado') { } else { this.onStartRecord() } }}
                      colorDinamico={'#383838'}
                      bordaDinamica={this.props.corBotaoPadrao}
                      className="icon-microphone" /> */
                < IconeMostrarSenha
                    style={{ cursor: 'pointer', marginLeft: 10 }}
                    data-tip={"Enviar mensagem de áudio"}
                    data-for="tip-top"
                    onClick={() => { this.onStartRecord() }}
                    colorDinamico={'#383838'}
                    bordaDinamica={this.props.corBotaoPadrao}
                    className="icon-microphone" />
            )
        }
    }
    zeraListaContatosParaEncaminhar() {
        this.setState({
            dadosMensagemParaSerEncaminhada: [],
            arrayKeysClientesEnviaMensagemEncaminhada: [],
            mensagemParaSerEncaminhada: [],
            modalEncaminharMensagem: false,
            pesquisaConversasOuContatosEncaminharMensagem: '',
            noDataAtendimentoEncaminharMensagem: false,
            noDataContactsEncaminharMensagem: false,
            noDataEsperaEncaminharMensagem: false

        })
    }
    zeraMensagemResponder() {
        this.setState({
            viewResponder: false,
            commentResponder: '',
            idResponder: '',
            circleColorResponder: '',
            commentResponderType: null,
            atendenteResponder: ''
        })
    }
    /*    shouldComponentUpdate(nextProps, nextState) {
           if (this.state.defaultTabIndex !== nextState.defaultTabIndex) {
               return false;
           } else {
               return true;
           }
       } */
    /*    scrollTo = (ref) => {


          if (ref) {
              ref.scrollIntoView({ behavior: 'smooth', block: 'start' })
          }
      } */
    _toggleModalEncaminharMensagem() {
        this.setState({ modalEncaminharMensagem: !this.state.modalEncaminharMensagem })
        if (!this.state.modalEncaminharMensagem === true) {
            this.props.getAllContatcsWpp(this.props.configWhatsapp, this.props.allClientes)
        }
        /* else {
           this.props.enviaContatosParaReducerExport([])
       } */
    }


    renderTextGravacaoAudio() {
        if (this.state.statusAudio == 'gravar') {
            return (
                <p style={{
                    color: this.props.coresLetraPadrao,
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: 18,
                    fontFamily: 'Source Sans Pro'
                }}>Pressione no Microphone para Gravar</p>
            )
        } else if (this.state.statusAudio == 'gravando') {
            return (
                <TextGravandoAudio corDinamico={this.props.coresLetraPadrao} >GRAVANDO</TextGravandoAudio>
            )
        } else {
            //play audio
            return (
                <p style={{
                    color: this.props.coresLetraPadrao,
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: 18,
                    fontFamily: 'Source Sans Pro'
                }}>Gravação</p>
            )
        }

    }
    comentaChat(value) {
        this.props.alteraComentarioChat(value.target.value);
    }
    _toggleModalGravarAudio() {
        this.handleRest()
        this.setState({ viewMicrophone: !this.state.viewMicrophone });
    }

    _toggleModalAlterarChat() {
        //this.setState({ modalAlterarChat: !this.state.modalAlterarChat })
        this.props.modificaVisibilidadeModalAlterarChat(!this.props.modalAlterarChat)

    }
    millisToMinutesAndSeconds(millis) {
        let minutes = Math.floor(millis / 60000);
        let seconds = ((millis % 60000) / 1000).toFixed(0);
        return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
    }
    handleRest() {
        const reset = {
            url: null,
            blob: null,
            chunks: null,
            duration: {
                h: null,
                m: null,
                s: null,
            }
        }
        this.setState({ audioDetails: reset });
    }
    renderBotaoGravarAudio() {

        if (this.state.statusAudio == 'gravar') {
            return (
                <div style={{ display: 'flex', height: 100, justifyContent: 'center', alignItems: 'center', paddingBottom: 5 }}>
                    <div style={{
                        display: 'flex', height: 60, width: 60, borderRadius: 50, backgroundColor: '#F50257', justifyContent: 'center', alignItems: 'center', cursor: 'pointer'
                    }} onClick={() => this.onStartRecord()}>
                        <i className="icon-microphone" style={{ fontSize: 28, color: '#FFF' }} />
                    </div>
                </div>
            )
        } else if (this.state.statusAudio == 'gravando') {

            return (
                <div style={{ display: 'flex', height: 100, justifyContent: 'center', alignItems: 'center', paddingBottom: 5 }}>

                    <div style={{
                        display: 'flex', height: 60, width: 60, borderRadius: 50, backgroundColor: this.props.corSegundoBotaoPadrao, justifyContent: 'center', alignItems: 'center',
                        cursor: 'pointer'
                    }} onClick={() => {
                        /*     setTimeout(() => {
                                this.setState({
                                    recordSecs: 0,
                                    statusAudio: 'gravacao'
                                });
                            }, 100);
                            AudioManager.stopRecord(); */


                        this.onStopRecord(false);

                    }}>
                        <i className="icon-stop-button-black-rounded-square" style={{ fontSize: 19, color: this.props.letraBotaoPadrao }} />
                    </div>
                </div>
            )
        } else {
            //play audio

            return (
                <div style={{ height: 300, display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                        <audio src={this.state.blobURL} controls />
                    </div>
                    <div style={{ display: 'flex', height: 65, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around', height: 45, width: 90, background: this.props.corSegundoBotaoPadrao, borderRadius: 10, cursor: 'pointer' }} onClick={() => {

                            this.onStopRecord(true)
                        }}>
                            {/*   <Icon name="rubbish-bin" size={15} color={'#EC6A69'} style={{ marginRight: 3 }}
                            /> */}
                            {/*  <i className="icon-download" style={{ fontSize: 15, color: this.props.corBotaoPadrao }} /> */}
                            <p style={{ color: this.props.letraBotaoPadrao, fontSize: 17, fontWeight: 'bold' }}>Limpar</p>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around', height: 45, width: 90, background: this.props.corBotaoPadrao, borderRadius: 10, cursor: 'pointer' }} onClick={() => { this.enviarAudioFirebaseEmEsperaParaIniciarAtendimento() }}>
                            {/*      <i className="icon-download" style={{ fontSize: 15, color: this.props.corBotaoPadrao }} /> */}
                            <p style={{ color: this.props.letraBotaoPadrao, fontSize: 17, fontWeight: 'bold' }}>Enviar</p>
                        </div>
                    </div>
                    {/* <TouchableOpacity
                        style={styles.viewBarWrapper}
                        onPress={(e) => { this.onStatusPress(e) }}
                    >
                        <View style={styles.viewBar}>
                            <View style={[styles.viewBarPlay, { width: playWidth }]} />
                        </View>
                    </TouchableOpacity>*/}


                </div >
            )
        }

    }
    onStartRecord = () => {
        /*   navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {


              this.setState({ isBlocked: false });
              if (this.state.isBlocked) {

              } else { */
        Mp3Recorder
            .start()
            .then((e) => {
                this.setState({ isRecording: true, statusAudio: 'gravando' });
                let timer = setInterval(this.tick, 1000);
                this.setState({ timer });
            }).catch((e) => console.error(e));
        /*  }
     }).catch(() => {

         this.setState({ isBlocked: true })
     })

*/
    }
    /*   onStartRecord = () => {
          navigator.getUserMedia({ audio: true },
              () => {

                  this.setState({ isBlocked: false });
                  if (this.state.isBlocked) {

                  } else {
                      Mp3Recorder
                          .start()
                          .then((e) => {

                              this.setState({ isRecording: true, statusAudio: 'gravando' });
                              let timer = setInterval(this.tick, 1000);
                              this.setState({ timer });
                          }).catch((e) => console.error(e));
                  }
              },
              () => {

                  this.setState({ isBlocked: true })
              },
          );
      } */

    onStopRecord = (apenasLimpar) => {
        if (apenasLimpar == true) {
            if (this.state.isRecording) {
                Mp3Recorder.stop()
                    .getMp3()
                    .then(([buffer, blob]) => {
                        clearInterval(this.state.timer);
                        this.setState({
                            blobURL: '',
                            isRecording: false,
                            recordSecs: 0,
                            blobAudioPuro: '',
                            statusAudio: 'gravar',
                            timer: null,
                            bufferAudio: ''
                        });

                    }).catch((e) => console.error(e));
            } else {
                clearInterval(this.state.timer);
                this.setState({
                    blobURL: '',
                    isRecording: false,
                    recordSecs: 0,
                    blobAudioPuro: '',
                    statusAudio: 'gravar',
                    timer: null,
                    bufferAudio: ''
                });
            }

        } else {
            Mp3Recorder.stop()
                .getMp3()
                .then(([buffer, blob]) => {

                    const blobURL = URL.createObjectURL(blob)
                    clearInterval(this.state.timer);
                    this.setState({
                        blobURL,
                        isRecording: false,
                        //recordSecs: 0,
                        blobAudioPuro: blob,
                        statusAudio: 'gravacao',
                        timer: null,
                        bufferAudio: buffer
                    });

                    /* const file = new File(buffer, `audioweb${new Date().getTime()}.mp3`, {
                        type: blob.type,
                        lastModified: Date.now()
                    });

                    const player = new Audio(URL.createObjectURL(file));
                    player.controls = true; */
                    //  player.play();

                }).catch((e) => console.error(e));
        }
    }
    renderResponderMensagem(item) {
        //onReplyClick={() => { this.renderResponderMensagem(item) }}
        let input = this.nativeTextInput;
        input.focus();
        if (item.tipo === 'mensagem') {
            this.setState({ commentResponderType: 'texto', commentResponder: item.description, idResponder: item.idMensagem, circleColorResponder: item.circleColor, viewResponder: true, atendenteResponder: item.title })
        } else if (item.tipo === 'location') {
            this.setState({ commentResponderType: 'location', commentResponder: 'Localização', idResponder: item.idMensagem, circleColorResponder: item.circleColor, viewResponder: true, atendenteResponder: item.title })
        } else if (item.tipo === 'vcard') {
            this.setState({ commentResponderType: 'contato', commentResponder: 'Contato', idResponder: item.idMensagem, circleColorResponder: item.circleColor, viewResponder: true, atendenteResponder: item.title })
        } else {
            if (item.tipoArquivo.includes('image') && !item.tipoArquivo.includes('corel')) {
                this.setState({ commentResponderType: 'imagem', commentResponder: 'Imagem', idResponder: item.idMensagem, circleColorResponder: item.circleColor, viewResponder: true, atendenteResponder: item.title })
            } else if (item.tipoArquivo.includes('video') && item.audio !== true || item.tipoArquivo.includes('mp4') && item.audio !== true) {
                this.setState({ commentResponderType: 'video', commentResponder: 'Vídeo', idResponder: item.idMensagem, circleColorResponder: item.circleColor, viewResponder: true, atendenteResponder: item.title })
            } else if (item.audio == true) {
                this.setState({ commentResponderType: 'audio', commentResponder: 'Mensagem de Voz', idResponder: item.idMensagem, circleColorResponder: item.circleColor, viewResponder: true, atendenteResponder: item.title })
            } else {
                this.setState({ commentResponderType: 'arquivo', commentResponder: 'Arquivo', idResponder: item.idMensagem, circleColorResponder: item.circleColor, viewResponder: true, atendenteResponder: item.title })
            }
        }
    }
    zeraPesquisa() {
        this.updateSearch('');
        this.setState({ pesquisaConversasOuContatosEncaminharMensagem: '', pesquisaConversa: '' });
    }
    tick = () => {
        this.setState({
            recordSecs: this.state.recordSecs + 1
        });
    }
    milliseconds = (h, m, s) => ((h * 60 * 60 + m * 60 + s) * 1000);
    getFileBlob = (url, cb) => {
        let xhr = new XMLHttpRequest();
        xhr.open("GET", url);
        xhr.responseType = "blob";
        xhr.addEventListener('load', function () {
            cb(xhr.response);
        });
        xhr.send();
    };
    getFileBlobPromise = (url) => {
        return new Promise(function (resolve, reject) {
            let xhr = new XMLHttpRequest();
            xhr.open("GET", url);
            xhr.responseType = "blob";
            xhr.addEventListener('load', function () {
                resolve(xhr);
            });
            xhr.send();
        }).then((xhr) => {
            return xhr.response;
        })
    };
    handleAudioStop(data) {
        this.setState({ audioDetails: data });
    }
    enviarAudioFirebaseEmEsperaParaIniciarAtendimento() {
        //this._toggleModalGravarAudio();

        let duracao = (this.state.recordSecs * 1000);
        let duracaoNan = Number.isNaN(duracao)
        if (duracaoNan == true) {
            duracao = 1000
        }
        const file = new File(this.state.bufferAudio, `audioweb-${new Date().getTime()}.ogg`, {
            type: 'audio/ogg',
            lastModified: Date.now()
        });
        file.audio = true;
        file.duracao = duracao;
        let res = [file]

        if (res.length > 0) {

            if (this.props.premium == 0) {
                toastr.error('Recurso Premium', 'Este é um recurso PREMIUM, para fazer upload de arquivos e imagens, Assine a versão PREMIUM e libere esse e mais recursos!');
            } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
                toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
            }
            else {
                this.onStopRecord(true);
                this.props.ativaDesativaLoadingIniciandoCOnversaContactsExport(true);

                let arrayDadosParaComentar = ['tem arquivo sim', false, '', res, 1, 'keyChamado', 'dataAbertura', this.props.premium, this.props.keyUsuarioLogado, 'arrayAtendimentoCompleto', true, this.props.configWhatsapp, this.state.viewResponder, this.state.idResponder, this.state.commentResponder,
                    this.state.circleColorResponder, this.state.commentResponderType, this.state.atendenteResponder, this.props.desativarAssistenteVirtualBotWpp, this.props.mostrarNomeAtendenteWpp];
                /*    let arrayDadosParaAtribuirChamado = [this.props.keyUsuarioLogado, 'keyChamado', this.props.nome, 'keySetor', 'arrayAtendimentoCompleto', true, this.props.configWhatsapp]; */

                this.verificaSeClienteJaNaoEstaEmAtendimento(this.props.dadosContactsSelecionado[0], this.props.verificaAgrupaAtendenteSetor).then(
                    (value) => {
                        this.props.enviaUltimaReeferenciaKeyEmEsperaExport('');
                        this.props.enviaConversasCarregadasEmEsperaExport([]);
                        this.props.enviaUltimaReeferenciaKeyEmAtendimentoExport('');
                        this.props.enviaConversasCarregadasEmAtendimentoExport([]);
                        if (value === false) {
                            this.props.ativaDesativaLoadingIniciandoCOnversaContactsExport(false)
                        } else if (value === undefined) {
                            this.props.ativaDesativaLoadingIniciandoCOnversaContactsExport(false)
                        }
                        else if (value.statusChamado === 'Aberto') {
                            this.setState({ modalAtenderAtendimentoEmProcesso: true })
                            let arrayInfo = [];
                            arrayInfo.push(value)
                            this.setState({ dadosModalAtendimentoEmProcesso: arrayInfo })

                        } else if (value.statusChamado === 'Atendendo') {
                            toastr.warning('Error', `Este cliente já está em atendimento com o atendente "${value.atendente}", aguarde o término do atendimento para uma nova conversa`)
                            this.props.ativaDesativaLoadingIniciandoCOnversaContactsExport(false)
                        } else {

                            this.props.iniciaAtendimentoPorContacts(value.keyCliente, this.props.keyUsuarioLogado, this.props.nome, this.props.dadosContactsSelecionado[0].phone, false, '', '', '', '', arrayDadosParaComentar);
                            this.setState({ pesquisaConversasOuContatosEncaminharMensagem: '', pesquisaConversa: '' });
                            this.updateSearchEncaminhar('');
                            this.zeraMensagemResponder();
                            setTimeout(() => {
                                this.setState({ defaultTabIndex: 0 })
                            }, 1000);

                        }
                    }
                );

            }

        } else {
            toastr.error('Error ao Enviar Audio', 'Tente novamente');
        }
        //   let duracaoMilisegundos = this.milliseconds(this.state.audioDetails.duration.h, this.state.audioDetails.duration.m, this.state.audioDetails.duration.s)
        //  let duracao = duracaoMilisegundos;

    }
    enviarAudioFirebase() {
        this._toggleModalGravarAudio();
        let duracao = (this.state.recordSecs * 1000);
        let duracaoNan = Number.isNaN(duracao)
        if (duracaoNan == true) {
            duracao = 1000
        }
        const file = new File(this.state.bufferAudio, `audioweb-${new Date().getTime()}.ogg`, {
            type: 'audio/ogg',
            lastModified: Date.now()
        });
        file.audio = true;
        file.duracao = duracao;
        let res = [file]

        if (res.length > 0) {

            if (this.props.premium == 0) {
                toastr.error('Recurso Premium', 'Este é um recurso PREMIUM, para fazer upload de arquivos e imagens, Assine a versão PREMIUM e libere esse e mais recursos!');
            } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
                toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
            }
            else {

                this.props.ativaDesativaLoadingEnviandoAudioChatExport(true)
                this.props.salvarComentario('tem arquivo sim', false, '', res, 1, this.props.atendimentosPorKeyReducerChat.key, this.props.atendimentosPorKeyReducerChat.dataAbertura, this.props.premium, this.props.atendimentosPorKeyReducerChat.keyAtendente, this.props.atendimentosPorKeyReducerChat, true, this.props.configWhatsapp, this.state.viewResponder, this.state.idResponder, this.state.commentResponder,
                    this.state.circleColorResponder, this.state.commentResponderType, this.state.atendenteResponder, this.props.desativarAssistenteVirtualBotWpp, this.props.mostrarNomeAtendenteWpp);
                this.onStopRecord(true);
                this.zeraMensagemResponder()
            }

        } else {
            toastr.error('Error ao Enviar Audio', 'Tente novamente');
        }
        //   let duracaoMilisegundos = this.milliseconds(this.state.audioDetails.duration.h, this.state.audioDetails.duration.m, this.state.audioDetails.duration.s)
        //  let duracao = duracaoMilisegundos;

    }
    addEmoji = (event, emojiObject) => {
        /* this.props.alteraComentarioChat(this.props.comentarioChat + event.native); */
        let input = this.nativeTextInput;
        input.value = this.nativeTextInput.value + event.native;
        input.focus();
    };

    salvarComentario() {
        let comentario = this.nativeTextInput.value;
        if (comentario.trim() == '') {
            toastr.error('Error', 'Digite uma mensagem');
        } else {
            this.props.salvarComentario('', false, comentario, [], 1, this.props.atendimentosPorKeyReducerChat.key, this.props.atendimentosPorKeyReducerChat.dataAbertura, this.props.premium, this.props.atendimentosPorKeyReducerChat.keyAtendente, this.props.atendimentosPorKeyReducerChat, true, this.props.configWhatsapp, this.state.viewResponder, this.state.idResponder, this.state.commentResponder,
                this.state.circleColorResponder, this.state.commentResponderType, this.state.atendenteResponder, this.props.desativarAssistenteVirtualBotWpp, this.props.mostrarNomeAtendenteWpp);
            let input = this.nativeTextInput;
            input.value = '';
            input.focus();
            this.zeraMensagemResponder()
        }

    }
    _toggleModalGravarAudio() {
        this.handleRest()
        this.setState({ viewMicrophone: !this.state.viewMicrophone });
    }
    _toggleModalRespostaPadrao() {
        // this.setState({ modalRespostaPadrao: !this.state.modalRespostaPadrao })
        this.props.modificaModalRespostaPadrao(!this.props.modalRespostaPadrao)
    }
    _toggleViewEmoji() {
        this.setState({ viewEmoji: !this.state.viewEmoji })
    }
    renderTextArquivosMensagemRespondidas(item) {
        if (item.commentResponderType === 'imagem') {
            return <p><i className="icon-imagewpp" style={{ fontSize: 13, color: '#000' }} /> Imagem</p>
        } else if (item.commentResponderType === 'video') {
            return <p><i className="icon-video-camera" style={{ fontSize: 13, color: '#000' }} /> Vídeo</p>
        } else if (item.commentResponderType === 'location') {
            return <p><i className="icon-localization" style={{ fontSize: 13, color: '#000' }} /> Localização</p>
        } else if (item.commentResponderType === 'contato') {
            return <p><i className="icon-man-user" style={{ fontSize: 13, color: '#000' }} /> Contato</p>
        } else if (item.commentResponderType === 'audio') {
            return <p><i className="icon-microphone" style={{ fontSize: 13, color: '#000' }} /> Mensagem de Voz</p>
        } else if (item.commentResponderType === 'arquivo') {
            return <p><i className="icon-documentwpp" style={{ fontSize: 13, color: '#000' }} /> Arquivo</p>
        }
        else if (item.commentResponderType === 'link' && item.commentResponderTypeArquivo.includes('image') && !item.commentResponderTypeArquivo.includes('corel')) {
            return <p><i className="icon-imagewpp" style={{ fontSize: 13, color: '#000' }} /> Imagem</p>
        } else if (item.commentResponderType === 'link' && item.commentResponderTypeArquivo.includes('video') && item.commentResponderIsAudio !== true || item.commentResponderTypeArquivo.includes('mp4') && item.commentResponderIsAudio !== true) {
            return <p><i className="icon-video-camera" style={{ fontSize: 13, color: '#000' }} /> Vídeo</p>
        }
        else if (item.commentResponderType === 'link' && item.commentResponderIsAudio === true) {
            return <p><i className="icon-microphone" style={{ fontSize: 13, color: '#000' }} /> Mensagem de Voz</p>
        }
        else if (item.commentResponderType === 'link' && !item.commentResponderTypeArquivo.includes('image') && !item.commentResponderTypeArquivo.includes('corel') && !item.commentResponderTypeArquivo.includes('video') && item.commentResponderIsAudio === false) {
            return <p><i className="icon-documentwpp" style={{ fontSize: 13, color: '#000' }} /> Arquivo</p>
        }
        else {
            return <p>{item.mensagemResposta}</p>
        }
    }

    renderMensagemReplyEmEspera(item) {
        if (item.possuiResposta) {

            let cliente = this.props.keyContacts !== '' ? this.props.dadosContactsSelecionado[0].nome : this.props.atendimentosPorKeyReducerChatEmEspera.cliente
            /*     { this.state.commentResponderType === 'imagem' && <i className="icon-imagewpp" style={{ fontSize: 14, color: '#000' }} /> }
                { this.state.commentResponderType === 'video' && <i className="icon-video-camera" style={{ fontSize: 14, color: '#000' }} /> }

                { this.state.commentResponderType === 'location' && <i className="icon-localization" style={{ fontSize: 14, color: '#000' }} /> }
                { this.state.commentResponderType === 'contato' && <i className="icon-man-user" style={{ fontSize: 14, color: '#000' }} /> }
                { this.state.commentResponderType === 'audio' && <i className="icon-microphone" style={{ fontSize: 14, color: '#000' }} /> }
                { this.state.commentResponderType === 'arquivo' && <i className="icon-documentwpp" style={{ fontSize: 14, color: '#000' }} /> }
                { this.state.commentResponder } */
            return {
                title: item.circleColorResponder === '#39a3f4' ? cliente : this.props.nome,
                titleColor: item.circleColorResponder === '#00FF7F' ? '#16cb9c' : "#39a3f4",
                message: (<p>
                    {item.commentResponderType === 'imagem' && <i className="icon-imagewpp" style={{ fontSize: 13, color: '#000' }} />}
                    {item.commentResponderType === 'video' && <i className="icon-video-camera" style={{ fontSize: 13, color: '#000' }} />}

                    {item.commentResponderType === 'location' && <i className="icon-localization" style={{ fontSize: 13, color: '#000' }} />}
                    {item.commentResponderType === 'contato' && <i className="icon-man-user" style={{ fontSize: 13, color: '#000' }} />}
                    {item.commentResponderType === 'audio' && <i className="icon-microphone" style={{ fontSize: 13, color: '#000' }} />}
                    {item.commentResponderType === 'arquivo' && <i className="icon-documentwpp" style={{ fontSize: 13, color: '#000' }} />}
                    {item.mensagemResposta}
                </p>),
            }
        } else {
            return null
        }

    }
    renderRespostaPadrao(item, index) {
        return (
            <DivRespostasPadroes backgrounddinamico={this.props.backgroundSegundo} style={{ marginTop: 15, marginLeft: 15 }} key={item.value}>
                <TextTitleResposta onClick={() => {
                    this._toggleModalRespostaPadrao();
                    //this.zeraMensagemResponder();
                    if (this.props.keyAtendimentoEmEsperaAtendimentoOnline !== '') {
                        this[this.props.atendimentosPorKeyReducerChatEmEspera.key].setInputValue(item.name, this.props.atendimentosPorKeyReducerChatEmEspera.key);

                    } else if (this.props.keyAtendimentoEmAtendimentoOnline !== '') {
                        this[this.props.atendimentosPorKeyReducerChat.key].setInputValue(item.name, this.props.atendimentosPorKeyReducerChat.key);
                        /*  this.props.salvarComentario('', false, item.name, [], 1, this.props.atendimentosPorKeyReducerChat.key, this.props.atendimentosPorKeyReducerChat.dataAbertura, this.props.premium, this.props.atendimentosPorKeyReducerChat.keyAtendente, this.props.atendimentosPorKeyReducerChat, true, this.props.configWhatsapp, this.state.viewResponder, this.state.idResponder, this.state.commentResponder,
                             this.state.circleColorResponder, this.state.commentResponderType, this.state.atendenteResponder); */
                    } else {
                        let input = this.nativeTextInput;
                        input.style.height = `${40}px`
                        input.value = item.name;
                        let altura = input.scrollHeight;
                        input.style.height = `${altura}px`
                        input.focus();
                    }

                }} corLetraBotaoPadrao={this.props.corBotaoPadrao} corLetraDinamico={this.props.coresLetraPadrao}>{item.subTitle}  <TextResposta corLetraBotaoPadrao={this.props.corBotaoPadrao} corLetraDinamico={this.props.coresLetraSecundario} >{item.name}</TextResposta></TextTitleResposta>
            </DivRespostasPadroes>
        )
    }
    comparaStatusDosObjetos(x, y) {
        let objectsAreSame = true;
        for (let status in x) {
            if (x[status] !== y[status]) {
                objectsAreSame = false;
                break;
            }
        }
        return objectsAreSame;
    }
    renderIconeAudioEmAtendimento() {
        //  let cfgAudio = JSON.parse(localStorage.getItem('reproduzsomematendimento')) == null ? true : JSON.parse(localStorage.getItem('reproduzsomematendimento'))
        /*  if (this.props.chamadosEmAtendimentoOnline.length > 0) { */


        if (this.state.cfgAudio == false) {
            //  sound.pause();
            return (
                <IconeAudioEmAtendimento onClick={() => {
                    localStorage.setItem(`reproduzsomematendimento`, JSON.stringify(true));
                    this.setState({ cfgAudio: true })
                }} data-tip="Ativar som de novas mensagens" data-for="tip-top" className="icon-audio-speaker-off" colorDinamico='red' />
            )
        } else {

            //sound.play();
            return (
                <IconeAudioEmAtendimento onClick={() => {
                    this.setState({ cfgAudio: false })
                    localStorage.setItem(`reproduzsomematendimento`, JSON.stringify(false));
                }} data-tip="Desativar som de novas mensagens" data-for="tip-top" className="icon-audio-speaker-on" colorDinamico={this.props.coresLetraPadrao} />
            )
        }
        /*   } else {
              //  sound.pause();
              return null
          } */
    }
    renderSoundEmEspera() {

        //  let cfgAudio = JSON.parse(localStorage.getItem('reproduzsomematendimento')) == null ? true : JSON.parse(localStorage.getItem('reproduzsomematendimento'))
        //
        //
        let quantidadeChamadoEmEspera = 0;
        for (let i = 0; i < this.props.chamadosEmEsperaAtendimentoOnline.length; i++) {
            if (this.props.chamadosEmEsperaAtendimentoOnline[i].permiteVisualizarChamado !== false) {
                quantidadeChamadoEmEspera++
            }
        }
        if (quantidadeChamadoEmEspera > 0) {


            if (this.state.cfgAudioEmEspera == false) {
                setTimeout(() => {
                    if (soundEmEspera.playing() == true) {
                        soundEmEspera.pause();
                    }
                    /* this.setState({ audioEmEsperaExecucao: false }) */
                }, 700);


            } else {
                setTimeout(() => {
                    /*   if (this.state.audioEmEsperaExecucao == false) { */
                    if (soundEmEspera.playing() == false) {
                        soundEmEspera.play();
                    }
                    /*    this.setState({ audioEmEsperaExecucao: true }) */
                    /* } */
                }, 700);




            }
        } else {
            setTimeout(() => {
                if (soundEmEspera.playing() == true) {
                    soundEmEspera.pause();
                }
                /* this.setState({ audioEmEsperaExecucao: false }) */
            }, 700);
        }
    }
    rendericoneAudioEmEspera() {

        if (this.state.cfgAudioEmEspera == false) {


            return (
                <IconeAudioEmAtendimento onClick={() => {
                    localStorage.setItem(`reproduzsomemespera`, JSON.stringify(true));
                    this.setState({ cfgAudioEmEspera: true })
                }} data-tip="Ativar som de novos atendimentos em espera" data-for="tip-top" className="icon-audio-speaker-off" colorDinamico='red' />
            )
        } else {




            return (
                <IconeAudioEmAtendimento onClick={() => {
                    this.setState({ cfgAudioEmEspera: false })
                    localStorage.setItem(`reproduzsomemespera`, JSON.stringify(false));
                }} data-tip="Desativar som de novos atendimentos em espera" data-for="tip-top" className="icon-audio-speaker-on" colorDinamico={this.props.coresLetraPadrao} />
            )
        }

    }
    _handleKeyDown = (e) => {
        /*  if (this.props.enviandoComentario == false) { */
        if (e.key === 'Enter' && e.shiftKey) {
            /*   $('#app').append("<br/> Detected Shift+Enter") */
        }
        else if (e.key === 'Enter') {
            if (this.props.atendimentosPorKeyReducerChat.status !== 'Fechado') {
                let comentario = this.nativeTextInput.value;
                this.nativeTextInput.value = '';
                if (comentario.trim() == '') {
                    // toastr.error('Error', 'Digite uma mensagem');
                    e.preventDefault();
                } else {
                    e.preventDefault();
                    this.salvarComentario();
                    this.nativeTextInput.style = { height: 40 };
                    this.nativeTextInput.focus();
                }
            } else {
                toastr.error('Esta conversa já foi encerrada');
                e.preventDefault();
            }
        }
        /*  } */
    }
    _handleKeyDownEmEspera = (e) => {

        /*  if (this.props.enviandoComentario == false) { */
        if (e.key === 'Enter' && e.shiftKey) {
            /*   $('#app').append("<br/> Detected Shift+Enter") */
        }
        else if (e.key === 'Enter') {

            let comentario = this.nativeTextInput.value;
            if (comentario.trim() == '') {
                // toastr.error('Error', 'Digite uma mensagem');
                e.preventDefault();
            } else {
                e.preventDefault();
                this.salvarComentarioEmEsperaComInicioDeAtendimento();
                this.nativeTextInput.style = { height: 40 };
                this.nativeTextInput.focus()
            }
        }
        /*  } */
    }
    /*   renderBotoesDireito() {

          return (
              <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                  <i
                      onClick={() => {

                          this._toggleModalRespostaPadrao()

                      }}
                      data-tip={"Exibir respostas padrões"} data-for="tip-top" className="icon-list-on-window" style={{ fontSize: 20, color: '#383838', marginLeft: 10, cursor: 'pointer' }} />
                  {this.props.loadingEnviandoAudioChat == true && <ClipLoader
                      css={{ marginLeft: 10, marginRight: 10 }}
                      style={{ marginLeft: 10, marginRight: 10 }}
                      size={21}
                      color={'#383838'}
                      loading={true}
                  />}

                  {this.props.loadingEnviandoAudioChat == false &&
                  < IconeMostrarSenha style={{ cursor: 'pointer', marginLeft: 10 }} data-tip={"Enviar mensagem de áudio"} data-for="tip-top" onClick={() => {
                       if (this.props.atendimentosPorKeyReducerChat.status === 'Fechado') { } else { this.setState({ viewMicrophone: true }) }
                       }} colorDinamico={'#383838'} bordaDinamica={this.props.corBotaoPadrao} className="icon-microphone" />}


                  <i
                      onClick={() => { if (this.props.atendimentosPorKeyReducerChat.status === 'Fechado') { } else { this.salvarComentario() } }}
                      className="icon-send2" style={{ fontSize: 20, color: '#383838', marginLeft: 10, cursor: this.props.atendimentosPorKeyReducerChat.status === 'Fechado' ? 'no-drop' : 'pointer' }} />
              </div>
          )
      } */

    renderIconeUploadArquivoChatEmEspera() {

        if (this.props.textArquivoChat !== '') {
            return (
                <ClipLoader
                    css={{ marginLeft: 10, marginRight: 10 }}
                    style={{ marginLeft: 10, marginRight: 10 }}
                    size={18}
                    color={'#383838'}
                    loading={true}
                />
                /*   <TextLoadingEnviandoARquivo corDinamico={this.props.corBotaoPadrao}>{this.props.textArquivoChat}</TextLoadingEnviandoARquivo> */
            )
        } else {
            return (
                <Dropzone
                    multiple={true}
                    noKeyboard
                    noDrag
                    maxSize={2e+7}
                    /*  onDropRejected={() => {
                         toastr.error('Error', 'Adicione arquivos de no máximo 20MB')
                     }} */
                    onDrop={acceptedFiles => this.escolheArquivoChamadoEmEsperaComInicioDeAtendimento(acceptedFiles)}>
                    {({ getRootProps, getInputProps, isDragActive }) => {
                        //

                        return (
                            /*     <section> */
                            <div   {...getRootProps()}>
                                <input  {...getInputProps()} />
                                <IconeMostrarSenha style={{ cursor: 'pointer' }} className="icon-clip" data-tip="Enviar arquivos" data-for="tip-top" colorDinamico='#383838' backgroundBotao='#383838' />
                            </div>
                            /*   </section> */
                        )
                    }}
                </Dropzone>
            )
        }
    }
    renderBordaDrag(isDragActive, isDragAccept, isDragReject) {
        if (isDragAccept == true) {
            return '#00FF7F';
        } else {
            return 'red';
        }
    }
    mudaTabIndexParaEmAtendimento() {
        this.setState({ defaultTabIndex: 0 })
    }
    renderDivCountChamadoEmAtendimento() {
        if (this.props.chamadosEmAtendimentoOnline.length > 0) {
            return (
                <DivCountChamadoEspera backgrounddinamico={'#FFF'} corletradinamica={'#000'}>{this.props.chamadosEmAtendimentoOnline.length}</DivCountChamadoEspera>
            )
        } else if (this.props.chamadosEmAtendimentoOnline.length > 9) {
            return (
                <DivCountChamadoEspera backgrounddinamico={'#FFF'} corletradinamica={'#000'}>9+</DivCountChamadoEspera>
            )

        } else {
            return null;
        }
    }
    renderDivCountChamadoEspera() {
        let quantidadeChamadoEmEspera = 0;
        for (let i = 0; i < this.props.chamadosEmEsperaAtendimentoOnline.length; i++) {
            if (this.props.chamadosEmEsperaAtendimentoOnline[i].permiteVisualizarChamado !== false) {
                quantidadeChamadoEmEspera++
            }
        }
        if (quantidadeChamadoEmEspera === 0) {
            return null
        }
        else if (quantidadeChamadoEmEspera < 25) {
            return (
                <DivCountChamadoEspera backgrounddinamico={'#FFF'} corletradinamica={'#000'}>{quantidadeChamadoEmEspera}</DivCountChamadoEspera>
            )
        } else if (quantidadeChamadoEmEspera >= 25) {
            return (
                <DivCountChamadoEspera backgrounddinamico={'#FFF'} corletradinamica={'#000'}>25+</DivCountChamadoEspera>
            )

        } else {
            return null;
        }
    }
    updateSearch(value) {
        this.props.modificaKeyAtendimentoEmAtendimentoOnline("")
        this.props.modificaKeyAtendimentoEmEsperaAtendimentoOnline("")
        this.props.modificaKeyContacts("")
        this.searchTextContatos(value)
    }
    updateSearchEncaminhar(value) {
        this.props.modificaKeyAtendimentoEmAtendimentoOnline("")
        this.props.modificaKeyAtendimentoEmEsperaAtendimentoOnline("")
        this.props.modificaKeyContacts("")
        this.searchTextContatosEncaminharMensagem(value)

    }
    renderBotoesDireitoEmEspera() {

        return (
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                {this.state.statusAudio !== 'gravando' && this.state.statusAudio !== 'gravacao' && <i
                    onClick={() => { this.props.modificaModalRespostaPadrao(!this.props.modalRespostaPadrao) }}
                    className="icon-list-on-window" style={{ fontSize: 20, color: '#383838', marginLeft: 10, cursor: 'pointer' }} />}

                {this.props.loadingEnviandoAudioChat == true && <ClipLoader
                    css={{ marginLeft: 10, }}
                    style={{ marginLeft: 10, }}
                    size={21}
                    color={'#383838'}
                    loading={true}
                />}

                {this.props.loadingEnviandoAudioChat == false && this.renderMicrophoneRecording()}
                {this.state.statusAudio !== 'gravando' && this.state.statusAudio !== 'gravacao' && <i
                    onClick={() => { this.salvarComentarioEmEsperaComInicioDeAtendimento() }}
                    className="icon-send2" style={{ fontSize: 20, color: '#383838', marginLeft: 10, cursor: 'pointer' }} />}

            </div>
        )
    }


    baixarArquivo(nomeArquivo, arquivo) {
        /*  AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
             const chaveUnica = chaveUnica2.replace(/"/g, ""); */
        /* toastr.warning('Download Iniciado', 'Baixando Arquivo...'); */
        this.setState({ nomeFileDownload: nomeArquivo, loadingFileDownload: 0.2, downloadFile: true, clickFileDownload: true })
        /* firebase.storage().ref().child(`${this.props.atendimentosPorKeyReducerChat.chaveUnica}/${this.props.atendimentosPorKeyReducerChat.key}/${this.props.atendimentosPorKeyReducerChat.key}/${nomeArquivo}`).getDownloadURL().then((url) => {
           firebase.storage().ref().child(`${this.props.atendimentosPorKeyReducerChat.chaveUnica}/${this.props.atendimentosPorKeyReducerChat.key}/${this.props.atendimentosPorKeyReducerChat.key}/${nomeArquivo}`).getMetadata().then((metadata) => {
               //
               this.setState({ nomeFileDownload: nomeArquivo, loadingFileDownload: 0.5, downloadFile: true, clickFileDownload: true }) */
        this.setState({ nomeFileDownload: nomeArquivo, loadingFileDownload: 0.5, downloadFile: true, clickFileDownload: true })
        this.getFileBlob(arquivo.urlDownload, (blob) => {

            this.setState({ nomeFileDownload: nomeArquivo, loadingFileDownload: 0.9, downloadFile: true, clickFileDownload: true })
            let file = new File([blob], nomeArquivo, { type: blob.type });
            saveAs(file)
            this.setState({ nomeFileDownload: '', loadingFileDownload: 0, downloadFile: false, clickFileDownload: false })
        })
        /*   saveAs(url, metadata.contentType); */
        /*  var link = document.createElement("a");
        if (link.download !== undefined) {
            //usar quando for abrir imagem
            link.setAttribute("href", url);
            link.setAttribute("target", "_blank");
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } */

        /* }).catch(function (error) {

            this.setState({ nomeFileDownload: '', loadingFileDownload: 0, downloadFile: false, clickFileDownload: false })
            //
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {

                case 'storage/object-not-found':
                    // File doesn't exist
                    break;

                case 'storage/unauthorized':
                    // User doesn't have permission to access the object
                    break;

                case 'storage/canceled':
                    // User canceled the upload
                    break;

                case 'storage/unknown':
                    // Unknown error occurred, inspect the server response
                    break;
            }
        });
    }).catch(function (error) {

        this.setState({ nomeFileDownload: '', loadingFileDownload: 0, downloadFile: false, clickFileDownload: false })
        //
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {

            case 'storage/object-not-found':
                // File doesn't exist
                break;

            case 'storage/unauthorized':
                // User doesn't have permission to access the object
                break;

            case 'storage/canceled':
                // User canceled the upload
                break;

            case 'storage/unknown':
                // Unknown error occurred, inspect the server response
                break;
        }
    }); */
        /*  }) */
    }
    baixarArquivo2(arquivo, event) {
        let state = this.state;
        state.nomeFileDownload = arquivo.description;
        state.loadingFileDownload = 0.5;
        state.downloadFile = true;
        state.clickFileDownload = true;
        this.setState({ state })
    }
    downloadImage(url, name) {


        this.props.modificaUrlPreviewImage(url)
        this.props.modificaModalPreviewImage(true)
        this.props.modificaNamePreviewImage(name)
        /*  let link = document.createElement("a");
         //usar quando for abrir imagem
         let urlFormatada = this.fixedEncodeURIComponent(url)

         link.setAttribute("href", urlFormatada);
         link.setAttribute("target", "_blank");
         link.style.visibility = 'hidden';
         document.body.appendChild(link);
         link.click();
         document.body.removeChild(link); */
        /*  const a = document.createElement('a');
         a.href = url;
         a.download = name;
         a.click();
         a.target = "_blank";

         window.URL.revokeObjectURL(url); */
        /*  const a = document.createElement('a');
         a.href = url;
         a.download = name;
         a.click();
         a.target = "_blank";
         window.open(url); */
    };
    fixedEncodeURIComponent(str) {
        return encodeURI(str).replace(/[!'()*]/g, function (c) {
            return '';
        });
    }
    renderClickButtonReply(item, ref) {
        AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            /*  this.refsArray[item.key].props.focus = true; */
            /*  this.refsArray[item.key].focus() */
            /*  this.refsArray[item.key].scrollIntoView({ behavior: 'smooth', block: 'start' }) */

            let telefoneCliente = this.props.dadosContactsSelecionado[0].phone;
            // firebase.database().ref(`relacionamentoMensagensStatusWpp`).orderByChild('idMensagem').equalTo(item.idMensagemResponder).limitToFirst(1).once('value').then((snapshot) => {
            firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild('telefoneCliente').equalTo(telefoneCliente).limitToLast(1).once('value').then((snapshot) => {
                if (snapshot.val()) {
                    let keyChamado = '';
                    let keyCliente = '';
                    snapshot.forEach(childItem => {
                        keyChamado = childItem.key;
                        keyCliente = childItem.val().keyCliente;
                    })
                    let conversaJaListada = false;
                    for (let i = 0; i < this.props.conversasUltimoAtendimento.length; i++) {
                        if (this.props.conversasUltimoAtendimento[i].idMensagem === item.idMensagemResponder) {
                            conversaJaListada = true;
                            break;
                        }
                    }
                    if (conversaJaListada === true) {
                        this.setState({ focusMessageBox: item.idMensagemResponder })
                    } else {
                        if (item.keyAtendimentoResponder === "") {
                            this.props.listaConversasAntigasResponder(keyChamado, item.idMensagemResponder, this.props.conversasUltimoAtendimento, 'contacts', this.props.verificaAgrupaAtendenteSetor, this.props.isAdmin, this.props.usuarioAdmVisualizaApenasChamadosDoSetorVinculado, this.props.keyUsuarioLogado, keyCliente, true, this.props.atendenteVeApenasProprioHistoricoChamado)
                        } else {
                            this.props.listaConversasAntigasResponder(item.keyAtendimentoResponder, item.idMensagemResponder, this.props.conversasUltimoAtendimento, 'contacts', this.props.verificaAgrupaAtendenteSetor, this.props.isAdmin, this.props.usuarioAdmVisualizaApenasChamadosDoSetorVinculado, this.props.keyUsuarioLogado, keyCliente, true, this.props.atendenteVeApenasProprioHistoricoChamado)
                        }
                        /*   this.props.listaConversasAntigasResponder(keyChamado, item.idMensagemResponder, this.props.conversasUltimoAtendimento, 'contacts') */
                    }
                } else {
                }
            })

        })
    }

    escolheArquivoChamado(res) {
        //
        if (this.props.atendimentosPorKeyReducerChat.status === 'Cancelado') {
            toastr.error('Error ao Anexar', 'Atendimento Cancelado');
        } else if (this.props.atendimentosPorKeyReducerChat.status === 'Fechado') {
            toastr.error('Error ao Anexar', 'Atendimento Fechado');
        } else if (this.props.textArquivoChat !== '') {
            toastr.error('Error ao Anexar', 'Tem arquivo sendo upado neste momento, por favor aguarde.');
        } else {
            if (res.length > 0) {
                //
                /*  for (let i = 0; i < res.length; i++) { */

                //
                if (this.props.premium == 0) {
                    toastr.error('Recurso Premium', 'Este é um recurso PREMIUM, para fazer upload de arquivos e imagens, Assine a versão PREMIUM e libere esse e mais recursos!');
                } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
                    toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
                }
                else {
                    //
                    this.props.uploadArquivoChat('tem arquivo sim', false,
                        '', res, true, this.props.atendimentosPorKeyReducerChat.key, this.props.atendimentosPorKeyReducerChat, this.props.atendimentosPorKeyReducerChat.dataAbertura, this.props.premium, false, this.props.atendimentosPorKeyReducerChat.keyAtendente, this.props.atendimentosPorKeyReducerChat, this.props.licensawebouapp, true, this.props.configWhatsapp, this.state.viewResponder, this.state.idResponder, this.state.commentResponder,
                        this.state.circleColorResponder, this.state.commentResponderType, this.state.atendenteResponder);
                    this.zeraMensagemResponder()

                }
                /*  } */
            } else {
                toastr.error('Error ao Anexar', 'Selecione arquivo com no máximo 20MB');
            }
        }
    }
    verificaValorContemNoArray(array, key) {
        return array.some(function (el) {
            return el.key === key;
        });
    }
    renderImagemConversasEmAtendimentoEmcaminhar(item) {
        let urlImagem = imgUser;
        if (item.urlImagemNuvem === null || item.urlImagemNuvem === undefined || item.urlImagemNuvem === '') {
            const primeiraLetra = item.cliente.substring(0, 1).trim().toLowerCase();
            urlImagem = this.verificaSeEhAlphabeto(primeiraLetra);
        } else {
            urlImagem = item.urlImagemNuvem;
        }
        return (
            <img src={urlImagem} alt="foto" style={{ height: 40, width: 40, borderRadius: 50, backgroundColor: 'rgba(159,173,183, 0.3)' }} />
        )

    }
    renderImagemConversasEmAtendimentoEmcaminharContatos(item) {

        let urlImagem = imgUser;
        if (item.urlImagem === null || item.urlImagem === undefined || item.urlImagem === '') {
            const primeiraLetra = item.nome.substring(0, 1).trim().toLowerCase();
            urlImagem = this.verificaSeEhAlphabeto(primeiraLetra);
        } else {
            urlImagem = item.urlImagem;
        }
        return (
            <img src={urlImagem} alt="foto" style={{ height: 40, width: 40, borderRadius: 50, backgroundColor: 'rgba(159,173,183, 0.3)' }} />
        )

    }
    renderConversasEncaminharFAtendimentos(item) {
        return (
            <ContainerChamado key={item.key} onClick={() => {
                this.handleInputChange(item)
            }} bordadinamica={this.props.coresLetraSecundario} backGroundDinamico={this.props.backgroundPrimeiro} type="button" >
                <ContainerInputCompartilhar>

                    <CheckBoxWrapper>
                        <RadioCheck
                            id={item.key}
                            colordinamico={this.props.coresLetraSecundario}
                            type="checkbox"
                            checked={this.state.arrayKeysClientesEnviaMensagemEncaminhada.includes(item.key) || this.state.dadosMensagemParaSerEncaminhada.filter(e => e.telefoneCliente === item.key).length > 0}
                            onChange={(e) => { }}
                            value={item.key}
                            disabled
                            backgroundinamico={this.props.corBotaoPadrao}
                        />

                        <CheckBoxLabelRadio htmlFor={item.key} corLetraDinamico={this.props.coresLetraPadrao} backgrounddinamico={this.props.backgroundPrimeiro} />

                        <i className="icon-checkwpp2" style={{ fontSize: 13, color: this.state.arrayKeysClientesEnviaMensagemEncaminhada.includes(item.key) === true || this.state.dadosMensagemParaSerEncaminhada.filter(e => e.telefoneCliente === item.key).length > 0 ? this.props.letraBotaoPadrao : this.props.backgroundPrimeiro, zIndex: 999, position: 'absolute', right: 0, bottom: 2 }} />


                    </CheckBoxWrapper>
                </ContainerInputCompartilhar>
                <ContainerImagem>
                    {this.renderImagemConversasEmAtendimentoEmcaminhar(item)}


                </ContainerImagem>
                <ContainerDadosChamado bordadinamica={this.props.coresLetraSecundario}>
                    <ContaineDadosChamadosPrincipal>
                        <SubContainerIconeEFraseClienteAtendente >
                            <TextCliente corLetraDinamica={this.props.coresLetraPadrao}>{item.cliente}</TextCliente>
                        </SubContainerIconeEFraseClienteAtendente>
                        <SubContainerIconeEFraseClienteAtendente >
                            <TextEmpresa corLetraDinamica={this.props.coresLetraSecundario}>{item.empresa}</TextEmpresa>
                        </SubContainerIconeEFraseClienteAtendente>
                    </ContaineDadosChamadosPrincipal>

                </ContainerDadosChamado>


            </ContainerChamado>
        )
    }

    handleInputChange(item) {
        /* setTimeout(() => { */
        let arrayKeys = [];
        let dadosMensagem = this.state.dadosMensagemParaSerEncaminhada;
        let arrayAtual = this.state.arrayKeysClientesEnviaMensagemEncaminhada;
        if (!arrayAtual.includes(item.key)) {
            if (dadosMensagem.length >= 5) {
                toastr.warning('Você só pode compartilhar com até 5 conversas')
            } else {
                arrayKeys.push(item.key)
                dadosMensagem.push(item)
                let newArray = arrayAtual.concat(arrayKeys);
                this.setState({ arrayKeysClientesEnviaMensagemEncaminhada: newArray, dadosMensagemParaSerEncaminhada: dadosMensagem })
            }
        } else {
            const index = arrayAtual.indexOf(item.key);
            if (index > -1) {
                arrayAtual.splice(index, 1);
            }


            for (let i = 0; i < dadosMensagem.length; i++) {
                if (dadosMensagem[i].key === item.key) {
                    dadosMensagem.splice(i, 1);
                }
            }
            this.setState({ arrayKeysClientesEnviaMensagemEncaminhada: arrayAtual, dadosMensagemParaSerEncaminhada: dadosMensagem })
        }
        /* }, 200); */
    }
    renderConversasEncaminharContatos(item) {
        return (
            <ContainerChamado key={item.key} onClick={() => {
                this.handleInputChange(item)
            }} bordadinamica={this.props.coresLetraSecundario} backGroundDinamico={this.props.backgroundPrimeiro} type="button" >
                <ContainerInputCompartilhar>

                    <CheckBoxWrapper>
                        <RadioCheck
                            id={item.key}
                            colordinamico={this.props.coresLetraSecundario}
                            type="checkbox"
                            checked={this.state.arrayKeysClientesEnviaMensagemEncaminhada.includes(item.key) || this.state.dadosMensagemParaSerEncaminhada.filter(e => e.telefoneCliente === item.key).length > 0}
                            onChange={(e) => { }}
                            value={item.key}
                            disabled
                            backgroundinamico={this.props.corBotaoPadrao}
                        />

                        <CheckBoxLabelRadio htmlFor={item.key} corLetraDinamico={this.props.coresLetraPadrao} backgrounddinamico={this.props.backgroundPrimeiro} />
                        <i className="icon-checkwpp2" style={{ fontSize: 13, color: this.state.arrayKeysClientesEnviaMensagemEncaminhada.includes(item.key) === true || this.state.dadosMensagemParaSerEncaminhada.filter(e => e.telefoneCliente === item.key).length > 0 ? this.props.letraBotaoPadrao : this.props.backgroundPrimeiro, zIndex: 999, position: 'absolute', right: 0, bottom: 2 }} />
                    </CheckBoxWrapper>
                </ContainerInputCompartilhar>
                <ContainerImagem>
                    {this.renderImagemConversasEmAtendimentoEmcaminharContatos(item)}
                    {/*   <img src={item.urlImagem === null || item.urlImagem === '' || item.urlImagem === undefined ? imgUser : item.urlImagem} alt="foto" style={{ height: 40, width: 40, borderRadius: 50, backgroundColor: 'rgba(159,173,183, 0.3)' }} /> */}
                </ContainerImagem>
                <ContainerDadosChamado bordadinamica={this.props.coresLetraSecundario}>
                    <ContaineDadosChamadosPrincipal>
                        <SubContainerIconeEFraseClienteAtendente >
                            <TextCliente corLetraDinamica={this.props.coresLetraPadrao}>{item.nome}</TextCliente>
                        </SubContainerIconeEFraseClienteAtendente>
                        <SubContainerIconeEFraseClienteAtendente >
                            <TextEmpresa corLetraDinamica={this.props.coresLetraSecundario}>{item.phone}</TextEmpresa>
                        </SubContainerIconeEFraseClienteAtendente>
                    </ContaineDadosChamadosPrincipal>

                </ContainerDadosChamado>


            </ContainerChamado>
        )
    }

    renderBotaoAtender() {
        if (this.props.chamadosEmEsperaAtendimentoOnline.length == 0) {
            return (
                <ButtonImpedidoAtenderChamado data-tip="Nenhum chamado em espera" data-for="tip-top"
                    onClick={() => {

                    }} backgrounddinamico={shade(0.3, this.props.corBotaoPadrao)} corLetraDinamica={this.props.letraBotaoPadrao}>Atender</ButtonImpedidoAtenderChamado>
            )
        }
        else if (this.props.keyAtendimentoEmEsperaAtendimentoOnline == '') {
            return (
                <ButtonImpedidoAtenderChamado data-tip="Selecione um chamado para atender" data-for="tip-top"
                    onClick={() => {

                    }} backgrounddinamico={shade(0.3, this.props.corBotaoPadrao)} corLetraDinamica={this.props.letraBotaoPadrao}>Atender</ButtonImpedidoAtenderChamado>
            )
        } else if (this.verificaValorContemNoArray(this.props.chamadosEmEsperaAtendimentoOnline, this.props.keyAtendimentoEmEsperaAtendimentoOnline) == false) {
            return (
                <ButtonImpedidoAtenderChamado data-tip="Selecione um chamado para atender" data-for="tip-top"
                    onClick={() => {

                    }} backgrounddinamico={shade(0.3, this.props.corBotaoPadrao)} corLetraDinamica={this.props.letraBotaoPadrao}>Atender</ButtonImpedidoAtenderChamado>
            )
        } else {
            return (
                <ButtonEncerrarChamado onClick={() => {
                    //
                    //
                    firebase.database().ref(`${this.props.atendimentoEmEsperaSelecionado.chaveUnica}/acoesChamados/${this.props.atendimentoEmEsperaSelecionado.key}`).off("value");
                    let arraySemChamadoAtendido = this.props.chamadosEmEsperaAtendimentoOnline.filter(el => el.key !== this.props.atendimentoEmEsperaSelecionado.key);
                    this.props.enviaAtendimentoEmEsperaAtendimentoOnlineExport(arraySemChamadoAtendido)
                    this.props.atribuiAtendimentoAh(this.props.keyUsuarioLogado, this.props.atendimentoEmEsperaSelecionado.key, this.props.nome, this.props.atendimentoEmEsperaSelecionado.keySetor, this.props.atendimentoEmEsperaSelecionado, true, this.props.configWhatsapp, '', this.props.desativarAssistenteVirtualBotWpp);
                    // this.props.listaChamadoParaTelaChatOnline(this.props.atendimentoEmEsperaSelecionado.key, this.props.acoesDoAtendimentoChat)
                    this.props.modificaKeyAtendimentoEmEsperaAtendimentoOnline('');
                    this.props.modificaKeyAtendimentoEmAtendimentoOnline(this.props.atendimentoEmEsperaSelecionado.key);

                }} backgrounddinamico={this.props.corBotaoPadrao} corLetraDinamica={this.props.letraBotaoPadrao}>Atender</ButtonEncerrarChamado>
            )
        }
    }
    renderTitleSemAtendimentoCriadoPor(title, item) {
        if (title.includes('Atendimento criado por')) {
            let titleSemString = title.replace('Atendimento criado por: ', '');
            return (
                <TextTitulo corLetraDinamica={item.circleColor}>{titleSemString}</TextTitulo>
            )
        } else {
            return (
                <TextTitulo corLetraDinamica={item.circleColor}>{title}</TextTitulo>
            )

        }
    }
    renderDescriptionTimeline(item) {
        if (item.title == 'Avaliar Este Atendimento') {

            return (
                <div
                >

                    <TextMensagem corLetraDinamica='#000'>
                        {item.description}
                    </TextMensagem>
                    <TextAvaliar corPrincipalChat={this.props.corPrincipalChat} onClick={() => {
                        toastr.error('Error', 'Somente cliente pode avaliar o atendimento');
                    }} corLetraDinamica={this.props.coresLetraPadrao}>{item.title}</TextAvaliar>

                </div>
            )


        } else {
            return (
                <>
                    {this.renderTitleSemAtendimentoCriadoPor(item.title, item)}
                    <Linkify
                        componentDecorator={(decoratedHref, decoratedText, key) => (
                            <a style={{ fontSize: 15, color: '#39a3f4' }} target="blank" href={decoratedHref} key={key}>
                                {decoratedText}
                            </a>
                        )}
                    >
                        <TextMensagem corLetraDinamica='#000'>
                            {item.description}
                        </TextMensagem>
                    </Linkify>
                    {/*  < TextMensagem corLetraDinamica='#000' >
                        {item.description}
                    </TextMensagem > */}
                </>
            )
        }
    }


    renderDescriptionMessageBoxEmEspera(item) {
        if (item.title == 'Avaliar Este Atendimento') {

            return (
                <div key={item.key}>

                    <TextMensagem corLetraDinamica='#000'>
                        {item.description}
                    </TextMensagem>
                    <TextAvaliar corPrincipalChat={this.props.corPrincipalChat} onClick={() => {
                        toastr.error('Error', 'Somente cliente pode avaliar o atendimento');
                    }} corLetraDinamica={this.props.coresLetraPadrao}>{item.title}</TextAvaliar>

                </div>
            )


        } else {
            if (item.description.includes('Conversa Iniciada por ')) {
                return null
            } else {
                //onReplyClick={() => { this.renderResponderMensagem(item) }}
                return (
                    <div key={item.key}>
                        <MessageBox

                            {...item}

                            /*   ref={scrollTo} */
                            id={item.key}
                            reply={this.renderMensagemReplyEmEspera(item)}
                            onReplyMessageClick={() => { this.renderClickButtonReply(item) }}
                            onReplyClick={() => { this.renderResponderMensagem(item) }}
                            onForwardClick={() => {
                                this._toggleModalEncaminharMensagem();
                                let mensagemParaSerEncaminhada2 = [];
                                item['keyChamado'] = this.props.dadosContactsSelecionado[0].key;
                                mensagemParaSerEncaminhada2.push(item)
                                this.setState({ mensagemParaSerEncaminhada: mensagemParaSerEncaminhada2 })
                            }}
                            //onClick={() => {  }}
                            //onOpen={() => {  }}
                            position={item.circleColor === '#00FF7F' ? "right" : "left"}
                            type={'text'}
                            title={(
                                <div
                                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                >
                                    {item.title.includes('Atendimento criado por') ? item.title.replace('Atendimento criado por: ', '') : item.title}
                                    {this.state.visualizaMensagemExcluida === true && item.deleteMessage === true && <span onClick={() => { this.setState({ visualizaMensagemExcluida: false }) }} style={{ color: '#EC6A69', fontSize: 10, textDecorationLine: 'underline', marginLeft: 10, fontStyle: 'italic', cursor: "pointer" }}>Ocultar</span>}
                                </div>
                            )}
                            //title={item.title.includes('Atendimento criado por') ? item.title.replace('Atendimento criado por: ', '') : item.title}
                            titleColor={item.circleColor === '#00FF7F' ? '#16cb9c' : "#39a3f4"}
                            focus={item.idMensagem === this.state.focusMessageBox ? true : false}
                            onMessageFocused={() => { setTimeout(() => { this.setState({ focusMessageBox: 'vazio' }); }, 1000); }}
                            text={
                                (
                                    <Linkify
                                        componentDecorator={(decoratedHref, decoratedText, key) => (
                                            <a style={{ fontSize: 15, color: '#39a3f4' }} target="blank" href={decoratedHref} key={key}>
                                                {decoratedText}
                                            </a>
                                        )}
                                    >
                                        {item.description}
                                    </Linkify>
                                )
                            }
                            copiableDate
                            dateString={item.dataHoraAtual}
                            forwarded={item.statusMessage === 'waiting' ? false : true}
                            replyButton={item.statusMessage === 'waiting' ? false : true}
                            status={item.circleColor === '#39a3f4' ? null : item.statusMessage} //(waiting, sent, received, read)
                        />
                    </div>
                )
            }
        }
    }

    verificaSeEhAlphabeto = (ch) => {
        //
        let verificaSeEhAlphabeto = typeof ch === "string" && ch.length === 1
            && (ch >= "a" && ch <= "z");
        //
        //
        if (verificaSeEhAlphabeto) {
            //
            switch (ch) {
                case "a":
                    return require('../../imagens/a.png')
                    break;
                case "b":
                    return require('../../imagens/b.png')
                    break;
                case "c":
                    return require('../../imagens/c.png')
                    break;
                case "d":
                    return require('../../imagens/d.png')
                    break;
                case "e":
                    return require('../../imagens/e.png')
                    break;
                case "f":
                    return require('../../imagens/f.png')
                    break;
                case "g":
                    return require('../../imagens/g.png')
                    break;
                case "h":
                    return require('../../imagens/h.png')
                    break;
                case "i":
                    return require('../../imagens/i.png')
                    break;
                case "j":
                    return require('../../imagens/j.png')
                    break;
                case "k":
                    return require('../../imagens/k.png')
                    break;
                case "l":
                    return require('../../imagens/l.png')
                    break;
                case "m":
                    return require('../../imagens/m.png')
                    break;
                case "n":
                    return require('../../imagens/n.png')
                    break;
                case "o":
                    return require('../../imagens/o.png')
                    break;
                case "p":
                    return require('../../imagens/p.png')
                    break;
                case "q":
                    return require('../../imagens/q.png')
                    break;
                case "r":
                    return require('../../imagens/r.png')
                    break;
                case "s":
                    return require('../../imagens/s.png')
                    break;
                case "t":
                    return require('../../imagens/t.png')
                    break;
                case "u":
                    return require('../../imagens/u.png')
                    break;
                case "v":
                    return require('../../imagens/v.png')
                    break;
                case "x":
                    return require('../../imagens/x.png')
                    break;
                case "z":
                    return require('../../imagens/z.png')
                    break;
                case "w":
                    return require('../../imagens/w.png');
                    break;
                case "y":
                    return require('../../imagens/y.png');
                    break;
                default:
                    return require('../../imagens/userPadrao.png');
                    //
                    break;

            }
        } else {
            //
            let urlIMG = require('../../imagens/userPadrao.png')
            return urlIMG;
        }

    }
    verificaSeClienteJaNaoEstaEmAtendimento(dadosCliente, agrupaAtendenteSetor) {
        let chaveUnica2 = localStorage.getItem("chaveUnica");
        const chaveUnica = chaveUnica2.replace(/"/g, "");
        let dadosReturn = {};
        return firebase.database().ref(`${chaveUnica}/clientes/`).orderByChild('telefoneCliente').equalTo(dadosCliente.phone).once('value').then((snapshot) => {
            let keyCliente = '';

            if (snapshot.val()) {
                snapshot.forEach(childItem => {
                    keyCliente = childItem.key;
                })
                return firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild('cliente').equalTo(keyCliente).limitToLast(1).once('value').then((snapshot) => {
                    if (snapshot.val()) {
                        let statusChamado = '';
                        let arrayChamado = [];
                        let chamadoAbertoPorWpp = false;
                        let atendente, setor, promisseAtendente, promisseSetor, channelChamado, numeroChamado, keyChamado;
                        snapshot.forEach(childItem => {
                            statusChamado = childItem.val().status;
                            atendente = childItem.val().atendente;
                            setor = childItem.val().setor;
                            channelChamado = childItem.val().channelChamado;
                            numeroChamado = childItem.val().numeroChamado;
                            chamadoAbertoPorWpp = childItem.val().chamadoAbertoPorWpp === undefined ? false : childItem.val().chamadoAbertoPorWpp;
                            keyChamado = childItem.key;
                            promisseAtendente = firebase.database().ref(`${chaveUnica}/usuarios/${atendente}/nome`).once("value");
                            promisseSetor = firebase.database().ref(`${chaveUnica}/setores/${setor}/nome`).once("value");
                            arrayChamado.push(promisseAtendente, promisseSetor);
                        })
                        if (statusChamado === 'Aberto' && channelChamado === 'clienteChatWeb') {
                            return Promise.all(arrayChamado).then(responses => {
                                dadosReturn['statusChamado'] = statusChamado;
                                dadosReturn['chamadoAbertoPorWpp'] = chamadoAbertoPorWpp;
                                dadosReturn['keyCliente'] = keyCliente;
                                dadosReturn['keySetor'] = setor;
                                dadosReturn['chaveUnica'] = chaveUnica;
                                dadosReturn['numeroChamado'] = numeroChamado;
                                dadosReturn['keyChamado'] = keyChamado;
                                dadosReturn['keyAtendente'] = atendente;
                                dadosReturn['atendente'] = responses[0].val() == null ? "Não informado" : responses[0].val();
                                dadosReturn['setor'] = responses[1].val() == null ? "Não informado" : responses[1].val();
                                return dadosReturn;
                            })
                        } else if (statusChamado === 'Atendendo' && channelChamado === 'clienteChatWeb') {

                            return Promise.all(arrayChamado).then(responses => {
                                dadosReturn['statusChamado'] = statusChamado;
                                dadosReturn['keyCliente'] = keyCliente;
                                dadosReturn['keySetor'] = setor;
                                dadosReturn['chaveUnica'] = chaveUnica;
                                dadosReturn['keyChamado'] = keyChamado;
                                dadosReturn['keyAtendente'] = atendente;
                                dadosReturn['numeroChamado'] = numeroChamado;
                                dadosReturn['atendente'] = responses[0].val() == null ? "Não informado" : responses[0].val();
                                dadosReturn['setor'] = responses[1].val() == null ? "Não informado" : responses[1].val();
                                dadosReturn['chamadoAbertoPorWpp'] = chamadoAbertoPorWpp;
                                return dadosReturn;
                            })


                        } else {
                            return Promise.all(arrayChamado).then(responses => {

                                dadosReturn['statusChamado'] = statusChamado;
                                dadosReturn['keyCliente'] = keyCliente;
                                dadosReturn['keyChamado'] = keyChamado;
                                dadosReturn['atendente'] = responses[0].val() == null ? "Não informado" : responses[0].val();
                                dadosReturn['setor'] = responses[1].val() == null ? "Não informado" : responses[1].val();
                                return dadosReturn;
                            })

                        }
                    } else {
                        dadosReturn['statusChamado'] = '';
                        dadosReturn['keyCliente'] = keyCliente;
                        return dadosReturn;
                    }
                })
            } else {
                let telefoneCliente = dadosCliente.phone;
                let nomeEmpresa = dadosCliente.nome;
                let nomeCliente = dadosCliente.nome;
                let empresaPesquisa = dadosCliente.nome;
                let urlImagem = dadosCliente.urlImagem === null ? '' : dadosCliente.urlImagem;
                if (urlImagem === '') {
                    return firebase.database().ref(`${chaveUnica}/clientes`).push({
                        nome: nomeCliente,
                        empresaPesquisa: empresaPesquisa,
                        email: "",
                        empresa: nomeEmpresa,
                        /* empresa:vinculadoEmpresa == true? nomeEmpresaSelecionadoConsultaClienteTelaCAdastraCliente: nomeEmpresa, */
                        senha: "",
                        permissaoAPP: false,
                        alterado: 0,
                        notifNovoAtendimento: 1,
                        notifRespNosAtendimentos: 1,
                        vinculadoEmpresa: false,
                        keyEmpresaVinculada: "Não Vinculado",
                        enderecoCliente: '',
                        telefoneCliente,
                        urlImagemNuvem: urlImagem
                    }).then(dadosClientes => {
                        dadosReturn['statusChamado'] = '';
                        dadosReturn['keyCliente'] = dadosClientes.key;
                        return dadosReturn;
                    })
                } else {
                    return firebase.database().ref(`${chaveUnica}/clientes`).push({
                        nome: nomeCliente,
                        empresaPesquisa: empresaPesquisa,
                        email: "",
                        empresa: nomeEmpresa,
                        /* empresa:vinculadoEmpresa == true? nomeEmpresaSelecionadoConsultaClienteTelaCAdastraCliente: nomeEmpresa, */
                        senha: "",
                        permissaoAPP: false,
                        alterado: 0,
                        notifNovoAtendimento: 1,
                        notifRespNosAtendimentos: 1,
                        vinculadoEmpresa: false,
                        keyEmpresaVinculada: "Não Vinculado",
                        enderecoCliente: '',
                        telefoneCliente,
                        urlImagemNuvem: '',
                        cadastradoViaWpp: true
                    }).then(dadosClientes => {
                        keyCliente = dadosClientes.key;
                        dadosReturn['statusChamado'] = '';
                        dadosReturn['keyCliente'] = keyCliente;
                        return this.getFileBlobPromise(urlImagem).then(
                            (blob) => {
                                let file = new File([blob], 'imagem.jpg', { type: blob.type });
                                let caminhoPadraoImagem = firebase.storage().ref(`${chaveUnica}/${dadosClientes.key}/imagem.jpg`);
                                let mime = "image/jpeg";
                                return caminhoPadraoImagem.put(file)
                                    .then((url) => {
                                        url.ref.getDownloadURL().then(function (downloadURL) {
                                            firebase.database().ref(`${chaveUnica}/clientes/${dadosClientes.key}`).update({ alterado: 1, urlImagemNuvem: downloadURL });
                                            return dadosReturn;
                                        })
                                    })
                                    .catch(error => {
                                        //error.code
                                        //error.message
                                        switch (error.code) {
                                            case "auth/invalid-email":
                                                alert('Error',
                                                    "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br"
                                                );
                                                return dadosReturn;
                                                break;
                                            case "auth/email-already-in-use":
                                                alert('Error',
                                                    "Este email já está sendo utilizado, informe outro email."
                                                );
                                                return dadosReturn;
                                                break;
                                            case "auth/network-request-failed":
                                                alert('Error',
                                                    "Sem conexão com a internet"
                                                );
                                                return dadosReturn;
                                                break;
                                            default:
                                                alert('Error',
                                                    "Erro ao cadastrar, tente novamente mais tarde"
                                                );
                                                return dadosReturn;
                                                break;
                                        }
                                    });
                            })
                    })
                }

            }

        }).catch(err => { console.error(err) })
    }
    renderRefreshMaisAtendimentos() {
        if (this.props.referenciaUltimakeyUltimoChamadoContatos !== undefined && this.props.configWhatsapp !== undefined && this.props.configWhatsapp.length > 0/*  && this.props.atendimentosPorKeyReducerChat.chamadoAbertoPorWpp === true */) {
            if (this.props.refreshControlCarregaMaisChamados === true) {
                return (
                    <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center', /* position: 'absolute', top: 15, center: 0 */ }}>
                        <div style={{ display: 'flex', height: 40, width: 40, borderRadius: '50%', marginTop: 5, backgroundColor: this.props.backgroundSegundo, alignItems: 'center', justifyContent: 'center' }}>
                            <ClipLoader
                                css={{}}
                                size={25}
                                color={this.props.corBotaoPadrao}
                                loading={true}
                            />
                        </div>
                    </div>
                )
            } else {
                return (
                    <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center', /* position: 'absolute', top: 15, center: 0 */ background: 'transparent' }}>
                        <div onClick={() => { this.props.listaConversasAntigasWppContacts(1, this.props.dadosContactsSelecionado[0].phone, this.props.referenciaUltimakeyUltimoChamadoContatos, this.props.conversasUltimoAtendimento, this.props.verificaAgrupaAtendenteSetor, this.props.isAdmin, this.props.usuarioAdmVisualizaApenasChamadosDoSetorVinculado, this.props.keyUsuarioLogado, this.props.atendenteVeApenasProprioHistoricoChamado) }} style={{ display: 'flex', height: 40, width: 40, marginTop: 5, cursor: 'pointer', borderRadius: '50%', backgroundColor: this.props.backgroundSegundo, alignItems: 'center', justifyContent: 'center' }}>
                            <i className="icon-refresh-arrow" style={{ fontSize: 22, color: this.props.coresLetraPadrao }} />
                        </div>
                    </div>
                )
            }

        } else {
            return null;
        }
    }


    renderConversasUltimoAtendimento() {
        if (this.props.loadingCarregaConversasUltimoAtendimento === true) {
            return (
                <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center',/*  position: 'absolute', top: 15, center: 0 */ }}>
                    <div style={{ display: 'flex', height: 40, width: 40, borderRadius: '50%', backgroundColor: this.props.backgroundPrimeiro, alignItems: 'center', justifyContent: 'center' }}>
                        <ClipLoader
                            css={{}}
                            size={25}
                            color={this.props.corBotaoPadrao}
                            loading={true}
                        />
                    </div>
                </div>
            )
        } else {
            if (this.props.conversasUltimoAtendimento.length > 0) {
                return (
                    <>
                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column-reverse' }}>

                            <FlatList
                                list={this.props.conversasUltimoAtendimento}
                                renderItem={(item) => this.messageBoxEmEspera(item)}
                                renderWhenEmpty={() => null}
                            />
                        </div>
                        {this.renderRefreshMaisAtendimentos()}
                    </>
                )
            } else {
                return (
                    <div style={{ display: 'flex', flex: 1, height: '100%', width: '100%', alignItems: 'flex-start', justifyContent: 'center',/*  position: 'absolute', top: 15, center: 0 */ }}>
                        <SystemMessage
                            text={`Hoje`} />
                    </div>
                )
            }
        }
    }
    renderConteudoDetalhesAtendimentoOnline() {
        if (this.props.keyAtendimentoEmAtendimentoOnline !== '' && this.props.atendimentosPorKeyReducerChat !== undefined) {
            return (
                <ContainerMaisInformacoes backGroundDinamico={this.props.backgroundSegundo}>
                    {this.props.atendimentosPorKeyReducerChat !== undefined && <SubContainerMaisInformacoes backGroundDinamico={this.props.backgroundMaisEscuro}>
                        <DivSeparadoraMaisInformacoes style={{ borderTopWidth: 1, borderTopColor: this.props.coresLetraPadrao }} corBordaDinamica={this.props.coresLetraPadrao}>
                            <DivLAbelCliente>
                                <LabelClienteCabecalho corLetraDinamica={this.props.coresLetraPadrao}>Nome Cliente:</LabelClienteCabecalho>
                            </DivLAbelCliente>
                            <DivCliente>
                                <LabelCliente corLetraDinamica={this.props.coresLetraPadrao}>{this.props.atendimentosPorKeyReducerChat.cliente}</LabelCliente>
                            </DivCliente>
                        </DivSeparadoraMaisInformacoes>
                        <DivSeparadoraMaisInformacoes corBordaDinamica={this.props.coresLetraPadrao}>
                            <DivLAbelCliente>
                                <LabelClienteCabecalho corLetraDinamica={this.props.coresLetraPadrao}>Empresa:</LabelClienteCabecalho>
                            </DivLAbelCliente>
                            <DivCliente>
                                <LabelCliente corLetraDinamica={this.props.coresLetraPadrao}>{this.props.atendimentosPorKeyReducerChat.empresa}</LabelCliente>
                            </DivCliente>
                        </DivSeparadoraMaisInformacoes>
                        <DivSeparadoraMaisInformacoes corBordaDinamica={this.props.coresLetraPadrao}>
                            <DivLAbelCliente>
                                <LabelClienteCabecalho corLetraDinamica={this.props.coresLetraPadrao}>Telefone:</LabelClienteCabecalho>
                            </DivLAbelCliente>
                            <DivCliente>
                                <LabelCliente corLetraDinamica={this.props.coresLetraPadrao}>{this.props.atendimentosPorKeyReducerChat.telefoneCliente}</LabelCliente>
                            </DivCliente>
                        </DivSeparadoraMaisInformacoes>
                        <DivSeparadoraMaisInformacoes corBordaDinamica={this.props.coresLetraPadrao}>
                            <DivLAbelCliente>
                                <LabelClienteCabecalho corLetraDinamica={this.props.coresLetraPadrao}>Atendente:</LabelClienteCabecalho>
                            </DivLAbelCliente>
                            <DivCliente>
                                <LabelCliente corLetraDinamica={this.props.coresLetraPadrao}>{this.props.atendimentosPorKeyReducerChat.atendente}</LabelCliente>
                            </DivCliente>
                        </DivSeparadoraMaisInformacoes>
                        <DivSeparadoraMaisInformacoes corBordaDinamica={this.props.coresLetraPadrao}>
                            <DivLAbelCliente>
                                <LabelClienteCabecalho corLetraDinamica={this.props.coresLetraPadrao}>Setor:</LabelClienteCabecalho>
                            </DivLAbelCliente>
                            <DivCliente>
                                <LabelCliente corLetraDinamica={this.props.coresLetraPadrao}>{this.props.atendimentosPorKeyReducerChat.setor}</LabelCliente>
                            </DivCliente>
                        </DivSeparadoraMaisInformacoes>
                        <DivSeparadoraMaisInformacoes corBordaDinamica={this.props.coresLetraPadrao}>
                            <DivLAbelCliente>
                                <LabelClienteCabecalho corLetraDinamica={this.props.coresLetraPadrao}>Vencimento:</LabelClienteCabecalho>
                            </DivLAbelCliente>
                            <DivCliente>
                                <LabelCliente corLetraDinamica={this.props.coresLetraPadrao}>{this.props.atendimentosPorKeyReducerChat.dataVencimento}</LabelCliente>
                            </DivCliente>
                        </DivSeparadoraMaisInformacoes>
                        <DivSeparadoraMaisInformacoes corBordaDinamica={this.props.coresLetraPadrao}>
                            <DivLAbelCliente>
                                <LabelClienteCabecalho corLetraDinamica={this.props.coresLetraPadrao}>Prioridade:</LabelClienteCabecalho>
                            </DivLAbelCliente>
                            <DivCliente>
                                <LabelCliente corLetraDinamica={this.props.coresLetraPadrao}>{this.props.atendimentosPorKeyReducerChat.prioridade}</LabelCliente>
                            </DivCliente>
                        </DivSeparadoraMaisInformacoes>
                        <DivSeparadoraMaisInformacoes corBordaDinamica={this.props.coresLetraPadrao}>
                            <DivLAbelCliente>
                                <LabelClienteCabecalho corLetraDinamica={this.props.coresLetraPadrao}>Local:</LabelClienteCabecalho>
                            </DivLAbelCliente>
                            <DivCliente>
                                <LabelCliente corLetraDinamica={this.props.coresLetraPadrao}>{this.props.atendimentosPorKeyReducerChat.local}</LabelCliente>
                            </DivCliente>
                        </DivSeparadoraMaisInformacoes>
                        <DivSeparadoraMaisInformacoes corBordaDinamica={this.props.backgroundMaisEscuro}>
                            <DivLAbelCliente>
                                <LabelClienteCabecalho corLetraDinamica={this.props.coresLetraPadrao}>Status:</LabelClienteCabecalho>
                            </DivLAbelCliente>
                            <DivCliente>
                                <LabelCliente corLetraDinamica={this.props.coresLetraPadrao}>{this.props.atendimentosPorKeyReducerChat.status}</LabelCliente>
                            </DivCliente>
                        </DivSeparadoraMaisInformacoes>
                    </SubContainerMaisInformacoes>}
                </ContainerMaisInformacoes>
            )
        } else if (this.props.keyAtendimentoEmEsperaAtendimentoOnline !== '' && this.props.atendimentosPorKeyReducerChatEmEspera !== undefined) {
            return (
                <ContainerMaisInformacoes backGroundDinamico={this.props.backgroundSegundo}>
                    {this.props.atendimentosPorKeyReducerChatEmEspera !== undefined && <SubContainerMaisInformacoes backGroundDinamico={this.props.backgroundMaisEscuro}>
                        <DivSeparadoraMaisInformacoes style={{ borderTopWidth: 1, borderTopColor: this.props.coresLetraPadrao }} corBordaDinamica={this.props.coresLetraPadrao}>
                            <DivLAbelCliente>
                                <LabelClienteCabecalho corLetraDinamica={this.props.coresLetraPadrao}>Nome Cliente:</LabelClienteCabecalho>
                            </DivLAbelCliente>
                            <DivCliente>
                                <LabelCliente corLetraDinamica={this.props.coresLetraPadrao}>{this.props.atendimentosPorKeyReducerChatEmEspera.cliente}</LabelCliente>
                            </DivCliente>
                        </DivSeparadoraMaisInformacoes>
                        <DivSeparadoraMaisInformacoes corBordaDinamica={this.props.coresLetraPadrao}>
                            <DivLAbelCliente>
                                <LabelClienteCabecalho corLetraDinamica={this.props.coresLetraPadrao}>Empresa:</LabelClienteCabecalho>
                            </DivLAbelCliente>
                            <DivCliente>
                                <LabelCliente corLetraDinamica={this.props.coresLetraPadrao}>{this.props.atendimentosPorKeyReducerChatEmEspera.empresa}</LabelCliente>
                            </DivCliente>
                        </DivSeparadoraMaisInformacoes>
                        <DivSeparadoraMaisInformacoes corBordaDinamica={this.props.coresLetraPadrao}>
                            <DivLAbelCliente>
                                <LabelClienteCabecalho corLetraDinamica={this.props.coresLetraPadrao}>Telefone:</LabelClienteCabecalho>
                            </DivLAbelCliente>
                            <DivCliente>
                                <LabelCliente corLetraDinamica={this.props.coresLetraPadrao}>{this.props.atendimentosPorKeyReducerChatEmEspera.telefoneCliente}</LabelCliente>
                            </DivCliente>
                        </DivSeparadoraMaisInformacoes>
                        <DivSeparadoraMaisInformacoes corBordaDinamica={this.props.coresLetraPadrao}>
                            <DivLAbelCliente>
                                <LabelClienteCabecalho corLetraDinamica={this.props.coresLetraPadrao}>Atendente:</LabelClienteCabecalho>
                            </DivLAbelCliente>
                            <DivCliente>
                                <LabelCliente corLetraDinamica={this.props.coresLetraPadrao}>{this.props.atendimentosPorKeyReducerChatEmEspera.atendente}</LabelCliente>
                            </DivCliente>
                        </DivSeparadoraMaisInformacoes>
                        <DivSeparadoraMaisInformacoes corBordaDinamica={this.props.coresLetraPadrao}>
                            <DivLAbelCliente>
                                <LabelClienteCabecalho corLetraDinamica={this.props.coresLetraPadrao}>Setor:</LabelClienteCabecalho>
                            </DivLAbelCliente>
                            <DivCliente>
                                <LabelCliente corLetraDinamica={this.props.coresLetraPadrao}>{this.props.atendimentosPorKeyReducerChatEmEspera.setor}</LabelCliente>
                            </DivCliente>
                        </DivSeparadoraMaisInformacoes>
                        <DivSeparadoraMaisInformacoes corBordaDinamica={this.props.coresLetraPadrao}>
                            <DivLAbelCliente>
                                <LabelClienteCabecalho corLetraDinamica={this.props.coresLetraPadrao}>Vencimento:</LabelClienteCabecalho>
                            </DivLAbelCliente>
                            <DivCliente>
                                <LabelCliente corLetraDinamica={this.props.coresLetraPadrao}>{this.props.atendimentosPorKeyReducerChatEmEspera.dataVencimento}</LabelCliente>
                            </DivCliente>
                        </DivSeparadoraMaisInformacoes>
                        <DivSeparadoraMaisInformacoes corBordaDinamica={this.props.coresLetraPadrao}>
                            <DivLAbelCliente>
                                <LabelClienteCabecalho corLetraDinamica={this.props.coresLetraPadrao}>Prioridade:</LabelClienteCabecalho>
                            </DivLAbelCliente>
                            <DivCliente>
                                <LabelCliente corLetraDinamica={this.props.coresLetraPadrao}>{this.props.atendimentosPorKeyReducerChatEmEspera.prioridade}</LabelCliente>
                            </DivCliente>
                        </DivSeparadoraMaisInformacoes>
                        <DivSeparadoraMaisInformacoes corBordaDinamica={this.props.coresLetraPadrao}>
                            <DivLAbelCliente>
                                <LabelClienteCabecalho corLetraDinamica={this.props.coresLetraPadrao}>Local:</LabelClienteCabecalho>
                            </DivLAbelCliente>
                            <DivCliente>
                                <LabelCliente corLetraDinamica={this.props.coresLetraPadrao}>{this.props.atendimentosPorKeyReducerChatEmEspera.local}</LabelCliente>
                            </DivCliente>
                        </DivSeparadoraMaisInformacoes>
                        <DivSeparadoraMaisInformacoes corBordaDinamica={this.props.backgroundMaisEscuro}>
                            <DivLAbelCliente>
                                <LabelClienteCabecalho corLetraDinamica={this.props.coresLetraPadrao}>Status:</LabelClienteCabecalho>
                            </DivLAbelCliente>
                            <DivCliente>
                                <LabelCliente corLetraDinamica={this.props.coresLetraPadrao}>{this.props.atendimentosPorKeyReducerChatEmEspera.status}</LabelCliente>
                            </DivCliente>
                        </DivSeparadoraMaisInformacoes>
                    </SubContainerMaisInformacoes>}
                </ContainerMaisInformacoes>
            )
        } else {
            return null
        }
    }
    containsKey(array, key) {
        let i;
        for (i = 0; i < array.length; i++) {
            if (array[i].key === key) {
                return true;
            }
        }

        return false;
    }
    renderEtiquetaPrioridade(prioridade) {
        if (prioridade == "Baixa") {
            return (
                <DivSelectCabecalho style={{ backgroundColor: '#FCFCFC' }}>
                    <TextPrioridadeCabecalho corLetraDinamica={this.props.backgroundSegundo}>BAIXA</TextPrioridadeCabecalho>

                </DivSelectCabecalho>
            )
        } else if (prioridade == "Urgente") {
            return (
                <DivSelectCabecalho style={{ backgroundColor: '#f70e4c' }}>
                    <TextPrioridadeCabecalho corLetraDinamica={this.props.backgroundSegundo}>URGENTE</TextPrioridadeCabecalho>

                </DivSelectCabecalho>
            )
        } else if (prioridade == "Alta") {
            return (
                <DivSelectCabecalho style={{ backgroundColor: '#FFA500' }}>
                    <TextPrioridadeCabecalho corLetraDinamica={this.props.backgroundSegundo}>ALTA</TextPrioridadeCabecalho>

                </DivSelectCabecalho>
            )
        }
        else if (prioridade == "Normal") {
            return (
                <DivSelectCabecalho style={{ backgroundColor: '#B0C4DE' }}>
                    <TextPrioridadeCabecalho corLetraDinamica={this.props.backgroundSegundo}>NORMAL</TextPrioridadeCabecalho>

                </DivSelectCabecalho>
            )
        } else {
            return null;
        }

    }
    verifyStatusTicketBeforeCall(chaveUnica, keyAtendimento) {
        return new Promise(async (resolve, reject) => {
            const ticketDataClicked = await firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}`).once('value');
            const dataTicket = ticketDataClicked.val();
            if (dataTicket.status === 'Atendendo' || dataTicket.atendente && dataTicket.atendente !== 'Não informado') {
                resolve('Atendendo')
            } else {
                resolve('Aberto')
            }
        }).then((xhr) => {
            return xhr;
        })
    }
    renderTExtCabecalho() {
        let verificaKeyReducerChat = this.props.atendimentosPorKeyReducerChat == undefined ? false : this.props.atendimentosPorKeyReducerChat;
        let verificaKeyReducerChatEmEspera = this.props.atendimentosPorKeyReducerChatEmEspera == undefined ? false : this.props.atendimentosPorKeyReducerChatEmEspera;
        //  let chamadoEmEsperaSelecionadoIsTrue =  this.props.atendimentosPorKeyReducerChatEmEspera == undefined ? false: this.containsKey(this.props.chamadosEmEsperaAtendimentoOnline, this.props.atendimentosPorKeyReducerChatEmEspera.key)
        //

        /* if (verificaKeyReducerChat == false || this.props.keyAtendimentoEmAtendimentoOnline === '' || verificaKeyReducerChat == [] ||
verificaKeyReducerChatEmEspera == false || this.props.keyAtendimentoEmEsperaAtendimentoOnline === '' || verificaKeyReducerChatEmEspera == []) {
return null
    } else */
        /*

          */

        if (this.props.keyAtendimentoEmEsperaAtendimentoOnline !== '' && this.props.atendimentosPorKeyReducerChatEmEspera !== undefined && this.props.chamadosEmEsperaAtendimentoOnline.length > 0) {
            //mostro dados do atendimento em espera

            let urlImagem = aguardandoAtendimento;
            if (this.props.atendimentosPorKeyReducerChatEmEspera.urlImagemNuvem === null || this.props.atendimentosPorKeyReducerChatEmEspera.urlImagemNuvem === undefined || this.props.atendimentosPorKeyReducerChatEmEspera.urlImagemNuvem === '') {
                const primeiraLetra = this.props.atendimentosPorKeyReducerChatEmEspera.cliente.substring(0, 1).trim().toLowerCase();
                urlImagem = this.verificaSeEhAlphabeto(primeiraLetra);
            } else {
                urlImagem = this.props.atendimentosPorKeyReducerChatEmEspera.urlImagemNuvem
            }
            return (

                <div style={{ borderLeft: `0.1px solid ${this.props.coresLetraSecundario}`, display: 'flex', flex: 1, padding: 10 }}>

                    <ContainerImagem>
                        <img src={urlImagem} alt="foto" style={{ height: 50, width: 50, borderRadius: 50, backgroundColor: 'rgba(159,173,183, 0.3)' }} />
                    </ContainerImagem>
                    <DivTextAtendimentoEmEspera>
                        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                            <TextCabecalhoAtendimento onClick={() => { if (this.props.atendimentosPorKeyReducerChatEmEspera.chamadoAbertoPorWpp === true) { this.toggleModalAlterarNome(); this.props.modificaNomeClienteTelaChatOnline(this.props.atendimentosPorKeyReducerChatEmEspera.cliente) } else { } }} alterarNome={this.props.atendimentosPorKeyReducerChatEmEspera.chamadoAbertoPorWpp} corLetraDinamica={this.props.coresLetraPadrao}>#{this.props.atendimentosPorKeyReducerChatEmEspera.numeroChamado} <span style={{ paddingLeft: 8, paddingRight: 8 }}> - </span>{this.props.atendimentosPorKeyReducerChatEmEspera.cliente}</TextCabecalhoAtendimento>
                            <TextSubCabecalhoAtendimento corLetraDinamica={this.props.coresLetraSecundario} style={{}}>{this.props.atendimentosPorKeyReducerChatEmEspera.telefoneCliente}</TextSubCabecalhoAtendimento>
                            {this.renderEtiquetaPrioridade(this.props.atendimentosPorKeyReducerChatEmEspera.prioridade)}
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {this.props.loadingIniciandoConversaViaContacts === false && <ButtonIniciarAtendimento backgrounddinamico={this.props.corBotaoPadrao} corLetraDinamica={this.props.letraBotaoPadrao} onClick={() => {
                                setTimeout(async () => {

                                    this.props.ativaDesativaLoadingIniciandoCOnversaContactsExport(true);
                                    firebase.database().ref(`${this.props.atendimentoEmEsperaSelecionado.chaveUnica}/acoesChamados/${this.props.atendimentoEmEsperaSelecionado.key}`).off("value");
                                    let arraySemChamadoAtendido = this.props.chamadosEmEsperaAtendimentoOnline.filter(el => el.key !== this.props.atendimentoEmEsperaSelecionado.key);
                                    this.props.enviaAtendimentoEmEsperaAtendimentoOnlineExport(arraySemChamadoAtendido, this.props.atendimentoEmEsperaSelecionado.key)
                                    const status = await this.verifyStatusTicketBeforeCall(this.props.atendimentoEmEsperaSelecionado.chaveUnica, this.props.atendimentoEmEsperaSelecionado.key)
                                    if (status === 'Atendendo') {
                                        toastr.warning('AVISO, esta conversa já se encontra em um atendimento.');
                                        this.props.enviaUltimaReeferenciaKeyEmEsperaExport('');
                                        this.props.enviaConversasCarregadasEmEsperaExport([]);
                                        this.props.enviaUltimaReeferenciaKeyEmAtendimentoExport('');
                                        this.props.enviaConversasCarregadasEmAtendimentoExport([]);
                                        this.setState({ pesquisaConversasOuContatosEncaminharMensagem: '', pesquisaConversa: '' });
                                        this.updateSearchEncaminhar('')
                                        this.setState({ defaultTabIndex: 0 })
                                    } else {
                                        this.props.atribuiAtendimentoAh(this.props.keyUsuarioLogado, this.props.atendimentoEmEsperaSelecionado.key, this.props.nome, this.props.atendimentoEmEsperaSelecionado.keySetor, this.props.atendimentoEmEsperaSelecionado, true, this.props.configWhatsapp, '', this.props.desativarAssistenteVirtualBotWpp);
                                        this.props.enviaUltimaReeferenciaKeyEmEsperaExport('');
                                        this.props.enviaConversasCarregadasEmEsperaExport([]);
                                        this.props.enviaUltimaReeferenciaKeyEmAtendimentoExport('');
                                        this.props.enviaConversasCarregadasEmAtendimentoExport([]);
                                        this.setState({ pesquisaConversasOuContatosEncaminharMensagem: '', pesquisaConversa: '' });
                                        this.updateSearchEncaminhar('')
                                        this.setState({ defaultTabIndex: 0 })

                                    }

                                }, 0);
                                /*  this.props.modificaKeyAtendimentoEmEsperaAtendimentoOnline('');
                                 this.props.modificaKeyAtendimentoEmAtendimentoOnline(this.props.atendimentoEmEsperaSelecionado.key); */
                            }}>

                                {/*      <p style={{ fontSize: 14, color: this.props.letraBotaoPadrao, fontWeight: 'bold' }}> */}INICIAR CONVERSA{/* </p> */}
                            </ButtonIniciarAtendimento>}
                            {this.props.loadingIniciandoConversaViaContacts === true &&
                                <ButtonIniciarAtendimentoLoading backgrounddinamico={this.props.corBotaoPadrao} corLetraDinamica={this.props.letraBotaoPadrao} > <ClipLoader
                                    css={{}}
                                    size={28}
                                    color={this.props.letraBotaoPadrao}
                                    loading={true}
                                /></ButtonIniciarAtendimentoLoading>
                                /*  <ClipLoader
                                     css={{ marginLeft: 30 }}
                                     size={28}
                                     color={this.props.corBotaoPadrao}
                                     loading={true}
                                 /> */
                            }
                        </div>
                    </DivTextAtendimentoEmEspera>
                    {
                        this.props.atendimentosPorKeyReducerChatEmEspera !== undefined && <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: '100%' }}>

                                <IconeAcaoChat
                                    onClick={() => {
                                        if (this.props.atendimentosPorKeyReducerChatEmEspera.status === 'Fechado') {
                                            toastr.error('Error', 'Atendimento encerrado')
                                        } else {
                                            let dadosChamado = this.props.atendimentosPorKeyReducerChatEmEspera;

                                            if (dadosChamado.prioridade != "Não informado") {
                                                this.props.modificaPrioridadeSelecionado(dadosChamado.prioridade)
                                                //  this.onSelectedItemsChangePrioridade([dadosChamado.prioridade])
                                            } else {
                                                this.props.modificaPrioridadeSelecionado('')
                                            }

                                            if (dadosChamado.local != "Não informado") {
                                                this.props.modificaLocalSelecionado(dadosChamado.local)
                                                //  this.onSelectedItemsChangeLocal([dadosChamado.local])
                                            } else {
                                                this.props.modificaLocalSelecionado('')
                                            }

                                            if (dadosChamado.dataVencimento != "Não informado") {
                                                this.props.modificaDataVencimentoTelaChamadoAbertura(dadosChamado.dataVencimento)
                                            } else {
                                                this.props.modificaDataVencimentoTelaChamadoAbertura('')
                                            }
                                            //
                                            //
                                            if (this.props.criarChamadoProblemaEstaticoAtendimento == true) {
                                                this.props.modificaProblemaEstaticoSelecionadoNovoAtendimento(dadosChamado.keyProblemaEstatico);
                                                this.props.modificaNomeProblemaEstaticoSelecionadoNovoAtendimento(dadosChamado.descricaoProblema);

                                            }
                                            if (dadosChamado.keySetor != "Não informado") {
                                                this.props.modificaSetorSelecionado(dadosChamado.keySetor)
                                                // this.onSelectedItemsChangeSetor([this.props.dadosAtendimentos[0].keySetor])
                                            } else {
                                                this.props.modificaSetorSelecionado('')
                                            }

                                            this.props.alteraDescricaoChamado(dadosChamado.descricaoProblema)
                                            this.setState({ dataVencimento: '' })
                                            this._toggleModalAlterarChat();
                                        }
                                    }}
                                    colorDinamico={this.props.corSegundoBotaoPadrao} backgroundBotao={this.props.corBotaoPadrao} data-tip={this.props.atendimentosPorKeyReducerChatEmEspera.status === 'Fechado' ? 'Atendimento Encerrado' : "Alterar Informações do Atendimento"} data-for="tip-top" className="icon-editar" style={{ cursor: this.props.atendimentosPorKeyReducerChatEmEspera.status === 'Fechado' ? 'no-drop' : 'pointer', marginRight: 5 }} />

                                {this.props.permissoesAcessarTelasAtendente.permissaoCancelarAtendimento === true && <IconeAcaoChat
                                    onClick={() => {
                                        if (this.props.atendimentosPorKeyReducerChatEmEspera.status === 'Fechado') {
                                            toastr.error('Error', 'Atendimento encerrado')
                                        } else {
                                            this.modificaVisibilidadeModalCancelarAtendimento()
                                        }
                                    }}
                                    colorDinamico={this.props.corSegundoBotaoPadrao} backgroundBotao={this.props.corBotaoPadrao} data-tip={this.props.atendimentosPorKeyReducerChatEmEspera.status === 'Fechado' ? 'Atendimento Encerrado' : this.props.configWhatsapp.length > 0 ? "Cancelar Atendimento, não envia mensagem para o cliente e o atendimento é cancelado" : "Cancelar Atendimento"} data-for="tip-top" className="icon-sair" style={{ cursor: this.props.atendimentosPorKeyReducerChatEmEspera.status === 'Fechado' ? 'no-drop' : 'pointer' }} />}


                                {/*    <IconeAcaoChat
                                    onClick={() => {
                                        if (this.props.atendimentosPorKeyReducerChatEmEspera.status === 'Fechado') {
                                            toastr.error('Error', 'Atendimento encerrado')
                                        } else {
                                            this._toggleModalRespostaPadrao()
                                        }
                                    }}
                                    colorDinamico={this.props.corSegundoBotaoPadrao} backgroundBotao={this.props.corBotaoPadrao} data-tip={this.props.atendimentosPorKeyReducerChatEmEspera.status === 'Fechado' ? 'Atendimento Encerrado' : "Exibir respostas padrões"} data-for="tip-top" className="icon-list-on-window" style={{ cursor: this.props.atendimentosPorKeyReducerChatEmEspera.status === 'Fechado' ? 'no-drop' : 'pointer' }} /> */}
                                <IconeAcaoChat
                                    style={{ cursor: this.props.atendimentosPorKeyReducerChatEmEspera.status === 'Fechado' ? 'no-drop' : 'pointer' }}
                                    className="icon-transferir" data-tip="Transferir atendimento" data-for="tip-top" colorDinamico={this.props.corSegundoBotaoPadrao} backgroundBotao={this.props.corBotaoPadrao} onClick={() => {
                                        if (this.props.atendimentosPorKeyReducerChatEmEspera.status === 'Fechado') {
                                            toastr.error('Error', 'Atendimento encerrado')
                                        } else {
                                            this.setState({ modalAtribuirAtendimento: true })
                                        }
                                    }} />
                                <IconeAcaoChat data-tip="Detalhes do Chamado" data-for="tip-top" className="icon-danger1" colorDinamico={this.props.corSegundoBotaoPadrao} backgroundBotao={this.props.corBotaoPadrao} onClick={() => {
                                    this.props.modificaVisibilidadeModalAtendimentoOnline(true)
                                }} />


                            </div>

                        </div>
                    }

                </div>
            )
        }

        else if (this.props.keyAtendimentoEmAtendimentoOnline !== '' && this.props.atendimentosPorKeyReducerChat !== undefined) {

            let urlImagem = aguardandoAtendimento;
            if (this.props.atendimentosPorKeyReducerChat.urlImagemNuvem === null || this.props.atendimentosPorKeyReducerChat.urlImagemNuvem === undefined || this.props.atendimentosPorKeyReducerChat.urlImagemNuvem === '') {
                const primeiraLetra = this.props.atendimentosPorKeyReducerChat.cliente.substring(0, 1).trim().toLowerCase();
                urlImagem = this.verificaSeEhAlphabeto(primeiraLetra);
            } else {
                urlImagem = this.props.atendimentosPorKeyReducerChat.urlImagemNuvem
            }
            return (
                <div style={{ borderLeft: `0.1px solid ${this.props.coresLetraSecundario}`, display: 'flex', flex: 1, padding: 10 }}>

                    <ContainerImagem>
                        <img src={urlImagem} alt="foto" style={{ height: 50, width: 50, borderRadius: 50, backgroundColor: 'rgba(159,173,183, 0.3)' }} />
                    </ContainerImagem>
                    <DivTextAtendimento>

                        <TextCabecalhoAtendimento onClick={() => { if (this.props.atendimentosPorKeyReducerChat.chamadoAbertoPorWpp === true) { this.toggleModalAlterarNome(); this.props.modificaNomeClienteTelaChatOnline(this.props.atendimentosPorKeyReducerChat.cliente) } else { } }} alterarNome={this.props.atendimentosPorKeyReducerChat.chamadoAbertoPorWpp} corLetraDinamica={this.props.coresLetraPadrao}>#{this.props.atendimentosPorKeyReducerChat.numeroChamado}<span style={{ paddingLeft: 8, paddingRight: 8 }}>-</span>{this.props.atendimentosPorKeyReducerChat.cliente} </TextCabecalhoAtendimento>
                        <TextSubCabecalhoAtendimento corLetraDinamica={this.props.coresLetraSecundario} style={{}}>{this.props.atendimentosPorKeyReducerChat.telefoneCliente}</TextSubCabecalhoAtendimento>
                        {this.renderEtiquetaPrioridade(this.props.atendimentosPorKeyReducerChat.prioridade)}
                    </DivTextAtendimento>
                    {this.props.atendimentosPorKeyReducerChat !== undefined && <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: '100%' }}>

                            <IconeAcaoChat
                                onClick={() => {
                                    if (this.props.atendimentosPorKeyReducerChat.status === 'Fechado') {
                                        toastr.error('Error', 'Atendimento encerrado')
                                    } else {

                                        let dadosChamado = this.props.atendimentosPorKeyReducerChat;

                                        if (dadosChamado.prioridade != "Não informado") {
                                            this.props.modificaPrioridadeSelecionado(dadosChamado.prioridade)
                                            //  this.onSelectedItemsChangePrioridade([dadosChamado.prioridade])
                                        } else {
                                            this.props.modificaPrioridadeSelecionado('')
                                        }

                                        if (dadosChamado.local != "Não informado") {
                                            this.props.modificaLocalSelecionado(dadosChamado.local)
                                            //  this.onSelectedItemsChangeLocal([dadosChamado.local])
                                        } else {
                                            this.props.modificaLocalSelecionado('')
                                        }

                                        if (dadosChamado.dataVencimento != "Não informado") {
                                            this.props.modificaDataVencimentoTelaChamadoAbertura(dadosChamado.dataVencimento)
                                        } else {
                                            this.props.modificaDataVencimentoTelaChamadoAbertura('')
                                        }
                                        if (dadosChamado.keySetor != "Não informado") {
                                            this.props.modificaSetorSelecionado(dadosChamado.keySetor)
                                            // this.onSelectedItemsChangeSetor([this.props.dadosAtendimentos[0].keySetor])
                                        } else {
                                            this.props.modificaSetorSelecionado('')
                                        }
                                        //
                                        //
                                        if (this.props.criarChamadoProblemaEstaticoAtendimento == true) {
                                            this.props.modificaProblemaEstaticoSelecionadoNovoAtendimento(dadosChamado.keyProblemaEstatico);
                                            this.props.modificaNomeProblemaEstaticoSelecionadoNovoAtendimento(dadosChamado.descricaoProblema);

                                        }

                                        this.props.alteraDescricaoChamado(dadosChamado.descricaoProblema)
                                        this.setState({ dataVencimento: '' })
                                        this._toggleModalAlterarChat();
                                    }
                                }}
                                colorDinamico={this.props.corSegundoBotaoPadrao} backgroundBotao={this.props.corBotaoPadrao} data-tip={this.props.atendimentosPorKeyReducerChat.status === 'Fechado' ? 'Atendimento Encerrado' : "Alterar Informações do Atendimento"} data-for="tip-top" className="icon-editar" style={{ cursor: this.props.atendimentosPorKeyReducerChat.status === 'Fechado' ? 'no-drop' : 'pointer', marginRight: 5 }} />
                            {this.props.permissoesAcessarTelasAtendente.permissaoCancelarAtendimento === true && <IconeAcaoChat
                                onClick={() => {
                                    if (this.props.atendimentosPorKeyReducerChat.status === 'Fechado') {
                                        toastr.error('Error', 'Atendimento encerrado')
                                    } else {
                                        this.modificaVisibilidadeModalCancelarAtendimento()
                                    }
                                }}
                                colorDinamico={this.props.corSegundoBotaoPadrao} backgroundBotao={this.props.corBotaoPadrao} data-tip={this.props.atendimentosPorKeyReducerChat.status === 'Fechado' ? 'Atendimento Encerrado' : this.props.configWhatsapp.length > 0 ? "Cancelar Atendimento, não envia mensagem para o cliente e o atendimento é cancelado" : "Cancelar Atendimento"} data-for="tip-top" className="icon-sair" style={{ cursor: this.props.atendimentosPorKeyReducerChat.status === 'Fechado' ? 'no-drop' : 'pointer' }} />}

                            {/*   <IconeAcaoChat
                                onClick={() => {
                                    if (this.props.atendimentosPorKeyReducerChat.status === 'Fechado') {
                                        toastr.error('Error', 'Atendimento encerrado')
                                    } else {
                                        this._toggleModalRespostaPadrao()
                                    }
                                }}
                                colorDinamico={this.props.corSegundoBotaoPadrao} backgroundBotao={this.props.corBotaoPadrao} data-tip={this.props.atendimentosPorKeyReducerChat.status === 'Fechado' ? 'Atendimento Encerrado' : "Exibir respostas padrões"} data-for="tip-top" className="icon-list-on-window" style={{ cursor: this.props.atendimentosPorKeyReducerChat.status === 'Fechado' ? 'no-drop' : 'pointer' }} /> */}
                            <IconeAcaoChat
                                style={{ cursor: this.props.atendimentosPorKeyReducerChat.status === 'Fechado' ? 'no-drop' : 'pointer' }}
                                className="icon-transferir" data-tip="Transferir atendimento" data-for="tip-top" colorDinamico={this.props.corSegundoBotaoPadrao} backgroundBotao={this.props.corBotaoPadrao} onClick={() => {
                                    if (this.props.atendimentosPorKeyReducerChat.status === 'Fechado') {
                                        toastr.error('Error', 'Atendimento encerrado')
                                    } else {
                                        this.setState({ modalAtribuirAtendimento: true })
                                    }
                                }} />
                            <IconeAcaoChat

                                data-tip="Detalhes do Chamado" data-for="tip-top" className="icon-danger1" colorDinamico={this.props.corSegundoBotaoPadrao} backgroundBotao={this.props.corBotaoPadrao} onClick={() => {
                                    this.props.modificaVisibilidadeModalAtendimentoOnline(true)
                                }} />
                            <IconeAcaoChat
                                style={{ marginRight: 25 }}
                                data-tip="Encerrar Atendimento" data-for="tip-top" className="icon-logoutatendimento" colorDinamico={this.props.corSegundoBotaoPadrao} backgroundBotao={this.props.corBotaoPadrao} onClick={() => {
                                    this.props.modificaResolucaoProblema('');
                                    this.setState({ modalEncerrarChat: true })
                                }} />

                        </div>

                    </div>}
                    {/*   <DivBotaoEncerrarAtendimento>
                        <ButtonEncerrarChamado onClick={() => { this.props.modificaVisibilidadeModalAtendimentoOnline(true) }} backgrounddinamico={this.props.corSegundoBotaoPadrao} corLetraDinamica={this.props.letraBotaoPadrao}>Detalhes</ButtonEncerrarChamado>
                        <ButtonEncerrarChamado onClick={() => {
                            this.props.modificaResolucaoProblema('');
                            this.setState({ modalEncerrarChat: true })
                        }} backgrounddinamico='#d9534f' corLetraDinamica='#FFF'>Encerrar</ButtonEncerrarChamado>
                    </DivBotaoEncerrarAtendimento> */}
                </div>
            )
        }
        else if (this.props.keyContacts !== '' && this.props.dadosContactsSelecionado.length > 0) {
            /*       */
            //mostro dados do atendimento em espera
            let urlImagem = aguardandoAtendimento;
            if (this.props.dadosContactsSelecionado[0].urlImagem === null || this.props.dadosContactsSelecionado[0].urlImagem === undefined || this.props.dadosContactsSelecionado[0].urlImagem === '') {
                const primeiraLetra = this.props.dadosContactsSelecionado[0].nome.substring(0, 1).trim().toLowerCase();
                urlImagem = this.verificaSeEhAlphabeto(primeiraLetra);
            } else {
                urlImagem = this.props.dadosContactsSelecionado[0].urlImagem
            }
            return (

                <div style={{ borderLeft: `0.1px solid ${this.props.coresLetraSecundario}`, display: 'flex', flex: 1, padding: 10 }}>

                    <ContainerImagem>
                        <img src={urlImagem} alt="foto" style={{ height: 50, width: 50, borderRadius: 50, backgroundColor: 'rgba(159,173,183, 0.3)' }} />
                    </ContainerImagem>
                    <DivTextAtendimentoEmEspera>
                        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                            <TextCabecalhoAtendimento corLetraDinamica={this.props.coresLetraPadrao}>{this.props.dadosContactsSelecionado[0].nome}</TextCabecalhoAtendimento>
                            <TextSubCabecalhoAtendimento corLetraDinamica={this.props.coresLetraSecundario} style={{}}>{this.props.dadosContactsSelecionado[0].phone}</TextSubCabecalhoAtendimento>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {this.props.loadingIniciandoConversaViaContacts === false && <ButtonIniciarAtendimento backgrounddinamico={this.props.corBotaoPadrao} corLetraDinamica={this.props.letraBotaoPadrao} onClick={() => {
                                this.props.ativaDesativaLoadingIniciandoCOnversaContactsExport(true)
                                this.verificaSeClienteJaNaoEstaEmAtendimento(this.props.dadosContactsSelecionado[0], this.props.verificaAgrupaAtendenteSetor).then(
                                    (value) => {
                                        this.props.enviaUltimaReeferenciaKeyEmEsperaExport('');
                                        this.props.enviaConversasCarregadasEmEsperaExport([]);
                                        this.props.enviaUltimaReeferenciaKeyEmAtendimentoExport('');
                                        this.props.enviaConversasCarregadasEmAtendimentoExport([]);
                                        if (value === false) {
                                            this.props.ativaDesativaLoadingIniciandoCOnversaContactsExport(false)
                                        } else if (value === undefined) {
                                            this.props.ativaDesativaLoadingIniciandoCOnversaContactsExport(false)
                                        }
                                        else if (value.statusChamado === 'Aberto') {
                                            this.setState({ modalAtenderAtendimentoEmProcesso: true })
                                            let arrayInfo = [];
                                            arrayInfo.push(value)
                                            this.setState({ dadosModalAtendimentoEmProcesso: arrayInfo })
                                            /*  this.props.ativaDesativaLoadingIniciandoCOnversaContactsExport(false) */

                                        } else if (value.statusChamado === 'Atendendo') {
                                            toastr.warning('Error', `Este cliente já está em atendimento com o atendente "${value.atendente}", aguarde o término do atendimento para uma nova conversa`)
                                            this.props.ativaDesativaLoadingIniciandoCOnversaContactsExport(false)
                                            /*   let arrayInfo = [];
                                            arrayInfo.push(value)
                                            this.setState({ dadosModalAtendimentoEmProcesso: arrayInfo })
                                            this.setState({ modalAtenderAtendimentoEmProcesso: true }) */
                                            /* this.props.ativaDesativaLoadingIniciandoCOnversaContactsExport(false) */
                                        } else {

                                            this.props.iniciaAtendimentoPorContacts(value.keyCliente, this.props.keyUsuarioLogado, this.props.nome, this.props.dadosContactsSelecionado[0].phone, false, '', '', '', '', []);
                                            this.setState({ pesquisaConversasOuContatosEncaminharMensagem: '', pesquisaConversa: '' });
                                            this.updateSearchEncaminhar('');
                                            this.zeraMensagemResponder()
                                            setTimeout(() => {
                                                this.setState({ defaultTabIndex: 0 })
                                            }, 1000);

                                        }
                                    }
                                );

                            }}>

                                {/*      <p style={{ fontSize: 14, color: this.props.letraBotaoPadrao, fontWeight: 'bold' }}> */}INICIAR CONVERSA{/* </p> */}
                            </ButtonIniciarAtendimento>}
                            {this.props.loadingIniciandoConversaViaContacts === true &&
                                <ButtonIniciarAtendimentoLoading backgrounddinamico={this.props.corBotaoPadrao} corLetraDinamica={this.props.letraBotaoPadrao} ><ClipLoader
                                    css={{}}
                                    size={28}
                                    color={this.props.letraBotaoPadrao}
                                    loading={true}
                                /></ButtonIniciarAtendimentoLoading>
                                /* <ClipLoader
                                    css={{ marginLeft: 30 }}
                                    size={28}
                                    color={this.props.corBotaoPadrao}
                                    loading={true}
                                /> */
                            }
                        </div>
                    </DivTextAtendimentoEmEspera>
                    {/*  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <IconeAcaoChat
                            onClick={() => {
                                this._toggleModalRespostaPadrao()
                            }}
                            colorDinamico={this.props.corSegundoBotaoPadrao} backgroundBotao={this.props.corBotaoPadrao} data-tip={"Exibir respostas padrões"} data-for="tip-top" className="icon-list-on-window" style={{ cursor: 'pointer' }} />


                    </div> */}
                </div >
            )
        }

        else {
            return null
        }
    }
    moveCaretAtEnd(e) {
        var temp_value = e.target.value
        e.target.value = ''
        e.target.value = temp_value
    }
    renderConteudoCabecalhoBatePapo() {
        if (this.props.keyContacts !== '' || this.props.keyAtendimentoEmEsperaAtendimentoOnline !== '' || this.props.keyAtendimentoEmAtendimentoOnline !== '') {
            return (
                < ContainerPrincipal backgroundDinamico={this.props.backgroundMaisEscuro}>
                    <CabecalhoPrincipal bordaDinamica={this.props.coresLetraSecundario} backgroundDinamico={this.props.backgroundSegundo}>
                        {this.renderTExtCabecalho()}

                    </CabecalhoPrincipal>
                    <FlatList
                        /*    sortBy={(item,index) => {


                               return item.lastMessageDateTimeStamp
                           }} */
                        // sort={(item) =>}
                        list={this.props.chamadosEmAtendimentoOnline}
                        //list={this.props.chamadosEmAtendimentoOnline.sort((a, b) => { return b.lastMessageDateTimeStamp - a.lastMessageDateTimeStamp })}
                        renderItem={(item) => <ChatWindowEmAtendimento onRef={ref => (this[item.key] = ref)} data={item} key={item.key} onClickTabIndex={() => this.mudaTabIndexParaEmAtendimento()} />}
                        renderWhenEmpty={() => {
                            return null

                        }}

                    />

                    <FlatList
                        list={this.props.chamadosEmEsperaAtendimentoOnline}
                        renderItem={(item) => <ChatWindowEmEspera onRef={ref => (this[item.key] = ref)} data={item} key={item.key} onClickTabIndex={() => this.mudaTabIndexParaEmAtendimento()} limpaPesquisaConversa={() => this.zeraPesquisa()} />}
                        renderWhenEmpty={() => {
                            return null

                        }} />
                    {this.props.keyContacts !== '' && this.props.dadosContactsSelecionado.length > 0 && <ContainerBatepapo bordaDinamica={this.props.coresLetraSecundario} backgroundinamico={this.props.backgroundPrimeiro}>
                        {this.props.dadosContactsSelecionado.length > 0 &&
                            <ContainerBatepapo bordaDinamica={this.props.coresLetraSecundario} backgroundinamico={this.props.backgroundPrimeiro}>
                                <MarcaDaguaBatePapo>
                                    <Dropzone
                                        multiple={true}
                                        noKeyboard
                                        maxSize={2e+7}
                                        noClick
                                        onDrop={acceptedFiles => this.escolheArquivoChamadoEmEsperaComInicioDeAtendimento(acceptedFiles)}>
                                        {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject }) => {
                                            //
                                            if (isDragActive) {

                                                return (
                                                    <div style={{
                                                        display: 'flex',
                                                        flex: 1,
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                        padding: '20px',
                                                        borderWidth: 2,
                                                        borderRadius: 2,
                                                        borderColor: this.renderBordaDrag(isDragActive, isDragAccept, isDragReject),
                                                        borderStyle: 'dashed',
                                                        color: this.props.coresLetraPadrao,
                                                        background: this.props.backgroundPrimeiro,
                                                        outline: 'none',
                                                        transition: 'border .24s ease-in-out',
                                                        justifyContent: 'center',
                                                        /*  margin: 5, */
                                                    }}  {...getRootProps()}>
                                                        {/*   <input {...getInputProps()} /> */}
                                                        <p style={{ color: this.props.coresLetraPadrao, textAlign: 'center', fontSize: 22 }}>Solte Arquivos para Anexar <i className="icon-clip2" style={{ color: this.props.coresLetraPadrao, fontSize: 21 }} /></p>
                                                        <p style={{ color: this.props.coresLetraPadrao, textAlign: 'center', fontSize: 22, marginTop: 20 }}>Tamanho Máximo de 20MB</p>
                                                    </div>
                                                )
                                            } else {
                                                return (

                                                    /*  <DivTesteFlatList  {...getRootProps()}>
                                                         <input {...getInputProps()} /> */
                                                    <>
                                                        <ContainerFlatListBatePapo
                                                            /*   onScroll={this.handleScroll} */
                                                            backgrounddinamico={this.props.coresLetraSecundario}
                                                            {...getRootProps()} >
                                                            <input {...getInputProps()} />

                                                            {/*    <div style={{ display: 'flex', flex: 1, flexDirection: 'column-reverse' }}> */}

                                                            {this.renderConversasUltimoAtendimento()}
                                                            {/*  <FlatList
                                                                    list={this.props.conversasChatEmEspera}
                                                                    renderItem={(item) => this.messageBoxEmEspera(item)}
                                                                    renderWhenEmpty={() => null}
                                                                />
                                                                <FlatList
                                                                    list={this.props.conversasCarregadasEmEspera}
                                                                    renderItem={(item) => this.messageBoxEmEspera(item)}
                                                                    renderWhenEmpty={() => null}
                                                                /> */}
                                                            {/* </div> */}
                                                            {/*     {this.renderConversasUltimoAtendimento()} */}
                                                        </ContainerFlatListBatePapo>
                                                        <ContainerInputDescricaoChamado
                                                            backgroundDinamico={'#F0f0f7'}
                                                            corLetraDinamico={this.props.coresLetraPadrao}
                                                            corLetraSecundaria={this.props.coresLetraSecundario}
                                                            corBordaDinamica={this.props.corBotaoPadrao}
                                                            style={{
                                                                /*  flex: this.props.mostrarMaisDetalhesChamado === true ? 0 : 1, */

                                                            }}>
                                                            {this.state.viewResponder === true &&

                                                                <div style={{ display: 'flex', borderTopLeftRadius: 5, borderTopRightRadius: 5, background: '#FFF', width: '100%', padding: 12, flexDirection: 'row' }}>

                                                                    <div style={{ display: 'flex', background: '#F0f0f7', width: '100%', borderRadius: 3, flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                                                                        <div style={{ display: 'flex', flexDirection: 'row', flex: 1, alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                                                                            <div style={{ width: 5, height: '100%', borderTopLeftRadius: 3, borderBottomLeftRadius: 3, backgroundColor: this.state.circleColorResponder === '#00FF7F' ? '#16cb9c' : "#39a3f4" }}></div>
                                                                            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'flex-start', justifyContent: 'flex-start', padding: 7 }}>
                                                                                <TextResponder style={{ color: this.state.circleColorResponder === '#00FF7F' ? '#16cb9c' : "#39a3f4" }}>{this.state.circleColorResponder === '#39a3f4' ? this.props.dadosContactsSelecionado[0].nome : this.state.atendenteResponder}</TextResponder>
                                                                                {/*  </div>
                                                                                <div style={{ display: 'flex', flexDirection: 'row' }}> */}

                                                                                <DivTruncarTexto /* style={{ display: 'flex', height: 52, flex: 1, alignItems: 'center', justifyContent: 'flex-start' }} */>


                                                                                    <TextResponder style={{ color: '#000' }}>
                                                                                        {this.state.commentResponderType === 'imagem' && <i className="icon-imagewpp" style={{ fontSize: 14, color: '#000' }} />}
                                                                                        {this.state.commentResponderType === 'video' && <i className="icon-video-camera" style={{ fontSize: 14, color: '#000' }} />}

                                                                                        {this.state.commentResponderType === 'location' && <i className="icon-localization" style={{ fontSize: 14, color: '#000' }} />}
                                                                                        {this.state.commentResponderType === 'contato' && <i className="icon-man-user" style={{ fontSize: 14, color: '#000' }} />}
                                                                                        {this.state.commentResponderType === 'audio' && <i className="icon-microphone" style={{ fontSize: 14, color: '#000' }} />}
                                                                                        {this.state.commentResponderType === 'arquivo' && <i className="icon-documentwpp" style={{ fontSize: 14, color: '#000' }} />}
                                                                                        {this.state.commentResponder}</TextResponder>

                                                                                </DivTruncarTexto>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ display: 'flex', width: 40, justifyContent: 'center', alignItems: 'center' }}>
                                                                        <i onClick={() => { this.zeraMensagemResponder() }} className="icon-close" style={{ fontSize: 13, color: '#000', cursor: 'pointer' }} />
                                                                    </div>
                                                                </div>}
                                                            <Input
                                                                autofocus={true}
                                                                onFocus={this.moveCaretAtEnd}
                                                                inputRef={ref => this.nativeTextInput = ref}
                                                                /*  inputRef={ref =>this.nativeTextInput= ref} */
                                                                onChange={(value) => {
                                                                    let valorDigitado = value.target.value
                                                                    /*
                                                                     */
                                                                    if (valorDigitado === "/" && valorDigitado.length === 1) {
                                                                        this.props.modificaModalRespostaPadrao(!this.props.modalRespostaPadrao)
                                                                    } else {
                                                                        if (this.props.modalRespostaPadrao === true) {
                                                                            this.props.modificaModalRespostaPadrao(!this.props.modalRespostaPadrao)
                                                                        }
                                                                    }
                                                                    let input = this.nativeTextInput;
                                                                    input.value = value.target.value;
                                                                    input.focus();
                                                                }}
                                                                /* defaultValue={this.state.defaultValeInput} */
                                                                /* value={this.props.comentarioChat} */
                                                                inputStyle={{ borderRadius: 5 }}
                                                                minHeight={40}
                                                                maxHeight={400}
                                                                placeholder={"Digite sua mensagem aqui"}
                                                                multiline={true}
                                                                onKeyDown={this._handleKeyDownEmEspera}
                                                                rightButtons={this.renderBotoesDireitoEmEspera()}
                                                                leftButtons={
                                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                        {this.renderIconeUploadArquivoChatEmEspera()}
                                                                        < IconeMostrarSenha style={{ cursor: 'pointer' }} id="emojis-btn" data-tip={"Enviar Emoji na Conversa"} data-for="tip-top" onClick={() => { this._toggleViewEmoji() }} colorDinamico={this.state.viewEmoji == true ? '#16cb9c' : '#383838'} bordaDinamica={this.props.corBotaoPadrao} className="icon-smiley" />
                                                                    </div>
                                                                }
                                                            />

                                                            {this.state.viewEmoji == true && <Picker
                                                                emojiSize={21}
                                                                native
                                                                onSelect={this.addEmoji}
                                                                // onClick={this.addEmoji}
                                                                title='Selecione seu Emoji'
                                                                emoji='point_up'
                                                                showPreview={false}
                                                                autoFocus={false}

                                                                useButton
                                                                tooltip={false}
                                                                style={{ position: 'absolute', bottom: '55px', left: '65px', zIndex: 999 }}
                                                                i18n={{
                                                                    search: 'Pesquisar emoji',
                                                                    clear: 'Limpar',
                                                                    notfound: 'Nenhum Emoji Encontrado',
                                                                    skintext: 'Escolha seu tom de pele padrão',
                                                                    categories: {
                                                                        search: 'Resultados da pesquisa',
                                                                        recent: 'Recentes',
                                                                        smileys: 'Emojis & Pessoas',
                                                                        people: 'Emojis & Pessoas',
                                                                        nature: 'Animais & Natureza',
                                                                        foods: 'Comidas & Bebidas',
                                                                        activity: 'Atividades',
                                                                        places: 'Viagens & Locais',
                                                                        objects: 'Objetos',
                                                                        symbols: 'Símbolos',
                                                                        flags: 'Bandeiras',
                                                                        custom: 'Custom',
                                                                    }
                                                                }}
                                                            />}

                                                        </ContainerInputDescricaoChamado>
                                                    </>
                                                    /*  </DivTesteFlatList> */

                                                )
                                            }
                                        }}

                                    </Dropzone>
                                </MarcaDaguaBatePapo>
                            </ContainerBatepapo>
                            /*  <MarcaDaguaBatePapo> */
                            /*  <Dropzone
                                 multiple={true}
                                 noKeyboard
                                 maxSize={2e+7}
                                 noClick
                                 onDrop={acceptedFiles => this.escolheArquivoChamado(acceptedFiles)}>
                                 {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject }) => {
                                     //
                                     if (isDragActive) {
                                         return (
                                             <div style={{
                                                 display: 'flex',
                                                 flex: 1,
                                                 flexDirection: 'column',
                                                 alignItems: 'center',
                                                 padding: '20px',
                                                 borderWidth: 2,
                                                 borderRadius: 2,
                                                 borderColor: 'red',
                                                 borderStyle: 'dashed',
                                                 color: this.props.coresLetraPadrao,
                                                 background: this.props.backgroundPrimeiro,
                                                 outline: 'none',
                                                 transition: 'border .24s ease-in-out',
                                                 justifyContent: 'center',
                                             }}  {...getRootProps()}>
                                                 <p style={{ color: this.props.coresLetraPadrao, textAlign: 'center', fontSize: 22 }}>Inicie a Conversa</p>
                                                 <p style={{ color: this.props.coresLetraPadrao, textAlign: 'center', fontSize: 22, marginTop: 20 }}>Clique em INICIAR CONVERSA para fazer Upload de Arquivos</p>
                                             </div>
                                         )

                                     } else {
                                         return (


                                             <>
                                                 <ContainerFlatListBatePapo
                                                     backgrounddinamico={this.props.coresLetraSecundario}
                                                     {...getRootProps()} >
                                                     <input {...getInputProps()} />


                                                 </ContainerFlatListBatePapo>
                                                 <ContainerInputDescricaoChamado
                                                     backgroundDinamico={this.props.backgroundMaisEscuro}
                                                     corLetraDinamico={this.props.coresLetraPadrao}
                                                     corLetraSecundaria={this.props.coresLetraSecundario}
                                                     corBordaDinamica={this.props.corBotaoPadrao}
                                                     style={{


                                                     }}>

                                                     <Input
                                                         inputRef={ref => this.nativeTextInput = ref}
                                                         disabled={true}
                                                         onChange={(value) => {

                                                             let input = this.nativeTextInput;
                                                             input.value = value.target.value;
                                                             input.focus();
                                                         }}

                                                         inputStyle={{ borderRadius: 5 }}
                                                         minHeight={40}
                                                         maxHeight={400}
                                                         placeholder={"Inicie o Atendimento para enviar Mensagens"}
                                                         multiline={true}
                                                         onKeyDown={this._handleKeyDownEmEspera}
                                                         rightButtons={this.renderBotoesDireitoEmEspera()}
                                                         leftButtons={
                                                             <div style={{ alignItems: 'center', justifyContent: 'center' }}>
                                                                 < IconeMostrarSenha style={{ cursor: 'no-drop' }} data-tip={"Inicie o atendimento para Enviar Arquivos"} data-for="tip-top" onClick={() => {
                                                                     toastr.error('Error', 'Inicie o Atendimento para Enviar Arquivos');
                                                                 }} colorDinamico={'#383838'} bordaDinamica={'#383838'} className="icon-clip" />

                                                                 < IconeMostrarSenha style={{ cursor: 'no-drop' }} id="emojis-btn" disabled={true} data-tip={"Inicie o atendimento para Enviar Emoji"} data-for="tip-top" onClick={() => {
                                                                     toastr.error('Error', 'Inicie o Atendimento para Enviar Mensagens');
                                                                 }} colorDinamico={this.state.viewEmoji == true ? '#16cb9c' : '#383838'} bordaDinamica={this.props.corBotaoPadrao} className="icon-smiley" />
                                                             </div>
                                                         }
                                                     />

                                                     {this.state.viewEmoji == true && <Picker
                                                         emojiSize={21}
                                                         native
                                                         onSelect={this.addEmoji}
                                                         // onClick={this.addEmoji}
                                                         title='Selecione seu Emoji'
                                                         emoji='point_up'
                                                         showPreview={false}
                                                         autoFocus={false}

                                                         useButton
                                                         tooltip={false}
                                                         style={{ position: 'absolute', bottom: '55px', left: '60px', zIndex: 999 }}
                                                         i18n={{
                                                             search: 'Pesquisar emoji',
                                                             clear: 'Limpar',
                                                             notfound: 'Nenhum Emoji Encontrado',
                                                             skintext: 'Escolha seu tom de pele padrão',
                                                             categories: {
                                                                 search: 'Resultados da pesquisa',
                                                                 recent: 'Recentes',
                                                                 smileys: 'Emojis & Pessoas',
                                                                 people: 'Emojis & Pessoas',
                                                                 nature: 'Animais & Natureza',
                                                                 foods: 'Comidas & Bebidas',
                                                                 activity: 'Atividades',
                                                                 places: 'Viagens & Locais',
                                                                 objects: 'Objetos',
                                                                 symbols: 'Símbolos',
                                                                 flags: 'Bandeiras',
                                                                 custom: 'Custom',
                                                             }
                                                         }}
                                                     />}

                                                 </ContainerInputDescricaoChamado>
                                             </>


                                         )
                                     }
                                 }}

                             </Dropzone> */


                        }
                    </ContainerBatepapo>}

                </ContainerPrincipal>
            )
        } else {
            return null
        }


    }
    renderSelecionaChamado() {
        let quantidadeChamadoEmEspera = 0;
        for (let i = 0; i < this.props.chamadosEmAtendimentoOnline.length; i++) {
            if (this.props.chamadosEmAtendimentoOnline[i].permiteVisualizarChamado !== false) {
                quantidadeChamadoEmEspera++
            }
        }
        for (let i = 0; i < this.props.chamadosEmEsperaAtendimentoOnline.length; i++) {
            if (this.props.chamadosEmEsperaAtendimentoOnline[i].permiteVisualizarChamado !== false) {
                quantidadeChamadoEmEspera++
            }
        }

        if (quantidadeChamadoEmEspera > 0 && this.props.keyContacts === '' && this.props.keyAtendimentoEmEsperaAtendimentoOnline === '' && this.props.keyAtendimentoEmAtendimentoOnline === '') {
            return (
                <ContainerPrincipal >
                    <CabecalhoPrincipal bordaDinamica={this.props.coresLetraSecundario} backgroundDinamico={this.props.backgroundSegundo}>
                    </CabecalhoPrincipal>
                    <div style={{ display: 'flex', borderLeft: `1px solid ${this.props.coresLetraSecundario}`, flex: 1, alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                        <img src={selectwhite} alt="foto" draggable={false} style={{ height: 200, width: 200 }} />
                        <p style={{ marginTop: 10, marginBottom: 10, color: this.props.coresLetraPadrao, fontSize: 22 }}>Atendimentos à Vista!</p>
                        {this.props.configWhatsapp !== undefined && this.props.configWhatsapp.length > 0 && <> <p style={{ color: this.props.coresLetraPadrao, fontSize: 17 }}>Selecione um atendimento</p>
                            <p style={{ color: this.props.coresLetraPadrao, fontSize: 17 }}>Para iniciar uma nova conversa</p></>}

                    </div>

                </ContainerPrincipal>
            )
        } else if (quantidadeChamadoEmEspera > 0 && this.props.keyContacts === '' && this.props.keyAtendimentoEmEsperaAtendimentoOnline === '' && this.props.keyAtendimentoEmAtendimentoOnline === '') {
            return (
                <ContainerPrincipal >
                    <CabecalhoPrincipal bordaDinamica={this.props.coresLetraSecundario} backgroundDinamico={this.props.backgroundSegundo}>
                    </CabecalhoPrincipal>
                    <div style={{ display: 'flex', borderLeft: `1px solid ${this.props.coresLetraSecundario}`, flex: 1, alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                        <img src={selectwhite} alt="foto" draggable={false} style={{ height: 200, width: 200 }} />
                        <p style={{ marginTop: 10, marginBottom: 10, color: this.props.coresLetraPadrao, fontSize: 22 }}>Atendimentos à Vista!</p>
                        {this.props.configWhatsapp !== undefined && this.props.configWhatsapp.length > 0 && <> <p style={{ color: this.props.coresLetraPadrao, fontSize: 17 }}>Selecione um atendimento</p>
                            <p style={{ color: this.props.coresLetraPadrao, fontSize: 17 }}>Para iniciar uma nova conversa</p></>}

                    </div>

                </ContainerPrincipal>
            )

        } else if (quantidadeChamadoEmEspera > 0 && this.props.keyContacts === '' && this.props.keyAtendimentoEmEsperaAtendimentoOnline === '' && this.props.keyAtendimentoEmAtendimentoOnline === '' && this.props.chamadosEmEsperaAtendimentoOnline.length !== 0) {
            return (
                <ContainerPrincipal >
                    <CabecalhoPrincipal bordaDinamica={this.props.coresLetraSecundario} backgroundDinamico={this.props.backgroundSegundo}>
                    </CabecalhoPrincipal>
                    <div style={{ display: 'flex', borderLeft: `1px solid ${this.props.coresLetraSecundario}`, flex: 1, alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                        <img src={selectwhite} alt="foto" draggable={false} style={{ height: 200, width: 200 }} />
                        <p style={{ marginTop: 10, marginBottom: 10, color: this.props.coresLetraPadrao, fontSize: 22 }}>Atendimentos à Vista!</p>
                        {this.props.configWhatsapp !== undefined && this.props.configWhatsapp.length > 0 && <> <p style={{ color: this.props.coresLetraPadrao, fontSize: 17 }}>Selecione um atendimento</p>
                            <p style={{ color: this.props.coresLetraPadrao, fontSize: 17 }}>Para iniciar uma nova conversa</p></>}

                    </div>

                </ContainerPrincipal>
            )
        }
        else if (quantidadeChamadoEmEspera === 0 && this.props.keyContacts === '' && this.props.keyAtendimentoEmEsperaAtendimentoOnline === '' && this.props.keyAtendimentoEmAtendimentoOnline === '') {
            return (
                <ContainerPrincipal >
                    <CabecalhoPrincipal bordaDinamica={this.props.coresLetraSecundario} backgroundDinamico={this.props.backgroundSegundo}>
                    </CabecalhoPrincipal>
                    <div style={{ display: 'flex', borderLeft: `1px solid ${this.props.coresLetraSecundario}`, flex: 1, alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                        <img src={pandawhite} alt="foto" draggable={false} style={{ height: 200, width: 200 }} />
                        <p style={{ marginTop: 10, marginBottom: 10, color: this.props.coresLetraPadrao, fontSize: 22 }}>Tudo Tranquilo!</p>
                        {this.props.configWhatsapp !== undefined && this.props.configWhatsapp.length > 0 && <> <p style={{ color: this.props.coresLetraPadrao, fontSize: 17 }}>Que tal começar um atendimento?</p>
                            <p style={{ color: this.props.coresLetraPadrao, fontSize: 17 }}>Selecione um contato para iniciar</p></>}
                        {this.props.configWhatsapp === undefined || this.props.configWhatsapp.length === 0 && <p style={{ color: this.props.coresLetraPadrao, fontSize: 17 }}>Nenhum Atendimento.</p>
                        }
                    </div>

                </ContainerPrincipal>
            )
        }

        else {
            return null
        }
    }
    messageBoxEmEspera(item) {
        /*      */
        if (item.deleteMessage === true && this.state.visualizaMensagemExcluida === false) {
            return (
                <MessageBox
                    {...item}
                    id={item.key}
                    reply={this.renderMensagemReplyEmEspera(item)}
                    /*   ref={ref => { this.refsArray[item.key] = ref }} */
                    onReplyMessageClick={(e) => { this.renderClickButtonReply(item, e) }}
                    onReplyClick={() => { this.renderResponderMensagem(item) }}
                    onForwardClick={() => {
                        this._toggleModalEncaminharMensagem();
                        let mensagemParaSerEncaminhada2 = [];
                        item['keyChamado'] = this.props.data.key;
                        mensagemParaSerEncaminhada2.push(item)
                        this.setState({ mensagemParaSerEncaminhada: mensagemParaSerEncaminhada2 })
                    }}
                    onRemoveMessageClick={() => { this.setState({ modalMensagemExcluida: true, dadosMensagemExcluida: item }) }}
                    //onClick={() => {  }}
                    //onOpen={() => {  }}
                    position={item.circleColor === '#00FF7F' ? "right" : "left"}
                    focus={item.idMensagem === this.state.focusMessageBox ? true : false}
                    onMessageFocused={() => { setTimeout(() => { this.setState({ focusMessageBox: 'vazio' }); }, 1000); }}
                    type={'text'}
                    title={(
                        <div
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                        >
                            {item.title}
                            {this.state.visualizaMensagemExcluida === false && <span onClick={() => { this.setState({ visualizaMensagemExcluida: true }) }} style={{ color: '#16cb9c', fontSize: 10, textDecorationLine: 'underline', marginLeft: 10, fontStyle: 'italic', cursor: "pointer" }}>Visualizar</span>}
                        </div>
                    )}
                    titleColor={item.circleColor === '#00FF7F' ? '#16cb9c' : "#39a3f4"}
                    text={
                        (
                            <Linkify
                                componentDecorator={(decoratedHref, decoratedText, key) => (
                                    <a style={{ fontSize: 15, color: '#39a3f4' }} target="blank" href={decoratedHref} key={key}>
                                        {decoratedText}
                                    </a>
                                )}
                            >
                                <i className="icon-block" style={{ fontSize: 14, color: '#808080' }} />
                                <span style={{ color: '#808080', fontSize: 14, fontStyle: 'italic' }}>Mensagem Apagada</span>
                                {/*   {item.deleteMessage === true && <span onClick={() => { }} style={{ color: '#16cb9c', fontSize: 10, textDecorationLine: 'underline', marginLeft: 10, fontStyle: 'italic', cursor: "pointer" }}>Visualizar</span>}
                                {item.deleteMessage === false && <span onClick={() => { }} style={{ color: '#EC6A69', fontSize: 10, textDecorationLine: 'underline', marginLeft: 10, fontStyle: 'italic', cursor: "pointer" }}>Ocultar</span>} */}
                            </Linkify>
                        )
                    }
                    copiableDate
                    dateString={item.dataHoraAtual}
                    forwarded={false}
                    replyButton={false}
                    removeButton={false}
                    status={null} //(waiting, sent, received, read)

                />
            )
        } else if (item.tipo == 'mensagem') {
            if (item.title.includes('Atendimento criado por')) {
                return (
                    <div key={item.key}>
                        <SystemMessage
                            text={`Conversa Iniciada`} />

                        {this.renderDescriptionMessageBoxEmEspera(item)}
                    </div>
                )

            } else if (item.description.includes(`${item.title} entrou no Chat`)) {
                return (
                    <div key={item.key}>
                        <SystemMessage
                            text={`${item.title} entrou na Conversa`} />
                    </div>
                )
            }
            else if (item.description.includes(`Atendimento atribuido ao atendente`)) {
                let atendendoAtribuido = item.description.replace('Atendimento atribuido ao atendente', '')
                return (
                    <div key={item.key}>
                        {/*   <SystemMessage
                            text={`${item.description} entrou na Conversa`} /> */}
                        <SystemMessage
                            text={`${item.description}`} />

                    </div>
                )
            }
            else if (item.description.includes(`Atendimento transferido para o setor`)) {
                return (
                    <div key={item.key}>
                        {/*   <SystemMessage
                            text={`${item.description} entrou na Conversa`} /> */}
                        <SystemMessage
                            text={`${item.description}`} />

                    </div>
                )
            }
            else if (item.description.includes(`${item.title} encerrou o chat`)) {
                return (
                    <div key={item.key}>
                        <SystemMessage
                            text={`${item.title} encerrou a Conversa.`} />
                    </div>
                )
            } else if (item.description.includes(`Inicio do Atendimento: #`)) {
                return (
                    <div key={item.key}>
                        <div style={{
                            display: 'flex', height: 20, flex: 1, backgroundColor: '#39a3f4', borderRadius: 10, alignItems: 'center', justifyContent: 'center', textAlign: 'center',
                            margin: 15
                        }}>

                            < TextMensagem corLetraDinamica={'#000'} >
                                {item.description}
                            </TextMensagem>
                        </div>

                    </div>
                )
            }
            else if (item.description.includes(`Fim do Atendimento: #`)) {
                return (
                    <div key={item.key}>
                        <div style={{
                            display: 'flex', height: 20, flex: 1, backgroundColor: '#FFC04D', borderRadius: 10, alignItems: 'center', justifyContent: 'center', textAlign: 'center',
                            margin: 15
                        }}>
                            < TextMensagem corLetraDinamica={'#000'} >
                                {item.description}
                            </TextMensagem>
                        </div>
                    </div >
                )
            } else if (item.description.includes(`Atendimento REDIRECIONADO para espera devido ao cliente`) || item.description.includes(`Atendimento redirecionado devido o cliente `)) {
                return (
                    <div key={item.key}>
                        <SystemMessage
                            text={item.description} />
                    </div>
                )
            }
            else if (item.description.includes(`${item.title} Alterou o local do atendimento de`)) {
                return (
                    <div key={item.key}>
                        <SystemMessage
                            text={item.description} />
                    </div>
                )
            }
            else if (item.description.includes(`${item.title} Alterou o setor de`)) {
                return (
                    <div key={item.key}>
                        <SystemMessage
                            text={item.description} />
                    </div>
                )
            }
            else if (item.description.includes(`${item.title} Alterou a descrição do problema de`)) {
                return (
                    <div key={item.key}>
                        <SystemMessage
                            text={item.description} />
                    </div>
                )
            }
            else if (item.description.includes(`Alterou a data de vencimento de`)) {
                return (
                    <div key={item.key}>
                        <SystemMessage
                            text={item.description} />
                    </div>
                )
            } else if (item.description.includes(`Alterou prioridade do atendimento de`)) {
                return (
                    <div key={item.key}>
                        <SystemMessage
                            text={item.description} />
                    </div>
                )
            }
            else if (item.description.includes(`Atendimento Finalizado Devido á Suspeita de Looping de Bot`)) {
                return (
                    <div key={item.key}>
                        <SystemMessage
                            text={item.description} />
                    </div>
                )
            }
            else {

                return (
                    <div key={item.key}>

                        <MessageBox
                            {...item}
                            id={item.key}
                            reply={this.renderMensagemReplyEmEspera(item)}
                            /*   ref={ref => { this.refsArray[item.key] = ref }} */
                            onReplyMessageClick={(e) => { this.renderClickButtonReply(item, e) }}
                            onReplyClick={() => { this.renderResponderMensagem(item) }}
                            onForwardClick={() => {
                                this._toggleModalEncaminharMensagem();
                                let mensagemParaSerEncaminhada2 = [];
                                item['keyChamado'] = this.props.dadosContactsSelecionado[0].key;
                                mensagemParaSerEncaminhada2.push(item)
                                this.setState({ mensagemParaSerEncaminhada: mensagemParaSerEncaminhada2 })
                            }}
                            //onClick={() => {  }}
                            //onOpen={() => {  }}
                            position={item.circleColor === '#00FF7F' ? "right" : "left"}
                            focus={item.idMensagem === this.state.focusMessageBox ? true : false}
                            onMessageFocused={() => { setTimeout(() => { this.setState({ focusMessageBox: 'vazio' }); }, 1000); }}
                            type={'text'}
                            title={(
                                <div
                                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                >
                                    {item.title}
                                    {this.state.visualizaMensagemExcluida === true && item.deleteMessage === true && <span onClick={() => { this.setState({ visualizaMensagemExcluida: false }) }} style={{ color: '#EC6A69', fontSize: 10, textDecorationLine: 'underline', marginLeft: 10, fontStyle: 'italic', cursor: "pointer" }}>Ocultar</span>}
                                </div>
                            )}
                            titleColor={item.circleColor === '#00FF7F' ? '#16cb9c' : "#39a3f4"}
                            text={
                                (
                                    <Linkify
                                        componentDecorator={(decoratedHref, decoratedText, key) => (
                                            <a style={{ fontSize: 15, color: '#39a3f4' }} target="blank" href={decoratedHref} key={key}>
                                                {decoratedText}
                                            </a>
                                        )}
                                    >
                                        {item.description}
                                    </Linkify>
                                )
                            }
                            copiableDate
                            dateString={item.dataHoraAtual}
                            forwarded={item.statusMessage === 'waiting' ? false : true}
                            replyButton={item.statusMessage === 'waiting' ? false : true}
                            status={item.circleColor === '#39a3f4' ? null : item.statusMessage} //(waiting, sent, received, read)

                        />



                    </div>

                )

            }
        } else {
            //let tipoArquivo = rowData.description.split('.').pop().toLowerCase();
            if (item.tipoArquivo != undefined && item.tipoArquivo != null && item.tipoArquivo != '') {
                if (item.tipoArquivo.includes('image') && !item.tipoArquivo.includes('corel')) {
                    if (item.urlDownload == undefined || item.urlDownload == null || item.urlDownload == '') {
                        //tenho que buscar a url de download no firebase
                        //
                        //
                        return (
                            <div key={item.key}>
                                <MessageBox
                                    {...item}
                                    id={item.key}
                                    reply={this.renderMensagemReplyEmEspera(item)}
                                    data={{
                                        uri: item.urlDownload,
                                        caption: item.captionArquivo,
                                        status: {
                                            click: this.state.nomeFileDownload == item.description ? this.state.clickFileDownload : false,
                                            loading: this.state.nomeFileDownload == item.description ? this.state.loadingFileDownload : 0,
                                            download: this.state.downloadFile
                                        },
                                        //size: "100MB",
                                    }}
                                    focus={item.idMensagem === this.state.focusMessageBox ? true : false}
                                    onMessageFocused={() => { setTimeout(() => { this.setState({ focusMessageBox: 'vazio' }); }, 1000); }}
                                    onDownload={event => this.baixarArquivo(item.description, item)}
                                    onReplyClick={() => { this.renderResponderMensagem(item) }}
                                    onReplyMessageClick={() => { this.renderClickButtonReply(item) }}
                                    onForwardClick={() => {
                                        this._toggleModalEncaminharMensagem();
                                        let mensagemParaSerEncaminhada2 = [];
                                        item['keyChamado'] = this.props.dadosContactsSelecionado[0].key;
                                        mensagemParaSerEncaminhada2.push(item)
                                        this.setState({ mensagemParaSerEncaminhada: mensagemParaSerEncaminhada2 })
                                    }}
                                    //onClick={() => {  }}
                                    //onOpen={() => {  }}
                                    position={item.circleColor === '#00FF7F' ? 'right' : "left"}
                                    type={'file'}
                                    title={(
                                        <div
                                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                        >
                                            {item.title}
                                            {this.state.visualizaMensagemExcluida === true && item.deleteMessage === true && <span onClick={() => { this.setState({ visualizaMensagemExcluida: false }) }} style={{ color: '#EC6A69', fontSize: 10, textDecorationLine: 'underline', marginLeft: 10, fontStyle: 'italic', cursor: "pointer" }}>Ocultar</span>}
                                        </div>
                                    )}
                                    titleColor={item.circleColor === '#00FF7F' ? '#16cb9c' : "#39a3f4"}
                                    text={item.description}
                                    copiableDate
                                    dateString={item.dataHoraAtual}
                                    forwarded={item.statusMessage === 'waiting' ? false : true}
                                    replyButton={item.statusMessage === 'waiting' ? false : true}
                                    status={item.circleColor === '#39a3f4' ? null : item.statusMessage} //(waiting, sent, received, read)

                                />
                            </div>
                        )
                        {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right' }} key={item.key + item.alterado}>
                                    <div
                                        style={{
                                            background: '#d9edf7',
                                            padding: 10,
                                            // borderRadius: 8,
                                            borderTopLeftRadius: 20,
                                            borderTopRightRadius: 1,
                                            borderBottomRightRadius: 20,
                                            borderBottomLeftRadius: 5,
                                            boxShadow: '0.5rem 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.2)',
                                            margin: 10,
                                            display: 'flex',
                                            flexDirection: 'column'
                                        }} >
                                        <TextTitulo corLetraDinamica={item.circleColor}>{item.title}</TextTitulo>
                                        <ContainerClipBaixarArquivo backgroundDinamico={this.props.backgroundSegundo} corBordaDinamica={this.props.coresLetraSecundario} onClick={() => { this.baixarArquivo(item.description) }} style={{}}>
                                            <i className="icon-clip2" style={{ fontSize: 15, color: this.props.coresLetraSecundario }} />
                                            <TextBaixarArquivo >{item.description}</TextBaixarArquivo>
                                        </ContainerClipBaixarArquivo>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'left', marginBottom: -8, marginTop: 5 }}>
                                            <p style={{ fontSize: 11, color: '#808080', fontStyle: 'italic' }}>{item.time}</p>
                                        </div>
                                    </div>
                                </div> */}


                    } else {
                        //tenho a URL salva, somente renderizar
                        //
                        //

                        return (
                            <div key={item.key}>
                                <MessageBox
                                    {...item}
                                    id={item.key}
                                    reply={this.renderMensagemReplyEmEspera(item)}

                                    data={{
                                        uri: item.urlDownload,
                                        status: {
                                            click: true,
                                            // loading: 1,
                                            download: true
                                        },
                                        width: 300,
                                        height: 300,
                                    }}
                                    //onDownload={event => this.baixarArquivo(item.description, item)}
                                    onReplyMessageClick={() => { this.renderClickButtonReply(item) }}
                                    onReplyClick={() => { this.renderResponderMensagem(item) }}
                                    onForwardClick={() => {
                                        this._toggleModalEncaminharMensagem();
                                        let mensagemParaSerEncaminhada2 = [];
                                        item['keyChamado'] = this.props.dadosContactsSelecionado[0].key;
                                        mensagemParaSerEncaminhada2.push(item)
                                        this.setState({ mensagemParaSerEncaminhada: mensagemParaSerEncaminhada2 })
                                    }}
                                    /*     onClick={(event) => {  this.downloadImage(item.urlDownload, item.description);   }} */
                                    onOpen={() => { this.downloadImage(item.urlDownload, item.description); }}
                                    position={item.circleColor === '#00FF7F' ? 'right' : "left"}
                                    type={'photo'}
                                    title={(
                                        <div
                                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                        >
                                            {item.title}
                                            {this.state.visualizaMensagemExcluida === true && item.deleteMessage === true && <span onClick={() => { this.setState({ visualizaMensagemExcluida: false }) }} style={{ color: '#EC6A69', fontSize: 10, textDecorationLine: 'underline', marginLeft: 10, fontStyle: 'italic', cursor: "pointer" }}>Ocultar</span>}
                                        </div>
                                    )}
                                    titleColor={item.circleColor === '#00FF7F' ? '#16cb9c' : "#39a3f4"}
                                    text={item.captionArquivo}
                                    focus={item.idMensagem === this.state.focusMessageBox ? true : false}
                                    onMessageFocused={() => { setTimeout(() => { this.setState({ focusMessageBox: 'vazio' }); }, 1000); }}
                                    copiableDate
                                    dateString={item.dataHoraAtual}
                                    forwarded={item.statusMessage === 'waiting' ? false : true}
                                    replyButton={item.statusMessage === 'waiting' ? false : true}
                                    status={item.circleColor === '#39a3f4' ? null : item.statusMessage} //(waiting, sent, received, read)

                                />
                            </div>
                        )


                    }

                } else if (item.tipoArquivo.includes('video') && item.audio !== true || item.tipoArquivo.includes('mp4') && item.audio !== true) {
                    if (item.urlDownload == undefined || item.urlDownload == null || item.urlDownload == '') {
                        //tenho que buscar a url de download no firebase
                        //
                        //
                        return (
                            <div key={item.key}>
                                <MessageBox
                                    {...item}
                                    id={item.key}
                                    reply={this.renderMensagemReplyEmEspera(item)}
                                    data={{
                                        uri: item.urlDownload,
                                        caption: item.captionArquivo,
                                        status: {
                                            click: this.state.nomeFileDownload == item.description ? this.state.clickFileDownload : false,
                                            loading: this.state.nomeFileDownload == item.description ? this.state.loadingFileDownload : 0,
                                            download: this.state.downloadFile
                                        },
                                        //size: "100MB",
                                    }}
                                    focus={item.idMensagem === this.state.focusMessageBox ? true : false}
                                    onMessageFocused={() => { setTimeout(() => { this.setState({ focusMessageBox: 'vazio' }); }, 1000); }}
                                    onDownload={event => this.baixarArquivo(item.description, item)}
                                    onReplyClick={() => { this.renderResponderMensagem(item) }}
                                    onReplyMessageClick={() => { this.renderClickButtonReply(item) }}
                                    onForwardClick={() => {
                                        this._toggleModalEncaminharMensagem();
                                        let mensagemParaSerEncaminhada2 = [];
                                        item['keyChamado'] = this.props.dadosContactsSelecionado[0].key;
                                        mensagemParaSerEncaminhada2.push(item)
                                        this.setState({ mensagemParaSerEncaminhada: mensagemParaSerEncaminhada2 })
                                    }}
                                    //onClick={() => {  }}
                                    //onOpen={() => {  }}
                                    position={item.circleColor === '#00FF7F' ? 'right' : "left"}
                                    type={'file'}
                                    title={(
                                        <div
                                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                        >
                                            {item.title}
                                            {this.state.visualizaMensagemExcluida === true && item.deleteMessage === true && <span onClick={() => { this.setState({ visualizaMensagemExcluida: false }) }} style={{ color: '#EC6A69', fontSize: 10, textDecorationLine: 'underline', marginLeft: 10, fontStyle: 'italic', cursor: "pointer" }}>Ocultar</span>}
                                        </div>
                                    )}
                                    titleColor={item.circleColor === '#00FF7F' ? '#16cb9c' : "#39a3f4"}
                                    text={item.description}
                                    copiableDate
                                    dateString={item.dataHoraAtual}
                                    forwarded={item.statusMessage === 'waiting' ? false : true}
                                    replyButton={item.statusMessage === 'waiting' ? false : true}
                                    status={item.circleColor === '#39a3f4' ? null : item.statusMessage}//(waiting, sent, received, read)

                                />
                            </div>
                        )



                    } else {
                        //tenho a URL salva, somente renderizar
                        //
                        //

                        return (
                            <div key={item.key}>
                                <MessageBox
                                    {...item}
                                    id={item.key}
                                    reply={this.renderMensagemReplyEmEspera(item)}

                                    data={{
                                        videoURL: item.urlDownload,
                                        status: {
                                            click: true,
                                            // loading: 1,
                                            download: true
                                        },
                                        width: 300,
                                        height: 300,
                                    }}
                                    focus={item.idMensagem === this.state.focusMessageBox ? true : false}
                                    onMessageFocused={() => { setTimeout(() => { this.setState({ focusMessageBox: 'vazio' }); }, 1000); }}
                                    //onDownload={event => this.baixarArquivo(item.description, item)}
                                    onReplyMessageClick={() => { this.renderClickButtonReply(item) }}
                                    onReplyClick={() => { this.renderResponderMensagem(item) }}
                                    onForwardClick={() => {
                                        this._toggleModalEncaminharMensagem();
                                        let mensagemParaSerEncaminhada2 = [];
                                        item['keyChamado'] = this.props.dadosContactsSelecionado[0].key;
                                        mensagemParaSerEncaminhada2.push(item)
                                        this.setState({ mensagemParaSerEncaminhada: mensagemParaSerEncaminhada2 })
                                    }}
                                    /* onClick={(event) => {  this.downloadImage(item.urlDownload, item.description);  }} */
                                    onOpen={() => { this.downloadImage(item.urlDownload, item.description); }}
                                    position={item.circleColor === '#00FF7F' ? 'right' : "left"}
                                    type={'video'}
                                    title={(
                                        <div
                                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                        >
                                            {item.title}
                                            {this.state.visualizaMensagemExcluida === true && item.deleteMessage === true && <span onClick={() => { this.setState({ visualizaMensagemExcluida: false }) }} style={{ color: '#EC6A69', fontSize: 10, textDecorationLine: 'underline', marginLeft: 10, fontStyle: 'italic', cursor: "pointer" }}>Ocultar</span>}
                                        </div>
                                    )}
                                    titleColor={item.circleColor === '#00FF7F' ? '#16cb9c' : "#39a3f4"}
                                    text={item.captionArquivo}
                                    copiableDate
                                    dateString={item.dataHoraAtual}
                                    forwarded={item.statusMessage === 'waiting' ? false : true}
                                    replyButton={item.statusMessage === 'waiting' ? false : true}
                                    status={item.circleColor === '#39a3f4' ? null : item.statusMessage}//(waiting, sent, received, read)

                                />
                            </div>
                        )


                    }

                }
                else if (item.audio == true) {
                    return (
                        <div key={item.key}>
                            <MessageBox
                                {...item}
                                id={item.key}
                                reply={this.renderMensagemReplyEmEspera(item)}
                                /*  data={{
                                     uri: item.urlDownload,
                                     status: {
                                         click: false,
                                         loading: 0,
                                     }
                                 }} */
                                focus={item.idMensagem === this.state.focusMessageBox ? true : false}
                                onMessageFocused={() => { setTimeout(() => { this.setState({ focusMessageBox: 'vazio' }); }, 1000); }}
                                onReplyMessageClick={() => { this.renderClickButtonReply(item) }}
                                onReplyClick={() => { this.renderResponderMensagem(item) }}
                                onForwardClick={() => {
                                    this._toggleModalEncaminharMensagem();
                                    let mensagemParaSerEncaminhada2 = [];
                                    item['keyChamado'] = this.props.dadosContactsSelecionado[0].key;
                                    mensagemParaSerEncaminhada2.push(item)
                                    this.setState({ mensagemParaSerEncaminhada: mensagemParaSerEncaminhada2 })
                                }}
                                //onClick={() => {  }}
                                //onOpen={() => {  }}
                                position={item.circleColor === '#00FF7F' ? 'right' : "left"}
                                type={'text'}
                                title={(
                                    <div
                                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                    >
                                        {item.title}
                                        {this.state.visualizaMensagemExcluida === true && item.deleteMessage === true && <span onClick={() => { this.setState({ visualizaMensagemExcluida: false }) }} style={{ color: '#EC6A69', fontSize: 10, textDecorationLine: 'underline', marginLeft: 10, fontStyle: 'italic', cursor: "pointer" }}>Ocultar</span>}
                                    </div>
                                )}
                                titleColor={item.circleColor === '#00FF7F' ? '#16cb9c' : "#39a3f4"}
                                text={
                                    (
                                        <audio style={{ height: 60, width: 250 }} src={item.urlDownload} controls />
                                        /*  <AudioPlayer
                                             src={item.urlDownload}
                                             preload='auto'
                                             showSkipControls={false}
                                             showJumpControls={false}
                                             layout={'horizontal-reverse'}
                                             defaultDuration={this.millisToMinutesAndSeconds(item.duracao)}
                                             customControlsSection={
                                                 [
                                                     RHAP_UI.MAIN_CONTROLS,
                                                 ]
                                             }
                                             style={{
                                                 width: '300px',
                                                 borderRadius: 10,
                                                 background: '#E9E9E9'
                                             }}
                                         // other props here
                                         /> */
                                    )
                                }
                                copiableDate
                                dateString={item.dataHoraAtual}
                                forwarded={item.statusMessage === 'waiting' ? false : true}
                                replyButton={item.statusMessage === 'waiting' ? false : true}
                                status={item.circleColor === '#39a3f4' ? null : item.statusMessage} //(waiting, sent, received, read)

                            />
                        </div>
                    )

                } else {
                    //
                    return (
                        <div key={item.key}>
                            <MessageBox
                                {...item}
                                id={item.key}
                                reply={this.renderMensagemReplyEmEspera(item)}
                                data={{
                                    uri: item.urlDownload,
                                    caption: item.captionArquivo,
                                    status: {
                                        click: this.state.nomeFileDownload == item.description ? this.state.clickFileDownload : false,
                                        loading: this.state.nomeFileDownload == item.description ? this.state.loadingFileDownload : 0,
                                        download: this.state.downloadFile,
                                        caption: this.state.captionArquivo
                                    },
                                    //size: "100MB",
                                }}
                                focus={item.idMensagem === this.state.focusMessageBox ? true : false}
                                onMessageFocused={() => { setTimeout(() => { this.setState({ focusMessageBox: 'vazio' }); }, 1000); }}
                                onDownload={event => this.baixarArquivo(item.description, item)}
                                onReplyClick={() => { this.renderResponderMensagem(item) }}
                                onReplyMessageClick={() => { this.renderClickButtonReply(item) }}
                                onForwardClick={() => {
                                    this._toggleModalEncaminharMensagem();
                                    let mensagemParaSerEncaminhada2 = [];
                                    item['keyChamado'] = this.props.dadosContactsSelecionado[0].key;
                                    mensagemParaSerEncaminhada2.push(item)
                                    this.setState({ mensagemParaSerEncaminhada: mensagemParaSerEncaminhada2 })
                                }}
                                //onClick={() => {  }}
                                //onOpen={() => {  }}
                                position={item.circleColor === '#00FF7F' ? 'right' : "left"}
                                type={'file'}
                                title={(
                                    <div
                                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                    >
                                        {item.title}
                                        {this.state.visualizaMensagemExcluida === true && item.deleteMessage === true && <span onClick={() => { this.setState({ visualizaMensagemExcluida: false }) }} style={{ color: '#EC6A69', fontSize: 10, textDecorationLine: 'underline', marginLeft: 10, fontStyle: 'italic', cursor: "pointer" }}>Ocultar</span>}
                                    </div>
                                )}
                                titleColor={item.circleColor === '#00FF7F' ? '#16cb9c' : "#39a3f4"}
                                text={item.description}
                                copiableDate
                                dateString={item.dataHoraAtual}
                                forwarded={item.statusMessage === 'waiting' ? false : true}
                                replyButton={item.statusMessage === 'waiting' ? false : true}
                                status={item.circleColor === '#39a3f4' ? null : item.statusMessage} //(waiting, sent, received, read)

                            />
                        </div>
                    )

                }
            } else {
                if (item.tipo === 'location') {
                    let dados = item.description.split(" - ");
                    /*

                      */
                    return (
                        <div key={item.key}>
                            <MessageBox

                                {...item}
                                id={item.key}
                                apiKey="AIzaSyDz1f3-p7gxOvHjVYnmt23VHuLCbZ0TS6s"
                                reply={this.renderMensagemReplyEmEspera(item)}
                                data={{
                                    // uri: item.urlDownload,
                                    /* status: {
                                        click: this.state.nomeFileDownload == item.description ? this.state.clickFileDownload : false,
                                        loading: this.state.nomeFileDownload == item.description ? this.state.loadingFileDownload : 0,
                                        download: this.state.downloadFile
                                    }, */
                                    // size: "100MB",
                                    latitude: dados[0],
                                    longitude: dados[1],

                                }}
                                focus={item.idMensagem === this.state.focusMessageBox ? true : false}
                                onMessageFocused={() => { setTimeout(() => { this.setState({ focusMessageBox: 'vazio' }); }, 1000); }}
                                onReplyClick={() => { this.renderResponderMensagem(item) }}
                                onReplyMessageClick={() => { this.renderClickButtonReply(item) }}
                                onForwardClick={() => {
                                    this._toggleModalEncaminharMensagem();
                                    let mensagemParaSerEncaminhada2 = [];
                                    item['keyChamado'] = this.props.dadosContactsSelecionado[0].key;
                                    mensagemParaSerEncaminhada2.push(item)
                                    this.setState({ mensagemParaSerEncaminhada: mensagemParaSerEncaminhada2 })
                                }}
                                //onClick={() => {  }}
                                //onOpen={() => {  }}
                                position={item.circleColor === '#00FF7F' ? 'right' : "left"}
                                type={'location'}
                                /*  onDownload={event => this.baixarArquivo(item.description, item)} */

                                title={(
                                    <div
                                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                    >
                                        {item.title}
                                        {this.state.visualizaMensagemExcluida === true && item.deleteMessage === true && <span onClick={() => { this.setState({ visualizaMensagemExcluida: false }) }} style={{ color: '#EC6A69', fontSize: 10, textDecorationLine: 'underline', marginLeft: 10, fontStyle: 'italic', cursor: "pointer" }}>Ocultar</span>}
                                    </div>
                                )}
                                titleColor={item.circleColor === '#00FF7F' ? '#16cb9c' : "#39a3f4"}
                                text={''}
                                copiableDate
                                dateString={item.dataHoraAtual}
                                forwarded={item.statusMessage === 'waiting' ? false : true}
                                replyButton={item.statusMessage === 'waiting' ? false : true}
                                status={item.circleColor === '#39a3f4' ? null : item.statusMessage}//(waiting, sent, received, read)

                            />
                            {/* < LocationMessage
                                key={item.key}
                                target="_blank"
                                data={{
                                     // latitude: dados[0],
                                    // longitude: dados[1],
                                    latitude: '37.773972',
                                    longitude: '-122.431297',
                                    // staticURL: '<optional>',
                                    // mapURL: '<optional>'
                                }
                                } /> */}
                        </div>
                    )
                } if (item.tipo === 'vcard') {
                    //let vcardMEssage2 = vCard.parse(item.description);
                    let card = vCard.parse(item.description);
                    return (
                        <div key={item.key}>

                            <MessageBox
                                /* avatar={imgTeste} */
                                {...item}
                                id={item.key}
                                reply={this.renderMensagemReplyEmEspera(item)}
                                onReplyClick={() => { this.renderResponderMensagem(item) }}
                                onReplyMessageClick={() => { this.renderClickButtonReply(item) }}
                                onForwardClick={() => {
                                    this._toggleModalEncaminharMensagem();
                                    let mensagemParaSerEncaminhada2 = [];
                                    item['keyChamado'] = this.props.dadosContactsSelecionado[0].key;
                                    mensagemParaSerEncaminhada2.push(item)
                                    this.setState({ mensagemParaSerEncaminhada: mensagemParaSerEncaminhada2 })
                                }}
                                focus={item.idMensagem === this.state.focusMessageBox ? true : false}
                                onMessageFocused={() => { setTimeout(() => { this.setState({ focusMessageBox: 'vazio' }); }, 1000); }}
                                /*  data={{
                                     uri: item.urlDownload,
                                     status: {
                                         click: this.state.nomeFileDownload == item.description ? this.state.clickFileDownload : false,
                                         loading: this.state.nomeFileDownload == item.description ? this.state.loadingFileDownload : 0,
                                         download: this.state.downloadFile
                                     },
                                     size: "100MB",
                                 }} */
                                /*       onReplyMessageClick={() => } */
                                //onClick={() => {  }}
                                //onOpen={() => {  }}
                                position={item.circleColor === '#00FF7F' ? 'right' : "left"}
                                type={'text'}
                                /* onDownload={event => this.baixarArquivo(item.description, item)} */

                                title={(
                                    <div
                                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                    >
                                        {item.title}
                                        {this.state.visualizaMensagemExcluida === true && item.deleteMessage === true && <span onClick={() => { this.setState({ visualizaMensagemExcluida: false }) }} style={{ color: '#EC6A69', fontSize: 10, textDecorationLine: 'underline', marginLeft: 10, fontStyle: 'italic', cursor: "pointer" }}>Ocultar</span>}
                                    </div>
                                )}
                                titleColor={item.circleColor === '#00FF7F' ? '#16cb9c' : "#39a3f4"}
                                /* text={(
                                    <p>{card.fn[0].value}</p>
                                )} */
                                text={(
                                    <div>
                                        {card.fn && card.fn.map(function (item, i) {
                                            return (
                                                <p key={i}>{item.value}</p>
                                            )
                                        })}
                                        {card.tel && card.tel.map(function (item, i) {
                                            return <p key={i}>{item.value}</p>
                                        })}
                                    </div>
                                )}
                                copiableDate
                                dateString={item.dataHoraAtual}
                                forwarded={item.statusMessage === 'waiting' ? false : true}
                                replyButton={item.statusMessage === 'waiting' ? false : true}
                                status={item.circleColor === '#39a3f4' ? null : item.statusMessage} //(waiting, sent, received, read)

                            />
                        </div>
                    )
                } else {
                    return (
                        <div key={item.key}>
                            <MessageBox
                                {...item}
                                id={item.key}
                                reply={this.renderMensagemReplyEmEspera(item)}
                                data={{
                                    uri: item.urlDownload,
                                    caption: item.captionArquivo,
                                    status: {
                                        click: this.state.nomeFileDownload == item.description ? this.state.clickFileDownload : false,
                                        loading: this.state.nomeFileDownload == item.description ? this.state.loadingFileDownload : 0,
                                        download: this.state.downloadFile,
                                        caption: this.state.captionArquivo
                                    },
                                    // size: "100MB",
                                }}
                                onForwardClick={() => {
                                    this._toggleModalEncaminharMensagem();
                                    let mensagemParaSerEncaminhada2 = [];
                                    item['keyChamado'] = this.props.dadosContactsSelecionado[0].key;
                                    mensagemParaSerEncaminhada2.push(item)
                                    this.setState({ mensagemParaSerEncaminhada: mensagemParaSerEncaminhada2 })
                                }}
                                focus={item.idMensagem === this.state.focusMessageBox ? true : false}
                                onMessageFocused={() => { setTimeout(() => { this.setState({ focusMessageBox: 'vazio' }); }, 1000); }}
                                onReplyMessageClick={() => { this.renderClickButtonReply(item) }}
                                onReplyClick={() => { this.renderResponderMensagem(item) }}
                                //onClick={() => {  }}
                                //onOpen={() => {  }}
                                position={item.circleColor === '#00FF7F' ? 'right' : "left"}
                                type={'file'}
                                onDownload={event => this.baixarArquivo(item.description, item)}
                                title={(
                                    <div
                                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                    >
                                        {item.title}
                                        {this.state.visualizaMensagemExcluida === true && item.deleteMessage === true && <span onClick={() => { this.setState({ visualizaMensagemExcluida: false }) }} style={{ color: '#EC6A69', fontSize: 10, textDecorationLine: 'underline', marginLeft: 10, fontStyle: 'italic', cursor: "pointer" }}>Ocultar</span>}
                                    </div>
                                )}
                                titleColor={item.circleColor === '#00FF7F' ? '#16cb9c' : "#39a3f4"}
                                text={item.description}
                                copiableDate
                                dateString={item.dataHoraAtual}
                                forwarded={item.statusMessage === 'waiting' ? false : true}
                                replyButton={item.statusMessage === 'waiting' ? false : true}
                                status={item.circleColor === '#39a3f4' ? null : item.statusMessage} //(waiting, sent, received, read)

                            />
                        </div>
                    )
                }


            }
        }


    }
    salvarComentarioEmEsperaComInicioDeAtendimento() {
        let comentario = this.nativeTextInput.value;
        if (comentario.trim() == '') {
            /*  toastr.error('Error', 'Digite uma mensagem'); */
        } else {
            this.nativeTextInput.value = '';
            this.props.ativaDesativaLoadingIniciandoCOnversaContactsExport(true);


            let arrayDadosParaComentar = ['', false, comentario, [], 1, 'keyChamado', 'dataAbertura', this.props.premium, this.props.keyUsuarioLogado, 'arrayAtendimentoCompleto', true, this.props.configWhatsapp, this.state.viewResponder, this.state.idResponder, this.state.commentResponder,
                this.state.circleColorResponder, this.state.commentResponderType, this.state.atendenteResponder, this.props.desativarAssistenteVirtualBotWpp, this.props.mostrarNomeAtendenteWpp];
            /*    let arrayDadosParaAtribuirChamado = [this.props.keyUsuarioLogado, 'keyChamado', this.props.nome, 'keySetor', 'arrayAtendimentoCompleto', true, this.props.configWhatsapp]; */

            this.verificaSeClienteJaNaoEstaEmAtendimento(this.props.dadosContactsSelecionado[0], this.props.verificaAgrupaAtendenteSetor).then(
                (value) => {
                    this.props.enviaUltimaReeferenciaKeyEmEsperaExport('');
                    this.props.enviaConversasCarregadasEmEsperaExport([]);
                    this.props.enviaUltimaReeferenciaKeyEmAtendimentoExport('');
                    this.props.enviaConversasCarregadasEmAtendimentoExport([]);
                    if (value === false) {
                        this.props.ativaDesativaLoadingIniciandoCOnversaContactsExport(false)
                    } else if (value === undefined) {
                        this.props.ativaDesativaLoadingIniciandoCOnversaContactsExport(false)
                    }
                    else if (value.statusChamado === 'Aberto') {
                        this.setState({ modalAtenderAtendimentoEmProcesso: true })
                        let arrayInfo = [];
                        arrayInfo.push(value)
                        this.setState({ dadosModalAtendimentoEmProcesso: arrayInfo })

                    } else if (value.statusChamado === 'Atendendo') {
                        toastr.warning('Error', `Este cliente já está em atendimento com o atendente "${value.atendente}", aguarde o término do atendimento para uma nova conversa`)
                        this.props.ativaDesativaLoadingIniciandoCOnversaContactsExport(false)
                    } else {

                        this.props.iniciaAtendimentoPorContacts(value.keyCliente, this.props.keyUsuarioLogado, this.props.nome, this.props.dadosContactsSelecionado[0].phone, false, '', '', '', '', arrayDadosParaComentar);
                        this.setState({ pesquisaConversasOuContatosEncaminharMensagem: '', pesquisaConversa: '' });
                        this.updateSearchEncaminhar('');
                        this.zeraMensagemResponder();
                        setTimeout(() => {
                            this.setState({ defaultTabIndex: 0 })
                        }, 1000);

                    }
                }
            );


        }
    }
    escolheArquivoChamadoEmEsperaComInicioDeAtendimento(res) {

        //
        if (this.props.textArquivoChat !== '') {
            toastr.error('Error ao Anexar', 'Tem arquivo sendo upado neste momento, por favor aguarde.');
        } else {
            if (res.length > 0) {
                //
                /*  for (let i = 0; i < res.length; i++) { */

                //
                if (this.props.premium == 0) {
                    toastr.error('Recurso Premium', 'Este é um recurso PREMIUM, para fazer upload de arquivos e imagens, Assine a versão PREMIUM e libere esse e mais recursos!');
                } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
                    toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
                }
                else {
                    this.props.ativaDesativaLoadingIniciandoCOnversaContactsExport(true);
                    let arrayDadosParaComentar = ['tem arquivo sim', false, '', res, 1, 'keyChamado', 'dataAbertura', this.props.premium, this.props.keyUsuarioLogado, 'arrayAtendimentoCompleto', true, this.props.configWhatsapp, this.state.viewResponder, this.state.idResponder, this.state.commentResponder,
                        this.state.circleColorResponder, this.state.commentResponderType, this.state.atendenteResponder, this.props.desativarAssistenteVirtualBotWpp, this.props.mostrarNomeAtendenteWpp];

                    /*  let arrayDadosParaComentar = ['', false, comentario, [], 1, 'keyChamado', 'dataAbertura', this.props.premium, this.props.keyUsuarioLogado, 'arrayAtendimentoCompleto', true, this.props.configWhatsapp, this.state.viewResponder, this.state.idResponder, this.state.commentResponder,
                         this.state.circleColorResponder, this.state.commentResponderType, this.state.atendenteResponder]; */


                    this.verificaSeClienteJaNaoEstaEmAtendimento(this.props.dadosContactsSelecionado[0], this.props.verificaAgrupaAtendenteSetor).then(
                        (value) => {
                            this.props.enviaUltimaReeferenciaKeyEmEsperaExport('');
                            this.props.enviaConversasCarregadasEmEsperaExport([]);
                            this.props.enviaUltimaReeferenciaKeyEmAtendimentoExport('');
                            this.props.enviaConversasCarregadasEmAtendimentoExport([]);
                            if (value === false) {
                                this.props.ativaDesativaLoadingIniciandoCOnversaContactsExport(false)
                            } else if (value === undefined) {
                                this.props.ativaDesativaLoadingIniciandoCOnversaContactsExport(false)
                            }
                            else if (value.statusChamado === 'Aberto') {
                                this.setState({ modalAtenderAtendimentoEmProcesso: true })
                                let arrayInfo = [];
                                arrayInfo.push(value)
                                this.setState({ dadosModalAtendimentoEmProcesso: arrayInfo })

                            } else if (value.statusChamado === 'Atendendo') {
                                toastr.warning('Error', `Este cliente já está em atendimento com o atendente "${value.atendente}", aguarde o término do atendimento para uma nova conversa`)
                                this.props.ativaDesativaLoadingIniciandoCOnversaContactsExport(false)
                            } else {

                                this.props.iniciaAtendimentoPorContacts(value.keyCliente, this.props.keyUsuarioLogado, this.props.nome, this.props.dadosContactsSelecionado[0].phone, false, '', '', '', '', arrayDadosParaComentar);
                                this.setState({ pesquisaConversasOuContatosEncaminharMensagem: '', pesquisaConversa: '' });
                                this.updateSearchEncaminhar('');
                                this.zeraMensagemResponder();
                                setTimeout(() => {
                                    this.setState({ defaultTabIndex: 0 })
                                }, 1000);

                            }
                        }
                    );


                }

            } else {
                toastr.error('Error ao Anexar', 'Selecione arquivo com no máximo 20MB');
            }
        }



    }
    /*  renderMensagemReplyEmEspera(item) {


         let nomeMostrar = this.props.atendimentosPorKeyReducerChatEmEspera === undefined ? :  this.props.atendimentosPorKeyReducerChatEmEspera.cliente
         if (item.possuiResposta) {

                 { this.state.commentResponder }
             return {
                 title: item.circleColorResponder === '#39a3f4' ? this.props.atendimentosPorKeyReducerChatEmEspera.cliente : item.atendenteResponder,
                 titleColor: item.circleColorResponder === '#00FF7F' ? '#16cb9c' : "#39a3f4",
                 message:
                     this.renderTextArquivosMensagemRespondidas(item)

             ,

 qrReadFail
             }
         } else {
             return null
         }


     } */
    renderSomenteConecta() {
        if (this.props.configWhatsapp.length > 0 && this.props.configWhatsapp[0].statusConnect === 'isLogout' || this.props.configWhatsapp.length > 0 && this.props.configWhatsapp[0].statusConnect === 'tokenRemoved' ||
            this.props.configWhatsapp.length > 0 && this.props.configWhatsapp[0].statusConnect === 'qrReadFail' || this.props.configWhatsapp.length > 0 && this.props.configWhatsapp[0].statusConnect === 'isDisconnected' ||
            this.props.configWhatsapp.length > 0 && this.props.configWhatsapp[0].statusConnect === 'serverDisconnected' || this.props.configWhatsapp[0].statusConnect === 'notLogged') {
            return (
                <p onClick={() => {
                    if (this.props.premium == 0) {
                        toastr.error('Error', 'Este é um recurso da versão PREMIUM,Adquira a versão PREMIUM e LIBERE esse e mais recursos.')

                    } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
                        toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
                    } else {
                        this.setState({ infoConectadoOk: false });
                        let diferencaDia = this.calculaDias2(this.props.dataCompra, this.props.dataValidoAte);
                        if (diferencaDia <= 31 && this.props.dataValidadeAtual === undefined) {
                            let dataAtual = this.retornaDataAtual();
                            let diasRestantesVersaoGratis = this.calculaDiasRestantesVersaoGratis(dataAtual, this.props.dataValidoAte);
                            let diasUsados = diferencaDia - diasRestantesVersaoGratis;
                            if (diasUsados > 7) {
                                toastr.error('Periodo Teste Esgotou', 'O período teste de 7 dias EXPIROU, Assine a Versão Premium para utilizar este recurso!');
                            } else {
                                this.toggleModalConectarWhatsapp();
                                if (this.props.configWhatsapp.length > 0) {
                                    this.somenteConectarContainer();
                                } else {
                                    //this.criaContainerVenom();
                                }
                            }
                        } else {
                            this.toggleModalConectarWhatsapp();
                            if (this.props.configWhatsapp.length > 0) {
                                this.somenteConectarContainer();
                            } else {
                                //this.criaContainerVenom();
                            }
                        }


                    }


                }} style={{ fontSize: 15, color: '#000', textDecoration: 'underline', cursor: 'pointer' }}>Clique aqui para Reconectar</p>
            )
        } else if (this.props.configWhatsapp.length > 0 && this.props.configWhatsapp[0].statusConnect === 'desconnectedMobile') {
            return (
                <p onClick={() => {
                    if (this.props.premium == 0) {
                        toastr.error('Error', 'Este é um recurso da versão PREMIUM,Adquira a versão PREMIUM e LIBERE esse e mais recursos.')

                    } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
                        toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
                    } else {
                        this.setState({ infoConectadoOk: false });
                        let diferencaDia = this.calculaDias2(this.props.dataCompra, this.props.dataValidoAte);
                        if (diferencaDia <= 31 && this.props.dataValidadeAtual === undefined) {
                            let dataAtual = this.retornaDataAtual();
                            let diasRestantesVersaoGratis = this.calculaDiasRestantesVersaoGratis(dataAtual, this.props.dataValidoAte);
                            let diasUsados = diferencaDia - diasRestantesVersaoGratis;
                            if (diasUsados > 7) {
                                toastr.error('Periodo Teste Esgotou', 'O período teste de 7 dias EXPIROU, Assine a Versão Premium para utilizar este recurso!');
                            } else {
                                this.toggleModalConectarWhatsapp();
                                if (this.props.configWhatsapp.length > 0) {
                                    this.somenteConectarContainer();
                                } else {
                                    //this.criaContainerVenom();
                                }
                            }
                        } else {
                            this.toggleModalConectarWhatsapp();
                            if (this.props.configWhatsapp.length > 0) {
                                this.somenteConectarContainer();
                            } else {
                                //this.criaContainerVenom();
                            }
                        }


                    }


                }} style={{ fontSize: 15, color: '#000', textDecoration: 'underline', cursor: 'pointer' }}>Clique aqui para Reconectar</p>
            )
        } else {
            return null
        }

    }
    renderTelaDesconectado() {
        if (this.props.configWhatsapp.length > 0 && this.props.configWhatsapp[0].statusConnect === 'desconnectedMobile' ||
            this.props.configWhatsapp.length > 0 && this.props.configWhatsapp[0].statusConnect === 'notLogged' ||
            this.props.configWhatsapp.length > 0 && this.props.configWhatsapp[0].statusConnect === 'autocloseCalled' ||
            this.props.configWhatsapp.length > 0 && this.props.configWhatsapp[0].statusConnect === 'deviceNotConnected' ||
            this.props.configWhatsapp.length > 0 && this.props.configWhatsapp[0].statusConnect === '' &&
            this.props.configWhatsapp.length > 0 && this.props.configWhatsapp[0].qrCode === '' ||
            this.props.configWhatsapp.length > 0 && this.props.configWhatsapp[0].statusConnect === 'noSync' ||
            this.props.configWhatsapp.length > 0 && this.props.configWhatsapp[0].statusConnect === 'isLogout' ||
            this.props.configWhatsapp.length > 0 && this.props.configWhatsapp[0].statusConnect === 'tokenRemoved' ||
            this.props.configWhatsapp.length > 0 && this.props.configWhatsapp[0].statusConnect === 'qrReadFail' ||
            this.props.configWhatsapp.length > 0 && this.props.configWhatsapp[0].statusConnect === 'isDisconnected' ||
            this.props.configWhatsapp.length > 0 && this.props.configWhatsapp[0].statusConnect === 'serverDisconnected') {
            return (
                <div style={{ display: 'flex', height: 107, flex: 1, backgroundColor: '#D7A53A', flexDirection: 'row', borderRightWidth: 1, borderRightColor: this.props.coresLetraSecundario }}>
                    <div style={{ display: 'flex', height: 107, width: 70, justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ display: 'flex', height: 50, width: 50, justifyContent: 'center', alignItems: 'center', borderRadius: '50%', backgroundColor: '#FFF' }}>
                            <i onClick={() => { }} className="icon-no-mobile-phones-allowed" style={{ fontSize: 16, color: '#D7A53A' }} />
                        </div>
                    </div>
                    <div style={{ display: 'flex', height: 107, flex: 1, justifyContent: 'space-around', alignItems: 'flex-start', padding: 10, flexDirection: 'column' }}>
                        <p style={{ fontSize: 15, fontWeight: 'bold', color: '#000' }}>WhatsApp Desconectado</p>
                        <p style={{ fontSize: 15, color: '#000' }}>Certifique-se de que seu celular está conectado á Internet, fecha o App WhatsApp e abra novamente.</p>
                        {this.renderSomenteConecta()}
                    </div>
                </div>
            )
        } else {
            return null
        }

    }
    escolheArquivoChamadoNovoCadastro(res) {
        if (res.length > 0) {
            //
            let uri;
            res.map(file => Object.assign(file, {
                uri: URL.createObjectURL(file)
            }));
            //
            this.props.modificaArquivoCompletoParaUparNovoContato(res[0])
            this.props.insereFotoCadastroContato(res[0].uri);
        } else {
            toastr.error('Error ao Anexar', 'Selecione uma foto com no máximo 5mb');

        }
    }
    exibeUltimosChamados() {
        if (this.props.chamadosEmEsperaAtendimentoOnline.length >= 25 && this.props.loadingAtendimentoViaChat == false && this.state.defaultTabIndex == 1) {
            this.props.listaOsUltimosChamadosEmEspera(this.props.keyReferenciaUltimoChamadoEmEspera, this.props.chamadosEmEsperaAtendimentoOnline, this.props.verificaAtendenteVeSomenteProprioAtendimento, this.props.verificaAgrupaAtendenteSetor, this.props.isAdmin, this.props.usuarioAdmVisualizaApenasChamadosDoSetorVinculado, this.props.atendimentosPorKeyReducerChatEmEsperaTotal)
        } else {
            //this.props.modificaloadingCarregandoChamadosEmEsperaRefreshControlExport(false)
        }


    }
    cadastraClienteContato(e) {
        e.preventDefault();
        //let telefoneContato = this.stripPhonenumber(this.props.telefoneContato)
        this.props.cadastraContato(this.props.nomeContato, this.props.nomeContato, this.props.telefoneContato, this.props.uriImagemPadraoTelaCadastroNovoContato, this.props.arquivoCompletoParaUparNovoContato, this.props.configWhatsapp)
    }
    renderLoadingConsultaSetor() {
        if (this.props.loadingAtendimentoViaChat === true) {
            return (
                <Container>
                    <SubContainer>
                        <ContainerSecundario>
                            <ContainerEmAtendimento bordaDinamica={this.props.coresLetraSecundario}>
                                <CabecalhoSecundario backgroundDinamico={this.props.backgroundSegundo}>
                                    <TextCabecalhoEmAtendimentoLoading backgroundinamico={this.props.backgroundSegundo}></TextCabecalhoEmAtendimentoLoading>
                                </CabecalhoSecundario>
                                <ContainerChamadosEmAtendimento style={{ alignItems: 'center', display: 'flex' }} backgroundDinamico={this.props.backgroundPrimeiro} bordaDinamica={this.props.backgroundMaisEscuro}>


                                    <ContainerChamadoLoading backgroundinamico={this.props.backgroundPrimeiro} >

                                    </ContainerChamadoLoading>
                                    <ContainerChamadoLoading backgroundinamico={this.props.backgroundPrimeiro} >

                                    </ContainerChamadoLoading>

                                </ContainerChamadosEmAtendimento>
                            </ContainerEmAtendimento>
                        </ContainerSecundario>
                        <ContainerPrincipal backgroundDinamico={this.props.backgroundSegundo}>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <CabecalhoPrincipalLoading style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} backgroundDinamico={this.props.backgroundSegundo}>
                                    < ImageLoadingPerfil backgroundinamico={this.props.backgroundSegundo} />
                                </CabecalhoPrincipalLoading>
                                <CabecalhoPrincipalLoading style={{ display: 'flex', flex: 1 }} backgroundDinamico={this.props.backgroundSegundo}>
                                    <TextCabecalhoPrincipalLoading backgroundinamico={this.props.backgroundSegundo}></TextCabecalhoPrincipalLoading>
                                    <TextCabecalhoPrincipalLoading backgroundinamico={this.props.backgroundSegundo}></TextCabecalhoPrincipalLoading>
                                </CabecalhoPrincipalLoading>
                            </div>

                            <ContainerBatepapo bordaDinamica={this.props.backgroundSegundo} backgroundDinamico={this.props.backgroundSegundo}>

                            </ContainerBatepapo>
                            {/* <ContainerEnviaMensagem bordaDinamica={this.props.backgroundMaisEscuro} backgroundDinamico={this.props.backgroundSegundo}>
                                <InputDescricaoChamadoLoading backgroundinamico={this.props.backgroundSegundo}></InputDescricaoChamadoLoading>

                            </ContainerEnviaMensagem> */}

                        </ContainerPrincipal>

                    </SubContainer>
                </Container>
            )
        } else if (this.props.utilizaChatAtendimentoOnline == false && this.props.configWhatsapp.length === 0) {
            return (
                <Container>
                    <div style={{ display: 'flex', position: 'relative', flex: 1, alignItems: 'center', justifyContent: 'space-around', flexDirection: "column" }}>
                        <p style={{ fontSize: 29, color: this.props.coresLetraPadrao, fontWeight: 'bold', marginBottom: 20 }}>De Qual Lugar Deseja começar a Atender?</p>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                            <ButtonWhatsApp onClick={() => {
                                if (this.props.premium == 0) {
                                    toastr.error('Error', 'Este é um recurso da versão PREMIUM,Adquira a versão PREMIUM e LIBERE esse e mais recursos.')

                                } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
                                    toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
                                } else {
                                    /*      if (this.props.dataCompra != '' && this.props.dataValidoAte != '' && this.props.premium == 1) { */
                                    let diferencaDia = this.calculaDias2(this.props.dataCompra, this.props.dataValidoAte);
                                    if (diferencaDia <= 31 && this.props.dataValidadeAtual === undefined) {
                                        let dataAtual = this.retornaDataAtual();
                                        let diasRestantesVersaoGratis = this.calculaDiasRestantesVersaoGratis(dataAtual, this.props.dataValidoAte);
                                        let diasUsados = diferencaDia - diasRestantesVersaoGratis;
                                        if (diasUsados > 7) {
                                            toastr.error('Periodo Teste Esgotou', 'O período teste de 7 dias EXPIROU, Assine a Versão Premium para utilizar este recurso!');
                                        } else {
                                            this.toggleModalConectarWhatsapp();
                                            if (this.props.configWhatsapp.length > 0) {
                                                this.somenteConectarContainer();
                                            } /* else if (this.props.configWhatsapp.length > 0 && this.props.configWhatsapp[0].statusConnect === 'notLogged') {
                                                //nao faz nada, ja esta em periodo de exibicao do token
                                            } */ else {
                                                this.criaContainerVenom();
                                            }
                                        }
                                    } else {
                                        this.toggleModalConectarWhatsapp();
                                        if (this.props.configWhatsapp.length > 0) {
                                            this.somenteConectarContainer();
                                        } /* else if (this.props.configWhatsapp.length > 0 && this.props.configWhatsapp[0].statusConnect === 'notLogged') {
                                            //nao faz nada, ja esta em periodo de exibicao do token
                                        } */ else {
                                            this.criaContainerVenom();
                                        }
                                    }

                                    /* } */
                                }





                            }} backgrounddinamico={this.props.backgroundPrimeiro} borderColorDinamico={this.props.coresLetraPadrao} corLetraDinamica={this.props.coresLetraPadrao}>
                                <img src={wppimage} alt="whatsapp" style={{
                                    height: 40, width: 40, marginRight: 10
                                }} />
                                WhatsApp
                                <div style={{ width: 20 }}></div>
                            </ButtonWhatsApp>
                            <ButtonLinkCfg to="/configuracao" onClick={() => {

                                this.props.modificaClicouCFGchat(true)
                            }} backgrounddinamico={this.props.backgroundPrimeiro} borderColorDinamico={this.props.coresLetraPadrao} corLetraDinamica={this.props.coresLetraPadrao}>
                                <img src={chatimage} alt="chat" style={{
                                    height: 40, width: 40, marginRight: 10
                                }} />
                                Chat Online
                                <div style={{ width: 20 }}></div>
                            </ButtonLinkCfg>

                        </div>
                    </div>
                </Container>
            )
        } else if (
            this.props.utilizaChatAtendimentoOnline == false && this.props.configWhatsapp.length > 0 && this.props.configWhatsapp[0].statusConnect === 'notLogged' ||
            this.props.utilizaChatAtendimentoOnline == false && this.props.configWhatsapp.length > 0 && this.props.configWhatsapp[0].statusConnect === 'browserClose' ||
            this.props.utilizaChatAtendimentoOnline == false && this.props.configWhatsapp.length > 0 && this.props.configWhatsapp[0].statusConnect === 'autocloseCalled' ||
            this.props.utilizaChatAtendimentoOnline == false && this.props.configWhatsapp.length > 0 && this.props.configWhatsapp[0].statusConnect === 'qrReadFail' ||
            this.props.utilizaChatAtendimentoOnline == false && this.props.configWhatsapp.length > 0 && this.props.configWhatsapp[0].statusConnect === 'serverClose' ||
            this.props.utilizaChatAtendimentoOnline == false && this.props.configWhatsapp.length > 0 && this.props.configWhatsapp[0].statusConnect === 'connecting' ||
            this.props.utilizaChatAtendimentoOnline == false && this.props.configWhatsapp.length > 0 && this.props.configWhatsapp[0].statusConnect === 'deleteToken'

            // || this.props.utilizaChatAtendimentoOnline == false && this.props.configWhatsapp.length > 0 && this.props.configWhatsapp[0].statusConnect === 'desconnectedMobile'
        ) {
            return (
                <Container>
                    <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'space-around', flexDirection: "column" }}>
                        <p style={{ fontSize: 29, color: this.props.coresLetraPadrao, fontWeight: 'bold', marginBottom: 20 }}>De Qual Lugar Deseja começar a Atender?</p>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                            <ButtonWhatsApp onClick={() => {
                                if (this.props.premium == 0) {
                                    toastr.error('Error', 'Este é um recurso da versão PREMIUM,Adquira a versão PREMIUM e LIBERE esse e mais recursos.')

                                } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
                                    toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
                                } else {
                                    /*      if (this.props.dataCompra != '' && this.props.dataValidoAte != '' && this.props.premium == 1) { */
                                    let diferencaDia = this.calculaDias2(this.props.dataCompra, this.props.dataValidoAte);
                                    if (diferencaDia <= 31 && this.props.dataValidadeAtual === undefined) {
                                        let dataAtual = this.retornaDataAtual();
                                        let diasRestantesVersaoGratis = this.calculaDiasRestantesVersaoGratis(dataAtual, this.props.dataValidoAte);
                                        let diasUsados = diferencaDia - diasRestantesVersaoGratis;
                                        if (diasUsados > 7) {
                                            toastr.error('Periodo Teste Esgotou', 'O período teste de 7 dias EXPIROU, Assine a Versão Premium para utilizar este recurso!');
                                        } else {
                                            this.toggleModalConectarWhatsapp();
                                            if (this.props.configWhatsapp.length > 0) {
                                                this.somenteConectarContainer();
                                            } /* else if (this.props.configWhatsapp.length > 0 && this.props.configWhatsapp[0].statusConnect === 'notLogged') {
                                                //nao faz nada, ja esta em periodo de exibicao do token
                                            } */ else {
                                                this.criaContainerVenom();
                                            }
                                        }
                                    } else {
                                        this.toggleModalConectarWhatsapp();
                                        if (this.props.configWhatsapp.length > 0) {
                                            this.somenteConectarContainer();
                                        } /* else if (this.props.configWhatsapp.length > 0 && this.props.configWhatsapp[0].statusConnect === 'notLogged') {
                                            //nao faz nada, ja esta em periodo de exibicao do token
                                        } */ else {
                                            this.criaContainerVenom();
                                        }
                                    }

                                    /* } */
                                }


                            }} backgrounddinamico={this.props.backgroundPrimeiro} borderColorDinamico={this.props.coresLetraPadrao} corLetraDinamica={this.props.coresLetraPadrao}>
                                <img src={wppimage} alt="whatsapp" style={{
                                    height: 40, width: 40, marginRight: 10
                                }} />
                                WhatsApp
                                <div style={{ width: 20 }}></div>
                            </ButtonWhatsApp>
                            <ButtonLinkCfg to="/configuracao" onClick={() => {

                                this.props.modificaClicouCFGchat(true)
                            }} backgrounddinamico={this.props.backgroundPrimeiro} borderColorDinamico={this.props.coresLetraPadrao} corLetraDinamica={this.props.coresLetraPadrao}>
                                <img src={chatimage} alt="chat" style={{
                                    height: 40, width: 40, marginRight: 10
                                }} />
                                Chat Online
                                <div style={{ width: 20 }}></div>
                            </ButtonLinkCfg>

                        </div>
                    </div>
                </Container>
            )
        } else {
            return (
                <Container>
                    <SubContainer>
                        <ContainerConversas bordaDinamica={this.props.coresLetraSecundario} backgrounddinamico={this.props.backgroundPrimeiro}>
                            {this.renderTelaDesconectado()}
                            <DivPesquisaConversas backgrounddinamico={this.props.backgroundSegundo}>
                                <ContainerInputConversa
                                    corLetraDinamico={this.props.coresLetraPadrao}
                                    backgroundinamico={this.props.backgroundPrimeiro}
                                    placeholderColor={this.props.coresLetraPadrao}
                                    corBordaDinamica={this.props.corBotaoPadrao}
                                >
                                    <i data-tip={"Pesquisar pelo nome do cliente"} data-for="tip-top" onClick={() => { this.inputPesquisa.focus() }} className="icon-magnifier" style={{ fontSize: 13, color: this.props.coresLetraPadrao, cursor: 'pointer' }} />
                                    <InputPesquisaConversa
                                        ref={ref => this.inputPesquisa = ref}
                                        corLetraDinamico={this.props.coresLetraPadrao}
                                        backgroundinamico={this.props.backgroundPrimeiro}
                                        placeholderColor={this.props.coresLetraPadrao}
                                        corBordaDinamica={this.props.corBotaoPadrao}
                                        type="text"
                                        placeholder={this.props.configWhatsapp !== undefined && this.props.configWhatsapp.length > 0 ? 'Pesquisar Conversas' : 'Pesquisar Atendimentos'}
                                        // onChange={(value) => this.updateSearch(value.target.value)}
                                        onChange={(value) => {
                                            this.setState({ pesquisaConversa: value.target.value });
                                            this.props.ativaDesativaLoadingCarregaContatosWppExport(true);
                                            this.searchDebounce(value.target.value, true);
                                        }}
                                        value={this.state.pesquisaConversa}
                                    />
                                    {this.props.configWhatsapp !== undefined && this.props.configWhatsapp.length > 0 && <i data-tip={"Iniciar uma nova conversa"} data-for="tip-top" onClick={() => {
                                        this.setState({ defaultTabIndex: 2 });
                                        this.inputPesquisa.focus();
                                        if (this.props.loadingCarregaContatosWpp === false && this.props.allContactsWhatsapp.length === 0) {
                                            this.props.getAllContatcsWpp(this.props.configWhatsapp, this.props.allClientes)
                                        }
                                    }} className="icon-comment2" style={{ fontSize: 15, color: this.props.corBotaoPadrao, marginRight: 5, cursor: 'pointer' }} />}
                                    {this.props.configWhatsapp !== undefined && this.props.configWhatsapp.length > 0 && <i data-tip={"Adicionar Contato"} data-for="tip-top" onClick={() => { this.props.modificaVisibilidadeModalAdicionarContato(true); }} className="icon-new-user2" style={{ fontSize: 17, color: this.props.corBotaoPadrao, cursor: 'pointer', marginRight: 5 }} />}
                                </ContainerInputConversa>

                            </DivPesquisaConversas>
                            <TabsModified
                                backgrounddinamico={this.props.coresLetraPadrao}
                                selectedIndex={this.state.defaultTabIndex}
                                //forceRenderTabPanel
                                onSelect={index => {
                                    this.setState({ defaultTabIndex: index, pesquisaConversa: '' });
                                    if (index === 2 && this.props.loadingCarregaContatosWpp === false && this.props.allContactsWhatsapp.length === 0) {
                                        this.props.getAllContatcsWpp(this.props.configWhatsapp, this.props.allClientes)
                                    } else {
                                        if (index !== 2) {
                                            this.setState({
                                                limiteContato: 20, limiteContatoPesquisado: 20, loadingexibeContatoPesquisado: true,
                                                loadingexibeContato: true
                                            })
                                            this.props.enviaContatosParaReducerExport([])
                                        }
                                    }

                                }}
                            >
                                <TabListModified style={{
                                    backgroundColor: this.props.backgroundSegundo, color: this.props.coresLetraPadrao,

                                }}>
                                    <TabModified tabIndex={'0'} coriconedinamicoselecionado={this.props.corBotaoPadrao}  >

                                        {this.renderIconeAudioEmAtendimento()}
                                        <TextTab colorDinamico={this.props.coresLetraPadrao}>CONVERSAS</TextTab>
                                        {this.renderDivCountChamadoEmAtendimento()}
                                    </TabModified>
                                    <TabModified tabIndex={'1'} coriconedinamicoselecionado={this.props.corBotaoPadrao}  >
                                        {this.rendericoneAudioEmEspera()}
                                        {this.renderSoundEmEspera()}
                                        <TextTab colorDinamico={this.props.coresLetraPadrao}>ESPERA </TextTab>

                                        {this.renderDivCountChamadoEspera()}
                                    </TabModified>
                                    {this.props.configWhatsapp !== undefined && this.props.configWhatsapp.length > 0 && <TabModified tabIndex={'2'} coriconedinamicoselecionado={this.props.corBotaoPadrao} >
                                        {/* <IconeTab className="icon-chamadofechado2" colorDinamico={this.props.coresLetraPadrao} /> */}
                                        <TextTab colorDinamico={this.props.coresLetraPadrao}>CONTATOS</TextTab>
                                    </TabModified>}

                                </TabListModified>
                                <ContainerFlatListOverFlow backgrounddinamico={this.props.coresLetraPadrao}>
                                    <TabPanelModified forceRender>
                                        <ContainerFlatListOverFlow backgrounddinamico={this.props.coresLetraPadrao}>
                                            {this.props.loadingCarregaChatsEmAtendimento === false && this.state.pesquisaConversa === '' &&
                                                <AutoSizer>
                                                    {({ height, width }) => (
                                                        <List
                                                            // sortBy={"lastMessageChatTimeStamp"}
                                                            // sortDirection={"descending"}
                                                            // list={}
                                                            height={height}
                                                            rowHeight={60}
                                                            width={width}
                                                            overscanRowCount={10}
                                                            rowCount={this.props.chamadosEmAtendimentoOnline.length}
                                                            rowRenderer={({ key, index, style, parent }) => {
                                                                const item = this.props.chamadosEmAtendimentoOnline[index];
                                                                return (
                                                                    <div key={key} style={style} parent={parent}>
                                                                        <FlatListEmAtendimento data={item} />
                                                                    </div>
                                                                )

                                                            }}
                                                        />
                                                    )}
                                                </AutoSizer>

                                                /*  <FlatList
                                                      list={this.props.chamadosEmAtendimentoOnline}
                                                      //  list={this.props.chamadosEmAtendimentoOnline.sort((a, b) => { return b.lastMessageDateTimeStamp - a.lastMessageDateTimeStamp })}
                                                      //  sortBy={["lastMessageDateTimeStamp", {descending: true}]}
                                                      renderItem={(item) => <FlatListEmAtendimento data={item} key={item.key} />}
                                                      // filterBy={item => (typeof item.lastMessageDateTimeStamp === 'number')}
                                                      sortBy={[{ key: "lastMessageChatTimeStamp", descending: true }]}
                                                      renderWhenEmpty={() => {
                                                          return null

                                                      }} /> */

                                            }



                                            {this.props.loadingCarregaChatsEmAtendimento === false &&
                                                this.state.pesquisaConversa !== '' &&
                                                this.state.noDataAtendimento === false &&
                                                <AutoSizer>
                                                    {({ height, width }) => (
                                                        <List
                                                            // sortBy={"lastMessageChatTimeStamp"}
                                                            // sortDirection={"descending"}
                                                            // list={}
                                                            height={height}
                                                            rowHeight={60}
                                                            width={width}
                                                            overscanRowCount={10}
                                                            rowCount={this.state.resultadosPesquisaAtendimentos.length}
                                                            rowRenderer={({ key, index, style, parent }) => {
                                                                const item = this.state.resultadosPesquisaAtendimentos[index];
                                                                return (
                                                                    <div key={key} style={style} parent={parent}>
                                                                        <FlatListEmAtendimento data={item} />
                                                                    </div>
                                                                )

                                                            }}
                                                        />
                                                    )}
                                                </AutoSizer>
                                                /*   <FlatList
                                                    list={this.state.resultadosPesquisaAtendimentos}
                                                    renderItem={(item) => <FlatListEmAtendimento data={item} key={item.key} />}
                                                    renderWhenEmpty={() => {
                                                        return null

                                                    }}
                                                /> */
                                            }
                                            {this.props.loadingCarregaChatsEmAtendimento === false && this.state.pesquisaConversa !== '' && this.state.noDataAtendimento === true &&
                                                <div style={{ display: 'flex', flex: 1, width: '100%', height: 100, alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                                    <p style={{ fontSize: 17, color: this.props.coresLetraPadrao, margin: 15, fontWeight: 'bold' }}>Nenhum Resultado encontrado na pesquisa</p>
                                                </div>
                                            }

                                            {
                                                this.props.loadingCarregaChatsEmAtendimento === true &&
                                                <div style={{ display: 'flex', flex: 1, width: '100%', height: 100, alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                                    <p style={{ fontSize: 17, color: this.props.coresLetraPadrao, marginTop: 15, fontWeight: 'bold' }}>Carregando Atendimentos</p>
                                                    <HashLoader
                                                        css={{ marginTop: 15 }}
                                                        size={60}
                                                        color={this.props.corBotaoPadrao}
                                                        loading
                                                    />
                                                </div>
                                            }

                                        </ContainerFlatListOverFlow >
                                    </TabPanelModified>
                                    <TabPanelModified forceRender>
                                        <ContainerFlatListOverFlow backgrounddinamico={this.props.coresLetraPadrao}>
                                            {this.props.loadingAtendimentoViaChat === false && this.state.pesquisaConversa === '' &&
                                                <FlatList
                                                    list={this.props.chamadosEmEsperaAtendimentoOnline}
                                                    renderItem={(item, index) => <FlatListEmEspera data={item} key={item.key} />}
                                                    hasMoreItems={this.props.loadingCarregandoChamadosEmEsperaRefreshControl}
                                                    loadMoreItems={() => this.exibeUltimosChamados()}
                                                    paginationLoadingIndicator={
                                                        <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                                                            <MoonLoader
                                                                css={{ margin: 10 }}
                                                                size={40}
                                                                color={this.props.corBotaoPadrao}
                                                                loading={this.props.loadingCarregandoChamadosEmEsperaRefreshControl}
                                                            />
                                                        </div>}
                                                    renderWhenEmpty={() => {
                                                        return null

                                                    }}
                                                />}
                                            {this.props.loadingAtendimentoViaChat === false && this.state.pesquisaConversa !== '' && this.state.noDataEspera === false && <FlatList
                                                list={this.state.resultadosPesquisaEspera}
                                                renderItem={(item, index) => <FlatListEmEspera data={item} key={item.key} />}
                                                renderWhenEmpty={() => {
                                                    return null

                                                }}
                                            />}
                                            {this.props.loadingAtendimentoViaChat === false && this.state.pesquisaConversa !== '' && this.state.noDataEspera === true &&
                                                <div style={{ display: 'flex', flex: 1, width: '100%', height: 100, alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                                    <p style={{ fontSize: 17, color: this.props.coresLetraPadrao, margin: 15, fontWeight: 'bold' }}>Nenhum Resultado encontrado na pesquisa</p>
                                                </div>
                                            }


                                            {
                                                this.props.loadingAtendimentoViaChat === true &&
                                                <div style={{ display: 'flex', flex: 1, width: '100%', height: 100, alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                                    <p style={{ fontSize: 17, color: this.props.coresLetraPadrao, marginTop: 15, fontWeight: 'bold' }}>Carregando Atendimentos</p>
                                                    <HashLoader
                                                        css={{ marginTop: 15 }}
                                                        size={60}
                                                        color={this.props.corBotaoPadrao}
                                                        loading
                                                    />
                                                </div>
                                            }
                                        </ContainerFlatListOverFlow>
                                    </TabPanelModified>

                                    <TabPanelModified forceRender>
                                        {/*   <ComponentAllContacts data={this.props.allContactsWhatsapp} /> */}
                                        <ContainerFlatListOverFlow backgrounddinamico={this.props.coresLetraPadrao}>
                                            {this.props.loadingCarregaContatosWpp === false
                                                &&
                                                this.props.loadingAssinalaErrorGetContatcs === false &&
                                                this.state.pesquisaConversa === '' &&
                                                <AutoSizer>
                                                    {({ height, width }) => (
                                                        <List
                                                            // sortBy={"lastMessageChatTimeStamp"}
                                                            // sortDirection={"descending"}
                                                            // list={}
                                                            height={height}
                                                            rowHeight={60}
                                                            width={width}
                                                            overscanRowCount={10}
                                                            rowCount={this.props.allContactsWhatsapp.length}
                                                            rowRenderer={({ key, index, style, parent }) => {
                                                                const item = this.props.allContactsWhatsapp[index];
                                                                return (
                                                                    <div key={key} style={style} parent={parent}>
                                                                        <FlatListAllContacts data={item} />
                                                                    </div>
                                                                )

                                                            }}
                                                        />
                                                    )}
                                                </AutoSizer>
                                                /*  <FlatList
                                                    list={this.props.allContactsWhatsapp}
                                                    renderItem={(item) => <FlatListAllContacts data={item} key={item.key + item.name} />}
                                                    renderWhenEmpty={() => {
                                                        return null

                                                    }}
                                                    limit={this.state.limiteContato}
                                                    hasMoreItems={this.state.loadingexibeContato}
                                                    loadMoreItems={() => {
                                                        if (this.state.limiteContato === this.props.allContactsWhatsapp.length) {
                                                            this.setState({ loadingexibeContato: false })
                                                        } else {
                                                            this.setState({ loadingexibeContato: true, limiteContato: this.state.limiteContato + 20 })
                                                        }
                                                    }}
                                                    paginationLoadingIndicator={
                                                        <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                                                            <MoonLoader
                                                                css={{ margin: 10 }}
                                                                size={40}
                                                                color={this.props.corBotaoPadrao}
                                                                loading={true}
                                                            />
                                                        </div>}
                                                /> */

                                            }
                                            {this.props.loadingCarregaContatosWpp === false &&
                                                this.props.loadingAssinalaErrorGetContatcs === false &&
                                                this.state.pesquisaConversa !== '' &&
                                                this.state.noDataContacts === false &&

                                                <AutoSizer>
                                                    {({ height, width }) => (
                                                        <List
                                                            // sortBy={"lastMessageChatTimeStamp"}
                                                            // sortDirection={"descending"}
                                                            // list={}
                                                            height={height}
                                                            rowHeight={60}
                                                            width={width}
                                                            overscanRowCount={10}
                                                            rowCount={this.state.resultadosPesquisaContacts.length}
                                                            rowRenderer={({ key, index, style, parent }) => {
                                                                const item = this.state.resultadosPesquisaContacts[index];
                                                                return (
                                                                    <div key={key} style={style} parent={parent}>
                                                                        <FlatListAllContacts data={item} />
                                                                    </div>
                                                                )

                                                            }}
                                                        />
                                                    )}
                                                </AutoSizer>
                                                /*  <FlatList
                                                     list={this.state.resultadosPesquisaContacts}
                                                     renderItem={(item) => <FlatListAllContacts data={item} key={item.key + item.name} />}
                                                     renderWhenEmpty={() => {
                                                         return null

                                                     }}
                                                     limit={this.state.limiteContatoPesquisado}
                                                     hasMoreItems={this.state.loadingexibeContatoPesquisado}
                                                     loadMoreItems={() => {
                                                         if (this.state.limiteContatoPesquisado === this.props.allContactsWhatsapp.length) {
                                                             this.setState({ loadingexibeContatoPesquisado: false })
                                                         } else {
                                                             this.setState({ loadingexibeContatoPesquisado: true, limiteContatoPesquisado: this.state.limiteContatoPesquisado + 20 })
                                                         }
                                                     }}
                                                     paginationLoadingIndicator={
                                                         <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                                                             <MoonLoader
                                                                 css={{ margin: 10 }}
                                                                 size={40}
                                                                 color={this.props.corBotaoPadrao}
                                                                 loading={true}
                                                             />
                                                         </div>}

                                                 /> */

                                            }
                                            {this.props.loadingCarregaContatosWpp === false && this.props.loadingAssinalaErrorGetContatcs === false && this.state.pesquisaConversa !== '' && this.state.noDataContacts === true &&
                                                <div style={{ display: 'flex', flex: 1, width: '100%', height: 100, alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                                    <p style={{ fontSize: 17, color: this.props.coresLetraPadrao, margin: 15, fontWeight: 'bold' }}>Nenhum Contato encontrado na pesquisa</p>
                                                </div>
                                            }

                                            {
                                                this.props.loadingCarregaContatosWpp === true && this.props.loadingAssinalaErrorGetContatcs === false &&
                                                this.state.modalEncaminharMensagem === false &&
                                                <div style={{ display: 'flex', flex: 1, width: '100%', height: 100, alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                                    <p style={{ fontSize: 17, color: this.props.coresLetraPadrao, marginTop: 15, fontWeight: 'bold' }}>Carregando Contatos ...</p>
                                                    <HashLoader
                                                        css={{ marginTop: 15 }}
                                                        size={60}
                                                        color={this.props.corBotaoPadrao}
                                                        loading
                                                    />
                                                </div>
                                            }
                                            {
                                                this.props.loadingCarregaContatosWpp === false && this.props.loadingAssinalaErrorGetContatcs === true &&
                                                <div style={{ display: 'flex', flex: 1, width: '100%', height: 100, alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                                    <p style={{ fontSize: 17, color: this.props.coresLetraPadrao, margin: 15, fontWeight: 'bold' }}>{/* <span style={{color:'#EC6A69'}}> */}Error{/* </span> */} ao Carregar os Contatos, espere alguns minutos e clique em Tentar Novamente</p>
                                                    <ButtonIniciarAtendimento backgrounddinamico={this.props.corBotaoPadrao} corLetraDinamica={this.props.letraBotaoPadrao} onClick={() => {
                                                        this.props.getAllContatcsWpp(this.props.configWhatsapp, this.props.allClientes)
                                                    }}>
                                                        Tentar Novamente
                                                    </ButtonIniciarAtendimento>
                                                </div>
                                            }
                                        </ContainerFlatListOverFlow>
                                    </TabPanelModified>
                                </ContainerFlatListOverFlow>
                            </TabsModified>
                        </ContainerConversas>
                        {this.renderConteudoCabecalhoBatePapo()}

                        {this.renderSelecionaChamado()}

                        {/* <ContainerSecundario>
                            <ContainerEmAtendimento bordaDinamica={this.props.backgroundMaisEscuro}>
                                <CabecalhoSecundario backgroundDinamico={this.props.backgroundMaisEscuro}>
                                    <p style={{ fontSize: 17, color: this.props.coresLetraPadrao, fontWeight: 'bold' }}>Em Atendimento</p>
                                    {this.renderIconeAudioEmAtendimento()}
                                </CabecalhoSecundario>
                                <ContainerChamadosEmAtendimento backgroundDinamico={this.props.backgroundSegundo} bordaDinamica={this.props.backgroundMaisEscuro}> */}
                        {/*   <FlatList
                                    list={this.props.chamadosEmAtendimentoOnline}
                                    renderItem={(item) => <FlatListEmAtendimento data={item} key={item.key + item.alterado + item.newmessage} /> }
                                    renderWhenEmpty={() => {
                                        return null

                                    }}
                                />  */}
                        {/*  {this.props.chamadosEmAtendimentoOnline.map((item) => */}
                        {/*   <FlatList
                                        list={this.props.chamadosEmAtendimentoOnline}
                                        renderItem={(item) => <FlatListEmAtendimento data={item} key={item.key + item.alterado} />}
                                        renderWhenEmpty={() => {
                                            return null

                                        }} /> */}
                        {/* <FlatList
                                        list={this.props.chamadosEmAtendimentoOnline}
                                        renderItem={(item) =>
                                            <ContainerChamado key={item.key} onClick={() => {
                                                this.props.modificaKeyAtendimentoEmAtendimentoOnline(item.key);
                                            }} bordadinamica={this.props.coresLetraSecundario} backGroundDinamico={item.key == this.props.keyAtendimentoEmAtendimentoOnline ? shade(0.3, this.props.backgroundSegundo) : this.props.backgroundSegundo} type="button" >
                                                {item.key == this.props.keyAtendimentoEmAtendimentoOnline && <div style={{ width: 5, backgroundColor: this.props.corBotaoPadrao, height: 58, borderTopLeftRadius: 7, borderBottomLeftRadius: 7 }}></div>}
                                                <ContainerImagem>
                                                    <img src={atendendochat} alt="foto cliente" style={{ height: 40, width: 40, borderRadius: 50 }} />

                                                </ContainerImagem>
                                                <ContainerDadosChamado bordadinamica={this.props.coresLetraSecundario}>
                                                    <ContaineDadosChamadosPrincipal>
                                                        <SubContainerIconeEFraseClienteAtendente >
                                                            <TextCliente corLetraDinamica={this.props.coresLetraPadrao}>{item.cliente}</TextCliente>
                                                        </SubContainerIconeEFraseClienteAtendente>
                                                        <SubContainerIconeEFraseClienteAtendente >
                                                            <TextEmpresa corLetraDinamica={this.props.coresLetraSecundario}>{item.empresa}</TextEmpresa>
                                                        </SubContainerIconeEFraseClienteAtendente>
                                                    </ContaineDadosChamadosPrincipal>
                                                    <ContainerSeta>
                                                        <p style={{ fontSize: 12, color: this.props.coresLetraSecundario }}>{this.renderHoraMinuto(item.dataHoraAbertura)}</p>

                                                        {item.newmessage == true &&

                                                            <DivCountNotif>
                                                                <i className="icon-envelope" style={{ fontSize: 10, color: '#FFF' }} />
                                                            </DivCountNotif>}
                                                        {item.newmessage == false && <div>
                                                        </div>}


                                                    </ContainerSeta>

                                                </ContainerDadosChamado>


                                            </ContainerChamado>}
                                        renderWhenEmpty={() => {
                                            return null

                                        }}
                                    /> */}

                        {/* } */}
                        {/* </ContainerChamadosEmAtendimento>
                            </ContainerEmAtendimento>
                            <ContainerEmEspera bordaDinamica={this.props.backgroundMaisEscuro}>
                                <CabecalhoSecundarioEmEspera backgroundDinamico={this.props.backgroundMaisEscuro} >
                                    <div style={{ fontSize: 17, color: this.props.coresLetraPadrao, fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                                        {this.renderDivCountChamadoEspera()} Em Espera {this.rendericoneAudioEmEspera()}
                                    </div>
                                    {this.renderBotaoAtender()}
                                </CabecalhoSecundarioEmEspera>
                                <ContainerChamadosEmEspera backgroundDinamico={this.props.backgroundSegundo} bordaDinamica={this.props.backgroundMaisEscuro}>
                                    <FlatList
                                        list={this.props.chamadosEmEsperaAtendimentoOnline}
                                        renderItem={(item) => <FlatListEmEspera data={item} key={item.key + item.alterado} />}
                                        renderWhenEmpty={() => {
                                            return null

                                        }}
                                    />
                                </ContainerChamadosEmEspera>
                            </ContainerEmEspera>
                        </ContainerSecundario> */}
                    </SubContainer>
                </Container >
            )
            /* } */
        }
    }
    searchTextContatos = async (digitoPesquisa) => {
        if (this.state.defaultTabIndex === 0) {

            let text = digitoPesquisa.toLowerCase();
            let trucks = this.props.chamadosEmAtendimentoOnline;
            let filteredName = trucks.filter((item) => {
                return item.cliente.toLowerCase().match(text)
            })
            if (!text || text === '') {
                /*  this.setState({
                   data: initial
                 }) */
            } else if (filteredName.length == 0) {
                // set no data flag to true so as to render flatlist conditionally
                this.setState({
                    noDataAtendimento: true
                })
            } else if (Array.isArray(filteredName)) {
                this.setState({
                    noDataAtendimento: false,
                    resultadosPesquisaAtendimentos: filteredName
                })
            }
        }
        else if (this.state.defaultTabIndex === 2) {
            const text = digitoPesquisa.toLowerCase();
            let filteredName = [];
            const contactsSearch = await loadContacts(digitoPesquisa, 0);
            const mergedContacts = [...contactsSearch, ...this.props.allContactsWhatsapp];
            const contacts = removeItemsDuplicateFromListByValue(mergedContacts, 'phone');
            filteredName = contacts.filter((item) => {
                return item.nome.toLowerCase().match(text) || item.phone.toLowerCase().match(text)
            })



            if (!text || text === '') {

                this.setState({
                    limiteContato: 20,
                    limiteContatoPesquisado: 20,
                    loadingexibeContato: true,
                    loadingexibeContatoPesquisado: true,
                })

            } else if (filteredName.length == 0) {

                this.setState({
                    noDataContacts: true,
                    limiteContato: 20,
                    limiteContatoPesquisado: 20,
                    loadingexibeContato: true,
                    loadingexibeContatoPesquisado: true,
                })

            } else if (Array.isArray(filteredName)) {

                this.setState({
                    noDataContacts: false,
                    resultadosPesquisaContacts: filteredName,
                    limiteContato: 20,
                    limiteContatoPesquisado: 20,
                    loadingexibeContato: true,
                    loadingexibeContatoPesquisado: true,
                })

            }
        } else {
            let text = digitoPesquisa.toLowerCase();
            let trucks = this.props.chamadosEmEsperaAtendimentoOnline;
            let filteredName = trucks.filter((item) => {
                return item.cliente.toLowerCase().match(text)
            })
            if (!text || text === '') {
                /*  this.setState({
                   data: initial
                 }) */
            } else if (filteredName.length == 0) {
                // set no data flag to true so as to render flatlist conditionally
                this.setState({
                    noDataEspera: true
                })
            } else if (Array.isArray(filteredName)) {
                this.setState({
                    noDataEspera: false,
                    resultadosPesquisaEspera: filteredName
                })
            }
        }
        this.props.ativaDesativaLoadingCarregaContatosWppExport(false);

    }

    searchTextContatosEncaminharMensagem = async (digitoPesquisa) => {
        let text = digitoPesquisa.toLowerCase();
        let trucksEmAtendimento = this.props.chamadosEmAtendimentoOnline;
        let filteredNameEmAtendimento = trucksEmAtendimento.filter((item) => {
            return item.cliente.toLowerCase().match(text)
        })
        if (!text || text === '') {
            this.setState({
                noDataAtendimentoEncaminharMensagem: false
            })
            /*  this.setState({
               data: initial
             }) */
        } else if (filteredNameEmAtendimento.length == 0) {
            // set no data flag to true so as to render flatlist conditionally
            this.setState({
                noDataAtendimentoEncaminharMensagem: true
            })
        } else if (Array.isArray(filteredNameEmAtendimento)) {
            this.setState({
                noDataAtendimentoEncaminharMensagem: false,
                resultadosPesquisaAtendimentosEncaminharMensagem: filteredNameEmAtendimento
            })
        }


        const contactsSearch = await loadContacts(digitoPesquisa, 0);
        const mergedContacts = [...contactsSearch, ...this.props.allContactsWhatsapp];
        const contacts = removeItemsDuplicateFromListByValue(mergedContacts, 'phone');
        let filteredNameContacts = contacts.filter((item) => {
            return item.nome.toLowerCase().match(text) || item.phone.toLowerCase().match(text)
        })

        if (!text || text === '') {
            this.setState({
                noDataContactsEncaminharMensagem: false
            })

        } else if (filteredNameContacts.length == 0) {
            this.setState({
                noDataContactsEncaminharMensagem: true
            })
        } else if (Array.isArray(filteredNameContacts)) {
            this.setState({
                noDataContactsEncaminharMensagem: false,
                resultadosPesquisaContactsEncaminharMensagem: filteredNameContacts
            })
        }

        /*   let text = digitoPesquisa.toLowerCase(); */
        let trucksEmEspera = this.props.chamadosEmEsperaAtendimentoOnline;
        let filteredNameEmEspera = trucksEmEspera.filter((item) => {
            return item.cliente.toLowerCase().match(text)
        })
        if (!text || text === '') {
            this.setState({
                noDataEsperaEncaminharMensagem: false
            })
            /*  this.setState({
               data: initial
             }) */
        } else if (filteredNameEmEspera.length == 0) {
            // set no data flag to true so as to render flatlist conditionally
            this.setState({
                noDataEsperaEncaminharMensagem: true
            })
        } else if (Array.isArray(filteredNameEmEspera)) {
            this.setState({
                noDataEsperaEncaminharMensagem: false,
                resultadosPesquisaEsperaEncaminharMensagem: filteredNameEmEspera
            })
        }
        this.props.ativaDesativaLoadingCarregaContatosWppExport(false);
    }

    transformaMinutosEmSegundos(s) {

        function duas_casas(numero) {
            if (numero <= 9) {
                numero = "0" + numero;
            }
            return numero;
        }

        let hora = duas_casas(Math.trunc(s / 3600));
        let minuto = duas_casas(Math.trunc((s % 3600) / 60));
        let segundo = duas_casas((s % 3600) % 60);

        let formatado = minuto + ":" + segundo;

        return formatado;
    }
    renderIconeUploadArquivoChat() {

        if (this.props.textArquivoChat !== '') {
            return (
                <TextLoadingEnviandoARquivo corDinamico={this.props.corBotaoPadrao}>{this.props.textArquivoChat}</TextLoadingEnviandoARquivo>
            )
        } else if (this.props.atendimentosPorKeyReducerChat.status === 'Fechado') {
            return (
                <IconeAcaoChat style={{ marginRight: 5 }} onClick={() => { toastr.error('Error', 'Atendimento encerrado') }} className="icon-clip2" data-tip="Enviar arquivos" data-for="tip-top" colorDinamico={this.props.corSegundoBotaoPadrao} backgroundBotao={this.props.corBotaoPadrao} />

            )

        } else {
            return (
                <Dropzone
                    multiple={true}
                    noKeyboard
                    noDrag
                    maxSize={2e+7}
                    /*  onDropRejected={() => {
                         toastr.error('Error', 'Adicione arquivos de no máximo 20MB')
                     }} */
                    onDrop={acceptedFiles => this.escolheArquivoChamado(acceptedFiles)}>
                    {({ getRootProps, getInputProps, isDragActive }) => {
                        //

                        return (
                            /*     <section> */
                            <div   {...getRootProps()}>
                                <input  {...getInputProps()} />
                                <IconeAcaoChat style={{ marginRight: 5 }} className="icon-clip2" data-tip="Enviar arquivos" data-for="tip-top" colorDinamico={this.props.corSegundoBotaoPadrao} backgroundBotao={this.props.corBotaoPadrao} />
                            </div>
                            /*   </section> */
                        )
                    }}
                </Dropzone>
            )
        }
    }
    listaAtendentesDoSetorSelecionado(keySetor) {
        AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            firebase.database().ref(`${chaveUnica}/setores/${keySetor}/atendentesVinculados`).once('value').then((snapshot) => {
                if (snapshot.val()) {
                    let arrayAtendenteVinculados = []
                    let arrayAtendentesVinculadosSelecionados = []
                    //  console.log('dentro do snapshot.val()')
                    // console.log(snapshot.val())
                    snapshot.forEach(childItem => {
                        arrayAtendenteVinculados.push({
                            value: childItem.key,
                        });
                    })
                    //
                    //
                    let result = this.props.atendentesAcoesChamado.filter(o1 => arrayAtendenteVinculados.some(o2 => o1.value === o2.value));
                    //
                    for (let i = 0; i < result.length; i++) {
                        arrayAtendentesVinculadosSelecionados.push({ value: result[i].value, name: result[i].name })
                    }
                    const items = [{
                        name: 'ATENDENTES',
                        type: 'group', items: arrayAtendentesVinculadosSelecionados
                    }];
                    this.setState({ arrayAtendentesTransferir: items })
                    /*  const items = [{
                         name: 'ATENDENTES',
                         type: 'group', items: arrayAtendentesVinculadosSelecionados
                     }];
                     this.setState({ arrayAtendentesTransferir: items }) */
                    /*     modificaAtendenteVinculadoSelecionadoLOCAL(dispatch, arrayAtendentesVinculadosSelecionados)

                        ativaDesativaLoadingVinculaAtendenteSetor(dispatch, false); */
                } else {
                    const items = [{
                        name: 'ATENDENTES',
                        type: 'group', items: this.props.atendentesAcoesChamado
                    }];
                    this.setState({ arrayAtendentesTransferir: items })
                }

            })
        })
    }
    renderModalTransferirAtendimento() {
        if (this.props.loadingCarregandoAtendenteTelaNovoChamado) {
            return (
                <ClipLoader
                    css={{}}
                    size={35}
                    color={this.props.corBotaoPadrao}
                    loading={true}
                />
            )
        } else if (this.props.verificaAgrupaAtendenteSetor === true) {
            return (

                <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: 15 }}>
                        <div style={{ marginTop: 10, marginBottom: 10 }}>
                            <p style={{ color: this.props.corLetraDescricaoDosCampos, fontWeight: 'bold', fontSize: 13, textAlign: 'left', marginBottom: 5 }}>Transferir para setor (obrigatório)<span style={{ color: 'red', fontSize: 14, marginLeft: 3 }}>*</span></p>
                            <div style={{ borderRadius: 3, height: 30, width: 380 }}>
                                <SelectSearch
                                    renderValue={(valueProps) =>
                                        <DivInputSearch>
                                            <InputSearch
                                                corLetraDinamico={this.props.coresLetraPadrao}
                                                backgroundDinamico={this.props.backgroundMaisEscuro}
                                                placeholderColor={this.props.corLetraSecundaria}
                                                corBordaDinamica={this.props.corBotaoPadrao}
                                                {...valueProps} ></InputSearch>
                                            {this.state.setorSelecionadoTransferir !== '' && <IconeExcluirOpcaoMarcada
                                                onClick={() => { this.setState({ setorSelecionadoTransferir: '' }) }}
                                                className="icon-close"
                                                colorDinamico={this.props.coresLetraPadrao}
                                                corBotaoPadrao={this.props.corBotaoPadrao}
                                            />}
                                            <IconeSetaPraBaixo className="icon-angle-arrow-down"
                                                colorDinamico={this.props.coresLetraPadrao}
                                            />

                                        </DivInputSearch>
                                    }
                                    // disabled={this.props.loadingBotaoAlterarCliente}
                                    value={this.state.setorSelecionadoTransferir}
                                    options={this.state.arraySetoresTransferir}
                                    onChange={(value, option) => {
                                        this.setState({ setorSelecionadoTransferir: value, nomeSetorSelecionadoTransf: option.name })
                                        this.listaAtendentesDoSetorSelecionado(value);
                                        // this.props.alteraEmpresaSelecionadoTelaAlterarCliente(value);
                                        //  this.props.modificaNomeClienteSelecionadoTelaAlterarCliente(option.name);
                                    }}
                                    placeholder={this.state.arraySetoresTransferir.length == 0 ? "Carregando Setores" : "Selecione o Setor"}
                                    search={true}
                                // style={{ color: '#FFF' }} onChange={(value) => alert(value)} options={this.state.testeEmpresas} placeholder={this.state.testeEmpresas.length == 0 ? `Nenhum Cliente cadastrado, Por favor verifique!` : "Selecione o Cliente"} search={this.state.testeEmpresas.length == 0 ? false : true}
                                />
                            </div>
                        </div>
                        <div style={{ marginTop: 10, marginBottom: 10 }}>
                            <p style={{ color: this.props.corLetraDescricaoDosCampos, fontWeight: 'bold', fontSize: 13, textAlign: 'left', marginBottom: 5 }}>Transferir para atendente (opcional)</p>
                            <div style={{ borderRadius: 3, height: 30, width: 380 }}>
                                <SelectSearch
                                    renderValue={(valueProps) =>
                                        <DivInputSearch>
                                            <InputSearch
                                                corLetraDinamico={this.props.coresLetraPadrao}
                                                backgroundDinamico={this.props.backgroundMaisEscuro}
                                                placeholderColor={this.props.corLetraSecundaria}
                                                corBordaDinamica={this.props.corBotaoPadrao}
                                                {...valueProps} ></InputSearch>
                                            {this.state.atendenteSelecionadoTransferir !== '' && <IconeExcluirOpcaoMarcada
                                                onClick={() => { this.setState({ atendenteSelecionadoTransferir: '', nomeAtendenteSelecionadoTransferir: '' }); }}
                                                className="icon-close"
                                                colorDinamico={this.props.coresLetraPadrao}
                                                corBotaoPadrao={this.props.corBotaoPadrao}
                                            />}
                                            <IconeSetaPraBaixo className="icon-angle-arrow-down"
                                                colorDinamico={this.props.coresLetraPadrao}
                                            />

                                        </DivInputSearch>
                                    }
                                    disabled={this.state.setorSelecionadoTransferir === '' ? true : false}
                                    value={this.state.atendenteSelecionadoTransferir}
                                    options={this.state.arrayAtendentesTransferir}
                                    onChange={(value, option) => {
                                        this.setState({ atendenteSelecionadoTransferir: value, nomeAtendenteSelecionadoTransferir: option.name })

                                        // this.props.alteraEmpresaSelecionadoTelaAlterarCliente(value);
                                        //  this.props.modificaNomeClienteSelecionadoTelaAlterarCliente(option.name);
                                    }}
                                    placeholder={this.state.arrayAtendentesTransferir.length == 0 ? "Carregando Atendentes" : "Selecione o Atendente"}
                                    search={true}
                                // style={{ color: '#FFF' }} onChange={(value) => alert(value)} options={this.state.testeEmpresas} placeholder={this.state.testeEmpresas.length == 0 ? `Nenhum Cliente cadastrado, Por favor verifique!` : "Selecione o Cliente"} search={this.state.testeEmpresas.length == 0 ? false : true}
                                />
                            </div>
                        </div>
                        <div style={{ marginTop: 10 }}>
                            <ContainerInputComentarioTransferir

                                style={{}}>
                                <strong style={{ color: this.props.corLetraDescricaoDosCampos, fontSize: 13, marginLeft: 5, marginBottom: 5 }}>Adicionar Comentário:</strong>
                                <InputComentarioTransferir
                                    //autoFocus
                                    type="textarea"
                                    onChange={(value) => { this.setState({ comentarioTransferirChamado: value.target.value }) }}
                                    corLetraDinamico={this.props.coresLetraPadrao}
                                    corLetraSecundaria={this.props.coresLetraSecundario}
                                    corBordaDinamica={this.props.corBotaoPadrao}
                                    placeholder="Comentário visível apenas para a equipe de atendimento"
                                    value={this.state.comentarioTransferirChamado}
                                />
                            </ContainerInputComentarioTransferir>
                        </div>
                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center', alignItems: 'center',
                    }}>
                        <ButtonNaoCancelarTransferencia onClick={() => { this.setState({ modalAtribuirAtendimento: false }) }} borderColorDinamico={this.props.corBotaoPadrao} backgroundBotao={this.props.backgroundPrimeiro}>
                            <TextBotaoCancelarAtendimento colorDinamico={this.props.corBotaoPadrao}>Não</TextBotaoCancelarAtendimento>
                        </ButtonNaoCancelarTransferencia>
                        <ButtonEnviarTransferencia onClick={() => {
                            this.transferiChamadoLocal()
                        }} backgroundBotao={this.props.corBotaoPadrao}>
                            <TextBotaoCancelarAtendimento colorDinamico={this.props.letraBotaoPadrao}>Sim</TextBotaoCancelarAtendimento>
                        </ButtonEnviarTransferencia>
                    </div>
                </div>
            )
        } else {
            return (
                <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: 15 }}>
                        <div>
                            <p style={{ color: this.props.corLetraDescricaoDosCampos, fontWeight: 'bold', fontSize: 13, textAlign: 'left', marginBottom: 5 }}>Transferir para atendente (obrigatório)<span style={{ color: 'red', fontSize: 14, marginLeft: 3 }}>*</span></p>
                            <div style={{ borderRadius: 3, height: 30, width: 380 }}>
                                <SelectSearch
                                    renderValue={(valueProps) =>
                                        <DivInputSearch>
                                            <InputSearch
                                                corLetraDinamico={this.props.coresLetraPadrao}
                                                backgroundDinamico={this.props.backgroundMaisEscuro}
                                                placeholderColor={this.props.corLetraSecundaria}
                                                corBordaDinamica={this.props.corBotaoPadrao}
                                                {...valueProps} ></InputSearch>
                                            {this.state.atendenteSelecionadoTransferir !== '' && <IconeExcluirOpcaoMarcada
                                                onClick={() => { this.setState({ atendenteSelecionadoTransferir: '', nomeAtendenteSelecionadoTransferir: '' }); }}
                                                className="icon-close"
                                                colorDinamico={this.props.coresLetraPadrao}
                                                corBotaoPadrao={this.props.corBotaoPadrao}
                                            />}
                                            <IconeSetaPraBaixo className="icon-angle-arrow-down"
                                                colorDinamico={this.props.coresLetraPadrao}
                                            />

                                        </DivInputSearch>
                                    }
                                    // disabled={this.props.loadingBotaoAlterarCliente}
                                    value={this.state.atendenteSelecionadoTransferir}
                                    options={this.state.arrayAtendentesTransferir}
                                    onChange={(value, option) => {
                                        this.setState({ atendenteSelecionadoTransferir: value, nomeAtendenteSelecionadoTransferir: option.name })

                                        // this.props.alteraEmpresaSelecionadoTelaAlterarCliente(value);
                                        //  this.props.modificaNomeClienteSelecionadoTelaAlterarCliente(option.name);
                                    }}
                                    placeholder={this.state.arrayAtendentesTransferir.length == 0 ? "Carregando Atendentes" : "Selecione o Atendente"}
                                    search={true}
                                // style={{ color: '#FFF' }} onChange={(value) => alert(value)} options={this.state.testeEmpresas} placeholder={this.state.testeEmpresas.length == 0 ? `Nenhum Cliente cadastrado, Por favor verifique!` : "Selecione o Cliente"} search={this.state.testeEmpresas.length == 0 ? false : true}
                                />
                            </div>
                        </div>
                        <div style={{ marginTop: 10 }}>
                            <ContainerInputComentarioTransferir

                                style={{}}>
                                <strong style={{ color: this.props.corLetraDescricaoDosCampos, fontSize: 13, marginLeft: 5, marginBottom: 5 }}>Adicionar Comentário:</strong>
                                <InputComentarioTransferir
                                    //autoFocus
                                    type="textarea"
                                    onChange={(value) => { this.setState({ comentarioTransferirChamado: value.target.value }) }}
                                    corLetraDinamico={this.props.coresLetraPadrao}
                                    corLetraSecundaria={this.props.coresLetraSecundario}
                                    corBordaDinamica={this.props.corBotaoPadrao}
                                    placeholder="Comentário visível apenas para a equipe de atendimento"
                                    value={this.state.comentarioTransferirChamado}
                                />
                            </ContainerInputComentarioTransferir>
                        </div>
                        {/* <ContainerTextCabecalhoModalAcoes>
                        <TextCabecalhoModalAcoes corLetraDinamico={this.props.corBotaoPadrao}>Transferir atendimento para o Atendente:</TextCabecalhoModalAcoes>
                    </ContainerTextCabecalhoModalAcoes>
                    <ContainerAtendentes>

                        <FlatList
                            list={this.props.atendentesAcoesChamado}
                            renderItem={(item) => this.renderAtendentes(item)}
                            renderWhenEmpty={() => <div>Lista está vazia!</div>}
                        />
                    </ContainerAtendentes> */}
                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center', alignItems: 'center',
                    }}>
                        <ButtonNaoCancelarTransferencia onClick={() => { this.setState({ modalAtribuirAtendimento: false }) }} borderColorDinamico={this.props.corBotaoPadrao} backgroundBotao={this.props.backgroundPrimeiro}>
                            <TextBotaoCancelarAtendimento colorDinamico={this.props.corBotaoPadrao}>Não</TextBotaoCancelarAtendimento>
                        </ButtonNaoCancelarTransferencia>
                        <ButtonEnviarTransferencia onClick={() => {
                            this.transferiChamadoLocal()
                        }} backgroundBotao={this.props.corBotaoPadrao}>
                            <TextBotaoCancelarAtendimento colorDinamico={this.props.letraBotaoPadrao}>Sim</TextBotaoCancelarAtendimento>
                        </ButtonEnviarTransferencia>
                    </div>
                </div>
            )
        }
    }
    transferiChamadoLocal() {
        let keySetorVinculado = this.state.setorSelecionadoTransferir;
        let comentario = this.state.comentarioTransferirChamado.trim();
        let dadosAtendimento = this.props.keyAtendimentoEmAtendimentoOnline !== '' ? this.props.atendimentosPorKeyReducerChat : this.props.atendimentosPorKeyReducerChatEmEspera;
        //
        if (this.props.verificaAgrupaAtendenteSetor === true) {
            //Agrupa atendente por setor, então ele pode transferir de 2 formas
            //1 - selecionando apenas o setor
            //quando selecionar apenas o setor, tenho que mostrar o atendimento para os atendentes daquele setor e o comentario
            //2 - selecionando o setor e o atendente
            //tenho que mostrar o atendimento para o atendente e vincular o setor no atendimento
            if (this.state.atendenteSelecionadoTransferir === '') {
                //apenas setor
                if (this.state.atendenteSelecionadoTransferir === this.props.keyUsuarioLogado) {
                    toastr.error('Error', 'Atendimento não pode ser transferido para quem já está atendendo.')
                } else if (keySetorVinculado === '') {
                    toastr.error('Error', 'Selecione o setor para qual irá transferir')
                }
                else {
                    const items = [{
                        name: 'ATENDENTES',
                        type: 'group', items: this.props.atendentesAcoesChamado
                    }];
                    firebase.database().ref(`${dadosAtendimento.chaveUnica}/acoesChamados/${dadosAtendimento.key}`).off("value");
                    firebase.database().ref(`${dadosAtendimento.chaveUnica}/chamados/`).orderByKey().equalTo(dadosAtendimento.key).off("value")
                    this.props.modificaKeyAtendimentoEmAtendimentoOnline('');
                    this.props.modificaKeyAtendimentoEmEsperaAtendimentoOnline('');
                    this.props.enviaAtendimentoParaReducerChatExport([])
                    toastr.success('Sucesso', 'Atendimento Transferido com Sucesso!')
                    // this.props.atribuiAtendimentoAh(this.state.atendenteSelecionadoTransferir, dadosAtendimento.key, this.state.nomeAtendenteSelecionadoTransferir, keySetorVinculado, dadosAtendimento, true, this.props.configWhatsapp, comentario);
                    this.props.transferiAtendimentoParaSetorChat("Não informado", dadosAtendimento.key, this.props.nome, keySetorVinculado, dadosAtendimento, true, this.props.configWhatsapp, comentario, this.state.nomeSetorSelecionadoTransf, true, this.props.desativarAssistenteVirtualBotWpp,
                        this.props.enviarMensagemTransSetorWpp);
                    this.setState({
                        modalAtribuirAtendimento: false, atendenteSelecionadoTransferir: '', nomeAtendenteSelecionadoTransferir: '',
                        comentarioTransferirChamado: '', arrayAtendentesTransferir: items, setorSelecionadoTransferir: '', nomeSetorSelecionadoTransf: ''
                    });

                }
            } else {
                //setor e atendente
                if (this.state.atendenteSelecionadoTransferir === this.props.keyUsuarioLogado) {
                    toastr.error('Error', 'Atendimento não pode ser transferido para quem já está atendendo.')
                } else if (this.state.atendenteSelecionadoTransferir.trim() === '') {
                    toastr.error('Error', 'Selecione o atendente pra quem irá transferir')
                } else if (keySetorVinculado === '') {
                    toastr.error('Error', 'Selecione o setor para qual irá transferir')
                }
                else {
                    const items = [{
                        name: 'ATENDENTES',
                        type: 'group', items: this.props.atendentesAcoesChamado
                    }];
                    firebase.database().ref(`${dadosAtendimento.chaveUnica}/acoesChamados/${dadosAtendimento.key}`).off("value");
                    firebase.database().ref(`${dadosAtendimento.chaveUnica}/chamados/`).orderByKey().equalTo(dadosAtendimento.key).off("value")
                    this.props.modificaKeyAtendimentoEmAtendimentoOnline('');
                    this.props.modificaKeyAtendimentoEmEsperaAtendimentoOnline('');
                    this.props.enviaAtendimentoParaReducerChatExport([])
                    toastr.success('Sucesso', 'Atendimento Transferido com Sucesso!')
                    this.props.atribuiAtendimentoAh(this.state.atendenteSelecionadoTransferir, dadosAtendimento.key, this.state.nomeAtendenteSelecionadoTransferir, keySetorVinculado, dadosAtendimento, true, this.props.configWhatsapp, comentario, this.props.desativarAssistenteVirtualBotWpp, this.props.mostrarNomeAtendenteWpp);
                    this.setState({
                        modalAtribuirAtendimento: false, atendenteSelecionadoTransferir: '', nomeAtendenteSelecionadoTransferir: '',
                        comentarioTransferirChamado: '', arrayAtendentesTransferir: items, setorSelecionadoTransferir: '', nomeSetorSelecionadoTransf: ''
                    });

                }
            }


        } else {
            //não tem agrupa atendente á setor, vai atribuir direto para atendente, então já cai na fila de conversas
            if (this.state.atendenteSelecionadoTransferir === this.props.keyUsuarioLogado) {
                toastr.error('Error', 'Atendimento não pode ser transferido para quem já está atendendo.')
            } else if (this.state.atendenteSelecionadoTransferir.trim() === '') {
                toastr.error('Error', 'Selecione o atendente pra quem irá transferir')
            }
            else {
                const items = [{
                    name: 'ATENDENTES',
                    type: 'group', items: this.props.atendentesAcoesChamado
                }];
                firebase.database().ref(`${dadosAtendimento.chaveUnica}/acoesChamados/${dadosAtendimento.key}`).off("value");
                firebase.database().ref(`${dadosAtendimento.chaveUnica}/chamados/`).orderByKey().equalTo(dadosAtendimento.key).off("value")
                this.props.modificaKeyAtendimentoEmAtendimentoOnline('');
                this.props.modificaKeyAtendimentoEmEsperaAtendimentoOnline('');
                this.props.enviaAtendimentoParaReducerChatExport([])
                toastr.success('Sucesso', 'Atendimento Transferido com Sucesso!')

                this.props.atribuiAtendimentoAh(this.state.atendenteSelecionadoTransferir, dadosAtendimento.key, this.state.nomeAtendenteSelecionadoTransferir, dadosAtendimento.keySetor, dadosAtendimento, true, this.props.configWhatsapp, comentario, this.props.desativarAssistenteVirtualBotWpp, this.props.mostrarNomeAtendenteWpp);
                this.setState({
                    modalAtribuirAtendimento: false, atendenteSelecionadoTransferir: '', nomeAtendenteSelecionadoTransferir: '',
                    comentarioTransferirChamado: '', arrayAtendentesTransferir: items, setorSelecionadoTransferir: '', nomeSetorSelecionadoTransf: ''
                });

            }
        }

    }
    renderAtendentes(item) {
        return (
            <DivAtendentes key={item.value}>
                <TextAtendentes onClick={() => {
                    if (item.value === this.props.keyUsuarioLogado) {
                        toastr.error('Error', 'Atendimento não pode ser transferido para quem já está atendendo.')
                    } else {
                        setTimeout(() => {
                            firebase.database().ref(`${this.props.atendimentosPorKeyReducerChat.chaveUnica}/acoesChamados/${this.props.atendimentosPorKeyReducerChat.key}`).off("value");
                            firebase.database().ref(`${this.props.atendimentosPorKeyReducerChat.chaveUnica}/chamados/`).orderByKey().equalTo(this.props.atendimentosPorKeyReducerChat.key).off("value")
                            this.props.modificaKeyAtendimentoEmAtendimentoOnline('');
                            this.props.enviaAtendimentoParaReducerChatExport([])
                            this.setState({ modalAtribuirAtendimento: false });
                            toastr.success('Sucesso', 'Atendimento Transferido com Sucesso!')
                            this.props.atribuiAtendimentoAh(item.value, this.props.atendimentosPorKeyReducerChat.key, item.name, this.props.atendimentosPorKeyReducerChat.keySetor, this.props.atendimentosPorKeyReducerChat, true, this.props.configWhatsapp, '', this.props.desativarAssistenteVirtualBotWpp);
                        }, 0);
                    }

                    //  this.props.modificaVisibilidadeModalAtribuirAtendimento(false);
                }} corLetraBotaoPadrao={this.props.corBotaoPadrao} corLetraDinamico={this.props.coresLetraPadrao}>{item.name}</TextAtendentes>
            </DivAtendentes>
        )
    }
    renderHoraMinuto(dataHoraAbertura) {
        let formattedTime = moment(dataHoraAbertura, 'DD/MM/YYYY HH:mm').format('HH:mm');
        return formattedTime;

    }
    renderConteudoModalConnectWhatsapp() {
        let qrCode = this.props.configWhatsapp.length > 0 ? this.props.configWhatsapp[0].qrCode : '';
        let statusConnect = this.props.configWhatsapp.length > 0 ? this.props.configWhatsapp[0].statusConnect : '';
        if (statusConnect === 'autocloseCalled' || statusConnect === 'browserClose' || this.state.errorConnectarContainer === true || statusConnect === 'qrReadFail') {
            return (
                <>
                    <img src={qrcodeimage} alt="QR CODE" style={{ height: 200, width: 200, opacity: 0.3 }} />
                    <div onClick={() => {
                        if (this.props.premium == 0) {
                            toastr.error('Error', 'Este é um recurso da versão PREMIUM,Adquira a versão PREMIUM e LIBERE esse e mais recursos.')

                        } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
                            toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
                        } else {
                            /*      if (this.props.dataCompra != '' && this.props.dataValidoAte != '' && this.props.premium == 1) { */
                            let diferencaDia = this.calculaDias2(this.props.dataCompra, this.props.dataValidoAte);
                            if (diferencaDia <= 31 && this.props.dataValidadeAtual === undefined) {
                                let dataAtual = this.retornaDataAtual();
                                let diasRestantesVersaoGratis = this.calculaDiasRestantesVersaoGratis(dataAtual, this.props.dataValidoAte);
                                let diasUsados = diferencaDia - diasRestantesVersaoGratis;
                                if (diasUsados > 7) {
                                    toastr.error('Periodo Teste Esgotou', 'O período teste de 7 dias EXPIROU, Assine a Versão Premium para utilizar este recurso!');
                                } else {
                                    this.somenteConectarContainer();
                                }
                            } else {
                                this.somenteConectarContainer();
                            }

                            /* } */
                        }

                    }} style={{ display: 'flex', height: 140, width: 140, borderRadius: '50%', backgroundColor: '#00BEA4', cursor: 'pointer', alignItems: 'center', justifyContent: 'center', position: 'absolute', center: 0, flexDirection: 'column' }}>
                        <i className="icon-refresh-arrow" style={{ fontSize: 22, color: '#FFF', marginBottom: 7 }} />
                        <p style={{ fontSize: 16, color: '#FFF' }}> Gerar QR CODE</p>
                    </div>
                </>
            )

        } else if (this.state.criandoContainer === true || qrCode === '') {
            return (
                <>
                    <p style={{ fontSize: 19, color: '#000' }}><b>Gerando QR CODE </b><br></br>Por favor Aguarde ...</p>
                    <HashLoader
                        css={{ marginTop: 15 }}
                        size={60}
                        color={'#00BEA4'}
                        loading
                    />
                </>
            )
        } else {
            return (
                <div>
                    <img src={qrCode} alt="QR CODE" style={{ height: 200, width: 200 }} />
                </ div>
            )
        }

    }
    toggleModalDesconectarWhatsapp() {
        this.setState({ modalDesconectarWhatsapp: !this.state.modalDesconectarWhatsapp })
    }
    toggleModalConectarWhatsapp() {
        this.setState({ modalConnectWhatsapp: !this.state.modalConnectWhatsapp })
    }
    randomIntFromInterval(min, max) { // min and max included
        return Math.floor(Math.random() * (max - min + 1) + min)
    }
    criaContainerVenom() {
        this.setState({ criandoContainer: true, errorConnectarContainer: false })
        AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");

            let data = {
                chaveUnica: chaveUnica,
                utilizaVersaoBETA: this.props.utilizaVersaoBETA
            }
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                    'Accept': 'application/json',
                },

                data: data,
                url: 'createAndConnectContainerHibrid',
            };
            api(options).then((response) => {
                this.setState({ criandoContainer: false })
                // this.downloadUsuariosCadastradosExcel(['email'], response.data)
            }).catch((err) => {
                this.setState({ criandoContainer: false, errorConnectarContainer: true })
                toastr.error('Erro ao Connectar, Gere o QR Code e Tente Novamente')
            })
        })
    }



    modificaVisibilidadeModalCancelarAtendimento() {
        this.setState({ modalCancelarAtendimento: !this.state.modalCancelarAtendimento });
    }
    somenteConectarContainer() {
        this.setState({ criandoContainer: true, errorConnectarContainer: false })

        AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            firebase.database().ref(`${chaveUnica}/configuracao/whatsapp/${this.props.configWhatsapp[0].key}`).update({ statusConnect: '', qrCode: '' });


            /* let sessionClientRandom = Math.floor(
                Date.now() * Math.random()
            )
            sessionClientRandom = sessionClientRandom.toString(); */
            let data = {
                sessionCliente: this.props.configWhatsapp[0].sessionclient, // HERA SESSION 17 ATUAL
                urlApi: this.props.configWhatsapp[0].urlApi,
                //sessionCliente: '18', // HERA SESSION 17 ATUAL
                chaveUnica: chaveUnica,
                utilizaVersaoBETA: this.props.utilizaVersaoBETA
            }
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                    'Accept': 'application/json',
                },

                data: data,
                url: 'somenteConectaVenomHibrid',
            };
            api(options).then((response) => {
                this.setState({ criandoContainer: false })
                // this.downloadUsuariosCadastradosExcel(['email'], response.data)
            }).catch((err) => {
                this.setState({ criandoContainer: false, errorConnectarContainer: true })
                toastr.error('Erro ao Connectar, Gere o QR Code e Tente Novamente')
                firebase.database().ref(`${chaveUnica}/configuracao/whatsapp/${this.props.configWhatsapp[0].key}`).update({ statusConnect: 'isLogout', qrCode: '' });
            })
        })
    }
    renderAlteraDescricaoChamadoDigitado() {
        let dadosChamado = this.props.atendimentosPorKeyReducerChat !== undefined ? this.props.atendimentosPorKeyReducerChat : this.props.atendimentosPorKeyReducerChatEmEspera !== undefined ? this.props.atendimentosPorKeyReducerChatEmEspera : undefined
        //
        //
        if (dadosChamado !== undefined) {
            if (this.props.criarChamadoProblemaEstaticoAtendimento == false) {
                return (
                    <ContainerInputDescricaoChamadoAlterChat>
                        <strong style={{ color: this.props.corLetraDescricaoDosCampos, fontSize: 14, marginBottom: 2, marginLeft: 5 }}>Descrição do problema:</strong>
                        <InputDescricaoChamadoAlterChat
                            type="textarea"
                            corBordaDinamica={this.props.corBotaoPadrao}
                            onChange={(value) => { this.props.alteraDescricaoChamado(value.target.value) }}
                            corLetraDinamico={this.props.coresLetraPadrao}
                            value={this.props.descricaoAtendimento}
                            corLetraSecundaria={this.props.coresLetraSecundario}
                            placeholder=" Descreva o problema..."
                        />
                    </ContainerInputDescricaoChamadoAlterChat>
                )
            } else {
                return null;
            }
        } else {
            return null;
        }

    }
    renderConteudoModalAlteracao() {
        let dadosChamado = this.props.atendimentosPorKeyReducerChat !== undefined ? this.props.atendimentosPorKeyReducerChat : this.props.atendimentosPorKeyReducerChatEmEspera !== undefined ? this.props.atendimentosPorKeyReducerChatEmEspera : undefined


        const CustomInputDataVencimento = ({ value, onClick }) => (
            <>

                <ContainerSelectSearch onClick={onClick}>
                    <DivInputSearch>
                        <InputDataVencimento
                            readOnly
                            corLetraDinamico={this.props.coresLetraPadrao}
                            backgroundDinamico={this.props.backgroundMaisEscuro}
                            placeholderColor={this.props.corLetraSecundaria}
                            corBordaDinamica={this.props.corBotaoPadrao}
                            type="text"
                            placeholder='Selecione a Data'
                            value={value}
                        >

                        </InputDataVencimento>
                        <IconeSetaPraBaixo className="icon-angle-arrow-down" colorDinamico={this.props.coresLetraPadrao} />
                    </DivInputSearch>
                </ContainerSelectSearch>
            </>
        );



        if (dadosChamado !== undefined) {
            return (
                <ContainerDetalhesModalNovoAtendimento >
                    {dadosChamado.status === "Atendendo" && this.props.verificaAgrupaAtendenteSetor === true && <div style={{ display: 'flex', flexDirectionL: 'row', marginTop: 20 }}>
                        <i className="icon-danger1" style={{ fontSize: 15, color: '#F9BF3B', marginLeft: 10 }} />
                        <p style={{ marginLeft: 5, color: '#F9BF3B', fontSize: 14 }}>Alterar o Setor não irá transferir o atendimento para o mesmo, para realizar uma transferência, utilize a ferramenta <b>"Transferir Atendimento"</b></p>
                    </div>}
                    <SubContainerNovoAtendimento>
                        <p style={{ marginLeft: 20, color: this.props.corLetraDescricaoDosCampos, fontSize: 14 }}>Setor:</p>
                        <ContainerSelectSearch>
                            <SelectSearch
                                renderValue={(valueProps) =>
                                    <DivInputSearch>
                                        <InputSearch
                                            corLetraDinamico={this.props.coresLetraPadrao}
                                            backgroundDinamico={this.props.backgroundMaisEscuro}
                                            placeholderColor={this.props.corLetraSecundaria}
                                            corBordaDinamica={this.props.corBotaoPadrao}
                                            {...valueProps} ></InputSearch>
                                        <IconeSetaPraBaixo className="icon-angle-arrow-down" colorDinamico={this.props.coresLetraPadrao} />
                                    </DivInputSearch>
                                }
                                value={this.props.setorSelecionado} options={this.props.setorNovoChamado} onChange={(value, option) => { this.props.modificaSetorSelecionado(value); this.setState({ nomeAuxiliarSetor: option.name }) }} placeholder={this.props.setorNovoChamado.length == 0 ? `Nenhum Setor cadastrado` : "Selecione o Setor"} search={this.props.setorNovoChamado.length == 0 ? false : true} />

                        </ContainerSelectSearch>
                    </SubContainerNovoAtendimento>
                    <SubContainerNovoAtendimento>
                        <p style={{ marginLeft: 20, fontSize: 14, color: this.props.corLetraDescricaoDosCampos }}>Data Vencimento:</p>
                        <DatePicker

                            /* selected={new Date()} */
                            onChange={(date) => { this.props.modificaDataVencimentoTelaChamadoAbertura(moment(date).format('DD/MM/YYYY HH:mm')); this.setState({ dataVencimento: date }) }}
                            locale="pt-BR"
                            value={this.props.dataVencimento}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeCaption="Hora"
                            selected={this.state.dataVencimento}
                            customInput={<CustomInputDataVencimento />}
                            formatWeekDay={d => {
                                switch (d) {
                                    case 'segunda':
                                        return 'Seg';
                                    case 'terça':
                                        return 'Ter';
                                    case 'quarta':
                                        return 'Qua';
                                    case 'quinta':
                                        return 'Qui';
                                    case 'sexta':
                                        return 'Sex';
                                    case 'sábado':
                                        return 'Sab';
                                    case 'domingo':
                                        return 'Dom';
                                    default:
                                        return d;
                                }
                            }}
                            timeIntervals={5}
                            dateFormat="dd/MM/yyyy HH:mm"
                            placeholderText="Data Final" />
                    </SubContainerNovoAtendimento>
                    <SubContainerNovoAtendimento>
                        <p style={{ marginLeft: 20, fontSize: 14, color: this.props.corLetraDescricaoDosCampos }}>Prioridade:</p>
                        <ContainerSelectSearch>
                            <SelectSearch
                                renderValue={(valueProps) =>
                                    <DivInputSearch>
                                        <InputSearch
                                            corLetraDinamico={this.props.coresLetraPadrao}
                                            backgroundDinamico={this.props.backgroundMaisEscuro}
                                            placeholderColor={this.props.corLetraSecundaria}
                                            corBordaDinamica={this.props.corBotaoPadrao}
                                            {...valueProps} ></InputSearch>
                                        <IconeSetaPraBaixo className="icon-angle-arrow-down" colorDinamico={this.props.coresLetraPadrao} />
                                    </DivInputSearch>
                                }
                                value={this.props.prioridadeSelecionada} options={this.props.prioridade} onChange={(value) => this.props.modificaPrioridadeSelecionado(value)} placeholder={"Selecione a Prioridade"} search />
                        </ContainerSelectSearch>
                    </SubContainerNovoAtendimento>
                    <SubContainerNovoAtendimento>
                        <p style={{ marginLeft: 20, fontSize: 14, color: this.props.corLetraDescricaoDosCampos }}>Local:</p>
                        <ContainerSelectSearch>
                            <SelectSearch
                                renderValue={(valueProps) =>
                                    <DivInputSearch>
                                        <InputSearch
                                            corLetraDinamico={this.props.coresLetraPadrao}
                                            backgroundDinamico={this.props.backgroundMaisEscuro}
                                            placeholderColor={this.props.corLetraSecundaria}
                                            corBordaDinamica={this.props.corBotaoPadrao}
                                            {...valueProps} ></InputSearch>
                                        <IconeSetaPraBaixo className="icon-angle-arrow-down" colorDinamico={this.props.coresLetraPadrao} />
                                    </DivInputSearch>
                                }
                                value={this.props.localAtendimentoSelecionado} options={this.props.localAtendimento} onChange={(value) => this.props.modificaLocalSelecionado(value)} placeholder={"Selecione o Local"} search />
                        </ContainerSelectSearch>
                    </SubContainerNovoAtendimento>
                </ContainerDetalhesModalNovoAtendimento>
            )
        } else {
            return null
        }
    }
    desconnectarContainerVenom() {


        this.setState({ desconectandoContainer: true })

        AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            // firebase.database().ref(`${chaveUnica}/configuracao/whatsapp/${this.props.configWhatsapp[0].key}`).update({ statusConnect: '', qrCode: '' });
            /* let sessionClientRandom = Math.floor(
                Date.now() * Math.random()
            )
            sessionClientRandom = sessionClientRandom.toString(); */
            let data = {
                sessionCliente: this.props.configWhatsapp[0].sessionclient, // HERA SESSION 17 ATUAL
                urlApi: this.props.configWhatsapp[0].urlApi,
                // sessionCliente: '17', // HERA SESSION 17 ATUAL
                chaveUnica: chaveUnica,
            }
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                    'Accept': 'application/json',
                },

                data: data,
                url: 'desconectarVenom',
            };
            api(options).then((response) => {
                this.setState({ desconectandoContainer: false, modalDesconectarWhatsapp: false, infoConectadoOk: false });
                toastr.success('WhatsApp desconectado com Sucesso')
                // this.downloadUsuariosCadastradosExcel(['email'], response.data)
            }).catch((err) => {
                this.setState({ desconectandoContainer: false, modalDesconectarWhatsapp: false, infoConectadoOk: false })
                toastr.error('Error ao desconectar o WhatsApp, Tente Novamente')
            })
        })
    }

    renderChamadosEmAtendimentoChat(item) {
        return (
            <ContainerChamado key={item.key + item.newmessage} onClick={() => {
                this.props.modificaKeyAtendimentoEmAtendimentoOnline(item.key);
            }} bordadinamica={this.props.coresLetraSecundario} backGroundDinamico={item.key == this.props.keyAtendimentoEmAtendimentoOnline ? shade(0.3, this.props.backgroundSegundo) : this.props.backgroundSegundo} type="button" >
                {item.key == this.props.keyAtendimentoEmAtendimentoOnline && <div style={{ width: 5, backgroundColor: this.props.corBotaoPadrao, height: 58, borderTopLeftRadius: 7, borderBottomLeftRadius: 7 }}></div>}
                <ContainerImagem>
                    {/* {this.renderImagem()} */}

                </ContainerImagem>
                <ContainerDadosChamado bordadinamica={this.props.coresLetraSecundario}>
                    <ContaineDadosChamadosPrincipal>
                        <SubContainerIconeEFraseClienteAtendente >
                            <TextCliente corLetraDinamica={this.props.coresLetraPadrao}>{item.cliente}</TextCliente>
                        </SubContainerIconeEFraseClienteAtendente>
                        <SubContainerIconeEFraseClienteAtendente >
                            <TextEmpresa corLetraDinamica={this.props.coresLetraSecundario}>{item.empresa}</TextEmpresa>
                        </SubContainerIconeEFraseClienteAtendente>
                    </ContaineDadosChamadosPrincipal>
                    <ContainerSeta>
                        <p style={{ fontSize: 12, color: this.props.coresLetraSecundario }}>{this.renderHoraMinuto(item.dataHoraAbertura)}</p>

                        {item.newmessage == true && <DivCountNotif>
                            <i className="icon-envelope" style={{ fontSize: 10, color: '#FFF' }} />
                        </DivCountNotif>}
                        {item.newmessage == false && <div>
                        </div>}


                    </ContainerSeta>

                </ContainerDadosChamado>


            </ContainerChamado>
        )

    }
    ajusta = v => {
        const digitos = !v ? '' : v.replace(/[^\d]/g, '');
        if (!digitos || digitos.length < 10) return v;
        const corte = digitos.length === 10 ? 6 : 7;
        const max = digitos.length > 11 ? 11 : digitos.length;
        return `(${digitos.substring(0, 2)}) ${digitos.substring(2, corte)}-${digitos.substring(corte, max)}`;
    }
    maskBuilder = v => {
        if (!v || v.length == 0) return '(99) 9999-9999';
        const a = this.ajusta(v);
        return (a.length >= 6 && a[5] === '9') ? '(99) 99999-9999' : '(99) 9999-9999';
    }
    insereTitleTab(title) {
        this.props.modificaTitleTab(title)
    }
    cancelarChamadoLocal() {
        if (this.state.motivoCancelamento.trim() == '' && this.props.obrigatorioInformarMotivoCancelamento === true) {
            toastr.error('Error ao Cancelar', 'Informe um motivo de cancelamento');
        } else {
            if (this.props.keyAtendimentoEmAtendimentoOnline !== '') {
                setTimeout(() => {
                    this.modificaVisibilidadeModalCancelarAtendimento();
                    firebase.database().ref(`${this.props.atendimentosPorKeyReducerChat.chaveUnica}/acoesChamados/${this.props.atendimentosPorKeyReducerChat.key}`).off("value");
                    firebase.database().ref(`${this.props.atendimentosPorKeyReducerChat.chaveUnica}/chamados/`).orderByKey().equalTo(this.props.atendimentosPorKeyReducerChat.key).off("value")
                    let arraySemChamadoEncerrado = this.props.chamadosEmAtendimentoOnline.filter(el => el.key !== this.props.atendimentosPorKeyReducerChat.key);
                    this.props.modificaChamadosEmAtendimentoViaChatExport(arraySemChamadoEncerrado)
                    this.props.cancelarChamado(this.props.atendimentosPorKeyReducerChat.key, this.state.motivoCancelamento, this.props.atendimentosPorKeyReducerChat.keyAtendente, this.props.atendimentosPorKeyReducerChat, this.props.obrigatorioInformarMotivoCancelamento)

                    this.props.modificaKeyAtendimentoEmAtendimentoOnline('');
                    this.setState({ motivoCancelamento: '' })
                }, 0);
            } else {
                setTimeout(() => {
                    this.modificaVisibilidadeModalCancelarAtendimento();
                    firebase.database().ref(`${this.props.atendimentosPorKeyReducerChatEmEspera.chaveUnica}/acoesChamados/${this.props.atendimentosPorKeyReducerChatEmEspera.key}`).off("value");
                    firebase.database().ref(`${this.props.atendimentosPorKeyReducerChatEmEspera.chaveUnica}/chamados/`).orderByKey().equalTo(this.props.atendimentosPorKeyReducerChatEmEspera.key).off("value")

                    let arraySemChamadoAtendido = this.props.chamadosEmEsperaAtendimentoOnline.filter(el => el.key !== this.props.atendimentoEmEsperaSelecionado.key);
                    this.props.enviaAtendimentoEmEsperaAtendimentoOnlineExport(arraySemChamadoAtendido)
                    this.props.cancelarChamado(this.props.atendimentosPorKeyReducerChatEmEspera.key, this.state.motivoCancelamento, this.props.atendimentosPorKeyReducerChatEmEspera.keyAtendente, this.props.atendimentosPorKeyReducerChatEmEspera, this.props.obrigatorioInformarMotivoCancelamento)

                    this.props.modificaKeyAtendimentoEmEsperaAtendimentoOnline('');
                    this.setState({ motivoCancelamento: '' })
                }, 0);
            }

        }
    }
    /* async getDimensionsImage(srcImage, isHeight) {
        return new Promise(function (resolve, reject) {
            let image = new Image();
            image.src = srcImage;
            return image.onload = function () {
                let height = image.height;
                const screenHeight = window.innerHeight - 300;
                if (height > screenHeight) {
                    height = screenHeight;
                }
                console.log(height);
                resolve(height);
                // code here to use the dimensions
            }
        }).then((response) => {
            return response;
        })



    } */

    render() {
        registerLocale('pt-BR', pt);
        setDefaultLocale("pt-BR");
        return (
            <>
                {this.renderLoadingConsultaSetor()}
                <Modal focusTrapped={false} showCloseIcon={false} open={this.props.modalViewImage} onClose={() => { }}>
                    <ContainerModalViewImage backgroundDinamico={this.props.backgroundPrimeiro}>
                        <ContainerIconeFecharImagemPreview backgroundDinamico={this.props.backgroundSegundo}>
                            {/*  <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Detalhes do Chamado</TextNovoAtendimento> */}
                            <div>
                                <IconeFecharImagePreview style={{ fontSize: 20 }} backgroundDinamico={this.props.coresLetraPadrao} onClick={() => {

                                    saveAs(this.props.urlPreViewImage, this.props.nameUrlPreview)
                                }} className="icon-download" />
                                <IconeFecharImagePreview style={{ fontSize: 17 }} backgroundDinamico={this.props.coresLetraPadrao} onClick={() => {
                                    this.props.modificaModalPreviewImage(false);
                                    this.props.modificaUrlPreviewImage(null)
                                    this.props.modificaNamePreviewImage('')
                                }} className="icon-close" />
                            </div>
                        </ContainerIconeFecharImagemPreview>
                        <div style={{ display: 'flex', flex: 1, background: this.props.backgroundPrimeiro, alignItems: 'center', justifyContent: 'center', overflow: 'auto' }}>
                            {/*  <InnerImageZoom
                                src={this.props.urlPreViewImage}
                                zoomScale={1.5}
                                hideHint
                                imgAttributes={{ height:'100%',width:'100%'  }}
                                height={'100%'}
                                width={'100%'}
                               // hasSpacer
                            /> */}
                            <ImagePreview
                                src={this.props.urlPreViewImage}
                                alt="Imagem"
                                zoomScale={1.5}
                                hideHint
                            // hasSpacer
                            />
                        </div>


                    </ContainerModalViewImage>
                </Modal>
                <Modal focusTrapped={false} showCloseIcon={false} open={this.props.modalDetalhesAtendimentoOnline} onClose={() => { }} center>
                    <ContainerModalAlterar backgroundDinamico={this.props.backgroundPrimeiro}>
                        <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                            <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Detalhes do Chamado</TextNovoAtendimento>
                            <div>
                                <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => { this.props.modificaVisibilidadeModalAtendimentoOnline(false) }} className="icon-close" />
                            </div>
                        </ContainerIconeFecharModal>
                        {this.renderConteudoDetalhesAtendimentoOnline()}



                    </ContainerModalAlterar>
                </Modal>
                <Modal focusTrapped={false} showCloseIcon={false} open={this.props.modalRespostaPadrao} onClose={() => { }} center>
                    <ContainerModalRespostas backgroundDinamico={this.props.backgroundPrimeiro}>
                        <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                            <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Respostas Padrões</TextNovoAtendimento>
                            <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => { this._toggleModalRespostaPadrao() }} className="icon-close" />
                        </ContainerIconeFecharModal>
                        <ContainerTextCabecalhoModalAcoes>
                            <TextCabecalhoModalAcoes corLetraDinamico={this.props.corBotaoPadrao}>Selecione uma resposta para comentar:</TextCabecalhoModalAcoes>
                        </ContainerTextCabecalhoModalAcoes>
                        <ContainerAtendentes2>

                            <FlatList
                                list={this.props.respostasPadroesAcoesChamado}
                                renderItem={(item, index) => this.renderRespostaPadrao(item, index)}
                                renderWhenEmpty={() => {
                                    return (
                                        <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                                            <MoonLoader
                                                size={40}
                                                color={this.props.corBotaoPadrao}
                                                loading={true}
                                            />
                                        </div>
                                    )
                                }

                                }
                            />
                        </ContainerAtendentes2>
                    </ContainerModalRespostas>
                </Modal>
                <Modal focusTrapped={false} showCloseIcon={false} open={this.state.modalAtribuirAtendimento} onClose={() => { }}>
                    <ContainerModal backgroundDinamico={this.props.backgroundPrimeiro}>
                        <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                            <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Transferir Atendimento</TextNovoAtendimento>
                            <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => { this.setState({ modalAtribuirAtendimento: false }) }} className="icon-close" />
                        </ContainerIconeFecharModal>
                        {this.renderModalTransferirAtendimento()}

                    </ContainerModal>
                </Modal>
                <Modal focusTrapped={false} showCloseIcon={false} open={this.state.modalEncerrarChat} onClose={() => { }} center>
                    <ContainerModalPrioridadeAtendimento backgroundDinamico={this.props.backgroundPrimeiro}>
                        <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                            <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Encerrar Atendimento</TextNovoAtendimento>
                            <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => { this.setState({ modalEncerrarChat: false }) }} className="icon-close" />
                        </ContainerIconeFecharModal>

                        {this.props.obrigatorioInformarResolucaoChamado === true && <ContainerAtendentes>
                            <ContainerInputEBotaoComent>
                                <DivInputComentarChamado >
                                    <InputDescricaoChamado2
                                        autoFocus
                                        onFocus={this.moveCaretAtEnd}
                                        type="textarea"
                                        onChange={(value) => { this.props.modificaResolucaoProblema(value.target.value) }}
                                        corLetraDinamico={this.props.coresLetraPadrao}
                                        value={this.props.solucaoAtendimento}
                                        corLetraSecundaria={this.props.coresLetraSecundario}
                                        corBordaDinamica={this.props.corBotaoPadrao}
                                        backgroundDinamico={this.props.backgroundPrimeiro}
                                        placeholder="Descreva a SOLUÇÃO do atendimento(CHAT)"
                                    />
                                </DivInputComentarChamado>
                            </ContainerInputEBotaoComent>

                            <DivInputMotivoCancelamento>

                                <ButtonNaoCancelarAtendimento onClick={() => { this.setState({ modalEncerrarChat: false }) }} borderColorDinamico={this.props.corBotaoPadrao} backgroundBotao={this.props.backgroundPrimeiro}>
                                    <TextBotaoCancelarAtendimento colorDinamico={this.props.corBotaoPadrao}>Não</TextBotaoCancelarAtendimento>
                                </ButtonNaoCancelarAtendimento>
                                <ButtonCancelarAtendimento onClick={() => {
                                    if (this.props.solucaoAtendimento !== '') {
                                        setTimeout(() => {
                                            firebase.database().ref(`${this.props.atendimentosPorKeyReducerChat.chaveUnica}/acoesChamados/${this.props.atendimentosPorKeyReducerChat.key}`).off("value");
                                            firebase.database().ref(`${this.props.atendimentosPorKeyReducerChat.chaveUnica}/chamados/`).orderByKey().equalTo(this.props.atendimentosPorKeyReducerChat.key).off("value")
                                            let arraySemChamadoEncerrado = this.props.chamadosEmAtendimentoOnline.filter(el => el.key !== this.props.atendimentosPorKeyReducerChat.key);
                                            this.props.modificaChamadosEmAtendimentoViaChatExport(arraySemChamadoEncerrado)

                                            let encerradoPorCliente = this.props.atendimentosPorKeyReducerChat.status === 'Fechado' ? true : false;
                                            this.props.atribuiStatusAtendimento(this.props.atendimentosPorKeyReducerChat.key, 'Fechado', this.props.atendimentosPorKeyReducerChat.dataAbertura, this.props.premium, this.props.atendimentosPorKeyReducerChat.keyAtendente, this.props.atendimentosPorKeyReducerChat, true, encerradoPorCliente, this.props.solucaoAtendimento, this.props.configWhatsapp, this.props.mensagemFinalizacaoAtendimentoWpp, this.props.desativarAssistenteVirtualBotWpp, this.props.mostrarNomeAtendenteWpp);

                                            this.props.modificaKeyAtendimentoEmAtendimentoOnline('');
                                            this.setState({ modalEncerrarChat: false });
                                        }, 0);

                                    } else {
                                        toastr.error('Error', 'Informe a solução do atendimento')
                                    }
                                }} backgroundBotao={this.props.corBotaoPadrao}>
                                    <TextBotaoCancelarAtendimento colorDinamico={this.props.letraBotaoPadrao}>Sim</TextBotaoCancelarAtendimento>
                                </ButtonCancelarAtendimento>
                            </DivInputMotivoCancelamento>
                        </ContainerAtendentes>}
                        {this.props.obrigatorioInformarResolucaoChamado === false && <ContainerAtendentes>
                            <DivTextoReabrirAtendimento >
                                <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Deseja realmente Finalizar o atendimento?</TextNovoAtendimento>
                            </DivTextoReabrirAtendimento>
                            <DivInputMotivoCancelamento>

                                <ButtonNaoCancelarAtendimento onClick={() => { this.setState({ modalEncerrarChat: false }) }} borderColorDinamico={this.props.corBotaoPadrao} backgroundBotao={this.props.backgroundPrimeiro}>
                                    <TextBotaoCancelarAtendimento colorDinamico={this.props.corBotaoPadrao}>Não</TextBotaoCancelarAtendimento>
                                </ButtonNaoCancelarAtendimento>
                                <ButtonCancelarAtendimento onClick={() => {

                                    setTimeout(() => {
                                        let dadosChamado = this.props.atendimentosPorKeyReducerChat;
                                        firebase.database().ref(`${dadosChamado.chaveUnica}/acoesChamados/${dadosChamado.key}`).off('value');
                                        firebase.database().ref(`${dadosChamado.chaveUnica}/chamados/${dadosChamado.key}`).off('value')
                                        let arraySemChamadoEncerrado = this.props.chamadosEmAtendimentoOnline.filter(el => el.key !== dadosChamado.key);
                                        this.props.modificaChamadosEmAtendimentoViaChatExport(arraySemChamadoEncerrado)

                                        let encerradoPorCliente = dadosChamado.status === 'Fechado' ? true : false;
                                        this.props.atribuiStatusAtendimento(dadosChamado.key, 'Fechado', dadosChamado.dataAbertura, this.props.premium, dadosChamado.keyAtendente, dadosChamado, true, encerradoPorCliente, 'Não informado', this.props.configWhatsapp, this.props.mensagemFinalizacaoAtendimentoWpp, this.props.desativarAssistenteVirtualBotWpp, this.props.mostrarNomeAtendenteWpp);

                                        this.props.modificaKeyAtendimentoEmAtendimentoOnline('');
                                        this.setState({ modalEncerrarChat: false });
                                    }, 0);
                                }} backgroundBotao={this.props.corBotaoPadrao}>
                                    <TextBotaoCancelarAtendimento colorDinamico={this.props.letraBotaoPadrao}>Sim</TextBotaoCancelarAtendimento>
                                </ButtonCancelarAtendimento>
                            </DivInputMotivoCancelamento>
                        </ContainerAtendentes>}
                    </ContainerModalPrioridadeAtendimento>
                </Modal>
                <Modal focusTrapped={false} showCloseIcon={false} open={this.state.viewMicrophone} onClose={() => { }} center>
                    <ContainerModalGravarAudio backgroundDinamico={this.props.backgroundPrimeiro}>
                        <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                            <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Gravar Áudio</TextNovoAtendimento>
                            <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => {
                                this.onStopRecord(true)
                                this._toggleModalGravarAudio()

                            }} className="icon-close" />
                        </ContainerIconeFecharModal>

                        {/* <Recorder
                            record={false}
                            title={"New recording"}
                            audioURL={this.state.audioDetails.url}
                            showUIAudio
                            hideHeader
                            handleAudioStop={data => this.handleAudioStop(data)}
                            handleOnChange={(value) => this.handleOnChange(value, 'firstname')}
                            handleAudioUpload={data => this.handleAudioUpload(data)}
                            handleRest={() => this.handleRest()}
                            handleAudioStart={() => {}} /> */}
                        <div style={{ backgroundColor: this.state.statusAudio == 'gravando' ? '#EC6A69' : this.props.backgroundPrimeiro }} >


                            {this.state.statusAudio !== 'gravacao' && this.state.statusAudio !== 'executandoAudio' && <div style={{ display: 'flex', height: 180, alignItems: 'center', justifyContent: 'center' }}>
                                <p style={{
                                    color: this.props.coresLetraPadrao,
                                    fontSize: 45,
                                    fontWeight: '200',
                                    letterSpacing: 3,
                                }}>{this.transformaMinutosEmSegundos(this.state.recordSecs)}</p>

                            </div>}
                            <div style={{
                                display: 'flex',
                                height: 50,
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'row',
                                marginLeft: 5,
                            }}>
                                <div></div>
                                {this.renderTextGravacaoAudio()}
                                <div ></div>
                            </div>
                            {this.renderBotaoGravarAudio()}
                        </div>


                    </ContainerModalGravarAudio>
                </Modal>
                <Modal focusTrapped={false} showCloseIcon={false} open={this.state.modalAtenderAtendimentoEmProcesso} onClose={() => { }} center>
                    <ContainerModalPrioridadeAtendimento backgroundDinamico={this.props.backgroundPrimeiro}>
                        <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                            <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Atenção</TextNovoAtendimento>
                            <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => { this.setState({ modalAtenderAtendimentoEmProcesso: false }); this.props.ativaDesativaLoadingIniciandoCOnversaContactsExport(false); }} className="icon-close" />
                        </ContainerIconeFecharModal>

                        <ContainerAtendentes>
                            <ContainerInputEBotaoComent style={{ margin: 15, justifyContent: 'center' }}>
                                {this.state.dadosModalAtendimentoEmProcesso.length > 0 && this.props.dadosContactsSelecionado.length > 0 && this.state.dadosModalAtendimentoEmProcesso[0].statusChamado === 'Atendendo' && <p style={{ fontSize: 17, color: this.props.coresLetraPadrao }}>O cliente <span style={{ fontWeight: 'bold' }}>{this.props.dadosContactsSelecionado[0].nome}</span> já está EM ATENDIMENTO com o atendente <span style={{ fontWeight: 'bold' }}>{this.state.dadosModalAtendimentoEmProcesso[0].atendente}</span>, deseja abrir um atendimento e participar da conversa?</p>}
                                {this.state.dadosModalAtendimentoEmProcesso.length > 0 && this.props.dadosContactsSelecionado.length > 0 && this.state.dadosModalAtendimentoEmProcesso[0].statusChamado === 'Aberto' && this.props.verificaAgrupaAtendenteSetor === false && <p style={{ fontSize: 17, color: this.props.coresLetraPadrao }}>O cliente <span style={{ fontWeight: 'bold' }}>{this.props.dadosContactsSelecionado[0].nome}</span> já está com um atendimento em ABERTO na fila de Espera, Deseja puxar esse atendimento pra você?</p>}
                                {this.state.dadosModalAtendimentoEmProcesso.length > 0 && this.props.dadosContactsSelecionado.length > 0 && this.state.dadosModalAtendimentoEmProcesso[0].statusChamado === 'Aberto' && this.props.verificaAgrupaAtendenteSetor === true && <p style={{ fontSize: 17, color: this.props.coresLetraPadrao }}>O cliente <span style={{ fontWeight: 'bold' }}>{this.props.dadosContactsSelecionado[0].nome}</span> já está com um atendimento em ABERTO na fila de Espera, no setor de <span style={{ fontWeight: 'bold' }}>{this.state.dadosModalAtendimentoEmProcesso[0].setor}</span>, Deseja puxar esse atendimento pra você?</p>}
                            </ContainerInputEBotaoComent>
                            {/*  <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                                <p style={{ fontSize: 22, color: this.props.coresLetraPadrao, fontWeight: 'bold' }}>Deseja realmente Encerrar este Chat?</p>
                            </div> */}
                            <DivInputMotivoCancelamento>

                                <ButtonNaoCancelarAtendimento onClick={() => { this.setState({ modalAtenderAtendimentoEmProcesso: false }); this.props.ativaDesativaLoadingIniciandoCOnversaContactsExport(false); }} borderColorDinamico={this.props.corBotaoPadrao} backgroundBotao={this.props.backgroundPrimeiro}>
                                    <TextBotaoCancelarAtendimento colorDinamico={this.props.corBotaoPadrao}>Não</TextBotaoCancelarAtendimento>
                                </ButtonNaoCancelarAtendimento>
                                <ButtonCancelarAtendimento onClick={() => {
                                    if (this.state.dadosModalAtendimentoEmProcesso[0].statusChamado === 'Aberto') {

                                        firebase.database().ref(`${this.state.dadosModalAtendimentoEmProcesso[0].chaveUnica}/acoesChamados/${this.state.dadosModalAtendimentoEmProcesso[0].keyChamado}`).off("value");
                                        let arraySemChamadoAtendido = this.props.chamadosEmEsperaAtendimentoOnline.filter(el => el.key !== this.state.dadosModalAtendimentoEmProcesso[0].keyChamado);
                                        this.props.enviaAtendimentoEmEsperaAtendimentoOnlineExport(arraySemChamadoAtendido)
                                        this.props.iniciaAtendimentoPorContacts(this.state.dadosModalAtendimentoEmProcesso[0].keyCliente, this.props.keyUsuarioLogado, this.props.nome, this.props.dadosContactsSelecionado[0].phone, true, this.state.dadosModalAtendimentoEmProcesso[0].keyChamado, this.state.dadosModalAtendimentoEmProcesso[0].statusChamado, this.state.dadosModalAtendimentoEmProcesso[0].keySetor, this.state.dadosModalAtendimentoEmProcesso[0].numeroChamado, []);
                                        this.zeraMensagemResponder()
                                        this.setState({ modalAtenderAtendimentoEmProcesso: false });
                                        setTimeout(() => {
                                            this.setState({ defaultTabIndex: 0 })
                                        }, 1000);
                                    } else {
                                        if (this.state.dadosModalAtendimentoEmProcesso[0].keyAtendente === this.props.keyUsuarioLogado) {
                                            toastr.warning('Error', 'Este atendimento está sendo atendido por você mesmo, clique em CONVERSAS para enviar mensagens')
                                        } else {

                                            this.props.iniciaAtendimentoPorContacts(this.state.dadosModalAtendimentoEmProcesso[0].keyCliente, this.props.keyUsuarioLogado, this.props.nome, this.props.dadosContactsSelecionado[0].phone, true, this.state.dadosModalAtendimentoEmProcesso[0].keyChamado, this.state.dadosModalAtendimentoEmProcesso[0].statusChamado, this.state.dadosModalAtendimentoEmProcesso[0].keySetor, this.state.dadosModalAtendimentoEmProcesso[0].numeroChamado, []);
                                            this.zeraMensagemResponder()
                                        }
                                    }
                                }} backgroundBotao={this.props.corBotaoPadrao}>
                                    <TextBotaoCancelarAtendimento colorDinamico={this.props.letraBotaoPadrao}>Sim</TextBotaoCancelarAtendimento>
                                </ButtonCancelarAtendimento>
                            </DivInputMotivoCancelamento>
                        </ContainerAtendentes>
                    </ContainerModalPrioridadeAtendimento>
                </Modal>
                <Modal focusTrapped={false} showCloseIcon={false} open={this.state.modalEncaminharMensagem} onClose={() => { }} center>
                    <ContainerModalEncaminharMensagem backgroundDinamico={this.props.backgroundPrimeiro}>
                        <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                            <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Encaminhar Mensagem para</TextNovoAtendimento>
                            <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => {
                                this._toggleModalEncaminharMensagem();
                                this.zeraListaContatosParaEncaminhar();
                            }} className="icon-close" />
                        </ContainerIconeFecharModal>
                        <DivPesquisaEncaminharMensagem backgrounddinamico={this.props.backgroundSegundo}>
                            <ContainerInputPesquisaConversaEncaminharMensagem
                                corLetraDinamico={this.props.coresLetraPadrao}
                                backgroundinamico={this.props.backgroundPrimeiro}
                                placeholderColor={this.props.coresLetraPadrao}
                                corBordaDinamica={this.props.corBotaoPadrao}
                            >
                                <i data-tip={"Pesquisar pelo nome do cliente"} data-for="tip-top" onClick={() => { this.inputPesquisa.focus() }} className="icon-magnifier" style={{ fontSize: 13, color: this.props.coresLetraPadrao, cursor: 'pointer' }} />
                                <InputPesquisaConversa
                                    ref={ref => this.inputPesquisa = ref}
                                    corLetraDinamico={this.props.coresLetraPadrao}
                                    backgroundinamico={this.props.backgroundPrimeiro}
                                    placeholderColor={this.props.coresLetraPadrao}
                                    corBordaDinamica={this.props.corBotaoPadrao}
                                    type="text"
                                    placeholder={this.props.configWhatsapp !== undefined && this.props.configWhatsapp.length > 0 ? 'Pesquisar conversas' : 'Pesquisar Atendimentos'}
                                    // onChange={(value) => this.updateSearchEncaminhar(value.target.value)}
                                    onChange={(value) => {
                                        this.setState({ pesquisaConversasOuContatosEncaminharMensagem: value.target.value });
                                        this.props.ativaDesativaLoadingCarregaContatosWppExport(true);
                                        this.searchDebounce(value.target.value, false);
                                    }}
                                    value={this.state.pesquisaConversasOuContatosEncaminharMensagem}
                                />
                                {this.state.pesquisaConversasOuContatosEncaminharMensagem !== '' && <i onClick={() => { this.setState({ pesquisaConversasOuContatosEncaminharMensagem: '' }); this.updateSearchEncaminhar('') }} className="icon-close" style={{ fontSize: 11, color: this.props.coresLetraPadrao, cursor: 'pointer', marginRight: 10 }} />}
                            </ContainerInputPesquisaConversaEncaminharMensagem>

                        </DivPesquisaEncaminharMensagem>
                        <ContainerAtendentes style={{ marginBottom: this.state.dadosMensagemParaSerEncaminhada.length > 0 ? 60 : 0 }}>
                            {this.props.chamadosEmAtendimentoOnline.length > 0 && this.state.noDataAtendimentoEncaminharMensagem === false && <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                                <p style={{ fontSize: 20, paddingLeft: 20, paddingTop: 20, paddingBottom: 20, fontWeight: 'bold', color: this.props.corBotaoPadrao }}>Conversas</p>
                                {this.props.loadingCarregaChatsEmAtendimento === false && this.state.pesquisaConversasOuContatosEncaminharMensagem === '' &&
                                    <FlatList
                                        list={this.props.chamadosEmAtendimentoOnline}
                                        renderItem={(item) => this.renderConversasEncaminharFAtendimentos(item)}
                                        /*    renderItem={(item) => <FlatListEmAtendimento data={item} key={item.key + item.alterado} />} */
                                        renderWhenEmpty={() => {
                                            return null

                                        }} />}
                                {this.props.loadingCarregaChatsEmAtendimento === false && this.state.pesquisaConversasOuContatosEncaminharMensagem !== '' && this.state.noDataAtendimentoEncaminharMensagem === false && <FlatList
                                    list={this.state.resultadosPesquisaAtendimentosEncaminharMensagem}
                                    renderItem={(item) => this.renderConversasEncaminharFAtendimentos(item)}
                                    renderWhenEmpty={() => {
                                        return null

                                    }}
                                />}
                            </div>}
                            {this.props.chamadosEmEsperaAtendimentoOnline.length > 0 && this.state.noDataEsperaEncaminharMensagem === false && <div style={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
                                <p style={{ fontSize: 20, paddingLeft: 20, paddingTop: 20, paddingBottom: 20, fontWeight: 'bold', color: this.props.corBotaoPadrao }}>Em Espera</p>
                                {this.props.loadingAtendimentoViaChat === false && this.state.pesquisaConversasOuContatosEncaminharMensagem === '' && <FlatList
                                    list={this.props.chamadosEmEsperaAtendimentoOnline}
                                    renderItem={(item) => this.renderConversasEncaminharFAtendimentos(item)}
                                    /*    renderItem={(item) => <FlatListEmAtendimento data={item} key={item.key + item.alterado} />} */

                                    renderWhenEmpty={() => {
                                        return null

                                    }} />}
                                {this.props.loadingAtendimentoViaChat === false && this.state.pesquisaConversasOuContatosEncaminharMensagem !== '' && this.state.noDataEsperaEncaminharMensagem === false && <FlatList
                                    list={this.state.resultadosPesquisaEsperaEncaminharMensagem}
                                    renderItem={(item) => this.renderConversasEncaminharFAtendimentos(item)}
                                    renderWhenEmpty={() => {
                                        return null

                                    }}
                                />}
                            </div>}
                            {this.props.configWhatsapp !== undefined && this.props.configWhatsapp.length > 0 && this.state.noDataContactsEncaminharMensagem === false && <div style={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
                                <p style={{ fontSize: 20, marginLeft: 20, marginTop: 20, marginBottom: 20, fontWeight: 'bold', color: this.props.corBotaoPadrao }}>Contatos</p>

                                {this.props.loadingCarregaContatosWpp === true &&
                                    <p style={{ fontSize: 18, color: this.props.coresLetraPadrao, textAlign: 'center' }}>Carregando Contatos ...</p>}

                                {this.state.pesquisaConversasOuContatosEncaminharMensagem === '' && this.props.loadingCarregaContatosWpp === false &&
                                    <FlatList
                                        list={this.props.allContactsWhatsapp}
                                        renderItem={(item) => this.renderConversasEncaminharContatos(item)}
                                        renderWhenEmpty={() => {
                                            if (this.props.loadingCarregaContatosWpp === true) {
                                                return <p style={{ fontSize: 18, color: this.props.coresLetraPadrao, textAlign: 'center' }}>Carregando Contatos</p>
                                            } else {
                                                return <p style={{ fontSize: 18, color: this.props.coresLetraPadrao, textAlign: 'center' }}>Error ao Carregar Contatos</p>
                                            }

                                        }}
                                    />}
                                {this.state.pesquisaConversasOuContatosEncaminharMensagem !== '' &&
                                    this.state.noDataContactsEncaminharMensagem === false &&
                                    this.props.loadingCarregaContatosWpp === false &&
                                    <FlatList
                                        list={this.state.resultadosPesquisaContactsEncaminharMensagem}
                                        renderItem={(item) => this.renderConversasEncaminharContatos(item)}
                                        renderWhenEmpty={() => {
                                            return null
                                        }}
                                    />}


                            </div>}
                            {this.state.dadosMensagemParaSerEncaminhada.length > 0 && <div style={{ display: 'flex', flexDirection: 'row', height: 60, width: 400, backgroundColor: this.props.backgroundSegundo, position: 'absolute', bottom: 0, center: 0, zIndex: 999, borderBottomRightRadius: 7, borderBottomLeftRadius: 7 }}>
                                <div style={{ display: 'flex', width: 320, flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                                    <DivTruncarTextoEncaminharMensagem style={{}} >
                                        {this.state.dadosMensagemParaSerEncaminhada.reverse().map((item) => {
                                            return (
                                                < p key={item.key} style={{ color: this.props.coresLetraPadrao, fontSize: 14, marginLeft: 5 }}>{item.cliente === undefined || item.cliente === null || item.cliente === '' ? this.state.dadosMensagemParaSerEncaminhada.length > 1 ? `${item.nome}, ` : item.nome : this.state.dadosMensagemParaSerEncaminhada.length > 1 ? `${item.cliente}, ` : item.cliente}</p>
                                            )
                                        })

                                        }
                                    </DivTruncarTextoEncaminharMensagem>
                                </div>
                                <div style={{ display: 'flex', width: 80, minWidth: 80, flexDirection: 'row', alignItems: 'center', justifyContent: 'center', zIndex: '998' }}>
                                    <ButtonEnviarMensagemEncaminhada onClick={() => {
                                        this.props.enviaMensagemEncaminhada(this.state.dadosMensagemParaSerEncaminhada, this.state.mensagemParaSerEncaminhada, this.props.nome, this.props.keyUsuarioLogado, this.props.configWhatsapp, this.props.desativarAssistenteVirtualBotWpp, this.props.mostrarNomeAtendenteWpp, this.state.dadosMensagemParaSerEncaminhada[0].telefoneCliente);
                                        this.zeraListaContatosParaEncaminhar()

                                    }} backgroundinamico={this.props.corBotaoPadrao} corLetraBotao={this.props.letraBotaoPadrao}>
                                        <i className="icon-send2" style={{ fontSize: 22, color: this.props.letraBotaoPadrao, cursor: 'pointer' }} />
                                    </ButtonEnviarMensagemEncaminhada>
                                </div>
                            </div>}
                        </ContainerAtendentes>
                    </ContainerModalEncaminharMensagem>
                </Modal>
                <Modal focusTrapped={false} showCloseIcon={false} open={this.state.modalAlterarNome} onClose={() => { }} center>
                    <ContainerModalPrioridadeAtendimento backgroundDinamico={this.props.backgroundPrimeiro}>
                        <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                            <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Alterar Nome Cliente</TextNovoAtendimento>
                            <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => { this.setState({ modalAlterarNome: false }) }} className="icon-close" />
                        </ContainerIconeFecharModal>

                        <ContainerAtendentes>
                            <ContainerInputEBotaoComent>
                                <DivInputComentarChamado >
                                    <InputDescricaoChamado2
                                        autoFocus
                                        type="textarea"
                                        onFocus={this.moveCaretAtEnd}
                                        onChange={(value) => { this.props.modificaNomeClienteTelaChatOnline(value.target.value) }}
                                        corLetraDinamico={this.props.coresLetraPadrao}
                                        value={this.props.nomeClienteChatOnline}
                                        corLetraSecundaria={this.props.coresLetraSecundario}
                                        corBordaDinamica={this.props.corBotaoPadrao}
                                        backgroundDinamico={this.props.backgroundPrimeiro}
                                        placeholder="Nome Cliente"
                                    />
                                </DivInputComentarChamado>
                            </ContainerInputEBotaoComent>
                            {/*  <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                                <p style={{ fontSize: 22, color: this.props.coresLetraPadrao, fontWeight: 'bold' }}>Deseja realmente Encerrar este Chat?</p>
                            </div> */}
                            <DivInputMotivoCancelamento>

                                <ButtonNaoCancelarAtendimento onClick={() => { this.setState({ modalAlterarNome: false }) }} borderColorDinamico={this.props.corBotaoPadrao} backgroundBotao={this.props.backgroundPrimeiro}>
                                    <TextBotaoCancelarAtendimento colorDinamico={this.props.corBotaoPadrao}>Não</TextBotaoCancelarAtendimento>
                                </ButtonNaoCancelarAtendimento>
                                <ButtonCancelarAtendimento onClick={() => {
                                    if (this.props.nomeClienteChatOnline.trim().length === 0) {
                                        toastr.error('Error', 'Digite um Nome');
                                    } else {
                                        let telefoneCliente;
                                        this.setState({ modalAlterarNome: false });

                                        if (this.props.keyAtendimentoEmAtendimentoOnline !== '') {
                                            this.props.atendimentosPorKeyReducerChat['cliente'] = this.props.nomeClienteChatOnline;
                                            for (let i = 0; i < this.props.chamadosEmAtendimentoOnline.length; i++) {
                                                if (this.props.chamadosEmAtendimentoOnline.key === this.props.atendimentosPorKeyReducerChat.key) {
                                                    this.props.chamadosEmAtendimentoOnline[i].cliente = this.props.nomeClienteChatOnline;
                                                    break;
                                                }
                                            }
                                            const newChamados = [...this.props.chamadosEmAtendimentoOnline]
                                            this.props.modificaChamadosEmAtendimentoViaChatExport(newChamados);
                                            firebase.database().ref(`${this.props.atendimentosPorKeyReducerChat.chaveUnica}/clientes/${this.props.atendimentosPorKeyReducerChat.keyCliente}`).update({ nome: this.props.nomeClienteChatOnline });
                                            removeFirebaseCachedData(`${this.props.atendimentosPorKeyReducerChat.chaveUnica}/clientes`, this.props.atendimentosPorKeyReducerChat.keyCliente);
                                            console.log(this.props.atendimentosPorKeyReducerChat);
                                            clearCacheRedis(this.props.atendimentosPorKeyReducerChat.chaveUnica, this.props.atendimentosPorKeyReducerChat.telefoneCliente);
                                        } else {
                                            this.props.atendimentosPorKeyReducerChatEmEspera['cliente'] = this.props.nomeClienteChatOnline;
                                            for (let i = 0; i < this.props.chamadosEmEsperaAtendimentoOnline.length; i++) {
                                                if (this.props.chamadosEmEsperaAtendimentoOnline.key === this.props.atendimentosPorKeyReducerChatEmEspera.key) {
                                                    this.props.chamadosEmEsperaAtendimentoOnline[i].cliente = this.props.nomeClienteChatOnline;
                                                    break;
                                                }
                                            }
                                            const newChamados = [...this.props.chamadosEmEsperaAtendimentoOnline]
                                            this.props.enviaAtendimentoEmEsperaAtendimentoOnlineExport(newChamados);


                                            firebase.database().ref(`${this.props.atendimentosPorKeyReducerChatEmEspera.chaveUnica}/clientes/${this.props.atendimentosPorKeyReducerChatEmEspera.keyCliente}`).update({ nome: this.props.nomeClienteChatOnline });
                                            removeFirebaseCachedData(`${this.props.atendimentosPorKeyReducerChatEmEspera.chaveUnica}/clientes`, this.props.atendimentosPorKeyReducerChatEmEspera.keyCliente);
                                            console.log(this.props.atendimentosPorKeyReducerChatEmEspera);
                                            clearCacheRedis(this.props.atendimentosPorKeyReducerChatEmEspera.chaveUnica, this.props.atendimentosPorKeyReducerChatEmEspera.telefoneCliente);
                                        }
                                    }
                                }} backgroundBotao={this.props.corBotaoPadrao}>
                                    <TextBotaoCancelarAtendimento colorDinamico={this.props.letraBotaoPadrao}>Sim</TextBotaoCancelarAtendimento>
                                </ButtonCancelarAtendimento>
                            </DivInputMotivoCancelamento>
                        </ContainerAtendentes>
                    </ContainerModalPrioridadeAtendimento>
                </Modal>
                <Modal focusTrapped={false} showCloseIcon={false} open={this.state.modalDesconectarWhatsapp} onClose={() => { }} center>
                    <ContainerModalPrioridadeAtendimento backgroundDinamico={this.props.backgroundPrimeiro}>
                        <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                            <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Desconectar Número WhatsApp</TextNovoAtendimento>
                            <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => { this.toggleModalDesconectarWhatsapp(); }} className="icon-close" />
                        </ContainerIconeFecharModal>

                        <ContainerAtendentes2>
                            {this.state.desconectandoContainer === false && <> <ContainerInputEBotaoComent>

                                <p style={{ color: this.props.coresLetraPadrao, fontSize: 20 }}> Deseja Realmente Desconectar seu WhatsApp?</p>
                                <p style={{ color: this.props.coresLetraPadrao, fontSize: 20 }}> Você não irá conseguir atender via WhatsApp pelo DropDesk.</p>
                            </ContainerInputEBotaoComent>
                                <DivBotaoSalvarComentario>
                                    <ButtonNaoCancelarAtendimento onClick={() => { this.toggleModalDesconectarWhatsapp(); }} borderColorDinamico={this.props.corBotaoPadrao} backgroundBotao={this.props.backgroundPrimeiro}>
                                        <TextBotaoCancelarAtendimento colorDinamico={this.props.corBotaoPadrao}>Não</TextBotaoCancelarAtendimento>
                                    </ButtonNaoCancelarAtendimento>
                                    <ButtonCancelarAtendimento onClick={() => { this.desconnectarContainerVenom() }} backgroundBotao={this.props.corBotaoPadrao}>
                                        <TextBotaoCancelarAtendimento colorDinamico={this.props.letraBotaoPadrao}>Desconectar</TextBotaoCancelarAtendimento>
                                    </ButtonCancelarAtendimento>

                                </DivBotaoSalvarComentario></>}

                            {this.state.desconectandoContainer === true &&
                                <>
                                    <p style={{ fontSize: 19, color: this.props.coresLetraPadrao }}><b>Desconectando</b><br></br>Por favor Aguarde ...</p>
                                    <HashLoader
                                        css={{ marginTop: 15 }}
                                        size={60}
                                        color={'#00BEA4'}
                                        loading
                                    />
                                </>
                            }
                        </ContainerAtendentes2>
                    </ContainerModalPrioridadeAtendimento>
                </Modal>
                <Modal focusTrapped={false} showCloseIcon={false} open={this.state.modalConnectWhatsapp} onClose={() => { }} center>
                    <ContainerModalConectarWpp backgroundDinamico={'#EFF8FE'}>
                        <ContainerIconeFecharModal backgroundDinamico={'#00BEA4'}>
                            <TextNovoAtendimento corLetraDinamico={'#FFF'}>Connectar Número WhastApp</TextNovoAtendimento>
                            <IconeFechar backgroundDinamico={'#FFF'} onClick={() => {
                                this.toggleModalConectarWhatsapp()
                            }} className="icon-close" />
                        </ContainerIconeFecharModal>
                        {this.state.infoConectadoOk === false && <> <div style={{ marginTop: 10, marginLeft: 10 }}>
                            <p style={{ color: '#000', fontSize: 15 }}>1 - Abra o Whatsapp no seu Celular</p>
                            <p style={{ color: '#000', fontSize: 15 }}>2 - Toque em <b>"Menu"</b> <i className="icon-menu3" style={{ fontSize: 14, color: '#000' }} /> ou <b>"Configurações"</b>  <i className="icon-settings" style={{ fontSize: 14, color: '#000' }} /> e selecione<b>"WhatsApp Web"</b></p>
                            <p style={{ color: '#000', fontSize: 15 }}>3 - Aponte o seu celular para essa tela para capturar o <b>código</b></p>
                        </div>
                            <ContainerAtendentes2>

                                {this.renderConteudoModalConnectWhatsapp()}

                            </ContainerAtendentes2>
                            <div style={{ marginTop: 10, marginLeft: 10, marginBottom: 20, display: 'flex', flexDirection: 'row' }}>
                                <p style={{ color: '#000', fontSize: 17 }}>Ao registrar seu número, você concorda com os </p>
                                <a style={{ marginLeft: 5 }} href="https://www.whatsapp.com/legal/terms-of-service?lang=pt_br" target="_blank"><p onClick={() => { }} style={{ color: '#39a3f4', fontSize: 17, textDecoration: 'underline', cursor: 'pointer' }}>Termos de banimento do WhatsApp</p></a>
                            </div></>}
                        {this.state.infoConectadoOk === true && <> <div style={{ marginTop: 20 }}>
                            <p style={{ color: '#000', fontSize: 20, textAlign: 'center' }}>Parabéns, conectado com <span style={{ color: '#00BEA4', fontSize: 22 }}><b>Sucesso!</b></span></p>
                        </div>
                            <ContainerAtendentes2>

                                <img src={approved} alt="conectado" style={{ height: 200, width: 200 }} />

                            </ContainerAtendentes2>
                            <div style={{ marginTop: 10, marginLeft: 10, marginBottom: 40, display: 'flex', flexDirection: 'column' }}>
                                <p style={{ color: '#000', fontSize: 17 }}><b>IMPORTANTE:</b> Configure seu Horário de Operação em <b>"Configuração do Chat"</b> ou </p>
                                <Link to="/configuracao" onClick={() => { this.props.modificaClicouCFGchat(true); this.toggleModalConectarWhatsapp(); }} style={{ color: '#39a3f4', fontSize: 17, textDecoration: 'underline', cursor: 'pointer', textAlign: 'center' }}><b>Clique aqui para Configurar</b></Link>
                            </div></>}
                    </ContainerModalConectarWpp>
                </Modal>
                <Modal focusTrapped={false} showCloseIcon={false} open={this.props.modalNovoContato} onClose={() => {

                }} center>
                    <ContainerModalNovoCliente onSubmit={(e) => this.cadastraClienteContato(e)} backgroundDinamico={this.props.backgroundPrimeiro}>
                        <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                            <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Adicionar Contato</TextNovoAtendimento>
                            <div>
                                <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => {
                                    this.props.modificaVisibilidadeModalAdicionarContato(false)
                                }} className="icon-close" />
                            </div>
                        </ContainerIconeFecharModal>
                        <SubContainerCliente>

                            <ContainerImagemAlterarCliente>
                                <Dropzone
                                    accept="image/*"
                                    multiple={false}
                                    noKeyboard
                                    maxSize={5e+6}
                                    noDrag
                                    onDrop={acceptedFiles => this.escolheArquivoChamadoNovoCadastro(acceptedFiles)}>
                                    {({ getRootProps, getInputProps, isDragActive }) => {

                                        return (
                                            /*     <section> */
                                            <div  {...getRootProps()}>
                                                <input {...getInputProps()} />
                                                <ImagePerfil bordaDinamico={this.props.corBotaoPadrao} src={this.props.uriImagemPadraoTelaCadastroNovoContato} alt="foto cliente" />
                                            </div>
                                            /*   </section> */
                                        )
                                    }}
                                </Dropzone>
                            </ContainerImagemAlterarCliente>
                            <ContainerDadosNovoCliente>

                                <p style={{ color: this.props.corLetraDescricaoDosCampos, fontWeight: 'bold', fontSize: 12, marginTop: 5 }}>Nome</p>
                                <InputEmpresa
                                    autoFocus
                                    type="text"
                                    placeholder="Nome do Contato"
                                    colorDinamica={this.props.coresLetraPadrao}
                                    placeholderDinamico={this.props.coresLetraSecundario}
                                    borderDinamico={this.props.corBotaoPadrao}
                                    value={this.props.nomeContato}
                                    onChange={(e) => { this.props.modificaNomeContato(e.target.value) }}
                                //  onFocus={() => { this.handleInputEmailFocus() }}
                                // onBlur={() => { this.handleInputEmailBlur() }}
                                />

                                <p style={{ color: this.props.corLetraDescricaoDosCampos, fontWeight: 'bold', fontSize: 12, marginTop: 15 }}>Telefone  <span style={{ color: 'orange', marginleft: 15 }}>(55) 21 98888 - 8888</span></p>
                                <InputEmpresa


                                    onChange={(e) => {
                                        this.props.modificaTelefoneContato(e.target.value.replace(/\D/g, ""))
                                    }}
                                    value={this.props.telefoneContato}
                                    type="text"
                                    colorDinamica={this.props.coresLetraPadrao}
                                    placeholderDinamico={this.props.coresLetraSecundario}
                                    borderDinamico={this.props.corBotaoPadrao}
                                //  onFocus={() => { this.handleInputEmailFocus() }}
                                // onBlur={() => { this.handleInputEmailBlur() }}
                                />

                                {/*    <InputEmpresa
                                   type="text"
                                    placeholder="Ex: 5521997622412"
                                    colorDinamica={this.props.coresLetraPadrao}
                                    placeholderDinamico={this.props.coresLetraSecundario}
                                    borderDinamico={this.props.corBotaoPadrao}
                                    value={this.props.telefoneContato}
                                    onChange={(e) => { this.props.modificaTelefoneContato(e.target.value.replace(/\D/,'')) }}
                                //  onFocus={() => { this.handleInputEmailFocus() }}
                                // onBlur={() => { this.handleInputEmailBlur() }}
                                /> */}
                            </ContainerDadosNovoCliente>
                        </SubContainerCliente>
                        <SubContainerClientePermissao>

                            <ContainerBotaoAlterar>
                                {this.props.loadingBotaoCadastrarContato == false && <ButtonEntrar backGroundDinamico={this.props.corBotaoPadrao} corLetraBotao={this.props.letraBotaoPadrao} type="submit" onClick={(e) => this.cadastraClienteContato(e)}>
                                    Adicionar
                                </ButtonEntrar>}
                                {this.props.loadingBotaoCadastrarContato == true && <ClipLoader
                                    css={{ marginBottom: 10 }}
                                    size={35}
                                    color={this.props.corBotaoPadrao}
                                    loading={this.props.loadingBotaoCadastrarContato}
                                />}
                            </ContainerBotaoAlterar>
                        </SubContainerClientePermissao>
                    </ContainerModalNovoCliente>
                </Modal>
                <Modal focusTrapped={false} showCloseIcon={false} open={this.state.modalCancelarAtendimento} onClose={() => { }} center>
                    <ContainerModalPrioridadeAtendimento backgroundDinamico={this.props.backgroundPrimeiro}>
                        <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                            <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Cancelar Atendimento</TextNovoAtendimento>
                            <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => { this.modificaVisibilidadeModalCancelarAtendimento() }} className="icon-close" />
                        </ContainerIconeFecharModal>

                        {this.props.obrigatorioInformarMotivoCancelamento === true && <ContainerAtendentes>
                            <DivInputMotivoCancelamento >
                                <InputCancelamento
                                    corLetraDinamico={this.props.coresLetraPadrao}
                                    autoFocus
                                    type="text"
                                    placeholder="Motivo do Cancelamento"
                                    corBordaDinamica={this.props.corBotaoPadrao}
                                    value={this.state.motivoCancelamento}
                                    onChange={(e) => { this.setState({ motivoCancelamento: e.target.value }) }}
                                // onFocus={() => { this.handleInputEmailFocus() }}
                                // onBlur={() => { this.handleInputEmailBlur() }}
                                />
                            </DivInputMotivoCancelamento>
                            <DivInputMotivoCancelamento>

                                <ButtonNaoCancelarAtendimento onClick={() => { this.modificaVisibilidadeModalCancelarAtendimento() }} borderColorDinamico={this.props.corBotaoPadrao} backgroundBotao={this.props.backgroundPrimeiro}>
                                    <TextBotaoCancelarAtendimento colorDinamico={this.props.corBotaoPadrao}>Não</TextBotaoCancelarAtendimento>
                                </ButtonNaoCancelarAtendimento>
                                <ButtonCancelarAtendimento onClick={() => { this.cancelarChamadoLocal(); }} backgroundBotao={this.props.corBotaoPadrao}>
                                    <TextBotaoCancelarAtendimento colorDinamico={this.props.letraBotaoPadrao}>Sim</TextBotaoCancelarAtendimento>
                                </ButtonCancelarAtendimento>
                            </DivInputMotivoCancelamento>
                        </ContainerAtendentes>}

                        {this.props.obrigatorioInformarMotivoCancelamento === false &&
                            <ContainerAtendentes>
                                <DivTextoReabrirAtendimento >
                                    <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Deseja realmente Cancelar o atendimento?</TextNovoAtendimento>
                                </DivTextoReabrirAtendimento>
                                <DivInputMotivoCancelamento>

                                    <ButtonNaoCancelarAtendimento onClick={() => { this.modificaVisibilidadeModalCancelarAtendimento() }} borderColorDinamico={this.props.corBotaoPadrao} backgroundBotao={this.props.backgroundPrimeiro}>
                                        <TextBotaoCancelarAtendimento colorDinamico={this.props.corBotaoPadrao}>Não</TextBotaoCancelarAtendimento>
                                    </ButtonNaoCancelarAtendimento>
                                    <ButtonCancelarAtendimento onClick={() => {
                                        if (this.props.keyAtendimentoEmAtendimentoOnline !== '') {
                                            setTimeout(() => {
                                                this.modificaVisibilidadeModalCancelarAtendimento();
                                                firebase.database().ref(`${this.props.atendimentosPorKeyReducerChat.chaveUnica}/acoesChamados/${this.props.atendimentosPorKeyReducerChat.key}`).off("value");
                                                firebase.database().ref(`${this.props.atendimentosPorKeyReducerChat.chaveUnica}/chamados/`).orderByKey().equalTo(this.props.atendimentosPorKeyReducerChat.key).off("value")
                                                let arraySemChamadoEncerrado = this.props.chamadosEmAtendimentoOnline.filter(el => el.key !== this.props.atendimentosPorKeyReducerChat.key);
                                                this.props.modificaChamadosEmAtendimentoViaChatExport(arraySemChamadoEncerrado)
                                                this.props.cancelarChamado(this.props.atendimentosPorKeyReducerChat.key, '', this.props.atendimentosPorKeyReducerChat.keyAtendente, this.props.atendimentosPorKeyReducerChat, this.props.obrigatorioInformarMotivoCancelamento)

                                                this.props.modificaKeyAtendimentoEmAtendimentoOnline('');
                                                this.setState({ motivoCancelamento: '' })
                                            }, 0);
                                        } else {
                                            setTimeout(() => {
                                                this.modificaVisibilidadeModalCancelarAtendimento();
                                                firebase.database().ref(`${this.props.atendimentosPorKeyReducerChatEmEspera.chaveUnica}/acoesChamados/${this.props.atendimentosPorKeyReducerChatEmEspera.key}`).off("value");
                                                firebase.database().ref(`${this.props.atendimentosPorKeyReducerChatEmEspera.chaveUnica}/chamados/`).orderByKey().equalTo(this.props.atendimentosPorKeyReducerChatEmEspera.key).off("value")

                                                let arraySemChamadoAtendido = this.props.chamadosEmEsperaAtendimentoOnline.filter(el => el.key !== this.props.atendimentoEmEsperaSelecionado.key);
                                                this.props.enviaAtendimentoEmEsperaAtendimentoOnlineExport(arraySemChamadoAtendido)
                                                this.props.cancelarChamado(this.props.atendimentosPorKeyReducerChatEmEspera.key, '', this.props.atendimentosPorKeyReducerChatEmEspera.keyAtendente, this.props.atendimentosPorKeyReducerChatEmEspera, this.props.obrigatorioInformarMotivoCancelamento)

                                                this.props.modificaKeyAtendimentoEmEsperaAtendimentoOnline('');
                                                this.setState({ motivoCancelamento: '' })
                                            }, 0);
                                        }
                                    }} backgroundBotao={this.props.corBotaoPadrao}>
                                        <TextBotaoCancelarAtendimento colorDinamico={this.props.letraBotaoPadrao}>Sim</TextBotaoCancelarAtendimento>
                                    </ButtonCancelarAtendimento>
                                </DivInputMotivoCancelamento>
                            </ContainerAtendentes>
                        }
                    </ContainerModalPrioridadeAtendimento>
                </Modal>
                <Modal focusTrapped={false} showCloseIcon={false} open={this.props.modalAlterarChat} onClose={() => { }} center>
                    <ContainerModalAlterarConversa backgroundDinamico={this.props.backgroundPrimeiro}>
                        <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                            <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Alterar Atendimento</TextNovoAtendimento>
                            <div>
                                <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => { this._toggleModalAlterarChat() }} className="icon-close" />
                            </div>
                        </ContainerIconeFecharModal>


                        {/*   <div style={{ display: 'flex', flex: 1, flexDirection: 'column',backgroundColor:'#CCC' }}> */}
                        {this.renderConteudoModalAlteracao()}

                        {/*   {this.props.criarChamadoProblemaEstaticoAtendimento === false && <ContainerInputDescricaoChamado>
                            <strong style={{ color: this.props.corLetraDescricaoDosCampos, fontSize: 14, marginBottom: 2, marginLeft: 5 }}>Descrição do problema:</strong>
                            <InputDescricaoChamado
                                type="textarea"
                                corBordaDinamica={this.props.corBotaoPadrao}
                                onChange={(value) => { this.props.alteraDescricaoChamado(value.target.value) }}
                                corLetraDinamico={this.props.coresLetraPadrao}
                                value={this.props.descricaoAtendimento}
                                corLetraSecundaria={this.props.coresLetraSecundario}
                                placeholder=" Descreva o problema..."
                            />
                        </ContainerInputDescricaoChamado>} */}
                        {this.renderAlteraDescricaoChamadoDigitado()}
                        {this.props.criarChamadoProblemaEstaticoAtendimento == true &&
                            <SubContainerNovoAtendimento style={{ marginTop: 20, marginBottom: 15 }}>
                                <p style={{ marginLeft: 20, color: this.props.corLetraDescricaoDosCampos, fontSize: 14 }}>Problema:</p>
                                <ContainerSelectSearch>
                                    <SelectSearch
                                        renderValue={(valueProps) =>
                                            <DivInputSearch>
                                                <InputSearch
                                                    corLetraDinamico={this.props.coresLetraPadrao}
                                                    backgroundDinamico={this.props.backgroundMaisEscuro}
                                                    placeholderColor={this.props.corLetraSecundaria}
                                                    corBordaDinamica={this.props.corBotaoPadrao}
                                                    {...valueProps} ></InputSearch>
                                                <IconeSetaPraBaixo className="icon-angle-arrow-down" colorDinamico={this.props.coresLetraPadrao} />
                                            </DivInputSearch>
                                        }
                                        value={this.props.problemaEstaticoSelecionadoNovoAtendimento} options={this.props.problemasEstaticos} onChange={(value, option) => { this.props.modificaProblemaEstaticoSelecionadoNovoAtendimento(value); this.props.modificaNomeProblemaEstaticoSelecionadoNovoAtendimento(option.name); }} placeholder="Selecione o Problema" search />

                                </ContainerSelectSearch>
                            </SubContainerNovoAtendimento>
                        }
                        <DivBotaoNovoAtendimento>
                            <BotaoCadastrarAtendimento type="submit" onClick={() => {
                                let dadosChamado = this.props.atendimentosPorKeyReducerChat !== undefined ? this.props.atendimentosPorKeyReducerChat : this.props.atendimentosPorKeyReducerChatEmEspera !== undefined ? this.props.atendimentosPorKeyReducerChatEmEspera : undefined
                                if (dadosChamado !== undefined) {
                                    this.props.alterarInfoChat(
                                        this.props.prioridadeSelecionada,
                                        this.props.localAtendimentoSelecionado,
                                        this.props.descricaoAtendimento,
                                        this.props.dataVencimento,
                                        dadosChamado.key,
                                        [dadosChamado],
                                        dadosChamado,
                                        dadosChamado.status,
                                        this.props.criarChamadoProblemaEstaticoAtendimento,
                                        this.props.problemaEstaticoSelecionadoNovoAtendimento,
                                        this.props.nomeProblemaEstaticoSelecionadoNovoAtendimento,
                                        dadosChamado.descricaoProblemaEstatico,
                                        this.props.setorSelecionado
                                    )
                                    this.setState({ dataVencimento: '' })
                                    //  this._toggleModalAlterarChat()
                                }

                            }} backgroundDinamico={this.props.corBotaoPadrao}>
                                <TextCadastrar corLetraDinamico={this.props.letraBotaoPadrao}>Alterar</TextCadastrar>
                            </BotaoCadastrarAtendimento>
                        </DivBotaoNovoAtendimento>
                        {/*  </div> */}
                    </ContainerModalAlterarConversa>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = state => {
    return (
        {
            backgroundPrimeiro: state.CoresPadraoSistema.backgroundPrimeiro,
            backgroundSegundo: state.CoresPadraoSistema.backgroundSegundo,
            backgroundMaisEscuro: state.CoresPadraoSistema.backgroundMaisEscuro,
            coresLetraPadrao: state.CoresPadraoSistema.coresLetraPadrao,
            corBotaoPadrao: state.CoresPadraoSistema.corBotaoPadrao,
            corSegundoBotaoPadrao: state.CoresPadraoSistema.corSegundoBotaoPadrao,
            coresLetraSecundario: state.CoresPadraoSistema.coresLetraSecundario,
            letraBotaoPadrao: state.CoresPadraoSistema.letraBotaoPadrao,
            corLetraDescricaoDosCampos: state.CoresPadraoSistema.corLetraDescricaoDosCampos,
            nomeRota: state.HeaderReducer.nomeRota,
            testePesquisa: state.HeaderReducer.testePesquisa,

            nomeSetor: state.SetorReducer.nomeSetor,
            observacaoSetor: state.SetorReducer.observacaoSetor,
            loadingBotaoAlterarSetor: state.SetorReducer.loadingBotaoAlterarSetor,
            mensagemErroAlterarSetor: state.SetorReducer.mensagemErroAlterarSetor,
            backgroundMensagemErrorTelaAlterarSetor: state.SetorReducer.backgroundMensagemErrorTelaAlterarSetor,
            keySetor: state.SetorReducer.keySetor,
            verificaConexaoInternetAlteraSetor: state.SetorReducer.verificaConexaoInternetAlteraSetor,
            visibleModalExcluirSetor: state.SetorReducer.visibleModalExcluirSetor,
            loadingConteudoExcluirSetor: state.SetorReducer.loadingConteudoExcluirSetor,
            loadingSectionedAtendenteVinculadoSetor: state.SetorReducer.loadingSectionedAtendenteVinculadoSetor,
            atendentesVinculadosSetor: state.SetorReducer.atendentesVinculadosSetor,
            atendenteVinculadoSelecionado: state.SetorReducer.atendenteVinculadoSelecionado,
            verificaAgrupaAtendenteSetor: state.ChamadoReducer.verificaAgrupaAtendenteSetor,
            verificaAtendenteVeSomenteProprioAtendimento: state.ChamadoReducer.verificaAtendenteVeSomenteProprioAtendimento,
            contaPrincipal: state.AtendenteReducer.contaPrincipal,
            filtroSetor: state.SetorReducer.filtroSetor,
            setores: state.SetorReducer.setores,
            modalNovoSetor: state.SetorReducer.modalNovoSetor,

            inputDigitoConsultaSetor: state.SetorReducer.inputDigitoConsultaSetor,
            mensagemSucessoAlterarSetor: state.SetorReducer.mensagemSucessoAlterarSetor,
            verificaConexaoInternetConsultaSetor: state.SetorReducer.verificaConexaoInternetConsultaSetor,
            loadingConsultaSetor: state.SetorReducer.loadingConsultaSetor,
            loadingFimDaPaginaConsultaSetor: state.SetorReducer.loadingFimDaPaginaConsultaSetor,
            referenciaUltimaKeySetor: state.SetorReducer.referenciaUltimaKeySetor,
            loadingCarregarSetoresSectionedConsultaSetores: state.SetorReducer.loadingCarregarSetoresSectionedConsultaSetores,
            setoresSectionedConsulta: state.SetorReducer.setoresSectionedConsulta,
            setorSelecionadosectioned: state.SetorReducer.setorSelecionadosectioned,
            nomeSetoresSelecinadoSectioned: state.SetorReducer.nomeSetoresSelecinadoSectioned,
            refreshControlConsultaSetor: state.SetorReducer.refreshControlConsultaSetor,
            permissoesAcessarTelasAtendente: state.AtendenteReducer.permissoesAcessarTelasAtendente,
            licensawebouapp: state.PremiumReducer.licensawebouapp,
            premium: state.PremiumReducer.premium,
            nomeSetorNovoCadastro: state.SetorReducer.nomeSetorNovoCadastro,
            observacaoSetorNovoCadastro: state.SetorReducer.observacaoSetorNovoCadastro,
            atendenteVinculadoSelecionadoNovoCadastro: state.SetorReducer.atendenteVinculadoSelecionadoNovoCadastro,
            loadingBotaoCadastraSetor: state.SetorReducer.loadingBotaoCadastraSetor,
            retornaTelaClienteParaNovoAtendimentos: state.ChamadoReducer.retornaTelaClienteParaNovoAtendimentos,

            chamadosEmEsperaAtendimentoOnline: state.ChamadoReducer.chamadosEmEsperaAtendimentoOnline,
            atendimentoEmEsperaSelecionado: state.ChamadoReducer.atendimentoEmEsperaSelecionado,
            chamadosEmAtendimentoOnline: state.ChamadoReducer.chamadosEmAtendimentoOnline,
            keyUsuarioLogado: state.PerfilReducer.keyUsuarioLogado,
            nome: state.PerfilReducer.nome,




            dadosAtendimentosChat: state.ChamadoReducer.dadosAtendimentosChat,
            keyAtendimentoEmAtendimentoOnline: state.ChamadoReducer.keyAtendimentoEmAtendimentoOnline,
            modalDetalhesAtendimentoOnline: state.ChamadoReducer.modalDetalhesAtendimentoOnline,
            comentarioChat: state.ChamadoReducer.comentarioChat,
            textArquivoChat: state.ChamadoReducer.textArquivoChat,
            enviandoComentario: state.ChamadoReducer.enviandoComentario,
            atendentesAcoesChamado: state.ChamadoReducer.atendentesAcoesChamado,
            loadingCarregandoAtendenteTelaNovoChamado: state.ChamadoReducer.loadingCarregandoAtendenteTelaNovoChamado,
            atendimentosPorKeyReducerChat: state.ChamadoReducer.atendimentosPorKeyReducerChat[state.ChamadoReducer.keyAtendimentoEmAtendimentoOnline],
            keyAtendimentoEmEsperaAtendimentoOnline: state.ChamadoReducer.keyAtendimentoEmEsperaAtendimentoOnline,
            atendimentosPorKeyReducerChatEmEspera: state.ChamadoReducer.atendimentosPorKeyReducerChatEmEspera[state.ChamadoReducer.keyAtendimentoEmEsperaAtendimentoOnline],
            atendimentosPorKeyReducerChatEmEsperaTotal: state.ChamadoReducer.atendimentosPorKeyReducerChatEmEspera,
            loadingAtendimentoViaChat: state.ChamadoReducer.loadingAtendimentoViaChat,
            respostasPadroesAcoesChamado: state.ConfiguracaoReducer.respostasPadroesAcoesChamado,
            utilizaChatAtendimentoOnline: state.ConfiguracaoReducer.utilizaChatAtendimentoOnline,
            loadingEnviandoAudioChat: state.ChamadoReducer.loadingEnviandoAudioChat,
            solucaoAtendimento: state.ChamadoReducer.solucaoAtendimento,

            isAdmin: state.PerfilReducer.isAdmin,
            usuarioAdmVisualizaApenasChamadosDoSetorVinculado: state.PerfilReducer.usuarioAdmVisualizaApenasChamadosDoSetorVinculado,

            configWhatsapp: state.ChamadoReducer.configWhatsapp,

            allContactsWhatsapp: state.ChamadoReducer.allContactsWhatsapp,

            keyContacts: state.ChamadoReducer.keyContacts,
            dadosContactsSelecionado: state.ChamadoReducer.dadosContactsSelecionado,

            conversasUltimoAtendimento: state.ChamadoReducer.conversasUltimoAtendimento,
            loadingCarregaConversasUltimoAtendimento: state.ChamadoReducer.loadingCarregaConversasUltimoAtendimento,
            referenciaUltimakeyUltimoChamadoContatos: state.ChamadoReducer.referenciaUltimakeyUltimoChamadoContatos,

            refreshControlCarregaMaisChamados: state.ChamadoReducer.refreshControlCarregaMaisChamados,
            loadingIniciandoConversaViaContacts: state.ChamadoReducer.loadingIniciandoConversaViaContacts,
            loadingTelaAcoesAtendimento: state.ChamadoReducer.loadingTelaAcoesAtendimento,

            loadingCarregaChatsEmAtendimento: state.ChamadoReducer.loadingCarregaChatsEmAtendimento,
            loadingCarregaChatsEmEspera: state.ChamadoReducer.loadingCarregaChatsEmEspera,
            loadingCarregaContatosWpp: state.ChamadoReducer.loadingCarregaContatosWpp,
            loadingAssinalaErrorGetContatcs: state.ChamadoReducer.loadingAssinalaErrorGetContatcs,

            referenciaUltimakeyUltimoChamadoEmEspera: state.ChamadoReducer.referenciaUltimakeyUltimoChamadoEmEspera,
            refreshControlCarregaMaisChamadosEmEspera: state.ChamadoReducer.refreshControlCarregaMaisChamadosEmEspera,
            referenciaUltimakeyUltimoChamadoEmAtendimento: state.ChamadoReducer.referenciaUltimakeyUltimoChamadoEmAtendimento,
            refreshControlCarregaMaisChamadosEmAtendimento: state.ChamadoReducer.refreshControlCarregaMaisChamadosEmAtendimento,

            conversasCarregadasEmEspera: state.ChamadoReducer.conversasCarregadasEmEspera,
            conversasCarregadasEmAtendimento: state.ChamadoReducer.conversasCarregadasEmAtendimento,

            loadingCarregandoRespostasAntigas: state.ChamadoReducer.loadingCarregandoRespostasAntigas,
            idRespostaAntiga: state.ChamadoReducer.idRespostaAntiga,

            nomeClienteChatOnline: state.ChamadoReducer.nomeClienteChatOnline,

            dataValidoAte: state.PremiumReducer.dataValidoAte,
            dataCompra: state.PremiumReducer.dataCompra,
            dataValidadeAtual: state.PremiumReducer.dataValidadeAtual,

            uriImagemPadraoTelaCadastroNovoContato: state.ChamadoReducer.uriImagemPadraoTelaCadastroNovoContato,
            nomeContato: state.ChamadoReducer.nomeContato,
            telefoneContato: state.ChamadoReducer.telefoneContato,
            loadingBotaoCadastrarContato: state.ChamadoReducer.loadingBotaoCadastrarContato,
            modalNovoContato: state.ChamadoReducer.modalNovoContato,
            arquivoCompletoParaUparNovoContato: state.ChamadoReducer.arquivoCompletoParaUparNovoContato,

            mensagemFinalizacaoAtendimentoWpp: state.ChamadoReducer.mensagemFinalizacaoAtendimentoWpp,

            countMensagensNaoLidas: state.ChamadoReducer.countMensagensNaoLidas,
            acoesDoAtendimentoChat: state.ChamadoReducer.acoesDoAtendimentoChat,

            obrigatorioInformarResolucaoChamado: state.ChamadoReducer.obrigatorioInformarResolucaoChamado,

            loadingCarregandoChamadosEmEsperaRefreshControl: state.ChamadoReducer.loadingCarregandoChamadosEmEsperaRefreshControl,
            chamadosCarregadosEmEspera: state.ChamadoReducer.chamadosCarregadosEmEspera,
            keyReferenciaUltimoChamadoEmEspera: state.ChamadoReducer.keyReferenciaUltimoChamadoEmEspera,

            mostrarNomeAtendenteWpp: state.ConfiguracaoReducer.mostrarNomeAtendenteWpp,
            desativarAssistenteVirtualBotWpp: state.ConfiguracaoReducer.desativarAssistenteVirtualBotWpp,
            modalRespostaPadrao: state.ChamadoReducer.modalRespostaPadrao,

            obrigatorioInformarMotivoCancelamento: state.ConfiguracaoReducer.obrigatorioInformarMotivoCancelamento,

            atendenteVeApenasProprioHistoricoChamado: state.ChamadoReducer.atendenteVeApenasProprioHistoricoChamado,



            setorNovoChamado: state.SetorReducer.setoresSectionedConsulta,
            localAtendimentoSelecionado: state.ChamadoReducer.localAtendimentoSelecionado,
            localAtendimento: state.ChamadoReducer.localAtendimento,
            prioridadeSelecionada: state.ChamadoReducer.prioridadeSelecionada,
            prioridade: state.ChamadoReducer.prioridade,
            setorSelecionado: state.ChamadoReducer.setorSelecionado,
            descricaoAtendimento: state.ChamadoReducer.descricaoAtendimento,
            dataVencimento: state.ChamadoReducer.dataVencimento,
            criarChamadoProblemaEstaticoAtendimento: state.PremiumReducer.criarChamadoProblemaEstaticoAtendimento,

            modalViewImage: state.ChamadoReducer.modalViewImage,
            urlPreViewImage: state.ChamadoReducer.urlPreViewImage,
            nameUrlPreview: state.ChamadoReducer.nameUrlPreview,

            utilizaVersaoBETA: state.ConfiguracaoReducer.utilizaVersaoBETA,
            enviarMensagemTransSetorWpp: state.ChamadoReducer.enviarMensagemTransSetorWpp,
            problemaEstaticoSelecionadoNovoAtendimento: state.ChamadoReducer.problemaEstaticoSelecionadoNovoAtendimento,
            problemasEstaticos: state.ConfiguracaoReducer.problemasEstaticos,
            nomeProblemaEstaticoSelecionadoNovoAtendimento: state.ChamadoReducer.nomeProblemaEstaticoSelecionadoNovoAtendimento,

            modalAlterarChat: state.ChamadoReducer.modalAlterarChat,
            allClientes: state.DashBoardReducer.clientesExportarExcel,
        }
    );
};
export default connect(mapStateToProps, {
    modificaNomeRotaNoHeader, atribuiAtendimentoAh,
    modificaKeyAtendimentoEmEsperaAtendimentoOnline, modificaVisibilidadeModalAtendimentoOnline,
    atribuiStatusAtendimento, modificaKeyAtendimentoEmAtendimentoOnline, enviaAtendimentoParaReducerChatExport,
    alteraComentarioChat, salvarComentario, uploadArquivoChat, listaAtendentesTelaConsultaAtendente,
    listaRespostasPadroes, verificaSeChatEstaHabilitadoTelaAtendimentoOnline, modificaClicouCFGchat,
    desligaOuvintesChamadosFirebase, enviaAtendimentoEmEsperaAtendimentoOnlineExport, modificaChamadosEmAtendimentoViaChatExport,
    ativaDesativaLoadingEnviandoAudioChatExport, modificaResolucaoProblema, getAllContatcsWpp,
    listaConversasAntigasWppContacts, iniciaAtendimentoPorContacts, ativaDesativaLoadingIniciandoCOnversaContactsExport,
    listaConversasAntigasWppEmEspera, listaConversasAntigasWppEmAtendimento,
    enviaUltimaReeferenciaKeyEmEsperaExport, enviaConversasCarregadasEmEsperaExport,
    enviaUltimaReeferenciaKeyEmAtendimentoExport, enviaConversasCarregadasEmAtendimentoExport,
    listaConversasAntigasResponder, enviaAcoesAtendimentoParaReducerChatExport,
    enviaMensagemEncaminhada, comentaEAtribuiAtendimento, modificaNomeClienteTelaChatOnline, modificaArquivoCompletoParaUparNovoContato,
    insereFotoCadastroContato, modificaVisibilidadeModalAdicionarContato, modificaTelefoneContato, modificaNomeContato,
    cadastraContato, modificaTitleTab, cancelarChamado,
    listaSetorSectionedTelaConsultaSetor, transferiAtendimentoParaSetorChat,
    modificaKeyContacts, modificaDadosContactsSelecionado, enviaContatosParaReducerExport,
    listaOsUltimosChamadosEmEspera, modificaloadingCarregandoChamadosEmEsperaRefreshControlExport,
    modificaModalRespostaPadrao, modificaModalPreviewImage, modificaUrlPreviewImage,
    modificaNamePreviewImage, modificaPrioridadeSelecionado, modificaLocalSelecionado, modificaDataVencimentoTelaChamadoAbertura, alteraDescricaoChamado,
    modificaProblemaEstaticoSelecionadoNovoAtendimento, modificaNomeProblemaEstaticoSelecionadoNovoAtendimento,
    listaProblemasEstaticosTelaNovoChamado, alterarInfoChat, modificaSetorSelecionado, modificaVisibilidadeModalAlterarChat,
    ativaDesativaLoadingCarregaContatosWppExport
})(withRouter(AtendimentoOnline));
