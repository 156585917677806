import styled from 'styled-components';
import { shade, lighten } from 'polished';


export const Container = styled.div`
 display:flex;
 flex:1;
 padding:32px;
 align-items:center;
 flex-direction:column;
`;

export const SubContainer = styled.div`
 display:flex;
 height:500px;
 width:768px;
 background: pink;
 background:${props => props.backgroundDinamico};
 flex-direction: column;
 border-radius: 5px;
`;

export const Header = styled.div`
 display:flex;
 height:60px;
 width:100%;
 flex-direction: row;
 border-bottom: .5px solid ${props => props.bordercolor};
`;
export const Content = styled.div`
 display:flex;
 flex:1;
`;
export const Footer = styled.div`
 display:flex;
 height:80px;
 flex-direction: row;
 width:100%;
 padding:8px;
 align-items: center;
 justify-content: flex-end;
 border-top: .5px solid ${props => props.bordercolor};
`;
export const ContainerLink = styled.div`
 display:flex;
 width:100%;
 align-items: center;
 flex-direction: row;
`;

export const ButtonExport = styled.button`
display:flex;
flex-direction:row;
border:0;
justify-content:center;
height:45px;
min-height:45px;
width:100px;
align-items:center;
border-radius:5px;
transition: background-color 0.2s;
background:${props => props.backgroundbotao};
font-size: 17px;
font-weight: bold;
&:hover{
   background:${props => shade(0.2, props.backgroundbotao)};
}
`;


export const ButtonDownloadFooter = styled.button`
display:flex;
flex-direction:row;
justify-content:center;
height:45px;
min-height:45px;
width:140px;
align-items:center;
border-radius:5px;
transition: background-color 0.2s;
background:${props => props.backgroundbotao};
font-size: 17px;
font-weight: bold;
border:1px solid ${props => props.bordercolor};
margin-right: 16px;
color:${props => props.bordercolor};
&:hover{
   background:${props => shade(0.2, props.backgroundbotao)};
}
`;

export const TextExport = styled.p`
font-size:20px;
font-weight:bold;
color:${props => props.corLetraDinamica};
`;

export const ContainerExportDefault = styled.div`
 display:flex;
flex:1;
 align-items: center;
justify-content: center;
flex-direction: column;
`;

export const ContainerLoading = styled.div`
 display:flex;
flex:1;
 align-items: center;
justify-content: center;
flex-direction: column;
font-size:17px;
font-weight:bold;
color:${props => props.corLetraDinamica};
`;
export const ContainerDone = styled.div`
 display:flex;
flex:1;
flex-direction: column;
padding:16px;
`;
export const ContainerProgress = styled.div`
 display:flex;
flex:1;
flex-direction: column;
padding:16px;
align-items:center;
justify-content: center;
`;

export const Title = styled.p`
font-size:20px;
font-weight:bold;
color:${props => props.corLetraDinamica};
text-align: center;
`;


export const ContainerTitle = styled.div`
 display:flex;
padding: 16px 0;
flex-direction: column;
`;

export const ContainerProgressBar = styled.div`
 display:flex;
padding: 32px 0;
flex-direction: row;
align-items: center;
justify-content: center;
`;
export const ContainerDescriptions = styled.div`
 display:flex;
padding: 16px 0;
flex-direction: column;
color:${props => props.corLetraDinamica};
`;

export const Description = styled.span`
font-size:17px;
color:${props => props.corLetraDinamica};
`;

export const ClickBy = styled.span`
font-size:17px;
font-weight: bold;
text-decoration:underline;
color:${props => props.corLetraDinamica};
cursor:pointer;
`;
export const ContainerNewExport = styled.div`
 display:flex;
flex-direction: column;
`;


export const ButtonDownload = styled.button`
display:flex;
flex-direction:row;
border:0;
justify-content:space-between;
height:33px;
min-height:33px;
width:120px;
margin-top: 16px;
margin-bottom: 11px;
align-items:center;
border-radius:5px;
transition: background-color 0.2s;
background:${props => props.backgroundbotao};
&:hover{
   background:${props => shade(0.2, props.backgroundbotao)};
}
`;

export const Icon = styled.i`
  color:${props => props.colorPrimario};
  font-size:13px;
  padding: 5px;
`;


export const TextDownload = styled.span`
  color:${props => props.colorDinamico};
  font-size:13px;
 font-weight:bold;
`;


export const DescriptionList = styled.li`
  color:${props => props.colorDinamico};
  font-size:16px;
 font-weight:bold;
 margin:5px 0;
`;


export const ContainerModal = styled.div`
 display:flex;
   overflow:auto;
 flex:1;
   width:450px;
   height:450px;
   min-height:450px;
   flex-direction:column;
   border-radius:7px;
   background:${props => props.backgroundDinamico};
`;

export const ContainerIconeFecharModal = styled.div`
padding:10px 0;
justify-content:space-between;
align-items:center;
display:flex;
border-radius: 7px 7px 0 0;
width:100%;
background:${props => props.backgroundDinamico};
`;

export const TextHeaderModal = styled.strong`
font-size:17px;
color:${props => props.corLetraDinamico};
margin-left:12px;
letter-spacing: 1.5px;
`;



export const IconeFechar = styled.i`
font-size:15px;
margin-right:5px;
color:${props => props.backgroundDinamico};
cursor: pointer;
`;


export const ContentModal = styled.div`
 display:flex;
 flex:11;
  flex-direction:column;
  align-items:center;
  justify-content:center;
`;


export const ContainerBotaoModal = styled.div`
 display:flex;
 flex-direction:column;
 justify-content:flex-end;
 align-items:center;
 padding:16px 10px;
 border-top: .5px solid ${props => props.bordercolor};
 flex-direction: row;
`;


export const ButtonModal = styled.button`
display:flex;
justify-content:center;
align-items:center;

background-color:${props => props.backGroundDinamico};
color:${props => props.corLetraBotao};
letter-spacing: 1px;
font-size:20px;
font-weight:bold;
width:110px;
border:0;
transition: background-color 0.2s;
height:45px;
min-height:45px;
border-radius: 5px;
&:hover{
   background: ${props => shade(0.2, props.backGroundDinamico)};
}
`;


export const ButtonCancelModal = styled.button`
display:flex;
justify-content:center;
align-items:center;
background-color:${props => props.backgroundbotao};
color:${props => props.bordercolor};
letter-spacing: 1px;
font-size:20px;
font-weight:bold;
width:110px;
border:0;
transition: background-color 0.2s;
height:45px;
min-height:45px;
margin-right: 10px;
border-radius: 5px;
border: 1px solid ${props => props.bordercolor};
&:hover{
   background: ${props => shade(0.2, props.backgroundbotao)};
}
`;

export const ContentProgressBar = styled.div`
display: flex;
width:200px;
max-width: 200px;
height:15px;
border:1px solid ${props => props.bordercolor};
border-radius:4px;
`;

export const ProgressBar = styled.div`
  position: relative;
  height: 13px;
  border-radius: 2px;
  background: linear-gradient(
    90deg,
    ${props => lighten(0.3, props.color)} 0%,
    ${props => props.color} 100%,
    ${props => shade(0.5, props.color)} 100%);
`;

export const TextProgress = styled.span`
font-size:16px;
color:${props => props.corLetraDinamica};
margin-left: 10px;
`;
