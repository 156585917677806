import React from 'react';
import { connect } from 'react-redux';
import { ResponsivePie } from '@nivo/pie';
import moment from 'moment';
import { toastr } from 'react-redux-toastr';
import { ResponsiveLine } from '@nivo/line'
import { DateRangePicker, SingleDatePicker, DayPickerRangeController, isSameDay } from 'react-dates';
import SelectSearch from 'react-select-search';
import { Modal } from 'react-responsive-modal';
import { withRouter } from "react-router-dom";
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import HashLoader from "react-spinners/HashLoader";
import ClipLoader from "react-spinners/ClipLoader";
import {
    modificaCorBordaListaTarefas, insereDataFiltroDashboard, modificaDataInicialFinalRelatorioAtendimentoDetalhado,
    modificaFiltroSelecionadoRelatorioDetalhado,

    modificaClienteTelaRelatorioDetalhado,
    modificaAtendenteRelatorioDetalhado, modificaSetorRelatorioDetalhado,
    modificaFiltroTelaRelatorioDetalhado, modificaFiltroClienteSectionedTelaRelatorioDetalhado, modificaFiltroAtendenteSectionedTelaRelatorioDetalhado,
    modificaFiltroSetorSectionedTelaRelatorioDetalhado, modificaVisibilidadeModalInformacaoRelatorioDetalhado,
    insereTextosInformacionaisModalRelatorioDetalhado, zeraMensagemGerandoRelatorio, novalistaAtendimentosZeradoParaRelatorioDetalhado,
    listaAtendimentosPeriodoPersonalizado, exportaAtendimentosParaExcelPdf, desligaOuvintesChamadosFirebase,
    listaRelatoriosCRM, modificaDataInicialFinalRelatorioCRM, exportaCrmParaExcel, ativaDesativaLoadingRelatorioCRMExport,
    listaStatusContaClientePainelAdm, excluiVersaoPremiumPainelAdm, AddVersaoGratisPainelAdm,
    AddVersaoPremiumPainelAdm, atualizaStatusConexaoWpp
} from '../../actions/DashBoardAction';
import { modificaNomeRotaNoHeader } from '../../actions/HeaderAction';
import { listaAtendentesTelaConsultaAtendente } from '../../actions/AtendenteAction';
import { listaSetorSectionedTelaConsultaSetor } from '../../actions/SetorAction';

import {
    Title, Container, ContainerBox, Box, TextStatus, TextResultado, ContainerGrafico, SubContainerGrafico,
    ContainerTextGrafico, TextGrafico, ContainerAviso, TextAviso, SubContainerListaDeTarefas, TextTarefas,
    SubContainerInputEmail, InputEmail, DivContainerSelectDate, BotaoPesquisarChamadoPorNumero,
    ContainerGraficoTempoMedio, SubContainerGraficoTempoMedio, SubContainerGraficoTempoMedioSemSombra,
    ContainerTextTempoMedio, TextTempoMedio, ContainerIconesExport, IconeExport,
    ContainerTextTituloTempoMedio, DivTextPeriodo, TextPeriodo, ContainerInputSearch, IconeSair, DivPeriodoBotao,
    ContainerIconesExportTempoMedio, ContainerModal, ContainerIconeFecharModal, TextNovoAtendimento, ContainerExplicacaoVincular, TextVincular,
    IconeFechar,
    InputSearch, DivInputSearch, IconeSetaPraBaixo,
    ContainerAtendentes, ContainerInputEBotaoComent, InputDescricaoChamado,
    ContainerTextGraficoLoading, TextGraficoLoading, ContainerGraficoLoading, GraficoLoading,
    TextGraficoLoadingTempoMedio, ContainerModalPrioridadeAtendimento, DivInputComentarChamado, DivBotaoSalvarComentario,
    ButtonSalvarComentario, TextBotaoSalvarComentar, InputEmailAcc, ContainerEmailPrinc,
    DivCabecalhoCfg, ContainerCfg, TextCabecalho, DivConfiguracoes, TextMensagem, TextMensagemResposta,
    DivInputComentarChamadoConfig, ButtonVersaoPremium,

    DivTextoReabrirAtendimento, DivInputMotivoCancelamento, ButtonNaoCancelarAtendimento,
    TextBotaoCancelarAtendimento, ButtonCancelarAtendimento, InputSearchQtdAtendentes,
    ContainerModalAddVersaoPremium, BotaoAtualizaStatusConexaoWpp
} from './styled';

class PainelADM extends React.Component {
    state = {
        startDate: null,
        endDate: null,
        focusedInput: null,
        acessoPermitido: false,
        modalSenhaAcesso: true, // voltar para true quando deploy
        senhaAcesso: '',
        numeroTentativa: 0,
        emailPrincipalAcc: '',
        modalAddVersaoPremium: false,
        modalAddVersaoGratis: false,
        modalExcluirPremium: false,
        planoSelecionado: '',
        planos: [
            {
                name: 'QUANTIDADE DE ATENDENTES',
                type: 'group',
                items: [
                    /*  { value: "Cartão de Crédito", name: "Cartão de Crédito" }, */
                    { value: "5", name: "Valor Plano 49,90" },
                    { value: "10", name: "Valor Plano 99,90" },
                    { value: "15", name: "Valor Plano 199,90" },
                    { value: "20", name: "Valor Plano 299,90" },
                    { value: "30", name: "Valor Plano 399,90" },
                    { value: "40", name: "Valor Plano 499,90" },
                    { value: "50", name: "Valor Plano 599,90" },
                    { value: "60", name: "Valor Plano 699,90" },
                    { value: "80", name: "Valor Plano 799,90" },
                    { value: "100", name: "Valor Plano 899,90" },
                    { value: "120", name: "Valor Plano 999,90" },

                ]
            }
        ],
    }


    componentWillUnmount() {

    }
    componentDidMount() {
        /*   if (this.props.premium == 0) {
             toastr.error('Recurso Premium', 'Este é um recurso PREMIUM, Assine a versão PREMIUM e libere esse e mais recursos!');
             this.props.history.push('/dashboard')
 
         } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
             toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
             this.props.history.push('/dashboard')
 
         } else { */

        moment.locale('pt-br', {
            months: 'Janeiro_Fevereiro_Março_Abril_Maio_Junho_Julho_Agosto_Setembro_Outubro_Novembro_Dezembro'.split('_')
        })
        /*   if (this.state.acessoPermitido) {
              this.props.listaRelatoriosCRM('', '');
              this.props.modificaNomeRotaNoHeader('Relatório CRM DropDesk')
          } else {
  
          } */


        /* else {
           toastr.error('Acesso Negado', 'Você não tem permissão para acessar esta tela');
           this.props.history.push('/dashboard')
       } 
   } */
    }
    /*   removeFiltrosAll() {
          this.props.modificaFiltroSelecionadoRelatorioDetalhado('');
          this.props.modificaAtendenteRelatorioDetalhado('');
          this.props.modificaClienteTelaRelatorioDetalhado('');
          this.props.modificaSetorRelatorioDetalhado('');
          this.props.listaAtendimentosPeriodoPersonalizado(this.props.dataInicialConsultaFiltroRelatorioDetalhado, this.props.dataFinalConsultaFiltroRelatorioDetalhado, 1, 'padrao', '')
      } */
    handleInputEmailBlur() {
        this.props.modificaCorBordaListaTarefas(this.props.corBordaDinamica);

    }
    handleInputEmailFocus() {
        this.props.modificaCorBordaListaTarefas(this.props.corBotaoPadrao);
    }
    handleWeekDays = (day) => {
        //Change week day with custom day array
        day._locale._weekdaysMin = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'];
        // return the actual dates value(like 1,2,3 ...) from the moment object.
        return (day.format('D'));
    }
    insereDataInicialFinal = ({ dataInicial, dataFinal }) => {
        // 
        // 
        if (dataInicial !== null) {
            let dataInicio = moment(dataInicial, 'MM/DD/YYYY').format('DD/MM/YYYY');
            let state = this.state;
            state.startDate = dataInicio;
            this.setState({ state })
        }
        if (dataFinal !== null) {
            let dataFim = moment(dataFinal, 'MM/DD/YYYY').format('DD/MM/YYYY');
            let state = this.state;
            state.endDate = dataFim;
            this.setState({ state })
        }

    }
    modificaSenhAcesso = (senha) => {
        this.setState({ senhaAcesso: senha })
    }
    renderLoadingRelatorioDetalhado() {
        if (this.props.loadingRelatorioCRM) {
            return (
                <Container backgroundDinamico={this.props.backgroundPrimeiro}>
                    <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                        <p style={{ fontSize: 19, color: '#FFF' }}><b>Verificando Configurações</b><br></br>Por favor, Aguarde...</p>
                        <HashLoader
                            css={{ marginTop: 15 }}
                            size={60}
                            color={'#00BEA4'}
                            loading
                        />
                    </div>

                </Container >
            )
        } else {
            return (
                <Container backgroundDinamico={this.props.backgroundPrimeiro}>
                    <ContainerEmailPrinc>
                        <DivInputComentarChamado >
                            <p style={{ color: this.props.coresLetraPadrao }}>Email Principal da Conta:</p>
                            <InputEmailAcc
                                autoFocus
                                //type="textarea"
                                onChange={(value) => { this.setState({ emailPrincipalAcc: value.target.value }) }}
                                corLetraDinamico={'#000'}
                                value={this.state.emailPrincipalAcc}
                                corLetraSecundaria={'#000'}
                                corBordaDinamica={this.props.corBotaoPadrao}
                                placeholder="Digite o email"
                                onKeyDown={this._handleKeyDownEmail}
                            />

                        </DivInputComentarChamado>
                        <BotaoPesquisarChamadoPorNumero onClick={() => {
                            this.props.listaStatusContaClientePainelAdm(this.state.emailPrincipalAcc, this.props.dadosUsuarioPesquisadoPainelAdm);
                        }} backGroundDinamico={this.props.corBotaoPadrao} type="button">
                            <i className="icon-magnifier" style={{ color: this.props.letraBotaoPadrao, fontSize: 17 }} />
                        </BotaoPesquisarChamadoPorNumero>
                    </ContainerEmailPrinc>

                    {this.props.arrayDadosEmpresaPainelADM.length > 0 && <ContainerCfg style={{}} backgroundDinamico={this.props.backgroundSegundo}>
                        <DivCabecalhoCfg bordaDinamica={this.props.coresLetraSecundario}>
                            <TextCabecalho corLetraDinamica={this.props.coresLetraPadrao}>Dados da Empresa</TextCabecalho>
                        </DivCabecalhoCfg>
                        <DivConfiguracoes>

                            <DivInputComentarChamadoConfig >
                                <TextMensagem corLetraDinamica={'#B0C4DE'}>Nome da Empresa:</TextMensagem>
                                <TextMensagemResposta corLetraDinamica={this.props.coresLetraPadrao}>{this.props.arrayDadosEmpresaPainelADM[0].empresa}</TextMensagemResposta>
                            </DivInputComentarChamadoConfig>

                            <DivInputComentarChamadoConfig >
                                <TextMensagem corLetraDinamica={'#B0C4DE'}>Email Conta Principal:</TextMensagem>
                                <TextMensagemResposta corLetraDinamica={this.props.coresLetraPadrao}>{this.props.arrayDadosEmpresaPainelADM[0].email}</TextMensagemResposta>
                            </DivInputComentarChamadoConfig>
                            <DivInputComentarChamadoConfig >
                                <TextMensagem corLetraDinamica={'#B0C4DE'}>Senha Conta Principal:</TextMensagem>
                                <TextMensagemResposta corLetraDinamica={this.props.coresLetraPadrao}>{this.props.arrayDadosEmpresaPainelADM[0].senha}</TextMensagemResposta>
                            </DivInputComentarChamadoConfig>

                            <DivInputComentarChamadoConfig >
                                <TextMensagem corLetraDinamica={'#B0C4DE'}>Plano:</TextMensagem>
                                <TextMensagemResposta corLetraDinamica={this.props.coresLetraPadrao}>{this.props.arrayDadosEmpresaPainelADM[0].plano}</TextMensagemResposta>
                            </DivInputComentarChamadoConfig>

                            <DivInputComentarChamadoConfig >
                                <TextMensagem corLetraDinamica={'#B0C4DE'}>Status Versão:</TextMensagem>
                                <TextMensagemResposta corLetraDinamica={this.props.arrayDadosEmpresaPainelADM[0].versao.includes('Expirado') ? 'red' : this.props.arrayDadosEmpresaPainelADM[0].versao.includes('ATIVO') ? '#00FF7F' : "#39a3f4"}>{this.props.arrayDadosEmpresaPainelADM[0].versao}</TextMensagemResposta>
                            </DivInputComentarChamadoConfig>

                            {this.props.arrayDadosEmpresaPainelADM[0].motivoCancelamento && this.props.arrayDadosEmpresaPainelADM[0].motivoCancelamento !== '' && <DivInputComentarChamadoConfig >
                                <TextMensagem corLetraDinamica={'#B0C4DE'}>Motivo Não Renovação:</TextMensagem>
                                <TextMensagemResposta corLetraDinamica={this.props.coresLetraPadrao}>{this.props.arrayDadosEmpresaPainelADM[0].motivoCancelamento}</TextMensagemResposta>
                            </DivInputComentarChamadoConfig>}


                            <DivInputComentarChamadoConfig >
                                <TextMensagem corLetraDinamica={'#B0C4DE'}>Data Compra:</TextMensagem>
                                <TextMensagemResposta corLetraDinamica={this.props.coresLetraPadrao}>{this.props.arrayDadosEmpresaPainelADM[0].dataCompra}</TextMensagemResposta>
                            </DivInputComentarChamadoConfig>
                            <DivInputComentarChamadoConfig >
                                <TextMensagem corLetraDinamica={'#B0C4DE'}>Valido Até:</TextMensagem>
                                <TextMensagemResposta corLetraDinamica={this.props.coresLetraPadrao}>{this.props.arrayDadosEmpresaPainelADM[0].versao.includes('Expirado') ? this.props.arrayDadosEmpresaPainelADM[0].dataValidadeAtual : this.props.arrayDadosEmpresaPainelADM[0].dataValidoAte === "12/12/2099" ? this.props.arrayDadosEmpresaPainelADM[0].dataValidadeAtual : this.props.arrayDadosEmpresaPainelADM[0].dataValidoAte}</TextMensagemResposta>
                            </DivInputComentarChamadoConfig>
                            <DivInputComentarChamadoConfig >
                                <TextMensagem corLetraDinamica={'#B0C4DE'}>Pagamento Feito por:</TextMensagem>
                                <TextMensagemResposta corLetraDinamica={this.props.coresLetraPadrao}>{this.props.arrayDadosEmpresaPainelADM[0].versao.includes('Expirado') ? this.props.arrayDadosEmpresaPainelADM[0].statusAssinatura : this.props.arrayDadosEmpresaPainelADM[0].dataValidoAte === "12/12/2099" ? 'cartao' : this.props.arrayDadosEmpresaPainelADM[0].statusAssinatura}</TextMensagemResposta>
                            </DivInputComentarChamadoConfig>
                            <DivInputComentarChamadoConfig >
                                <TextMensagem corLetraDinamica={'#B0C4DE'}>Valor Plano:</TextMensagem>
                                <TextMensagemResposta corLetraDinamica={this.props.coresLetraPadrao}>{this.props.arrayDadosEmpresaPainelADM[0].valorPlano}</TextMensagemResposta>
                            </DivInputComentarChamadoConfig>

                            <DivInputComentarChamadoConfig >
                                <TextMensagem corLetraDinamica={'#B0C4DE'}>Assinou através do Portal:</TextMensagem>
                                <TextMensagemResposta corLetraDinamica={this.props.coresLetraPadrao}>{this.props.arrayDadosEmpresaPainelADM[0].plataforma}</TextMensagemResposta>
                            </DivInputComentarChamadoConfig>

                            <DivInputComentarChamadoConfig >
                                <TextMensagem corLetraDinamica={'#B0C4DE'}>Quantidade Atendentes Liberados:</TextMensagem>
                                <TextMensagemResposta corLetraDinamica={this.props.coresLetraPadrao}>{this.props.arrayDadosEmpresaPainelADM[0].qtdAtendentesLiberados}</TextMensagemResposta>
                            </DivInputComentarChamadoConfig>
                            <DivInputComentarChamadoConfig >
                                <TextMensagem corLetraDinamica={'#B0C4DE'}>Segmento:</TextMensagem>
                                <TextMensagemResposta corLetraDinamica={this.props.coresLetraPadrao}>{this.props.arrayDadosEmpresaPainelADM[0].segmento}</TextMensagemResposta>
                            </DivInputComentarChamadoConfig>
                            <DivInputComentarChamadoConfig >
                                <TextMensagem corLetraDinamica={'#B0C4DE'}>Nome Assinante:</TextMensagem>
                                <TextMensagemResposta corLetraDinamica={this.props.coresLetraPadrao}>{this.props.arrayDadosEmpresaPainelADM[0].nomeAssinante}</TextMensagemResposta>
                            </DivInputComentarChamadoConfig>
                            <DivInputComentarChamadoConfig >
                                <TextMensagem corLetraDinamica={'#B0C4DE'}>Email Assinante:</TextMensagem>
                                <TextMensagemResposta corLetraDinamica={this.props.coresLetraPadrao}>{this.props.arrayDadosEmpresaPainelADM[0].emailAssinante}</TextMensagemResposta>
                            </DivInputComentarChamadoConfig>
                            <DivInputComentarChamadoConfig >
                                <TextMensagem corLetraDinamica={'#B0C4DE'}>Quantidade de Vezes que a Versão Teste foi Liberada:</TextMensagem>
                                <TextMensagemResposta corLetraDinamica={this.props.coresLetraPadrao}>{this.props.arrayDadosEmpresaPainelADM[0].qtdVersaoTesteLiberada}</TextMensagemResposta>
                            </DivInputComentarChamadoConfig>
                            {this.props.arrayDadosEmpresaPainelADM[0].numberConfirmation && <DivInputComentarChamadoConfig >
                                <TextMensagem corLetraDinamica={'#B0C4DE'}>Número confirmação de exclusão de conta:</TextMensagem>
                                <TextMensagemResposta corLetraDinamica={this.props.coresLetraPadrao}>{this.props.arrayDadosEmpresaPainelADM[0].numberConfirmation}</TextMensagemResposta>
                            </DivInputComentarChamadoConfig>}


                        </DivConfiguracoes>
                        <div style={{ display: 'flex', flex: 1, height: 100, alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row' }}>
                            <ButtonVersaoPremium backGroundDinamico={'#39a3f4'} corLetraBotao={this.props.letraBotaoPadrao} type="button" onClick={() => {

                                this.setState({ modalAddVersaoGratis: true })
                            }}>
                                Adicionar Versão Teste
                            </ButtonVersaoPremium>
                            <ButtonVersaoPremium backGroundDinamico={this.props.corBotaoPadrao} corLetraBotao={this.props.letraBotaoPadrao} type="button" onClick={() => {
                                this.setState({ modalAddVersaoPremium: true })
                            }}>
                                Adicionar Versão Premium
                            </ButtonVersaoPremium>
                            <ButtonVersaoPremium backGroundDinamico={'#EC6A69'} corLetraBotao={this.props.letraBotaoPadrao} type="button" onClick={() => {
                                this.setState({ modalExcluirPremium: true })
                            }}>
                                Excluir Versão Premium
                            </ButtonVersaoPremium>

                        </div>

                    </ContainerCfg>}

                    {this.props.arrayDadosEmpresaPainelADM.length > 0 && this.props.statusConexaoWppPainelAdm !== "" && <ContainerCfg style={{}} backgroundDinamico={this.props.backgroundSegundo}>
                        <DivCabecalhoCfg bordaDinamica={this.props.coresLetraSecundario}>
                            <TextCabecalho corLetraDinamica={this.props.coresLetraPadrao}>WhatsApp</TextCabecalho>
                        </DivCabecalhoCfg>
                        <DivConfiguracoes>

                            <DivInputComentarChamadoConfig >
                                <TextMensagem corLetraDinamica={'#B0C4DE'}>Status Conexão:</TextMensagem>
                                <TextMensagemResposta corLetraDinamica={this.props.statusConexaoWppPainelAdm.includes('connected') || this.props.statusConexaoWppPainelAdm.includes('inChat') || this.props.statusConexaoWppPainelAdm.includes('isConnected') || this.props.statusConexaoWppPainelAdm.includes('isSynced') || this.props.statusConexaoWppPainelAdm.includes('isLogged') || this.props.statusConexaoWppPainelAdm.includes('chatsAvailable') ? "#00FF7F" : this.props.statusConexaoWppPainelAdm.includes('Não utiliza WhatsApp') ? this.props.coresLetraPadrao : "red"}>{this.props.statusConexaoWppPainelAdm}</TextMensagemResposta>
                            </DivInputComentarChamadoConfig>

                            {this.props.configuracoesInternasWpp !== '' &&
                                <DivInputComentarChamadoConfig >
                                    <TextMensagem corLetraDinamica={'#B0C4DE'}>Configurações internas:</TextMensagem>
                                    <TextMensagemResposta corLetraDinamica={this.props.configuracoesInternasWpp.includes('ERROR') ? 'red' : this.props.coresLetraPadrao}>{this.props.configuracoesInternasWpp}</TextMensagemResposta>
                                </DivInputComentarChamadoConfig>}
                           
                            {this.props.versaoBETA !== '' && <DivInputComentarChamadoConfig >
                                <TextMensagem corLetraDinamica={'#B0C4DE'}>Utiliza Versão BETA?</TextMensagem>
                                <TextMensagemResposta corLetraDinamica={this.props.coresLetraPadrao}>{this.props.versaoBETA}</TextMensagemResposta>
                            </DivInputComentarChamadoConfig>}
                            {this.props.dataUltimaConexao !== '' && <DivInputComentarChamadoConfig >
                                <TextMensagem corLetraDinamica={'#B0C4DE'}>Data da Conexão:</TextMensagem>
                                <TextMensagemResposta corLetraDinamica={this.props.coresLetraPadrao}>{this.props.dataUltimaConexao}</TextMensagemResposta>
                            </DivInputComentarChamadoConfig>}

                            {this.props.memoriaContainerWpp !== '' && <DivInputComentarChamadoConfig >
                                <TextMensagem corLetraDinamica={'#B0C4DE'}>Memória Container:</TextMensagem>
                                <TextMensagemResposta corLetraDinamica={this.props.memoriaContainerWpp.includes('Error') ? 'red' : this.props.coresLetraPadrao}>{this.props.memoriaContainerWpp}</TextMensagemResposta>
                            </DivInputComentarChamadoConfig>}

                            {this.props.nivelBateria !== 0 &&
                                <DivInputComentarChamadoConfig >
                                    <TextMensagem corLetraDinamica={'#B0C4DE'}>Nivel Bateria:</TextMensagem>
                                    <TextMensagemResposta corLetraDinamica={this.props.nivelBateria <= 20 ? 'red' : this.props.coresLetraPadrao}>{this.props.nivelBateria}%</TextMensagemResposta>
                                </DivInputComentarChamadoConfig>}
                            {this.props.modeloCelular !== '' && <DivInputComentarChamadoConfig >
                                <TextMensagem corLetraDinamica={'#B0C4DE'}>Modelo Celular:</TextMensagem>
                                <TextMensagemResposta corLetraDinamica={this.props.modeloCelular.includes('Error') ? 'red' : this.props.coresLetraPadrao}>{this.props.modeloCelular}</TextMensagemResposta>
                            </DivInputComentarChamadoConfig>}

                            {this.props.versaoOS !== '' && <DivInputComentarChamadoConfig >
                                <TextMensagem corLetraDinamica={'#B0C4DE'}>Versao OS:</TextMensagem>
                                <TextMensagemResposta corLetraDinamica={this.props.versaoOS.includes('Error') ? 'red' : this.props.coresLetraPadrao}>{this.props.versaoOS}</TextMensagemResposta>
                            </DivInputComentarChamadoConfig>}

                            {this.props.api !== '' && <DivInputComentarChamadoConfig >
                                <TextMensagem corLetraDinamica={'#B0C4DE'}>API:</TextMensagem>
                                <TextMensagemResposta corLetraDinamica={this.props.coresLetraPadrao}>{this.props.api}</TextMensagemResposta>
                            </DivInputComentarChamadoConfig>}
                            {this.props.container !== '' && <DivInputComentarChamadoConfig >
                                <TextMensagem corLetraDinamica={'#B0C4DE'}>Container:</TextMensagem>
                                <TextMensagemResposta corLetraDinamica={this.props.coresLetraPadrao}>{this.props.container}</TextMensagemResposta>
                            </DivInputComentarChamadoConfig>}
                            {this.props.numeroConectado !== '' && <DivInputComentarChamadoConfig >
                                <TextMensagem corLetraDinamica={'#B0C4DE'}>Número Conectado:</TextMensagem>
                                <TextMensagemResposta corLetraDinamica={this.props.coresLetraPadrao}>{this.props.numeroConectado}</TextMensagemResposta>
                            </DivInputComentarChamadoConfig>}


                        </DivConfiguracoes>
                        {/*    <div style={{ display: 'flex', flex: 1, height: 100, alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row' }}>
                            <ButtonVersaoPremium backGroundDinamico={'#39a3f4'} corLetraBotao={this.props.letraBotaoPadrao} type="button" onClick={() => {

                                this.setState({ modalAddVersaoGratis: true })
                            }}>
                                Adicionar Versão Teste
                       </ButtonVersaoPremium>
                            <ButtonVersaoPremium backGroundDinamico={this.props.corBotaoPadrao} corLetraBotao={this.props.letraBotaoPadrao} type="button" onClick={() => {
                                this.setState({ modalAddVersaoPremium: true })
                            }}>
                                Adicionar Versão Premium
                       </ButtonVersaoPremium>
                            <ButtonVersaoPremium backGroundDinamico={'#EC6A69'} corLetraBotao={this.props.letraBotaoPadrao} type="button" onClick={() => {
                                this.setState({ modalExcluirPremium: true })
                            }}>
                                Excluir Versão Premium
                       </ButtonVersaoPremium>


                        </div> */}

                    </ContainerCfg>}
                </Container >
            )
        }
    }
    _handleKeyDown = (e) => {

        if (e.key === 'Enter') {
            e.preventDefault()
            if (this.state.senhaAcesso === this.props.senhaAcessoADM) {
                // this.props.listaRelatoriosCRM('', '');
                this.props.modificaNomeRotaNoHeader('Painel ADM');
                this.props.ativaDesativaLoadingRelatorioCRMExport(false);
                this.setState({ modalSenhaAcesso: false })
            } else {
                toastr.error('Acesso Negado')
                this.setState({ numeroTentativa: this.state.numeroTentativa + 1 })
            }
        }
    }
    _handleKeyDownEmail = (e) => {

        if (e.key === 'Enter') {
            e.preventDefault()
            this.props.listaStatusContaClientePainelAdm(this.state.emailPrincipalAcc, this.props.dadosUsuarioPesquisadoPainelAdm);
        }
    }
    render() {

        return (
            <>
                {this.renderLoadingRelatorioDetalhado()}
                <Modal focusTrapped={false} showCloseIcon={false} open={this.state.modalAddVersaoPremium} onClose={() => { }} center>
                    <ContainerModalAddVersaoPremium backgroundDinamico={this.props.backgroundPrimeiro}>
                        <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                            <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Habilitar Versão Premium</TextNovoAtendimento>
                            <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => { this.setState({ modalAddVersaoPremium: false }) }} className="icon-close" />
                        </ContainerIconeFecharModal>


                        <ContainerAtendentes>

                            <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', height: 50 }}>
                                    <p style={{ color: this.props.coresLetraPadrao, fontSize: 15, fontWeight: 'bold', marginRight: 10 }}>Selecione o Plano Premium: </p>
                                    {/*     <ContainerSelectSearch> */}
                                    <SelectSearch
                                        renderValue={(valueProps) =>
                                            <DivInputSearch>
                                                <InputSearchQtdAtendentes
                                                    corLetraDinamico={'#000'}
                                                    backgroundDinamico={'#FFF'}
                                                    placeholderColor={'#000'}
                                                    corBordaDinamica={'#000'}
                                                    {...valueProps} ></InputSearchQtdAtendentes>
                                                <IconeSetaPraBaixo className="icon-angle-arrow-down" colorDinamico={this.props.coresLetraPadrao} />
                                            </DivInputSearch>
                                        }
                                        value={this.state.planoSelecionado}
                                        options={this.state.planos}
                                        onChange={(value, option) => {
                                            this.setState({ planoSelecionado: value })
                                            //  this.props.modificaClienteSelecionado(value);


                                        }}
                                        placeholder={"Selecione o Valor do Plano"}
                                        search={false} />

                                    {/* </ContainerSelectSearch> */}
                                </div>
                            </div>
                            <DivInputMotivoCancelamento>

                                <ButtonNaoCancelarAtendimento onClick={() => { this.setState({ modalAddVersaoPremium: false }) }} borderColorDinamico={this.props.corBotaoPadrao} backgroundBotao={this.props.backgroundPrimeiro}>
                                    <TextBotaoCancelarAtendimento colorDinamico={this.props.corBotaoPadrao}>Não</TextBotaoCancelarAtendimento>
                                </ButtonNaoCancelarAtendimento>
                                <ButtonCancelarAtendimento onClick={() => {
                                    if (this.state.planoSelecionado === "") {
                                        toastr.error("Error", "Selecione o Valor do Plano")
                                    } else {
                                        this.props.AddVersaoPremiumPainelAdm(this.props.arrayDadosEmpresaPainelADM[0], this.state.planoSelecionado)
                                        this.setState({ modalAddVersaoPremium: false, planoSelecionado: '' });
                                    }
                                }} backgroundBotao={this.props.corBotaoPadrao}>
                                    <TextBotaoCancelarAtendimento colorDinamico={this.props.letraBotaoPadrao}>Sim</TextBotaoCancelarAtendimento>
                                </ButtonCancelarAtendimento>
                            </DivInputMotivoCancelamento>
                        </ContainerAtendentes>
                    </ContainerModalAddVersaoPremium>
                </Modal>
                <Modal focusTrapped={false} showCloseIcon={false} open={this.state.modalAddVersaoGratis} onClose={() => { }} center>
                    <ContainerModalPrioridadeAtendimento backgroundDinamico={this.props.backgroundPrimeiro}>
                        <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                            <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Habilitar Versão Teste</TextNovoAtendimento>
                            <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => { this.setState({ modalAddVersaoGratis: false }) }} className="icon-close" />
                        </ContainerIconeFecharModal>


                        <ContainerAtendentes>
                            <DivTextoReabrirAtendimento >
                                <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Deseja realmente Habilitar Versão Teste?</TextNovoAtendimento>
                            </DivTextoReabrirAtendimento>
                            <DivInputMotivoCancelamento>

                                <ButtonNaoCancelarAtendimento onClick={() => { this.setState({ modalAddVersaoGratis: false }) }} borderColorDinamico={this.props.corBotaoPadrao} backgroundBotao={this.props.backgroundPrimeiro}>
                                    <TextBotaoCancelarAtendimento colorDinamico={this.props.corBotaoPadrao}>Não</TextBotaoCancelarAtendimento>
                                </ButtonNaoCancelarAtendimento>
                                <ButtonCancelarAtendimento onClick={() => {
                                    this.props.AddVersaoGratisPainelAdm(this.props.arrayDadosEmpresaPainelADM[0])
                                    this.setState({ modalAddVersaoGratis: false });

                                }} backgroundBotao={this.props.corBotaoPadrao}>
                                    <TextBotaoCancelarAtendimento colorDinamico={this.props.letraBotaoPadrao}>Sim</TextBotaoCancelarAtendimento>
                                </ButtonCancelarAtendimento>
                            </DivInputMotivoCancelamento>
                        </ContainerAtendentes>
                    </ContainerModalPrioridadeAtendimento>
                </Modal>
                <Modal focusTrapped={false} showCloseIcon={false} open={this.state.modalExcluirPremium} onClose={() => { }} center>
                    <ContainerModalPrioridadeAtendimento backgroundDinamico={this.props.backgroundPrimeiro}>
                        <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                            <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Excluir Versão Premium</TextNovoAtendimento>
                            <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => { this.setState({ modalExcluirPremium: false }) }} className="icon-close" />
                        </ContainerIconeFecharModal>


                        <ContainerAtendentes>
                            <DivTextoReabrirAtendimento >
                                <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Deseja realmente Excluir Versão Premium?</TextNovoAtendimento>
                            </DivTextoReabrirAtendimento>
                            <DivInputMotivoCancelamento>

                                <ButtonNaoCancelarAtendimento onClick={() => { this.setState({ modalExcluirPremium: false }) }} borderColorDinamico={this.props.corBotaoPadrao} backgroundBotao={this.props.backgroundPrimeiro}>
                                    <TextBotaoCancelarAtendimento colorDinamico={this.props.corBotaoPadrao}>Não</TextBotaoCancelarAtendimento>
                                </ButtonNaoCancelarAtendimento>
                                <ButtonCancelarAtendimento onClick={() => {
                                    this.props.excluiVersaoPremiumPainelAdm(this.props.arrayDadosEmpresaPainelADM[0])
                                    this.setState({ modalExcluirPremium: false });

                                }} backgroundBotao={this.props.corBotaoPadrao}>
                                    <TextBotaoCancelarAtendimento colorDinamico={this.props.letraBotaoPadrao}>Sim</TextBotaoCancelarAtendimento>
                                </ButtonCancelarAtendimento>
                            </DivInputMotivoCancelamento>
                        </ContainerAtendentes>
                    </ContainerModalPrioridadeAtendimento>
                </Modal>
                <Modal focusTrapped={false} showCloseIcon={false} open={this.state.modalSenhaAcesso} onClose={() => { }} center>
                    <ContainerModalPrioridadeAtendimento backgroundDinamico={this.props.backgroundPrimeiro}>
                        <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                            <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Senha de Acesso</TextNovoAtendimento>
                            <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => { this.setState({ modalSenhaAcesso: false }) }} className="icon-close" />
                        </ContainerIconeFecharModal>
                        <ContainerAtendentes>
                            <ContainerInputEBotaoComent>
                                <DivInputComentarChamado >
                                    <p style={{ color: this.props.coresLetraPadrao }}>Senha:</p>
                                    <InputDescricaoChamado
                                        autoFocus
                                        type="textarea"
                                        onChange={(value) => { this.modificaSenhAcesso(value.target.value) }}
                                        corLetraDinamico={this.props.coresLetraPadrao}
                                        value={this.state.senhaAcesso}
                                        corLetraSecundaria={this.props.coresLetraSecundario}
                                        corBordaDinamica={this.props.corBotaoPadrao}
                                        placeholder="Digite a senha"
                                        onKeyDown={this._handleKeyDown}
                                    />
                                </DivInputComentarChamado>
                            </ContainerInputEBotaoComent>
                            <DivBotaoSalvarComentario>

                                <ButtonSalvarComentario onClick={() => {
                                    if (this.state.senhaAcesso === this.props.senhaAcessoADM) {
                                        // this.props.listaRelatoriosCRM('', '');
                                        this.props.modificaNomeRotaNoHeader('Painel ADM')
                                        this.setState({ modalSenhaAcesso: false });
                                        this.props.ativaDesativaLoadingRelatorioCRMExport(false);
                                    } else {
                                        toastr.error('Acesso Negado')
                                        this.setState({ numeroTentativa: this.state.numeroTentativa + 1 })
                                    }
                                }}
                                    backgroundBotao={this.props.corBotaoPadrao}>
                                    <TextBotaoSalvarComentar backgroundBotao={this.props.letraBotaoPadrao}>Entrar</TextBotaoSalvarComentar>
                                </ButtonSalvarComentario>

                            </DivBotaoSalvarComentario>
                        </ContainerAtendentes>
                        {/*   <ContainerTextCabecalhoModalAcoes>
                            <TextCabecalhoModalAcoes corLetraDinamico={this.props.corBotaoPadrao}>Selecione o Status do Atendimento:</TextCabecalhoModalAcoes>
                        </ContainerTextCabecalhoModalAcoes>
                        <ContainerAtendentes>
                            <DivAtendentes >
                                <TextAtendentes onClick={() => { this.props.modificaVisibilidadeModalFinalizarAtendimento(false); this.props.atribuiStatusAtendimento(this.props.keyAtendimento, 'Fechado', this.props.dadosAtendimentos[0].dataAbertura, this.props.premium, this.props.dadosAtendimentos[0].keyAtendente, this.props.dadosAtendimentos[0]); }} corLetraBotaoPadrao={this.props.corBotaoPadrao} corLetraDinamico={this.props.coresLetraPadrao}>Fechado</TextAtendentes>
                            </DivAtendentes>
                            <DivAtendentes >
                                <TextAtendentes onClick={() => { this.props.modificaVisibilidadeModalFinalizarAtendimento(false); this.props.atribuiStatusAtendimento(this.props.keyAtendimento, 'Aguardando Cliente', this.props.dadosAtendimentos[0].dataAbertura, this.props.premium, this.props.dadosAtendimentos[0].keyAtendente, this.props.dadosAtendimentos[0]); }} corLetraBotaoPadrao={this.props.corBotaoPadrao} corLetraDinamico={this.props.coresLetraPadrao}>Aguardando o Cliente responder</TextAtendentes>
                            </DivAtendentes>
                        </ContainerAtendentes> */}
                    </ContainerModalPrioridadeAtendimento>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = state => {
    return (
        {

            backgroundPrimeiro: state.CoresPadraoSistema.backgroundPrimeiro,
            backgroundSegundo: state.CoresPadraoSistema.backgroundSegundo,
            backgroundMaisEscuro: state.CoresPadraoSistema.backgroundMaisEscuro,
            coresLetraPadrao: state.CoresPadraoSistema.coresLetraPadrao,
            corBotaoPadrao: state.CoresPadraoSistema.corBotaoPadrao,
            corSegundoBotaoPadrao: state.CoresPadraoSistema.corSegundoBotaoPadrao,
            coresLetraSecundario: state.CoresPadraoSistema.coresLetraSecundario,
            corLetraDescricaoDosCampos: state.CoresPadraoSistema.corLetraDescricaoDosCampos,
            nomeRota: state.HeaderReducer.nomeRota,
            corBordaPadrao: state.CoresPadraoSistema.corBordaPadrao,
            corBordaDinamica: state.CoresPadraoSistema.corBordaDinamica,


            loadingRelatorioCRM: state.DashBoardReducer.loadingRelatorioCRM,
            loadingSemInternetRelatorioDetalhado: state.DashBoardReducer.loadingSemInternetRelatorioDetalhado,
            loadingRefreshControlRelatorioDetalhado: state.DashBoardReducer.loadingRefreshControlRelatorioDetalhado,
            periodoPersonalizadoRelatorioDetalhado: state.DashBoardReducer.periodoPersonalizadoRelatorioDetalhado,

            atendimentosAEFC: state.DashBoardReducer.atendimentosAEFC,
            totalAtendimentosAEFC: state.DashBoardReducer.totalAtendimentosAEFC,
            atendimentosPorPrioridadeRelatorioDetalhado: state.DashBoardReducer.atendimentosPorPrioridadeRelatorioDetalhado,
            totalAtendimentosPorPrioridadeRelatorioDetalhado: state.DashBoardReducer.totalAtendimentosPorPrioridadeRelatorioDetalhado,


            atendimentosPorTempoSolucaoRelatorioDetalhado: state.DashBoardReducer.atendimentosPorTempoSolucaoRelatorioDetalhado,
            totalAtendimentosPorTempoSolucaoRelatorioDetalhado: state.DashBoardReducer.totalAtendimentosPorTempoSolucaoRelatorioDetalhado,
            atendimentosInternoExternoRelatorioDetalhado: state.DashBoardReducer.atendimentosInternoExternoRelatorioDetalhado,
            totalAtendimentosInternoExternoRelatorioDetalhado: state.DashBoardReducer.totalAtendimentosInternoExternoRelatorioDetalhado,
            filtroRelatorioDetalhado: state.DashBoardReducer.filtroRelatorioDetalhado,


            clientesRelatorioAtendimentoDetalhado: state.ChamadoReducer.clientesSectionedConsulta,
            clienteSelecionadoRelatorioAtendimentoDetalhado: state.DashBoardReducer.clienteSelecionadoRelatorioAtendimentoDetalhado,
            atendentesRelatorioAtendimentoDetalhado: state.AtendenteReducer.atendentesSectionedConsulta,
            atendentesSelecionadoRelatorioAtendimentoDetalhado: state.DashBoardReducer.atendentesSelecionadoRelatorioAtendimentoDetalhado,
            SetoresRelatorioAtendimentoDetalhado: state.SetorReducer.setoresSectionedConsulta,
            SetoresSelecionadoRelatorioAtendimentoDetalhado: state.DashBoardReducer.SetoresSelecionadoRelatorioAtendimentoDetalhado,

            loadingClientesRelatorioAtendimentoDetalhado: state.DashBoardReducer.loadingClientesRelatorioAtendimentoDetalhado,
            loadingAtendentesRelatorioAtendimentoDetalhado: state.DashBoardReducer.loadingAtendentesRelatorioAtendimentoDetalhado,
            loadingSetoresRelatorioAtendimentoDetalhado: state.DashBoardReducer.loadingSetoresRelatorioAtendimentoDetalhado,

            filtroClienteSectioned: state.DashBoardReducer.filtroClienteSectioned,
            filtroAtendenteSectioned: state.DashBoardReducer.filtroAtendenteSectioned,
            filtroSetorSectioned: state.DashBoardReducer.filtroSetorSectioned,

            dataInicialConsultaFiltroRelatorioDetalhado: state.DashBoardReducer.dataInicialConsultaFiltroRelatorioDetalhado,
            dataFinalConsultaFiltroRelatorioDetalhado: state.DashBoardReducer.dataFinalConsultaFiltroRelatorioDetalhado,
            licensawebouapp: state.PremiumReducer.licensawebouapp,
            tempoMedioParaSolucaoAtendimento: state.DashBoardReducer.tempoMedioParaSolucaoAtendimento,

            modalInformacaoRelatorioDetalhado: state.DashBoardReducer.modalInformacaoRelatorioDetalhado,

            textCabecalhoModalInfoRelatorioDetalhado: state.DashBoardReducer.textCabecalhoModalInfoRelatorioDetalhado,
            text1ModalInfRelatorioDetalhado: state.DashBoardReducer.text1ModalInfRelatorioDetalhado,
            text2ModalInfRelatorioDetalhado: state.DashBoardReducer.text2ModalInfRelatorioDetalhado,
            premium: state.PremiumReducer.premium,
            arrayChamadosDetalhadosParaDownload: state.DashBoardReducer.arrayChamadosDetalhadosParaDownload,
            mensagemGerandoRelatorio: state.DashBoardReducer.mensagemGerandoRelatorio,
            permissoesAcessarTelasAtendente: state.AtendenteReducer.permissoesAcessarTelasAtendente,
            filtroSelecionadoRelatorioDetalhado: state.DashBoardReducer.filtroSelecionadoRelatorioDetalhado,
            tempoMedioPrimeiraResposta: state.DashBoardReducer.tempoMedioPrimeiraResposta,

            graficoClienteAtivosCancelados: state.DashBoardReducer.graficoClienteAtivosCancelados,
            graficoClienteViaAnuncio: state.DashBoardReducer.graficoClienteViaAnuncio,
            graficoTipoPlanoMensal: state.DashBoardReducer.graficoTipoPlanoMensal,
            graficoFormaPagamento: state.DashBoardReducer.graficoFormaPagamento,
            graficoAssinadoViaWebApp: state.DashBoardReducer.graficoAssinadoViaWebApp,
            dataInicialConsultaRelatorioCRM: state.DashBoardReducer.dataInicialConsultaRelatorioCRM,
            dataFinalConsultaRelatorioCRM: state.DashBoardReducer.dataFinalConsultaRelatorioCRM,

            planosMaisAssinados: state.DashBoardReducer.planosMaisAssinados,
            estadosComMaisAssinantes: state.DashBoardReducer.estadosComMaisAssinantes,
            segmentosComMaisAssinantes: state.DashBoardReducer.segmentosComMaisAssinantes,
            senhaAcessoADM: state.DashBoardReducer.senhaAcessoADM,

            planosComMaisAssinadosParaBaixar: state.DashBoardReducer.planosComMaisAssinadosParaBaixar,
            arrayDadosEmpresaPainelADM: state.DashBoardReducer.arrayDadosEmpresaPainelADM,

            memoriaContainerWpp: state.DashBoardReducer.memoriaContainerWpp,
            statusContainerWpp: state.DashBoardReducer.statusContainerWpp,
            configuracoesInternasWpp: state.DashBoardReducer.configuracoesInternasWpp,
            statusConexaoWppPainelAdm: state.DashBoardReducer.statusConexaoWppPainelAdm,


            nivelBateria: state.DashBoardReducer.nivelBateria,
            modeloCelular: state.DashBoardReducer.modeloCelular,
            versaoOS: state.DashBoardReducer.versaoOS,

            api: state.DashBoardReducer.api,
            container: state.DashBoardReducer.container,
            numeroConectado: state.DashBoardReducer.numeroConectado,

            dataUltimaConexao: state.DashBoardReducer.dataUltimaConexao,
            loadingBotaoAtualizaStatusConexaoWpp: state.DashBoardReducer.loadingBotaoAtualizaStatusConexaoWpp,

            dadosUsuarioPesquisadoPainelAdm: state.DashBoardReducer.dadosUsuarioPesquisadoPainelAdm,
            versaoBETA: state.DashBoardReducer.versaoBETA,
        }
    );
};
export default connect(mapStateToProps, {
    modificaCorBordaListaTarefas, insereDataFiltroDashboard, modificaDataInicialFinalRelatorioAtendimentoDetalhado,
    modificaFiltroSelecionadoRelatorioDetalhado,
    modificaClienteTelaRelatorioDetalhado,
    modificaAtendenteRelatorioDetalhado, modificaSetorRelatorioDetalhado,
    modificaFiltroTelaRelatorioDetalhado, modificaFiltroClienteSectionedTelaRelatorioDetalhado, modificaFiltroAtendenteSectionedTelaRelatorioDetalhado,
    modificaFiltroSetorSectionedTelaRelatorioDetalhado, modificaVisibilidadeModalInformacaoRelatorioDetalhado,
    insereTextosInformacionaisModalRelatorioDetalhado, zeraMensagemGerandoRelatorio,
    modificaNomeRotaNoHeader, novalistaAtendimentosZeradoParaRelatorioDetalhado,
    listaAtendimentosPeriodoPersonalizado, listaAtendentesTelaConsultaAtendente,
    listaSetorSectionedTelaConsultaSetor, exportaAtendimentosParaExcelPdf, desligaOuvintesChamadosFirebase,
    listaRelatoriosCRM, modificaDataInicialFinalRelatorioCRM, exportaCrmParaExcel, ativaDesativaLoadingRelatorioCRMExport,
    listaStatusContaClientePainelAdm, excluiVersaoPremiumPainelAdm, AddVersaoGratisPainelAdm, AddVersaoPremiumPainelAdm,
    atualizaStatusConexaoWpp
})(withRouter(PainelADM));
