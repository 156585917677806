import React, { memo, PureComponent } from 'react';
import Avatar from "react-avatar";
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import moment from 'moment';
import firebase from '../../servicos/FirebaseConnection';
import { shade, opacify, lighten, } from 'polished';
import {
    DivBadget, ContainerChamado, ContainerImagem, ContainerDadosChamado,
    ContaineDadosChamadosPrincipal,
    ContainerIconeEFrase, TextCliente,
    SubContainerIconeEFraseClienteAtendente,
    ContainerSeta, TextEmpresa, DivData, DivCountNotif
} from './styled';
import imgUser from '../../imagens/userPadrao.png';
import {
    modificaKeyAtendimentoEmEsperaAtendimentoOnline, modificaAtendimentoEmEsperaSelecionado, modificaKeyAtendimentoEmAtendimentoOnline,
    modificaKeyContacts, modificaDadosContactsSelecionado, listaConversasAntigasWppContacts
} from '../../actions/ChamadoAction';
import aguardandoAtendimento from '../../imagens/aguardandoAtendimento.png';
moment.locale('pt-br', {
    months: 'Janeiro_Fevereiro_Março_Abril_Maio_Junho_Julho_Agosto_Setembro_Outubro_Novembro_Dezembro'.split('_')
    //  weekdays:'Seg_Ter_Qua_Qui_Sex_Sab_Dom'.split('_'),
})
class FlatListAllContacts extends PureComponent {
    state = {
        fotoCliente: null,
        newmessage: 0,
        lastMessageType: null,
        lastMessage: '',
        lastMessageStatus: 'waiting',
        lastMessageLineColor: ''
    }
    componentDidMount() {
   
    }
    
    verificaQtdMEnsagens2(arrayMensagensInversas) {
        let aux = 0;
        for (let i = 0; i < arrayMensagensInversas.length; i++) {

            /*  
              */
            if (arrayMensagensInversas[i].lineColor == "#00FF7F" /* && this.props.keyAtendimentoEmAtendimentoOnline !== this.props.data.key */) {
                if (aux > 0) {
                    return aux;
                } else {
                    return 0;
                }

            } else {
                aux++;
                /*    if (this.props.keyAtendimentoEmAtendimentoOnline !== this.props.data.key) { */
                if (aux == arrayMensagensInversas.length) {
                    return aux;
                }
                /* } */

            }
        }
    }
    verificaSeEhAlphabeto = (ch) => {
        // 
        let verificaSeEhAlphabeto = typeof ch === "string" && ch.length === 1
            && (ch >= "a" && ch <= "z");
        //
        // 
        if (verificaSeEhAlphabeto) {
            //
            switch (ch) {
                case "a":
                    this.setState({ fotoCliente: require('../../imagens/a.png') });
                    break;
                case "b":
                    this.setState({ fotoCliente: require('../../imagens/b.png') });
                    break;
                case "c":
                    this.setState({ fotoCliente: require('../../imagens/c.png') });
                    break;
                case "d":
                    this.setState({ fotoCliente: require('../../imagens/d.png') });
                    break;
                case "e":
                    this.setState({ fotoCliente: require('../../imagens/e.png') });
                    break;
                case "f":
                    this.setState({ fotoCliente: require('../../imagens/f.png') });
                    break;
                case "g":
                    this.setState({ fotoCliente: require('../../imagens/g.png') })
                    break;
                case "h":
                    this.setState({ fotoCliente: require('../../imagens/h.png') });
                    break;
                case "i":
                    this.setState({ fotoCliente: require('../../imagens/i.png') });
                    break;
                case "j":
                    this.setState({ fotoCliente: require('../../imagens/j.png') });
                    break;
                case "k":
                    this.setState({ fotoCliente: require('../../imagens/k.png') });
                    break;
                case "l":
                    this.setState({ fotoCliente: require('../../imagens/l.png') });
                    break;
                case "m":
                    this.setState({ fotoCliente: require('../../imagens/m.png') });
                    break;
                case "n":
                    this.setState({ fotoCliente: require('../../imagens/n.png') });
                    break;
                case "o":
                    this.setState({ fotoCliente: require('../../imagens/o.png') });
                    break;
                case "p":
                    this.setState({ fotoCliente: require('../../imagens/p.png') });
                    break;
                case "q":
                    this.setState({ fotoCliente: require('../../imagens/q.png') });
                    break;
                case "r":
                    this.setState({ fotoCliente: require('../../imagens/r.png') });
                    break;
                case "s":
                    this.setState({ fotoCliente: require('../../imagens/s.png') });
                    break;
                case "t":
                    this.setState({ fotoCliente: require('../../imagens/t.png') });
                    break;
                case "u":
                    this.setState({ fotoCliente: require('../../imagens/u.png') });
                    break;
                case "v":
                    this.setState({ fotoCliente: require('../../imagens/v.png') });
                    break;
                case "x":
                    this.setState({ fotoCliente: require('../../imagens/x.png') });
                    break;
                case "z":
                    this.setState({ fotoCliente: require('../../imagens/z.png') });
                    break;
                case "w":
                    this.setState({ fotoCliente: require('../../imagens/w.png') });
                    break;
                case "y":
                    this.setState({ fotoCliente: require('../../imagens/y.png') });
                    break;
                default:
                    this.setState({ fotoCliente: require('../../imagens/userPadrao.png') });
                    // 
                    break;

            }
        } else {
            //   
            let urlIMG = require('../../imagens/userPadrao.png')
            this.setState({ fotoCliente: urlIMG });
        }

    }
    navegaTelaAlterarSetorLocal() {

    }
    last(array) {
        return array[array.length - 1];
    }
    renderImagem() {
        if (this.state.fotoCliente == null) {
            return (
                <div style={{ height: 40, width: 40, borderRadius: '50%', backgroundColor: 'rgba(159,173,183, 0.3)', border: 'none' }} />
            )
        } else {
            return (
                <img src={this.state.fotoCliente} draggable={false} alt="foto" style={{ height: 40, width: 40, borderRadius: 50, backgroundColor: 'rgba(159,173,183, 0.3)' }} />
            )
        }
    }
    renderHoraMinuto(dataHoraAbertura) {
        // 
        //
        let formattedTime = moment(dataHoraAbertura, 'DD/MM/YYYY HH:mm').format('HH:mm');
        return formattedTime;

    }
    renderIconeVisualizaImagem() {
        if (this.props.data.lastMessageChat === `Mensagem Apagada`) {
            return (
                <i className="icon-block" style={{ fontSize: 13, color: this.props.coresLetraSecundario, fontWeight: 'bold' }} />
            )
        }
        else if (this.state.lastMessageLineColor === '#00FF7F' && this.state.lastMessageStatus === 'waiting' && this.state.lastMessage !== `${this.props.nome} entrou no Chat`
            && !this.state.lastMessage.includes('Atendimento atribuido ao atendente') && !this.state.lastMessage.includes('encerrou o Chat')) {
            return (
                <i className="icon-clockteste3" style={{ fontSize: 13, color: this.props.coresLetraSecundario, fontWeight: 'bold' }} />
            )
        } else if (this.state.lastMessageLineColor === '#00FF7F' && this.state.lastMessageStatus === 'sent' && this.state.lastMessage !== `${this.props.nome} entrou no Chat` && !this.state.lastMessage.includes('Atendimento atribuido ao atendente') && !this.state.lastMessage.includes('encerrou o Chat')) {
            return (
                <i className="icon-checkwpp2" style={{ fontSize: 13, color: this.props.coresLetraSecundario }} />
            )
        } else if (this.state.lastMessageLineColor === '#00FF7F' && this.state.lastMessageStatus === 'received' && this.state.lastMessage !== `${this.props.nome} entrou no Chat` && !this.state.lastMessage.includes('Atendimento atribuido ao atendente') && !this.state.lastMessage.includes('encerrou o Chat')) {
            return (
                <i className="icon-double-tick-indicator" style={{ fontSize: 15, color: this.props.coresLetraSecundario }} />
            )
        } else if (this.state.lastMessageLineColor === '#00FF7F' && this.state.lastMessageStatus === 'read' && this.state.lastMessage !== `${this.props.nome} entrou no Chat` && !this.state.lastMessage.includes('Atendimento atribuido ao atendente') && !this.state.lastMessage.includes('encerrou o Chat')) {
            return (
                <i className="icon-double-tick-indicator" style={{ fontSize: 16, color: '#39a3f4' }} />
            )
        } else {
            return null
        }
    }
    render() {
        /*      return (
                 <ChatItem
                     onClick={() => {
                         this.props.modificaKeyAtendimentoEmEsperaAtendimentoOnline(this.props.data.key);
                         this.props.modificaAtendimentoEmEsperaSelecionado(this.props.data);
                     }}
                     avatar={aguardandoAtendimento}
                     avatarFlexible={false}
                     alt={'foto cliente'}
                     title={'Sergio SOuza'}
                     subtitle={this.props.data.descricaoProblema}
                     //date={this.renderHoraMinuto(this.props.data.descricaoProblema)}
                     dateString={this.renderHoraMinuto(this.props.data.dataHoraAbertura)}
                     unread={1} />
             ) */
        return (
            <>
                <ContainerChamado onClick={() => {
                    setTimeout(() => {
                        this.props.modificaKeyAtendimentoEmAtendimentoOnline('');
                        this.props.modificaKeyAtendimentoEmEsperaAtendimentoOnline('');
                        this.props.modificaKeyContacts(this.props.data.phone);
                        this.props.modificaDadosContactsSelecionado([this.props.data]);
                        this.props.listaConversasAntigasWppContacts(0, this.props.data.phone, '', [], this.props.verificaAgrupaAtendenteSetor, this.props.isAdmin, this.props.usuarioAdmVisualizaApenasChamadosDoSetorVinculado, this.props.keyUsuarioLogado, this.props.atendenteVeApenasProprioHistoricoChamado)
                        /* 
                        this.props.modificaKeyAtendimentoEmAtendimentoOnline('');
                        this.props.modificaKeyAtendimentoEmEsperaAtendimentoOnline(this.props.data.key);
                        this.props.modificaAtendimentoEmEsperaSelecionado(this.props.data);
                        this.setState({ newmessage: 0 }) */
                    }, 0);
                }} bordadinamica={this.props.coresLetraSecundario} backGroundDinamico={this.props.data.key == this.props.keyContacts ? shade(0.3, this.props.backgroundPrimeiro) : this.props.backgroundPrimeiro} type="button" >
                    {/* {this.props.data.key == this.props.keyContacts && <div style={{ width: 5, backgroundColor: this.props.corBotaoPadrao, height: 60,  }}></div>} */}
                    <ContainerImagem>
                        {/*    {this.renderImagem()} */}

                        <Avatar
                            name={this.props.data.nome}
                            size='50'
                            src={!!this.props.data.urlImagemNuvem ? this.props.data.urlImagemNuvem : undefined}
                            maxInitials={2}
                            round
                            style={{ cursor: "pointer" }}
                        />
                    </ContainerImagem>
                    <ContainerDadosChamado bordadinamica={this.props.coresLetraSecundario} >
                        <ContaineDadosChamadosPrincipal>
                            <SubContainerIconeEFraseClienteAtendente >
                                <TextCliente corLetraDinamica={this.props.coresLetraPadrao}>{this.props.data.nome}</TextCliente>
                            </SubContainerIconeEFraseClienteAtendente>
                            <SubContainerIconeEFraseClienteAtendente >
                                <TextEmpresa data-tip={this.state.lastMessage} data-for="tip-top" corLetraDinamica={this.props.coresLetraSecundario}>{this.props.data.phone}</TextEmpresa>
                            </SubContainerIconeEFraseClienteAtendente>
                        </ContaineDadosChamadosPrincipal>

                    </ContainerDadosChamado>


                </ContainerChamado>


            </>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return (
        {
            backgroundPrimeiro: state.CoresPadraoSistema.backgroundPrimeiro,
            backgroundSegundo: state.CoresPadraoSistema.backgroundSegundo,
            backgroundMaisEscuro: state.CoresPadraoSistema.backgroundMaisEscuro,
            coresLetraPadrao: state.CoresPadraoSistema.coresLetraPadrao,
            coresLetraSecundario: state.CoresPadraoSistema.coresLetraSecundario,
            corBotaoPadrao: state.CoresPadraoSistema.corBotaoPadrao,
            corSegundoBotaoPadrao: state.CoresPadraoSistema.corSegundoBotaoPadrao,
            corLetraDescricaoDosCampos: state.CoresPadraoSistema.corLetraDescricaoDosCampos,
            nomeRota: state.HeaderReducer.nomeRota,
            // conversasChatEmEspera: state.ChamadoReducer.conversasChatEmEspera[ownProps.data.key],
            keyContacts: state.ChamadoReducer.keyContacts,

            isAdmin: state.PerfilReducer.isAdmin,
            usuarioAdmVisualizaApenasChamadosDoSetorVinculado: state.PerfilReducer.usuarioAdmVisualizaApenasChamadosDoSetorVinculado,
            verificaAgrupaAtendenteSetor: state.ChamadoReducer.verificaAgrupaAtendenteSetor,
            keyUsuarioLogado: state.PerfilReducer.keyUsuarioLogado,

            atendenteVeApenasProprioHistoricoChamado: state.ChamadoReducer.atendenteVeApenasProprioHistoricoChamado,
        }
    );
};
export default connect(mapStateToProps, {
    modificaKeyAtendimentoEmEsperaAtendimentoOnline, modificaAtendimentoEmEsperaSelecionado, modificaKeyAtendimentoEmAtendimentoOnline,
    modificaKeyContacts, modificaDadosContactsSelecionado, listaConversasAntigasWppContacts
})(memo(FlatListAllContacts));
