import moment from 'moment';
import { toastr } from 'react-redux-toastr';
import firebase from '../servicos/FirebaseConnection';

import {
  MODIFICADESTINATARIOSELECIONADO,
  ATIVADESATIVALOADINGCARREGARATENDENTETELANOVOAVISO,
  ENVIAATENDENTEPARAREDUCERNOVOAVISO,
  MODIFICAATENDENTESELECIONADONOVOAVISO,
  ATIVADESATIVALOADINGCARREGARCLIENTETELANOVOAVISO,
  ENVIACLIENTEPARAREDUCERNOVOAVISO,
  MODIFICAClIENTESELECIONADONOVOAVISO,
  ALTERADESCRICAOMENSAGEMNOVOAVISO,
  MENSAGEMENVIAAVISOERROR,
  ATIVADESATIVALOADINGCADASTRARNOVOAVISO,
  ZERACADASTROAVISO,
  ALTERARVALORSWITCHNOVOAVISO,
  MODIFICADATAFINALNOVOAVISO,
  MODIFICADATAINICIALNOVOAVISO,
  ATIVADESATIVALOADINGTELANOVOAVISO,
  ENVIAAVISOPARAREDUCER,
  ATIVADESATIVALOADINGREFRESHCONTROLNOVOAVISO,
  ENVIAREFERENCIAULTIMAKEYAVISO,
  ATIVADESATIVALOADINGFIMDAPAGINACONSULTAAVISO,
  ENVIASEMCONEXAOINTERNETCONSULTAAVISO,
  ALTERAFILTROSELECIONADOTELANOVOAVISO,
  ALTERAKEYAVISOPARAALTERACAO,
  ALTERASTATUSAVISOPARAALTERACAO,
  ZERACADASTROAVISOPARAALTERACAO,
  ENVIAAVISOPARATELADASHBOARD,
  ATIVADESATIVALOADINGALTERARAVISO,
  MUDAVISIBILIDADEMODALAVISOVERSAOPREMIUM, MODIFICAVISIBLEMODALNOVOAVISO,
  MODIFICADESTINATARIOSELECIONADONOVOCADASTRO, MODIFICAATENDENTESELECIONADONOVOAVISONOVOCADASTRO,
  MODIFICAClIENTESELECIONADONOVOAVISONOVOCADASTRO, ALTERARVALORSWITCHNOVOAVISONOVOCADASTRO,
  MODIFICADATAINICIALNOVOAVISONOVOCADASTRO,
  MODIFICADATAFINALNOVOAVISONOVOCADASTRO, ALTERADESCRICAOMENSAGEMNOVOAVISONOVOCADASTRO
} from "./types";

export const criarAviso2 = (
  mensagem2,
  destinatarioSelecionadoValor,
  clienteSelecionado,
  atendenteSelecionado,
  porFaixaHorario,
  dataInicial,
  dataFinal
) => {
  let mensagem = mensagem2.trim();
  let cliente = clienteSelecionado.toString().trim();
  let atendente = atendenteSelecionado.toString();
  let dataAtual = moment().toDate();
  dataAtual = moment(dataAtual).format("DD/MM/YYYY HH:mm");
  let dataAtualVerificacao = moment(dataAtual, "DD/MM/YYYY HH:mm").format(
    "YYYY-MM-DD HH:mm"
  );
  let dataInicialVerificacao = moment(dataInicial, "DD/MM/YYYY HH:mm").format(
    "YYYY-MM-DD HH:mm"
  );
  let dataFinalVerificacao = moment(dataFinal, "DD/MM/YYYY HH:mm").format(
    "YYYY-MM-DD HH:mm"
  );
  let verificaSeDataAtualMaiorQueDataFinal = moment(
    dataFinalVerificacao
  ).isBefore(dataAtualVerificacao);
  let verificaSeDataInicialMaiorQueDataFinal = moment(
    dataFinalVerificacao
  ).isBefore(dataInicialVerificacao);

  /* 
  
   */

  return dispatch => {
    //destinatarioSelecionadoValor === 1 é atendente
    //destinatarioSelecionadoValor === 2 é cliente
    if (destinatarioSelecionadoValor === 0) {
      toastr.error('Error', "Selecione um DESTINATÁRIO.");
    } else if (cliente == "" && destinatarioSelecionadoValor == 2) {
      toastr.error('Error',
        "Selecione um Cliente para criar o Aviso."
      );
    } else if (
      porFaixaHorario == true &&
      dataInicial == "" &&
      dataFinal == ""
    ) {
      toastr.error('Error',
        "Informe a DATA INICIAL e DATA FINAL do período de exibição."
      );
    } else if (porFaixaHorario == true && dataInicial == "") {
      toastr.error('Error',
        "Informe a DATA INICIAL do período de exibição."
      );
    } else if (porFaixaHorario == true && dataFinal == "") {
      toastr.error('Error',
        "Informe a DATA FINAL do período de exibição."
      );
    } else if (
      porFaixaHorario == true &&
      verificaSeDataAtualMaiorQueDataFinal == true
    ) {
      toastr.error('Error',
        "DATA E HORA FINAL menor que a data de HOJE."
      );
    } else if (
      porFaixaHorario == true &&
      verificaSeDataInicialMaiorQueDataFinal == true
    ) {
      toastr.error('Error',
        "DATA E HORA INICIAL maior que DATA FINAL, por favor verifique."
      );
    } else if (mensagem === "") {
      toastr.error('Error', "Informe a MENSAGEM de AVISO.");
    } else if (destinatarioSelecionadoValor === 2 && cliente === "") {
      toastr.error('Error', "Informe um CLIENTE.");
    } else if (destinatarioSelecionadoValor === 1 && atendenteSelecionado === "") {
      toastr.error('Error', "Informe um ATENDENTE.");
    } else {
      AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
        const chaveUnica = chaveUnica2.replace(/"/g, "");
        ativaDesativaLoadingCadastrarNovoAviso(dispatch, true);
        let uidUsuarioLogado = firebase.auth().currentUser.uid;

        //pego key de quem Criou o aviso
        firebase
          .database()
          .ref(`${chaveUnica}/usuarios/`)
          .orderByChild("uid")
          .equalTo(uidUsuarioLogado)
          .once("value")
          .then(snapshot => {
            let arrayKeyQuemCriouAviso = [];
            snapshot.forEach(childItem => {
              arrayKeyQuemCriouAviso.push({
                keyQuemAbriuAviso: childItem.key
              });
            });
            let keyDeQuemAbriuAviso =
              arrayKeyQuemCriouAviso[0].keyQuemAbriuAviso;
            firebase
              .database()
              .ref(`${chaveUnica}/avisos/`)
              .push({
                destinatario: destinatarioSelecionadoValor,
                cliente: destinatarioSelecionadoValor == 1 ? "" : cliente,
                atendente: destinatarioSelecionadoValor == 2 ? "" : atendente,
                aviso: mensagem,
                keyDeQuemAbriuAviso: keyDeQuemAbriuAviso,
                ativo: true,
                porFaixaHorario,
                dataInicial: porFaixaHorario == false ? "" : dataInicial,
                dataFinal: porFaixaHorario == false ? "" : dataFinal,
                alterado: 0
              })
              .then(() => {
                ativaDesativaLoadingCadastrarNovoAviso(dispatch, false);
                toastr.success('SUCESSO', 'Aviso criado com Sucesso!')
                zeraCadastroAviso(dispatch);
              });
          });
      });
    }
  };
};


export const alterarAviso2 = (
  mensagem2,
  destinatarioSelecionadoValor,
  clienteSelecionado,
  atendenteSelecionado,
  porFaixaHorario,
  dataInicial,
  dataFinal,
  status,
  keyAviso
) => {
  /* console.log( mensagem2,
    destinatarioSelecionadoValor,
    clienteSelecionado,
    atendenteSelecionado,
    porFaixaHorario,
    dataInicial,
    dataFinal,
    status,
    keyAviso) */
  let mensagem = mensagem2.trim();
  let cliente = clienteSelecionado.toString();
  let atendente = atendenteSelecionado.toString();
  let dataAtual = moment().toDate();
  dataAtual = moment(dataAtual).format("DD/MM/YYYY HH:mm");
  let dataAtualVerificacao = moment(dataAtual, "DD/MM/YYYY HH:mm").format("YYYY-MM-DD HH:mm");
  let dataInicialVerificacao = moment(dataInicial, "DD/MM/YYYY HH:mm").format("YYYY-MM-DD HH:mm");
  let dataFinalVerificacao = moment(dataFinal, "DD/MM/YYYY HH:mm").format("YYYY-MM-DD HH:mm");
  /* 
   */
  let verificaSeDataAtualMaiorQueDataFinal = moment(dataFinalVerificacao).isBefore(dataAtualVerificacao);
  let verificaSeDataInicialMaiorQueDataFinal = moment(dataFinalVerificacao).isBefore(dataInicialVerificacao);

  return dispatch => {
    //destinatarioSelecionadoValor === 1 é atendente
    //destinatarioSelecionadoValor === 2 é cliente
    if (destinatarioSelecionadoValor === 0) {
      toastr.error('Error', "Selecione um DESTINATÁRIO.");
    } else if (cliente == "" && destinatarioSelecionadoValor == 2) {
      toastr.error('Error', "Selecione um Cliente para criar o Aviso.");
    } else if (porFaixaHorario == true && dataInicial == "" && dataFinal == "") {
      toastr.error('Error', "Informe a DATA INICIAL e DATA FINAL do período de exibição.");
    } else if (porFaixaHorario == true && dataInicial == "") {
      toastr.error('Error', "Informe a DATA INICIAL do período de exibição.");
    } else if (porFaixaHorario == true && dataFinal == "") {
      toastr.error('Error', "Informe a DATA FINAL do período de exibição.");
    } else if (porFaixaHorario == true && verificaSeDataAtualMaiorQueDataFinal == true && status == true) {
      toastr.error('Error', "DATA E HORA FINAL menor que a data de HOJE.");
    } else if (porFaixaHorario == true && verificaSeDataInicialMaiorQueDataFinal == true && status == true) {
      toastr.error('Error', "DATA E HORA INICIAL maior que DATA FINAL, por favor verifique.");
    } else if (mensagem === "") {
      toastr.error('Error', "Informe a MENSAGEM de AVISO.");
    } else if (destinatarioSelecionadoValor === 2 && cliente === "") {
      toastr.error('Error', "Informe um CLIENTE.");
    } else {
      AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
        const chaveUnica = chaveUnica2.replace(/"/g, "");
        ativaDesativaLoadingAlterarAviso(dispatch, true);
        firebase
          .database()
          .ref(`${chaveUnica}/avisos/${keyAviso}`)
          .once("value")
          .then(snapshot => {
            //
            let alteradoSomado = snapshot.val().alterado + 1;
            firebase
              .database()
              .ref(`${chaveUnica}/avisos/${keyAviso}`)
              .update({
                destinatario: destinatarioSelecionadoValor,
                cliente: destinatarioSelecionadoValor == 1 ? "" : cliente,
                atendente: destinatarioSelecionadoValor == 2 ? "" : atendente,
                aviso: mensagem,
                alterado: alteradoSomado,
                ativo: status,
                porFaixaHorario,
                dataInicial: porFaixaHorario == false ? "" : dataInicial,
                dataFinal: porFaixaHorario == false ? "" : dataFinal
              })
              .then(() => {
                setTimeout(() => {
                  //dispatch(NavigationActions.back());
                  toastr.success(
                    'SUCESSO',
                    "Aviso alterado com SUCESSO!"
                  );
                  ativaDesativaLoadingAlterarAviso(dispatch, false);
                }, 200);
              });
          });
      });
    }
  };
};
export const listaAvisosTelaDashBoard = () => {
  return dispatch => {
    AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");

      let dataAtual = moment().toDate();
      dataAtual = moment(dataAtual).format("YYYY-MM-DD HH:mm");
      //pego key de quem Criou o aviso
      firebase
        .database()
        .ref(`${chaveUnica}/usuarios/`)
        .orderByChild("uid")
        .equalTo(firebase.auth().currentUser.uid)
        .once("value")
        .then(snapshot => {
          let arrayKeyQuemCriouAviso = [];

          snapshot.forEach(childItem => {
            arrayKeyQuemCriouAviso.push({
              keyQuemAbriuAviso: childItem.key
            });
          });
          let keyUsuarioLogado = arrayKeyQuemCriouAviso[0].keyQuemAbriuAviso;
          firebase
            .database()
            .ref(`${chaveUnica}/avisos/`)
            .orderByChild("ativo")
            .equalTo(true)
            .limitToLast(10)
            .on("value", snapshot => {
              let arrayAvisos = [];
              //;
              snapshot.forEach(childItem => {
                if (childItem.val().destinatario == 1) {
                  if (
                    childItem.val().atendente == "TodosPadrao" ||
                    childItem.val().atendente == keyUsuarioLogado
                  ) {
                    if (childItem.val().porFaixaHorario == true) {
                      let dataHoraInicial = childItem.val().dataInicial;
                      let dataHoraFinal = childItem.val().dataFinal;
                      let dataHoraInicialVerificacao = moment(
                        dataHoraInicial,
                        "DD/MM/YYYY HH:mm"
                      ).format("YYYY-MM-DD HH:mm");
                      let dataHoraFinalVerificacao = moment(
                        dataHoraFinal,
                        "DD/MM/YYYY HH:mm"
                      ).format("YYYY-MM-DD HH:mm");
                      // 
                      // 
                      let alterado = childItem.val().alterado + 1;
                      let VerificaSeDataInicialEhDepoisDataAtual = moment(
                        dataHoraInicialVerificacao
                      ).isAfter(dataAtual);
                      let VerificaSeDataFinalEhAntesDataAtual = moment(
                        dataHoraFinalVerificacao
                      ).isAfter(dataAtual);
                      let vigorData = moment(dataAtual).isBetween(
                        dataHoraInicialVerificacao,
                        dataHoraFinalVerificacao
                      );
                      let dataInicialIgualDataAtual = moment(
                        dataHoraInicialVerificacao
                      ).isSame(dataAtual);
                      if (vigorData || dataInicialIgualDataAtual) {
                        /*   console.log(
                            `está em vigor ou data inicial igual data atual`
                          ); */
                        //então o aviso ainda está no prazo correto
                        arrayAvisos.push({
                          key: childItem.key,
                          destinatario: childItem.val().destinatario,
                          cliente: childItem.val().cliente,
                          atendente: childItem.val().atendente,
                          aviso: childItem.val().aviso,
                          keyDeQuemAbriuAviso: childItem.val()
                            .keyDeQuemAbriuAviso,
                          ativo: childItem.val().ativo,
                          porFaixaHorario: childItem.val().porFaixaHorario,
                          dataInicial: childItem.val().dataInicial,
                          dataFinal: childItem.val().dataFinal,
                          alterado: childItem.val().alterado
                        });
                      }
                      //ainda nao entrou em vigor, entao nao faz nada
                      else if (VerificaSeDataInicialEhDepoisDataAtual) {
                        /*    console.log(
                             `a data inicial ainda nao entrou em vigor ou seja a data inicial é depois da data atual`
                           ); */
                      } else {
                        /*   */
                        firebase
                          .database()
                          .ref(`${chaveUnica}/avisos/${childItem.key}`)
                          .update({
                            ativo: false,
                            alterado: alterado
                          });
                      }
                    } else {
                      arrayAvisos.push({
                        key: childItem.key,
                        destinatario: childItem.val().destinatario,
                        cliente: childItem.val().cliente,
                        atendente: childItem.val().atendente,
                        aviso: childItem.val().aviso,
                        keyDeQuemAbriuAviso: childItem.val()
                          .keyDeQuemAbriuAviso,
                        ativo: childItem.val().ativo,
                        porFaixaHorario: childItem.val().porFaixaHorario,
                        dataInicial: childItem.val().dataInicial,
                        dataFinal: childItem.val().dataFinal,
                        alterado: childItem.val().alterado
                      });
                    }
                  }
                }
              });
              /*   
                  */
              enviaAvisoParaTelaDashBoard(dispatch, arrayAvisos);
            });
        });

    });
  };
};

export const consultaAviso = (valor, referenciaUltimaKey, avisosAnteriores) => {
  return dispatch => {

    AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");
      //
      if (valor == 0) {
        // if initial fetch
        ativaDesativaLoadingTelaNovoAviso(dispatch, true);
        ativaDesativaLoadingRefreshControlNovoAviso(dispatch, true);
        firebase
          .database()
          .ref(`${chaveUnica}/avisos/`)
          .orderByKey()
          .limitToLast(10)
          .on("value", snapshot => {
            if (snapshot.val() == null) {
              const arrayUser = [];
              enviaAvisoParaReducer(dispatch, arrayUser);
              ativaDesativaLoadingTelaNovoAviso(dispatch, false);
              ativaDesativaLoadingRefreshControlNovoAviso(dispatch, false);
            } else {
              let arrayOfKeys = Object.keys(snapshot.val()).sort().reverse();
              let results = arrayOfKeys.map(key => snapshot.val()[key]);
              let referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
              let arrayNotices = [];
              let promisseCliente;

              for (let i = 0; i < results.length; i++) {
                const cliente = results[i].cliente;
                if (cliente && cliente !== "TodosPadrao") {
                  promisseCliente = firebase.database().ref(`${chaveUnica}/clientes/${cliente}/nome`).once("value");
                  arrayNotices.push(promisseCliente);
                }
              }

              const dadosAviso = [];
              let aux = 0;
              Promise.all(arrayNotices).then(responses => {
                for (let i = 0; i < results.length; i++) {
                  dadosAviso.push({
                    key: arrayOfKeys[i],
                    atendente: results[i].atendente,
                    ativo: results[i].ativo,
                    aviso: results[i].aviso,
                    cliente:
                      !!results[i].cliente && results[i].cliente !== "TodosPadrao" ?
                        responses[aux].val() == null ? "Não informado" : responses[aux].val() :
                        results[i].cliente,
                    dataFinal: results[i].dataFinal,
                    dataInicial: results[i].dataInicial,
                    destinatario: results[i].destinatario,
                    keyDeQuemAbriuAviso: results[i].keyDeQuemAbriuAviso,
                    porFaixaHorario: results[i].porFaixaHorario,
                    alterado: results[i].alterado,
                    chaveUnica: chaveUnica
                  });
                  if (!!results[i].cliente && results[i].cliente !== "TodosPadrao") {
                    aux++;
                  }
                }
                enviaAvisoParaReducer(dispatch, dadosAviso);
                enviaReferenciaUltimaKeyAviso(dispatch, referenceToOldestKey);
                ativaDesativaLoadingTelaNovoAviso(dispatch, false);
                ativaDesativaLoadingRefreshControlNovoAviso(dispatch, false);
                ativaDesativaLoadingFimdaPaginaConsultaAviso(dispatch, true);
              })
            }
          });
      } else {
        if (referenciaUltimaKey != undefined && referenciaUltimaKey != "") {
          //ativaDesativaLoadingFimdaPaginaConsultaAviso(dispatch, true);
          firebase
            .database()
            .ref(`${chaveUnica}/avisos/`)
            .orderByKey()
            .endAt(referenciaUltimaKey)
            .limitToLast(11)
            .on("value", snapshot => {
              if (snapshot.val() == null) {
                ativaDesativaLoadingFimdaPaginaConsultaAviso(
                  dispatch,
                  false
                );
              } else {
                // changing to reverse chronological order (latest first)
                let arrayOfKeys = Object.keys(snapshot.val())
                  .sort()
                  .reverse()
                  .slice(1);
                // transforming to array
                let results = arrayOfKeys.map(key => snapshot.val()[key]);
                // storing reference
                let referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
                let arrayNotices = [];
                let promisseCliente;

                for (let i = 0; i < results.length; i++) {
                  const cliente = results[i].cliente;
                  if (cliente && cliente !== "TodosPadrao") {
                    promisseCliente = firebase.database().ref(`${chaveUnica}/clientes/${cliente}/nome`).once("value");
                    arrayNotices.push(promisseCliente);
                  }
                }

                const dadosAviso = [];
                let aux = 0;
                Promise.all(arrayNotices).then(responses => {
                  for (let i = 0; i < results.length; i++) {
                    dadosAviso.push({
                      key: arrayOfKeys[i],
                      atendente: results[i].atendente,
                      ativo: results[i].ativo,
                      aviso: results[i].aviso,
                      cliente:
                        !!results[i].cliente && results[i].cliente !== "TodosPadrao" ?
                          responses[aux].val() == null ? "Não informado" : responses[aux].val() :
                          results[i].cliente,
                      dataFinal: results[i].dataFinal,
                      dataInicial: results[i].dataInicial,
                      destinatario: results[i].destinatario,
                      keyDeQuemAbriuAviso: results[i].keyDeQuemAbriuAviso,
                      porFaixaHorario: results[i].porFaixaHorario,
                      alterado: results[i].alterado,
                      chaveUnica: chaveUnica
                    });
                    if (!!results[i].cliente && results[i].cliente !== "TodosPadrao") {
                      aux++;
                    }
                  }

                  enviaAvisoParaReducer(
                    dispatch,
                    avisosAnteriores.concat(dadosAviso)
                  );
                  enviaReferenciaUltimaKeyAviso(
                    dispatch,
                    referenceToOldestKey
                  );
                })
                /*   ativaDesativaLoadingFimdaPaginaConsultaAviso(
                    dispatch,
                    false
                  ); */
              }
            });
        } else {
          ativaDesativaLoadingFimdaPaginaConsultaAviso(dispatch, false);
        }
      }
    });

  };
};
export const consultaAvisoPorAtendente = () => {
  return dispatch => {

    ativaDesativaLoadingTelaNovoAviso(dispatch, true);
    AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");
      firebase
        .database()
        .ref(`${chaveUnica}/avisos/`)
        .orderByChild("destinatario")
        .equalTo(1)
        .once("value")
        .then(snapshot => {
          if (snapshot.val() == null) {
            const arrayUser = [];
            enviaAvisoParaReducer(dispatch, arrayUser);
            ativaDesativaLoadingTelaNovoAviso(dispatch, false);
          } else {
            let arrayOfKeys = Object.keys(snapshot.val())
              .sort()
              .reverse();
            // transforming to array
            let results = arrayOfKeys.map(key => snapshot.val()[key]);
            // storing reference
            let referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];

            const dadosAviso = [];

            for (let i = 0; i < results.length; i++) {
              dadosAviso.push({
                key: arrayOfKeys[i],
                atendente: results[i].atendente,
                ativo: results[i].ativo,
                aviso: results[i].aviso,
                cliente: results[i].cliente,
                dataFinal: results[i].dataFinal,
                dataInicial: results[i].dataInicial,
                destinatario: results[i].destinatario,
                keyDeQuemAbriuAviso: results[i].keyDeQuemAbriuAviso,
                porFaixaHorario: results[i].porFaixaHorario,
                chaveUnica: chaveUnica
              });
            }
            enviaAvisoParaReducer(dispatch, dadosAviso);
            ativaDesativaLoadingTelaNovoAviso(dispatch, false);
          }
        });
    });

  };
};
export const consultaAvisoPorCliente = () => {
  return dispatch => {

    ativaDesativaLoadingTelaNovoAviso(dispatch, true);
    AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");
      firebase
        .database()
        .ref(`${chaveUnica}/avisos/`)
        .orderByChild("destinatario")
        .equalTo(2)
        .once("value")
        .then(snapshot => {
          if (snapshot.val() == null) {
            const arrayUser = [];
            enviaAvisoParaReducer(dispatch, arrayUser);
            ativaDesativaLoadingTelaNovoAviso(dispatch, false);
          } else {
            let arrayOfKeys = Object.keys(snapshot.val())
              .sort()
              .reverse();
            // transforming to array
            let results = arrayOfKeys.map(key => snapshot.val()[key]);
            // storing reference
            let referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];

            let arrayNotices = [];
            let promisseCliente;

            for (let i = 0; i < results.length; i++) {
              const cliente = results[i].cliente;
              if (cliente && cliente !== "TodosPadrao") {
                promisseCliente = firebase.database().ref(`${chaveUnica}/clientes/${cliente}/nome`).once("value");
                arrayNotices.push(promisseCliente);
              }
            }

            const dadosAviso = [];
            let aux = 0;
            Promise.all(arrayNotices).then(responses => {
              for (let i = 0; i < results.length; i++) {
                dadosAviso.push({
                  key: arrayOfKeys[i],
                  atendente: results[i].atendente,
                  ativo: results[i].ativo,
                  aviso: results[i].aviso,
                  cliente:
                    !!results[i].cliente && results[i].cliente !== "TodosPadrao" ?
                      responses[aux].val() == null ? "Não informado" : responses[aux].val() :
                      results[i].cliente,
                  dataFinal: results[i].dataFinal,
                  dataInicial: results[i].dataInicial,
                  destinatario: results[i].destinatario,
                  keyDeQuemAbriuAviso: results[i].keyDeQuemAbriuAviso,
                  porFaixaHorario: results[i].porFaixaHorario,
                  alterado: results[i].alterado,
                  chaveUnica: chaveUnica
                });
                if (!!results[i].cliente && results[i].cliente !== "TodosPadrao") {
                  aux++;
                }
              }
              enviaAvisoParaReducer(dispatch, dadosAviso);
            ativaDesativaLoadingTelaNovoAviso(dispatch, false);
            })
            
          }
        });
    });

  };
};
export const consultaAvisoPorStatusAtivo = () => {
  return dispatch => {

    ativaDesativaLoadingTelaNovoAviso(dispatch, true);
    AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");
      firebase
        .database()
        .ref(`${chaveUnica}/avisos/`)
        .orderByChild("ativo")
        .equalTo(true)
        .once("value")
        .then(snapshot => {
          if (snapshot.val() == null) {
            const arrayUser = [];
            enviaAvisoParaReducer(dispatch, arrayUser);
            ativaDesativaLoadingTelaNovoAviso(dispatch, false);
          } else {
            let arrayOfKeys = Object.keys(snapshot.val())
              .sort()
              .reverse();
            // transforming to array
            let results = arrayOfKeys.map(key => snapshot.val()[key]);
            // storing reference
            let referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];

            const dadosAviso = [];

            for (let i = 0; i < results.length; i++) {
              dadosAviso.push({
                key: arrayOfKeys[i],
                atendente: results[i].atendente,
                ativo: results[i].ativo,
                aviso: results[i].aviso,
                cliente: results[i].cliente,
                dataFinal: results[i].dataFinal,
                dataInicial: results[i].dataInicial,
                destinatario: results[i].destinatario,
                keyDeQuemAbriuAviso: results[i].keyDeQuemAbriuAviso,
                porFaixaHorario: results[i].porFaixaHorario,
                chaveUnica: chaveUnica
              });
            }
            enviaAvisoParaReducer(dispatch, dadosAviso);
            ativaDesativaLoadingTelaNovoAviso(dispatch, false);
          }
        });
    });

  };
};

export const consultaAvisoPorStatusInativo = () => {
  return dispatch => {

    ativaDesativaLoadingTelaNovoAviso(dispatch, true);
    AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");
      firebase
        .database()
        .ref(`${chaveUnica}/avisos/`)
        .orderByChild("ativo")
        .equalTo(false)
        .once("value")
        .then(snapshot => {
          if (snapshot.val() == null) {
            const arrayUser = [];
            enviaAvisoParaReducer(dispatch, arrayUser);
            ativaDesativaLoadingTelaNovoAviso(dispatch, false);
          } else {
            let arrayOfKeys = Object.keys(snapshot.val())
              .sort()
              .reverse();
            // transforming to array
            let results = arrayOfKeys.map(key => snapshot.val()[key]);
            // storing reference
            let referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];

            const dadosAviso = [];

            for (let i = 0; i < results.length; i++) {
              dadosAviso.push({
                key: arrayOfKeys[i],
                atendente: results[i].atendente,
                ativo: results[i].ativo,
                aviso: results[i].aviso,
                cliente: results[i].cliente,
                dataFinal: results[i].dataFinal,
                dataInicial: results[i].dataInicial,
                destinatario: results[i].destinatario,
                keyDeQuemAbriuAviso: results[i].keyDeQuemAbriuAviso,
                porFaixaHorario: results[i].porFaixaHorario,
                chaveUnica: chaveUnica
              });
            }
            enviaAvisoParaReducer(dispatch, dadosAviso);
            ativaDesativaLoadingTelaNovoAviso(dispatch, false);
          }
        });
    });

  };
};

const ativaDesativaLoadingAlterarAviso = (dispatch, valor) => {
  dispatch({
    type: ATIVADESATIVALOADINGALTERARAVISO,
    payload: valor
  });
};

const enviaAvisoParaTelaDashBoard = (dispatch, array) => {
  dispatch({
    type: ENVIAAVISOPARATELADASHBOARD,
    payload: array
  });
};

export const navegaTelaNovoAviso = () => {
  return dispatch => {
    zeraCadastroAvisoParaAlteracao(dispatch, true);
    //  dispatch(NavigationActions.navigate({ routeName: "NovoAviso" }));
  };
};
export const navegaTelaAlterarAviso = item => {
  /*  
    */
  return dispatch => {
    let cliente = item.cliente === "" ? "TodosPadrao" : item.cliente;
    let atendente = item.atendente === "" ? "TodosPadrao" : item.atendente;
    let destinatarioSelecionado =
      item.destinatario === 1 ? "Atendentes" : "Clientes";
    dispatch({
      type: MODIFICADESTINATARIOSELECIONADO,
      payload: destinatarioSelecionado
    });
    dispatch({
      type: MODIFICAATENDENTESELECIONADONOVOAVISO,
      payload: atendente
    });
    /*    */
    dispatch({ type: MODIFICAClIENTESELECIONADONOVOAVISO, payload: cliente });
    dispatch({
      type: ALTERARVALORSWITCHNOVOAVISO,
      payload: item.porFaixaHorario
    });
    dispatch({ type: MODIFICADATAINICIALNOVOAVISO, payload: item.dataInicial });
    dispatch({ type: MODIFICADATAFINALNOVOAVISO, payload: item.dataFinal });
    dispatch({ type: ALTERADESCRICAOMENSAGEMNOVOAVISO, payload: item.aviso });
    dispatch({ type: ALTERAKEYAVISOPARAALTERACAO, payload: item.key });
    dispatch({ type: ALTERASTATUSAVISOPARAALTERACAO, payload: item.ativo });
    //dispatch(NavigationActions.navigate({ routeName: "AlterarAviso" }));
  };
};
export const alteraStatusAviso = status => {
  return {
    type: ALTERASTATUSAVISOPARAALTERACAO,
    payload: status
  };
};
export const modificaVisibleModalNovoAviso = status => {
  return {
    type: MODIFICAVISIBLEMODALNOVOAVISO,
    payload: status
  };
};

export const mudaVisibilidadeModalAvisoVersaoPremium = visible => {
  return {
    type: MUDAVISIBILIDADEMODALAVISOVERSAOPREMIUM,
    payload: visible
  };
};
export const modificaDataInicialEFinalAlterarAviso = (dataInicial, dataFinal) => {
  return dispatch => {
    dispatch({ type: MODIFICADATAINICIALNOVOAVISO, payload: dataInicial });
    dispatch({ type: MODIFICADATAFINALNOVOAVISO, payload: dataFinal });
  }
}
const ativaDesativaLoadingFimdaPaginaConsultaAviso = (dispatch, valor) => {
  dispatch({
    type: ATIVADESATIVALOADINGFIMDAPAGINACONSULTAAVISO,
    payload: valor
  });
};
const enviaReferenciaUltimaKeyAviso = (dispatch, key) => {
  dispatch({
    type: ENVIAREFERENCIAULTIMAKEYAVISO,
    payload: key
  });
};

const enviaAvisoParaReducer = (dispatch, array) => {
  dispatch({
    type: ENVIAAVISOPARAREDUCER,
    payload: array
  });
};
const enviaSemConexaoInternetConsultaAviso = (dispatch, valor) => {
  dispatch({
    type: ENVIASEMCONEXAOINTERNETCONSULTAAVISO,
    payload: valor
  });
};
export const zeraEnviaConexaoInternetConsultaAviso = () => {
  return {
    type: ENVIASEMCONEXAOINTERNETCONSULTAAVISO,
    payload: ""
  };
};
export const alteraFiltroSelecionadoTelaNovoAviso = filtro => {
  return {
    type: ALTERAFILTROSELECIONADOTELANOVOAVISO,
    payload: filtro
  };
};

export const alteraValorSwitchNovoAviso = valor => {
  return {
    type: ALTERARVALORSWITCHNOVOAVISO,
    payload: valor
  };
};
const zeraCadastroAviso = (dispatch, valor) => {
  dispatch({
    type: ZERACADASTROAVISO,
    payload: valor
  });
};
const zeraCadastroAvisoParaAlteracao = (dispatch, valor) => {
  dispatch({
    type: ZERACADASTROAVISOPARAALTERACAO,
    payload: valor
  });
};
const mensagemEnviaAvisoError = (dispatch, error) => {
  dispatch({
    type: MENSAGEMENVIAAVISOERROR,
    payload: error
  });
};
export const modificaDataInicialNovoAviso = data => {
  let dataInicialFormatada = moment(data).format('DD/MM/YYYY HH:mm');
  /*    */
  return {
    type: MODIFICADATAINICIALNOVOAVISO,
    payload: dataInicialFormatada
  };
};
export const modificaDataFinalNovoAviso = data => {
  let dataFinalFormatada = moment(data).format('DD/MM/YYYY HH:mm');
  return {
    type: MODIFICADATAFINALNOVOAVISO,
    payload: dataFinalFormatada
  };
};
export const zeramensagemEnviaAvisoError = () => {
  return {
    type: MENSAGEMENVIAAVISOERROR,
    payload: ""
  };
};
export const zerCadastroAvisoExport = () => {
  return {
    type: ZERACADASTROAVISO,
    payload: ""
  };
};

export const alteraDescricaoMensagemNovoAviso = descricao => {
  return {
    type: ALTERADESCRICAOMENSAGEMNOVOAVISO,
    payload: descricao
  };
};
export const alteraMensagemNovoAvisoAudio = (descricaoAudio, descricao) => {
  const descricaoFinal = descricao + " " + descricaoAudio;
  return {
    type: ALTERADESCRICAOMENSAGEMNOVOAVISO,
    payload: descricaoFinal
  };
};
const enviaClienteParaReducerNovoAviso = (dispatch, arrayCliente) => {
  dispatch({
    type: ENVIACLIENTEPARAREDUCERNOVOAVISO,
    payload: arrayCliente
  });
};
const enviaAtendenteParaReducerNovoAviso = (dispatch, arrayAtendente) => {
  dispatch({
    type: ENVIAATENDENTEPARAREDUCERNOVOAVISO,
    payload: arrayAtendente
  });
};
const ativaDesativaLoadingCarregarAtendenteTelaNovoAviso = (
  dispatch,
  valor
) => {
  dispatch({
    type: ATIVADESATIVALOADINGCARREGARATENDENTETELANOVOAVISO,
    payload: valor
  });
};
const ativaDesativaLoadingTelaNovoAviso = (dispatch, valor) => {
  dispatch({
    type: ATIVADESATIVALOADINGTELANOVOAVISO,
    payload: valor
  });
};
const ativaDesativaLoadingRefreshControlNovoAviso = (dispatch, valor) => {
  dispatch({
    type: ATIVADESATIVALOADINGREFRESHCONTROLNOVOAVISO,
    payload: valor
  });
};
const ativaDesativaLoadingCadastrarNovoAviso = (dispatch, valor) => {
  dispatch({
    type: ATIVADESATIVALOADINGCADASTRARNOVOAVISO,
    payload: valor
  });
};
const ativaDesativaLoadingCarregarClienteTelaNovoAviso = (dispatch, valor) => {
  dispatch({
    type: ATIVADESATIVALOADINGCARREGARCLIENTETELANOVOAVISO,
    payload: valor
  });
};
export const modificaDestinatarioSelecionado = dest => {
  return {
    type: MODIFICADESTINATARIOSELECIONADO,
    payload: dest
  };
};
export const modificaAtendenteSelecionado = atendente => {
  return {
    type: MODIFICAATENDENTESELECIONADONOVOAVISO,
    payload: atendente
  };
};
const modificaAtendenteSelecionadoLocal = (dispatch, atendente) => {
  dispatch({
    type: MODIFICAATENDENTESELECIONADONOVOAVISO,
    payload: atendente
  });
};

export const modificaClienteSelecionado = cliente => {
  return {
    type: MODIFICAClIENTESELECIONADONOVOAVISO,
    payload: cliente
  };
};
const modificaClienteSelecionadoLocal = (dispatch, cliente) => {
  dispatch({
    type: MODIFICAClIENTESELECIONADONOVOAVISO,
    payload: cliente
  });
};
const AsyncStorage = {
  setItem: function (key, value) {
    return Promise.resolve().then(function () {
      localStorage.setItem(key, value);
    });
  },
  getItem: function (key) {
    return Promise.resolve().then(function () {
      return localStorage.getItem(key);
    });
  }
};


export const modificaDestinatarioSelecionadoNovoCadastro = dest => {
  return {
    type: MODIFICADESTINATARIOSELECIONADONOVOCADASTRO,
    payload: dest
  };
};
export const modificaAtendenteSelecionadoNovoCadastro = atendente => {
  return {
    type: MODIFICAATENDENTESELECIONADONOVOAVISONOVOCADASTRO,
    payload: atendente
  };
};

export const modificaClienteSelecionadoNovoCadastro = cliente => {
  return {
    type: MODIFICAClIENTESELECIONADONOVOAVISONOVOCADASTRO,
    payload: cliente
  };
};
export const alteraValorSwitchNovoAvisoNovoCadastro = valor => {
  return {
    type: ALTERARVALORSWITCHNOVOAVISONOVOCADASTRO,
    payload: valor
  };
};
export const modificaDataInicialNovoAvisoNovoCadastro = data => {
  let dataInicialFormatada = moment(data).format('DD/MM/YYYY HH:mm');
  /*  */
  return {
    type: MODIFICADATAINICIALNOVOAVISONOVOCADASTRO,
    payload: dataInicialFormatada
  };
};
export const modificaDataFinalNovoAvisoNovoCadastro = data => {

  let dataFinalFormatada = moment(data).format('DD/MM/YYYY HH:mm');
  return {
    type: MODIFICADATAFINALNOVOAVISONOVOCADASTRO,
    payload: dataFinalFormatada
  };
};
export const alteraDescricaoMensagemNovoAvisoNovoCadastro = descricao => {
  return {
    type: ALTERADESCRICAOMENSAGEMNOVOAVISONOVOCADASTRO,
    payload: descricao
  };
};

