import React from 'react';
import { connect } from 'react-redux';

import SelectSearchPaginate from "../SelectSearch/index";
import FlatList from 'flatlist-react';
import firebase from '../../servicos/FirebaseConnection';
import MoonLoader from "react-spinners/MoonLoader";
import FlatListChamadoTodos from "./FlatListChamadoTodos";
import ReactTooltip from "react-tooltip";
import SelectSearch from 'react-select-search';

import { desligaOuvintesChamadosFirebase } from "../../actions/DashBoardAction";
import { modificaPesquisaTeste } from '../../actions/HeaderAction';
import {
    zeraMensagemSemInternetChamadoAberto, navegaTelaNovoChamado,
    modificaFiltroChamado, modificaSelectTextChamado, modificaPesquisaTextChamado,
    ativaLoadingTelaAberturaChamadoRemoveFiltro, modificaFiltroSelecionadoTelaChamadoAberto, modificaFiltroSelecionadoTelaChamadoCancelado,
    modificaFiltroSelecionadoTelaChamadoEmAtendimento, modificaFiltroSelecionadoTelaChamadoFechado, modificaFiltroSelecionadoTelaChamadoTodos,
    listaTodosOsChamados, listaChamadosTodosComFiltroDigitadoEVencimento, listaChamadosTodosComFiltro,
    ativaDesativaLoadingFinalFlatListChamadoTodosExport,
} from "../../actions/ChamadoAction";

import {
    Title, Container, IconeSair, ContainerInputSearch, InputFiltroPorNumero, BotaoPesquisarChamadoPorNumero,
    InputSearch, DivInputSearch, IconeSetaPraBaixo,
    TextProblemaLoading, ContainerDadosChamado,
    ContaineDadosChamadosPrincipal, ContaineDadosChamadosSecundario,
    ContainerIconeEFrase,
    ContainerImagemLoading, ImageLoading, TextClienteLoading, ContainerChamadoLoading, ContainerListaVazia
} from './styled';
//this.myRef = null;
class ChamadoTodos extends React.Component {
    state = {
        filtroTeste: false,
        hasMoreItems: false,
        offset: 0,
        search: '',
        maisDados: [],
        loading: true,
        focusFiltro: false,
        clientSelected: null,
        referenciaUltimaKeys: []

    }
    /*    constructor(props) {
           super(props)
           this.myRef = React.createRef()
       } */


    componentWillUnmount() {
        this.props.desligaOuvintesChamadosFirebase('atendimentoTodos', this.props.verificaAtendenteVeSomenteProprioAtendimento, this.props.verificaAgrupaAtendenteSetor, this.props.isAdmin,false,this.state.referenciaUltimaKeys);
        /*   const chaveUnica2 = JSON.parse(localStorage.getItem('chaveUnica'));
          if (chaveUnica2) {
              const chaveUnica = chaveUnica2.replace(/"/g, "");
              firebase.database().ref(`${chaveUnica}/chamados`).off("value");
          } else {

          } */

    }
    componentDidMount() {
        if (this.props.filtroSelecionadoTodos !== '') {
            //alert("ativa filtro")
        } else {
            this.props.listaTodosOsChamados(0, '', 'willmount', [], '', this.props.verificaAtendenteVeSomenteProprioAtendimento, this.props.isAdmin);
        }
        ReactTooltip.rebuild();

    }

    componentWillReceiveProps(nextProps) {


        if (this.props.filtroChamado !== nextProps.filtroChamado && nextProps.filtroChamado !== '') {

            setTimeout(() => {
                this.myRef.scrollTop = 0;
            }, 200)
        }
    }
    updateSearch = search => {
        let state = this.state;
        state.search = search;
        state.clientSelected = null;
        this.setState({ state });

        // this.searchText(search)
    };
    removeFiltrosAll() {
        //this.props.ativaLoadingTelaAberturaChamadoRemoveFiltro(true)
        setTimeout(() => {
            this.props.listaTodosOsChamados(0, '', [], 'willmount', this.props.verificaAtendenteVeSomenteProprioAtendimento, this.props.isAdmin);
            this.updateSearch('')
            this.props.modificaFiltroSelecionadoTelaChamadoAberto('');
            this.props.modificaFiltroSelecionadoTelaChamadoCancelado('');
            this.props.modificaFiltroSelecionadoTelaChamadoEmAtendimento('');
            this.props.modificaFiltroSelecionadoTelaChamadoFechado('');
            this.props.modificaFiltroSelecionadoTelaChamadoTodos('');
            this.props.modificaFiltroChamado('');
            this.props.modificaSelectTextChamado('');
            this.setState({ referenciaUltimaKeys: [] });
        }, 200);
    }
    pesquisaComDigito(digito) {
        this.props.listaChamadosTodosComFiltroDigitadoEVencimento(0, '', 'willmount', [], '', this.props.verificaAtendenteVeSomenteProprioAtendimento, this.props.isAdmin, this.props.filtroChamado, 'Aberto', digito);
        this.props.desligaOuvintesChamadosFirebase('atendimentoTodos', this.props.verificaAtendenteVeSomenteProprioAtendimento, this.props.verificaAgrupaAtendenteSetor, this.props.isAdmin,false,this.state.referenciaUltimaKeys);
    }
    filtrarChamado(key) {
        //
        this.props.desligaOuvintesChamadosFirebase('atendimentoTodos', this.props.verificaAtendenteVeSomenteProprioAtendimento, this.props.verificaAgrupaAtendenteSetor, this.props.isAdmin,false,this.state.referenciaUltimaKeys);
        this.props.modificaFiltroSelecionadoTelaChamadoTodos(key)
        if (this.props.filtroChamado == 'Vencimento') {
            this.props.listaChamadosTodosComFiltroDigitadoEVencimento(0, '', 'willmount', [], '', this.props.verificaAtendenteVeSomenteProprioAtendimento, this.props.isAdmin, this.props.filtroChamado, 'Todos', key)
        } else {
            this.props.listaChamadosTodosComFiltro(0, '', 'willmount', [], '', this.props.verificaAtendenteVeSomenteProprioAtendimento, this.props.isAdmin, this.props.filtroChamado, 'Todos', key);
        }

    };
    exibeUltimosChamadosAbertos = () => {
        //
        if (this.props.chamadoTodos.length > 1 && this.props.loadingTelaTodos == false) {
            if (this.props.filtroChamado !== 'Número Chamado' && this.props.filtroChamado !== 'Problema' && this.props.filtroChamado !== 'Vencimento' && this.props.filtroSelecionadoTodos.length > 0) {
                //
                this.props.listaChamadosTodosComFiltro(1, this.props.referenciaUltimaKeyTodos, '', this.props.chamadoTodos, this.props.keyTodos, this.props.verificaAtendenteVeSomenteProprioAtendimento, this.props.isAdmin, this.props.filtroChamado, 'Todos', this.props.filtroSelecionadoTodos);
            } else if (this.props.filtroChamado == 'Vencimento') {
                //
                /*   let ultimosDados = _.last(_.values(this.props.chamadoTodos));

                  if (ultimosDados.key !== this.props.referenciaUltimaKey) { */
                // this.props.listaChamadosTodosComFiltroDigitadoEVencimento(1, this.props.referenciaUltimaKeyTodos, '',this.props.chamadoTodos, this.props.keyTodos, this.props.verificaAtendenteVeSomenteProprioAtendimento, this.props.contaPrincipal, this.props.filtroChamado, 'Todos', this.props.filtroSelecionadoTodos[0]);
                /* } */

            }
            else if (this.props.filtroChamado == 'Número Chamado') {
                //  this.props.listaChamadosTodosComFiltroDigitadoEVencimento(1, this.props.referenciaUltimaKeyTodos, '', this.props.chamadoTodos, this.props.keyTodos,this.props.verificaAtendenteVeSomenteProprioAtendimento, this.props.contaPrincipal, this.props.filtroChamado, 'Todos', this.state.search);
            } else {
                //
                this.props.listaTodosOsChamados(1, this.props.referenciaUltimaKeyTodos, '', this.props.chamadoTodos, this.props.keyTodos, this.props.verificaAtendenteVeSomenteProprioAtendimento, this.props.isAdmin)
                if (!!this.props.keyTodos) {
                    const hasIncludeKey = this.state.referenciaUltimaKeys.filter(entry => entry === this.props.keyTodos).length > 0;
                    if (!hasIncludeKey) {
                        const newKeys = [...this.state.referenciaUltimaKeys, this.props.keyTodos];
                        this.setState({ referenciaUltimaKeys: newKeys });
                    }

                }
            }
        } else {
            this.props.ativaDesativaLoadingFinalFlatListChamadoTodosExport(false)
        }
        /* if (this.props.indicaFimDaListaTodos == false && this.props.loadingTelaTodos == false) {
          if (this.props.clienteSelecionadoTelaChamadoTodos != '') {
            this.props.listaChamadosComCliente(1, this.props.chamadoTodos, this.props.keyClienteChamadoTodos, this.props.clienteSelecionadoTelaChamadoTodos[0])
          } else {
            this.props.listaTodosOsChamados(1, this.props.referenciaUltimaKeyTodos, '', this.props.chamadoTodos,
              this.props.keyTodos, this.props.verificaAtendenteVeSomenteProprioAtendimento, this.props.contaPrincipal)
          }
        } */
    }

    render() {

        if (this.props.loadingTelaTodos) {
            return (
                <div style={{ flex: 1, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <ContainerChamadoLoading type="button" backgroundinamico={this.props.backgroundMaisEscuro}>
                        <ContainerImagemLoading>
                            <ImageLoading backgroundinamico={this.props.backgroundMaisEscuro}></ImageLoading>
                        </ContainerImagemLoading>
                        <ContainerDadosChamado>
                            <ContaineDadosChamadosPrincipal>
                                <ContainerIconeEFrase>
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>


                                <ContainerIconeEFrase >
                                    {/*   <i className="icon-comment2" style={{ color: this.props.coresLetraPadrao, fontSize: 11 }} /> */}
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>
                                <ContainerIconeEFrase >
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>

                            </ContaineDadosChamadosPrincipal>
                            <ContaineDadosChamadosSecundario>
                                <ContainerIconeEFrase >

                                    <TextClienteLoading style={{ marginLeft: 4 }} backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>

                                <ContainerIconeEFrase >
                                    <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>
                                <ContainerIconeEFrase >
                                    <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>
                            </ContaineDadosChamadosSecundario>
                        </ContainerDadosChamado>
                    </ContainerChamadoLoading>
                    <ContainerChamadoLoading type="button" backgroundinamico={this.props.backgroundMaisEscuro}>
                        <ContainerImagemLoading>
                            <ImageLoading backgroundinamico={this.props.backgroundMaisEscuro}></ImageLoading>
                        </ContainerImagemLoading>
                        <ContainerDadosChamado>
                            <ContaineDadosChamadosPrincipal>
                                <ContainerIconeEFrase>
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>


                                <ContainerIconeEFrase >
                                    {/*   <i className="icon-comment2" style={{ color: this.props.coresLetraPadrao, fontSize: 11 }} /> */}
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>
                                <ContainerIconeEFrase >
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>

                            </ContaineDadosChamadosPrincipal>
                            <ContaineDadosChamadosSecundario>
                                <ContainerIconeEFrase >

                                    <TextClienteLoading style={{ marginLeft: 4 }} backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>

                                <ContainerIconeEFrase >
                                    <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>
                                <ContainerIconeEFrase >
                                    <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>
                            </ContaineDadosChamadosSecundario>
                        </ContainerDadosChamado>
                    </ContainerChamadoLoading>
                    <ContainerChamadoLoading type="button" backgroundinamico={this.props.backgroundMaisEscuro}>
                        <ContainerImagemLoading>
                            <ImageLoading backgroundinamico={this.props.backgroundMaisEscuro}></ImageLoading>
                        </ContainerImagemLoading>
                        <ContainerDadosChamado>
                            <ContaineDadosChamadosPrincipal>
                                <ContainerIconeEFrase>
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>


                                <ContainerIconeEFrase >
                                    {/*   <i className="icon-comment2" style={{ color: this.props.coresLetraPadrao, fontSize: 11 }} /> */}
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>
                                <ContainerIconeEFrase >
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>

                            </ContaineDadosChamadosPrincipal>
                            <ContaineDadosChamadosSecundario>
                                <ContainerIconeEFrase >

                                    <TextClienteLoading style={{ marginLeft: 4 }} backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>

                                <ContainerIconeEFrase >
                                    <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>
                                <ContainerIconeEFrase >
                                    <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>
                            </ContaineDadosChamadosSecundario>
                        </ContainerDadosChamado>
                    </ContainerChamadoLoading>
                    <ContainerChamadoLoading type="button" backgroundinamico={this.props.backgroundMaisEscuro}>
                        <ContainerImagemLoading>
                            <ImageLoading backgroundinamico={this.props.backgroundMaisEscuro}></ImageLoading>
                        </ContainerImagemLoading>
                        <ContainerDadosChamado>
                            <ContaineDadosChamadosPrincipal>
                                <ContainerIconeEFrase>
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>


                                <ContainerIconeEFrase >
                                    {/*   <i className="icon-comment2" style={{ color: this.props.coresLetraPadrao, fontSize: 11 }} /> */}
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>
                                <ContainerIconeEFrase >
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>

                            </ContaineDadosChamadosPrincipal>
                            <ContaineDadosChamadosSecundario>
                                <ContainerIconeEFrase >

                                    <TextClienteLoading style={{ marginLeft: 4 }} backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>

                                <ContainerIconeEFrase >
                                    <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>
                                <ContainerIconeEFrase >
                                    <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>
                            </ContaineDadosChamadosSecundario>
                        </ContainerDadosChamado>
                    </ContainerChamadoLoading>
                    <ContainerChamadoLoading type="button" backgroundinamico={this.props.backgroundMaisEscuro}>
                        <ContainerImagemLoading>
                            <ImageLoading backgroundinamico={this.props.backgroundMaisEscuro}></ImageLoading>
                        </ContainerImagemLoading>
                        <ContainerDadosChamado>
                            <ContaineDadosChamadosPrincipal>
                                <ContainerIconeEFrase>
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>


                                <ContainerIconeEFrase >
                                    {/*   <i className="icon-comment2" style={{ color: this.props.coresLetraPadrao, fontSize: 11 }} /> */}
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>
                                <ContainerIconeEFrase >
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>

                            </ContaineDadosChamadosPrincipal>
                            <ContaineDadosChamadosSecundario>
                                <ContainerIconeEFrase >

                                    <TextClienteLoading style={{ marginLeft: 4 }} backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>

                                <ContainerIconeEFrase >
                                    <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>
                                <ContainerIconeEFrase >
                                    <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>
                            </ContaineDadosChamadosSecundario>
                        </ContainerDadosChamado>
                    </ContainerChamadoLoading>
                    <ContainerChamadoLoading type="button" backgroundinamico={this.props.backgroundMaisEscuro}>
                        <ContainerImagemLoading>
                            <ImageLoading backgroundinamico={this.props.backgroundMaisEscuro}></ImageLoading>
                        </ContainerImagemLoading>
                        <ContainerDadosChamado>
                            <ContaineDadosChamadosPrincipal>
                                <ContainerIconeEFrase>
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>


                                <ContainerIconeEFrase >
                                    {/*   <i className="icon-comment2" style={{ color: this.props.coresLetraPadrao, fontSize: 11 }} /> */}
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>
                                <ContainerIconeEFrase >
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>

                            </ContaineDadosChamadosPrincipal>
                            <ContaineDadosChamadosSecundario>
                                <ContainerIconeEFrase >

                                    <TextClienteLoading style={{ marginLeft: 4 }} backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>

                                <ContainerIconeEFrase >
                                    <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>
                                <ContainerIconeEFrase >
                                    <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>
                            </ContaineDadosChamadosSecundario>
                        </ContainerDadosChamado>
                    </ContainerChamadoLoading>
                </div>
            )
        } else {
            return (
                <Container ref={ref => { this.myRef = ref }} >
                    {this.props.filtroChamado !== '' && this.props.filtroChamado == 'Número Chamado' &&
                        <div style={{ justifyContent: 'center', alignItems: 'center' }}>
                            <p style={{ marginTop: 15, marginLeft: 40, color: this.props.coresLetraPadrao }}>{this.props.filtroChamado}:</p>
                            <ContainerInputSearch backGroundDinamico={this.props.backgroundMaisEscuro}>
                                <IconeSair className="icon-close" onClick={() => { this.removeFiltrosAll(); }} />
                                <div className="App" style={{ borderRadius: 3, height: 30 }}>
                                    <InputFiltroPorNumero
                                        type="number"
                                        placeholder={this.props.selectTextChamado}
                                        onChange={(value) => this.updateSearch(value.target.value)}
                                        corLetraDinamico={this.props.coresLetraPadrao}
                                        backgroundDinamico={this.props.backgroundMaisEscuro}
                                        placeholderColor={this.props.corLetraSecundaria}
                                        corBordaDinamica={this.props.corBotaoPadrao}
                                        value={this.state.search}
                                        autoFocus
                                    />
                                    {this.state.search !== '' && <BotaoPesquisarChamadoPorNumero onClick={() => { this.pesquisaComDigito(this.state.search) }} backGroundDinamico={this.props.corBotaoPadrao} type="button">
                                        <i className="icon-magnifier" style={{ color: this.props.letraBotaoPadrao, fontSize: 13 }} />
                                    </BotaoPesquisarChamadoPorNumero>}
                                </div>
                            </ContainerInputSearch>
                        </div>}
                    {this.props.filtroChamado !== '' && this.props.filtroChamado !== 'Número Chamado' &&
                        <div style={{ justifyContent: 'center', alignItems: 'center' }}>
                            <p style={{ marginTop: 15, marginLeft: 40, color: this.props.coresLetraPadrao }}>{this.props.filtroChamado}:</p>
                            <ContainerInputSearch backGroundDinamico={this.props.backgroundMaisEscuro}>
                                <IconeSair className="icon-close" onClick={() => { this.removeFiltrosAll(); }} />
                                <div style={{ background: this.props.corSegundoBotaoPadrao, borderRadius: 3, height: 30 }}>

                                    {((this.props.filtroChamado !== "Cliente") ||
                                        (this.props.filtroChamado === "Cliente" && this.props.clientesSectionedConsulta.length > 0))
                                        && <SelectSearch
                                        renderValue={(valueProps) =>
                                            <DivInputSearch>
                                                <InputSearch
                                                    corLetraDinamico={this.props.coresLetraPadrao}
                                                    backgroundDinamico={this.props.backgroundMaisEscuro}
                                                    placeholderColor={this.props.corLetraSecundaria}
                                                    corBordaDinamica={this.props.corBotaoPadrao}
                                                    {...valueProps} ></InputSearch>
                                                <IconeSetaPraBaixo className="icon-angle-arrow-down" colorDinamico={this.props.coresLetraPadrao} />
                                            </DivInputSearch>
                                        }
                                        value={this.props.filtroSelecionadoTodos}

                                        onChange={(value) => this.filtrarChamado(value)}
                                        options={this.props.chamadosComFiltro}
                                        placeholder={this.props.chamadosComFiltro.length == 0 ? `Nenhum ${this.props.filtroChamado} cadastrado, Por favor verifique!` : this.props.selectTextChamado}
                                        search={this.props.chamadosComFiltro.length == 0 ? false : true}
                                        autoFocus />}
                                        {this.props.filtroChamado === "Cliente" && this.props.clientesSectionedConsulta.length === 0 && <div style={{ display: 'flex', flex: 1 }}>
                                        <SelectSearchPaginate
                                            onSelectClient={(client) => {

                                                this.setState({ clientSelected: { value: client.value, label: client.label } });
                                                this.filtrarChamado(client.idUser)

                                            }}
                                            value={this.state.clientSelected}
                                            isMyContact={false}
                                            background={this.props.backgroundMaisEscuro}
                                            letterColor={this.props.coresLetraPadrao}
                                            backgroundDinamico={this.props.backgroundMaisEscuro}
                                            placeholderColor={this.props.coresLetraSecundario}
                                            corBordaDinamica={this.props.corBotaoPadrao}
                                            width={400}
                                            letraBotaoPadrao={this.props.letraBotaoPadrao}
                                        />
                                    </div>}
                                </div>
                            </ContainerInputSearch>
                        </div>}
                    <FlatList
                        list={this.props.chamadoTodos}
                        renderItem={(item) => <FlatListChamadoTodos data={item} key={item.key + item.alterado} />}
                        renderWhenEmpty={() => {
                            if (this.props.filtroSelecionadoTodos !== '' || this.state.search !== '') {
                                return (
                                    <ContainerListaVazia>
                                        <i className="icon-box" style={{ color: this.props.coresLetraPadrao, fontSize: 110 }} />
                                        <p style={{ color: this.props.coresLetraPadrao, fontSize: 20 }}>Nenhum registro encontrado</p>
                                    </ContainerListaVazia>
                                )
                            } else {
                                return (
                                    <ContainerListaVazia >
                                        <i className="icon-box" style={{ color: this.props.coresLetraPadrao, fontSize: 110 }} />
                                        <p style={{ color: this.props.coresLetraPadrao, fontSize: 20 }}>Nenhum Chamado Encontrado</p>
                                    </ContainerListaVazia>
                                )
                            }
                        }}
                        hasMoreItems={this.props.loadingFimDaPaginaTodos}
                        loadMoreItems={() => this.exibeUltimosChamadosAbertos()}
                        paginationLoadingIndicator={<MoonLoader
                            css={{ margin: 10 }}
                            size={40}
                            color={this.props.corBotaoPadrao}
                            loading={this.props.loadingFimDaPaginaTodos}
                        />}


                    //sortBy={["firstName", { key: "lastName", descending: true }]}
                    // groupBy={person => person.info.age > 18 ? 'Over 18' : 'Under 18'}
                    />
                </Container>
            )
        }
    }
}

const mapStateToProps = state => {
    return (
        {
            backgroundPrimeiro: state.CoresPadraoSistema.backgroundPrimeiro,
            backgroundSegundo: state.CoresPadraoSistema.backgroundSegundo,
            backgroundMaisEscuro: state.CoresPadraoSistema.backgroundMaisEscuro,
            coresLetraPadrao: state.CoresPadraoSistema.coresLetraPadrao,
            corBotaoPadrao: state.CoresPadraoSistema.corBotaoPadrao,
            corSegundoBotaoPadrao: state.CoresPadraoSistema.corSegundoBotaoPadrao,
            letraBotaoPadrao: state.CoresPadraoSistema.letraBotaoPadrao,
            nomeRota: state.HeaderReducer.nomeRota,
            testePesquisa: state.HeaderReducer.testePesquisa,
            email: state.LoginReducer.email,
            coresLetraSecundario: state.CoresPadraoSistema.coresLetraSecundario,
            chamadoTodos: state.ChamadoReducer.chamadoTodos,
            verificaConexaoInternetListaChamadoTodos: state.ChamadoReducer.verificaConexaoInternetListaChamadoTodos,
            loadingTelaTodos: state.ChamadoReducer.loadingTelaTodos,
            refreshControlFlatListChamadoTodos: state.ChamadoReducer.refreshControlFlatListChamadoTodos,
            referenciaUltimaKeyTodos: state.ChamadoReducer.referenciaUltimaKeyTodos,
            loadingFimDaPaginaTodos: state.ChamadoReducer.loadingFimDaPaginaTodos,
            keyTodos: state.ChamadoReducer.keyTodos,
            indicaFimDaListaTodos: state.ChamadoReducer.indicaFimDaListaTodos,
            loadingClienteTelaChamadoTodos: state.ChamadoReducer.loadingClienteTelaChamadoTodos,
            clientesTelaChamadoTOdos: state.ChamadoReducer.clientesSectionedConsulta,
            clienteSelecionadoTelaChamadoTodos: state.ChamadoReducer.clienteSelecionadoTelaChamadoTodos,
            nomeClienteSelecionadoTelaChamadoTodos: state.ChamadoReducer.nomeClienteSelecionadoTelaChamadoTodos,
            keyClienteChamadoTodos: state.ChamadoReducer.keyClienteChamadoTodos,
            premium: state.PremiumReducer.premium,
            verificaAgrupaAtendenteSetor: state.ChamadoReducer.verificaAgrupaAtendenteSetor,
            verificaAtendenteVeSomenteProprioAtendimento: state.ChamadoReducer.verificaAtendenteVeSomenteProprioAtendimento,
            contaPrincipal: state.AtendenteReducer.contaPrincipal,
            filtroChamado: state.ChamadoReducer.filtroChamado,
            chamadosComFiltro: state.ChamadoReducer.chamadosComFiltro,
            filtroSelecionadoTodos: state.ChamadoReducer.filtroSelecionadoTodos,
            selectTextChamado: state.ChamadoReducer.selectTextChamado,
            pesquisaTextChamado: state.ChamadoReducer.pesquisaTextChamado,
            licensawebouapp: state.PremiumReducer.licensawebouapp,
            isAdmin: state.PerfilReducer.isAdmin,
            usuarioAdmVisualizaApenasChamadosDoSetorVinculado: state.PerfilReducer.usuarioAdmVisualizaApenasChamadosDoSetorVinculado,
            clientesSectionedConsulta: state.ChamadoReducer.clientesSectionedConsulta,
        }
    );
};
export default connect(mapStateToProps, {
    modificaPesquisaTeste, zeraMensagemSemInternetChamadoAberto,
    navegaTelaNovoChamado, modificaFiltroChamado, modificaSelectTextChamado, modificaPesquisaTextChamado,
    ativaLoadingTelaAberturaChamadoRemoveFiltro, modificaFiltroSelecionadoTelaChamadoAberto, modificaFiltroSelecionadoTelaChamadoCancelado,
    modificaFiltroSelecionadoTelaChamadoEmAtendimento, modificaFiltroSelecionadoTelaChamadoFechado, modificaFiltroSelecionadoTelaChamadoTodos,
    listaTodosOsChamados, listaChamadosTodosComFiltroDigitadoEVencimento, listaChamadosTodosComFiltro, ativaDesativaLoadingFinalFlatListChamadoTodosExport,
    desligaOuvintesChamadosFirebase
})(ChamadoTodos);
