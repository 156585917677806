import styled, { keyframes } from 'styled-components';
import { shade, opacify, lighten } from 'polished';

const animacaoLoading = keyframes`
   0%{
        background-position: 0% 0%;
     }
     100%{
        background-position: -135% 0%;
     }
  }
`;
export const Title = styled.h1`
   font-size: 35px;
`;
export const Container = styled.div`
   height:calc(100vh - 65px);
   background:${props => props.backgroundDinamico};
   display:flex;
   position:relative;
   align-items:center;
   width:100%;
   flex-direction:column;
`;
export const ContainerBox = styled.div`
 display:flex;
   flex-direction:row;
   height:110px;
   min-height:110px;
   max-height:110px;
   justify-content:space-around;
   align-items:center;
`;
export const Box = styled.div`
 display:flex;
   flex-direction:column;
   background:${props => props.backgroundDinamico};
   margin:10px;
   max-height:85px;
   height:85px;
   min-height:85px;
   width:150px;
   max-width:150px;
   justify-content:center;
   align-items:flex-start;
   padding-left:20px;
   border-radius:5px;
   -webkit-box-shadow: 0px 0px 2px 1px #000;
  -moz-box-shadow: 0px 0px 2px 1px #000;
  box-shadow: 0px 0px 2px 1px #000;
`;
export const TextStatus = styled.span`
   color:${props => props.colorDinamico};
   font-size:19px;
`;
export const TextResultado = styled.strong`
  color:${props => props.colorDinamico};
   font-size:22px;
`;

export const ContainerGrafico = styled.div`
 display:flex;
 flex-direction:row;
 justify-content:space-around;
 align-items:center;
 min-height:350px;
`;
export const SubContainerGrafico = styled.div`
 display:flex;
 flex-direction:column;
 justify-content:center;
 background-color:${props => props.backgroundDinamico};
 align-items:center;
 height:300px;
 min-height:300px;
 width:45%;
 margin:10px;
 max-width:45%;
 -webkit-box-shadow: 0px 0px 4px 1px #000;
  -moz-box-shadow: 0px 0px 4px 1px #000;
  box-shadow: 0px 0px 4px 1px #000;
  border-radius:7px;
`;
export const ContainerAviso = styled.div`
  flex-direction:row;
 padding:10px;
  justify-content:flex-start;
  padding-left:10px;
  padding-right:10px;
  align-items:center;
  display:flex;
  background-color:#F9BF3B;
  margin:5px;
  border-radius:2px;
`;
export const TextAviso = styled.strong`
   color: #000;
   font-size:14px;
`;


export const TextGrafico = styled.strong`
   font-size:12px;
   color:${props => props.colorDinamico};
`;

export const ContainerTextGrafico = styled.div`
display:flex;
height:35px;
min-height:35px;
justify-content:space-between;
align-items:center;
margin-top:25px;
width:100%;
`;
export const ContainerTextTituloTempoMedio = styled.div`
display:flex;
height:35px;
min-height:35px;
justify-content:space-between;
align-items:center;
width:100%;
`;
export const ContainerIconesExport = styled.div`
display:flex;
height:30px;
min-height:30px;
justify-content:flex-end;
align-items:center;
width:80px;
`;
export const ContainerIconesExportTempoMedio = styled.div`
display:flex;
height:30px;
min-height:30px;
justify-content:space-between;
align-items:center;
`;

export const SubContainerListaDeTarefas = styled.div`
 display:flex;
 flex-direction:column;
 justify-content:flex-start;
 background-color:${props => props.backgroundDinamico};
 align-items:flex-start;
 padding:15px;
 height:300px;
 min-height:300px;
 width:45%;
 margin:10px;
 max-width:45%;
 -webkit-box-shadow: 0px 0px 4px 1px #000;
  -moz-box-shadow: 0px 0px 4px 1px #000;
  box-shadow: 0px 0px 4px 1px #000;
  border-radius:7px;
`;
export const TextTarefas = styled.strong`
   font-size:15px;
   color:${props => props.colorDinamico};
`;
export const SubContainerInputEmail = styled.div`
height:45px;
min-height:45px;
margin: 0px 10px;
margin-top:20px;
padding: 0 10px;
border-bottom: 1px solid #fff;
border-color: ${props => props.corBordaInputEmail};
display:flex;
width:85%;
align-items:center;
`;
export const InputEmail = styled.input`
display:flex;
background-Color:transparent;
flex:1;
height:40px;
min-height:40px;
border:0;
color:#FFF;
&::placeholder{
   color:#a8a8B3
}
`;
export const DivContainerSelectDate = styled.div`
  flex-direction:row;
  justify-content:space-between;
  padding-left:10px;
  padding-bottom:10px;
  padding-right:10px;
  align-items:center;
  min-height:100px;
  display:flex;
  margin-left:20px;
  margin-right:20px;
  border-radius:2px;
`;


export const BotaoPesquisarChamadoPorNumero = styled.button`
display:flex;
justify-content:center;
align-items:center;
background:${props => props.backGroundDinamico};
width:40px;
border:0;
transition: background-color 0.2s;
height:40px;
min-height:40px;
margin-left:2px;
border-radius: 3px;
&:hover{
   background:${props => shade(0.2, props.backGroundDinamico)};
}
`;
export const BotaoAtualizaStatusConexaoWpp = styled.button`
display:flex;
justify-content:center;
align-items:center;
background:${props => props.backGroundDinamico};
width:30px;
border:0;
transition: background-color 0.2s;
height:30px;
min-height:30px;
margin-left:15px;
margin-left:2px;
border-radius: 3px;
&:hover{
   background:${props => shade(0.2, props.backGroundDinamico)};
}
`;



export const ContainerGraficoTempoMedio = styled.div`
 display:flex;
 flex-direction:row;
 justify-content:space-around;
 align-items:center;
 min-height:150px;
 
`;
export const SubContainerGraficoTempoMedio = styled.div`
 display:flex;
 flex-direction:column;
 background-color:${props => props.backgroundDinamico};
 height:150px;
 min-height:150px;
 width:45%;
 margin:10px;
 margin-bottom:25px;
 max-width:45%;
 -webkit-box-shadow: 0px 0px 4px 1px #000;
  -moz-box-shadow: 0px 0px 4px 1px #000;
  box-shadow: 0px 0px 4px 1px #000;
  border-radius:7px;
`;
export const SubContainerGraficoTempoMedioSemSombra = styled.div`
 display:flex;
 flex-direction:column;
 justify-content:center;
 background-color:${props => props.backgroundDinamico};
 align-items:center;
 height:150px;
 min-height:150px;
 width:45%;
 margin:10px;
 max-width:45%;
  border-radius:7px;
`;
export const ContainerTextTempoMedio = styled.div`
 display:flex;
 flex:1;
 justify-content:center;
 align-items:center;
`;

export const TextTempoMedio = styled.p`
font-size:25px;
font-weight:bold;
color:${props => props.corLetraDinamica};
`;
export const IconeExport = styled.i`
font-size:15px;
color:${props => props.colorDinamico};;
cursor: pointer;
&:hover{
  color: ${props => shade(0.2, props.colorDinamico)};
}
`;
export const DivTextPeriodo = styled.div`
 display:flex;
 flex-direction:row;
 margin-top:15px;
`;
export const TextPeriodo = styled.p`
font-size:14px;
font-weight:bold;
color:${props => props.corLetraDinamica};
`;

export const ContainerInputSearch = styled.div`
display:flex;
flex-direction:row;
 justify-content:center;
 align-items:center;
 margin-bottom:10px;
margin-right:2px;
`;
export const IconeSair = styled.i`
font-size:17px;
margin-right:5px;
color:red;
cursor: pointer;
flex:1;
`;
export const DivPeriodoBotao = styled.div`
display:flex;
flex-direction:row;
`;
export const ContainerExplicacaoVincular = styled.div`
 display:flex;
 flex:1;
 padding:5px;
  flex-direction:column;
  align-items:flex-start;
  justify-content:center;
`;
export const TextVincular = styled.p`
font-size:17px;
text-align:left;
color:${props => props.corLetraDinamica};
`;
export const ContainerModal = styled.div`
 display:flex;
   overflow:auto;
 flex:1;
   width:350px;
   height:350px;
   min-height:350px;
   flex-direction:column;
   border-radius:7px;
   background:${props => props.backgroundDinamico};
`;
export const ContainerIconeFecharModal = styled.div`
padding-top:5px;
padding-bottom:5px;
justify-content:space-between;
align-items:center;
display:flex;
border-radius: 7px 7px 0 0;
width:100%;
background:${props => props.backgroundDinamico};
`;
export const TextNovoAtendimento = styled.strong`
font-size:17px;
color:${props => props.corLetraDinamico};
margin-left:12px;
letter-spacing: 1.5px;
`;
export const IconeFechar = styled.i`
font-size:15px;
margin-right:5px;
color:${props => props.backgroundDinamico};
cursor: pointer;
`;

export const InputSearch = styled.input`
display:flex;
flex:1;
height:30px;
min-height:30px;
width:400px;
border-radius:5px;
padding-left:7px;
font-size:14px;
border:0;
color:${props => props.corLetraDinamico};
background-color: ${props => props.backgroundDinamico};

border:${props => `outset 0.5px ${props.corLetraDinamico}`};
cursor: pointer;
&::placeholder{
   color:${props => props.placeholderColor};
   font-style: italic;
}
&:hover{
  border:${props => `solid 0.5px ${props.corBordaDinamica}`};
 }

`;

export const DivInputSearch = styled.div`
 display:flex;
  flex-direction:row;
  align-items:center;

`;
export const IconeSetaPraBaixo = styled.i`
font-size: 12px;
position: absolute;
top:8px;
right: 19px;
width: 11px;
height: 11px;
min-height: 11px;
color:${props => props.colorDinamico};
pointer-events: none;
`;

export const TextBoxLoading = styled.div`
 height:25px;
 min-height:25px;
 width:130px;
 background:${props => `linear-gradient(to right, ${props.backgroundinamico} 10%, ${lighten(0.2, props.backgroundinamico)} 28%, ${props.backgroundinamico} 33%)`};
animation:${animacaoLoading} 1.2s linear infinite;
  background-size: 400% 400%; 
  border-radius:5px;
`;

export const TextGraficoLoading = styled.div`
 height:25px;
 min-height:25px;
 flex:1;
 width:100%;
 border-radius:5px;
 background:${props => `linear-gradient(to right, ${props.backgroundinamico} 10%, ${lighten(0.2, props.backgroundinamico)} 28%, ${props.backgroundinamico} 33%)`};
animation:${animacaoLoading} 1.2s linear infinite;
  background-size: 400% 400%; 
`;
export const ContainerTextGraficoLoading = styled.div`
 height:25px;
 min-height:25px;
 width:90%;
margin-top:15px;
`;
export const ContainerGraficoLoading = styled.div`
 flex:1;
 height: 100%;
 width: 100%;
display:flex;
justify-content:center;
align-items:center;
`;
export const GraficoLoading = styled.div`
 height: 80%;
 width: 50%;
display:flex;
border-radius:50%;
background:${props => `linear-gradient(to right, ${props.backgroundinamico} 10%, ${lighten(0.2, props.backgroundinamico)} 28%, ${props.backgroundinamico} 33%)`};
animation:${animacaoLoading} 1.2s linear infinite;
  background-size: 400% 400%; 
`;
export const TextGraficoLoadingTempoMedio = styled.div`
 height:25px;
 min-height:25px;
 flex:1;
 width:100%;
 margin-left:20px;
 margin-top:10px;
 margin-right:20px;
 border-radius:5px;
 background:${props => `linear-gradient(to right, ${props.backgroundinamico} 10%, ${lighten(0.2, props.backgroundinamico)} 28%, ${props.backgroundinamico} 33%)`};
animation:${animacaoLoading} 1.2s linear infinite;
  background-size: 400% 400%; 
`;
export const ContainerModalPrioridadeAtendimento = styled.div`
 display:flex;
   width:550px;
  min-height:260px;
  overflow:auto;
   flex-direction:column;
   border-radius:7px;
   background:${props => props.backgroundDinamico};
`;
export const ContainerModalAddVersaoPremium = styled.div`
 display:flex;
   width:550px;
  min-height:550px;
  overflow:auto;
   flex-direction:column;
   border-radius:7px;
   background:${props => props.backgroundDinamico};
`;
export const ContainerAtendentes = styled.div`
display:flex;
  flex:1;
  align-items:center;
  flex-direction:column;
`;
export const ContainerInputEBotaoComent = styled.div`
 display:flex;
justify-content:center;
flex:1;
margin-top:40px;
flex-direction:column;
align-items:center;
`;
export const ContainerEmailPrinc = styled.div`
 display:flex;
justify-content:center;
margin-top:40px;
flex-direction:row;
align-items:flex-end;
`;

export const DivInputComentarChamado = styled.div`
 display:flex;
justify-content:center;
align-items:flex-start;
flex-direction:column;
`;
export const DivInputComentarChamadoConfig = styled.div`
 display:flex;
justify-content:flex-start;
align-items:flex-start;
flex-direction:row;
margin-top:10px;
margin-bottom:10px;
`;
export const InputDescricaoChamado = styled.textarea`
display:flex;
background-Color:transparent;
height:70px;
min-height:70px;
border:0;
padding:5px;
width:450px;
border-radius:4px;
border: 1px solid ${props => props.corLetraSecundaria};
color:${props => props.corLetraDinamico};
&::placeholder{
   color:#a8a8B3;
   
}
&:focus {
  border:1px solid ${props => props.corBordaDinamica};
  }
`;
export const DivBotaoSalvarComentario = styled.div`
 display:flex;
 flex:1;
 width:550px;
justify-content:flex-end;
align-items:flex-end;
`;
export const ButtonSalvarComentario = styled.button`
display:flex;
flex-direction:row;
border:0;
margin:10px;
justify-content:space-around;
height:40px;
min-height:40px;
width:100px;
align-items:center;
border-radius:5px;
transition: background-color 0.2s;
background:${props => props.backgroundBotao};
`;
export const TextBotaoSalvarComentar = styled.span`
  color:${props => props.backgroundBotao};
  font-size:14px;
 font-weight:bold;
 flex:1;
 position: relative;
 margin-right:7px;
 text-align:center;
 align-items:center;
 justify-content:center;
`;

export const InputEmailAcc = styled.input`
display:flex;
background-Color:#FFF;
height:40px;
min-height:40px;
border:0;
padding:5px;
width:600px;
border-radius:4px;
border: 1px solid ${props => props.corLetraSecundaria};
color:${props => props.corLetraDinamico};
&::placeholder{
   color:#000;
   
}
&:focus {
  border:1px solid ${props => props.corBordaDinamica};
  }
`;

export const ContainerCfg = styled.div`
 display:block;
 flex:1;
   flex-direction:column;
   background:${props => props.backgroundDinamico};
   margin:10px;
   border-radius:10px;
   margin-top:40px;
`;
export const DivCabecalhoCfg = styled.div`
 display:flex;
   width:900px;
   flex-direction:column;
   justify-content:center;
   height:50px;
   min-height:50px;
   margin:10px;
   padding:16px;
border-bottom:${props => `1px solid ${props.bordaDinamica}`};
`;
export const TextCabecalho = styled.h4`
color:${props => props.corLetraDinamica};
`;
export const DivConfiguracoes = styled.div`
 display:flex;
   width:900px;
   flex-direction:column;
   justify-content:flex-start;
   padding:0px 0px 16px 16px;
`;
export const TextMensagem = styled.p`
font-size:15px;
font-weight:bold;
color:${props => props.corLetraDinamica};
padding-left:5px;
margin-top:10px;
`;
export const TextMensagemResposta = styled.p`
font-size:14px;
color:${props => props.corLetraDinamica};
padding-left:5px;
margin-top:10px;
font-weight:bold;
`;

export const ButtonVersaoPremium = styled.button`
display:flex;
justify-content:center;
align-items:center;

background-color:${props => props.backGroundDinamico};
color:${props => props.corLetraBotao};
letter-spacing: 1px;
font-size:18px;
font-weight:bold;
width:180px;
border:0;
transition: background-color 0.2s;
height:80px;
min-height:80px;
margin:10px;
margin-top:20px;
border-radius: 5px;
&:hover{
   background: ${props => shade(0.2, props.backGroundDinamico)};
}

`;

export const DivTextoReabrirAtendimento = styled.div`
 display:flex;
 flex:1;
justify-content:center;
align-items:center;
`;
export const DivInputMotivoCancelamento = styled.div`
 display:flex;
 flex:1;
justify-content:center;
align-items:flex-end;
`;
export const ButtonNaoCancelarAtendimento = styled.button`
display:flex;
flex-direction:row;
border:0;
margin:10px;
justify-content:space-around;
height:50px;
min-height:50px;
width:150px;
align-items:center;
border-radius:5px;
transition: background-color 0.2s;
background:${props => props.backgroundBotao};
border:${props => ` 1px solid ${props.borderColorDinamico}`};

&:hover{
    background:${props => shade(0.2, props.backgroundBotao)};
}

`;

export const ButtonCancelarAtendimento = styled.button`
display:flex;
flex-direction:row;
border:0;
margin:10px;
justify-content:space-around;
height:50px;
min-height:50px;
width:150px;
align-items:center;
border-radius:5px;
transition: background-color 0.2s;
background:${props => props.backgroundBotao};
&:hover{
    background:${props => shade(0.2, props.backgroundBotao)};
}
`;
export const TextBotaoCancelarAtendimento = styled.span`
  color:${props => props.colorDinamico};
  font-size:16px;
 font-weight:bold;
 flex:1;
 position: relative;
 margin-right:7px;
 text-align:center;
 align-items:center;
 justify-content:center;
`;

export const ContainerSelectSearch = styled.div`
display:flex;
flex-direction:row;
height:30px;
min-height:30px;
width:435px;
margin-left:15px;
background:blue;
`;
export const InputSearchQtdAtendentes = styled.input`
display:flex;
flex:1;
height:30px;
min-height:30px;
width:250px;
border-radius:5px;
padding-left:7px;
font-size:14px;
border:0;
color:${props => props.corLetraDinamico};
background-color: ${props => props.backgroundDinamico};

border:${props => `outset 0.5px ${props.corLetraDinamico}`};
cursor: pointer;
&::placeholder{
   color:${props => props.placeholderColor};
   font-style: italic;
}
&:hover{
  border:${props => `solid 0.5px ${props.corBordaDinamica}`};
 }

`;