import firebase from '../servicos/FirebaseConnection';
import api from '../http_client/http.client';

const getCacheRandomTime = () => {
    const availableTimes = [7, 8, 9, 10, 11, 12, 13, 14, 15];
    const randomIndex = Math.floor(Math.random() * availableTimes.length);
    const randomTime = availableTimes[randomIndex];
    const now = new Date();
    const cachedAt = new Date(`${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()} ${randomTime}:00`);
    return cachedAt.getTime() + 1000*60*60*24;
}


const isValidCache = (cachedAt) => {
    const now = (new Date()).getTime();
    return now < cachedAt;
}

const getFirebaseData = async (table, id, props) => {
    let ref = `${table}/${id}`;
    if (props) {
        ref += `/${props}`;
    }

    const firebaseData = await firebase.database().ref(ref).once("value");
    return { data: firebaseData.val(), key: id };
}

export const removeFirebaseCachedData = (table, id) => {
    const key = `${table}_${id}`;
    localStorage.removeItem(key);
}

export const clearCacheRedis = (chaveUnica, telephone) => {
    if (chaveUnica && !!telephone) {
        const data = {
            key: `${chaveUnica}_${telephone}`
        }
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'Accept': 'application/json',
            },

            data: data,
            url: 'clearCache',
        };
        api(options).then((response) => {
            //console.log(response.data);
        }).catch((err) => {
            console.warn(err);

        })
    }
};

const logFirebaseError = async (key, e) => {
    return firebase.firestore().collection('error').doc(key).set(e);
}

export const getFirebaseCachedData = async (table, id, props) => {
    try {
        const key = `${table}_${id}`;
        const cached = localStorage.getItem(key);
        const parsedCache = cached ? JSON.parse(cached) : null;

        if (parsedCache && isValidCache(parsedCache['cachedAt'])) {
            /*   console.log('key returned from cache', key, parsedCache); */
            return parsedCache;
        } else {
            const firebaseData = await getFirebaseData(table, id, props);
            const storageData = {
                ...firebaseData,
                cachedAt: getCacheRandomTime(),
            }
            localStorage.setItem(key, JSON.stringify(storageData));
            /* console.log('key returned from firebase', key, firebaseData); */
            return firebaseData;
        }
    } catch (e) {
        /*  console.log('error get cached data', e); */
        await logFirebaseError(id, { e, table, id });
        return getFirebaseData(table, id, props);
    }
}

export const getAllFirebaseCachedData = async (chaveUnica, results) => {
    let promiseCliente;
    let promiseAtendente;
    let promiseSetor;
    const arrayClientesChamado = [];
    const arraySetoresChamado = [];
    const arrayAtendentesChamado = [];
    const clientesKeys = [];
    const setoresKeys = [];
    const atendentesKeys = [];

    for (let i = 0; i < results.length; i++) {
        const cliente = results[i].cliente;
        const atendente = results[i].atendente;
        const setor = results[i].setor;

        if (cliente && cliente.toLowerCase() !== 'não informado' && clientesKeys.indexOf((item) => item === cliente) === -1) {
            promiseCliente = getFirebaseCachedData(`${chaveUnica}/clientes`, cliente, null);
            arrayClientesChamado.push(promiseCliente);
            clientesKeys.push(cliente);
        }

        if (atendente && atendente.toLowerCase() !== 'não informado' && atendentesKeys.indexOf((item) => item === atendente) === -1) {
            promiseAtendente = getFirebaseCachedData(`${chaveUnica}/usuarios`, atendente, 'nome');
            arrayAtendentesChamado.push(promiseAtendente);
            atendentesKeys.push(atendente);
        }

        if (setor && setor.toLowerCase() !== 'não informado' && setoresKeys.indexOf((item) => item === setor) === -1) {
            promiseSetor = getFirebaseCachedData(`${chaveUnica}/setores`, setor, 'nome');
            arraySetoresChamado.push(promiseSetor);
            setoresKeys.push(setor);
        }
    }

    const responseClientes = await Promise.all(arrayClientesChamado);
    const responseAtendentes = await Promise.all(arrayAtendentesChamado);
    const responseSetores = await Promise.all(arraySetoresChamado);

    return { responseClientes, responseAtendentes, responseSetores };

}