import styled, { keyframes } from 'styled-components';
import { shade, opacify, lighten, darken } from 'polished';
import imagebackgroundbatepapo from '../../imagens/background3_2.jpg';
import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import InnerImageZoom from 'react-inner-image-zoom';


import InputMask from "react-input-mask";

const bounce = keyframes`
    0% {
    transform: translateY(0px);
  }
  40% {
    transform: translateY(-20px);
  }
  80%,
  100% {
    transform: translateY(0px);
  }
`;
const animacaoLoading = keyframes`
   0%{
        background-position: 0% 0%;
     }
     100%{
        background-position: -135% 0%;
     }
  }
`;
export const Container = styled.div`
 display:flex;
 flex: 1;
 height:calc(100vh - 61px);
 flex-direction:row;
overflow:hidden;
position: relative;
`;
export const SubContainer = styled.div`
 display:flex;
 flex-direction:row;
/*  margin:15px; */
overflow:hidden;
 flex:1;
`;
export const ContainerFlatListOverFlow = styled.div`
 display:flex;
 flex-direction:column;
/*  margin:15px; */
overflow:auto;
height:calc(100vh - 137px);
 flex:1;
 &::-webkit-scrollbar {
  width: 5px;
  border-radius:7px;
}

/* Track */
&::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
&::-webkit-scrollbar-thumb {
  background:${props => shade(0.2, props.backgrounddinamico)};
}

/* Handle on hover */
&::-webkit-scrollbar-thumb:hover {
  background:${props => shade(0.2, props.backgrounddinamico)};
}
`;

export const ContainerConversas = styled.div`
 display:flex;
 flex-direction:column;
 width:350px;
 background:${props => props.backgrounddinamico};

 /* margin:10px; */

`;

export const ContainerPrincipal = styled.div`
 display:flex;
 flex-direction:column;
 flex:1;
 /* margin:10px; */
/*  border-right:2px solid ${props => props.backgroundDinamico}; */
`;
export const ContainerSecundario = styled.div`
 display:flex;
 flex-direction:column;
 height:100%;
 width:350px;
`;

export const CabecalhoPrincipal = styled.div`
 display:flex;
 flex-direction:row;
 height:75px;
 min-height:75px;
 /* padding:10px; */
 width:100%;
 background:${props => props.backgroundDinamico};

`;
export const DivPesquisaConversas = styled.div`
 display:flex;
 flex-direction:row;
 height:40px;
 min-height:40px;
 width:350px;
 background:${props => props.backgrounddinamico};
 align-items:center;
 justify-content:center;
`;


export const ContainerBatepapo = styled.div`
 display: flex;
 flex-direction:column;
 flex:1;
 z-index:2;
 overflow:auto;
 border-left:0.5px solid ${props => props.bordaDinamica};

 background:${props => props.backgroundinamico};
`;
export const ContainerEnviaMensagem = styled.div`
 display:flex;
 flex-direction:column;
 /* min-height:50px; */
 width:100%;
 border-left:1px solid ${props => props.bordaDinamica};
 background:${props => props.backgroundDinamico};
`;

export const CabecalhoSecundario = styled.div`
 display:flex;
 flex-direction:row;
 height:75px;
 min-height:75px;
 width:100%;
 padding:15px;
 padding-left:25px;
align-items:center;
 border-top-right-radius:5px;
 
 background:${props => props.backgroundDinamico};
`;
export const CabecalhoSecundarioEmEspera = styled.div`
 display:flex;
 flex-direction:row;
 height:75px;
 min-height:75px;
 width:100%;
 padding:15px;
 padding-left:25px;
align-items:center;
justify-content:space-between;
 border-top-right-radius:5px;
 background:${props => props.backgroundDinamico};
`;
export const ContainerEmAtendimento = styled.div`
 display:flex;
 flex-direction:column;
flex:1;
border-right:1px solid ${props => props.bordaDinamica};
`;
export const ContainerEmEspera = styled.div`
 display:flex;
 flex-direction:column;
flex:1;
border-bottom:1px solid ${props => props.bordaDinamica};
border-right:1px solid ${props => props.bordaDinamica};
`;
export const ContainerChamadosEmAtendimento = styled.div`
 display:relative;
 flex-direction:column;
flex-grow:1;
flex-basis:60px;
background:${props => props.backgroundDinamico};
overflow:auto;
align-items:center;
`;
export const ContainerChamadosEmEspera = styled.div`
 display:flex;
 flex-direction:column;
 flex-grow:1;
 flex-basis:60px;
border-bottom-right-radius:5px;
background:${props => props.backgroundDinamico};
overflow:auto;
align-items:center;
`;
export const DivTextAtendimento = styled.div`
 display:flex;
 flex-direction:column;
flex:1;
justify-content:center;
`;
export const DivTextAtendimentoEmEspera = styled.div`
 display:flex;
 flex-direction:row;
flex:1;
`;
export const DivBotaoEncerrarAtendimento = styled.div`
 display:flex;
 flex-direction:row;
 align-items:center;
`;

export const TextCabecalhoAtendimento = styled.p`
font-size: 17px; 
color: ${props => props.corLetraDinamica};
font-weight:bold;
max-width:350px;
overflow:hidden;
white-space:nowrap;
text-overflow:ellipsis;
&:hover{
   text-decoration:${props => props.alterarNome === true ? 'underline' : 'none'};
   cursor :${props => props.alterarNome === true ? 'pointer' : 'auto'};
} 
`;
export const TextSubCabecalhoAtendimento = styled.p`
font-size: 12px; 
color: ${props => props.corLetraDinamica};
max-width:350px;
overflow:hidden;
white-space:nowrap;
text-overflow:ellipsis;
`;
export const ButtonEncerrarChamado = styled.button`
display:flex;
justify-content:center;
align-items:center;
font-weight:bold;
font-size:15px;
color:${props => props.corLetraDinamica};
background:${props => props.backgrounddinamico};
width:85px;
border:0;
transition: background-color 0.2s;
height:35px;
min-height:35px;
margin-left:10px;
margin-right:10px;
border-radius: 5px;
 &:hover{
   background: ${props => shade(0.2, props.backgrounddinamico)};
} 
`;

export const ButtonIniciarAtendimento = styled.button`
display:flex;
justify-content:center;
align-items:center;
font-weight:bold;
font-size:13px;
color:${props => props.corLetraDinamica};
background:${props => props.backgrounddinamico};
width:150px;
border:0;
transition: background-color 0.2s;
height:40px;
min-height:40px;
margin-left:25px;
border-radius: 5px;
letter-spacing:0.5px;
box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
 &:hover{
   background: ${props => shade(0.2, props.backgrounddinamico)};
} 
`;
export const ButtonIniciarAtendimentoLoading = styled.div`
display:flex;
justify-content:center;
align-items:center;
font-weight:bold;
font-size:13px;
color:${props => props.corLetraDinamica};
background:${props => `linear-gradient(to right, ${darken(0.2, props.backgrounddinamico)} 10%, ${darken(0.2, props.backgrounddinamico)} 28%, ${props.backgrounddinamico} 33%)`};
animation:${animacaoLoading} .8s linear infinite;
background-size: 400% 400%; 
width:150px;
border:0;
transition: background-color 0.2s;
height:40px;
min-height:40px;
margin-left:25px;
border-radius: 5px;
letter-spacing:0.5px;
box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);

`;


export const ButtonLinkCfg = styled(Link)`
display:flex;
justify-content:space-between;
align-items:center;
text-decoration:none;
font-weight:bold;
font-size:20px;
color:${props => props.corLetraDinamica};
background:${props => props.backgrounddinamico};
width:200px;
border: 1px solid ${props => props.borderColorDinamico};
transition: background-color 0.2s;
height:100px;
min-height:100px;
padding:10px;
margin-left:25px;
margin-right:25px;
border-radius: 5px;
 &:hover{
   background: ${props => shade(0.2, props.backgrounddinamico)};
} 
`;
export const ButtonWhatsApp = styled.div`
display:flex;
justify-content:space-between;
align-items:center;
text-decoration:none;
font-weight:bold;
font-size:20px;
color:${props => props.corLetraDinamica};
background:${props => props.backgrounddinamico};
width:200px;
border: 1px solid ${props => props.borderColorDinamico};
transition: background-color 0.2s;
height:100px;
cursor:pointer;
min-height:100px;
margin-left:25px;
margin-right:25px;
border-radius: 5px;
padding:10px;
 &:hover{
   background: ${props => shade(0.2, props.backgrounddinamico)};
} 
`;
export const ButtonImpedidoAtenderChamado = styled.button`
display:flex;
justify-content:center;
align-items:center;
font-weight:bold;
font-size:15px;
color:${props => props.corLetraDinamica};
background:${props => props.backgrounddinamico};
width:85px;
border:0;
transition: background-color 0.2s;
height:35px;
min-height:35px;
margin-left:10px;
margin-right:10px;
border-radius: 5px;
cursor:no-drop;
 &:hover{
   background: ${props => shade(0.2, props.backgrounddinamico)};
} 
`;









export const ContainerPrincipalDadosCliente = styled.div`
 display:flex;
 flex:1;
 padding-bottom:10px;
 flex-direction:column;
 margin:15px;
 overflow:auto;
 background:${props => props.backGroundDinamico};
 border-radius:5px;
 position:relative;
`;

export const SubContainerCliente = styled.div`
 display:flex;
 flex:1;
 flex-direction:row;
 align-items:center;
 justify-content:center;
 
`;
export const ContainerAtendentesSelecionados = styled.div`
 display:flex;
 flex:1;
 flex-direction:column;
 justify-content:flex-start;
align-items:center;
margin:20px;
`;
export const ContainerAtendentesSetor = styled.div`
 display:flex;
 height:150px;
 min-height:150px;
 width:100%;
 flex-direction:column;
 border-radius:10px;
 justify-content:center;
align-items:center;
border:${props => `1px solid ${props.corLetraDinamico}`};
background:${props => props.backgroundDinamico};


`;
export const ContainerSelectSearch = styled.div`
display:flex;
flex-direction:column;
height:30px;
min-height:30px;
width:400px;
margin-left:15px;
`;
export const DivIconeExcluirCliente = styled.div`
 display:flex;
 flex-direction:row;
justify-content:flex-end;
align-items:flex-end;
padding-top:5px;
`;

export const SubContainerClientePermissao = styled.div`
 display:flex;
 height:55px;
 flex-direction:column;
`;
export const ContainerPermitir = styled.div`
 display:flex;
 justify-content:center;
 flex-direction:row;
 margin-top:10px;
`;


export const DivInfoVencPrioLocal = styled.div`
display:flex;
 height:20px;
 min-height:20px;
 flex-direction:row;
 align-items:center;
justify-content:space-between;
background:orange;
flex:1;
`;
export const ContainerChamadoAux = styled.div`
display:flex;
 height:80px;
 min-height:80px;
z-index:2;
cursor:pointer;
/* border-bottom:1px solid ${props => props.bordadinamica}; */
 background:${props => props.backGroundDinamico}; 
 flex-direction:row;
 &:hover{
  overflow:hidden;
  background: ${props => shade(0.3, props.backGroundDinamico)};
}
`;
export const ContainerChamado = styled.div`
display:flex;
 height:60px;
 min-height:60px;
z-index:2;
cursor:pointer;
/* border-bottom:1px solid ${props => props.bordadinamica}; */
 background:${props => props.backGroundDinamico}; 
 flex-direction:row;
 &:hover{
  overflow:hidden;
  background: ${props => shade(0.4, props.backGroundDinamico)};
}
`;
export const ContainerImagem = styled.div`
display:flex;
 height:60px;
 min-height:60px;
width:70px;
min-width:70px;
max-width:70px;
align-items:center;
justify-content:center;
 flex-direction:row;
 overflow:hidden;
`;
export const ContainerInputCompartilhar = styled.div`
display:flex;
 height:60px;
 min-height:60px;
width:40px;
max-width:40px;
align-items:center;
justify-content:center;
 flex-direction:column;
`;

export const ContainerImagemAlterarCliente = styled.div`
display:flex;
 height:100%;
width:200px;

align-items:center;
justify-content:center;
 flex-direction:column;
`;


export const ContainerDadosCliente = styled.div`
 display:flex;
 flex: 1;
 flex-direction:column;
margin-right:10px;
margin-left:10px;
`;
export const ContainerDadosNovoCliente = styled.div`
 display:flex;
 flex: 1;
 flex-direction:column;
margin-right:10px;
justify-content:center;
`;
export const ContainerDadosLoginCliente = styled.div`
 display:flex;
 flex: 1;
 flex-direction:column;
margin-right:10px;
margin-left:30px;
`;
export const ContainerBotaoAlterar = styled.div`
 display:flex;
 flex-direction:column;
 justify-content:center;
 align-items:center;
`;

export const ContainerDadosChamado = styled.div`
display:flex;
flex:1;
 flex-direction:row;
 border-bottom:1px solid ${props => props.bordadinamica};
`;

export const ContaineDadosChamadosPrincipal = styled.div`
display:flex;
flex:1;
 height:60px;
 min-height:60px;
 flex-direction:column;
`;
export const ContaineDadosChamadosPrincipalAux = styled.div`
display:flex;
flex:1;
 height:80px;
 min-height:80px;
 flex-direction:column;
`;
export const ContainerIconeEFrase = styled.div`
display:flex;
flex:1;
 flex-direction:row;
  align-items:center;
 justify-content:flex-start;
`;
export const ContainerIconeEFraseClienteAtendente = styled.div`
display:flex;
flex:1;
 flex-direction:row;
`;
export const DivContainerInputSenha = styled.div`
display:flex;
 flex-direction:row;
`;
export const DivContainerInputEmpresaVinculada = styled.div`
display:flex;
 flex-direction:row;
 align-items:center;
`;

export const SubContainerIconeEFraseClienteAtendente = styled.div`
display:flex;
 flex-direction:row;
 align-items:center;
 justify-content:flex-start;
 height:30px;
 min-height:30px;
`;
export const TextCliente = styled.p`
font-size:14px;
margin-left:3px;
font-weight:bold;
color:${props => props.corLetraDinamica};
max-width:165px;
overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
`;
export const TextEmpresa = styled.p`
font-size:14px;
margin-left:3px;
font-weight:200;
color:${props => props.corLetraDinamica};
max-width:165px;
overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
`;
export const TextResponder = styled.p`
font-size:14px;
/* margin-left:3px; */
color:${props => props.corLetraDinamica};
text-align:left;
`;

export const DivTextResponder = styled.div`
display: block;
 display: -webkit-box;
 /* max-width: 100%; */
 margin: 0 auto;
 -webkit-line-clamp:2;
 -webkit-box-orient: vertical;
 overflow: hidden;
 text-overflow: ellipsis;
 white-space:nowrap;
`;
export const TextProblema = styled.p`
font-size:14px;
margin-left:3px;
font-weight:bold;
font-style:italic;
color:${props => props.corLetraDinamica};
`;
export const TextPermitir = styled.p`
font-size:16px;
text-align:center;
font-weight:bold;
color:${props => props.corLetraDinamica};
`;
export const ContaineDadosChamadosSecundario = styled.div`
display:flex;
flex:0.5;
 height:100px;
 min-height:100px;
 flex-direction:column;
 align-items:flex-start;
 justify-content:flex-start;
`;
export const ContainerNumeroChamado = styled.div`
display:flex;
 justify-content:center;
margin-right:2px;
`;
export const ContainerInputSearch = styled.div`
display:flex;
flex-direction:row;
 justify-content:center;
 align-items:center;
 margin-bottom:10px;
margin-right:2px;
`;

export const DivBadget = styled.div`
   border-radius: 4px;
    padding: 4px;
    background: #f64b34;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: -7px;
    top:-7px;
    font-size: 10px;
    z-index: 1;
`;
export const DivCountNotif = styled.div`
height: 17px;
width: 17px;
max-width: 17px;
min-height: 17px;
display: flex;
align-items: center;
flex-direction:row;
justify-content: center;
font-size: 12px;
border-radius: 50%;
background: red;
font-weight: 400; 
color: white;
padding:1px;
`;
export const DivCountChamadoEspera = styled.div`
height: 14px;
width: 14px;
max-width: 14px;
min-height: 14px;
display: flex;
align-items: center;
flex-direction:row;
justify-content: center;
font-size: 11px;
border-radius: 50%;
background: ${props => props.backgrounddinamico};;
color: ${props => props.corletradinamica};
margin-left:7px;
padding-bottom:1px;
`;

export const DivData = styled.div`
display:flex;
flex-direction:row;
justify-content:center;
align-items:center;
width:60px;
height:30px;
min-height:30px;
`;
export const ContainerSeta = styled.div`
display:flex;
flex-direction:column;
justify-content:space-around;
align-items:flex-end;
padding-right:5px;
min-width:70px;
`;
export const TextNumeroChamado = styled.strong`
font-size:14px;
font-weight:bold;
color:${props => props.corLetraDinamica};
`;
export const TextDataHoraAbertura = styled.p`
font-size:9px;
color:${props => props.corLetraDinamica};
margin-left:2px;
`;
export const TextAbertura = styled.p`
font-size:10px;
color:${props => props.corLetraDinamica};
`;

export const ContainerDataAbertura = styled.div`
display:flex;
flex-direction:row;
justify-content:flex-start;
align-items:center;
margin-bottom:2px;
`;
export const IconeSair = styled.i`
font-size:17px;
margin-right:5px;
color:red;
cursor: pointer;
`;
export const InputFiltroPorNumero = styled.input`
display:flex;
background-Color:#FFF;
flex:1;
height:30px;
min-height:30px;
padding-left:20px;
width:400px;
border:0;
border-radius:3px;
color:#000;
&::placeholder{
   color:#a8a8B3
}
`;

export const BotaoPesquisarChamadoPorNumero = styled.button`
display:flex;
justify-content:center;
align-items:center;
background:${props => props.backGroundDinamico};
width:30px;
border:0;
transition: background-color 0.2s;
height:30px;
min-height:30px;
margin-left:5px;
border-radius: 5px;

`;

export const CheckBox = styled.input`
  z-index: 1;
  border-radius: 15px;
  width: 42px;
  height: 26px;
  min-height: 26px;
  background:#CCC;
  &:checked  {
    background: #00FF7F;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      min-height: 18px;
      margin-left: 21px;
      transition: 0.2s;
    }
  }
`;

export const ButtonEntrar = styled.button`
display:flex;
justify-content:center;
align-items:center;

background-color:${props => props.backGroundDinamico};
color:${props => props.corLetraBotao};
letter-spacing: 1px;
font-size:21px;
font-weight:bold;
width:40%;
border:0;
transition: background-color 0.2s;
height:40px;
min-height:40px;
margin:10px;
border-radius: 5px;
&:hover{
   background: ${props => shade(0.2, props.backGroundDinamico)};
}

`;
export const IconeExcluirCliente = styled.i`
font-size:18px;
color:${props => props.colorDinamico};
cursor: pointer;
&:hover{
  color: ${props => shade(0.2, props.colorDinamico)};
}
`;
export const IconeAudioEmAtendimento = styled.i`
font-size:12px;
color:${props => props.colorDinamico};
cursor: pointer;
margin-right:5px;
&:hover{
  color: ${props => shade(0.2, props.colorDinamico)};
}
`;

export const ContainerModal = styled.div`
 display:flex;
 margin-top:30px;
   overflow:visible;
 flex:1;
   width:400px;
   height:350px;
   min-height:400px;
   flex-direction:column;
   border-radius:7px;
   background:${props => props.backgroundDinamico};
`;
export const ContainerModalRespostas = styled.div`
 display:flex;
   width:585px;
   height:calc(100vh - 90px);
  overflow:auto;
   flex-direction:column;
   border-radius:7px;
   background:${props => props.backgroundDinamico};
`;
export const ContainerModalNovoCliente = styled.div`
 display:flex;
   overflow:auto;
 flex:1;
   width:580px;
   padding-bottom:10px;
   height:300px;
   min-height:300px;
   flex-direction:column;
   border-radius:7px;
   background:${props => props.backgroundDinamico};
`;
export const TextNovoAtendimento = styled.strong`
font-size:17px;
color:${props => props.corLetraDinamico};
margin-left:12px;
letter-spacing: 1.5px;
`;
export const IconeFechar = styled.i`
font-size:15px;
margin-right:5px;
color:${props => props.backgroundDinamico};
cursor: pointer;
`;
export const IconeFecharImagePreview = styled.i`
margin-right:11px;
color:${props => props.backgroundDinamico};
cursor: pointer;
`;
export const ContainerIconeFecharModal = styled.div`
padding-top:5px;
padding-bottom:5px;
justify-content:space-between;
align-items:center;
display:flex;
border-radius: 7px 7px 0 0;
width:100%;
background:${props => props.backgroundDinamico};
`;
export const ContainerIconeFecharImagemPreview = styled.div`
padding-top:5px;
padding-bottom:5px;
justify-content:flex-end;
align-items:center;
display:flex;
border-radius: 7px 7px 0 0;
width:100%;
min-height:70px;
background:${props => props.backgroundDinamico};
`;

export const IconeMostrarSenha = styled.i`
font-size:20px;
color:${props => props.colorDinamico};;
&:hover{
  color: ${props => shade(0.2, props.colorDinamico)};
}

&:focus {
   border-bottom: ${props => `1px solid ${props.bordaDinamica}`};
  }
`;
export const IconGravadorVoz = styled.i`
font-size:26px;
color:${props => props.colorDinamico};;
&:hover{
  color: ${props => shade(0.2, props.colorDinamico)};
}


`;

export const InputEmpresa = styled.input`
display:flex;
background-Color:transparent;
height:25px;
min-height:25px;
width:100%;
border:0;
font-size:14px;
border-bottom: ${props => `1px solid ${props.colorDinamica}`};
color:${props => props.colorDinamica};
&::placeholder{
   color:${props => props.placeholderDinamico};
}
:focus +${IconeMostrarSenha}{
   border-bottom: ${props => `1px solid ${props.borderDinamico}`};
  }
  :focus{
   border-bottom: ${props => `1px solid ${props.borderDinamico}`};
  }
`;
export const IconeEmpresaVinculada = styled.i`
font-size:19px;
color:${props => props.colorDinamica};
cursor: pointer;
`;
export const IconeEmpresaVinculadaOff = styled.i`
font-size:19px;
color:${props => props.colorDinamica};
`;

export const ContainerImagemVincularEmpresa = styled.div`
 display:flex;
 flex:1.5;
  flex-direction:column;
  align-items:center;
  justify-content:center;
`;
export const ContainerExplicacaoVincular = styled.div`
 display:flex;
 flex:1;
 padding:5px;
  flex-direction:column;
  align-items:center;
  justify-content:center;
`;
export const ButtonVincular = styled.button`
display:flex;
justify-content:center;
align-items:center;

background-color:${props => props.backGroundDinamico};
color:${props => props.corLetraBotao};
letter-spacing: 1px;
font-size:20px;
font-weight:bold;
width:60%;
border:0;
transition: background-color 0.2s;
height:40px;
min-height:40px;
margin:10px;
border-radius: 5px;
&:hover{
   background: ${props => shade(0.2, props.backGroundDinamico)};
}
`;

export const TextVincular = styled.p`
font-size:16px;
text-align:center;
color:${props => props.corLetraDinamica};
`;

export const PerfilImage = styled.img`
    transition: all 2s;
`;
export const ImagePreview = styled(InnerImageZoom)`
   transition: all .5s;
    max-width: 100%;
    max-height: auto;
    margin: auto;
    z-index:9999;
    padding:10px;
`;


export const ImagePerfil = styled.img`
height:130px;
min-height:130px;
width:130px;
border-radius:50%;
cursor: pointer;
&:hover{
   border: ${props => `1px solid ${props.bordaDinamico}`};
}
`;
export const TextAtendentesSetor = styled.p`
font-size:16px;
text-align:center;
height:50px;
min-height:50px;
color:${props => props.corLetraDinamica};
`;

export const InputSearch = styled.input`
display:flex;
flex:1;
height:30px;
min-height:30px;
width:100%;
border-radius:5px;
padding-left:7px;
font-size:14px;
border:0;
color:${props => props.corLetraDinamico};
background-color: ${props => props.backgroundDinamico};

border:${props => `outset 0.5px ${props.corLetraDinamico}`};
cursor: pointer;
&::placeholder{
   color:${props => props.placeholderColor};
   font-style: italic;
}
&:hover{
  border:${props => `solid 0.5px ${props.corBordaDinamica}`};
 }

`;

export const DivInputSearch = styled.div`
 display:flex;
  flex-direction:row;
  align-items:center;

`;
export const IconeSetaPraBaixo = styled.i`
font-size: 12px;
position: absolute;
top:8px;
right: 19px;
width: 11px;
height: 11px;
min-height: 11px;
color:${props => props.colorDinamico};
pointer-events: none;
`;
export const IconeExcluirOpcaoMarcada = styled.i`
font-size: 10px;
position: absolute;
top:8px;
right: 44px;
width: 11px;
height: 11px;
min-height: 11px;
color:${props => props.colorDinamico};
z-index:10;
cursor:pointer;
&:hover{
  color:${props => props.corBotaoPadrao};
  } 
`;

export const ImageLoading = styled.div`
display:flex;
 height:40px;
 min-height:40px;
width:40px;
border-radius:50%;
background:${props => `linear-gradient(to right, ${lighten(0.2, props.backgroundinamico)} 10%, ${lighten(0.2, props.backgroundinamico)} 28%, ${props.backgroundinamico} 33%)`};
animation:${animacaoLoading} 1.2s linear infinite;
  background-size: 400% 400%; 
`;

export const TextClienteLoading = styled.div`
margin-left:3px;
height:15px;
min-height:15px;
border-radius:5px;
width:95%;
max-width:95%;
background:${props => `linear-gradient(to right, ${lighten(0.2, props.backgroundinamico)} 10%, ${lighten(0.2, props.backgroundinamico)} 28%, ${props.backgroundinamico} 33%)`};
animation:${animacaoLoading} 1.2s linear infinite;
  background-size: 400% 400%; 
`;
export const ImagemPerfilLoading = styled.div`
height:150px;
min-height:150px;
width:150px;
border-radius:50%;
background:${props => `linear-gradient(to right, ${lighten(0.2, props.backgroundinamico)} 10%, ${lighten(0.2, props.backgroundinamico)} 28%, ${props.backgroundinamico} 33%)`};
animation:${animacaoLoading} 1.2s linear infinite;
background-size: 400% 400%; 
`;
export const InputLoadingEmpresa = styled.div`
display:flex;
height:25px;
min-height:25px;
width:100%;
border:0;
margin-bottom:10px;
border-radius:4px;
background:${props => `linear-gradient(to right, ${lighten(0.2, props.backgroundinamico)} 10%, ${lighten(0.2, props.backgroundinamico)} 28%, ${props.backgroundinamico} 33%)`};
animation:${animacaoLoading} 1.2s linear infinite;
  background-size: 400% 400%; 

`;
export const SubContainerClienteLoading = styled.div`
 display:flex;
 flex:1;
 flex-direction:row;
padding-top:40px;
`;
export const ContainerDadosClienteLoading = styled.div`
 display:flex;
 height:100px;
 min-height:100px;
 justify-content:space-around;
 flex-direction:column;
margin-right:10px;
margin-left:10px;
margin-top:60px;
`;
export const SubContainerClientePermissaoLoading = styled.div`
 display:flex;
 flex:1;
 flex-direction:column;
 margin-top:40px;
 align-items:center;
 margin-right:10px;
 margin-left:10px;
`;
export const ContainerListaVazia = styled.div`
 display:flex;
 flex:1;
  flex-direction:column;
  align-items:center;
justify-content:center;
`;
export const DivTruncarTexto = styled.div`
display: block;
display: -webkit-box;
flex-direction:row;
 max-width: 100%;
 margin: 0 auto;
 -webkit-line-clamp:2;
 -webkit-box-orient: vertical;
 overflow: hidden;
 text-overflow: ellipsis;
 text-align:left;
`;
export const DivTruncarTextoEncaminharMensagem = styled.div`
display: block;
display: -webkit-box;
flex-direction:row;
 width:320px;
 max-width:320px;
 margin: 0 auto;
 -webkit-line-clamp:1;
 -webkit-box-orient: horizontal;
 overflow: hidden;
 text-overflow: ellipsis;
 text-align:left;
`;

export const TextMensagem = styled.p`
font-size:16px;
color:${props => props.corLetraDinamica};
max-width:700px;
overflow-wrap: break-word;
white-space: pre-wrap;
text-align:left;
`;
export const TextTitulo = styled.span`
font-size:14px;
font-weight:bold;
max-width:200px;
overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
color:${props => props.corLetraDinamica == '#00FF7F' ? '#16cb9c' : props.corLetraDinamica};
text-align:left;
`;
export const TextAvaliar = styled.p`
font-size:13px;
background:#00BFFF;
color:${props => props.corLetraDinamica};
width:150px;
margin-top:5px;
border-radius:5px;
cursor:pointer;
padding:5px;
`;
export const ImagemComentario = styled.img`
height:170px;
min-height:170px;
width:170px;
margin:15px;
border-radius:20px;
background:#9fadb7;
cursor:pointer;
`;
export const TextBaixarArquivo = styled.span`
color:#00BFFF;
font-size:13px;
transition: background-color 0.2s;
margin-top:5px;
text-decoration:underline;
cursor: pointer;
&:hover{
   color: ${shade(0.2, '#00BFFF')};
}
`;
export const ContainerClipBaixarArquivo = styled.div`
display:flex;
padding:3px;
flex-direction:row;
margin-top:5px;
border:${props => `1px solid ${props.corBordaDinamica}`};
border-radius:5px;
cursor: pointer;
&:hover{
  background:${props => shade(0.2, props.backgroundDinamico)};
}
`;
export const ContainerModalAlterar = styled.div`
display:flex;
   overflow:auto;
   flex:1;
   flex-direction:column;
   border-radius:7px;
   background:${props => props.backgroundDinamico};
`;
export const ContainerModalViewImage = styled.div`
   display:flex;
   flex:1;
   flex-direction:column;
   border-radius:7px;
   background:${props => props.backgroundDinamico};
   margin:0;
   height:calc(95vh);
   max-width:95vw; 
`;
export const ContentImageInnerZoom = styled.div`
   display:flex;
   overflow:auto;
   flex:1;
   flex-direction:column;
   border-radius:7px;
   background:${props => props.backgroundDinamico};
   margin:0;
   height:calc(95vh);
   width:calc(120vh); 
`;
export const ContainerModalAlterarConversa = styled.div`
display:flex;
   overflow:auto;
   width:450px;
   flex:1;
   height:calc(100vh - 61px);
   flex-direction:column;
   border-radius:7px;
   background:${props => props.backgroundDinamico};
`;
export const ContainerMaisInformacoes = styled.div`
 display:flex;
 flex-direction:column;
 background:${props => props.backGroundDinamico};

 padding:10px;
 justify-content:center;
 align-items:center;
 width:100%;

`;
export const SubContainerMaisInformacoes = styled.div`
 display:flex;
 flex-direction:column;
 background:${props => props.backGroundDinamico};
`;
export const DivLAbelCliente = styled.div`
 display:flex;
 height:30px;
 min-height:30px;
 justify-content:center;
 align-items:center;
 width:350px;
`;
export const DivCliente = styled.div`
 display:flex;
 height:30px;
 min-height:30px;
 justify-content:center;
 align-items:center;
 width:350px;
`;
export const LabelCliente = styled.p`
font-size:13px;
width:300px;
text-align:center;
overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
color:${props => props.corLetraDinamica};
`;
export const LabelClienteCabecalho = styled.p`
font-size:13px;
font-weight:bold;
width:300px;
text-align:center;
overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
color:${props => props.corLetraDinamica};
`;
export const DivSeparadoraMaisInformacoes = styled.div`
 display:flex;
 border-bottom:${props => `1px solid ${props.corBordaDinamica}`};
`;
export const ContainerModalPrioridadeAtendimento = styled.div`
 display:flex;
   width:550px;
  height:260px;
  min-height:260px;
  overflow:auto;
   flex-direction:column;
   border-radius:7px;
   background:${props => props.backgroundDinamico};
`;
export const ContainerModalEncaminharMensagem = styled.div`
 display:flex;
   width:400px;
  height:550px;
  min-height:550px;
  overflow:auto;
   flex-direction:column;
   border-radius:7px;
   background:${props => props.backgroundDinamico};
`;
export const DivInputMotivoCancelamento = styled.div`
 display:flex;
 flex:1;
justify-content:center;
align-items:flex-end;
`;
export const ButtonNaoCancelarAtendimento = styled.button`
display:flex;
flex-direction:row;
border:0;
margin:10px;
justify-content:space-around;
height:50px;
min-height:50px;
width:150px;
align-items:center;
border-radius:5px;
transition: background-color 0.2s;
background:${props => props.backgroundBotao};
border:${props => ` 1px solid ${props.borderColorDinamico}`};

&:hover{
    background:${props => shade(0.2, props.backgroundBotao)};
}

`;
export const ButtonNaoCancelarTransferencia = styled.button`
display:flex;
flex-direction:row;
border:0;
margin:10px;
justify-content:space-around;
height:40px;
min-height:40px;
width:120px;
align-items:center;
border-radius:5px;
transition: background-color 0.2s;
background:${props => props.backgroundBotao};
border:${props => ` 1px solid ${props.borderColorDinamico}`};

&:hover{
    background:${props => shade(0.2, props.backgroundBotao)};
}

`;

export const TextBotaoCancelarAtendimento = styled.span`
  color:${props => props.colorDinamico};
  font-size:16px;
 font-weight:bold;
 flex:1;
 position: relative;
 margin-right:7px;
 text-align:center;
 align-items:center;
 justify-content:center;
`;
export const TextBotaoSalvarComentar = styled.span`
  color:${props => props.backgroundBotao};
  font-size:14px;
 font-weight:bold;
 flex:1;
 position: relative;
 margin-right:7px;
 text-align:center;
 align-items:center;
 justify-content:center;
`;
export const ContainerAtendentes2 = styled.div`
display:flex;
  flex:1;
  align-items:center;
  justify-content:center;
  flex-direction:column;
`;

export const ContainerAtendentes = styled.div`
display:flex;
  flex:1;
  /* align-items:center; */
  flex-direction:column;
  overflow:auto;
  &::-webkit-scrollbar {
  width: 5px;
  border-radius:7px;
}

/* Track */
&::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
&::-webkit-scrollbar-thumb {
  background:${props => shade(0.2, '#CCC')};
}

/* Handle on hover */
&::-webkit-scrollbar-thumb:hover {
  background:${props => shade(0.2, '#CCC')};
}
`;
export const ButtonCancelarAtendimento = styled.button`
display:flex;
flex-direction:row;
border:0;
margin:10px;
justify-content:space-around;
height:50px;
min-height:50px;
width:150px;
align-items:center;
border-radius:5px;
transition: background-color 0.2s;
background:${props => props.backgroundBotao};
&:hover{
    background:${props => shade(0.2, props.backgroundBotao)};
}
`;
export const ButtonEnviarTransferencia = styled.button`
display:flex;
flex-direction:row;
border:0;
margin:10px;
justify-content:space-around;
height:40px;
min-height:40px;
width:120px;
align-items:center;
border-radius:5px;
transition: background-color 0.2s;
background:${props => props.backgroundBotao};
&:hover{
    background:${props => shade(0.2, props.backgroundBotao)};
}
`;





export const ContainerInputDescricaoChamado = styled.div`
   display:flex;
   flex-direction:column;
   align-items:center;
   justify-content:center;
   margin-bottom:15px;
   margin-left:35px;
   margin-right:35px;
   margin-top:20px;

/*    max-height:400px; */
   background:${props => props.backgroundDinamico};
/*    padding:10px; */
 /*   border: 1px solid ${props => props.corLetraSecundaria}; */
  /*  margin:10px; */
   border-radius:5px;
   &:focus {
  /* border:1px solid ${props => props.corBordaDinamica}; */
  } 
`;
export const ContainerInputComentarioTransferir = styled.div`
 display:flex;
   flex-direction:column;
   padding:10px;
   height:90px;
   margin:10px;
min-height:90px;
`;
export const InputComentarioTransferir = styled.textarea`
display:flex;
background-Color:transparent;
text-transform: none;
height:60px;
min-height:60px;
width:380px;
border:0;
padding:5px;
border-radius:4px;
border: 1px solid ${props => props.corLetraSecundaria};
color:${props => props.corLetraDinamico};
&::placeholder{
   color:#a8a8B3;
}
&:focus {
  border:1px solid ${props => props.corBordaDinamica};
  }
`;
export const InputDescricaoChamado = styled.textarea`
display:flex;
background-Color:${props => props.backgroundDinamico};
text-transform: none;
/* min-height:40px; */
justify-content:center;
align-items:center;
font-size:15px;
border:0;
/* max-height:400px; */
padding-left:15px;
flex:1;
border-radius:25px;
color:${props => props.corLetraDinamico};
&::placeholder{
   color:#a8a8B3;
}
&:focus {
  border:1px solid ${props => props.corBordaDinamica};
  }


/* &:focus {
  border:1px solid ${props => props.corBordaDinamica};
  } */
`;
export const IconeAcaoChat = styled.i`
font-size:20px;
color:${props => props.colorDinamico};
margin-right:10px;
margin-left:10px;
cursor: pointer;
&:hover{
  color:${props => shade(0.2, props.backgroundBotao)};
}
`;
export const ButtonVariosNovos = styled.button`
display:flex;
flex-direction:row;
border:0;
justify-content:center;
height:30px;
min-height:30px;
width:70px;
margin-right:10px;
   margin-left:10px;
align-items:center;
border-radius:5px;
transition: background-color 0.2s;
background:${props => props.backgroundBotao};
&:hover{
   background:${props => shade(0.2, props.backgroundBotao)};
}
`;
export const TextBotaoNovo = styled.span`
color:${props => props.colorDinamico};
font-size:13px;
font-weight:bold;
`;

export const MarcaDaguaBatePapo = styled.div`
position: relative;
z-index: 5;
display: flex;
flex:1;
flex-direction:column;
overflow:auto;
&:after{
position: absolute;
z-index: -1;
top: 0;
bottom: 0;
background-image: url(${imagebackgroundbatepapo});
opacity: 0.2;
background-repeat: no-repeat;
background-position: center;
background-size: cover;
background-attachment: scroll;
content: "";
height:100%;
width:100%;
/*   
  display:flex;
flex:1;
  content: "a";
  background-size: cover;
background-repeat: no-repeat;
background-position: center;
background-image: url(${imagebackgroundbatepapo});
opacity:0.5;
  z-index: -1;   */ 
}
`;
export const ContainerFlatListBatePapo = styled.div`
    display: flex;
overflow: auto;
flex:1;
flex-direction: column-reverse;
&::-webkit-scrollbar {
  width: 5px;
  border-radius:7px;
}

/* Track */
&::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
&::-webkit-scrollbar-thumb {
  background:${props => shade(0.2, props.backgrounddinamico)};
}

/* Handle on hover */
&::-webkit-scrollbar-thumb:hover {
  background:${props => shade(0.2, props.backgrounddinamico)};
}
/* z-index: 5;
display:flex;
overflow: auto;
flex:1;
flex-direction: column-reverse; */
`;
export const DivTesteFlatList = styled.div`
overflow: auto;
z-index: 5;
flex:1;
flex-direction: column-reverse;
display:flex;
`;

export const TextLoadingEnviandoARquivo = styled.p`
font-size: 14px;
color: ${props => props.corDinamico};
font-weight:bold;
animation: ${bounce} 2s infinite;
`;
export const DivAtendentes = styled.div`
 display:flex;
padding:10px;
justify-content:center;
align-items:center;
`;
export const TextAtendentes = styled.strong`
font-size:15px;
color:${props => props.corLetraDinamico};
font-weight:bold;
cursor:pointer;
&:hover{
  color:${props => props.corLetraBotaoPadrao};
}
`;
export const ContainerTextCabecalhoModalAcoes = styled.div`
 display:flex;
 flex-direction:column;
padding-top:15px;
padding-bottom:15px;
 align-items:center;
 justify-content:center;
`;
export const TextCabecalhoModalAcoes = styled.strong`
font-size:18px;
color:${props => props.corLetraDinamico};
text-align:center;
`;
export const TextCabecalhoPrincipalLoading = styled.div`
display:flex;
width:500px;
max-width:500px;
border-radius:5px;
height:17px;
min-height:17px;
margin-top:5px;
background:${props => `linear-gradient(to right, ${lighten(0.2, props.backgroundinamico)} 10%, ${lighten(0.2, props.backgroundinamico)} 28%, ${props.backgroundinamico} 33%)`};
animation:${animacaoLoading} 1.2s linear infinite;
  background-size: 400% 400%; 
`;
export const TextCabecalhoEmAtendimentoLoading = styled.div`
display:flex;
border-radius:5px;
flex:1;
height:17px;
min-height:17px;
margin-top:5px;
background:${props => `linear-gradient(to right, ${lighten(0.2, props.backgroundinamico)} 10%, ${lighten(0.2, props.backgroundinamico)} 28%, ${props.backgroundinamico} 33%)`};
animation:${animacaoLoading} 1.2s linear infinite;
  background-size: 400% 400%; 
`;

export const InputDescricaoChamadoLoading = styled.div`
display:flex;
height:40px;
min-height:40px;
flex:1;
margin:15px;
border:0;
padding:5px;
border-radius:4px;
background:${props => `linear-gradient(to right, ${lighten(0.2, props.backgroundinamico)} 10%, ${lighten(0.2, props.backgroundinamico)} 28%, ${props.backgroundinamico} 33%)`};
animation:${animacaoLoading} 1.2s linear infinite;
  background-size: 400% 400%; 
`;
export const CabecalhoPrincipalLoading = styled.div`
display:flex;
flex-direction:column;
height:75px;
min-height:75px;
padding:10px;

border-top-left-radius:5px;
background:${props => props.backgroundDinamico};
`;
export const ContainerChamadoLoading = styled.div`
display:flex;
 height:50px;
 min-height:50px;
width:95%;
z-index:2;
 flex-direction:row;
 border-radius:7px;
margin:5px;
background:${props => `linear-gradient(to right, ${lighten(0.2, props.backgroundinamico)} 10%, ${lighten(0.2, props.backgroundinamico)} 28%, ${props.backgroundinamico} 33%)`};
animation:${animacaoLoading} 1.2s linear infinite;
  background-size: 400% 400%; 
`;
export const TextTitleResposta = styled.p`
font-size:15px;
color:${props => props.corLetraDinamico};
font-weight:bold;
cursor:pointer;

`;
export const TextResposta = styled.span`
font-size:13px;
color:${props => props.corLetraDinamico};
cursor:pointer;
&:hover{
  color:${props => props.corLetraBotaoPadrao};
}
`;
export const DivRespostasPadroes = styled.div`
 display:flex;
justify-content:flex-start;
align-items:flex-start;
width:550px;

display: block;
 display: -webkit-box;
 padding: 5px;
 border-radius:5px;
 -webkit-line-clamp: 3;
 margin:0 auto;
 -webkit-box-orient: vertical;
 overflow: hidden;
 text-overflow: ellipsis;
 background:${props => props.backgrounddinamico};
 &:hover{
  background:${props => shade(0.2, props.backgrounddinamico)};
}
`;


export const ContainerMessageClient = styled.div`
  color: transparent;
  margin-left: 25%;
  background: linear-gradient(to bottom, #00D0EA 0%, #0085D1 100%);
  background-attachment: fixed;
  position: relative;
  padding:10px;
  margin:10px;
`;
export const MessageCliente = styled.div`
&::before{
  content: "";
  position: absolute;
  z-index: 0;
  bottom: 0;
  right: -8px;
  height: 20px;
  width: 20px;
  background: linear-gradient(to bottom, #00D0EA 0%, #0085D1 100%);
  background-attachment: fixed;
  border-bottom-left-radius: 15px;
}
&:after{
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: -10px;
  width: 10px;
  height: 20px;
  background: #CCC;
  border-bottom-left-radius: 10px;
};

`;
export const ContainerModalGravarAudio = styled.div`
 display:flex;
   width:400px;
  min-height:330px;
  overflow:auto;
   flex-direction:column;
   border-radius:7px;
   background:${props => props.backgroundDinamico};
   margin:0;
`;
export const TextGravandoAudio = styled.p`
font-size: 18px;
color: ${props => props.corDinamico};
font-weight:bold;
animation: ${bounce} 2s infinite;
text-align:center;
font-weight:200px;
font-family:Helvetica;
letter-spacing:3px;
`;
export const DivInputComentarChamado = styled.div`
 display:flex;
 flex:1;
justify-content:center;
align-items:flex-start;
flex-direction:column;
`;
export const InputDescricaoChamado2 = styled.textarea`
display:flex;
background-Color:transparent;
height:70px;
min-height:70px;
border:0;
padding:5px;
width:450px;
border-radius:4px;
border: 1px solid ${props => props.corLetraSecundaria};
color:${props => props.corLetraDinamico};
&::placeholder{
   color:#a8a8B3;
   
}
&:focus {
  border:1px solid ${props => props.corBordaDinamica};
  }
`;

export const ContainerInputEBotaoComent = styled.div`
 display:flex;
justify-content:center;
flex:1;
margin-top:40px;
flex-direction:column;
align-items:center;
`;
export const TabsModified = styled(Tabs)`
 /* overflow:auto;  */
flex:1;
&::-webkit-scrollbar {
  width: 3px;
  border-radius:7px;
}

/* Track */
&::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
&::-webkit-scrollbar-thumb {
  background:${props => shade(0.2, props.backgrounddinamico)};
  border-radius:7px;
}

/* Handle on hover */
&::-webkit-scrollbar-thumb:hover {
  background:${props => shade(0.2, props.backgrounddinamico)};
  border-radius:7px;
}
`;
export const TabListModified = styled(TabList)`
    display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
/*    overflow:auto;  */
`;
export const TabModified = styled(Tab).attrs({
  selectedClassName: 'selected',
  disabledClassName: 'disabled'
})`
display:flex;

   flex-grow: 1;
   font-size:13px;
   text-align: center;
   height:35px;
   min-height:35px;
   list-style: none;
   align-items:center;
   justify-content:center;
   cursor: pointer;
   color: #888;

   &:hover{
   background:rgba(255,255,255,0.2);
   border-radius:5px;
}
   &:first-child {
     border-left: none;
   }
 
   &.selected {
     /*  i{
         color:${props => props.coriconedinamicoselecionado};
      } */
      p{
    color:${props => props.coriconedinamicoselecionado};
   }
      color:${props => props.coriconedinamicoselecionado};
     border-bottom: 1px solid ${props => props.coriconedinamicoselecionado}
   }
 
   &.disabled {
     color: #e0e0e0;
     cursor: not-allowed;
   }
 `;
export const TabPanelModified = styled(TabPanel).attrs({ selectedClassName: 'selected' })`
  display: flex;
  flex-direction:column;
  display: none;
  &.selected {
  /*   display: flex; */
  display: block;
  }
  
`;
export const TextTab = styled.p`
color:${props => props.colorDinamico};
font-size:13px;
font-weight:bold;
`;
export const IconeTab = styled.i`
font-size:17px;
color:${props => props.colorDinamico};
`;
export const InputPesquisaConversa = styled.input`
display:flex;
border:0;
width:100%;
border-top-right-radius:15px;
border-bottom-right-radius:15px;
padding-left:10px;
color:${props => props.corLetraDinamico};
background: ${props => props.backgroundinamico};
&::placeholder{
   color:${props => props.corLetraDinamico};
}
`;
export const ContainerInputConversa = styled.div`
 display:flex;
 width:90%;
height:30px;
min-height:30px;
border:0;
border-radius:15px;
padding-left:10px;
 width:90%;
 flex-direction:row;
 color:${props => props.corLetraDinamico};
 align-items:center;
background: ${props => props.backgroundinamico};
`;
export const ContainerInputPesquisaConversaEncaminharMensagem = styled.div`
 display:flex;
 width:90%;
height:30px;
min-height:30px;
border:0;
border-radius:15px;
padding-left:10px;
 flex-direction:row;
 color:${props => props.corLetraDinamico};
 align-items:center;
background: ${props => props.backgroundinamico};
`;
export const DivPesquisaEncaminharMensagem = styled.div`
 display:flex;
 flex-direction:row;
 height:40px;
 min-height:40px;
 width:400px;
 background:${props => props.backgrounddinamico};
 align-items:center;
 justify-content:center;
`;

export const CheckBoxLabelRadio = styled.label`
position: absolute;
top: 0;
left: 0;
width: 18px;
height: 18px;
border-radius: 2px;
background: ${props => props.backgrounddinamico};
border:${props => `2px solid ${props.corLetraDinamico}`};

cursor: pointer;
/* &:disabled{
    background:#000;
  } */
/*  &::after {
  content: "";
  display: block;
  width: 14px;
  height: 14px;
  margin: 3px;
  background: transparent;
  box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
  transition: 0.2s;
}  */
`;
export const RadioCheck = styled.input`
  z-index: 2;
  width: 18px;
  height: 18px;
  min-height: 18px;
  cursor:pointer;
  background:transparent;
  &:checked + ${CheckBoxLabelRadio}  {
    cursor:pointer; 
     background-color:${props => props.backgroundinamico}; 
    border:0;
     &::after {
      content: "";
      display: block;
      width: 18px;
      height: 18px;
      min-height: 18px;
      margin-left: 21px;
      transition: 0.2s;
    } 
  
}
 &:disabled + ${CheckBoxLabelRadio}{
/*   background: linear-gradient(to top, #000 0px, #fff 100%) repeat scroll 0 0 rgba(0, 0, 0, 0); */
  /* &::-webkit-filter{
     opacity:0.4px;
 }; */
  } 
`;

export const ButtonEnviarMensagemEncaminhada = styled.button`
display:flex;
justify-content:center;
align-items:center;
background-color:${props => props.backgroundinamico};
color:${props => props.corLetraBotao};
letter-spacing: 1px;
font-size:21px;
font-weight:bold;
width:57px;
border:0;
transition: background-color 0.2s;
height:57px;
min-height:57px;
border-radius: 50%;
z-index:999;
&:hover{
   background: ${props => shade(0.2, props.backgroundinamico)};
}
margin-top:-60px;
`;

export const ImageLoadingPerfil = styled.div`
display:flex;
width:60px;
max-width:60px;
border-radius:50%;
height:60px;
min-height:60px;
margin-top:5px;
background:${props => `linear-gradient(to right, ${lighten(0.2, props.backgroundinamico)} 10%, ${lighten(0.2, props.backgroundinamico)} 28%, ${props.backgroundinamico} 33%)`};
animation:${animacaoLoading} 1.2s linear infinite;
  background-size: 400% 400%; 
`;
export const DivBotaoSalvarComentario = styled.div`
 display:flex;
justify-content:flex-end;
align-items:flex-end;
`;
export const ContainerModalConectarWpp = styled.div`
 display:flex;
   width:90vh;
  height:90vh;
  min-height:90vh;
  overflow:auto;
   flex-direction:column;
   border-radius:7px;
   background:${props => props.backgroundDinamico};
`;

export const InputMaskModied = styled.input`
display:flex;
background-Color:transparent;
height:25px;
min-height:25px;
width:100%;
border:0;
font-size:14px;
border-bottom: ${props => `1px solid ${props.colorDinamica}`};
color:${props => props.colorDinamica};

&::placeholder{
   color:${props => props.placeholderDinamico};
}
:focus +${IconeMostrarSenha}{
   border-bottom: ${props => `1px solid ${props.borderDinamico}`};
  }
  :focus{
   border-bottom: ${props => `1px solid ${props.borderDinamico}`};
  }
`;
export const InputCancelamento = styled.input`
display:flex;
background-Color:transparent;
flex:1;
height:40px;
min-height:40px;
border:0;
color:${props => props.corLetraDinamico};
border-radius:5px;
padding-left:5px;
width:350px;
max-width:350px;
border: ${props => ` 1px solid ${props.corLetraDinamico}`};
&::placeholder{
   color:#a8a8B3;
}
&:focus {
  border:1px solid ${props => props.corBordaDinamica};
  }
`;
export const DivTextoReabrirAtendimento = styled.div`
 display:flex;
 flex:1;
justify-content:center;
align-items:center;
`;

export const DivSelect = styled.div`
 display:flex;
 flex-direction:column;
 min-width:8px;
 max-width:8px;
 min-height: 55px;
 max-height: 55px;
 border-radius:2px;
  z-index:100;
  align-items:center;
  justify-content:center;
`;
export const DivSelectCabecalho = styled.div`
 display:flex;
 flex-direction:row;
 min-width:80px;
 max-width:80px;
 min-height: 15px;
 max-height: 15px;
 border-radius:2px;
  z-index:100;
  align-items:center;
  justify-content:center;
  margin-top:3px;
`;
export const ContainerDetalhesModalNovoAtendimento = styled.div`
 display:flex;
   flex-direction:column;
   align-items:flex-start;
   justify-content:flex-start;
`;
export const SubContainerNovoAtendimento = styled.div`
 display:flex;
 margin-top:20px;
   flex-direction:column;
align-items:flex-start;
justify-content:flex-start;
`;
export const InputDataVencimento = styled.input`
display:flex;
flex:1;
height:30px;
min-height:30px;
width:400px;
border-radius:5px;
padding-left:7px;
font-size:14px;
border:0;
color:${props => props.corLetraDinamico};
background-color: ${props => props.backgroundDinamico};
border:${props => `outset 0.5px ${props.corLetraDinamico}`};
cursor: pointer;
&::placeholder{
   color:${props => props.placeholderColor};
   font-style: italic;
}
&:hover{
  border:${props => `solid 0.5px ${props.corBordaDinamica}`};
 }
`;

export const IconeSetaPraBaixoDataVencimento = styled.i`
font-size: 12px;
position: absolute;
top:8px;
right: 52px;
width: 11px;
height: 11px;
min-height: 11px;
color:${props => props.colorDinamico};
pointer-events: none;
`;
export const DivBotaoNovoAtendimento = styled.div`
 display:flex;
 flex:1;
flex-direction:row;
justify-content:center;
align-items:flex-end;
margin:10px;
`;
export const BotaoCadastrarAtendimento = styled.button`
display:flex;
width:50%;
height:40px;
min-height:40px;
justify-content:center;
align-items:center;
border:0;
border-radius: 7px;
transition: background-color 0.2s;
background:${props => props.backgroundDinamico};
&:hover{
  background:${props => shade(0.2, props.backgroundDinamico)};
}
`;
export const TextCadastrar = styled.strong`
font-size:20px;
color:${props => props.corLetraDinamico};
font-weight:bold;
letter-spacing: 1px;
`;

export const ContainerInputDescricaoChamadoAlterChat = styled.div`
 display:flex;
   flex-direction:column;
   padding:10px;
`;

export const InputDescricaoChamadoAlterChat = styled.textarea`
display:flex;
background-Color:transparent;
height:55px;
min-height:55px;
border:0;
padding:5px;
width:400px;
border-radius:4px;
border: 1px solid ${props => props.corLetraSecundaria};
color:${props => props.corLetraDinamico};
&::placeholder{
   color:#a8a8B3;
   
}
&:focus {
  border:1px solid ${props => props.corBordaDinamica};
  }
`;


export const TextPrioridade = styled.p`
font-size: 6.5px; 
color: ${props => props.corLetraDinamica};
font-weight:bold;
`;
export const TextPrioridadeCabecalho = styled.p`
font-size: 12px; 
color: ${props => props.corLetraDinamica};
font-weight:bold;
letter-spacing:2px;
`;
