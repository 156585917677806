import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { modificaNomeRotaNoHeader } from '../../actions/HeaderAction';
import { toastr } from 'react-redux-toastr';
import ReactTooltip from "react-tooltip";
import firebase from '../../servicos/FirebaseConnection';
import { Modal } from 'react-responsive-modal';
import ClipLoader from "react-spinners/ClipLoader";
import axios from 'axios';
import { displayDateToLocale, downloadFile } from '../../servicos/utils';
import moment from 'moment';
import {
    Container, SubContainer, Content, Footer, Header, ContainerLink, ButtonExport, TextExport, ContainerExportDefault, ContainerLoading,
    ContainerDone, Title, ContainerDescriptions, ContainerTitle, Icon, ButtonCancelModal,
    Description, TextDownload, ContainerNewExport, ButtonDownload, DescriptionList, ButtonDownloadFooter,
    ContainerModal, ContainerIconeFecharModal, TextHeaderModal, IconeFechar, ContentModal, ContainerBotaoModal,
    ButtonModal, ContainerProgressBar, ContentProgressBar, ProgressBar, ContainerProgress, TextProgress
} from "./styled";

class ExportClients extends React.Component {
    state = {
        loading: false,
        status: 'initial', //done, in_progress, initial
        downloadLink: null,
        exportedAt: '',
        progress: 0,
        totalRows: 0,
        unsubscribe: null,
        modalExportarClientes: false
    }


    componentDidMount() {
        ReactTooltip.rebuild();

        if (this.props.permissoesAcessarTelasAtendente.permissaoAdministrador === true) {

            if (this.props.nomeRota !== 'Exportar Clientes') {
                this.props.modificaNomeRotaNoHeader('Exportar Clientes');
            }

        } else {
            toastr.error('Acesso Negado', 'Você não tem permissão para acessar esta tela');
            this.props.history.push('/dashboard');
        }

        this.getExports();

    }

    componentWillUnmount() {
        if (this.state.unsubscribe) {
            this.state.unsubscribe();
        }
    }

    async getExports() {
        try {



            const idCompany = JSON.parse(localStorage.getItem('chaveUnica'));
            this.setState({ loading: true });

            const unsubscribe = firebase.firestore().collection("export-clients").doc(idCompany).onSnapshot((doc) => {
                const result = doc.data();
                if (result) {
                    const status = !result.downloadLink ? 'in_progress' : 'done';
                    this.setState({
                        loading: status === 'in_progress', status,
                        downloadLink: result.downloadLink, exportedAt: result.exportedAt, progress: result.progress,
                        totalRows: result.totalRows
                    });
                } else {
                    this.setState({ loading: false, status: 'initial' });
                }
            });
            this.setState({ unsubscribe });
        } catch (err) {
            console.log('error export')
            console.log(err)
        }

    }

    async initExport() {
        this.setState({
            loading: true, status: 'in_progress',
            downloadLink: null, exportedAt: '', progress: 0,
            totalRows: 0
        });
        const idCompany = JSON.parse(localStorage.getItem('chaveUnica'));
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'Accept': 'application/json',
            },

            data: {},
            url: `https://drop-desk-old-cloud-run-by4wpwq5za-uc.a.run.app/api/clients/export/${idCompany}`,
        };
        await axios(options)

    }

    convertFiletoBlobAndDownload = async (fileUrl, name) => {
        const blob = await fetch(fileUrl).then(response => response.blob());
        const url = URL.createObjectURL(blob);
        downloadFile(url, name);
    }

    getCurrentDateAndHour() {
        let dataAtual = moment().toDate();
        dataAtual = moment(dataAtual).format('DD/MM/YYYY HH:mm');
        return dataAtual;
    }


    render() {
        return (
            <Container>
                <SubContainer backgroundDinamico={this.props.backgroundSegundo}>
                    <Header bordercolor={this.props.coresLetraPadrao}>

                        <ContainerLink>
                            <Link to="/clientes" style={{
                                display: 'flex',
                                textDecoration: 'none',
                                color: this.props.coresLetraPadrao,
                                fontWeight: 'bold',
                                backgroundColor: 'transparent',
                                border: 0,
                                fontSize: 19,
                                fontWeight: 'bold',
                                flexDirection: 'row'
                            }} >
                                <i className="icon-setavoltar" style={{ color: this.props.coresLetraPadrao, marginRight: 10, fontSize: 22, marginLeft: 10, fontWeight: 'bold' }} />
                                <p>Exportar Clientes</p>
                            </Link>
                        </ContainerLink>

                    </Header>

                    <Content>
                        {this.state.loading === false && this.state.status === 'initial' &&
                            <ContainerExportDefault>
                                <i className="icon-excel" style={{ color: this.props.coresLetraPadrao, fontSize: 110 }} />
                                <TextExport corLetraDinamica={this.props.coresLetraPadrao}>Deseja realmente exportar todos os dados dos seus clientes para arquivo EXCEL?</TextExport>
                            </ContainerExportDefault>
                        }

                        {this.state.status === 'in_progress' &&
                            <ContainerProgress>
                                <ContainerTitle>
                                    <Title corLetraDinamica={this.props.coresLetraPadrao}>Exportação em andamento ...</Title>
                                </ContainerTitle>
                                <ContainerProgressBar>
                                    <ContentProgressBar bordercolor={this.props.coresLetraPadrao}>
                                        <ProgressBar
                                            style={{
                                                width: `${(this.state.progress * 100)}%`
                                            }}
                                            color={this.props.corBotaoPadrao}
                                        />

                                    </ContentProgressBar>
                                    <TextProgress
                                        corLetraDinamica={this.props.coresLetraPadrao}>{`${(this.state.progress * 100).toFixed(0)}%`}
                                    </TextProgress>

                                </ContainerProgressBar>
                                <ContainerNewExport>
                                    <DescriptionList colorDinamico={this.props.coresLetraPadrao}>Enquanto a exportação está em andamento, você pode navegar em outras telas normalmente...</DescriptionList>
                                </ContainerNewExport>
                            </ContainerProgress>
                        }

                        {this.state.loading === false && this.state.status === 'done' &&
                            <ContainerDone>
                                <ContainerTitle>
                                    <Title corLetraDinamica={this.props.coresLetraPadrao}>Exportação feita com <span style={{ color: this.props.corBotaoPadrao }}>sucesso!</span></Title>
                                </ContainerTitle>
                                <ContainerDescriptions >
                                    <Description corLetraDinamica={this.props.coresLetraPadrao}>
                                        A exportação feita em {displayDateToLocale(this.state.exportedAt)} foi concluída com sucesso, para baixar o arquivo excel com todos os clientes clique no botão download.
                                    </Description>
                                    <ButtonDownload onClick={() => { this.convertFiletoBlobAndDownload(this.state.downloadLink, `clientes_exportacao_${this.getCurrentDateAndHour()}.xlsx`) }} backgroundbotao={this.props.corSegundoBotaoPadrao}>
                                        <Icon className="icon-excel" colorPrimario={this.props.letraBotaoPadrao} />
                                        <TextDownload colorDinamico={this.props.letraBotaoPadrao}>Download</TextDownload>
                                        <Icon className="icon-download" colorPrimario={this.props.letraBotaoPadrao} />
                                    </ButtonDownload>

                                    <ContainerNewExport>
                                        <DescriptionList colorDinamico={this.props.coresLetraPadrao}>ao realizar uma nova exportação, este arquivo será apagado.</DescriptionList>
                                        <DescriptionList colorDinamico={this.props.coresLetraPadrao}>  Para realizar uma nova exportação, clique em Exportar. </DescriptionList>
                                    </ContainerNewExport>
                                </ContainerDescriptions>
                            </ContainerDone>
                        }

                        {this.state.loading === true && this.state.status === 'initial' &&
                            <ContainerLoading corLetraDinamica={this.props.coresLetraPadrao}>
                                <ClipLoader
                                    css={{}}
                                    size={45}
                                    color={this.props.corBotaoPadrao}
                                    loading={true}
                                />
                                <p>Carregando...</p>
                            </ContainerLoading>
                        }

                    </Content>

                    <Footer bordercolor={this.props.coresLetraPadrao}>

                        {this.state.loading === false && this.state.status === 'done' &&
                            <ButtonDownloadFooter disabled={this.state.loading}
                                onClick={() => { this.convertFiletoBlobAndDownload(this.state.downloadLink, `clientes_exportacao_${this.getCurrentDateAndHour()}.xlsx`) }}
                                backgroundbotao={this.props.backgroundSegundo}
                                bordercolor={this.props.corBotaoPadrao}
                            >
                                Download
                            </ButtonDownloadFooter>}

                        <ButtonExport disabled={(this.state.loading || this.state.status === 'in_progress')}
                            onClick={() => {
                                if (this.state.status === 'done') {
                                    this.setState({ modalExportarClientes: true });
                                } else {
                                    this.initExport();
                                }
                            }} backgroundbotao={this.props.corBotaoPadrao}>
                            Exportar
                        </ButtonExport>
                    </Footer>
                </SubContainer >
                <Modal focusTrapped={false} showCloseIcon={false} open={this.state.modalExportarClientes} onClose={() => { }} center>
                    <ContainerModal backgroundDinamico={this.props.backgroundPrimeiro}>
                        <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                            <TextHeaderModal corLetraDinamico={this.props.coresLetraPadrao}>Exportar Clientes Excel</TextHeaderModal>
                            <div>
                                <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => { this.setState({ modalExportarClientes: false }); }} className="icon-close" />
                            </div>
                        </ContainerIconeFecharModal>
                        <ContentModal>
                            <p style={{ color: this.props.coresLetraPadrao, fontSize: 20, fontWheight: 'bold', margin: 15 }}>Deseja realmente excluir a exportação atual e iniciar uma nova exportação?</p>
                        </ContentModal>
                        <ContainerBotaoModal bordercolor={this.props.coresLetraPadrao}>
                            <ButtonCancelModal
                                onClick={() => {
                                    this.setState({ modalExportarClientes: false });
                                }}
                                backgroundbotao={this.props.backgroundPrimeiro}
                                bordercolor={this.props.corBotaoPadrao}
                            >
                                Cancelar
                            </ButtonCancelModal>
                            <ButtonModal backGroundDinamico={this.props.corBotaoPadrao} corLetraBotao={this.props.letraBotaoPadrao} type="button"
                                onClick={() => {
                                    this.initExport();
                                    this.setState({ modalExportarClientes: false });
                                }}>
                                Exportar
                            </ButtonModal>
                        </ContainerBotaoModal>
                    </ContainerModal>
                </Modal>
            </Container>

        )
    }

}

const mapStateToProps = state => {
    return (
        {
            backgroundPrimeiro: state.CoresPadraoSistema.backgroundPrimeiro,
            backgroundSegundo: state.CoresPadraoSistema.backgroundSegundo,
            backgroundMaisEscuro: state.CoresPadraoSistema.backgroundMaisEscuro,
            coresLetraPadrao: state.CoresPadraoSistema.coresLetraPadrao,
            corBotaoPadrao: state.CoresPadraoSistema.corBotaoPadrao,
            corSegundoBotaoPadrao: state.CoresPadraoSistema.corSegundoBotaoPadrao,
            coresLetraSecundario: state.CoresPadraoSistema.coresLetraSecundario,
            letraBotaoPadrao: state.CoresPadraoSistema.letraBotaoPadrao,
            corLetraDescricaoDosCampos: state.CoresPadraoSistema.corLetraDescricaoDosCampos,
            permissoesAcessarTelasAtendente: state.AtendenteReducer.permissoesAcessarTelasAtendente,
            nomeRota: state.HeaderReducer.nomeRota,
        }
    );
};
export default connect(mapStateToProps, {
    modificaNomeRotaNoHeader
})(withRouter(ExportClients));