import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import logoDropDesk from '../../imagens/logodropdesk2.png';
import iconeEmail from '../../icones/arroba.svg';
import iconePassword from '../../icones/password.svg';
import logo4 from '../../imagens/background4.jpg';
import ClipLoader from "react-spinners/ClipLoader";
import '../../fontello/css/fontello.css';
import firebase from '../../servicos/FirebaseConnection';
import SelectSearch from 'react-select-search';


import {
    modificaEmailLogin, modificaSenhaLogin, logarUsuario, modificaCorBordaInputEmailLogin, modificaCorBordaInputSenhaLogin,
    modificaErrorLoginTelaLogin, modificaErrorSenhaLogin, modificaCorIconeInputEmailLogin, modificaCorIconeInputSenhaLogin,
    modificaConfirmaSenha, modificaCompania, modificaNome,
    modificaCorIconeInputEmpresaCadastro, modificaCorBordaInputEmpresaCadastro,
    modificaCorIconeInputEmailCadastro, modificaCorBordaInputEmailCadastro,
    modificaCorIconeInputNomeCadastro, modificaCorBordaInputNomeCadastro,
    modificaCorIconeInputSenhaCadastro, modificaCorBordaInputSenhaCadastro,
    modificaCorIconeInputConfirmaSenhaCadastro, modificaCorBordaInputConfirmaSenhaCadastro,

    modificaMensagemErrorInputEmpresaCadastro, modificaMensagemErrorInputEmailCadastro,
    modificaMensagemErrorInputNomeCadastro, modificaMensagemErrorInputSenhaCadastro,
    modificaMensagemErrorInputConfirmaSenhaCadastro, cadastrarUsuario, ativaDesativaLoadingBotaoCadastrarTelaCadastraCompaniaExport,
    modificaSegmentoCadastro
} from '../../actions/LoginAction';

import {
    Container, TextLogin, ContainerLogin, TextEntre, InputEmail, ContainerTextLogin, ContainerInputs,
    InputSenha, SubContainer, ContainerButton, ButtonEsqueciSenha, ButtonEntrar, ButtonCriarConta,
    CheckManterConectado, ContainerConectarAutomaticamente, SubContainerInputSenha, SubContainerInputEmail,
    ContainerHeader, ContainerHeaderLogo, DivCelular, SubContainerInputEmpresa,
    SubContainerInputNome, SubContainerInputConfirma, DivLogoSetaVoltar, MarcaDagua,

    InputSearch, DivInputSearch, IconeSetaPraBaixo,
} from './styled'

class Cadastro extends React.Component {
    state = {
        checkBoxManterConectado: false,
        iconeEmailPressionadoCor: '#FFF',
        iconeSenhaPressionadoCor: '#FFF',
        email: '',
        senha: '',
        tipoInputSenha: 'password',
        tipoInputConfirmaSenha: 'password',
        isLoggedIn: false,
        userViaAnuncio: false
    }

    componentWillMount() {
        let paramskey = new URLSearchParams(this.props.history.location.search)
        let idUserAnuncio = paramskey.get('id')
        
        if (idUserAnuncio == null) {

        } else {
            this.setState({ userViaAnuncio: true })
        }
       // this.props.ativaDesativaLoadingBotaoCadastrarTelaCadastraCompaniaExport(true);
    }
    componentDidMount() {

      /*   let listener = firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                //  
                this.setState({
                    isLoggedIn: true
                })
                this.props.history.push(("/dashboard"));
                this.props.ativaDesativaLoadingBotaoCadastrarTelaCadastraCompaniaExport(false)
                listener();
            } else {
                //  
                this.setState({
                    isLoggedIn: false
                })
                this.props.ativaDesativaLoadingBotaoCadastrarTelaCadastraCompaniaExport(false)
                listener();
            }
        }) */
    }
    handleVisualizaSenha(value) {
        let state = this.state;
        state.tipoInputSenha = value;
        this.setState({ state });
    }
    handleVisualizaConfirmaSenha(value) {
        let state = this.state;
        state.tipoInputConfirmaSenha = value;
        this.setState({ state });
    }
    handleInputEmpresaBlur() {

        if (this.props.empresa.trim() === '') {
            this.props.modificaMensagemErrorInputEmpresaCadastro('Digite o nome da sua Empresa');
            this.props.modificaCorIconeInputEmpresaCadastro('red');
            this.props.modificaCorBordaInputEmpresaCadastro('red');
        } else if (this.props.empresa.trim().length < 2) {
            this.props.modificaMensagemErrorInputEmpresaCadastro('Sua empresa deve possuir mais de 1 letra');
            this.props.modificaCorIconeInputEmpresaCadastro('red');
            this.props.modificaCorBordaInputEmpresaCadastro('red');
        } else {
            this.props.modificaMensagemErrorInputEmpresaCadastro('');
            this.props.modificaCorIconeInputEmpresaCadastro(this.props.corBotaoPadrao);
            this.props.modificaCorBordaInputEmpresaCadastro('#FFF');

        }

    }
    handleInputEmpresaFocus() {
        if (this.props.textErrorInputEmpresaCadastro !== '') {

        } else {
            this.props.modificaCorBordaInputEmpresaCadastro(this.props.corBotaoPadrao);
            this.props.modificaCorIconeInputEmpresaCadastro(this.props.corBotaoPadrao);
        }
    }

    handleInputEmailBlur() {

        let emailValido = this.validarEmail(this.props.email);
        if (emailValido) {
            this.props.modificaMensagemErrorInputEmailCadastro('');
            this.props.modificaCorIconeInputEmailCadastro(this.props.corBotaoPadrao);
            this.props.modificaCorBordaInputEmailCadastro('#FFF');
        } else {
            this.props.modificaMensagemErrorInputEmailCadastro('Digite um e-mail válido');
            this.props.modificaCorIconeInputEmailCadastro('red');
            this.props.modificaCorBordaInputEmailCadastro('red');
        }

    }
    handleInputEmailFocus() {
        if (this.props.textErrorInputEmailCadastro !== '') {


        } else {
            this.props.modificaCorBordaInputEmailCadastro(this.props.corBotaoPadrao);
            this.props.modificaCorIconeInputEmailCadastro(this.props.corBotaoPadrao);
        }
    }
    handleInputNomeBlur() {


        if (this.props.nome.trim() === '') {
            this.props.modificaMensagemErrorInputNomeCadastro('Digite seu nome');
            this.props.modificaCorIconeInputNomeCadastro('red');
            this.props.modificaCorBordaInputNomeCadastro('red');
        } else if (this.props.nome.trim().length < 2) {
            this.props.modificaMensagemErrorInputNomeCadastro('Seu nome deve possuir mais de 1 letra');
            this.props.modificaCorIconeInputNomeCadastro('red');
            this.props.modificaCorBordaInputNomeCadastro('red');
        } else {
            this.props.modificaMensagemErrorInputNomeCadastro('');
            this.props.modificaCorIconeInputNomeCadastro(this.props.corBotaoPadrao);
            this.props.modificaCorBordaInputNomeCadastro('#FFF');

        }

    }
    handleInputNomeFocus() {
        if (this.props.textErrorInputNomeCadastro !== '') {

        } else {
            this.props.modificaCorBordaInputNomeCadastro(this.props.corBotaoPadrao);
            this.props.modificaCorIconeInputNomeCadastro(this.props.corBotaoPadrao);
        }
    }

    handleInputSenhaBlur() {
        if (this.props.senha.length >= 6) {
            this.props.modificaCorBordaInputSenhaCadastro('#FFF');
            this.props.modificaMensagemErrorInputSenhaCadastro('')
            this.props.modificaCorIconeInputSenhaCadastro(this.props.corBotaoPadrao)
        } else if (this.props.senha == '') {
            this.props.modificaMensagemErrorInputSenhaCadastro('Digite sua senha')
            this.props.modificaCorIconeInputSenhaCadastro('red');
            this.props.modificaCorBordaInputSenhaCadastro('red');
        }
        else {
            this.props.modificaMensagemErrorInputSenhaCadastro('Digite uma senha com mais de 6 digitos')
            this.props.modificaCorIconeInputSenhaCadastro('red');
            this.props.modificaCorBordaInputSenhaCadastro('red');

        }
    }
    handleInputSenhaFocus() {
        if (this.props.textErrorInputSenhaCadastro !== '') {

        } else {
            this.props.modificaCorBordaInputSenhaCadastro(this.props.corBotaoPadrao);
            this.props.modificaCorIconeInputSenhaCadastro(this.props.corBotaoPadrao);
        }

    }
    handleSubmit(e) {
        e.preventDefault();
        this.props.cadastrarUsuario(this.props.email, this.props.senha, this.props.confirmarSenha, this.props.empresa, this.props.nome, this.props.history, this.props.areaAtuacaoSelecionada, this.state.userViaAnuncio)
    }
    handleInputConfirmaSenhaBlur() {
        if (this.props.confirmarSenha.trim() === '') {
            this.props.modificaMensagemErrorInputConfirmaSenhaCadastro('Confirme sua senha')
            this.props.modificaCorIconeInputConfirmaSenhaCadastro('red');
            this.props.modificaCorBordaInputConfirmaSenhaCadastro('red');
        } else if (this.props.senha.trim() !== this.props.confirmarSenha.trim()) {
            this.props.modificaMensagemErrorInputConfirmaSenhaCadastro('Senhas diferentes, por favor verifique')
            this.props.modificaCorIconeInputConfirmaSenhaCadastro('red');
            this.props.modificaCorBordaInputConfirmaSenhaCadastro('red');
        } else {
            this.props.modificaCorBordaInputConfirmaSenhaCadastro('#FFF');
            this.props.modificaMensagemErrorInputConfirmaSenhaCadastro('')
            this.props.modificaCorIconeInputConfirmaSenhaCadastro(this.props.corBotaoPadrao)
        }

    }
    handleInputConfirmaSenhaFocus() {
        if (this.props.textErrorInputConfirmaSenhaCadastro !== '') {
        } else {
            this.props.modificaCorBordaInputConfirmaSenhaCadastro(this.props.corBotaoPadrao);
            this.props.modificaCorIconeInputConfirmaSenhaCadastro(this.props.corBotaoPadrao);
        }
    }

    validarEmail(email) {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    };

    render() {
        return (
            <Container>
                <MarcaDagua style={{
                    opacity: 0.05,
                    backgroundImage: `url(${logo4})`,
                    height: '100%',
                    width: '100%',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',

                }}>
                    {/*   <img src={logo4} alt="Background DropDesk" height="100%" width="100%" /> */}
                </MarcaDagua>
                <SubContainer>
                    <ContainerHeaderLogo>
                        <DivLogoSetaVoltar>
                            <img src={logoDropDesk} alt="Logo DropDesk" style={{ height: 65, width: 70 }} />

                            <Link style={{ fontSize: 15, textDecoration: 'none', color: '#FFF', fontWeight: 'bold' }} to="/"><i className="icon-setavoltar" style={{ color: '#CCC', marginRight: 5, fontSize: 17, marginLeft: 45, fontWeight: 'bold' }} />Voltar</Link>
                        </DivLogoSetaVoltar>
                        <DivCelular>
                            <i className="icon-chamadoematendimento2" style={{ color: '#FFF', marginRight: 5, fontSize: 18 }} />
                            <i className="icon-whatsapp" style={{ color: '#FFF', marginRight: 5, fontSize: 18 }} />
                            <p style={{ fontSize: 22, color: '#FFF' }}></p>
                        </DivCelular>
                    </ContainerHeaderLogo>
                    <ContainerHeader>
                        <ContainerLogin onSubmit={(e) => this.handleSubmit(e)}>

                            <ContainerTextLogin>
                                <TextLogin>Crie sua conta grátis</TextLogin>
                                <TextEntre>Precisamos apenas de alguns dados.</TextEntre>
                            </ContainerTextLogin>

                            <ContainerInputs>
                                <SubContainerInputEmpresa corBordaInputEmail={this.props.corBordaInputEmpresaCadastro}>
                                    <i className="icon-hotel1" style={{ color: this.props.corIconeInputEmpresaCadastro, marginRight: 5 }} />
                                    <InputEmail
                                        disabled={this.props.loadingBotaoCadastrarCompania}
                                        autoFocus
                                        type="text"
                                        placeholder="Nome Empresa"
                                        value={this.props.empresa}
                                        onChange={(e) => { this.props.modificaCompania(e.target.value) }}
                                        onFocus={() => { this.handleInputEmpresaFocus() }}
                                        onBlur={() => { this.handleInputEmpresaBlur() }}
                                    />
                                </SubContainerInputEmpresa>
                                {this.props.textErrorInputEmpresaCadastro !== '' && <p style={{ color: 'red', fontSize: 13, marginLeft: 10, fontStyle: 'italic' }}>{this.props.textErrorInputEmpresaCadastro}</p>}
                                <SubContainerInputNome corBordaInputEmail={this.props.corBordaInputNomeCadastro}>
                                    <i className="icon-setup" style={{ color: this.props.corIconeInputNomeCadastro, marginRight: 5 }} />
                                    <div style={{ flex: 1 }}>
                                        <SelectSearch
                                            renderValue={(valueProps) =>
                                                <DivInputSearch>
                                                    <InputSearch
                                                        corLetraDinamico='#FFF'
                                                        backgroundDinamico='#29454D'
                                                        placeholderColor='#a8a8B3'
                                                        corBordaDinamica='#00FF7F'
                                                        {...valueProps} ></InputSearch>
                                                    <IconeSetaPraBaixo className="icon-angle-arrow-down" colorDinamico={this.props.coresLetraPadrao} />
                                                </DivInputSearch>
                                            }
                                            value={this.props.areaAtuacaoSelecionada}
                                            options={this.props.areaAtuacao}
                                            onChange={(value) => { this.props.modificaSegmentoCadastro(value) }}
                                            placeholder={"Selecione o Segmento"}
                                            search={true}
                                        //  style={{ color: '#FFF' }} onChange={(value) => alert(value)} options={this.props.clientesSectionedConsulta} placeholder={this.props.clientesSectionedConsulta.length == 0 ? `Nenhum Cliente cadastrado, Por favor verifique!` : "Selecione o Cliente"} search={this.props.clientesSectionedConsulta.length == 0 ? false : true} 
                                        />
                                    </div>
                                </SubContainerInputNome>

                                <SubContainerInputEmail corBordaInputEmail={this.props.corBordaInputEmailCadastro}>
                                    <i className="icon-email" style={{ color: this.props.corIconeInputEmailCadastro, marginRight: 5 }} />
                                    <InputEmail
                                        disabled={this.props.loadingBotaoCadastrarCompania}
                                        type="text"
                                        placeholder="E-mail"
                                        value={this.props.email}
                                        onChange={(e) => { this.props.modificaEmailLogin(e.target.value) }}
                                        onFocus={() => { this.handleInputEmailFocus() }}
                                        onBlur={() => { this.handleInputEmailBlur() }}
                                    />
                                </SubContainerInputEmail>
                                {this.props.textErrorInputEmailCadastro !== '' && <p style={{ color: 'red', fontSize: 13, marginLeft: 10, fontStyle: 'italic' }}>{this.props.textErrorInputEmailCadastro}</p>}
                                <SubContainerInputNome corBordaInputEmail={this.props.corBordaInputNomeCadastro}>
                                    <i className="icon-user" style={{ color: this.props.corIconeInputNomeCadastro, marginRight: 5 }} />
                                    <InputEmail
                                        disabled={this.props.loadingBotaoCadastrarCompania}
                                        type="text"
                                        placeholder="Nome"
                                        value={this.props.nome}
                                        onChange={(e) => { this.props.modificaNome(e.target.value) }}
                                        onFocus={() => { this.handleInputNomeFocus() }}
                                        onBlur={() => { this.handleInputNomeBlur() }}
                                    />
                                </SubContainerInputNome>
                                {this.props.textErrorInputNomeCadastro !== '' && <p style={{ color: 'red', fontSize: 13, marginLeft: 10, fontStyle: 'italic' }}>{this.props.textErrorInputNomeCadastro}</p>}
                                <SubContainerInputSenha corBordaInputSenha={this.props.corBordaInputSenhaCadastro}>
                                    <i className="icon-senha" style={{ color: this.props.corIconeInputSenhaCadastro, marginRight: 5 }} />
                                    <InputSenha
                                        disabled={this.props.loadingBotaoCadastrarCompania}
                                        type={this.state.tipoInputSenha}
                                        placeholder="Senha"
                                        value={this.props.senha}
                                        onChange={(e) => { this.props.modificaSenhaLogin(e.target.value) }}
                                        onFocus={() => { this.handleInputSenhaFocus() }}
                                        onBlur={() => { this.handleInputSenhaBlur() }}
                                    />
                                    {this.state.tipoInputSenha == 'password' && <button type="button" style={{ backgroundColor: 'transparent', border: 0, height: 30 }} onClick={() => { this.handleVisualizaSenha('text') }}>
                                        <i className="icon-semvisao" style={{ color: '#FFF', fontSize: 17 }} />
                                    </button>}
                                    {this.state.tipoInputSenha == 'text' && <button type="button" style={{ backgroundColor: 'transparent', border: 0, height: 30 }} onClick={() => { this.handleVisualizaSenha('password') }}>
                                        <i className="icon-comvisao" style={{ color: '#FFF', fontSize: 17 }} />
                                    </button>}
                                </SubContainerInputSenha>
                                {this.props.textErrorInputSenhaCadastro !== '' && <p style={{ color: 'red', fontSize: 13, marginLeft: 10, fontStyle: 'italic' }}>{this.props.textErrorInputSenhaCadastro}</p>}
                                <SubContainerInputConfirma corBordaInputSenha={this.props.corBordaInputConfirmaSenhaCadastro}>
                                    <i className="icon-senha" style={{ color: this.props.corIconeInputConfirmaSenhaCadastro, marginRight: 5 }} />
                                    <InputSenha
                                        disabled={this.props.loadingBotaoCadastrarCompania}
                                        type={this.state.tipoInputConfirmaSenha}
                                        placeholder="Confirmar senha"
                                        value={this.props.confirmarSenha}
                                        onChange={(e) => { this.props.modificaConfirmaSenha(e.target.value) }}
                                        onFocus={() => { this.handleInputConfirmaSenhaFocus() }}
                                        onBlur={() => { this.handleInputConfirmaSenhaBlur() }}
                                    />
                                    {this.state.tipoInputConfirmaSenha == 'password' && <button type="button" style={{ backgroundColor: 'transparent', border: 0, height: 30 }} onClick={() => { this.handleVisualizaConfirmaSenha('text') }}>
                                        <i className="icon-semvisao" style={{ color: '#FFF', fontSize: 17 }} />
                                    </button>}
                                    {this.state.tipoInputConfirmaSenha == 'text' && <button type="button" style={{ backgroundColor: 'transparent', border: 0, height: 30 }} onClick={() => { this.handleVisualizaConfirmaSenha('password') }}>
                                        <i className="icon-comvisao" style={{ color: '#FFF', fontSize: 17 }} />
                                    </button>}
                                </SubContainerInputConfirma>
                                {this.props.textErrorInputConfirmaSenhaCadastro !== '' && <p style={{ color: 'red', fontSize: 13, marginLeft: 10, fontStyle: 'italic' }}>{this.props.textErrorInputConfirmaSenhaCadastro}</p>}

                                <ContainerButton>
                                    <ClipLoader
                                        css={{ margin: 10 }}
                                        size={40}
                                        color={"#00FF7F"}
                                        loading={this.props.loadingBotaoCadastrarCompania}
                                    />
                                    {this.props.loadingBotaoCadastrarCompania == false && <ButtonEntrar type="submit" onClick={(e) => this.handleSubmit(e)}>
                                        CADASTRAR
                                    </ButtonEntrar>}

                                </ContainerButton>

                            </ContainerInputs>

                        </ContainerLogin>

                    </ContainerHeader>
                    {/*  <input
                    style={{ height: 40, width: 150 }}
                    value={this.props.email}
                    onChange={(e) => { this.props.modificaEmailLogin(e.target.value) }}
                ></input>
                <p>{this.props.email}</p>
                <Link to="/dashboard">Dashboard</Link>
                <button>teste</button> */}
                </SubContainer>
            </Container>

        );
    }
}


const mapStateToProps = state => {
    return (
        {
            email: state.LoginReducer.email,
            senha: state.LoginReducer.senha,
            confirmarSenha: state.LoginReducer.confirmarSenha,
            empresa: state.LoginReducer.empresa,
            nome: state.LoginReducer.nome,

            mensagemDeErrorTelaLogin: state.LoginReducer.mensagemDeErrorTelaLogin,
            loadingBotaoLogarTelaLogin: state.LoginReducer.loadingBotaoLogarTelaLogin,
            statusConexao: state.LoginReducer.statusConexao,
            mensagemDeSucessoTelaCadastro: state.LoginReducer.mensagemDeSucessoTelaCadastro,
            verificaInternetLogin: state.LoginReducer.verificaInternetLogin,
            corBordaInputEmailLogin: state.LoginReducer.corBordaInputEmailLogin,
            corBordaInputSenhaLogin: state.LoginReducer.corBordaInputSenhaLogin,
            mensagemDeErrorSenhaTelaLogin: state.LoginReducer.mensagemDeErrorSenhaTelaLogin,
            corIconeInputEmailLogin: state.LoginReducer.corIconeInputEmailLogin,
            corIconeInputSenhaLogin: state.LoginReducer.corIconeInputSenhaLogin,

            backgroundPrimeiro: state.CoresPadraoSistema.backgroundPrimeiro,
            backgroundSegundo: state.CoresPadraoSistema.backgroundSegundo,
            backgroundMaisEscuro: state.CoresPadraoSistema.backgroundMaisEscuro,
            coresLetraPadrao: state.CoresPadraoSistema.coresLetraPadrao,
            corBotaoPadrao: state.CoresPadraoSistema.corBotaoPadrao,
            corSegundoBotaoPadrao: state.CoresPadraoSistema.corSegundoBotaoPadrao,

            corIconeInputEmpresaCadastro: state.LoginReducer.corIconeInputEmpresaCadastro,
            corBordaInputEmpresaCadastro: state.LoginReducer.corBordaInputEmpresaCadastro,

            corIconeInputEmailCadastro: state.LoginReducer.corIconeInputEmailCadastro,
            corBordaInputEmailCadastro: state.LoginReducer.corBordaInputEmailCadastro,

            corIconeInputNomeCadastro: state.LoginReducer.corIconeInputNomeCadastro,
            corBordaInputNomeCadastro: state.LoginReducer.corBordaInputNomeCadastro,

            corIconeInputSenhaCadastro: state.LoginReducer.corIconeInputSenhaCadastro,
            corBordaInputSenhaCadastro: state.LoginReducer.corBordaInputSenhaCadastro,

            corIconeInputConfirmaSenhaCadastro: state.LoginReducer.corIconeInputConfirmaSenhaCadastro,
            corBordaInputConfirmaSenhaCadastro: state.LoginReducer.corBordaInputConfirmaSenhaCadastro,


            textErrorInputEmpresaCadastro: state.LoginReducer.textErrorInputEmpresaCadastro,
            textErrorInputEmailCadastro: state.LoginReducer.textErrorInputEmailCadastro,
            textErrorInputNomeCadastro: state.LoginReducer.textErrorInputNomeCadastro,
            textErrorInputSenhaCadastro: state.LoginReducer.textErrorInputSenhaCadastro,
            textErrorInputConfirmaSenhaCadastro: state.LoginReducer.textErrorInputConfirmaSenhaCadastro,
            loadingBotaoCadastrarCompania: state.LoginReducer.loadingBotaoCadastrarCompania,

            areaAtuacaoSelecionada: state.LoginReducer.areaAtuacaoSelecionada,
            areaAtuacao: state.LoginReducer.areaAtuacao,
        }
    );
};
export default connect(mapStateToProps, {
    modificaEmailLogin, modificaSenhaLogin, logarUsuario, modificaCorBordaInputEmailLogin, modificaCorBordaInputSenhaLogin,
    modificaErrorLoginTelaLogin, modificaErrorSenhaLogin, modificaCorIconeInputEmailLogin, modificaCorIconeInputSenhaLogin,
    modificaConfirmaSenha, modificaCompania, modificaNome,
    modificaCorIconeInputEmpresaCadastro, modificaCorBordaInputEmpresaCadastro,
    modificaCorIconeInputEmailCadastro, modificaCorBordaInputEmailCadastro,
    modificaCorIconeInputNomeCadastro, modificaCorBordaInputNomeCadastro,
    modificaCorIconeInputSenhaCadastro, modificaCorBordaInputSenhaCadastro,
    modificaCorIconeInputConfirmaSenhaCadastro, modificaCorBordaInputConfirmaSenhaCadastro,
    modificaMensagemErrorInputEmpresaCadastro, modificaMensagemErrorInputEmailCadastro,
    modificaMensagemErrorInputNomeCadastro, modificaMensagemErrorInputSenhaCadastro,
    modificaMensagemErrorInputConfirmaSenhaCadastro, cadastrarUsuario, ativaDesativaLoadingBotaoCadastrarTelaCadastraCompaniaExport,
    modificaSegmentoCadastro
})(withRouter(Cadastro));
