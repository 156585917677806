import React from 'react';
import { connect } from 'react-redux';
import Avatar from "react-avatar";
import { Modal } from 'react-responsive-modal';
import { withRouter } from "react-router-dom";
import FlatList from 'flatlist-react';
import firebase from '../../servicos/FirebaseConnection';
import FlatListClientes from "./FlatListClientes";
import Autosuggest from 'react-autosuggest';
import ReactTooltip from "react-tooltip";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import styled from 'styled-components';
import _ from "lodash";
import './autoSugest.css';
import SelectSearch from 'react-select-search';
import { toastr } from 'react-redux-toastr';
import MoonLoader from "react-spinners/MoonLoader";
import HashLoader from "react-spinners/HashLoader";
import SelectSearchPaginate from "../SelectSearch/index";
import Dropzone from 'react-dropzone';
import * as XLSX from 'xlsx';
import imgTeste from '../../imagens/userPadrao.png';
import imglink from '../../imagens/link.png'
import imgunlink from '../../imagens/unlink.png';
import imgexcluirCliente from '../../imagens/delete-user.png';
import ClipLoader from "react-spinners/ClipLoader";
import { modificaPesquisaTeste, modificaNomeRotaNoHeader } from '../../actions/HeaderAction';
import {
    zeraMensagemSemInternetChamadoAberto, navegaTelaNovoChamado,
    modificaFiltroChamado, modificaSelectTextChamado, modificaPesquisaTextChamado,
    ativaLoadingTelaAberturaChamadoRemoveFiltro, modificaFiltroSelecionadoTelaChamadoAberto, modificaFiltroSelecionadoTelaChamadoCancelado,
    modificaFiltroSelecionadoTelaChamadoEmAtendimento, modificaFiltroSelecionadoTelaChamadoFechado, modificaFiltroSelecionadoTelaChamadoTodos,
    modificaFiltroClientesTelaClientes, alteraValorSwitch, modificaNomeClienteSelecionadoTelaAlterarClienteNovoCadastro,
    modificaEmpresaClienteTelaCadastroCliente, modificaNomeClienteTelaCadastroCliente, modificaEnderecoTelaCAdastroCliente, modificaTelefoneTelaCAdastroCliente,
    modificaEmailClienteTelaCadastroCliente, modificaSenhaClienteTelaCadastroCliente, modificaConfirmaClienteTelaCadastroCliente,
    mudaVisibilidadeModalExcluirCliente, alteraEmpresaSelecionadoTelaAlterarCliente, modificaNomeClienteSelecionadoTelaAlterarCliente,
    modificaVisibleModalNovoCLiente, consultaCliente2, listaClientesTelaConsultaClientes, consultaClienteComCliente,
    modificaClienteSelecionadoTelaConsultaCliente, modificaNomeClienteSelecionadoTelaConsultaCliente,
    alteraCliente, insereFotoAlterarCliente, modificaArquivoCompletoParaUpar, zeraInformacoesCadastroClienteExport,

    modificaEmpresaClienteTelaCadastroClienteNovoCadastro, modificaNomeClienteTelaCadastroClienteNovoCadastro, modificaEnderecoTelaCAdastroClienteNovoCadastro, modificaTelefoneTelaCAdastroClienteNovoCadastro, alteraValorSwitchNovoCadastro,
    modificaEmailClienteTelaCadastroClienteNovoCadastro, modificaSenhaClienteTelaCadastroClienteNovoCadastro, modificaConfirmaClienteTelaCadastroClienteNovoCadastro,
    insereFotoCadastroCliente, modificaArquivoCompletoParaUparNovoCadastro, alteraEmpresaSelecionadoTelaAlterarClienteNovoCadastro, cadastraCliente,
    excluiCliente, modificaRetornaTelaClienteParaNovoAtendimento, modificaVisibilidadeModalPortalCliente,
    modificaVisibilidadeModalImportarClientes, cadastraClienteEmMassa, pesquisaClientePorDigito,
    removeListaClientesFiltrados
} from "../../actions/ChamadoAction";
import { desligaOuvintesChamadosFirebase, modificaVisibilidadeModalExportarClientes, exportarClientesExcel } from "../../actions/DashBoardAction";

import {
    ContainerDadosCliente, Container, IconeSair, ContainerInputSearch, SubContainer, SubContainerCliente,
    ContainerImagemAlterarCliente, ContainerPrincipalDadosCliente, InputEmpresa, SubContainerClientePermissao,
    ContainerPermitir, TextPermitir, ContainerDadosLoginCliente, ContainerBotaoAlterar, ButtonEntrar,
    DivIconeExcluirCliente, IconeExcluirCliente, DivContainerInputSenha, IconeMostrarSenha,
    IconeEmpresaVinculada, DivContainerInputEmpresaVinculada, ContainerModal, ContainerIconeFecharModal,
    TextNovoAtendimento, IconeFechar, ContainerImagemVincularEmpresa, ContainerExplicacaoVincular,
    ButtonVincular, TextVincular, ImagePerfil, IconeEmpresaVinculadaOff, ContainerModalNovoCliente,
    ContainerDadosNovoCliente,
    InputSearch, DivInputSearch, IconeSetaPraBaixo,

    ContainerChamado, ContainerImagem, ContainerDadosChamado,
    ContaineDadosChamadosPrincipal,
    ContainerIconeEFrase, TextCliente,
    SubContainerIconeEFraseClienteAtendente,
    ContainerSeta, TextEmpresa, ImageLoading, ContainerChamadoLoading, TextClienteLoading, ImagemPerfilLoading,
    InputLoadingEmpresa, ContainerDadosClienteLoading, SubContainerClientePermissaoLoading, ContainerListaVazia,
    ContainerModalRespostas, ContainerTextCabecalhoModalAcoes, TextCabecalhoModalAcoes, ContainerAtendentes,
    ContainerImportarClientes, ButtonNext
} from './styled';

const languages = [
    {
        title: '1970s',
        languages: [
            {
                name: 'C',
                year: 1972
            }
        ]
    },
    {
        title: '1980s',
        languages: [
            {
                name: 'C++',
                year: 1983
            },
            {
                name: 'Perl',
                year: 1987
            }
        ]
    },
    {
        title: '1990s',
        languages: [
            {
                name: 'Haskell',
                year: 1990
            },
            {
                name: 'Python',
                year: 1991
            },
            {
                name: 'Java',
                year: 1995
            },
            {
                name: 'Javascript',
                year: 1995
            },
            {
                name: 'PHP',
                year: 1995
            },
            {
                name: 'Ruby',
                year: 1995
            }
        ]
    },
    {
        title: '2000s',
        languages: [
            {
                name: 'C#',
                year: 2000
            },
            {
                name: 'Scala',
                year: 2003
            },
            {
                name: 'Clojure',
                year: 2007
            },
            {
                name: 'Go',
                year: 2009
            }
        ]
    },
    {
        title: '2010s',
        languages: [
            {
                name: 'Elm',
                year: 2012
            }
        ]
    }
];
// https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions#Using_Special_Characters
function escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

function getSuggestions(value) {
    const escapedValue = escapeRegexCharacters(value.trim());

    if (escapedValue === '') {
        return [];
    }

    const regex = new RegExp('^' + escapedValue, 'i');

    return languages
        .map(section => {
            return {
                title: section.title,
                languages: section.languages.filter(language => regex.test(language.name))
            };
        })
        .filter(section => section.languages.length > 0);
}

function getSuggestionValue(suggestion) {
    return suggestion.name;
}

function renderSuggestion(suggestion) {
    return (
        <span>{suggestion.name}</span>
    );
}

function renderSectionTitle(section) {
    return (
        <strong>{section.title}</strong>
    );
}

function getSectionSuggestions(section) {
    return section.items;
}

class Clientes extends React.Component {
    state = {
        primeiraTelaImport: true,
        filtroTeste: false,
        hasMoreItems: false,
        listeenerFile: false,
        modalDesvinculaCliente: false,
        offset: 0,
        fotoCliente: imgTeste,
        search: '',
        inputFicticio: '',
        checkFicticio: false,
        maisDados: [],
        tipoInputSenha: 'password',
        tipoInputConfirmaSenha: 'password',
        loading: true,
        visaoInputSenha: false,
        visaoInputConfirmaSenha: false,
        pesquisaCliente: false,
        pesquisaClienteNovoCadastro: '',
        modalNovoCliente: false,
        modalPesquisaCliente: false,
        nomeArquivoAnexado: '',
        arrayClientesParaCAdastro: [],
        value: '',
        data: [],
        suggestions: [],
        clientSelected: null,
        clientSelectedNovoCliente: null,
        clientSelectedAlterarCliente: null,
    }

    componentDidMount() {
        //
        ReactTooltip.rebuild();
        if (this.props.permissoesAcessarTelasAtendente.permissaoAlterarCadastrarCliente == true) {
            this.props.consultaCliente2(0);
            this.props.listaClientesTelaConsultaClientes();

            if (this.props.nomeRota !== 'Clientes') {
                this.props.modificaNomeRotaNoHeader('Clientes')
            }
            /*  let EmpresaVinculada;
             if (this.props.clienteEmpresaPrincipal) {
                 EmpresaVinculada = false;
             } else {
                 if (this.props.empresaSelecionadasTelaAlterarCliente.length == 0) {
                     EmpresaVinculada = false;
                 } else {
                     EmpresaVinculada = true;
                 }
             }
             //EmpresaVinculada = (this.props.clienteSelecionadoConsultaClienteTelaCAdastraCliente.length == 0) ? false : true;
             this.setState({ pesquisaCliente: EmpresaVinculada })
              */
        } else {
            toastr.error('Acesso Negado', 'Você não tem permissão para acessar esta tela');
            this.props.history.push('/dashboard')
        }


        /* const input = document.getElementById('input');
         this.fileExcel = input.addListener(
            'change',
            () => this._fileExcel(),
        );  */
        /* input.addEventListener('change', () => {

        }) */
    }
    componentWillReceiveProps(nextProps) {

        let EmpresaVinculada;
        if (nextProps.clienteEmpresaPrincipal) {
            EmpresaVinculada = false;
        } else {
            if (nextProps.empresaSelecionadasTelaAlterarCliente.length == 0) {
                EmpresaVinculada = false;
            } else {
                EmpresaVinculada = true;
            }
        }
        //EmpresaVinculada = (this.props.clienteSelecionadoConsultaClienteTelaCAdastraCliente.length == 0) ? false : true;
        this.setState({ pesquisaCliente: EmpresaVinculada })
        if (this.props.keyCliente != nextProps.keyCliente) {

            // ReactTooltip.rebuild();
        }
        if (this.props.loadingCadastrandoClienteMassa != nextProps.loadingCadastrandoClienteMassa) {
            if (nextProps.loadingCadastrandoClienteMassa == true) {
                //
                let chaveUnica2 = JSON.parse(localStorage.getItem("chaveUnica"));
                const chaveUnica = chaveUnica2.replace(/"/g, "");
                //
                firebase.database().ref(`${chaveUnica}/clientes/`).orderByChild('excluido').equalTo(null).limitToLast(10).off("value");
            } else {
                //
                this.props.consultaCliente2(0);
            }
        }
        if (this.props.clienteSelecionadoTelaConsultaCliente !== nextProps.clienteSelecionadoTelaConsultaCliente) {
            //
            //
            //
            //
            let clienteAnterior = this.props.clienteSelecionadoTelaConsultaCliente;
            if (clienteAnterior.length > 0) {
                const chaveUnica2 = JSON.parse(localStorage.getItem('chaveUnica'));
                if (chaveUnica2) {
                    const chaveUnica = chaveUnica2.replace(/"/g, "");
                    //
                    firebase.database().ref(`${chaveUnica}/clientes/${clienteAnterior}`).off("value");
                    //
                } else {

                }
            }

        }
        setTimeout(() => {
            ReactTooltip.rebuild();
        }, 1000);

    }
    componentWillUnmount() {
        /*    this.fileExcel.remove(); */
        this.props.desligaOuvintesChamadosFirebase('clientes', '', '', '');
    }

    _toggleMostrarSenha() {
        this.setState({ visaoInputSenha: !this.state.visaoInputSenha });
    }
    _toggleSectionedPesquisaCliente() {
        this.setState({ pesquisaCliente: !this.state.pesquisaCliente });
    }
    _toggleSectionedPesquisaClienteNovoCadastro() {
        this.setState({ pesquisaClienteNovoCadastro: !this.state.pesquisaClienteNovoCadastro });
    }

    _toggleModalPesquisarCliente() {
        this.setState({ modalPesquisaCliente: !this.state.modalPesquisaCliente });
    }
    _toggleModalDesvincularCliente() {
        this.setState({ modalDesvinculaCliente: !this.state.modalDesvinculaCliente });
    }
    handleVisualizaSenha(value) {
        let state = this.state;
        state.tipoInputSenha = value;
        this.setState({ state });
    }
    handleVisualizaConfirmaSenha(value) {
        let state = this.state;
        state.tipoInputConfirmaSenha = value;
        this.setState({ state });
    }
    handleModalNovoCliente(value) {
        let state = this.state;
        state.modalNovoCliente = value;
        this.setState({ state });
    }
    updateSearch = search => {
        let state = this.state;
        state.search = search;
        this.setState({ state });

        // this.searchText(search)
    };
    handleInputExcel() {

    }
    removeClienteExcluidoDaConsulta(keyCliente, clientes) {


        let array = clientes.splice(_.findIndex(clientes, { value: keyCliente }), 1);


    }
    renderAreaEmailSenha() {
        if (this.props.valorSwitch) {
            return (
                <ContainerDadosLoginCliente>
                    {/* <div> */}
                    <p style={{ color: this.props.corLetraDescricaoDosCampos, fontWeight: 'bold', fontSize: 12 }}>Email<span style={{ color: 'red', fontSize: 14, marginLeft: 3 }}>*</span></p>
                    <InputEmpresa

                        type="text"
                        placeholder="Email"
                        colorDinamica={this.props.coresLetraPadrao}
                        placeholderDinamico={this.props.coresLetraSecundario}
                        borderDinamico={this.props.corBotaoPadrao}
                        value={this.props.emailCliente}
                        onChange={(e) => { this.props.modificaEmailClienteTelaCadastroCliente(e.target.value) }}
                    //  onFocus={() => { this.handleInputEmailFocus() }}
                    // onBlur={() => { this.handleInputEmailBlur() }}
                    />
                    <p style={{ color: this.props.corLetraDescricaoDosCampos, fontWeight: 'bold', fontSize: 12, marginTop: 5 }}>Senha<span style={{ color: 'red', fontSize: 14, marginLeft: 3 }}>*</span></p>
                    <DivContainerInputSenha>
                        <InputEmpresa

                            type={this.state.tipoInputSenha}
                            placeholder="Senha"
                            colorDinamica={this.props.coresLetraPadrao}
                            placeholderDinamico={this.props.coresLetraSecundario}
                            borderDinamico={this.props.corBotaoPadrao}
                            value={this.props.senhaCliente}
                            onChange={(e) => { this.props.modificaSenhaClienteTelaCadastroCliente(e.target.value) }}
                        //  onFocus={() => { this.handleInputEmailFocus() }}
                        // onBlur={() => { this.handleInputEmailBlur() }}
                        />
                        {this.state.tipoInputSenha == 'password' && < IconeMostrarSenha onClick={() => { this.handleVisualizaSenha('text') }} colorDinamico={this.props.coresLetraPadrao} bordaDinamica={this.props.corBotaoPadrao} className="icon-semvisao" />}
                        {this.state.tipoInputSenha == 'text' && < IconeMostrarSenha onClick={() => { this.handleVisualizaSenha('password') }} colorDinamico={this.props.coresLetraPadrao} bordaDinamica={this.props.corBotaoPadrao} className="icon-comvisao" />}
                    </DivContainerInputSenha>
                    <p style={{ color: this.props.corLetraDescricaoDosCampos, fontWeight: 'bold', fontSize: 12, marginTop: 5 }}>Confirmar Senha<span style={{ color: 'red', fontSize: 14, marginLeft: 3 }}>*</span></p>
                    <DivContainerInputSenha>
                        <InputEmpresa

                            type={this.state.tipoInputConfirmaSenha}
                            placeholder="Confirma Senha"
                            colorDinamica={this.props.coresLetraPadrao}
                            placeholderDinamico={this.props.coresLetraSecundario}
                            borderDinamico={this.props.corBotaoPadrao}
                            value={this.props.confirmaSenhaCliente}
                            onChange={(e) => { this.props.modificaConfirmaClienteTelaCadastroCliente(e.target.value) }}
                        //  onFocus={() => { this.handleInputEmailFocus() }}
                        // onBlur={() => { this.handleInputEmailBlur() }}
                        />
                        {this.state.tipoInputConfirmaSenha == 'password' && < IconeMostrarSenha onClick={() => { this.handleVisualizaConfirmaSenha('text') }} colorDinamico={this.props.coresLetraPadrao} bordaDinamica={this.props.corBotaoPadrao} className="icon-semvisao" />}
                        {this.state.tipoInputConfirmaSenha == 'text' && < IconeMostrarSenha onClick={() => { this.handleVisualizaConfirmaSenha('password') }} colorDinamico={this.props.coresLetraPadrao} bordaDinamica={this.props.corBotaoPadrao} className="icon-comvisao" />}
                    </DivContainerInputSenha>
                </ContainerDadosLoginCliente>
            )
        } else {
            return <ContainerDadosLoginCliente></ContainerDadosLoginCliente>
        }
    }
    renderAreaEmailSenhaNovoCAdastro() {
        if (this.props.valorSwitchNovoCadastro) {
            return (
                <ContainerDadosLoginCliente>
                    {/* <div> */}
                    <p style={{ color: this.props.corLetraDescricaoDosCampos, fontWeight: 'bold', fontSize: 12 }}>Email<span style={{ color: 'red', fontSize: 14, marginLeft: 3 }}>*</span></p>
                    <InputEmpresa

                        type="text"
                        placeholder="Email"
                        colorDinamica={this.props.coresLetraPadrao}
                        placeholderDinamico={this.props.coresLetraSecundario}
                        borderDinamico={this.props.corBotaoPadrao}
                        value={this.props.emailClienteNovoCadastro}
                        onChange={(e) => { this.props.modificaEmailClienteTelaCadastroClienteNovoCadastro(e.target.value) }}
                    //  onFocus={() => { this.handleInputEmailFocus() }}
                    // onBlur={() => { this.handleInputEmailBlur() }}
                    />
                    <p style={{ color: this.props.corLetraDescricaoDosCampos, fontWeight: 'bold', fontSize: 12, marginTop: 5 }}>Senha<span style={{ color: 'red', fontSize: 14, marginLeft: 3 }}>*</span></p>
                    <DivContainerInputSenha>
                        <InputEmpresa

                            type={this.state.tipoInputSenha}
                            placeholder="Senha"
                            colorDinamica={this.props.coresLetraPadrao}
                            placeholderDinamico={this.props.coresLetraSecundario}
                            borderDinamico={this.props.corBotaoPadrao}
                            value={this.props.senhaClienteNovoCadastro}
                            onChange={(e) => { this.props.modificaSenhaClienteTelaCadastroClienteNovoCadastro(e.target.value) }}
                        //  onFocus={() => { this.handleInputEmailFocus() }}
                        // onBlur={() => { this.handleInputEmailBlur() }}
                        />
                        {this.state.tipoInputSenha == 'password' && < IconeMostrarSenha onClick={() => { this.handleVisualizaSenha('text') }} colorDinamico={this.props.coresLetraPadrao} bordaDinamica={this.props.corBotaoPadrao} className="icon-semvisao" />}
                        {this.state.tipoInputSenha == 'text' && < IconeMostrarSenha onClick={() => { this.handleVisualizaSenha('password') }} colorDinamico={this.props.coresLetraPadrao} bordaDinamica={this.props.corBotaoPadrao} className="icon-comvisao" />}
                    </DivContainerInputSenha>
                    <p style={{ color: this.props.corLetraDescricaoDosCampos, fontWeight: 'bold', fontSize: 12, marginTop: 5 }}>Confirmar Senha<span style={{ color: 'red', fontSize: 14, marginLeft: 3 }}>*</span></p>
                    <DivContainerInputSenha>
                        <InputEmpresa

                            type={this.state.tipoInputConfirmaSenha}
                            placeholder="Confirma Senha"
                            colorDinamica={this.props.coresLetraPadrao}
                            placeholderDinamico={this.props.coresLetraSecundario}
                            borderDinamico={this.props.corBotaoPadrao}
                            value={this.props.confirmaSenhaClienteNovoCadastro}
                            onChange={(e) => { this.props.modificaConfirmaClienteTelaCadastroClienteNovoCadastro(e.target.value) }}
                        //  onFocus={() => { this.handleInputEmailFocus() }}
                        // onBlur={() => { this.handleInputEmailBlur() }}
                        />
                        {this.state.tipoInputConfirmaSenha == 'password' && < IconeMostrarSenha onClick={() => { this.handleVisualizaConfirmaSenha('text') }} colorDinamico={this.props.coresLetraPadrao} bordaDinamica={this.props.corBotaoPadrao} className="icon-semvisao" />}
                        {this.state.tipoInputConfirmaSenha == 'text' && < IconeMostrarSenha onClick={() => { this.handleVisualizaConfirmaSenha('password') }} colorDinamico={this.props.coresLetraPadrao} bordaDinamica={this.props.corBotaoPadrao} className="icon-comvisao" />}
                    </DivContainerInputSenha>
                </ContainerDadosLoginCliente>
            )
        } else {
            return <ContainerDadosLoginCliente></ContainerDadosLoginCliente>
        }
    }
    renderAreaEmailSenhaFicticio() {
        if (this.state.checkFicticio) {
            return (
                <ContainerDadosLoginCliente>
                    {/* <div> */}
                    <p style={{ color: this.props.corLetraDescricaoDosCampos, fontWeight: 'bold', fontSize: 12 }}>Email<span style={{ color: 'red', fontSize: 14, marginLeft: 3 }}>*</span></p>
                    <InputEmpresa
                        disabled={this.props.loadingBotaoAlterarCliente}
                        type="text"
                        placeholder="Email"
                        colorDinamica={this.props.coresLetraPadrao}
                        placeholderDinamico={this.props.coresLetraSecundario}
                        borderDinamico={this.props.corBotaoPadrao}
                        value={this.props.emailCliente}
                        onChange={(e) => { this.props.modificaEmailClienteTelaCadastroCliente(e.target.value) }}
                    //  onFocus={() => { this.handleInputEmailFocus() }}
                    // onBlur={() => { this.handleInputEmailBlur() }}
                    />
                    <p style={{ color: this.props.corLetraDescricaoDosCampos, fontWeight: 'bold', fontSize: 12, marginTop: 5 }}>Senha<span style={{ color: 'red', fontSize: 14, marginLeft: 3 }}>*</span></p>
                    <DivContainerInputSenha>
                        <InputEmpresa
                            disabled={this.props.loadingBotaoAlterarCliente}
                            type={this.state.tipoInputSenha}
                            placeholder="Senha"
                            colorDinamica={this.props.coresLetraPadrao}
                            placeholderDinamico={this.props.coresLetraSecundario}
                            borderDinamico={this.props.corBotaoPadrao}
                            value={this.props.senhaCliente}
                            onChange={(e) => { this.props.modificaSenhaClienteTelaCadastroCliente(e.target.value) }}
                        //  onFocus={() => { this.handleInputEmailFocus() }}
                        // onBlur={() => { this.handleInputEmailBlur() }}
                        />
                        {this.state.tipoInputSenha == 'password' && < IconeMostrarSenha onClick={() => { this.handleVisualizaSenha('text') }} colorDinamico={this.props.coresLetraPadrao} bordaDinamica={this.props.corBotaoPadrao} className="icon-semvisao" />}
                        {this.state.tipoInputSenha == 'text' && < IconeMostrarSenha onClick={() => { this.handleVisualizaSenha('password') }} colorDinamico={this.props.coresLetraPadrao} bordaDinamica={this.props.corBotaoPadrao} className="icon-comvisao" />}
                    </DivContainerInputSenha>
                    <p style={{ color: this.props.corLetraDescricaoDosCampos, fontWeight: 'bold', fontSize: 12, marginTop: 5 }}>Confirmar Senha<span style={{ color: 'red', fontSize: 14, marginLeft: 3 }}>*</span></p>
                    <DivContainerInputSenha>
                        <InputEmpresa
                            disabled={this.props.loadingBotaoAlterarCliente}
                            type={this.state.tipoInputConfirmaSenha}
                            placeholder="Confirma Senha"
                            colorDinamica={this.props.coresLetraPadrao}
                            placeholderDinamico={this.props.coresLetraSecundario}
                            borderDinamico={this.props.corBotaoPadrao}
                            value={this.props.confirmaSenhaCliente}
                            onChange={(e) => { this.props.modificaConfirmaClienteTelaCadastroCliente(e.target.value) }}
                        //  onFocus={() => { this.handleInputEmailFocus() }}
                        // onBlur={() => { this.handleInputEmailBlur() }}
                        />
                        {this.state.tipoInputConfirmaSenha == 'password' && < IconeMostrarSenha onClick={() => { this.handleVisualizaConfirmaSenha('text') }} colorDinamico={this.props.coresLetraPadrao} bordaDinamica={this.props.corBotaoPadrao} className="icon-semvisao" />}
                        {this.state.tipoInputConfirmaSenha == 'text' && < IconeMostrarSenha onClick={() => { this.handleVisualizaConfirmaSenha('password') }} colorDinamico={this.props.coresLetraPadrao} bordaDinamica={this.props.corBotaoPadrao} className="icon-comvisao" />}
                    </DivContainerInputSenha>
                </ContainerDadosLoginCliente>
            )
        } else {
            return <ContainerDadosLoginCliente></ContainerDadosLoginCliente>
        }
    }
    renderIconeEmpresaModal() {

        if (this.state.pesquisaClienteNovoCadastro == false) {

            return (
                <IconeEmpresaVinculada data-tip="Vincular este cliente á uma empresa já cadastrada" data-for="tip-top" colorDinamica={this.props.corBotaoPadrao} className="icon-hotel" onClick={() => { this._toggleModalPesquisarCliente() }} />
            )
        }

        else {
            return (
                <IconeEmpresaVinculada style={{ marginLeft: 5 }} data-tip="Desvincular Cliente" data-for="tip-top" colorDinamica="red" className="icon-close" onClick={() => { this._toggleModalDesvincularCliente() }} />
            )
        }
    }
    renderIconeEmpresa() {

        if (this.state.pesquisaCliente == false) {
            if (this.props.clienteEmpresaPrincipal) {
                return (
                    <IconeEmpresaVinculadaOff data-tip="Há Clientes Vinculados á essa Empresa, portanto essa Empresa NÃO pode ser Vinculada á nenhuma outra" data-for="tip-top" colorDinamica='#9fadb7' className="icon-hotel" onClick={() => { toastr.warning('Atenção', 'Há Clientes Vinculados á essa Empresa, portanto essa Empresa NÃO pode ser Vinculada á nenhuma outra') }} />
                )
            } else {
                return (
                    <IconeEmpresaVinculada data-tip="Vincular este cliente á uma empresa já cadastrada" data-for="tip-top" colorDinamica={this.props.corBotaoPadrao} className="icon-hotel" onClick={() => { this._toggleModalPesquisarCliente() }} />
                )
            }

        } else {
            return (
                <IconeEmpresaVinculada style={{ marginLeft: 5 }} data-tip="Desvincular Cliente" data-for="tip-top" colorDinamica="red" className="icon-close" onClick={() => { this._toggleModalDesvincularCliente() }} />
            )
        }
    }
    handleSubmit(e) {
        e.preventDefault();
        this.props.alteraCliente(this.props.nomeEmpresaCliente, this.props.nomeCliente, this.props.emailCliente, this.props.valorSwitch, this.props.senhaCliente, this.props.confirmaSenhaCliente, this.props.fotoImagemClienteTelaAlterar,
            this.props.keyCliente, this.state.pesquisaCliente, this.props.empresaSelecionadasTelaAlterarCliente,
            this.props.nomeEmpresaSelecionadoConsultaTelaAlterarCliente, this.props.clienteEmpresaPrincipal, this.props.telefoneCliente, this.props.enderecoCliente, this.props.arquivoCompletoParaUpar,
            this.props.configWhatsapp)
    }
    alterarClienteLocal(e) {
        e.preventDefault();
    }
    cadastraClienteLocal(e) {
        e.preventDefault();
        this.props.cadastraCliente(this.props.nomeEmpresaClienteNovoCadastro, this.props.nomeClienteNovoCadastro, this.props.emailClienteNovoCadastro, this.props.valorSwitchNovoCadastro, this.props.senhaClienteNovoCadastro, this.props.confirmaSenhaClienteNovoCadastro, this.props.uriImagemPadraoTelaCadastroCliente, this.props.premium, this.state.pesquisaClienteNovoCadastro, this.props.empresaSelecionadasTelaCadastraCliente,
            this.props.nomeClienteSelecionadoTelaCadastroCLiente, this.props.enderecoClienteNovoCadastro, this.props.telefoneClienteNovoCadastro, this.props.arquivoCompletoParaUparNovoCadastro, this.props.configWhatsapp);
        this.setState({ clientSelectedNovoCliente: null });
    }
    removeFiltrosAll() {
        // this.props.modificaClienteSelecionadoTelaConsultaCliente([]);
        this.setState({ clientSelected: null });
        this.props.modificaNomeClienteSelecionadoTelaConsultaCliente('');
        this.props.modificaFiltroClientesTelaClientes('')
        this.props.consultaCliente2(0, '', []);
    }
    exibeUltimosClientes() {
        //
        if (this.props.loadingTelaConsultaCliente == false) {
            if (this.props.nomeClienteSelecionadoTelaConsultaCliente == '') {
                this.props.consultaCliente2(1, this.props.referenciaUltimaKeyTelaConsultaCliente, this.props.clientes);
            }
        }
    }
    downloadExcelExemplo() {
        const a = document.createElement('a');
        //a.href = "https://firebasestorage.googleapis.com/v0/b/dark-4b307.appspot.com/o/IZY07H5Ec7bCuF5n2riRoZdvGon1%2F-MLwrFHQxF-8mtPEC-Jo%2F-MLwrFHQxF-8mtPEC-Jo%2Fexceldropdeskexemplo.xlsx?alt=media&token=7245ee43-8e27-4b55-9b2f-42d80257e0e4";
        a.href = "https://storage.googleapis.com/venom_install/Modelo%20Importac%CC%A7a%CC%83o%20Clientes.xlsx";
        a.download = 'Excel Exemplo DropDesk';
        a.click();
        window.URL.revokeObjectURL("https://storage.googleapis.com/venom_install/Modelo%20Importac%CC%A7a%CC%83o%20Clientes.xlsx");
    };
    escolheArquivoChamado(res) {
        if (res.length > 0) {
            //
            let uri;
            res.map(file => Object.assign(file, {
                uri: URL.createObjectURL(file)
            }));
            //
            this.props.modificaArquivoCompletoParaUpar(res[0])
            this.props.insereFotoAlterarCliente(res[0].uri);
        } else {
            toastr.error('Error ao Anexar', 'Selecione uma foto com no máximo 5mb');

        }
    }
    escolheArquivoChamadoNovoCadastro(res) {
        if (res.length > 0) {
            //
            let uri;
            res.map(file => Object.assign(file, {
                uri: URL.createObjectURL(file)
            }));
            //
            this.props.modificaArquivoCompletoParaUparNovoCadastro(res[0])
            this.props.insereFotoCadastroCliente(res[0].uri);
        } else {
            toastr.error('Error ao Anexar', 'Selecione uma foto com no máximo 5mb');

        }
    }
    validEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    };
    _insereNomeArquivoExcel(res) {
        if (res.length > 0) {
            try {
                /*      this.setState({ nomeArquivoAnexado: res[0].name }) */
                const reader = new FileReader();
                reader.onload = (evt) => { // evt = on_file_select event
                    /* Parse data */
                    const bstr = evt.target.result;
                    const wb = XLSX.read(bstr, { type: 'binary' });
                    /* Get first worksheet */
                    const wsname = wb.SheetNames[0];
                    const ws = wb.Sheets[wsname];
                    /* Convert array of arrays */
                    const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
                    /* Update state */
                    //
                    //
                    //
                    let colunas = [];
                    let linhas = [];
                    let objectPush = {};
                    let dadosConvertidos = [];
                    let aconteceuerror = false;
                    if (data.length > 51) {
                        alert('Error, Arquivo excel maior que o limite permitido, limite de 50 linhas.')
                    } else {


                        for (var i = 0; i < data.length; ++i) {
                            objectPush = {};
                            for (var j = 0; j < data[i].length; ++j) {
                                if (i == 0) {
                                    //
                                    //
                                    //se a coluna for maior do que a quantidade de colunas padrao, entao esta errado
                                    if (data[i].length > 4) {
                                        alert('Error, Quantidade de colunas maior do que o arquivo de exemplo, verifique o excel de exemplo e tenta novamente')
                                        aconteceuerror = true;
                                        break;
                                    }//se a coluna for menor do que a quantidade de colunas padrao, entao esta errado
                                    else if (data[i].length < 4) {
                                        alert('Error, Quantidade de colunas menor do que o arquivo de exemplo, verifique o excel de exemplo e tenta novamente')
                                        aconteceuerror = true;
                                        break;
                                    } else {
                                        //
                                        let arrayColunas = ['empresa', 'nome', 'endereco', 'telefone'];
                                        const isInArray = arrayColunas.includes(data[i][j]);
                                        //verifico os nome das colunas
                                        if (isInArray === true) {
                                            //
                                            //
                                            colunas.push(data[i][j])
                                        } else {
                                            //
                                            alert('Error, a coluna ' + (j + 1) + ' está com nome errado, verifique o excel de exemplo e tente novamente.')
                                            aconteceuerror = true;
                                            break;
                                        }
                                    }
                                } else {
                                    //
                                    //
                                    //
                                    //
                                    objectPush[colunas[j]] = (data[i][j] == undefined ? '' : data[i][j]);
                                }

                            }
                            if (i !== 0 && data[i].length > 0) {
                                linhas.push(objectPush)
                            }

                        }
                        //
                        if (linhas.length == 0) {
                            alert('Error, Arquivo excel vazio ou com formato inválido, verifique o arquivo excel de exemplo.')
                            //
                        } else {
                            //
                            if (aconteceuerror === false) {
                                //daqui verifico os campos antes de efetuar o cadastro
                                for (let i = 0; i < linhas.length; i++) {
                                    //
                                    if (linhas[i].empresa === undefined) {
                                        alert('ERROR!!! Informe o nome da empresa no campo "empresa" na linha  ' + (i + 2) + ',preencha conforme o excel de exemplo, faça a correção e envie o arquivo novamente.')
                                        aconteceuerror = true;
                                        break;
                                    } else if (linhas[i].nome === undefined) {
                                        alert('ERROR!!! Informe um nome no campo "nome" na linha  ' + (i + 2) + ',preencha conforme o excel de exemplo, faça a correção e envie o arquivo novamente.')
                                        aconteceuerror = true;
                                        break;
                                    } else {
                                        if (linhas[i].empresa.toString().length < 2) {
                                            alert('ERROR!!! "empresa" com tamanho menor que dois caracteres na linha ' + (i + 2) + ', faça a correção e envie o arquivo novamente.')
                                            aconteceuerror = true;
                                            break;
                                        }
                                        if (linhas[i].nome.toString().length < 2) {
                                            alert('ERROR!!! "nome" com tamanho menor que dois caracteres na linha ' + (i + 2) + ', faça a correção e envie o arquivo novamente.')
                                            aconteceuerror = true;
                                            break;
                                        }

                                        dadosConvertidos.push(linhas[i]);
                                    }
                                }
                            }
                            //
                            if (aconteceuerror === false) {
                                //
                                this.setState({ nomeArquivoAnexado: res[0].name, arrayClientesParaCAdastro: dadosConvertidos });
                                toastr.success('Arquivo anexado e processado com sucesso!');
                            }

                        }
                    }
                };
                reader.readAsBinaryString(res[0]);
            } catch (err) {
                alert(err)
            }
        } else {
            toastr.error('Error ao Anexar', 'Selecione um arquivo com no maximo 30 MB');

        }
    }
    renderImagem() {

        return (
            <Dropzone
                accept="image/*"
                multiple={false}
                noKeyboard
                maxSize={5e+6}
                noDrag
                onDrop={acceptedFiles => this.escolheArquivoChamado(acceptedFiles)}>
                {({ getRootProps, getInputProps, isDragActive }) => {

                    return (
                        /*     <section> */
                        <div  {...getRootProps()}>
                            <input {...getInputProps()} />
                            <Avatar
                                name={this.props.nomeCliente}
                                size='150'
                                src={this.props.fotoImagemClienteTelaAlterar}
                                maxInitials={2}
                                round
                                style={{ cursor: "pointer" }}
                            />
                            {/*   <ImagePerfil bordaDinamico={this.props.corBotaoPadrao} disabled={this.props.loadingBotaoAlterarCliente} src={this.props.fotoImagemClienteTelaAlterar} alt="foto cliente" style={{}} /> */}
                        </div>
                        /*   </section> */
                    )
                }}
            </Dropzone>
        )
    }
    onChange = (event, { newValue, method }) => {
        this.setState({
            value: newValue
        });
    };

    onSuggestionsFetchRequested = ({ value }) => {


        this.props.pesquisaClientePorDigito(value)
        /*  this.setState({
             suggestions: getSuggestions(value)
         }); */
    };

    onSuggestionsClearRequested = () => {
        this.props.removeListaClientesFiltrados([])
    };
    onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {

        this.props.consultaClienteComCliente(suggestion.value);
        this.props.desligaOuvintesChamadosFirebase('clientes', '', '', '');
    }
    renderLoadingClientes() {
        const CheckBoxWrapper = styled.div`
        position: relative;
        margin-left:10px;
      `;
        const CheckBoxLabel = styled.label`
        position: absolute;
        top: 0;
        left: 0;
        width: 42px;
        height: 23px;
        border-radius: 15px;
        background: ${this.props.coresLetraSecundario};
        cursor: pointer;
        &::after {
          content: "";
          display: block;
          border-radius: 50%;
          width: 18px;
          height: 18px;
          margin: 3px;
          background: #ffffff;
          box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
          transition: 0.2s;
        }
      `;
        const CheckBox = styled.input`
        opacity: 0;
        z-index: 1;
        border-radius: 15px;
        width: 42px;
        height: 26px;
        &:checked + ${CheckBoxLabel} {
          background: #00FF7F;
          &::after {
            content: "";
            display: block;
            border-radius: 50%;
            width: 18px;
            height: 18px;
            margin-left: 21px;
            transition: 0.2s;
          }
        }
      `;
        const { value, suggestions } = this.state;
        /*  const inputProps = {
             placeholder: "Type 'c'",
             value,
             onChange: this.onChange
         }; */
        if (this.props.loadingTelaConsultaCliente) {
            return (
                <Container>
                    <SubContainer style={{ flex: 0.5 }}>
                        <ContainerChamadoLoading backGroundDinamico={this.props.backgroundMaisEscuro}>
                            <ContainerImagem>
                                <ImageLoading backgroundinamico={this.props.backgroundMaisEscuro}></ImageLoading>
                                {/*  <img src={this.state.fotoCliente} alt="foto cliente" style={{ height: 40, width: 40, borderRadius: 50 }} /> */}
                            </ContainerImagem>
                            <ContainerDadosChamado>
                                <ContaineDadosChamadosPrincipal>
                                    <SubContainerIconeEFraseClienteAtendente >
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                    <SubContainerIconeEFraseClienteAtendente >
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                </ContaineDadosChamadosPrincipal>


                            </ContainerDadosChamado>


                        </ContainerChamadoLoading>
                        <ContainerChamadoLoading backGroundDinamico={this.props.backgroundMaisEscuro}>
                            <ContainerImagem>
                                <ImageLoading backgroundinamico={this.props.backgroundMaisEscuro}></ImageLoading>
                                {/*  <img src={this.state.fotoCliente} alt="foto cliente" style={{ height: 40, width: 40, borderRadius: 50 }} /> */}
                            </ContainerImagem>
                            <ContainerDadosChamado>
                                <ContaineDadosChamadosPrincipal>
                                    <SubContainerIconeEFraseClienteAtendente >
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                    <SubContainerIconeEFraseClienteAtendente >
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                </ContaineDadosChamadosPrincipal>


                            </ContainerDadosChamado>


                        </ContainerChamadoLoading>
                        <ContainerChamadoLoading backGroundDinamico={this.props.backgroundMaisEscuro}>
                            <ContainerImagem>
                                <ImageLoading backgroundinamico={this.props.backgroundMaisEscuro}></ImageLoading>
                                {/*  <img src={this.state.fotoCliente} alt="foto cliente" style={{ height: 40, width: 40, borderRadius: 50 }} /> */}
                            </ContainerImagem>
                            <ContainerDadosChamado>
                                <ContaineDadosChamadosPrincipal>
                                    <SubContainerIconeEFraseClienteAtendente >
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                    <SubContainerIconeEFraseClienteAtendente >
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                </ContaineDadosChamadosPrincipal>


                            </ContainerDadosChamado>


                        </ContainerChamadoLoading>
                        <ContainerChamadoLoading backGroundDinamico={this.props.backgroundMaisEscuro}>
                            <ContainerImagem>
                                <ImageLoading backgroundinamico={this.props.backgroundMaisEscuro}></ImageLoading>
                                {/*  <img src={this.state.fotoCliente} alt="foto cliente" style={{ height: 40, width: 40, borderRadius: 50 }} /> */}
                            </ContainerImagem>
                            <ContainerDadosChamado>
                                <ContaineDadosChamadosPrincipal>
                                    <SubContainerIconeEFraseClienteAtendente >
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                    <SubContainerIconeEFraseClienteAtendente >
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                </ContaineDadosChamadosPrincipal>


                            </ContainerDadosChamado>


                        </ContainerChamadoLoading>
                        <ContainerChamadoLoading backGroundDinamico={this.props.backgroundMaisEscuro}>
                            <ContainerImagem>
                                <ImageLoading backgroundinamico={this.props.backgroundMaisEscuro}></ImageLoading>
                                {/*  <img src={this.state.fotoCliente} alt="foto cliente" style={{ height: 40, width: 40, borderRadius: 50 }} /> */}
                            </ContainerImagem>
                            <ContainerDadosChamado>
                                <ContaineDadosChamadosPrincipal>
                                    <SubContainerIconeEFraseClienteAtendente >
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                    <SubContainerIconeEFraseClienteAtendente >
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                </ContaineDadosChamadosPrincipal>


                            </ContainerDadosChamado>


                        </ContainerChamadoLoading>
                        <ContainerChamadoLoading backGroundDinamico={this.props.backgroundMaisEscuro}>
                            <ContainerImagem>
                                <ImageLoading backgroundinamico={this.props.backgroundMaisEscuro}></ImageLoading>
                                {/*  <img src={this.state.fotoCliente} alt="foto cliente" style={{ height: 40, width: 40, borderRadius: 50 }} /> */}
                            </ContainerImagem>
                            <ContainerDadosChamado>
                                <ContaineDadosChamadosPrincipal>
                                    <SubContainerIconeEFraseClienteAtendente >
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                    <SubContainerIconeEFraseClienteAtendente >
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                </ContaineDadosChamadosPrincipal>


                            </ContainerDadosChamado>


                        </ContainerChamadoLoading>
                        <ContainerChamadoLoading backGroundDinamico={this.props.backgroundMaisEscuro}>
                            <ContainerImagem>
                                <ImageLoading backgroundinamico={this.props.backgroundMaisEscuro}></ImageLoading>
                                {/*  <img src={this.state.fotoCliente} alt="foto cliente" style={{ height: 40, width: 40, borderRadius: 50 }} /> */}
                            </ContainerImagem>
                            <ContainerDadosChamado>
                                <ContaineDadosChamadosPrincipal>
                                    <SubContainerIconeEFraseClienteAtendente >
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                    <SubContainerIconeEFraseClienteAtendente >
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                </ContaineDadosChamadosPrincipal>


                            </ContainerDadosChamado>


                        </ContainerChamadoLoading>
                    </SubContainer>

                    <ContainerPrincipalDadosCliente backGroundDinamico={this.props.backgroundSegundo}>
                        <SubContainerCliente>
                            <ContainerImagemAlterarCliente>
                                <ImagemPerfilLoading backgroundinamico={this.props.backgroundSegundo}></ImagemPerfilLoading>
                                {/*   <ImagePerfil onClick={() => { alert('trocar imagem') }} src={this.state.fotoCliente} alt="foto cliente" style={{}} /> */}
                            </ContainerImagemAlterarCliente>
                            <ContainerDadosClienteLoading>

                                <InputLoadingEmpresa backgroundinamico={this.props.backgroundSegundo}></InputLoadingEmpresa>
                                <InputLoadingEmpresa backgroundinamico={this.props.backgroundSegundo}></InputLoadingEmpresa>
                                <InputLoadingEmpresa backgroundinamico={this.props.backgroundSegundo}></InputLoadingEmpresa>
                                <InputLoadingEmpresa backgroundinamico={this.props.backgroundSegundo}></InputLoadingEmpresa>

                            </ContainerDadosClienteLoading>
                        </SubContainerCliente>
                        <SubContainerClientePermissaoLoading>
                            <InputLoadingEmpresa backgroundinamico={this.props.backgroundSegundo}></InputLoadingEmpresa>
                            <InputLoadingEmpresa backgroundinamico={this.props.backgroundSegundo}></InputLoadingEmpresa>
                            <InputLoadingEmpresa backgroundinamico={this.props.backgroundSegundo}></InputLoadingEmpresa>
                            <InputLoadingEmpresa backgroundinamico={this.props.backgroundSegundo}></InputLoadingEmpresa>
                        </SubContainerClientePermissaoLoading>
                    </ContainerPrincipalDadosCliente>

                </Container>
            )
        } else {
            return (
                <Container>
                    <SubContainer style={{ flex: this.props.clientes.length > 0 ? 0.5 : 1 }} >
                        {this.props.filtroClientes !== '' &&
                            <div style={{ justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                <p style={{ marginLeft: 40, color: this.props.coresLetraPadrao }}>Clientes:</p>
                                <ContainerInputSearch backGroundDinamico='#FFF'>
                                    <IconeSair className="icon-close" onClick={() => { this.removeFiltrosAll(); }} />
                                    <div style={{ flex: 1 }}>
                                        {this.props.clientesSectionedConsulta.length === 0 && <SelectSearchPaginate
                                            onSelectClient={(client) => {
                                                console.log(client);
                                                this.setState({ clientSelected: { value: client.value, label: client.label } });
                                                //this.props.modificaClienteSelecionadoTelaConsultaCliente(client.value);
                                                this.props.consultaClienteComCliente(client.value);
                                                this.props.desligaOuvintesChamadosFirebase('clientes', '', '', '');

                                            }}
                                            value={this.state.clientSelected}
                                            isMyContact={false}
                                            background={this.props.backgroundMaisEscuro}
                                            letterColor={this.props.coresLetraPadrao}
                                            backgroundDinamico={this.props.backgroundMaisEscuro}
                                            placeholderColor={this.props.coresLetraSecundario}
                                            corBordaDinamica={this.props.corBotaoPadrao}
                                            width={280}
                                            letraBotaoPadrao={this.props.letraBotaoPadrao}
                                        />}
                                        {this.props.clientesSectionedConsulta.length > 0 && <SelectSearch
                                            renderValue={(valueProps) =>
                                                <DivInputSearch>
                                                    <InputSearch
                                                        corLetraDinamico={this.props.coresLetraPadrao}
                                                        backgroundDinamico={this.props.backgroundMaisEscuro}
                                                        placeholderColor={this.props.corLetraSecundaria}
                                                        corBordaDinamica={this.props.corBotaoPadrao}
                                                        {...valueProps} ></InputSearch>
                                                    <IconeSetaPraBaixo className="icon-angle-arrow-down" colorDinamico={this.props.coresLetraPadrao} />
                                                </DivInputSearch>
                                            }
                                            value={this.props.clienteSelecionadoTelaConsultaCliente}
                                            options={this.props.clientesSectionedConsulta}
                                            onChange={(value) => {
                                                this.props.modificaClienteSelecionadoTelaConsultaCliente(value);
                                                this.props.consultaClienteComCliente(value);
                                                this.props.desligaOuvintesChamadosFirebase('clientes', '', '', '');
                                            }}
                                            placeholder={this.props.clientesSectionedConsulta.length == 0 ? `Nenhum Cliente cadastrado` : "Selecione o Cliente"}
                                            search={this.props.clientesSectionedConsulta.length == 0 ? false : true}
                                        />}
                                    </div>
                                </ContainerInputSearch>
                            </div>}

                        {/*    <div style={{display:'flex',flex:1,flexDirection:'column',width:'100%',padding:10,overflow:'auto'}}>     */}
                        <FlatList
                            list={this.props.clientes}
                            renderItem={(item) => <FlatListClientes data={item} key={item.key} onClickClient={(item) => {
                                this.setState({ clientSelectedAlterarCliente: { value: item.key, label: item.empresa } });
                            }} />}

                            renderWhenEmpty={() => {
                                return (
                                    <ContainerListaVazia>
                                        <i className="icon-box" style={{ color: this.props.coresLetraPadrao, fontSize: 110 }} />
                                        <p style={{ color: this.props.coresLetraPadrao, fontSize: 20 }}>Nenhum Cliente Cadastrado</p>
                                    </ContainerListaVazia>
                                )

                            }}
                            hasMoreItems={this.props.loadingFimDaPAginaTelaConsultaCliente}
                            loadMoreItems={() => this.exibeUltimosClientes()}
                            paginationLoadingIndicator={<MoonLoader
                                css={{ margin: 10 }}
                                size={40}
                                color={this.props.corBotaoPadrao}
                                loading={this.props.loadingFimDaPAginaTelaConsultaCliente}
                            />}

                        //sortBy={["firstName", { key: "lastName", descending: true }]}
                        // groupBy={person => person.info.age > 18 ? 'Over 18' : 'Under 18'}
                        />
                        {/*     </div>    */}
                    </SubContainer>
                    {this.props.modalNovoCliente == false && this.props.clientes.length > 0 && <ContainerPrincipalDadosCliente onSubmit={(e) => this.handleSubmit(e)} backGroundDinamico={this.props.backgroundSegundo}>

                        {this.props.modalNovoCliente == false && <SubContainerCliente>

                            <ContainerImagemAlterarCliente>
                                {this.renderImagem()}
                            </ContainerImagemAlterarCliente>
                            <ContainerDadosCliente>
                                {this.props.clienteEmpresaPrincipal == false && <DivIconeExcluirCliente>
                                    <IconeExcluirCliente disabled={this.props.loadingBotaoAlterarCliente} onClick={() => { this.props.mudaVisibilidadeModalExcluirCliente(true) }} data-tip="Excluir Cliente" data-for="tip-top" className="icon-rubbish-bin" colorDinamico={this.props.coresLetraPadrao} />
                                </DivIconeExcluirCliente>}
                                {this.props.clienteEmpresaPrincipal == true &&
                                    <DivIconeExcluirCliente>
                                        <IconeExcluirCliente onClick={() => { toastr.warning('Atenção', 'Há Clientes Vinculados á essa Empresa, portanto essa Empresa NÃO pode ser Excluída') }} data-tip="Há Clientes Vinculados á essa Empresa, portanto essa Empresa NÃO pode ser Excluída" data-for="tip-top" className="icon-rubbish-bin" colorDinamico="#9fadb7" />
                                    </DivIconeExcluirCliente>
                                }
                                <p style={{ color: this.props.corLetraDescricaoDosCampos, fontWeight: 'bold', fontSize: 12 }}>Nome Empresa<span style={{ color: 'red', fontSize: 14, marginLeft: 3 }}>*</span></p>
                                <DivContainerInputEmpresaVinculada>
                                    {this.state.pesquisaCliente == false && <InputEmpresa
                                        disabled={this.props.loadingBotaoAlterarCliente}
                                        autoFocus
                                        type="text"
                                        placeholder="Nome da Empresa"
                                        colorDinamica={this.props.coresLetraPadrao}
                                        placeholderDinamico={this.props.coresLetraSecundario}
                                        borderDinamico={this.props.corBotaoPadrao}
                                        value={this.props.nomeEmpresaCliente}
                                        onChange={(e) => { this.props.modificaEmpresaClienteTelaCadastroCliente(e.target.value) }}
                                    //  onFocus={() => { this.handleInputEmailFocus() }}
                                    // onBlur={() => { this.handleInputEmailBlur() }}
                                    />}
                                    {this.state.pesquisaCliente == true &&
                                        <div style={{ justifyContent: 'center', alignItems: 'center', flex: 1 }}>

                                            <div style={{ borderRadius: 3, height: 30, flex: 1 }}>

                                                {this.props.listaEmpresasTelaAlterarCliente.length === 0 && <SelectSearchPaginate
                                                    onSelectClient={(client) => {
                                                        console.log(client)
                                                        this.setState({ clientSelectedAlterarCliente: { value: client.value, label: client.label } });
                                                        this.props.alteraEmpresaSelecionadoTelaAlterarCliente(client.value);
                                                        this.props.modificaNomeClienteSelecionadoTelaAlterarCliente(client.empresa);
                                                    }}
                                                    value={this.state.clientSelectedAlterarCliente}
                                                    isMyContact={false}
                                                    background={this.props.backgroundMaisEscuro}
                                                    letterColor={this.props.coresLetraPadrao}
                                                    backgroundDinamico={this.props.backgroundMaisEscuro}
                                                    placeholderColor={this.props.coresLetraSecundario}
                                                    corBordaDinamica={this.props.corBotaoPadrao}
                                                    width={'100%'}
                                                    letraBotaoPadrao={this.props.letraBotaoPadrao}
                                                    placeHolder={"Selecione a empresa"}
                                                    isClientLinked={true}
                                                />}
                                                {this.props.listaEmpresasTelaAlterarCliente.length > 0 && <SelectSearch
                                                    renderValue={(valueProps) =>
                                                        <DivInputSearch>
                                                            <InputSearch
                                                                corLetraDinamico={this.props.coresLetraPadrao}
                                                                backgroundDinamico={this.props.backgroundMaisEscuro}
                                                                placeholderColor={this.props.corLetraSecundaria}
                                                                corBordaDinamica={this.props.corBotaoPadrao}
                                                                {...valueProps} ></InputSearch>
                                                            <IconeSetaPraBaixo className="icon-angle-arrow-down" colorDinamico={this.props.coresLetraPadrao} />
                                                        </DivInputSearch>
                                                    }
                                                    disabled={this.props.loadingBotaoAlterarCliente}
                                                    value={this.props.empresaSelecionadasTelaAlterarCliente}
                                                    options={this.props.listaEmpresasTelaAlterarCliente}
                                                    onChange={(value, option) => { this.props.alteraEmpresaSelecionadoTelaAlterarCliente(value); this.props.modificaNomeClienteSelecionadoTelaAlterarCliente(option.name); }}
                                                    placeholder={this.props.listaEmpresasTelaAlterarCliente.length == 0 ? `Nenhum Empresa cadastrada` : "Selecione a Empresa"} search={this.props.listaEmpresasTelaAlterarCliente.length == 0 ? false : true}
                                                />}
                                            </div>
                                        </div>
                                    }
                                    {this.renderIconeEmpresa()}
                                </DivContainerInputEmpresaVinculada>
                                {/*  </div> */}
                                <p style={{ color: this.props.corLetraDescricaoDosCampos, fontWeight: 'bold', fontSize: 12, marginTop: 5 }}>Nome Cliente<span style={{ color: 'red', fontSize: 14, marginLeft: 3 }}>*</span></p>
                                <InputEmpresa
                                    disabled={this.props.loadingBotaoAlterarCliente}
                                    type="text"
                                    placeholder="Nome do Cliente"
                                    colorDinamica={this.props.coresLetraPadrao}
                                    placeholderDinamico={this.props.coresLetraSecundario}
                                    borderDinamico={this.props.corBotaoPadrao}
                                    value={this.props.nomeCliente}
                                    onChange={(e) => { this.props.modificaNomeClienteTelaCadastroCliente(e.target.value) }}
                                //  onFocus={() => { this.handleInputEmailFocus() }}
                                // onBlur={() => { this.handleInputEmailBlur() }}
                                />
                                <p style={{ color: this.props.corLetraDescricaoDosCampos, fontWeight: 'bold', fontSize: 12, marginTop: 5 }}>Endereço</p>
                                <InputEmpresa
                                    disabled={this.props.loadingBotaoAlterarCliente}
                                    type="text"
                                    placeholder="Endereço (opcional)"
                                    colorDinamica={this.props.coresLetraPadrao}
                                    placeholderDinamico={this.props.coresLetraSecundario}
                                    borderDinamico={this.props.corBotaoPadrao}
                                    value={this.props.enderecoCliente}
                                    onChange={(e) => { this.props.modificaEnderecoTelaCAdastroCliente(e.target.value) }}
                                //  onFocus={() => { this.handleInputEmailFocus() }}
                                // onBlur={() => { this.handleInputEmailBlur() }}
                                />
                                <p style={{ color: this.props.corLetraDescricaoDosCampos, fontWeight: 'bold', fontSize: 12, marginTop: 5 }}>Telefone {this.props.configWhatsapp.length > 0 && <span style={{ color: 'orange', marginleft: 15 }}>(55) 21 98888 - 8888</span>}</p>
                                <InputEmpresa
                                    disabled={this.props.loadingBotaoAlterarCliente}
                                    type="text"
                                    placeholder="Telefone (opcional)"
                                    colorDinamica={this.props.coresLetraPadrao}
                                    placeholderDinamico={this.props.coresLetraSecundario}
                                    borderDinamico={this.props.corBotaoPadrao}
                                    value={this.props.telefoneCliente}
                                    onChange={(e) => { this.props.modificaTelefoneTelaCAdastroCliente(e.target.value.replace(/\D/, '')) }}
                                //  onFocus={() => { this.handleInputEmailFocus() }}
                                // onBlur={() => { this.handleInputEmailBlur() }}
                                />
                            </ContainerDadosCliente>
                        </SubContainerCliente>}
                        {this.props.modalNovoCliente == false && <SubContainerClientePermissao>
                            <ContainerPermitir>
                                <TextPermitir corLetraDinamica={this.props.corLetraDescricaoDosCampos}>Permitir o cliente abrir atendimento via App e Web?</TextPermitir>
                                <CheckBoxWrapper>
                                    <CheckBox disabled={this.props.loadingBotaoAlterarCliente} id="checkbox" type="checkbox" checked={this.props.valorSwitch} onChange={(value) => { this.props.alteraValorSwitch(value.target.checked) }} />
                                    <CheckBoxLabel htmlFor="checkbox" />
                                </CheckBoxWrapper>

                            </ContainerPermitir>
                            {this.renderAreaEmailSenha()}

                            <ContainerBotaoAlterar>
                                {this.props.loadingBotaoAlterarCliente == false &&
                                    <ButtonEntrar backGroundDinamico={this.props.corBotaoPadrao} corLetraBotao={this.props.letraBotaoPadrao} type="submit" onClick={(e) => this.handleSubmit(e)}>
                                        Alterar
                                    </ButtonEntrar>}
                                {this.props.loadingBotaoAlterarCliente == true &&
                                    <ClipLoader
                                        css={{ marginBottom: 10 }}
                                        size={35}
                                        color={this.props.corBotaoPadrao}
                                        loading={this.props.loadingBotaoAlterarCliente}
                                    />}
                            </ContainerBotaoAlterar>
                        </SubContainerClientePermissao>}

                    </ContainerPrincipalDadosCliente>}
                </Container>
            )
        }
    }



    render() {


        const CheckBoxWrapper = styled.div`
        position: relative;
        margin-left:10px;
      `;
        const CheckBoxLabel = styled.label`
        position: absolute;
        top: 0;
        left: 0;
        width: 42px;
        height: 23px;
        border-radius: 15px;
        background: ${this.props.coresLetraSecundario};
        cursor: pointer;
        &::after {
          content: "";
          display: block;
          border-radius: 50%;
          width: 18px;
          height: 18px;
          margin: 3px;
          background: #ffffff;
          box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
          transition: 0.2s;
        }
      `;
        const CheckBox = styled.input`
        opacity: 0;
        z-index: 1;
        border-radius: 15px;
        width: 42px;
        height: 26px;
        &:checked + ${CheckBoxLabel} {
          background: #00FF7F;
          &::after {
            content: "";
            display: block;
            border-radius: 50%;
            width: 18px;
            height: 18px;
            margin-left: 21px;
            transition: 0.2s;
          }
        }
      `;
        return (
            <>
                {this.renderLoadingClientes()}
                <Modal focusTrapped={false} showCloseIcon={false} open={this.props.modalNovoCliente} onClose={() => {

                }} center>
                    <ContainerModalNovoCliente onSubmit={(e) => this.cadastraClienteLocal(e)} backgroundDinamico={this.props.backgroundPrimeiro}>
                        <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                            <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Cadastro Cliente</TextNovoAtendimento>
                            <div>
                                <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => {
                                    if (this.props.retornaTelaClienteParaNovoAtendimentos) {
                                        this.props.history.goBack();
                                        this.props.modificaVisibleModalNovoCLiente(false);
                                        this.setState({ pesquisaClienteNovoCadastro: false });
                                        this.props.modificaRetornaTelaClienteParaNovoAtendimento(false);
                                    } else {
                                        this.props.modificaVisibleModalNovoCLiente(false);
                                        this.setState({ pesquisaClienteNovoCadastro: false });
                                    }
                                    this.setState({ clientSelectedNovoCliente: null });
                                }} className="icon-close" />
                            </div>
                        </ContainerIconeFecharModal>
                        <SubContainerCliente>

                            <ContainerImagemAlterarCliente>
                                <Dropzone
                                    accept="image/*"
                                    multiple={false}
                                    noKeyboard
                                    maxSize={5e+6}
                                    noDrag
                                    onDrop={acceptedFiles => this.escolheArquivoChamadoNovoCadastro(acceptedFiles)}>
                                    {({ getRootProps, getInputProps, isDragActive }) => {

                                        return (
                                            /*     <section> */
                                            <div  {...getRootProps()}>
                                                <input {...getInputProps()} />
                                                <ImagePerfil bordaDinamico={this.props.corBotaoPadrao} src={this.props.uriImagemPadraoTelaCadastroCliente} alt="foto cliente" />
                                            </div>
                                            /*   </section> */
                                        )
                                    }}
                                </Dropzone>
                            </ContainerImagemAlterarCliente>
                            <ContainerDadosNovoCliente>

                                <p style={{ color: this.props.corLetraDescricaoDosCampos, fontWeight: 'bold', fontSize: 12 }}>Nome Empresa<span style={{ color: 'red', fontSize: 14, marginLeft: 3 }}>*</span></p>
                                <DivContainerInputEmpresaVinculada>
                                    {this.state.pesquisaClienteNovoCadastro == false && <InputEmpresa
                                        autoFocus
                                        type="text"
                                        placeholder="Nome da Empresa"
                                        colorDinamica={this.props.coresLetraPadrao}
                                        placeholderDinamico={this.props.coresLetraSecundario}
                                        borderDinamico={this.props.corBotaoPadrao}
                                        value={this.props.nomeEmpresaClienteNovoCadastro}
                                        onChange={(e) => { this.props.modificaEmpresaClienteTelaCadastroClienteNovoCadastro(e.target.value) }}
                                    //  onFocus={() => { this.handleInputEmailFocus() }}
                                    // onBlur={() => { this.handleInputEmailBlur() }}
                                    />}
                                    {this.state.pesquisaClienteNovoCadastro == true &&
                                        <div style={{ justifyContent: 'center', alignItems: 'center', flex: 1 }}>

                                            <div style={{ background: this.props.corSegundoBotaoPadrao, borderRadius: 3, height: 30 }}>

                                                {this.props.listaEmpresasTelaAlterarCliente.length === 0 &&
                                                    <SelectSearchPaginate
                                                        onSelectClient={(client) => {

                                                            this.setState({ clientSelectedNovoCliente: { value: client.value, label: client.label } });
                                                            this.props.alteraEmpresaSelecionadoTelaAlterarClienteNovoCadastro(client.value);
                                                            this.props.modificaNomeClienteSelecionadoTelaAlterarClienteNovoCadastro(client.empresa);

                                                        }}
                                                        value={this.state.clientSelectedNovoCliente}
                                                        isMyContact={false}
                                                        background={this.props.backgroundMaisEscuro}
                                                        letterColor={this.props.coresLetraPadrao}
                                                        backgroundDinamico={this.props.backgroundMaisEscuro}
                                                        placeholderColor={this.props.coresLetraSecundario}
                                                        corBordaDinamica={this.props.corBotaoPadrao}
                                                        width={'100%'}
                                                        letraBotaoPadrao={this.props.letraBotaoPadrao}
                                                        placeHolder={"Selecione a empresa"}
                                                        isClientLinked={true}
                                                    />}
                                                {this.props.listaEmpresasTelaAlterarCliente.length > 0 && <SelectSearch
                                                    renderValue={(valueProps) =>
                                                        <DivInputSearch>
                                                            <InputSearch
                                                                corLetraDinamico={this.props.coresLetraPadrao}
                                                                backgroundDinamico={this.props.backgroundMaisEscuro}
                                                                placeholderColor={this.props.corLetraSecundaria}
                                                                corBordaDinamica={this.props.corBotaoPadrao}
                                                                {...valueProps} ></InputSearch>
                                                            <IconeSetaPraBaixo className="icon-angle-arrow-down" colorDinamico={this.props.coresLetraPadrao} />
                                                        </DivInputSearch>
                                                    }
                                                    value={this.props.empresaSelecionadasTelaCadastraCliente}
                                                    options={this.props.listaEmpresasTelaAlterarCliente}
                                                    onChange={(value, option) => { this.props.alteraEmpresaSelecionadoTelaAlterarClienteNovoCadastro(value); this.props.modificaNomeClienteSelecionadoTelaAlterarClienteNovoCadastro(option.name) }} placeholder={this.props.listaEmpresasTelaAlterarCliente.length == 0 ? `Nenhum Empresa cadastrada` : "Selecione a Empresa"} search={this.props.listaEmpresasTelaAlterarCliente.length == 0 ? false : true}
                                                />}
                                            </div>
                                        </div>
                                    }
                                    {this.renderIconeEmpresaModal()}
                                </DivContainerInputEmpresaVinculada>
                                {/*  </div> */}
                                <p style={{ color: this.props.corLetraDescricaoDosCampos, fontWeight: 'bold', fontSize: 12, marginTop: 5 }}>Nome Cliente<span style={{ color: 'red', fontSize: 14, marginLeft: 3 }}>*</span></p>
                                <InputEmpresa
                                    type="text"
                                    placeholder="Nome do Cliente"
                                    colorDinamica={this.props.coresLetraPadrao}
                                    placeholderDinamico={this.props.coresLetraSecundario}
                                    borderDinamico={this.props.corBotaoPadrao}
                                    value={this.props.nomeClienteNovoCadastro}
                                    onChange={(e) => { this.props.modificaNomeClienteTelaCadastroClienteNovoCadastro(e.target.value) }}
                                //  onFocus={() => { this.handleInputEmailFocus() }}
                                // onBlur={() => { this.handleInputEmailBlur() }}
                                />
                                <p style={{ color: this.props.corLetraDescricaoDosCampos, fontWeight: 'bold', fontSize: 12, marginTop: 5 }}>Endereço</p>
                                <InputEmpresa
                                    type="text"
                                    placeholder="Endereço (opcional)"
                                    colorDinamica={this.props.coresLetraPadrao}
                                    placeholderDinamico={this.props.coresLetraSecundario}
                                    borderDinamico={this.props.corBotaoPadrao}
                                    value={this.props.enderecoClienteNovoCadastro}
                                    onChange={(e) => { this.props.modificaEnderecoTelaCAdastroClienteNovoCadastro(e.target.value) }}
                                //  onFocus={() => { this.handleInputEmailFocus() }}
                                // onBlur={() => { this.handleInputEmailBlur() }}
                                />
                                <p style={{ color: this.props.corLetraDescricaoDosCampos, fontWeight: 'bold', fontSize: 12, marginTop: 5 }}>Telefone {this.props.configWhatsapp.length > 0 && <span style={{ color: 'orange', marginleft: 15 }}>(55) 21 98888 - 8888</span>}</p>
                                <InputEmpresa
                                    type="text"
                                    placeholder="Telefone (opcional)"
                                    colorDinamica={this.props.coresLetraPadrao}
                                    placeholderDinamico={this.props.coresLetraSecundario}
                                    borderDinamico={this.props.corBotaoPadrao}
                                    value={this.props.telefoneClienteNovoCadastro}
                                    onChange={(e) => { this.props.modificaTelefoneTelaCAdastroClienteNovoCadastro(e.target.value.replace(/\D/, '')) }}
                                //  onFocus={() => { this.handleInputEmailFocus() }}
                                // onBlur={() => { this.handleInputEmailBlur() }}
                                />
                            </ContainerDadosNovoCliente>
                        </SubContainerCliente>
                        <SubContainerClientePermissao>
                            <ContainerPermitir>
                                <TextPermitir corLetraDinamica={this.props.corLetraDescricaoDosCampos}>Permitir o cliente abrir atendimento via App e Web?</TextPermitir>
                                <CheckBoxWrapper>
                                    <CheckBox id="checkbox2" type="checkbox" checked={this.props.valorSwitchNovoCadastro} onChange={(value) => { this.props.alteraValorSwitchNovoCadastro(value.target.checked) }} />
                                    <CheckBoxLabel htmlFor="checkbox2" />
                                </CheckBoxWrapper>

                            </ContainerPermitir>
                            {this.renderAreaEmailSenhaNovoCAdastro()}

                            <ContainerBotaoAlterar>
                                {this.props.loadingBotaoCadastrarCliente == false && <ButtonEntrar backGroundDinamico={this.props.corBotaoPadrao} corLetraBotao={this.props.letraBotaoPadrao} type="submit" onClick={(e) => this.cadastraClienteLocal(e)}>
                                    Cadastrar
                                </ButtonEntrar>}
                                {this.props.loadingBotaoCadastrarCliente == true && <ClipLoader
                                    css={{ marginBottom: 10 }}
                                    size={35}
                                    color={this.props.corBotaoPadrao}
                                    loading={this.props.loadingBotaoCadastrarCliente}
                                />}
                            </ContainerBotaoAlterar>
                        </SubContainerClientePermissao>
                    </ContainerModalNovoCliente>
                </Modal>
                <Modal focusTrapped={false} showCloseIcon={false} open={this.state.modalPesquisaCliente} onClose={() => { }} center>
                    <ContainerModal backgroundDinamico={this.props.backgroundPrimeiro}>
                        <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                            <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Vincular Empresa</TextNovoAtendimento>
                            <div>
                                <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => { this._toggleModalPesquisarCliente() }} className="icon-close" />
                            </div>
                        </ContainerIconeFecharModal>
                        <ContainerImagemVincularEmpresa>
                            <img src={imglink} alt="foto vincular" style={{ height: 130, width: 130 }} />
                        </ContainerImagemVincularEmpresa>

                        <ContainerExplicacaoVincular>
                            <TextVincular corLetraDinamica={this.props.coresLetraPadrao}>Ao Vincular esse Cliente à uma <span style={{ color: this.props.coresLetraPadrao, fontSize: 16, fontWeight: 'bold' }}>Empresa já Cadastrada,</span> os ATENDIMENTOS abertos por esse cliente serão vinculados à essa Empresa e serão visíveis para todos os usuários dessa Empresa.</TextVincular>
                        </ContainerExplicacaoVincular>
                        <ContainerBotaoAlterar>
                            <ButtonVincular backGroundDinamico={this.props.corBotaoPadrao} corLetraBotao={this.props.letraBotaoPadrao} type="submit" onClick={() => {
                                this._toggleModalPesquisarCliente();
                                if (this.props.modalNovoCliente == true) {
                                    this._toggleSectionedPesquisaClienteNovoCadastro();
                                } else {
                                    this._toggleSectionedPesquisaCliente();
                                }
                            }}>
                                Vincular
                            </ButtonVincular>
                        </ContainerBotaoAlterar>
                    </ContainerModal>
                </Modal>
                <Modal focusTrapped={false} showCloseIcon={false} open={this.state.modalDesvinculaCliente} onClose={() => { }} center>
                    <ContainerModal backgroundDinamico={this.props.backgroundPrimeiro}>
                        <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                            <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Desvincular Empresa</TextNovoAtendimento>
                            <div>
                                <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => { this._toggleModalDesvincularCliente(); }} className="icon-close" />
                            </div>
                        </ContainerIconeFecharModal>
                        <ContainerImagemVincularEmpresa>
                            <img src={imgunlink} alt="foto vincular" style={{ height: 130, width: 130 }} />
                        </ContainerImagemVincularEmpresa>

                        <ContainerExplicacaoVincular>
                            <TextVincular corLetraDinamica={this.props.coresLetraPadrao}>Ao Desvincular esse Cliente da <span style={{ color: this.props.coresLetraPadrao, fontSize: 16, fontWeight: 'bold' }}>Empresa Selecionada,</span> os ATENDIMENTOS abertos por esse cliente não serão mais vinculados à essa Empresa e não serão mais visíveis para os usuários dessa Empresa.</TextVincular>
                        </ContainerExplicacaoVincular>
                        <ContainerBotaoAlterar>
                            <ButtonVincular backGroundDinamico={this.props.corBotaoPadrao} corLetraBotao={this.props.letraBotaoPadrao} type="submit" onClick={() => {
                                if (this.props.modalNovoCliente == true) {
                                    this._toggleSectionedPesquisaClienteNovoCadastro();
                                    this.props.modificaNomeClienteTelaCadastroClienteNovoCadastro('');
                                    this.props.alteraEmpresaSelecionadoTelaAlterarClienteNovoCadastro([]);
                                    this.props.modificaEmpresaClienteTelaCadastroClienteNovoCadastro('');
                                    this._toggleModalDesvincularCliente();
                                } else {
                                    this._toggleSectionedPesquisaCliente();
                                    this.props.modificaNomeClienteSelecionadoTelaAlterarCliente('');
                                    this.props.alteraEmpresaSelecionadoTelaAlterarCliente([]);
                                    this.props.modificaEmpresaClienteTelaCadastroCliente('');
                                    this._toggleModalDesvincularCliente();
                                }

                            }}>
                                Desvincular
                            </ButtonVincular>
                        </ContainerBotaoAlterar>
                    </ContainerModal>
                </Modal>
                <Modal focusTrapped={false} showCloseIcon={false} open={this.props.visibleModalExcluirCliente} onClose={() => { }} center>
                    <ContainerModal backgroundDinamico={this.props.backgroundPrimeiro}>
                        <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                            <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Excluir Cliente</TextNovoAtendimento>
                            <div>
                                <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => { this.props.mudaVisibilidadeModalExcluirCliente(false) }} className="icon-close" />

                            </div>
                        </ContainerIconeFecharModal>
                        <ContainerImagemVincularEmpresa>
                            <img src={imgexcluirCliente} alt="foto excluir" style={{ height: 130, width: 130 }} />
                            <span style={{ color: this.props.coresLetraPadrao }}>Tem certeza que deseja excluir <span style={{ color: this.props.coresLetraPadrao, fontWeight: 'bold' }}>{this.props.nomeCliente}</span>?</span>
                        </ContainerImagemVincularEmpresa>

                        <ContainerExplicacaoVincular>
                            <TextVincular corLetraDinamica={this.props.coresLetraPadrao}>A exclusão de <span style={{ color: this.props.coresLetraPadrao, fontSize: 16, fontWeight: 'bold' }}>{this.props.nomeCliente}</span> não apaga seus atendimentos, avaliações e nenhuma outra ação já feita por ele, apenas o cliente é excluído e não terá mais acesso ao Aplicativo/Web sendo assim não conseguirá mais abrir Atendimentos.</TextVincular>
                        </ContainerExplicacaoVincular>
                        <ContainerBotaoAlterar>
                            <ButtonVincular backGroundDinamico={this.props.corBotaoPadrao} corLetraBotao={this.props.letraBotaoPadrao} type="button" onClick={() => {

                                this.props.excluiCliente(this.props.keyCliente);
                                if (this.props.filtroClientes !== "") {
                                    this.removeFiltrosAll();
                                }
                                this.props.mudaVisibilidadeModalExcluirCliente(false);
                                // this.removeClienteExcluidoDaConsulta(this.props.keyCliente, this.props.clientesSectionedConsulta)


                                //this.props.excluiCliente(this.props.keyCliente);
                            }}>
                                Excluir
                            </ButtonVincular>
                        </ContainerBotaoAlterar>
                    </ContainerModal>
                </Modal>
                <Modal focusTrapped={false} showCloseIcon={false} open={this.props.modalExportarClientes} onClose={() => { }} center>
                    <ContainerModal backgroundDinamico={this.props.backgroundPrimeiro}>
                        <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                            <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Exportar Clientes Excel</TextNovoAtendimento>
                            <div>
                                <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => { this.props.modificaVisibilidadeModalExportarClientes(false) }} className="icon-close" />

                            </div>
                        </ContainerIconeFecharModal>
                        <ContainerImagemVincularEmpresa>
                            <i className="icon-excel" style={{ color: this.props.coresLetraPadrao, fontSize: 110 }} />
                            <p style={{ color: this.props.coresLetraPadrao, fontSize: 20, fontWheight: 'bold', margin: 15 }}>Deseja realmente exportar todos os dados dos seus clientes para EXCEL?</p>
                        </ContainerImagemVincularEmpresa>

                        {/* <ContainerExplicacaoVincular>
                            <TextVincular corLetraDinamica={this.props.coresLetraPadrao}>A exclusão de <span style={{ color: this.props.coresLetraPadrao, fontSize: 16, fontWeight: 'bold' }}>{this.props.nomeCliente}</span> não apaga seus atendimentos, avaliações e nenhuma outra ação já feita por ele, apenas o cliente é excluído e não terá mais acesso ao Aplicativo/Web sendo assim não conseguirá mais abrir Atendimentos.</TextVincular>
                        </ContainerExplicacaoVincular> */}
                        <ContainerBotaoAlterar>
                            <ButtonVincular backGroundDinamico={this.props.corBotaoPadrao} corLetraBotao={this.props.letraBotaoPadrao} type="button" onClick={() => {
                                this.props.exportarClientesExcel(this.props.clientesExportarExcel)
                                this.props.modificaVisibilidadeModalExportarClientes(false)
                            }}>
                                Exportar
                            </ButtonVincular>
                        </ContainerBotaoAlterar>
                    </ContainerModal>
                </Modal>
                <Modal focusTrapped={false} showCloseIcon={false} open={this.props.modalPortalCliente} onClose={() => { }} center>
                    <ContainerModalRespostas backgroundDinamico={this.props.backgroundPrimeiro}>
                        <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                            <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Portal do Cliente</TextNovoAtendimento>
                            <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => { this.props.modificaVisibilidadeModalPortalCliente(false) }} className="icon-close" />
                        </ContainerIconeFecharModal>


                        {this.props.loadingverificaSeJaTemCodigoCadastraCliente == false && <ContainerTextCabecalhoModalAcoes>
                            <TextCabecalhoModalAcoes corLetraDinamico={this.props.corBotaoPadrao}>Site de acesso para o painel de help desk onde os clientes poderão abrir chamados:</TextCabecalhoModalAcoes>
                        </ContainerTextCabecalhoModalAcoes>}
                        {this.props.loadingverificaSeJaTemCodigoCadastraCliente == false && <ContainerAtendentes>
                            <div style={{
                                padding: 10, display: 'flex', flexDirection: 'column',
                                alignItems: 'center', justifyContent: 'space-between'
                            }}>
                                <p style={{ fontSize: 15, color: this.props.coresLetraPadrao, fontWeight: 'bold' }}>Nesta URL de acesso os clientes terão a Logo Marca e as Cores que foram configuradas no menu "Configuração", personalizadas já na tela de login.<br></br>
                                    O campo "Código de Referência" já irá preenchido para o cliente se auto-cadastrar.
                                    copie esta URL e passe para seus clientes:</p>
                                <div style={{
                                    height: 35, background: '#808080', borderRadius: 7, display: 'flex', flexDirection: 'row',
                                    alignItems: 'center', justifyContent: 'space-between', width: '100%', paddingLeft: 10, paddingRight: 10,
                                    marginTop: 10
                                }}>
                                    <p style={{ color: this.props.coresLetraPadrao, fontSize: 15 }}>http://www.clientes.dropdesk.com.br?ref={this.props.numeroExclusivoCadastraCliente}</p>
                                    <CopyToClipboard text={'http://www.clientes.dropdesk.com.br?ref=' + this.props.numeroExclusivoCadastraCliente}
                                        onCopy={() => { toastr.success('Copiado para área de transferência') }}>
                                        <i className="icon-copy" style={{ color: this.props.coresLetraPadrao, fontSize: 14, cursor: 'pointer' }} />
                                    </CopyToClipboard>
                                </div>
                            </div>
                            <div style={{
                                padding: 10, display: 'flex', flexDirection: 'column',
                                alignItems: 'center', justifyContent: 'space-between'
                            }}>
                                <p style={{ fontSize: 15, color: this.props.coresLetraPadrao, fontWeight: 'bold' }}>Nesta URL de acesso os clientes terão a Logo Marca e as Cores que foram configuradas no menu "Configuração", personalizadas após a tela de login ou seja somente após logar no sistema.<br></br>
                                    O campo "Código de Referência" não irá preenchido, para o cliente se auto-cadastrar você irá precisar passar o código de referência que se encontra nesta tela.
                                    copie esta URL e passe para seus clientes:</p>
                                <div style={{
                                    height: 35, background: '#808080', borderRadius: 7, display: 'flex', flexDirection: 'row',
                                    alignItems: 'center', justifyContent: 'space-between', width: '100%', paddingLeft: 10, paddingRight: 10,
                                    marginTop: 10
                                }}>
                                    <p style={{ color: this.props.coresLetraPadrao, fontSize: 15 }}>{this.props.urlPadraoAcessoPortalCliente}</p>
                                    <CopyToClipboard text={'https://clientes.dropdesk.com.br/'}
                                        onCopy={() => { toastr.success('Copiado para área de transferência') }}>
                                        <i className="icon-copy" style={{ color: this.props.coresLetraPadrao, fontSize: 14, cursor: 'pointer' }} />
                                    </CopyToClipboard>
                                </div>
                            </div>
                            <div>
                                <p style={{ fontSize: 15, color: this.props.coresLetraPadrao }}>Código Referência: <strong>{this.props.numeroExclusivoCadastraCliente}</strong></p>
                            </div>
                        </ContainerAtendentes>}
                        {this.props.loadingverificaSeJaTemCodigoCadastraCliente == true &&
                            <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                                < MoonLoader
                                    css={{ margin: 10 }}
                                    size={40}
                                    color={this.props.corBotaoPadrao}
                                    loading
                                />
                            </div>}
                    </ContainerModalRespostas>
                </Modal>
                <Modal focusTrapped={false} showCloseIcon={false} open={this.props.modalImportarClientes} onClose={() => { }} center>
                    <ContainerModalRespostas backgroundDinamico={this.props.backgroundPrimeiro}>
                        <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                            <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Importar Clientes</TextNovoAtendimento>
                            <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => {
                                if (this.props.loadingCadastrandoClienteMassa == true) {

                                } else {
                                    this.props.modificaVisibilidadeModalImportarClientes(false);
                                    this.setState({ nomeArquivoAnexado: '', arrayClientesParaCAdastro: [] })
                                }
                            }}
                                className="icon-close" />
                        </ContainerIconeFecharModal>



                        <ContainerImportarClientes>
                            {this.state.primeiraTelaImport == true && <> <div style={{
                                padding: 30, display: 'flex', flexDirection: 'column',
                                flex: 1
                            }}>
                                <p style={{ fontSize: 14, color: this.props.coresLetraPadrao }}>A partir daqui, você pode importar informações de clientes de um EXCEL(.xlsx) para sua conta do DropDesk. A importação de dados do cliente permite que você faça cadastro em massa com facilidade, porém a permissão para o cliente acessar o DropDesk através do email e senha, terá que ser concedida posteriormente.</p>
                                <br></br>
                                <p style={{ fontSize: 14, color: this.props.coresLetraPadrao }}>Certifique-se de que o arquivo EXCEL(.xlsx) esteja codificado em UTF-8 e que a linha do cabeçalho seja identica ao arquivo de exemplo.</p>
                                <br></br>
                                <p style={{ fontSize: 14, color: this.props.coresLetraPadrao, fontWeight: 'bold' }}>Campos que devem compor o excel:</p>
                                <li style={{ fontSize: 14, color: this.props.coresLetraPadrao, fontWeight: 'bold', marginTop: 5 }}>empresa  <span style={{ color: 'red', fontSize: 11 }}>(Obrigatório, Texto com no mínimo 2 digitos)</span></li>
                                <li style={{ fontSize: 14, color: this.props.coresLetraPadrao, fontWeight: 'bold', marginTop: 5 }}>nome <span style={{ color: 'red', fontSize: 11 }}>(Obrigatório, Texto com no mínimo 2 digitos)</span></li>
                                <li style={{ fontSize: 14, color: this.props.coresLetraPadrao, fontWeight: 'bold', marginTop: 5 }}>endereco</li>
                                <li style={{ fontSize: 14, color: this.props.coresLetraPadrao, fontWeight: 'bold', marginTop: 5 }}>telefone</li>
                                <div style={{ marginTop: 25, border: '1px solid #CCC', borderRadius: 5, height: 80, justifyContent: 'space-around', alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                                    <p style={{ fontSize: 16, color: this.props.coresLetraPadrao, fontWeight: 'bold', textAlign: 'center' }}>Baixe o arquivo de exemplo de como deve ser o modelo do Excel(.xlsx):</p>
                                    <p onClick={() => { this.downloadExcelExemplo() }} style={{ fontSize: 13, color: '#39a3f4', fontWeight: 'bold', textAlign: 'center', textDecoration: 'underline', cursor: 'pointer' }}>Baixar arquivo</p>
                                </div>
                            </div>
                                <div style={{
                                    padding: 30, display: 'flex', flexDirection: 'row',
                                    height: 70, justifyContent: 'space-between', alignItems: 'center',
                                }}>
                                    <div style={{ height: 40, width: 130 }}></div>
                                    {/*   <ButtonNext backGroundDinamico={this.props.corSegundoBotaoPadrao} corLetraBotao={this.props.letraBotaoPadrao}>Voltar</ButtonNext> */}
                                    <ButtonNext onClick={() => { this.setState({ primeiraTelaImport: false }); ReactTooltip.rebuild(); }} backGroundDinamico={this.props.corBotaoPadrao} corLetraBotao={this.props.letraBotaoPadrao}>Próximo</ButtonNext>
                                </div></>}
                            {this.state.primeiraTelaImport == false &&
                                <>
                                    {this.props.loadingCadastrandoClienteMassa == false && <><div style={{
                                        padding: 30, display: 'flex', flexDirection: 'column',
                                        flex: 1
                                    }}>
                                        <p style={{ fontSize: 14, color: this.props.coresLetraPadrao }}>Agora com o arquivo EXCEL(.xlsx) já formatado e preenchido corretamente, envie o arquivo para comerçamos o cadastro em massa.</p>
                                        <br></br>
                                        <div style={{ marginTop: 25, backgroundColor: '#F2F5F7', borderRadius: 5, height: 100, justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                                            {/*                                             <p style={{ fontSize: 16, color: this.props.coresLetraPadrao, fontWeight: 'bold', textAlign: 'center' }}>Baixe o arquivo de exemplo de como deve ser o modelo do Excel:</p> */}
                                            <i className="icon-upload" style={{ color: '#808080', fontSize: 20, marginBottom: 10 }} />
                                            <Dropzone
                                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                                multiple={false}
                                                noKeyboard
                                                maxSize={3e+7}
                                                noDrag
                                                onDrop={acceptedFiles => { this._insereNomeArquivoExcel(acceptedFiles) }}>
                                                {({ getRootProps, getInputProps, isDragActive }) => {

                                                    return (
                                                        /*     <section> */
                                                        <div  {...getRootProps()}>
                                                            <input id="input" {...getInputProps()} />
                                                            <p style={{ fontSize: 13, color: '#39a3f4', fontWeight: 'bold', textAlign: 'center', textDecoration: 'underline', cursor: 'pointer', marginBottom: 10 }}>Enviar Arquivo</p>
                                                        </div>
                                                        /*   </section> */
                                                    )
                                                }}
                                            </Dropzone>
                                            {/*   <input type="file" id="input" onClick={() => this._fileExcel()} /> */}
                                            {/*     <p style={{ fontSize: 13, color: '#39a3f4', fontWeight: 'bold', textAlign: 'center', textDecoration: 'underline', cursor: 'pointer', marginBottom: 10 }}>Enviar Arquivo</p> */}
                                            {this.state.nomeArquivoAnexado !== '' && <p style={{ fontSize: 13, color: '#808080', fontWeight: 'bold', textAlign: 'center' }}>Arquivo Anexado (<span style={{ color: '#39a3f4' }}>{this.state.nomeArquivoAnexado}</span>)</p>}
                                        </div>
                                        <div style={{ marginTop: 25, border: '1px solid #F2F5F7', borderRadius: 5, display: 'flex', flexDirection: 'column' }}>
                                            <div style={{ padding: 15, display: 'flex', alignItems: 'center' }}>
                                                <i className="icon-prioridadeweb" style={{ color: 'red', fontSize: 14, marginRight: 5 }} />
                                                <p style={{ fontSize: 17, fontWeight: 'bold', color: this.props.coresLetraPadrao }}>Nota Importante</p>
                                            </div>
                                            <div style={{ paddingLeft: 15, paddingRight: 15, display: 'flex', flexDirection: 'column' }}>
                                                <li style={{ fontSize: 14, color: this.props.coresLetraPadrao, marginTop: 5 }}>Os campos obrigatórios são "Empresa" e "Nome".</li>
                                                <li style={{ fontSize: 14, color: this.props.coresLetraPadrao, marginTop: 5 }}>Os campos "Telefone" e "Endereco" podem ficar em brancos.</li>
                                                <li style={{ fontSize: 14, color: this.props.coresLetraPadrao, marginTop: 5 }}>Caso utilize WhatsApp, o telefone informado deve conter código do pais, DDD e número, exemplo: <span style={{ color: 'orange', marginleft: 15 }}>5521988888888</span>.</li>
                                                <li style={{ fontSize: 14, color: this.props.coresLetraPadrao, marginTop: 5 }}>Limite de 50 linhas ou seja 50 cadastros por vez.</li>
                                                <li style={{ fontSize: 14, color: this.props.coresLetraPadrao, marginTop: 5 }}>O formato do EXCEL tem que ser (.xlsx)</li>
                                            </div>

                                        </div>
                                    </div>
                                        <div style={{
                                            padding: 30, display: 'flex', flexDirection: 'row',
                                            height: 70, justifyContent: 'space-between', alignItems: 'center',
                                        }}>

                                            <ButtonNext onClick={() => { this.setState({ primeiraTelaImport: true }) }} backGroundDinamico={this.props.corSegundoBotaoPadrao} corLetraBotao={this.props.letraBotaoPadrao}>Voltar</ButtonNext>

                                            {this.state.nomeArquivoAnexado === '' && <ButtonNext data-tip="Selecione um arquivo EXCEL(xlsx) para fazer o cadastro" data-for="tip-top" onClick={() => { toastr.warning('Atenção', 'Selecione o arquivo EXCEL(.xlsx) para poder Processar o cadastro') }} backGroundDinamico='#CCC' corLetraBotao={this.props.letraBotaoPadrao}>Cadastrar</ButtonNext>}
                                            {this.state.nomeArquivoAnexado !== '' && <ButtonNext onClick={() => {
                                                if (this.props.premium == 0) {
                                                    //
                                                    toastr.error('Recurso Premium', 'Este é um recurso PREMIUM, para cadastro de clientes em massa, Assine a versão PREMIUM e libere esse e mais recursos!')
                                                } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
                                                    toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
                                                } else {
                                                    this.props.cadastraClienteEmMassa(this.state.arrayClientesParaCAdastro);
                                                    this.setState({ nomeArquivoAnexado: '' });
                                                }
                                            }} backGroundDinamico={this.props.corBotaoPadrao} corLetraBotao={this.props.letraBotaoPadrao}>Cadastrar</ButtonNext>}
                                        </div></>}
                                    {this.props.loadingCadastrandoClienteMassa == true &&
                                        <div style={{
                                            display: 'flex', flexDirection: 'column',
                                            flex: 1, justifyContent: 'center', alignItems: 'center',
                                        }}>
                                            <p style={{ fontSize: 20, fontWeight: 'bold', color: this.props.coresLetraPadrao, marginBottom: 10 }}>Efetuando Cadastro dos Clientes em Massa</p>
                                            <p style={{ fontSize: 20, fontWeight: 'bold', color: this.props.coresLetraPadrao, marginBottom: 10 }}>Não feche a tela</p>
                                            <p style={{ fontSize: 20, fontWeight: 'bold', color: this.props.coresLetraPadrao, marginBottom: 10 }}>Não atualize a página</p>
                                            <p style={{ fontSize: 20, fontWeight: 'bold', color: this.props.coresLetraPadrao, marginBottom: 10 }}>Por favor aguarde...</p>
                                            <HashLoader
                                                css={{ marginTop: 30 }}
                                                size={60}
                                                color={this.props.corBotaoPadrao}
                                                loading
                                            />
                                        </div>
                                    }
                                </>}

                        </ContainerImportarClientes>

                    </ContainerModalRespostas>
                </Modal>
            </>

        );
    }
}

const mapStateToProps = state => {
    return (
        {
            backgroundPrimeiro: state.CoresPadraoSistema.backgroundPrimeiro,
            backgroundSegundo: state.CoresPadraoSistema.backgroundSegundo,
            backgroundMaisEscuro: state.CoresPadraoSistema.backgroundMaisEscuro,
            coresLetraPadrao: state.CoresPadraoSistema.coresLetraPadrao,
            corBotaoPadrao: state.CoresPadraoSistema.corBotaoPadrao,
            corSegundoBotaoPadrao: state.CoresPadraoSistema.corSegundoBotaoPadrao,
            coresLetraSecundario: state.CoresPadraoSistema.coresLetraSecundario,
            letraBotaoPadrao: state.CoresPadraoSistema.letraBotaoPadrao,
            corLetraDescricaoDosCampos: state.CoresPadraoSistema.corLetraDescricaoDosCampos,
            nomeRota: state.HeaderReducer.nomeRota,
            testePesquisa: state.HeaderReducer.testePesquisa,

            chamados: state.ChamadoReducer.chamados,
            verificaConexaoInternetListaChamadoAberto: state.ChamadoReducer.verificaConexaoInternetListaChamadoAberto,
            loadingTelaChamadoAberto: state.ChamadoReducer.loadingTelaChamadoAberto,
            refreshControlFlatListChamadoAberto: state.ChamadoReducer.refreshControlFlatListChamadoAberto,
            referenciaUltimaKey: state.ChamadoReducer.referenciaUltimaKey,
            loadingFimDaPagina: state.ChamadoReducer.loadingFimDaPagina,
            premium: state.PremiumReducer.premium,
            verificaAgrupaAtendenteSetor: state.ChamadoReducer.verificaAgrupaAtendenteSetor,
            verificaAtendenteVeSomenteProprioAtendimento: state.ChamadoReducer.verificaAtendenteVeSomenteProprioAtendimento,
            //  contaPrincipal: state.AtendenteReducer.contaPrincipal,
            filtroChamado: state.ChamadoReducer.filtroChamado,
            chamadosComFiltro: state.ChamadoReducer.chamadosComFiltro,
            filtroSelecionado: state.ChamadoReducer.filtroSelecionado,
            filtroClientes: state.ChamadoReducer.filtroClientes,
            clientesSectionedConsulta: state.ChamadoReducer.clientesSectionedConsulta,
            nomeClienteSelecionadoTelaCadastroCLiente: state.ChamadoReducer.nomeClienteSelecionadoTelaCadastroCLiente,
            licensawebouapp: state.PremiumReducer.licensawebouapp,
            fotoImagemClienteTelaAlterar: state.ChamadoReducer.fotoImagemClienteTelaAlterar,
            nomeEmpresaCliente: state.ChamadoReducer.nomeEmpresaCliente,
            nomeCliente: state.ChamadoReducer.nomeCliente,
            enderecoCliente: state.ChamadoReducer.enderecoCliente,
            telefoneCliente: state.ChamadoReducer.telefoneCliente,
            emailCliente: state.ChamadoReducer.emailCliente,
            senhaCliente: state.ChamadoReducer.senhaCliente,
            confirmaSenhaCliente: state.ChamadoReducer.confirmaSenhaCliente,
            mensagemerrorAoCadastrarClienteTelaAlterar: state.ChamadoReducer.mensagemerrorAoCadastrarClienteTelaAlterar,
            loadingBotaoAlterarCliente: state.ChamadoReducer.loadingBotaoAlterarCliente,
            valorSwitch: state.ChamadoReducer.valorSwitch,
            backgroundMensagemErrorTelaAlterarCliente: state.ChamadoReducer.backgroundMensagemErrorTelaAlterarCliente,
            keyCliente: state.ChamadoReducer.keyCliente,
            visibleModalExcluirCliente: state.ChamadoReducer.visibleModalExcluirCliente,
            clienteEmpresaPrincipal: state.ChamadoReducer.clienteEmpresaPrincipal,
            empresaSelecionadasTelaAlterarCliente: state.ChamadoReducer.empresaSelecionadasTelaAlterarCliente,
            modalNovoCliente: state.ChamadoReducer.modalNovoCliente,
            loadingBotaoCadastrarCliente: state.ChamadoReducer.loadingBotaoCadastrarCliente,
            clientes: state.ChamadoReducer.clientes,
            inputDigitoConsulta: state.ChamadoReducer.inputDigitoConsulta,
            mensagemSucessoAoCadastrarClienteTelaAlterar: state.ChamadoReducer.mensagemSucessoAoCadastrarClienteTelaAlterar,
            verificaInternetConsultaCliente: state.ChamadoReducer.verificaInternetConsultaCliente,
            loadingTelaConsultaCliente: state.ChamadoReducer.loadingTelaConsultaCliente,
            referenciaUltimaKeyTelaConsultaCliente: state.ChamadoReducer.referenciaUltimaKeyTelaConsultaCliente,
            loadingFimDaPAginaTelaConsultaCliente: state.ChamadoReducer.loadingFimDaPAginaTelaConsultaCliente,
            loadingCarregarClientesSectionedConsultaCliente: state.ChamadoReducer.loadingCarregarClientesSectionedConsultaCliente,
            clienteSelecionadoTelaConsultaCliente: state.ChamadoReducer.clienteSelecionadoTelaConsultaCliente,
            nomeClienteSelecionadoTelaConsultaCliente: state.ChamadoReducer.nomeClienteSelecionadoTelaConsultaCliente,
            refreshControlConsultaCliente: state.ChamadoReducer.refreshControlConsultaCliente,
            nomeEmpresaSelecionadoConsultaTelaAlterarCliente: state.ChamadoReducer.nomeEmpresaSelecionadoConsultaTelaAlterarCliente,
            listaEmpresasTelaAlterarCliente: state.ChamadoReducer.listaEmpresasTelaAlterarCliente,
            uriImagemPadraoTelaCadastroCliente: state.ChamadoReducer.uriImagemPadraoTelaCadastroCliente,
            arquivoCompletoParaUpar: state.ChamadoReducer.arquivoCompletoParaUpar,


            nomeEmpresaClienteNovoCadastro: state.ChamadoReducer.nomeEmpresaClienteNovoCadastro,
            nomeClienteNovoCadastro: state.ChamadoReducer.nomeClienteNovoCadastro,
            enderecoClienteNovoCadastro: state.ChamadoReducer.enderecoClienteNovoCadastro,
            telefoneClienteNovoCadastro: state.ChamadoReducer.telefoneClienteNovoCadastro,
            emailClienteNovoCadastro: state.ChamadoReducer.emailClienteNovoCadastro,
            senhaClienteNovoCadastro: state.ChamadoReducer.senhaClienteNovoCadastro,
            confirmaSenhaClienteNovoCadastro: state.ChamadoReducer.confirmaSenhaClienteNovoCadastro,
            valorSwitchNovoCadastro: state.ChamadoReducer.valorSwitchNovoCadastro,
            empresaSelecionadasTelaCadastraCliente: state.ChamadoReducer.empresaSelecionadasTelaCadastraCliente,
            arquivoCompletoParaUparNovoCadastro: state.ChamadoReducer.arquivoCompletoParaUparNovoCadastro,
            permissoesAcessarTelasAtendente: state.AtendenteReducer.permissoesAcessarTelasAtendente,
            modalPortalCliente: state.ChamadoReducer.modalPortalCliente,
            retornaTelaClienteParaNovoAtendimentos: state.ChamadoReducer.retornaTelaClienteParaNovoAtendimentos,

            urlPadraoAcessoPortalCliente: state.ChamadoReducer.urlPadraoAcessoPortalCliente,
            urlPersonalizadaAcessoPortalCliente: state.ChamadoReducer.urlPersonalizadaAcessoPortalCliente,
            loadingverificaSeJaTemCodigoCadastraCliente: state.ChamadoReducer.loadingverificaSeJaTemCodigoCadastraCliente,
            numeroExclusivoCadastraCliente: state.ChamadoReducer.numeroExclusivoCadastraCliente,
            modalImportarClientes: state.ChamadoReducer.modalImportarClientes,
            loadingCadastrandoClienteMassa: state.ChamadoReducer.loadingCadastrandoClienteMassa,

            referenciaUltimoNomeCliente: state.ChamadoReducer.referenciaUltimoNomeCliente,

            configWhatsapp: state.ChamadoReducer.configWhatsapp,

            modalExportarClientes: state.ChamadoReducer.modalExportarClientes,

            clientesExportarExcel: state.DashBoardReducer.clientesExportarExcel,

            clientesFiltradosAposDigito: state.ChamadoReducer.clientesFiltradosAposDigito,
        }
    );
};
export default connect(mapStateToProps, {
    modificaPesquisaTeste, zeraMensagemSemInternetChamadoAberto,
    navegaTelaNovoChamado, modificaFiltroChamado, modificaSelectTextChamado, modificaPesquisaTextChamado,
    ativaLoadingTelaAberturaChamadoRemoveFiltro, modificaFiltroSelecionadoTelaChamadoAberto, modificaFiltroSelecionadoTelaChamadoCancelado,
    modificaFiltroSelecionadoTelaChamadoEmAtendimento, modificaFiltroSelecionadoTelaChamadoFechado, modificaFiltroSelecionadoTelaChamadoTodos,
    modificaFiltroClientesTelaClientes, alteraValorSwitch,
    modificaEmpresaClienteTelaCadastroCliente, modificaNomeClienteTelaCadastroCliente, modificaEnderecoTelaCAdastroCliente, modificaTelefoneTelaCAdastroCliente,
    modificaEmailClienteTelaCadastroCliente, modificaSenhaClienteTelaCadastroCliente, modificaConfirmaClienteTelaCadastroCliente,
    mudaVisibilidadeModalExcluirCliente, alteraEmpresaSelecionadoTelaAlterarCliente, modificaNomeClienteSelecionadoTelaAlterarCliente,
    modificaVisibleModalNovoCLiente, modificaNomeRotaNoHeader, consultaCliente2, listaClientesTelaConsultaClientes, consultaClienteComCliente,
    modificaClienteSelecionadoTelaConsultaCliente, modificaNomeClienteSelecionadoTelaConsultaCliente,
    alteraCliente, insereFotoAlterarCliente, modificaArquivoCompletoParaUpar, zeraInformacoesCadastroClienteExport,
    modificaEmpresaClienteTelaCadastroClienteNovoCadastro, modificaNomeClienteTelaCadastroClienteNovoCadastro, modificaEnderecoTelaCAdastroClienteNovoCadastro, modificaTelefoneTelaCAdastroClienteNovoCadastro, alteraValorSwitchNovoCadastro,
    modificaEmailClienteTelaCadastroClienteNovoCadastro, modificaSenhaClienteTelaCadastroClienteNovoCadastro, modificaConfirmaClienteTelaCadastroClienteNovoCadastro,
    insereFotoCadastroCliente, modificaArquivoCompletoParaUparNovoCadastro, alteraEmpresaSelecionadoTelaAlterarClienteNovoCadastro,
    cadastraCliente, modificaNomeClienteSelecionadoTelaAlterarClienteNovoCadastro, excluiCliente,
    modificaRetornaTelaClienteParaNovoAtendimento, modificaVisibilidadeModalPortalCliente, modificaVisibilidadeModalImportarClientes,
    cadastraClienteEmMassa, desligaOuvintesChamadosFirebase, modificaVisibilidadeModalExportarClientes, exportarClientesExcel,
    pesquisaClientePorDigito, removeListaClientesFiltrados
})(withRouter(Clientes));
