import React from 'react';
import { connect } from 'react-redux';
import firebase from '../../servicos/FirebaseConnection';
import MoonLoader from "react-spinners/MoonLoader";
import SelectSearchPaginate from "../SelectSearch/index";
import FlatList from 'flatlist-react';
import FlatListChamadoAberto from "./FlatListChamadoAberto";
import ReactTooltip from "react-tooltip";
import SelectSearch from 'react-select-search';
import '../Avisos/stylesSearch.css';
import { toastr } from 'react-redux-toastr';


import { modificaPesquisaTeste } from '../../actions/HeaderAction';
import {
    zeraMensagemSemInternetChamadoAberto, navegaTelaNovoChamado,
    modificaFiltroChamado, modificaSelectTextChamado, modificaPesquisaTextChamado,
    ativaLoadingTelaAberturaChamadoRemoveFiltro, modificaFiltroSelecionadoTelaChamadoAberto, modificaFiltroSelecionadoTelaChamadoCancelado,
    modificaFiltroSelecionadoTelaChamadoEmAtendimento, modificaFiltroSelecionadoTelaChamadoFechado, modificaFiltroSelecionadoTelaChamadoTodos,
    listaOsUltimosChamadosAbertos, listaChamadosAbertoComFiltroVencimento, listaChamadosAbertoComFiltro, listaChamadosAbertoComFiltroDigitado,
    ativaDesativaLoadingFinalFlatListChamadoAbertoExport
} from "../../actions/ChamadoAction";

import { desligaOuvintesChamadosFirebase } from "../../actions/DashBoardAction";

import {
    Title, Container, IconeSair, ContainerInputSearch, InputFiltroPorNumero, BotaoPesquisarChamadoPorNumero,
    InputSearch, DivInputSearch, IconeSetaPraBaixo, ContainerChamadoLoading,

    TextProblemaLoading, ContainerDadosChamado,
    ContaineDadosChamadosPrincipal, ContaineDadosChamadosSecundario,
    ContainerIconeEFrase,
    ContainerImagemLoading, ImageLoading, TextClienteLoading, ContainerListaVazia

} from './styled';


class ChamadoAberto extends React.Component {
    state = {
        testeFimPagina: true,
        filtroTeste: false,
        hasMoreItems: false,
        offset: 0,
        search: '',
        maisDados: [],
        loading: true,
        clientSelected: null,
        referenciaUltimaKeys: []
    }

    componentWillUnmount() {

    }
    componentDidMount() {
        ReactTooltip.rebuild();
        if (this.props.filtroSelecionado !== '') {
            //alert("ativa filtro")
        } else {
            this.props.listaOsUltimosChamadosAbertos(0, '', [], 'willmount', this.props.verificaAtendenteVeSomenteProprioAtendimento, this.props.verificaAgrupaAtendenteSetor, this.props.isAdmin, this.props.usuarioAdmVisualizaApenasChamadosDoSetorVinculado);
        }


    }
    componentWillUnmount() {

        this.props.desligaOuvintesChamadosFirebase('atendimentoAberto', this.props.verificaAtendenteVeSomenteProprioAtendimento, this.props.verificaAgrupaAtendenteSetor, this.props.isAdmin, this.props.usuarioAdmVisualizaApenasChamadosDoSetorVinculado, this.state.referenciaUltimaKeys);
    }
    componentWillReceiveProps(nextProps) {


        if (this.props.filtroChamado !== nextProps.filtroChamado && nextProps.filtroChamado !== '') {

            setTimeout(() => {
                this.myRef.scrollTop = 0;
            }, 200)
        }
    }

    pesquisaComDigito(digito) {
        this.props.listaChamadosAbertoComFiltroDigitado(0, '', [], 'willmount', this.props.verificaAtendenteVeSomenteProprioAtendimento, this.props.isAdmin, this.props.filtroChamado, 'Aberto', digito);
        this.props.desligaOuvintesChamadosFirebase('atendimentoAberto', this.props.verificaAtendenteVeSomenteProprioAtendimento, this.props.verificaAgrupaAtendenteSetor, this.props.isAdmin, this.props.usuarioAdmVisualizaApenasChamadosDoSetorVinculado, this.state.referenciaUltimaKeys);
    }
    renderCarregaMaisResultados() {
        if (this.props.verificaAtendenteVeSomenteProprioAtendimento == true && this.props.verificaAgrupaAtendenteSetor == true && this.props.isAdmin == false) {
            if (this.props.loadingMaisResultadosChamados) {
                return (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 100 }}>
                        <MoonLoader
                            css={{ margin: 10 }}
                            size={40}
                            color={this.props.corBotaoPadrao}
                            loading={true}
                        />
                    </div>
                )
            } else {
                if (this.props.referenciaUltimaKey != '' && this.props.referenciaUltimaKey != undefined) {
                    return (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{
                                display: 'flex', cursor: 'pointer', height: 30, margin: 20, backgroundColor: this.props.backgroundSegundo, border: `1px solid ${this.props.coresLetraPadrao}`, width: 200, justifyContent: 'center', alignItems: 'center'
                            }} onClick={() => {
                                this.props.listaOsUltimosChamadosAbertos(1, this.props.referenciaUltimaKey, this.props.chamados, '', this.props.verificaAtendenteVeSomenteProprioAtendimento, this.props.verificaAgrupaAtendenteSetor, this.props.isAdmin, this.props.usuarioAdmVisualizaApenasChamadosDoSetorVinculado)
                            }}><p style={{ fontSize: 12, fontWeight: "bold", color: this.props.coresLetraPadrao, fontFamily: 'Source Sans Pro' }}>CARREGAR MAIS CHAMADOS</p></div>
                        </div>
                    )

                } else {
                    return (
                        null
                    )
                }
            }
        } else {
            return null

        }
    }
    updateSearch = search => {
        let state = this.state;
        state.search = search;
        state.clientSelected = null;
        this.setState({ state });

        // this.searchText(search)
    };
    hideTooltips() {
        ReactTooltip.hide()
        setTimeout(
            () => {
                ReactTooltip.rebuild();
                ReactTooltip.show();

            },
            100
        )
    }
    removeFiltrosAll() {
        //this.props.ativaLoadingTelaAberturaChamadoRemoveFiltro(true)
        setTimeout(() => {
            this.props.listaOsUltimosChamadosAbertos(0, '', [], 'willmount', this.props.verificaAtendenteVeSomenteProprioAtendimento, this.props.verificaAgrupaAtendenteSetor, this.props.isAdmin, this.props.usuarioAdmVisualizaApenasChamadosDoSetorVinculado);
            this.updateSearch('')
            this.props.modificaFiltroSelecionadoTelaChamadoAberto('');
            this.props.modificaFiltroSelecionadoTelaChamadoCancelado('');
            this.props.modificaFiltroSelecionadoTelaChamadoEmAtendimento('');
            this.props.modificaFiltroSelecionadoTelaChamadoFechado('');
            this.props.modificaFiltroSelecionadoTelaChamadoTodos('');
            this.props.modificaFiltroChamado('');
            this.props.modificaSelectTextChamado('');
            this.setState({ referenciaUltimaKeys: [] });
        }, 200);
    }
    removeFiltrosFlatList() {
        this.props.modificaFiltroSelecionadoTelaChamadoAberto('');
        this.props.modificaFiltroSelecionadoTelaChamadoCancelado('');
        this.props.modificaFiltroSelecionadoTelaChamadoEmAtendimento('');
        this.props.modificaFiltroSelecionadoTelaChamadoFechado('');
        this.props.modificaFiltroSelecionadoTelaChamadoTodos('');
        this.props.modificaFiltroChamado('');
        this.props.modificaSelectTextChamado('');
    }
    filtrarChamado(key) {
        this.props.desligaOuvintesChamadosFirebase('atendimentoAberto', this.props.verificaAtendenteVeSomenteProprioAtendimento, this.props.verificaAgrupaAtendenteSetor, this.props.isAdmin, this.props.usuarioAdmVisualizaApenasChamadosDoSetorVinculado, this.state.referenciaUltimaKeys);
        this.props.modificaFiltroSelecionadoTelaChamadoAberto(key)
        if (this.props.filtroChamado == 'Vencimento') {
            this.props.listaChamadosAbertoComFiltroVencimento(0, '', [], 'willmount', this.props.verificaAtendenteVeSomenteProprioAtendimento, this.props.isAdmin, this.props.filtroChamado, 'Aberto', key)
        } else {
            this.props.listaChamadosAbertoComFiltro(0, '', [], 'willmount', this.props.verificaAtendenteVeSomenteProprioAtendimento, this.props.isAdmin, this.props.filtroChamado, 'Aberto', key);
        }

    };
    exibeUltimosChamadosAbertos() {

        if (this.props.chamados.length > 1 && this.props.loadingTelaChamadoAberto == false) {
            if (this.props.filtroChamado !== 'Número Chamado' && this.props.filtroChamado !== 'Problema' && this.props.filtroChamado !== 'Vencimento' && this.props.filtroSelecionado.length > 0) {
                //
                this.props.listaChamadosAbertoComFiltro(1, this.props.referenciaUltimaKey, this.props.chamados, '', this.props.verificaAtendenteVeSomenteProprioAtendimento, this.props.isAdmin, this.props.filtroChamado, 'Aberto', this.props.filtroSelecionado);
            } else if (this.props.filtroChamado == 'Vencimento') {


            }
            else if (this.props.filtroChamado == 'Número Chamado') {
                // this.props.listaChamadosAbertoComFiltroDigitado(1, this.props.referenciaUltimaKey, this.props.chamados, '', this.props.verificaAtendenteVeSomenteProprioAtendimento, this.props.contaPrincipal, this.props.filtroChamado, 'Aberto', this.state.search);
            } else {

                this.props.listaOsUltimosChamadosAbertos(1, this.props.referenciaUltimaKey, this.props.chamados, '', this.props.verificaAtendenteVeSomenteProprioAtendimento, this.props.verificaAgrupaAtendenteSetor, this.props.isAdmin, this.props.usuarioAdmVisualizaApenasChamadosDoSetorVinculado)
                if (!!this.props.referenciaUltimaKey) {
                    const hasIncludeKey = this.state.referenciaUltimaKeys.filter(entry => entry === this.props.referenciaUltimaKey).length > 0;
                    if (!hasIncludeKey) {
                        const newKeys = [...this.state.referenciaUltimaKeys, this.props.referenciaUltimaKey];
                        this.setState({ referenciaUltimaKeys: newKeys });
                    }

                }
            }
        } else {
            this.props.ativaDesativaLoadingFinalFlatListChamadoAbertoExport(false)
        }


    }
    renderLoadingChamadoAberto() {
        if (this.props.loadingTelaChamadoAberto) {
            return (
                <div style={{ flex: 1, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <ContainerChamadoLoading type="button" backgroundinamico={this.props.backgroundMaisEscuro}>
                        <ContainerImagemLoading>
                            <ImageLoading backgroundinamico={this.props.backgroundMaisEscuro}></ImageLoading>
                        </ContainerImagemLoading>
                        <ContainerDadosChamado>
                            <ContaineDadosChamadosPrincipal>
                                <ContainerIconeEFrase>
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>


                                <ContainerIconeEFrase >
                                    {/*   <i className="icon-comment2" style={{ color: this.props.coresLetraPadrao, fontSize: 11 }} /> */}
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>
                                <ContainerIconeEFrase >
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>

                            </ContaineDadosChamadosPrincipal>
                            <ContaineDadosChamadosSecundario>
                                <ContainerIconeEFrase >

                                    <TextClienteLoading style={{ marginLeft: 4 }} backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>

                                <ContainerIconeEFrase >
                                    <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>
                                <ContainerIconeEFrase >
                                    <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>
                            </ContaineDadosChamadosSecundario>
                        </ContainerDadosChamado>
                    </ContainerChamadoLoading>
                    <ContainerChamadoLoading type="button" backgroundinamico={this.props.backgroundMaisEscuro}>
                        <ContainerImagemLoading>
                            <ImageLoading backgroundinamico={this.props.backgroundMaisEscuro}></ImageLoading>
                        </ContainerImagemLoading>
                        <ContainerDadosChamado>
                            <ContaineDadosChamadosPrincipal>
                                <ContainerIconeEFrase>
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>


                                <ContainerIconeEFrase >
                                    {/*   <i className="icon-comment2" style={{ color: this.props.coresLetraPadrao, fontSize: 11 }} /> */}
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>
                                <ContainerIconeEFrase >
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>

                            </ContaineDadosChamadosPrincipal>
                            <ContaineDadosChamadosSecundario>
                                <ContainerIconeEFrase >

                                    <TextClienteLoading style={{ marginLeft: 4 }} backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>

                                <ContainerIconeEFrase >
                                    <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>
                                <ContainerIconeEFrase >
                                    <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>
                            </ContaineDadosChamadosSecundario>
                        </ContainerDadosChamado>
                    </ContainerChamadoLoading>
                    <ContainerChamadoLoading type="button" backgroundinamico={this.props.backgroundMaisEscuro}>
                        <ContainerImagemLoading>
                            <ImageLoading backgroundinamico={this.props.backgroundMaisEscuro}></ImageLoading>
                        </ContainerImagemLoading>
                        <ContainerDadosChamado>
                            <ContaineDadosChamadosPrincipal>
                                <ContainerIconeEFrase>
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>


                                <ContainerIconeEFrase >
                                    {/*   <i className="icon-comment2" style={{ color: this.props.coresLetraPadrao, fontSize: 11 }} /> */}
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>
                                <ContainerIconeEFrase >
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>

                            </ContaineDadosChamadosPrincipal>
                            <ContaineDadosChamadosSecundario>
                                <ContainerIconeEFrase >

                                    <TextClienteLoading style={{ marginLeft: 4 }} backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>

                                <ContainerIconeEFrase >
                                    <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>
                                <ContainerIconeEFrase >
                                    <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>
                            </ContaineDadosChamadosSecundario>
                        </ContainerDadosChamado>
                    </ContainerChamadoLoading>
                    <ContainerChamadoLoading type="button" backgroundinamico={this.props.backgroundMaisEscuro}>
                        <ContainerImagemLoading>
                            <ImageLoading backgroundinamico={this.props.backgroundMaisEscuro}></ImageLoading>
                        </ContainerImagemLoading>
                        <ContainerDadosChamado>
                            <ContaineDadosChamadosPrincipal>
                                <ContainerIconeEFrase>
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>


                                <ContainerIconeEFrase >
                                    {/*   <i className="icon-comment2" style={{ color: this.props.coresLetraPadrao, fontSize: 11 }} /> */}
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>
                                <ContainerIconeEFrase >
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>

                            </ContaineDadosChamadosPrincipal>
                            <ContaineDadosChamadosSecundario>
                                <ContainerIconeEFrase >

                                    <TextClienteLoading style={{ marginLeft: 4 }} backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>

                                <ContainerIconeEFrase >
                                    <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>
                                <ContainerIconeEFrase >
                                    <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>
                            </ContaineDadosChamadosSecundario>
                        </ContainerDadosChamado>
                    </ContainerChamadoLoading>
                    <ContainerChamadoLoading type="button" backgroundinamico={this.props.backgroundMaisEscuro}>
                        <ContainerImagemLoading>
                            <ImageLoading backgroundinamico={this.props.backgroundMaisEscuro}></ImageLoading>
                        </ContainerImagemLoading>
                        <ContainerDadosChamado>
                            <ContaineDadosChamadosPrincipal>
                                <ContainerIconeEFrase>
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>


                                <ContainerIconeEFrase >
                                    {/*   <i className="icon-comment2" style={{ color: this.props.coresLetraPadrao, fontSize: 11 }} /> */}
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>
                                <ContainerIconeEFrase >
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>

                            </ContaineDadosChamadosPrincipal>
                            <ContaineDadosChamadosSecundario>
                                <ContainerIconeEFrase >

                                    <TextClienteLoading style={{ marginLeft: 4 }} backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>

                                <ContainerIconeEFrase >
                                    <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>
                                <ContainerIconeEFrase >
                                    <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>
                            </ContaineDadosChamadosSecundario>
                        </ContainerDadosChamado>
                    </ContainerChamadoLoading>
                    <ContainerChamadoLoading type="button" backgroundinamico={this.props.backgroundMaisEscuro}>
                        <ContainerImagemLoading>
                            <ImageLoading backgroundinamico={this.props.backgroundMaisEscuro}></ImageLoading>
                        </ContainerImagemLoading>
                        <ContainerDadosChamado>
                            <ContaineDadosChamadosPrincipal>
                                <ContainerIconeEFrase>
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>


                                <ContainerIconeEFrase >
                                    {/*   <i className="icon-comment2" style={{ color: this.props.coresLetraPadrao, fontSize: 11 }} /> */}
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>
                                <ContainerIconeEFrase >
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>

                            </ContaineDadosChamadosPrincipal>
                            <ContaineDadosChamadosSecundario>
                                <ContainerIconeEFrase >

                                    <TextClienteLoading style={{ marginLeft: 4 }} backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>

                                <ContainerIconeEFrase >
                                    <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>
                                <ContainerIconeEFrase >
                                    <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>
                            </ContaineDadosChamadosSecundario>
                        </ContainerDadosChamado>
                    </ContainerChamadoLoading>
                </div>
            )
        } else {
            return (
                <Container ref={ref => { this.myRef = ref }}>
                    {this.props.filtroChamado !== '' && this.props.filtroChamado == 'Número Chamado' &&
                        <div style={{ justifyContent: 'center', alignItems: 'center' }}>
                            <p style={{ marginTop: 15, marginLeft: 40, color: this.props.corLetraDescricaoDosCampos }}>{this.props.filtroChamado}:</p>
                            <ContainerInputSearch backGroundDinamico={this.props.backgroundMaisEscuro}>
                                <IconeSair className="icon-close" onClick={() => { this.removeFiltrosAll(); }} />
                                <div className="App" style={{ borderRadius: 3, height: 30 }}>
                                    <InputFiltroPorNumero
                                        autoFocus
                                        corLetraDinamico={this.props.coresLetraPadrao}
                                        backgroundDinamico={this.props.backgroundMaisEscuro}
                                        placeholderColor={this.props.corLetraSecundaria}
                                        corBordaDinamica={this.props.corBotaoPadrao}
                                        type="number"
                                        placeholder={this.props.selectTextChamado}
                                        onChange={(value) => this.updateSearch(value.target.value)}
                                        value={this.state.search}
                                    />
                                    {this.state.search !== '' && <BotaoPesquisarChamadoPorNumero onClick={() => { this.pesquisaComDigito(this.state.search) }} backGroundDinamico={this.props.corBotaoPadrao} type="button">
                                        <i className="icon-magnifier" style={{ color: this.props.letraBotaoPadrao, fontSize: 13 }} />
                                    </BotaoPesquisarChamadoPorNumero>}
                                </div>
                            </ContainerInputSearch>
                        </div>}
                    {this.props.filtroChamado !== '' && this.props.filtroChamado !== 'Número Chamado' &&
                        <div style={{ justifyContent: 'center', alignItems: 'center' }}>
                            <p style={{ marginTop: 15, marginLeft: 40, color: this.props.corLetraDescricaoDosCampos }}>{this.props.filtroChamado}:</p>
                            <ContainerInputSearch backGroundDinamico={this.props.backgroundMaisEscuro}>
                                <IconeSair className="icon-close" onClick={() => { this.removeFiltrosAll(); }} />
                                <div style={{ background: this.props.corSegundoBotaoPadrao, borderRadius: 3, height: 30 }}>

                                    {
                                        ((this.props.filtroChamado !== "Cliente") ||
                                            (this.props.filtroChamado === "Cliente" && this.props.clientesSectionedConsulta.length > 0))
                                        && <SelectSearch
                                            renderValue={(valueProps) =>
                                                <DivInputSearch>
                                                    <InputSearch
                                                        corLetraDinamico={this.props.coresLetraPadrao}
                                                        backgroundDinamico={this.props.backgroundMaisEscuro}
                                                        placeholderColor={this.props.coresLetraSecundario}
                                                        corBordaDinamica={this.props.corBotaoPadrao}
                                                        {...valueProps} ></InputSearch>
                                                    <IconeSetaPraBaixo className="icon-angle-arrow-down" colorDinamico={this.props.coresLetraPadrao} />
                                                </DivInputSearch>
                                            }
                                            autoFocus
                                            value={this.props.filtroSelecionado}
                                            options={this.props.chamadosComFiltro}
                                            onChange={(value) => this.filtrarChamado(value)}
                                            placeholder={this.props.chamadosComFiltro.length == 0 ? `Nenhum ${this.props.filtroChamado} cadastrado, Por favor verifique!` : this.props.selectTextChamado}
                                            search={this.props.chamadosComFiltro.length == 0 ? false : true}
                                        />}

                                    {this.props.filtroChamado === "Cliente" && this.props.clientesSectionedConsulta.length === 0 && <div style={{ display: 'flex', flex: 1 }}>
                                        <SelectSearchPaginate
                                            onSelectClient={(client) => {

                                                this.setState({ clientSelected: { value: client.value, label: client.label } });
                                                this.filtrarChamado(client.idUser)

                                            }}
                                            value={this.state.clientSelected}
                                            isMyContact={false}
                                            background={this.props.backgroundMaisEscuro}
                                            letterColor={this.props.coresLetraPadrao}
                                            backgroundDinamico={this.props.backgroundMaisEscuro}
                                            placeholderColor={this.props.coresLetraSecundario}
                                            corBordaDinamica={this.props.corBotaoPadrao}
                                            width={400}
                                            letraBotaoPadrao={this.props.letraBotaoPadrao}
                                        />
                                    </div>}

                                </div>
                            </ContainerInputSearch>
                        </div>}
                    <FlatList
                        list={this.props.chamados}
                        renderItem={(item) => <FlatListChamadoAberto data={item} key={item.key + item.alterado} />}
                        renderWhenEmpty={() => {
                            if (this.props.filtroSelecionado !== '' || this.state.search !== '') {
                                return (
                                    <ContainerListaVazia>
                                        <i className="icon-box" style={{ color: this.props.coresLetraPadrao, fontSize: 110 }} />
                                        <p style={{ color: this.props.coresLetraPadrao, fontSize: 20 }}>Nenhum registro encontrado</p>
                                    </ContainerListaVazia>
                                )
                            } else {
                                return (
                                    <ContainerListaVazia >
                                        <i className="icon-box" style={{ color: this.props.coresLetraPadrao, fontSize: 110 }} />
                                        <p style={{ color: this.props.coresLetraPadrao, fontSize: 20 }}>Nenhum Chamado Aberto</p>
                                    </ContainerListaVazia>
                                )
                            }
                        }}
                        hasMoreItems={this.props.loadingFimDaPagina}
                        loadMoreItems={() => this.exibeUltimosChamadosAbertos()}
                        paginationLoadingIndicator={<MoonLoader
                            css={{ margin: 10 }}
                            size={40}
                            color={this.props.corBotaoPadrao}
                            loading={this.props.loadingFimDaPagina}
                        />}
                    //sortBy={["firstName", { key: "lastName", descending: true }]}
                    // groupBy={person => person.info.age > 18 ? 'Over 18' : 'Under 18'}
                    />
                    {/*  </div> */}
                    {/*  {this.renderCarregaMaisResultados()} */}
                </Container>
            )
        }
    }

    render() {
        return (
            <>
                {this.renderLoadingChamadoAberto()}
            </>
        );
    }
}

const mapStateToProps = state => {
    return (
        {
            backgroundPrimeiro: state.CoresPadraoSistema.backgroundPrimeiro,
            backgroundSegundo: state.CoresPadraoSistema.backgroundSegundo,
            backgroundMaisEscuro: state.CoresPadraoSistema.backgroundMaisEscuro,
            coresLetraPadrao: state.CoresPadraoSistema.coresLetraPadrao,
            corBotaoPadrao: state.CoresPadraoSistema.corBotaoPadrao,
            coresLetraSecundario: state.CoresPadraoSistema.coresLetraSecundario,
            corLetraDescricaoDosCampos: state.CoresPadraoSistema.corLetraDescricaoDosCampos,
            corSegundoBotaoPadrao: state.CoresPadraoSistema.corSegundoBotaoPadrao,
            letraBotaoPadrao: state.CoresPadraoSistema.letraBotaoPadrao,
            nomeRota: state.HeaderReducer.nomeRota,
            testePesquisa: state.HeaderReducer.testePesquisa,
            email: state.LoginReducer.email,

            chamados: state.ChamadoReducer.chamados,
            verificaConexaoInternetListaChamadoAberto: state.ChamadoReducer.verificaConexaoInternetListaChamadoAberto,
            loadingTelaChamadoAberto: state.ChamadoReducer.loadingTelaChamadoAberto,
            refreshControlFlatListChamadoAberto: state.ChamadoReducer.refreshControlFlatListChamadoAberto,
            referenciaUltimaKey: state.ChamadoReducer.referenciaUltimaKey,
            loadingFimDaPagina: state.ChamadoReducer.loadingFimDaPagina,
            // premium: state.PremiumReducer.premium,
            verificaAgrupaAtendenteSetor: state.ChamadoReducer.verificaAgrupaAtendenteSetor,
            verificaAtendenteVeSomenteProprioAtendimento: state.ChamadoReducer.verificaAtendenteVeSomenteProprioAtendimento,
            contaPrincipal: state.AtendenteReducer.contaPrincipal,
            filtroChamado: state.ChamadoReducer.filtroChamado,
            chamadosComFiltro: state.ChamadoReducer.chamadosComFiltro,
            filtroSelecionado: state.ChamadoReducer.filtroSelecionado,
            selectTextChamado: state.ChamadoReducer.selectTextChamado,
            pesquisaTextChamado: state.ChamadoReducer.pesquisaTextChamado,
            licensawebouapp: state.PremiumReducer.licensawebouapp,
            loadingMaisResultadosChamados: state.ChamadoReducer.loadingMaisResultadosChamados,
            keyUsuarioLogado: state.PerfilReducer.keyUsuarioLogado,

            isAdmin: state.PerfilReducer.isAdmin,

            usuarioAdmVisualizaApenasChamadosDoSetorVinculado: state.PerfilReducer.usuarioAdmVisualizaApenasChamadosDoSetorVinculado,
            clientesSectionedConsulta: state.ChamadoReducer.clientesSectionedConsulta,
        }
    );
};
export default connect(mapStateToProps, {
    modificaPesquisaTeste, zeraMensagemSemInternetChamadoAberto,
    navegaTelaNovoChamado, modificaFiltroChamado, modificaSelectTextChamado, modificaPesquisaTextChamado,
    ativaLoadingTelaAberturaChamadoRemoveFiltro, modificaFiltroSelecionadoTelaChamadoAberto, modificaFiltroSelecionadoTelaChamadoCancelado,
    modificaFiltroSelecionadoTelaChamadoEmAtendimento, modificaFiltroSelecionadoTelaChamadoFechado, modificaFiltroSelecionadoTelaChamadoTodos,
    listaOsUltimosChamadosAbertos, listaChamadosAbertoComFiltroVencimento, listaChamadosAbertoComFiltro, listaChamadosAbertoComFiltroDigitado,
    ativaDesativaLoadingFinalFlatListChamadoAbertoExport,
    desligaOuvintesChamadosFirebase
})(ChamadoAberto);
