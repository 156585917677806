import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-responsive-modal';
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import FlatList from 'flatlist-react';
import { withRouter } from "react-router-dom";
import firebase from '../../servicos/FirebaseConnection';
import ClipLoader from "react-spinners/ClipLoader";
import SelectSearchPaginate from "../SelectSearch/index";
import MoonLoader from "react-spinners/MoonLoader";
import FlatListAvisos from "./FlatListAvisos";
import ReactTooltip from "react-tooltip";
import styled from 'styled-components';
import SelectSearch from 'react-select-search';
import { toastr } from 'react-redux-toastr';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController, isSameDay } from 'react-dates';
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import pt from 'date-fns/locale/pt-BR';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import imgTeste from '../../imagens/userPadrao.png';
import imglink from '../../imagens/link.png'
import imgunlink from '../../imagens/unlink.png'
import imgexcluirCliente from '../../imagens/delete-user.png';
import { modificaNomeRotaNoHeader } from '../../actions/HeaderAction';
import './stylesSearch.css';
import { desligaOuvintesChamadosFirebase } from "../../actions/DashBoardAction";
import { listaAtendentesTelaConsultaAtendente } from "../../actions/AtendenteAction";
import { listaClientesTelaConsultaClientes } from "../../actions/ChamadoAction";


import {
    modificaDestinatarioSelecionado, modificaAtendenteSelecionado,
    modificaClienteSelecionado, alteraDescricaoMensagemNovoAviso,
    alteraMensagemNovoAvisoAudio, zeramensagemEnviaAvisoError,
    alteraValorSwitchNovoAviso, modificaDataInicialNovoAviso, modificaDataFinalNovoAviso,
    alteraStatusAviso, alteraFiltroSelecionadoTelaNovoAviso, modificaDataInicialEFinalAlterarAviso,
    modificaVisibleModalNovoAviso, consultaAviso,
    consultaAvisoPorAtendente, consultaAvisoPorCliente, consultaAvisoPorStatusAtivo,
    consultaAvisoPorStatusInativo, alterarAviso2,

    alteraDescricaoMensagemNovoAvisoNovoCadastro, modificaDataFinalNovoAvisoNovoCadastro,
    modificaDataInicialNovoAvisoNovoCadastro, alteraValorSwitchNovoAvisoNovoCadastro,
    modificaClienteSelecionadoNovoCadastro, modificaAtendenteSelecionadoNovoCadastro,
    modificaDestinatarioSelecionadoNovoCadastro, criarAviso2
} from "../../actions/AvisoAction";

import {
    ContainerDadosCliente, Container, IconeSair, ContainerInputSearch, SubContainer, SubContainerCliente,
    ContainerInputDescricaoChamado, ContainerPrincipalDadosCliente, InputEmpresa, SubContainerClientePermissao,
    ContainerPermitir, TextPermitir, InputDescricaoChamado, ContainerBotaoAlterar, ButtonEntrar,
    DivIconeExcluirCliente, IconeExcluirCliente, DivContainerInputSenha, IconeMostrarSenha,
    IconeEmpresaVinculada, DivContainerInputEmpresaVinculada, ContainerModal, ContainerIconeFecharModal,
    TextNovoAtendimento, IconeFechar, ContainerImagemVincularEmpresa, ContainerExplicacaoVincular,
    ButtonVincular, TextVincular, ImagePerfil, IconeEmpresaVinculadaOff, ContainerModalNovoCliente,
    ContainerListaVazia, ContainerPeriodoExibicao, BotaoInputDateInicial, ContainerSelecionarPeriodo,
    BotaoInputDateFinal, IconeVariosNovos,
    InputSearch, DivInputSearch, IconeSetaPraBaixo, DivInputSearchFiltro,


    ContainerChamado, ContainerImagem, ContainerDadosChamado,
    ContaineDadosChamadosPrincipal,
    ContainerIconeEFrase, TextCliente,
    SubContainerIconeEFraseClienteAtendente,
    ContainerSeta, TextEmpresa, ImageLoading, ContainerChamadoLoading, TextClienteLoading, ImagemPerfilLoading,
    InputLoadingEmpresa, ContainerDadosClienteLoading, SubContainerClientePermissaoLoading,
    ContainerPrincipalDadosClienteLoading, SubContainerClienteLoading
} from './styled';

const AsyncStorage = {
    setItem: function (key, value) {
        return Promise.resolve().then(function () {
            localStorage.setItem(key, value);
        });
    },
    getItem: function (key) {
        return Promise.resolve().then(function () {
            return localStorage.getItem(key);
        });
    }
};

class Avisos extends React.Component {
    state = {
        filtroTeste: false,
        hasMoreItems: false,
        modalDesvinculaCliente: false,
        offset: 0,
        fotoCliente: imgTeste,
        search: '',
        inputFicticio: '',
        checkFicticio: false,
        maisDados: [],
        tipoInputSenha: 'password',
        tipoInputConfirmaSenha: 'password',
        loading: true,
        visaoInputSenha: false,
        visaoInputConfirmaSenha: false,
        pesquisaCliente: false,
        modalNovoCliente: false,
        modalPesquisaCliente: false,
        dataInicial: '',
        dataFinal: '',
        clientSelected: null,
        clientSelectedNovoAviso: null,
        arrayAtendentesAvisos: [],
        arrayClientesAvisos: []
    }

    componentWillMount() {

        let atendentes = this.props.atendentes.length > 0 ? JSON.parse(JSON.stringify(this.props.atendentes[0].items)) : [];

        if (atendentes.length > 0 && atendentes.filter(e => e.value === 'TodosPadrao').length > 0) {

        } else {
            atendentes.push({ value: "TodosPadrao", name: "TODOS" });
        }


        const items = [{
            name: 'ATENDENTES',
            type: 'group', items: atendentes
        }];

        this.setState({ arrayAtendentesAvisos: items });


        let clientes = this.props.clientes.length > 0 ? JSON.parse(JSON.stringify(this.props.clientes)) : [];

        if (clientes.length > 0 && clientes.filter(e => e.name === 'Todos os Clientes').length > 0) {

        } else {
            const itemsClientes2 = {
                name: 'Todos os Clientes',
                type: 'group', items: [{ value: 'TodosPadrao', name: 'TODOS', empresa: 'Todos os Clientes', keyEmpresaVinculada: 'Não Vinculado' }]
            };
            clientes.push(itemsClientes2);
        }


        /*  const itemsClientes = [{
             name: 'EMPRESAS',
             type: 'group', items: clientes
         }]; */

        this.setState({ arrayClientesAvisos: clientes });
    }

    componentWillUnmount() {
        this.props.desligaOuvintesChamadosFirebase('consultaavisos', '', '', '');

    }

    componentDidMount() {
        if (this.props.premium == 0) {
            toastr.error('Recurso Premium', 'Este é um recurso PREMIUM, Assine a versão PREMIUM e libere esse e mais recursos!');
            this.props.history.push('/dashboard')

        } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
            toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
            this.props.history.push('/dashboard')

        } else {
            if (this.props.permissoesAcessarTelasAtendente.permissaoAlterarCadastrarAvisos == true) {
                this.props.consultaAviso(0, '', []);
                //this.props.listaAtendentesNovoAviso();
                // this.props.listaClienteNovoAviso();
                this.props.listaClientesTelaConsultaClientes('avisos')
                this.props.listaAtendentesTelaConsultaAtendente('avisos')
                ReactTooltip.rebuild();
                if (this.props.nomeRota !== 'Mural de Avisos') {
                    this.props.modificaNomeRotaNoHeader('Mural de Avisos')
                }
            } else {
                toastr.error('Acesso Negado', 'Você não tem permissão para acessar esta tela');
                this.props.history.push('/dashboard')
            }
        }


    }

    componentWillReceiveProps(nextProps) {
        // ReactTooltip.rebuild();

        if (this.props.atendentes !== nextProps.atendentes) {


            if (nextProps.atendentes.length > 0) {

                let atendentes = nextProps.atendentes.length > 0 ? JSON.parse(JSON.stringify(nextProps.atendentes[0].items)) : [];
                if (atendentes.length > 0 && atendentes.filter(e => e.value === 'TodosPadrao').length > 0) {

                } else {
                    atendentes.push({ value: "TodosPadrao", name: "TODOS" });
                }

                // nextProps.atendentes.push({ value: "TodosPadrao", name: "TODOS" });
                const items = [{
                    name: 'ATENDENTES',
                    type: 'group', items: atendentes
                }];

                this.setState({ arrayAtendentesAvisos: items })
            } else if (this.props.atendentes.length > 0) {

                let atendentes = this.props.atendentes.length > 0 ? JSON.parse(JSON.stringify(this.props.atendentes[0].items)) : [];

                if (atendentes.length > 0 && atendentes.filter(e => e.value === 'TodosPadrao').length > 0) {

                } else {
                    atendentes.push({ value: "TodosPadrao", name: "TODOS" });
                }

                // this.props.atendentes.push({ value: "TodosPadrao", name: "TODOS" });
                const items = [{
                    name: 'ATENDENTES',
                    type: 'group', items: atendentes
                }];

                this.setState({ arrayAtendentesAvisos: items })
            }


        }
        if (this.props.clientes !== nextProps.clientes) {


            if (nextProps.clientes.length > 0) {

                let clientes = nextProps.clientes.length > 0 ? JSON.parse(JSON.stringify(nextProps.clientes)) : [];
                if (clientes.length > 0 && clientes.filter(e => e.name === 'Todos os Clientes').length > 0) {

                } else {
                    const itemsClientes2 = {
                        name: 'Todos os Clientes',
                        type: 'group',
                        items: [{ value: 'TodosPadrao', name: 'TODOS', empresa: 'Todos os Clientes', keyEmpresaVinculada: 'Não Vinculado' }]
                    };
                    clientes.push(itemsClientes2);
                }

                // nextProps.atendentes.push({ value: "TodosPadrao", name: "TODOS" });
                /*   const items = [{
                      name: 'EMPRESAS',
                      type: 'group', items: clientes
                  }];
                  */
                this.setState({ arrayClientesAvisos: clientes })
            } else if (this.props.clientes.length > 0) {

                let clientes = this.props.clientes.length > 0 ? JSON.parse(JSON.stringify(this.props.clientes)) : [];

                if (clientes.length > 0 && clientes.filter(e => e.name === 'Todos os Clientes').length > 0) {

                } else {
                    const itemsClientes2 = {
                        name: 'Todos os Clientes',
                        type: 'group',
                        items: [{ value: 'TodosPadrao', name: 'TODOS', empresa: 'Todos os Clientes', keyEmpresaVinculada: 'Não Vinculado' }]
                    };
                    clientes.push(itemsClientes2);
                }

                // this.props.atendentes.push({ value: "TodosPadrao", name: "TODOS" });
                /*  const items = [{
                     name: 'EMPRESAS',
                     type: 'group', items: clientes
                 }];
                 */
                this.setState({ arrayClientesAvisos: clientes })
            }


        }
        setTimeout(() => {
            ReactTooltip.rebuild();
        }, 500);

    }

    handleDataInicial(date) {
        this.setState({ dataInicial: date });
    }

    handleDataFinal(date) {
        this.setState({ dataFinal: date });
    }

    _toggleMostrarSenha() {
        this.setState({ visaoInputSenha: !this.state.visaoInputSenha });
    }

    _toggleSectionedPesquisaCliente() {
        this.setState({ pesquisaCliente: !this.state.pesquisaCliente });
    }

    _toggleModalPesquisarCliente() {
        this.setState({ modalPesquisaCliente: !this.state.modalPesquisaCliente });
    }

    _toggleModalDesvincularCliente() {
        this.setState({ modalDesvinculaCliente: !this.state.modalDesvinculaCliente });
    }

    handleVisualizaSenha(value) {
        let state = this.state;
        state.tipoInputSenha = value;
        this.setState({ state });
    }

    handleVisualizaConfirmaSenha(value) {
        let state = this.state;
        state.tipoInputConfirmaSenha = value;
        this.setState({ state });
    }

    handleModalNovoCliente(value) {
        let state = this.state;
        state.modalNovoCliente = value;
        this.setState({ state });
    }

    updateSearch = search => {
        let state = this.state;
        state.search = search;
        this.setState({ state });

        // this.searchText(search)
    };
    handleWeekDays = (day) => {
        //Change week day with custom day array
        day._locale._weekdaysMin = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'];
        // return the actual dates value(like 1,2,3 ...) from the moment object.
        return (day.format('D'));
    }

    removeFiltrosAll() {
        //this.props.ativaLoadingTelaAberturaChamadoRemoveFiltro(true)
        setTimeout(() => {
            // this.props.listaOsUltimosChamadosAbertos(0, '', [], 'willmount', this.props.verificaAtendenteVeSomenteProprioAtendimento, this.props.verificaAgrupaAtendenteSetor, this.props.contaPrincipal);
            this.props.alteraFiltroSelecionadoTelaNovoAviso([]);
        }, 200);
    }

    renderFontValue(valueProps, snapshot, className) {
        const { value } = snapshot;
        //
        //
        //
        const style = {
            color: this.props.coresLetraPadrao,
            background: this.props.backgroundMaisEscuro,
            border: `0.5px solid ${this.props.coresLetraSecundario}`,
        };

        return (
            <input {...valueProps} className={className} style={style} value={snapshot.displayValue} />
        );
    }

    exibeUltimosClientes() {
        /* if (this.props.filtrosSelecionado == 'TODOS') { */
        if (this.props.loadingConsultaAviso == false) {
            this.props.consultaAviso(1, this.props.referenciaUltimaKeyAviso, this.props.avisos);
        }
        /*   } */
    }

    renderLoadingAvisos() {
        registerLocale('pt-BR', pt);
        setDefaultLocale("pt-BR");
        const CustomInputDateInicial = ({ value, onClick }) => (
            <>
                <BotaoInputDateInicial corBordaDinamica={this.props.corBotaoPadrao} placeholder="Data Inicial"
                    placeHoldarDinamico={this.props.coresLetraSecundario} corLetraDinamica={this.props.coresLetraPadrao}
                    backGroundDinamico={this.props.backgroundMaisEscuro} onClick={onClick}>
                    <IconeVariosNovos className="icon-vencimento"
                        colorPrimario={this.props.coresLetraPadrao} /> {(value == '') ? "Data Inicial" : value}<span>  </span>
                </BotaoInputDateInicial>
            </>
        );
        const CustomInputDateFinal = ({ value, onClick }) => (
            <>

                <BotaoInputDateFinal corBordaDinamica={this.props.corBotaoPadrao} placeholder="Data Final"
                    placeHoldarDinamico={this.props.coresLetraSecundario} corLetraDinamica={this.props.coresLetraPadrao}
                    backGroundDinamico={this.props.backgroundMaisEscuro} onClick={onClick}>
                    <IconeVariosNovos className="icon-vencimento" colorPrimario={this.props.coresLetraPadrao} /> {(value == '') ? "Data Final" : value}
                    <span>  </span>
                </BotaoInputDateFinal>
            </>
        );


        const CheckBoxWrapper = styled.div`
          position: relative;
          margin-left: 10px;
          height: 24px;
        `;
        const CheckBoxLabel = styled.label`
          position: absolute;
          top: 0;
          left: 0;
          width: 42px;
          height: 23px;
          border-radius: 15px;
          background: ${this.props.coresLetraSecundario};

          cursor: pointer;

          &::after {
            content: "";
            display: block;
            border-radius: 50%;
            width: 18px;
            height: 18px;
            margin: 3px;
            background: #ffffff;
            box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
            transition: 0.2s;
          }
        `;
        const CheckBox = styled.input`
          opacity: 0;
          z-index: 1;

          border-radius: 15px;
          width: 42px;
          height: 26px;

          &:checked + ${CheckBoxLabel} {
            background: #00FF7F;

            &::after {
              content: "";
              display: block;
              border-radius: 50%;
              width: 18px;
              height: 18px;
              margin-left: 21px;
              transition: 0.2s;
            }
          }
        `;
        if (this.props.loadingConsultaAviso) {
            return (
                <Container>
                    <SubContainer style={{ flex: 0.7 }}>
                        <ContainerChamadoLoading backGroundDinamico={this.props.backgroundMaisEscuro}>

                            <ContainerDadosChamado>
                                <ContaineDadosChamadosPrincipal>
                                    <SubContainerIconeEFraseClienteAtendente>
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                    <SubContainerIconeEFraseClienteAtendente>
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                </ContaineDadosChamadosPrincipal>


                            </ContainerDadosChamado>


                        </ContainerChamadoLoading>
                        <ContainerChamadoLoading backGroundDinamico={this.props.backgroundMaisEscuro}>

                            <ContainerDadosChamado>
                                <ContaineDadosChamadosPrincipal>
                                    <SubContainerIconeEFraseClienteAtendente>
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                    <SubContainerIconeEFraseClienteAtendente>
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                </ContaineDadosChamadosPrincipal>


                            </ContainerDadosChamado>


                        </ContainerChamadoLoading>
                        <ContainerChamadoLoading backGroundDinamico={this.props.backgroundMaisEscuro}>

                            <ContainerDadosChamado>
                                <ContaineDadosChamadosPrincipal>
                                    <SubContainerIconeEFraseClienteAtendente>
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                    <SubContainerIconeEFraseClienteAtendente>
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                </ContaineDadosChamadosPrincipal>


                            </ContainerDadosChamado>


                        </ContainerChamadoLoading>
                        <ContainerChamadoLoading backGroundDinamico={this.props.backgroundMaisEscuro}>

                            <ContainerDadosChamado>
                                <ContaineDadosChamadosPrincipal>
                                    <SubContainerIconeEFraseClienteAtendente>
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                    <SubContainerIconeEFraseClienteAtendente>
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                </ContaineDadosChamadosPrincipal>


                            </ContainerDadosChamado>


                        </ContainerChamadoLoading>
                        <ContainerChamadoLoading backGroundDinamico={this.props.backgroundMaisEscuro}>

                            <ContainerDadosChamado>
                                <ContaineDadosChamadosPrincipal>
                                    <SubContainerIconeEFraseClienteAtendente>
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                    <SubContainerIconeEFraseClienteAtendente>
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                </ContaineDadosChamadosPrincipal>


                            </ContainerDadosChamado>


                        </ContainerChamadoLoading>
                        <ContainerChamadoLoading backGroundDinamico={this.props.backgroundMaisEscuro}>

                            <ContainerDadosChamado>
                                <ContaineDadosChamadosPrincipal>
                                    <SubContainerIconeEFraseClienteAtendente>
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                    <SubContainerIconeEFraseClienteAtendente>
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                </ContaineDadosChamadosPrincipal>


                            </ContainerDadosChamado>


                        </ContainerChamadoLoading>
                        <ContainerChamadoLoading backGroundDinamico={this.props.backgroundMaisEscuro}>

                            <ContainerDadosChamado>
                                <ContaineDadosChamadosPrincipal>
                                    <SubContainerIconeEFraseClienteAtendente>
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                    <SubContainerIconeEFraseClienteAtendente>
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                </ContaineDadosChamadosPrincipal>


                            </ContainerDadosChamado>


                        </ContainerChamadoLoading>
                    </SubContainer>

                    <ContainerPrincipalDadosClienteLoading backGroundDinamico={this.props.backgroundSegundo}>

                        <ContainerDadosClienteLoading>

                            <InputLoadingEmpresa backgroundinamico={this.props.backgroundSegundo}></InputLoadingEmpresa>
                            <InputLoadingEmpresa backgroundinamico={this.props.backgroundSegundo}></InputLoadingEmpresa>

                        </ContainerDadosClienteLoading>
                        <SubContainerClientePermissaoLoading>
                            <InputLoadingEmpresa backgroundinamico={this.props.backgroundSegundo}></InputLoadingEmpresa>
                            <InputLoadingEmpresa style={{ marginTop: 17 }} backgroundinamico={this.props.backgroundSegundo}></InputLoadingEmpresa>
                        </SubContainerClientePermissaoLoading>
                    </ContainerPrincipalDadosClienteLoading>

                </Container>
            )
        } else {
            return (
                <Container>
                    <SubContainer style={{ flex: this.props.avisos.length > 0 ? 0.7 : 1 }}>

                        <div style={{ justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                            <p style={{ marginLeft: 5, color: this.props.coresLetraPadrao, fontSize: 15 }}>Filtro:</p>
                            <ContainerInputSearch backGroundDinamico={this.props.backgroundMaisEscuro}>

                                <div style={{ flex: 1, borderRadius: 3, height: 30 }}>
                                    <SelectSearch
                                        renderValue={(valueProps) =>
                                            <DivInputSearchFiltro>
                                                <InputSearch
                                                    corLetraDinamico={this.props.coresLetraPadrao}
                                                    backgroundDinamico={this.props.backgroundMaisEscuro}
                                                    placeholderColor={this.props.corLetraSecundaria}
                                                    corBordaDinamica={this.props.corBotaoPadrao}
                                                    {...valueProps} ></InputSearch>
                                                <IconeSetaPraBaixo className="icon-angle-arrow-down" colorDinamico={this.props.coresLetraPadrao} />
                                            </DivInputSearchFiltro>
                                        }
                                        value={this.props.filtrosSelecionado}
                                        style={{ color: '#FFF' }}
                                        onChange={(value) => {
                                            if (value !== 'TODOS') {
                                                this.props.desligaOuvintesChamadosFirebase('consultaavisos', '', '', '');
                                            }
                                            this.props.alteraFiltroSelecionadoTelaNovoAviso(value);
                                            switch (value) {
                                                case 'TODOS':
                                                    this.props.consultaAviso(0, '', []);
                                                    break;
                                                case 'ATENDENTES':
                                                    this.props.consultaAvisoPorAtendente()
                                                    break;
                                                case 'CLIENTES':
                                                    this.props.consultaAvisoPorCliente()
                                                    break;
                                                case 'ATIVOS':
                                                    this.props.consultaAvisoPorStatusAtivo();
                                                    break;
                                                case 'INATIVOS':
                                                    this.props.consultaAvisoPorStatusInativo();
                                                    break;

                                            }
                                        }}
                                        options={this.props.filtros}
                                        search
                                    />
                                </div>
                            </ContainerInputSearch>
                        </div>
                        <FlatList

                            list={this.props.avisos}
                            renderItem={(item) => <FlatListAvisos data={item} key={item.key + item.alterado} onClickNotices={(item) => {

                                if (this.props.clientes.length > 0) {
                                    this.props.modificaClienteSelecionado(item.key)
                                } else {
                                    this.setState({
                                        clientSelected: {
                                            value: item.key,
                                            label: item.cliente === "TodosPadrao" ? "Todos os Clientes" : item.cliente
                                        }
                                    });
                                }
                            }} />}
                            renderWhenEmpty={() => {
                                return (
                                    <ContainerListaVazia>
                                        <i className="icon-box" style={{ color: this.props.coresLetraPadrao, fontSize: 110 }} />
                                        <p style={{ color: this.props.coresLetraPadrao, fontSize: 20 }}>Nenhum Registro Encontrado na Consulta</p>
                                    </ContainerListaVazia>
                                )

                            }}
                            hasMoreItems={this.props.loadingFimDaPaginaAviso}
                            loadMoreItems={() => this.exibeUltimosClientes()}
                            paginationLoadingIndicator={<MoonLoader
                                css={{ margin: 10 }}
                                size={40}
                                color={this.props.corBotaoPadrao}
                                loading={this.props.loadingFimDaPaginaAviso}
                            />}
                        />
                    </SubContainer>
                    {/*    {this.props.modalNovoAviso == true &&<div style={{
                         display:'flex',
                         flex:1,
                         flexDirection:'column',
                         margin:15,
                         borderRadius:5
                    }}>

                    </div>} */}
                    {this.props.modalNovoAviso == false && this.props.avisos.length > 0 &&
                        <ContainerPrincipalDadosCliente backGroundDinamico={this.props.backgroundSegundo}>

                            {this.props.modalNovoAviso == false && this.props.avisos.length > 0 && <SubContainerCliente>
                                <ContainerDadosCliente>
                                    {/*  <div className="themedComponent" style={{ "--bg": String(this.props.backgroundMaisEscuro) }}>
                            Make it blue
                        </div> */}
                                    <p style={{ color: this.props.corLetraDescricaoDosCampos, fontWeight: 'bold', fontSize: 13 }}>Destinatário<span
                                        style={{ color: 'red', fontSize: 14, marginLeft: 3 }}>*</span></p>
                                    <DivContainerInputEmpresaVinculada>
                                        <SelectSearch
                                            // renderValue={(valueProps, snapshot, className) => this.renderFontValue(valueProps, snapshot, className)}
                                            renderValue={(valueProps) =>
                                                <DivInputSearch>
                                                    <InputSearch
                                                        corLetraDinamico={this.props.coresLetraPadrao}
                                                        backgroundDinamico={this.props.backgroundMaisEscuro}
                                                        placeholderColor={this.props.corLetraSecundaria}
                                                        corBordaDinamica={this.props.corBotaoPadrao}
                                                        {...valueProps} ></InputSearch>
                                                    <IconeSetaPraBaixo className="icon-angle-arrow-down" colorDinamico={this.props.coresLetraPadrao} />
                                                </DivInputSearch>
                                            }
                                            onChange={(value) => this.props.modificaDestinatarioSelecionado(value)}
                                            options={this.props.destinatario}
                                            value={this.props.destinatarioSelecionado}
                                            placeholder="Selecione o Destinatário"
                                            search
                                        />

                                    </DivContainerInputEmpresaVinculada>

                                    {this.props.destinatarioSelecionadoValor == 1 && <> <p style={{
                                        color: this.props.corLetraDescricaoDosCampos,
                                        fontWeight: 'bold',
                                        fontSize: 13,
                                        marginTop: 15
                                    }}>Atendentes<span style={{ color: 'red', fontSize: 14, marginLeft: 3 }}>*</span></p>
                                        <DivContainerInputEmpresaVinculada>

                                            <SelectSearch
                                                renderValue={(valueProps) =>
                                                    <DivInputSearch>
                                                        <InputSearch
                                                            corLetraDinamico={this.props.coresLetraPadrao}
                                                            backgroundDinamico={this.props.backgroundMaisEscuro}
                                                            placeholderColor={this.props.corLetraSecundaria}
                                                            corBordaDinamica={this.props.corBotaoPadrao}
                                                            {...valueProps} ></InputSearch>
                                                        <IconeSetaPraBaixo className="icon-angle-arrow-down"
                                                            colorDinamico={this.props.coresLetraPadrao} />
                                                    </DivInputSearch>
                                                }
                                                onChange={(value) => this.props.modificaAtendenteSelecionado(value)}
                                                options={this.state.arrayAtendentesAvisos}
                                                value={this.props.atendenteSelecionado}
                                                placeholder={this.props.atendentes.length == 0 ? `Nenhum Atendente cadastrado, Por favor verifique!` : "Selecione o Atendente"}
                                                search={this.props.atendentes.length == 0 ? false : true}
                                            />


                                        </DivContainerInputEmpresaVinculada></>}
                                    {this.props.destinatarioSelecionadoValor == 2 && <> <p style={{
                                        color: this.props.corLetraDescricaoDosCampos,
                                        fontWeight: 'bold',
                                        fontSize: 13,
                                        marginTop: 15
                                    }}>Clientes<span style={{ color: 'red', fontSize: 14, marginLeft: 3 }}>*</span></p>
                                        <DivContainerInputEmpresaVinculada>

                                            {this.props.clientes.length === 0 && <div style={{ display: 'flex', flex: 1 }}>
                                                <SelectSearchPaginate
                                                    onSelectClient={(client) => {

                                                        this.setState({ clientSelected: { value: client.value, label: client.label } });
                                                        this.props.modificaClienteSelecionado(client.value);

                                                    }}
                                                    value={this.state.clientSelected}
                                                    isMyContact={false}
                                                    background={this.props.backgroundMaisEscuro}
                                                    letterColor={this.props.coresLetraPadrao}
                                                    backgroundDinamico={this.props.backgroundMaisEscuro}
                                                    placeholderColor={this.props.coresLetraSecundario}
                                                    corBordaDinamica={this.props.corBotaoPadrao}
                                                    width={400}
                                                    letraBotaoPadrao={this.props.letraBotaoPadrao}
                                                    isScreenNotices={true}
                                                />
                                            </div>}
                                            {this.props.clientes.length > 0 && <SelectSearch
                                                renderValue={(valueProps) =>
                                                    <DivInputSearch>
                                                        <InputSearch
                                                            corLetraDinamico={this.props.coresLetraPadrao}
                                                            backgroundDinamico={this.props.backgroundMaisEscuro}
                                                            placeholderColor={this.props.corLetraSecundaria}
                                                            corBordaDinamica={this.props.corBotaoPadrao}
                                                            {...valueProps} ></InputSearch>
                                                        <IconeSetaPraBaixo className="icon-angle-arrow-down"
                                                            colorDinamico={this.props.coresLetraPadrao} />
                                                    </DivInputSearch>
                                                }
                                                onChange={(value) => {
                                                    this.props.modificaClienteSelecionado(value);
                                                }}
                                                options={this.state.arrayClientesAvisos}
                                                value={this.props.clienteSelecionado}
                                                placeholder={this.props.clientes.length == 0 ? `Nenhum Cliente cadastrado, Por favor verifique!` : "Selecione o Cliente"}
                                                search={this.props.clientes.length == 0 ? false : true}
                                            />}


                                        </DivContainerInputEmpresaVinculada></>}
                                    <ContainerPermitir style={{ marginTop: 45 }}>
                                        <TextPermitir corLetraDinamica={this.props.coresLetraPadrao}>Por Faixa Horária?</TextPermitir>

                                        <CheckBoxWrapper>
                                            <CheckBox id="checkbox2" type="checkbox" checked={this.props.porFaixaHoraria} onChange={(value) => {
                                                this.props.alteraValorSwitchNovoAviso(value.target.checked)
                                            }} />
                                            <CheckBoxLabel htmlFor="checkbox2" />
                                        </CheckBoxWrapper>
                                        <ContainerPermitir style={{ marginLeft: 30 }}>
                                            <TextPermitir corLetraDinamica={this.props.coresLetraPadrao}>Status:</TextPermitir>

                                            <CheckBoxWrapper>
                                                <CheckBox id="checkbox" type="checkbox" checked={this.props.statusAvisoParaAlteracao}
                                                    onChange={(value) => {
                                                        this.props.alteraStatusAviso(value.target.checked)
                                                    }} />
                                                <CheckBoxLabel htmlFor="checkbox" />
                                            </CheckBoxWrapper>
                                        </ContainerPermitir>
                                    </ContainerPermitir>
                                    {this.props.porFaixaHoraria == true && <ContainerPeriodoExibicao>
                                        <p style={{
                                            color: this.props.corLetraDescricaoDosCampos,
                                            fontWeight: 'bold',
                                            fontSize: 12,
                                            marginTop: 7
                                        }}>Período exibição<span style={{ color: 'red', fontSize: 14, marginLeft: 3 }}>*</span></p>
                                        <ContainerSelecionarPeriodo>
                                            <DatePicker
                                                selected={this.state.dataInicial}
                                                onChange={(date) => {
                                                    this.props.modificaDataInicialNovoAviso(date);
                                                    this.setState({ dataInicial: date })
                                                }}
                                                locale="pt-BR"
                                                value={this.props.dataInicial}
                                                showTimeSelect
                                                timeFormat="HH:mm"
                                                timeCaption="Hora"
                                                customInput={<CustomInputDateInicial />}

                                                formatWeekDay={d => {
                                                    switch (d) {
                                                        case 'segunda':
                                                            return 'Seg';
                                                        case 'terça':
                                                            return 'Ter';
                                                        case 'quarta':
                                                            return 'Qua';
                                                        case 'quinta':
                                                            return 'Qui';
                                                        case 'sexta':
                                                            return 'Sex';
                                                        case 'sábado':
                                                            return 'Sab';
                                                        case 'domingo':
                                                            return 'Dom';
                                                        default:
                                                            return d;
                                                    }
                                                }}
                                                timeIntervals={5}
                                                dateFormat="dd/MM/yyyy HH:mm"
                                                placeholderText="Data Inicial" />
                                            <DatePicker
                                                selected={this.state.dataFinal}
                                                onChange={(date) => {
                                                    this.props.modificaDataFinalNovoAviso(date);
                                                    this.setState({ dataFinal: date })
                                                }}
                                                locale="pt-BR"
                                                showTimeSelect
                                                timeFormat="HH:mm"
                                                timeCaption="Hora"
                                                value={this.props.dataFinal}
                                                customInput={<CustomInputDateFinal />}
                                                formatWeekDay={d => {
                                                    switch (d) {
                                                        case 'segunda':
                                                            return 'Seg';
                                                        case 'terça':
                                                            return 'Ter';
                                                        case 'quarta':
                                                            return 'Qua';
                                                        case 'quinta':
                                                            return 'Qui';
                                                        case 'sexta':
                                                            return 'Sex';
                                                        case 'sábado':
                                                            return 'Sab';
                                                        case 'domingo':
                                                            return 'Dom';
                                                        default:
                                                            return d;
                                                    }
                                                }}
                                                timeIntervals={5}
                                                dateFormat="dd/MM/yyyy HH:mm"
                                                placeholderText="Data Final" />
                                        </ContainerSelecionarPeriodo>
                                    </ContainerPeriodoExibicao>}


                                </ContainerDadosCliente>

                            </SubContainerCliente>}


                            {this.props.modalNovoAviso == false && this.props.avisos.length > 0 && <ContainerInputDescricaoChamado>
                                <strong style={{ color: this.props.corLetraDescricaoDosCampos, fontSize: 14, marginBottom: 2, marginLeft: 5 }}>Descrição
                                    do Aviso:<span style={{ color: 'red', fontSize: 14, marginLeft: 3 }}>*</span></strong>
                                <InputDescricaoChamado
                                    autoFocus
                                    type="textarea"
                                    onChange={(value) => {
                                        this.props.alteraDescricaoMensagemNovoAviso(value.target.value)
                                    }}
                                    corLetraDinamico={this.props.coresLetraPadrao}
                                    corLetraSecundaria={this.props.coresLetraSecundario}
                                    corBordaDinamica={this.props.corBotaoPadrao}
                                    value={this.props.mensagem}
                                    placeholder=" Descreva o aviso que aparecerá na tela..."
                                />
                            </ContainerInputDescricaoChamado>}

                            {this.props.modalNovoAviso == false && this.props.avisos.length > 0 && <ContainerBotaoAlterar>
                                {this.props.loadingBotaoAlterarAviso == false &&
                                    <ButtonEntrar backGroundDinamico={this.props.corBotaoPadrao} corLetraBotao={this.props.letraBotaoPadrao}
                                        type="submit" onClick={() => {
                                            this.props.alterarAviso2(this.props.mensagem, this.props.destinatarioSelecionadoValor, this.props.clienteSelecionado,
                                                this.props.atendenteSelecionado, this.props.porFaixaHoraria, this.props.dataInicial, this.props.dataFinal, this.props.statusAvisoParaAlteracao, this.props.keyAvisoParaAlteracao)
                                            this.setState({ dataFinal: '', dataInicial: '' })
                                            this.setState({ dataFinalNovoCadastro: '', dataInicialNovoCadastro: '' })
                                        }}>
                                        Alterar
                                    </ButtonEntrar>}
                                {this.props.loadingBotaoAlterarAviso == true && <ClipLoader
                                    css={{ marginBottom: 10 }}
                                    size={35}
                                    color={this.props.corBotaoPadrao}
                                    loading={this.props.loadingBotaoAlterarAviso}
                                />}
                            </ContainerBotaoAlterar>}


                        </ContainerPrincipalDadosCliente>}

                </Container>
            )
        }
    }

    render() {
        registerLocale('pt-BR', pt);
        setDefaultLocale("pt-BR");
        const CustomInputDateInicial = ({ value, onClick }) => (
            <>
                <BotaoInputDateInicial corBordaDinamica={this.props.corBotaoPadrao} placeholder="Data Inicial"
                    placeHoldarDinamico={this.props.coresLetraSecundario} corLetraDinamica={this.props.coresLetraPadrao}
                    backGroundDinamico={this.props.backgroundMaisEscuro} onClick={onClick}>
                    <IconeVariosNovos className="icon-vencimento"
                        colorPrimario={this.props.coresLetraPadrao} /> {(value == '') ? "Data Inicial" : value}<span>  </span>
                </BotaoInputDateInicial>
            </>
        );
        const CustomInputDateFinal = ({ value, onClick }) => (
            <>

                <BotaoInputDateFinal corBordaDinamica={this.props.corBotaoPadrao} placeholder="Data Final"
                    placeHoldarDinamico={this.props.coresLetraSecundario} corLetraDinamica={this.props.coresLetraPadrao}
                    backGroundDinamico={this.props.backgroundMaisEscuro} onClick={onClick}>
                    <IconeVariosNovos className="icon-vencimento" colorPrimario={this.props.coresLetraPadrao} /> {(value == '') ? "Data Final" : value}
                    <span>  </span>
                </BotaoInputDateFinal>
            </>
        );


        const CheckBoxWrapper = styled.div`
          position: relative;
          margin-left: 10px;
          height: 24px;
        `;
        const CheckBoxLabel = styled.label`
          position: absolute;
          top: 0;
          left: 0;
          width: 42px;
          height: 23px;
          border-radius: 15px;
          background: ${this.props.coresLetraSecundario};

          cursor: pointer;

          &::after {
            content: "";
            display: block;
            border-radius: 50%;
            width: 18px;
            height: 18px;
            margin: 3px;
            background: #ffffff;
            box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
            transition: 0.2s;
          }
        `;
        const CheckBox = styled.input`
          opacity: 0;
          z-index: 1;

          border-radius: 15px;
          width: 42px;
          height: 26px;

          &:checked + ${CheckBoxLabel} {
            background: #00FF7F;

            &::after {
              content: "";
              display: block;
              border-radius: 50%;
              width: 18px;
              height: 18px;
              margin-left: 21px;
              transition: 0.2s;
            }
          }
        `;
        return (
            <>
                {this.renderLoadingAvisos()}
                <Modal focusTrapped={false} showCloseIcon={false} open={this.props.modalNovoAviso} onClose={() => {
                }} center>
                    <ContainerModalNovoCliente backgroundDinamico={this.props.backgroundPrimeiro}>
                        <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                            <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Cadastro Aviso</TextNovoAtendimento>
                            <div>
                                <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => {
                                    this.props.modificaVisibleModalNovoAviso(false);
                                    this.setState({ clientSelectedNovoAviso: null });
                                }} className="icon-close" />
                            </div>
                        </ContainerIconeFecharModal>

                        <SubContainerCliente>
                            <ContainerDadosCliente>

                                <p style={{ color: this.props.corLetraDescricaoDosCampos, fontWeight: 'bold', fontSize: 13 }}>Destinatário<span
                                    style={{ color: 'red', fontSize: 14, marginLeft: 3 }}>*</span></p>
                                <DivContainerInputEmpresaVinculada>
                                    <SelectSearch
                                        renderValue={(valueProps) =>
                                            <DivInputSearch>
                                                <InputSearch
                                                    corLetraDinamico={this.props.coresLetraPadrao}
                                                    backgroundDinamico={this.props.backgroundMaisEscuro}
                                                    placeholderColor={this.props.corLetraSecundaria}
                                                    corBordaDinamica={this.props.corBotaoPadrao}
                                                    {...valueProps} ></InputSearch>
                                                <IconeSetaPraBaixo className="icon-angle-arrow-down" colorDinamico={this.props.coresLetraPadrao} />
                                            </DivInputSearch>
                                        }
                                        value={this.props.destinatarioSelecionadoNovoCadastro}
                                        onChange={(value) => this.props.modificaDestinatarioSelecionadoNovoCadastro(value)}
                                        options={this.props.destinatario}
                                        placeholder="Selecione" search
                                    />

                                </DivContainerInputEmpresaVinculada>

                                {this.props.destinatarioSelecionadoValorNovoCadastro == 1 && <> <p
                                    style={{ color: this.props.corLetraDescricaoDosCampos, fontWeight: 'bold', fontSize: 13, marginTop: 15 }}>Atendentes<span
                                        style={{ color: 'red', fontSize: 14, marginLeft: 3 }}>*</span></p>
                                    <DivContainerInputEmpresaVinculada>

                                        <SelectSearch
                                            renderValue={(valueProps) =>
                                                <DivInputSearch>
                                                    <InputSearch
                                                        corLetraDinamico={this.props.coresLetraPadrao}
                                                        backgroundDinamico={this.props.backgroundMaisEscuro}
                                                        placeholderColor={this.props.corLetraSecundaria}
                                                        corBordaDinamica={this.props.corBotaoPadrao}
                                                        {...valueProps} ></InputSearch>
                                                    <IconeSetaPraBaixo className="icon-angle-arrow-down" colorDinamico={this.props.coresLetraPadrao} />
                                                </DivInputSearch>
                                            }
                                            onChange={(value) => this.props.modificaAtendenteSelecionadoNovoCadastro(value)}
                                            options={this.state.arrayAtendentesAvisos}
                                            value={this.props.atendenteSelecionadoNovoCadastro}
                                            placeholder={this.props.atendentes.length == 0 ? `Nenhum Atendente cadastrado, Por favor verifique!` : "Selecione o Atendente"}
                                            search={this.props.atendentes.length == 0 ? false : true}
                                        />


                                    </DivContainerInputEmpresaVinculada></>}
                                {this.props.destinatarioSelecionadoValorNovoCadastro == 2 && <> <p style={{
                                    color: this.props.corLetraDescricaoDosCampos,
                                    fontWeight: 'bold',
                                    fontSize: 13,
                                    marginTop: 15
                                }}>Clientes<span style={{ color: 'red', fontSize: 14, marginLeft: 3 }}>*</span></p>
                                    <DivContainerInputEmpresaVinculada>
                                        {this.props.clientes.length === 0 && <div style={{ display: 'flex', flex: 1 }}>
                                            <SelectSearchPaginate
                                                onSelectClient={(client) => {

                                                    this.setState({ clientSelectedNovoAviso: { value: client.value, label: client.label } });
                                                    this.props.modificaClienteSelecionadoNovoCadastro(client.value);

                                                }}
                                                value={this.state.clientSelectedNovoAviso}
                                                isMyContact={false}
                                                background={this.props.backgroundMaisEscuro}
                                                letterColor={this.props.coresLetraPadrao}
                                                backgroundDinamico={this.props.backgroundMaisEscuro}
                                                placeholderColor={this.props.coresLetraSecundario}
                                                corBordaDinamica={this.props.corBotaoPadrao}
                                                width={400}
                                                letraBotaoPadrao={this.props.letraBotaoPadrao}
                                                isScreenNotices={true}
                                            />
                                        </div>}

                                        {this.props.clientes.length > 0 && <SelectSearch
                                            renderValue={(valueProps) =>
                                                <DivInputSearch>
                                                    <InputSearch
                                                        corLetraDinamico={this.props.coresLetraPadrao}
                                                        backgroundDinamico={this.props.backgroundMaisEscuro}
                                                        placeholderColor={this.props.corLetraSecundaria}
                                                        corBordaDinamica={this.props.corBotaoPadrao}
                                                        {...valueProps} ></InputSearch>
                                                    <IconeSetaPraBaixo className="icon-angle-arrow-down" colorDinamico={this.props.coresLetraPadrao} />
                                                </DivInputSearch>
                                            }
                                            onChange={(value) => {
                                                this.props.modificaClienteSelecionadoNovoCadastro(value)
                                            }}
                                            options={this.state.arrayClientesAvisos}
                                            value={this.props.clienteSelecionadoNovoCadastro}
                                            placeholder={this.props.clientes.length == 0 ? `Nenhum Cliente cadastrado, Por favor verifique!` : "Selecione o Cliente"}
                                            search={this.props.clientes.length == 0 ? false : true}
                                        />}


                                    </DivContainerInputEmpresaVinculada></>}
                                <ContainerPermitir style={{ marginTop: 45 }}>
                                    <TextPermitir corLetraDinamica={this.props.coresLetraPadrao}>Por Faixa Horária?</TextPermitir>

                                    <CheckBoxWrapper>
                                        <CheckBox id="checkbox2" type="checkbox" checked={this.props.porFaixaHorariaNovoCadastro}
                                            onChange={(value) => {
                                                this.props.alteraValorSwitchNovoAvisoNovoCadastro(value.target.checked)
                                            }} />
                                        <CheckBoxLabel htmlFor="checkbox2" />
                                    </CheckBoxWrapper>
                                </ContainerPermitir>
                                {this.props.porFaixaHorariaNovoCadastro == true && <ContainerPeriodoExibicao>
                                    <p style={{ color: this.props.corLetraDescricaoDosCampos, fontWeight: 'bold', fontSize: 12, marginTop: 7 }}>Período
                                        exibição<span style={{ color: 'red', fontSize: 14, marginLeft: 3 }}>*</span></p>
                                    <ContainerSelecionarPeriodo>
                                        <DatePicker
                                            selected={this.state.dataInicialNovoCadastro}
                                            onChange={(date) => {
                                                this.props.modificaDataInicialNovoAvisoNovoCadastro(date);
                                                this.setState({ dataInicialNovoCadastro: date })
                                            }}
                                            locale="pt-BR"
                                            showTimeSelect
                                            timeFormat="HH:mm"
                                            timeCaption="Hora"
                                            customInput={<CustomInputDateInicial />}
                                            formatWeekDay={d => {
                                                switch (d) {
                                                    case 'segunda':
                                                        return 'Seg';
                                                    case 'terça':
                                                        return 'Ter';
                                                    case 'quarta':
                                                        return 'Qua';
                                                    case 'quinta':
                                                        return 'Qui';
                                                    case 'sexta':
                                                        return 'Sex';
                                                    case 'sábado':
                                                        return 'Sab';
                                                    case 'domingo':
                                                        return 'Dom';
                                                    default:
                                                        return d;
                                                }
                                            }}
                                            timeIntervals={5}
                                            dateFormat="dd/MM/yyyy HH:mm"
                                            placeholderText="Data Inicial"
                                            value={this.props.dataInicialNovoCadastro} />
                                        <DatePicker
                                            selected={this.state.dataFinalNovoCadastro}
                                            onChange={(date) => {
                                                this.props.modificaDataFinalNovoAvisoNovoCadastro(date);
                                                this.setState({ dataFinalNovoCadastro: date })
                                            }}
                                            locale="pt-BR"
                                            showTimeSelect
                                            timeFormat="HH:mm"
                                            timeCaption="Hora"
                                            customInput={<CustomInputDateFinal />}
                                            formatWeekDay={d => {
                                                switch (d) {
                                                    case 'segunda':
                                                        return 'Seg';
                                                    case 'terça':
                                                        return 'Ter';
                                                    case 'quarta':
                                                        return 'Qua';
                                                    case 'quinta':
                                                        return 'Qui';
                                                    case 'sexta':
                                                        return 'Sex';
                                                    case 'sábado':
                                                        return 'Sab';
                                                    case 'domingo':
                                                        return 'Dom';
                                                    default:
                                                        return d;
                                                }
                                            }}
                                            timeIntervals={5}
                                            dateFormat="dd/MM/yyyy HH:mm"
                                            placeholderText="Data Final"
                                            value={this.props.dataFinalNovoCadastro} />
                                    </ContainerSelecionarPeriodo>
                                </ContainerPeriodoExibicao>}


                            </ContainerDadosCliente>

                        </SubContainerCliente>


                        <ContainerInputDescricaoChamado>
                            <strong style={{ color: this.props.corLetraDescricaoDosCampos, fontSize: 14, marginBottom: 2, marginLeft: 5 }}>Descrição do
                                Aviso:<span style={{ color: 'red', fontSize: 14, marginLeft: 3 }}>*</span></strong>
                            <InputDescricaoChamado
                                autoFocus
                                type="textarea"
                                value={this.props.mensagemNovoCadastro}
                                onChange={(value) => {
                                    this.props.alteraDescricaoMensagemNovoAvisoNovoCadastro(value.target.value)
                                }}
                                corLetraDinamico={this.props.coresLetraPadrao}
                                corLetraSecundaria={this.props.coresLetraSecundario}
                                placeholder=" Descreva o aviso que aparecerá na tela..."
                            />
                        </ContainerInputDescricaoChamado>

                        <ContainerBotaoAlterar>
                            {this.props.loadginBotaoCadastrarAviso == false &&
                                <ButtonEntrar backGroundDinamico={this.props.corBotaoPadrao} corLetraBotao={this.props.letraBotaoPadrao} type="submit"
                                    onClick={() => {
                                        this.props.criarAviso2(this.props.mensagemNovoCadastro, this.props.destinatarioSelecionadoValorNovoCadastro, this.props.clienteSelecionadoNovoCadastro,
                                            this.props.atendenteSelecionadoNovoCadastro, this.props.porFaixaHorariaNovoCadastro, this.props.dataInicialNovoCadastro, this.props.dataFinalNovoCadastro);
                                        this.setState({
                                            dataFinal: '',
                                            dataInicial: '',
                                            dataFinalNovoCadastro: '',
                                            dataInicialNovoCadastro: '',
                                            clientSelectedNovoAviso: null
                                        })

                                    }}>
                                    Cadastrar
                                </ButtonEntrar>}
                            {this.props.loadginBotaoCadastrarAviso == true && <ClipLoader
                                css={{ marginBottom: 10 }}
                                size={35}
                                color={this.props.corBotaoPadrao}
                                loading={this.props.loadginBotaoCadastrarAviso}
                            />}

                        </ContainerBotaoAlterar>
                    </ContainerModalNovoCliente>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = state => {
    return (
        {
            backgroundPrimeiro: state.CoresPadraoSistema.backgroundPrimeiro,
            backgroundSegundo: state.CoresPadraoSistema.backgroundSegundo,
            backgroundMaisEscuro: state.CoresPadraoSistema.backgroundMaisEscuro,
            coresLetraPadrao: state.CoresPadraoSistema.coresLetraPadrao,
            corBotaoPadrao: state.CoresPadraoSistema.corBotaoPadrao,
            corSegundoBotaoPadrao: state.CoresPadraoSistema.corSegundoBotaoPadrao,
            coresLetraSecundario: state.CoresPadraoSistema.coresLetraSecundario,
            letraBotaoPadrao: state.CoresPadraoSistema.letraBotaoPadrao,
            corLetraDescricaoDosCampos: state.CoresPadraoSistema.corLetraDescricaoDosCampos,
            nomeRota: state.HeaderReducer.nomeRota,
            testePesquisa: state.HeaderReducer.testePesquisa,


            filtrosSelecionado: state.AvisosReducer.filtrosSelecionado,
            destinatario: state.AvisosReducer.destinatario,
            clienteSelecionado: state.AvisosReducer.clienteSelecionado,
            atendenteSelecionado: state.AvisosReducer.atendenteSelecionado,
            destinatarioSelecionado: state.AvisosReducer.destinatarioSelecionado,
            clientes: state.ChamadoReducer.clientesSectionedConsulta,
            atendentes: state.AtendenteReducer.atendentesSectionedConsulta,
            mensagem: state.AvisosReducer.mensagem,
            loadingAtendenteNovoAviso: state.AvisosReducer.loadingAtendenteNovoAviso,
            destinatarioSelecionadoValor: state.AvisosReducer.destinatarioSelecionadoValor,
            loadingClienteNovoAviso: state.AvisosReducer.loadingClienteNovoAviso,
            verificaAvisoError: state.AvisosReducer.verificaAvisoError,
            loadginBotaoCadastrarAviso: state.AvisosReducer.loadginBotaoCadastrarAviso,
            porFaixaHoraria: state.AvisosReducer.porFaixaHoraria,
            dataInicial: state.AvisosReducer.dataInicial,
            dataFinal: state.AvisosReducer.dataFinal,
            statusAvisoParaAlteracao: state.AvisosReducer.statusAvisoParaAlteracao,
            keyAvisoParaAlteracao: state.AvisosReducer.keyAvisoParaAlteracao,
            loadingBotaoAlterarAviso: state.AvisosReducer.loadingBotaoAlterarAviso,
            modalNovoAviso: state.AvisosReducer.modalNovoAviso,

            avisos: state.AvisosReducer.avisos,
            loadingConsultaAviso: state.AvisosReducer.loadingConsultaAviso,
            refreshControlConsultaAvisos: state.AvisosReducer.refreshControlConsultaAvisos,
            referenciaUltimaKeyAviso: state.AvisosReducer.referenciaUltimaKeyAviso,
            loadingFimDaPaginaAviso: state.AvisosReducer.loadingFimDaPaginaAviso,
            verificaInternetConsultaAviso: state.AvisosReducer.verificaInternetConsultaAviso,
            filtros: state.AvisosReducer.filtros,
            modalNovoAvisoVersaoPremium: state.AvisosReducer.modalNovoAvisoVersaoPremium,
            permissoesAcessarTelasAtendente: state.AtendenteReducer.permissoesAcessarTelasAtendente,

            atendenteSelecionadoNovoCadastro: state.AvisosReducer.atendenteSelecionadoNovoCadastro,
            clienteSelecionadoNovoCadastro: state.AvisosReducer.clienteSelecionadoNovoCadastro,
            porFaixaHorariaNovoCadastro: state.AvisosReducer.porFaixaHorariaNovoCadastro,
            dataInicialNovoCadastro: state.AvisosReducer.dataInicialNovoCadastro,
            dataFinalNovoCadastro: state.AvisosReducer.dataFinalNovoCadastro,
            mensagemNovoCadastro: state.AvisosReducer.mensagemNovoCadastro,
            destinatarioSelecionadoValorNovoCadastro: state.AvisosReducer.destinatarioSelecionadoValorNovoCadastro,
            destinatarioSelecionadoNovoCadastro: state.AvisosReducer.destinatarioSelecionadoNovoCadastro,
            licensawebouapp: state.PremiumReducer.licensawebouapp,
            premium: state.PremiumReducer.premium,
        }
    );
};
export default connect(mapStateToProps, {


    modificaDestinatarioSelecionado, modificaAtendenteSelecionado,
    modificaClienteSelecionado, alteraDescricaoMensagemNovoAviso,
    alteraMensagemNovoAvisoAudio, zeramensagemEnviaAvisoError,
    alteraValorSwitchNovoAviso, modificaDataInicialNovoAviso, modificaDataFinalNovoAviso,
    alteraStatusAviso, alteraFiltroSelecionadoTelaNovoAviso, modificaDataInicialEFinalAlterarAviso,
    modificaVisibleModalNovoAviso, modificaNomeRotaNoHeader, consultaAviso,
    consultaAvisoPorAtendente, consultaAvisoPorCliente, consultaAvisoPorStatusAtivo,
    consultaAvisoPorStatusInativo, alterarAviso2,

    alteraDescricaoMensagemNovoAvisoNovoCadastro, modificaDataFinalNovoAvisoNovoCadastro,
    modificaDataInicialNovoAvisoNovoCadastro, alteraValorSwitchNovoAvisoNovoCadastro,
    modificaClienteSelecionadoNovoCadastro, modificaAtendenteSelecionadoNovoCadastro,
    modificaDestinatarioSelecionadoNovoCadastro, criarAviso2, desligaOuvintesChamadosFirebase,
    listaAtendentesTelaConsultaAtendente, listaClientesTelaConsultaClientes
})(withRouter(Avisos));
