import styled, { keyframes } from 'styled-components';
import { shade, opacify, lighten, } from 'polished';


const animacaoLoading = keyframes`
   0%{
        background-position: 0% 0%;
     }
     100%{
        background-position: -135% 0%;
     }
  }
`;
export const Container = styled.div`
 display:flex;
 flex: 1;
 height:calc(100vh - 101px);
align-items:center;
overflow:auto;
 flex-direction:column;
`;
export const ContainerChamado = styled.button`
display:flex;
 height:100px;
 min-height:100px;
 width:80%;
 border:0;
margin-bottom:4px;
margin-top:6px;
margin-right:13px;
margin-left:13px;
border-radius:7px;
background:${props => props.backGroundDinamico};
 flex-direction:row;
 transition: transform 0.2s;
 &:hover{
    transform: translate(10px);
 }
 
`;
/* export const ContainerImagem = styled.div`
display:flex;
 height:100px;
 min-height:100px;
width:80px;
align-items:center;
justify-content:center;
 flex-direction:column;
`; */
export const ContainerImagem = styled.div`
display:flex;
height:100px;
 min-height:100px;
width:80px;
align-items:center;
justify-content:center;
 flex-direction:row;
 overflow:hidden;
`;

export const ContainerDadosChamado = styled.div`
display:flex;
flex:1;
 height:100px;
 min-height:100px;
 flex-direction:row;
`;

export const ContaineDadosChamadosPrincipal = styled.div`
display:flex;
flex:1;
 height:100px;
 min-height:100px;
 flex-direction:column;
`;
export const ContainerIconeEFrase = styled.div`
display:flex;
flex:1;
 flex-direction:row;
  align-items:center;
 justify-content:flex-start;
`;
export const ContainerIconeEFraseClienteAtendente = styled.div`
display:flex;
flex:1;
 flex-direction:row;
`;
export const SubContainerIconeEFraseClienteAtendente = styled.div`
display:flex;
 flex-direction:row;
 width:230px;
 align-items:center;
 justify-content:flex-start;
 
`;
export const TextCliente = styled.p`
font-size:13px;
text-align:left;
margin-left:3px;
font-weight:bold;
max-width:230px;
overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
color:${props => props.corLetraDinamica};
`;
export const TextProblema = styled.p`
font-size:14px;
margin-left:3px;
font-weight:bold;
font-style:italic;
max-width:510px;
padding-left:5px;
padding-right:5px;
text-align:left;
overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
color:${props => props.corLetraDinamica};
`;
export const TextAtendente = styled.p`
font-size:14px;
margin-left:3px;
font-weight:bold;
text-align:left;
max-width:500px;
overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
color:${props => props.corLetraDinamica};
`;

export const ContaineDadosChamadosSecundario = styled.div`
display:flex;
flex:0.5;
 height:100px;
 min-height:100px;
 flex-direction:column;
 align-items:flex-start;
 justify-content:flex-start;
`;
export const ContainerNumeroChamado = styled.div`
display:flex;
 justify-content:center;
margin-right:2px;
`;
export const ContainerInputSearch = styled.div`
display:flex;
flex-direction:row;
 justify-content:center;
 align-items:center;
 margin-bottom:10px;
margin-right:2px;
`;


export const TextNumeroChamado = styled.strong`
font-size:14px;
font-weight:bold;
color:${props => props.corLetraDinamica};
`;
export const TextDataHoraAbertura = styled.p`
font-size:11px;
font-weight:bold;
color:${props => props.corLetraDinamica};
margin-left:2px;

`;
export const TextAbertura = styled.p`
font-size:10px;
font-weight:bold;
color:${props => props.corLetraDinamica};
`;

export const ContainerDataAbertura = styled.div`
display:flex;
flex-direction:row;
justify-content:flex-start;
align-items:center;
margin-bottom:2px;
`;
export const ContainerAberturaFechamento = styled.div`
display:flex;
flex-direction:row;
justify-content:space-between;
align-items:center;
margin-right:20px;
`;

export const IconeSair = styled.i`
font-size:17px;
margin-right:5px;
color:red;
cursor: pointer;
flex:1;
`;
export const InputFiltroPorNumero = styled.input`
display:flex;
flex:1;
height:30px;
min-height:30px;
padding-left:20px;
width:400px;
border:0;
border-radius:3px;
color:${props => props.corLetraDinamico};
background-color: ${props => props.backgroundDinamico};

border:${props => `outset 0.5px ${props.corLetraDinamico}`};
&::placeholder{
   color:${props => props.placeholderColor};
   font-style: italic;
}
&:hover{
  border:${props => `solid 0.5px ${props.corBordaDinamica}`};
 }
`;

export const BotaoPesquisarChamadoPorNumero = styled.button`
display:flex;
justify-content:center;
align-items:center;
background:${props => props.backGroundDinamico};
width:30px;
border:0;
transition: background-color 0.2s;
height:30px;
min-height:30px;
margin-left:5px;
border-radius: 5px;

`;
export const InputSearch = styled.input`
display:flex;
flex:1;
height:30px;
min-height:30px;
width:400px;
border-radius:5px;
padding-left:7px;
font-size:14px;
border:0;
color:${props => props.corLetraDinamico};
background-color: ${props => props.backgroundDinamico};

border:${props => `outset 0.5px ${props.corLetraDinamico}`};
cursor: pointer;
&::placeholder{
   color:${props => props.placeholderColor};
   font-style: italic;
}
&:hover{
  border:${props => `solid 0.5px ${props.corBordaDinamica}`};
 }

`;

export const DivInputSearch = styled.div`
 display:flex;
  flex-direction:row;
  align-items:center;

`;
export const IconeSetaPraBaixo = styled.i`
font-size: 12px;
position: absolute;
top:8px;
right: 19px;
width: 11px;
height: 11px;
min-height: 11px;
color:${props => props.colorDinamico};
pointer-events: none;
`;
export const ContainerChamadoLoading = styled.div`
display:flex;
 height:100px;
 min-height:100px;
 width:80%;
 border:0;
margin-bottom:4px;
margin-top:6px;
margin-right:13px;
margin-left:13px;
border-radius:7px;
background:${props => props.backgroundinamico};
 flex-direction:row;
`;
export const ContainerImagemLoading = styled.div`
display:flex;
 height:100px;
 min-height:100px;
width:80px;
align-items:center;
justify-content:center;
 flex-direction:column;
`;
export const ImageLoading = styled.div`
display:flex;
 height:60px;
 min-height:60px;
width:60px;
border-radius:50%;
background:${props => `linear-gradient(to right, ${lighten(0.2, props.backgroundinamico)} 10%, ${lighten(0.2, props.backgroundinamico)} 28%, ${props.backgroundinamico} 33%)`};
animation:${animacaoLoading} 1.2s linear infinite;
  background-size: 400% 400%; 
`;
export const TextClienteLoading = styled.div`
font-size:13px;
text-align:left;
margin-left:3px;
height:22px;
min-height:22px;
border-radius:5px;
width:130px;
background:${props => `linear-gradient(to right, ${props.backgroundinamico} 10%, ${lighten(0.2, props.backgroundinamico)} 28%, ${props.backgroundinamico} 33%)`};
animation:${animacaoLoading} 1.2s linear infinite;
  background-size: 400% 400%; 
`;
export const TextProblemaLoading = styled.div`
margin-left:3px;
width:400px;
height:22px;
min-height:22px;
border-radius:5px;
background:${props => `linear-gradient(to right, ${props.backgroundinamico} 10%, ${lighten(0.2, props.backgroundinamico)} 28%, ${props.backgroundinamico} 33%)`};
animation:${animacaoLoading} 1.2s linear infinite;
  background-size: 400% 400%; 
`;
export const ContainerListaVazia = styled.div`
 display:flex;
 flex:1;
  flex-direction:column;
  align-items:center;
justify-content:center;
`;