import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-responsive-modal';
import { withRouter } from "react-router-dom";
import MoonLoader from "react-spinners/MoonLoader";
import SelectSearchPaginate from "../SelectSearch/index";
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import FlatList from 'flatlist-react';
import FlatListRelatorioRegistroAvaliacoes from "./FlatListRelatorioRegistroAvaliacoes";
import ReactTooltip from "react-tooltip";
import styled from 'styled-components';
import SelectSearch from 'react-select-search';
import { toastr } from 'react-redux-toastr'

import imgTeste from '../../imagens/userPadrao.png';
import imglink from '../../imagens/link.png'
import imgunlink from '../../imagens/unlink.png'
import imgexcluirCliente from '../../imagens/delete-user.png';
import { modificaPesquisaTeste } from '../../actions/HeaderAction';
import {
    modificaFiltroAvaliacao, listaAsUltimasAvaliacoes,
    removeKeyTodosOsFiltros, modificaClienteSelecionadoTelaAvaliacaoTodas, modificaAtendenteSelecionadoTelaAvaliacaoTodas, modificaValorAvaliacaoSelecionadoTelaAvaliacaoTodas,
    listaUltimasAvaliacoesComCliente, listaUltimasAvaliacoesComAtendente, listaUltimasAvaliacoesComValorAvaliacao
} from "../../actions/AvaliacaoAction";
import { modificaNomeRotaNoHeader } from '../../actions/HeaderAction';
import { listaAtendentesTelaConsultaAtendente } from '../../actions/AtendenteAction';

import {
    ContainerDadosCliente, Container, IconeSair, ContainerInputSearch, SubContainer, SubContainerCliente,
    ContainerImagemAlterarCliente, ContainerPrincipalDadosCliente, InputEmpresa, SubContainerClientePermissao,
    ContainerPermitir, TextPermitir, ContainerDadosLoginCliente, ContainerBotaoAlterar, ButtonEntrar,
    DivIconeExcluirCliente, IconeExcluirCliente, DivContainerInputSenha, IconeMostrarSenha,
    IconeEmpresaVinculada, DivContainerInputEmpresaVinculada, ContainerModal, ContainerIconeFecharModal,
    TextNovoAtendimento, IconeFechar, ContainerImagemVincularEmpresa, ContainerExplicacaoVincular,
    ButtonVincular, TextVincular, ImagePerfil, IconeEmpresaVinculadaOff, ContainerModalNovoCliente,
    ContainerDadosNovoCliente,
    InputSearch, DivInputSearch, IconeSetaPraBaixo, DivInputComentarChamado, InputDescricaoChamado,

    ImageLoading, ContainerChamadoLoading, TextClienteLoading, ImagemPerfilLoading,
    InputLoadingEmpresa, ContainerDadosClienteLoading, SubContainerClientePermissaoLoading,

    ContainerListaVazia, ContainerImagem, ContainerDadosChamado,
    ContaineDadosChamadosPrincipal, ContaineDadosChamadosSecundario,
    ContainerIconeEFrase, TextCliente, TextProblema, ContainerIconeEFraseClienteAtendente,
    SubContainerIconeEFraseClienteAtendente, TextNumeroChamado,
    ContainerNumeroChamado, ContainerDataAbertura, ContainerSeta, TextEmpresa, TextComentario,
    InputLoadingComentario,
} from './styled';

import { desligaOuvintesChamadosFirebase } from "../../actions/DashBoardAction";
import { listaClientesTelaConsultaClientes } from "../../actions/ChamadoAction";

class RelatorioRegistroAvaliacoes extends React.Component {
    state = {
        modalPrioridadeVisible: false,
        filtroAtendente: false,
        filtroCliente: false,
        filtroValorAvaliacao: false,
        filtroClienteSelecionado: false,
        filtroValorAvaliacaoSelecionado: false,
        filtroAtendenteSelecionado: false,
        clientSelected: null
    }


    componentDidMount() {
        if (this.props.premium == 0) {
            toastr.error('Recurso Premium', 'Este é um recurso PREMIUM, Assine a versão PREMIUM e libere esse e mais recursos!');
            this.props.history.push('/dashboard')

        } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
            toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
            this.props.history.push('/dashboard')

        } else {
            if (this.props.permissoesAcessarTelasAtendente.permissaoAcessoRelatorios == true) {
                this.props.listaAsUltimasAvaliacoes(0, '', [], 'willmount');
                //this.props.listaClientesTelaRelatorioAvaliacaoTodas();
                this.props.listaClientesTelaConsultaClientes();
                this.props.listaAtendentesTelaConsultaAtendente()

                // this.props.listaAtendentesRelatorioAvaliacaoTodas();
                ReactTooltip.rebuild();
                if (this.props.nomeRota !== 'Relatório Registros Avaliações') {
                    this.props.modificaNomeRotaNoHeader('Relatório Registros Avaliações')
                }
            } else {
                toastr.error('Acesso Negado', 'Você não tem permissão para acessar esta tela');
                this.props.history.push('/dashboard')
            }
        }

    }
    componentWillUnmount() {
        this.props.desligaOuvintesChamadosFirebase('relatorioavaliacaotodas', '', '', '');
    }
    componentWillReceiveProps(nextProps) {
        //  ReactTooltip.rebuild();
    }

    _toggleModalPriorirade() {
        this.setState({ modalPrioridadeVisible: !this.state.modalPrioridadeVisible });
    }
    _togglefiltroAtendente() {
        this.setState({ filtroCliente: false, filtroAtendente: true, filtroValorAvaliacao: false });
    }
    _togglefiltroCliente() {
        this.setState({ filtroCliente: true, filtroAtendente: false, filtroValorAvaliacao: false });
    }
    _togglefiltroValorAvaliacao() {
        this.setState({ filtroCliente: false, filtroAtendente: false, filtroValorAvaliacao: true });
    }

    _togglefiltroAtendenteSelecionado() {
        this.setState({ filtroClienteSelecionado: false, filtroAtendenteSelecionado: true, filtroValorAvaliacaoSelecionado: false });
    }
    _togglefiltroClienteSelecionado() {
        this.setState({ filtroClienteSelecionado: true, filtroAtendfiltroAtendenteSelecionadoente: false, filtroValorAvaliacaoSelecionado: false });
    }
    _togglefiltroValorAvaliacaoSelecionado() {
        this.setState({ filtroClienteSelecionado: false, filtroAtendenteSelecionado: false, filtroValorAvaliacaoSelecionado: true });
    }
    renderNomeAValiacao() {
        if (this.props.detalhesAvaliacao.valorAvaliacao == 1) {
            return 'Ruim';
        } else if (this.props.detalhesAvaliacao.valorAvaliacao == 2) {
            return 'Regular';
        }
        else if (this.props.detalhesAvaliacao.valorAvaliacao == 3) {
            return 'Bom';
        }
        else if (this.props.detalhesAvaliacao.valorAvaliacao == 4) {
            return 'Ótimo';
        }
        else {
            return 'Excelente';
        }
    }
    _toggleMostrarSenha() {
        this.setState({ visaoInputSenha: !this.state.visaoInputSenha });
    }
    _toggleSectionedPesquisaCliente() {
        this.setState({ pesquisaCliente: !this.state.pesquisaCliente });
    }
    _toggleModalPesquisarCliente() {
        this.setState({ modalPesquisaCliente: !this.state.modalPesquisaCliente });
    }
    _toggleModalDesvincularCliente() {
        this.setState({ modalDesvinculaCliente: !this.state.modalDesvinculaCliente });
    }
    handleVisualizaSenha(value) {
        let state = this.state;
        state.tipoInputSenha = value;
        this.setState({ state });
    }
    handleVisualizaConfirmaSenha(value) {
        let state = this.state;
        state.tipoInputConfirmaSenha = value;
        this.setState({ state });
    }
    handleModalNovoCliente(value) {
        let state = this.state;
        state.modalNovoCliente = value;
        this.setState({ state });
    }
    updateSearch = search => {
        let state = this.state;
        state.search = search;
        this.setState({ state });

        // this.searchText(search)
    };
    renderAreaEmailSenha() {
        if (this.props.valorSwitch) {
            return (
                <ContainerDadosLoginCliente>
                    {/* <div> */}
                    <p style={{ color: this.props.corLetraDescricaoDosCampos, fontWeight: 'bold', fontSize: 12 }}>Email</p>
                    <InputEmpresa

                        type="text"
                        placeholder="Email"
                        colorDinamica={this.props.coresLetraPadrao}
                        placeholderDinamico={this.props.coresLetraSecundario}
                        borderDinamico={this.props.corBotaoPadrao}
                        value={this.props.emailCliente}
                        onChange={(e) => { this.props.modificaEmailClienteTelaCadastroCliente(e.target.value) }}
                    //  onFocus={() => { this.handleInputEmailFocus() }}
                    // onBlur={() => { this.handleInputEmailBlur() }}
                    />
                    <p style={{ color: this.props.corLetraDescricaoDosCampos, fontWeight: 'bold', fontSize: 12, marginTop: 5 }}>Senha</p>
                    <DivContainerInputSenha>
                        <InputEmpresa

                            type={this.state.tipoInputSenha}
                            placeholder="Senha"
                            colorDinamica={this.props.coresLetraPadrao}
                            placeholderDinamico={this.props.coresLetraSecundario}
                            borderDinamico={this.props.corBotaoPadrao}
                            value={this.props.senhaCliente}
                            onChange={(e) => { this.props.modificaSenhaClienteTelaCadastroCliente(e.target.value) }}
                        //  onFocus={() => { this.handleInputEmailFocus() }}
                        // onBlur={() => { this.handleInputEmailBlur() }}
                        />
                        {this.state.tipoInputSenha == 'password' && < IconeMostrarSenha onClick={() => { this.handleVisualizaSenha('text') }} colorDinamico={this.props.coresLetraPadrao} bordaDinamica={this.props.corBotaoPadrao} className="icon-semvisao" />}
                        {this.state.tipoInputSenha == 'text' && < IconeMostrarSenha onClick={() => { this.handleVisualizaSenha('password') }} colorDinamico={this.props.coresLetraPadrao} bordaDinamica={this.props.corBotaoPadrao} className="icon-comvisao" />}
                    </DivContainerInputSenha>
                    <p style={{ color: this.props.corLetraDescricaoDosCampos, fontWeight: 'bold', fontSize: 12, marginTop: 5 }}>Confirmar Senha</p>
                    <DivContainerInputSenha>
                        <InputEmpresa

                            type={this.state.tipoInputConfirmaSenha}
                            placeholder="Confirma Senha"
                            colorDinamica={this.props.coresLetraPadrao}
                            placeholderDinamico={this.props.coresLetraSecundario}
                            borderDinamico={this.props.corBotaoPadrao}
                            value={this.props.confirmaSenhaCliente}
                            onChange={(e) => { this.props.modificaConfirmaClienteTelaCadastroCliente(e.target.value) }}
                        //  onFocus={() => { this.handleInputEmailFocus() }}
                        // onBlur={() => { this.handleInputEmailBlur() }}
                        />
                        {this.state.tipoInputConfirmaSenha == 'password' && < IconeMostrarSenha onClick={() => { this.handleVisualizaConfirmaSenha('text') }} colorDinamico={this.props.coresLetraPadrao} bordaDinamica={this.props.corBotaoPadrao} className="icon-semvisao" />}
                        {this.state.tipoInputConfirmaSenha == 'text' && < IconeMostrarSenha onClick={() => { this.handleVisualizaConfirmaSenha('password') }} colorDinamico={this.props.coresLetraPadrao} bordaDinamica={this.props.corBotaoPadrao} className="icon-comvisao" />}
                    </DivContainerInputSenha>
                </ContainerDadosLoginCliente>
            )
        } else {
            return <ContainerDadosLoginCliente></ContainerDadosLoginCliente>
        }
    }
    renderAreaEmailSenhaFicticio() {
        if (this.state.checkFicticio) {
            return (
                <ContainerDadosLoginCliente>
                    {/* <div> */}
                    <p style={{ color: this.props.corLetraDescricaoDosCampos, fontWeight: 'bold', fontSize: 12 }}>Email</p>
                    <InputEmpresa

                        type="text"
                        placeholder="Email"
                        colorDinamica={this.props.coresLetraPadrao}
                        placeholderDinamico={this.props.coresLetraSecundario}
                        borderDinamico={this.props.corBotaoPadrao}
                        value={this.props.emailCliente}
                        onChange={(e) => { this.props.modificaEmailClienteTelaCadastroCliente(e.target.value) }}
                    //  onFocus={() => { this.handleInputEmailFocus() }}
                    // onBlur={() => { this.handleInputEmailBlur() }}
                    />
                    <p style={{ color: this.props.corLetraDescricaoDosCampos, fontWeight: 'bold', fontSize: 12, marginTop: 5 }}>Senha</p>
                    <DivContainerInputSenha>
                        <InputEmpresa

                            type={this.state.tipoInputSenha}
                            placeholder="Senha"
                            colorDinamica={this.props.coresLetraPadrao}
                            placeholderDinamico={this.props.coresLetraSecundario}
                            borderDinamico={this.props.corBotaoPadrao}
                            value={this.props.senhaCliente}
                            onChange={(e) => { this.props.modificaSenhaClienteTelaCadastroCliente(e.target.value) }}
                        //  onFocus={() => { this.handleInputEmailFocus() }}
                        // onBlur={() => { this.handleInputEmailBlur() }}
                        />
                        {this.state.tipoInputSenha == 'password' && < IconeMostrarSenha onClick={() => { this.handleVisualizaSenha('text') }} colorDinamico={this.props.coresLetraPadrao} bordaDinamica={this.props.corBotaoPadrao} className="icon-semvisao" />}
                        {this.state.tipoInputSenha == 'text' && < IconeMostrarSenha onClick={() => { this.handleVisualizaSenha('password') }} colorDinamico={this.props.coresLetraPadrao} bordaDinamica={this.props.corBotaoPadrao} className="icon-comvisao" />}
                    </DivContainerInputSenha>
                    <p style={{ color: this.props.corLetraDescricaoDosCampos, fontWeight: 'bold', fontSize: 12, marginTop: 5 }}>Confirmar Senha</p>
                    <DivContainerInputSenha>
                        <InputEmpresa

                            type={this.state.tipoInputConfirmaSenha}
                            placeholder="Confirma Senha"
                            colorDinamica={this.props.coresLetraPadrao}
                            placeholderDinamico={this.props.coresLetraSecundario}
                            borderDinamico={this.props.corBotaoPadrao}
                            value={this.props.confirmaSenhaCliente}
                            onChange={(e) => { this.props.modificaConfirmaClienteTelaCadastroCliente(e.target.value) }}
                        //  onFocus={() => { this.handleInputEmailFocus() }}
                        // onBlur={() => { this.handleInputEmailBlur() }}
                        />
                        {this.state.tipoInputConfirmaSenha == 'password' && < IconeMostrarSenha onClick={() => { this.handleVisualizaConfirmaSenha('text') }} colorDinamico={this.props.coresLetraPadrao} bordaDinamica={this.props.corBotaoPadrao} className="icon-semvisao" />}
                        {this.state.tipoInputConfirmaSenha == 'text' && < IconeMostrarSenha onClick={() => { this.handleVisualizaConfirmaSenha('password') }} colorDinamico={this.props.coresLetraPadrao} bordaDinamica={this.props.corBotaoPadrao} className="icon-comvisao" />}
                    </DivContainerInputSenha>
                </ContainerDadosLoginCliente>
            )
        } else {
            return <ContainerDadosLoginCliente></ContainerDadosLoginCliente>
        }
    }
    renderIconeEmpresaModal() {


        if (this.state.pesquisaCliente == false) {
            if (this.props.clienteEmpresaPrincipal) {
                return (
                    <IconeEmpresaVinculadaOff data-tip="Há Clientes Vinculados á essa Empresa, portanto essa Empresa NÃO pode ser Vinculada á nenhuma outra" data-for="tip-top" colorDinamica='#9fadb7' className="icon-hotel" onClick={() => { toastr.warning('Atenção', 'Há Clientes Vinculados á essa Empresa, portanto essa Empresa NÃO pode ser Vinculada á nenhuma outra') }} />
                )
            } else {
                return (
                    <IconeEmpresaVinculada data-tip="Vincular este cliente á uma empresa já cadastrada" data-for="tip-top" colorDinamica={this.props.corBotaoPadrao} className="icon-hotel" onClick={() => { this._toggleModalPesquisarCliente() }} />
                )
            }

        } else {
            return (
                <IconeEmpresaVinculada style={{ marginLeft: 5 }} data-tip="Desvincular Cliente" data-for="tip-top" colorDinamica="red" className="icon-close" onClick={() => { this._toggleModalDesvincularCliente() }} />
            )
        }
    }
    renderIconeEmpresa() {

        if (this.state.pesquisaCliente == false) {
            if (this.props.clienteEmpresaPrincipal) {
                return (
                    <IconeEmpresaVinculadaOff data-tip="Há Clientes Vinculados á essa Empresa, portanto essa Empresa NÃO pode ser Vinculada á nenhuma outra" data-for="tip-top" colorDinamica='#9fadb7' className="icon-hotel" onClick={() => { toastr.warning('Atenção', 'Há Clientes Vinculados á essa Empresa, portanto essa Empresa NÃO pode ser Vinculada á nenhuma outra') }} />
                )
            } else {
                return (
                    <IconeEmpresaVinculada data-tip="Vincular este cliente á uma empresa já cadastrada" data-for="tip-top" colorDinamica={this.props.corBotaoPadrao} className="icon-hotel" onClick={() => { this._toggleModalPesquisarCliente() }} />
                )
            }

        } else {
            return (
                <IconeEmpresaVinculada style={{ marginLeft: 5 }} data-tip="Desvincular Cliente" data-for="tip-top" colorDinamica="red" className="icon-close" onClick={() => { this._toggleModalDesvincularCliente() }} />
            )
        }
    }
    removeFiltrosAll() {
        //this.props.ativaLoadingTelaAberturaChamadoRemoveFiltro(true)
        setTimeout(() => {
            // this.props.listaOsUltimosChamadosAbertos(0, '', [], 'willmount', this.props.verificaAtendenteVeSomenteProprioAtendimento, this.props.verificaAgrupaAtendenteSetor, this.props.contaPrincipal);
            this.props.modificaFiltroAvaliacao('');
            this.props.removeKeyTodosOsFiltros();


            this.props.modificaClienteSelecionadoTelaAvaliacaoTodas('');
            this.props.modificaAtendenteSelecionadoTelaAvaliacaoTodas('');
            this.props.modificaValorAvaliacaoSelecionadoTelaAvaliacaoTodas('');
            this.setState({
                filtroAtendenteSelecionado: false, filtroClienteSelecionado: false, filtroValorAvaliacaoSelecionado: false,
                filtroAtendente: false, filtroCliente: false, filtroValorAvaliacao: false, clientSelected: null

            });

            this.props.listaAsUltimasAvaliacoes(0, '', [], 'willmount');
        }, 200);
    }
    renderImagem() {
        if (this.props.imagemClienteTelaDetalhesAvaliacao == null) {
            return (
                <div style={{ height: 150, width: 150, borderRadius: '50%', backgroundColor: 'rgba(159,173,183, 0.3)', border: 'none' }} />
            )
        } else {
            return (
                <ImagePerfil onClick={() => { }} src={this.props.imagemClienteTelaDetalhesAvaliacao} alt="foto cliente" style={{}} />
            )
        }
    }
    exibeUltimasAvaliacoes = () => {
        /*







           */
        if (this.state.filtroClienteSelecionado == true) {

            /*    */
            this.props.listaUltimasAvaliacoesComCliente(1, this.props.arrayTodasAvaliacoes, this.props.ultimaKeyReferenciaClienteTodasAvaliacoes, this.props.clienteSelecionadoTodasAvaliacoes)
        } else if (this.state.filtroAtendenteSelecionado == true) {
            this.props.listaUltimasAvaliacoesComAtendente(1, this.props.arrayTodasAvaliacoes, this.props.ultimaKeyReferenciaAtendenteTodasAvaliacoes, this.props.atendenteSelecionadoTodasAvaliacoes)

            /*      */
        } else if (this.state.filtroValorAvaliacaoSelecionado == true) {
            /*  alert("opa") */
            this.props.listaUltimasAvaliacoesComValorAvaliacao(1, this.props.arrayTodasAvaliacoes, this.props.ultimaKeyReferenciaValorAvaliacaoTodasAvaliacoes, this.props.valorAvaliacaoSelecionadoTodasAvaliacoes)
            /*   */
        } else if (this.props.referenciaUltimaKeyTodasAvaliacoes != '' && this.state.filtroClienteSelecionado == false &&
            this.state.filtroAtendenteSelecionado == false && this.state.filtroValorAvaliacaoSelecionado == false) {
            /*   */
            this.props.listaAsUltimasAvaliacoes(1, this.props.referenciaUltimaKeyTodasAvaliacoes, this.props.arrayTodasAvaliacoes)
        }/* else if(this.props.filtroAvaliacao === 'Avaliação'){

        } */
    }
    renderLoadingRegistroAvaliacoes() {
        if (this.props.loadingTelaTodasAvaliacoes) {
            return (
                <Container>
                    <SubContainer style={{ flex: 0.5 }}>
                        <ContainerChamadoLoading backGroundDinamico={this.props.backgroundMaisEscuro}>
                            <ContainerImagem>
                                <ImageLoading backgroundinamico={this.props.backgroundMaisEscuro}></ImageLoading>
                                {/*  <img src={this.state.fotoCliente} alt="foto cliente" style={{ height: 40, width: 40, borderRadius: 50 }} /> */}
                            </ContainerImagem>
                            <ContainerDadosChamado>
                                <ContaineDadosChamadosPrincipal>
                                    <SubContainerIconeEFraseClienteAtendente >
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                    <SubContainerIconeEFraseClienteAtendente >
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                    <SubContainerIconeEFraseClienteAtendente >
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                </ContaineDadosChamadosPrincipal>


                            </ContainerDadosChamado>


                        </ContainerChamadoLoading>
                        <ContainerChamadoLoading backGroundDinamico={this.props.backgroundMaisEscuro}>
                            <ContainerImagem>
                                <ImageLoading backgroundinamico={this.props.backgroundMaisEscuro}></ImageLoading>
                                {/*  <img src={this.state.fotoCliente} alt="foto cliente" style={{ height: 40, width: 40, borderRadius: 50 }} /> */}
                            </ContainerImagem>
                            <ContainerDadosChamado>
                                <ContaineDadosChamadosPrincipal>
                                    <SubContainerIconeEFraseClienteAtendente >
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                    <SubContainerIconeEFraseClienteAtendente >
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                    <SubContainerIconeEFraseClienteAtendente >
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                </ContaineDadosChamadosPrincipal>


                            </ContainerDadosChamado>


                        </ContainerChamadoLoading>
                        <ContainerChamadoLoading backGroundDinamico={this.props.backgroundMaisEscuro}>
                            <ContainerImagem>
                                <ImageLoading backgroundinamico={this.props.backgroundMaisEscuro}></ImageLoading>
                                {/*  <img src={this.state.fotoCliente} alt="foto cliente" style={{ height: 40, width: 40, borderRadius: 50 }} /> */}
                            </ContainerImagem>
                            <ContainerDadosChamado>
                                <ContaineDadosChamadosPrincipal>
                                    <SubContainerIconeEFraseClienteAtendente >
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                    <SubContainerIconeEFraseClienteAtendente >
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                    <SubContainerIconeEFraseClienteAtendente >
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                </ContaineDadosChamadosPrincipal>


                            </ContainerDadosChamado>


                        </ContainerChamadoLoading>
                        <ContainerChamadoLoading backGroundDinamico={this.props.backgroundMaisEscuro}>
                            <ContainerImagem>
                                <ImageLoading backgroundinamico={this.props.backgroundMaisEscuro}></ImageLoading>
                                {/*  <img src={this.state.fotoCliente} alt="foto cliente" style={{ height: 40, width: 40, borderRadius: 50 }} /> */}
                            </ContainerImagem>
                            <ContainerDadosChamado>
                                <ContaineDadosChamadosPrincipal>
                                    <SubContainerIconeEFraseClienteAtendente >
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                    <SubContainerIconeEFraseClienteAtendente >
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                    <SubContainerIconeEFraseClienteAtendente >
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                </ContaineDadosChamadosPrincipal>


                            </ContainerDadosChamado>


                        </ContainerChamadoLoading>
                        <ContainerChamadoLoading backGroundDinamico={this.props.backgroundMaisEscuro}>
                            <ContainerImagem>
                                <ImageLoading backgroundinamico={this.props.backgroundMaisEscuro}></ImageLoading>
                                {/*  <img src={this.state.fotoCliente} alt="foto cliente" style={{ height: 40, width: 40, borderRadius: 50 }} /> */}
                            </ContainerImagem>
                            <ContainerDadosChamado>
                                <ContaineDadosChamadosPrincipal>
                                    <SubContainerIconeEFraseClienteAtendente >
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                    <SubContainerIconeEFraseClienteAtendente >
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                    <SubContainerIconeEFraseClienteAtendente >
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                </ContaineDadosChamadosPrincipal>


                            </ContainerDadosChamado>


                        </ContainerChamadoLoading>
                        <ContainerChamadoLoading backGroundDinamico={this.props.backgroundMaisEscuro}>
                            <ContainerImagem>
                                <ImageLoading backgroundinamico={this.props.backgroundMaisEscuro}></ImageLoading>
                                {/*  <img src={this.state.fotoCliente} alt="foto cliente" style={{ height: 40, width: 40, borderRadius: 50 }} /> */}
                            </ContainerImagem>
                            <ContainerDadosChamado>
                                <ContaineDadosChamadosPrincipal>
                                    <SubContainerIconeEFraseClienteAtendente >
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                    <SubContainerIconeEFraseClienteAtendente >
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                    <SubContainerIconeEFraseClienteAtendente >
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                </ContaineDadosChamadosPrincipal>


                            </ContainerDadosChamado>


                        </ContainerChamadoLoading>
                    </SubContainer>

                    <ContainerPrincipalDadosCliente backGroundDinamico={this.props.backgroundSegundo}>
                        <SubContainerCliente>
                            <ContainerImagemAlterarCliente>
                                <ImagemPerfilLoading backgroundinamico={this.props.backgroundSegundo}></ImagemPerfilLoading>
                                {/*   <ImagePerfil onClick={() => { alert('trocar imagem') }} src={this.state.fotoCliente} alt="foto cliente" style={{}} /> */}
                            </ContainerImagemAlterarCliente>
                            <ContainerDadosClienteLoading>

                                <InputLoadingEmpresa backgroundinamico={this.props.backgroundSegundo}></InputLoadingEmpresa>
                                <InputLoadingEmpresa backgroundinamico={this.props.backgroundSegundo}></InputLoadingEmpresa>
                                <InputLoadingEmpresa backgroundinamico={this.props.backgroundSegundo}></InputLoadingEmpresa>
                                <InputLoadingEmpresa backgroundinamico={this.props.backgroundSegundo}></InputLoadingEmpresa>
                                <InputLoadingEmpresa backgroundinamico={this.props.backgroundSegundo}></InputLoadingEmpresa>
                                <InputLoadingEmpresa backgroundinamico={this.props.backgroundSegundo}></InputLoadingEmpresa>
                                <InputLoadingEmpresa backgroundinamico={this.props.backgroundSegundo}></InputLoadingEmpresa>

                            </ContainerDadosClienteLoading>
                        </SubContainerCliente>
                        <SubContainerClientePermissaoLoading>
                            <InputLoadingComentario backgroundinamico={this.props.backgroundSegundo}></InputLoadingComentario>
                            <InputLoadingComentario backgroundinamico={this.props.backgroundSegundo}></InputLoadingComentario>
                        </SubContainerClientePermissaoLoading>
                    </ContainerPrincipalDadosCliente>

                </Container>
            )
        } else {
            return (
                <Container>
                    <SubContainer style={{ flex: this.props.arrayTodasAvaliacoes.length > 0 ? 0.5 : 1 }}>
                        {this.props.filtroAvaliacao === 'Avaliação' &&
                            <div style={{ justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                <p style={{ marginLeft: 40, color: this.props.coresLetraPadrao }}>{this.props.filtroAvaliacao}:</p>
                                <ContainerInputSearch backGroundDinamico='#FFF'>
                                    <IconeSair className="icon-close" onClick={() => { this.removeFiltrosAll(); }} />
                                    <div style={{ flex: 1 }}>
                                        <SelectSearch
                                            renderValue={(valueProps) =>
                                                <DivInputSearch>
                                                    <InputSearch
                                                        corLetraDinamico={this.props.coresLetraPadrao}
                                                        backgroundDinamico={this.props.backgroundMaisEscuro}
                                                        placeholderColor={this.props.corLetraSecundaria}
                                                        corBordaDinamica={this.props.corBotaoPadrao}
                                                        {...valueProps} ></InputSearch>
                                                    <IconeSetaPraBaixo className="icon-angle-arrow-down" colorDinamico={this.props.coresLetraPadrao} />
                                                </DivInputSearch>
                                            }
                                            onChange={(value) => {
                                                this.props.modificaValorAvaliacaoSelecionadoTelaAvaliacaoTodas(value);
                                                this.props.listaUltimasAvaliacoesComValorAvaliacao(0, [], '', value);
                                                this._togglefiltroValorAvaliacaoSelecionado();
                                                this.props.desligaOuvintesChamadosFirebase('relatorioavaliacaotodas', '', '', '');
                                            }}
                                            value={this.props.valorAvaliacaoSelecionadoTodasAvaliacoes}
                                            options={this.props.valorAvaliacaoTodasAvaliacoes}
                                            placeholder="Selecione a Avaliação"
                                            search
                                        />
                                    </div>
                                </ContainerInputSearch>
                            </div>}
                        {this.props.filtroAvaliacao === 'Atendente' &&
                            <div style={{ justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                <p style={{ marginLeft: 40, color: this.props.coresLetraPadrao }}>{this.props.filtroAvaliacao}:</p>
                                <ContainerInputSearch backGroundDinamico='#FFF'>
                                    <IconeSair className="icon-close" onClick={() => { this.removeFiltrosAll(); }} />
                                    <div style={{ flex: 1 }}>
                                        <SelectSearch
                                            renderValue={(valueProps) =>
                                                <DivInputSearch>
                                                    <InputSearch
                                                        corLetraDinamico={this.props.coresLetraPadrao}
                                                        backgroundDinamico={this.props.backgroundMaisEscuro}
                                                        placeholderColor={this.props.corLetraSecundaria}
                                                        corBordaDinamica={this.props.corBotaoPadrao}
                                                        {...valueProps} ></InputSearch>
                                                    <IconeSetaPraBaixo className="icon-angle-arrow-down" colorDinamico={this.props.coresLetraPadrao} />
                                                </DivInputSearch>
                                            }
                                            onChange={(value) => {
                                                this.props.modificaAtendenteSelecionadoTelaAvaliacaoTodas(value);
                                                this.props.listaUltimasAvaliacoesComAtendente(0, [], '', value);
                                                this._togglefiltroAtendenteSelecionado();
                                                this.props.desligaOuvintesChamadosFirebase('relatorioavaliacaotodas', '', '', '');
                                            }}
                                            options={this.props.atendenteTodasAvaliacoes}
                                            value={this.props.atendenteSelecionadoTodasAvaliacoes}
                                            placeholder={this.props.atendenteTodasAvaliacoes.length == 0 ? `Nenhum Atendente Cadastrado` : "Selecione o Atendente"}
                                            search={this.props.atendenteTodasAvaliacoes.length == 0 ? false : true}
                                        />
                                    </div>
                                </ContainerInputSearch>
                            </div>}
                        {this.props.filtroAvaliacao === 'Cliente' &&
                            <div style={{ justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                <p style={{ marginLeft: 40, color: this.props.coresLetraPadrao }}>{this.props.filtroAvaliacao}:</p>
                                <ContainerInputSearch backGroundDinamico='#FFF'>
                                    <IconeSair className="icon-close" onClick={() => { this.removeFiltrosAll(); }} />
                                    <div style={{ flex: 1 }}>
                                        {this.props.clientesTodasAvaliacoes.length === 0 && <SelectSearchPaginate
                                            onSelectClient={(client) => {

                                                this.setState({ clientSelected: { value: client.value, label: client.label } });
                                                this.props.listaUltimasAvaliacoesComCliente(0, [], '', client.value);
                                                this._togglefiltroClienteSelecionado();
                                                this.props.desligaOuvintesChamadosFirebase('relatorioavaliacaotodas', '', '', '');

                                            }}
                                            value={this.state.clientSelected}
                                            isMyContact={false}
                                            background={this.props.backgroundMaisEscuro}
                                            letterColor={this.props.coresLetraPadrao}
                                            backgroundDinamico={this.props.backgroundMaisEscuro}
                                            placeholderColor={this.props.coresLetraSecundario}
                                            corBordaDinamica={this.props.corBotaoPadrao}
                                            width={400}
                                            letraBotaoPadrao={this.props.letraBotaoPadrao}
                                        />}
                                        {this.props.clientesTodasAvaliacoes.length > 0 &&<SelectSearch
                                            renderValue={(valueProps) =>
                                                <DivInputSearch>
                                                    <InputSearch
                                                        corLetraDinamico={this.props.coresLetraPadrao}
                                                        backgroundDinamico={this.props.backgroundMaisEscuro}
                                                        placeholderColor={this.props.corLetraSecundaria}
                                                        corBordaDinamica={this.props.corBotaoPadrao}
                                                        {...valueProps} ></InputSearch>
                                                    <IconeSetaPraBaixo className="icon-angle-arrow-down" colorDinamico={this.props.coresLetraPadrao} />
                                                </DivInputSearch>
                                            }
                                            onChange={(value) => {
                                                this.props.modificaClienteSelecionadoTelaAvaliacaoTodas(value);
                                                this.props.listaUltimasAvaliacoesComCliente(0, [], '', value);
                                                this._togglefiltroClienteSelecionado();
                                                this.props.desligaOuvintesChamadosFirebase('relatorioavaliacaotodas', '', '', '');
                                            }}
                                            options={this.props.clientesTodasAvaliacoes}
                                            value={this.props.clienteSelecionadoTodasAvaliacoes}
                                            placeholder={this.props.clientesTodasAvaliacoes.length == 0 ? `Nenhum Cliente Cadastrado` : "Selecione o Cliente"}
                                            search={this.props.clientesTodasAvaliacoes.length == 0 ? false : true}
                                        />}
                                    </div>
                                </ContainerInputSearch>
                            </div>}
                        <FlatList
                            list={this.props.arrayTodasAvaliacoes}
                            renderItem={(item) => <FlatListRelatorioRegistroAvaliacoes data={item} key={item.key + item.alterado} />}
                            renderWhenEmpty={() => {
                                return (
                                    <ContainerListaVazia>
                                        <i className="icon-box" style={{ color: this.props.coresLetraPadrao, fontSize: 110 }} />
                                        <p style={{ color: this.props.coresLetraPadrao, fontSize: 20 }}>Nenhum Registro Encontrado</p>
                                    </ContainerListaVazia>
                                )

                            }}
                            hasMoreItems={this.props.loadingFimDaPaginaTodasAvaliacoes}
                            loadMoreItems={() => this.exibeUltimasAvaliacoes()}
                            paginationLoadingIndicator={<MoonLoader
                                css={{ margin: 10 }}
                                size={40}
                                color={this.props.corBotaoPadrao}
                                loading={this.props.loadingFimDaPaginaTodasAvaliacoes}
                            />}

                        />
                    </SubContainer>
                    {this.props.arrayTodasAvaliacoes.length > 0 && <ContainerPrincipalDadosCliente backGroundDinamico={this.props.backgroundSegundo}>

                        <SubContainerCliente>

                            <ContainerImagemAlterarCliente>
                                {this.renderImagem()}

                            </ContainerImagemAlterarCliente>
                            <ContainerDadosCliente>

                                <p style={{ color: this.props.corLetraDescricaoDosCampos, fontWeight: 'bold', fontSize: 12 }}>Cliente:</p>
                                <DivContainerInputEmpresaVinculada>
                                    <InputEmpresa
                                        disabled
                                        type="text"
                                        placeholder="Cliente"
                                        colorDinamica={this.props.coresLetraPadrao}
                                        placeholderDinamico={this.props.coresLetraSecundario}
                                        borderDinamico={this.props.corBotaoPadrao}
                                        value={this.props.detalhesAvaliacao.cliente}
                                    //  onChange={(e) => { this.props.modificaEmpresaClienteTelaCadastroCliente(e.target.value) }}
                                    //  onFocus={() => { this.handleInputEmailFocus() }}
                                    // onBlur={() => { this.handleInputEmailBlur() }}
                                    />


                                </DivContainerInputEmpresaVinculada>
                                {/*  </div> */}
                                <p style={{ color: this.props.corLetraDescricaoDosCampos, fontWeight: 'bold', fontSize: 12, marginTop: 5 }}>Atendente:</p>
                                <InputEmpresa
                                    disabled
                                    type="text"
                                    placeholder="Atendente"
                                    colorDinamica={this.props.coresLetraPadrao}
                                    placeholderDinamico={this.props.coresLetraSecundario}
                                    borderDinamico={this.props.corBotaoPadrao}
                                    value={this.props.detalhesAvaliacao.atendente}
                                //  onChange={(e) => { this.props.modificaNomeClienteTelaCadastroCliente(e.target.value) }}
                                //  onFocus={() => { this.handleInputEmailFocus() }}
                                // onBlur={() => { this.handleInputEmailBlur() }}
                                />
                                <p style={{ color: this.props.corLetraDescricaoDosCampos, fontWeight: 'bold', fontSize: 12, marginTop: 5 }}>Setor:</p>
                                <InputEmpresa
                                    disabled
                                    type="text"
                                    placeholder="Setor"
                                    colorDinamica={this.props.coresLetraPadrao}
                                    placeholderDinamico={this.props.coresLetraSecundario}
                                    borderDinamico={this.props.corBotaoPadrao}
                                    value={this.props.detalhesAvaliacao.setor}
                                //onChange={(e) => { this.props.modificaEnderecoTelaCAdastroCliente(e.target.value) }}
                                //  onFocus={() => { this.handleInputEmailFocus() }}
                                // onBlur={() => { this.handleInputEmailBlur() }}
                                />
                                <p style={{ color: this.props.corLetraDescricaoDosCampos, fontWeight: 'bold', fontSize: 12, marginTop: 5 }}>Data Vencimento:</p>
                                <InputEmpresa
                                    disabled
                                    type="text"
                                    placeholder="Data Vencimento"
                                    colorDinamica={this.props.coresLetraPadrao}
                                    placeholderDinamico={this.props.coresLetraSecundario}
                                    borderDinamico={this.props.corBotaoPadrao}
                                    value={this.props.detalhesAvaliacao.dataVencimento}
                                //onChange={(e) => { this.props.modificaTelefoneTelaCAdastroCliente(e.target.value) }}
                                //  onFocus={() => { this.handleInputEmailFocus() }}
                                // onBlur={() => { this.handleInputEmailBlur() }}
                                />
                                <p style={{ color: this.props.corLetraDescricaoDosCampos, fontWeight: 'bold', fontSize: 12, marginTop: 5 }}>Prioridade:</p>
                                <InputEmpresa
                                    disabled
                                    type="text"
                                    placeholder="Prioridade"
                                    colorDinamica={this.props.coresLetraPadrao}
                                    placeholderDinamico={this.props.coresLetraSecundario}
                                    borderDinamico={this.props.corBotaoPadrao}
                                    value={this.props.detalhesAvaliacao.prioridade}
                                //onChange={(e) => { this.props.modificaTelefoneTelaCAdastroCliente(e.target.value) }}
                                //  onFocus={() => { this.handleInputEmailFocus() }}
                                // onBlur={() => { this.handleInputEmailBlur() }}
                                />
                                <p style={{ color: this.props.corLetraDescricaoDosCampos, fontWeight: 'bold', fontSize: 12, marginTop: 5 }}>Local:</p>
                                <InputEmpresa
                                    disabled
                                    type="text"
                                    placeholder="Local"
                                    colorDinamica={this.props.coresLetraPadrao}
                                    placeholderDinamico={this.props.coresLetraSecundario}
                                    borderDinamico={this.props.corBotaoPadrao}
                                    value={this.props.detalhesAvaliacao.local}
                                //onChange={(e) => { this.props.modificaTelefoneTelaCAdastroCliente(e.target.value) }}
                                //  onFocus={() => { this.handleInputEmailFocus() }}
                                // onBlur={() => { this.handleInputEmailBlur() }}
                                />
                                <p style={{ color: this.props.corLetraDescricaoDosCampos, fontWeight: 'bold', fontSize: 12, marginTop: 5 }}>Valor Avaliação:</p>
                                <InputEmpresa
                                    disabled
                                    type="text"
                                    placeholder="Valor Avaliação"
                                    colorDinamica={this.props.coresLetraPadrao}
                                    placeholderDinamico={this.props.coresLetraSecundario}
                                    borderDinamico={this.props.detalhesAvaliacao.valorAvaliacao}
                                    value={this.props.detalhesAvaliacao.valorAvaliacao}
                                //  onChange={(e) => { this.props.modificaTelefoneTelaCAdastroCliente(e.target.value) }}
                                //  onFocus={() => { this.handleInputEmailFocus() }}
                                // onBlur={() => { this.handleInputEmailBlur() }}
                                />
                            </ContainerDadosCliente>
                        </SubContainerCliente>
                        <SubContainerClientePermissao>
                            <ContainerPermitir>
                                <DivInputComentarChamado >
                                    <p style={{ fontSize: 14, color: this.props.corLetraDescricaoDosCampos }}>Descrição Problema:</p>
                                    <InputDescricaoChamado
                                        disabled
                                        type="textarea"
                                        //     onChange={(value) => { this.props.modificaInputComentario(value.target.value) }}
                                        corLetraDinamico={this.props.coresLetraPadrao}
                                        value={this.props.detalhesAvaliacao.descricaoProblema}
                                        corLetraSecundaria={this.props.coresLetraSecundario}
                                        placeholder="Descrição do Problema"
                                    />
                                </DivInputComentarChamado>
                                <DivInputComentarChamado >
                                    <p style={{ fontSize: 14, color: this.props.corLetraDescricaoDosCampos }}>Descrição Solução:</p>
                                    <InputDescricaoChamado
                                        disabled
                                        type="textarea"
                                        //     onChange={(value) => { this.props.modificaInputComentario(value.target.value) }}
                                        corLetraDinamico={this.props.coresLetraPadrao}
                                        value={this.props.detalhesAvaliacao.solucaoAtendimento}
                                        corLetraSecundaria={this.props.coresLetraSecundario}
                                        placeholder=""
                                    />
                                </DivInputComentarChamado>
                                <DivInputComentarChamado >
                                    <p style={{ fontSize: 14, color: this.props.corLetraDescricaoDosCampos, marginTop: 15 }}>Comentário da Avaliação:</p>
                                    <InputDescricaoChamado
                                        disabled
                                        type="textarea"
                                        //   onChange={(value) => { this.props.modificaInputComentario(value.target.value) }}
                                        corLetraDinamico={this.props.coresLetraPadrao}
                                        value={this.props.detalhesAvaliacao.comentarioAvaliacao}
                                        corLetraSecundaria={this.props.coresLetraSecundario}
                                        placeholder="Comentário Não informado..."
                                    />
                                </DivInputComentarChamado>

                            </ContainerPermitir>



                        </SubContainerClientePermissao>

                    </ContainerPrincipalDadosCliente>}
                </Container>
            )
        }
    }

    render() {

        return (
            <>
                {this.renderLoadingRegistroAvaliacoes()}
            </>
        );
    }
}

const mapStateToProps = state => {
    return (
        {
            backgroundPrimeiro: state.CoresPadraoSistema.backgroundPrimeiro,
            backgroundSegundo: state.CoresPadraoSistema.backgroundSegundo,
            backgroundMaisEscuro: state.CoresPadraoSistema.backgroundMaisEscuro,
            coresLetraPadrao: state.CoresPadraoSistema.coresLetraPadrao,
            corBotaoPadrao: state.CoresPadraoSistema.corBotaoPadrao,
            corSegundoBotaoPadrao: state.CoresPadraoSistema.corSegundoBotaoPadrao,
            coresLetraSecundario: state.CoresPadraoSistema.coresLetraSecundario,
            letraBotaoPadrao: state.CoresPadraoSistema.letraBotaoPadrao,
            corLetraDescricaoDosCampos: state.CoresPadraoSistema.corLetraDescricaoDosCampos,
            nomeRota: state.HeaderReducer.nomeRota,
            testePesquisa: state.HeaderReducer.testePesquisa,




            detalhesAvaliacao: state.AvaliacaoReducer.detalhesAvaliacao,
            filtroAvaliacao: state.AvaliacaoReducer.filtroAvaliacao,
            avaliacoesFiltro: state.AvaliacaoReducer.avaliacoesFiltro,

            arrayTodasAvaliacoes: state.AvaliacaoReducer.arrayTodasAvaliacoes,
            verificaConexaoInterenetTodasAvaliacoes: state.AvaliacaoReducer.verificaConexaoInterenetTodasAvaliacoes,
            loadingTelaTodasAvaliacoes: state.AvaliacaoReducer.loadingTelaTodasAvaliacoes,
            refreshControlFlatListTodasAvaliacoes: state.AvaliacaoReducer.refreshControlFlatListTodasAvaliacoes,
            referenciaUltimaKeyTodasAvaliacoes: state.AvaliacaoReducer.referenciaUltimaKeyTodasAvaliacoes,
            loadingFimDaPaginaTodasAvaliacoes: state.AvaliacaoReducer.loadingFimDaPaginaTodasAvaliacoes,
            premium: state.PremiumReducer.premium,
            clientesTodasAvaliacoes: state.ChamadoReducer.clientesSectionedConsulta,
            clienteSelecionadoTodasAvaliacoes: state.AvaliacaoReducer.clienteSelecionadoTodasAvaliacoes,
            atendenteTodasAvaliacoes: state.AtendenteReducer.atendentesSectionedConsulta,
            atendenteSelecionadoTodasAvaliacoes: state.AvaliacaoReducer.atendenteSelecionadoTodasAvaliacoes,
            valorAvaliacaoTodasAvaliacoes: state.AvaliacaoReducer.valorAvaliacaoTodasAvaliacoes,
            valorAvaliacaoSelecionadoTodasAvaliacoes: state.AvaliacaoReducer.valorAvaliacaoSelecionadoTodasAvaliacoes,
            licensawebouapp: state.PremiumReducer.licensawebouapp,
            loadingListaAtendenteAvaliacaoTodas: state.AvaliacaoReducer.loadingListaAtendenteAvaliacaoTodas,
            loadingListaClientesAvaliacaoTodas: state.AvaliacaoReducer.loadingListaClientesAvaliacaoTodas,
            permissoesAcessarTelasAtendente: state.AtendenteReducer.permissoesAcessarTelasAtendente,
            ultimaKeyReferenciaClienteTodasAvaliacoes: state.AvaliacaoReducer.ultimaKeyReferenciaClienteTodasAvaliacoes,
            ultimaKeyReferenciaAtendenteTodasAvaliacoes: state.AvaliacaoReducer.ultimaKeyReferenciaAtendenteTodasAvaliacoes,
            ultimaKeyReferenciaValorAvaliacaoTodasAvaliacoes: state.AvaliacaoReducer.ultimaKeyReferenciaValorAvaliacaoTodasAvaliacoes,
            imagemClienteTelaDetalhesAvaliacao: state.AvaliacaoReducer.imagemClienteTelaDetalhesAvaliacao,
        }
    );
};
export default connect(mapStateToProps, {
    modificaFiltroAvaliacao, modificaNomeRotaNoHeader, listaAsUltimasAvaliacoes,
    removeKeyTodosOsFiltros,
    modificaClienteSelecionadoTelaAvaliacaoTodas, modificaAtendenteSelecionadoTelaAvaliacaoTodas, modificaValorAvaliacaoSelecionadoTelaAvaliacaoTodas,
    listaUltimasAvaliacoesComCliente, listaUltimasAvaliacoesComAtendente, listaUltimasAvaliacoesComValorAvaliacao, desligaOuvintesChamadosFirebase,
    listaClientesTelaConsultaClientes, listaAtendentesTelaConsultaAtendente
})(withRouter(RelatorioRegistroAvaliacoes));
