import React,{memo} from 'react';
import { connect } from 'react-redux';
import firebase from '../../servicos/FirebaseConnection';
import Avatar from "react-avatar";
import { Link, withRouter } from 'react-router-dom';
import {
    Title, ContainerChamado, ContainerImagem, ContainerDadosChamado,
    ContaineDadosChamadosPrincipal, ContaineDadosChamadosSecundario,
    ContainerIconeEFrase, TextCliente, TextProblema, ContainerIconeEFraseClienteAtendente,
    SubContainerIconeEFraseClienteAtendente, TextNumeroChamado,
    ContainerNumeroChamado, ContainerDataAbertura, ContainerSeta, TextEmpresa
} from './styled';
import {
    navegaTelaALterarChamado, navegaTelaAlterarCliente
} from '../../actions/ChamadoAction';
import { navegaTelaAlterarAtendente } from '../../actions/AtendenteAction'
import imgTeste from '../../imagens/userPadrao.png'
import { toastr } from 'react-redux-toastr';

class FlatListAtendentes extends React.Component {
    state = {
        fotoAtendente: null,
        // email: this.props.data.email,
        // nome: this.props.data.nome,
        email: this.props.data.email == undefined ? 'Entre em contato com desenvolvedor' : this.props.data.email,
        nome: this.props.data.nome == undefined ? 'Error' : this.props.data.nome,
        keyAtendente: this.props.data.key
    }

    componentDidMount() {
       //
       if (this.props.atendentes[0].key == this.props.data.key) {
        this.props.navegaTelaAlterarAtendente(this.state.fotoAtendente, this.state.nome, this.state.email, this.props.data.senha, this.props.data.senha, this.props.data.key,
            this.props.data.permissaoAcessoRelatorios, this.props.data.permissaoAdministrador, this.props.data.permissaoAlterarCadastrarAtendente,
            this.props.data.permissaoAlterarCadastrarAvisos, this.props.data.permissaoAlterarCadastrarCliente, this.props.data.permissaoAlterarCadastrarSetores,
            this.props.data.contaPrincipal, this.props.data.StringAdmin, this.props.data.uid,this.props.data.permissaoCancelarAtendimento)
    }
    }
    verificaSeEhAlphabeto = (ch) => {
        // 
        let verificaSeEhAlphabeto = typeof ch === "string" && ch.length === 1
            && (ch >= "a" && ch <= "z");
        //
        // 
        if (verificaSeEhAlphabeto) {
            //
            switch (ch) {
                case "a":
                    this.setState({ fotoAtendente: require('../../imagens/a.png') });
                    break;
                case "b":
                    this.setState({ fotoAtendente: require('../../imagens/b.png') });
                    break;
                case "c":
                    this.setState({ fotoAtendente: require('../../imagens/c.png') });
                    break;
                case "d":
                    this.setState({ fotoAtendente: require('../../imagens/d.png') });
                    break;
                case "e":
                    this.setState({ fotoAtendente: require('../../imagens/e.png') });
                    break;
                case "f":
                    this.setState({ fotoAtendente: require('../../imagens/f.png') });
                    break;
                case "g":
                    this.setState({ fotoAtendente: require('../../imagens/g.png') })
                    break;
                case "h":
                    this.setState({ fotoAtendente: require('../../imagens/h.png') });
                    break;
                case "i":
                    this.setState({ fotoAtendente: require('../../imagens/i.png') });
                    break;
                case "j":
                    this.setState({ fotoAtendente: require('../../imagens/j.png') });
                    break;
                case "k":
                    this.setState({ fotoAtendente: require('../../imagens/k.png') });
                    break;
                case "l":
                    this.setState({ fotoAtendente: require('../../imagens/l.png') });
                    break;
                case "m":
                    this.setState({ fotoAtendente: require('../../imagens/m.png') });
                    break;
                case "n":
                    this.setState({ fotoAtendente: require('../../imagens/n.png') });
                    break;
                case "o":
                    this.setState({ fotoAtendente: require('../../imagens/o.png') });
                    break;
                case "p":
                    this.setState({ fotoAtendente: require('../../imagens/p.png') });
                    break;
                case "q":
                    this.setState({ fotoAtendente: require('../../imagens/q.png') });
                    break;
                case "r":
                    this.setState({ fotoAtendente: require('../../imagens/r.png') });
                    break;
                case "s":
                    this.setState({ fotoAtendente: require('../../imagens/s.png') });
                    break;
                case "t":
                    this.setState({ fotoAtendente: require('../../imagens/t.png') });
                    break;
                case "u":
                    this.setState({ fotoAtendente: require('../../imagens/u.png') });
                    break;
                case "v":
                    this.setState({ fotoAtendente: require('../../imagens/v.png') });
                    break;
                case "x":
                    this.setState({ fotoAtendente: require('../../imagens/x.png') });
                    break;
                case "z":
                    this.setState({ fotoAtendente: require('../../imagens/z.png') });
                    break;
                case "w":
                    this.setState({ fotoAtendente: require('../../imagens/w.png') });
                    break;
                case "y":
                    this.setState({ fotoAtendente: require('../../imagens/y.png') });
                    break;
                default:
                    this.setState({ fotoAtendente: require('../../imagens/userPadrao.png') });
                   // 
                    break;

            }
        } else {
            //   
            let urlIMG = require('../../imagens/userPadrao.png')
            this.setState({ fotoAtendente: urlIMG });
        }

    }
    navegaTelaAlterarAtendenteLocal() {
        /*    if (this.props.permissoesAcessarTelasAtendente[0].permissaoAlterarCadastrarAtendente == undefined) {
             alert('Verifique se há conexão com a INTERNET e depois tente novamente.');
           } else {
             if (this.props.permissoesAcessarTelasAtendente[0].permissaoAlterarCadastrarAtendente) { */
        if (this.state.email == 'Entre em contato com desenvolvedor') {
            toastr.warning('Error', 'Este atendente está com um error, entre em contato com Suporte DropDesk para normalizar a situação, obrigado pela compreensão')
        } else {
            this.props.navegaTelaAlterarAtendente(!!this.props.data.urlImagemNuvem ? this.props.data.urlImagemNuvem : undefined, this.state.nome, this.state.email, this.props.data.senha, this.props.data.senha, this.props.data.key,
                this.props.data.permissaoAcessoRelatorios, this.props.data.permissaoAdministrador, this.props.data.permissaoAlterarCadastrarAtendente,
                this.props.data.permissaoAlterarCadastrarAvisos, this.props.data.permissaoAlterarCadastrarCliente, this.props.data.permissaoAlterarCadastrarSetores,
                this.props.data.contaPrincipal, this.props.data.StringAdmin, this.props.data.uid,this.props.data.permissaoCancelarAtendimento)
        }
        /*      } else {
               Alert.alert(
                 'Atenção',
                 'Você não tem permissão para Cadastrar/Alterar Atendentes.',
       
                 [{ text: 'OK', onPress: () => { } }],
               );
             }
           } */
    }
    renderImagem() {
        if (this.state.fotoAtendente == null) {
            return (
                <div style={{ height: 40, width: 40, borderRadius: '50%', backgroundColor: 'rgba(159,173,183, 0.3)', border: 'none' }} />
            )
        } else {
            return (
                <img src={this.state.fotoAtendente} alt="foto atendente" style={{ height: 40, width: 40, borderRadius: 50, backgroundColor: 'rgba(159,173,183, 0.3)' }} />
            )
        }
    }
    renderImagemClienteBloqueado() {
        if (this.state.fotoAtendente == null) {
            return (
                <div style={{ height: 40, width: 40, borderRadius: '50%', backgroundColor: 'rgba(159,173,183, 0.3)', border: 'none' }} />
            )
        } else {
            return (
                <img src={this.state.fotoAtendente} alt="foto atendente" style={{ opacity: 0.4, height: 40, width: 40, borderRadius: 50, backgroundColor: 'rgba(159,173,183, 0.3)' }} />
            )
        }
    }
    render() {
        if (this.props.data.clienteBoqueadoDevidoLimiteExcedido == 1) {
            return (


                <ContainerChamado backGroundDinamico={this.props.backgroundMaisEscuro} type="button" onClick={() => { toastr.warning('Limite de Atendentes Atingido', `Este atendente está com o acesso bloqueado porque a QUANTIDADE DE ATENDENTES LIBERADOS PARA CADASTRO foi Atingido, Entre no Menu "Versão Premium" e veja a quantidade de Atendentes Liberados para sua Organização, Assine a Versão PREMIUM com mais Atendentes Liberados e libere esse e mais atendentes!`) }}>

                    <ContainerImagem>
                        {this.renderImagemClienteBloqueado()}
                        {/* <img src={this.state.fotoAtendente} alt="foto atendente" style={{ height: 40, width: 40, borderRadius: 50 }} /> */}
                    </ContainerImagem>
                    <ContainerDadosChamado>
                        <ContaineDadosChamadosPrincipal>
                            <SubContainerIconeEFraseClienteAtendente >
                                <TextCliente data-tip="Nome" data-for="tip-top" corLetraDinamica='#9fadb7'>{this.props.data.nome}</TextCliente>
                            </SubContainerIconeEFraseClienteAtendente>
                            <SubContainerIconeEFraseClienteAtendente >
                                <TextEmpresa data-tip="Email" data-for="tip-top" corLetraDinamica='#9fadb7'>{this.props.data.email}</TextEmpresa>
                            </SubContainerIconeEFraseClienteAtendente>
                        </ContaineDadosChamadosPrincipal>
                        <ContainerSeta>
                            <TextEmpresa data-tip="Atendente admin" data-for="tip-top" corLetraDinamica='#9fadb7'>{this.props.data.StringAdmin}</TextEmpresa>
                            <i className="icon-nextbold" style={{ color: '#9fadb7', fontSize: 10, color: this.props.coresLetraSecundario }} />
                        </ContainerSeta>

                    </ContainerDadosChamado>


                </ContainerChamado>
            )
        } else {
            return (

                <ContainerChamado backGroundDinamico={this.props.backgroundMaisEscuro} type="button" onClick={() => { this.navegaTelaAlterarAtendenteLocal() }}>
                    {this.state.keyAtendente == this.props.keyAtendente && <div style={{ width: 5, backgroundColor: this.props.corBotaoPadrao, height: 58, borderTopLeftRadius: 7, borderBottomLeftRadius: 7 }}></div>}
                    <ContainerImagem>
                    <Avatar
                        name={this.props.data.nome}
                        size='50'
                        src={!!this.props.data.urlImagemNuvem ? this.props.data.urlImagemNuvem : undefined}
                        maxInitials={2}
                        round
                        style={{ cursor: "pointer" }}
                    />
                      {/*   {this.renderImagem()} */}
                        {/* <img src={this.state.fotoAtendente} alt="foto atendente" style={{ height: 40, width: 40, borderRadius: 50 }} /> */}
                    </ContainerImagem>
                    <ContainerDadosChamado>
                        <ContaineDadosChamadosPrincipal>
                            <SubContainerIconeEFraseClienteAtendente >
                                <TextCliente data-tip="Nome" data-for="tip-top" corLetraDinamica={this.props.coresLetraPadrao}>{this.state.nome}</TextCliente>
                            </SubContainerIconeEFraseClienteAtendente>
                            <SubContainerIconeEFraseClienteAtendente >
                                <TextEmpresa data-tip="Email" data-for="tip-top" corLetraDinamica={this.props.coresLetraSecundario}>{this.state.email}</TextEmpresa>
                            </SubContainerIconeEFraseClienteAtendente>
                        </ContaineDadosChamadosPrincipal>
                        <ContainerSeta>
                            <TextEmpresa data-tip="Atendente admin" data-for="tip-top" corLetraDinamica={this.props.coresLetraSecundario}>{this.props.data.StringAdmin}</TextEmpresa>
                            <i className="icon-nextbold" style={{ color: '#000', fontSize: 10, color: this.props.coresLetraSecundario }} />
                        </ContainerSeta>

                    </ContainerDadosChamado>


                </ContainerChamado>

            );
        }
    }
}

const mapStateToProps = state => {
    return (
        {
            backgroundPrimeiro: state.CoresPadraoSistema.backgroundPrimeiro,
            backgroundSegundo: state.CoresPadraoSistema.backgroundSegundo,
            backgroundMaisEscuro: state.CoresPadraoSistema.backgroundMaisEscuro,
            coresLetraPadrao: state.CoresPadraoSistema.coresLetraPadrao,
            coresLetraSecundario: state.CoresPadraoSistema.coresLetraSecundario,
            corBotaoPadrao: state.CoresPadraoSistema.corBotaoPadrao,
            corSegundoBotaoPadrao: state.CoresPadraoSistema.corSegundoBotaoPadrao,
            corLetraDescricaoDosCampos: state.CoresPadraoSistema.corLetraDescricaoDosCampos,
            nomeRota: state.HeaderReducer.nomeRota,
            keyAtendente: state.AtendenteReducer.keyAtendente,
            email: state.LoginReducer.email,
            atendentes: state.AtendenteReducer.atendentes,

            permissaoCancelarAtendimento: state.AtendenteReducer.permissaoCancelarAtendimento,
        }
    );
};
export default connect(mapStateToProps, {
    navegaTelaALterarChamado, navegaTelaAlterarCliente, navegaTelaAlterarAtendente
})(memo(FlatListAtendentes));
