import axios from "axios";
import { getToken } from "./token";
import { getIdCompanyByLocalStorage } from "./common";

const api = axios.create({
    headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Accept': 'application/json',
    },
    baseURL: 'https://us-central1-dark-4b307.cloudfunctions.net/',
});


api.interceptors.request.use(async (request) => {
    const token = await getToken();
    const idCompany = getIdCompanyByLocalStorage();
    request.headers['Authorization'] = `Bearer ${token}`;
    request.headers['idCompany'] = idCompany;

    return request;
});

/* api.interceptors.response.use((response) => {
    return response
}, (error) => {
    return new Promise < AxiosResponse < any, any >> (async (resolve, reject) => {
        if (axios.isAxiosError(error) && error.response) {

            const response = error.response;
            console.log(response);
            if (response.data.error === 'no_company_claims') {

                return reject(error);
            } else {
                return reject(error);
            }

        } else {
            return reject(error);
        }
    })
}) */

export default api;
