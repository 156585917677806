import _ from "lodash";
import { v4 as uuidv4 } from 'uuid';

export const removeItemsDuplicateFromListByValue = (list, value) => {
    const eligibleItems = _.uniqBy(list, value);
    return eligibleItems;
}
const convertSQLISOToDate = (dateString) => {
    if (!dateString) {
        return null;
    }
    return new Date(dateString ? dateString.replace(' ', 'T') : new Date);
}

export const displayDateToLocale = (dateString) => {
    const date = convertSQLISOToDate(dateString);
    if (!date) {
        return '';
    }
    return date.toLocaleDateString("pt-BR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
    })
}


export const downloadFile = (urlDownload, fileName) => {
    const anchor = document.createElement('a');
    const url = urlDownload;
    anchor.download = fileName;
    anchor.fileName = fileName;
    anchor.href = url;
    anchor.target = "_blank";
    anchor.click();
    anchor.remove();
    window.URL.revokeObjectURL(url);
}

export const generateUUIDv4 = () => {
    return uuidv4();
}

export const renameFile = (originalFile, newName) => {
    return new File([originalFile], newName, {
        type: originalFile.type,
        lastModified: originalFile.lastModified,
    });
}