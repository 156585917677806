import {
    MODIFICADATAFINALAVALIACAOGERAL, MODIFICADATAINICIALAVALIACAOGERAL, ENVIAPERIODOPERSONALIZADOAVALIACAOGERAL,
    ATIVADESATIVALOADINGTELARELATORIOAVALIACAOGERAL, MENSAGEMERRORAPLICAPERIODOPERSONALIZADO, ENVIAAVALIACOESGERALDASHBOARD,
    ENVIATOTALAVALIACOESGERAL, ENVIAAVALIACAO1ESTRELA, ENVIAAVALIACAO2ESTRELA, ENVIAAVALIACAO3ESTRELA, ENVIAAVALIACAO4ESTRELA, ENVIAAVALIACAO5ESTRELA,
    ENVIATOTALAVALIACOES, ENVIAPORCENTAGEM1ESTRELA, ENVIAPORCENTAGEM2ESTRELA, ENVIAPORCENTAGEM3ESTRELA, ENVIAPORCENTAGEM4ESTRELA, ENVIAPORCENTAGEM5ESTRELA,
    ENVIANSOGERAL, ENVIANOTAMEDIAGERAL, ATIVADESATIVALOADINGSECTIONEDATENDENTERELATORIOATENDENTE, ENVIATENDENTEPARASECTIONEDRELATORIOATENDENTE,
    MODIFICAATENDENTESELECIONADOSECTIONEDRELATORIOAVALIACAO, ATIVADESATIVALOADINGTELARELATORIOAVALIACAOATENDENTE,
    ENVIAPERIODOPERSONALIZADOAVALIACAOATENDENTE, ENVIAAVALIACAO1ESTRELAATENDENTE, ENVIAAVALIACAO2ESTRELAATENDENTE, ENVIAAVALIACAO3ESTRELAATENDENTE, ENVIAAVALIACAO4ESTRELAATENDENTE, ENVIAAVALIACAO5ESTRELAATENDENTE,
    ENVIATOTALAVALIACOESATENDENTE, ENVIAPORCENTAGEM1ESTRELAATENDENTE, ENVIAPORCENTAGEM2ESTRELAATENDENTE, ENVIAPORCENTAGEM3ESTRELAATENDENTE, ENVIAPORCENTAGEM4ESTRELAATENDENTE,
    ENVIAPORCENTAGEM5ESTRELAATENDENTE, ENVIANSOGERALATENDENTE, ENVIANOTAMEDIAGERALATENDENTE, MODIFICADATAINICIALAVALIACAOATENDENTE,
    MODIFICADATAFINALAVALIACAOATENDENTE, MENSAGEMERRORAPLICAPERIODOPERSONALIZADOATENDENTE, ATIVADESATIVALOADINGTELATODASAVALIACOES,
    ATIVADESATIVALOADINGREFRESHCONTROLTODASAVALIACOES, ENVIAAVALIACOESTELATODASAVALIACOS, ENVIAULTIMAREFERENCIAKEYAVALIACAO,
    ATIVADESATIVALOADINGFINALPAGINATODASAVALIACOES, VERIFICACONEXAOINTERNETELATODASAVALIACOES, ENVIADETALHESAVALIACAOTELADETALHESAVALIACAO,
    ENVIAATENDENTEPARAREDUCERTODASAVALIACOES, ENVIACLIENTEPARAREDUCERTODASAVALIACOES, ATIVADESATIVALOADINGENVIAATENDENTETELAAVALIACAOTODAS,
    ATIVADESATIVALOADINGENVIACLIENTETELAAVALIACAOTODAS, MODIFICACLIENTESELECIONADOTELAVALIACAOTODAS, MODIFICAATENDENTESELECIONADOTELAVALIACAOTODAS,
    MODIFICAVALORAVALIACAOSELECIONADOTELAVALIACAOTODAS, ENVIAULTIMAKEYREFERENCIAKEYCLIENTETODASAVALIACOES, ENVIAULTIMAKEYREFERENCIAKEYATENDENTETODASAVALIACOES,
    ENVIAULTIMAKEYREFERENCIAKEYVALORAVALIACAOTODASAVALIACOES, MODIFICAKEYAVALIACAO, MODIFICAFILTROAVALIACAO,
    MODIFICAFILTRORELATORIOAVALIACAOGERAL, ENVIAIMAGEMPERFILCLIENTETELADETALHESAVALIACAO, MODIFICAFILTROAVALIACAOWEB
} from '../actions/types';


const INITIAL_STATE = {
    periodoPesquisaAvaliacaoGeral: '',
    dataInicialPesquisaAvaliacaoGeral: null,
    dataFinalPesquisaAvaliacaoGeral: null,
    mensagemErrorPesquisarAvaliacao: '',
    loadingRelatorioAvaliacaoGeral: true,
    avalicoes: {},
    totalAvaliacoesNoPeriodo: 0,
    totalAvaliacoes: 0,
    totalAtendimento5estrelas: 0,
    totalAtendimento4estrelas: 0,
    totalAtendimento3estrelas: 0,
    totalAtendimento2estrelas: 0,
    totalAtendimento1estrelas: 0,

    totalPorcentagemAtendimento5estrelas: 0.00,
    totalPorcentagemAtendimento4estrelas: 0.00,
    totalPorcentagemAtendimento3estrelas: 0.00,
    totalPorcentagemAtendimento2estrelas: 0.00,
    totalPorcentagemAtendimento1estrelas: 0.00,

    notaMedia: 0.00,
    notaMediaNSO: 0.00,



    totalAvaliacoesAtendente: 0,
    totalAtendimento5estrelasAtendente: 0,
    totalAtendimento4estrelasAtendente: 0,
    totalAtendimento3estrelasAtendente: 0,
    totalAtendimento2estrelasAtendente: 0,
    totalAtendimento1estrelasAtendente: 0,

    totalPorcentagemAtendimento5estrelasAtendente: 0.00,
    totalPorcentagemAtendimento4estrelasAtendente: 0.00,
    totalPorcentagemAtendimento3estrelasAtendente: 0.00,
    totalPorcentagemAtendimento2estrelasAtendente: 0.00,
    totalPorcentagemAtendimento1estrelasAtendente: 0.00,

    notaMediaAtendente: 0.00,
    notaMediaNSOAtendente: 0.00,

    periodoPesquisaAvaliacaoAtendente: '',
    dataInicialPesquisaAvaliacaoAtendente: null,
    dataFinalPesquisaAvaliacaoAtendente: null,
    mensagemErrorPesquisarAvaliacaoAtendente: '',
    loadingRelatorioAvaliacaoAtendente: false,
    atendentesSelecionadoConsulta: [],
    atendentesSectionedConsulta: [],
    loadingSectionedConsultaAtendente: false,
    arrayTodasAvaliacoes: [],
    verificaConexaoInterenetTodasAvaliacoes: '',
    loadingTelaTodasAvaliacoes: true,
    refreshControlFlatListTodasAvaliacoes: false,
    referenciaUltimaKeyTodasAvaliacoes: '',
    loadingFimDaPaginaTodasAvaliacoes: false,
    detalhesAvaliacao: {
        alterado: 0,
        atendente: "",
        chaveUnica: "",
        cliente: "",
        comentarioAvaliacao: "",
        dataHoraAbertura: "",
        dataVencimento: "",
        descricaoProblema: "",
        empresa: "",
        key: "",
        keyAtendente: "",
        keyCliente: "",
        keySetor: "",
        local: "",
        numeroChamado: 0,
        prioridade: "",
        setor: "",
        status: "",
        valorAvaliacao: 0,
        solucaoAtendimento:'',
    },
    clientesTodasAvaliacoes: [],
    clienteSelecionadoTodasAvaliacoes: [],
    atendenteTodasAvaliacoes: [],
    atendenteSelecionadoTodasAvaliacoes: [],
    valorAvaliacaoTodasAvaliacoes: [
        {
            name: "Valor da Avaliação",
            type: 'group',
            items: [
                { value: "1", name: "Ruim (1 estrela)" },
                { value: "2", name: "Regular (2 estrelas)" },
                { value: "3", name: "Bom (3 estrelas)" },
                { value: "4", name: "Ótimo (4 estrelas)" },
                { value: "5", name: "Excelente (5 estrelas)" },
            ]
        }
    ],
    valorAvaliacaoSelecionadoTodasAvaliacoes: [],
    loadingListaAtendenteAvaliacaoTodas: false,
    loadingListaClientesAvaliacaoTodas: false,
    ultimaKeyReferenciaClienteTodasAvaliacoes: '',
    ultimaKeyReferenciaAtendenteTodasAvaliacoes: '',
    ultimaKeyReferenciaValorAvaliacaoTodasAvaliacoes: '',
    keyAvaliacao: '',
    filtroAvaliacao: '',
    avaliacoesFiltro: [],
    filtroSelecionadoRelatorioAvaliacaoGeral: '',
    imagemClienteTelaDetalhesAvaliacao: null
};
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MODIFICADATAINICIALAVALIACAOGERAL:
            return { ...state, dataInicialPesquisaAvaliacaoGeral: action.payload };
        case MODIFICADATAFINALAVALIACAOGERAL:
            return { ...state, dataFinalPesquisaAvaliacaoGeral: action.payload };
        case ENVIAPERIODOPERSONALIZADOAVALIACAOGERAL:
            return { ...state, periodoPesquisaAvaliacaoGeral: action.payload };
        case ATIVADESATIVALOADINGTELARELATORIOAVALIACAOGERAL:
            return { ...state, loadingRelatorioAvaliacaoGeral: action.payload };
        case MENSAGEMERRORAPLICAPERIODOPERSONALIZADO:
            return { ...state, mensagemErrorPesquisarAvaliacao: action.payload };
        case ENVIAAVALIACOESGERALDASHBOARD:
            return { ...state, avalicoes: action.payload };
        case ENVIATOTALAVALIACOESGERAL:
            return { ...state, totalAvaliacoesNoPeriodo: action.payload };
        case ENVIAAVALIACAO1ESTRELA:
            return { ...state, totalAtendimento1estrelas: action.payload };
        case ENVIAAVALIACAO2ESTRELA:
            return { ...state, totalAtendimento2estrelas: action.payload };
        case ENVIAAVALIACAO3ESTRELA:
            return { ...state, totalAtendimento3estrelas: action.payload };
        case ENVIAAVALIACAO4ESTRELA:
            return { ...state, totalAtendimento4estrelas: action.payload };
        case ENVIAAVALIACAO5ESTRELA:
            return { ...state, totalAtendimento5estrelas: action.payload };
        case ENVIATOTALAVALIACOES:
            return { ...state, totalAvaliacoes: action.payload };
        case ENVIAPORCENTAGEM1ESTRELA:
            return { ...state, totalPorcentagemAtendimento1estrelas: action.payload };
        case ENVIAPORCENTAGEM2ESTRELA:
            return { ...state, totalPorcentagemAtendimento2estrelas: action.payload };
        case ENVIAPORCENTAGEM3ESTRELA:
            return { ...state, totalPorcentagemAtendimento3estrelas: action.payload };
        case ENVIAPORCENTAGEM4ESTRELA:
            return { ...state, totalPorcentagemAtendimento4estrelas: action.payload };
        case ENVIAPORCENTAGEM5ESTRELA:
            return { ...state, totalPorcentagemAtendimento5estrelas: action.payload };
        case ENVIANSOGERAL:
            return { ...state, notaMediaNSO: action.payload };
        case ENVIANOTAMEDIAGERAL:
            return { ...state, notaMedia: action.payload };
        case ATIVADESATIVALOADINGSECTIONEDATENDENTERELATORIOATENDENTE:
            return { ...state, loadingSectionedConsultaAtendente: action.payload };
        case ENVIATENDENTEPARASECTIONEDRELATORIOATENDENTE:
            return { ...state, atendentesSectionedConsulta:  action.payload };
        case MODIFICAATENDENTESELECIONADOSECTIONEDRELATORIOAVALIACAO:
            return { ...state, atendentesSelecionadoConsulta: action.payload };
        case ATIVADESATIVALOADINGTELARELATORIOAVALIACAOATENDENTE:
            return { ...state, loadingRelatorioAvaliacaoAtendente: action.payload };
        case ENVIAPERIODOPERSONALIZADOAVALIACAOATENDENTE:
            return { ...state, periodoPesquisaAvaliacaoAtendente: action.payload };


        case ENVIAAVALIACAO1ESTRELAATENDENTE:
            return { ...state, totalAtendimento1estrelasAtendente: action.payload };
        case ENVIAAVALIACAO2ESTRELAATENDENTE:
            return { ...state, totalAtendimento2estrelasAtendente: action.payload };
        case ENVIAAVALIACAO3ESTRELAATENDENTE:
            return { ...state, totalAtendimento3estrelasAtendente: action.payload };
        case ENVIAAVALIACAO4ESTRELAATENDENTE:
            return { ...state, totalAtendimento4estrelasAtendente: action.payload };
        case ENVIAAVALIACAO5ESTRELAATENDENTE:
            return { ...state, totalAtendimento5estrelasAtendente: action.payload };
        case ENVIATOTALAVALIACOESATENDENTE:
            return { ...state, totalAvaliacoesAtendente: action.payload };


        case ENVIAPORCENTAGEM1ESTRELAATENDENTE:
            return { ...state, totalPorcentagemAtendimento1estrelasAtendente: action.payload };
        case ENVIAPORCENTAGEM2ESTRELAATENDENTE:
            return { ...state, totalPorcentagemAtendimento2estrelasAtendente: action.payload };
        case ENVIAPORCENTAGEM3ESTRELAATENDENTE:
            return { ...state, totalPorcentagemAtendimento3estrelasAtendente: action.payload };
        case ENVIAPORCENTAGEM4ESTRELAATENDENTE:
            return { ...state, totalPorcentagemAtendimento4estrelasAtendente: action.payload };
        case ENVIAPORCENTAGEM5ESTRELAATENDENTE:
            return { ...state, totalPorcentagemAtendimento5estrelasAtendente: action.payload };
        case ENVIANSOGERALATENDENTE:
            return { ...state, notaMediaNSOAtendente: action.payload };
        case ENVIANOTAMEDIAGERALATENDENTE:
            return { ...state, notaMediaAtendente: action.payload };
        case MODIFICADATAINICIALAVALIACAOATENDENTE:
            return { ...state, dataInicialPesquisaAvaliacaoAtendente: action.payload };
        case MODIFICADATAFINALAVALIACAOATENDENTE:
            return { ...state, dataFinalPesquisaAvaliacaoAtendente: action.payload };
        case MENSAGEMERRORAPLICAPERIODOPERSONALIZADOATENDENTE:
            return { ...state, mensagemErrorPesquisarAvaliacaoAtendente: action.payload };
        case ATIVADESATIVALOADINGTELATODASAVALIACOES:
            return { ...state, loadingTelaTodasAvaliacoes: action.payload };
        case ATIVADESATIVALOADINGREFRESHCONTROLTODASAVALIACOES:
            return { ...state, refreshControlFlatListTodasAvaliacoes: action.payload };
        case ENVIAAVALIACOESTELATODASAVALIACOS:
            return { ...state, arrayTodasAvaliacoes: action.payload };
        case ENVIAULTIMAREFERENCIAKEYAVALIACAO:
            return { ...state, referenciaUltimaKeyTodasAvaliacoes: action.payload };
        case ATIVADESATIVALOADINGFINALPAGINATODASAVALIACOES:
            return { ...state, loadingFimDaPaginaTodasAvaliacoes: action.payload };
        case VERIFICACONEXAOINTERNETELATODASAVALIACOES:
            return { ...state, verificaConexaoInterenetTodasAvaliacoes: action.payload };
        case ENVIADETALHESAVALIACAOTELADETALHESAVALIACAO:
            return { ...state, detalhesAvaliacao: action.payload };
        case ENVIAATENDENTEPARAREDUCERTODASAVALIACOES:
            return { ...state, atendenteTodasAvaliacoes:  action.payload };
        case ENVIACLIENTEPARAREDUCERTODASAVALIACOES:
            return { ...state, clientesTodasAvaliacoes: action.payload };
        case ATIVADESATIVALOADINGENVIAATENDENTETELAAVALIACAOTODAS:
            return { ...state, loadingListaAtendenteAvaliacaoTodas: action.payload };
        case ATIVADESATIVALOADINGENVIACLIENTETELAAVALIACAOTODAS:
            return { ...state, loadingListaClientesAvaliacaoTodas: action.payload };
        case MODIFICACLIENTESELECIONADOTELAVALIACAOTODAS:
            return { ...state, clienteSelecionadoTodasAvaliacoes: action.payload };
        case MODIFICAATENDENTESELECIONADOTELAVALIACAOTODAS:
            return { ...state, atendenteSelecionadoTodasAvaliacoes: action.payload };
        case MODIFICAVALORAVALIACAOSELECIONADOTELAVALIACAOTODAS:
            return { ...state, valorAvaliacaoSelecionadoTodasAvaliacoes: action.payload };
        case ENVIAULTIMAKEYREFERENCIAKEYCLIENTETODASAVALIACOES:
            return { ...state, ultimaKeyReferenciaClienteTodasAvaliacoes: action.payload };
        case ENVIAULTIMAKEYREFERENCIAKEYATENDENTETODASAVALIACOES:
            return { ...state, ultimaKeyReferenciaAtendenteTodasAvaliacoes: action.payload };
        case ENVIAULTIMAKEYREFERENCIAKEYVALORAVALIACAOTODASAVALIACOES:
            return { ...state, ultimaKeyReferenciaValorAvaliacaoTodasAvaliacoes: action.payload };
        case MODIFICAKEYAVALIACAO:
            return { ...state, keyAvaliacao: action.payload };
        case MODIFICAFILTROAVALIACAO:
            return { ...state, filtroAvaliacao: action.payload };
        case MODIFICAFILTRORELATORIOAVALIACAOGERAL:
            return { ...state, filtroSelecionadoRelatorioAvaliacaoGeral: action.payload };
        case ENVIAIMAGEMPERFILCLIENTETELADETALHESAVALIACAO:
            return { ...state, imagemClienteTelaDetalhesAvaliacao: action.payload };
        case MODIFICAFILTROAVALIACAOWEB:
            return { ...state, avaliacoesFiltro: action.payload };
        default:
            return state;
    }
};