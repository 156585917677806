import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { toastr } from 'react-redux-toastr';
import { ResponsivePie } from '@nivo/pie';
import moment from 'moment';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController, isSameDay } from 'react-dates';
import SelectSearch from 'react-select-search';
import { modificaNomeRotaNoHeader } from '../../actions/HeaderAction';
import { Modal } from 'react-responsive-modal';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import {
    modificaDataInicialFinalRelatorioAvaliacaoGeral, modificaFiltroRelatorioAvaliacaoGeral,
    listaAvaliacoesGeralPeriodoPersonalizado,
    listaAvaliacoesAtendentePeriodoPersonalizado, modificaAtendenteSelecionadoSectionedRelatorioAvaliacao
} from '../../actions/AvaliacaoAction';
import { desligaOuvintesChamadosFirebase } from "../../actions/DashBoardAction";
import {listaAtendentesTelaConsultaAtendente} from  "../../actions/AtendenteAction";

import {
    Title, Container, ContainerBox, Box, TextStatus, TextResultado, ContainerGrafico, SubContainerGrafico,
    ContainerTextGrafico, TextGrafico, ContainerAviso, TextAviso, SubContainerListaDeTarefas, TextTarefas,
    SubContainerInputEmail, InputEmail, DivContainerSelectDate, BotaoPesquisarChamadoPorNumero,
    ContainerGraficoTempoMedio, TextRodaPe, SubContainerGraficoTempoMedioSemSombra,
    ContainerTextTempoMedio, DivTotaisCabecalho40, TextAvaliacao2, DivTotaisCabecalhoIcon,
    TextTotalAvaliacao, DivTextPeriodo, TextPeriodo, ContainerInputSearch, IconeSair, DivPeriodoBotao,
    DivTotaisCabecalho, ContainerPrincipalAvaliacao, TextTotalAvaliacao2, TextNovoAtendimento, ContainerExplicacaoVincular, TextVincular,
    IconeFechar,
    InputSearch, DivInputSearch, IconeSetaPraBaixo,

    ContainerAvaliacoes, CabecalhoAvaliacao, DadosAvaliacao, TotalAvaliacao,
    DivTotaisRodape, TextAvaliacao, DivTotaisCabecalho1,

    ContainerAvaliacoesLoading, TextGraficoLoading, CabecalhoAvaliacaoLoading, TotalAvaliacaoLoading,
    DadosAvaliacaoLoading
} from './styled';

class RelatorioAvaliacaoGeral extends React.Component {
    state = {
        startDate: null,
        endDate: null,
        focusedInput: null,
        data: [
            {
                id: "Baixa",
                label: "Baixa",
                value: 50,
                color: "hsl(195, 100%, 50%)"
            },
            {
                id: "Normal",
                label: "Normal",
                value: 61,
                color: "hsl(177, 100%, 41%)"
            },
            {
                id: "Alta",
                label: "Alta",
                value: 470,
                color: "hsl(42, 94%, 60%)"
            },
            {
                id: "Urgente",
                label: "Urgente",
                value: 148,
                color: "hsl(344, 94%, 51%)"
            },

        ],
        data2: [
            {
                id: "< 1 dia", label: "< 1 dia", value: 50, color: "hsl(150, 100%, 50%)"
            },
            {
                id: "1 dia", label: "1 dia", value: 96, color: "hsl(0, 0%, 83%)"
            },
            {
                id: "2 dias", label: "2 dias", value: 470, color: "hsl(42, 94%, 60%)"
            },
            {
                id: "3 dias", label: " 3dias", value: 148, color: "hsl(344, 94%, 51%)"
            },
            {
                id: "4 dias", label: " 4 dias", value: 148, color: "hsl(214, 41%, 78%)"
            },
            {
                id: "5 dias", label: " 5 dias", value: 148, color: "hsl(195, 100%, 50%)"
            },
            {
                id: "6 dias", label: " 6 dias", value: 148, color: "hsl(180, 100%, 50%)"
            },
            {
                id: "7 dias", label: " 7 dias", value: 148, color: "hsl(25, 75%, 47%)"
            },
            {
                id: "> 7 dias", label: "> 7 dias", value: 148, color: "hsl(180, 100%, 27%)"
            },

        ],
        data3: [
            {
                id: "Interno", label: "Interno", value: 50, color: "hsl(195, 100%, 50%)"
            },
            {
                id: "Externo", label: "Externo", value: 96, color: "hsl(214, 41%, 78%)"
            },
        ],
        aviso: [],
        tarefas: [
            { id: 1, tarefa: 'Aprender git hub' },
            { id: 2, tarefa: 'Instalar windows na maquina' }]
    }

    componentDidMount() {
        if (this.props.premium == 0) {
            toastr.error('Recurso Premium', 'Este é um recurso PREMIUM, Assine a versão PREMIUM e libere esse e mais recursos!');
            this.props.history.push('/dashboard')

        } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
            toastr.error('Plano imcompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
            this.props.history.push('/dashboard')

        } else {
            if (this.props.permissoesAcessarTelasAtendente.permissaoAcessoRelatorios == true) {
                moment.locale('pt-br', {
                    months: 'Janeiro_Fevereiro_Março_Abril_Maio_Junho_Julho_Agosto_Setembro_Outubro_Novembro_Dezembro'.split('_')
                })
                this.props.listaAvaliacoesGeralPeriodoPersonalizado(this.props.dataInicialPesquisaAvaliacaoGeral, this.props.dataFinalPesquisaAvaliacaoGeral, 1);
                //this.props.listaAtendentesRelatorioAvaliacaoTodas();
                this.props.listaAtendentesTelaConsultaAtendente()
                if (this.props.nomeRota !== 'Relatório Avaliação Geral') {
                    this.props.modificaNomeRotaNoHeader('Relatório Avaliação Geral')
                }
            } else {
                toastr.error('Acesso Negado', 'Você não tem permissão para acessar esta tela');
                this.props.history.push('/dashboard')
            }
        }

    }
    componentWillUnmount() {
        this.props.desligaOuvintesChamadosFirebase('relatorioavaliacaoatendente', '', '', '');
    }
    removeFiltrosAll() {
        this.props.modificaFiltroRelatorioAvaliacaoGeral('');
        this.props.listaAvaliacoesGeralPeriodoPersonalizado(this.props.dataInicialPesquisaAvaliacaoGeral, this.props.dataFinalPesquisaAvaliacaoGeral, 0);
    }
    handleInputEmailBlur() {
        this.props.modificaCorBordaListaTarefas(this.props.corBordaDinamica);

    }
    handleInputEmailFocus() {
        this.props.modificaCorBordaListaTarefas(this.props.corBotaoPadrao);
    }
    handleWeekDays = (day) => {
        //Change week day with custom day array
        day._locale._weekdaysMin = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'];
        // return the actual dates value(like 1,2,3 ...) from the moment object.
        return (day.format('D'));
    }
    insereDataInicialFinal = ({ dataInicial, dataFinal }) => {
        // 
        //
        if (dataInicial !== null) {
            let dataInicio = moment(dataInicial, 'MM/DD/YYYY').format('DD/MM/YYYY');
            let state = this.state;
            state.startDate = dataInicio;
            this.setState({ state })
        }
        if (dataFinal !== null) {
            let dataFim = moment(dataFinal, 'MM/DD/YYYY').format('DD/MM/YYYY');
            let state = this.state;
            state.endDate = dataFim;
            this.setState({ state })
        }

    }
    renderLoadingAvaliacaoGeral() {
        if (this.props.loadingRelatorioAvaliacaoGeral) {
            return (
                <Container backgroundDinamico={this.props.backgroundPrimeiro}>


                    <ContainerPrincipalAvaliacao>
                        <ContainerAvaliacoesLoading backgroundinamico={this.props.backgroundSegundo}>
                            <CabecalhoAvaliacaoLoading backgroundDinamico={this.props.backgroundMaisEscuro}>
                                <TextGraficoLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextGraficoLoading>
                            </CabecalhoAvaliacaoLoading>
                            <DadosAvaliacaoLoading backgroundDinamico={this.props.backgroundSegundo}>
                                <TextGraficoLoading backgroundinamico={this.props.backgroundSegundo}></TextGraficoLoading>
                                <TextGraficoLoading backgroundinamico={this.props.backgroundSegundo}></TextGraficoLoading>
                                <TextGraficoLoading backgroundinamico={this.props.backgroundSegundo}></TextGraficoLoading>
                                <TextGraficoLoading backgroundinamico={this.props.backgroundSegundo}></TextGraficoLoading>
                                <TextGraficoLoading backgroundinamico={this.props.backgroundSegundo}></TextGraficoLoading>
                            </DadosAvaliacaoLoading>
                            <TotalAvaliacaoLoading backgroundDinamico={this.props.backgroundMaisEscuro}>
                                <TextGraficoLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextGraficoLoading>
                                <TextGraficoLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextGraficoLoading>
                            </TotalAvaliacaoLoading>
                        </ContainerAvaliacoesLoading>

                    </ContainerPrincipalAvaliacao>
                </Container >
            )
        } else {
            return (
                <Container backgroundDinamico={this.props.backgroundPrimeiro}>

                    <DivContainerSelectDate>
                        <div style={{}}>
                            <DivTextPeriodo>
                                <i className="icon-calendarweb" style={{ color: this.props.coresLetraPadrao, fontSize: 15, marginRight: 3 }} />
                                <TextPeriodo corLetraDinamica={this.props.coresLetraPadrao}>Período:</TextPeriodo>
                            </DivTextPeriodo>
                            <DivPeriodoBotao >
                                <DateRangePicker
                                    // customInputIcon={<i className="icon-calendar" style={{ color: '#000', marginRight: 5 }} />}
                                    //   customArrowIcon={<Icon icon="next" size="xxs" />}
                                    // customCloseIcon={<i className="icon-close" style={{ color: '#000', marginRight: 5 }} />}
                                    displayFormat={() => "DD/MM/YYYY"}
                                    small
                                    isOutsideRange={() => false}
                                    minimumNights={0}
                                    firstDayOfWeek={1}
                                    renderDayContents={this.handleWeekDays}
                                    isDayHighlighted={day => isSameDay(moment(new Date()), moment(day))}
                                    hideKeyboardShortcutsPanel
                                    startDatePlaceholderText="Data Inicial"
                                    endDatePlaceholderText="Data Final"
                                    startDate={this.props.dataInicialPesquisaAvaliacaoGeral} // momentPropTypes.momentObj or null,
                                    startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                                    endDate={this.props.dataFinalPesquisaAvaliacaoGeral} // momentPropTypes.momentObj or null,
                                    endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                                    //onDatesChange={this.insereDataInicialFinal} // PropTypes.func.isRequired,
                                    onDatesChange={({ startDate, endDate }) => this.props.modificaDataInicialFinalRelatorioAvaliacaoGeral(startDate, endDate)}
                                    focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                    onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                                />
                                {this.props.dataInicialPesquisaAvaliacaoGeral !== null && this.props.dataFinalPesquisaAvaliacaoGeral !== null && <BotaoPesquisarChamadoPorNumero onClick={() => {
                                    if (this.props.filtroSelecionadoRelatorioAvaliacaoGeral == 'atendentes' && this.props.atendentesSelecionadoConsulta.length > 0) {
                                        this.props.listaAvaliacoesAtendentePeriodoPersonalizado(this.props.dataInicialPesquisaAvaliacaoGeral, this.props.dataFinalPesquisaAvaliacaoGeral, 0, this.props.atendentesSelecionadoConsulta);
                                    } else {
                                        this.props.listaAvaliacoesGeralPeriodoPersonalizado(this.props.dataInicialPesquisaAvaliacaoGeral, this.props.dataFinalPesquisaAvaliacaoGeral, 0);
                                    }

                                }} backGroundDinamico={this.props.corBotaoPadrao} type="button">
                                    <i className="icon-magnifier" style={{ color: this.props.letraBotaoPadrao, fontSize: 15 }} />
                                </BotaoPesquisarChamadoPorNumero>}
                            </DivPeriodoBotao>
                        </div>


                        {this.props.filtroSelecionadoRelatorioAvaliacaoGeral == 'atendentes' && <div style={{ marginTop: 18 }}>
                            <TextPeriodo style={{ marginLeft: 40 }} corLetraDinamica={this.props.coresLetraPadrao}>Atendente:</TextPeriodo>
                            <ContainerInputSearch backGroundDinamico={this.props.backgroundMaisEscuro}>
                                <IconeSair className="icon-close" onClick={() => { this.removeFiltrosAll(); }} />
                                <div style={{ background: this.props.corSegundoBotaoPadrao, borderRadius: 3, height: 30 }}>
                                    <SelectSearch
                                        renderValue={(valueProps) =>
                                            <DivInputSearch>
                                                <InputSearch
                                                    corLetraDinamico={this.props.coresLetraPadrao}
                                                    backgroundDinamico={this.props.backgroundMaisEscuro}
                                                    placeholderColor={this.props.corLetraSecundaria}
                                                    corBordaDinamica={this.props.corBotaoPadrao}
                                                    {...valueProps} ></InputSearch>
                                                <IconeSetaPraBaixo className="icon-angle-arrow-down" colorDinamico={this.props.coresLetraPadrao} />
                                            </DivInputSearch>
                                        }
                                        value={this.props.atendentesSelecionadoConsulta}
                                        onChange={(value) => {
                                            this.props.modificaAtendenteSelecionadoSectionedRelatorioAvaliacao(value);
                                            this.props.listaAvaliacoesAtendentePeriodoPersonalizado(this.props.dataInicialPesquisaAvaliacaoGeral, this.props.dataFinalPesquisaAvaliacaoGeral, 0, value);
                                        }}
                                        options={this.props.atendentesSectionedConsulta}
                                        placeholder={this.props.atendentesSectionedConsulta.length == 0 ? `Nenhum Atendente cadastrado, Por favor verifique!` : 'Selecione o Atendente'}
                                        search={this.props.atendentesSectionedConsulta.length == 0 ? false : true}
                                    />
                                </div>
                            </ContainerInputSearch>
                        </div>}

                    </DivContainerSelectDate>
                    <ContainerPrincipalAvaliacao>
                        <ContainerAvaliacoes>
                            <CabecalhoAvaliacao backgroundDinamico={this.props.backgroundMaisEscuro}>
                                <DivTotaisCabecalho1>
                                    <TextAvaliacao colorDinamico={this.props.corLetraDescricaoDosCampos}></TextAvaliacao>
                                    <TextTotalAvaliacao colorDinamico={this.props.corLetraDescricaoDosCampos}>Total</TextTotalAvaliacao>
                                    <TextAvaliacao colorDinamico={this.props.corLetraDescricaoDosCampos}>{this.props.totalAvaliacoes}</TextAvaliacao>
                                    <TextAvaliacao colorDinamico={this.props.corLetraDescricaoDosCampos}>100,00%</TextAvaliacao>
                                </DivTotaisCabecalho1>
                            </CabecalhoAvaliacao>
                            <DadosAvaliacao backgroundDinamico={this.props.backgroundSegundo}>
                                <DivTotaisCabecalhoIcon>
                                    <i className="icon-in-love" style={{ color: "#00FF7F", fontSize: 22, marginRight: 3 }} />
                                    <i className="icon-good-mood-emoticon" style={{ color: "#98FB98", fontSize: 22, marginRight: 3 }} />
                                    <i className="icon-big-smiley-face" style={{ color: "#F9BF3B", fontSize: 22, marginRight: 3 }} />
                                    <i className="icon-sceptic" style={{ color: "#E67E22", fontSize: 22, marginRight: 3 }} />
                                    <i className="icon-sad" style={{ color: "red", fontSize: 22, marginRight: 3 }} />

                                </DivTotaisCabecalhoIcon>
                                <DivTotaisCabecalho40>
                                    <TextTotalAvaliacao2 colorDinamico="#00FF7F" >Excelente</TextTotalAvaliacao2>
                                    <TextTotalAvaliacao2 colorDinamico="#98FB98" >Ótimo    </TextTotalAvaliacao2>
                                    <TextTotalAvaliacao2 colorDinamico="#F9BF3B" >Bom      </TextTotalAvaliacao2>
                                    <TextTotalAvaliacao2 colorDinamico="#E67E22" >Regular  </TextTotalAvaliacao2>
                                    <TextTotalAvaliacao2 colorDinamico="red">Ruim     </TextTotalAvaliacao2>
                                </DivTotaisCabecalho40>

                                <DivTotaisCabecalho>
                                    <TextAvaliacao2 colorDinamico="#00FF7F" >{this.props.totalAtendimento5estrelas}</TextAvaliacao2>
                                    <TextAvaliacao2 colorDinamico="#98FB98" >{this.props.totalAtendimento4estrelas}</TextAvaliacao2>
                                    <TextAvaliacao2 colorDinamico="#F9BF3B">{this.props.totalAtendimento3estrelas}</TextAvaliacao2>
                                    <TextAvaliacao2 colorDinamico="#E67E22" >{this.props.totalAtendimento2estrelas}</TextAvaliacao2>
                                    <TextAvaliacao2 colorDinamico="red" >{this.props.totalAtendimento1estrelas}</TextAvaliacao2>
                                </DivTotaisCabecalho>

                                <DivTotaisCabecalho>
                                    <TextAvaliacao2 colorDinamico="#00FF7F" >{this.props.totalPorcentagemAtendimento5estrelas}%</TextAvaliacao2>
                                    <TextAvaliacao2 colorDinamico="#98FB98" >{this.props.totalPorcentagemAtendimento4estrelas}%</TextAvaliacao2>
                                    <TextAvaliacao2 colorDinamico="#F9BF3B">{this.props.totalPorcentagemAtendimento3estrelas}%</TextAvaliacao2>
                                    <TextAvaliacao2 colorDinamico="#E67E22" >{this.props.totalPorcentagemAtendimento2estrelas}%</TextAvaliacao2>
                                    <TextAvaliacao2 colorDinamico="red" >{this.props.totalPorcentagemAtendimento1estrelas}%</TextAvaliacao2>


                                </DivTotaisCabecalho>
                            </DadosAvaliacao>
                            <TotalAvaliacao backgroundDinamico={this.props.backgroundMaisEscuro}>
                                <DivTotaisRodape>
                                    <TextRodaPe colorDinamico={this.props.corLetraDescricaoDosCampos}>NOTA MÉDIA (Escala 1 a 5)</TextRodaPe>
                                    <TextRodaPe colorDinamico={this.props.corLetraDescricaoDosCampos}>NSO (Escala de -2 a +2)</TextRodaPe>

                                </DivTotaisRodape>
                                <DivTotaisRodape>
                                    <TextRodaPe colorDinamico={this.props.corLetraDescricaoDosCampos}>{this.props.notaMedia}</TextRodaPe>
                                    <TextRodaPe colorDinamico={this.props.corLetraDescricaoDosCampos}>{this.props.notaMediaNSO}</TextRodaPe>

                                </DivTotaisRodape>
                            </TotalAvaliacao >
                        </ContainerAvaliacoes>

                    </ContainerPrincipalAvaliacao>
                </Container >
            )
        }
    }
    render() {

        return (
            <>
                {this.renderLoadingAvaliacaoGeral()}
            </>
        );
    }
}

const mapStateToProps = state => {
    return (
        {

            backgroundPrimeiro: state.CoresPadraoSistema.backgroundPrimeiro,
            backgroundSegundo: state.CoresPadraoSistema.backgroundSegundo,
            backgroundMaisEscuro: state.CoresPadraoSistema.backgroundMaisEscuro,
            coresLetraPadrao: state.CoresPadraoSistema.coresLetraPadrao,
            corBotaoPadrao: state.CoresPadraoSistema.corBotaoPadrao,
            corSegundoBotaoPadrao: state.CoresPadraoSistema.corSegundoBotaoPadrao,
            coresLetraSecundario: state.CoresPadraoSistema.coresLetraSecundario,
            corLetraDescricaoDosCampos: state.CoresPadraoSistema.corLetraDescricaoDosCampos,
            nomeRota: state.HeaderReducer.nomeRota,
            corBordaPadrao: state.CoresPadraoSistema.corBordaPadrao,
            corBordaDinamica: state.CoresPadraoSistema.corBordaDinamica,

            periodoPesquisaAvaliacaoGeral: state.AvaliacaoReducer.periodoPesquisaAvaliacaoGeral,
            loadingRelatorioAvaliacaoGeral: state.AvaliacaoReducer.loadingRelatorioAvaliacaoGeral,
            avalicoes: state.AvaliacaoReducer.avalicoes,
            totalAvaliacoesNoPeriodo: state.AvaliacaoReducer.totalAvaliacoesNoPeriodo,
            totalAvaliacoes: state.AvaliacaoReducer.totalAvaliacoes,


            totalAtendimento5estrelas: state.AvaliacaoReducer.totalAtendimento5estrelas,
            totalAtendimento4estrelas: state.AvaliacaoReducer.totalAtendimento4estrelas,
            totalAtendimento3estrelas: state.AvaliacaoReducer.totalAtendimento3estrelas,
            totalAtendimento2estrelas: state.AvaliacaoReducer.totalAtendimento2estrelas,
            totalAtendimento1estrelas: state.AvaliacaoReducer.totalAtendimento1estrelas,
            premium: state.PremiumReducer.premium,
            totalPorcentagemAtendimento5estrelas: state.AvaliacaoReducer.totalPorcentagemAtendimento5estrelas,
            totalPorcentagemAtendimento4estrelas: state.AvaliacaoReducer.totalPorcentagemAtendimento4estrelas,
            totalPorcentagemAtendimento3estrelas: state.AvaliacaoReducer.totalPorcentagemAtendimento3estrelas,
            totalPorcentagemAtendimento2estrelas: state.AvaliacaoReducer.totalPorcentagemAtendimento2estrelas,
            totalPorcentagemAtendimento1estrelas: state.AvaliacaoReducer.totalPorcentagemAtendimento1estrelas,
            permissoesAcessarTelasAtendente: state.AtendenteReducer.permissoesAcessarTelasAtendente,
            notaMedia: state.AvaliacaoReducer.notaMedia,
            notaMediaNSO: state.AvaliacaoReducer.notaMediaNSO,
            licensawebouapp: state.PremiumReducer.licensawebouapp,
            dataInicialPesquisaAvaliacaoGeral: state.AvaliacaoReducer.dataInicialPesquisaAvaliacaoGeral,
            dataFinalPesquisaAvaliacaoGeral: state.AvaliacaoReducer.dataFinalPesquisaAvaliacaoGeral,
            filtroSelecionadoRelatorioAvaliacaoGeral: state.AvaliacaoReducer.filtroSelecionadoRelatorioAvaliacaoGeral,
            atendentesSectionedConsulta: state.AtendenteReducer.atendentesSectionedConsulta,
            atendentesSelecionadoConsulta: state.AvaliacaoReducer.atendentesSelecionadoConsulta,
        }
    );
};
export default connect(mapStateToProps, {
    modificaDataInicialFinalRelatorioAvaliacaoGeral, modificaFiltroRelatorioAvaliacaoGeral,
    modificaNomeRotaNoHeader, listaAvaliacoesGeralPeriodoPersonalizado,
    listaAvaliacoesAtendentePeriodoPersonalizado, modificaAtendenteSelecionadoSectionedRelatorioAvaliacao,
    desligaOuvintesChamadosFirebase,listaAtendentesTelaConsultaAtendente
})(withRouter(RelatorioAvaliacaoGeral));
