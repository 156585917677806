import api from '../../http_client/http.client';


const loadOptions = async (search, idCompany, page, isMyContact, isScreenNotices, isClientLinked) => {
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept': 'application/json',
        },

        data: {search: search, idCompany, page: page, isMyContact, isClientLinked},
        url: 'searchClients',
    };

    const response = await api(options);
    const result = response.data;

    if (isScreenNotices && page === 0) {
        result.data.unshift({value: 'TodosPadrao', label: `Todos os Clientes`, empresa: "Todos"})
    }

    const parsedData = result.data.map(entry => {
        return {value: entry.idUser, label: `${entry.nome} - ${entry.empresa}`, ...entry}
    });

    return {
        options: parsedData,
        hasMore: result.hasMore,
        additional: {
            page: page + 1
        },
        //searchFromHasura: result.searchFromHasura ?? false
        searchFromHasura: false
    };
};

export default loadOptions;
