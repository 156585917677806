import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-responsive-modal';
import FlatList from 'flatlist-react';
import FlatListSetores from "./FlatListSetores";
import MoonLoader from "react-spinners/MoonLoader";
import { withRouter } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import styled from 'styled-components';
import { shade, opacify, lighten, darken } from 'polished';
import SelectSearch from 'react-select-search';
import ClipLoader from "react-spinners/ClipLoader";
import { toastr } from 'react-redux-toastr'
import { desligaOuvintesChamadosFirebase } from "../../actions/DashBoardAction";
import firebase from '../../servicos/FirebaseConnection';
import imgTeste from '../../imagens/userPadrao.png';
import excluirSetorPNG from '../../imagens/excluirSetor.png'
import backGroundStopVersaoPremium from '../../imagens/backGroundStopVersaoPremium.jpg'
import imgexcluirCliente from '../../imagens/delete-user.png';
import { modificaPesquisaTeste } from '../../actions/HeaderAction';
import {
    modificaNomeSetor, modificaObservacaoSetor, zeraMensagemSemInternetAlteraSetor, mudaVisibilidadeModalExcluirSetor,
    modificaAtendenteVinculadoSelecionado, modificaFiltroSetoresTelaSetores, modificaVisibleModalNovoSetor,
    consultaSetor2, listaAtendentesVincularSetor, modificaSetorSelecionadoSelecionadoSectioned, consultaSetorComSetor,
    listaSetorSectionedTelaConsultaSetor, modificaNomeSetorSelecionadoSectioned, alteraSetor,
    excluirSetor, cadastraSetor, modificaNomeSetorNovoCadastro, modificaObservacaoSetorNovoCadastro,
    modificaAtendenteVinculadoSelecionadoNovoCadastro, modificaPermiteClienteSelecionarSetor, modificaPermiteClienteSelecionarSetorCadastro
} from "../../actions/SetorAction";
import { modificaNomeRotaNoHeader } from '../../actions/HeaderAction';
import { modificaRetornaTelaClienteParaNovoAtendimento } from '../../actions/ChamadoAction';

import {
    ContainerDadosCliente, Container, IconeSair, ContainerInputSearch, SubContainer, SubContainerCliente,
    ContainerImagemAlterarCliente, ContainerPrincipalDadosCliente, InputEmpresa, SubContainerClientePermissao,
    ContainerPermitir, TextPermitir, ContainerDadosLoginCliente, ContainerBotaoAlterar, ButtonEntrar,
    DivIconeExcluirCliente, IconeExcluirCliente, DivContainerInputSenha, IconeMostrarSenha,
    IconeEmpresaVinculada, DivContainerInputEmpresaVinculada, ContainerModal, ContainerIconeFecharModal,
    TextNovoAtendimento, IconeFechar, ContainerImagemVincularEmpresa, ContainerExplicacaoVincular,
    ButtonVincular, TextVincular, ImagePerfil, IconeEmpresaVinculadaOff, ContainerModalNovoCliente,
    ContainerDadosNovoCliente, ContainerAtendentesSelecionados, ContainerSelectSearch,
    TextAtendentesSetor, ContainerAtendentesSetor,
    InputSearch, DivInputSearch, IconeSetaPraBaixo,

    ContainerChamado, ContainerListaVazia, ContainerDadosChamado,
    ContaineDadosChamadosPrincipal,
    ContainerIconeEFrase, TextCliente,
    SubContainerIconeEFraseClienteAtendente,
    ContainerSeta, TextEmpresa, ImageLoading, ContainerChamadoLoading, TextClienteLoading, ImagemPerfilLoading,
    InputLoadingEmpresa, ContainerDadosClienteLoading, SubContainerClientePermissaoLoading, DivConfiguracoes,
    IconeInterrogacao, ContainerPermitir2, TextPermitir2,ContainerImagemVincularEmpresaSetor
} from './styled';


class Setor extends React.Component {
    state = {
        filtroTeste: false,
        hasMoreItems: false,
        modalAtencaoSetor: false,
        modalDesvinculaCliente: false,
        offset: 0,
        fotoCliente: imgTeste,
        search: '',
        inputFicticio: '',
        checkFicticio: false,
        maisDados: [],
        tipoInputSenha: 'password',
        tipoInputConfirmaSenha: 'password',
        loading: true,
        visaoInputSenha: false,
        visaoInputConfirmaSenha: false,
        pesquisaCliente: false,
        modalNovoCliente: false,
        modalPesquisaCliente: false,
        testeEmpresas: [
            { value: 'Hera', name: 'Hera' },
            { value: 'Mr Automac', name: 'Mr Automac' },
            { value: 'Autocom', name: 'Autocom' },
            { value: '1', name: 'Hera' },
            { value: 'Mr', name: 'Mr Automac' },
            { value: '3', name: 'Autocom' },
        ],
        atendentesSelecionados: [],
        chamados:
            [
                {
                    key: '1', nome: 'Frios', observacao: ''
                },
                {
                    key: '2', nome: 'Cereais Cereais Cereais Cereais CereaisCereaisCereaisCereais', observacao: 'MatinaisMatinaisMatinaisMatinaisMatinaisMatinaisMatinaisMatinaisMatinais'
                },
                {
                    key: '3', nome: 'Congelados', observacao: 'obs'
                },
                {
                    key: '4', nome: 'Mochilas', observacao: 'yns'
                },
                {
                    key: '5', nome: 'Bebidas', observacao: 'energeticos'
                },
                /* {
                    key: '6', nome: 'Aldair Fernandes', numeroChamado: 6, empresa: 'Hera Engenharia de Software LTDA',
                    setor: 'Financeiro', descricaoProblema: 'Computador Não liga', dataHoraAbertura: '19/10/2020 12:12:01',
                    status: 'Aberto', atendente: 'João Victor', prioridade: 'Urgente'
                },
                {
                    key: '7', nome: 'Aldair Fernandes', numeroChamado: 7, empresa: 'Hera Engenharia de Software LTDA',
                    setor: 'Financeiro', descricaoProblema: 'Computador Não liga', dataHoraAbertura: '19/10/2020 12:12:01',
                    status: 'Aberto', atendente: 'João Victor', prioridade: 'Urgente'
                },
                {
                    key: '8', nome: 'Aldair Fernandes', numeroChamado: 8, empresa: 'Hera Engenharia de Software LTDA',
                    setor: 'Financeiro', descricaoProblema: 'Computador Não liga', dataHoraAbertura: '19/10/2020 12:12:01',
                    status: 'Aberto', atendente: 'João Victor', prioridade: 'Urgente'
                },
                {
                    key: '9', nome: 'Aldair Fernandes', numeroChamado: 9, empresa: 'Hera Engenharia de Software LTDA',
                    setor: 'Financeiro', descricaoProblema: 'Computador Não liga', dataHoraAbertura: '19/10/2020 12:12:01',
                    status: 'Aberto', atendente: 'João Victor', prioridade: 'Urgente'
                },
                {
                    key: '10', nome: 'Aldair Fernandes', numeroChamado: 10, empresa: 'Hera Engenharia de Software LTDA',
                    setor: 'Financeiro', descricaoProblema: 'Computador Não liga', dataHoraAbertura: '19/10/2020 12:12:01',
                    status: 'Aberto', atendente: 'João Victor', prioridade: 'Urgente'
                }, */
            ]
    }
    componentWillMount() {
        if (this.props.verificaAgrupaAtendenteSetor == true) {
            this.props.listaAtendentesVincularSetor(this.props.keySetor);
        }
    }
    componentDidMount() {
        if (this.props.permissoesAcessarTelasAtendente.permissaoAlterarCadastrarSetores == true) {
            this.props.consultaSetor2(0, '', []);

            this.props.listaSetorSectionedTelaConsultaSetor();
            ReactTooltip.rebuild();
            if (this.props.nomeRota !== 'Setores') {
                this.props.modificaNomeRotaNoHeader('Setores')
            }
        } else {
            toastr.error('Acesso Negado', 'Você não tem permissão para acessar esta tela');
            this.props.history.push('/dashboard')
        }

    }
    componentWillReceiveProps(nextProps) {
        //   ReactTooltip.rebuild();
        if (this.props.keySetor !== nextProps.keySetor) {
            ReactTooltip.rebuild();
            if (this.props.verificaAgrupaAtendenteSetor == true) {
                this.props.listaAtendentesVincularSetor(nextProps.keySetor);
            }
        }
        if (this.props.setorSelecionadosectioned !== nextProps.setorSelecionadosectioned) {
            /*  
            
            
              */
            let clienteAnterior = this.props.setorSelecionadosectioned;
            if (clienteAnterior.length > 0) {
                const chaveUnica2 = JSON.parse(localStorage.getItem('chaveUnica'));
                if (chaveUnica2) {
                    const chaveUnica = chaveUnica2.replace(/"/g, "");
                    /*  
                      */
                    firebase.database().ref(`${chaveUnica}/setores/${clienteAnterior}`).off("value");
                    /*    */
                } else {

                }
            }

        }
        setTimeout(() => {
            ReactTooltip.rebuild();
        }, 1000);
    }
    componentWillUnmount() {
        this.props.desligaOuvintesChamadosFirebase('setores', '', '', '');
    }
    _toggleMostrarSenha() {
        this.setState({ visaoInputSenha: !this.state.visaoInputSenha });
    }
    _toggleSectionedPesquisaCliente() {
        this.setState({ pesquisaCliente: !this.state.pesquisaCliente });
    }
    _toggleModalPesquisarCliente() {
        this.setState({ modalPesquisaCliente: !this.state.modalPesquisaCliente });
    }
    _toggleModalDesvincularCliente() {
        this.setState({ modalDesvinculaCliente: !this.state.modalDesvinculaCliente });
    }
    handleVisualizaSenha(value) {
        let state = this.state;
        state.tipoInputSenha = value;
        this.setState({ state });
    }
    handleVisualizaConfirmaSenha(value) {
        let state = this.state;
        state.tipoInputConfirmaSenha = value;
        this.setState({ state });
    }
    handleModalNovoCliente(value) {
        let state = this.state;
        state.modalNovoCliente = value;
        this.setState({ state });
    }
    updateSearch = search => {
        let state = this.state;
        state.search = search;
        this.setState({ state });

        // this.searchText(search)
    };
    removeFiltrosAll() {
        //this.props.ativaLoadingTelaAberturaChamadoRemoveFiltro(true)
        setTimeout(() => {
            this.props.modificaSetorSelecionadoSelecionadoSectioned('');
            this.props.modificaNomeSetorSelecionadoSectioned('');
            this.props.consultaSetor2(0, '', []);
            this.props.modificaFiltroSetoresTelaSetores('');
        }, 0);
    }
    exibeUltimosClientes() {
        if (this.props.loadingConsultaSetor == false) {
            if (this.props.nomeSetoresSelecinadoSectioned == '') {
                this.props.consultaSetor2(1, this.props.referenciaUltimaKeySetor, this.props.setores);
            }
        }
    }

    renderLoadingConsultaSetor() {
        const CheckBoxWrapper = styled.div`
        position: relative;
        margin-left:10px;
      `;
        const CheckBoxLabel = styled.label`
        position: absolute;
        top: 0;
        left: 0;
        width: 42px;
        height: 23px;
        border-radius: 15px;
        background: ${this.props.coresLetraSecundario};
        cursor: pointer;
        &::after {
          content: "";
          display: block;
          border-radius: 50%;
          width: 18px;
          height: 18px;
          margin: 3px;
          background: #ffffff;
          box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
          transition: 0.2s;
        }
      `;
        const CheckBox = styled.input`
        opacity: 0;
        z-index: 1;
        border-radius: 15px;
        width: 42px;
        height: 26px;
        &:checked + ${CheckBoxLabel} {
          background: #00FF7F;
          &::after {
            content: "";
            display: block;
            border-radius: 50%;
            width: 18px;
            height: 18px;
            margin-left: 21px;
            transition: 0.2s;
          }
        }  &:disabled + ${CheckBoxLabel}{
            background: ${darken(0.2, '#00FF7F')};
        }
      `;
        if (this.props.loadingConsultaSetor) {
            return (
                <Container>
                    <SubContainer style={{ flex: 0.5 }}>
                        <ContainerChamadoLoading backGroundDinamico={this.props.backgroundMaisEscuro}>

                            <ContainerDadosChamado>
                                <ContaineDadosChamadosPrincipal>
                                    <SubContainerIconeEFraseClienteAtendente >
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                    <SubContainerIconeEFraseClienteAtendente >
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                </ContaineDadosChamadosPrincipal>


                            </ContainerDadosChamado>


                        </ContainerChamadoLoading>
                        <ContainerChamadoLoading backGroundDinamico={this.props.backgroundMaisEscuro}>

                            <ContainerDadosChamado>
                                <ContaineDadosChamadosPrincipal>
                                    <SubContainerIconeEFraseClienteAtendente >
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                    <SubContainerIconeEFraseClienteAtendente >
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                </ContaineDadosChamadosPrincipal>


                            </ContainerDadosChamado>


                        </ContainerChamadoLoading>
                        <ContainerChamadoLoading backGroundDinamico={this.props.backgroundMaisEscuro}>

                            <ContainerDadosChamado>
                                <ContaineDadosChamadosPrincipal>
                                    <SubContainerIconeEFraseClienteAtendente >
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                    <SubContainerIconeEFraseClienteAtendente >
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                </ContaineDadosChamadosPrincipal>


                            </ContainerDadosChamado>


                        </ContainerChamadoLoading>
                        <ContainerChamadoLoading backGroundDinamico={this.props.backgroundMaisEscuro}>

                            <ContainerDadosChamado>
                                <ContaineDadosChamadosPrincipal>
                                    <SubContainerIconeEFraseClienteAtendente >
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                    <SubContainerIconeEFraseClienteAtendente >
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                </ContaineDadosChamadosPrincipal>


                            </ContainerDadosChamado>


                        </ContainerChamadoLoading>
                        <ContainerChamadoLoading backGroundDinamico={this.props.backgroundMaisEscuro}>

                            <ContainerDadosChamado>
                                <ContaineDadosChamadosPrincipal>
                                    <SubContainerIconeEFraseClienteAtendente >
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                    <SubContainerIconeEFraseClienteAtendente >
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                </ContaineDadosChamadosPrincipal>


                            </ContainerDadosChamado>


                        </ContainerChamadoLoading>
                        <ContainerChamadoLoading backGroundDinamico={this.props.backgroundMaisEscuro}>

                            <ContainerDadosChamado>
                                <ContaineDadosChamadosPrincipal>
                                    <SubContainerIconeEFraseClienteAtendente >
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                    <SubContainerIconeEFraseClienteAtendente >
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                </ContaineDadosChamadosPrincipal>


                            </ContainerDadosChamado>


                        </ContainerChamadoLoading>
                        <ContainerChamadoLoading backGroundDinamico={this.props.backgroundMaisEscuro}>

                            <ContainerDadosChamado>
                                <ContaineDadosChamadosPrincipal>
                                    <SubContainerIconeEFraseClienteAtendente >
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                    <SubContainerIconeEFraseClienteAtendente >
                                        <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                    </SubContainerIconeEFraseClienteAtendente>
                                </ContaineDadosChamadosPrincipal>


                            </ContainerDadosChamado>


                        </ContainerChamadoLoading>
                    </SubContainer>

                    <ContainerPrincipalDadosCliente backGroundDinamico={this.props.backgroundSegundo}>
                        <SubContainerCliente>

                            <ContainerDadosClienteLoading>

                                <InputLoadingEmpresa backgroundinamico={this.props.backgroundSegundo}></InputLoadingEmpresa>
                                <InputLoadingEmpresa backgroundinamico={this.props.backgroundSegundo}></InputLoadingEmpresa>

                            </ContainerDadosClienteLoading>
                        </SubContainerCliente>
                        <SubContainerClientePermissaoLoading>
                            <InputLoadingEmpresa backgroundinamico={this.props.backgroundSegundo}></InputLoadingEmpresa>
                            <InputLoadingEmpresa style={{ marginTop: 17 }} backgroundinamico={this.props.backgroundSegundo}></InputLoadingEmpresa>
                        </SubContainerClientePermissaoLoading>
                    </ContainerPrincipalDadosCliente>

                </Container>
            )
        } else {
            return (
                <Container>
                    <SubContainer style={{ flex: this.props.setores.length > 0 ? 0.5 : 1 }}>
                        {this.props.filtroSetor !== '' &&
                            <div style={{ justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                <p style={{ marginLeft: 40, color: this.props.coresLetraPadrao }}>Setores:</p>
                                <ContainerInputSearch backGroundDinamico={this.props.backgroundMaisEscuro}>
                                    <IconeSair className="icon-close" onClick={() => { this.removeFiltrosAll(); }} />
                                    <div style={{ borderRadius: 3, height: 30, flex: 1 }}>
                                        <SelectSearch
                                            renderValue={(valueProps) =>
                                                <DivInputSearch>
                                                    <InputSearch
                                                        corLetraDinamico={this.props.coresLetraPadrao}
                                                        backgroundDinamico={this.props.backgroundMaisEscuro}
                                                        placeholderColor={this.props.corLetraSecundaria}
                                                        corBordaDinamica={this.props.corBotaoPadrao}
                                                        {...valueProps} ></InputSearch>
                                                    <IconeSetaPraBaixo className="icon-angle-arrow-down" colorDinamico={this.props.coresLetraPadrao} />
                                                </DivInputSearch>
                                            }
                                            value={this.props.setorSelecionadosectioned} options={this.props.setoresSectionedConsulta} onChange={(value) => {
                                                this.props.modificaSetorSelecionadoSelecionadoSectioned(value);
                                                this.props.consultaSetorComSetor(value);
                                                this.props.desligaOuvintesChamadosFirebase('setores', '', '', '');
                                            }} placeholder={this.props.setoresSectionedConsulta.length == 0 ? `Nenhum Setor cadastrado` : "Selecione o Setor"} search={this.props.setoresSectionedConsulta.length == 0 ? false : true}
                                        />
                                    </div>
                                </ContainerInputSearch>
                            </div>}
                        <FlatList
                            list={this.props.setores}
                            renderItem={(item) => <FlatListSetores data={item} key={item.alterado + item.key} />}
                            renderWhenEmpty={() => {
                                return (
                                    <ContainerListaVazia>
                                        <i className="icon-box" style={{ color: this.props.coresLetraPadrao, fontSize: 110 }} />
                                        <p style={{ color: this.props.coresLetraPadrao, fontSize: 20 }}>Nenhum Setor Cadastrado</p>
                                    </ContainerListaVazia>
                                )

                            }}
                            hasMoreItems={this.props.loadingFimDaPaginaConsultaSetor}
                            loadMoreItems={() => this.exibeUltimosClientes()}
                            paginationLoadingIndicator={<MoonLoader
                                css={{ margin: 10 }}
                                size={40}
                                color={this.props.corBotaoPadrao}
                                loading={this.props.loadingFimDaPaginaConsultaSetor}
                            />}

                        //sortBy={["firstName", { key: "lastName", descending: true }]}
                        // groupBy={person => person.info.age > 18 ? 'Over 18' : 'Under 18'}
                        />
                    </SubContainer>
                    {this.props.modalNovoSetor == false && this.props.setores.length > 0 && <ContainerPrincipalDadosCliente backGroundDinamico={this.props.backgroundSegundo}>

                        {this.props.modalNovoSetor == false && <SubContainerCliente>

                            <ContainerDadosCliente>

                                <DivIconeExcluirCliente>
                                    <IconeExcluirCliente onClick={() => { this.props.mudaVisibilidadeModalExcluirSetor(true) }} data-tip="Excluir Setor" data-for="tip-top" className="icon-rubbish-bin" colorDinamico={this.props.coresLetraPadrao} />
                                </DivIconeExcluirCliente>
                                <p style={{ color: this.props.corLetraDescricaoDosCampos, fontWeight: 'bold', fontSize: 12, marginTop: 30 }}>Setor<span style={{ color: 'red', fontSize: 14, marginLeft: 3 }}>*</span></p>

                                <InputEmpresa
                                    autoFocus
                                    type="text"
                                    placeholder="Nome do Setor"
                                    colorDinamica={this.props.coresLetraPadrao}
                                    placeholderDinamico={this.props.coresLetraSecundario}
                                    borderDinamico={this.props.corBotaoPadrao}
                                    value={this.props.nomeSetor}
                                    onChange={(e) => { this.props.modificaNomeSetor(e.target.value) }}
                                //  onFocus={() => { this.handleInputEmailFocus() }}
                                // onBlur={() => { this.handleInputEmailBlur() }}
                                />


                                <p style={{ color: this.props.corLetraDescricaoDosCampos, fontWeight: 'bold', fontSize: 12, marginTop: 15 }}>Observação</p>
                                <InputEmpresa
                                    type="text"
                                    placeholder="Anote alguma observação do setor"
                                    colorDinamica={this.props.coresLetraPadrao}
                                    placeholderDinamico={this.props.coresLetraSecundario}
                                    borderDinamico={this.props.corBotaoPadrao}
                                    value={this.props.observacaoSetor}
                                    onChange={(e) => { this.props.modificaObservacaoSetor(e.target.value) }}
                                //  onFocus={() => { this.handleInputEmailFocus() }}
                                // onBlur={() => { this.handleInputEmailBlur() }}
                                />
                                {this.props.verificaAgrupaAtendenteSetor === true && <DivConfiguracoes>
                                    <ContainerPermitir2>
                                        <TextPermitir2 data-tip="Ao desmarcar esta opção este SETOR não ficará disponível para o CLIENTE Selecionar ao abrir chamado no app/portal CLIENTE." data-for="tip-top" corLetraDinamica={this.props.corLetraDescricaoDosCampos}>Permitir CLIENTE selecionar este setor<IconeInterrogacao colorDinamico={this.props.coresLetraSecundario} className="icon-question-mark-on-a-circular-black-background" /></TextPermitir2>
                                        <CheckBoxWrapper>
                                            <CheckBox id="checkbox" type="checkbox" checked={this.props.permiteClienteSelecionarSetor} onChange={(value) => {

                                                if (value.target.checked == false) {
                                                    this.setState({ modalAtencaoSetor: true })
                                                } else {
                                                    this.props.modificaPermiteClienteSelecionarSetor(value.target.checked);
                                                }
                                            }} />
                                            <CheckBoxLabel htmlFor="checkbox" />
                                        </CheckBoxWrapper>

                                    </ContainerPermitir2>
                                </DivConfiguracoes>}
                            </ContainerDadosCliente>
                            {this.props.verificaAgrupaAtendenteSetor == true && <>


                                <ContainerAtendentesSelecionados corLetraDinamico={this.props.corLetraDescricaoDosCampos}>
                                    <ContainerAtendentesSetor backgroundDinamico={this.props.backgroundSegundo} corLetraDinamico={this.props.corLetraDescricaoDosCampos}>

                                        <TextAtendentesSetor corLetraDinamica={this.props.corLetraDescricaoDosCampos}>                                    <i className="icon-atendimentos" style={{
                                            color: '#FFF', fontSize: 17,
                                            //backgroundColor:'rgba(173, 173, 173, 0.2)',padding:5,borderRadius:5
                                        }} />Atendentes que Atuam neste Setor:</TextAtendentesSetor>
                                        <ContainerSelectSearch>

                                            <p style={{ marginLeft: 5, color: this.props.corLetraDescricaoDosCampos, fontSize: 14 }}>Atendentes:</p>
                                            <SelectSearch
                                                renderValue={(valueProps) =>
                                                    <DivInputSearch>
                                                        <InputSearch
                                                            corLetraDinamico={this.props.coresLetraPadrao}
                                                            backgroundDinamico={this.props.backgroundMaisEscuro}
                                                            placeholderColor={this.props.corLetraSecundaria}
                                                            corBordaDinamica={this.props.corBotaoPadrao}
                                                            {...valueProps} ></InputSearch>
                                                        <IconeSetaPraBaixo className="icon-angle-arrow-down" colorDinamico={this.props.coresLetraPadrao} />
                                                    </DivInputSearch>
                                                }
                                                search
                                                closeOnSelect={false}
                                                printOptions="on-focus"
                                                multiple
                                                value={this.props.atendenteVinculadoSelecionado}
                                                onChange={(value) => this.props.modificaAtendenteVinculadoSelecionado(value)}
                                                options={this.props.atendentesVinculadosSetor}
                                                placeholder="Selecione os Atendentes"
                                            />

                                        </ContainerSelectSearch>
                                    </ContainerAtendentesSetor>
                                </ContainerAtendentesSelecionados>
                            </>}
                        </SubContainerCliente>}


                        {this.props.modalNovoSetor == false && <ContainerBotaoAlterar>
                            {this.props.loadingBotaoAlterarSetor == false && <ButtonEntrar backGroundDinamico={this.props.corBotaoPadrao} corLetraBotao={this.props.letraBotaoPadrao} type="submit" onClick={() => {
                                this.props.alteraSetor(this.props.nomeSetor, this.props.observacaoSetor, this.props.keySetor, this.props.atendenteVinculadoSelecionado, this.props.verificaAgrupaAtendenteSetor, this.props.permiteClienteSelecionarSetor)
                            }}>
                                Alterar
                       </ButtonEntrar>}
                            {this.props.loadingBotaoAlterarSetor == true && <ClipLoader
                                css={{ marginBottom: 10 }}
                                size={35}
                                color={this.props.corBotaoPadrao}
                                loading={this.props.loadingBotaoAlterarSetor}
                            />}
                        </ContainerBotaoAlterar>}

                    </ContainerPrincipalDadosCliente>}

                    <Modal focusTrapped={false} showCloseIcon={false} open={this.props.visibleModalExcluirSetor} onClose={() => { }} center>
                        <ContainerModal backgroundDinamico={this.props.backgroundPrimeiro}>
                            <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                                <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Excluir Setor</TextNovoAtendimento>
                                <div>
                                    <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => { this.props.mudaVisibilidadeModalExcluirSetor(false) }} className="icon-close" />
                                </div>
                            </ContainerIconeFecharModal>
                            <ContainerImagemVincularEmpresaSetor>
                                <img src={excluirSetorPNG} alt="foto excluir" style={{ height: 130, width: 130 }} />
                                <span style={{ color: this.props.coresLetraPadrao }}>Tem certeza que deseja excluir o Setor <span style={{ color: this.props.coresLetraPadrao, fontWeight: 'bold' }}>{this.props.nomeSetor}</span>?</span>
                            </ContainerImagemVincularEmpresaSetor>

                            <ContainerExplicacaoVincular>
                                <TextVincular corLetraDinamica={this.props.coresLetraPadrao}>A exclusão de <span style={{ color: this.props.coresLetraPadrao, fontSize: 16, fontWeight: 'bold' }}>{this.props.nomeSetor}</span> não apaga os dados dos Atendimentos vinculados á esse Setor, apenas o setor é excluído e não será mais listado.</TextVincular>
                            </ContainerExplicacaoVincular>
                            <ContainerBotaoAlterar>
                                <ButtonVincular backGroundDinamico={this.props.corBotaoPadrao} corLetraBotao={this.props.letraBotaoPadrao} type="submit" onClick={() => {

                                    this.props.excluirSetor(this.props.keySetor);
                                    if (this.props.filtroSetor !== "") {
                                        this.removeFiltrosAll();
                                    }
                                    this.props.mudaVisibilidadeModalExcluirSetor(false);
                                }}>
                                    Excluir
                                </ButtonVincular>
                            </ContainerBotaoAlterar>
                        </ContainerModal>
                    </Modal>
                </Container>
            )
        }
    }
    render() {
        const CheckBoxWrapper = styled.div`
        position: relative;
        margin-left:10px;
      `;
        const CheckBoxLabel = styled.label`
        position: absolute;
        top: 0;
        left: 0;
        width: 42px;
        height: 23px;
        border-radius: 15px;
        background: ${this.props.coresLetraSecundario};
        cursor: pointer;
        &::after {
          content: "";
          display: block;
          border-radius: 50%;
          width: 18px;
          height: 18px;
          margin: 3px;
          background: #ffffff;
          box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
          transition: 0.2s;
        }
      `;
        const CheckBox = styled.input`
        opacity: 0;
        z-index: 1;
        border-radius: 15px;
        width: 42px;
        height: 26px;
        &:checked + ${CheckBoxLabel} {
          background: #00FF7F;
          &::after {
            content: "";
            display: block;
            border-radius: 50%;
            width: 18px;
            height: 18px;
            margin-left: 21px;
            transition: 0.2s;
          }
        }  &:disabled + ${CheckBoxLabel}{
            background: ${darken(0.2, '#00FF7F')};
        }
      `;
        return (
            <>
                {this.renderLoadingConsultaSetor()}
                <Modal focusTrapped={false} showCloseIcon={false} open={this.props.modalNovoSetor} onClose={() => { }} center>
                    <ContainerModalNovoCliente backgroundDinamico={this.props.backgroundPrimeiro}>
                        <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                            <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Cadastro Setor</TextNovoAtendimento>
                            <div>
                                <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => {

                                    if (this.props.retornaTelaClienteParaNovoAtendimentos) {
                                        this.props.history.goBack();
                                        this.props.modificaVisibleModalNovoSetor(false)
                                        this.props.modificaRetornaTelaClienteParaNovoAtendimento(false);
                                    } else {
                                        this.props.modificaVisibleModalNovoSetor(false)
                                    }
                                }} className="icon-close" />
                            </div>
                        </ContainerIconeFecharModal>

                        <SubContainerCliente>

                            <ContainerDadosCliente>

                                <p style={{ color: this.props.corLetraDescricaoDosCampos, fontWeight: 'bold', fontSize: 12, marginTop: 30 }}>Setor<span style={{ color: 'red', fontSize: 14, marginLeft: 3 }}>*</span></p>

                                <InputEmpresa
                                    autoFocus
                                    type="text"
                                    placeholder="Nome do Setor"
                                    colorDinamica={this.props.coresLetraPadrao}
                                    placeholderDinamico={this.props.coresLetraSecundario}
                                    borderDinamico={this.props.corBotaoPadrao}
                                    value={this.props.nomeSetorNovoCadastro}
                                    onChange={(e) => { this.props.modificaNomeSetorNovoCadastro(e.target.value) }}
                                //  onFocus={() => { this.handleInputEmailFocus() }}
                                // onBlur={() => { this.handleInputEmailBlur() }}
                                />


                                <p style={{ color: this.props.corLetraDescricaoDosCampos, fontWeight: 'bold', fontSize: 12, marginTop: 15 }}>Observação</p>
                                <InputEmpresa
                                    type="text"
                                    placeholder="Anote alguma observação do setor"
                                    colorDinamica={this.props.coresLetraPadrao}
                                    placeholderDinamico={this.props.coresLetraSecundario}
                                    borderDinamico={this.props.corBotaoPadrao}
                                    value={this.props.observacaoSetorNovoCadastro}
                                    onChange={(e) => { this.props.modificaObservacaoSetorNovoCadastro(e.target.value) }}
                                //  onFocus={() => { this.handleInputEmailFocus() }}
                                // onBlur={() => { this.handleInputEmailBlur() }}
                                />
                                {this.props.verificaAgrupaAtendenteSetor === true &&
                                    <DivConfiguracoes>
                                        <ContainerPermitir2>
                                            <TextPermitir2 data-tip="Ao desmarcar esta opção este SETOR não ficará disponível para o CLIENTE Selecionar ao abrir chamado no app/portal CLIENTE." data-for="tip-top" corLetraDinamica={this.props.corLetraDescricaoDosCampos}>Permitir CLIENTE selecionar este setor<IconeInterrogacao colorDinamico={this.props.coresLetraSecundario} className="icon-question-mark-on-a-circular-black-background" /></TextPermitir2>
                                            <CheckBoxWrapper>
                                                <CheckBox id="checkbox2" type="checkbox" checked={this.props.permiteClienteSelecionarSetorCadastro} onChange={(value) => {

                                                    if (value.target.checked == false) {
                                                        this.setState({ modalAtencaoSetor: true })
                                                    } else {
                                                        this.props.modificaPermiteClienteSelecionarSetorCadastro(value.target.checked);
                                                    }
                                                }} />
                                                <CheckBoxLabel htmlFor="checkbox2" />
                                            </CheckBoxWrapper>

                                        </ContainerPermitir2>
                                    </DivConfiguracoes>}

                            </ContainerDadosCliente>
                            {this.props.verificaAgrupaAtendenteSetor == true && <ContainerAtendentesSelecionados corLetraDinamico={this.props.corLetraDescricaoDosCampos}>
                                <ContainerAtendentesSetor backgroundDinamico={this.props.backgroundPrimeiro} corLetraDinamico={this.props.corLetraDescricaoDosCampos}>

                                    <TextAtendentesSetor corLetraDinamica={this.props.corLetraDescricaoDosCampos}>                                    <i className="icon-atendimentos" style={{
                                        color: '#FFF', fontSize: 17,
                                        //backgroundColor:'rgba(173, 173, 173, 0.2)',padding:5,borderRadius:5
                                    }} />Atendentes que Atuam neste Setor:</TextAtendentesSetor>
                                    <ContainerSelectSearch>

                                        <p style={{ marginLeft: 5, color: this.props.corLetraDescricaoDosCampos, fontSize: 14 }}>Atendentes:</p>
                                        <SelectSearch
                                            renderValue={(valueProps) =>
                                                <DivInputSearch>
                                                    <InputSearch
                                                        corLetraDinamico={this.props.coresLetraPadrao}
                                                        backgroundDinamico={this.props.backgroundMaisEscuro}
                                                        placeholderColor={this.props.corLetraSecundaria}
                                                        corBordaDinamica={this.props.corBotaoPadrao}
                                                        {...valueProps} ></InputSearch>
                                                    <IconeSetaPraBaixo className="icon-angle-arrow-down" colorDinamico={this.props.coresLetraPadrao} />
                                                </DivInputSearch>
                                            }
                                            search
                                            closeOnSelect={false}
                                            printOptions="on-focus"
                                            multiple
                                            value={this.props.atendenteVinculadoSelecionadoNovoCadastro}
                                            onChange={(value) => this.props.modificaAtendenteVinculadoSelecionadoNovoCadastro(value)}
                                            options={this.props.atendentesVinculadosSetor}
                                            placeholder="Selecione os Atendentes"
                                        />

                                    </ContainerSelectSearch>
                                </ContainerAtendentesSetor>
                            </ContainerAtendentesSelecionados>}
                        </SubContainerCliente>


                        <ContainerBotaoAlterar>
                            {this.props.loadingBotaoCadastraSetor == false && <ButtonEntrar backGroundDinamico={this.props.corBotaoPadrao} corLetraBotao={this.props.letraBotaoPadrao} type="submit" onClick={() => {

                                this.props.cadastraSetor(this.props.nomeSetorNovoCadastro, this.props.observacaoSetorNovoCadastro, this.props.atendenteVinculadoSelecionadoNovoCadastro, this.props.verificaAgrupaAtendenteSetor, this.props.permiteClienteSelecionarSetorCadastro)
                            }}>
                                Cadastrar
                            </ButtonEntrar>}
                            {this.props.loadingBotaoCadastraSetor == true && <ClipLoader
                                css={{ marginBottom: 10 }}
                                size={35}
                                color={this.props.corBotaoPadrao}
                                loading={this.props.loadingBotaoCadastraSetor}
                            />}
                        </ContainerBotaoAlterar>
                    </ContainerModalNovoCliente>
                </Modal>
                <Modal focusTrapped={false} showCloseIcon={false} open={this.state.modalAtencaoSetor} onClose={() => { }} center>
                    <ContainerModal backgroundDinamico={this.props.backgroundPrimeiro}>
                        <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                            <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Atenção</TextNovoAtendimento>
                            <div>
                                <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => {
                                    this.setState({ modalAtencaoSetor: false })
                                }} className="icon-close" />
                            </div>
                        </ContainerIconeFecharModal>
                        <ContainerImagemVincularEmpresa>
                            <img src={backGroundStopVersaoPremium} alt="foto atencao" style={{ height: 130, width: '100%', }} />
                        </ContainerImagemVincularEmpresa>

                        <ContainerExplicacaoVincular>
                            <TextVincular corLetraDinamica={this.props.coresLetraPadrao}>Atenção!!! Ao desmarcar esta opção, o SETOR não ficará disponível para o CLIENTE abrir chamado no app/portal CLIENTE, CERTIFIQUE-SE DE TER PELO MENOS 1 SETOR DISPONIVEL PARA O CLIENTE ABRIR ATENDIMENTO. </TextVincular>
                        </ContainerExplicacaoVincular>
                        <ContainerBotaoAlterar>
                            <ButtonVincular backGroundDinamico={this.props.corBotaoPadrao} corLetraBotao={this.props.letraBotaoPadrao} type="submit" onClick={() => {
                                this.setState({ modalAtencaoSetor: false });

                                if (this.props.modalNovoSetor === true) {
                                    this.props.modificaPermiteClienteSelecionarSetorCadastro(false)
                                } else {
                                    this.props.modificaPermiteClienteSelecionarSetor(false)
                                }
                            }}>
                                Desmarcar
                                </ButtonVincular>
                        </ContainerBotaoAlterar>
                    </ContainerModal>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = state => {
    return (
        {
            backgroundPrimeiro: state.CoresPadraoSistema.backgroundPrimeiro,
            backgroundSegundo: state.CoresPadraoSistema.backgroundSegundo,
            backgroundMaisEscuro: state.CoresPadraoSistema.backgroundMaisEscuro,
            coresLetraPadrao: state.CoresPadraoSistema.coresLetraPadrao,
            corBotaoPadrao: state.CoresPadraoSistema.corBotaoPadrao,
            corSegundoBotaoPadrao: state.CoresPadraoSistema.corSegundoBotaoPadrao,
            coresLetraSecundario: state.CoresPadraoSistema.coresLetraSecundario,
            letraBotaoPadrao: state.CoresPadraoSistema.letraBotaoPadrao,
            corLetraDescricaoDosCampos: state.CoresPadraoSistema.corLetraDescricaoDosCampos,
            nomeRota: state.HeaderReducer.nomeRota,
            testePesquisa: state.HeaderReducer.testePesquisa,

            nomeSetor: state.SetorReducer.nomeSetor,
            observacaoSetor: state.SetorReducer.observacaoSetor,
            loadingBotaoAlterarSetor: state.SetorReducer.loadingBotaoAlterarSetor,
            mensagemErroAlterarSetor: state.SetorReducer.mensagemErroAlterarSetor,
            backgroundMensagemErrorTelaAlterarSetor: state.SetorReducer.backgroundMensagemErrorTelaAlterarSetor,
            keySetor: state.SetorReducer.keySetor,
            verificaConexaoInternetAlteraSetor: state.SetorReducer.verificaConexaoInternetAlteraSetor,
            visibleModalExcluirSetor: state.SetorReducer.visibleModalExcluirSetor,
            loadingConteudoExcluirSetor: state.SetorReducer.loadingConteudoExcluirSetor,
            loadingSectionedAtendenteVinculadoSetor: state.SetorReducer.loadingSectionedAtendenteVinculadoSetor,
            atendentesVinculadosSetor: state.SetorReducer.atendentesVinculadosSetor,
            atendenteVinculadoSelecionado: state.SetorReducer.atendenteVinculadoSelecionado,
            verificaAgrupaAtendenteSetor: state.ChamadoReducer.verificaAgrupaAtendenteSetor,
            filtroSetor: state.SetorReducer.filtroSetor,
            setores: state.SetorReducer.setores,
            modalNovoSetor: state.SetorReducer.modalNovoSetor,

            inputDigitoConsultaSetor: state.SetorReducer.inputDigitoConsultaSetor,
            mensagemSucessoAlterarSetor: state.SetorReducer.mensagemSucessoAlterarSetor,
            verificaConexaoInternetConsultaSetor: state.SetorReducer.verificaConexaoInternetConsultaSetor,
            loadingConsultaSetor: state.SetorReducer.loadingConsultaSetor,
            loadingFimDaPaginaConsultaSetor: state.SetorReducer.loadingFimDaPaginaConsultaSetor,
            referenciaUltimaKeySetor: state.SetorReducer.referenciaUltimaKeySetor,
            loadingCarregarSetoresSectionedConsultaSetores: state.SetorReducer.loadingCarregarSetoresSectionedConsultaSetores,
            setoresSectionedConsulta: state.SetorReducer.setoresSectionedConsulta,
            setorSelecionadosectioned: state.SetorReducer.setorSelecionadosectioned,
            nomeSetoresSelecinadoSectioned: state.SetorReducer.nomeSetoresSelecinadoSectioned,
            refreshControlConsultaSetor: state.SetorReducer.refreshControlConsultaSetor,
            permissoesAcessarTelasAtendente: state.AtendenteReducer.permissoesAcessarTelasAtendente,
            licensawebouapp: state.PremiumReducer.licensawebouapp,
            nomeSetorNovoCadastro: state.SetorReducer.nomeSetorNovoCadastro,
            observacaoSetorNovoCadastro: state.SetorReducer.observacaoSetorNovoCadastro,
            atendenteVinculadoSelecionadoNovoCadastro: state.SetorReducer.atendenteVinculadoSelecionadoNovoCadastro,
            loadingBotaoCadastraSetor: state.SetorReducer.loadingBotaoCadastraSetor,
            retornaTelaClienteParaNovoAtendimentos: state.ChamadoReducer.retornaTelaClienteParaNovoAtendimentos,

            permiteClienteSelecionarSetor: state.SetorReducer.permiteClienteSelecionarSetor,
            permiteClienteSelecionarSetorCadastro: state.SetorReducer.permiteClienteSelecionarSetorCadastro,
        }
    );
};
export default connect(mapStateToProps, {
    modificaNomeSetor, modificaObservacaoSetor, zeraMensagemSemInternetAlteraSetor, mudaVisibilidadeModalExcluirSetor,
    modificaAtendenteVinculadoSelecionado, modificaFiltroSetoresTelaSetores, modificaVisibleModalNovoSetor,
    modificaNomeRotaNoHeader, consultaSetor2, listaAtendentesVincularSetor, modificaSetorSelecionadoSelecionadoSectioned,
    consultaSetorComSetor, listaSetorSectionedTelaConsultaSetor, modificaNomeSetorSelecionadoSectioned, alteraSetor,
    excluirSetor, cadastraSetor, modificaNomeSetorNovoCadastro, modificaObservacaoSetorNovoCadastro,
    modificaAtendenteVinculadoSelecionadoNovoCadastro, modificaRetornaTelaClienteParaNovoAtendimento,
    desligaOuvintesChamadosFirebase, modificaPermiteClienteSelecionarSetor, modificaPermiteClienteSelecionarSetorCadastro
})(withRouter(Setor));
