import styled, { keyframes } from 'styled-components';
import { shade, opacify } from 'polished';
import {  Link,} from 'react-router-dom';

export const Container404 = styled.div`
display:flex;
 height:200px;
 min-height:200px;

 width:500px;
 border:0;
margin-bottom:4px;
flex-direction:column;
justify-content:space-between;
margin-top:6px;
margin-right:13px;
margin-left:13px;
border-radius:7px;
background:${props => props.backGroundDinamico};
 -webkit-box-shadow: 0px 0px  3px #000000;
  -moz-box-shadow: 0px 0px  3px #000000;
  box-shadow: 0px 0px  3px #000000;
`;
export const ButtonEntrar = styled(Link)`
display:flex;
justify-content:center;
align-items:center;
text-decoration:none;
background-color:${props => props.backGroundDinamico};
color:${props => props.corLetraBotao};
letter-spacing: 1px;
font-size:21px;
font-weight:bold;
width:40%;
border:0;
transition: background-color 0.2s;
height:40px;
min-height:40px;
margin:10px;
border-radius: 5px;
&:hover{
   background: ${props => shade(0.2, props.backGroundDinamico)};
}

`;