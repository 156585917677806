import React ,{memo}from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import ReactTooltip from "react-tooltip";
import {
    Title, ContainerChamado, ContainerImagem, ContainerDadosChamado,
    ContaineDadosChamadosPrincipal, ContaineDadosChamadosSecundario,
    ContainerIconeEFrase, TextCliente, TextProblema, ContainerIconeEFraseClienteAtendente,
    SubContainerIconeEFraseClienteAtendente, TextNumeroChamado,
    ContainerNumeroChamado, ContainerDataAbertura, ContainerSeta, TextEmpresa,
    TextDest,ContainerDestStatus,TextData
} from './styled';
import {
    navegaTelaAlterarAviso
} from '../../actions/AvisoAction';
import imgTeste from '../../imagens/userPadrao.png'

class FlatListAvisos extends React.Component {
    state = {
        dest: (this.props.data.destinatario === 1) ? 'Atendentes' : 'Clientes',
        status: (this.props.data.ativo === true) ? 'Ativo' : 'Inativo',
        corStatus: (this.props.data.ativo === true) ? '#00FF7F' : 'red',
    }

    componentDidMount() {
         ReactTooltip.rebuild();
         if (this.props.avisos[0].key == this.props.data.key) {
            this.props.navegaTelaAlterarAviso(this.props.data);
            this.props.onClickNotices(this.props.data);
        }
    }

    navegaTelaAlterarAvisoLocal() {
        /* if (this.props.permissoesAcessarTelasAtendente[0].permissaoAlterarCadastrarAvisos == undefined) {
            alert('Verifique se há conexão com a INTERNET e depois tente novamente.');
          } else {
        if (this.props.permissoesAcessarTelasAtendente[0].permissaoAlterarCadastrarAvisos) {
            if (this.props.premium == 0) {
                this.props.mudaVisibilidadeModalAvisoVersaoPremium(true)
            } else { */
        this.props.navegaTelaAlterarAviso(this.props.data)
        /*  }
     } else {
         Alert.alert(
             'Atenção',
             'Você não tem permissão para Cadastrar/Alterar Avisos.',

             [{ text: 'OK', onPress: () => { } }],
         );
     }
 }*/

    }

    render() {
        return (

            <ContainerChamado backGroundDinamico={this.props.backgroundMaisEscuro} type="button" onClick={() => { this.navegaTelaAlterarAvisoLocal(); this.props.onClickNotices(this.props.data) }}>
                {this.props.data.key == this.props.keyAvisoParaAlteracao && <div style={{ width: 5, backgroundColor: this.props.corBotaoPadrao, height: 58, borderTopLeftRadius: 7, borderBottomLeftRadius: 7 }}></div>}

                <ContainerDadosChamado>
                    <ContaineDadosChamadosPrincipal>
                        <SubContainerIconeEFraseClienteAtendente >
                            <TextCliente data-tip="Descrição do Aviso" data-for="tip-top" corLetraDinamica={this.props.coresLetraPadrao}>{this.props.data.aviso}</TextCliente>
                        </SubContainerIconeEFraseClienteAtendente>
                        <ContainerDestStatus>
                            <SubContainerIconeEFraseClienteAtendente >
                                <TextDest  corLetraDinamica={this.props.corLetraDescricaoDosCampos}>Destinatário:</TextDest>
                                <TextEmpresa  corLetraDinamica={this.props.coresLetraSecundario}>{this.state.dest}</TextEmpresa>
                            </SubContainerIconeEFraseClienteAtendente>
                            <SubContainerIconeEFraseClienteAtendente >
                                <TextDest data-tip="Status do Aviso" data-for="tip-top" corLetraDinamica={this.props.corLetraDescricaoDosCampos}>Status:</TextDest>
                                <TextEmpresa data-tip="Status do Aviso" data-for="tip-top" corLetraDinamica={this.state.corStatus}>{this.state.status}</TextEmpresa>
                            </SubContainerIconeEFraseClienteAtendente>
                        </ContainerDestStatus>
                        {(this.props.data.dataInicial !== '') ? <ContainerDestStatus>
                            <SubContainerIconeEFraseClienteAtendente >
                                <TextDest  corLetraDinamica={this.props.corLetraDescricaoDosCampos}>Início:</TextDest>
                                <TextData  corLetraDinamica={this.props.coresLetraSecundario}>{this.props.data.dataInicial}</TextData>
                            </SubContainerIconeEFraseClienteAtendente>
                            <SubContainerIconeEFraseClienteAtendente >
                                <TextDest  corLetraDinamica={this.props.corLetraDescricaoDosCampos}>Fim:</TextDest>
                                <TextData  corLetraDinamica={this.props.coresLetraSecundario}>{this.props.data.dataFinal}</TextData>
                            </SubContainerIconeEFraseClienteAtendente>
                        </ContainerDestStatus>: null}
                    </ContaineDadosChamadosPrincipal>
                    <ContainerSeta>
                        <i className="icon-nextbold" style={{ fontSize: 10, color: this.props.coresLetraSecundario }} />
                    </ContainerSeta>

                </ContainerDadosChamado>


            </ContainerChamado>

        );
    }
}

const mapStateToProps = state => {
    return (
        {
            backgroundPrimeiro: state.CoresPadraoSistema.backgroundPrimeiro,
            backgroundSegundo: state.CoresPadraoSistema.backgroundSegundo,
            backgroundMaisEscuro: state.CoresPadraoSistema.backgroundMaisEscuro,
            coresLetraPadrao: state.CoresPadraoSistema.coresLetraPadrao,
            coresLetraSecundario: state.CoresPadraoSistema.coresLetraSecundario,
            corBotaoPadrao: state.CoresPadraoSistema.corBotaoPadrao,
            corSegundoBotaoPadrao: state.CoresPadraoSistema.corSegundoBotaoPadrao,
            corLetraDescricaoDosCampos: state.CoresPadraoSistema.corLetraDescricaoDosCampos,
            keyAvisoParaAlteracao: state.AvisosReducer.keyAvisoParaAlteracao,
            avisos: state.AvisosReducer.avisos,
        }
    );
};
export default connect(mapStateToProps, {
    navegaTelaAlterarAviso
})(memo(FlatListAvisos));
