import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom";
import firebase, { searchFromHasura } from "./FirebaseConnection";
import ClipLoader from "react-spinners/ClipLoader";
import api from '../http_client/http.client';
import jwt_decode from "jwt-decode";
import { toastr } from 'react-redux-toastr';
import { listaConfiguracoesDaEmpresa, modificaLoadingConfiguracoesEmpresaExport } from "../actions/DashBoardAction";
import { sairDoApp } from "../actions/PerfilAction";
import { getIdCompanyByLocalStorage } from "../http_client/common";


export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [pending, setPending] = useState(true);
    const backgroundPrimeiro = useSelector(state => state.CoresPadraoSistema.backgroundPrimeiro)
    const corBotaoPadrao = useSelector(state => state.CoresPadraoSistema.corBotaoPadrao)
    const loadingConfiguracoesEmpresa = useSelector(state => state.DashBoardReducer.loadingConfiguracoesEmpresa)
    let history = useHistory();
    const dispatch = useDispatch();
    const { pathname } = useLocation();


    useEffect(() => {

        firebase.auth().onAuthStateChanged(async (user) => {
            //  console.log('user', user);
            setCurrentUser(user);
            const idCompany = getIdCompanyByLocalStorage();


            if (user) {
                try {
                   /*  console.log('listando usuarios', idCompany);
                    if (idCompany) {
                        firebase.database().ref(`${idCompany}/usuarios/`).orderByKey().limitToLast(1).once('value').then((snapshot) => {
                            console.log('test', snapshot.val());
                        }).catch(err => console.log('err database', err));
                    } */
                    const token = await user.getIdToken();
                    const decodedToken = token ? jwt_decode(token) : null;

                    if (!decodedToken || !decodedToken.idCompany || (!!idCompany && idCompany !== decodedToken.idCompany) ||
                        decodedToken.idCompany === 'null') {
                        //console.log('fazendo login');
                        await api.post('login');
                        const newToken = await user.getIdToken(true);
                        localStorage.setItem('token', newToken);
                    }

                    dispatch(listaConfiguracoesDaEmpresa(history, pathname));
                } catch (e) {
                    // console.log('error',e);
                   // dispatch(sairDoApp());
                    toastr.error('Error', 'Erro ao se autenticar');
                }

            } else {
                dispatch(modificaLoadingConfiguracoesEmpresaExport(false));
                history.push(("/"));

            }
            setPending(false);
        });
    }, []);

    if (loadingConfiguracoesEmpresa) {
        return (
            <div style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: JSON.parse(localStorage.getItem('backgroundPrimeiro')) == null ? backgroundPrimeiro : JSON.parse(localStorage.getItem('backgroundPrimeiro')),
                height: '100%'
            }}>
                <ClipLoader
                    //  css={override}
                    size={40}
                    color={JSON.parse(localStorage.getItem('corBotaoPadrao')) == null ? corBotaoPadrao : JSON.parse(localStorage.getItem('corBotaoPadrao'))}
                    loading={true}
                />
            </div>
        )
    }

    return (
        <AuthContext.Provider
            value={{
                currentUser
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
