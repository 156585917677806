import styled, { keyframes } from 'styled-components';
import { shade, opacify, lighten, } from 'polished';

const animacaoLoading = keyframes`
   0%{
        background-position: 0% 0%;
     }
     100%{
        background-position: -135% 0%;
     }
  
`;
export const Container = styled.div`
 display:flex;
 flex: 1;
 height:calc(100vh - 61px);
 flex-direction:row;
overflow:auto;
`;
export const SubContainer = styled.div`
 display:flex;
padding:10px;
overflow-x:hidden;
align-items:center;
 flex-direction:column;
 margin:15px;
`;
export const ContainerPrincipalDadosCliente = styled.div`
 display:flex;
 flex:1;
 padding-bottom:10px;
 flex-direction:column;
 margin:15px;
 overflow:auto;
 background:${props => props.backGroundDinamico};
 border-radius:5px;
`;

export const SubContainerCliente = styled.div`
 display:flex;
 flex:1;
 flex-direction:column;
`;
export const ContainerAtendentesSelecionados = styled.div`
 display:flex;
 flex:1;
 flex-direction:column;
 justify-content:flex-start;
align-items:center;
margin:20px;
`;
export const ContainerAtendentesSetor = styled.div`
 display:flex;
 height:150px;
 min-height:150px;
 width:100%;
 flex-direction:column;
 border-radius:10px;
 justify-content:center;
align-items:center;
border:${props => `1px solid ${props.corLetraDinamico}`};
background:${props => props.backgroundDinamico};


`;
export const ContainerSelectSearch = styled.div`
display:flex;
flex-direction:column;
height:30px;
min-height:30px;
width:400px;
margin-left:15px;
`;
export const DivIconeExcluirCliente = styled.div`
 display:flex;
 flex-direction:row;
justify-content:flex-end;
align-items:flex-end;
padding-top:5px;
`;

export const SubContainerClientePermissao = styled.div`
 display:flex;
 flex:1;
 flex-direction:column;
`;
export const ContainerPermitir = styled.div`
 display:flex;
 justify-content:center;
 flex-direction:row;
 margin-top:10px;
`;


export const ContainerChamado = styled.button`
display:flex;
 height:60px;
 min-height:60px;
 width:98%;
 border:0;
margin-bottom:4px;
margin-top:6px;
margin-right:13px;
margin-left:13px;
border-radius:7px;
background:${props => props.backGroundDinamico};
 flex-direction:row;
 transition: transform 0.2s;
 -webkit-box-shadow: 0px 0px  3px #000000;
  -moz-box-shadow: 0px 0px  3px #000000;
  box-shadow: 0px 0px  3px #000000;
 &:hover{
    transform: translate(10px);
 }
 
`;
export const ContainerImagem = styled.div`
display:flex;
 height:60px;
 min-height:60px;
width:70px;
align-items:center;
justify-content:center;
 flex-direction:column;
`;
export const ContainerImagemAlterarCliente = styled.div`
display:flex;
 height:100%;
width:200px;

align-items:center;
justify-content:center;
 flex-direction:column;
`;


export const ContainerDadosCliente = styled.div`
 display:flex;
 flex: 1;
 flex-direction:column;
margin-right:10px;
margin-left:10px;
`;
export const ContainerDadosNovoCliente = styled.div`
 display:flex;
 flex: 1;
 flex-direction:column;
margin-right:10px;
justify-content:center;
`;
export const ContainerDadosLoginCliente = styled.div`
 display:flex;
 flex: 1;
 flex-direction:column;
margin-right:10px;
margin-left:30px;
`;
export const ContainerBotaoAlterar = styled.div`
 display:flex;
 flex-direction:column;
 justify-content:center;
 align-items:center;
`;

export const ContainerDadosChamado = styled.div`
display:flex;
flex:1;
 flex-direction:row;
`;

export const ContaineDadosChamadosPrincipal = styled.div`
display:flex;
flex:1;
 height:60px;
 min-height:60px;
 flex-direction:column;
`;
export const ContainerIconeEFrase = styled.div`
display:flex;
flex:1;
 flex-direction:row;
  align-items:center;
 justify-content:flex-start;
`;
export const ContainerIconeEFraseClienteAtendente = styled.div`
display:flex;
flex:1;
 flex-direction:row;
`;
export const DivContainerInputSenha = styled.div`
display:flex;
 flex-direction:row;
`;
export const DivContainerInputEmpresaVinculada = styled.div`
display:flex;
 flex-direction:row;
 align-items:center;
`;

export const SubContainerIconeEFraseClienteAtendente = styled.div`
display:flex;
 flex-direction:row;
 align-items:center;
 justify-content:flex-start;
 padding-left:10px;
 height:30px;
 min-height:30px;
 
`;
export const TextCliente = styled.p`
font-size:14px;
margin-left:3px;
font-weight:bold;
color:${props => props.corLetraDinamica};
max-width:230px;;
overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
`;
export const TextEmpresa = styled.p`
font-size:13px;
margin-left:3px;
color:${props => props.corLetraDinamica};
max-width:230px;;
overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
`;

export const TextProblema = styled.p`
font-size:14px;
margin-left:3px;
font-weight:bold;
font-style:italic;
color:${props => props.corLetraDinamica};
`;
export const TextPermitir = styled.p`
font-size:16px;
text-align:center;
font-weight:bold;
color:${props => props.corLetraDinamica};
`;
export const ContaineDadosChamadosSecundario = styled.div`
display:flex;
flex:0.5;
 height:100px;
 min-height:100px;
 flex-direction:column;
 align-items:flex-start;
 justify-content:flex-start;
`;
export const ContainerNumeroChamado = styled.div`
display:flex;
 justify-content:center;
margin-right:2px;
`;
export const ContainerInputSearch = styled.div`
display:flex;
flex-direction:row;
 justify-content:center;
 align-items:center;
 margin-bottom:10px;
margin-right:2px;
`;

export const ContainerSeta = styled.div`
display:flex;
flex-direction:row;
justify-content:center;
align-items:center;
width:30px;
`;
export const TextNumeroChamado = styled.strong`
font-size:14px;
font-weight:bold;
color:${props => props.corLetraDinamica};
`;
export const TextDataHoraAbertura = styled.p`
font-size:9px;
color:${props => props.corLetraDinamica};
margin-left:2px;
`;
export const TextAbertura = styled.p`
font-size:10px;
color:${props => props.corLetraDinamica};
`;

export const ContainerDataAbertura = styled.div`
display:flex;
flex-direction:row;
justify-content:flex-start;
align-items:center;
margin-bottom:2px;
`;
export const IconeSair = styled.i`
font-size:17px;
margin-right:5px;
color:red;
cursor: pointer;
`;
export const InputFiltroPorNumero = styled.input`
display:flex;
background-Color:#FFF;
flex:1;
height:30px;
min-height:30px;
padding-left:20px;
width:400px;
border:0;
border-radius:3px;
color:#000;
&::placeholder{
   color:#a8a8B3
}
`;

export const BotaoPesquisarChamadoPorNumero = styled.button`
display:flex;
justify-content:center;
align-items:center;
background:${props => props.backGroundDinamico};
width:30px;
border:0;
transition: background-color 0.2s;
height:30px;
min-height:30px;
margin-left:5px;
border-radius: 5px;

`;

export const CheckBox = styled.input`
  z-index: 1;
  border-radius: 15px;
  width: 42px;
  height: 26px;
  min-height: 26px;
  background:#CCC;
  &:checked  {
    background: #00FF7F;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      min-height: 18px;
      margin-left: 21px;
      transition: 0.2s;
    }
  }
`;

export const ButtonEntrar = styled.button`
display:flex;
justify-content:center;
align-items:center;

background-color:${props => props.backGroundDinamico};
color:${props => props.corLetraBotao};
letter-spacing: 1px;
font-size:21px;
font-weight:bold;
width:40%;
border:0;
transition: background-color 0.2s;
height:40px;
min-height:40px;
margin:10px;
border-radius: 5px;
&:hover{
   background: ${props => shade(0.2, props.backGroundDinamico)};
}

`;
export const IconeExcluirCliente = styled.i`
font-size:18px;
color:${props => props.colorDinamico};;
cursor: pointer;
&:hover{
  color: ${props => shade(0.2, props.colorDinamico)};
}
`;
export const ContainerModal = styled.div`
 display:flex;
   overflow:auto;
 flex:1;
   width:400px;
   height:400px;
   min-height:400px;
   flex-direction:column;
   border-radius:7px;
   background:${props => props.backgroundDinamico};
`;
export const ContainerModalNovoCliente = styled.div`
 display:flex;
   overflow:auto;
 flex:1;
   width:580px;
   padding-bottom:10px;
   height:580px;
   min-height:580px;
   flex-direction:column;
   border-radius:7px;
   background:${props => props.backgroundDinamico};
`;
export const TextNovoAtendimento = styled.strong`
font-size:17px;
color:${props => props.corLetraDinamico};
margin-left:12px;
letter-spacing: 1.5px;
`;
export const IconeFechar = styled.i`
font-size:15px;
margin-right:5px;
color:${props => props.backgroundDinamico};
cursor: pointer;
`;
export const ContainerIconeFecharModal = styled.div`
padding-top:5px;
padding-bottom:5px;
justify-content:space-between;
align-items:center;
display:flex;
border-radius: 7px 7px 0 0;
width:100%;
background:${props => props.backgroundDinamico};
`;
export const IconeMostrarSenha = styled.i`
font-size:18px;
color:${props => props.colorDinamico};;
cursor: pointer;
&:hover{
  color: ${props => shade(0.2, props.colorDinamico)};
}
border-bottom: ${props => `1px solid ${props.colorDinamico}`};

&:focus {
   border-bottom: ${props => `1px solid ${props.bordaDinamica}`};
  }
`;
export const InputEmpresa = styled.input`
display:flex;
background-Color:transparent;
height:25px;
min-height:25px;
width:100%;
border:0;
font-size:14px;
border-bottom: ${props => `1px solid ${props.colorDinamica}`};
color:${props => props.colorDinamica};
&::placeholder{
   color:${props => props.placeholderDinamico};
}
:focus +${IconeMostrarSenha}{
   border-bottom: ${props => `1px solid ${props.borderDinamico}`};
  }
  :focus{
   border-bottom: ${props => `1px solid ${props.borderDinamico}`};
  }
`;
export const IconeEmpresaVinculada = styled.i`
font-size:19px;
color:${props => props.colorDinamica};
cursor: pointer;
`;
export const IconeEmpresaVinculadaOff = styled.i`
font-size:19px;
color:${props => props.colorDinamica};
`;

export const ContainerImagemVincularEmpresa = styled.div`
 display:flex;
 flex:1.5;
  flex-direction:column;
  align-items:flex-start;
  justify-content:flex-start;
`;
export const ContainerImagemVincularEmpresaSetor = styled.div`
 display:flex;
 flex:1.5;
  flex-direction:column;
  align-items:center;
  justify-content:center;
`;
export const ContainerExplicacaoVincular = styled.div`
 display:flex;
 flex:1;
 padding:5px;
  flex-direction:column;
  align-items:center;
  justify-content:center;
`;
export const ButtonVincular = styled.button`
display:flex;
justify-content:center;
align-items:center;

background-color:${props => props.backGroundDinamico};
color:${props => props.corLetraBotao};
letter-spacing: 1px;
font-size:20px;
font-weight:bold;
width:60%;
border:0;
transition: background-color 0.2s;
height:40px;
min-height:40px;
margin:10px;
border-radius: 5px;
&:hover{
   background: ${props => shade(0.2, props.backGroundDinamico)};
}
`;

export const TextVincular = styled.p`
font-size:16px;
text-align:center;
color:${props => props.corLetraDinamica};
`;

export const ImagePerfil = styled.img`
height:150px;
min-height:150px;
width:150px;
border-radius:50%;
cursor: pointer;
`;
export const TextAtendentesSetor = styled.p`
font-size:16px;
text-align:center;
height:50px;
min-height:50px;
color:${props => props.corLetraDinamica};
`;

export const InputSearch = styled.input`
display:flex;
flex:1;
height:30px;
min-height:30px;
width:100%;
border-radius:5px;
padding-left:7px;
font-size:14px;
border:0;
color:${props => props.corLetraDinamico};
background-color: ${props => props.backgroundDinamico};

border:${props => `outset 0.5px ${props.corLetraDinamico}`};
cursor: pointer;
&::placeholder{
   color:${props => props.placeholderColor};
   font-style: italic;
}
&:hover{
  border:${props => `solid 0.5px ${props.corBordaDinamica}`};
 }

`;

export const DivInputSearch = styled.div`
 display:flex;
  flex-direction:row;
  align-items:center;

`;
export const IconeSetaPraBaixo = styled.i`
font-size: 12px;
position: absolute;
top:8px;
right: 19px;
width: 11px;
height: 11px;
min-height: 11px;
color:${props => props.colorDinamico};
pointer-events: none;
`;
export const ImageLoading = styled.div`
display:flex;
 height:40px;
 min-height:40px;
width:40px;
border-radius:50%;
background:${props => `linear-gradient(to right, ${lighten(0.2, props.backgroundinamico)} 10%, ${lighten(0.2, props.backgroundinamico)} 28%, ${props.backgroundinamico} 33%)`};
animation:${animacaoLoading} 1.2s linear infinite;
  background-size: 400% 400%; 
`;
export const ContainerChamadoLoading = styled.div`
display:flex;
 height:60px;
 min-height:60px;
 width:98%;
 border:0;
margin-bottom:4px;
margin-top:6px;
margin-right:13px;
margin-left:13px;
border-radius:7px;
background:${props => props.backGroundDinamico};
 flex-direction:row;
 -webkit-box-shadow: 0px 0px  3px #000000;
  -moz-box-shadow: 0px 0px  3px #000000;
  box-shadow: 0px 0px  3px #000000;
`;
export const TextClienteLoading = styled.div`
margin-left:3px;
height:15px;
min-height:15px;
border-radius:5px;
width:95%;
max-width:95%;
background:${props => `linear-gradient(to right, ${lighten(0.2, props.backgroundinamico)} 10%, ${lighten(0.2, props.backgroundinamico)} 28%, ${props.backgroundinamico} 33%)`};
animation:${animacaoLoading} 1.2s linear infinite;
  background-size: 400% 400%; 
`;
export const ImagemPerfilLoading = styled.div`
height:150px;
min-height:150px;
width:150px;
border-radius:50%;
background:${props => `linear-gradient(to right, ${lighten(0.2, props.backgroundinamico)} 10%, ${lighten(0.2, props.backgroundinamico)} 28%, ${props.backgroundinamico} 33%)`};
animation:${animacaoLoading} 1.2s linear infinite;
background-size: 400% 400%; 
`;
export const InputLoadingEmpresa = styled.div`
display:flex;
height:25px;
min-height:25px;
width:100%;
border:0;
margin-bottom:10px;
border-radius:4px;
background:${props => `linear-gradient(to right, ${lighten(0.2, props.backgroundinamico)} 10%, ${lighten(0.2, props.backgroundinamico)} 28%, ${props.backgroundinamico} 33%)`};
animation:${animacaoLoading} 1.2s linear infinite;
  background-size: 400% 400%; 

`;
export const SubContainerClienteLoading = styled.div`
 display:flex;
 flex:1;
 flex-direction:row;
padding-top:40px;
`;
export const ContainerDadosClienteLoading = styled.div`
 display:flex;
 height:100px;
 min-height:100px;
 justify-content:space-around;
 flex-direction:column;
margin-right:10px;
margin-left:10px;
margin-top:60px;
`;
export const SubContainerClientePermissaoLoading = styled.div`
 display:flex;
 flex:1;
 flex-direction:column;
 margin-top:40px;
 align-items:center;
 margin-right:10px;
 margin-left:10px;
`;
export const ContainerListaVazia = styled.div`
 display:flex;
 flex:1;
  flex-direction:column;
  align-items:center;
justify-content:center;
`;

export const DivConfiguracoes = styled.div`
 display:flex;
   flex-direction:column;
   justify-content:flex-start;
   margin-top:10px;
`;

export const IconeInterrogacao = styled.i`
font-size:15px;
margin-left:3px;
color:${props => props.colorDinamico};
cursor: pointer;

`;
export const ContainerPermitir2 = styled.div`
 display:flex;
 justify-content:flex-start;
 flex-direction:row;
 margin-top:10px;
`;
export const TextPermitir2 = styled.p`
font-size:13px;
text-align:center;
color:${props => props.corLetraDinamica};
font-weight:bold;
`;